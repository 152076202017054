import React, { useContext } from "react";
import { Select, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import Shippo from "./thirdPartyShipping/shippo";

const ThirdPartyOptions = (props) => {
  const { consignmentShip, setConsignmentShip, handleValidation, error, setSubmitEnable, cms } = useContext(
    FulfillmentContext
  );

  const { selected, setSelected } = props;

  const options = [{ label: cms("label.shippo"), value: constant.SHIPPO, disabled: false }];

  const handleShippo = async (field, value) => {
    const oldShippo = (consignmentShip && consignmentShip.shippo) || {};
    await setConsignmentShip((prevState) => ({
      ...prevState,
      shippo: { ...oldShippo, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleSelect = async (value) => {
    setSelected(value);
    const newConsignmentShip = consignmentShip;

    if (value === constant.SHIPPO) {
      newConsignmentShip.shippo = {};
    }
    newConsignmentShip.shippingOption = value;
    setSelected(value);
    await setConsignmentShip(newConsignmentShip);
  };

  return (
    <FormLayout>
      <Select
        label={cms("label.thirdParty")}
        placeholder={cms("placeholder.shippingOption")}
        options={options}
        onChange={(value) => handleSelect(value)}
        value={selected}
      />

      {selected === constant.SHIPPO && (
        <Shippo handleShippo={handleShippo} error={error} handleValidation={handleValidation} />
      )}
    </FormLayout>
  );
};

ThirdPartyOptions.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ThirdPartyOptions), {
  // feature: constant.ADVANCE_DROPSHIP,
  // subFeature: constant.ADVANCE_DROPSHIP,
});
