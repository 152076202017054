const cmsvendorReview = (cms) => {
  const reviewCMS = {
    custom: {
      down: cms("label.down"),
      onHoldVendor: cms("label.onHoldVendor"),
      rejectVendor: cms("label.rejectVendor"),
      rejectDeleteVendor: cms("label.rejectDeleteVendor"),
      up: cms("label.up"),
      description: {
        holdDescription1: cms("label.description.hold1"),
        holdDescription2: cms("label.description.hold2"),
        modalLabel: cms("label.description.modalLabel"),
        rejectDescription1: cms("label.description.reject1"),
        rejectDescription2: cms("label.description.reject2"),
        rejectDeleteDescription1: cms("label.description.rejectDelete1"),
        rejectDeleteDescription2: cms("label.description.rejectDelete2"),
      },
    },
    caption: {
      address: cms("caption.address"),
      brandHandle: cms("caption.brandHandle"),
      brandName: cms("caption.brandName"),
      brandTitle: cms("caption.brandTitle"),
      city: cms("caption.city"),
      contactTilte: cms("caption.contactTilte"),
      country: cms("caption.country"),
      email: cms("caption.email"),
      firstName: cms("caption.firstName"),
      helpText: cms("caption.request"),
      lastName: cms("caption.lastName"),
      phoneNumber: cms("caption.phoneNumber"),
      postalCode: cms("caption.postalCode"),
      provinceCode: cms("caption.provinceCode"),
    },
    button: {
      approveButton: cms("button.approve"),
      cancel: cms("button.cancel"),
      confirm: cms("button.confirm"),
      onHoldButton: cms("button.onHold"),
      rejectButton: cms("button.reject"),
      rejectDeleteButton: cms("button.rejectDelete"),
    },
    hyper: {
      hyperContent: cms("link.vendorPage"),
    },
    message: {
      approveToast: cms("messages.approve"),
      onHoldToast: cms("messages.onHold"),
      rejectToast: cms("messages.reject"),
      rejectDeleteToast: cms("messages.rejectDelete"),
    },
  };
  return reviewCMS;
};

export default cmsvendorReview;
