// import packages
import React, { useState, useEffect } from "react";

import CurrencyShipping from "./currencyShipping";

function DestinationShipping(props) {
  const { vendorId, cardLabel, vendorShippingRowsData, onChange } = props;
  const [vendorShippingRows, setVendorShippingRows] = useState([]);

  useEffect(() => {
    setVendorShippingRows([...vendorShippingRowsData]);
  }, [vendorShippingRowsData]);

  const handleDestinationChange = (vendorDestinationRows, destination) => {
    const rows = vendorShippingRows.filter((item) => item.destination !== destination);
    setVendorShippingRows([...rows, ...vendorDestinationRows]);
    onChange([...vendorShippingRows]);
  };

  const renderDestinationRow = () => {
    let destinations = vendorShippingRows.map((item) => item.destination).filter((item) => item);
    destinations = [...new Set(destinations)];
    const rows = destinations.sort().map((destination) => {
      const vendorDestinationRows = vendorShippingRows.filter((item) => item.destination === destination);
      return (
        <CurrencyShipping
          cardLabel={cardLabel}
          id={`${vendorId}${destination}`}
          vendorId={vendorId}
          destination={destination}
          vendorDestinationRowsData={vendorDestinationRows}
          onChange={(vendorDestinations) => handleDestinationChange(vendorDestinations, destination)}
        />
      );
    });

    return rows;
  };

  return renderDestinationRow();
}

export default DestinationShipping;
