import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

import ProviderVariantAdd from "app/product/modules/provider/features/variant/subFeatures/add/addService";
import { AddServiceVariant } from "./operator/features";

const { userKey } = constant;
const { operator, provider } = userKey;

const ServiceVariantAdd = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <AddServiceVariant />;
    }
    if (role === provider) {
      return <ProviderVariantAdd />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const VariantAddComponent = getComponent(currentUserRole);
  return VariantAddComponent;
};

export default ServiceVariantAdd;
