import React, { useContext } from "react";
import { AdminCustomDomain } from "app/userManagement/modules/admin/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin } = userKey;

const CustomDomain = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === admin) {
      return <AdminCustomDomain />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const CustomDomainComponent = getComponent(currentUserRole);
  return CustomDomainComponent;
};

export default CustomDomain;
