import React, { useState } from "react";
import { Layout, Collapsible } from "@shopify/polaris";

import { withErrorBoundary, withFeature } from "lib/hoc";

import { Toast, Banner } from "lib/components";
import constant from "lib/constant/constant";
import InvoiceAccess from "./access/access";
import InvoiceManage from "./label/label";
import CustomNote from "./customNote/customNote";
import InvoiceNumber from "./customNote/manageInvoiceNumber";

const Invoice = () => {
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Layout>
        <Layout.Section>
          {banner.isOpen && (
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => dismissBanner()}
              />
            </Layout.Section>
          )}
          <Collapsible open>
            <InvoiceAccess setMessage={setMessage} setBanner={setBanner} />
          </Collapsible>
          <Collapsible open>
            <InvoiceManage message={message} setMessage={setMessage} setBanner={setBanner} />
          </Collapsible>
          <Collapsible open>
            <CustomNote message={message} setMessage={setMessage} setBanner={setBanner} />
          </Collapsible>
          <InvoiceNumber message={message} setMessage={setMessage} setBanner={setBanner} />
          <Toast message={message} setToast={setMessage} timeout={5000} />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(Invoice), {
  feature: constant.PAYMENT_INVOICE,
});
