import { gql } from "apollo-boost";

const BULK_UPLOAD = gql`
  mutation bulkProductUpload($input: ProductUpload) {
    bulkProductUpload(input: $input) {
      status
      data
      error
    }
  }
`;

export default BULK_UPLOAD;
