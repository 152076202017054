const physicalData = (cms) => {
  const physicalDataSetting = [
    {
      label: cms("operator.section.shipping.content.physical.title"),
      helpText: cms("operator.section.shipping.content.physical.description"),
      key: "physical",
      name: "physical",
    },
    {
      label: cms("operator.section.shipping.content.nonPhysical.title"),
      helpText: cms("operator.section.shipping.content.nonPhysical.description"),
      key: "nonPhysical",
      name: "nonPhysical",
    },
  ];
  return physicalDataSetting;
};
export default physicalData;
