/* eslint-disable react/jsx-curly-newline */
// import packages
import React from "react";
import { FormLayout, Card, TextField, Stack, Checkbox } from "@shopify/polaris";

// import helper

// import constant
import constant from "lib/constant/constant";

const {
  PRICE = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999 },
} = constant;

const VariantField = (props) => {
  const {
    cms,
    handleValidVariants,
    option1,
    option2,
    option3,
    validVariants,
    variantValueChange,
    variantValues,
    variants,
  } = props;
  const key = {
    temp: 0,
  };

  const variantRows = variants.map((variant, idx) => {
    const item = { ...variants[idx] };
    key.temp += 1;
    return (
      <Card.Section subdued key={key.temp}>
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={!!validVariants[idx]}
              id="variant"
              labelHidden
              label={cms("label.productVariant")}
              onChange={() => handleValidVariants(idx)}
            />
          </Stack.Item>
          <Stack.Item vertical fill>
            {item.option1 && (
              <Stack.Item>
                <div className="ellipsis">{`${option1}: ${item.option1}`}</div>
              </Stack.Item>
            )}
            {item.option2 && (
              <Stack.Item>
                <div className="ellipsis">{`${option2}: ${item.option2}`}</div>
              </Stack.Item>
            )}
            {item.option3 && (
              <Stack.Item>
                <div className="ellipsis">{`${option3}: ${item.option3}`}</div>
              </Stack.Item>
            )}
          </Stack.Item>
        </Stack>
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label={cms("label.price")}
              id="price"
              type="number"
              min={MIN_PRICE}
              max={MAX_FLAT}
              disabled
              value={(variantValues[idx] && variantValues[idx].price).toString() || ""}
              onChange={(val) => variantValueChange(PRICE, (Number(val) >= 0 && Number(val)) || 0, idx)}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    );
  });
  return variantRows;
};

export default VariantField;
