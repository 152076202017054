import React, { useContext, useEffect, useState } from "react";
import { Card, FormLayout, Layout, List, Select } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import query
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";
import { GET_METAFIELD_SETTING } from "app/product/apollo/queries";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";
import ProductTabs from "./subFeatures/form/subFeatureItem/tab";
import BulkCreate from "./subFeatures/bulkCreateCSV/bulkCreate";
import addData from "./config/addData";
import ServiceTabs from "./subFeatures/form/service/serviceTabs";

const { CREATE_FORM, CREATE_CSV, SERVICE } = constant;

const ProductAdd = () => {
  const {
    cms,
    isLoading,
    currentUser: { _id: userId },
  } = useContext(PrivateContext);
  const { gql } = constant;
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [selected, setSelected] = useState();
  const [isHideTag, setIsHideTag] = useState(false);
  const [isHideType, setIsHideType] = useState(false);
  const [isMetafield, setIsMetafield] = useState(false);
  const { data: productSettingsData, refetch } = useQuery(GET_PRODUCT_SETTING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    const responseError = baseHelper.getResponseError(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, title: responseError, status: constant.CRITICAL });
    }
    if (responseData) {
      const { isHideTag: hideTag, isHideType: hydeType } = responseData;
      setIsHideTag(hideTag);
      setIsHideType(hydeType);
      refetch();
    }
  }, [gql.GET_PRODUCT_SETTING, productSettingsData, refetch]);

  const { loading: metafieldLoading, data: metaFieldData } = useQuery(GET_METAFIELD_SETTING);

  useEffect(() => {
    if (metaFieldData) {
      const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
      const { metafield = [] } = metafieldSetting;

      if (metafield && metafield.length) {
        setIsMetafield(true);
        return;
      }
      setIsMetafield(false);
    }
  }, [metaFieldData]);

  const renderComponent = (value) => {
    if (value === CREATE_FORM) {
      return <ProductTabs setBanner={setBanner} cms={cms} isMetafield={isMetafield} />;
    }

    if (value === SERVICE) {
      return <ServiceTabs setBanner={setBanner} cms={cms} />;
    }

    if (value === CREATE_CSV) {
      return <BulkCreate setBanner={setBanner} cms={cms} hideTag={isHideTag} hideType={isHideType} />;
    }
    return null;
  };
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (isLoading || metafieldLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={cms("label.import")} description={cms("label.csvInfo")}>
        <Card title={cms("label.addProduct")}>
          <Card.Section>
            <FormLayout>
              <List type="bullet">
                <List.Item>{cms("label.manual")}</List.Item>
                <List.Item>{cms("label.upload")}</List.Item>
                <List.Item>{cms("label.paas")}</List.Item>
              </List>
              <Select
                id="addProduct"
                options={addData(cms, userId).options}
                onChange={(value) => setSelected(value)}
                placeholder={cms("label.choose")}
                value={selected}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {renderComponent(selected)}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProductAdd), { feature: constant.PRODUCT_ADD });
