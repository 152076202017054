import { gql } from "apollo-boost";

const GET_PRODUCT_EXPORT_SETTING = gql`
  query getProductExportSetting {
    getProductExportSetting {
      error
      status
      data {
        export {
          handle {
            isHideCsv
            isHidePdf
            label
          }
          title {
            isHideCsv
            isHidePdf
            label
          }
          body {
            isHideCsv
            isHidePdf
            label
          }
          vendor {
            isHideCsv
            isHidePdf
            label
          }
          type {
            isHideCsv
            isHidePdf
            label
          }
          tags {
            isHideCsv
            isHidePdf
            label
          }
          collectionData {
            isHideCsv
            isHidePdf
            label
          }
          published {
            isHideCsv
            isHidePdf
            label
          }
          option1Name {
            isHideCsv
            isHidePdf
            label
          }
          option1Value {
            isHideCsv
            isHidePdf
            label
          }
          option2Name {
            isHideCsv
            isHidePdf
            label
          }
          option2Value {
            isHideCsv
            isHidePdf
            label
          }
          option3Name {
            isHideCsv
            isHidePdf
            label
          }
          option3Value {
            isHideCsv
            isHidePdf
            label
          }
          variantSku {
            isHideCsv
            isHidePdf
            label
          }
          variantWeight {
            isHideCsv
            isHidePdf
            label
          }
          variantInventoryTracker {
            isHideCsv
            isHidePdf
            label
          }
          variantInventoryQty {
            isHideCsv
            isHidePdf
            label
          }
          variantInventoryPolicies {
            isHideCsv
            isHidePdf
            label
          }
          variantPrice {
            isHideCsv
            isHidePdf
            label
          }
          variantCompareAtPrice {
            isHideCsv
            isHidePdf
            label
          }
          variantRequiresShipping {
            isHideCsv
            isHidePdf
            label
          }
          variantTaxable {
            isHideCsv
            isHidePdf
            label
          }
          variantBarcode {
            isHideCsv
            isHidePdf
            label
          }
          imageSrc {
            isHideCsv
            isHidePdf
            label
          }
          imageAtText {
            isHideCsv
            isHidePdf
            label
          }
          giftCard {
            isHideCsv
            isHidePdf
            label
          }
        }
        updatedAt
      }
    }
  }
`;
export default GET_PRODUCT_EXPORT_SETTING;
