import { gql } from "apollo-boost";

const FETCH_EMAIL_TEMPLATE_BY_ID = gql`
  query getEmailTemplateBy($input: EmailTemplateInput!) {
    getEmailTemplateById(input: $input) {
      status
      error
      data {
        label
        code
        category
        message
        parentId
        subject
        subjectTags
        tags
      }
    }
  }
`;
export default FETCH_EMAIL_TEMPLATE_BY_ID;
