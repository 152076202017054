import React, { useContext } from "react";
import { TextField, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";
import { withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const NZPostShipping = (props) => {
  const { cms } = useContext(FulfillmentContext);
  const { error, handleValidation, dropship, handleNZPost } = props;

  return (
    <Stack vertical>
      <TextField
        label={cms("label.clientId")}
        value={(dropship && dropship.nzPost && dropship.nzPost.clientId) || ""}
        onChange={(val) => handleNZPost("clientId", val)}
        error={error && error.clientId}
        onBlur={() => {
          handleValidation("clientId", dropship && dropship.nzPost && dropship.nzPost.clientId);
        }}
      />
      <TextField
        label={cms("label.secret")}
        value={(dropship && dropship.nzPost && dropship.nzPost.clientSecret) || ""}
        onChange={(val) => handleNZPost("clientSecret", val)}
        error={error && error.clientSecret}
        onBlur={() => {
          handleValidation("clientSecret", dropship && dropship.nzPost && dropship.nzPost.clientSecret);
        }}
      />
      <TextField
        label={cms("label.accountNumber")}
        value={(dropship && dropship.nzPost && dropship.nzPost.accountNumber) || ""}
        onChange={(val) => handleNZPost("accountNumber", val)}
        error={error && error.accountNumber}
        onBlur={() => {
          handleValidation("accountNumber", dropship && dropship.nzPost && dropship.nzPost.accountNumber);
        }}
      />
      <TextField
        label={cms("label.code")}
        value={(dropship && dropship.nzPost && dropship.nzPost.siteCode) || ""}
        onChange={(val) => handleNZPost("siteCode", val)}
        error={error && error.siteCode}
        onBlur={() => {
          handleValidation("siteCode", dropship && dropship.nzPost && dropship.nzPost.siteCode);
        }}
      />
    </Stack>
  );
};

NZPostShipping.propTypes = {
  dropship: PropTypes.objectOf(PropTypes.shape).isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleNZPost: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

NZPostShipping.defaultProps = {
  error: {},
};

export default withFeature(NZPostShipping, {
  feature: constant.NZPOST,
  // subFeature: constant.ADVANCE_DROPSHIP,
  // subFeatureItem: constant.NZPOST,
});
