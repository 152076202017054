// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_ORDER_HISTORY = gql`
  query getOrderHistory($input: getOrderHistory) {
    getOrderHistory(input: $input) {
      data {
        _id
        changes {
          orders {
            key
            prev
            updated
          }
          orderLines {
            key
            prev
            updated
          }
        }
        operation
        productId
        orderId
        userName
        updatedBy
        version
        via
        createdAt
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_ORDER_HISTORY;
