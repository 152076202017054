import parse from "html-react-parser";
import React, { useContext } from "react";
import { MediaCard } from "@shopify/polaris";
import { PrivateBrandingContext, PrivateContext } from "lib/context";
import { useMutation } from "@apollo/react-hooks";
import { VIEW_DASHBOARD } from "app/onboarding/apollo/mutations";
import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";

const { DEFAULT_ONBOARDING_FINISH_TITLE, FINISH_DEFAULT_IMAGE } = constant;

const Finish = () => {
  const { currentUser, currentUserRefetch, isLoading, cms = {}, setIsOnboarding } = useContext(PrivateContext);
  const { branding, brandingLoading } = useContext(PrivateBrandingContext);
  const { brand } = branding || {};
  const { finish } = brand || {};
  const { cover: finishCover, description: finishDescription } = finish || {};
  const { imageUrl: finishImage = "" } = finishCover || {};
  const { description: finishDesc = "" } = finishDescription || {};

  const [accessDashboard, { loading: accessDashboardLoading }] = useMutation(VIEW_DASHBOARD);

  const onSubmit =  () => {
     accessDashboard();
    currentUserRefetch();
    setIsOnboarding({ cms: "dashboard", module: "dashboard" });
  };

  if (brandingLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <MediaCard
      // title={!finishDesc && cms("common.onboarding.vendorFinish.title")}
      title={DEFAULT_ONBOARDING_FINISH_TITLE}
      primaryAction={{
        content: cms("common.onboarding.finish.button.primary"),
        onAction: () => {
          onSubmit();
        },
        primary: true,
        loading: accessDashboardLoading || isLoading,
      }}
      description={finishDesc ? parse(finishDesc) : constant.DEFAULT_FINISH_DESCRIPTION}
    >
      <img
        className="onboarding-thumbnail"
        src={finishImage || FINISH_DEFAULT_IMAGE}
        alt={DEFAULT_ONBOARDING_FINISH_TITLE}
      />
    </MediaCard>
  );
};

export default Finish;
