import { gql } from "apollo-boost";

const UPDATE_COMMISSION = gql`
  mutation Commission($input: UpdateCommission!) {
    updateCommission(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_COMMISSION;
