import React, { useCallback, useContext, useEffect, useState } from "react";
import { Badge, Caption, Card, Collapsible, SkeletonBodyText, Stack, TextStyle } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// context
import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner, MultiCarousel, ReportCardSkeleton } from "lib/components";

import { GET_ALL_COUNTS, GET_LAKE_COUNTS } from "app/reports/apollo/queries";
import { INVITE_DATA, ORDER_DATA, PAYMENT_DATA, PRODUCT_DATA, PROVIDER_DATA } from "app/reports/apollo/subscriptions";

import ReportCard from "app/reports/generic/style/reportCard";

import config from "./quickReportConfig";

const {
  gql: { GET_COUNT, GET_LAKE_COUNT, INVITE_COUNT, ORDER_COUNT, PAYMENT_COUNT, PRODUCT_COUNT, VENDOR_COUNT },
  DOWN,
  UP,
} = constant;

const OperatorQuickReport = () => {
  const { cms, currentUser, match } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const { path } = match || {};

  const ecommercePlatformArray = [constant.WOOCOMMERCE, constant.BIGCOMMERCE];

  const { data: dashboardData, loading: dashboardLoading, error: dashboardError, subscribeToMore } = useQuery(
    ecommercePlatformArray.includes(ecommercePlatform) ? GET_LAKE_COUNTS : GET_ALL_COUNTS
  );

  const [openQuickReport, setQuickReport] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [count, setCount] = useState({
    inviteCount: {},
    orderCount: {},
    paymentCount: {},
    productCount: {},
    vendorCount: {},
  });
  useEffect(() => {
    if (dashboardError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(dashboardError),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardError, errorHelper]);
  useEffect(() => {
    const responseData = baseHelper.getResponseData(
      dashboardData,
      ecommercePlatformArray.includes(ecommercePlatform) ? GET_LAKE_COUNT : GET_COUNT
    );
    const responseError = baseHelper.getResponseError(
      dashboardData,
      ecommercePlatformArray.includes(ecommercePlatform) ? GET_LAKE_COUNT : GET_COUNT
    );
    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const {
      inviteCount = null,
      orderCount = null,
      paymentCount = null,
      productCount = null,
      vendorCount = null,
    } = responseData;
    setCount({
      inviteCount: inviteCount || {},
      orderCount: orderCount || {},
      paymentCount: paymentCount || {},
      productCount: productCount || {},
      vendorCount: vendorCount || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const createSubscribeToMore = useCallback(
    ({ document, responseKey }) => {
      subscribeToMore({
        document,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }
          const responseData = baseHelper.getResponseData(subscriptionData.data, responseKey);
          if (!responseData) {
            const responseError = baseHelper.getResponseError(subscriptionData.data, responseKey);
            setBanner({
              isOpen: true,
              status: constant.CRITICAL,
              title: responseError,
            });
            return null;
          }
          setCount((prevState) => ({
            ...prevState,
            [responseKey]: responseData[responseKey] || {},
          }));
          return true;
        },
      });
    },
    [subscribeToMore]
  );

  useEffect(() => {
    switch (path) {
      case "/":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        createSubscribeToMore({ document: PROVIDER_DATA, responseKey: VENDOR_COUNT });
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        createSubscribeToMore({ document: PAYMENT_DATA, responseKey: PAYMENT_COUNT });
        createSubscribeToMore({ document: INVITE_DATA, responseKey: INVITE_COUNT });
        break;
      case "/invitation":
        createSubscribeToMore({ document: INVITE_DATA, responseKey: INVITE_COUNT });
        break;
      case "/orders":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        break;
      case "/products":
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        break;
      case "/payments":
        createSubscribeToMore({ document: PAYMENT_DATA, responseKey: PAYMENT_COUNT });
        break;
      case "/providers":
        createSubscribeToMore({ document: PROVIDER_DATA, responseKey: VENDOR_COUNT });
        break;
      default:
        break;
    }
  }, [createSubscribeToMore, path]);

  // data taken from report config
  const quickReportData = config({ cms, count, path });

  const isHome = path === "/";

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const noDataAvailable = (
    <Card sectioned key="noData">
      No data available
    </Card>
  );

  const getQuickReportList = () => {
    if (!quickReportData) {
      return noDataAvailable;
    }
    const loading = <SkeletonBodyText lines={3} />;
    const dataStack = (quickReport) => (
      <>
        {(isHome && (
          <Stack>
            <Stack.Item fill>
              <TextStyle>{quickReport.label}</TextStyle>
              <Caption>{quickReport.caption}</Caption>
            </Stack.Item>
            <Stack.Item>
              <Badge status={constant.SUCCESS}>{quickReport.count}</Badge>
            </Stack.Item>
          </Stack>
        )) || (
          <Card>
            <TextStyle variation="strong">{quickReport.count}</TextStyle>
            <span className="card-title">{quickReport.label}</span>
          </Card>
        )}
      </>
    );
    const reportData = quickReportData.map((quickReport, index) => {
      const { count: reportCount = null } = quickReport;
      if (!reportCount && reportCount !== 0) {
        return null;
      }
      return (
        <>
          {isHome && index > 0 && index !== quickReportData.length && <br />}
          {dashboardLoading ? loading : dataStack(quickReport)}
        </>
      );
    });

    const filteredReportData = reportData.filter((data) => data);

    if (filteredReportData.length) {
      return (
        (isHome && filteredReportData) || (
          <ReportCard>
            <MultiCarousel containerClass="quick-reports" slideItems={filteredReportData} />
          </ReportCard>
        )
      );
    }

    return false;
  };

  const home = () => {
    return (
      (isHome && dashboardLoading && (
        <>
          <br />
          <SkeletonBodyText lines={3} />
        </>
      )) || (
        <>
          {banner.isOpen && (
            <>
              <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
              <br />
            </>
          )}
          <Card>
            <Card.Header
              title={cms("common.label.quickReport")}
              actions={[
                {
                  content: openQuickReport ? cms("common.label.hide") : cms("common.label.show"),
                  onAction: () => setQuickReport(!openQuickReport),
                  disclosure: openQuickReport ? UP : DOWN,
                },
              ]}
            />
            <br />
            <Collapsible open={openQuickReport} id="collapsibleQuickReport">
              <Card>
                <Card.Section subdued>{getQuickReportList()}</Card.Section>
              </Card>
            </Collapsible>
          </Card>
        </>
      )
    );
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
          <br />
        </>
      )}
      {(isHome && home()) ||
        (dashboardLoading && <ReportCardSkeleton cardCount={quickReportData.length} />) ||
        getQuickReportList()}
    </>
  );
};

export default OperatorQuickReport;
