import { gql } from "apollo-boost";

const ORDER_LAKE_LIST = gql`
  subscription {
    orderLakeLineView {
      status
      error
      data {
        operationType
        orderLakeLine {
          _id
          price
          accountingStatus
          shippingStatus
          isVendorFulfilled
          baseCostPrice
          basePrice
          baseTotal
          baseWrappingCost
          binPickingNumber
          cost {
            priceExTax
            priceIncTax
            totalExTax
            totalIncTax
            wrappingExTax
          }
          ecommercePlatform
          eventName
          fixedShippingCost
          isBundledProduct
          isRefunded
          name
          priceExTax
          priceIncTax
          priceTax
          quantity
          quantityShipped
          sku
          storeEbayItemId
          storeEbayTransactionId
          storeEventDate
          storeExternalId
          storeOptionSetId
          storeOrderAddressId
          storeOrderId
          storeParentOrderProductId
          storeLineItemId
          storeReturnId
          storeVariantId
          tax {
            costPrice
            total
          }
          type
          upc
          weight
          wrappingMessage
          wrappingName
          createdBy
          isSynced
          commissionSysAdmin
          commissionSeller
          commissionVendor
          dueDate
          image
          productId
          ownerId
          orderId
          updatedBy
          providerId
          storeStatus
          dispatchedDate
          trackingCompany
          trackingNumber
          createdAt
          updatedAt
          shippingAddress
          currency
          brandName
          shippingData {
            _id
            storeShippingAddressId
            addressLineOne
            addressLineTwo
          }
          commissionType
        }
      }
    }
  }
`;
export default ORDER_LAKE_LIST;
