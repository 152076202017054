import PropTypes from "prop-types";

const priceProp = {
  type: {
    lastUpdated: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
    setRate: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    radioButtonValue: PropTypes.string.isRequired,
    cms: PropTypes.func.isRequired,
    learnMore: PropTypes.func.isRequired,
    setBannerStatus: PropTypes.func.isRequired,
  },
};

export default priceProp;
