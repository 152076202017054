import React, { useContext } from "react";
import { Card, Checkbox, TextField, Select, Layout, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { Banner } from "lib/components";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "../../../context/context";

const {
  SELECT_WEIGHT = "",
  SHIPPING = "",
  WEIGHT = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999, MAX_MEASUREMENT = 999 },
} = constant;

const Shipping = () => {
  const { cms } = useContext(PrivateContext);
  const { handleChange, data, learnMore } = useContext(FormContext);
  const options = ["lb", "oz", "kg", "g"];

  const selectWeight = (
    <Select
      id="selectWeight"
      value={data.weightUnit}
      label={cms("section.form.section.shipping.placeholder.weightUnit")}
      onChange={(value) => handleChange(SELECT_WEIGHT, value)}
      placeholder={cms("section.form.section.shipping.placeholder.selectUnit")}
      labelHidden
      options={options}
    />
  );

  const acceptOnlyValidInput = (value, measurementValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && measurementValue);
  };

  const renderMeasurement = () => {
    return (
      <>
        <TextField
          id="length"
          label={cms("section.form.section.shipping.label.length")}
          value={data.length || ""}
          placeholder={cms("section.form.section.shipping.placeholder.length")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleChange(constant.LENGTH, acceptOnlyValidInput(value, data.length));
          }}
        />
        <br />
        <TextField
          id="width"
          label={cms("section.form.section.shipping.label.width")}
          value={data.width || ""}
          placeholder={cms("section.form.section.shipping.placeholder.width")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleChange(constant.WIDTH, acceptOnlyValidInput(value, data.width));
          }}
        />
        <br />
        <TextField
          id="height"
          label={cms("section.form.section.shipping.label.height")}
          value={data.height || ""}
          placeholder={cms("section.form.section.shipping.placeholder.height")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleChange(constant.HEIGHT, acceptOnlyValidInput(value, data.height));
          }}
        />
      </>
    );
  };

  return (
    // <Layout.AnnotatedSection
    //   title={cms("section.form.section.shipping.title")}
    //   description={cms("section.form.section.shipping.description")}
    // >
    <Card
      title={cms("section.form.section.shipping.title")}
      id="productShipping"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productShipping", cms("section.form.section.shipping.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label={cms("section.form.section.shipping.label.isPhysicalProduct")}
          checked={data.shipping || ""}
          onChange={(value) => handleChange(SHIPPING, value)}
        />
        {!data.shipping && (
          <div>
            <br />
            <Banner isOpen status="info" isScrollTop={false}>
              <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {data.shipping && (
        <Card.Section title={cms("section.form.section.shipping.label.weight")}>
          <p>{cms("section.form.section.shipping.label.description")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.form.section.shipping.label.weight")}
            type="number"
            value={data.weight || ""}
            placeholder={cms("section.form.section.shipping.placeholder.weightUnit")}
            min={MIN_PRICE}
            max={MAX_FLAT}
            disabled={!data.weightUnit}
            onChange={(value) => {
              handleChange(WEIGHT, acceptOnlyValidInput(value, data.weight));
            }}
            connectedRight={selectWeight}
          />
          <br />
          {renderMeasurement()}
        </Card.Section>
      )}
    </Card>
    // </Layout.AnnotatedSection>
  );
};

export default Shipping;
