import React, { useState, useContext, useEffect } from "react";
import { Tabs, Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import private context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { GET_SELLER_SHIPPING, GET_SHIPPING_LAKE_PRODUCT } from "app/shipping/apollo/queries";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import components
import { Banner, Spinner } from "lib/components";
import Offers from "./offers/offers";
import Rules from "./rules/rules";

// import advance shipping provider
import { AdvanceShippingProvider } from "./context/context";

const ProviderAdvanceShipping = () => {
  const { history, currentUser, cms } = useContext(PrivateContext);
  const { _id: userId } = currentUser;
  const [selectedTab, setSelectedTab] = useState(0);
  const [banner, setBanner] = useState({ isOpen: false, title: "", message: "", status: "" });
  const [sellerShipping, setSellerShipping] = useState({});

  const [isShippingBandAvailable, setIsShippingBandAvailable] = useState(false);

  const { loading: shippingLoading, data: shippingData, error } = useQuery(GET_SELLER_SHIPPING);

  const tabs = [
    {
      id: constant.MANAGE_RULE,
      content: cms("tab.rule"),
      accessibilityLabel: cms("tab.rule"),
      panelID: constant.MANAGE_RULE,
    },
    {
      id: constant.MANAGE_OFFER,
      content: cms("tab.offer"),
      accessibilityLabel: cms("tab.offer"),
      panelID: constant.MANAGE_OFFER,
    },
  ];

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(error) });
      return;
    }
    const responseError = baseHelper.getResponseError(shippingData, constant.gql.GET_SELLER_SHIPPING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    const responseData = baseHelper.getResponseData(shippingData, constant.gql.GET_SELLER_SHIPPING);
    const { sellerShipping: shipping = {}, isShippingBandAvailable: isBandAvailable = false } = responseData || {};
    setSellerShipping(shipping);
    setIsShippingBandAvailable(isBandAvailable);
  }, [shippingData, error]);

  const { loading: productLakeLoading, data: productLakeData, error: productLakeError } = useQuery(
    GET_SHIPPING_LAKE_PRODUCT
  );

  const productLakeDataError = baseHelper.getResponseError(productLakeData, constant.gql.GET_LAKE_PRODUCTS);

  const productLakeDataResponse = baseHelper.getResponseData(productLakeData, constant.gql.GET_LAKE_PRODUCTS) || [];

  if (shippingLoading || productLakeLoading) {
    return <Spinner />;
  }

  const { term, allowVendor = constant.ALL, vendorIds = [] } = sellerShipping || {};
  const isVendorManageShipping = term === constant.VENDOR_MANAGE || {};
  const isVendorAllowed =
    allowVendor === constant.ALL || (allowVendor === constant.SELECTED && vendorIds.includes(userId));

  const bannerValues = () => {
    const banners = [];
    banners.push({
      isOpen: banner.isOpen,
      status: banner.status,
      title: banner.title,
      message: banner.message,
      key: constant.DEFAULT,
      onDismiss: () => setBanner({ isOpen: false, title: "", status: "", message: "" }),
    });
    if (!isVendorManageShipping) {
      banners.push({
        key: "vendorManageShipping",
        isOpen: true,
        status: constant.WARNING,
        title: cms("message.enable.title"),
        message: cms("message.enable.content"),
        action: baseHelper.bannerButtonAction(
          true,
          {
            message: cms("message.enable.action"),
            to: "/shipping",
          },
          history
        ),
      });
    }

    if (isVendorManageShipping && !isShippingBandAvailable) {
      banners.push({
        key: "addShippingBand",
        isOpen: true,
        status: constant.WARNING,
        title: cms("message.add.title"),
        message: cms("message.add.content"),
        action: baseHelper.bannerButtonAction(
          false,
          {
            message: cms("message.add.action"),
            to: "/products",
          },
          history
        ),
      });
    }
    return banners;
  };

  const renderBanners = () => {
    const banners = bannerValues();

    if (!banners.length) {
      return null;
    }

    return banners.map((item) => {
      return (
        item.isOpen && (
          <Layout.Section key={item.key}>
            <Banner
              isOpen={item.isOpen}
              status={item.status}
              title={item.title}
              action={item.action || null}
              onDismiss={item.onDismiss || null}
            >
              {banner.message || null}
            </Banner>
          </Layout.Section>
        )
      );
    });
  };

  return (
    <>
      {renderBanners()}
      <Layout.Section>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
      </Layout.Section>
      <Layout.Section>
        <AdvanceShippingProvider value={{ setBannerOuter: setBanner, isVendorAllowed }}>
          {selectedTab === 0 && (
            <Rules productLakeDataError={productLakeDataError} productLakeDataResponse={productLakeDataResponse} />
          )}
          {selectedTab === 1 && <Offers />}
        </AdvanceShippingProvider>
      </Layout.Section>
    </>
  );
};
export default withFeature(withErrorBoundary(ProviderAdvanceShipping), { feature: constant.ADVANCE_SHIPPING });
