import { gql } from "apollo-boost";

const GET_DISCOUNT_SETTING = gql`
  query {
    getSellerDiscountSetting {
      data {
        isAllowed
        isAllVendor
        selectedVendor
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_DISCOUNT_SETTING;
