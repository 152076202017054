import React from "react";
import { Select, Stack, Button } from "@shopify/polaris";
import { getCode } from "country-list";
import { withErrorBoundary } from "lib/hoc";

const AddMapperField = (props) => {
  const { cms, country, updateVal, removeItem, countryList } = props;
  const selectCountry = (data, id) => {
    return (
      <>
        <br />
        <Stack wrap={false} spacing="extraTight" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={countryList}
                placeholder={cms("placeholder.selectcountry")}
                value={data.value}
                onChange={(val) => {
                  updateVal(val, id, getCode(val));
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button plain onClick={() => removeItem(id)}>
              <i className="far fa-trash fa-lg" style={{ color: "red" }} />
            </Button>
          </Stack.Item>
        </Stack>
      </>
    );
  };

  return (
    <>
      {country &&
        country.map((value, index) => {
          return selectCountry(value, index);
        })}
      <br />
    </>
  );
};

export default withErrorBoundary(AddMapperField);
