import PropTypes from "prop-types";

const listProp = {
  type: {
    item: PropTypes.arrayOf(PropTypes.object),
  },
};
const filterProp = {
  type: {
    handleFilterClick: PropTypes.func,
  },
};

const footerProp = {
  type: {
    count: PropTypes.number,
    changePage: PropTypes.func,
    changePageLimit: PropTypes.func,
    page: PropTypes.number,
    perPage: PropTypes.number,
  },
};

export { listProp, footerProp, filterProp };
