import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";

import { OperatorApprovalSetting } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;

const ProductApprovalSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorApprovalSetting />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductSettingComponent = getComponent(currentUserRole);
  return ProductSettingComponent;
};

export default withFeature(withErrorBoundary(ProductApprovalSetting));
