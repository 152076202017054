import React, { useContext } from "react";
import { Badge, Caption, Card, Link, List, TextStyle, Thumbnail, Stack } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper, statusHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const RejectedLineItems = (props) => {
  const { data = {}, lineItemArray = [], refundLineData = [], setRejectAmount } = props;
  const { cms, history, userData } = useContext(PrivateContext);
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);
  const amount = [];
  const vendorsSum = [];
  let countItems = 0;

  refundLineData &&
    refundLineData.length &&
    refundLineData.forEach((dataLine) => {
      const { totalPrice = 0, line_item: itemLine = {}, totalquantity = 0 } = dataLine || {};
      const { price = 0, vendor } = itemLine || {};
      vendorsSum.push(vendor);
      amount.push(totalPrice);
      countItems += 1;
      return [amount];
    });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  const isVendor = baseHelper.isVendor(currentUserData);

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  if (totalAmount) {
    setRejectAmount(totalAmount);
  }

  const lineItems =
    refundLineData &&
    refundLineData.length &&
    refundLineData.map((lineData, idx) => {
      const { line_item: itemLine = {}, line_item_id: shopifyLineItemId = "", totalPrice, totalQuantity } =
        lineData || {};
      const { sku = "", title = "" } = itemLine || {};

      const getItem = lineItemArray.find((item) => item.shopifyLineItemId === shopifyLineItemId);
      const {
        fulfillmentType = "",
        image = "",
        isAutoExpiry,
        isAutoReject,
        isProductDeleted,
        isRefund,
        isVendorDeleted,
        moneyFormat: lineMoneyFormat,
        productId,
        properties,
        vendor,
        vendorId,
      } = getItem || {};

      const lineItemKey = `lineItem${idx}`;
      const lineImage = imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;
      const autoStatus = isRefund && isAutoReject ? constant.AUTO_REJECT : isAutoExpiry && constant.NO_SHOW;
      const status = statusHelper.getBadgeStatus(autoStatus || constant.REJECTED);
      const isServiceLineItem = baseHelper.isServiceLineItem(fulfillmentType, properties);
      const productURL = isServiceLineItem ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;

      const lineItemName = <div className="productName">{title}</div>;

      return (
        <div key={lineItemKey} className="fulfilled-Secn">
          <Card>
            <Card.Section subdued>
              <StyledCard className="styled-card-unfulfill">
                <Stack wrap={false}>
                  <Stack.Item>
                    <Thumbnail size="large" source={lineImage} alt={title} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="ellipsis">
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(productURL)}>{title || ""}</Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>
                          {vendor && vendorId && `${cms("label.vendor")}: ${vendor}`}
                        </TextStyle>
                        <LineItemProperties
                          variation={constant.SUBDUED}
                          properties={properties}
                          isServiceLineItem={isServiceLineItem}
                        />
                        {isServiceLineItem
                          ? ""
                          : sku && (
                          <TextStyle variation={constant.SUBDUED}>
                                {cms("label.skuList")}
                                {sku}
                              </TextStyle>
                            )}
                      </Caption>

                      <Badge status="critical" className="text-capitalize">
                        {status}
                      </Badge>
                    </div>
                  </Stack.Item>
                  <Stack.Item>{` ${cms("label.qty")}: ${totalQuantity}`}</Stack.Item>
                  <Stack.Item>
                    {moneyFormat}
                    {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
                  </Stack.Item>
                </Stack>
              </StyledCard>
            </Card.Section>
          </Card>
        </div>
      );
    });

  const moneyFormat = data && data.moneyFormat;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            {" "}
            (
            {countItems > 1
              ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {`${moneyFormat || constant.symbol.DOLLAR}${baseHelper.getPrice(totalAmount)}`}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );

  return [lineItems, totalFulfill];
};

export default RejectedLineItems;
