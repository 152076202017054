import { gql } from "apollo-boost";

const SESSION_LIST = gql`
  subscription {
    sessionList {
      data {
        sessionList {
          _id
          login
          logout
          userRole
          brandName
          userId
          device
        }
        operationType
      }
    }
  }
`;

export default SESSION_LIST;
