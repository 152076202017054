import React from "react";

// import generic
import { ExportPDF } from "./generic";

const ExportPaymentAsPDF = () => {
  const getComponent = () => {
    return <ExportPDF />;
  };
  const ExportPaymentComponent = getComponent();
  return ExportPaymentComponent;
};

export default ExportPaymentAsPDF;
