import { gql } from "apollo-boost";

const GET_SELLER_FEATURE = gql`
  query getSellerFeature($input: GetSellerFeature!) {
    getSellerFeature(input: $input) {
      data {
        isBranding
        maxProducts
        maxVendors
      }
      status
      error
    }
  }
`;

export default GET_SELLER_FEATURE;
