import { gql } from "apollo-boost";

const GET_TAX_SETTING = gql`
  query {
    getTaxSetting {
      data {
        shipping {
          isTaxInclusive
          value
          updatedAt
        }
        commission {
          value
          updatedAt
        }
      }
      error
      status
    }
  }
`;

export default GET_TAX_SETTING;
