import React, { useContext, useState, useEffect } from "react";
import { Banner, Button, Heading, Link, Select, Stack, TextContainer } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import { useQuery } from "react-apollo";

import { GET_SHIPPO_CARRIER, GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";

const CarrierService = () => {
  const { cms } = useContext(PrivateContext);
  const { dropship, isEcommerceUser, setDropship, setSubmitEnable, setIsError, selectedTab, type } = useContext(
    FulfillmentContext
  );

  const [shippoList, setShippoList] = useState();
  const [vendorList, setVedorList] = useState();

  const [carrierService, setCarrierService] = useState(
    (dropship && dropship.shippo && dropship.shippo.vendorCarrier) || []
  );

  const [defaultCarrier, setDefaultCarrier] = useState(
    (dropship && dropship.shippo && dropship.shippo.defaultCarrier) || {}
  );
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const inputData = { isServiceLevel: true };
  if (type === constant.HYBRID) {
    inputData.modalType = selectedTab === 1 ? "dropship" : "consignment";
  }

  const { error: shippoError, loading: shippoLoading, data: shippoData } = useQuery(GET_SHIPPO_CARRIER, {
    variables: {
      input: inputData,
    },
  });

  useEffect(() => {
    if (shippoError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(shippoError) });
    }
    if (!shippoData) {
      return;
    }
    const shippoCarrierData = baseHelper.getResponseData(shippoData, constant.gql.GET_SHIPPO_CARRIER);
    if (!shippoCarrierData) {
      return;
    }

    if (shippoCarrierData) {
      const shippoDataList = shippoCarrierData.map((item) => {
        return {
          label: item.name,
          name: item.name,
          value: item.service,
          service: item.service,
        };
      });
      setShippoList(shippoDataList);
    }

    const shippoCarrierError = baseHelper.getResponseError(shippoData, constant.gql.GET_SHIPPO_CARRIER);
    if (shippoCarrierError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: shippoCarrierError });
    }
  }, [shippoData, shippoError]);

  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  useEffect(() => {
    if (vendorAndProductTypesError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(vendorAndProductTypesError) });
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, constant.gql.GET_VENDOR_PRODUCT);
    const { supplierRows = [] } = responseData;

    if (!responseData) {
      return;
    }

    const selectedSuppliers = [];
    if (dropship && dropship.shippo && dropship.shippo.vendorCarrier && dropship.shippo.vendorCarrier.length) {
      dropship.shippo.vendorCarrier.map((data) => selectedSuppliers.push(data.vendorId));
    }
    const suppliers = supplierRows.filter((item) => {
      const { _id: id } = item;
      const itemData = item;
      if (itemData.brandName) {
        if (!selectedSuppliers.includes(id)) {
          itemData.disabled = false;
        }
        if (selectedSuppliers.includes(id)) {
          itemData.disabled = true;
        }
        return itemData;
      }
      return null;
    });

    const formattedSuppliers = suppliers
      .filter((item) => item.brandName && item)
      .map(({ _id: vendorId, brandName, disabled }) => ({
        label: brandName,
        value: vendorId,
        disabled,
      }));

    setVedorList(formattedSuppliers);
  }, [dropship, setVedorList, vendorAndProductTypesData, vendorAndProductTypesError, vendorAndProductTypesLoading]);

  const addItem = () => {
    const shippoItem = [...carrierService];
    if (shippoItem.length > 0) {
      const item = shippoItem[shippoItem.length - 1];
      const keys = Object.keys(item);
      let isInvalid = false;
      keys.forEach(() => {
        if (!item.vendorId || !item.id) {
          isInvalid = true;
        }
      });

      if (isInvalid) {
        setIsError(isInvalid);
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
      } else
        shippoItem.push({
          id: "",
          name: "",
          vendorId: "",
          service: "",
        });
      setCarrierService(shippoItem);
    }
    const isInvalidRow =
      shippoItem.length && !!shippoItem.find((element) => !element.id || !element.vendorId || !element.service);
    setIsError(isInvalidRow);
    if (!shippoItem.length) {
      setCarrierService([{ id: "", name: "", vendorId: "", service: "" }]);
    }
  };

  const handleChange = (field, value, index) => {
    const oldShippo = (dropship && dropship.shippo) || {};
    const shippoItem = [...carrierService];
    shippoItem[index][field] = value;
    const updateVendorCarrier = [];
    carrierService.map((element) => {
      const shippoListItem = shippoList.find((item) => item.value === element.id);
      if (element.id && element.vendorId && shippoListItem) {
        updateVendorCarrier.push({
          id: shippoListItem.value,
          name: shippoListItem.name,
          service: shippoListItem.service,
          vendorId: element.vendorId,
        });
        shippoItem[index].name = shippoListItem.name;
        shippoItem[index].service = shippoListItem.service;
      }
      return null;
    });

    const isInvalidRow = !!carrierService.find((item) => !(item.id && item.vendorId));

    setIsError(isInvalidRow);

    setCarrierService([...shippoItem]);
    setDropship((prevState) => ({
      ...prevState,
      shippo: { ...oldShippo, vendorCarrier: updateVendorCarrier || carrierService },
    }));
    setSubmitEnable(true);
  };

  const removeItem = (id) => {
    const shippoItem = [...carrierService];
    const oldShippo = (dropship && dropship.shippo) || {};
    shippoItem.splice(id, 1);
    setCarrierService(shippoItem);
    setDropship((prevState) => ({
      ...prevState,
      shippo: { ...oldShippo, vendorCarrier: [...shippoItem] },
    }));
    const isInvalidRow =
      shippoData.length && !!shippoItem.find((element) => !element.id || !element.vendorId || !element.service);
    setIsError(isInvalidRow);

    setSubmitEnable(true);
  };

  const handleDefaultChange = (value) => {
    const oldShippo = (dropship && dropship.shippo) || {};
    const updateDefaultCarrierData =
      shippoList && shippoList.find(({ value: carrierServiceValue }) => carrierServiceValue === value);
    let updateValue = {};
    if (updateDefaultCarrierData) {
      updateValue = {
        name: updateDefaultCarrierData.name,
        id: updateDefaultCarrierData.value,
        service: updateDefaultCarrierData.service,
      };
    }
    setDefaultCarrier((prevState) => ({
      ...prevState,
      id: value,
    }));
    setDropship((prevState) => ({
      ...prevState,
      shippo: { ...oldShippo, defaultCarrier: updateValue || defaultCarrier },
    }));
    setSubmitEnable(true);
  };

  // set default shippo carrier SDA defined in constant
  useEffect(() => {
    const selectedCarrier =
      shippoList && shippoList.find(({ service: carrierName }) => carrierName === constant.SENDABOX_DEFAULT_CARRIER);
    if (selectedCarrier && dropship.shippo && !dropship.shippo.defaultCarrier) {
      handleDefaultChange(selectedCarrier.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippoList]);

  const selectVendor = (data, id) => {
    return (
      <>
        <br />
        <Stack wrap={false} spacing="extraTight" alignment="center" key={id}>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={vendorList}
                placeholder={cms("placeholder.vendors")}
                value={carrierService && carrierService.length && carrierService[id].vendorId}
                onChange={(value) => {
                  handleChange(constant.VENDOR_ID, value, id);
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={shippoList}
                placeholder={cms("placeholder.carrier")}
                value={carrierService && carrierService.length && carrierService[id].id}
                onChange={(value) => handleChange(constant.ACCESS_KEY_ID, value, id)}
              />
            </div>
          </Stack.Item>
          <Button size="plain" onClick={() => removeItem(id)}>
            <i className="far fa-trash fa-lg" style={{ color: constant.color.RED }} />
          </Button>
        </Stack>
      </>
    );
  };

  if (shippoLoading) {
    return <Spinner size="small" />;
  }
  return (
    <div>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <Select
        label={`${cms("label.default")}*`}
        options={shippoList}
        placeholder={cms("placeholder.carrier")}
        value={(defaultCarrier && defaultCarrier.id) || "shippipro carrier"}
        onChange={(value) => handleDefaultChange(value)}
      />
      {!isEcommerceUser && (
        <>
          <br />
          <TextContainer spacing="tight">
            <Heading>{`${cms("label.associate")}`}</Heading>
          </TextContainer>
          {carrierService &&
            carrierService.map((value, index) => {
              return selectVendor(value, index);
            })}
          <br />
          <Link id="addLink" onClick={() => addItem()}>
            {cms("button.vendor")}
          </Link>
        </>
      )}
    </div>
  );
};

export default withErrorBoundary(CarrierService);
