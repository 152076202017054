import React, { useContext } from "react";
import { Card, Collapsible, Caption, Layout, TextContainer, TextStyle } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const FinishCover = () => {
  const { FINISH_COVER, ONBOARD_IMAGE_TYPE } = constant;
  const { finishCoverImage, handleChange } = useContext(BrandingContext);
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { brand } = currentUser || {};
  const { finish, updatedAt: brandUpdatedAt = "" } = brand || {};
  const { cover: finishCover } = finish || {};
  const { updatedAt = "" } = finishCover || {};
  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.finishCover.title")}
        description={cms("section.finishCover.description")}
      >
        <Card
          title={[
            cms("section.finishCover.card.title"),
            updatedAt ? (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ) : (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(brandUpdatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.finishCover.card.subDescription")}</TextContainer>
            <br />
            <DropZone
              size={10}
              allowedTypes={ONBOARD_IMAGE_TYPE}
              id="dropZone"
              onAdd={(value) => handleChange(FINISH_COVER, (value && value.length && value[0]) || "", FINISH_COVER)}
              onRemove={() => handleChange(FINISH_COVER, "", FINISH_COVER)}
              fileList={(finishCoverImage !== "" && [finishCoverImage]) || []}
              allowMultiple={false}
            />
            <br />
            <Caption>{cms("section.finishCover.card.caption")}</Caption>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(FinishCover), {
  feature: constant.STANDARD_BRANDING,
});
