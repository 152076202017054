import React, { useContext } from "react";
import { Badge, Button, Card, DisplayText, List, Stack } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const Heading = ({ isActive = false, monthlyFee = "", name = "", period = "", periodUnit = "" }) => {
  const { cms } = useContext(PrivateContext);

  return (
    <Stack alignment="center" wrap={false}>
      <Stack.Item fill>
        <DisplayText size="small" element="h1">
          <Stack>
            <Stack.Item fill>
              {name.toUpperCase()}
              {isActive && <Badge status={constant.INFO}>{cms("label.active")}</Badge>}
            </Stack.Item>
          </Stack>
        </DisplayText>
      </Stack.Item>
      <Stack.Item>
        <DisplayText size="large">
          {" "}
          <Stack>
            <Stack.Item fill>
              {monthlyFee}
              <br />
              {period && (
                <Badge status={constant.INFO}>{`${period} ${periodUnit}${Number(period) > 1 ? "s" : ""}`}</Badge>
              )}
            </Stack.Item>
          </Stack>
        </DisplayText>
      </Stack.Item>
    </Stack>
  );
};

const PlanCard = ({ plan = {}, callback = () => {}, activePlan = "", submitLoading = false }) => {
  const { cms, history } = useContext(PrivateContext);

  const {
    code = "",
    currencyCode = "",
    description = "",
    isPopular,
    name = "",
    period = "",
    periodUnit = "",
    price = "",
    productLimit = "",
    trialPeriod,
    trialPeriodUnit = "",
  } = plan || {};

  const isUpdatePlanPage = history && history.location && history.location.pathname === "/update/plan";
  const buttonText = isUpdatePlanPage ? cms("button.update") : cms("common.placeholder.select");

  const isActivePlan = activePlan === plan.code;
  const isDisabled = isUpdatePlanPage ? isActivePlan : !!activePlan;

  return (
    <Card key={code}>
      <Card.Section>
        <Heading
          code={code}
          period={period}
          periodUnit={periodUnit}
          isActive={isActivePlan}
          name={name}
          monthlyFee={`$${price}`}
        />
      </Card.Section>
      <Card.Section>
        <p>{description}</p>
        <br />
        <List>
          <List.Item key="sellerPlanProduct">{`${cms("common.label.productLimit")}: ${productLimit}`}</List.Item>
          <List.Item key="sellerPlanPeriod">{`${cms("common.label.plan")}: ${period} ${periodUnit}${Number(period) > 1 ? "s" : ""}`}</List.Item>
          <List.Item key="sellerPlanTrial">{`${cms("common.label.trial")}: ${trialPeriod} ${trialPeriodUnit}${Number(trialPeriod) > 1 ? "s" : ""}`}</List.Item>
        </List>
        <br />
        <Button primary fullWidth onClick={() => callback()} disabled={isDisabled} loading={submitLoading}>
          {buttonText}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default PlanCard;
