import { gql } from "apollo-boost";

const SELLER_LIST = gql`
  subscription {
    getSellerList {
      data {
        seller {
          email {
            address
            verified
          }
          brandName
          createdAt
          plan {
            name
            code
          }
          updatedAt
          _id
          roles {
            name
          }
        }
      }
    }
  }
`;

export default SELLER_LIST;
