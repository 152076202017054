import { gql } from "apollo-boost";

const UPDATE_VENDOR_LOCATION = gql`
  mutation updateVendorLocation($input: VendorLocationSetting) {
    updateVendorLocation(input: $input) {
      data
      error
      status
    }
  }
`;

export default UPDATE_VENDOR_LOCATION;
