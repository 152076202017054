import { gql } from "apollo-boost";

const PRODUCT_LAKE_LIST = gql`
  subscription {
    productLakeList {
      status
      data {
        operationType
        product {
          _id
          createdAt
          updatedAt
          ecommercePlatform
          name
          type
          published
          primaryUrl {
            imageUrl
            imageId
          }
          variants
          status
          ownerId
          vendorId
          vendor {
            brandName
          }
        }
      }
      error
    }
  }
`;

export default PRODUCT_LAKE_LIST;
