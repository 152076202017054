import React, { useContext, useState } from "react";
import {
  Badge,
  Caption,
  Card,
  Link,
  TextStyle,
  Thumbnail,
  Stack,
  Icon,
  Button,
  ButtonGroup,
  FormLayout,
  Checkbox,
} from "@shopify/polaris";
import { NoteMajor, QuickSaleMajor, ReturnMinor } from "@shopify/polaris-icons";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper } from "lib/helpers";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";
import Popover from "app/orders/modules/provider/features/view/features/popover";

const ReadyForCollect = (props) => {
  const {
    itemData,
    openClickReserveModal,
    refundData,
    selectedItems,
    setSelectedItems,
    setSelectedReserveItemData,
    selectedReserveItemData,
    setIsBulkReserveChecked,
    isBulkReserveChecked,
    setBulkQuantity,
    bulkQuantity,
    setActiveReserveCollect,
  } = props;
  const { cms, history, match, userData } = useContext(PrivateContext);

  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const [activePopover, setActivePopover] = useState({});

  const handleChange = (value) => {
    const { fulfillmentStatus, _id: id, collectDetail } = value;
    if (fulfillmentStatus === constant.UNFULFILLED && collectDetail) {
      const found = selectedItems.find((element) => element === id);
      if (found) {
        setSelectedItems(
          selectedItems.filter((item) => {
            return item !== id;
          })
        );
        setSelectedReserveItemData(selectedReserveItemData.filter((item) => item._id !== id));
        setBulkQuantity(bulkQuantity.filter((item) => item.id !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
        setSelectedReserveItemData([...selectedReserveItemData, value]);
        setBulkQuantity([
          ...bulkQuantity,
          {
            id: value._id,
            quantity: value.fulfillableQuantity.toString(),
            totalQuantity: value.actualQuantity.toString(),
          },
        ]);
      }
    }
  };

  const amount = [];
  const vendorsSum = [];
  const countItems = 0;

  itemData
    .filter(
      (lineItem) =>
        lineItem.fulfillmentStatus !== constant.FULFILLED &&
        [constant.RESERVE, constant.COLLECT].includes(lineItem.fulfillmentType) &&
        lineItem.collectDetail &&
        (!lineItem.isRefund
          ? parseInt(lineItem.actualQuantity || lineItem.quantity, 10) -
              parseInt(lineItem.collectDetail.readyQuantity, 10) <
            parseInt(lineItem.fulfillableQuantity, 10)
          : !(
              parseInt(lineItem.quantity, 10) - parseInt(lineItem.fulfillableQuantity, 10) ===
              parseInt(lineItem.collectDetail.readyQuantity, 10)
            ))
    )
    .forEach(({ vendor }) => {
      vendorsSum.push(vendor);
      return vendorsSum;
    });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const lineDataItems = itemData.filter(
    (lineItem) =>
      lineItem.fulfillmentStatus !== constant.FULFILLED &&
      [constant.RESERVE, constant.COLLECT].includes(lineItem.fulfillmentType) &&
      lineItem.collectDetail
  ).length;
  const isVendor = baseHelper.isVendor(currentUserData);

  const lineItems =
    itemData &&
    itemData.length &&
    itemData
      .filter(
        (item) =>
          [constant.UNFULFILLED, constant.PARTIAL].includes(item.fulfillmentStatus) &&
          [constant.RESERVE, constant.COLLECT].includes(item.fulfillmentType) &&
          item.collectDetail
      )
      .map((lineData, idx) => {
        const {
          _id: id,
          actualQuantity = 0,
          collectDetail = {},
          fulfillableQuantity = 0,
          image = "",
          isProductDeleted,
          isRefund = false,
          isVendorDeleted,
          moneyFormat: lineMoneyFormat,
          name,
          productId,
          price,
          quantity = 0,
          sku,
          shopifyLineItemId,
          status,
          title,
          vendor,
          vendorId,
          fulfillmentType: lineItemFulfillmentType,
        } = lineData || {};

        let { readyQuantity = 0 } = collectDetail || {};
        isRefund &&
          refundData &&
          refundData
            .filter((item) => item.line_item_id === shopifyLineItemId)
            .forEach((refundItem) => {
              const { totalQuantity = 0 } = refundItem;
              readyQuantity -= totalQuantity;
            });
        const lineItemKey = `lineItem${idx}`;
        const lineImage =
          imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

        const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;

        const newQuantity = parseInt(actualQuantity || quantity) - parseInt(fulfillableQuantity);
        const readyCollectQuantity = parseInt(readyQuantity) - parseInt(newQuantity);

        if (!readyCollectQuantity) {
          return null;
        }

        const totalPrice = price * readyCollectQuantity;

        amount.push(totalPrice);

        const lineItemName = <div className="productName">{name}</div>;

        return (
          <div key={lineItemKey} className="fulfilled-Secn">
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      <div className="ellipsis">
                        {(isProductDeleted && (lineItemName || "")) || (
                          <Link onClick={() => history.push(`/products/edit/${productId}`)}>{name || ""}</Link>
                        )}

                        <Caption>
                          <TextStyle variation={constant.SUBDUED}>
                            {vendor ? `${cms("label.vendor")}: ${vendor}` : ""}
                          </TextStyle>
                          {/* {sku && ( */}
                          <TextStyle variation={constant.SUBDUED}>
                            {cms("label.skuList")}
                            {sku}
                          </TextStyle>
                          {/* )} */}
                        </Caption>

                        <Badge status={baseHelper.getBadgeType(status)} className="text-capitalize">
                          {readyQuantity < quantity ? cms("label.partialReady") : cms("label.readyForCollection")}
                        </Badge>

                        <Button plain size="slim" onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                          <Stack spacing="extraTight" alignment="center">
                            <Stack.Item>
                              <Icon source={NoteMajor} />
                            </Stack.Item>
                            {lineItemFulfillmentType === constant.RESERVE && (
                              <Stack.Item>{`${cms("label.reserveSlip")}`}</Stack.Item>
                            )}
                            {lineItemFulfillmentType === constant.COLLECT && (
                              <Stack.Item>{`${cms("label.pickupSlip")}`}</Stack.Item>
                            )}
                          </Stack>
                        </Button>
                      </div>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${readyCollectQuantity}`}</Stack.Item>
                    <Stack.Item>
                      {moneyFormat}
                      {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
                    </Stack.Item>
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        );
      });

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const moneyFormat = (itemData && itemData.length && itemData[0].moneyFormat) || constant.symbol.DOLLAR;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            (
            {lineDataItems > 1
              ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">{`${moneyFormat}${baseHelper.getPrice(totalAmount)}`}</TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );

  return [lineItems, totalFulfill];
};

export default ReadyForCollect;
