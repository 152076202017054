import React from "react";
import { Card, Select, TextField, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";

const Title = (props) => {
  const {
    cms,
    errorMessage = false,
    handleChange = () => {},
    handleValidation = () => {},
    options,
    setDisabledButton,
    variantData = {},
    locations = [],
  } = props;

  const {
    option1 = locations && locations.length ? "Locations" : cms("label.size"),
    option2 = locations && locations.length ? cms("label.size") : cms("label.color"),
    option3 = locations && locations.length ? cms("label.color") : cms("label.material"),
    option1Val = null,
    option2Val = null,
    option3Val = null,
  } = options || {};

  const renderTextField = (optionLabel, optionValue, id) => {
    if (optionLabel === "Location" && locations && locations.length) {
      return (
        <Select
          label={`${optionLabel}*`}
          placeholder="Select Locations"
          options={locations}
          onChange={(value) => {
            handleChange(`option${id}Val`, value, true);
            setDisabledButton(false);
          }}
          value={optionValue || ""}
        />
      );
    }
    return (
      <TextField
        id={`optionValue${id}`}
        label={`${optionLabel}*`}
        value={optionValue || ""}
        onChange={(value) => {
          handleChange(`option${id}Val`, value);
          setDisabledButton(false);
        }}
        onBlur={() => handleValidation(`option${id}`, optionValue)}
        placeholder={optionLabel}
        error={
          errorMessage &&
          ((errorMessage[`option${id}`] && cms("message.error.field")) ||
            (errorMessage[optionLabel.toLowerCase()] && cms("message.error.field")))
        }
      />
    );
  };

  return (
    <Card title={cms("section.option.title")} id="title" sectioned>
      <FormLayout>
        {option1 && option1Val && renderTextField(option1, variantData.option1Val, "1")}
        {option2 && option2Val && renderTextField(option2, variantData.option2Val, "2")}
        {option3 && option3Val && renderTextField(option3, variantData.option3Val, "3")}
      </FormLayout>
    </Card>
  );
};

Title.propTypes = {
  cms: PropTypes.func.isRequired,
  errorMessage: PropTypes.shape(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.string),
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

Title.defaultProps = {
  options: {},
};

export default Title;
