import React, { useContext } from "react";
import { Badge, Card, Caption, Link, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const ReturnedLineItems = (props) => {
  const { data, lineItemArray } = props;
  const { cms, history, currentUser = {} } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);

  const lineItems = lineItemArray
    .filter(
      (lineItem) =>
        lineItem.fulfillmentStatus === constant.FULFILLED &&
        lineItem.returnedQuantity &&
        lineItem.returnedQuantity !== constant.value.ZERO
    )
    .map((lineItem, idx) => {
      const {
        actualQuantity,
        image = "",
        isProductDeleted,
        isVendorDeleted,
        moneyFormat: lineMoneyFormat,
        name,
        price,
        productId,
        quantity,
        sku,
        returnedQuantity,
        vendor,
        vendorId,
      } = lineItem || {};

      const lineItemKey = `lineItem${idx}`;
      const lineImage = imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;
      const status =
        returnedQuantity < (actualQuantity || quantity)
          ? statusHelper.getBadgeStatus(constant.PARTIAL_RETURN)
          : statusHelper.getBadgeStatus(constant.RETURN);
      const badgeType = baseHelper.getBadgeType(constant.RETURN);
      const totalPrice = parseFloat(price) * parseInt(returnedQuantity, 10);

      const lineItemName = <div className="productName">{name}</div>;

      return (
        <div key={lineItemKey} className="fulfilled-Secn">
          <Card>
            <Card.Section subdued>
              <StyledCard className="styled-card-unfulfill">
                <Stack wrap={false}>
                  <Stack.Item>
                    <Thumbnail size="large" source={lineImage} alt={name} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="ellipsis">
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(`/products/edit/${productId}`)}>{lineItem.name || ""}</Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>
                          {vendor && vendorId && `${cms("label.vendor")}: ${vendor}`}
                        </TextStyle>
                        <TextStyle variation={constant.SUBDUED}>{`${cms("label.skuList")} ${sku}`}</TextStyle>
                      </Caption>
                      <Badge status={badgeType} className="text-capitalize">
                        {status}
                      </Badge>
                    </div>
                  </Stack.Item>
                  <Stack.Item>
                    {`${cms("label.qty")}:`}
                    {returnedQuantity}
                  </Stack.Item>
                  <Stack.Item>
                    {moneyFormat}
                    {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
                  </Stack.Item>
                </Stack>
              </StyledCard>
            </Card.Section>
          </Card>
        </div>
      );
    });
  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    let countItems = 0;
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    lineItemArray
      .filter(
        (lineItem) =>
          lineItem.fulfillmentStatus === constant.FULFILLED && lineItem.returnedQuantity !== constant.value.ZERO
      )
      .forEach(({ returnedQuantity = 1, price, vendor }) => {
        vendorsSum.push(vendor);
        amount.push(parseFloat(price) * parseInt(returnedQuantity, 10));
        countItems += 1;
      });

    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

    const stringToNumber = amount.map((item) => parseFloat(item));
    const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.total")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {countItems > 1
                  ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${moneyFormat}${baseHelper.getPrice(totalAmount)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  return [lineItems, renderTotalAmount()];
};

export default ReturnedLineItems;
