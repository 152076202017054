import React, { useEffect, useState } from "react";
import { Modal, Stack, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

import { Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const GetOtpModal = (props) => {
  const {
    brandName,
    buttonLoading,
    closeOtpModal,
    fullAccessRes,
    handleOTP,
    logIn,
    openOtpModal,
    otpEntered,
    selectedButton,
    sellerListCMS,
    verifyButtonLoading,
    verifyOTP,
    verifyOtpError,
  } = props;

  const [otpBanner, setOtpBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });

  const { button, label, message, sectionContent } = sellerListCMS;

  const sellerData = brandName.split("#");

  const isErrorOtpVerify = baseHelper.getResponseError(verifyOtpError, constant.gql.VERIFY_OTP);

  useEffect(() => {
    if (isErrorOtpVerify) {
      setOtpBanner({
        title: isErrorOtpVerify,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
  }, [isErrorOtpVerify]);

  const validateAndVerifyOTP = (val) => {
    const { token } = fullAccessRes;
    if (!otpEntered) {
      const banner = {
        title: message.enterAccessCode,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setOtpBanner(banner);
      return;
    }
    verifyOTP(token, otpEntered, val);
  };

  return (
    <Modal
      key={label.otpModal}
      open={openOtpModal}
      onClose={closeOtpModal}
      title={`${label.logInAs} ${baseHelper.ucFirst(sellerData[0])}`}
      primaryAction={{
        content: button.confirmOtp,
        onAction: () => validateAndVerifyOTP(constant.VERIFY_LOGIN),
        disabled: verifyButtonLoading && selectedButton !== constant.VERIFY_LOGIN,
        loading: verifyButtonLoading && selectedButton === constant.VERIFY_LOGIN,
      }}
      secondaryActions={[
        {
          content: button.resendOtp,
          onAction: () => logIn(sellerData[1], constant.RESEND_OTP, sellerData[2]),
          disabled: buttonLoading && selectedButton !== constant.RESEND_OTP,
          loading: buttonLoading && selectedButton === constant.RESEND_OTP,
        },
        {
          content: button.readOnly,
          onAction: () => logIn(sellerData[1], constant.READ_ONLY_ACCESS, sellerData[2]),
          disabled: buttonLoading && selectedButton !== constant.READ_ONLY_ACCESS,
          loading: buttonLoading && selectedButton === constant.READ_ONLY_ACCESS,
        },
      ]}
      sectioned
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <Banner
              isOpen={otpBanner.isOpen}
              title={otpBanner.title}
              status={otpBanner.status}
              onDismiss={() => {
                setOtpBanner({ isOpen: false, title: "", status: "" });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField label={label.enterCode} type="number" value={otpEntered} onChange={handleOTP} />
          </Stack.Item>
          <Stack.Item>{sectionContent.otpNote}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

GetOtpModal.defaultProps = {
  openOtpModal: false,
  closeOtpModal: () => {},
  handleOTP: () => {},
  verifyButtonLoading: () => {},
  buttonLoading: false,
  selectedButton: "",
  brandName: "",
  otpEntered: "",
  fullAccessRes: [],
  verifyOtpError: [],
  verifyOTP: () => {},
  logIn: () => {},
};

GetOtpModal.propTypes = {
  openOtpModal: PropTypes.bool,
  closeOtpModal: PropTypes.func,
  handleOTP: PropTypes.func,
  buttonLoading: PropTypes.bool,
  verifyButtonLoading: PropTypes.bool,
  selectedButton: PropTypes.string,
  brandName: PropTypes.string,
  otpEntered: PropTypes.string,
  fullAccessRes: PropTypes.arrayOf(PropTypes.object),
  verifyOTP: PropTypes.func,
  logIn: PropTypes.func,
  verifyOtpError: PropTypes.arrayOf(PropTypes.object),
  sellerListCMS: PropTypes.func.isRequired,
};

export default GetOtpModal;
