import React, { useState, useContext } from "react";
import { FormLayout, Autocomplete, Stack, Tag } from "@shopify/polaris";

import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context/privateContext";
import constant from "lib/constant/constant";
import { tagProp } from "app/productOld/modules/operator/features/add/props";

const Tags = (props) => {
  const { cms = {} } = useContext(PrivateContext);
  const { values = {}, selected = [], onChange, label, placeholder, id } = props;

  const tagOptions = baseHelper.sort((values && values.tags && values.tags.length && values.tags) || []);
  const sortedOptions = tagOptions;
  const options = sortedOptions.map((item) => ({ label: item, value: item }));
  const [state, setState] = useState({
    options: [],
    selected,
    inputText: "",
  });

  const handleChange = (val) => {
    const selectedTags = [];
    val.forEach((value) => {
      const selectedVal = options.find((item) => item.value === value);
      if (selectedVal) {
        selectedTags.push(selectedVal.value);
      }
    });
    setState({ ...state, selected: selectedTags });
    onChange(constant.ORGANISATION_TAGS, selectedTags);
  };

  const filterAndUpdateOptions = (inputString) => {
    if (!inputString) {
      setState({
        ...state,
        inputText: inputString,
      });
      return;
    }
    const filterRegex = new RegExp(inputString, "i");
    const resultOptions = options.filter((option) => option.label.match(filterRegex));
    setState({
      ...state,
      inputText: inputString,
      options: resultOptions,
    });
  };

  const updateText = (newValue) => {
    filterAndUpdateOptions(newValue);
  };

  const removeTag = (tag) => {
    const { selected: selectRemoveTag } = state;
    const valueIndex = selectRemoveTag.findIndex((item) => item === tag);
    if (valueIndex !== -1) {
      selectRemoveTag.splice(valueIndex, 1);
    }
    setState({ ...state, selectRemoveTag });
    onChange(constant.ORGANISATION_TAGS, selectRemoveTag);
  };

  const renderTags = () => {
    const { selected: selectedRenderTags } = state;
    const selectedTags = options.filter((item) => selectedRenderTags.includes(item.value));
    let tagFields = baseHelper.sort(selectedTags, "label").map((item) => {
      return (
        <Tag key={item.label} onRemove={() => removeTag(item.value)}>
          {item.label}
        </Tag>
      );
    });
    tagFields = tagFields.filter((item) => item);
    return <Stack>{tagFields}</Stack>;
  };

  const textField = (
    <Autocomplete.TextField
      label={label}
      id={id}
      value={state.inputText}
      onChange={(value) => updateText(value)}
      placeholder={placeholder}
    />
  );

  return (
    <FormLayout>
      <Autocomplete
        allowMultiple
        options={state.options}
        textField={textField}
        selected={state.selected}
        onSelect={handleChange}
        listTitle={cms("cms.label.availableOption")}
      />
      {renderTags()}
    </FormLayout>
  );
};

Tags.propTypes = tagProp.type;

export default Tags;
