import React, { useContext, useState } from "react";
import { Button, Card, Layout } from "@shopify/polaris";
import { BalanceMajor, ExportMinor } from "@shopify/polaris-icons";
import { StyledCSVButton } from "app/analytics/modules/generic/style";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { SingleColumnLayout } from "layout/private/components";
import { Banner, SkeletonList } from "lib/components";
import { QuickAction, QuickReport } from "app/reports";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import subFeature
// import OperatorExportPayment from "./export/export";
import CommissionRevenueList from "./subFeatures/commissionRevenueList";

const OperatorPaymentList = () => {
  const { isLoading, history, cms } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        <StyledCSVButton>
          <div className="btn text-inverse ">
            <Button primary onClick={() => setIsOpenModal(true)}>
              Export Your Data
            </Button>
          </div>
        </StyledCSVButton>
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <CommissionRevenueList setBanner={setBanner} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withErrorBoundary(OperatorPaymentList);
