import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { SkeletonList } from "lib/components";

import { storageHelper } from "lib/helpers";
import { SingleColumnLayout } from "layout/private/components";
import OperatorAppPermission from "./operator/appPermission";

const { userKey } = constant;
const { operator } = userKey;

const AppPermission = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }

    if (role === operator) {
      return <OperatorAppPermission />;
    }
    return null;
  };

  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default AppPermission;
