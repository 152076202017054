import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";

import { Layout } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

import { STRIPE_CONFIRM } from "app/plans/apollo/mutations";

const BillingSuccess = () => {
  const { currentUser, history, isLoading: isComponentLoading, currentUserRefetch } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [error, setError] = useState("");
  const [stripeConfirm] = useMutation(STRIPE_CONFIRM);

  const checkPayment = () => {
    stripeConfirm()
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.STRIPE_CONFIRM);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.STRIPE_CONFIRM);

        if (responseError) {
          setError(responseError);
          setTimeout(() => {
            history.push("/");
          }, 3000);
        }

        if (responseData && responseData.isActive) {
          currentUserRefetch();
          return history.push("/");
        }
      })
      .catch((exception) => {
        const parsedError = errorHelper.parse(exception);
        setError(parsedError);
        setTimeout(() => {
          history.push("/");
        }, 3000);
      });
  }

  useEffect(() => {
    checkPayment();
  }, []);

  useEffect(() => {
    if (error) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: error,
      });
    }
  }, [error]);

  const getComponent = () => {
    if (isComponentLoading) {
      return <Spinner />;
    }

    if (banner.isOpen) {
      return (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )
    }

    return <Spinner isFullPage={true} />;
  };

  const BillingSuccessComponent = getComponent();
  return BillingSuccessComponent;
};

export default withErrorBoundary(BillingSuccess);
