import React, { useContext, useState, useCallback, useEffect } from "react";
import { Tag, Stack, Autocomplete } from "@shopify/polaris";
import constant from "lib/constant/constant";
// import context
import { PrivateContext } from "lib/context";

import { withErrorBoundary } from "lib/hoc";

// import prop type
import { tagProp } from "app/product/modules/generic/propTypes";

const Catalog = (props) => {
  const { handleChange, data, helpText, productCatalog, label, placeholder } = props;
  const { cms } = useContext(PrivateContext);
  const [selected, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // const isKeyValuePair = !!(productTag && productTag.tags && productTag.tags.length && productTag.tags.id);
  // let sortedOptions =
  //   (productTag && productTag.tags && baseHelper.sort(productTag.tags, isKeyValuePair && "label")) || [];
  const sortedOptions =
    (productCatalog &&
      productCatalog.length &&
      productCatalog.map((item) => ({ label: item.title, value: item.storeCatalogId }))) ||
    [];

  const [optionsValue, setOptionsValue] = useState(sortedOptions || []);

  const onSelect = (selectedCatalog) => {
    setSelected(selectedCatalog);
    handleChange(constant.SHOPIFY_CATALOG_ID, selectedCatalog);
  };

  useEffect(() => {
    if (data && data.shopifyCatalogId && data.shopifyCatalogId.length) {
      setSelected(data.shopifyCatalogId);
    }
  }, [data]);

  const filterAndUpdateOptions = (inputString) => {
    if (!inputString) {
      setInputValue(inputString);
      setOptionsValue(sortedOptions);
      return;
    }
    const filterRegex = new RegExp(inputString, "i");
    const resultOptions = sortedOptions.filter((option) => option.label.match(filterRegex));
    setInputValue(inputString);
    setOptionsValue(resultOptions);
  };

  const textField = (
    <Autocomplete.TextField
      onChange={filterAndUpdateOptions}
      label={label || cms("label.catalog")}
      helpText={helpText || cms("subtext.catalog")}
      value={inputValue}
      placeholder={placeholder || cms("placeholder.catalog")}
    />
  );
  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selected];
      options.splice(options.indexOf(tag), 1);
      handleChange(constant.SHOPIFY_CATALOG_ID, options);
      setSelected(options);
    },
    [selected, handleChange]
  );
  const tagsMarkup =
    (selected.length &&
      selected.map((option) => {
        let tagLabel = "";
        tagLabel = sortedOptions.find((item) => item.value === option);
        if (tagLabel && tagLabel.label) {
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {(tagLabel && tagLabel.label) || ""}
            </Tag>
          );
        }
        return null;
      })) ||
    [];

  return (
    <>
      <Autocomplete
        allowMultiple
        options={optionsValue || []}
        selected={selected}
        textField={textField}
        onSelect={(value) => onSelect(value)}
        listTitle={cms("label.suggested")}
      />
      <br />
      <Stack>{tagsMarkup}</Stack>
    </>
  );
};

Catalog.propTypes = tagProp.type;

export default withErrorBoundary(Catalog);
