import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const MoreFilters = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, taggedWith, selectedFilter, setSelectedFilter } = props;
  const { history, cms } = useContext(PrivateContext);

  const filterOptions = [
    { label: cms("label.productList.select"), value: "" },
    { label: cms("label.productList.handle"), value: cms("label.productList.handle").toLowerCase() },
    { label: cms("label.productList.productStatus"), value: cms("label.productList.status") },
  ];
  const filterStatus = [
    { label: cms("label.productList.select"), value: "" },
    { label: cms("label.productList.new"), value: cms("label.productList.new").toLowerCase() },
    { label: cms("label.productList.review"), value: cms("label.productList.review").toLowerCase() },
    { label: cms("label.productList.approved"), value: cms("label.productList.approved").toLowerCase() },
    { label: cms("label.productList.rejected"), value: cms("label.productList.rejected").toLowerCase() },
  ];

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    let listFilter = baseHelper.setQueryParams(history.location, { list_filter: value });
    if (value !== cms("label.productList.status")) {
      setTaggedWith("");
      listFilter = baseHelper.setQueryParams(history.location, { list_filter: value, list_search: "" });
    }
    history.push(`${history.location.pathname}?${listFilter}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  return (
    <>
      <FormLayout>
        <Select
          label={cms("label.productList.showAllProducts")}
          value={selectedFilter}
          onChange={handleSelectChange}
          options={filterOptions}
          placeholder={cms("label.productList.selectFilter")}
        />
        {(selectedFilter && selectedFilter !== cms("label.productList.status") && (
          <TextField label={cms("label.productList.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          (selectedFilter === cms("label.productList.status") && (
            <Select
              label={baseHelper.ucFirst(cms("label.productList.status"))}
              value={taggedWith}
              onChange={handleTaggedWithChange}
              options={filterStatus}
              placeholder={cms("label.productList.selectStatus")}
            />
          )) ||
          null}
      </FormLayout>
    </>
  );
};

MoreFilters.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export default MoreFilters;
