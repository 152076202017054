import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Caption,
  Card,
  Collapsible,
  Frame,
  Link,
  ResourceItem,
  Stack,
  Tag,
  TextStyle,
  Tooltip,
  ChoiceList,
} from "@shopify/polaris";

// import { ViewMajor, ExportMinor, CircleCancelMinor, CircleTickOutlineMinor } from "@shopify/polaris-icons";
import { ViewMajor } from "@shopify/polaris-icons";

import { useQuery } from "react-apollo";
import { SingleColumnLayout } from "layout/private/components";
import { Banner, ResourceList, Toast } from "lib/components";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { QuickReport } from "app/reports";

import { ProductCard, ProductSubCard } from "asset/styles/globalStyle";
import LineItem from "app/orderLake/modules/provider/features/list/component/lineItem/lineItem";

import { ORDER_LAKE_LIST } from "app/orderLake/apollo/subscriptions";
import { GET_ORDER_LAKE_LIST } from "app/orderLake/apollo/queries";
import { baseHelper, errorHelper } from "lib/helpers";
import MoreFilters from "app/orderLake/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";
import ProviderExportOrder from "app/orderLake/modules/provider/features/export";
import listData from "./orderListData";

const ProviderOrderList = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { brandName, moneyFormat } = currentUser;

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const {
    ACCEPTED,
    ALL,
    CLOSED,
    COLLECTED,
    CREATED_AT,
    CRITICAL,
    DESC,
    DOWN,
    FILTER_BY,
    gql,
    HIDE_CAPITAL,
    PARTIAL_COLLECT,
    PARTIAL_REFUND,
    REFUND,
    REJECTED,
    RESERVE,
    SHIPPED,
    SHOW,
    TAGGED_WITH,
    UP,
  } = constant;

  const { mainLink, filterByStatusOption } = listData(cms, currentUser);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const paramFilter = (queryParam.filter && [...queryParam.filter.split(",")]) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);

  const [orderCount, setOrderCount] = useState(0);
  // const [selectedTab, setSelectedTab] = useState(0);
  const [isExportModal, setIsExportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [orderList, setOrderList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [active, setActive] = useState({});
  const [activePopover, setActivePopover] = useState({});
  const [expanded, setExpanded] = useState({});

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || CREATED_AT,
    sort_order: queryParam.sort_order || DESC,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };

  const { data: order, loading: orderLoading, error: orderError, subscribeToMore, refetch } = useQuery(
    GET_ORDER_LAKE_LIST,
    {
      variables: {
        input: inputData,
      },
    }
  );

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(orderError),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError]);

  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LAKE_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: responseError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LAKE_LIST);

  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  useEffect(() => {
    subscribeToMore({
      document: ORDER_LAKE_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        refetch();
        return null;
      },
    });
  }, [subscribeToMore, refetch]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  // const setFilters = useCallback(
  //   (newFilter, select) => {
  //     const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
  //     history.push(`${history.location.pathname}?${getFilter}`);
  //   },
  //   [history]
  // );

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };

  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), setSelectedFilter(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.SELECTED_FILTER:
            return [setSelectedFilter(""), baseHelper.setUrl(history, clearParams)];
          default:
            return null;
        }
      }
    }
    clearParams.list_filter = "";
    clearParams.filter = "";
    setTaggedWith("");
    setSelectedFilter("");
    setFilterByStatus(null);
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  // const linkData = [
  //   {
  //     action: () => setIsExportModal(!isExportModal),
  //     caption: "Export your orders",
  //     id: "exportOrder",
  //     icon: ExportMinor,
  //     label: cms("section.orderList.label.exportOrder"),
  //     primary: true,
  //     shop: true,
  //   },
  // ];
  const getSecondaryComponent = () => (
    <>
      <QuickReport />
      {/* <QuickAction link={linkData} /> */}
    </>
  );

  // const getTabs = {
  //   0: ALL,
  //   1: PENDING,
  //   2: ON_HOLD,
  //   3: PROCESS,
  //   4: COMPLETE,
  //   5: CANCELLED,
  //   6: FAILED,
  //   7: REFUND,
  //   8: PARTIAL,
  // };
  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  // const handleTabChange = (select) => {
  //   setSelectedItems([]);
  //   setSelectedTab(select);
  //   baseHelper.setUrl(history, { page: 1 });
  //   setPage(1);
  //   setSelectedItems([]);
  //   setFilters(getTabs[select], select);
  // };

  const pageContent = {
    label: {
      status: cms("section.orderList.label.status"),
      fulfillmentStatus: cms("section.orderList.label.fulfillmentStatus"),
    },
  };

  const handlePageChange = (value) => {
    setSelectedItems([]);
    setPage(value);
  };

  // eslint-disable-next-line no-unused-vars
  const bulkOrderActions = async (formData) => {
    const { ids, key } = formData;
    ids.forEach((orderId) => {
      const index = orderList.findIndex((orders) => {
        const { _id: id } = orders;
        return id === orderId;
      });
      if (orderList[index].status === constant.NEW) {
        orderList[index] = {
          ...orderList[index],
          status: key === constant.ACCEPT ? constant.ACCEPTED : constant.REJECTED,
        };
      }
    });
    return {
      status: "Success",
      message: `${ids.length > 1 ? "Orders are" : "Order is"} ${
        key === constant.ACCEPT ? ACCEPTED : REJECTED
      } successfully.`,
    };
  };

  // const promotedBulkActions = [
  //   {
  //     content: "Bulk Action",
  //   },
  // ];

  // const bulkActions = [
  //   {
  //     content: "Bulk Accept",
  // onAction: () => bulkAction(constant.ACCEPT),
  // icon: CircleTickOutlineMinor,
  // disabled:
  //   bulkActionButton(constant.ACCEPT) ||
  //   (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
  //     getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
  //     getTabs[selectedTab] !== constant.NEW.toLowerCase()),
  //   disabled: true,
  // },
  // {
  //   content: "Bulk Reject",
  // onAction: () => setRejectModal(true),
  // icon: CircleCancelMinor,
  // destructive: true,
  // disabled:
  //   bulkActionButton(constant.REJECT) ||
  //   (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
  //     getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
  //     getTabs[selectedTab] !== constant.ACCEPTED.toLowerCase() &&
  //     getTabs[selectedTab] !== constant.NEW.toLowerCase()),
  //     disabled: true,
  //   },
  // ];

  const renderItem = (item) => {
    const {
      _id: id,
      actualQuantity,
      createdAt,
      fulfillmentType,
      // image,
      storeStatus: currentOrderStatus,
      number,
      storeOrderId,
      quantity,
      lineItems,
    } = item;

    const vendorLineItems = lineItems.filter(
      (lineItem) => lineItem.providerId === baseHelper.mongoIdAsString(currentUser._id)
    );

    const shippingStatuses = vendorLineItems.map((lineItem) => lineItem.shippingStatus);

    const isAllShipped = shippingStatuses.every((status) => status === constant.DISPATCHED);
    const isAllProcessing = shippingStatuses.every((status) => status === constant.AWAITING_DISPATCH);
    const isAnyShipped = !!shippingStatuses.find((status) => status === constant.DISPATCHED);

    const storeStatus =
      (isAllShipped && constant.COMPLETE) ||
      (isAllProcessing && constant.AWAITING_FULFILLMENT) ||
      (!isAllShipped && !isAllProcessing && isAnyShipped && constant.PARTIALLY_SHIPPED) ||
      currentOrderStatus;

    let { fulfillmentStatus: orderFulfillmentStatus, status } = item;
    const isReserve = (status === SHIPPED || status === CLOSED) && fulfillmentType === RESERVE;

    if (isReserve) {
      status = COLLECTED;
    }
    const statusType = status === ACCEPTED;

    if (statusType) {
      status = PARTIAL_COLLECT;
    }
    if (status === constant.REJECTED) {
      orderFulfillmentStatus = REFUND;
    }

    const isPartialRefund = orderFulfillmentStatus === REFUND && !!(actualQuantity || quantity);

    if (isPartialRefund) {
      orderFulfillmentStatus = PARTIAL_REFUND;
    }

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajor,
        onAction: () => history.push(`${mainLink.orderNumber}${id}`),
      },
    ];

    const handlePopover = (orderId) => {
      setSelectedItems([]);
      setActivePopover({ [orderId]: !activePopover[orderId] });
    };

    return (
      <ResourceItem id={id} persistActions>
        <ProductCard className={`product-card ${expanded[id] ? "Polaris-ResourceItem--selected" : ""}`}>
          <div className="list-item order-lake">
            <Stack alignment="leading">
              <Stack.Item fill>
                <div className="ellipsis">
                  <Link onClick={() => history.push(`${mainLink.orderNumber}${id}`)}>
                    {`Order #${number || storeOrderId}`}
                  </Link>
                  <Caption>
                    <TextStyle variation="subdued">
                      {` ${cms("section.orderList.label.createdOn")}: ${baseHelper.formatDate(createdAt)}`}
                    </TextStyle>
                  </Caption>
                  <div>
                    <Stack>
                      {storeStatus && (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.status} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType(storeStatus)}>
                              {baseHelper.ucFirst(storeStatus)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}
                      <Stack.Item>
                        <div className="history-items">
                          <Button
                            plain
                            removeUnderline
                            disclosure={active[id] ? UP : DOWN}
                            onClick={() => {
                              setActive((prev) => {
                                return {
                                  ...prev,
                                  [id]: !active[id],
                                };
                              });
                              setExpanded((prev) => {
                                return {
                                  ...prev,
                                  [id]: !expanded[id],
                                };
                              });
                            }}
                          >
                            {`${active[id] ? HIDE_CAPITAL : SHOW} ${constant.ITEMS}`}
                          </Button>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div className="manage-button">
                  <Popover
                    active={activePopover[id]}
                    setActive={() => {
                      handlePopover(id);
                    }}
                    options={option}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <Collapsible
            open={active[id]}
            id={`timeline-${active[id] + 1}`}
            transition={{ duration: "150ms", timingFunction: "ease" }}
          >
            <ProductSubCard className="collapsible-card">
              <LineItem cms={cms} brandName={brandName} id={id} moneyFormat={moneyFormat} orderId={id} />
            </ProductSubCard>
          </Collapsible>
        </ProductCard>
      </ResourceItem>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`section.orderList.label.filter.${value}`) || value === constant.STORE_ORDER_ID
          ? cms("section.orderList.label.orderId")
          : value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(true, constant.TAGGED_WITH),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true, constant.SELECTED_FILTER),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(true, constant.FILTER_BY_STATUS),
    });
  }

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: ALL, page: 1 });
    }
  };
  const appliedFilters = appliedFilterArrObj;

  const filters = [
    {
      key: TAGGED_WITH,
      // label: cms("section.orderList.label.filterBy"),
      label: FILTER_BY,
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      // label: cms("common.label.filterByStatus"),
      label: "Filter By Status",
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        {getSecondaryComponent()}
        <ProviderExportOrder isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        <Card>
          {/* <Tabs tabs={listData(cms).tabsData} selected={selectedTab} onSelect={handleTabChange} /> */}
          <div className="order-list">
            <ResourceList
              items={orderList}
              renderItem={renderItem}
              idForItem={(orders) => {
                const { _id: id } = orders;
                return id;
              }}
              loading={loading || orderLoading}
              queryValue={search}
              onQueryClear={handleQueryClear}
              onQueryChange={handleSearchChange}
              sortOptions={listData(cms).sortOptions}
              sortValue={sortValue}
              onSortChange={handleSort}
              selectedItems={selectedItems}
              // bulkActions={bulkActions}
              // promotedBulkActions={promotedBulkActions}
              onSelectionChange={handleSelect}
              handleClearAll={handleClearAll}
              count={orderCount}
              page={page}
              appliedFilters={appliedFilters}
              filters={filters}
              perPage={perPage}
              setPage={(value) => handlePageChange(value)}
              setPerPage={setPerPage}
            />
          </div>
        </Card>
        <div className="toast">
          <Frame>
            <Toast message={message} />
          </Frame>
        </div>
      </>
    );
  };
  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(ProviderOrderList), { feature: constant.ORDERS });
