import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, phoneNumberRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail"))
      .nullable(),
  });

const phoneNumberSchema = (cms) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .min(7, cms("common.message.error.minPhoneNumber"))
      .max(15, cms("common.message.error.maxPhoneNumber"))
      .nullable(),
  });

export { emailSchema, phoneNumberSchema };
