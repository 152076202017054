import React from "react";
import { Card, Stack, Select, Heading, Pagination } from "@shopify/polaris";

import PropTypes from "prop-types";

const BulkProductEditPagination = (props) => {
  const { onSelectLimit, perPageCount, totalCount, onPerPage, currentPage } = props;

  const optionsList = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  let maxPage = (totalCount && totalCount && parseInt(totalCount / perPageCount, 10)) || 0;
  maxPage = (maxPage && ((totalCount % perPageCount && maxPage + 1) || maxPage)) || 0;

  return (
    <>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <Heading>
              Total
              <span className="totalCount">{totalCount}</span>
            </Heading>
          </Stack.Item>
          {maxPage && (
            <Stack.Item fill>
              <Pagination
                hasPrevious={(maxPage && parseInt(currentPage, 10) !== 1 && true) || false}
                onPrevious={() => {
                  if (parseInt(currentPage, 10) !== 1) {
                    onPerPage(parseInt(currentPage, 10) - 1);
                  }
                }}
                hasNext={(parseInt(currentPage, 10) < maxPage && true) || false}
                onNext={() => {
                  if (parseInt(currentPage, 10) <= totalCount) {
                    onPerPage(parseInt(currentPage, 10) + 1);
                  }
                }}
              />
            </Stack.Item>
          )}

          <Stack.Item>
            <Select value={perPageCount} options={optionsList} onChange={onSelectLimit} />
          </Stack.Item>
        </Stack>
      </Card.Section>
    </>
  );
};

BulkProductEditPagination.defaultProps = {
  perPageCount: "",
  totalCount: "",
  currentPage: 1,
  onSelectLimit: () => {},
  onPerPage: () => {},
};

BulkProductEditPagination.propTypes = {
  perPageCount: PropTypes.number,
  totalCount: PropTypes.number,
  onSelectLimit: PropTypes.func,
  onPerPage: PropTypes.func,
  currentPage: PropTypes.number,
};

export default BulkProductEditPagination;
