import React, { useState } from "react";
import { AppProvider, Checkbox, Modal, ResourceItem, ResourceList, Stack, TextStyle, TopBar } from "@shopify/polaris";
import PropTypes from "prop-types";
import { LocationTopBar } from "app/setup/modules/operator/features/currency/currencyStyle";

import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const AddVendor = (props) => {
  const {
    activator,
    active,
    cms,
    handleChange,
    setActive,
    vendorList,
    userInput,
    setUserInput,
    standardAccess,
    setVendorList,
    setDisable,
  } = props;
  const { color } = constant;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempVendorList, setTempVendorList] = useState([]);
  const [searchList, setSearchList] = useState(vendorList);

  const theme = {
    colors: {
      topBar: {
        background: color.WHITE,
        backgroundLighter: color.GRAY,
        backgroundDarker: color.ATHENS_GRAY,
        border: color.LOBLOLLY,
        color: color.CLAY,
      },
    },
  };

  const handleSearchChange = (searchItem) => {
    setSearchValue(searchItem);
    setIsSearchActive(searchItem.length > 0);
    const vendorToShow = vendorList.filter((item) => {
      const { _id: code = "", brandName = "" } = item || {};
      if (
        (code && code.toLowerCase().includes(searchItem)) ||
        (code && code.includes(searchItem)) ||
        (brandName && brandName.toLowerCase().includes(searchItem)) ||
        (brandName && brandName.includes(searchItem))
      ) {
        return true;
      }
      return false;
    });
    setSearchList([...vendorToShow]);
  };

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={(value) => handleSearchChange(value)}
      value={searchValue}
      placeholder="Search Vendors"
      showFocusBorder
    />
  );

  const handleSearchResultsDismiss = () => {
    setIsSearchActive(false);
    setSearchValue("");
    setSearchList([...vendorList]);
  };

  const onSubmit = () => {
    let empty = userInput;

    tempVendorList.forEach((item) => {
      const findNew = empty.find((item1) => item1.vendorId === item);
      if (findNew) {
        empty = empty.filter((item1) => item1.vendorId !== item);
      }
      if (!findNew) {
        empty.push({
          vendorId: item,
          product: { ...standardAccess.product },
          order: { ...standardAccess.order },
          payment: { ...standardAccess.payment },
        });
      }
    });
    setUserInput([...empty]);
    setTempVendorList([]);
    setActive(!active);
    setDisable(false);
  };

  const handleCheckbox = (index, checkUncheck, code) => {
    const vendorCheckbox = [...vendorList] || [];
    vendorCheckbox.forEach((vendorData) => {
      if (vendorData._id === code) {
        // eslint-disable-next-line no-param-reassign
        vendorData.isCheck = checkUncheck;
        tempVendorList.push(vendorData._id);
      }
      return false;
    });
    setVendorList([...vendorCheckbox]);
  };

  const renderItem = (item, _, index) => {
    const { _id: id, brandName, isCheck } = item;
    return (
      <ResourceItem id={id}>
        <Stack>
          <Checkbox key={id} checked={isCheck} onChange={(action) => handleCheckbox(index, action, id)} />
          <TextStyle>{brandName}</TextStyle>
        </Stack>
      </ResourceItem>
    );
  };

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={cms("label.addVendor")}
      primaryAction={{
        content: cms("button.addVendor"),
        onAction: onSubmit,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: handleChange,
        },
      ]}
    >
      <Modal.Section>
        <AppProvider theme={theme}>
          <LocationTopBar className="vendor-popup">
            <div className="vendor-topBar">
              <TopBar
                searchResultsVisible={isSearchActive}
                searchField={searchFieldMarkup}
                onSearchResultsDismiss={handleSearchResultsDismiss}
              />
            </div>
            <ResourceList items={searchList} renderItem={renderItem} />
          </LocationTopBar>
        </AppProvider>
      </Modal.Section>
    </Modal>
  );
};

export default withErrorBoundary(AddVendor);

AddVendor.propTypes = {
  active: PropTypes.bool,
  activator: PropTypes.objectOf(PropTypes.string),
  cms: PropTypes.func,
  handleChange: PropTypes.func,
  setActive: PropTypes.func,
};

AddVendor.defaultProps = {
  active: null,
  activator: {},
  cms: () => {},
  handleChange: () => {},
  setActive: () => {},
};
