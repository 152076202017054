import { gql } from "apollo-boost";

const UPDATE_FULFILLMENT = gql`
  mutation updateFulfillment($input: Fulfillment) {
    updateFulfillment(input: $input) {
      status
      data {
        type
        clientId
        clientSecret
        siteCode
        accountNumber
        consignment {
          global {
            address
            city
            country
            countryCode
            pincode
          }
          addresses {
            vendorIds
            address {
              address
              city
              country
              countryCode
              pincode
            }
          }
        }
        ownerId
      }
      error
    }
  }
`;

export const UPDATE_SELLER_FULFILLMENT = gql`
  mutation updateFulfillment($input: Fulfillment) {
    updateFulfillment(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_FULFILLMENT;
