import { gql } from "apollo-boost";

const GET_ORDER_DETAILS = gql`
  query getOrderDetails($input: GetOrderDetail!) {
    getOrderDetails(input: $input) {
      data {
        isNotFound
        orderDataRow {
          createdAt
          moneyFormat
          orderNumber
          shopifyOrderId
          shipping {
            amount
          }
          shippingLines {
            source
            tax_lines {
              rate
            }
          }
          shippingAddress {
            name
            phone
            address1
            address2
            city
            company
            province
            country
            zip
          }
          taxLines {
            rate
          }
          totalPrice
          totalTax
          taxesIncluded
        }
        orderLineItemsData {
          actualQuantity
          addressId
          createdAt
          commissionVendor
          commissionSeller
          discountAllocations {
            amount
            discount_application_index
          }
          fulfillmentStatus
          fulfillmentType
          fulfillableQuantity
          markUpPrice
          name
          price
          isDiscountAllowed
          quantity
          sellerDiscount
          shippingAmount
          status
          taxLines {
            price
            rate
            title
          }
          totalDiscount
          totalTax
          sku
        }
        seller {
          address
          brandName
          firstName
          lastName
          phoneNumber
          pinCode
          city
          country
          logo
          taxSetting {
            company
            customs
            vat
          }
          plan {
            isBranding
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_ORDER_DETAILS;
