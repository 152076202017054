import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, PageActions, Select, TextContainer, TextField } from "@shopify/polaris";
import { getNames } from "country-list";
// libs
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { baseHelper, storageHelper, errorHelper } from "lib/helpers";

// gql
import { EDIT_PROFILE } from "app/onboarding/apollo/mutations";
import { GET_VENDOR_PROFILE_SETTING } from "app/onboarding/apollo/queries";
import { GET_SELLER_FULFILLMENT } from "app/vendors/apollo/queries";
import { GET_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/queries";

// config
import fields from "./updateProfileConfig";
import validate from "./yup";

// sub features
import EmailChangeModal from "./subFeatures";

const {
  onboardingSteps: { WELCOME, PENDING_APPROVAL, FINISH },
  providerList: { IN_REVIEW, ON_HOLD, REJECT },
} = constant;

const UpdateProfile = (props) => {
  const {
    BRAND_NAME,
    BRAND_SLUG,
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
    gql,
    providerList,
  } = constant;

  const { UPDATE_PROFILE } = gql;
  const { isApproved, setSelectedTab, refetch } = props;
  const [isShowProvinceCode, setShowProvinceCode] = useState(false);

  const { cms = {}, currentUser, currentUserRefetch = () => {}, history, isLoading = false } = useContext(
    PrivateContext
  );
  const onNext = () => {
    if (
      currentUser.isApproved === IN_REVIEW ||
      currentUser.isApproved === ON_HOLD ||
      currentUser.isApproved === REJECT
    ) {
      setSelectedTab(PENDING_APPROVAL);
      currentUserRefetch();
      refetch();
    } else {
      setSelectedTab(FINISH);
    }
  };
  const onPrevious = () => setSelectedTab(WELCOME);

  const {
    firstName = "",
    lastName = "",
    email = "",
    phoneNumber = "",
    brandName = "",
    address = "",
    city = "",
    country = "",
    pinCode = "",
    provinceCode = "",
    slug = (currentUser.brand && currentUser.brand.slug) || "",
    mobile = {},
  } = currentUser || {};

  const [vendorFormSetting, setVendorFormSetting] = useState({});

  const isBrandHandle = !!(currentUser && currentUser.brand && currentUser.brand.slug);

  // states
  const [state, setState] = useState({
    [FIRST_NAME]: firstName,
    [LAST_NAME]: lastName,
    [EMAIL]: email && email.address,
    [PHONE_NUMBER]: phoneNumber,
    [CITY]: city,
    [COUNTRY]: country,
    [POSTAL_CODE]: pinCode,
    [PROVINCE_CODE]: provinceCode,
    [STREET_ADDRESS]: address,
    [BRAND_NAME]: brandName,
    [BRAND_SLUG]: slug,
  });
  const [error, setError] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [banner, setBanner] = useState({
    status: "",
    title: "",
    children: null,
    isOpen: false,
  });
  const [sheetActive, setSheetActive] = useState(false);
  const [brandNameData, setBrandNameData] = useState();
  const [brandHandleData, setBrandHandleData] = useState();
  const [firstNameData, setFirstNameData] = useState();
  const [lastNameData, setLastNameData] = useState();
  const [emailData, setEmailData] = useState();
  const [cityData, setCityData] = useState();
  const [countryData, setCountryData] = useState();
  const [postalCodeData, setPostalCodaData] = useState();
  const [phoneNumberData, setPhoneNumberData] = useState();
  const [streetAddressData, setStreetAddressData] = useState();

  const { data: vendorFormData } = useQuery(GET_VENDOR_PROFILE_SETTING);

  const { loading: loadingEditVendorForm, data: dataVendorForm } = useQuery(GET_VENDOR_FORM_LABEL);

  useEffect(() => {
    if (dataVendorForm) {
      const vendorLabelData = baseHelper.getResponseData(dataVendorForm, gql.GET_VENDOR_FORM_LABEL);
      if (vendorLabelData) {
        setBrandNameData(vendorLabelData.brandName);
        setBrandHandleData(vendorLabelData.brandHandle);
        setFirstNameData(vendorLabelData.firstName);
        setLastNameData(vendorLabelData.lastName);
        setEmailData(vendorLabelData.email);
        setCityData(vendorLabelData.city);
        setCountryData(vendorLabelData.country);
        setPostalCodaData(vendorLabelData.postalCode);
        setPhoneNumberData(vendorLabelData.phoneNumber);
        setStreetAddressData(vendorLabelData.streetAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorForm]);

  useEffect(() => {
    if (!vendorFormData) {
      return;
    }

    const responseData = baseHelper.getResponseData(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
    const responseError = baseHelper.getResponseError(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { vendorForm = {} } = responseData || {};
    setVendorFormSetting(vendorForm);
  }, [vendorFormData, gql.FETCH_VENDOR_FORM_SETTING]);

  useEffect(() => {
    setState({
      [FIRST_NAME]: firstName,
      [LAST_NAME]: lastName,
      [EMAIL]: email && email.address,
      [PHONE_NUMBER]: phoneNumber || (mobile && mobile.number),
      [CITY]: city,
      [COUNTRY]: country,
      [POSTAL_CODE]: pinCode,
      [PROVINCE_CODE]: provinceCode,
      [STREET_ADDRESS]: address,
      [BRAND_NAME]: brandName,
      [BRAND_SLUG]: slug,
    });
  }, [
    firstName,
    FIRST_NAME,
    lastName,
    LAST_NAME,
    email,
    EMAIL,
    phoneNumber,
    PHONE_NUMBER,
    city,
    CITY,
    country,
    COUNTRY,
    pinCode,
    POSTAL_CODE,
    provinceCode,
    PROVINCE_CODE,
    address,
    STREET_ADDRESS,
    brandName,
    BRAND_NAME,
    slug,
    BRAND_SLUG,
  ]);

  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError } = useQuery(
    GET_SELLER_FULFILLMENT
  );

  useEffect(() => {
    if (fulfillmentError) {
      setBanner({
        title: fulfillmentError,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    setShowProvinceCode(showProvinceCode);
  }, [fulfillmentData, fulfillmentError]);

  // gql states
  const [updateProviderProfile, { loading: updateProviderProfileLoading }] = useMutation(EDIT_PROFILE);

  if (isLoading || fulfillmentLoading || loadingEditVendorForm) {
    return <SkeletonAnnotated />;
  }

  const updateProfile = async () => {
    const formValues = {
      firstName: state[FIRST_NAME],
      lastName: state[LAST_NAME],
      email: state[EMAIL],
      phoneNumber: state[PHONE_NUMBER].toString(),
      brandName: state[BRAND_NAME],
      address: state[STREET_ADDRESS],
      city: state[CITY],
      country: state[COUNTRY],
      pinCode: state[POSTAL_CODE],
      provinceCode: state[PROVINCE_CODE],
      brand: {
        slug: state[BRAND_SLUG],
      },
    };

    if (!isShowProvinceCode) {
      delete formValues.provinceCode;
    }

    updateProviderProfile({
      variables: {
        input: {
          formValues,
        },
      },
    })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, UPDATE_PROFILE);
        if (responseError) {
          setBanner({
            title: responseError,
            status: "critical",
            isOpen: true,
          });
          return;
        }
        if (isModalActive) {
          setBanner({
            title: cms("message.success.profileUpdatedVerifyEmail"),
            children: cms("message.success.redirect"),
            status: "success",
            isOpen: true,
          });
          storageHelper.remove("token");
          setTimeout(() => {
            history.push("/login");
          }, 5000);
          setIsModalActive(false);
          return;
        }
        onNext();
        // currentUserRefetch();
      })
      .catch((exception) => {
        setBanner({
          status: "critical",
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  // check for any validation error or empty fields
  const validateFields = () => {
    const vendorRequiredData = { ...state };
    delete vendorRequiredData.provinceCode;
    const isAnyError = Object.values(error).some((err) => err);
    const isAnyEmptyField = Object.values(vendorRequiredData).some((value) => !value);
    const isValidCountry = getNames()
      .sort()
      .includes(state && state[COUNTRY]);
    return isAnyError || isAnyEmptyField || !isValidCountry;
  };

  const onSubmit = () => {
    if (validateFields()) {
      return;
    }
    if (mobile && !mobile.verified) {
      if (email && email.address !== state[EMAIL]) {
        setIsModalActive(true);
        return;
      }
      return;
    }
    updateProfile();
  };

  const handleChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    if (key === constant.BRAND_NAME && !isBrandHandle) {
      const brandHandleAccurateValue = baseHelper.generateBrandHandle(value);
      handleChange(constant.BRAND_SLUG, brandHandleAccurateValue);
    }
  };

  const handleValidation = async (key, value, label) => {
    let schema = {};
    const { provinceCode: { isRequired: provinceCodeRequired = false } = {}, postalCode: { min = 2 } = {} } =
      vendorFormSetting || {};
    if (key === constant.PROVINCE_CODE && !provinceCodeRequired && !value) {
      setError((prevState) => ({
        ...prevState,
        [key]: "",
      }));
      return;
    }
    if (key === constant.POSTAL_CODE) {
      schema = { min };
    }
    const validationError = await validate(cms, key, value, schema, label);
    setError((prevState) => ({
      ...prevState,
      [key]: validationError,
    }));
  };

  const getLabelData = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  const getPlaceholderText = (value) => {
    if (value && value.label) {
      return value.label;
    }
    return "";
  };

  const getFields = (text) => {
    const newTextField = {};
    newTextField[constant.FIRST_NAME] = firstNameData;
    newTextField[constant.LAST_NAME] = lastNameData;
    newTextField[constant.EMAIL] = emailData;
    newTextField[constant.CITY] = cityData;
    newTextField[constant.COUNTRY] = countryData;
    newTextField[constant.POSTAL_CODE] = postalCodeData;
    newTextField[constant.PHONE_NUMBER] = phoneNumberData;
    newTextField[constant.STREET_ADDRESS] = streetAddressData;
    return text
      .filter((item) => (item.key === constant.PROVINCE_CODE ? isShowProvinceCode : true))
      .map(({ key = "", type = "", label = "", placeholder = "", min = "" }) =>
        key !== constant.COUNTRY ? (
          <TextField
            key={key}
            type={type}
            id={key}
            label={`${getLabelData(newTextField[key]) || label}*`}
            placeholder={getPlaceholderText(newTextField[key]) || placeholder}
            value={(state && state[key] && state[key].toString()) || ""}
            min={min}
            onChange={(value) => handleChange(key, value)}
            onBlur={() => handleValidation(key, state && state[key], getLabelData(newTextField[key]) || label)}
            error={error && error[key]}
          />
        ) : (
          <Select
            label={`${getLabelData(newTextField[key]) || label}*`}
            value={(state && state[key] && state[key].toString()) || ""}
            options={getNames().sort()}
            placeholder={getPlaceholderText(newTextField[key]) || placeholder}
            onChange={(value) => handleChange(key, value)}
            onBlur={() => handleValidation(key, state && state[key])}
            error={error && error[key]}
          />
        )
      );
  };

  if ((error && error[BRAND_SLUG]) || (error && error[BRAND_NAME])) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <Layout>
      <EmailChangeModal
        isOpen={isModalActive}
        onClose={() => setIsModalActive(false)}
        onSubmit={() => updateProfile()}
        isLoading={updateProviderProfileLoading}
      />
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false, children: null });
            }}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.updateProfile.title")}
        description={cms("section.updateProfile.description")}
      >
        <Card title={cms("section.updateProfile.brandSection.title")}>
          <Card.Section>
            <TextContainer>{cms("section.updateProfile.brandSection.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                key={BRAND_NAME}
                id={BRAND_NAME}
                label={`${getLabelData(brandNameData, cms("section.updateProfile.brandSection.label.name"))}*`}
                placeholder={getLabelData(brandNameData, cms("section.updateProfile.brandSection.placeHolder.name"))}
                value={state && state[BRAND_NAME]}
                onChange={(value) => handleChange(BRAND_NAME, value)}
                onBlur={() =>
                  handleValidation(
                    BRAND_NAME,
                    state && state[BRAND_NAME],
                    getLabelData(brandNameData, cms("section.updateProfile.brandSection.label.name"))
                  )}
                error={error && error[BRAND_NAME]}
                helpText={cms("section.updateProfile.brandSection.helpText.name")}
              />
              <TextField
                id="BrandHandle"
                label={`${getLabelData(brandHandleData, cms("section.updateProfile.brandSection.label.handle"))}*`}
                placeholder={getLabelData(brandHandleData, cms("section.updateProfile.brandSection.label.handle"))}
                value={state && state[BRAND_SLUG]}
                onChange={(value) => handleChange(BRAND_SLUG, value)}
                onBlur={() =>
                  handleValidation(
                    constant.BRAND_SLUG,
                    state && state[BRAND_SLUG],
                    getLabelData(brandHandleData, cms("section.updateProfile.brandSection.label.handle"))
                  )}
                error={error && error[BRAND_SLUG]}
                helpText={cms("section.updateProfile.brandSection.helpText.handle")}
              />
            </FormLayout>
          </Card.Section>
        </Card>

        <Card
          title={cms("section.updateProfile.contactSection.title")}
          // actions={[{ content: "Learn more",onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.updateProfile.contactSection.description")}</TextContainer>
            <br />
            <FormLayout>{getFields(fields)}</FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: (
              <span>
                &ensp;&ensp;
                {isApproved === providerList.APPROVE ? cms("common.button.next") : cms("common.button.reviewVendor")}
                &ensp;&ensp;
              </span>
            ),
            id: "next",
            onAction: onSubmit,
            loading: updateProviderProfileLoading,
            disabled: validateFields(),
          }}
          secondaryActions={[
            {
              content: cms("common.button.previous"),
              id: "previous",
              onAction: () => onPrevious(),
              loading: false,
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Submit", onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

UpdateProfile.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default UpdateProfile;
