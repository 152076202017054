import React, { useContext } from "react";
import { Stack, Button } from "@shopify/polaris";
import PropTypes from "prop-types";
import { PrivateContext } from "lib/context";

const CardFooter = (props) => {
  const { cms } = useContext(PrivateContext);
  const { disabled, loading, updatedDate } = props;
  return (
    <>
      <br />
      <Stack>
        <Stack.Item fill />
        <Stack.Item>
          <Button primary submit disabled={disabled} loading={loading || false}>
            {updatedDate ? cms("common.button.update") : cms("common.button.submit")}
          </Button>
        </Stack.Item>
      </Stack>
    </>
  );
};

CardFooter.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
CardFooter.defaultProps = {
  loading: false,
  disabled: false,
};

export default CardFooter;
