import { gql } from "apollo-boost";

const CREATE_HOPSCOTCH_TASK = gql`
  mutation reinitiateDelivery($input: ReinitiateDeliveryInput) {
    reinitiateDelivery(input: $input) {
      data {
        deliveryJobId
        jobId
      }
      status
      error
    }
  }
`;

export default CREATE_HOPSCOTCH_TASK;
