// import packages
import React from "react";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { privacyData } from "./hrPrivacyCode";

const HRPrivacy = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: privacyData }} />
  );
};

export default withErrorBoundary(HRPrivacy);
