import React, { useEffect, useState, useContext } from "react";
import { Modal, Stack, TextField } from "@shopify/polaris";
import { useMutation, useLazyQuery } from "react-apollo";
import PropTypes from "prop-types";
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { baseHelper, storageHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import { PROXY_LOGIN } from "app/vendors/apollo/mutations";
import { VERIFY_OTP } from "app/vendors/apollo/queries";

// import cms
import cmsVendorList from "app/vendors/modules/operator/feature/list/cms/vendorListCMS";

function LoginVendor(props) {
  const {
    loginModalOpen,
    closeLoginModal,
    brandName,
    setBanner,
    setLoginModal,
    setSelectedBrand,
    isAdvanceVendor = true,
  } = props;
  let brand = "";
  let id = "";
  let role = "";

  if (isAdvanceVendor) {
    const vendorData = (typeof brandName === "string" && brandName.split("_")) || "";
    [brand = "", id = "", role = ""] = vendorData || [];
  }

  if (!isAdvanceVendor) {
    const { brand: vendorBrand, id: vendorId, role: userRole } = brandName;
    brand = vendorBrand || "";
    id = vendorId || "";
    role = userRole || "";
  }

  const { currentUser, history, cms } = useContext(PrivateContext);
  const vendorListCMS = cmsVendorList(cms);
  const { label, sectionContent, message, button } = vendorListCMS;
  const [selectedButton, setSelectedButton] = useState("");
  const [access, setAccess] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [loginVendorResponse, setLoginVendorResponse] = useState();
  const [otpEntered, setOtpEntered] = useState();
  const [otpBanner, setOtpBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });

  const [loginVendors, { data: vendorDataLogin, loading: loadingVendorLogin, error: errorVendorLogin }] = useMutation(
    PROXY_LOGIN
  );

  // verify Otp mutation
  const [otpVerify, { error: errorVerifyOtp, loading: loadingVerifyOtp, data: dataVerifyOtp }] = useLazyQuery(
    VERIFY_OTP
  );

  const handleLoginVendor = async (vendorId, vendorAccessType, roleName) => {
    setOtpBanner({ isOpen: false, title: "", status: "" });
    setOtpEntered("");
    const isReadOnlyAccess = vendorAccessType === constant.READ_ONLY_ACCESS;
    setAccess(isReadOnlyAccess);
    setSelectedButton(vendorAccessType);
    const logInData = { variables: { input: { id: vendorId, userRole: roleName, onlyRead: isReadOnlyAccess } } };
    await loginVendors(logInData);
  };

  const closeOtpModal = () => {
    setSelectedBrand("");
    setOtpEntered("");
    setOpenOtpModal(false);
  };

  const handleOTP = (otpText) => {
    if (parseInt(otpText, 10) && parseInt(otpText, 10) > -1) {
      return setOtpEntered(otpText);
    }
    return setOtpEntered("");
  };

  const verifyOTP = (token, otp, key) => {
    setSelectedButton(key);
    if (!token) {
      setBanner({
        title: message.token_expire,
        status: constant.CRITICAL,
        isOpen: true,
      });
      return;
    }
    const otpData = {
      token,
      otp,
    };
    otpVerify({
      variables: { input: otpData },
    });
  };

  const validateAndVerifyOTP = (val) => {
    const { token } = loginVendorResponse;
    if (!otpEntered) {
      const banner = {
        title: message.enterAccessCode,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setOtpBanner(banner);
      return;
    }
    verifyOTP(token, otpEntered, val);
  };

  // useEffect for verifyOtp Query call.
  useEffect(() => {
    const isSeller = baseHelper.isSeller(currentUser);
    const verifyOtpResponse = baseHelper.getResponseData(dataVerifyOtp, constant.gql.VERIFY_OTP);
    if (verifyOtpResponse) {
      if (!verifyOtpResponse.token) {
        setOpenOtpModal(false);
        setBanner({
          title: message.token_expire,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }
      storageHelper.set("token", verifyOtpResponse.token);
      setOtpEntered("");
      if (isSeller && baseHelper.isIframe()) {
        const serverUrl = window.origin;
        window.top.location.href = serverUrl;
      } else {
        history.push("/");
      }
    }
  }, [currentUser, dataVerifyOtp, history, message.token_expire, setBanner]);

  const isErrorOtpVerify = baseHelper.getResponseError(dataVerifyOtp, constant.gql.VERIFY_OTP);

  useEffect(() => {
    if (isErrorOtpVerify) {
      setOtpBanner({
        title: isErrorOtpVerify,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
  }, [isErrorOtpVerify]);

  useEffect(() => {
    if (errorVendorLogin || errorVerifyOtp) {
      setBanner({
        title: errorHelper.parse(errorVerifyOtp || errorVendorLogin),
        isOpen: true,
        status: constant.CRITICAL,
      });
    }
  }, [errorVendorLogin, errorVerifyOtp, setBanner]);

  // useEffect for login As vendor Mutation call.
  useEffect(() => {
    const responseLoginData = baseHelper.getResponseData(vendorDataLogin, constant.gql.PROXY_LOGIN);
    const responseLoginError = baseHelper.getResponseError(vendorDataLogin, constant.gql.PROXY_LOGIN);
    const isSeller = baseHelper.isSeller(currentUser);
    setLoginVendorResponse(responseLoginData);
    if (responseLoginData) {
      setLoginModal(false);
      setSelectedButton("");
      if (access) {
        storageHelper.set("token", responseLoginData.token);
        if (isSeller && baseHelper.isIframe()) {
          const serverUrl = window.origin;
          window.top.location.href = serverUrl;
        } else {
          history.push("/");
        }
        return;
      }
      setOpenOtpModal(true);
    }
    if (responseLoginError) {
      setLoginModal(false);
      setSelectedButton("");
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseLoginError });
    }
  }, [
    access,
    currentUser,
    history,
    setBanner,
    setLoginModal,
    setLoginVendorResponse,
    setOpenOtpModal,
    vendorDataLogin,
  ]);
  const acceptOnlyValidInput = (value, prevState) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && prevState);
  };

  return (
    <>
      {/* login as provider popup */}
      <Modal
        key={label.logInModal}
        open={loginModalOpen}
        onClose={closeLoginModal}
        title={`${label.logInAs} ${baseHelper.ucFirst(brand)}`}
        primaryAction={{
          content: label.fullAccess,
          onAction: () => handleLoginVendor(id, constant.FULL_ACCESS, role),
          disabled: loadingVendorLogin && selectedButton !== constant.FULL_ACCESS,
          loading: loadingVendorLogin && selectedButton === constant.FULL_ACCESS,
        }}
        secondaryActions={[
          {
            content: label.onlyReadAccess,
            onAction: () => handleLoginVendor(id, constant.READ_ONLY_ACCESS, role),
            disabled: loadingVendorLogin && selectedButton !== constant.READ_ONLY_ACCESS,
            loading: loadingVendorLogin && selectedButton === constant.READ_ONLY_ACCESS,
          },
        ]}
        sectioned
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>{sectionContent.logInAsAccess}</Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
      {/* end */}
      {/* Enter OTP popup */}
      <Modal
        key={label.otpModal}
        open={openOtpModal}
        onClose={closeOtpModal}
        title={`${label.logInAs} ${baseHelper.ucFirst(brand)}`}
        primaryAction={{
          content: button.confirmOtp,
          onAction: () => validateAndVerifyOTP(constant.VERIFY_LOGIN),
          disabled: loadingVerifyOtp && selectedButton !== constant.VERIFY_LOGIN,
          loading: loadingVerifyOtp && selectedButton === constant.VERIFY_LOGIN,
        }}
        secondaryActions={[
          {
            content: button.resendOtp,
            onAction: () => handleLoginVendor(id, constant.RESEND_OTP, role),
            disabled: loadingVendorLogin && selectedButton !== constant.RESEND_OTP,
            loading: loadingVendorLogin && selectedButton === constant.RESEND_OTP,
          },
          {
            content: button.readOnly,
            onAction: () => handleLoginVendor(id, constant.READ_ONLY_ACCESS, role),
            disabled: loadingVendorLogin && selectedButton !== constant.READ_ONLY_ACCESS,
            loading: loadingVendorLogin && selectedButton === constant.READ_ONLY_ACCESS,
          },
        ]}
        sectioned
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <Banner
                isOpen={otpBanner.isOpen}
                title={otpBanner.title}
                status={otpBanner.status}
                onDismiss={() => {
                  setOtpBanner({ isOpen: false, title: "", status: "" });
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label={label.enterCode}
                value={otpEntered}
                onChange={(value) => handleOTP(acceptOnlyValidInput(value, otpEntered))}
              />
            </Stack.Item>
            <Stack.Item>{sectionContent.otpNote}</Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
      {/* end */}
    </>
  );
}

LoginVendor.defaultProps = {
  loginModalOpen: false,
  brandName: "",
  closeLoginModal: () => {},
  setBanner: () => {},
  setLoginModal: () => {},
  setSelectedBrand: () => {},
  isAdvanceVendor: true,
};

LoginVendor.propTypes = {
  loginModalOpen: PropTypes.bool,
  brandName: PropTypes.string,
  closeLoginModal: PropTypes.func,
  setBanner: PropTypes.func,
  setLoginModal: PropTypes.func,
  setSelectedBrand: PropTypes.func,
  isAdvanceVendor: PropTypes.bool,
};

export default withFeature(withErrorBoundary(LoginVendor), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
