import React from "react";
import { Banner } from "@shopify/polaris";
import "react-multi-carousel/lib/styles.css";

import StyledCarousel from "./style";

const MultiCarousel = (props) => {
  const {
    containerClass,
    children,
    deviceType,
    desktopItems = 4,
    mobileItems = 2,
    slideItems,
    tabletItems = 3,
  } = props;

  const items = slideItems || children;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: desktopItems,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: tabletItems,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: mobileItems,
      paritialVisibilityGutter: 40,
    },
  };
 
  return (
    <StyledCarousel
      deviceType={deviceType}
      itemClass="image-item"
      containerClass={containerClass}
      responsive={responsive}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      showDots={false}
      arrows
    >
      {items || (
        <Banner title="No Data">
          <p>Please add slide data </p>
        </Banner>
      )}
    </StyledCarousel>
  );
};

export default MultiCarousel;
