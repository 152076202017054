import React from "react";
import { Card, EmptyState } from "@shopify/polaris";

const EmptyReportingState = () => {
  return (
    <Card>
      <Card.Section>
        <EmptyState
          heading="No graph data found"
          // image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          // image="https://polaris.shopify.com/assets/b9de27c544ab6b6cbaea59f9db8e634e.png"
        >
          <p>Try changing the filters </p>
        </EmptyState>
      </Card.Section>
    </Card>
  );
};
export default EmptyReportingState;
