// import react packages;
import React, { useContext } from "react";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { Card, TextContainer } from "@shopify/polaris";

// import helper
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import components
import PrestaShopConnect from "app/product/modules/provider/features/genric/prestaShopConnect/prestaShopConnect";

const VendorConnectPrestaShop = (prop) => {
  const { cms } = useContext(PrivateContext);
  const { setBanner } = prop;

  return (
    <>
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <PrestaShopConnect setBanner={setBanner} />
        </Card.Section>
      </Card>
    </>
  );
};

export default withFeature(withErrorBoundary(VendorConnectPrestaShop), {
  feature: constant.PRODUCT_IMPORT_WOOCOMMERCE,
  // subFeature: constant.PRODUCT_IMPORT_WOOCOMMERCE,
});
