// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_ORDER_LINE_VIEW = gql`
  query getOrderLineView($input: GetOrderLineView!) {
    getOrderLineView(input: $input) {
      data {
        _id
        accountingStatus
        actualQuantity
        createdAt
        fulfillableQuantity
        fulfillmentStatus
        fulfillmentType
        id
        image
        isAutoExpiry
        isAutoReject
        isCampaign
        isConsignmentPick
        isRefund
        isReturn
        isDelivered
        isVendorFulfilled
        moneyFormat
        name
        orderId
        orderNumber
        price
        productExists
        productId
        properties {
          name
          value
        }
        quantity
        shopifyLineItemId
        status
        title
        updatedAt
        vendor
        vendorId
        rejectedByVendor
        return {
          isAddToInventory
          isSellerManaged
          quantity
          reason
          requestedAt
          sellerNote
          status
          updatedAt
          vendorNote
        }
        fulfillmentStatus
        returnedQuantity
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LINE_VIEW;
