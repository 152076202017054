import React, { useContext } from "react";
import { Layout, Card, Checkbox } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// propTypes
import PropTypes from "prop-types";

const ManagePayment = (props) => {
  const { managePaymentProps } = props;
  const { checked, onChange } = managePaymentProps;
  const { cms } = useContext(PrivateContext);
  return (
    <Layout.AnnotatedSection description={cms("operator.section.showLiveRates.description")}>
      <Card sectioned>
        <Checkbox checked={checked} onChange={onChange} label={cms("operator.section.showLiveRates.label")} />
      </Card>
    </Layout.AnnotatedSection>
  );
};

ManagePayment.propTypes = {
  managePaymentProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
};
export default ManagePayment;
