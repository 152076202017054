import { gql } from "apollo-boost";

const INVOICE_DATA = gql`
  query getInvoiceData($input: GetInvoiceData!) {
    getInvoiceData(input: $input) {
      data {
        order {
          _id
          confirmed
          currency
          isOrderBasedCommission
          status
          shopifyOrderId
          taxesIncluded
          vendorLineCount
          totalOrderProductCount
          totalOrderAllProductCount
          totalOrderAllProductCountTran
        }
        orderLine {
          accountingStatus
          actualQuantity
          commissionSeller
          commissionSetting {
            rate
            type
          }
          commissionTax
          commissionTaxSetting {
            rate
            type
          }
          commissionVendor
          createdAt
          description
          isDiscountAllowed
          markUpPrice
          moneyFormat
          name
          price
          priceTaxRate
          productId
          quantity
          sellerCommissionTax
          sellerDiscount
          shippingAmount
          shippingTax
          shopifyShippingTax
          thirdPartyShippingAmount
          shippingTaxSetting {
            rate
            type
          }
          sku
          status
          totalDiscount
          totalTax
          vendorCommissionTax
          vendorGst
          vendorPst
          vendorVat
          vendorProductCount
        }
        seller {
          firstName
          lastName
          phoneNumber
          address
          city
          logo
          country
          plan {
            isBranding
            name
            price
          }
          pinCode
          brandName
          taxSetting {
            company
            customs
            vat
          }
        }
        orderLine {
          _id
          accountingStatus
          commissionSeller
          commissionSysAdmin
          commissionVendor
          createdAt
          customCharge
          customLabel
          discountAllocations {
            amount
            discount_application_index
          }
          dueDate
          fulfillableQuantity
          fulfillmentService
          fulfillmentStatus
          grams
          id
          image
          isDeleted
          isGiftCard
          isProductDeleted
          isShippingPaid
          isVendorDeleted
          markUpPrice
          moneyFormat
          name
          orderId
          orderNumber
          paidVia
          price
          vendorDiscount
          vendorId
          vendor
          variantTitle
          priceSet {
            shopMoney {
              amount
              currencyCode
            }
            presentmentMoney {
              amount
              currencyCode
            }
          }
          productExists
          productId
          # properties {
          #  name
          #  value
          # }
          quantity
          requiresShipping
          sellerDiscount
          sellerId
          shippingAmount
          shop
          shopifyLineItemId
          shopifyOrderDate
          shopifyOrderId
          shopifyOrderUpdateDate
          status
          totalDiscount
          totalDiscountSet {
            shopMoney {
              amount
              currencyCode
            }
            presentmentMoney {
              amount
              currencyCode
            }
          }
          totalTax
          totalDiscount
          taxLines {
            price
            rate
            title
            price_set {
              shopMoney {
                amount
                currencyCode
              }
              presentmentMoney {
                amount
                currencyCode
              }
            }
          }
          updatedAt
          trackingCompany
          trackingNumber
        }
        vendor {
          firstName
          lastName
          address
          city
          country
          pinCode
          email {
            address
          }
          taxSetting {
            company
            customs
            vat
            gst
            pst
          }
        }
        invoiceNumber
      }
      status
      error
    }
  }
`;
export default INVOICE_DATA;
