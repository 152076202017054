import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

import { OperatorProviderReview } from "./operator";
import { AdminProviderReview } from "./admin";

const { userKey } = constant;
const { operator, admin } = userKey;

const VendorReview = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === admin) {
      return <AdminProviderReview />;
    }
    if (role === operator) {
      return <OperatorProviderReview />;
    }

    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const VendorReviewComponent = getComponent(currentUserRole);
  return VendorReviewComponent;
};

export default withErrorBoundary(VendorReview);
