import { gql } from "apollo-boost";

const GET_SHIPPING_LABEL_STATUS = gql`
  query getShippingLabelStatus($input: ShippingLabel!) {
    getShippingLabelStatus(input: $input) {
      data {
        consignmentId
        consignmentStatus
        labels {
          tracking_reference
          label_id
          label_generation_status
        }
      }
      status
      error
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_SHIPPING_LABEL_STATUS };
