import { gql } from "apollo-boost";

const SYNC_TEMP_VENDOR_PRESTASHOP_PRODUCT = gql`
  mutation syncTempVendorPrestaShopProduct($input: BulkAction!) {
    syncTempVendorPrestaShopProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_TEMP_VENDOR_PRESTASHOP_PRODUCT;
