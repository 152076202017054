import React, { useState, useContext, useEffect } from "react";
import { Tabs } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { baseHelper, storageHelper } from "lib/helpers";
import { Spinner, SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import StyledTabs from "layout/private/components/tabs/tabsStyle";

// sub features
import { FETCH_VENDOR_ACCESS } from "app/advanceVendor/apollo/queries";
import { Welcome, UpdateProfile, PendingApproval, Finish } from "./features";

import { inReviewTab, approveTab } from "./stepperOnboardingConfig";

const ProviderStepperOnboarding = () => {
  const [status, setStatus] = useState(false);

  const {
    currentUser: { isApproved = "" },
    isLoading,
    setOnboardingTab,
  } = useContext(PrivateContext);

  const { onboardingSteps, providerList } = constant;
  const { FINISH, PENDING_APPROVAL, UPDATE_PROFILE, WELCOME } = onboardingSteps;

  const initialSelectedTab = storageHelper.get("setupProgress") || "welcome";
  const indexToTabKeyMapper = {
    welcome: WELCOME,
    pendingApproval: PENDING_APPROVAL,
  };

  const inReviewTabMapper = [WELCOME, UPDATE_PROFILE, PENDING_APPROVAL, FINISH];
  const approveTabMapper = [WELCOME, UPDATE_PROFILE, FINISH];
  const findTab = (key) => (status ? approveTab : inReviewTab.find((tab) => tab.id === key));
  const tabKey = indexToTabKeyMapper[initialSelectedTab] || UPDATE_PROFILE;
  const currentTab = findTab(tabKey);
  // states
  const [selected, setSelected] = useState(tabKey);
  const [selectedTabIndex, setSelectedTabIndex] = useState(currentTab.tabIndex);
  const [text, setText] = useState("");

  const { data: reviewVendorData, refetch } = useQuery(FETCH_VENDOR_ACCESS);
  const responseData = baseHelper.getResponseData(reviewVendorData, constant.gql.GET_VENDOR_ACCESS_SETTING);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (responseData && responseData.review) {
      setText(responseData.review.welcomeText);
      setStatus(responseData.review.isEnabled);
    }
  }, [selected, responseData]);

  const changeTab = (value) => {
    setSelected(value);
    const activeTab = findTab(value);
    setSelectedTabIndex(activeTab.tabIndex);
    // HACK: We can do better than this, context: Manage cms on basis of onboarding tab change
    if (value !== FINISH) {
      setOnboardingTab(value);
    }
  };

  const changeTabApprove = (value, tab) => {
    const tabs = isApproved ? approveTabMapper : inReviewTabMapper;
    if (![WELCOME, FINISH].includes(selected)) {
      const selectCurrentTab = tabs.findIndex((tabValue) => tabValue === selected);
      if (value <= selectCurrentTab) {
        setSelectedTabIndex(value);
        if (tab === providerList.APPROVE) {
          setSelected(approveTabMapper[value]);
        } else {
          setSelected(inReviewTabMapper[value]);
        }
      }
    }
  };

  const getOnboardingStep = () => {
    if (isLoading) {
      if (selected === WELCOME || selected === FINISH) {
        return <Spinner />;
      }
      return <SkeletonAnnotated primaryLines={15} />;
    }
    switch (selected) {
      case WELCOME:
        return <Welcome setSelectedTab={changeTab} />;
      case UPDATE_PROFILE:
        return <UpdateProfile setSelectedTab={changeTab} status={status} isApproved={isApproved} refetch={refetch} />;
      case PENDING_APPROVAL:
        return <PendingApproval setSelectedTab={changeTab} text={text} status={status} />;
      case FINISH:
        return <Finish />;
      default:
        return null;
    }
  };

  const topMargin25px = {
    marginTop: "25px",
  };

  return (
    <>
      {isApproved === providerList.APPROVE ? (
        <StyledTabs className="styled-tabs">
          <Tabs
            tabs={approveTab}
            selected={selectedTabIndex}
            fitted
            onSelect={(value) => changeTabApprove(value, providerList.APPROVE)}
          />
        </StyledTabs>
      ) : (
        <StyledTabs className="styled-tabs">
          <Tabs tabs={inReviewTab} selected={selectedTabIndex} fitted onSelect={(value) => changeTabApprove(value)} />
        </StyledTabs>
      )}
      <div style={topMargin25px}>{getOnboardingStep()}</div>
    </>
  );
};

export default ProviderStepperOnboarding;
