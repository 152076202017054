import React, { useContext, useState } from "react";
import { Card, Layout, Link, TextContainer } from "@shopify/polaris";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { SingleColumnLayout } from "layout/private/components";
import { Banner, ModalPopup, PermissionBanner, SkeletonList } from "lib/components";
import { QuickAction } from "app/reports";
import { BalanceMajor, ExportMinor } from "@shopify/polaris-icons";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";

// import subFeature
import PaymentList from "app/payments/modules/generic/list/list";
import ProviderExportPayment from "app/payments/modules/provider/features/export/export";

const ProviderPaymentList = () => {
  const { cms, currentUser, history, isLoading, isShopConnected, isShopifyOldVendor } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const [isOpenModal, setModal] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const linkData = [
    {
      action: () => setModal(!isOpenModal),
      caption: cms("label.caption"),
      id: "exportPayment",
      icon: ExportMinor,
      label: cms("label.export"),
      primary: true,
      shop: true,
    },
    {
      action: () => history.push("/payments/credit-note"),
      caption: cms("label.access"),
      icon: BalanceMajor,
      id: "creditNoteBalance",
      label: cms("label.balance"),
    },
  ];

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.banner.description.heading")}
              status="critical"
            >
              <p>
                <br />
                {cms("common.popup.migrate.banner.description.para1")}
                <br />
                <br />
                {cms("common.popup.migrate.banner.description.para2")}
              </p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_VENDOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>
                      <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para1")}
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para2")}
                    </TextContainer>
                  </Banner>
                </TextContainer>
                <br />
                {banner.isOpen && isOpen && (
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    action={banner.action}
                    onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                    isScrollTop={banner.isOpen}
                  />
                )}
              </Card>
              <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setModalBanner={setBanner} />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <Layout>
          {!isOpen && banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <QuickAction link={linkData} />
            <Card>
              <PaymentList setBanner={setBanner} />
            </Card>
          </Layout.Section>

          <ProviderExportPayment openModal={isOpenModal} setOpenModal={setModal} />
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  //  <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={getSecondaryComponent()} />
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withFeature(withErrorBoundary(ProviderPaymentList), { feature: constant.PAYMENTS });
