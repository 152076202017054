import { gql } from "apollo-boost";

const GET_SALES_METRICS = gql`
  query getSalesMetrics($input: GetSalesMetrics) {
    getSalesMetrics(input: $input) {
      data {
        sales {
          month
          revenue
        }
      }
      error
      status
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export { GET_SALES_METRICS };
