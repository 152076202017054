import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import ClickCollect from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const HybridClickCollect = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <div className="hybridClickCollect">
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.clickCollect.title")}</Heading>
          </Stack.Item>
          <br />
          <TextStyle variation="subdued">{cms("hybrid.clickCollect.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <ClickCollect method="hybridClickCollect" />
    </div>
  );
};

export default HybridClickCollect;
