import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";

import { ConnectShop } from "./provider/features";

const { userKey } = constant;
const { provider } = userKey;

const ProductConnectShop = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === provider) {
      return <ConnectShop />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductConnectShopComponent = getComponent(currentUserRole);
  return ProductConnectShopComponent;
};

export default ProductConnectShop;
