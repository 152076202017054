import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis } from "recharts";

// import props
import { stackedBarGraphProp } from "app/reporting/modules/operator/props";

const StackedBarGraph = (props) => {
  const { data, width = 400, height = 250 } = props;
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <XAxis dataKey="name" />
      <Bar dataKey="product" stackId="a" fill="#8884d8" />
      <Bar dataKey="revenue" stackId="a" fill="#82ca9d" />
    </BarChart>
  );
};

StackedBarGraph.propTypes = stackedBarGraphProp.type;

export default StackedBarGraph;
