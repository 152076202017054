import { gql } from "apollo-boost";

const CUSTOMER = gql`
  mutation customer($input: CustomerData!) {
    customer(input: $input) {
      status
      error
      data
    }
  }
`;

export default CUSTOMER;
