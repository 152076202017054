import React, { useContext, useEffect, useState } from "react";
import { Card, FormLayout, PageActions, Select, Stack, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import mutation
import { ADD_PRODUCT_INFORMATION } from "app/product/apollo/mutations";

// import queries
import {
  GET_CATALOG,
  GET_PRODUCT_SETTING,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TYPE,
  GET_WHOLESALE_SETTING,
} from "app/product/apollo/queries";
import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

// import context
import { OnboardingContext, PrivateContext } from "lib/context";
import { ProductContext } from "app/product/modules/generic/context";

// import component
import Tags from "app/product/modules/generic/features/form/tag/tag";
import Catalog from "app/product/modules/generic/features/form/tag/catalog";

// import proptype
import { tabProp } from "app/product/modules/generic/propTypes";

const Information = () => {
  const { isOnboarding, onNext, onPrevious } = useContext(OnboardingContext);
  const { handleTabChange, setBanner } = useContext(ProductContext);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isHideTag, setIsHideTag] = useState(true);
  const [isHideType, setIsHideType] = useState(true);
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);
  const [catalog, setCatalog] = useState("");
  const [productFormSetting, setProductFormSetting] = useState();
  const [titleData, setTitleData] = useState();
  const [descriptionData, setDescriptionData] = useState();
  const [priceData, setPriceData] = useState();
  const [comparePriceData, setComparePriceData] = useState();
  const [tagData, setTagData] = useState();
  const [typeData, setTypeData] = useState();
  const [wholesalePriceData, setWholesalePriceData] = useState();
  const [isHideCollection, setIsHideCollection] = useState(true);

  const [wholesalePrice, setWholesalePrice] = useState("");
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState({});
  const { CRITICAL, gql, INVENTORY } = constant;

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { data: productSettingData, error: productSettingError, loading: productSettingLoading } = useQuery(
    GET_PRODUCT_SETTING
  );

  const {
    loading: loadingProductForm,
    // error: errorProductForm,
    data: dataProductForm,
    // refetch: refetchProductForm,
  } = useQuery(GET_PRODUCT_FORM);

  const { loading: catalogLoading, data: catalogData } = useQuery(GET_CATALOG);

  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);

  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_INFORMATION);

  // load product catalog data
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || {};

  useEffect(() => {
    if (productSettingError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productSettingError });
      return;
    }
    if (!productSettingData) {
      return;
    }
    const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);
    if (productError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productError });
      return;
    }
    const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
    const { isHideTagAndType: hideTagAndType, isHideTag: hideTag, isHideType: hideType, isCatalogHidden } =
      productSetting || {};
    setIsHideTagAndType(!!hideTagAndType);
    setIsHideTag(!!hideTag);
    setIsHideType(!!hideType);
    setIsHideCollection(!!isCatalogHidden);
  }, [CRITICAL, productSettingData, productSettingError, setBanner, setIsHideTagAndType, setIsHideTag, setIsHideType]);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, "getProductFormSetting");
      // const productFormError = baseHelper.getResponseError(errorProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        // setIsProductFormsetting(true);
        delete productLabel.vendor;
        delete productLabel.markup;
        setTitleData(productLabel.title);
        setDescriptionData(productLabel.description);
        setPriceData(productLabel.price);
        setComparePriceData(productLabel.comparePrice);
        setTagData(productLabel.tags);
        setTypeData(productLabel.productType);
        setProductFormSetting(productLabel);
        setWholesalePriceData(productLabel.wholesalePrice);
        setCatalog(productLabel.catalog);
      }
    }
  }, [dataProductForm]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  if (
    productTagLoading ||
    productTypeLoading ||
    productSettingLoading ||
    loadingProductForm ||
    wholesaleLoading ||
    catalogLoading
  ) {
    return <SkeletonCard />;
  }

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const handleChange = (fieldName, fieldValue) => {
    setDisabledButton(false);
    setData({
      ...data,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = (content) => {
    setDescription(content);
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const addProduct = async (formValues) => {
    if (isWholesalePrice) {
      // eslint-disable-next-line no-param-reassign
      formValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice) };
    }

    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const responseData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_INFORMATION);

      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_INFORMATION);

      if (responseData) {
        if (isOnboarding) {
          onNext();
          return;
        }
        setMessage(cms("message.success.add"));
        setTimeout(() => {
          history.push(`edit/${encodeURIComponent(responseData)}?tab=${INVENTORY}`, { add: true });
        }, 1000);
      }
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const submitCallBack = async () => {
    const formValues = {
      title,
      description,
      productType: selectedProductType,
      tags: data && data.tags,
      shopifyCatalogId: data && data.shopifyCatalogId,
    };

    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    try {
      // add product
      const productId = await addProduct(formValues);
      if (productId) {
        handleTabChange(1);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };

    const dataToCheck = {
      title,
      description,
      comparePrice,
      price,
      catalog: data.shopifyCatalogId || [],
    };

    // if (!isHideTagAndType) {
    //   dataToCheck.productType = selectedProductType;
    //   dataToCheck.tags = (data && data.tags && data.tags.length) || null;
    // }

    if (isHideTag) {
      dataToCheck.tags = (data && data.tags && data.tags.length) || null;
    }
    if (isHideType) {
      dataToCheck.productType = selectedProductType;
    }

    if (isHideCollection && productFormSetting && productFormSetting.catalog) {
      delete productFormSetting.catalog;
    }

    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.title"),
      };
      setBanner(banner);
      return;
    }

    if (isWholesalePrice) {
      dataToCheck.wholesalePrice = parseFloat(wholesalePrice || 0);
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    let requiredTagLabel = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys
        // .filter((item) =>
        //   isHideTagAndType ? item !== constant.PRODUCT_TYPE && item !== constant.PRODUCT_TAGS : requiredKeys
        // )
        .filter((item) => {
          if (!isHideTag && item === constant.PRODUCT_TAGS) {
            return false;
          }
          if (!isHideType && item === constant.PRODUCT_TYPE) {
            return false;
          }
          if (item === constant.WHOLESALE_PRICE) {
            return !!isWholesalePrice;
          }
          return true;
        })
        .forEach((key) => {
          const valueData = dataToCheck[key];
          if (
            (!valueData || valueData.length === 0 || valueData === "undefined") &&
            constant.INFORMATION_KEY.includes(key)
          ) {
            if (dataToCheck[key] === constant.TAGS && availableProductTags) {
              requiredTagLabel = productFormSetting[key].label || cms(`label.${key}`) || key;
            } else {
              errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
            }
          }
        });
    }

    if (requiredTagLabel) {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.tag"),
      };
      setBanner(banner);
      return;
    }

    if (errorField) {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  const required = (value) => {
    if (value && value.isRequired) {
      return "*";
    }
    return "";
  };

  const getLabel = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  return (
    <>
      <Card
        title={cms("label.productInformation")}
        id="productInformation"
        // actions={[
        //   {
        //     content: cms("label.more"),
        //     onAction: () => {
        //       learnMore("productInformation", cms("label.productInformation"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${(titleData && titleData.label) || cms("label.title.informationTitle")}*`}
              error=""
              value={title || ""}
              onChange={(value) => {
                setTitle(value);
                setDisabledButton(false);
              }}
              onBlur={() => title && setTitle(title.trim())}
            />
            {`${getLabel(descriptionData, cms("label.description"))}${required(descriptionData)}`}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={`${getLabel(priceData, cms("label.price"))}${required(priceData)}`}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix={moneyFormat}
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                  setDisabledButton(false);
                }}
              />
              <TextField
                id="comparePrice"
                label={`${getLabel(comparePriceData, cms("label.comparePrice"))}${required(comparePriceData)}`}
                min={0}
                placeholder="0.00"
                prefix={moneyFormat}
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>
            {isWholesalePrice && (
              <FormLayout.Group>
                <TextField
                  id="wholesalePrice"
                  label={`${getLabel(wholesalePriceData, cms("label.wholesalePrice"))}${required(wholesalePriceData)}`}
                  min={0}
                  placeholder="0.00"
                  prefix={moneyFormat}
                  value={wholesalePrice || ""}
                  onChange={(value) => {
                    setWholesalePrice(acceptOnlyValidInput(value, wholesalePrice));
                    setDisabledButton(false);
                  }}
                  maxLength={13}
                />
                <Stack>
                  <Stack.Item fill />
                </Stack>
              </FormLayout.Group>
            )}
            {isHideType ? (
              <Stack vertical>
                {productTypeOptions && productTypeOptions.length && (
                  <Select
                    label={`${getLabel(typeData, cms("label.type"))}${required(typeData)}`}
                    options={productTypeOptions}
                    helpText={cms("subtext.type")}
                    placeholder={cms("placeholder.selectProductType")}
                    onChange={(value) => setSelectedProductType(value)}
                    value={selectedProductType}
                  />
                )}
              </Stack>
            ) : null}
            {isHideTag ? (
              <Stack vertical>
                {availableProductTags && availableProductTags.tags && availableProductTags.tags.length && (
                  <Tags
                    label={`${getLabel(tagData, cms("label.tags"))}${required(tagData)}`}
                    placeholder={cms("placeholder.tag")}
                    data={data}
                    helpText={cms("subtext.tags")}
                    setData={setData}
                    handleChange={handleChange}
                    productTag={availableProductTags}
                  />
                )}
              </Stack>
            ) : null}
            {!isHideCollection ? (
              <Catalog
                label={`${getLabel(catalog, cms("label.catalog"))}${required(catalog)}`}
                placeholder={cms("placeholder.catalog")}
                data={data}
                helpText={cms("subtext.catalog")}
                isHideCollection={isHideCollection}
                // setData={setData}
                handleChange={handleChange}
                productCatalog={(productCatalogOption && productCatalogOption.catalog) || []}
              />
            ) : null}
            <br />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms(`common.button.${isOnboarding ? "submit" : "save"}`),
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
          disabled: disabledButton,
        }}
        secondaryActions={
          isOnboarding && [
            {
              content: cms("common.button.previous"),
              onAction: onPrevious,
            },
          ]
        }
      />
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

Information.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(Information), { feature: constant.PRODUCT_USING_FORM });
