import { gql } from "apollo-boost";

const UPDATE_MARKUP = gql`
  mutation updateMarkup($input: UpdateMarkup!) {
    updateMarkup(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_MARKUP;
