import { gql } from "apollo-boost";

const GENERATE_PASSWORD = gql`
  mutation GENERATE_PASSWORD($input: OtpInput!) {
    otp(input: $input) {
      data
      status
      error
    }
  }
`;
export default GENERATE_PASSWORD;
