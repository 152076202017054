import { gql } from "apollo-boost";

const GET_SELLER_VARIANTS = gql`
  query getProductAndSellerProduct($input: GetProductSeller) {
    getProductAndSellerProduct(input: $input) {
      data {
        sellerProduct {
          _id
          sku
          images {
            imageId
            imageUrl
            position
            shopifyImageId
            url
          }
          variants {
            option1Val
            option2Val
            option3Val
            option3
            option1
            option2
            barcode
            weightUnit
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
            comparePrice
            image
            inventoryManagement
            inventoryQuantity
            isShipping
            markUp {
              price
              type
            }
            price
            sku
            vendorDiscount {
              price
              type
            }
            sellerDiscount {
              price
              type
            }
            weight
          }
        }
      }
      error
      status
    }
  }
`;

export default GET_SELLER_VARIANTS;
