import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { storageHelper } from "lib/helpers";
import BulkOrderFulfillment from "./generic/bulkFulfill/bulkFulfill";

const { userKey } = constant;
const { operator, provider } = userKey;

const OrderView = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }

    if (role === operator) {
      return <BulkOrderFulfillment />;
    }
    if (role === provider) {
      return <BulkOrderFulfillment />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderViewComponent = getComponent(currentUserRole);
  return OrderViewComponent;
};

export default OrderView;
