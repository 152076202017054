import React, { useContext } from "react";
import { Layout, Card, TextField, TextContainer } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import components
import ImageComponent from "lib/components/dropZone/dropZone";

const BrandSection = () => {
  const { cms = {} } = useContext(PrivateContext);
  const getFields = () => {
    return (
      <>
        <TextField
          label={cms("section.brand.label.name")}
          placeholder={cms("section.brand.placeHolder.name")}
          value=""
          disabled
        />
        <br />
        <TextField
          label={cms("section.brand.label.handle")}
          placeholder={cms("section.brand.placeHolder.handle")}
          value=""
          disabled
        />
        <br />
        <TextField
          label={cms("section.brand.label.description")}
          placeholder={cms("section.brand.placeHolder.description")}
          value=""
          multiline
          disabled
        />
        <br />
        <span>{cms("section.brand.label.logo")}</span>
        <ImageComponent size={10} disabled />
        <br />
        <span>{cms("section.brand.label.cover")}</span>
        <ImageComponent size={10} disabled />
      </>
    );
  };
  return (
    <Layout.AnnotatedSection title={cms("section.brand.title")} description={cms("section.brand.description")}>
      <Card title={cms("section.brand.subTitle")}>
        <Card.Section>
          <TextContainer>{cms("section.brand.subDescription")}</TextContainer>
          <br />
          {getFields()}
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default BrandSection;
