import { gql } from "apollo-boost";

const ONBOARDING_ACTIVITY = gql`
  subscription {
    onBoardActivityView {
      data {
        onBoardActivity {
          _id
          store
          email
          message
          country
          platform
          createdAt
          activity
        }
      }
    }
  }
`;

export default ONBOARDING_ACTIVITY;
