import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card, TextStyle, Subheading, Stack } from "@shopify/polaris";
import { OrderAnalyticStyle } from "app/analytics/modules/generic/style";
import PieChart from "app/analytics/modules/provider/pieChart";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

import { GET_GA_DETAIL } from "app/analytics/apollo/queries";

const OrderAnalyticsChart = React.forwardRef((props, ref) => {
  const { orderReport, countryList } = props;
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;

  const [ageLabel, setAgeLabel] = useState([]);
  const [ageValue, setAgeValue] = useState([]);

  const [genderLabel, setGenderLabel] = useState([]);
  const [genderValue, setGenderValue] = useState([]);

  const { loading: googleAnalyticsDetailLoading, data: googleAnalyticsData } = useQuery(GET_GA_DETAIL);

  useEffect(() => {
    const googleAnalyticsResponseData = baseHelper.getResponseData(googleAnalyticsData, constant.gql.GET_GA_DETAIL);

    if (googleAnalyticsResponseData) {
      const { age = [], gender = [] } = googleAnalyticsResponseData || {};
      const ageData = baseHelper.transformGAData(age, true);
      const genderData = baseHelper.transformGAData(gender);
      if (ageData) {
        setAgeLabel(ageData.label);
        setAgeValue(ageData.value);
      }

      if (genderData) {
        setGenderLabel(genderData.label);
        setGenderValue(genderData.value);
      }
    }
  }, [googleAnalyticsData]);

  const genderData = {
    // labels: ["Male", "Female"],
    labels: genderLabel,
    datasets: [
      {
        label: "# of Votes",
        data: genderValue,
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const ageData = {
    // labels: ["Age 18-25", "Age 25-35", "Age 35+"],
    labels: ageLabel,
    datasets: [
      {
        label: "# of Votes",
        data: ageValue,
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const geographyData = {
    labels: countryList[0],
    datasets: [
      {
        label: "# of Votes",
        data: countryList[1],
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const isCountryLength = !!(countryList && countryList.length && countryList[0].length);
  const isAgeLength = !!(ageValue && ageValue.length);
  const isGenderLength = !!(genderValue && genderValue.length);

  const isDemographic = isCountryLength || isAgeLength || isGenderLength;

  if (googleAnalyticsDetailLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div ref={ref}>
        <OrderAnalyticStyle className="analytics-chart">
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Card>
                <Subheading element="strong">{orderReport.orderRentalCount || 0}</Subheading>
                <br />
                <TextStyle>Rental Orders</TextStyle>
              </Card>
              <Card>
                <Subheading element="strong">{`${moneyFormat} ${orderReport.totalPrice || 0}`}</Subheading>
                <br />
                <TextStyle>Revenue Generator</TextStyle>
              </Card>
            </Stack>

            <h2 className="Polaris-Heading graph-heading"> Customer Demography </h2>
            {isDemographic &&
              (<div style={{ display: "flex", justifyContent: "space-around" }}>
                {isCountryLength &&
                  <div>
                    <PieChart data={geographyData} />
                    <span className="graph-title">Geography</span>
                  </div>
                }

                {isAgeLength &&
                  <div>
                    <PieChart data={ageData} />
                    <span className="graph-title">Age</span>
                  </div>
                }

                {isGenderLength &&
                  <div>
                    <PieChart data={genderData} />
                    <span className="graph-title">Gender</span>
                  </div>
                }
              </div>) || <center>No Data Available</center>
            }
          </Card.Section>
        </OrderAnalyticStyle>
      </div>
    </>
  );
});

export default OrderAnalyticsChart;
