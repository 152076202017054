import React, { useState, useEffect, useContext } from "react";

import htmlParse from "html-react-parser";
import { Card, TextStyle } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Notes = (props) => {
  const { cms } = useContext(PrivateContext);
  const { dataItem, note } = props;
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    setNotes(dataItem.notes);
  }, [dataItem]);

  return (
    <Card title={cms("label.note")}>
      <Card.Section>
        <div className="notes">
          <TextStyle variation={!notes && constant.SUBDUED}>
            {(notes &&
              notes.length &&
              notes.map((item, index) => (
                <>
                  {`${index + 1}. `}
                  {htmlParse(item)}
                  <br />
                </>
              ))) ||
              note ||
              cms("label.noNotes")}
          </TextStyle>
        </div>
      </Card.Section>
    </Card>
  );
};

export default Notes;
