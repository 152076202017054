import React, { useCallback, useContext, useEffect, useState } from "react";
import { ChoiceList, Tag } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import helpers
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

// import helpers components
import { ResourceList } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import MoreFilters from "app/payments/modules/generic/moreFilters/moreFilter";
import { errorHelper } from "lib/helpers";

// import gql
import ACCOUNT_LIST from "app/payments/apollo/query/";
import { GET_INVOICE_SETTING } from "app/payments/apollo/queries";
import { PAYMENT_LIST } from "app/payments/apollo/subscriptions";
import BULK_ACCOUNT from "app/payments/apollo/mutation/bulkAccount";

// import cms
import actionData from "./cms/payment";
import { listProp } from "./propTypes";

// import subFeature
import PaymentRow from "./features/paymentRow";

const PaymentList = (props) => {
  const { setBanner } = props;
  const { cms, history, currentUser } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const {
    _id,
    roles: { name },
  } = currentUser;
  const { CRITICAL, DISPUTE, gql, SUCCESS, userKey } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [loading, setLoading] = useState(true);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const [paymentListData, setPaymentList] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [isInvoiceEnabled, setInvoiceEnabled] = useState(true);
  const [bulkAccount] = useMutation(BULK_ACCOUNT);
  const resourceData = actionData(cms);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [activePopover, setActivePopover] = useState({});
  const [popoverDisable, setPopoverDisable] = useState(false);

  const renderItem = (item) => {
    return (
      <PaymentRow
        item={item}
        setBanner={setBanner}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        isInvoiceEnabled={isInvoiceEnabled}
        popoverDisable={popoverDisable}
      />
    );
  };

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : paramFilter,
    path: queryParam.path,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: orderLinesData, loading: orderLoading, subscribeToMore, error, refetch } = useQuery(ACCOUNT_LIST, {
    variables: {
      input: inputData,
    },
  });
  const { error: errorGetInvoiceAccess, data: dataGetInvoiceAccess } = useQuery(GET_INVOICE_SETTING);

  let invoiceAccessResponseData = {};
  let invoiceAccessErrorData = "";

  if (name !== userKey.systemAdminLevel) {
    // eslint-disable-next-line no-const-assign
    invoiceAccessResponseData = baseHelper.getResponseData(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);
    // eslint-disable-next-line no-const-assign
    invoiceAccessErrorData = baseHelper.getResponseError(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);
  }

  const { payment } = invoiceAccessResponseData || {};
  useEffect(() => {
    if (payment) {
      const { invoice } = payment;
      if (invoice) {
        const { access: invoiceAccess } = invoice;
        if (invoiceAccess) {
          const { isAllVendor: disabledForAllVendors, selectedVendor = [] } = invoiceAccess;
          setInvoiceEnabled(!isVendor || !(disabledForAllVendors || selectedVendor.includes(_id)));
        }
      }
    }
  }, [payment, isVendor, _id]);

  useEffect(() => {
    if (invoiceAccessErrorData) {
      setBanner({ status: constant.CRITICAL, title: invoiceAccessErrorData, isOpen: true });
    }
    if (errorGetInvoiceAccess) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetInvoiceAccess), isOpen: true });
    }
  }, [setBanner, invoiceAccessErrorData, errorGetInvoiceAccess]);

  useEffect(() => {
    setSelectedItems([]);
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: CRITICAL });
    }
  }, [error, setBanner, CRITICAL]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const orderLineError = baseHelper.getResponseError(orderLinesData, gql.GET_ACCOUNT_LIST);
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: CRITICAL, title: orderLineError });
    }
  }, [orderLineError, setBanner, CRITICAL]);

  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, gql.GET_ACCOUNT_LIST);

  useEffect(() => {
    if (responseOrderLinesData) {
      setPaymentList(responseOrderLinesData && responseOrderLinesData.paymentList);
      setPaymentCount(responseOrderLinesData && responseOrderLinesData.count);
    }
  }, [responseOrderLinesData]);

  useEffect(() => {
    subscribeToMore({
      document: PAYMENT_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };
  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    const searchValue = baseHelper.setQueryParams(history.location, { search: "" });
    setSearch("");
    history.push(`${history.location.pathname}?${searchValue}`);
  };
  const handleBulkAccount = async (key) => {
    try {
      const response = await bulkAccount({ variables: { input: { ids: selectedItems, key } } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.BULK_ACCOUNT);
      if (responseError) {
        setBanner({
          isOpen: true,
          title: responseError,
          status: CRITICAL,
        });
      }
      const responseData = baseHelper.getResponseData(response.data, constant.gql.BULK_ACCOUNT);
      if (responseData) {
        setBanner({ isOpen: true, title: cms("common.message.success.background"), status: SUCCESS });
        refetch();
        setSelectedItems([]);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(exception),
        status: CRITICAL,
      });
    }
  };

  const bulkActionButton = (key) => {
    let disabled = true;
    if (selectedItems && selectedItems.length) {
      selectedItems.forEach((paymentId) => {
        const index = paymentListData.findIndex((paymentList) => {
          const { _id: id } = paymentList;
          return id === paymentId;
        });
        if (key === DISPUTE && paymentListData[index].accountingStatus !== DISPUTE) {
          disabled = false;
        }
      });
    }
    return disabled;
  };

  const bulkActions = [
    {
      content: cms("label.markDispute"),
      onAction: () => handleBulkAccount(DISPUTE),
      disabled: bulkActionButton(DISPUTE),
    },
  ];

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    setFilterByStatus(null);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    handleSelectedFilterRemove();
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {option === constant.FORECAST
            ? cms("label.forecast")
            : option === constant.DUE
            ? cms("label.duePayment")
            : option === constant.DISPUTE
            ? cms("label.dispute")
            : baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }
  const appliedFilters = appliedFilterArrObj;

  const filterOptions = [{ label: cms("label.brandName"), value: constant.VENDOR }];

  const filterByStatusOption = [
    {
      label: cms("label.all"),
      value: "all",
    },
    {
      label: cms("label.forecast"),
      value: "forecast",
    },
    {
      label: cms("label.duePayment"),
      value: "due",
    },
    {
      label: cms("label.pay"),
      value: "paid",
    },
    {
      label: cms("label.dispute"),
      value: "dispute",
    },
  ];

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  return (
    <>
      <ResourceList
        items={paymentListData}
        idForItem={(orderLine) => {
          const { _id: id } = orderLine;
          return id;
        }}
        renderItem={renderItem}
        loading={loading || orderLoading}
        queryValue={search}
        onQueryClear={handleQueryClear}
        onQueryChange={handleSearchChange}
        sortOptions={resourceData.sortOptions}
        sortValue={sortValue}
        onSortChange={handleSort}
        selectedItems={selectedItems}
        onSelectionChange={handleSelect}
        bulkActions={bulkActions}
        filters={filters}
        appliedFilters={appliedFilters}
        handleClearAll={handleClearAll}
        selectable
        count={paymentCount}
        page={page}
        perPage={perPage}
        setPage={handlePageChange}
        setPopoverDisable={setPopoverDisable}
        setPerPage={handlePageLimitChange}
      />
    </>
  );
};
PaymentList.propTypes = listProp.type;
export default PaymentList;
