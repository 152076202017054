import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, Layout, PageActions, TextContainer, TextField } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { EDIT_SELLER_PLAN } from "app/setup/apollo/mutations";
import { GET_SELLER_PLAN_DETAILS } from "app/setup/apollo/queries";
import { withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import validate from "./yup";

const PlanEdit = () => {
  const { history, match, cms, currentUser = {} } = useContext(PrivateContext);
  const { isChargebee, chargebee } = currentUser;
  const chargebeeEnabled = (isChargebee && chargebee) || false;
  const { gql, PROVINCE_CODE } = constant;
  const { params } = match;
  const [errorMessage, setErrorMessage] = useState({});
  const [updateSellerPlan, { loading }] = useMutation(EDIT_SELLER_PLAN);
  const [values, setValues] = useState({
    price: "",
    productLimit: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [sellerPlanDetails, setSellerPlanDetails] = useState({});

  const { data: sellerPlanData, loading: sellerPlanLoading, error: sellerPlanError } = useQuery(
    GET_SELLER_PLAN_DETAILS,
    {
      variables: { input: { sellerId: currentUser._id, planId: params.id } },
    }
  );

  useEffect(() => {
    if (!chargebeeEnabled) {
      const bannerValue = {
        status: constant.CRITICAL,
        title: cms("baner.error.title.enable"),
        onDismiss: () => setBanner({ isOpen: false, title: "", status: "" }),
        action: {
          content: cms("baner.error.button.enable"),
          onAction: () => history.push("/setting/provider/subscription"),
        },
      };
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
    }
  }, [chargebeeEnabled, cms, history]);

  useEffect(() => {
    const bannerTitle = sellerPlanError;
    if (bannerTitle) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(sellerPlanError) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerPlanError]);

  useEffect(() => {
    if (!sellerPlanData) {
      return;
    }

    const planResponseData = baseHelper.getResponseData(sellerPlanData, gql.GET_SELLER_PLAN_DETAILS);
    if (!planResponseData) {
      return;
    }
    const planResponseError = baseHelper.getResponseError(sellerPlanData, gql.GET_SELLER_PLAN_DETAILS);
    if (planResponseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: planResponseError });
      return;
    }
    setSellerPlanDetails(planResponseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerPlanData, gql.FETCH_VENDOR_FORM_SETTING]);

  useEffect(() => {
    if (sellerPlanDetails) {
      const { price = "", productLimit = "" } = sellerPlanDetails;
      setValues({
        price,
        productLimit,
      });
    }
  }, [sellerPlanDetails]);
  const acceptOnlyValidInputs = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && values.productLimit);
  };
  const handleChangeEvent = (field, value) => {
    if (value) {
      setSubmitDisabled(false);
    }
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isAnyError = () => Object.values(errorMessage).some((error) => error);
  const isAllValuesFilled = () =>
    Object.keys(values).every((key) => {
      if (key === PROVINCE_CODE) {
        return true;
      }
      return typeof values[key] === "boolean" ? true : values[key];
    });

  const onSubmit = async () => {
    try {
      const res = await updateSellerPlan({
        variables: {
          input: {
            planId: params.id,
            price: values.price.toString(),
            productLimit: values.productLimit.toString(),
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_SELLER_PLAN);
      let bannerValue;
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_SELLER_PLAN);
        bannerValue = { status: constant.CRITICAL, title: errorResponse };
      }
      bannerValue = { status: constant.SUCCESS, title: cms("banner.success.message.plan") };
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
      setSubmitDisabled(true);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const handleValidation = async (field, value) => {
    const error = await validate(field, value, cms);
    setSubmitDisabled(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };
  const acceptOnlyValidInput = (value) => {
    return (baseHelper.isValidPrice(value) && value) || (value !== "" && values.price);
  };

  if (sellerPlanLoading) {
    return <Spinner />;
  }

  return (
    <>
      {chargebeeEnabled && banner.isOpen ? (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </Layout.Section>
      ) : (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </Layout.Section>
      )}
      {chargebeeEnabled && (
        <Layout>
        <Layout.AnnotatedSection
          title={cms("label.section.setting.title")}
          description={cms("label.section.setting.description")}
        >
          <Card title={cms("label.section.plan.title")}>
            <Card.Section>
              <TextContainer>{cms("label.section.plan.description")}</TextContainer>
              <br />
              <FormLayout>
                <TextField
                  label={`${cms("textField.label.price")}*`}
                  placeholder={cms("textField.placeholder.price")}
                  key={constant.PRICE}
                  id={constant.PRICE}
                  value={(values && values.price && values.price.toString()) || ""}
                  maxLength={10}
                  onChange={(value) => handleChangeEvent(constant.PRICE, acceptOnlyValidInput(value))}
                  onBlur={() => handleValidation(constant.PRICE, values[constant.PRICE])}
                  error={errorMessage && errorMessage[constant.PRICE]}
                />
                <TextField
                  label={`${cms("textField.label.productLimit")}*`}
                  placeholder={cms("textField.placeholder.productLimit")}
                  key={constant.PRODUCT_LIMIT}
                  id={constant.PRODUCT_LIMIT}
                  value={(values && values.productLimit && values.productLimit.toString()) || ""}
                  min={0}
                  maxLength={10}
                  onChange={(value) => handleChangeEvent(constant.PRODUCT_LIMIT, acceptOnlyValidInputs(value))}
                  onBlur={() => handleValidation(constant.PRODUCT_LIMIT, values[constant.PRODUCT_LIMIT])}
                  error={errorMessage && errorMessage[constant.PRODUCT_LIMIT]}
                />
              </FormLayout>
              <PageActions
                primaryAction={{
                  content: cms("common.button.update"),
                  id: "submitButton",
                  onAction: () => onSubmit(),
                  disabled: !isAllValuesFilled() || isAnyError() || submitDisabled || loading,
                  loading,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    id: "cancelButton",
                    onAction: () => history.push("/setting/plan/list"),
                  },
                ]}
              />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        </Layout>
      )}
    </>
  );
};
export default withErrorBoundary(PlanEdit);
