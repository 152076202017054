import { gql } from "apollo-boost";

const GET_PRODUCT_METRICS = gql`
  query getProductMetrics($input: GetProductMetrics) {
    getProductMetrics(input: $input) {
      data {
        product {
          month
          count
        }
      }
      error
      status
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export { GET_PRODUCT_METRICS };
