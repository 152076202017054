import React, { useContext } from "react";
import { Card, TextField, Layout, FormLayout, Select, TextContainer, Caption } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

import { priceProp } from "app/productOld/modules/generic/edit/propTypes";

const Price = (props) => {
  const {
    cms,
    data,
    handleDiscount,
    handleChange,
    // learnMore
  } = props;
  const { discount = {} } = data || {};
  const { price: vendorPrice = "", type: vendorType = "" } = discount || {};
  const { symbol } = constant;
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat = symbol.DOLLAR } = currentUser;

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.price.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () => {
        //       learnMore(cms("section.price.title"), cms("label.todo"));
        //     },
        //   },
        // ]}
        id="productPrice"
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.price.description")}</TextContainer>
            <FormLayout.Group>
              <TextField
                label={cms("section.price.label.price")}
                value={(data.price && data.price.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                prefix={moneyFormat}
                onChange={(value) => handleChange(constant.PRICE, acceptOnlyValidInput(value, data.price))}
              />
              <TextField
                label={cms("section.price.label.comparePrice")}
                value={(data.comparePrice && data.comparePrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                prefix={moneyFormat}
                onChange={(value) => {
                  handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(value, data.comparePrice));
                }}
              />
              <TextField
                label={cms("section.price.label.discount")}
                value={(vendorPrice && vendorPrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                max={(vendorType === constant.PERCENTAGE && 100) || 99999}
                prefix={vendorType === constant.FLAT && moneyFormat}
                suffix={vendorType === constant.PERCENTAGE && "%"}
                onChange={(value) => handleDiscount(constant.PRICE, acceptOnlyValidInput(value, vendorPrice))}
                disabled={!vendorType}
                connectedRight={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    value={vendorType || ""}
                    options={[
                      {
                        label: cms("section.price.placeholder.selectType"),
                        value: "",
                      },
                      {
                        label: cms("common.label.flat"),
                        value: constant.FLAT,
                      },
                      {
                        label: cms("common.label.percentage"),
                        value: constant.PERCENTAGE,
                      },
                    ]}
                    onChange={(value) => handleDiscount(constant.TYPE, value)}
                  />
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
Price.propTypes = priceProp.type;
export default Price;
