import React, { useState } from "react";
import { Card, Stack, Collapsible, Checkbox, Caption } from "@shopify/polaris";

import { reviewVariant } from "app/productOld/modules/operator/features/review/props";

const ReviewVariant = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isAdded,
    isDeleted,
    date,
    vendor,
    vendorChange,
    sellerChange,
    store,
    sellerLabel,
    changedKey: key,
    onToggleSelect,
    isVariantIdAvailable,
    cms,
  } = props;

  const actionText = (isAdded && "Added") || (isDeleted && "Deleted") || "Updated";
  const [isSelected, setIsSelected] = useState(true);
  const handleToggleSelect = () => {
    setIsSelected(!isSelected);
    onToggleSelect(key);
  };
  return (
    <Card
      sectioned
      title={[
        <Checkbox
          label="Basic checkbox"
          labelHidden
          disabled={!isVariantIdAvailable}
          checked={isSelected}
          onChange={handleToggleSelect}
        />,
        " ",
        `${sellerLabel}`,
      ]}
      actions={[
        {
          content: isOpen ? cms("common.label.hide") : cms("common.label.show"),
          onAction: () => setIsOpen(!isOpen),
          disclosure: isOpen ? "up" : "down",
        },
      ]}
    >
      <Stack>
        <Stack.Item fill>
          <Caption>
            {`${cms("common.label.lastUpdatedAt")}: `}
            {date}
          </Caption>
        </Stack.Item>
      </Stack>
      <Stack vertical>
        <Collapsible open={isOpen} id="basic-collapsible-2">
          <Card.Section title={`${actionText} by ${vendor}`}>{vendorChange}</Card.Section>
          <Card.Section subdued title={`Published at ${store}`}>
            {sellerChange}
          </Card.Section>
        </Collapsible>
      </Stack>
    </Card>
  );
};

ReviewVariant.propTypes = reviewVariant.type;

export default ReviewVariant;
