import React from "react";
import { TextStyle } from "@shopify/polaris";
import _ from "lodash";
import constant from "lib/constant/constant";

const propertyLabels = {
  "service-id": constant.SERVICE_ID,
};

const LineItemProperties = (props) => {
  const { properties = [], variation, isServiceLineItem = false } = props;

  return (
    !!isServiceLineItem &&
    properties.map((property) => {
      const label = propertyLabels[property.name] ? propertyLabels[property.name] : property.name;
      return _.isString(property.name) && _.isString(property.value) ? (
        <TextStyle variation={variation}>{`${label}: ${property.value}`}</TextStyle>
      ) : null;
    })
  );
};

export default LineItemProperties;
