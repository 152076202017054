import React from "react";
import { RadioButton } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";

const ClickAndReserve = (props) => {
  const { cms, onHandleChange, value } = props;
  return (
    <RadioButton
      label={cms("label.clickReserve")}
      helpText={cms("helpText.clickReserve")}
      id="clickReserve"
      checked={value === constant.RESERVE}
      onChange={() => onHandleChange(constant.RESERVE)}
    />
  );
};

ClickAndReserve.propTypes = {
  value: PropTypes.string.isRequired,
  cms: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ClickAndReserve), { feature: constant.CLICK_RESERVE });
