import React, { useContext, useEffect, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { ProductProvider } from "app/product/modules/generic/context";
import { tabProp } from "app/product/modules/generic/propTypes";
// import tab
import EditImage from "app/product/modules/generic/features/form/image";
import EditVariant from "app/product/modules/generic/features/form/variant";
import EditInventory from "app/product/modules/generic/features/form/inventory";
import EditFulfillment from "app/product/modules/generic/features/form/fulfillment";
import EditAttachment from "app/product/modules/generic/features/form/attachment";
// import tab
import editData from "app/product/modules/admin/features/edit/config/editData";
import EditInformation from "./information";

const EditTabs = (props) => {
  const { setBanner, setDescription, setTitle, isVariant, refetch } = props;
  const { cms, history, currentUser } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { search } = history.location;
  const { tab } = baseHelper.getQueryParams(search);
  const { ATTACHMENT, FULFILLMENT, IMAGE, INFORMATION, INVENTORY, VARIANT } = constant;
  const validProductTabs = editData(cms, isVariant, isAttachmentVisible).tabs;
  const validtab = validProductTabs.map((item) => item.id);
  const tabName = validtab.includes(tab) ? tab : INFORMATION;
  const [selected, setSelected] = useState(tabName);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    baseHelper.setUrl(history, { tab: tabName });
  }, [history, tabName]);

  useEffect(() => {
    let index = validProductTabs.findIndex((item) => item.id === tab);
    if (validProductTabs.length < index) {
      index = 0;
    }
    setSelectedTabIndex(index);
  }, [cms, isVariant, tab, validProductTabs]);

  const handleTabChange = (tabIndex) => {
    const tabId = validProductTabs[tabIndex].id;
    setSelected(tabId);
    baseHelper.setUrl(history, { tab: tabId });
  };

  const getTab = (tabSelected) => {
    let lastTab = isAttachmentVisible ? ATTACHMENT : FULFILLMENT;
    if (isVariant) {
      lastTab = isAttachmentVisible ? ATTACHMENT : VARIANT;
    }
    const tabObj = { islastTab: tabSelected === lastTab };

    switch (tabSelected) {
      case INFORMATION:
        return <EditInformation />;
      case INVENTORY:
        return <EditInventory />;
      case IMAGE:
        return <EditImage />;
      case VARIANT:
        return <EditVariant tabObj={tabObj} />;
      case FULFILLMENT:
        return <EditFulfillment tabObj={tabObj} />;
      case ATTACHMENT:
        return <EditAttachment tabObj={tabObj} />;
      default:
        return <EditInformation />;
    }
  };

  const tabLength = validProductTabs.length;
  const tabValue = selectedTabIndex >= tabLength ? tabLength - 1 : selectedTabIndex;

  return (
    <>
      <Card>
        <Tabs tabs={validProductTabs} selected={tabValue > 0 ? tabValue : 0} onSelect={handleTabChange} />
      </Card>
      <ProductProvider
        value={{
          handleTabChange,
          isVariant,
          refetch,
          setBanner,
          setDescription,
          setTitle,
        }}
      >
        <div className="productComponent">{getTab(selected)}</div>
      </ProductProvider>
    </>
  );
};

EditTabs.propTypes = tabProp.type;
export default EditTabs;
