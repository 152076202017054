import React from "react";
import { ActionList, Button, Popover as PolarisPopover, Spinner } from "@shopify/polaris";
import PropTypes from "prop-types";

const Popover = (props) => {
  const { active, disabled, loading, options, setActive } = props;
  const filteredOptions = (options || []).filter((item) => item);
  const button = (
    <Button onClick={() => setActive(!active)} disabled={disabled} disclosure>
      {(loading && <Spinner size="small" />) || "Manage"}
    </Button>
  );

  return (
    <PolarisPopover active={active} activator={button} onClose={() => setActive()} onActionAnyItem={() => setActive()}>
      <PolarisPopover.Pane>
        <ActionList items={filteredOptions} onActionAnyItem={() => setActive()} />
      </PolarisPopover.Pane>
    </PolarisPopover>
  );
};

Popover.propTypes = {
  loading: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  setActive: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Popover.defaultProps = {
  loading: false,
  disabled: false,
  active: false,
};

export default Popover;
