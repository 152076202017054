import React, { useCallback, useContext, useState, useEffect } from "react";
import {
  Button,
  Caption,
  Card,
  FormLayout,
  // PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import mutations
import { UPDATE_PRODUCT_LAKE_INVENTORY } from "app/productLake/apollo/mutations";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";

import editData from "app/productLake//modules/generic/config/editData";
import { withErrorBoundary } from "lib/hoc";

const Inventory = () => {
  const { handleTabChange, setBanner, setDescription, setTitle, productValue, refetchProduct } = useContext(
    ProductContext
  );
  const { cms, currentUser, match } = useContext(PrivateContext);
  const { params } = match || {};
  const { id: productId } = params || {};
  const { ecommercePlatform } = currentUser;
  const { gql, SHOPIFY = "", IS_MANAGING_STOCK, CRITICAL } = constant;
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [value, setValue] = useState(productValue.inventoryId);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    setValue(productValue.inventoryId);
  }, [productValue]);

  setDescription(cms("label.editInventory"));
  setTitle(cms("label.title.inventory"));
  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.SKU];
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  const acceptOnlyValidInput = (val) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && value.quantity);
  };

  const primaryAction = {
    content: cms("label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("label.cancel"),
    onAction: () => setSheetActive(false),
  };

  const [addProductInventory, { loading: addInventoryLoading }] = useMutation(UPDATE_PRODUCT_LAKE_INVENTORY);

  if (addInventoryLoading) {
    return <SkeletonCard />;
  }

  const onFormSubmit = async () => {
    const formValues = {
      productId: value.productId || productId,
      sku: value.sku,
      isManagingStock: value.isManagingStock,
    };
    formValues.quantity = formValues.isManagingStock ? (value.quantity && parseInt(value.quantity, 10)) || 0 : 0;

    await addProductInventory({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_LAKE_INVENTORY);
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_LAKE_INVENTORY);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          if (responseData) {
            setLoader(false);
            setMessage(cms("message.success.inventory"));
            setBanner({ isOpen: false, status: "", title: "" });
            refetchProduct();
            setTimeout(() => {
              handleTabChange(2);
            }, 1500);
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  return (
    <>
      <Card
        title={[
          cms("label.organisation"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productOrganisation"
      >
        <Card.Section>
          <p>{cms("label.caption.organization")}</p>
          <br />
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id="sku"
                label={cms("label.sku")}
                value={(value && value.sku) || ""}
                onChange={(val) => {
                  handleChange(constant.SKU, val);
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                id="policySelection"
                label={cms("label.inventory")}
                options={editData(cms, baseHelper.ucFirst(ecommercePlatform || constant.SHOPIFY)).policyOption}
                value={value && value.isManagingStock ? SHOPIFY : ""}
                onChange={(val) => {
                  handleChange(IS_MANAGING_STOCK, val === SHOPIFY);
                  setDisabledButton(false);
                }}
              />
              {value && value.isManagingStock ? (
                <TextField
                  id="inventoryQuantity"
                  disabled={!value.isManagingStock}
                  label={cms("label.quantity")}
                  value={(value.quantity && value.quantity.toString()) || ""}
                  min={0}
                  onChange={(val) => {
                    handleChange(constant.QUANTITY, acceptOnlyValidInput(val));
                    setDisabledButton(false);
                  }}
                />
              ) : (
                ""
              )}
            </FormLayout.Group>
            <div className="toast">
              <Toast message={message} />
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button onClick={() => handleTabChange(0)}>{cms("common.button.previous")}</Button>
        </Stack.Item>
        {value && value.status && value.status.toLowerCase() === constant.NEW && (
          <Stack.Item>
            <Button onClick={() => handleTabChange(2)}>{cms("common.button.skip")}</Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={disabledButton}
            loading={loader || addInventoryLoading}
          >
            {cms("common.button.save")}
          </Button>
        </Stack.Item>
      </Stack>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

export default withErrorBoundary(Inventory);
