import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail"))
      .nullable(),
  });

const durationSchema = (cms) =>
  yup.object().shape({
    duration: yup
      .string()
      .required("Please enter the number of days.")
      .max(3, "Maximum three digits allowed")
      .nullable(),
  });

export { emailSchema, durationSchema };
