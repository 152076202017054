import { gql } from "apollo-boost";

const ADD_PRODUCT_LAKE_VARIANT = gql`
  mutation addProductLakeVariant($input: AddProductLakeVariant) {
    addProductLakeVariant(input: $input) {
      status
      data
      error
    }
  }
`;

export default ADD_PRODUCT_LAKE_VARIANT;
