import { gql } from "apollo-boost";

const GET_VENDOR_FULFILLMENT = gql`
  query getSellerFulfillment {
    getSellerFulfillment {
      data {
        default
        dropship {
          by
          isThirdPartyShipping
          shippo {
            apiKey
            isAutoFulfill
            isShippingRateRequired
            defaultCarrier {
              id
              name
              service
            }
            isShippingRateRequired
            shippingRateName
            vendorCarrier {
              id
              name
              service
              vendorId
            }
          }
          shippingOption
        }
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_FULFILLMENT;
