import { gql } from "apollo-boost";

const SYNC_SHOPIFY_PRODUCT = gql`
  mutation syncShopifyProduct($input: BulkAction!) {
    syncShopifyProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_SHOPIFY_PRODUCT;
