import { gql } from "apollo-boost";

const FETCH_ORDER_LINE_LIST = gql`
  query getOrderLineList($input: GetListInputV1) {
    getOrderLineList(input: $input) {
      data {
        count
        orderLineList {
          isAutoExpiry
          isAutoReject
          isShippingPaid
          vendor
          vendorId
          orderId
          shopifyLineItemId
          createdAt
          shopifyOrderId
          shopifyOrderDate
          status
          orderNumber
          fulfillmentStatus
          title
        }
      }
      status
      error
    }
  }
`;
export default FETCH_ORDER_LINE_LIST;
