import React, { useContext } from "react";
import { DescriptionList, Modal, ProgressBar, Stack, TextStyle } from "@shopify/polaris";
import PropTypes from "prop-types";
// import context
import { PrivateContext } from "lib/context";

const UploadModal = (props) => {
  const { history } = useContext(PrivateContext);
  const { modalData, modal, setModal, cms } = props;
  const { duplicate, error, success, total, status, errorType: csvType, _id: id, processedCount } = modalData;
  const statusKeys = Object.freeze({
    pending: "Pending",
    completed: "Completed",
    error: "Error",
    success: "Success",
  });
  const productKeys = { product: "product" };
  const supplierKeys = {
    supplierinvite: "supplierinvite",
    supplierinvitecsv: "supplierinvitecsv",
    supplierupload: "supplierupload",
  };
  const errorTypeMapper = {
    supplierinvite: "supplierInvite",
    supplierinvitecsv: "supplierInviteCsv",
    product: "product",
    supplierupload: "supplierUpload",
    "bulk supplier invitation": "supplierInvite",
    "shopify products import": "shopifyImport",
    paystripe: "payStripe",
    paypaypal: "payPaypal",
  };

  const errorType = csvType && csvType.toLowerCase();
  const mappedErrorType = errorTypeMapper[errorType];
  const jobErrorType = cms("label.".concat(String(mappedErrorType)));
  const isInvite = errorType === supplierKeys.supplierinvitecsv || errorType === supplierKeys.supplierinvite;
  const isProduct = errorType === productKeys.product;
  const isVendor = errorType === supplierKeys.supplierupload;
  const jobText =
    (isInvite && cms("label.invite")) ||
    (isProduct && cms("label.product")) ||
    (isVendor && cms("label.vendor")) ||
    cms("label.item");
  const jobTextSingular =
    (isInvite && cms("label.activity.singular.invite")) ||
    (isProduct && cms("label.activity.singular.product")) ||
    (isVendor && cms("label.activity.singular.vendor")) ||
    cms("label.activity.singular.item");
  const jobTextPlural =
    (isInvite && cms("label.activity.plural.invite")) ||
    (isProduct && cms("label.activity.plural.product")) ||
    (isVendor && cms("label.activity.plural.vendor")) ||
    cms("label.activity.plural.item");
  const jobTextType = isInvite ? cms("label.sent") : cms("label.added");

  const isCompleted = status !== statusKeys.pending;
  const numberOfProcessedJobs = success + error + duplicate;
  const totalNumberOfJobs = total;
  const percentage = parseFloat((processedCount || numberOfProcessedJobs) / totalNumberOfJobs) * 100;

  const singularJobText = cms("label.activity.singular.".concat(`${jobText}`));
  const pluralJobText = cms("label.activity.plural.".concat(`${jobText}`));

  const textForJob = (number) =>
    `${number > 1 ? `${pluralJobText} ${cms("label.were")}` : `${singularJobText} ${cms("label.was")}`}`;

  const completeText =
    total > 1
      ? ` ${cms("label.all")} ${totalNumberOfJobs} ${pluralJobText} ${cms("label.were")} ${cms("message.processed")}`
      : ` ${total} ${singularJobText} ${cms("label.was")} ${cms("message.processed")}`;

  const getDescription = () => {
    const baseCms = "label.modal.".trim();

    return [
      {
        term: cms(`${baseCms}total.heading`),
        description: cms(`${baseCms}total.description`, {
          item: `${total} ${total > 1 ? pluralJobText : singularJobText}`,
        }),
      },
      {
        term: cms(`${baseCms}success.heading`),
        description: cms(`${baseCms}success.description`, { item: `${success} ${textForJob(success)} ${jobTextType}` }),
      },
      {
        term: cms(`${baseCms}error.heading`),
        description: error ? (
          <TextStyle variation="negative">
            {cms(`${baseCms}error.description`, { item: `${error} ${textForJob(error)}`, _item: `${jobTextType} ` })}
          </TextStyle>
        ) : (
          cms(`${baseCms}error.default`)
        ),
      },
      {
        term: cms(`${baseCms}duplicate.heading`),
        description: duplicate
          ? `${duplicate} of the ${duplicate > 1 ? pluralJobText : singularJobText} 
          ${cms(`${baseCms}duplicate.descriptionDuplicate`)}`
          : `${cms(`${baseCms}duplicate.descriptionUnique`, { item: `${jobTextType} ${pluralJobText}` })}`,
      },
    ];
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(!modal)}
      title={`${jobErrorType}-${isCompleted ? cms("label.completed") : cms("label.inProgress")}`}
      primaryAction={{
        content: `${cms("button.primaryAction")}`,
        onAction: () => history.push(`/activity/view/${id}`),
      }}
    >
      <Modal.Section>
        <ProgressBar progress={percentage} size="medium" />
        <br />
        <Stack vertical alignment="center">
          <Stack.Item>
            {!isCompleted && (
              <TextStyle variation="strong">
                {`${jobErrorType}`}
                {": "}
                {`${cms("label.processed")} `}
                {processedCount || numberOfProcessedJobs}
                {` ${cms("label.outOf")} `}
                {`${totalNumberOfJobs} `}
                {totalNumberOfJobs > 1 ? jobTextSingular : jobTextPlural}
              </TextStyle>
            )}
            {isCompleted && (
              <TextStyle variation="strong">
                {jobErrorType}
                {":"}
                {completeText}
              </TextStyle>
            )}
          </Stack.Item>
          {isCompleted && (
            <Stack.Item>
              <DescriptionList items={getDescription()} />
            </Stack.Item>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

UploadModal.propTypes = {
  modalData: PropTypes.shape({
    duplicate: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    errorType: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    processedCount: PropTypes.number.isRequired,
  }).isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  cms: PropTypes.func.isRequired,
};

export default UploadModal;
