import { baseHelper } from "lib/helpers";

class OrderLakeHelper {
  /**
   * @desc To return orders csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  orderCSVData = (orderLines = [], isCsv = false, isPdf = false, isVendor) => {
    const newOrderArray = [];
    orderLines.forEach((element) => {
      const {
        createdAt,
        shippingStatus: fulfillmentStatus,
        _id: lineItemId,
        // _id: lineItemId,
        orderId,
        storeOrderId: orderNumber,
        // orderStatus,
        price,
        name: product,
        quantity,
        vendor: vendorData,
      } = element || {};

      const vendor = {};

      if (vendorData && vendorData.length) {
        vendorData.forEach(({ _id, brandName }) => {
          vendor.id = _id;
          vendor.brand = brandName;
        });
      }

      const orderdata = {
        orderId: orderId || "Order Id",
        orderNumber: orderNumber || "Order Number",
        lineItemId: lineItemId || "Lineitem Id",
        product: product || "Product",
        // orderStatus: orderStatus || "Order Status",
        fulfillmentStatus: fulfillmentStatus || "Fulfillment Status",
        quantity: quantity || "Quantity",
        price: price || "Price",
        createdAt: createdAt || "Created At",
      };

      if (!isVendor) {
        orderdata.vendor = (vendor && vendor.label) || "Vendor";
      }
      const updatedArray = [];
      if (element) {
        const notAllowedCsvKeys = Object.keys(element).filter((item) => {
          let hideArray;
          if (isCsv) {
            hideArray = element[item];
          }

          if (isPdf) {
            hideArray = element[item];
          }
          return hideArray;
        });

        notAllowedCsvKeys.map((key) => {
          updatedArray.push(orderdata[key]);
          return false;
        });
      }

      const orderArray = [
        orderId && "Order Id",
        orderNumber && "Order Number",
        lineItemId && "Lineitem Id",
        product && "Product",
        // orderStatus || "Order Status",
        fulfillmentStatus && "Fulfillment Status",
        quantity && "Quantity",
        price && "Price",
        createdAt && "Created At",
      ];

      if (!isVendor) {
        orderArray.push(vendor && vendor?.brand && "Vendor Name");
      }

      const removeIndex = [];

      updatedArray.map((arr) => {
        const index1 = orderArray.indexOf(arr);
        removeIndex.push(index1);
        return false;
      });

      const differenceArray = orderArray.filter((item) => !updatedArray.includes(item));
      newOrderArray[0] = differenceArray;

      orderLines.forEach((item, idx) => {
        newOrderArray[idx + 1] = [
          `${item.orderId}`,
          `#${item.storeOrderId}`,
          `#${item._id}`,
          item.name,
          item.storeStatus,
          // item.shippingStatus,
          item.quantity,
          baseHelper.formatPrice(item.price * item.quantity || 0),
          baseHelper.formatDate(item.createdAt),
          (!isVendor && vendor?.brand) || null,
        ].filter((value, index2) => {
          return removeIndex.indexOf(index2) === -1;
        });
      });
    });
    return newOrderArray;
  };
}

export default new OrderLakeHelper();
