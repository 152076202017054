// import packages
import React from "react";
import { Banner, Card, FormLayout, List, Select, TextField, TextStyle, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// constants
import constant from "lib/constant/constant";

const { PAYMENT_TERMS_DAY, SELECTED_PAYMENT, PRE_FULFILLMENT, POST_FULFILLMENT, POST_RETURN } = constant;

const Payment = (props) => {
  const { data, handleChange, handleValidation, errorMessage, cms = {} } = props;

  const standardLists = cms("section.onboardingTerms.paymentSection.caption") || [];
  const renderCaptions = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{`${content.title} `}</b>
        {content.description}
      </List.Item>
    ));
    return list;
  };
  const paymentOptions = [
    {
      label: cms("section.onboardingTerms.paymentSection.label.pre"),
      value: PRE_FULFILLMENT,
    },
    {
      label: cms("section.onboardingTerms.paymentSection.label.post"),
      value: POST_FULFILLMENT,
    },
    {
      label: cms("section.onboardingTerms.paymentSection.label.expiry"),
      value: POST_RETURN,
    },
  ];

  return (
    <Card
      title={[
        cms("section.onboardingTerms.paymentSection.title"),
        <TextStyle variation="subdued">
          {/* <Caption>Last updated: Monday 21st Dec 2019, 07:32am GMT</Caption> */}
        </TextStyle>,
      ]}
      // actions={[{ content: "Learn more", onAction: () => sheetToggle(!sheetActive) }]}
    >
      <Card.Section>
        <TextContainer>{cms("section.onboardingTerms.paymentSection.description")}</TextContainer>
        <br />
        {/* <Stack spacing="loose" vertical> */}
        <FormLayout>
          <FormLayout.Group>
            <Select
              label={cms("section.onboardingTerms.paymentSection.label.payment")}
              options={paymentOptions}
              id="paymentTermsDay"
              onChange={(value) => handleChange(SELECTED_PAYMENT, value)}
              value={data.paymentTermsType.toString()}
            />
            {data.paymentTermsType !== PRE_FULFILLMENT && (
              <TextField
                label={cms("section.onboardingTerms.paymentSection.label.days")}
                id="numberId"
                placeholder={cms("section.onboardingTerms.returnSection.placeHolder.zero")}
                suffix={(data.paymentTermsDay && <div className="suffixColor">{constant.DAYS}</div>) || constant.DAYS}
                min={0}
                onChange={(value) => handleChange(PAYMENT_TERMS_DAY, value)}
                onBlur={() => handleValidation(PAYMENT_TERMS_DAY, data.paymentTermsDay)}
                value={(data.paymentTermsDay && data.paymentTermsDay.toString()) || ""}
                disabled={!data.paymentTermsType || data.paymentTermsType === PRE_FULFILLMENT}
                error={errorMessage && errorMessage.paymentTermsDay}
                // type="number"
              />
            )}
          </FormLayout.Group>
        </FormLayout>
        <br />
        <Banner status="info">
          <p>{cms("section.onboardingTerms.options")}</p>
          <br />
          <p>
            <List type="bullet">{renderCaptions()}</List>
          </p>
        </Banner>
      </Card.Section>
    </Card>
  );
};

Payment.propTypes = {
  data: PropTypes.exact({
    paymentTermsType: PropTypes.string,
    paymentTermsDay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([null, PropTypes.object]),
  cms: PropTypes.func.isRequired,
};

Payment.defaultProps = {
  errorMessage: null,
};

export default Payment;
