import { gql } from "apollo-boost";

const UPDATE_ABOUT = gql`
  mutation UpdateAbout($input: UpdateAbout!) {
    updateAbout(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_ABOUT;
