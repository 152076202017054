import React, { useContext } from "react";
import { Card, Collapsible, Caption, Layout, TextContainer, TextStyle } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const Favicon = () => {
  const { FAVICON, FAVICON_SIZE } = constant;
  const {
    faviconImage,
    handleChange,
    // learnMore
  } = useContext(BrandingContext);
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { brand } = currentUser || {};
  const { favicon, updatedAt: brandUpdatedAt = "" } = brand || {};
  const { updatedAt = "" } = favicon || {};
  return (
    <Collapsible open>
      <Layout.AnnotatedSection title={cms("section.favicon.title")} description={cms("section.favicon.description")}>
        <Card
          title={[
            cms("section.favicon.card.title"),
            updatedAt ? (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ) : (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(brandUpdatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>
              {cms("section.favicon.card.description")}
              <small>{`(${cms("section.favicon.card.size", { item: FAVICON_SIZE })})`}</small>
            </TextContainer>
            <br />
            <DropZone
              id="dropZone"
              onAdd={(value) => handleChange(FAVICON, (value && value.length && value[0]) || "", FAVICON)}
              onRemove={() => handleChange(FAVICON, "", FAVICON)}
              fileList={(faviconImage !== "" && [faviconImage]) || []}
              allowMultiple={false}
            />
            <br />
            <Caption>{cms("section.favicon.caption")}</Caption>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(Favicon), {
  feature: constant.STANDARD_BRANDING,
});
