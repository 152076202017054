import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  ActionList,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Collapsible,
  FormLayout,
  Frame,
  Heading,
  Icon,
  Layout,
  Modal,
  Popover,
  Select,
  Spinner,
  Stack,
  TextContainer,
} from "@shopify/polaris";

import {
  ArrowRightMinor,
  CircleCancelMinor,
  CircleDisabledMajor,
  CircleInformationMajor,
  CircleTickMajor,
  CircleTickOutlineMinor,
  EditMinor,
  QuickSaleMajor,
  TipsMajor,
} from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { withFeature, withErrorBoundary } from "lib/hoc";
import { Banner, Spinner as Loader, Toast } from "lib/components";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { NotFound } from "layout/private/components";

import { QuickAction } from "app/reports";

import { getOrderView, getOrderLineView, GET_ORDER_REFUND, GET_ORDER_FULFILLMENT } from "app/orders/apollo/viewOrder";

import { GET_SELLER_FULFILLMENT } from "app/setup/apollo";

import {
  BULK_ACCEPT,
  BULK_EDIT,
  BULK_FULFILL,
  BULK_REJECT,
  GENERATE_SHIPPING_LABEL,
} from "app/orders/apollo/mutations";

import { DOWNLOAD_LABEL, GET_VENDOR_LIST, GET_UPLOAD } from "app/orders/apollo/queries";
import { GET_FULFILLMENT_TRACKING_DETAILS_SETTING, GET_PACKING_SLIP_ACCESS } from "app/setup/apollo/queries";

import { errorHelper } from "lib/helpers";
import {
  Customer,
  FulfilledLineItems,
  Fulfillments,
  FulfillmentStatus,
  Pickup,
  RejectedLineItems,
  ReturnedLineItems,
  Notes,
  UnfulfilledLineItems,
  VendorFulfillments,
} from "./features";

import { EditModal, FulfillModel } from "./modal";
import { StyledButton } from "./genericStyled";

import ReadyForCollect from "./features/readyForCollect";
import PendingForCollect from "./features/pendingForCollect";
import ReadyForService from "./features/readyForService";
import PendingForService from "./features/pendingForService";

const { gql, HIDE, SHOW } = constant;

const downloadLabelId = "download-shipping-Label";
const generateLabelId = "shipping-Label";

const OperatorOrderView = () => {
  const { cms, currentUser = {}, history } = useContext(PrivateContext);
  const { _id: currentUserId = "" } = currentUser || {};
  const [viewOrderData, setViewOrderData] = useState(false);
  const [viewLineOrderData, setViewLineOrderData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isConsignmentValue, setConsignmentValue] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isDynamicDiscount, setIsDynamicDiscount] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [eventKey, setEventKey] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [lineLoading, setLineLoading] = useState([]);
  const [active, setActive] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [fulfillModal, setFulfillModal] = useState(false);
  const [totalRejectAmount, setRejectAmount] = useState(0);
  const [totalFulfilledAmount, setFulfillAmount] = useState(0);
  const [isBulk, setIsBulk] = useState(false);
  const [isBulkChecked, setIsBulkChecked] = useState(false);
  const [isCapmaignOrder, setIsCapmaignOrder] = useState(false);
  const [isDownloadShipping, setDownloadShipping] = useState(false);
  const [enableDownloadLabel, setDownloadLabel] = useState(false);
  const [packingSlipAccessData, setPackingSlipAccessData] = useState([]);
  const [isEnabledData, setIsEnabledData] = useState(false);

  const [checked, setChecked] = useState(false);
  const [fulfillmentType, setFulfillmentType] = useState([]);

  const [labelModal, setLabelModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [options, setOptions] = useState([]);
  const [orderConsignmentId, setOrderConsignmentId] = useState("");
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });

  const [term, setTerm] = useState("");
  const [message, setMessage] = useState("");

  const [actionData, setActionData] = useState([]);

  const [selectedItemData, setSelectedItemData] = useState([]);
  // const [sellerAddresId, setSellerAddresId] = useState("");
  // const [addressModal, setAddressModal] = useState(false);
  const [bulkQuantity, setBulkQuantity] = useState([{ id: "", quantity: "", totalQuantity: "" }]);
  const [activeReserveCollect, setActiveReserveCollect] = useState(false);
  const [isBulkReserveChecked, setIsBulkReserveChecked] = useState(false);

  const [isActionData, setIsActionData] = useState(false);

  const [orderRefund, setOrderRefund] = useState([]);
  const [orderFulfillment, setOrderFulfillment] = useState([]);
  const { setPageData, match } = useContext(PrivateContext);
  const [toggleStatus, setToggleStatus] = useState({
    unfulfilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fullFillmentStatus: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulFilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulfilledServiceItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulfillments: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    rejected: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    return: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    vendorFulfillments: {
      active: false,
      toggleText: SHOW,
    },
    readyForCollect: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    readyForService: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    pendingForCollect: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    pendingForService: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    pickup: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
  });

  const handleModalChange = useCallback(() => {
    setRejectModal(!rejectModal);
    setChecked(false);
  }, [rejectModal]);

  const {
    loading: loadingOrderLineView,
    error: errorOrderLineView,
    data: dataOrderLineView,
    refetch: refetchOrderLineView,
  } = useQuery(getOrderLineView, {
    variables: { input: { orderId: match.params.id } },
  });

  const { loading: loadingOrderView, error: errorOrderView, data: dataOrderView, refetch: refetchOrderView } = useQuery(
    getOrderView,
    {
      variables: { input: { id: match.params.id } },
    }
  );

  const {
    data: dataOrderRefund,
    error: errorOrderRefund,
    loading: loadingOrderRefund,
    refetch: refetchOrderRefund,
  } = useQuery(GET_ORDER_REFUND, {
    variables: { input: { orderId: match.params.id } },
  });

  const { data: dataOrderFulfillment, error: errorOrderFulfillment, refetch: refetchOrderFulfillment } = useQuery(
    GET_ORDER_FULFILLMENT,
    {
      variables: { input: { orderId: match.params.id } },
    }
  );

  const [bulkAccept, { loading: acceptLoading }] = useMutation(BULK_ACCEPT, {
    refetchQueries: [
      {
        query: getOrderLineView,
        variables: {
          input: { orderId: match.params.id },
        },
      },
    ],
  });

  const { data: trackingData, loading: fulfillSettingLoading } = useQuery(GET_FULFILLMENT_TRACKING_DETAILS_SETTING);

  const [bulkReject, { loading: rejectLoading }] = useMutation(BULK_REJECT);

  const {
    error: errorGetPackingSlipAccess,
    loading: loadingGetPackingSlipAccess,
    data: dataGetPackingSlipAccess,
    refetch,
  } = useQuery(GET_PACKING_SLIP_ACCESS);

  const packingSlipResponseData = baseHelper.getResponseData(dataGetPackingSlipAccess, "getPackingSlipAccess");
  const packingSlipErrorData = baseHelper.getResponseError(dataGetPackingSlipAccess, "getPackagingSlipAccess");
  useEffect(() => {
    if (packingSlipResponseData) {
      setPackingSlipAccessData(packingSlipResponseData);
    }
    if (packingSlipErrorData) {
      setIsError(true);
    }
  }, [packingSlipResponseData, packingSlipErrorData]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  useEffect(() => {
    if (dataOrderLineView && dataOrderView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderView = baseHelper.getResponseData(dataOrderView, gql.GET_ORDER_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderViewError = baseHelper.getResponseError(dataOrderView, gql.GET_ORDER_VIEW);

      if (orderLineView && orderView) {
        setViewOrderData(orderView);
        setViewLineOrderData(orderLineView);
      }
      if (orderLineViewError && orderViewError) {
        setIsError(true);
      }
      if (
        orderView &&
        orderView.totalDiscount &&
        orderView.discountCodes[0] &&
        orderView.discountCodes[0].type === constant.FIXED_AMOUNT
      ) {
        setIsDynamicDiscount(true);
      } else {
        setIsDynamicDiscount(false);
      }
    }
  }, [dataOrderLineView, dataOrderView]);

  useEffect(() => {
    if (dataOrderRefund) {
      const dataRefund = baseHelper.getResponseData(dataOrderRefund, gql.GET_ORDER_REFUND);
      const dataRefundError = baseHelper.getResponseError(errorOrderRefund, gql.GET_ORDER_REFUND);
      if (dataRefund) {
        setOrderRefund(dataRefund);
      }
      if (dataRefundError) {
        setIsError(true);
      }
    }
  }, [dataOrderRefund]);

  useEffect(() => {
    if (dataOrderFulfillment) {
      const dataFulfillment = baseHelper.getResponseData(dataOrderFulfillment, gql.GET_ORDER_FULFILLMENT);
      const dataFulfillmentError = baseHelper.getResponseError(errorOrderFulfillment, gql.GET_ORDER_FULFILLMENT);

      if (dataFulfillment && dataFulfillment.length) {
        const updatedOrderFulfillments = [];

        dataFulfillment.forEach((item) => {
          const isExists = updatedOrderFulfillments.find(
            (updatedOrderFulfillment) => updatedOrderFulfillment.shopifyFulfillmentId === item.shopifyFulfillmentId
          );
          if (!isExists) {
            updatedOrderFulfillments.push(item);
          }
        });

        setOrderFulfillment(updatedOrderFulfillments);
      } else {
        setOrderFulfillment([]);
      }

      if (dataFulfillmentError) {
        setIsError(true);
      }
    }
  }, [dataOrderFulfillment]);

  useEffect(() => {
    setTerm("");
    if (viewOrderData && viewLineOrderData) {
      let isConsignmentValueExist = true;
      let showDownloadShippingLabel = false;
      const { orderNumber = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      const { name = "", shipping = {} } = viewOrderData;
      setTerm(shipping.term);
      viewLineOrderData.map((lineItem) => {
        if (!lineItem.consignmentId) {
          isConsignmentValueExist = false;
        } else {
          showDownloadShippingLabel = true;
        }

        return null;
      });

      const title = `#${orderNumber}`;

      setConsignmentValue(isConsignmentValueExist);
      setDownloadLabel(showDownloadShippingLabel);
      setPageData({
        title: name === title ? title : `${name} - ${title}`,
        breadcrumbs: [{ url: "/" }],
        subtitle: baseHelper.formatDate(createdAt),
      });
    }
  }, [viewOrderData, viewLineOrderData, history, match.params.id, setPageData]);

  useEffect(() => {
    if (errorOrderView || errorOrderLineView || errorOrderRefund) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorOrderLineView || errorOrderView || errorOrderRefund),
        isOpen: true,
      });
    }
  }, [errorOrderView, errorOrderLineView, errorOrderRefund]);

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  const fulfillmentView = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};

  const handleToggle = (evt) => {
    const { id } = evt.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? cms("common.label.show") : cms("common.label.hide"),
      },
    }));
  };

  useEffect(() => {
    if (trackingData) {
      const resData = baseHelper.getResponseData(trackingData, "getFulfillmentTrackingDetailsSetting");
      const resError = baseHelper.getResponseError(trackingData, "getFulfillmentTrackingDetailsSetting");
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        return;
      }
      if (resData) {
        const { isEnabled } = resData || {};
        setIsEnabledData(isEnabled);
      }
    }
  }, [trackingData, setBanner, "getFulfillmentTrackingDetailsSetting"]);

  const handleBulkReject = async () => {
    const refundData = [];
    if (isBulk) {
      setMessage(cms("common.message.success.background"));
      setIsBulk(false);
    }
    selectedItems.forEach((orderId) => {
      viewLineOrderData.map((item) => {
        if (orderId === item._id) {
          const dataToAdd = {
            isAddToInventory: item.isAddToInventory || false,
            orderId: item.orderId,
            orderLineItemId: item._id,
          };
          refundData.push(dataToAdd);
        }
        return null;
      });
    });

    const formData = {
      isRefund: false,
      refund: refundData,
    };
    setSelectedItems([]);
    if (!isBulk) {
      formData.isSingle = true;
    }
    await bulkReject({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_REJECT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_REJECT);
          if (resData) {
            setBanner({ isOpen: false, title: responseDataError, status: constant.CRITICAL });
            setMessage(cms("message.success.reject", { item: cms("message.success.manageTransaction") }));
            setTimeout(() => {
              refetchOrderLineView();
              refetchOrderView();
              refetchOrderRefund();
            }, 5000);
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
        if (!rejectLoading) {
          setRejectModal(false);
        }
        setChecked(false);
        setRejectModal(false);
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleBulkAccept = async (key = true, ids) => {
    const formData = {
      lineItemIds: (key && selectedItems) || ids,
    };
    if (key) {
      setLineLoading(selectedItems);
      setMessage(cms("common.message.success.background"));
    }
    if (!key) {
      formData.isSingle = true;
    }
    setSelectedItems([]);
    await bulkAccept({ variables: { input: { ...formData } } })
      .then((res) => {
        setActive(false);
        if (res) {
          setLineLoading([]);
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_ACCEPT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_ACCEPT);
          if (resData) {
            setBanner({ isOpen: false, title: responseDataError, status: constant.CRITICAL });
            setMessage(cms("message.success.accept"));
            refetchOrderLineView();
            refetchOrderView();
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleChecked = useCallback((newChecked) => setChecked(newChecked), []);

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Checkbox label={cms("label.checkbox")} checked={checked} onChange={handleChecked} />
      </Stack.Item>
      <Stack.Item>
        <Button
          onClick={(event) => {
            handleModalChange(event);
            handleChecked(!event);
          }}
        >
          {cms("common.button.cancel")}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button primary onClick={handleBulkReject} disabled={!checked} loading={rejectLoading}>
          {cms("common.button.confirm")}
        </Button>
      </Stack.Item>
    </Stack>
  );

  const handelRejectModal = () => {
    return (
      <Modal open={rejectModal} onClose={handleModalChange} title={cms("modal.reject")} footer={actions()}>
        <Modal.Section>
          <p>{cms("modal.section.description")}</p>
        </Modal.Section>
      </Modal>
    );
  };

  const renderHeading = (title, count, fulfilled = false) => {
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          {title === baseHelper.ucFirst(constant.REJECTED) && (
            <Icon source={CircleDisabledMajor} backdrop color={constant.REDDARK} />
          )}
          {title === baseHelper.ucFirst(constant.RETURN) && (
            <Icon source={CircleTickMajor} backdrop color={constant.REDDARK} />
          )}
          {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
            <Icon
              source={fulfilled ? CircleTickMajor : CircleDisabledMajor}
              backdrop
              color={fulfilled ? constant.GREENDARK : constant.YELLOWDARK}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
        {title === baseHelper.ucFirst(constant.REJECTED) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title === baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={fulfilled ? constant.SUCCESS : constant.ATTENTION}>{count}</Badge>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const isPaidStatus = (status, isAllFullfilled) => {
    return (
      status === constant.PAID ||
      (status === constant.REFUND && isAllFullfilled) ||
      status === constant.PARTIALLY_REFUND
    );
  };

  const renderPaymentSectionHeading = (status, isAllFullfilled) => {
    const isPaid = isPaidStatus(status, isAllFullfilled);
    const isPartiallyPaid = status === constant.PARTIALLYPAID || (status === constant.REFUND && !isAllFullfilled);

    const title = (isPaid && cms("label.paid")) || (isPartiallyPaid && cms("label.partiallyPaid")) || "Payment Pending";

    const iconSource =
      (isPaid && CircleTickMajor) || (isPartiallyPaid && CircleInformationMajor) || CircleDisabledMajor;
    const iconColor = isPaid ? constant.GREENDARK : constant.YELLOWDARK;
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          <Icon source={iconSource} backdrop color={iconColor} />
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
      </Stack>
    );
  };

  const [generateShippingLabel, { loading: shippingLabelLoading }] = useMutation(GENERATE_SHIPPING_LABEL);
  useEffect(() => {
    if (shippingLabelLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [shippingLabelLoading]);

  const [bulkEditItem, { loading: bulkEditLoading }] = useMutation(BULK_EDIT);
  useEffect(() => {
    if (bulkEditLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [bulkEditLoading, cms]);

  const [bulkFulfillItem, { data: bulkFulfillData, loading: bulkFulfillLoading }] = useMutation(BULK_FULFILL);

  useEffect(() => {
    if (bulkFulfillLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [bulkFulfillLoading, cms]);

  const [downloadLabel, { loading: downloadLabelLoading, data: downloadLabelData }] = useLazyQuery(DOWNLOAD_LABEL);
  const [getVendorListData, { loading: vendorListDataLoading, data: vendorListData }] = useLazyQuery(GET_VENDOR_LIST);
  const [getUploadData, { loading: uploadDataLoading, data: uploadData }] = useLazyQuery(GET_UPLOAD);

  useEffect(() => {
    if (!vendorListDataLoading && vendorListData) {
      const responseData = baseHelper.getResponseData(vendorListData, gql.GET_CONSIGNMENT_VENDOR_LIST);
      if (!responseData) {
        const bannerData = {
          title: baseHelper.getResponseError(vendorListData, gql.GET_CONSIGNMENT_VENDOR_LIST) || errorHelper.parse(),
          status: constant.CRITICAL,
          isOpen: true,
        };
        setBanner(bannerData);
      }
      setLabelModal(true);
      setOptions(responseData);
    }
  }, [vendorListDataLoading, vendorListData]);

  useEffect(() => {
    if (uploadData) {
      const resData = baseHelper.getResponseData(uploadData, gql.GET_UPLOAD);
      if (resData) {
        const { data = [], dataType, type } = resData;
        data.map((pdfURL) => {
          const url = `data:application/${type};${dataType},${pdfURL}`;
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `vendor-${orderConsignmentId}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          setMessage(cms("message.success.downloadSuccess"));
          return null;
        });
      }
    }
  }, [uploadData, orderConsignmentId]);

  useEffect(() => {
    if (isDownload) {
      if (downloadLabelLoading) {
        setLabelModal(false);
        setSelectedVendor("");
        setMessage(cms("message.success.requestProcess"));
      }

      if (!downloadLabelLoading && downloadLabelData) {
        const responseData = baseHelper.getResponseData(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL);
        let bannerData = {
          title: cms("message.success.downloadSuccess"),
          status: constant.SUCCESS,
          isOpen: false,
        };
        if (responseData) {
          setMessage(cms("message.success.downloadSuccess"));
        }
        if (!responseData) {
          bannerData = {
            title: baseHelper.getResponseError(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL) || errorHelper.parse(),
            status: constant.CRITICAL,
            isOpen: true,
          };
          setBanner(bannerData);
          return;
        }

        const {
          clientId = "",
          consignmentId = "",
          consignmentStatus = "",
          consignmentUrl = "",
          pdf = {},
          shippingOption = "",
          token = "",
          consignmentLabel = [],
        } = responseData || {};
        if (shippingOption === constant.SHIPPY_PRO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `label-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.UPS_SMALL || shippingOption === constant.SENDABOX) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }
          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${consignmentId}`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.NZPOST) {
          if (!consignmentStatus) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          if (consignmentStatus === constant.PROCESSING) {
            bannerData = {
              title: baseHelper.getResponseError(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL) || errorHelper.parse(),
              status: constant.CRITICAL,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }
          const shippingUrl = baseHelper.getNewZeaLandPostAPIUrl(consignmentId);
          // TODO: needs to improvised latter as no other solution found;
          fetch(shippingUrl, {
            headers: {
              Authorization: token,
              client_id: clientId,
            },
          })
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${consignmentId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.STARSHIPIT) {
          if (consignmentLabel && consignmentLabel.length) {
            consignmentLabel.map(async (pdfItem) => {
              fetch(pdfItem.pdfURL)
                .then((res) => {
                  if (res && !res.ok) {
                    return false;
                  }
                  return res.blob();
                })
                .then((result) => {
                  if (!result) {
                    bannerData = {
                      title: cms("message.error.unableToDownloadPdf"),
                      status: constant.CRITICAL,
                      isOpen: true,
                    };
                    setBanner(bannerData);
                    return;
                  }
                  const url = window.URL.createObjectURL(result);
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = `vendor-${consignmentId}`;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  setBanner(bannerData);
                });
            });
            setIsDownload(false);
          }
          if (!(consignmentLabel && consignmentLabel.length)) {
            try {
              setOrderConsignmentId(consignmentId);
              getUploadData({ variables: { input: { collectionId: match.params.id, userId: selectedVendor } } });
            } catch (exception) {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
            }
          }
        } else {
          const buffer = new Buffer(pdf);
          const newBlob = new Blob([buffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(newBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `vendor-${consignmentId}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          setBanner(bannerData);
          setIsDownload(false);
        }
      }
    }
  }, [downloadLabelLoading, downloadLabelData, cms]);

  const { dropship = {}, consignmentShip = {} } = fulfillmentView || {};
  const { isThirdPartyShipping: dropShipIsThirdPartyShipping = false } = dropship || {};
  const { isThirdPartyShipping: consignmentIsThirdPartyShipping = false } = consignmentShip || {};
  const linkData = [];

  const downloadConsignmentLabel = () => {
    setDownloadShipping(false);
    const { params = {} } = match;
    const { id: orderId } = params;
    downloadLabel({ variables: { input: { orderId, vendorId: selectedVendor } } });
    setIsDownload(true);
  };

  const generateLabel = async () => {
    const { params = {} } = match;
    const { id: orderId } = params;
    const response = await generateShippingLabel({ variables: { input: { orderId, vendorId: selectedVendor } } });
    const shippingLabelResponse = baseHelper.getResponseData(response.data, gql.GENERATE_SHIPPING_LABEL);
    const shippingLabelError = baseHelper.getResponseError(response.data, gql.GENERATE_SHIPPING_LABEL);
    if (shippingLabelResponse) {
      refetchOrderLineView();
      refetchOrderFulfillment();
    }

    if (shippingLabelError) {
      const bannerData = {
        isOpen: true,
        title: shippingLabelError || errorHelper.parse(),
        status: constant.CRITICAL,
      };
      setBanner(bannerData);
    }
    setLabelModal(false);
    setSelectedVendor("");
  };

  const handleSelectChange = useCallback((value) => setSelectedVendor(value), []);
  const renderConsignmentVendor = () => {
    const modalTitle = isDownloadShipping ? cms("label.downloadVendorLabel") : cms("label.generateVendorLabel");
    const generateLabelAction = {
      content: cms("button.generateLabel"),
      onAction: () => generateLabel(),
      loading: shippingLabelLoading,
      disabled: !selectedVendor,
    };

    const downloadLabelAction = {
      content: cms("button.downloadLabel"),
      onAction: () => downloadConsignmentLabel(),
      loading: downloadLabelLoading,
      disabled: !selectedVendor,
    };

    const action = isDownloadShipping ? downloadLabelAction : generateLabelAction;

    return (
      <>
        <Modal
          open={labelModal}
          onClose={() => {
            setLabelModal(false);
            setDownloadShipping(false);
            setSelectedVendor("");
          }}
          title={modalTitle}
          primaryAction={action}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <Select
                label={gql.VENDOR_LABEL}
                options={options}
                onChange={handleSelectChange}
                value={selectedVendor}
                placeholder={cms("placeholder.selectVendor")}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };


  

  const fulfilledLineItems = orderFulfillment.map((item) => item.lineItems).flat() || [];

  let fulfilledData = [];
  fulfilledLineItems.forEach((fulfilledLineItem) => {
    const getOrderItem =
      viewLineOrderData && viewLineOrderData.find((lineItem) => lineItem.shopifyLineItemId === fulfilledLineItem.id);
    if (!getOrderItem) {
      return false;
    }
    const optionIndex = fulfilledData.findIndex((fulfillment) => fulfillment.id === fulfilledLineItem.id);
    if (optionIndex === -1) {
      fulfilledLineItem.totalQuantity = parseInt(fulfilledLineItem.quantity, 10);
      fulfilledLineItem.totalPrice = parseFloat(fulfilledLineItem.price) * parseFloat(fulfilledLineItem.quantity);
      fulfilledLineItem.fulfillmentType = getOrderItem.fulfillmentType;
      fulfilledLineItem.isProductDeleted = getOrderItem.isProductDeleted;
      fulfilledLineItem.taxLines = getOrderItem.isProductDeleted;
      fulfilledData.push(fulfilledLineItem);
    } else {
      fulfilledData[optionIndex].totalQuantity += parseInt(fulfilledLineItem.quantity, 10);
      fulfilledData[optionIndex].totalPrice +=
        parseFloat(fulfilledLineItem.price) * parseFloat(fulfilledLineItem.quantity);
    }
  });

  // fulfilledData = fulfilledData.filter((itemLine) => itemLine && !itemLine.isProductDeleted);

  const refundLineItems = orderRefund.map((item) => item.lineItems).flat() || [];

  let refundData = [];
  refundLineItems.forEach((refundItem) => {
    const { line_item: refundLineItem = {} } = refundItem || {};
    const optionIndex = refundData.findIndex((refund) => refund.line_item_id === refundItem.line_item_id);
    if (optionIndex === -1) {
      refundItem.totalQuantity = parseInt(refundItem.quantity, 10);
      refundItem.totalPrice = parseFloat(refundLineItem.price) * parseFloat(refundItem.quantity);
      refundData.push(refundItem);
    } else {
      refundData[optionIndex].totalQuantity += parseInt(refundItem.quantity, 10);
      refundData[optionIndex].totalPrice += parseFloat(refundLineItem.price) * parseFloat(refundItem.quantity);
    }
  });

  const isProductDeleted =
    viewLineOrderData && viewLineOrderData.filter((lineItem) => lineItem.isProductDeleted).length;

  if (isProductDeleted) {
    viewLineOrderData.forEach((lineItem) => {
      if (lineItem.status === constant.REJECTED) {
        const refundItem = {};
        const optionIndex = refundData.findIndex((refund) => refund.line_item_id === lineItem.shopifyLineItemId);
        if (optionIndex === -1) {
          refundItem.line_item_id = lineItem.shopifyLineItemId;
          refundItem.restock_type = constant.CANCEL_SMALL;
          refundItem.totalQuantity = parseInt(lineItem.quantity, 10);
          refundItem.totalPrice = parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
          refundItem.line_item = lineItem;
          refundData.push(refundItem);
        }
      }
    });
  }

  refundData = refundData.filter((itemLine) => itemLine);

  const renderItemData = (dataOrderViews, dataOrderLineViews) => {
    const orderLineItemsData = dataOrderLineViews;
    const isReserveAndCollect = orderLineItemsData.every(
      (data) => data.fulfillmentType === constant.RESERVE || data.fulfillmentType === constant.COLLECT
    );
    const isLineService = orderLineItemsData.every((data) => data.fulfillmentType === constant.SERVICE);
    const isAllFullfilled =
      orderLineItemsData.every((data) => data.fulfillableQuantity === 0) && (isReserveAndCollect || isLineService);
    const { financialStatus, status: orderStatus = "" } = dataOrderViews || {};
    if (!orderLineItemsData)
      return (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      );

    let unfulfillCount = constant.value.ZERO;
    let fulfillCount = constant.value.ZERO;
    let fulfilledCount = constant.value.ZERO;
    let fulfilledServiceCount = constant.value.ZERO;
    let consignmentShipCount = constant.value.ZERO;
    let pickUpCount = constant.value.ZERO;
    let paymentCount = constant.value.ZERO;
    let rejectCount = constant.value.ZERO;
    let returnCount = constant.value.ZERO;
    let collectCount = constant.value.ZERO;
    let pendingCount = constant.value.ZERO;
    let serviceCount = constant.value.ZERO;
    let pendingServiceCount = constant.value.ZERO;
    if (orderLineItemsData) {
      unfulfillCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          !(lineItem.status === constant.REJECTED) && // || lineItem.status === constant.CLOSED
          !lineItem.collectDetail &&
          lineItem.fulfillableQuantity
      ).length;

      fulfillCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.FULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          ![constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(lineItem.fulfillmentType)
      ).length;
      pendingCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT].includes(lineItem.fulfillmentType) &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (lineItem.isRefund
            ? lineItem.collectDetail.readyQuantity < (lineItem.quantity || 0)
            : lineItem.collectDetail.readyQuantity < (lineItem.actualQuantity || 0))
      ).length;
      collectCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT].includes(lineItem.fulfillmentType) &&
          lineItem.status !== constant.REJECTED &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (!lineItem.isRefund
            ? parseInt(lineItem.actualQuantity || lineItem.quantity, 10) -
                parseInt(lineItem.collectDetail.readyQuantity, 10) <
              parseInt(lineItem.fulfillableQuantity, 10)
            : !(
                parseInt(lineItem.quantity, 10) - parseInt(lineItem.fulfillableQuantity, 10) ===
                lineItem.collectDetail.readyQuantity
              ))
      ).length;

      serviceCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.SERVICE].includes(lineItem.fulfillmentType) &&
          lineItem.status !== constant.REJECTED &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (!lineItem.isRefund
            ? parseInt(lineItem.actualQuantity || lineItem.totalQuantity, 10) -
                parseInt(lineItem.collectDetail.readyQuantity, 10) <
              parseInt(lineItem.fulfillableQuantity, 10)
            : !(
                parseInt(lineItem.quantity, 10) - parseInt(lineItem.fulfillableQuantity, 10) ===
                lineItem.collectDetail.readyQuantity
              ))
      ).length;

      pendingServiceCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.SERVICE].includes(lineItem.fulfillmentType) &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (lineItem.isRefund
            ? lineItem.collectDetail.readyQuantity < (lineItem.quantity || 0)
            : lineItem.collectDetail.readyQuantity < (lineItem.actualQuantity || 0))
      ).length;

      pickUpCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.isVendorFulfilled &&
          lineItem.fulfillmentStatus !== constant.FULFILLED &&
          lineItem.fulfillmentStatus !== constant.REFUND &&
          lineItem.status !== constant.REJECTED &&
          (lineItem.isConsignmentPick || lineItem.fulfillmentType === constant.PICKUP)
      ).length;

      paymentCount = orderLineItemsData.filter((lineItem) =>
        lineItem && lineItem.isRefund
          ? !(
              lineItem.status === constant.REJECTED &&
              lineItem.fulfillmentStatus === constant.FULFILLED &&
              lineItem.actualQuantity === 0
            )
          : !(
              (lineItem.status === constant.REJECTED ||
                (lineItem.status === constant.NEW && lineItem.fulfillableQuantity === 0)) &&
              (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.REFUND)
            )
      ).length;

      rejectCount = refundData && refundData.length;
      returnCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.fulfillmentStatus === constant.FULFILLED &&
          lineItem.returnedQuantity &&
          lineItem.returnedQuantity !== constant.value.ZERO
      ).length;

      fulfilledCount = fulfilledData && fulfilledData.length;

      fulfilledServiceCount = orderLineItemsData.filter(
        (lineItem) => !lineItem.isProductDeleted && fulfilledData && lineItem.fulfillmentType === constant.SERVICE
      ).length;

      consignmentShipCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.fulfillmentType === constant.CONSIGNMENT &&
          lineItem.isVendorFulfilled &&
          lineItem.fulfillmentStatus !== constant.FULFILLED &&
          !lineItem.isConsignmentPick
      ).length;
    }

    const createShippingLabel = async () => {
      const { params = {} } = match;
      const { id: orderId } = params;
      getVendorListData({ variables: { input: { orderId } } });
    };

    const downloadShippingLabel = async () => {
      setDownloadShipping(true);
      const { params = {} } = match;
      const { id: orderId } = params;
      getVendorListData({ variables: { input: { orderId, isDownloadShipping: true } } });
    };
    const toggleActive = () => setActive((activate) => !activate);

    const handleDisable = (type) => {
      if (selectedItems && selectedItems.length) {
        let disabled = true;
        selectedItems.forEach((id) => {
          const data = orderLineItemsData.find((item) => item._id === id);
          if (type === constant.ACCEPT && data && data.status && data.status === constant.NEW) {
            disabled = false;
          }
          if (
            type === constant.FULFILLMENT &&
            data &&
            data.status === constant.ACCEPTED &&
            data.fulfillmentType !== constant.RESERVE &&
            data.fulfillmentType !== constant.COLLECT &&
            data.fulfillmentType !== constant.SERVICE
          ) {
            disabled = false;
          }
        });
        return disabled;
      }
    };
    const activator = (
      <Button onClick={toggleActive} disclosure>
        {cms("label.manage")}
      </Button>
    );

    const openModel = (event, items) => {
      switch (event) {
        case constant.BULK_EDIT:
          setSelectedItems(items);
          setEventKey(event);
          setEditModal(true);
          break;
        case constant.FULFILLED:
          setSelectedItems(items);
          setEventKey(event);
          setFulfillModal(true);
          break;
        default:
          setFulfillModal(false);
          setEditModal(false);
          break;
      }
    };

    const actionButtons = [
      {
        content: toggleStatus.unfulfilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.UNFULFILLEDLINEITEMS,
      },
    ];

    const fulfilledActionButton = [
      {
        content: toggleStatus.fulFilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.FULFILLEDLINEITEMS,
      },
    ];

    const fulfilledServiceActionButton = [
      {
        content: toggleStatus.fulfilledServiceItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.FULFILLED_SERVICE_ITEMS,
      },
    ];

    const { shippingOption = "" } = dropship || {};
    const isPaid = shippingOption === constant.SENDABOX ? isPaidStatus(financialStatus, isAllFullfilled) : true;

    if (
      (fulfillmentType &&
        fulfillmentType.includes(constant.DROPSHIP) &&
        dropShipIsThirdPartyShipping &&
        !isConsignmentValue &&
        options) ||
      (fulfillmentType &&
        fulfillmentType.includes(constant.CONSIGNMENT) &&
        consignmentIsThirdPartyShipping &&
        !isConsignmentValue &&
        options)
    ) {
      const shippingLabel = {
        action: () => createShippingLabel(),
        caption: cms("label.shippingLabel"),
        id: generateLabelId,
        label: cms("label.shippingLabel"),
        icon: TipsMajor,
        primary: true,
        disabled: !isPaid,
      };
      linkData.push(shippingLabel);
    }

    if (
      (fulfillmentType &&
        fulfillmentType.includes(constant.DROPSHIP) &&
        (dropShipIsThirdPartyShipping || consignmentIsThirdPartyShipping) &&
        enableDownloadLabel) ||
      (fulfillmentType &&
        fulfillmentType.includes(constant.CONSIGNMENT) &&
        (dropShipIsThirdPartyShipping || consignmentIsThirdPartyShipping) &&
        enableDownloadLabel)
    ) {
      const shippingLabel = {
        action: () => downloadShippingLabel(),
        caption: cms("label.downloadShippingLabel"),
        id: downloadLabelId,
        label: cms("label.downloadShippingLabel"),
        icon: TipsMajor,
      };

      linkData.push(shippingLabel);
    }

    const handleChange = (orders) => {
      if (selectedItems.length !== unfulfillCount) {
        const arrSelected = [...selectedItems];
        orders.forEach((order) => {
          const { _id: id, fulfillmentStatus, status, collectDetail, fulfillableQuantity } = order;
          if (
            fulfillmentStatus !== constant.FULFILLED &&
            !(status === constant.REJECTED || status === constant.CLOSED) &&
            !collectDetail &&
            fulfillableQuantity
          ) {
            const foundSelect = selectedItems.find((element) => element === id);
            if (!foundSelect) {
              arrSelected.push(order._id);
            }
          }
        });
        setSelectedItems(arrSelected);
      } else {
        setSelectedItems([]);
      }
      setActive(false);
    };

    const bulkModal = () => {
      return (
        <>
          {eventKey === constant.BULK_EDIT && (
            <EditModal
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              orderLineItemsData={orderLineItemsData}
              bulkEditItem={bulkEditItem}
              isDynamicDiscount={isDynamicDiscount}
              setBanner={setBanner}
              setMessage={setMessage}
              refetchOrderLineView={refetchOrderLineView}
              refetchOrderRefund={refetchOrderRefund}
              refetchOrderView={refetchOrderView}
              active={editModal}
              setActive={setEditModal}
              isBulkChecked={isBulkChecked}
              bulkEditLoading={bulkEditLoading}
            />
          )}
          {eventKey === constant.FULFILLED && (
            <FulfillModel
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              orderLineItemsData={orderLineItemsData}
              active={fulfillModal}
              setActive={setFulfillModal}
              setBanner={setBanner}
              setMessage={setMessage}
              refetchOrderLineView={refetchOrderLineView}
              refetchOrderView={refetchOrderView}
              bulkFulfillItem={bulkFulfillItem}
              loading={bulkFulfillLoading}
              refetchOrderFulfillment={refetchOrderFulfillment}
              isEnabledData={isEnabledData}
            />
          )}
        </>
      );
    };

    const getPopoverContent = () => {
      const items = [];
      selectedItems.forEach((element) => {
        const filterItem = viewLineOrderData.filter(
          (item) => item._id === element && item.status === constant.ACCEPTED
        );
        items.push(...filterItem);
      });
      if (items.length && items.filter((item) => item.fulfillmentType === constant.RESERVE).length === items.length) {
        return cms("popover.action.ready");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.COLLECT).length === items.length) {
        return cms("popover.action.readyCollect");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.PICKUP).length === items.length) {
        return cms("label.readyToCollect");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.SERVICE).length === items.length) {
        return cms("popover.action.readyService");
      }
      return cms("popover.action.fulfill");
    };

    const getFulfillmentContent = () => {
      if (isReserveAndCollect) {
        return cms("label.paidAndCollected");
      }
      if (isLineService) {
        return cms("label.paidAndAvailed");
      }
      return baseHelper.ucFirst(constant.FULFILLED);
    };

    const getSlipText = (fulfillType) => {
      if ([constant.RESERVE].includes(fulfillType)) {
        return <Stack.Item>{`${cms("label.reserveSlip")}`}</Stack.Item>;
      }
      if (fulfillType === constant.SERVICE) {
        return <Stack.Item>{`${cms("label.serviceSlip")}`}</Stack.Item>;
      }
      if (fulfillType === constant.COLLECT) {
        return <Stack.Item>{`${cms("label.pickupSlip")}`}</Stack.Item>;
      }
      return <Stack.Item>{`${cms("label.packingSlip")}`}</Stack.Item>;
    };

    // if (fulfillmentType.includes("dropship") && isThirdPartyShipping && !isConsignmentValue) {
    //   const shippingLabel = {
    //     content: cms("label.shippingLabel"),
    //     onClick: () => createShippingLabel(),
    //     id: generateLabelId,
    //   };
    //   if (unfulfillCount) {
    //     actionButtons.unshift(shippingLabel);
    //   }

    //   if (fulfillCount) {
    //     fulfilledActionButton.unshift(shippingLabel);
    //   }
    // }

    // if (fulfillmentType.includes("dropship") && isThirdPartyShipping && enableDownloadLabel) {
    //   const shippingLabel = {
    //     content: cms("label.downloadShippingLabel"),
    //     onClick: () => downloadShippingLabel(),
    //     id: downloadLabelId,
    //   };

    //   if (unfulfillCount) {
    //     actionButtons.unshift(shippingLabel);
    //   }
    //   if (fulfillCount) {
    //     fulfilledActionButton.unshift(shippingLabel);
    //   }
    // }

    return (
      <>
        {(unfulfillCount && (
          <Card title={renderHeading(baseHelper.ucFirst(constant.UNFULFILLED), unfulfillCount)} actions={actionButtons}>
            <br />
            <Collapsible id={constant.UNFULFILLEDLINEITEMS} open={toggleStatus.unfulfilledLineItems.active}>
              {isBulkChecked && selectedItems.length ? (
                <>
                  <Card.Section>
                    <Stack wrap={false}>
                      <ButtonGroup segmented>
                        <StyledButton>
                          <Button>
                            <Checkbox
                              label={isBulkChecked && `${selectedItems.length} ${cms("label.select")}`}
                              checked={
                                (isBulkChecked &&
                                  (selectedItems.length === unfulfillCount
                                    ? true
                                    : cms("label.indeterminate").toLowerCase())) ||
                                ""
                              }
                              onChange={() => [handleChange(orderLineItemsData), setIsBulkChecked(true)]}
                            />
                          </Button>
                        </StyledButton>
                        <Stack>
                          <Stack.Item>
                            <Popover active={active} fullHeight fullWidth activator={activator} onClose={toggleActive}>
                              <ActionList
                                items={[
                                  {
                                    content: cms("popover.action.accept"),
                                    onAction: () => handleBulkAccept(),
                                    icon: CircleTickOutlineMinor,
                                    disabled: handleDisable(constant.ACCEPT),
                                  },
                                  {
                                    content: cms("popover.action.edit"),
                                    onAction: () => [
                                      openModel(constant.BULK_EDIT, selectedItems),
                                      setIsBulkChecked(true),
                                    ],
                                    icon: EditMinor,
                                    disabled: isCapmaignOrder,
                                  },
                                  {
                                    content: getPopoverContent(),
                                    onAction: () => openModel(constant.FULFILLED, selectedItems),
                                    icon: QuickSaleMajor,
                                    disabled: handleDisable(constant.FULFILLMENT) || isCapmaignOrder,
                                  },
                                  {
                                    content: cms("popover.action.reject"),
                                    onAction: () => [setRejectModal(true), setIsBulk(true)],
                                    icon: CircleCancelMinor,
                                    destructive: true,
                                  },
                                ]}
                              />
                            </Popover>
                          </Stack.Item>
                        </Stack>
                      </ButtonGroup>
                    </Stack>
                  </Card.Section>
                </>
              ) : (
                <Card.Section>
                  <Stack>
                    <Stack.Item>
                      <Checkbox
                        label={cms("label.show", { item: unfulfillCount })}
                        checked={isBulkChecked && selectedItems.length === unfulfillCount}
                        onChange={() => [handleChange(orderLineItemsData), setIsBulkChecked(true)]}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              )}
              {bulkModal()}
              {handelRejectModal()}
              <UnfulfilledLineItems
                handleBulkReject={handleBulkReject}
                setRejectModal={setRejectModal}
                handleBulkAccept={handleBulkAccept}
                itemData={orderLineItemsData}
                onRefetchOrderLineView={refetchOrderLineView}
                onRefetchOrderView={refetchOrderView}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                bulkFulfillData={bulkFulfillData}
                openModel={openModel}
                bulkFulfillItem={bulkFulfillItem}
                setBanner={setBanner}
                setMessage={setMessage}
                fulfillment={fulfillmentView}
                data={dataOrderViews}
                setConsignmentValue={setConsignmentValue}
                fulfillmentType={fulfillmentType}
                setFulfillmentType={setFulfillmentType}
                linkData={linkData}
                setActive={setActive}
                actionData={actionData}
                setActionData={setActionData}
                setIsBulkChecked={setIsBulkChecked}
                isBulkChecked={isBulkChecked}
                loading={acceptLoading || bulkFulfillLoading}
                refetchOrderFulfillment={refetchOrderFulfillment}
                lineLoading={lineLoading}
                setLineLoading={setLineLoading}
                getSlipText={getSlipText}
                setIsCapmaignOrder={setIsCapmaignOrder}
                isEnabledData={isEnabledData}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(collectCount && (
          <Card
            title={renderHeading("Ready For Collection", collectCount, true)}
            actions={[
              {
                content: toggleStatus.readyForCollect.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "readyForCollect",
              },
            ]}
          >
            {/* {isBulkReserveChecked && selectedItems.length ? (
              <>
                <Card.Section>
                  <Stack wrap={false}>
                    <ButtonGroup segmented>
                      <Button size="slim">
                        <StyledButton>
                          <Checkbox
                            label={isBulkReserveChecked && `${selectedItems.length} ${cms("label.select")}`}
                            checked={
                              (isBulkReserveChecked &&
                                (selectedItems.length === collectCount
                                  ? true
                                  : cms("label.indeterminate").toLowerCase())) ||
                              ""
                            }
                            disabled
                          />
                        </StyledButton>
                      </Button>
                      <Stack.Item>
                        <Popover active={active} fullHeight fullWidth activator={activator} onClose={toggleActive}>
                          <ActionList
                            items={
                              [
                                // {
                                //   content: "Confirm Collect",
                                //   onAction: () => setActiveReserveCollect(true),
                                //   icon: ReturnMinor,
                                //   // disabled: handleDisable(constant.ACCEPT),
                                // },
                              ]
                            }
                          />
                        </Popover>
                      </Stack.Item>
                    </ButtonGroup>
                  </Stack>
                </Card.Section>
              </>
            ) : (
              <Card.Section>
                <Stack>
                  <Stack.Item>
                    <Checkbox
                      label={`Showing ${collectCount} items`}
                      checked={isBulkReserveChecked && selectedItems.length === collectCount}
                      // onChange={() => [handleBulkReserve(orderLineItemsData), setIsBulkReserveChecked(true)]}
                      disabled
                    />
                  </Stack.Item>
                </Stack>
              </Card.Section>
            )} */}
            {/* {renderCollection()}
            {renderTracking()} */}
            <br />
            <Collapsible id={constant.RESERVE} open={toggleStatus.readyForCollect.active}>
              <ReadyForCollect
                itemData={orderLineItemsData}
                // openClickReserveModal={openClickReserveModal}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                // handleSelect={handleSelect}
                refundData={refundData}
                setSelectedReserveItemData={setSelectedItemData}
                selectedReserveItemData={selectedItemData}
                setIsBulkReserveChecked={setIsBulkReserveChecked}
                isBulkReserveChecked={isBulkReserveChecked}
                setBulkQuantity={setBulkQuantity}
                bulkQuantity={bulkQuantity}
                activeReserveCollect={activeReserveCollect}
                setActiveReserveCollect={setActiveReserveCollect}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(serviceCount && (
          <Card
            title={renderHeading("Ready For Service", serviceCount, true)}
            actions={[
              {
                content: toggleStatus.readyForService.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "readyForService",
              },
            ]}
          >
            <br />
            <Collapsible id={constant.SERVICE} open={toggleStatus.readyForService.active}>
              <ReadyForService itemData={orderLineItemsData} refundData={refundData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(pendingCount && (
          <Card
            title={renderHeading("Pending Ready For Collection", pendingCount, true)}
            actions={[
              {
                content: toggleStatus.pendingForCollect.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "pendingForCollect",
              },
            ]}
          >
            <br />
            <Collapsible id="pendingForCollect" open={toggleStatus.pendingForCollect.active}>
              <PendingForCollect
                itemData={orderLineItemsData}
                // openClickReserveModal={openClickReserveModal}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                // handleSelect={handleSelect}
                refundData={refundData}
                setSelectedReserveItemData={setSelectedItemData}
                selectedReserveItemData={selectedItemData}
                setIsBulkReserveChecked={setIsBulkReserveChecked}
                isBulkReserveChecked={isBulkReserveChecked}
                setBulkQuantity={setBulkQuantity}
                bulkQuantity={bulkQuantity}
                activeReserveCollect={activeReserveCollect}
                setActiveReserveCollect={setActiveReserveCollect}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(pendingServiceCount && (
          <Card
            title={renderHeading("Pending Ready For Service", pendingServiceCount, true)}
            actions={[
              {
                content: toggleStatus.pendingForService.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "pendingForService",
              },
            ]}
          >
            <br />
            <Collapsible id="pendingForService" open={toggleStatus.pendingForService.active}>
              <PendingForService itemData={orderLineItemsData} refundData={refundData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(orderFulfillment && orderFulfillment.length && fulfilledCount && (
          <Card title={renderHeading(getFulfillmentContent(), fulfilledCount, true)} actions={fulfilledActionButton}>
            <br />
            <Collapsible id={constant.FULFILLEDLINEITEMS} open={toggleStatus.fulFilledLineItems.active}>
              <FulfilledLineItems
                fulfilledData={fulfilledData}
                data={dataOrderViews}
                lineItemArray={viewLineOrderData}
                setFulfillAmount={setFulfillAmount}
                getSlipText={getSlipText}
                packingSlipAccessData={packingSlipAccessData}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLMENTS), fulfillCount, true)}
            actions={[
              {
                content: toggleStatus.fulfillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULFILLMENTS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLMENTS} open={toggleStatus.fulfillments.active}>
              <Fulfillments
                lineData={orderFulfillment}
                fulfillmentsCount={fulfillCount}
                fulfillmentType={fulfillmentType}
                lineCount={orderLineItemsData}
                setFulfillmentType={setFulfillmentType}
                setBanner={setBanner}
                setMessage={setMessage}
                refetchOrderFulfillment={refetchOrderFulfillment}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(pickUpCount && (
          <Card
            title={renderHeading(cms("label.readyToCollect"), pickUpCount, true)}
            actions={[
              {
                content: toggleStatus.pickup.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.pickup"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.pickup")} open={toggleStatus.pickup.active}>
              <Pickup lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(consignmentShipCount && (
          <Card
            title={renderHeading(cms("label.consignmentShip"), consignmentShipCount, true)}
            actions={[
              {
                content: toggleStatus.vendorFulfillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.vendorFulfillments"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.vendorFulfillments")} open={toggleStatus.vendorFulfillments.active}>
              <VendorFulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(((refundLineItems && refundLineItems.length) || rejectCount) && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.REJECTED), rejectCount)}
            actions={[
              {
                content: toggleStatus.rejected.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.REJECTED,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.REJECTED} open={toggleStatus.rejected.active}>
              <RejectedLineItems
                refundLineData={refundData}
                data={dataOrderViews}
                lineItemArray={viewLineOrderData}
                setRejectAmount={setRejectAmount}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(returnCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.RETURN), returnCount, true)}
            actions={[
              {
                content: toggleStatus.return.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.RETURN,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.RETURN} open={toggleStatus.return.active}>
              <ReturnedLineItems data={dataOrderViews} lineItemArray={viewLineOrderData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(paymentCount && (
          <Card
            title={renderPaymentSectionHeading(financialStatus, isAllFullfilled)}
            actions={[
              {
                content: toggleStatus.fullFillmentStatus.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULLFILLMENTSTATUS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULLFILLMENTSTATUS} open={toggleStatus.fullFillmentStatus.active}>
              <FulfillmentStatus
                lineData={orderLineItemsData}
                data={dataOrderViews}
                term={term}
                fulfilledData={fulfilledData}
                totalRejectAmount={totalRejectAmount}
                totalFulfilledAmount={totalFulfilledAmount}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
      </>
    );
  };

  let isNote = true;
  const getSecondaryComponent = (dataOrderViews) => {
    const { note } = dataOrderViews || {};
    if (!note) {
      isNote = false;
    }

    if (linkData && linkData.length && !isActionData) {
      setActionData(linkData);
      setIsActionData(true);
    }

    return (
      <>
        {dataOrderViews && (
          <>
            {linkData && linkData.length >= 1 && <QuickAction link={linkData} />}
            <Notes
              note={(isNote && note) || ""}
              dataItem={dataOrderViews}
              setBanner={setBanner}
              setMessage={setMessage}
              onRefetchOrderView={refetchOrderView}
            />
            <br />
            <Customer data={dataOrderViews} />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      {(loadingOrderView || loadingOrderLineView || loadingOrderRefund) && (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      )}
      <Layout.Section>
        {isError && (
          <Layout.Section>
            <div style={{ display: "flex" }}>
              <NotFound />
            </div>
          </Layout.Section>
        )}
        <Toast message={message} setToast={setMessage} timeout={5000} />
        {viewOrderData && viewLineOrderData && renderItemData(viewOrderData, viewLineOrderData)}
        {renderConsignmentVendor()}
      </Layout.Section>
      <Layout.Section secondary>{getSecondaryComponent(viewOrderData)}</Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorOrderView), { feature: constant.VIEW_ORDER });
