import React, { useContext } from "react";

// import constant
import constant from "lib/constant/constant";

// import components
import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import NotFound from "layout/private/components/notFound/notFound";

// storage helpers
import { storageHelper } from "lib/helpers";

// import features
// import { AdminPaymentList } from "./admin/features";
import { OperatorPaymentList } from "./operator/features";
import { ProviderPaymentList } from "./provider/features";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const PaymentList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    // if (role === admin) {
    //   return <AdminPaymentList />;
    // }
    if (role === operator) {
      return <OperatorPaymentList />;
    }
    if (role === provider) {
      return <ProviderPaymentList />;
    }
    return <NotFound />;
  };

  const currentUserRole = storageHelper.get("userRole");
  const PaymentListComponent = getComponent(currentUserRole);
  return PaymentListComponent;
};

export default PaymentList;
