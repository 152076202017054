import React, { createRef, useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";

import { withErrorBoundary } from "lib/hoc";

import { chatHelper } from "lib/helpers";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

import { Spinner } from "lib/components";

const ProviderChat = () => {
  const { chatSetting, isLoading, history } = useContext(PrivateContext);
  const { isCustomer, isVendor } = chatSetting;
  const { userKey } = constant;
  const { operator } = userKey;

  const container = createRef();

  const [state, setState] = useState(false);

  useEffect(() => {
    if (window.talkSession && !state) {
      const inbox = chatHelper.createTalkJSInbox();
      if (isCustomer && isVendor) {
        inbox.setFeedFilter({});
      } else if (isCustomer) {
        inbox.setFeedFilter({
          custom: {
            role: ["!=", operator],
          },
        });
      } else {
        inbox.setFeedFilter({
          custom: {
            role: ["==", operator],
          },
        });
      }
      // Added delay for solve the mounting problem
      setTimeout(() => {
        inbox.mount(container.current);
        setState(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, state, isVendor, isCustomer]);

  if (!isCustomer && !isVendor) {
    history.push("/");
  }
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout.Section>
      <div className="chatbox-container" ref={container} />
    </Layout.Section>
  );
};

export default withErrorBoundary(ProviderChat);