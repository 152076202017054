// import react packages;
import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { PageActions, Stack, TextField, Button } from "@shopify/polaris";

// import helper
import { OnboardingContext, PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { CONNECT_MAGENTO_SHOP } from "app/product/apollo/mutations";

import SyncMagentoProduct from "app/product/modules/provider/features/add/subFeatures/syncMagentoProduct";

// import components
import TermModal from "app/product/modules/provider/features/genric/term/term";
// import newCMSData from "app/v1/products/modules/provider/features/add/subFeatures/wixConnect/cms/cms.json";
import ConnectedShop from "./subFeatureItems/connected";
import DiscoveryTermModal from "../term/discoveryTerm";
import HelpModal from "./subFeatureItems/help";

const VendorConnectMagento = (props) => {
  const { currentUser: currentUserData, history, cms } = useContext(PrivateContext);

  const { magento: magentoConfig = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const { shop = "", accessToken = "" } = magentoConfig || {};
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { CRITICAL, SUCCESS } = constant;
  const { setBanner } = props;
  const [value, setValue] = useState({
    accessToken: accessToken || "",
    shopDomain: shop || "",
  });
  const [isConnected, setIsConnected] = useState(!!shop);
  const [isOpen, setIsOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    shopDomain: "",
    accessToken: "",
  });

  const isProductDiscovery = !!currentUserData?.isProductDiscovery;

  const [connectMagentoShop, { loading }] = useMutation(CONNECT_MAGENTO_SHOP);
  const { CONNECT_MAGENTO_SHOP: STORE_CONNECT } = constant.gql;

  const onSubmit = async () => {
    const { accessToken = "", shopDomain = "" } = value;
    // const isValidDomainPath = baseHelper.isValidDomainPath(shopDomain.trim());
    // if (!isValidDomainPath) {
    //   setBanner({ isOpen: true, status: CRITICAL, title: cms("section.connect.message.error.invalidShop") });
    //   setIsOpen(false);
    //   return;
    // }
    try {
      const response = await connectMagentoShop({
        variables: {
          input: {
            accessToken: (accessToken || "").trim(),
            shopDomain: (shopDomain || "").trim(),
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, STORE_CONNECT);
      if (responseData) {
        setIsConnected(true);
        setBanner({ isOpen: true, status: SUCCESS, title: `${cms("section.connect.message.success.connected")}` });
        setIsOpen(false);
        return;
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const handleTermModal = (fieldName = "check", modalValue = "") => {
    if (!value.shopDomain || !value.accessToken) {
      setIsOpen(false);
      const errorMessages = {
        accessToken: "Access Token is Required",
        shopDomain: cms("section.connect.message.error.domain"),
      };
      setErrorMessage({
        ...errorMessage,
        [fieldName]: !value[fieldName] ? errorMessages[fieldName] : "",
      });

      return;
    }
    if (modalValue === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  return (
    <>
      <HelpModal
        cms={cms}
        isOpen={isHelpOpen}
        setIsOpen={setIsHelpOpen}
        shopDomain={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={() => setIsHelpOpen(false)}
        item="Magento"
      />
      {isProductDiscovery ? <DiscoveryTermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shopDomain={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item="Magento"
      /> : <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shopDomain={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item="Magento"
      />}
      <TextField
        label={`${cms("section.connect.label.shopDomain")}*`}
        error={errorMessage.shopDomain}
        id="shopInput"
        prefix="https://"
        placeholder={cms("section.connect.placeholder.demo")}
        type={constant.TEXT}
        value={value.shopDomain}
        onChange={(inputValue) => handleChange(constant.SHOP_DOMAIN, inputValue)}
        onBlur={() => handleTermModal(constant.SHOP_DOMAIN)}
        disabled={isConnected}
      />
      {!isConnected ? (
        <>
          <br />
          <TextField
            label="Access Token"
            error={errorMessage.accessToken}
            id="accessToken"
            placeholder="Access Token"
            type={constant.TEXT}
            value={value.accessToken}
            onChange={(inputValue) => handleChange(constant.ACCESS_TOKEN, inputValue)}
            onBlur={() => handleTermModal(constant.ACCESS_TOKEN)}
            disabled={isConnected}
            helpText={<Button plain onClick={() => setIsHelpOpen(true)}>Generate your Magento access token</Button>}
          />
        </>
      ) : null}
      {isConnected && (
        <>
          <br />
          <Stack>
            <Stack.Item fill>
              <ConnectedShop
                cms={cms}
                currentUserData={currentUserData}
                setBanner={setBanner}
                setIsConnected={setIsConnected}
                setValue={setValue}
                value={value}
              />
            </Stack.Item>
            <Stack.Item>
              <SyncMagentoProduct history={history} setBanner={setBanner} cms={cms} />
            </Stack.Item>
          </Stack>
        </>
      )}
      <br />
      {!isConnected && (
        <PageActions
          primaryAction={{
            id: "connectShop",
            content: `${cms("section.connect.button.connect")}`,
            onAction: () => handleTermModal("check ", constant.CONNECT),
            disabled: isConnected,
          }}
          secondaryActions={[
            {
              id: "cancelAction",
              content: `${cms("section.connect.button.cancel")}`,
              onAction: () => (isOnboarding && onPrevious()) || history.push("/products"),
            },
          ]}
        />
      )}
    </>
  );
};

VendorConnectMagento.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectMagento.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorConnectMagento), {
  feature: constant.PRODUCT_IMPORT_WOOCOMMERCE,
  // subFeature: constant.PRODUCT_IMPORT_WOOCOMMERCE,
});
