import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import { OperatorProviderEdit } from "./operator";
import { AdminProviderEdit } from "./admin";

const { userKey } = constant;
const { operator, admin } = userKey;

const VendorEdit = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProviderEdit />;
    }
    if (role === admin) {
      return <AdminProviderEdit />;
    }
    return null;
  };
  if (isLoading) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }
  const currentUserRole = storageHelper.get("userRole");
  const VendorAddComponent = getComponent(currentUserRole);
  return VendorAddComponent;
};

export default VendorEdit;
