import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  Banner,
  Button,
  Caption,
  Card,
  FormLayout,
  Layout,
  Link,
  PageActions,
  RadioButton,
  TextField,
  TextStyle,
  Select,
  Stack,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

import { SkeletonAnnotatedCard } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import gql
import { ADVANCE_PICKUP_ORDER_EXPIRY, GET_ORDER_EXPIRY_SETTING, GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";
// import yup validation
import validate from "./yup";

const AdvancePickupAutoExpiry = (props) => {
  const { setBannerStatus = () => {} } = props;
  const { setToast = () => {} } = props;
  const history = useHistory();
  const { currentUser, cms, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: currentUserId = "" } = currentUser || {};
  const { show } = props;
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [lastUpdate, setLastUpdate] = useState("");
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [advancePickupChecked, setAdvancePickupChecked] = useState(false);
  const [error, setError] = useState([""]);
  const [firstPush, setFirstPush] = useState(false);
  const [isAnyError, setIsAnyError] = useState(false);
  const [advancedValue, setAdvancedValue] = useState([
    {
      vendorId: null,
      duration: 0,
    },
  ]);
  const formWidth170px = { width: "170px" };
  const formWidth100px = { width: "200px" };

  const [count, setCount] = useState((advancedValue && advancedValue.length) || 1);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const selectedSuppliers = [];
  const bannerProps = {
    status: "",
    title: "",
  };
  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  const { error: customError, data: orderAutoExpiryData, loading: orderExpiryLoading } = useQuery(
    GET_ORDER_EXPIRY_SETTING,
    {
      variables: {
        input: { sellerId: currentUserId },
      },
    }
  );

  const [updateAdvanceOrderExpiry, { loading: submitLoading }] = useMutation(ADVANCE_PICKUP_ORDER_EXPIRY);

  const handleValidation = async (value) => {
    if (value) {
      if (Number(value) > 999) {
        setSubmitEnable(false);
        setBanner({
          isOpen: true,
          title: cms("message.error.lessThanThousand"),
          status: constant.CRITICAL,
        });
        return;
      }
      setBanner({
        isOpen: false,
      });
    }
  };

  useEffect(() => {
    if (orderAutoExpiryData) {
      const resData = baseHelper.getResponseData(orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING);
      if (resData.order === null || resData.order.advancePickup === null) {
        setFirstPush(true);
        return;
      }
      const buff = resData.order.advancePickup;
      setAdvancePickupChecked(buff.isActive);
      setAdvancedValue(buff.advance);
      setLastUpdate(buff.updatedAt);
      setCount(buff.advance.length);
    }
  }, [orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, gql.GET_VENDOR_PRODUCT);
    const { supplierRows = [] } = responseData;
    advancedValue.map((data) => selectedSuppliers.push(data.vendorId));
    const suppliers = supplierRows.filter((item) => {
      const { _id: id } = item;
      const itemData = item;
      if (itemData.brandName) {
        if (!selectedSuppliers.includes(id)) {
          itemData.disabled = false;
        }
        if (selectedSuppliers.includes(id)) {
          itemData.disabled = true;
        }
        return itemData;
      }
      return null;
    });
    const formattedSuppliers = suppliers
      .filter((item) => item.brandName && item)
      .map(({ _id: vendorId, brandName, disabled }) => ({
        label: brandName,
        value: vendorId,
        disabled,
      }));
    const isAnySuppliers = formattedSuppliers.length;
    setAllSuppliers(formattedSuppliers);
  }, [gql.GET_VENDOR_PRODUCT, cms, vendorAndProductTypesData, vendorAndProductTypesLoading, advancedValue]);

  const handleChange = useCallback((newChecked, id) => {
    if (id === constant.ENABLE_ADVANCE_PICKUP) {
      setAdvancePickupChecked(newChecked);
    }
    if (id === constant.DISABLE_ADVANCE_PICKUP) {
      setAdvancePickupChecked(false);
      setAdvancedValue([]);
      setSubmitEnable(true);
    }
    setError({});
  }, []);
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const onSubmit = async () => {
    setSubmitEnable(false);
    setBanner({ isOpen: false });
    if (advancePickupChecked && advancedValue.length === 0) {
      setBanner({
        isOpen: true,
        title: cms("message.error.addVendor"),
        status: constant.CRITICAL,
      });
      return;
    }
    if (advancePickupChecked && advancedValue.length >= 1) {
      let isInvalid = false;
      advancedValue.forEach((value) => {
        const keys = Object.keys(value);
        if (keys.length === 0) {
          isInvalid = true;
          setBanner({
            isOpen: true,
            title: cms("message.error.incompleteRow"),
            status: constant.CRITICAL,
          });
        } else {
          keys.forEach((key) => {
            if (key === constant.VENDOR_ID && !value[key]) {
              isInvalid = true;
              setBanner({
                isOpen: true,
                title: cms("message.error.addVendor"),
                status: constant.CRITICAL,
              });
            }
            if (key === constant.DURATION && !value[key] && !isInvalid) {
              isInvalid = true;
              setBanner({
                isOpen: true,
                title: cms("message.error.required"),
                status: constant.CRITICAL,
              });
            }
          });
        }
      });
      if (isInvalid) return;
    }
    let inputData = {};
    if (advancePickupChecked) {
      inputData = {
        isActive: advancePickupChecked,
        advance: advancedValue,
      };
    } else {
      inputData = {
        isActive: advancePickupChecked,
      };
    }
    const res = await updateAdvanceOrderExpiry({
      variables: {
        input: inputData,
      },
    });

    if (res) {
      const resData = baseHelper.getResponseData(res.data, gql.ADVANCE_PICKUP_ORDER_EXPIRY);
      const resError = baseHelper.getResponseError(res.data, gql.ADVANCE_PICKUP_ORDER_EXPIRY);
      if (resError) {
        setBannerStatus({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
      }
      if (resData) {
        setToast({
          isOpen: true,
          content: cms("message.success.setting"),
        });
      }
    }
  };

  const removeItem = (index) => {
    const updatedAdvancedValue = [...advancedValue];
    updatedAdvancedValue.splice(index, 1);
    setAdvancedValue(updatedAdvancedValue);
    setCount(count - 1);
    setSubmitEnable(true);
  };

  const updateValue = ({ option, value, item }) => {
    let duplicate = false;
    const updatedAdvancedValue = [...advancedValue];
    if (option === constant.VENDOR) {
      updatedAdvancedValue[item].duration = null;
      updatedAdvancedValue.forEach((data) => {
        if (data.vendorId === value) {
          updatedAdvancedValue[item].vendorId = null;
          setBanner({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          duplicate = true;
        }
      });
    }
    if (duplicate) {
      return;
    }
    if (option === constant.VENDOR) updatedAdvancedValue[item].vendorId = value;
    if (option === constant.VALUE) updatedAdvancedValue[item].duration = Number(value);
    setAdvancedValue(updatedAdvancedValue);
    setSubmitEnable(true);
  };

  const addItem = () => {
    if (advancedValue.length >= 1) {
      const line = advancedValue[advancedValue.length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key] || line[key] === constant.SELECT) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({
          isOpen: true,
          title: cms("message.error.incompleteRow"),
          status: constant.CRITICAL,
        });
        return;
      }
    }

    const row = { vendorId: null, duration: "" };
    const updatedAdvancedValue = [...advancedValue];
    updatedAdvancedValue.push(row);
    setAdvancedValue(updatedAdvancedValue);
    setCount(count + 1);
  };

  const renderAdvancePickupItems = () => {
    const indexes = Array.from(Array(count).keys());
    return indexes.map((index) => {
      const advanceItem = advancedValue[index];
      const getTypeOptions = [...allSuppliers];

      if (!advanceItem) {
        return null;
      }
      return (
        <Stack wrap={false} key={index} spacing="tight" alignment="center">
          <Stack.Item>
            <div style={formWidth170px}>
              <Select
                id={`advanceOptionSelect${index}`}
                options={getTypeOptions}
                placeholder="Select"
                value={(advanceItem && advanceItem.vendorId) || ""}
                onChange={(value) => updateValue({ option: "vendor", value, item: index })}
              />
            </div>
          </Stack.Item>
          <div style={formWidth100px}>
            <Stack.Item>
              <TextField
                id={`advanceValue${index}`}
                // onBlur={(value) => updateValue({ option: "Value", value, item: index })}
                onBlur={() => handleValidation(advanceItem.duration)}
                suffix="Days"
                onChange={(value) => updateValue({ option: "value", value, item: index })}
                value={
                  (advanceItem && Number(advanceItem.duration) && parseFloat(advanceItem.duration).toString()) || ""
                }
                // onBlur={() => handleValidation("Acceptance", advanceItem.duration, index)}
                error={error[index] || null}
              />
            </Stack.Item>
          </div>
          <Stack.Item>
            <Button id={`advanceDeleteButton${index}`} plain onClick={() => removeItem(index)}>
              <i className="far fa-trash fa-lg redColor" />
            </Button>
          </Stack.Item>
        </Stack>
      );
    });
  };

  if (orderExpiryLoading) {
    return <SkeletonAnnotatedCard />;
  }

  return (
    <Layout.AnnotatedSection>
      <Card
        sectioned
        title={[
          cms("label.advancePickup"),
          lastUpdate && (
            <Caption>
              <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdate)}`}</TextStyle>
            </Caption>
          ),
        ]}
      >
        <Stack vertical>
          <RadioButton
            label={cms("label.disable")}
            helpText={cms("helpText.disable")}
            checked={advancePickupChecked === false}
            id="disableAdvancePickup"
            onChange={handleChange}
          />
          <RadioButton
            label={cms("label.enable")}
            helpText={cms("helpText.enable")}
            id="enableAdvancePickup"
            checked={advancePickupChecked === true}
            onChange={handleChange}
          />
        </Stack>
        {banner.isOpen && (
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        )}
        <div className="check-box">
          <FormLayout>
            {advancePickupChecked && renderAdvancePickupItems()}
            {advancePickupChecked && (
              <Link id="addLink" onClick={addItem}>
                {cms("common.button.addMore")}
              </Link>
            )}
          </FormLayout>
        </div>
        <div className="manageCustomerButton">
          <PageActions
            primaryAction={{
              content: firstPush ? cms("common.button.submit") : cms("common.button.update"),
              onAction: () => onSubmit(),
              disabled: isAnyError || submitLoading || !submitEnabled,
              loading: submitLoading,
            }}
          />
        </div>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default withErrorBoundary(AdvancePickupAutoExpiry);
