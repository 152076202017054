import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ActionList,
  Badge,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  DisplayText,
  Frame,
  Heading,
  Icon,
  Layout,
  Link,
  Modal,
  Popover,
  ResourceList,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import {
  CircleDisabledMajor,
  CircleInformationMajor,
  CircleTickMajor,
  CircleTickOutlineMinor,
  CircleCancelMinor,
  EditMinor,
  QuickSaleMajor,
  ReturnMinor,
  TipsMajor,
} from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { withFeature, withErrorBoundary } from "lib/hoc";

import { Banner, Spinner as Loader, Toast } from "lib/components";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { NotFound } from "layout/private/components";

import {
  getOrderView,
  getOrderLineView,
  GET_OPERATOR_SETTING,
  GET_ORDER_REFUND,
  GET_ORDER_FULFILLMENT,
} from "app/orders/apollo/viewOrder";

import { GET_ASSOCIATED_SELLER, GET_SELLER_FULFILLMENT } from "app/setup/apollo";
import {
  BULK_ACCEPT,
  BULK_EDIT,
  BULK_FULFILL,
  BULK_REJECT,
  GENERATE_SHIPPING_LABEL,
} from "app/orders/apollo/mutations";

import { DOWNLOAD_LABEL, GET_ADDRESS, GET_ADDRESS_DETAILS, GET_CUSTOMER, GET_UPLOAD } from "app/orders/apollo/queries";

import {
  GET_FULFILLMENT_TRACKING_DETAILS_SETTING,
  GET_PACKING_SLIP_ACCESS,
  GET_ORDER_LIVE_CONNECT_SETTING,
} from "app/setup/apollo/queries";

import { errorHelper, imageHelper } from "lib/helpers";
import { QuickAction } from "app/reports";

import { OrderProvider } from "./orderContext";

import {
  Customer,
  FulfilledLineItems,
  Fulfillments,
  FulfillmentStatus,
  Notes,
  Pickup,
  RejectedLineItems,
  ReturnedLineItems,
  UnfulfilledLineItems,
  VendorFulfillments,
} from "./features";

import { EditModal, FulfillModel } from "./modal";
import { StyledButton } from "./genericStyled";
import ReadyForCollect from "./features/readyForCollect";
import PendingForCollect from "./features/pendingForCollect";
import CollectOrder from "./features/collectOrder";
import { FieldSetQty } from "./modal/modalStyle";

const { gql, HIDE, SHOW } = constant;

const downloadLabelId = "download-shipping-Label";
const generateLabelId = "shipping-Label";

const ProviderOrderView = () => {
  const { cms, currentUser, history, match, setPageData, setting } = useContext(PrivateContext);
  const { brandName, introductorySeller: currentUserId, _id } = currentUser;
  const { isReviewOrder = false } = setting || {};

  const [active, setActive] = useState(false);
  const [activeReserveCollect, setActiveReserveCollect] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [fulfillModal, setFulfillModal] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [isBulkChecked, setIsBulkChecked] = useState(false);
  const [isBulkMark, setIsBulkMark] = useState(false);
  const [isBulkReserveChecked, setIsBulkReserveChecked] = useState(false);
  const [isConsignmentValue, setConsignmentValue] = useState(false);
  const [isDynamicDiscount, setIsDynamicDiscount] = useState(false);
  const [isShippo, setIsShippo] = useState(true);
  const [isDownload, setIsDownload] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isHideBilling, setIsHideBilling] = useState(false);
  const [isLiveConnect, setIsLiveConnect] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [viewLineOrderData, setViewLineOrderData] = useState(false);
  const [viewOrderData, setViewOrderData] = useState(false);

  const [checked, setChecked] = useState(false);
  const [restock, setRestock] = useState(false);
  const [actionData, setActionData] = useState([]);
  const [addressLabel, setAddressLabel] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [bulkQuantity, setBulkQuantity] = useState([]);
  const [fulfillmentType, setFulfillmentType] = useState([]);
  const [lineLoading, setLineLoading] = useState([]);
  const [orderFulfillment, setOrderFulfillment] = useState([]);
  const [orderRefund, setOrderRefund] = useState([]);
  const [packingSlipAccessData, setPackingSlipAccessData] = useState([]);
  const [selectedCollectItems, setSelectedCollectItems] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [totalFulfilledAmount, setFulfillAmount] = useState(0);
  const [totalRejectAmount, setRejectAmount] = useState(0);

  const [addressId, setAddressId] = useState("");
  const [eventKey, setEventKey] = useState("");
  const [message, setMessage] = useState("");
  const [orderConsignmentId, setOrderConsignmentId] = useState("");
  const [isSingleOperation, setIsSingleOperation] = useState(false);
  const [term, setTerm] = useState("");
  const [isBulkCheckedFulfill, setIsBulkCheckedFulfilled] = useState(false);
  const [isCapmaignOrder, setIsCapmaignOrder] = useState(false);
  const [selectedAddressLabel, setSelectedAddressLabel] = useState("");
  const [sellerAddresId, setSellerAddresId] = useState("");
  const [isEnabledData, setIsEnabledData] = useState(false);


  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });
  const [orderLiveConnectSetting, setorderLiveConnectSetting] = useState({
    isShippingAddress: false,
    isOrderNote: false,
    isBillingAddress: false,
    isCustomer: false,
  });
  const [selectedAddress, setSelectedAddress] = useState({
    address: currentUser.address,
    city: currentUser.city,
    country: currentUser.country,
    postalCode: currentUser.pinCode,
  });

  const [toggleStatus, setToggleStatus] = useState({
    unfulfilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fullFillmentStatus: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulFilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    rejected: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    return: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulfillments: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    vendorFulfillments: {
      active: false,
      toggleText: SHOW,
    },
    readyForCollect: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    pendingForCollect: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    pickup: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
  });

  const handleModalChange = useCallback(() => {
    setRejectModal(!rejectModal);
    setChecked(false);
    setRestock(false);
  }, [rejectModal]);

  const {
    data: dataOrderRefund,
    error: errorOrderRefund,
    loading: loadingOrderRefund,
    refetch: refetchOrderRefund,
  } = useQuery(GET_ORDER_REFUND, {
    variables: { input: { orderId: match.params.id } },
  });

  const { data: operatorSettingData, error: errorOperatorSetting, loading: loadingOperatorSetting } = useQuery(
    GET_OPERATOR_SETTING
  );

  const {
    error: errorOrderFulfillment,
    data: dataOrderFulfillment,
    // eslint-disable-next-line no-unused-vars
    loading: loadingOrderFulfillment,
    refetch: refetchOrderFulfillment,
  } = useQuery(GET_ORDER_FULFILLMENT, {
    variables: { input: { orderId: match.params.id } },
  });

  const [bulkAccept, { loading: acceptLoading }] = useMutation(BULK_ACCEPT, {
    refetchQueries: [
      {
        query: getOrderLineView,
        variables: {
          input: { orderId: match.params.id },
        },
      },
    ],
  });

  const {
    error: orderLiveConnectSettingError,
    data: orderLiveConnectSettingData,
    loading: orderLiveConnectSettingLoading,
  } = useQuery(GET_ORDER_LIVE_CONNECT_SETTING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(orderLiveConnectSettingData, gql.GET_ORDER_LIVE_CONNECT_SETTING);
    const responseError = baseHelper.getResponseError(orderLiveConnectSettingError, gql.GET_ORDER_LIVE_CONNECT_SETTING);
    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    if (responseData) {
      setorderLiveConnectSetting(responseData);
    }
  }, [orderLiveConnectSettingData, orderLiveConnectSettingError]);

  const [bulkReject, { loading: rejectLoading }] = useMutation(BULK_REJECT);
  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  const {
    loading: loadingOrderLineView,
    error: errorOrderLineView,
    data: dataOrderLineView,
    refetch: refetchOrderLineView,
  } = useQuery(getOrderLineView, {
    variables: { input: { orderId: match.params.id } },
  });

  const { loading: loadingOrderView, error: errorOrderView, data: dataOrderView, refetch: refetchOrderView } = useQuery(
    getOrderView,
    {
      variables: { input: { id: match.params.id } },
    }
  );

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  // , {
  //   variables: {
  //     input: { sellerId: currentUserId },
  //   },
  // });

  const { data: sellerData } = useQuery(GET_ASSOCIATED_SELLER);

  const {
    error: errorGetPackingSlipAccess,
    loading: loadingGetPackingSlipAccess,
    data: dataGetPackingSlipAccess,
    refetch,
  } = useQuery(GET_PACKING_SLIP_ACCESS);

  const { data: trackingData, loading: fulfillSettingLoading } = useQuery(GET_FULFILLMENT_TRACKING_DETAILS_SETTING);

  const packingSlipResponseData = baseHelper.getResponseData(dataGetPackingSlipAccess, "getPackingSlipAccess");
  const packingSlipErrorData = baseHelper.getResponseError(dataGetPackingSlipAccess, "getPackagingSlipAccess");
  useEffect(() => {
    if (packingSlipResponseData) {
      setPackingSlipAccessData(packingSlipResponseData);
    }
    if (packingSlipErrorData) {
      setIsError(true);
    }
  }, [packingSlipResponseData, packingSlipErrorData]);

  const { loading: addressLoading, data: addressData, error } = useQuery(GET_ADDRESS);
  const [getSellerAddress, { loading: sellerAddressLoading, data: sellerAddressData }] = useLazyQuery(
    GET_ADDRESS_DETAILS
  );

  const fulfillmentView = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};

  const sellerView = baseHelper.getResponseData(sellerData, gql.GET_ASSOCIATED_SELLER) || {};
  fulfillmentView.seller = sellerView;
  const customerView = baseHelper.getResponseData(customerData, gql.GET_CUSTOMER);

  useEffect(() => {
    if (dataOrderRefund) {
      const dataRefund = baseHelper.getResponseData(dataOrderRefund, gql.GET_ORDER_REFUND);
      const dataRefundError = baseHelper.getResponseError(errorOrderRefund, gql.GET_ORDER_REFUND);
      if (dataRefund) {
        setOrderRefund(dataRefund);
      }
      if (dataRefundError) {
        setIsError(true);
      }
    }
  }, [dataOrderRefund, errorOrderRefund]);

  useEffect(() => {
    if (dataOrderFulfillment) {
      const dataFulfillment = baseHelper.getResponseData(dataOrderFulfillment, gql.GET_ORDER_FULFILLMENT);
      const dataFulfillmentError = baseHelper.getResponseError(errorOrderFulfillment, gql.GET_ORDER_FULFILLMENT);

      if (dataFulfillment && dataFulfillment.length) {
        const updatedOrderFulfillments = [];

        dataFulfillment.forEach((item) => {
          const isExists = updatedOrderFulfillments.find(
            (updatedOrderFulfillment) => updatedOrderFulfillment.shopifyFulfillmentId === item.shopifyFulfillmentId
          );
          if (!isExists) {
            updatedOrderFulfillments.push(item);
          }
        });

        setOrderFulfillment(updatedOrderFulfillments);
      } else {
        setOrderFulfillment([]);
      }

      if (dataFulfillmentError) {
        setIsError(true);
      }
    }
  }, [dataOrderFulfillment, errorOrderFulfillment]);

  useEffect(() => {
    if (addressList && addressList.length) {
      const formattedAddress = addressList
        .filter((item) => item.label && item)
        .map(({ _id: addressID, label, isDefault = false }) => ({
          label: `${label} ${isDefault ? "(default address)" : ""}`,
          value: addressID,
          isDefault: !!isDefault,
        }));
      setAddressLabel(formattedAddress);
      const defaultAddress = addressList.filter((item) => item.isDefault);
      setSelectedAddress(defaultAddress[0]);
      // eslint-disable-next-line no-underscore-dangle
      setSelectedAddressLabel(defaultAddress[0]._id);
    }
  }, [addressList]);

  const sellerSetting = baseHelper.getResponseData(operatorSettingData, gql.GET_OPERATOR_SETTING);
  const sellerSettingError = baseHelper.getResponseError(operatorSettingData, gql.GET_OPERATOR_SETTING);
  const orderLineView = baseHelper.getResponseData(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
  const orderView = baseHelper.getResponseData(dataOrderView, gql.GET_ORDER_VIEW);
  const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
  const orderViewError = baseHelper.getResponseError(dataOrderView, gql.GET_ORDER_VIEW);

  useEffect(() => {
    const { order = {} } = sellerSetting || {};
    const { isHideBillingInfo = false } = order || {};

    if (isHideBillingInfo) {
      setIsHideBilling(isHideBillingInfo);
    }
    if (orderView) {
      setViewOrderData(orderView);
    }
    if (orderLineView) {
      const orderFulfillmentType = orderLineView.map((orderLine) => orderLine.fulfillmentType);
      const isLiveConnected = orderLineView.find((data) => data.liveConnect || data.vendorShopifyProductId);
      setIsLiveConnect(isLiveConnected);
      setViewLineOrderData(orderLineView);
      setFulfillmentType(orderFulfillmentType);
    }
    if (orderLineViewError || orderViewError || sellerSettingError) {
      setIsError(true);
    }
    if (
      orderView &&
      orderView.totalDiscount &&
      orderView.discountCodes[0] &&
      orderView.discountCodes[0].type === constant.FIXED_AMOUNT
    ) {
      setIsDynamicDiscount(true);
    } else {
      setIsDynamicDiscount(false);
    }
  }, [orderView, orderLineView, orderLineViewError, orderViewError, sellerSetting, sellerSettingError]);

  useEffect(() => {
    if (viewOrderData && viewLineOrderData) {
      // let isDisabled = true;
      let isConsignmentValueExist = true;
      // const { orderNumber = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      viewLineOrderData.map((lineItem) => {
        // if (lineItem.status === constant.ACCEPTED) {
        //   isDisabled = false;
        // }
        if (!lineItem.consignmentId) {
          isConsignmentValueExist = false;
        }
        return null;
      });
      setConsignmentValue(isConsignmentValueExist);
    }
  }, [viewOrderData, viewLineOrderData, history, match.params.id, setPageData]);

  useEffect(() => {
    setTerm("");
    if (viewOrderData && viewLineOrderData && viewLineOrderData.length && viewLineOrderData[0]) {
      const { orderNumber = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      const { name = "", shipping = {} } = viewOrderData || {};
      const { term: shippingTerm = "" } = shipping || {};

      const title = `#${orderNumber}`;
      setTerm(shippingTerm);

      setPageData({
        title: name === title ? title : `${name} - ${title}`,
        breadcrumbs: [{ url: "/" }],
        subtitle: baseHelper.formatDate(createdAt),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOrderData, viewLineOrderData]);

  useEffect(() => {
    if (errorOperatorSetting || errorOrderView || errorOrderLineView || errorOrderRefund) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorOperatorSetting || errorOrderLineView || errorOrderView || errorOrderRefund),
        isOpen: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOperatorSetting, errorOrderView, errorOrderLineView, errorHelper, errorOrderRefund]);

  useEffect(() => {
    if (addressData) {
      const addressDataResponse = baseHelper.getResponseData(addressData, constant.gql.GET_ADDRESS);
      const addressError = baseHelper.getResponseError(addressData, constant.gql.GET_ADDRESS);
      if (addressDataResponse) {
        setAddressList(addressDataResponse && addressDataResponse.addressList);
      }
      if (addressError) {
        setBanner({
          status: constant.CRITERIA,
          title: addressError,
          isOpen: true,
        });
      }
    }
    if (error) {
      setBanner({
        status: constant.CRITERIA,
        title: errorHelper.parse(error),
        isOpen: true,
      });
    }
  }, [addressData, error]);

  useEffect(() => {
    if (trackingData) {
      const resData = baseHelper.getResponseData(trackingData, "getFulfillmentTrackingDetailsSetting");
      const resError = baseHelper.getResponseError(trackingData, "getFulfillmentTrackingDetailsSetting");
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        return;
      }
      if (resData) {
        const { isEnabled } = resData || {};
        setIsEnabledData(isEnabled);
      }
    }
  }, [trackingData, setBanner, "getFulfillmentTrackingDetailsSetting"]);

  const getSlipName = (orderLineFulfillmentType) => {
    if (orderLineFulfillmentType === constant.SERVICE) {
      return cms("label.serviceSlip");
    }
    if (orderLineFulfillmentType === constant.COLLECT) {
      return cms("label.pickupSlip");
    }
    return cms("label.reserveSlip");
  };

  const handleToggle = (evt) => {
    const { id } = evt.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? cms("common.label.show") : cms("common.label.hide"),
      },
    }));
  };

  const [generateShippingLabel, { loading: shippingLabelLoading }] = useMutation(GENERATE_SHIPPING_LABEL);
  useEffect(() => {
    if (shippingLabelLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingLabelLoading]);

  const [bulkEditItem, { loading: bulkEditLoading }] = useMutation(BULK_EDIT);
  useEffect(() => {
    if (bulkEditLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [bulkEditLoading, cms]);

  const [bulkFulfillItem, { data: bulkFulfillData, loading: bulkFulfillLoading }] = useMutation(BULK_FULFILL);
  useEffect(() => {
    if (bulkFulfillLoading) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [bulkFulfillLoading, cms]);

  const [downloadLabel, { loading: downloadLabelLoading, data: downloadLabelData }] = useLazyQuery(DOWNLOAD_LABEL);
  const [getUploadData, { data: uploadData }] = useLazyQuery(GET_UPLOAD);

  const { dropship = {}, type = "" } = fulfillmentView || {};
  const { isThirdPartyShipping = false } = dropship || {};
  const linkData = [];

  const isPickReserve = type === constant.PICKUP;
  const isReserve = type === constant.RESERVE;
  const isCollect = type === constant.COLLECT;
  const isService = type === constant.SERVICE;

  // eslint-disable-next-line no-unused-vars
  const reserve = () => {
    const reserveValue = [];
    selectedItems.forEach((element) => {
      const items = viewLineOrderData.filter(
        (item) => item._id === element && item.status === constant.ACCEPTED && item.fulfillmentType === constant.RESERVE
      );
      reserveValue.push(...items);
      // reserveValue = [...reserveValue, items];
    });
    return reserveValue.length === selectedItems.length;
  };
  useEffect(() => {
    if (bulkFulfillData) {
      const updateOrderTracking = baseHelper.getResponseData(bulkFulfillData, gql.BULK_ORDER_FULFILL);
      const updateOrderTrackingError = baseHelper.getResponseError(bulkFulfillData, gql.BULK_ORDER_FULFILL);
      if (updateOrderTracking) {
        // setPageData({ title, breadcrumbs, subtitle });
        setActiveReserveCollect(false);
        setSelectedItemData([]);
        setSelectedCollectItems([]);
        setBulkQuantity([{ id: "", quantity: "", totalQuantity: "" }]);
        setMessage(cms("message.success.fulfill"));
        setTimeout(() => {
          refetchOrderView();
          refetchOrderLineView();
          refetchOrderFulfillment();
        }, 2000);
      }
      if (updateOrderTrackingError) {
        setActiveReserveCollect(false);
        setSelectedItemData([]);
        setSelectedCollectItems([]);
        setBulkQuantity([{ id: "", quantity: "", totalQuantity: "" }]);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkFulfillData]);
  useEffect(() => {
    if (uploadData) {
      const resData = baseHelper.getResponseData(uploadData, gql.GET_UPLOAD);
      if (resData) {
        const { data = [], dataType, type } = resData;
        data.map((pdfURL) => {
          const url = `data:application/${type};${dataType},${pdfURL}`;
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `vendor-${orderConsignmentId}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          setMessage(cms("message.success.downloadSuccess"));
          return null;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData, orderConsignmentId]);

  useEffect(() => {
    if (isDownload) {
      if (downloadLabelLoading) {
        setMessage(cms("message.success.requestProcess"));
      }
      if (!downloadLabelLoading && downloadLabelData) {
        const responseData = baseHelper.getResponseData(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL);
        let bannerData = {
          title: cms("message.success.downloadSuccess"),
          status: constant.SUCCESS,
          isOpen: false,
        };
        if (responseData) {
          setMessage(cms("message.success.downloadSuccess"));
        }
        if (!responseData) {
          bannerData = {
            title: baseHelper.getResponseError(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL) || errorHelper.parse(),
            status: constant.CRITICAL,
            isOpen: true,
          };
          setBanner(bannerData);
          return;
        }

        const {
          clientId = "",
          consignmentId = "",
          consignmentStatus = "",
          consignmentUrl = "",
          pdf = {},
          shippingOption = "",
          token = "",
          consignmentLabel = [],
        } = responseData || {};

        if (shippingOption === constant.SHIPPY_PRO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `label-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.UPS_SMALL || shippingOption === constant.SENDABOX) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${consignmentId}`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.NZPOST) {
          if (!consignmentStatus) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          if (consignmentStatus === constant.PROCESSING) {
            bannerData = {
              title: cms("message.error.labelUnderProcess"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }
          // TODO: needs to improvised latter as no other solution found;
          const shippingUrl = baseHelper.getNewZeaLandPostAPIUrl(consignmentId);
          fetch(shippingUrl, {
            headers: {
              Authorization: token,
              client_id: clientId,
            },
          })
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${consignmentId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.STARSHIPIT) {
          if (consignmentLabel && consignmentLabel.length) {
            consignmentLabel.map(async (pdfItem) => {
              fetch(pdfItem.pdfURL)
                .then((res) => {
                  if (res && !res.ok) {
                    return false;
                  }
                  return res.blob();
                })
                .then((result) => {
                  if (!result) {
                    bannerData = {
                      title: cms("message.error.unableToDownloadPdf"),
                      status: constant.CRITICAL,
                      isOpen: true,
                    };
                    setBanner(bannerData);
                    return;
                  }
                  const url = window.URL.createObjectURL(result);
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = `vendor-${consignmentId}`;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  setBanner(bannerData);
                });
            });
            setIsDownload(false);
          }
          if (!(consignmentLabel && consignmentLabel.length)) {
            try {
              setOrderConsignmentId(consignmentId);
              getUploadData({ variables: { input: { collectionId: match.params.id, userId: _id } } });
            } catch (exception) {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
            }
          }
        } else {
          // eslint-disable-next-line no-buffer-constructor
          const buffer = new Buffer(pdf);
          const newBlob = new Blob([buffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(newBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `vendor-${consignmentId}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          setBanner(bannerData);
          setIsDownload(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadLabelLoading, downloadLabelData, cms]);
  const handleBulkReject = async () => {
    const refundData = [];
    if (isBulk) {
      setMessage(cms("common.message.success.background"));
      setIsBulk(false);
    }
    selectedItems.forEach((orderId) => {
      viewLineOrderData.map((item) => {
        if (orderId === item._id) {
          const dataToAdd = {
            isAddToInventory: item.isAddToInventory || false,
            orderId: item.orderId,
            orderLineItemId: item._id,
            rejectQuantity: item.quantity,
            fulfillmentType: item.fulfillmentType || constant.DROPSHIP,
          };
          refundData.push(dataToAdd);
        }
        return null;
      });
    });

    const formData = {
      isRefund: false,
      isRestock: restock,
      refund: refundData,
    };
    if (!isBulk) {
      formData.isSingle = true;
    }

    setSelectedItems([]);
    await bulkReject({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_REJECT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_REJECT);
          if (resData) {
            setBanner({ isOpen: false, title: responseDataError, status: constant.CRITICAL });
            setMessage(cms("message.success.reject", { item: cms("message.success.manageTransaction") }));
            setRestock(false);
            setTimeout(() => {
              refetchOrderLineView();
              refetchOrderView();
              refetchOrderRefund();
            }, 5000);
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
        if (!rejectLoading) {
          setRejectModal(false);
        }
        setChecked(false);
        setRejectModal(false);
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleBulkAccept = async (key = true, ids) => {
    const formData = {
      lineItemIds: (key && selectedItems) || ids,
    };
    if (key) {
      setLineLoading(selectedItems);
      setMessage(cms("common.message.success.background"));
    }
    if (!key) {
      formData.isSingle = true;
    }
    setSelectedItems([]);
    await bulkAccept({ variables: { input: { ...formData } } })
      .then((res) => {
        setActive(false);
        if (res) {
          setLineLoading([]);
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_ACCEPT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_ACCEPT);
          if (resData) {
            setBanner({ isOpen: false, title: responseDataError, status: constant.CRITICAL });
            setMessage(cms("message.success.accept"));
            refetchOrderLineView();
            refetchOrderView();
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleChecked = useCallback((newChecked) => setChecked(newChecked), []);
  const handleRestockChecked = useCallback((newChecked) => setRestock(newChecked), []);

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Checkbox label={cms("label.checkbox")} checked={checked} onChange={handleChecked} />
      </Stack.Item>
      <Stack.Item>
        <Button
          onClick={(event) => {
            handleModalChange(event);
            handleChecked(!event);
            setRestock(false);
          }}
        >
          {cms("common.button.cancel")}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button primary onClick={handleBulkReject} disabled={!checked} loading={rejectLoading}>
          {cms("common.button.confirm")}
        </Button>
      </Stack.Item>
    </Stack>
  );

  const handelRejectModal = () => {
    return (
      <Modal open={rejectModal} onClose={handleModalChange} title={cms("modal.reject")} footer={actions()}>
        <Modal.Section>
          <Stack vertical>
            {isReviewOrder && (
              <Stack.Item>
                <Banner isOpen status="info">
                  <p>{cms("modal.section.note")}</p>
                </Banner>
              </Stack.Item>
            )}
            <Stack.Item>
              <p>{cms("modal.section.description")}</p>
            </Stack.Item>
            {isReviewOrder && (
              <Stack.Item>
                <Checkbox label={cms("modal.section.restock")} checked={restock} onChange={handleRestockChecked} />
              </Stack.Item>
            )}
          </Stack>
        </Modal.Section>
      </Modal>
    );
  };

  const renderHeading = (title, count, fulfilled = false) => {
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          {title === baseHelper.ucFirst(constant.REJECTED) && (
            <Icon source={CircleDisabledMajor} backdrop color={constant.REDDARK} />
          )}
          {title === baseHelper.ucFirst(constant.RETURN) && (
            <Icon source={CircleTickMajor} backdrop color={constant.REDDARK} />
          )}
          {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
            <Icon
              source={fulfilled ? CircleTickMajor : CircleDisabledMajor}
              backdrop
              color={fulfilled ? constant.GREENDARK : constant.YELLOWDARK}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
        {title === baseHelper.ucFirst(constant.REJECTED) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title === baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={fulfilled ? constant.SUCCESS : constant.ATTENTION}>{count}</Badge>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const handleSelect = (selectedId) => {
    let ids = selectedItems;
    const isExist = selectedItems.includes(selectedId);
    if (isExist) {
      ids = selectedItems.filter((item) => item !== selectedId);
    }

    if (!isExist) {
      ids.push(selectedId);
    }
    setSelectedItems(ids);
  };

  const isPaidStatus = (status, isAllFullfilled) => {
    return (
      status === constant.PAID ||
      (status === constant.REFUND && isAllFullfilled) ||
      status === constant.PARTIALLY_REFUND
    );
  };

  const renderPaymentSectionHeading = (status, isAllFullfilled) => {
    const isPaid =
      status === constant.PAID ||
      (status === constant.REFUND && isAllFullfilled) ||
      status === constant.PARTIALLY_REFUND;
    const isPartiallyPaid = status === constant.PARTIALLYPAID || (status === constant.REFUND && !isAllFullfilled);

    const title = (isPaid && cms("label.paid")) || (isPartiallyPaid && "Partially Paid") || "Payment Pending";

    const iconSource =
      (isPaid && CircleTickMajor) || (isPartiallyPaid && CircleInformationMajor) || CircleDisabledMajor;
    const iconColor = isPaid ? constant.GREENDARK : constant.YELLOWDARK;
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          <Icon source={iconSource} backdrop color={iconColor} />
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
      </Stack>
    );
  };
  const fulfilledLineItems = orderFulfillment.map((item) => item.lineItems).flat() || [];

  let fulfilledData = [];
  fulfilledLineItems.forEach((fulfilledLineItem) => {
    const getOrderItem =
      viewLineOrderData && viewLineOrderData.find((lineItem) => lineItem.shopifyLineItemId === fulfilledLineItem.id);
    const optionIndex = fulfilledData.findIndex((fulfillment) => fulfillment.id === fulfilledLineItem.id);
    if (optionIndex === -1) {
      fulfilledLineItem.totalQuantity = parseInt(fulfilledLineItem.quantity, 10);
      fulfilledLineItem.totalPrice = parseFloat(getOrderItem.price) * parseFloat(fulfilledLineItem.quantity);
      fulfilledLineItem.fulfillmentType = getOrderItem.fulfillmentType;
      fulfilledLineItem.isProductDeleted = getOrderItem.isProductDeleted;
      fulfilledData.push(fulfilledLineItem);
    } else {
      fulfilledData[optionIndex].totalQuantity += parseInt(fulfilledLineItem.quantity, 10);
      fulfilledData[optionIndex].totalPrice += parseFloat(getOrderItem.price) * parseFloat(fulfilledLineItem.quantity);
    }
  });

  fulfilledData = fulfilledData.filter((itemLine) => itemLine.vendor === brandName);
  const refundLineItems = orderRefund.map((item) => item.lineItems).flat() || [];

  let refundData = [];
  refundLineItems.forEach((refundItem) => {
    const { line_item: refundLineItem = {} } = refundItem || {};
    const getOrderItem =
      viewLineOrderData && viewLineOrderData.find((lineItem) => lineItem.shopifyLineItemId === refundLineItem.id);
    const optionIndex = refundData.findIndex((refund) => refund.line_item_id === refundItem.line_item_id);
    if (optionIndex === -1) {
      refundItem.totalQuantity = parseInt(refundItem.quantity, 10);
      refundItem.totalPrice = parseFloat(getOrderItem.price) * parseFloat(refundItem.quantity);
      refundData.push(refundItem);
    } else {
      refundData[optionIndex].totalQuantity += parseInt(refundItem.quantity, 10);
      refundData[optionIndex].totalPrice += parseFloat(getOrderItem.price) * parseFloat(refundItem.quantity);
    }
  });

  const isProductDeleted =
    viewLineOrderData && viewLineOrderData.filter((lineItem) => lineItem.isProductDeleted).length;

  const isRejectedByVendor =
    viewLineOrderData && viewLineOrderData.filter((lineItem) => lineItem.rejectedByVendor).length;
  if (isRejectedByVendor) {
    viewLineOrderData.forEach((lineItem) => {
      if (lineItem.status === constant.NEW && lineItem.rejectedByVendor) {
        const refundItem = {};
        const optionIndex = refundData.findIndex((refund) => refund.line_item_id === lineItem.shopifyLineItemId);
        if (optionIndex === -1) {
          refundItem.line_item_id = lineItem.shopifyLineItemId;
          refundItem.restock_type = constant.CANCEL_SMALL;
          refundItem.totalQuantity = parseInt(lineItem.quantity, 10);
          refundItem.totalPrice = parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
          refundItem.line_item = lineItem;
          refundData.push(refundItem);
        }
      }
    });
  }

  if (isProductDeleted) {
    viewLineOrderData.forEach((lineItem) => {
      if (lineItem.status === constant.REJECTED) {
        const refundItem = {};
        const optionIndex = refundData.findIndex((refund) => refund.line_item_id === lineItem.shopifyLineItemId);
        if (optionIndex === -1) {
          refundItem.line_item_id = lineItem.shopifyLineItemId;
          refundItem.restock_type = constant.CANCEL_SMALL;
          refundItem.totalQuantity = parseInt(lineItem.quantity, 10);
          refundItem.totalPrice = parseFloat(lineItem.price) * parseFloat(lineItem.quantity);
          refundItem.line_item = lineItem;
          refundData.push(refundItem);
        }
      }
    });
  }

  refundData = refundData.filter((itemLine) => itemLine);

  const renderItemData = (dataOrderViews, dataOrderLineViews) => {
    const orderLineItemsData = dataOrderLineViews;
    const isReserveAndCollect = orderLineItemsData.every(
      (data) => data.fulfillmentType === constant.RESERVE || data.fulfillmentType === constant.COLLECT
    );
    const isLineService = orderLineItemsData.every((data) => data.fulfillmentType === constant.SERVICE);

    const isAllFullfilled =
      orderLineItemsData.every((data) => data.fulfillableQuantity === 0) && (isReserveAndCollect || isLineService);

    const { financialStatus, status: orderStatus = "" } = dataOrderViews || {};
    if (!orderLineItemsData)
      return (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      );
    let unfulfillCount = constant.value.ZERO;
    let fulfillCount = constant.value.ZERO;
    let fulfilledCount = constant.value.ZERO;
    let consignmentShipCount = constant.value.ZERO;
    let rejectCount = constant.value.ZERO;
    let returnCount = constant.value.ZERO;
    let pickUpCount = constant.value.ZERO;
    let paymentCount = constant.value.ZERO;
    let collectCount = constant.value.ZERO;
    let pendingCount = constant.value.ZERO;
    if (orderLineItemsData) {
      unfulfillCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          !(lineItem.status === constant.REJECTED) && // || lineItem.status === constant.CLOSED
          !lineItem.collectDetail &&
          lineItem.fulfillableQuantity &&
          !(lineItem.rejectedByVendor && lineItem.status === constant.NEW)
      ).length;
      fulfillCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.FULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          ![constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(lineItem.fulfillmentType)
      ).length;

      pickUpCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.isVendorFulfilled &&
          lineItem.fulfillmentStatus !== constant.FULFILLED &&
          lineItem.fulfillmentStatus !== constant.REFUND &&
          lineItem.status !== constant.REJECTED &&
          (lineItem.isConsignmentPick || lineItem.fulfillmentType === constant.PICKUP)
      ).length;

      paymentCount = orderLineItemsData.filter((lineItem) =>
        lineItem && lineItem.isRefund
          ? !(
              (lineItem.status === constant.REJECTED ||
                (lineItem.status === constant.NEW && !lineItem.fulfillableQuantity)) &&
              lineItem.fulfillmentStatus === constant.FULFILLED &&
              lineItem.actualQuantity === 0
            )
          : !(
              ((lineItem.status === constant.REJECTED ||
                (lineItem.status === constant.NEW && lineItem.fulfillableQuantity === 0)) &&
                (lineItem.fulfillmentStatus === constant.UNFULFILLED ||
                  lineItem.fulfillmentStatus === constant.REFUND)) ||
              (lineItem.rejectedByVendor && lineItem.status === constant.NEW)
            )
      ).length;

      pendingCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(lineItem.fulfillmentType) &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (lineItem.isRefund
            ? lineItem.collectDetail.readyQuantity < (lineItem.quantity || 0)
            : lineItem.collectDetail.readyQuantity < (lineItem.actualQuantity || 0))
      ).length;
      collectCount = orderLineItemsData.filter(
        (lineItem) =>
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(lineItem.fulfillmentType) &&
          lineItem.status !== constant.REJECTED &&
          !lineItem.isProductDeleted &&
          lineItem.collectDetail &&
          (!lineItem.isRefund
            ? parseInt(lineItem.actualQuantity || lineItem.quantity, 10) -
                parseInt(lineItem.collectDetail.readyQuantity, 10) <
              parseInt(lineItem.fulfillableQuantity, 10)
            : !(
                parseInt(lineItem.quantity, 10) - parseInt(lineItem.fulfillableQuantity, 10) ===
                parseInt(lineItem.collectDetail.readyQuantity, 10)
              ))
      ).length;

      rejectCount = refundData && refundData.length;
      returnCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.fulfillmentStatus === constant.FULFILLED &&
          lineItem.returnedQuantity &&
          lineItem.returnedQuantity !== constant.value.ZERO
      ).length;
      fulfilledCount = fulfilledData && fulfilledData.length;

      consignmentShipCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.fulfillmentType === constant.CONSIGNMENT &&
          lineItem.isVendorFulfilled &&
          lineItem.fulfillmentStatus !== constant.FULFILLED &&
          !lineItem.isConsignmentPick
      ).length;
    }

    const createShippingLabel = async () => {
      const { params = {} } = match;
      const { id: orderId } = params;
      const response = await generateShippingLabel({ variables: { input: { orderId } } });
      const shippingLabelResponse = baseHelper.getResponseData(response.data, gql.GENERATE_SHIPPING_LABEL);
      const shippingLabelError = baseHelper.getResponseError(response.data, gql.GENERATE_SHIPPING_LABEL);
      if (shippingLabelResponse) {
        refetchOrderLineView();
      }

      if (shippingLabelError) {
        const bannerData = {
          isOpen: true,
          title: baseHelper.getResponseError(response.data, gql.GENERATE_SHIPPING_LABEL) || errorHelper.parse(),
          status: constant.CRITICAL,
        };
        setBanner(bannerData);
      }
    };

    const fulfillOption = () => {
      const items = [];
      selectedItems.forEach((element) => {
        const filterItem = viewLineOrderData.filter(
          (item) => item._id === element && item.status === constant.ACCEPTED
        );
        items.push(...filterItem);
      });
      if (items.length && items.filter((item) => item.fulfillmentType === constant.RESERVE).length === items.length) {
        return cms("popover.action.ready");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.COLLECT).length === items.length) {
        return cms("popover.action.readyCollect");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.SERVICE).length === items.length) {
        return cms("popover.action.readyService");
      }
      if (items.length && items.filter((item) => item.fulfillmentType === constant.PICKUP).length === items.length) {
        return cms("label.readyToCollect");
      }
      return cms("popover.action.fulfill");
    };

    const downloadShippingLabel = async () => {
      const { params = {} } = match;
      const { id: orderId } = params;
      downloadLabel({ variables: { input: { orderId, vendorId: _id } } });
      setIsDownload(true);
    };

    const toggleActive = () => setActive((activate) => !activate);

    const handleDisable = (option) => {
      let disabled = true;
      selectedItems.forEach((id) => {
        const data = orderLineItemsData.find((item) => item._id === id);
        if (option === constant.ACCEPT && data && data.status === constant.NEW) {
          disabled = false;
        }
        if (
          option === constant.FULFILLMENT &&
          data &&
          data.status === constant.ACCEPTED &&
          data.fulfillmentType !== constant.PICKUP
        ) {
          disabled = false;
        }
      });
      return disabled;
    };

    const getFulfilledHeading = (count) => {
      if (isReserveAndCollect) {
        return renderHeading(cms("label.paidAndCollected"), count, true);
      }
      if (isLineService) {
        return renderHeading(cms("label.paidAndAvailed"), count, true);
      }
      return renderHeading(baseHelper.ucFirst(constant.FULFILLED), count, true);
    };

    const activator = (
      <Button onClick={toggleActive} disclosure>
        {cms("label.manage")}
      </Button>
    );

    const openModel = (event, items) => {
      switch (event) {
        case constant.BULK_EDIT:
          setSelectedItems(items);
          setEventKey(event);
          setEditModal(true);
          break;
        case constant.FULFILLED:
          setSelectedItems(items);
          setEventKey(event);
          setFulfillModal(true);
          break;
        default:
          setFulfillModal(false);
          setEditModal(false);
          break;
      }
    };

    const actionButtons = [
      // {
      //   content: cms("label.packingSlip"),
      //   onClick: () => window.open(`/order-slip/${match.params.id}`),
      //   id: "packingSlip",
      // },
      {
        content: toggleStatus.unfulfilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.UNFULFILLEDLINEITEMS,
      },
    ];

    const fulfilledActionButton = [
      {
        content: toggleStatus.fulFilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.FULFILLEDLINEITEMS,
      },
    ];

    const { shippingOption = "" } = dropship || {};
    const isPaid = shippingOption === constant.SENDABOX ? isPaidStatus(financialStatus, isAllFullfilled) : true;

    if (fulfillmentType && fulfillmentType.includes(constant.DROPSHIP) && isThirdPartyShipping && !isConsignmentValue) {
      const shippingLabel = {
        action: () => createShippingLabel(),
        caption: cms("label.shippingLabel"),
        id: generateLabelId,
        label: cms("label.shippingLabel"),
        icon: TipsMajor,
        primary: true,
        disabled: !isPaid,
      };

      linkData.push(shippingLabel);
    }

    if (fulfillmentType && fulfillmentType.includes(constant.DROPSHIP) && isThirdPartyShipping && isConsignmentValue) {
      const shippingLabel = {
        action: () => downloadShippingLabel(),
        caption: cms("label.downloadShippingLabel"),
        id: downloadLabelId,
        label: cms("label.downloadShippingLabel"),
        icon: TipsMajor,
      };

      linkData.push(shippingLabel);
    }

    // function to fetch addresses list for corresponding vendor
    const openClickReserveModal = async (value, fulfillType, sellerId, isBulkFuilfill = false) => {
      if (
        [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(fulfillType) &&
        !(addressList && addressList.length)
      ) {
        setBanner({
          status: constant.CRITICAL,
          title: cms("message.error.address"),
          isOpen: true,
        });
        return;
      }
      setIsBulkMark(isBulkFuilfill);
      if (!isBulkFuilfill) {
        const updatedBulk = []; // bulkQuantity;
        const updatedItem = []; // selectedItemData;
        const isExist = updatedBulk.find((item) => item.id === value._id);
        const { readyQuantity = 0 } = value.collectDetail || {};

        if (isExist) {
          updatedBulk.filter((item) => item.id !== value._id);
          updatedItem.filter((item) => item._id !== value._id);
        }

        if (!isExist) {
          const { isRefund = false } = value || {};
          updatedBulk.push({
            id: value._id,
            quantity: !isRefund ? (value.actualQuantity || 0) - readyQuantity : (value.quantity || 0) - readyQuantity,
            totalQuantity: !isRefund
              ? ((value.actualQuantity || 0) - readyQuantity).toString()
              : ((value.quantity || 0) - readyQuantity).toString(),
          });
          updatedItem.push(value);
        }
        setBulkQuantity(updatedBulk);
        setSelectedItemData(updatedItem);
      }
      // setFulfillmentType(type);
      setSellerAddresId(sellerId);
      setAddressModal(true);
    };

    const handleQuantityChange = (id, value) => {
      const tempData = bulkQuantity.filter((item) => item.id !== id);
      const newQuantity = bulkQuantity.find((item) => item.id === id);

      if (newQuantity) {
        newQuantity.quantity = parseInt(value, 10);
        tempData.push(newQuantity);
      }
      setBulkQuantity(tempData);
    };

    const acceptOnlyValidInput = (value, preValue) => {
      return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
    };

    const fulfillReserveModal = (isConfirmCollect = false) => {
      const isClickAndReserve = fulfillmentType === constant.RESERVE;
      const isClickAndCollect = fulfillmentType === constant.COLLECT;
      const isTimeBasedService = fulfillmentType.includes(constant.SERVICE);

      return (
        <ResourceList
          items={[...new Set(selectedItemData)] || []}
          renderItem={(item) => {
            const lineImage =
              imageHelper.resize({ url: item.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

            const quantityData = bulkQuantity.find((quantityItem) => quantityItem.id === item._id) || {};
            const { actualQuantity = 0, collectDetail = {}, fulfillableQuantity = 0, quantity = 0 } = item;
            const { readyQuantity } = collectDetail;
            const partial = quantityData.totalQuantity - quantityData.quantity;

            return (
              <>
                <br />
                <Stack wrap={false} id={item._id}>
                  <Stack.Item>
                    <Thumbnail size="large" source={lineImage} alt={item.name} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="ellipsis">
                      <Stack.Item>
                        {(item.isProductDeleted && (item.name || "")) || (
                          <Link onClick={() => history.push(`/products/edit/${item.productId}`)}>
                            {item.name || ""}
                          </Link>
                        )}
                        {item.variantTitle && (
                          <Caption>
                            <TextStyle variation={constant.SUBDUED}>{item.variantTitle.toUpperCase()}</TextStyle>
                          </Caption>
                        )}
                      </Stack.Item>
                      {item.fulfillmentType && (
                        <Badge className="text-capitalize">{baseHelper.getBadgeStatus(item.fulfillmentType)}</Badge>
                      )}
                      {!isConfirmCollect && partial > 0 && (
                        <Badge status={constant.SUCCESS}>
                          {isTimeBasedService ? cms("label.partialService") : cms("label.partialReady")}
                        </Badge>
                      )}
                    </div>
                  </Stack.Item>
                  <FieldSetQty>
                    <Stack.Item>
                      <TextField
                        value={(quantityData.quantity && quantityData.quantity.toString()) || ""}
                        min={0}
                        max={(quantityData.totalQuantity && quantityData.totalQuantity.toString()) || ""}
                        onChange={(value) => [
                          value <= parseInt(quantityData.totalQuantity, 10) &&
                            handleQuantityChange(quantityData.id, acceptOnlyValidInput(value, quantityData.quantity)),
                          // validInput(value, quantityData.quantity)),
                        ]}
                        suffix={`${cms("label.of")} ${quantityData.totalQuantity}`}
                        disabled={isClickAndReserve || isClickAndCollect}
                      />
                    </Stack.Item>
                  </FieldSetQty>
                </Stack>
                <br />
                {isConfirmCollect && (
                  <>
                    <br />
                    <DisplayText size="large">{collectDetail.code}</DisplayText>
                  </>
                )}
              </>
            );
          }}
        />
      );
    };
    const quantityData = bulkQuantity.find((quantityItem) => quantityItem.id) || {};
    const confirmCollected = async () => {
      setMessage("");
      const { fulfillmentType = "", orderId = "", vendorId = "" } = selectedItemData[0];
      const data = {
        trackingNumber: "",
        trackingCompany: "",
        fulfillmentType,
        bulkQuantity: bulkQuantity
          .filter((item) => !(Number(item.quantity) === constant.value.ZERO || item.quantity === ""))
          .map((bulkQty) => ({
            id: bulkQty.id,
            quantity: bulkQty.quantity,
          })),
      };
      if (isSingleOperation) {
        data.isSingle = isSingleOperation;
      }
      bulkFulfillItem({
        variables: {
          input: { ...data },
        },
      })
        .then((res) => {
          const resData = baseHelper.getResponseData(res.data, gql.BULK_FULFILL);
          if (resData) {
            setTimeout(() => {
              refetchOrderLineView();
              refetchOrderView();
              refetchOrderRefund();
            }, 5000);
          }
        })
        .catch((exception) => {
          setBanner({ title: errorHelper.parse(exception), status: constant.CRITICAL, isOpen: true });
        });
      return null;
    };
    const renderTracking = () => {
      return (
        <>
          <Modal
            open={activeReserveCollect}
            onClose={() => {
              setActiveReserveCollect(false);
              setSelectedItemData([]);
              setSelectedCollectItems([]);
              setBulkQuantity([]);
            }}
            title={
              fulfillmentType && fulfillmentType.includes(constant.SERVICE)
                ? cms("label.confirmService")
                : cms("label.confirmCollection")
            }
            primaryAction={{
              content:
                fulfillmentType && fulfillmentType.includes(constant.SERVICE)
                  ? cms("label.paidAndAvailed")
                  : cms("label.paidAndCollected"),
              onAction: () => {
                confirmCollected();
              },
              loading: bulkFulfillLoading,
              disabled: !bulkQuantity.filter(
                (item) =>
                  !(
                    Number(item.quantity) === constant.value.ZERO ||
                    item.quantity === "" ||
                    !Number.isInteger(item.quantity)
                  )
              ).length,
            }}
            sectioned
          >
            {fulfillReserveModal(true)}
          </Modal>
        </>
      );
    };

    const renderCollection = () => {
      return (
        addressModal && (
          <>
            <CollectOrder
              addressModal={addressModal}
              sellerAddresId={sellerAddresId}
              selectedItemData={selectedItemData}
              addressList={addressLabel}
              setAddressModal={setAddressModal}
              onRefetchOrderLineView={refetchOrderLineView}
              setBanner={setBanner}
              setMessage={setMessage}
              bulkQuantity={bulkQuantity}
              isBulkMark={isBulkMark}
              handleQuantityChange={handleQuantityChange}
              cms={cms}
              history={history}
              fulfillModal={fulfillReserveModal}
              viewFulfillmentType={fulfillmentType}
              isReadyForCollect
            />
          </>
        )
      );
    };

    const handleChange = (orders, items) => {
      if (items.length !== unfulfillCount) {
        const arrSelected = [...items];
        orders.forEach((order) => {
          const { _id: id, fulfillmentStatus, status, collectDetail, fulfillableQuantity } = order;
          if (
            fulfillmentStatus !== constant.FULFILLED &&
            !(status === constant.REJECTED || status === constant.CLOSED) &&
            !collectDetail &&
            fulfillableQuantity &&
            !order.rejectedByVendor
          ) {
            const foundSelect = selectedItems.find((element) => element === id);
            if (!foundSelect) {
              arrSelected.push(order._id);
            }
          }
        });
        setSelectedItems(arrSelected);
      } else {
        setSelectedItems([]);
      }
      setActive(false);
    };

    const handleBulkReserve = (orders, refund) => {
      if (selectedCollectItems.length !== collectCount) {
        const arrSelected = [...selectedCollectItems];
        const collectOrder = [];
        const bulkQuantityData = [];
        orders
          .filter(
            (item) =>
              (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.PARTIAL) &&
              [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(item.fulfillmentType) &&
              item.collectDetail &&
              (!item.isRefund
                ? parseInt(item.actualQuantity || item.quantity, 10) - parseInt(item.collectDetail.readyQuantity, 10) <
                  parseInt(item.fulfillableQuantity, 10)
                : !(
                    parseInt(item.quantity, 10) - parseInt(item.fulfillableQuantity, 10) ===
                    parseInt(item.collectDetail.readyQuantity, 10)
                  ))
          )
          .forEach((order) => {
            const {
              _id: id,
              actualQuantity = 0,
              collectDetail = {},
              fulfillmentStatus,
              fulfillableQuantity = 0,
              isRefund,
              quantity = 0,
              shopifyLineItemId,
              status,
            } = order;
            if (fulfillmentStatus !== constant.FULFILLED && status === constant.ACCEPTED && collectDetail) {
              let { readyQuantity = 0 } = collectDetail || {};
              isRefund &&
                refund &&
                refund
                  .filter((item) => item.line_item_id === shopifyLineItemId)
                  .forEach((refundItem) => {
                    const { totalQuantity = 0 } = refundItem;
                    readyQuantity -= totalQuantity;
                  });

              const foundSelect = selectedCollectItems.find((element) => element === id);
              if (!foundSelect) {
                const newQuantity = parseInt(actualQuantity || quantity) - parseInt(fulfillableQuantity);
                const readyCollectQuantity = parseInt(readyQuantity) - parseInt(newQuantity);

                arrSelected.push(order._id);
                collectOrder.push(order);
                bulkQuantityData.push({
                  id: order._id,
                  quantity: parseInt(readyCollectQuantity, 10),
                  totalQuantity: readyCollectQuantity.toString(),
                });
              }
            }
          });
        setSelectedCollectItems(arrSelected);
        setSelectedItemData(collectOrder);
        setBulkQuantity(bulkQuantityData);
      } else {
        setSelectedCollectItems([]);
        setBulkQuantity([]);
        setSelectedItemData([]);
      }
      setActive(false);
    };

    const getSlipText = (fulfillType) => {
      if ([constant.RESERVE].includes(fulfillType)) {
        return <Stack.Item>{`${cms("label.reserveSlip")}`}</Stack.Item>;
      }
      if (fulfillType === constant.COLLECT) {
        return <Stack.Item>{`${cms("label.pickupSlip")}`}</Stack.Item>;
      }
      if (fulfillType === constant.SERVICE) {
        return <Stack.Item>{`${cms("label.serviceSlip")}`}</Stack.Item>;
      }
      return <Stack.Item>{`${cms("label.packingSlip")}`}</Stack.Item>;
    };

    const bulkModal = () => {
      return (
        <>
          {eventKey === constant.BULK_EDIT && (
            <EditModal
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              orderLineItemsData={orderLineItemsData}
              bulkEditItem={bulkEditItem}
              isDynamicDiscount={isDynamicDiscount}
              setBanner={setBanner}
              setMessage={setMessage}
              refetchOrderLineView={refetchOrderLineView}
              refetchOrderRefund={refetchOrderRefund}
              refetchOrderView={refetchOrderView}
              active={editModal}
              setActive={setEditModal}
              isBulkChecked={isBulkChecked}
              bulkEditLoading={bulkEditLoading}
            />
          )}
          {eventKey === constant.FULFILLED && (
            <FulfillModel
              active={fulfillModal}
              addressList={addressList}
              bulkFulfillItem={bulkFulfillItem}
              isPickReserve={isPickReserve}
              isReserve={isReserve}
              isCollect={isCollect}
              isService={isService}
              orderLineItemsData={orderLineItemsData}
              refetchOrderFulfillment={refetchOrderFulfillment}
              refetchOrderLineView={refetchOrderLineView}
              refetchOrderView={refetchOrderView}
              selectedItems={selectedItems}
              setActive={setFulfillModal}
              setBanner={setBanner}
              setMessage={setMessage}
              setSelectedItems={setSelectedItems}
              loading={bulkFulfillLoading}
              isEnabledData={isEnabledData}
            />
          )}
        </>
      );
    };

    // if (fulfillmentType.includes("dropship") && isThirdPartyShipping && !isConsignmentValue) {
    //   const shippingLabel = {
    //     content: cms("label.shippingLabel"),
    //     onClick: () => createShippingLabel(),
    //     id: generateLabelId,
    //   };

    //   if (unfulfillCount) {
    //     actionButtons.unshift(shippingLabel);
    //   }

    //   if (fulfillCount) {
    //     fulfilledActionButton.unshift(shippingLabel);
    //   }
    // }

    // if (fulfillmentType.includes("dropship") && isThirdPartyShipping && isConsignmentValue) {
    //   const shippingLabel = {
    //     content: cms("label.downloadShippingLevel"),
    //     onClick: () => downloadShippingLabel(),
    //     id: downloadLabelId,
    //   };
    //   if (unfulfillCount) {
    //     actionButtons.unshift(shippingLabel);
    //   }

    //   if (fulfillCount) {
    //     fulfilledActionButton.unshift(shippingLabel);
    //   }
    // }

    return (
      <>
        {(unfulfillCount && (
          <Card title={renderHeading(baseHelper.ucFirst(constant.UNFULFILLED), unfulfillCount)} actions={actionButtons}>
            <br />
            <Collapsible id={constant.UNFULFILLEDLINEITEMS} open={toggleStatus.unfulfilledLineItems.active}>
              {isBulkChecked && selectedItems.length ? (
                <>
                  <Card.Section>
                    <Stack wrap={false}>
                      <ButtonGroup segmented>
                        <StyledButton>
                          <Button>
                            <Checkbox
                              label={isBulkChecked && `${selectedItems.length} ${cms("label.select")}`}
                              checked={
                                (isBulkChecked &&
                                  (selectedItems.length === unfulfillCount
                                    ? true
                                    : cms("label.indeterminate").toLowerCase())) ||
                                ""
                              }
                              onChange={() => [
                                handleChange(orderLineItemsData, selectedItems),
                                setIsBulkChecked(true),
                                setIsBulkCheckedFulfilled(true),
                              ]}
                            />
                          </Button>
                        </StyledButton>
                        <Stack.Item>
                          <Popover active={active} fullHeight fullWidth activator={activator} onClose={toggleActive}>
                            <ActionList
                              items={[
                                {
                                  content: cms("popover.action.accept"),
                                  onAction: () => handleBulkAccept(),
                                  icon: CircleTickOutlineMinor,
                                  disabled: handleDisable(constant.ACCEPT),
                                },
                                {
                                  content: cms("popover.action.edit"),
                                  onAction: () => [
                                    openModel(constant.BULK_EDIT, selectedItems),
                                    setIsBulkChecked(true),
                                  ],
                                  icon: EditMinor,
                                  disabled: isCapmaignOrder,
                                },
                                {
                                  content: fulfillOption(),
                                  onAction: () => openModel(constant.FULFILLED, selectedItems),
                                  icon: QuickSaleMajor,
                                  disabled: handleDisable(constant.FULFILLMENT) || isCapmaignOrder,
                                },
                                {
                                  content: cms("popover.action.reject"),
                                  onAction: () => [setRejectModal(true), setIsBulk(true)],
                                  icon: CircleCancelMinor,
                                  destructive: true,
                                },
                              ]}
                            />
                          </Popover>
                        </Stack.Item>
                      </ButtonGroup>
                    </Stack>
                  </Card.Section>
                </>
              ) : (
                <Card.Section>
                  <Stack>
                    <Stack.Item>
                      <Checkbox
                        label={`Showing ${unfulfillCount} items`}
                        checked={isBulkChecked && selectedItems.length === unfulfillCount}
                        onChange={() => [handleChange(orderLineItemsData, selectedItems), setIsBulkChecked(true)]}
                        disabled={orderLineItemsData.every((item) => item && item.rejectedByVendor)}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              )}
              {bulkModal()}
              {handelRejectModal()}
              <UnfulfilledLineItems
                handleBulkReject={handleBulkReject}
                setRejectModal={setRejectModal}
                handleBulkAccept={handleBulkAccept}
                itemData={orderLineItemsData}
                onRefetchOrderLineView={refetchOrderLineView}
                onRefetchOrderView={refetchOrderView}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                bulkFulfillData={bulkFulfillData}
                openModel={openModel}
                bulkFulfillItem={bulkFulfillItem}
                setBanner={setBanner}
                setMessage={setMessage}
                fulfillment={fulfillmentView}
                data={dataOrderViews}
                setConsignmentValue={setConsignmentValue}
                addressList={addressList}
                actionData={actionData}
                linkData={linkData}
                setActive={setActive}
                setActionData={setActionData}
                isBulkChecked={isBulkChecked}
                setIsBulkChecked={setIsBulkChecked}
                isBulkCheckedFulfill={isBulkCheckedFulfill}
                setIsBulkCheckedFulfilled={setIsBulkCheckedFulfilled}
                lineLoading={lineLoading}
                setLineLoading={setLineLoading}
                loading={acceptLoading || bulkFulfillLoading}
                refetchOrderFulfillment={refetchOrderFulfillment}
                packingSlipAccessData={packingSlipAccessData}
                getSlipText={getSlipText}
                setIsCapmaignOrder={setIsCapmaignOrder}
                isEnabledData={isEnabledData}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(collectCount && (
          <Card
            title={renderHeading(
              fulfillmentType && fulfillmentType.includes(constant.SERVICE)
                ? cms("label.readyService")
                : cms("label.readyForCollection"),
              collectCount,
              true
            )}
            actions={[
              {
                content: toggleStatus.readyForCollect.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "readyForCollect",
              },
            ]}
          >
            <br />
            <Collapsible id={constant.RESERVE} open={toggleStatus.readyForCollect.active}>
              {isBulkReserveChecked && selectedCollectItems.length ? (
                <>
                  <Card.Section>
                    <Stack wrap={false}>
                      <ButtonGroup segmented>
                        <Button size="slim">
                          <StyledButton>
                            <Checkbox
                              label={isBulkReserveChecked && `${selectedCollectItems.length} ${cms("label.select")}`}
                              checked={
                                (isBulkReserveChecked &&
                                  (selectedCollectItems.length === collectCount
                                    ? true
                                    : cms("label.indeterminate").toLowerCase())) ||
                                ""
                              }
                              onChange={() => [handleBulkReserve(orderLineItemsData), setIsBulkReserveChecked(true)]}
                            />
                          </StyledButton>
                        </Button>
                        <Stack.Item>
                          <Popover active={active} fullHeight activator={activator} onClose={toggleActive}>
                            <ActionList
                              items={[
                                {
                                  content:
                                    fulfillmentType && fulfillmentType.includes(constant.SERVICE)
                                      ? cms("button.confirmService")
                                      : cms("button.confirm"),
                                  onAction: () => setActiveReserveCollect(true),
                                  icon: ReturnMinor,
                                  // disabled: handleDisable(constant.ACCEPT),
                                },
                              ]}
                            />
                          </Popover>
                        </Stack.Item>
                      </ButtonGroup>
                    </Stack>
                  </Card.Section>
                </>
              ) : (
                <Card.Section>
                  <Stack>
                    <Stack.Item>
                      <Checkbox
                        label={`Showing ${collectCount} items`}
                        checked={isBulkReserveChecked && selectedCollectItems.length === collectCount}
                        onChange={() => [
                          handleBulkReserve(orderLineItemsData, refundData),
                          setIsBulkReserveChecked(true),
                        ]}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              )}
              {renderTracking()}
              <ReadyForCollect
                collectCount={collectCount}
                checked={isBulkReserveChecked && selectedCollectItems.length === collectCount}
                itemData={orderLineItemsData}
                openClickReserveModal={openClickReserveModal}
                refundData={refundData}
                selectedItems={selectedCollectItems}
                setSelectedItems={setSelectedCollectItems}
                handleSelect={handleSelect}
                setSelectedReserveItemData={setSelectedItemData}
                selectedReserveItemData={selectedItemData}
                setIsBulkReserveChecked={setIsBulkReserveChecked}
                isBulkReserveChecked={isBulkReserveChecked}
                setBulkQuantity={setBulkQuantity}
                bulkQuantity={bulkQuantity}
                activeReserveCollect={activeReserveCollect}
                setActiveReserveCollect={setActiveReserveCollect}
                onChange={() => [handleBulkReserve(orderLineItemsData), setIsBulkReserveChecked(true)]}
                getSlipName={getSlipName}
                setIsSingleOperation={setIsSingleOperation}
                packingSlipAccessData={packingSlipAccessData}
                // refundLineData={refundData}
                // data={dataOrderViews}
                // lineItemArray={viewLineOrderData}
                // setRejectAmount={setRejectAmount}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {renderCollection()}
        {(pendingCount && (
          <Card
            title={renderHeading(
              fulfillmentType && fulfillmentType.includes(constant.SERVICE)
                ? cms("label.pendingService")
                : cms("label.pendingCollection"),
              pendingCount,
              true
            )}
            actions={[
              {
                content: toggleStatus.pendingForCollect.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "pendingForCollect",
              },
            ]}
          >
            <br />
            <Collapsible id="pendingForCollect" open={toggleStatus.pendingForCollect.active}>
              <PendingForCollect
                collectCount={pendingCount}
                checked={isBulkReserveChecked && selectedCollectItems.length === pendingCount}
                itemData={orderLineItemsData}
                refundData={refundData}
                openClickReserveModal={openClickReserveModal}
                selectedItems={selectedCollectItems}
                setSelectedItems={setSelectedCollectItems}
                handleSelect={handleSelect}
                setSelectedReserveItemData={setSelectedItemData}
                selectedReserveItemData={selectedItemData}
                setIsBulkReserveChecked={setIsBulkReserveChecked}
                isBulkReserveChecked={isBulkReserveChecked}
                setBulkQuantity={setBulkQuantity}
                bulkQuantity={bulkQuantity}
                activeReserveCollect={activeReserveCollect}
                setActiveReserveCollect={setActiveReserveCollect}
                onChange={() => [handleBulkReserve(orderLineItemsData), setIsBulkReserveChecked(true)]}
                getSlipName={getSlipName}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(orderFulfillment && orderFulfillment.length && fulfilledCount && (
          <Card title={getFulfilledHeading(fulfilledCount)} actions={fulfilledActionButton}>
            <br />
            <Collapsible id={constant.FULFILLEDLINEITEMS} open={toggleStatus.fulFilledLineItems.active}>
              <FulfilledLineItems
                fulfilledData={fulfilledData}
                data={dataOrderViews}
                lineItemArray={viewLineOrderData}
                setFulfillAmount={setFulfillAmount}
                getSlipText={getSlipText}
                packingSlipAccessData={packingSlipAccessData}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLMENTS), fulfillCount, true)}
            actions={[
              {
                content: toggleStatus.fulfillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULFILLMENTS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLMENTS} open={toggleStatus.fulfillments.active}>
              <Fulfillments
                lineData={orderFulfillment}
                fulfillmentsCount={fulfillCount}
                lineCount={orderLineItemsData}
                setBanner={setBanner}
                setMessage={setMessage}
                refetchOrderFulfillment={refetchOrderFulfillment}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(pickUpCount && (
          <Card
            title={renderHeading(cms("label.readyToCollect"), pickUpCount, true)}
            actions={[
              {
                content: toggleStatus.pickup.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.pickup"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.pickup")} open={toggleStatus.pickup.active}>
              <Pickup lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(consignmentShipCount && (
          <Card
            title={renderHeading(cms("label.consignmentShip"), consignmentShipCount, true)}
            actions={[
              {
                content: toggleStatus.vendorFulfillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.vendorFulfillments"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.vendorFulfillments")} open={toggleStatus.vendorFulfillments.active}>
              <VendorFulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(((orderRefund && orderRefund.length) || rejectCount) && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.REJECTED), rejectCount)}
            actions={[
              {
                content: toggleStatus.rejected.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.REJECTED,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.REJECTED} open={toggleStatus.rejected.active}>
              <RejectedLineItems
                refundLineData={refundData}
                data={dataOrderViews}
                lineItemArray={viewLineOrderData}
                setRejectAmount={setRejectAmount}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(returnCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.RETURN), returnCount, true)}
            actions={[
              {
                content: toggleStatus.return.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.RETURN,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.RETURN} open={toggleStatus.return.active}>
              <ReturnedLineItems data={dataOrderViews} lineItemArray={viewLineOrderData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(paymentCount && (
          <Card
            title={renderPaymentSectionHeading(financialStatus, isAllFullfilled)}
            actions={[
              {
                content: toggleStatus.fullFillmentStatus.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULLFILLMENTSTATUS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULLFILLMENTSTATUS} open={toggleStatus.fullFillmentStatus.active}>
              <FulfillmentStatus
                lineData={orderLineItemsData}
                data={dataOrderViews}
                fulfilledData={fulfilledData}
                term={term}
                totalRejectAmount={totalRejectAmount}
                totalFulfilledAmount={totalFulfilledAmount}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
      </>
    );
  };
  let isNote = true;
  const getSecondaryComponent = (dataOrderViews) => {
    const { note } = dataOrderViews || {};
    if (!note) {
      isNote = false;
    }
    return (
      <>
        {dataOrderViews && (
          <>
            {linkData && linkData.length >= 1 && <QuickAction link={linkData} />}
            <Notes
              dataItem={dataOrderViews}
              isEnable={orderLiveConnectSetting.isOrderNote}
              isLiveConnect={isLiveConnect}
              note={(isNote && note) || ""}
              onRefetchOrderView={refetchOrderView}
              setBanner={setBanner}
              setMessage={setMessage}
            />
            <Customer
              currentUser={currentUser}
              customerData={customerView}
              data={dataOrderViews}
              fulfillment={fulfillmentView}
              getSellerAddress={getSellerAddress}
              isHideBillingInfo={isHideBilling}
              isLiveConnect={isLiveConnect}
              orderLiveConnectSetting={orderLiveConnectSetting}
              sellerAddressData={sellerAddressData}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      {(loadingOrderView ||
        loadingOrderLineView ||
        addressLoading ||
        sellerAddressLoading ||
        loadingOperatorSetting ||
        loadingOrderRefund) && (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      )}
      <OrderProvider
        value={{
          addressId,
          setAddressId,
          fulfillmentType,
          setFulfillmentType,
        }}
      >
        <Layout.Section>
          {isError && (
            <Layout.Section>
              <div style={{ display: "flex" }}>
                <NotFound />
              </div>
            </Layout.Section>
          )}
          <div className="toast">
            <Frame>
              <Toast message={message} timeout={5000} />
            </Frame>
          </div>
          {viewOrderData && viewLineOrderData && renderItemData(viewOrderData, viewLineOrderData)}
        </Layout.Section>
        {!isError && <Layout.Section secondary>{getSecondaryComponent(viewOrderData)}</Layout.Section>}
      </OrderProvider>
    </>
  );
};
export default withFeature(withErrorBoundary(ProviderOrderView), { feature: constant.VIEW_ORDER });
