import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { ChoiceList, Tabs, Tag, Card, Layout } from "@shopify/polaris";
import { AddProductMajor } from "@shopify/polaris-icons";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import { ResourceList, Banner } from "lib/components";

// layout component
import { SingleColumnLayout } from "layout/private/components";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import productListCMS from "app/productOld/modules/generic/feature/list/cms/productListCMS";

// import report
import { QuickAction, QuickReport } from "app/reports";

// GQL
import { GET_PRODUCT_LIST_PROVIDER } from "app/productOld/apollo/queries";
import { BULK_PRODUCT } from "app/productOld/apollo/mutations";

// Generic Component.
import { RejectProduct, DeleteProduct, ProductListView } from "app/productOld/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/productOld/modules/generic/moreFilters/moreFilter";
import { SUBSCRIPTION_BY_PROVIDER } from "app/productOld/apollo/subscriptions/productList";

const AdminProductList = () => {
  const { history, cms } = useContext(PrivateContext);
  const { filterStatus, tabs, sortOptions, resourceName } = productListCMS(cms);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const paramFilter = (prevAppliedFilter.filter && prevAppliedFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [queryValue, setQueryValue] = useState(searchVal || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const selectedSort = queryParams.sort_name && `${queryParams.sort_name}_${queryParams.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 10);
  const [singleId, setSingleId] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [primaryActionTitle, setPrimaryActionTitle] = useState(null);
  const [secondaryActionsTitle, setSecondaryActionsTitle] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productListData, setProductListData] = useState(false);
  const [productListTotal, setProductListTotal] = useState(0);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || "");
  const [listLoading, setListLoading] = useState(false);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const [deleteSelected, setDeleteSelected] = useState(constant.DELETE_SHOPIFY);
  const [selectedTab, setSelectedTab] = useState("all");
  const [popoverDisable, setPopoverDisable] = useState(false);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });
  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
  }
  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LIST_PROVIDER, {
    variables: {
      input: {
        search: getQueryParams.search,
        sort_name: getQueryParams.sort_name || constant.UPDATE,
        sort_order: getQueryParams.sort_order || constant.DESC,
        page: parseInt(getQueryParams.page || page, 10),
        perPage: parseInt(getQueryParams.perPage || perPage, 10),
        filter: filterByStatus || selectedTab,
        list_filter: getQueryParams.list_filter,
        list_search: getQueryParams.list_search,
      },
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST_PROVIDER,
        variables: {
          input: {
            search: getQueryParams.search,
            sort_name: getQueryParams.sort_name,
            sort_order: getQueryParams.sort_order,
            page: parseInt(getQueryParams.page || page, 10),
            perPage: parseInt(getQueryParams.perPage || perPage, 10),
            filter: getQueryParams.filter,
            list_filter: getQueryParams.list_filter,
            list_search: getQueryParams.list_search,
          },
        },
      },
    ],
  });

  useEffect(() => {
    const getProducts = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const getProductsError = baseHelper.getResponseError(dataProductList, constant.gql.GET_PRODUCT_LIST);
    if (getProducts) {
      setProductListData(getProducts.productList);
      setProductListTotal(getProducts.count);
    }
    if (getProductsError) {
      setListLoading(false);
      setBanner({ title: getProductsError, status: constant.CRITICAL, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setBanner({ title: errorHelper.parse(errorProductList), status: constant.CRITICAL, isOpen: true });
    }
  }, [errorProductList]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_BY_PROVIDER,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleQueryValueRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const handleSort = (selectedValue) => {
    const sort = selectedValue.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };

  const handleRejectModal = (eventKey, id = false) => {
    setSingleId(null);
    if (
      (eventKey === constant.REJECT ||
        eventKey === constant.UNREJECT ||
        eventKey === constant.UNDELETE ||
        eventKey === constant.RE_SUBMIT) &&
      id
    ) {
      setSingleId(id);
    }
    switch (eventKey) {
      case constant.RE_SUBMIT:
        setModalTitle(cms("label.resubmitProduct"));
        setPrimaryActionTitle(cms("label.resubmit"));
        setSecondaryActionsTitle(cms("label.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      default:
        break;
    }
  };

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      setListLoading(`${eventKey}_${id}`);
    }
    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setOpenRejectModal(false);
        setSelectedItems([]);
        setDeleteSelected([constant.DELETE_SHOPIFY]);
        setKeyName(null);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (responseData) {
            setBanner({ isOpen: true, title: cms("common.message.success.background"), status: constant.SUCCESS });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        listLoading={listLoading}
        item={item}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        handleRejectModal={handleRejectModal}
        handleDeleteModal={handleDeleteModal}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setBanner}
        active={active}
        setActive={setActive}
        popoverDisable={popoverDisable}
        cms={cms}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: cms("label.action"),
    },
  ];
  const bulkActions = [
    {
      content: cms("label.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
    {
      content: cms("label.edit"),
      onAction: () => bulkAction(constant.BULK_EDIT),
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
  ];

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setQueryParams(history.location, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setQueryParams(history.location, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setQueryParams(history.location, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }
    const search = baseHelper.setQueryParams(history.location, clearParams);
    history.push(`${history.location.pathname}?${search}`);
    return null;
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {option === constant.PENDING_SMALL ? cms("label.review") : baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value === constant.TITLE ? "titleName" : value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: "taggedWith",
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: "selectedFilter",
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilters.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    handleSelectedFilterRemove();
  };

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const filters = [
    {
      key: "selectedFilter",
      label: cms("label.filterBy"),
      filter: (
        <Filter
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }
  const linkData = [
    {
      action: () => history.push("/products/add"),
      caption: cms("label.addCaption"),
      id: "addProduct",
      label: cms("label.add"),
      icon: AddProductMajor,
    },
  ];

  // const getSecondaryComponent = () => {
  //   return (
  //     <>
  //       <QuickAction link={linkData} />
  //       <QuickReport />
  //     </>
  //   );
  // };

  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              action={banner.action}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
            <br />
          </>
        )}
        <QuickReport />
        <QuickAction link={linkData} />
        <Layout>
          <DeleteProduct
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            cms={cms}
            selectedItems={selectedItems}
            deleteSelected={deleteSelected}
          />
          <RejectProduct
            openRejectModal={openRejectModal}
            setOpenRejectModal={setOpenRejectModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            modalTitle={modalTitle}
            primaryActionTitle={primaryActionTitle}
            secondaryActionsTitle={secondaryActionsTitle}
            keyName={keyName}
            cms={cms}
          />
          <Layout.Section>
            <Card>
              <ResourceList
                resourceName={resourceName}
                items={(productListData && productListData) || []}
                idForItem={(products) => {
                  const { _id: id } = products;
                  return id;
                }}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={(selectValue) => setSelectedItems(selectValue)}
                bulkActions={bulkActions}
                promotedBulkActions={promotedBulkActions}
                loading={loadingProductList}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={handleSort}
                onQueryChange={handleSearchValue}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                filters={filters}
                appliedFilters={appliedFilters}
                queryValue={queryValue}
                count={productListTotal}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                setPopoverDisable={setPopoverDisable}
                renderItem={renderItem}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(AdminProductList), { feature: constant.PRODUCTS });
