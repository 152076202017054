import { gql } from "apollo-boost";

const ORDER_LIST = gql`
  subscription {
    orderLineView {
      status
      error
      data {
        operationType
        orderLine {
          _id
          createdAt
          fulfillmentStatus
          image
          isConsignmentPick
          isReturn
          isShippingPaid
          isVendorFulfilled
          orderId
          orderNumber
          shop
          shopifyLineItemId
          shopifyOrderDate
          shopifyOrderId
          status
          title
          vendor
          vendorId
          return {
            isSellerManaged
            reason
            requestedAt
            sellerNote
            status
            updatedAt
            vendorNote
          }
        }
      }
    }
  }
`;
export default ORDER_LIST;
