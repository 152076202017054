import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  streetAddressSchema,
  brandNameSchema,
  brandHandleSchema,
  provinceCodeSchema,
} from "./schema";

const {
  BRAND_NAME,
  BRAND_SLUG,
  CITY,
  COUNTRY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  POSTAL_CODE,
  PROVINCE_CODE,
  STREET_ADDRESS,
} = constant;

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (cms, field, value, schema, label) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(label, cms), { firstName: value });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(label, cms), { lastName: value });
      break;
    case EMAIL:
      promise = validateValue(emailSchema(label, cms), { email: value });
      break;
    case PHONE_NUMBER:
      promise = validateValue(phoneNumberSchema(label, cms), { phoneNumber: value });
      break;
    case CITY:
      promise = validateValue(citySchema(label, cms), { city: value });
      break;
    case COUNTRY:
      promise = validateValue(countrySchema, { country: value });
      break;
    case POSTAL_CODE:
      promise = validateValue(postalCodeSchema(label, cms, schema), { postalCode: value });
      break;
    case PROVINCE_CODE:
      promise = validateValue(provinceCodeSchema, { provinceCode: value });
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(label, cms), { streetAddress: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(label, cms), { brandName: value });
      break;
    case BRAND_SLUG:
      promise = validateValue(brandHandleSchema(label, cms), { brandHandle: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;
