const listData = (cms) => {
  return {
    filterData: {
      label: cms("section.orderList.label.filterVendors"),
      filters: [
        {
          key: "vendor",
          label: cms("section.orderList.label.brandName"),
          operatorText: "like",
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/orders/view/",
      id: "vendorId",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    productLink: {
      link: "/products/edit/",
      id: "productId",
    },
    sortOptions: [
      { label: cms("section.orderList.label.sortByDateAsc"), value: "createdAt_asc" },
      { label: cms("section.orderList.label.sortByDateDesc"), value: "createdAt_desc" },
      { label: cms("section.orderList.label.sortByOrderIdAsc"), value: "orderNumber_asc" },
      { label: cms("section.orderList.label.sortByOrderIdDesc"), value: "orderNumber_desc" },
    ],
    resourceName: {
      singular: "Order",
      plural: "Orders",
    },
    // tabsData: [
    //   {
    //     id: "all-orders",
    //     content: cms("section.orderList.label.all"),
    //     accessibilityLabel: "All orders",
    //     panelID: "all-orders-content",
    //     value: "all",
    //   },
    //   {
    //     id: "pending-acceptance",
    //     content: cms("section.orderList.label.pendingAcceptance"),
    //     panelID: "pending-acceptance-content",
    //     value: "pending",
    //   },
    //   {
    //     id: "pending-shipment",
    //     content: cms("section.orderList.label.pendingShipment"),
    //     panelID: "pending-shipment-content",
    //     value: "accepted",
    //   },
    //   {
    //     id: "rejected",
    //     content: cms("section.orderList.label.rejected"),
    //     panelID: "rejected-content",
    //     value: "rejected",
    //   },
    //   {
    //     id: "shipped",
    //     content: cms("section.orderList.label.shipped"),
    //     panelID: "shipped-content",
    //     value: "shipped",
    //   },
    //   {
    //     id: "archived",
    //     content: cms("section.orderList.label.archived"),
    //     panelID: "archived-content",
    //     value: "closed",
    //   },
    // ],
    filterByStatusOption: [
      {
        label: cms("section.orderList.label.all"),
        value: "all",
      },
      {
        label: cms("section.orderList.label.new"),
        value: "new",
      },
      {
        label: cms("section.orderList.label.accepted"),
        value: "accepted",
      },
      // {
      //   label: cms("section.orderList.label.partial"),
      //   value: "partial",
      // },
      {
        label: cms("section.orderList.label.rejected"),
        value: "rejected",
      },
      {
        label: cms("section.orderList.label.shipped"),
        value: "shipped",
      },
      {
        label: cms("section.orderList.label.archived"),
        value: "closed",
      },
    ],
    badge: ["status", "fulfillmentStatus"],
    // media: 'image',
    tableData: {
      tbody: [],
    },
  };
};
export default listData;
