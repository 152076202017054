import { gql } from "apollo-boost";

const GET_CURRENCY_RATE = gql`
  query CURRENCY_RATE($input: GetCurrencyRateSettingInput) {
    getCurrencyRateSetting(input: $input) {
      error
      status
      data {
        currency {
          isAllProduct
          isAllPayment
          rate {
            currencyCode
            currencySymbol
            price
            isProduct
            isPayment
          }
        }
      }
    }
  }
`;

export default GET_CURRENCY_RATE;
