import React from "react";
import { Card, SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris";

const OnBoardingSkeleton = () => {
  return (
    <>
      <Card sectioned>
        <SkeletonDisplayText />
        <br />
        <SkeletonBodyText lines={9} />
      </Card>
    </>
  );
};

export default OnBoardingSkeleton;
