import { gql } from "apollo-boost";

const REVIEW_VENDOR_ACCESS = gql`
  mutation reviewVendorAccess($input: ReviewVendorAccess) {
    reviewVendorAccess(input: $input) {
      data
      status
      error
    }
  }
`;
export default REVIEW_VENDOR_ACCESS;
