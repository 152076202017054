import React, { useContext, useState, useCallback, useEffect } from "react";
import { Tag, Stack, Autocomplete } from "@shopify/polaris";
import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context";

// import prop type
import { tagProp } from "app/product/modules/generic/propTypes";

const Tags = (props) => {
  const { handleChange, data, helpText, productTag, label, placeholder } = props;
  const { cms } = useContext(PrivateContext);
  const [selected, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const isKeyValuePair = !!(productTag && productTag.tags && productTag.tags.length && productTag.tags.value);
  let sortedOptions =
    (productTag && productTag.tags && baseHelper.sort(productTag.tags, isKeyValuePair && "label")) || [];
  sortedOptions = isKeyValuePair ? sortedOptions : sortedOptions.map((item) => ({ label: item, value: item }));

  const [optionsValue, setOptionsValue] = useState(sortedOptions || []);

  const onSelect = (selectedTags) => {
    setSelected(selectedTags);
    handleChange(constant.TAGS, selectedTags);
  };

  useEffect(() => {
    if (data && data.tags && data.tags.length) {
      setSelected(data.tags);
    }
  }, [data]);

  const filterAndUpdateOptions = (inputString) => {
    if (!inputString) {
      setInputValue(inputString);
      setOptionsValue(sortedOptions);
      return;
    }
    const filterRegex = new RegExp(inputString, "i");
    const resultOptions = sortedOptions.filter((option) => option.label.match(filterRegex));
    setInputValue(inputString);
    setOptionsValue(resultOptions);
  };

  const textField = (
    <Autocomplete.TextField
      onChange={filterAndUpdateOptions}
      label={label || cms("label.tags")}
      helpText={helpText || cms("subtext.tags")}
      value={inputValue}
      placeholder={placeholder || cms("placeholder.tag")}
    />
  );
  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selected];
      options.splice(options.indexOf(tag), 1);
      handleChange(constant.TAGS, options);
      setSelected(options);
    },
    [selected, handleChange]
  );
  const tagsMarkup =
    (selected.length &&
      selected.map((option) => {
        let tagLabel = "";
        tagLabel = option.replace("_", " ");
        return (
          <Tag key={`option${option}`} onRemove={removeTag(option)}>
            {tagLabel}
          </Tag>
        );
      })) ||
    [];

  return (
    <>
      <Autocomplete
        allowMultiple
        options={optionsValue || []}
        selected={selected}
        textField={textField}
        onSelect={(value) => onSelect(value)}
        listTitle={cms("label.suggested")}
      />
      <br />
      <Stack>{tagsMarkup}</Stack>
    </>
  );
};

Tags.propTypes = tagProp.type;

export default Tags;
