import React, { useContext } from "react";

import { OperatorUpdatePayment } from "app/plans/modules/operator/features";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

const { userKey } = constant;
const { operator } = userKey;

const UpdatePayment = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorUpdatePayment />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const UpdatePaymentComponent = getComponent(currentUserRole);
  return UpdatePaymentComponent;
};

export default UpdatePayment;
