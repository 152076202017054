import React, { useContext } from "react";

import AdminDashboard from "app/dashboard/modules/admin/dashboard";
import OperatorDashboard from "app/dashboard/modules/operator/dashboard";
import ProviderDashboard from "app/dashboard/modules/provider/dashboard";

import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Dashboard = () => {
  const { currentUser } = useContext(PrivateContext);
  if (currentUser && currentUser.firstName && currentUser.lastName) {
    storageHelper.set("firstName", currentUser.firstName);
    storageHelper.set("lastName", currentUser.lastName);
  }
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminDashboard />;
    }
    if (role === operator) {
      return <OperatorDashboard />;
    }
    if (role === provider) {
      return <ProviderDashboard />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const DashboardComponent = getComponent(currentUserRole);
  return DashboardComponent;
};

export default withErrorBoundary(Dashboard);
