import constant from "lib/constant/constant";

const planFormConfig = (cms) => {
  const { CODE, DESCRIPTION, NAME, PERIOD, PRODUCT_LIMIT, TRIAL_PERIOD, PRICE } = constant;

  const textFields = [
    {
      label: cms("textField.label.name"),
      placeholder: cms("textField.placeholder.planA"),
      key: NAME,
    },
    {
      label: cms("textField.label.code"),
      placeholder: cms("textField.placeholder.code"),
      key: CODE,
    },
    {
      label: cms("textField.label.description"),
      placeholder: cms("textField.placeholder.description"),
      key: DESCRIPTION,
    },
    {
      label: cms("textField.label.price"),
      placeholder: cms("textField.placeholder.price"),
      key: PRICE,
    },
    {
      label: cms("textField.label.period"),
      placeholder: cms("textField.placeholder.month"),
      key: PERIOD,
    },
    {
      label: cms("textField.label.trialPeriod"),
      placeholder: cms("textField.placeholder.trialPeriod"),
      key: TRIAL_PERIOD,
    },
    {
      label: cms("textField.label.productLimit"),
      placeholder: cms("textField.placeholder.productLimit"),
      key: PRODUCT_LIMIT,
    },
  ];

  return { textFields };
};

export default planFormConfig;
