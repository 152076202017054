import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Caption,
  Card,
  EmptyState,
  Layout,
  Link,
  Page,
  ResourceItem,
  Stack,
  TextStyle,
} from "@shopify/polaris";

import { useQuery } from "@apollo/react-hooks";

// import query
import CREDIT_NOTE_LIST from "app/payments/apollo/query/creditNoteList";

// import component
import { Banner, ResourceList } from "lib/components";

import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import filter
import MoreFilters from "app/payments/modules/generic/moreFilters/moreFilter";

import actionData from "app/payments/modules/generic/list/cms/payment";
import StyledButton from "./genericStyled";

const CreditNoteList = () => {
  const { cms, history, currentUser } = useContext(PrivateContext);
  const { mainLink } = actionData(cms);
  const isVendor = baseHelper.isVendor(currentUser);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [creditNoteList, setCreditNoteList] = useState([]);
  const [creditNoteCount, setCreditNoteCount] = useState(0);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const resourceData = actionData(cms);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [moreButton, setMoreButton] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const inputData = {
    filter: queryParam.filter,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };

  const { loading: getCreditNoteLoading, data: getCreditNoteData, error: getCreditNoteError /* refetch */ } = useQuery(
    CREDIT_NOTE_LIST,
    {
      variables: { input: inputData },
    }
  );

  const creditNoteResponseData = baseHelper.getResponseData(getCreditNoteData, constant.gql.GET_CREDIT_NOTE_LIST);
  const creditNoteErrorData = baseHelper.getResponseError(getCreditNoteData, constant.gql.GET_CREDIT_NOTE_LIST);

  useEffect(() => {
    if (creditNoteResponseData) {
      setCreditNoteList(creditNoteResponseData.creditNoteList || []);
      setCreditNoteCount(creditNoteResponseData.count || 0);
    }
  }, [creditNoteResponseData]);

  useEffect(() => {
    if (creditNoteErrorData) {
      setBanner({ status: constant.CRITICAL, title: creditNoteErrorData, isOpen: true });
    }
    if (getCreditNoteError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(getCreditNoteError), isOpen: true });
    }
  }, [creditNoteErrorData, getCreditNoteError]);

  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };

  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };

  const handleQueryClear = () => {
    setSearch(null);
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false) => {
    const clearParams = { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      setSelectedFilter("");
    }
    setTaggedWith("");
    baseHelper.setUrl(history, clearParams);
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true);
  };

  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return `${value}` || value;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true),
    });
  }
  const appliedFilters = appliedFilterArrObj;

  const filterOptions = [
    { label: cms("label.brandName"), value: constant.VENDOR },
    { label: cms("label.orderId"), value: constant.ORDER_NUMBER },
  ];

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      ),
      shortcut: false,
    },
  ];
  const emptyStateMarkup = !creditNoteCount && (
    <EmptyState heading={cms("section.empty.title")} image={constant.ADDRESS_BOOK_IMAGE} />
  );

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleMoreButton = (id) => {
    try {
      setMoreButton({ [id]: !moreButton[id] });
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: err });
    }
  };

  const renderItem = (item) => {
    const {
      _id: id,
      createdAt,
      creditNoteAmount,
      creditNoteBalance,
      moneyFormat,
      name,
      orderId,
      orderNumber,
      shopifyLineItemId,
      title,
      updatedAt,
      vendor,
      vendorId,
    } = item;

    return (
      <ResourceItem
        id={id}
        orderNumber={orderNumber}
        title={title}
        createdAt={createdAt}
        moneyFormat={moneyFormat}
        name={name}
        orderId={orderId}
        updatedAt={updatedAt}
        vendor={vendor}
        lineItemId={shopifyLineItemId}
        creditNoteAmount={creditNoteAmount}
        creditAccountBalance={creditNoteBalance}
      >
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                {`#${orderNumber} - ${name}`}
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {`${cms("label.createDate")}: ${baseHelper.formatDate(createdAt, true)}`}
              </TextStyle>
              <StyledButton>
                <Button
                  plain
                  onClick={() => {
                    handleMoreButton(id);
                  }}
                  disclosure={moreButton[id] ? constant.UP : constant.DOWN}
                >
                  More
                </Button>
              </StyledButton>
              {moreButton[id] && (
                <>
                  <div className="hidden" title={vendor}>
                    {`${cms("label.vendor")}: `}
                    {(isVendor && vendor) || <Link url={`/providers/view/${vendorId}`}>{vendor}</Link>}
                  </div>
                  <Caption>
                    {`${cms("label.lineItemId")}: `}
                    <span>{`#${shopifyLineItemId}`}</span>
                  </Caption>
                  <TextStyle>{` ${cms("label.amount")}: ${moneyFormat} ${creditNoteAmount}`}</TextStyle>
                  <br />
                  <TextStyle>{` ${cms("label.balance")}: ${moneyFormat} ${creditNoteBalance}`}</TextStyle>
                  <br />
                </>
              )}
            </Caption>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };
  return (
    <>
      <Page>
        {banner.isOpen && (
          <Layout.Section>
            <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("section.balance.title")} description={cms("section.balance.description")}>
          <Card title={cms("section.balance.card.title")}>
            <ResourceList
              items={creditNoteList}
              idForItem={(creditNote) => {
                const { _id: id } = creditNote;
                return id;
              }}
              renderItem={renderItem}
              loading={getCreditNoteLoading}
              queryValue={search}
              onQueryClear={handleQueryClear}
              onQueryChange={handleSearchChange}
              sortOptions={resourceData.sortOptions}
              emptyState={emptyStateMarkup}
              isEmptyState
              sortValue={sortValue}
              onSortChange={handleSort}
              filters={filters}
              appliedFilters={appliedFilters}
              handleClearAll={handleClearAll}
              page={page}
              perPage={perPage}
              setPage={handlePageChange}
              setPerPage={handlePageLimitChange}
              count={creditNoteCount}
            />
          </Card>
        </Layout.AnnotatedSection>
      </Page>
    </>
  );
};

export default withFeature(withErrorBoundary(CreditNoteList), {
  feature: constant.CREDIT_NOTE_LIST,
  // subFeature: constant.CREDIT_NOTE_LIST,
});
