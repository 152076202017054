// import packages
import React, { useContext } from "react";
import { Layout, Card, DescriptionList, Thumbnail, Stack, TextStyle } from "@shopify/polaris";

import { imageHelper } from "lib/helpers";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
// propTypes
import vendorProfileProps from "app/advanceVendor/modules/operator/feature/view/propTypes";

const Additional = (props) => {
  const { cms } = useContext(PrivateContext);
  const { vendorDetails = {} } = props;
  const { customFields = [] } = vendorDetails || {};
  const customFieldsInformation = [];
  let additionalTabData = [];
  let additionalImages = {};
  if (customFields) {
    customFields.forEach((field) => {
      if (field.inputType === cms("label.advanceVendor.textField")) {
        customFieldsInformation.push({
          term: field.label,
          description: field.value,
        });
      } else if (field.inputType === cms("label.advanceVendor.image")) {
        additionalImages = {
          label: field.label,
          url: field.imageUrls,
        };
      } else if (field.inputType === cms("label.advanceVendor.file")) {
        const multimediaUrl =
          imageHelper.resize({ url: field.value, type: constant.imageTypes.THUMBNAIL }) ||
          cms("label.advanceVendor.noImagePlaceHolder");
        customFieldsInformation.push({
          term: field.label,
          description: <Thumbnail size="large" source={multimediaUrl} alt={cms("label.advanceVendor.multimediaAlt")} />,
        });
      }
    });
    additionalTabData = customFieldsInformation.map((item) => {
      const updatedItem = { ...item };
      if (!updatedItem.description) {
        updatedItem.description = cms("label.advanceVendor.noDetailsFound");
      }
      return item;
    });
  }

  const getGrid = () => {
    return additionalImages.url.map((imageValue, index) => {
      let imageShown = imageHelper.resize({ url: imageValue, type: constant.imageTypes.THUMBNAIL });
      if (imageValue === "false") {
        imageShown = cms("label.advanceVendor.noImagePlaceHolder");
      }
      return <Thumbnail source={imageShown} size="large" alt={cms("label.advanceVendor.additionalImage", { index })} />;
    });
  };

  const getDescriptionList = () => {
    if (!(customFields && customFields.length)) {
      return <div className="noData">{cms("label.advanceVendor.noDataAvailable")}</div>;
    }
    const emptyObj = {
      term: "",
      description: "",
    };
    additionalTabData.push(emptyObj);
    return <DescriptionList items={additionalTabData} />;
  };

  const getImages = () => {
    if (!(additionalImages && additionalImages.url && additionalImages.url.length)) {
      return null;
    }
    return (
      <>
        <TextStyle variation="strong">{additionalImages.label}</TextStyle>
        <br />
        <br />
        <Stack alignment="center">{getGrid()}</Stack>
      </>
    );
  };

  const getAdditionalInformation = () => (
    <>
      {getDescriptionList()}
      {getImages()}
    </>
  );
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getAdditionalInformation()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

Additional.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(Additional), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
