// import packages
import React from "react";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { termsData } from "./hrTermCode";

const HRTerms = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: termsData }} />
  );
};

export default withErrorBoundary(HRTerms);
