import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { withFeature, withErrorBoundary } from "lib/hoc";
import ShopifyInstall from "./provider/features/install/install";

const { userKey } = constant;
const { provider } = userKey;

const VendorShopifyInstall = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (role === provider) {
      return <ShopifyInstall />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const ShopifyInstallComponent = getComponent(currentUserRole);
  return ShopifyInstallComponent;
};

export default withFeature(withErrorBoundary(VendorShopifyInstall), { feature: constant.PRODUCT_IMPORT_SHOPIFY });
