import React, { Fragment, useContext } from "react";
import { Card, Layout, RadioButton, Stack } from "@shopify/polaris";
// propTypes
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

// constants
import constants from "lib/constant/constant";
import AutoComplete from "lib/components/autocomplete/autocomplete";

function ManageSetupRule(props) {
  const { manageSetupRuleProps } = props;
  const { cms } = useContext(PrivateContext);
  const { vendors, selectedRuleVendors, onChangeRadio, onChangeAutoComplete, allowVendor } = manageSetupRuleProps;
  const allowAllVendorFieldData = [
    {
      field: constants.RADIO_BUTTON,
      key: constants.APPROVE_ALL,
      label: cms("operator.section.allowVendors.content.allow.label"),
      helpText: cms("operator.section.allowVendors.content.allow.caption"),
    },
    {
      field: constants.RADIO_BUTTON_WITH_AUTO_COMPLETE,
      key: constants.APPROVE_SELECTED,
      label: cms("operator.section.allowVendors.content.selected.label"),
      helpText: cms("operator.section.allowVendors.content.selected.caption"),
      autocomplete: {
        field: constants.AUTOCOMPLETE,
        key: constants.AUTOCOMPLETE,
        label: "Vendors",
        helpText: cms("operator.section.allowVendors.content.selected.placeholder.brandNameAutocomplete"),
      },
    },
    {
      field: constants.RADIO_BUTTON,
      key: constants.NONE,
      label: cms("operator.section.allowVendors.content.dontAllow.label"),
      helpText: cms("operator.section.allowVendors.content.dontAllow.caption"),
    },
  ];
  return (
    <Layout.AnnotatedSection description={cms("operator.section.allowVendors.description")}>
      <Card sectioned>
        <Stack vertical>
          {allowAllVendorFieldData.map((item) => {
            const { key, field, label: itemLabel, helpText } = item;
            return (
              <Fragment key={key}>
                {field === constants.RADIO_BUTTON && (
                  <RadioButton
                    id={key}
                    key={key}
                    label={itemLabel}
                    helpText={helpText}
                    checked={allowVendor === key}
                    onChange={onChangeRadio}
                  />
                )}
                {field === constants.RADIO_BUTTON_WITH_AUTO_COMPLETE && (
                  <>
                    <RadioButton
                      id={key}
                      key={key}
                      label={itemLabel}
                      helpText={helpText}
                      checked={allowVendor === key}
                      onChange={onChangeRadio}
                    />
                    {allowVendor === constants.APPROVE_SELECTED && (
                      <Layout.Section>
                        <AutoComplete
                          label={item.autocomplete.label}
                          labelHidden
                          placeholder={item.autocomplete.helpText}
                          values={vendors}
                          selected={selectedRuleVendors}
                          onChange={onChangeAutoComplete}
                          minimumSearchLength={constants.value.MIN_SEARCH_LENGTH}
                        />
                      </Layout.Section>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}

ManageSetupRule.propTypes = {
  manageSetupRuleProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.array]))
    .isRequired,
};

export default ManageSetupRule;
