import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

//  import components
import { Banner, SkeletonAnnotated } from "lib/components";

// contexts
import { PrivateContext } from "lib/context";

// gql
import { useMutation, useQuery } from "react-apollo";
import { ADD_VARIANT, UPLOAD_IMAGE } from "app/product/apollo/mutations";
import { GET_VENDOR_VARIANT, GET_WHOLESALE_SETTING } from "app/product/apollo/queries";
import { GET_GEOLOCATION_SETTING } from "app/setup/apollo";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";
// import Location from "app/product/modules/provider/features/variant/subFeatures/edit/subFeatureItems/location";

// sub feature items
import { Image, Title, Pricing, Inventory, Shipping } from "./subFeatureItems";

const ProviderVariantAdd = () => {
  const { match, history, cms } = useContext(PrivateContext);
  const { params } = match;
  const { productId = "" } = params;
  const {
    gql,
    value: { MAX_FLAT, MIN_PRICE },
  } = constant;

  const { data: variantData, loading, error } = useQuery(GET_VENDOR_VARIANT, {
    variables: { input: { id: productId } },
  });
  const [addVariant, { loading: addVariantLoading }] = useMutation(ADD_VARIANT);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);

  // states
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [metaFieldData, setMetafielData] = useState([]);

  const [title, setTitle] = useState({});
  const [image, setImage] = useState("");
  const [pricing, setPricing] = useState({
    price: "",
    comparePrice: "",
  });
  const [inventory, setInventory] = useState({
    sku: "",
    barCode: "",
    inventoryPolicy: "",
    quantity: "",
  });
  const [shipping, setShipping] = useState({
    isPhysicalProduct: true,
    weight: "",
    weightUnit: "lb",
  });
  const [variantOption, setVariantOption] = useState([]);
  const [titleError, setTitleError] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [isWholesalePrice, setIsWholesalePrice] = useState("");
  const [locations, setLocation] = useState([]);

  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);
  const { loading: locationLoading, data: locationData } = useQuery(GET_GEOLOCATION_SETTING);

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  const validFieldValue = () => {
    const isEmpty = Object.keys(variantOption)
      .filter((key) => variantOption[key])
      .every((option) => title[option]);
    return isEmpty;
  };

  useEffect(() => {
    if (error && Object.keys(error).length) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.fetch"),
      }));
    }
  }, [cms, error]);

  useEffect(() => {
    if (locationData) {
      const locationSetting = baseHelper.getResponseData(locationData, constant.gql.GET_GEOLOCATION_SETTING) || {};
      const { isAllowed = false, locations: locationArray = [] } = locationSetting;
      if (isAllowed && locationArray && locationArray.length) {
        const updatedLocations = locationArray.map((location) => ({
          label: location,
          value: location,
          disabled: false,
        }));
        setLocation(updatedLocations);
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  // useEffect(() => {
  //   if (geoLocationData) {
  //     const geoLocationDataResponse = baseHelper.getResponseData(geoLocationData, gql.GET_GEOLOCATION_SETTING);
  //     const { locations } = geoLocationDataResponse;
  //     const loc = locations.map((location) => ({
  //       value: location,
  //       // disabled: false,
  //       label: location
  //         .toLowerCase()
  //         .split(" ")
  //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" "),
  //     }));
  //     setGeoLocation(loc);
  //   }
  // }, [geoLocationData, gql.GET_GEOLOCATION_SETTING]);

  if (variantData && Object.keys(variantOption).length === 0) {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT);
    const responseError = baseHelper.getResponseError(variantData, gql.GET_PRODUCT);
    const { variants = [], sku: productSKU, images = [], isShipping } = responseData || {};
    const variantPosition = variants.length + 1;
    if (responseError) {
      setBanner({
        ...banner,
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
    let options = {
      option1: cms("label.size"),
      option2: cms("label.color"),
      option3: cms("label.material"),
    };
    if (variants.length) {
      const {
        option1Val = null,
        option2Val = null,
        option3Val = null,
        option1 = null,
        option2 = null,
        option3 = null,
      } = variants[0];
      const availableOptions = {
        option1: option1Val && option1,
        option2: option2Val && option2,
        option3: option3Val && option3,
      };
      options = availableOptions;
    }
    setShipping({
      ...shipping,
      isPhysicalProduct: isShipping,
    });
    setVariantOption(options);
    setProductImages(images);
    if (productSKU) setInventory({ ...inventory, sku: `${productSKU}-${variantPosition}` });
  }

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (loading || locationLoading || wholesaleLoading) {
    return <SkeletonAnnotated />;
  }

  // const handleLocationChange = (fieldValue) => {
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.label,
  //     value: geo.value,
  //     disabled: geo.disabled || geo.value === fieldValue || false,
  //   }));
  //   setGeoLocation(loc);
  //   const locationData = [...metaFieldData];
  //   locationData.push({
  //     location: fieldValue,
  //     price: (pricing && pricing.price) || 0,
  //     comparePrice: (pricing && pricing.comparePrice) || 0,
  //     quantity: (inventory && inventory.quantity) || 0,
  //   });
  //   setMetafielData(locationData);
  // };

  // const handleTableChange = (newValue, id, field) => {
  //   const metaData = [...metaFieldData];
  //   metaData[id][field] = newValue;
  //   setMetafielData(metaData);
  // };

  // const handleDelete = (index) => {
  //   const metaData = [...metaFieldData];
  //   const deletedCities = metaData.splice(index, 1);
  //   const { location: cities } = deletedCities[0];
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.label,
  //     value: geo.value,
  //     disabled: geo.value === cities ? false : geo.disabled,
  //   }));
  //   setGeoLocation(loc);
  //   setMetafielData(metaData);
  // };

  const saveVariant = async (variantValue) => {
    try {
      const result = await addVariant({
        variables: {
          input: variantValue,
        },
      });

      const responseData = baseHelper.getResponseData(result.data, gql.ADD_VARIANT);
      const responseError = baseHelper.getResponseError(result.data, gql.ADD_VARIANT);

      if (responseError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: responseError,
        });
        return;
      }
      if (responseData) {
        history.push(`/variants/edit/${productId}/${responseData.position}`, { addVariant: true });
      }
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      }));
    }
  };

  const prepareData = () => {
    const { option1 = null, option2 = null, option3 = null } = title;
    const formValues = {
      productId,
      seller: "",
      image: {},
      option1Val: option1 || "",
      option2Val: option2 || "",
      option3Val: option3 || "",
      price: parseFloat(pricing.price),
      comparePrice: parseFloat(pricing.comparePrice),
      sku: inventory.sku,
      barcode: inventory.barCode,
      inventoryManagement: inventory.inventoryPolicy,
      inventoryQuantity: parseInt(inventory.quantity, 10),
      isShipping: shipping.isPhysicalProduct,
      weight: parseFloat(shipping.weight),
      weightUnit: (shipping.weight && shipping.weight !== "" && shipping.weightUnit) || constant.weightValue.LB,
    };

    if (isWholesalePrice) {
      formValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice || 0) };
    }

    const metaData = metaFieldData.map((meta) => {
      return {
        key: meta.location,
        namespace: constant.LOCATION,
        type: constant.JSON,
        value: JSON.stringify({
          price: parseFloat(meta.price, 10),
          comparePrice: parseFloat(meta.comparePrice, 10),
          quantity: parseInt(meta.quantity, 10),
        }),
      };
    });
    formValues.metafield = metaData;
    const requiredError = {};
    let hasError = false;

    if (variantOption.option1) {
      requiredError.option1 = !(option1 && option1.trim() !== "");
      hasError = hasError || requiredError.option1;
    }
    if (variantOption.option2) {
      requiredError.option2 = !(option2 && option2.trim() !== "");
      hasError = hasError || requiredError.option2;
    }
    if (variantOption.option3) {
      requiredError.option3 = !(option3 && option3.trim() !== "");
      hasError = hasError || requiredError.option3;
    }
    setTitleError(requiredError);

    return !hasError && formValues;
  };

  const onSubmit = async () => {
    const variantValue = prepareData();

    if (!variantValue) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      variantValue.image = { url: imageUrl };
    }
    const isLocalImageUrl = image && image.upload === constant.LOCAL;
    if (!isLocalImageUrl) {
      saveVariant(variantValue);
      return;
    }
    if (image && image.length) {
      try {
        const response = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const responseData = baseHelper.getResponseData(response.data, gql.UPLOAD_IMAGE);
        const responseError = baseHelper.getResponseError(response.data, gql.UPLOAD_IMAGE);
        if (responseError) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: responseError,
          }));
          return;
        }
        const { imageUrl = null, imageId = "" } = responseData || {};
        if (!imageUrl) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.url"),
          }));
          return;
        }
        variantValue.image = { url: imageUrl, imageId };
      } catch (exception) {
        setBanner((prev) => ({
          ...prev,
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        }));
        return;
      }
    }
    saveVariant(variantValue);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <br />
        <Title data={title} onChange={setTitle} variantOption={variantOption} error={titleError} cms={cms} locations={locations} />
        <Image productImages={productImages} image={image} setImage={setImage} cms={cms} />
        <Pricing
          acceptOnlyValidInput={acceptOnlyValidInput}
          data={pricing}
          onChange={setPricing}
          cms={cms}
          setWholesalePrice={setWholesalePrice}
          wholesalePrice={wholesalePrice}
          isWholesalePriceVisible={isWholesalePrice}
        />
        <Inventory data={inventory} onChange={setInventory} cms={cms} />
        {/* {!!(geoLocation && geoLocation.length) && (
          <Location
            cms={cms}
            handleLocationChange={handleLocationChange}
            geoLocation={geoLocation}
            metaFieldData={metaFieldData}
            setMetafielData={setMetafielData}
            handleTableChange={handleTableChange}
            handleDelete={handleDelete}
          />
        )} */}
        <Shipping data={shipping} onChange={setShipping} cms={cms} />
        <PageActions
          primaryAction={{
            id: "submit",
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            disabled: !validFieldValue(),
            loading: addVariantLoading || imageLoading,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push(`/products/edit/${productId}?tab=2`),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderVariantAdd), { feature: constant.ADD_VARIANT });
