import React, { useContext } from "react";
import { Button, TextField, Select, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const RenderCommissionRange = (props) => {
  const { count, addRange, updateVal, removeItem } = props;
  const {
    COMMISSION,
    FLAT,
    OPTION,
    PERCENTAGE: PERCENTAGE_TEXT,
    TYPE,
    MAX,
    color: { RED },
    symbol: { DOLLAR, PERCENTAGE },
    value: { MAX_FLAT, MAX_PERCENTAGE },
  } = constant;
  const { currentUser = {}, cms = {} } = useContext(PrivateContext);
  const { moneyFormat = DOLLAR } = currentUser;
  const rangeOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE_TEXT,
    },
  ];
  const arr = Array.from(Array(count).keys());
  return arr.map((item) => {
    const addRangeItem = addRange[item];
    const endRangeValue = parseFloat(addRangeItem.max);
    let startRangeValue = 0;
    if (item) {
      const perviousItemValue = addRange[item - 1];
      startRangeValue = parseFloat(perviousItemValue.max) + 1;
    }
    const commissionValue = parseFloat(addRangeItem.commission);
    const commissionType = addRangeItem.type;
    const isFlatType = addRangeItem && commissionType === FLAT;
    const getMaxValue = isFlatType ? MAX_FLAT : MAX_PERCENTAGE;
    const getValuePrefix = isFlatType ? moneyFormat || DOLLAR : "";
    const getValueSuffix = isFlatType ? "" : PERCENTAGE;
    const optionSelect = `${OPTION}${item}`;
    const commissionText = `${COMMISSION}${item}`;
    const startRangeId = `startRange${item}`;
    const endRangeId = `endRange${item}`;
    const deleteButtonId = `deleteButton${item}`;
    return (
      <div key={item}>
        <Stack wrap={false} key={item} spacing="extraTight" distribution="fillEvenly" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <TextField
                id={startRangeId}
                type="number"
                value={startRangeValue.toString()}
                prefix={moneyFormat}
                disabled
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <TextField
                placeholder={cms("common.placeholder.endRange")}
                id={endRangeId}
                value={endRangeValue && endRangeValue.toString()}
                onChange={(val) => {
                  updateVal(MAX, val, item);
                }}
                prefix={moneyFormat}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={rangeOptions}
                id={optionSelect}
                placeholder={cms("common.label.value")}
                onChange={(val) => {
                  updateVal(TYPE, val, item);
                }}
                value={(addRangeItem && commissionType) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <TextField
                required
                id={commissionText}
                max={getMaxValue}
                prefix={getValuePrefix}
                suffix={getValueSuffix}
                onChange={(val) => {
                  updateVal(COMMISSION, val, item);
                }}
                value={(addRangeItem && commissionValue && commissionValue.toString()) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button id={deleteButtonId} plain onClick={() => removeItem(item)}>
              <i className="far fa-trash fa-lg" style={{ color: RED }} />
            </Button>
          </Stack.Item>
        </Stack>
      </div>
    );
  });
};

export default RenderCommissionRange;
