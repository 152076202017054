/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { ChoiceList, Modal, Tabs, Tag, Card, Layout, Link, TextContainer } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import layout component
import { SingleColumnLayout } from "layout/private/components";

import { ResourceList, Banner, ModalPopup, PermissionBanner } from "lib/components";
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import productListCMS from "app/productOld/modules/generic/feature/list/cms/productListCMS";

// GQL
import { GET_PRODUCT_LIST_PROVIDER, GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";
import { BULK_PRODUCT } from "app/productOld/apollo/mutations";
import RE_SYNC_PRODUCT from "app/productOld/apollo/mutations/reSync";

// Generic Component.
import { RejectProduct, DeleteProduct, ProductListView } from "app/productOld/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/productOld/modules/generic/moreFilters/moreFilter";
import { SUBSCRIPTION_BY_PROVIDER } from "app/productOld/apollo/subscriptions/productList";
import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
import Widget from "./subFeatures/widget";

const ProviderProductList = () => {
  const { cms, history, currentUser, currentUserRefetch, isShopConnected, isShopifyOldVendor } = useContext(
    PrivateContext
  );
  const { fulfillmentScopeExists = false } = currentUser;
  const isVendor = baseHelper.isVendor(currentUser);
  const { filterStatus, tabs, sortOptions, resourceName } = productListCMS(cms);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);

  const queryParams = baseHelper.getQueryParams(history.location.search);

  const paramFilter = (prevAppliedFilter.filter && prevAppliedFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const [queryValue, setQueryValue] = useState(searchVal ? decodeURI(searchVal) : "");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const selectedSort = queryParams.sort_name && `${queryParams.sort_name}_${queryParams.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.UPDATE_DESC);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 10);
  const [singleId, setSingleId] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [primaryActionTitle, setPrimaryActionTitle] = useState(null);
  const [secondaryActionsTitle, setSecondaryActionsTitle] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productListData, setProductListData] = useState(false);
  const [productListTotal, setProductListTotal] = useState(0);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || "");
  const [listLoading, setListLoading] = useState(false);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const [selectedTab, setSelectedTab] = useState("all");
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [isproductListSetting, setIsProductListSetting] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const sort = sortValue.split("_");
    const productSearch = {
      search: queryValue ? decodeURI(queryValue) : "",
      list_filter: selectedFilter ? decodeURI(selectedFilter) : "",
      list_search: taggedWith ? decodeURI(taggedWith) : "",
      sort_name: sort[0] || constant.UPDATE,
      sort_order: sort[1] || constant.DESC,
      page: parseInt(page, 10),
      perPage: parseInt(perPage, 10),
      filter: filterByStatus || [],
    };
    storageHelper.set("productSearch", `?${new URLSearchParams(productSearch).toString()}`);
  }, [queryValue, taggedWith, selectedFilter, sortValue, page, perPage, filterByStatus]);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });
  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
  }
  // update value based on display name - Advance search
  if (constant.ADVANCE_MAP_FILTER && constant.ADVANCE_MAP_FILTER[getQueryParams.list_filter]) {
    getQueryParams.list_filter = constant.ADVANCE_MAP_FILTER[getQueryParams.list_filter];
  }
  getQueryParams.list_search =
    getQueryParams.list_filter === constant.SHOPIFY_CATALOG_ID && getQueryParams.list_search
      ? (prevAppliedFilter && prevAppliedFilter.list_cat_id) || getQueryParams.list_cat_id
      : getQueryParams.list_search;

  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LIST_PROVIDER, {
    variables: {
      input: {
        search: getQueryParams.search ? encodeURI(getQueryParams.search) : getQueryParams.search,
        list_filter: getQueryParams.list_filter ? encodeURI(getQueryParams.list_filter) : getQueryParams.list_filter,
        list_search: getQueryParams.list_search ? encodeURI(getQueryParams.list_search) : getQueryParams.list_search,
        sort_name: getQueryParams.sort_name || constant.UPDATE,
        sort_order: getQueryParams.sort_order || constant.DESC,
        page: parseInt(getQueryParams.page || page, 10),
        perPage: parseInt(getQueryParams.perPage || perPage, 10),
        filter: filterByStatus || selectedTab,
      },
    },
  });

  const [reSyncActions, { loading: reSyncLoading }] = useMutation(RE_SYNC_PRODUCT);

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST_PROVIDER,
        variables: {
          input: {
            search: getQueryParams.search,
            sort_name: getQueryParams.sort_name,
            sort_order: getQueryParams.sort_order,
            page: parseInt(getQueryParams.page || page, 10),
            perPage: parseInt(getQueryParams.perPage || perPage, 10),
            filter: getQueryParams.filter,
            list_filter: getQueryParams.list_filter,
            list_search: getQueryParams.list_search,
          },
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedItems([]);
    const getProducts = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const getProductsError = baseHelper.getResponseError(dataProductList, constant.gql.GET_PRODUCT_LIST);
    if (getProducts) {
      setProductListData(getProducts.productList);
      setProductListTotal(getProducts.count);
    }
    if (getProductsError) {
      setListLoading(false);
      setBanner({ title: getProductsError, status: constant.CRITICAL, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setBanner({ title: errorHelper.parse(errorProductList), status: constant.CRITICAL, isOpen: true });
    }
  }, [errorProductList]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_BY_PROVIDER,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const { loading: loadingProductListing, data: dataProductSetting } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (dataProductSetting) {
      const productistResponse = baseHelper.getResponseData(dataProductSetting, "getProductListingSetting");
      if (productistResponse) {
        const { isEnabled = {} } = productistResponse;
        if (isEnabled) {
          setIsProductListSetting(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductSetting]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleQueryValueRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (selectedValue) => {
    const sort = selectedValue.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };

  const handleRejectModal = (eventKey, id = false) => {
    setSingleId(null);
    if (
      (eventKey === constant.REJECT ||
        eventKey === constant.UNREJECT ||
        eventKey === constant.UNDELETE ||
        eventKey === constant.RE_SUBMIT) &&
      id
    ) {
      setSingleId(id);
    }
    switch (eventKey) {
      case constant.RE_SUBMIT:
        setModalTitle(cms("label.resubmitProduct"));
        setPrimaryActionTitle(cms("label.resubmit"));
        setSecondaryActionsTitle(cms("label.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      default:
        break;
    }
  };
  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const reSyncAction = async (id = "") => {
    let formData = {};
    formData = {
      vendorShopifyProductId: id,
    };
    await reSyncActions({ variables: { input: { ...formData } } }).then((res) => {
      if (res) {
        const responseData = res.data;
        const responseDataError = res.errors;
        if (responseData) {
          const title = cms("common.message.success.background");
          setBanner({ isOpen: true, title, status: constant.SUCCESS });
        }
        if (responseDataError) {
          setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
        }
      }
    });
  };

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      setListLoading(`${eventKey}_${id}`);
    }

    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setOpenRejectModal(false);
        setSelectedItems([]);
        setKeyName(null);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (responseData) {
            setBanner({ isOpen: true, title: cms("common.message.success.background"), status: constant.SUCCESS });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams =
      key === constant.FILTER_BY_STATUS
        ? { list_cat_id: "", list_filter: "", page: 1 }
        : { list_cat_id: "", list_search: "", page: 1 };
    if (clearFilter) {
      // clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setQueryParams(history.location, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setQueryParams(history.location, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      // clearParams.list_filter = "";
      setTaggedWith("");
    }
    const search = baseHelper.setQueryParams(history.location, clearParams);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_cat_id: "", list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {option === constant.PENDING_SMALL ? cms("label.review") : baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return value === constant.SKU
          ? value.toUpperCase()
          : cms(`label.${value === constant.TITLE ? "titleName" : baseHelper.ucFirst(value)}`) ||
              baseHelper.ucFirst(value);
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: "taggedWith",
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(true, constant.TAGGED_WITH),
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: "selectedFilter",
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilters.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(true, constant.FILTER_BY_STATUS),
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    handleSelectedFilterRemove();
  };

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  // const handleModalChange = () => {
  //   setAppUpdate(!appUpdate);
  //   storageHelper.set("customVendorAppUpdate", "updated");
  //   currentUserRefetch();
  //   setIsOpen(false);
  // };

  // function CustomAppUpdateModle() {
  //   if (appUpdate === "No") {
  //     return (
  //       <div style={{ height: "500px" }}>
  //         <Modal
  //           open={appUpdate}
  //           onClose={handleModalChange}
  //           title={cms("common.popup.migrate.title")}
  //           primaryAction={{
  //             content: "Submit",
  //             onAction: handleModalChange,
  //           }}
  //         >
  //           <Card>
  //             <Modal.Section>
  //               <TextContainer>
  //                 <p>{cms("common.popup.migrate.description")}</p>
  //               </TextContainer>
  //             </Modal.Section>
  //           </Card>
  //         </Modal>
  //       </div>
  //     );
  //   }
  //   return null;
  // }

  const filters = [
    {
      key: "selectedFilter",
      label: cms("label.filterBy"),
      filter: (
        <Filter
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          vendorProductListData={productListData}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        item={item}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        reSyncAction={reSyncAction}
        handleRejectModal={handleRejectModal}
        handleDeleteModal={handleDeleteModal}
        listLoading={listLoading}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setBanner}
        active={active}
        setActive={setActive}
        popoverDisable={popoverDisable}
        cms={cms}
        isproductListSetting={isproductListSetting}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: cms("label.action"),
    },
  ];
  const bulkActions = [
    {
      content: cms("label.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
    {
      content: cms("label.edit"),
      onAction: () => bulkAction(constant.BULK_EDIT),
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
    {
      content: cms("label.resubmitSelected"),
      onAction: () => handleRejectModal(constant.RE_SUBMIT),
    },
  ];

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  const getSecondaryComponent = () => {
    return <Widget setBanner={setBanner} cms={cms} />;
  };

  const updatedResourceName = { ...resourceName };

  updatedResourceName.singular = `${updatedResourceName.singular} from page ${page}`;
  updatedResourceName.plural = `${updatedResourceName.plural} from page ${page}`;

  const getComponent = () => {
    return (
      <>
        {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.banner.description.heading")}
              status="critical"
            >
              <p>
                <br />
                {cms("common.popup.migrate.banner.description.para1")}
                <br />
                <br />
                {cms("common.popup.migrate.banner.description.para2")}
              </p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_VENDOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>
                      <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para1")}
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para2")}
                    </TextContainer>
                  </Banner>
                </TextContainer>
                <br />
                {banner.isOpen && isOpen && (
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    action={banner.action}
                    onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                    isScrollTop={banner.isOpen}
                  />
                )}
              </Card>
              <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setModalBanner={setBanner} />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        {!isOpen && banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              action={banner.action}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
            <br />
          </>
        )}
        {getSecondaryComponent()}
        <Layout>
          {/* {isVendor && storageHelper.get("customVendorAppUpdate") === "No" && <CustomAppUpdateModle />} */}
          <DeleteProduct
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            cms={cms}
            selectedItems={selectedItems}
          />
          <RejectProduct
            openRejectModal={openRejectModal}
            setOpenRejectModal={setOpenRejectModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            modalTitle={modalTitle}
            primaryActionTitle={primaryActionTitle}
            secondaryActionsTitle={secondaryActionsTitle}
            keyName={keyName}
            cms={cms}
          />
          <Layout.Section>
            <Card>
              <ResourceList
                resourceName={updatedResourceName}
                items={(productListData && productListData) || []}
                idForItem={(products) => {
                  const { _id: id } = products;
                  return id;
                }}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={(selectValue) => setSelectedItems(selectValue)}
                bulkActions={bulkActions}
                promotedBulkActions={promotedBulkActions}
                loading={loadingProductList || loadingProductListing}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={handleSort}
                onQueryChange={handleSearchValue}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                filters={filters}
                appliedFilters={appliedFilters}
                queryValue={queryValue}
                count={Number(productListTotal)}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                setPopoverDisable={setPopoverDisable}
                renderItem={renderItem}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(ProviderProductList), { feature: constant.PRODUCTS });
