import React, { useState, useEffect, useContext } from "react";

import {
  Badge,
  Banner,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Layout,
  PageActions,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { UPDATE_PACKING_SLIP_LABEL } from "app/setup/apollo/mutations";
import { GET_PACKING_SLIP_SETTING } from "app/setup/apollo/queries";
import ExportTable from "./style";

const PackingSlipLabel = (props) => {
  const {
    CRITICAL,
    gql,
    HIDDEN,
    ORDER_COLUMN_CONTENT,
    PACKING_SLIP_COLUMN_HEADING,
    PACKING_SLIP_LABEL,
    SUCCESS,
    VISIBLE,
    IS_HIDE,
  } = constant;

  const { setBanner } = props;
  const { cms, history } = useContext(PrivateContext);
  const [firstPush, setFirstPush] = useState(true);
  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);

  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [userInput, setUserInput] = useState({
    purchasedFrom: {
      label: "",
      isHide: false,
    },
    deliveredTo: {
      label: "",
      isHide: false,
    },
    product: {
      label: "",
      isHide: false,
    },
    sku: {
      label: "",
      isHide: false,
    },
    quantity: {
      label: "",
      isHide: false,
    },
    price: {
      label: "",
      isHide: false,
    },
    discount: {
      label: "",
      isHide: false,
    },
    tax: {
      label: "",
      isHide: false,
    },
    subTotal: {
      label: "",
      isHide: false,
    },
    totalTax: {
      label: "",
      isHide: false,
    },
    totalShipping: {
      label: "",
      isHide: false,
    },
    totalDiscount: {
      label: "",
      isHide: false,
    },
    total: {
      label: "",
      isHide: false,
    },
    orderFulfilledBy: {
      label: "",
      isHide: false,
    },
  });
  const mappingObject = {
    deliveredTo: "deliverTo",
    discount: "discount",
    orderFulfilledBy: "fulfillBy",
    price: "price",
    product: "product",
    purchasedFrom: "purchaseFrom",
    quantity: "quantity",
    sku: "sku",
    subTotal: "subTotal",
    tax: "tax",
    total: "total",
    totalDiscount: "totalDiscount",
    totalShipping: "shipping",
    totalTax: "amount", // total tax label updated with the Order amount label at UI
  };

  // eslint-disable-next-line no-shadow
  const handleToggle = () => setOpen((open) => !open);
  const [manageSlip, { loading: manageSlipLoading }] = useMutation(UPDATE_PACKING_SLIP_LABEL);

  const { error: errorManageSlip, data: dataManageSlip, refetch } = useQuery(GET_PACKING_SLIP_SETTING);

  const manageSlipData = baseHelper.getResponseData(dataManageSlip, gql.GET_PACKING_SLIP_SETTING);
  const manageSlipError = baseHelper.getResponseError(errorManageSlip, gql.GET_PACKING_SLIP_SETTING);
  useEffect(() => {
    if (dataManageSlip) {
      setUpdatedAt(manageSlipData.updatedAt);

      const data = manageSlipData;
      if (data && data.createdAt) {
        setFirstPush(false);
        // eslint-disable-next-line no-shadow
        const { createdAt, updatedAt, ...rest } = data;
        setUserInput({ ...userInput, ...rest });
      }

      if (manageSlipError) {
        setBanner({ isOpen: true, title: manageSlipError, status: constant.CRITICAL });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataManageSlip, manageSlipData]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const check = Object.keys(userInput).map((item) => {
      newData.push(userInput[item]);
      if (userInput[item].isHide) {
        return true;
      }
      return false;
    });
    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        if (element.label && item.label && element.label.trim() === item.label.trim()) {
          response = true;
          setBanner({
            isOpen: true,
            title: cms("section.labelSetting.message.error.duplicate"),
            status: constant.CRITICAL,
          });
        }
        return true;
      });
    });
    if (!response) {
      response = true;
      check.forEach((item) => {
        if (!item) response = item;
      });
      setBanner({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const managePackingSlipData = async () => {
    manageSlip({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, gql.UPDATE_PACKING_SLIP_LABEL);
          const resError = baseHelper.getResponseError(res.data, gql.UPDATE_PACKING_SLIP_LABEL);
          if (resData) {
            refetch();
            // eslint-disable-next-line no-unused-expressions
            firstPush === true
              ? setMessage(cms("section.labelSetting.message.success.save"))
              : setMessage(cms("section.labelSetting.message.success.update"));
          }
          if (resError) {
            setBanner({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const updateData = userInput[key];
    updateData.label = val;
    setSubmitEnable(true);
    setUserInput({ ...userInput, [key]: updateData });
  };

  const acceptsStringOnly = (value, prevValue) => {
    const currentValue = (baseHelper.stringNotAcceptSpaceAtStart(value) && value) || (value !== "" && prevValue) || "";
    const result =
      (baseHelper.acceptOnlyString(currentValue) && currentValue) || (currentValue !== "" && prevValue) || "";
    return result;
  };

  const selectCurrentLabelsColumn = (item) => {
    const key = mappingObject[item];
    return <TextStyle>{cms(`section.labelSetting.label.${key}`) || item}</TextStyle>;
  };

  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField
        value={data && data.label}
        onChange={(value) => handleLabelChange(acceptsStringOnly(value, data.label), key)}
      />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === IS_HIDE) {
      switch (key) {
        case PACKING_SLIP_LABEL.DISCOUNT:
        case PACKING_SLIP_LABEL.TOTAL_DISCOUNT: {
          const discountKeys = {
            discount: { label: userInput.discount.label, isHide: !updateData.isHide },
            totalDiscount: { label: userInput.totalDiscount.label, isHide: !updateData.isHide },
          };
          setUserInput({ ...userInput, ...discountKeys });
          break;
        }
        case PACKING_SLIP_LABEL.TAX:
        case PACKING_SLIP_LABEL.TOTAL_TAX: {
          const taxKeys = {
            tax: { label: userInput.tax.label, isHide: !updateData.isHide },
            totalTax: { label: userInput.totalTax.label, isHide: !updateData.isHide },
          };
          setUserInput({ ...userInput, ...taxKeys });
          break;
        }
        default:
          setUserInput({ ...userInput, [key]: { ...updateData, isHide: !updateData.isHide } });
          break;
      }
    }
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => {
    return (
      <Checkbox
        label=""
        checked={userInput[index].isHide}
        onChange={() => handleCheckbox(constant.IS_HIDE, index)}
        disabled={
          index === PACKING_SLIP_LABEL.PRODUCT ||
          index === PACKING_SLIP_LABEL.DELIVERED_TO ||
          index === PACKING_SLIP_LABEL.QUANTITY
        }
      />
    );
  };

  const getRows = () => {
    return Object.keys(userInput).map((item) => {
      let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
      const itemData = userInput[item];
      if (itemData.isHide) {
        badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
      } else {
        badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
      }
      const currentValues = selectCurrentLabelsColumn(item);
      const newValues = selectNewLabelsTextField(userInput[item], item);
      const badgesValues = badges(badge);
      const hideColumnValues = selectHideColumn(item);
      return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
    });
  };

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection
          title={cms("section.labelSetting.title")}
          description={cms("section.labelSetting.description")}
        >
          <Card
            sectioned
            title={[
              cms("section.labelSetting.card.heading"),
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
            actions={[
              {
                content: open ? "Hide" : "Show",
                onAction: () => handleToggle(),
                disclosure: open ? "up" : "down",
              },
            ]}
          >
            <div className="toggle-action">
              <TextStyle variation="subdued">{cms("section.labelSetting.card.description")}</TextStyle>
            </div>

            <Collapsible
              open={open}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <TextStyle variation="strong">{cms("section.manageSlip.card.title")}</TextStyle>
              <ExportTable className="payment-export-table">
                <DataTable
                  columnContentTypes={ORDER_COLUMN_CONTENT}
                  headings={PACKING_SLIP_COLUMN_HEADING}
                  rows={getRows()}
                />
              </ExportTable>
              {!firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) managePackingSlipData();
                    },
                    disabled: !submitEnabled,
                    loading: manageSlipLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </Collapsible>
            {firstPush && (
              <Collapsible
                open={open}
                id="basic-collapsible"
                transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                expandOnPrint
              >
                <br />
                <div className="page-action">
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.submit"),
                      onAction: () => {
                        const update = verifyValues();
                        if (!update) managePackingSlipData();
                      },
                      disabled: !submitEnabled,
                      loading: manageSlipLoading,
                    }}
                    secondaryActions={[
                      {
                        id: "cancelButton",
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </div>
              </Collapsible>
            )}
          </Card>
        </Layout.AnnotatedSection>
        <Toast message={message} setToast={setMessage} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(PackingSlipLabel), {
  feature: constant.ORDER_PACKING_SLIP_LABEL_SETTING,
});
