import React, { useState, useContext } from "react";
import { Layout, Card, Checkbox, Stack, RadioButton, TextField, Select } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import AutoComplete from "./shippingAutoComplete";

const ShippingByVendor = (props) => {
  const { cms, currentUser } = useContext(PrivateContext);
  const { moneyFormat = constant.symbol.DOLLAR } = currentUser;
  const {
    setShippingByVendor,
    shippingByVendor,
    setIsShippingByVendor,
    isShippingByVendor,
    vendors,
    shippingVendors,
  } = props;
  const [selected, setSelected] = useState(
    (shippingByVendor && shippingByVendor.selected && !!shippingByVendor.selected.length) || false
  );
  const [selectedVendors, setSelectedVendors] = useState(shippingVendors || []);

  function handleSelectShipping(value) {
    setSelectedVendors(value);
    if (value.length > ((shippingByVendor.selected && shippingByVendor.selected.length) || 0)) {
      const selectedValues = shippingByVendor.selected || [];
      const selectedVendorIds = selectedValues.map((selectedValue) => selectedValue.vendorId);
      const vendorIds = value.filter((vendorId) => !selectedVendorIds.includes(vendorId));
      if (vendorIds && vendorIds.length) {
        vendorIds.forEach((vendorId) => {
          selectedValues.push({
            amount: 0,
            type: constant.FIXED,
            vendorId,
          });
        });
      }

      setShippingByVendor({
        all: null,
        selected: selectedValues,
      });
    }
  }

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const onShippingDataChange = (field, value, vendor, id) => {
    const newData = (shippingByVendor && shippingByVendor.selected) || [];
    const findIndex = newData.findIndex((dataIndex) => dataIndex.vendorId === vendor);

    if (field === constant.AMOUNT) {
      newData[findIndex].amount = value;
    }
    if (field === constant.TYPE) {
      newData[findIndex].type = value;
    }
    newData[findIndex].vendorId = vendor;

    setShippingByVendor({
      all: null,
      selected: newData,
    });
  };

  const handleAllShipping = (field, val) => {
    const allValue = shippingByVendor.all || {};
    allValue[field] = val;
    setShippingByVendor({
      all: allValue,
      selected: [],
    });
  };

  const allShippingData = (shippingByVendor && shippingByVendor.all) || {
    amount: 0,
    type: constant.FIXED,
  };

  return (
    <Layout.AnnotatedSection description={cms("operator.section.chargeShipping.label.description")}>
      <Card sectioned>
        <Stack vertical>
          <Checkbox
            label={cms("operator.section.chargeShipping.label.charge")}
            helpText={cms("operator.section.chargeShipping.helpText.charge")}
            checked={isShippingByVendor}
            onChange={() => setIsShippingByVendor(!isShippingByVendor)}
          />
          {isShippingByVendor && (
            <>
              <RadioButton
                label={cms("operator.section.chargeShipping.label.all")}
                helpText={cms("operator.section.chargeShipping.helpText.all")}
                checked={selected === false}
                id="allVendor"
                name="selectedShipping"
                onChange={() => [
                  setSelected(selected === null ? false : !selected),
                  setShippingByVendor({ all: { type: constant.FIXED }, selected: [] }),
                ]}
              />
              {selected !== null && !selected && (
                <Layout.Section>
                  <Stack>
                    <Select
                      label={`${cms("operator.section.chargeShipping.label.selectShipping")}*`}
                      value={(allShippingData && allShippingData.type) || ""}
                      options={[
                        {
                          label: cms("operator.section.chargeShipping.label.fixed"),
                          value: constant.FIXED,
                        },
                        {
                          label: cms("operator.section.chargeShipping.label.free"),
                          value: constant.FREE,
                        },
                      ]}
                      onChange={(val) => handleAllShipping(constant.TYPE, val)}
                    />
                    {allShippingData && allShippingData.type === constant.FIXED && (
                      <TextField
                        label={`${cms("operator.section.chargeShipping.label.amount")}*`}
                        value={(allShippingData && allShippingData.amount && String(allShippingData.amount)) || ""}
                        onChange={(val) => {
                          handleAllShipping(constant.AMOUNT, acceptOnlyValidInput(val, allShippingData.amount));
                        }}
                        prefix={moneyFormat}
                        onBlur={() => handleAllShipping(constant.AMOUNT, parseFloat(allShippingData.amount))}
                      />
                    )}
                  </Stack>
                </Layout.Section>
              )}
              <RadioButton
                label={cms("operator.section.chargeShipping.label.select")}
                helpText={cms("operator.section.chargeShipping.helpText.select")}
                id="selectedVendor"
                name="selectedShipping"
                checked={selected === true}
                onChange={() => [
                  setSelected(selected === null ? true : !selected),
                  setShippingByVendor({ all: null, selected: [] }),
                ]}
              />
              {selected && (
                <Layout.Section>
                  <AutoComplete
                    options={vendors}
                    selectedOptions={selectedVendors}
                    setSelectedOptions={(value) => handleSelectShipping(value)}
                    setShippingByVendor={setShippingByVendor}
                    shippingByVendor={shippingByVendor}
                    minimumSearchLength={constant.value.MIN_SEARCH_LENGTH}
                    cms={cms}
                  />
                  <br />
                  {selectedVendors.map((item, id) => {
                    const brand = vendors.find((vendorItem) => vendorItem.value === item);
                    if (!brand || !brand.label) {
                      return null;
                    }
                    const userData = (shippingByVendor.selected || []).find(
                      (vendorItem) => vendorItem.vendorId === item
                    ) || {
                      amount: 0,
                      type: constant.FIXED,
                      vendorId: item,
                    };
                    return (
                      <Card key={item} sectioned title={brand.label}>
                        <Layout.Section>
                          <Stack>
                            <Select
                              label={`${cms("operator.section.chargeShipping.label.selectShipping")}*`}
                              value={userData.type || ""}
                              options={[
                                {
                                  label: cms("operator.section.chargeShipping.label.fixed"),
                                  value: constant.FIXED,
                                },
                                {
                                  label: cms("operator.section.chargeShipping.label.free"),
                                  value: constant.FREE,
                                },
                              ]}
                              onChange={(val) => onShippingDataChange(constant.TYPE, val, item, id)}
                            />
                            {userData.type === constant.FIXED && (
                              <TextField
                                label={`${cms("operator.section.chargeShipping.label.amount")}*`}
                                value={(userData.amount && String(userData.amount)) || ""}
                                prefix={moneyFormat}
                                onChange={(val) => {
                                  onShippingDataChange(
                                    constant.AMOUNT,
                                    acceptOnlyValidInput(val, userData.amount),
                                    item,
                                    id
                                  );
                                }}
                                onBlur={() => {
                                  onShippingDataChange(constant.AMOUNT, parseFloat(userData.amount), item, id);
                                }}
                              />
                            )}
                          </Stack>
                        </Layout.Section>
                      </Card>
                    );
                  })}
                </Layout.Section>
              )}
            </>
          )}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default withErrorBoundary(ShippingByVendor);
