import React, { useContext } from "react";

import AdminFeaturePanel from "app/setup/modules/admin/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin } = userKey;

const FeaturePanel = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminFeaturePanel />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const FeaturePanelComponent = getComponent(currentUserRole);
  return FeaturePanelComponent;
};

export default FeaturePanel;
