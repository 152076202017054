import { gql } from "apollo-boost";

const APPROVE_ALL_PRODUCT_LAKE_CHANGES = gql`
  mutation approveAllProductLakeChanges($input: AllProductLakeChanges) {
    approveAllProductLakeChanges(input: $input) {
      data
      error
      status
    }
  }
`;

const APPROVE_LAKE_SELLER_CHANGES = gql`
  mutation approveLakeSellerChanges($input: approveLakeSellerChanges) {
    approveLakeSellerChanges(input: $input) {
      data
      error
      status
    }
  }
`;

export { APPROVE_ALL_PRODUCT_LAKE_CHANGES, APPROVE_LAKE_SELLER_CHANGES };
