import { gql } from "apollo-boost";

const GET_MANAGE_FULFILLMENT_SETTING = gql`
  query {
    getManageFulfillmentSetting {
      data {
        edit
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_MANAGE_FULFILLMENT_SETTING;
