import React, { useState, useContext } from "react";
import { Card, Layout, Tabs } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper } from "lib/helpers";

// subFeature
import ManageShipping from "./subFeatures/manageShipping";
import ShippingBand from "./subFeatures/shippingBand";
import ManageShippingProcess from "./subFeatures/manageShipping/subItems/manageShippingProcess";

function OperatorShipping() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { cms, currentUser } = useContext(PrivateContext);
  const [isShowingBandTab, setShowingBandTab] = useState(false);
  const [allowShipping, setAllowShipping] = useState(
    currentUser && currentUser.isGlobalShipping ? constant.GLOBAL : constant.ADVANCE
  );

  const isWooCommerceUser = baseHelper.isWooCommerceUser(currentUser);

  const tabs = [
    {
      id: constant.MANAGE_SHIPPING,
      content: cms("operator.tab.manage"),
      accessibilityLabel: cms("operator.tab.manage"),
      panelID: constant.MANAGE_SHIPPING_TERMS,
    },
  ];

  if (isShowingBandTab) {
    const shippingBand = {
      id: constant.SHIPPING_BAND,
      content: cms("operator.tab.band"),
      accessibilityLabel: cms("operator.tab.band"),
      panelID: constant.SHIPPING_BAND,
    };
    tabs.push(shippingBand);
  }

  const handleAllowShipping = (_, newValue) => {
    setAllowShipping(newValue);
  };

  return (
    <>
      <Layout.Section>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
        {selectedTab === 0 && isWooCommerceUser && (
          <ManageShippingProcess allowShipping={allowShipping} handleAllowShipping={handleAllowShipping} />
        )}
        {selectedTab === 0 && (
          <ManageShipping
            setShowingBandTab={setShowingBandTab}
            setSelectedTab={setSelectedTab}
            allowShipping={allowShipping}
          />
        )}
        {selectedTab === 1 && isShowingBandTab && <ShippingBand />}
      </Layout.Section>
    </>
  );
}

export default withFeature(withErrorBoundary(OperatorShipping));
