import styled from "styled-components";

export const StyledCardHeader = styled.div`
  .Polaris-Card__Header {
    padding: 2rem;
    i {
      padding-top: 0.6rem;
      line-height: 1;
    }
    .Polaris-TextStyle--variationSubdued {
      display: none;
      @media (min-width: 490px) {
        display: block;
      }
    }
  }
  .Polaris-Stack__Item.Polaris-Stack__Item--fill {
    max-width: calc(100% - 5.5rem);
    & + .Polaris-Stack__Item {
      align-self: center;
      flex: 0 0 5.5rem;
    }
  }
`;

export const StyledSection = styled.div`
  .Polaris-Card__Section {
    padding: 1.2rem 1rem;
    border: 0;
    @media (min-width: 490px) {
      padding: 1.2rem 2.7rem;
    }
  }
`;

export const StyledMediaCard = styled.div`
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    width: 40%;
  }
  .Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
    width: 60%;
  }
  .notes {
    margin-top: 1.5rem;
  }
  .Polaris-TextStyle--variationSubdued {
    font-size: 13px;
  }
  .Polaris-Stack .Polaris-Stack__Item + .Polaris-Stack__Item {
    margin-top: 0;
  }
`;
