import React from "react";
import { SkeletonThumbnail } from "@shopify/polaris";

import CardSkeleton from "./style";

const ReportCardSkeleton = ({ cardCount = 4 }) => {
  const card = [];
  for (let i = 0; i < cardCount; i += 1) {
    card.push(<SkeletonThumbnail />);
  }
  return (
    <>
      <CardSkeleton>{card}</CardSkeleton>
      <br />
    </>
  );
};

export default ReportCardSkeleton;
