import { gql } from "apollo-boost";

const SYNC_TEMP_VENDOR_MAGENTO_PRODUCT = gql`
  mutation syncTempVendorMagentoProduct($input: BulkAction!) {
    syncTempVendorMagentoProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_TEMP_VENDOR_MAGENTO_PRODUCT;
