import React from "react";
import { InvoiceDownload } from "app/payments/modules/generic";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const ProviderInvoice = () => {
  return <InvoiceDownload />;
};

export default withFeature(withErrorBoundary(ProviderInvoice), { feature: constant.INVOICE });
