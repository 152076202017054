import React, { useContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout, Stack, Button, DisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { GET_PACKING_SLIP_SETTING } from "app/setup/apollo/queries";
import { GET_CUSTOMER } from "app/orders/apollo/queries";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";
import constant from "lib/constant/constant";
import StyledSlip from "asset/styles/paymentSlip";

require("jspdf-autotable");

const OrderSlipView = (props) => {
  const { getSellerAddress, logo, orderData, orderLineItems, sellerAddressData, sellerInfo, viewOrderData } = props;
  const {
    taxesIncluded,
    shipping: { amount },
  } = orderData;

  const { customer: customerDetail = {}, email, phone } = viewOrderData || {};
  const { phone: customerPhone = "" } = customerDetail || {};

  const { cms, currentUser } = useContext(PrivateContext);
  const { font = "" } = currentUser;
  const { introductorySeller: sellerId } = currentUser;
  const marketCube = cms("label.marketCube");
  const currentUserAddress = baseHelper.getFullAddress(currentUser);
  const [isTaxInclusive, setIsTaxInclusive] = useState(false);
  const [customer, setCustomer] = useState({});
  const [fulfillmentType, setFulfillmentType] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [shippingAmount, setShippingAmount] = useState(0);
  const [fulfillmentData, setFulfillmentData] = useState({});
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });

  const isVendor = baseHelper.isVendor(currentUser);
  const { taxSetting = {} } = currentUser;
  const isPackingSlip = true;

  const [userInput, setUserInput] = useState({
    product: {
      label: "",
      isHide: false,
    },
    quantity: {
      label: "",
      isHide: false,
    },
    price: {
      label: "",
      isHide: false,
    },
    sku: {
      label: "",
      isHide: false,
    },
    discount: {
      label: "",
      isHide: false,
    },
    total: {
      label: "",
      isHide: false,
    },
    totalDiscount: {
      label: "",
      isHide: false,
    },
    tax: {
      label: "",
      isHide: false,
    },
    totalTax: {
      label: "",
      isHide: false,
    },
    shipping: {
      label: "",
      isHide: false,
    },
    totalShipping: {
      label: "",
      isHide: false,
    },
    subTotal: {
      label: "",
      isHide: false,
    },
    purchasedFrom: {
      label: "",
      isHide: false,
    },
    deliveredTo: {
      label: "",
      isHide: false,
    },
    orderFulfilledBy: {
      label: "",
      isHide: false,
    },
  });

  const { loading: loadingManagePackingSlip, error: errorManagePackingSlip, data: dataManagePackingSlip } = useQuery(
    GET_PACKING_SLIP_SETTING
  );

  const { data: customerData, loading: loadingGetCustomer } = useQuery(GET_CUSTOMER, {
    variables: {
      input: { sellerId },
    },
  });

  const managePackingSlipResponse = baseHelper.getResponseData(
    dataManagePackingSlip,
    constant.gql.GET_PACKING_SLIP_SETTING
  );
  const managePackingSlipError = baseHelper.getResponseError(
    errorManagePackingSlip,
    constant.gql.GET_PACKING_SLIP_SETTING
  );

  useEffect(() => {
    if (dataManagePackingSlip) {
      if (managePackingSlipResponse && managePackingSlipResponse.createdAt) {
        setUserInput(managePackingSlipResponse);
      }
      if (managePackingSlipError) {
        setBanner({ isOpen: true, title: managePackingSlipError, status: constant.CRITICAL });
      }
    }
  }, [dataManagePackingSlip]);

  useEffect(() => {
    if (customerData) {
      const customerView = baseHelper.getResponseData(customerData, constant.gql.GET_CUSTOMER);
      const { customer: customerSetting = {} } = customerView || {};
      setCustomer(customerSetting);
    }
  }, [customerData]);

  useEffect(() => {
    if (sellerAddressData) {
      const resData = baseHelper.getResponseData(sellerAddressData, constant.gql.GET_ADDRESS_DETAILS);

      if (resData) {
        setFulfillmentData(resData);
      }
    }
  }, [sellerAddressData]);

  useEffect(() => {
    if (fulfillmentType.includes(constant.CONSIGNMENT)) {
      if (isVendor) {
        getSellerAddress({ variables: { input: { addressId, sellerId } } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType]);

  const getShippingAddress = (dataShipping) => {
    const {
      name = "",
      phone = "",
      address1 = "",
      address2 = "",
      city = "",
      company = "",
      province = "",
      country = "",
      zip = "",
    } = dataShipping;
    return (
      <>
        <b>{name}</b>
        {name ? <br /> : ""}
        {company}
        {company ? <br /> : ""}
        {address1}
        {address1 ? <br /> : ""}
        {address2}
        {address2 ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {province}
        {province ? <br /> : ""}
        {zip}
        {zip ? <br /> : ""}
        {country}
        <br />
      </>
    );
  };

  const getVendorShippingAddress = (data, users) => {
    const { address = "", city = "", postalCode = "", country = "" } = data || {};
    const { firstName = "", lastName = "", phoneNumber = "" } = users || {};
    return (
      <>
        {firstName}
        {lastName}
        {firstName || lastName ? <br /> : ""}
        {phoneNumber}
        {phoneNumber ? <br /> : ""}
        {address}
        {address ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {country}
        {country ? <br /> : ""}
        {postalCode}
      </>
    );
  };

  useEffect(() => {
    const type = [];
    let address = "";
    let shipping = 0;
    orderLineItems.forEach((item) => {
      type.push(item.fulfillmentType);
      address = item.addressId;
      shipping += item.shippingAmount || 0;
    });
    setFulfillmentType(type);
    setAddressId(address);
    setShippingAmount(shipping);
  }, [orderLineItems]);

  const productsList = (isSku = false) => {
    const productList = orderLineItems.map((item) => {
      const { price, markUpPrice, sellerDiscount } = item;
      const itemPrice = parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0);
      const unId = Date.now() + Math.random();
      const totalPrice = parseFloat(item.fulfillableQuantity) * parseFloat(itemPrice);
      return (
        <tr className="item" key={`${item.shopifyLineItemId}_${unId}`}>
          <td>{item.name}</td>
          {isSku && <td>{item.sku}</td>}
          <td>{item.fulfillableQuantity}</td>
          <td>{baseHelper.getPrice(itemPrice)}</td>
          <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totalPrice)}`}</td>
        </tr>
      );
    });
    return productList;
  };

  const getItemPrice = (value, item) => {
    const { actualQuantity = 0, fulfillableQuantity = 0, markUpPrice, price, sellerDiscount } = item;
    const priceValue = parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0);
    if (value === constant.PRICE) {
      return priceValue;
    }
    const totalPrice = parseFloat(fulfillableQuantity || actualQuantity - fulfillableQuantity) * parseFloat(priceValue);
    return totalPrice;
  };

  const getSenderName = (user) => {
    const isSeller = baseHelper.isSeller(user);
    if (isSeller) {
      return user.brandName || user.shop;
    }
    if (isVendor) {
      return user.brandName;
    }

    return marketCube;
  };

  const getTaxValue = (item) => {
    let taxValue = 0;
    item.taxLines.forEach((data) => {
      taxValue += Number(data.price);
    });
    return taxValue;
  };

  const checkSku = (lineData = []) => {
    let isSku = false;
    lineData.forEach((data) => {
      if (data.sku && data.sku !== "NaN") {
        isSku = true;
      }
    });
    return isSku;
  };

  const checkShipping = (shippingAmount = 0) => {
    let isShipping = false;
    if (shippingAmount) {
      isShipping = true;
    }
    return isShipping;
  };

  const checkDiscount = (lineData = []) => {
    let isDiscount = false;
    lineData.forEach((data) => {
      if (data && data.discountAllocations && data.discountAllocations.length) {
        isDiscount = true;
      }
    });
    return isDiscount;
  };
  if (loadingManagePackingSlip || loadingGetCustomer) {
    return <Spinner isFullPage />;
  }

  const isSku = checkSku(orderLineItems || []);
  const isShipping = checkShipping(shippingAmount);
  const isDiscount = checkDiscount(orderLineItems || []);
  const isSeller = baseHelper.isSeller(currentUser);

  const totals = baseHelper.getCalculationForSlip(orderLineItems || [], orderData, isSeller);
  const totalTax = totals.totalTaxRate ? (totals.subTotal - totals.discount) * totals.totalTaxRate : 0;

  const tdColSpan = !!isSku;
  const tdColSpan1 = isShipping ? true : "";
  const tdColSpan2 = isDiscount ? true : "";

  const sellerAddress = baseHelper.getFullAddress(sellerInfo);
  const { taxSetting: sellerTaxSetting = {} } = sellerInfo || {};

  let totalTaxValue = 0;
  orderLineItems.forEach((data) => {
    data.taxLines.forEach((item) => {
      totalTaxValue += Number(item.price);
    });
  });

  const totalTaxData = totalTaxValue;

  let totalDiscountValue = 0;
  orderLineItems.forEach((data) => {
    data.discountAllocations.forEach((item) => {
      totalDiscountValue += Number(item.amount);
    });
  });

  const totalDiscountData = totalDiscountValue;

  const totalShippingData = amount;

  const subTotalAmount = orderLineItems.map((item) => {
    const {
      actualQuantity = 0,
      discountAllocations = [],
      fulfillableQuantity = 0,
      markUpPrice,
      price,
      sellerDiscount,
    } = item;
    let discountToAdd;
    (discountAllocations || []).forEach((discountItem) => {
      if (discountItem) {
        discountToAdd = discountItem.amount || 0;
      }
    });
    const priceValue =
      parseFloat(price || 0) +
      parseFloat(markUpPrice || 0) -
      parseFloat(sellerDiscount || 0) -
      parseFloat(discountToAdd || 0);
    const totalPrice = parseFloat(fulfillableQuantity || actualQuantity - fulfillableQuantity) * parseFloat(priceValue);
    return totalPrice;
  });

  const subTotalData = subTotalAmount.reduce((total, item) => {
    return total + item;
  }, 0);

  const totalData =
    (taxesIncluded ? null : parseFloat(totalTaxData)) +
    parseFloat(shippingAmount || 0) +
    //   -
    //  parseFloat(totalDiscountData)
    parseFloat(subTotalData);

  let totalpriceData = 0;

  orderLineItems.forEach((item) => {
    totalpriceData += parseFloat(baseHelper.getPrice(getItemPrice(constant.SUBTOTAL, item)));
  });

  const {
    isEmailChecked = false,
    isPhoneNumberChecked = false,
    email: operatorSettingEmail = "",
    phoneNumber: operatorSettingPhone = "",
  } = customer || {};

  const packingSlipEmail = isEmailChecked ? operatorSettingEmail : email;
  const packingSlipPhoneNumber = isPhoneNumberChecked ? operatorSettingPhone : (customerPhone || phone);

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      <StyledSlip font={font} className="container">
        <div className="invoice-company text-inverse">
          {(logo && <img src={logo} className="brand-logo" id="tableBanner" alt="" />) || (
            <DisplayText size="medium">{sellerInfo.brandName}</DisplayText>
          )}
          <span className="pull-right hidden-print" id="hide-element">
            <a href="" onClick={baseHelper.printInvoice} className="btn-white">
              <i className="fa fa-print t-plus-1 fa-fw fa-lg" style={{ paddingRight: "20px" }} />
              {cms("button.print")}
            </a>
          </span>
        </div>
        {/* invoice-header */}

        <div className="invoice-header">
          {userInput.purchasedFrom && !userInput.purchasedFrom.isHide && (
            <div className="invoice-from">
              <small className="title text-uppercase">
                {userInput.purchasedFrom.label ? userInput.purchasedFrom.label : cms("label.purchaseFrom")}
              </small>
              <address>
                <strong className="text-inverse capitalize">{sellerInfo.brandName.toLowerCase()}</strong>
                <br />
                {sellerInfo.address}
                <br />
                {sellerInfo.city}
                <br />
                {sellerInfo.country}
                <br />
                {sellerInfo.pinCode && sellerInfo.pinCode}
              </address>
            </div>
          )}

          {orderData.shippingAddress && Object.values(orderData.shippingAddress).filter((item) => item).length > 1 && (
            <div className="invoice-to">
              <small className="title text-uppercase">
                {userInput.deliveredTo.label ? userInput.deliveredTo.label : cms("label.deliver")}
              </small>
              <address>
                {(isVendor &&
                  fulfillmentType.includes(constant.CONSIGNMENT) &&
                  getVendorShippingAddress(fulfillmentData, sellerInfo)) ||
                  getShippingAddress((orderData && orderData.shippingAddress) || {})}
              </address>
              {packingSlipEmail && cms("label.customerEmail")}
              {packingSlipEmail}
              {packingSlipEmail ? <br /> : ""}
              {packingSlipPhoneNumber && cms("label.customerPhone")}
              {packingSlipPhoneNumber}
              {packingSlipPhoneNumber ? <br /> : ""}
            </div>
          )}

          <div className="invoice-date">
            <small className="title text-uppercase">
              {fulfillmentType.includes(constant.SERVICE) ? cms("label.serviceSlip") : cms("label.packingSlip")}
            </small>

            <div className="date text-inverse">{`${baseHelper.formatDate(orderLineItems.createdAt, false)}`}</div>
            <div className="invoice-detail">
              <strong>{` ${baseHelper.formatDate(new Date(), false)}`}</strong>
              <br />
              {cms("label.orderId")}
              {orderData && `: #${orderData.orderNumber}`}
            </div>
          </div>
        </div>

        <div className="invoice-content">
          <div className="table-responsive">
            <table className="table table-invoice">
              <thead>
                <tr>
                  <th>{userInput.product.label || cms("label.product")}</th>
                  {userInput.sku && !userInput.sku.isHide && (
                    <th>
                      {productsList(isSku) && tdColSpan
                        ? userInput.sku.label
                          ? userInput.sku.label
                          : cms("label.sku")
                        : ""}
                    </th>
                  )}
                  <th>{userInput.quantity.label || cms("label.qty")}</th>
                  {userInput.price && !userInput.price.isHide ? (
                    <th>{userInput.price.label ? userInput.price.label : cms("label.price")}</th>
                  ) : null}
                  {/* {isDiscount && !userInput.discount.isHide ? (
                    <th>{userInput.discount.label || cms("label.discount")}</th>
                  ) : null} */}
                  {userInput.tax && !userInput.tax.isHide ? (
                    <th>
                      {userInput.tax.label || (taxesIncluded ? cms("label.includeTax") : cms("label.excludeTax"))}
                    </th>
                  ) : null}
                  {userInput.subTotal && !userInput.subTotal.isHide && (
                    <th>{userInput.subTotal.label ? userInput.subTotal.label : cms("label.subTotal")}</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {orderLineItems &&
                  orderLineItems.map((item) => (
                    <tr>
                      <td>{item.name}</td>
                      {productsList(isSku) && userInput.sku && !userInput.sku.isHide && (
                        <td>{tdColSpan && isSku && item.sku}</td>
                      )}
                      <td>{item.fulfillableQuantity || item.actualQuantity - item.fulfillableQuantity}</td>
                      {userInput.price && !userInput.price.isHide ? (
                        <td>{`${orderData.moneyFormat}${baseHelper.getPrice(getItemPrice(constant.PRICE, item))} `}</td>
                      ) : null}
                      {/* {isDiscount && !userInput.discount.isHide ? (
                        <td>
                          {`${
                            tdColSpan2 &&
                            orderData.moneyFormat +
                              baseHelper.getPrice(
                                item.discountAllocations.length
                                  ? item.discountAllocations.map((discount) => {
                                      return discount.amount;
                                    })
                                  : 0
                              )
                          }`}
                        </td>
                      ) : null} */}

                      {userInput.tax && !userInput.tax.isHide ? (
                        <td>{`${orderData.moneyFormat}${baseHelper.getPrice(getTaxValue(item))}`} </td>
                      ) : null}

                      {userInput.subTotal && !userInput.subTotal.isHide ? (
                        <td>
                          {`${orderData.moneyFormat}${baseHelper.getPrice(getItemPrice(constant.SUBTOTAL, item))}`}
                        </td>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="invoice-price">
            <div className="invoice-price-left">
              <div>
                <div className="invoice-price-row">
                  {userInput.totalTax && !userInput.totalTax.isHide ? (
                    <div className="sub-price">
                      <small className="label">
                        {userInput.totalTax && userInput.totalTax.label
                          ? userInput.totalTax.label
                          : cms("label.orderAmount")}
                      </small>
                      <span className="text-inverse">
                        {`${orderData.moneyFormat}${baseHelper.getPrice(
                          (taxesIncluded ? null : totalTaxData) + totalpriceData
                        )}`}
                      </span>
                    </div>
                  ) : null}

                  {isShipping && !userInput.totalShipping.isHide && !userInput.totalTax.isHide ? (
                    <div className="sub-price">
                      <i className="fa fa-plus" />
                    </div>
                  ) : null}
                  {isShipping && !userInput.totalShipping.isHide && (
                    <div className="sub-price">
                      <small className="label">
                        {userInput.totalShipping && userInput.totalShipping.label
                          ? userInput.totalShipping.label
                          : cms("label.shipping")}
                      </small>
                      <span>
                        {isShipping && `${tdColSpan1 && orderData.moneyFormat + baseHelper.getPrice(shippingAmount)}`}
                      </span>
                    </div>
                  )}
                  {/* {(isDiscount && !userInput.totalDiscount.isHide && !userInput.totalTax.isHide) ||
                  (isDiscount &&
                    !userInput.totalDiscount.isHide &&
                    !userInput.totalShipping.isHide &&
                    userInput.totalTax.isHide) ? (
                      <div className="sub-price">
                        <i className="fa fa-minus" />
                      </div>
                  ) : null} */}
                  {/* {isDiscount && !userInput.totalDiscount.isHide ? (
                    <div className="sub-price">
                      <small className="label">
                        {isDiscount && userInput.totalDiscount.label
                          ? userInput.totalDiscount.label
                          : cms("label.totalDiscount")}
                      </small>
                      <span className="text-inverse">
                        {isDiscount &&
                          `${tdColSpan2 && orderData.moneyFormat + baseHelper.getPrice(totalDiscountData)}`}
                      </span>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </div>
            {userInput.total && !userInput.total.isHide ? (
              <div className="invoice-price-right">
                <small className="text-upper">
                  {userInput.total.label ? userInput.total.label : cms("label.total")}
                </small>

                <span className="text-inverse">{`${orderData.moneyFormat}${baseHelper.getPrice(totalData)}`}</span>
              </div>
            ) : null}
          </div>
          {/* end invoice-price */}
        </div>
        {/* end invoice-content */}
        {/* invoice-note */}

        <div className="invoice-footer-content">
          {userInput.orderFulfilledBy && !userInput.orderFulfilledBy.isHide ? (
            <div className="note">
              <span className="text-uppercase note-label">
                {userInput.orderFulfilledBy.label || cms("label.fulfillBy")}
              </span>
              <address style={{ fontSize: "14px" }}>
                {[currentUser.brandName, <br />]}
                {currentUserAddress.address ? [currentUserAddress.address, <br />] : ""}
                {currentUserAddress.city ? [currentUserAddress.city, <br />] : ""}
                {currentUserAddress.pinCode ? [currentUserAddress.pinCode, <br />] : ""}
                {currentUserAddress.country}
              </address>
            </div>
          ) : null}
          {viewOrderData.note && (
            <div className="note">
              <span className="text-uppercase">{cms("label.note")}</span>
              <br />
              <span className="note-font">{viewOrderData.note}</span>
            </div>
          )}
        </div>

        {/* invoice-footer */}

        <div className="invoice-footer">{cms("label.footer")}</div>
      </StyledSlip>
    </>
  );
};
OrderSlipView.defaultProps = {
  fulfillment: () => {},
  logo: "",
  orderData: {},
  orderLineItems: [],
  sellerInfo: {},
  vendorInfo: {},
};
OrderSlipView.propTypes = {
  fulfillment: PropTypes.func,
  logo: PropTypes.string,
  orderData: PropTypes.shape(PropTypes.object),
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
  sellerInfo: PropTypes.shape(PropTypes.object),
  vendorInfo: PropTypes.shape(PropTypes.object),
};

export default OrderSlipView;
