import React from "react";
import constant from "lib/constant/constant";
import Information from "app/productLake/modules/provider/features/add/subFeatures/form/subFeatureItem/information";

const addData = (cms) => {
  return {
    options: [
      // {
      //   label: cms("label.connect"),
      //   value: constant.WOOCOMMERCE,
      // },
      {
        label: cms("label.store", { item: constant.SHOPIFY }),
        value: constant.SHOPIFY,
      },
      {
        label: cms("label.store", { item: constant.BIGCOMMERCE }),
        value: constant.BIGCOMMERCE,
      },
      // {
      //   label: cms("section.connect.label.wix"),
      //   value: constant.WIX,
      // },
      {
        label: cms("label.form"),
        value: constant.CREATE_FORM,
      },
      // {
      //   label: cms("label.service"),
      //   value: constant.SERVICE,
      // },
      // {
      //   label: cms("label.csv"),
      //   value: constant.CREATE_CSV,
      //   disabled: !!isOnboarding,
      // },
    ],
    tabs: [
      {
        id: "information",
        content: cms("label.information"),
        panelID: "information-content",
        PanelComponent: (selectedTab) => <Information selectedTab={selectedTab} />,
      },
      {
        id: "inventory",
        content: cms("label.inventory"),
        panelID: "inventory-content",
      },
      {
        id: "image",
        content: cms("label.image"),
        panelID: "image-content",
      },
      {
        id: "variant",
        content: cms("label.variant"),
        panelID: "variant-content",
      },
      {
        id: "fulfillment",
        content: cms("label.fulfillment"),
        panelID: "fulfillment-content",
      },
      // {
      //   id: "attachment",
      //   content: cms("label.attachment"),
      //   panelID: "attachment-content",
      // },
    ],
  };
};

export default addData;

export const getPanelComponent = (selectedTab, cms) => {
  return addData(cms).tabs[selectedTab].PanelComponent(selectedTab);
};
