import React from "react";
import { SkeletonBodyText, Icon } from "@shopify/polaris";
import { SettingsMajor } from "@shopify/polaris-icons";

const settingIcon = <Icon source={SettingsMajor} color="base" />;

const adminTabs = (cms, isLoading) => [
  {
    id: "home",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.home"),
    panelID: "homeContent",
    link: "/",
    activeRoutes: [],
  },
  // {
  //   id: "Invites",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.invitations"),
  //   panelID: "inviteContent",
  //   link: "/invitation",
  //   activeRoutes: [],
  // },
  // {
  //   id: "Orders",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.orders"),
  //   panelID: "ordersContent",
  //   link: "/orders",
  //   activeRoutes: ["/orders/view/:id"],
  // },
  // {
  //   id: "Products",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.products"),
  //   panelID: "productsContent",
  //   link: "/products",
  //   activeRoutes: [
  //     "/products/add",
  //     "/products/bulk/edit",
  //     "/variants/add/:productId",
  //     "/products/edit/:id",
  //     "/variants/edit/:productId/:id",
  //     "/products/view/:id",
  //     // "/v1/products/add",
  //     // "/v1/products/edit/:id",
  //   ],
  // },
  // {
  //   id: "Payments",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.payments"),
  //   panelID: "paymentsContent",
  //   link: "/payments",
  //   activeRoutes: [],
  // },
  {
    id: "Vendors",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.providers"),
    panelID: "vendorsContent",
    link: "/providers",
    activeRoutes: ["/providers/add", "/providers/view/:id", "/provider/review/:id"],
  },
  {
    id: "Sellers",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.admin.operators"),
    panelID: "sellersContent",
    link: "/operators",
    activeRoutes: ["/operators/view/:id"],
  },
  {
    id: "Settings",
    content: (isLoading && <SkeletonBodyText lines={1} />) || settingIcon,
    panelID: "settingsContent",
    link: "/setting",
    activeRoutes: [
      "/activity/bulk",
      "/association",
      "/domain",
      "/email-template",
      "/feature",
      "/maintenance",
      "/onboard-activity",
      "/payouts",
      "/profile",
      "/session",
      "/activity/view/:id",
      "setting/provider/access",
    ],
  },
];

export default adminTabs;
