const standardList = (cms) => [
  {
    code: "marketplace",
    title: "Analytics",
    description: "This page will display the reports based on product, order and customer data.",
    thumbnail: cms("provider.thumbnail"),
    list: [
      {
        title: "Analytics",
        description: "View your order and product report here.",
        icon: "fal fa-shopping-cart fa-lg",
        code: "order",
        actions: [
          {
            // label: "Order Analytics",
            label: "Analytics Report",
            // link: "analytics/order-analytics",
            link: "analytics/report",
            code: "orderSettings",
            description: "Display report based on order and product analytics.",
          },
        ],
      },
      // {
      //   title: "Products",
      //   description: "View your product report here.",
      //   icon: "fal fa-tag fa-lg",
      //   code: "product",
      //   actions: [
      //     {
      //       label: "Product Analytics",
      //       link: "analytics/product-analytics",
      //       code: "orderSettings",
      //       description: "Display report based on product analytics.",
      //     },
      //   ],
      // },
    ],
  },
];

export default standardList;
