import React, { useContext, useState, useEffect } from "react";

import {
  Caption,
  Card,
  Collapsible,
  Frame,
  Layout,
  PageActions,
  RadioButton,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

import constant from "lib/constant/constant";
import { GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";
import { UPDATE_PACKING_SLIP_ACCESS } from "app/setup/apollo/mutations";
import { GET_PACKING_SLIP_ACCESS } from "app/setup/apollo/queries";
import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Toast, Spinner } from "lib/components";
import { withErrorBoundary, withFeature } from "lib/hoc";

import Add from "./add";

function PackingSlipAccess() {
  const { cms, history } = useContext(PrivateContext);
  const [enableAllVendor, setEnableAllVendor] = useState(true);
  const [open, setOpen] = useState(false);
  const [isAllVendor, setIsAllVendor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedVendor, setIsSelectedVendor] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingAction, setLoadingAction] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [message, setMessage] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  // eslint-disable-next-line no-shadow
  const handleToggle = () => setOpen((open) => !open);
  const { error: errorVendorBrandList, loading: loadingVendorBrandList, data: dataVendorBrandList } = useQuery(
    GET_VENDOR_BRAND_LIST
  );

  const vendorBrandListResponseData = baseHelper.getResponseData(
    dataVendorBrandList,
    constant.gql.GET_VENDOR_BRAND_LIST
  );
  const vendorBrandListErrorData = baseHelper.getResponseError(dataVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);

  useEffect(() => {
    if (vendorBrandListResponseData) {
      const vendorNameList =
        vendorBrandListResponseData &&
        vendorBrandListResponseData.map((value) => {
          return {
            label: value.brandName,
            value: value._id,
          };
        });
      if (vendor && vendor.length) {
        let vendorSelected = [];
        vendorSelected = vendor.map((data) => {
          return data.vendorId.length && data.vendorId;
        });
        const updatedVendorList = vendorNameList.map((item) => {
          const { value = "" } = item;
          const vendorData = { ...item };
          vendorData.disabled = vendorSelected.includes(value);
          return vendorData;
        });
        setVendorList(updatedVendorList || []);
      } else {
        setVendorList(vendorNameList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorBrandListResponseData, vendor]);

  const {
    error: errorGetPackingSlipAccess,
    loading: loadingGetPackingSlipAccess,
    data: dataGetPackingSlipAccess,
    refetch,
  } = useQuery(GET_PACKING_SLIP_ACCESS);

  const packingSlipResponseData = baseHelper.getResponseData(
    dataGetPackingSlipAccess,
    constant.gql.GET_PACKING_SLIP_ACCESS
  );
  const packingSlipErrorData = baseHelper.getResponseError(
    dataGetPackingSlipAccess,
    constant.gql.GET_PACKING_SLIP_ACCESS
  );

  useEffect(() => {
    if (packingSlipResponseData) {
      const { isAllVendor: allVendor, isEnabled, selectedVendor, updatedAt: lastUpdatedAt } = packingSlipResponseData;
      const vendors = selectedVendor && selectedVendor.map((value) => ({ vendorId: value }));
      if (isEnabled !== null) {
        // eslint-disable-next-line no-unused-expressions
        allVendor
          ? setIsAllVendor(allVendor && !isEnabled)
          : setIsAllVendor(!((vendors && vendors.length) || isEnabled));
      }
      setIsSelectedVendor(vendors && vendors.length);
      setVendor(vendors || []);
      setUpdatedAt(lastUpdatedAt);
      // eslint-disable-next-line no-unused-expressions
      isEnabled === null ? setEnableAllVendor(true) : setEnableAllVendor(isEnabled);
    }
  }, [packingSlipResponseData]);

  useEffect(() => {
    if (vendorBrandListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorBrandListErrorData, isOpen: true });
    }
    if (errorVendorBrandList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorBrandList), isOpen: true });
    }
    if (packingSlipErrorData) {
      setBanner({ status: constant.CRITICAL, title: packingSlipErrorData, isOpen: true });
    }
    if (errorGetPackingSlipAccess) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetPackingSlipAccess), isOpen: true });
    }
  }, [vendorBrandListErrorData, errorVendorBrandList, packingSlipErrorData, errorGetPackingSlipAccess]);

  const [updatePackingSlipAccess] = useMutation(UPDATE_PACKING_SLIP_ACCESS);

  const onSubmit = async () => {
    let formData;
    if (!enableAllVendor) {
      setIsLoading(true);
      const selectedVendor = vendor.map(({ vendorId }) => vendorId);
      formData = {
        isAllVendor: isAllVendor || false,
        isEnabled: enableAllVendor || false,
        selectedVendor: (!isAllVendor && selectedVendor) || [],
      };
    } else {
      setLoadingAction(true);
      formData = {
        isEnabled: true,
      };
    }

    try {
      const response = await updatePackingSlipAccess({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_PACKING_SLIP_ACCESS);
      setIsLoading(false);
      setLoadingAction(false);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      // eslint-disable-next-line no-unused-expressions, no-nested-ternary
      !enableAllVendor
        ? isAllVendor
          ? setMessage(cms("section.access.message.success.disable"))
          : setMessage(cms("section.access.message.success.selectVendor"))
        : setMessage(cms("section.access.message.success.enable"));
      setBanner({ isOpen: false, title: "", status: "" });
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  const handleAllVendorCheckBox = (value) => {
    if (isSelectedVendor) {
      setIsSelectedVendor(false);
      setVendor([]);
    }
    if (enableAllVendor) {
      setEnableAllVendor(false);
    }
    setIsAllVendor(!value);
    setSubmitEnable(!value);
  };

  const handleSelectVendorCheckBox = (value) => {
    if (isAllVendor) {
      setIsAllVendor(false);
    }
    if (enableAllVendor) {
      setEnableAllVendor(false);
    }
    setIsSelectedVendor(!value);
    setSubmitEnable(!value);
  };

  const handleisEnableCheckBox = (value) => {
    if (isAllVendor) {
      setIsAllVendor(false);
    }
    if (isSelectedVendor) {
      setIsSelectedVendor(false);
      setVendor([]);
    }
    setEnableAllVendor(!value);
    setSubmitEnable(!value);
  };

  if (loadingVendorBrandList || loadingGetPackingSlipAccess) {
    return <Spinner />;
  }

  const handleSubmit = () => {
    let isSubmit = true;
    if (vendor) {
      vendor.forEach((element) => {
        if (!element.vendorId && isSelectedVendor) {
          setBanner({
            isOpen: true,
            title: cms("section.access.message.error.row"),
            status: constant.CRITICAL,
          });
          isSubmit = false;
        }
      });
    }
    if (vendor.length === 0 && isSelectedVendor) {
      setBanner({
        isOpen: true,
        title: cms("section.access.message.error.vendor"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      setIsLoading(true);
      onSubmit();
    }
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("section.access.title")} description={cms("section.access.description")}>
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
        <Card
          sectioned
          title={[
            cms("section.access.card.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          actions={[
            {
              content: open ? cms("common.label.hide") : cms("common.label.show"),
              onAction: () => handleToggle(),
              disclosure: open ? "up" : "down",
            },
          ]}
        >
          <div className="toggle-action">
            <TextStyle variation="subdued">{cms("section.access.card.subTitle1")}</TextStyle>
          </div>

          <Collapsible open={open}>
            <Stack vertical fill>
              <RadioButton
                label={cms("section.access.label.enable")}
                checked={enableAllVendor}
                onChange={(newChecked) => handleisEnableCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("section.access.label.disable")}
                checked={isAllVendor}
                onChange={(newChecked) => handleAllVendorCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("section.access.label.selectVendor")}
                checked={isSelectedVendor}
                onChange={(newChecked) => handleSelectVendorCheckBox(!newChecked)}
              />
              {isSelectedVendor && (
                <Add
                  vendorList={vendorList}
                  isSelectedVendor={isSelectedVendor}
                  vendor={vendor}
                  setVendor={setVendor}
                  setSubmitEnable={setSubmitEnable}
                />
              )}
            </Stack>
            {updatedAt && (
              <div className="manage-link">
                <br />
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => handleSubmit(),
                    disabled: !submitEnabled,
                    loading: isLoading,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
            {!updatedAt && (
              <PageActions
                primaryAction={{
                  content: cms("common.button.submit"),
                  onAction: () => handleSubmit(),
                  disabled: !submitEnabled,
                  loading: isLoading,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            )}
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}

export default withFeature(withErrorBoundary(PackingSlipAccess), {
  feature: constant.ORDER_PACKING_SLIP_ACCESS,
});
