import { gql } from "apollo-boost";

const GET_ORDER_METRICS = gql`
  query getOrderMetrics($input: GetOrderMetrics) {
    getOrderMetrics(input: $input) {
      data {
        order {
          month
          count
        }
      }
      error
      status
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export { GET_ORDER_METRICS };
