import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo";

import {
  Modal,
  Stack,
  Select,
  TextField,
  Checkbox,
  Popover,
  OptionList,
  Button,
  List,
  DisplayText,
} from "@shopify/polaris";

import Banner from "lib/components/banner/banner";
import AutoComplete from "lib/components/autocomplete/autocomplete";
import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";

import { PrivateContext } from "lib/context";
import { errorHelper } from "lib/helpers";
import { ADD_RULE } from "app/shipping/apollo/mutations";
import { addProp } from "app/shipping/modules/provider/features/subFeatures/advance/rules/props";

// import advance shipping context
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

const AddNewRule = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { cms } = useContext(PrivateContext);
  const { addNewRuleModal, setAddNewRuleModal, destinations, band, products, refetch, moneyFormat } = props;
  const [addRule, { loading: addRuleLoading }] = useMutation(ADD_RULE);
  const { value: cValue, gql } = constant;
  const [destinationCodes, setDestinationCodes] = useState([]);
  const [isShippingDisabled, setIsShippingDisabled] = useState(false);
  const [shippingBandId, setShippingBand] = useState("");
  const [condition, setCondition] = useState("");
  const [priceType, setPriceType] = useState("");
  const [popoverActive, setPopoverActive] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [ruleBanner, setRuleBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });

  const option = {
    priceTypeOptions: [
      { label: cms("label.fixed"), value: constant.FIXED },
      { label: cms("modal.rule.add.label.free"), value: constant.FREE },
    ],
    conditionOptions: [
      { label: cms("modal.rule.add.label.destination"), value: constant.DESTINATION },
      { label: cms("modal.rule.add.label.product"), value: constant.PRODUCT },
    ],
  };

  const toggleBanner = () => {
    setRuleBanner({ isOpen: false });
  };
  const togglePopoverActive = () => {
    setPopoverActive(!popoverActive);
  };
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {cms("modal.rule.add.button.selectRegion")}
    </Button>
  );
  const handleShippingBand = (value) => {
    setShippingBand(value);
  };
  const handleShippingDestination = (value) => {
    setDestinationCodes(value);
  };
  const handleChange = () => {
    setAddNewRuleModal(!addNewRuleModal);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const handleProductChange = (value) => {
    setProductIds(value);
  };
  const handleSelectPriceType = (value) => {
    setPriceType(value);
  };
  const handleSelectShippingRule = (region) => {
    setCondition(region);
  };
  const handleFixedValue = (value) => {
    setPrice(value);
  };
  const addShippingBand = () => {
    const data = {
      condition,
      destinationCodes,
      shippingBandId,
      productIds,
      price: parseFloat(price),
      priceType,
      description,
    };
    addRule({ variables: { input: data } })
      .then((response) => {
        const responseError = baseHelper.getResponseError(response.data, gql.CREATE_SHIPPING_RULE);
        if (responseError) {
          setRuleBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }
        refetch();
        setBannerOuter({
          isOpen: true,
          title: cms("modal.rule.add.message.success.successfullyAdded"),
          status: "success",
        });
        setAddNewRuleModal(false);
      })
      .catch((exception) => {
        setRuleBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };
  const checkValidation = () => {
    if (!shippingBandId) {
      return cms("message.error.selectShippingBand");
    }
    if (!condition) {
      return cms("message.error.selectShippingCondition");
    }
    if (condition === constant.DESTINATION && !(destinationCodes && destinationCodes.length)) {
      return cms("message.error.selectAtleastOneDestination");
    }
    if (condition === constant.PRODUCT && !(productIds && productIds.length)) {
      return cms("message.error.selectAtleastOneProduct");
    }
    if (!description) {
      return cms("message.error.addDescriptionForRule");
    }
    if (description.length > 255) {
      return cms("message.error.descriptionCharacter");
    }
    if (!priceType) {
      return cms("message.error.selectShippingRuleType");
    }
    if (priceType !== constant.FREE && !price) {
      return cms("message.error.provideCost");
    }
    if (priceType !== constant.FREE && price < 0) {
      return cms("message.error.provideValidPrice");
    }
    return false;
  };

  const handleSubmit = () => {
    const hasError = checkValidation();
    if (hasError) {
      setRuleBanner({
        isOpen: true,
        title: hasError,
        status: constant.CRITICAL,
      });
      return false;
    }
    addShippingBand();
    return true;
  };
  const togglePopover = () => setPopoverActive(!popoverActive);

  const renderRegion = () => {
    const list = destinations.map((item) => {
      const { title, options = [] } = item;
      const selectedOptions = options.filter((key) => destinationCodes.includes(key.value));
      if (!selectedOptions.length) {
        return null;
      }
      return (
        <List.Item key={`${item.title}${selectedOptions.length}`}>
          {title}
          <List>
            {selectedOptions.map((key) => (
              <List.Item key={key.value}>{key.label}</List.Item>
            ))}
          </List>
        </List.Item>
      );
    });
    const destinationList = list.filter((item) => item);

    if (!destinationList.length) {
      return null;
    }

    return (
      <Stack>
        <Stack.Item>
          <DisplayText size={constant.SMALL}>{`${cms("modal.rule.add.label.selected")}:`}</DisplayText>
        </Stack.Item>
        <Stack.Item>
          <List>{destinationList}</List>
        </Stack.Item>
      </Stack>
    );
  };

  if (!isVendorAllowed) {
    return null;
  }

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <>
      <Modal
        open={addNewRuleModal}
        onClose={handleChange}
        title={cms("modal.rule.add.title")}
        primaryAction={{
          content: cms("modal.rule.add.button.primary"),
          loading: addRuleLoading,
          onAction: handleSubmit,
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              {ruleBanner.isOpen && (
                <Banner
                  isOpen={ruleBanner.isOpen}
                  title={ruleBanner.title}
                  message={ruleBanner.message}
                  status={ruleBanner.status}
                  onDismiss={toggleBanner}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <Select
                label={cms("modal.rule.add.label.selectShippingBand")}
                placeholder={cms("modal.rule.add.label.selectShippingBand")}
                options={band}
                onChange={handleShippingBand}
                value={shippingBandId}
              />
            </Stack.Item>
            <Stack.Item>
              <Select
                label={cms("modal.rule.add.label.selectShippingRule")}
                options={option.conditionOptions}
                onChange={handleSelectShippingRule}
                value={condition}
                placeholder={cms("modal.rule.add.label.selectShippingRule")}
              />
            </Stack.Item>
            {condition === constant.PRODUCT && (
              <Stack.Item>
                <AutoComplete
                  label=""
                  key={products.value}
                  values={products}
                  onChange={handleProductChange}
                  selected={productIds}
                  placeholder={cms("modal.rule.add.placeholder.startTyping")}
                  minimumSearchLength={Number(3)}
                />
              </Stack.Item>
            )}

            {condition === constant.DESTINATION && (
              <Stack.Item>
                <Stack.Item>
                  <Popover active={popoverActive} activator={activator} onClose={togglePopover}>
                    <OptionList
                      onChange={(value) => {
                        handleShippingDestination(value);
                      }}
                      sections={destinations}
                      selected={destinationCodes}
                      allowMultiple
                    />
                  </Popover>
                </Stack.Item>
                <Stack.Item>{renderRegion()}</Stack.Item>
              </Stack.Item>
            )}

            <Stack.Item>
              <TextField
                label={`${cms("modal.rule.add.label.description")}*`}
                value={description}
                onChange={handleDescriptionChange}
                placeholder={cms("modal.rule.add.placeholder.description")}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    label={cms("modal.rule.add.label.selectShippingType")}
                    options={option.priceTypeOptions}
                    onChange={handleSelectPriceType}
                    value={priceType}
                    placeholder={cms("modal.rule.add.placeholder.selectShipping")}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      prefix={moneyFormat}
                      label={cms("modal.rule.add.label.amount")}
                      onChange={(value) => {
                        handleFixedValue(acceptOnlyValidInput(value, price));
                      }}
                      value={price.toString() || ""}
                      min={cValue.MIN_PRICE}
                      step={cValue.STEP}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label={cms("modal.rule.add.label.isAvailableForShipping")}
                checked={isShippingDisabled}
                onChange={() => {
                  setIsShippingDisabled(!isShippingDisabled);
                }}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};
AddNewRule.propTypes = addProp.type;
export default AddNewRule;
