import { gql } from "apollo-boost";

const GET_ADVANCE_METAFIELD = gql`
  query getAdvanceMetafieldSetting {
    getAdvanceMetafieldSetting {
      data {
        advanceMetafield {
          customSetting {
            type
            catalogId
            metafields
          }
          isEnabled
          isAll
        }
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_ADVANCE_METAFIELD;
