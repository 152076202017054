import React, { useContext, useState } from "react";
import {
  Banner,
  Card,
  Link,
  Collapsible,
  Caption,
  Layout,
  Stack,
  Heading,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

import { withErrorBoundary } from "lib/hoc";

// import helper
import { baseHelper, storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { ModalPopup, PermissionBanner, Spinner } from "lib/components";
import { PrivateContext } from "lib/context";
import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
import standardList from "./standardConfig";

const ProviderAnalytics = () => {
  const [openSetup, setOpenSetup] = useState({});
  const { cms, currentUser, isLoading, isShopConnected, isShopifyOldVendor } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;

  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  const borderSet = {
    border: "0",
  };

  const iconHeightFix = {
    lineHeight: "2.2em",
  };
  const hiddenCards = ["Billing", "General"];

  if (isLoading) {
    return <Spinner />;
  }

  return (standardList(cms) || []).map((analyticList) => {
    let updateAnalyticList = analyticList.list;
    const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
    if (isEcommerceUser) {
      updateAnalyticList = baseHelper.getAllowedVendorSetting(updateAnalyticList);
    }
    return (
      <>
        {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.banner.description.heading")}
              status="critical"
            >
              <p>
                <br />
                {cms("common.popup.migrate.banner.description.para1")}
                <br />
                <br />
                {cms("common.popup.migrate.banner.description.para2")}
              </p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_VENDOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>
                      <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para1")}
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para2")}
                    </TextContainer>
                  </Banner>
                </TextContainer>
                <br />
                {banner.isOpen && (
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    action={banner.action}
                    onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                    isScrollTop={banner.isOpen}
                  />
                )}
              </Card>
              <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setBanner={setBanner} />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <Layout.AnnotatedSection
          title="Analytics"
          description={[
            <div>
              <TextStyle variation="subdued">{analyticList.description}</TextStyle>
            </div>,
          ]}
        >
          {updateAnalyticList.map((actionList) => {
            const actionData = actionList.actions;
            return (
              !hiddenCards.includes(actionList.title) && (
                <Card>
                  <Card.Header
                    sectioned
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Stack>
                        <i className={actionList.icon} style={iconHeightFix} />
                        <Heading>
                          {actionList.title}
                          <TextStyle variation="subdued">
                            <Caption>{actionList.description}</Caption>
                          </TextStyle>
                        </Heading>
                      </Stack>
                    }
                    actions={[
                      {
                        content: openSetup[actionList.code] ? "Hide" : "Show",
                        onAction: () => handleSetupToggle(actionList.code),
                        disclosure: openSetup[actionList.code] ? "up" : "down",
                      },
                    ]}
                  />
                  <br />

                  <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                    <Card.Section subdued>
                      {actionData.map((step) => {
                        return (
                          <div style={borderSet}>
                            <Card.Section subdued>
                              <Stack>
                                <Stack.Item>
                                  <Link url={step.link}>{step.label}</Link>
                                  <TextStyle variation="">
                                    <Caption>{step.description}</Caption>
                                  </TextStyle>
                                </Stack.Item>
                              </Stack>
                            </Card.Section>
                          </div>
                        );
                      })}
                    </Card.Section>
                  </Collapsible>
                </Card>
              )
            );
          })}
        </Layout.AnnotatedSection>
      </>
    );
  });
};

export default withErrorBoundary(ProviderAnalytics);
