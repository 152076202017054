export const tabs = [
  {
    id: "all-reports",
    content: "All",
    label: "all Metrics",
    accessibilityLabel: "All Reports",
    panelID: "all-reports-content",
    value: "all",
  },
  {
    id: "sales-id",
    content: "Sales",
    label: "Sales Metrics:",
    panelID: "sales-reports",
    value: "sales",
  },
  {
    id: "payments-id",
    content: "Payments",
    label: "Payments Metrics:",
    panelID: "payments-reports",
    value: "payments",
  },
  {
    id: "orders-id",
    content: "Orders",
    label: "Orders Metrics:",
    panelID: "orders-reports",
    value: "orders",
  },
  {
    id: "vendors-id",
    content: "Vendors",
    label: "Vendors Metrics:",
    panelID: "vendors-reports",
    value: "vendors",
  },
  {
    id: "products-id",
    content: "Products",
    label: "Products Metrics:",
    panelID: "products-reports",
    value: "products",
  },
];

export const operatorReportingConfig = {
  all: {
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
  products: {
    filterByOptions: [
      {
        label: "Per Cateogry",
        value: "perCategory",
      },
      {
        label: "Per Vendor",
        value: "perVendor",
      },
      {
        label: "Per combination of vendor and catogory",
        value: "perVendorAndCategory",
      },
    ],
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
  sales: {
    filterByOptions: [
      {
        label: "Per Product",
        value: "perProduct",
      },
      {
        label: "Per Vendor",
        value: "perVendor",
      },
    ],
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
  payments: {
    filterByOptions: [
      {
        label: "Per Product",
        value: "perProduct",
      },
      {
        label: "Per Vendor",
        value: "perVendor",
      },
    ],
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
  orders: {
    filterByOptions: [
      {
        label: "Per Product",
        value: "perProduct",
      },
      {
        label: "Per Vendor",
        value: "perVendor",
      },
    ],
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
  vendors: {
    filterByOptions: [
      {
        label: "Revenue generated per vendor",
        value: "perVendor",
      },
    ],
    timeByOptions: [
      { label: "Select", value: "" },
      {
        label: "Today",
        value: "1",
      },
      {
        label: "Last week",
        value: "7",
      },
      {
        label: "Last month",
        value: "30",
      },

      {
        label: "Last 3 months",
        value: "90",
      },

      {
        label: "Last 6 months",
        value: "180",
      },

      {
        label: "Last 1 year",
        value: "365",
      },
    ],
  },
};
