import styled from "styled-components";

const Div = styled.div`
  user-select: none;
`;

const Span = styled.span`
  &:hover {
    border-style: dashed;
    cursor: pointer;
    border-color: #7290bb;
  }
`;

export { Div, Span };
