// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const QUOTE_APPROVAL = gql`
  mutation quoteApproval($input: quoteApprovalInput!) {
    quoteApproval(input: $input) {
      data
      status
      error
    }
  }
`;

export default QUOTE_APPROVAL;
