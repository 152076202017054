export const stripeTextFields = (cms) => {
  return [
    {
      id: "stripeClientId",
      label: `${cms("section.label.clientId")}*`,
      placeholder: cms("section.placeholder.clientId"),
      key: "stripeClientId",
    },
    {
      id: "stripeSecret",
      label: `${cms("section.label.secret")}*`,
      placeholder: cms("section.placeholder.secretPlaceholder"),
      key: "stripeSecret",
    },
  ];
};

export const paypalTextFields = (cms) => {
  return [
    {
      id: "paypalClientId",
      label: `${cms("section.label.clientId")}*`,
      placeholder: cms("section.placeholder.clientId"),
      key: "paypalClientId",
    },
    {
      id: "paypalSecret",
      label: `${cms("section.label.secret")}*`,
      placeholder: cms("section.placeholder.secretPlaceholder"),
      key: "paypalSecret",
    },
  ];
};
