import { gql } from "apollo-boost";

const UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING = gql`
  mutation updateMarkupDiscountExportSetting($input: MarkupDiscountExportSetting) {
    updateMarkupDiscountExportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING;
