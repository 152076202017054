import React, { useContext } from "react";
import {
  Caption,
  Card,
  Collapsible,
  Layout,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const RegisterContent = () => {
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { updatedAt = "" } = currentUser || {};
  const { REGISTER, IS_DEFAULT, TITLE, DESCRIPTION, DEFAULT_REGISTER_DESCRIPTION, DEFAULT_REGISTER_TITLE } = constant;
  const {
    // learnMore
    page,
  } = useContext(BrandingContext);
  const { register, handleChange, resetDefault } = page;

  const registerList = [
    {
      label: cms("section.registerPageContent.label.default"),
      value: true,
      id: "registerDefault",
    },
    {
      label: cms("section.registerPageContent.label.customized"),
      value: false,
      id: "registerCustom",
    },
  ];

  const renderList = () => {
    return registerList.map((list) => (
      <RadioButton
        label={list.label}
        checked={register.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="register"
        onChange={() => handleChange(IS_DEFAULT, list.value, REGISTER)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.registerPageContent.title")}
        description={cms("section.registerPageContent.description")}
      >
        <Card
          title={[
            cms("section.registerPageContent.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          // actions={[
          //   { content: "Learn more", onAction: () => learnMore(cms("section.registerPageContent.title"), "TODO: ") },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.registerPageContent.subDescription")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!register.isDefault} id="custom-register-collapsible">
              <TextField
                id="customTitle"
                label={cms("section.registerPageContent.label.title")}
                value={register.title}
                onChange={(value) => handleChange(TITLE, value, REGISTER)}
                labelAction={
                  register.title !== DEFAULT_REGISTER_TITLE && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(TITLE, DEFAULT_REGISTER_TITLE, REGISTER),
                  }
                }
              />
              <br />
              <TextField
                id="customDescription"
                label={cms("section.registerPageContent.label.description")}
                value={register.description}
                onChange={(value) => handleChange(DESCRIPTION, value, REGISTER)}
                labelAction={
                  register.description !== DEFAULT_REGISTER_DESCRIPTION && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(DESCRIPTION, DEFAULT_REGISTER_DESCRIPTION, REGISTER),
                  }
                }
              />
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(RegisterContent), {
  feature: constant.STANDARD_BRANDING,
});
