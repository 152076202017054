import { gql } from "apollo-boost";

const GET_WHOLESALE_SETTING = gql`
  query {
    getWholesaleSetting {
      data {
        isWholesalePriceVisible
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_WHOLESALE_SETTING;
