import { gql } from "apollo-boost";

const DELETE_PRODUCT_ATTACHMENT = gql`
  mutation deleteProductAttachment($input: DeleteProductAttachment) {
    deleteProductAttachment(input: $input) {
      data
      status
      error
    }
  }
`;
export default DELETE_PRODUCT_ATTACHMENT;
