import styled from "styled-components";

export const StyledPage = styled.div`
  .tabs-content {
    margin-top: 2.5rem;
  }
  .Polaris-Page-Header {
    &.Polaris-Page-Header--hasNavigation {
      display: flex;
      align-items: flex-start;
      .Polaris-Page-Header__Navigation {
        margin-right: 1.5rem;
      }
    }
    .Polaris-Header-Title__TitleMetadata {
      vertical-align: top;
      margin-top: 0.5rem;
    }
  }
  .Polaris-Header-Title {
    line-height: 1;
  }
`;
export const MainHeader = styled.header`
  background-color: ${(props) => props.background || "#fff"};
  .Polaris-TopBar-UserMenu__Details p,
  .brandTitle {
    color: ${(props) => props.color || "#000"};
  }
  .Polaris-TopBar {
    background-color: transparent;
  }
`;
