import React, { useCallback, useState } from "react";
import { Stack, Autocomplete, TextContainer, Tag, FormLayout } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

const AutoComplete = (props) => {
  const {
    options: deselectedOptions,
    minimumSearchLength,
    selectedOptions,
    setSelectedOptions,
    setShippingByVendor,
    shippingByVendor,
    cms,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      if (value.length >= minimumSearchLength) {
        const filterRegex = new RegExp(value, "i");
        const resultOptions = deselectedOptions.filter((option) => option.label && option.label.match(filterRegex));
        setOptions(resultOptions);
      }
    },
    [deselectedOptions, minimumSearchLength]
  );

  const removeTag = useCallback(
    (tag) => () => {
      const option = [...selectedOptions];
      option.splice(option.indexOf(tag), 1);
      if (shippingByVendor && shippingByVendor.selected) {
        const index = shippingByVendor.selected.findIndex((item) => item.vendorId === tag);
        if (index === 0 || index) {
          const updateVendors = shippingByVendor.selected;
          updateVendors.splice(index, 1);
          setShippingByVendor({
            all: null,
            selected: updateVendors,
          });
        }
      }
      setSelectedOptions(option);
    },
    [selectedOptions, shippingByVendor, setSelectedOptions, setShippingByVendor]
  );

  const tagsMarkup = () => {
    const selectedTags = deselectedOptions.filter((item) => selectedOptions.includes(item.value));
    let tagFields = baseHelper.sort(selectedTags, constant.LABEL).map((item) => (
      <Tag key={item.value} onRemove={removeTag(item.value)}>
        {item.label}
      </Tag>
    ));
    tagFields = tagFields.filter((item) => item);
    return <Stack>{tagFields}</Stack>;
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      placeholder={cms("operator.section.chargeShipping.placeholder.typeBrand")}
    />
  );

  return (
    <FormLayout>
      <Autocomplete
        allowMultiple
        options={options}
        selected={selectedOptions}
        textField={textField}
        onSelect={setSelectedOptions}
      />
      <TextContainer>
        <Stack>{tagsMarkup()}</Stack>
      </TextContainer>
    </FormLayout>
  );
};

export default withErrorBoundary(AutoComplete);
