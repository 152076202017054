import { gql } from "apollo-boost";

const GET_PRODUCT_TAG_SETTING = gql`
  query {
    getProductTagSetting {
      error
      status
      data {
        tagSetting {
          tag
          vendor
          type {
            isAll
            value
          }
          category {
            isAll
            value
          }
        }
        updatedAt
      }
    }
  }
`;

export default GET_PRODUCT_TAG_SETTING;
