import React from "react";
import { useQuery } from "react-apollo";
import { Banner, ChoiceList, Modal, Select, Spinner, Stack, TextField } from "@shopify/polaris";

// import ResourceList
import { AutoComplete } from "lib/components";
// props
import { FETCH_PLANS } from "app/plans/apollo/queries";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import feature from "app/setup/modules/admin/features/props";

const FeatureModel = (props) => {
  const {
    CMS,
    editFeatureLoading,
    featureToEdit,
    handleChange,
    handleSellers,
    handleVendors,
    isModalOpen,
    sellers,
    setFeatureToEdit,
    setIsConfirm,
    setIsModalOpen,
    updateFeature,
    vendors,
  } = props;

  const versionNames = [
    { label: constant.featureVersion.alpha, value: constant.featureVersion.alpha },
    { label: constant.featureVersion.beta, value: constant.featureVersion.beta },
    { label: constant.featureVersion.live, value: constant.featureVersion.live },
  ];

  const { button, label, placeHolder, users } = CMS;
  const { data: planData, error: planError, loading: planLoading } = useQuery(FETCH_PLANS);
  const planCode = baseHelper.getResponseData(planData, "getPlans") || [];

  const plans = Object.keys(planCode).map((item) => ({
    value: planCode[item].name,
    label: planCode[item].name,
  }));
  if (planLoading) {
    return <Spinner />;
  }
  if (planError) {
    const parsedError = errorHelper.parse(planError);
    return <Banner isOpen status={constant.CRITICAL} title={parsedError} />;
  }

  const { user = {} } = featureToEdit;
  const { group = {} } = user;
  const { seller = [], vendor = [] } = group;
  const allowedSellers = sellers
    .filter((item) => !!seller.find((sellerRow) => item.value === sellerRow))
    .map((item) => item.value);
  const allowedVendors = vendors
    .filter((item) => !!vendor.find((vendorRow) => item.value === vendorRow))
    .map((item) => item.value);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setIsConfirm(false);
      }}
      title={`${label.configure} ${featureToEdit.label} ${label.settings}`}
      primaryAction={{
        content: button.primaryAction,
        onAction: updateFeature,
        loading: editFeatureLoading,
      }}
    >
      <Modal.Section>
        <div id="featureModal">
          <Stack vertical>
            <TextField
              id="label"
              label={label.label}
              value={featureToEdit.label}
              onChange={(value) => handleChange("label", value)}
            />
            <TextField
              id="description"
              label={label.description}
              value={featureToEdit.description}
              onChange={(value) => handleChange("description", value)}
            />
            <Select
              id="selectVersion"
              label={label.version}
              options={versionNames}
              onChange={(value) => [handleChange("version", value), setIsConfirm(false)]}
              value={featureToEdit.version}
            />
            <ChoiceList
              id="hideFrom"
              title={label.hideFromColon}
              onChange={(updated) => {
                const updatedFeature = { ...featureToEdit };
                updatedFeature.hide.permission = [...updated];
                setFeatureToEdit(updatedFeature);
                setIsConfirm(false);
              }}
              choices={users}
              selected={(featureToEdit.hide && featureToEdit.hide.permission) || ""}
              allowMultiple
            />
            <ChoiceList
              id="allowedFor"
              title={label.allowedForColon}
              onChange={(updated) => {
                const updatedFeature = { ...featureToEdit };
                updatedFeature.user.permission = [...updated];
                setFeatureToEdit(updatedFeature);
                setIsConfirm(false);
              }}
              choices={users}
              selected={(featureToEdit.user && featureToEdit.user.permission) || ""}
              allowMultiple
            />
            <ChoiceList
              id="allowedTier"
              title={label.allowedTierColon}
              onChange={(updated) => {
                const updatedFeature = { ...featureToEdit };
                updatedFeature.user.tier = [...updated];
                setFeatureToEdit(updatedFeature);
                setIsConfirm(false);
              }}
              choices={plans}
              selected={featureToEdit.user.tier}
              allowMultiple
            />
            <Stack vertical>
              <Stack.Item>
                <AutoComplete
                  id="sellersAllowed"
                  label={label.sellersAllowed}
                  placeholder={placeHolder.sellersAllowed}
                  values={sellers}
                  options={() => {}}
                  selected={allowedSellers}
                  onChange={(val) => handleSellers(val, featureToEdit)}
                  minimumSearchLength={3}
                />
              </Stack.Item>
              <Stack.Item>
                <AutoComplete
                  id="vendorsAllowed"
                  label={label.vendorsAllowed}
                  placeholder={placeHolder.vendorsAllowed}
                  values={vendors}
                  selected={allowedVendors}
                  onChange={(val) => handleVendors(val, featureToEdit)}
                  minimumSearchLength={3}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </Modal.Section>
    </Modal>
  );
};
FeatureModel.propTypes = feature.type;

export default FeatureModel;
