/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Card, Layout, ChoiceList, Tag } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import layout component
import { SingleColumnLayout } from "layout/private/components";

import { ResourceList, Banner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import productListCMS from "app/productLake/modules/generic/feature/list/cms/productListCMS";

// GQL
import { GET_PRODUCT_LAKE_LIST } from "app/productLake/apollo/queries";
import { BULK_PRODUCT_LAKE } from "app/productLake/apollo/mutations";

// Generic Component.
import { /* RejectProduct */ DeleteProduct, ProductListView } from "app/productLake/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/productLake/modules/generic/moreFilters/moreFilter";
import PRODUCT_LAKE_LIST from "app/productLake/apollo/subscriptions/productLakeList";
import Widget from "./subFeatures/widget";

const ProviderProductLakesList = () => {
  const { cms, history, currentUser } = useContext(PrivateContext);
  const { filterStatus, tabs, sortOptions, resourceName } = productListCMS(cms);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const paramFilter = (prevAppliedFilter.filter && prevAppliedFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const [queryValue, setQueryValue] = useState(searchVal || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const selectedSort = queryParams.sort_name && `${queryParams.sort_name}_${queryParams.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 10);
  const [singleId, setSingleId] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productListData, setProductListData] = useState(false);
  const [productListTotal, setProductListTotal] = useState(0);
  const [selected] = useState(parseInt(queryParams.tab, 10) || 0);
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || "");
  const [listLoading, setListLoading] = useState(false);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const { ALL } = constant;
  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
  }

  const inputData = {
    search: getQueryParams.search,
    sort_name: getQueryParams.sort_name || constant.UPDATE,
    sort_order: getQueryParams.sort_order || constant.DESC,
    page: parseInt(getQueryParams.page || page, 10),
    perPage: parseInt(getQueryParams.perPage || perPage, 10),
    // filter: getQueryParams.filter,
    filter: getQueryParams.filter?.split(","),
    list_filter: getQueryParams.list_filter,
    list_search: getQueryParams.list_search,
  };

  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LAKE_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT_LAKE, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LAKE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedItems([]);
    const productListResponseResponseData = baseHelper.getResponseData(
      dataProductList,
      constant.gql.GET_PRODUCT_LAKE_LIST
    );
    const productListResponseResponseError = baseHelper.getResponseError(
      dataProductList,
      constant.gql.GET_PRODUCT_LAKE_LIST
    );
    if (productListResponseResponseData) {
      setProductListData(productListResponseResponseData.tempProductList);
      setProductListTotal(productListResponseResponseData.count);
    }
    if (productListResponseResponseError) {
      setListLoading(false);
      setBanner({ title: productListResponseResponseError, status: constant.CRITICAL, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setBanner({ title: errorHelper.parse(errorProductList), status: constant.CRITICAL, isOpen: true });
    }
  }, [errorProductList]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: PRODUCT_LAKE_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleQueryValueRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (selectedValue) => {
    const sort = selectedValue.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  // const handleTabChange = (selectedTabIndex) => {
  //   const tabName = tabs[selectedTabIndex].value;
  //   setSelected(selectedTabIndex);
  //   setSelectedItems([]);
  //   setPage(1);
  //   const tabChange = baseHelper.setQueryParams(history.location, {
  //     tab: selectedTabIndex,
  //     filter: tabName,
  //     page: 1,
  //   });
  //   history.push(`${history.location.pathname}?${tabChange}`);
  // };

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };

  const bulkAction = async (eventKey, isDeleteFromStore = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_LAKE_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_LAKE_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: "delete",
      ids: selectedItems,
      // all: productListTotal === selectedItems.length,
      isDeleteFromStore,
      reason,
    };
    if (id) {
      formData.ids = [id];
      // formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }

    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setSelectedItems([]);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT_LAKE);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT_LAKE);
          if (responseData) {
            setBanner({ isOpen: true, title: cms("common.message.success.background"), status: constant.SUCCESS });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleTaggedWithRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "" });
    setTaggedWith("");

    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleFilterByStatusRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { filter: "", page: 1 });
    setFilterByStatus(null);
    history.push(`${history.location.pathname}?${search}`);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value === constant.TITLE ? "titleName" : value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilters.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: handleFilterByStatusRemove,
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove();
    handleSelectedFilterRemove();
    handleFilterByStatusRemove();
  };

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: ALL, page: 1 });
    }
  };

  const filters = [
    {
      key: constant.SELECTED_FILTER,
      // label: cms("label.filterBy"),
      label: constant.FILTER_BY,
      filter: (
        <Filter
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          vendorProductListData={productListData}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      // label: cms("common.label.filterByStatus"),
      label: "Filter By Status",
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: constant.TAGGED_WITH,
      // label: cms("label.filter"),
      label: "Advance Filter",
      filter: (
        <MoreFilters
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        item={item}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        handleDeleteModal={handleDeleteModal}
        listLoading={listLoading}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setBanner}
        active={active}
        setActive={setActive}
        cms={cms}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: cms("label.action"),
    },
  ];
  const bulkActions = [
    {
      // content: cms("label.deleteSelected"),
      content: "Delete Selected",
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
  ];

  const getSecondaryComponent = () => {
    return <Widget setBanner={setBanner} cms={cms} />;
  };

  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              action={banner.action}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
            <br />
          </>
        )}
        {isBigCommerceUser && (
          <>
            <Banner isOpen title={cms("label.requiredField")} status={constant.INFO} />
            <br />
          </>
        )}
        <br />
        {getSecondaryComponent()}
        <Layout>
          <DeleteProduct
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            cms={cms}
            selectedItems={selectedItems}
          />
          <Layout.Section>
            <Card>
              {/* <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} /> */}
              <ResourceList
                resourceName={resourceName}
                items={(productListData && productListData) || []}
                idForItem={(products) => {
                  const { _id: id } = products;
                  return id;
                }}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={(selectValue) => setSelectedItems(selectValue)}
                bulkActions={bulkActions}
                promotedBulkActions={promotedBulkActions}
                loading={loadingProductList}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={handleSort}
                onQueryChange={handleSearchValue}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                filters={filters}
                appliedFilters={appliedFilters}
                queryValue={queryValue}
                count={Number(productListTotal)}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                renderItem={renderItem}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withErrorBoundary(ProviderProductLakesList);
