import { gql } from "apollo-boost";

// for operator
const UPDATE_PHYSICAL_SETTING = gql`
  mutation updateProductPhysicalSetting($input: UpdatePhysicalSetting) {
    updateProductPhysicalSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_PHYSICAL_SETTING;
