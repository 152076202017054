import { gql } from "apollo-boost";

const DELETE_RULE = gql`
  mutation deleteShippingRule($input: DeleteShippingRule!) {
    deleteShippingRule(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_RULE = gql`
  mutation createShippingRule($input: CreateShippingRule!) {
    createShippingRule(input: $input) {
      status
      data
      error
    }
  }
`;

const EDIT_RULE = gql`
  mutation updateShippingRule($input: UpdateShippingRule!) {
    updateShippingRule(input: $input) {
      status
      data
      error
    }
  }
`;

export { DELETE_RULE, ADD_RULE, EDIT_RULE };
