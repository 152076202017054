import { gql } from "apollo-boost";

const UPDATE_PRODUCT_LAKE_IMAGE = gql`
  mutation updateProductLakeImage($input: UpdateImage) {
    updateProductLakeImage(input: $input) {
      data
      error
      status
    }
  }
`;
export default UPDATE_PRODUCT_LAKE_IMAGE;
