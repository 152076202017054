import React, { useState, useEffect, useContext } from "react";
import { Layout, Page } from "@shopify/polaris";
import { useQuery, useLazyQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import from lib
import constant from "lib/constant/constant";
import { Banner, Spinner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { NotFound } from "layout/private/components";

// import cms
import GET_ORDER_DETAILS from "app/orders/apollo/queries/orderSlip";
import OrderSlipView from "app/orders/modules/generic/slip/subFeature/orderSlipView";

import { GET_ADDRESS_DETAILS } from "app/orders/apollo/queries";
import { getOrderView } from "app/orders/apollo/viewOrder";

const ProviderOrderSlip = () => {
  const { match } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;
  const defaultLogo = constant.MARKET_CUBE;
  const [sellerInfo, setSellerInfo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [isPackingSlipFound, setIsPackingSlipFound] = useState(true);
  const [orderData, setOrderData] = useState(false);
  const [viewOrderData, setViewOrderData] = useState({});
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });

  const { error: orderDetailsError, loading: orderDetailsLoading, data: orderDetailsData } = useQuery(
    GET_ORDER_DETAILS,
    {
      variables: { input: { id } },
    }
  );

  const { error: viewOrderDetailsError, loading: viewOrderDetailsLoading, data: viewOrderDetailsData } = useQuery(
    getOrderView,
    {
      variables: { input: { id } },
    }
  );

  const [getSellerAddress, { data: sellerAddressData }] = useLazyQuery(GET_ADDRESS_DETAILS);

  const imageToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  // getOrderSlipDetails
  useEffect(() => {
    const orderSlipResponseData = baseHelper.getResponseData(orderDetailsData, constant.gql.GET_ORDER_DETAILS);
    const orderSlipResponseError = baseHelper.getResponseError(orderDetailsData, constant.gql.GET_ORDER_DETAILS);

    const viewOrderSlipResponseData = baseHelper.getResponseData(viewOrderDetailsData, constant.gql.GET_ORDER_VIEW);
    const viewOrderSlipResponseError = baseHelper.getResponseData(viewOrderDetailsData, constant.gql.GET_ORDER_VIEW);

    if (orderSlipResponseData) {
      setIsPackingSlipFound(true);
      const { isNotFound = false } = orderSlipResponseData || {};
      setNotFound(isNotFound);
      const { orderDataRow, orderLineItemsData = [], seller } = orderSlipResponseData;
      if (seller && seller.plan && seller.plan.isBranding) {
        imageToDataURL(seller.logo, (url) => {
          setOrderData({ ...orderDataRow });
          setOrderLineItems(orderLineItemsData);
          setSellerInfo(seller);
          setLogo(seller.logo && url);
        });
      } else {
        imageToDataURL(defaultLogo, (url) => {
          setOrderData({ ...orderDataRow });
          setOrderLineItems(orderLineItemsData);
          setSellerInfo(seller);
          setLogo(url);
        });
      }
    }
    if (orderSlipResponseError) {
      setIsPackingSlipFound(false);
      setNotFound(true);
      setBanner({ isOpen: true, title: orderSlipResponseError, status: constant.CRITICAL });
    }

    if (viewOrderSlipResponseData) {
      setViewOrderData(viewOrderSlipResponseData);
    }
  }, [defaultLogo, orderDetailsData, viewOrderDetailsData]);
  // end

  useEffect(() => {
    if (orderDetailsError) {
      setIsPackingSlipFound(false);
      setBanner({ isOpen: true, title: errorHelper.parse(orderDetailsError), status: constant.CRITICAL });
    }
  }, [orderDetailsError]);

  if (notFound) {
    return (
      <div className="Polaris-Page__OrderSlip">
        <NotFound />
      </div>
    );
  }

  if (orderDetailsLoading || !(sellerInfo && orderData)) {
    return <Spinner isFullPage />;
  }

  return (
    <div className="packing-slip">
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            message={banner.message}
            onDismiss={() => {
              setBanner({ isOpen: false, title: "", status: "" });
            }}
          />
        </Layout.Section>
      )}
      {isPackingSlipFound && sellerInfo && orderData && (
        <OrderSlipView
          sellerInfo={sellerInfo}
          orderData={orderData}
          logo={logo}
          orderLineItems={orderLineItems}
          sellerAddressData={sellerAddressData}
          getSellerAddress={getSellerAddress}
          viewOrderData={viewOrderData}
        />
      )}
    </div>
  );
};

export default withFeature(withErrorBoundary(ProviderOrderSlip), { feature: constant.ORDER_PACKING_SLIP });
