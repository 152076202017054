import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const { labels = [], datasets = [] } = data;
  if (labels && labels.length && datasets && datasets.length && datasets[0].data && datasets[0].data) {
    return <Pie data={data} />;
  }
  return false;
};

export default PieChart;
