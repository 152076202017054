import React, { useContext } from "react";
import { Card, TextStyle, Stack, Link, Caption, DataTable } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { customerProp } from "app/orders/modules/provider/features/view/propsType";
import { baseHelper } from "lib/helpers";

const Pickup = (props) => {
  const { lineData } = props;
  const { cms } = useContext(PrivateContext);
  let statusHeading = "";

  const getFulfillmentStack = (item) => {
    const { address, city, country, pinCode } = item.consignmentPickAddress || {};
    const { status: hopscotchStatus = "" } = (item && item.hopscotch) || {};
    statusHeading = hopscotchStatus ? "Status" : "";

    return [
      <Stack.Item fill>
        <Link url="">{item.name}</Link>
        <Caption>
          <TextStyle variation="subdued">{`${cms("label.vendor")}: ${item.vendor}`}</TextStyle>
        </Caption>
      </Stack.Item>,

      <Stack.Item>
        {address ? (city && `${address},`) || address : ""}
        <br />
        {city ? (country && `${city},`) || city : ""}
        <br />
        {country ? (pinCode && `${country},`) || country : ""}
        {pinCode ? [<br />, pinCode || ""] : ""}
      </Stack.Item>,

      hopscotchStatus ? <Stack.Item>{baseHelper.ucFirst(hopscotchStatus).replace(/-/g, " ") || ""}</Stack.Item> : "",
    ];
  };

  const fulfillments = lineData
    .filter((item) => item.isVendorFulfilled && item.isConsignmentPick && item.fulfillmentStatus !== constant.FULFILLED)
    .map((selectedItem) => getFulfillmentStack(selectedItem));

  return (
    <div className="add-ellipsis">
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "text", statusHeading ? "numeric" : ""]}
          headings={[cms("label.product"), cms("label.pickupLocation"), statusHeading]}
          rows={fulfillments}
        />
      </Card.Section>
      <Card>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.totalFulfillments")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${fulfillments.length} ${cms("label.of")} ${lineData.length}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

Pickup.propTypes = customerProp.type;
export default Pickup;
