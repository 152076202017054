import styled from "styled-components";

export const StyledModal = styled.div`
  @media (min-width: 800px) {
    .Polaris-Stack {
      flex-wrap: nowrap;
    }
  }
`;

export const FieldSetQty = styled.div`
  .Polaris-TextField__Input {
    max-width: 70px;
  }
`;
