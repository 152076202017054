import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE } from "app/productOld/apollo/queries";
import { GET_CATALOG } from "app/product/apollo/queries";

const AdvanceFilters = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, taggedWith, selectedFilter } = props;
  const { cms, history } = useContext(PrivateContext);

  const { data: getProductTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { data: getProductTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { data: catalogData } = useQuery(GET_CATALOG);

  const filterTag = baseHelper.getResponseData(getProductTagsData, constant.gql.GET_PRODUCT_TAGS) || [];
  const filterType = baseHelper.getResponseData(getProductTypeData, constant.gql.GET_PRODUCT_TYPE) || [];
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || [];

  const typeArray =
    (filterType &&
      filterType.length &&
      filterType.map((item) => {
        return item.label;
      })) ||
    [];

  const collectionsArray = productCatalogOption?.catalog
    ? productCatalogOption.catalog.map((item) => {
        const { title = "", storeCatalogId = 0 } = item || {};
        const optionData = {
          value: title,
          label: title,
          id: storeCatalogId.toFixed(1).toString(),
        };
        return optionData;
      })
    : [];

  const filterStatus = [
    { label: cms("label.new"), value: cms("label.new").toLowerCase() },
    { label: cms("label.review"), value: cms("label.review").toLowerCase() },
    { label: cms("label.approve"), value: cms("label.approve").toLowerCase() },
    { label: cms("label.reject"), value: cms("label.reject").toLowerCase() },
    { label: cms("label.delete"), value: cms("label.delete").toLowerCase() },
  ];

  const handleSelectChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { filter: value });
  };

  const handleChange = (value) => {
    setTaggedWith(value);
    let updatedData = {};
    if (selectedFilter === constant.COLLECTION) {
      const labelset = collectionsArray.filter((item) => {
        return item.value === value;
      });
      const setCatId = (labelset && labelset.length && labelset[0].id) || "";
      updatedData = { list_cat_id: setCatId };
    }

    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value, ...updatedData });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied, ...updatedData });
  };

  return (
    <>
      <FormLayout>
        {(selectedFilter && selectedFilter === constant.TAG.toLowerCase() && (
          <Select
            label={baseHelper.ucFirst(cms("label.Select"))}
            onChange={handleChange}
            value={taggedWith}
            placeholder={cms("label.selectTag")}
            options={filterTag.tags}
          />
        )) ||
          (selectedFilter && selectedFilter === constant.TYPE && (
            <Select
              onChange={handleChange}
              value={taggedWith}
              label={baseHelper.ucFirst(cms("label.Select"))}
              placeholder={cms("label.selectType")}
              options={typeArray}
            />
          )) ||
          (selectedFilter && selectedFilter === constant.COLLECTION && (
            <Select
              onChange={handleChange}
              value={taggedWith}
              label={baseHelper.ucFirst(cms("label.Select"))}
              placeholder={cms("label.selectCollection")}
              options={collectionsArray}
            />
          )) ||
          (selectedFilter && selectedFilter !== constant.STATUS && (
            <TextField label={cms("common.label.like")} value={taggedWith} onChange={handleChange} />
          )) ||
          (selectedFilter === constant.STATUS && (
            <Select
              label={baseHelper.ucFirst(cms("common.label.status"))}
              placeholder={cms("common.label.select")}
              onChange={handleSelectChange}
              options={filterStatus}
            />
          )) ||
          null}
      </FormLayout>
    </>
  );
};

const Filter = (props) => {
  const {
    dataToFetch,
    isLiveConnect = false,
    productList,
    selectedFilter,
    setDataToFetch,
    setSelectedFilter,
    setTaggedWith,
    vendorProductListData,
  } = props;
  const { cms, history, currentUser } = useContext(PrivateContext);

  const filterOptions = isLiveConnect
    ? [{ label: cms("common.label.titleName"), value: constant.TITLES }]
    : [
        { label: cms("common.label.titleName"), value: constant.TITLE },
        { label: cms("label.tag"), value: constant.TAG.toLowerCase() },
        { label: cms("common.label.type"), value: constant.TYPE },
        { label: cms("label.collection"), value: constant.COLLECTION },
        { label: cms("common.label.sku"), value: constant.SKU },
      ];

  const isVendor = baseHelper.isVendor(currentUser);

  if (!isVendor) {
    filterOptions.push({ label: cms("common.label.vendor"), value: constant.VENDOR });
  }
  if (productList || vendorProductListData) {
    filterOptions.push({ label: cms("label.platform"), value: constant.LIVECONNECTED_PLATFORM });
  }
  const handleSelectChange = (value) => {
    let paramValue = value;
    if (value === constant.TITLES) {
      paramValue = constant.TITLE;
    }
    setSelectedFilter(value);
    let listFilter = baseHelper.setQueryParams(history.location, { list_filter: paramValue });
    if (value !== constant.STATUS) {
      setTaggedWith("");
      listFilter = baseHelper.setQueryParams(history.location, { list_filter: paramValue, list_search: "" });
    }
    history.push(`${history.location.pathname}?${listFilter}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);

    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  return (
    <Select
      label={cms("common.label.showAll")}
      placeholder={cms("common.placeholder.select")}
      value={selectedFilter || ""}
      onChange={handleSelectChange}
      options={filterOptions}
    />
  );
};

AdvanceFilters.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  taggedWith: "",
  selectedFilter: "",
};

AdvanceFilters.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  selectedFilter: PropsType.string,
};

Filter.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  setSelectedFilter: () => {},
  selectedFilter: "",
};

Filter.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export { AdvanceFilters as default, Filter };
