import React, { useCallback, useContext, useEffect, useState } from "react";
import { Banner, Caption, Card, FormLayout, PageActions, Select, TextField, TextStyle } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withFeature } from "lib/hoc";

// import mutation
import { EDIT_PRODUCT_INFORMATION } from "app/product/apollo/mutations";

// import queries
import {
  GET_CATALOG,
  GET_PRODUCT_AND_SELLER_PRODUCT,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TYPE,
  GET_VENDOR_BRAND_LIST,
  GET_WHOLESALE_SETTING,
} from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import Tag
import Tags from "app/product/modules/generic/features/form/tag/tag";

import Catalog from "app/product/modules/generic/features/form/tag/catalog";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

const EditInformation = () => {
  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { currentUser, match, cms } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;

  const [disabledButton, setDisabledButton] = useState(true);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);

  const [basePricing, setBasePricing] = useState();
  const [comparePriceData, setComparePriceData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [discountData, setDiscountData] = useState("");
  const [markupData, setMarkupData] = useState("");
  const [message, setMessage] = useState("");
  const [priceData, setPriceData] = useState("");
  const [productFormSetting, setProductFormSetting] = useState();
  const [tagData, setTagData] = useState("");
  const [typeData, setTypeData] = useState("");
  const [value, setValue] = useState({});
  const [vendorData, setVendorData] = useState("");
  const [wholesalePrice, setWholesalePrice] = useState("");

  setDescription(cms("label.editInformation"));
  setTitle(cms("label.title.information"));

  const { CRITICAL, gql } = constant;
  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_PERCENTAGE, MAX_MARKUP_PERCENTAGE },
  } = constant;

  const { loading: catalogLoading, data: catalogData } = useQuery(GET_CATALOG);
  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: brandListLoading } = useQuery(GET_VENDOR_BRAND_LIST);
  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });
  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);

  const [updateProduct, { loading: editProductLoading }] = useMutation(EDIT_PRODUCT_INFORMATION);

  const { loading: loadingProductForm, data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  // load product catalog data
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || {};

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        setDescriptionData(productLabel.description);
        setPriceData(productLabel.price);
        setComparePriceData(productLabel.comparePrice);
        setVendorData(productLabel.vendor);
        setTagData(productLabel.tags);
        setTypeData(productLabel.productType);
        setMarkupData(productLabel.markup);
        setDiscountData(productLabel.discount);
        setProductFormSetting(productLabel);
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.TITLE, constant.DESCRIPTION];
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      setValue(productResponse && productResponse.sellerProduct);
      setWholesalePrice(
        productResponse &&
          productResponse.sellerProduct &&
          productResponse.sellerProduct.customInfo &&
          productResponse.sellerProduct.customInfo.wholesalePrice
      );
      setBasePricing(productResponse && productResponse.sellerProduct && productResponse.sellerProduct.basePricing);
    }
  }, [gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productListError });
    }
  }, [CRITICAL, cms, productListError, setBanner]);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  if (
    productTagLoading ||
    brandListLoading ||
    productTypeLoading ||
    productLoading ||
    loadingProductForm ||
    wholesaleLoading ||
    catalogLoading
  ) {
    return <SkeletonCard />;
  }

  const typeOption = [
    {
      label: cms("common.label.flat"),
      value: constant.FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: constant.PERCENTAGE,
    },
  ];

  const handleDiscount = (fieldName, fieldValue) => {
    const sellerDiscount = { ...value.sellerDiscount };
    sellerDiscount[fieldName] = fieldValue || parseFloat(0);
    if (fieldName !== constant.PRICE) {
      sellerDiscount.price = null;
    }
    setValue({
      ...value,
      sellerDiscount,
    });
  };

  const handleMarkup = (fieldName, fieldValue) => {
    const markUp = { ...value.markUp };
    markUp[fieldName] = fieldValue;
    if (fieldName !== constant.PRICE) {
      markUp.price = null;
    }
    setValue({
      ...value,
      markUp,
    });
  };

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT) => {
    if (parseFloat(val) > MIN_PRICE && parseFloat(val) <= max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const { sellerDiscount = {}, markUp = {} } = value || {};
  const { price = "", type = "" } = markUp || {};
  const { price: sellerPrice = "", type: sellerType = "" } = sellerDiscount || {};
  const maxDiscount = (sellerType === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;
  const maxMarkup = (type === constant.PERCENTAGE && MAX_MARKUP_PERCENTAGE) || MAX_AMOUNT;

  const discountPrice = value && value.sellerDiscount && value.sellerDiscount.price;
  const discountType = value && value.sellerDiscount && value.sellerDiscount.type;
  const markUpPrice = value && value.markUp && value.markUp.price;
  const markUpType = value && value.markUp && value.markUp.type;

  const selectDiscount = (
    <Select
      value={discountType || ""}
      options={typeOption}
      placeholder={cms("placeholder.selectType")}
      onChange={(val) => {
        handleDiscount(constant.TYPE, val);
        setDisabledButton(false);
      }}
    />
  );

  const selectMarkUp = (
    <Select
      value={markUpType || ""}
      options={typeOption}
      placeholder={cms("placeholder.selectType")}
      onChange={(val) => {
        handleMarkup(constant.TYPE, val);
        setDisabledButton(false);
      }}
    />
  );

  const onFormSubmit = async () => {
    const formValues = {
      productId: value.productId,
      title: value.title,
      description: value.description,
      productType: value.productType,
      tags: value.tags,
      shopifyCatalogId: value.shopifyCatalogId,
      discount: {
        price: parseFloat(discountPrice),
        type: discountType,
      },
      markUp: {
        price: parseFloat(markUpPrice),
        type: markUpType,
      },
    };

    if (isWholesalePrice) {
      formValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice) };
    }

    const dataToCheck = {
      title: (value.title && value.title.trim()) || "",
      description: (value.description && value.description.trim()) || "",
      vendor: value.vendor,
      productType: value.productType,
      tags: (value.tags && value.tags.length) || null,
      comparePrice: value.comparePrice,
      price: value.price,
      markup: (value.markUp && value.markUp.price) || 0,
      discount: (value.sellerDiscount && value.sellerDiscount.price) || 0,
    };

    if (isWholesalePrice) {
      dataToCheck.wholesalePrice = parseFloat(wholesalePrice || 0);
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];
    const { ALLOWED_INFORMATION_KEYS, WHOLESALE_PRICE, MARKUP_KEY } = constant;

    if (isWholesalePrice) {
      ALLOWED_INFORMATION_KEYS.push(WHOLESALE_PRICE);
    }

    ALLOWED_INFORMATION_KEYS.push(MARKUP_KEY);
    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        const valueData = dataToCheck[key];
        if (!valueData && ALLOWED_INFORMATION_KEYS.includes(key)) {
          errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
        }
      });
    }
    if (errorField) {
      const banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }
    await updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT_INFORMATION);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT_INFORMATION);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.information"));
          setBanner({ isOpen: false, status: "", title: "" });
          setTimeout(() => {
            handleTabChange(1);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const required = (field) => {
    if (field && field.isRequired) {
      return "*";
    }
    return "";
  };

  const label = (val, defaultCms) => {
    if (val && val.label) {
      return val.label;
    }
    return defaultCms;
  };

  const descriptionLabel = `${label(descriptionData, cms("label.description"))}${required(descriptionData)}`;

  return (
    <>
      <Card
        title={[
          cms("service.label.information"),
          value && value.updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
        id="serviceInformation"
      >
        <Card.Section>
          <p>{cms("service.label.operatorDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("service.label.name")}*`}
              placeholder={cms("service.placeholder.name")}
              required="true"
              onChange={(val) => {
                handleChange(constant.TITLE, val);
                setDisabledButton(false);
              }}
              error=""
              value={(value && value.title) || ""}
              onBlur={() => value && value.title && handleChange(constant.TITLE, value.title.trim())}
            />
            {descriptionLabel}
            <Editor
              id="idTiny"
              textareaName={`${cms("label.description")}`}
              value={(value && value.description) || ""}
              onEditorChange={(val) => handleChange(constant.DESCRIPTION, val)}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            {/* // )} */}
            {!(value && value.variants && value.variants.length) ? (
              <FormLayout>
                {basePricing && basePricing.currencyCode ? (
                  <>
                    <br />
                    <Banner status={constant.INFO}>
                      <TextStyle>{`${cms("label.import")} ${basePricing.currencyCode}`}</TextStyle>
                    </Banner>
                  </>
                ) : null}
                <FormLayout.Group>
                  <TextField
                    id="price"
                    label={[`${label(priceData, cms("label.price"))}${required(priceData)}`]}
                    min={0}
                    value={(value && value.price && value.price.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    prefix={moneyFormat}
                    disabled
                  />
                  <TextField
                    id="comparePrice"
                    label={`${label(comparePriceData, cms("label.comparePrice"))}${required(comparePriceData)}`}
                    min={0}
                    placeholder={constant.ZERO_PRICE}
                    prefix={moneyFormat}
                    value={(value && value.comparePrice && value.comparePrice.toString()) || ""}
                    disabled
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label={`${label(markupData, cms("label.markup"))}${required(markupData)}`}
                    value={(markUpPrice && markUpPrice.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    max={maxMarkup}
                    prefix={type === constant.FLAT && currentUser.moneyFormat}
                    suffix={type === constant.PERCENTAGE && constant.symbol.PERCENTAGE}
                    onChange={(val) => {
                      handleMarkup(constant.PRICE, acceptOnlyValidInput(val, price, maxMarkup));
                      setDisabledButton(false);
                    }}
                    disabled={!type}
                    connectedRight={selectMarkUp}
                  />
                  <TextField
                    label={`${label(discountData, cms("label.discount"))}${required(discountData)}`}
                    value={(discountPrice && discountPrice.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    min={0}
                    max={maxDiscount}
                    prefix={sellerType === constant.FLAT && currentUser.moneyFormat}
                    suffix={sellerType === constant.PERCENTAGE && constant.symbol.PERCENTAGE}
                    onChange={(val) => {
                      handleDiscount(constant.PRICE, acceptOnlyValidInput(val, sellerPrice, maxDiscount));
                      setDisabledButton(false);
                    }}
                    disabled={!sellerType}
                    connectedRight={selectDiscount}
                  />
                </FormLayout.Group>
              </FormLayout>
            ) : null}
            <TextField
              label={`${label(vendorData, cms("service.label.vendorName"))}${required(vendorData)}`}
              value={(value && value.vendor) || ""}
              disabled
            />

            {productTypeOptions && productTypeOptions.length > 0 && (
              <Select
                label={`${label(typeData, cms("service.label.type"))}${required(typeData)}`}
                placeholder={cms("service.placeholder.type")}
                options={productTypeOptions}
                value={value && value.productType}
                onChange={(val) => {
                  handleChange(constant.PRODUCT_TYPE, val);
                  setDisabledButton(false);
                }}
              />
            )}
            {availableProductTags && availableProductTags.tags && availableProductTags.tags.length > 0 && (
              <Tags
                cms={cms}
                productTag={availableProductTags}
                data={value}
                label={`${label(tagData, cms("service.label.tag"))}${required(tagData)}`}
                placeholder={cms("service.placeholder.tag")}
                setData={setValue}
                handleChange={handleChange}
              />
            )}
            <Catalog
              label={cms("service.label.collection")}
              placeholder={cms("service.placeholder.collection")}
              data={value}
              handleChange={handleChange}
              productCatalog={(productCatalogOption && productCatalogOption.catalog) || []}
            />
          </FormLayout>
          <div className="page-action">
            <PageActions
              primaryAction={{
                content: cms("button.update"),
                onAction: () => onFormSubmit(),
                loading: editProductLoading,
                disabled: !(value && value.title) || disabledButton,
              }}
            />
          </div>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
    </>
  );
};

export default withFeature(EditInformation, { feature: constant.PRODUCT_UPDATE_FORM });
