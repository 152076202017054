import { gql } from "apollo-boost";

const PAY_LAKE_VENDOR = gql`
  mutation payLakeVendor($input: PayLakeVendor!) {
    payLakeVendor(input: $input) {
      data
      status
      error
    }
  }
`;

export default PAY_LAKE_VENDOR;
