import PropTypes from "prop-types";

const titleDescriptionProp = {
  type: {
    data: PropTypes.shape({
      title: PropTypes.string,
      tinyMCE: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    handleChange: PropTypes.func,
    error: PropTypes.string,
  },
};

const imageProp = {
  type: {
    handleChange: PropTypes.func,
  },
};

const priceProp = {
  type: {
    data: PropTypes.shape({
      price: PropTypes.number,
      comparePrice: PropTypes.number,
    }),
    handleChange: PropTypes.func,
  },
};

const inventoryProp = {
  type: {
    data: PropTypes.shape({
      policySelection: PropTypes.string,
      quantityDisabled: PropTypes.bool,
      sku: PropTypes.string,
      barcode: PropTypes.string,
      inventoryQuantity: PropTypes.number,
    }),
    handleChange: PropTypes.func,
  },
};

const shippingProp = {
  type: {
    data: PropTypes.shape({
      shipping: PropTypes.bool,
      weight: PropTypes.string,
      selectWeight: PropTypes.string,
    }),
    handleChange: PropTypes.func,
  },
};

const variantProp = {
  type: {
    data: PropTypes.shape({
      price: PropTypes.number,
      sku: PropTypes.string,
      barCode: PropTypes.string,
      quantity: PropTypes.number,
      quantityDisabled: PropTypes.bool,
    }),
    handleChange: PropTypes.func,
  },
};

const organizationProp = {
  type: {
    data: PropTypes.shape({
      vendorValue: PropTypes.string,
      productValueType: PropTypes.string,
      organisationTags: PropTypes.array,
    }),
    handleChange: PropTypes.func,
    error: PropTypes.string,
  },
};

const tagProp = {
  type: {
    data: PropTypes.shape({
      values: PropTypes.array,
      selected: PropTypes.array,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func,
  },
};

const manualFormProp = {
  type: {
    setBannerValue: PropTypes.func,
  },
};

export {
  titleDescriptionProp,
  imageProp,
  priceProp,
  inventoryProp,
  shippingProp,
  variantProp,
  organizationProp,
  tagProp,
  manualFormProp,
};
