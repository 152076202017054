function logsData(cms) {
  return {
    sortOptions: [
      { label: cms("common.label.sortOption.dateAsc"), value: "createdAt_asc" },
      { label: cms("common.label.sortOption.dateDesc"), value: "createdAt_desc" },
    ],
    tabs: [
      {
        id: "all-activity-log",
        content: cms("label.tab.all"),
        accessibilityLabel: "All activity log",
        panelID: "all-activity-content",
      },
      {
        id: "errors",
        content: cms("label.tab.error"),
        panelID: "accepts-errors-content",
      },
      {
        id: "duplicate-activities",
        content: cms("label.tab.duplicate"),
        panelID: "duplicate-activities-content",
      },
      {
        id: "success-activities",
        content: cms("label.tab.success"),
        panelID: "success-content",
      },
    ],
  };
}
export default logsData;
