// import react packages;
import React, { useContext, useState, useEffect } from "react";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// helper
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// Import Polaris Components
import { Layout } from "@shopify/polaris";

// import components
import { Banner } from "lib/components";

import { Connect } from "../add/subFeatures";

const ConnectShop = () => {
  const { history, cms } = useContext(PrivateContext);
  const { state } = history.location;
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });
  useEffect(() => {
    if (state && state.bannerData) {
      setBanner({
        isOpen: true,
        status: state.bannerData.status,
        title: state.bannerData.title,
      });
    }
  }, [state]);

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.connect.title")} description={cms("section.connect.description")}>
        <Connect setBanner={setBanner} />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ConnectShop), { feature: constant.CONNECT_SHOP });
