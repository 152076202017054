import React, { useContext, useState, useEffect } from "react";
import { Card, Button, Stack, TextStyle, Layout, Heading, Caption } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context/privateContext";

import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import mutations
import { UPDATE_FULFILLMENT_TRACKING_DETAILS_SETTING } from "app/setup/apollo/mutations";
// import queries
import { GET_FULFILLMENT_TRACKING_DETAILS_SETTING } from "app/setup/apollo/queries";

const OperatorManageTrackingDetails = () => {
  const { gql } = constant;
  const { currentUser, cms } = useContext(PrivateContext);
  const { _id: currentUserId } = currentUser;
  const [message, setMessage] = useState("");
  const [updateAt, setUpdateAt] = useState(new Date());
  const [isEnabledData, setIsEnabledData] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const { data: trackingData, loading: fulfillSettingLoading, refetch } = useQuery(
    GET_FULFILLMENT_TRACKING_DETAILS_SETTING
  );

  const [updateFulfillmentTrackingDetailsSetting, { loading }] = useMutation(
    UPDATE_FULFILLMENT_TRACKING_DETAILS_SETTING
  );
  const contentStatus = isEnabledData ? constant.displayStatus.DISABLE : constant.ENABLE;

  useEffect(() => {
    if (trackingData) {
      const resData = baseHelper.getResponseData(trackingData, "getFulfillmentTrackingDetailsSetting");
      const resError = baseHelper.getResponseError(trackingData, "getFulfillmentTrackingDetailsSetting");
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        return;
      }
      if (resData) {
        const { isEnabled, updatedAt } = resData || {};
        setIsEnabledData(isEnabled);
        setUpdateAt(updatedAt);
      }
    }
  }, [trackingData, setBanner, "getFulfillmentTrackingDetailsSetting"]);

  const onCodeSubmit = async (enable) => {
    const formData = {
      isEnabled: enable,
      sellerId: currentUserId,
    };
    try {
      const response = await updateFulfillmentTrackingDetailsSetting({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, "updateFulfillmentTrackingDetailsSetting");

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (!enable) {
        setMessage(cms("message.success.disable"));
        setIsEnabledData(!isEnabledData);
      } else {
        setMessage(cms("message.success.enable"));
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const renderBanner = () => {
    const { status, title, isOpen } = banner;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  if (fulfillSettingLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {banner.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection
        title={cms("label.section.setting.title")}
        description={cms("label.section.setting.description")}
      >
        <Card>
          <Card sectioned>
            <Stack wrap={false}>
              <Stack.Item fill>
                <Heading variation="strong">{cms("label.section.editFulfillment.title")}</Heading>
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
                </TextStyle>
                <br />
                <TextStyle>{cms("label.section.editFulfillment.description")}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                <Button
                  id="auto"
                  primary={!isEnabledData}
                  onClick={() => onCodeSubmit(!isEnabledData)}
                  loading={loading}
                  buttonType={contentStatus}
                  destructive={isEnabledData}
                >
                  {isEnabledData ? "Disable" : "Enable"}
                </Button>
              </Stack.Item>
            </Stack>
          </Card>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorManageTrackingDetails), {
  feature: constant.MANAGE_FULFILLMENT_DETAIL,
});
