import constant from "lib/constant/constant";

const cmsVendorList = (cms) => {
  const cmsData = {
    sortOptions: [
      { label: cms("label.providerList.sortByDateAsc"), value: cms("label.providerList.createdAtAsc") },
      { label: cms("label.providerList.sortByDateDesc"), value: cms("label.providerList.createdAtDesc") },
      { label: cms("label.providerList.sortByBrandNameAsc"), value: cms("label.providerList.brandNameAsc") },
      { label: cms("label.providerList.sortByBrandNameDesc"), value: cms("label.providerList.brandNameDesc") },
    ],
    promotedBulkActions: [
      {
        content: cms("label.providerList.bulkAction"),
      },
    ],
    resourceName: {
      singular: cms("label.providerList.Vendor"),
      plural: cms("label.providerList.Vendors"),
    },
    filterOptions: [{ label: cms("label.providerList.brand"), value: constant.BRAND_NAME }],
    message: {
      something_went_wrong: cms("error.common.somethingWentWrong"),
      running_background: cms("message.providerList.requestSuccess"),
      token_expire: cms("message.providerList.token_expire"),
      enterAccessCode: cms("message.providerList.enterAccessCode"),
      assocRemoveSuccess: cms("message.providerList.assocRemoveSuccess"),
      noSellerFound: cms("message.providerList.noSellerFound"),
      vendorEnable: cms("message.providerList.enable"),
    },
    label: {
      approved: cms("label.providerList.approved"),
      critical: cms("label.providerList.critical"),
      delete_selected: cms("label.providerList.deleteSelected"),
      delete: cms("label.providerList.delete"),
      deleteModal: cms("label.providerList.deleteModal"),
      deleteProductsAssociated: cms("label.providerList.deleteProductsAssociated"),
      disabled: cms("label.providerList.disable"),
      doNothing: cms("label.providerList.doNothing"),
      email: cms("label.providerList.email"),
      enterCode: cms("label.providerList.enterCode"),
      filter_by: cms("label.providerList.filterBy"),
      filter: cms("label.providerList.filter"),
      fullAccess: cms("label.providerList.fullAccess"),
      hold: cms("label.providerList.hold"),
      inReview: cms("label.providerList.inReview"),
      like: cms("label.providerList.like"),
      list_filter: cms("label.providerList.listFilter"),
      list_search: cms("label.providerList.listSearch"),
      listOfAssocSellers: cms("label.providerList.listOfAssocSellers"),
      logInAs: cms("label.providerList.logInAs"),
      logInModal: cms("label.providerList.logInModal"),
      name: cms("label.providerList.name"),
      needReview: cms("label.providerList.needReview"),
      onBoardedOn: cms("label.providerList.onBoardedOn"),
      onHold: cms("label.providerList.onHold"),
      onlyReadAccess: cms("label.providerList.onlyReadAccess"),
      otpModal: cms("label.providerList.otpModal"),
      page: cms("label.providerList.page"),
      perPage: cms("label.providerList.perPage"),
      primaryDelete: cms("label.providerList.primaryDelete"),
      reason: cms("label.providerList.reason"),
      reject: cms("label.providerList.rejected"),
      rejected: cms("label.providerList.rejectVendor"),
      rejectOrder: cms("label.providerList.reject"),
      review: cms("label.providerList.review"),
      search: cms("label.providerList.search"),
      secondaryDelete: cms("label.providerList.secondaryDelete"),
      select_filter: cms("label.providerList.selectFilter"),
      show_all_vendor: cms("label.providerList.showAllVendor"),
      sort_name: cms("label.providerList.sortName"),
      sort_order: cms("label.providerList.sortOrder"),
      store: cms("label.providerList.store"),
      tabKey: cms("label.providerList.tabKey"),
      total: cms("label.providerList.total"),
      verify_selected: cms("label.providerList.verifySelected"),
      viewDetailsFor: cms("label.providerList.viewDetailsFor"),
    },
    sectionContent: {
      deleteVendorSelected: cms("sectionContent.providerList.deleteVendorSelected"),
      deleteVendorNote: cms("sectionContent.providerList.deleteVendorNote"),
      logInAsAccess: cms("sectionContent.providerList.logInAsAccess"),
      otpNote: cms("sectionContent.providerList.otpNote"),
    },
    button: {
      deleteFromMc: cms("button.providerList.deleteProvider.primaryAction"),
      deleteFromShop: cms("button.providerList.deleteProvider.secondaryAction1"),
      confirmOtp: cms("button.providerList.accessCodeVerify.primaryAction"),
      resendOtp: cms("button.providerList.accessCodeVerify.secondaryAction1"),
      readOnly: cms("button.providerList.accessCodeVerify.secondaryAction2"),
      deleteVendor: cms("button.providerList.providerList.primaryAction"),
      loginVendor: cms("button.providerList.providerList.secondaryAction1"),
      viewSeller: cms("button.providerList.providerList.secondaryAction2"),
      reviewVendor: cms("button.providerList.providerList.secondaryAction7"),
      brandPage: cms("button.providerList.providerList.secondaryAction3"),
      brandPageV2: cms("button.providerList.providerList.secondaryAction4"),
      removeSeller: cms("button.providerList.removeAssocSeller.primaryAction"),
      enableVendor: cms("button.providerList.providerList.secondaryAction5"),
      disableVendor: cms("button.providerList.providerList.secondaryAction6"),
      profile: cms("button.providerList.providerList.profile"),
    },
    limitOptions: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
    // tabs: [
    //   {
    //     id: "all-vendors",
    //     content: cms("label.providerList.all"),
    //     accessibilityLabel: "All vendors",
    //     panelID: "allVendorContent",
    //     value: "all",
    //   },
    //   {
    //     id: "pending-approval",
    //     content: cms("label.providerList.pendingApproval"),
    //     panelID: "pendingApprovalContent",
    //     value: "pending",
    //   },
    //   {
    //     id: "approved",
    //     content: cms("label.providerList.approved"),
    //     panelID: "approvedContent",
    //     value: "approve",
    //   },
    //   {
    //     id: "review",
    //     content: cms("label.providerList.review"),
    //     panelID: "reviewContent",
    //     value: "inReview",
    //   },
    //   {
    //     id: "onHold",
    //     content: cms("label.providerList.onHold"),
    //     panelID: "onHoldContent",
    //     value: "onHold",
    //   },
    //   {
    //     id: "rejected",
    //     content: cms("label.providerList.rejected"),
    //     panelID: "rejectedContent",
    //     value: "reject",
    //   },
    //   {
    //     id: "onHoliday",
    //     content: cms("label.providerList.onHoliday"),
    //     panelID: "onHolidayContent",
    //     value: "onHoliday",
    //   },
    // ],
    filterStatus: [
      {
        label: cms("label.providerList.all"),
        value: "all",
      },
      {
        label: cms("label.providerList.pendingApproval"),
        value: "pending",
      },
      {
        label: cms("label.providerList.approved"),
        value: "approve",
      },
      {
        label: cms("label.providerList.review"),
        value: "inReview",
      },
      {
        label: cms("label.providerList.onHold"),
        value: "onHold",
      },
      {
        label: cms("label.providerList.rejected"),
        value: "reject",
      },
      {
        label: cms("label.providerList.onHoliday"),
        value: "onHoliday",
      },
    ],
  };

  return cmsData;
};

export default cmsVendorList;
