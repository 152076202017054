import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, PageActions, Banner, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import fileDownload from "js-file-download";
import Baby from "babyparse";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context/privateContext";

import { baseHelper, errorHelper } from "lib/helpers";

// import component
import FileUpload from "lib/components/fileInput/fileUpload";
import { Sheet, SkeletonCard } from "lib/components";

// import mutation
import { BULK_UPLOAD } from "app/product/apollo/mutations";

import csvData from "app/product/modules/operator/features/add/config/csvData";

// import query
import { GET_METAFIELD_SETTING } from "app/product/apollo/queries";
import { GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";

const BulkCreate = (props) => {
  const { setBanner } = props;
  const { history, cms } = useContext(PrivateContext) || {};
  const { gql, CRITICAL, SUCCESS, INFO } = constant;
  const [values, setValues] = useState({});
  const [metaField, setMetaField] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [bulkProductUploadRequest, { loading: bulkUploadLoading }] = useMutation(BULK_UPLOAD);
  const [sheetActive, setSheetActive] = useState(false);
  const [isProductList, setIsProductList] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");

  const { loading: metaFieldLoading, data: metaFieldData } = useQuery(GET_METAFIELD_SETTING);

  const { loading: loadingProductListing, data: dataProductList } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (metaFieldData) {
      const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
      const { metafield = [] } = metafieldSetting;

      if (metafield && metafield.length) {
        setMetaField(metafield);
      }
    }
  }, [metaFieldData]);

  useEffect(() => {
    if (dataProductList) {
      const productistResponse = baseHelper.getResponseData(dataProductList, "getProductListingSetting");
      if (productistResponse) {
        const { isEnabled = {} } = productistResponse;
        if (isEnabled) {
          setIsProductList(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductList]);

  const getCSVData = (csvValue) => {
    const regexForEnterKey = /\n,|\n/gi;
    const csvDataAfterRegex = csvValue.data.replace(regexForEnterKey, "\n");
    return csvDataAfterRegex;
  };

  const onSubmit = async () => {
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.csvIsRequired"),
        status: CRITICAL,
      });
      return;
    }
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: modifiedFormat,
        fileName: values.fileName,
      },
    };
    try {
      const res = await bulkProductUploadRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_PRODUCT_CSV);
      let banner = {
        isOpen: true,
        status: INFO,
        title: cms("section.bulk.message.success.title"),
        children: cms("section.bulk.message.success.content"),
        action: {
          content: cms("section.bulk.message.success.action"),
          onAction: () => history.push("/activity/bulk"),
        },
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_PRODUCT_CSV);
        banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: error,
        };
      }
      setIsSubmitDisabled(true);
      setBanner(banner);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };
  const downloadCSV = () => {
    const keysToAdd = (metaField || []).map((field) => field.key).filter((item) => item);
    csvData.forEach((csv) => {
      keysToAdd.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        csv[key] = "";
      });
    });

    const keyToAddListValue = isProductList ? ["Listing Quantity", "Campaign"] : [];
    csvData.forEach((csv) => {
      keyToAddListValue.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        csv[key] = "";
      });
    });
    const csv = Baby.unparse(csvData);
    fileDownload(csv, constant.BULK_PRODUCT_CSV);
  };
  // const learnMore = (title, content) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(content);
  // };

  useEffect(() => {
    if (values.data) {
      setIsSubmitDisabled(false);
    }
    if (!values.data) {
      setIsSubmitDisabled(true);
    }
  }, [values]);

  if (metaFieldLoading || loadingProductListing) {
    return <SkeletonCard />;
  }

  return (
    <>
      <Card
        title={cms("section.bulk.title")}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () => learnMore(cms("section.bulk.title"), "TODO:"),
        //   },
        // ]}
      >
        <Card.Section>
          <TextContainer>
            {cms("section.bulk.description")}
            <br />
            <Banner status="info">
              <p>{cms("section.bulk.label.sampleCsv")}</p>
            </Banner>
          </TextContainer>
          <br />
          <FormLayout>
            <FileUpload setValue={setValues} getCSV={downloadCSV} values={values} />
          </FormLayout>
          <PageActions
            primaryAction={{
              content: cms("section.bulk.button.primary"),
              id: "submitButton",
              onAction: () => onSubmit(),
              loading: bulkUploadLoading,
              disabled: bulkUploadLoading || isSubmitDisabled,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                id: "cancelButton",
                onAction: () => history.push("/"),
              },
            ]}
          />
        </Card.Section>
      </Card>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

BulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(BulkCreate), {
  feature: constant.PRODUCT_ADD_CSV,
  // subFeature: constant.PRODUCT_ADD_CSV,
});
