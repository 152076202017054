import { gql } from "apollo-boost";

const GET_EMAIL_TEMPLATE = gql`
  query {
    getBulkEmailTemplate {
      data {
        _id
        label
        message
        subject
      }
      status
      error
    }
  }
`;
export default GET_EMAIL_TEMPLATE;
