import { gql } from "apollo-boost";

const DELETE_VARIANT = gql`
  mutation deleteVariantById($input: DeleteVariant) {
    deleteVariantById(input: $input) {
      data
      status
      error
    }
  }
`;

export default DELETE_VARIANT;
