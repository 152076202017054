import { gql } from "apollo-boost";

const ERROR_LOG_VIEW = gql`
  subscription {
    errorLogView {
      data {
        errorLog {
          _id
          createdAt
          duplicate
          error
          errorType
          field
          fileName
          initiator
          status
          success
          total
          updatedAt
          userId
        }
        operationType
      }
    }
  }
`;

export default ERROR_LOG_VIEW;
