import React from "react";
import { Card } from "@shopify/polaris";

import { ServiceDetails, SlotOptions } from "app/product/modules/generic/features/form/service/subFeatures";

const Availability = (props) => {
  const {
    address,
    capacity,
    cms,
    duration,
    endTime,
    location,
    maxDuration,
    selected,
    selectedDays,
    selectedHours,
    setCapacity,
    setDays,
    setDuration,
    setEndTime,
    setLocation,
    setSelectedDays,
    setSelectedHours,
    setStartTime,
    startTime,
    setDisabledButton,
    setMaxDuration = () => {},
  } = props;

  return (
    <Card title={cms("service.label.serviceAvailability")}>
      <SlotOptions
        selected={selected}
        startTime={startTime}
        endTime={endTime}
        setDays={setDays}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        selectedDays={selectedDays}
        selectedHours={selectedHours}
        setSelectedDays={setSelectedDays}
        setSelectedHours={setSelectedHours}
        setDisabledButton={setDisabledButton}
        setMaxDuration={setMaxDuration}
      />
      <ServiceDetails
        address={address}
        duration={duration}
        capacity={capacity}
        setCapacity={setCapacity}
        setDuration={setDuration}
        location={location}
        setLocation={setLocation}
        setDisabledButton={setDisabledButton}
        maxDuration={maxDuration}
      />
    </Card>
  );
};

export default Availability;
