import { gql } from "apollo-boost";

const UPDATE_PLAN = gql`
  mutation updatePlan($input: UpdatePlan) {
    updatePlan(input: $input) {
      status
      error
      data {
        data
        confirmation_url
      }
    }
  }
`;

const UPDATE_CHARGE_DETAILS = gql`
  mutation updateChargeDetails($input: UpdateChargeDetails) {
    updateChargeDetails(input: $input) {
      status
      error
      data {
        data
        confirmation_url
      }
    }
  }
`;

export { UPDATE_PLAN, UPDATE_CHARGE_DETAILS };
