import React, { useContext } from "react";
import { RadioButton } from "@shopify/polaris";

// propTypes
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const ManageShipping = (props) => {
  const { cms } = useContext(PrivateContext);
  const { isChecked, handleShipping } = props;
  return (
    <RadioButton
      label={cms("label.managedShipping")}
      helpText={cms("label.managedShipping")}
      checked={isChecked}
      onChange={() => handleShipping("shipping")}
    />
  );
};
ManageShipping.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  handleShipping: PropTypes.func.isRequired,
};

export default withFeature(ManageShipping, { feature: constant.DROPSHIP });
