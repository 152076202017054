/* eslint-disable no-shadow */
import React, { useEffect, useCallback, useState, useContext } from "react";
import moment from "moment";

import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Banner,
  Button,
  Caption,
  Card,
  Checkbox,
  DatePicker,
  Icon,
  Layout,
  Modal,
  PageActions,
  Popover,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { CalendarMinor, DeleteMajor } from "@shopify/polaris-icons";

import { Toast, Spinner } from "lib/components";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { HOLIDAY_SETTING } from "app/advanceVendor/apollo/mutations";
import { GET_VENDOR_HOLIDAY } from "app/setup/apollo";
import { StackWrapper } from "asset/styles/globalStyle";

import cmsHolidaySetting from "app/advanceVendor/modules/operator/feature/setting/holiday/cms/holidayCMS";

const VendorHolidaySetting = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const cmsHoliday = cmsHolidaySetting(cms);
  const { label } = cmsHoliday;
  const [endingDate, setEndingDate] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noDateSelected, setisDateSelect] = useState(true);
  const [submitButton, setSubmitButton] = useState(true);
  const [popoverActive, setPopoverActive] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  // eslint-disable-next-line no-unused-vars
  const [intialFormatVendor, setVendorHolidayData] = useState({});

  const { data: getVendorHoliday, loading, refetch } = useQuery(GET_VENDOR_HOLIDAY);

  const vendorHolidayResponseData = baseHelper.getResponseData(getVendorHoliday, constant.gql.GET_VENDOR_HOLIDAY);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  });

  const handleTextfield = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const handleChange = useCallback(() => {
    setActive(!active);
  }, [active]);

  const [vendorHolidaySetting, { loading: holidayLoading }] = useMutation(HOLIDAY_SETTING);

  useEffect(() => {
    if (vendorHolidayResponseData) {
      if (!vendorHolidayResponseData.length) {
        setVendorHolidayData({});
        setisDateSelect(true);
        setChecked(false);
        setValue("");
        setUpdatedAt("");
        setStartingDate(new Date());
        setEndingDate(new Date());
        setSelectedDates({
          start: new Date(),
          end: new Date(),
        });
      } else {
        vendorHolidayResponseData.forEach((element) => {
          if (currentUser._id === element.vendorId) {
            setVendorHolidayData({
              month: new Date().getMonth(),
              year: new Date().getFullYear(),
              isActive: false,
              noDateSelected: false,
              startingDate: element && element.holiday && element.holiday.startDate,
              endingDate: element && element.holiday && element.holiday.endDate,
              start: element && element.holiday && element.holiday.startDate,
              end: element && element.holiday && element.holiday.endDate,
              id: element && element.vendorId,
              updatedAt: element && element.updatedAt,
            });
            setisDateSelect(false);
            setChecked(element.holiday.isRejectOrder);
            setValue(element.holiday.reason);
            setUpdatedAt(element.updatedAt);
            if (
              element &&
              element.holiday &&
              element.holiday.endDate &&
              element &&
              element.holiday &&
              element.holiday.startDate
            ) {
              setStartingDate(new Date(element.holiday.startDate));
              setEndingDate(new Date(element.holiday.endDate));
              setSelectedDates({
                start: new Date(element.holiday.startDate),
                end: new Date(element.holiday.endDate),
              });
            } else {
              setisDateSelect(true);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVendorHoliday, vendorHolidayResponseData]);

  const onSubmit = async (isDeleted) => {
    try {
      const vendorDetail = [];
      vendorDetail.push({
        vendorId: currentUser._id,
        date: {
          startDate: baseHelper.yearFirstDateFormat(selectedDates.start),
          endDate: baseHelper.yearFirstDateFormat(selectedDates.end),
        },
      });

      const response = await vendorHolidaySetting({
        variables: { input: { vendor: isDeleted ? [] : vendorDetail, reason: value, isRejected: checked } },
      });

      const responseData = baseHelper.getResponseData(response.data, constant.gql.HOLIDAY_SETTING);
      const responseError = baseHelper.getResponseError(response.data, constant.gql.HOLIDAY_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (responseData) {
        if (isDeleted) {
          setSelectedDates({
            start: new Date(),
            end: new Date(),
          });
          setisDateSelect(true);
          if (intialFormatVendor.start) {
            const successMesssage = updatedAt ? cms("label.updated") : cms("label.saved");
            setMessage(cms("message.success.save", { item: successMesssage }));
            setSubmitButton(true);
            refetch();
          }
        } else {
          const successMesssage = updatedAt ? cms("label.updated") : cms("label.saved");
          setMessage(cms("message.success.save", { item: successMesssage }));
          setActive(false);
          setSubmitButton(true);
          refetch();
        }
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleClose = () => {
    setActive(!active);
  };

  const handleCheckedChange = useCallback((newChecked) => setChecked(newChecked), []);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  if (loading) {
    return <Spinner />;
  }

  const activator = (
    <Stack alignment="center">
      <Stack.Item>
        <Button icon={CalendarMinor} onClick={togglePopoverActive}>
          {noDateSelected
            ? "Select Date"
            : `${moment(selectedDates.start).format("ll")} - ${moment(selectedDates.end).format("ll")}`}
        </Button>
      </Stack.Item>
      {noDateSelected === false ? (
        <Stack.Item>
          <Button id="deleteButton" plain destructive onClick={() => onSubmit(true)}>
            <Icon source={DeleteMajor} color="critical" />
          </Button>
        </Stack.Item>
      ) : null}
    </Stack>
  );

  return (
    <>
      <Layout>
        <Layout.Section>
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
              />
              <br />
            </>
          )}
          <Layout.AnnotatedSection
            title={label.section.setting.title}
            description={label.section.setting.providerDescription}
          >
            <Card
              title={[
                label.section.holiday.title,
                Date.now() && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                  </TextStyle>
                ),
              ]}
              sectioned
            >
              <hr />
              <StackWrapper className="stack-wrapper">
                <Stack alignment="center">
                  <Stack.Item>
                    <TextStyle variation="strong"> Time Period </TextStyle>
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                      id="select-date"
                      active={popoverActive}
                      activator={activator}
                      onClose={togglePopoverActive}
                      fullWidth
                    >
                      <Popover.Pane>
                        <Stack distribution="equalSpacing">
                          <TextField label={label.start} value={startingDate} />
                          <TextField label={label.end} value={endingDate} />
                        </Stack>
                        <DatePicker
                          month={month}
                          year={year}
                          onChange={(date) => {
                            setSelectedDates({ start: date.start, end: date.end });
                            setStartingDate(moment(date.start).format("ll"));
                            setEndingDate(moment(date.end).format("ll"));
                            setisDateSelect(true);
                          }}
                          onMonthChange={handleMonthChange}
                          selected={selectedDates}
                          disableDatesBefore={new Date()}
                          multiMonth
                          allowRange
                        />
                      </Popover.Pane>
                      <div className="holidayDate">
                        <PageActions
                          primaryAction={{
                            content: label.apply,
                            onClick: () => {
                              setSelectedDates({ start: selectedDates.start, end: selectedDates.end });
                              setisDateSelect(false);
                              setSubmitButton(false);
                              togglePopoverActive();
                            },
                          }}
                          secondaryActions={[
                            {
                              content: cms("common.button.cancel"),
                              onClick: togglePopoverActive,
                            },
                          ]}
                        />
                      </div>
                    </Popover>
                  </Stack.Item>
                </Stack>
              </StackWrapper>
              <div className="page-action">
                {updatedAt && (
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: () => {
                        handleChange();
                      },
                      disabled: noDateSelected || submitButton,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => {
                          history.push("/setting");
                        },
                      },
                    ]}
                  />
                )}
                <Modal
                  open={active}
                  onClose={handleClose}
                  title={label.section.setting.title}
                  primaryAction={{
                    content: cms("common.button.confirm"),
                    onAction: () => {
                      onSubmit(false);
                    },
                    loading: holidayLoading,
                    disabled: holidayLoading,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: handleClose,
                      disabled: holidayLoading,
                    },
                  ]}
                >
                  <Modal.Section>
                    <Stack vertical>
                      <Stack.Item>
                        <Banner status="critical">{label.section.banner.description}</Banner>
                      </Stack.Item>
                      <Stack.Item>
                        <div className="checkBoxLabel">
                          <Checkbox
                            label={label.section.reject.title}
                            checked={checked}
                            onChange={handleCheckedChange}
                          />
                        </div>
                        <div className="checkBox-text-style">
                          <TextStyle variation="subdued">{label.section.reject.providerDescription}</TextStyle>
                        </div>
                      </Stack.Item>
                      <Stack.Item fill>
                        <TextField multiline={3} label={label.reason} value={value} onChange={handleTextfield} />
                      </Stack.Item>
                    </Stack>
                  </Modal.Section>
                </Modal>
              </div>
            </Card>
            {!updatedAt && (
              <PageActions
                primaryAction={{
                  content: cms("common.button.submit"),
                  onAction: () => {
                    handleChange();
                  },
                  disabled: noDateSelected || submitButton,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onAction: () => {
                      history.push("/setting");
                    },
                  },
                ]}
              />
            )}
          </Layout.AnnotatedSection>
          <Toast message={message} setToast={setMessage} />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(VendorHolidaySetting), {
  feature: constant.VENDOR_HOLIDAY_SETTING,
});
