import React, { useContext } from "react";
import { Card, TextField, FormLayout, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Price = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { variantData = {}, handleChange = () => {} } = props;
  const { price = 0, comparePrice = 0, vendorDiscount = { price: 0, type: "flat" } } = variantData;
  const { symbol } = constant;
  const { moneyFormat = symbol.DOLLAR } = currentUser;
  const options = [
    { label: cms("section.price.label.flat"), value: constant.FLAT },
    { label: cms("section.price.label.percentage"), value: constant.PERCENTAGE },
  ];
  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= 100) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
    // handleChange(field, finalValue);
  };
  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || "flat"}
      placeholder={cms("section.price.placeholder.selectType")}
      label={cms("section.price.label.type")}
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );
  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label={cms("section.price.label.price")}
            type="number"
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            prefix={moneyFormat}
            onChange={(value) => handleChange(constant.PRICE, Number(value))}
          />
          <TextField
            id="comparePrice"
            label={cms("section.price.label.compare")}
            type="number"
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            prefix={moneyFormat}
            onChange={(value) => handleChange(constant.COMPARE_PRICE, Number(value))}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="discount"
            label={cms("section.price.label.discount")}
            type="number"
            min="0"
            max={(vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && 100) || 9999}
            value={(vendorDiscount && vendorDiscount.price && vendorDiscount.price.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            onChange={(value) => {
              handlePriceChange(
                constant.VENDOR_DISCOUNT,
                vendorDiscount,
                { price: Number(value) },
                vendorDiscount.type
              );
            }}
            connectedRight={selectWeight(constant.VENDOR_DISCOUNT, vendorDiscount)}
            suffix={vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && symbol.PERCENTAGE}
            prefix={vendorDiscount && vendorDiscount.type === constant.FLAT && moneyFormat}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Price;
