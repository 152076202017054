import { gql } from "apollo-boost";

const GET_SHIPPY_PRO_CARRIER = gql`
  query {
    getShippyProCarrier {
      data {
        id
        label
        name
        service
      }
      status
      error
    }
  }
`;

export default GET_SHIPPY_PRO_CARRIER;
