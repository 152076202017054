import { gql } from "apollo-boost";

const GET_MANAGE_METAFIELD_SETTING = gql`
  query {
    getManageMetaFieldKeys {
      data {
        isEnabled
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_MANAGE_METAFIELD_SETTING;
