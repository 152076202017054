import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";
import { VendorLabel } from "./operator";

const { userKey } = constant;
const { operator } = userKey;

const VendorFormLabel = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <VendorLabel />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ProviderLabelComponent = getComponent(currentUserRole);
  return ProviderLabelComponent;
};

export default withErrorBoundary(VendorFormLabel);
