import { gql } from "apollo-boost";

const UPDATE_PRODUCT_LISTING = gql`
  mutation manageProductListing($input: ProductListingAccess) {
    manageProductListing(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_PRODUCT_LISTING;
