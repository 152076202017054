import React, { useState, useContext, useEffect } from "react";
import { Button, Card, Caption, Collapsible, Form, FormLayout, Layout, TextStyle } from "@shopify/polaris";

// import propTypes
import PropTypes from "prop-types";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner } from "lib/components";
import AdvanceItem from "./advanceLine";

const AdvancePaymentOption = (props) => {
  const { cms = {} } = useContext(PrivateContext);

  const {
    advanceTermData,
    bannerData,
    isAdvanceBanner,
    isOpen,
    loading,
    responseData,
    responseError,
    setIsAdvanceBanner,
    setIsUpdatedAdvance,
    setSubmitEnable,
    setValues,
    updateAt,
  } = props;

  const [allSuppliers, setAllSuppliers] = useState([]);
  const [state, setState] = useState({
    count: 1,
    advancedValue: [
      {
        status: null,
        dateOfPayment: null,
        vendorId: null,
      },
    ],
  });
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });

  useEffect(() => {
    const { supplierRows = [] } = responseData || {};
    if (!loading && (responseError || !responseData)) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.advanceOptionUnavailable"),
      });
      return;
    }

    const allSupplierValues = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: supplierId, brandName }) => ({
        label: brandName,
        value: supplierId,
      }));
    setAllSuppliers(allSupplierValues);
  }, [cms, loading, responseData, responseError]);

  useEffect(() => {
    const advancedValueLength = advanceTermData.length || 0;
    const isEqualArray = baseHelper.isItemEqual(advanceTermData, state.advancedValue);
    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        advancedValue: advanceTermData,
      });
    }
  }, [advanceTermData, state.advancedValue]);

  const updateVal = (option, val, item) => {
    setIsUpdatedAdvance(true);
    const date = constant.DATE_OF_PAYMENT;
    setBanner({ isOpen: false, status: "", title: "" });
    const { advancedValue } = state;
    let duplicate = false;
    if (option === "option" && advancedValue[item]) {
      advancedValue[item].vendorId = null;
    }
    if (option === constant.VENDOR_ID) {
      advancedValue.forEach((advanceData) => {
        if (advanceData.vendorId === val && advancedValue[item]) {
          advancedValue[item].vendorId = null;
          setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.rowAlreadyExists") });
          duplicate = true;
        }
        return null;
      });
    }
    if (duplicate) {
      return;
    }
    if (option === date && advancedValue[item]) {
      advancedValue[item][date] = val;
    } else if (advancedValue[item]) {
      advancedValue[item][option] = val;
    }

    setState({ ...state, advancedValue });
    setValues(advancedValue);
    setSubmitEnable(true);
  };

  const add = () => {
    const { advancedValue, count } = state;
    if (advancedValue.length) {
      const line = advancedValue[advancedValue.length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setSubmitEnable(false);
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
        return;
      }
    }
    const orderStatus = constant.STATUS;
    const date = constant.DATE_OF_PAYMENT;
    const row = { [orderStatus]: null, [date]: null, vendorId: null };
    advancedValue.push(row);
    setState({ ...state, count: count + 1, advancedValue });
  };

  const removeItem = (item) => {
    const { advancedValue } = state;
    advancedValue.splice(item, 1);
    setState({ ...state, count: state.count - 1, advancedValue });
    setSubmitEnable(true);
  };

  const renderAdvanceItems = () => {
    const { count, advancedValue } = state;
    return (
      <AdvanceItem
        advancedValue={advancedValue}
        allSuppliers={allSuppliers}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
      />
    );
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  return (
    <Collapsible open={isOpen}>
      <Layout.AnnotatedSection
        title={cms("section.advancePaymentOption.layout.title")}
        description={cms("section.advancePaymentOption.layout.description")}
      >
        <Card sectioned>
          <TextStyle variation="strong">{cms("section.advancePaymentOption.card.title")}</TextStyle>
          <TextStyle variation="subdued">
            <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
          </TextStyle>
          <br />
          <TextStyle>{cms("section.advancePaymentOption.card.description")}</TextStyle>
          <br />
          {isAdvanceBanner
            ? bannerData.isOpen && (
                <>
                  <br />
                  <Banner
                    isOpen={bannerData.isOpen}
                    status={bannerData.status}
                    title={bannerData.title}
                    onDismiss={() => setIsAdvanceBanner(false)}
                  />
                  <br />
                </>
              )
            : banner.isOpen && (
                <>
                  <br />
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    onDismiss={() => dismissBanner()}
                  >
                    {banner.message}
                  </Banner>
                  <br />
                </>
              )}
          <Form>
            <FormLayout>
              {renderAdvanceItems()}
              {
                <Button plain id="addLink" onClick={add}>
                  {cms("button.addVendor")}
                </Button>
              }
            </FormLayout>
          </Form>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

AdvancePaymentOption.propTypes = {
  updateAt: PropTypes.string,
  setSubmitEnable: PropTypes.func,
  setValues: PropTypes.func,
};

AdvancePaymentOption.defaultProps = {
  updateAt: "",
  setSubmitEnable: () => {},
  setValues: () => {},
};

export default withFeature(withErrorBoundary(AdvancePaymentOption), { feature: constant.ADVANCE_SCHEDULE_PAYMENT });
