import { gql } from "apollo-boost";

const GET_MARKUP = gql`
  query getMarkup($input: SellerId!) {
    getMarkup(input: $input) {
      data {
        _id
        sellerId
        advance {
          markup
          productTypeId
          type
          vendorId
        }
        type
        ruleBy
        markup
        createdAt
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_MARKUP;
