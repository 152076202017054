import React, { useContext } from "react";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import polaris packages
import { Card } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const ProviderMaintenance = () => {
  const { cms } = useContext(PrivateContext);
  return (
    <Card>
      <Card.Section>{cms("common.maintenance.description")}</Card.Section>
    </Card>
  );
};

export default withFeature(withErrorBoundary(ProviderMaintenance, { feature: constant.MAINTENANCE }));
