import { gql } from "apollo-boost";

const UPDATE_PROFILE = gql`
  mutation UpdateBusinessInfo($input: UpdateBusinessInfo!) {
    updateBusinessInfo(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_PROFILE;
