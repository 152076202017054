import { gql } from "apollo-boost";

const GET_PAYMENT_REPORT_SETTING = gql`
  query getPaymentReportSetting {
    getPaymentReportSetting {
      data {
        report {
          vendorPaymentStatus {
            label
            isHideCsv
            isHidePdf
          }
          vendorPaidDate {
            label
            isHideCsv
            isHidePdf
          }
          sellerCommission {
            label
            isHideCsv
            isHidePdf
          }
        }
        updatedAt
      }
      error
      status
    }
  }
`;
export default GET_PAYMENT_REPORT_SETTING;
