import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// import constant
import constant from "lib/constant/constant";

// import helper components and context
import { SkeletonAnnotated } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

// import gql
import { UPDATE_TAX_INFO } from "app/userManagement/apollo/mutation";

const EditBusinessTaxInfo = (props) => {
  const { setBanner } = props;
  const { currentUser, isLoading, history, cms } = useContext(PrivateContext);
  const { taxSetting = {} } = currentUser;

  const { gql } = constant;

  const [updateTaxInfo, { loading: updateTaxInfoLoading }] = useMutation(UPDATE_TAX_INFO);

  const [sellerData, setSellerData] = useState({
    company: (taxSetting && taxSetting.company) || "",
    customs: (taxSetting && taxSetting.customs) || "",
    vat: (taxSetting && taxSetting.vat) || "",
  });

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const handleChangeEvent = (field, value) => {
    setIsSubmitEnabled(true);
    setSellerData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    setIsSubmitEnabled(false);
    try {
      const res = await updateTaxInfo({
        variables: {
          input: sellerData,
        },
      });
      const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_TAX_INFO);
      if (responseError) {
        setBanner({
          title: responseError,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }

      setBanner({
        title: cms("message.success.profileUpdated"),
        status: constant.SUCCESS,
        isOpen: true,
      });
    } catch (e) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("common.message.error.somethingWentWrong"),
        isOpen: true,
      });
    }
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      <Layout.AnnotatedSection
        title={cms("section.businessTax.title")}
        description={cms("section.businessTax.description")}
      >
        <Card
          title={cms("section.businessTax.title")}
          primaryFooterAction={{
            content: cms("common.button.submit"),
            key: "submitButton",
            onAction: () => onSubmit(),
            loading: updateTaxInfoLoading,
            disabled: updateTaxInfoLoading || !isSubmitEnabled,
          }}
          secondaryFooterActions={[
            {
              content: cms("common.button.cancel"),
              key: "cancelButton",
              onAction: () => history.push("/setting"),
            },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.businessTax.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                key="company"
                label={cms("section.businessTax.label.company")}
                placeholder={cms("section.businessTax.placeholder.company")}
                value={sellerData.company}
                maxLength={20}
                onChange={(value) => handleChangeEvent(constant.COMPANY, value)}
              />
              <TextField
                key="customs"
                label={cms("section.businessTax.label.customs")}
                placeholder={cms("section.businessTax.placeholder.customs")}
                value={sellerData.customs}
                maxLength={20}
                onChange={(value) => handleChangeEvent(constant.CUSTOMS, value)}
              />
              <TextField
                label={cms("section.businessTax.label.vat")}
                placeholder={cms("section.businessTax.placeholder.vat")}
                key="vat"
                value={sellerData.vat}
                maxLength={20}
                onChange={(value) => handleChangeEvent(constant.VAT, value)}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

EditBusinessTaxInfo.defaultProps = {
  setBanner: () => {},
};

EditBusinessTaxInfo.propTypes = {
  setBanner: PropTypes.func,
};

export default EditBusinessTaxInfo;
