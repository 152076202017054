import { gql } from "apollo-boost";

const UPDATE_LAKE_VARIANT = gql`
  mutation updateLakeVariant($input: UpdateLakeVariant) {
    updateLakeVariant(input: $input) {
      data
      error
      status
    }
  }
`;

export default UPDATE_LAKE_VARIANT;
