import React, { useContext, useState, useEffect, useRef } from "react";
import { Autocomplete, FormLayout, Select, TextField } from "@shopify/polaris";
import { useQuery } from "react-apollo";
import PropsType from "prop-types";
import _ from "lodash";

import GET_PRODUCT_LIST from "app/orders/apollo/queries/productList";

// import lib
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Spinner } from "lib/components";

const MoreFilters = (props) => {
  const {
    loading,
    queryParam,
    selectedFilter,
    selectedOptions,
    setBanner,
    setProductTag,
    setSelectedFilter,
    setSelectedOptions,
    setTaggedWith,
    taggedWith,
    searchInput,
    setSearchInput,
    setTagInputValue,
    productList,
    setProductList,
  } = props;

  const { history, cms } = useContext(PrivateContext);
  const [inputValue, setInputValue] = useState("");

  const inputData = {
    search: searchInput,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(queryParam.perPage, 10) || 10,
  };

  const { error: errorProductList, loading: loadingProductList, data: dataProductList } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const productListResponseResponseData = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
  const productListResponseResponseError = baseHelper.getResponseError(dataProductList, constant.gql.GET_PRODUCT_LIST);
  useEffect(() => {
    if (productListResponseResponseData && productListResponseResponseData.productList) {
      const productOtion = (productListResponseResponseData.productList || []).map((item) => ({
        value: item._id,
        label: baseHelper.ucFirst(item.title),
      }));
      setProductList(productOtion);
    }
    if (productListResponseResponseError) {
      setBanner({ status: constant.CRITICAL, title: productListResponseResponseError, isOpen: true });
    }
    if (errorProductList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorProductList), isOpen: true });
    }
  }, [
    dataProductList,
    errorProductList,
    productListResponseResponseData,
    productListResponseResponseError,
    setBanner,
    setProductList,
  ]);

  const sendQuery = (query) => {
    const filterRegex = new RegExp(query, "i");
    const resultOptions = productList.filter((option) => option.label.match(filterRegex));
    let endIndex = resultOptions.length - 1;
    if (resultOptions.length === 0) {
      endIndex = 0;
    }
    setProductList(resultOptions);
    setSearchInput(query);
  };

  const delayedQuery = useRef(_.debounce((value) => sendQuery(value), 500)).current;

  const updateText = (value) => {
    setInputValue(value);
    delayedQuery(value);
  };

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
  };

  const filterOptions = [
    { label: cms("section.orderList.label.brandName"), value: constant.VENDOR },
    { label: cms("section.orderList.label.orderId"), value: constant.ORDER_NUMBER },
    { label: "Product Name", value: constant.PRODUCT },
  ];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
  };

  const textField = (
    <Autocomplete.TextField
      onChange={(value) => {
        updateText(value);
      }}
      label={cms("section.orderList.label.like")}
      value={inputValue}
      disabled={loadingProductList}
    />
  );

  const handleAutocomlete = (value = "") => {
    const sData = [];
    value.map((item) => {
      const pData = productList.find((pItem) => pItem.value === item);
      if (pData) {
        sData.push(pData?.label?.toLowerCase());
      }
      return true;
    });

    setSelectedOptions(value);
    setTagInputValue(sData);
    baseHelper.setUrl(history, { list_search: sData, page: 1 });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <FormLayout>
        <Select
          label={cms("section.orderList.label.allOrder")}
          value={selectedFilter || ""}
          placeholder={cms("section.orderList.label.select")}
          onChange={handleSelectChange}
          options={filterOptions}
        />
        {(selectedFilter && selectedFilter !== constant.PRODUCT && (
          <TextField label={cms("section.orderList.label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          null}
        {selectedFilter === constant.PRODUCT && (
          <div>
            <Autocomplete
              allowMultiple
              options={productList?.length ? productList : [""]}
              selected={selectedOptions || null}
              textField={textField}
              onSelect={(value) => handleAutocomlete(value)}
              listTitle={inputValue && !productList?.length ? "No such results found" : "Select Product Name"}
            />
          </div>
        )}
      </FormLayout>
    </>
  );
};
MoreFilters.defaultProps = {
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};
export default MoreFilters;
