import PropTypes from "prop-types";

const dropShipProp = {
  type: {
    data: PropTypes.shape({
      isDropShip: PropTypes.bool,
    }),
    handleChange: PropTypes.func,
    errorMessage: PropTypes.objectOf(PropTypes.string),
  },
};

const consignmentProp = {
  type: {
    data: PropTypes.shape({
      isDropShip: PropTypes.bool,
    }),
    handleChange: PropTypes.func,
    errorMessage: PropTypes.objectOf(PropTypes.string),
  },
};

export { dropShipProp, consignmentProp };
