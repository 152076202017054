import React, { useContext, useEffect } from "react";
import { Checkbox, Stack, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import constant from "lib/constant/constant";
import CarrierService from "./sendabox/carrierService";

const Sendabox = (props) => {
  const { cms, dropship, sendaboxApiKeyAndAccessKey, setOnSubmitError } = useContext(FulfillmentContext);
  const { isError, handleSendabox, error, handleValidation } = props;
  const { apiKey = "", accessKey = "", shippingRateName = "", isShippingRateRequired = true } =
    (dropship && dropship.sendabox) || {};

  useEffect(() => {
    if (
      dropship &&
      dropship.sendabox &&
      apiKey &&
      accessKey &&
      sendaboxApiKeyAndAccessKey &&
      !shippingRateName &&
      isError
    ) {
      setOnSubmitError(cms("message.error.shippingRateName"));
    } else {
      setOnSubmitError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropship, dropship.sendabox, apiKey, accessKey, sendaboxApiKeyAndAccessKey, shippingRateName]);

  return (
    <>
      <TextField
        label={`${cms("label.api")}*`}
        value={apiKey}
        placeholder={cms("placeholder.api")}
        onChange={(value) => [handleSendabox(constant.API_KEY, value), handleValidation(constant.API_KEY, value)]}
        onBlur={() => {
          handleValidation(constant.API_KEY, apiKey);
        }}
        error={error && !apiKey && error.apiKey}
      />
      <br />
      <TextField
        label={`${cms("label.accessKey")}*`}
        value={accessKey}
        placeholder={cms("placeholder.accessKey")}
        onChange={(value) => [handleSendabox(constant.ACCESS_KEY, value), handleValidation(constant.ACCESS_KEY, value)]}
        onBlur={() => {
          handleValidation(constant.ACCESS_KEY, accessKey);
        }}
        error={error && !accessKey && error.accessKey}
      />
      <br />
      {apiKey && accessKey && sendaboxApiKeyAndAccessKey && (
        <>
          <Stack vertical>
            <Stack>
              <Stack.Item fill>
                <Checkbox
                  label={cms("label.orderline")}
                  helpText={cms("helpText.orderline")}
                  checked={dropship && dropship.sendabox && dropship.sendabox.isAutoFulfill}
                  onChange={(value) => handleSendabox(constant.IS_AUTO_FULFILL, value)}
                />
              </Stack.Item>
            </Stack>
            <Stack>
              <Stack.Item>
                <Checkbox
                  label={cms("label.shippingRate")}
                  helpText={cms("helpText.shippingRate")}
                  checked={isShippingRateRequired}
                  onChange={(value) => {
                    handleSendabox(constant.IS_SHIPPING_RATE_REQUIRED, value);
                  }}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </>
      )}
      {dropship && dropship.sendabox && apiKey && accessKey && sendaboxApiKeyAndAccessKey && (
        <div>
          <CarrierService error={error} />
          <br />
          <TextField
            label={`${cms("label.customRateName")}*`}
            value={shippingRateName}
            placeholder={cms("placeholder.customRateName")}
            onChange={(value) => [
              handleSendabox(constant.CUSTOM_RATE_NAME, value),
              handleValidation(constant.CUSTOM_RATE_NAME, value),
            ]}
            onBlur={() => {
              handleValidation(constant.CUSTOM_RATE_NAME, shippingRateName || "");
            }}
            error={error && !shippingRateName && error.shippingRateName}
          />
        </div>
      )}
    </>
  );
};

Sendabox.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  dropship: PropTypes.objectOf(PropTypes.shape()).isRequired,
  handleSendabox: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleValidation: PropTypes.func.isRequired,
};

Sendabox.defaultProps = {
  error: {},
};

export default withFeature(withErrorBoundary(Sendabox), {
  feature: constant.SENDABOX,
});
