import React, { useState, useContext, useCallback } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { CashDollarMajor, ReceiptMajor } from "@shopify/polaris-icons";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "app/payments/modules/operator/features/list/propTypes";

// import cms and subFeature
import InvoiceModal from "app/payments/modules/generic/list/modal/invoice";
import actionData from "app/payments/modules/operator/features/list/cms/resourceTableData";
import View from "./view";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { activePopover, item, isExcludeShippingAmount = false, popoverDisable, setActivePopover, setBanner } = props;
  const { _id: id, orderId, vendorId, isRefunded: isLineItemRefunded } = item;

  const { mainLink } = actionData(cms);
  const [active, setActive] = useState(false);
  const [invoiceModalActive, setInvoiceModalActive] = useState(false);
  const [orderLineItemId, setOrderLineItemId] = useState("");
  const [commissionVendor1, setCommissionVendor] = useState("");
  const [shippingAmount, setShippingAmount] = useState("");
  const [shippingTaxValue, setShippingTaxValue] = useState("");
  const [moneyFormat1, setMoneyFormat] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorDetail, setVendorDetail] = useState("");
  const [fulfillmenttype, setFulfillmentType] = useState("");

  const isVendor = baseHelper.isVendor(currentUser);
  const { CLOSED, COLLECT, PAID_AND_AVAILED, PAY_AND_COLLECT, REJECTED, RESERVE, SERVICE, SHIPPED } = constant;

  // eslint-disable-next-line no-shadow
  const handleChange = (
    value,
    id,
    orderId,
    commissionVendor,
    moneyFormat,
    vendor,
    shippingAmount = "",
    shippingTax,
    vendorId
  ) => {
    if (value === constant.INVOICE) {
      setInvoiceModalActive(true);
    }
    if (value === constant.gql.PAY_VENDOR) {
      setActive(true);
      setOrderLineItemId(id);
      setCommissionVendor(commissionVendor);
      setMoneyFormat(moneyFormat);
      setVendorDetail(vendorId);
      setVendorName(vendor);
      setShippingTaxValue(shippingTax);
      setShippingAmount(shippingAmount);
    }
  };

  const handlePopover = useCallback(
    (itemId, type) => {
      setActivePopover({ [itemId]: !activePopover[itemId] });
      setFulfillmentType(type);
    },
    [activePopover, setActivePopover]
  );

  const renderOrderRow = (items) => {
    const {
      _id,
      accountingStatus,
      actualQuantity,
      commissionSeller,
      commissionTax,
      commissionVendor,
      createdAt,
      dueDate,
      fulfillmentType,
      isExpressPayment,
      isAutoExpiry,
      isAutoReject,
      isOrderBasedCommission,
      isRefund,
      moneyFormat,
      orderNumber,
      paidVia,
      price,
      priceTaxRate,
      rejectedByVendor,
      quantity,
      // eslint-disable-next-line no-shadow
      shippingAmount,
      shippingTax,
      shopifyOrderDate,
      vendor,
      vendorId: userId,
      returnedQuantity,
      // eslint-disable-next-line no-shadow
    } = items;
    let { status } = items;

    const returnEnable = (returnedQuantity && returnedQuantity > 0) < (actualQuantity || quantity);

    const isDispute = accountingStatus === constant.DISPUTE;

    const totalShippingAmount = parseFloat(shippingAmount || 0) + parseFloat(shippingTax || 0);

    const totalOrderPayout = parseFloat((!rejectedByVendor && commissionVendor) || 0);

    const getItemCommissionTax = () => {
      const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
      const totalPriceInclusiveTax = baseHelper.getPrice(price * quantity);
      const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
      const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
      const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
      const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
      return sellerTaxOnCommission;
    };

    const isReserve = [SHIPPED, CLOSED].includes(status) && fulfillmentType === RESERVE;
    const isCollect = [SHIPPED, CLOSED].includes(status) && fulfillmentType === COLLECT;
    const isService = [SHIPPED, CLOSED].includes(status) && fulfillmentType === SERVICE;
    const isRefunded = isRefund && (fulfillmentType === COLLECT || fulfillmentType === RESERVE) && actualQuantity === 0;
    const isReject = [REJECTED].includes(status);
    if (isReserve || isCollect || isService) {
      status = isService ? PAID_AND_AVAILED : PAY_AND_COLLECT;
    }

    if (isRefunded) {
      status = REJECTED;
    }

    if (isRefunded) {
      status = REJECTED;
    }

    if (isService) {
      status = PAID_AND_AVAILED;
    }

    const autoStatus = isRefund && isAutoReject ? constant.AUTO_REJECT : isAutoExpiry && constant.NO_SHOW;
    status = autoStatus || status;

    const { ERROR, INVOICE, PAYVENDOR, UNPAID } = constant;
    const handleClick = () => {
      history.push(`${mainLink.orderNumber}${orderId}`);
    };

    const badgeStatus = baseHelper.ucFirst(status);
    const badgeType = baseHelper.getBadgeType(status);

    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);

    const isPayoutAllowed = [ERROR, UNPAID].includes(accountingStatus);

    const option = [
      {
        content: cms("label.invoice"),
        icon: ReceiptMajor,
        onAction: () => handleChange(INVOICE, id, orderId, commissionVendor, moneyFormat, vendor),
        disabled: [REJECTED, constant.AUTO_REJECT, constant.NO_SHOW].includes(status) || rejectedByVendor,
      },
    ];
    const unpaid = {
      content: cms("label.payVendor"),
      icon: CashDollarMajor,
      onAction: () =>
        handleChange(
          PAYVENDOR,
          id,
          orderId,
          commissionVendor,
          moneyFormat,
          vendor,
          shippingAmount,
          shippingTax,
          userId
        ),
      disabled: ((!isPayoutAllowed || isReject) && !returnEnable) || isDispute || rejectedByVendor || isExpressPayment,
    };
    if (isPayoutAllowed) {
      option.unshift(unpaid);
    }
    if (!isPayoutAllowed) {
      option.push(unpaid);
    }

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={handleClick}>
                {"#"}
                {orderNumber}
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {`${cms("label.creationDate")}: ${baseHelper.formatDate(createdAt || shopifyOrderDate, true)}`}
              </TextStyle>
              <br />
              <div className="hidden" title={vendor}>
                {`${cms("label.vendor")}: `}
                {(isVendor && vendor) || <Link url={`/providers/view/${vendorId}`}>{vendor}</Link>}
              </div>
              <TextStyle>
                {` ${cms("label.commission")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}
              </TextStyle>
              <br />
              {priceTaxRate && !commissionTax && !isOrderBasedCommission ? (
                <TextStyle>
                  {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(
                    getItemCommissionTax()
                  )} (Incl.)`}
                </TextStyle>
              ) : (
                <TextStyle>
                  {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(commissionTax)}`}
                </TextStyle>
              )}
              <br />
              <TextStyle>
                {` ${cms("label.vendorPayout")}: ${moneyFormat} ${baseHelper.getPrice(totalOrderPayout)}`}
              </TextStyle>
              {!isExcludeShippingAmount && !!shippingAmount && (
                <>
                  <br />
                  <TextStyle>
                    {`${cms("label.shipping")}: ${moneyFormat} ${baseHelper.getPrice(totalShippingAmount)}`}
                  </TextStyle>
                </>
              )}
              <br />
              {!(isReserve || isCollect) && (
                <>
                  <TextStyle>{`${cms("label.dueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>
                  <br />
                </>
              )}
              {/* <br />
              {!paidVia && (
                <TextStyle>{`${cms("label.manual")}: ${baseHelper.formatDate(createdAt, false)}`}</TextStyle>
              )} */}
            </Caption>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              setActive={() => (popoverDisable ? setActivePopover(false) : handlePopover(_id, fulfillmentType))}
              options={option}
            />
          </Stack.Item>
        </Stack>
        <br />
        <Stack.Item style={topMargin10px}>
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                {rejectedByVendor && <Badge status={constant.CRITICAL}>Rejected By Vendor</Badge>}
                {isLineItemRefunded && <Badge status={constant.CRITICAL}>Refunded</Badge>}
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>

      {active && (
        <View
          active={active}
          handleClose={() => setActive(!active)}
          orderLineItemId={orderLineItemId}
          commissionVendor={commissionVendor1}
          shippingAmount={shippingAmount}
          shippingTax={shippingTaxValue}
          moneyFormat={moneyFormat1}
          vendor={vendorName}
          vendorId={vendorDetail}
          setBanner={setBanner}
          cms={cms}
          fulfillmentType={fulfillmenttype}
        />
      )}
      {invoiceModalActive && (
        <InvoiceModal
          active={invoiceModalActive}
          handleClose={() => setInvoiceModalActive(!invoiceModalActive)}
          item={item}
          id={id}
          setActive={setInvoiceModalActive}
          setBanner={setBanner}
        />
      )}
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;
