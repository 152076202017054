import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { passwordSchema, newPasswordSchema, confirmationPasswordSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD } = constant;
const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case PASSWORD:
      promise = validateValue(passwordSchema(cms), { password: value });
      break;
    case NEW_PASSWORD:
      promise = validateValue(newPasswordSchema(cms), { newPassword: value });
      break;
    case CONFIRM_PASSWORD:
      promise = validateValue(confirmationPasswordSchema(cms), {
        newPassword: value.newPassword,
        confirmationPassword: value.confirmPassword,
      });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
