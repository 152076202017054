import React, { useEffect, useState, useContext } from "react";
import { Modal, Stack, TextField, TextContainer } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import from lib
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

// import from gql
import { BULK_SUPPLIER_ACTION } from "app/vendors/apollo/mutations";
import { GET_VENDOR_LIST } from "app/vendors/apollo/queries";

// import cms
import cmsVendorList from "app/vendors/modules/operator/feature/list/cms/vendorListCMS";

const DeleteVendor = (props) => {
  const {
    openModal,
    isReasonField,
    setDeleteModal,
    setBanner,
    dataToFetch,
    singleVendor,
    selectedItems,
    isVerifyVendors,
    setIsVerifyVendors,
  } = props;
  const {
    cms,
    currentUser: { ecommercePlatform },
  } = useContext(PrivateContext);
  const vendorListCMS = cmsVendorList(cms);
  const { label, sectionContent, button, message } = vendorListCMS;
  const vendorMessage = <TextContainer>{`${sectionContent.deleteVendorSelected}`}</TextContainer>;
  const [selectedButton, setSelectedButton] = useState("");
  const [reason, setReason] = useState("");

  const handleModalClose = () => {
    setReason("");
    setDeleteModal(false);
  };

  // useMutation calls.
  const [deleteVendors, { data, loading, error }] = useMutation(BULK_SUPPLIER_ACTION, {
    refetchQueries: [
      {
        query: GET_VENDOR_LIST,
        variables: {
          input: dataToFetch,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleDeleteVendor = async (val, action) => {
    const singleRecord = singleVendor;
    const vendorIds = (singleRecord && singleRecord.length > 0 && singleRecord) || selectedItems;
    setSelectedButton(action);
    const fromData = {
      variables: {
        input: { reason, deleteFromShopify: val, ids: vendorIds, key: constant.DELETE.toLowerCase() },
      },
    };
    await deleteVendors(fromData);
  };

  useEffect(() => {
    if (isVerifyVendors) {
      const fromData = {
        variables: { input: { ids: selectedItems, key: constant.VERIFY } },
      };
      deleteVendors(fromData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerifyVendors]);

  // useEffect for deleteVendor Data Mutation.
  useEffect(() => {
    setIsVerifyVendors(false);
    setReason("");
    const responseDeleteData = baseHelper.getResponseData(data, constant.gql.BULK_SUPPLIER_ACTION);
    const responseDeleteError = baseHelper.getResponseError(data, constant.gql.BULK_SUPPLIER_ACTION);
    if (responseDeleteData) {
      setDeleteModal(false);
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.running_background });
    }
    if (responseDeleteError) {
      setDeleteModal(false);
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseDeleteError });
    }
  }, [data, message.running_background, setBanner, setDeleteModal, setIsVerifyVendors]);

  useEffect(() => {
    if (!isReasonField) {
      setReason("");
    }
  }, [isReasonField]);

  useEffect(() => {
    setReason("");
    if (error) {
      setBanner({ title: errorHelper.parse(error), isOpen: true, status: constant.CRITICAL });
    }
  }, [error, setBanner]);

  return (
    <Modal
      key={label.deleteModal}
      open={openModal}
      onClose={handleModalClose}
      title={label.delete}
      primaryAction={{
        content: button.deleteFromMc,
        onAction: () => handleDeleteVendor(false, label.primaryDelete),
        loading: loading && selectedButton === label.primaryDelete,
        disabled: loading && selectedButton !== label.primaryDelete,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: ecommercePlatform
            ? cms("button.providerList.deleteProvider.ecommerce", { item: ecommercePlatform })
            : button.deleteFromShop,
          onAction: () => handleDeleteVendor(true, label.secondaryDelete),
          loading: loading && selectedButton === label.secondaryDelete,
          disabled: loading && selectedButton !== label.secondaryDelete,
          destructive: true,
        },
      ]}
      sectioned
    >
      <Modal.Section>
        <Stack.Item>{vendorMessage}</Stack.Item>
        {isReasonField && (
          <TextField label={label.reason} value={reason} onChange={(val) => setReason(val)} multiline={4} />
        )}
      </Modal.Section>
    </Modal>
  );
};

DeleteVendor.defaultProps = {
  openModal: false,
  isReasonField: false,
  setDeleteModal: () => {},
  setBanner: () => {},
  dataToFetch: {},
  singleVendor: [],
  selectedItems: [],
  isVerifyVendors: false,
  setIsVerifyVendors: () => {},
};

DeleteVendor.propTypes = {
  openModal: PropTypes.bool,
  isReasonField: PropTypes.bool,
  setDeleteModal: PropTypes.func,
  setBanner: PropTypes.func,
  dataToFetch: PropTypes.shape(PropTypes.object),
  singleVendor: PropTypes.PropTypes.arrayOf(PropTypes.string),
  selectedItems: PropTypes.PropTypes.arrayOf(PropTypes.string),
  isVerifyVendors: PropTypes.bool,
  setIsVerifyVendors: PropTypes.func,
};

export default withFeature(withErrorBoundary(DeleteVendor), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
