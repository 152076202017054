import { gql } from "apollo-boost";

const GET_SELLER_BRANDING = gql`
  query getSellerBranding {
    getSellerBranding {
      data {
        brand {
          favicon {
            imageUrl
            imageId
          }
          finish {
            cover {
              imageId
              imageUrl
              updatedAt
            }
            description {
              description
              updatedAt
            }
          }
          welcome {
            description {
              description
              updatedAt
            }
            cover {
              imageId
              imageUrl
              updatedAt
            }
          }
        }
        brandName
        footerText
        isHideMcInfo
        loginText
        logo
        plan {
          isBranding
          name
          price
        }
        register {
          desc
          title
        }
        roles {
          name
        }
        shop
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_SELLER_BRANDING;
