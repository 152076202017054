import React from "react";
import { Modal } from "@shopify/polaris";

function ModalPopup(props) {
  const { open, activator, onClose, title, primaryAction, secondaryActions, children } = props;

  return (
    <Modal
      activator={activator}
      open={open}
      onClose={onClose}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>{children}</Modal.Section>
    </Modal>
  );
}
export default ModalPopup;
