import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import ClickReserve from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const HybridClickReserve = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <div className="hybridClickReserve">
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.clickReserve.title")}</Heading>
          </Stack.Item>
          <br />
          <TextStyle variation="subdued">{cms("hybrid.clickReserve.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <ClickReserve method="hybridClickReserve" />
    </div>
  );
};

export default HybridClickReserve;
