import { gql } from "apollo-boost";

const PAY_VENDOR = gql`
  mutation payVendor($input: PayVendor!) {
    payVendor(input: $input) {
      status
      data
      error
    }
  }
`;
export default PAY_VENDOR;
