import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { BulkInvite } from "app/vendors/modules/generic/feature/add/subFeatures";

const OperatorBulkInvite = (props) => {
  const {
    // learnMore
    setBanner,
  } = props;
  // return <BulkInvite setBanner={setBanner} learnMore={learnMore} />;
  return <BulkInvite setBanner={setBanner} />;
};

OperatorBulkInvite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  // learnMore: PropTypes.func.isRequired,
};
export default withFeature(OperatorBulkInvite, {
  feature: constant.VENDOR_INVITE_CSV,
  // subFeature: constant.VENDOR_INVITE_CSV,
});
