// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_QUOTE_LIST = gql`
  query getQuoteList($input: GetListInputV1!) {
    getQuoteList(input: $input) {
      data {
        count
        quotes {
          _id
          customerName
          depositAmount
          remainingAmount
          depositOrderId
          finalOrderId
          history {
            time
            price
            type
            location
            date
            orderId
          }
          orders {
            financialStatus
            name
            orderId
            orderNumber
          }
          location
          date
          customerEmail
          price
          message
          orderIds
          productId
          status
          createdAt
          title
          image
          vendor {
            id
            firstName
            lastName
            email
            role
          }
          seller {
            id
            firstName
            lastName
            email
            role
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_QUOTE_LIST;
