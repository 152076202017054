import { gql } from "apollo-boost";

// const GET_SHIPPING_BY_VENDOR = gql`
//   query {
//     getShippingByVendor {
//       error
//       data {
//         csvData {
//           Default
//           ProductID
//           KP
//           IR
//           AL
//           OM
//           MM
//           BH
//           AT
//           CC
//           IQ
//           IL
//           TJ
//           EE
//           MT
//           BA
//           PM
//           GD
//           NR
//           LB
//           RU
//           BY
//           AZ
//           ER
//           KM
//           DJ
//           CI
//           TD
//           AR
//           MZ
//           PF
//           FK
//           DM
//           BQ
//           PE
//           HM
//           SN
//           LK
//           AM
//           MV
//           LV
//           MC
//           IM
//           GH
//           AG
//           LR
//           CW
//           BZ
//           NU
//           KH
//           NorthAmerica
//           HU
//           GG
//           EG
//           ME
//           PN
//           PL
//           UM
//           TZ
//           NG
//           FJ
//           AW
//           Europe
//           BE
//           Asia
//           JE
//           FO
//           GI
//           GR
//           NZ
//           SR
//           TT
//           HT
//           AD
//           IO
//           ID
//           JP
//           KW
//           GE
//           BV
//           IS
//           TR
//           FI
//           HR
//           LU
//           CF
//           ET
//           UY
//           SZ
//           NE
//           NA
//           ZA
//           SS
//           CR
//           CK
//           CU
//           MQ
//           TW
//           CN
//           NP
//           BT
//           CG
//           SM
//           GS
//           ST
//           ZW
//           SC
//           Oceania
//           DO
//           BD
//           MY
//           TH
//           CY
//           YT
//           NL
//           BJ
//           CV
//           BG
//           NI
//           TG
//           CM
//           CH
//           PG
//           LS
//           KN
//           FR
//           TM
//           DZ
//           RS
//           CL
//           UG
//           TK
//           SO
//           CentralAmerica
//           VC
//           BL
//           AI
//           GM
//           MU
//           HN
//           TF
//           MO
//           QA
//           MD
//           XK
//           PY
//           GN
//           EH
//           GY
//           ZM
//           MX
//           EC
//           MN
//           PS
//           ES
//           LT
//           CZ
//           SE
//           CD
//           MG
//           JM
//           PA
//           TL
//           TO
//           HK
//           KZ
//           AF
//           PH
//           CX
//           GL
//           AN
//           GA
//           SV
//           LC
//           TC
//           CO
//           NC
//           KE
//           KI
//           KR
//           DE
//           GB
//           GP
//           SI
//           AO
//           MK
//           BS
//           AU
//           MW
//           MS
//           TV
//           VE
//           GT
//           MA
//           CA
//           BO
//           KY
//           AX
//           BN
//           LA
//           KG
//           SJ
//           BF
//           UA
//           IT
//           BW
//           GF
//           RW
//           WS
//           BM
//           VU
//           Ly
//           US
//           IN
//           SA
//           UZ
//           YE
//           PK
//           JO
//           VN
//           SG
//           VA
//           IE
//           RO
//           SK
//           SH
//           MF
//           MR
//           VG
//           ML
//           SD
//           NO
//           GW
//           SX
//           NF
//           BR
//           SY
//           AE
//           PT
//           GQ
//           LI
//           RE
//           BI
//           DK
//           Africa
//           WF
//           SL
//           TN
//           SouthAmerica
//           Other
//           BB
//           SB
//         }
//       }
//     }
//   }
// `;

const GET_SHIPPING_BY_VENDOR = gql`
  query {
    getShippingByVendor {
      error
      data {
        csvData
      }
      status
    }
  }
`;

export { GET_SHIPPING_BY_VENDOR };
