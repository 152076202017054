import { gql } from "apollo-boost";

const GET_PRODUCT_LIST = gql`
  query getProductList($input: GetListInputV1) {
    getProductList(input: $input) {
      status
      error
      data {
        ... on SellerProductListData {
          count
          productList {
            _id
            approved
            compaign
            status
            title
            sku
            updatedAt
            vendor
            vendorId
            images {
              imageUrl
            }
            inventoryManagement
            listingQuantity
            liveConnect {
              platform
            }
            productId
            vendorShopifyProductId
            createdAt
            progressQuantity
            variants {
              barcode
              comparePrice
              customRule
              customLabel
              customCharge
              sku
              vendorDiscount {
                price
                type
              }
              inventoryQuantity
              inventoryManagement
            }
            published
            quantity
            isService
            serviceId
            isDraft
          }
        }
      }
    }
  }
`;
const GET_PRODUCT_LIST_PROVIDER = gql`
  query getProductList($input: GetListInputV1) {
    getProductList(input: $input) {
      status
      error
      data {
        ... on ProductListData {
          count
          productList {
            _id
            approved
            compaign
            createdAt
            images {
              imageUrl
            }
            inventoryManagement
            productId
            status
            title
            sku
            listingQuantity
            liveConnect {
              platform
            }
            updatedAt
            progressQuantity
            variants {
              barcode
              comparePrice
              customRule
              customLabel
              customCharge
              sku
              vendorDiscount {
                price
                type
              }
              inventoryQuantity
              inventoryManagement
            }
            isService
            quantity
            serviceId
            vendor
            vendorId
            vendorShopifyProductId
            isDraft
          }
        }
      }
    }
  }
`;
export { GET_PRODUCT_LIST, GET_PRODUCT_LIST_PROVIDER };
