import { gql } from "apollo-boost";

const GET_ORDER_LAKE_LINE = gql`
  query getOrderLakeLine($input: GetOrderLakeLine) {
    getOrderLakeLine(input: $input) {
      data {
        _id
        price
        accountingStatus
        shippingStatus
        isVendorFulfilled
        baseCostPrice
        basePrice
        baseTotal
        baseWrappingCost
        binPickingNumber
        #cost {
        #  priceExTax
        #  priceIncTax
        #  totalExTax
        #  totalIncTax
        #  wrappingExTax
        #}
        ecommercePlatform
        eventName
        fixedShippingCost
        isBundledProduct
        isRefunded
        name
        priceExTax
        priceIncTax
        priceTax
        quantity
        quantityShipped
        sku
        storeEbayItemId
        storeEbayTransactionId
        storeEventDate
        storeExternalId
        storeOptionSetId
        storeOrderAddressId
        storeOrderId
        storeParentOrderProductId
        storeLineItemId
        storeReturnId
        storeVariantId
        #tax {
        #  total
        #  costPrice
        #}
        type
        upc
        weight
        wrappingMessage
        wrappingName
        createdBy
        isSynced
        commissionSysAdmin
        commissionSeller
        commissionVendor
        dueDate
        image
        productId
        ownerId
        orderId
        updatedBy
        providerId
        storeStatus
        dispatchedDate
        trackingCompany
        trackingNumber
        createdAt
        updatedAt
        shippingAddress
        currency
        vendor {
          _id
          brandName
        }
        shippingData {
          _id
          ecommercePlatform
          ownerId
          storeOrderId
          storeShippingAddressId
          addressLineOne
          addressLineTwo
          baseCost
          baseHandlingCost
          city
          company
          #cost {
          #  exTax
          #  incTax
          #  handlingExTax
          #  handlingIncTax
          #}
          country
          countryISO2
          createdAt
          createdBy
          email
          firstName
          isSynced
          itemsShipped
          itemsTotal
          lastName
          orderId
          phone
          postcode
          shippingMethod
          shippingZoneName
          state
          storeCostTaxClassId
          storeHandlingCostTaxClassId
          storeShippingZoneId
          #tax {
          #  cost
          #  handlingCost
          #}
          updatedAt
          updatedBy
        }
        commissionType
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LAKE_LINE;
