// import packages
import React, { useContext } from "react";
import { Layout, Card, TextStyle, Link } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// helpers
import { baseHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import context
import { PublicContext } from "lib/context";
import config from "configuration";

// sub features
// import { TermsPolicy } from "app/public/modules/register/subFeatures";

const RegisterRole = () => {
  const { history, cms } = useContext(PublicContext);
  // const [isTermsModalActive, setIsTermsModalActive] = useState(false);
  const { TERMS_URL } = constant;
  const handleAction = (item) => {
    if (item.url) {
      window.location.href = item.url;
      return;
    }
    history.push(item.link);
  };

  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  const registerRoleList = [
    {
      id: "seller",
      title: cms("section.operator.title"),
      description: cms("section.operator.description"),
      url: "/register/seller",
      primaryButtonContent: cms("section.operator.button.primary"),
    },
  ];

  const registerVendorRole = {
    id: "vendor",
    title: cms("section.provider.title"),
    description: cms("section.provider.description"),
    link: "/register",
    primaryButtonContent: cms("section.provider.button.primary"),
  };
  if (isCustomDomain) {
    registerRoleList.push(registerVendorRole);
  }

  const renderRoleCard = (item) => (
    <Card
      sectioned
      key={item.id}
      title={item.title}
      primaryFooterAction={{
        id: `${item.role}Button`,
        content: item.primaryButtonContent,
        onAction: () => handleAction(item),
      }}
      secondaryFooterActions={[
        {
          id: `${item.role}CancelButton`,
          content: cms("common.button.cancel"),
          onAction: () => history.push("/login"),
        },
      ]}
    >
      <Card.Section>
        <p id={`${item.role}Description`}>{item.description}</p>
      </Card.Section>
    </Card>
  );

  const renderRegisterRoleList = () => {
    return registerRoleList.map(renderRoleCard);
  };

  return (
    <>
      {/* <TermsPolicy isOpen={isTermsModalActive} onClose={() => setIsTermsModalActive(!isTermsModalActive)} /> */}
      <Layout>
        <Layout.Section>{renderRegisterRoleList()}</Layout.Section>
        <Layout.Section>
          <br />
          <center>
            <TextStyle variation="subdued">
              {cms("footer").map((item) => {
                if (item && item.type && item.type === "br") {
                  return <br />;
                }
                if (item && item.type && item.type === "action") {
                  return (
                    <Link key={item.content} plain id="linkTerms" external url={TERMS_URL}>
                      {item.content}
                    </Link>
                  );
                }
                return <React.Fragment key={item.content}>{item.content}</React.Fragment>;
              })}
            </TextStyle>
          </center>
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withErrorBoundary(RegisterRole);
