import PropTypes from "prop-types";

const reviewProduct = {
  type: {
    isAdded: PropTypes.bool,
    isDeleted: PropTypes.bool,
    vendor: PropTypes.string,
    store: PropTypes.string,
  },
};

const reviewVariant = {
  type: {
    isAdded: PropTypes.bool,
    isDeleted: PropTypes.bool,
    date: PropTypes.string,
    vendor: PropTypes.string,
    vendorChange: PropTypes.string,
    sellerChange: PropTypes.string,
    store: PropTypes.string,
    sellerLabel: PropTypes.string,
  },
};

const variants = {
  type: {
    isAdded: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isUpdated: PropTypes.bool,
    position: PropTypes.number,
    sellerVariant: PropTypes.arrayOf(PropTypes.shape),
    variantDiff: PropTypes.arrayOf(PropTypes.shape),
    vendorVariant: PropTypes.arrayOf(PropTypes.shape),
  },
};

export { reviewProduct, reviewVariant, variants };
