import { gql } from "apollo-boost";

const ENABLE_DISABLE_API = gql`
  mutation enableDisableApi($input: EnableDisableApi) {
    enableDisableApi(input: $input) {
      status
      data
      error
    }
  }
`;

export default ENABLE_DISABLE_API;
