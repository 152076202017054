import { gql } from "apollo-boost";

const GET_VENDOR_ORDER_REPORT_LIST = gql`
  query getVendorOrderReportList($input: GetVendorReportListInput) {
    getVendorOrderReportList(input: $input) {
      status
      error
      data {
        commissionList {
          _id
          name
          commissionSeller
          totalPrice
        }
        count
      }
    }
  }
`;

export default GET_VENDOR_ORDER_REPORT_LIST;
