import React, { useState } from "react";
import { Layout, Banner } from "@shopify/polaris";
import WholesalePriceSetting from "./wholesalePrice";
import ManagePriceSetting from "./managePrice";

const ProductPrice = () => {
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  return (
    <div>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <WholesalePriceSetting setBannerStatus={setBannerStatus} />
      <ManagePriceSetting setBannerStatus={setBannerStatus} />
    </div>
  );
};

export default ProductPrice;
