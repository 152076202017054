import { gql } from "apollo-boost";

const LOGIN_BIGCOMMERCE_SELLER = gql`
  mutation loginBigCommerceSeller($input: LoginBigCommerceSeller) {
    loginBigCommerceSeller(input: $input) {
      data {
        token
      }
      error
      status
    }
  }
`;

const REGISTER_BIGCOMMERCE_SELLER = gql`
  mutation registerBigCommerceSeller($input: RegisterBigCommerceSeller) {
    registerBigCommerceSeller(input: $input) {
      data {
        parentToken
        token
      }
      error
      status
    }
  }
`;

const REGISTER_BIGCOMMERCE_WEBHOOK = gql`
  mutation registerWebhook {
    registerWebhook {
      status
      data
      error
    }
  }
`;

export { LOGIN_BIGCOMMERCE_SELLER, REGISTER_BIGCOMMERCE_SELLER, REGISTER_BIGCOMMERCE_WEBHOOK };
