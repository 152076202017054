import React, { useContext } from "react";
import { Select, FormLayout } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { CanadaPostShipping, NZPostShipping } from "./thirdPartyShipping";

const ThirdPartyOptions = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    accountNumber,
    canadaPost,
    clientId,
    clientSecret,
    handleAccountNumber,
    handleCanadaPostChange,
    handleClientId,
    handleClientSecret,
    handleSelectChange,
    handleSiteCode,
    selected,
    setError,
    siteCode,
  } = props;

  const options = [
    { label: cms("label.canadaPost"), value: constant.CANADA_POST, disabled: false },
    { label: cms("label.nzShip"), value: constant.NZPOST, disabled: false },
    { label: cms("label.shippo"), value: constant.SHIPPO, disabled: true },
    { label: cms("label.eazyShip"), value: constant.EAZY_SHIP, disabled: true },
    { label: cms("label.shipStation"), value: constant.SHIP_STATION, disabled: true },
    { label: cms("label.flvrCloud"), value: constant.FLVR_CLOUD, disabled: true },
  ];

  const label = `${cms("label.thirdParty")} ${cms("label.shipping")} ${cms("label.option")}*`;

  return (
    <FormLayout>
      <Select label={label} options={options} onChange={handleSelectChange} value={selected} />
      {selected === constant.NZPOST && (
        <NZPostShipping
          clientId={clientId}
          clientSecret={clientSecret}
          accountNumber={accountNumber}
          siteCode={siteCode}
          handleClientId={handleClientId}
          handleAccountNumber={handleAccountNumber}
          handleSiteCode={handleSiteCode}
          handleClientSecret={handleClientSecret}
          setError={setError}
        />
      )}
      {selected === constant.CANADA_POST && (
        <CanadaPostShipping
          canadaPost={canadaPost}
          handleCanadaPostChange={handleCanadaPostChange}
          setError={setError}
        />
      )}
    </FormLayout>
  );
};

export default withFeature(ThirdPartyOptions, { feature: constant.DROPSHIP });
