import { gql } from "apollo-boost";

const UPDATE_FEATURE = gql`
  mutation updateFeature($input: UpdateFeature) {
    updateFeature(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_FEATURE;
