import { gql } from "apollo-boost";

const GET_MAINTENANCE = gql`
  query {
    getMaintenance {
      status
      error
      data {
        isApp
        isSite
      }
    }
  }
`;

export default GET_MAINTENANCE;
