import React, { useContext } from "react";
import { Card, TextField, Layout, FormLayout, Select, TextContainer, Caption } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

import { priceProp } from "app/productOld/modules/generic/edit/propTypes";

const Price = (props) => {
  const {
    cms,
    data,
    handleMarkup,
    handleDiscount,
    // learnMore
  } = props;
  const { markUp = {}, sellerDiscount = {} } = data || {};
  const { price = "", type = "" } = markUp || {};
  const { price: sellerPrice = "", type: sellerType = "" } = sellerDiscount || {};
  const { currentUser } = useContext(PrivateContext);
  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_PERCENTAGE },
    symbol,
  } = constant;
  const { moneyFormat = symbol.DOLLAR } = currentUser;
  const maxDiscount = (sellerType === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;
  const maxMarkup = (type === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    return null;
  };

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.price.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () => {
        //       learnMore(cms("section.price.title"), cms("label.todo"));
        //     },
        //   },
        // ]}
        id="productPrice"
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.price.title")}</TextContainer>
            <FormLayout.Group>
              <TextField
                label={cms("section.price.label.price")}
                value={(data.price && data.price.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                step={0.01}
                prefix={moneyFormat}
                disabled
              />
              <TextField
                label={cms("section.price.label.comparePrice")}
                value={(data.comparePrice && data.comparePrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                step={0.01}
                prefix={moneyFormat}
                disabled
              />
              <TextField
                label={cms("section.price.label.markup")}
                value={(price && price.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                max={maxMarkup}
                prefix={type === constant.FLAT && moneyFormat}
                suffix={type === constant.PERCENTAGE && "%"}
                onChange={(value) => handleMarkup(constant.PRICE, acceptOnlyValidInput(value, price, maxMarkup))}
                disabled={!type}
                connectedRight={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    value={type || ""}
                    options={[
                      {
                        label: cms("common.label.flat"),
                        value: constant.FLAT,
                      },
                      {
                        label: cms("common.label.percentage"),
                        value: constant.PERCENTAGE,
                      },
                    ]}
                    placeholder={cms("section.price.placeholder.selectType")}
                    onChange={(value) => handleMarkup(constant.TYPE, value)}
                  />
                }
              />
              <TextField
                label={cms("section.price.label.discount")}
                value={(sellerPrice && sellerPrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                min={0}
                max={maxDiscount}
                prefix={sellerType === constant.FLAT && moneyFormat}
                suffix={sellerType === constant.PERCENTAGE && "%"}
                onChange={(value) => {
                  handleDiscount(constant.PRICE, acceptOnlyValidInput(value, sellerPrice, maxDiscount));
                }}
                disabled={!sellerType}
                connectedRight={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    value={sellerType || ""}
                    options={[
                      {
                        label: cms("common.label.flat"),
                        value: constant.FLAT,
                      },
                      {
                        label: cms("common.label.percentage"),
                        value: constant.PERCENTAGE,
                      },
                    ]}
                    placeholder={cms("section.price.placeholder.selectType")}
                    onChange={(value) => handleDiscount(constant.TYPE, value)}
                  />
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
Price.propTypes = priceProp.type;
export default Price;
