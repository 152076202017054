import { gql } from "apollo-boost";

const GET_GA_DETAIL = gql`
  query {
    getGADetail {
      data {
        age {
          label
          value
        }
        gender {
          label
          value
        }        
      }
      error
      status
    }
  }
`;

export default GET_GA_DETAIL;
