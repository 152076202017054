import React, { useCallback, useContext, useState } from "react";

import { AppProvider, Card, Modal, OptionList, Stack, TopBar } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

import { LocationTopBar } from "app/setup/modules/operator/features/currency/currencyStyle";
import cmsHolidaySetting from "app/advanceVendor/modules/operator/feature/setting/holiday/cms/holidayCMS";
import { withErrorBoundary } from "lib/hoc";

const VendorListModal = ({
  active,
  addButton,
  setActive,
  selected,
  setAddButton,
  setSelected,
  setVendorsSelected,
  vendorsSelected,
  vendorList,
  setEditVendor,
  setSubmitButton,
}) => {
  const { cms } = useContext(PrivateContext);
  const cmsHoliday = cmsHolidaySetting(cms);
  const { label } = cmsHoliday;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [vendors, setVendors] = useState([]);
  const handleClose = () => {
    setActive(!active);
  };

  const handleSubmit = () => {
    const addVendors = [];
    const addedVendors = vendorsSelected.map((vendor) => vendor.id);
    setEditVendor(addedVendors);
    const initialCalenderState = {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      isActive: false,
      noDateSelected: true,
      startingDate: null,
      endingDate: null,
      start: new Date(),
      end: new Date(),
    };
    selected.forEach((vendorId) => {
      if (addedVendors.includes(vendorId)) {
        const vendorData = vendorsSelected.find((vendor) => vendor.id === vendorId);
        addVendors.push(vendorData);
      } else {
        const vendorData = vendorList.find((vendor) => vendor._id === vendorId);
        addVendors.push({ ...initialCalenderState, id: vendorData._id, name: vendorData.brandName });
      }
    });
    setVendorsSelected(addVendors);
    setAddButton(true);
    setSubmitButton(false);
    setActive(!active);
  };
  const handleSearchChange = useCallback(
    (value) => {
      setSearchValue(value);
      setIsSearchActive(value.length > 0);
      const vendorData = vendorList.filter((item) => {
        const { brandName = "" } = item;
        if ((brandName && brandName.toLowerCase().includes(value)) || (brandName && brandName.includes(value))) {
          return true;
        }
        return false;
      });
      setVendors(vendorData);
    },
    [vendorList]
  );

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue("");
  }, []);

  const searchFieldMarkup = (
    <TopBar.SearchField onChange={handleSearchChange} value={searchValue} placeholder={label.search} showFocusBorder />
  );

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={label.add}
      primaryAction={{
        content: label.add,
        onAction: handleSubmit,
        disabled: addButton,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <AppProvider>
          <LocationTopBar className="vendor-popup">
            <div className="vendor-topBar">
              <TopBar
                searchField={searchFieldMarkup}
                onSearchResultsDismiss={handleSearchResultsDismiss}
                searchResultsVisible={isSearchActive}
              />
            </div>
          </LocationTopBar>
        </AppProvider>
        <Stack vertical>
          <Stack.Item />
          <Stack.Item>
            <Card>
              <OptionList
                onChange={setSelected}
                options={
                  isSearchActive
                    ? vendors?.map((vendorItem) => {
                        return { value: vendorItem._id, label: vendorItem.brandName };
                      })
                    : vendorList?.map((vendorItem) => {
                        return { value: vendorItem._id, label: vendorItem.brandName };
                      })
                }
                selected={selected}
                allowMultiple
              />
            </Card>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default withErrorBoundary(VendorListModal);
