import { gql } from "apollo-boost";

const SET_ORDER_COMMISSION = gql`
  mutation setOrderCommission($input: OrderCommission!) {
    setOrderCommission(input: $input) {
      data
      error
      status
    }
  }
`;

export default SET_ORDER_COMMISSION;