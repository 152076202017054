import { gql } from "apollo-boost";

const FETCH_PLANS = gql`
  {
    getPlans {
      status
      data {
        _id
        code
        isHidden
        labelVendor
        maxVendors
        maxProducts
        monthlyFee
        name
        sortOrder
        feature
        featureHeading
        trial
        isPopular
        sections {
          title
          content
        }
      }
      error
    }
  }
`;

const FETCH_SELLER_PLANS = gql`
  query getSellerPlanList {
    getSellerPlanList {
      data {
        planList {
          _id
          analytics
          code
          commission
          currencyCode
          name
          price
          productLimit
          period
          periodUnit
          priceModel
          updatedAt
          description
          sellerId
          trialPeriod
          trialPeriodUnit
          type
          redirectURL
          createdAt
        }
      }
      error
      status
    }
  }
`;

const FETCH_CURRENT_PLAN = gql`
  query {
    getCurrentPlan {
      data {
        code
        monthlyFee
      }
      status
      error
    }
  }
`;

export { FETCH_CURRENT_PLAN, FETCH_PLANS, FETCH_SELLER_PLANS };
