import { gql } from "apollo-boost";

const GET_USER_DATABASE_SETTING = gql`
  query getUserDatabaseSetting {
    getUserDatabaseSetting {
      status
      error
      data {
        uri
        updatedAt
      }
    }
  }
`;

export default GET_USER_DATABASE_SETTING;
