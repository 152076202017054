const publicRouteCodes = {
  "/join/:id/:shop": {
    module: "linkRegister",
    cms: "linkRegister",
  },
  "/login": {
    module: "login",
    cms: "login",
  },
  "/singular/privacy": {
    module: "login",
    cms: "login",
  },
  "/singular/term": {
    module: "login",
    cms: "login",
  },
  "/seur/privacy": {
    module: "login",
    cms: "login",
  },
  "/seur/term": {
    module: "login",
    cms: "login",
  },
  "/hr/privacy": {
    module: "login",
    cms: "login",
  },
  "/hr/term": {
    module: "login",
    cms: "login",
  },
  "/prelogin": {
    module: "prelogin",
    cms: "prelogin",
  },
  "/verify-email/:token": {
    module: "verifyEmail",
    cms: "verifyEmail",
  },
  "/resend-verify-email": {
    module: "resendVerifyEmail",
    cms: "resendVerifyEmail",
  },
  "/register": {
    module: "register",
    cms: "register",
  },
  "/register/seller": {
    module: "registerSeller",
    cms: "registerSeller",
  },
  "/reset-password": {
    module: "resetPassword",
    cms: "resetPassword",
  },
  "/reset-password/:token": {
    module: "resetPassword",
    cms: "resetPasswordConfirmation",
  },
  "/register/role": {
    module: "registerRole",
    cms: "registerRole",
  },
  "/seller/shopify": {
    module: "registerRole",
    cms: "registerRole",
  },
  "/shopify/install": {
    module: "shopifyRedirect",
    cms: "shopifyRedirect",
  },
  "/shopify/plan": {
    module: "selectPlan",
    cms: "selectPlan",
  },
  "/shopify/redirect": {
    module: "selectPlan",
    cms: "selectPlan",
  },
  "/shopify/payment": {
    module: "shopifyPayment",
    cms: "shopifyPayment",
  },
  "/otp": {
    module: "otp",
    cms: "otp",
  },
  "/bigcommerce/install": {
    module: "bigcommerceInstall",
    cms: "appInstall",
  },
  "/bigcommerce/load": {
    module: "bigcommerceLoad",
    cms: "appInstall",
  },
  "/woocommerce/install": {
    module: "woocommerceInstall",
    cms: "appInstall",
  },
  "/woocommerce/load": {
    module: "woocommerceLoad",
    cms: "appInstall",
  },
};

export default publicRouteCodes;
