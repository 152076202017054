import PropTypes from "prop-types";

const listProp = {
  type: {
    bands: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        priceType: PropTypes.string,
        price: PropTypes.number,
        isUpdateAllowed: PropTypes.bool,
      })
    ),
    shippingTerm: PropTypes.string,
    handleBanner: PropTypes.func,
    loading: PropTypes.bool,
  },
};

const optionProp = {
  type: {
    show: PropTypes.bool,
    isDefault: PropTypes.bool,
    onClose: PropTypes.func,
    shippingBand: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      priceType: PropTypes.string,
      price: PropTypes.number,
      isUpdateAllowed: PropTypes.bool,
    }),
  },
  default: {
    shippingBand: {},
  },
};

export { listProp, optionProp };
