import { gql } from "apollo-boost";

const SUBSCRIPTION_BY_OPERATOR = gql`
  subscription {
    sellerProductList {
      data {
        operationType
        sellerProduct {
          _id
          approved
          status
          title
          vendor
          vendorId
          images {
            imageUrl
          }
          productId
          createdAt
          published
          variants {
            barcode
            comparePrice
            customRule
            customLabel
            customCharge
            vendorDiscount {
              price
              type
            }
          }
        }
      }
      status
      error
    }
  }
`;

const SUBSCRIPTION_BY_PROVIDER = gql`
  subscription {
    productList {
      data {
        operationType
        product {
          _id
          approved
          status
          title
          vendor
          vendorId
          images {
            imageUrl
          }
          createdAt
          variants {
            barcode
            comparePrice
            customRule
            customLabel
            customCharge
            vendorDiscount {
              price
              type
            }
          }
          productId
        }
      }
      status
      error
    }
  }
`;

export { SUBSCRIPTION_BY_OPERATOR, SUBSCRIPTION_BY_PROVIDER };
