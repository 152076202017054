import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import ConsignmentShip from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";

const DefaultConsignmentShip = (props) => {
  const { cms } = useContext(FulfillmentContext);
  const { value } = props;
  return (
    <>
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.consignmentShip.title")}</Heading>
          </Stack.Item>
          {/* <Stack.Item>
            <Link onClick={() => learnMore(cms("hybrid.consignmentShip.title"), cms("hybrid.consignmentShip.title"))}>
              {cms("common.learnMore")}
            </Link>
          </Stack.Item> */}
          <br />
          <TextStyle variation="subdued">{cms("hybrid.consignmentShip.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <ConsignmentShip method="defaultConsignmentShip" value={value} />
    </>
  );
};

DefaultConsignmentShip.propTypes = {
  value: PropTypes.string.isRequired,
};

export default withFeature(withErrorBoundary(DefaultConsignmentShip), { feature: constant.CONSIGNMENT_SHIP });
