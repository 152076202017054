import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { subDomainRegex } = yupHelper;
const shopSubDomainSchema = (cms) => {

  return yup.object().shape({
    shopSubDomain: yup
      .string()
      .required(`${cms("section.operator.shop.message.error.subDomain")}`)
      .matches(subDomainRegex, cms("section.operator.shop.message.error.invalidSubDomain")),
  });
};

export { shopSubDomainSchema };
