/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { Banner, ResourceList } from "lib/components";
import { useQuery, useMutation } from "react-apollo";
import { Badge, Caption, Card, Heading, ResourceItem, Stack, Tabs, TextStyle, Thumbnail } from "@shopify/polaris";

import { baseHelper, imageHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import MoreFilters, { Filter } from "app/productLake/modules/generic/moreFilters/moreFilter";

import GET_TEMP_PRODUCT_LIST from "app/productLake/apollo/queries/getShopifyProductList";
import SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT from "app/productLake/apollo/mutations/syncVendorLakeProduct";
// import TEMP_PRODUCT_SUBSCRIPTION from "app/productOld/apollo/subscriptions/tempProductList";

import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";
import Popover from "lib/components/popover/popover";

const { SELECTED_FILTER, TAGGED_WITH, UPDATE_ASC, UPDATE_DESC } = constant;

const List = () => {
  const { history, cms } = useContext(PrivateContext);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const [values, setValues] = useState(searchVal || "");
  /* const [selectedAction, setSelectedAction] = useState(""); */
  const [appliedFiltersValue, setAppliedFiltersValue] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [queryValue, setQueryValue] = useState(searchVal || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const [sortValue, setSortValue] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 10);
  const [activePopover, setActivePopover] = useState({});
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || null);
  const [dataToFetch, setDataToFetch] = useState("");
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
    getQueryParams.filter = "";
  }

  const input = {
    search: getQueryParams.search,
    sort_name: getQueryParams.sort_name,
    sort_order: getQueryParams.sort_order,
    filter: getQueryParams.filter,
    list_filter: getQueryParams.list_filter,
    list_search: getQueryParams.list_search,
    page: parseInt(getQueryParams.page || page, 10),
    perPage: parseInt(getQueryParams.perPage || perPage, 10),
  };

  const { loading, data /* , error, subscribeToMore, refetch */ } = useQuery(GET_TEMP_PRODUCT_LIST, {
    variables: {
      input,
    },
  });

  const [bulkProductActions] = useMutation(SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT, {
    refetchQueries: [
      {
        query: GET_TEMP_PRODUCT_LIST,
        variables: {
          input,
        },
      },
    ],
  });

  const tempData = baseHelper.getResponseData(data, "getShopifyLakeProductList");

  useEffect(() => {
    const paramValue = baseHelper.getQueryParams(history.location.search);
    const listFilter = (paramValue.list_filter && paramValue.list_filter.split(",")) || [];
    const listSearch = (paramValue.list_search && paramValue.list_search.split(",")) || [];
    const appliedFilters = [];
    listFilter.forEach((item, index) => {
      appliedFilters.push({
        key: item,
        value: listSearch[index],
      });
    });
    setAppliedFiltersValue(appliedFilters);
  }, [history.location.search, values]);

  // useEffect(() => {
  //   subscribeToMore({
  //     document: TEMP_PRODUCT_SUBSCRIPTION,
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //       return refetch();
  //     },
  //   });
  // }, [subscribeToMore, refetch]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleQueryValueRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (select) => {
    const sort = select.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(select);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabsData = [
    { id: "all", panelID: "all", content: cms("label.all"), value: constant.ALL },
    { id: "synced", panelID: "synced", content: cms("label.synced"), value: constant.SYNCED },
    { id: "unsynced", panelID: "unsynced", content: cms("label.notSynced"), value: constant.NOT_SYNCED },
  ];
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      setSelectedItems([]);
      const filterValue = baseHelper.setQueryParams(history.location, {
        filter: tabsData[selectedTabIndex].value,
        tab: selectedTabIndex,
      });
      history.push(`${history.location.pathname}?${filterValue}`);
    },
    [history, tabsData]
  );

  const handleSync = async (eventKey, id = false) => {
    let formData = {};

    formData = {
      ids: selectedItems,
    };

    if (id) {
      formData.ids = [id];
      setListLoading(`${eventKey}_${id}`);
    }

    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT);
          const responseDataError = baseHelper.getResponseError(
            res.data,
            constant.gql.SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT
          );
          if (responseData) {
            const title = cms("message.success.requestBackground");
            setBanner({ isOpen: true, title, status: constant.SUCCESS });
            setSelectedItems([]);
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const bulkActions = [
    {
      content: cms("label.syncProducts"),
      disabled: selected === 1,
      onAction: () => handleSync(constant.APPROVE),
    },
  ];

  const handleChange = (val, _id) => {
    setSelectedButtonIndex(_id);
    if (val === constant.SYNC) {
      handleSync(constant.APPROVE, _id);
    }
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleTaggedWithRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setTaggedWith("");

    history.push(`${history.location.pathname}?${search}`);
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case TAGGED_WITH:
        return `${value}`;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: TAGGED_WITH,
      label: disambiguateLabel(TAGGED_WITH, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: SELECTED_FILTER,
      label: disambiguateLabel(SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove();
    handleSelectedFilterRemove();
  };

  const filters = [
    {
      key: SELECTED_FILTER,
      label: cms("label.filterBy"),
      filter: (
        <Filter
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          isShopifyList
        />
      ),
      shortcut: false,
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: TAGGED_WITH,
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={() => setBanner({ isOpen: false })}
          />
          <br />
        </>
      )}
      <Banner title={cms("banner.addProductInstruction")} isOpen status={constant.INFO} />
      <br />
      <Banner title={cms("banner.importedProductInstruction")} isOpen />
      <br />
      {/* <QuickReport /> */}

      <Card>
        <Tabs tabs={tabsData} selected={selected} onSelect={handleTabChange} />
        <ResourceList
          resourceName={{
            singular: baseHelper.ucFirst(constant.PRODUCT),
            plural: baseHelper.ucFirst(constant.PRODUCTS),
          }}
          items={tempData.tempProductLakeList ? tempData.tempProductLakeList : []}
          idForItem={(item) => {
            const { _id } = item;
            return _id;
          }}
          selectable
          bulkActions={bulkActions}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          loading={loading}
          sortValue={sortValue}
          sortOptions={[
            { label: cms("label.dateDesc"), value: UPDATE_DESC },
            { label: cms("label.dateAsc"), value: UPDATE_ASC },
            { label: cms("label.productAsc"), value: "data.title_asc" },
            { label: cms("label.productDesc"), value: "data.title_desc" },
          ]}
          filters={filters}
          appliedFilters={appliedFilters}
          handleClearAll={handleClearAll}
          onSortChange={handleSort}
          onQueryChange={handleSearchValue}
          onQueryClear={handleQueryValueRemove}
          queryValue={queryValue}
          count={tempData.count}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          setPopoverDisable={setPopoverDisable}
          renderItem={(item) => {
            const { _id, updatedAt, /* isShopifySync, */ isSynced, isUpdate, isDelete, data: tempItemData } = item;
            const { title, image = {} } = tempItemData || {};
            const thumbnailImage = image
              ? imageHelper.resize({ url: image?.src, type: constant.imageTypes.THUMBNAIL })
              : constant.NOIMAGESNAP;
            const syncStatus = isSynced || isUpdate || isDelete;
            const syncedStatusType = (syncStatus && constant.SYNCED) || constant.NOT_SYNCED;
            const syncedStatus = (syncStatus && cms("label.synced")) || cms("label.notSynced");
            return (
              <ResourceItem id={_id}>
                <Stack>
                  <Stack.Item>
                    <Thumbnail size={constant.LARGE} source={thumbnailImage} alt={`${title} image`} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="ellipsisShopifyList">
                      <Heading element="h2">
                        <TextStyle variation="strong">{title}</TextStyle>
                      </Heading>
                      <Caption>
                        {/* {`${cms("common.label.by")} `}
                          <b>{vendor}</b> */}
                        {` ${cms("common.label.on")} ${baseHelper.formatDate(updatedAt)}`}
                      </Caption>
                      <Stack>
                        <Stack.Item>
                          <Badge status={baseHelper.getBadgeType(syncedStatusType)}>
                            {baseHelper.ucFirst(syncedStatus)}
                          </Badge>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                      // eslint-disable-next-line no-underscore-dangle
                      active={activePopover[_id]}
                      // eslint-disable-next-line no-underscore-dangle
                      setActive={() =>
                        popoverDisable ? setActivePopover(false) : setActivePopover({ [_id]: !activePopover[_id] })
                      }
                      loading={_id === selectedButtonIndex && listLoading}
                      options={[
                        {
                          content: cms("label.publish"),
                          onAction: () => handleChange(constant.SYNC, _id),
                          disabled: isSynced,
                        },
                      ]}
                    />
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </Card>
    </>
  );
};
export default withFeature(withErrorBoundary(List), {
  feature: constant.SHOPIFY_PRODUCT,
});
