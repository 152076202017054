import constant from "lib/constant/constant";
import { yupHelper } from "lib/helpers";

import { subjectSchema, messageSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  if (field === constant.SUBJECT) {
    promise = validateValue(subjectSchema(cms), { subject: value });
    return handlePromiseError(promise);
  }
  promise = validateValue(messageSchema(cms), { message: value });
  return handlePromiseError(promise);
};

export default validate;
