import { gql } from "apollo-boost";

const BULK_UPDATE_VARIANT = gql`
  mutation bulkUpdateVariant($input: BulkUpdateVariant) {
    bulkUpdateVariant(input: $input) {
      data
      status
      error
    }
  }
`;
export default BULK_UPDATE_VARIANT;
