import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, Layout, Select, PageActions, TextContainer, TextField } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import ADD_SELLER_PLAN from "app/setup/apollo/mutations/addSellerPlan";
import { withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Banner, SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import planFormConfig from "./planFormConfig";
import validate from "./yup";

const PlanCreate = () => {
  const {
    gql,
    PROVINCE_CODE,
    CODE,
    DESCRIPTION,
    NAME,
    PERIOD,
    PRODUCT_LIMIT,
    TRIAL_PERIOD,
    PRICE,
    TRIAL_PERIOD_MAX,
  } = constant;
  const [errorMessage, setErrorMessage] = useState({});
  const [addSellerPlan, { loading }] = useMutation(ADD_SELLER_PLAN);
  const { history, cms, currentUser = {} } = useContext(PrivateContext);
  const { isChargebee, chargebee } = currentUser;
  const chargebeeEnabled = !!(isChargebee && chargebee) || false;
  const { textFields } = planFormConfig(cms);
  const [values, setValues] = useState({
    name: "",
    code: "",
    description: "",
    price: "",
    period: "",
    trialPeriod: "",
    productLimit: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  useEffect(() => {
    if (!chargebeeEnabled) {
      const bannerValue = {
        status: constant.CRITICAL,
        title: cms("banner.error.title.enable"),
        onDismiss: () => setBanner({ isOpen: false, title: "", status: "" }),
        action: {
          content: cms("banner.error.button.enable"),
          onAction: () => history.push("/setting/provider/subscription"),
        },
      };
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
    }
  }, [chargebeeEnabled, cms, history]);

  const handleChangeEvent = (field, value) => {
    if (field === constant.TRIAL_PERIOD && value && !baseHelper.validateWholeNumber(value)) {
      return;
    }
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isAnyError = () => Object.values(errorMessage).some((error) => error);
  const isAllValuesFilled = () =>
    Object.keys(values).every((key) => {
      if (key === PROVINCE_CODE) {
        return true;
      }
      return typeof values[key] === "boolean" ? true : values[key];
    });

  const monthOptions = [
    { label: cms("select.options.months.first"), value: cms("select.options.months.first") },
    { label: cms("select.options.months.third"), value: cms("select.options.months.third") },
    { label: cms("select.options.months.sixth"), value: cms("select.options.months.sixth") },
    { label: cms("select.options.months.twelfth"), value: cms("select.options.months.twelfth") },
  ];

  const onSubmit = async () => {
    try {
      const res = await addSellerPlan({
        variables: {
          input: {
            name: values.name,
            code: values.code,
            description: values.description,
            price: values.price,
            period: values.period,
            periodUnit: "month",
            trialPeriodUnit: "day",
            trialPeriod: values.trialPeriod,
            productLimit: values.productLimit,
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.ADD_SELLER_PLAN);
      let bannerValue;
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.ADD_SELLER_PLAN);
        bannerValue = { status: constant.CRITICAL, title: errorResponse };
        setBanner({
          ...bannerValue,
          isOpen: true,
        });
        return;
      }
      setBanner({
        ...bannerValue,
        status: constant.SUCCESS,
        title: cms("banner.success.message.plan"),
        isOpen: true,
      });
      setSubmitDisabled(true);
      setTimeout(() => {
        history.push("/setting/plan/list");
      }, 3000);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const handleValidation = async (field, value) => {
    const error = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const acceptsStringOnly = (value, prevValue) => {
    const val = (baseHelper.stringNotAcceptSpaceAtStart(value) && value) || (value !== "" && prevValue) || "";
    const result = (baseHelper.acceptOnlyString(val) && val) || (val !== "" && prevValue) || "";
        return result;
  };

  const acceptOnlyValidInput = (field, value) => {
    switch (field) {
      case NAME:
        return acceptsStringOnly(value && value) || (value !== "" && values.name);
      case PRICE:
        return (baseHelper.isValidPrice(value) && value) || (value !== "" && values.price);
      case PRODUCT_LIMIT:
        return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && values.productLimit);
      case TRIAL_PERIOD:
        return (
          (baseHelper.validateNumberMaxLimit(value, TRIAL_PERIOD_MAX) && value) || (value !== "" && values.trialPeriod)
        );
      case DESCRIPTION:
        return acceptsStringOnly(value && value) || (value !== "" && values.description);     

      default:
        return value;
    }
  };

  const getFields = (text) =>
    text.map((textField) => {
      if (textField.key === NAME) {
        return (
          <TextField
            label={`${textField.label}*`}
            placeholder={textField.placeholder}
            key={textField.key}
            id={textField.key}
            value={(values && values.name && values.name.toString()) || ""}
            onChange={(value) => handleChangeEvent(NAME, acceptOnlyValidInput(textField.key, value))}
            onBlur={() => handleValidation(textField.key, values[textField.key])}
            error={errorMessage && errorMessage[textField.key]}
          />
        );
      }
      if (textField.key === PERIOD) {
        return (
          <Select
            label={`${textField.label}*`}
            value={values[textField.key]}
            options={monthOptions}
            placeholder={textField.placeholder}
            onChange={(value) => handleChangeEvent(textField.key, value)}
          />
        );
      }
      if (textField.key === PRICE) {
        return (
          <TextField
            label={`${textField.label}*`}
            placeholder={textField.placeholder}
            key={textField.key}
            id={textField.key}
            value={(values && values.price && values.price.toString()) || ""}
            onChange={(value) => handleChangeEvent(PRICE, acceptOnlyValidInput(textField.key, value))}
            onBlur={() => handleValidation(textField.key, values[textField.key])}
            error={errorMessage && errorMessage[textField.key]}
          />
        );
      }
      if (textField.key === TRIAL_PERIOD) {
        return (
          <TextField
            label={`${textField.label}*`}
            placeholder={textField.placeholder}
            key={textField.key}
            id={textField.key}
            value={values[textField.key]}
            onChange={(value) => handleChangeEvent(TRIAL_PERIOD, acceptOnlyValidInput(textField.key, value))}
            onBlur={() => handleValidation(textField.key, values[textField.key])}
            error={errorMessage && errorMessage[textField.key]}
          />
        );
      }
      if (textField.key === PRODUCT_LIMIT) {
        return (
          <TextField
            label={`${textField.label}*`}
            placeholder={textField.placeholder}
            key={textField.key}
            id={textField.key}
            value={(values && values.productLimit && values.productLimit.toString()) || ""}
            maxLength={10}
            onChange={(value) => handleChangeEvent(PRODUCT_LIMIT, acceptOnlyValidInput(textField.key, value))}
            onBlur={() => handleValidation(textField.key, values[textField.key])}
            error={errorMessage && errorMessage[textField.key]}
          />
        );
      }
      return (
        <TextField
          label={`${textField.label}*`}
          placeholder={textField.placeholder}
          key={textField.key}
          id={textField.key}
          value={values[textField.key]}
          onChange={(value) => handleChangeEvent( textField.key, acceptOnlyValidInput(textField.key,value))}
          onBlur={() => handleValidation(textField.key, values[textField.key])}
          error={errorMessage && errorMessage[textField.key]}
        />
      );
    });

  if (loading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {chargebeeEnabled && banner.isOpen ? (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </Layout.Section>
      ) : (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </Layout.Section>
      )}
      {chargebeeEnabled && (
        <Layout>
          <Layout.AnnotatedSection
            title={cms("label.section.setting.title")}
            description={cms("label.section.setting.description")}
          >
            <Card title={cms("label.section.plan.title")}>
              <Card.Section>
                <TextContainer>{cms("label.section.setting.description")}</TextContainer>
                <br />
                <FormLayout>{getFields(textFields)}</FormLayout>
              </Card.Section>
            </Card>
            <br />
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                id: "submitButton",
                onAction: () => onSubmit(),
                disabled: !isAllValuesFilled() || isAnyError() || submitDisabled || loading,
                loading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  id: "cancelButton",
                  onAction: () => history.push("/setting/plan/list"),
                },
              ]}
            />
          </Layout.AnnotatedSection>
        </Layout>
      )}
    </>
  );
};
export default withErrorBoundary(PlanCreate);
