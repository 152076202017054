import { gql } from "apollo-boost";

const UPLOAD_FILE = gql`
  mutation uploadFile($input: UploadFile) {
    uploadFile(input: $input) {
      status
      data {
        fileId
        fileName
        fileURL
      }
      error
    }
  }
`;
export default UPLOAD_FILE;
