import React, { useContext } from "react";
import { Checkbox, TextField, Layout, Card, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

const ShippingSection = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    handleChange,
    shippingInformation,
    errorShippingMessage,
    handleShippingValidation,
    // learnMore,
    lastUpdated,
  } = props;

  const getTextFields = () => {
    const fields = [
      {
        label: cms("section.shipping.label.street"),
        placeholder: cms("section.shipping.placeHolder.street"),
        isRequired: true,
        key: "streetAddress",
      },
      {
        label: cms("section.shipping.label.city"),
        placeholder: cms("section.shipping.placeHolder.city"),
        isRequired: true,
        key: "city",
      },
      {
        label: cms("section.shipping.label.country"),
        placeholder: cms("section.shipping.placeHolder.country"),
        isRequired: true,
        key: "country",
      },
      {
        label: cms("section.shipping.label.postalCode"),
        placeholder: cms("section.shipping.placeHolder.postalCode"),
        isRequired: false,
        key: "postalCode",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField
            label={`${label}${required}`}
            placeholder={placeholder}
            value={shippingInformation[key]}
            onChange={(value) => handleChange(key, value)}
            onBlur={() => (isRequired && handleShippingValidation(key, shippingInformation[key])) || true}
            error={errorShippingMessage && errorShippingMessage[key]}
          />
          <br />
        </>
      );
    });
  };
  const getFields = () => {
    return (
      <>
        {getTextFields()}
        <Checkbox
          checked={shippingInformation.isSameAsBusinessAddress}
          label={cms("section.shipping.label.businessAddress")}
          onChange={(value) => handleChange(constant.IS_SAME_BUSINESS_ADDRESS, value)}
        />
      </>
    );
  };
  return (
    <Layout.AnnotatedSection title={cms("section.shipping.title")} description={cms("section.shipping.description")}>
      <Card
        title={[
          cms("section.shipping.title"),
          lastUpdated && (
            <Caption>
              <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
            </Caption>
          ),
        ]}
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore(cms("section.shipping.title"), "TODO:");
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.shipping.description")}</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

ShippingSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleShippingValidation: PropTypes.func.isRequired,
  shippingInformation: PropTypes.shape({
    isSameAsBusinessAddress: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errorShippingMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  // learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

ShippingSection.defaultProps = {
  errorShippingMessage: PropTypes.bool,
  lastUpdated: "",
};

export default ShippingSection;
