import React from "react";

// import { SelectPlan } from "app/plans";

import {
  BigCommerceInstall,
  BigCommerceLoad,
  GeneratePassword,
  Install,
  LinkRegister,
  Login,
  PreLogin,
  Redirect,
  Register,
  RegisterRole,
  RegisterSeller,
  ResendVerifyEmail,
  ResetPassword,
  ResetPasswordConfirmation,
  // SellerShopify,
  ShopifyPayment,
  VerifyEmail,
  WooCommerceInstall,
  WooCommerceLoad,
  SingularPrivacy,
  SingularTerms,
  SeurPrivacy,
  SeurTerms,
  HRPrivacy,
  HRTerms,
} from "app/public";
import NotFound from "layout/private/components/notFound/notFound";

import PublicRoute from "./publicRoute";

const routes = [
  { path: "/bigcommerce/install", component: BigCommerceInstall },
  { path: "/bigcommerce/load", component: BigCommerceLoad },
  { path: "/join/:id/:shop", component: LinkRegister },
  { path: "/login", component: Login },
  { path: "/prelogin", component: PreLogin },
  { path: "/register", component: Register },
  { path: "/register/role", component: RegisterRole },
  { path: "/register/seller", component: RegisterSeller },
  { path: "/resend-verify-email", component: ResendVerifyEmail },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password/:token", component: ResetPasswordConfirmation },
  { path: "/shopify/install", component: Install },
  { path: "/shopify/redirect", component: Redirect },
  // { path: "/shopify/plan", component: SelectPlan },
  { path: "/seller/shopify", component: NotFound },
  { path: "/shopify/payment", component: ShopifyPayment },
  { path: "/verify-email/:token", component: VerifyEmail },
  { path: "/otp", component: GeneratePassword },
  { path: "/woocommerce/install", component: WooCommerceInstall },
  { path: "/woocommerce/load", component: WooCommerceLoad },
  { path: "/singular/privacy", component: SingularPrivacy },
  { path: "/singular/term", component: SingularTerms },
  { path: "/seur/privacy", component: SeurPrivacy },
  { path: "/seur/term", component: SeurTerms },
  { path: "/hr/privacy", component: HRPrivacy },
  { path: "/hr/term", component: HRTerms },
];
const publicRoutes = routes.map((route) => (
  <PublicRoute exact path={route.path} component={route.component} key={route.path} />
));

export default publicRoutes;
