import { gql } from "apollo-boost";

const ADVANCE_VENDOR_ACCESS = gql`
  mutation advanceVendorAccess($input: AdvanceVendorAccessInput!) {
    advanceVendorAccess(input: $input) {
      data
      status
      error
    }
  }
`;

export default ADVANCE_VENDOR_ACCESS;
