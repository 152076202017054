function actionData(cms) {
  return {
    filterData: {
      label: cms("label.filterPayment"),
      filters: [
        {
          key: "vendor",
          label: cms("label.brandName"),
          operatorText: cms("label.like"),
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/order-lake/view/",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: cms("label.dateAsc"), value: "createdAt_asc" },
      { label: cms("label.dateDesc"), value: "createdAt_desc" },
      { label: cms("label.orderIdAsc"), value: "storeOrderId_asc" },
      { label: cms("label.orderIdDesc"), value: "storeOrderId_desc" },
    ],
    resourceName: {
      singular: cms("label.singular"),
      plural: cms("label.plural"),
    },
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
    tabsData: [
      {
        id: "all-customers",
        content: cms("label.all"),
        accessibilityLabel: "All customers",
        panelID: "all-customers-content",
      },
      {
        id: "due",
        content: cms("label.duePayment"),
        panelID: "due-for-payment-content",
      },
      {
        id: "paid",
        content: cms("label.pay"),
        panelID: "paid-content",
      },
    ],
    badge: ["accountingStatus"],
    // media: 'image',
    tableData: {
      tbody: [],
    },
  };
}
export default actionData;
