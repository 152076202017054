// import feature related string constants
import constants from "lib/constant/constant";

// import helpers
import accessHelper from "./access";
import baseHelper from "./base";

const { featureError } = constants;

class FeatureHelper {
  /**
   * @desc To check user level access of a feature
   *
   * @param {Object} currentUser Current user data
   * @param {Object} feature Data of feature to check permission
   *
   * @return {String} seller plan(free, bootstrap, growth, pro, customPlan)
   */
  checkUserAccess = (currentUser, feature) => {
    const { user, hide = {} } = feature;

    if (!user) {
      return featureError.roleNotAllowed;
    }

    const isSystemAdmin = accessHelper.isSystemAdmin(currentUser);

    if (isSystemAdmin) {
      return null;
    }

    const { permission = [], tier = [], group = {} } = user;
    const { permission: hidePermission = [] } = hide;

    const userLevel = accessHelper.getLevel(currentUser);
    const userRole = accessHelper.getRole(currentUser);
    const sellerPlan = baseHelper.getSellerPlan(currentUser);

    const allowedIds = group[userRole] || [];

    // eslint-disable-next-line no-underscore-dangle
    const currentUserId = baseHelper.mongoIdAsString(currentUser._id);

    if (allowedIds.length && allowedIds.includes(currentUserId)) {
      return null;
    }

    const isHide = !!hidePermission.find((item) => item.level === userLevel && item.role === userRole);

    if (isHide) {
      const isVendor = baseHelper.isVendor(currentUser);
      if (isVendor) {
        const sellerId = currentUser && currentUser.introductorySeller;
        const sellerAllowedIds = [...group.seller] || [];
        if (sellerAllowedIds.length && sellerAllowedIds.includes(sellerId)) {
          return null;
        }
        return featureError.roleNotAllowed;
      }
      return featureError.hideFeature;
    }

    const isPermissionAllowed = !!permission.find((item) => item.level === userLevel && item.role === userRole);

    if (!isPermissionAllowed) {
      return featureError.roleNotAllowed;
    }

    if (sellerPlan && !tier.includes(sellerPlan)) {
      return featureError.needPlanUpgrade;
    }

    return null;
  };

  /**
   * @desc To check if a feature is in allowed time
   *
   * @param {Object} currentUser Current user data
   * @param {Object} feature Data of feature to check permission
   *
   * @return
   */
  checkTimeAllowed = () => null; // to do

  /**
   * @desc To check if a feature is accessible to a user
   *
   * @param {Object} currentUser Current user data
   * @param {Object} feature Data of feature to check permission
   *
   * @return {String} seller plan(free, bootstrap, growth, pro, customPlan)
   */
  checkPermission = (currentUser, feature) => {
    const userAccess = this.checkUserAccess(currentUser, feature);
    if (userAccess) return userAccess;

    const timeAllowed = this.checkTimeAllowed(currentUser, feature);
    if (timeAllowed) return timeAllowed;

    return null;
  };

  /**
   * @desc To get sub-feature out of features
   *
   * @param {Object} feature Feature data
   * @param {Array} features List of all features
   * @param {String} featureName name of sub-feature to find
   *
   * @return {Object} Sub feature object
   */
  getFeature = (feature, features = [], featureName) => {
    if (!feature || !features || !features.length) {
      return null;
    }

    // eslint-disable-next-line no-underscore-dangle
    const featureId = baseHelper.mongoIdAsString(feature._id);
    // const featureToCheck = features.find((item) => item.key === featureName && item.parentId === featureId);
    // return featureToCheck;

    const featureToCheckModule = features.find((item) => item.key === featureName && item.moduleId === featureId);
    return featureToCheckModule;
  };

  /**
   * @desc To check permission of a feature
   *
   * @param {Object} currentUser Current user data
   * @param {Object} featureFlag Data of feature to check permission
   * @param {String} feature Feature name
   * @param {String} subFeature Sub-feature name
   * @param {String} subFeatureItem Sub-feature-item name
   *
   * @return {Object || Boolean} Error mesage object or null
   */
  checkFeature = ({
    currentUser = false,
    featureFlag = {},
    feature: featureName = "",
    // subFeature: subFeatureName = "",
    // subFeatureItem: subFeatureItemName = "",
  }) => {
    if (!currentUser) {
      return null;
    }

    let featureToCheck = { ...featureFlag };
    const { features = [] } = featureFlag;

    if (featureName && featureToCheck) {
      featureToCheck = this.getFeature(featureToCheck, features, featureName);
    }
    // if (subFeatureName && featureToCheck) {
    //   featureToCheck = this.getFeature(featureToCheck, features, subFeatureName);
    // }
    // if (subFeatureItemName && featureToCheck) {
    //   featureToCheck = this.getFeature(featureToCheck, features, subFeatureItemName);
    // }
    if (!featureToCheck) {
      return null;
    }
    const checkUserPermission = this.checkPermission(currentUser, featureToCheck);
    return {
      error: checkUserPermission,
      feature: { ...featureToCheck },
    };
  };
}

export default new FeatureHelper();
