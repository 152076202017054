import { gql } from "apollo-boost";

const GET_SELLER_SETTING = gql`
  query {
    getSellerShippingSetting {
      data {
        shipping {
          isExcludeShippingAmount
          updatedAt
        }
      }
      status
      error
    }
  }
`;

export default GET_SELLER_SETTING;
