import * as yup from "yup";

import yupHelper from "lib/helpers/yup";

const { emailRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const accountTypeSchema = (cms) =>
  yup.object().shape({
    accountType: yup.string().min(1, cms("message.error.accountType")),
  });

const brandNameSchema = (cms) =>
  yup.object().shape({
    brandName: yup.string().min(1, cms("common.message.error.brandNameIsRequired")),
  });

export { emailSchema, accountTypeSchema, brandNameSchema };
