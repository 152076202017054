// import packages
import React, { useState, useEffect, useContext } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { PrivateContext } from "lib/context";

// import helpers components
import { Banner, /* Sheet */ SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import gql
import { GET_TERM, UPDATE_TERM } from "app/setup/apollo/mutations";

// import yup validation
import validate from "./yup/validate";

// import subFeatures
import { Returns, Payment, Shipping } from "./subFeatures";
import AdvanceOption from "./subFeatures/advanceOption";

const { PAYMENT_TERMS_DAY, ALLOWED_DAY, RETURN_ALLOWED, NO_RETURN_ALLOWED, PRE_FULFILLMENT, gql } = constant;

const OperatorTerms = () => {
  const { history, cms, currentUser } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const { error: termError, data: termData, loading } = useQuery(GET_TERM);
  const [setUpTerms, { loading: termsLoading }] = useMutation(UPDATE_TERM);
  const [returnDetail, setReturnDetail] = useState({
    selectedPolicy: "",
    allowedDay: "",
  });
  const [paymentDetail, setPaymentValue] = useState({
    type: "",
    days: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [isSubmit, setIsSubmit] = useState(true);
  const [isNoReturn, setIsNoReturn] = useState(false);
  const [isPreFull, setIsPreFull] = useState(false);
  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [isReturnValue, setIsReturnValue] = useState(false);
  const [returnPeriodValue, setReturnPeriodValue] = useState("");
  const [advanceReturn, setAdvanceReturn] = useState([]);
  const [advancePayment, setAdvancePayment] = useState([]);
  const [returnBanner, setReturnBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [paymentBanner, setPaymentBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [advanceReturnValues, setAdvanceReturnValues] = useState([]);
  const [advancePaymentValues, setAdvancePaymentValues] = useState([]);

  const setStateReturn = (field, value) => {
    let returnValue = RETURN_ALLOWED;
    if (field !== true) {
      returnValue = NO_RETURN_ALLOWED;
    }
    setReturnDetail((prevState) => ({
      ...prevState,
      selectedPolicy: returnValue,
      allowedDay: String(value),
    }));
  };
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  useEffect(() => {
    if (termError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(termError),
      });
    }
  }, [cms, termError]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(termData, gql.GET_TERM);
    const responseError = baseHelper.getResponseError(termData, gql.GET_TERM);
    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const {
      paymentTerms,
      updatedAt = "",
      isReturn = "",
      returnPeriod = "",
      // eslint-disable-next-line no-shadow
      advanceReturn = {},
      // eslint-disable-next-line no-shadow
      advancePayment = {},
    } = responseData;

    // if (paymentTerms) {
    //   const formattedPaymentTermValues = [];
    //   paymentTerms.forEach((item) => {
    //     if (item.days) {
    //       formattedPaymentTermValues.push({
    //         days: item.days,
    //         type: item.type,
    //       });
    //     }
    //   });
    //   setReturnDetail(formattedPaymentTermValues);
    //   setPaymentTerm((prevState) => ({
    //     ...prevState,
    //     paymentTermsType: paymentTerms.type,
    //     paymentTermsDay: String(paymentTerms.days),
    //   }));
    //   setPaymentTerm(paymentTerms);
    // }

    setPaymentValue(
      paymentTerms || {
        days: "",
        type: "",
      }
    );
    setAdvanceReturn(advanceReturn || []);
    setAdvancePayment(advancePayment || []);
    setAdvancePaymentValues(advancePayment || []);
    setAdvanceReturnValues(advanceReturn || []);
    setLastUpdated(updatedAt || "");
    setIsReturnValue(!!isReturn);
    setReturnPeriodValue(returnPeriod || "");
  }, [termData]);

  useEffect(() => {
    setStateReturn(isReturnValue, returnPeriodValue);
  }, [isReturnValue, returnPeriodValue]);

  const handleReturnChange = (field, value) => {
    if ((field === ALLOWED_DAY && !!value && !baseHelper.validateWholeNumber(value)) || Number(value) > 365) {
      return;
    }
    setIsNoReturn(false);
    if (value === NO_RETURN_ALLOWED) {
      setIsNoReturn(true);
    }
    setIsSubmit(false);
    setReturnDetail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePaymentChange = (field, value) => {
    if ((field === constant.DAYS && !!value && !baseHelper.validateWholeNumber(value)) || Number(value) > 365) {
      return;
    }
    if (field === constant.TYPE) {
      paymentDetail.days = "";
    }
    setIsPreFull(false);
    if (value === PRE_FULFILLMENT) {
      setIsPreFull(true);
      setPaymentValue((prevState) => ({
        ...prevState,
        days: null,
      }));
    }
    setIsSubmit(false);
    setPaymentValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isReturnAllowed = returnDetail.selectedPolicy !== NO_RETURN_ALLOWED;
  const isPaymentAllowed = paymentDetail.type !== PRE_FULFILLMENT;

  const setError = (field, errorText) => {
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: errorText,
    }));
  };

  const validateFields = async (field, value) => {
    if (isReturnAllowed || isPaymentAllowed) {
      const errorField = await validate(
        field,
        value,
        !value ? cms("message.error.days") : cms("message.error.daysLowerLimit")
      );
      setError(field, errorField);
    }
  };
  useEffect(() => {
    if (paymentDetail.paymentTermsType === PRE_FULFILLMENT && isPreFull) {
      setPaymentValue((prevState) => ({
        ...prevState,
        paymentTermsDay: "",
      }));
      setError(PAYMENT_TERMS_DAY, "");
    }
    if (returnDetail.selectedPolicy === NO_RETURN_ALLOWED && isNoReturn) {
      setReturnDetail((prevState) => ({
        ...prevState,
        allowedDay: "",
      }));
      setError(ALLOWED_DAY, "");
    }
  }, [returnDetail.selectedPolicy, paymentDetail.paymentTermsType, isPreFull, isNoReturn]);

  const handleSubmit = (isReturn, data) => {
    let advancedValue = data;
    const advanceSetBanner = isReturn ? setReturnBanner : setPaymentBanner;
    const returnOrType = isReturn ? "isReturn" : "type";
    const returnOrDays = isReturn ? "returnPeriod" : "days";
    let isEmpty = false;
    if (advancedValue && advancedValue.length) {
      advancedValue.map((row) => {
        const keys = Object.keys(row);
        let count = 0;
        keys.map((key) => {
          if (returnOrType === key || returnOrDays === key || row[key]) {
            count += 1;
          }
          return null;
        });

        if (
          (count && count !== keys.length) ||
          !(row[returnOrDays] >= 0) ||
          !(row[returnOrType] === false || row[returnOrType])
        ) {
          isEmpty = true;
        }
        return null;
      });
      advancedValue = advancedValue.filter(
        (item) => item.type !== null && item[returnOrDays] && item[returnOrDays] !== "NaN"
      );
    }
    if (isEmpty) {
      advanceSetBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.invalidRowDetail"),
      });
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    const isValidReturnInputs = await handleSubmit(true, advanceReturnValues);
    const isValidPaymentInputs = await handleSubmit(false, advancePaymentValues);

    if (!isValidReturnInputs || !isValidPaymentInputs) {
      return;
    }
    const returnDayValue = parseInt(returnDetail.allowedDay, 10) <= 0 || returnDetail.allowedDay === "";
    const paymentDayValue = parseInt(paymentDetail.days, 10) <= 0 || paymentDetail.days === "";

    if (isReturnAllowed && returnDayValue) {
      setError(ALLOWED_DAY, cms("message.error.daysLowerLimit"));
      return;
    }

    if (isPaymentAllowed && paymentDayValue) {
      setError(PAYMENT_TERMS_DAY, cms("message.error.daysLowerLimit"));
      return;
    }
    const returnSelectedValue = returnDetail.selectedPolicy;
    let setReturnValue = false;
    const isReturnValues = returnSelectedValue === RETURN_ALLOWED;
    if (isReturnValues) {
      setReturnValue = true;
    }
    const period = parseInt(returnDetail.allowedDay, 10) || null;
    try {
      const response = await setUpTerms({
        variables: {
          input: {
            isReturn: setReturnValue,
            returnPeriod: period,
            paymentTerms: {
              days: parseInt(paymentDetail.days, 10),
              type: paymentDetail.type,
            },
            advancePayment: advancePaymentValues,
            advanceReturn: advanceReturnValues,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.UPDATE_TERM);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.termsUpdated") });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  // const primaryAction = {
  //   content: cms("common.label.done"),
  //   onAction: () => setSheetActive(false),
  // };

  // const secondaryAction = {
  //   content: cms("common.button.cancel"),
  //   onAction: () => setSheetActive(false),
  // };

  // const learnMore = (title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(`TODO: Add description about ${title}`);
  // };

  if (loading) {
    return (
      <>
        <SkeletonAnnotated />
        <SkeletonAnnotated />
        <SkeletonAnnotated />
        <SkeletonAnnotated />
      </>
    );
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
      <div>
        <Returns
          errorMessage={errorMessage}
          handleChange={handleReturnChange}
          data={returnDetail}
          handleValidation={validateFields}
          // learnMore={learnMore}
          lastUpdated={lastUpdated}
        />
        {!ecommercePlatform && (
          <div>
            <AdvanceOption
              module="return"
              isPayment="false"
              advanceTermData={advanceReturn}
              bannerData={returnBanner}
              setValues={setAdvanceReturnValues}
              setIsSubmit={setIsSubmit}
              // learnMore={learnMore}
              lastUpdated={lastUpdated}
            />
          </div>
        )}
        <Payment
          handleChange={handlePaymentChange}
          data={paymentDetail}
          errorMessage={errorMessage}
          handleValidation={validateFields}
          // learnMore={learnMore}
          lastUpdated={lastUpdated}
        />
        {!ecommercePlatform && (
          <div>
            <AdvanceOption
              module="payment"
              isPayment="true"
              advanceTermData={advancePayment}
              bannerData={paymentBanner}
              setValues={setAdvancePaymentValues}
              setIsSubmit={setIsSubmit}
              // learnMore={learnMore}
              lastUpdated={lastUpdated}
            />
          </div>
        )}
      </div>
      <Layout.Section>
        <PageActions
          primaryAction={{
            id: "submit",
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: termsLoading,
            disabled: termsLoading || isSubmit,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/setting"),
            },
          ]}
        />
      </Layout.Section>
      {/* <Shipping learnMore={learnMore} /> */}
      {!ecommercePlatform && <Shipping />}
      {/*
        <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet> 
    */}
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorTerms));
