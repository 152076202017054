import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Filters,
  Pagination,
  ResourceList as PolarisResourceList,
  Select,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const ResourceList = (props) => {
  // NOTE: Add more props as per your need
  const {
    appliedFilters,
    bulkActions,
    count,
    emptyState,
    filters,
    handleClearAll,
    hasMoreItems,
    idForItem,
    isEmptyState,
    isFilter,
    items,
    loading,
    onFiltersChange,
    onQueryChange,
    onQueryClear,
    onSelectionChange,
    onSortChange,
    page,
    perPage,
    promotedBulkActions,
    queryValue,
    renderItem,
    resourceName,
    selectable,
    selectedItems,
    setPage,
    setPopoverDisable,
    setPerPage,
    showHeader,
    sortOptions,
    sortValue,
    totalCountLabel,
    totalItemsCount,
  } = props;

  const emptyProps = {
    resourceName,
  };

  if (isEmptyState) {
    emptyProps.emptyState = emptyState;
  }

  const { history } = useContext(PrivateContext);
  const [selectedPerPage, setSelectedPerPage] = useState(perPage.toString() || "20");
  const PerPage = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ];

  let maxPage = (count && count && parseInt(count / perPage, 10)) || 0;
  maxPage = (maxPage && ((count % perPage && maxPage + 1) || maxPage)) || 0;

  const changePage = (value) => {
    const newPage = parseInt(page, 10) + parseInt(value, 10);
    const search = baseHelper.setQueryParams(history.location, { page: newPage });
    setPage(newPage);
    history.push(`${history.location.pathname}?${search}`);
  };

  const changePageLimit = (value) => {
    setPerPage(value);
    setPage(1);
    setSelectedPerPage(value);
    const search = baseHelper.setQueryParams(history.location, { perPage: value, page: 1 });
    history.push(`${history.location.pathname}?${search}`);
  };

  if (selectedItems) {
    if (selectedItems.length > 0) {
      setPopoverDisable(true);
    }
    if (selectedItems.length === 0) {
      setPopoverDisable(false);
    }
  }

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onFilterQueryChange={onFiltersChange}
      onQueryChange={onQueryChange}
      onFiltersChange={onFiltersChange}
      onQueryClear={onQueryClear}
      onClearAll={handleClearAll}
    />
  );

  return (
    <Card>
      <PolarisResourceList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...emptyProps}
        // resourceName={resourceName}
        items={items}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        promotedBulkActions={promotedBulkActions}
        // bulkActions={bulkActions}
        bulkActions={selectedItems.length > 0 ? bulkActions : null}
        sortValue={sortValue}
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        filterControl={(isFilter && filterControl) || null}
        loading={loading}
        selectable={selectable}
        showHeader={showHeader}
        totalItemsCount={totalItemsCount}
        idForItem={idForItem}
        hasMoreItems={hasMoreItems}
        // emptyState={emptyState}
      />
      {(count && (
        <Card sectioned>
          <Stack wrap={false} alignment="center">
            <Stack.Item fill>
              <TextStyle>{`${totalCountLabel || "Total Count"}: ${count}`}</TextStyle>
            </Stack.Item>
            <Stack.Item fill>
              {(maxPage && (
                <Pagination
                  hasPrevious={parseInt(page, 10) !== 1}
                  onPrevious={() => changePage(-1)}
                  hasNext={parseInt(page, 10) !== maxPage}
                  onNext={() => changePage(1)}
                />
              )) ||
                ""}
            </Stack.Item>
            <Stack.Item>
              <Select options={PerPage} onChange={changePageLimit} value={selectedPerPage} />
            </Stack.Item>
          </Stack>
        </Card>
      )) ||
        ""}
    </Card>
  );
};

ResourceList.propTypes = {
  appliedFilters: PropTypes.arrayOf(PropTypes.object),
  bulkActions: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  emptyState: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  handleClearAll: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  idForItem: PropTypes.func,
  isFilter: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  onQueryClear: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSortChange: PropTypes.func,
  page: PropTypes.number,
  perPage: PropTypes.number,
  promotedBulkActions: PropTypes.arrayOf(PropTypes.object),
  queryValue: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  resourceName: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }),
  selectable: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  setPage: PropTypes.func,
  setPopoverDisable: PropTypes.func,
  setPerPage: PropTypes.func,
  showHeader: PropTypes.bool,
  sortOptions: PropTypes.arrayOf(PropTypes.object),
  sortValue: PropTypes.string,
  totalCountLabel: PropTypes.string,
  totalItemsCount: PropTypes.number,
  isEmptyState: PropTypes.bool,
};

ResourceList.defaultProps = {
  isEmptyState: false,
  appliedFilters: [],
  bulkActions: [],
  count: 0,
  emptyState: () => {},
  filters: [],
  handleClearAll: () => {},
  hasMoreItems: false,
  idForItem: (item) => item.id,
  isFilter: true,
  items: [],
  loading: false,
  onFiltersChange: () => {},
  onQueryChange: () => {},
  onQueryClear: () => {},
  onSelectionChange: () => {},
  onSortChange: () => {},
  page: 0,
  perPage: 0,
  promotedBulkActions: [],
  queryValue: "",
  resourceName: {
    singular: "item",
    plural: "items",
  },
  selectable: false,
  selectedItems: [],
  setPage: () => {},
  setPerPage: () => {},
  setPopoverDisable: () => {},
  showHeader: true,
  sortOptions: [],
  sortValue: "",
  totalCountLabel: null,
  totalItemsCount: 0,
};

export default ResourceList;
