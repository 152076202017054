import styled from "styled-components";

export const StyledButton = styled.span`
  .Polaris-Choice {
    padding: 0.3rem 0;
  }
  .Polaris-Button {
    padding: 0.4rem 1.6rem;
  }
`;

export const StyledCard = styled.div`
  .Polaris-Choice__Control {
    margin-right: 0;
  }
  .Polaris-Badge {
    margin-bottom: 1rem;
    &.Polaris-Badge--statusCritical {
      margin-bottom: 0;
    }
  }
  .Polaris-Stack__Item {
    > .Polaris-Link {
      margin-bottom: 4px;
    }
    .Polaris-Caption {
      margin-bottom: 7px;
      span {
        margin-bottom: 3px;
        display: block;
      }
    }
    .Polaris-Button {
      padding: 0.9rem 1.2rem;
    }
  }
`;

export const TotalSection = styled.div`
  > .Polaris-Card > .Polaris-Card__Section--subdued {
    padding-top: 0;
    padding-bottom: 0;
  }
  .Polaris-Card__Subsection > .Polaris-Card__Section {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledStack = styled.div`
  .Polaris-Stack__Item {
    align-self: center;
    margin-top: 7px;
  }
`;

export const StyledLayout = styled.div`
  .Polaris-Layout__Section {
    margin-top: 0;
  }
`;

export const StyledModal = styled.div`
  .Polaris-Modal-Section {
    padding-top: 0;
  }
`;

export const HopScotchModal = styled.div`
  .Polaris-Stack__Item {
    flex: 0 0 45%;
    width: 45%;
  }
  .Polaris-TextStyle--variationStrong {
    margin-bottom: 2rem;
    display: block;
    font-size: 16px;
    font-weight: 500;
  }
  .date-input {
    margin-bottom: 1.5rem;
  }
  .date-picker {
    padding: 1rem;
    border: 1px solid #e9e9e9;
  }
  .select-time {
    .Polaris-Select__Content {
      flex-direction: row-reverse;
    }
    .Polaris-Select__Prefix {
      padding-right: 0;
    }
    .Polaris-Select__Icon {
      display: none;
    }
  }
`;
