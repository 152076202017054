import * as yup from "yup";

import { yupHelper } from "lib/helpers";

const { postalCodeRegex } = yupHelper;

const labelSchema = (cms) => yup.object().shape({ label: yup.string().required(cms("message.error.label")) });

const addressSchema = (cms) => yup.object().shape({ address: yup.string().required(cms("message.error.address")) });

const citySchema = (cms) => yup.object().shape({ city: yup.string().required(cms("message.error.city")) });

const countrySchema = (cms) => yup.object().shape({ countryCode: yup.string().required(cms("message.error.country")) });

const postalCodeSchema = (cms, schema) => {
  const { min = 2, max = 32 } = schema || {};
  return yup.object().shape({
    postalCode: yup
      .string()
      .required(cms("common.message.error.postalCodeIsRequired"))
      .matches(postalCodeRegex, cms("common.message.error.alphanumeric"))
      .min(min, cms("common.message.error.minPostalCode", { min }))
      .max(max, cms("common.message.error.maxPostalCode"))
      .nullable(),
  });
};

export { labelSchema, addressSchema, citySchema, countrySchema, postalCodeSchema };
