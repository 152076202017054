import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const schema = (message) => {
  const { hexRegex } = yupHelper;

  const backgroundSchema = yup.object().shape({
    background: yup.string().matches(hexRegex, message.enterValid),
  });

  const headerHexSchema = yup.object().shape({
    header: yup.string().matches(hexRegex, message.enterValid),
  });

  const headingHexSchema = yup.object().shape({
    heading: yup.string().matches(hexRegex, message.enterValid),
  });

  const contentHexSchema = yup.object().shape({
    content: yup.string().matches(hexRegex, message.enterValid),
  });

  const headerTextHexSchema = yup.object().shape({
    headerText: yup.string().matches(hexRegex, message.enterValid),
  });

  const buttonHexSchema = yup.object().shape({
    button: yup.string().matches(hexRegex, message.enterValid),
  });

  const buttonTextHexSchema = yup.object().shape({
    buttonText: yup.string().matches(hexRegex, message.enterValid),
  });

  const menuSchema = yup.object().shape({
    background: yup.string().matches(hexRegex, message.enterValid),
  });

  const toastBackgroundColorSchema = yup.object().shape({
    toastBackgroundColor: yup.string().matches(hexRegex, message.enterValid),
  });

  const toastTextSchema = yup.object().shape({
    toastText: yup.string().matches(hexRegex, message.enterValid),
  });

  return {
    backgroundSchema,
    headerHexSchema,
    headingHexSchema,
    contentHexSchema,
    headerTextHexSchema,
    buttonHexSchema,
    buttonTextHexSchema,
    menuSchema,
    toastBackgroundColorSchema,
    toastTextSchema,
  };
};

export default schema;
