import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";

import { GET_ORDER_LINES, GET_ORDER_EXPORT_SETTING, GET_SELLER_DEFAULT_ADDRESS } from "app/orders/apollo/queries";
import { GET_WHOLESALE_SETTING } from "app/product/apollo/queries";

import orderHelper from "app/orders/modules/orderHelper";

const ExportOrder = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser, history } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [csvDetails, setCSVDetails] = useState([]);
  const [hasRecord, setHasRecord] = useState(true);
  const [pdfCount, setPdfCount] = useState(0);
  const [isBulkFulfill, setIsBulkFulfill] = useState(false);
  const hideValue = {
    label: "",
    isHideCsv: true,
    isHidePdf: true,
  };
  const [getSellerDefaultAddress, { loading: addressLoading, data: addressData }] = useLazyQuery(
    GET_SELLER_DEFAULT_ADDRESS
  );
  const [getOrderLines, { data: getOrderData, loading: getOrderLoading, error: getOrderError }] = useLazyQuery(
    GET_ORDER_LINES
  );

  const [
    getOrderSetting,
    // eslint-disable-next-line no-unused-vars
    { data: getOrderSettingData, loading: getPaymentSettingLoading, error: getPaymentSettingError },
  ] = useLazyQuery(GET_ORDER_EXPORT_SETTING);

  const [getWholesaleSetting, { loading: wholesaleLoading, data: wholesaleData }] = useLazyQuery(GET_WHOLESALE_SETTING);

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (
      !getOrderLoading &&
      !getOrderError &&
      getOrderData &&
      getOrderSettingData &&
      !wholesaleLoading &&
      !addressLoading
    ) {
      const resData = baseHelper.getResponseData(getOrderData, "getOrderLines");
      const settingResponse = baseHelper.getResponseData(getOrderSettingData, constant.gql.GET_ORDER_EXPORT_SETTING);
      const { isMarkupDiscountExport = false } = settingResponse;
      if (!isVendor && settingResponse && settingResponse.export) {
        settingResponse.export.discount = hideValue;
      }
      if (isVendor && !isMarkupDiscountExport && settingResponse && settingResponse.export) {
        settingResponse.export.markupPrice = hideValue;
        settingResponse.export.discount = hideValue;
      }
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getOrderData, "getOrderLines");
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      const { orderLines = [], hasMore = false } = resData;
      setHasRecord(hasMore);
      if (!(orderLines && orderLines.length)) {
        bannerData = {
          title: cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, constant.gql.GET_WHOLESALE_SETTING);
      const addressResponse = baseHelper.getResponseData(addressData, constant.gql.GET_SELLER_DEFAULT_ADDRESS);
      const { isWholesalePriceVisible = false } = wholesaleResponse;

      const csvData = isBulkFulfill
        ? orderHelper.orderFulfillCSVData(orderLines, cms)
        : orderHelper.orderCSVData(
            orderLines,
            settingResponse,
            isWholesalePriceVisible,
            true,
            false,
            isVendor,
            cms,
            addressResponse
          );
      if (csvData && csvData.length) {
        setPdfCount(pdfCount + 1);
        if (pdfCount > 0) {
          csvData.shift();
        }
        setCSVDetails([...csvDetails, ...csvData]);
      }
      if (hasMore) {
        setQueryData({ ...queryData, page: parseInt(queryData.page, 10) + 1 });
      }
    }
  }, [
    getOrderData,
    getOrderError,
    getOrderLoading,
    cms,
    getOrderSettingData,
    data,
    wholesaleLoading,
    wholesaleData,
    isVendor,
    isBulkFulfill,
  ]);

  useEffect(() => {
    if (csvDetails && csvDetails.length > 1) {
      const csvData = Baby.unparse(csvDetails);
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      if (!hasRecord) {
        setTimeout(() => {
          window.close();
        }, 500);
        FileDownload(csvData, `Order Export - ${date}.csv`);
      }
    }
  }, [csvDetails]);

  useEffect(() => {
    getOrderLines({ variables: { input: { ...queryData } } });
  }, [queryData]);

  useEffect(() => {
    const exportOrder = () => {
      const {
        status,
        fulfillmentStatus,
        vendor,
        accountingStatus,
        bulkFulfill = false,
        page = 1,
      } = baseHelper.queryParamsFromLocation(data);

      const dataToFetch = { page };

      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      if (bulkFulfill) {
        dataToFetch.isCampaign = true;
      }
      setIsBulkFulfill(bulkFulfill);
      setQueryData(dataToFetch);
      getSellerDefaultAddress();
      getWholesaleSetting();
      getOrderSetting();
    };
    if (isPageLoading) {
      exportOrder();
      setIsPageLoading(false);
    }
  }, [data, getOrderSetting, isPageLoading, getWholesaleSetting]);

  return (
    <Layout.Section>
      {getOrderLoading && <Spinner isFullPage />}
      {banner.isOpen && (
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
        />
      )}
      {/* <Banner
        title="Order export is in progress"
        status="info"
        isOpen={true}
      >
        <br />
        Please wait while we are exporting your orders in a batch of 500. Please allow multiple downloads if there is a popup on your screen. <br /> <br />

        This tab will automatically close once all the downloads are completed.
      </Banner> */}
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(ExportOrder), { feature: constant.EXPORT_ORDER });