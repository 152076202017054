// import packages
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout, Card, Collapsible, TextContainer, Caption, TextStyle } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import gql
import { GET_COMMISSION, GET_ORDER_COMMISSION } from "app/setup/apollo/queries";

// import components
import { Banner, SkeletonAnnotated } from "lib/components";
import {
  AdvanceCommission,
  OrderBasedCommission,
  PriorityCommission,
  RangeCommission,
  StandardCommission,
} from "./subFeatures";

const OperatorCommission = () => {
  const {
    CATEGORY,
    PRODUCT_TYPE,
    VENDOR,
    WARNING,
    gql: { GET_COMMISSION: GET_COMMISSION_TEXT, GET_ORDER_COMMISSION: GET_ORDER_COMMISSION_TEXT },
  } = constant;
  const { currentUser, cms = {} } = useContext(PrivateContext);
  const { _id: currentUserId, ecommercePlatform, isStripeSubscription = false } = currentUser;
  const [standardValue, setStandardValue] = useState({
    selectedStandardType: "",
    standardAmount: "",
  });
  const [advanceValue, setAdvanceValue] = useState({
    advancedValues: [],
    ruleBy: PRODUCT_TYPE,
  });
  const [rangeValue, setRangeValue] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [showHideStandard, setShowHideStandard] = useState(true);
  const [showHideAdvance, setShowHideAdvance] = useState(true);
  const [showHideRange, setShowHideRange] = useState(true);
  const [showSetPriority, setShowSetPriority] = useState(true);
  const [updatedAt, setUpdatedAt] = useState("");
  const [priorityValue, setPriorityValue] = useState("");
  const [isTaxInclusive, setIsTaxInclusive] = useState(false);
  const [isOrderBased, setIsOrderBased] = useState(false);

  const { loading: queryLoading, data: queryData, error, networkStatus, refetch } = useQuery(GET_COMMISSION, {
    variables: { input: { sellerId: currentUserId } },
    notifyOnNetworkStatusChange: true,
  });

  const { loading: orderCommissionLoading, data: orderQueryData } = useQuery(GET_ORDER_COMMISSION);

  const setStateValues = (result) => {
    setStandardValue({
      selectedStandardType: result.type || "",
      standardAmount: String(result.commission),
    });
    setIsTaxInclusive(result.isTaxInclusive);
    setUpdatedAt(result.updatedAt);
    if (result.ruleBy) {
      setPriorityValue(result.ruleBy);
    }
    const hasRangeValue = !!(result.range && result.range.length);
    if (hasRangeValue) {
      setRangeValue(result.range);
    }
    const hasAdvanceValue = !!(result.advance && result.advance.length);
    const dataToUpdate = {
      ruleBy: result.ruleBy || PRODUCT_TYPE,
    };
    if (hasAdvanceValue) {
      const { advance } = result;
      const advancedValues = [];
      advance.map((item) => {
        const advanceData = {
          category: "",
          price: String(item.commission),
          type: "",
          value: item.type,
        };
        if (item.vendorId) {
          advanceData.type = VENDOR;
          advanceData.category = item.vendorId;
        } else if (item.categoryId) {
          advanceData.type = CATEGORY;
          advanceData.category = item.categoryId;
        }
        advancedValues.push(advanceData);
        return null;
      });
      dataToUpdate.advancedValues = advancedValues;
    }
    setAdvanceValue({
      ruleBy: dataToUpdate.ruleBy,
      advancedValues: dataToUpdate.advancedValues || [],
    });
  };

  useEffect(() => {
    if (!queryData) {
      return;
    }
    const responseData = baseHelper.getResponseData(queryData, GET_COMMISSION_TEXT);
    const responseError = baseHelper.getResponseError(queryData, GET_COMMISSION_TEXT);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    setStateValues(responseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  useEffect(() => {
    if (!orderQueryData) {
      return;
    }
    const responseData = baseHelper.getResponseData(orderQueryData, GET_ORDER_COMMISSION_TEXT);
    const responseError = baseHelper.getResponseError(orderQueryData, GET_ORDER_COMMISSION_TEXT);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }

    if (!responseData) {
      return;
    }

    const { isOrderBasedCommission } = responseData;
    setIsOrderBased(isOrderBasedCommission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderQueryData]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(error) });
    }
  }, [cms, error]);

  if (queryLoading && orderCommissionLoading && networkStatus !== 4) {
    return <SkeletonAnnotated />;
  }

  // const primaryAction = {
  //   content: cms("common.label.done"),
  //   onAction: () => setSheetActive(false),
  // };

  // const secondaryAction = {
  //   content: cms("common.button.cancel"),
  //   onAction: () => setSheetActive(false),
  // };

  // const learnMore = (title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(`TODO: Add description about ${title}`);
  // };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      {isStripeSubscription && (
        <Layout.Section>
          <Banner isOpen status={WARNING} title={cms("message.info.stripe")} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.standardCommission.title")}
        description={cms("section.standardCommission.description")}
      >
        <Card
          title={[
            cms("section.standardCommission.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          actions={[
            {
              content:
                !isStripeSubscription && (showHideStandard ? cms("common.label.hide") : cms("common.label.show")),
              onAction: () => setShowHideStandard(!showHideStandard),
              disclosure: !isStripeSubscription && (showHideStandard ? constant.UP : constant.DOWN),
            },
            // {
            //   content: cms("common.label.learnMore"),
            //   onAction: () => learnMore(cms("section.standardCommission.title")),
            // },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.standardCommission.description")}</TextContainer>
            <br />
            <Collapsible open={showHideStandard} id="standardCommission">
              <StandardCommission
                advanceCommissionValue={advanceValue}
                refetchLoading={networkStatus === 4}
                standardCommissionValue={standardValue}
                rangeCommissionValue={rangeValue}
                updatedAt={updatedAt}
                setStandardValue={setStandardValue}
                priorityValue={priorityValue}
                isTaxInclusive={isTaxInclusive}
                refetch={refetch}
              />
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
      {/* <Collapsible open>
        <Layout.AnnotatedSection
          title={"Commission on product price + GST"}
          description={"This will enable you to charge commission on product price and GST value rather than only product price. If you have 100 as product price and 15 as GST, the commission will be calculated on 100 + 15 i.e, 115 rather than 100."}
        >
          <Card
            title={[
              "Charge commission on product price + GST",
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
            actions={[
              {
                content: cms("common.label.learnMore"),
                onAction: () => learnMore("charge commission on product price + GST"),
              },
            ]}
          >
            <TaxCommission
              isTaxInclusive={isTaxInclusive}
              setIsTaxInclusive={setIsTaxInclusive}
              advanceCommissionValue={advanceValue}
              refetchLoading={networkStatus === 4}
              standardCommissionValue={standardValue}
              rangeCommissionValue={rangeValue}
              priorityValue={priorityValue}
            />
          </Card>
        </Layout.AnnotatedSection>
      </Collapsible> */}
      {!ecommercePlatform && (
        <>
          <Collapsible open>
            <Layout.AnnotatedSection
              title={cms("section.advancedCommission.title")}
              description={cms("section.advancedCommission.description")}
            >
              <Card
                title={[
                  cms("section.advancedCommission.title"),
                  updatedAt && (
                    <TextStyle variation="subdued">
                      <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>
                    </TextStyle>
                  ),
                ]}
                actions={[
                  {
                    content:
                      !isStripeSubscription && (showHideAdvance ? cms("common.label.hide") : cms("common.label.show")),
                    onAction: () => setShowHideAdvance(!showHideAdvance),
                    disclosure: !isStripeSubscription && (showHideAdvance ? constant.UP : constant.DOWN),
                  },
                  // {
                  //   content: cms("common.label.learnMore"),
                  //   onAction: () => learnMore(cms("section.advancedCommission.title")),
                  // },
                ]}
              >
                <Card.Section>
                  <TextContainer>{cms("section.advancedCommission.description")}</TextContainer>
                  <br />
                  <Collapsible open={showHideAdvance} id="advancedCommission">
                    <AdvanceCommission
                      advanceCommissionValue={advanceValue}
                      refetchLoading={networkStatus === 4}
                      standardCommissionValue={standardValue}
                      rangeCommissionValue={rangeValue}
                      setAdvanceValue={setAdvanceValue}
                      updatedAt={updatedAt}
                      priorityValue={priorityValue}
                      isTaxInclusive={isTaxInclusive}
                      refetch={refetch}
                    />
                  </Collapsible>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Collapsible>
          <Collapsible open>
            <Layout.AnnotatedSection
              title={cms("section.commissionByPrice.title")}
              description={cms("section.commissionByPrice.description")}
            >
              <Card
                title={[
                  cms("section.commissionByPrice.title"),
                  updatedAt && (
                    <TextStyle variation="subdued">
                      <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>
                    </TextStyle>
                  ),
                ]}
                actions={[
                  {
                    content:
                      !isStripeSubscription && (showHideRange ? cms("common.label.hide") : cms("common.label.show")),
                    onAction: () => setShowHideRange(!showHideRange),
                    disclosure: !isStripeSubscription && (showHideRange ? constant.UP : constant.DOWN),
                  },
                  // {
                  //   content: cms("common.label.learnMore"),
                  //   onAction: () => learnMore(cms("section.commissionByPrice.title")),
                  // },
                ]}
              >
                <Card.Section>
                  <TextContainer>{cms("section.commissionByPrice.description")}</TextContainer>
                  <br />
                  <Collapsible open={showHideRange} id="commissionByPriceRange">
                    <RangeCommission
                      advanceCommissionValue={advanceValue}
                      refetchLoading={networkStatus === 4}
                      standardCommissionValue={standardValue}
                      updatedAt={updatedAt}
                      rangeCommissionValue={rangeValue}
                      setRangeValue={setRangeValue}
                      priorityValue={priorityValue}
                      isTaxInclusive={isTaxInclusive}
                    />
                  </Collapsible>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Collapsible>
          <Collapsible open>
            <Layout.AnnotatedSection
              title={cms("section.priority.title")}
              description={cms("section.priority.description")}
            >
              <Card
                title={[
                  cms("section.priority.title"),
                  updatedAt && (
                    <TextStyle variation="subdued">
                      <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>
                    </TextStyle>
                  ),
                ]}
                actions={[
                  {
                    content:
                      !isStripeSubscription && (showSetPriority ? cms("common.label.hide") : cms("common.label.show")),
                    onAction: () => setShowSetPriority(!showSetPriority),
                    disclosure: !isStripeSubscription && (showSetPriority ? constant.UP : constant.DOWN),
                  },
                ]}
              >
                <Card.Section>
                  <TextContainer>{cms("section.priority.description")}</TextContainer>
                  <br />
                  <Collapsible open={showSetPriority}>
                    <PriorityCommission
                      advanceCommissionValue={advanceValue}
                      refetchLoading={networkStatus === 4}
                      standardCommissionValue={standardValue}
                      updatedAt={updatedAt}
                      rangeCommissionValue={rangeValue}
                      setPriorityValue={setPriorityValue}
                      priorityValue={priorityValue}
                      isTaxInclusive={isTaxInclusive}
                    />
                  </Collapsible>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Collapsible>
          <Layout.AnnotatedSection
            title={cms("section.commissionByOrder.title")}
            description={cms("section.commissionByOrder.description")}
          >
            <Card sectioned>
              <OrderBasedCommission isOrderBasedCommission={isOrderBased} setIsOrderBasedCommission={setIsOrderBased} />
            </Card>
          </Layout.AnnotatedSection>
        </>
      )}
      {/* <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet> */}
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorCommission), { feature: constant.COMMISSION });
