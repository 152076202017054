import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MediaCard, List } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

const Welcome = (props) => {
  const { currentUserRefetch, cms = {} } = useContext(PrivateContext);
  const { setSelectedTab } = props;
  return (
    <MediaCard
      title={cms("common.onboarding.welcome.title")}
      primaryAction={{
        id: "MC_PORTAL_ONBOARDING_STEP_00",
        content: cms("common.onboarding.welcome.button"),
        onAction: () => {
          setSelectedTab("about");
          currentUserRefetch();
        },
      }}
      description={[
        <>
          <p>{cms("common.onboarding.welcome.description")}</p>
          <br />
          <p>
            <List type="number">
              {cms("common.onboarding.welcome.list") &&
                cms("common.onboarding.welcome.list").map((list) => <List.Item>{list}</List.Item>)}
            </List>
          </p>
          <br />
          <p>{cms("common.onboarding.welcome.paragraph")}</p>
        </>,
      ]}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src="https://ik.imagekit.io/marketcube/platform/welcome.jpg?tr=w-800"
      />
    </MediaCard>
  );
};

Welcome.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default Welcome;
