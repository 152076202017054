import React from "react";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { DigestSetting } from "app/userManagement/modules/generic";

const OperatorDigestSetting = () => {
  return <DigestSetting />;
};

export default withErrorBoundary(OperatorDigestSetting);
