import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import _ from "lodash";
import {
  Banner,
  Button,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  FormLayout,
  Layout,
  Stack,
  Tag,
  TextField,
  TextStyle,
  PageActions,
  TextContainer,
} from "@shopify/polaris";

import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { UPDATE_GEOLOCATION_SETTING, GET_GEOLOCATION_SETTING } from "app/setup/apollo";
import { Spinner, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

const OperatorGeolocationSetting = () => {
  const { cms = {}, history } = useContext(PrivateContext);
  const [isCheckEnabled, setIsCheckEnabled] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [cityName, setCityName] = useState("");
  const [cityError, setCityError] = useState("");

  const [message, setMessage] = useState();
  const [updateAt, setUpdateAt] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const {
    error: errorGeolocationSetting,
    loading: loadingGeolocationSetting,
    data: dataGeolocationSetting,
    refetch,
  } = useQuery(GET_GEOLOCATION_SETTING);

  const responseGeolocationSettingData = baseHelper.getResponseData(
    dataGeolocationSetting,
    constant.gql.GET_GEOLOCATION_SETTING
  );
  const responseGeolocationSettingError = baseHelper.getResponseError(
    errorGeolocationSetting,
    constant.gql.GET_GEOLOCATION_SETTING
  );

  useEffect(() => {
    if (responseGeolocationSettingData) {
      const { isAllowed = false, locations = [], updatedAt: lastUpdated = "" } = responseGeolocationSettingData;
      if (locations && locations.length) {
        setCityList([...locations]);
      }
      setUpdateAt(lastUpdated);
      setIsCheckEnabled(isAllowed);
    }
  }, [dataGeolocationSetting, responseGeolocationSettingData]);

  useEffect(() => {
    if (responseGeolocationSettingError) {
      setBanner({ status: constant.CRITICAL, title: responseGeolocationSettingError, isOpen: true });
    }

    if (errorGeolocationSetting) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGeolocationSetting), isOpen: true });
    }
  }, [responseGeolocationSettingError, errorGeolocationSetting]);

  const [updateGeolocationSetting, { loading: isLoading }] = useMutation(UPDATE_GEOLOCATION_SETTING);

  const validInput = (value, prevValue) => {
    const val =
      (baseHelper.stringNotAcceptSpaceAtStart((value && value.trim()) || "") && value) ||
      (value !== "" && prevValue) ||
      "";
    return val;
  };

  const handleAllowedCheckbox = () => {
    if (isCheckEnabled) {
      setCityList([]);
    }
    setIsCheckEnabled(!isCheckEnabled);
    setIsDisabled(false);
  };

  const handleSelectedCityChange = () => {
    const cityAdded = [...cityList];
    const name = (cityName && cityName.trim().toLowerCase()) || "";
    if (name && name.length <= 2) {
      setCityError(cms("message.error.cityLength"));
      return;
    }
    cityAdded.push(name);
    setCityList(cityAdded);
    setCityName("");
    setIsDisabled(false);
  };

  const handleCityNameChange = (value) => {
    setCityName(value);
    const name = (value && value.trim()) || "";
    setCityError("");
    if (cityList.includes(name)) {
      setCityError(cms("message.error.alreadyAdded"));
    }
  };

  const removeTag = (tag) => () => {
    const options = [...cityList];
    options.splice(options.indexOf(tag), 1);
    setCityList(options);
    setIsDisabled(false);
  };

  const cityTags =
    (cityList &&
      cityList.length &&
      cityList.map((option) => {
        let tagLabel = "";
        tagLabel = option
          .toLowerCase()
          .split(" ")
          .join(" ");
        return (
          <Tag key={`option${option}`} onRemove={removeTag(option)}>
            {tagLabel}
          </Tag>
        );
      })) ||
    [];

  const onSubmit = () => {
    const inputData = {
      isAllowed: !!(cityList && cityList.length),
      locations: [...cityList],
    };

    updateGeolocationSetting({
      variables: {
        input: inputData,
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_GEOLOCATION_SETTING);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_GEOLOCATION_SETTING);
        if (responseData) {
          if (!updateAt) {
            setMessage(cms("message.success.save"));
          } else {
            setMessage(cms("message.success.update"));
          }
          setIsDisabled(true);
          refetch();
        }
        if (responseError) {
          setBanner({
            isOpen: true,
            title: responseError,
            status: constant.CRITICAL,
          });
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          title: errorHelper.parse(exception),
          status: constant.CRITICAL,
        });
      });
  };

  if (loadingGeolocationSetting) {
    return <Spinner />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Banner isOpen title={cms("label.title")} status={constant.INFO} />
        <br />
        <Card
          title={[
            cms("section.title"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.description")}</TextContainer>
            <br />
            <Stack>
              <Checkbox label={cms("label.allow")} checked={isCheckEnabled} onChange={handleAllowedCheckbox} />
            </Stack>
            <Collapsible
              open={isCheckEnabled}
              id="geolocationSetting"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <br />
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    placeholder={cms("placeholder.cityName")}
                    value={cityName}
                    onChange={(value) => handleCityNameChange(validInput(value, cityName))}
                    error={cityError}
                  />
                  <Button
                    primary
                    id="addLink"
                    onClick={handleSelectedCityChange}
                    disabled={cityError || !cityName.trim()}
                  >
                    {cms("button.addCity")}
                  </Button>
                </FormLayout.Group>
                <Stack>{cityTags}</Stack>
              </FormLayout>
            </Collapsible>
            <br />
            {updateAt && (
              <ActionButton>
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => onSubmit(),
                    loading: isLoading,
                    disabled: isDisabled,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </ActionButton>
            )}
          </Card.Section>
        </Card>
        {!updateAt && (
          <ActionButton>
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => onSubmit(),
                loading: isLoading,
                disabled: isDisabled,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          </ActionButton>
        )}
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorGeolocationSetting), {
  feature: constant.MANAGE_GEOLOCATION,
});
