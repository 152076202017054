import { gql } from "apollo-boost";

const ORDER_LIST = gql`
  subscription {
    orderLineView {
      status
      error
      data {
        operationType
        orderLine {
          _id
          isShippingPaid
          vendor
          vendorId
          shop
          orderId
          shopifyLineItemId
          createdAt
          shopifyOrderId
          shopifyOrderDate
          status
          orderNumber
          fulfillmentStatus
          isReturn
          return {
            reason
            requestedAt
            updatedAt
            sellerNote
            vendorNote
            status
            isSellerManaged
          }
        }
      }
    }
  }
`;
export default ORDER_LIST;
