import React, { useContext, useState } from "react";
import { Tabs as PolarisTabs } from "@shopify/polaris";

import { baseHelper, storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import StyledTabs from "./tabsStyle";

import { adminTabs, operatorTabs, providerTabs } from "./tabsConfig/index";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Tabs = () => {
  const { history, isLoading, match, vendorHiddenModule = [] } = useContext(PrivateContext);
  const { chatSetting = {}, cms, currentUser, unreadMessageCount } = useContext(PrivateContext);
  const { isCustomer, isVendor } = chatSetting || {};

  const isDisabled = !isCustomer && !isVendor;

  const getTabs = (role) => {
    if (role === admin) {
      return adminTabs(cms, isLoading);
    }

    if (role === operator) {
      let tabs = operatorTabs(cms, isLoading, currentUser);
      tabs = tabs.filter(
        (tab) =>
          (tab.id !== constant.QUOTES ||
            (tab.id === constant.QUOTES &&
              baseHelper.isPAASEnabled(currentUser._id) &&
              !currentUser.ecommercePlatform)) &&
          (tab.id !== constant.ANALYTICS ||
            (tab.id === constant.ANALYTICS &&
              baseHelper.isRotaroUser(currentUser) &&
              !currentUser.ecommercePlatform))
      );

      // const updatedTabs = tabs.map(tab => {
      //   if (tab.id === "chat") {
      //     tab.content = `${tab.content} (${unreadMessageCount})`;
      //     if (currentUser.ecommercePlatform || isDisabled) {
      //       return false;
      //     }
      //   }
      //   return tab;
      // }).filter(tab => tab);

      return tabs;
    }

    if (role === provider) {
      let tabs = providerTabs(cms, isLoading, currentUser);
      tabs = tabs.filter(
        (tab) =>
          (tab.id !== constant.QUOTES ||
            (tab.id === constant.QUOTES &&
              baseHelper.isPAASEnabled(currentUser.introductorySeller) &&
              !currentUser.ecommercePlatform)) &&
          (tab.id !== constant.ANALYTICS ||
            (tab.id === constant.ANALYTICS &&
              baseHelper.isRotaroUser(currentUser) &&
              !currentUser.ecommercePlatform))
      );

      const updatedTabs = tabs.map(tab => {
        if (tab.id === "chat") {
          tab.content = `${tab.content} (${unreadMessageCount})`;
          if (currentUser.ecommercePlatform || isDisabled) {
            return false;
          }
        }
        if (vendorHiddenModule.includes(tab.id)) {
          return false;
        }
        return tab;
      }).filter(tab => tab);

      return updatedTabs;
    }

    return [];
  };

  const currentPath = match.path;
  const currentUserRole = storageHelper.get("userRole");
  const tabs = getTabs(currentUserRole);
  const updatedTabs = tabs.map((tab) => {
    return { ...tab, content: tab.content };
  });
  const currentTabIndex = tabs.findIndex(
    (tab) => tab.link === currentPath || tab.activeRoutes.find((route) => route === currentPath)
  );

  const initialTabState = currentTabIndex === -1 ? 0 : currentTabIndex;
  const [selectedTab, setSelectedTab] = useState(initialTabState);

  const handleTabChange = (tabIndex) => {
    history.push(tabs[tabIndex].link);
    setSelectedTab(tabIndex);
  };
  if (isLoading) {
    return null;
  }
  return (
    <StyledTabs className="styled-tabs">
      <PolarisTabs key="main-tab" tabs={updatedTabs} selected={initialTabState} onSelect={handleTabChange} />
    </StyledTabs>
  );
};

export default Tabs;
