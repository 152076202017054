import { gql } from "apollo-boost";

// for provider
const UPDATE_PRODUCT_SYNC = gql`
  mutation updateProductSync($input: UpdateProSync!) {
    updateProductSync(input: $input) {
      status
      data
      error
    }
  }
`;

const SYNC_INVENTORY_VENDOR = gql`
  mutation {
    syncInventoryVendor {
      data
      status
      error
    }
  }
`;

export { UPDATE_PRODUCT_SYNC, SYNC_INVENTORY_VENDOR };
