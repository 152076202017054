import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

const Inventory = (props) => {
  const { data, onChange, cms, ecommercePlatform } = props;
  const { sku, inventoryPolicy, quantity } = data;
  const isDontTrackPolicy = inventoryPolicy !== constant.SHOPIFY;

  const handleInventory = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInventoryPolicy = (value) => {
    if (value === constant.DO_NOT_TRACK_INVENTORY) {
      handleInventory(constant.QUANTITY, "");
    }
    handleInventory(constant.INVENTORY_POLICY, value);
  };
  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && quantity);
  };

  return (
    <Card sectioned title={cms("section.inventory.title")}>
      <FormLayout>
        <TextField
          id="sku"
          label={cms("section.inventory.label.sku")}
          value={sku}
          onChange={(value) => handleInventory(constant.SKU, value)}
          placeholder={cms("section.inventory.placeholder.zero")}
        />
        <Select
          id="inventoryPolicy"
          label={cms("section.inventory.label.inventoryPolicy")}
          options={[
            { label: cms("section.inventory.label.notTrack"), value: constant.DO_NOT_TRACK },
            {
              label: cms("section.inventory.label.ecommerce", { item: baseHelper.ucFirst(ecommercePlatform) }),
              value: constant.SHOPIFY,
            },
          ]}
          onChange={handleInventoryPolicy}
          value={inventoryPolicy}
        />
        {inventoryPolicy === constant.SHOPIFY ? (
          <TextField
            id="inventoryQuantity"
            label={cms("section.inventory.label.quantity")}
            value={quantity && quantity.toString()}
            onChange={(value) => handleInventory(constant.QUANTITY, Number(acceptOnlyValidInput(value)))}
            placeholder={cms("section.inventory.placeholder.zero")}
            disabled={isDontTrackPolicy}
          />
        ) : (
          ""
        )}
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  cms: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withErrorBoundary(Inventory);
