import PropTypes from "prop-types";

const shippingRuleProp = {
  type: {
    isVendorAllowed: PropTypes.bool,
    setBanner: PropTypes.func,
  },
};

const listProp = {
  type: {
    bands: PropTypes.arrayOf(PropTypes.object),
    destinations: PropTypes.arrayOf(PropTypes.object),
    isVendorAllowed: PropTypes.bool,
    loading: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.object),
    refetch: PropTypes.func,
    rules: PropTypes.arrayOf(PropTypes.object),
    setBanner: PropTypes.func,
  },
};

const ruleItemProp = {
  type: {
    bands: PropTypes.arrayOf(PropTypes.object),
    destinations: PropTypes.arrayOf(PropTypes.object),
    item: PropTypes.object,
    moneyFormat: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
    sVendorAllowed: PropTypes.bool,
    toggleDeleteModal: PropTypes.func,
    toggleModal: PropTypes.func,
  },
};

const addRuleProp = {
  type: {
    bands: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    destinations: PropTypes.arrayOf(PropTypes.object),
    handleBanner: PropTypes.func,
    onClose: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.object),
    refetch: PropTypes.func,
    shippingRule: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object])
    ),
    show: PropTypes.bool,
  },
};

const ruleConditionProp = {
  shippingRule: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object])
  ),
  isConditionApplied: PropTypes.bool,
  moneyFormat: PropTypes.string,
  handleCriteria: PropTypes.func,
  handleCondition: PropTypes.func,
};
const editRuleProp = addRuleProp;

export { shippingRuleProp, listProp, ruleItemProp, addRuleProp, editRuleProp, ruleConditionProp };
