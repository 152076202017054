import constant from "lib/constant/constant";

const editProfileConfig = (cms) => {
  const {
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
  } = constant;
  const textFields = [
    {
      label: `${cms("common.label.firstName")}`,
      placeholder: cms("common.placeholder.firstName"),
      key: FIRST_NAME,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.lastName")}`,
      placeholder: cms("common.placeholder.lastName"),
      key: LAST_NAME,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.email")}`,
      placeholder: cms("common.placeholder.emailAddress"),
      key: EMAIL,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.phone")}`,
      placeholder: cms("common.placeholder.phoneNumber"),
      key: PHONE_NUMBER,
      type: "text",
      min: 0,
      tag: "input",
    },
    {
      label: `${cms("common.label.streetAddress")}`,
      placeholder: cms("common.placeholder.streetAddress"),
      key: STREET_ADDRESS,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.city")}`,
      placeholder: cms("common.placeholder.city"),
      key: CITY,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.country")}`,
      placeholder: cms("common.placeholder.country"),
      key: COUNTRY,
      type: "text",
      tag: "input",
    },
    {
      label: `${cms("common.label.provinceCode")}`,
      placeholder: cms("common.placeholder.provinceCode"),
      key: PROVINCE_CODE,
      type: "text",
      tag: "input",
    },
    // {
    //   label: `${cms("common.label.country")}*`,
    //   placeholder: cms("common.placeholder.country"),
    //   key: COUNTRY,
    //   type: "text",
    //   tag: "select",
    // },
    {
      label: `${cms("common.label.postalCode")}`,
      placeholder: cms("common.placeholder.postalCode"),
      key: POSTAL_CODE,
      type: "text",
      tag: "input",
    },
  ];
  return textFields;
};

export default editProfileConfig;
