import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { Link } from "@shopify/polaris";
import { SHOPIFY_INSTALL_LOGIN } from "app/plans/apollo/mutations";
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { PublicContext } from "lib/context";
import { Banner } from "lib/components";

const Redirect = () => {
  const [shopifyInstallLogin, { loading }] = useMutation(SHOPIFY_INSTALL_LOGIN);
  const { history } = useContext(PublicContext);
  const searchParams = history && history.location && history.location.search;
  const { shop, code } = baseHelper.getQueryParams(searchParams);
  const [errorMessage, setErrorMessage] = useState("");

  const setURL = (url) => {
    window.top.location = url;
  };

  // component did mount
  // commenting below code as it is used for registering seller through marketcube listing app and we are using shopify customa app for registering seller
  // useEffect(() => {
  //   storageHelper.set("shop", shop);
  //   const shopifyInstallLoginInput = {
  //     shop,
  //     code,
  //   };
  //   shopifyInstallLogin({
  //     variables: { input: shopifyInstallLoginInput },
  //   })
  //     .then((res) => {
  //       const responseData = baseHelper.getResponseData(res.data, "shopifyInstallLogin");
  //       if (responseData && responseData.token) {
  //         storageHelper.set("token", responseData.token);
  //         storageHelper.set("shop", shop);
  //         storageHelper.set("isInitial", true);
  //         const route = "/";
  //         // setURL(baseHelper.shopifyUrl(shop, route));
  //         history.push(route);
  //       } else {
  //         const responseError = baseHelper.getResponseError(res.data, "shopifyInstallLogin");
  //         setErrorMessage(responseError);
  //       }
  //     })
  //     .catch((err) => {
  //       const parsedError = errorHelper.parse(err);
  //       setErrorMessage(parsedError);
  //     });
  // }, [code, shop, shopifyInstallLogin]);

  if (loading) {
    return (
      <div>
        <h3>
        This is an invalid attempt. Please access marketplace dashboard using your login credentials at <Link url="https://marketplace.marketcube.io/">https://marketplace.marketcube.io/</Link>
        </h3>
      </div>
    );
  }

  if (errorMessage) {
    return <Banner title={errorMessage} status="critical" />;
  }

  return (
    <div>
      <h3>
      This is an invalid attempt. Please access marketplace dashboard using your login credentials at <Link url="https://marketplace.marketcube.io/">https://marketplace.marketcube.io/</Link>
      </h3>
    </div>
  );
};

export default Redirect;
