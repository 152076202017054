import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

import { storageHelper, baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import context
import { PrivateContext, PrivateBrandingProvider } from "lib/context";

// import query
import { GET_SELLER_BRANDING } from "./apollo/queries";

const {
  gql: { GET_SELLER_BRANDING: GET_SELLER_BRANDING_GQL },
} = constant;

const PrivateBrandingContainer = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { children = null } = props;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const [getSellerBranding, { loading: sellerBrandingLoading, data: sellerBrandingData }] = useLazyQuery(
    GET_SELLER_BRANDING
  );
  const [branding, setBranding] = useState({});
  const [previousButtonFont, setPreviousButtonFont] = useState("");
  const [buttonCSS, setButtonCSS] = useState(storageHelper.get("buttonCSS"));
  const fetchSellerBrandingData = useCallback(() => {
    try {
      getSellerBranding();
    } catch (err) {
      return false;
    }
    return false;
  }, [getSellerBranding]);

  const setBrandingData = useCallback(
    (data) => {
      const setDefault = "setDefault";
      const defaultButtonColor = "linear-gradient(180deg,#6371c7,#5563c1)";
      const { sellerButtonColor, buttonFont } = branding;

      const buttonText = data.buttonFont || buttonFont || "";
      let bColor = "";
      if (sellerButtonColor || data.buttonColor) {
        bColor = data.buttonColor || sellerButtonColor;
      }

      if (data.buttonColor === setDefault) {
        bColor = "";
      }

      let buttonCSSData = "";
      if (bColor && (data.buttonColor || sellerButtonColor) && buttonText) {
        buttonCSSData = `.Polaris-Page .Polaris-Button--primary:not(.Polaris-Button--disabled), #__talkjs_launcher, .Polaris-MediaCard__PrimaryAction  .Polaris-Button--primary, .Polaris-MediaCard__PrimaryAction .Polaris-Button  {
          background: ${bColor || defaultButtonColor} !important;
          border-color: ${data.buttonColor || sellerButtonColor} !important;
          color: ${buttonText} !important;
          ${
            bColor &&
            `box-shadow: inset 0 1px 0 0 ${
              data.buttonColor || sellerButtonColor
            }, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;`
          }
        }
      `;
      } else if (bColor && (data.buttonColor || sellerButtonColor)) {
        buttonCSSData = `.Polaris-Page .Polaris-Button--primary {
          background: ${bColor || defaultButtonColor} !important;
          border-color: ${data.buttonColor || sellerButtonColor} !important;
          ${
            bColor &&
            `box-shadow: inset 0 1px 0 0 ${
              data.buttonColor || sellerButtonColor
            }, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;`
          }
        }
      `;
      } else if (buttonText) {
        buttonCSSData = `.Polaris-Page .Polaris-Button--primary {
          color: ${buttonText} !important;
        }
      `;
      }

      setBranding((prevState) => ({
        ...prevState,
        headerFont: data.headerFont || "",
        headerColor: data.headerColor || "",
        buttonCSS: buttonCSSData,
        loginText: data.loginText || "",
        register: data.register || "",
      }));
    },
    [branding]
  );

  useEffect(() => {
    if (isSeller) {
      if (currentUser && currentUser.plan && currentUser.plan.isBranding && Object.entries(branding).length === 0) {
        const footerText = currentUser.footerText && currentUser.footerText !== "" && currentUser.footerText;
        setBranding((prevState) => ({
          ...prevState,
          brand: currentUser.brand,
          logoUrl: currentUser.logo,
          isDefaultLogo: !(currentUser.logo && currentUser.logo.trim() !== "") || false,
          loginText: currentUser.loginText || false,
          register: currentUser.register || false,
          brandName: currentUser.brandName || false,
          footerText: footerText || "default",
          headerColor: (currentUser.style && currentUser.style.header && currentUser.style.header.color) || "",
          sellerButtonColor: (currentUser.style && currentUser.style.button && currentUser.style.button.color) || "",
          headerFont: (currentUser.style && currentUser.style.header && currentUser.style.header.font) || "",
          buttonFont: (currentUser.style && currentUser.style.button && currentUser.style.button.font) || "",
        }));
      }
    }
    if (isVendor) {
      fetchSellerBrandingData();
    }
    if (branding.buttonFont && branding.buttonFont !== previousButtonFont) {
      setPreviousButtonFont(branding.buttonFont);
      setBrandingData(branding);
    }
  }, [
    isSeller,
    isVendor,
    currentUser,
    branding.buttonFont,
    fetchSellerBrandingData,
    branding,
    previousButtonFont,
    setBrandingData,
  ]);

  useEffect(() => {
    if (sellerBrandingLoading) {
      return;
    }
    if (sellerBrandingData && Object.entries(branding).length === 0) {
      const resData = baseHelper.getResponseData(sellerBrandingData, GET_SELLER_BRANDING_GQL);
      if (!resData) {
        return;
      }

      const isBranded = baseHelper.isBranded(resData);
      if (!isBranded) {
        return;
      }

      const isHideMc = isBranded && resData.isHideMcInfo;
      storageHelper.set("hideMcDetails", isHideMc);

      // const hidePoweredBy = isBranded && baseHelper.isProPlan(resData);
      storageHelper.set("hidePoweredBy", isHideMc);

      const footerText = resData.footerText && resData.footerText !== "" && resData.footerText;

      setBranding((prevState) => ({
        ...prevState,
        logoUrl: resData.logo,
        brand: resData.brand,
        isDefaultLogo: !(resData.logo && resData.logo.trim() !== "") || false,
        headerColor: resData.style && resData.style.header && resData.style.header.color,
        sellerButtonColor: resData.style && resData.style.button && resData.style.button.color,
        footerText: footerText || "default",
        loginText: resData.loginText || false,
        register: resData.register || false,
        brandName: resData.brandName || false,
        headerFont: resData.style && resData.style.header && resData.style.header.font,
        buttonFont: resData.style && resData.style.button && resData.style.button.font,
        sellerEmail: resData && resData.email && resData.email.address,
        sellerName: resData && resData.shop,
        isHideMc,
        hidePoweredBy: isHideMc,
      }));
    }
  }, [branding, sellerBrandingData, sellerBrandingLoading]);

  useEffect(() => {
    if (Object.entries(branding).length !== 0) {
      if (buttonCSS !== branding.buttonCSS) {
        setButtonCSS(branding.buttonCSS);
        storageHelper.set("buttonCSS", branding.buttonCSS);
      }
      const currentBrandName = storageHelper.get("brandName");
      if (branding !== currentBrandName) {
        storageHelper.set("brandName", branding.brandName);
      }
    }
  }, [branding.buttonCSS, buttonCSS, branding]);

  return (
    <>
      <style>{buttonCSS}</style>
      <PrivateBrandingProvider
        value={{
          branding,
          setBrandingData,
          brandingLoading: !!sellerBrandingLoading,
        }}
      >
        {children}
      </PrivateBrandingProvider>
    </>
  );
};

PrivateBrandingContainer.propTypes = {
  children: PropTypes.node,
};

PrivateBrandingContainer.defaultProps = {
  children: null,
};

export default PrivateBrandingContainer;
