import React, { useContext } from "react";
import { FormLayout, Select } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const AdvanceFilters = (props) => {
  const { setTaggedWith, taggedWith, selectedFilter } = props;
  const { cms, history } = useContext(PrivateContext);

  const filterMonthOptions = [
    { label: "Current Month", value: "current" },
    { label: "Previous Month", value: "previous" },
    { label: "Upto 6 Months", value: "halfYearly" },
    { label: "Upto 1 Year", value: "yearly" },
  ];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { listFilter: "month", listValue: value });
  };

  return (
    <>
      {(selectedFilter && (
        <Select
          label="Select Months"
          placeholder="Select"
          value={taggedWith || ""}
          onChange={handleTaggedWithChange}
          options={filterMonthOptions}
        />
      )) ||
        null}
    </>
  );
};

const Filter = (props) => {
  const { selectedFilter, setSelectedFilter } = props;
  const { cms, history } = useContext(PrivateContext);

  const filterOptions = [{ label: "Month", value: "month" }];

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { listFilter: value });
  };

  return (
    <Select
      label={cms("common.label.allVendor")}
      placeholder={cms("common.placeholder.select")}
      value={selectedFilter || ""}
      onChange={handleSelectChange}
      options={filterOptions}
    />
  );
};

AdvanceFilters.defaultProps = {
  setTaggedWith: () => {},
  taggedWith: "",
  selectedFilter: "",
};

AdvanceFilters.propTypes = {
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  selectedFilter: PropsType.string,
};

Filter.defaultProps = {
  setTaggedWith: () => {},
  setSelectedFilter: () => {},
  selectedFilter: "",
};

Filter.propTypes = {
  setTaggedWith: PropsType.func,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export { AdvanceFilters as default, Filter };
