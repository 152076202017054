import { gql } from "apollo-boost";

const DISCONNECT_SHOP = gql`
  mutation disconnectShop($input: DisconnectShop) {
    disconnectShop(input: $input) {
      status
      data
      error
    }
  }
`;

const SYNC_LAKE_INVENTORY_VENDOR = gql`
  mutation syncLakeInventoryVendor {
    syncLakeInventoryVendor {
      status
      data
      error
    }
  }
`;

const SYNC_WOOCOMMERCE_VENDOR_PRODUCT = gql`
  mutation syncWooCommerceVendorProduct {
    syncWooCommerceVendorProduct {
      status
      data
      error
    }
  }
`;

export {
  DISCONNECT_SHOP,
  SYNC_LAKE_INVENTORY_VENDOR,
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT,
};
