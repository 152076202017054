import React, { useContext, useState, useEffect } from "react";
import { Layout, Stack, Card, PageActions, Select, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
import { Banner, Toast, SkeletonAnnotated } from "lib/components";
import { GET_SHIPPO_CARRIER, GET_VENDOR_FULFILLMENT, UPDATE_VENDOR_FULFILLMENT } from "app/setup/apollo";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withFeature, withErrorBoundary } from "lib/hoc";

const Carrier = () => {
  const {
    cms,
    currentUser: { _id },
  } = useContext(PrivateContext);
  const { CRITICAL, gql } = constant;

  const [carrierOption, setCarrierOption] = useState("");
  const [defaultCarrier, setDefaultCarrier] = useState("");
  const [toast, setToast] = useState("");

  const [options, setOptions] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { loading: vendorFulfillmentLoading, data: vendorFulfillmentData } = useQuery(GET_VENDOR_FULFILLMENT);
  const { error: shippoError, loading: shippoLoading, data: shippoData } = useQuery(GET_SHIPPO_CARRIER, {
    variables: {
      input: { isServiceLevel: true },
    },
  });

  useEffect(() => {
    if (vendorFulfillmentData) {
      const vendorFulfillmentResponse =
        baseHelper.getResponseData(vendorFulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};
      if (vendorFulfillmentResponse?.dropship?.shippo?.defaultCarrier) {
        const {
          dropship: {
            shippo: { defaultCarrier: sellerDefaultCarrier },
          },
        } = vendorFulfillmentResponse || {};
        const { name } = sellerDefaultCarrier || {};
        setDefaultCarrier(name || "");
      }
      if (vendorFulfillmentResponse?.dropship?.shippo?.vendorCarrier) {
        const vendorCarrierOption = vendorFulfillmentResponse?.dropship?.shippo?.vendorCarrier.find(
          (item) => _id === item.vendorId
        );
        setCarrierOption((vendorCarrierOption && vendorCarrierOption.service) || "");
      }
    }
  }, [_id, gql.GET_SELLER_FULFILLMENT, vendorFulfillmentData]);

  useEffect(() => {
    if (shippoError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(shippoError) });
    }
    if (!shippoData) {
      return;
    }
    const shippoCarrierData = baseHelper.getResponseData(shippoData, constant.gql.GET_SHIPPO_CARRIER);
    if (!shippoCarrierData) {
      return;
    }

    if (shippoCarrierData) {
      const shippoDataList = shippoCarrierData.map((item) => {
        return {
          label: item.name,
          name: item.name,
          value: item.service,
          service: item.service,
        };
      });
      setOptions(shippoDataList);
    }

    const shippoCarrierError = baseHelper.getResponseError(shippoData, constant.gql.GET_SHIPPO_CARRIER);
    if (shippoCarrierError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: shippoCarrierError });
    }
  }, [shippoData, shippoError]);

  const [updateVendorFulfillment, { loading: updateVendorFulfillmentLoading }] = useMutation(UPDATE_VENDOR_FULFILLMENT);
  const toggleBanner = () => setBanner({ ...banner });
  const bannerStatus = () => {
    if (banner.isOpen) {
      return (
        <>
          <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
          <br />
        </>
      );
    }
    return null;
  };
  const onSubmit = async () => {
    const vendorCarrierInput = options && options.length && options.find((item) => item.value === carrierOption);
    const formValues = {
      id: vendorCarrierInput.value,
      name: vendorCarrierInput.name,
      service: vendorCarrierInput.service,
      vendorId: _id,
    };
    await updateVendorFulfillment({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_VENDOR_FULFILLMENT);
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_VENDOR_FULFILLMENT);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setToast(cms("message.success.select"));
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
    setSubmitEnabled(false);
  };

  if (vendorFulfillmentLoading || shippoLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        {bannerStatus()}
        <Card title={cms("label.select")} sectioned>
          <Stack wrap={false}>
            <Stack.Item fill>
              <Select
                placeholder={`${cms("placeholder.select")}`}
                options={options}
                onChange={(value) => [setCarrierOption(value), setSubmitEnabled(true)]}
                value={carrierOption || ""}
              />
            </Stack.Item>
          </Stack>
          <br />
          <TextStyle variation="subdued">{`${cms("label.default")}`}</TextStyle>
          <TextStyle variation="strong">{` ${defaultCarrier}.`}</TextStyle>
        </Card>
        <PageActions
          primaryAction={{
            id: "submit",
            content: `${cms("common.button.submit")}`,
            disabled: !submitEnabled,
            loading: updateVendorFulfillmentLoading,
            onClick: onSubmit,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: `${cms("common.button.cancel")}`,
            },
          ]}
        />
        <div className="toast">
          <Toast message={toast} setToast={setToast} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(Carrier), { feature: constant.CARRIER });
