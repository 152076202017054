import React, { useContext, useState } from "react";
import { Layout } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Banner, Sheet } from "lib/components";

// import subFeatures
import { EditBrand, EditPolicy, EditBusinessTaxInfo } from "./subFeatures";

const OperatorEditProfile = () => {
  const { cms } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    status: "",
    title: "",
    isOpen: false,
  });
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };
  const learnMore = (content, title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <EditBrand setBanner={setBanner} learnMore={learnMore} />
      <EditPolicy setBanner={setBanner} learnMore={learnMore} />
      <EditBusinessTaxInfo setBanner={setBanner} />
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorEditProfile), { feature: constant.EDIT_PROFILE });
