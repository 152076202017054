import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { LocationMappingSetting } from "app/setup/modules/operator/features/index";
import { ProviderLocationMapper } from "app/setup/modules/provider/features/index";

const { userKey } = constant;
const { operator, provider } = userKey;

const LocationMapping = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <LocationMappingSetting />;
    }
    if (role === provider) {
      return <ProviderLocationMapper />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const TermsComponent = getComponent(currentUserRole);
  return TermsComponent;
};

export default LocationMapping;
