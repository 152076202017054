import React, { useState, useEffect, useContext } from "react";

import { Layout, Page } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import from lib
import constant from "lib/constant/constant";
import { Banner } from "lib/components";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";

import { NotFound } from "layout/private/components";

import { GET_ORDER_LAKE_LINE_VIEW, GET_ORDER_LAKE } from "app/orderLake/apollo/queries";
import OrderSlipView from "app/orderLake/modules/generic/slip/subFeature/orderSlipView";

const OperatorOrderSlip = () => {
  const { match } = useContext(PrivateContext);
  const { params } = match;
  const { id: orderId } = params;
  const defaultLogo = constant.MARKET_CUBE;
  const [sellerInfo, setSellerInfo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [isPackingSlipFound, setIsPackingSlipFound] = useState(true);
  const [orderData, setOrderData] = useState(false);
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });

  const { error: orderDetailsError, data: orderDetailsData, loading } = useQuery(GET_ORDER_LAKE_LINE_VIEW, {
    variables: { input: { orderId } },
  });

  const { data: getOrderData } = useQuery(GET_ORDER_LAKE, {
    variables: { input: { orderId } },
  });

  const imageToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  // getOrderSlipDetails
  useEffect(() => {
    const orderSlipResponseData = baseHelper.getResponseData(orderDetailsData, constant.gql.GET_ORDER_LAKE_LINE_VIEW);
    const orderSlipResponseError = baseHelper.getResponseError(orderDetailsData, constant.gql.GET_ORDER_LAKE_LINE_VIEW);
    const orderLakeResponseData = baseHelper.getResponseData(getOrderData, constant.gql.GET_ORDER_LAKE);
    if (orderSlipResponseData || orderLakeResponseData) {
      setIsPackingSlipFound(true);
      setOrderLineItems(orderSlipResponseData || []);
      setOrderData(orderLakeResponseData);
      const { seller } = orderSlipResponseData;
      if (seller && seller.plan && seller.plan.isBranding) {
        imageToDataURL(seller.logo, (url) => {
          setSellerInfo(seller);
          setLogo(seller.logo && url);
        });
      }
    }
    if (orderSlipResponseError) {
      setIsPackingSlipFound(false);
      setNotFound(true);
      setBanner({
        isOpen: true,
        title: orderSlipResponseError,
        status: constant.CRITICAL,
      });
    }
  }, [defaultLogo, orderDetailsData, getOrderData]);
  // end

  useEffect(() => {
    if (orderDetailsError) {
      setIsPackingSlipFound(false);
      setBanner({ isOpen: true, title: errorHelper.parse(orderDetailsError), status: constant.CRITICAL });
    }
  }, [orderDetailsError]);
  if (notFound) {
    return (
      <div className="Polaris-Page__OrderSlip">
        <NotFound />
      </div>
    );
  }
  return (
    <div className="packing-slip">
      <Page>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              message={banner.message}
              onDismiss={() => {
                setBanner({ isOpen: false, title: "", status: "" });
              }}
            />
          </Layout.Section>
        )}
      </Page>
      {isPackingSlipFound && (
        <OrderSlipView
          sellerInfo={sellerInfo}
          orderData={orderData}
          loading={loading}
          logo={logo}
          orderLineItems={orderLineItems}
        />
      )}
    </div>
  );
};

export default withFeature(withErrorBoundary(OperatorOrderSlip), { feature: constant.ORDER_ADD });
