import { gql } from "apollo-boost";

const GET_ADDRESS = gql`
  query GetAddressList($input: GetListInput) {
    getAddress(input: $input) {
      data {
        addressList {
          address
          userId
          city
          country
          countryCode
          isDefault
          label
          postalCode
          _id
        }
        count
      }
      status
      error
    }
  }
`;

export const GET_ADDRESS_DETAILS = gql`
  query getAddressDetails($input: GetAddressDetails!) {
    getAddressDetails(input: $input) {
      data {
        _id
        address
        userId
        city
        country
        postalCode
        label
      }
      status
      error
    }
  }
`;

export const GET_SELLER_DEFAULT_ADDRESS = gql`
  query {
    getSellerDefaultAddress {
      data {
        _id
        address
        userId
        city
        country
        postalCode
        label
      }
      status
      error
    }
  }
`;

export default GET_ADDRESS;
