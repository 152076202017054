import { gql } from "apollo-boost";

const GET_VENDOR_EXPORT_SETTING = gql`
  query getVendorExportSetting {
    getVendorExportSetting {
      data {
        export {
          brandName {
            isHideCsv
            isHidePdf
            label
          }
          brandHandle {
            isHideCsv
            isHidePdf
            label
          }
          brandDescription {
            isHideCsv
            isHidePdf
            label
          }
          brandLogo {
            isHideCsv
            isHidePdf
            label
          }
          brandCoverImage {
            isHideCsv
            isHidePdf
            label
          }
          firstName {
            isHideCsv
            isHidePdf
            label
          }
          lastName {
            isHideCsv
            isHidePdf
            label
          }
          email {
            isHideCsv
            isHidePdf
            label
          }
          phoneNumber {
            isHideCsv
            isHidePdf
            label
          }
          streetAddress {
            isHideCsv
            isHidePdf
            label
          }
          city {
            isHideCsv
            isHidePdf
            label
          }
          provinceCode {
            isHideCsv
            isHidePdf
            label
          }
          country {
            isHideCsv
            isHidePdf
            label
          }
          postalCode {
            isHideCsv
            isHidePdf
            label
          }
          bankName {
            isHideCsv
            isHidePdf
            label
          }
          accountNumber {
            isHideCsv
            isHidePdf
            label
          }
          sortCode {
            isHideCsv
            isHidePdf
            label
          }
          paymentCountry {
            isHideCsv
            isHidePdf
            label
          }
          facebookUrl {
            isHideCsv
            isHidePdf
            label
          }
          youtubeUrl {
            isHideCsv
            isHidePdf
            label
          }
          twitterUrl {
            isHideCsv
            isHidePdf
            label
          }
          instagramUrl {
            isHideCsv
            isHidePdf
            label
          }
          linkedinUrl {
            isHideCsv
            isHidePdf
            label
          }
          shippingStreetAddress {
            isHideCsv
            isHidePdf
            label
          }
          shippingCity {
            isHideCsv
            isHidePdf
            label
          }
          shippingCountry {
            isHideCsv
            isHidePdf
            label
          }
          shippingPostalCode {
            isHideCsv
            isHidePdf
            label
          }
          customFields {
            isHideCsv
            isHidePdf
            label
            key
            inputType
          }
        }
        updatedAt
      }
      status
      error
    }
  }
`;
export default GET_VENDOR_EXPORT_SETTING;
