import PropTypes from "prop-types";

const exportProps = {
  type: {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  },
  default: {
    openModal: false,
    setOpenModal: () => {},
  },
};

const listProps = {
  type: {
    items: PropTypes.array,
    selected: PropTypes.number,
    selectedItems: PropTypes.array,
    handleSelection: PropTypes.func,
    productLoading: PropTypes.bool,
    onExport: PropTypes.func,
  },
  default: {
    items: [],
    selected: 0,
    selectedItems: [],
    handleSelection: () => {},
    productLoading: false,
    onExport: () => {},
  },
};

export { exportProps, listProps };
