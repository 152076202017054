import React, { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Badge,
  Button,
  Caption,
  Card,
  Heading,
  Icon,
  Layout,
  Link,
  Page,
  Stack,
  TextContainer,
  TextStyle,
  // Tooltip,
} from "@shopify/polaris";

// import page content
import { greyIcon, blueInfoIcon } from "app/public/icon/icon";

import { PublicContext } from "lib/context";
import { Spinner } from "lib/components";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";
import Banner from "lib/components/banner/banner";

// import gql
import { FETCH_PLANS } from "app/plans/apollo/queries";
import {
  LOGIN_WOOCOMMERCE_SELLER,
  REGISTER_WOOCOMMERCE_SELLER,
} from "app/public/apollo/mutations/registerWooCommerceSeller";

// import constant
import constant from "lib/constant/constant";

const { gql } = constant;
const WooCommerceInstall = () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [value, setValue] = useState({
    loaded: false,
    loadingPlan: "",
  });

  const { data: planData, error: planError, loading: isPlanLoading } = useQuery(FETCH_PLANS);
  const [registerWooCommerceSeller, { loading: registerWooCommerceSellerLoading }] = useMutation(
    REGISTER_WOOCOMMERCE_SELLER
  );

  const [loginWooCommerceSeller, { loading: loginWooCommerceSellerLoading }] = useMutation(LOGIN_WOOCOMMERCE_SELLER);

  const { cms, history } = useContext(PublicContext);
  const { location } = history;
  const { state } = location;
  const queryParams = baseHelper.getQueryParams(history.location.search) || {};
  const { token } = queryParams;

  const onload = async () => {
    try {
      const response = await loginWooCommerceSeller({ variables: { input: { token } } });
      const responseData = baseHelper.getResponseData(response.data, gql.LOGIN_WOOCOMMERCE_SELLER);
      if (!(responseData && responseData.token)) {
        if (state && state.isError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: state.message });
        }
        return true;
      }
      storageHelper.set("token", responseData.token);
      history.push("/");
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.sellerloginError") });
    }
    return true;
  };

  useEffect(() => {
    if (token) {
      onload();
    }
  }, [token]);

  const plans = baseHelper.getResponseData(planData, gql.GET_PLANS) || [];
  if (isPlanLoading) {
    return (
      <div>
        <Spinner fontSize={42} />
        <h3 className="text-center">{cms("message.information")}</h3>
      </div>
    );
  }
  if (!plans.length) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={cms("message.planNotFound")} />
      </Layout.Section>
    );
  }

  // return <div className="text-center">Redirecting to Install accept Page</div>;

  const submitPlan = async (element) => {
    setValue({ loaded: false, loadingPlan: element.name });
    // const queryParams = baseHelper.getQueryParams(history.location.search) || {};
    const { token: wooCommerceTempId } = queryParams;
    const isWooCommerceSeller = wooCommerceTempId;

    if (isWooCommerceSeller) {
      const data = {
        planId: baseHelper.mongoIdAsString(element._id),
        wooCommerceTempId,
      };

      const response = await registerWooCommerceSeller({ variables: { input: data } });
      const responseData = baseHelper.getResponseData(response.data, gql.REGISTER_WOOCOMMERCE_SELLER);
      if (!(responseData && responseData.parentToken)) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.sellerRegisterError") });
        return true;
      }
      storageHelper.set("token", responseData.parentToken);

      history.push("/");
    } else {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.sellerRegisterError") });
    }
    return true;
  };

  const renderPlans = (planList) => {
    const { loaded, loadingPlan } = value;

    const render = planList.map((plan) => {
      const isFree = baseHelper.isFreePlan({ plan });
      const cost = (isFree && cms("label.free")) || `$${plan.monthlyFee}/${cms("label.month")}`;
      const planType = !isFree && cms("label.coming");
      const iconData = (!isFree && greyIcon) || blueInfoIcon;
      const branding = (plan.isBranding && cms("label.custom")) || cms("label.marketcube");
      const maxVendors = plan.labelVendor;
      const { maxProducts } = plan;
      const addDetails = !isFree && cms("message.later");
      const detailsVariation = "negative";
      return (
        <Card>
          <Card.Section>
            <Stack alignment="top">
              <Stack.Item>
                <Icon source={iconData.icon} backdrop color={iconData.className} />
              </Stack.Item>
              <Stack.Item fill>
                <TextContainer spacing="tight">
                  <Heading>{plan.name}</Heading>
                  <p>
                    <b>
                      {cms("label.maxVendors")}
                      :
                    </b> 
                    {maxVendors}
                    <br />
                    <b>
                      {cms("label.maxProducts")}
                      :
                    </b> 
                    {maxProducts}
                    <br />
                    <b>
                      {cms("label.branding")}
                      :
                    </b> 
                    {branding}
                  </p>
                </TextContainer>
              </Stack.Item>
              {planType && (
                <Stack.Item>
                  <Badge>{planType}</Badge>
                </Stack.Item>
              )}
              <Stack.Item>
                <TextStyle variation="positive">
                  <Button
                    size="slim"
                    disabled={!isFree || (loadingPlan && loadingPlan !== plan.name)}
                    primary={isFree || (loadingPlan && loadingPlan === plan.name)}
                    onClick={() => submitPlan(plan)}
                    loading={!loaded && loadingPlan === plan.name}
                  >
                    {cost}
                  </Button>
                </TextStyle>
              </Stack.Item>
            </Stack>
            <br />
            {addDetails && (
              <TextStyle variation={detailsVariation}>
                <Caption>
                  <b>{cms("label.note")}</b>
                  {addDetails}
                </Caption>
              </TextStyle>
            )}
          </Card.Section>
        </Card>
      );
    });
    return render;
  };

  if (registerWooCommerceSellerLoading || loginWooCommerceSellerLoading) {
    return (
      <div>
        <Spinner fontSize={42} />
        <h3 className="text-center">{cms("message.wait")}</h3>
      </div>
    );
  }

  const onDismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
      <Page>
        <Layout>
          <Layout.Section>
            <div className="Polaris-Page__AppInstall">{renderPlans(plans)}</div>
          </Layout.Section>
          <Layout.Section>
            <div className="Polaris-Page__AppInstallFooter">
              <TextStyle variation="subdued">
                {cms("message.byProceeding")}
                <Link for="/">{cms("message.termsOfService")}</Link>
                <br />
                {cms("message.subjectToGovt")}
              </TextStyle>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default WooCommerceInstall;
