import React from "react";
import { Checkbox, DataTable, TextField, TextStyle /* Thumbnail */ } from "@shopify/polaris";

import constant from "lib/constant/constant";
// eslint-disable-next-line import/no-cycle
import { baseHelper } from "lib/helpers";

// eslint-disable-next-line import/no-cycle
import { withErrorBoundary } from "lib/hoc";

import { CurrencyTable } from "app/setup/modules/operator/features/currency/currencyStyle";

const { CURRENCY_TABLE_HEADING, CURRENCY_COLUMN } = constant;

const Table = (props) => {
  const {
    cms,
    data,
    inlineError,
    isAllow,
    loading,
    setData,
    setInlineError,
    setIsAllow,
    setIsOpenCheckboxModal,
    setSubmitEnable,
  } = props;

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validateTenDecimalPositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  const onHandleChange = (value, key, item) => {
    let updateRate = {};
    let payment = true;
    let isModal = false;
    updateRate = data.currency.rate.map((element) => {
      let res = element;
      if (element.currencyCode === item.currencyCode) {
        res = {
          ...res,
          [key]: value,
        };
      }
      if (key === constant.PRICE && value <= 0) {
        setInlineError((prevState) => ({ ...prevState, [item.currencyCode]: true }));
        setSubmitEnable(true);
      } else {
        setInlineError((prevState) => ({ ...prevState, [item.currencyCode]: false }));
        setSubmitEnable(false);
      }
      if (key === constant.IS_PAYMENT && !res[key]) {
        payment = false;
      }
      return res;
    });
    if (key === constant.IS_PAYMENT && value === false) {
      isModal = true;
    }
    setIsOpenCheckboxModal(isModal);
    // eslint-disable-next-line no-unused-expressions
    updateRate &&
      updateRate.forEach(({ price, currencyCode }) => {
        if (price <= 0 || price === "") {
          setInlineError((prevState) => ({ ...prevState, [currencyCode]: true }));
          setSubmitEnable(true);
        }
      });
    setIsAllow({
      payment: key === constant.IS_PAYMENT ? payment : isAllow.payment,
    });
    setData((preData) => ({
      ...preData,
      currency: { ...preData.currency, rate: updateRate },
    }));
  };

  // Now Thumbnail not use but we will show image when image will added in getAllCurrency query
  const selectCountry = (item) => (
    <>
      <div className="flag">
        {/* <Thumbnail source={item.image} /> */}
        <TextStyle>{`${item.country} (${item.currencyCode})`}</TextStyle>
      </div>
    </>
  );
  const selectCountryTextField = (item) => (
    <>
      <TextField
        prefix={item.symbol}
        suffix={item.currencyCode}
        value={String(item.price)}
        placeholder={constant.ZERO_PRICE}
        onChange={(value) => onHandleChange(acceptOnlyValidInput(value, item.price), constant.PRICE, item)}
        error={inlineError[item.currencyCode] ? cms("message.error.greaterThan") : ""}
        disabled={loading}
      />
      {inlineError[item.currencyCode] ? <br /> : " "}
    </>
  );
  const selectPayout = (item) => (
    <>
      <div className="rate_checkbox">
        <Checkbox
          label={cms("label.payment")}
          checked={item.isPayment || isAllow.payment}
          onChange={(value) => onHandleChange(value, constant.IS_PAYMENT, item)}
        />
      </div>
    </>
  );

  const getRows = () => {
    if (data && data.currency && data.currency.rate && data.currency.rate.length) {
      return data.currency.rate.map((item) => {
        const currencyRow = selectCountry(item);
        const rateRow = selectCountryTextField(item);
        const payoutRow = selectPayout(item);
        return [[currencyRow], [rateRow], [payoutRow]];
      });
    }
    return [];
  };

  return (
    <CurrencyTable className="currency-table">
      <DataTable columnContentTypes={CURRENCY_COLUMN} headings={CURRENCY_TABLE_HEADING} rows={getRows()} />
    </CurrencyTable>
  );
};
export default withErrorBoundary(Table);
