import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { validateValue, handlePromiseError } = yupHelper;

const schema = (errorMessage) => yup.string().max(32, errorMessage);

const validate = async (value, errorMessage) => {
  let promise = "";
  promise = validateValue(schema(errorMessage), value);
  return handlePromiseError(promise);
};

export default validate;
