import React from "react";
import _ from "lodash";

import { Stack, Thumbnail, Heading, Badge, TextStyle } from "@shopify/polaris";
import { imageHelper, baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { variants } from "app/productLake/modules/operator/features/review/props";
import content from "app/productLake/modules/operator/features/review/reviewContent";

import ReviewVariant from "./reviewVariant";

const noImageUrl = "https://via.placeholder.com/80x80/ffffff/000000?text=No+image";

const Variant = (props) => {
  const { isAdded, isDeleted, isUpdated, sellerVariant, variantDiff, vendorVariant, position, cms } = props;
  const { variantLabels } = content;
  const getData = (value) => (_.isBoolean(value) && (value ? "Yes" : "No")) || value;
  const getVariantTitle = ({ option1Val, option2Val, option3Val }) => {
    return `${option1Val ? `${option1Val}` : ""}${option2Val ? ` / ${option2Val}` : ""}${
      option3Val ? ` / ${option3Val}` : ""
    }`;
  };

  const heading =
    (isAdded && (
      <Heading size="small">
        {cms("label.variant")}
        {position ? ` #${position}` : ""}
        <Badge status="success">{constant.displayStatus.NEW}</Badge>
      </Heading>
    )) ||
    (isDeleted && (
      <Heading size="small">
        {cms("label.variant")}
        {position ? ` #${position}` : ""}
        <Badge status="warning">{constant.DELETED}</Badge>
      </Heading>
    )) ||
    (isUpdated && (
      <Heading size="small">
        {cms("label.variant")}
        {position ? ` #${position}` : ""}
        <Badge status="info">{constant.UPDATED}</Badge>
      </Heading>
    )) ||
    null;

  const variantDifferences = (isUpdated && { ...variantDiff }) || {};

  let variantDiffs = Object.keys(variantDifferences).map((variantKey) => {
    const notAllowedKeys = ["_id", "isTaxable", "option1", "option2", "option3", "measurement", "customInfo"];
    if (notAllowedKeys.includes(variantKey) || !variantLabels[variantKey]) {
      return null;
    }

    let vendorLabel = variantLabels[variantKey];
    let sellerLabel = variantLabels[variantKey];

    const option1Val = variantKey === "option1Val";
    const option2Val = variantKey === "option2Val";
    const option3Val = variantKey === "option3Val";

    vendorLabel =
      (vendorVariant &&
        ((option1Val && vendorVariant.option1) ||
          (option2Val && vendorVariant.option2) ||
          (option3Val && vendorVariant.option3))) ||
      vendorLabel;
    sellerLabel =
      (sellerVariant &&
        ((option1Val && sellerVariant.option1) ||
          (option2Val && sellerVariant.option2) ||
          (option3Val && sellerVariant.option3))) ||
      sellerLabel;

    let vendorChange = "";
    let sellerChange = "";

    if (variantKey === constant.IMAGES && variantDifferences.images && variantDifferences.images.length) {
      const vImages = [];
      const sImages = [];
      variantDifferences.images.map((image, idx) => {
        const vendorImage = (vendorVariant.images[idx] && vendorVariant.images[idx].imageUrl) || noImageUrl;
        const sellerImage = (sellerVariant.images[idx] && sellerVariant.images[idx].imageUrl) || noImageUrl;
        if (vendorImage !== sellerImage) {
          vImages.push([
            <Thumbnail
              source={imageHelper.resize({ url: vendorImage, type: constant.imageTypes.THUMBNAIL })}
              alt={cms("label.vendorImage")}
            />,
          ]);
          sImages.push([
            <Thumbnail
              source={imageHelper.resize({ url: sellerImage, type: constant.imageTypes.THUMBNAIL })}
              alt={cms("label.sellerImage")}
            />,
          ]);
        }
        return null;
      });
      if (vImages.length && sImages.length) {
        vendorChange = vImages;
        sellerChange = sImages;
      }
    }

    if (![constant.IMAGES].includes(variantKey)) {
      vendorChange =
        (vendorVariant &&
          (vendorVariant[variantKey] || vendorVariant[variantKey] === false) &&
          getData(vendorVariant[variantKey])) ||
        false;
      sellerChange =
        (sellerVariant &&
          (sellerVariant[variantKey] || sellerVariant[variantKey] === false) &&
          getData(sellerVariant[variantKey])) ||
        false;
    }

    if (!(vendorChange || sellerChange) || vendorChange === sellerChange) {
      return null;
    }

    return (
      <ReviewVariant
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        vendorChange={vendorChange || "Not Provided"}
        sellerChange={sellerChange || "Not Provided"}
        vendorLabel={`${vendorLabel} Changed`}
        sellerLabel={`${sellerLabel} Changed`}
        changedKey={`variant.${sellerVariant.variantId}.updated.${variantKey}`}
        cms={cms}
      />
    );
  });

  variantDiffs = variantDiffs.filter((item) => item);

  if (isUpdated && !variantDiffs.length) {
    return null;
  }

  if (isAdded) {
    variantDiffs = [
      <ReviewVariant
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        vendorChange={`Title: ${getVariantTitle(vendorVariant)}`}
        sellerChange={<TextStyle variation="positive">{cms("label.vendorAddedVariant")}</TextStyle>}
        changedKey={`variant.${baseHelper.mongoIdAsString(vendorVariant._id)}.added`}
        vendorLabel="Variant Added"
        sellerLabel="Variant Added"
        cms={cms}
      />,
    ];
  }

  if (isDeleted) {
    variantDiffs = [
      <ReviewVariant
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        sellerChange={`Title: ${getVariantTitle(sellerVariant)}`}
        vendorChange={<TextStyle variation="negative">{cms("label.deletedVariant")}</TextStyle>}
        changedKey={`variant.${sellerVariant.variantId}.deleted`}
        vendorLabel="Variant Deleted"
        sellerLabel="Variant Deleted"
        cms={cms}
      />,
    ];
  }
  return (
    <Stack vertical>
      <Stack fill />
      <Stack.Item>{heading}</Stack.Item>
      {variantDiffs}
    </Stack>
  );
};

Variant.propTypes = variants.type;

export default Variant;
