import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import OperatorManageFulfillmentDetail from "app/setup/modules/operator/features/manageFulfillmentDetail/manageFulfillmentDetail";

const { userKey } = constant;
const { operator } = userKey;

const ManageFulfillmentDetail = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorManageFulfillmentDetail />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const FulfillmentComponent = getComponent(currentUserRole);
  return FulfillmentComponent;
};

export default withFeature(withErrorBoundary(ManageFulfillmentDetail), { feature: constant.MANAGE_FULFILLMENT_DETAIL });
