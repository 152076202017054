import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

//  import components
import { Banner, SkeletonAnnotated } from "lib/components";

// contexts
import { PrivateContext } from "lib/context";

// gql
import { useMutation, useQuery } from "react-apollo";
// import { UPLOAD_IMAGE } from "app/productLake/apollo/mutations";
import { ADD_LAKE_VARIANT, UPLOAD_IMAGE } from "app/productLake/apollo/mutations";
import { GET_PRODUCT_LAKE } from "app/productLake/apollo/queries";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// sub feature items
import { Image, Title, Pricing, Inventory, Shipping } from "./subFeatureItems";

const OperatorVariantAdd = () => {
  const { match, history, cms, currentUser } = useContext(PrivateContext);
  const { ecommercePlatform = "" } = currentUser;
  const { params } = match;
  const { productId = "" } = params;
  const {
    gql,
    value: { MAX_FLAT, MIN_PRICE },
  } = constant;

  const { loading: productLoading, data: productData, error } = useQuery(GET_PRODUCT_LAKE, {
    variables: { input: { id: match.params.productId } },
  });

  // states
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [title, setTitle] = useState({});
  const [image, setImage] = useState([]);
  const [pricing, setPricing] = useState({
    price: "",
    comparePrice: "",
  });
  const [inventory, setInventory] = useState({
    sku: "",
    inventoryPolicy: "",
    quantity: "",
  });
  const [shipping, setShipping] = useState({
    isPhysicalProduct: true,
    weight: "",
    weightUnit: "kg",
  });
  const [variantOption, setVariantOption] = useState([]);
  const [titleError, setTitleError] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [variant, setVariant] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  useEffect(() => {
    const productResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCT_LAKE) || {};
    const productLakeError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCT_LAKE);

    if (!productLoading && productResponse) {
      const { variants = [], images = [], inventoryId } = productResponse;
      const variantPosition = variants.length + 1;
      const productSKU = inventoryId && inventoryId.sku;
      setProductImages(images);
      if (variants && variants.length) {
        setVariant(variants[0]);
      }
      if (productSKU) {
        setInventory({ ...inventory, sku: `${productSKU}-${variantPosition}` });
      }
    }
    if (productLakeError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: productLakeError });
    }
  }, [productData, productLoading]);

  useEffect(() => {
    if (error && Object.keys(error).length) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.fetch"),
      }));
    }
  }, [cms, error]);

  if (variant && Object.keys(variantOption).length === 0) {
    const { optionValues = [] } = variant || {};

    const options = {};

    optionValues.forEach(({ optionId }, index) => {
      options[`option${index + 1}`] = optionId.displayName;
    });
    if (Object.keys(options).length) setVariantOption(options);
  }

  // gql states
  const [addVariant, { loading: addVariantLoading }] = useMutation(ADD_LAKE_VARIANT);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (productLoading) {
    return <SkeletonAnnotated />;
  }

  const saveVariant = async (variantValue) => {
    try {
      const result = await addVariant({
        variables: {
          input: variantValue,
        },
      });
      const responseData = baseHelper.getResponseData(result.data, gql.ADD_LAKE_VARIANT);
      const responseError = baseHelper.getResponseError(result.data, gql.ADD_LAKE_VARIANT);
      if (responseError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: responseError,
        });
        return;
      }
      if (responseData) {
        history.push(`/lake-variants/edit/${productId}/${responseData}`, { addVariant: true });
      }
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      }));
    }
  };

  const prepareData = () => {
    let price = {
      price: pricing.price ? parseFloat(pricing.price).toString() : "",
      costPrice: pricing.comparePrice ? parseFloat(pricing.comparePrice).toString() : "",
    };
    if (ecommercePlatform === constant.WOOCOMMERCE) {
      price = {
        regularPrice: pricing.price ? parseFloat(pricing.price).toString() : "",
        salePrice: pricing.comparePrice ? parseFloat(pricing.comparePrice).toString() : "",
      };
    }

    const formValues = {
      productId,
      imageUrl: "",
      price,
      dimensions: {
        weight: parseFloat(shipping.weight) || 0,
      },
      inventory: {
        sku: inventory.sku,
        quantity: inventory.quantity ? parseInt(inventory.quantity, 10) : 0,
        isManagingStock: inventory.inventoryPolicy === constant.SHOPIFY,
      },
      optionValues: [],
    };

    const requiredError = {};
    let hasError = false;

    Object.keys(title).forEach((option) => {
      formValues.optionValues.push({
        optionDisplayName: option,
        label: title[option],
      });
      requiredError.option = !(title[option] && title[option].trim() !== "");
      hasError = hasError || requiredError.option1;
    });
    setTitleError(requiredError);

    return !hasError && formValues;
  };

  const onSubmit = async () => {
    const variantValue = prepareData();

    if (!variantValue) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      variantValue.imageUrl = imageUrl;
    }
    const isLocalImageUrl = image && image.upload === constant.LOCAL;
    if (!isLocalImageUrl) {
      saveVariant(variantValue);
      return;
    }

    if (image && image.length) {
      try {
        const response = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const responseData = baseHelper.getResponseData(response.data, gql.UPLOAD_IMAGE);
        const responseError = baseHelper.getResponseError(response.data, gql.UPLOAD_IMAGE);
        if (responseError) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: responseError,
          }));
          return;
        }
        const { imageUrl = "", imageId = "" } = responseData || {};
        if (!imageUrl) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.url"),
          }));
          return;
        }
        variantValue.imageUrl = imageUrl;
        variantValue.imageId = imageId;
      } catch (exception) {
        setBanner((prev) => ({
          ...prev,
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        }));
        return;
      }
    }

    saveVariant(variantValue);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <br />
        <Title
          data={title}
          onChange={setTitle}
          variantOption={variantOption}
          error={titleError}
          cms={cms}
          setDisableSubmit={setDisableSubmit}
        />
        <Image productImages={productImages} image={image} setImage={setImage} cms={cms} />
        <Pricing acceptOnlyValidInput={acceptOnlyValidInput} data={pricing} onChange={setPricing} cms={cms} />
        <Inventory ecommercePlatform={ecommercePlatform} data={inventory} onChange={setInventory} cms={cms} />
        <Shipping ecommercePlatform={ecommercePlatform} data={shipping} onChange={setShipping} cms={cms} />
        <PageActions
          primaryAction={{
            id: "submit",
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: addVariantLoading || imageLoading,
            disabled: disableSubmit,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push(`/product-lake/edit/${productId}?tab=2`),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(OperatorVariantAdd);
