// import packages
import React, { useState, useEffect, useContext } from "react";
import { Card, Select, Stack, Tag, TextField } from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

function CurrencyShipping(props) {
  const currencies = baseHelper.getCurrencySymbols();
  const { cms } = useContext(PrivateContext);
  const { cardLabel, id, destination, onChange, vendorDestinationRowsData = [] } = props;
  const selectedCurrency = vendorDestinationRowsData.map((item) => item.currencyCode).filter((item) => item);
  const [vendorDestinationRows, setVendorDestinationRows] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState(selectedCurrency);

  useEffect(() => {
    const selectedCurrenciesData = vendorDestinationRows.map((item) => item.currencyCode).filter((item) => item);
    setVendorDestinationRows([...vendorDestinationRowsData] || []);
    setSelectedCurrencies(selectedCurrenciesData);
  }, [vendorDestinationRowsData]);

  const handleType = (type, currencyCode) => {
    const currencyIndex = vendorDestinationRows.findIndex((item) => item.currencyCode === currencyCode);
    if (currencyIndex !== -1) {
      vendorDestinationRows[currencyIndex].type = type;
    }
    setVendorDestinationRows(vendorDestinationRows);
    onChange([...vendorDestinationRows]);
  };

  const handleCost = (amount, currencyCode) => {
    const currencyIndex = vendorDestinationRows.findIndex((item) => item.currencyCode === currencyCode);
    if (currencyIndex !== -1) {
      vendorDestinationRows[currencyIndex].amount = parseFloat(amount);
    }
    setVendorDestinationRows(vendorDestinationRows);
    onChange([...vendorDestinationRows]);
  };

  const handleCurrency = (currencyCode) => {
    vendorDestinationRows.push({
      destination,
      currencyCode,
      amount: "",
      type: constant.FIXED,
      taxRate: 0,
    });
    setVendorDestinationRows(vendorDestinationRows);
    setSelectedCurrencies([...selectedCurrencies, currencyCode].sort());
  };

  const handleTaxRate = (taxRate, currencyCode) => {
    const currencyIndex = vendorDestinationRows.findIndex((item) => item.currencyCode === currencyCode);
    if (currencyIndex !== -1) {
      vendorDestinationRows[currencyIndex].taxRate = parseFloat(taxRate);
    }
    setVendorDestinationRows(vendorDestinationRows);
    onChange([...vendorDestinationRows]);
  };

  const unselectCurrency = (currency) => {
    const updatedShippings = vendorDestinationRows.filter((item) => item.currencyCode !== currency);
    const updatedCurrencies = selectedCurrencies.filter((item) => item !== currency);
    setSelectedCurrencies(updatedCurrencies.sort());
    setVendorDestinationRows([...updatedShippings]);
  };

  const renderCurrencyRow = () => {
    const { id: componentId } = props;
    const priceTypeOptions = [
      {
        label: cms("operator.section.globalShipping.label.fixed"),
        value: constant.FIXED,
      },
      {
        label: cms("operator.section.globalShipping.label.free"),
        value: constant.FREE,
      },
    ];
    const rows = vendorDestinationRows
      .filter((item) => !!item.currencyCode)
      .map(({ type, amount, currencyCode, taxRate }) => {
        return (
          <Card sectioned title={currencyCode}>
            <Stack>
              <Stack.Item>
                <Select
                  options={priceTypeOptions}
                  id={`shippingType${currencyCode}${componentId}`}
                  name="shippingType"
                  label={`${cms("operator.section.globalShipping.label.selectFixedOrFree")}*`}
                  placeholder={cms("operator.section.globalShipping.placeHolder.shippingRate")}
                  value={type || ""}
                  onChange={(updatedType) => handleType(updatedType, currencyCode)}
                />
              </Stack.Item>
              {type === constant.FIXED && (
                <Stack.Item>
                  <TextField
                    id={`shippingPrice${currencyCode}${componentId}`}
                    type="number"
                    label={`${cms("operator.section.globalShipping.label.amount")}*`}
                    min={0}
                    step={0.01}
                    value={amount && amount.toString()}
                    onChange={(updatedPrice) => handleCost(updatedPrice, currencyCode)}
                    prefix={baseHelper.getCurrencySymbol(currencyCode)}
                  />
                </Stack.Item>
              )}
              <Stack.Item>
                <TextField
                  id={`shippingTaxRate${componentId}`}
                  type="number"
                  label={cms("operator.section.globalShipping.label.taxRate")}
                  min={0}
                  max={100}
                  step={0.01}
                  value={taxRate && taxRate.toString()}
                  onChange={(updatedTaxRate) => handleTaxRate(updatedTaxRate, currencyCode)}
                  suffix="%"
                />
              </Stack.Item>
            </Stack>
          </Card>
        );
      });
    return rows;
  };

  const renderCurrency = () => {
    let tagFields = selectedCurrencies.map((item) => <Tag onRemove={() => unselectCurrency(item)}>{item}</Tag>);
    tagFields = tagFields.filter((item) => item);
    return <Stack>{tagFields}</Stack>;
  };

  const availableCurrencies = currencies.filter(({ value }) => selectedCurrencies.indexOf(value) === -1);
  return (
    <Card id={`card${id}`} sectioned title={`${cardLabel}-${destination}`}>
      <Select
        options={availableCurrencies}
        id={`currencyCode${id}`}
        name="currencyCode"
        label={`${cms("operator.section.globalShipping.label.selectCurrencies")}*`}
        placeholder={cms("operator.section.globalShipping.placeHolder.selectCurrency")}
        value=""
        onChange={(updatedDestination) => handleCurrency(updatedDestination)}
      />
      <br />
      {renderCurrency()}
      <br />
      {renderCurrencyRow()}
    </Card>
  );
}

export default CurrencyShipping;
