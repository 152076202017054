import { gql } from "apollo-boost";

const GET_VENDOR_LOCATION = gql`
  query {
    getVendorLocation {
      data {
        countryCode
        countryName
      }
      error
      status
    }
  }
`;

export default GET_VENDOR_LOCATION;
