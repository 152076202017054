import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Badge, Caption, Checkbox, Heading, Modal, Select, Stack, TextField } from "@shopify/polaris";

// import mutation
import { LINE_RETURN } from "app/orders/apollo/mutations";

// import from lib
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper, statusHelper } from "lib/helpers";

// import banner
import { Banner } from "lib/components";

// import style
import { FieldSetQty } from "app/orders/modules/provider/features/view/modal/modalStyle";

// import yup
import validate from "app/orders/modules/operator/features/lineReturn/yup";

// import props
import operatorLineReturnProps from "./props";

const OperatorLineReturn = (props) => {
  const { gql, value, displayStatus, returnReason, REASON, NOTE, PRODUCT, PRODUCT_UC_FIRST } = constant;
  const { cms } = useContext(PrivateContext);
  const { showModal, orderToInitiate, onClose, disable, setDisable, refetch } = props;
  const {
    return: returnStatus,
    actualQuantity = 0,
    quantity: totalQuantity = 0,
    returnedQuantity = 0,
    isReturn = false,
  } = orderToInitiate;
  const isReturnObject = returnStatus && Object.keys(returnStatus).length;
  // react state
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [vendorNote, setVendorNote] = useState("");
  const [isSellerManaged, setIsSellerManaged] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [returnQuantity, setReturnQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState({});
  const [acceptReturnLoading, setAcceptReturnLoading] = useState(false);
  const [requestReturnLoading, setRequestReturnLoading] = useState(false);
  const [returnInitiateLoading, setReturnInitiateLoading] = useState(false);
  const [buttonValue, setButtonValue] = useState(isReturnObject);
  const [isInitiate, setIsInitiate] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    tittle: "",
  });

  // gql state
  const [lineReturn] = useMutation(LINE_RETURN);

  useEffect(() => {
    setQuantity((actualQuantity || totalQuantity) - returnedQuantity);
    if (!returnStatus || (returnStatus && returnStatus.status === constant.ACCEPT && isReturn)) {
      setReturnQuantity((actualQuantity || totalQuantity) - returnedQuantity);
    }
    if (returnStatus && returnStatus.status !== constant.ACCEPT) {
      setReturnQuantity(returnStatus.quantity);
      setReason(returnStatus.reason);
      setIsSellerManaged(returnStatus.isSellerManaged);
      setVendorNote(returnStatus.vendorNote);
      if (isInitiate) {
        setButtonValue(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnStatus]);

  const setLoadingState = (isRequestAccept, loadingState) => {
    if (isRequestAccept) {
      setAcceptReturnLoading(loadingState);
      return;
    }
    if (isReturnObject && returnStatus && returnStatus.status !== constant.ACCEPT) {
      setRequestReturnLoading(loadingState);
      return;
    }
    setReturnInitiateLoading(loadingState);
  };

  const setSuccessBanner = (data) => {
    if (data.isAccept) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.returnAccepted") });
      return;
    }
    if (isReturnObject && returnStatus && returnStatus.status !== constant.ACCEPT) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.returnReinitiate") });
      return;
    }
    if (data.isInitiate) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.lineReturnSuccess") });
    }
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const handleQuantityChange = (value) => {
    setReturnQuantity(value);
  };

  const orderLineReturn = async (requestData, isRequestAccept) => {
    try {
      const res = await lineReturn({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, gql.LINE_RETURN);
      const errorData = baseHelper.getResponseError(res.data, gql.LINE_RETURN);
      if (!errorData) {
        refetch();
        setSuccessBanner(requestData);
        if (requestData.isInitiate || requestData.isAccept) {
          setTimeout(() => {
            onClose();
          }, 1500);
        }
      }
      if (!responseData) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorData });
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setLoadingState(isRequestAccept, false);
  };

  const reasons = [
    {
      label: cms("section.lineReturn.label.productNotRequired"),
      value: returnReason.NOT_NEEDED,
    },
    {
      label: cms("section.lineReturn.label.productNotAsAdvertised"),
      value: returnReason.NOT_AS_ADVERTISED,
    },
    {
      label: cms("section.lineReturn.label.productNotAsSold"),
      value: returnReason.NOT_AS_SOLD,
    },
    {
      label: cms("section.lineReturn.label.productFaulty"),
      value: returnReason.FAULTY,
    },
    {
      label: cms("section.lineReturn.label.others"),
      value: returnReason.OTHER,
    },
  ];

  const handleSelectChange = (selectedValue) => setReason(selectedValue);

  const handleNoteChange = (noteText) => setNote(noteText);

  const handleSellerManaged = () => setIsSellerManaged(!isSellerManaged);

  const handleValidate = async (field, event) => {
    const validationError = await validate(field, event.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const isDisabled = () => {
    const isAllFieldEmpty = !(reason && note && returnQuantity && parseInt(returnQuantity, 10));
    return isAllFieldEmpty;
  };

  const renderTitle = (item) => {
    if (!item) {
      return null;
    }
    const { orderNumber, shopifyLineItemId } = item;

    return (
      <>
        <Heading>{`#${orderNumber}`}</Heading>
        <Caption>
          {`${cms("section.lineReturn.label.lineItemId")} : `}
          <span>{`#${shopifyLineItemId}`}</span>
        </Caption>
      </>
    );
  };
  const onClickRequestReturn = (isRequestAccept = false) => {
    setLoadingState(isRequestAccept, true);
    setDisable(true);
    const { orderId, _id } = orderToInitiate;
    const reqData = {
      isSellerManaged,
      orderId,
      orderLineItemId: _id,
      reason,
      note,
      returnQuantity: parseInt(returnQuantity, 10),
      isInitiate: !isRequestAccept,
      isAccept: isRequestAccept,
    };

    orderLineReturn(reqData, isRequestAccept);
    return true;
  };

  const renderPrimaryAction = () => {
    const initiateButton = [
      {
        content: cms("section.lineReturn.button.requestReturn"),
        onAction: () => {
          setIsInitiate(true);
          onClickRequestReturn();
        },
        disabled: isDisabled() || disable,
        loading: returnInitiateLoading,
      },
    ];
    const requestButton = [
      {
        content: cms("section.lineReturn.button.accept"),
        onAction: () => {
          onClickRequestReturn(true);
        },
        disabled: isDisabled() || disable,
        loading: acceptReturnLoading,
      },
      {
        content: cms("section.lineReturn.button.rejectRequestAgain"),
        onAction: () => {
          onClickRequestReturn();
        },
        disabled: isDisabled() || disable,
        loading: requestReturnLoading,
      },
    ];
    if (buttonValue && returnStatus && returnStatus.status !== constant.ACCEPT) {
      return requestButton;
    }
    return initiateButton;
  };
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      title={renderTitle(orderToInitiate)}
      primaryAction={renderPrimaryAction()}
      secondaryActions={{
        content: cms("section.lineReturn.label.cancel"),
        onAction: () => onClose(),
        disabled: disable,
      }}
    >
      {banner && banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ ...banner, isOpen: false })}
        />
      )}
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            <h2>{`${PRODUCT_UC_FIRST} - ${(orderToInitiate && orderToInitiate.title) || PRODUCT}`}</h2>
            <h5>{`${cms("section.lineReturn.label.supplier")} - ${orderToInitiate.vendor}`}</h5>
            <Badge>{displayStatus.FULFILLED || orderToInitiate.fulfillmentStatus}</Badge>
            {returnQuantity && parseInt(returnQuantity, 10) > 0 && parseInt(quantity - returnQuantity, 10) > 0 && (
              <Badge status={baseHelper.getBadgeType(constant.RETURN)}>
                {statusHelper.getBadgeStatus(constant.PARTIAL_RETURN)}
              </Badge>
            )}
          </Stack.Item>
          <FieldSetQty>
            <Stack.Item>
              <TextField
                value={(returnQuantity && returnQuantity.toString()) || ""}
                min={constant.value.ZERO}
                max={quantity}
                onChange={(value) => [
                  value <= parseInt(quantity, 10) && handleQuantityChange(acceptOnlyValidInput(value, returnQuantity)),
                ]}
                suffix={`of ${quantity}`}
                disabled={(returnStatus && returnStatus.status !== constant.ACCEPT) || disable}
              />
            </Stack.Item>
          </FieldSetQty>
        </Stack>
        <br />
        <Select
          placeholder={cms("section.lineReturn.placeholder.reason")}
          options={reasons}
          onChange={handleSelectChange}
          value={reason}
          onBlur={(e) => handleValidate(REASON, e)}
          error={errorMessage && errorMessage.reason}
          disabled={disable}
        />
        <br />
        {vendorNote && (
          <TextField
            placeholder={cms("section.lineReturn.placeholder.noteAdd")}
            value={`${cms("section.lineReturn.label.note")}: ${vendorNote}`}
            disabled
            multiline
          />
        )}
        <TextField
          label={`${cms("section.lineReturn.label.note")}*`}
          placeholder={cms("section.lineReturn.placeholder.noteAdd")}
          value={note}
          onChange={(noteValue) => handleNoteChange(noteValue)}
          onBlur={(e) => handleValidate(NOTE, e)}
          error={errorMessage && errorMessage.note}
          maxLength={value.MAX_CHARACTER}
          multiline
          disabled={disable}
        />
        <br />
        <Checkbox
          label={cms("section.lineReturn.label.sellerManaged")}
          checked={isSellerManaged}
          onChange={handleSellerManaged}
          disabled={disable}
        />
      </Modal.Section>
    </Modal>
  );
};

OperatorLineReturn.propTypes = operatorLineReturnProps.type;

export default OperatorLineReturn;
