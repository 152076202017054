// import packages
import { Caption, Card, Layout, PageActions, RadioButton, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useContext, useEffect, useState } from "react";

// import context
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import cms and constants
import constant from "lib/constant/constant";
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";

// import gql
import { UPDATE_SETTING } from "app/productOld/apollo/mutations";
import fieldData from "app/productOld/modules/operator/features/approvalSetting/cms/fielfData";

// import autopublish
import AutoPublish from "app/productOld/modules/operator/features/approvalSetting/subFeature/autoPublish";

const { APPROVE_ALL, APPROVE_SELECTED, gql, REVIEW_ALL } = constant;
const OperatorApprovalSetting = () => {
  const [buttonValue, setButtonValue] = useState(true);
  const { data: productSettingsData, loading, refetch } = useQuery(GET_PRODUCT_SETTING);
  const { cms, history } = useContext(PrivateContext);
  const [isHideTag, setIsHideTag] = useState(false);
  const [isHideType, setIsHideType] = useState(false);
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isCatalogHidden, setIsCatalogHidden] = useState(true);
  const [lastUpdated, SetLastUpdated] = useState("");
  const [message, setMessage] = useState("");
  const [publish, setPublish] = useState("");
  const productSettingData = fieldData(cms);
  const [radioButtonValue, setRadioButtonValue] = useState(false);
  const [radioButton, setRadioButton] = useState(false);
  const [rate, setRate] = useState("");
  const [selected, setSelected] = useState("");
  const [updateProductSetting, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const handleChange = (checked, newValue) => {
    setRadioButtonValue(newValue);
    setButtonValue(false);
  };

  const onSubmit = () => {
    setButtonValue(true);
    updateProductSetting({
      variables: {
        input: {
          reviewAll: radioButtonValue === REVIEW_ALL,
          approveSelected: radioButtonValue === APPROVE_SELECTED,
          approveAll: radioButtonValue === APPROVE_ALL,
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
          setBannerStatus({
            status: constant.CRITICAL,
            title: errorResponse,
            isOpen: true,
          });
        }
        setMessage(cms("message.success.approval"));
      })
      .catch((exception) => {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (!responseData) {
      return;
    }
    const {
      productApproval = "",
      updatedAt = "",
      isPriceTaxInclusive = false,
      rate: amount,
      isHideTag: hideTag,
      isHideTagAndType: hideTagAndType,
      isHideType: hideType,
      isAutoPublish = false,
      isCatalogHidden: catalogHidden,
    } = responseData;
    setPublish(isAutoPublish);
    setRadioButtonValue(productApproval);
    setRadioButton(productApproval);
    SetLastUpdated(updatedAt);
    setIsHideTag(hideTag);
    setIsHideTagAndType(hideTagAndType);
    setIsHideType(hideType);
    setIsCatalogHidden(catalogHidden);
    if (!isPriceTaxInclusive) {
      setSelected("false");
      return;
    }
    setSelected("true");
    setRate(amount || 0);
  }, [productSettingsData]);

  if (loading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title={cms("section.approval.title")} description={cms("section.approval.description")}>
        <Card
          title={[
            cms("section.approval.title"),
            <TextStyle variation="subdued">
              {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
            </TextStyle>,
          ]}
        >
          <Card.Section>
            <Stack vertical>
              <TextContainer>{cms("section.approval.description")}</TextContainer>
              {productSettingData.map((item) => {
                const { label: labelItem, helpText, key, name } = item;
                return (
                  <RadioButton
                    id={key}
                    key={key}
                    label={labelItem}
                    helpText={helpText}
                    name={name}
                    checked={radioButtonValue === key}
                    onChange={handleChange}
                  />
                );
              })}
            </Stack>
            <div className="manageVendorAccessButton">
              <PageActions
                primaryAction={{
                  id: "submitProductSetting",
                  content: cms("common.button.update"),
                  disabled: productSettingLoading || buttonValue,
                  loading: productSettingLoading,
                  onAction: () => onSubmit(),
                }}
                secondaryActions={[
                  {
                    id: "cancelProductSetting",
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            </div>
          </Card.Section>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
      <br />
      <AutoPublish
        lastUpdated={lastUpdated}
        selected={selected}
        rate={rate}
        radioButtonValue={radioButton}
        isHideTagAndType={isHideTagAndType}
        setIsHideTagAndType={setIsHideTagAndType}
        setBannerStatus={setBannerStatus}
        refetch={refetch}
        isAutoPublish={publish}
        setIsAutoPublish={setPublish}
      />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorApprovalSetting), {
  feature: constant.PRODUCT_APPROVAL_SETTING,
});
