import React, { useEffect, useState, useContext } from "react";

import constant from "lib/constant/constant";
import { baseHelper, storageHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { useQuery } from "@apollo/react-hooks";
import { GET_VENDOR_BRAND_LIST, GET_VENDOR_HOLIDAY } from "app/setup/apollo/index";

import { PrivateContext } from "lib/context";

import { Spinner } from "lib/components";
import OperatorHolidaySetting from "./operator/feature/setting/holiday/holiday";
import VendorHolidaySetting from "./provider/features/setting/holiday/holiday";

const HolidaySetting = () => {
  const { userKey } = constant;
  const { operator, provider } = userKey;
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const { data: getVendorBrandList } = useQuery(GET_VENDOR_BRAND_LIST);
  const { data: getVendorHoliday, loading, refetch } = useQuery(GET_VENDOR_HOLIDAY);
  const vendorHolidayResponseData = baseHelper.getResponseData(getVendorHoliday, constant.gql.GET_VENDOR_HOLIDAY);
  const vendorListData = baseHelper.getResponseData(getVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);
  const [intialFormatVendor, setVendorHolidayData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");

  const initialvendorData = [];
  useEffect(() => {
    if (vendorHolidayResponseData && vendorListData) {
      const value = [];
      const allDates = [];
      vendorHolidayResponseData.forEach((element) => {
        const brandName = vendorListData && vendorListData.find((data) => data._id === element.vendorId);

        if (
          element &&
          element.holiday &&
          element.holiday.startDate &&
          element &&
          element.holiday &&
          element.holiday.endDate
        ) {
          const holidayData = {
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            isActive: false,
            noDateSelected: false,
            startingDate: (element && element.holiday && element.holiday.startDate) || "",
            endingDate: (element && element.holiday && element.holiday.endDate) || "",
            start: (element && element.holiday && element.holiday.startDate) || "",
            end: (element && element.holiday && element.holiday.endDate) || "",
            id: element.vendorId,
            name: (brandName && brandName.brandName) || "",
            updatedAt: element && element.holiday && element.holiday.updatedAt,
          };
          value.push(holidayData);
        }

        const newDate = new Date(element && element.holiday && element.holiday.updatedAt);
        allDates.push(newDate);

        let maxDate = "";
        if (allDates && allDates.length) {
          maxDate = new Date(Math.max.apply(null, allDates));
        }
        setUpdatedAt(maxDate);
      });
      setVendorHolidayData(value);
    }
  }, [getVendorHoliday, getVendorBrandList, vendorHolidayResponseData, vendorListData]);

  useEffect(() => {
    if (vendorListData && vendorListData.length) {
      const activeVendors = [];
      vendorListData.forEach((vendor) => {
        if (!vendor.isInactive) {
          activeVendors.push(vendor);
        }
      });
      setVendorList(activeVendors);
    }
  }, [getVendorBrandList, vendorListData]);

  if (loading || isLoading) {
    return <Spinner />;
  }

  const getComponent = (role) => {
    if (role === operator && initialvendorData) {
      return (
        <OperatorHolidaySetting
          temp={intialFormatVendor || []}
          vendorListData={vendorList || []}
          updatedAt={updatedAt}
          refetch={refetch}
        />
      );
    }
    if (role === provider) {
      return <VendorHolidaySetting />;
    }
    return null;
  };

  const AdvanceProviderComponent = getComponent(currentUserRole);
  return AdvanceProviderComponent;
};

export default withFeature(withErrorBoundary(HolidaySetting), {
  feature: constant.VENDOR_SETTING,
});
