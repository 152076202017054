import React, { useContext } from "react";
import { Layout, Spinner } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Banner } from "lib/components";
import constant from "lib/constant/constant";
import Vendor from "app/setup/modules/operator/features/fulfillment/subFeatures/components/vendor";

const Advance = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    addresses,
    countries,
    vendors,
    destinations,
    loading,
    setAddressValueOption,
    setSelectedVendors,
    selectedVendors,
    setError,
    setAdvanceError,
    advanceError,
    setIsSubmit,
  } = props;

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {advanceError && (
        <Layout.Section>
          <Banner isOpen status="critical" title={cms("message.advanceError")} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("label.additionalConsignment")}
        description={cms("helpText.additionalConsignment")}
      >
        <Vendor
          setIsSubmit={setIsSubmit}
          addresses={addresses}
          countries={countries}
          destinations={destinations}
          vendors={vendors}
          loading={loading}
          setAddressValueOption={setAddressValueOption}
          setSelectedVendors={setSelectedVendors}
          selectedVendors={selectedVendors}
          setError={setError}
          setAdvanceError={setAdvanceError}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(Advance, {
  feature: constant.ADVANCE_CONSIGNMENT,
  // subFeature: constant.ADVANCE_CONSIGNMENT,
});
