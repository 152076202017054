import React from "react";

import PaymentExportSetting from "app/payments/modules/generic/exportSetting/payment";

import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const OperatorPaymentExportSetting = () => {
  return <PaymentExportSetting />;
};

export default withFeature(withErrorBoundary(OperatorPaymentExportSetting), {
  feature: constant.PAYMENT_EXPORT_SETTING,
});
