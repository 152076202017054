import React, { useState, useContext } from "react";
import {
  Card,
  Avatar,
  Heading,
  EmptyState,
  Stack,
  ResourceItem,
  Button,
  Badge,
  TextStyle,
  Tabs,
  Select,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor, LocationsMinor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import Popover from "lib/components/popover/popover";

// import helper
import { baseHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";
import { ResourceList } from "lib/components";

import { useMutation } from "@apollo/react-hooks";
import constant from "lib/constant/constant";

// import subFeatures
import { DELETE_ADDRESS, EDIT_DEFAULT } from "app/setup/apollo";
// import mutation
import Add from "app/setup/modules/generic/addressBook/subFeature/add/add";
import Edit from "app/setup/modules/generic/addressBook/subFeature/edit/edit";

const List = (props) => {
  const {
    active,
    addressDataResponse,
    banner,
    cms,
    currentPage,
    dataToFetch,
    error,
    filterOptions,
    loading,
    queryValue,
    refetch,
    selectedFilter,
    selectedLimit,
    setActive,
    setAddressForm,
    setBanner,
    setCurrentPage,
    setDataToFetch,
    setError,
    setQueryValue,
    setSelectedFilter,
    setSelectedLimit,
    setTaggedWith,
    taggedWith,
    total,
    isFirst,
  } = props;

  const { history } = useContext(PrivateContext);

  const [selectedItems, setSelectedItems] = useState([]);
  const [addressItem, setAddressItem] = useState([]);
  const [defaultButton, setDefaultButton] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [moreButton, setMoreButton] = useState(false);
  const [popoverActive, setPopoverActive] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [index, setIndex] = useState(null);
  const defaultAddress = {
    address: "",
    city: "",
    country: "",
    isDefault: false,
    label: "",
    postalCode: "",
  };
  const [addressData, setAddressData] = useState({ ...defaultAddress });
  const [editAddressForm, setEditAddressForm] = useState({
    label: "",
    address: "",
    city: "",
    isDefault: !!addressDataResponse.length,
    country: "",
    postalCode: "",
  });

  const tabs = [
    {
      id: "addressBook",
      content: constant.ALL_TAB,
      panelID: "manageAddressBook",
    },
  ];

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  // function for remove values of more filter.
  const handleTaggedWithRemove = (clearFilter = false) => {
    const removeParams = { list_search: "" };
    if (clearFilter) {
      removeParams.list_filter = "";
      setSelectedFilter("");
    }
    setTaggedWith("");
    baseHelper.setUrl(history, removeParams);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // end
  function disambiguateLabel(key, value) {
    switch (key) {
      case "addressBook":
        return `${value}`;
      default:
        return value;
    }
  }
  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.total === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.ADDRESS_BOOK,
      label: disambiguateLabel(constant.ADDRESS_BOOK, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true),
    });
  }
  const appliedFilters = appliedFilterArrObj;
  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const customFilter = (
    <FormLayout>
      <Select
        label={cms("section.label.filter")}
        value={selectedFilter || ""}
        placeholder={cms("label.moreFilter.select")}
        onChange={handleSelectChange}
        options={filterOptions}
      />
      {(selectedFilter && (
        <TextField label={cms("section.label.search")} value={taggedWith} onChange={handleTaggedWithChange} />
      )) ||
        null}
    </FormLayout>
  );

  const filter = [
    {
      key: constant.ADDRESS_BOOK,
      label: constant.FILTER_BY,
      filter: customFilter,
      shortcut: false,
    },
  ];

  const handleChange = () => {
    setActive(!active);
    setEditActive(false);
    setAddressForm({
      label: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
    });
  };

  const emptyStateMarkup = !total && (
    <EmptyState
      heading={cms("section.empty.title")}
      action={{
        content: cms("button.empty.primary"),
        onAction: () => handleChange(),
      }}
      image={constant.ADDRESS_BOOK_IMAGE}
    >
      <p>{cms("section.empty.description")}</p>
    </EmptyState>
  );

  const handleMoreButton = (id) => {
    try {
      setMoreButton({ [id]: !moreButton[id] });
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: err });
    }
  };

  const handleEditButton = (addressId) => {
    try {
      setEditActive(!editActive);
      const editData = addressDataResponse.find((item) => {
        const { _id: id } = item;
        return id === addressId;
      });
      setEditAddressForm(editData);
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: err });
    }
  };

  const [deleteAddressItem, { loading: deleteAddressLoading }] = useMutation(DELETE_ADDRESS);
  const handleDeleteButton = async (addressId) => {
    setIndex(addressId);
    const requestData = {
      addressId,
    };
    try {
      const response = await deleteAddressItem({
        variables: { input: requestData },
      });
      refetch();
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.delete"),
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.DELETE_ADDRESS);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: err });
    }
  };

  const [defaultAddressItem, { loading: defaultAddressLoading }] = useMutation(EDIT_DEFAULT);
  const handleDefaultButton = async (addressId) => {
    setIndex(addressId);
    const requestData = {
      addressId,
    };
    try {
      const response = await defaultAddressItem({
        variables: { input: requestData },
      });
      setDefaultButton({ [addressId]: !defaultButton[addressId] });
      setAddressData({ ...defaultAddress, isDefault: defaultButton });
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.default"),
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_DEFAULT_ADDRESS);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.UPDATE_DEFAULT_ADDRESS);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (responseData) {
        refetch();
      }
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: err });
    }
  };

  const renderItem = (addressList) => {
    const { _id: id, label, isDefault, address, city, country, postalCode, updatedAt } = addressList;
    const avatarChar = label.charAt(0);
    return (
      <>
        <ResourceItem
          id={id}
          label={label}
          address={address}
          city={city}
          country={country}
          postalCode={postalCode}
          persistActions
        >
          <Stack spacing="loose" wrap={false}>
            <Stack.Item>
              <Avatar initials={avatarChar} size="medium" />
            </Stack.Item>
            <Stack.Item fill Style="width: 200px">
              <span>
                <div className="wrap_text">
                  {label}
                  {isDefault && <Badge status={constant.SUCCESS}>{cms("section.label.default")}</Badge>}
                </div>
              </span>
              <div className="addressDate">
                <TextStyle variation="subdued">
                  {`${cms("section.label.update")}: ${baseHelper.formatDate(updatedAt)}`}
                </TextStyle>
              </div>
              <Stack wrap={false}>
                <Stack.Item>
                  <div className="wrap_text">{address}</div>
                </Stack.Item>
                <Stack.Item spacing="loose">
                  <Button
                    plain
                    onClick={() => {
                      handleMoreButton(id);
                    }}
                    disclosure={moreButton[id] ? constant.UP : constant.DOWN}
                  >
                    {cms("button.more.primary")}
                  </Button>
                </Stack.Item>
              </Stack>
              {moreButton[id] && (
                <>
                  {city}
                  <br />
                  {country}
                  <br />
                  {postalCode}
                  <br />
                </>
              )}
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={popoverActive[id]}
                setActive={() => setPopoverActive({ [id]: !popoverActive[id] })}
                loading={(defaultAddressLoading || deleteAddressLoading) && id === index}
                options={[
                  { content: cms("manage.edit"), icon: EditMinor, onAction: () => handleEditButton(id) },
                  {
                    content: cms("manage.default"),
                    icon: LocationsMinor,
                    onAction: () => handleDefaultButton(id),
                    disabled: defaultButton[id] || isDefault,
                  },
                  {
                    content: cms("manage.delete"),
                    icon: DeleteMinor,
                    onAction: () => handleDeleteButton(id),
                    destructive: true,
                  },
                ]}
              />
            </Stack.Item>
          </Stack>
        </ResourceItem>
      </>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true);
  };

  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };
  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const renderModal = () => {
    if (editActive) {
      return (
        <Edit
          addressDataResponse={addressDataResponse}
          addressItem={addressItem}
          banner={banner}
          cms={cms}
          editActive={editActive}
          editAddressForm={editAddressForm}
          refetch={refetch}
          setAddressItem={setAddressItem}
          setBanner={setBanner}
          setEditActive={setEditActive}
          setEditAddressForm={setEditAddressForm}
          total={total}
        />
      );
    }
    return (
      <Add
        active={active}
        addressData={addressData}
        addressItem={addressItem}
        addressDataResponse={addressDataResponse}
        banner={banner}
        cms={cms}
        defaultAddress={defaultAddress}
        error={error}
        refetch={refetch}
        setActive={setActive}
        setAddressData={setAddressData}
        setAddressItem={setAddressItem}
        setBanner={setBanner}
        setError={setError}
        total={total}
        isFirst={isFirst}
      />
    );
  };
  return (
    <>
      {renderModal()}
      <Card>
        {total > 0 ? <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} /> : null}
        <ResourceList
          appliedFilters={appliedFilters}
          idForItem={(address) => {
            const { _id: id } = address;
            return id;
          }}
          count={Number(total)}
          emptyState={emptyStateMarkup}
          isEmptyState
          filters={filter}
          handleClearAll={handleClearAll}
          items={addressDataResponse || {}}
          loading={loading}
          onQueryChange={handleQueryValueChange}
          onQueryClear={handleQueryValueRemove}
          onSelectionChange={handleSelect}
          page={currentPage}
          perPage={selectedLimit}
          queryValue={queryValue}
          renderItem={renderItem}
          // resourceName={{ singular: constant.ADDRESS, plural: constant.ADDRESS }}
          // selectable
          selectedItems={selectedItems}
          setPage={handlePerPage}
          setPerPage={handleSelectLimit}
        />
      </Card>
    </>
  );
};
export default List;
List.propTypes = {
  active: PropTypes.bool,
  addressDataResponse: PropTypes.objectOf(PropTypes.shape),
  banner: PropTypes.objectOf(PropTypes.shape),
  cms: PropTypes.func,
  currentPage: PropTypes.number,
  dataToFetch: PropTypes.objectOf(PropTypes.shape),
  error: PropTypes.shape(),
  loading: PropTypes.bool,
  queryValue: PropTypes.string,
  refetch: PropTypes.func,
  selectedFilter: PropTypes.objectOf(PropTypes.shape),
  selectedLimit: PropTypes.number,
  setActive: PropTypes.func,
  setAddressForm: PropTypes.func,
  setBanner: PropTypes.func,
  setCurrentPage: PropTypes.func,
  setDataToFetch: PropTypes.func,
  setError: PropTypes.func,
  setQueryValue: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  setSelectedLimit: PropTypes.func,
  setTaggedWith: PropTypes.func,
  taggedWith: PropTypes.objectOf(PropTypes.shape),
  total: PropTypes.number,
};

List.defaultProps = {
  active: null,
  addressDataResponse: {},
  banner: {},
  cms: () => {},
  currentPage: "",
  dataToFetch: {},
  error: {},
  loading: null,
  queryValue: "",
  refetch: () => {},
  selectedFilter: {},
  selectedLimit: "",
  setActive: () => {},
  setAddressForm: () => {},
  setBanner: () => {},
  setCurrentPage: () => {},
  setDataToFetch: () => {},
  setError: () => {},
  setQueryValue: () => {},
  setSelectedFilter: () => {},
  setSelectedLimit: () => {},
  setTaggedWith: () => {},
  taggedWith: {},
  total: "",
};
