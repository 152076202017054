import React from "react";
import { DataTable, TextStyle, Select } from "@shopify/polaris";

import constant from "lib/constant/constant";

import { withErrorBoundary } from "lib/hoc";

import { CurrencyTable } from "app/setup/modules/operator/features/currency/currencyStyle";

const { VENDOR_ACCESS_TABLE_HEADING, VENDOR_ACCESS_COLUMN } = constant;

const Table = (props) => {
  const { setUserInput, userInput, vendorList, cms, setDisable } = props;

  const selectVendor = (item) => {
    const vendorData = vendorList.find((vendor) => vendor._id === item);
    return (
      <div className="flag">
        <TextStyle>{(vendorData && vendorData.brandName) || ""}</TextStyle>
      </div>
    );
  };

  const handleChange = (value, item, key, index) => {
    const latest = userInput;
    const newItem = item;
    setDisable(false);

    if (key === constant.PRODUCT) {
      newItem.product.isFullAccess = value === constant.FULL;
      newItem.product.isHide = value === constant.HIDE;
      newItem.product.isReadOnly = value === constant.READ;
    }
    if (key === constant.ORDER) {
      newItem.order.isFullAccess = value === constant.FULL;
      newItem.order.isHide = value === constant.HIDE;
      newItem.order.isReadOnly = value === constant.READ;
    }
    if (key === constant.PAYMENT) {
      newItem.payment.isFullAccess = value === constant.FULL;
      newItem.payment.isHide = value === constant.HIDE;
      newItem.payment.isReadOnly = value === constant.READ;
    }
    latest[index] = newItem;
    setUserInput([...latest]);
  };

  const getOptions = () => {
    return [
      {
        label: cms("label.full"),
        value: constant.FULL,
      },
      {
        label: cms("label.read"),
        value: constant.READ,
      },
      {
        label: cms("label.hide"),
        value: constant.HIDE,
      },
    ];
  };

  const getFieldValue = (selected) => {
    if (selected.isReadOnly) {
      return constant.READ;
    }
    if (selected.isHide) {
      return constant.HIDE;
    }

    return constant.FULL;
  };

  const selectProductAccess = (item, index) => {
    const { product = {} } = item;
    const productOptions = getOptions(product);
    return (
      <Select
        options={productOptions}
        onChange={(value) => {
          handleChange(value, item, constant.PRODUCT, index);
        }}
        value={getFieldValue(product)}
        placeholder=""
      />
    );
  };
  const selectOrderAccess = (item, index) => {
    const { order = {} } = item;
    const orderOptions = getOptions(order);
    return (
      <Select
        options={orderOptions}
        onChange={(value) => {
          handleChange(value, item, constant.ORDER, index);
        }}
        value={getFieldValue(order)}
        placeholder=""
      />
    );
  };
  const selectPaymentAccess = (item, index) => {
    const { payment = {} } = item;
    const paymentOptions = getOptions(payment);
    return (
      <Select
        options={paymentOptions}
        onChange={(value) => {
          handleChange(value, item, constant.PAYMENT, index);
        }}
        value={getFieldValue(payment)}
        placeholder=""
      />
    );
  };

  const getRows = () => {
    if (userInput && userInput.length) {
      return userInput.map((item, index) => {
        const vendorRow = selectVendor(item && item.vendorId);
        const productRow = selectProductAccess(item, index);
        const orderRow = selectOrderAccess(item, index);
        const paymentRow = selectPaymentAccess(item, index);
        return [[vendorRow], [productRow], [orderRow], [paymentRow]];
      });
    }
    return [];
  };

  return (
    <CurrencyTable className="currency-table">
      <DataTable columnContentTypes={VENDOR_ACCESS_COLUMN} headings={VENDOR_ACCESS_TABLE_HEADING} rows={getRows()} />
    </CurrencyTable>
  );
};
export default withErrorBoundary(Table);
