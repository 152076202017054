import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo";

import PropTypes from "prop-types";

import {
  Stack,
  Card,
  Caption,
  FormLayout,
  TextField,
  Layout,
  PageActions,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

import { UPDATE_PROFILE, SET_HUB_CONTACT_PROPERTIES } from "app/onboarding/apollo/mutations";
import { UPLOAD_IMAGE } from "app/setup/apollo/mutations";

// libs
import { Banner, DropZone, Sheet } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import LargeModalExample from "app/onboarding/modules/operator/stepperOnboarding/features/components/modal";

// import yup validations
import validate from "app/userManagement/modules/generic/editProfile/yup/validate";

const UpdateProfile = (props) => {
  const { setSelectedTab } = props;
  const {
    FIRST_NAME,
    gql: { UPDATE_BUSINESS_INFO, UPLOAD_IMAGE: UPLOAD_IMAGE_TEXT, SET_HUB_CONTACT_PROPERTIES: SET_HUB_CONTACT },
    LAST_NAME,
    onboardingSteps,
  } = constant;

  const { currentUser, currentUserRefetch, cms = {} } = useContext(PrivateContext);
  const { email /* , plan */ } = currentUser || {};
  const { address = "" } = email || {};

  // states
  const [brandName, setBrandName] = useState((currentUser && currentUser.brandName) || "");
  const [brandDescription, setBrandDescription] = useState(
    (currentUser && currentUser.generalInfo && currentUser.generalInfo.brandDescription) || ""
  );
  const [logo, setLogo] = useState((currentUser && currentUser.logo && [currentUser.logo]) || []);
  const [alternateEmail, setAlternateEmail] = useState(
    (currentUser && currentUser.generalInfo && currentUser.generalInfo.alternateEmail) || ""
  );
  const [firstName, setFirstName] = useState((currentUser && currentUser.firstName) || "");
  const [lastName, setLastName] = useState((currentUser && currentUser.lastName) || "");
  const [sheetActive, setSheetActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const isLogoHidden = false;
  const [updateBusinessInfo, { loading: updateBusinessInfoLoading }] = useMutation(UPDATE_PROFILE);
  const [uploadImage, { loading: uploadImageLoading }] = useMutation(UPLOAD_IMAGE);
  const [setHubContactProperties] = useMutation(SET_HUB_CONTACT_PROPERTIES);

  // useEffect(() => {
  //   if (!baseHelper.isGrowthAndAbovePlan({ plan })) {
  //     setLogoHidden(true);
  //   }
  // }, [plan, setLogoHidden]);

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const onNext = () => {
    setSelectedTab(onboardingSteps.COMMISSION);
  };

  const addImage = (value) => {
    setLogo([...value]);
    setIsSubmitEnabled(false);
  };

  const removeImage = (index) => {
    if (index <= 0) {
      setLogo([]);
    }
  };

  const setHubContactPropertiesData = async () => {
    try {
      const userDetails = baseHelper.getChatUser(currentUser);
      const { id: sellerId, dateFreeTrialStarted } = userDetails;
      const response = await setHubContactProperties({
        variables: {
          input: {
            sellerId,
            dateFreeTrialStarted,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, SET_HUB_CONTACT);
      if (responseError) {
        console.log("Error while creating or updating hub contact is: ", responseError);
      }
    } catch (resError) {
      console.log("Error is:", resError);
    }
  };

  const onSubmit = async () => {
    const isFieldEmpty = !(brandName && firstName && lastName);
    const { brandName: brandNameError, firstName: firstNameError, lastName: lastNameError } = errorMessage;
    const isAnyError = brandNameError || firstNameError || lastNameError;

    if (isFieldEmpty || isAnyError) {
      return;
    }
    try {
      let logoUrl = logo[0] || "";

      if (!currentUser.logo && logo.length) {
        const uploadImageResponse = await uploadImage({
          variables: {
            input: {
              image: logo[0] || "",
            },
          },
        });

        const responseError = baseHelper.getResponseError(uploadImageResponse.data, UPLOAD_IMAGE_TEXT);
        if (responseError) {
          setBanner({
            title: responseError,
            status: constant.CRITICAL,
            isOpen: true,
          });
          return;
        }

        const responseData = baseHelper.getResponseData(uploadImageResponse.data, UPLOAD_IMAGE_TEXT);
        const { imageUrl = "" } = responseData;
        logoUrl = imageUrl;
      }

      const input = {
        alternateEmail,
        brandDescription,
        brandName,
        email: address,
        firstName,
        lastName,
        logo: logoUrl,
      };

      const updateProfileResponse = await updateBusinessInfo({
        variables: {
          input,
        },
      });

      const responseError = baseHelper.getResponseError(updateProfileResponse.data, UPDATE_BUSINESS_INFO);
      if (responseError) {
        setBanner({
          title: responseError,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }
      currentUserRefetch();
      setHubContactPropertiesData();
      onNext();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);

    if (validationError) {
      setIsSubmitEnabled(true);
    }

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };
  const onHandleChange = (field, value) => {
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: "",
    }));
    setAlternateEmail(value);
    if (!value) {
      setIsSubmitEnabled(false);
    }
  };

  return (
    <Layout id="commission-settings">
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("common.onboarding.businessInfo.title")}
        description={cms("common.onboarding.businessInfo.description")}
      >
        {" "}
        <Card
          title={cms("common.onboarding.businessInfo.brandSection.title")}
          // actions={[{ content: "Learn more",onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <TextContainer>{cms("common.onboarding.businessInfo.brandSection.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                label={`${cms("common.onboarding.businessInfo.brandSection.label.name")}*`}
                placeholder={cms("common.onboarding.businessInfo.brandSection.placeHolder.name")}
                value={brandName}
                onChange={(val) => [setBrandName(val), setIsSubmitEnabled(false)]}
                onBlur={() => handleValidation(constant.BRAND_NAME, brandName)}
                helpText={cms("common.onboarding.businessInfo.brandSection.helpText.name")}
                error={errorMessage && errorMessage.brandName}
              />
              <TextField
                multiline
                label={cms("common.onboarding.businessInfo.brandSection.label.description")}
                placeholder={cms("common.onboarding.businessInfo.brandSection.placeHolder.description")}
                value={brandDescription}
                onChange={(val) => [setBrandDescription(val), setIsSubmitEnabled(false)]}
                helpText={cms("common.onboarding.businessInfo.brandSection.helpText.description")}
              />
              {!isLogoHidden && (
                <DropZone
                  label={cms("common.onboarding.businessInfo.brandSection.label.logo")}
                  onAdd={addImage}
                  removeExistingImage={removeImage}
                  onRemove={removeImage}
                  fileList={logo}
                  size={10}
                />
              )}
              <Stack>
                <Stack.Item fill>
                  <TextStyle variation="subdued">
                    <Caption>{cms("common.onboarding.businessInfo.label.caption")}</Caption>
                  </TextStyle>
                </Stack.Item>
              </Stack>
            </FormLayout>
            <br />
            <Banner status="" isOpen>
              <p>
                <b>{`${cms("common.onboarding.businessInfo.brandSection.label.more")} `}</b>
                {cms("common.onboarding.businessInfo.brandSection.label.paragraph")}
                {/* <Link url="">Find out more here</Link> */}
              </p>
            </Banner>
          </Card.Section>
        </Card>
        <Card
          title={cms("common.onboarding.businessInfo.contactSection.title")}
          // actions={[{ content: "Learn more", onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <TextContainer>{cms("common.onboarding.businessInfo.contactSection.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                required
                type="email"
                label={cms("common.onboarding.businessInfo.contactSection.label.default")}
                placeholder={cms("common.onboarding.businessInfo.contactSection.placeHolder.default")}
                value={address}
                helpText={cms("common.onboarding.businessInfo.contactSection.helpText.default")}
                disabled
              />
              <TextField
                required
                type="email"
                label={cms("common.onboarding.businessInfo.contactSection.label.alternate")}
                placeholder={cms("common.onboarding.businessInfo.contactSection.placeHolder.alternate")}
                value={alternateEmail}
                onChange={(val) => onHandleChange(constant.ALTERNATE_EMAIL, val)}
                helpText={cms("common.onboarding.businessInfo.contactSection.helpText.alternate")}
                onBlur={() => alternateEmail && handleValidation(constant.ALTERNATE_EMAIL, alternateEmail)}
                error={errorMessage && errorMessage.alternateEmail}
              />
              <TextField
                label={`${cms("common.onboarding.businessInfo.contactSection.label.firstName")}*`}
                placeholder={cms("common.onboarding.businessInfo.contactSection.placeHolder.firstName")}
                value={firstName}
                onChange={(val) => [setFirstName(val), setIsSubmitEnabled(false)]}
                onBlur={() => handleValidation(FIRST_NAME, firstName)}
                helpText={cms("common.onboarding.businessInfo.contactSection.helpText.firstName")}
                error={errorMessage && errorMessage.firstName}
              />
              <TextField
                label={`${cms("common.onboarding.businessInfo.contactSection.label.lastName")}*`}
                placeholder={cms("common.onboarding.businessInfo.contactSection.placeHolder.lastName")}
                value={lastName}
                onChange={(val) => [setLastName(val), setIsSubmitEnabled(false)]}
                onBlur={() => handleValidation(LAST_NAME, lastName)}
                helpText={cms("common.onboarding.businessInfo.contactSection.helpText.lastName")}
                error={errorMessage && errorMessage.lastName}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            id: "MC_PORTAL_ONBOARDING_STEP_02",
            content: cms("common.button.nextStep"),
            onAction: () => {
              onSubmit();
            },
            disabled: isSubmitEnabled,
            loading: uploadImageLoading || updateBusinessInfoLoading,
          }}
          secondaryActions={[
            {
              content: cms("common.button.previous"),
              onAction: () => {
                setSelectedTab(onboardingSteps.ABOUT);
                currentUserRefetch();
              },
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <LargeModalExample />
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: cms("common.button.close"), onAction: () => setSheetActive(false) }}
        primaryAction={{ content: cms("common.button.submit"), onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

UpdateProfile.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default UpdateProfile;
