import { gql } from "apollo-boost";

const RESET_PASSWORD = gql`
  mutation forgotPassword($input: ForgetPassword) {
    forgetPassword(input: $input) {
      data
      status
      error
    }
  }
`;

const CHECK_RESET_TOKEN = gql`
  mutation checkResetToken($input: CheckResetToken!) {
    checkResetToken(input: $input) {
      data
      status
      error
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation resetPassword($input: ResetPassword!) {
    resetPassword(input: $input) {
      data
      status
      error
    }
  }
`;

export { RESET_PASSWORD, CHECK_RESET_TOKEN, UPDATE_PASSWORD };
