import { gql } from "apollo-boost";

const GET_CHAT_SETTING = gql`
  query {
    getSellerSetting {
      data {
        chat {
          isCustomer
          isVendor
          updatedAt
        }
      }
      error
      status
    }
  }
`;
export default GET_CHAT_SETTING;
