import React, { useCallback, useContext, useEffect, useState } from "react";
import { ActionList, Button, Card, Collapsible } from "@shopify/polaris";

// import helper
import { baseHelper, storageHelper } from "lib/helpers";

// config
import { PrivateBrandingContext, PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import appConfig from "configuration";
import quickSettingConfig from "app/reports/provider/widgets/quickSetting/quickSettingConfig";
import quickLink from "./quickLinkConfig";

const { brandPageV2URL } = appConfig; // brandPageURL

const ProviderQuickLink = (props) => {
  const { link: actionLink = [] } = props;
  const { cms, currentUser, history, match } = useContext(PrivateContext);

  const {
    branding: { isHideMc },
  } = useContext(PrivateBrandingContext);

  const { path } = match;

  const { config, title } = quickSettingConfig(cms);

  const [openActionLink, setActive] = useState(true);
  const [actionLinkExpanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => setActive((link) => !link), []);
  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get(constant.HIDE_DETAILS));

  useEffect(() => {
    if (typeof isHideMc === "undefined") {
      return;
    }
    if (isHideMc !== hideMcDetails) {
      setHideMcDetails(isHideMc);
    }
  }, [hideMcDetails, isHideMc]);

  const currentUserContext = {
    brandName: currentUser.brandName,
    // domain: brandPageURL,
    domain: brandPageV2URL,
    shop: currentUser.shop,
    token: storageHelper.get(constant.TOKEN),
  };

  const actionCard = (action) => {
    if (hideMcDetails && action.link && action.link === "/contact-us") {
      return false;
    }
    if (action.isHide) {
      return false;
    }
    const onAction = (action.action && action.action.bind(currentUserContext)) || (() => history.push(action.link));

    return (
      <ActionList
        sections={[
          {
            items: [
              {
                content: action.label,
                helpText: action.caption,
                icon: action.icon,
                onAction,
              },
            ],
          },
        ]}
      />
    );
  };

  const getQuickLinks = (links = []) => {
    let updatedActions = [...links];
    if (currentUser.ecommercePlatform) {
      updatedActions = updatedActions.filter((item) => item.id !== "exportOrder");
    }
    return updatedActions.map((link) => actionCard(link));
  };

  const getQuickSettings = (settings = []) => {
    return settings.map((setting) => actionCard(setting));
  };

  const quickActions = (actionLink.length && actionLink) || quickLink[path];

  return (
    <Card>
      <Card.Header title={constant.ACTION}>
        <Button
          plain
          disclosure={actionLinkExpanded ? constant.DOWN : constant.UP}
          onClick={() => {
            handleToggle();
            setExpanded(!actionLinkExpanded);
          }}
        >
          {actionLinkExpanded ? constant.SHOW : baseHelper.ucFirst(constant.HIDE)}
        </Button>
      </Card.Header>

      <br />

      <Collapsible
        open={openActionLink}
        id="collapsibleQuickLink"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Card>
          <Card.Section subdued>{getQuickLinks(quickActions)}</Card.Section>
          {config[path] && (
            <Card.Section subdued title={title[path] || cms("common.actions.title.setting")}>
              {getQuickSettings(config[path])}
            </Card.Section>
          )}
        </Card>
      </Collapsible>
    </Card>
  );
};

export default ProviderQuickLink;
