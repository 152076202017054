// TODO: Requires refactoring

import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Caption,
  Layout,
  Stack,
  TextContainer,
  Button,
  Tag,
  TextField,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";

import { variantsProp } from "app/productOld/modules/generic/edit/propTypes";
import VariantField from "./variantField";

const Variants = (props) => {
  const {
    data,
    cms,
    handleChange,
    // learnMore
  } = props || {};
  const option = {
    option1: "option1",
    option2: "option2",
    option3: "option3",
  };
  const message = {
    alreadyUsed: cms("section.variant.message.alreadyUsedOption"),
    emptyOptionValue: cms("section.variant.message.valuesNotEmpty"),
  };
  const [state, setState] = useState({
    option1: cms("section.variant.label.size"),
    option2: cms("section.variant.label.color"),
    option3: cms("section.variant.label.material"),
    option1Val: "",
    option2Val: "",
    option3Val: "",
    allowedOption: [option.option1],
    tags: [],
    variantValues: [],
    variants: [],
    validVariants: {},
    showOption: false,
    errorOption1: false,
    errMsg1: false,
    errorOption2: false,
    errMsg2: false,
    errorOption3: false,
    errMsg3: false,
  });

  const onChange = useCallback(
    (variants, variantValues, validVariants, option1, option2, option3) => {
      let vars = variants.map((variant, idx) => {
        const newVard = {};
        if (variant.option1) {
          newVard.option1Val = variant.option1;
          newVard.option1 = option1;
        }
        if (variant.option2) {
          newVard.option2Val = variant.option2;
          newVard.option2 = option2;
        }
        if (variant.option3) {
          newVard.option3Val = variant.option3;
          newVard.option3 = option3;
        }

        newVard.price = (variantValues[idx] && parseFloat(variantValues[idx].price)) || 0;
        newVard.sku = (variantValues[idx] && variantValues[idx].sku) || "";
        newVard.barcode = (variantValues[idx] && variantValues[idx].barcode) || "";
        newVard.inventoryQuantity = (variantValues[idx] && parseFloat(variantValues[idx].inventoryQuantity)) || 0;

        if (validVariants[idx]) {
          return newVard;
        }

        return "";
      });
      vars = vars.filter((item) => item);
      handleChange("variants", vars);
    },
    [handleChange]
  );

  const optionChange = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const optionValChange = (key, value) => {
    setState({ ...state, [`option${key}Val`]: value, [`errorOption${key}`]: false });
  };

  const variantsChange = useCallback(
    (tagsParam = [], variantValuesParam = [], validVariantsParam = {}) => {
      const tags = (tagsParam.length && tagsParam) || state.tags || [];
      const variantValues = (variantValuesParam.length && variantValuesParam) || state.variantValues || [];
      const validVariants = (Object.keys(validVariantsParam).length && validVariantsParam) || state.validVariants || {};
      const tagOption1 = [];
      const tagOption2 = [];
      const tagOption3 = [];
      const variants = [];

      tags.map((tag) => {
        if (tag.key === option.option1) {
          tagOption1.push(tag.value);
        }
        if (tag.key === option.option2) {
          tagOption2.push(tag.value);
        }
        if (tag.key === option.option3) {
          tagOption3.push(tag.value);
        }

        return true;
      });

      if (tagOption1.length && tagOption2.length && tagOption3.length) {
        tagOption1.forEach((option1Val) => {
          tagOption2.forEach((option2Val) => {
            tagOption3.forEach((option3Val) => {
              variants.push({
                option1: option1Val,
                option2: option2Val,
                option3: option3Val,
              });
            });
          });
        });
      } else if (tagOption1.length && tagOption2.length) {
        tagOption1.forEach((option1Val) => {
          tagOption2.forEach((option2Val) => {
            variants.push({
              option1: option1Val,
              option2: option2Val,
            });
          });
        });
      } else if (tagOption2.length && tagOption3.length) {
        tagOption2.forEach((option2Val) => {
          tagOption3.forEach((option3Val) => {
            variants.push({
              option2: option2Val,
              option3: option3Val,
            });
          });
        });
      } else if (tagOption1.length && tagOption3.length) {
        tagOption1.forEach((option1Val) => {
          tagOption3.forEach((option3Val) => {
            variants.push({
              option1: option1Val,
              option3: option3Val,
            });
          });
        });
      } else if (tagOption1.length) {
        tagOption1.forEach((option1Val) => {
          variants.push({
            option1: option1Val,
          });
        });
      } else if (tagOption2.length) {
        tagOption2.forEach((option2Val) => {
          variants.push({
            option2: option2Val,
          });
        });
      } else if (tagOption3.length) {
        tagOption3.forEach((option3Val) => {
          variants.push({
            option3: option3Val,
          });
        });
      }
      variants.map((variant, idx) => {
        let index = idx;
        index += 1;
        if (!variantValues[idx]) {
          variantValues.splice(idx, 0, {
            price: data.price || "",
            barcode: "",
            sku: (data.sku && `${data.sku}-${index}`) || "",
            inventoryQuantity: data.quantity || "",
          });
        }
        if (validVariants[idx] === undefined) {
          validVariants[idx] = true;
        }
        return true;
      });

      const { variants: prevVariants, variantValues: prevVariantValues, validVariants: prevValidVariants } = state;

      if (
        JSON.stringify(prevVariants) !== JSON.stringify(variants) ||
        JSON.stringify(prevVariantValues) !== JSON.stringify(variantValues) ||
        JSON.stringify(prevValidVariants) !== JSON.stringify(validVariants)
      ) {
        const { option1, option2, option3 } = state;
        setState({ ...state, variants, variantValues, validVariants });
        onChange(variants, variantValues, validVariants, option1, option2, option3);
      }
    },
    [data.quantity, data.price, data.sku, onChange, option.option1, option.option2, option.option3, state]
  );

  const emptyOptionValue = (optionKey) => {
    setState({
      ...state,
      [optionKey]: "",
    });
  };

  useEffect(() => {
    variantsChange();
  }, [state.option1Val, state.option2Val, state.option3Val, variantsChange]);

  const handleValidVariants = (index) => {
    const { variants = [], variantValues = [], validVariants = {}, option1, option2, option3 } = state;
    setState((prevState) => {
      return { ...prevState, validVariants: { ...prevState.validVariants, [index]: !prevState.validVariants[index] } };
    });
    onChange(variants, variantValues, { ...validVariants, [index]: !validVariants[index] }, option1, option2, option3);
  };
  const handleKeyPress = (event, filledOption) => {
    const enterKeyPressed = event.keyCode === 13 || event.keyCode === 188; // press enter or ","
    if (enterKeyPressed) {
      event.preventDefault();
      const { tags, option1Val, option2Val, option3Val } = state;
      if (filledOption === option.option1) {
        if (!option1Val) {
          setState({ ...state, option1Val: "", errorOption1: true, errMsg1: message.emptyOptionValue });
          return;
        }
        const tag = { key: option.option1, value: option1Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({ ...state, option1Val: "", errorOption1: true, errMsg1: message.alreadyUsed });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option1Val");
      } else if (filledOption === option.option2) {
        if (!option2Val) {
          setState({ ...state, option2Val: "", errorOption2: true, errMsg2: message.emptyOptionValue });
          return;
        }
        const tag = { key: option.option2, value: option2Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({ ...state, option2Val: "", errorOption2: true, errMsg2: message.alreadyUsed });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option2Val");
      } else if (filledOption === option.option3) {
        if (!option3Val) {
          setState({ ...state, option3Val: "", errorOption3: true, errMsg3: message.emptyOptionValue });
          return;
        }
        const tag = { key: option.option3, value: option3Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({ ...state, option3Val: "", errorOption3: true, errMsg3: message.alreadyUsed });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option3Val");
      }
    }
  };

  const remove = (item) => {
    const { allowedOption, tags, option1, option2, option3 } = state;
    const index = allowedOption.indexOf(item);

    if (index > -1) {
      allowedOption.splice(-1, 1);
    }

    const nTags = [];
    let newOption1 = option1;
    let newOption2 = option2;
    let newOption3 = option3;
    const tagValues = tags.map(({ value }) => value);
    const tagKeys = tags.map(({ key }) => key);
    tagValues.splice(index, 1);
    // eslint-disable-next-line no-underscore-dangle
    tagValues.map((_data, _index) => nTags.push({ key: tagKeys[_index], value: _data }));
    if (index === 0) {
      newOption1 = option2;
      newOption2 = option3;
      newOption3 = option1;
    }
    if (index === 1) {
      newOption2 = option3;
      newOption3 = option2;
    }
    if (index === 2) {
      newOption2 = option2;
      newOption3 = option3;
    }

    const { variantValues, validVariants = {} } = state;

    variantsChange(nTags, variantValues, validVariants);
    setState({ ...state, allowedOption, tags: nTags, option1: newOption1, option2: newOption2, option3: newOption3 });
  };

  const addOptions = () => {
    const { allowedOption } = state;
    if (!allowedOption.includes(option.option1)) {
      allowedOption.splice(0, 0, option.option1);
    } else if (!allowedOption.includes(option.option2)) {
      allowedOption.splice(1, 0, option.option2);
    } else if (!allowedOption.includes(option.option3)) {
      allowedOption.splice(2, 0, option.option3);
    }

    setState({ ...state, allowedOption });
  };

  const removeTag = (tag) => {
    const { tags } = state;
    const index = tags.indexOf(tag);
    if (index !== -1) {
      tags.splice(index, 1);
    }

    const { variantValues = [], validVariants = {} } = state;

    variantsChange(tags, variantValues, validVariants);

    setState({ ...state, tags });
  };

  const variantValueChange = (type, value, index) => {
    const { variantValues } = state;
    variantValues[index][type] = value;
    const { variants, validVariants, option1, option2, option3 } = state;
    onChange(variants, variantValues, validVariants, option1, option2, option3);
    setState({ ...state, variantValues });
  };
  const renderVariants = () => {
    const { variantValues, variants, validVariants, option1, option2, option3 } = state;

    return (
      <VariantField
        data={data}
        variantValues={variantValues}
        variants={variants}
        validVariants={validVariants}
        option1={option1}
        option2={option2}
        option3={option3}
        handleValidVariants={handleValidVariants}
        variantValueChange={variantValueChange}
      />
    );
  };
  const renderTags = (selectedTag) => {
    const { tags } = state;
    let tagFields = tags.map((tag) => {
      if (tag.key === selectedTag) {
        return (
          <Tag key={tag.key} onRemove={() => removeTag(tag)}>
            {tag.value}
          </Tag>
        );
      }
      return "";
    });
    tagFields = tagFields.filter((item) => item);
    return <Stack wrap>{tagFields}</Stack>;
  };
  const showOption1 = state.allowedOption.includes(option.option1);
  const showOption2 = state.allowedOption.includes(option.option2);
  const showOption3 = state.allowedOption.includes(option.option3);

  const showRemoveButton = state.allowedOption.length > 1;
  const showAddButton = state.allowedOption.length !== 3;

  return (
    <Layout.Section>
      <Card
        title={cms("section.variant.title")}
        actions={[
          {
            content: state.showOption ? cms("common.button.cancel") : cms("section.variant.headerAction"),
            onAction: () => setState({ ...state, showOption: !state.showOption }),
          },
          // {
          //   content: cms("common.label.learnMore"),
          //   onAction: () => {
          //     learnMore(cms("section.variant.title"), cms("label.todo"));
          //   },
          // },
        ]}
      >
        <Card.Section>
          <TextContainer>
            <span id="addVariants">{cms("section.variant.content")}</span>
          </TextContainer>
          <br />
          <Caption>
            <TextStyle variation="strong">
              <span id="pleaseNote">{`${cms("section.variant.note.label")}: `}</span>
            </TextStyle>
            {cms("section.variant.note.content")}
          </Caption>
        </Card.Section>
        {state.showOption && (
          <Card.Section subdued>
            <Stack vertical spacing="extraTight">
              {showOption1 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      label="Option Name"
                      labelHidden
                      value={state.option1}
                      onChange={(val) => optionChange("option1", val)}
                      id="option1Change"
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("section.variant.placeholder.separateOption")} preferredPosition="above">
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div onKeyDown={(e) => handleKeyPress(e, option.option1)} id="">
                        <TextField
                          label="Option value"
                          id="option1Values"
                          placeholder={cms("section.variant.placeholder.separateOption")}
                          labelHidden
                          value={state.option1Val}
                          onChange={(val) => optionValChange("1", val)}
                          error={state.errorOption1 && state.errMsg1}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option1)} id="cancelOption1Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption1 && renderTags(option.option1)}
              {showOption2 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      label="Option Name"
                      labelHidden
                      value={state.option2}
                      onChange={(val) => optionChange("option2", val)}
                      id="option2Change"
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("section.variant.placeholder.separateOption")} preferredPosition="above">
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div onKeyDown={(e) => handleKeyPress(e, option.option2)} id="option2">
                        <TextField
                          label="Option value"
                          id="option2Values"
                          placeholder={cms("section.variant.placeholder.separateOption")}
                          labelHidden
                          value={state.option2Val}
                          onChange={(val) => optionValChange("2", val)}
                          error={state.errorOption2 && state.errMsg2}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option2)} id="cancelOption2Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption2 && renderTags(option.option2)}
              {showOption3 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      label="Option Name"
                      id="option3Change"
                      labelHidden
                      value={state.option3}
                      onChange={(val) => optionChange("option3", val)}
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("section.variant.placeholder.separateOption")} preferredPosition="above">
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div onKeyDown={(e) => handleKeyPress(e, option.option3)} id="option3">
                        <TextField
                          label="Option value"
                          id="option3Values"
                          placeholder={cms("section.variant.placeholder.separateOption")}
                          labelHidden
                          value={state.option3Val}
                          onChange={(val) => optionValChange("3", val)}
                          error={state.errorOption3 && state.errMsg3}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option3)} id="cancelOption3Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption3 && renderTags(option.option3)}
              {showAddButton && (
                <Button slim onClick={addOptions} id="addOptionsButton">
                  {cms("section.variant.button.addOption")}
                </Button>
              )}
            </Stack>
          </Card.Section>
        )}
        {state.showOption && renderVariants()}
      </Card>
    </Layout.Section>
  );
};

Variants.propTypes = variantsProp.type;
export default Variants;
