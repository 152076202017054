import { gql } from "apollo-boost";

const UPDATE_EXPRESS_PAYMENT_SETTING = gql`
  mutation updateExpressPaymentSetting($input: ExpressPaymentSetting) {
    updateExpressPaymentSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_EXPRESS_PAYMENT_SETTING;
