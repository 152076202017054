import React, { useContext } from "react";
import { Button, Select, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary } from "lib/hoc";

const AdvanceItem = (props) => {
  const { advancedValue = [], allSuppliers, count, removeItem, updateVal } = props;
  const { ACCEPTED, DATE_OF_PAYMENT, FULFILLED, STATUS, OPTION, VALUE } = constant;
  const { cms = {} } = useContext(PrivateContext);

  const optionValues = allSuppliers.map((obj) => ({ ...obj, disabled: false }));
  const statusOptions = [
    {
      label: cms("label.accepted"),
      value: ACCEPTED,
    },
    {
      label: cms("label.fulfilled"),
      value: FULFILLED,
    },
  ];

  const vendorKeyArray = Array.from(Array(count).keys());

  const vendorList = vendorKeyArray.map((item) => {
    const advanceItem = advancedValue && advancedValue[item];
    if (!advanceItem) {
      return null;
    }
    const objIndex = optionValues && optionValues.findIndex((obj) => obj.value === advanceItem.vendorId);

    if (objIndex !== -1) {
      optionValues[objIndex].disabled = true;
    }

    const advanceItemValue = advanceItem[STATUS];
    const advanceItemVendor = advanceItem.vendorId;

    const getVendorOptions = [...optionValues];

    const optionSelect = `${OPTION}${item}`;
    const valueSelect = `${VALUE}${item}`;

    return (
      <div className="schedulePayment" key={item}>
        <Stack wrap={false} key={item} alignment="trailing">
          <Stack.Item fill>
            <Select
              label={!item ? cms("label.vendor") : ""}
              options={getVendorOptions}
              placeholder={cms("common.label.vendor")}
              id={optionSelect}
              onChange={(val) => {
                updateVal("vendorId", val, item);
              }}
              value={(advanceItem && advanceItemVendor) || ""}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Select
              label={!item ? cms("label.selectStatus") : ""}
              options={statusOptions}
              id={valueSelect}
              placeholder={cms("common.label.status")}
              onChange={(val) => {
                updateVal(STATUS, val, item);
              }}
              value={advanceItemValue || ""}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Select
              label={!item ? cms("label.selectDate") : ""}
              options={baseHelper.getDates() || []}
              placeholder={cms("placeholder.date")}
              value={(advanceItem && advanceItem[DATE_OF_PAYMENT] && advanceItem[DATE_OF_PAYMENT].toString()) || ""}
              onChange={(val) => {
                updateVal(DATE_OF_PAYMENT, Number(val), item);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Button id="deleteButton" plain onClick={() => removeItem(item)}>
              <i className="far fa-trash fa-lg redColor" />
            </Button>
          </Stack.Item>
        </Stack>
      </div>
    );
  });
  return vendorList;
};

export default withErrorBoundary(AdvanceItem);
