import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import ConsignmentShip from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const HybridConsignmentShip = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <div>
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.consignmentShip.title")}</Heading>
          </Stack.Item>
          {/* <Stack.Item>
            <Link onClick={() => learnMore(cms("hybrid.consignmentShip.title"), cms("hybrid.consignmentShip.title"))}>
              {cms("common.learnMore")}
            </Link>
          </Stack.Item> */}
          <br />
          <TextStyle variation="subdued">{cms("hybrid.consignmentShip.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <ConsignmentShip method="defaultConsignmentShip" value="consignmentShip" />
    </div>
  );
};
export default HybridConsignmentShip;
