import * as yup from "yup";

const addressSchema = (cms) =>
  yup.object().shape({ address: yup.string().required(cms("message.address")).nullable() });

const accessLicenseNumberSchema = (cms) =>
  yup.object().shape({ accessLicenseNumber: yup.string().required(cms("message.license")).nullable() });

const citySchema = (cms) => yup.object().shape({ city: yup.string().required(cms("message.city")).nullable() });

const countrySchema = (cms) =>
  yup.object().shape({ countryCode: yup.string().required(cms("message.country")).nullable() });

const pincodeSchema = (cms) =>
  yup.object().shape({ pincode: yup.string().required(cms("message.pincode")).nullable() });

const vendorSchema = (cms) => yup.object().shape({ vendorIds: yup.array().required(cms("message.vendor")).nullable() });

const userSchema = (cms) => yup.object().shape({ user: yup.string().required(cms("message.userName")).nullable() });

const contactSchema = (cms) =>
  yup.object().shape({ contact: yup.string().required(cms("message.contractId")).nullable() });

const passwordSchema = (cms) =>
  yup.object().shape({ password: yup.string().required(cms("message.password")).nullable() });

const customerSchema = (cms) =>
  yup.object().shape({ customer: yup.string().required(cms("message.customerNumber")).nullable() });

const clientIdSchema = (cms) =>
  yup.object().shape({ clientId: yup.string().required(cms("message.clientId")).nullable() });

const clientSecretSchema = (cms) =>
  yup.object().shape({ clientSecret: yup.string().required(cms("message.clientSecret")).nullable() });

const accountNumberSchema = (cms) =>
  yup.object().shape({ accountNumber: yup.string().required(cms("message.accountNumber")).nullable() });

const siteCodeSchema = (cms) =>
  yup.object().shape({ siteCode: yup.string().required(cms("message.siteCode")).nullable() });

const apiSchema = (cms) => yup.object().shape({ apiKey: yup.string().required(cms("message.apiKey")).nullable() });

const accessSchema = (cms) => yup.object().shape({ accessKey: yup.string().required(cms("message.error.accessKey")) });

const shippingRateSchema = (cms) =>
  yup.object().shape({ shippingRateName: yup.string().required(cms("message.error.shippingRateName")) });

export {
  accountNumberSchema,
  accessLicenseNumberSchema,
  accessSchema,
  addressSchema,
  apiSchema,
  citySchema,
  clientIdSchema,
  clientSecretSchema,
  contactSchema,
  countrySchema,
  customerSchema,
  passwordSchema,
  pincodeSchema,
  siteCodeSchema,
  shippingRateSchema,
  userSchema,
  vendorSchema,
};
