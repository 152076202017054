import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Button,
  Card,
  Collapsible,
  Layout,
  Modal,
  ResourceList,
  SkeletonBodyText,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { PrivateContext } from "lib/context";

// import ResourceList
import { Banner } from "lib/components";

// helper
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// query and mutation
import { GET_ALL_USER_FOR_FEATURE, GET_FEATURE_BY_PARENT_ID, GET_MODULE } from "app/setup/apollo/queries";
import { UPDATE_FEATURE } from "app/setup/apollo/mutations";

// cms
import { withErrorBoundary } from "lib/hoc";
import cmsFeaturePanel from "app/setup/modules/admin/features/cms/feature";

// component
import FeatureModel from "./featureModel";

const FeaturePanel = () => {
  const { cms } = useContext(PrivateContext);
  const CMS = cmsFeaturePanel(cms);
  // useQuery, UseMutation Calls to fetch data.
  const { data: moduleData, error: moduleError, loading: moduleLoading } = useQuery(GET_MODULE);
  const { data: allUserFeatureData, error: allUserFeatureError, loading: allUserFeatureLoading } = useQuery(
    GET_ALL_USER_FOR_FEATURE
  );
  const [
    getFeatureByParentId,
    { data: featureByParentIdData, error: featureByIdError, loading: featureByParentIdLoading },
  ] = useLazyQuery(GET_FEATURE_BY_PARENT_ID);
  const [editFeature, { loading: editFeatureLoading }] = useMutation(UPDATE_FEATURE);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState({});
  const [featureToEdit, setFeatureToEdit] = useState(false);
  const [modules, setModules] = useState([]);
  const [features, setFeatures] = useState([]);
  const [showHideIds, setShowHideIds] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isResponseByParentId, setIsResponseByParentId] = useState(false);
  const [moduleId, setModuleId] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const { resourceName, label } = CMS;

  const getItems = (featuresData = []) => {
    const itemsValue = featuresData.map((item) => {
      const { _id, user = {}, hide = {}, ...rest } = item;
      const { permission: userPermission = [] } = user;
      const { permission: hidePermission = [] } = hide;
      user.permission = userPermission.map((userItem) => (userItem && userItem.role) || userItem);
      hide.permission = hidePermission.map((hideItem) => (hideItem && hideItem.role) || hideItem);
      const object = {
        id: baseHelper.mongoIdAsString(_id),
        user,
        hide,
        ...rest,
      };
      return object;
    });
    return itemsValue;
  };

  useEffect(() => {
    if (moduleError || allUserFeatureError || featureByIdError) {
      const bannerContent = {
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(moduleError || allUserFeatureError || featureByIdError),
      };
      setBanner({ bannerContent });
    }
  }, [allUserFeatureError, featureByIdError, moduleError]);

  const onConfigure = ({ featureId, toggle = false }) => {
    const feats = (toggle === label.module && modules) || (toggle === label.feature && features) || [];
    const feature = feats.find((item) => item.id === featureId);
    setFeatureToEdit(baseHelper.parseData(feature));
    setIsModalOpen(true);
  };

  const handleSellers = (selectedSellers, featureToEditData) => {
    const updatedFeature = { ...featureToEditData };
    updatedFeature.user.group.seller = [...selectedSellers];
    setFeatureToEdit(updatedFeature);
    setIsConfirm(false);
  };

  const handleVendors = (selectedVendors, featureToEditData) => {
    const updatedFeature = { ...featureToEditData };
    updatedFeature.user.group.vendor = [...selectedVendors];
    setFeatureToEdit(updatedFeature);
    setIsConfirm(false);
  };

  const handleChange = (field, value) => {
    setFeatureToEdit((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handelShowHideState = (moduleResponseData) => {
    Object.keys(moduleResponseData).map((key) => {
      const parentModuleId = moduleResponseData[key].moduleId;
      const value = !isExpanded[parentModuleId];
      setIsExpanded((prevState) => ({
        ...prevState,
        [parentModuleId]: value,
        [moduleResponseData[key].id]: false,
      }));
      return null;
    });
  };
  // show hide useEffect
  useEffect(() => {
    if (!featureByParentIdLoading && featureByParentIdData) {
      const responseData = baseHelper.getResponseData(featureByParentIdData, constant.gql.GET_FEATURE_BY_PARENT_ID);
      if (!responseData) {
        const error = baseHelper.getResponseError(featureByParentIdData, constant.gql.GET_FEATURE_BY_PARENT_ID);
        return setBanner({ isOpen: true, status: constant.CRITICAL, title: error });
      }
      if (!responseData.length) {
        return setIsExpanded((prevState) => ({
          ...prevState,
          moduleId: false,
        }));
      }
      setIsResponseByParentId(true);
      const moduleResponseData = getItems([...responseData]);
      handelShowHideState(moduleResponseData);
      if (showHideIds.length > 1) {
        const parentId = showHideIds.find((item) => item !== moduleId);
        setIsExpanded((prevState) => ({
          ...prevState,
          [parentId]: false,
        }));
        const index = showHideIds.indexOf(parentId);
        if (index > -1) {
          showHideIds.splice(index, 1);
        }
      }
      setFeatures(moduleResponseData);
    }
    // return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureByParentIdData, featureByParentIdLoading, moduleId]);

  const responseModuleError = baseHelper.getResponseError(moduleData, constant.gql.GET_MODULE);
  if (responseModuleError) {
    setBanner({ isOpen: true, status: constant.CRITICAL, title: responseModuleError });
  }
  const responseModuleData = baseHelper.getResponseData(moduleData, constant.gql.GET_MODULE);
  if (responseModuleData && modules.length === 0 && Object.keys(isExpanded).length === 0) {
    try {
      const moduleResponseData = getItems(responseModuleData);
      setModules([...moduleResponseData]);
      const expandedList = {};
      Object.keys(moduleResponseData).map((key) => {
        expandedList[moduleResponseData[key].id] = false;
        return null;
      });
      setIsExpanded(expandedList);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  }
  const responseAllUserFeatureData = baseHelper.getResponseData(
    allUserFeatureData,
    constant.gql.GET_ALL_USER_FOR_FEATURE
  );
  const responseAllUserFeatureError = baseHelper.getResponseError(
    allUserFeatureData,
    constant.gql.GET_ALL_USER_FOR_FEATURE
  );
  if (responseAllUserFeatureError) {
    setBanner({ isOpen: true, status: constant.CRITICAL, title: responseAllUserFeatureError });
  }
  if (responseAllUserFeatureData && sellers.length === 0 && vendors.length === 0) {
    try {
      const { shops = [], brandNames = [] } = responseAllUserFeatureData;
      setSellers([...shops]);
      setVendors([...brandNames]);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  }
  const toggleFeature = async (id) => {
    setModuleId(id);
    if (id !== moduleId) {
      setShowHideIds((prevState) => [...prevState, id]);
    }
    getFeatureByParentId({ variables: { input: { _id: id } } });
  };

  const renderConfirmModal = () => {
    if (!isConfirmModalOpen) {
      return null;
    }
    return (
      <Modal
        open
        onClose={() => {
          setIsConfirmModalOpen(false);
          setIsConfirm(false);
          setIsModalOpen(true);
        }}
        title={cms("modal.confirm.title")}
        primaryAction={{
          content: cms("button.confirm"),
          onAction: () => {
            setIsConfirm(true);
            setIsConfirmModalOpen(false);
            setIsModalOpen(true);
          },
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("modal.confirm.description")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  const updateFeature = async () => {
    if (!isConfirm) {
      setIsConfirmModalOpen(true);
      setIsModalOpen(false);
      return;
    }
    const {
      time,
      location,
      device,
      key,
      moduleId: moduleIdData,
      parentId,
      features: featuresValue,
      dependency,
      ...rest
    } = featureToEdit;
    const requestToData = { ...rest };
    try {
      const res = await editFeature({
        variables: { input: requestToData },
      });
      setIsModalOpen(false);
      setIsConfirm(false);
      setIsConfirmModalOpen(false);
      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_FEATURE);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_FEATURE);
        return setBanner({ isOpen: true, status: constant.CRITICAL, title: errorResponse });
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: label.successFulFeaturePanel });
      const moduleIndex = modules.findIndex((item) => item.id === featureToEdit.id);
      if (moduleIndex !== -1) {
        modules[moduleIndex] = baseHelper.parseData(featureToEdit);
      }
      const featureIndex = features.findIndex((item) => item.id === featureToEdit.id);
      if (featureIndex !== -1) {
        features[featureIndex] = baseHelper.parseData(featureToEdit);
      }
      setFeatureToEdit(false);
      setModules(modules);
      setFeatures(features);
    } catch (exception) {
      setIsModalOpen(false);
      setIsConfirm(false);
      setIsConfirmModalOpen(false);
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    return null;
  };

  const renderDetails = (feature, toggle = false) => {
    const { user = {}, hide = {} } = feature;
    const { permission: userPermission = [], tier = [] } = user;
    const { permission: hidePermission = [] } = hide;
    let allowedPermission = "";
    let allowedTier = "";
    let hideFrom = "";
    tier.forEach((item, idx) => {
      allowedTier += baseHelper.ucFirst(item);
      if (idx !== tier.length - 1) {
        allowedTier += ", ";
      }
    });
    userPermission.forEach((item, idx) => {
      allowedPermission += baseHelper.ucFirst(item);
      if (idx !== userPermission.length - 1) {
        allowedPermission += ", ";
      }
    });
    hidePermission.forEach((item, idx) => {
      hideFrom += baseHelper.ucFirst(item);
      if (idx !== hidePermission.length - 1) {
        hideFrom += ", ";
      }
    });
    const { id } = feature;
    return (
      <Card.Section
        actions={[
          {
            content: isExpanded[id] ? label.hide : label.show,
            disclosure: isExpanded[id] ? label.up : label.down,
            onAction: () => toggleFeature(id),
          },
        ]}
      >
        <Stack vertical spacing="extraTight">
          <Stack.Item>
            <Stack>
              <Stack.Item>
                <Button plain>
                  <TextStyle variation="strong">{feature.label}</TextStyle>
                </Button>
              </Stack.Item>
              <Stack.Item fill>
                <span id={`${feature.key}Badge`}>
                  <Badge id={`${feature.key}Badge`} status={baseHelper.getBadgeType(feature.version)}>
                    {baseHelper.ucFirst(feature.version)}
                  </Badge>
                </span>
              </Stack.Item>
              <Stack.Item>
                <Button
                  id={`${feature.key}ConfigureButton`}
                  size="slim"
                  outline
                  onClick={() => {
                    onConfigure({
                      featureId: feature.id,
                      toggle,
                    });
                  }}
                >
                  {label.configure}
                </Button>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="extraTight">
              <Stack.Item>
                <TextStyle variation="strong">{label.descriptionColon}</TextStyle>
              </Stack.Item>
              <Stack.Item>{feature.description || cms("common.label.na")}</Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="extraTight">
              <Stack.Item>
                <TextStyle variation="strong">{label.allowedForColon}</TextStyle>
              </Stack.Item>
              <Stack.Item>{allowedPermission || cms("common.label.na")}</Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="extraTight">
              <Stack.Item>
                <TextStyle variation="strong">{label.allowedTierColon}</TextStyle>
              </Stack.Item>
              <Stack.Item>{allowedTier || cms("common.label.na")}</Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="extraTight">
              <Stack.Item>
                <TextStyle variation="strong">{label.hideFromColon}</TextStyle>
              </Stack.Item>
              <Stack.Item>{hideFrom || cms("common.label.na")}</Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card.Section>
    );
  };

  const renderModal = () => {
    if (!featureToEdit) {
      return null;
    }
    return (
      <FeatureModel
        featureToEdit={featureToEdit}
        isModalOpen={isModalOpen}
        updateFeature={updateFeature}
        editFeatureLoading={editFeatureLoading}
        handleChange={handleChange}
        setFeatureToEdit={setFeatureToEdit}
        setIsModalOpen={setIsModalOpen}
        handleSellers={handleSellers}
        handleVendors={handleVendors}
        vendors={vendors}
        sellers={sellers}
        CMS={CMS}
        setIsConfirm={setIsConfirm}
      />
    );
  };

  const renderFeatures = (id) => {
    const result = features.map((featureValue) => <>{renderDetails(featureValue, label.feature)}</>);
    return (
      <Collapsible open={isExpanded[id]} id={`feature-collapsible${id}`}>
        <Layout.Section>
          {isResponseByParentId ? <Stack vertical>{result}</Stack> : <SkeletonBodyText />}
        </Layout.Section>
      </Collapsible>
    );
  };
  const renderModule = (feature) => (
    <Card>
      <ResourceList.Item id={feature.id} accessibilityLabel={`View details for ${feature.label}`}>
        {renderDetails(feature, label.module)}
        {renderFeatures(feature.id)}
      </ResourceList.Item>
    </Card>
  );

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner(() => setBanner({ isOpen: false, title: "", status: "" }))}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        {renderConfirmModal()}
        {renderModal()}
        <ResourceList
          loading={moduleLoading || allUserFeatureLoading}
          resourceName={resourceName}
          items={[...modules]}
          renderItem={renderModule}
          showHeader
        />
      </Layout.Section>
    </>
  );
};

export default withErrorBoundary(FeaturePanel);
