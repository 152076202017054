import React from "react";
import { Card, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris";

const SkeletonCard = () => {
  return (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );
};

export default SkeletonCard;
