import React, { useContext } from "react";
import { PrivateContext } from "lib/context";
import Color from "./color/color";
import Cover from "./cover";
import Description from "./description";
import Domain from "./domain";
import Favicon from "./favicon";
import FinishCover from "./finishCover";
import FinishDescription from "./finishDescription";
import Image from "./logo";
import Page from "./page/page";
import WelcomeCover from "./welcomeCover";
import WelcomeDescription from "./welcomeDescription";
import { Layout } from "@shopify/polaris";

const CustomPage = () => {
  const { currentUser } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  return (
    <Layout>
      <Domain />
      {!ecommercePlatform && <Description />}
      <Image />
      <Favicon />
      {!ecommercePlatform && <Cover />}
      <WelcomeCover />
      <WelcomeDescription />
      <FinishCover />
      <FinishDescription />
      <Color />
      <Page />
    </Layout>
  );
};

export default CustomPage;
