import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import { CalloutCard, Layout, Button } from "@shopify/polaris";
import config from "configuration";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";
// import config from "configuration";

import { FETCH_PLANS } from "app/plans/apollo/queries";
import { CREATE_PLAN, STRIPE_OPERATOR, UPDATE_PLAN_DETAILS } from "app/plans/apollo/mutations";

import { PlanSkeleton, PlanCard, Skeleton } from "./components";

// NOTE: To the future
// if there are two many differences between select & update plan then create separate file
const Plan = (props) => {
  const { banner, currentPlan, error, onFreeSubmit = () => {}, onSubmit = () => {}, submitLoading, setBanner } = props;
  const { cms, currentUser, history, isLoading } = useContext(PrivateContext);

  const stripe = window.Stripe(currentUser.isTest ? config.stripePublishKey('uat') : config.stripePublishKey());

  const isIframe = baseHelper.isIframe();

  const [stripeOperator, { loading: stripeOperatorLoading }] = useMutation(STRIPE_OPERATOR);

  const { gql } = constant;
  const {
    customer = "",
    plan: shopifyPlanCode = "",
    charge_id: shopifyChargeId = "",
    sub = "",
  } = baseHelper.queryParamsFromLocation(history);

  const onPlanSelect = async () => {
    try {
      stripeOperator({
        variables: {
          input: {},
        },
      })
        .then(async (res) => {
          const responseData = baseHelper.getResponseData(res.data, gql.STRIPE_OPERATOR);
          if (responseData.sessionId) {
            await stripe.redirectToCheckout({
              sessionId: responseData.sessionId,
            });
          }
        })
        .catch((exception) => {
          catchBlock(exception);
        });
    } catch (err) {
      catchBlock(err);
    }
  };

  let isChargebee = !!customer; // May add additional setting to check if chargebee
  let planCode = shopifyPlanCode;
  let chargeId = isChargebee ? sub : shopifyChargeId;

  const isUpdate = !!(currentUser && currentUser.plan && currentUser.plan.code) || false;
  const isPAYE = !!(currentUser && currentUser.plan && currentUser.plan.isPAYE);

  const { plan: userPlan = {}, planPreference } = currentUser || {};
  const { isChargebee: isChargebeeValue = false, chargeId: chargbeeId, code } = userPlan || {};
  if (isChargebeeValue) {
    isChargebee = isChargebeeValue;
  }
  if (code) {
    planCode = code;
  }

  if (chargbeeId) {
    chargeId = chargbeeId;
  }

  let initialIsPaye = isUpdate ? isPAYE : null;
  if (planPreference) {
    initialIsPaye = planPreference === "paye";
  }

  const [activePlan, setActivePlan] = useState("");
  const [isPaye, setIsPaye] = useState(initialIsPaye);
  const { data: planData, error: planError, loading: planLoading } = useQuery(FETCH_PLANS);
  const [createPlan, { loading: createPlanLoading }] = useMutation(CREATE_PLAN);
  const [updatePlan, { loading: updatePlanLoading }] = useMutation(UPDATE_PLAN_DETAILS);

  useEffect(() => {
    if (currentPlan) {
      setActivePlan(currentPlan);
    }
  }, [currentPlan]);

  const thenBlock = (res) => {
    const redirectURL = `${currentUser.controlPanelBaseUrl}/manage/app/${config.bigCommerceMarketcubeAppId}`;
    const gqlPlanKey = isUpdate ? gql.UPDATE_PLAN_DETAILS : gql.CREATE_PLAN;
    const responseData = baseHelper.getResponseData(res.data, gqlPlanKey);

    if (responseData) {
      const title = isUpdate ? cms("message.success.update") : cms("message.success.select");

      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title,
      });
      setActivePlan(planCode);

      setTimeout(() => {
        if (!isIframe && currentUser.controlPanelBaseUrl) {
          window.top.location = redirectURL;
        } else {
          return history.push("/");
        }
      }, 1500);
    } else {
      const responseError = baseHelper.getResponseError(res.data, gqlPlanKey);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
  };

  const catchBlock = (exception) => {
    setBanner({
      isOpen: true,
      status: constant.CRITICAL,
      title: exception.message,
    });
  };

  useEffect(() => {
    if (isChargebee && !isUpdate) {
      const planInput = {
        code: planCode,
        chargeId,
      };

      if (customer && !isUpdate) {
        planInput.customer = customer;
      }

      if (isUpdate && isChargebee) {
        planInput.code = planCode;
      }

      createPlan({
        variables: {
          input: planInput,
        },
      })
        .then((res) => {
          thenBlock(res);
        })
        .catch((exception) => {
          catchBlock(exception);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeId, createPlan, customer, isLoading, planCode]);

  if (planLoading || isLoading || createPlanLoading || updatePlanLoading) {
    return isUpdate ? <Skeleton /> : <PlanSkeleton />;
  }

  if (error) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={error} />
      </Layout.Section>
    );
  }

  if (planError) {
    const parsedError = errorHelper.parse(planError);
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={parsedError} />
      </Layout.Section>
    );
  }

  const plans = baseHelper.getResponseData(planData, "getPlans") || [];
  if (plans.length === 0) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={cms("message.error.planNotFound")} />
      </Layout.Section>
    );
  }

  const getFreePlan = () => {
    const freePlan = plans.find((plan) => plan.code === constant.FREE);
    return freePlan;
  };

  const selectFreePlan = () => {
    onFreeSubmit();
  };

  const renderFreePlan = (selectedPlan = "") => {
    const plan = getFreePlan();
    if (!plan || plan.isHidden || isUpdate) {
      return null;
    }

    return (
      <Layout.Section>
        <center>
          <br />
          <Button
            disabled={selectedPlan === plan.code}
            onClick={() => selectFreePlan()}
            loading={selectedPlan === plan.code}
            size="slim"
            outline
            monochrome
          >
            {cms("button.free")}
          </Button>
        </center>
      </Layout.Section>
    );
  };

  const chargeableSortedPlans = plans
    .filter((plan) => !plan.isHidden && !baseHelper.isFreePlan({ plan }) && plan.code !== "startup")
    .sort((first, second) => first.sortOrder - second.sortOrder);

  const startUpPlan = plans.find((plan) => plan.isHidden && plan.code === "startup");

  const getLayoutSections = () => {
    return chargeableSortedPlans.map((plan) => {
      const submitCallback = () => {
        onSubmit(plan);
      };
      return (
        <Layout.Section oneThird key={plan && plan.name}>
          <PlanCard
            plan={plan}
            callback={() => onPlanSelect(plan)}
            activePlan={activePlan}
            submitLoading={submitLoading || stripeOperatorLoading}
          />
        </Layout.Section>
      );
    });
  };

  if (isPaye === null) {
    return (
      <>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
            />
          </Layout.Section>
        )}
        <Layout.Section>
          <CalloutCard
            title={<b>Pay as You GO</b>}
            illustration="https://assets.website-files.com/617550f85b9a2b5d1719b66a/617550f85b9a2b961219b7ba_icon-3-store-features-saaslify-template.svg"
            primaryAction={{
              content: "Choose this plan",
              onAction: () => setIsPaye(true),
            }}
          >
            Fair and transparent pricing. One time setup fees with a simple 3% transaction charge. No expensive startup or ongoing costs. We partner with you to help you succeed so if you grow we grow and you get access to our world class platform and support.
          </CalloutCard>
        </Layout.Section>
        {/* <Layout.Section>
          <CalloutCard
            title={<b>Monthly Subscription</b>}
            illustration="https://assets.website-files.com/617550f85b9a2b5d1719b66a/617550f85b9a2bcb7819b7c7_icon-1-perks-careers-saaslify-template.svg"
            primaryAction={{
              content: 'Choose this plan',
              onAction: () => setIsPaye(false),
            }}
          >
            Fair and transparent pricing without lock-ins. No hidden fees. No setup fees. No extra transaction charges. Only a monthly subscription that you can cancel at any time. With Bespoke Builds available.
          </CalloutCard>
        </Layout.Section> */}
      </>
    );
  }

  if (isPaye === true) {
    return (
      <>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
            />
          </Layout.Section>
        )}
        {!isUpdate && !planPreference && (
          <Layout.Section>
            <Button size="slim" onClick={() => setIsPaye(null)}>
              Back
            </Button>
          </Layout.Section>
        )}
        <Layout.Section>
          <Layout>
            <Layout.Section key={startUpPlan.name}>
              <PlanCard
                plan={startUpPlan}
                callback={() => onPlanSelect(startUpPlan)}
                activePlan={activePlan}
                submitLoading={submitLoading || stripeOperatorLoading}
              />
            </Layout.Section>
            <Layout.Section />
          </Layout>
          <br />
          <Layout>{renderFreePlan(activePlan)}</Layout>
        </Layout.Section>
      </>
    )
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {!isUpdate && !planPreference && (
        <Layout.Section>
          <Button size="slim" onClick={() => setIsPaye(null)}>
            Back
          </Button>
        </Layout.Section>
      )}
      <Layout.Section>
        <Layout>{getLayoutSections()}</Layout>
        <br />
        <Layout>{renderFreePlan(activePlan)}</Layout>
      </Layout.Section>
    </>
  );
};

Plan.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  currentPlan: PropTypes.string,
  error: PropTypes.string,
};

Plan.defaultProps = {
  currentPlan: "",
  error: "",
};

export default Plan;
