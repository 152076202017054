import React, { useContext, useState } from "react";
import {
  Layout,
  Card,
  FormLayout,
  Select,
  TextField,
  Stack,
  List,
  Banner,
  TextContainer,
  Caption,
  TextStyle,
  PageActions,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import propType from "prop-types";

// import constant and helper
import constant from "lib/constant/constant";
import { Sheet } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const StandardDiscount = (props) => {
  const { history, cms } = useContext(PrivateContext);
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser || {};
  const {
    errorMessage,
    fieldValue,
    handleChange,
    handleSelectChange,
    handleValidate,
    isDisabled,
    isSubmitDisbled,
    onSubmit,
  } = props;
  const { standardType, standardAmount, lastUpdated } = fieldValue;
  const { FLAT, PERCENTAGE, SELECTED_STANDARD_TYPE, STANDARD_AMOUNT, value: values, symbol } = constant;
  const { MAX_PERCENTAGE, MAX_FLAT } = values;
  const [sheetActive, setSheetActive] = useState(false);
  const formWidth170px = { width: "170px" };
  const formWidth130px = { width: "130px" };

  const valueOptions = [
    {
      label: `${cms("common.label.flat")}`,
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE,
    },
  ];

  const isFlatType = standardType === FLAT;
  const getAmountMax = isFlatType ? MAX_FLAT : MAX_PERCENTAGE;
  const getAmountPrefix = isFlatType ? (currentUser && moneyFormat) || symbol.DOLLAR : "";
  const getAmountSuffix = isFlatType ? "" : symbol.PERCENTAGE;

  const standardLists = cms("provider.standard.caption") || [];

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && standardAmount);
  };

  const renderList = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b>
        {` ${content.description}`}
      </List.Item>
    ));
    return list;
  };

  return (
    <>
      <Layout.AnnotatedSection
        title={`${cms("provider.standard.title")}`}
        description={`${cms("provider.standard.description")}`}
      >
        <Card
          title={[
            cms("provider.standard.title"),
            lastUpdated && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
              </Caption>
            ),
          ]}
          // actions={[
          //   {
          //     content: cms("common.label.learnMore"),
          //     onAction: () => {
          //       setSheetActive(true);
          //     },
          //   },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("provider.standard.subDescription")}</TextContainer>
            <Sheet
              title={cms("provider.standard.title")}
              isOpen={sheetActive}
              onClose={() => setSheetActive(false)}
              primaryAction={{
                content: cms("common.label.done"),
                onAction: () => setSheetActive(false),
              }}
              secondaryAction={{
                content: cms("common.button.cancel"),
                onAction: () => setSheetActive(false),
              }}
            >
              TODO:
            </Sheet>
            <FormLayout>
              <Stack wrap={false}>
                <div style={formWidth130px}>
                  <Select
                    value={standardType}
                    placeholder={`${cms("common.placeholder.value")}`}
                    options={valueOptions}
                    onChange={handleSelectChange}
                    onBlur={() => handleValidate(SELECTED_STANDARD_TYPE, standardType)}
                    error={errorMessage && errorMessage.selectedStandardType}
                  />
                </div>
                <div style={formWidth170px}>
                  <TextField
                    value={standardAmount}
                    min={0}
                    max={getAmountMax}
                    prefix={getAmountPrefix}
                    suffix={getAmountSuffix}
                    onChange={(value) => handleChange(acceptOnlyValidInput(value, standardAmount))}
                    onBlur={() => handleValidate(STANDARD_AMOUNT, standardAmount)}
                    error={errorMessage && errorMessage.standardAmount}
                  />
                </div>
              </Stack>
              <Banner status="info">
                <p>{cms("common.label.option")}</p>
                <br />
                <List type="bullet">{renderList()}</List>
              </Banner>
            </FormLayout>
            <div className="page-action">
              {lastUpdated && (
                <PageActions
                  primaryAction={{
                    id: "update",
                    content: `${cms("common.button.update")}`,
                    onAction: () => onSubmit(),
                    disabled: !isSubmitDisbled || isDisabled(),
                  }}
                  secondaryActions={[
                    {
                      id: "cancel",
                      content: `${cms("common.button.cancel")}`,
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </div>
          </Card.Section>
        </Card>
        {!lastUpdated && (
          <PageActions
            primaryAction={{
              id: "submit",
              content: `${cms("common.button.submit")}`,
              onAction: () => onSubmit(),
              disabled: !isSubmitDisbled || isDisabled(),
            }}
            secondaryActions={[
              {
                id: "cancel",
                content: `${cms("common.button.cancel")}`,
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

StandardDiscount.defaultProps = propType.default;
StandardDiscount.propTypes = propType.type;
export default withFeature(withErrorBoundary(StandardDiscount), { feature: constant.STANDARD_DISCOUNT });
