import { gql } from "apollo-boost";

const GET_DISCOUNT_CODE_SETTING = gql`
  query {
    getSellerDiscountCodeSetting {
      data {
        isEnabled
        prefixCode
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_DISCOUNT_CODE_SETTING;
