import React from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import constant from "lib/constant/constant";

import { Banner } from "lib/components";

const Shipping = (props) => {
  const { data, onChange, cms } = props;
  const { isPhysicalProduct = false, weight = "", weightUnit } = data;

  const handleShipping = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const selectWeightUnit = () => {
    const options = ["lb", "oz", "kg", "g"];
    return (
      <Select
        id="selectWeight"
        value={weightUnit || "lb"}
        label={cms("section.shipping.label.weightUnit")}
        labelHidden
        onChange={(value) => handleShipping(constant.WEIGHT_UNIT, value)}
        options={options}
      />
    );
  };

  return (
    <Card title={cms("section.shipping.title")}>
      <Card.Section>
        <Checkbox
          label={cms("section.shipping.label.physicalProduct")}
          checked={isPhysicalProduct}
          onChange={(value) => handleShipping(constant.IS_PHYSICAL_PRODUCT, value)}
        />
        {!isPhysicalProduct && (
          <div>
            <br />
            <Banner isOpen status="info" isScrollTop={false}>
              <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {isPhysicalProduct && (
        <Card.Section title={cms("section.shipping.label.weight").toUpperCase()}>
          <p>{cms("section.shipping.label.calculateShippingRate")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.shipping.label.weight")}
            type="number"
            value={weight}
            placeholder={cms("section.shipping.placeholder.zero")}
            onChange={(value) => handleShipping(constant.WEIGHT, value)}
            connectedRight={selectWeightUnit()}
          />
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  data: PropTypes.shape({
    isPhysicalProduct: PropTypes.bool.isRequired,
    weight: PropTypes.string.isRequired,
    weightUnit: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Shipping;
