import styled from "styled-components";

export const StyledPlanCard = styled.div``;

export const InnerCard = styled.div`
  .Polaris-Card__Section {
    background: #f8f8f8;
    .Polaris-Button--primary {
      background: #006e52 !important;
    }
  }
  .upgrade-button .Polaris-Button__Text {
    font-size: 1.4rem;
  }
  .stack-wrapper {
    margin-bottom: 2.2rem;
    .Polaris-DisplayText--sizeSmall {
      font-size: 1.8rem;
    }
    &:nth-last-of-type(2) {
      margin-bottom: 3.3rem;
    }
    .Polaris-ProgressBar {
      margin-top: 0.5rem;
    }
    &.count .Polaris-Stack__Item--fill {
      font-size: 1.2rem;
    }
  }
  .Polaris-ProgressBar__Indicator {
    background: #396f53;
  }
`;
