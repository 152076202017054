import React, { useEffect, useState, useContext } from "react";
import {
  Caption,
  Card,
  Layout,
  Link,
  TextStyle,
  PageActions,
  // Thumbnail,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { useQuery, useMutation } from "@apollo/react-hooks";

import { GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";
// import gql
import { FETCH_VENDOR_ACCESS } from "app/advanceVendor/apollo/queries";
import { ADVANCE_VENDOR_ACCESS } from "app/advanceVendor/apollo/mutations";

import constant from "lib/constant/constant";

import { Banner, SkeletonAnnotated, Toast } from "lib/components";

import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

import AddVendor from "./components/add/add";
import Table from "./components/table/table";

const ManageAdvanceAccess = () => {
  const { cms, isLoading, history } = useContext(PrivateContext);
  const { gql } = constant;
  const [activeModal, setActiveModal] = useState(false);
  const [isVendorListLoad, setIsVendorListLoad] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [addVendor, setAddVendor] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disable, setDisable] = useState(true);
  const [standardAccess, setStandardAccess] = useState({
    order: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    product: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    payment: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
  });
  const [userInput, setUserInput] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [accessSetting, setAccessSetting] = useState({
    order: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    product: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    payment: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
  });

  const { data: vendorAndProductTypesData, loading: vendorListLoading } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);
  const { loading: settingLoading = false, data: vendorAccessData, refetch } = useQuery(FETCH_VENDOR_ACCESS);
  const [advanceVendorAccess, { loading: advanceAccessLoading }] = useMutation(ADVANCE_VENDOR_ACCESS);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, constant.gql.GET_VENDOR_PRODUCT);
    const { supplierRows = [] } = responseData;
    setVendorList(supplierRows);
  }, [vendorAndProductTypesData, gql.GET_VENDOR_PRODUCT]);

  useEffect(() => {
    if (!vendorAccessData) {
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING);
    const responseError = baseHelper.getResponseError(vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (responseData && (responseData.advanceAccess || responseData.access)) {
      const { advanceAccess = [], access = {}, updatedAt: lastUpdateAt = "" } = responseData;

      if (access) {
        delete access.updatedAt;
        setStandardAccess(access);
      }

      setUpdatedAt(lastUpdateAt);

      if (advanceAccess && advanceAccess.length) {
        setUserInput(advanceAccess);
      }
    }
  }, [vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING]);

  useEffect(() => {
    if (!isAccess) {
      setAccessSetting(standardAccess);
      setIsAccess(true);
    }
  }, [standardAccess, isAccess]);

  useEffect(() => {
    if (userInput && userInput.length && !isVendorListLoad && vendorList && vendorList.length) {
      const vendorIds = [];
      userInput.forEach((item) => {
        vendorIds.push(item.vendorId);
      });
      vendorList.forEach((item) => {
        if (vendorIds.includes(item._id)) {
          // eslint-disable-next-line no-param-reassign
          item.isCheck = true;
        }
      });
      setIsVendorListLoad(true);
    }
  }, [userInput, vendorList, isVendorListLoad]);

  const handleModal = () => {
    setActiveModal(!activeModal);
  };

  const activator = (
    <>
      <Link id="addLink" onClick={handleModal}>
        {cms("common.button.addMore")}
      </Link>
      <br />
    </>
  );

  const addVendorModal = () => {
    if (activeModal) {
      return (
        <AddVendor
          activator={activator}
          active={activeModal}
          cms={cms}
          setAddVendor={setAddVendor}
          userInput={userInput}
          setUserInput={setUserInput}
          standardAccess={accessSetting}
          setStandardAccess={setStandardAccess}
          addVendor={addVendor}
          vendorList={vendorList}
          setVendorList={setVendorList}
          handleChange={handleModal}
          setActive={setActiveModal}
          setDisable={setDisable}
        />
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    try {
      const res = await advanceVendorAccess({
        variables: {
          input: { advanceAccess: userInput },
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.ADVANCE_ACCEPT_ACCESS);
      const resError = baseHelper.getResponseError(res.data, gql.ADVANCE_ACCEPT_ACCESS);
      if (resError) {
        setBanner({
          status: constant.CRITICAL,
          isOpen: true,
          title: resError,
        });
      }
      if (resData) {
        setSuccessMessage(cms("message.success.save"));
        setDisable(true);
        refetch();
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  if (vendorListLoading || settingLoading || advanceAccessLoading || isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <Layout>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            isScrollTop={banner.isOpen}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Toast message={successMessage} />
          <Card
            title={[
              cms("section.title"),
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <Table
                cms={cms}
                data={addVendor}
                setData={setVendorList}
                userInput={userInput}
                vendorList={vendorList}
                setVendorList={setVendorList}
                setUserInput={setUserInput}
                setDisable={setDisable}
              />
              <br />
              {activator}
              {addVendorModal()}
              {updatedAt && (
                <div className="manageVendorAccessButton">
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: handleSubmit,
                      disabled: disable,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </div>
              )}
            </Card.Section>
          </Card>
          {!updatedAt && (
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: handleSubmit,
                disabled: disable,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </Layout.AnnotatedSection>
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ManageAdvanceAccess), {
  feature: constant.ADVANCE_VENDOR_ACCESS,
});
