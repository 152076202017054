import React, { useState, useContext, useEffect } from "react";
import { FormLayout, Form, Button } from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import propTypes
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import components
import { Banner } from "lib/components";
import AdvanceItem from "./advanceLine";

const SelectedVendorPayment = (props) => {
  const { cms = {} } = useContext(PrivateContext);

  const { loading, responseData, responseError, setSubmitEnable, setValues, vendorTermData } = props;

  const [allSuppliers, setAllSuppliers] = useState([]);
  const [state, setState] = useState({
    count: 1,
    advancedValue: [
      {
        vendorId: null,
        status: null,
        dateOfPayment: null,
      },
    ],
  });

  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });

  useEffect(() => {
    const { supplierRows = [] } = responseData || {};
    if (!loading && (responseError || !responseData)) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.advanceOptionUnavailable"),
      });
      return;
    }

    const allSupplierValues = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: supplierId, brandName }) => ({
        label: brandName,
        value: supplierId,
      }));
    setAllSuppliers(allSupplierValues);
  }, [cms, loading, responseData, responseError]);

  useEffect(() => {
    const advancedValueLength = vendorTermData.length || 0;
    const isEqualArray = baseHelper.isItemEqual(vendorTermData, state.advancedValue);
    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        advancedValue: vendorTermData,
      });
    }
  }, [vendorTermData, state.advancedValue]);

  const updateVal = (option, val, item) => {
    const date = constant.DATE_OF_PAYMENT;
    setBanner({ isOpen: false, status: "", title: "" });
    const { advancedValue } = state;
    let duplicate = false;
    if (option === "option" && advancedValue[item]) {
      advancedValue[item].vendorId = null;
    }
    if (option === constant.VENDOR_ID) {
      advancedValue.forEach((advanceData) => {
        if (advanceData.vendorId === val && advancedValue[item]) {
          advancedValue[item].vendorId = null;
          setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.rowAlreadyExists") });
          duplicate = true;
        }
        return null;
      });
    }
    if (duplicate) {
      return;
    }
    if (option === date && advancedValue[item]) {
      advancedValue[item][date] = val;
    } else if (advancedValue[item]) {
      advancedValue[item][option] = val;
    }
    setState({ ...state, advancedValue });
    setValues(advancedValue);
    setSubmitEnable(true);
  };

  const add = () => {
    const { advancedValue, count } = state;
    if (advancedValue.length) {
      const line = advancedValue[advancedValue.length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setSubmitEnable(false);
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
        return;
      }
    }
    const orderStatus = constant.STATUS;
    const date = constant.DATE_OF_PAYMENT;
    const row = { [orderStatus]: null, [date]: null, vendorId: null };
    advancedValue.push(row);
    setState({ ...state, count: count + 1, advancedValue });
  };

  const removeItem = (item) => {
    const { advancedValue } = state;
    advancedValue.splice(item, 1);
    setState({ ...state, count: state.count - 1, advancedValue });
    setSubmitEnable(true);
  };

  const renderAdvanceItems = () => {
    const { count, advancedValue } = state;
    return (
      <AdvanceItem
        advancedValue={advancedValue}
        allSuppliers={allSuppliers}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
      />
    );
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  return (
    <>
      {banner.isOpen && (
        <>
          <br />
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => dismissBanner()}>
            {banner.message}
          </Banner>
          <br />
        </>
      )}
      <Form>
        <FormLayout>
          {renderAdvanceItems()}
          {
            <Button plain id="addLink" onClick={add}>
              {cms("button.addVendor")}
            </Button>
          }
        </FormLayout>
      </Form>
    </>
  );
};

SelectedVendorPayment.propTypes = {
  setSubmitEnable: PropTypes.func,
  setValues: PropTypes.func,
};

SelectedVendorPayment.defaultProps = {
  setSubmitEnable: () => {},
  setValues: () => {},
};

export default withErrorBoundary(SelectedVendorPayment);
