import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import {
  ActionList,
  Button,
  Caption,
  DatePicker,
  Link,
  Modal,
  PageActions,
  Popover,
  RadioButton,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import components
import { Banner } from "lib/components";
import List from "./subFeatures/list";

// import props
import { exportProps } from "./Props/props";

const VendorExport = (props) => {
  const {
    openModal,
    setOpenModal,
    statusList,
    getVendorData,
    getVendorLoading,
    getVendorError,
    getVendorLines,
    setQueryData,
    vendorListResponseData,
  } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const [isExportDone, setExportDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataToFetch = {};
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const { createdAt } = currentUser;

  const [selected, setSelected] = useState(0);
  const [selectedItems, setSelectedItems] = useState([[]]);
  const [submitDate, setSubmitDate] = useState({});
  const [generate, setGenerate] = useState(false);
  const [exportedAll, setExportedAll] = useState(true);
  const [exportedLink, setExportedLink] = useState(null);
  const [exportedPDFLink, setExportedPDFLink] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectDate, setSelectDate] = useState();
  const [params, setParams] = useState();
  const exportVendorURL = "/export/vendor";

  const disableDate = (newDate) => {
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  };

  const handleStates = () => {
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
    setSelectedItems([[]]);
    setSelected(0);
  };
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  useEffect(() => {
    let bannerData = false;
    if (vendorListResponseData && generate && getVendorData && !getVendorLoading) {
      const checkVendorDetails = baseHelper.getResponseData(getVendorData, constant.gql.GET_VENDOR_DETAIL);
      if (!vendorListResponseData.vendors.length || !checkVendorDetails?.vendorDetail?.length) {
        setIsEnabled(true);
        setBanner({
          title: cms("sectionContent.export.message.error.noVendor"),
          status: constant.CRITICAL,
          isOpen: true,
        });
      }
      const csvDownloadRoute = `${exportVendorURL}${params}`;
      const pdfDownloadRoute = `${exportVendorURL}/pdf${params}`;
      setExportedLink((isExportDone && csvDownloadRoute) || null);
      setExportedPDFLink((isExportDone && pdfDownloadRoute) || null);
      setLoading(false);
    }
    if (getVendorError) {
      bannerData = { title: errorHelper.parse(getVendorError), status: constant.CRITICAL, isOpen: true };
    }
    if (bannerData) {
      setBanner({ title: bannerData.title, status: bannerData.status, isOpen: bannerData.isOpen });
      handleStates();
    }
  }, [
    exportedAll,
    isExportDone,
    params,
    cms,
    generate,
    getVendorData,
    getVendorLoading,
    getVendorError,
    vendorListResponseData,
  ]);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
  };

  const onExport = () => {
    setIsLoading(true);
    setGenerate(true);
    const dateRange = selectDate || {};
    let getStatus = [];
    getStatus = [...selectedItems[0]];
    const statusString = getStatus ? getStatus.join(",") : "";
    if (!(exportedAll || statusString)) {
      setBanner({ title: cms("sectionContent.export.message.error.select"), status: constant.CRITICAL, isOpen: true });
      return;
    }
    let url = `?startDate=${dateRange.start || ""}&endDate=${dateRange.end || ""}`;
    if (!exportedAll) {
      url = `?status=${statusString}&startDate=${dateRange.start || ""}&endDate=${dateRange.end || ""}`;
    }
    setParams(url);

    if (selectDate) {
      const startDate = moment(selectDate.start).format("ll");
      const endDate = moment(selectDate.end).format("ll");
      dataToFetch.dateRange = { start: startDate, end: endDate };
    }
    if (getStatus.length > 0) {
      dataToFetch.status = getStatus;
    }
    getVendorLines();
    setExportedLink(false);
    setExportedPDFLink(false);
    setExportDone(true);
    setQueryData(dataToFetch);
  };
  const tabs = [{ id: "statuses", content: "Status", panelId: "panel-status" }];

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(tabs);
    const singularItem = constant.ITEM;
    const pluralItem = constant.ITEMS;
    const changeIndex = 0;
    selectedItems.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${constant.SELECTED})`;
      }
    });
    return exportModalTabs;
  };

  const items = [statusList];
  const handleSelection = (event) => {
    const prevSelection = [...selectedItems];
    prevSelection[selected] = [...event];
    setSelectedItems(prevSelection);
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
    setLoading(false);
  };

  const onCloseModal = () => {
    handleStates();
    setExportedAll(true);
    setOpenModal(false);
    setBanner("");
    setLoading(false);
    setSubmitDate({});
    setSelectDate();
    setIsCalendarOpen(false);
    setIsEnabled(false);
    setGenerate(false);
    setIsLoading(false);
  };

  const onDismissBanner = () => {
    setIsEnabled(false);
    setExportedLink(false);
    setBanner({ isOpen: !banner.isOpen });
  };

  const downloadCSV = (link) => {
    window.open(link);
    onCloseModal();
    setActive(false);
    setPopoverActive(false);
  };

  const downloadPDF = (link) => {
    window.open(link);
    onCloseModal();
    setActive(false);
    setPopoverActive(false);
  };

  const tab = getResourceListTabs();

  const handleChange = () => {
    setActive(!active);
    setPopoverActive(!popoverActive);
  };

  const activator = (
    <Button onClick={handleChange} disclosure>
      {cms("sectionContent.export.label.selectFormat")}
    </Button>
  );
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);

  const handleDatePicker = () => {
    setIsEnabled(banner.isOpen);
    setExportedLink(false);
    setSelectDate({ start: selectDate.start, end: selectDate.end });
    setSubmitDate((prev) => {
      const initialState = { ...prev };
      initialState.start = selectDate.start;
      initialState.end = selectDate.end;
      initialState.startingDate = moment(selectDate.start).format("ll");
      initialState.endingDate = moment(selectDate.end).format("ll");
      initialState.isShowDate = true;
      return initialState;
    });
    setIsCalendarOpen(false);
  };

  const handleCancelDate = () => {
    const initialState = { ...submitDate };
    setSelectDate({
      start: initialState.start || new Date(),
      end: initialState.end || new Date(),
    });
    setIsCalendarOpen(false);
    setIsEnabled(banner.isOpen);
    setExportedLink(false);
  };

  const handlePopover = () => {
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList
          items={[
            {
              content: cms("sectionContent.export.label.exportCSV"),
              onAction: () => {
                setLoading(false);
                downloadCSV(exportedLink);
              },
            },
            {
              content: cms("sectionContent.export.label.exportPDF"),
              onAction: () => {
                setLoading(false);
                downloadPDF(exportedPDFLink);
              },
            },
          ]}
        />
      </Popover>
    );
  };

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Link onClick={() => history.push("/setting/provider/export")}>
          {cms("sectionContent.export.label.customize")}
        </Link>
      </Stack.Item>
      <Stack.Item>
        <Button onClick={() => onCloseModal()}>{cms("common.button.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        {exportedLink && !isEnabled ? (
          handlePopover()
        ) : (
          <Button
            primary
            onClick={() => onExport()}
            loading={loading || (getVendorLoading && isLoading)}
            disabled={isEnabled}
          >
            {cms("sectionContent.export.label.generate")}
          </Button>
        )}
      </Stack.Item>
    </Stack>
  );

  return (
    <Modal
      open={openModal}
      onClose={() => onCloseModal()}
      title={[cms("sectionContent.export.label.title")]}
      footer={actions()}
    >
      <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={() => onDismissBanner()} />
      <Modal.Section>
        {isCalendarOpen && (
          <>
            <div>
              <DatePicker
                month={month}
                year={year}
                onChange={(date) => {
                  setSelectDate({ start: date.start, end: date.end });
                }}
                onMonthChange={handleMonthChange}
                selected={selectDate}
                disableDatesAfter={new Date()}
                disableDatesBefore={disableDate(new Date(createdAt))}
                multiMonth
                allowRange
              />
            </div>
            <div>
              <PageActions
                primaryAction={{
                  content: cms("sectionContent.export.label.apply"),
                  onClick: () => {
                    handleDatePicker();
                  },
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onClick: () => {
                      handleCancelDate();
                    },
                  },
                ]}
              />
            </div>
            <br />
          </>
        )}
        {!isCalendarOpen && (
          <Stack>
            <Stack.Item fill>
              <RadioButton
                id="exportAllRadio"
                name={constant.EXPORT}
                label={cms("sectionContent.export.label.all")}
                helpText={cms("sectionContent.export.helpText.all")}
                checked={exportedAll}
                onChange={() => {
                  setBanner({ isOpen: false });
                  setExportedAll(!exportedAll);
                  setExportDone(false);
                  setExportedPDFLink(false);
                  setExportedLink(false);
                  setGenerate(false);
                  setIsEnabled(false);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                plain
                removeUnderline
                onClick={() => {
                  setIsCalendarOpen(true);
                  setIsEnabled(true);
                }}
              >
                {cms("sectionContent.export.label.date")}
              </Button>
              {submitDate.isShowDate && (
                <TextStyle variation="subdued">
                  <Caption>{`${submitDate.startingDate} - ${submitDate.endingDate}`}</Caption>
                </TextStyle>
              )}
            </Stack.Item>
            <RadioButton
              id="exportSelectedRadio"
              name={constant.EXPORT}
              label={cms("sectionContent.export.label.one")}
              helpText={cms("sectionContent.export.helpText.select")}
              checked={!exportedAll}
              onChange={() => {
                setBanner({ isOpen: false });
                setExportedAll(!exportedAll);
                setExportDone(false);
                setExportedPDFLink(false);
                setExportedLink(false);
                setGenerate(false);
                setIsEnabled(false);
              }}
            />
          </Stack>
        )}
        {!exportedAll && !isCalendarOpen && (
          <Tabs tabs={tab} selected={selected} onSelect={handleTabChange} fitted>
            <List
              items={items}
              selected={selected}
              handleSelection={handleSelection}
              selectedItems={selectedItems || []}
              onExport={onExport}
              cms={cms}
              getVendorLoading={getVendorLoading}
              isLoading={isLoading}
            />
          </Tabs>
        )}
      </Modal.Section>
    </Modal>
  );
};

VendorExport.propTypes = exportProps.type;
VendorExport.defaultProps = exportProps.default;

export default VendorExport;
