import * as yup from "yup";

import errorMessage from "./errorMessage";

const productSchema = yup.object().shape({
  product: yup.string().required(errorMessage),
});

const storeSchema = yup.object().shape({
  store: yup.string().required(errorMessage),
});

const vendorSchema = yup.object().shape({
  vendor: yup.string().required(errorMessage),
});

const multiVendorSchema = yup.object().shape({
  multiVendor: yup.string().required(errorMessage),
});

const revenueSchema = yup.object().shape({
  revenue: yup.string().required(errorMessage),
});

export { productSchema, vendorSchema, multiVendorSchema, storeSchema, revenueSchema };
