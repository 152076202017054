import styled from "styled-components";

const ExportTable = styled.div`
  td,
  th {
    vertical-align: middle;
    &:nth-child(4) {
      text-align: center;
      width: 24%;
    }
    &:nth-child(3) {
      text-align: center;
      width: 25%;
    }
  }
  tbody th,
  thead th:first-child {
    padding-left: 0;
  }
`;
export default ExportTable;
