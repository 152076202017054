import ADVANCE_VENDOR_ACCESS from "./advanceAccess";
import BULK_PRODUCT from "./bulkProductAction";
import HOLIDAY_SETTING from "./holidayMode";
import MANAGE_SUBSCRIPTION from "./manageSubscription";
import REVIEW_VENDOR_ACCESS from "./reviewVendorAccess";
import UPDATE_VENDOR_FIELD from "./updateVendorField";
import VENDOR_ACCESS from "./access";
import UPDATE_VENDOR_FORM_LABEL from "./updateVendorFormLabel";

export default BULK_PRODUCT;
export {
  ADVANCE_VENDOR_ACCESS,
  HOLIDAY_SETTING,
  MANAGE_SUBSCRIPTION,
  UPDATE_VENDOR_FIELD,
  VENDOR_ACCESS,
  UPDATE_VENDOR_FORM_LABEL,
  REVIEW_VENDOR_ACCESS,
};
