import React, { useState } from "react";
import { Modal, TextField, Checkbox, Select, Banner } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { baseHelper } from "lib/helpers";
import { GET_SHIPPING_REGION } from "app/shipping/apollo/queries";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import validate from "app/setup/modules/generic/addressBook/yup";

const AddressModal = (props) => {
  const { addressForm, setAddressForm, banner, setBanner, cms, isFirst } = props;
  const [defaultButton, setDefaultButton] = useState(false);
  const { label, address, city, country, postalCode, id, isDefault } = addressForm || {};
  const [error, setError] = useState(false);
  const { data: destinationData } = useQuery(GET_SHIPPING_REGION);
  const destinationDetails = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];
  const destinationSelect = [{ label: constant.SELECT_COUNTRY, value: "", disabled: true }];
  const destinations = [...destinationDetails];
  const destinationOptions = destinations.map(({ options, title }) => {
    if (title && options.length) {
      return {
        title,
        options: options.map(({ label: addressLabel }) => ({
          label: addressLabel,
          value: addressLabel,
        })),
      };
    }
    return null;
  });

  const handleValidation = async (key, value) => {
    const errorMessage = await validate(key, value, cms);
    setError((prevState) => ({
      ...prevState,
      [key]: errorMessage,
    }));
  };

  const handleCheckbox = async (value, field, addressId) => {
    setDefaultButton({ [id]: !defaultButton[addressId] });
    setAddressForm({ ...addressForm, isDefault: value, addressId });
  };

  const onHandleChanges = async (value, field, addressId) => {
    handleValidation();
    setAddressForm({ ...addressForm, [field]: value, addressId });
  };
  const toggleBanner = () => setBanner({ ...banner, isOpen: !banner.isOpen });

  return (
    <div>
      <Modal.Section>
        {banner.isOpen && (
          <>
            <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
            <br />
          </>
        )}
        <TextField
          type="text"
          label={cms("label.label")}
          placeholder={cms("placeholder.label")}
          value={label}
          onChange={(value) => onHandleChanges(value, "label", id)}
          error={error && !label ? error.label : ""}
          onBlur={() => {
            handleValidation("label", label.trim());
            onHandleChanges(label.trim(), "label", id);
          }}
          autoComplete="label"
        />
        <br />
        <TextField
          label={cms("label.address")}
          placeholder={cms("placeholder.address")}
          value={address}
          onChange={(value) => onHandleChanges(value, "address", id)}
          error={error && !address ? error.address : ""}
          onBlur={() => {
            handleValidation("address", address.trim());
            onHandleChanges(address.trim(), "address", id);
          }}
          autoComplete="addressLabel"
        />
        <br />
        <TextField
          label={cms("label.city")}
          placeholder={cms("placeholder.city")}
          value={city}
          onChange={(value) => onHandleChanges(value, "city", id)}
          error={error && !city ? error.city : ""}
          onBlur={() => {
            handleValidation("city", city.trim());
            onHandleChanges(city.trim(), "city", id);
          }}
          autoComplete="city"
        />
        <br />
        <Select
          label={cms("label.country")}
          options={[...destinationSelect, ...destinationOptions]}
          value={country}
          onChange={(value) => onHandleChanges(value, "country", id)}
          error={error && !country ? error.country : ""}
          onBlur={() => handleValidation("country", country)}
        />
        <br />
        <TextField
          label={cms("label.postal")}
          placeholder={cms("placeholder.portal")}
          value={postalCode}
          onChange={(value) => onHandleChanges(value, "postalCode", id)}
          error={error ? error.postalCode : ""}
          onBlur={() => {
            handleValidation("postalCode", postalCode.trim());
            onHandleChanges(postalCode.trim(), "postalCode", id);
          }}
          autoComplete="postal"
        />
        <br />
        <Checkbox
          label={cms("label.default")}
          checked={isFirst ? true : isDefault}
          onChange={(value) => handleCheckbox(value, "isDefault", id)}
        />
      </Modal.Section>
    </div>
  );
};
export default AddressModal;

AddressModal.propTypes = {
  addressForm: PropTypes.shape(),
  banner: PropTypes.shape(),
  cms: PropTypes.func,
  setAddressForm: PropTypes.func,
  setBanner: PropTypes.func,
};

AddressModal.defaultProps = {
  addressForm: {},
  banner: {},
  cms: () => {},
  setAddressForm: () => {},
  setBanner: () => {},
};
