import React, { useContext, useState, useEffect } from "react";
import { Card, Checkbox, RadioButton, Layout, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

// helper
import baseHelper from "lib/helpers/base";

import { Spinner, AutoComplete } from "lib/components";

import { useQuery } from "@apollo/react-hooks";

import { errorHelper, shippingHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import {
  GET_SHIPPING_OFFER,
  GET_SHIPPING,
  GET_SHIPPING_BAND,
  GET_SHIPPING_REGION,
  GET_SHIPPING_PRODUCT,
  GET_SHIPPING_RULE,
} from "app/shipping/apollo/queries";

const ManageAggregateShipping = (props) => {
  const { manageAggregateShippingSettingProps, setBannerStatus } = props;
  const { currentUser, cms = {} } = useContext(PrivateContext);

  const [isGlobalShipping, setIsGlobalShipping] = useState(false);

  const [isAllowShippingBands, setIsAllowShippingBands] = useState(false);
  const [isAllowShippingRules, setIsAllowShippingRules] = useState(false);
  const [isAllowShippingOffers, setIsAllowShippingOffers] = useState(false);

  const [isAllowAllBands, setIsAllowAllBands] = useState(true);
  const [isAllowAllRules, setIsAllowAllRules] = useState(true);
  const [isAllowAllOffers, setIsAllowAllOffers] = useState(true);

  const [isAllowSelected, setIsAllowSelected] = useState(false);
  const [isAllowAll, setIsAllowAll] = useState(false);

  const [isAllowSelectedBands, setIsAllowSelectedBands] = useState(false);
  const [isAllowSelectedRules, setIsAllowSelectedRules] = useState(false);
  const [isAllowSelectedOffers, setIsAllowSelectedOffers] = useState(false);

  const [bandIds, setBandIds] = useState([]);
  const [offerIds, setOfferIds] = useState([]);
  const [ruleIds, setRuleIds] = useState([]);

  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedBands, setSelectedBands] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);

  const isVendor = baseHelper.isVendor(currentUser);

  const { data: bandData, loading: bandLoading, error: bandError } = useQuery(GET_SHIPPING_BAND);

  // GET_SHIPPING_BAND data handling.
  const resBandData = baseHelper.getResponseData(bandData, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows: shippingBandRows = [] } = resBandData || {};
  const bandDetail = shippingBandRows.map((shippingBand) => {
    return {
      label: shippingBand.name,
      // eslint-disable-next-line no-underscore-dangle
      value: shippingBand._id,
    };
  });

  // GET_SHIPPING_BAND error handling.
  const resBandError = baseHelper.getResponseError(bandData, constant.gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (resBandError) {
      const banner = {
        title: resBandError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resBandError]);

  useEffect(() => {
    if (bandError) {
      const banner = {
        title: errorHelper.parse(bandError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bandError]);

  const { loading: destinationLoading, data: destinationData, error: destinationError } = useQuery(GET_SHIPPING_REGION);

  // shipping destination
  const destinationDataError = baseHelper.getResponseError(destinationData, constant.gql.GET_REGION);
  useEffect(() => {
    if (destinationDataError) {
      const banner = {
        title: destinationDataError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationDataError]);

  useEffect(() => {
    if (destinationError) {
      const banner = {
        title: errorHelper.parse(destinationError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationError]);
  const destinationDataResponse = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];

  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_PRODUCT);

  // shipping products
  const productDataError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      const banner = {
        title: productDataError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDataError]);

  useEffect(() => {
    if (productError) {
      const banner = {
        title: errorHelper.parse(productError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productError]);
  const productDataResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCTS) || [];

  const { data: offerData, loading: offerLoading, error: offerError } = useQuery(GET_SHIPPING_OFFER);

  // GET_SHIPPING_OFFER data handling.
  const resOfferData = baseHelper.getResponseData(offerData, constant.gql.GET_SHIPPING_OFFERS);
  const { shippingOffers: shippingOfferRows = [] } = resOfferData || {};
  const offerDetail = shippingHelper.getOfferLabel(
    shippingOfferRows,
    shippingBandRows,
    destinationDataResponse,
    productDataResponse
  );

  // GET_SHIPPING_OFFER error handling.
  const resOfferError = baseHelper.getResponseError(offerData, constant.gql.GET_SHIPPING_OFFERS);
  useEffect(() => {
    if (resOfferError) {
      const banner = {
        title: resOfferError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resOfferError]);

  useEffect(() => {
    if (offerError) {
      const banner = {
        title: errorHelper.parse(offerError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerError]);

  const { data: ruleData, loading: ruleLoading, error: ruleError } = useQuery(GET_SHIPPING_RULE);

  // GET_SHIPPING_RULE data handling.
  const resRuleData = baseHelper.getResponseData(ruleData, constant.gql.GET_SHIPPING_RULE);
  const { shippingRules: shippingRuleRows = [] } = resRuleData || {};
  const ruleDetail = shippingHelper.getRuleLabel(
    shippingRuleRows,
    shippingBandRows,
    destinationDataResponse,
    productDataResponse
  );

  // GET_SHIPPING_RULE error handling.
  const resRuleError = baseHelper.getResponseError(ruleData, constant.gql.GET_SHIPPING_RULE);
  useEffect(() => {
    if (resRuleError) {
      const banner = {
        title: resRuleError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resRuleError]);

  useEffect(() => {
    if (ruleError) {
      const banner = {
        title: errorHelper.parse(ruleError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleError]);

  const { data: shippingData, loading: shippingLoading, error: shippingError } = useQuery(GET_SHIPPING);

  // GET_SHIPPING data handling.
  const resShippingData = baseHelper.getResponseData(shippingData, constant.gql.GET_SHIPPING);

  useEffect(() => {
    if (resShippingData) {
      try {
        const { shipping = {} } = resShippingData || {};
        const { setting } = shipping || {};
        const {
          bandIds: bandIDs = [],
          offerIds: offerIDs = [],
          ruleIDs = [],
          isAllBand = false,
          isAllRule = false,
          isAllOffer = false,
        } = setting || {};

        const isAnyShippingBands = isAllBand || (bandIDs && bandIDs.length);
        const isAnyShippingRule = isAllRule || (ruleIDs && ruleIDs.length);
        const isAnyShippingOffer = isAllOffer || (offerIDs && offerIDs.length);

        const isGlobalShippings = setting ? !!(isAnyShippingBands || isAnyShippingRule || isAnyShippingOffer) : false;
        const isAllowedALL = setting ? isAllBand && isAllRule && isAllOffer : true;
        const isAllowedSelected = setting ? !isAllowedALL : false;

        const isAllowedSelectedBands = setting ? !!(bandIDs && bandIDs.length) : false;
        const isAllowedSelectedOffers = setting ? !!(offerIDs && offerIDs.length) : false;
        const isAllowedSelectedRules = setting ? !!(ruleIDs && ruleIDs.length) : false;

        setIsGlobalShipping(isGlobalShippings);

        setBandIds(bandIDs);
        setOfferIds(offerIDs);
        setRuleIds(ruleIDs);

        setIsAllowAll(isAllowedALL);
        setIsAllowSelected(isAllowedSelected);

        setSelectedBands(bandIDs);
        setSelectedOffers(offerIDs);
        setSelectedRules(ruleIDs);

        // handle selected-bands/offers/rules from bandIDs/offerIDs/ruleIDs.
        setIsAllowAllBands(!isAllowedSelectedBands);
        setIsAllowAllOffers(!isAllowedSelectedOffers);
        setIsAllowAllRules(!isAllowedSelectedRules);

        setIsAllowShippingBands(isAllowAllBands || (bandIDs && bandIDs.length));
        setIsAllowSelectedBands(bandIDs && bandIDs.length);
        setIsAllowShippingRules(isAllowAllRules || (ruleIDs && ruleIDs.length));
        setIsAllowSelectedRules(ruleIDs && ruleIDs.length);
        setIsAllowShippingOffers(isAllowAllOffers || (offerIDs && offerIDs.length));
        setIsAllowSelectedOffers(offerIDs && offerIDs.length);
      } catch (exception) {
        const banner = {
          title: errorHelper.parse(exception),
          status: constant.CRITICAL,
          isOpen: true,
        };
        setBannerStatus(banner);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resShippingData]);

  // GET_SHIPPING_RULE error handling.
  const resShippingError = baseHelper.getResponseError(shippingData, constant.gql.GET_SHIPPING);
  useEffect(() => {
    if (resShippingError) {
      const banner = {
        title: resShippingError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resShippingError]);

  useEffect(() => {
    if (shippingError) {
      const banner = {
        title: errorHelper.parse(shippingError),
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingError]);

  const loading = bandLoading || ruleLoading || offerLoading || shippingLoading || destinationLoading || productLoading;

  const genericLabel = `${(!isVendor && `${cms("operator.section.manageAggregate.description.bands")}`) || ""} ${cms(
    "operator.section.manageAggregate.description.rules"
  )} ${cms("operator.section.manageAggregate.description.offers")}`;

  const shippingDescription = `${cms("operator.section.manageAggregate.description.textStart")}
      ${genericLabel} ${cms("operator.section.manageAggregate.description.textEnd")}`;

  const allowSelected = `${cms("operator.section.manageAggregate.helpText.allowSelected")}
    ${genericLabel} ${cms("operator.section.manageAggregate.helpText.selectedTypeVendor")}`;
  const shippingLabel = `${cms("operator.section.manageAggregate.label.shipping")} ${genericLabel}`;

  const handleCumulativeRules = (selectedIds) => {
    setRuleIds(selectedIds);
    setSelectedRules(selectedIds);
  };
  const handleCumulativeBands = (selectedIds) => {
    setBandIds(selectedIds);
    setSelectedBands(selectedIds);
  };
  const handleCumulativeOffers = (selectedIds) => {
    setOfferIds(selectedIds);
    setSelectedOffers(selectedIds);
  };

  const handleChange = () => {
    let settingData = {};
    if (isGlobalShipping) {
      if (isAllowAll) {
        settingData = {
          isAllBand: true,
          isAllOffer: true,
          isAllRule: true,
          isAllVendor: true,
          bandIds: [],
          offerIds: [],
          ruleIds: [],
          vendorIds: [],
        };
      } else {
        if (isAllowShippingBands) {
          if (isAllowAllBands) {
            settingData.isAllBand = isAllowAllBands;
            settingData.bandIds = [];
          } else {
            settingData.isAllBand = false;
            settingData.bandIds = selectedBands || bandIds;
          }
        }

        if (isAllowShippingRules) {
          if (isAllowAllRules) {
            settingData.isAllRule = isAllowAllRules;
            settingData.ruleIds = [];
          } else {
            settingData.isAllRule = false;
            settingData.ruleIds = selectedRules || ruleIds;
          }
        }

        if (isAllowShippingOffers) {
          if (isAllowAllOffers) {
            settingData.isAllOffer = isAllowAllOffers;
            settingData.offerIds = [];
          } else {
            settingData.isAllOffer = false;
            settingData.offerIds = selectedOffers || offerIds;
          }
        }
      }
    } else {
      settingData = {
        bandIds: [],
        offerIds: [],
        ruleIds: [],
        vendorIds: [],
      };
    }
    return manageAggregateShippingSettingProps(settingData);
  };

  useEffect(() => {
    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllowAllBands,
    isAllowAllOffers,
    isAllowAllRules,
    selectedRules,
    selectedBands,
    selectedOffers,
    isGlobalShipping,
  ]);

  return (
    <Layout.AnnotatedSection title="" description={shippingDescription}>
      {(loading && <Spinner />) || (
        <Card sectioned>
          <Checkbox
            checked={isGlobalShipping}
            onChange={() => setIsGlobalShipping(!isGlobalShipping)}
            label={shippingLabel}
          />
          {isGlobalShipping && (
            <div>
              <RadioButton
                label={cms("operator.section.manageAggregate.label.allTypeVendor")}
                helpText={cms("operator.section.manageAggregate.helpText.allTypeVendor")}
                checked={isAllowAll}
                onChange={() => {
                  setIsAllowAll(true);
                  setIsAllowSelected(false);
                }}
              />

              <RadioButton
                label={cms("operator.section.manageAggregate.label.selectedTypeVendor")}
                helpText={allowSelected}
                checked={isAllowSelected}
                onChange={() => {
                  setIsAllowSelected(true);
                  setIsAllowAll(false);
                }}
              />

              {isAllowSelected && (
                <Card sectioned>
                  <Stack.Item>
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.bands")}
                      checked={isAllowShippingBands}
                      onChange={() => setIsAllowShippingBands(!isAllowShippingBands)}
                    />
                    {isAllowShippingBands && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allBands")}
                          checked={isAllowAllBands}
                          onChange={() => {
                            setIsAllowAllBands(true);
                            setIsAllowSelectedBands(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedBands")}
                          checked={isAllowSelectedBands}
                          onChange={() => {
                            setIsAllowSelectedBands(true);
                            setIsAllowAllBands(false);
                          }}
                        />
                        {!!isAllowSelectedBands && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.bands")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.bands")}
                            values={bandDetail}
                            selected={bandIds}
                            onChange={handleCumulativeBands}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                    <br />
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.rules")}
                      checked={isAllowShippingRules}
                      onChange={() => setIsAllowShippingRules(!isAllowShippingRules)}
                    />
                    {isAllowShippingRules && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allRules")}
                          checked={isAllowAllRules}
                          onChange={() => {
                            setIsAllowAllRules(true);
                            setIsAllowSelectedRules(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedRules")}
                          checked={isAllowSelectedRules}
                          onChange={() => {
                            setIsAllowSelectedRules(true);
                            setIsAllowAllRules(false);
                          }}
                        />
                        {!!isAllowSelectedRules && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.rules")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.rules")}
                            values={ruleDetail}
                            selected={ruleIds}
                            onChange={handleCumulativeRules}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                    <br />
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.offers")}
                      checked={isAllowShippingOffers}
                      onChange={() => setIsAllowShippingOffers(!isAllowShippingOffers)}
                    />
                    <br />
                    {isAllowShippingOffers && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allOffers")}
                          checked={isAllowAllOffers}
                          onChange={() => {
                            setIsAllowAllOffers(true);
                            setIsAllowSelectedOffers(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedOffers")}
                          checked={isAllowSelectedOffers}
                          onChange={() => {
                            setIsAllowSelectedOffers(true);
                            setIsAllowAllOffers(false);
                          }}
                        />
                        {!!isAllowSelectedOffers && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.offers")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.offers")}
                            values={offerDetail}
                            selected={offerIds}
                            onChange={handleCumulativeOffers}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                  </Stack.Item>
                </Card>
              )}
            </div>
          )}
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
};

ManageAggregateShipping.propTypes = {
  manageAggregateShippingSettingProps: PropTypes.func.isRequired,
  setBannerStatus: PropTypes.func.isRequired,
};

export default ManageAggregateShipping;
