import { gql } from "apollo-boost";

const FETCH_VENDOR_ACCESS = gql`
  query getVendorAccessSetting {
    getVendorAccessSetting {
      data {
        updatedAt
        access {
          product {
            isFullAccess
            isHide
            isReadOnly
          }
          order {
            isFullAccess
            isHide
            isReadOnly
          }
          payment {
            isFullAccess
            isHide
            isReadOnly
          }
          updatedAt
        }
        advanceAccess {
          order {
            isFullAccess
            isHide
            isReadOnly
          }
          payment {
            isFullAccess
            isHide
            isReadOnly
          }
          product {
            isFullAccess
            isHide
            isReadOnly
          }
          vendorId
        }
        review {
          isEnabled
          welcomeText
        }
      }
      status
      error
    }
  }
`;
export default FETCH_VENDOR_ACCESS;
