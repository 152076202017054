import React from "react";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import generic component
import { ContactUs } from "app/userManagement/modules/generic";

const OperatorContactUs = () => {
  return <ContactUs />;
};

export default withFeature(withErrorBoundary(OperatorContactUs), { feature: constant.SUPPORT });
