// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
// import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// Import Polaris Components
import { TextField, Card, Stack, PageActions, TextContainer } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { IS_WOOCOMMERCE_STORE_CONNECTED } from "app/productOld/apollo/mutations/wooCommerceConnect";

import { SyncWooCommerceProduct } from "app/productOld/modules/provider/features/subFeatures";

import TermModal from "app/product/modules/provider/features/genric/term/term";

// import components
import ConnectedShop from "./subFeatureItems/connected";

const VendorConnectWoocommerce = (props) => {
  const { cms, currentUser: currentUserData, history } = useContext(PrivateContext);
  const { wooCommerce: wooCommerceConfig = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const { shop = "", accessKey = "", accessToken = "" } = wooCommerceConfig || {};
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { setBanner } = props;
  const [value, setValue] = useState({
    consumerKey: accessKey || "",
    consumerSecret: accessToken || "",
    shopDomain: shop || "",
  });
  const [isConnected, setIsConnected] = useState(!!shop);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    shopDomain: "",
    consumerKey: "",
    consumerSecret: "",
  });

  const [isWooCommerceConnected, { loading }] = useMutation(IS_WOOCOMMERCE_STORE_CONNECTED);
  const { IS_WOOCOMMERCE_STORE_CONNECTED: STORE_CONNECT } = constant.gql;

  const onSubmit = async () => {
    const { consumerKey = "", consumerSecret = "", shopDomain = "" } = value;
    const isValidSubDomain = baseHelper.isValidSubDomain(shopDomain.trim());
    if (!isValidSubDomain) {
      setBanner({ isOpen: true, status: "critical", title: cms("section.connect.message.error.invalidShopDomain") });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isWooCommerceConnected({
        variables: {
          input: {
            consumerKey: (consumerKey || "").trim(),
            consumerSecret: (consumerSecret || "").trim(),
            shopDomain: (shopDomain || "").trim(),
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        setIsOpen(false);
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, STORE_CONNECT);
      if (responseData) {
        setIsConnected(true);
        setBanner({ isOpen: true, status: "success", title: `${cms("section.connect.message.success.connected")}` });
        setIsOpen(false);
        return;
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const handleTermModal = (fieldName = "check", modalValue = "") => {
    if (!value.shopDomain || !value.consumerSecret || !value.consumerKey) {
      setIsOpen(false);
      const errorMessages = {
        consumerKey: cms("common.message.error.required", { item: "Consumer Key" }),
        consumerSecret: cms("common.message.error.required", { item: "Consumer Secret" }),
        shopDomain: cms("common.message.error.required", { item: "Shop Domain" }),
      };
      setErrorMessage({
        ...errorMessage,
        [fieldName]: !value[fieldName] ? errorMessages[fieldName] : "",
      });

      return;
    }
    if (modalValue === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  const bottomSpace = {
    marginBottom: "10px",
  };

  return (
    <>
      <TermModal
        isOpen={isOpen}
        cms={cms}
        setIsOpen={setIsOpen}
        shopDomain={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.WOOCOMMERCE_PASCAL_CASE}
      />
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <div style={bottomSpace}>
            <TextField
              label={`${cms("section.connect.label.shopDomain")}*`}
              error={errorMessage.shopDomain}
              id="shopInput"
              prefix="https://"
              placeholder="demo-example.com"
              type={constant.TEXT}
              value={value.shopDomain}
              onChange={(inputValue) => handleChange(constant.SHOP_DOMAIN, inputValue)}
              onBlur={() => handleTermModal(constant.SHOP_DOMAIN)}
              disabled={isConnected}
            />
          </div>
          {!isConnected ? (
            <div style={bottomSpace}>
              <TextField
                label={`${cms("section.connect.label.wooCommerceConsumerKey")}*`}
                error={errorMessage.consumerKey}
                id="consumerKey"
                placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                type={constant.TEXT}
                value={value.consumerKey}
                onChange={(inputValue) => handleChange(constant.CONSUMER_KEY, inputValue)}
                onBlur={() => handleTermModal(constant.CONSUMER_KEY)}
                disabled={isConnected}
              />
            </div>
          ) : null}
          {!isConnected ? (
            <TextField
              label={`${cms("section.connect.label.wooCommerceConsumerSecret")}*`}
              error={errorMessage.consumerSecret}
              id="consumerSecret"
              placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              type={constant.TEXT}
              value={value.consumerSecret}
              onChange={(inputValue) => handleChange(constant.CONSUMER_SECRET, inputValue)}
              onBlur={() => handleTermModal(constant.CONSUMER_SECRET)}
              disabled={isConnected}
            />
          ) : null}
          {isConnected && (
            <Stack>
              <Stack.Item fill>
                <ConnectedShop
                  cms={cms}
                  currentUserData={currentUserData}
                  setBanner={setBanner}
                  setIsConnected={setIsConnected}
                  setValue={setValue}
                  value={value}
                />
              </Stack.Item>
              <Stack.Item>
                <SyncWooCommerceProduct history={history} setBanner={setBanner} cms={cms} />
              </Stack.Item>
            </Stack>
          )}
          {!isConnected && (
            <PageActions
              primaryAction={{
                id: "connectShop",
                content: `${cms("section.connect.button.connect")}`,
                onAction: () => handleTermModal("check ", constant.CONNECT),
                disabled: isConnected,
              }}
              secondaryActions={[
                {
                  id: "cancelAction",
                  content: `${cms("section.connect.button.cancel")}`,
                  onAction: () => (isOnboarding && onPrevious()) || history.push("/"),
                },
              ]}
            />
          )}
        </Card.Section>
      </Card>
    </>
  );
};

VendorConnectWoocommerce.propTypes = {
  setBanner: () => {},
};

VendorConnectWoocommerce.defaultProps = {
  setBanner: () => {},
};

export default withFeature(VendorConnectWoocommerce, {
  feature: constant.WOOCOMMERCE,
  // subFeature: constant.WOOCOMMERCE,
});
