import React from "react";

import PaymentExportSetting from "app/payments/modules/generic/exportSetting/payment";

import { withErrorBoundary } from "lib/hoc";

const AdminPaymentExportSetting = () => {
  return <PaymentExportSetting />;
};

export default withErrorBoundary(AdminPaymentExportSetting);
