import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";

// Import Polaris Components
import { Stack, Modal } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  SYNC_INVENTORY_VENDOR,
  DISCONNECT_SHOP,
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT,
} from "app/productOld/apollo/mutations/connect";
import { DISCONNECT_WOOCOMMERCE_SHOP } from "app/productOld/apollo/mutations/wooCommerceConnect";
import {
  DISCONNECT_MAGENTO_SHOP,
  DISCONNECT_WIX_SHOP,
  DISCONNECT_PRESTASHOP,
  SYNC_VENDOR_MAGENTO_PRODUCT,
  SYNC_VENDOR_WIX_PRODUCT,
  SYNC_VENDOR_PRESTASHOP_PRODUCT,
} from "app/product/apollo/mutations";

// import report
import { QuickAction, QuickReport } from "app/reports";
import { AddProductMajor, ImportStoreMajor, OnlineStoreMajor, StoreMajor } from "@shopify/polaris-icons";

const {
  gql: {
    SYNC_INVENTORY_VENDOR: SYNC_INVENTORY_VENDOR_GQL,
    DISCONNECT_SHOP: DISCONNECT,
    DISCONNECT_WOOCOMMERCE_SHOP: DISCONNECT_WOO_SHOP,
    DISCONNECT_WIX_SHOP: DISCONNECT_WIX,
    DISCONNECT_MAGENTO_SHOP: DISCONNECT_MAGENTO,
    DISCONNECT_PRESTASHOP: DISCONNECT_PRESTA_SHOP, 
    SYNC_WIX_VENDOR_PRODUCT,
    SYNC_WOOCOMMERCE_VENDOR_PRODUCT: SYNC_WOOCOMMERCE_VENDOR_PRODUCT_GQL,
    SYNC_VENDOR_MAGENTO_PRODUCT: SYNC_MAGENTO_VENDOR_PRODUCT_GQL,
    SYNC_VENDOR_PRESTASHOP_PRODUCT: SYNC_PRESTASHOP_VENDOR_PRODUCT_GQL,
  },
} = constant;

const Widget = (props) => {
  const { setBanner = () => {} } = props;
  const { cms, currentUser = {}, history = {}, currentUserRefetch = () => {} } = useContext(PrivateContext);

  const { shop = null } = currentUser;
  const {
    magento: magentoConfig = {},
    wix: wixConfig = {},
    wooCommerce: wooCommerceConfig = {},
    prestaShop: prestaShopConfig = {},
  } = (currentUser && currentUser.storeConfig) || {};
  const { shop: woocommerceVendorShop = "" } = wooCommerceConfig || {};
  const { shop: magentoVendorShop = "" } = magentoConfig || {};
  const { shop: prestaShopVendorShop = "" } = prestaShopConfig || {};
  const { instanceId: wixInstanceId = "", accessKey: wixAccessKey = "" } = wixConfig || {};

  const [syncInventoryVendor, { loading: syncInventoryVendorLoading }] = useMutation(SYNC_INVENTORY_VENDOR);
  const [syncWooCommerceVendorProduct, { loading: syncWooCommerceVendorProductLoading }] = useMutation(
    SYNC_WOOCOMMERCE_VENDOR_PRODUCT
  );
  const [syncVendorWixProduct, { loading: syncVendorWixProductLoading }] = useMutation(SYNC_VENDOR_WIX_PRODUCT);
  const [syncVendorMagentoProduct, { loading: syncVendorMagentoProductLoading }] = useMutation(SYNC_VENDOR_MAGENTO_PRODUCT);
  const [syncVendorPrestaShopProduct, { loading: syncVendorPrestaShopProductLoading }] = useMutation(SYNC_VENDOR_PRESTASHOP_PRODUCT);
  const [disconnectShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_SHOP);
  const [disconnectWooCommerceShop, { loading: disconnectWooCommerceLoading }] = useMutation(
    DISCONNECT_WOOCOMMERCE_SHOP
  );
  const [disconnectWixShop, { loading: disconnectWixLoading }] = useMutation(DISCONNECT_WIX_SHOP);
  const [disconnectMagentoShop, { loading: disconnectMagentoLoading }] = useMutation(DISCONNECT_MAGENTO_SHOP);
  const [disconnectPrestaShop, { loading: disconnectPrestaShopLoading }] = useMutation(DISCONNECT_PRESTASHOP);

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);
  const [isWooCommerceDisconnectActive, setIsWooCommerceDisconnectActive] = useState(false);
  const [isMagentoDisconnectActive, setIsMagentoDisconnectActive] = useState(false);
  const [isPrestaShopDisconnectActive, setIsPrestaShopDisconnectActive] = useState(false);
  const [isWixDisconnectActive, setWixDisconnectActive] = useState(false);

  if (!(shop || woocommerceVendorShop || magentoVendorShop || (wixInstanceId && wixAccessKey) || prestaShopVendorShop)) {
    return (
      <>
        <QuickReport />
        <QuickAction />
      </>
    );
  }

  const syncProducts = async () => {
    try {
      const response = await syncInventoryVendor();
      const responseError = baseHelper.getResponseError(response.data, SYNC_INVENTORY_VENDOR_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/shopify");
      const bannerAction = {
        onAction,
        content: cms("section.sync.label.view"),
      };
      setBanner({
        isOpen: true,
        status: constant.INFO,
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncWooCommerceProducts = async () => {
    try {
      const response = await syncWooCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_WOOCOMMERCE_VENDOR_PRODUCT_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/woocommerce");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWoocommerceProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: constant.INFO,
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncWixProducts = async () => {
    try {
      const response = await syncVendorWixProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_WIX_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/wix");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWixProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncMagentoProducts = async () => {
    try {
      const response = await syncVendorMagentoProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_MAGENTO_VENDOR_PRODUCT_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/magento");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWixProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncPrestaShopProducts = async () => {
    try {
      const response = await syncVendorPrestaShopProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_PRESTASHOP_VENDOR_PRODUCT_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/prestashop");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWixProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectShop({
        variables: { input: { id: currentUser.id } },
      });
      setIsDisconnectActive(!isDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.disconnect") });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectWooCommerceShopConfirm = async () => {
    try {
      const response = await disconnectWooCommerceShop();
      setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_WOO_SHOP);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("section.connect.message.success.disconnected")}`,
      });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectWixShopConfirm = async () => {
    try {
      const response = await disconnectWixShop();
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_WIX);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, DISCONNECT_WIX);
      if (responseData) {
        setWixDisconnectActive(!isWixDisconnectActive);
        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title: `${cms("section.connect.message.success.disconnected")}`,
        });
        setTimeout(() => {
          currentUserRefetch();
        }, 1000);
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectMagentoShopConfirm = async () => {
    try {
      const response = await disconnectMagentoShop();
      setIsMagentoDisconnectActive(!isMagentoDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_MAGENTO);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("section.connect.message.success.disconnected")}`,
      });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectPrestaShopConfirm = async () => {
    try {
      const response = await disconnectPrestaShop();
      setIsPrestaShopDisconnectActive(!isPrestaShopDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_PRESTA_SHOP);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("section.connect.message.success.disconnected")}`,
      });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const linkData = [
    {
      action: () => history.push("/products/add"),
      caption: cms("section.caption.add"),
      id: "addProduct",
      icon: AddProductMajor,
      label: cms("section.connect.label.add"),
      primary: true,
      shop: true,
    },
    {
      action: () => history.push("/products/edit"),
      caption: cms("section.caption.edit"),
      id: "editProducts",
      primary: true,
      label: cms("label.editProduct"),
    },
  ];
  const storedata = [];
  if (shop) {
    storedata.unshift(
      {
        action: () => syncProducts(),
        caption: cms("section.sync.label.import"),
        id: "importProduct",
        label: cms("section.connect.label.import"),
        icon: ImportStoreMajor,
        loading: syncInventoryVendorLoading,
        shop,
      },
      {
        action: () => history.push("/products/shopify"),
        caption: cms("section.connect.label.view"),
        id: "storeProduct",
        label: cms("section.connect.label.view"),
        icon: OnlineStoreMajor,
        shop,
      },
      {
        action: () => setIsDisconnectActive(!isDisconnectActive),
        caption: `${cms("section.disconnect.label.disconnectShop", { shop })}`,
        id: "disconnectShop",
        label: `${cms("section.disconnect.label.disconnect", { shop })}`,
        icon: StoreMajor,
        shop,
      }
    );
  }

  if (woocommerceVendorShop) {
    storedata.unshift(
      {
        action: () => syncWooCommerceProducts(),
        caption: cms("section.connect.label.importWooCommerce"),
        id: "importProduct",
        icon: ImportStoreMajor,
        label: cms("section.connect.label.importWooCommerce"),
        loading: syncWooCommerceVendorProductLoading,
        shop: woocommerceVendorShop,
      },
      {
        action: () => history.push("/products/woocommerce"),
        caption: cms("section.connect.label.view"),
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: cms("section.connect.label.wooCommerce"),
        shop: woocommerceVendorShop,
      },
      {
        action: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
        caption: `${cms("section.disconnect.label.wooCommerce", { woocommerceVendorShop })}`,
        id: "disconnectWooCommerceShop",
        icon: StoreMajor,
        label: `${cms("section.disconnect.label.wooCommerceShop", { woocommerceVendorShop })}`,
        shop: woocommerceVendorShop,
      }
    );
  }

  if (wixInstanceId && wixAccessKey) {
    storedata.unshift(
      {
        action: () => syncWixProducts(),
        caption: cms("section.connect.label.importWix"),
        id: "importProduct",
        icon: ImportStoreMajor,
        label: cms("section.connect.label.importWix"),
        loading: syncVendorWixProductLoading,
        shop: true,
      },
      {
        action: () => history.push("/products/wix"),
        caption: cms("section.connect.label.viewWix"),
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: cms("section.connect.label.wix"),
        shop: true,
      },
      {
        action: () => setWixDisconnectActive(!isWixDisconnectActive),
        caption: cms("section.disconnect.label.wix"),
        id: "disconnectWixShop",
        icon: StoreMajor,
        label: cms("section.disconnect.label.wixShop"),
        shop: true,
      }
    );
  }

  if (magentoVendorShop) {
    storedata.unshift(
      {
        action: () => syncMagentoProducts(),
        caption: "Import Magento products",
        id: "importProduct",
        icon: ImportStoreMajor,
        label: "Import Magento products",
        loading: syncVendorMagentoProductLoading,
        shop: true,
      },
      {
        action: () => history.push("/products/magento"),
        caption: "View products from your Magento store",
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: "View products from your Magento store",
        shop: true,
      },
      {
        action: () => setIsMagentoDisconnectActive(!isMagentoDisconnectActive),
        caption: `${cms("section.disconnect.label.wooCommerce", { woocommerceVendorShop: magentoVendorShop })}`,
        id: "disconnectWooCommerceShop",
        icon: StoreMajor,
        label: `${cms("section.disconnect.label.wooCommerceShop", { woocommerceVendorShop: magentoVendorShop })}`,
        shop: magentoVendorShop,
      }
    );
  }

  if (prestaShopVendorShop) {
    storedata.unshift(
      {
        action: () => syncPrestaShopProducts(),
        caption: "Import Presta Shop products",
        id: "importProduct",
        icon: ImportStoreMajor,
        label: "Import Presta Shop products",
        loading: syncVendorPrestaShopProductLoading,
        shop: true,
      },
      {
        action: () => history.push("/products/prestashop"),
        caption: "View products from your Presta Shop store",
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: "View products from your Presta Shop store",
        shop: true,
      },
      {
        action: () => setIsPrestaShopDisconnectActive(!isPrestaShopDisconnectActive),
        caption: `${cms("section.disconnect.label.wooCommerce", { woocommerceVendorShop: prestaShopVendorShop })}`,
        id: "disconnectWooCommerceShop",
        icon: StoreMajor,
        label: `${cms("section.disconnect.label.wooCommerceShop", { woocommerceVendorShop: prestaShopVendorShop })}`,
        shop: magentoVendorShop,
      }
    );
  }

  return (
    <>
      <Stack.Item>
        <Modal
          open={isDisconnectActive}
          onClose={() => setIsDisconnectActive(!isDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.sync.label.disconnect")} ${currentUser.shop}`,
            onAction: disconnectShopConfirm,
            destructive: true,
            loading: disconnectLoading,
          }}
          secondaryActions={[
            {
              content: cms("button.cancel"),
              onAction: () => setIsDisconnectActive(!isDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.sync.label.disconnectShop")}`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isWooCommerceDisconnectActive}
          onClose={() => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectWooCommerceShopConfirm,
            destructive: true,
            loading: disconnectWooCommerceLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.connect.label.disconnectShop")} ${woocommerceVendorShop}?`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isMagentoDisconnectActive}
          onClose={() => setIsMagentoDisconnectActive(!isMagentoDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectMagentoShopConfirm,
            destructive: true,
            loading: disconnectMagentoLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setIsMagentoDisconnectActive(!isMagentoDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.connect.label.disconnectShop")} ${magentoVendorShop}?`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isPrestaShopDisconnectActive}
          onClose={() => setIsPrestaShopDisconnectActive(!isPrestaShopDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectPrestaShopConfirm,
            destructive: true,
            loading: disconnectPrestaShopLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setIsPrestaShopDisconnectActive(!isPrestaShopDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.connect.label.disconnectShop")} ${magentoVendorShop}?`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isWixDisconnectActive}
          onClose={() => setWixDisconnectActive(!isWixDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectWixShopConfirm,
            destructive: true,
            loading: disconnectWixLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setWixDisconnectActive(!isWixDisconnectActive),
            },
          ]}
        >
          <Modal.Section>
            {`${cms("section.connect.label.disconnectShop")} ${cms("section.connect.label.wixShop")}?`}
          </Modal.Section>
        </Modal>
      </Stack.Item>

      <QuickReport />
      <QuickAction link={linkData} storedata={storedata} />
    </>
  );
};

Widget.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default Widget;
