import PropTypes from "prop-types";

const editProp = {
  type: {
    editModal: PropTypes.bool,
    destinationData: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
    bands: PropTypes.arrayOf(PropTypes.object),
    item: PropTypes.object,
    moneyFormat: PropTypes.string,
    toggleEditModal: PropTypes.func,
    setBannerOuter: PropTypes.func,
    refetch: PropTypes.func,
  },
};
const deleteProp = {
  type: {
    deleteModal: PropTypes.bool,
    destinationData: PropTypes.arrayOf(PropTypes.object),
    bands: PropTypes.arrayOf(PropTypes.object),
    toggleDeleteModal: PropTypes.func,
    setBannerOuter: PropTypes.func,
    shippingBandId: PropTypes.string,
    refetch: PropTypes.func,
  },
};

const offerItemProp = {
  type: {
    moneyFormat: PropTypes.string,
    item: PropTypes.object,
    bands: PropTypes.string,
    destinations: PropTypes.string,
    products: PropTypes.string,
    toggleModal: PropTypes.func,
    toggleDeleteModal: PropTypes.func,
  },
};
const addProp = {
  type: {
    addModal: PropTypes.bool,
    destinationData: PropTypes.arrayOf(PropTypes.object),
    shippingBands: PropTypes.arrayOf(PropTypes.object),
    toggleAddModal: PropTypes.func,
    setBannerOuter: PropTypes.func,
    refetch: PropTypes.func,
  },
};
const providerProp = {
  type: {
    setBannerOuter: PropTypes.func,
  },
};

const actionProp = {
  type: {
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  },
};
const renderConditon = {
  type: {
    selectedDropdown: PropTypes.string,
    shippingOffer: PropTypes.object,
    checkedCondition: PropTypes.bool,
    handleSelectChangeDropdown: PropTypes.func,
    offerCriteria: PropTypes.string,
    offerCriteriaValue: PropTypes.string,
    handleCriteriaValue: PropTypes.func,
    handleCriteria: PropTypes.func,
    handleCondition: PropTypes.func,
    moneyFormat: PropTypes.string,
  },
};
const renderDetail = {
  type: {
    isCumulative: PropTypes.bool,
    handleCost: PropTypes.func,
    handleDescription: PropTypes.func,
    handleType: PropTypes.func,
    priceType: PropTypes.string,
    description: PropTypes.string,
    toggleCumulativeShipping: PropTypes.func,
    isOrderValue: PropTypes.bool,
    moneyFormat: PropTypes.string,
    priceValue: PropTypes.string,
  },
};
const renderDestination = {
  type: {
    activator: PropTypes.object,
    checkedDestination: PropTypes.bool,
    destinationCodes: PropTypes.arrayOf(PropTypes.object),
    destinationData: PropTypes.arrayOf(PropTypes.object),
    handleDestination: PropTypes.func,
    handleShippingDestination: PropTypes.func,
    popoverActive: PropTypes.bool,
    renderRegion: PropTypes.func,
    togglePopoverActive: PropTypes.func,
  },
};
const destinationProp = {
  type: {
    destinationData: PropTypes.arrayOf(PropTypes.object),
    destinationCodes: PropTypes.arrayOf(PropTypes.object),
  },
};

export {
  actionProp,
  renderDestination,
  renderDetail,
  editProp,
  renderConditon,
  offerItemProp,
  providerProp,
  addProp,
  deleteProp,
  destinationProp,
};
