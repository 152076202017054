import React from "react";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";

const Shipping = (props) => {
  const {
    acceptOnlyValidInput = () => {},
    handleChange = () => {},
    variantData = {},
    handleTitleChange = () => {},
    handleMeasurementChange = () => {},
    cms,
    setDisabledButton,
  } = props;

  const {
    value: { MAX_MEASUREMENT = 999 },
  } = constant;

  const { isShipping = false, weight = "", weightUnit = "", measurement = {} } = variantData || {};
  const options = constant.OPTIONS;
  const selectWeight = (
    <Select
      id="selectWeight"
      value={weightUnit || ""}
      label={cms("section.shipping.label.select")}
      placeholder={cms("section.shipping.placeholder.select")}
      onChange={(value) => handleTitleChange(constant.WEIGHT_UNIT, value)}
      labelHidden
      options={options}
    />
  );

  const renderMeasurement = () => {
    const { length = {}, width = {}, height = {} } = measurement || {};
    const { value: lengthValue = 0 } = length || {};
    const { value: widthValue = 0 } = width || {};
    const { value: heightValue = 0 } = height || {};
    return (
      <>
        <TextField
          id="length"
          label="Length"
          value={(lengthValue && lengthValue.toString()) || ""}
          placeholder="Length (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleMeasurementChange(constant.LENGTH, acceptOnlyValidInput(value, lengthValue));
            setDisabledButton(false);
          }}
        />
        <br />
        <TextField
          id="width"
          label="Width"
          value={(widthValue && widthValue.toString()) || ""}
          placeholder="Width (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleMeasurementChange(constant.WIDTH, acceptOnlyValidInput(value, widthValue));
            setDisabledButton(false);
          }}
        />
        <br />
        <TextField
          id="height"
          label="Height"
          value={(heightValue && heightValue.toString()) || ""}
          placeholder="Height (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => {
            handleMeasurementChange(constant.HEIGHT, acceptOnlyValidInput(value, heightValue));
            setDisabledButton(false);
          }}
        />
      </>
    );
  };

  return (
    <Card title="Shipping" id="variantShipping">
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label="This is a physical product"
          checked={isShipping}
          onChange={(value) => {
            handleTitleChange(constant.IS_SHIPPING, value);
            setDisabledButton(false);
            if (!value) {
              handleTitleChange(constant.WEIGHT, null);
              handleTitleChange(constant.WEIGHT_UNIT, constant.weightValue.LB);
            }
          }}
        />
      </Card.Section>
      {isShipping && (
        <Card.Section title="WEIGHT">
          <p>Used to calculate shipping rates at checkout.</p>
          <br />
          <TextField
            id="weight"
            label="Weight"
            value={(weight && weight.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            onChange={(value) => {
              handleChange(constant.WEIGHT, acceptOnlyValidInput(value, weight));
              setDisabledButton(false);
            }}
            connectedRight={selectWeight}
          />
          <br />
          {renderMeasurement()}
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  acceptOnlyValidInput: PropTypes.func.isRequired,
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleMeasurementChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Shipping;
