import React from "react";
import { AdminMaintenance } from "app/userManagement/modules/admin/features";
import { OperatorMaintenance } from "app/userManagement/modules/operator/features";
import { ProviderMaintenance } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Maintenance = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminMaintenance />;
    }
    if (role === operator) {
      return <OperatorMaintenance />;
    }
    if (role === provider) {
      return <ProviderMaintenance />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const MaintenanceComponent = getComponent(currentUserRole);
  return MaintenanceComponent;
};

export default Maintenance;
