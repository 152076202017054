import React, { useState, useCallback } from "react";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Modal } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_ADDRESS } from "app/setup/apollo";
import AddressModal from "app/setup/modules/generic/addressBook/subFeature/generic/addressModal";

const Edit = (props) => {
  const {
    addressDataResponse,
    addressItem,
    cms,
    editActive,
    editAddressForm,
    refetch,
    setBanner,
    setEditActive,
    setEditAddressForm,
    total,
  } = props;
  const { _id: id } = addressDataResponse;
  const addressRequestData = () => {
    const { _id: addressId, label, address, city, country, postalCode, isDefault } = editAddressForm;
    return {
      addressId,
      label,
      address,
      isDefault,
      city,
      country,
      postalCode,
    };
  };
  const [error, setError] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [editAddress, setEditAddress] = useState({ ...addressItem });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditChange = useCallback(() => setEditActive(!editActive), [editActive]);

  const [editAddressItem, { loading: editLoading }] = useMutation(EDIT_ADDRESS);
  const onEditButton = async () => {
    const requestData = addressRequestData();
    const addressLabels = addressDataResponse.map((item) => item && item.label);
    const { label = "" } = requestData || {};

    if (addressLabels.includes(label)) {
      setError({ isOpen: true, status: constant.CRITICAL, title: "Duplicate address label found." });
      return;
    }
    try {
      const val = await editAddressItem({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(val.data, constant.EDIT_ADDRESS);
      if (responseError) {
        setError({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setEditAddress({ ...addressItem });
      setEditActive(!editActive);
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.edit") });
      refetch();
    } catch (exception) {
      setError({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const isFormValid = () => {
    const { address = "", city = "", country = "", label = "", postalCode = "" } = editAddressForm || {};
    return label && address && city && country && postalCode;
  };

  return (
    <div>
      <Modal
        open={editActive}
        onClose={handleEditChange}
        title={cms("section.edit.title")}
        primaryAction={{
          content: cms("button.edit.primary"),
          loading: editLoading,
          disabled: !isFormValid(),
          onAction: () => onEditButton(id),
        }}
      >
        <AddressModal
          addressForm={editAddressForm}
          banner={error}
          cms={cms}
          editActive={editActive}
          editAddress={editAddress}
          setAddressForm={setEditAddressForm}
          setBanner={setError}
          total={total}
          isFirst={false}
        />
      </Modal>
    </div>
  );
};
export default Edit;

Edit.propTypes = {
  addressDataResponse: PropTypes.objectOf(PropTypes.string),
  addressItem: PropTypes.objectOf(PropTypes.string),
  cms: PropTypes.func,
  editActive: PropTypes.bool,
  editAddressForm: PropTypes.objectOf(PropTypes.string),
  refetch: PropTypes.func,
  setBanner: PropTypes.func,
  setEditActive: PropTypes.func,
  setEditAddressForm: PropTypes.func,
  total: PropTypes.number,
};

Edit.defaultProps = {
  addressDataResponse: {},
  addressItem: {},
  cms: () => {},
  editActive: null,
  editAddressForm: {},
  refetch: () => {},
  setBanner: () => {},
  setEditActive: () => {},
  setEditAddressForm: () => {},
  total: "",
};
