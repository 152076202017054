import styled from "styled-components";

export const StyledOrderVersioning = styled.div`
  .itemcollapse {
    padding-right: 2rem;
    padding-left: 5rem;
    .Polaris-Scrollable--hasTopShadow,
    .Polaris-Scrollable--hasBottomShadow {
      box-shadow: none;
    }
    .Polaris-Scrollable:focus {
      outline: none;
    }
    .history-items-after-version .Polaris-Button {
      padding-right: 4px;
    }
  }
  .history-items-after-version {
    float: right;
    margin-bottom: 15px;
  }
  .scrollable {
    max-height: 255px;
    overflow-y: auto;
    margin-top: 20px;
  }
  .scrollbar__auto-false {
    overflow-y: unset;
  }
  .itemcollapse-history {
    .Polaris-Scrollable--hasTopShadow,
    .Polaris-Scrollable--hasBottomShadow {
      box-shadow: none;
    }
    .Polaris-Scrollable:focus {
      outline: none;
    }
  }
`;

export const StyledCardHeader = styled.div`
  .Polaris-Card__Header {
    padding: 1.9rem 2rem;
    box-shadow: inset 0px -1px 0px #e4e5e7;
  }
`;
export const StyledFulfillment = styled.div`
  .Polaris-Stack__Item {
    text-align: end;
  }
`;

export const StyledView = styled.div`
  display: flex;
`;

/*
 *   Product listing wrapper
 *   usage - Dashboard
 */
export const ProductCard = styled.div`
  & + & {
    margin-top: 1rem;
  }
  .history-items {
    position: absolute;
    right: 1.5rem;
  }
  .history-items-versioning {
    margin-top: 22px;
    position: absolute;
    right: 1.5rem;
    .content {
      color: black;
    }
  }
  &.Polaris-ResourceItem--selected {
    > .Polaris-Card {
      background: #f2f7fe;
    }
    .list-item {
      margin-top: -2rem;
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .list-item {
    margin: -2rem;
    padding: 2rem;
    min-height: 105px;
    position: relative;
    .Polaris-Stack__Item--fill {
      flex-basis: 60%;
      width: 60%;
    }

    &:before {
      content: "";
      background: #2c6ecb;
      width: 0.3rem;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }
  }

  .card-description {
    word-break: break-all;
    > .Polaris-Link {
      @media (min-width: 800px) {
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    .Polaris-Caption {
      line-height: 1.9rem;
      margin-bottom: 0.38rem;
    }
    .Polaris-Icon {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
  .manage-button .Polaris-Button {
    min-height: 3rem;
    padding: 0.4rem 1.6rem;
  }
  .history-items .Polaris-Button__Content {
    font-size: 1.2rem;
  }
`;

/*
 *  Product listing collapsible sub card wrapper
 *  usage - Dashboard
 */

export const ProductSubCard = styled.div`
  margin: -2rem;
  padding-top: 2rem;
  .Polaris-Card {
    box-shadow: none;
    background: transparent;
  }
  .card-description {
    .Polaris-Link {
      line-height: 1.6;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
  .Polaris-TextStyle--variationSubdued {
    color: #8c9196;
    text-transform: capitalize;
  }
  // Product sub items list
  .Polaris-ResourceList {
    .Polaris-Card__Section {
      padding: 2rem 2rem;
    }
  }
  .card-header {
    .Polaris-Card__Section {
      padding-bottom: 0.4rem;
      display: initial;
      > .Polaris-Stack {
        width: 100%;
      }
    }
  }
  .card-header,
  .Polaris-ResourceList {
    .Polaris-Card__Section > .Polaris-Stack > .Polaris-Stack__Item {
      /* .Polaris-Thumbnail {
        width: 6rem;
      } */
      @media (min-width: 768px) {
        &:first-child {
          flex-basis: 15%;
          width: 15%;
        }
        &:nth-child(2) {
          flex-basis: 44%;
          width: 44%;
        }
        &:nth-child(3) {
          flex-basis: 15%;
          width: 15%;
          text-align: center;
        }
        &:last-child {
          flex-basis: 17%;
          width: 17%;
          text-align: right;
          flex-grow: 1;
        }
      }
    }
  }

  .card-footer {
    border-top: 0.1rem solid #e1e3e5;
    padding: 1.7rem 2rem;
    background: #f6faff;
    .Polaris-Card__Section {
      padding: 0;
    }
  }
  .light-text {
    color: #202223;
    font-size: 1.1rem;
    margin-right: 2rem;
  }
`;

// Polaris-ResourceItem__ListItem

export const StyledResourceItem = styled.div`
  // Resource list filter header style
  .Polaris-Filters {
    margin-bottom: 1.6rem;
  }
  .Polaris-ResourceList__HeaderTitleWrapper {
    font-weight: 600;
  }
  // Resource list card listing style
  .Polaris-ResourceItem__Handle {
    margin: 0;
  }
  .Polaris-ResourceItem__OwnedNoMedia {
    padding-left: 7px;
  }
  .Polaris-ResourceItem__CheckboxWrapper {
    padding-top: 4.3rem;
  }
  .Polaris-ResourceItem__Container {
    padding: 0;
    .Polaris-Stack__Item--fill {
      flex-basis: 63%;
      width: 63%;
    }
  }
  .product-card {
    padding: 2rem;
  }
  .ellipsis {
    word-break: break-all;
    @media (min-width: 1200px) {
      padding-right: 20rem;
    }
    > .Polaris-Link {
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: capitalize;
    }
    .Polaris-Caption {
      margin-bottom: 0.2rem;
      &:last-of-type {
        margin-bottom: 0.7rem;
      }
    }
    .Polaris-Icon {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
  .manage-button {
    .Polaris-Button {
      min-height: 3rem;
      padding: 0.4rem 1.6rem;
      display: block;
      margin-left: auto;
    }
  }
  .history-items {
    position: absolute;
    right: 1.5rem;
    .Polaris-Button__Content {
      font-size: 1.2rem;
    }
  }
  .status-icons {
    display: flex;
    margin-top: 3.1rem;
    .Polaris-Icon {
      height: 2.2rem;
      width: 2.2rem;
    }
    span + span {
      margin-left: 1.5rem;
    }
  }

  // Resource list sub items
  .collapsible-card {
    .card-header,
    .Polaris-ResourceList {
      .Polaris-Stack__Item {
        @media (min-width: 768px) {
          &:first-child {
            flex-basis: 11.5%;
            width: 11.5%;
          }
        }
      }
    }
  }
`;

export const StackWrapper = styled.div`
  > .Polaris-Stack {
    > .Polaris-Stack__Item {
      flex: 1 1 47.2%;
    }
  }
  &.operator {
    margin-bottom: 15px;
  }
`;

export const FulfillmentWrapper = styled.div`
  .Polaris-Banner {
    margin-bottom: 15px;
  }
`;

export const HybridDropshipWrapper = styled.div`
  .Polaris-TextContainer {
    margin-bottom: 15px;
  }
`;

export const ConsignmentShipWrapper = styled.div`
  .Polaris-Stack {
    .Polaris-Stack__Item {
      flex-grow: 1;
      &:nth-last-child(1) {
        flex-grow: 0;
      }
    }
  }
`;

export const FormTable = styled.div`
  td,
  th {
    border-bottom: 0;
    padding-left: 0;
    vertical-align: middle;
    &:nth-child(3) {
      text-align: center;
      width: 25%;
    }
  }
  td {
    padding-right: 0;
  }
  .Polaris-DataTable__ScrollContainer {
    overflow: hidden;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;
