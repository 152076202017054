import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

// import props
import { barGraphProp } from "app/reporting/modules/operator/props";

const BarGraph = (props) => {
  const { data, xDataKey, barDataKey, width = 900, height = 450 } = props;
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xDataKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={barDataKey} fill="#8884d8" />
    </BarChart>
  );
};

BarGraph.propTypes = barGraphProp.type;

export default BarGraph;
