import { gql } from "apollo-boost";

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePassword) {
    changePassword(input: $input) {
      data
      status
      error
    }
  }
`;
export default CHANGE_PASSWORD;
