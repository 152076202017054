import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

import { CREATE_FREE_PLAN } from "app/plans/apollo/mutations";

import OperatorPlan from "./modules/operator/features/generic/plan/newPlan";
import VendorPlan from "./modules/vendor/features/plan/plan";

const { userKey } = constant;
const { operator, vendor } = userKey;

const Plan = () => {
  const { currentUser, history, isLoading: isComponentLoading, currentUserRefetch } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [error, setError] = useState("");
  const [createFreePlan, { loading: freePlanLoading }] = useMutation(CREATE_FREE_PLAN);

  const onSubmit = (plan) => {
    const chargeBee = window.Chargebee.getInstance();
    const cart = chargeBee.getCart();
    const product = chargeBee.initializeProduct(plan.customCode || plan.code);

    const {
      _id,
      address,
      brandName,
      city,
      // NOTE: Not saving country for now, as it requires country code
      // country,
      email,
      firstName,
      lastName,
      phoneNumber,
      pinCode,
      shop,
    } = currentUser;

    const customer = {
      email: (email && email.address) || "",
      first_name: firstName,
      last_name: lastName,

      billing_address: {
        company: brandName || shop,
        first_name: firstName,
        last_name: lastName,
        line1: address,
        city,
        // NOTE: Not saving country for now, as it requires country code
        // country,
        zip: pinCode,
        phone: phoneNumber || "",
      },
    };

    // Setting custom fields
    customer.customUserId = baseHelper.mongoIdAsString(_id);

    cart.setCustomer(customer);
    cart.replaceProduct(product);
    cart.proceedToCheckout();
  };

  const onFreeSubmit = () => {
    createFreePlan()
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.CREATE_FREE_PLAN);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.CREATE_FREE_PLAN);

        if (responseError) {
          setError(responseError);
          return false;
        }

        if (responseData) {
          currentUserRefetch();
          return history.push("/");
        }

        return false;
      })
      .catch((exception) => {
        const parsedError = errorHelper.parse(exception);
        setError(parsedError);
      });
  };

  const getComponent = (role) => {
    if (isComponentLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return (
        <OperatorPlan
          banner={banner}
          error={error}
          onFreeSubmit={onFreeSubmit}
          onSubmit={onSubmit}
          setBanner={setBanner}
          submitLoading={freePlanLoading}
        />
      );
    }
    if (role === vendor) {
      return (
        <VendorPlan
          banner={banner}
          error={error}
          onSubmit={onSubmit}
          setBanner={setBanner}
          submitLoading={freePlanLoading}
        />
      );
    }
    return null;
  };

  const PlanComponent = getComponent(currentUserRole);
  return PlanComponent;
};

export default withErrorBoundary(Plan);
