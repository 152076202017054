import React, { useContext } from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
// propTypes
import vendorProfileProps from "app/advanceVendor/modules/operator/feature/view/propTypes";

const Payment = (props) => {
  const { cms } = useContext(PrivateContext);
  const { vendorDetails = {} } = props;
  const { payment = [] } = vendorDetails || {};
  let paymentTabData = [];

  const paymentInformation = [
    {
      term: cms("label.advanceVendor.bankName"),
      description: payment.bank,
    },
    {
      term: cms("label.advanceVendor.accountNumber"),
      description: payment.account,
    },
    {
      term: cms("label.advanceVendor.sortCode"),
      description: payment.sortCode,
    },
    {
      term: cms("label.advanceVendor.country"),
      description: payment.country,
    },
  ];
  paymentTabData = paymentInformation.map((item) => {
    const updatedItem = { ...item };
    if (!updatedItem.description) {
      updatedItem.description = cms("label.advanceVendor.noDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(payment).length === 0) {
      return <div className="noData">{cms("label.advanceVendor.noDataAvailable")}</div>;
    }
    return <DescriptionList items={paymentTabData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

Payment.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(Payment), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
