import React, { useContext, useEffect, useState, useCallback } from "react";
import { Card, OptionList, Popover, Select, Tag, ChoiceList } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import helpers
import baseHelper from "lib/helpers/base";
import { errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import helpers component
import { ResourceList } from "lib/components";

// import filter component
import MoreFilters from "app/payments/modules/generic/moreFilters/moreFilter";

// import context
import { PrivateContext } from "lib/context";

// import gql
import ACCOUNT_LIST from "app/payments/apollo/query";
import { PAYMENT_LIST } from "app/payments/apollo/subscriptions";
import { GET_SELLER_SETTING } from "app/setup/apollo/queries";

// import cms
import actionData from "app/payments/modules/operator/features/list/cms/resourceTableData";

// import subFeature
import { listProp } from "app/payments/modules/operator/features/list/propTypes";
import BULK_ACCOUNT from "app/payments/apollo/mutation/bulkAccount";
import PaymentRow from "./paymentRow";
import { useDebounce } from "lib/hooks";

const PaymentList = (props) => {
  const { setBanner } = props;
  const { cms, history } = useContext(PrivateContext);
  const { CRITICAL, DISPUTE, PAYPAL, PAID, STRIPE, gql, PAIDVIAPAYPAL, PAIDVIASTRIPE, SUCCESS, UNPAID } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [paymentListData, setPaymentList] = useState([]);
  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);
  const [paymentCount, setPaymentCount] = useState(0);
  const [bulkAccount] = useMutation(BULK_ACCOUNT);
  const resourceData = actionData(cms);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [activePopover, setActivePopover] = useState({});
  const [popoverDisable, setPopoverDisable] = useState(false);

  const renderItem = (item) => {
    return (
      <PaymentRow
        isExcludeShippingAmount={isExcludeShippingAmount}
        item={item}
        setBanner={setBanner}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        popoverDisable={popoverDisable}
      />
    );
  };

  const updatedSearchValue = useDebounce(search, 1000);

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : paramFilter,
    path: queryParam.path,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: updatedSearchValue,
  };
  const { data: orderLinesData, loading: orderLoading, error, subscribeToMore, refetch } = useQuery(ACCOUNT_LIST, {
    variables: {
      input: inputData,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: sellerSetting, loading: sellerSettingLoading } = useQuery(GET_SELLER_SETTING);

  useEffect(() => {
    setSelectedItems([]);
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (!sellerSetting) {
      return;
    }
    const sellerData = baseHelper.getResponseData(sellerSetting, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    // eslint-disable-next-line no-shadow
    const { isExcludeShippingAmount = false } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
  }, [sellerSetting]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
  }, [error, setBanner]);

  const orderLineError = baseHelper.getResponseError(orderLinesData, gql.GET_ACCOUNT_LIST);
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: orderLineError });
    }
  }, [orderLineError, setBanner]);

  useEffect(() => {
    subscribeToMore({
      document: PAYMENT_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, gql.GET_ACCOUNT_LIST);

  useEffect(() => {
    if (responseOrderLinesData) {
      setPaymentList(responseOrderLinesData && responseOrderLinesData.paymentList);
      setPaymentCount(responseOrderLinesData && responseOrderLinesData.count);
    }
  }, [responseOrderLinesData]);

  const handleSelect = (item) => {
    setSelectedItems(item);
  };

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    } else baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };
  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    setSearch(null);
    baseHelper.setUrl(history, { search: "" });
  };
  const handleBulkAccount = async (key) => {
    try {
      const response = await bulkAccount({ variables: { input: { ids: selectedItems, key } } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.BULK_ACCOUNT);
      if (responseError) {
        setBanner({
          isOpen: true,
          title: responseError,
          status: CRITICAL,
        });
      }
      const responseData = baseHelper.getResponseData(response.data, constant.gql.BULK_ACCOUNT);
      if (responseData) {
        setBanner({ isOpen: true, title: cms("common.message.success.background"), status: SUCCESS });
        refetch();
        setSelectedItems([]);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(exception),
        status: CRITICAL,
      });
    }
  };

  const bulkActionButton = (key) => {
    let disabled = true;
    if (selectedItems && selectedItems.length) {
      selectedItems.forEach((paymentId) => {
        const index = paymentListData.findIndex((paymentList) => {
          const { _id: id } = paymentList;
          return id === paymentId;
        });
        if ((key === PAYPAL || key === STRIPE) && paymentListData[index].accountingStatus === UNPAID) {
          disabled = false;
        }
        if (key === DISPUTE && paymentListData[index].accountingStatus !== DISPUTE) {
          disabled = false;
        }
        if (key === PAID && paymentListData[index].accountingStatus === UNPAID) {
          disabled = false;
        }
      });
    }
    return disabled;
  };

  const bulkActions = [
    {
      content: cms("label.payPaypal"),
      onAction: () => handleBulkAccount(PAIDVIAPAYPAL),
      disabled: bulkActionButton(PAYPAL),
    },
    {
      content: cms("label.payStripe"),
      onAction: () => handleBulkAccount(PAIDVIASTRIPE),
      disabled: bulkActionButton(STRIPE),
    },
    {
      content: cms("label.markDispute"),
      onAction: () => handleBulkAccount(DISPUTE),
      disabled: bulkActionButton(DISPUTE),
    },
    {
      content: cms("label.markPaid"),
      onAction: () => handleBulkAccount(PAID),
      disabled: bulkActionButton(PAID),
    },
  ];

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }

    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
  };

  const handleClearAll = () => {
    handleSelectedFilterRemove();
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    setFilterByStatus(null);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {option === constant.FORECAST
            ? cms("label.forecast")
            : option === constant.DUE
            ? cms("label.duePayment")
            : option === constant.DISPUTE
            ? cms("label.dispute")
            : baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilterArrObj = [];

  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }
  const appliedFilters = appliedFilterArrObj;

  const filterOptions = [{ label: cms("label.brandName"), value: constant.VENDOR }];

  const filterByStatusOption = [
    {
      label: cms("label.all"),
      value: "all",
    },
    {
      label: cms("label.forecast"),
      value: "forecast",
    },
    {
      label: cms("label.duePayment"),
      value: "due",
    },
    {
      label: cms("label.pay"),
      value: "paid",
    },
    {
      label: cms("label.dispute"),
      value: "dispute",
    },
  ];

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filter"),
      // label: "Filter by",
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      ),
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        // <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        //   <OptionList
        //     fullWidth
        //     allowMultiple
        //     onChange={(value) => handleAction(value)}
        //     options={filterByStatusOption}
        //     selected={filterByStatus || []}
        //   />
        // </Popover>

        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  return (
    <Card>
      <ResourceList
        items={paymentListData}
        idForItem={(orderLine) => {
          const { _id: id } = orderLine;
          return id;
        }}
        renderItem={renderItem}
        loading={loading || orderLoading || sellerSettingLoading}
        queryValue={search}
        onQueryClear={handleQueryClear}
        onQueryChange={handleSearchChange}
        sortOptions={resourceData.sortOptions}
        sortValue={sortValue}
        onSortChange={handleSort}
        selectedItems={selectedItems}
        onSelectionChange={handleSelect}
        bulkActions={bulkActions}
        filters={filters}
        appliedFilters={appliedFilters}
        handleClearAll={handleClearAll}
        selectable
        count={paymentCount}
        page={page}
        perPage={perPage}
        setPage={handlePageChange}
        setPopoverDisable={setPopoverDisable}
        setPerPage={handlePageLimitChange}
      />
    </Card>
  );
};
PaymentList.propTypes = listProp.type;
export default PaymentList;
