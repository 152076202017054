import React, { useContext, useCallback, useState, useEffect } from "react";

import {
  Button,
  Caption,
  Card,
  Collapsible,
  Frame,
  Heading,
  Layout,
  RadioButton,
  Stack,
  SettingToggle,
  TextStyle,
} from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

import constant from "lib/constant/constant";
import { GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";
import { GET_CREDIT_NOTE_SETTING, UPDATE_CREDIT_NOTE_SETTING } from "app/setup/apollo";
import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Toast, SkeletonAnnotated } from "lib/components";
import { withErrorBoundary, withFeature } from "lib/hoc";

import Add from "./add";

function CreditNote() {
  const { cms } = useContext(PrivateContext);
  const [active, setActive] = useState(false);
  const [isAllVendor, setIsAllVendor] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedVendor, setIsSelectedVendor] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [updateAt, setUpdateAt] = useState(new Date());
  const [message, setMessage] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendor, setVendor] = useState([{ vendorId: "" }]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const { error: errorVendorBrandList, loading: loadingVendorBrandList, data: dataVendorBrandList } = useQuery(
    GET_VENDOR_BRAND_LIST
  );

  const vendorBrandListResponseData = baseHelper.getResponseData(
    dataVendorBrandList,
    constant.gql.GET_VENDOR_BRAND_LIST
  );
  const vendorBrandListErrorData = baseHelper.getResponseError(dataVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);

  const { error: errorGetCreditNote, loading: loadingGetCreditNote, data: dataGetCreditNote, refetch } = useQuery(
    GET_CREDIT_NOTE_SETTING
  );

  const creditNoteResponseData = baseHelper.getResponseData(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  const creditNoteErrorData = baseHelper.getResponseError(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);

  const { creditNote } = creditNoteResponseData;

  useEffect(() => {
    if (vendorBrandListResponseData && creditNote) {
      let vendorNameList =
        vendorBrandListResponseData &&
        vendorBrandListResponseData.map((value) => {
          return {
            label: value.brandName,
            value: value._id,
          };
        });

      const { isAllVendor: allVendor, isEnabled, selectedVendor, updatedAt } = creditNote;
      if (selectedVendor && selectedVendor.length) {
        vendorNameList = vendorNameList.map((item) => {
          const { value = "" } = item;
          const vendorData = { ...item };
          vendorData.disabled = selectedVendor.includes(value);
          return vendorData;
        });
      }
      setVendorList(vendorNameList);
      if (isEnabled && vendorNameList.length) {
        const vendors = [];
        selectedVendor.forEach((vendorId) => {
          vendorNameList.forEach(({ value }) => {
            if (value === vendorId) {
              vendors.push({ vendorId });
            }
          });
        });
        setIsAllVendor(allVendor);
        setIsSelectedVendor(!allVendor);
        setVendor(vendors);
        setUpdateAt(updatedAt);
        setActive(isEnabled);
        setOpen(isEnabled);
      } else {
        setIsAllVendor(true);
        setIsSelectedVendor(false);
        setVendor([{ vendorId: "" }]);
        setUpdateAt(updatedAt);
        setActive(isEnabled);
        setOpen(isEnabled);
      }
    }
  }, [vendorBrandListResponseData, creditNote]);

  useEffect(() => {
    if (vendorBrandListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorBrandListErrorData, isOpen: true });
    }
    if (errorVendorBrandList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorBrandList), isOpen: true });
    }
    if (creditNoteErrorData) {
      setBanner({ status: constant.CRITICAL, title: creditNoteErrorData, isOpen: true });
    }
    if (errorGetCreditNote) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetCreditNote), isOpen: true });
    }
  }, [vendorBrandListErrorData, errorVendorBrandList, creditNoteErrorData, errorGetCreditNote]);

  const [updateCreditNote] = useMutation(UPDATE_CREDIT_NOTE_SETTING);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }, [message]);

  const onSubmit = async (toggle) => {
    let formData;
    if (toggle) {
      formData = {
        isAllVendor: true,
        isEnabled: !active,
        selectedVendor: [],
      };
    } else {
      const selectedVendor = vendor.map(({ vendorId }) => vendorId);
      formData = {
        isAllVendor: isAllVendor || false,
        isEnabled: active || false,
        selectedVendor: (!isAllVendor && selectedVendor) || [],
      };
    }

    try {
      const response = await updateCreditNote({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_CREDIT_NOTE);
      setIsLoading(false);
      setLoadingAction(false);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (toggle) {
        if (!active) {
          setMessage(cms("message.success.enable"));
        } else {
          setMessage(cms("message.success.disable"));
        }
        setActive(() => !active);
        setOpen(() => !open);
      } else {
        setMessage(cms("message.success.update"));
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  const handleToggle = useCallback(() => {
    setLoadingAction(true);
    onSubmit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, cms, open]);

  const handleAllVendorCheckBox = (value) => {
    if (isSelectedVendor) {
      setIsSelectedVendor(false);
    }
    setIsAllVendor(!value);
    setSubmitEnable(!value);
  };

  const handleSelectVendorCheckBox = (value) => {
    if (isAllVendor) {
      setIsAllVendor(false);
    }
    setIsSelectedVendor(!value);
    setSubmitEnable(!value);
  };

  if (loadingVendorBrandList || loadingGetCreditNote) {
    return <SkeletonAnnotated />;
  }

  const handleSubmit = () => {
    let isSubmit = true;
    if (vendor) {
      vendor.forEach((element) => {
        if (!element.vendorId && isSelectedVendor) {
          setBanner({
            isOpen: true,
            title: cms("message.error.vendor"),
            status: constant.CRITICAL,
          });
          isSubmit = false;
        }
      });
    }
    if (vendor.length === 0 && isSelectedVendor) {
      setBanner({
        isOpen: true,
        title: cms("message.error.vendor"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      setIsLoading(true);
      onSubmit(false);
    }
  };

  const contentStatus = active ? constant.displayStatus.DISABLE : constant.ENABLE;
  const setButtonColor = active;

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.manageCreditNote.title")}
        description={cms("section.manageCreditNote.description")}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <SettingToggle
              action={{
                content: contentStatus,
                onAction: handleToggle,
                destructive: setButtonColor,
                loading: loadingAction,
              }}
              enabled={setButtonColor}
            >
              <Heading variation="strong">{cms("section.manageCreditNote.card.title")}</Heading>
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
              <br />
              <TextStyle>{cms("section.manageCreditNote.card.subTitle")}</TextStyle>
            </SettingToggle>
          </div>

          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <Stack vertical fill>
              <RadioButton
                label={cms("label.allowVendor")}
                checked={isAllVendor}
                onChange={(newChecked) => handleAllVendorCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("label.selectedVendor")}
                checked={isSelectedVendor}
                onChange={(newChecked) => handleSelectVendorCheckBox(!newChecked)}
              />
              {isSelectedVendor && (
                <Add
                  vendorList={vendorList}
                  isSelectedVendor={isSelectedVendor}
                  vendor={vendor}
                  setVendor={setVendor}
                  setSubmitEnable={setSubmitEnable}
                />
              )}
            </Stack>
            <div align="right">
              <Button primary submit disabled={!submitEnabled} onClick={handleSubmit} loading={isLoading}>
                {cms("common.button.update")}
              </Button>
            </div>
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
export default withFeature(withErrorBoundary(CreditNote), {
  feature: constant.CREDIT_NOTE_SETTING,
  // subFeature: constant.CREDIT_NOTE_SETTING,
});
