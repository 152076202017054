import { gql } from "apollo-boost";

const UPDATE_PRODUCT_ATTACHMENT_SETTING = gql`
  mutation updateProductAttachmentSetting($input: UpdateProductAttachmentSetting) {
    updateProductAttachmentSetting(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_PRODUCT_ATTACHMENT_SETTING;
