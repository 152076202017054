import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { Frame, Select, SkeletonBodyText, Stack, TextField, TextStyle } from "@shopify/polaris";

// import component
import { Toast } from "lib/components";

import { GET_ADDRESS_DETAILS } from "app/orders/apollo/queries";
import { READY_TO_COLLECT } from "app/orders/apollo/mutations";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { CMS } from "routes/public/apollo/queries";

const { gql } = constant;

const BulkCollectOrder = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    addressModal = false,
    addressList,
    fulfillModal,
    setAddressModal,
    setBanner,
    selectedItemData,
    onRefetchOrderLineView,
  } = props;
  const { collectDetail = {} } = selectedItemData || {};

  const [vendorAddressId, setVendorAddressId] = useState((collectDetail && collectDetail.addressId) || "");
  const [note, setNote] = useState((collectDetail && collectDetail.note) || "");
  const [addressDetails, setAddressDetails] = useState({});
  const [message, setMessage] = useState("");

  const [getAddressListDetails, { loading: addressDetailsLoading, data: addressDetailsData }] = useLazyQuery(
    GET_ADDRESS_DETAILS
  );
  const [readyForCollectOrder, { loading: readyToCollectLoading }] = useMutation(READY_TO_COLLECT);

  useEffect(() => {
    const defaultAddress = addressList.find((item) => item.isDefault);
    if (defaultAddress) {
      setVendorAddressId(defaultAddress.value);
    }
  }, [addressList]);

  useEffect(() => {
    if (!addressDetailsLoading && addressDetailsData) {
      const responseData = baseHelper.getResponseData(addressDetailsData, gql.GET_ADDRESS_DETAILS);
      if (!responseData) {
        const bannerData = {
          title: baseHelper.getResponseError(addressDetailsData, gql.GET_ADDRESS_DETAILS) || errorHelper.parse(),
          status: constant.CRITICAL,
          isOpen: true,
        };
        setAddressModal(false);
        setBanner(bannerData);
      }

      setAddressDetails(responseData);
    }
  }, [addressDetailsLoading, addressDetailsData]);

  useEffect(() => {
    if (vendorAddressId) {
      setAddressDetails({});
      const { vendorId = "" } = selectedItemData || {};
      getAddressListDetails({ variables: { input: { addressId: vendorAddressId, sellerId: vendorId } } });
    }
  }, [vendorAddressId]);

  const readyForCollect = async () => {
    setMessage("");
    setAddressModal(false);
    const { _id: orderLineItemId = "" } = selectedItemData || {};
    const formData = {
      orderLineItemIds: [orderLineItemId],
      addressId: vendorAddressId,
      note,
    };

    if (!selectedItemData) {
      formData.isSingle = true;
    }
    try {
      const response = await readyForCollectOrder({
        variables: {
          input: formData,
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.READY_TO_COLLECT);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.READY_TO_COLLECT);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setMessage(cms("message.success.readyForCollection"));
      onRefetchOrderLineView();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const renderModal = () => {
    const modalTitle = cms("label.confirmPickup");
    const action = {
      content: cms("label.readyForCollection"),
      onAction: () => readyForCollect(),
      loading: readyToCollectLoading,
      disabled: !vendorAddressId,
    };

    return (
      <>
        {/* <Modal
          open={addressModal}
          onClose={() => {
            setAddressModal(false);
            setVendorAddressId("");
            setAddressDetails({});
            setNote("");
          }}
          title={modalTitle}
          primaryAction={action}
          sectioned
        > */}
        {/* <Modal.Section> */}
        {/* {fulfillModal()} */}
        <Stack>
          <Stack.Item>
            <Select
              label={cms("label.address")}
              options={addressList}
              onChange={(value) => setVendorAddressId(value)}
              value={vendorAddressId}
              placeholder={cms("placeholder.selectAddress")}
            />
            {addressDetailsLoading && <SkeletonBodyText />}
            {addressDetails &&
              Object.keys(addressDetails).length !== 0 && [
                <br />,
                <Stack vertical>
                  <Stack.Item>
                    <b>{cms("label.pickupLocation")}</b>
                  </Stack.Item>
                  <Stack.Item>
                    <TextStyle>
                      <Stack wrap={false}>
                        <Stack.Item>
                          <TextStyle>{baseHelper.ucFirst(addressDetails.address)}</TextStyle>
                          <br />
                          <TextStyle>{addressDetails.city}</TextStyle>
                          <br />
                          <TextStyle>{addressDetails.country}</TextStyle>
                          <br />
                          <TextStyle>{addressDetails.postalCode}</TextStyle>
                          <br />
                        </Stack.Item>
                      </Stack>
                    </TextStyle>
                  </Stack.Item>
                </Stack>,
              ]}
            <br />
            <TextField label={cms("label.note")} value={note} multiline={4} onChange={(value) => setNote(value)} />
            {/* </Modal.Section> */}
            {/* </Modal> */}
          </Stack.Item>
        </Stack>
        <Toast message={message} />
      </>
    );
  };

  return <>{renderModal()}</>;
};

export default BulkCollectOrder;
