import React, { useEffect, useState, useContext } from "react";
import { Button, Caption, Card, Layout, PageActions, TextStyle, Tooltip } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  GET_CATALOG,
  GET_PRODUCT_TAG_SETTING,
  GET_VENDOR_AND_PRODUCT_TYPES,
  UPDATE_TAG_SETTING,
} from "app/setup/apollo";

import { GET_PRODUCT_TAGS } from "app/productOld/apollo/queries";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { Banner, SkeletonAnnotated, Toast } from "lib/components";
import constant from "lib/constant/constant";
import { ActionButton, TagMapTable } from "app/setup/modules/operator/features/productTagSetting/style";
import { AddProductTag, Table } from "app/setup/modules/operator/features/productTagSetting/components";

const ProductTagSetting = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [productTag, setProductTag] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productType, setProductType] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const { shop = "", accessKey = "", accessToken = "" } = currentUser;

  const [updateTagSetting, { loading: updateTagSettingLoading }] = useMutation(UPDATE_TAG_SETTING);

  const {
    data: vendorAndProductTypesData,
    error: vendorAndProductTypesError,
    loading: vendorAndProductTypesLoading,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  const responseData = baseHelper.getResponseData(vendorAndProductTypesData, constant.gql.GET_VENDOR_PRODUCT);
  const responseError = baseHelper.getResponseError(vendorAndProductTypesData, constant.gql.GET_VENDOR_PRODUCT);

  useEffect(() => {
    if (responseData) {
      const { supplierRows = [], types = [] } = responseData;
      if (supplierRows && supplierRows.length) {
        const vendorList = supplierRows.map((vendor) => {
          const { _id = "", brandName = "" } = vendor || {};
          return { id: _id, label: brandName, value: _id };
        });
        setVendors([...vendorList]);
      }
      if (types && types.length) {
        const typeList = types.map((type) => {
          const { _id = "", value = "" } = type || {};
          return { id: _id, label: value, value };
        });
        typeList.unshift({ id: constant.ALL, label: cms("section.tag.label.type"), value: constant.ALL });
        setProductType([...typeList]);
      }
    }
  }, [cms, responseData, vendorAndProductTypesData]);

  const { data: getProductTagsData, error: getProductTagsError, loading: getProductTagsLoading } = useQuery(
    GET_PRODUCT_TAGS
  );

  const responseTagsData = baseHelper.getResponseData(getProductTagsData, constant.gql.GET_PRODUCT_TAGS);
  const responseTagsError = baseHelper.getResponseError(getProductTagsData, constant.gql.GET_PRODUCT_TAGS);

  useEffect(() => {
    if (responseTagsData) {
      const { tags = [] } = responseTagsData;
      if (tags && tags.length) {
        const tagList = tags.map((tag) => ({ tagName: tag, isCheck: false }));

        setProductTag([...tagList]);
      }
    }
  }, [getProductTagsData, responseTagsData]);

  const { data: getCatalogData, error: getCatalogError, loading: getCatalogLoading } = useQuery(GET_CATALOG);

  const responseCatalogData = baseHelper.getResponseData(getCatalogData, constant.gql.GET_CATALOG);
  const responseCatalogError = baseHelper.getResponseError(getCatalogData, constant.gql.GET_CATALOG);

  useEffect(() => {
    if (responseCatalogData) {
      const { catalog = [] } = responseCatalogData;
      if (catalog && catalog.length) {
        const catalogList = catalog.map((value) => {
          const { storeCatalogId = "", title = "" } = value || {};
          return { id: storeCatalogId.toString(), label: title, value: storeCatalogId.toString() };
        });
        catalogList.unshift({
          id: constant.ALL,
          label:
            shop && accessKey && accessToken ? cms("section.tag.label.category") : cms("section.tag.label.collection"),
          value: constant.ALL,
        });
        setCatalogs([...catalogList]);
      }
    }
  }, [accessKey, accessToken, cms, getCatalogData, responseCatalogData, shop]);

  const {
    data: getProductTagSettingData,
    error: getProductTagSettingError,
    loading: getProductTagSettingLoading,
    refetch,
  } = useQuery(GET_PRODUCT_TAG_SETTING);

  const responseTagSettingData = baseHelper.getResponseData(
    getProductTagSettingData,
    constant.gql.GET_PRODUCT_TAG_SETTING
  );
  const responseTagSettingError = baseHelper.getResponseError(
    getProductTagSettingError,
    constant.gql.GET_PRODUCT_TAG_SETTING
  );

  useEffect(() => {
    if (responseTagSettingData) {
      const { tagSetting, updatedAt: lastUpdated = "" } = responseTagSettingData;
      if (tagSetting && tagSetting.length) {
        setRowData([...tagSetting]);
      }
      setUpdatedAt(lastUpdated);
    }
  }, [getProductTagSettingData, responseTagSettingData]);

  useEffect(() => {
    if (responseError) {
      setBannerStatus({ status: constant.CRITICAL, title: responseError, isOpen: true });
    }
    if (vendorAndProductTypesError) {
      setBannerStatus({
        status: constant.CRITICAL,
        title: errorHelper.parse(vendorAndProductTypesError),
        isOpen: true,
      });
    }
    if (responseTagsError) {
      setBannerStatus({ status: constant.CRITICAL, title: responseTagsError, isOpen: true });
    }
    if (getProductTagsError) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(getProductTagsError), isOpen: true });
    }
    if (responseTagSettingError) {
      setBannerStatus({ status: constant.CRITICAL, title: responseTagSettingError, isOpen: true });
    }
    if (getProductTagSettingError) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(getProductTagSettingError), isOpen: true });
    }
    if (responseCatalogError) {
      setBannerStatus({ status: constant.CRITICAL, title: responseCatalogError, isOpen: true });
    }
    if (getCatalogError) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(getCatalogError), isOpen: true });
    }
  }, [
    vendorAndProductTypesError,
    getProductTagsError,
    getProductTagSettingError,
    getCatalogError,
    responseError,
    responseTagsError,
    responseTagSettingError,
    responseCatalogError,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  if (vendorAndProductTypesLoading || getProductTagsLoading || getProductTagSettingLoading || getCatalogLoading) {
    return <SkeletonAnnotated />;
  }

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const rowDataValidation = () => {
    if (responseTagSettingData) {
      const { tagSetting } = responseTagSettingData;
      if (tagSetting && tagSetting.length) {
        if (JSON.stringify(tagSetting) === JSON.stringify(rowData)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleEmptyValidation = () => {
    let isEmpty = false;
    if (rowData && rowData.length) {
      rowData.forEach((item) => {
        const {
          vendor = "",
          type: { isAll: typeAll = false, value: typeValue = "" },
          category: { isAll: categoryAll = false, value: categoryValue = "" },
        } = item;
        if (!(vendor || typeAll || typeValue || categoryAll || categoryValue)) {
          isEmpty = true;
        }
      });
    }
    return isEmpty;
  };

  const handleModal = () => {
    const isEmpty = handleEmptyValidation();
    if (isEmpty && rowData.length) {
      setBannerStatus({ status: constant.CRITICAL, title: cms("section.tag.message.error.incomplete"), isOpen: true });
      return;
    }
    const tags = productTag.map((item) => ({ ...item, isCheck: false }));
    setProductTag([...tags]);
    setActiveModal(!activeModal);
  };

  const activator = (
    <>
      {productTag.length > 0 ? (
        <>
          <Button plain onClick={handleModal}>
            {`+ ${cms("section.tag.modal.title")}`}
          </Button>
          <br />
        </>
      ) : (
        <>
          <Tooltip active={false} content={cms("section.tag.label.tooltip")} preferredPosition="above">
            <Button plain onClick={handleModal} disabled>
              {`+ ${cms("section.tag.modal.title")}`}
            </Button>
          </Tooltip>
          <br />
        </>
      )}
    </>
  );

  const addProductTag = () => {
    if (activeModal) {
      return (
        <AddProductTag
          active={activeModal}
          cms={cms}
          setProductTag={setProductTag}
          productTag={productTag}
          rowData={rowData}
          setRowData={setRowData}
          handleChange={handleModal}
          setActive={setActiveModal}
        />
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    try {
      const res = await updateTagSetting({
        variables: {
          input: { tagSetting: rowData },
        },
      });
      const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_TAG_SETTING);
      const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_TAG_SETTING);
      if (resError) {
        setBannerStatus({
          status: constant.CRITICAL,
          isOpen: true,
          title: resError,
        });
      }
      if (resData) {
        setMessage(cms("section.tag.message.success.update"));
        setIsDisable(true);
        refetch();
      }
    } catch (exception) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  return (
    <Layout>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Layout.AnnotatedSection title={cms("section.tag.title")} description={cms("section.tag.description")}>
          <Card
            title={[
              cms("section.tag.subtitle"),
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <TagMapTable className="tag-map-table">
                <Table
                  cms={cms}
                  currentUser={currentUser}
                  data={rowData}
                  setData={setRowData}
                  vendors={vendors}
                  productType={productType}
                  productCategory={catalogs}
                  setBannerStatus={setBannerStatus}
                  setIsRepeat={setIsRepeat}
                  setIsDisable={setIsDisable}
                />
              </TagMapTable>
              <br />
              {activator}
              {addProductTag()}
              {updatedAt && (
                <ActionButton>
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: handleSubmit,
                      disabled:
                        isDisable ||
                        isRepeat ||
                        updateTagSettingLoading ||
                        handleEmptyValidation() ||
                        rowDataValidation(),
                      loading: updateTagSettingLoading,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                        disabled: updateTagSettingLoading,
                      },
                    ]}
                  />
                </ActionButton>
              )}
            </Card.Section>
          </Card>
          {!updatedAt && (
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: handleSubmit,
                disabled: isDisable || isRepeat || updateTagSettingLoading || handleEmptyValidation(),
                loading: updateTagSettingLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                  disabled: updateTagSettingLoading,
                },
              ]}
            />
          )}
          <Toast message={message} timeout={5000} />
        </Layout.AnnotatedSection>
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ProductTagSetting), { feature: constant.PRODUCT_TAG_SETTING });
