import * as yup from "yup";

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup.string().required(cms("message.validation.passwordIsRequired")).nullable(),
  });

const userNameSchema = (cms) =>
  yup.object().shape({
    userName: yup.string().required(cms("message.validation.userNameIsRequired")).nullable(),
  });

export { passwordSchema, userNameSchema };
