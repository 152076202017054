import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";

import { OperatorProductAttachmentSetting } from "./operator/features";
// import { ProviderProductAttachmentSetting } from "./provider/features";

const { userKey } = constant;
const { operator } = userKey;

const ProductAttachmentSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProductAttachmentSetting />;
    }
    // if (role === provider) {
    //   return <ProviderProductAttachmentSetting />;
    // }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductSettingComponent = getComponent(currentUserRole);
  return ProductSettingComponent;
};

export default ProductAttachmentSetting;
