import { gql } from "apollo-boost";

const UPDATE_METAFIELD = gql`
  mutation updateMetafield($input: MetaField) {
    updateMetafield(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_METAFIELD;
