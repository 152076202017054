import React, { useState, useContext, useEffect } from "react";
import { Card, Checkbox, FormLayout, Layout, PageActions, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";

// import component
import config from "configuration";
import FileUpload from "lib/components/fileInput/fileUpload";
import { BULK_SUPPLIER_CSV } from "app/vendors/apollo/mutations/createVendor";
import { GET_SELLER_FULFILLMENT } from "app/vendors/apollo/queries";
import { Spinner } from "lib/components";

const getCSVData = (csvData) => {
  const regexForEnterKey = /\n,|\n/gi; // TODO: Need updation after encoding resolution
  const csvDataAfterRegex = csvData.data.replace(regexForEnterKey, "\n");
  return csvDataAfterRegex;
};
const BulkCreate = (props) => {
  const {
    // learnMore
    setBanner,
  } = props;
  const { gql } = constant;
  const { history, cms = {} } = useContext(PrivateContext) || {};
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);
  const [values, setValues] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isThirdParty, setIsThirdParty] = useState(false);
  const [bulkSupplierUsingCSVRequest] = useMutation(BULK_SUPPLIER_CSV);

  const { loading: fulfillmentLoading, data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  useEffect(() => {
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    if (showProvinceCode) {
      setIsThirdParty(showProvinceCode);
    }
    setIsSubmitted(!values.data);
  }, [cms, fulfillmentData, values.data]);

  if (fulfillmentLoading) {
    return <Spinner />;
  }

  const onSubmit = async () => {
    setSubmitButtonLoading(true);
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: `${cms("message.error.csvIsRequired")}`,
        status: constant.CRITICAL,
      });
      setSubmitButtonLoading(false);
      return;
    }
    setBanner({
      isOpen: true,
      title: cms("section.bulkCreate.message.success"),
      status: constant.INFO,
    });
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: [modifiedFormat],
        fileNames: [values.fileName],
      },
      isVerified: shippingLabel,
      isReadOnly: readOnly,
      isProvinceCodeRequired: isThirdParty,
    };
    try {
      const res = await bulkSupplierUsingCSVRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_SUPPLIER_CSV);
      let banner = {
        action: {
          id: "viewLogs",
          content: cms("message.successAction"),
          onAction: () => history.push("/activity/bulk"),
        },
        isOpen: true,
        status: constant.INFO,
        title: cms("section.bulkCreate.message.successAction"),
        children: cms("section.bulkCreate.message.success"),
      };
      setIsSubmitted(true);
      setSubmitButtonLoading(false);
      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_SUPPLIER_CSV);
        banner = {
          action: null,
          isOpen: true,
          children: null,
          status: constant.CRITICAL,
          title: error,
        };
      }
      setBanner({
        action: banner.action,
        children: banner.children,
        isOpen: banner.isOpen,
        status: banner.status,
        title: banner.title,
      });
      if (isOnboarding && resData) {
        onNext();
      }
    } catch (exception) {
      setSubmitButtonLoading(false);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const handleChangeReadOnly = () => {
    setReadOnly(!readOnly);
  };

  const handleChangeShippingLabel = () => {
    setShippingLabel(!shippingLabel);
  };
  const isAnyEmptyField = () => {
    return !values.data;
  };

  return (
    <div>
      <Layout.AnnotatedSection
        title={cms("section.bulkCreate.title")}
        description={cms("section.bulkCreate.description.para1")}
      >
        <Card
          title={cms("section.bulkCreate.title")}
          // actions={[
          //   {
          //     content: cms("section.bulkCreate.learnMore"),
          //     onAction: () => learnMore(cms("section.bulkCreate.title"), cms("section.bulkCreate.todo")),
          //   },
          // ]}
        >
          <Card.Section>
            <FormLayout>
              <TextContainer>{cms("section.bulkCreate.description.para2")}</TextContainer>
              <FileUpload
                setValue={setValues}
                downloadCSV={`${config.rootURL}/file-download/vendor`}
                values={values}
                setIsSubmitDisabled={setIsSubmitted}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <Card title={cms("section.bulkCreate.vendorVerification.title")}>
          <Card.Section>
            <TextContainer>
              <p>{cms("section.bulkCreate.vendorVerification.description")}</p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.autoVerifyVendor")}
                checked={shippingLabel}
                onChange={handleChangeShippingLabel}
                id="autoVerifyCheckBox"
              />
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title={cms("section.bulkCreate.vendorAccessPermission.title")}>
          <Card.Section>
            <TextContainer>
              <p>{cms("section.bulkCreate.vendorAccessPermission.description.para1")}</p>
              <p>{cms("section.bulkCreate.vendorAccessPermission.description.para2")}</p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.isReadOnlyVendor")}
                checked={readOnly}
                onChange={handleChangeReadOnly}
                id="readOnlyCheckbox"
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.upload"),
            id: "submitButton",
            onAction: () => onSubmit(),
            loading: submitButtonLoading,
            disabled: isAnyEmptyField() || isSubmitted,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <br />
    </div>
  );
};

BulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
  // learnMore: PropTypes.func.isRequired,
};

export default BulkCreate;
