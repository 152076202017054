import React, { useContext, useCallback, useState, useEffect } from "react";

import {
  Card,
  Caption,
  Collapsible,
  Frame,
  Layout,
  Link,
  RadioButton,
  PageActions,
  Stack,
  Select,
  SettingToggle,
  TextStyle,
} from "@shopify/polaris";

import { useQuery, useMutation } from "react-apollo";

// import constant
import constant from "lib/constant/constant";

// import queries
import { GET_VENDOR_PRODUCT_TYPES } from "app/setup/apollo/queries";
import { GET_SCHEDULE_PAYMENT } from "app/payments/apollo/queries";
import SCHEDULE_PAYMENT_TERM from "app/payments/apollo/mutation/schedulePaymentTerm";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner, SkeletonAnnotated, Toast } from "lib/components";
import AdvancePaymentOption from "./advancePaymentOption";
import SelectedVendorPayment from "./selectedVendorPayment";

function OperatorSchedulePaymentSetting() {
  const { cms, history } = useContext(PrivateContext);
  const [active, setActive] = useState(false);
  const [advanceUpdatedAt, setAdvanceUpdatedAt] = useState();
  const [advancedValues, setAdvancedValues] = useState([]);
  const [advancePaymentValues, setAdvancePaymentValues] = useState([]);
  const [dateOfPayment, setDate] = useState();
  const [defaultCheck, setDefaultCheck] = useState(true);
  const [isAdvanceBanner, setIsAdvanceBanner] = useState(false);
  const [isAllVendor, setIsAllVendor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSettingUpdated, setIsSettingUpdated] = useState(false);
  const [isSelectedVendor, setIsSelectedVendor] = useState(false);
  const [isShowAdvanced, setIsShowAdvanced] = useState(false);
  const [isUpdatedAdvance, setIsUpdatedAdvance] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedVendorValues, setSelectedVendorValues] = useState([]);
  const [status, setStatus] = useState("");
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [vendorValues, setVendorValues] = useState([]);
  const [updateAt, setUpdateAt] = useState();

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [advanceBanner, setAdvanceBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const {
    gql: { GET_VENDOR_PRODUCT },
  } = constant;

  const statusList = [
    { label: cms("label.accepted"), value: constant.ACCEPTED },
    { label: cms("label.fulfilled"), value: constant.FULFILLED },
  ];

  const { error: termError, data: termData, loading: termLoading, refetch } = useQuery(GET_SCHEDULE_PAYMENT);
  const termGetData = baseHelper.getResponseData(termData, constant.gql.GET_SCHEDULE_PAYMENT);
  const termGetError = baseHelper.getResponseError(termData, constant.gql.GET_SCHEDULE_PAYMENT);

  const { loading, data: vendorAndProductTypesData } = useQuery(GET_VENDOR_PRODUCT_TYPES);
  const responseData = baseHelper.getResponseData(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
  const responseError = baseHelper.getResponseError(vendorAndProductTypesData, GET_VENDOR_PRODUCT);

  useEffect(() => {
    const {
      isEnabled,
      schedulePaymentUpdatedAt,
      advancedSchedulePaymentUpdatedAt,
      dateOfPayment: paymentDate,
      status: paymentStatus,
      advancedPaymentSchedule,
      vendorPaymentSchedule,
    } = termGetData;

    let advancePayment;
    let allVendor;
    let selectedVendor;

    if (!termLoading) {
      allVendor = !!(paymentStatus && paymentDate);
      selectedVendor = !!(vendorPaymentSchedule && vendorPaymentSchedule.length);
      advancePayment = advancedPaymentSchedule && advancedPaymentSchedule.length;
      setDefaultCheck(!(selectedVendor || allVendor || advancePayment));
    }

    if (isEnabled) {
      setIsAllVendor(allVendor);
      setIsSelectedVendor(selectedVendor);
      setIsShowAdvanced(advancePayment);
      setStatus(paymentStatus);
      setDate(paymentDate);
      setAdvancePaymentValues(advancedPaymentSchedule);
      setAdvancedValues(advancedPaymentSchedule);
      setVendorValues(vendorPaymentSchedule);
      setSelectedVendorValues(vendorPaymentSchedule);
      setUpdateAt(schedulePaymentUpdatedAt);
      setAdvanceUpdatedAt(advancedSchedulePaymentUpdatedAt);
      setActive(isEnabled);
      setOpen(isEnabled);
    } else {
      setIsAllVendor(false);
      setIsSelectedVendor(false);
      setUpdateAt(schedulePaymentUpdatedAt);
      setIsShowAdvanced(advancePayment);
      setAdvanceUpdatedAt(advancedSchedulePaymentUpdatedAt);
      setActive(isEnabled);
      setOpen(isEnabled);
    }
  }, [termLoading, termGetData, updateAt]);

  useEffect(() => {
    if (termGetError) {
      setBanner({ status: constant.CRITICAL, title: termGetError, isOpen: true });
    }
    if (termError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(termError), isOpen: true });
    }
  }, [termGetError, termError]);

  const [schedulePaymentTerm] = useMutation(SCHEDULE_PAYMENT_TERM);

  const handleEmptyFields = (data, toggle) => {
    const advancedValue = data;
    if (!toggle && advancedValue.length) {
      const line = advancedValue[advancedValue.length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid && !toggle) {
        setSubmitEnable(false);
        setIsAdvanceBanner(true);
        setAdvanceBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
          onDismiss: () => [setIsAdvanceBanner(false), setAdvanceBanner({ isOpen: false, title: "", status: "" })],
        });
        return false;
      }
    }
    return true;
  };

  const handleInvalidRows = (data, toggle) => {
    let advancedValue = data;
    const orderStatus = constant.STATUS;
    const date = constant.DATE_OF_PAYMENT;
    let isEmpty = false;
    if (advancedValue && advancedValue.length) {
      advancedValue.map((row) => {
        const keys = Object.keys(row);
        let count = 0;
        keys.map((key) => {
          if (orderStatus === key || date === key || row[key]) {
            count += 1;
          }
          return null;
        });

        if ((count && count !== keys.length) || !(row[date] > 0) || !(row[orderStatus] === false || row[orderStatus])) {
          isEmpty = true;
        }
        return null;
      });
      advancedValue = advancedValue.filter((item) => item.orderStatus !== null && item[date] && item[date] !== "NaN");
    }
    if (!toggle && isEmpty) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("common.message.error.invalidRowDetails"),
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (toggle) => {
    let formData;
    if (toggle) {
      setStatus("");
      setDate();
      setAdvancePaymentValues([]);
      setAdvancedValues([]);
      setIsAllVendor(false);
      setIsSelectedVendor(false);
      formData = {
        isSchedulePayment: !active,
        paymentTerms: constant.SCHEDULE_PAYMENT,
      };

      try {
        const response = await schedulePaymentTerm({
          variables: {
            input: formData,
          },
        });

        const responseTermError = baseHelper.getResponseError(response.data, constant.gql.SCHEDULE_PAYMENT_TERM);
        setIsLoading(false);
        setLoadingAction(false);

        if (responseTermError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseTermError });
          return;
        }
        if (toggle) {
          if (!active) {
            setMessage(cms("message.success.enable"));
          } else {
            setMessage(cms("message.success.disable"));
          }
          setActive(() => !active);
          setOpen(() => !open);
        }
        setBanner({ isOpen: false, status: "", title: "" });
        setAdvanceBanner({ isOpen: false, status: "", title: "" });
        setMessage("");
        refetch();
      } catch (exception) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      }
      setSubmitEnable(false);
    }

    if (!toggle && isSelectedVendor && selectedVendorValues && selectedVendorValues.length === 0) {
      setIsLoading(false);
      setLoadingAction(false);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.addOneVendor"),
      });
      return;
    }

    if (!toggle && (isAllVendor || defaultCheck) && !(status && dateOfPayment)) {
      setIsLoading(false);
      setLoadingAction(false);
      if (!status) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: cms("message.error.status"),
        });
      } else if (!dateOfPayment) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: cms("message.error.date"),
        });
      }
      return;
    }

    const isValidAdvancePaymentInputs = handleEmptyFields(advancePaymentValues, toggle);
    const isValidSelectedVendorInputs = handleInvalidRows(selectedVendorValues, toggle);

    if (!toggle && !isValidSelectedVendorInputs) {
      setIsLoading(false);
      setLoadingAction(false);
      return;
    }

    if (!toggle && !isValidAdvancePaymentInputs) {
      setIsLoading(false);
      setLoadingAction(false);
      return;
    }

    if (!toggle && (isAllVendor || defaultCheck)) {
      formData = {
        isSchedulePayment: true,
        paymentTerms: constant.SCHEDULE_PAYMENT,
        dateOfPayment,
        status,
        advancedPaymentSchedule: advancePaymentValues || [],
        vendorPaymentSchedule: [],
      };
    }
    if (!toggle && isSelectedVendor) {
      formData = {
        isSchedulePayment: true,
        paymentTerms: constant.SCHEDULE_PAYMENT,
        advancedPaymentSchedule: [],
        vendorPaymentSchedule: selectedVendorValues || [],
      };
    }

    try {
      const response = await schedulePaymentTerm({
        variables: {
          input: formData,
        },
      });

      const responseTermError = baseHelper.getResponseError(response.data, constant.gql.SCHEDULE_PAYMENT_TERM);
      setIsLoading(false);
      setLoadingAction(false);

      if (responseTermError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseTermError });
        return;
      }
      if (!toggle) {
        if (isUpdatedAdvance && !isSettingUpdated) {
          if (advanceUpdatedAt) {
            setMessage(cms("message.success.advanceUpdate"));
          } else {
            setMessage(cms("message.success.advanceSaved"));
          }
        } else if (updateAt) {
          setMessage(cms("message.success.update"));
        } else {
          setMessage(cms("message.success.saved"));
        }
      }
      setBanner({ isOpen: false, status: "", title: "" });
      setAdvanceBanner({ isOpen: false, status: "", title: "" });
      setMessage("");
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  const handleToggle = useCallback(() => {
    setLoadingAction(true);
    onSubmit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, cms, open]);

  const handleAllVendorCheckBox = (value) => {
    setBanner({ isOpen: false, status: "", title: "" });
    setAdvanceBanner({ isOpen: false, status: "", title: "" });
    if (isSelectedVendor) {
      setIsSelectedVendor(!isSelectedVendor);
    }
    setIsAllVendor(!value);
    setSubmitEnable(!value);
  };

  const handleSelectVendorCheckBox = (value) => {
    setBanner({ isOpen: false, status: "", title: "" });
    setAdvanceBanner({ isOpen: false, status: "", title: "" });
    if (isAllVendor) {
      setIsAllVendor(!isAllVendor);
    }
    setIsSelectedVendor(!value);
    setSubmitEnable(!value);
  };

  if (termLoading) {
    return <SkeletonAnnotated />;
  }

  const handleSubmit = () => {
    setIsUpdatedAdvance(false);
    setIsLoading(true);
    setIsSettingUpdated(false);
    onSubmit(false);
  };

  const contentStatus = active ? constant.displayStatus.DISABLE : constant.ENABLE;
  const setButtonColor = active;

  if (termLoading || loading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.schedulePayment.layout.title")}
        description={cms("section.schedulePayment.layout.description")}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <SettingToggle
              action={{
                content: contentStatus,
                onAction: handleToggle,
                destructive: setButtonColor,
                loading: loadingAction,
              }}
              enabled={setButtonColor}
            >
              <TextStyle variation="strong">{cms("section.schedulePayment.card.title")}</TextStyle>
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
              <br />
              <TextStyle>{cms("section.schedulePayment.card.description")}</TextStyle>
            </SettingToggle>
          </div>

          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: "100ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <Stack vertical fill>
              <RadioButton
                label={cms("label.allVendor")}
                checked={isAllVendor || defaultCheck}
                onChange={(newChecked) => {
                  setDefaultCheck(false);
                  handleAllVendorCheckBox(!newChecked);
                }}
              />
              {(isAllVendor || defaultCheck) && (
                <>
                  <Stack>
                    <Stack.Item fill>
                      <Select
                        label={cms("label.status")}
                        options={statusList}
                        placeholder={cms("placeholder.status")}
                        value={status || ""}
                        onChange={(value) => {
                          setStatus(value);
                          setIsSettingUpdated(true);
                          setSubmitEnable(true);
                        }}
                      />
                    </Stack.Item>
                    <Stack.Item fill>
                      <Select
                        label={cms("label.date")}
                        options={baseHelper.getDates() || []}
                        placeholder={cms("placeholder.date")}
                        value={(dateOfPayment && String(dateOfPayment)) || ""}
                        onChange={(val) => {
                          setIsSettingUpdated(true);
                          setDate(parseInt(val, 10));
                          setSubmitEnable(true);
                        }}
                      />
                    </Stack.Item>
                  </Stack>
                </>
              )}
              <Link onClick={() => setIsShowAdvanced(!isShowAdvanced)}>{cms("button.toggleAdvance")}</Link>
              <RadioButton
                label={cms("label.selectedVendor")}
                checked={isSelectedVendor}
                onChange={(newChecked) => {
                  setDefaultCheck(false);
                  handleSelectVendorCheckBox(!newChecked);
                }}
              />
              {isSelectedVendor && (
                <SelectedVendorPayment
                  loading={loading}
                  responseData={responseData}
                  responseError={responseError}
                  setSubmitEnable={setSubmitEnable}
                  setValues={setSelectedVendorValues}
                  vendorTermData={vendorValues}
                />
              )}
            </Stack>
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
      {active && (isAllVendor || defaultCheck) && (
        <AdvancePaymentOption
          advanceTermData={advancedValues}
          bannerData={advanceBanner}
          loading={loading}
          isAdvanceBanner={isAdvanceBanner}
          isOpen={isShowAdvanced}
          responseData={responseData}
          responseError={responseError}
          setIsAdvanceBanner={setIsAdvanceBanner}
          setSubmitEnable={setSubmitEnable}
          setIsUpdatedAdvance={setIsUpdatedAdvance}
          setValues={setAdvancePaymentValues}
          updateAt={advanceUpdatedAt}
        />
      )}
      <Layout.Section>
        {active && (
          <PageActions
            primaryAction={{
              content: !updateAt ? cms("common.button.submit") : cms("common.button.update"),
              onAction: () => {
                handleSubmit();
              },
              disabled: !submitEnabled,
              loading: isLoading,
            }}
            secondaryActions={[
              {
                id: "cancelButton",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.Section>
    </>
  );
}
export default withFeature(withErrorBoundary(OperatorSchedulePaymentSetting), { feature: constant.SCHEDULE_PAYMENT });
