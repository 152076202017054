import * as yup from "yup";

const sellerSchema = (cms) => {
  return yup.object().shape({
    Seller: yup.string().required(cms("message.error.sellerRequired")).nullable(),
  });
};

const vendorSchema = (cms) => {
  return yup.object().shape({
    vendor: yup.string().required(cms("message.error.vendorRequired")).nullable(),
  });
};

export { sellerSchema, vendorSchema };
