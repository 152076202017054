import React, { useContext } from "react";
import { Page, EmptyState } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

const NotFound = () => {
  const { history } = useContext(PrivateContext);
  return (
    <Page>
      <EmptyState
        heading="Sorry"
        action={{
          content: "Go Back",
          onAction: () => history.push("/"),
        }}
        secondaryAction={{
          content: "Learn more about MarketCube",
          url: "http://marketcube.io",
        }}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>Didn't find what you are looking for.</p>
      </EmptyState>
    </Page>
  );
};

export default NotFound;
