import { gql } from "apollo-boost";

const GET_SELLER_LOCATION = gql`
  query {
    getSellerLocation {
      data {
        locations
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_SELLER_LOCATION;
