import * as jsPDF from "jspdf";
import Moment from "moment";

class FileHelper {
  generatePdf = (logo, pdfData, title, callback) => {
    const pdfsize = "a1";
    const doc = new jsPDF("l", "in", pdfsize);
    const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
    doc.text(`Generated at: ${date}`, 29.6, 1);
    if (logo) {
      doc.addImage(logo, "JPEG", 0.5, 0.3, 2, 1);
    }
    doc.autoTable(pdfData.shift(), pdfData, { startY: 1.5, styles: { minCellWidth: 1 } });
    doc.save(`${title} - ${date}.pdf`);
    if (!callback) {
      setTimeout(() => {
        window.close();
      }, 500);
    } else {
      callback();
    }
  };

  loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      if (!url) {
        reject();
      }
      img.onload = () => resolve(img);
      img.src = url;
    });
  }
}

export default new FileHelper();
