import PropTypes from "prop-types";

const connectedShop = {
  cms: PropTypes.object.isRequired,
  currentUserData: PropTypes.object.isRequired,
  setIsConnected: PropTypes.func.isRequired,
  setBanner: PropTypes.func.isRequired,
  setShop: PropTypes.func.isRequired,
};

const syncProduct = {
  type: {
    setBanner: PropTypes.func,
  },
  default: {
    setBanner: () => {},
  },
};

const syncWooCommerceProduct = {
  type: {
    setBanner: PropTypes.func,
  },
  default: {
    setBanner: () => {},
  },
};

export { connectedShop, syncProduct, syncWooCommerceProduct };
