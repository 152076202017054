import React from "react";
import { Layout, Card, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

const Skeleton = () => {
  const layouts = [...Array(3).keys()];
  return layouts.map((_, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Layout.Section oneThird key={`SkeletonLayoutSection${index}`}>
        <Card>
          <Card.Section>
            <SkeletonDisplayText />
          </Card.Section>
          <Card.Section>
            <SkeletonBodyText lines={16} />
          </Card.Section>
        </Card>
      </Layout.Section>
    );
  });
};

export default Skeleton;
