import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Spinner } from "lib/components";

import { DownloadInviteCSV } from "./generic/feature/downloadCSV";

const { userKey } = constant;
const { admin, operator } = userKey;

const DownloadVendorCSV = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === admin) {
      return <DownloadInviteCSV />;
    }
    if (role === operator) {
      return <DownloadInviteCSV />;
    }
    return null;
  };
  if (isLoading) {
    return <Spinner isFullPage />;
  }
  const currentUserRole = storageHelper.get("userRole");
  const DownloadVendorCSVComponent = getComponent(currentUserRole);
  return DownloadVendorCSVComponent;
};

export default DownloadVendorCSV;
