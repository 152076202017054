import { gql } from "apollo-boost";

const GET_VENDOR_PROFILE_SETTING = gql`
  query GetVendorFormSetting($input: VendorId) {
    getVendorFormSetting(input: $input) {
      data {
        vendorForm {
          provinceCode {
            isRequired
          }
          postalCode {
            min
          }
        }
      }
      error
      status
    }
  }
`;

export default GET_VENDOR_PROFILE_SETTING;
