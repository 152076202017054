import { gql } from "apollo-boost";

const BULK_INVITE_VIEW = gql`
  subscription {
    bulkInviteView {
      data {
        bulkInviteList {
          _id
          createdAt
          email
          emailStatus
          resentCount
          sentBy
          signedUp
          status
          storeName
        }
        operationType
      }
      status
      error
    }
  }
`;

export default BULK_INVITE_VIEW;
