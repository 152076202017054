import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery } from "react-apollo";
import {
  Badge,
  Button,
  Caption,
  Card,
  Heading,
  Layout,
  Link,
  ResourceItem,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";
// import constant
import constant from "lib/constant/constant";
// import helper components
import { Banner, ResourceList, SkeletonList } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
// import cms
import listData from "app/userManagement/modules/generic/bulkActivity/cms/listData";
// import gql
import { GET_ERROR_LIST } from "app/userManagement/apollo/queries/index";
import { ERROR_LOG_VIEW } from "app/userManagement/apollo/subscriptions/index";
import UploadModal from "./subFeature/upload";

const List = () => {
  const { history, isLoading, cms } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const statusKeys = Object.freeze({
    pending: "Pending",
    completed: "Completed",
    error: "Error",
    success: "Success",
  });
  const { gql } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [errorLogsList, setErrorLogsList] = useState([]);
  const [errorCount, setErrorCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    tabKey: queryParam.tabKey,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: errorLogs, loading: errorLoading, error: errorBulkLogs, subscribeToMore } = useQuery(GET_ERROR_LIST, {
    variables: {
      input: inputData,
    },
  });

  useEffect(() => {
    if (!errorLoading) {
      setLoading(false);
    }
  }, [errorLoading]);

  useEffect(() => {
    if (errorBulkLogs) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(errorBulkLogs),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorBulkLogs, errorHelper]);
  const responseError = baseHelper.getResponseError(errorLogs, gql.GET_ERROR_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(errorLogs, gql.GET_ERROR_LIST);
  useEffect(() => {
    if (responseData) {
      setErrorLogsList(responseData && responseData.errorLogList);
      setErrorCount(responseData && responseData.count);
    }
  }, [responseData]);
  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select, page: 1 });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );
  const getTabs = {
    0: "all",
    1: "error",
    2: "success",
  };

  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handleTabChange = (select) => {
    setSelectedTab(select);
    setFilters(getTabs[select], select);
    setPage(1);
  };
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };
  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };
  useEffect(() => {
    subscribeToMore({
      document: ERROR_LOG_VIEW,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newLogs = subscriptionData.data.errorLogView.data.errorLog;
        const { duplicate = 0, error = 0, success = 0, total = 0 } = newLogs || {};
        const processedCount = (duplicate || 0) + (error || 0) + (success || 0);
        return [
          setModalData((prevValue) => {
            return {
              ...prevValue,
              processedCount,
              total,
            };
          }),
        ];
      },
    });
  }, [subscribeToMore]);

  const transformStatus = ({ status, duplicate, error, success }) => {
    const isSuccess = !!(success && !error && !duplicate);
    if (status === statusKeys.completed) {
      return (isSuccess && statusKeys.success) || statusKeys.error;
    }
    return status;
  };

  const renderItem = (item) => {
    const { _id, status, fileName, errorType, initiator, createdAt, duplicate, error, success, total } = item;
    let colorStatus = "appuninstall";
    let buttonTitle = cms("button.result");
    const badgeStatus = transformStatus({ status, duplicate, error, success });
    switch (status) {
      case statusKeys.completed:
        colorStatus = badgeStatus.toLowerCase();
        buttonTitle = cms("button.result");
        break;
      case statusKeys.error:
        colorStatus = badgeStatus.toLowerCase();
        buttonTitle = cms("button.result");
        break;
      default: {
        colorStatus = "default";
        buttonTitle = cms("button.progress");
      }
    }
    return (
      <ResourceItem id={_id} persistActions key={_id}>
        <Stack>
          <Stack.Item fill>
            <Heading>
              <Link onClick={() => history.push(`/activity/edit/view/${_id}`)}>
                {errorType && listData(cms).activityMessage[errorType.toLowerCase()]}
              </Link>
            </Heading>
            <Caption>{`${cms("label.fileName")}: ${fileName}`}</Caption>
            <Caption>{`${cms("label.doneBy")} ${initiator}`}</Caption>
            <Caption>
              <TextStyle variation="subdued">{`${cms("label.doneOn")} ${baseHelper.formatDate(createdAt)}`}</TextStyle>
            </Caption>
          </Stack.Item>
          <Stack.Item>
            <Badge status={baseHelper.getBadgeType(colorStatus)}>{badgeStatus || " "}</Badge>
          </Stack.Item>
          <Stack.Item>
            <Button
              size="slim"
              onClick={() => {
                setModal(!modal);
                setModalData({ duplicate, error, success, total, status, errorType, _id });
              }}
            >
              {buttonTitle}
            </Button>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };
  const renderModal = () => {
    return <UploadModal modal={modal} setModal={setModal} modalData={modalData} cms={cms} />;
  };
  if (isLoading) {
    return <SkeletonList />;
  }
  return (
    <>
      {renderModal()}
      <Layout.Section>
        {banner.isOpen && (
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => closeBanner()} />
        )}
        <br />
        <Card>
          <Tabs tabs={listData(cms).tabsData} selected={selectedTab} onSelect={handleTabChange} />
          <ResourceList
            items={errorLogsList}
            loading={loading || errorLoading}
            sortOptions={listData(cms).sortOptions}
            resourceName={listData(cms).resourceName}
            sortValue={sortValue}
            renderItem={renderItem}
            queryValue={search}
            onQueryClear={handleQueryClear}
            onQueryChange={handleSearchChange}
            onSortChange={handleSort}
            selectedItems={selectedItems}
            onSelectionChange={handleSelect}
            count={errorCount}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </Card>
      </Layout.Section>
    </>
  );
};
export default List;
