import { gql } from "apollo-boost";

const BULK_FULFILL = gql`
  mutation bulkFulfill($input: BulkFulfill!) {
    bulkFulfill(input: $input) {
      status
      data
      error
    }
  }
`;

export const BULK_FULFILL_LINEITEM = gql`
  mutation updateBulkOrderLineTracking($input: BulkUpdateOrderLineTracking) {
    updateBulkOrderLineTracking(input: $input) {
      data
      error
      status
    }
  }
`;

export default BULK_FULFILL;
