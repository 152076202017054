import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import mutation
import { SYNC_VENDOR_WIX_PRODUCT } from "app/product/apollo/mutations";

// import props
import { syncWixProduct } from "app/product/modules/generic/propTypes";

import { withErrorBoundary } from "lib/hoc";

const SyncWixProduct = (props) => {
  const { cms, history, setBanner } = props;
  const { CRITICAL, gql } = constant;

  const [syncVendorWixProduct, { loading: syncVendorWixProductLoading }] = useMutation(SYNC_VENDOR_WIX_PRODUCT);
  const syncWixProducts = async () => {
    try {
      const response = await syncVendorWixProduct();
      const responseError = baseHelper.getResponseError(response.data, gql.SYNC_WIX_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/wix");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWixProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: `${cms("section.connect.message.success.sync")}`,
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncVendorWixProductLoading} onClick={(value) => syncWixProducts(value)}>
      {cms("section.connect.button.importWixProduct")}
    </Button>
  );
};

SyncWixProduct.propTypes = syncWixProduct.type;
SyncWixProduct.defaultProps = syncWixProduct.default;

export default withErrorBoundary(SyncWixProduct);
