import React from "react";
import { FormLayout, Stack } from "@shopify/polaris";

// import props
import { reportingFilterProp } from "app/reporting/modules/operator/props";

import ReportingAutoComplete from "./reportingAutoComplete";

// import popover
import Popover from "./popover";

const ReportingFilters = (props) => {
  const {
    setFilterBy,
    selectedFilterOption,
    setSelectedFilterOption,
    filterOptions,
    setFilterOptions,
    timePeriod,
    handleTimePeriod,
    reportingConfig,
    selectedDates,
    setSelectedDates,
    filterOptionsResponse,
    submitReportingQuery,
  } = props;

  const handleAutoComplete = (type) => (value) => {
    if (type === "filterBy") {
      setSelectedFilterOption("");
      handleTimePeriod("");
      setSelectedDates(null);
      setFilterOptions(filterOptionsResponse[value]);
      return setFilterBy(value);
    }
    if (type === "filterOption") {
      return setSelectedFilterOption(value);
    }
    return value;
  };

  return (
    <>
      <Stack distribution="fill" alignment="center">
        <FormLayout>
          <FormLayout.Group>
            <ReportingAutoComplete
              label="Filter by:"
              initOptions={reportingConfig?.filterByOptions || []}
              allowMultiple
              handleAutoComplete={handleAutoComplete("filterBy")}
            />

            <ReportingAutoComplete
              label="Filter options:"
              initOptions={filterOptions || []}
              allowMultiple
              handleAutoComplete={handleAutoComplete("filterOption")}
            />

            <div style={{ top: "4px", position: "relative" }}>
              <Popover
                timePeriod={timePeriod}
                timeByOptions={reportingConfig?.timeByOptions}
                handleTimePeriod={handleTimePeriod}
                disabled={!selectedFilterOption}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                submitReportingQuery={submitReportingQuery}
              />
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Stack>
      <br />
      <br />
    </>
  );
};

ReportingFilters.propTypes = reportingFilterProp.type;

export default ReportingFilters;
