import React from "react";
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import CreditNoteList from "app/payments/modules/generic/creditNoteList/list";

const OperatorCreditNoteList = () => {
  return <CreditNoteList />;
};

export default withFeature(withErrorBoundary(OperatorCreditNoteList), {
  feature: constant.CREDIT_NOTE_LIST,
  // subFeature: constant.CREDIT_NOTE_LIST,
});
