import React, { useContext, useState } from "react";
import { Banner, Card, FormLayout, Select, Stack, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper } from "lib/helpers";

const Price = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;

  const {
    FLAT,
    symbol,
    value: { MAX_FLAT, MIN_PRICE },
  } = constant;

  const {
    basePricing,
    cms,
    currencyPrice,
    currencySymbol,
    handleChange = () => {},
    handleCurrencyPrice,
    isWholesalePriceVisible,
    productLabelForm = {},
    required = () => {},
    setDisabledButton,
    setWholesalePrice,
    variantData = {},
    wholesalePrice,
  } = props;

  const {
    comparePrice: comparePriceLabel,
    discount: discountLabel,
    markup: markupLabel,
    price: priceLabel,
  } = productLabelForm;

  const { price, comparePrice = 0, vendorDiscount: variantVendorDiscount } = variantData;

  const vendorDiscount = variantVendorDiscount;
  const [type, setType] = useState({
    vendorDiscount: { labelType: (vendorDiscount && vendorDiscount.type) || FLAT },
  });
  const options = [
    { label: cms("section.price.label.flat"), value: constant.FLAT },
    { label: cms("section.price.label.percentage"), value: constant.PERCENTAGE },
  ];

  const label = (value, defaultCms) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCms;
  };

  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if ("type" in value) {
      setType((preState) => ({ ...preState, [field]: { labelType: value.type } }));
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= 100) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
  };

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  const renderPricingComponent = () => {
    if (!basePricing || !basePricing.currencyCode) return null;
    const { price: currencyBasePrice = 0, comparePrice: currencyCompareBasePrice = 0, currencyCode } =
      basePricing || {};
    return (
      <FormLayout.Group>
        <TextField
          id="price"
          label={`${label(priceLabel, cms("section.price.label.price"))}${required(priceLabel)}`}
          min={0}
          value={(currencyBasePrice && currencyBasePrice.toString()) || ""}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          onChange={(val) => {
            handleCurrencyPrice(constant.PRICE, acceptOnlyValidInput(val, currencyBasePrice), currencyCode);
            setDisabledButton(false);
          }}
        />
        <TextField
          id="comparePrice"
          label={`${label(comparePriceLabel, cms("section.price.label.compare"))}${required(comparePriceLabel)}`}
          min={0}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          value={(currencyCompareBasePrice && currencyCompareBasePrice.toString()) || ""}
          onChange={(val) => {
            handleCurrencyPrice(
              constant.COMPARE_PRICE,
              acceptOnlyValidInput(val, currencyCompareBasePrice),
              currencyCode
            );
            setDisabledButton(false);
          }}
        />
      </FormLayout.Group>
    );
  };

  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || ""}
      placeholder={cms("section.price.placeholder.type")}
      label={cms("section.price.label.type")}
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );

  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      {basePricing && basePricing.currencyCode && currencyPrice ? (
        <>
          <Banner status={constant.INFO} isOpen>
            {`${cms("label.rate")} ${moneyFormat}1 = ${currencySymbol}${currencyPrice}`}
          </Banner>
          <br />
        </>
      ) : null}
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label={`${label(priceLabel, cms("section.price.label.price"))}${required(priceLabel)}`}
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={currencyPrice}
            onChange={(value) => {
              handleChange(constant.PRICE, acceptOnlyValidInput(value, price));
              setDisabledButton(false);
            }}
          />
          <TextField
            id="comparePrice"
            label={`${label(comparePriceLabel, cms("section.price.label.compare"))}${required(comparePriceLabel)}`}
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={currencyPrice}
            onChange={(value) => {
              handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(value, comparePrice));
              setDisabledButton(false);
            }}
          />
        </FormLayout.Group>
        {isWholesalePriceVisible && (
          <FormLayout.Group>
            <TextField
              id="wholesalePrice"
              label={cms("section.price.label.wholesale")}
              min={0}
              placeholder="0.00"
              prefix={moneyFormat}
              value={(wholesalePrice && wholesalePrice.toString()) || ""}
              onChange={(value) => {
                setWholesalePrice(acceptOnlyValidInput(value, wholesalePrice));
                setDisabledButton(false);
              }}
            />
            <Stack>
              <Stack.Item fill />
            </Stack>
          </FormLayout.Group>
        )}
        {basePricing && basePricing.currencyCode && currencyPrice ? renderPricingComponent() : null}
        <FormLayout.Group>
          <TextField
            id="discount"
            label={`${label(discountLabel, cms("section.price.label.discount"))}${required(discountLabel)}`}
            min="0"
            max={(vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && 100) || MAX_FLAT}
            value={(vendorDiscount && vendorDiscount.price && vendorDiscount.price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            onChange={(value) => {
              handlePriceChange(
                constant.VENDOR_DISCOUNT,
                vendorDiscount,
                { price: acceptOnlyValidInput(value, vendorDiscount.price) },
                type.vendorDiscount.labelType
              );
              setDisabledButton(false);
            }}
            disabled={!vendorDiscount}
            connectedRight={selectWeight(constant.VENDOR_DISCOUNT, vendorDiscount)}
            suffix={vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && symbol.PERCENTAGE}
            prefix={vendorDiscount && vendorDiscount.type === constant.FLAT && (moneyFormat || symbol.DOLLAR)}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Price;
