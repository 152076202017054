import { gql } from "apollo-boost";

const BULK_ACCOUNT = gql`
  mutation bulkAccount($input: BulkAction!) {
    bulkAccount(input: $input) {
      status
      data
      error
    }
  }
`;
export default BULK_ACCOUNT;
