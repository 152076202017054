import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Caption,
  Card,
  Collapsible,
  Checkbox,
  ChoiceList,
  Frame,
  Link,
  Modal,
  ResourceItem,
  Spinner,
  Stack,
  Tag,
  TextContainer,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";

import {
  CircleCancelMinor,
  CircleTickOutlineMinor,
  MarkFulfilledMinor,
  ExportMinor,
  ViewMajor,
  WholesaleMajor,
} from "@shopify/polaris-icons";

import { useQuery, useMutation } from "react-apollo";

import { QuickAction, QuickReport } from "app/reports";
import { SingleColumnLayout } from "layout/private/components";
import { Banner, ModalPopup, PermissionBanner, ResourceList, Toast } from "lib/components";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { ProductCard, ProductSubCard, StyledOrderVersioning } from "asset/styles/globalStyle";
import LineItem from "app/orders/modules/provider/features/list/component/lineItem/lineItem";
import OrderVersioning from "app/orders/modules/generic/orderVersioning/orderVersioning";
import { GET_ORDER_LIST } from "app/orders/apollo/queries";
import { BULK_ACCEPT, BULK_REJECT, UPDATE_ORDER_LINE_DELIVERY_STATUS } from "app/orders/apollo/mutations";
import { GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";
import { GET_SELLER_FULFILLMENT } from "app/setup/apollo";
import { GET_FULFILLMENT_TRACKING_DETAILS_SETTING } from "app/setup/apollo/queries";
import { updateOrderLineTracking } from "app/orders/apollo/updateOrderNote";
import { baseHelper, errorHelper, imageHelper, statusHelper, storageHelper } from "lib/helpers";
import MoreFilters from "app/orders/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";
import { ORDER_LIST } from "app/orders/apollo/subscriptions/index";
import ProviderExportOrder from "app/orders/modules/provider/features/export";
import FulfillOrder from "app/orders/modules/operator/features/fulfill/fulfill";
import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
import listData from "./orderListData";

const ProviderOrderList = () => {
  const { cms, currentUser, history, isShopConnected, isShopifyOldVendor, setting } = useContext(PrivateContext);
  const {
    fulfillmentScopeExists = false,
    introductorySeller: sellerId,
    roles: { name },
    moneyFormat,
  } = currentUser;
  const { isReviewOrder = false } = setting || {};

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const {
    ACCEPT,
    ACCEPTED,
    ACCEPT_LABEL,
    CLOSED,
    COLLECT,
    COLLECTED,
    DROPSHIP,
    FULFILLED,
    FULFILLED_BY_OPERATOR,
    gql,
    HIDE_CAPITAL,
    PARTIAL,
    PARTIAL_COLLECT,
    PARTIAL_REFUND,
    PARTIALLY_COMPLETE,
    PARTIALLY_FULFILLED,
    REFUND,
    REJECT,
    REJECT_LABEL,
    REJECTED,
    RESERVE,
    SHIPPED,
    SHOW,
    UNFULFILLED,
  } = constant;

  const { mainLink, filterByStatusOption } = listData(cms);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const paramListSearch = (queryParam.list_search && queryParam.list_search.split(",")) || [];
  const [selectedOptions, setSelectedOptions] = useState(paramListSearch || []);
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [tagInputValue, setTagInputValue] = useState([]);
  const [productList, setProductList] = useState([]);

  const [checked, setChecked] = useState(false);
  const [restock, setRestock] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bulkToggle, setBulkToggle] = useState({});
  const [historyActive, setHistoryActive] = useState({});
  const [disable, setDisable] = useState(false);
  const [isExportModal, setIsExportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rejectModel, setRejectModal] = useState(false);
  const [isFulfillModal, setIsFulfillModal] = useState(false);

  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [message, setMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [orderList, setOrderList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [active, setActive] = useState({});
  const [activePopover, setActivePopover] = useState({});
  const [expanded, setExpanded] = useState({});
  const [trackingModal, setTrackingModal] = useState({});

  const [totalOrderItem, setTotalOrderItem] = useState();
  const [totalOrderVendor, setTotalOrderVendor] = useState();
  const [isOrderId, setIsOrderId] = useState();
  const [isOrderReject, setIsOrderReject] = useState();
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [deliveryOrderId, setDeliveryOrderId] = useState("");
  const [productTag, setProductTag] = useState();
  const [isProductList, setIsProductList] = useState(false);

  const [lineReturn, setLineReturn] = useState({
    selectedOrder: false,
    showModal: false,
  });
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabledData, setIsEnabledData] = useState(false);


  const handleChange = useCallback(() => {
    setRejectModal(!rejectModel);
    setChecked(false);
    setRestock(false);
  }, [rejectModel]);

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : queryParam.filter,
    path: queryParam.path,
    sort_name: "shopifyOrderDate",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: taggedWith ? queryParam.list_search : tagInputValue || "",
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };

  const { data: order, loading: orderLoading, error: orderError, subscribeToMore, refetch } = useQuery(GET_ORDER_LIST, {
    variables: {
      input: inputData,
    },
  });

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  const [bulkAccept, { loading: loadingBulkAccept }] = useMutation(BULK_ACCEPT, {
    refetchQueries: [
      {
        query: GET_ORDER_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [bulkReject, { loading: loadingBulkReject }] = useMutation(BULK_REJECT, {
    refetchQueries: [
      {
        query: GET_ORDER_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [updateOrderDelivery, { loading: loadingUpdateOrderDelivery }] = useMutation(
    UPDATE_ORDER_LINE_DELIVERY_STATUS,
    {
      refetchQueries: [
        {
          query: GET_ORDER_LIST,
          variables: {
            input: inputData,
          },
        },
      ],
    }
  );

  const { data: trackingData, loading: fulfillSettingLoading } = useQuery(GET_FULFILLMENT_TRACKING_DETAILS_SETTING);


  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );

  const { loading: loadingProductListing, data: dataProductList } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (dataProductList) {
      const productistResponse = baseHelper.getResponseData(dataProductList, "getProductListingSetting");
      if (productistResponse) {
        const { isEnabled = false } = productistResponse;
        setIsProductList(isEnabled);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductList]);

  const linkData = [
    {
      action: () => setIsExportModal(!isExportModal),
      caption: "Export your orders",
      id: "exportOrder",
      icon: ExportMinor,
      label: cms("section.orderList.label.exportOrder"),
      shop: true,
      primary: true,
    },
  ];

  if (isProductList) {
    linkData.push({
      action: () => history.push("/orders/bulk"),
      caption: "Bulk Fulfill Orders",
      id: "fulfillOrder",
      primary: true,
      label: "Bulk Fulfill Orders",
      icon: ExportMinor,
    });
  }

  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(orderError),
      });
    }
  }, [orderError]);

  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LIST);
  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    if (selectedOptions && selectedOptions.length) {
      baseHelper.setUrl(history, { list_search: selectedOptions, page: 1 });
    }
    return baseHelper.setUrl(history, {
      filter: "",
      list_search: "",
      list_filter: selectedOptions && selectedOptions.length ? constant.PRODUCT : "",
      page: 1,
    });
  }, [filterByStatus, history, selectedOptions]);

  useEffect(() => {
    if (trackingData) {
      const resData = baseHelper.getResponseData(trackingData, "getFulfillmentTrackingDetailsSetting");
      const resError = baseHelper.getResponseError(trackingData, "getFulfillmentTrackingDetailsSetting");
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        return;
      }
      if (resData) {
        const { isEnabled } = resData || {};
        setIsEnabledData(isEnabled);
      }
    }
  }, [trackingData, setBanner, "getFulfillmentTrackingDetailsSetting"]);

  const removeTag = useCallback(
    (tag, key) => () => {
      if (key === constant.PRODUCT) {
        setSelectedOptions((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
      }
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const fulfillmentView = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};

  const { dropship = {} } = fulfillmentView || {};
  const { isThirdPartyShipping = false } = dropship || {};

  const itemCollapseAction = (id) => {
    setActive((prev) => {
      return {
        ...prev,
        [id]: !active[id],
      };
    });
    setExpanded((prev) => {
      return {
        ...prev,
        [id]: !expanded[id],
      };
    });
  };

  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue.trim(), page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.PRODUCT:
            return [
              setSelectedOptions(""),
              baseHelper.setUrl(
                history,
                { list_search: "", list_filter: "", page: 1 },
                setSearch(""),
                setSelectedFilter("")
              ),
            ];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const historyCollapseAction = (id) => {
    setBulkToggle((prev) => {
      return {
        ...prev,
        [id]: !bulkToggle[id],
      };
    });
    setExpanded((prev) => {
      return {
        ...prev,
        [id]: !expanded[id],
      };
    });
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  let productNameTag = [];
  if (selectedOptions && selectedOptions.length) {
    const fData = [...new Set(selectedOptions)];
    productNameTag =
      fData &&
      fData.map((option) => {
        const pData = (productList || []).find((item) => item.value === option);
        return (
          <Tag key={option} onRemove={removeTag(option, constant.PRODUCT)}>
            {baseHelper.ucFirst(pData.label || option)}
          </Tag>
        );
      });
  }

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    setSelectedOptions("");
    setSearchInput("");
    setFilterByStatus(null);
    history.push(`${history.location.pathname}?${search}`);
  };

  const getSecondaryComponent = () => (
    <>
      <QuickReport />
      <QuickAction link={linkData} />
    </>
  );

  const getTabs = {
    0: "all",
    1: "new",
    2: "accepted",
    3: "partial",
    4: "rejected",
    5: "shipped",
    6: "closed",
  };
  const handleSelect = (item) => {
    setSelectedItems(item);
  };

  useEffect(() => {
    subscribeToMore({
      document: ORDER_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        refetch();
        return null;
      },
    });
  }, [subscribeToMore, refetch]);
  const pageContent = {
    label: {
      status: cms("section.orderList.label.status"),
      fulfillmentStatus: cms("section.orderList.label.fulfillmentStatus"),
    },
  };
  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      if (updateOrderTracking) {
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: cms("message.success.orderFulfilled"),
          isOpen: true,
        });
        refetch();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataUpdateOrderLineTracking, cms, refetch]);

  const toggleModal = (shopifyLineItemId = false) => {
    setLineReturn((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedOrder: !prevState.showModal && shopifyLineItemId,
    }));
    setDisable(false);
  };

  const promotedBulkActions = [
    {
      content: "Bulk Action",
    },
  ];

  const handlePageChange = (value) => {
    setSelectedItems([]);
    setPage(value);
  };

  const bulkRejectAction = async () => {
    setChecked(false);
    setRejectModal(false);
    const orderIds = isOrderReject ? [isOrderReject] : [...selectedItems];
    const refundData = [];
    orderIds.forEach((orderId) => {
      orderList.map((item) => {
        if (orderId === item._id) {
          const orderLineIds =
            item.orderLines &&
            item.orderLines.map((val) => {
              const dataToAdd = {
                isAddToInventory: item.isAddToInventory || false,
                orderId: item._id,
                orderLineItemId: val._id,
                rejectQuantity: val.quantity,
                fulfillmentType: val.fulfillmentType || constant.DROPSHIP,
              };
              refundData.push(dataToAdd);
              return false;
            });
          return orderLineIds;
        }
        return null;
      });
    });

    let formData = {};
    formData = {
      isRefund: false,
      isRestock: restock,
      refund: refundData,
    };
    setSelectedItems([]);
    await bulkReject({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_REJECT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_REJECT);
          if (resData) {
            setMessage(
              `Order line rejected successfully,
               Please manage the transaction for this amount from your shopify account manually.`
            );
            setRestock(false);
            setBanner({ isOpen: false, title: "", status: constant.CRITICAL });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const bulkAction = async () => {
    const orderIds = isOrderId ? [isOrderId] : [...selectedItems];
    let formData = {};
    const lineItemIds = [];
    orderIds.forEach((orderId) => {
      let orderLineItemIds;
      orderList.map((item) => {
        if (orderId === item._id) {
          orderLineItemIds =
            item &&
            item.orderLines.map((value) => {
              return value._id;
            });
        }
        return lineItemIds;
      });
      lineItemIds.push(...orderLineItemIds);
    });
    formData = {
      lineItemIds,
    };
    setSelectedItems([]);
    await bulkAccept({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.BULK_ACCEPT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_ACCEPT);
          if (resData) {
            setMessage("Order line accepted successfully.");
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleChecked = useCallback((newChecked) => setChecked(newChecked), []);
  const handleRestockChecked = useCallback((newChecked) => setRestock(newChecked), []);

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Checkbox label={cms("label.checkbox")} checked={checked} onChange={handleChecked} />
      </Stack.Item>
      <Stack.Item>
        <Button
          onClick={(event) => {
            handleChange(event);
            handleChecked(!event);
            setRestock(false);
          }}
        >
          {cms("common.button.cancel")}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button primary onClick={bulkRejectAction} disabled={!checked}>
          {cms("common.button.confirm")}
        </Button>
      </Stack.Item>
    </Stack>
  );

  const renderReject = () => {
    return (
      <Modal open={rejectModel} onClose={handleChange} title={cms("modal.title")} footer={actions()}>
        <Modal.Section>
          <Stack vertical>
            {isReviewOrder && (
              <Stack.Item>
                <Banner isOpen status="info">
                  <p>{cms("modal.note")}</p>
                </Banner>
              </Stack.Item>
            )}
            <Stack.Item>
              <p>{cms("modal.description")}</p>
            </Stack.Item>
            {isReviewOrder && (
              <Stack.Item>
                <Checkbox label={cms("modal.restock")} checked={restock} onChange={handleRestockChecked} />
              </Stack.Item>
            )}
          </Stack>
        </Modal.Section>
      </Modal>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const bulkOrderActions = async (formData) => {
    const { ids, key } = formData;
    ids.forEach((orderId) => {
      const index = orderList.findIndex((orders) => {
        const { _id: id } = orders;
        return id === orderId;
      });
      if (orderList[index].status === constant.NEW) {
        orderList[index] = {
          ...orderList[index],
          status: key === constant.ACCEPT ? constant.ACCEPTED : constant.REJECTED,
        };
      }
    });
    return {
      status: "Success",
      message: `${ids.length > 1 ? "Orders are" : "Order is"} ${
        key === constant.ACCEPT ? "accepted" : "rejected"
      } successfully.`,
    };
  };

  const bulkActionButton = (key) => {
    let disabled = true;
    if (selectedItems && selectedItems.length) {
      selectedItems.forEach((orderId) => {
        const index = orderList.findIndex((orders) => {
          const { _id: id } = orders;
          return id === orderId;
        });
        if (
          key === constant.ACCEPT &&
          orderList[index] &&
          (orderList[index].status === constant.NEW || orderList[index].status === constant.PARTIAL)
        ) {
          disabled = false;
        }
        if (
          (key === constant.REJECT &&
            orderList[index] &&
            (orderList[index].status === constant.NEW || orderList[index].status === constant.ACCEPTED)) ||
          orderList[index].status === constant.PARTIAL
        ) {
          disabled = false;
        }
        if (key === constant.REJECT && orderList[index] && orderList[index].rejectedByVendor) {
          disabled = true;
        }
        if (key === constant.FULFILLED && orderList[index] && orderList[index].status === constant.ACCEPTED) {
          disabled = false;
        }
      });
    }
    return disabled;
  };

  const bulkActions = [
    {
      content: "Bulk Accept",
      onAction: () => bulkAction(constant.ACCEPT),
      icon: CircleTickOutlineMinor,
      disabled:
        bulkActionButton(constant.ACCEPT) ||
        (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
          getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
          getTabs[selectedTab] !== constant.NEW.toLowerCase()),
    },
    {
      content: "Bulk Reject",
      onAction: () => setRejectModal(true),
      icon: CircleCancelMinor,
      destructive: true,
      disabled:
        bulkActionButton(constant.REJECT) ||
        (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
          getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
          getTabs[selectedTab] !== constant.ACCEPTED.toLowerCase() &&
          getTabs[selectedTab] !== constant.NEW.toLowerCase()),
    },
  ];
  if (isProductList) {
    bulkActions.push({
      content: "Bulk Fulfill",
      onAction: () => setIsFulfillModal(true),
      icon: MarkFulfilledMinor,
      disabled:
        bulkActionButton(constant.FULFILLED) ||
        (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
          getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
          getTabs[selectedTab] !== constant.NEW.toLowerCase()),
    });
  }

  const checkDisabled = () => {
    let check = true;
    orderList.map((item) => {
      if (item._id === deliveryOrderId) {
        item.orderLines.map((element) => {
          let isReturnInitatied = false;
          const {
            actualQuantity,
            isDelivered,
            fulfillmentType,
            fulfillmentStatus,
            quantity,
            return: orderReturn,
            returnedQuantity,
          } = element;
          if (orderReturn && orderReturn.status) {
            isReturnInitatied = true;
          }

          if (
            element &&
            !isDelivered &&
            (!isReturnInitatied ||
              (returnedQuantity < (quantity || actualQuantity) &&
                orderReturn &&
                orderReturn.status === constant.ACCEPT)) &&
            [constant.FULFILLED, constant.SHIPPED_BY_VENDOR, constant.READY_TO_PICK].includes(fulfillmentStatus) &&
            [constant.DROPSHIP, constant.CONSIGNMENT, constant.PICKUP, constant.HYBRID].includes(fulfillmentType)
          ) {
            check = false;
            return check;
          }
        });
      }
    });
    return check;
  };

  const updateOrderDeliveryAction = async () => {
    const formData = { orderLineArray: [] };

    orderList.map((item) => {
      if (item._id === deliveryOrderId) {
        item.orderLines.map((element) => {
          let isReturnInitatied = false;
          const {
            actualQuantity,
            isDelivered,
            fulfillmentType,
            fulfillmentStatus,
            quantity,
            return: orderReturn,
            returnedQuantity,
          } = element;
          if (orderReturn && orderReturn.status) {
            isReturnInitatied = true;
          }

          if (
            element &&
            !isDelivered &&
            (!isReturnInitatied ||
              (returnedQuantity < (quantity || actualQuantity) &&
                orderReturn &&
                orderReturn.status === constant.ACCEPT)) &&
            [constant.FULFILLED, constant.SHIPPED_BY_VENDOR, constant.READY_TO_PICK].includes(fulfillmentStatus) &&
            [constant.DROPSHIP, constant.CONSIGNMENT, constant.PICKUP, constant.HYBRID].includes(fulfillmentType)
          ) {
            formData.orderLineArray.push({
              id: element._id,
            });
          }
        });
      }
    });

    await updateOrderDelivery({ variables: { input: { ...formData } } })
      .then((res) => {
        setSelectedItems([]);
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_ORDER_LINE_DELIVERY_STATUS);
          const responseDataError = baseHelper.getResponseError(
            res.data,
            constant.gql.UPDATE_ORDER_LINE_DELIVERY_STATUS
          );
          if (resData) {
            setMessage(cms("message.success.markDeliver"));
            setBanner({ isOpen: false, title: "", status: constant.CRITICAL });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const renderItem = (item) => {
    const {
      _id: id,
      actualQuantity,
      createdAt,
      financialStatus = constant.financialStatus.PENDING,
      fulfillmentType,
      image,
      isAutoExpiry,
      isAutoReject,
      isConsignmentPick,
      isRefund,
      isVendorFulfilled,
      orderNumber,
      quantity,
      return: orderReturn,
      orderLines,
      shopifyOrderDate,
    } = item;

    let vendorReject = false;

    const [shopifyLineId] = orderLines;
    const { shopifyLineItemId } = shopifyLineId;
    let { fulfillmentStatus: orderFulfillmentStatus, status } = item;
    const fulfillment = [];
    const orderLineStatus = [];
    let returnOrder = false;
    let returnedStatus = "";
    const deliveredStatus = [];
    const isLineOrderReturn = [];
    let isAcceptedByOperator;
    let isFulfilledByOperator;

    const orderFulfillmentType = [constant.DROPSHIP, constant.CONSIGNMENT, constant.PICKUP, constant.HYBRID];
    const orderStatus = [constant.FULFILLED, constant.PARTIALLY_FULFILLED, constant.SHIPPED, constant.PARTIALLY_REFUND];

    orderLines.forEach((line) => {
      const {
        actualQuantity: actualLineQuantity,
        fulfillmentType = constant.DROPSHIP,
        fulfillmentStatus: lineFulfillmentStatus,
        isReturn = false,
        quantity: lineQuantity,
        status: lineStatus = "",
        isDelivered = false,
        rejectedByVendor,
        return: returnedOrder,
        returnedQuantity,
      } = line;
      vendorReject = rejectedByVendor;
      if (returnedQuantity < (lineQuantity || actualLineQuantity) && returnedOrder && returnedOrder.status) {
        returnedStatus = returnedOrder.status;
      }

      isAcceptedByOperator = vendorReject && status === constant.ACCEPTED;
      isFulfilledByOperator = vendorReject && status === constant.SHIPPED;

      if (vendorReject) {
        status = constant.REJECTED;
      }

      if (
        returnedQuantity < (actualLineQuantity || actualLineQuantity) &&
        returnedOrder &&
        returnedOrder.status === constant.ACCEPT
      ) {
        isLineOrderReturn.push(false);
      } else if (returnedOrder && returnedOrder.status === constant.REJECT) {
        isLineOrderReturn.push(true);
      } else if (lineFulfillmentStatus === constant.FULFILLED && !isDelivered) {
        isLineOrderReturn.push(isReturn);
      }
      fulfillment.push(fulfillmentType);
      if (
        !isDelivered &&
        lineStatus === constant.CLOSED &&
        [constant.CONSIGNMENT, constant.PICKUP].includes(fulfillmentType)
      ) {
        status = SHIPPED;
      }
      if (
        !isDelivered &&
        (!isReturn || returnedStatus !== constant.REJECT) &&
        orderStatus.includes(orderFulfillmentStatus) &&
        lineFulfillmentStatus === constant.FULFILLED &&
        orderFulfillmentType.includes(fulfillmentType)
      ) {
        orderLineStatus.push(lineStatus);
      }
      deliveredStatus.push(!!isDelivered);
      returnOrder = isReturn;
    });

    let fulfillmentStatus;
    let reserveOrCollect;
    fulfillment.forEach((fulfilType) => {
      fulfillmentStatus =
        [constant.PICKUP, constant.CONSIGNMENT].includes(fulfilType) && isVendorFulfilled
          ? (isConsignmentPick && cms("section.orderList.label.ready")) || cms("section.orderList.label.ship")
          : orderFulfillmentStatus;
      reserveOrCollect = [RESERVE, COLLECT].includes(fulfilType);
    });
    if (fulfillmentStatus === PARTIALLY_FULFILLED) {
      fulfillmentStatus = FULFILLED;
    }
    if (fulfillmentStatus === FULFILLED && vendorReject) {
      fulfillmentStatus = FULFILLED_BY_OPERATOR;
    }
    if (financialStatus === REFUND && reserveOrCollect) {
      status = constant.REJECTED;
    }

    let isReturnInitatied = false;
    let lineReturnStatus = "";
    const isReserve = [SHIPPED, CLOSED].includes(status) && fulfillmentType === RESERVE;
    const isCollect = [SHIPPED, CLOSED].includes(status) && fulfillmentType === COLLECT;
    const isDropship = [SHIPPED, CLOSED].includes(status) && fulfillment.includes(DROPSHIP);

    if (isDropship && !returnOrder && filterByStatus && !filterByStatus.length) {
      status = SHIPPED;
    }

    if (isReserve || (isCollect && filterByStatus && !filterByStatus.length)) {
      status = COLLECTED;
    }
    let statusType;
    fulfillment.forEach((fulfilType) => {
      statusType = status === ACCEPTED && [RESERVE, COLLECT].includes(fulfilType) && fulfillmentStatus === PARTIAL;
    });
    if (statusType && filterByStatus && !filterByStatus.length) {
      status = PARTIAL_COLLECT;
    }
    if (status === constant.REJECTED) {
      orderFulfillmentStatus = REFUND;
    }

    const isPartialRefund = orderFulfillmentStatus === REFUND && !!(actualQuantity || quantity);

    if (isPartialRefund) {
      orderFulfillmentStatus = PARTIAL_REFUND;
    }

    const isReturn = orderReturn && Object.keys(orderReturn).length;
    if (isReturn && orderReturn.status) {
      const { status: orderReturnStatus } = orderReturn;
      isReturnInitatied = true;
      lineReturnStatus = listData(cms).lineReturnStatus[orderReturnStatus];
    }

    const isRejected = orderLines.every((lineItem) => lineItem && lineItem.rejectedByVendor);

    if (isRejected) {
      status = constant.REJECTED;
    }

    const handleRejectModal = (rejectOrderId) => {
      setIsOrderReject(rejectOrderId);
    };

    const manageBulk = (field) => {
      let disabled = true;
      if (isOrderId) {
        orderList.map((orderListElement) => {
          if (
            isOrderId === orderListElement._id &&
            (orderListElement.status === constant.NEW || orderListElement.status === constant.PARTIAL)
          ) {
            disabled = false;
          }
          if (
            field === constant.REJECT &&
            isOrderId === orderListElement._id &&
            orderListElement.status !== constant.REJECTED &&
            orderListElement.status !== constant.CLOSED
          ) {
            disabled = false;
          }
          return disabled;
        });
      }
      return disabled;
    };

    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={constant.LARGE} />;

    // const orderFulfillmentType = [constant.DROPSHIP, constant.CONSIGNMENT, constant.PICKUP, constant.HYBRID];
    // const orderStatus = [constant.FULFILLED, constant.PARTIALLY_FULFILLED, constant.SHIPPED, constant.PARTIALLY_REFUND];

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajor,
        onAction: () => history.push(`${mainLink.orderNumber}${id}`),
      },
      {
        content: ACCEPT_LABEL,
        onAction: () => bulkAction(constant.ACCEPT),
        icon: CircleTickOutlineMinor,
        disabled:
          manageBulk(constant.ACCEPT) ||
          (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
            getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
            getTabs[selectedTab] !== constant.NEW.toLowerCase()),
      },
      {
        content: REJECT_LABEL,
        onAction: () => {
          handleRejectModal();
          setRejectModal(true);
        },
        icon: CircleCancelMinor,
        destructive: true,
        disabled:
          manageBulk(constant.REJECT) ||
          (getTabs[selectedTab] !== constant.PENDING.toLowerCase() &&
            getTabs[selectedTab] !== constant.ALL.toLowerCase() &&
            getTabs[selectedTab] !== constant.ACCEPTED.toLowerCase() &&
            getTabs[selectedTab] !== constant.NEW.toLowerCase()),
      },
      {
        content: cms("section.orderList.button.markedAsDelivered"),
        onAction: () => {
          updateOrderDeliveryAction();
        },
        icon: WholesaleMajor,
        destructive: false,
        disabled: checkDisabled(),
      },
    ];

    const handlePopover = (orderId) => {
      if (popoverDisable) {
        return setActivePopover(false);
      }
      setSelectedItems([]);
      setActivePopover({ [orderId]: !activePopover[orderId] });
      setIsOrderId(activePopover[orderId] ? "" : orderId);
      handleRejectModal(orderId);
      setDeliveryOrderId(orderId);
    };

    const manageActions = (value) => {
      if (value === constant.VIEW_ID) {
        history.push(`${mainLink.orderNumber}${id}`);
      }
      if (value === constant.RETURN) {
        toggleModal(shopifyLineItemId);
      }
    };

    const financialStatusBadge = (financialStatus === constant.PAID && constant.PAID) || constant.CRITICAL;

    return (
      <ResourceItem id={id} persistActions>
        {/* {fulfillment()} */}
        <ProductCard className={`product-card ${expanded[id] ? "Polaris-ResourceItem--selected" : ""}`}>
          <div className="list-item">
            <Stack alignment="leading">
              {/* <Stack.Item>{thumbnail}</Stack.Item> */}
              <Stack.Item fill>
                <div className="ellipsis">
                  <Link onClick={() => history.push(`${mainLink.orderNumber}${id}`)}>{`Order #${orderNumber}`}</Link>
                  {/* <Caption>
                    {`${cms("section.orderList.label.lineItemId")}: `}
                    <span>{`#${shopifyLineItemId}`}</span>
                  </Caption> */}
                  <Stack>
                    <Stack.Item>
                      <Caption>
                        <TextStyle variation="subdued">
                          {` ${cms("section.orderList.label.createdOn")}: ${baseHelper.formatDate(
                            createdAt || shopifyOrderDate
                          )}`}
                        </TextStyle>
                      </Caption>
                    </Stack.Item>
                    <Stack.Item>
                      <div className="history-items-versioning">
                        <Button
                          plain
                          disclosure={bulkToggle[id] ? "up" : "down"}
                          onClick={() => historyCollapseAction(id)}
                        >
                          <p className="content">{cms("section.orderVersion.button.history")}</p>
                        </Button>
                      </div>
                    </Stack.Item>
                  </Stack>
                  <div>
                    <Stack>
                      {!deliveredStatus.includes(false)
                        ? !isRefund &&
                          !isReturn &&
                          !isAutoExpiry && (
                            <Stack.Item>
                              <Tooltip content={pageContent.label.status} preferredPosition="above">
                                <Badge status={baseHelper.getBadgeType(status)}>{constant.DELEVERED}</Badge>
                              </Tooltip>
                            </Stack.Item>
                          )
                        : !isRefund &&
                          !isReturn &&
                          !isAutoExpiry && (
                            <Stack.Item>
                              <Tooltip content={pageContent.label.status} preferredPosition="above">
                                <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(status)}</Badge>
                              </Tooltip>
                            </Stack.Item>
                          )}
                      {isRefund && isAutoReject && (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.status} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType(status)}>
                              {baseHelper.ucFirst(constant.AUTO_REJECT)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}
                      {isAcceptedByOperator && (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.status} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType("accepted by operator")}>Accepted By Operator</Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}
                      {isAutoExpiry && (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.status} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType(status)}>
                              {baseHelper.ucFirst(constant.NO_SHOW)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}

                      {isRefund ? (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType(orderFulfillmentStatus)}>
                              {baseHelper.ucFirst(orderFulfillmentStatus)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      ) : (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                            <Badge
                              progress={orderFulfillmentStatus === PARTIALLY_FULFILLED ? PARTIALLY_COMPLETE : ""}
                              status={baseHelper.getBadgeType(fulfillmentStatus)}
                            >
                              {baseHelper.ucFirst(fulfillmentStatus)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}
                      {financialStatus && (
                        <Stack.Item>
                          <Tooltip content={pageContent.label.status} preferredPosition="above">
                            <Badge status={baseHelper.getBadgeType(financialStatusBadge)}>
                              {baseHelper.getFinancialStatus(financialStatus)}
                            </Badge>
                          </Tooltip>
                        </Stack.Item>
                      )}
                    </Stack>
                  </div>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div className="manage-button">
                  <Popover
                    active={activePopover[id]}
                    setActive={() => {
                      handlePopover(id);
                    }}
                    options={option}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <StyledOrderVersioning>
            <div className="itemcollapse">
              <Collapsible
                open={bulkToggle && bulkToggle[id]}
                id="timeline"
                transition={{ duration: "150ms", timingFunction: "ease" }}
              >
                <OrderVersioning cms={cms} orderId={id} />
              </Collapsible>
              <div className="history-items-after-version">
                <Button
                  plain
                  removeUnderline
                  disclosure={active[id] ? "up" : "down"}
                  onClick={() => itemCollapseAction(id)}
                >
                  {orderLines.length > 1
                    ? `${active[id] ? HIDE_CAPITAL : SHOW} ${orderLines.length} items`
                    : `${active[id] ? HIDE_CAPITAL : SHOW} ${orderLines.length} item`}
                </Button>
              </div>
            </div>
          </StyledOrderVersioning>
          <Collapsible
            open={active[id]}
            id={`timeline-${active[id] + 1}`}
            transition={{ duration: "150ms", timingFunction: "ease" }}
          >
            <ProductSubCard className="collapsible-card">
              <LineItem
                active={active}
                setActive={setActive}
                cms={cms}
                disable={disable}
                id={id}
                initialTrackingCompany={initialTrackingCompany}
                initialTrackingNumber={initialTrackingNumber}
                isThirdPartyShipping={isThirdPartyShipping}
                lineReturn={lineReturn}
                loadingOrderLineTracking={loadingOrderLineTracking}
                manageActions={manageActions}
                moneyFormat={moneyFormat}
                option={option}
                orderId={id}
                orderList={orderList}
                refetch={refetch}
                role={name}
                setBanner={setBanner}
                setDisable={setDisable}
                setInitialTrackingCompany={setInitialTrackingCompany}
                setInitialTrackingNumber={setInitialTrackingNumber}
                setLineReturn={setLineReturn}
                setOrder={setDeliveryOrderId}
                setTotalOrderItem={setTotalOrderItem}
                setTotalOrderVendor={setTotalOrderVendor}
                setTrackingModal={setTrackingModal}
                setMessage={setMessage}
                showHistory
                toggleModal={toggleModal}
                totalOrderItem={totalOrderItem}
                totalOrderVendor={totalOrderVendor}
                totalPrice={orderList.totalPrice}
                trackingModal={trackingModal}
                updateOrderLine={updateOrderLine}
                historyActive={historyActive}
                setHistoryActive={setHistoryActive}
                expanded={expanded}
                setExpanded={setExpanded}
                isEnabledData={isEnabledData}
              />
            </ProductSubCard>
          </Collapsible>
        </ProductCard>
      </ResourceItem>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    handleSelectedFilterRemove();
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return value === constant.PRODUCT ? (
          <div className="remove_tag">{productNameTag}</div>
        ) : (
          cms(`section.orderList.label.filter.${value}`) || value
        );
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false, selectedFilter),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };
  const appliedFilters = appliedFilterArrObj;

  const filters = [
    {
      key: "taggedWith",
      label: cms("section.orderList.label.filterBy"),
      filter: (
        <MoreFilters
          dataToFetch={inputData}
          loading={loading}
          productTag={productTag}
          queryParam={queryParam}
          selectedFilter={selectedFilter}
          selectedOptions={selectedOptions}
          setBanner={setBanner}
          setProductTag={setProductTag}
          setSelectedFilter={setSelectedFilter}
          setSelectedOptions={setSelectedOptions}
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setTagInputValue={setTagInputValue}
          productList={productList}
          setProductList={setProductList}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  if (
    loadingBulkAccept ||
    loadingBulkReject ||
    loadingOrderLineTracking ||
    loadingUpdateOrderDelivery ||
    loadingProductListing
  ) {
    return <Spinner />;
  }

  const getComponent = () => {
    return (
      <>
        {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.banner.description.heading")}
              status="critical"
            >
              <p>
                <br />
                {cms("common.popup.migrate.banner.description.para1")}
                <br />
                <br />
                {cms("common.popup.migrate.banner.description.para2")}
              </p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_VENDOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>
                      <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para1")}
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para2")}
                    </TextContainer>
                  </Banner>
                </TextContainer>
                <br />
                {banner.isOpen && isOpen && (
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    action={banner.action}
                    onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                    isScrollTop={banner.isOpen}
                  />
                )}
              </Card>
              <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setModalBanner={setBanner} />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        {!isOpen && banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <ProviderExportOrder isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        <FulfillOrder
          cms={cms}
          isFulfillModal={isFulfillModal}
          setIsFulfillModal={setIsFulfillModal}
          setBanner={setBanner}
          orderList={orderList}
          selectedItems={selectedItems}
        />
        {getSecondaryComponent()}
        {renderReject()}
        <Card>
          <div className="order-list">
            <ResourceList
              // resourceName={resourceName}
              items={orderList}
              renderItem={renderItem}
              idForItem={(orders) => {
                const { _id: id } = orders;
                return id;
              }}
              loading={loading || orderLoading}
              queryValue={search}
              onQueryClear={handleQueryClear}
              onQueryChange={handleSearchChange}
              sortOptions={listData(cms).sortOptions}
              sortValue={sortValue}
              onSortChange={handleSort}
              selectedItems={selectedItems}
              onSelectionChange={handleSelect}
              bulkActions={bulkActions}
              promotedBulkActions={promotedBulkActions}
              filters={filters}
              appliedFilters={appliedFilters}
              handleClearAll={handleClearAll}
              count={orderCount}
              page={page}
              perPage={perPage}
              setPage={(value) => handlePageChange(value)}
              setPopoverDisable={setPopoverDisable}
              setPerPage={setPerPage}
            />
          </div>
        </Card>
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
      </>
    );
  };
  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(ProviderOrderList), { feature: constant.ORDERS });
