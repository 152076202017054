import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const MoreFilters = (props) => {
  const { taggedWith, setTaggedWith, selectedFilter, setSelectedFilter } = props;
  const { history, cms } = useContext(PrivateContext);

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
  };

  const filterOptions = [
    // { label: cms("section.orderList.label.brandName") || "brandName", value: constant.VENDOR },
    { label: cms("section.orderList.label.orderId") || constant.ORDER_NUMBER, value: constant.STORE_ORDER_ID },
  ];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
  };

  return (
    <>
      <FormLayout>
        <Select
          label={cms("section.orderList.label.allOrder")}
          value={selectedFilter || ""}
          // placeholder={cms("section.orderList.label.select")}
          placeholder="Select"
          onChange={handleSelectChange}
          options={filterOptions}
        />
        {(selectedFilter && (
          <TextField label={cms("section.orderList.label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          null}
      </FormLayout>
    </>
  );
};
MoreFilters.defaultProps = {
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};
export default MoreFilters;
