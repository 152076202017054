// import constant
import constant from "lib/constant/constant";

const editServiceData = (cms, isVariant) => {
  const filterServiceTab = [
    {
      id: "information",
      content: cms("label.information"),
      panelID: "information-content",
    },
  ];

  if (!isVariant) {
    filterServiceTab.push({
      id: "availability",
      content: cms("service.label.availability"),
      panelID: "availability-content",
    });
  }

  filterServiceTab.push({
    id: "image",
    content: cms("label.image"),
    panelID: "image-content",
  });

  filterServiceTab.push({
    id: "variant",
    content: cms("label.variant"),
    panelID: "variant-content",
  });

  return {
    option: {
      option1: "option1",
      option2: "option2",
      option3: "option3",
    },
    serviceTabs: filterServiceTab,
    typeOption: [
      {
        label: cms("common.label.flat"),
        value: constant.FLAT,
      },
      {
        label: cms("common.label.percentage"),
        value: constant.PERCENTAGE,
      },
    ],
  };
};

export default editServiceData;
