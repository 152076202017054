import constant from "lib/constant/constant";

const { providerList } = constant;

const viewListData = {
  brandTabs: [
    {
      id: "vendor-products",
      content: providerList.PRODUCTS,
      panelId: "product-details",
      accessibilityLabel: "Product-Details",
    },
    {
      id: "vendor-orders",
      content: providerList.ORDERS,
      panelId: "order-details",
      accessibilityLabel: "Order-Details",
    },
    {
      id: "vendor-payment",
      content: providerList.PAYMENT,
      panelId: "payment-details",
      accessibilityLabel: "Payment-Details",
    },
    {
      id: "vendor-shipping",
      content: providerList.SHIPPING,
      panelId: "shipping-details",
      accessibilityLabel: "Shipping-details",
    },
    {
      id: "vendor-socialLink",
      content: providerList.SOCIAL_LINK,
      panelId: "socialLink-details",
      accessibilityLabel: "socialLink-details",
    },
    {
      id: "vendor-other",
      content: providerList.OTHER,
      panelId: "other-details",
      accessibilityLabel: "Other-details",
    },
  ],
};

export default viewListData;
