import { gql } from "apollo-boost";

const DOWNLOAD_LABEL = gql`
  query downloadShippingLabel($input: ShippingLabelInput!) {
    downloadShippingLabel(input: $input) {
      data
      status
      error
    }
  }
`;

const GET_UPLOAD = gql`
  query getUpload($input: UploadInput) {
    getUpload(input: $input) {
      data {
        data
        dataType
        type
      }
      error
      status
    }
  }
`;
export { DOWNLOAD_LABEL, GET_UPLOAD };
