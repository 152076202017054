import React, { useContext, useCallback, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";
import { ProductProvider } from "app/product/modules/generic/context";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import Hoc
import { withFeature } from "lib/hoc";

// import prop
import { tabProp } from "app/product/modules/generic/propTypes";
import constant from "lib/constant/constant";
import addData, { getPanelComponent } from "app/product/modules/provider/features/add/config/addData";

const ProductTabs = (props) => {
  const { setBanner, isMetafield } = props;
  const { cms } = useContext(PrivateContext);
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  return (
    <>
      <Card>
        <Tabs tabs={addData(cms, "", "", isMetafield).tabs} selected={selected} setBanner={setBanner} />
      </Card>
      <ProductProvider
        value={{
          setBanner,
          handleTabChange,
        }}
      >
        <div className="productComponent">{getPanelComponent(selected, cms)}</div>
      </ProductProvider>
    </>
  );
};

ProductTabs.propTypes = tabProp.type;

export default withFeature(ProductTabs, {
  feature: constant.PRODUCT_ADD_FORM,
  // subFeature: constant.PRODUCT_ADD_FORM,
});
