import { yupHelper } from "lib/helpers";

import { productSchema, vendorSchema, multiVendorSchema, storeSchema, revenueSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value) => {
  let promise = "";
  switch (field) {
    case "product":
      promise = validateValue(productSchema, { product: value });
      break;
    case "vendor":
      promise = validateValue(vendorSchema, { vendor: value });
      break;
    case "multiVendor":
      promise = validateValue(multiVendorSchema, { multiVendor: value });
      break;
    case "store":
      promise = validateValue(storeSchema, { store: value });
      break;
    case "revenue":
      promise = validateValue(revenueSchema, { revenue: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;
