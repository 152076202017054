import { gql } from "apollo-boost";

const SHOPIFY_INSTALL_SELLER_LOGIN = gql`
  mutation shopifyInstallAndSellerLogin($input: shopifyInstallAndSellerLoginInput) {
    shopifyInstallAndSellerLogin(input: $input) {
      status
      error
      data {
        token
      }
    }
  }
`;

export default SHOPIFY_INSTALL_SELLER_LOGIN;
