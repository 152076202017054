import { gql } from "apollo-boost";

const GET_VENDOR_PRODUCT_TYPES = gql`
  query GetVendorAndProductTypes {
    getVendorAndProductTypes {
      data {
        supplierRows {
          brandName
          _id
        }
        types {
          value
          _id
        }
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_PRODUCT_TYPES;
