import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import { Layout, Stack, Button, TextStyle, Card } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helper components
import { Banner, Spinner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

import { errorHelper } from "lib/helpers";

// import gql
import { PrivateContext } from "lib/context";
import { GET_VENDOR_AUTH_CODE, GET_SELLER_PAYMENT } from "app/setup/apollo/queries";
import { GET_SELLER_BRANDING } from "routes/private/apollo/queries";
import {
  CREATE_CUSTOM_STRIPE_VENDOR,
  DISCONNECT_PAYPAL_VENDOR,
  DISCONNECT_STRIPE_VENDOR,
} from "app/setup/apollo/mutations";

const ProviderConnect = () => {
  const { currentUser, history, cms } = useContext(PrivateContext);

  const { gql } = constant;
  const { GET_SELLER_PAYMENT: GET_SELLER_PAYMENT_GQL } = gql || {};
  const [getAuthCode, { loading: paypalLoading, data: paypalData, error: paypalError }] = useLazyQuery(
    GET_VENDOR_AUTH_CODE
  );
  const { loading, data, error } = useQuery(GET_SELLER_PAYMENT);
  const { data: sellerData } = useQuery(GET_SELLER_BRANDING);
  const [disconnectFromStripe, { loading: disconnectStripeLoading }] = useMutation(DISCONNECT_STRIPE_VENDOR);
  const [disconnectFromPaypal, { loading: disconnectPaypalLoading }] = useMutation(DISCONNECT_PAYPAL_VENDOR);
  const [createStripeAccount, { loading: createStripeAccountLoading }] = useMutation(CREATE_CUSTOM_STRIPE_VENDOR);

  const { _id: id, isExpressPayment: isVendorExpressPayment = false } = currentUser;
  const { location } = history;
  const { state } = location;

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [infoBanner, setInfoBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [isStripeConnected, setIsStripeConnected] = useState(false);

  const sellerResponseData = baseHelper.getResponseData(data, GET_SELLER_PAYMENT_GQL);
  const errorSellerResponse = baseHelper.getResponseData(data, GET_SELLER_PAYMENT_GQL);
  useEffect(() => {
    if (error) {
      setBanner({ title: errorHelper.parse(error), status: constant.CRITICAL, isOpen: true });
    }
    if (state && (!state.isPaypalConnected || !state.isStripeConnected)) {
      setBanner({ title: state.message, status: constant.CRITICAL, isOpen: true });
    }
    if (state && state.isStripeConnected) {
      setBanner({ title: cms("message.success.stripeConnect"), status: constant.SUCCESS, isOpen: true });
    }
    if (state && state.isPaypalConnected) {
      setBanner({ title: cms("message.success.paypalConnect"), status: constant.SUCCESS, isOpen: true });
    }
    if (state) {
      history.replace("/payment-connect");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, cms, state]);

  useEffect(() => {
    if (errorSellerResponse && !sellerResponseData) {
      setBanner({ isOpen: true, title: errorSellerResponse, status: constant.CRITICAL });
    }
  }, [errorSellerResponse, sellerResponseData]);

  const { stripeKey, paypalKey, isExpressPayment } = sellerResponseData;

  const { paypal, stripe } = currentUser;
  const { accountId: stripeAccountId, isVerified } = stripe || {};

  useEffect(() => {
    const isConnected = isExpressPayment && isVendorExpressPayment ? !!(stripeAccountId && isVerified) : !!stripeAccountId;
    setIsStripeConnected(isConnected);
  }, [isExpressPayment])

  const stripeClientId = stripeKey && stripeKey.clientId;
  const paypalClientId = paypalKey && paypalKey.clientId;
  const isPaypalConnected = paypal && (paypal.payerId || paypal.emailId);
  const serverUrl = baseHelper.getCurrentDomain(window.location, "stripe/callback");

  const dismiss = () => {
    setBanner({ isOpen: false, status: "", title: "" });
  };

  useEffect(() => {
    if (paypalData) {
      const responseData = baseHelper.getResponseData(paypalData, gql.GET_VENDOR_AUTH_CODE);

      if (responseData) {
        window.location.href = responseData.uri;
      }
      if (!responseData) {
        const errorResponseData = baseHelper.getResponseError(paypalData, gql.GET_VENDOR_AUTH_CODE);
        setBanner({ title: errorResponseData, status: constant.CRITICAL, isOpen: true });
      }
    }

    if (paypalError) {
      setBanner({ title: errorHelper.parse(paypalError), status: constant.CRITICAL, isOpen: true });
    }
  }, [gql.GET_VENDOR_AUTH_CODE, paypalData, paypalError]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(sellerData, constant.gql.GET_SELLER_BRANDING);
    const sellerBrandName = responseData.brandName;

    const isSellerPaypalConnected = !!(paypalKey && paypalKey.clientId);
    const isSellerStripeConnected = !!(stripeKey && stripeKey.clientId);

    const title = baseHelper.getPaymentBannerText(isSellerPaypalConnected, isSellerStripeConnected, sellerBrandName);

    if (title) {
      setInfoBanner({
        title,
        status: constant.INFO,
        isOpen: true,
      });
    }

    if (!isSellerPaypalConnected && !isSellerStripeConnected) {
      setInfoBanner({
        title: "",
        status: "",
        isOpen: false,
      });
    }
  }, [sellerData, data]);

  const onPaypalConnect = async () => {
    const redirectUri = baseHelper.getCurrentDomain(window.location, "paypal/callback");
    getAuthCode({
      variables: { input: { redirectUri } },
    });
  };

  const onStripeConnect = async () => {
    const stripeUrl = `${constant.STRIPE_ACCOUNT}${stripeClientId}&scope=read_write&state=${id}&redirect_uri=${serverUrl}`;
    if (!isExpressPayment) {
      window.open(stripeUrl, "_self");
    } else {
      createStripeAccount()
        .then((response) => {
          const responseData = baseHelper.getResponseData(response.data, gql.CREATE_VENDOR_CUSTOM_STRIPE_ACCOUNT);
          const errorResponseData = baseHelper.getResponseError(response.data, gql.CREATE_VENDOR_CUSTOM_STRIPE_ACCOUNT);

          if (errorResponseData) {
            setBanner({ title: errorResponseData, status: constant.CRITICAL, isOpen: true });
            return;
          }
          if (responseData) {
            window.open(responseData.accountLink, "_self");
          }
        })
        .catch((exception) => {
          setBanner({ title: errorHelper.parse(exception), status: constant.CRITICAL, isOpen: true });
        });
    }
  };

  const handleDisconnect = (key) => {
    if (key === gql.STRIPE_CONNECTED) {
      disconnectFromStripe({ variables: { input: { paymentMethod: constant.STRIPE } } })
        .then((response) => {
          const responseData = baseHelper.getResponseData(response.data, gql.DISCONNECT_STRIPE);
          const errorResponseData = baseHelper.getResponseError(response.data, gql.DISCONNECT_STRIPE);

          if (errorResponseData) {
            setBanner({ title: errorResponseData, status: constant.CRITICAL, isOpen: true });
            return;
          }
          if (responseData) {
            setBanner({ title: cms("message.success.stripeDisconnect"), status: constant.SUCCESS, isOpen: true });
            setIsStripeConnected(false);
          }
        })
        .catch((exception) => {
          setBanner({ title: errorHelper.parse(exception), status: constant.CRITICAL, isOpen: true });
        });
    }
    if (key === constant.gql.PAYPAL_CONNECTED) {
      disconnectFromPaypal({ variables: { input: { paymentMethod: constant.PAYPAL } } })
        .then((response) => {
          const responseData = baseHelper.getResponseData(response.data, gql.DISCONNECT_PAYPAL);
          let bannerData = { status: constant.SUCCESS, title: cms("message.success.paypalDisconnect") };
          if (!responseData) {
            const errorResponseData = baseHelper.getResponseError(response.data, gql.DISCONNECT_PAYPAL);
            bannerData = { title: errorResponseData, status: constant.CRITICAL };
          }
          setBanner({ isOpen: true, title: bannerData.title, status: bannerData.status });
        })
        .catch((exception) => {
          setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
        });
    }
  };

  const isVerifyRequired = !!(stripeAccountId && isExpressPayment && !isVerified);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} onDismiss={() => dismiss()} isOpen={banner.isOpen} />
        </Layout.Section>
      )}

      {infoBanner.isOpen && (
        <Layout.Section>
          <Banner title={infoBanner.title} status={infoBanner.status} isOpen={infoBanner.isOpen} />
        </Layout.Section>
      )}

      <Layout.AnnotatedSection title={cms("title.account")} description={cms("description.account")}>
        <Card title={cms("title.stripe")} sectioned>
          {stripeClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{isStripeConnected ? cms("label.stripeConnect") : cms("label.stripeInfo")}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                {isStripeConnected ? (
                  <Button
                    id="stripeDisconnect"
                    slim
                    primary
                    loading={disconnectStripeLoading}
                    destructive
                    onClick={() => handleDisconnect(constant.gql.STRIPE_CONNECTED)}
                  >
                    {cms("button.disconnect")}
                  </Button>
                ) : (
                  <Button id="stripeConnect" slim primary loading={createStripeAccountLoading} onClick={onStripeConnect}>
                    {cms(isVerifyRequired ? "button.verify" : "button.connect")}
                  </Button>
                )}
              </Stack.Item>
            </Stack>
          )}
          {!stripeClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{cms("label.sellerStripe")}</TextStyle>
              </Stack.Item>
            </Stack>
          )}
        </Card>
        <Card title={cms("title.paypal")} sectioned>
          {paypalClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{isPaypalConnected ? cms("label.paypalConnect") : cms("label.paypalInfo")}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                {isPaypalConnected ? (
                  <Button
                    id="paypalDisconnect"
                    slim
                    primary
                    loading={disconnectPaypalLoading}
                    destructive
                    onClick={() => handleDisconnect(constant.gql.PAYPAL_CONNECTED)}
                  >
                    {cms("button.disconnect")}
                  </Button>
                ) : (
                  <Button id="paypalConnect" slim loading={paypalLoading} primary onClick={() => onPaypalConnect()}>
                    {cms("button.connect")}
                  </Button>
                )}
              </Stack.Item>
            </Stack>
          )}
          {!paypalClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{cms("label.sellerPaypal")}</TextStyle>
              </Stack.Item>
            </Stack>
          )}
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderConnect), { feature: constant.CONNECT_PAYMENT });
