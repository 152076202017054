import { gql } from "apollo-boost";

const GET_VENDOR_ORDER_LINE = gql`
  query getVendorOrderLine($input: GetVendorOrderLineInput) {
    getVendorOrderLine(input: $input) {
      data {
        _id
        actualQuantity
        commissionSeller
        commissionTax
        commissionVendor
        image
        isOrderBasedCommission
        name
        price
        priceTaxRate
        quantity
        shippingAmount
        shippingTax
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_ORDER_LINE;
