import _ from "lodash";
import constant from "lib/constant/constant";

class Hooks {
  useCMS = (cmsData = false) => {
    if (!cmsData) {
      return false;
    }

    const cms = (key, valuesToAdd = {}) => {
      const valuesToAddKeys = Object.keys(valuesToAdd);
      const common = cmsData.commonContent;
      const module = cmsData.pageContent;
      let keys = key.split(".");
      let data = { ...module };
      const isCommonPrefix = keys[0] === constant.COMMON;
      if (isCommonPrefix) {
        data = { ...common };
        keys = keys.slice(1);
      }
      let isInvalidKey = false;
      // get the content dynamically. Also check if dynamic keys are valid
      keys.forEach((item) => {
        if (!!data[item] && !isInvalidKey) {
          data = data[item];
        } else {
          isInvalidKey = true;
        }
      });

      // replace the variables with the actual values
      valuesToAddKeys.forEach((item) => {
        const regex = new RegExp(`{{${item}}}`, "g");
        if (_.isString(data)) {
          data = data.replace(regex, valuesToAdd[item]);
        }
        // else {
        //   console.log("invalid key--", key); // invalid key
        // }
      });

      return (!isInvalidKey && data) || "";
    };
    return cms;
  };
}

export default new Hooks();
