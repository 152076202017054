import { gql } from "apollo-boost";

const GET_BULK_LIST = gql`
  query getBulkLog($input: GetListInput) {
    getBulkLog(input: $input) {
      data {
        count
        bulkLog {
          type
          _id
          status
          field
          error
          updatedAt
        }
      }
      status
      error
    }
  }
`;

export default GET_BULK_LIST;
