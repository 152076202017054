import { gql } from "apollo-boost";

const UPLOAD_IMAGE = gql`
  mutation uploadImage($input: UploadImage) {
    uploadImage(input: $input) {
      status
      data {
        imageId
        imageUrl
      }
      error
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($input: UploadFile) {
    uploadFile(input: $input) {
      status
      data {
        fileId
        fileURL
      }
      error
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation addProduct($input: Product) {
    addProduct(input: $input) {
      status
      data
      error
    }
  }
`;

export { ADD_PRODUCT, UPLOAD_IMAGE, UPLOAD_FILE };
