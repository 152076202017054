import { gql } from "apollo-boost";

const HOLIDAY_SETTING = gql`
  mutation holidaySetting($input: HolidaySettingData) {
    holidaySetting(input: $input) {
      data
      status
      error
    }
  }
`;

export default HOLIDAY_SETTING;
