import * as yup from "yup";

export const clientIdSchema = (cms) =>
  yup.object().shape({
    clientId: yup.string().required(cms("message.error.clientId")).nullable(),
  });

export const secretSchema = (cms) =>
  yup.object().shape({
    secret: yup.string().required(cms("message.error.secret")).nullable(),
  });
