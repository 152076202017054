import { AddProductMajor, ChatMajor, ImagesMajor } from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.addProducts"),
        label: cms("common.actions.dashboard.label.addProducts"),
        icon: AddProductMajor,
        link: "/products/add",
        key: "product",
      },
      {
        caption: cms("common.actions.dashboard.caption.brand"),
        label: cms("common.actions.dashboard.label.brand"),
        icon: ImagesMajor,
        action: function action() {
          const { token, domain } = this;
          const url = `${domain}/builder/${token}`;
          window.open(url);
        },
        key: "brand",
      },
      {
        caption: cms("common.actions.dashboard.caption.contactUs"),
        label: cms("common.actions.dashboard.label.contactUs"),
        icon: ChatMajor,
        link: "/contact-us",
        key: "contact",
      },
    ],
  };
};

export default config;
