import React, { useContext, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import gql
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";

// helper
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import Banner from "lib/components/banner/banner";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import List from "./list";

const ProviderShippingBand = () => {
  const { cms, history } = useContext(PrivateContext);
  const getQueryParams = baseHelper.queryParamsFromLocation(history);
  const [dataToFetch, setDataToFetch] = useState({
    list_filter: getQueryParams.list_filter,
    list_search: getQueryParams.list_search,
  });
  const [bannerStatus, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const inputData = {
    list_filter: dataToFetch.list_filter,
    list_search: dataToFetch.list_search,
  };

  const { loading, data, error, refetch, networkStatus } = useQuery(GET_SHIPPING_BAND, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: inputData,
    },
  });

  const responseData = baseHelper.getResponseData(data, constant.gql.GET_SHIPPING_BAND);
  const errorData = baseHelper.getResponseError(data, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows = [], shippingTerm = "" } = responseData || {};

  useEffect(() => {
    if (responseData && !shippingRows.length) {
      const bannerData = {
        isOpen: true,
        status: constant.WARNING,
        title: cms("provider.message.addShippingBand.title"),
        message: cms("provider.message.addShippingBand.content"),
      };
      setBanner(bannerData);
    }
    if (errorData) {
      const bannerData = {
        isOpen: true,
        status: constant.CRITICAL,
        title: errorData,
      };
      setBanner(bannerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, errorData]);

  useEffect(() => {
    if (error) {
      const bannerData = {
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error),
      };
      setBanner(bannerData);
    }
  }, [error]);

  return (
    <Layout.Section>
      <Banner
        isOpen={bannerStatus.isOpen}
        status={bannerStatus.status}
        title={bannerStatus.title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      >
        {bannerStatus.message}
      </Banner>
      <br />
      <List
        bands={shippingRows.length ? shippingRows : []}
        dataToFetch={dataToFetch}
        setDataToFetch={setDataToFetch}
        term={shippingTerm}
        loading={loading}
        setBanner={setBanner}
        refetch={refetch}
        networkStatus={networkStatus}
      />
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(ProviderShippingBand), { feature: constant.ADVANCE_SHIPPING });
