// packages
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import { Card, Checkbox, FormLayout, InlineError, Layout, Link, PageActions, Stack, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// yup validation
import validateSeller from "app/public/modules/register/yupSeller/index";

// constant
import constant from "lib/constant/constant";

// helpers
import { baseHelper, storageHelper, errorHelper } from "lib/helpers";

// helper components
import { Banner } from "lib/components";

// gql
import { REGISTER_MARKETCUBE_SELLER } from "app/public/apollo/mutations/index";

// context
import { PublicContext } from "lib/context";
import config from "configuration";

const RegisterSeller = () => {
  const { history, cms } = useContext(PublicContext);
  const queryParams = baseHelper.getQueryParams(history.location.search) || {};

  const {
    CONFIRM_PASSWORD_SELLER,
    EMAIL_SELLER,
    gql,
    IS_ACCEPT_POLICY_SELLER,
    PASSWORD_SELLER,
    POLICY_LINK_SELLER,
    POLICY_URL_SELLER,
    SHOP_NAME,
    TERMS_LINK_SELLER,
    TERMS_URL_SELLER,
  } = constant;

  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  // states
  const [values, setValues] = useState({
    confirmPassword: "",
    email: "",
    isAcceptPolicy: false,
    password: "",
    shopName: "",
  });

  const termsLink = storageHelper.get(TERMS_LINK_SELLER);
  const policyLink = storageHelper.get(POLICY_LINK_SELLER);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitButton, setSubmitButton] = useState({
    isReadyToSubmit: false,
  });

  const [banner, setBanner] = useState({
    isOpen: false,
    status: constant.SUCCESS,
    title: "",
  });

  const [isEmail, setIsEmail] = useState(false);

  const [registerUser, { loading }] = useMutation(REGISTER_MARKETCUBE_SELLER);

  const handleValidation = async (field, value) => {
    const validationError = await validateSeller(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    const isAnyValidationError =
      errorMessage &&
      !!(errorMessage.email || errorMessage.shopName || errorMessage.password || errorMessage.confirmPassword);
    const isAllValuesFilled =
      values.email && values.shopName && values.password && values.confirmPassword && values.isAcceptPolicy;
    setSubmitButton((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [values, errorMessage]);

  useEffect(() => {
    const { password, confirmPassword } = values;
    if (password && confirmPassword) {
      handleValidation(CONFIRM_PASSWORD_SELLER, {
        password,
        confirmPassword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.password, CONFIRM_PASSWORD_SELLER, values.confirmPassword]);

  const handleChange = (key, value) => {
    let updatedValue = value;
    if (key === SHOP_NAME) {
      updatedValue = value.replace(/ +/g, "").toLowerCase();
    }
    setValues((prevState) => ({
      ...prevState,
      [key]: key === EMAIL_SELLER ? updatedValue.trim() : updatedValue,
    }));
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const onSubmit = async () => {
    let bannerData = {};
    try {
      await handleValidation(EMAIL_SELLER, values.email);
      await handleValidation(SHOP_NAME, values.shopName);
      await handleValidation(PASSWORD_SELLER, values.password);
      await handleValidation(CONFIRM_PASSWORD_SELLER, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      await handleValidation(IS_ACCEPT_POLICY_SELLER, values.isAcceptPolicy);

      if (!submitButton.isReadyToSubmit) {
        return false;
      }
      let res = {};
      const inputData = {
        email: values.email,
        password: values.password,
        shopName: values.shopName,
      };

      if ("isPaye" in queryParams) {
        inputData.isPaye = queryParams.isPaye === "true";
      }

      const registerMutation = gql.REGISTER_MARKETCUBE_SELLER;

      if (!isCustomDomain) {
        res = await registerUser({
          variables: { input: inputData },
        });
      }
      const responseData = baseHelper.getResponseData(res.data, registerMutation);

      const errorData = baseHelper.getResponseError(res.data, registerMutation);
      bannerData = {
        isOpen: true,
        status: constant.SUCCESS,
        title: "Your Marketcube Operator account has been created successfully.",
      };

      bannerData.message = (
        <>
          Please verify your email by clicking on the link to continue. We have sent you an email at <b>{values.email}</b>.<br/>
          <b>Note:</b> If you're unable to see the email then please check your spam/junk folder or contact <b>support@marketcube.io</b>
        </>
      );

      if (!responseData && errorData) {
        bannerData = { isOpen: true, status: constant.CRITICAL, title: errorData };
      }

      if (bannerData) {
        setBanner(bannerData);
        setValues({
          confirmPassword: "",
          email: "",
          isAcceptPolicy: false,
          password: "",
          shopName: "",
        });
      }
      // if (!errorData) {
      //   redirectToLogin();
      // }
    } catch (exception) {
      bannerData = { isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) };
      setBanner(bannerData);
    }
    return false;
  };

  const policyLabel = () => {
    const privacyUrl = policyLink || POLICY_URL_SELLER;
    const agreeUrl = termsLink || TERMS_URL_SELLER;
    return (
      <>
        {" "}
        {cms("label.agree")}
        <Link key={cms("label.privacyPolicy")} plain id="linkPolicy" external url={privacyUrl}>
          {cms("label.privacyPolicy")}
        </Link>
        {" & "}
        <Link key={cms("label.agree")} plain id="linkTerms" external url={agreeUrl}>
          {cms("label.terms")}
        </Link>
      </>
    );
  };

  const getDescription = () => {
    return (
      <div>
        <p>Welocme to Marketcube. You are minutes away from starting your marketplace.
        <br/><br/>
        You need to complete a few steps to get your account setup on Marketcube.</p>
        <br/>
        <iframe width="100%" src="https://www.youtube.com/embed/hKrfiHXp-Zs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" />
        <ol>
            <li>Create your account</li>
            <li>Verify your email</li>
            <li>Login to platform</li>
            <li>Choose your plan and subscribe</li>
            <li>Update Business details</li>
            <li>Setup Marketplace operational terms</li>
            <li>Connect your shop</li>
            <li>Setup complete</li>
        </ol>
      </div>
    )
  };

  // cms("description")
  return (
    <>
      <Banner
        id="registerBanner"
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        action={banner.action || null}
        // onDismiss={() => setBanner({ isOpen: false })}
      >
        {banner.message}
      </Banner>
      <br />
      <Banner isOpen title={cms("message.success.shopifyHelpText")} status={constant.INFO} />
      <br />
      <Layout>
        <Layout.AnnotatedSection title="Create your account" description={getDescription()}>
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                label={`${cms("common.label.email")}*`}
                value={values.email || ""}
                placeholder={cms("common.placeholder.emailAddress")}
                onChange={(value) => handleChange(EMAIL_SELLER, value)}
                onBlur={() => handleValidation(EMAIL_SELLER, values.email)}
                disabled={isEmail}
                error={errorMessage && errorMessage.email}
              />

              <TextField
                id="shopName"
                label={`${"Brand Name"}*`}
                value={values.shopName || ""}
                onChange={(value) => handleChange(SHOP_NAME, value)}
                onBlur={() => handleValidation(SHOP_NAME, values.shopName)}
                placeholder="Brand Name"
                // prefix={`${cms("common.appPermission.shop.prefix.shopSubDomain")}`}
                // suffix={`${cms("common.appPermission.shop.suffix.shopSubDomain")}`}
                error={errorMessage && errorMessage.shopName}
                helpText={"Brand name should be without spaces and in lower case. eg: Marketcube Ltd should be marketcubeltd."}
              />
              <TextField
                id="password"
                label={`${cms("common.label.password")}*`}
                placeholder={cms("common.placeholder.password")}
                type="password"
                value={values.password || ""}
                onChange={(value) => handleChange(PASSWORD_SELLER, value)}
                onBlur={() => handleValidation(PASSWORD_SELLER, values.password)}
                error={errorMessage && errorMessage.password}
                helpText={cms("common.message.error.enterValidPassword")}
              />
              <TextField
                id="confirmPassword"
                label={`${cms("common.label.confirmPassword")}*`}
                placeholder={cms("common.placeholder.confirmPassword")}
                type="password"
                value={values.confirmPassword || ""}
                onChange={(value) => handleChange(CONFIRM_PASSWORD_SELLER, value)}
                onBlur={() => {
                  handleValidation(CONFIRM_PASSWORD_SELLER, {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                  });
                }}
                error={errorMessage && errorMessage.confirmPassword}
              />
              <Stack wrap spacing="extraTight">
                <Checkbox
                  id="privacyPolicyTermsOfUse"
                  checked={values.isAcceptPolicy}
                  onChange={(value) => handleChange(IS_ACCEPT_POLICY_SELLER, value)}
                  onBlur={() => handleValidation(IS_ACCEPT_POLICY_SELLER, values.isAcceptPolicy)}
                />
                <div className="checkBoxLabel">
                  <span>{policyLabel()}</span>
                </div>
              </Stack>
              <InlineError message={errorMessage && errorMessage.isAcceptPolicy} fieldID="privacyPolicyTermsOfUse" />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "MC_PORTAL_REGISTER_STEP_01",
              content: cms("button.register"),
              onAction: () => onSubmit(),
              disabled: !submitButton.isReadyToSubmit,
              loading,
            }}
            secondaryActions={[
              {
                id: "registerCancelButton",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/register-role"),
              },
            ]}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default withErrorBoundary(RegisterSeller);
