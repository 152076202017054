import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const MoreFilters = (props) => {
  const { taggedWith, setTaggedWith, selectedFilter, setSelectedFilter } = props;
  const { history, cms } = useContext(PrivateContext);

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
  };

  const filterOptions = [
    { label: cms("label.order.select"), value: "" },
    { label: cms("label.order.orderNumber"), value: cms("label.order.orderNum") },
  ];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
  };

  return (
    <>
      <FormLayout>
        <Select
          label={cms("label.order.showAllVendor")}
          value={selectedFilter}
          onChange={handleSelectChange}
          options={filterOptions}
          placeholder={cms("label.order.selectFilter")}
        />
        {(selectedFilter && (
          <TextField label={cms("label.order.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          null}
      </FormLayout>
    </>
  );
};
MoreFilters.defaultProps = {
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};
export default MoreFilters;
