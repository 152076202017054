import React, { useContext, useState, useEffect } from "react";
import { SkeletonBodyText, TopBar } from "@shopify/polaris";
import PropTypes from "prop-types";

// import components
import { useMutation } from "@apollo/react-hooks";
import { PrivateContext, PrivateBrandingContext } from "lib/context";
import constant from "lib/constant/constant";

// import helpers
import { storageHelper, baseHelper, accessHelper, errorHelper, chatHelper } from "lib/helpers";

// import mutation
import { LOGOUT_USER } from "layout/private/apollo/mutations";

const Header = (props) => {
  const { cms } = props;
  const { currentUser, destroyPopup, history, isLoading } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);
  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const { branding } = useContext(PrivateBrandingContext);
  const { mobile = {}, setupProgress = "" } = currentUser;
  const { isHideMc } = branding || {};

  const {
    gql: { LOGOUT },
  } = constant;

  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get("hideMcDetails"));
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [logoutMutation, { loading }] = useMutation(LOGOUT_USER);
  const { setLogoutLoading } = props;
  const [setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const logout = async () => {
    try {
      const response = await logoutMutation();
      const responseData = baseHelper.getResponseData(response.data, LOGOUT);
      if (responseData) {
        // NOTE: Removed all except language code & shop
        // storageHelper.removeAll();
        storageHelper.remove("token");
        storageHelper.remove("isInitial");
        storageHelper.remove("userRole");
        storageHelper.remove("setupProgress");
        storageHelper.removeAllCookie();

        chatHelper.destroyTalkJSSession();
        destroyPopup();

        const {
          buttonCSS = "",
          headerColor = "",
          loginText = "",
          logoUrl = "",
          register = {},
          brandName = "",
          headerFont = "",
        } = branding;

        if (isVendor) {
          // baseHelper.setBranding(this.props.branding);
          if (buttonCSS && buttonCSS !== "") {
            storageHelper.set("buttonCSS", buttonCSS);
          }
          if (headerColor && headerColor !== "") {
            storageHelper.set("headerColor", headerColor);
          }
          if (loginText && loginText !== "") {
            storageHelper.set("loginText", loginText);
          }
          if (logoUrl && logoUrl !== "") {
            storageHelper.set("logoUrl", logoUrl);
          }
          if (register && (register.title || register.desc)) {
            storageHelper.set("register", register);
          }
          if (brandName && brandName !== "") {
            storageHelper.set("brandName", brandName);
          }
          if (headerFont && headerFont !== "") {
            storageHelper.set("headerFont", headerFont);
          }
          if (isHideMc) {
            storageHelper.set("hideMcDetails", isHideMc);
          }
          storageHelper.remove('customVendorAppUpdate');
          storageHelper.remove('customAppUpdate');
        } else {
          storageHelper.remove("brandName", brandName);
          storageHelper.remove('customVendorAppUpdate');
          storageHelper.remove('customAppUpdate');
        }

        history.push({
          pathname: mobile && mobile.verified ? "/prelogin" : "/login",
        });
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(exception),
      });
    }
  };

  const userMenuActions = [
    {
      items: [
        {
          content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.actions.dashboard.label.logout"),
          onAction: () => logout(),
        },
      ],
    },
  ];

  const isOnboarding = !!storageHelper.get("setupProgress") || false;
  if (!isOnboarding) {
    userMenuActions.unshift(
      {
        items: [
          {
            content:
              (isLoading && <SkeletonBodyText lines={1} />) || cms("common.actions.dashboard.label.changePassword"),
            onAction: () => history.push("/change-password"),
          },
        ],
      },
      {
        items: [
          {
            content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.actions.dashboard.label.profile"),
            onAction: () => history.push("/profile"),
          },
        ],
      },
      {
        items: [
          {
            content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.actions.dashboard.label.contactUs"),
            onAction: () => history.push("/contact-us"),
          },
        ],
      }
    );
  }
  useEffect(() => {
    if (typeof isHideMc === "undefined") {
      return;
    }
    if (isHideMc !== hideMcDetails) {
      setHideMcDetails(isHideMc);
    }
  }, [hideMcDetails, isHideMc]);

  if (isSystemAdmin) {
    // removing ContactUs from userMenuActions
    userMenuActions.splice(2, 1);
  }

  if (loading) {
    setLogoutLoading(loading);
  }

  const renderHeader = () => {
    if (isLoading) {
      return null;
    }
    const { shop = "", brandName = "", firstName = "", lastName = "" } = currentUser || {};
    const firstNameInitial = (firstName && firstName.charAt(0).toUpperCase()) || "";
    const lastNameInitial = (lastName && lastName.charAt(0).toUpperCase()) || "";
    const fullNameInitials = `${firstNameInitial}${lastNameInitial}`;
    return (
      <>
        <TopBar.UserMenu
          actions={userMenuActions}
          open={isUserMenuOpen}
          name={
            !isSeller && setupProgress === constant.onboardingSteps.WELCOME && !(firstName || lastName)
              ? cms("common.actions.dashboard.label.user")
              : (isSeller && brandName) || `${firstName || shop} ${lastName || ""}`
          }
          detail={accessHelper.getUserRole(currentUser)}
          initials={fullNameInitials}
          onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
        />
      </>
    );
  };

  return renderHeader();
};

Header.propTypes = {
  setLogoutLoading: PropTypes.func.isRequired,
};

export default Header;
