import { gql } from "apollo-boost";

const GET_INVOICE_SETTING = gql`
  query {
    getInvoiceSetting {
      data {
        payment {
          invoice {
            access {
              isAllVendor
              isEnabled
              selectedVendor
              updatedAt
            }
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_INVOICE_SETTING;
