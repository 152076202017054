import React, { useContext } from "react";
import { FooterHelp, Button, Caption, TextStyle, Link } from "@shopify/polaris";
import { PublicContext } from "lib/context";
import { baseHelper, storageHelper } from "lib/helpers";
import config from "configuration";

const Footer = (props) => {
  const { history, cms } = useContext(PublicContext);
  const { loginVia = "email" } = props || {};
  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  const { pathname = "" } = window.location;
  const footerContent = cms("footer").map((item) => {
    if (item && item.link) {
      const redirectUri = isCustomDomain ? "/register" : item.link;
      return (
        <Button
          key={item.content}
          plain
          id="registerHere"
          onClick={() => {
            history.push({
              pathname: redirectUri,
              state: { loginVia },
            });
          }}
        >
          {item.content}
        </Button>
      );
    }
    return (
      <React.Fragment key={item.content}>
        {isCustomDomain || storageHelper.get("hideMcDetails") ? "New Vendor? " : item.content}
      </React.Fragment>
    );
  });
  return (
    <>
      {pathname !== "/prelogin" && <FooterHelp>{footerContent}</FooterHelp>}
      {!storageHelper.get("hidePoweredBy") && pathname !== "/prelogin" ? (
        <center>
          <Caption>
            <TextStyle variation="subdued">
              {`${cms("common.label.poweredBy")}${" "}`}
              {!(isCustomDomain || storageHelper.get("hideMcDetails")) ? (
                <Link url="https://www.marketcube.io" external>
                  {cms("common.label.marketcube")}
                </Link>
              ) : (
                `${cms("common.label.marketcube")}`
              )}
            </TextStyle>
          </Caption>
        </center>
      ) : (
        !storageHelper.get("hidePoweredBy") && (
          <center style={{ marginTop: "90px", marginLeft: "40px" }}>
            <Caption>
              <TextStyle variation="subdued">
                {`${cms("common.label.poweredBy")}${" "}`}
                {!(isCustomDomain || storageHelper.get("hideMcDetails")) ? (
                  <Link url="https://www.marketcube.io" external>
                    {cms("common.label.marketcube")}
                  </Link>
                ) : (
                  `${cms("common.label.marketcube")}`
                )}
              </TextStyle>
            </Caption>
          </center>
        )
      )}
    </>
  );
};

export default Footer;
