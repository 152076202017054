import React, { useCallback, useState } from "react";
import { Card, Layout, Tabs } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

import { providerReportingConfig, tabs } from "./config/reporting";
import { AllReportsTab, OrdersTab, ProductsTab, SalesTab } from "./tabs";

const { ORDERS, PRODUCTS, SALES } = constant;

const ProviderReporting = () => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const getTab = (value, reportingConfig) => {
    let component;
    switch (value) {
      case SALES:
        component = <SalesTab reportingConfig={reportingConfig} />;
        break;
      case ORDERS:
        component = <OrdersTab reportingConfig={reportingConfig} />;
        break;
      case PRODUCTS:
        component = <ProductsTab reportingConfig={reportingConfig} />;
        break;
      default:
        component = <AllReportsTab reportingConfig={reportingConfig} />;
        break;
    }
    return component;
  };
  const tabValue = tabs[selected]?.value;
  return (
    <Layout.Section>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
        {getTab(tabValue, providerReportingConfig[tabValue] || {})}
      </Card>
    </Layout.Section>
  );
};
export default ProviderReporting;
