/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Caption, Card, Stack, Tag, TextContainer, TextField, TextStyle, Tooltip } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import component
import { Sheet, SkeletonCard, Toast, Banner } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

//  import mutations
import { ADD_PRODUCT_LAKE_VARIANT } from "app/productLake/apollo/mutations";

// import queries
import { GET_PRODUCT_LAKE } from "app/productLake/apollo/queries";

// import prop Type
import { tabProp } from "app/productLake/modules/generic/propTypes";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";

import editData from "app/productLake/modules/operator/features/edit/config/editData";
import { withErrorBoundary } from "lib/hoc";

// import variant data
import VariantField from "./variant/variantField";

import RenderVariants from "./variant/renderVariant";

const EditVariant = (props) => {
  const { tabObj = {}, tabLength, selectedTab } = props;
  const { islastTab = false } = tabObj || {};
  const [data, setData] = useState({});
  const { handleTabChange, isVariant, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { gql, CRITICAL } = constant;
  const { cms, history, match, currentUser } = useContext(PrivateContext);
  const [toastmessage, setToastMessage] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [sheetActive, setSheetActive] = useState(false);
  const [variantLength, setVariantLength] = useState(0);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [loader, setLoader] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const { option } = editData(cms);
  setDescription(cms("label.editVariant"));
  setTitle(cms("label.title.variant"));

  const [state, setState] = useState({
    option1: cms("label.size"),
    option2: cms("label.color"),
    option3: cms("label.material"),
    option1Val: "",
    option2Val: "",
    option3Val: "",
    allowedOption: [option.option1],
    tags: [],
    variantValues: [],
    variants: [],
    validVariants: {},
    showOption: false,
    errorOption1: false,
    errMsg1: false,
    errorOption2: false,
    errMsg2: false,
    errorOption3: false,
    errMsg3: false,
  });

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      setData({
        ...data,
        [fieldName]: fieldValue,
      });
      setDisabledButton(false);
    },
    [data]
  );

  const { loading: productLoading, data: productData, refetch } = useQuery(GET_PRODUCT_LAKE, {
    variables: { input: { id: match.params.id } },
  });

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_LAKE) || {};
      setData(productResponse);
      const { variants = [] } = productResponse;
      if (variants && variants.length) {
        setVariantLength(variants && variants.length);
        setState({
          option1: cms("label.size"),
          option2: cms("label.color"),
          option3: cms("label.material"),
          option1Val: "",
          option2Val: "",
          option3Val: "",
          allowedOption: [option.option1],
          tags: [],
          variantValues: [],
          variants: [],
          validVariants: {},
          showOption: false,
          errorOption1: false,
          errMsg1: false,
          errorOption2: false,
          errMsg2: false,
          errorOption3: false,
          errMsg3: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gql.GET_PRODUCT_LAKE, productData]);

  const [addProductVariant, { loading: addVariantLoading }] = useMutation(ADD_PRODUCT_LAKE_VARIANT);

  const message = {
    alreadyUsed: cms("message.error.already"),
    emptyOptionValue: cms("message.error.variant"),
  };

  const onChange = useCallback(
    (variants, variantValues, validVariants, option1, option2, option3) => {
      let vars = variants.map((variant, idx) => {
        const newVard = {};
        if (variant.option1) {
          newVard.option1Val = variant.option1;
          newVard.option1 = option1;
        }
        if (variant.option2) {
          newVard.option2Val = variant.option2;
          newVard.option2 = option2;
        }
        if (variant.option3) {
          newVard.option3Val = variant.option3;
          newVard.option3 = option3;
        }

        newVard.price = (variantValues[idx] && parseFloat(variantValues[idx].price)) || 0;
        newVard.regularPrice = (variantValues[idx] && parseFloat(variantValues[idx].regularPrice)) || 0;
        newVard.costPrice = (variantValues[idx] && parseFloat(variantValues[idx].costPrice)) || 0;
        newVard.salePrice = (variantValues[idx] && parseFloat(variantValues[idx].salePrice)) || 0;
        newVard.sku = (variantValues[idx] && variantValues[idx].sku) || "";
        newVard.barcode = (variantValues[idx] && variantValues[idx].barcode) || "";
        newVard.inventoryQuantity = (variantValues[idx] && parseFloat(variantValues[idx].inventoryQuantity)) || 0;

        if (validVariants[idx]) {
          return newVard;
        }

        return "";
      });
      vars = vars.filter((item) => item);
      handleChange("variant", vars);
    },
    [handleChange]
  );

  const optionChange = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const optionValChange = (key, value) => {
    setState({
      ...state,
      [`option${key}Val`]: value,
      [`errorOption${key}`]: false,
    });
  };

  const variantsChange = useCallback(
    (tagsParam = [], variantValuesParam = [], validVariantsParam = {}) => {
      const tags = (tagsParam.length && tagsParam) || state.tags || [];
      const variantValues = (variantValuesParam.length && variantValuesParam) || state.variantValues || [];
      const validVariants = (Object.keys(validVariantsParam).length && validVariantsParam) || state.validVariants || {};
      const tagOption1 = [];
      const tagOption2 = [];
      const tagOption3 = [];
      const variants = [];

      tags.map((tag) => {
        if (tag.key === option.option1) {
          tagOption1.push(tag.value);
        }
        if (tag.key === option.option2) {
          tagOption2.push(tag.value);
        }
        if (tag.key === option.option3) {
          tagOption3.push(tag.value);
        }

        return true;
      });

      if (tagOption1.length && tagOption2.length && tagOption3.length) {
        tagOption1.forEach((option1Val) => {
          tagOption2.forEach((option2Val) => {
            tagOption3.forEach((option3Val) => {
              variants.push({
                option1: option1Val,
                option2: option2Val,
                option3: option3Val,
              });
            });
          });
        });
      } else if (tagOption1.length && tagOption2.length) {
        tagOption1.forEach((option1Val) => {
          tagOption2.forEach((option2Val) => {
            variants.push({
              option1: option1Val,
              option2: option2Val,
            });
          });
        });
      } else if (tagOption2.length && tagOption3.length) {
        tagOption2.forEach((option2Val) => {
          tagOption3.forEach((option3Val) => {
            variants.push({
              option2: option2Val,
              option3: option3Val,
            });
          });
        });
      } else if (tagOption1.length && tagOption3.length) {
        tagOption1.forEach((option1Val) => {
          tagOption3.forEach((option3Val) => {
            variants.push({
              option1: option1Val,
              option3: option3Val,
            });
          });
        });
      } else if (tagOption1.length) {
        tagOption1.forEach((option1Val) => {
          variants.push({
            option1: option1Val,
          });
        });
      } else if (tagOption2.length) {
        tagOption2.forEach((option2Val) => {
          variants.push({
            option2: option2Val,
          });
        });
      } else if (tagOption3.length) {
        tagOption3.forEach((option3Val) => {
          variants.push({
            option3: option3Val,
          });
        });
      }
      variants.map((variant, idx) => {
        if (!variantValues[idx]) {
          const { priceId, inventoryId } = data;
          const { regularPrice, price, costPrice, salePrice } = priceId || {};
          const { sku, quantity, barcode } = inventoryId || {};
          variantValues.splice(idx, 0, {
            price: price || "",
            regularPrice: regularPrice || "",
            costPrice: costPrice || "",
            salePrice: salePrice || "",
            barcode: barcode || "",
            sku: (sku && `${sku}-${idx + 1}`) || "",
            inventoryQuantity: quantity || "",
          });
        }
        if (validVariants[idx] === undefined) {
          validVariants[idx] = true;
        }
        return true;
      });

      const { variants: prevVariants, variantValues: prevVariantValues, validVariants: prevValidVariants } = state;
      if (
        JSON.stringify(prevVariants) !== JSON.stringify(variants) ||
        JSON.stringify(prevVariantValues) !== JSON.stringify(variantValues) ||
        JSON.stringify(prevValidVariants) !== JSON.stringify(validVariants)
      ) {
        const { option1, option2, option3 } = state;
        setState({ ...state, variants, variantValues, validVariants });
        onChange(variants, variantValues, validVariants, option1, option2, option3);
      }
    },
    [data, onChange, option.option1, option.option2, option.option3, state]
  );

  const handleVariants = () => {};

  const emptyOptionValue = (optionKey) => {
    setState({
      ...state,
      [optionKey]: "",
    });
  };

  useEffect(() => {
    variantsChange();
  }, [state.option1Val, state.option2Val, state.option3Val, variantsChange]);

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const handleKeyPress = (event, filledOption) => {
    const enterKeyPressed = event.keyCode === 13 || event.keyCode === 188; // press enter or ","
    if (enterKeyPressed) {
      event.preventDefault();
      let { option1Val, option2Val, option3Val } = state;
      const { tags } = state;
      if (filledOption === option.option1) {
        option1Val = option1Val.trim();
        if (!option1Val) {
          setState({
            ...state,
            option1Val: "",
            errorOption1: true,
            errMsg1: message.emptyOptionValue,
          });
          return;
        }
        const tag = { key: option.option1, value: option1Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({
            ...state,
            option1Val: "",
            errorOption1: true,
            errMsg1: message.alreadyUsed,
          });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option1Val");
      } else if (filledOption === option.option2) {
        option2Val = option2Val.trim();
        if (!option2Val) {
          setState({
            ...state,
            option2Val: "",
            errorOption2: true,
            errMsg2: message.emptyOptionValue,
          });
          return;
        }
        const tag = { key: option.option2, value: option2Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({
            ...state,
            option2Val: "",
            errorOption2: true,
            errMsg2: message.alreadyUsed,
          });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option2Val");
      } else if (filledOption === option.option3) {
        option3Val = option3Val.trim();
        if (!option3Val) {
          setState({
            ...state,
            option3Val: "",
            errorOption3: true,
            errMsg3: message.emptyOptionValue,
          });
          return;
        }
        const tag = { key: option.option3, value: option3Val };
        const index = tags.findIndex((item) => item.key === tag.key && item.value === tag.value);
        if (index !== -1) {
          setState({
            ...state,
            option3Val: "",
            errorOption3: true,
            errMsg3: message.alreadyUsed,
          });
          return;
        }
        tags.push(tag);
        setState({
          ...state,
          tags,
        });
        emptyOptionValue("option3Val");
      }
    }
  };

  const handleValidVariants = (index) => {
    const { variants = [], variantValues = [], validVariants = {}, option1, option2, option3 } = state;
    setState((prevState) => {
      return {
        ...prevState,
        validVariants: {
          ...prevState.validVariants,
          [index]: !prevState.validVariants[index],
        },
      };
    });
    onChange(variants, variantValues, { ...validVariants, [index]: !validVariants[index] }, option1, option2, option3);
  };

  const handleSubmit = () => {
    setLoader(true);
    setToastMessage(cms("message.success.variant"));
    setTimeout(() => {
      if (islastTab) {
        history.push("/product-lake");
        return;
      }
      if (isVariant) {
        handleTabChange(3);
        return;
      }
      handleTabChange(5);
    }, 1500);
  };

  const handleSkip = () => {
    if (isVariant || skipLoading) {
      handleTabChange(3);
      return;
    }
    handleTabChange(4);
  };

  const onFormSubmit = async () => {
    if (!state.showOption) {
      setLoader(true);
      setToastMessage(cms("message.success.variant"));
      setTimeout(() => {
        handleTabChange(4);
      }, 1000);
      return;
    }
    const option1 = (data.variant && data.variant.length && data.variant[0].option1) || data.option1;
    const option2 = (data.variant && data.variant.length && data.variant[0].option2) || data.option2;
    const option3 = (data.variant && data.variant.length && data.variant[0].option3) || data.option3;
    const isVariantLength = !!(data && data.variant && data.variant.length);
    const option1Vals = [];
    const option2Vals = [];
    const option3Vals = [];

    const { variant = [] } = data;

    variant.forEach((item) => {
      const option1ValExists = option1Vals.find((optionItem) => optionItem.label === item.option1Val);
      const option2ValExists = option2Vals.find((optionItem) => optionItem.label === item.option2Val);
      const option3ValExists = option3Vals.find((optionItem) => optionItem.label === item.option3Val);
      if (!option1ValExists && item.option1Val) {
        option1Vals.push({ label: item.option1Val, sortOrder: option1Vals.length + 1 });
      }
      if (!option2ValExists && item.option2Val) {
        option2Vals.push({ label: item.option2Val, sortOrder: option2Vals.length + 1 });
      }
      if (!option3ValExists && item.option3Val) {
        option3Vals.push({ label: item.option3Val, sortOrder: option3Vals.length + 1 });
      }
    });

    const options = [];

    if (option1 && option1Vals.length) {
      options.push({
        displayName: option1,
        optionValues: option1Vals,
        sortOrder: options.length + 1,
      });
    }

    if (option2 && option2Vals.length) {
      options.push({
        displayName: option2,
        optionValues: option2Vals,
        sortOrder: options.length + 1,
      });
    }

    if (option3 && option3Vals.length) {
      options.push({
        displayName: option3,
        optionValues: option3Vals,
        sortOrder: options.length + 1,
      });
    }

    const newVariants = variant.map((item) => {
      let optionValues = [
        {
          optionDisplayName: item.option1 || "",
          label: item.option1Val || "",
        },
        {
          optionDisplayName: item.option2 || "",
          label: item.option2Val || "",
        },
        {
          optionDisplayName: item.option3 || "",
          label: item.option3Val || "",
        },
      ];

      optionValues = optionValues
        .filter((opt) => opt.label)
        .map((optionItem, index) => {
          const updatedOption = { ...optionItem };
          updatedOption.sortOrder = `${index + 1}`;
          return updatedOption;
        });
      const { regularPrice, price, costPrice, salePrice } = item || {};
      let productPrice = {
        regularPrice: (regularPrice && regularPrice.toString()) || "",
        salePrice: (salePrice && salePrice.toString()) || "",
      };
      if (baseHelper.isBigCommerceUser(currentUser)) {
        productPrice = {
          price: (price && price.toString()) || "",
          costPrice: (costPrice && costPrice.toString()) || "",
        };
      }

      return {
        price: productPrice,
        inventory: {
          quantity: item.inventoryQuantity || null,
          sku: item.sku || null,
        },
        optionValues,
      };
    });

    const formValues = {
      productId: match.params.id,
      options,
      variants: newVariants,
    };

    await addProductVariant({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.ADD_PRODUCT_LAKE_VARIANT);
        const responseData = baseHelper.getResponseData(res.data, gql.ADD_PRODUCT_LAKE_VARIANT);
        if (responseError) {
          setLoader(false);
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setLoader(false);
          setToastMessage(cms("message.success.add"));
          setDisabledButton(false);
          setSkipLoading(true);
          setTimeout(() => {
            if (!isVariantLength) {
              return;
            }
            refetch();
            handleTabChange(3);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const remove = (item) => {
    const { allowedOption, tags } = state;
    const index = allowedOption.indexOf(item);

    if (index > -1) {
      allowedOption.splice(index, 1);
    }

    const nTags = [];
    tags.forEach((tag) => {
      if (tag.key !== item) {
        nTags.push(tag);
      }
    });

    const { variantValues, validVariants = {} } = state;

    variantsChange(nTags, variantValues, validVariants);

    setState({ ...state, allowedOption, tags: nTags });
  };
  const addOptions = () => {
    const { allowedOption } = state;
    if (!allowedOption.includes(option.option1)) {
      allowedOption.splice(0, 0, option.option1);
    } else if (!allowedOption.includes(option.option2)) {
      allowedOption.splice(1, 0, option.option2);
    } else if (!allowedOption.includes(option.option3)) {
      allowedOption.splice(2, 0, option.option3);
    }

    setState({ ...state, allowedOption });
  };
  const removeTag = (tag) => {
    const { tags } = state;
    const index = tags.indexOf(tag);
    if (index !== -1) {
      tags.splice(index, 1);
    }

    const { variantValues = [], validVariants = {} } = state;

    variantsChange(tags, variantValues, validVariants);

    setState({ ...state, tags });
  };
  const variantValueChange = (type, value, index) => {
    const { variantValues } = state;
    variantValues[index][type] = value;
    const { variants, validVariants, option1, option2, option3 } = state;
    onChange(variants, variantValues, validVariants, option1, option2, option3);
    setState({ ...state, variantValues });
  };

  const renderVariants = () => {
    const { variantValues, variants, validVariants, option1, option2, option3 } = state;
    return (
      <VariantField
        data={data}
        currentUser={currentUser}
        variantValues={variantValues}
        variants={variants}
        validVariants={validVariants}
        option1={option1}
        option2={option2}
        option3={option3}
        handleValidVariants={handleValidVariants}
        variantValueChange={variantValueChange}
        cms={cms}
      />
    );
  };
  const renderTags = (selectedTag) => {
    const { tags } = state;
    let tagFields = tags.map((tag) => {
      if (tag.key === selectedTag) {
        return (
          <Tag key={tag.key} onRemove={() => removeTag(tag)}>
            {tag.value}
          </Tag>
        );
      }
      return "";
    });
    tagFields = tagFields.filter((item) => item);
    return <Stack wrap>{tagFields}</Stack>;
  };
  const showOption1 = state.allowedOption.includes(option.option1);
  const showOption2 = state.allowedOption.includes(option.option2);
  const showOption3 = state.allowedOption.includes(option.option3);

  const showRemoveButton = state.allowedOption.length > 1;
  const showAddButton = state.allowedOption.length !== 3;
  if (productLoading) {
    return <SkeletonCard />;
  }
  const handleAction = () =>
    data && data.variants && data.variants.length
      ? history.push(`/lake-variants/add/${match.params.id}`)
      : setState({ ...state, showOption: !state.showOption });
  return (
    <>
      <Card
        title={[
          cms("label.productVariant"),
          data && data.title && (
            <TextStyle variation="subdued">
              {data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productVariant"
        actions={[
          {
            id: "addVariant",
            content: state.showOption ? cms("common.button.cancel") : cms("label.addVariant"),
            onAction: handleAction,
          },
          // {
          //   id: "learnMoreVariant",
          //   content: "Learn more",
          //   onAction: () => {
          //     learnMore("productVariant", cms("label.productVariant"));
          //   },
          // },
        ]}
      >
        {/* {state.showOption && (
          <div>
            <Card.Section>
              <Banner isOpen={state.showOption} status={constant.INFO}>
                When you have added your variants then the fulfillment section will disappear.
              </Banner>
            </Card.Section>
          </div>
        )} */}
        {(state.showOption || !(data && data.variants && data.variants.length)) && (
          <Card.Section>
            {state.showOption && (
              <>
                <Banner isOpen={state.showOption} status={constant.INFO}>
                  <strong>{`${cms("message.info.note")}: `}</strong>
                  {cms("message.info.variant")}
                </Banner>

                <br />
              </>
            )}
            {!(data && data.variants && data.variants.length) && (
              <>
                <TextContainer>
                  <span id="addVariants">{cms("label.caption.variant")}</span>
                </TextContainer>
                <br />
                <Caption>
                  <TextStyle variation="strong">
                    <span id="pleaseNote">{`${cms("label.note")}: `}</span>
                  </TextStyle>
                  {cms("label.caption.add")}
                </Caption>
              </>
            )}
          </Card.Section>
        )}
        {state.showOption && (
          <Card.Section subdued>
            <Stack vertical spacing="extraTight">
              {showOption1 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      value={state.option1}
                      onChange={(val) => optionChange("option1", val)}
                      id="option1Change"
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("label.separate")} preferredPosition="above">
                      <div onKeyDown={(e) => handleKeyPress(e, option.option1)}>
                        <TextField
                          id="option1Values"
                          placeholder={cms("label.separate")}
                          value={state.option1Val}
                          onChange={(val) => optionValChange("1", val)}
                          error={state.errorOption1 && state.errMsg1}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option1)} id="cancelOption1Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption1 && renderTags(option.option1)}
              {showOption2 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      value={state.option2}
                      onChange={(val) => optionChange("option2", val)}
                      id="option2Change"
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("label.separate")} preferredPosition="above">
                      <div onKeyDown={(e) => handleKeyPress(e, option.option2)} id="option2">
                        <TextField
                          id="option2Values"
                          placeholder={cms("placeholder.separate")}
                          value={state.option2Val}
                          onChange={(val) => optionValChange("2", val)}
                          error={state.errorOption2 && state.errMsg2}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option2)} id="cancelOption2Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption2 && renderTags(option.option2)}
              {showOption3 && (
                <Stack wrap={false}>
                  <Stack.Item>
                    <TextField
                      label={cms("label.name")}
                      id="option3Change"
                      labelHidden
                      value={state.option3}
                      onChange={(val) => optionChange("option3", val)}
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Tooltip content={cms("label.separate")} preferredPosition="above">
                      <div onKeyDown={(e) => handleKeyPress(e, option.option3)} id="option3">
                        <TextField
                          label={cms("label.value")}
                          id="option3Values"
                          placeholder={cms("placeholder.separate")}
                          labelHidden
                          value={state.option3Val}
                          onChange={(val) => optionValChange("3", val)}
                          error={state.errorOption3 && state.errMsg3}
                        />
                      </div>
                    </Tooltip>
                  </Stack.Item>
                  {showRemoveButton && (
                    <Stack.Item>
                      <Button slim onClick={() => remove(option.option3)} id="cancelOption3Button">
                        <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                      </Button>
                    </Stack.Item>
                  )}
                </Stack>
              )}
              {showOption3 && renderTags(option.option3)}
              {showAddButton && (
                <Button slim onClick={addOptions} id="addOptionsButton">
                  {cms("button.add")}
                </Button>
              )}
            </Stack>
          </Card.Section>
        )}
        {data && data.variants && data.variants.length ? (
          <RenderVariants
            cms={cms}
            data={data}
            handleTabChange={handleTabChange}
            handleVariants={handleVariants}
            loading={productLoading}
            refetch={refetch}
            setBanner={setBanner}
            setToastMessage={setToastMessage}
            variantLength={variantLength}
            setDisabledButton={setDisabledButton}
          />
        ) : (
          state.showOption && renderVariants()
        )}
      </Card>
      <div className="toast">
        <Toast message={toastmessage} />
      </div>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button
            onClick={() => {
              const tab = isVariant ? 1 : 2;
              handleTabChange(tab);
            }}
          >
            {cms("common.button.previous")}
          </Button>
        </Stack.Item>
        {data && data.status && data.status.toLowerCase() === constant.NEW && !islastTab && (
          <Stack.Item>
            <Button onClick={() => handleSkip()} disabled={addVariantLoading}>
              {cms("common.button.skip")}
            </Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => (data && data.variants && data.variants.length ? handleSubmit() : onFormSubmit())}
            disabled={islastTab ? false : disabledButton}
            loading={loader || addVariantLoading}
          >
            {(state.showOption && tabLength - 2 === selectedTab && cms("common.button.submit")) || islastTab
              ? cms("common.button.finish")
              : cms(`common.button.${islastTab ? "submit" : "save"}`)}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content:
            (state.showOption && tabLength - 2 === selectedTab && cms("button.submit")) ||
            cms(`button.${islastTab ? "submit" : "save"}`),
          onAction: () => (data && data.variants && data.variants.length ? handleSubmit() : onFormSubmit()),
          loading: loader || addVariantLoading,
          disabled: disabledButton,
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => {
              const tab = isVariant ? 1 : 2;
              handleTabChange(tab);
            },
          },
        ]}
      /> */}
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

EditVariant.propTypes = tabProp.type;

export default withErrorBoundary(EditVariant);
