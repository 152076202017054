const fieldData = (cms) => {
  const productApprovalPageData = [
    {
      label: cms("section.approval.content.reviewAll.title"),
      helpText: cms("section.approval.content.reviewAll.description"),
      key: "review",
      name: "productSetting",
    },
    {
      label: cms("section.approval.content.expect.title"),
      helpText: cms("section.approval.content.expect.description"),
      key: "selected",
      name: "productSetting",
    },
    {
      label: cms("section.approval.content.approve.title"),
      helpText: cms("section.approval.content.approve.description"),
      key: "all",
      name: "productSetting",
    },
  ];
  return productApprovalPageData;
};
export default fieldData;
