import * as yup from "yup";

const subjectSchema = (cms) => {
  return yup.object().shape({
    subject: yup
      .string()
      .required(`${cms("message.error.isFieldRequired")}`)
      .nullable(),
  });
};
const messageSchema = (cms) => {
  return yup.object().shape({
    message: yup
      .string()
      .required(`${cms("message.error.isFieldRequired")}`)
      .nullable(),
  });
};

export { subjectSchema, messageSchema };
