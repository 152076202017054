import React, { useContext } from "react";
import { Layout, Card, Checkbox, TextField, TextContainer } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

const VendorSection = () => {
  const { cms = {} } = useContext(PrivateContext);
  const getTextFields = () => {
    const fields = [
      {
        label: cms("section.contact.label.firstName"),
        placeholder: cms("section.contact.placeHolder.firstName"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.lastName"),
        placeholder: cms("section.contact.placeHolder.lastName"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.email"),
        placeholder: cms("section.contact.placeHolder.email"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.phone"),
        placeholder: cms("section.contact.placeHolder.phone"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.address"),
        placeholder: cms("section.contact.placeHolder.address"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.city"),
        placeholder: cms("section.contact.placeHolder.city"),
        isRequired: true,
      },
      {
        label: cms("section.contact.label.postalCode"),
        placeholder: cms("section.contact.placeHolder.postalCode"),
        isRequired: false,
      },
    ];
    return fields.map(({ label, placeholder, isRequired }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField label={`${label}${required}`} placeholder={placeholder} value="" disabled />
          <br />
        </>
      );
    });
  };
  const getFields = () => {
    return (
      <>
        <TextContainer>{cms("section.contact.subDescription")}</TextContainer>
        <br />
        {getTextFields()}
        <Checkbox checked={false} label={cms("section.contact.label.autoVerify")} disabled />
      </>
    );
  };
  return (
    <div>
      <Layout.AnnotatedSection title={cms("section.contact.title")} description={cms("section.contact.description")}>
        <Card title={cms("section.contact.subTitle")}>
          <Card.Section>{getFields()}</Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};

export default VendorSection;
