import PropTypes from "prop-types";

const vendorProfileProps = {
  vendorDetails: {
    data: PropTypes.shape({
      payment: PropTypes.shape(PropTypes.object).isRequired,
      shipping: PropTypes.shape(PropTypes.object).isRequired,
      socialLink: PropTypes.shape(PropTypes.object).isRequired,
      customFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  },
};

export default vendorProfileProps;
