import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Card,
  Collapsible,
  DisplayText,
  // Link,
  ProgressBar,
  SkeletonBodyText,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { StyledCardHeader } from "asset/styles/globalStyle";
import { useQuery } from "@apollo/react-hooks";
import { GET_ALL_COUNTS } from "app/reports/apollo/queries/quickReportData";
import constant from "lib/constant/constant";
import { StyledPlanCard, InnerCard } from "app/plans/widgets/operator/features/planCard/planCardStyle";

const ProviderPlanCard = () => {
  const { cms, currentUser, isLoading } = useContext(PrivateContext);
  const [openPlanCard, setOpenPlanCard] = useState(true);
  const { gql } = constant;
  const [productCount, setProductCount] = useState(0);

  const { plan = {}, products = {} } = currentUser;
  const { code = "", commission, name = "", price = "" } = plan;
  const { max: maxProduct = "", used = 0 } = products || {};
  const planURL = "/update/plan";

  const { data: productCountData } = useQuery(GET_ALL_COUNTS);

  useEffect(() => {
    if (productCountData) {
      const countProduct = baseHelper.getResponseData(productCountData, gql.GET_COUNT);
      const { productCount: productCounts } = countProduct;
      setProductCount(productCounts.total);
    }
  }, [productCountData]);

  if (isLoading) {
    return <SkeletonBodyText lines={3} />;
  }

  return (
    <Card>
      <StyledPlanCard>
        <StyledCardHeader>
          <Card.Header title={cms("common.label.planCard.title")}>
            <Button
              plain
              disclosure={openPlanCard ? "up" : "down"}
              onClick={() => {
                setOpenPlanCard(!openPlanCard);
              }}
            >
              {openPlanCard ? cms("common.label.hide") : cms("common.label.show")}
            </Button>
          </Card.Header>
        </StyledCardHeader>
        <Collapsible
          open={openPlanCard}
          id="collapsiblePlanCard"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <Card>
            <InnerCard className="inner-card">
              <Card.Section subdued>
                <div className="stack-wrapper plan">
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle variation="strong">
                        {baseHelper.ucFirst(baseHelper.getPlanBadge(code || name))}
                      </TextStyle>
                      <Caption>
                        <TextStyle variation="subdued">{cms("common.label.planCard.currentPlan")}</TextStyle>
                      </Caption>
                    </Stack.Item>
                    <Stack.Item>
                      <DisplayText size="small">{`${currentUser.moneyFormat}${price}/m`}</DisplayText>
                    </Stack.Item>
                  </Stack>
                </div>
                <div className="stack-wrapper count">
                  <Stack>
                    <Stack.Item fill>
                      <TextStyle>{cms("common.label.tabs.operator.products")}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                      <TextStyle variation="subdued">{`${productCount || 0}/${maxProduct}`}</TextStyle>
                    </Stack.Item>
                  </Stack>
                  <ProgressBar progress={((productCount || 0) / maxProduct) * 100} size="small" />
                </div>
                <div className="stack-wrapper count">
                  <Stack>
                    <Stack.Item fill>
                      <TextStyle>{cms("common.label.commission")}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                      <TextStyle variation="subdued">{`${commission}%`}</TextStyle>
                    </Stack.Item>
                  </Stack>
                </div>
                <div className="upgrade-button">
                  <Button primary fullWidth url={planURL}>
                    {cms("common.button.upgrade")}
                  </Button>
                </div>
              </Card.Section>
            </InnerCard>
          </Card>
        </Collapsible>
      </StyledPlanCard>
    </Card>
  );
};

export default ProviderPlanCard;
