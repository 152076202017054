import { gql } from "apollo-boost";

const GET_ORDER_EXPORT_SETTING = gql`
  query {
    getOrderExportSetting {
      data {
        export {
          createdAt {
            label
            isHideCsv
            isHidePdf
          }
          fulfillmentStatus {
            label
            isHideCsv
            isHidePdf
          }
          lineItemId {
            label
            isHideCsv
            isHidePdf
          }
          orderNumber {
            label
            isHideCsv
            isHidePdf
          }
          markupPrice {
            label
            isHideCsv
            isHidePdf
          }
          orderId {
            label
            isHideCsv
            isHidePdf
          }
          orderStatus {
            label
            isHideCsv
            isHidePdf
          }
          price {
            label
            isHideCsv
            isHidePdf
          }
          product {
            label
            isHideCsv
            isHidePdf
          }
          variantSku {
            label
            isHideCsv
            isHidePdf
          }
          variantWeight {
            label
            isHideCsv
            isHidePdf
          }
          quantity {
            label
            isHideCsv
            isHidePdf
          }
          vendor {
            label
            isHideCsv
            isHidePdf
          }
          wholesalePrice {
            label
            isHideCsv
            isHidePdf
          }
          trackingNumber {
            label
            isHideCsv
            isHidePdf
          }
          shippingCountry {
            label
            isHideCsv
            isHidePdf
          }
          shippingName {
            label
            isHideCsv
            isHidePdf
          }
          shippingAddress {
            label
            isHideCsv
            isHidePdf
          }
          shippingCity {
            label
            isHideCsv
            isHidePdf
          }
          shippingState {
            label
            isHideCsv
            isHidePdf
          }
          shippingPincode {
            label
            isHideCsv
            isHidePdf
          }
          discount {
            label
            isHideCsv
            isHidePdf
          }
          shipping {
            label
            isHideCsv
            isHidePdf
          }
          totalVendorPayout {
            label
            isHideCsv
            isHidePdf
          }
          tax {
            label
            isHideCsv
            isHidePdf
          }
          customerPrice {
            label
            isHideCsv
            isHidePdf
          }
          comparePrice {
            label
            isHideCsv
            isHidePdf
          }
        }
        isMarkupDiscountExport
        updatedAt
      }
      error
      status
    }
  }
`;
export default GET_ORDER_EXPORT_SETTING;
