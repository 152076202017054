import { gql } from "apollo-boost";

const UPDATE_PACKING_SLIP_ACCESS = gql`
  mutation updatePackingSlipAccess($input: UpdatePackingSlipAccessInput!) {
    updatePackingSlipAccess(input: $input) {
      status
      data
      error
    }
  }
`;

const UPDATE_PACKING_SLIP_LABEL = gql`
  mutation updatePackingSlipLabelSetting($input: UpdatePackingSlipLabelSettingInput!) {
    updatePackingSlipLabelSetting(input: $input) {
      status
      data
      error
    }
  }
`;

export { UPDATE_PACKING_SLIP_ACCESS, UPDATE_PACKING_SLIP_LABEL };
