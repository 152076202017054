import { gql } from "apollo-boost";

const UPDATE_DISCOUNT = gql`
  mutation manageDiscount($input: ManageDiscount!) {
    manageDiscount(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_DISCOUNT;
