import React, { useState } from "react";
import { AppProvider, Checkbox, Modal, ResourceItem, ResourceList, Stack, TopBar } from "@shopify/polaris";
import PropTypes from "prop-types";
import { LocationTopBar } from "app/setup/modules/operator/features/currency/currencyStyle";

// eslint-disable-next-line import/no-cycle
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const AddCurrency = (props) => {
  const {
    activator,
    active,
    addCountry,
    cms,
    currency,
    handleChange,
    setActive,
    setAddCountry,
    setCurrency,
    setSubmitEnable,
    isAllow,
    setIsAllow,
    inlineError,
  } = props;

  const { color } = constant;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [country, setCountry] = useState(currency);
  const [searchValue, setSearchValue] = useState("");

  const theme = {
    colors: {
      topBar: {
        background: color.WHITE,
        backgroundLighter: color.GRAY,
        backgroundDarker: color.ATHENS_GRAY,
        border: color.LOBLOLLY,
        color: color.CLAY,
      },
    },
  };

  const handleSearchChange = (searchItem) => {
    setSearchValue(searchItem);
    setIsSearchActive(searchItem.length > 0);
    const countryToShow = currency.filter((item) => {
      const { id: code = "", country: currencyName = "" } = item || {};
      if (
        (code && code.toLowerCase().includes(searchItem)) ||
        (code && code.includes(searchItem)) ||
        (currencyName && currencyName.toLowerCase().includes(searchItem)) ||
        (currencyName && currencyName.includes(searchItem))
      ) {
        return true;
      }
      return false;
    });
    setCountry(countryToShow);
  };

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={(value) => handleSearchChange(value)}
      value={searchValue}
      placeholder={cms("placeholder.search")}
      showFocusBorder
    />
  );

  const handleSearchResultsDismiss = () => {
    setIsSearchActive(false);
    setSearchValue("");
  };

  const onSubmit = () => {
    const countryList = { ...addCountry };
    const addRow = [];
    currency.forEach(({ isCheck, id, country: countryName, price, symbol, isPayment }) => {
      let updatedPrice = price;
      let updateIsPayment = isPayment;
      const getPrice =
        countryList &&
        countryList.currency &&
        countryList.currency.rate &&
        countryList.currency.rate.find((item) => id === item.currencyCode);

      if (getPrice) {
        updatedPrice = getPrice.price;
        updateIsPayment = getPrice.isPayment;
      }
      if (isCheck === true) {
        addRow.push({
          currencyCode: id,
          country: countryName,
          price: updatedPrice,
          symbol,
          isPayment: isAllow.payment || updateIsPayment,
        });
      }
    });
    if (countryList && countryList.currency) {
      countryList.currency.rate = [...addRow];
    }
    if (countryList && !countryList.currency) {
      countryList.currency = {};
      countryList.currency.rate = [...addRow];
    }
    const isEnable = addRow && addRow.find(({ price }) => price === "");
    if (isEnable) {
      setSubmitEnable(true);
    } else setSubmitEnable(inlineError);
    setAddCountry(countryList);
    const isCheck =
      countryList &&
      countryList.currency &&
      countryList.currency.rate &&
      countryList.currency.rate.map((element) => element.isPayment === true).filter((item) => item === false);
    if (isCheck.length > 0) {
      setIsAllow({
        payment: false || isAllow.payment,
      });
    } else
      setIsAllow({
        payment: true,
      });
    if (addRow.length === 0) {
      setIsAllow({
        payment: false,
      });
      setSubmitEnable(false);
    }
    setActive(!active);
  };

  const handleCheckbox = (index, checkUncheck, code) => {
    const countryCheckbox = [...currency];
    countryCheckbox.forEach((countryData) => {
      if (countryData.id === code) {
        // eslint-disable-next-line no-param-reassign
        countryData.isCheck = checkUncheck;
      }
      return false;
    });
    setCurrency([...countryCheckbox]);
  };

  const handleClose = () => {
    handleChange();
    const enableCheckbox = [...currency];
    const addCheckbox = addCountry;
    const { currency: { rate = [] } = {} } = addCheckbox || {};
    const checkCurrenies = (rate && rate.length && rate.map((element) => element.currencyCode)) || [];
    enableCheckbox.forEach((countryData, index) => {
      if (!checkCurrenies.includes(countryData.id)) {
        // eslint-disable-next-line no-param-reassign
        enableCheckbox[index].isCheck = false;
      }
    });
    setCurrency([...enableCheckbox]);
  };

  // todo - add image
  const renderItem = (item, _, index) => {
    const { id, country: countryName, isCheck } = item;
    return (
      <ResourceItem id={id}>
        <Stack>
          <Checkbox
            label={countryName}
            key={id}
            checked={isCheck}
            onChange={(action) => handleCheckbox(index, action, id)}
          />
          {/* <div className="flag"><Thumbnail source={image} /></div> */}
        </Stack>
      </ResourceItem>
    );
  };

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={cms("label.add")}
      primaryAction={{
        content: cms("label.add"),
        onAction: onSubmit,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: () => {
            handleClose();
          },
        },
      ]}
    >
      <Modal.Section>
        <AppProvider theme={theme}>
          <LocationTopBar className="currency-popup">
            <div className="currency-topBar">
              <TopBar
                searchResultsVisible={isSearchActive}
                searchField={searchFieldMarkup}
                onSearchResultsDismiss={handleSearchResultsDismiss}
              />
            </div>
            <ResourceList items={isSearchActive ? country : currency} renderItem={renderItem} />
          </LocationTopBar>
        </AppProvider>
      </Modal.Section>
    </Modal>
  );
};

export default withErrorBoundary(AddCurrency);

AddCurrency.propTypes = {
  active: PropTypes.bool,
  activator: PropTypes.objectOf(PropTypes.string),
  cms: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};

AddCurrency.defaultProps = {
  active: null,
  activator: {},
  cms: () => {},
};
