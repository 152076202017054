import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  Banner,
  Button,
  Caption,
  Card,
  Collapsible,
  Form,
  FormLayout,
  Layout,
  TextStyle,
  PageActions,
  TextContainer,
} from "@shopify/polaris";
import { getCode, getNames, getName } from "country-list";

import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_SELLER_LOCATION } from "app/setup/apollo/queries";
import { UPDATE_SELLER_LOCATION } from "app/setup/apollo/mutations";
import { Spinner, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

import AddMapperField from "./component/add";

const LocationMappingSetting = () => {
  const { cms = {}, history } = useContext(PrivateContext);
  const [country, setCountry] = useState([{ label: "", value: "" }]);

  const [countryList, setCountryList] = useState([]);
  const [selected, setSelected] = useState([]);

  const [message, setMessage] = useState();
  const [updateAt, setUpdateAt] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const { error: errorCountryList, loading: loadingCountryList, data: dataCountryList } = useQuery(GET_SELLER_LOCATION);
  const queryData = baseHelper.getResponseData(dataCountryList, constant.gql.GET_SELLER_LOCATION);
  const [updateSellerLocation, { loading: isLoadingLabel }] = useMutation(UPDATE_SELLER_LOCATION);

  useEffect(() => {
    if (queryData) {
      const queryError = baseHelper.getResponseError(errorCountryList, constant.gql.GET_SELLER_LOCATION);
      const { locations, updatedAt } = queryData;
      const updatedData = (locations || [])
        .filter((item) => item)
        .map((item) => {
          return {
            label: getName(item),
            value: item,
          };
        });
      if (queryError) {
        setBanner({
          isOpen: true,
          title: queryError,
          status: constant.CRITICAL,
        });
      }
      setUpdateAt(updatedAt);
      setCountry([...updatedData]);
    }
  }, [queryData, errorCountryList]);

  useEffect(() => {
    if (countryList && !countryList?.length) {
      const countryListArray = getNames();
      const updatedCountryList = countryListArray.map((item) => {
        return { label: item, value: getCode(item) };
      });

      setCountryList(updatedCountryList);
    }
  }, [countryList]);

  const removeItem = (item) => {
    country.splice(item, 1);
    const disableFields = [...country];
    disableFields.forEach((key, index) => {
      disableFields[index] = key - 1;
    });
    setCountry([...country]);
    setIsDisabled(false);
    country.forEach((data) => {
      if (data.label && data.value) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    });
  };
  useEffect(() => {
    if (country && country.length) {
      let countrySelected = [];
      countrySelected = country.map((data) => data.value);
      const updatedCountryList = countryList?.map((item) => {
        const countryData = { label: item.label, value: item.value };
        countryData.disabled = countrySelected.includes(item.value);
        return countryData;
      });
      setCountryList(updatedCountryList || []);
      country.forEach((item) => {
        if (item.label && item.value) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const updateVal = (value, index) => {
    const advancedValue = [...country];
    advancedValue[index].value = value;
    advancedValue[index].label = getName(value);
    setCountry([...advancedValue]);
    setIsDisabled(false);
  };

  const add = () => {
    const { length } = country;
    let isInvalid = false;
    if (length > 0) {
      const line = country[length - 1];
      const keys = Object.keys(line);
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
      }
    }
    if (!isInvalid) {
      const row = { label: null, value: null };
      country.push(row);
      selected.push(row);
      setSelected([...selected]);
    }
    setIsDisabled(true);
  };

  const onSubmit = () => {
    const selectedCountry = [];
    country.forEach((item) => {
      selectedCountry.push(item.value);
    });
    updateSellerLocation({
      variables: {
        input: { locations: selectedCountry },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_SELLER_LOCATION);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_SELLER_LOCATION);
        if (responseData) {
          if (!updateAt) {
            setMessage(cms("message.success.save"));
          } else {
            setMessage(cms("message.success.update"));
          }
          setIsDisabled(true);
        }
        if (responseError) {
          setBanner({
            isOpen: true,
            title: responseError,
            status: constant.CRITICAL,
          });
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          title: errorHelper.parse(exception),
          status: constant.CRITICAL,
        });
      });
  };

  if (loadingCountryList) {
    return <Spinner />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card
          title={[
            cms("section.title"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.description")}</TextContainer>
            <Collapsible
              open
              id="advancedCommission"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <Form>
                <FormLayout>
                  <AddMapperField
                    cms={cms}
                    country={country}
                    removeItem={removeItem}
                    countryList={countryList}
                    options={countryList}
                    updateVal={updateVal}
                    count={country.length}
                  />
                </FormLayout>
                <Button plain id="addLink" onClick={add}>
                  {cms("label.addMore")}
                </Button>
                <br />
                {updateAt && (
                  <ActionButton>
                    <PageActions
                      primaryAction={{
                        content: cms("common.button.update"),
                        onAction: () => onSubmit(),
                        loading: isLoadingLabel,
                        disabled: isDisabled,
                      }}
                      secondaryActions={[
                        {
                          content: cms("common.button.cancel"),
                          onAction: () => history.push("/setting"),
                        },
                      ]}
                    />
                  </ActionButton>
                )}
              </Form>
            </Collapsible>
          </Card.Section>
        </Card>
        {!updateAt && (
          <ActionButton>
            <PageActions
              primaryAction={{
                content: cms("button.submit"),
                onAction: () => onSubmit(),
                loading: isLoadingLabel,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          </ActionButton>
        )}
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(LocationMappingSetting), { feature: constant.LOCATION_MAPPING_SETTING });
