import React from "react";

import LoginHeader from "./subFeatureItems/loginHeader";
import RegisterContent from "./subFeatureItems/registerContent";
import Email from "./subFeatureItems/email";
import Footer from "./subFeatureItems/footer";
import Contact from "./subFeatureItems/contactDetail";

const PageContent = () => {
  return (
    <>
      <LoginHeader />
      <RegisterContent />
      <Email />
      <Footer />
      <Contact />
    </>
  );
};

export default PageContent;
