import styled from "styled-components";

export const ActionButton = styled.div`
  .Polaris-PageActions {
    padding: 2rem 0 0 0;
    border-top: 0;
  }
`;

export const TagMapTable = styled.div`
  td,
  th {
    min-width: 150px !important;
    text-align: left !important;
  }
  .delete-row {
    display: flex;
    .Polaris-Button {
      margin-left: 0;
    }
  }
  .delete-row div {
    width: 100%;
  }
`;

export const StackItems50 = styled.div`
  .Polaris-Stack {
    margin-top: 0;
  }
  .Polaris-Stack__Item {
    flex: 1 1 47%;
  }
`;
