import constant from "lib/constant/constant";

const editData = (cms) => {
  return {
    option: {
      option1: "option1",
      option2: "option2",
      option3: "option3",
    },
    policyOption: [
      {
        label: cms("label.notTrack"),
        value: "Don't track inventory",
      },
      {
        label: cms("label.shopify"),
        value: "shopify",
      },
    ],
    typeOption: [
      {
        label: cms("common.label.flat"),
        value: constant.FLAT,
      },
      {
        label: cms("common.label.percentage"),
        value: constant.PERCENTAGE,
      },
    ],
  };
};

export default editData;

export const getPanelComponent = (selectedTab, cms, tabLength) => {
  const tabObj = selectedTab === tabLength - 1 ? { islastTab: true } : { islastTab: false };
  return editData(cms).tabs[selectedTab].PanelComponent(tabObj);
};
