import { gql } from "apollo-boost";

const UPDATE_NOTIFICATION_SETTING = gql`
  mutation notificationSetting($input: NotificationSetting!) {
    notificationSetting(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_NOTIFICATION_SETTING;
