import { gql } from "apollo-boost";

const GENERATE_SHIPPING_LABEL = gql`
  mutation generateLakeShippingLabel($input: ShippingLabelInput!) {
    generateLakeShippingLabel(input: $input) {
      status
      data
      error
    }
  }
`;

export default GENERATE_SHIPPING_LABEL;
