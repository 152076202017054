import React, { useState } from "react";
import { Card, Modal, Stack, Link, Thumbnail, Icon } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";

// import components
import { DropZone } from "lib/components";
import { imageHelper } from "lib/helpers";

const Image = (props) => {
  const { setImage, productImages: images = [], image = [], cms } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const onImageChange = (nImage) => {
    setSelectedImage(selectedImage.imageUrl === nImage.imageUrl ? {} : nImage);
  };

  const uploadLocalImage = (value) => {
    const uploadImage = value;
    uploadImage.upload = constant.LOCAL;
    setImage(uploadImage);
  };

  const renderModal = () => {
    const allImages = images.filter((item) => item && item.imageUrl);
    const productImages = allImages.map((nImage, index) => {
      const isSelectedImage = nImage.imageUrl === selectedImage.imageUrl;
      return (
        <Stack.Item key={nImage.position}>
          <Stack alignment="center" vertical>
            <Stack.Item>
              <Link onClick={() => onImageChange(nImage)}>
                <Thumbnail
                  source={imageHelper.resize({ url: nImage.imageUrl, type: constant.imageTypes.THUMBNAIL })}
                  size="large"
                  alt={`image ${index}`}
                />
              </Link>
            </Stack.Item>
            {isSelectedImage && (
              <Stack.Item>
                <Icon source={CircleTickMajor} color="greenDark" backdrop />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      );
    });
    return <Stack>{productImages}</Stack>;
  };
  return (
    <Card
      title={cms("section.images.title")}
      id="variantImage"
      sectioned
      actions={[
        {
          content: !(image && image.length) && cms("section.images.modal.title"),
          onAction: () => !(image && image.length) && setIsOpen(true),
        },
      ]}
    >
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedImage({});
        }}
        title={cms("section.images.modal.title")}
        primaryAction={{
          content: cms("section.images.modal.button.save"),
          onAction: () => selectedImage.imageUrl && [setIsOpen(false), setImage([{ url: selectedImage.url }])],
        }}
        sectioned
      >
        <Modal.Section>
          <Card sectioned>{renderModal()}</Card>
        </Modal.Section>
      </Modal>
      <DropZone
        id="dropZone"
        onAdd={(value) => uploadLocalImage(value)}
        onRemove={() => setImage([])}
        fileList={(image && image.length && image) || []}
        allowMultiple={false}
        size={10}
      />
    </Card>
  );
};

Image.propTypes = {
  setImage: PropTypes.func.isRequired,
  productImages: PropTypes.arrayOf(PropTypes.shape).isRequired,
  image: PropTypes.arrayOf(PropTypes.any),
};

Image.defaultProps = {
  image: [],
};

export default Image;
