import React, { useContext } from "react";
import { Badge, Button, Card, DisplayText, List, Stack } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const Heading = ({ name = "", monthlyFee = "", isActive = false, isPopular = false, isHidePrice = false }) => {
  const { cms } = useContext(PrivateContext);

  return (
    <Stack alignment="center" wrap={false}>
      <Stack.Item fill>
        <DisplayText size="small" element="h1">
          <Stack>
            <Stack.Item fill>
              {name.toUpperCase()}
              {isPopular && <Badge status={constant.SUCCESS}>{cms("label.popular")}</Badge>}
              {isActive && <Badge status={constant.INFO}>{cms("label.active")}</Badge>}
            </Stack.Item>
          </Stack>
        </DisplayText>
      </Stack.Item>
      {!isHidePrice && (
        <Stack.Item>
          <DisplayText size="large">{`$${monthlyFee}/m`}</DisplayText>
        </Stack.Item>
      )}
      {isHidePrice && (
        <Stack.Item>
          <DisplayText size="large">3% of total order value / month</DisplayText>
        </Stack.Item>
      )}
    </Stack>
  );
};

const PlanCard = ({ plan = {}, callback = () => {}, activePlan = "", submitLoading = false }) => {
  const { cms, history } = useContext(PrivateContext);

  const { sections = [], feature: features = [], isPopular, labelVendor, maxProducts, maxVendors, monthlyFee = "", name = "" } = plan;
  const isUpdatePlanPage = history && history.location && history.location.pathname === "/update/plan";

  const buttonText = isUpdatePlanPage ? cms("button.update") : cms("button.select");

  const isActivePlan = activePlan === plan.code;
  const isStartUpPlan = plan.code === "startup";
  const isDisabled = isUpdatePlanPage ? isActivePlan : !!activePlan;

  return (
    <Card>
      <Card.Section>
        <Heading name={name} monthlyFee={monthlyFee} isActive={isActivePlan} isPopular={isPopular} isHidePrice={isStartUpPlan} />
      </Card.Section>
      <Card.Section>
        <List>
          {!isStartUpPlan && [
            <List.Item key="planProduct">{`${cms("label.product")}: ${maxProducts}`}</List.Item>,
            <List.Item key="planVendor">{`${cms("label.vendor")}: ${labelVendor || maxVendors}`}</List.Item>,
          ]}
          {features &&
            features.map((feature) => {
              return <List.Item key={feature}>{feature}</List.Item>;
            })}
          {sections && sections.length && sections.map((section, index) => {
            const { title, content = [] } = section;
            return [
              <List.Item key={`section${index}`}>{title}</List.Item>,
              <List>
                {content && content.length && content.map((contentText, index) => {
                  return <List.Item key={`sectionContent${index}`}>{contentText}</List.Item>;
                })}
              </List>,
            ];
          })}
        </List>
        <br />
        <Button primary fullWidth onClick={() => callback()} disabled={isDisabled} loading={submitLoading}>
          {buttonText}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default PlanCard;
