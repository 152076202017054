import React, { useEffect } from "react";
import { Button, DataTable, TextStyle, Select } from "@shopify/polaris";

import constant from "lib/constant/constant";

import { withErrorBoundary } from "lib/hoc";

import { CurrencyTable } from "app/setup/modules/operator/features/currency/currencyStyle";

const { PRODUCT_TAG_TABLE_HEADING, PRODUCT_TAG_COLUMN } = constant;
let heading = PRODUCT_TAG_TABLE_HEADING;

const Table = (props) => {
  const {
    cms,
    currentUser,
    data,
    setData,
    vendors,
    productType,
    productCategory,
    setBannerStatus,
    setIsRepeat,
    setIsDisable,
  } = props;
  const { shop = "", accessKey = "", accessToken = "" } = currentUser;
  if (shop && accessKey && accessToken) {
    const collection = cms("section.tag.placeHolder.collection")
      .split(" ")
      .map((item) => item.substring(0, 1).toUpperCase() + item.substring(1))
      .join(" ");
    const category = cms("section.tag.placeHolder.category")
      .split(" ")
      .map((item) => item.substring(0, 1).toUpperCase() + item.substring(1))
      .join(" ");
    heading = PRODUCT_TAG_TABLE_HEADING.filter((item) => item !== collection);
    heading.push(category);
  }

  useEffect(() => {
    let isRepeat = false;
    const set = new Set(data.map(JSON.stringify));
    const hasDuplicates = set.size < data.length;
    if (hasDuplicates) {
      isRepeat = true;
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.tag.message.error.repeat"),
      });
    }
    setIsRepeat(isRepeat);
    if (!isRepeat) {
      setBannerStatus({
        title: "",
        status: "",
        isOpen: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const selectTag = (item) => {
    const { tag = "" } = item;
    return (
      <>
        <div className="flag">
          <TextStyle>{tag}</TextStyle>
        </div>
      </>
    );
  };
  const removeItem = (index) => {
    const arrRemove = [...data];
    arrRemove.splice(index, 1);
    setData([...arrRemove]);
    setIsDisable(false);
  };
  const handleVendorChange = (value, index) => {
    const arrVendor = [...data];
    arrVendor[index].vendor = value;
    setData(arrVendor);
    setIsDisable(false);
  };
  const handleTypeChange = (value, index) => {
    const arrType = [...data];
    if (value === constant.ALL) {
      arrType[index].type.isAll = true;
      arrType[index].type.value = "";
    } else {
      arrType[index].type.isAll = false;
      arrType[index].type.value = value;
    }
    setData(arrType);
    setIsDisable(false);
  };
  const handleCategoryChange = (value, index) => {
    const arrCategory = [...data];
    if (value === constant.ALL) {
      arrCategory[index].category.isAll = true;
      arrCategory[index].category.value = "";
    } else {
      arrCategory[index].category.isAll = false;
      arrCategory[index].category.value = value;
    }
    setData(arrCategory);
    setIsDisable(false);
  };
  const selectVendor = (item, index) => {
    const { vendor = "" } = item;
    return (
      <Select
        options={vendors}
        onChange={(value) => handleVendorChange(value, index)}
        value={vendor}
        placeholder={cms("section.tag.placeHolder.vendors")}
      />
    );
  };
  const selectType = (item, index) => {
    const {
      type: { isAll = "", value: typeValue = "" },
    } = item;
    return (
      <Select
        options={productType}
        onChange={(value) => handleTypeChange(value, index)}
        value={isAll ? constant.ALL : typeValue}
        placeholder={cms("section.tag.placeHolder.type")}
      />
    );
  };
  const selectCategory = (item, index) => {
    const {
      category: { isAll = "", value: categoryValue = "" },
    } = item;
    return (
      <div className="delete-row">
        <Select
          options={productCategory}
          onChange={(value) => handleCategoryChange(value, index)}
          value={isAll ? constant.ALL : categoryValue}
          placeholder={
            shop && accessKey && accessToken
              ? cms("section.tag.placeHolder.category")
              : cms("section.tag.placeHolder.collection")
          }
        />
        <Button id={`advanceDeleteButton${index}`} plain onClick={() => removeItem(index)}>
          <i className="far fa-trash fa-lg redColor" />
        </Button>
      </div>
    );
  };

  const getRows = () => {
    if (data && data.length) {
      return data.map((item, index) => {
        const tagRow = selectTag(item, index);
        const vendorRow = selectVendor(item, index);
        const typeRow = selectType(item, index);
        const categoryRow = selectCategory(item, index);

        return [[tagRow], [vendorRow], [typeRow], [categoryRow]];
      });
    }
    return [];
  };

  return (
    <CurrencyTable className="currency-table">
      <DataTable columnContentTypes={PRODUCT_TAG_COLUMN} headings={heading} hideScrollIndicator rows={getRows()} />
    </CurrencyTable>
  );
};
export default withErrorBoundary(Table);
