import { gql } from "apollo-boost";

const GET_PRODUCT_BY_IDS_SELLER = gql`
  query getProductbyIds($input: ProductByIds!) {
    getProductbyIds(input: $input) {
      status
      error
      data {
        ... on SellerProductSchemaResponse {
          productList {
            _id
            price
            quantity
            productId
            isService
            handle
            title
            inventoryManagement
            vendor
            status
            comparePrice
            variants {
              position
              price
              quantity
              option1Val
              option2Val
              option3Val
              comparePrice
              inventoryManagement
              inventoryQuantity
              vendorDiscount {
                type
                price
              }
              sellerDiscount {
                type
                price
              }
              markUp {
                type
                price
              }
              measurement {
                length {
                  value
                  unit
                }
                width {
                  value
                  unit
                }
                height {
                  value
                  unit
                }
              }
            }
            markUp {
              type
              price
            }
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
            sellerDiscount {
              price
              type
            }
            vendorDiscount {
              price
              type
            }
          }
        }
      }
    }
  }
`;
const GET_PRODUCT_BY_IDS = gql`
  query getProductbyIds($input: ProductByIds!) {
    getProductbyIds(input: $input) {
      status
      error
      data {
        ... on ProductSchemaResponse {
          productList {
            _id
            price
            quantity
            listingQuantity
            compaign
            isService
            productId
            handle
            title
            inventoryManagement
            vendor
            status
            comparePrice
            variants {
              position
              price
              quantity
              option1Val
              option2Val
              option3Val
              comparePrice
              inventoryManagement
              inventoryQuantity
              vendorDiscount {
                type
                price
              }
              sellerDiscount {
                type
                price
              }
              markUp {
                type
                price
              }
              measurement {
                length {
                  value
                  unit
                }
                width {
                  value
                  unit
                }
                height {
                  value
                  unit
                }
              }
            }
            markUp {
              type
              price
            }
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
            sellerDiscount {
              price
              type
            }
            vendorDiscount {
              price
              type
            }
          }
        }
      }
    }
  }
`;

export { GET_PRODUCT_BY_IDS_SELLER, GET_PRODUCT_BY_IDS };
