// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { Card, PageActions, RadioButton, Stack, TextContainer, TextField } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { IS_STORE_CONNECTED } from "app/productOld/apollo/mutations/connect";

import config from "configuration";
// import { SyncProduct } from "app/productOld/modules/provider/features/subFeatures";

// import components
import WixStoreConnect from "app/product/modules/provider/features/genric/wixConnect/wixConnect";
import WooCommerce from "app/product/modules/provider/features/genric/wooCommerceConnect/wooCommerceConnect";
import BigCommerce from "app/productLake/modules/provider/features/genric/bigCommerceConnect/bigCommerceConnect";
import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
// import ConnectedShop from "./subFeatureItems/connected";
import TermModal from "./subFeatureItems/term";
import Magento from "app/product/modules/provider/features/genric/magentoConnect/magentoConnect";
import PrestaShop from "app/product/modules/provider/features/genric/prestaShopConnect/prestaShopConnect";

// eslint-disable-next-line no-useless-escape
const subDomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;

const VendorConnectShopify = (props) => {
  const { history, currentUser: currentUserData, cms } = useContext(PrivateContext);
  const { search = "" } = history.location || {};
  const { store = "" } = baseHelper.getQueryParams(search);
  // const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { setBanner } = props;
  const [shop, setShop] = useState((currentUserData && currentUserData.shop) || "");
  const [isConnected, setIsConnected] = useState(!!(currentUserData && currentUserData.shop));
  const [isOpen, setIsOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [isStoreConnected, { loading }] = useMutation(IS_STORE_CONNECTED);
  const [radioButtonValue, setRedioButtonValue] = useState(store || constant.SHOPIFY);
  const { IS_STORE_CONNECTED: STORE_CONNECTED } = constant.gql;
  const { ecommercePlatform } = currentUserData || {};

  const isProductDiscovery = !!currentUserData?.isProductDiscovery;

  const onSubmit = async () => {
    const isValidSubDomain = subDomainRegex.test(shop) && baseHelper.isValidUrl(`https://${shop}.myshopify.com`);
    if (!isValidSubDomain) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.connect.message.error.invalidSubDomain"),
      });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isStoreConnected({
        variables: { input: { shop } },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECTED);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }
      const resData = baseHelper.getResponseData(response.data, STORE_CONNECTED);
      if (resData.supplier) {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${shop} ${cms("section.connect.message.error.alreadyConnected")}`,
        });
        return;
      }
      if (resData.associated) {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${cms("section.connect.message.error.cannotSync")} ${shop} ${cms(
            "section.connect.message.error.alreadyAssociated"
          )}`,
        });
        return;
      }
      setTimeout(() => {
        const clientId = config.vendorShopifyAPIKey;
        const redirectUri = baseHelper.getCurrentDomain(window.location, "providers/shopify-callback");
        const scope =
          "read_products, write_products, read_customers, write_customers, read_orders, " +
          "write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, " +
          "read_shipping, write_shipping, read_locations, write_order_edits, read_discounts, write_discounts, " +
          "write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders";
        const url =
          `https://${shop}.myshopify.com` +
          `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
        window.location.href = url;
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };
 /*
  const handelChange = (value) => {
    setShop(value);
  };
  const handleTermModal = (value) => {
    if (!shop) {
      setIsOpen(false);
      setErrorMessage(cms("section.connect.message.error.shopRequired"));
      return;
    }
    if (value === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };
*/
  const handelRadioButton = (value) => {
    setRedioButtonValue(value);
  };

  return (
    <>
      <TermModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={shop}
        cms={cms}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
      />
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          {ecommercePlatform !== constant.BIGCOMMERCE ? (
            <>
              <Stack>
                <RadioButton
                  label="Shopify"
                  id={constant.SHOPIFY}
                  checked={radioButtonValue === constant.SHOPIFY}
                  onChange={() => handelRadioButton(constant.SHOPIFY)}
                />
              </Stack>
              <Stack>
                <RadioButton
                  label="WooCommerce"
                  id={constant.WOOCOMMERCE}
                  checked={radioButtonValue === constant.WOOCOMMERCE}
                  onChange={() => handelRadioButton(constant.WOOCOMMERCE)}
                />
              </Stack>
              <Stack>
                <RadioButton
                  label="Wix"
                  id={constant.WIX}
                  checked={radioButtonValue === constant.WIX}
                  onChange={() => handelRadioButton(constant.WIX)}
                />
              </Stack>
              {isProductDiscovery && (
                <Stack>
                  <RadioButton
                    label="Magento"
                    id={constant.MAGENTO}
                    checked={radioButtonValue === constant.MAGENTO}
                    onChange={() => handelRadioButton(constant.MAGENTO)}
                  />
                </Stack>
              )}
              {isProductDiscovery && (
                <Stack>
                  <RadioButton
                    label="Presta Shop"
                    id={constant.PRESTASHOP}
                    checked={radioButtonValue === constant.PRESTASHOP}
                    onChange={() => handelRadioButton(constant.PRESTASHOP)}
                  />
                </Stack>
              )}
            </>
          ) : (
            <Stack>
              <RadioButton
                label="BigCommerce"
                id={constant.BIGCOMMERCE}
                checked={radioButtonValue === constant.BIGCOMMERCE}
                onChange={() => handelRadioButton(constant.BIGCOMMERCE)}
              />
            </Stack>
          )}
          <br />
          {radioButtonValue === constant.SHOPIFY && (
            // <TextField
            //   label={`${cms("section.connect.label.shop")}*`}
            //   error={errorMessage}
            //   id="shopInput"
            //   type={constant.TEXT}
            //   suffix=".myshopify.com"
            //   value={shop}
            //   onChange={(value) => handelChange(value)}
            //   onBlur={handleTermModal}
            //   disabled={isConnected}
            // />
            <VendorAppConnectShopify
              setBanner={setBanner}
              pageName={constant.SHOPIFY_CONNECT_RADIO}
              setModalBanner={setBanner}
            />
          )}
          {radioButtonValue === constant.WOOCOMMERCE && <WooCommerce setBanner={setBanner} />}
          {radioButtonValue === constant.WIX && <WixStoreConnect setBanner={setBanner} />}
          {radioButtonValue === constant.BIGCOMMERCE && <BigCommerce setBanner={setBanner} />}
          {radioButtonValue === constant.MAGENTO && <Magento setBanner={setBanner} />}
          {radioButtonValue === constant.PRESTASHOP && <PrestaShop setBanner={setBanner} />}
          {/* {currentUserData.shop && isConnected && radioButtonValue === constant.SHOPIFY && (
            <>
              <br />
              <Stack>
                <Stack.Item fill>
                  <ConnectedShop
                    cms={cms}
                    currentUserData={currentUserData}
                    setBanner={setBanner}
                    setIsConnected={setIsConnected}
                    setShop={setShop}
                  />
                </Stack.Item>
                <Stack.Item>
                  <SyncProduct history={history} setBanner={setBanner} />
                </Stack.Item>
              </Stack>
            </>
          )}
          {!(currentUserData.shop && isConnected) && radioButtonValue === constant.SHOPIFY && (
            <div className="manageCustomerButton">
              <br />
              <PageActions
                primaryAction={{
                  id: "connectShop",
                  content: cms("section.connect.button.connect"),
                  onAction: () => handleTermModal(constant.CONNECT),
                  disabled: isConnected,
                }}
                secondaryActions={[
                  {
                    id: "cancelAction",
                    content: cms("section.connect.button.cancel"),
                    onAction: () => (isOnboarding && onPrevious()) || history.push("/setting"),
                  },
                ]}
              />
            </div>
          )} */}
        </Card.Section>
      </Card>
    </>
  );
};

VendorConnectShopify.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectShopify.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorConnectShopify), { feature: constant.PRODUCT_FROM_SHOPIFY });
