import React, { useState, useContext, useEffect } from "react";
import { Card, Layout, Button } from "@shopify/polaris";
import { useQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// layout component
import { SingleColumnLayout } from "layout/private/components";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// lib component
import { ResourceList, Banner, Toast } from "lib/components";

// import from apollo
import { GET_SELLER_PLAN } from "app/setup/apollo";

// subFeatures
import SellerPlanList from "./subFeatures/sellerPlanList";

const OperatorSellerPlanList = () => {
  const { history, cms, currentUser } = useContext(PrivateContext);
  const {
    chargebee = {},
    isChargebee = false,
    isStripeSubscription = false,
    stripeSubscription = {}
  } = currentUser;

  const isChargebeeEnabled = !!(isChargebee && chargebee);
  const isStripe = !!(isStripeSubscription && stripeSubscription && stripeSubscription.apiKey);

  const isSubscription = !!(isStripe || isChargebeeEnabled);

  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [messageToast, setMessageToast] = useState("");
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const [planList, setPlanList] = useState([]);
  const [totalPlan, setTotalPlan] = useState(0);

  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || constant.CREATED_AT,
    sort_order: prevFilter.sort_order || constant.DESC,
  });

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const inputData = {
    search: dataToFetch.search,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  // useQuery Calls to fetch data.

  const { error: errorPlanList, loading: loadingPlanList, data: dataPlanList } = useQuery(GET_SELLER_PLAN, {
    variables: {
      input: inputData,
    },
  });

  const planListResponseData = baseHelper.getResponseData(dataPlanList, constant.gql.GET_SELLER_PLAN_LIST);
  const planListErrorData = baseHelper.getResponseError(dataPlanList, constant.gql.GET_SELLER_PLAN_LIST);

  useEffect(() => {
    if (planListErrorData) {
      setBanner({ status: constant.CRITICAL, title: planListErrorData, isOpen: true });
    }
    if (errorPlanList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorPlanList), isOpen: true });
    }
  }, [planListErrorData, errorPlanList]);

  useEffect(() => {
    if (!isSubscription) {
      const bannerValue = {
        status: constant.INFO,
        title: cms("section.banner.error.title.enable"),
        onDismiss: () => setBanner({ isOpen: false, title: "", status: "" }),
        action: {
          content: cms("section.banner.button.enable"),
          onAction: () => history.push("/setting/provider/subscription"),
        },
      };
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
    }
  }, [isSubscription, cms, history]);

  useEffect(() => {
    if (planListResponseData) {
      const { planList: planListData = [], count: totalCount = 0 } = planListResponseData;
      setPlanList(planListData);
      setTotalPlan(totalCount);
    }
  }, [planListResponseData]);

  useEffect(() => {
    setTimeout(() => {
      setMessageToast("");
    }, 5000);
  }, [messageToast]);

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };

  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const renderItem = (item, _, index) => {
    if (isSubscription) {
      return <SellerPlanList item={item} keyIndex={index} setMessageToast={setMessageToast} isStripe={isStripe} />;
    }
    return null;
  };

  const getComponent = () => {
    return (
      <>
        {isSubscription && banner.isOpen ? (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
            <br />
          </>
        ) : (
          banner.isOpen && (
            <>
              <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} action={banner.action} />
            </>
          )
        )}
        {isSubscription && (
          <Layout>
            {isChargebeeEnabled && (<Layout.Section>
              <Button primary onClick={() => history.push("/setting/plan/add")}>
                {cms("section.button.add")}
              </Button>
              </Layout.Section>
            )}

            {isStripe && (<Layout.Section>
              <Button info onClick={() => history.push("/setting/plan/preview")}>
                Preview
              </Button>
              </Layout.Section>
            )}
            <Layout.Section>
              <Card>
                <ResourceList
                  resourceName={{
                    singular: cms("section.label.plan"),
                    plural: cms("section.label.plans"),
                  }}
                  items={planList && planList.length > 0 ? planList : []}
                  idForItem={(plan) => {
                    const { _id: id = "" } = plan || {};
                    return id;
                  }}
                  renderItem={renderItem}
                  sortValue={sortValue}
                  onSortChange={sortFilter}
                  loading={loadingPlanList}
                  onQueryChange={handleQueryValueChange}
                  onQueryClear={handleQueryValueRemove}
                  queryValue={queryValue}
                  page={currentPage}
                  count={Number(totalPlan)}
                  perPage={selectedLimit}
                  setPage={handlePerPage}
                  setPerPage={handleSelectLimit}
                />
              </Card>
            </Layout.Section>
            <Toast message={messageToast} timeout={5000} />
          </Layout>
        )}
      </>
    );
  };
  return <SingleColumnLayout primary={getComponent()} />;
};
export default OperatorSellerPlanList;
