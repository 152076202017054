import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import {
  Card,
  Caption,
  Form,
  FormLayout,
  TextField,
  Layout,
  Select,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

import constant from "lib/constant/constant";
import { CardFooter, Toast } from "lib/components";
import { UPDATE_SHIPPING_TAX_SETTING } from "app/setup/apollo/mutations";

const ShippingTax = (props) => {
  const { setBanner, selected, setSelected, shippingRate, setShippingRate, refetch, updatedDate } = props;
  const [error, setError] = useState();
  const [message, setMessage] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [updateShippingTaxSetting, { loading: taxSettingLoading }] = useMutation(UPDATE_SHIPPING_TAX_SETTING);
  const { cms } = useContext(PrivateContext);
  const {
    EXCLUSIVE,
    INCLUSIVE,
    gql,
    value: { MAX_PERCENTAGE, ZERO },
  } = constant;
  const returnOptions = [
    {
      label: cms("label.inclusive"),
      value: INCLUSIVE,
    },
    {
      label: cms("label.exclusive"),
      value: EXCLUSIVE,
    },
  ];

  const acceptOnlyValidInput = (val, prevValue, max) => {
    if (parseFloat(val) < max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleValidation = () => {
    if (!shippingRate && selected === EXCLUSIVE) {
      setError(cms("message.error.field"));
      setSubmitEnabled(false);
      return;
    }
    setError("");
  };

  const handleSelectChange = (value) => {
    setSelected(value);
    if (value === INCLUSIVE) {
      setError("");
      setShippingRate("");
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  };

  const handleSubmit = async () => {
    if (!submitEnabled || error) {
      return;
    }
    setSubmitEnabled(false);
    updateShippingTaxSetting({
      variables: {
        input: {
          shipping: {
            isTaxInclusive: selected === INCLUSIVE,
            value: parseFloat(shippingRate),
          },
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_SHIPPING_TAX_SETTING);
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_SHIPPING_TAX_SETTING);
          setBanner({ status: constant.CRITICAL, title: errorResponse, isOpen: true });
        }
        setMessage(cms("message.success.shipping"));
        refetch();
      })
      .catch((exception) => {
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  return (
    <>
      <Layout.AnnotatedSection title={cms("section.shipping.title")} description={cms("section.shipping.description")}>
        <Card
          title={[
            cms("section.shipping.title"),
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(updatedDate)}`}</Caption>
            </TextStyle>,
          ]}
          sectioned
        >
          <Form onSubmit={() => handleSubmit()}>
            <FormLayout>
              <TextContainer>{cms("section.shipping.cardDescription")}</TextContainer>
              <Stack wrap={false}>
                <Select
                  label={cms("section.shipping.status")}
                  options={returnOptions}
                  id="isPriceTaxInclusive"
                  onChange={(value) => handleSelectChange(value)}
                  value={selected}
                />
                {selected === EXCLUSIVE && (
                  <TextField
                    label={cms("section.shipping.taxTitle")}
                    id="shippingRate"
                    placeholder={ZERO}
                    suffix={<div className="suffixColor">%</div>}
                    min={ZERO}
                    value={shippingRate.toString()}
                    onChange={(value) => {
                      setShippingRate(acceptOnlyValidInput(value, shippingRate, MAX_PERCENTAGE));
                      setSubmitEnabled(true);
                    }}
                    onBlur={(value) => handleValidation(value)}
                    disabled={selected === INCLUSIVE || !selected}
                    error={error}
                  />
                )}
              </Stack>
            </FormLayout>
            <CardFooter updatedDate={updatedDate} disabled={!submitEnabled} loading={taxSettingLoading} />
          </Form>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ShippingTax), {
  feature: constant.TAX_SHIPPING_SETTING,
});
