import { gql } from "apollo-boost";

const GET_CREDIT_NOTE_SETTING = gql`
  query GetCreditNoteSetting($input: VendorId) {
    getCreditNoteSetting(input: $input) {
      data {
        creditNote {
          isAllVendor
          isEnabled
          selectedVendor
          updatedAt
        }
        creditBalance
      }
      status
      error
    }
  }
`;

export default GET_CREDIT_NOTE_SETTING;
