import { gql } from "apollo-boost";

const TOGGLE_DOMAIN = gql`
  mutation toggleDomain($input: ToggleDomain) {
    toggleDomain(input: $input) {
      data
      status
      error
    }
  }
`;

export default TOGGLE_DOMAIN;
