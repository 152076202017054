import { gql } from "apollo-boost";

const ADD_PRODUCT_ATTACHMENT = gql`
  mutation addProductAttachment($input: AddProductAttachment) {
    addProductAttachment(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_FULFILLMENT = gql`
  mutation addProductFulfillment($input: AddProductFulfillment) {
    addProductFulfillment(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_IMAGE = gql`
  mutation addProductImage($input: AddProductImage) {
    addProductImage(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_INFORMATION = gql`
  mutation addProductInformation($input: AddProduct) {
    addProductInformation(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_INVENTORY = gql`
  mutation addProductInventory($input: AddProductInventory) {
    addProductInventory(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_METAFIELD = gql`
  mutation addProductMetafield($input: AddProductMetafield) {
    addProductMetafield(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_PRODUCT_VARIANT = gql`
  mutation addProductVariant($input: AddProductVariant) {
    addProductVariant(input: $input) {
      status
      data
      error
    }
  }
`;

export {
  ADD_PRODUCT_ATTACHMENT,
  ADD_PRODUCT_FULFILLMENT,
  ADD_PRODUCT_IMAGE,
  ADD_PRODUCT_INFORMATION,
  ADD_PRODUCT_INVENTORY,
  ADD_PRODUCT_METAFIELD,
  ADD_PRODUCT_VARIANT,
};
