// import packages
import React, { useContext } from "react";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import helpers
import { storageHelper } from "lib/helpers";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { SkeletonAnnotated } from "lib/components";
import { OperatorTransactionFeesSetting } from "app/payments/modules/operator/features";

const { userKey } = constant;
const { operator } = userKey;

const TransactionFeesSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorTransactionFeesSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const TransactionFeesSettingComponent = getComponent(currentUserRole);
  return TransactionFeesSettingComponent;
};

export default withFeature(withErrorBoundary(TransactionFeesSetting), { feature: constant.SCHEDULE_PAYMENT_SETTING });
