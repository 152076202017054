import styled from "styled-components";

const CustomDaysWrapper = styled.div`
  display: flex;
  .select-time {
    flex: 1 1 auto;
    & + .select-time {
      margin-left: 20px;
    }
    p {
      margin-bottom: 5px;
    }
  }
  select {
    padding: 10px;
    width: 100%;
    background: transparent;
    border: 1px solid #c9cccf;
    border-radius: 3px;
  }
`;
export default CustomDaysWrapper;
