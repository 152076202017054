import React, { useCallback, useContext, useEffect, useState } from "react";
import { Banner, Caption, Card, FormLayout, PageActions, Select, Stack, TextField, TextStyle } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import mutation
import { UPDATE_PRODUCT_LAKE_INFORMATION } from "app/productLake/apollo/mutations";

// import queries
// import { GET_CURRENCY_RATE } from "app/v1/products/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";

const Information = () => {
  const { handleTabChange, isVariant, setBanner, setDescription, setTitle, productValue, refetchProduct } = useContext(
    ProductContext
  );
  const { cms, currentUser, match } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;
  const { BIGCOMMERCE, CRITICAL, gql } = constant;
  const [disabledButton, setDisabledButton] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [sheetActive, setSheetActive] = useState(false);
  const [message, setMessage] = useState("");
  const [rate] = useState([]);
  const [value, setValue] = useState(productValue);
  const [basePricing] = useState();
  const [currencyPrice, setCurrencyPrice] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  setDescription(cms("label.vendorInformation"));
  setTitle(cms("label.title.information"));

  useEffect(() => {
    setValue(productValue);
  }, [productValue]);

  // const { loading: currencyRateLoading, data: currencyRateData } = useQuery(GET_CURRENCY_RATE);

  const [updateProduct, { loading: editProductLoading }] = useMutation(UPDATE_PRODUCT_LAKE_INFORMATION);

  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999 },
  } = constant;

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (val >= min && val <= max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.TITLE, constant.DESCRIPTION];
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  const handlePriceChange = useCallback(
    (fieldName, fieldValue) => {
      setDisabledButton(false);
      setValue({
        ...value,
        priceId: {
          ...value.priceId,
          [fieldName]: fieldValue || parseFloat(0),
        },
      });
    },
    [value]
  );

  // const handleCurrencyPrice = (field, newValue) => {
  //   setBasePricing((prevState) => ({
  //     ...prevState,
  //     [field]: newValue,
  //   }));
  //   if (currencyPrice) {
  //     setValue({
  //       ...value,
  //       [field]: parseFloat(((1 / currencyPrice) * newValue).toFixed(2)),
  //     });
  //   }
  // };

  useEffect(() => {
    setValue(productValue);
  }, [productValue]);

  useEffect(() => {
    if (rate && rate.length && basePricing && basePricing.currencyCode) {
      const currencyData =
        rate && rate.length && rate.find((element) => basePricing.currencyCode === element.currencyCode);
      if (currencyData) {
        const { currencySymbol: symbol, price } = currencyData;
        setCurrencySymbol(symbol);
        setCurrencyPrice(price);
      }
    }
  }, [basePricing, rate]);

  // const currencyRateError = baseHelper.getResponseError(currencyRateData, gql.GET_CURRENCY_RATE);

  // useEffect(() => {
  //   if (currencyRateError) {
  //     return;
  //   }

  //   const currencyRateSetting = baseHelper.getResponseData(currencyRateData, constant.gql.GET_CURRENCY_RATE) || {};
  //   const { currency = {} } = currencyRateSetting || {};
  //   const { rate: rateData = [] } = currency || {};
  //   setRate(rateData);
  // }, [currencyRateError, currencyRateData]);

  // if (currencyRateLoading) {
  //   return <SkeletonCard />;
  // }

  const primaryAction = {
    content: cms("button.save"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const renderPricingComponent = () => {
  //   if (!basePricing || !basePricing.currencyCode) return null;
  //   const { price = 0, comparePrice = 0, currencyCode } = basePricing || {};
  //   return (
  //     <FormLayout.Group>
  //       <TextField
  //         id="price"
  //         label={cms("label.price")}
  //         min={0}
  //         value={(price && price.toString()) || ""}
  //         placeholder={constant.ZERO_PRICE}
  //         prefix={currencySymbol}
  //         suffix={currencyCode}
  //         disabled={!currencyPrice}
  //         onChange={(val) => {
  //           handleCurrencyPrice(constant.PRICE, acceptOnlyValidInput(val, price), currencyCode);
  //           setDisabledButton(false);
  //         }}
  //       />
  //       <TextField
  //         id="comparePrice"
  //         label={cms("label.comparePrice")}
  //         min={0}
  //         placeholder={constant.ZERO_PRICE}
  //         prefix={currencySymbol}
  //         suffix={currencyCode}
  //         disabled={!currencyPrice}
  //         value={(comparePrice && comparePrice.toString()) || ""}
  //         onChange={(val) => {
  //           handleCurrencyPrice(constant.COMPARE_PRICE, acceptOnlyValidInput(val, comparePrice), currencyCode);
  //           setDisabledButton(false);
  //         }}
  //       />
  //     </FormLayout.Group>
  //   );
  // };

  const onFormSubmit = async () => {
    const { priceId } = value;
    const { price, costPrice, regularPrice, salePrice } = priceId || {};
    const priceValue = parseFloat(price);
    const salePriceValue = parseFloat(salePrice);
    const costPriceValue = parseFloat(costPrice);
    const regularPriceValue = parseFloat(regularPrice);

    let productPrice = {
      regularPrice: (regularPriceValue && regularPriceValue.toString()) || "",
      salePrice: (salePriceValue && salePriceValue.toString()) || "",
    };
    if (baseHelper.isBigCommerceUser(currentUser)) {
      productPrice = {
        price: (priceValue && priceValue.toString()) || "",
        costPrice: (costPriceValue && costPriceValue.toString()) || "",
      };
    }
    const formValues = {
      name: value.name,
      description: value.description,
      price: productPrice,
      type: value.type,
      productId: match.params.id,
    };

    if (value.type === constant.SIMPLE && isVariant) {
      setBanner({ isOpen: true, status: CRITICAL, title: cms("message.error.type") });
      return false;
    }

    await updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_LAKE_INFORMATION);
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_LAKE_INFORMATION);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.information"));
          refetchProduct();
          setBanner({ isOpen: false, status: "", title: "" });
          setTimeout(() => {
            handleTabChange(1);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
    return true;
  };

  let typeOptions = [
    { label: "Simple", value: "simple" },
    // { label: "Grouped", value: "grouped" },
    // { label: "External", value: "external" },
    { label: "Variable", value: "variable" },
  ];

  if (currentUser.ecommercePlatform === BIGCOMMERCE) {
    typeOptions = [
      { label: "Physical", value: "physical" },
      { label: "Digital", value: "digital" },
    ];
  }

  const { priceId } = value || {};

  const { regularPrice, price, costPrice, salePrice } = priceId || {};

  return (
    <>
      <Card
        title={[
          cms("label.productInformation"),
          value && value.updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
        id="productInformation"
      >
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              required="true"
              onChange={(val) => {
                handleChange(constant.NAME, val);
                setDisabledButton(false);
              }}
              error=""
              value={value.name || ""}
              onBlur={() => value && value.name && handleChange(constant.name, value.name.trim())}
            />
            {cms("label.description")}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={value.description}
              onEditorChange={(val) => handleChange(constant.DESCRIPTION, val)}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout>
              {basePricing && basePricing.currencyCode && currencyPrice ? (
                <>
                  <br />
                  <Banner status={constant.INFO} isOpen>
                    {`${cms("label.rate")} ${moneyFormat}1 = ${currencySymbol}${currencyPrice}`}
                  </Banner>
                </>
              ) : null}
              <FormLayout.Group>
                <TextField
                  id="price"
                  label={[
                    cms("label.price"),
                    // <span className="currency-badge">
                    //   <Badge status="info">{cms("label.currency")}</Badge>
                    // </span>,
                  ]}
                  min={0}
                  value={
                    baseHelper.isBigCommerceUser(currentUser)
                      ? price && price.toString()
                      : (regularPrice && regularPrice.toString()) || ""
                  }
                  placeholder={constant.ZERO_PRICE}
                  prefix={moneyFormat}
                  disabled={currencyPrice}
                  onChange={(val) => {
                    handlePriceChange(
                      baseHelper.isBigCommerceUser(currentUser) ? constant.PRICE : constant.REGULAR_PRICE,
                      acceptOnlyValidInput(val, baseHelper.isBigCommerceUser(currentUser) ? price : regularPrice)
                    );
                    setDisabledButton(false);
                  }}
                />
                <TextField
                  id="comparePrice"
                  label={cms("label.comparePrice")}
                  min={0}
                  placeholder={constant.ZERO_PRICE}
                  prefix={moneyFormat}
                  value={
                    baseHelper.isBigCommerceUser(currentUser)
                      ? costPrice && costPrice.toString()
                      : (salePrice && salePrice.toString()) || ""
                  }
                  disabled={currencyPrice}
                  onChange={(val) => {
                    handlePriceChange(
                      baseHelper.isBigCommerceUser(currentUser) ? constant.COST_PRICE : constant.SALE_PRICE,
                      acceptOnlyValidInput(val, baseHelper.isBigCommerceUser(currentUser) ? costPrice : salePrice)
                    );
                    setDisabledButton(false);
                  }}
                />
              </FormLayout.Group>
              {/* {basePricing && basePricing.currencyCode && currencyPrice ? renderPricingComponent() : null} */}
            </FormLayout>
            {/* {isHideType ? ( */}
            <Stack vertical>
              {/* {productTypeOptions && productTypeOptions.length > 0 && ( */}
              <Select
                label={cms("label.type")}
                placeholder={cms("placeholder.selectProductType")}
                options={typeOptions}
                value={value.type}
                onChange={(val) => handleChange(constant.TYPE, val)}
              />
              {/* )} */}
            </Stack>
            <br />
          </FormLayout>
          <div className="fulfillment_button">
            <PageActions
              primaryAction={{
                content: cms("common.button.update"),
                onAction: () => onFormSubmit(),
                loading: editProductLoading,
                disabled: !(value && value.name) || disabledButton,
              }}
            />
          </div>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};
export default Information;
