import { gql } from "apollo-boost";

// for operator
const UPDATE_MANAGE_PRICE_SETTING = gql`
  mutation updateManagePriceSetting($input: ManagePriceSetting) {
    updateManagePriceSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_MANAGE_PRICE_SETTING;
