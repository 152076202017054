import React, { useState, useContext, useEffect } from "react";
import { Card, Checkbox, FormLayout, Layout, Select, PageActions, TextContainer, TextField } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PropType from "prop-types";
import { getNames, getCode } from "country-list";
import { State } from "country-state-city";

import { CREATE_VENDOR } from "app/vendors/apollo/mutations/createVendor";
import { GET_SELLER_FULFILLMENT, GET_VENDOR_PROFILE_SETTING } from "app/vendors/apollo/queries";
import { GET_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/queries";
import { PrivateContext, OnboardingContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import formConfig from "./formConfig";
import validate from "./yup";

const FormCreate = (props) => {
  const {
    // learnMore
    setBanner,
  } = props;
  const { gql, BRAND_HANDLE, BRAND_NAME, PROVINCE_CODE, VENDOR_TAGGED } = constant;
  const [errorMessage, setErrorMessage] = useState({});
  const [createVendorProfile, { loading }] = useMutation(CREATE_VENDOR);
  const { history, cms, currentUser = {} } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);
  const [brandNameData, setBrandNameData] = useState();
  const [brandHandleData, setBrandHandleData] = useState();
  const [firstNameData, setFirstNameData] = useState();
  const [lastNameData, setLastNameData] = useState();
  const [emailData, setEmailData] = useState();
  const [cityData, setCityData] = useState();
  const [countryData, setCountryData] = useState();
  const [postalCodeData, setPostalCodaData] = useState();
  const [phoneNumberData, setPhoneNumberData] = useState();
  const [streetAddressData, setStreetAddressData] = useState();
  const { textFields, brandFields } = formConfig(cms);
  const [values, setValues] = useState({
    brandHandle: "",
    brandName: "",
    city: "",
    country: "",
    email: "",
    firstName: "",
    isOtp: true,
    isReadOnly: false,
    isVendor: true,
    isVendorTagged: false,
    isVerified: true,
    lastName: "",
    password: baseHelper.generateRandomPassword(),
    phoneNumber: "",
    postalCode: "",
    provinceCode: "",
    streetAddress: "",
  });
  const [vendorFormSetting, setVendorFormSetting] = useState({});

  const [isShowProvinceCode, setShowProvinceCode] = useState(false);

  const userRole = (currentUser && currentUser.roles && currentUser.roles.name) || "";

  const { loading: vendorFormLoading, data: vendorFormData, error: vendorFormError } = useQuery(
    GET_VENDOR_PROFILE_SETTING
  );
  const { loading: loadingEditVendorForm, data: dataVendorForm } = useQuery(GET_VENDOR_FORM_LABEL);

  useEffect(() => {
    if (dataVendorForm) {
      const vendorLabelData = baseHelper.getResponseData(dataVendorForm, gql.GET_VENDOR_FORM_LABEL);
      if (vendorLabelData) {
        setBrandNameData(vendorLabelData.brandName);
        setBrandHandleData(vendorLabelData.brandHandle);
        setFirstNameData(vendorLabelData.firstName);
        setLastNameData(vendorLabelData.lastName);
        setEmailData(vendorLabelData.email);
        setCityData(vendorLabelData.city);
        setCountryData(vendorLabelData.country);
        setPostalCodaData(vendorLabelData.postalCode);
        setPhoneNumberData(vendorLabelData.phoneNumber);
        setStreetAddressData(vendorLabelData.streetAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorForm]);

  useEffect(() => {
    if (vendorFormError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(vendorFormError) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorFormError]);

  useEffect(() => {
    if (!vendorFormData) {
      return;
    }

    const responseData = baseHelper.getResponseData(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
    const responseError = baseHelper.getResponseError(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { vendorForm = {} } = responseData || {};
    setVendorFormSetting(vendorForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorFormData, gql.FETCH_VENDOR_FORM_SETTING]);

  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError } = useQuery(
    GET_SELLER_FULFILLMENT
  );

  useEffect(() => {
    if (fulfillmentError) {
      setBanner({
        title: fulfillmentError,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    setShowProvinceCode(showProvinceCode);
  }, [fulfillmentData, fulfillmentError, setBanner]);

  const handleBrandChange = (value) => {
    const manipulatedBrandHandleValue = baseHelper.generateBrandHandle(value);
    setValues((prevState) => ({
      ...prevState,
      [BRAND_NAME]: value,
      [BRAND_HANDLE]: manipulatedBrandHandleValue,
    }));
  };

  const handleChangeEvent = (field, value) => {
    if (field === constant.PHONE_NUMBER && !baseHelper.validateWholeNumber(value) && value) {
      return;
    }

    if (field === BRAND_NAME) {
      handleBrandChange(value);
    }

    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isAnyError = () => Object.values(errorMessage).some((error) => error);
  const isAllValuesFilled = () =>
    Object.keys(values).every((key) => {
      if (key === PROVINCE_CODE) {
        return true;
      }
      return typeof values[key] === "boolean" ? true : values[key];
    });

  const toggleBanner = () =>
    setBanner({
      action: null,
      isOpen: false,
      status: "",
      title: "",
      onDismiss: null,
    });

  const onSubmit = async () => {
    try {
      const res = await createVendorProfile({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            brand: {
              slug: values.brandHandle,
            },
            brandName: values.brandName,
            address: values.streetAddress,
            city: values.city,
            country: values.country,
            pinCode: values.postalCode,
            provinceCode: values.provinceCode || "",
            password: values.password,
            isOtp: values.isOtp,
            isVerified: values.isVerified,
            isOverseas: values.isVendorTagged,
            isReadOnly: values.isReadOnly,
            isVendor: values.isVendor,
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.CREATE_VENDOR);
      let bannerValue = {
        status: constant.SUCCESS,
        title: cms("message.success"),
        children: ecommercePlatform
          ? cms("message.pushToEcommerce", { item: baseHelper.ucFirst(ecommercePlatform) })
          : cms("message.shopifyPushMessage"),
        onDismiss: toggleBanner,
        action: {
          id: "viewProviders",
          content: cms("message.successAction"),
          onAction: () => history.push("/providers"),
        },
      };
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.CREATE_VENDOR);
        bannerValue = { status: constant.CRITICAL, title: errorResponse };
      }
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
      if (isOnboarding && responseData) {
        onNext();
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const cmsError = {
    brandHandle: {
      required: cms("message.error.brandHandle.required"),
      regx: cms("message.error.brandHandle.match"),
    },
  };

  const handleBrandValidation = async (value) => {
    const { brandHandle } = values;
    const brandNameError = await validate({ field: BRAND_NAME, value });
    const brandHandleError = await validate({
      field: BRAND_HANDLE,
      value: brandHandle,
      errorMessage: cmsError,
    });
    setErrorMessage((prevError) => ({
      ...prevError,
      [BRAND_NAME]: brandNameError,
      [BRAND_HANDLE]: brandHandleError,
    }));
  };

  const handleValidation = async (field, value, label) => {
    if (field === BRAND_NAME) {
      handleBrandValidation(value);
    }
    let schema = {};
    const { provinceCode: { isRequired: provinceCodeRequired = false } = {}, postalCode: { min = 2 } = {} } =
      vendorFormSetting || {};
    if (field === constant.PROVINCE_CODE && !provinceCodeRequired) {
      return;
    }
    if (field === constant.POSTAL_CODE) {
      schema = { min };
    }
    const error = await validate(field, value, cms, schema, label);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const getLabelData = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  const getPlaceholderText = (value) => {
    if (value && value.label) {
      return value.label;
    }
    return "";
  };

  const getFields = (text) => {
    const newTextField = {};
    newTextField[constant.FIRST_NAME] = firstNameData;
    newTextField[constant.LAST_NAME] = lastNameData;
    newTextField[constant.EMAIL] = emailData;
    newTextField[constant.CITY] = cityData;
    newTextField[constant.COUNTRY] = countryData;
    newTextField[constant.POSTAL_CODE] = postalCodeData;
    newTextField[constant.PHONE_NUMBER] = phoneNumberData;
    newTextField[constant.STREET_ADDRESS] = streetAddressData;

    const stateList = State.getStatesOfCountry(getCode(values.country) || "").map((item) => ({
      label: item.name,
      value: item.isoCode,
    }));
    return (
      text
        // .filter((item) => (item.key === constant.PROVINCE_CODE ? isShowProvinceCode : true))
        .map((textField) =>
          ![constant.COUNTRY, constant.PROVINCE_CODE].includes(textField.key) ? (
            <TextField
              label={`${getLabelData(newTextField[textField.key]) || textField.label}*`}
              placeholder={getPlaceholderText(newTextField[textField.key]) || textField.placeholder}
              key={textField.key}
              id={textField.key}
              value={values[textField.key]}
              onChange={(value) => handleChangeEvent(textField.key, value)}
              onBlur={() =>
                handleValidation(
                  textField.key,
                  values[textField.key],
                  getLabelData(newTextField[textField.key]) || textField.label
                )
              }
              error={errorMessage && errorMessage[textField.key]}
            />
          ) : (
            <Select
              label={`${getLabelData(newTextField[textField.key]) || textField.label}*`}
              value={values[textField.key]}
              options={textField.key === constant.COUNTRY ? getNames().sort() : stateList}
              placeholder={getPlaceholderText(newTextField[textField.key]) || textField.placeholder}
              onChange={(value) => handleChangeEvent(textField.key, value)}
            />
          )
        )
    );
  };

  if (vendorFormLoading || fulfillmentLoading || loadingEditVendorForm) {
    return <SkeletonAnnotated />;
  }

  return (
    <div>
      <Layout.AnnotatedSection
        title={cms("section.createForm.title")}
        description={cms("section.createForm.description")}
      >
        <Card
          title={cms("section.addVendorMethod.brandInformation.title")}
          // actions={[
          //   {
          //     content: cms("section.addVendorMethod.brandInformation.learnMore"),
          //     onAction: () =>
          //       learnMore(
          //         cms("section.addVendorMethod.brandInformation.title"),
          //         cms("section.addVendorMethod.brandInformation.todo")
          //       ),
          //   },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.addVendorMethod.brandInformation.description")}</TextContainer>
            <br />
            <FormLayout>
              {brandFields.map((brandField) => {
                const newBrandField = {};
                newBrandField[constant.BRAND_NAME] = brandNameData;
                newBrandField[constant.BRAND_HANDLE] = brandHandleData;
                return (
                  <TextField
                    label={`${getLabelData(newBrandField[brandField.key]) || brandField.label}*`}
                    placeholder={getPlaceholderText(newBrandField[brandField.key]) || brandField.placeholder}
                    key={brandField.key}
                    maxLength={255}
                    id={brandField.key}
                    value={values[brandField.key]}
                    onChange={(value) => handleChangeEvent(brandField.key, value)}
                    onBlur={() =>
                      handleValidation(
                        brandField.key,
                        values[brandField.key],
                        getLabelData(newBrandField[brandField.key]) || brandField.label
                      )
                    }
                    error={errorMessage && errorMessage[brandField.key]}
                    helpText={brandField.helpText}
                  />
                );
              })}
            </FormLayout>
          </Card.Section>
        </Card>

        <Card
          title={cms("section.addVendorMethod.vendorDetails.title")}
          // actions={[
          //   {
          //     content: cms("section.addVendorMethod.vendorDetails.learnMore"),
          //     onAction: () =>
          //       learnMore(
          //         cms("section.addVendorMethod.vendorDetails.title"),
          //         cms("section.addVendorMethod.vendorDetails.todo")
          //       ),
          //   },
          // ]}
        >
          <Card.Section>
            <FormLayout>{getFields(textFields)}</FormLayout>
          </Card.Section>
        </Card>

        <Card title={cms("section.addVendorMethod.vendorVerification.title")}>
          <Card.Section>
            <TextContainer>
              <p>{cms("section.addVendorMethod.vendorVerification.description")}</p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.autoVerifyVendor")}
                checked={values.isVerified}
                onChange={(value) => handleChangeEvent("isVerified", value)}
                id="autoVerifyCheckBox"
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {!ecommercePlatform && userRole === constant.SELLER && (
          <Card title={cms("section.addVendorMethod.overseas.title")}>
            <Card.Section>
              <TextContainer>
                <p>{cms("section.addVendorMethod.overseas.description")}</p>
              </TextContainer>
              <br />
              <FormLayout>
                <Checkbox
                  label={cms("common.label.overseas")}
                  checked={values.isVendorTagged}
                  onChange={(value) => handleChangeEvent(VENDOR_TAGGED, value)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        )}
        <Card title={cms("section.addVendorMethod.vendorAccessPermission.title")}>
          <Card.Section>
            <TextContainer>
              <p>{cms("section.addVendorMethod.vendorAccessPermission.description.para1")}</p>
              <p>{cms("section.addVendorMethod.vendorAccessPermission.description.para2")}</p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.isReadOnlyVendor")}
                checked={values.isReadOnly}
                onChange={(value) => handleChangeEvent("isReadOnly", value)}
                id="readOnlyCheckbox"
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => onSubmit(),
            disabled: !isAllValuesFilled() || isAnyError(),
            loading,
          }}
          secondaryActions={[
            {
              content: isOnboarding ? cms("common.button.previous") : cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </div>
  );
};
FormCreate.propTypes = {
  setBanner: PropType.func.isRequired,
  // learnMore: PropType.func.isRequired,
};

export default FormCreate;
