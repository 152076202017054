import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { TextField, Select, Checkbox, Modal, Heading, Caption, Stack, Badge } from "@shopify/polaris";

// import query
import { GET_CREDIT_NOTE_SETTING } from "app/setup/apollo";

// import mutation
import { LINE_RETURN } from "app/orders/apollo/mutations";

// import from lib
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper, statusHelper } from "lib/helpers";

// import style
import { FieldSetQty } from "app/orders/modules/provider/features/view/modal/modalStyle";

// import banner
import { Banner } from "lib/components";

// import yup
import validate from "app/orders/modules/provider/features/lineReturn/yup";

// import props
import providerLineReturnProps from "./props";

const ProviderLineReturn = (props) => {
  const {
    gql,
    value,
    displayStatus,
    returnReason,
    REJECT,
    ACKNOWLEDGE,
    CREATE,
    NOTE,
    PRODUCT,
    PRODUCT_UC_FIRST,
  } = constant;
  const { cms } = useContext(PrivateContext);
  const { disable, onClose, orderToInitiate, refetch, setDisable, setLineReturn, setMessage, showModal } = props;
  const {
    accountingStatus,
    actualQuantity,
    orderId,
    quantity: totalQuantity = 0,
    return: returnData,
    returnedQuantity,
    _id,
    vendorId,
  } = orderToInitiate;
  const [note, setNote] = useState("");
  const [reason, setReason] = useState("");
  const [sellerNote, setSellerNote] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [returnQuantity, setReturnQuantity] = useState(0);
  const [isSellerManaged, setIsSellerManaged] = useState(false);
  const [inventoryCheck, setInventoryCheck] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [isCreditNote, setIsCreditNote] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    tittle: "",
  });

  const { error: errorGetCreditNote, loading: loadingGetCreditNote, data: dataGetCreditNote } = useQuery(
    GET_CREDIT_NOTE_SETTING
  );

  const creditNoteResponseData = baseHelper.getResponseData(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  const creditNoteErrorData = baseHelper.getResponseError(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  useEffect(() => {
    if (creditNoteResponseData) {
      const { creditNote = {} } = creditNoteResponseData;
      const { isAllVendor = false, isEnabled = false, selectedVendor = [] } = creditNote || {};
      if (isEnabled && accountingStatus === constant.PAID && (isAllVendor || selectedVendor.includes(vendorId))) {
        setIsCreditNote(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditNoteResponseData]);

  useEffect(() => {
    if (creditNoteErrorData) {
      setBanner({ status: constant.CRITICAL, title: creditNoteErrorData, isOpen: true });
    }
    if (errorGetCreditNote) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetCreditNote), isOpen: true });
    }
  }, [creditNoteErrorData, errorGetCreditNote]);

  const [lineReturn] = useMutation(LINE_RETURN);

  const [acknowledgeLoading, setAcknowledgeLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const setSuccessBanner = (data) => {
    if (data.isAccept && isCreditNote) {
      setMessage(cms("message.success.returnAcceptCredit"));
      return;
    }
    if (data.isAccept) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.returnAccepted") });
      return;
    }
    if (data.isAcknowledge) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.returnAcknowledged") });
      return;
    }
    if (data.isReject) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.returnReject") });
    }
  };

  const orderLineReturn = async (requestData, IsButtonLoading) => {
    try {
      const res = await lineReturn({
        variables: { input: requestData },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.LINE_RETURN);
      const errorData = baseHelper.getResponseError(res.data, gql.LINE_RETURN);
      if (!errorData) {
        refetch();
        setSuccessBanner(requestData);
        if (requestData.isReject || requestData.isAccept) {
          setTimeout(() => {
            onClose();
          }, 1500);
        }
      }
      if (!responseData) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorData });
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    if (IsButtonLoading === REJECT) {
      setRejectLoading(false);
    }
    if (IsButtonLoading === ACKNOWLEDGE) {
      setAcknowledgeLoading(false);
    }
  };

  useEffect(() => {
    if (returnData && returnData.status === ACKNOWLEDGE) {
      setDisable(false);
      setNote(returnData.vendorNote);
      setIsAcknowledge(true);
    }
    if (returnData && (returnData.status === ACKNOWLEDGE || returnData.status === CREATE)) {
      const { quantity: quantityReturned = 0 } = returnData;
      setReturnQuantity(quantityReturned);
      setQuantity((actualQuantity || totalQuantity) - returnedQuantity);
      setReason(returnData.reason);
      setIsSellerManaged(returnData.isSellerManaged);
      setSellerNote(returnData.sellerNote);
    }
  }, [returnData, ACKNOWLEDGE]);

  const reasons = [
    {
      label: cms("section.lineReturn.label.productNotRequired"),
      value: returnReason.NOT_NEEDED,
    },
    {
      label: cms("section.lineReturn.label.productNotAsAdvertised"),
      value: returnReason.NOT_AS_ADVERTISED,
    },
    {
      label: cms("section.lineReturn.label.productNotAsSold"),
      value: returnReason.NOT_AS_SOLD,
    },
    {
      label: cms("section.lineReturn.label.productFaulty"),
      value: returnReason.FAULTY,
    },
    {
      label: cms("section.lineReturn.label.others"),
      value: returnReason.OTHER,
    },
  ];

  const handleSelectChange = (selectedValue) => setReason(selectedValue);

  const handleNoteChange = (noteText) => setNote(noteText);

  const handleSellerManaged = (checked) => {
    setIsSellerManaged(checked);
  };

  const handelInventoryCheck = (checked) => {
    setInventoryCheck(checked);
  };

  const handleValidate = async (field, event) => {
    const validationError = await validate(field, event.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const isDisabled = () => {
    const isAllFieldEmpty = !(reason && note && returnQuantity && parseInt(returnQuantity, 10));
    return isAllFieldEmpty;
  };

  const renderTitle = (item) => {
    if (!item) {
      return null;
    }
    const { orderNumber, shopifyLineItemId: shopifyLineItemIdValue } = item;

    return (
      <>
        <Heading>{`#${orderNumber}`}</Heading>
        <Caption>
          {`${cms("section.lineReturn.label.lineItemId")} : `}
          <span>{`#${shopifyLineItemIdValue}`}</span>
        </Caption>
      </>
    );
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const handleQuantityChange = (value) => {
    setReturnQuantity(value);
  };

  const onClickRejectReturn = () => {
    if (inventoryCheck) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.success.addToInventoryCheck"),
      });
      return;
    }
    setRejectLoading(true);
    setDisable(true);
    const requestData = {
      orderId,
      reason,
      note,
      orderLineItemId: _id,
      isSellerManaged,
      isAddToInventory: inventoryCheck,
      isReject: true,
    };
    orderLineReturn(requestData, REJECT);
  };

  const onClickAcknowledgeReturn = () => {
    setAcknowledgeLoading(true);
    setDisable(true);
    const requestData = {
      orderId,
      reason,
      note,
      isSellerManaged,
      orderLineItemId: _id,
      isAddToInventory: inventoryCheck,
      isAcknowledge: !isAcknowledge,
      isAccept: isAcknowledge,
      returnQuantity,
    };

    orderLineReturn(requestData, ACKNOWLEDGE);
  };
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      title={renderTitle(orderToInitiate)}
      primaryAction={[
        {
          content: cms("section.lineReturn.button.rejectReturn"),
          destructive: true,
          onAction: () => onClickRejectReturn(),
          disabled: isDisabled() || disable,
          loading: rejectLoading,
        },
        {
          content:
            (!isAcknowledge && cms("section.lineReturn.button.acknowledgeReturn")) ||
            cms("section.lineReturn.button.acceptReturn"),
          onAction: () => onClickAcknowledgeReturn(),
          disabled: isDisabled() || disable,
          loading: acknowledgeLoading,
        },
      ]}
      secondaryActions={{
        content: cms("section.lineReturn.label.cancel"),
        onAction: () => onClose(),
        disabled: disable,
      }}
      loading={isAcknowledge && loadingGetCreditNote}
    >
      {banner && banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ ...banner, isOpen: false })}
        />
      )}

      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            <h2>{`${PRODUCT_UC_FIRST} - ${(orderToInitiate && orderToInitiate.title) || PRODUCT}`}</h2>
            <h5>{`${cms("section.lineReturn.label.supplier")} - ${orderToInitiate.vendor}`}</h5>
            <Badge>{displayStatus.FULFILLED || orderToInitiate.fulfillmentStatus}</Badge>
            {returnQuantity && parseInt(returnQuantity, 10) > 0 && parseInt(quantity - returnQuantity, 10) > 0 && (
              <Badge status={baseHelper.getBadgeType(constant.RETURN)}>
                {statusHelper.getBadgeStatus(constant.PARTIAL_RETURN)}
              </Badge>
            )}
          </Stack.Item>
          <FieldSetQty>
            <Stack.Item>
              <TextField
                value={(returnQuantity && returnQuantity.toString()) || ""}
                min={constant.value.ZERO}
                max={quantity}
                onChange={(value) => [
                  value <= parseInt(quantity, 10) && handleQuantityChange(acceptOnlyValidInput(value, returnQuantity)),
                ]}
                suffix={`of ${quantity}`}
                disabled
              />
            </Stack.Item>
          </FieldSetQty>
        </Stack>
        <br />
        <Select
          placeholder={cms("section.lineReturn.placeHolder.reason")}
          options={reasons}
          onChange={handleSelectChange}
          value={reason}
          disabled
        />
        <br />
        {sellerNote && (
          <TextField
            placeholder={cms("section.lineReturn.placeholder.noteAdd")}
            value={`${cms("section.lineReturn.label.note")}: ${sellerNote}`}
            disabled
            multiline
          />
        )}
        <br />
        <TextField
          label={cms("section.lineReturn.label.note")}
          labelHidden
          placeholder={cms("section.lineReturn.placeHolder.noteAdd")}
          value={note}
          onChange={(noteValue) => handleNoteChange(noteValue)}
          onBlur={(e) => handleValidate(NOTE, e)}
          error={errorMessage && errorMessage.note}
          maxLength={value.MAX_CHARACTER}
          multiline
          disabled={disable}
        />
        <br />
        <Stack>
          <Stack.Item>
            <Checkbox
              label={cms("section.lineReturn.label.sellerManaged")}
              id="sellerManaged"
              checked={isSellerManaged}
              onChange={handleSellerManaged}
              disabled={disable}
            />
          </Stack.Item>
          {isAcknowledge && (
            <Stack.Item>
              <Checkbox
                label={cms("section.lineReturn.label.addInventory")}
                checked={inventoryCheck}
                id="addToInventory"
                onChange={handelInventoryCheck}
                disabled={disable}
              />
            </Stack.Item>
          )}
          <br />
          {isAcknowledge && isCreditNote && (
            <Stack.Item>
              <Checkbox
                label={cms("section.lineReturn.label.issueCreditNote")}
                checked
                id="issueCreditNote"
                disabled={disable}
              />
            </Stack.Item>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

ProviderLineReturn.propTypes = providerLineReturnProps.type;

export default ProviderLineReturn;
