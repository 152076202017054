import React, { useContext, useEffect, useState } from "react";
import { Card, FormLayout, Modal, TextContainer } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import { useQuery } from "react-apollo";
import moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import helpers
import baseHelper from "lib/helpers/base";
import { errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import helpers component
import { ResourceList } from "lib/components";

// import filter component
import MoreFilters, { Filter } from "app/analytics/modules/generic/moreFilter";

// import context
import { PrivateContext } from "lib/context";

// import gql
// import ACCOUNT_LIST from "app/payments/apollo/query";
// import { PAYMENT_LIST } from "app/payments/apollo/subscriptions";
import { GET_VENDOR_ORDER_REPORT_LIST } from "app/analytics/apollo/queries";

// import cms
import actionData from "app/analytics/modules/operator/commissionRevenue/cms/resourceTableData";

// import subFeature
import { listProp } from "app/payments/modules/operator/features/list/propTypes";
import commissionRevenueHelper from "./commissionRevenueHelper";
// import BULK_ACCOUNT from "app/payments/apollo/mutation/bulkAccount";
import CommissionRevenueRow from "./commissionRevenueRow";

const CommissionRevenueList = (props) => {
  const { isOpenModal, setBanner, setIsOpenModal } = props;
  const { cms, history } = useContext(PrivateContext);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(queryParam.listValue || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.listFilter || "");
  const [paymentListData, setPaymentList] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const resourceData = actionData(cms);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [disable, setDisable] = useState(false);

  const renderItem = (item) => {
    return <CommissionRevenueRow item={item} />;
  };

  const inputData = {
    sort_name: queryParam.sort_name || "vendor",
    sort_order: queryParam.sort_order || "asc",
    sort_date: queryParam.sort_date,
    page: parseInt(page, 10),
    perPage: parseInt(perPage, 10),
    listFilter: queryParam.listFilter,
    listValue: queryParam.listValue,
    // month: queryParam.month,
    search: queryParam.search,
  };
  const { data: orderLinesData, loading: orderLoading, error } = useQuery(GET_VENDOR_ORDER_REPORT_LIST, {
    variables: {
      input: inputData,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    getOrderReportList,
    { data: reportListData, loading: reportListLoading, error: reportListError },
  ] = useLazyQuery(GET_VENDOR_ORDER_REPORT_LIST, {
    variables: {
      input: {
        sort_name: "vendor",
        sort_order: "asc",
      },
    },
  });

  useEffect(() => {
    // setSelectedItems([]);
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (error || reportListError) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
  }, [error, reportListError, setBanner]);

  const orderLineError = baseHelper.getResponseError(orderLinesData, "getVendorOrderReportList");
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: orderLineError });
    }
  }, [orderLineError, setBanner]);

  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, "getVendorOrderReportList");

  useEffect(() => {
    if (responseOrderLinesData) {
      setPaymentList(responseOrderLinesData && responseOrderLinesData.commissionList);
      setPaymentCount(responseOrderLinesData && responseOrderLinesData.count);
    }
  }, [responseOrderLinesData]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(reportListData, "getVendorOrderReportList");
    const responseError = baseHelper.getResponseError(reportListData, "getVendorOrderReportList");
    if (responseData) {
      const { commissionList = [] } = responseData || {};
      const csvData = commissionRevenueHelper.commissionCSVData(commissionList);
      const csv = Baby.unparse(csvData);
      const date = moment(new Date(), "DD-MM-YYYY").format("LL");
      FileDownload(csv, `${"Order revenue"} ${date}.csv`);
      setIsOpenModal(false);
      setDisable(false);
    }
    if (responseError) {
      setBanner({
        title: responseError,
        status: "critical",
        isOpen: true,
      });
      setIsOpenModal(false);
      setDisable(false);
    }
  }, [reportListData]);

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };
  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    setSearch(null);
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = { listValue: "", page: 1 };
    if (clearFilter) {
      clearParams.listFilter = "";
      if (key) {
        switch (key) {
          case constant.MONTH:
            return [setMonth(""), baseHelper.setUrl(history, clearParams)];
          // case constant.FILTER_BY_STATUS:
          //   return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setMonth(""),
              setSelectedFilter(""),
              // setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.SELECTED_FILTER) {
      clearParams.listFilter = "";
      setSelectedFilter("");
    }
    setMonth("");

    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setMonth("");
    baseHelper.setUrl(history, { listFilter: "", listValue: "", page: 1 });
  };

  const handleClearAll = () => {
    handleSelectedFilterRemove();
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    // setFilterByStatus(null);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  // let tagMarkup = [];
  // if (filterByStatus && filterByStatus.length) {
  //   tagMarkup =
  //     filterByStatus &&
  //     filterByStatus.map((option) => (
  //       <Tag key={option} onRemove={removeTag(option)}>
  //         {option === constant.FORECAST
  //           ? cms("label.forecast")
  //           : option === constant.DUE
  //           ? cms("label.duePayment")
  //           : option === constant.DISPUTE
  //           ? cms("label.dispute")
  //           : baseHelper.ucFirst(option)}
  //       </Tag>
  //     ));
  // }

  const disambiguateLabel = (key, value) => {
    const label = {
      yearly: "Upto 1 Year",
      halfYearly: "Upto 6 Months",
      current: "Current Month",
      previous: "Previous Month",
      month: "Month",
    };
    switch (key) {
      case constant.MONTH:
        return label[value] || value;
      case constant.SELECTED_FILTER:
        return label[value] || value;
      // case constant.FILTER_BY_STATUS:
      //   return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilterArrObj = [];

  if (!isEmpty(month)) {
    appliedFilterArrObj.push({
      key: constant.MONTH,
      label: disambiguateLabel(constant.MONTH, month),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false, constant.SELECTED_FILTER),
    });
  }

  // if (!isEmpty(filterByStatus)) {
  //   appliedFilterArrObj.push({
  //     key: constant.FILTER_BY_STATUS,
  //     label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
  //     onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
  //   });
  // }
  const appliedFilters = appliedFilterArrObj;

  const filterOptions = [{ label: baseHelper.ucFirst(constant.MONTH), value: constant.MONTH }];

  const exportCSVModal = () => (
    <>
      <Modal
        open={isOpenModal}
        onClose={handleCloseModal}
        title="Generate CSV"
        primaryAction={{
          content: "Generate",
          onAction: () => {
            getOrderReportList();
            setDisable(true);
          },
          disabled: loading || disable,
          loading: reportListLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCloseModal,
            disabled: loading || reportListLoading,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Do you want to generate CSV?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );

  // const filterByStatusOption = [
  //   {
  //     label: cms("label.all"),
  //     value: "all",
  //   },
  //   {
  //     label: cms("label.forecast"),
  //     value: "forecast",
  //   },
  //   {
  //     label: cms("label.duePayment"),
  //     value: "due",
  //   },
  //   {
  //     label: cms("label.pay"),
  //     value: "paid",
  //   },
  //   {
  //     label: cms("label.dispute"),
  //     value: "dispute",
  //   },
  // ];

  // const handleAction = (value) => {
  //   setFilterByStatus(value);
  //   baseHelper.setUrl(history, { filter: value, page: 1 });
  //   if (!value.length) {
  //     baseHelper.setUrl(history, { filter: "all", page: 1 });
  //   }
  // };

  const customFilter = (
    <FormLayout>
      <Filter
        taggedWith={month}
        setTaggedWith={setMonth}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        filterOptions={filterOptions}
      />
      {(selectedFilter && (
        <MoreFilters
          label="Select Months"
          taggedWith={month}
          setTaggedWith={setMonth}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      )) ||
        null}
    </FormLayout>
  );

  const filters = [
    {
      key: "selectedFilter",
      label: "Filter By",
      filter: customFilter,
      shortcut: false,
    },
  ];

  // const filters = [
  //   {
  //     key: "taggedWith",
  //     label: cms("label.filter"),
  //     // label: "Filter by",
  //     filter: (
  //       <MoreFilters
  //         taggedWith={taggedWith}
  //         setTaggedWith={setTaggedWith}
  //         selectedFilter={selectedFilter}
  //         setSelectedFilter={setSelectedFilter}
  //         filterOptions={filterOptions}
  //       />
  //     ),
  //   },
  // {
  //   key: constant.FILTER_BY_STATUS,
  //   label: cms("common.label.filterByStatus"),
  //   filter: (
  //     // <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
  //     //   <OptionList
  //     //     fullWidth
  //     //     allowMultiple
  //     //     onChange={(value) => handleAction(value)}
  //     //     options={filterByStatusOption}
  //     //     selected={filterByStatus || []}
  //     //   />
  //     // </Popover>

  //     <ChoiceList
  //       title={cms("common.label.filterByStatus")}
  //       titleHidden
  //       choices={filterByStatusOption}
  //       selected={filterByStatus || []}
  //       onChange={(value) => handleAction(value)}
  //       allowMultiple
  //     />
  //   ),
  // },
  // ];

  return (
    <Card>
      {exportCSVModal()}
      <ResourceList
        resourceName={resourceData.resourceName}
        items={paymentListData}
        idForItem={(orderLine) => {
          const { _id: id } = orderLine;
          return id;
        }}
        renderItem={renderItem}
        loading={loading || orderLoading}
        queryValue={search}
        onQueryClear={handleQueryClear}
        onQueryChange={handleSearchChange}
        sortOptions={resourceData.sortOptions}
        sortValue={sortValue}
        onSortChange={handleSort}
        // selectedItems={selectedItems}
        // onSelectionChange={handleSelect}
        // bulkActions={bulkActions}
        filters={filters}
        appliedFilters={appliedFilters}
        handleClearAll={handleClearAll}
        count={paymentCount}
        page={page}
        perPage={perPage}
        setPage={handlePageChange}
        setPopoverDisable={setPopoverDisable}
        setPerPage={handlePageLimitChange}
      />
    </Card>
  );
};
CommissionRevenueList.propTypes = listProp.type;
export default CommissionRevenueList;
