import React, { useState, useContext } from "react";
import { Modal, Card, Stack, Button, RadioButton, Select } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { PrivateContext } from "lib/context";
import { AutoComplete, Banner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { ADD_OFFER } from "app/shipping/apollo/mutations";
import { addProp } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";
import Destinations from "app/shipping/modules/provider/features/subFeatures/advance/offers/subFeatures/destinations";
// eslint-disable-next-line max-len
import RenderOfferCondition from "app/shipping/modules/provider/features/subFeatures/advance/offers/subFeatures/renderOfferCondition";

// eslint-disable-next-line max-len
import RenderOfferDetail from "app/shipping/modules/provider/features/subFeatures/advance/offers/subFeatures/renderOfferDetail";

// eslint-disable-next-line max-len
import RenderOfferDestination from "app/shipping/modules/provider/features/subFeatures/advance/offers/subFeatures/renderOfferDestination";

// import advance shipping context
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

const AddOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { addModal, toggleAddModal, destinationData, shippingBands, refetch, products } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const defaultOffer = {
    productQuantity: {
      value: 0,
      criteria: "",
    },
    productPrice: {
      value: 0,
      criteria: "",
    },
    orderValue: {
      value: 0,
      criteria: "",
    },
  };
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const { CRITERIA, FIXED, FREE, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY, VALUE, gql } = constant;
  const [shippingOffer, setShippingOffer] = useState({ ...defaultOffer });
  const [description, setDescription] = useState("");
  const [shippingBandId, setShippingBandId] = useState("");
  const [checkedDestination, setCheckedDestination] = useState(true);
  const [checkedProduct, setCheckedProduct] = useState(true);
  const [checkedCondition, setCheckedCondition] = useState(true);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [offerCriteria, setofferCriteria] = useState("");
  const [offerCriteriaValue, setofferCriteriaValue] = useState("");
  const [priceType, setPricetype] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [isCumulative, setisCumulative] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [destinationCodes, setDestinationCodes] = useState([]);

  const handleShippingBand = (value) => {
    setShippingBandId(value);
  };

  const togglePopoverActive = () => setPopoverActive(!popoverActive);
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {cms("modal.rule.add.button.selectRegion")}
    </Button>
  );
  const handleDestination = () => setCheckedDestination(!checkedDestination);
  const handleProduct = () => {
    if (!checkedProduct) {
      setProductIds("");
    }
    setCheckedProduct(!checkedProduct);
  };
  const handleCondition = () => setCheckedCondition(!checkedCondition);

  const handleSelectChangeDropdown = (value) => {
    if (value === ORDER_VALUE) {
      setProductIds([]);
      setCheckedProduct(!checkedProduct);
      setisCumulative(!isCumulative);
    }
    setSelectedDropdown(value);
    setofferCriteria("");
    setofferCriteriaValue("");
  };
  const handleShippingDestination = (value) => {
    setDestinationCodes(value);
  };

  const [createOfferManage, { loading: createLoading }] = useMutation(ADD_OFFER);

  let isOrderValue = selectedDropdown === ORDER_VALUE;
  const handleCriteria = (criteria, value) => {
    if (!shippingOffer[criteria]) {
      shippingOffer[criteria] = {
        criteria: value,
      };
    } else {
      shippingOffer[criteria].criteria = value;
    }
    setShippingOffer({ ...shippingOffer });
    setofferCriteria(value);
  };

  const handleCriteriaValue = (criteria, value) => {
    if (!shippingOffer[criteria]) {
      shippingOffer[criteria] = {
        value,
      };
    } else {
      shippingOffer[criteria].value = parseFloat(value);
    }
    setShippingOffer({ ...shippingOffer });
    setofferCriteriaValue(value);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };
  const handleProducts = (value) => {
    setProductIds(value);
  };
  const handleType = (value) => {
    if (value !== FIXED) {
      setPriceValue("");
    }
    setPricetype(value);
  };
  const handleCost = (value) => {
    setPriceValue(value);
  };
  const toggleCumulativeShipping = () => {
    setisCumulative(!isCumulative);
  };

  const renderRegion = () => {
    return <Destinations destinationData={destinationData} destinationCodes={destinationCodes} />;
  };

  const isValid = () => {
    const { productQuantity, productPrice, orderValue } = shippingOffer || {};
    const isProductQuantity = selectedDropdown === PRODUCT_QUANTITY;
    const isProductPrice = selectedDropdown === PRODUCT_PRICE;
    isOrderValue = selectedDropdown === ORDER_VALUE;
    if (!shippingBandId) {
      return cms("message.error.selectShippingBand");
    }
    if (isProductQuantity && !(productQuantity && CRITERIA in productQuantity && productQuantity.criteria)) {
      return cms("message.error.selectCriteriaQuantity");
    }
    if (isProductQuantity && !(productQuantity && VALUE in productQuantity && productQuantity.value)) {
      return cms("message.error.provideQuantity");
    }
    if (isProductQuantity && productQuantity.value <= 0) {
      return cms("message.error.enterValidQuantity");
    }
    if (isProductPrice && !(productPrice && CRITERIA in productPrice && productPrice.criteria)) {
      return cms("message.error.selectCriteriaProduct");
    }
    if (isProductPrice && !(productPrice && VALUE in productPrice && productPrice.value)) {
      return cms("message.error.providePrice");
    }
    if (isProductPrice && productPrice.value <= 0) {
      return cms("message.error.enterValidPrice");
    }
    if (isOrderValue && !(orderValue && CRITERIA in orderValue && orderValue.criteria)) {
      return cms("message.error.selectCriteriaOrder");
    }
    if (isOrderValue && !(orderValue && VALUE in orderValue && orderValue.value)) {
      return cms("message.error.provideOrder");
    }
    if (isOrderValue && orderValue.value <= 0) {
      return cms("message.error.enterValidOrderAmount");
    }
    if (!description) {
      return cms("message.error.addDescriptionForRule");
    }
    if (description.length > 255) {
      return cms("message.error.descriptionCharacter");
    }
    if (!priceType) {
      return cms("message.error.selectShippingRuleType");
    }
    if (priceType !== FREE && !priceValue) {
      return cms("message.error.provideCost");
    }
    if (priceType !== FREE && priceValue < 0) {
      return cms("message.error.provideValidPrice");
    }
    return false;
  };

  const addShipping = () => {
    const productQuantityValue = parseInt(shippingOffer.productQuantity.value, 10);
    shippingOffer.productQuantity.value = productQuantityValue;

    createOfferManage({
      variables: {
        input: {
          shippingBandId,
          price: parseFloat(priceValue, 10),
          description,
          condition: selectedDropdown,
          destinationCodes,
          productIds,
          ...shippingOffer,
          priceType,
          isCumulative,
        },
      },
    })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.CREATE_SHIPPING_OFFER);
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.CREATE_SHIPPING_OFFER);
          setBanner({ isOpen: true, title: resError, status: "critical" });
          return;
        }

        setBannerOuter({ isOpen: true, title: cms("modal.offer.add.message.shippingOfferAdded"), status: "success" });
        toggleAddModal();
        refetch();
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: "critical" });
      });
  };
  const handleSubmit = () => {
    const isValidation = isValid();
    if (isValidation) {
      setBanner({
        isOpen: true,
        title: isValidation,
        status: "critical",
      });
      return false;
    }
    addShipping();
    return true;
  };
  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };

  const offerConditions = () => {
    return (
      <RenderOfferCondition
        selectedDropdown={selectedDropdown}
        shippingOffer={shippingOffer}
        checkedCondition={checkedCondition}
        handleSelectChangeDropdown={handleSelectChangeDropdown}
        offerCriteria={offerCriteria}
        offerCriteriaValue={offerCriteriaValue}
        handleCriteriaValue={handleCriteriaValue}
        handleCriteria={handleCriteria}
        handleCondition={handleCondition}
        moneyFormat={moneyFormat}
        cms={cms}
      />
    );
  };
  const OfferDetail = () => {
    return (
      <RenderOfferDetail
        isCumulative={isCumulative}
        handleCost={handleCost}
        handleDescription={handleDescription}
        handleType={handleType}
        priceType={priceType}
        description={description}
        toggleCumulativeShipping={toggleCumulativeShipping}
        isOrderValue={isOrderValue}
        moneyFormat={moneyFormat}
        priceValue={priceValue}
        cms={cms}
      />
    );
  };
  const OfferDestination = () => {
    return (
      <RenderOfferDestination
        checkedDestination={checkedDestination}
        renderRegion={renderRegion}
        handleDestination={handleDestination}
        popoverActive={popoverActive}
        activator={activator}
        handleShippingDestination={handleShippingDestination}
        destinationData={destinationData}
        destinationCodes={destinationCodes}
        togglePopoverActive={togglePopoverActive}
        cms={cms}
      />
    );
  };

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={addModal}
        large
        onClose={toggleAddModal}
        title={cms("modal.offer.add.title")}
        primaryAction={{
          content: cms("modal.offer.add.button.primary"),
          onAction: handleSubmit,
          loading: createLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <Stack vertical>
            <Card title={cms("modal.offer.add.section.band.title")} sectioned>
              <Stack.Item>
                <Select
                  options={shippingBands}
                  placeholder={`${cms("modal.offer.add.section.band.placeholder.select")} *`}
                  value={shippingBandId}
                  onChange={handleShippingBand}
                />
              </Stack.Item>
            </Card>
            {OfferDestination()}
            {!isOrderValue && (
              <Card title={cms("label.product")} sectioned>
                <Stack.Item>
                  <RadioButton
                    label={cms("modal.offer.add.section.product.label.allProduct")}
                    helpText={cms("modal.offer.add.section.product.caption.allProduct")}
                    checked={checkedProduct}
                    id="all-products"
                    name="all-products"
                    onChange={handleProduct}
                  />
                </Stack.Item>
                <Stack.Item>
                  <RadioButton
                    label={cms("modal.offer.add.section.product.label.selectedDestination")}
                    helpText={cms("modal.offer.add.section.product.caption.selectedDestination")}
                    id="selected-products"
                    name="selected-products"
                    checked={!checkedProduct}
                    onChange={handleProduct}
                  />
                  {!checkedProduct && (
                    <AutoComplete
                      id="productOfferCondition"
                      label={cms("modal.rule.add.label.products")}
                      labelHidden
                      placeholder={cms("modal.rule.add.placeholder.startTyping")}
                      values={products}
                      selected={productIds}
                      onChange={handleProducts}
                      minimumSearchLength={3}
                    />
                  )}
                </Stack.Item>
              </Card>
            )}
            {offerConditions()}
            {OfferDetail()}
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

AddOffers.propTypes = addProp.type;

export default AddOffers;
