import React from "react";

import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { CreditCardMajor, LogOutMinor, TransactionMajor } from "@shopify/polaris-icons";

import PropTypes from "prop-types";

// import from lib
import Popover from "lib/components/popover/popover";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

const SellerListView = (props) => {
  const {
    activePopover,
    item,
    keyIndex,
    onLoginSeller,
    onUpdateSeller,
    popoverDisable,
    sellerListCMS,
    setActivePopover,
    handleDisableApiModal,
    enableDisableApiLoading,
    isApiAccessLoading,
  } = props;
  const { _id, brandName, email, plan, roles, api = {} } = item;
  const { status = "" } = api || {};
  const { address, verified } = email;
  const { code: planCode = "", name: planName = "" } = plan || {};
  const { name: role } = roles;
  const { button, label } = sellerListCMS;
  const apiStatus = [constant.PENDING_SMALL, constant.DISABLED];

  const topMargin10px = {
    marginTop: "10px",
  };

  const handleSelectAction = (selectAction, id, operatorBrandName, operatorRole) => {
    if (selectAction === "login") {
      onLoginSeller(id, operatorBrandName, operatorRole);
    } else {
      onUpdateSeller(id, operatorBrandName, operatorRole);
    }
  };

  const optionList = [
    {
      content: button.updatePlan,
      icon: CreditCardMajor,
      onAction: () => handleSelectAction("updatePlan", _id, brandName, role),
    },
    {
      content: button.loginSeller,
      icon: LogOutMinor,
      onAction: () => handleSelectAction("login", _id, brandName, role),
    },
  ];

  if (status) {
    optionList.push({
      content: apiStatus.includes(status) ? "Enable API" : "Disable API",
      icon: TransactionMajor,
      onAction: () => handleDisableApiModal(),
      loading: enableDisableApiLoading,
      destructive: !apiStatus.includes(status) || false,
    });
  }

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <Link url={`/operators/view/${_id}`}>
              <TextStyle>{baseHelper.ucFirst(brandName)}</TextStyle>
            </Link>
            <Caption>
              <Stack.Item>{`${label.email}: ${address}`}</Stack.Item>
              <Stack.Item>{`${label.plan}: ${planCode || planName || ""}`}</Stack.Item>
              <Stack.Item>{`API Status: ${baseHelper.ucFirst(status) || constant.NOT_REQUESTED}`}</Stack.Item>
            </Caption>
            <div style={topMargin10px}>
              <Stack>
                <Stack.Item>
                  <Badge status={verified ? constant.SUCCESS : constant.NEW}>
                    {verified ? constant.APPROVED : constant.PENDING}
                  </Badge>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              // eslint-disable-next-line no-underscore-dangle
              setActive={() =>
                popoverDisable ? setActivePopover(false) : setActivePopover({ [_id]: !activePopover[_id] })}
              options={optionList}
              loading={isApiAccessLoading[_id]}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

SellerListView.defaultProps = {
  item: {},
  keyIndex: "",
  onUpdateSeller: () => {},
  onLoginSeller: () => {},
  activePopover: {},
};

SellerListView.propTypes = {
  item: PropTypes.objectOf(PropTypes.shape),
  keyIndex: PropTypes.number,
  onUpdateSeller: PropTypes.func,
  onLoginSeller: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
  sellerListCMS: PropTypes.func.isRequired,
};

export default SellerListView;
