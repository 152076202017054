import React, { useContext } from "react";
import { Card, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import components
import { DropZoneFile } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { FormContext } from "app/productOld/modules/operator/features/add/context/context";

const Attachment = () => {
  const { handleChange, data, learnMore } = useContext(FormContext);
  // const { gql } = constant; setDisabledButton, existingProductAttachments = [], setExistingAttachments
  const { cms } = useContext(PrivateContext);
  const addFile = (value) => {
    const productFiles = (data && data.file) || [];
    const uploadedFiles = [...productFiles, ...value];
    handleChange(constant.FILE, uploadedFiles);
  };
  const removeFile = (index) => {
    const productFiles = data.file || [];
    if (index < 0 || productFiles.length <= 0) {
      return;
    }
    productFiles.splice(index, 1);
    handleChange(constant.FILE, productFiles);
  };

  const handleDownload = (file) => {
    window.open(file.fileURL, "_blank");
  };

  return (
    <Card
      title={cms("section.form.section.attachment.title")}
      id="productAttachment"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("product file", cms("section.form.section.image.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <TextContainer>{cms("section.form.section.attachment.label.addFile")}</TextContainer>
        <br />
        <DropZoneFile
          id="productAttachmentId"
          size={10}
          onAdd={addFile}
          onRemove={removeFile}
          downloadExistingFile={handleDownload}
          allowMultiple
        />
      </Card.Section>
    </Card>
  );
};

export default Attachment;
