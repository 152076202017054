import React, { useContext } from "react";
import { TextField } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const CanadaPostShipping = (props) => {
  const { cms } = useContext(PrivateContext);
  const { canadaPost, handleCanadaPostChange, setError: error } = props;
  const { userName = "", password = "", customerNumber = "", contractId = "" } = canadaPost || {};

  return (
    <>
      <TextField
        label={`${cms("label.userName")}*`}
        value={userName}
        onChange={(value) => handleCanadaPostChange("userName", value)}
        error={error && !userName && error.userName}
      />
      <br />
      <TextField
        label={`${cms("label.password")}*`}
        value={password}
        onChange={(value) => handleCanadaPostChange("password", value)}
        error={error && !password && error.password}
      />
      <br />
      <TextField
        label={`${cms("label.customerNumber")}*`}
        type="number"
        value={customerNumber}
        onChange={(value) => handleCanadaPostChange("customerNumber", value)}
        error={error && !customerNumber && error.customerNumber}
      />
      <br />
      <TextField
        label={`${cms("label.contractId")}*`}
        type="number"
        value={contractId}
        onChange={(value) => handleCanadaPostChange("contractId", value)}
        error={error && !contractId && error.contractId}
      />
    </>
  );
};

export default withFeature(CanadaPostShipping, {
  feature: constant.CANADA_POST,
  // subFeature: constant.CANADA_POST,
});
