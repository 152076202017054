import React, { useContext } from "react";
import { Caption, Card, Layout, Stack, Collapsible, TextContainer, RadioButton, TextStyle } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const Footer = () => {
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { updatedAt = "" } = currentUser || {};
  const { IS_DEFAULT, FOOTER, CONTENT } = constant;
  const {
    // learnMore
    page,
  } = useContext(BrandingContext);
  const { footer, handleChange, handleTiny } = page;

  const footerList = [
    {
      label: cms("section.footerText.label.default"),
      value: true,
      id: "defaultFooter",
    },
    {
      label: cms("section.footerText.label.customized"),
      value: false,
      id: "customFooter",
    },
  ];

  const renderList = () => {
    return footerList.map((list) => (
      <RadioButton
        label={list.label}
        checked={footer.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="footer"
        onChange={() => handleChange(IS_DEFAULT, list.value, FOOTER)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.footerText.title")}
        description={cms("section.footerText.description")}
      >
        <Card
          title={[
            cms("section.footerText.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          // actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.footerText.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.footerText.description")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!footer.isDefault} id="custom-footer-collapsible">
              <TextContainer>
                <Editor
                  id="footerCustomText"
                  textareaName="footerDescription"
                  value={footer.content}
                  onEditorChange={(value) => handleTiny(CONTENT, value, FOOTER)}
                  init={{
                    menubar: true,
                    plugins: ["link"],
                    toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                />
              </TextContainer>
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(Footer), {
  feature: constant.STANDARD_BRANDING,
});
