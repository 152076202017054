import React, { useEffect, useState, useContext } from "react";
import PropTypes, { string } from "prop-types";
import { useQuery } from "react-apollo";
import _ from "lodash";

import { List, Link, TextStyle, Scrollable } from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { StyledOrderVersioning } from "asset/styles/globalStyle";
import constant from "lib/constant/constant";
import GET_ORDER_HISTORY from "app/orders/apollo/queries/getOrderVersioning";

const OrderVersioning = (props) => {
  const { cms, orderId, lineItemId } = props;
  const { history, currentUser } = useContext(PrivateContext);
  const { brandName } = currentUser;
  const { gql } = constant;
  const { GET_ORDER_VERSION: GET_ORDER_VERSION_TEXT } = gql;
  const [orderVersions, setOrderVersions] = useState([]);

  const value = {
    orderId: [orderId],
  };

  if (lineItemId !== "") {
    value.orderLineId = [lineItemId];
  }

  // graphql query call
  const { loading: loadingHistory, data: dataOrderHistory } = useQuery(GET_ORDER_HISTORY, {
    variables: {
      input: value,
    },
  });

  useEffect(() => {
    if (dataOrderHistory) {
      const responseError = baseHelper.getResponseError(dataOrderHistory, GET_ORDER_VERSION_TEXT);
      const responseData = baseHelper.getResponseData(dataOrderHistory, GET_ORDER_VERSION_TEXT);
      if (responseData) {
        setOrderVersions(responseData);
      }
    }
  }, [loadingHistory, dataOrderHistory, GET_ORDER_VERSION_TEXT]);

  const fullName = baseHelper.getFullName(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);

  if (loadingHistory) {
    return <Spinner size="small" />;
  }

  const renderVersion = (orderVersion, isLatest) => {
    if (!orderVersion) {
      return <></>;
    }
    const { changes = {}, userName, createdAt, operation, via, version, updatedBy } = orderVersion;
    const viaText =
      (via === constant.SHOPIFY && cms("common.history.label.shopifyStore")) ||
      (via === constant.VENDOR_SHOPIFY && cms("common.history.label.vendorLiveConnect")) ||
      cms("common.history.label.portal");
    const operationText = cms(`section.orderVersion.label.${operation}`);
    const isUpdated = operation === constant.EDITED || operation === constant.NOTE_UPDATED;
    const { orders = [], orderLines = [] } = changes;
    const OrderNoteUpdated = Array.isArray(orders) && orders.find((item) => {
      if (item.key === constant.NOTE) {
        return item.updated;
      }
    });
    const updatedQty = Array.isArray(orderLines) && orderLines.find((change) => change.key === constant.orderVersion.fulfillableQuantity) || {};
    const handleProfileClick = () => {
      const fullNameCapitalized = fullName.toUpperCase();
      const userNameCapitalized = userName.toUpperCase();
      if (isSeller && fullNameCapitalized !== userNameCapitalized) {
        history.push(`/providers/view/${updatedBy}`);
      } else if (fullNameCapitalized === userNameCapitalized) {
        history.push("/profile");
      }
    };
    return (
      <>
        <List.Item>
          <p className={isLatest ? "title-list" : "title-list other"}>
            {operationText}
            {` ${cms("common.label.smallBy")} `}
            <TextStyle variation="strong">
              <Link onClick={handleProfileClick}>{`${userName}`}</Link>
            </TextStyle>
            {` ${cms("common.label.on")} ${baseHelper.formatDate(createdAt)}`}
          </p>
          <p>
            {`${cms("common.history.label.changeTriggered")} `}
            <TextStyle variation="strong">{viaText}</TextStyle>
          </p>
          {isUpdated && !!updatedQty && !!updatedQty.prev && !!updatedQty.updated && (
            <p>
              {`${cms("common.label.quantityChanged")} `}
              <TextStyle>{`${updatedQty.prev} `}</TextStyle>
              {`${cms("common.label.to")} `}
              <TextStyle>{`${updatedQty.updated} `}</TextStyle>
            </p>
          )}
          <p>
            <TextStyle variation="subdued">{`${cms("common.history.label.version")} ${version} `}</TextStyle>
          </p>
        </List.Item>
        <List>
          {OrderNoteUpdated && (
            <List.Item type="bullet">
              <TextStyle variation="strong">{cms("section.orderVersion.label.notesUpdated")}</TextStyle>
              <br />
              <TextStyle>{OrderNoteUpdated.updated}</TextStyle>
            </List.Item>
          )}
        </List>
      </>
    );
  };

  const orderVersionLength = orderVersions.length;
  return orderVersions && orderVersions.length ? (
    <StyledOrderVersioning>
      <Scrollable className={orderVersionLength > 2 ? "scrollable" : "scrollbar__auto-false"} shadow focusable>
        <div className="timelineWrapper order-history">
          {orderVersions.map((version, index) => {
            const isFirstChange = !index;
            return [
              <div className="timeline">
                <List>{renderVersion(version, isFirstChange)}</List>
              </div>,
            ];
          })}
        </div>
      </Scrollable>
    </StyledOrderVersioning>
  ) : (
    <TextStyle>{cms("common.history.label.noChangesOrder")}</TextStyle>
  );
};

OrderVersioning.defaultProps = {
  cms: () => {},
  orderId: "",
  lineItemId: "",
  setListBanner: {},
};

OrderVersioning.prototype = {
  cms: PropTypes.func,
  orderId: PropTypes.string,
  lineItemId: PropTypes.string,
  setListBanner: PropTypes.objectOf(string),
};

export default OrderVersioning;
