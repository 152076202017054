import React, { useContext, useState } from "react";
import { Card, FormLayout, Layout, PageActions, TextField } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
// import helper components
import { Banner, SkeletonAnnotated } from "lib/components";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";
import config from "configuration";

// import query
import CONTACT_US from "app/userManagement/apollo/mutation/contactUs";
import GET_ASSOCIATED_SELLER from "app/userManagement/apollo/queries/getAssociatedSeller";

// import yup validation
import validate from "./yup";

const ContactUs = () => {
  const { cms, history, isLoading } = useContext(PrivateContext);
  // NOTE: Below commented code showing sender email on contact us page. undo comment and revert added code if required the same i.e. sender email id.
  // const { firstName, email } = currentUser
  // const { address } = email;
  const { gql } = constant;
  const { supportEmail = "" } = config;
  const [values, setValues] = useState({
    subject: "",
    message: "",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    message: "",
    children: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [contactUsRequest, { loading }] = useMutation(CONTACT_US);

  const { loading: associatedSellerLoading, data: associatedSellerData } = useQuery(GET_ASSOCIATED_SELLER);
  const associatedSeller = baseHelper.getResponseData(associatedSellerData, gql.GET_ASSOCIATED_SELLER) || {};
  const { email = {}, brandName, contactEmail = "" } = associatedSeller || {};
  const { address = "" } = email || {};

  const handleBlur = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    const Subject = values.subject.trim();
    const Message = values.message.trim();
    await handleBlur(constant.SUBJECT, Subject);
    await handleBlur(constant.MESSAGE, Message);
    if (!Message || !Subject) {
      return;
    }
    contactUsRequest({
      variables: {
        input: {
          subject: values.subject,
          message: values.message,
        },
      },
    })
      .then((response) => {
        const resData = baseHelper.getResponseData(response.data, gql.SEND_HELP_MESSAGE);
        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title: `${cms("message.success.emailSentSuccessfully")}`,
          children: `${cms("message.success.contact")}`,
        });
        setValues({
          subject: "",
          message: "",
        });
        if (!resData) {
          const resError = baseHelper.getResponseError(response.data, gql.SEND_HELP_MESSAGE);
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: resError,
          });
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  if (isLoading || associatedSellerLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
            >
              {banner.children}
            </Banner>
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("title")}>
        <Card sectioned>
          <FormLayout>
            <TextField
              id="name"
              label={`${cms("label.name")}*`}
              value={brandName || constant.SYSTEM_ADMIN_NAME}
              disabled
            />
            <TextField
              id="email"
              label={`${cms("common.label.email")}*`}
              value={contactEmail || address || supportEmail}
              disabled
            />
            <TextField
              id="subject"
              label={`${cms("label.subject")}*`}
              placeholder={cms("placeholder.subject")}
              value={values.subject}
              onChange={(value) => handleChange(constant.SUBJECT, value)}
              onBlur={() => handleBlur(constant.SUBJECT, values.subject)}
              error={errorMessage && errorMessage.subject}
            />
            <TextField
              id="message"
              label={`${cms("label.message")}*`}
              placeholder={cms("placeholder.message")}
              value={values.message}
              onChange={(value) => handleChange(constant.MESSAGE, value)}
              onBlur={() => handleBlur(constant.MESSAGE, values.message)}
              error={errorMessage && errorMessage.message}
              multiline={3}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("button.send"),
            onAction: () => onSubmit(),
            loading,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(ContactUs, { feature: constant.SUPPORT });
