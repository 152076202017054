import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  DataTable,
  Layout,
  PageActions,
  SettingToggle,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_ORDER_EXPORT_SETTING, UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING, UPDATE_EXPRESS_PAYMENT_SETTING } from "app/setup/apollo/mutations";
import { GET_ORDER_EXPORT_SETTING } from "app/setup/apollo/queries";
import { GET_WHOLESALE_SETTING } from "app/product/apollo/queries";
import ExpressPaymentSetting from "app/payments/modules/expressPaymentSetting";

// import ExportTable from "./style";

const { gql } = constant;

const OperatorExpressPaymentSetting = () => {
  const {
    CRITICAL,
    HIDDEN,
    PARTIALLY_HIDDEN,
    ORDER_COLUMN_CONTENT,
    ORDER_COLUMN_HEADING,
    SUCCESS,
    VISIBLE,
    WARNING,
  } = constant;

  const { cms, currentUser, history } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [markupDiscountExportSetting, setMarkupDiscountExportSetting] = useState(false);
  const [isExpressPayment, setIsExpressPayment] = useState(false);

  const expressPayment = currentUser.isExpressPayment;
  if (expressPayment) {
    // setIsExpressPayment(expressPayment);
  }

  const [expressPaymentSetting, { loading: expressPaymentSettingLoading }] = useMutation(
    UPDATE_EXPRESS_PAYMENT_SETTING
  );

  useEffect(() => {
    if (expressPayment) {
      setIsExpressPayment(true);
    }
  }, [expressPayment]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  const updateExpressPaymentSetting = async () => {
    expressPaymentSetting({ variables: { input: { isExpressPayment: !isExpressPayment } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_EXPRESS_PAYMENT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_EXPRESS_PAYMENT_SETTING);
          if (resData) {
            setMessage(cms("message.success"));
            setIsExpressPayment(!isExpressPayment);
            // refetch();
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card>
            <Card.Header title={cms("label.exportSetting")} />
            <SettingToggle
              action={{
                id: "submit",
                content: isExpressPayment ? constant.DISABLE : constant.ENABLE,
                onAction: () => updateExpressPaymentSetting(),
                // disabled: markupDiscountExportSetting,
                destructive: isExpressPayment,
                loading: expressPaymentSettingLoading,
              }}
              enabled={isExpressPayment}
            >
              {cms("cardDescription")}
            </SettingToggle>
          </Card>
        </Layout.AnnotatedSection>
        <Toast message={message} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(OperatorExpressPaymentSetting), {
  feature: constant.ORDER_EXPORT_SETTING,
});
