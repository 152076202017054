import { gql } from "apollo-boost";

const UPDATE_VENDOR_DISCOUNT_SETTING = gql`
  mutation manageVendorDiscountSetting($input: ManageVendorDiscount!) {
    manageVendorDiscountSetting(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_VENDOR_DISCOUNT_SETTING;
