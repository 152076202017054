import React from "react";

// import generic
import { InvoiceDownload } from "app/payments/modules/generic";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

const OperatorInvoice = () => {
  return <InvoiceDownload />;
};

export default withFeature(withErrorBoundary(OperatorInvoice), { feature: constant.INVOICE });
