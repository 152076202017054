import React, { useContext, useState } from "react";
import { Button, Link, Select, Stack } from "@shopify/polaris";
import { Banner } from "lib/components";

import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const Add = (props) => {
  const { cms } = useContext(PrivateContext);
  const { isSelectedVendor, setVendor, setSubmitEnable, vendorList, vendor } = props;
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const addItem = () => {
    const vendorItem = [...vendor];
    if (vendorItem.length > 0) {
      const item = vendorItem[vendorItem.length - 1];
      const keys = Object.keys(item);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!item[key]) {
          isInvalid = true;
        }
      });

      if (isInvalid) {
        setBanner({
          isOpen: true,
          title: cms("section.access.message.error.row"),
          status: constant.CRITICAL,
        });
      } else
        vendorItem.push({
          vendorId: "",
        });
      setVendor(vendorItem);
    }
    if (!vendorItem.length) {
      setVendor([{ vendorId: "" }]);
    }
  };

  const removeItem = (id) => {
    const vendorItem = [...vendor];
    vendorItem.splice(id, 1);
    setVendor(vendorItem);
    setSubmitEnable(true);
  };

  const handleChange = (field, value, index) => {
    const vendorItem = [...vendor];
    vendorItem[index][field] = value;
    setVendor([...vendorItem]);
    setSubmitEnable(true);
    setBanner({ isOpen: false, title: "", status: "" });
  };
  const selectVendor = (data, id) => {
    return (
      <>
        <Stack>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={vendorList}
                placeholder={cms("section.access.placeholder.vendor")}
                value={vendor && vendor.length && vendor[id].vendorId}
                onChange={(value) => handleChange(constant.VENDOR_ID, value, id)}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button size="plain" onClick={() => removeItem(id)}>
              <i className="far fa-trash fa-lg" style={{ color: constant.color.RED }} />
            </Button>
          </Stack.Item>
        </Stack>
        <br />
      </>
    );
  };

  return (
    <div>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      {isSelectedVendor &&
        vendor &&
        vendor.map((value, index) => {
          return selectVendor(value, index);
        })}
      {isSelectedVendor && (
        <Link id="addLink" onClick={() => addItem()}>
          {cms("section.access.button.vendor")}
        </Link>
      )}
    </div>
  );
};

export default withErrorBoundary(Add);
