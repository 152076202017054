import React, { useContext, useEffect, useState } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant , helper and components
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import gql
import { GET_NOTIFICATION_SETTING } from "app/userManagement/apollo/queries";
import { UPDATE_NOTIFICATION_SETTING } from "app/userManagement/apollo/mutation";

// import generic
import { CardList, ConfirmationModal, ResetAllButton } from "app/userManagement/modules/generic";

const OperatorNotification = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const cardContent = {
    seller: {
      newOrderSeller: `${cms("label.newOrder")}`,
      packingSlip: `${cms("label.packingSlip")}`,
      productBulkUploadNotification: `${cms("label.bulkProduct")}`,
      productBulkUploadNotificationError: `${cms("label.bulkProductError")}`,
      productsReviewSeller: cms("label.reviewProduct"),
      productsEditSeller: `${cms("label.editProducts")}`,
      productsResubmitSeller: `${cms("label.reSubmitProduct")}`,
      supplierJoined: `${cms("label.vendorJoin")}`,
      supplierAcceptsOrder: `${cms("label.orderAccept")}`,
      supplierRejectsOrder: `${cms("label.orderReject")}`,
      vendorBulkUploadNotification: `${cms("label.bulkVendor")}`,
      vendorBulkUploadNotificationError: `${cms("label.bulkVendorError")}`,
    },
  };

  const isSeller = baseHelper.isSeller(currentUser);
  const labelsList = isSeller && cardContent.seller;
  const resetAll = constant.RESET_ALL;
  const muteAll = constant.MUTE_ALL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mutedLabels, setMutedLabels] = useState([]);
  const [modalLabel, setModalLabel] = useState("");
  const [loadingLabel, setLoadingLabel] = useState("");
  const [refetchLabel, setRefetchLabel] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const { data: notificationSettingData, loading, refetch, networkStatus } = useQuery(GET_NOTIFICATION_SETTING, {
    notifyOnNetworkStatusChange: true,
  });
  const [updateNotificationSetting] = useMutation(UPDATE_NOTIFICATION_SETTING);

  const onOpenModal = (modalLabelProp) => {
    setIsModalOpen(true);
    setModalLabel(modalLabelProp);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setModalLabel("");
  };

  useEffect(() => {
    const responseError = baseHelper.getResponseError(notificationSettingData, constant.gql.GET_NOTIFICATION);
    if (responseError) {
      setBanner({
        title: responseError,
        status: constant.CRITICAL,
        isOpen: true,
      });
      return;
    }
    const responseData = baseHelper.getResponseData(notificationSettingData, constant.gql.GET_NOTIFICATION);
    const { labels = [] } = responseData || {};
    setMutedLabels(labels || []);
  }, [notificationSettingData]);

  const handleClick = (labelData) => {
    setLoadingLabel(labelData);
    const isResetAll = labelData === resetAll;
    const isMuteAll = labelData === muteAll;
    const isMuted = mutedLabels && mutedLabels.includes(labelData);
    let allLabels =
      isMuteAll && mutedLabels ? Object.keys(labelsList).filter((item) => !mutedLabels.includes(item)) : [labelData];
    if (allLabels.indexOf("muteAll") !== -1) {
      const allKeys = Object.keys(cardContent.seller);
      allLabels = allKeys;
    }
    updateNotificationSetting({ variables: { input: { label: allLabels, isMuted, isResetAll } } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_NOTIFICATION);
        if (responseError) {
          setLoadingLabel(false);
          setIsModalOpen(false);
          setBanner({
            title: responseError,
            status: constant.CRITICAL,
            isOpen: true,
          });
          return;
        }

        setRefetchLabel(labelData);
        refetch()
          .then(() => {
            setIsModalOpen(false);
            setRefetchLabel("");
            setLoadingLabel(false);
            setBanner({
              title: `${cms("message.success")}`,
              status: constant.SUCCESS,
              isOpen: true,
            });
          })
          .catch((exception) => {
            setIsModalOpen(false);
            setRefetchLabel("");
            setLoadingLabel(false);
            setBanner({
              status: constant.CRITICAL,
              title: errorHelper.parse(exception),
              isOpen: true,
            });
          });
      })
      .catch((exception) => {
        setIsModalOpen(false);
        setLoadingLabel(false);
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  if (networkStatus !== 4 && loading) {
    return <Spinner />;
  }

  const loadCardList = () => {
    let labelsListData = isSeller && cardContent.seller;
    const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
    if (isEcommerceUser) {
      labelsListData = baseHelper.getAllowedNotificatonSetting(labelsListData);
    }
    return Object.keys(labelsListData).map((labelData) => {
      const isMuted = mutedLabels && mutedLabels.includes(labelData);
      const isLoadingLabel = loadingLabel === labelData || (networkStatus === 4 && refetchLabel === labelData);
      return (
        <CardList
          key={labelData}
          isMuted={isMuted}
          labelData={labelData}
          isLoadingLabel={isLoadingLabel}
          handleClick={handleClick}
          seller={cardContent.seller}
        />
      );
    });
  };
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <ConfirmationModal
        onClose={onCloseModal}
        isOpen={isModalOpen}
        onSubmit={handleClick}
        modalLabel={modalLabel}
        loadingLabel={loadingLabel}
      />
      <Layout.AnnotatedSection title={`${cms("title")}`} description={`${cms("description")}`}>
        <ResetAllButton mutedLabels={mutedLabels} openConfirmationModal={onOpenModal} seller={cardContent.seller} />
        <br />
        {loadCardList()}
      </Layout.AnnotatedSection>
      <Layout.Section>
        <PageActions
          secondaryActions={{
            content: cms("common.button.cancel"),
            onAction: () => history.push("/setting"),
          }}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorNotification), { feature: constant.MUTE_NOTIFICATION });
