import { gql } from "apollo-boost";

const UPDATE_TAX_INFO = gql`
  mutation updateTaxInfo($input: TaxInfo) {
    updateTaxInfo(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_TAX_INFO;
