import React from "react";

import OrderExportSetting from "app/orders/modules/generic/exportSetting/order";

import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const ProviderOrderExportSetting = () => {
  return <OrderExportSetting />;
};
export default withFeature(withErrorBoundary(ProviderOrderExportSetting), {
  feature: constant.ORDER_EXPORT_SETTING,
});
