import React, { useContext } from "react";
import { Caption, Stack, ResourceList, TextStyle } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

// import propType
import { itemProp } from "app/shipping/modules/operator/features/offer/propTypes";

import Actions from "./action";

const OfferItem = (props) => {
  const {
    moneyFormat,
    item,
    bands = [],
    destinations,
    products,
    isVendorAllowed,
    toggleModal,
    toggleDeleteModal,
  } = props;
  const {
    _id: id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    productPrice,
    productQuantity,
    orderValue,
    isCumulative,
  } = item;
  const { cms } = useContext(PrivateContext);

  const isProductPrice = condition === constant.PRODUCT_PRICE;
  const isProductQuantity = condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = condition === constant.ORDER_VALUE;
  const shippingBand = (bands.length > 0 && bands.find((key) => key.value === shippingBandId)) || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);

  const isFreeOption = priceType === constant.FREE;
  const priceTypeText = isFreeOption ? constant.FREE : constant.FIXED;

  const shortcutActions = [
    {
      content: cms("button.edit"),
      onAction: () => toggleModal(id),
      disabled: !isVendorAllowed,
    },
    {
      content: cms("button.delete"),
      destructive: true,
      onAction: () => toggleDeleteModal(id),
      disabled: !isVendorAllowed,
    },
  ];
  return (
    <ResourceList.Item id={id} accessibilityLabel={`${cms("label.viewDetails")} ${shippingBand.label}`}>
      <Stack alignment="leading" wrap={false}>
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{shippingBand.label}</TextStyle>
          </h3>
          <Caption>{description}</Caption>
          <br />
          <Caption>{`${cms("label.destination")}: ${destination ? destination.label : cms("label.all")}`}</Caption>
          <Caption>{`${cms("label.products")}: ${product ? product.label : cms("label.all")}`}</Caption>
          {isProductPrice && (
            <Caption>
              {`${cms("label.productPrice")}: ${
                productPrice.criteria === constant.GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")
              }`}
              {` ${moneyFormat} ${productPrice.value}`}
            </Caption>
          )}
          {isProductQuantity && (
            <Caption>
              {`${cms("label.productQuantity")}: ${
                productQuantity.criteria === constant.GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")
              } ${productQuantity.value}`}
            </Caption>
          )}
          {isOrderValue && (
            <Caption>
              {`${cms("label.orderValue")}: ${
                orderValue.criteria === constant.GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")
              }`}
              {` ${moneyFormat} ${orderValue.value}`}
            </Caption>
          )}
          <Caption>{`${cms("label.priceType")}: ${priceTypeText}`}</Caption>
          <Caption>{`${cms("label.cost")}: ${moneyFormat} ${isFreeOption ? constant.value.MIN_PRICE : price}`}</Caption>
          {!isOrderValue && (
            <Caption>
              {`${cms("label.chargeCumulative")}: ${isCumulative ? cms("label.yes") : cms("label.no")}`}
            </Caption>
          )}
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceList.Item>
  );
};

OfferItem.propTypes = itemProp.type;

export default OfferItem;
