import React from "react";

// import generic
import ExportProductAsCSV from "app/productOld/modules/generic/export/exportProductCSV";

const ExportProducts = () => {
  const getComponent = () => {
    return <ExportProductAsCSV />;
  };
  const ExportProductComponent = getComponent();
  return ExportProductComponent;
};

export default ExportProducts;
