import { gql } from "apollo-boost";

const OLD_TO_NEW_SHOPIFY_INSTALL_SELLER_LOGIN = gql`
  mutation oldToNewShopifyInstallAndSellerLogin($input: oldToNewShopifyInstallAndSellerLoginInput) {
    oldToNewShopifyInstallAndSellerLogin(input: $input) {
      status
      error
      data {
        token
      }
    }
  }
`;

export default OLD_TO_NEW_SHOPIFY_INSTALL_SELLER_LOGIN;
