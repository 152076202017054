import { gql } from "apollo-boost";

const GET_AUTOMATIC_PAYOUTS = gql`
  query getPaymentTransList($input: GetListInput) {
    getPaymentTransList(input: $input) {
      data {
        count
        paymentTransList {
          _id
          currency
          createdAt
          dueDate
          error
          errorMessage
          gateway
          orderId
          orderLineId
          orderNumber
          paymentId
          sellerId
          shippingLineId
          shop
          shopifyOrderId
          status
          vendor
          vendorId
        }
      }
      status
      error
    }
  }
`;

export default GET_AUTOMATIC_PAYOUTS;
