import React, { useContext } from "react";
import ProductLabel from "app/setup/modules/generic/productFormLabel/productFormLabel";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { provider, operator } = userKey;

const ProductFormLabel = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === provider) {
      return <ProductLabel />;
    }
    if (role === operator) {
      return <ProductLabel />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const productLabelComponent = getComponent(currentUserRole);
  return productLabelComponent;
};

export default withErrorBoundary(ProductFormLabel);
