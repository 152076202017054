import React, { useState, useContext } from "react";
import { DisplayText, TextField, Select, Checkbox, Modal, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import component
import { Banner } from "lib/components";
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import mutations
import { EDIT_OFFER } from "app/shipping/apollo/mutations";

import { editModalProp } from "app/shipping/modules/operator/features/offer/propTypes";

import { commonValidation } from "./subFeatureItems/offerHelper";

const EditOffer = (props) => {
  const {
    shippingOffer: shippingOfferProp,
    onClose,
    show = false,
    bands = [],
    destinations = [],
    products = [],
    handleBanner,
    refetch,
  } = props || {};
  const { currentUser, cms } = useContext(PrivateContext);

  const defaultBanner = {
    isOpen: false,
    status: "",
    title: "",
  };
  const [shippingOffer, setShippingOffer] = useState(shippingOfferProp);
  const [banner, setBanner] = useState({ ...defaultBanner });
  const [updateOffer, { loading }] = useMutation(EDIT_OFFER);
  const closeModal = () => {
    setBanner({ ...defaultBanner });
    onClose();
  };

  const prepareRequestData = () => {
    const { _id: id, price, description, priceType, isCumulative } = shippingOffer;
    const reqData = {
      id,
      description,
      price: (priceType && priceType === constant.FIXED && price) || 0,
      priceType,
      isCumulative,
    };
    return reqData;
  };

  const editShippingBand = async () => {
    const requestData = prepareRequestData();
    try {
      const response = await updateOffer({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_SHIPPING_OFFER);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      handleBanner({ isOpen: true, status: "success", title: cms("modal.offer.edit.message.shippingOfferEdited") });
      closeModal();
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const onSubmit = () => {
    const { price, description, priceType } = shippingOffer || {};
    const hasError = commonValidation(price, description, priceType, cms);
    if (hasError) {
      setBanner({ isOpen: true, status: "critical", title: hasError });
      return false;
    }
    editShippingBand();
    return true;
  };

  const toggleBanner = () => setBanner({ ...defaultBanner });

  const handleShippingOffer = (key, value) => {
    setShippingOffer({ ...shippingOffer, [key]: value });
  };
  const toggleCumulativeShipping = () => {
    const toggleCumulative = !shippingOffer.isCumulative;
    setShippingOffer((prevState) => ({
      ...prevState,
      isCumulative: toggleCumulative,
    }));
  };

  const {
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productQuantity,
    productPrice,
    orderValue,
    productId,
    priceType,
    isCumulative = false,
  } = shippingOffer || {};
  shippingOffer.price = parseFloat(price);

  const { moneyFormat = constant.symbol.DOLLAR } = currentUser || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((item) => item.value === destinationCode);
  const product = products.find((item) => item.value === productId);
  const shippingBand = bands.find((band) => band.value === shippingBandId) || {};

  const isProductPrice = condition === constant.PRODUCT_PRICE;
  const isProductQuantity = condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = condition === constant.ORDER_VALUE;

  const criteriaValues =
    (isProductPrice && { ...productPrice }) ||
    (isProductQuantity && { ...productQuantity }) ||
    (isOrderValue && { ...orderValue }) ||
    {};

  const criteriaText =
    (isProductPrice && cms("label.priceBe")) ||
    (isProductQuantity && cms("label.quantityBe")) ||
    cms("label.orderValue");

  const priceTypeOptions = [
    {
      label: cms("label.fixed"),
      value: constant.FIXED,
    },
    {
      label: cms("label.freeShipping"),
      value: constant.FREE,
    },
  ];
  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={cms("modal.offer.edit.button.editOffer")}
      primaryAction={{
        content: cms("modal.offer.edit.button.update"),
        loading,
        onAction: () => onSubmit(),
      }}
    >
      <Modal.Section>
        <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        <br />
        <form>
          <Stack vertical>
            <Stack.Item>
              <DisplayText size={constant.SMALL}>{`${cms("label.shippingBand")}: ${shippingBand.label}`}</DisplayText>
            </Stack.Item>
            <Stack.Item>
              <Stack vertical>
                <Stack.Item>
                  <DisplayText size={constant.SMALL}>
                    {`${cms("label.destination")}: ${destination ? destination.label : cms("label.all")}`}
                  </DisplayText>
                </Stack.Item>
                <Stack.Item>
                  <DisplayText size={constant.SMALL}>
                    {`${cms("label.product")}: ${product ? product.label : cms("label.all")}`}
                  </DisplayText>
                </Stack.Item>
                {(isProductPrice || isProductQuantity || isOrderValue) && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${cms("label.criteria")}: `}
                      {`${criteriaText} `}
                      {`${
                        criteriaValues.criteria === constant.GREATER_THAN
                          ? cms("label.greaterThan")
                          : cms("label.lessThan")
                      } `}
                      {isProductPrice || isOrderValue ? moneyFormat : ""}
                      {criteriaValues.value}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id={constant.INPUT_ID_DESCRIPTION}
                type={constant.TEXT}
                label={`${cms("placeholder.description")}*`}
                placeholder={cms("placeholder.description")}
                value={description}
                onChange={(val) => handleShippingOffer(constant.DESCRIPTION, val)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    options={priceTypeOptions}
                    id={constant.INPUT_ID_TYPE}
                    label={cms("modal.rule.add.label.selectShippingType")}
                    name={constant.SHIPPING_TYPE}
                    placeholder={cms("modal.rule.add.placeholder.selectShipping")}
                    value={priceType || ""}
                    onChange={(val) => handleShippingOffer(constant.PRICE_TYPE, val)}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      id={constant.INPUT_ID_COST}
                      label={`${cms("modal.rule.add.label.amount")}*`}
                      min={constant.value.MIN_PRICE}
                      step={constant.value.STEP}
                      value={price.toString() || ""}
                      onChange={(val) => handleShippingOffer(constant.PRICE, acceptOnlyValidInput(val), price)}
                      prefix={moneyFormat}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            {!isOrderValue && (
              <Stack.Item>
                <Checkbox
                  checked={isCumulative}
                  onChange={toggleCumulativeShipping}
                  label={cms("label.chargeCumulative")}
                />
              </Stack.Item>
            )}
          </Stack>
        </form>
      </Modal.Section>
    </Modal>
  );
};

EditOffer.propTypes = editModalProp.type;

export default EditOffer;
