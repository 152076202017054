// import constant
import constant from "lib/constant/constant";

const editData = (cms, isVariant, isAttachment, isMetafield, isCampaign) => {
  const filterTab = [
    {
      id: "information",
      content: cms("label.information"),
      panelID: "information-content",
    },
  ];
  if (!isVariant || isCampaign) {
    filterTab.push({
      id: "inventory",
      content: cms("label.inventory"),
      panelID: "inventory-content",
    });
  }
  filterTab.push(
    {
      id: "image",
      content: cms("label.image"),
      panelID: "image-content",
    },
    {
      id: "variant",
      content: cms("label.variant"),
      panelID: "variant-content",
    }
  );
  if (!isVariant) {
    filterTab.push({
      id: "fulfillment",
      content: cms("label.fulfillment"),
      panelID: "fulfillment-content",
    });
  }
  if (isMetafield) {
    filterTab.push({
      id: "metafield",
      content: cms("label.metafieldPlural"),
      panelID: "metafield-content",
    });
  }
  if (isAttachment) {
    filterTab.push({
      id: "attachment",
      content: cms("label.attachment"),
      panelID: "attachment-content",
    });
  }
  return {
    option: {
      option1: "option1",
      option2: "option2",
      option3: "option3",
    },
    policyOption: [
      {
        label: cms("label.notTrack"),
        value: "Don't track inventory",
      },
      {
        label: cms("label.shopify"),
        value: "shopify",
      },
    ],
    tabs: filterTab,
    typeOption: [
      {
        label: cms("common.label.flat"),
        value: constant.FLAT,
      },
      {
        label: cms("common.label.percentage"),
        value: constant.PERCENTAGE,
      },
    ],
  };
};

export default editData;
