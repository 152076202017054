import React, { useState, useEffect, useContext, useCallback } from "react";
import { Banner, ChoiceList, Modal, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";

const DeleteProduct = (props) => {
  const {
    bulkAction,
    cms,
    deleteSelected,
    id = false,
    loadingBulkProduct,
    openDeleteModal = false,
    selectedItems,
    setOpenDeleteModal,
  } = props;

  const [reason, setReason] = useState(null);
  const { currentUser } = useContext(PrivateContext);
  const [selectedDeleteFrom, setSelectedDeleteFrom] = useState(deleteSelected);
  const isVendor = baseHelper.isVendor(currentUser);

  useEffect(() => {
    if (openDeleteModal) {
      setReason(null);
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if (deleteSelected) {
      setSelectedDeleteFrom(deleteSelected);
    }
  }, [deleteSelected]);

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
    setReason(null);
  };

  const handleReasonChange = (value) => {
    setReason(value);
  };

  const handleSelectedExportAs = useCallback((value) => setSelectedDeleteFrom(value), []);

  const handleClose = () => {
    handleCloseDelete();
    handleSelectedExportAs([constant.DELETE_ECOMMERCE]);
  };

  const productLabel = selectedItems.length > 1 ? cms("label.productPlural") : cms("label.product");

  return (
    <>
      {isVendor && (
        <Modal
          open={openDeleteModal}
          onClose={() => handleCloseDelete()}
          title={cms("section.delete.label.delete")}
          primaryAction={{
            content: cms("section.delete.label.delete"),
            onAction: () => bulkAction(constant.DELETE.toLowerCase(), true, id, reason, constant.DELETE_ECOMMERCE),
            destructive: true,
            loading: loadingBulkProduct,
          }}
        >
          <Modal.Section>
            <Banner
              status={constant.CRITICAL}
              title={` ${cms("section.delete.message.about")} ${selectedItems.length || 1} ${productLabel}. 
${cms("section.delete.message.vendor")}`}
            />
            <TextField
              label={cms("section.delete.label.reason")}
              value={reason}
              onChange={handleReasonChange}
              multiline={2}
            />
          </Modal.Section>
        </Modal>
      )}
      {!isVendor && (
        <Modal
          open={openDeleteModal}
          onClose={() => handleClose()}
          title={cms("section.delete.label.delete")}
          secondaryActions={[
            {
              content: cms("section.delete.label.delete"),
              destructive: true,
              onAction: () => {
                bulkAction(
                  constant.DELETE.toLowerCase(),
                  selectedDeleteFrom[0] === constant.DELETE_ECOMMERCE,
                  id,
                  reason,
                  selectedDeleteFrom[0]
                );
              },
              loading: loadingBulkProduct,
            },
          ]}
        >
          <Modal.Section>
            <Banner
              isOpen
              status={constant.CRITICAL}
              title={`${cms("section.delete.message.about")} ${selectedItems.length || 1} ${productLabel}. 
                ${cms("section.delete.message.this")}`}
            />
            <br />
            <ChoiceList
              choices={[
                {
                  label: cms("section.delete.choiceList.woocommerce"),
                  value: constant.DELETE_ECOMMERCE,
                },
                {
                  label: cms("section.delete.choiceList.marketCube"),
                  value: constant.DELETE_MARKET,
                },
              ]}
              selected={selectedDeleteFrom}
              onChange={handleSelectedExportAs}
            />
            <TextField
              label={cms("section.delete.label.reason")}
              value={reason}
              onChange={handleReasonChange}
              multiline={2}
            />
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

DeleteProduct.defaultProps = {
  cms: () => {},
  openDeleteModal: false,
  id: "",
  setOpenDeleteModal: () => {},
  bulkAction: () => {},
  loadingBulkProduct: false,
  deleteSelected: () => {},
};

DeleteProduct.propTypes = {
  cms: PropTypes.func,
  openDeleteModal: PropTypes.bool,
  id: PropTypes.string,
  setOpenDeleteModal: PropTypes.func,
  bulkAction: PropTypes.func,
  loadingBulkProduct: PropTypes.bool,
  deleteSelected: PropTypes.func,
};

export default withErrorBoundary(DeleteProduct);
