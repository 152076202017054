const env = process.env.REACT_APP_ENV || "dev";

const chargeBeeSiteBigCommerece = {
  prod: "bigcommerce-mc",
  automation: "bigcommerce-mc-test",
  beta: "bigcommerce-mc-test",
  dev: "bigcommerce-mc-test",
  local: "bigcommerce-mc-test",
  test: "bigcommerce-mc-test",
  uat: "bigcommerce-mc-test",
};

const chargeBeeSite = {
  automation: "marketcube-automation-test",
  beta: "marketcube-beta-test",
  dev: "marketcube-dev-test",
  local: "marketcube-local-test",
  prod: "marketcube",
  test: "marketcube-qa-test",
  uat: "marketcube-uat-test",
};

const stripePortal = {
  automation: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
  beta: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
  dev: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
  local: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
  prod: "https://billing.marketcube.io/p/login/3cs8A224g5fb3Cw144",
  test: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
  uat: "https://billing.marketcube.io/p/login/test_aEU8xe7U24ez0b6aEE",
}

const stripePublishKey = {
  automation: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
  beta: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
  dev: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
  local: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
  prod: "pk_live_GwKDRFhzXxdu41f9rxKNu5fs",
  test: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
  uat: "pk_test_O7LbmEtXyUOoI9isY6iBZhIt",
};

const localizeAPIKey = {
  automation: "",
  beta: "",
  dev: "",
  local: "",
  prod: "u1fIWCzdvmwKs",
  test: "e50a8b1b8dcf8",
  uat: "e50a8b1b8dcf8",
};

const newZeaLandPostAPIUrl = {
  automation: "https://api.uat.nzpost.co.nz/",
  beta: "https://api.uat.nzpost.co.nz/",
  dev: "https://api.uat.nzpost.co.nz/",
  local: "https://api.uat.nzpost.co.nz/",
  prod: "https://api.nzpost.co.nz/",
  test: "https://api.uat.nzpost.co.nz/",
  uat: "https://api.uat.nzpost.co.nz/",
};

const paasConfig = {
  automation: true,
  beta: true,
  dev: true,
  local: true,
  prod: false,
  test: true,
  uat: false,
};

const shopifyAPIKey = {
  // NOTE: update local key, as per your app
  local: "",
  automation: "9163e5980873f00e77a338f6030cab43",
  beta: "f29202d00091705303bf2eea566c5a00",
  dev: "1bb69dca90a4906816efa1ea1600cac1",
  prod: "5baaa204176fd5136e83a61d93eeeb48",
  test: "b3d6f19f293fefcd1f167c663b00a8bc",
  uat: "b9490f51e9ab32e3e2593d3ae306bf77",
};

const bigCommerceMarketcubeAppId = {
  local: "34545",
  automation: "",
  beta: "",
  dev: "35396",
  prod: "36330",
  test: "35883",
  uat: "36216",
}

const beaconLiveChatAPIKey = {
  local: "",
  automation: "e47d3176-cde5-4b6d-8811-d7fe304e6cf6",
  beta: "e47d3176-cde5-4b6d-8811-d7fe304e6cf6",
  dev: "e47d3176-cde5-4b6d-8811-d7fe304e6cf6",
  prod: "994fdfae-d501-4edb-bc4f-59481783aca7",
  test: "e47d3176-cde5-4b6d-8811-d7fe304e6cf6",
  uat: "e47d3176-cde5-4b6d-8811-d7fe304e6cf6",
};

const supportEmail = {
  automation: "mc-support@mailinator.com",
  beta: "mc-support@mailinator.com",
  dev: "support@yopmail.com",
  local: "support@yopmail.com",
  prod: "support@marketcube.io",
  test: "mc-support@mailinator.com",
  uat: "mc-support@mailinator.com",
};

const shopifyAppID = {
  prod: "1545827",
  local: "1545827",
  uat: "1079771137",
};

const vendorShopifyAPIKey = {
  // NOTE: update local key, as per your app
  local: "",
  automation: "235920931c53728ebbbc8a253a3376ab",
  beta: "f44cae075f8aa9f99e0c1393314778f7",
  dev: "26fc860d3788fe34737497c09fe4525f",
  prod: "e8b4d1ed32048994edf7afade78cc612",
  test: "74a72d521cc836f097165df2c9d2938b",
  uat: "917eb66a2e45f9fa8b617075742feeee",
};

const vendorWixAPIKey = {
  // NOTE: update local key, as per your app
  local: "d122ba0d-9a0d-43bd-91fe-eb30d80c095e",
  automation: "13cebcf9-d806-4005-ba1a-21bae5ee0d86",
  beta: "39679c81-da11-418b-ad0e-ae01c3ce7fda",
  dev: "39679c81-da11-418b-ad0e-ae01c3ce7fda",
  prod: "b748e4a6-ba61-4d48-a3a4-7f4d334e68e2",
  test: "13cebcf9-d806-4005-ba1a-21bae5ee0d86",
  uat: "13cebcf9-d806-4005-ba1a-21bae5ee0d86",
};

const vendorWixShareUrl = {
  // NOTE: update local share url, as per your app
  local: "http://wix.to/GcBtDNM",
  automation: "http://wix.to/60C5CLE",
  beta: "http://wix.to/-8BvDTM",
  dev: "http://wix.to/-8BvDTM",
  prod: "http://wix.to/bUCLCHM",
  test: "http://wix.to/60C5CLE",
  uat: "http://wix.to/60C5CLE",
};

const config = {
  apiURL: process.env.REACT_APP_GQL_URL || "http://localhost:5000/graphql", // apiURL[env],
  beaconLiveChatAPIKey: beaconLiveChatAPIKey[env],
  brandPageURL: process.env.REACT_APP_BRAND_PAGE_URL || "http://localhost:4000",
  brandPageV2URL: process.env.REACT_APP_BRAND_PAGE_V2_URL || "http://localhost:4001",
  chargeBeeSite: chargeBeeSite[env],
  bigCommerceMarketcubeAppId: bigCommerceMarketcubeAppId[env],
  chargeBeeSiteBigCommerece: chargeBeeSiteBigCommerece[env],
  localizeAPIKey: localizeAPIKey[env],
  stripePublishKey: (environment = env) => stripePublishKey[environment],
  stripePortal: (environment = env) => stripePortal[environment],
  muteChatIntegration: true,
  newZeaLandPostAPIUrl: newZeaLandPostAPIUrl[env],
  isPAASEnable: paasConfig[env],
  isProd: env === "prod",
  paasUserId: (process.env.REACT_APP_PAAS_USER_ID && process.env.REACT_APP_PAAS_USER_ID.split(",")) || [],
  rootURL: process.env.REACT_APP_ROOT_URL || "http://localhost:3000", // rootURL[env]
  rotaroUserId: (process.env.REACT_APP_ROTARO_USER_ID && process.env.REACT_APP_ROTARO_USER_ID.split(",")) || [],
  shopifyAPIKey: shopifyAPIKey[env],
  supportEmail: supportEmail[env],
  shopifyAppID: shopifyAppID[env],
  tinyAPIKey: "p93n47i1ddh84c4i7262tzpqyfbwadt2xotion4eprwbh0b4",
  vendorShopifyAPIKey: vendorShopifyAPIKey[env],
  vendorWixAPIKey: vendorWixAPIKey[env],
  vendorWixShareUrl: vendorWixShareUrl[env],
  webSocketUrl: process.env.REACT_APP_SOCKET_URL || "wss://localhost:5000/graphql",
};

// Debugging of server not working
// eslint-disable-next-line no-console
// console.log(config, process.env.REACT_APP_ENV, process.env.REACT_APP_ROOT_URL, process.env.REACT_APP_GQL_URL);

export default config;
