import React, { useContext } from "react";
import { FooterHelp, Link, SkeletonBodyText } from "@shopify/polaris";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";

const Footer = (props) => {
  const { footerText } = props;
  const { history, cms, isLoading } = useContext(PrivateContext);

  if (isLoading) {
    return null;
  }

  const getFooterHTML = (text) => {
    const updatedText = text.replace(/{{here}}/i, `<a href="/contact-us">${cms("common.label.here")}</a>`);
    return `<div>${updatedText}</div>`;
  };

  if (storageHelper.get("setupProgress")) {
    return null;
  }

  if (footerText && footerText !== "" && footerText !== "default") {
    return <FooterHelp>{ReactHtmlParser(getFooterHTML(footerText))}</FooterHelp>;
  }

  // title={title ? (isLoading && <SkeletonBodyText lines={1} />) || cms(`common.pageTitle.${title}`) : getPageTitle()}
  return (
    <FooterHelp>
      {`${(isLoading && <SkeletonBodyText lines={1} />) || cms("common.contact.description")} `}
      <Link onClick={() => history.push("/contact-us")}>
        {(isLoading && <SkeletonBodyText lines={1} />) || cms("common.contact.link")}
      </Link>
    </FooterHelp>
  );
};

Footer.propTypes = {
  footerText: PropTypes.string,
};

Footer.defaultProps = {
  footerText: "",
};

export default Footer;
