import { gql } from "apollo-boost";

const GET_PRODUCT_REPORT_SETTING = gql`
  query getProductReportSetting {
    getProductReportSetting {
      data {
        report {
          title {
            label
            isHideCsv
            isHidePdf
          }
          sku {
            label
            isHideCsv
            isHidePdf
          }
          productCost {
            label
            isHideCsv
            isHidePdf
          }
          productType {
            label
            isHideCsv
            isHidePdf
          }
          vendor {
            label
            isHideCsv
            isHidePdf
          }
          price {
            label
            isHideCsv
            isHidePdf
          }
          markup {
            label
            isHideCsv
            isHidePdf
          }
          customerProductPrice {
            label
            isHideCsv
            isHidePdf
          }
          discount {
            label
            isHideCsv
            isHidePdf
          }
          tax {
            label
            isHideCsv
            isHidePdf
          }
        }
        updatedAt
      }
      error
      status
    }
  }
`;
export default GET_PRODUCT_REPORT_SETTING;
