import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";

import {
  GET_ORDER_REPORT_SETTING,
  GET_PRODUCT_REPORT_SETTING,
  GET_PAYMENT_REPORT_SETTING,
  GET_REPORT_DATA,
} from "app/setup/apollo/queries";

import reportHelper from "app/dashboard/modules/reportHelper";

const ExportReport = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [
    getReportLines,
    { data: reportData, error: getOrderError, loading: getOrderLoading },
  ] = useLazyQuery(GET_REPORT_DATA, { variables: { input: queryData } });

  const {
    data: getOrderReportSettingData,
    error: getOrderReportSettingError,
    loading: getOrderReportSettingLoading,
  } = useQuery(GET_ORDER_REPORT_SETTING);

  const {
    data: getProductReportSettingData,
    error: getProductReportSettingError,
    loading: getProductReportSettingLoading,
  } = useQuery(GET_PRODUCT_REPORT_SETTING);

  const {
    data: getPaymentReportSettingData,
    error: getPaymentReportSettingError,
    loading: getPaymentReportSettingLoading,
  } = useQuery(GET_PAYMENT_REPORT_SETTING);

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    const isLoading =
      getOrderReportSettingLoading ||
      getProductReportSettingLoading ||
      getPaymentReportSettingLoading ||
      getOrderLoading;

    if (!isLoading && !getOrderLoading && reportData && getOrderReportSettingData) {
      const resData = baseHelper.getResponseData(reportData, constant.gql.GET_REPORT_DATA);
      const orderReportSettingResponse = baseHelper.getResponseData(
        getOrderReportSettingData,
        constant.gql.GET_ORDER_REPORT_SETTING
      );
      const paymentReportSettingResponse = baseHelper.getResponseData(
        getPaymentReportSettingData,
        constant.gql.GET_PAYMENT_REPORT_SETTING
      );
      const productReportSettingResponse = baseHelper.getResponseData(
        getProductReportSettingData,
        constant.gql.GET_PRODUCT_REPORT_SETTING
      );

      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(reportData, constant.GET_REPORT_LINES);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (getOrderReportSettingError) {
        bannerData = {
          title: getOrderReportSettingError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (getProductReportSettingError) {
        bannerData = {
          title: getProductReportSettingError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (getPaymentReportSettingError) {
        bannerData = {
          title: getPaymentReportSettingError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (!resData.length) {
        bannerData = {
          title: cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }

      const csvData = reportHelper.reportCSVData(
        resData,
        orderReportSettingResponse,
        paymentReportSettingResponse,
        productReportSettingResponse,
        true,
        false,
        isVendor
      );
      const csv = Baby.unparse(csvData);
      const date = Moment(new Date(), constant.FORMATDATE).format(constant.LL);
      FileDownload(csv, `${constant.CONSOLIDATED_REPORT} ${date}.csv`);
      setTimeout(() => {
        window.close();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportData,
    getOrderError,
    getOrderLoading,
    cms,
    getOrderReportSettingData,
    getPaymentReportSettingData,
    getProductReportSettingData,
    isVendor,
  ]);

  useEffect(() => {
    const exportOrder = () => {
      const dataToFetch = {};
      const { status, fulfillmentStatus, vendor, accountingStatus } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      setQueryData(dataToFetch);
      getReportLines();
    };
    if (isPageLoading) {
      exportOrder();
      setIsPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getReportLines]);

  return (
    <Layout>
      {getOrderLoading && <Spinner isFullPage />}
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ExportReport), { feature: constant.EXPORT_REPORT });
