import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  ActionList,
  Button,
  Modal,
  Popover,
  RadioButton,
  ResourceList,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { CHECK_ORDER_LAKE_LINES, GET_ORDER_LAKE_LINE } from "app/orderLake/apollo/queries";
import operatorExportOrderLakeProps from "./props";

const OperatorExportOrderLake = (props) => {
  const { isOpenModal, setIsOpenModal } = props;
  const { cms } = useContext(PrivateContext);
  const { loading: getOrderLineLoading, data: getOrderLineData } = useQuery(GET_ORDER_LAKE_LINE);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([[], []]);
  const [vendorList, setVendorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [exportAll, setExportAll] = useState(true);
  // const [isPrimaryActionLoading, setPrimaryAction] = useState(false);
  const [exportLink, setExportLink] = useState(false);
  const [exportPDFLink, setExportPDFLink] = useState(false);
  const [generate, setGenerate] = useState(false);
  // const [isModalClickable, setIsModalClickable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState("");
  const [queryData, setQueryData] = useState({});
  const dataToFetch = {};
  const exportOrderUrl = "/export/order-lake";
  const [
    getCheckOrderList,
    { loading: checkOrderLineLoading, data: checkOrderLineData },
  ] = useLazyQuery(CHECK_ORDER_LAKE_LINES, { variables: { input: { ...queryData } } });

  useEffect(() => {
    let bannerData = false;
    if (!checkOrderLineLoading && checkOrderLineData && generate) {
      const responseData = baseHelper.getResponseData(checkOrderLineData, constant.gql.CHECK_ORDER_LAKE_LINES);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkOrderLineData, constant.gql.CHECK_ORDER_LAKE_LINES) ||
            cms("message.error.noOrder"),
          status: constant.CRITICAL,
        };
      }

      if (bannerData) {
        // setPrimaryAction(false);
        setBanner({ ...bannerData, isOpen: true });
      } else {
        const csvDownloadRoute = (exportAll && exportOrderUrl) || `${exportOrderUrl}${params}`;
        const pdfDownloadRoute = (exportAll && `${exportOrderUrl}/pdf`) || `${exportOrderUrl}/pdf${params}`;
        // setPrimaryAction(false);
        setExportLink(csvDownloadRoute);
        setExportPDFLink(pdfDownloadRoute);
      }
      setLoading(false);
    }
  }, [checkOrderLineLoading, checkOrderLineData, exportAll, params, cms, generate]);

  useEffect(() => {
    if (!getOrderLineLoading || getOrderLineData) {
      const orderLines = baseHelper.getResponseData(getOrderLineData, constant.gql.GET_ORDER_LAKE_LINE);
      if (!orderLines) {
        const bannerValue = {
          status: constant.CRITICAL,
          title: baseHelper.getResponseError(getOrderLineData, constant.gql.GET_ORDER_LAKE_LINE),
        };
        setBanner({ ...bannerValue, isOpen: true });
      }
      // if (!orderLines.length) {
      //   setIsModalClickable(false);
      //   return;
      // }
      let vendorData = [];
      let statusData = [];
      const fulfillmentStatusData = [];
      const orderStatusData = [];
      orderLines.forEach(({ storeStatus: status, vendor }) => {
        statusData.push(
          // {
          //   id: fStatus,
          //   name: fStatus,
          // },
          {
            id: status,
            name: status,
          }
        );
        // fulfillmentStatusData.push(fStatus);
        orderStatusData.push(status);

        vendor.forEach(({ _id, brandName }) => {
          vendorData.push({
            id: _id,
            name: brandName,
          });
        });
      });

      vendorData = baseHelper.removeDuplicateData(vendorData);
      statusData = baseHelper.removeDuplicateData(statusData);
      vendorData = [...baseHelper.sort(vendorData, constant.NAME)];
      statusData = [...baseHelper.sort(statusData, constant.NAME)];
      setStatusList(statusData);
      setVendorList(vendorData);
      setFulfillmentStatus(fulfillmentStatusData);
      setOrderStatus(orderStatusData);
    }
  }, [getOrderLineData, getOrderLineLoading]);

  const exportOrder = {
    label: {
      resourceName: [
        { singular: cms("section.exportOrder.label.vendor"), plural: cms("section.exportOrder.label.vendors") },
        { singular: cms("section.exportOrder.label.status"), plural: cms("section.exportOrder.label.statuses") },
      ],
    },
    exportModalTabs: [
      {
        id: "vendors",
        content: cms("section.exportOrder.label.vendor"),
        panelID: "panel-vendors",
      },
      {
        id: "status",
        content: cms("section.exportOrder.label.status"),
        panelID: "panel-status",
      },
    ],
  };

  const onExport = (isSpecificExport = false) => {
    setLoading(true);
    setGenerate(true);
    // setPrimaryAction(true);
    let bannerData = false;
    let status = [];
    let vendor = [];
    let fulfillmentStatusNew = [];
    let orderStatusNew = [];

    vendor = [...selectedOptions[0]];
    status = [...selectedOptions[1]];
    fulfillmentStatusNew = status.filter((item) => item && fulfillmentStatus.includes(item));
    orderStatusNew = status.filter((item) => item && orderStatus.includes(item));
    const fulfillmentString = fulfillmentStatusNew.join(",");
    const orderString = orderStatusNew.join(",");
    const vendorString = vendor.join(",");
    if (!(exportAll || vendorString || fulfillmentString || orderString)) {
      setLoading(false);
      bannerData = {
        title: cms("message.error.pleaseSelect"),
        status: constant.CRITICAL,
      };
      // setPrimaryAction(false);
      setBanner({ ...bannerData, isOpen: true });
      return;
    }
    if (isSpecificExport) {
      const updatedSelectedOptions = baseHelper.cloneData(selectedOptions);
      if (!selectedTabIndex) {
        setParams(`?vendor=${vendorString}`);
        updatedSelectedOptions[1] = [];
        orderStatusNew = [];
        fulfillmentStatusNew = [];
      } else {
        setParams(`?storeStatus=${orderString}&fulfillmentStatus=${fulfillmentString}`);
        updatedSelectedOptions[0] = [];
        vendor = [];
      }
      setSelectedOptions(updatedSelectedOptions);
    }
    setParams(`?vendor=${vendorString}&storeStatus=${orderString}&fulfillmentStatus=${fulfillmentString}`);
    if (orderStatusNew.length > 0) {
      dataToFetch.storeStatus = orderStatusNew;
    }
    if (fulfillmentStatusNew.length > 0) {
      dataToFetch.fulfillmentStatus = fulfillmentStatusNew;
    }
    if (vendor.length > 0) {
      dataToFetch.vendor = vendor;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
    setExportLink(false);
    setExportPDFLink(false);
  };

  const toggleBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(exportOrder.exportModalTabs);
    const singularItem = cms("section.exportOrder.label.item");
    const pluralItem = cms("section.exportOrder.label.items");
    const changeIndex = 0;
    selectedOptions.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${cms("section.exportOrder.label.Selected")})`;
      }
    });
    return exportModalTabs;
  };
  const closeModal = () => {
    setSelectedTabIndex(0);
    setSelectedOptions([[], []]);
    setExportAll(true);
    // setPrimaryAction(false);
    setExportLink(false);
    setExportPDFLink(false);
    setIsOpenModal(false);
    setLoading(false);
    toggleBanner();
    setGenerate(false);
  };

  const downloadCSV = () => {
    window.open(exportLink);
    closeModal();
  };

  const downloadPDF = () => {
    window.open(exportPDFLink);
    closeModal();
  };

  const handleSelectionChange = (selectedItems) => {
    const prevSelectedOptions = [...selectedOptions];
    prevSelectedOptions[selectedTabIndex] = [...selectedItems];
    setSelectedOptions(prevSelectedOptions);
    setExportLink(false);
    setExportPDFLink(false);
    setLoading(false);
  };

  const handleTabChange = (selectedTabInde) => {
    setSelectedTabIndex(selectedTabInde);
    setExportLink(false);
    setExportPDFLink(false);
  };

  const items = [vendorList, statusList];
  const updateResourceName = [...exportOrder.label.resourceName];

  const promotedBulkActions = [
    {
      content: cms("section.exportOrder.label.generate"),
      onAction: () => onExport(true),
    },
  ];
  const [active, setActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const handleChange = () => {
    setActive(!active);
    setPopoverActive(!popoverActive);
  };

  const activator = (
    <Button onClick={handleChange} disclosure>
      {cms("section.exportOrder.label.selectFormat")}
    </Button>
  );
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);

  const handlePopover = () => {
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList
          items={[
            {
              content: cms("section.exportOrder.label.exportCSV"),
              onAction: () => {
                setLoading(false);
                downloadCSV();
              },
            },
            {
              content: cms("section.exportOrder.label.exportPDF"),
              onAction: () => {
                setLoading(false);
                downloadPDF();
              },
            },
          ]}
        />
      </Popover>
    );
  };

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        {/* 
         <Link onClick={() => history.push("/setting/order/export")}>{cms("section.exportOrder.label.customize")}
         </Link>
        */}
      </Stack.Item>
      <Stack.Item>
        <Button onClick={() => closeModal()}>{cms("section.exportOrder.label.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        {exportLink ? (
          handlePopover()
        ) : (
          <Button primary onClick={() => onExport()} loading={loading}>
            {cms("section.exportOrder.label.generateCSV")}
          </Button>
        )}
      </Stack.Item>
    </Stack>
  );
  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={() => closeModal()}
        title={cms("section.exportOrder.label.exportOrder")}
        footer={actions()}
      >
        <Modal.Section>
          {banner.isOpen && (
            <Banner
              key="banner"
              onDismiss={toggleBanner}
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
            />
          )}
          <Stack vertical>
            <RadioButton
              label={cms("section.exportOrder.label.exportAll")}
              helpText={cms("section.exportOrder.label.exportAllText")}
              checked={exportAll}
              name="allOrders"
              onChange={() => {
                setGenerate(false);
                setBanner({ isOpen: false });
                setExportAll((prevState) => !prevState);
                setExportPDFLink(false);
                setExportLink(false);
              }}
            />
            <RadioButton
              label={cms("section.exportOrder.label.exportSelected")}
              helpText={cms("section.exportOrder.label.exportSelectedText")}
              name="selectedOrders"
              checked={!exportAll}
              onChange={() => {
                setGenerate(false);
                setExportAll((prevState) => !prevState);
                setExportPDFLink(false);
                setBanner({ isOpen: false });
                setExportLink(false);
              }}
            />
          </Stack>
          {!exportAll && (
            <>
              <Tabs
                key="tabs"
                tabs={getResourceListTabs()}
                selected={selectedTabIndex}
                onSelect={handleTabChange}
                fitted
              >
                <ResourceList
                  key="resourceList"
                  resourceName={updateResourceName[selectedTabIndex]}
                  items={items[selectedTabIndex]}
                  renderItem={(item) => {
                    const { id, name, location } = item;
                    const replaceName = {
                      noShow: constant.NOSHOW,
                    };
                    const statusName = name.replace(/\b(?:noShow)\b/gi, (matched) => replaceName[matched]);
                    return (
                      <ResourceList.Item id={id} accessibilityLabel={`${cms("section.export.label.view")} ${id}`}>
                        <h3>
                          <TextStyle variation="strong">{baseHelper.ucFirst(statusName)}</TextStyle>
                        </h3>
                        <div>{location}</div>
                      </ResourceList.Item>
                    );
                  }}
                  selectedItems={selectedOptions[selectedTabIndex]}
                  onSelectionChange={handleSelectionChange}
                  selectable
                  promotedBulkActions={promotedBulkActions}
                  loading={getOrderLineLoading}
                />
              </Tabs>
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

OperatorExportOrderLake.propTypes = operatorExportOrderLakeProps.type;

export default OperatorExportOrderLake;
