import { gql } from "apollo-boost";

const GET_SELLER_BRANDING = gql`
  query getSellerBranding {
    getSellerBranding {
      data {
        brandName
        logo
      }
      status
      error
    }
  }
`;

export default GET_SELLER_BRANDING;
