/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  // Badge,
  Banner,
  Caption,
  Card,
  FormLayout,
  PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withFeature } from "lib/hoc";

// import mutation
import { EDIT_PRODUCT_INFORMATION } from "app/product/apollo/mutations";

// import queries
import {
  GET_CATALOG,
  GET_CURRENCY_RATE,
  GET_PRODUCT,
  GET_PRODUCT_SETTING,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TYPE,
  GET_WHOLESALE_SETTING,
} from "app/product/apollo/queries";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import Tag
import editData from "app/product/modules/provider/features/edit/config/editData";

import Tags from "app/product/modules/generic/features/form/tag/tag";

import Catalog from "app/product/modules/generic/features/form/tag/catalog";

const ServiceInformation = () => {
  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { cms, currentUser, match } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;
  const { typeOption } = editData(cms);
  const { CRITICAL, gql } = constant;

  const [basePricing, setBasePricing] = useState();
  const [comparePriceData, setComparePriceData] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [descriptionData, setDescriptionData] = useState("");
  const [discountData, setDiscountData] = useState("");
  const [message, setMessage] = useState("");
  const [priceData, setPriceData] = useState("");
  const [productFormSetting, setProductFormSetting] = useState();
  const [rate, setRate] = useState([]);
  const [tagData, setTagData] = useState("");
  const [typeData, setTypeData] = useState("");
  const [value, setValue] = useState({});
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [catalog, setCatalog] = useState("");

  // const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isHideCollection, setIsHideCollection] = useState(true);
  const [isHideTag, setIsHideTag] = useState(true);
  const [isHideType, setIsHideType] = useState(true);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);

  setDescription(cms("label.vendorInformation"));
  setTitle(cms("label.title.information"));

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: productSettingLoading, error: productSettingError, data: productSettingData } = useQuery(
    GET_PRODUCT_SETTING
  );
  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { input: { id: match.params.id } },
  });

  const { loading: catalogLoading, data: catalogData } = useQuery(GET_CATALOG);

  const { loading: currencyRateLoading, data: currencyRateData } = useQuery(GET_CURRENCY_RATE);

  const [updateProduct, { loading: editProductLoading }] = useMutation(EDIT_PRODUCT_INFORMATION);

  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);

  const { data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        setDescriptionData(productLabel.description);
        setPriceData(productLabel.price);
        setComparePriceData(productLabel.comparePrice);
        setTagData(productLabel.tags);
        setTypeData(productLabel.productType);
        setDiscountData(productLabel.discount);
        setProductFormSetting(productLabel);
        setCatalog(productLabel.catalog);
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_PERCENTAGE },
  } = constant;

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (val >= min && val <= max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.TITLE, constant.DESCRIPTION];
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue || parseFloat(0),
      });
    },
    [value]
  );

  const handleDiscount = (fieldName, fieldValue) => {
    const vendorDiscount = { ...value.vendorDiscount };
    vendorDiscount[fieldName] = fieldValue || parseFloat(0);
    if (fieldName !== constant.PRICE) {
      vendorDiscount.price = null;
    }
    setValue({
      ...value,
      vendorDiscount,
    });
  };

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT) || {};
      const { basePricing: vendorBasePricing } = productResponse || {};
      setValue(productResponse);
      setWholesalePrice(productResponse && productResponse.customInfo && productResponse.customInfo.wholesalePrice);
      setBasePricing(vendorBasePricing);
    }
  }, [gql.GET_PRODUCT, productData]);

  useEffect(() => {
    if (rate && rate.length && basePricing && basePricing.currencyCode) {
      const currencyData =
        rate && rate.length && rate.find((element) => basePricing.currencyCode === element.currencyCode);
      if (currencyData) {
        const { currencySymbol: symbol, price } = currencyData;
        setCurrencySymbol(symbol);
        setCurrencyPrice(price);
      }
    }
  }, [basePricing, rate]);

  useEffect(() => {
    if (productSettingError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productSettingError });
      return;
    }
    if (!productSettingData) {
      return;
    }
    const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);
    if (productError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productError });
      return;
    }
    const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
    const { isHideTag: hideTag, isHideType: hideType, isCatalogHidden } = productSetting || {};
    // setIsHideTagAndType(!!hideTagAndType);
    setIsHideTag(!!hideTag);
    setIsHideType(!!hideType);
    setIsHideCollection(!!isCatalogHidden);
  }, [CRITICAL, productSettingData, productSettingError, setBanner, setIsHideTag, setIsHideType]);

  const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);
  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT);

  useEffect(() => {
    const error = productListError || productError;
    if (error) {
      setBanner({ isOpen: true, status: CRITICAL, title: error });
    }
  }, [CRITICAL, productError, productListError, setBanner]);

  const currencyRateError = baseHelper.getResponseError(currencyRateData, gql.GET_CURRENCY_RATE);

  useEffect(() => {
    if (currencyRateError) {
      return;
    }

    const currencyRateSetting = baseHelper.getResponseData(currencyRateData, constant.gql.GET_CURRENCY_RATE) || {};
    const { currency = {} } = currencyRateSetting || {};
    const { rate: rateData = [] } = currency || {};
    setRate(rateData);
  }, [currencyRateError, currencyRateData]);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  // load product catalog data
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || {};

  if (
    productLoading ||
    productSettingLoading ||
    productTagLoading ||
    productTypeLoading ||
    currencyRateLoading ||
    wholesaleLoading ||
    catalogLoading
  ) {
    return <SkeletonCard />;
  }

  const primaryAction = {
    content: cms("button.save"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const { vendorDiscount = {} } = value || {};
  const { price: vendorPrice = "", type: vendorType = "" } = vendorDiscount || {};
  const discountPrice = value.vendorDiscount && value.vendorDiscount.price;
  const discountType = value.vendorDiscount && value.vendorDiscount.type;

  const maxDiscount = (discountType === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;

  const selectDiscount = (
    <Select
      value={discountType || ""}
      options={typeOption}
      placeholder={cms("placeholder.selectType")}
      onChange={(val) => {
        handleDiscount(constant.TYPE, val);
        setDisabledButton(false);
      }}
    />
  );

  const onFormSubmit = async () => {
    const { _id: id = "" } = value;
    const { price, comparePrice } = basePricing || {};
    const formValues = {
      productId: id,
      title: value.title,
      basePricing: {
        price: parseFloat(price || 0),
        comparePrice: parseFloat(comparePrice || 0),
      },
      description: value.description,
      productType: value.productType,
      tags: value.tags,
      shopifyCatalogId: value.shopifyCatalogId,
      price: parseFloat(value.price),
      comparePrice: parseFloat(value.comparePrice),
      discount: {
        price: parseFloat(discountPrice),
        type: discountType,
      },
    };

    if (isWholesalePrice) {
      formValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice) };
    }

    const dataToCheck = {
      title: (value.title && value.title.trim()) || "",
      description: (value.description && value.description.trim()) || "",
      vendor: value.vendor,
      comparePrice: value.comparePrice,
      price: value.price,
      markup: value.markup,
      discount: (value.vendorDiscount && value.vendorDiscount.price) || 0,
      catalog: value.shopifyCatalogId,
    };
    const { ALLOWED_INFORMATION_KEYS, WHOLESALE_PRICE, COMPARE_PRICE, PRICE } = constant;

    if (isHideTag) {
      dataToCheck.tags = (value.tags && value.tags.length) || null;
    }
    if (isHideType) {
      dataToCheck.productType = value.productType;
    }
    if (isWholesalePrice) {
      dataToCheck.wholesalePrice = parseFloat(wholesalePrice || 0);
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    if (isWholesalePrice) {
      ALLOWED_INFORMATION_KEYS.push(WHOLESALE_PRICE);
    }

    ALLOWED_INFORMATION_KEYS.push(COMPARE_PRICE, PRICE);
    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys
        .filter((item) => {
          if (!isHideTag && item === constant.PRODUCT_TAGS) {
            return false;
          }
          if (!isHideType && item === constant.PRODUCT_TYPE) {
            return false;
          }
          return true;
        })
        .forEach((key) => {
          const valueData = dataToCheck[key];
          if (
            (!valueData || valueData.length === 0 || valueData === "undefined") &&
            ALLOWED_INFORMATION_KEYS.includes(key)
          ) {
            errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
          }
        });
    }
    if (errorField) {
      const banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }

    await updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT_INFORMATION);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT_INFORMATION);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.information"));
          setBanner({ isOpen: false, status: "", title: "" });
          setTimeout(() => {
            handleTabChange(1);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const required = (field) => {
    if (field && field.isRequired) {
      return "*";
    }
    return "";
  };

  const label = (val, defaultCms) => {
    if (val && val.label) {
      return val.label;
    }
    return defaultCms;
  };

  const descriptionLabel = `${label(descriptionData, cms("label.description"))}${required(descriptionData)}`;

  return (
    <>
      <Card
        title={[
          cms("service.label.information"),
          value && value.updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
        id="serviceInformation"
      >
        <Card.Section>
          <p>{cms("service.label.serviceAvailability.description")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("service.label.name")} *`}
              placeholder={cms("service.placeholder.name")}
              required="true"
              onChange={(val) => {
                handleChange(constant.TITLE, val);
                setDisabledButton(false);
              }}
              error=""
              value={value.title || ""}
              onBlur={() => value && value.title && handleChange(constant.TITLE, value.title.trim())}
            />
            {descriptionLabel}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={value.description}
              onEditorChange={(val) => handleChange(constant.DESCRIPTION, val)}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />

            {!(value && value.variants && value.variants.length) ? (
              <FormLayout>
                {basePricing && basePricing.currencyCode && currencyPrice ? (
                  <>
                    <br />
                    <Banner status={constant.INFO} isOpen>
                      {`${cms("label.rate")} ${moneyFormat}1 = ${currencySymbol}${currencyPrice}`}
                    </Banner>
                  </>
                ) : null}
                <FormLayout.Group>
                  <TextField
                    id="price"
                    label={[`${label(priceData, cms("label.price"))}${required(priceData)}`]}
                    min={0}
                    value={(value.price && value.price.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    prefix={moneyFormat}
                    disabled={currencyPrice}
                    onChange={(val) => {
                      handleChange(constant.PRICE, acceptOnlyValidInput(val, value.price));
                      setDisabledButton(false);
                    }}
                  />
                  <TextField
                    id="comparePrice"
                    label={`${label(comparePriceData, cms("label.comparePrice"))}${required(comparePriceData)}`}
                    min={0}
                    placeholder={constant.ZERO_PRICE}
                    prefix={moneyFormat}
                    value={(value.comparePrice && value.comparePrice.toString()) || ""}
                    disabled={currencyPrice}
                    onChange={(val) => {
                      handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(val, value.comparePrice));
                      setDisabledButton(false);
                    }}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label={`${label(discountData, cms("label.discount"))}${required(discountData)}`}
                    value={(discountPrice && discountPrice.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    max={maxDiscount}
                    prefix={vendorType === constant.FLAT && currentUser.moneyFormat}
                    suffix={vendorType === constant.PERCENTAGE && constant.symbol.PERCENTAGE}
                    onChange={(val) => {
                      handleDiscount(constant.PRICE, acceptOnlyValidInput(val, vendorPrice, maxDiscount));
                      setDisabledButton(false);
                    }}
                    connectedRight={selectDiscount}
                  />
                </FormLayout.Group>
              </FormLayout>
            ) : null}
            {isHideType ? (
              <Stack vertical>
                {productTypeOptions && productTypeOptions.length > 0 && (
                  <Select
                    label={`${label(typeData, cms("service.label.type"))}${required(typeData)}`}
                    placeholder={cms("service.placeholder.type")}
                    options={productTypeOptions}
                    value={value.productType}
                    onChange={(val) => handleChange(constant.PRODUCT_TYPE, val)}
                  />
                )}
              </Stack>
            ) : null}
            {isHideTag ? (
              <Stack vertical>
                {availableProductTags && availableProductTags.tags && availableProductTags.tags.length && (
                  <Tags
                    productTag={availableProductTags}
                    data={value}
                    handleChange={handleChange}
                    label={`${label(tagData, cms("service.label.tag"))}${required(tagData)}`}
                    placeholder={cms("service.placeholder.tag")}
                  />
                )}
              </Stack>
            ) : null}

            {!isHideCollection ? (
              <Catalog
                label={`${label(catalog, cms("service.label.collection"))}${required(catalog)}`}
                placeholder={cms("service.placeholder.collection")}
                data={value}
                isHideCollection={isHideCollection}
                // setData={setData}
                handleChange={handleChange}
                productCatalog={(productCatalogOption && productCatalogOption.catalog) || []}
              />
            ) : null}
          </FormLayout>
          <div className="page-action">
            <PageActions
              primaryAction={{
                content: cms("button.update"),
                onAction: () => onFormSubmit(),
                loading: editProductLoading,
                disabled: !(value && value.title) || disabledButton,
              }}
            />
          </div>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};
export default withFeature(ServiceInformation, { feature: constant.PRODUCT_UPDATE_FORM });
