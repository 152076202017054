import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

import OperatorCreditNoteList from "app/payments/modules/operator/features/creditNoteList/list";
import ProviderCreditNoteList from "app/payments/modules/provider/features/creditNoteList/list";

const { userKey } = constant;
const { operator, provider } = userKey;

const CreditNoteList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorCreditNoteList />;
    }
    if (role === provider) {
      return <ProviderCreditNoteList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const CreditNoteBalanceComponent = getComponent(currentUserRole);
  return CreditNoteBalanceComponent;
};

export default withErrorBoundary(CreditNoteList);
