import { gql } from "apollo-boost";

const BULK_PRODUCT_LAKE = gql`
  mutation bulkProductLake($input: BulkProductLakeAction!) {
    bulkProductLake(input: $input) {
      data
      status
      error
    }
  }
`;

export default BULK_PRODUCT_LAKE;
