import React, { useContext, useEffect, useState } from "react";
import { Layout, Card, PageActions, Select, FormLayout, List, TextContainer } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext, OnboardingContext } from "lib/context";

// import query
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";
import { GET_METAFIELD_SETTING } from "app/product/apollo/queries";

// import components
import Banner from "lib/components/banner/banner";

import { baseHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import ServiceTabs from "./subFeatures/form/service/serviceTab";
import ProductTabs from "./subFeatures/form/subFeatureItem/tab";
import BulkCreate from "./subFeatures/bulkCreateCSV/bulkCreate";
// import Connect from "./subFeatures/connect/connect";
import VendorAppConnectShopify from "./subFeatures/connect/appConnect";
import PrestaShopConnect from "./subFeatures/prestaShopConnect/prestaShopConnect";
import MagentoConnect from "./subFeatures/magentoConnect/magentoConnect";
import WooCommerceConnect from "./subFeatures/wooCommerceConnect/wooCommerceConnect";
import addData from "./config/addData";
import WixStore from "./subFeatures/wixConnect/wixConnect";

const { CREATE_FORM, CREATE_CSV, SHOPIFY, WIX, WOOCOMMERCE, MAGENTO, SERVICE, SHOPIFY_CONNECT_FORM, PRESTASHOP } = constant;

const ProductAdd = () => {
  const {
    cms,
    history,
    isLoading,
    currentUser,
  } = useContext(PrivateContext);

  const { introductorySeller: sellerId } = currentUser;
  const { gql } = constant;
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { search, state } = history.location;
  const { shop: isShopConnected, key = "", code = "" } = baseHelper.getQueryParams(search);
  const [isSelectChange, setIsSelectChange] = useState(false);
  const [count, setCount] = useState(0);
  const [isHideTag, setIsHideTag] = useState(false);
  const [isHideType, setIsHideType] = useState(false);
  const [isCatalogHidden, setIsCatalogHidden] = useState(false);
  const [isMetafield, setIsMetafield] = useState(false);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [selected, setSelected] = useState("");
  const { data: productSettingsData, refetch } = useQuery(GET_PRODUCT_SETTING);

  useEffect(() => {
    setCount(count + 1);
    // setSelected(shopifyAccessToken && shopifyShop ? SHOPIFY : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.bannerData) {
      setBanner({
        isOpen: true,
        status: state.bannerData.status,
        title: state.bannerData.title,
      });
      delete state.bannerData;
      history.replace({ ...history.location, state });
    }
  }, [history, state]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    const responseError = baseHelper.getResponseError(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, title: responseError, status: constant.CRITICAL });
    }
    if (responseData) {
      const { isHideTag: hideTag, isHideType: hydeType, isCatalogHidden: catalogHidden } = responseData;
      setIsHideTag(hideTag);
      setIsHideType(hydeType);
      setIsCatalogHidden(catalogHidden);
      refetch();
    }
  }, [gql.GET_PRODUCT_SETTING, productSettingsData, refetch]);

  const { loading: metafieldLoading, data: metaFieldData } = useQuery(GET_METAFIELD_SETTING);

  useEffect(() => {
    if (metaFieldData) {
      const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
      const { metafield = [] } = metafieldSetting;

      if (metafield && metafield.length) {
        setIsMetafield(true);
        return;
      }
      setIsMetafield(false);
    }
  }, [metaFieldData]);

  const renderComponent = (value) => {
    if (value === WOOCOMMERCE) {
      return <WooCommerceConnect setBanner={setBanner} />;
    }
    if (value === MAGENTO) {
      return <MagentoConnect setBanner={setBanner} />;
    }
    if (value === PRESTASHOP) {
      return <PrestaShopConnect setBanner={setBanner} />;
    }
    if (value === CREATE_FORM) {
      return <ProductTabs setBanner={setBanner} isMetafield={isMetafield} />;
    }
    if (value === SERVICE) {
      return <ServiceTabs setBanner={setBanner} />;
    }

    if (value === SHOPIFY || (isShopConnected && count === 1)) {
      return (
        <Card title={cms("section.connect.title")}>
          <Card.Section>
            <VendorAppConnectShopify setBanner={setBanner} pageName={SHOPIFY_CONNECT_FORM} setModalBanner={setBanner} />
          </Card.Section>
        </Card>
      );
    }

    if (value === CREATE_CSV) {
      return (
        <BulkCreate
          setBanner={setBanner}
          cms={cms}
          hideTag={isHideTag}
          hideType={isHideType}
          catalogHidden={isCatalogHidden}
        />
      );
    }

    if (value === WIX || key === WIX) {
      return <WixStore setBanner={setBanner} code={code} />;
    }
    return null;
  };
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const standardLists = cms("section.onboarding.caption") || [];
  const renderCaptions = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b>
        {content.description}
      </List.Item>
    ));
    return list;
  };

  const getOptions = (options) => {
    const integration = [constant.SHOPIFY, constant.WOOCOMMERCE, constant.WIX, constant.MAGENTO, constant.PRESTASHOP];

    if (isOnboarding) {
      return options.filter((option) => !integration.includes(option.value));
    }
    return options;
  };

  const handleSelectChange = (updatedValue) => {
    setIsSelectChange(true);
    setSelected(updatedValue);
  };

  if (isLoading || metafieldLoading) {
    return <SkeletonAnnotated />;
  }

  const wixSelected = key === constant.WIX ? constant.WIX : selected;

  const isProductDiscovery = !!currentUser?.isProductDiscovery;

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={cms("label.import")} description={cms("label.csvInfo")}>
      <Card title={cms("label.addProduct")} >
          <Card.Section>
            {isOnboarding && (
              <>
                <TextContainer>
                  <p>{cms("section.onboarding.subDescription")}</p>
                  <p>{cms("section.onboarding.note")}</p>
                </TextContainer>

                <br />
                <TextContainer>
                  <Banner status={constant.CRITICAL} isOpen>
                    <p>{cms("section.onboarding.message.error.manual")}</p>
                  </Banner>
                </TextContainer>
                <br />
              </>
            )}
            {!isOnboarding && (
              <>
                <TextContainer>
                  <p>{cms("section.onboarding.manual")}</p>

                  <p>{cms("section.onboarding.note")}</p>
                </TextContainer>
                <br />
              </>
            )}
            <FormLayout>
              <List type="bullet">
                <List.Item>{cms("label.manual")}</List.Item>
                <List.Item>{cms("label.upload")}</List.Item>
                <List.Item>{cms("label.paas")}</List.Item>
              </List>
              <Select
                id="addProduct"
                options={getOptions(addData(cms, isOnboarding, sellerId, false, isProductDiscovery).options)}
                onChange={handleSelectChange}
                placeholder={cms("label.choose")}
                value={isShopConnected && count === 1 ? constant.SHOPIFY : wixSelected}
              />
              {isOnboarding && (
                <Banner status="info" isOpen>
                  <p>{cms("section.onboarding.options")}</p>
                  <br />
                  <p>
                    <List type="bullet">
                      <List type="bullet">{renderCaptions()}</List>
                    </List>
                  </p>
                </Banner>
              )}
            </FormLayout>
          </Card.Section>
        </Card>
        {renderComponent(selected)}

        {!(isSelectChange || isShopConnected) && isOnboarding && (
          <PageActions
            secondaryActions={[
              {
                content: cms("common.button.previous"),
                onAction: onPrevious,
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProductAdd), { feature: constant.PRODUCT_ADD });
