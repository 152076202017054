class YupHelper {
  /**
   * @desc Removed nodes from Tinymce editor
   * @usage Onboarding enhancement
   */

  domNodesRegex = /\s|(<([^>]+)>)|&amp;|&nbsp;/g;

  /**
   * @desc Email validation regex
   */

  // eslint-disable-next-line no-useless-escape, max-len
  emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  /**
   * @desc Avoid spaces and slashes
   */
  codeRegex = "^[^\\s\\\\/]+$";

  /**
   * @desc Avoid spaces and slashes
   */
  codeRegex = "^[^\\s\\\\/]+$";

  /**
   * @desc Password validation regex
   */
  passwordRegex = /^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*[\t\r\n\f]).{8,}$/;

  /**
   * @desc Phone number regex
   */
  phoneNumberRegex = /^(\+|\d)[0-9]*$/;

  /**
   * @desc Postal code regex
   */
  postalCodeRegex = /^[a-zA-Z0-9 ]*$/;

  /**
   * @desc Province code regex
   */
  provinceCodeRegex = /\b[A-Z]{2}$\b/;

  /**
   * @desc Hexadecimal value validation regex
   */
  hexRegex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

  /**
   * @desc handleBrandRegex accepts only lower case characters (a-z), hyphen (-) and underscore (_)
   */
  handleBrandRegex = /^[a-z0-9_-]{1,255}$/;

  /**
   * @desc domain validation regex
   */
  // eslint-disable-next-line no-useless-escape
  subDomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;

  /**
   * @desc URI validation regex
   */

  uriRegex = /^(mongodb:(?:\/{2})?)((\w+?):(\w+?)@|:?@?)(\w+?):(\d+)\/(\w+?)$/;

  /**
   * @desc validate value according to the schema
   *
   * @param schema yup schema
   * @param value to be validated
   *
   * @returns validation error status
   */
  validateValue = async (schema, value) => schema.validate(value);

  /**
   * @desc handle error promise
   *
   * @param promise errorPromise
   *
   * @returns errorMessage
   */
  handlePromiseError = async (promise) => {
    let errorMessage = "";
    await promise.catch((err) => {
      errorMessage = err.message;
    });
    return errorMessage;
  };
}

export default new YupHelper();
