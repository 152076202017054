import { gql } from "apollo-boost";

const GET_PRODUCT_TYPE = gql`
  query getProductType($input: GetProductType) {
    getProductType(input: $input) {
      data {
        label
        value
      }
      status
      error
    }
  }
`;

export default GET_PRODUCT_TYPE;
