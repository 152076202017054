import React, { useContext } from "react";
import { Badge, Button, Card, Caption, Link, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const FulfilledLineItems = (props) => {
  const { data, fulfilledData, getSlipText, lineItemArray, packingSlipAccessData, setFulfillAmount } = props;
  const { cms, history, userData, match } = useContext(PrivateContext);
  const { PARTIAL, FULFILLED, REFUND } = constant;
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const amount = [];
  const vendorsSum = [];
  let countItems = 0;
  // fulfilledData &&
  //   fulfilledData.length &&
  //   fulfilledData.forEach((dataLine) => {
  //     const { id: shopifyLineItemId = "", totalPrice = 0, vendor, totalQuantity } = dataLine;
  //     const getItem = lineItemArray.find((item) => item.shopifyLineItemId === shopifyLineItemId);
  //     const { markUpPrice = 0, sellerDiscount = 0 } = getItem;
  //     vendorsSum.push(vendor);
  //     amount.push(
  //       parseFloat(totalPrice) - parseFloat(totalQuantity * markUpPrice) + parseFloat(totalQuantity * sellerDiscount)
  //     );
  //     countItems += 1;
  //     return [amount];
  //   });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  const stringToNumber = amount.map((item) => parseFloat(item));
  // const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);
  let totalAmount = 0;

  const isVendor = baseHelper.isVendor(currentUserData);

  const lineItems = fulfilledData.map((lineItem, idx) => {
    const { id: shopifyLineItemId = "", name = "", sku, totalPrice, totalQuantity } = lineItem || {};
    const getItem = lineItemArray.find((item) => item.shopifyLineItemId === shopifyLineItemId);
    const {
      fulfillmentStatus,
      fulfillmentType,
      image = "",
      isProductDeleted,
      isVendorDeleted,
      moneyFormat: lineMoneyFormat,
      price,
      // markUpPrice = 0,
      properties,
      // sellerDiscount = 0,
      productId,
      rejectedByVendor,
      vendor,
      vendorId,
    } = getItem || {};

    const lineTotalPrice = parseFloat(price);
    totalAmount += lineTotalPrice;

    const lineItemKey = `lineItem${idx}`;
    const lineImage = imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

    const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;
    const isServiceLineItem = baseHelper.isServiceLineItem(fulfillmentType, properties);
    const status = statusHelper.getBadgeStatus(isServiceLineItem ? constant.COMPLETED : constant.FULFILLED);
    const badgeType = baseHelper.getBadgeType(constant.FULFILLED);

    const lineItemName = <div className="productName">{name}</div>;
    const productURL = isServiceLineItem ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;

    return (
      <div key={lineItemKey} className="fulfilled-Secn">
        <Card>
          <Card.Section subdued>
            <StyledCard className="styled-card-unfulfill">
              <Stack wrap={false}>
                <Stack.Item>
                  <Thumbnail size="large" source={lineImage} alt={name} />
                </Stack.Item>
                <Stack.Item fill>
                  <div className="ellipsis">
                    {(isProductDeleted && (lineItemName || "")) || (
                      <Link onClick={() => history.push(productURL)}>{lineItem.name || ""}</Link>
                    )}

                    <Caption>
                      <TextStyle variation={constant.SUBDUED}>
                        {vendor && vendorId
                          ? isVendor || isVendorDeleted
                            ? [`${cms("label.vendor")}: `, vendor, <br />]
                            : [
                                `${cms("label.vendor")}: `,
                              <Link onClick={() => history.push(constant.PROVIDER_VIEW + vendorId)}>
                                {vendor || ""}
                              </Link>,
                              <br />,
                              ]
                          : ""}
                      </TextStyle>
                      <LineItemProperties
                        variation={constant.SUBDUED}
                        properties={properties}
                        isServiceLineItem={isServiceLineItem}
                      />
                      {isServiceLineItem ? '' : (<TextStyle variation={constant.SUBDUED}>
                        {cms("label.skuList")} {sku} </TextStyle>)}
                    </Caption>
                    <Badge status={badgeType} className="text-capitalize">
                      {status}
                    </Badge>
                    {rejectedByVendor && (
                      <Badge status={baseHelper.getBadgeType(constant.REJECTED)} className="text-capitalize">
                        {constant.REJECTED_BY_VENDOR}
                      </Badge>
                    )}
                    {!(fulfillmentStatus === constant.REFUND) && (
                      <span className="packing-slip-link">
                        {[FULFILLED, PARTIAL, REFUND].includes(fulfillmentStatus) && packingSlipAccessData.isFulfilled && (
                          <Button plain icon={NoteMajor} onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                            {getSlipText(fulfillmentType)}
                          </Button>
                        )}
                      </span>
                    )}
                  </div>
                </Stack.Item>
                <Stack.Item>
                  {`${cms("label.qty")}:`}
                  {totalQuantity}
                </Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {(lineTotalPrice && baseHelper.getPrice(lineTotalPrice)) || constant.value.ZERO}
                </Stack.Item>
              </Stack>
            </StyledCard>
          </Card.Section>
        </Card>
      </div>
    );
  });

  if (totalAmount) {
    setFulfillAmount(totalAmount);
  }

  const moneyFormat = data && data.moneyFormat;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            {" "}
            (
            {countItems > 1
              ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {`${moneyFormat || constant.symbol.DOLLAR}${baseHelper.getPrice(totalAmount)}`}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
  return [lineItems, totalFulfill];
};

export default FulfilledLineItems;
