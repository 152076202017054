import { gql } from "apollo-boost";

const UPDATE_ORDER_LINE_DELIVERY_STATUS = gql`
  mutation updateOrderLineDeliveryStatus($input: UpdateOrderLineDeliveryStatus) {
    updateOrderLineDeliveryStatus(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_ORDER_LINE_DELIVERY_STATUS;
