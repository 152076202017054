import { yupHelper } from "lib/helpers";
import { passwordSchema, userNameSchema } from "./schema";

const { handlePromiseError, validateValue } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case "password":
      promise = validateValue(passwordSchema(cms), { password: value });
      break;
    case "userName":
      promise = validateValue(userNameSchema(cms), { userName: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
