import { baseHelper } from "lib/helpers";

class VendorHelper {
  /**
   * @desc To return vendor csv data
   * @param vendorDetail array of vendorDetail
   *
   * @return csv data
   *
   */
  vendorCSVData = (
    vendorDetail = [],
    settingResponse = {},
    isCsv = false,
    isPdf = false,
    isPaymentEnable = true,
    isSocialLinkEnable = true,
    isShippingEnable = true,
    customFieldInformation = []
  ) => {
    const settingData = settingResponse.export;
    const {
      accountNumber,
      bankName,
      brandHandle,
      brandName,
      brandDescription,
      brandCoverImage,
      brandLogo,
      city,
      country,
      email,
      facebookUrl,
      firstName,
      instagramUrl,
      lastName,
      linkedinUrl,
      paymentCountry,
      phoneNumber,
      postalCode,
      provinceCode,
      shippingCity,
      shippingCountry,
      shippingPostalCode,
      shippingStreetAddress,
      sortCode,
      streetAddress,
      twitterUrl,
      youtubeUrl,
      customFields = [],
    } = settingData || {};
    let updateVendorExportData = [];

    if (customFields && customFields.length && customFieldInformation && customFieldInformation.length) {
      updateVendorExportData = customFields.map((item) => item.label || item.key) || [];
    }

    const vendorObj = {
      brandName: (brandName && brandName.label) || "Brand Name",
      brandHandle: (brandHandle && brandHandle.label) || "Brand Handle",
      brandDescription: (brandDescription && brandDescription.label) || "Brand Description",
      brandLogo: (brandLogo && brandLogo.label) || "Brand Logo",
      brandCoverImage: (brandCoverImage && brandCoverImage.label) || "Brand Cover Image",
      firstName: (firstName && firstName.label) || "First Name",
      lastName: (lastName && lastName.label) || "Last Name",
      email: (email && email.label) || "Email",
      phoneNumber: (phoneNumber && phoneNumber.label) || "Phone Number",
      streetAddress: (streetAddress && streetAddress.label) || "Street Address",
      city: (city && city.label) || "City",
      provinceCode: (provinceCode && provinceCode.label) || "Province Code",
      country: (country && country.label) || "Country",
      postalCode: (postalCode && postalCode.label) || "Postal Code",
      bankName: (bankName && bankName.label) || "Bank Name",
      accountNumber: (accountNumber && accountNumber.label) || "Account Number",
      sortCode: (sortCode && sortCode.label) || "Sort Code",
      paymentCountry: (paymentCountry && paymentCountry.label) || "Payment Country",
      facebookUrl: (facebookUrl && facebookUrl.label) || "Facebook Url",
      youtubeUrl: (youtubeUrl && youtubeUrl.label) || "Youtube Url",
      twitterUrl: (twitterUrl && twitterUrl.label) || "Twitter Url",
      instagramUrl: (instagramUrl && instagramUrl.label) || "Instagram Url",
      linkedinUrl: (linkedinUrl && linkedinUrl.label) || "Linkedin Url",
      shippingStreetAddress: (shippingStreetAddress && shippingStreetAddress.label) || "Shipping Street Address",
      shippingCity: (shippingCity && shippingCity.label) || "Shipping City",
      shippingCountry: (shippingCountry && shippingCountry.label) || "Shipping Country",
      shippingPostalCode: (shippingPostalCode && shippingPostalCode.label) || "Shipping Postal Code",
    };
    if (updateVendorExportData && updateVendorExportData.length) {
      // eslint-disable-next-line no-return-assign
      updateVendorExportData.forEach((item) => (vendorObj[item] = baseHelper.ucFirst(item)));
    }

    const updatedArray = [];
    if (settingData) {
      const notAllowedCsvKeys = Object.keys(settingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = settingData[item] && settingData[item].isHideCsv;
        }

        if (isPdf) {
          hideArray = settingData[item] && settingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedCsvKeys.map((key) => {
        updatedArray.push(vendorObj[key]);
        return false;
      });
    }
    if (customFields && customFields.length && customFieldInformation && customFieldInformation.length) {
      customFields.forEach((customItem) => {
        if (customItem.isHideCsv && isCsv) {
          updatedArray.push(customItem.label || customItem.key);
        }
        if (customItem.isHidePdf && isPdf) {
          updatedArray.push(customItem.label || customItem.key);
        }
      });
    }

    const vendorArray = [
      (brandName && brandName.label) || "Brand Name",
      (brandHandle && brandHandle.label) || "Brand Handle",
      (brandDescription && brandDescription.label) || "Brand Description",
      (brandLogo && brandLogo.label) || "Brand Logo",
      (brandCoverImage && brandCoverImage.label) || "Brand Cover Image",
      (firstName && firstName.label) || "First Name",
      (lastName && lastName.label) || "Last Name",
      (email && email.label) || "Email",
      (phoneNumber && phoneNumber.label) || "Phone Number",
      (streetAddress && streetAddress.label) || "Street Address",
      (city && city.label) || "City",
      (provinceCode && provinceCode.label) || "Province Code",
      (country && country.label) || "Country",
      (postalCode && postalCode.label) || "Postal Code",
      (bankName && bankName.label) || "Bank Name",
      (accountNumber && accountNumber.label) || "Account Number",
      (sortCode && sortCode.label) || "Sort Code",
      (paymentCountry && paymentCountry.label) || "Payment Country",
      (facebookUrl && facebookUrl.label) || "Facebook Url",
      (youtubeUrl && youtubeUrl.label) || "Youtube Url",
      (twitterUrl && twitterUrl.label) || "Twitter Url",
      (instagramUrl && instagramUrl.label) || "Instagram Url",
      (linkedinUrl && linkedinUrl.label) || "Linkedin Url",
      (shippingStreetAddress && shippingStreetAddress.label) || "Shipping Street Address",
      (shippingCity && shippingCity.label) || "Shipping City",
      (shippingCountry && shippingCountry.label) || "Shipping Country",
      (shippingPostalCode && shippingPostalCode.label) || "Shipping Postal Code",
      ...updateVendorExportData,
    ];
    const removeIndex = [];

    updatedArray.map((arr) => {
      removeIndex.push(vendorArray.indexOf(arr));
      return false;
    });

    if (!isPaymentEnable) {
      updatedArray.push(
        accountNumber?.label || "Account Number",
        bankName?.label || "Bank Name",
        sortCode?.label || "Sort Code",
        paymentCountry?.label || "Payment Country"
      );
    }

    if (!isSocialLinkEnable) {
      updatedArray.push(
        twitterUrl?.label || "Twitter Url",
        youtubeUrl?.label || "Youtube Url",
        linkedinUrl?.label || "Linkedin Url",
        facebookUrl?.label || "Facebook Url",
        instagramUrl?.label || "Instagram Url"
      );
    }

    if (!isShippingEnable) {
      updatedArray.push(
        shippingCity?.label || "Shipping City",
        shippingCountry?.label || "Shipping Country",
        shippingPostalCode?.label || "Shipping Postal Code",
        shippingStreetAddress?.label || "Shipping Street Address"
      );
    }

    if (customFields && customFields.length && customFieldInformation && customFieldInformation.length) {
      customFields.forEach((customItem) => {
        if (customItem.isHideCsv && isCsv) {
          updatedArray.push(customItem.label || customItem.key);
        }
        if (customItem.isHidePdf && isPdf) {
          updatedArray.push(customItem.label || customItem.key);
        }
      });
    }
    const differenceArray = vendorArray.filter((item) => !updatedArray.includes(item));
    const newVendorArray = [];
    newVendorArray[0] = differenceArray;
    vendorDetail.forEach((item, idx) => {
      let paymentData = [];
      let shippingData = [];
      let socialData = [];
      let customData = [];

      if (isPaymentEnable) {
        paymentData = [item.bankName || "", item.accountNumber || "", item.sortCode || "", item.paymentCountry || ""];
      }
      if (isShippingEnable) {
        shippingData = [
          item.shippingStreetAddress || "",
          item.shippingCity || "",
          item.shippingCountry || "",
          item.shippingPostalCode || "",
        ];
      }
      if (isSocialLinkEnable) {
        socialData = [
          item.facebookUrl || "",
          item.youtubeUrl || "",
          item.twitterUrl || "",
          item.instagramUrl || "",
          item.linkedinUrl || "",
        ];
      }
      if (customFields && customFields.length && customFieldInformation && customFieldInformation.length) {
        customData = customFields.map((customItem) => customItem.value || "");
      }

      newVendorArray[idx + 1] = [
        item.brandName || "",
        item.brandHandle || "",
        item?.brandDescription?.replace(/<[^>]+>/g, "") || "",
        item.brandLogo || "",
        item.brandCoverImage || "",
        item.firstName || "",
        item.lastName || "",
        item.email || "",
        item.phoneNumber || "",
        item.streetAddress || "",
        item.city || "",
        item.provinceCode || "",
        item.country || "",
        item.postalCode || "",
        ...paymentData,
        ...socialData,
        ...shippingData,
        ...customData,
      ].filter((value, index) => {
        return removeIndex.indexOf(index) === -1;
      });
    });
    return newVendorArray;
  };
}

export default new VendorHelper();
