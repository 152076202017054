import React, { useContext } from "react";
import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import AdminAutomaticPayouts from "./admin/features/automaticPayout/feature/automaticPayouts";
import { OperatorAutomaticPayouts } from "./operator/features";

const { userKey } = constant;
const { admin, operator } = userKey;

const AutomaticPayouts = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminAutomaticPayouts />;
    }
    if (role === operator) {
      return <OperatorAutomaticPayouts />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const AutomaticPayoutsComponent = getComponent(currentUserRole);
  return AutomaticPayoutsComponent;
};

export default AutomaticPayouts;
