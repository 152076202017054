import styled from "styled-components";

export const MainHeader = styled.header`
  background-color: ${(props) => props.background || "#fff"};
  .Polaris-TopBar-UserMenu__Details p,
  .brandTitle {
    color: ${(props) => props.color || "#000"};
  }
  .Polaris-TopBar {
    background-color: transparent;
  }
  .Polaris-TopBar-Menu__Activator:hover,
  .Polaris-TopBar-Menu__Activator:active,
  .Polaris-TopBar-Menu__Activator:focus,
  .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
    background: transparent;
    opacity: 0.9;
    &:after {
      content: none;
    }
  }
`;
export const LoadingWrapper = styled.div`
  background-color: #fff;
  .Polaris-SkeletonDisplayText__DisplayText {
    max-width: none;
    height: 5.7rem;
  }
`;
