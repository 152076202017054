// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { TextField, Card, Stack, PageActions, TextContainer } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper, yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { IS_BIGCOMMERCE_STORE_CONNECTED, SAVE_BIGCOMMERCE_STORE_DETAILS } from "app/productLake/apollo/mutations";

import config from "configuration";
import SyncBigcommerceProduct from "app/productLake/modules/provider/features/add/subFeatures/syncBigcommerceProduct";

// import components
import TermModal from "app/product/modules/provider/features/genric/term/term";
import ConnectedShop from "./subFeatureItems/connected";

const VendorConnectBigcommerce = (props) => {
  const { history, currentUser: currentUserData, cms } = useContext(PrivateContext);
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { setBanner } = props;
  // const [shop, setShop] = useState((currentUserData && currentUserData.shop) || "");
  const { bigCommerce = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const {
    accessToken: bigcommerceVendorAccessToken = "",
    clientId: bigcommerceVendorClientId = "",
    clientSecret: bigcommerceVendorClientSecret = "",
    shopDomain: bigcommerceVendorShopDomain = "",
  } = bigCommerce || {};

  const [value, setValue] = useState({
    accessToken: bigcommerceVendorAccessToken || "",
    clientId: bigcommerceVendorClientId || "",
    clientSecret: bigcommerceVendorClientSecret || "",
    shopDomain: bigcommerceVendorShopDomain || "",
  });
  // const [storeHash, setStoreHash] = useState(
  //   (currentUserData && currentUserData?.storeConfig?.bigCommerce?.storeHash) || ""
  // );
  // const [isConnected, setIsConnected] = useState(!!(currentUserData && currentUserData.shop));
  const [isConnected, setIsConnected] = useState(!!bigcommerceVendorShopDomain);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    accessToken: "",
    clientId: "",
    clientSecret: "",
    shopDomain: "",
  });
  const [isBigCommerceStoreConnected, { loading }] = useMutation(IS_BIGCOMMERCE_STORE_CONNECTED);
  const [saveBigCommerceStoreDetails, { storeloading }] = useMutation(SAVE_BIGCOMMERCE_STORE_DETAILS);

  const {
    IS_BIGCOMMERCE_STORE_CONNECTED: STORE_CONNECTED,
    SAVE_BIGCOMMERCE_STORE_DETAILS: SAVE_STORE_DETAILS,
  } = constant.gql;
  const onSubmit = async () => {
    const isValidSubDomain =
      yupHelper.subDomainRegex.test(value.shopDomain) &&
      baseHelper.isValidUrl(`https://${value.shopDomain}.mybigcommerce.com`);
    if (!isValidSubDomain) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.connect.message.error.invalidSubDomain"),
      });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isBigCommerceStoreConnected({
        variables: { input: { ...value } },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECTED);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }

      setTimeout(async () => {
        try {
          const resp = await saveBigCommerceStoreDetails({
            variables: { input: { ...value } },
          });
          const respError = baseHelper.getResponseError(resp.data, SAVE_STORE_DETAILS);
          if (respError) {
            setIsConnected(false);
            setBanner({ isOpen: true, status: constant.CRITICAL, title: respError });
            setIsOpen(false);
          }
          const respData = baseHelper.getResponseData(resp.data, SAVE_STORE_DETAILS);
          if (respData) {
            setIsConnected(true);
            setIsOpen(false);
            setBanner({
              isOpen: true,
              status: constant.SUCCESS,
              title: cms("section.connect.message.success.connected"),
            });
          }
        } catch (e) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(e) });
          setIsOpen(false);
        }
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const handleTermModal = (fieldName = "check", modalValue = "") => {
    if (!Object.values(value).every((item) => item)) {
      setIsOpen(false);
      const errorMessages = {
        accessToken: cms("section.connect.message.error.tokenRequired"),
        clientId: cms("section.connect.message.error.clientIdRequired"),
        clientSecret: cms("section.connect.message.error.clientSecretRequired"),
        shopDomain: cms("section.connect.message.error.storeHashRequired"),
      };
      setErrorMessage({
        ...errorMessage,
        [fieldName]: !value[fieldName] ? errorMessages[fieldName] : "",
      });

      return;
    }
    if (modalValue === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage({
      accessToken: "",
      clientId: "",
      clientSecret: "",
      shopDomain: "",
    });
  };

  return (
    <>
      <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.BIGCOMMERCE_CAPITAL}
      />
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <TextField
            label={`${"Shop Domain"}*`}
            error={errorMessage.shopDomain}
            id="shopDomainInput"
            type={constant.TEXT}
            prefix="https://"
            suffix=".mybigcommerce.com"
            value={value.shopDomain}
            onChange={(inputValue) => handleChange("shopDomain", inputValue)}
            onBlur={() => handleTermModal("shopDomain")}
            disabled={isConnected}
          />
          <br />
          <TextField
            label={`${"Access Token"}*`}
            error={errorMessage.accessToken}
            id="shopInput"
            type={constant.TEXT}
            value={value.accessToken}
            onChange={(inputValue) => handleChange("accessToken", inputValue)}
            onBlur={() => handleTermModal("accessToken")}
            disabled={isConnected}
          />
          <br />
          <TextField
            label={`${"Client ID"}*`}
            error={errorMessage.clientId}
            id="clientIdInput"
            type={constant.TEXT}
            value={value.clientId}
            onChange={(inputValue) => handleChange("clientId", inputValue)}
            onBlur={() => handleTermModal("clientId")}
            disabled={isConnected}
          />
          <br />
          <TextField
            label={`${"Client Secret"}*`}
            error={errorMessage.clientSecret}
            id="clientSecretInput"
            type={constant.TEXT}
            value={value.clientSecret}
            onChange={(inputValue) => handleChange("clientSecret", inputValue)}
            onBlur={() => handleTermModal("clientSecret")}
            disabled={isConnected}
          />
          <br />
          {value.shopDomain && isConnected && (
            <Stack>
              <Stack.Item fill>
                <ConnectedShop
                  cms={cms}
                  currentUserData={currentUserData}
                  setBanner={setBanner}
                  setIsConnected={setIsConnected}
                  setValue={setValue}
                />
              </Stack.Item>
              <Stack.Item>
                <SyncBigcommerceProduct
                  history={history}
                  setBanner={setBanner}
                  cms={cms}
                  store={constant.BIGCOMMERCE}
                  // eslint-disable-next-line react/destructuring-assignment
                  path={props.path}
                />
              </Stack.Item>
            </Stack>
          )}
          {!(value.shopDomain && isConnected) && (
            <div className="manageCustomerButton">
              <PageActions
                primaryAction={{
                  id: "connectShop",
                  content: cms("section.connect.button.connect"),
                  onAction: () => handleTermModal("check", constant.CONNECT),
                  disabled: !Object.values(value).every((item) => item) || isConnected,
                }}
                secondaryActions={[
                  {
                    id: "cancelAction",
                    content: cms("section.connect.button.cancel"),
                    onAction: () => (isOnboarding && onPrevious()) || history.push("/product-lake"),
                  },
                ]}
              />
            </div>
          )}
        </Card.Section>
      </Card>
    </>
  );
};

VendorConnectBigcommerce.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectBigcommerce.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorConnectBigcommerce), {
  feature: constant.PRODUCT_IMPORT_SHOPIFY,
  // subFeature: constant.PRODUCT_IMPORT_SHOPIFY,
});
