import { gql } from "apollo-boost";

const LINE_RETURN = gql`
  mutation lineReturn($input: LineReturn!) {
    lineReturn(input: $input) {
      status
      error
      data
    }
  }
`;

export default LINE_RETURN;
