import { gql } from "apollo-boost";

const UPDATE_ADVANCE_METAFIELDS = gql`
  mutation updateAdvanceMetafield($input: AdvanceMetafield) {
    updateAdvanceMetafield(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_ADVANCE_METAFIELDS;
