import * as yup from "yup";

const addressSchema = (cms) => yup.object().shape({ address: yup.string().required(cms("message.address")) });

const citySchema = (cms) => yup.object().shape({ city: yup.string().required(cms("message.city")) });

const countrySchema = (cms) => yup.object().shape({ countryCode: yup.string().required(cms("message.country")) });

const pincodeSchema = (cms) => yup.object().shape({ pincode: yup.string().required(cms("message.pincode")) });

const vendorSchema = (cms) => yup.object().shape({ vendorIds: yup.array().required(cms("message.vendor")) });

const clientIdSchema = (cms) => yup.object().shape({ clientId: yup.string().required(cms("message.clientId")) });

const clientSecretSchema = (cms) =>
  yup.object().shape({ clientSecret: yup.string().required(cms("message.clientSecret")) });

const accountNumberSchema = (cms) =>
  yup.object().shape({ accountNumber: yup.string().required(cms("message.accountNumber")) });

const siteCodeSchema = (cms) => yup.object().shape({ siteCode: yup.string().required(cms("message.siteCode")) });

const userNameSchema = (cms) => yup.object().shape({ userName: yup.string().required(cms("message.userName")) });
const passwordSchema = (cms) => yup.object().shape({ password: yup.string().required(cms("message.password")) });
const customerNumberSchema = (cms) =>
  yup.object().shape({ customerNumber: yup.string().required(cms("message.customerNumber")) });
const contractIdSchema = (cms) => yup.object().shape({ contractId: yup.string().required(cms("message.contractId")) });

export {
  accountNumberSchema,
  addressSchema,
  citySchema,
  clientIdSchema,
  clientSecretSchema,
  contractIdSchema,
  countrySchema,
  customerNumberSchema,
  passwordSchema,
  pincodeSchema,
  siteCodeSchema,
  userNameSchema,
  vendorSchema,
};
