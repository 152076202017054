import React, { useState, useContext } from "react";
import { Modal, Stack, Select, Checkbox, DisplayText, TextField, FormLayout } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import lib component
import { Banner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import gql
import { EDIT_OFFER } from "app/shipping/apollo/mutations";

// import propType
import { editProp } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

const EditOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { currentUser = {}, cms } = useContext(PrivateContext);
  const { editModal, toggleEditModal, bands = [], destinations = [], products = [], refetch, item, moneyFormat } =
    props || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const {
    _id: id,
    shippingBandId,
    price: priceVal,
    description: descriptions,
    destinationCode,
    productId,
    priceType: priceAmount,
    isCumulative,
  } = item || {};
  const [checked, setChecked] = useState(isCumulative);
  const [priceType, setPriceAmount] = useState(priceAmount);
  const [priceValue, setPriceValue] = useState(priceVal);
  const [description, setDescription] = useState(descriptions);
  const isFieldDisabled = false;
  const [updateOfferManage, { loading: updateLoading }] = useMutation(EDIT_OFFER);

  const handleChange = (value) => {
    setDescription(value);
  };
  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };
  const { FIXED, FREE, gql } = constant;
  const options = [
    { label: cms("label.fixed"), value: FIXED },
    { label: cms("label.freeShipping"), value: FREE },
  ];
  const handleType = (value) => {
    if (value !== FIXED) {
      setPriceValue("");
    }
    setPriceAmount(value);
  };
  const handleCost = (value) => {
    setPriceValue(value);
  };

  const isValid = () => {
    if (!description) {
      return cms("message.error.addDescriptionForOffer");
    }
    if (description.length > 255) {
      return cms("message.error.descriptionCharacter");
    }
    if (!priceType) {
      return cms("message.error.selectShippingOfferType");
    }
    if (priceType !== FREE && !priceValue) {
      return cms("message.error.provideCost");
    }
    if (priceType !== FREE && priceValue < 0) {
      return cms("message.error.provideValidPrice");
    }
    return false;
  };

  const updateShipping = () => {
    const paramsToUpdate = {
      id,
      parentId: item && item.parentId,
      priceType,
      description,
      isCumulative: checked,
      price: (priceType && priceType === constant.FIXED && parseFloat(priceValue)) || 0,
    };

    const { userId: ruleCreatedBy = null } = item || {};
    const { _id: currentUserId = null } = currentUser || {};
    if (baseHelper.mongoIdAsString(ruleCreatedBy) !== baseHelper.mongoIdAsString(currentUserId)) {
      paramsToUpdate.parentId = id;
    }

    updateOfferManage({
      variables: {
        input: paramsToUpdate,
      },
    })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.UPDATE_SHIPPING_OFFER);
        if (resError) {
          setBanner({ isOpen: true, title: resError, status: "critical" });
          return;
        }

        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_SHIPPING_OFFER);
        if (responseData) {
          setBannerOuter({
            isOpen: true,
            title: cms("modal.offer.edit.message.shippingOfferEdited"),
            status: "success",
          });
          toggleEditModal();
          refetch();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: "critical" });
      });
  };
  const handleSubmit = () => {
    const isValidation = isValid();
    if (isValidation) {
      setBanner({
        isOpen: true,
        title: isValidation,
        status: "critical",
      });
      return false;
    }
    updateShipping();
    return true;
  };

  const shippingBand =
    (bands.length > 0 &&
      bands.find((band) => {
        return band.value === shippingBandId || band.parentId === shippingBandId;
      })) ||
    "";
  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);

  if (!isVendorAllowed) {
    return null;
  }

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <>
      <Modal
        open={editModal}
        onClose={toggleEditModal}
        title={cms("modal.offer.edit.button.editOffer")}
        primaryAction={{
          content: cms("modal.offer.edit.button.update"),
          onAction: handleSubmit,
          loading: updateLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <Stack vertical>
            <Stack.Item>
              <DisplayText size="small">
                {`${cms("label.shippingBand")}: ${shippingBand && shippingBand.label}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {` ${cms("label.destination")}: ${(destination && destination.label) || cms("label.all")}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {` ${cms("label.product")}: ${(product && product.label) || cms("label.all")}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label={`${cms("placeholder.description")} *`}
                placeholder={cms("placeholder.description")}
                value={description}
                onChange={handleChange}
              />
            </Stack.Item>
            <Stack.Item>
              <FormLayout>
                <Stack>
                  <Select
                    label={`${cms("modal.rule.add.label.selectShippingType")} *`}
                    options={options}
                    onChange={handleType}
                    value={priceType}
                    placeholder={cms("modal.rule.add.placeholder.selectShipping")}
                  />
                  {priceType === FIXED && (
                    <Stack.Item>
                      <TextField
                        label={`${cms("modal.rule.add.label.amount")} *`}
                        min={0}
                        step={0.01}
                        value={(priceValue && priceValue.toString()) || ""}
                        disabled={isFieldDisabled}
                        onChange={(value) => {
                          handleCost(acceptOnlyValidInput(value, priceValue));
                        }}
                        prefix={moneyFormat}
                      />
                    </Stack.Item>
                  )}
                </Stack>
              </FormLayout>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label={cms("label.chargeCumulative")}
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

EditOffers.propTypes = editProp.type;

export default EditOffers;
