import React, { useCallback, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Banner,
  DropZone as PolarisDropZone,
  Button,
  ButtonGroup,
  List,
  Stack,
  Thumbnail,
  Tooltip,
  SkeletonThumbnail,
} from "@shopify/polaris";

// eslint-disable-next-line import/no-cycle
import { imageHelper } from "lib/helpers";
import LazyLoad from "react-lazyload";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const DropZone = (props) => {
  const {
    allowMultiple = true,
    allowedTypes,
    disabled,
    existingImageList = [],
    fileList = [],
    id,
    isImageErrorDisable,
    label,
    loadingPosition = "",
    onAdd,
    onRemove,
    removeExistingImage,
    setDisabledButton = () => {},
    setIsImageError = () => {},
    sizeType,
  } = props;

  const { cms } = useContext(PrivateContext);
  const [files, setFiles] = useState(fileList);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [existingImages, setExistingImages] = useState(existingImageList);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  useEffect(() => {
    setExistingImages(existingImageList);
  }, [existingImageList]);

  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [hasError, setHasError] = useState(rejectedFiles.length > 0);
  const skeletalThumbnail = <SkeletonThumbnail size={constant.LARGE} />;

  useEffect(() => {
    if (rejectedFiles.length && hasError) {
      setDisabledButton(!isImageErrorDisable);
      setIsImageError(true);
    }
  }, [hasError, isImageErrorDisable, rejectedFiles, setDisabledButton, setIsImageError]);

  const handleDrop = useCallback(
    async (_droppedFiles, acceptedFiles, rejectedFile) => {
      const promiseList = acceptedFiles.map((acceptedFile) => {
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          const img = new Image();
          reader.readAsDataURL(acceptedFile);
          reader.onloadend = (event) => {
            const result = reader.result || "";
            img.onload = () => {
              const { width } = img;
              const { height } = img;
              // resolve({ width, height });
              const megapixels = (width * height) / 1000000;
              if (megapixels < 20) {
                resolve(result);
              }
              if (megapixels > 20) {
                setImageSizeError(true);
                setDisabledButton(true);
              }
            };
            img.onerror = () => {
              reject(new Error("Invalid image file."));
            };

            img.src = event.target.result;
            reader.onerror = () => {
              reject(new Error("Error reading image file."));
            };
          };
        });
      });
      const response = await Promise.all(promiseList);
      onAdd(response);
      setFiles([...files, ...response]);
      setRejectedFiles(rejectedFile);
    },
    [files, onAdd]
  );

  const fileUpload = <PolarisDropZone.FileUpload />;

  const removeFile = (file, buttonIndex) => {
    const updatedFiles = [...files];
    const index = updatedFiles.indexOf(file);
    if (index < 0) {
      return;
    }
    onRemove(index, buttonIndex);
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setHasError(false);
    }
  };

  const isValidSize = (file) => {
    const fileSize = (sizeType === constant.DATA_UNIT.MB ? props.size * 1024 : props.size) * 1024;
    if (file.size >= fileSize) {
      return false;
    }
    return true;
  };

  const isTypeAllowed = (file) => {
    const { name = "" } = file;
    if (name) {
      const fileType = name.split(".");
      return allowedTypes.includes(fileType.pop().toLowerCase());
    }
    return true;
  };

  const customValidator = (file) => {
    const isValidFile = isValidSize(file) && isTypeAllowed(file);
    if (!isValidFile) {
      setHasError(!isValidFile);
    }
    return isValidFile;
  };

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const downloadImage = async (image) => {
    try {
      const imageUrl = image.url || image.imageUrl;
      if (!imageUrl) {
        return false;
      }

      let fileName = imageUrl.includes("/") ? imageUrl.split("/").pop() : "download.jpeg";
      const extension = fileName.split(".").length > 1 && fileName.split(".")[1];

      if (extension.length > 4) {
        fileName = `${fileName}.jpeg`;
      }

      const a = document.createElement("a");
      a.href = await toDataURL(imageUrl);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return true;
    } catch (err) {
      return false;
    }
  };

  const uploadedFiles = () => {
    if (files.length === 0 && existingImages.length === 0) {
      return null;
    }

    return (
      <Stack>
        {existingImages.map((image, index) => (
          <Stack key={`dropZoneUploadedImage${image.position}`} alignment="center" vertical>
            <Stack.Item fill>
              <LazyLoad once height={80} placeholder={skeletalThumbnail}>
                <Thumbnail
                  alt={`image${image.position}`}
                  size={constant.LARGE}
                  source={imageHelper.resize({
                    url: image.url ? image.url : image.imageUrl,
                    type: constant.imageTypes.THUMBNAIL,
                  })}
                />
              </LazyLoad>
            </Stack.Item>
            <Stack.Item>
              <ButtonGroup segmented>
                <Tooltip content="Download original image.">
                  <Button
                    loading={image.position === loadingPosition}
                    disabled={(loadingPosition && !image.position === loadingPosition) || disabled}
                    onClick={() => downloadImage(image)}
                  >
                    <i className="far fa-file-download fa-lg" />
                  </Button>
                </Tooltip>
                <Tooltip content="Delete image.">
                  <Button
                    loading={image.position === loadingPosition}
                    disabled={(loadingPosition && !image.position === loadingPosition) || disabled}
                    onClick={() => removeExistingImage(image, index)}
                  >
                    <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack.Item>
          </Stack>
        ))}
        {files.map((file, index) => {
          const imageURL = file.url || file.imageUrl || file;
          if (!imageURL) {
            return null;
          }
          return (
            <Stack alignment="center" vertical key="uploadedImages">
              <Stack.Item fill>
                <Thumbnail
                  size="large"
                  alt={file.name}
                  source={imageHelper.resize({ url: imageURL, type: constant.imageTypes.THUMBNAIL })}
                />
              </Stack.Item>
              <Stack.Item>
                <Button onClick={() => removeFile(file, existingImages.length + index)} disabled={disabled}>
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const errorMessage = rejectedFiles.length && hasError && (
    <Banner title={`${cms("common.dropZone.title")}:`} status={constant.CRITICAL} onDismiss={() => setHasError(false)}>
      <List type="bullet">
        {rejectedFiles.map((file) => (
          <List.Item>
            {cms("common.dropZone.caption", {
              name: file.name,
              type: allowedTypes.map((value) => ` ${value}`),
              size: `${props.size + props.sizeType}`,
            })}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const isDropZoneShown = (!allowMultiple && files.length === 0) || allowMultiple;

  return (
    <Stack vertical>
      {imageSizeError && (
        <>
          <Banner
            isOpen={imageSizeError}
            status="critical"
            title="unable to upload image as image size is more than 20 megapixel"
            onDismiss={() => setImageSizeError(false)}
          />
          <br />
        </>
      )}
      {errorMessage}
      {uploadedFiles()}
      {isDropZoneShown && (
        <PolarisDropZone
          id={id}
          accept="image/*"
          type="image"
          onDrop={handleDrop}
          allowMultiple={allowMultiple}
          customValidator={customValidator}
          disabled={disabled}
          label={label}
        >
          {fileUpload}
        </PolarisDropZone>
      )}
    </Stack>
  );
};

DropZone.propTypes = {
  allowMultiple: PropTypes.bool,
  existingImageList: PropTypes.arrayOf(PropTypes.any),
  fileList: PropTypes.arrayOf(PropTypes.any),
  loadingPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  removeExistingImage: PropTypes.func,
  size: PropTypes.number,
  sizeType: PropTypes.string,
  allowedTypes: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
DropZone.defaultProps = {
  allowedTypes: ["gif", "jpeg", "jpg", "png", "svg"],
  allowMultiple: false,
  disabled: false,
  existingImageList: [],
  fileList: [],
  id: "dropZone",
  label: "",
  loadingPosition: "",
  onAdd: () => {},
  onRemove: () => {},
  removeExistingImage: () => {},
  size: 1,
  sizeType: "MB",
};

export default DropZone;
