import React, { useContext, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Caption,
  Card,
  Collapsible,
  Checkbox,
  DataTable,
  Layout,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { GET_INVOICE_SETTING } from "app/setup/apollo/queries";
import { UPDATE_INVOICE_LABEL_SETTING } from "app/setup/apollo/mutations";
import StyledTable from "app/setup/modules/operator/features/invoice/label/style";
import validateLabel from "./validateLabel";

function InvoiceLabel(props) {
  const { CRITICAL, HIDDEN, MANAGE_INVOICE_COLUMN_CONTENT, MANAGE_INVOICE_COLUMN_HEADING, SUCCESS, VISIBLE } = constant;
  const { setMessage, setBanner } = props;
  const { cms, history } = useContext(PrivateContext);
  const { gql } = constant;
  const [firstPush, setFirstPush] = useState(true);
  const [showManageInvoice, setShowManageInvoice] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [updateAt, setUpdateAt] = useState("");
  const [userInput, setUserInput] = useState({
    purchaseFrom: {
      label: "",
      isHide: false,
    },
    deliverTo: {
      label: "",
      isHide: false,
    },
    product: {
      label: "",
      isHide: false,
    },
    sku: {
      label: "",
      isHide: false,
    },
    quantity: {
      label: "",
      isHide: false,
    },
    price: {
      label: "",
      isHide: false,
    },
    tax: {
      label: "",
      isHide: false,
    },
    discount: {
      label: "",
      isHide: false,
    },
    // status: {
    //   label: "",
    //   isHide: false,
    // },
    subtotal: {
      label: "",
      isHide: false,
    },
    shipping: {
      label: "",
      isHide: false,
    },
    sellerCommission: {
      label: "",
      isHide: false,
    },
    totalDeduction: {
      label: "",
      isHide: false,
    },
    orderTotal: {
      label: "",
      isHide: false,
    },
    orderPayout: {
      label: "",
      isHide: false,
    },
    totalVendorPayout: {
      label: "",
      isHide: false,
    },
    fulfillBy: {
      label: "",
      isHide: false,
    },
    totalOrderTax: {
      label: "",
      isHide: false,
    },
    totalAmount: {
      label: "",
      isHide: false,
    },
    totalDeductionAmount: {
      label: "",
      isHide: false,
    },
    totalOrderShipping: {
      label: "",
      isHide: false,
    },
    totalDeductionTax: {
      label: "",
      isHide: false,
    },
    totalOrderAmount: {
      label: "",
      isHide: false,
    },
  });
  const [errorMessage, setErrorMessage] = useState({
    deliverTo: "",
    discount: "",
    fulfillBy: "",
    orderPayout: "",
    orderTotal: "",
    price: "",
    product: "",
    purchaseFrom: "",
    quantity: "",
    sellerCommission: "",
    shipping: "",
    sku: "",
    // status: "",
    subtotal: "",
    tax: "",
    totalDeduction: "",
    totalVendorPayout: "",
    totalOrderTax: "",
    totalAmount: "",
    totalOrderShipping: "",
    totalDeductionTax: "",
    totalDeductionAmount: "",
    totalOrderAmount: "",
  });

  const disabledCheckboxList = [
    "product",
    "price",
    "quantity",
    "subtotal",
    "total",
    "orderTotal",
    "totalOrderAmount",
    "totalVendorPayout",
  ];
  const mappingObject = {
    deliverTo: "deliverTo",
    discount: "discount",
    fulfillBy: "fulfillBy",
    orderPayout: "orderPayout",
    orderTotal: "orderTotal",
    price: "price",
    product: "item",
    purchaseFrom: "purchaseFrom",
    quantity: "quantity",
    sellerCommission: "commission",
    shipping: "shipping",
    sku: "sku",
    // status: "status",
    subtotal: "subtotal",
    tax: "tax",
    totalDeduction: "totalDeduction",
    totalVendorPayout: "totalVendorPayout",
    totalOrderTax: "totalOrderTax",
    totalAmount: "totalAmount",
    totalOrderShipping: "totalOrderShipping",
    totalDeductionTax: "totalDeductionTax",
    totalDeductionAmount: "totalDeductionAmount",
    totalOrderAmount: "totalOrderAmount",
  };

  const { error: errorManageInvoiceSetting, data: dataManageInvoiceSetting, refetch } = useQuery(GET_INVOICE_SETTING);
  const [manageInvoiceSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_INVOICE_LABEL_SETTING);

  const manageInvoiceResponseData = baseHelper.getResponseData(dataManageInvoiceSetting, gql.GET_INVOICE_SETTING);
  const manageInvoiceError = baseHelper.getResponseError(errorManageInvoiceSetting, gql.GET_INVOICE_SETTING);
  const { payment } = manageInvoiceResponseData || {};

  useEffect(() => {
    if (payment) {
      const { invoice } = payment;
      if (invoice) {
        const { manage: manageInvoiceData } = invoice;
        if (manageInvoiceData) {
          const { updatedAt: lastUpdatedAt, createdAt } = manageInvoiceData;
          delete manageInvoiceData.updatedAt;
          delete manageInvoiceData.createdAt;
          setUserInput({ ...userInput, ...manageInvoiceData });
          setUpdateAt(lastUpdatedAt);
          if (createdAt !== updateAt) {
            setFirstPush(false);
          }
        }
      }
    }
    if (manageInvoiceError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(manageInvoiceError), isOpen: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, manageInvoiceError]);

  const verifyValues = () => {
    setSubmitEnable(false);
    setBanner({ isOpen: false, status: "", title: "" });
    const newLabelsList = Object.values(userInput)
      .map((item) => item.label)
      .filter((object) => object);
    const uniqueLabelValues = new Set(newLabelsList);
    if (newLabelsList.length !== uniqueLabelValues.size) {
      setBanner({
        isOpen: true,
        title: cms("section.labelSetting.message.error.duplicate"),
        status: constant.CRITICAL,
      });
      return true;
    }
    return false;
  };
  const updateSetting = () => {
    manageInvoiceSetting({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_INVOICE_LABEL_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_INVOICE_LABEL_SETTING);
          if (resData) {
            refetch();
            setMessage(cms("message.success.order"));
            setUpdateAt(new Date());
          }
          if (resError) {
            setBanner({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
    setMessage(
      firstPush ? cms("section.labelSetting.message.success.save") : cms("section.labelSetting.message.success.update")
    );
  };

  const handleValidate = async (value, key) => {
    const validationError = await validateLabel(value, cms("section.labelSetting.message.error.maxLength"));
    setErrorMessage((prevState) => ({
      ...prevState,
      [key]: validationError,
    }));
    if (validationError) {
      setSubmitEnable(false);
    }
  };

  const handleLabelChange = (val, key) => {
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val;
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };

  const buttonDisable = () => {
    let disable = false;
    Object.keys(errorMessage).forEach((item) => {
      if (errorMessage[item] !== "") {
        disable = true;
      }
    });
    return disable;
  };

  const selectCurrentLabelsColumn = (item) => {
    const key = mappingObject[item];
    return <TextStyle>{cms(`section.labelSetting.label.${key}`) || item}</TextStyle>;
  };

  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField
        value={data && data.label}
        onChange={(value) => handleLabelChange(value, key)}
        onBlur={() => handleValidate(data && data.label, key)}
        error={errorMessage && errorMessage[key]}
      />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === "isHide") {
      setUserInput({ ...userInput, [key]: { ...updateData, isHide: !updateData.isHide } });
    }
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (key) => (
    <>
      <div align="center">
        <Checkbox
          checked={userInput[key] && userInput[key].isHide}
          disabled={disabledCheckboxList.includes(key)}
          onChange={() => handleCheckbox("isHide", key)}
        />
      </div>
    </>
  );

  const getRows = () => {
    return Object.keys(userInput).map((item) => {
      let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
      const itemData = userInput[item] || {};
      if (itemData.isHide) {
        badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
      } else {
        badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
      }
      const currentValues = selectCurrentLabelsColumn(item);
      const newValues = selectNewLabelsTextField(userInput[item], item);
      const badgesValues = badges(badge);
      const hideColumnValues = selectHideColumn(item);
      return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
    });
  };
  return (
    <>
      <Layout.AnnotatedSection
        title={cms("section.labelSetting.title")}
        description={cms("section.labelSetting.description")}
      >
        <Card
          sectioned
          title={[
            cms("section.labelSetting.card.title"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          actions={[
            {
              content: showManageInvoice ? cms("common.label.hide") : cms("common.label.show"),
              onAction: () => setShowManageInvoice(!showManageInvoice),
              disclosure: showManageInvoice ? constant.UP : constant.DOWN,
            },
          ]}
        >
          <div className="toggle-action">
            <TextStyle>{cms("section.labelSetting.card.subtitle")}</TextStyle>
          </div>
          <Collapsible open={showManageInvoice} id="manageInvoice">
            <StyledTable className="product-form-label-table">
              <DataTable
                columnContentTypes={MANAGE_INVOICE_COLUMN_CONTENT}
                headings={MANAGE_INVOICE_COLUMN_HEADING}
                rows={getRows()}
              />
            </StyledTable>
            <br />
            <Stack horizonatl distribution="equalSpacing">
              <Button secondry onClick={() => history.push("/setting")}>
                {cms("common.button.cancel")}
              </Button>
              <Button
                primary
                submit
                disabled={buttonDisable() || !submitEnabled}
                onClick={() => {
                  const update = verifyValues();
                  if (!update) updateSetting();
                }}
                loading={settingUpdateLoading}
              >
                {firstPush ? cms("common.button.submit") : cms("common.button.update")}
              </Button>
            </Stack>
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}

export default withFeature(withErrorBoundary(InvoiceLabel), {
  feature: constant.PAYMENT_INVOICE_LABEL_SETTING,
});
