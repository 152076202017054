import React from "react";

import EditVendorProfile from "app/vendors/modules/generic/feature/edit/edit";

const OperatorVendorEdit = () => {
  return (
    <>
      <EditVendorProfile />
    </>
  );
};

export default OperatorVendorEdit;
