const quickReportConfig = ({ cms = {}, count = null, path = "" }) => {
  const { inviteCount = {}, orderCount = {}, paymentCount = {}, productCount = {}, vendorCount = {} } = count;

  const {
    joined: joinedInviteCount = null,
    newInvites: newInviteCount = null,
    pending: pendingInviteCount = null,
    revoked: revokedInviteCount = null,
    total: totalInviteCount = null,
  } = inviteCount;

  const {
    archived: archivedOrderCount = null,
    pendingAcceptance: pendingAcceptanceOrderCount = null,
    pendingShipment: pendingShipmentOrderCount = null,
    rejected: rejectedOrderCount = null,
    return: returnOrderCount = null,
    shipped: shippedOrderCount = null,
    total: totalOrderCount = null,
    completed: completedOrderCount = null,
    pending: pendingOrderCount = null,
    processing: processingOrderCount = null,
    refunded: refundedOrderCount = null,
  } = orderCount;

  const {
    // disputed: disputedPaymentCount = null,
    unpaid: paymentUnpaidCount = null,
    // forcasted: forcastedPaymentCount = null,
    paid: paymentPaidCount = null,
    all: paymentTotalCount = null,
  } = paymentCount;

  const {
    approved: approvedProductCount = null,
    delete: deletedProductCount = null,
    new: newProductCount = null,
    reject: rejectedProductCount = null,
    review: reviewProductCount = null,
    reviewed: reviewedProductCount = null,
    total: totalProductCount = null,
  } = productCount;

  const {
    approved: approvedProviderCount = null,
    new: pendingApprovalProviderCount = null,
    total: totalProviderCount = null,
  } = vendorCount;

  const config = {
    "/": [
      {
        caption: cms("section.quickReport.caption.order"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.order"),
      },
      {
        caption: cms("section.quickReport.caption.product"),
        count: totalProductCount,
        label: cms("section.quickReport.label.product"),
      },
      {
        caption: cms("section.quickReport.caption.vendor"),
        count: totalProviderCount,
        label: cms("section.quickReport.label.vendor"),
      },
      {
        caption: cms("section.quickReport.caption.invite"),
        count: totalInviteCount,
        label: cms("section.quickReport.label.invite"),
      },
    ],
    "/invitation": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalInviteCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newInviteCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.join"),
        count: joinedInviteCount,
        label: cms("section.quickReport.label.join"),
      },
      {
        caption: cms("section.quickReport.caption.revoke"),
        count: revokedInviteCount,
        label: cms("section.quickReport.label.revoke"),
      },
      {
        caption: cms("section.quickReport.caption.pending"),
        count: pendingInviteCount,
        label: cms("section.quickReport.label.pending"),
      },
    ],
    "/orders": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.acceptance"),
        count: pendingAcceptanceOrderCount,
        label: cms("section.quickReport.label.acceptance"),
      },
      {
        caption: cms("section.quickReport.caption.shipment"),
        count: pendingShipmentOrderCount,
        label: cms("section.quickReport.label.shipment"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedOrderCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.ship"),
        count: shippedOrderCount,
        label: cms("section.quickReport.label.ship"),
      },
      {
        caption: cms("section.quickReport.caption.archive"),
        count: archivedOrderCount,
        label: cms("section.quickReport.label.archive"),
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: returnOrderCount,
        label: cms("section.quickReport.label.return"),
      },
    ],
    "/order-lake": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.all") || "All Count",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: completedOrderCount,
        label: cms("section.quickReport.label.return") || "Completed",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: pendingOrderCount,
        label: cms("section.quickReport.label.return") || "Pending",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: processingOrderCount,
        label: cms("section.quickReport.label.return") || "Processing",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: refundedOrderCount,
        label: cms("section.quickReport.label.return") || "Refunded",
      },
    ],
    "/payments": [
      {
        caption: cms("quickReport.caption.all"),
        count: paymentTotalCount,
        label: cms("quickReport.label.all"),
      },
      {
        caption: cms("quickReport.caption.due"),
        count: paymentUnpaidCount,
        label: cms("quickReport.label.due"),
      },
      {
        caption: cms("quickReport.caption.paid"),
        count: paymentPaidCount,
        label: cms("quickReport.label.paid"),
      },
    ],
    "/products": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalProductCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.approve"),
        count: approvedProductCount,
        label: cms("section.quickReport.label.approve"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newProductCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.review"),
        count: reviewProductCount,
        label: cms("section.quickReport.label.review"),
      },
      {
        caption: cms("section.quickReport.caption.reviewed"),
        count: reviewedProductCount,
        label: cms("section.quickReport.label.reviewed"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedProductCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.delete"),
        count: deletedProductCount,
        label: cms("section.quickReport.label.delete"),
      },
    ],
    "/product-lake": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalProductCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.approve"),
        count: approvedProductCount,
        label: cms("section.quickReport.label.approve"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newProductCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.review"),
        count: reviewProductCount,
        label: cms("section.quickReport.label.review"),
      },
      {
        caption: cms("section.quickReport.caption.reviewed"),
        count: reviewedProductCount,
        label: cms("section.quickReport.label.reviewed"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedProductCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.delete"),
        count: deletedProductCount,
        label: cms("section.quickReport.label.delete"),
      },
    ],
    "/providers": [
      {
        caption: cms("sectionContent.quickReport.caption.all"),
        count: totalProviderCount,
        label: cms("sectionContent.quickReport.label.all"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.pending"),
        count: pendingApprovalProviderCount,
        label: cms("sectionContent.quickReport.label.pending"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.approve"),
        count: approvedProviderCount,
        label: cms("sectionContent.quickReport.label.approve"),
      },
    ],
  };
  return config[path];
};

export default quickReportConfig;
