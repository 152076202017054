import React, { useCallback, useContext, useState } from "react";
// import { ActionList, Button, Card, Collapsible, Popover } from "@shopify/polaris";
import { Button, ButtonGroup, Popover, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// config
// import constant from "lib/constant/constant";
import quickSettingConfig from "app/reports/provider/widgets/quickSetting/quickSettingConfig";
import { StyledActionCard, DropDown } from "app/reports/generic/style/quickAction";
import ProviderExportProduct from "app/productOld/modules/provider/features/exportProduct/export";
import { StyledView } from "asset/styles/globalStyle";
import quickAction from "./quickActionConfig";

const ProviderQuickAction = (props) => {
  const { link = [], storedata = [], linkView = [] } = props;
  const { cms, currentUser, match, history } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const { path } = match;
  // const [actionLinkExpanded, setExpanded] = useState(false);
  const quickActionConfig = quickAction(cms);
  const { config, title } = quickSettingConfig(cms);
  const [popoverActive, setPopoverActive] = useState(false);
  const [popoverStoreActive, setPopoverStoreActive] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const actionCard = (action) => {
    const onAction = action.action || (() => history.push(action.link));
    return (
      <>
        <Button primary={action.primary} disabled={!!action.disabled} onClick={onAction} loading={action.loading}>
          {action.label}
        </Button>
      </>
    );
  };
  const actionCardView = (action) => {
    const onAction = action.action || (() => history.push(action.linkView));
    return (
      <>
        <Button primary={action.primary} disabled={!!action.disabled} onClick={onAction} loading={action.loading}>
          {action.label}
        </Button>
      </>
    );
  };
  // eslint-disable-next-line no-shadow
  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);
  const toggleStorePopoverActive = useCallback(
    // eslint-disable-next-line no-shadow
    () => setPopoverStoreActive((popoverStoreActive) => !popoverStoreActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {title[path] || cms("common.actions.title.setting")}
    </Button>
  );

  const storeActivator = (
    <Button onClick={toggleStorePopoverActive} disclosure>
      {cms("common.button.storeSetting")}
    </Button>
  );
  const exportProductactivator = (
    <Button primary onClick={() => setModal(!isOpenModal)}>
      {cms("section.export.label.title")}
    </Button>
  );

  const getQuickActions = (actions = []) => {
    return <ButtonGroup>{actions.map((links) => actionCard(links))}</ButtonGroup>;
  };

  const getOrderQuickActions = (actions = []) => {
    return <ButtonGroup>{actions.map((links) => actionCardView(links))}</ButtonGroup>;
  };

  const getQuickSettings = (settings = []) => {
    // return settings.map((setting) => actionCard(setting));
    if (settings.length === 1) {
      return settings.map((setting) => <div>{actionCard(setting)}</div>);
    }

    const options = settings.map((setting) => (
      <li>
        <Button plain onClick={() => history.push(setting.link)}>
          {setting.label}
        </Button>
      </li>
    ));

    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <DropDown className="drop-down">{options}</DropDown>
      </Popover>
    );
  };
  const getStoreQuickSettings = (storeData) => {
    // return settings.map((setting) => actionCard(setting));
    if (storeData.length === 1) {
      return storeData.map((setting) => <div>{actionCard(setting)}</div>);
    }
    const options = storeData.map((storeDataOptions) => (
      <li>
        <Button
          plain
          onClick={() => {
            if (
              storeDataOptions.id === "importConnectProduct" ||
              storeDataOptions.id === "connectStoreProduct" ||
              storeDataOptions.id === "connectShop"
            ) {
              history.push(storeDataOptions.link);
            } else {
              storeDataOptions.action();
            }
          }}
        >
          {storeDataOptions.label}
        </Button>
      </li>
    ));
    return (
      <Popover active={popoverStoreActive} activator={storeActivator} onClose={toggleStorePopoverActive}>
        <DropDown className="drop-down">{options}</DropDown>
      </Popover>
    );
  };
  const quickActions = (link.length && link) || quickActionConfig[path];
  const quickViewActions = (linkView.length && linkView) || quickActionConfig[path];
  const [firstElement, ...rest] = quickActions || [];

  const primaryViewActions = (quickViewActions || []).filter(
    (quickActionItem) => quickActionItem.primary || quickActionItem.caption
  );
  const primaryActions = (quickActions || []).filter((quickActionItem) => quickActionItem.primary);
  const secondaryActions = (quickActions || []).filter((quickActionItem) => !quickActionItem.primary);
  const pathNotForSecondaryActions = ["/products", "/product-lake"];

  return [
    <>
      {path === "/order-lake/view/:id" && (
        <StyledView>
          {primaryViewActions && primaryViewActions.length && (
            <Stack.Item>{getOrderQuickActions(primaryViewActions)}</Stack.Item>
          )}
        </StyledView>
      )}
      <Stack>
        {primaryActions && primaryActions.length && <Stack.Item>{getQuickActions(primaryActions)}</Stack.Item>}
        {config[path] && path === "/products" && <Stack.Item fill>{exportProductactivator}</Stack.Item>}
        <ProviderExportProduct openModal={isOpenModal} setOpenModal={setModal} />
        {secondaryActions && secondaryActions.length && !pathNotForSecondaryActions.includes(path) && (
          <Stack.Item>{getQuickActions(secondaryActions)}</Stack.Item>
        )}
        {config[path] && path === "/products" && (
          <Stack.Item>{getStoreQuickSettings((storedata.length !== 0 && storedata) || rest)}</Stack.Item>
        )}
        {config[path] && path === "/product-lake" && storedata.length && (
          <Stack.Item>{getStoreQuickSettings((storedata.length !== 0 && storedata) || rest)}</Stack.Item>
        )}
        {!ecommercePlatform && config[path] && <Stack.Item>{getQuickSettings(config[path])}</Stack.Item>}
      </Stack>
      <br />
    </>,
  ];
};

ProviderQuickAction.propTypes = {
  link: PropTypes.arrayOf(PropTypes.object),
  storedata: PropTypes.arrayOf(PropTypes.object),
};

ProviderQuickAction.defaultProps = {
  link: [],
  storedata: [],
};

export default ProviderQuickAction;
