import { gql } from "apollo-boost";

const UPDATE_SHIPPING_TAX_SETTING = gql`
  mutation updateShippingTaxSetting($input: UpdateShippingTaxSetting) {
    updateShippingTaxSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_SHIPPING_TAX_SETTING;
