import React, { useContext, useEffect, useState } from "react";

import { Badge, Card, Collapsible, Heading, Icon, Layout, Stack, TextContainer } from "@shopify/polaris";

import { CircleDisabledMajor, CircleTickMajor, TipsMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { Banner, Spinner as Loader, Toast } from "lib/components";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { NotFound } from "layout/private/components";

import { errorHelper } from "lib/helpers";
import { QuickAction } from "app/reports";

import {
  DOWNLOAD_LABEL,
  GET_ORDER_LAKE,
  GET_ORDER_LAKE_FULFILLMENT,
  GET_ORDER_LAKE_LINE_VIEW,
} from "app/orderLake/apollo/queries";
import { GET_SELLER_FULFILLMENT } from "app/setup/apollo";
import { FULFILL_ORDER_LAKE_ITEM, GENERATE_SHIPPING_LABEL } from "app/orderLake/apollo/mutations";

import { Customer, Fulfillments, FulfillmentStatus, Notes, UnfulfilledLineItems, FulfilledLineItems } from "./features";

const { gql, HIDE, PARTIAL_SHIPPED, PENDING, REFUND, PROCESS, UNPAID } = constant;

const ProviderOrderView = () => {
  const { cms, history, setPageData, match, currentUser } = useContext(PrivateContext);
  const [viewOrderData, setViewOrderData] = useState(false);
  const [viewLineOrderData, setViewLineOrderData] = useState(false);
  const [orderLakeLineFulfilledData, setOrderLakeLineFulfilledData] = useState(false);
  const [isError, setIsError] = useState(false);

  const [fulfillmentType, setFulfillmentType] = useState([]);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });

  const [message, setMessage] = useState("");
  const [toggleStatus, setToggleStatus] = useState({
    unfulfilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fullFillmentStatus: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulFilledLineItems: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    fulfillments: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
  });

  const [isDownloadShipping, setDownloadShipping] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isConsignmentValue, setConsignmentValue] = useState(false);

  const { loading: loadingOrderLineView, data: dataOrderLineView, refetch: refetchOrderLineView } = useQuery(
    GET_ORDER_LAKE_LINE_VIEW,
    {
      variables: { input: { orderId: match.params.id } },
    }
  );

  const { loading: loadingOrderLine, data: dataOrderLakeFulfillment } = useQuery(GET_ORDER_LAKE_FULFILLMENT, {
    variables: { input: { orderId: match.params.id } },
  });

  const [fulfillOrderLakeItem, { data: fulfillOrderLakeItemData, loading: fulfillOrderItemLoading }] = useMutation(
    FULFILL_ORDER_LAKE_ITEM
  );

  const [generateShippingLabel, { loading: shippingLabelLoading }] = useMutation(GENERATE_SHIPPING_LABEL);

  const [downloadLabel, { loading: downloadLabelLoading, data: downloadLabelData }] = useLazyQuery(DOWNLOAD_LABEL);

  useEffect(() => {
    const updateOrderTracking = baseHelper.getResponseData(fulfillOrderLakeItemData, gql.FULFILL_ORDER_LAKE_ITEM);
    if (updateOrderTracking) {
      setMessage(cms("message.success.requestProcess"));
    }
  }, [cms, fulfillOrderLakeItemData]);

  const { loading: loadingOrderView, data: dataOrderView, refetch: refetchOrderView } = useQuery(GET_ORDER_LAKE, {
    variables: { input: { orderId: match.params.id } },
  });

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  const fulfillmentView = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};

  const { dropship = {} } = fulfillmentView || {};
  const { isThirdPartyShipping = false } = dropship || {};

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  useEffect(() => {
    if (dataOrderLineView && dataOrderView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, gql.GET_ORDER_LAKE_LINE_VIEW);
      const orderView = baseHelper.getResponseData(dataOrderView, gql.GET_ORDER_LAKE);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, gql.GET_ORDER_LAKE_LINE_VIEW);
      const orderViewError = baseHelper.getResponseError(dataOrderView, gql.GET_ORDER_LAKE);

      if (orderLineView && orderView) {
        setViewOrderData(orderView);
        setViewLineOrderData(orderLineView);
      }
      if (orderLineViewError && orderViewError) {
        setIsError(true);
      }
    }
  }, [dataOrderLineView, dataOrderView]);

  useEffect(() => {
    if (viewOrderData && viewLineOrderData) {
      let isConsignmentValueExist = true;
      let showDownloadShippingLabel = false;
      const { storeOrderId = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      setPageData({
        title: `#${storeOrderId}`,
        breadcrumbs: [{ url: "/" }],
        subtitle: baseHelper.formatDate(createdAt),
      });
      viewLineOrderData.map((lineItem) => {
        if (!lineItem.consignmentId) {
          isConsignmentValueExist = false;
        } else {
          showDownloadShippingLabel = true;
        }
        return null;
      });
      setConsignmentValue(isConsignmentValueExist);
      // setDownloadLabel(showDownloadShippingLabel);
    }
  }, [viewOrderData, viewLineOrderData, history, match.params.id, setPageData]);

  useEffect(() => {
    if (dataOrderLakeFulfillment) {
      const orderLakeFulfillment = baseHelper.getResponseData(dataOrderLakeFulfillment, gql.GET_ORDER_LAKE_FULFILLMENT);
      const orderLakeFulfillmentError = baseHelper.getResponseError(
        dataOrderLakeFulfillment,
        gql.GET_ORDER_LAKE_FULFILLMENT
      );

      if (orderLakeFulfillment && orderLakeFulfillment.length) {
        setOrderLakeLineFulfilledData(orderLakeFulfillment);
      }
      if (orderLakeFulfillmentError) {
        setIsError(true);
      }
    }
  }, [dataOrderLakeFulfillment]);

  useEffect(() => {
    if (isDownload) {
      if (downloadLabelLoading) {
        setMessage(cms("message.success.requestProcess"));
      }

      if (!downloadLabelLoading && downloadLabelData) {
        const responseData = baseHelper.getResponseData(downloadLabelData, gql.DOWNLOAD_LAKE_SHIPPING_LABEL);
        let bannerData = {
          title: cms("message.success.downloadSuccess"),
          status: constant.SUCCESS,
          isOpen: false,
        };
        if (responseData) {
          setMessage(cms("message.success.downloadSuccess"));
        }
        if (!responseData) {
          bannerData = {
            title:
              baseHelper.getResponseError(downloadLabelData, gql.DOWNLOAD_LAKE_SHIPPING_LABEL) || errorHelper.parse(),
            status: constant.CRITICAL,
            isOpen: true,
          };
          setBanner(bannerData);
          return;
        }

        const { consignmentId = "", consignmentUrl = "", pdf = {}, shippingOption = "" } = responseData || {};
        if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else if (shippingOption === constant.SHIPPO) {
          if (!consignmentUrl) {
            bannerData = {
              title: cms("message.error.labelNotGenerated"),
              status: constant.WARNING,
              isOpen: true,
            };
            setBanner(bannerData);
            return;
          }

          const { params = {} } = match;
          const { id: orderId } = params;

          fetch(consignmentUrl)
            .then((res) => {
              if (res && !res.ok) {
                return false;
              }
              return res.blob();
            })
            .then((result) => {
              if (!result) {
                bannerData = {
                  title: cms("message.error.unableToDownloadPdf"),
                  status: constant.CRITICAL,
                  isOpen: true,
                };
                setBanner(bannerData);
                return;
              }
              const url = window.URL.createObjectURL(result);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `vendor-${orderId}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              setBanner(bannerData);
            });
          setIsDownload(false);
        } else {
          const buffer = new Buffer(pdf);
          const newBlob = new Blob([buffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(newBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `vendor-${consignmentId}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          setBanner(bannerData);
          setIsDownload(false);
        }
      }
    }
  }, [downloadLabelLoading, downloadLabelData]);

  const handleToggle = (evt) => {
    const { id } = evt.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? cms("common.label.show") : cms("common.label.hide"),
      },
    }));
  };

  const renderHeading = (title, count, fulfilled = false) => {
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          {title === baseHelper.ucFirst(constant.REJECTED) && (
            <Icon source={CircleDisabledMajor} backdrop color={constant.REDDARK} />
          )}
          {title === baseHelper.ucFirst(constant.RETURN) && (
            <Icon source={CircleTickMajor} backdrop color={constant.REDDARK} />
          )}
          {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
            <Icon
              source={fulfilled ? CircleTickMajor : CircleDisabledMajor}
              backdrop
              color={fulfilled ? constant.GREENDARK : constant.YELLOWDARK}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
        {title === baseHelper.ucFirst(constant.REJECTED) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title === baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={constant.CRITICAL}>{count}</Badge>
          </Stack.Item>
        )}
        {title !== baseHelper.ucFirst(constant.REJECTED) && title !== baseHelper.ucFirst(constant.RETURN) && (
          <Stack.Item>
            <Badge status={fulfilled ? constant.SUCCESS : constant.ATTENTION}>{count}</Badge>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const renderPaymentSectionHeading = (status, isAllFullfilled) => {
    const title = (isAllFullfilled && cms("label.paid")) || baseHelper.ucFirst(UNPAID);
    const iconSource = (isAllFullfilled && CircleTickMajor) || CircleDisabledMajor;
    const iconColor = isAllFullfilled ? constant.GREENDARK : constant.YELLOWDARK;
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          <Icon source={iconSource} backdrop color={iconColor} />
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
      </Stack>
    );
  };

  const linkData = [];
  const downloadLabelId = "download-shipping-Label";
  const generateLabelId = "shipping-Label";

  const downloadConsignmentLabel = () => {
    setDownloadShipping(false);
    const { params = {} } = match;
    const { id: orderId } = params;
    downloadLabel({ variables: { input: { orderId, vendorId: currentUser.id } } });
    setIsDownload(true);
  };

  const generateLabel = async () => {
    setMessage(cms("message.success.requestProcess"));
    const { params = {} } = match;
    const { id: orderId } = params;
    const response = await generateShippingLabel({ variables: { input: { orderId, vendorId: currentUser.id } } });
    const shippingLabelResponse = baseHelper.getResponseData(response.data, gql.GENERATE_LAKE_SHIPPING_LABEL);
    const shippingLabelError = baseHelper.getResponseError(response.data, gql.GENERATE_LAKE_SHIPPING_LABEL);
    if (shippingLabelResponse) {
      refetchOrderLineView();
      // refetchOrderFulfillment();
    }

    if (shippingLabelError) {
      const bannerData = {
        isOpen: true,
        title: shippingLabelError, // || errorHelper.parse(),
        status: constant.CRITICAL,
      };
      setBanner(bannerData);
    }
  };

  if (fulfillmentType && fulfillmentType.includes(constant.DROPSHIP) && isThirdPartyShipping && !isConsignmentValue) {
    const shippingLabel = {
      action: () => generateLabel(),
      caption: cms("label.shippingLabel"),
      id: generateLabelId,
      label: cms("label.shippingLabel"),
      icon: TipsMajor,
      primary: true,
      loading: shippingLabelLoading,
    };

    linkData.push(shippingLabel);
  }

  if (fulfillmentType && fulfillmentType.includes(constant.DROPSHIP) && isThirdPartyShipping && isConsignmentValue) {
    const shippingLabel = {
      action: () => downloadConsignmentLabel(),
      caption: cms("label.downloadShippingLabel"),
      id: downloadLabelId,
      label: cms("label.downloadShippingLabel"),
      icon: TipsMajor,
      loading: downloadLabelLoading,
    };

    linkData.push(shippingLabel);
  }

  const renderItemData = (dataOrderViews, dataOrderLineViews) => {
    const orderLineItemsData = dataOrderLineViews;
    const isAllCompleted = orderLineItemsData.every((data) =>
      ["completed", "shipped"].includes(data.storeStatus && data.storeStatus.toLowerCase())
    );
    const { storeStatus } = dataOrderViews || {};
    if (!orderLineItemsData)
      return (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      );
    const inProgressArray = [
      PENDING,
      REFUND,
      PROCESS,
      PARTIAL_SHIPPED,
      "awaiting fulfillment",
      "partially shipped",
      "awaiting payment",
      "awaiting shipment",
      "incomplete",
      "cancelled",
      "declined",
      "awaiting pickup",
      "manual verification required",
      "disputed",
      "partially refunded",
    ];

    const orderStoreFulfillment = baseHelper.getStoreFulfillmentData(orderLakeLineFulfilledData);
    // updating lineItem data based on shipment data
    baseHelper.getLineItemMapData(orderStoreFulfillment, orderLineItemsData);

    const inProgress = orderLineItemsData
      .map(
        (item) => inProgressArray.includes(item.storeStatus && item.storeStatus.toLowerCase())
        // !(item.trackingCompany && item.trackingNumber)
      )
      .includes(true);
    let unfulfillCount = constant.value.ZERO;
    let fulfillCount = constant.value.ZERO;
    let paymentCount = constant.value.ZERO;
    if (orderLineItemsData) {
      unfulfillCount = orderLineItemsData.filter(
        (lineItem) => inProgressArray.includes(lineItem.storeStatus && lineItem.storeStatus.toLowerCase())
        // !(lineItem.trackingCompany && lineItem.trackingNumber)
      ).length;

      fulfillCount = orderLineItemsData.filter(
        (lineItem) =>
          [constant.COMPLETE, constant.SHIPPED].includes(lineItem.storeStatus && lineItem.storeStatus.toLowerCase())
        // (lineItem.trackingCompany && lineItem.trackingNumber)
      ).length;

      paymentCount = orderLineItemsData.filter((lineItem) =>
        lineItem && lineItem.isRefund
          ? !(
              lineItem.storeStatus === constant.REJECTED &&
              lineItem.storeStatus === constant.FULFILLED &&
              lineItem.quantity === 0
            )
          : !(
              lineItem.storeStatus === constant.REJECTED &&
              (lineItem.storeStatus === constant.UNFULFILLED || lineItem.storeStatus === constant.REFUND)
            )
      ).length;
    }
    const fulfillments = orderLineItemsData.filter((lineItem) => lineItem.trackingCompany || lineItem.trackingNumber);

    return (
      <>
        {(inProgress && (
          <Card
            title={renderHeading("In Progress", unfulfillCount)}
            actions={[
              {
                content: toggleStatus.unfulfilledLineItems.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.UNFULFILLEDLINEITEMS,
              },
            ]}
          >
            <br />
            <Collapsible id="unfulfilledLineItems" open={toggleStatus.unfulfilledLineItems.active}>
              <UnfulfilledLineItems
                itemData={orderLineItemsData}
                data={dataOrderViews}
                fulfillmentType={fulfillmentType}
                setFulfillmentType={setFulfillmentType}
                setBanner={setBanner}
                setMessage={setMessage}
                fulfillOrderLakeItem={fulfillOrderLakeItem}
                fulfillOrderLakeItemData={fulfillOrderLakeItemData}
                onRefetchOrderView={refetchOrderView}
                onRefetchOrderLineView={refetchOrderLineView}
                fulfillOrderItemLoading={fulfillOrderItemLoading}
                currentUser={currentUser}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(fulfillCount && (
          <Card
            title={renderHeading("Completed", fulfillCount, true)}
            actions={[
              {
                content: toggleStatus.fulFilledLineItems.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULFILLEDLINEITEMS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLEDLINEITEMS} open={toggleStatus.fulFilledLineItems.active}>
              <FulfilledLineItems
                lineData={orderLineItemsData}
                fulfillmentType={fulfillmentType}
                data={dataOrderViews}
                setFulfillmentType={setFulfillmentType}
              />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(fulfillments.length && (
          <Card
            title={renderHeading("Fulfillments", fulfillments.length, true)}
            actions={[
              {
                content: toggleStatus && toggleStatus.fulfillments?.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULFILLMENTS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLMENTS} open={toggleStatus.fulfillments.active}>
              <Fulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(paymentCount && (
          <Card
            title={renderPaymentSectionHeading(storeStatus, isAllCompleted)}
            actions={[
              {
                content: toggleStatus.fullFillmentStatus.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULLFILLMENTSTATUS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULLFILLMENTSTATUS} open={toggleStatus.fullFillmentStatus.active}>
              <FulfillmentStatus lineData={orderLineItemsData} data={dataOrderViews} />
            </Collapsible>
          </Card>
        )) ||
          null}
      </>
    );
  };
  let isNote = true;
  const getSecondaryComponent = (dataOrderViews) => {
    const { note } = dataOrderViews.customer || {};
    if (!note) {
      isNote = false;
    }

    return (
      <>
        {dataOrderViews && (
          <>
            {linkData && linkData.length >= 1 && <QuickAction linkView={linkData} />}
            <Notes
              note={isNote && note}
              dataItem={dataOrderViews}
              setBanner={setBanner}
              setMessage={setMessage}
              onRefetchOrderView={refetchOrderView}
            />
            <br />
            <Customer data={dataOrderViews} />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      {(loadingOrderView || loadingOrderLineView || loadingOrderLine) && (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      )}
      <Layout.Section>
        {isError && (
          <Layout.Section>
            <div style={{ display: "flex" }}>
              <NotFound />
            </div>
          </Layout.Section>
        )}
        <Toast message={message} setToast={setMessage} timeout={5000} />
        {viewOrderData && viewLineOrderData && renderItemData(viewOrderData, viewLineOrderData)}
      </Layout.Section>
      <Layout.Section secondary>{getSecondaryComponent(viewOrderData)}</Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderOrderView), { feature: constant.VIEW_ORDER });
