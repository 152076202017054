class LocalizeHelper {
  hide = () => {
    return window.Localize && window.Localize.hideWidget();
  };

  show = () => {
    return window.Localize && window.Localize.showWidget();
  };
}

export default new LocalizeHelper();
