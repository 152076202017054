import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// import context
import { PrivateContext } from "lib/context";
// import constant
import constant from "lib/constant/constant";
// import components
import { Banner, Spinner } from "lib/components";
// import query
import { GET_PRODUCT_VARIANT_DETAILS } from "app/productOld/apollo/queries";

// import helpers
import { baseHelper } from "lib/helpers";
import productHelper from "./helper/product";

const ExportProductAsCSV = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [csvDetails, setCSVDetails] = useState([]);
  const [hasRecord, setHasRecord] = useState(true);
  const [getProductList, { data: getProductData, loading: productDetailsLoading, error: productError }] = useLazyQuery(
    GET_PRODUCT_VARIANT_DETAILS
  );
  const [pdfCount, setPdfCount] = useState(0);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  useEffect(() => {
    if (!productDetailsLoading && !productError && getProductData) {
      const resData = baseHelper.getResponseData(getProductData, "getProductVariantDetail");
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getProductData, "getProductVariantDetail");
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }

      const { productList = [], hasMore = false } = resData;
      setHasRecord(hasMore);
      if (!(productList && productList.length)) {
        bannerData = {
          title: cms("common.message.error.noProduct"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const { isSeller } = baseHelper.queryParamsFromLocation(data);
      const isExport = true;
      const isVendorCSV = !JSON.parse(isSeller);
      const csvData = productHelper.productCSVData(productList, {}, true, false, isVendorCSV, {}, isExport);
      if (csvData && csvData.length) {
        setPdfCount(pdfCount + 1);
        if (pdfCount > 0) {
          csvData.shift();
        }
        setCSVDetails([...csvDetails, ...csvData]);
      }

      if (hasMore) {
        setQueryData({ ...queryData, page: parseInt(queryData.page, 10) + 1 });
      }
    }
  }, [getProductData, productError, productDetailsLoading, cms, isVendor]);

  useEffect(() => {
    const { isSeller } = baseHelper.queryParamsFromLocation(data);

    if (csvDetails && csvDetails.length > 1) {
      const csvData = Baby.unparse(csvDetails);

      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      if (!hasRecord) {
        FileDownload(
          csvData,
          `${JSON.parse(isSeller) ? "Seller Product Export" : "Vendor Product Export"} ${date}.csv`
        );
      }
    }
  }, [csvDetails]);

  useEffect(() => {
    getProductList({ variables: { input: queryData } });
  }, [queryData]);

  useEffect(() => {
    const exportProduct = () => {
      const dataToFetch = { page: 1 };
      const { id, isSeller } = baseHelper.queryParamsFromLocation(data);
      if (isSeller) {
        dataToFetch.isSeller = JSON.parse(isSeller);
      }
      if (id) {
        dataToFetch.id = id;
      }
      setQueryData(dataToFetch);
    };
    if (isPageLoading) {
      exportProduct();
      setIsPageLoading(false);
    }
  }, [data, isPageLoading]);

  return (
    <Layout.Section>
      {productDetailsLoading && <Spinner isFullPage />}
      {banner.isOpen && (
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
        />
      )}
      {/* <Banner title="Product export is in progress" status="info" isOpen>
        <br />
        Please wait while we are exporting your products in a batch of 500. Please allow multiple downloads if there is
        a popup on your screen. <br /> <br />
        This tab will automatically close once all the downloads are completed.
      </Banner> */}
    </Layout.Section>
  );
};
export default withFeature(withErrorBoundary(ExportProductAsCSV), { feature: constant.EXPORT_PRODUCT });
