// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { TextField, Card, Stack, PageActions, TextContainer } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper, yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { IS_STORE_CONNECTED } from "app/product/apollo/mutations";

import config from "configuration";
import SyncProduct from "app/productLake/modules/provider/features/add/subFeatures/syncProduct";

// import components
import TermModal from "app/product/modules/provider/features/genric/term/term";
import ConnectedShop from "./subFeatureItems/connected";

const VendorConnectShopify = (props) => {
  const { history, currentUser: currentUserData, cms } = useContext(PrivateContext);
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { setBanner } = props;
  const [shop, setShop] = useState((currentUserData && currentUserData.shop) || "");
  const [isConnected, setIsConnected] = useState(!!(currentUserData && currentUserData.shop));
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isStoreConnected, { loading }] = useMutation(IS_STORE_CONNECTED);
  const { IS_STORE_CONNECTED: STORE_CONNECTED } = constant.gql;

  const onSubmit = async () => {
    const isValidSubDomain =
      yupHelper.subDomainRegex.test(shop) && baseHelper.isValidUrl(`https://${shop}.myshopify.com`);
    if (!isValidSubDomain) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.connect.message.error.invalidSubDomain"),
      });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isStoreConnected({
        variables: { input: { shop } },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECTED);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }
      const resData = baseHelper.getResponseData(response.data, STORE_CONNECTED);
      if (resData.supplier) {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${shop} ${cms("section.connect.message.error.alreadyConnected")}`,
        });
        return;
      }
      if (resData.associated) {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${cms("connect.message.error.cannotSync")} ${shop} ${cms(
            "section.connect.message.error.alreadyAssociated"
          )}`,
        });
        return;
      }
      setTimeout(() => {
        const clientId = config.vendorShopifyAPIKey;
        const redirectUri = baseHelper.getCurrentDomain(window.location, "providers/shopify-callback");
        const scope =
          "read_products, write_products, read_customers, write_customers, read_orders, " +
          "write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, " +
          "read_shipping, write_shipping, read_locations, write_order_edits, read_discounts, write_discounts ";
        const url =
          `https://${shop}.myshopify.com` +
          `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
        window.location.href = url;
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handelChange = (value) => {
    setShop(value);
  };
  const handleTermModal = (value) => {
    if (!shop) {
      setIsOpen(false);
      setErrorMessage(cms("section.connect.message.error.shopRequired"));
      return;
    }
    if (value === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  return (
    <>
      <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={shop}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.SHOPIFY_CAPITAL}
      />
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <TextField
            label={`${cms("section.connect.label.shop")}*`}
            error={errorMessage}
            id="shopInput"
            type={constant.TEXT}
            suffix=".myshopify.com"
            value={shop}
            onChange={(value) => handelChange(value)}
            onBlur={handleTermModal}
            disabled={isConnected}
          />
          <br />
          {currentUserData.shop && isConnected && (
            <Stack>
              <Stack.Item fill>
                <ConnectedShop
                  cms={cms}
                  currentUserData={currentUserData}
                  setBanner={setBanner}
                  setIsConnected={setIsConnected}
                  setShop={setShop}
                />
              </Stack.Item>
              <Stack.Item>
                <SyncProduct history={history} setBanner={setBanner} cms={cms} store={constant.SHOPIFY}/>
              </Stack.Item>
            </Stack>
          )}
          {!(currentUserData.shop && isConnected) && (
            <div className="manageCustomerButton">
              <PageActions
                primaryAction={{
                  id: "connectShop",
                  content: cms("section.connect.button.connect"),
                  onAction: () => handleTermModal(constant.CONNECT),
                  disabled: isConnected,
                }}
                secondaryActions={[
                  {
                    id: "cancelAction",
                    content: cms("section.connect.button.cancel"),
                    onAction: () => (isOnboarding && onPrevious()) || history.push("/product-lake"),
                  },
                ]}
              />
            </div>
          )}
        </Card.Section>
      </Card>
    </>
  );
};

VendorConnectShopify.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectShopify.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorConnectShopify), {
  feature: constant.PRODUCT_IMPORT_SHOPIFY,
  // subFeature: constant.PRODUCT_IMPORT_SHOPIFY,
});
