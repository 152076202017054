import { gql } from "apollo-boost";

const UPDATE_INVOICE_LABEL_SETTING = gql`
  mutation UpdateInvoiceLabelSetting($input: UpdateInvoiceLabelSettingInput!) {
    updateInvoiceLabelSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_INVOICE_LABEL_SETTING;
