import React, { useContext } from "react";
import { RadioButton } from "@shopify/polaris";

// propTypes

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { dropShipProp } from "app/setup/modules/operator/features/fulfillment/props";

const Dropship = (props) => {
  const { cms } = useContext(PrivateContext);
  const { data, handleChange } = props;
  const { isDropship } = data;
  return (
    <RadioButton
      label={cms("label.dropship")}
      helpText={cms("helpText.dropship")}
      checked={isDropship}
      onChange={() => handleChange("dropship")}
    />
  );
};

Dropship.propTypes = dropShipProp.type;

export default withFeature(Dropship, { feature: constant.DROPSHIP });
