import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import mutation
import { SYNC_VENDOR_MAGENTO_PRODUCT } from "app/product/apollo/mutations";

// import props
import { syncMagentoProduct } from "app/product/modules/generic/propTypes";

const SyncMagentoProduct = (props) => {
  const { cms, history, setBanner } = props;
  const { CRITICAL, gql } = constant;

  const [syncVendorMagentoProduct, { loading: syncVendorMagentoProductLoading }] = useMutation(
    SYNC_VENDOR_MAGENTO_PRODUCT
  );
  const syncMagentoProducts = async () => {
    try {
      const response = await syncVendorMagentoProduct();
      const responseError = baseHelper.getResponseError(response.data, gql.SYNC_VENDOR_MAGENTO_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/magento");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewMagentoProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: `${cms("section.connect.message.success.sync")}`,
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncVendorMagentoProductLoading} onClick={(value) => syncMagentoProducts(value)}>
      Import products from Magento store
    </Button>
  );
};

SyncMagentoProduct.propTypes = syncMagentoProduct.type;
SyncMagentoProduct.defaultProps = syncMagentoProduct.default;

export default SyncMagentoProduct;
