import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

import { OperatorProductEditCsv } from "./operator/features";
import { ProviderProductEditCsv } from "./provider/features";

const { userKey } = constant;
const { operator, provider } = userKey;

const ProductEditCsv = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProductEditCsv />;
    }
    if (role === provider) {
      return <ProviderProductEditCsv />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductsAddComponent = getComponent(currentUserRole);
  return ProductsAddComponent;
};

export default withErrorBoundary(ProductEditCsv);
