import { gql } from "apollo-boost";

const RE_SYNC_PRODUCT = gql`
  mutation reSyncProduct($input: reSyncProduct!) {
    reSyncProduct(input: $input) {
      data
      status
      error
    }
  }
`;
export default RE_SYNC_PRODUCT;
