import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { Invite } from "app/vendors/modules/generic/feature/add/subFeatures";

const OperatorInvite = (props) => {
  const {
    // learnMore
    setBanner,
  } = props;
  // return <Invite setBanner={setBanner} learnMore={learnMore} />;
  return <Invite setBanner={setBanner} />;
};

OperatorInvite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  // learnMore: PropTypes.func.isRequired,
};
export default withFeature(OperatorInvite, {
  feature: constant.VENDOR_INVITE_FORM,
  // subFeature: constant.VENDOR_INVITE_FORM,
});
