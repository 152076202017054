import { gql } from "apollo-boost";

const GET_PRODUCT_PHYSICAL_SETTING = gql`
  query {
    getProductPhysicalSetting {
      data {
        isDefaultNonPhysical
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_PRODUCT_PHYSICAL_SETTING;
