import React, { useState, useEffect, useCallback, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { SingleColumnLayout } from "layout/private/components";
import { QuickAction } from "app/reports";
import { LocationsMinor } from "@shopify/polaris-icons";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper } from "lib/helpers";
import { Banner } from "lib/components";

import constant from "lib/constant/constant";

// import query
import { GET_ADDRESS } from "app/setup/apollo";
// import components
import List from "./subFeature/list/list";

const ManageAddressBook = () => {
  const { history, cms } = useContext(PrivateContext);
  const queryParam = baseHelper.getQueryParams(history.location.search);

  const [currentPage, setCurrentPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [queryValue, setQueryValue] = useState(queryParam.search || "");
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || null);

  const [total, setTotal] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [addressList, setAddressList] = useState([]);
  const [dataToFetch, setDataToFetch] = useState({
    filter: queryParam.filter,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(queryParam.perPage, 10) || 10,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  });
  const [active, setActive] = useState(false);
  const [addressForm, setAddressForm] = useState({
    label: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
  });
  const defaultBanner = {
    isOpen: false,
    status: "",
    title: "",
  };
  const [banner, setBanner] = useState({ ...defaultBanner });
  const [error, setError] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const filterOptions = [{ label: cms("label.moreFilter.address"), value: constant.LABEL }];
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || null);
  const inputData = {
    filter: queryParam.filter,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(queryParam.perPage, 10) || 10,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };

  const { loading: addressLoading, data: addressData, error: getError, refetch } = useQuery(GET_ADDRESS, {
    variables: { input: inputData },
  });
  const isError = getError;
  useEffect(() => {
    if (isError) {
      setBanner({
        isOpen: true,
        title: isError,
        status: constant.CRITICAL,
      });
    }
  }, [isError, setBanner]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBanner({
        isOpen: true,
        title: errorMessage,
        status: constant.CRITICAL,
      });
    },
    [setBanner]
  );

  const addressError = baseHelper.getResponseError(addressData, constant.gql.GET_ADDRESS);
  useEffect(() => {
    if (addressError) {
      setErrorBanner(addressError);
    }
  }, [addressError, setErrorBanner]);
  const setIsExportModal = () => {
    setActive(!active);
    setAddressForm({
      label: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
    });
    setError({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const linkData = [
    {
      action: () => setIsExportModal(true),
      caption: "",
      id: "addressBook",
      label: cms("section.add.title"),
      icon: LocationsMinor,
    },
  ];

  const addressDataResponse = baseHelper.getResponseData(addressData, constant.gql.GET_ADDRESS);
  useEffect(() => {
    if (addressDataResponse) {
      setAddressList(addressDataResponse && addressDataResponse.addressList);
      setTotal(addressDataResponse && addressDataResponse.count);
      setIsFirst(addressDataResponse && addressDataResponse.isFirstAddress);
    }
  }, [addressDataResponse]);

  const toggleBanner = () => setBanner({ ...defaultBanner });
  const bannerStatus = () => {
    if (banner.isOpen) {
      return (
        <>
          <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
          <br />
        </>
      );
    }
    return null;
  };

  const getComponent = () => {
    return (
      <>
        {bannerStatus()}
        <QuickAction link={linkData} />
        <List
          active={active}
          addressDataResponse={addressList || []}
          addressForm={addressForm}
          banner={banner}
          cms={cms}
          currentPage={currentPage}
          dataToFetch={dataToFetch}
          error={error}
          filterOptions={filterOptions}
          loading={addressLoading}
          queryValue={queryValue}
          refetch={refetch}
          selectedFilter={selectedFilter}
          selectedLimit={selectedLimit}
          setActive={setActive}
          setAddressForm={setAddressForm}
          setBanner={setBanner}
          setCurrentPage={setCurrentPage}
          setDataToFetch={setDataToFetch}
          setError={setError}
          setQueryValue={setQueryValue}
          setSelectedFilter={setSelectedFilter}
          setSelectedLimit={setSelectedLimit}
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          total={total}
          isFirst={isFirst}
        />
      </>
    );
  };
  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(ManageAddressBook), { feature: constant.ADDRESS });
