import React, { useContext, useState, useEffect } from "react";
import { Card, FormLayout, Select, SkeletonBodyText, TextContainer } from "@shopify/polaris";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

// import components

// import helpers and consstants
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "app/productOld/modules/admin/features/add/context/context";
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE, GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";
import Tags from "./tag";

// import queries

const Organization = () => {
  const { data, handleChange, learnMore, setBanner } = useContext(FormContext);
  const { cms } = useContext(PrivateContext);

  const [isOptionLoaded, setoptionLoaded] = useState(false);

  const { loading: brandListLoading, data: vendorBrandListData } = useQuery(GET_VENDOR_BRAND_LIST);
  const [
    getProductTypes,
    { error: productTypeError, loading: productTypeLoading, data: productTypeData },
  ] = useLazyQuery(GET_PRODUCT_TYPE);

  const [
    getProductTags,
    { error: productTagsError, loading: productTagsLoading, data: productTagsData },
  ] = useLazyQuery(GET_PRODUCT_TAGS);

  // load tags
  const productTagsOptions = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || [];
  const getProductTagsError = baseHelper.getResponseError(productTagsData, constant.gql.GET_PRODUCT_TAGS);

  // load types
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];
  const getProductTypeError = baseHelper.getResponseError(productTypeData, constant.gql.GET_PRODUCT_TYPE);

  useEffect(() => {
    if (isOptionLoaded && data.vendorValue) {
      getProductTypes({ variables: { input: { vendorId: data.vendorValue } } });
      getProductTags({ variables: { input: { vendorId: data.vendorValue } } });
    }
  }, [isOptionLoaded, data.vendorValue, getProductTypes, getProductTags]);

  useEffect(() => {
    if (productTypeError || getProductTypeError || productTagsError || getProductTagsError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(productTypeError || getProductTypeError || productTagsError || getProductTagsError),
      });
    }
  }, [productTypeError, errorHelper, getProductTypeError, productTagsError, getProductTagsError]);

  if (brandListLoading || productTypeLoading || productTagsLoading) {
    return (
      <Card
        title={cms("section.form.section.organization.title")}
        id="productOrganisation"
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore("productOrganisation", cms("section.form.section.organization.title"));
            },
          },
        ]}
        sectioned
      >
        <SkeletonBodyText />
      </Card>
    );
  }
  // load vendors
  const brandList = baseHelper.getResponseData(vendorBrandListData, constant.gql.GET_VENDOR_BRAND_LIST);
  const vendorOptions = brandList.map(({ _id: vendorId, brandName }) => ({
    label: brandName,
    value: vendorId,
  }));

  if (!isOptionLoaded && vendorOptions.length) {
    setoptionLoaded(true);
    handleChange(constant.VENDOR_OPTIONS, vendorOptions);
  }

  return (
    <Card
      title={cms("section.form.section.organization.title")}
      id="productOrganisation"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productOrganisation", cms("section.form.section.organization.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>{cms("section.form.section.organization.description")}</TextContainer>
          <FormLayout.Group>
            <Select
              label={`${cms("common.label.vendor")}*`}
              id="chooseVendor"
              placeholder={cms("section.form.section.organization.placeholder.pleaseSelect")}
              options={vendorOptions}
              value={data.vendorValue || ""}
              onChange={(value) => handleChange(constant.VENDOR_VALUE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              label={cms("section.form.section.organization.label.productType")}
              id="chooseProductType"
              placeholder={cms("section.form.section.organization.placeholder.pleaseSelect")}
              options={productTypeOptions}
              value={data.productValueType || ""}
              onChange={(value) => handleChange(constant.PRODUCT_VALUE_TYPE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Tags
              label={cms("section.form.section.organization.label.productTags")}
              id="productTag"
              placeholder={cms("section.form.section.organization.placeholder.selectTags")}
              values={productTagsOptions}
              selected={data.organisationTags || []}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Organization;
