import { gql } from "apollo-boost";

const BULK_INVITED_VENDOR_DATA = gql`
  query getBulkInvitedUser($input: BulkInvitedUserData!) {
    getBulkInvitedUser(input: $input) {
      data {
        invite {
          _id
          email
          sentBy
          status
        }
        seller {
          _id
          brandName
        }
      }
      error
      status
    }
  }
`;
export default BULK_INVITED_VENDOR_DATA;
