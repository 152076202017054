import { gql } from "apollo-boost";

const UPDATE_DISCOUNT_CODE_SETTING = gql`
  mutation manageDiscountCodeSetting($input: SellerDiscountSetting!) {
    manageDiscountCodeSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_DISCOUNT_CODE_SETTING;
