import React from "react";
import { Card, Heading, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import { PieChart } from "react-minimal-pie-chart";
import CustomPieChart from "app/analytics/modules/provider/pieChart";
import { ProductAnalyticStyle } from "app/analytics/modules/generic/style";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

const ProductAnalyticsChart = React.forwardRef((props) => {
  const {
    bottomPerforming,
    colorData,
    isKeyTakeAway,
    sizeData,
    stockUtilisation,
    typeData,
    topPerforming,
  } = props;

  const materialDataPie = {
    labels: typeData[0],
    datasets: [
      {
        label: "# of Votes",
        data: typeData[1],
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const colorDataPie = {
    labels: colorData[0],
    datasets: [
      {
        label: "# of Votes",
        data: colorData[1],
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const sizeDataPie = {
    labels: sizeData[0],
    datasets: [
      {
        label: "# of Votes",
        data: sizeData[1],
        backgroundColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#deebf7",
          "#9ecae1",
          "#3282bd",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [sizePopular] = sizeData;
  const [colorPopular] = colorData;
  const [typePopular] = typeData;

  const isSizePopular = !!(sizePopular && sizePopular.length);
  const isColorPopular = !!(colorPopular && colorPopular.length);
  const isTypePopular = !!(typePopular && typePopular.length);

  return (
    <ProductAnalyticStyle>
        <Card>
          <Card.Section title="Top Three Performing Products">
            <Stack distribution="equalSpacing">
              {(topPerforming &&
                topPerforming.length &&
                (topPerforming || []).map((data) => {
                  return (
                    <Card>
                      <Card.Section>
                        <Stack>
                          <img alt="Product" src={data.image ? data.image : constant.NOIMAGESNAP} />
                          <TextContainer>
                            <Heading>{data.title}</Heading>
                            {`Order : ${" "} ${data.orderCount}`}
                          </TextContainer>
                        </Stack>
                      </Card.Section>
                    </Card>
                  );
                })) || <p>No Data Available</p>}
            </Stack>
          </Card.Section>

          <Card.Section title="Bottom Two Performing Products">
            <Stack distribution="equalSpacing">
              {(bottomPerforming &&
                bottomPerforming.length &&
                (bottomPerforming || []).map((data) => {
                  return (
                    <Card>
                      <Card.Section>
                        <Stack>
                          <img alt="Product" src={data.image ? data.image : constant.NOIMAGESNAP} />

                          <TextContainer>
                            <Heading>{data.title}</Heading>
                            {`Order : ${" "} ${data.orderCount}`}
                          </TextContainer>
                        </Stack>
                      </Card.Section>
                    </Card>
                  );
                })) || <p>No Data Available</p>}
            </Stack>
          </Card.Section>
          <Card.Section title="Stock Utilisation">
            <PieChart
              data={[{ value: stockUtilisation, color: "#9ecae1" }]}
              totalValue={100}
              lineWidth={20}
              label={({ dataEntry }) => `${dataEntry.value}%`}
              labelStyle={{
                fontSize: "20px",
                fontFamily: "sans-serif",
                // fill: "#666",
              }}
              labelPosition={0}
              style={{ height: "200px" }}
              //   startAngle={360}
              background="#ccc"
            />
            <p>
              {`Great job! ${stockUtilisation}%
                      of your stock is being utilised`}
            </p>
            {/* <div> */}
              <h2 className="Polaris-Heading graph-heading"> Key Take Aways </h2>
              {isKeyTakeAway &&
                (<div style={{ display: "flex", justifyContent: "space-around" }}>
                {isSizePopular &&
                  <div>
                    <CustomPieChart data={sizeDataPie} />
                    <span className="graph-title">{sizePopular[1] ? baseHelper.ucFirst(sizePopular[1]) : "Size"}</span>
                    <TextStyle variation="subdued">Was your most popularly rented size</TextStyle>
                  </div>
                }
                {isColorPopular &&
                  <div>
                    <CustomPieChart data={colorDataPie} />
                    <span className="graph-title">{colorPopular[1] ? baseHelper.ucFirst(colorPopular[1]) : "Color"}</span>
                    <TextStyle variation="subdued">Was your most popularly rented color</TextStyle>
                  </div>
                }
                {isTypePopular &&
                  <div>
                    <CustomPieChart data={materialDataPie} />
                    <span className="graph-title">{typePopular[1] ? baseHelper.ucFirst(typePopular[1]) : "Type"}</span>
                    <TextStyle variation="subdued">Was your most popularly rented catogary</TextStyle>
                  </div>
                }
              </div>) || "No Data Available"
              }
            {/* </div> */}
          </Card.Section>
        </Card>
    </ProductAnalyticStyle>
  );
});

export default ProductAnalyticsChart;
