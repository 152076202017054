import { gql } from "apollo-boost";

const CUSTOMIZE_FONT = gql`
  mutation customizeFont($input: getFont!) {
    customizeFont(input: $input) {
      status
      data
      error
    }
  }
`;

export default CUSTOMIZE_FONT;
