import React from "react";
import { Card, FormLayout, RadioButton, Select, Stack, TextField } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { renderConditon } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

const RenderOfferCondition = (props) => {
  const { GREATER_THAN, LESS_THAN, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY } = constant;
  const {
    shippingOffer,
    handleSelectChangeDropdown,
    selectedDropdown,
    handleCriteria,
    handleCondition,
    checkedCondition,
    moneyFormat,
    offerCriteria,
    offerCriteriaValue,
    handleCriteriaValue,
    cms,
  } = props;

  const { productPrice, productQuantity, orderValue } = shippingOffer || {};

  const optionsDropdown = [
    { label: cms("label.productPrice"), value: PRODUCT_PRICE },
    { label: cms("label.productQuantity"), value: PRODUCT_QUANTITY },
    { label: cms("label.orderValue"), value: ORDER_VALUE },
  ];

  const offerCriteriaOptions = [
    { label: cms("label.greaterThan"), value: GREATER_THAN },
    { label: cms("label.lessThan"), value: LESS_THAN },
  ];

  const isProductPrice = selectedDropdown === PRODUCT_PRICE;
  const isProductQuantity = selectedDropdown === PRODUCT_QUANTITY;
  const isOrderValue = selectedDropdown === ORDER_VALUE;

  const criteriaValues =
    (isProductPrice && { ...productPrice }) ||
    (isProductQuantity && { ...productQuantity }) ||
    (isOrderValue && { ...orderValue }) ||
    {};
  const criteriaText =
    (isProductPrice && cms("offer.add.section.condition.label.priceShouldBe")) ||
    (isProductQuantity && cms("offer.add.section.condition.label.quantityShouldBe")) ||
    cms("offer.add.section.condition.label.orderValueShouldBe");

  const criteriaKey = (isProductPrice && PRODUCT_PRICE) || (isProductQuantity && PRODUCT_QUANTITY) || ORDER_VALUE;

  return (
    <Card title={cms("modal.offer.add.section.condition.label.offerCondition")} sectioned>
      <Stack.Item>
        <RadioButton
          label={cms("modal.offer.add.section.condition.label.allConditions")}
          helpText={cms("modal.offer.add.section.condition.caption.allCondition")}
          checked={checkedCondition}
          id="all-conditions"
          name="all-conditions"
          onChange={handleCondition}
        />
      </Stack.Item>
      <Stack.Item>
        <RadioButton
          label={cms("modal.offer.add.section.condition.label.selectedCondition")}
          id="selected-conditions"
          name="selected-conditions"
          checked={!checkedCondition}
          onChange={handleCondition}
        />
        {!checkedCondition && [
          <Select
            placeholder={`${cms("modal.offer.add.section.condition.placeHolder.selectShippingOffer")} *`}
            options={optionsDropdown}
            value={selectedDropdown}
            onChange={handleSelectChangeDropdown}
          />,
          (isProductPrice || isProductQuantity || isOrderValue) && (
            <FormLayout>
              <Stack>
                <Stack.Item>
                  <p>{criteriaText}</p>
                </Stack.Item>
                <Stack.Item>
                  <Select
                    placeholder={cms("modal.offer.add.section.condition.placeHolder.selectCriteria")}
                    options={offerCriteriaOptions}
                    value={offerCriteria || criteriaValues.criteria}
                    onChange={(val) => handleCriteria(criteriaKey, val)}
                  />
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    type="number"
                    labelHidden
                    value={offerCriteriaValue || criteriaValues.value}
                    min={0}
                    step={isProductPrice || isOrderValue ? 0.01 : 0}
                    prefix={(isProductPrice || isOrderValue) && moneyFormat}
                    onChange={(val) => handleCriteriaValue(criteriaKey, val)}
                  />
                </Stack.Item>
              </Stack>
            </FormLayout>
          ),
        ]}
      </Stack.Item>
    </Card>
  );
};

RenderOfferCondition.propTypes = renderConditon.type;

export default RenderOfferCondition;
