import { gql } from "apollo-boost";

const UPDATE_CREDIT_NOTE_SETTING = gql`
  mutation updateCreditNoteSetting($input: UpdateCreditNoteSettingInput!) {
    updateCreditNoteSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_CREDIT_NOTE_SETTING;
