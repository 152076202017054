import { gql } from "apollo-boost";

const BULK_REJECT = gql`
  mutation lineRefund($input: LineRefund!) {
    lineRefund(input: $input) {
      data
      status
      error
    }
  }
`;

export default BULK_REJECT;
