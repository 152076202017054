const constantData = (cms) => {
  return {
    quoteLink: {
      link: "/products/edit/",
      id: "productId",
    },
    sortOptions: [
      { label: cms("section.quoteList.label.sortByDateAsc"), value: "createdAt_asc" },
      { label: cms("section.quoteList.label.sortByDateDesc"), value: "createdAt_desc" },
      { label: cms("section.quoteList.label.sortByProductTitleAsc"), value: "productTitle_asc" },
      { label: cms("section.quoteList.label.sortByProductTitleDesc"), value: "productTitle_desc" },
      { label: cms("section.quoteList.label.sortByProductPriceAsc"), value: "productPrice_asc" },
      { label: cms("section.quoteList.label.sortByProductPriceDesc"), value: "productPrice_desc" },
    ],
    filterByStatusOption: [
      {
        label: cms("section.quoteList.label.all"),
        value: "all",
      },
      {
        label: cms("section.quoteList.label.new"),
        value: "new",
      },
      {
        label: cms("section.quoteList.label.accepted"),
        value: "accepted",
      },
      {
        label: cms("section.quoteList.label.rejected"),
        value: "rejected",
      },
    ],
  };
};
export default constantData;
