import { gql } from "apollo-boost";

const SYNC_VENDOR_MAGENTO_PRODUCT = gql`
  mutation syncVendorMagentoProduct {
    syncVendorMagentoProduct {
      status
      data
      error
    }
  }
`;

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const DISCONNECT_MAGENTO_SHOP = gql`
  mutation disconnectMagentoShop {
    disconnectMagentoShop {
      status
      data
      error
    }
  }
`;

const CONNECT_MAGENTO_SHOP = gql`
  mutation connectMagentoShop($input: ShopConnect!) {
    connectMagentoShop(input: $input) {
      status
      data
      error
    }
  }
`;

export { SYNC_INVENTORY_VENDOR, SYNC_VENDOR_MAGENTO_PRODUCT, DISCONNECT_MAGENTO_SHOP, CONNECT_MAGENTO_SHOP };
