import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import { withErrorBoundary } from "lib/hoc";

import ChargebeeVendorPlan from "./subFeatures/chargebee";
import StripeVendorPlan from "./subFeatures/stripe";

const PlanView = (props) => {
  const { onSubmit = () => {}, submitLoading, error, banner, setBanner } = props;

  const currentUserRole = storageHelper.get("userRole");
  const { currentUser, isLoading, associatedSeller = {}, history } = useContext(PrivateContext);
  const { chargebee = {}, isChargebee = false, isStripeSubscription = false, stripeSubscription = {} } =
    associatedSeller || {};

  const isChargebeeEnabled = !!(isChargebee && chargebee);
  const isStripe = !!(isStripeSubscription && stripeSubscription && stripeSubscription.apiKey);

  if (!(isStripe || isChargebeeEnabled)) {
    return history.push("/");
  }

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (isChargebeeEnabled) {
      return (
        <ChargebeeVendorPlan
          banner={banner}
          error={error}
          onSubmit={onSubmit}
          setBanner={setBanner}
          submitLoading={submitLoading}
        />
      );
    }
    if (isStripe) {
      return <StripeVendorPlan />;
    }

    return null;
  };

  const PlanView = getComponent(currentUserRole);
  return PlanView;
};

export default withErrorBoundary(PlanView);
