import React, { useContext, useEffect, useState } from "react";
import { Card, FormLayout, Layout, List, Select } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import query
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";
import ProductTabs from "./subFeatures/form/subFeatureItem/tab";
import addData from "./config/addData";

const { CREATE_FORM } = constant;

const ProductAdd = () => {
  const { cms, isLoading, currentUser } = useContext(PrivateContext);
  const { gql } = constant;
  const { ecommercePlatform } = currentUser;
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [selected, setSelected] = useState();
  const { data: productSettingsData, refetch } = useQuery(GET_PRODUCT_SETTING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    const responseError = baseHelper.getResponseError(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, title: responseError, status: constant.CRITICAL });
    }
    if (responseData) {
      refetch();
    }
  }, [gql.GET_PRODUCT_SETTING, productSettingsData, refetch]);

  const renderComponent = (value) => {
    if (value === CREATE_FORM) {
      return <ProductTabs setBanner={setBanner} cms={cms} />;
    }
    return null;
  };
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection
        title={cms("label.import")}
        description={cms("label.csvInfo", { store: baseHelper.ucFirst(ecommercePlatform || constant.SHOPIFY) })}
      >
        <Card>
          <Card.Section>
            <FormLayout>
              <List type="bullet">
                <List.Item>{cms("label.manual")}</List.Item>
                {/* <List.Item>{cms("label.upload")}</List.Item> */}
              </List>
              <Select
                id="addProduct"
                options={addData(cms).options}
                onChange={(value) => setSelected(value)}
                placeholder={cms("label.choose")}
                value={selected}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {renderComponent(selected)}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProductAdd), { feature: constant.PRODUCT_ADD });
