import { gql } from "apollo-boost";

const FETCH_EMAIL_TEMPLATE_LIST = gql`
  query getEmailTemplate {
    getEmailTemplate {
      data {
        _id
        label
        code
        category
      }
      status
      error
    }
  }
`;
export default FETCH_EMAIL_TEMPLATE_LIST;
