import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
// eslint-disable-next-line max-len
import ConsignmentPickup from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";

const HybridConsignmentPickup = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <div className="hybridPickup">
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.consignmentPick.title")}</Heading>
          </Stack.Item>
          {/* <Stack.Item>
            <Link onClick={() => learnMore(cms("hybrid.consignmentPick.title"), cms("hybrid.consignmentPick.title"))}>
              {cms("common.learnMore")}
            </Link>
          </Stack.Item> */}
          <br />
          <TextStyle variation="subdued">{cms("hybrid.consignmentPick.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <ConsignmentPickup method="hybridConsignmentPickup" />
    </div>
  );
};

export default HybridConsignmentPickup;
