import { gql } from "apollo-boost";

const GET_SCHEDULE_PAYMENT = gql`
  query getSchedulePaymentSetting {
    getSchedulePaymentSetting {
      data {
        dateOfPayment
        status
        isEnabled
        schedulePaymentUpdatedAt
        advancedSchedulePaymentUpdatedAt
        advancedPaymentSchedule {
          vendorId
          dateOfPayment
          status
        }
        vendorPaymentSchedule {
          vendorId
          dateOfPayment
          status
        }
      }
      status
      error
    }
  }
`;

export default GET_SCHEDULE_PAYMENT;
