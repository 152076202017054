import { gql } from "apollo-boost";

const MANAGE_SUBSCRIPTION = gql`
  mutation manageSubscription($input: ManageSubscription!) {
    manageSubscription(input: $input) {
      data
      status
      error
    }
  }
`;
export default MANAGE_SUBSCRIPTION;
