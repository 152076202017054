/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useContext } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  Heading,
  Layout,
  Link,
  Modal,
  PageActions,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
import { Banner, Spinner } from "lib/components";
import { GET_CURRENCY, GET_CURRENCY_RATE, UPDATE_RATE } from "app/setup/apollo";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import AddCurrency from "./components/add/add";

import Table from "./components/table/table";

const CurrencyRateSetting = () => {
  const { currentUser, cms, history } = useContext(PrivateContext);
  const { additionalStoreInfo, moneyFormat } = currentUser;
  const [activeCheckboxModal, setActiveCheckboxModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [inlineError, setInlineError] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isOpenCheckboxModal, setIsOpenCheckboxModal] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(true);
  const [currencyName, setCurrencyName] = useState("");
  const [addCountry, setAddCountry] = useState();
  const [responseCounty, setResponseCountry] = useState();
  const [updateAt, setUpdateAt] = useState();
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [isAllow, setIsAllow] = useState({
    product: false,
    payment: false,
  });

  const [listCurrency, setListCurrency] = useState();

  let shopifyCurrency;

  if (additionalStoreInfo) {
    const { shop: { currency: defaultCurrency = "" } = {} } = additionalStoreInfo || {};
    shopifyCurrency = defaultCurrency;
  }

  const { loading: currencyLoader, data: currencyData, error: currencyError } = useQuery(GET_CURRENCY);
  const { loading: rateLoader, data: rateData, refetch } = useQuery(GET_CURRENCY_RATE);

  const currencyDataResponse = baseHelper.getResponseData(currencyData, constant.gql.GET_CURRENCY);

  const currencyDataError = baseHelper.getResponseError(currencyData, constant.gql.GET_CURRENCY);

  useEffect(() => {
    if (currencyDataError) {
      setBanner({ status: constant.CRITICAL, title: currencyDataError, isOpen: true });
    }
    if (currencyError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(currencyError), isOpen: true });
    }
  }, [currencyDataError, currencyError]);

  const rateDataError = baseHelper.getResponseError(rateData, constant.gql.GET_CURRENCY_RATE);
  const rateDataResponse = baseHelper.getResponseData(rateData, constant.gql.GET_CURRENCY_RATE);
  const { currency, currencyName: shopifyCurrencyName = "" } = rateDataResponse;
  const { isAllPayment: payment, isAllProduct: product, updatedAt } = currency || {};

  useEffect(() => {
    if (rateDataResponse && currencyDataResponse && currency) {
      const rateDataList = { ...rateDataResponse };
      rateDataList.currency.rate = rateDataResponse.currency.rate.map((element) => {
        const { name, symbol } = currencyDataResponse.find((i) => i.code === element.currencyCode);
        return {
          ...element,
          country: name,
          isPayment: element.isPayment,
          price: element.price,
          symbol,
        };
      });
      setResponseCountry(rateDataList);
      setAddCountry(rateDataList);
      setIsAllow({
        payment,
        product,
      });
      setCurrencyName(shopifyCurrencyName);
      setUpdateAt(updatedAt);
    }

    // if (rateDataError) {
    //   setBanner({
    //     isOpen: true,
    //     status: constant.CRITICAL,
    //     title: rateDataError,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyDataResponse, rateDataResponse, rateDataError, currency]);

  useEffect(() => {
    if (currencyDataResponse && rateDataResponse) {
      const currencyList = currencyDataResponse
        .map((item) => {
          const isCheck =
            rateDataResponse.currency &&
            rateDataResponse.currency.rate.find((element) => element.currencyCode === item.code);
          const isCurrency = item.code !== shopifyCurrency;
          if (!isCurrency) {
            return false;
          }
          if (isCheck) {
            return {
              isCheck: true,
              id: item.code,
              country: item.name,
              price: isCheck.price,
              symbol: isCheck.symbol,
              isProduct: isCheck.isProduct,
              isPayment: isCheck.isPayment,
            };
          }
          return {
            isCheck: false,
            id: item.code,
            country: item.name,
            price: "",
            symbol: item.symbol,
            isProduct: false,
            isPayment: false,
          };
        })
        .filter((item) => item);
      setListCurrency(currencyList);
      setCurrencyName(rateDataResponse && rateDataResponse.currencyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyDataResponse, rateDataResponse]);

  const onHandleChange = (field, newValue) => {
    setIsAllow((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    setSubmitEnable(false);
    if (addCountry) {
      const updatedAddCountry = {
        currency: {
          ...addCountry.currency,
          rate: addCountry.currency.rate.map((item) => {
            const toUpdate = item;
            if (field === "product") {
              toUpdate.isProduct = newValue;
            } else {
              toUpdate.isPayment = newValue;
            }
            return toUpdate;
          }),
        },
      };
      setAddCountry(updatedAddCountry);
    }
    setIsOpenCheckboxModal(!newValue);
  };

  const [updateRate, { loading: updateRateLoading }] = useMutation(UPDATE_RATE);
  const onSubmit = async () => {
    if (addCountry && addCountry.currency && addCountry.currency.rate) {
      (addCountry.currency.rate || []).forEach(({ price, currencyCode }) => {
        if (price <= 0 || !price) {
          setInlineError({ [currencyCode]: true });
          setSubmitEnable(true);
        }
      });
    }
    const requestData = {
      isAllPayment: isAllow.payment,
      rate: addCountry.currency.rate.map(({ price, currencyCode, isPayment }) => {
        return {
          currencyCode,
          isPayment,
          price: parseFloat(price),
        };
      }),
    };
    try {
      await updateRate({
        variables: { input: requestData },
      });
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.rate"),
      });
      refetch();
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(err) });
    }
    setActiveCheckboxModal(false);
    setIsCancel(true);
    setSubmitEnable(true);
  };

  const handleModal = () => {
    setActiveModal(!activeModal);
  };

  const activator = (
    <>
      <Link id="addLink" onClick={handleModal}>
        {cms("common.button.addMore")}
      </Link>
      <br />
    </>
  );

  const handleCheckboxModalCancel = () => {
    setActiveCheckboxModal(!activeCheckboxModal);
    setIsCancel(false);
    setIsAllow({
      payment: payment || isAllow.payment,
    });
    setAddCountry(responseCounty);
    setSubmitEnable(true);
  };

  const addCurrencyModal = () => {
    return (
      <AddCurrency
        activator={activator}
        active={activeModal}
        addCountry={addCountry}
        banner={banner}
        cms={cms}
        currency={listCurrency}
        handleChange={handleModal}
        setActive={setActiveModal}
        setAddCountry={setAddCountry}
        setBanner={setBanner}
        setCurrency={setListCurrency}
        setSubmitEnable={setSubmitEnable}
        isAllow={isAllow}
        setIsAllow={setIsAllow}
        inlineError={inlineError}
        setInlineError={setInlineError}
      />
    );
  };

  if (rateLoader || currencyLoader) {
    return <Spinner />;
  }

  const submitCallBack = () => {
    if (isOpenCheckboxModal === false) {
      onSubmit();
    } else setActiveCheckboxModal(true);
  };

  const checkboxModal = () => {
    return (
      <Modal
        open={activeCheckboxModal}
        onClose={handleCheckboxModalCancel}
        title={cms("section.payment.title")}
        primaryAction={{
          content: cms("common.button.confirm"),
          onAction: onSubmit,
          loading: updateRateLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.close"),
            onAction: handleCheckboxModalCancel,
          },
        ]}
      >
        <Modal.Section>
          <p>{cms("section.payment.description")}</p>
        </Modal.Section>
      </Modal>
    );
  };
  const { currency: countryCurrency = {} } = addCountry || {};
  const { rate = [] } = countryCurrency || {};
  const checkCurrency = rate && rate.length;

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.rate.title")} description={cms("section.rate.description")}>
        <Card
          title={[
            cms("section.rate.subTitle"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <Stack>
              {/* <div className="flag">
                  <Thumbnail
                    source="https://cdn.shopify.com/shopifycloud/web/assets/v1/1ae10ee3926d9bbebd5cd1fec685e91a.svg"
                    size="small"
                  />
                </div> */}
              <Heading>
                {currencyName && shopifyCurrency
                  ? `${currencyName} (${shopifyCurrency})`
                  : baseHelper.getCurrencyName(moneyFormat, constant.symbol, cms)}
              </Heading>
              <Badge status="info">{cms("label.currency")}</Badge>
            </Stack>
            <br />
            {/* <Checkbox
                label={cms("label.addProduct")}
                helpText={cms("helpText.product")}
                checked={isAllow.product}
                onChange={(newChecked) => onHandleChange(constant.PRODUCT, newChecked)}
              /> */}
            <Checkbox
              label={cms("label.payout")}
              helpText={cms("helpText.payment")}
              checked={isAllow.payment}
              onChange={(newChecked) => onHandleChange(constant.PAYMENT, newChecked)}
              disabled={!checkCurrency}
            />
            <br />
            <Table
              cms={cms}
              data={addCountry}
              isAllow={isAllow}
              setIsAllow={setIsAllow}
              setData={setAddCountry}
              setSubmitEnable={setSubmitEnable}
              setIsOpenCheckboxModal={setIsOpenCheckboxModal}
              isCancel={isCancel}
              inlineError={inlineError}
              setInlineError={setInlineError}
              loading={updateRateLoading}
            />
            <br />
            {addCurrencyModal()}
            {checkboxModal()}
            <br />
            {updateAt && (
              <div className="manage-link">
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: submitCallBack,
                    disabled: submitEnabled,
                    loading: updateRateLoading && !activeCheckboxModal,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {!updateAt && (
          <PageActions
            primaryAction={{
              content: cms("common.button.submit"),
              onAction: submitCallBack,
              disabled: submitEnabled,
              loading: updateRateLoading && !activeCheckboxModal,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(CurrencyRateSetting), { feature: constant.CURRENCY_RATE_SETTING });
