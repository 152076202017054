import React, { useState, useContext } from "react";
import { Modal, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner } from "lib/components";

// import props
import { deleteProp } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

// import gql
import { DELETE_OFFER } from "app/shipping/apollo/mutations";

// import advance shipping context
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

const DeleteOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { deleteModal, toggleDeleteModal, deleteId, bands = [], shippingBandId, refetch, cms } = props || {};
  const { gql } = constant;
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const shippingBand =
    (bands.length > 0 && bands.find((band) => band.value === shippingBandId || band.parentId === shippingBandId)) || "";

  const [deleteOfferManage, { loading: deleteLoading }] = useMutation(DELETE_OFFER);
  const deleteShipping = () => {
    deleteOfferManage({
      variables: {
        input: {
          shippingOfferId: deleteId,
        },
      },
    })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.DELETE_SHIPPING_OFFER);
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.DELETE_SHIPPING_OFFER);
          setBannerOuter({ isOpen: true, title: resError, status: "critical" });
          return;
        }
        setBannerOuter({
          isOpen: true,
          title: cms("modal.offer.delete.message.deleteSuccessfully"),
          status: "success",
        });
        toggleDeleteModal();
        refetch();
      })
      .catch((exception) => {
        setBannerOuter({ isOpen: true, title: errorHelper.parse(exception), status: "critical" });
      });
  };

  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={deleteModal}
        onClose={toggleDeleteModal}
        title={`${cms("modal.offer.delete.label.delete")} - ${shippingBand ? shippingBand.label : ""}`}
        primaryAction={{
          content: cms("button.delete"),
          onAction: deleteShipping,
          destructive: true,
          loading: deleteLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <TextContainer>
            <p>{cms("modal.offer.delete.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

DeleteOffers.propTypes = deleteProp.type;

export default DeleteOffers;
