import React, { useEffect, useState, useContext } from "react";
import { Tabs, Card } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { ProductProvider } from "app/product/modules/generic/context";

// import tab
import EditAttachment from "app/product/modules/generic/features/form/attachment";
import EditServiceImage from "app/product/modules/generic/features/form/service/image";
import EditServiceAvailability from "app/product/modules/generic/features/form/service/availability";
import EditServiceMetafield from "app/product/modules/generic/features/form/metafield";
import EditServiceVariant from "app/product/modules/provider/features/edit/features/form/service/variant";

// import tab prop
import { tabProp } from "app/product/modules/generic/propTypes";
import editServiceData from "app/product/modules/provider/features/edit/config/editServiceData";
import EditServiceInformation from "./information";

const EditServiceTabs = (props) => {
  const {
    setBanner,
    setDescription,
    setTitle,
    isVariant,
    refetch,
    isMetafield,
    availability,
    serviceId,
    getAvailability,
  } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { search } = history.location;
  const { tab } = baseHelper.getQueryParams(search);
  const { ATTACHMENT, IMAGE, INFORMATION, VARIANT, METAFIELD, AVAILABILITY } = constant;
  const validServiceTabs = editServiceData(cms, isVariant).serviceTabs;
  const [selected, setSelected] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  useEffect(() => {
    const validtab = validServiceTabs.map((item) => item.id);
    const tabName = validtab.includes(tab) ? tab : INFORMATION;
    setSelected(tabName);
    if (!validtab.includes(tab)) {
      baseHelper.setUrl(history, { tab: tabName });
    }
  }, [INFORMATION, history, selected, tab, validServiceTabs]);

  useEffect(() => {
    let index = validServiceTabs.findIndex((item) => item.id === tab);
    if (validServiceTabs.length < index) {
      index = 0;
    }
    setSelectedTabIndex(index);
  }, [tab, validServiceTabs]);

  const handleTabChange = (tabIndex) => {
    const tabId = validServiceTabs[tabIndex].id;
    setSelected(tabId);
    if (tabId === AVAILABILITY && serviceId) {
      getAvailability({ variables: { input: { id: serviceId } } });
    }
    baseHelper.setUrl(history, { tab: tabId });
  };

  const getTab = (tabSelected) => {
    let lastTab = isAttachmentVisible ? ATTACHMENT : VARIANT;

    if (isMetafield) {
      lastTab = isAttachmentVisible ? ATTACHMENT : METAFIELD;
    }

    const tabObj = { islastTab: tabSelected === lastTab };

    switch (tabSelected) {
      case INFORMATION:
        return <EditServiceInformation />;
      case IMAGE:
        return <EditServiceImage />;
      case VARIANT:
        return <EditServiceVariant tabObj={tabObj} />;
      case ATTACHMENT:
        return <EditAttachment tabObj={tabObj} />;
      case METAFIELD:
        return <EditServiceMetafield tabObj={tabObj} />;
      case AVAILABILITY:
        return <EditServiceAvailability tabObj={tabObj} />;
      default:
        return <EditServiceInformation />;
    }
  };

  return (
    <>
      <Card>
        <Tabs tabs={validServiceTabs} selected={selectedTabIndex} onSelect={handleTabChange} />
      </Card>
      <ProductProvider
        value={{
          handleTabChange,
          isVariant,
          refetch,
          setBanner,
          setDescription,
          setTitle,
          availability,
          serviceId,
          getAvailability,
        }}
      >
        <div className="productComponent">{getTab(selected)}</div>
      </ProductProvider>
    </>
  );
};

EditServiceTabs.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(EditServiceTabs), {
  feature: constant.PRODUCT_UPDATE_FORM,
});
