import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";

import { ProviderConnect } from "./provider/features";

const PaymentConnect = () => {
  const { isLoading } = useContext(PrivateContext);
  const { userKey } = constant;
  const { provider } = userKey;
  const currentUserRole = storageHelper.get("userRole");

  if (isLoading) return <Spinner />;

  const getComponent = (role) => {
    if (role === provider) {
      return <ProviderConnect />;
    }
    return null;
  };
  const PaymentConnectComponent = getComponent(currentUserRole);
  return PaymentConnectComponent;
};

export default PaymentConnect;
