import { gql } from "apollo-boost";

const UPDATE_ORDER_REPORT_SETTING = gql`
  mutation updateOrderReportSetting($input: OrderReportSetting) {
    updateOrderReportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_ORDER_REPORT_SETTING;
