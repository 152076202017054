import React, { Fragment, useContext, useEffect, useState } from "react";
import { Caption, Card, Collapsible, FormLayout, Layout, List, PageActions, Select, TextField } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// components
import { Banner, Spinner } from "lib/components";

// constants
import constant from "lib/constant/constant";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import gql
import { GET_SHIPPING } from "app/shipping/apollo/queries";
import { SAVE_SHIPPING, UPDATE_LAKE_SHIPPING } from "app/shipping/apollo/mutations";

import { withFeature, withErrorBoundary } from "lib/hoc";

import VendorManager from "./vendorManager";

const ManageShipping = (props) => {
  const { setShowingBandTab = () => {}, setSelectedTab, allowShipping } = props;
  const { history, cms, currentUser } = useContext(PrivateContext);
  const [term, setTerm] = useState(constant.value.ZERO);
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState(cms("common.label.flat").toLowerCase());
  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedRuleVendors, setSelectedRuleVendors] = useState([]);
  const [allowVendor, setAllowVendor] = useState(constant.APPROVE_ALL);
  const [isFreeForCustomer, setIsFreeForCustomer] = useState(false);
  const [shippingPerVendor, setShippingPerVendor] = useState({});
  const [isAllVendorsAllowed, setIsAllVendorsAllowed] = useState(true);
  const [allowCumulativeVendor, setAllowCumulativeVendor] = useState(false);
  const [isHideDescription, setIsHideDescription] = useState(false);
  const [isShippingByVendor, setIsShippingByVendor] = useState(false);
  const [shippingByVendor, setShippingByVendor] = useState({});
  const [shippingVendors, setShippingVendors] = useState([]);
  const [globalSelectedDestinations, setGlobalSelectedDestinations] = useState([]);
  const [globalAllowAllShippingByVendor, setGlobalAllowAllShippingByVendor] = useState(true);
  const [globalVendorShipping, setGlobalVendorShipping] = useState({
    all: [],
  });
  const [globalVendorIds, setGlobalVendorIds] = useState([]);
  const isShippingBandAvailable = true;
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setting, setSetting] = useState({});

  const captions = cms("operator.section.general.caption");

  const options = [
    {
      label: cms("operator.section.general.label.noShippingCharge"),
      value: constant.NONE,
    },
    {
      label: cms("operator.section.general.label.flatShippingCharge"),
      value: constant.FLAT,
    },
    {
      label: cms("operator.section.general.label.equalShippingChargeSplit"),
      value: constant.EQUAL,
    },
    {
      label: cms("operator.section.general.label.vendorManageShippingCharges"),
      value: constant.VENDOR_MANAGE,
    },
  ];
  const manageOptions = options && options.filter((item) => item);
  const { loading: loadingShipping, data: shippingData } = useQuery(GET_SHIPPING);

  const [saveShipping, { loading: isSaveShippingLoading }] = useMutation(SAVE_SHIPPING);
  const [updateLakeShipping, { loading: isUpdateLakeShippingLoading }] = useMutation(UPDATE_LAKE_SHIPPING);
  const handleVendors = (selected) => {
    setSelectedRuleVendors(selected);
  };

  const renderCaptions = (captionsList) => {
    return captionsList.map((caption) => (
      <List.Item key={caption}>
        <Caption>{caption}</Caption>
      </List.Item>
    ));
  };

  const handleAllowVendor = (_, newValue) => {
    setAllowVendor(newValue);
  };

  const handleShippingTypeSelect = (value) => {
    setTerm(value);
    if (value !== cms("common.label.flat").toLowerCase()) {
      setAmount(0);
    }
  };

  const toggleFreeForCustomer = () => {
    setIsFreeForCustomer(!isFreeForCustomer);
  };

  const toggleAllowed = () => {
    const { isAllowed = false } = shippingPerVendor || {};
    setShippingPerVendor((prevState) => ({
      ...prevState,
      isAllowed: !isAllowed,
    }));
  };

  const handleAllowCumulativeShipping = (_checked, newValue) => {
    setAllowCumulativeVendor(newValue);
    setIsAllVendorsAllowed(!isAllVendorsAllowed);
  };
  const handleAllowAllCumulativeShipping = () => {
    setAllowCumulativeVendor(false);
    setIsAllVendorsAllowed(!isAllVendorsAllowed);
  };

  const handleCumulativeVendors = (selectedIds) => {
    setSelectedVendors(selectedIds);
    setShippingPerVendor((prevState) => ({
      ...prevState,
      vendorIds: [...selectedIds],
    }));
  };
  const handleIsHideDescription = (value) => {
    setIsHideDescription(!value);
  };

  const manageSetupRuleProps = {
    vendors,
    selectedRuleVendors,
    allowVendor,
    onChangeRadio: handleAllowVendor,
    onChangeAutoComplete: handleVendors,
  };
  const managePaymentProps = {
    checked: isFreeForCustomer,
    onChange: toggleFreeForCustomer,
  };

  const manageShippingChargeProps = {
    checked: (shippingPerVendor && shippingPerVendor.isAllowed) || false,
    onChange: toggleAllowed,
    vendors,
    selectedVendors,
    isAllVendorsAllowed,
    onChangeRadioOne: handleAllowCumulativeShipping,
    onChangeRadioTwo: handleAllowAllCumulativeShipping,
    onChangeAutoComplete: handleCumulativeVendors,
  };

  const manageIsHideDescriptionProps = {
    isHideDescription,
    onChange: handleIsHideDescription,
  };

  useEffect(() => {
    const responseError = baseHelper.getResponseError(shippingData, constant.gql.GET_SHIPPING);
    if (responseError) {
      const banner = {
        title: responseError,
        status: constant.CRITICAL,
        isOpen: true,
      };
      setBannerStatus(banner);
      return;
    }
    const responseData = baseHelper.getResponseData(shippingData, constant.gql.GET_SHIPPING);
    const { shipping, vendors: resVendors } = responseData || {};
    const {
      term: resTerm,
      amount: resAmount,
      type: resShippingType,
      allowVendor: resAllowVendor,
      vendorIds,
      isHideDescription: isHideShipping,
      isFreeForCustomer: resIsFreeForCustomer,
      isShippingByVendor: resIsShippingByVendor,
      shippingPerVendor: resShippingPerVendor = {},
      shippingByVendor: resShippingByVendor = {},
      shippingByCurrency: resShippingByCurrency = [],
    } = shipping || {};
    const { vendorIds: shippingPerVendorIds = [] } = resShippingPerVendor || {};
    setTerm(resTerm || constant.NONE);
    setType(resShippingType || cms("common.label.flat").toLowerCase());
    if (shipping) {
      setShowingBandTab(resTerm === constant.VENDOR_MANAGE || false);
    }
    const vendorShipping = resShippingByVendor || {};
    const { selected } = vendorShipping;
    const selectedShipVendors = [];
    if (selected && selected.length) {
      selected.map((item) => selectedShipVendors.push(item.vendorId));
    }
    const vendorIdsRow = [];
    const vendorShippingRows = {};
    (resShippingByCurrency || []).forEach((row) => {
      const { amount: amountData, type: typeData, currencyCode, destination, vendorId, taxRate = 0 } = row;
      if (vendorId && !vendorShippingRows[vendorId]) {
        vendorIdsRow.push(vendorId);
        vendorShippingRows[vendorId] = [];
      }
      if (vendorId && vendorShippingRows[vendorId]) {
        vendorShippingRows[vendorId].push({ amount: amountData, type: typeData, currencyCode, destination, taxRate });
      }
      if (!vendorId) {
        if (!vendorShippingRows.all) {
          vendorShippingRows.all = [];
        }
        vendorShippingRows.all.push({ amount: amountData, type: typeData, currencyCode, destination, taxRate });
      }
    });

    if (!(resShippingByCurrency && resShippingByCurrency.length) || !vendorShippingRows.all) {
      vendorShippingRows.all = [];
    }

    const firstShippingData = vendorShippingRows[Object.keys(vendorShippingRows)[0]];

    let selectedDestinations = firstShippingData.map((item) => item.destination).filter((item) => item);
    selectedDestinations = [...new Set(selectedDestinations || [])];

    if (!(vendorShippingRows.all && vendorShippingRows.all.length) && selectedDestinations.length) {
      selectedDestinations.forEach((destination) => {
        vendorShippingRows.all.push({
          amount: "",
          type: constant.FIXED,
          destination,
          currencyCode: "",
          taxRate: 0,
        });
      });
    }

    setGlobalVendorShipping({ ...vendorShippingRows });
    setGlobalSelectedDestinations(selectedDestinations);
    setGlobalAllowAllShippingByVendor(Object.keys(vendorShippingRows).length <= 1);
    setGlobalVendorIds(vendorIdsRow);
    setShippingVendors(selectedShipVendors);
    setShippingByVendor(vendorShipping);
    setIsShippingByVendor(resIsShippingByVendor || false);
    setAmount(resAmount || constant.value.ZERO);
    setVendors(resVendors || []);
    setSelectedVendors(shippingPerVendorIds || []);
    setSelectedRuleVendors(vendorIds || []);
    setAllowVendor(resAllowVendor);
    setIsFreeForCustomer(resIsFreeForCustomer);
    setShippingPerVendor(resShippingPerVendor);
    setIsAllVendorsAllowed(!shippingPerVendorIds.length);
    setIsHideDescription(isHideShipping);
  }, [shippingData, setShowingBandTab, cms]);

  const isValid = () => {
    if (!amount && term === constant.FLAT) {
      setBannerStatus({ isOpen: true, title: cms("operator.message.error.amountIsRequired"), status: "critical" });
      return false;
    }
    return true;
  };

  const manageAggregateShippingSettingProps = (manageAggregateShippingSetting) => {
    setSetting(manageAggregateShippingSetting);
  };

  const handleAmountChange = (value) => {
    if (value <= constant.value.MAX_FLAT) {
      setAmount(value);
    }
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const isGlobal = allowShipping === constant.GLOBAL;

  const submitLakeForm = async () => {
    const data = {};
    const isAmount = isValid();
    if (!isAmount) {
      return;
    }
    const checkIsVendorManageShipping = term === constant.VENDOR_MANAGE;
    let isError = false;
    if (checkIsVendorManageShipping) {
      const parsedShipping = [];
      let shippingToSave = {};

      if (globalAllowAllShippingByVendor) {
        shippingToSave.all = [...globalVendorShipping.all];
      } else {
        shippingToSave = { ...globalVendorShipping };
        delete shippingToSave.all;
      }

      Object.keys(shippingToSave).forEach((vendorId) => {
        const rows = [...globalVendorShipping[vendorId]];
        rows.forEach((row) => {
          const { amount: amountData, type: typeData, currencyCode, destination, taxRate } = row;
          const shippingObject = {
            amount: amountData,
            type: typeData,
            currencyCode,
            destination,
            taxRate,
          };
          if (!(currencyCode && destination)) {
            return false;
          }
          if (vendorId === constant.ALL) {
            parsedShipping.push(shippingObject);
          } else {
            parsedShipping.push({ ...shippingObject, vendorId });
          }
        });
      });

      const checkValidShipping = (item) =>
        !!(
          item &&
          (item.type === constant.FREE || (item.type === constant.FIXED && item.amount && item.amount !== "0"))
        );
      const isValidShipping = parsedShipping.every((shipping) => checkValidShipping(shipping));
      if (!isValidShipping) {
        setBannerStatus({
          isOpen: true,
          title:
            "Please provide cost for all destinations or select free shipping in case you want to apply 0 shipping",
          status: constant.CRITICAL,
        });
        isError = true;
      }

      data.shippingByCurrency = [...parsedShipping];
    }
    if (setting) {
      data.setting = setting;
    }
    if (!isError) {
      try {
        const res = await updateLakeShipping({
          variables: {
            input: {
              term,
              amount: parseFloat(amount, 10),
              type,
              isGlobalShipping: true,
              shippingByCurrency: data.shippingByCurrency,
            },
          },
        });

        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_LAKE_SHIPPING);
        if (responseError) {
          setBannerStatus({
            title: responseError,
            status: constant.CRITICAL,
            isOpen: true,
          });
          return;
        }
        setBannerStatus({
          title: cms("operator.message.success"),
          status: constant.SUCCESS,
          isOpen: true,
        });
        setShowingBandTab(term === constant.VENDOR_MANAGE || false);
      } catch (exception) {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      }
    }
  };

  const submitForm = async () => {
    const data = {};
    const isAmount = isValid();
    if (!isAmount) {
      return;
    }
    const checkIsVendorManageShipping = term === constant.VENDOR_MANAGE;
    let isError = false;
    if (checkIsVendorManageShipping) {
      const { isAllowed, vendorIds } = shippingPerVendor || {};
      data.allowVendor = allowVendor;
      data.vendorIds = selectedRuleVendors || selectedVendors;
      data.isFreeForCustomer = isFreeForCustomer;
      data.shippingPerVendor = {
        isAllowed: !!isAllowed,
        vendorIds: isAllVendorsAllowed ? [] : vendorIds,
      };
      if (allowCumulativeVendor && data.vendorIds.length === 0) {
        data.allowVendor = constant.APPROVE_SELECTED;
        data.shippingPerVendor.isAllowed = false;
      }

      if (isShippingByVendor) {
        const { all, selected } = shippingByVendor;
        if (selected.length) {
          selected.map((item) => {
            if (item.type !== constant.FREE && !item.amount) {
              setBannerStatus({
                isOpen: true,
                title: cms("operator.section.chargeShipping.message.error.select"),
                status: constant.CRITICAL,
              });
              isError = true;
            }
            return null;
          });
        }

        if (all && all.type !== constant.FREE && !all.amount) {
          setBannerStatus({
            isOpen: true,
            title: cms("operator.section.chargeShipping.message.error.all"),
            status: constant.CRITICAL,
          });
          isError = true;
        }
      }
    }
    if (setting) {
      data.setting = setting;
    }
    if (!isError) {
      try {
        const res = await saveShipping({
          variables: {
            input: {
              term,
              // type,
              amount: parseFloat(amount, 10),
              allowVendor: data.allowVendor,
              vendorIds: data.vendorIds,
              isFreeForCustomer: data.isFreeForCustomer,
              shippingPerVendor: data.shippingPerVendor,
              isHideDescription,
              setting: data.setting,
              isShippingByVendor,
              shippingByVendor,
              isGlobalShipping: false,
            },
          },
        });

        const responseError = baseHelper.getResponseError(res.data, constant.gql.SAVE_SHIPPING);
        if (responseError) {
          setBannerStatus({
            title: responseError,
            status: constant.CRITICAL,
            isOpen: true,
          });
          return;
        }
        setBannerStatus({
          title: cms("operator.message.success"),
          status: constant.SUCCESS,
          isOpen: true,
        });
        setShowingBandTab(term === constant.VENDOR_MANAGE || false);
      } catch (exception) {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      }
    }
  };

  if (loadingShipping) {
    return <Spinner />;
  }
  const isWooCommerceUser = baseHelper.isWooCommerceUser(currentUser);
  return (
    <>
      {(bannerStatus.isOpen || !isShippingBandAvailable) && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => {
              setBannerStatus({ isOpen: false, title: "", status: "" });
            }}
          />
          <Banner
            isOpen={!isShippingBandAvailable}
            status="warning"
            title="Add Shipping Band"
            action={{ content: "Add Shipping Band", onAction: () => setSelectedTab(1) }}
          >
            You need to have atleast one shipping band otherwise FREE shipping will be applied to all your incoming
            orders
          </Banner>
        </Layout.Section>
      )}

      <Layout.AnnotatedSection
        title={cms("operator.section.general.title")}
        description={cms("operator.section.general.description")}
      >
        <Card sectioned>
          <FormLayout>
            <List type="bullet">{renderCaptions(captions)}</List>
            <FormLayout.Group>
              <Select
                options={manageOptions}
                id={constant.SHIPPING_CHARGE_SELECT}
                placeholder={cms("operator.section.general.placeholder.chooseShippingDistribution")}
                value={term}
                onChange={handleShippingTypeSelect}
              />
              {term === cms("common.label.flat").toLowerCase() && (
                <TextField
                  id={constant.SHIPPING_COST}
                  prefix={currentUser.moneyFormat}
                  onChange={(value) => {
                    handleAmountChange(acceptOnlyValidInput(value, amount));
                  }}
                  value={(amount || "").toString()}
                  min={constant.value.ZERO}
                  step={1}
                />
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>

      <Collapsible open={term === constant.VENDOR_MANAGE} id={constant.VENDOR_CHARGE_SECTION}>
        <VendorManager
          manageSetupRuleProps={manageSetupRuleProps}
          managePaymentProps={managePaymentProps}
          manageShippingChargeProps={manageShippingChargeProps}
          manageIsHideDescriptionProps={manageIsHideDescriptionProps}
          manageAggregateShippingSettingProps={manageAggregateShippingSettingProps}
          setBannerStatus={setBannerStatus}
          setShippingByVendor={setShippingByVendor}
          shippingByVendor={shippingByVendor}
          setIsShippingByVendor={setIsShippingByVendor}
          isShippingByVendor={isShippingByVendor}
          shippingVendors={shippingVendors}
          globalSelectedDestinations={globalSelectedDestinations}
          setGlobalSelectedDestinations={setGlobalSelectedDestinations}
          globalAllowAllShippingByVendor={globalAllowAllShippingByVendor}
          setGlobalAllowAllShippingByVendor={setGlobalAllowAllShippingByVendor}
          globalVendorShipping={globalVendorShipping}
          setGlobalVendorShipping={setGlobalVendorShipping}
          globalVendorIds={globalVendorIds}
          setGlobalVendorIds={setGlobalVendorIds}
          vendors={vendors}
          isGlobal={isGlobal}
        />
      </Collapsible>

      <Layout.Section>
        <PageActions
          primaryAction={{
            id: constant.SUBMIT_MANAGE_SHIPPING,
            content: cms("common.button.submit"),
            onClick: () => (isWooCommerceUser && isGlobal ? submitLakeForm() : submitForm()),
            loading: isSaveShippingLoading || isUpdateLakeShippingLoading,
          }}
          secondaryActions={{
            id: constant.CANCEL_MANAGE_SHIPPING,
            content: cms("common.button.cancel"),
            onClick: () => history.push("/setting"),
          }}
        />
      </Layout.Section>
    </>
  );
};

ManageShipping.propTypes = {
  setShowingBandTab: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ManageShipping), { feature: constant.STANDARD_SHIPPING });
