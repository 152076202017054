const blueInfoIcon = {
  icon: {
    body: `
      <g fillRule="evenodd">
        <circle fill="currentColor" cx="10" cy="10" r="9" />
        <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0
        18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m1-5v-3a1 1 0 0 0-1-1H9a1
         1 0 1 0 0 2v3a1 1 0 0 0 1 1h1a1 1 0 1 0 0-2m-1-5.9a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2" />
      </g>`,
    viewBox: "0 0 20 20",
  },
  className: "blueDark",
};

const activeIcon = {
  icon: {
    body: `
      <g fillRule="evenodd">
        <circle fill="currentColor" cx="10" cy="10" r="9" />
        <path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209A7.954
        7.954 0 0 1 10 18c-4.411 0-8-3.589-8-8m14.312 4.897L5.103 3.688A7.954 7.954 0 0 1 10 2c4.411
        0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514
         0 10 0 0 4.486 0 10" />
      </g>`,
    viewBox: "0 0 20 20",
  },
  className: "yellowDark",
};

const greenIcon = {
  icon: {
    body: `
      <g fillRule="evenodd">
        <circle fill="currentColor" cx="10" cy="10" r="9" />
        <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0
        10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m2.293-10.707L9
        10.586 7.707 9.293a1 1 0 1 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414" />
      </g>`,
    viewBox: "0 0 20 20",
  },
  className: "greenDark",
};

const greyIcon = {
  icon: {
    body: `
      <g fillRule="evenodd">
        <circle fill="currentColor" cx="10" cy="10" r="9" />
        <path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209A7.954 7.954
        0 0 1 10 18c-4.411 0-8-3.589-8-8m14.312 4.897L5.103 3.688A7.954 7.954 0
        0 1 10 2c4.411 0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897M0 10c0 5.514 4.486 10
        10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10" />
      </g>`,
    viewBox: "0 0 20 20",
  },
  className: "inkLighter",
};

export { activeIcon, greenIcon, greyIcon, blueInfoIcon };
