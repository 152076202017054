import React, { useCallback, useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "react-apollo";
import {
  Banner as ShopifyBanner,
  Button,
  Card,
  Checkbox,
  Collapsible,
  FormLayout,
  Frame,
  Layout,
  PageActions,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { Banner, Spinner, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { MANAGE_SUBSCRIPTION } from "app/advanceVendor/apollo/mutations";
import { GET_CURRENCY, GET_CURRENCY_RATE } from "app/setup/apollo";
import validate from "./yup";

const VendorSubscription = () => {
  const [manageSubscription, { loading }] = useMutation(MANAGE_SUBSCRIPTION);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { chargebee: chargebeeData = {}, isChargebee = false, isStripeSubscription = false, stripeSubscription = {} } =
    currentUser || {};

  const { apiKey: apikeyChargebee = "", site = "", currencyCode = "" } = chargebeeData || {};
  const chargebeeEnabled = !!(isChargebee && chargebeeData) || false;
  const isStripe = !!(isStripeSubscription && stripeSubscription);

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [shopSubDomain, setShopSubDomain] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiKeyFilled, setApiKeyFilled] = useState(true);
  const [domainFilled, setDomainFilled] = useState(true);
  const [selectValueFilled, setSelectedValueFilled] = useState(true);
  const [currencyValue, setCurrencyValue] = useState("");

  const [errorValidationMessage, setValidationErrorMessage] = useState(true);
  const { SHOP_SUB_DOMAIN } = constant;
  const [listCurrency, setListCurrency] = useState();

  const { loading: currencyLoader, data: currencyData, error: currencyError } = useQuery(GET_CURRENCY);
  const { loading: rateLoader, data: rateData, refetch } = useQuery(GET_CURRENCY_RATE);

  const currencyDataResponse = baseHelper.getResponseData(currencyData, constant.gql.GET_CURRENCY);
  const currencyDataError = baseHelper.getResponseError(currencyData, constant.gql.GET_CURRENCY);

  const rateDataError = baseHelper.getResponseError(rateData, constant.gql.GET_CURRENCY_RATE);
  const rateDataResponse = baseHelper.getResponseData(rateData, constant.gql.GET_CURRENCY_RATE);

  useEffect(() => {
    if (currencyDataError) {
      setBanner({ status: constant.CRITICAL, title: currencyDataError, isOpen: true });
    }
    if (currencyError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(currencyError), isOpen: true });
    }
  }, [currencyDataError, currencyError]);
  //   const contentStatus = active ? constant.DISABLE : constant.ENABLE;
  //   const setButtonColor = active;

  useEffect(() => {
    if (currencyDataResponse && rateDataResponse) {
      const currencyList = currencyDataResponse
        .map((item) => {
          const isCheck =
            rateDataResponse.currency &&
            rateDataResponse.currency.rate.find((element) => element.currencyCode === item.code);
          if (isCheck) {
            return {
              label: item.name,
              value: baseHelper.ucFirst(item.code),
            };
          }
          return {
            label: item.name,
            value: item.code,
          };
        })
        .filter((item) => item);
      setListCurrency(currencyList);
    }
    if (rateDataError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: rateDataError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyDataResponse, rateDataResponse]);

  useEffect(() => {
    if (chargebeeEnabled) {
      setApiKey(apikeyChargebee);
      setShopSubDomain(site);
      setCurrencyValue(currencyCode);
      setChecked(true);
    }
  }, [apikeyChargebee, chargebeeEnabled, currencyCode, history, site]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChargebeeSubscription = useCallback(async (isEnabled, chargebee) => {
    try {
      const inputData = {
        isChargebee: isEnabled,
        chargebee: {},
      };
      if (chargebeeEnabled || isEnabled) {
        inputData.chargebee = chargebee;
      }
      const response = await manageSubscription({
        variables: { input: inputData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.MANAGE_SUBSCRIPTION);
      setIsLoadingAction(false);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setApiKeyFilled(true);
        setSelectedValueFilled(true);
        setDomainFilled(true);
        setSaveEnabled(true);
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("success.modal"),
      });
      refetch();
      setTimeout(() => {
        history.push("/setting/plan/add");
      }, 3000);
    } catch (exception) {
      baseHelper.getResponseError(exception, constant.gql.MANAGE_SUBSCRIPTION);
    }
  });

  const handleChangeCheckbox = useCallback((newChecked) => {
    setChecked(newChecked);
  }, []);

  const handleSelectChange = (selectedValue) => [setCurrencyValue(selectedValue), setSelectedValueFilled(true)];

  const handleValidation = async (field, val) => {
    const validationError = await validate(field, val, cms);

    setValidationErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const onSubmit = () => {
    handleChargebeeSubscription(checked, {
      site: shopSubDomain,
      apiKey,
      currencyCode: currencyValue,
    });
    setSaveEnabled(false);
    setApiKeyFilled(false);
    setDomainFilled(false);
    setSelectedValueFilled(false);
  };

  if (currencyLoader || rateLoader) {
    return <Spinner />;
  }

  const currentUserPlan = currentUser && currentUser.plan && currentUser.plan.code;
  const isPAYE = !!(currentUser && currentUser.plan && currentUser.plan.isPAYE);
  const isEnabled =
    isPAYE ||
    currentUser.shop === "shopsovereigncollectiv" ||
    [constant.GROWTH, constant.PRO].includes(currentUserPlan);

  if (!isEnabled) {
    const tierAction = {
      heading: cms("common.message.error.upgrade"),
      action: {
        content: cms("common.button.upgradePlan"),
        url: "/update/plan",
      },
    };
    return (
      <Layout.AnnotatedSection title={tierAction.heading}>
        <Card title={tierAction.heading} sectioned>
          <Stack>
            <Stack.Item>
              <Button primary onClick={() => history.push(tierAction.action.url)}>
                {tierAction.action.content}
              </Button>
            </Stack.Item>
          </Stack>
        </Card>
      </Layout.AnnotatedSection>
    );
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      {isStripe && (
        <Layout.Section>
          <ShopifyBanner isOpen status={constant.WARNING} title={cms("section.info.stripeEnabled")} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.setting.title")}
        description={<TextStyle variation="subdued">{cms("section.setting.description")}</TextStyle>}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <TextStyle variation="strong">{cms("section.subscription.title")}</TextStyle>
            <br />
            <br />
            <TextStyle>{cms("section.subscription.description")}</TextStyle>
          </div>
          <br />
          <Collapsible
            open
            id="basic-collapsible"
            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <Checkbox
              label={cms("label.enableSubscription")}
              disabled={(!apiKeyFilled && !domainFilled) || chargebeeEnabled || isStripe}
              checked={checked}
              onChange={handleChangeCheckbox}
            />
            {checked && (
              <>
                <br />
                <TextContainer>{cms("label.selectVendor")}</TextContainer>
                <br />
                <Stack vertical fill>
                  <FormLayout>
                    <TextField
                      label={`${cms("label.apiKey")}*`}
                      placeholder={cms("label.apiKey")}
                      id={constant.API_KEY}
                      type={constant.TEXT}
                      error={errorValidationMessage && errorValidationMessage.apiKey}
                      value={apiKey}
                      onChange={(val) => {
                        setApiKey(val.replace(/\s/g, ""));
                        setApiKeyFilled(true);
                      }}
                      disabled={(apiKey && !apiKeyFilled) || chargebeeEnabled}
                      onBlur={() => handleValidation("apiKey", apiKey)}
                    />
                    <TextField
                      label={`${cms("label.domain")}*`}
                      helpText={cms("section.operator.shop.helpText.shopSubDomain")}
                      error={errorValidationMessage && errorValidationMessage.shopSubDomain}
                      id={constant.SHOP_SUB_DOMAIN}
                      type={constant.TEXT}
                      suffix={cms("section.placeholder.suffix")}
                      prefix={cms("section.placeholder.prefix")}
                      value={shopSubDomain}
                      onChange={(val) => {
                        setShopSubDomain(val.replace(/\s/g, ""));
                        setDomainFilled(true);
                      }}
                      onBlur={() => handleValidation(SHOP_SUB_DOMAIN, shopSubDomain)}
                      disabled={
                        (!errorValidationMessage.shopSubDomain && shopSubDomain && !domainFilled) || chargebeeEnabled
                      }
                    />
                    <Select
                      label={`${cms("label.currency")}*`}
                      value={currencyValue}
                      disabled={(currencyValue && !selectValueFilled) || chargebeeEnabled}
                      options={listCurrency}
                      placeholder={cms("section.placeholder.selectCurrency")}
                      onChange={handleSelectChange}
                    />
                  </FormLayout>
                </Stack>
              </>
            )}
            {chargebeeEnabled && (
              <PageActions
                primaryAction={{
                  content: cms("common.button.update"),
                  onAction: () => onSubmit(),
                  disabled: true,
                  // loading: loading && !isLoadingAction,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            )}
          </Collapsible>
        </Card>
        <div className="manageVendorAccessButton">
          {!chargebeeEnabled && (
            <PageActions
              primaryAction={{
                content: chargebeeEnabled ? cms("common.button.update") : cms("common.button.submit"),
                onAction: () => onSubmit(),
                disabled: !(apiKey && shopSubDomain && currencyValue && checked) ? saveEnabled : !saveEnabled,
                loading: loading && !isLoadingAction,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withErrorBoundary(VendorSubscription);
