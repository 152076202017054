import { gql } from "apollo-boost";

const UPDATE_TRACKING_DETAIL = gql`
  mutation updateTrackingDetails($input: UpdateTracking) {
    updateTrackingDetails(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_TRACKING_DETAIL;
