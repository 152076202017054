import { gql } from "apollo-boost";

const CONNECT_STRIPE = gql`
  mutation connectStripe($input: PaymentInput) {
    connectStripe(input: $input) {
      status
      data
      error
    }
  }
`;

const CONNECT_PAYPAL_SELLER = gql`
  mutation connectPaypalSeller($input: PaypalSeller) {
    connectPaypalSeller(input: $input) {
      status
      data
      error
    }
  }
`;

const DISCONNECT_STRIPE = gql`
  mutation disconnectStripe($input: DisconnectStripe!) {
    disconnectStripe(input: $input) {
      status
      error
      data
    }
  }
`;

const DISCONNECT_PAYPAL = gql`
  mutation disconnectPaypal($input: DisconnectPaypal) {
    disconnectPaypal(input: $input) {
      status
      data
      error
    }
  }
`;
const TOGGLE_AUTO_PAYOUT = gql`
  mutation toggleAutoPayout($input: ToggleAutoPayout) {
    toggleAutoPayout(input: $input) {
      status
      data
      error
    }
  }
`;

export { CONNECT_STRIPE, DISCONNECT_STRIPE, CONNECT_PAYPAL_SELLER, DISCONNECT_PAYPAL, TOGGLE_AUTO_PAYOUT };
