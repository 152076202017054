import styled from "styled-components";

const ExportTable = styled.div`
  td,
  th {
    vertical-align: middle;
    &:nth-child(4) {
      text-align: center;
      width: 20%;
    }
    &:nth-child(3) {
      text-align: center;
      width: 20%;
    }
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(1) {
      width: 30%;
    }
  }
`;
export default ExportTable;
