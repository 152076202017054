import React, { useContext } from "react";
import { OperatorDigestSetting } from "app/userManagement/modules/operator/features";
import { ProviderDigestSetting } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { operator, provider } = userKey;

const DigestSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorDigestSetting />;
    }
    if (role === provider) {
      return <ProviderDigestSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const DigestEmailComponent = getComponent(currentUserRole);
  return DigestEmailComponent;
};

export default withErrorBoundary(DigestSetting);
