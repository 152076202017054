import React, { useCallback, useEffect, useState } from "react";
import { Button, DatePicker, PageActions, Popover as PolarisPopover, Select } from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import moment from "moment";

// import props
import { popoverProp } from "app/reporting/modules/operator/props";

const Popover = (props) => {
  const { timePeriod, timeByOptions, handleTimePeriod, selectedDates, disabled, submitReportingQuery } = props;
  const [popoverActive, setPopoverActive] = useState(false);
  const [calendarDate, setCalendarDate] = useState();
  const [dateText, setDateText] = useState(`${moment().date(0).format("ll")} - ${moment().format("ll")}`);
  const [{ month, year }, setDate] = useState({
    month: 5,
    year: 2020,
  });

  useEffect(() => {
    setCalendarDate(selectedDates);
  }, [selectedDates]);

  // eslint-disable-next-line no-shadow
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);
  const handleCalendarDate = (value) => {
    const { start, end } = value;
    const startDate = moment(start).format("ll");
    const endDate = moment(end).format("ll");
    setDateText(`${startDate}-${endDate}`);
    setCalendarDate(value);
  };

  const togglePopoverActive = useCallback(() => setPopoverActive((isPopoverActive) => !isPopoverActive), []);

  const activator = (
    <span>
      <p>Date range: </p>
      <Button icon={CalendarMinor} onClick={togglePopoverActive}>
        {dateText || "June12-Jul 12, 2020 "}
      </Button>
    </span>
  );

  return (
    <div>
      <PolarisPopover
        active={popoverActive && !disabled}
        activator={activator}
        onClose={togglePopoverActive}
        fluidContent
        preferredAlignment="left"
        preferredPosition="below"
      >
        <div style={{ padding: "15px 15px 0px" }}>
          <div>
            <Select
              label="Time period: "
              options={timeByOptions}
              value={timePeriod}
              onChange={handleTimePeriod}
              disabled={disabled}
            />
            <div style={{ paddingTop: "20px", marginTop: "10px" }}>
              <DatePicker
                month={month}
                year={year}
                selected={calendarDate}
                onChange={handleCalendarDate}
                onMonthChange={handleMonthChange}
                multiMonth
                allowRange
                disableDatesAfter={new Date()}
              />
            </div>
          </div>
          <div style={{ background: "#fff" }}>
            <PageActions
              primaryAction={{
                content: "Apply",
                onClick: () => {
                  submitReportingQuery(calendarDate);
                  togglePopoverActive();
                },
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onClick: togglePopoverActive,
                },
              ]}
            />
          </div>
        </div>
      </PolarisPopover>
    </div>
  );
};

Popover.propTypes = popoverProp.type;

export default Popover;
