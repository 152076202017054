import React, { useContext } from "react";

import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// components
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { storageHelper } from "lib/helpers";

import { ProviderInvoice } from "./provider/features";

const { userKey } = constant;
const { provider } = userKey;

const Invoice = () => {
  const currentUserRole = storageHelper.get("userRole");
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner isFullPage />;
    }
    if (role === provider) {
      return <ProviderInvoice />;
    }
    return null;
  };
  const InvoiceComponent = getComponent(currentUserRole);
  return InvoiceComponent;
};

export default withErrorBoundary(Invoice);
