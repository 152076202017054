import { gql } from "apollo-boost";

const GET_PRODUCT_VERSION = gql`
  query GetProductVersion($input: GetProductVersion!) {
    getProductVersion(input: $input) {
      error
      status
      data {
        isVariant
        productVersions {
          _id
          operation
          isSellerProduct
          productId
          revertedVersion
          updatedBy
          userName
          userName
          version
          via
          updatedAt
          createdAt
          changes {
            key
            extraDetail
            prev
            updated
          }
        }
      }
    }
  }
`;

export default GET_PRODUCT_VERSION;
