import { gql } from "apollo-boost";

const GET_WHOLESALE_SETTING = gql`
  query {
    getWholesaleSetting {
      error
      status
      data {
        isWholesalePriceVisible
      }
    }
  }
`;

export default GET_WHOLESALE_SETTING;
