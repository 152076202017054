import { gql } from "apollo-boost";

const CREATE_API_REQUEST_ACCESS = gql`
  mutation createPayeApiRequestAccess($input: CreatePayeApiRequestAccess) {
    createPayeApiRequestAccess(input: $input) {
      status
      data
      error
    }
  }
`;
export default CREATE_API_REQUEST_ACCESS;
