// packages
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import {
  Card,
  Checkbox,
  Banner as ShopifyBanner,
  EmptyState,
  FormLayout,
  InlineError,
  Layout,
  Link,
  Page,
  PageActions,
  Stack,
  TextField,
  Button,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// subFeatures
// import { PrivacyPolicy, TermsPolicy } from "app/public/modules/register/subFeatures";

// yup validation
import validate from "app/public/modules/register/yup/index";

// constant
import constant from "lib/constant/constant";

// helpers
import { baseHelper, storageHelper, errorHelper } from "lib/helpers";

// helper components
import { Banner, SkeletonAnnotated } from "lib/components";

// gql
import { REGISTER, REGISTER_BY_INVITE, REGISTER_BY_MOBILE, GENERATE_OTP } from "app/public/apollo/mutations/index";
import { BULK_INVITED_VENDOR_DATA } from "app/public/apollo/queries";
import { ViewMinor, HideMinor } from "@shopify/polaris-icons";

// context
import { PublicContext } from "lib/context";
import config from "configuration";

const Register = () => {
  const { history, location, cms } = useContext(PublicContext);
  const {
    gql,
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    IS_ACCEPT_POLICY,
    PRIVACY,
    // TERMS,
    POLICY_LINK,
    POLICY_URL,
    TERMS_LINK,
    TERMS_URL,
    PHONE_NUMBER,
  } = constant;
  const { store = "", seller, id, token } = baseHelper.queryParamsFromLocation(history);
  const isInviteRegisterPage = !!store;
  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  const { loginVia = "email" } = location.state || {};
  const isProductDiscovery = !!storageHelper.get("hideTermsAndPrivacy");
  // states
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    isAcceptPolicy: isProductDiscovery,
    phoneNumber: "",
  });
  const [isOtpSend, setOtpSend] = useState(false);
  const termsLink = storageHelper.get(TERMS_LINK);
  const policyLink = storageHelper.get(POLICY_LINK);
  const [errorMessage, setErrorMessage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });
  // const [modal, setModal] = useState({
  //   privacy: false,
  //   terms: false,
  // });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    message: "",
  });
  const [registerInfo, setRegisterInfo] = useState({
    title: "",
    description: "",
  });
  const [isEmail, setIsEmail] = useState(false);

  const [sellerInviteData, setSellerInviteData] = useState(null);
  const [inviteData, setInviteData] = useState(null);
  const [resendOtp, setResendOtp] = useState(false);

  const [checkInvitedVendor, { data: InvitedVendorData, loading: invitedDataLoading }] = useLazyQuery(
    BULK_INVITED_VENDOR_DATA,
    {
      variables: { input: { id: baseHelper.mongoIdAsString(id) } },
    }
  );
  useEffect(() => {
    if (id) checkInvitedVendor();
  }, [checkInvitedVendor, id]);

  useEffect(() => {
    if (InvitedVendorData) {
      const resData = baseHelper.getResponseData(InvitedVendorData, gql.GET_BULK_INVITED_USER);
      const resError = baseHelper.getResponseError(InvitedVendorData, gql.GET_BULK_INVITED_USER);
      if (resData) {
        const { seller: sellerInvitedData, invite } = resData;
        setSellerInviteData(sellerInvitedData);
        setInviteData(invite);
        setIsEmail(!!(invite && invite.email));
        setValues({ email: invite && invite.email });
      }
      if (resError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: resError });
      }
    }
  }, [InvitedVendorData, gql.GET_BULK_INVITED_USER]);
  const [registerUser, { loading }] = useMutation(REGISTER);
  const [registerInvitedUser, { loading: invitedUserRegisterLoading }] = useMutation(REGISTER_BY_INVITE);
  const [registerMobileUser, { loading: mobileUserRegisterLoading }] = useMutation(REGISTER_BY_MOBILE);
  const [generateOtp, { loading: generateLoading }] = useMutation(GENERATE_OTP);
  const acceptOnlyValidInput = (value) => {
    return baseHelper.acceptOnlyNumber(value);
  };
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    const isAnyValidationError =
      errorMessage &&
      !!(
        errorMessage.email ||
        errorMessage.password ||
        errorMessage.confirmPassword ||
        errorMessage.phoneNumber ||
        errorMessage.otp
      );

    const isAllValuesFilled =
      // eslint-disable-next-line no-nested-ternary
      loginVia === "email"
        ? values.email && values.password && values.confirmPassword && values.isAcceptPolicy
        : !isOtpSend
        ? values.phoneNumber
        : values.phoneNumber && values.otp && values.isAcceptPolicy;
    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [values, errorMessage]);

  useEffect(() => {
    if (values.password && values.confirmPassword) {
      handleValidation(CONFIRM_PASSWORD, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.password, CONFIRM_PASSWORD, values.confirmPassword]);

  const handleRegisterInfo = (title, description) => {
    if ((title && title !== "") || (description && description !== "")) {
      setRegisterInfo({
        title,
        description,
      });
    }
  };
  const { title: defaultRegisterTitle, description: defaultRegisterDescription } = registerInfo;
  const registerValue = storageHelper.get("register");
  if (registerValue && (defaultRegisterTitle === "" || defaultRegisterDescription === "")) {
    const { title, desc } = registerValue;
    handleRegisterInfo(title, desc);
  }

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: key === EMAIL ? value.trim() : value,
    }));
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      history.push({
        pathname: "/login",
        state: { loginVia },
      });
    }, 2000);
  };

  const onSubmit = async () => {
    let bannerData = {};
    try {
      if (loginVia === "email") {
        await handleValidation(EMAIL, values.email);
        await handleValidation(PASSWORD, values.password);
        await handleValidation(CONFIRM_PASSWORD, {
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
        await handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy);
      }
      if (loginVia === "phone") {
        await handleValidation(PHONE_NUMBER, values.phoneNumber);
      }
      if (isOtpSend) {
        await handleValidation(PHONE_NUMBER, values.phoneNumber);
        await handleValidation("otp", values.otp);
        await handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy);
      }

      if (!submitButton.isReadyToSubmit) {
        return false;
      }
      let res = {};

      if (loginVia === "email") {
        const inputData = {
          email: values.email,
          password: values.password,
        };
        let registerMutation = gql.REGISTER;
        if (isInviteRegisterPage || isCustomDomain) {
          registerMutation = gql.REGISTER_BY_INVITE;
          if (store) inputData.shop = store;
          if (seller || isCustomDomain) inputData.seller = storageHelper.get("sellerId") || seller;
          if (token) inputData.associatedToken = token;
          if (sellerInviteData && sellerInviteData.brandName) inputData.brandName = sellerInviteData.brandName;
          res = await registerInvitedUser({
            variables: { input: inputData },
          });
        }
        if (!(isInviteRegisterPage || isCustomDomain)) {
          res = await registerUser({
            variables: { input: inputData },
          });
        }
        const responseData = baseHelper.getResponseData(res.data, registerMutation);
        const errorData = baseHelper.getResponseError(res.data, registerMutation);
        bannerData = {
          isOpen: true,
          status: constant.SUCCESS,
          title: isInviteRegisterPage ? cms("message.success.registerSuccessfully") : cms("message.success.registered"),
        };

        if (!responseData && errorData) {
          bannerData = { isOpen: true, status: constant.CRITICAL, title: errorData };
        }

        if (bannerData) setBanner(bannerData);
        if (!errorData) {
          redirectToLogin();
        }
      }

      if (loginVia === "phone" && !isOtpSend) {
        generateOtp({ variables: { input: { mobileNumber: values.phoneNumber, isLogin: false } } })
          .then((response) => {
            const generateOtpResponse = baseHelper.getResponseData(response.data, "generateOtp");
            const generateOtpRError = baseHelper.getResponseError(response.data, "generateOtp");
            if (generateOtpRError) {
              setBanner({
                isOpen: true,
                status: constant.CRITICAL,
                title: generateOtpRError,
              });
            } else if (generateOtpResponse) {
              setSubmitState(false);
              setOtpSend(true);
              setTimeout(() => {
                setResendOtp(!isOtpSend);
              }, 15000);
            }
          })
          .catch(() => {
            setBanner({
              isOpen: true,
              status: constant.CRITICAL,
              title: cms("message.error.unknown"),
            });
          });
      }

      if (loginVia === "phone" && isOtpSend) {
        const inputData = {
          mobileNumber: values.phoneNumber,
          otp: values.otp,
        };
        let registerMutation = gql.REGISTER;
        if (isInviteRegisterPage || isCustomDomain) {
          registerMutation = gql.REGISTER_BY_MOBILE;
          if (store) inputData.shop = store;
          if (seller || isCustomDomain) inputData.seller = storageHelper.get("sellerId") || seller;
          if (token) inputData.associatedToken = token;
          if (sellerInviteData && sellerInviteData.brandName) inputData.brandName = sellerInviteData.brandName;
          res = await registerMobileUser({
            variables: { input: inputData },
          });
        }
        if (!(isInviteRegisterPage || isCustomDomain)) {
          res = await registerUser({
            variables: { input: inputData },
          });
        }
        const responseData = baseHelper.getResponseData(res.data, registerMutation);
        const errorData = baseHelper.getResponseError(res.data, registerMutation);
        bannerData = {
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("message.success.registerSuccessfully"),
        };

        if (!responseData && errorData) {
          bannerData = { isOpen: true, status: constant.CRITICAL, title: errorData };
        }

        if (bannerData) setBanner(bannerData);
        if (!errorData) {
          redirectToLogin();
        }
      }
    } catch (exception) {
      bannerData = { isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) };
      setBanner(bannerData);
    }
    return false;
  };

  // const toggleModal = (modalType) => {
  //   if (modalType === PRIVACY && policyLink) {
  //     window.open(policyLink);
  //     return;
  //   }
  //   if (modalType === TERMS && termsLink) {
  //     window.open(termsLink);
  //     return;
  //   }
  //   setModal((prevState) => ({
  //     ...prevState,
  //     [modalType]: !prevState[modalType],
  //   }));
  // };

  const renderViewIcon = () => (
    <Button id="eyeButton" plain icon={visible ? HideMinor : ViewMinor} onClick={() => setVisible(!visible)} />
  );

  if (invitedDataLoading) {
    return <SkeletonAnnotated />;
  }

  if (inviteData && inviteData.status === "revoked") {
    return (
      <Page>
        <EmptyState
          heading={cms("common.history.heading.sorry")}
          action={{
            content: cms("common.button.back"),
            onAction: () => history.push("/"),
          }}
        >
          <p>{`${cms("common.actions.invitation.caption.cancel", { store })}`}</p>
        </EmptyState>
      </Page>
    );
  }

  const policyLabel = cms("label.policyTerms").map((item) => {
    if (item && item.type && item.type === "action") {
      const itemUrl = item.key === PRIVACY ? policyLink || POLICY_URL : termsLink || TERMS_URL;
      return (
        <Link key={item.content} plain id="linkTerms" external url={itemUrl}>
          {item.content}
        </Link>
      );
    }
    return <React.Fragment key={item.content}>{item.content}</React.Fragment>;
  });

  if (!isCustomDomain && !id) {
    history.push("/login");
  }

  const onResendOtp = async () => {
    if (loginVia === "phone") {
      await handleValidation("phoneNumber", values.phoneNumber);
    }
    if (resendOtp) {
      generateOtp({ variables: { input: { mobileNumber: values.phoneNumber, isLogin: false } } })
        .then((response) => {
          const generateOtpResponse = baseHelper.getResponseData(response.data, "generateOtp");
          const generateOtpRError = baseHelper.getResponseError(response.data, "generateOtp");
          if (generateOtpRError) {
            setBanner({
              isOpen: true,
              status: constant.CRITICAL,
              title: generateOtpRError,
            });
          } else if (generateOtpResponse) {
            const isAnyValidationError = errorMessage.phoneNumber;
            const isAllValuesFilled = values.phoneNumber;
            setResendOtp(isAllValuesFilled && !isAnyValidationError && !isOtpSend);
          }
        })
        .then(() => {
          setTimeout(() => {
            setResendOtp(true);
          }, 60000);
        })
        .catch(() => {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.unknown"),
          });
        });
    }
  };

  const secondaryActionValue =
    // eslint-disable-next-line no-nested-ternary
    loginVia !== "phone"
      ? [
          {
            id: "registerCancelButton",
            content: cms("common.button.cancel"),
            onAction: () => history.push("/register-role"),
          },
        ]
      : isOtpSend
      ? [
          {
            id: "registerHere",
            content: "Resend OTP",
            disabled: !resendOtp,
            plain: true,
            onAction: () => {
              onResendOtp();
            },
          },
        ]
      : [];

  return (
    <>
      <Banner
        id="registerBanner"
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        onDismiss={() => setBanner({ isOpen: false })}
      >
        {banner.message}
      </Banner>
      {/* <PrivacyPolicy isOpen={modal.privacy} onClose={() => toggleModal(PRIVACY)} /> */}
      {/* <TermsPolicy isOpen={modal.terms} onClose={() => toggleModal(TERMS)} /> */}
      <br />
      <Layout>
        <Layout.AnnotatedSection
          title={registerInfo.title || cms("title")}
          description={registerInfo.description || cms("description")}
        >
          {loginVia === "email" && (
            <Card sectioned>
              <FormLayout>
                <TextField
                  id="email"
                  label={`${cms("common.label.email")}*`}
                  value={values.email || ""}
                  placeholder={cms("common.placeholder.emailAddress")}
                  onChange={(value) => handleChange(EMAIL, value)}
                  onBlur={() => handleValidation(EMAIL, values.email)}
                  disabled={isInviteRegisterPage || isEmail}
                  error={errorMessage && errorMessage.email}
                />
                <TextField
                  id="password"
                  label={`${cms("common.label.registerPassword")}*`}
                  placeholder={cms("common.placeholder.registerPassword")}
                  type="password"
                  value={values.password || ""}
                  onChange={(value) => handleChange(PASSWORD, value)}
                  onBlur={() => handleValidation(PASSWORD, values.password)}
                  error={errorMessage && errorMessage.password}
                />
                <ShopifyBanner isOpen status="info" title={cms("common.message.error.enterValidPassword")} />
                <TextField
                  id="confirmPassword"
                  label={`${cms("common.label.registerConfirmPassword")}*`}
                  placeholder={cms("common.placeholder.registerConfirmPassword")}
                  type="password"
                  value={values.confirmPassword || ""}
                  onChange={(value) => handleChange(CONFIRM_PASSWORD, value)}
                  onBlur={() => {
                    handleValidation(CONFIRM_PASSWORD, {
                      password: values.password,
                      confirmPassword: values.confirmPassword,
                    });
                  }}
                  error={errorMessage && errorMessage.confirmPassword}
                />
                {!isProductDiscovery && (
                  <Stack wrap spacing="extraTight">
                    <Checkbox
                      id="privacyPolicyTermsOfUse"
                      checked={values.isAcceptPolicy}
                      onChange={(value) => handleChange(IS_ACCEPT_POLICY, value)}
                      onBlur={() => handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy)}
                    />
                    <div className="checkBoxLabel">
                      <span>{policyLabel}</span>
                    </div>
                  </Stack>
                )}
                <InlineError message={errorMessage && errorMessage.isAcceptPolicy} fieldID="privacyPolicyTermsOfUse" />
              </FormLayout>
            </Card>
          )}

          {loginVia === "phone" && (
            <>
              <Card sectioned>
                <FormLayout>
                  <TextField
                    id="phone"
                    label={`${cms("common.label.phone")}*`}
                    value={values.phoneNumber || ""}
                    placeholder={cms("common.placeholder.phoneNumber")}
                    onChange={(value) => handleChange(PHONE_NUMBER, acceptOnlyValidInput(value))}
                    onBlur={() => handleValidation(PHONE_NUMBER, values.phoneNumber)}
                    disabled={isInviteRegisterPage || isEmail}
                    error={errorMessage && errorMessage.phoneNumber}
                  />

                  {isOtpSend && (
                    <>
                      <TextField
                        id="otp"
                        value={values.otp || ""}
                        onChange={(value) => handleChange("otp", acceptOnlyValidInput(value))}
                        onBlur={() => handleValidation("otp", values.otp)}
                        label={`${cms("common.label.otp")}*`}
                        name="otp"
                        placeholder={cms("common.placeholder.otp")}
                        type={visible ? "text" : "password"}
                        suffix={renderViewIcon()}
                        error={errorMessage && errorMessage.otp}
                      />
                      <br />
                      <Stack wrap spacing="extraTight">
                        <Checkbox
                          id="privacyPolicyTermsOfUse"
                          checked={values.isAcceptPolicy}
                          onChange={(value) => handleChange(IS_ACCEPT_POLICY, value)}
                          onBlur={() => handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy)}
                        />
                        <div className="checkBoxLabel">
                          <span>{policyLabel}</span>
                        </div>
                      </Stack>
                    </>
                  )}
                  <InlineError
                    message={errorMessage && errorMessage.isAcceptPolicy}
                    fieldID="privacyPolicyTermsOfUse"
                  />
                </FormLayout>
              </Card>
              {/* {isOtpSend && (
                <Button plain id="registerHere" onClick={() => {}}>
                  Reset OTP
                </Button>
              )} */}
            </>
          )}
          <PageActions
            primaryAction={{
              id:
                // eslint-disable-next-line no-nested-ternary
                loginVia === "phone"
                  ? !submitButton
                    ? "prelogin_button_disabled"
                    : "prelogin_button"
                  : "submitButton",
              content: loginVia === "phone" && !isOtpSend ? "Generate OTP" : cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton.isReadyToSubmit,
              loading: loading || invitedUserRegisterLoading || mobileUserRegisterLoading || generateLoading,
            }}
            secondaryActions={secondaryActionValue}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default withErrorBoundary(Register);
