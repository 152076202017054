import { gql } from "apollo-boost";

const GET_BRANDS = gql`
  query ResetPassword($input: Email) {
    getBrands(input: $input) {
      data {
        shops {
          label
          value
        }
        brandName {
          label
          value
        }
        associatedSellers {
          label
          value
        }
      }
      status
      error
    }
  }
`;

export default GET_BRANDS;
