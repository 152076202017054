import * as yup from "yup";
import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const { passwordRegex } = yupHelper;
const newPasswordSchema = (cms) =>
  yup.object().shape({
    newPassword: yup
      .string()
      .required(cms("common.message.error.passwordIsRequired"))
      .matches(passwordRegex, cms("common.message.error.enterValidPassword")),
  });
const confirmationPasswordSchema = (cms) =>
  yup.object().shape({
    confirmationPassword: yup
      .string()
      .required(cms("common.message.error.confirmPasswordIsRequired"))
      .oneOf([yup.ref(constant.NEW_PASSWORD), null], cms("common.message.error.confirmPasswordMatch")),
  });

export { newPasswordSchema, confirmationPasswordSchema };
