import { gql } from "apollo-boost";

const DOWNLOAD_LABEL = gql`
  query downloadLakeShippingLabel($input: ShippingLabelInput!) {
    downloadLakeShippingLabel(input: $input) {
      data
      status
      error
    }
  }
`;

export default DOWNLOAD_LABEL;
