import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { uriRegex } = yupHelper;

const uriSchema = (cms) =>
  yup.object().shape({
    URI: yup
      .string()
      .required(cms("message.validation.uriIsRequired"))
      .nullable()
      .matches(uriRegex, cms("message.error.invalidURI")),
  });

export default uriSchema;
