import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import mutation
import { SYNC_VENDOR_PRESTASHOP_PRODUCT } from "app/product/apollo/mutations";

// import props
import { syncPrestaShopProduct } from "app/product/modules/generic/propTypes";

const SyncPrestaShopProduct = (props) => {
  const { cms, history, setBanner } = props;
  const { CRITICAL, gql } = constant;

  const [syncVendorPrestaShopProduct, { loading: syncVendorPrestaShopProductLoading }] = useMutation(
    SYNC_VENDOR_PRESTASHOP_PRODUCT
  );
  const syncPrestaShopProducts = async () => {
    try {
      const response = await syncVendorPrestaShopProduct();
      const responseError = baseHelper.getResponseError(response.data, gql.SYNC_VENDOR_PRESTASHOP_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/prestashop");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewPrestaShopProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: `${cms("section.connect.message.success.sync")}`,
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncVendorPrestaShopProductLoading} onClick={(value) => syncPrestaShopProducts(value)}>
      Import products from Presta Shop store
    </Button>
  );
};

SyncPrestaShopProduct.propTypes = syncPrestaShopProduct.type;
SyncPrestaShopProduct.defaultProps = syncPrestaShopProduct.default;

export default SyncPrestaShopProduct;
