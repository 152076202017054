import { gql } from "apollo-boost";

const FETCH_ALL_USERS = gql`
  query {
    getAllUsers {
      data {
        brandNames {
          key
          value
        }
        shops {
          key
          value
        }
      }
      error
      status
    }
  }
`;

export default FETCH_ALL_USERS;
