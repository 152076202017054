import { gql } from "apollo-boost";

const GET_CURRENCY = gql`
  query {
    getAllCurrency {
      error
      status
      data {
        code
        name
        symbol
      }
    }
  }
`;

export default GET_CURRENCY;
