import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { SkeletonCard } from "lib/components";

// import context
import { ProductContext } from "app/product/modules/generic/context";
import { PrivateContext } from "lib/context";

import { GET_ADDRESS } from "app/setup/apollo/queries";

import { ServiceAvailbility } from "./subFeatures";

const Availability = () => {
  const { availability, getAvailability, serviceId, setTitle, setDescription } = useContext(ProductContext);
  const { cms } = useContext(PrivateContext);
  const { gql } = constant;

  const [address, setAddress] = useState([]);

  const { loading: addressLoading, data: addressData } = useQuery(GET_ADDRESS);

  const addressResponse = baseHelper.getResponseData(addressData, gql.GET_ADDRESS);
  setDescription(cms("label.editAvailability"));
  setTitle(cms("label.title.availability"));

  useEffect(() => {
    if (addressResponse && addressResponse.addressList) {
      const newData =
        (addressResponse &&
          addressResponse.addressList.map((item) => {
            return { label: item.label, value: item._id };
          })) ||
        [];
      setAddress(newData);
    }
  }, [addressResponse]);
  if (addressLoading) {
    return <SkeletonCard />;
  }

  return (
    <ServiceAvailbility
      address={address}
      availability={availability}
      serviceId={serviceId}
      getAvailability={getAvailability}
    />
  );
};

export default Availability;
