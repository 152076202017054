import React, { useContext, useState } from "react";
import { TextField } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const HopScotch = () => {
  const { cms, consignmentPickup, setConsignmentPickup, setSubmitEnable } = useContext(FulfillmentContext);
  const { apiKey = "" } = (consignmentPickup && consignmentPickup.hopscotch) || {};
  const [error, setError] = useState(false);

  const onBlur = () => {
    if (!apiKey && !apiKey.length) {
      setError(true);
    }
  };
  const handleHopScotch = (value) => {
    setConsignmentPickup((prevState) => ({
      ...prevState,
      hopscotch: { apiKey: value },
    }));
    setSubmitEnable(true);
  };

  return (
    <>
      <TextField
        label={`${cms("label.api")}*`}
        value={apiKey}
        placeholder={cms("placeholder.api")}
        onChange={(value) => handleHopScotch(value)}
        onBlur={onBlur}
        error={error.isActive}
      />
    </>
  );
};

export default withFeature(withErrorBoundary(HopScotch), {
  feature: constant.HOPSCOTCH,
});
