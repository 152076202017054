import { gql } from "apollo-boost";

const GET_VENDOR_HOLIDAY = gql`
  query {
    getVendorHolidaySetting {
      data {
        holiday {
          startDate
          endDate
          reason
          isRejectOrder
          updatedAt
        }
        vendorId
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_HOLIDAY;
