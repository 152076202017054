import parse from "html-react-parser";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MediaCard } from "@shopify/polaris";
import { PrivateBrandingContext, PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Spinner } from "lib/components";

const { DEFAULT_ONBOARDING_TITLE, onboardingSteps } = constant;

const Welcome = (props) => {
  const { setSelectedTab } = props;
  const { currentUserRefetch = () => {}, cms = {} } = useContext(PrivateContext);
  const { branding, brandingLoading } = useContext(PrivateBrandingContext);
  const { brand } = branding || {};
  const { welcome } = brand || {};
  const { cover: welcomeCover, description: welcomeDescription } = welcome || {};
  const { imageUrl: welcomeImage = "" } = welcomeCover || {};
  const { description: welcomeDesc = "" } = welcomeDescription || {};

  if (brandingLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <MediaCard
      // title={!welcomeDesc && cms("common.onboarding.vendorWelcome.title")}
      title={DEFAULT_ONBOARDING_TITLE}
      primaryAction={{
        content: cms("common.onboarding.vendorWelcome.button"),
        onAction: () => {
          setSelectedTab(onboardingSteps.UPDATE_PROFILE);
          currentUserRefetch();
        },
        primary: true,
      }}
      description={welcomeDesc ? parse(welcomeDesc) : constant.DEFAULT_WELCOME_DESCRIPTION}
    >
      <img
        className="onboarding-thumbnail"
        src={welcomeImage || constant.WELCOME_DEFAULT_IMAGE}
        alt={{ DEFAULT_ONBOARDING_TITLE }}
      />
    </MediaCard>
  );
};

Welcome.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default Welcome;
