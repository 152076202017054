import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Checkbox, ChoiceList, Modal, Stack, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner } from "lib/components";

// import yup
import { schema } from "app/payments/modules/operator/features/list/yup/schema";
import validate from "app/payments/modules/operator/features/list/yup";

// import propTypes
import { viewProp } from "app/payments/modules/operator/features/list/propTypes";

// import gql
import PAY_VENDOR from "app/payments/apollo/mutation/payVendor";
import { GET_SELLER_SETTING, GET_CURRENCY_PAYOUT } from "app/setup/apollo/queries";
import { GET_CREDIT_NOTE_SETTING } from "app/setup/apollo";

const View = (props) => {
  const {
    active,
    cms,
    commissionVendor,
    fulfillmentType,
    handleClose,
    moneyFormat,
    orderLineItemId,
    setBanner,
    shippingAmount,
    shippingTax,
    vendor,
    vendorId,
  } = props;

  const [balance, setBalance] = useState(0);
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState(false);
  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);
  const [isCreditNote, setIsCreditNote] = useState(false);
  const [isVendorCurrency, setIsVendorCurrency] = useState(false);
  const [selected, setSelected] = useState([]);
  const [vendorCurrency, setVendorCurrency] = useState({});
  const [modalBanner, setModalBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const [payVendor, { loading }] = useMutation(PAY_VENDOR);

  const handleCheckbox = useCallback((newChecked) => setChecked(newChecked), []);

  const amount =
    (isExcludeShippingAmount && parseFloat(commissionVendor)) || // && (shippingAmount === 0 || !shippingAmount)
    parseFloat(commissionVendor) + parseFloat(shippingAmount) + parseFloat(shippingTax || 0);

  const priceValue = (isVendorCurrency && `(${baseHelper.getCurrencyPrice(amount, vendorCurrency)})`) || "";

  const displayPrice = (priceValue && `: ${moneyFormat}${baseHelper.getPrice(amount)} ${priceValue}`) || "";

  const modalData = [
    {
      label: cms("label.payStripe"),
      value: constant.STRIPE,
      name: "Radio Group",
      helpText: cms("helpText.stripe"),
      disabled: fulfillmentType === constant.RESERVE,
    },
    {
      label: cms("label.payPaypal"),
      value: constant.PAYPAL,
      name: "Radio Group",
      helpText: cms("helpText.paypal"),
      disabled: fulfillmentType === constant.RESERVE,
    },
    {
      label: `${cms("label.manual")}${
        (selected && selected.includes(constant.MANUAL) && isVendorCurrency && vendorCurrency.price && displayPrice) ||
        ""
      }`,
      value: constant.MANUAL,
      name: "Radio Group",
      helpText: cms("helpText.manual"),
    },
  ];

  const { data: sellerSetting, loading: sellerSettingLoading } = useQuery(GET_SELLER_SETTING);
  const { data: currencyPayout } = useQuery(GET_CURRENCY_PAYOUT);

  useEffect(() => {
    if (!sellerSetting) {
      return;
    }
    const sellerData = baseHelper.getResponseData(sellerSetting, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    // eslint-disable-next-line no-shadow
    const { isExcludeShippingAmount = false } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
  }, [sellerSetting]);

  if (selected) {
    schema(selected);
  }

  useEffect(() => {
    if (selected && selected.includes(constant.MANUAL) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.rate")} ${moneyFormat}1 = ${vendorCurrency.currencySymbol}${vendorCurrency.price}`,
        isOpen: true,
        status: constant.INFO,
      });
    } else if (selected && selected.includes(constant.STRIPE) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.stripeRate")}`,
        isOpen: true,
        status: constant.INFO,
      });
    } else if (selected && selected.includes(constant.PAYPAL) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.paypalRate")}`,
        isOpen: true,
        status: constant.INFO,
      });
    }
  }, [cms, moneyFormat, selected, vendorCurrency]);

  useLayoutEffect(() => {
    if (!currencyPayout) {
      return;
    }

    const sellerPayoutSetting = baseHelper.getResponseData(currencyPayout, constant.gql.GET_CURRENCY_PAYOUT);
    if (!sellerPayoutSetting) {
      // set banner or not
      return;
    }

    const { currency: currencyData = {} } = sellerPayoutSetting || {};
    const { rate = [], payment = {} } = currencyData || {};
    const { selectedVendor } = payment || {};

    let selectedVendorDetail = {};
    let vendorSelectedCurrency = {};

    if (selectedVendor && selectedVendor.length) {
      selectedVendorDetail = selectedVendor.find((item) => item.vendorId === vendorId);
    }

    if (selectedVendorDetail && Object.keys(selectedVendorDetail).length) {
      vendorSelectedCurrency = rate.find((item) => item.currencyCode === selectedVendorDetail.currencyCode);
      const { currencySymbol = "", price } = vendorSelectedCurrency || {};
      setVendorCurrency({
        currencySymbol,
        price,
      });
      setIsVendorCurrency(true);
    }
  }, [currencyPayout, vendorId]);

  const { error: errorGetCreditNote, loading: loadingGetCreditNote, data: dataGetCreditNote /* refetch */ } = useQuery(
    GET_CREDIT_NOTE_SETTING,
    {
      variables: {
        input: { vendorId },
      },
    }
  );

  const creditNoteResponseData = baseHelper.getResponseData(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  const creditNoteErrorData = baseHelper.getResponseError(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  useEffect(() => {
    if (creditNoteResponseData) {
      const { creditNote = {}, creditBalance = 0 } = creditNoteResponseData;
      const { isAllVendor = false, isEnabled = false, selectedVendor = [] } = creditNote || {};
      if (
        (isEnabled && isAllVendor && creditBalance) ||
        (isEnabled && creditBalance && !isAllVendor && selectedVendor.includes(vendorId))
      ) {
        setIsCreditNote(true);
        setBalance(creditBalance);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditNoteResponseData]);

  useEffect(() => {
    if (creditNoteErrorData) {
      setBanner({ status: constant.CRITICAL, title: creditNoteErrorData, isOpen: true });
    }
    if (errorGetCreditNote) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetCreditNote), isOpen: true });
    }
  }, [creditNoteErrorData, errorGetCreditNote, setBanner]);

  const submit = async () => {
    const validateError = await validate(selected, cms("message.error"));
    if (selected.length === 0) {
      setError(validateError);
      return;
    }
    try {
      const userData = { paymentMethod: selected[0], orderLineItemId };
      if (isCreditNote) {
        userData.isUseCreditBalance = checked;
      }
      const response = await payVendor({ variables: { input: userData } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.PAY_VENDOR);
      const bannerValue = {
        isOpen: true,
        title: `${moneyFormat} ${baseHelper.getPrice(amount)} ${cms("label.pay").toLowerCase()}
        ${cms("label.to")} ${vendor} ${cms("label.success")}`,
        status: constant.SUCCESS,
      };
      if (responseError) {
        bannerValue.title = response.data.payVendor.error;
        bannerValue.status = constant.CRITICAL;
      }
      setBanner(bannerValue);
      setError(false);
    } catch (exception) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(exception),
        status: constant.CRITICAL,
      });
    }
    setSelected("");
    setIsVendorCurrency(false);
    setVendorCurrency({});
    handleClose();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: constant.SMOOTH,
    });
  };
  const handleChange = (e) => {
    setSelected(e);
    setError(false);
  };

  const payoutAmount = !sellerSettingLoading ? `${moneyFormat}${baseHelper.getPrice(amount)}` : "";
  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${cms("label.pay")} ${vendor} ${payoutAmount}`}
      primaryAction={{
        content: cms("label.payVendor"),
        onAction: () => {
          submit();
        },
        loading,
      }}
      loading={loadingGetCreditNote || sellerSettingLoading}
    >
      <Modal.Section>
        {selected &&
          (selected.includes(constant.MANUAL) ||
            selected.includes(constant.PAYPAL) ||
            selected.includes(constant.STRIPE)) &&
          isVendorCurrency &&
          vendorCurrency.price > 0 && (
            <>
              <Banner status={modalBanner.status} isOpen={modalBanner.isOpen} isScrollTop={false}>
                <TextStyle>{modalBanner.title}</TextStyle>
              </Banner>
              <br />
            </>
          )}
        {isCreditNote && (
          <>
            <Stack>
              <Checkbox
                label={cms("label.adjust", {
                  item: `${moneyFormat}${
                    balance > amount ? baseHelper.getPrice(amount) : baseHelper.getPrice(balance)
                  }`,
                })}
                checked={checked}
                onChange={handleCheckbox}
              />
            </Stack>
            <br />
          </>
        )}
        <Stack vertical>
          <Stack.Item>
            <ChoiceList choices={modalData} selected={selected} onChange={handleChange} error={error} />
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

View.propTypes = viewProp.type;

export default View;
