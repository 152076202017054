import React, { useContext, useState } from "react";
import _ from "lodash";
import {
  Badge,
  Button,
  Caption,
  Collapsible,
  Heading,
  Link,
  ResourceItem,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import {
  DeleteMinor,
  EditMinor,
  ImportStoreMajor,
  CircleCancelMajor,
  CircleTickMajor,
  ViewMinor,
  UndoMajor,
  RefreshMinor,
  ListMajor,
} from "@shopify/polaris-icons";
// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper, imageHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";
import LazyLoad from "react-lazyload";
import Popover from "lib/components/popover/popover";
import ProductVersioning from "./productVersioning";

const ProductListView = (props) => {
  const { SHOPIFY_ICON, WIX, WIX_ICON, WOOCOMMERCE, WOOCOMMERCE_ICON, MAGENTO, MAGENTO_ICON, PRESTASHOP, PRESTASHOP_ICON } = constant;
  const {
    active,
    activePopover,
    bulkAction,
    cms,
    handleDeleteModal,
    handleRejectModal,
    item,
    loadingBulkProduct,
    popoverDisable,
    reSyncAction,
    setActive,
    setActivePopover,
    setListBanner,
    isproductListSetting,
  } = props;
  const { currentUser, history } = useContext(PrivateContext);
  const {
    _id: id,
    compaign = "",
    isDraft = false,
    images,
    inventoryManagement,
    isService = false,
    listingQuantity,
    liveConnect,
    progressQuantity,
    productId,
    published,
    quantity,
    serviceId = null,
    status,
    vendorShopifyProductId,
    title,
    updatedAt,
    variants = [],
    sku,
    vendor,
    vendorId,
  } = item;
  const { platform: liveConnectPlatform = "" } = liveConnect || {};

  const currentDate = new Date();
  const newDate = new Date(compaign);
  const oneDay = 1000 * 60 * 60 * 24;
  const difference = newDate.getTime() - currentDate.getTime();
  const diffInDays = Math.floor(difference / oneDay);
  const hourDiff = Math.floor(difference / 1000 / 60 / 60);

  let icon;
  if (vendorShopifyProductId) {
    icon = SHOPIFY_ICON;
  }
  if (liveConnectPlatform === WIX) {
    icon = WIX_ICON;
  }
  if (liveConnectPlatform === WOOCOMMERCE) {
    icon = WOOCOMMERCE_ICON;
  }
  if (liveConnectPlatform === MAGENTO) {
    icon = MAGENTO_ICON;
  }
  if (liveConnectPlatform === PRESTASHOP) {
    icon = PRESTASHOP_ICON;
  }

  const goalProgress = ((progressQuantity / listingQuantity) * 100).toFixed(2);

  const size = constant.LARGE;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const productID = isSeller ? productId : id;
  const thumbnailImage =
    images.length > 0
      ? imageHelper.resize({ url: images[0].imageUrl, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
  const thumbnail = <Thumbnail source={thumbnailImage} size={size} alt={`Image of ${title}`} />;
  const skeletalThumbnail = <SkeletonThumbnail size={size} />;
  const media = (
    <LazyLoad once height={80} offset={100} placeholder={skeletalThumbnail}>
      {thumbnail}
    </LazyLoad>
  );
  const statusType = status.toLowerCase() === constant.APPROVED.toLowerCase() ? constant.PUBLISHED : status;
  const isDraftStatus = isDraft ? baseHelper.ucFirst(constant.DARFT) : "";
  const badgeStatus = baseHelper.ucFirst(baseHelper.getBadgeStatus(statusType));
  const serviceBadgeText = baseHelper.ucFirst(baseHelper.getServiceBadge(isService));
  const badgeType = baseHelper.getBadgeType(status);

  const topMargin10px = {
    marginTop: "10px",
  };

  const handleResync = () => {
    reSyncAction(vendorShopifyProductId);
  };

  const isAvailablePublishment = _.isBoolean(published);

  const isUnavailableQuantity = variants && variants.length ? variants.every((variant) => !variant.inventoryManagement ? false : (variant.inventoryManagement && !variant.inventoryQuantity)) : !inventoryManagement ? false : !quantity;

  const productURL = isService
    ? `${constant.PRODUCT_SERVICE_URL_UPDATE}${productID}?tab=${constant.INFORMATION}`
    : `${constant.PRODUCT_URL_UPDATE}${productID}?tab=${constant.INFORMATION}`;

  const handleSelect = (productStatus) => {
    if (productStatus === constant.VIEW_ID) {
      // history.push(`${constant.PRODUCT_URL_UPDATE}${productID}`);
      history.push(productURL);
    }

    if (productStatus === constant.REVIEW_ALL) {
      history.push(`${constant.PRODUCT_URL_REVIEW}${productID}`);
    }

    if (productStatus === constant.PUSH_TO_STORE_ID) {
      setSelectedButtonIndex(productID);
      bulkAction(constant.APPROVE, false, productID, "", null, constant.NEW);
    }

    if (productStatus === constant.UNDELETE) {
      handleRejectModal(constant.UNDELETE, productID, productStatus);
    }

    if (productStatus === constant.UNREJECT) {
      handleRejectModal(constant.UNREJECT, productID, productStatus);
    }

    if (productStatus === constant.REJECT) {
      handleRejectModal(constant.REJECT, productID, productStatus);
    }

    if (productStatus === constant.RE_SUBMIT) {
      handleRejectModal(constant.RE_SUBMIT, productID, productStatus);
    }

    if (productStatus === constant.DELETED) {
      handleDeleteModal(constant.DELETE.toLowerCase(), productID, productStatus);
    }

    // if(productStatus === "available"){
    //   bulkAction(constant.PUBLISH, false, productID);
    // }

    // if(productStatus === "unavailable"){
    //   bulkAction(constant.UNPUBLISH, false, productID);
    // }
  };

  const disablePublish = !!(isService && !serviceId);
  const publishContent = disablePublish ? (
    <Tooltip active content={cms("label.serviceMissing")}>
      <TextStyle variation="strong">{constant.PUSH_TO_STORE_LABEL}</TextStyle>
    </Tooltip>
  ) : (
    constant.PUSH_TO_STORE_LABEL
  );

  const pushToStore = {
    content: publishContent,
    value: constant.PUSH_TO_STORE_ID,
    disabled: disablePublish,
    icon: ImportStoreMajor,
    onAction: () => handleSelect(constant.PUSH_TO_STORE_ID),
  };
  const review = {
    content: constant.displayStatus.NEEDSREVIEW,
    value: constant.REVIEW_ALL,
    disabled: false,
    icon: ViewMinor,
    onAction: () => handleSelect(constant.REVIEW_ALL),
  };
  const view = {
    content: constant.EDIT_LABEL,
    value: constant.VIEW_ID,
    disabled: false,
    icon: EditMinor,
    onAction: () => handleSelect(constant.VIEW_ID),
  };
  const undelete = {
    content: constant.UN_DELETE,
    value: constant.UNDELETE,
    disabled: false,
    icon: UndoMajor,
    onAction: () => handleSelect(constant.UNDELETE),
  };
  const unreject = {
    content: constant.UN_REJECT,
    value: constant.UNREJECT,
    disabled: false,
    icon: CircleTickMajor,
    onAction: () => handleSelect(constant.UNREJECT),
  };
  const reject = {
    content: constant.REJECT_LABEL,
    value: constant.REJECT,
    disabled: false,
    icon: CircleCancelMajor,
    destructive: true,
    onAction: () => handleSelect(constant.REJECT),
  };
  const deleteOption = {
    content: constant.DELETE,
    value: constant.DELETED,
    disabled: false,
    destructive: true,
    icon: DeleteMinor,
    onAction: () => handleSelect(constant.DELETED),
  };
  // const markAvailable = { label: t("label.available"), value: "available", disabled: false };
  // const markUnavailable = { label: t("label.unavailable"), value: "unavailable", disabled: false };
  const reSubmit = {
    content: cms("label.resubmit"),
    value: constant.RE_SUBMIT,
    disabled: false,
    icon: ListMajor,
    onAction: () => handleSelect(constant.RE_SUBMIT),
  };

  const resync = {
    content: cms("label.resync"),
    value: constant.RE_SYNC,
    disabled: false,
    icon: RefreshMinor,
    onAction: () => handleResync(),
  };

  const viewOption = [view];
  const pushToStoreOption =
    status.toLowerCase() === constant.NEW || status === constant.REVIEWED
      ? [pushToStore]
      : [{ ...pushToStore, disabled: true }];
  const reviewOption = status === constant.NEEDS_REVIEW ? [review] : [{ ...review, disabled: true }];
  const undeleteOption = status === constant.DELETED ? [undelete] : [{ ...undelete, disabled: true }];
  const unrejectOption = status.toLowerCase() === constant.REJECTED ? [unreject] : [{ ...unreject, disabled: true }];

  const rejectOption =
    status.toLowerCase() === constant.NEW ? [reject] : [{ ...reject, disabled: true, destructive: false }];
  const deleteOptions =
    status !== constant.DELETED || (isVendor && status === constant.DELETED)
      ? [deleteOption]
      : [{ ...deleteOption, disabled: true, destructive: false }];

  // const markAvailableOptions = (published && status.toLowerCase() !== constant.REJECTED && status !== constant.DELETED) ? [markAvailable] : [{ ...markAvailable, disabled: true }];
  // const markUnavailableOptions = (status === constant.REVIEWED || status === constant.NEEDS_REVIEW || status === constant.APPROVED) ? [markUnavailable] : [{ ...markUnavailable, disabled: true }];

  const reSubmitOption =
    status === constant.DELETED || status.toLowerCase() === constant.REJECTED
      ? [reSubmit]
      : [{ ...reSubmit, disabled: true }];
  let options = [];

  if (isSeller) {
    options = [...viewOption];
    if (status.toLowerCase() === constant.NEW || status === constant.REVIEWED) {
      options.unshift(...pushToStoreOption);
    }
    if (status === constant.NEEDS_REVIEW) {
      options.unshift(...reviewOption);
    }
    if (status === constant.DELETED) {
      options.unshift(...undeleteOption);
    }
    if (status.toLowerCase() === constant.REJECTED) {
      options.unshift(...unrejectOption);
    }
    if (!(status.toLowerCase() === constant.NEW || status === constant.REVIEWED)) {
      options.push(...pushToStoreOption);
    }
    if (!(status === constant.NEEDS_REVIEW)) {
      options.push(...reviewOption);
    }
    if (!(status === constant.DELETED)) {
      options.push(...undeleteOption);
    }
    if (!(status.toLowerCase() === constant.REJECTED)) {
      options.push(...unrejectOption);
    }
    if (!(status.toLowerCase() === constant.NEW)) {
      options.push(...rejectOption);
    }
    if (!(status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED)) {
      options.push(...deleteOptions);
    }
    if (status.toLowerCase() === constant.NEW) {
      options.push(...rejectOption);
    }
    if (status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED) {
      options.push(...deleteOptions);
    }
  }

  if (isVendor) {
    options = [...viewOption];
    if (status === constant.DELETED || status.toLowerCase() === constant.REJECTED) {
      options.unshift(...reSubmitOption);
    }
    if (!(status === constant.DELETED || status.toLowerCase() === constant.REJECTED)) {
      options.push(...reSubmitOption);
    }
    options.push(...deleteOptions);
    if (vendorShopifyProductId) {
      options.push(resync);
    }
  }

  if (isSystemAdmin) {
    options = [...viewOption, ...deleteOptions];
  }

  const variantSku =
    (Array.isArray(variants) &&
      variants.map((variantItem) => variantItem.sku).filter((fvalue) => fvalue !== "" && fvalue !== null)) ||
    "";

  const progressBadge =
    (progressQuantity >= listingQuantity && constant.TARGET) ||
    (progressQuantity < listingQuantity && hourDiff < 0 && constant.FAILURE);

  const progressBadgeStatus =
    (progressBadge === constant.TARGET && constant.SUCCESS) ||
    (progressBadge === constant.FAILURE && constant.CRITICAL);

  return (
    <>
      <ResourceItem
        id={productID}
        key={productID}
        accessibilityLabel={`${cms("label.detail")} ${title}`}
        persistActions
        recordID={id}
      >
        <Stack alignment={constant.LEADING} wrap={false}>
          <Stack.Item>{media}</Stack.Item>
          <Stack.Item fill>
            <div className="ellipsis">
              <Heading element="h2">
                <Link onClick={() => history.push(productURL)}>
                  <TextStyle>{title}</TextStyle>
                </Link>
              </Heading>
              <Caption>
                <TextStyle variation={constant.SUBDUED}>
                  {`${constant.UPDATED} ${constant.ON}: `}
                  {`${baseHelper.formatDate(updatedAt)}`}
                </TextStyle>
                <br />
                {`${constant.VENDOR_LABEL}: `}
                {(isVendor && vendor) || <Link url={`/providers/view/${vendorId}`}>{vendor}</Link>}
                <br />
                <Caption>
                  <TextStyle variation={constant.SUBDUED}>{`${cms("label.variant")} ${variants.length}`}</TextStyle>
                </Caption>
                <Caption>
                  {(sku || !!variantSku.length) && (
                    <TextStyle variation={constant.SUBDUED}>{`${cms("label.sku")}: ${sku || variantSku[0]}`}</TextStyle>
                  )}
                </Caption>
                {(listingQuantity && isproductListSetting && (
                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>
                      {`${cms("label.progress")} `}
                      {`${progressQuantity || 0}/${listingQuantity} (${goalProgress}% to goal)`}
                    </TextStyle>
                    <br />
                    {/* <TextStyle variation={constant.SUBDUED}>
                      {`${cms("label.goalProgress", { goal: goalProgress })}`}
                    </TextStyle> */}
                  </Caption>
                )) ||
                  null}
                {(hourDiff >= 0 && isproductListSetting && compaign && (
                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>{`${cms("label.campaign")} ${diffInDays}`}</TextStyle>
                  </Caption>
                )) ||
                  null}
                {(hourDiff < 0 && compaign && isproductListSetting && (
                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>{`${cms("label.campaign")} 0`}</TextStyle>
                  </Caption>
                )) ||
                  null}
                {isAvailablePublishment && (
                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>
                      {`${constant.AVAILABILITY_STATUS}: `}
                      {published ? constant.AVAILABLE : constant.UNAVAILABLE}
                    </TextStyle>
                  </Caption>
                )}
              </Caption>
              <div style={topMargin10px}>
                <Stack>
                  {serviceBadgeText ? (
                    <Stack.Item>
                      <Badge status="success">
                        <span>{serviceBadgeText}</span>
                      </Badge>
                    </Stack.Item>
                  ) : (
                    ""
                  )}
                  <Stack.Item>
                    <Badge status={badgeType}>
                      <span className="text-capitalize">{badgeStatus}</span>
                    </Badge>
                    {statusType !== constant.PUBLISHED && isDraft ? (
                      <Badge>
                        <span className="text-capitalize">{isDraftStatus}</span>
                      </Badge>
                    ) : (
                      ""
                    )}
                    {!isService && isUnavailableQuantity ? (
                      <Badge status={constant.CRITICAL}>
                        <span>{cms("label.outOfStock")}</span>
                      </Badge>
                    ) : (
                      ""
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    {compaign && hourDiff < 0 && isproductListSetting && (
                      <Badge status={constant.CRITICAL}>
                        <span>{constant.COMPAIGN_END}</span>
                      </Badge>
                    )}
                    {compaign && hourDiff >= 0 && isproductListSetting && (
                      <Badge status={constant.ATTENTION}>
                        <span>{constant.COMPAIGN}</span>
                      </Badge>
                    )}
                    {compaign && progressBadge && isproductListSetting && (
                      <Badge status={progressBadgeStatus}>
                        <span>{progressBadge}</span>
                      </Badge>
                    )}
                    {compaign && hourDiff < 0 && progressQuantity > listingQuantity && isproductListSetting && (
                      <Badge status={constant.SUCCESS}>
                        <span>{constant.PRODUCT_DEVELOPMENT}</span>
                      </Badge>
                    )}
                  </Stack.Item>
                  {liveConnectPlatform && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.platform")}>
                          <img src={icon} alt={liveConnectPlatform} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {vendorShopifyProductId && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.platform")}>
                          <img src={icon} alt={liveConnectPlatform} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  <Stack.Item>
                    <div className="timelineLink">
                      <Button
                        plain
                        monochrome
                        disclosure={active[id] ? constant.UP : constant.DOWN}
                        onClick={() => {
                          setActive((prev) => {
                            return {
                              ...prev,
                              [id]: !active[id],
                            };
                          });
                        }}
                      >
                        History
                      </Button>
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              active={activePopover[id]}
              setActive={() =>
                popoverDisable ? setActivePopover(false) : setActivePopover({ [id]: !activePopover[id] })
              }
              loading={selectedButtonIndex && loadingBulkProduct}
              options={options}
            />
          </Stack.Item>
        </Stack>
        <Collapsible
          open={active && active[id]}
          id="timeline"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <ProductVersioning cms={cms} productId={productID} setListBanner={setListBanner} />
        </Collapsible>
      </ResourceItem>
    </>
  );
};
ProductListView.defaultProps = {
  cms: () => {},
  item: {
    _id: "",
    title: "",
    vendor: "",
    createdAt: "",
    variantCount: "",
    published: "",
    productId: "",
    status: "",
    images: { imageUrl: null },
  },
  bulkAction: () => {},
  handleRejectModal: () => {},
  handleDeleteModal: () => {},
  loadingBulkProduct: "",
  activePopover: {},
};

ProductListView.propTypes = {
  cms: PropTypes.func,
  item: PropTypes.shape(PropTypes.object),
  bulkAction: PropTypes.func,
  handleRejectModal: PropTypes.func,
  handleDeleteModal: PropTypes.func,
  loadingBulkProduct: PropTypes.string,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ProductListView), { feature: constant.PRODUCT });
