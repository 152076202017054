import React, { useState, useCallback, useContext, useEffect } from "react";
import { Layout, Card, Checkbox, Banner, PageActions, TextField } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

import { SkeletonAnnotated } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import gql
import { CUSTOMER } from "app/orders/apollo/mutations/index";
import { GET_CUSTOMER } from "app/orders/apollo/queries/index";

// import yup validation
import validate from "./yup";

const ManageCustomerDetails = () => {
  const history = useHistory();
  const { /* currentUser */ cms, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  // const { _id: currentUserId = "" } = currentUser || {};
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [emailChecked, setEmailChecked] = useState(false);
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [emailField, setEmailField] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [error, setError] = useState({});
  const [isAnyError, setIsAnyError] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");

  const { loading: customerLoading = false, error: customerError, data: customerData } = useQuery(GET_CUSTOMER);
  // , {
  //   variables: {
  //     input: { sellerId: currentUserId },
  //   },
  // });

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setSubmitDisabled(false);
    setError((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const [customer, { loading: customerUpdateLoading }] = useMutation(CUSTOMER);

  useEffect(() => {
    if (customerData) {
      const resData = baseHelper.getResponseData(customerData, gql.GET_CUSTOMER);

      if (!resData) {
        setIsAnyError(true);
      }

      const { updatedAt = "" } = (resData && resData.customer) || {};
      setLastUpdated(updatedAt);
      setEmailChecked(resData && resData.customer && resData.customer.isEmailChecked);
      setEmailField((resData && resData.customer && resData.customer.email) || "");
      setPhoneChecked(resData && resData.customer && resData.customer.isPhoneNumberChecked);
      setPhoneField((resData && resData.customer && resData.customer.phoneNumber) || "");
    }
  }, [customerData, gql.GET_CUSTOMER]);

  useEffect(() => {
    if (customerError) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(customerError) });
    }
  }, [customerError]);

  const handleChange = useCallback((newChecked, id) => {
    if (id === constant.EMAIL) {
      setEmailChecked(newChecked);
      setError({});
    }
    if (id === constant.EMAIL && !newChecked) {
      setEmailField(null);
    }
    if (id === constant.PHONE_NUMBER) {
      setPhoneChecked(newChecked);
      if (newChecked) {
        setError((prevState) => ({
          ...prevState,
          phone: "",
        }));
      }
    }
    if (id === constant.PHONE_NUMBER && !newChecked) {
      setPhoneField(null);
    }
    setSubmitDisabled(false);
  }, []);

  const acceptOnlyValidInput = (value) => {
    return baseHelper.validateWholeNumber(value) && value;
  };

  const onSubmit = async () => {
    if (emailChecked) {
      await handleValidation("email", emailField);
    }

    if (emailChecked && !emailField) {
      return false;
    }

    try {
      const inputData = {};
      if (emailChecked && emailField) {
        inputData.email = emailField;
      }
      if (phoneChecked && phoneField) {
        inputData.phoneNumber = phoneField;
      }

      inputData.isEmailChecked = emailChecked;
      inputData.isPhoneNumberChecked = phoneChecked;

      const res = await customer({
        variables: {
          input: inputData,
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.CUSTOMER);
      const resError = baseHelper.getResponseError(res.data, gql.CUSTOMER);
      if (resError) {
        setBannerStatus({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
      }
      if (resData) {
        setBannerStatus({
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("section.message.success"),
        });
      }
      setSubmitDisabled(true);
    } catch (exception) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
    return null;
  };

  useEffect(() => {
    const isAnyErrorValue = () => Object.values(error).some((err) => err);
    setIsAnyError(isAnyErrorValue());
  }, [error]);

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  if (isLoading || customerLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <div>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.title")} description={cms("section.description")}>
        <Card sectioned>
          <Checkbox
            id="email"
            label={cms("section.label.email")}
            helpText={cms("section.helpText.email")}
            checked={emailChecked}
            onChange={handleChange}
          />
          {emailChecked && (
            <div className="check-box">
              <TextField
                placeholder={cms("section.placeholder.email")}
                value={emailField}
                onChange={(value) => {
                  setEmailField(value);
                }}
                onBlur={() => handleValidation("email", emailField)}
                error={(error && error.email) || null}
              />
            </div>
          )}
          <Checkbox
            id="phoneNumber"
            label={cms("section.label.phone")}
            helpText={cms("section.helpText.phone")}
            checked={phoneChecked}
            onChange={handleChange}
          />
          {phoneChecked && (
            <div className="check-box">
              <TextField
                placeholder={cms("section.placeholder.phone")}
                value={phoneField}
                onChange={(value) => {
                  setPhoneField(acceptOnlyValidInput(value));
                }}
                onBlur={() => phoneField && handleValidation("phone", phoneField)}
                error={(error && error.phone) || null}
              />
            </div>
          )}
          <br />
          {lastUpdated && (
            <div className="manageCustomerButton">
              <PageActions
                primaryAction={{
                  content: cms("common.button.update"),
                  onAction: () => onSubmit(),
                  disabled: isAnyError || submitDisabled,
                  loading: customerUpdateLoading,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            </div>
          )}
        </Card>
        {!lastUpdated && (
          <PageActions
            primaryAction={{
              content: cms("common.button.submit"),
              onAction: () => onSubmit(),
              disabled: isAnyError || submitDisabled,
              loading: customerUpdateLoading,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
    </div>
  );
};

export default withFeature(withErrorBoundary(ManageCustomerDetails), { feature: constant.ORDER_CUSTOMER });
