import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import { SYNC_VENDOR_BIGCOMMERCE_PRODUCT } from "app/productLake/apollo/mutations";

// import props
import { syncBigcommerceProduct } from "app/product/modules/generic/propTypes";

const {
  gql: { SYNC_VENDOR_BIGCOMMERCE_PRODUCT: SYNC_VENDOR_BIGCOMMERCE_PRODUCT_GQL },
} = constant;

const SyncBigcommerceProduct = (props) => {
  const { history, setBanner, cms, store } = props;
  const { CRITICAL } = constant;
  const [syncVendorBigcommerceProduct, { loading: syncInventoryVendorLoading }] = useMutation(
    SYNC_VENDOR_BIGCOMMERCE_PRODUCT
  );
  const SyncBigcommerceProducts = async () => {
    // eslint-disable-next-line react/destructuring-assignment

    try {
      // eslint-disable-next-line react/destructuring-assignment

      const response = await syncVendorBigcommerceProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_VENDOR_BIGCOMMERCE_PRODUCT_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/product-lake/bigcommerce");
      const bannerAction = {
        onAction,
        content: cms("section.connect.button.viewBigcommerceProduct"),
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("section.connect.message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button
      loading={syncInventoryVendorLoading}
      onClick={(value) => {
        SyncBigcommerceProducts(value);
      }}
    >
      {cms("section.connect.button.importBigcommerceProduct")}
    </Button>
  );
};

SyncBigcommerceProduct.propTypes = syncBigcommerceProduct.type;
SyncBigcommerceProduct.defaultProps = syncBigcommerceProduct.default;

export default SyncBigcommerceProduct;
