// import packages
import React, { useState, useEffect, useContext } from "react";
import { Layout, Page } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// import helpers components
import { Banner, Spinner } from "lib/components";
import { NotFound } from "layout/private/components";
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import gql
import { GET_FULFILLMENT } from "app/setup/apollo";
import { INVOICE_DATA, GET_INVOICE_SETTING } from "app/payments/apollo/queries";

// import sub Feature
import OrderSlipView from "app/payments/modules/generic/view/view";
import OrderTaxInvoiceView from "app/payments/modules/generic/view/orderTaxInvoiceView";

import { getOrderView } from "app/orders/apollo/viewOrder";

const InvoiceDownload = () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });

  const defaultLogo = constant.MARKET_CUBE;

  const { gql } = constant;
  const { match, currentUser } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const { introductorySeller = "", _id } = currentUser;
  const currentUserId = isVendor ? introductorySeller : _id;
  const { params } = match;
  const { id } = params;
  const orderId = id;
  const { lineItemId } = params;
  const selectedlineItemIds = JSON.parse(lineItemId);

  const [sellerInfo, setSellerInfo] = useState(false);
  const [vendorInfo, setVendorInfo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isInvoiceFound, setIsInvoiceFound] = useState(true);
  const [isInvoiceEnabled, setInvoiceEnabled] = useState(true);
  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState();
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [fulfillmentItem, setFulfillmentItems] = useState({});
  const [viewOrderData, setViewOrderData] = useState({});

  const { error: queryDataError, loading: queryDataLoading, data: queryData } = useQuery(INVOICE_DATA, {
    variables: { input: { orderId, lineItemId: selectedlineItemIds } },
  });

  const { data: fulfillmentData } = useQuery(GET_FULFILLMENT, {
    variables: {
      input: { ownerId: currentUserId },
    },
  });

  const { error: viewOrderDetailsError, loading: viewOrderDetailsLoading, data: viewOrderDetailsData } = useQuery(
    getOrderView,
    {
      variables: { input: { id } },
    }
  );

  const imageToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const { error: errorGetInvoiceAccess, data: dataGetInvoiceAccess } = useQuery(GET_INVOICE_SETTING);

  const invoiceAccessResponseData = baseHelper.getResponseData(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);
  const invoiceAccessErrorData = baseHelper.getResponseError(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);
  const { payment } = invoiceAccessResponseData || {};

  useEffect(() => {
    if (payment) {
      const { invoice } = payment;
      if (invoice) {
        const { access: invoiceAccess } = invoice;
        if (invoiceAccess) {
          const { isAllVendor: disabledForAllVendors, selectedVendor = [] } = invoiceAccess;
          setInvoiceEnabled(!isVendor || !(disabledForAllVendors || selectedVendor.includes(_id)));
        }
      }
    }
  }, [payment, isVendor, _id]);
  useEffect(() => {
    if (invoiceAccessErrorData) {
      setBanner({ status: constant.CRITICAL, title: invoiceAccessErrorData, isOpen: true });
    }
    if (errorGetInvoiceAccess) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetInvoiceAccess), isOpen: true });
    }
  }, [setBanner, invoiceAccessErrorData, errorGetInvoiceAccess]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(queryData, gql.GET_INVOICE_DATA);
    const fulfillmentResponseData = baseHelper.getResponseData(fulfillmentData, constant.gql.GET_FULFILLMENT) || {};
    const responseError = baseHelper.getResponseError(queryData, gql.GET_INVOICE_DATA);
    const viewOrderSlipResponseData = baseHelper.getResponseData(viewOrderDetailsData, constant.gql.GET_ORDER_VIEW);
    if (responseData) {
      setIsInvoiceFound(true);
      const { seller, vendor, order, orderLine, invoiceNumber } = responseData;
      if (seller && seller.plan && seller.plan.isBranding) {
        imageToDataURL(seller.logo, (url) => {
          setFulfillmentItems(fulfillmentResponseData);
          setOrderData(order);
          setOrderLineItems(orderLine);
          setSellerInfo(seller);
          setVendorInfo(vendor);
          setInvoiceNumberPrefix(invoiceNumber);
          setLogo(seller.logo && url);
        });
      } else {
        imageToDataURL(defaultLogo, (url) => {
          setFulfillmentItems(fulfillmentResponseData);
          setOrderData(order);
          setOrderLineItems(orderLine);
          setSellerInfo(seller);
          setVendorInfo(vendor);
          setInvoiceNumberPrefix(invoiceNumber);
          setLogo(url);
        });
      }
    }
    if (viewOrderSlipResponseData) {
      setViewOrderData(viewOrderSlipResponseData);
    }
    if (responseError) {
      setIsInvoiceFound(false);
      setNotFound(true);
      setBanner({ isOpen: true, title: responseError, status: constant.CRITICAL });
    }
  }, [defaultLogo, queryData, gql.GET_INVOICE_DATA, fulfillmentData, viewOrderDetailsData]);

  useEffect(() => {
    if (queryDataError) {
      setIsInvoiceFound(false);
      setBanner({ isOpen: true, title: errorHelper.parse(queryDataError), status: constant.CRITICAL });
    }
  }, [queryDataError]);

  if (notFound || !isInvoiceEnabled) {
    return (
      <div className="Polaris-Page__OrderSlip">
        <NotFound />
      </div>
    );
  }

  if (queryDataLoading || (isInvoiceFound && !sellerInfo)) {
    return <Spinner isFullPage />;
  }

  return (
    <div className="Polaris-Page__Invoice">
      <Page>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              message={banner.message}
              onDismiss={() => {
                setBanner({ isOpen: false, title: "", status: "" });
              }}
            />
          </Layout.Section>
        )}
      </Page>
      {isInvoiceFound &&
        sellerInfo &&
        (!viewOrderData?.isOrderBasedCommission ? (
          <OrderSlipView
            sellerInfo={sellerInfo}
            orderData={orderData}
            logo={logo}
            vendorInfo={vendorInfo}
            orderLineItems={orderLineItems}
            invoiceNumberPrefix={invoiceNumberPrefix}
            fulfillment={fulfillmentItem}
            viewOrderData={viewOrderData}
          />
        ) : (
          <OrderTaxInvoiceView
            sellerInfo={sellerInfo}
            orderData={orderData}
            logo={logo}
            vendorInfo={vendorInfo}
            orderLineItems={orderLineItems}
            invoiceNumberPrefix={invoiceNumberPrefix}
            fulfillment={fulfillmentItem}
            viewOrderData={viewOrderData}
          />
        ))}
    </div>
  );
};

export default InvoiceDownload;
