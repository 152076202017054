import { gql } from "apollo-boost";

const GET_INVOICE_SETTING = gql`
  query {
    getInvoiceSetting {
      data {
        payment {
          invoice {
            access {
              createdAt
              isAllVendor
              isEnabled
              selectedVendor
              updatedAt
            }
            manage {
              purchaseFrom {
                label
                isHide
              }
              deliverTo {
                label
                isHide
              }
              product {
                label
                isHide
              }
              sku {
                label
                isHide
              }
              quantity {
                label
                isHide
              }
              price {
                label
                isHide
              }
              tax {
                label
                isHide
              }
              discount {
                label
                isHide
              }
              subtotal {
                label
                isHide
              }
              shipping {
                label
                isHide
              }
              sellerCommission {
                label
                isHide
              }
              totalDeduction {
                label
                isHide
              }
              orderTotal {
                label
                isHide
              }
              orderPayout {
                label
                isHide
              }
              totalVendorPayout {
                label
                isHide
              }
              totalOrderTax {
                label
                isHide
              }
              totalAmount {
                label
                isHide
              }
              totalOrderShipping {
                label
                isHide
              }
              totalDeductionTax {
                label
                isHide
              }
              totalDeductionAmount {
                label
                isHide
              }
              totalOrderAmount {
                label
                isHide
              }
              fulfillBy {
                label
                isHide
              }
              updatedAt
              createdAt
            }
            note {
              value
              updatedAt
            }
            prefix {
              value
              updatedAt
            }
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_INVOICE_SETTING;
