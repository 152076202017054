import React, { useContext } from "react";
import { Card, TextStyle } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { customerProp } from "app/orders/modules/operator/features/view/propsType";

const Customer = (props) => {
  const { data } = props;
  const { cms } = useContext(PrivateContext);
  const { billingAddress, customer, email, shippingAddress, phone } = data || {};
  const { default_address: defaultAddress, email: customerEmail, phone: customerPhone } = customer || {};
  const {
    address1,
    address2,
    city,
    company,
    country,
    first_name: firstName,
    last_name: lastName,
    phone: shippingPhone,
    province,
    zip,
  } = shippingAddress || {};
  const {
    address1: billingAddress1,
    address2: billingAddress2,
    city: billingCity,
    company: billingCompany,
    country: billingCountry,
    first_name: billingFirstName,
    last_name: billingLastName,
    phone: billingPhone,
    province: billingProvince,
    zip: billingZip,
  } = billingAddress || {};
  return (
    <Card title={cms("label.customer")}>
      <Card.Section>
        <TextStyle variation={!(customer && defaultAddress && defaultAddress.name) && constant.SUBDUED}>
          <div className="wrap_text">
            {(customer && defaultAddress && defaultAddress.name) || cms("label.noCustomer")}
          </div>
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.contactInformation")}>
        <TextStyle variation={!(customerEmail || email) && constant.SUBDUED}>
          <p className="wrap_text">{customerEmail || email || cms("label.noEmailAddress")}</p>
        </TextStyle>
        <TextStyle variation={!(customerPhone || phone) && constant.SUBDUED}>
          <p className="wrap_text">{customerPhone || phone || cms("label.noPhoneNumber")}</p>
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.shippingAddress").toUpperCase()}>
        {shippingAddress ? (
          <TextStyle>
            <div className="wrap_address">
              {`${(shippingAddress && firstName) || ""} 
              ${(shippingAddress && lastName) || ""}`}
            </div>
            <div className="wrap_address">{`${(shippingAddress && company) || ""}`}</div>
            <div className="wrap_address">
              {`${(shippingAddress && address1) || ""} 
              ${(shippingAddress && address2) || ""}`}
            </div>
            <div className="wrap_address">
              {`${(shippingAddress && zip) || ""} 
              ${(shippingAddress && city) || ""} 
              ${(shippingAddress && province) || ""}`}
            </div>
            <p className="wrap_text">{shippingAddress && country}</p>
            <p className="wrap_text">{shippingAddress && shippingPhone}</p>
          </TextStyle>
        ) : (
          <TextStyle variation={constant.SUBDUED}>{cms("label.noShippingAddress")}</TextStyle>
        )}
      </Card.Section>
      <Card.Section title={cms("label.billingAddress")}>
        <TextStyle>
          {billingAddress ? (
            <TextStyle>
              <div className="wrap_address">
                {`${(billingAddress && billingFirstName) || ""} 
                ${(billingAddress && billingLastName) || ""}`}
              </div>
              <div className="wrap_address">{`${(billingAddress && billingCompany) || ""}`}</div>
              <div className="wrap_address">
                {`${(billingAddress && billingAddress1) || ""} 
                ${(billingAddress && billingAddress2) || ""}`}
              </div>
              <div className="wrap_address">
                {`${(billingAddress && billingZip) || ""} 
                ${(billingAddress && billingCity) || ""} 
                ${(billingAddress && billingProvince) || ""}`}
              </div>
              <p className="wrap_text">{billingAddress && billingCountry}</p>
              <p className="wrap_text">{billingAddress && billingPhone}</p>
            </TextStyle>
          ) : (
            <TextStyle variation={constant.SUBDUED}>{cms("label.noBillingAddress")}</TextStyle>
          )}
        </TextStyle>
      </Card.Section>
    </Card>
  );
};
Customer.propTypes = customerProp.type;
export default Customer;
