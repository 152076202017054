import engine from "store/src/store-engine";
import cookieStorage from "store/storages/cookieStorage";
import localStorage from "store/storages/localStorage";
import defaultPlugins from "store/plugins/defaults";
import constant from "lib/constant/constant";
import { accessHelper } from "lib/helpers";

const store = engine.createStore([localStorage], defaultPlugins);
const storeCookie = engine.createStore([cookieStorage], defaultPlugins);

class StorageHelper {
  get = (key) => {
    if (accessHelper.isIncognito()) {
      return "";
    }
    return store.get(key);
  }

  getAll = () => {
    const storage = {};
    store.each((value, key) => {
      storage[key] = value;
    });
    return storage;
  };

  remove = (key) => store.remove(key);

  removeAll = () => store.clearAll();

  set = (key, value) => {
    if (accessHelper.isIncognito()) {
      return false;
    }
    return store.set(key, value);
  }

  // For Cookies
  removeAllCookie = () => {
    if (document.cookie) {
      storeCookie.clearAll();
    }
  };

  setCookie = (key, value, SameSite = constant.none, Secure) => storeCookie.set(key, value, SameSite, Secure);
}

export default new StorageHelper();
