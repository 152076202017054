import React from "react";
import { Banner, Link } from "@shopify/polaris";
import constant from "lib/constant/constant";

import { withErrorBoundary } from "lib/hoc";

const PermissionBanner = () => {
  return (
    <Banner isOpen status="critical">
            <p> <strong> Attention Shopify Store Owners! </strong> <br />
            We apologize for any inconvenience caused by a recent update in the Shopify API. To ensure seamless functioning of our app,
            we kindly request your attention regarding a permissions update. Please review the permissions for our app in your Shopify store and enable the <strong> merchant_managed_fulfillment_orders </strong>
                   permission. This optimizes your order fulfillment process, delivering a smoother experience for you and your customers.
                   <br /><br />To confirm or adjust permissions <br />
                      1. Login to your Shopify store admin panel, go to the Apps section, select <strong> APP NAME </strong>, and click on the <strong> Configuration </strong> tab. <br />
                      2. Verify if the <strong> merchant_managed_fulfillment_orders </strong> for both <strong> read & write </strong> permission is checked. If enabled, click <strong> Save </strong> in the banner. If not, please grant the permission.
                   <br /><br />We apologize for any inconvenience and appreciate your cooperation. Contact our support team for assistance. Thank you for your attention and understanding.</p><br />
                   <strong>Note: Once you have successfully granted the mentioned permission, the banner will automatically disappear from the dashboard. </strong>
                   <br />
                   <Link external url={constant.PERMISSION_LINK}>
                  Link for video
                 </Link>
    </Banner>
);
};
export default withErrorBoundary(PermissionBanner);
