import React, { useContext, useState } from "react";
import {
  Badge,
  Caption,
  Card,
  Link,
  TextStyle,
  Thumbnail,
  Stack,
  Icon,
  Button,
  ButtonGroup,
  // FormLayout,
  Checkbox,
} from "@shopify/polaris";
import { NoteMajor, QuickSaleMajor, ReturnMinor } from "@shopify/polaris-icons";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";
import Popover from "app/orders/modules/provider/features/view/features/popover";

const ReadyForCollect = (props) => {
  const {
    checked,
    collectCount,
    itemData,
    onChange,
    openClickReserveModal,
    packingSlipAccessData,
    refundData,
    selectedItems,
    setSelectedItems,
    setSelectedReserveItemData,
    selectedReserveItemData,
    setIsBulkReserveChecked,
    isBulkReserveChecked,
    setBulkQuantity,
    bulkQuantity,
    setActiveReserveCollect,
    getSlipName,
    setIsSingleOperation,
  } = props;

  const { cms, history, match, userData } = useContext(PrivateContext);

  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const [activePopover, setActivePopover] = useState({});

  const handleChange = (value) => {
    const {
      _id: id,
      collectDetail = {},
      actualQuantity = 0,
      fulfillableQuantity = 0,
      fulfillmentStatus,
      isRefund = false,
      quantity = 0,
      shopifyLineItemId,
    } = value;

    if ([constant.UNFULFILLED, constant.PARTIAL].includes(fulfillmentStatus) && collectDetail) {
      let { readyQuantity = 0 } = collectDetail;
      isRefund &&
        refundData &&
        refundData
          .filter((item) => item.line_item_id === shopifyLineItemId)
          .forEach((refundItem) => {
            const { totalQuantity = 0 } = refundItem;
            readyQuantity -= totalQuantity;
          });
      const newQuantity = parseInt(actualQuantity || quantity, 10) - parseInt(fulfillableQuantity, 10);
      const readyCollectQuantity = parseInt(readyQuantity, 10) - parseInt(newQuantity, 10);
      const found = selectedItems.find((element) => element === id);
      if (found) {
        setSelectedItems(
          selectedItems.filter((item) => {
            return item !== id;
          })
        );
        setSelectedReserveItemData(selectedReserveItemData.filter((item) => item._id !== id));
        setBulkQuantity(bulkQuantity.filter((item) => item.id !== id));
      } else {
        const arrReserveItem = selectedReserveItemData.filter((item) => item._id !== value._id);
        setSelectedItems([...selectedItems, id]);
        setSelectedReserveItemData([...arrReserveItem, value]);
        const arrQuantity = bulkQuantity.filter((item) => item.id !== id);
        setBulkQuantity([
          ...arrQuantity,
          {
            id: value._id,
            quantity: (readyCollectQuantity && readyCollectQuantity) || "",
            totalQuantity: (readyCollectQuantity && readyCollectQuantity.toString()) || "",
          },
        ]);
      }
    }
  };

  const amount = [];
  const vendorsSum = [];
  const countItems = 0;

  itemData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const lineDataItems = itemData.filter(
    (lineItem) =>
      lineItem.fulfillmentStatus !== constant.FULFILLED &&
      [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(lineItem.fulfillmentType) &&
      lineItem.collectDetail &&
      (!lineItem.isRefund
        ? parseInt(lineItem.actualQuantity || lineItem.quantity, 10) -
            parseInt(lineItem.collectDetail.readyQuantity, 10) <
          parseInt(lineItem.fulfillableQuantity, 10)
        : !(
            parseInt(lineItem.quantity, 10) - parseInt(lineItem.fulfillableQuantity, 10) ===
            parseInt(lineItem.collectDetail.readyQuantity, 10)
          ))
  ).length;

  const isVendor = baseHelper.isVendor(currentUserData);

  const getBadgeStatus = (readyQuantity, totalQuantity, fulfillmentType) => {
    if (readyQuantity < totalQuantity) {
      return fulfillmentType === constant.SERVICE ? cms("label.readyService") : cms("label.partialReady");
    }
    return fulfillmentType === constant.SERVICE ? cms("label.readyService") : cms("label.readyForCollection");
  };

  const lineItems =
    itemData &&
    itemData.length &&
    itemData
      .filter(
        (item) =>
          (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(item.fulfillmentType) &&
          item.collectDetail &&
          (!item.isRefund
            ? parseInt(item.actualQuantity || item.quantity, 10) - parseInt(item.collectDetail.readyQuantity, 10) <
              parseInt(item.fulfillableQuantity, 10)
            : !(
                parseInt(item.quantity, 10) - parseInt(item.fulfillableQuantity, 10) ===
                parseInt(item.collectDetail.readyQuantity, 10)
              ))
      )
      .map((lineData, idx) => {
        const {
          _id: id,
          actualQuantity = 0,
          collectDetail = {},
          fulfillableQuantity = 0,
          image = "",
          isProductDeleted,
          isRefund = false,
          isVendorDeleted,
          moneyFormat: lineMoneyFormat,
          name,
          price,
          productId,
          properties,
          quantity: totalQuantity,
          sku,
          shopifyLineItemId,
          status,
          title,
          vendor,
          vendorId,
          fulfillmentType,
        } = lineData || {};
        let { readyQuantity = 0 } = collectDetail || {};
        isRefund &&
          refundData &&
          refundData
            .filter((item) => item.line_item_id === shopifyLineItemId)
            .forEach((refundItem) => {
              const { totalQuantity = 0 } = refundItem;
              readyQuantity -= totalQuantity;
            });
        const lineItemKey = `lineItem${idx}`;
        const lineImage =
          imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

        const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;

        const lineItemName = <div className="productName">{name}</div>;

        const newQuantity = parseInt(actualQuantity || totalQuantity) - parseInt(fulfillableQuantity);
        const readyCollectQuantity = parseInt(readyQuantity) - parseInt(newQuantity);

        if (!readyCollectQuantity) {
          return null;
        }

        const totalPrice = price * readyCollectQuantity;
        const isServiceLineItem = baseHelper.isServiceLineItem(fulfillmentType, properties);
        const productURL = isServiceLineItem ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;

        amount.push(totalPrice);

        return (
          <div key={lineItemKey} className="fulfilled-Secn">
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  {/* <Stack wrap={false}>
                    <Stack.Item>
                      <Checkbox label={` Showing ${collectCount} items`} checked={checked} onChange={onChange} />
                    </Stack.Item>
                  </Stack>
                  <br /> */}
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Checkbox
                        id={id}
                        checked={isBulkReserveChecked && selectedItems.find((element) => element === id)}
                        onChange={() => [handleChange(lineData), setIsBulkReserveChecked(true)]}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      <div className="ellipsis">
                        {(isProductDeleted && (lineItemName || "")) || (
                          <Link onClick={() => history.push(productURL)}>{name || ""}</Link>
                        )}

                        <Caption>
                          <TextStyle variation={constant.SUBDUED}>
                            {/* {vendor && vendorId
                              ? isVendor || isVendorDeleted
                                ? [`${cms("label.vendor")}: `, vendor, <br />]
                                : [
                                    `${cms("label.vendor")}: `,
                                    <Link onClick={() => history.push(constant.PROVIDER_VIEW + vendorId)}>
                                      {vendor || ""}
                                    </Link>,
                                    <br />,
                                  ]
                              : ""} */}
                            {vendor ? `${cms("label.vendor")}: ${vendor}` : ""}
                          </TextStyle>
                          <LineItemProperties
                            variation={constant.SUBDUED}
                            properties={properties}
                            isServiceLineItem={isServiceLineItem}
                          />
                          {/* {sku && ( */}
                          {isServiceLineItem ? (
                            ""
                          ) : (
                            <TextStyle variation={constant.SUBDUED}>
                              {cms("label.skuList")}
                              {sku}
                              {" "}
                            </TextStyle>
                          )}
                          {/* )} */}
                        </Caption>
                        <Badge status={baseHelper.getBadgeType(status)} className="text-capitalize">
                          {/* {baseHelper.getBadgeStatus(status)}  */}
                          {getBadgeStatus(readyQuantity, totalQuantity, fulfillmentType)}
                          {/* {readyQuantity < totalQuantity ? cms("label.partialReady") : cms("label.readyForCollection")} */}
                        </Badge>
                        <Stack alignment="center">
                          <Stack.Item>
                            <ButtonGroup>
                              <Stack.Item>
                                <Popover
                                  active={activePopover[id]}
                                  setActive={() => {
                                    // setLineLoading([itemId]);
                                    setActivePopover({ [id]: !activePopover[id] });
                                  }}
                                  item={[
                                    // {
                                    //   content: "Mark ready for collection",
                                    //   onAction: () =>
                                    //     openClickReserveModal(lineData, "reserve", lineData.sellerId, false),
                                    //   icon: QuickSaleMajor,
                                    //   disabled: readyQuantity >= fulfillableQuantity,
                                    // },
                                    {
                                      content:
                                        fulfillmentType === constant.SERVICE
                                          ? cms("button.confirmService")
                                          : cms("button.confirm"),
                                      onAction: () => [
                                        handleChange(lineData),
                                        setIsBulkReserveChecked(false),
                                        setActiveReserveCollect(true),
                                        setIsSingleOperation(true),
                                      ],
                                      icon: ReturnMinor,
                                    },
                                  ]}
                                />
                              </Stack.Item>
                              <Stack alignment="center">
                                {[constant.ACCEPTED].includes(status) &&
                                  (packingSlipAccessData.isEnabled ||
                                    !(
                                      packingSlipAccessData.isAllVendor ||
                                      (packingSlipAccessData.selectedVendor &&
                                        packingSlipAccessData.selectedVendor.includes(vendorId))
                                    )) && (
                                    <Button
                                      plain
                                      size="slim"
                                      onClick={() => window.open(`/order-slip/${match.params.id}`)}
                                    >
                                      <Stack spacing="extraTight" alignment="center">
                                        <Stack.Item>
                                          <Icon source={NoteMajor} />
                                        </Stack.Item>
                                        <Stack.Item>{getSlipName(fulfillmentType)}</Stack.Item>
                                      </Stack>
                                    </Button>
                                  )}
                              </Stack>
                            </ButtonGroup>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${readyCollectQuantity}`}</Stack.Item>
                    <Stack.Item>
                      {moneyFormat}
                      {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
                    </Stack.Item>
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        );
      });

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const moneyFormat = (itemData && itemData.length && itemData[0].moneyFormat) || constant.symbol.DOLLAR;

  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            (
            {lineDataItems > 1
              ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">{`${moneyFormat} ${baseHelper.getPrice(totalAmount)}`}</TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );

  return [lineItems, totalFulfill];
};

export default ReadyForCollect;
