import { gql } from "apollo-boost";

const DIGEST_EMAIL_SETTING = gql`
  mutation digestSetting($input: DigestSettingData) {
    digestSetting(input: $input) {
      status
      data
      error
    }
  }
`;

export default DIGEST_EMAIL_SETTING;
