import { gql } from "apollo-boost";

const GET_VENDOR_METRICS = gql`
  query getVendorMetrics($input: GetVendorMetrics) {
    getVendorMetrics(input: $input) {
      data {
        vendor {
          month
          count
          revenue
        }
      }
      error
      status
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export { GET_VENDOR_METRICS };
