import React, { useContext } from "react";

import { Caption, Card, Stack } from "@shopify/polaris";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { fullfillmentStatusProp } from "app/orderLake/modules/operator/features/view/propsType";

const FulfillmentStatus = (props) => {
  const { cms, userData, currentUser } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const { data, lineData } = props;
  const isRejected =
    lineData &&
    lineData.every((item) => item.storeStatus === constant.CANCELLED || item.storeStatus === constant.REFUND);

  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);
  const isSeller = baseHelper.isSeller(currentUserData);
  const isTaxIncluded = !!data.isPricesIncludeTax;

  const rejectedData = {
    commissionSeller: 0,
    commissionVendor: 0,
    discount: 0,
    isUpdatedOrderLine: true,
    markUp: 0,
    markUpItems: 0,
    price: 0,
    sellerDiscount: 0,
    shippingAmount: 0,
    tax: 0,
    totalTaxRate: 0,
    totalPrice: 0,
  };
  const totals = isRejected ? rejectedData : baseHelper.getOrderLakePrice(lineData, data, isSeller);
  const { isUpdatedOrderLine, price = 0, shippingAmount = 0 } = totals || {};

  const subTotal = parseFloat(price);
  const updatedLineSubTotal = parseFloat(data.totalLineItemsPrice);

  const { code: discountLineCode = "", amount } =
    (data.discountCodes && data.discountCodes.length && data.discountCodes[0]) || {};
  const lineDataItems = lineData.filter(
    (lineItem) =>
      !(
        lineItem.status === constant.REJECTED &&
        (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.REFUND)
      )
  ).length;
  const items = `${lineDataItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${lineDataItems}`;

  const { title: shippingLineTitle = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const { price: shippingLinePrice = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const vendorsSum = [];
  lineData.filter(({ providerId }) => {
    vendorsSum.push(providerId);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const totalPrice = baseHelper.getPrice(subTotal) + totals.shippingAmount;
  return (
    <Card>
      <Card.Section subdued>
        <Card.Subsection>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>{`${cms("label.subTotal")}`}</Stack.Item>
              <Stack.Item>{items}</Stack.Item>
              <Stack.Item>
                {`${moneyFormat}`}
                {!isUpdatedOrderLine ? baseHelper.getPrice(subTotal) : baseHelper.getPrice(updatedLineSubTotal)}
              </Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>
                <div className="ellipsis">
                  {cms("label.shipping")}
                  {`${shippingLineTitle ? `(${shippingLineTitle})` : ""}`}
                </div>
              </Stack.Item>
              <Stack.Item>
                {`${moneyFormat}`}
                {`${
                  shippingLinePrice
                    ? baseHelper.getPrice(shippingLinePrice)
                    : baseHelper.getPrice(totals.shippingAmount)
                }`}
              </Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>
                {cms("label.discount")}
                {discountLineCode ? `(${discountLineCode})` : ""}
              </Stack.Item>
              <Stack.Item>
                {`${moneyFormat}-`}
                {amount ||
                  (isUpdatedOrderLine &&
                    (baseHelper.getPrice(totals.sellerDiscount) || baseHelper.getPrice(totals.discount))) ||
                  (lineData.totalDiscount && baseHelper.getPrice(lineData.totalDiscount)) ||
                  constant.value.FLOAT_ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>
                {cms("label.salesTax")}
                {isTaxIncluded && ` ${cms("label.included")}`}
              </Stack.Item>
              <Stack.Item>
                {`${moneyFormat}`}
                {isSeller && isUpdatedOrderLine ? baseHelper.getPrice(totals.taxRate) : data.totalTax || 0}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card.Subsection>
      </Card.Section>
      <Card.Section>
        <Stack vertical>
          <Stack wrap={false} alignment={constant.CENTER}>
            <Stack.Item fill>
              <b>{cms("label.total")}</b>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {lineDataItems > 1
                  ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <b>
                {`${moneyFormat}`}
                {shippingAmount
                  ? baseHelper.getPrice(parseFloat(price) + parseFloat(shippingAmount))
                  : baseHelper.getPrice(parseFloat(price))}
              </b>
            </Stack.Item>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
};

FulfillmentStatus.propTypes = fullfillmentStatusProp.type;
export default FulfillmentStatus;
