// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_ORDER_LIST = gql`
  query GET_ORDER_LIST($input: GetListInputV1) {
    getOrderList(input: $input) {
      data {
        count
        orderList {
          _id
          createdAt
          financialStatus
          fulfillmentStatus
          moneyFormat
          orderLines {
            _id
            actualQuantity
            name
            productId
            quantity
            shopifyLineItemId
            isReturn
            isDelivered
            isProductDeleted
            fulfillmentType
            fulfillmentStatus
            quantity
            rejectedByVendor
            status
            return {
              status
            }
            returnedQuantity
          }
          orderNumber
          rejectedByVendor
          shopifyOrderDate
          status
          totalPrice
          updatedAt
          vendorCount
        }
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LIST;
