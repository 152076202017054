// import packages
import React, { useContext, useEffect, useState } from "react";
import { Caption, Card, Layout, RadioButton, Stack, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { withErrorBoundary, withFeature } from "lib/hoc";

import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import gql
import { UPDATE_PHYSICAL_SETTING } from "app/productOld/apollo/mutations";
import { GET_PRODUCT_PHYSICAL_SETTING } from "app/productOld/apollo/queries";

// import data
import physicalData from "app/productOld/modules/operator/features/setting/cms/physicalData";

const Physical = (props) => {
  const { lastUpdated } = props;
  const [updateProductPhysicalSetting, { loading: productSettingLoading }] = useMutation(UPDATE_PHYSICAL_SETTING);
  const { cms } = useContext(PrivateContext);
  const { data: productSettingData, loading: getProductSettingLoading, refetch } = useQuery(
    GET_PRODUCT_PHYSICAL_SETTING
  );
  const { gql, NON_PHYSICAL, PHYSICAL } = constant;
  const [buttonValue, setButtonValue] = useState(true);
  const [message, setMessage] = useState("");
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const physicalDataSetting = physicalData(cms);

  const handleChange = (checked, newValue) => {
    setRadioButtonValue(newValue);
    setButtonValue(false);
  };

  const onSubmit = () => {
    setButtonValue(true);
    if (radioButtonValue === PHYSICAL) {
      setRadioButtonValue(PHYSICAL);
    } else {
      setRadioButtonValue(NON_PHYSICAL);
    }

    const isNonPhysical = radioButtonValue === NON_PHYSICAL;
    updateProductPhysicalSetting({
      variables: {
        input: {
          isDefaultNonPhysical: isNonPhysical,
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PHYSICAL_SETTING);
        refetch();
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PHYSICAL_SETTING);
          setBanner({ status: constant.CRITICAL, title: errorResponse, isOpen: true });
        }
        if (responseData) {
          if (isNonPhysical) {
            setMessage(cms("operator.section.shipping.message.nonPhysical"));
          } else {
            setMessage(cms("operator.section.shipping.message.physical"));
          }
        }
      })
      .catch((exception) => {
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  const renderBanner = () => {
    const { status, title, isOpen } = banner;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
    );
  };

  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingData, gql.GET_PRODUCT_PHYSICAL_SETTING);
    if (!responseData) {
      return;
    }
    const { isDefaultNonPhysical, updatedAt = "" } = responseData;
    setUpdatedAt(updatedAt);

    if (!isDefaultNonPhysical) {
      setRadioButtonValue(PHYSICAL);
      return;
    }
    if (isDefaultNonPhysical) {
      setRadioButtonValue(NON_PHYSICAL);
    }
  }, [NON_PHYSICAL, PHYSICAL, gql.GET_PRODUCT_PHYSICAL_SETTING, productSettingData]);

  if (getProductSettingLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection
        title={cms("operator.section.shipping.title")}
        description={cms("operator.section.shipping.description")}
      >
        <Card
          title={[
            cms("operator.section.shipping.heading"),
            <TextStyle variation="subdued">
              {updatedAt && <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>}
            </TextStyle>,
          ]}
          sectioned
          primaryFooterAction={{
            id: "submitPhysicalNonPhysical",
            content: cms("common.button.update"),
            disabled: productSettingLoading || buttonValue,
            loading: productSettingLoading,
            onAction: () => onSubmit(),
          }}
        >
          <Stack vertical>
            {physicalDataSetting.map((item) => {
              const { label: labelItem, helpText, key, name } = item;
              return (
                <RadioButton
                  id={key}
                  key={key}
                  label={labelItem}
                  helpText={helpText}
                  name={name}
                  checked={radioButtonValue === key}
                  onChange={handleChange}
                />
              );
            })}
          </Stack>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
      <br />
    </>
  );
};

export default withFeature(withErrorBoundary(Physical), {
  feature: constant.PRODUCT_PHYSICAL_SETTING,
  // subFeature: constant.PRODUCT_PHYSICAL_SETTING,
});
