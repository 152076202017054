import { gql } from "apollo-boost";

const UPDATE_ORDER_NOTE = gql`
  mutation updateOrderNote($input: UpdateOrderNote) {
    updateOrderNote(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_ORDER_NOTE;
