import React from "react";
import { Page, EmptyState } from "@shopify/polaris";

const AppUnderMaintenance = () => {
  return (
    <Page>
      <EmptyState
        heading="We will be back soon!"
        action={{
          content: "Check",
          onAction: () => window.location.reload(),
        }}
      >
        <p>
          Sorry for the inconvenience but we are performing some upgrades to the app specially for you, we will be back
          online shortly! Please click below to check if we are back.
        </p>
      </EmptyState>
    </Page>
  );
};

export default AppUnderMaintenance;
