import React from "react";
import { Card, SkeletonThumbnail, SkeletonBodyText, Stack } from "@shopify/polaris";

const SkeletonList = () => {
  return (
    <>
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <SkeletonThumbnail size="large" />
          </Stack.Item>
          <Stack.Item fill>
            <br />
            <SkeletonBodyText />
          </Stack.Item>
        </Stack>
      </Card>
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <SkeletonThumbnail size="large" />
          </Stack.Item>
          <Stack.Item fill>
            <br />
            <SkeletonBodyText />
          </Stack.Item>
        </Stack>
      </Card>
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <SkeletonThumbnail size="large" />
          </Stack.Item>
          <Stack.Item fill>
            <br />
            <SkeletonBodyText />
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};

export default SkeletonList;
