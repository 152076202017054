import { gql } from "apollo-boost";

const DELETE_LAKE_VARIANT = gql`
  mutation deleteLakeVariant($input: DeleteLakeVariant) {
    deleteLakeVariant(input: $input) {
      data
      error
      status
    }
  }
`;

export default DELETE_LAKE_VARIANT;
