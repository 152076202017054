import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, TextContainer, Link } from "@shopify/polaris";
import config from "configuration";
import { Spinner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const PopupModal = (props) => {
  const { cms } = props;
  const { isLoading, currentUser } = useContext(PrivateContext);
  const isSeller = baseHelper.isSeller(currentUser);
  const isIframe = baseHelper.isIframe();

  const { shopifyAccessToken, shop } = currentUser;
  const [isOtp, setIsOtp] = useState(false);

  useEffect(() => {
    if (isSeller && shop && shopifyAccessToken) {
      // if (!isIframe && currentUser.isOtp && !currentUser.requestedBy) {
      //   setIsOtp(true);
      // }
      if (isIframe) {
        window.ShopifyApp.init({
          apiKey: config.shopifyAPIKey,
          shopOrigin: `https://${currentUser.shop}.myshopify.com`,
          debug: false,
          forceRedirect: true,
        });
      }
    }
  }, [currentUser.isOtp, currentUser.shop, currentUser.requestedBy, isIframe, isSeller, shop, shopifyAccessToken]);

  useEffect(() => {
    if (isIframe && isSeller && currentUser.shop && currentUser.shopifyAccessToken) {
      const title = currentUser.shop;
      window.ShopifyApp.Bar.initialize({ title });
    }
  }, [currentUser.shop, currentUser.shopifyAccessToken, isIframe, isSeller]);

  const sellerLogin = (e) => {
    e.preventDefault();
    if (isSeller && currentUser.isOtp) {
      window.location.href = `https://${currentUser.shop}.myshopify.com/admin/apps`;
      // window.ShopifyApp.init({
      //   apiKey: config.shopifyAPIKey,
      //   shopOrigin: `https://${currentUser.shop}.myshopify.com/admin/apps`,
      //   debug: false,
      //   forceRedirect: true,
      // });
    }
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Modal
        open={isOtp}
        title
        primaryAction={{
          content: cms("common.popup.button"),
          onAction: (e) => sellerLogin(e),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              {`${cms("common.popup.description.para1")} `}
              <Link url="https://www.marketcube.io">Marketcube.io</Link>
              {` ${cms("common.popup.description.para2")}`}
            </p>
            <p>{`${cms("common.popup.description.para3")}`}</p>
            <p>
              {`${cms("common.popup.description.para4")} `}
              {/* eslint-disable-next-line max-len */}
              <Link url="http://support.marketcube.io/faqs/vendors-cannot-login-as-they-keep-getting-redirected-to-marketcubeios-shopify-app-into-the-sellers-account">
                {` ${cms("common.popup.description.para5")} `}
              </Link>
              {` ${cms("common.popup.description.para6")}`}
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
PopupModal.propTypes = {
  cms: PropTypes.func,
};

PopupModal.defaultProps = {
  cms: {},
};

export default PopupModal;
