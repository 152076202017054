import { gql } from "apollo-boost";

const SYNC_ORDER_LAKE = gql`
  mutation {
    syncWooCommerceOrder {
      data
      status
      error
    }
  }
`;

export default SYNC_ORDER_LAKE;
