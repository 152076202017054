import React, { useContext, useState, useEffect } from "react";
import { Layout, Card, Collapsible, TextContainer, Caption, SettingToggle, TextStyle } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { GET_TRANSACTION_FEE } from "app/setup/apollo/queries";
import { UPDATE_TRANSACTION_FEE_SETTING } from "app/setup/apollo/mutations";

import { Toast, Banner } from "lib/components";
import TransactionTax from "./transactionTax";

const OperatorTransactionFeesSetting = () => {
  const { currentUser, cms = {} } = useContext(PrivateContext);
  // const { isStripeSubscription = false } = currentUser;
  const { _id: currentUserId } = currentUser;
  const {
    gql: { GET_TRANSACTION_FEE_TEXT, UPDATE_TRANSACTION_FEE_SETTING_TEXT },
  } = constant;
  const { data: queryData, networkStatus, refetch } = useQuery(GET_TRANSACTION_FEE, {
    variables: { input: { sellerId: currentUserId } },
    notifyOnNetworkStatusChange: true,
  });
  const [updatedAt, setUpdatedAt] = useState("");
  const [dbFeeList, setDbFeeList] = useState([]);
  const [loadButton, setLoadButton] = useState("");

  const [commissionStatus, setCommissionStatus] = useState(false);
  const [message, setMessage] = useState("");

  const [setTransactionTaxFee, { loading: updateTransactionFeeLoading }] = useMutation(UPDATE_TRANSACTION_FEE_SETTING);

  const [showHideAdvance, setShowHideAdvance] = useState(false);

  const contentStatus = showHideAdvance ? constant.DISABLE : constant.ENABLE;

  const [transactionTax, setTransactionTax] = useState({
    transactionTaxValues: [],
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  useEffect(() => {
    if (!queryData) {
      return;
    }
    const responseData = baseHelper.getResponseData(queryData, GET_TRANSACTION_FEE_TEXT);
    const responseError = baseHelper.getResponseError(queryData, GET_TRANSACTION_FEE_TEXT);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    if (!responseData.isOrderBasedCommission) {
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("section.message.info.orderCommission"),
      });
    }
    setCommissionStatus(responseData.isOrderBasedCommission);
    setUpdatedAt(responseData.updatedAt);
    setShowHideAdvance(responseData.isEnabled);
    if (responseData.labels && responseData?.labels !== null) {
      setTransactionTax([...responseData.labels]);
      // transactionTax?.transactionTaxValues && setTransactionTax([...responseData.labels]);
      setDbFeeList([...responseData.labels]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const updateTransactionTax = async (requestData) => {
    try {
      const res = await setTransactionTaxFee({
        variables: { input: requestData },
      });
      const responseData = baseHelper.getResponseData(res.data, UPDATE_TRANSACTION_FEE_SETTING_TEXT);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_TRANSACTION_FEE_SETTING_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });

        return;
      }
      setMessage(
        requestData.isEnabled ? cms("section.message.success.enabled") : cms("section.message.success.disabled")
      );
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const handleToggle = () => {
    setLoadButton("button");
    if (!commissionStatus) return;
    const requestData = {
      labels: [],
      isEnabled: !showHideAdvance,
    };
    updateTransactionTax(requestData);

    // setShowHideAdvance(!showHideAdvance);
  };

  return (
    <Collapsible open>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.labelSetting.title")}
        // eslint-disable-next-line max-len
        description={cms("section.labelSetting.description")}
      >
        <Card sectioned>
          <div className="toggle-action">
            <SettingToggle
              action={{
                content: commissionStatus ? contentStatus : constant.DISABLE,
                onAction: handleToggle,
                destructive: showHideAdvance,
                loading: loadButton === "button" ? networkStatus === 4 || updateTransactionFeeLoading : 0,
                disabled: !commissionStatus,
              }}
            >
              <TextStyle variation="strong">{cms("section.labelSetting.title")}</TextStyle>
              <TextStyle variation="subdued">
                {updatedAt && <Caption>{`Last updated at : On ${baseHelper.formatDate(updatedAt)}`}</Caption>}
              </TextStyle>
              <br />
              <TextContainer>{cms("section.labelSetting.card.subtitle")}</TextContainer>
            </SettingToggle>
            <Collapsible open={showHideAdvance} id="advancedCommission">
              <TransactionTax
                setTransactionTax={setTransactionTax}
                setTransactionTaxFee={setTransactionTaxFee}
                setLoadButton={setLoadButton}
                dbTransactionFeeList={dbFeeList.length}
                // updatedAt={updatedAt}
                transactionTax={transactionTax}
                refetchLoading={loadButton === "formButton" ? networkStatus === 4 || updateTransactionFeeLoading : 0}
                updatedAt={updatedAt}
                refetch={refetch}
              />
            </Collapsible>
          </div>
        </Card>
      </Layout.AnnotatedSection>
      <Toast message={message} timeout={5000} />
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(OperatorTransactionFeesSetting), { feature: constant.TRANSACTIONFEES });
