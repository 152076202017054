import { gql } from "apollo-boost";

const GET_AVAILABILITY = gql`
  query getService($input: ProductById!) {
    getService(input: $input) {
      error
      data {
        _id
        type
        capacity
        duration
        locationId
        hour {
          sun {
            open
            close
          }
          mon {
            close
            open
          }
          tue {
            close
            open
          }
          wed {
            close
            open
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_AVAILABILITY;
