import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, PageActions, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import constants
import constant from "lib/constant/constant";

// import contexts
import { PublicContext } from "lib/context";

// import helper components
import { Banner } from "lib/components";
import { Footer } from "layout/public/components";

// import gql
import { GET_BRANDS } from "app/public/apollo/queries";
import { RESEND_VERIFY_EMAIL } from "app/public/apollo/mutations";

// import yup validation
import validate from "app/public/modules/resetPassword/features/resetPassword/yup/validate";

import { AccountType, Brand } from "app/public/modules/resetPassword/features/resetPassword/subFeatureItems/index";

const ResendVerifyEmail = () => {
  const { cms, history } = useContext(PublicContext);
  const { ACCOUNT_TYPE, BRAND_NAME, CRITICAL, EMAIL, SHOW_ACCOUNT_TYPE, SHOW_BRAND_NAME, SUCCESS, gql } = constant;

  // states
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [values, setValues] = useState({
    email: "",
    isEmailDisable: false,
    isAccountTypeDisable: false,
    accountType: "",
    brandName: "",
  });
  const [showFields, setShowFields] = useState({
    showAccountType: false,
    showBrandName: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [userBrands, setUserBrands] = useState({
    seller: [],
    vendor: [],
  });
  const [isReadyToSubmit, setSubmitState] = useState(false);
  const [fetchNewAccount, setFetchNewAccount] = useState(true);

  // gql states
  const [getAccount, { data: accountData, loading: getBrandLoading }] = useLazyQuery(GET_BRANDS);
  const [resendVerificationEmail, { loading, data }] = useMutation(RESEND_VERIFY_EMAIL);

  const updateSubmitButton = useCallback(
    (field) => {
      const isAnyValidationError = errorMessage && !!errorMessage[field];
      const isAllValuesFilled = values[field];
      setSubmitState(!!isAllValuesFilled && !isAnyValidationError);
    },
    [errorMessage, values]
  );

  useEffect(() => {
    if (errorMessage) {
      if (!(showFields.showAccountType || showFields.showBrandName)) {
        updateSubmitButton(EMAIL);
      }
      if (showFields.showAccountType) {
        updateSubmitButton(ACCOUNT_TYPE);
      }
      if (showFields.showBrandName) {
        updateSubmitButton(BRAND_NAME);
      }
    }
  }, [showFields, errorMessage, updateSubmitButton, EMAIL, ACCOUNT_TYPE, BRAND_NAME]);

  const setFieldStatus = useCallback((field, value) => {
    setShowFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    setFieldStatus(SHOW_BRAND_NAME, false);
    if (
      (values.accountType === constant.SELLER || values.accountType === constant.VENDOR) &&
      showFields.showAccountType
    ) {
      setFieldStatus(SHOW_BRAND_NAME, true);
    }
  }, [
    SHOW_BRAND_NAME,
    showFields.showBrandName,
    values.accountType,
    userBrands,
    setFieldStatus,
    showFields.showAccountType,
  ]);

  const handleChange = useCallback(async (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const sendEmail = useCallback(
    async (accountType = "", brandName = "") => {
      const inputData = {};
      inputData.email = values.email && values.email.toLowerCase();
      inputData.userType = accountType || values.accountType;
      inputData.brandName = brandName || values.brandName;
      let bannerContent = {};
      let isMailSend = true;
      try {
        const result = await resendVerificationEmail({
          variables: { input: inputData },
        });
        bannerContent = {
          isOpen: true,
          status: SUCCESS,
          title: cms("message.success"),
        };
        const errorResponse = baseHelper.getResponseError(result.data, gql.RESEND_EMAIL);
        if (errorResponse) {
          bannerContent = { isOpen: true, status: CRITICAL, title: errorResponse };
          isMailSend = false;
          if (isSeller && isVendor) {
            setValues((prevState) => ({
              ...prevState,
              isAccountTypeDisable: false,
            }));
          }
        }
        setBanner(bannerContent);
        if (isMailSend) {
          setTimeout(() => history.push("/login"), 1000);
        }
        setEmail("");
      } catch (exception) {
        bannerContent = { isOpen: true, isScrollTop: true, status: CRITICAL, title: errorHelper.parse(exception) };
        setBanner(bannerContent);
      }
    },
    [
      CRITICAL,
      SUCCESS,
      cms,
      gql.RESEND_EMAIL,
      history,
      isSeller,
      isVendor,
      resendVerificationEmail,
      values.accountType,
      values.brandName,
      values.email,
    ]
  );

  const listBrand = useCallback((response) => {
    setUserBrands((prevState) => ({
      ...prevState,
      seller: response.getBrands.data.shops,
      vendor: response.getBrands.data.associatedSellers,
    }));
  }, []);

  const showSellerStore = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_BRAND_NAME, true);
    },
    [SHOW_BRAND_NAME, listBrand, setFieldStatus]
  );
  const showAccountAndBrand = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_ACCOUNT_TYPE, true);
    },
    [SHOW_ACCOUNT_TYPE, listBrand, setFieldStatus]
  );

  const userStoreList = useCallback(
    async (response) => {
      const resData = baseHelper.getResponseData(response, gql.GET_BRANDS);
      const resError = baseHelper.getResponseError(response, gql.GET_BRANDS);
      const isSellerRole = resData && !!resData.shops.length;
      const isVendorRole = resData && !!resData.associatedSellers.length;
      if (isVendorRole && resData.associatedSellers) {
        setIsVendor(!!resData.associatedSellers.filter((item) => item).length);
      }
      if (isSellerRole && resData.shops) {
        setIsSeller(!!resData.shops.filter((item) => item).length);
      }
      const isBothRole = resData && !!resData.shops.length && !!resData.associatedSellers.length;
      if (resData) {
        setBanner({ isOpen: false });
      }
      if (isSellerRole && isVendorRole) {
        showAccountAndBrand(response);
      }
      if (isSellerRole && !isVendorRole) {
        showSellerStore(response);
        const sellerBrandName = resData && resData.shops && resData.shops.length && resData.shops[0].value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.SELLER,
          brandName: sellerBrandName,
        }));
        if (resData.shops.length) {
          setFieldStatus(SHOW_ACCOUNT_TYPE, true);
        }
      }
      if (!isSellerRole && isVendorRole) {
        showSellerStore(response);
        const vendorBrandName =
          resData &&
          resData.associatedSellers &&
          resData.associatedSellers.length &&
          resData.associatedSellers[0].value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.VENDOR,
          brandName: vendorBrandName,
        }));
        if (resData.associatedSellers.length) {
          setFieldStatus(SHOW_ACCOUNT_TYPE, true);
        }
      }
      if ((!isSellerRole && !isVendorRole && !isBothRole) || resError) {
        setValues((prevState) => ({
          ...prevState,
          email: "",
        }));
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: constant.CRITICAL,
          title: resError || cms("common.message.error.unauthorized"),
        }));
        return;
      }
      setValues((prevState) => ({
        ...prevState,
        isEmailDisable: true,
      }));
    },
    [gql.GET_BRANDS, showAccountAndBrand, showSellerStore, setFieldStatus, SHOW_ACCOUNT_TYPE, cms]
  );

  useEffect(() => {
    if (!loading && !getBrandLoading && isLoading) {
      if (data) {
        const resData = baseHelper.getResponseData(data, gql.RESEND_EMAIL);
        let bannerData = { title: cms("message.success.mail"), status: constant.SUCCESS };
        if (!resData) {
          const resError = baseHelper.getResponseError(data, gql.RESEND_EMAIL);
          bannerData = { title: resError, status: constant.CRITICAL };
        }
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: bannerData.status,
          title: bannerData.title,
        }));
      }
      setIsLoading(false);
    }
  }, [data, accountData, loading, getBrandLoading, isLoading, gql.RESEND_EMAIL, cms, userStoreList]);

  useEffect(() => {
    if (accountData && !isEmailSent && fetchNewAccount) {
      userStoreList(accountData);
      setFetchNewAccount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  const fetchAccountTypes = async () => {
    setIsLoading(true);
    const isAnyValidationError = errorMessage && !!errorMessage.email;
    const isAllValuesFilled = values.email;
    if (!isAnyValidationError && isAllValuesFilled) {
      try {
        getAccount({ variables: { input: { email: values.email } } });
      } catch (exception) {
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        }));
      }
    }
  };

  const fetchAccounts = () => {
    setIsLoading(true);
    setValues((prevState) => ({
      ...prevState,
      isAccountTypeDisable: true,
    }));
    setFieldStatus(SHOW_BRAND_NAME, true);
  };

  const displayBrands = (role) => {
    if (role === constant.VENDOR) {
      return userBrands.vendor.length ? userBrands.vendor : [];
    }
    if (role === constant.SELLER) {
      return userBrands.seller.length ? userBrands.seller : [];
    }
    return false;
  };

  const renderBrandName = () => {
    if (!showFields.showBrandName) {
      return null;
    }
    return (
      <Brand
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        brandName={values.brandName}
        brands={displayBrands(values.accountType)}
        cms={cms}
      />
    );
  };

  const renderAccountType = () => {
    if (!showFields.showAccountType) {
      return null;
    }
    return (
      <AccountType
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        accountType={values.accountType}
        isVendor={isVendor}
        isSeller={isSeller}
        isAccountTypeDisable={values.isAccountTypeDisable}
        cms={cms}
      />
    );
  };

  const onSubmit = async () => {
    const isEmailSubmit = !(showFields.showAccountType || showFields.showBrandName);
    const isAccountTypeSubmit = showFields.showAccountType;
    const isBrandNameSubmit = showFields.showBrandName;
    if (isEmailSubmit) {
      await handleValidation(EMAIL, values.email);
      fetchAccountTypes();
      setFetchNewAccount(true);
    }
    if (isAccountTypeSubmit) {
      await handleValidation(ACCOUNT_TYPE, values.accountType);
      fetchAccounts();
    }
    if (isBrandNameSubmit) {
      await handleValidation(BRAND_NAME, values.brandName);
      setIsEmailSent(true);
      sendEmail();
    }
  };

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => dismissBanner()} />
      <br />
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card sectioned>
          <FormLayout>
            <TextField
              id="email"
              type="email"
              placeholder={cms("common.placeholder.emailAddress")}
              value={values.email || ""}
              onChange={(value) => handleChange(EMAIL, value)}
              onBlur={() => handleValidation(EMAIL, values.email)}
              error={errorMessage && errorMessage.email}
              disabled={values.isEmailDisable}
            />
            {renderAccountType()}
            {renderBrandName()}
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            id: "resendVerifyEmail",
            content: cms("button.primary"),
            onAction: () => onSubmit(),
            disabled: !isReadyToSubmit,
            loading: loading || getBrandLoading,
          }}
          secondaryActions={{
            id: "cancel",
            content: cms("common.button.cancel"),
            onAction: () => history.push("/login"),
          }}
        />
      </Layout.AnnotatedSection>
      <Footer />
    </>
  );
};

export default withErrorBoundary(ResendVerifyEmail);
