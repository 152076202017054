import {
  InviteMinor,
  ActivitiesMajor,
  AddProductMajor,
  CustomerPlusMajor,
  PaymentsMajor,
} from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.history"),
        label: cms("common.actions.dashboard.label.history"),
        link: "/onboard-activity",
        icon: ActivitiesMajor,
      },
    ],
    "/activity": [
      {
        caption: cms("common.actions.activity.caption.bulk"),
        label: cms("common.actions.activity.label.bulk"),
        link: "activity/bulk",
        icon: ActivitiesMajor,
      },
    ],
    "/invitation": [
      {
        caption: cms("common.actions.invitation.caption.manage"),
        label: cms("common.actions.invitation.label.manage"),
        link: "/providers/add",
        icon: InviteMinor,
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.addProductStore"),
        label: cms("common.actions.products.label.addProductStore"),
        link: "/products/add",
        icon: AddProductMajor,
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.autoPayouts"),
        label: cms("common.actions.payments.label.autoPayouts"),
        link: "/payouts",
        icon: PaymentsMajor,
      },
    ],
    "/providers": [
      {
        caption: cms("common.actions.vendors.caption.add"),
        label: cms("common.actions.vendors.label.add"),
        link: "/providers/add",
        icon: CustomerPlusMajor,
      },
    ],
  };
};

export default config;
