import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

const Inventory = (props) => {
  const { variantData = {}, handleChange = () => {}, handleTitleChange = () => {}, cms } = props;
  const { barcode = null, inventoryManagement = null, inventoryQuantity = 0, sku = "" } = variantData;
  const isDisabled = inventoryManagement !== constant.SHOPIFY;

  const options = [
    { label: cms("section.inventory.label.noTrack"), value: constant.DO_NOT_TRACK_INVENTORY },
    { label: cms("section.inventory.label.shopifyTrack"), value: constant.SHOPIFY },
  ];

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && inventoryQuantity);
  };

  return (
    <Card title={cms("section.inventory.title")} id="variantInventory" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="sku"
            label={cms("section.inventory.label.sku")}
            value={sku || ""}
            onChange={(value) => handleChange(constant.SKU, value)}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="barcode"
            label={cms("section.inventory.label.barcode")}
            value={barcode || ""}
            onChange={(value) => handleTitleChange(constant.BARCODE, value)}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            id="policySelection"
            label={cms("section.inventory.label.policy")}
            options={options}
            value={inventoryManagement || ""}
            onChange={(value) => {
              handleTitleChange(constant.INVENTORY_MANAGEMENT, value);
              if (value !== constant.SHOPIFY) {
                handleChange(constant.INVENTORY_QUANTITY, 0);
              }
            }}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="inventoryQuantity"
            disabled={isDisabled}
            label={cms("section.inventory.label.quantity")}
            value={(inventoryQuantity && inventoryQuantity.toString()) || ""}
            onChange={(value) => {
              handleChange(constant.INVENTORY_QUANTITY, Number(acceptOnlyValidInput(value)));
            }}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
};

export default Inventory;
