import {
  ACCEPT_ORDER_EXPIRY,
  ADD_ADDRESS,
  ADD_SELLER_PLAN,
  ADVANCE_ACCEPT_ORDER_EXPIRY,
  ADVANCE_PICKUP_ORDER_EXPIRY,
  CUSTOMIZE_FONT,
  DELETE_ADDRESS,
  EDIT_ADDRESS,
  EDIT_DEFAULT,
  EDIT_SELLER_PLAN,
  ORDER_EXPIRY,
  PICKUP_ORDER_EXPIRY,
  UPDATE_CREDIT_NOTE_SETTING,
  UPDATE_COMMISSION_TAX_SETTING,
  UPDATE_DISCOUNT,
  UPDATE_DYNAMIC_DISCOUNT_SETTING,
  UPDATE_DISCOUNT_CODE_SETTING,
  UPDATE_FULFILLMENT,
  UPDATE_GEOLOCATION_SETTING,
  UPDATE_INVOICE_ACCESS_SETTING,
  UPDATE_INVOICE_PREFIX_NUMBER,
  UPDATE_INVOICE_CUSTOM_NOTE,
  UPDATE_MARKUP,
  UPDATE_PAYOUT,
  UPDATE_ORDER_LIVE_CONNECT_SETTING,
  UPDATE_RATE,
  UPDATE_SELLER_FULFILLMENT,
  UPDATE_SHIPPING_TAX_SETTING,
  UPDATE_TAG_SETTING,
  UPDATE_TAX_RATE_SETTING,
  UPDATE_TERM,
  UPDATE_VENDOR_FULFILLMENT,
} from "./mutations";
import {
  GET_ADDRESS,
  GET_ASSOCIATED_SELLER,
  GET_CATALOG,
  GET_CREDIT_NOTE_SETTING,
  GET_CURRENCY_PAYOUT,
  GET_CURRENCY_RATE,
  GET_CURRENCY,
  GET_DISCOUNT,
  GET_DISCOUNT_SETTING,
  GET_DISCOUNT_CODE_SETTING,
  GET_FULFILLMENT,
  GET_GEOLOCATION_SETTING,
  GET_INVOICE_SETTING,
  GET_MARKUP,
  GET_ORDER_ACS_SETTING,
  GET_ORDER_EXPIRY_SETTING,
  GET_ORDER_LIVE_CONNECT_SETTING,
  GET_PRODUCT_TAG_SETTING,
  GET_PRODUCT_TYPE,
  GET_REPORT_DATA,
  GET_SELLER_FULFILLMENT,
  GET_SELLER_PLAN,
  GET_SELLER_PLAN_DETAILS,
  GET_SENDABOX_CARRIER,
  GET_SHIPPO_CARRIER,
  GET_SHIPPY_PRO_CARRIER,
  GET_TAX_SETTING,
  GET_TAX_RATE_SETTING,
  GET_USER,
  GET_USER_DATABASE_SETTING,
  GET_VENDOR_AND_PRODUCT_TYPES,
  GET_VENDOR_BRAND_LIST,
  GET_VENDOR_DISCOUNT_SETTING,
  GET_VENDOR_HOLIDAY,
  GET_VENDOR_FULFILLMENT,
  STANDARD_DISCOUNT,
  GET_PRODUCT_EXPORT_SETTING,
} from "./queries";

export {
  ACCEPT_ORDER_EXPIRY,
  ADD_ADDRESS,
  ADD_SELLER_PLAN,
  ADVANCE_ACCEPT_ORDER_EXPIRY,
  ADVANCE_PICKUP_ORDER_EXPIRY,
  CUSTOMIZE_FONT,
  DELETE_ADDRESS,
  EDIT_ADDRESS,
  EDIT_DEFAULT,
  EDIT_SELLER_PLAN,
  GET_ADDRESS,
  GET_ASSOCIATED_SELLER,
  GET_CATALOG,
  GET_CREDIT_NOTE_SETTING,
  GET_CURRENCY_PAYOUT,
  GET_CURRENCY_RATE,
  GET_CURRENCY,
  GET_DISCOUNT,
  GET_DISCOUNT_SETTING,
  GET_DISCOUNT_CODE_SETTING,
  GET_FULFILLMENT,
  GET_GEOLOCATION_SETTING,
  GET_INVOICE_SETTING,
  GET_MARKUP,
  GET_ORDER_ACS_SETTING,
  GET_ORDER_EXPIRY_SETTING,
  GET_ORDER_LIVE_CONNECT_SETTING,
  GET_PRODUCT_TAG_SETTING,
  GET_PRODUCT_TYPE,
  GET_REPORT_DATA,
  GET_SELLER_FULFILLMENT,
  GET_SELLER_PLAN,
  GET_SELLER_PLAN_DETAILS,
  GET_SENDABOX_CARRIER,
  GET_SHIPPO_CARRIER,
  GET_SHIPPY_PRO_CARRIER,
  GET_TAX_SETTING,
  GET_TAX_RATE_SETTING,
  GET_USER,
  GET_USER_DATABASE_SETTING,
  GET_VENDOR_AND_PRODUCT_TYPES,
  GET_VENDOR_BRAND_LIST,
  GET_VENDOR_DISCOUNT_SETTING,
  GET_VENDOR_HOLIDAY,
  GET_VENDOR_FULFILLMENT,
  GET_PRODUCT_EXPORT_SETTING,
  ORDER_EXPIRY,
  PICKUP_ORDER_EXPIRY,
  STANDARD_DISCOUNT,
  UPDATE_CREDIT_NOTE_SETTING,
  UPDATE_COMMISSION_TAX_SETTING,
  UPDATE_DISCOUNT,
  UPDATE_DYNAMIC_DISCOUNT_SETTING,
  UPDATE_DISCOUNT_CODE_SETTING,
  UPDATE_FULFILLMENT,
  UPDATE_GEOLOCATION_SETTING,
  UPDATE_INVOICE_ACCESS_SETTING,
  UPDATE_INVOICE_PREFIX_NUMBER,
  UPDATE_INVOICE_CUSTOM_NOTE,
  UPDATE_MARKUP,
  UPDATE_ORDER_LIVE_CONNECT_SETTING,
  UPDATE_PAYOUT,
  UPDATE_RATE,
  UPDATE_SELLER_FULFILLMENT,
  UPDATE_SHIPPING_TAX_SETTING,
  UPDATE_TAG_SETTING,
  UPDATE_TAX_RATE_SETTING,
  UPDATE_TERM,
  UPDATE_VENDOR_FULFILLMENT,
};
