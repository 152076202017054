import React, { useContext, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  GET_SHIPPING_BAND,
  GET_SHIPPING_REGION,
  GET_SHIPPING_PRODUCT,
  GET_SHIPPING_RULE,
} from "app/shipping/apollo/queries";

import { PrivateContext } from "lib/context";
// import subFeatures
import List from "./subFeatures/list/list";

// import propType
import { shippingRuleProp } from "./propTypes";

const OperatorShippingRule = (props) => {
  const {
    setBanner,
    isVendorAllowed,
    productLakeLoading,
    productLakeError,
    productLakeDataError,
    productLakeDataResponse,
  } = props;
  const { currentUser: { ecommercePlatform = "" } = {} } = useContext(PrivateContext);

  const { loading: ruleLoading, data: ruleData, error: ruleError, networkStatus, refetch } = useQuery(
    GET_SHIPPING_RULE,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const { loading: bandLoading, data: bandData, error: bandError } = useQuery(GET_SHIPPING_BAND);
  const { loading: destinationLoading, data: destinationData, error: destinationError } = useQuery(GET_SHIPPING_REGION);
  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_PRODUCT);

  const queryLoading =
    ruleLoading || networkStatus === 4 || bandLoading || destinationLoading || productLoading || productLakeLoading;
  const isError = ruleError || bandError || destinationError || productError || productLakeError;

  useEffect(() => {
    if (isError) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(isError),
        status: constant.CRITICAL,
      });
    }
  }, [isError, setBanner]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBanner({
        isOpen: true,
        title: errorMessage,
        status: constant.CRITICAL,
      });
    },
    [setBanner]
  );

  // shipping rule
  const ruleDataError = baseHelper.getResponseError(ruleData, constant.gql.GET_SHIPPING_RULE);
  useEffect(() => {
    if (ruleDataError) {
      setErrorBanner(ruleDataError);
    }
  }, [ruleDataError, setErrorBanner]);

  const ruleDataResponse = baseHelper.getResponseData(ruleData, constant.gql.GET_SHIPPING_RULE);
  // shipping band
  const bandDataError = baseHelper.getResponseError(bandData, constant.gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (bandDataError) {
      setErrorBanner(bandDataError);
    }
  }, [bandDataError, setErrorBanner]);
  const bandDataResponse = baseHelper.getResponseData(bandData, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows = [] } = bandDataResponse || {};
  const shippingBands = shippingRows.map(({ _id: bandId, name }) => ({
    label: name,
    value: bandId,
  }));

  // shipping destination
  const destinationDataError = baseHelper.getResponseError(destinationData, constant.gql.GET_REGION);
  useEffect(() => {
    if (destinationDataError) {
      setErrorBanner(destinationDataError);
    }
  }, [destinationDataError, setErrorBanner]);
  const destinationDataResponse = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];

  // shipping products
  const productDataError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      setErrorBanner(productDataError);
    }
  }, [productDataError, setErrorBanner]);
  const productDataResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCTS) || [];

  useEffect(() => {
    if (productLakeDataError) {
      setErrorBanner(productLakeDataError);
    }
  }, [productLakeDataError, setErrorBanner]);

  return (
    <>
      <List
        setBanner={setBanner}
        isVendorAllowed={isVendorAllowed}
        ruleDataResponse={ruleDataResponse || []}
        bands={shippingBands || []}
        products={ecommercePlatform ? productLakeDataResponse : productDataResponse || []}
        destinations={destinationDataResponse || []}
        loading={queryLoading}
        refetch={refetch}
      />
    </>
  );
};

OperatorShippingRule.propTypes = shippingRuleProp.type;

export default OperatorShippingRule;
