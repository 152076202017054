import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  accountNumberSchema,
  addressSchema,
  citySchema,
  clientIdSchema,
  clientSecretSchema,
  contractIdSchema,
  countrySchema,
  customerNumberSchema,
  passwordSchema,
  pincodeSchema,
  siteCodeSchema,
  userNameSchema,
  vendorSchema,
} from "./schema";

const validate = async (field, value, cms) => {
  const { validateValue, handlePromiseError } = yupHelper;
  let promise = "";
  switch (field) {
    case constant.ADDRESS:
      promise = validateValue(addressSchema(cms), { address: value });
      break;
    case constant.CITY:
      promise = validateValue(citySchema(cms), { city: value });
      break;
    case constant.COUNTRY_CODE:
      promise = validateValue(countrySchema(cms), { countryCode: value });
      break;
    case constant.PINCODE:
      promise = validateValue(pincodeSchema(cms), { pincode: value });
      break;
    case constant.VENDOR_IDS:
      promise = validateValue(vendorSchema(cms), { vendorIds: value });
      break;
    case constant.CLIENT_ID:
      promise = validateValue(clientIdSchema(cms), { clientId: value });
      break;
    case constant.CLIENT_SECRET:
      promise = validateValue(clientSecretSchema(cms), { clientSecret: value });
      break;
    case constant.ACCOUNT_NUMBER:
      promise = validateValue(accountNumberSchema(cms), { accountNumber: value });
      break;
    case constant.SITE_CODE:
      promise = validateValue(siteCodeSchema(cms), { siteCode: value });
      break;
    case constant.USER_NAME:
      promise = validateValue(userNameSchema(cms), { userName: value });
      break;
    case constant.PASSWORD:
      promise = validateValue(passwordSchema(cms), { password: value });
      break;
    case constant.CUSTOMER_NUMBER:
      promise = validateValue(customerNumberSchema(cms), { customerNumber: value });
      break;
    case constant.CONTRACT_ID:
      promise = validateValue(contractIdSchema(cms), { contractId: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
