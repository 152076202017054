import { gql } from "apollo-boost";

const CHECKOUT_STRIPE_PLAN = gql`
  mutation checkoutStripePlan($input: CheckoutStripePlan!) {
    checkoutStripePlan(input: $input) {
      data
      status
      error
    }
  }
`;

const CREATE_FREE_PLAN = gql`
  mutation createFreePlan {
    createFreePlan {
      status
      error
      data
    }
  }
`;

const CREATE_PLAN = gql`
  mutation createPlan($input: CreatePlan!) {
    createPlan(input: $input) {
      data
      status
      error
    }
  }
`;

const CREATE_SHOPIFY_CHARGE = gql`
  mutation createShopifyCharge($input: CreateShopifyCharge!) {
    createShopifyCharge(input: $input) {
      data {
        confirmation_url
      }
      status
      error
    }
  }
`;

const SET_SHOPIFY_DETAILS = gql`
  mutation shopifyDetail($input: ShopifyDetailInput) {
    shopifyDetail(input: $input) {
      status
      error
      data {
        _id
        shop
        accessToken
        chargeId
        plan
        token
      }
    }
  }
`;

const SHOPIFY_INSTALL_LOGIN = gql`
  mutation shopifyInstallLogin($input: ShopifyInstallLoginInput) {
    shopifyInstallLogin(input: $input) {
      status
      error
      data {
        token
      }
    }
  }
`;

const SELECT_PLAN = gql`
  mutation selectPlan($input: SelectPlanInput!) {
    selectPlan(input: $input) {
      status
      error
      data {
        confirmation_url
        firstLogin
        id
        shop
        token
        price
      }
    }
  }
`;

const UPDATE_PLAN_DETAILS = gql`
  mutation UpdatePlanDetails($input: UpdatePlanDetails) {
    updatePlanDetails(input: $input) {
      data
      status
      error
    }
  }
`;

const STRIPE_OPERATOR = gql`
  mutation StripeOperator {
    stripeOperator {
      data {
        sessionId
      }
      status
      error
    }
  }
`;

const STRIPE_CONFIRM = gql`
  mutation StripeConfirm {
    stripeConfirm {
      data {
        isActive
      }
      status
      error
    }
  }
`;

export {
  CHECKOUT_STRIPE_PLAN,
  CREATE_FREE_PLAN,
  CREATE_PLAN,
  CREATE_SHOPIFY_CHARGE,
  SET_SHOPIFY_DETAILS,
  SELECT_PLAN,
  UPDATE_PLAN_DETAILS,
  SHOPIFY_INSTALL_LOGIN,
  STRIPE_OPERATOR,
  STRIPE_CONFIRM,
};
