import styled from "styled-components";
import constant from "lib/constant/constant";

const {
  DEFAULT_MENU_COLOR,
  DEFAULT_CONTENT_COLOR,
  DEFAULT_HEADING_COLOR,
  DEFAULT_TOAST_COLOR,
  DEFAULT_TOAST_TEXT_COLOR,
} = constant;

const ThemeProvider = styled.div`
  .styled-tabs .Polaris-Tabs__Title {
    color: ${(props) => props.tabColor || DEFAULT_MENU_COLOR};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.headingColor || DEFAULT_HEADING_COLOR};
  }
  .Polaris-Frame-ToastManager .Polaris-Frame-Toast {
    background-color: ${(props) => props.toastBackground || DEFAULT_TOAST_COLOR};
    color: ${(props) => props.toastColor || DEFAULT_TOAST_TEXT_COLOR};
  }
`;

const AnalyticThemeProvider = styled.div`
  p,
  center,
  span,
  .Polaris-TextContainer,
  .Polaris-TextStyle--variationSubdued,
  strong {
    color: ${(props) => props.contentColor || DEFAULT_CONTENT_COLOR};
  }
  svg {
    fill: ${(props) => props.contentColor || DEFAULT_CONTENT_COLOR} !important;
  }
`;

export { ThemeProvider, AnalyticThemeProvider };
