import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { QuickReport } from "app/reports";
import { SkeletonList } from "lib/components";
import { SingleColumnLayout } from "layout/private/components";

import { withErrorBoundary } from "lib/hoc";

import { WixProducts } from "./provider/features";

const { userKey } = constant;
const { provider } = userKey;

const WixList = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    if (role === provider) {
      return <WixProducts />;
    }
    return null;
  };

  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withErrorBoundary(WixList);
