import React, { useState, useContext, useEffect } from "react";
import {
  Banner,
  Button,
  FormLayout,
  Link,
  RadioButton,
  Select,
  SkeletonBodyText,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { useQuery } from "react-apollo";
import PropTypes from "prop-types";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { GET_VENDOR_AND_PRODUCT_TYPES, GET_ADDRESS } from "app/setup/apollo/queries";
import { FulfillmentWrapper, ConsignmentShipWrapper } from "asset/styles/globalStyle";

const ConsignmentShip = (props) => {
  const {
    clickCollect,
    clickReserve,
    timeService,
    cms,
    consignmentPickup,
    consignmentShip,
    dropship,
    setClickCollect,
    setClickReserve,
    setTimeService,
    setConsignmentPickup,
    setConsignmentShip,
    setDropship,
    setSubmitEnable,
    selectedTab,
    type,
  } = useContext(FulfillmentContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { gql } = constant;
  const [allTypes, setAllTypes] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [shipData, setShipData] = useState();
  const [selectRadioButton, setSelectedRadioButton] = useState();
  const clickCollectVendorIds = [];
  const dropshipVendorIds = [];
  const clickCollectProductTypeIds = [];
  const dropshipProductTypeIds = [];
  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);
  const { loading: addressLoading, error: addressError, data: addressData } = useQuery(GET_ADDRESS);
  const { method } = props;
  const setData =
    (method === constant.HYBRID && setDropship) ||
    (method === constant.HYBRID_CONSIGNMENT_PICKUP && setConsignmentPickup) ||
    (method === constant.HYBRID_CLICK_COLLECT && setClickCollect) ||
    (method === constant.HYBRID_CLICK_RESERVE && setClickReserve) ||
    (method === constant.HYBRID_TIME_SERVICE && setTimeService) ||
    setConsignmentShip;

  useEffect(() => {
    if (method === constant.HYBRID) {
      setShipData(dropship);
    }
    if (method === constant.HYBRID_CONSIGNMENT_PICKUP) {
      setShipData(consignmentPickup);
    }
    if (method === constant.HYBRID_CLICK_COLLECT) {
      setShipData(clickCollect);
    }
    if (method === constant.HYBRID_CLICK_RESERVE) {
      setShipData(clickReserve);
    }
    if (method === constant.HYBRID_TIME_SERVICE) {
      setShipData(timeService);
    }
    if (
      ![
        constant.HYBRID,
        constant.HYBRID_CONSIGNMENT_PICKUP,
        constant.HYBRID_CLICK_RESERVE,
        constant.HYBRID_CLICK_COLLECT,
        constant.HYBRID_TIME_SERVICE,
      ].includes(method)
    ) {
      setShipData(consignmentShip);
    }
  }, [method, consignmentPickup, consignmentShip, clickCollect, clickReserve, timeService, dropship]);

  const handleSelectChange = (_checked, newValue) => {
    const newSetting = {};
    if (
      [
        constant.HYBRID,
        constant.HYBRID_CONSIGNMENT_PICKUP,
        constant.HYBRID_CLICK_RESERVE,
        constant.HYBRID_CLICK_COLLECT,
        constant.HYBRID_TIME_SERVICE,
      ].includes(method)
    ) {
      newSetting.productType = "";
      newSetting.vendorId = "";
    }
    if (
      ![
        constant.HYBRID,
        constant.HYBRID_CONSIGNMENT_PICKUP,
        constant.HYBRID_CLICK_RESERVE,
        constant.HYBRID_CLICK_COLLECT,
        constant.HYBRID_TIME_SERVICE,
      ].includes(method)
    ) {
      newSetting.vendorId = "";
      newSetting.productType = "";
      newSetting.addressId = "";
    }
    setData((prevState) => ({
      ...prevState,
      by: newValue,
      setting: [newSetting],
    }));
    setSubmitEnable(true);
    setSelectedRadioButton(newValue);
  };

  useEffect(() => {
    if (vendorAndProductTypesError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(vendorAndProductTypesError) });
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, gql.GET_VENDOR_PRODUCT);
    const { types = [], supplierRows = [] } = responseData;

    const selectedTypes = [];
    if (dropship && dropship.setting && dropship.setting.length) {
      // dropship.setting.map((data) => selectedTypes.push(data.productType));
      dropship.setting.map((data) => {
        dropshipProductTypeIds.push(data.productType);
        selectedTypes.push(data.productType);
      });
    }
    if (consignmentPickup && consignmentPickup.setting && consignmentPickup.setting.length) {
      consignmentPickup.setting.map((data) => selectedTypes.push(data.productType));
    }
    if (consignmentShip && consignmentShip.setting && consignmentShip.setting.length) {
      consignmentShip.setting.map((data) => selectedTypes.push(data.productType));
    }
    if (clickReserve && clickReserve.setting && clickReserve.setting.length) {
      clickReserve.setting.map((data) => selectedTypes.push(data.productType));
    }
    if (clickCollect && clickCollect.setting && clickCollect.setting.length) {
      // clickCollect.setting.map((data) => selectedTypes.push(data.productType));
      clickCollect.setting.map((data) => {
        clickCollectProductTypeIds.push(data.productType);
        selectedTypes.push(data.productType);
      });
    }
    if (timeService && timeService.setting && timeService.setting.length) {
      timeService.setting.map((data) => selectedTypes.push(data.productType));
    }
    const productTypes = types.filter((item) => {
      const { value } = item;
      const itemData = item;
      if (itemData.value) {
        if (shipData && shipData.by !== constant.VENDOR_PRODUCT_TYPE) {
          if (!selectedTypes.includes(value)) {
            itemData.disabled = false;
          }
          if (selectedTypes.includes(value)) {
            if (
              clickCollectProductTypeIds.length &&
              clickCollectProductTypeIds.includes(value) &&
              !dropshipProductTypeIds.includes(value) &&
              type === constant.HYBRID &&
              selectedTab === 1 &&
              dropship
            ) {
              itemData.disabled = false;
            } else if (
              dropshipProductTypeIds.length &&
              dropshipProductTypeIds.includes(value) &&
              !clickCollectProductTypeIds.includes(value) &&
              type === constant.HYBRID &&
              selectedTab === 4 &&
              clickCollect
            ) {
              itemData.disabled = false;
            } else {
              itemData.disabled = true;
            }
          }
        }
        return itemData;
      }
      return null;
    });
    const formattedTypes = productTypes.map(({ value, disabled }) => ({
      label: value,
      value,
      disabled,
    }));

    const selectedSuppliers = [];
    if (dropship && dropship.setting && dropship.setting.length) {
      // dropship.setting.map((data) => selectedSuppliers.push(data.vendorId));
      dropship.setting.map((data) => {
        dropshipVendorIds.push(data.vendorId);
        selectedSuppliers.push(data.vendorId);
      });
    }
    if (consignmentPickup && consignmentPickup.setting && consignmentPickup.setting.length) {
      consignmentPickup.setting.map((data) => selectedSuppliers.push(data.vendorId));
    }
    if (consignmentShip && consignmentShip.setting && consignmentShip.setting.length) {
      consignmentShip.setting.map((data) => selectedSuppliers.push(data.vendorId));
    }
    if (clickReserve && clickReserve.setting && clickReserve.setting.length) {
      clickReserve.setting.map((data) => selectedSuppliers.push(data.vendorId));
    }
    if (clickCollect && clickCollect.setting && clickCollect.setting.length) {
      // clickCollect.setting.map((data) => selectedSuppliers.push(data.vendorId));
      clickCollect.setting.map((data) => {
        clickCollectVendorIds.push(data.vendorId);
        selectedSuppliers.push(data.vendorId);
      });
    }
    if (timeService && timeService.setting && timeService.setting.length) {
      timeService.setting.map((data) => selectedSuppliers.push(data.vendorId));
    }
    const suppliers = supplierRows.filter((item) => {
      const { _id: id } = item;
      const itemData = item;
      if (itemData.brandName) {
        if (!selectedSuppliers.includes(id)) {
          itemData.disabled = false;
        }
        if (selectedSuppliers.includes(id)) {
          if (
            clickCollectVendorIds.length &&
            clickCollectVendorIds.includes(id) &&
            !dropshipVendorIds.includes(id) &&
            type === constant.HYBRID &&
            selectedTab === 1 &&
            dropship
          ) {
            itemData.disabled = false;
          } else if (
            dropshipVendorIds.length &&
            dropshipVendorIds.includes(id) &&
            !clickCollectVendorIds.includes(id) &&
            type === constant.HYBRID &&
            selectedTab === 4 &&
            clickCollect
          ) {
            itemData.disabled = false;
          } else {
            itemData.disabled = true;
          }
        }
        return itemData;
      }
      return null;
    });
    const formattedSuppliers = suppliers
      .filter((item) => item.brandName && item)
      .map(({ _id: vendorId, brandName, disabled }) => ({
        label: brandName,
        value: vendorId,
        disabled,
      }));

    if (responseData) {
      if (
        (selectRadioButton === constant.VENDOR_PRODUCT_TYPE && !(types.length && supplierRows.length)) ||
        (selectRadioButton === constant.PRODUCT_TYPE && !types.length) ||
        (selectRadioButton === constant.VENDOR && !supplierRows.length)
      ) {
        setBanner({
          isOpen: true,
          title: cms("message.error.consignmentShipUnavailableReason"),
          status: constant.CRITICAL,
        });
        // return;formattedTypes
      }
      if (!formattedTypes) {
        setBanner({
          isOpen: true,
          title: cms("message.error.productReason"),
          status: constant.WARNING,
        });
      }

      if (!formattedSuppliers) {
        setBanner({
          isOpen: true,
          title: cms("message.error.vendorReason"),
          status: constant.WARNING,
        });
      }
    }
    setAllTypes(formattedTypes);
    setAllSuppliers(formattedSuppliers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vendorAndProductTypesData,
    vendorAndProductTypesError,
    dropship,
    consignmentPickup,
    clickReserve,
    clickCollect,
    timeService,
    consignmentShip,
  ]);

  useEffect(() => {
    if (addressData) {
      const responseData = baseHelper.getResponseData(addressData, gql.GET_ADDRESS);
      const responseError = baseHelper.getResponseError(addressData, gql.GET_ADDRESS);
      if (responseData && responseData.addressList) {
        const { addressList } = responseData;
        const formattedAddress = addressList
          .filter((item) => item.label && item)
          .map(({ _id: addressId, label }) => ({
            label,
            value: addressId,
          }));
        setAllAddress(formattedAddress);
      }
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      }
    }

    if (addressError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(addressError) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData, addressError]);

  const removeItem = (id) => {
    if (shipData && shipData.by === constant.VENDOR) {
      const vendor = [...shipData.setting];
      vendor.splice(id, 1);
      // vendor.pop();
      setData((prevState) => ({
        ...prevState,
        setting: vendor,
      }));
    }

    if (shipData && shipData.by === constant.PRODUCT_TYPE) {
      const product = [...shipData.setting];
      product.splice(id, 1);
      // product.pop();
      setData((prevState) => ({
        ...prevState,
        setting: product,
      }));
    }

    if (shipData && shipData.by === constant.VENDOR_PRODUCT_TYPE) {
      const both = [...shipData.setting];
      both.splice(id, 1);
      // both.pop();
      setData((prevState) => ({
        ...prevState,
        setting: both,
      }));
    }
    setSubmitEnable(true);
  };

  const updateValue = (option, value, item) => {
    let duplicate = false;
    const updatedAdvancedValue = [...shipData.setting];
    if (shipData && shipData.by === constant.PRODUCT_TYPE) {
      updatedAdvancedValue.forEach((data) => {
        if (data.productType === value) {
          updatedAdvancedValue[item].productType = null;
          updatedAdvancedValue[item].addressId = null;
          setBanner({
            isOpen: true,
            title: cms("message.error.alreadyExists", { item: cms("label.provider") }),
            status: constant.CRITICAL,
          });
          duplicate = true;
        }
        setSubmitEnable(true);
      });
    }

    if ((shipData && shipData.by === constant.VENDOR) || (shipData && shipData.by === constant.VENDOR_PRODUCT_TYPE)) {
      updatedAdvancedValue.forEach((data) => {
        if (data.vendorId === value) {
          updatedAdvancedValue[item].vendorId = null;
          updatedAdvancedValue[item].addressId = null;
          setBanner({
            isOpen: true,
            title: cms("message.error.alreadyExists", { item: cms("label.product") }),
            status: constant.CRITICAL,
          });
          duplicate = true;
        }
        setSubmitEnable(true);
      });
    }

    if (duplicate) {
      return;
    }

    if (option === constant.VENDOR_ID) {
      updatedAdvancedValue[item].vendorId = value;
    }

    if (option === constant.PRODUCT_TYPE) {
      updatedAdvancedValue[item].productType = value;
    }

    if (option === constant.ADDRESS_ID) {
      updatedAdvancedValue[item].addressId = value;
    }

    setData((prevState) => ({
      ...prevState,
      setting: updatedAdvancedValue,
    }));
    setSubmitEnable(true);
  };

  const consignmentByVendor = (vendorData, id) => {
    if (shipData && shipData.by === constant.VENDOR) {
      return (
        <Stack vertical spacing="extraLoose">
          <FormLayout>
            <Stack>
              {([
                constant.HYBRID,
                constant.HYBRID_CONSIGNMENT_PICKUP,
                constant.HYBRID_CLICK_RESERVE,
                constant.HYBRID_CLICK_COLLECT,
                constant.HYBRID_TIME_SERVICE,
              ].includes(method) && (
                <Stack fill>
                  <Select
                    placeholder={cms("placeholder.vendors")}
                    options={allSuppliers}
                    value={vendorData.vendorId}
                    onChange={(value) => updateValue(constant.VENDOR_ID, value, id)}
                  />
                </Stack>
              )) || (
                <Stack>
                  <Stack.Item fill>
                    <Select
                      placeholder={cms("placeholder.vendors")}
                      options={allSuppliers}
                      value={vendorData.vendorId}
                      onChange={(value) => updateValue(constant.VENDOR_ID, value, id)}
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Select
                      placeholder={cms("placeholder.addressLabel")}
                      options={allAddress}
                      value={vendorData.addressId}
                      onChange={(value) => updateValue(constant.ADDRESS_ID, value, id)}
                    />
                  </Stack.Item>
                </Stack>
              )}
              <Stack>
                <div className="delete-icon">
                  <Stack.Item>
                    <Button size="plain" onClick={() => removeItem(id)}>
                      <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                    </Button>
                  </Stack.Item>
                </div>
              </Stack>
            </Stack>
          </FormLayout>
        </Stack>
      );
    }
    return null;
  };

  const consignmentByProductType = (productData, id) => {
    if (shipData && shipData.by === constant.PRODUCT_TYPE) {
      return (
        <Stack vertical spacing="extraLoose">
          <FormLayout>
            <Stack>
              {([
                constant.HYBRID,
                constant.HYBRID_CONSIGNMENT_PICKUP,
                constant.HYBRID_CLICK_RESERVE,
                constant.HYBRID_CLICK_COLLECT,
                constant.HYBRID_TIME_SERVICE,
              ].includes(method) && (
                <Select
                  placeholder={cms("placeholder.productType")}
                  options={allTypes}
                  onChange={(value) => updateValue(constant.PRODUCT_TYPE, value, id)}
                  value={productData.productType}
                />
              )) || (
                <Stack>
                  <Stack.Item fill>
                    <Select
                      options={allTypes}
                      placeholder={cms("placeholder.productType")}
                      onChange={(value) => updateValue(constant.PRODUCT_TYPE, value, id)}
                      value={productData.productType}
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Select
                      placeholder={cms("placeholder.addressLabel")}
                      options={allAddress}
                      value={productData.addressId}
                      onChange={(value) => updateValue(constant.ADDRESS_ID, value, id)}
                    />
                  </Stack.Item>
                </Stack>
              )}
              <div className="delete-icon">
                <Stack.Item>
                  <Button size="plain" onClick={() => removeItem(id)}>
                    <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                  </Button>
                </Stack.Item>
              </div>
            </Stack>
          </FormLayout>
        </Stack>
      );
    }
    return null;
  };

  const consignmentByBoth = (data, id) => {
    if (shipData && shipData.by === constant.VENDOR_PRODUCT_TYPE) {
      return (
        <Stack vertical spacing="extraTight">
          <FormLayout>
            <ConsignmentShipWrapper>
              <Stack>
                {([
                  constant.HYBRID,
                  constant.HYBRID_CONSIGNMENT_PICKUP,
                  constant.HYBRID_CLICK_RESERVE,
                  constant.HYBRID_CLICK_COLLECT,
                  constant.HYBRID_TIME_SERVICE,
                ].includes(method) && (
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Select
                        placeholder={cms("placeholder.vendors")}
                        options={allSuppliers}
                        value={data.vendorId}
                        onChange={(value) => updateValue(constant.VENDOR_ID, value, id)}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Select
                        options={allTypes}
                        placeholder={cms("placeholder.productType")}
                        onChange={(value) => updateValue(constant.PRODUCT_TYPE, value, id)}
                        value={data.productType}
                      />
                    </Stack.Item>
                  </Stack>
                )) || (
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Select
                        options={allSuppliers}
                        placeholder={cms("placeholder.vendors")}
                        value={data.vendorId}
                        onChange={(value) => updateValue(constant.VENDOR_ID, value, id)}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Select
                        placeholder={cms("placeholder.productType")}
                        options={allTypes}
                        onChange={(value) => updateValue(constant.PRODUCT_TYPE, value, id)}
                        value={data.productType}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Select
                        placeholder={cms("placeholder.addressLabel")}
                        options={allAddress}
                        value={data.addressId}
                        onChange={(value) => updateValue(constant.ADDRESS_ID, value, id)}
                      />
                    </Stack.Item>
                  </Stack>
                )}
                <div className="delete-icon">
                  <Stack.Item>
                    <Button size="plain" onClick={() => removeItem(id)}>
                      <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                    </Button>
                  </Stack.Item>
                </div>
              </Stack>
            </ConsignmentShipWrapper>
          </FormLayout>
        </Stack>
      );
    }
    return null;
  };

  const addItem = () => {
    switch (shipData && shipData.by) {
      case constant.VENDOR:
        {
          const vendorItem = (shipData && shipData.setting && [...shipData.setting]) || [];
          if (vendorItem.length > 0) {
            const line = vendorItem[vendorItem.length - 1];
            let keys = Object.keys(line);
            if (shipData.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            let isInvalid = false;
            keys.forEach((key) => {
              if (!line[key]) {
                isInvalid = true;
              }
            });
            if (isInvalid) {
              setBanner({
                isOpen: true,
                title: cms("message.requireFields"),
                status: constant.CRITICAL,
              });
              return;
            }
          }
          if (
            ![
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            vendorItem.push({
              vendorId: "",
              addressId: "",
            });
          }

          if (
            [
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            vendorItem.push({
              vendorId: "",
            });
            // if (method === constant.HYBRID) {
            //   setConsignmentPickup({});
            // }
            // if (method === constant.HYBRID_CONSIGNMENT_PICKUP) {
            //   setDropship({});
            // }
            // setConsignmentShip({});
          }
          setData((prevState) => ({
            ...prevState,
            setting: vendorItem,
          }));
        }
        break;
      case constant.PRODUCT_TYPE:
        {
          const productItem = (shipData && shipData.setting && [...shipData.setting]) || [];
          if (productItem.length > 0) {
            const line = productItem[productItem.length - 1];
            let keys = Object.keys(line);
            if (shipData.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            let isInvalid = false;
            keys.forEach((key) => {
              if (!line[key]) {
                isInvalid = true;
              }
            });
            if (isInvalid) {
              setBanner({
                isOpen: true,
                title: cms("message.requireFields"),
                status: constant.CRITICAL,
              });
              return;
            }
          }

          if (
            ![
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            productItem.push({
              productType: "",
              addressId: "",
            });
          }

          if (
            [
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            productItem.push({
              productType: "",
            });
          }
          setData((prevState) => ({
            ...prevState,
            setting: productItem,
          }));
        }
        break;
      case constant.VENDOR_PRODUCT_TYPE:
        {
          const productItem = (shipData && shipData.setting && [...shipData.setting]) || [];
          if (productItem.length > 0) {
            const line = productItem[productItem.length - 1];
            const keys = Object.keys(line);
            let isInvalid = false;
            keys.forEach((key) => {
              if (!line[key]) {
                isInvalid = true;
              }
            });
            if (isInvalid) {
              setBanner({
                isOpen: true,
                title: cms("message.requireFields"),
                status: constant.CRITICAL,
              });
              return;
            }
          }

          if (
            ![
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            productItem.push({
              vendorId: "",
              productType: "",
              addressId: "",
            });
          }

          if (
            [
              constant.HYBRID,
              constant.HYBRID_CONSIGNMENT_PICKUP,
              constant.HYBRID_CLICK_RESERVE,
              constant.HYBRID_CLICK_COLLECT,
              constant.HYBRID_TIME_SERVICE,
            ].includes(method)
          ) {
            productItem.push({
              vendorId: "",
              productType: "",
            });
          }
          setData((prevState) => ({
            ...prevState,
            setting: productItem,
          }));
        }

        break;
      default:
        break;
    }
  };
  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (addressLoading || vendorAndProductTypesLoading) {
    return (
      <TextContainer>
        <SkeletonBodyText />
      </TextContainer>
    );
  }

  return (
    <div>
      {banner.isOpen && (
        <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => closeBanner()} />
      )}
      <Stack>
        <Stack.Item>
          <RadioButton
            label={
              (method === constant.HYBRID && cms("hybrid.dropShip.dropByVendor")) ||
              (method === constant.DEFAULT_CONSIGNMENT_SHIP && cms("consignmentShip.vendorType")) ||
              (method === constant.HYBRID_CONSIGNMENT_PICKUP && cms("hybrid.consignmentPick.consignmentByVendor")) ||
              (method === constant.HYBRID_CLICK_RESERVE && cms("hybrid.clickReserve.byVendor")) ||
              (method === constant.HYBRID_CLICK_COLLECT && cms("hybrid.clickCollect.byVendor")) ||
              (method === constant.HYBRID_TIME_SERVICE && cms("hybrid.timeService.byVendor"))
            }
            id="vendor"
            checked={shipData && shipData.by === constant.VENDOR}
            onChange={handleSelectChange}
          />

          {(type !== constant.HYBRID ||
            (type === constant.HYBRID && selectedTab === 1 && !dropship?.isThirdPartyShipping) ||
            (type === constant.HYBRID && selectedTab === 2 && !consignmentShip?.isThirdPartyShipping) ||
            (type === constant.HYBRID && selectedTab === 3 && consignmentPickup) ||
            (type === constant.HYBRID && selectedTab === 4 && clickCollect) ||
            (type === constant.HYBRID && selectedTab === 5 && clickReserve) ||
            (type === constant.HYBRID && selectedTab === 6 && timeService)) && (
            <>
              <Stack.Item>
                <RadioButton
                  label={
                    (method === constant.HYBRID && cms("hybrid.dropShip.dropByProductType")) ||
                    (method === constant.DEFAULT_CONSIGNMENT_SHIP && cms("consignmentShip.productType")) ||
                    (method === constant.HYBRID_CONSIGNMENT_PICKUP &&
                      cms("hybrid.consignmentPick.consignmentByProductType")) ||
                    (method === constant.HYBRID_CLICK_RESERVE && cms("hybrid.clickReserve.byProduct")) ||
                    (method === constant.HYBRID_CLICK_COLLECT && cms("hybrid.clickCollect.byProduct")) ||
                    (method === constant.HYBRID_TIME_SERVICE && cms("hybrid.timeService.byProduct"))
                  }
                  id="productType"
                  checked={shipData && shipData.by === constant.PRODUCT_TYPE}
                  onChange={handleSelectChange}
                />
              </Stack.Item>
              <Stack.Item>
                <RadioButton
                  label={
                    (method === constant.HYBRID && cms("hybrid.dropShip.dropByVendorAndProductType")) ||
                    (method === constant.DEFAULT_CONSIGNMENT_SHIP && cms("consignmentShip.bothType")) ||
                    (method === constant.HYBRID_CONSIGNMENT_PICKUP &&
                      cms("hybrid.consignmentPick.consignmentByVendorAndProductType")) ||
                    (method === constant.HYBRID_CLICK_RESERVE && cms("hybrid.clickReserve.byVendorAndProduct")) ||
                    (method === constant.HYBRID_CLICK_COLLECT && cms("hybrid.clickCollect.byVendorAndProduct")) ||
                    (method === constant.HYBRID_TIME_SERVICE && cms("hybrid.timeService.byVendorAndProduct"))
                  }
                  checked={shipData && shipData.by === constant.VENDOR_PRODUCT_TYPE}
                  id="vendorAndProductType"
                  onChange={handleSelectChange}
                />
              </Stack.Item>
            </>
          )}
        </Stack.Item>
      </Stack>
      {shipData &&
        shipData.by === constant.VENDOR &&
        shipData.setting &&
        shipData.setting.map((value, index) => {
          return consignmentByVendor(value, index);
        })}
      {shipData &&
        shipData.by === constant.PRODUCT_TYPE &&
        shipData.setting &&
        shipData.setting.map((value, index) => {
          return consignmentByProductType(value, index);
        })}
      {shipData &&
        shipData.by === constant.VENDOR_PRODUCT_TYPE &&
        shipData.setting &&
        shipData.setting.map((value, index) => {
          return consignmentByBoth(value, index);
        })}
      {((shipData && shipData.by === constant.VENDOR) ||
        (shipData && shipData.by === constant.PRODUCT_TYPE) ||
        (shipData && shipData.by === constant.VENDOR_PRODUCT_TYPE)) && (
        <div>
          <br />
          <Link id="addLink" onClick={() => addItem()}>
            {`+ ${cms("button.addMore")}`}
          </Link>
        </div>
      )}
    </div>
  );
};

ConsignmentShip.propTypes = {
  method: PropTypes.string.isRequired,
};

export default withErrorBoundary(ConsignmentShip);
