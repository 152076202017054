import { gql } from "apollo-boost";

const GET_PRODUCT_LAKE_LIST = gql`
  query getProductLakeList($input: GetListInputV1) {
    getProductLakeList(input: $input) {
      status
      error
      data {
        count
        tempProductList {
          _id
          createdAt
          updatedAt
          published
          ecommercePlatform
          isLiveConnect
          name
          status
          type
          primaryUrl {
            imageUrl
            imageId
            sortOrder
          }
          variants
          ownerId
          providerProductId
          vendor {
            brandName
            _id
          }
          vendorShopifyProductId
        }
      }
    }
  }
`;

export default GET_PRODUCT_LAKE_LIST;
