import React from "react";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import changePassword
import { ChangePassword } from "app/userManagement/modules/generic";

// import constant
import constant from "lib/constant/constant";

const ProviderChangePassword = () => {
  return <ChangePassword />;
};

export default withFeature(withErrorBoundary(ProviderChangePassword), { feature: constant.CHANGE_PASSWORD });
