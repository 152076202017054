import React, { useEffect, useState, useContext } from "react";
import { Tabs, Card } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { SkeletonCard } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { ProductProvider } from "app/productLake/modules/generic/context";

// import tab
import EditImage from "app/productLake/modules/generic/features/form/image";
import EditVariant from "app/productLake/modules/generic/features/form/variant";
import EditInventory from "app/productLake/modules/generic/features/form/inventory";
import EditFulfillment from "app/productLake/modules/generic/features/form/fulfillment";
import EditAttachment from "app/productLake/modules/generic/features/form/attachment";

// import tab prop
import { tabProp } from "app/productLake/modules/generic/propTypes";
import editData from "app/productLake/modules/provider/features/edit/config/editData";
import EditInformation from "./information";

const EditTabs = (props) => {
  const { setBanner, setDescription, setTitle, isVariant, isVariable, refetch, productValue } = props;
  const { cms, currentUser, history, isLoading } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { search } = history.location;
  const { tab } = baseHelper.getQueryParams(search);
  const { ATTACHMENT, FULFILLMENT, IMAGE, INFORMATION, INVENTORY, VARIANT } = constant;
  const validProductTabs = editData(cms, isVariant, isAttachmentVisible, isVariable).tabs;
  const [selected, setSelected] = useState("");
  const [value, setValue] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (productValue) {
      setValue({
        ...productValue,
      });
    }
  }, [productValue]);

  useEffect(() => {
    const validtab = validProductTabs.map((item) => item.id);
    const tabName = validtab.includes(tab) ? tab : INFORMATION;
    setSelected(tabName);
    if (!validtab.includes(tab)) {
      baseHelper.setUrl(history, { tab: tabName });
    }
  }, [INFORMATION, history, selected, tab, validProductTabs]);

  useEffect(() => {
    let index = validProductTabs.findIndex((item) => item.id === tab);
    if (validProductTabs.length < index) {
      index = 0;
    }
    setSelectedTabIndex(index);
  }, [tab, validProductTabs]);

  if (isLoading) {
    return <SkeletonCard />;
  }

  const handleTabChange = (tabIndex) => {
    const tabId = validProductTabs[tabIndex].id;
    setSelected(tabId);
    baseHelper.setUrl(history, { tab: tabId });
  };

  const getTab = (tabSelected) => {
    let lastTab = isAttachmentVisible ? ATTACHMENT : FULFILLMENT;
    if (isVariant) {
      lastTab = isAttachmentVisible ? ATTACHMENT : VARIANT;
    }
    const tabObj = { islastTab: tabSelected === lastTab };

    switch (tabSelected) {
      case INFORMATION:
        return <EditInformation />;
      case INVENTORY:
        return <EditInventory />;
      case IMAGE:
        return <EditImage />;
      case VARIANT:
        return <EditVariant tabObj={tabObj} />;
      case FULFILLMENT:
        return <EditFulfillment tabObj={tabObj} />;
      case ATTACHMENT:
        return <EditAttachment tabObj={tabObj} />;
      default:
        return <EditInformation />;
    }
  };
  return (
    <>
      <Card>
        <Tabs tabs={validProductTabs} selected={selectedTabIndex} onSelect={handleTabChange} />
      </Card>
      <ProductProvider
        value={{
          handleTabChange,
          isVariant,
          isVariable,
          refetch,
          setBanner,
          setDescription,
          setTitle,
          productValue: value,
          refetchProduct: refetch,
        }}
      >
        <div className="productComponent">{getTab(selected)}</div>
      </ProductProvider>
    </>
  );
};

EditTabs.propTypes = tabProp.type;

export default withErrorBoundary(EditTabs);
