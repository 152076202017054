import React from "react";
import { RadioButton } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";

const ClickAndCollect = (props) => {
  const { cms, onHandleChange, value } = props;
  return (
    <RadioButton
      label={cms("label.clickCollect")}
      helpText={cms("helpText.clickCollect")}
      id="clickCollect"
      checked={value === constant.COLLECT}
      onChange={() => onHandleChange(constant.COLLECT)}
    />
  );
};

ClickAndCollect.propTypes = {
  value: PropTypes.string.isRequired,
  cms: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ClickAndCollect), { feature: constant.CLICK_COLLECT });
