function actionData(cms) {
  return {
    filterData: {
      label: cms("label.filterPayment"),
      filters: [
        {
          key: "vendor",
          label: cms("label.brandName"),
          operatorText: cms("label.like"),
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/orders/view/",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: "Vendor (Asc)", value: "vendor_asc" },
      { label: "Vendor (Desc)", value: "vendor_desc" },
    ],
    options: [
      { label: cms("label.invoice"), value: "invoice" },
      { label: cms("label.payVendor"), value: "payVendor" },
    ],
    resourceName: {
      singular: "vendor",
      plural: "vendors",
    },
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],

    badge: ["accountingStatus"],

    tableData: {
      tbody: [],
    },
  };
}
export default actionData;
