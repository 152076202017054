import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { withFeature, withErrorBoundary } from "lib/hoc";
import { CarrierService } from "app/setup/modules/provider/features";

const { userKey } = constant;
const { provider } = userKey;

const Carrier = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === provider) {
      return <CarrierService />;
    }
    return null;
  };
  const manageCarrierList = getComponent(currentUserRole);
  return manageCarrierList;
};

export default withFeature(withErrorBoundary(Carrier), { feature: constant.CARRIER });
