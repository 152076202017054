import { AddProductMajor, ImportStoreMajor, OnlineStoreMajor, StoreMajor, ExportMinor } from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.export"),
        id: "exportOrder",
        label: cms("common.actions.orders.label.export"),
        icon: ExportMinor,
        link: "/orders",
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.export"),
        id: "exportPayment",
        label: cms("common.actions.payments.label.export"),
        icon: ExportMinor,
        link: "/payments",
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.add"),
        id: "addProduct",
        label: cms("common.actions.products.label.add"),
        icon: AddProductMajor,
        primary: true,
        link: "/products/add",
      },
      {
        caption: cms("common.actions.products.caption.edit"),
        id: "editProducts",
        label: cms("common.actions.products.label.edit"),
        icon: AddProductMajor,
        primary: true,
        link: "/products/edit",
      },
      {
        caption: cms("common.actions.products.caption.importVendor"),
        id: "importConnectProduct",
        label: cms("common.actions.products.label.importVendor"),
        icon: ImportStoreMajor,
        link: "/products/add",
      },
      {
        caption: cms("common.actions.products.caption.ecommerce"),
        id: "connectStoreProduct",
        label: cms("common.actions.products.label.ecommerce"),
        icon: OnlineStoreMajor,
        link: "/products/shopify",
      },
      {
        caption: cms("common.actions.products.caption.connectShop"),
        id: "connectShop",
        label: cms("common.actions.products.label.connectShop"),
        icon: StoreMajor,
        link: "/products/add/shop",
      },
    ],
    "/product-lake": [
      {
        caption: cms("common.actions.products.caption.add"),
        id: "addProduct",
        label: cms("common.actions.products.label.add"),
        primary: true,
        icon: AddProductMajor,
        link: "/product-lake/add",
      },
    ],
  };
};

export default config;
