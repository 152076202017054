import { GET_TERM } from "app/setup/apollo/queries";

import {
  CONNECT_PAYPAL_VENDOR,
  CONNECT_STRIPE_VENDOR,
  CREATE_CUSTOM_STRIPE_VENDOR,
  DISCONNECT_PAYPAL_VENDOR,
  DISCONNECT_STRIPE_VENDOR,
  VERIFY_STRIPE_VENDOR,
} from "./connect";

import {
  CONNECT_PAYPAL_SELLER,
  CONNECT_STRIPE,
  DISCONNECT_PAYPAL,
  DISCONNECT_STRIPE,
  TOGGLE_AUTO_PAYOUT,
} from "./paymentService";

import ACCEPT_ORDER_EXPIRY from "./acceptOrderExpiry";
import ADD_SELLER_PLAN from "./addSellerPlan";
import ADVANCE_ACCEPT_ORDER_EXPIRY from "./advanceAcceptanceOrderExpiry";
import ADVANCE_PICKUP_ORDER_EXPIRY from "./advancePickupOrderExpiry";
import { DELETE_USER_DATABASE_SETTING, UPDATE_USER_DATABASE_SETTING } from "./userDatabaseSetting";
import { DELETE_ORDER_ACS_SETTING, UPDATE_ORDER_ACS_SETTING } from "./orderACSSetting";
import EDIT_SELLER_PLAN from "./editSellerPlan";
import ORDER_EXPIRY from "./orderExpiry";
import PICKUP_ORDER_EXPIRY from "./pickupOrderExpiry";
import PRODUCT_FORM from "./productForm";
import STANDARD_DISCOUNT_SETTING from "./discountSetting";
import SET_ORDER_COMMISSION from "./setOrderCommission";
import UPDATE_BRANDING from "./branding";
import UPDATE_COMMISSION from "./commission";
import UPDATE_COMMISSION_TAX_SETTING from "./updateCommissionTaxSetting";
import UPDATE_CREDIT_NOTE_SETTING from "./updateCreditNoteSetting";
import UPDATE_DISCOUNT from "./discount";
import UPDATE_DYNAMIC_DISCOUNT_SETTING from "./manageSellerDiscountSetting";
import UPDATE_DISCOUNT_CODE_SETTING from "./manageSellerDiscountCodeSetting";
import UPDATE_EXPRESS_PAYMENT_SETTING from "./updateExpressPaymentSetting";
import UPDATE_FEATURE from "./feature";
import UPDATE_FULFILLMENT, { UPDATE_SELLER_FULFILLMENT } from "./fulfillment";
import UPDATE_INVOICE_ACCESS_SETTING from "./updateInvoiceSettingAccess";
import UPDATE_INVOICE_PREFIX_NUMBER from "./updateInvoiceNumberPrefix";
import UPDATE_INVOICE_CUSTOM_NOTE from "./updateInvoiceCustomNote";
import UPDATE_INVOICE_LABEL_SETTING from "./updateInvoiceLabelSetting";
import UPDATE_MANAGE_FULFILLMENT_SETTING from "./updateManageFulfillmentSetting";
import UPDATE_FULFILLMENT_TRACKING_DETAILS_SETTING from "./updateFulfillmentTrackingDetailsSetting";
import UPDATE_MARKUP from "./markup";
import UPDATE_ORDER_EXPORT_SETTING from "./orderExportSetting";
import UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING from "./updateMarkupDiscountExportSetting"
import UPDATE_ORDER_LIVE_CONNECT_SETTING from "./updateOrderLiveConnectSetting";
import UPDATE_ORDER_REPORT_SETTING from "./orderReportSetting";
import UPDATE_PAYMENT_EXPORT_SETTING from "./paymentExportSetting";
import UPDATE_PAYMENT_REPORT_SETTING from "./paymentReportSetting";
import UPDATE_PAYOUT from "./currencyPayout";
import UPDATE_PRODUCT_REPORT_SETTING from "./productReportSetting";
import UPDATE_PRODUCT_EXPORT_SETTING from "./productExportSetting";
import UPDATE_RATE from "./currencyRate";
import UPDATE_SELLER_LOCATION from "./updateSellerLocation";
import UPDATE_SHIPPING_TAX_SETTING from "./updateShippingTaxSetting";
import UPDATE_TAG_SETTING from "./updateTagSetting";
import UPDATE_TRANSACTION_FEE_SETTING from "./updateTransactionFeeSetting";
import UPDATE_TAX_RATE_SETTING from "./updateTaxRateSetting";
import UPDATE_VENDOR_DISCOUNT_SETTING from "./manageVendorDiscountSetting";
import UPDATE_VENDOR_EXPORT_SETTING from "./vendorExportSetting";
import UPDATE_VENDOR_FULFILLMENT from "./updateVendorFulfillment";
import UPDATE_GEOLOCATION_SETTING from "./updateGeolocationSetting";
import UPLOAD_IMAGE from "./image";
import { ADD_ADDRESS, EDIT_ADDRESS, EDIT_DEFAULT, DELETE_ADDRESS } from "./addAddress";
import { UPDATE_PACKING_SLIP_ACCESS, UPDATE_PACKING_SLIP_LABEL } from "./updatePackingSlipLabelSetting";
import { UPDATE_SELLER_SETTING } from "./sellerSetting";
import { UPDATE_TERM } from "./terms";
import SYNC_LOCATION from "./syncLocation";
import UPDATE_VENDOR_LOCATION from "./updateVendorLocation";
import CUSTOMIZE_FONT from "./customize";

export {
  ACCEPT_ORDER_EXPIRY,
  ADD_ADDRESS,
  ADD_SELLER_PLAN,
  ADVANCE_ACCEPT_ORDER_EXPIRY,
  ADVANCE_PICKUP_ORDER_EXPIRY,
  CONNECT_PAYPAL_SELLER,
  CONNECT_PAYPAL_VENDOR,
  CONNECT_STRIPE,
  CONNECT_STRIPE_VENDOR,
  CREATE_CUSTOM_STRIPE_VENDOR,
  CUSTOMIZE_FONT,
  DELETE_ADDRESS,
  DELETE_USER_DATABASE_SETTING,
  DELETE_ORDER_ACS_SETTING,
  DISCONNECT_PAYPAL,
  DISCONNECT_PAYPAL_VENDOR,
  DISCONNECT_STRIPE,
  DISCONNECT_STRIPE_VENDOR,
  EDIT_ADDRESS,
  EDIT_DEFAULT,
  EDIT_SELLER_PLAN,
  GET_TERM,
  ORDER_EXPIRY,
  PICKUP_ORDER_EXPIRY,
  PRODUCT_FORM,
  STANDARD_DISCOUNT_SETTING,
  SET_ORDER_COMMISSION,
  SYNC_LOCATION,
  TOGGLE_AUTO_PAYOUT,
  UPDATE_BRANDING,
  UPDATE_COMMISSION,
  UPDATE_COMMISSION_TAX_SETTING,
  UPDATE_CREDIT_NOTE_SETTING,
  UPDATE_DISCOUNT,
  UPDATE_DYNAMIC_DISCOUNT_SETTING,
  UPDATE_DISCOUNT_CODE_SETTING,
  UPDATE_EXPRESS_PAYMENT_SETTING,
  UPDATE_FEATURE,
  UPDATE_FULFILLMENT,
  UPDATE_FULFILLMENT_TRACKING_DETAILS_SETTING,
  UPDATE_GEOLOCATION_SETTING,
  UPDATE_INVOICE_ACCESS_SETTING,
  UPDATE_INVOICE_PREFIX_NUMBER,
  UPDATE_INVOICE_CUSTOM_NOTE,
  UPDATE_INVOICE_LABEL_SETTING,
  UPDATE_MANAGE_FULFILLMENT_SETTING,
  UPDATE_MARKUP,
  UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING,
  UPDATE_ORDER_ACS_SETTING,
  UPDATE_ORDER_EXPORT_SETTING,
  UPDATE_ORDER_LIVE_CONNECT_SETTING,
  UPDATE_ORDER_REPORT_SETTING,
  UPDATE_PACKING_SLIP_ACCESS,
  UPDATE_PACKING_SLIP_LABEL,
  UPDATE_PAYMENT_EXPORT_SETTING,
  UPDATE_PAYMENT_REPORT_SETTING,
  UPDATE_PAYOUT,
  UPDATE_PRODUCT_REPORT_SETTING,
  UPDATE_PRODUCT_EXPORT_SETTING,
  UPDATE_RATE,
  UPDATE_SELLER_FULFILLMENT,
  UPDATE_SELLER_LOCATION,
  UPDATE_SELLER_SETTING,
  UPDATE_SHIPPING_TAX_SETTING,
  UPDATE_TAG_SETTING,
  UPDATE_TAX_RATE_SETTING,
  UPDATE_TERM,
  UPDATE_TRANSACTION_FEE_SETTING,
  UPDATE_USER_DATABASE_SETTING,
  UPDATE_VENDOR_DISCOUNT_SETTING,
  UPDATE_VENDOR_EXPORT_SETTING,
  UPDATE_VENDOR_FULFILLMENT,
  UPLOAD_IMAGE,
  UPDATE_VENDOR_LOCATION,
  VERIFY_STRIPE_VENDOR,
};
