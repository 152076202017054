import { gql } from "apollo-boost";

const GET_VENDOR_BRAND_LIST = gql`
  query {
    getVendorBrandList {
      data {
        isInactive
        brandName
        _id
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_BRAND_LIST;
