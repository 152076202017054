import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

import { SkeletonAnnotated } from "lib/components";

import { storageHelper } from "lib/helpers";

import GenericExportReport from "./genericExport/export";

const { userKey } = constant;

const { operator, provider } = userKey;

const ExportReport = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }

    if (role === operator) {
      return <GenericExportReport />;
    }

    if (role === provider) {
      return <GenericExportReport />;
    }

    return null;
  };

  const currentUserRole = storageHelper.get(constant.USER_ROLE);

  const ExportReportComponent = getComponent(currentUserRole);

  return ExportReportComponent;
};

export default ExportReport;
