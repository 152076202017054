import { gql } from "apollo-boost";

const EDIT_PRODUCT_INFORMATION = gql`
  mutation editProductInformation($input: EditProductInformation) {
    editProductInformation(input: $input) {
      status
      data
      error
    }
  }
`;
export default EDIT_PRODUCT_INFORMATION;
