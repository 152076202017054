import { gql } from "apollo-boost";

const GET_PRODUCT_AND_SELLER_PRODUCT = gql`
  query getProductAndSellerProduct($input: GetProductSeller) {
    getProductAndSellerProduct(input: $input) {
      data {
        product {
          _id
          attachments {
            fileURL
          }
          barcode
          status
          price
          inventoryManagement
          isShipping
          isTaxable
          sku
          customMetafields {
            key
            name
            value
          }
          weightUnit
          comparePrice
          customInfo {
            wholesalePrice
          }
          weight
          handle
          comparePrice
          isTaxable
          isShipping
          productType
          quantity
          shopifyCatalogId
          vendor
          weight
          weightUnit
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
          title
          description
          tags
          productType
          images {
            imageUrl
          }
          updatedAt
          variants {
            _id
            option1Val
            option2Val
            option3Val
            option3
            option1
            option2
            barcode
            weightUnit
            comparePrice
            customInfo {
              wholesalePrice
            }
            image
            imageUrl
            inventoryManagement
            inventoryQuantity
            isShipping
            isTaxable
            price
            position
            sku
            weight
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
          }
        }
        sellerProduct {
          _id
          attachments {
            fileURL
          }
          barcode
          customMetafields {
            key
            value
          }
          status
          price
          inventoryManagement
          isShipping
          isTaxable
          sku
          customMetafields {
            key
            name
            value
          }
          weightUnit
          comparePrice
          customInfo {
            wholesalePrice
          }
          weight
          handle
          comparePrice
          isTaxable
          isShipping
          productType
          quantity
          vendor
          weight
          weightUnit
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
          title
          description
          tags
          productType
          images {
            imageUrl
          }
          shopifyCatalogId
          variants {
            option1Val
            option2Val
            option3Val
            option3
            option1
            option2
            barcode
            variantId
            weightUnit
            comparePrice
            customInfo {
              wholesalePrice
            }
            image
            imageUrl
            inventoryManagement
            inventoryQuantity
            isShipping
            isTaxable
            price
            position
            sku
            weight
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
          }
        }
      }
      error
      status
    }
  }
`;

export default GET_PRODUCT_AND_SELLER_PRODUCT;
