import React, { useCallback, useEffect, useContext, useState } from "react";
import { Button, Caption, Card, FormLayout, Modal, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import constant
import constant from "lib/constant/constant";

// import components
import { DropZone, Sheet, SkeletonCard, Toast } from "lib/components";

// import mutations
import { ADD_PRODUCT_IMAGE, DELETE_PRODUCT_IMAGE, UPLOAD_IMAGE } from "app/product/apollo/mutations";

// import helpers
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";

//  import queries
import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context";
import { ProductContext } from "app/product/modules/generic/context";
import { Div, Span } from "app/product/modules/generic/style/style";

const EditImage = () => {
  const { handleTabChange, isVariant, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { cms } = useContext(PrivateContext);
  const { match } = useContext(PrivateContext);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState({});
  const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [loadingId, setLoadingId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isRearrange, setIsRearrange] = useState(false);
  const [imageStartUploading, setImageStartUploading] = useState(false);
  const [imageToBeUploaded, setImageToBeUploaded] = useState([]);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);
  const [existingProductImages, setExistingProductImages] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [rearrange, setRearrange] = useState([...existingProductImages]);
  const [addProductImage, { loading: addImageLoading }] = useMutation(ADD_PRODUCT_IMAGE);
  const [deleteImage, { loading: deleteImageLoading }] = useMutation(DELETE_PRODUCT_IMAGE);
  const { CRITICAL, gql } = constant;
  setDescription(cms("label.editImage"));
  setTitle(cms("label.title.image"));
  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      setValue({
        ...value,
        [fieldName]: fieldValue,
      });
    },
    [value]
  );

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      const { sellerProduct } = productResponse;
      const { title, images, productId, updatedAt, status } = sellerProduct;
      const product = {
        title,
        images,
        productId,
        updatedAt,
        status,
      };
      setValue(product);
      setExistingProductImages(
        (productResponse && productResponse.sellerProduct && productResponse.sellerProduct.images) || []
      );
    }
  }, [gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productListError });
    }
  }, [CRITICAL, cms, productListError, setBanner]);

  useEffect(() => {
    setRearrange([...existingProductImages]);
  }, [existingProductImages]);

  useEffect(() => {
    if (value && value.images && value.images.length) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [value]);

  if (productLoading) {
    return <SkeletonCard />;
  }

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const handleAddImage = (data) => {
    const productImages = (value && value.images) || [];
    const uploadedImages = [...productImages, ...data];
    setImageToBeUploaded([...imageToBeUploaded, ...data]);
    handleChange(constant.IMAGES, uploadedImages);
  };

  const removeImage = (index, buttonIndex) => {
    const productImages = value.images || [];
    if (index < 0 || productImages.length <= 0) {
      return;
    }
    const exitingProductImageLength = existingProductImages && existingProductImages.length;
    imageToBeUploaded.splice(buttonIndex - exitingProductImageLength, 1);
    setImageToBeUploaded(imageToBeUploaded);
    productImages.splice(buttonIndex, 1);
    handleChange(constant.IMAGES, productImages);
  };

  const addImageData = async (formValues, productId) => {
    try {
      const val = await addProductImage({
        variables: {
          input: formValues,
        },
      });

      const resData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_IMAGE);
      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_IMAGE);
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
      setIsOpen(false);
      setIsRearrange(false);
      if (resData && productId) {
        setMessage(cms("message.success.image"));
      }
      if (resData && !productId) {
        setMessage(cms("message.success.image"));
        setTimeout(() => {
          if (isVariant) {
            handleTabChange(2);
            return;
          }
          handleTabChange(3);
        }, 1500);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const imageUpload = (formField) => {
    const formValues = formField || {};
    const { images = [] } = formValues || {};
    let hasImageError = false;
    if (images && images.length) {
      const uploadedImages = [];
      const uploadImageCallback = (imageResponse) => {
        const { imageUrl = null, imageId = null } = imageResponse;
        if (imageUrl) {
          const uploadedImageData = {
            imageUrl,
            imageId,
          };

          uploadedImages.push(uploadedImageData);
        }
        if (hasImageError) {
          return;
        }
        if (uploadedImages && uploadedImages.length > 0 && uploadedImages.length === images.length) {
          setImageStartUploading(false);
          formValues.images = uploadedImages;
          addImageData(formValues);
        }
      };
      formValues.images.map(async (img) => {
        try {
          if (hasImageError) {
            return;
          }
          let response = {};
          if (typeof img === "string") {
            response = await uploadImage({ variables: { input: { image: img, productId: match.params.id } } });
          } else {
            uploadImageCallback(img);
            return;
          }

          if (!imageStartUploading) {
            setImageStartUploading(true);
          }
          const imageResponse = baseHelper.getResponseData(response.data, gql.UPLOAD_IMAGE);
          const imageError = baseHelper.getResponseError(response.data, gql.UPLOAD_IMAGE);
          if (imageError) {
            setImageStartUploading(false);
            hasImageError = true;
            setBanner((prev) => ({
              ...prev,
              action: null,
              isOpen: true,
              status: CRITICAL,
              title: imageError,
            }));
          }
          uploadImageCallback(imageResponse);
        } catch (uploadImageError) {
          setImageStartUploading(false);
          hasImageError = true;
          setBanner((prev) => ({
            ...prev,
            action: null,
            isOpen: true,
            status: CRITICAL,
            title: cms("label.notSave"),
          }));
        }
      });
      setImageStartUploading(false);
    } else {
      addImageData(formValues);
    }
    return null;
  };

  const handleDeleteImage = (image) => {
    setMessage("");
    const { productId = "" } = value;
    const { position = 0, url = "" } = image;

    if (!position || url === "") {
      return;
    }

    const formData = {
      id: position || "",
      productId,
      url,
    };
    setLoadingId(position);
    deleteImage({ variables: { input: formData } })
      .then((res) => {
        setLoadingId("");
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_PRODUCT_IMAGE);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_PRODUCT_IMAGE);
        if (responseData) {
          setMessage(cms("message.success.imageDelete"));
          let productImages = [...existingProductImages];
          productImages = productImages
            .map((img) => {
              if (img.position < position) {
                return img;
              }
              const pos = img.position - 1;
              const imagePosition = img;
              imagePosition.position = pos;
              return img.position - position + 1 !== 0 ? img : null;
            })
            .filter((item) => item);
          setExistingProductImages(productImages);
          setValue({ ...value, images: [...productImages, ...imageToBeUploaded] });
        }
      })
      .catch((exception) => {
        setLoadingId("");
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const onRearrangeSubmit = () => {
    const { productId = "" } = value;
    const images = rearrange.map(({ imageId, imageUrl }) => ({ imageId, imageUrl }));
    setValue({ ...value, images });
    addImageData({ productId, images }, productId);
    setExistingProductImages([...rearrange]);
  };

  const onFormSubmit = async () => {
    const { images: formImage = [], productId = "" } = value;
    try {
      const formValues = {
        productId,
        images: formImage,
      };
      await imageUpload(formValues);
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      }));
    }
  };

  const renderModal = () => {
    const images = rearrange;
    let dragId = "";
    const imageDragOver = (event) => event.preventDefault();

    const imageDragStart = (event) => {
      dragId = event.target.id;
    };

    const dropImage = (event) => {
      event.preventDefault();
      const dragElement = dragId.split("-");
      let dragIndex = "";
      if (dragElement.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        dragIndex = dragElement[0];
      }

      const dropElement = event.target.id.split("-");
      let dropIndex = "";
      if (dropElement.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        dropIndex = dropElement[0];
      }
      if (dragIndex !== "" && dropIndex !== "") {
        setIsRearrange(true);
        const dragObject = images[dragIndex];
        images.splice(dragIndex, 1);
        images.splice(dropIndex, 0, dragObject);
        setRearrange([...images]);
      }
    };
    return (
      // OnDrop and onDragOver is not supported by polaris stack component.
      <Div onDrop={dropImage} onDragOver={imageDragOver}>
        <Stack>
          {images.map(({ imageUrl }, index) => (
            <Stack.Item>
              <Span className="Polaris-Thumbnail Polaris-Thumbnail--sizeLarge">
                {/* OnDragStart is not supported by polaris thumbnail component. */}
                <img
                  onDragStart={imageDragStart}
                  id={`${index}-img`}
                  draggable
                  src={imageHelper.resize({ url: imageUrl, type: constant.imageTypes.THUMBNAIL })}
                  alt={`${index}-img`}
                />
              </Span>
            </Stack.Item>
          ))}
        </Stack>
      </Div>
    );
  };
  return (
    <>
      <Card
        title={[
          cms("label.productImage"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productImage"
        actions={[
          {
            content: existingProductImages && existingProductImages.length > 1 && cms("modal.rearrange"),
            onAction: () => existingProductImages && existingProductImages.length > 1 && setIsOpen(true),
            disabled: !(existingProductImages && existingProductImages.length > 1),
          },
        ]}
        // actions={[
        //   {
        //     content: cms("label.more"),
        //     onAction: () => {
        //       learnMore("productImage", cms("label.productImage"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.caption.image")}</p>
          <br />
          <Modal
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setIsRearrange(false);
              setRearrange([...existingProductImages]);
            }}
            title={cms("modal.rearrange")}
            secondaryActions={{
              content: cms("button.cancel"),
              disabled: isRearrange && addImageLoading,
              onAction: () => {
                setIsOpen(false);
                setIsRearrange(false);
                setRearrange([...existingProductImages]);
              },
            }}
            primaryAction={{
              content: cms("modal.button"),
              disabled: !isRearrange,
              loading: isRearrange && addImageLoading,
              onAction: onRearrangeSubmit,
            }}
            sectioned
          >
            <Modal.Section>{renderModal()}</Modal.Section>
          </Modal>
          <FormLayout>
            <TextContainer>{cms("section.form.section.price.description")}</TextContainer>
            <DropZone
              id="addProductDropZone"
              onAdd={handleAddImage}
              onRemove={removeImage}
              size={10}
              allowMultiple
              existingImageList={existingProductImages || []}
              removeExistingImage={handleDeleteImage}
              loadingPosition={loadingId}
              setDisabledButton={setDisabledButton}
              disabled={addImageLoading || deleteImageLoading || imageLoading}
            />
          </FormLayout>
          <div className="toast">
            <Toast message={message} setToast={setMessage} />
          </div>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button
            onClick={() => {
              const tab = isVariant ? 0 : 1;
              handleTabChange(tab);
            }}
          >
            {cms("button.previous")}
          </Button>
        </Stack.Item>
        {value && value.status && value.status.toLowerCase() === constant.NEW && (
          <Stack.Item>
            <Button onClick={() => (isVariant ? handleTabChange(2) : handleTabChange(3))}>{cms("button.skip")}</Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={deleteImageLoading || disabledButton}
            loading={!isRearrange && (addImageLoading || imageLoading)}
          >
            {cms("button.save")}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content: cms("button.save"),
          disabled: deleteImageLoading || disabledButton,
          onAction: () => onFormSubmit(),
          loading: addImageLoading || imageLoading,
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => {
              const tab = isVariant ? 0 : 1;
              handleTabChange(tab);
            },
          },
        ]}
      /> */}
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

export default EditImage;
