/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Caption, Card, FormLayout, PageActions, Select, TextField, TextStyle } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import mutation
import { UPDATE_PRODUCT_LAKE_INFORMATION } from "app/productLake/apollo/mutations";

// import queries
import { GET_VENDOR_BRAND_LIST } from "app/productLake/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";

const EditInformation = () => {
  const { handleTabChange, isVariant, setBanner, setDescription, setTitle, productValue, refetchProduct } = useContext(
    ProductContext
  );
  const { currentUser, cms, match } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [value, setValue] = useState(productValue);

  setDescription(cms("label.editInformation"));
  setTitle(cms("label.title.information"));

  const { BIGCOMMERCE, CRITICAL, gql } = constant;

  const { loading: brandListLoading, data: brandListData } = useQuery(GET_VENDOR_BRAND_LIST);

  const [updateProduct, { loading: editProductLoading }] = useMutation(UPDATE_PRODUCT_LAKE_INFORMATION);

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.TITLE, constant.DESCRIPTION];
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  useEffect(() => {
    if (brandListData && productValue && productValue.providerIds) {
      const { providerIds } = productValue;
      const vendorsDetials = baseHelper.getResponseData(brandListData, gql.GET_VENDOR_BRAND_LIST) || {};
      const productVendor = vendorsDetials.filter((vendor) => vendor._id === providerIds[0]);
      setValue({
        ...productValue,
        vendor: productVendor[0]?.brandName,
      });
    }
  }, [brandListData, gql.GET_VENDOR_BRAND_LIST, brandListLoading, productValue]);

  if (brandListLoading) {
    return <SkeletonCard />;
  }

  const primaryAction = {
    content: cms("button.save"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const onFormSubmit = async () => {
    const formValues = {
      name: value.name,
      description: value.description,
      // price: {
      //   price: value.price || "0.00",
      //   costPrice: value.costPrice || "0.00",
      // },
      type: value.type,
      productId: match.params.id,
    };

    if (value.type === constant.SIMPLE && isVariant) {
      setBanner({ isOpen: true, status: CRITICAL, title: cms("message.error.type") });
      return false;
    }

    await updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_LAKE_INFORMATION);
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_LAKE_INFORMATION);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.information"));
          refetchProduct();
          setBanner({ isOpen: false, status: "", title: "" });
          setTimeout(() => {
            handleTabChange(1);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
    return true;
  };

  const descriptionLabel = cms("label.description");

  let typeOptions = [
    { label: "Simple", value: "simple" },
    // { label: "Grouped", value: "grouped" },
    // { label: "External", value: "external" },
    { label: "Variable", value: "variable" },
  ];

  if (currentUser.ecommercePlatform === BIGCOMMERCE) {
    typeOptions = [
      { label: "Physical", value: "physical" },
      { label: "Digital", value: "digital" },
    ];
  }

  const { priceId } = value || {};

  const { regularPrice, price, costPrice, salePrice } = priceId || {};

  return (
    <>
      <Card
        title={[
          cms("label.productInformation"),
          value && value.updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
        id="productInformation"
      >
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              required="true"
              onChange={(val) => {
                handleChange(constant.NAME, val);
                setDisabledButton(false);
              }}
              error=""
              value={value.name || ""}
              onBlur={() => value && value.name && handleChange(constant.NAME, value.name.trim())}
            />
            {descriptionLabel}
            <Editor
              id="idTiny"
              textareaName={`${cms("label.description")}`}
              value={value.description || ""}
              onEditorChange={(val) => handleChange(constant.DESCRIPTION, val)}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  id="price"
                  label={[
                    `${cms("label.price")}`,
                    // <span className="currency-badge">
                    //   <Badge status="info">{cms("label.currency")}</Badge>
                    // </span>,
                  ]}
                  min={0}
                  value={
                    (baseHelper.isBigCommerceUser(currentUser)
                      ? price && price.toString()
                      : regularPrice && regularPrice.toString()) || ""
                  }
                  placeholder={constant.ZERO_PRICE}
                  prefix={moneyFormat}
                  disabled
                />
                <TextField
                  id="comparePrice"
                  label={`${cms("label.comparePrice")}`}
                  min={0}
                  placeholder={constant.ZERO_PRICE}
                  prefix={moneyFormat}
                  value={
                    baseHelper.isBigCommerceUser(currentUser)
                      ? costPrice && costPrice.toString()
                      : (salePrice && salePrice.toString()) || ""
                  }
                  disabled
                />
              </FormLayout.Group>
            </FormLayout>
            <TextField label={`${cms("label.vendor")}`} value={value.vendor || ""} disabled />
            <Select
              label={cms("label.type")}
              placeholder={cms("placeholder.selectProductType")}
              options={typeOptions}
              value={value.type}
              onChange={(val) => {
                handleChange(constant.TYPE, val);
                setDisabledButton(false);
              }}
            />
            <br />
          </FormLayout>
          <div className="fulfillment_button">
            <PageActions
              primaryAction={{
                content: cms("common.button.update"),
                onAction: () => onFormSubmit(),
                loading: editProductLoading,
                disabled: !(value && value.name) || disabledButton,
              }}
            />
          </div>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

export default withErrorBoundary(EditInformation);
