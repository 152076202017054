import React from "react";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import generic components
import { ContactUs } from "app/userManagement/modules/generic";

const ProviderContactUs = () => {
  return <ContactUs />;
};

export default withFeature(withErrorBoundary(ProviderContactUs), { feature: constant.SUPPORT });
