import { gql } from "apollo-boost";

const GET_ORDER_EXPIRY_SETTING = gql`
  query {
    getOrderExpirySetting {
      data {
        order {
          accept {
            duration
            updatedAt
          }
          pickup {
            duration
            updatedAt
          }
          advanceAccept {
            isActive
            advance {
              vendorId
              duration
            }
            updatedAt
          }
          advancePickup {
            isActive
            advance {
              vendorId
              duration
            }
            updatedAt
          }
          orderExpirySetting {
            isWeekend
            updatedAt
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_ORDER_EXPIRY_SETTING;
