import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Card,
  FormLayout,
  Frame,
  Heading,
  Layout,
  Modal,
  PageActions,
  Stack,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import { useMutation, useQuery } from "react-apollo";
// import context
import { PrivateContext } from "lib/context";

import { DELETE_ORDER_ACS_SETTING, UPDATE_ORDER_ACS_SETTING } from "app/setup/apollo/mutations";
import { GET_ORDER_ACS_SETTING } from "app/setup/apollo";

import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner, Toast } from "lib/components";

import validate from "./yup";

const OperatorOrderACSSetting = () => {
  const [values, setValues] = useState({ userName: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { _id: sellerId = "" } = currentUser || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const { data: acsData, error: acsError, loading: acsLoader, refetch } = useQuery(GET_ORDER_ACS_SETTING);

  const acsDataResponse = baseHelper.getResponseData(acsData, constant.gql.GET_ORDER_ACS_SETTING);
  const acsDataError = baseHelper.getResponseError(acsData, constant.gql.GET_ORDER_ACS_SETTING);

  useEffect(() => {
    if (acsDataResponse) {
      setValues({ userName: acsDataResponse.userName, password: acsDataResponse.password });
      setUpdatedAt(acsDataResponse.updatedAt);
      if (acsDataResponse.userName && acsDataResponse.password) {
        setIsDelete(true);
      }
      refetch();
      setIsLoading(false);
    }
    if (acsDataError) {
      setBanner({ status: constant.CRITICAL, title: acsDataError, isOpen: true });
    }
    if (acsError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(acsError), isOpen: true });
    }
  }, [acsDataError, acsDataResponse, acsError, refetch]);

  const [updateACSSetting, { loading: updateLoading }] = useMutation(UPDATE_ORDER_ACS_SETTING);
  const [deleteACSSetting, { loading: resetLoading }] = useMutation(DELETE_ORDER_ACS_SETTING);

  const handleChange = useCallback((key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value.trim(),
    }));
    setIsLoading(true);
  }, []);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleSubmit = useCallback(
    async (userName, password, acsUpdatedAt) => {
      setIsLoading(false);
      try {
        const inputData = {
          userName,
          password,
        };
        const response = await updateACSSetting({
          variables: { input: inputData },
        });
        const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_ORDER_ACS_SETTING);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }

        const key = acsUpdatedAt ? "message.success.update" : "message.success.save";

        setMessage(cms(key));

        refetch();
      } catch (exception) {
        baseHelper.getResponseError(exception, constant.gql.UPDATE_ORDER_ACS_SETTING);
      }
    },
    [cms, refetch, updateACSSetting]
  );

  const onSubmit = () => {
    const { updatedAt: acsUpdatedAt } = acsDataResponse;
    handleSubmit(values.userName, values.password, acsUpdatedAt);
  };

  const handleModal = useCallback(async () => {
    try {
      const response = await deleteACSSetting({
        variables: { input: { sellerId } },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.DELETE_ORDER_ACS_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      setMessage(cms("message.success.delete"));
      setErrorMessage("");
      setActive(false);

      refetch();
      setIsDelete(false);
    } catch (exception) {
      baseHelper.getResponseError(exception, constant.gql.DELETE_ORDER_ACS_SETTING);
    }
  }, [deleteACSSetting, sellerId, cms, refetch]);

  const renderViewIcon = () => (
    <Button id="eyeButton" plain icon={visible ? HideMinor : ViewMinor} onClick={() => setVisible(!visible)} />
  );

  if (acsLoader) {
    return <Spinner />;
  }

  const buttons = [
    {
      content: cms("common.button.cancel"),
      onAction: () => history.push("/setting"),
    },
  ];

  if (isDelete) {
    buttons.push({
      destructive: true,
      content: cms("section.button.delete"),
      onAction: () => setActive(true),
    });
  }

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection
        title={cms("title")}
        description={<TextStyle variation="subdued">{cms("description")}</TextStyle>}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <Heading variation="strong">{cms("section.acs.title")}</Heading>
            <br />
            {updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            )}
            <br />
            <TextStyle>{cms("section.acs.description")}</TextStyle>
          </div>
          <br />
          <>
            <FormLayout>
              <TextField
                id="userName"
                value={values.userName || ""}
                onChange={(value) => handleChange("userName", value)}
                onBlur={() => handleValidation("userName", values.userName)}
                label={`${cms("section.form.userName.label")}*`}
                name="userName"
                error={errorMessage && errorMessage.userName}
                autoComplete="off"
              />
              <TextField
                id="password"
                value={values.password || ""}
                onChange={(value) => handleChange("password", value)}
                onBlur={() => handleValidation("password", values.password)}
                label={`${cms("section.form.password.label")}*`}
                type={visible ? "text" : "password"}
                name="password"
                error={errorMessage && errorMessage.password}
                suffix={renderViewIcon()}
                autoComplete="off"
              />
            </FormLayout>
            <PageActions
              primaryAction={{
                content:
                  acsDataResponse && acsDataResponse.userName && acsDataResponse.password
                    ? cms("common.button.update")
                    : cms("common.button.submit"),
                onAction: onSubmit,
                disabled: !(
                  values &&
                  isLoading &&
                  values.userName &&
                  values.password &&
                  (values.userName !== acsDataResponse.userName || values.password !== acsDataResponse.password)
                ),
                loading: updateLoading,
              }}
              secondaryActions={buttons}
            />
          </>
        </Card>

        <Modal
          open={active}
          onClose={() => setActive(false)}
          title={cms("section.modal.title")}
          primaryAction={{
            content: cms("section.modal.button.yes"),
            onAction: () => {
              handleModal();
            },
            loading: resetLoading,
          }}
          secondaryActions={[
            {
              content: cms("section.modal.button.no"),
              onAction: () => setActive(false),
            },
          ]}
        >
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <TextContainer>{cms("section.modal.text")}</TextContainer>
              </Stack.Item>
            </Stack>
          </Modal.Section>
        </Modal>

        <Layout.Section />
      </Layout.AnnotatedSection>
    </>
  );
};

export default OperatorOrderACSSetting;
