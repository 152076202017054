import React from "react";

// import generic
import ExportPDF from "./generic/feature/export/exportPDF";

const ExportVendorAsPDF = () => {
  const getComponent = () => {
    return <ExportPDF />;
  };
  const ExportVendorComponent = getComponent();
  return ExportVendorComponent;
};

export default ExportVendorAsPDF;
