import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { Card, FormLayout, Layout } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import queries
import { GET_ALL_METRICS } from "app/reporting/apollo/queries";
import { productMock, salesMock } from "app/reporting/modules/generic/config/mock";
import { BarGraph, LineGraph, PieGraph, StackedBarGraph } from "app/reporting/modules/generic/graph";

// import props
import { tabProp } from "app/reporting/modules/operator/props";

// import component
import { Popover } from "app/reporting/modules/generic/components";

const { MONTHS } = constant;

const AllReportsTab = (props) => {
  const { reportingConfig } = props;
  const [timePeriod, setTimePeriod] = useState(180);
  const [productGraphData, setProductGraphData] = useState([]);
  const [orderGraphData, setOrderGraphData] = useState([]);

  const [selectedDates, setSelectedDates] = useState();

  const handleTimePeriod = (value) => {
    setTimePeriod(value);
    setSelectedDates(null);
  };

  const [getReporting, { data: allMetricsGraphData }] = useLazyQuery(GET_ALL_METRICS);

  useEffect(() => {
    const response = baseHelper.getResponseData(allMetricsGraphData, constant.gql.GET_ALL_METRICS);
    if (response) {
      const orderResponse = response.order.map((item) => ({ ...item, month: MONTHS[item.month - 1] }));
      const productResponse = response.product.map((item) => ({ ...item, month: MONTHS[item.month - 1] }));
      setOrderGraphData(orderResponse);
      setProductGraphData(productResponse);
    }
  }, [allMetricsGraphData]);

  useEffect(() => {
    const submitQuery = () => {
      const inputData = {
        days: parseInt(timePeriod, 10),
        dateRange: selectedDates,
      };
      getReporting({
        variables: {
          input: inputData,
        },
      });
    };
    if (timePeriod || selectedDates) submitQuery();
  }, [getReporting, selectedDates, timePeriod]);

  const submitReportingQuery = (value) => {
    setSelectedDates(value);
    setTimePeriod(null);
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <div style={{ top: "4px", position: "relative" }}>
                  <Popover
                    timePeriod={timePeriod}
                    timeByOptions={reportingConfig?.timeByOptions || []}
                    handleTimePeriod={handleTimePeriod}
                    submitReportingQuery={submitReportingQuery}
                    text="June 1-Aug 1, 2020 "
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                  />
                </div>
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Card title="Products Metric">
          <Card.Section>
            <BarGraph data={productGraphData || []} xDataKey="month" barDataKey="count" width={450} height={250} />
          </Card.Section>
        </Card>
        <Card title="Payments Metric">
          <Card.Section>
            <LineGraph data={salesMock} xDataKey="name" dataKey1="pv" dataKey2="uv" />
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Card title="Orders Metric">
          <Card.Section>
            <PieGraph
              data={orderGraphData || []}
              dataKey="count"
              labelSuffix="month"
              width={400}
              height={250}
              cx={200}
              cy={120}
              outerRadius={115}
            />
          </Card.Section>
        </Card>
        <Card title="Vendors Metric">
          <Card.Section>
            <StackedBarGraph data={productMock} dataKey1="product" dataKey2="revenue" />
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

AllReportsTab.propTypes = tabProp.type;

export default AllReportsTab;
