const vendorProduct = {
  title: {
    variant: "Variants",
    vendorProduct: "Add or import your products",
    manualForm: "Add products using our form",
    csvForm: "Bulk add products using a CSV",
    image: "Image",
    inventory: "Inventory",
    price: "Pricing",
    shipping: "Shipping",
    weight: "WEIGHT",
    organisation: "Organisation",
    availableOption: "Available Options",
    productError: "There is some error with this product.",
  },
  content: {
    imageNote: "Images can be added to variant only after variant has been added",
    pleaseNote: "Please note: ",
    addVariant: "Add variant",
    multipleVersion: "Add variants if this product comes in multiple versions, like different sizes or colors. ",
    cancel: "Cancel",
    option1: "Size",
    option2: "Color",
    option3: "Material",
    vendorProduct: [
      "You can add products manually, one by one, using our form",
      "You can also bulk add products by uploading a CSV file",
    ],
    manualForm: `You can also import products by connecting to a Shopify store. 
      You can add products manually, one by one, using our form. 
      You can also bulk add products by uplaoding a CSV file`,
    csvForm: `If you have a CSV file that was exported from another store or came from another source, 
      make sure it matches the CSV file format. If the format doesn't match, or if there are any missing headers, 
      then the import will fail.`,
  },
  label: {
    optionValue: "Option Values",
    optionName: "Option Name",
    organisation: "Organisation",
    sku: "SKU",
    variant: "Variant",
    barcode: "Barcode",
    productadditionOptions: ["Add products using our form", "Add products in bulk using a CSV"],
    vendor: "vendor*",
    productType: "Product Type",
    productTag: "Product Tags",
    inventory: {
      sku: "SKU (Stock Keeping Unit)",
      barcode: "Barcode (ISBN, UPC, GTIN, etc.)",
      policy: "Inventory policy",
      quantity: "Quantity",
    },
    price: "Price",
    comparePrice: "Compare at price",
    title: "Title*",
    shipping: {
      checkbox: "This is a physical product",
      weight: "Weight",
      weightUnit: "Weight unit",
    },
  },
  placeHolder: {
    chooseProducts: "Choose how you would like to add products",
    commaEnter: "Separate options with a comma/enter",
    price: "Rs. 0.00",
    comparePrice: "Rs.",
    title: "Product title",
    pleaseSelect: "-- Please Select --",
    selectTag: "Select Tags",
  },
  csvForm: {
    button: "Download a Sample CSV",
    csvText: "Please Select a CSV*",
  },
  message: {
    createAnotherProduct: "Create another product",
    fillField: "Please fill all the required fields, denoted by a * after the label",
    invalidCharacters: `Invalid handle. Values allowed are a-z, A-Z, 0-9, -, _ only and 
      no spaces are allowed e.g. "Product-1" or "product_two-1"`,
    alreadyUsed: "You've already used this option value",
    mptyOptionValue: "Variant values can't be empty",
    productAddStatus: "Product was created successfully. What’s next?",
  },
  error: {
    title: "Please enter title",
    vendor: "Please select vendor",
    mandatoryField: "please fill all mandatory(*) fields",
  },
  shippingText: "Used to calculate shipping rates at checkout.",
  options: {
    policySelection: [
      { label: "Don't track inventory", value: "Don't track inventory" },
      { label: "Shopify tracks this product's inventory", value: "shopify" },
    ],
  },
};

export default vendorProduct;
