function listData(cms) {
  return {
    sortOptions: [
      { label: cms("common.label.sortOption.dateAsc"), value: "createdAt_asc" },
      { label: cms("common.label.sortOption.dateDesc"), value: "createdAt_desc" },
    ],
    resourceName: {
      singular: cms("label.bulkActivity"),
      plural: cms("label.bulkActivity"),
    },
    activityMessage: {
      supplierinvite: cms("label.bulkInvitation"),
      supplierinvitecsv: cms("label.supplierInviteCSV"),
      product: cms("label.product"),
      supplierupload: cms("label.supplierUpload"),
      "bulk supplier invitation": cms("label.bulkInvitation"),
      "shopify products import": cms("label.shopifyImport"),
      paystripe: cms("label.payStripe"),
      paypaypal: cms("label.payPaypal"),
    },
    tabsData: [
      {
        id: "all-orders",
        content: cms("label.tabs.all"),
        accessibilityLabel: "All orders",
        panelID: "all-orders-content",
        value: "all",
      },
      {
        id: "pending-acceptance",
        content: cms("label.tabs.error"),
        panelID: "pending-acceptance-content",
        value: "error",
      },
      {
        id: "pending-shipment",
        content: cms("label.tabs.success"),
        panelID: "pending-shipment-content",
        value: "success",
      },
    ],
  };
}
export default listData;
