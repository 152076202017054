import { gql } from "apollo-boost";

const GET_CURRENT_USER = gql`
  {
    getCurrentUser {
      status
      data {
        roles {
          name
        }
        shop
        userLevel
        isProductDiscovery
      }
    }
  }
`;

export default GET_CURRENT_USER;
