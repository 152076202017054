import { gql } from "apollo-boost";

const ADD_PRODUCT_AS_SERVICE_INFORMATION = gql`
  mutation addProductInformation($input: AddProduct) {
    addProductInformation(input: $input) {
      status
      data
      error
    }
  }
`;

// const ADD_PRODUCT_METAFIELD = gql`
//   mutation addProductMetafield($input: AddProductMetafield) {
//     addProductMetafield(input: $input) {
//       status
//       data
//       error
//     }
//   }
// `;

const ADD_SERVICE_AVAILABILITY = gql`
  mutation addProductService($input: ServiceFieldInput) {
    addProductService(input: $input) {
      status
      data {
        _id
      }
      error
    }
  }
`;

const ADD_SERVICE_PRODUCT_IMAGE = gql`
  mutation addProductImage($input: AddProductImage) {
    addProductImage(input: $input) {
      status
      data
      error
    }
  }
`;

const ADD_SERVICE_VARIANT = gql`
  mutation addServiceVariant($input: AddServiceVariantInput) {
    addServiceVariant(input: $input) {
      status
      data {
        id
        position
      }
      error
    }
  }
`;

const CREATE_SERVICE_OPTION = gql`
  mutation createServiceOption($input: CreateServiceOptionInput) {
    createServiceOption(input: $input) {
      status
      data
      error
    }
  }
`;

const EDIT_SERVICE_VARIANT = gql`
  mutation editServiceVariant($input: EditServiceVariantInput) {
    editServiceVariant(input: $input) {
      data {
        id
        position
      }
      status
      error
    }
  }
`;

const EDIT_SERVICE_PRODUCT = gql`
  mutation editProductService($input: ServiceFieldInput) {
    editProductService(input: $input) {
      status
      error
      data {
        _id
        capacity
      }
    }
  }
`;

export {
  ADD_SERVICE_PRODUCT_IMAGE,
  ADD_PRODUCT_AS_SERVICE_INFORMATION,
  ADD_SERVICE_AVAILABILITY,
  ADD_SERVICE_VARIANT,
  EDIT_SERVICE_PRODUCT,
  EDIT_SERVICE_VARIANT,
  CREATE_SERVICE_OPTION,
};
