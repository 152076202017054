import React, { useContext, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import helper
import { baseHelper } from "lib/helpers";

// import queries
import { GET_PRODUCT_LAKE } from "app/productLake/apollo/queries";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";

// import tab
import EditTabs from "./features/form/tab";

const ProductEdit = () => {
  const { currentUser, isLoading, match } = useContext(PrivateContext);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const { CRITICAL, VARIABLE } = constant;
  const [productValue, setProductValue] = useState();
  const [isVariant, setIsVariant] = useState(false);
  const [isVariable, setIsVariable] = useState(true);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const { loading: productLoading, data: productData, refetch } = useQuery(GET_PRODUCT_LAKE, {
    variables: { input: { id: match.params.id } },
  });

  useEffect(() => {
    const productResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCT_LAKE);
    const productLakeError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCT_LAKE);
    if (!productLoading && productResponse) {
      setProductValue(productResponse);
      const { variants, type } = productResponse || {};
      if (variants) {
        setIsVariant(variants.length);
      }
      if (currentUser.ecommercePlatform === constant.WOOCOMMERCE) {
        setIsVariable(type === VARIABLE);
      }
    }
    if (productLakeError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productLakeError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, productLoading]);

  if (isLoading || productLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={title} description={description}>
        <EditTabs
          setBanner={setBanner}
          setDescription={setDescription}
          setTitle={setTitle}
          isVariant={isVariant}
          isVariable={isVariable}
          refetch={refetch}
          setIsVariant={setIsVariant}
          setIsVariable={setIsVariable}
          productValue={productValue}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProductEdit), { feature: constant.PRODUCT_UPDATE });
