import React, { useContext } from "react";
import { Badge, Banner, Card, TextField, FormLayout, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

// import constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

const Price = (props) => {
  const { cms } = useContext(PrivateContext);
  const { FLAT, symbol } = constant;

  const {
    acceptOnlyValidInput = () => {},
    basePricing,
    currencyPrice,
    currencySymbol,
    handleChange = () => {},
    handleCurrencyPrice,
    moneyFormat,
    setDisabledButton,
    variantData = {},
  } = props;

  const {
    price = 0,
    comparePrice = 0,
    discount = { price: 0, type: FLAT },
    vendorDiscount: variantVendorDiscount,
  } = variantData;

  const vendorDiscount = variantVendorDiscount || discount;

  const options = [
    { label: cms("section.price.label.flat"), value: constant.FLAT },
    { label: cms("section.price.label.percentage"), value: constant.PERCENTAGE },
  ];

  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= 100) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
  };

  const renderPricingComponent = () => {
    if (!basePricing || !basePricing.currencyCode) return null;
    const { price: currencyBasePrice = 0, comparePrice: currencyCompareBasePrice = 0, currencyCode } =
      basePricing || {};
    return (
      <FormLayout.Group>
        <TextField
          id="price"
          label={cms("section.price.label.price")}
          min={0}
          value={(currencyBasePrice && currencyBasePrice.toString()) || ""}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          onChange={(val) => {
            handleCurrencyPrice(constant.PRICE, acceptOnlyValidInput(val, currencyBasePrice), currencyCode);
            setDisabledButton(false);
          }}
        />
        <TextField
          id="comparePrice"
          label={cms("section.price.label.compare")}
          min={0}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          value={(currencyCompareBasePrice && currencyCompareBasePrice.toString()) || ""}
          onChange={(val) => {
            handleCurrencyPrice(
              constant.COMPARE_PRICE,
              acceptOnlyValidInput(val, currencyCompareBasePrice),
              currencyCode
            );
            setDisabledButton(false);
          }}
        />
      </FormLayout.Group>
    );
  };

  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || constant.FLAT}
      placeholder={cms("section.price.placeholder.type")}
      label={cms("section.price.label.type")}
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );
  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      {basePricing && basePricing.currencyCode && currencyPrice ? (
        <>
          <Banner status={constant.INFO} isOpen>
            {`${cms("label.rate")} ${moneyFormat}1 = ${currencySymbol}${currencyPrice}`}
          </Banner>
          <br />
        </>
      ) : null}
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label={[
              cms("section.price.label.price"),
              <span className="currency-badge">
                <Badge status="info">{cms("section.price.label.currency")}</Badge>
              </span>,
            ]}
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={currencyPrice}
            onChange={(value) => {
              handleChange(constant.PRICE, acceptOnlyValidInput(value, price));
              setDisabledButton(false);
            }}
          />
          <TextField
            id="comparePrice"
            label={cms("section.price.label.compare")}
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={currencyPrice}
            onChange={(value) => {
              handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(value, comparePrice));
              setDisabledButton(false);
            }}
          />
        </FormLayout.Group>
        {basePricing && basePricing.currencyCode && currencyPrice ? renderPricingComponent() : null}
        <FormLayout.Group>
          <TextField
            id="discount"
            label={cms("section.price.label.discount")}
            min="0"
            max={(vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && 100) || 9999}
            value={(vendorDiscount && vendorDiscount.price && vendorDiscount.price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            onChange={(value) => {
              handlePriceChange(
                constant.VENDOR_DISCOUNT,
                vendorDiscount,
                { price: acceptOnlyValidInput(value, vendorDiscount && vendorDiscount.price) },
                vendorDiscount.type
              );
              setDisabledButton(false);
            }}
            connectedRight={selectWeight(constant.VENDOR_DISCOUNT, vendorDiscount)}
            suffix={vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && symbol.PERCENTAGE}
            prefix={vendorDiscount && vendorDiscount.type === constant.FLAT && (moneyFormat || symbol.DOLLAR)}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  acceptOnlyValidInput: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Price;
