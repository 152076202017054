import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  ResourceItem,
  TextStyle,
  Tooltip,
  Stack,
  Link,
  Caption,
  Badge,
  Tag,
  Card,
  ChoiceList,
  Thumbnail,
  FormLayout,
  TextField,
  Modal,
} from "@shopify/polaris";
import { ViewMajor, QuickSaleMajor, ExportMinor } from "@shopify/polaris-icons";
import { useQuery, useMutation } from "react-apollo";

import { QuickReport, QuickAction } from "app/reports";
import { SingleColumnLayout } from "layout/private/components";
import { PrivateContext } from "lib/context";
import { ResourceList, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

import { FETCH_ORDER_LINE_LIST } from "app/orders/apollo/queries";
import { baseHelper, imageHelper, errorHelper } from "lib/helpers";
import MoreFilters from "app/orders/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";
import { updateOrderLineTracking } from "app/orders/apollo/updateOrderNote";
import { ORDER_LIST } from "app/orders/apollo/subscriptions/index";

import AdminExportOrder from "app/orders/modules/admin/features/export/index";
import listData from "./orderListData";

const AdminOrderList = () => {
  const { cms, history, setPageData } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { gql, FULFILLED, ACCEPTED } = constant;
  const [isExportModal, setIsExportModal] = useState(false);
  const [load, setLoad] = useState(true);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const { mainLink, links, filterByStatusOption } = listData(cms);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [orderList, setOrderList] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [activePopover, setActivePopover] = useState({});
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [trackingModal, setTrackingModal] = useState({});
  const [popoverDisable, setPopoverDisable] = useState(false);

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(perPage, 10) || 10,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: order, loading: orderLoading, error: orderError, subscribeToMore, refetch } = useQuery(
    FETCH_ORDER_LINE_LIST,
    {
      variables: {
        input: inputData,
      },
    }
  );

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );
  const size = constant.LARGE;
  useEffect(() => {
    if (load) {
      setPageData({
        title: "Orders",
        primaryAction: {
          content: "Export Order",
          onAction: () => setIsExportModal(!isExportModal),
          id: "exportOrder",
        },
      });
      setLoad(!load);
    }
  }, [isExportModal, load, setPageData]);
  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(orderError),
      });
    }
  }, [orderError]);
  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderLineList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );

      if (updateOrderTracking) {
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: cms("message.success.orderFulfilled"),
          isOpen: true,
        });
        refetch();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataUpdateOrderLineTracking, cms, refetch]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  const linkData = [
    {
      action: () => setIsExportModal(true),
      caption: "Export your orders",
      id: "exportOrder",
      label: cms("section.orderList.label.exportOrder"),
      icon: ExportMinor,
    },
  ];

  const getSecondaryComponent = () => {
    return (
      <>
        <QuickReport />
        <QuickAction link={linkData} />
      </>
    );
  };

  const handleSelect = (item) => {
    setSelectedItems(item);
  };

  useEffect(() => {
    subscribeToMore({
      document: ORDER_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newOrder = subscriptionData.data.orderLineView.data.orderLine;
        const prevOrder = prev.getOrderLineList.data;
        const OrderData = [newOrder, ...prevOrder.orderLineList];
        const totalCount = OrderData.length;
        return [setOrderList(OrderData), setOrderCount(totalCount)];
      },
    });
  }, [subscribeToMore]);
  const pageContent = {
    label: {
      status: cms("section.orderList.label.status"),
      fulfillmentStatus: cms("section.orderList.label.fulfillmentStatus"),
    },
  };

  const renderItem = (item) => {
    const {
      _id: id,
      createdAt,
      financialStatus = constant.financialStatus.PENDING,
      fulfillmentStatus,
      image,
      orderId,
      orderNumber,
      shopifyLineItemId,
      status,
      title,
      vendor,
      vendorId,
    } = item;
    const addTrackingDetails = () => {
      if (!initialTrackingCompany) {
        setTrackingModal(false);
        setBanner({
          isOpen: true,
          title: cms("section.orderList.label.trackingCompanyRequired"),
          status: constant.CRITICAL,
        });
        return false;
      }

      if (!initialTrackingNumber) {
        setTrackingModal(false);
        setBanner({
          isOpen: true,
          title: cms("section.orderList.label.trackingNumberRequired"),
          status: constant.CRITICAL,
        });
        return false;
      }

      const data = {
        trackingNumber: initialTrackingNumber.trim(),
        trackingCompany: initialTrackingCompany.trim(),
      };
      data.id = orderId;
      data.lineItemId = id;
      updateOrderLine({ variables: { input: data } });
      return null;
    };

    const acceptOnlyValidInput = (value) => {
      return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
    };

    const fulfillment = () => {
      return (
        <Modal
          open={trackingModal[shopifyLineItemId]}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber("");
            setInitialTrackingCompany("");
          }}
          title={cms("section.orderList.label.addTrackingDetails")}
          primaryAction={{
            content: cms("section.orderList.label.saveTrackingDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={cms("section.orderList.label.trackingCompany")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(acceptOnlyValidInput(val));
                }}
              />
              <TextField
                label={cms("section.orderList.label.trackingNumber")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(acceptOnlyValidInput(val));
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      );
    };

    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={size} />;
    const topMargin10 = {
      marginTop: "10px",
    };

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajor,
        onAction: () => history.push(`${mainLink.orderNumber}${orderId}`),
      },
    ];
    const fullfillOption = {
      content: cms("section.orderList.button.fulfillLineItem"),
      icon: QuickSaleMajor,
      onAction: () => setTrackingModal({ [shopifyLineItemId]: !trackingModal[shopifyLineItemId] }),
      disabled: fulfillmentStatus === FULFILLED || status !== ACCEPTED || false,
    };

    if (fulfillmentStatus === FULFILLED || status !== ACCEPTED) {
      option.push(fullfillOption);
    }
    if (!(fulfillmentStatus === FULFILLED || status !== ACCEPTED)) {
      option.unshift(fullfillOption);
    }

    const financialStatusBadge = (financialStatus === constant.PAID && constant.PAID) || constant.CRITICAL;

    return (
      <ResourceItem id={orderId} media={thumbnail} persistActions>
        <Stack alignment="leading">
          <Stack.Item fill Style="width: 200px">
            <TextStyle>
              <Link>
                <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                  <div className="ellipsis">{`#${orderNumber} - ${title}`}</div>
                </Link>
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {` ${cms("section.orderList.label.createdOn")}: ${baseHelper.formatDate(createdAt)}`}
              </TextStyle>
            </Caption>
            <Caption>
              {`${cms("section.orderList.label.vendor")}: `}
              <Link onClick={() => history.push(`${links.vendor.link}${vendorId}`)}>{vendor}</Link>
            </Caption>

            <div style={topMargin10}>
              <Stack>
                <Stack.Item>
                  <Tooltip content={pageContent.label.status} preferredPosition="above">
                    <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(status)}</Badge>
                  </Tooltip>
                </Stack.Item>
                <Stack.Item>
                  <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                    <Badge status={baseHelper.getBadgeType(fulfillmentStatus)}>
                      {baseHelper.ucFirst(fulfillmentStatus)}
                    </Badge>
                  </Tooltip>
                </Stack.Item>
                {financialStatus &&
                  <Stack.Item>
                    <Tooltip content={pageContent.label.status} preferredPosition="above">
                      <Badge status={baseHelper.getBadgeType(financialStatusBadge)}>{baseHelper.getFinancialStatus(financialStatus)}</Badge>
                    </Tooltip>
                  </Stack.Item>
                }
              </Stack>
            </div>
          </Stack.Item>
          {fulfillment()}
          <Stack.Item>
            <Popover
              active={activePopover[shopifyLineItemId]}
              setActive={() =>
                popoverDisable
                  ? setActivePopover(false)
                  : setActivePopover({ [shopifyLineItemId]: !activePopover[shopifyLineItemId] })
              }
              options={option}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`section.orderList.label.filter.${value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];

  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const appliedFilters = appliedFilterArrObj;

  const filters = [
    {
      key: "taggedWith",
      label: cms("section.orderList.label.filterBy"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };
  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <AdminExportOrder isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        {getSecondaryComponent()}
        <Card>
          <ResourceList
            items={orderList}
            renderItem={renderItem}
            loading={loading || orderLoading}
            queryValue={search}
            onQueryClear={handleQueryClear}
            onQueryChange={handleSearchChange}
            sortOptions={listData(cms).sortOptions}
            sortValue={sortValue}
            onSortChange={handleSort}
            selectedItems={selectedItems}
            onSelectionChange={handleSelect}
            bulkActions={listData(cms).bulkAction}
            filters={filters}
            handleClearAll={handleClearAll}
            appliedFilters={appliedFilters}
            count={orderCount}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPopoverDisable={setPopoverDisable}
            setPerPage={setPerPage}
          />
          {/* </Tabs> */}
        </Card>
      </>
    );
  };
  return <SingleColumnLayout primary={getComponent()} />;
};

export default withErrorBoundary(AdminOrderList);
