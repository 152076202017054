import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Badge,
  Button,
  Caption,
  Card,
  Heading,
  Icon,
  Layout,
  Link,
  Page,
  Stack,
  TextContainer,
  TextStyle,
  // Tooltip,
} from "@shopify/polaris";

// import page content
import { greyIcon, blueInfoIcon } from "app/public/icon/icon";

import { PublicContext } from "lib/context";
import { Spinner } from "lib/components";
// import config from "configuration";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";
import Banner from "lib/components/banner/banner";

// import gql
import { FETCH_PLANS } from "app/plans/apollo/queries";
import { REGISTER_BIGCOMMERCE_SELLER } from "app/public/apollo/mutations/registerBigCommerceSeller";

// import constant
import constant from "lib/constant/constant";

const { gql } = constant;

const BigCommerceInstall = () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const { cms, history } = useContext(PublicContext);

  useEffect(() => {
    submitPlan();
  }, []);

  const [registerBigCommerceSeller, { loading: registerBigCommerceSellerLoading }] = useMutation(
    REGISTER_BIGCOMMERCE_SELLER
  );

  const submitPlan = async (element) => {
    const queryParams = baseHelper.getQueryParams(history.location.search) || {};
    const { code, context, scope } = queryParams;
    const isBigCommerceSeller = code && context && scope;

    if (isBigCommerceSeller) {
      const data = {
        code,
        context,
        scope,
        // planId: baseHelper.mongoIdAsString(element._id),
      };
      const response = await registerBigCommerceSeller({ variables: { input: data } });
      const responseData = baseHelper.getResponseData(response.data, gql.REGISTER_BIGCOMMERCE_SELLER);
      if (!(responseData && responseData.parentToken)) {
        setBanner({ isOpen: true, status: "critical", title: cms("message.sellerRegisterError") });
        return true;
      }
      storageHelper.set("token", responseData.parentToken);
      history.push("/");
    } else {
      setBanner({ isOpen: true, status: "critical", title: cms("message.sellerRegisterError") });
    }
    return true;
  };

  if (registerBigCommerceSellerLoading) {
    return (
      <div>
        <Spinner fontSize={42} />
        <h3 className="text-center">{cms("message.wait")}</h3>
      </div>
    );
  }

  const onDismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
      {/* <Page>
        <Layout>
          <Layout.Section>
            <div className="Polaris-Page__AppInstall">{renderPlans(plans)}</div>
          </Layout.Section>
          <Layout.Section>
            <div className="Polaris-Page__AppInstallFooter">
              <TextStyle variation="subdued">
                {cms("message.byProceeding")} 
                <Link for="/">
                  {cms("message.termsOfService")}
                </Link>
                <br />
                {cms("message.subjectToGovt")}
              </TextStyle>
            </div>
          </Layout.Section>
        </Layout>
      </Page> */}
    </>
  );
};

export default BigCommerceInstall;
