import { gql } from "apollo-boost";

const SYNC_VENDOR_WIX_PRODUCT = gql`
  mutation syncVendorWixProduct($input: BulkAction!) {
    syncVendorWixProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_VENDOR_WIX_PRODUCT;
