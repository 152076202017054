import PropTypes from "prop-types";

const offerProp = {
  type: {
    setBanner: PropTypes.func,
    isVendorAllowed: PropTypes.bool,
  },
};

const listProp = {
  bands: PropTypes.arrayOf(PropTypes.object),
  destinations: PropTypes.arrayOf(PropTypes.object),
  isVendorAllowed: PropTypes.bool,
  loading: PropTypes.bool,
  offers: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  setBanner: PropTypes.func,
};

const itemProp = {
  moneyFormat: PropTypes.string,
  item: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object),
  destinations: PropTypes.arrayOf(PropTypes.object),
  bands: PropTypes.arrayOf(PropTypes.object),
  sVendorAllowed: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
};

const addOfferProp = {
  bands: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  destinations: PropTypes.arrayOf(PropTypes.object),
  handleBanner: PropTypes.func,
  onClose: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  shippingOffer: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object])
  ),
  show: PropTypes.bool,
};

const offerConditionProp = {
  shippingOffer: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object])
  ),
  isConditionApplied: PropTypes.bool,
  moneyFormat: PropTypes.string,
  handleCriteria: PropTypes.func,
  handleCondition: PropTypes.func,
};

const editModalProp = addOfferProp;

export { offerProp, listProp, itemProp, addOfferProp, editModalProp, offerConditionProp };
