import React, { useState, useContext, useEffect, useCallback } from "react";
import ResourceList from "lib/components/resourceList/resourceList";
import { Layout, FormLayout, TextField, Select } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  GET_SHIPPING_BAND,
  GET_SHIPPING_REGION,
  GET_SHIPPING_OFFER,
  GET_SHIPPING_PRODUCT,
} from "app/shipping/apollo/queries";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import components
import { AddOffers, DeleteOffers, EditOffers } from "./modal";
import OfferItem from "./modal/offerItem";

// import proptype
import { providerProp } from "./propsType";

const ProviderOffers = () => {
  const { setBannerOuter, isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser, setPageData, pageData = null, cms } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const { gql, DESTINATION, PRODUCT } = constant;
  const [queryValue, setQueryValue] = useState("");
  const [filterRecord, setFilterRecord] = useState([]);
  const [offers, setOffers] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [deleteId, setDeleteId] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [shippingBandId, setShippingBandId] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [taggedWith, setTaggedWith] = useState("");
  const [shippingOffersData, setShippingOffersData] = useState([]);

  const [destinationFilter, setDestinationFilter] = useState();
  const [productFilter, setProductFilter] = useState();
  const { loading: bandLoading, data: bandData, error: bandError } = useQuery(GET_SHIPPING_BAND);
  const { loading: regionLoading, data: regionData, error: regionError } = useQuery(GET_SHIPPING_REGION);
  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_PRODUCT);
  const {
    loading: offersLoading,
    data: offersData,
    error: offersError,
    refetch: OfferRefetch,
    networkStatus,
  } = useQuery(GET_SHIPPING_OFFER, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (bandLoading || offersLoading || productLoading || regionLoading || networkStatus === 4) setLoading(true);
    else {
      setLoading(false);
    }
  }, [bandLoading, productLoading, offersLoading, regionLoading, networkStatus, setBannerOuter]);

  const queryLoading = offersLoading || bandLoading || regionLoading || productLoading || loading;
  const isError = offersError || bandError || regionError || productError;

  const toggleAddModal = useCallback(() => {
    setAddModal(!addModal);
  }, [addModal]);

  useEffect(() => {
    if (pageData && pageData.primaryAction && pageData.primaryAction.content === cms("modal.offer.add.title")) {
      return;
    }
    setPageData({
      title: cms("title"),
      primaryAction: {
        content: cms("modal.offer.add.title"),
        onClick: () => toggleAddModal(),
        disabled: !isVendorAllowed,
      },
    });
  }, [setPageData, toggleAddModal, cms, pageData, isVendorAllowed]);

  useEffect(() => {
    if (isError) {
      setBannerOuter({
        isOpen: true,
        title: errorHelper.parse(isError),
        status: "critical",
      });
    }
  }, [isError, setBannerOuter]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBannerOuter({
        isOpen: true,
        title: errorMessage,
        status: "critical",
      });
    },
    [setBannerOuter]
  );

  const offersDataError = baseHelper.getResponseError(offersData, gql.GET_SHIPPING_OFFERS);
  useEffect(() => {
    if (offersDataError) {
      setErrorBanner(offersDataError);
    }
    const offersDataResponse = baseHelper.getResponseData(offersData, gql.GET_SHIPPING_OFFERS);
    const { shippingOffers = [] } = offersDataResponse || {};
    setOffers(shippingOffers);
    setFilterRecord(shippingOffers);
  }, [offersDataError, setErrorBanner, offersData, gql.GET_SHIPPING_OFFERS]);

  // Shipping Data
  const bandDataError = baseHelper.getResponseError(bandData, gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (bandDataError) setErrorBanner(bandDataError);
  }, [bandDataError, setErrorBanner]);

  const bandDataResponse = baseHelper.getResponseData(bandData, gql.GET_SHIPPING_BAND);
  const { shippingRows = [] } = bandDataResponse || {};
  const shippingBands = shippingRows.map(({ _id: bandId, name, parentId }) => ({
    bandId,
    label: name,
    parentId,
    value: parentId,
  }));

  // Product
  const productDataError = baseHelper.getResponseError(productData, gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      setErrorBanner(productDataError);
    }
  }, [productDataError, setErrorBanner]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productDataResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCTS);

  // Destinations
  const regionDataError = baseHelper.getResponseError(regionData, gql.GET_REGION);
  useEffect(() => {
    if (regionDataError) {
      setErrorBanner(regionDataError);
    }
  }, [regionDataError, setErrorBanner]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const regionDataResponse = baseHelper.getResponseData(regionData, gql.GET_REGION);

  useEffect(() => {
    if (productDataResponse.length > 0) {
      setProductFilter(productDataResponse);
    }
  }, [productDataResponse]);

  useEffect(() => {
    if (regionDataResponse.length > 0) {
      setDestinationFilter(regionDataResponse);
    }
  }, [regionDataResponse]);

  useEffect(() => {
    const filteredShippingOffers =
      productDataResponse &&
      filterRecord.filter((newFilterRecord) => {
        const isProductCondition = !!newFilterRecord.productId;
        return (
          !isProductCondition || (productDataResponse || []).find((pItem) => pItem.value === newFilterRecord.productId)
        );
      });
    setShippingOffersData(filteredShippingOffers);
  }, [productDataResponse, filterRecord]);

  function handleFiltersChange(appliedFilters) {
    if (!appliedFilters.length) {
      setFilterRecord(offers);
      setQueryValue("");
      return;
    }
    let destinationRowsToShow = [];
    let productRowsToShow = [];
    if (appliedFilters.length) {
      const filterValue = appliedFilters.toLowerCase().trim();
      if (selectedFilter === DESTINATION) {
        let options = [{ value: "", label: "all" }];
        destinationFilter.forEach((item) => {
          options = [...options, ...item.options];
        });
        const filteredDestinations = options.filter((item) => item.label.toLowerCase() === filterValue.toLowerCase());
        destinationRowsToShow = offers.filter((item) =>
          filteredDestinations.find((destination) => destination.value === item.destinationCode)
        );
      }
      if (selectedFilter === PRODUCT) {
        let options = [{ value: "", label: "all" }];
        productFilter.forEach((item) => {
          options = [...options, item];
        });
        const filteredProducts = options.filter((item) => item.label.toLowerCase() === filterValue.toLowerCase());
        productRowsToShow = offers.filter((item) =>
          filteredProducts.find((product) => product.value === item.productId)
        );
      }
      setFilterRecord([...destinationRowsToShow, ...productRowsToShow]);
      setQueryValue("");
    }
  }
  const toggleModal = (item) => {
    setEditModal(!editModal);
    setEditItem(item);
  };

  const toggleDelete = (id, value) => {
    setDeleteId(id);
    setShippingBandId(value);
    setDeleteModal(!deleteModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleQueryValueRemove = () => {
    setQueryValue("");
    setFilterRecord([...offers]);
  };
  const resourceName = {
    singular: constant.OFFER,
    plural: constant.OFFERS,
  };
  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    setTaggedWith("");
    setFilterRecord(offers);
  };

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    handleFiltersChange(value);
  };

  const customFilter = (
    <FormLayout>
      <Select
        label={cms("label.showAllOffer")}
        value={selectedFilter}
        onChange={handleSelectChange}
        options={[
          { label: cms("label.destination"), value: "destination" },
          { label: cms("label.product"), value: "product" },
        ]}
        placeholder={cms("label.selectFilter")}
      />
      {(selectedFilter && (
        <TextField label={cms("label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
      )) ||
        null}
    </FormLayout>
  );

  const handleTaggedWithRemove = (isClearAll = false) => {
    setTaggedWith("");
    if (isClearAll) {
      setSelectedFilter("");
    }
    handleFiltersChange("");
  };

  const handleClearAll = () => {
    setTaggedWith("");
    setSelectedFilter("");
    handleFiltersChange("");
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value}`) || value;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];

  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true),
    });
  }

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filterBy"),
      filter: customFilter,
      shortcut: false,
    },
  ];

  const handleSearchChange = (searchValue) => {
    if (!searchValue.length) {
      setFilterRecord(offers);
      setQueryValue("");
      return;
    }
    const search = searchValue.toLowerCase().trim();
    const updatedOffers = offers.filter((item) => {
      const shippingBand = shippingBands.find((band) => band.value === item.shippingBandId);
      return shippingBand && shippingBand.label && shippingBand.label.toLowerCase().includes(search);
    });
    setQueryValue(searchValue);
    setFilterRecord([...updatedOffers]);
  };
  const renderItem = (item) => {
    if (bandLoading || regionLoading || offersLoading || productLoading) {
      return null;
    }
    return (
      <OfferItem
        moneyFormat={moneyFormat}
        item={item}
        bands={shippingBands}
        destinations={regionDataResponse}
        products={productDataResponse}
        toggleModal={toggleModal}
        toggleDeleteModal={toggleDelete}
      />
    );
  };
  return (
    <>
      <Layout>
        {deleteModal && (
          <DeleteOffers
            deleteModal={deleteModal}
            toggleDeleteModal={toggleDeleteModal}
            deleteId={deleteId}
            shippingBandId={shippingBandId}
            bands={shippingBands}
            refetch={OfferRefetch}
            cms={cms}
          />
        )}
        {editModal && (
          <EditOffers
            editModal={editModal}
            toggleEditModal={toggleEditModal}
            item={editItem}
            bands={shippingBands}
            destinations={regionDataResponse}
            products={productDataResponse}
            moneyFormat={moneyFormat}
            refetch={OfferRefetch}
          />
        )}
        {addModal && (
          <AddOffers
            addModal={addModal}
            destinationData={regionDataResponse}
            shippingBands={shippingBands}
            toggleAddModal={toggleAddModal}
            refetch={OfferRefetch}
            setBannerOuter={setBannerOuter}
            products={productDataResponse}
          />
        )}
        <Layout.Section>
          <ResourceList
            resourceName={resourceName}
            items={shippingOffersData}
            loading={queryLoading}
            renderItem={renderItem}
            onQueryChange={handleSearchChange}
            onQueryClear={handleQueryValueRemove}
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            handleClearAll={handleClearAll}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

ProviderOffers.propTypes = providerProp.type;

export default ProviderOffers;
