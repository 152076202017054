import React from "react";
import { Page, EmptyState } from "@shopify/polaris";

const Suspended = (props) => {
  const { isVendor = false } = props;
  return (
    <Page>
      <EmptyState
        heading="Oops!"
      >
        <p>Your {isVendor ? "operator's account" : "account"} has been suspended because {isVendor ? "their" : "your"} subscription renewal is pending, {isVendor ? "please reach out to your operator." : "to get your platform working again please reach out to support@marketcube.io."}</p>
      </EmptyState>
    </Page>
  );
};

export default Suspended;
