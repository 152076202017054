import React, { useContext, useEffect, useState } from "react";

import { Badge, Caption, Link, ResourceItem, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import { ChatMajor, DeleteMinor, ImageMajor, LogOutMinor, ProfileMajor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

// import from lib
import { baseHelper, imageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import Popover from "lib/components/popover/popover";
import appConfig from "configuration";

// import cms
import cmsVendorList from "app/vendors/modules/operator/feature/list/cms/vendorListCMS";

import { BRAND_LOGO } from "app/advanceVendor/apollo/queries";

const { brandPageURL, brandPageV2URL } = appConfig;

const VendorListView = (props) => {
  const { chatSetting = {}, cms, history, currentUser, initiateChat = () => {} } = useContext(PrivateContext);

  const [profileImage, setProfileImage] = useState();

  const {
    activePopover,
    handleEnableDisable,
    item,
    keyIndex,
    loading,
    onDeleteVendor,
    onLoginVendor,
    popoverDisable,
    setActivePopover,
    setMessageToast,
  } = props;

  const { isVendor: isChatVendor = true } = chatSetting || {};

  const { ecommercePlatform } = currentUser;
  const { _id, brandName, createdAt, email, firstName, isApproved, isInactive, lastName, onHoliday, roles } = item;
  const { address = "", verified } = email || {};
  const { APPROVE, providerList, INFO, HOLIDAY } = constant;
  const { name: role } = roles;
  const { BRANDPAGE, BRAND_PAGE_V2, DELETE, DISABLE, ENABLE, LOGIN } = providerList;

  const vendorListCMS = cmsVendorList(cms);
  const { button, label, message } = vendorListCMS;

  const { paypal, shop: shopifyShop, stripe, storeConfig = {} } = item;
  const { accountId: stripeAccountId = "" } = stripe || {};
  const { emailId: paypalEmail = "", payerId = "" } = paypal || {};
  const { wooCommerce: wooCommerceConfig = {}, wix: wixConfig = {}, magento: magentoConfig = {}, prestaShop: prestaShopConfig } = storeConfig || {};
  const { instanceId: wixInstanceId = "" } = wixConfig || {};
  const { shop: wooShop = "" } = wooCommerceConfig || {};
  const { shop: magentoShop = "" } = magentoConfig || {};
  const { shop: prestaShop = "" } = prestaShopConfig || {};

  const { data: brandLogo } = useQuery(BRAND_LOGO, {
    variables: { input: { vendorId: _id } },
  });

  const vendorLogo = baseHelper.getResponseData(brandLogo, constant.gql.BRAND_LOGO);

  useEffect(() => {
    if (vendorLogo) {
      setProfileImage(vendorLogo?.vendorDetails?.brand?.imageUrl);
    }
  }, [vendorLogo]);

  // eslint-disable-next-line no-shadow
  const onBrandPage = (brandName) => {
    const url = `${brandPageURL}/${providerList.BRAND}/${currentUser.shop}/${brandName}`;
    window.open(url);
  };

  // eslint-disable-next-line no-shadow
  const onBrandV2Page = (brandName) => {
    const url = `${brandPageV2URL}/${providerList.BRAND}/${currentUser.shop}/${brandName}`;
    window.open(url);
  };

  const handleSelectAction = (action) => {
    if (action === BRANDPAGE) {
      onBrandPage(brandName);
    }

    if (action === BRAND_PAGE_V2) {
      onBrandV2Page(brandName);
    }

    if (action === DELETE) {
      onDeleteVendor(_id);
    }
    if (action === LOGIN) {
      onLoginVendor(_id, brandName, role);
    }
    if (action === ENABLE) {
      setMessageToast(message.vendorEnable);
      handleEnableDisable(_id, isInactive);
    }
    if (action === DISABLE) {
      handleEnableDisable(_id, isInactive);
    }
  };

  const handleDisable = () => {
    if (verified && (firstName || lastName)) {
      return false;
    }
    return true;
  };

  const topMargin10px = {
    marginTop: providerList.MARGIN_TOP,
  };

  const handleBadge = () => {
    switch (isApproved || APPROVE) {
      case providerList.IN_REVIEW:
        return { status: label.needReview, value: constant.ATTENTION };
      case providerList.ON_HOLD:
        return { status: label.onHold, value: constant.INFO };
      case providerList.APPROVE:
        return { status: label.approved, value: constant.SUCCESS };
      case providerList.REJECT:
        return { status: label.reject, value: constant.CRITICAL };
      default:
    }
    return {};
  };

  const vendorProfileOption = {
    content: button.profile,
    icon: ProfileMajor,
    onAction: () => history.push({ pathname: `/providers/profile/${item._id}`, state: { detail: item } }),
  };

  const initiateChatOption = isChatVendor && {
    content: cms("button.providerList.providerList.initiateChat"),
    icon: ChatMajor,
    onAction: () => initiateChat({ _id, firstName: `${firstName} ${lastName}`, email: address, profileImage }),
    disabled: handleDisable(),
  };

  const lakeApproveOptions = [
    vendorProfileOption,
    {
      content: button.loginVendor,
      icon: LogOutMinor,
      onAction: () => handleSelectAction(LOGIN),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const approveOptions = [
    vendorProfileOption,
    initiateChatOption,
    {
      content: button.loginVendor,
      icon: LogOutMinor,
      onAction: () => handleSelectAction(LOGIN),
    },
    {
      content: button.enableVendor,
      icon: ImageMajor,
      onAction: () => handleSelectAction(ENABLE),
      disabled: loading || !isInactive,
    },
    {
      content: button.disableVendor,
      icon: ImageMajor,
      onAction: () => handleSelectAction(DISABLE),
      disabled: isInactive || onHoliday,
    },
    {
      content: button.brandPage,
      icon: ImageMajor,
      onAction: () => handleSelectAction(BRANDPAGE),
    },
    {
      content: button.brandPageV2,
      icon: ImageMajor,
      onAction: () => handleSelectAction(BRAND_PAGE_V2),
    },
    // check for review if isApproved value inReview or onHold
    {
      content: button.reviewVendor,
      icon: ImageMajor,
      onAction: () =>
        history.push({
          pathname: `/provider/review/${item._id}`,
          state: { detail: item },
        }),
      disabled: !(isApproved === label.inReview || isApproved === label.hold),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const reviewOptions = [
    vendorProfileOption,
    {
      content: button.reviewVendor,
      icon: ImageMajor,
      onAction: () =>
        history.push({
          pathname: `/provider/review/${item._id}`,
          state: { detail: item },
        }),
      disabled: !verified || !(isApproved === label.inReview || isApproved === label.hold),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const rejectOptions = [
    vendorProfileOption,
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const approveStatus = {
    inReview: reviewOptions,
    onHold: reviewOptions,
    reject: rejectOptions,
  };

  const manageOptions = ecommercePlatform ? lakeApproveOptions : approveOptions;

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <TextStyle>
              <div className="ellipsis">
                {/* <Link url={`/providers/view/${_id}`}> */}
                <Link url={`/providers/profile/${_id}`}>
                  <TextStyle>
                    {(brandName && baseHelper.ucFirst(brandName)) || cms("error.common.emptyBrandName")}
                  </TextStyle>
                </Link>
              </div>
            </TextStyle>
            <Caption>
              <div className="ellipsis">
                <TextStyle variation="subdued">
                  {`${constant.ONBOARDED} ${constant.ON}: ${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
                <br />
                {`${label.name} ${firstName || cms("error.common.emptyName")} ${lastName || ""}`}
                <br />
                {`${label.email} ${address}`}
              </div>
            </Caption>
            <div style={topMargin10px}>
              <Stack>
                <Stack.Item>
                  <Badge status={verified ? handleBadge().value : constant.NEW}>
                    {verified ? handleBadge().status : constant.PENDING}
                  </Badge>
                  {isInactive && <Badge status={label.critical}>{label.disabled}</Badge>}
                </Stack.Item>
                {onHoliday ? (
                  <Stack.Item>
                    <Badge status={INFO}>{HOLIDAY}</Badge>
                  </Stack.Item>
                ) : null}
                <Stack>
                  {shopifyShop && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.platform")}>
                          <img src={constant.SHOPIFY_ICON} alt={constant.SHOPIFY} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {wixInstanceId && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.platform")}>
                          <img src={constant.WIX_ICON} alt={constant.WIX} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {magentoShop && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.platform")}>
                          <img src={constant.MAGENTO_ICON} alt={constant.MAGENTO} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {prestaShop && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.platform")}>
                          <img src={constant.PRESTASHOP_ICON} alt={constant.PRESTASHOP} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {wooShop && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.platform")}>
                          <img src={constant.WOOCOMMERCE_ICON} alt={constant.WOOCOMMERCE} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {paypal && (paypalEmail || payerId) && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.method")}>
                          <img src={constant.PAYPAL_ICON} alt={constant.PAYPAL} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {stripe && stripeAccountId && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.providerList.method")}>
                          <img src={constant.STRIPE_ICON} alt={constant.STRIPE} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              active={activePopover[_id]}
              setActive={() =>
                popoverDisable ? setActivePopover(false) : setActivePopover({ [_id]: !activePopover[_id] })}
              options={
                // eslint-disable-next-line no-nested-ternary
                isApproved === label.inReview || isApproved === label.hold
                  ? reviewOptions
                  : isApproved === label.rejected
                  ? rejectOptions
                  : ecommercePlatform
                  ? lakeApproveOptions
                  : approveOptions
              }
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

VendorListView.defaultProps = {
  item: {},
  keyIndex: "",
  onDeleteVendor: () => {},
  onLoginVendor: () => {},
  activePopover: {},
};

VendorListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  onDeleteVendor: PropTypes.func,
  onLoginVendor: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default withErrorBoundary(VendorListView);
