import React, { useContext } from "react";
import { Card, Stack, Button } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context/privateContext";

const OtherCurrency = () => {
  const { cms } = useContext(PrivateContext);
  return (
    <>
      {/* <Card sectioned title={connectStripe}>
        <Stack vertical>
          <Stack.Item>
            <p>{currencyBlockStripe}</p>
          </Stack.Item>
          <Stack.Item>
            <p>{payoutCurrency}</p>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <Button primary disabled>
              {label.connect}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
     */}
      <Card sectioned title={cms("message.success.connectPaypalAccount")}>
        <Stack vertical>
          <Stack.Item>
            <p>{cms("message.success.currencyBlockPaypal")}</p>
          </Stack.Item>
          <Stack.Item>
            <p>{cms("message.success.payoutCurrency")}</p>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <Button primary disabled>
              {cms("section.label.connect")}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};
export default OtherCurrency;
