import React, { useEffect, useContext, useState } from "react";
import { useQuery } from "react-apollo";

import { baseHelper, storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

import { FETCH_VENDOR_ACCESS } from "app/advanceVendor/apollo/queries";
import VendorReviewAccess from "app/advanceVendor/modules/operator/feature/reviewAccess/reviewAccess";

const { userKey } = constant;
const { operator } = userKey;

const ManageVendorReview = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const [status, setStatus] = useState();
  const { data: reviewVendorData, refetch } = useQuery(FETCH_VENDOR_ACCESS);
  const responseData = baseHelper.getResponseData(reviewVendorData, constant.gql.GET_VENDOR_ACCESS_SETTING);

  useEffect(() => {
    if (responseData && responseData.review) {
      setStatus(responseData.review.isEnabled);
    }
    refetch();
  }, [refetch, responseData]);

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator && status !== constant.UNDEFINE) {
      return <VendorReviewAccess status={status} reviewVendorData={reviewVendorData} />;
    }
    return null;
  };

  const AdvanceProviderComponent = getComponent(currentUserRole);
  return AdvanceProviderComponent;
};

export default withErrorBoundary(ManageVendorReview);
