import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";
import OperatorSellerPlanList from "./operator/features/managePlan/list";

const { userKey } = constant;
const { operator } = userKey;

const SellerPlanList = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorSellerPlanList />;
    }
    return null;
  };
  const managePlan = getComponent(currentUserRole);
  return managePlan;
};

export default withErrorBoundary(SellerPlanList);
