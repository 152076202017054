import React from "react";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import ConnectPaymentService from "./component/connectPaymentService";

const OperatorPaymentService = () => {
  return (
    <>
      <ConnectPaymentService />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorPaymentService), { feature: constant.ADD_PAYMENT });
