import styled from "styled-components";

export const ProfileCard = styled.div`
  .Polaris-Card__Section > .Polaris-Stack {
    > .Polaris-Stack__Item {
      width: 20%;
      &:nth-child(2) {
        flex-basis: 44%;
        width: 44%;
        word-break: break-all;
        @media (min-width: 768px) {
          padding-right: 6rem;
        }
      }
      &:nth-child(3) {
        flex-basis: 30%;
        width: 30%;
        padding-top: 1.2rem;
        word-break: break-all;
      }
    }
  }
  > .Polaris-Card {
    margin-bottom: 2rem;
  }
  .Polaris-Thumbnail {
    max-width: 168px;
    width: 100%;
    border: 0;
  }
  .Polaris-TextContainer {
    margin-bottom: 1.5rem;
  }
  .vendor-detail {
    .Polaris-Stack {
      margin-bottom: 1.3rem;
    }
    .Polaris-Stack__Item + .Polaris-Stack__Item {
      width: calc(100% - 52px);
      margin-left: 0.9rem;
    }
  }
`;
export const BackButton = styled.button`
  margin-bottom: 2rem;
  color: #2c6ecb;
  font-weight: 600;
  display: block;
  .Polaris-Icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
    svg {
      fill: #2c6ecb;
    }
  }
`;
export const StyledActionCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > button {
    .Polaris-Icon {
      display: inline-block;
      vertical-align: top;
      margin-right: 1.2rem;
    }
    &:first-of-type {
      background: #008060;
      color: #fff;
      border: 0;
    }
    & + button {
      margin-left: auto;
    }
  }
  > div {
    margin-left: 1rem;
  }
`;
