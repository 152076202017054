import React, { useContext, useState, useEffect } from "react";

import { Button, Caption, Card, Collapsible, Layout, RadioButton, Stack, TextStyle } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

import { SkeletonAnnotated } from "lib/components";

import { GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";
import { GET_INVOICE_SETTING, UPDATE_INVOICE_ACCESS_SETTING } from "app/setup/apollo";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import Add from "./add";

function InvoiceAccess(props) {
  const { cms, history } = useContext(PrivateContext);
  const { setBanner, setMessage } = props;
  const [showDisableInvoice, setShowDisableInvoice] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [isAllDisable, setIsAllVendor] = useState(false);
  const [isAllEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedVendor, setIsSelectedVendor] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [updateAt, setUpdateAt] = useState("");
  const { error: errorVendorBrandList, loading: loadingVendorBrandList, data: dataVendorBrandList } = useQuery(
    GET_VENDOR_BRAND_LIST
  );
  const vendorBrandListResponseData = baseHelper.getResponseData(
    dataVendorBrandList,
    constant.gql.GET_VENDOR_BRAND_LIST
  );
  const vendorBrandListErrorData = baseHelper.getResponseError(dataVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);

  useEffect(() => {
    if (vendorBrandListResponseData) {
      const vendorNameList =
        vendorBrandListResponseData &&
        vendorBrandListResponseData.map((value) => {
          return {
            label: value.brandName,
            value: value._id,
          };
        });
      if (vendors && vendors.length) {
        let vendorSelected = [];
        vendorSelected = vendors.map((data) => {
          return data.vendorId.length && data.vendorId;
        });
        const updatedVendorList = vendorNameList.map((item) => {
          const { value = "" } = item;
          const vendorData = { ...item };
          vendorData.disabled = vendorSelected.includes(value);
          return vendorData;
        });
        setVendorList(updatedVendorList || []);
      } else {
        setVendorList(vendorNameList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorBrandListResponseData, vendors]);

  const {
    error: errorGetInvoiceAccess,
    loading: loadingGetInvoiceAccess,
    data: dataGetInvoiceAccess,
    refetch,
  } = useQuery(GET_INVOICE_SETTING);

  const invoiceAccessResponseData = baseHelper.getResponseData(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);
  const invoiceAccessErrorData = baseHelper.getResponseError(dataGetInvoiceAccess, constant.gql.GET_INVOICE_SETTING);

  const { payment } = invoiceAccessResponseData || {};
  useEffect(() => {
    if (payment) {
      const { invoice } = payment;
      if (invoice) {
        const { access: invoiceAccess } = invoice;
        if (invoiceAccess && vendorBrandListResponseData) {
          const {
            isAllVendor: allVendor,
            isEnabled: enable = true,
            selectedVendor,
            updatedAt,
            createdAt,
          } = invoiceAccess;
          if (enable) {
            setIsEnabled(enable);
            setIsAllVendor(allVendor);
            setIsSelectedVendor(allVendor);
            setVendors([]);
            setUpdateAt(updatedAt);
          } else {
            let vendorsList = selectedVendor.map((value) => ({ vendorId: value }));
            vendorsList = vendorsList.filter((element) =>
              vendorBrandListResponseData.find((vendor) => vendor._id === element.vendorId)
            );
            setIsEnabled(enable);
            setIsAllVendor(allVendor);
            setIsSelectedVendor(!allVendor);
            setVendors(vendorsList);
            setUpdateAt(updatedAt);
          }
          if (createdAt !== updateAt) {
            setFirstPush(false);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, vendorBrandListResponseData]);
  useEffect(() => {
    if (vendorBrandListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorBrandListErrorData, isOpen: true });
    }
    if (errorVendorBrandList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorBrandList), isOpen: true });
    }
    if (invoiceAccessErrorData) {
      setBanner({ status: constant.CRITICAL, title: invoiceAccessErrorData, isOpen: true });
    }
    if (errorGetInvoiceAccess) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetInvoiceAccess), isOpen: true });
    }
  }, [vendorBrandListErrorData, errorVendorBrandList, invoiceAccessErrorData, errorGetInvoiceAccess, setBanner]);

  const [updateInvoiceAccessSetting] = useMutation(UPDATE_INVOICE_ACCESS_SETTING);

  const onSubmit = async (enable) => {
    let formData;
    if (enable) {
      formData = {
        isAllVendor: false,
        isEnabled: enable,
        selectedVendor: [],
      };
    } else {
      const selectedVendor = vendors.map(({ vendorId }) => vendorId);
      formData = {
        isAllVendor: isAllDisable || false,
        isEnabled: enable,
        selectedVendor: (!isAllDisable && selectedVendor) || [],
      };
    }
    try {
      const response = await updateInvoiceAccessSetting({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_INVOICE_ACCESS_SETTING);
      setIsLoading(false);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      // eslint-disable-next-line no-unused-expressions
      if (enable) {
        setMessage(cms("section.access.message.success.enable"));
      } else if (isAllDisable) {
        setMessage(cms("section.access.message.success.disable"));
      } else {
        setMessage(cms("section.access.message.success.selectedDisable"));
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }

    setSubmitEnable(false);
  };
  const handleEnabledCheckBox = (value) => {
    if (isSelectedVendor) {
      setIsSelectedVendor(false);
    }
    if (isAllDisable) {
      setIsAllVendor(false);
    }
    setIsEnabled(!value);
    setSubmitEnable(!value);
  };

  const handleAllVendorCheckBox = (value) => {
    if (isSelectedVendor) {
      setIsSelectedVendor(false);
    }
    if (isAllEnabled) {
      setIsEnabled(false);
    }
    setIsAllVendor(!value);
    setSubmitEnable(!value);
  };

  const handleSelectVendorCheckBox = (value) => {
    if (isAllDisable) {
      setIsAllVendor(false);
    }
    if (isAllEnabled) {
      setIsEnabled(false);
    }
    setIsSelectedVendor(!value);
    setSubmitEnable(!value);
  };

  if (loadingVendorBrandList || loadingGetInvoiceAccess) {
    return <SkeletonAnnotated />;
  }
  const handleSubmit = () => {
    let isSubmit = true;
    if (vendors) {
      vendors.forEach((element) => {
        if (!element.vendorId && isSelectedVendor) {
          setBanner({
            isOpen: true,
            title: cms("section.access.message.error.empty"),
            status: constant.CRITICAL,
          });
          isSubmit = false;
        } else {
          setBanner({ isOpen: false, title: "", status: "" });
        }
      });
    }
    if (vendors.length === 0 && isSelectedVendor) {
      setBanner({
        isOpen: true,
        title: cms("section.access.message.error.vendor"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      setIsLoading(true);
      onSubmit(isAllEnabled);
    }
  };
  return (
    <>
      <Layout.AnnotatedSection title={cms("section.access.title")} description={cms("section.access.description")}>
        <Card
          sectioned
          title={[
            cms("section.access.card.title"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{baseHelper.lastUpdateDate(updateAt)}</Caption>
              </TextStyle>
            ),
          ]}
          actions={[
            {
              content: showDisableInvoice ? cms("common.label.hide") : cms("common.label.show"),
              onAction: () => setShowDisableInvoice(!showDisableInvoice),
              disclosure: showDisableInvoice ? constant.UP : constant.DOWN,
            },
          ]}
          banner
        >
          <div className="toggle-action">
            <TextStyle>{cms("section.access.card.subtitle")}</TextStyle>
          </div>
          <br />
          <Collapsible open={showDisableInvoice} id="disableInvoice">
            <Stack vertical fill>
              <RadioButton
                label={cms("section.access.label.enable")}
                checked={isAllEnabled}
                onChange={(newChecked) => handleEnabledCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("section.access.label.disable")}
                checked={isAllDisable}
                onChange={(newChecked) => handleAllVendorCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("section.access.label.selectedDisable")}
                checked={isSelectedVendor}
                onChange={(newChecked) => handleSelectVendorCheckBox(!newChecked)}
              />
              {isSelectedVendor && (
                <Add
                  vendorList={vendorList}
                  isSelectedVendor={isSelectedVendor}
                  vendors={vendors}
                  setVendors={setVendors}
                  setSubmitEnable={setSubmitEnable}
                />
              )}
            </Stack>
            <br />
            <Stack horizonatl distribution="equalSpacing">
              <Button secondry onClick={() => history.push("/setting")}>
                {cms("common.button.cancel")}
              </Button>
              <Button primary submit disabled={!submitEnabled} onClick={handleSubmit} loading={isLoading}>
                {firstPush ? cms("common.button.submit") : cms("common.button.update")}
              </Button>
            </Stack>
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}

export default withFeature(withErrorBoundary(InvoiceAccess), {
  feature: constant.PAYMENT_INVOICE_ACCESS,
});
