import React, { useContext, useState, useEffect } from "react";
import { Card, Layout, PageActions, FormLayout, Autocomplete, Icon } from "@shopify/polaris";
import { ArrowUpMinor } from "@shopify/polaris-icons";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import helpers
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import gql
import { FETCH_ALL_USERS } from "app/userManagement/apollo/queries";
import { CREATE_ASSOCIATION } from "app/userManagement/apollo/mutation";

// import validation
import validate from "./yup";

const AdminAssociation = () => {
  const { history, cms } = useContext(PrivateContext);
  const { loading: queryLoading, data: queryData, error } = useQuery(FETCH_ALL_USERS);
  const [createAssociation, { loading }] = useMutation(CREATE_ASSOCIATION);
  const [sellers, setSellers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [errorValue, setErrorValue] = useState();
  const [selectedValue, setSelectedValue] = useState({
    seller: "",
    vendor: "",
  });
  const [bannerStatus, setBannerStatus] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const [inputValue, setInputValue] = useState({ seller: "", vendor: "" });
  const [sellerOptions, setSellerOptions] = useState("");
  const [vendorOptions, setVendorOptions] = useState("");

  const { GET_ALL_USERS, CREATE_ASSOCIATION: ASSOCIATION } = constant.gql;
  const { SELLER, VENDOR } = constant;

  useEffect(() => {
    if (queryData) {
      const res = baseHelper.getResponseData(queryData, GET_ALL_USERS);
      if (!res) {
        const errorResponse = baseHelper.getResponseError(queryData, GET_ALL_USERS);
        setBannerStatus({ isOpen: true, title: errorResponse, status: constant.CRITICAL });
        return;
      }
      const { shops = [], brandNames = [] } = res;

      const sellersList = shops.map((shop) => ({
        label: shop.value,
        value: shop.key,
      }));

      const vendorsList = brandNames.map((brandName) => ({
        label: brandName.value,
        value: brandName.key,
      }));
      setSellers(sellersList);
      setVendors(vendorsList);
      setSellerOptions(sellersList);
      setVendorOptions(vendorsList);
    }
  }, [GET_ALL_USERS, queryData]);

  const isAllValuesField = () => Object.values(selectedValue).every((val) => val);

  const handleChange = (field, value) => {
    let options = sellers;
    if (field === "vendor") {
      options = vendors;
    }
    const matchedOption = options.find((option) => {
      return option.value.match(value);
    });
    setSelectedValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setInputValue((prevState) => ({
      ...prevState,
      [field]: matchedOption && matchedOption.label,
    }));
  };
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorValue((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    if (error) {
      setBannerStatus({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorHelper]);

  const onSubmit = async () => {
    await handleValidation(SELLER, selectedValue.seller);
    await handleValidation(VENDOR, selectedValue.vendor);
    if (!isAllValuesField()) {
      return;
    }
    try {
      const res = await createAssociation({
        variables: {
          input: {
            seller: selectedValue.seller,
            vendor: selectedValue.vendor,
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, ASSOCIATION);
      let bannerValue = { status: constant.SUCCESS, title: cms("message.success") };
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, ASSOCIATION);
        bannerValue = { status: constant.CRITICAL, title: errorResponse };
      }
      setBannerStatus({ isOpen: true, title: bannerValue.title, status: bannerValue.status });
    } catch (exception) {
      setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
    }
  };

  const updateText = (field, value) => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    const filterRegex = new RegExp(value, "i");
    let setOptions = setVendorOptions;
    let users = vendors;
    if (field === SELLER) {
      setOptions = setSellerOptions;
      users = sellers;
    }
    if (!value) {
      setOptions(users);
      setSelectedValue((prevState) => ({
        ...prevState,
        [field]: "",
      }));
      return;
    }
    const resultOptions = users.filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  };

  if (queryLoading) {
    return <SkeletonAnnotated />;
  }

  const sellerTextField = (
    <Autocomplete.TextField
      onChange={(value) => updateText(SELLER, value)}
      label={`${cms("label.seller")}*`}
      value={inputValue.seller}
      suffix={<Icon source={ArrowUpMinor} color="inkLighter" />}
      placeholder="Search seller for association"
      error={errorValue && errorValue.seller}
      onBlur={() => handleValidation(constant.userKey.seller, errorValue.seller)}
    />
  );

  const vendorTextField = (
    <Autocomplete.TextField
      onChange={(value) => updateText(VENDOR, value)}
      label={`${cms("label.vendor")}*`}
      value={inputValue.vendor}
      suffix={<Icon source={ArrowUpMinor} color="inkLighter" />}
      placeholder="Search vendor for association"
      error={errorValue && errorValue.vendor}
      onBlur={() => handleValidation(constant.userKey.vendor, errorValue.vendor)}
    />
  );

  return (
    <>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => setBannerStatus({ isOpen: !bannerStatus.isOpen })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card sectioned>
          <FormLayout>
            <Autocomplete
              options={sellerOptions}
              selected={selectedValue.seller}
              onSelect={(value) => handleChange(SELLER, value[0])}
              textField={sellerTextField}
            />
            <Autocomplete
              options={vendorOptions}
              selected={selectedValue.vendor}
              onSelect={(value) => handleChange(VENDOR, value[0])}
              textField={vendorTextField}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            id: "submitAssociation",
            content: cms("common.button.submit"),
            loading,
            onAction: () => onSubmit(),
          }}
          secondaryActions={{
            id: "cancelAssociation",
            content: cms("common.button.cancel"),
            onAction: () => history.push("/"),
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(AdminAssociation);
