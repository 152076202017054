import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import "jspdf-autotable";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper, storageHelper, fileHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";

import {
  GET_ORDER_REPORT_SETTING,
  GET_PRODUCT_REPORT_SETTING,
  GET_PAYMENT_REPORT_SETTING,
  GET_REPORT_DATA,
} from "app/setup/apollo/queries";

import reportHelper from "app/dashboard/modules/reportHelper";

const ExportReportAsPDF = () => {
  const data = useContext(PrivateContext);
  const brandLogo = storageHelper.get(constant.LOGO_URL);
  const defaultLogo = constant.DEFAULT_PDF_LOGO;
  const { cms, currentUser } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [
    getReportLines,
    { data: reportData, loading: getOrderLoading, error: getOrderError },
  ] = useLazyQuery(GET_REPORT_DATA, { variables: { input: queryData } });

  const [
    getOrderReportSetting,
    // eslint-disable-next-line no-unused-vars
    { data: getOrderReportSettingData, loading: getOrderReportSettingLoading, error: getOrderReportSettingError },
  ] = useLazyQuery(GET_ORDER_REPORT_SETTING);

  const [
    getProductReportSetting,
    // eslint-disable-next-line no-unused-vars
    { data: getProductReportSettingData, loading: getProductReportSettingLoading, error: getProductReportSettingError },
  ] = useLazyQuery(GET_PRODUCT_REPORT_SETTING);

  const [
    getPaymentReportSetting,
    // eslint-disable-next-line no-unused-vars
    { data: getPaymentReportSettingData, loading: getPaymentReportSettingLoading, error: getPaymentReportSettingError },
  ] = useLazyQuery(GET_PAYMENT_REPORT_SETTING);

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!getOrderLoading && !getOrderError && reportData && getOrderReportSettingData) {
      const resData = baseHelper.getResponseData(reportData, constant.gql.GET_REPORT_DATA);
      const orderReportSettingResponse = baseHelper.getResponseData(
        getOrderReportSettingData,
        constant.gql.GET_ORDER_REPORT_SETTING
      );
      const paymentReportSettingResponse = baseHelper.getResponseData(
        getPaymentReportSettingData,
        constant.gql.GET_PAYMENT_REPORT_SETTING
      );
      const productReportSettingResponse = baseHelper.getResponseData(
        getProductReportSettingData,
        constant.gql.GET_PRODUCT_REPORT_SETTING
      );

      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(reportData, constant.GET_REPORT_LINES);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (!resData.length) {
        bannerData = {
          title: cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }

      const pdfData = reportHelper.reportCSVData(
        resData,
        orderReportSettingResponse,
        paymentReportSettingResponse,
        productReportSettingResponse,
        false,
        true,
        isVendor
      );

      if (brandLogo) {
        fileHelper
          .loadImage(brandLogo)
          .then((logo) => {
            fileHelper.generatePdf(logo, pdfData, constant.CONSOLIDATED_REPORT);
          })
          .catch(() => {
            fileHelper.generatePdf(false, pdfData, constant.CONSOLIDATED_REPORT);
          });
      } else {
        fileHelper
          .loadImage(defaultLogo)
          .then((logo) => {
            fileHelper.generatePdf(logo, pdfData, constant.CONSOLIDATED_REPORT);
          })
          .catch(() => {
            fileHelper.generatePdf(false, pdfData, constant.CONSOLIDATED_REPORT);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportData,
    getOrderError,
    getOrderLoading,
    cms,
    getOrderReportSettingData,
    data,
    getPaymentReportSettingData,
    getProductReportSettingData,
    isVendor,
    currentUser.logo,
  ]);

  useEffect(() => {
    const exportOrder = () => {
      const dataToFetch = {};
      const { status, fulfillmentStatus, vendor, accountingStatus } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      setQueryData(dataToFetch);
      getReportLines();
      getOrderReportSetting();
      getPaymentReportSetting();
      getProductReportSetting();
    };
    if (isPageLoading) {
      exportOrder();
      setIsPageLoading(false);
    }
    // eslint-disable-next-line max-len
  }, [data, getReportLines, getOrderReportSetting, getPaymentReportSetting, getProductReportSetting, isPageLoading]);

  return (
    <Layout>
      {getOrderLoading && <Spinner isFullPage />}
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ExportReportAsPDF), { feature: constant.EXPORT_REPORT });
