import React, { useCallback, useContext, useState } from "react";
import { Button, Popover } from "@shopify/polaris";
import PropTypes from "prop-types";
// import context
import { PrivateContext } from "lib/context";
// config
import quickSettingConfig from "app/reports/admin/widgets/quickSetting/quickSettingConfig";
import { StyledActionCard, DropDown } from "app/reports/generic/style/quickAction";
import quickAction from "./quickActionConfig";

const AdminQuickAction = (props) => {
  const { link = [] } = props;
  const { cms, history, match } = useContext(PrivateContext);
  const { path } = match;
  const quickActionConfig = quickAction(cms);
  const { config, title } = quickSettingConfig(cms);
  const [popoverActive, setPopoverActive] = useState(false);
  const actionCard = (action) => {
    const onAction = action.action || (() => history.push(action.link));
    return (
      <>
        <Button onClick={onAction}>{action.label}</Button>
      </>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const togglePopoverActive = useCallback(() => setPopoverActive(() => !popoverActive), []);
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {title[path] || cms("common.actions.title.setting")}
    </Button>
  );
  const getQuickActions = (actions = []) => {
    return actions.map((links) => actionCard(links));
  };
  const getQuickSettings = (settings = []) => {
    if (settings.length === 1) {
      return settings.map((setting) => <div>{actionCard(setting)}</div>);
    }
    const options = settings.map((setting) => (
      <li>
        <Button plain onClick={() => history.push(setting.link)}>
          {setting.label}
        </Button>
      </li>
    ));
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <DropDown className="drop-down">{options}</DropDown>
      </Popover>
    );
  };
  const quickActions = (link.length && link) || quickActionConfig[path];
  return (
    <StyledActionCard>
      {getQuickActions(quickActions)}
      {config[path] && getQuickSettings(config[path])}
    </StyledActionCard>
  );
};
AdminQuickAction.propTypes = {
  link: PropTypes.arrayOf(PropTypes.object),
};
AdminQuickAction.defaultProps = {
  link: [],
};
export default AdminQuickAction;
