import { gql } from "apollo-boost";

const UPDATE_DYNAMIC_DISCOUNT_SETTING = gql`
  mutation manageSellerDiscountSetting($input: ManageSellerDiscount!) {
    manageSellerDiscountSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_DYNAMIC_DISCOUNT_SETTING;
