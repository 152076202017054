import { gql } from "apollo-boost";

const CHECK_ORDER_LAKE_LINES = gql`
  query checkOrderLakeLines($input: CheckOrderLakeLines) {
    checkOrderLakeLines(input: $input) {
      data {
        _id
        price
        accountingStatus
        shippingStatus
        isVendorFulfilled
        baseCostPrice
        basePrice
        baseTotal
        baseWrappingCost
        binPickingNumber
        #cost {
        # priceExTax
        # priceIncTax
        # totalExTax
        #totalIncTax
        #wrappingExTax
        #}
        ecommercePlatform
        eventName
        fixedShippingCost
        isBundledProduct
        isRefunded
        name
        priceExTax
        priceIncTax
        priceTax
        quantity
        quantityShipped
        sku
        storeEbayItemId
        storeEbayTransactionId
        storeEventDate
        storeExternalId
        storeOptionSetId
        storeOrderAddressId
        storeOrderId
        storeParentOrderProductId
        storeLineItemId
        storeReturnId
        storeVariantId
        #tax {
        #costPrice
        #total
        #}
        type
        upc
        weight
        wrappingMessage
        wrappingName
        createdBy
        isSynced
        commissionSysAdmin
        commissionSeller
        commissionVendor
        dueDate
        image
        productId
        ownerId
        orderId
        updatedBy
        providerId
        storeStatus
        dispatchedDate
        trackingCompany
        trackingNumber
        createdAt
        updatedAt
        shippingAddress
        currency
        shippingData {
          _id
          ecommercePlatform
          ownerId
          storeOrderId
          storeShippingAddressId
          addressLineOne
          addressLineTwo
          baseCost
          baseHandlingCost
          city
          company
          #cost {
          # exTax
          # incTax
          # handlingExTax
          # handlingIncTax
          #}
          country
          countryISO2
          createdAt
          createdBy
          email
          firstName
          isSynced
          itemsShipped
          itemsTotal
          lastName
          orderId
          phone
          postcode
          shippingMethod
          shippingZoneName
          state
          storeCostTaxClassId
          storeHandlingCostTaxClassId
          storeShippingZoneId
          #tax {
          #cost
          #handlingCost
          #}
          updatedAt
          updatedBy
        }
        commissionType
      }
      status
      error
    }
  }
`;

export default CHECK_ORDER_LAKE_LINES;
