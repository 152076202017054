import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

class ReportHelper {
  /**
   * @desc To return orders csv data
   * @param reportData array of reportData
   *
   * @return csv data
   *
   */
  reportCSVData = (
    reportData = [],
    orderSettingResponse,
    paymentSettingResponse,
    productSettingResponse,
    isCsv = false,
    isPdf = false,
    isVendor = false
  ) => {
    const orderSettingData = orderSettingResponse.report;
    const {
      createdAt,
      fulfillmentStatus,
      lineItemId,
      orderNumber,
      orderStatus,
      quantity,
      grossSales,
      grossProfit,
      vendorAmount,
      returns,
      shipping,
      totalSalesPrice,
      trackingNumber,
    } = orderSettingData || {};

    const {
      RETURNS,
      ORDER_NUMBER_REPORT,
      LINEITEMID,
      ORDERSTATUS,
      FULFILLMENT_STATUS,
      QUANTITYS,
      VENDOR_AMOUNT,
      CREATEDAT,
      SHIPPING_AMOUNT,
      VENDOR_PAID_DATE,
      VENDOR_PAYMENT_STATUS,
      TITLES,
      SKU_REPORT,
      PRODUCTTYPE,
      PRICE_REPORT,
      VENDOR_CAPITAL,
      TAX,
      DISCOUNT_REPORT,
      MARKUP_REPORT,
      PRODUCT_COST,
      CUSTOMER_PRODUCT_PRICE,
      SELLER_COMMISSION,
      TOTAL_SALES_PRICE,
      GROSS_SALES,
      GROSS_PROFIT,
      YES,
      TRACKING_NUMBER,
    } = constant;
    const paymentSettingData = paymentSettingResponse.report;
    const { vendorPaidDate, vendorPaymentStatus, sellerCommission } = paymentSettingData || {};

    const productSettingData = productSettingResponse.report;
    const { customerProductPrice, title, sku, markup, productCost, productType, price, vendor, discount, tax } =
      productSettingData || {};

    const orderData = {
      orderNumber: (orderNumber && orderNumber.label) || ORDER_NUMBER_REPORT,
      lineItemId: (lineItemId && lineItemId.label) || LINEITEMID,
      orderStatus: (orderStatus && orderStatus.label) || ORDERSTATUS,
      fulfillmentStatus: (fulfillmentStatus && fulfillmentStatus.label) || FULFILLMENT_STATUS,
      quantity: (quantity && quantity.label) || QUANTITYS,
      vendorAmount: (vendorAmount && vendorAmount.label) || VENDOR_AMOUNT,
      createdAt: (createdAt && createdAt.label) || CREATEDAT,
      shipping: (shipping && shipping.label) || SHIPPING_AMOUNT,
      vendorPaidDate: (vendorPaidDate && vendorPaidDate.label) || VENDOR_PAID_DATE,
      vendorPaymentStatus: (vendorPaymentStatus && vendorPaymentStatus.label) || VENDOR_PAYMENT_STATUS,
      title: (title && title.label) || TITLES,
      sku: (sku && sku.label) || SKU_REPORT,
      productType: (productType && productType.label) || PRODUCTTYPE,
      price: (price && price.label) || PRICE_REPORT,
      vendor: (vendor && vendor.label) || VENDOR_CAPITAL,
      tax: (tax && tax.label) || TAX,
      trackingNumber: (trackingNumber && trackingNumber.label) || TRACKING_NUMBER,
    };

    if (!isVendor) {
      orderData.discount = (discount && discount.label) || DISCOUNT_REPORT;
      orderData.markup = (markup && markup.label) || MARKUP_REPORT;
      orderData.productCost = (productCost && productCost.label) || PRODUCT_COST;
      orderData.customerProductPrice = (customerProductPrice && customerProductPrice.label) || CUSTOMER_PRODUCT_PRICE;
      orderData.sellerCommission = (sellerCommission && sellerCommission.label) || SELLER_COMMISSION;
      orderData.totalSalesPrice = (totalSalesPrice && totalSalesPrice.label) || TOTAL_SALES_PRICE;
      orderData.grossSales = (grossSales && grossSales.label) || GROSS_SALES;
      orderData.grossProfit = (grossProfit && grossProfit.label) || GROSS_PROFIT;
      orderData.returns = (returns && returns.label) || RETURNS;
      orderData.trackingNumber = (trackingNumber && trackingNumber.label) || TRACKING_NUMBER;
    }

    const updatedArray = [];
    if (orderSettingData) {
      const notAllowedOrderCsvKeys = Object.keys(orderSettingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = orderSettingData[item] && orderSettingData[item].isHideCsv;
        }
        if (isPdf) {
          hideArray = orderSettingData[item] && orderSettingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedOrderCsvKeys.map((key) => {
        updatedArray.push(key);
        return false;
      });
    }

    if (paymentSettingData) {
      const notAllowedPaymentCsvKeys = Object.keys(paymentSettingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = paymentSettingData[item] && paymentSettingData[item].isHideCsv;
        }
        if (isPdf) {
          hideArray = paymentSettingData[item] && paymentSettingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedPaymentCsvKeys.map((key) => {
        updatedArray.push(key);
        return false;
      });
    }

    if (productSettingData) {
      const notAllowedProductCsvKeys = Object.keys(productSettingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = productSettingData[item] && productSettingData[item].isHideCsv;
        }
        if (isPdf) {
          hideArray = productSettingData[item] && productSettingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedProductCsvKeys.map((key) => {
        updatedArray.push(key);
        return false;
      });
    }

    const reportArray = [
      (orderNumber && orderNumber.label) || ORDER_NUMBER_REPORT,
      (lineItemId && lineItemId.label) || LINEITEMID,
      (orderStatus && orderStatus.label) || ORDERSTATUS,
      (fulfillmentStatus && fulfillmentStatus.label) || FULFILLMENT_STATUS,
      (quantity && quantity.label) || QUANTITYS,
      (vendorAmount && vendorAmount.label) || VENDOR_AMOUNT,
      (createdAt && createdAt.label) || CREATEDAT,
      (shipping && shipping.label) || SHIPPING_AMOUNT,
      (vendorPaidDate && vendorPaidDate.label) || VENDOR_PAID_DATE,
      (vendorPaymentStatus && vendorPaymentStatus.label) || VENDOR_PAYMENT_STATUS,
      (title && title.label) || TITLES,
      (sku && sku.label) || SKU_REPORT,
      (productType && productType.label) || PRODUCTTYPE,
      (price && price.label) || PRICE_REPORT,
      (vendor && vendor.label) || VENDOR_CAPITAL,
      (tax && tax.label) || TAX,
      (trackingNumber && trackingNumber.label) || TRACKING_NUMBER,
    ];

    if (!isVendor) {
      reportArray.push((discount && discount.label) || DISCOUNT_REPORT);
      reportArray.push((markup && markup.label) || MARKUP_REPORT);
      reportArray.push((productCost && productCost.label) || PRODUCT_COST);
      reportArray.push((customerProductPrice && customerProductPrice.label) || CUSTOMER_PRODUCT_PRICE);
      reportArray.push((sellerCommission && sellerCommission.label) || SELLER_COMMISSION);
      reportArray.push((totalSalesPrice && totalSalesPrice.label) || TOTAL_SALES_PRICE);
      reportArray.push((grossSales && grossSales.label) || GROSS_SALES);
      reportArray.push((grossProfit && grossProfit.label) || GROSS_PROFIT);
      reportArray.push((returns && returns.label) || RETURNS);
    }
    const removeIndex = [];

    updatedArray.map((updatedValue) => {
      const value = Object.keys(orderData).find((item) => item === updatedValue);
      const index = Object.keys(orderData).indexOf(value);
      removeIndex.push(index);
      return false;
    });

    const differenceArray = reportArray.filter((value, index) => !removeIndex.includes(index));

    const newReportArray = [];
    newReportArray[0] = differenceArray;
    reportData.forEach((item, idx) => {
      newReportArray[idx + 1] = [
        `${item.orderNumber}`,
        `${item.lineItemId}`,
        `${item.orderStatus || ""}`,
        `${item.fulfillmentStatus || ""}`,
        `${item.quantity || 0}`,
        `${item.vendorAmount || 0}`,
        `${baseHelper.formatDate(item.createdAt)}`,
        `${item.shipping || 0}`,
        `${baseHelper.formatDate(item.vendorPaidDate)}`,
        `${item.vendorPaymentStatus || ""}`,
        `${item.title || ""}`,
        `${item.sku}`,
        `${item.productType || ""}`,
        `${item.price || 0}`,
        `${item.vendor || ""}`,
        `${item.tax || 0}`,
        `${item.trackingNumber || ""}`,
        (!isVendor && (item.discount || 0)) || "",
        (!isVendor && (item.markup || 0)) || "",
        (!isVendor && (item.productCost || 0)) || "",
        (!isVendor && (item.customerProductPrice || 0)) || "",
        (!isVendor && (item.sellerCommission || 0)) || "",
        (!isVendor && (item.totalSalesPrice || 0)) || "",
        (!isVendor && (item.grossSales || 0)) || "",
        (!isVendor && (item.grossProfit || 0)) || "",
        (!isVendor && (item.returns ? YES : "")) || "",
      ].filter((value, index) => {
        return removeIndex.indexOf(index) === -1;
      });
    });
    return newReportArray;
  };
}

export default new ReportHelper();
