import { gql } from "apollo-boost";

const UPDATE_MAINTENANCE = gql`
  mutation updateMaintenance($input: UpdateMaintenance!) {
    updateMaintenance(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_MAINTENANCE;
