import React from "react";
import PropTypes from "prop-types";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import { withErrorBoundary } from "lib/hoc";

const Title = (props) => {
  const { data, onChange, variantOption = {}, error, cms, setDisableSubmit } = props;
  const handleTitle = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleBlur = () => {
    Object.keys(variantOption).forEach((option) => {
      if (!data[variantOption[option]]) {
        return setDisableSubmit(true);
      }
      return setDisableSubmit(false);
    });
  };

  const renderTextField = () => {
    const textFields = [];
    Object.keys(variantOption).forEach((variantLabel) => {
      const option = variantOption[variantLabel];
      if (option) {
        const hasError =
          (!data[option] || (data[option] && data[option].trim() === "")) &&
          error[variantLabel] &&
          `${cms("label.fill")} ${option}`;
        const textField = (
          <TextField
            key={variantLabel}
            id={variantLabel}
            label={`${option}*`}
            value={data[option] || ""}
            onChange={(value) => handleTitle(option, value)}
            placeholder={option}
            error={hasError}
            onBlur={() => handleBlur()}
          />
        );
        textFields.push(textField);
      }
    });
    return textFields;
  };

  return (
    <Card title={cms("section.title")} sectioned>
      <FormLayout>{renderTextField()}</FormLayout>
    </Card>
  );
};

Title.propTypes = {
  cms: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.objectOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func.isRequired,
  variantOption: PropTypes.objectOf(PropTypes.string),
};

Title.defaultProps = {
  variantOption: [],
};

export default withErrorBoundary(Title);
