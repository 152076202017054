import React, { useContext, useEffect, useState } from "react";
import { PrivateContext } from "lib/context";
import { Card, FormLayout, Layout, TextContainer, TextField, Thumbnail } from "@shopify/polaris";

import { GET_USER } from "app/sellers/apollo/queries/";
import { useQuery } from "react-apollo";

import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

import { Banner, Sheet, SkeletonAnnotated } from "lib/components";

import cmsSellerView from "./cms/sellerViewCMS";

const AdminSellerProfile = () => {
  const { cms, match } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;
  const sellerViewCMS = cmsSellerView(cms);
  const { label } = sellerViewCMS;
  const [sellerData, setSellerData] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [sheetActive, setSheetActive] = useState(false);
  const [sheetContent, setSheetContent] = useState("");
  const [sheetTitle, setSheetTitle] = useState("");

  // useQuery Calls to fetch data.
  const { error: sellerDetailError, data: sellerDetailData, loading } = useQuery(GET_USER, {
    variables: { input: { _id: id } },
  });

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  useEffect(() => {
    if (sellerDetailError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(sellerDetailError), isOpen: true });
      return;
    }
    const responseData = baseHelper.getResponseData(sellerDetailData, constant.gql.GET_USER);
    const responseError = baseHelper.getResponseError(sellerDetailData, constant.gql.GET_USER);

    if (responseError) {
      setBanner({ status: constant.CRITICAL, title: responseError, isOpen: true });
    }
    if (responseData) {
      setSellerData(responseData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHelper, sellerDetailData, sellerDetailError]);

  const { brandName, commission, commissionType, logo } = sellerData;

  const annotatedTitle = `${baseHelper.ucFirst(brandName)}'s Profile`;

  if (loading) {
    return <SkeletonAnnotated primaryLines={15} annotatedLines={3} />;
  }

  return (
    <Layout.Section>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      {!banner.isOpen && (
        <Layout.AnnotatedSection title={annotatedTitle} description={cms("sellerView.description")}>
          <Card
            title={annotatedTitle}
            actions={[{ content: cms("common.label.learnMore"), onAction: () => learnMore(annotatedTitle, "TODO: ") }]}
          >
            <Card.Section>
              <FormLayout>
                <TextContainer>{cms("sellerView.description")}</TextContainer>
                <TextField label={label.brand_name} value={brandName} disabled />
                <TextField label={label.commission_type} value={commissionType} disabled />
                <TextField label={label.commission} value={(commission && commission.toString()) || ""} disabled />
                {logo && <label htmlFor="thumb">{label.logo}</label>}
                {logo && (
                  <div id="thumb">
                    <Thumbnail
                      source={imageHelper.resize({ url: logo, type: constant.imageTypes.THUMBNAIL })}
                      size="large"
                      alt={`${brandName} logo`}
                    />
                  </div>
                )}
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      )}
      <Sheet
        isOpen={sheetActive}
        title={sheetTitle}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: cms("common.button.close"), onAction: () => setSheetActive(false) }}
        primaryAction={{ content: cms("common.label.done"), onAction: () => setSheetActive(false) }}
      >
        {sheetContent}
      </Sheet>
    </Layout.Section>
  );
};

export default withErrorBoundary(AdminSellerProfile);
