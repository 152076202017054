import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Card, Caption, Form, FormLayout, Layout, TextField, TextContainer, TextStyle, Stack } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { CardFooter, Toast } from "lib/components/index";
import { UPDATE_COMMISSION_TAX_SETTING } from "app/setup/apollo/mutations";

const CommissionTax = (props) => {
  const { setBanner, commissionRate, setCommissionRate, commissionUpdatedDate, refetch } = props;
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [updateCommissionTaxSetting, { loading: taxSettingLoading }] = useMutation(UPDATE_COMMISSION_TAX_SETTING);
  const { cms } = useContext(PrivateContext);
  const {
    gql,
    value: { MAX_PERCENTAGE, ZERO },
  } = constant;

  const acceptOnlyValidInput = (val, prevValue, max) => {
    if (parseFloat(val) < max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleValidation = () => {
    if (!commissionRate) {
      setError(cms("message.error.field"));
      setSubmitEnabled(false);
      return;
    }
    setError("");
  };

  const handleSubmit = async () => {
    if (!submitEnabled || error) {
      return;
    }
    setSubmitEnabled(false);
    updateCommissionTaxSetting({
      variables: {
        input: {
          commission: {
            value: parseFloat(commissionRate),
          },
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_COMMISSION_TAX_SETTING);
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_COMMISSION_TAX_SETTING);
          setBanner({ status: constant.CRITICAL, title: errorResponse, isOpen: true });
        }
        setMessage(cms("message.success.commission"));
        refetch();
      })
      .catch((exception) => {
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  return (
    <>
      <Layout.AnnotatedSection
        title={cms("section.commission.title")}
        description={cms("section.commission.description")}
      >
        <Card
          title={[
            cms("section.commission.title"),
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(commissionUpdatedDate)}`}</Caption>
            </TextStyle>,
          ]}
          sectioned
        >
          <Form onSubmit={() => handleSubmit()}>
            <FormLayout>
              <TextContainer>{cms("section.commission.cardDescription")}</TextContainer>
              <Stack wrap={false}>
                <TextField
                  id="commissionRate"
                  placeholder={ZERO}
                  suffix={<div className="suffixColor">%</div>}
                  min={ZERO}
                  value={commissionRate && commissionRate.toString()}
                  onChange={(value) => {
                    setCommissionRate(acceptOnlyValidInput(value, commissionRate, MAX_PERCENTAGE));
                    setSubmitEnabled(true);
                  }}
                  onBlur={() => handleValidation()}
                  error={error}
                />
              </Stack>
            </FormLayout>
            <CardFooter updatedDate={commissionUpdatedDate} disabled={!submitEnabled} loading={taxSettingLoading} />
          </Form>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(CommissionTax), {
  feature: constant.TAX_COMMISSION_SETTING,
});
