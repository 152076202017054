import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import config from "configuration";

import { withErrorBoundary } from "lib/hoc";

// import helpers
import { baseHelper } from "lib/helpers";

const VendorShopifyInstall = (props) => {
  const { history, currentUser: currentUserData } = useContext(PrivateContext);

  if (currentUserData && currentUserData.shop) {
    history.push("/products");
    return <div className="text-center">Redirecting ...</div>;
  }

  const redirect = () => {
    const queryParams = baseHelper.getQueryParams(history.location.search);
    let { shop } = queryParams;
    const { hmac = "" } = queryParams;

    if (history.location.state) {
      shop = history.location.state.shop;
    }

    const clientId = config.vendorShopifyAPIKey;
    const redirectUri = baseHelper.getCurrentDomain(window.location, "providers/shopify-callback");
    const scope =
        "read_products, write_products, read_customers, write_customers, read_orders, " +
        "write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, " +
        "read_shipping, write_shipping, read_locations, write_order_edits, read_discounts, write_discounts, " +
        "write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders";
    const url =
        `https://${shop}` +
        `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;

    window.location.href = url;
  };

  redirect();

  return <div className="text-center">Redirecting to Install accept Page</div>;
};

export default withErrorBoundary(VendorShopifyInstall);
