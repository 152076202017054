const quickReportConfig = ({ cms = {}, count = null, path = "" }) => {
  const {
    inviteCount = {},
    orderCount = {},
    paymentCount = {},
    productCount = {},
    vendorCount = {},
    operatorCount = {},
  } = count;

  const {
    joined: joinedInviteCount = null,
    newInvites: newInviteCount = null,
    pending: pendingInviteCount = null,
    revoked: revokedInviteCount = null,
    total: totalInviteCount = null,
  } = inviteCount;

  const {
    archived: archivedOrderCount = null,
    pendingAcceptance: pendingAcceptanceOrderCount = null,
    pendingShipment: pendingShipmentOrderCount = null,
    rejected: rejectedOrderCount = null,
    return: returnOrderCount = null,
    shipped: shippedOrderCount = null,
    total: totalOrderCount = null,
  } = orderCount;

  const {
    disputed: disputedPaymentCount = null,
    due: duePaymentCount = null,
    forcasted: forcastedPaymentCount = null,
    paid: paidPaymentCount = null,
    total: totalPaymentCount = null,
  } = paymentCount;

  const {
    approved: approvedProductCount = null,
    delete: deletedProductCount = null,
    new: newProductCount = null,
    reject: rejectedProductCount = null,
    review: reviewProductCount = null,
    reviewed: reviewedProductCount = null,
    total: totalProductCount = null,
  } = productCount;

  const {
    approved: approvedProviderCount = null,
    new: pendingApprovalProviderCount = null,
    total: totalProviderCount = null,
  } = vendorCount;

  const {
    approved: approvedOperatorCount = null,
    new: pendingApprovalOperatorCount = null,
    total: totalOperatorCount = null,
  } = operatorCount;

  const config = {
    "/": [
      {
        caption: cms("section.quickReport.caption.order"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.order"),
      },
      {
        caption: cms("section.quickReport.caption.product"),
        count: totalProductCount,
        label: cms("section.quickReport.label.product"),
      },
      {
        caption: cms("section.quickReport.caption.vendor"),
        count: totalProviderCount,
        label: cms("section.quickReport.label.vendor"),
      },
      {
        caption: cms("section.quickReport.caption.invite"),
        count: totalInviteCount,
        label: cms("section.quickReport.label.invite"),
      },
    ],
    "/invitation": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalInviteCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newInviteCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.join"),
        count: joinedInviteCount,
        label: cms("section.quickReport.label.join"),
      },
      {
        caption: cms("section.quickReport.caption.revoke"),
        count: revokedInviteCount,
        label: cms("section.quickReport.label.revoke"),
      },
      {
        caption: cms("section.quickReport.caption.pending"),
        count: pendingInviteCount,
        label: cms("section.quickReport.label.pending"),
      },
    ],
    "/orders": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.acceptance"),
        count: pendingAcceptanceOrderCount,
        label: cms("section.quickReport.label.acceptance"),
      },
      {
        caption: cms("section.quickReport.caption.shipment"),
        count: pendingShipmentOrderCount,
        label: cms("section.quickReport.label.shipment"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedOrderCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.ship"),
        count: shippedOrderCount,
        label: cms("section.quickReport.label.ship"),
      },
      {
        caption: cms("section.quickReport.caption.archive"),
        count: archivedOrderCount,
        label: cms("section.quickReport.label.archive"),
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: returnOrderCount,
        label: cms("section.quickReport.label.return"),
      },
    ],
    "/payments": [
      {
        caption: cms("section.payments.caption.all"),
        count: totalPaymentCount,
        label: cms("section.payments.label.all"),
      },
      {
        caption: cms("section.payments.caption.forecast"),
        count: forcastedPaymentCount,
        label: cms("section.payments.label.forecast"),
      },
      {
        caption: cms("section.payments.caption.due"),
        count: duePaymentCount,
        label: cms("section.payments.label.due"),
      },
      {
        caption: cms("section.payments.caption.paid"),
        count: paidPaymentCount,
        label: cms("section.payments.label.paid"),
      },
      {
        caption: cms("section.payments.caption.dispute"),
        count: disputedPaymentCount,
        label: cms("section.payments.label.dispute"),
      },
    ],
    "/products": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalProductCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.approve"),
        count: approvedProductCount,
        label: cms("section.quickReport.label.approve"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newProductCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.review"),
        count: reviewProductCount,
        label: cms("section.quickReport.label.review"),
      },
      {
        caption: cms("section.quickReport.caption.reviewed"),
        count: reviewedProductCount,
        label: cms("section.quickReport.label.reviewed"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedProductCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.delete"),
        count: deletedProductCount,
        label: cms("section.quickReport.label.delete"),
      },
    ],
    "/providers": [
      {
        caption: cms("sectionContent.quickReport.caption.all"),
        count: totalProviderCount,
        label: cms("sectionContent.quickReport.label.all"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.pending"),
        count: pendingApprovalProviderCount,
        label: cms("sectionContent.quickReport.label.pending"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.approve"),
        count: approvedProviderCount,
        label: cms("sectionContent.quickReport.label.approve"),
      },
    ],
    "/operators": [
      {
        caption: cms("sectionContent.quickReport.caption.all"),
        count: totalOperatorCount,
        label: cms("sectionContent.quickReport.label.all"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.pending"),
        count: pendingApprovalOperatorCount,
        label: cms("sectionContent.quickReport.label.pending"),
      },
      {
        caption: cms("sectionContent.quickReport.caption.approve"),
        count: approvedOperatorCount,
        label: cms("sectionContent.quickReport.label.approve"),
      },
    ],
  };
  return config[path];
};

export default quickReportConfig;
