import React from "react";

// import generic
import Export from "./generic/feature/export/export";

const ExportVendor = () => {
  const getComponent = () => {
    return <Export />;
  };
  const ExportVendorComponent = getComponent();
  return ExportVendorComponent;
};

export default ExportVendor;
