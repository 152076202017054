import React, { useState, useContext } from "react";
import { useQuery } from "react-apollo";

// import { Button, DisplayText, Layout, Stack, Card, EmptyState } from "@shopify/polaris";
import { DisplayText, Layout, Stack } from "@shopify/polaris";
import { Table } from "react-bootstrap";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { Spinner } from "lib/components";

import { GET_VENDOR_INVOICE } from "app/paymentsLake/apollo/queries";

import css from "./style";

// const html2canvas = require("html2canvas");
// const JsPDF = require("jspdf");

// will be used to add autotable methods to jspdf, doc.autotable, won't work without including jspdf-autotable
require("jspdf-autotable");

const PaymentInvoice = () => {
  const { currentUser, match, cms } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const { gql } = constant;
  const [isInvoiceVisible] = useState(true);
  const [logo] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(false);
  // const [vendor, setVendor] = useState(false);
  const [orderDataRow, setOrderDataRow] = useState();
  const [orderLineItems, setOrderLineItems] = useState();
  const [invoice, setInvoice] = useState(false);
  const [sellerDetails, setSellerDetails] = useState(false);

  const { id } = match.params;
  const { data: invoiceData, loading: invoiceLoading } = useQuery(GET_VENDOR_INVOICE, {
    variables: {
      input: { id },
    },
  });

  if (invoiceLoading) {
    return <Spinner />;
  }

  const responseInvoiceError = baseHelper.getResponseError(invoiceData, gql.GET_VENDOR_INVOICE);
  const responseinvoiceData = baseHelper.getResponseData(invoiceData, gql.GET_VENDOR_INVOICE);

  if (responseInvoiceError) {
    return (
      <div>
        <h1>{cms("message.noInvoice")}</h1>
      </div>
    );
  }

  if (responseinvoiceData && !invoice) {
    const { getVendorInvoice: { data } = {} } = invoiceData;
    setInvoice(data);
    setSellerDetails(data.seller);
    setVendorDetails(data.vendor);
    setOrderDataRow(data.orderDataRow);
    setOrderLineItems(data.orderLineItemsData);
  }

  const productsList = (linePriceDetail) => {
    const productList = linePriceDetail.map((item) => (
      <tr className="item" key={item.storeOrderId}>
        <td colSpan="1" className="widthProduct">
          {item.name}
        </td>
        <td colSpan="1" className="widthSku">
          {item.sku || ""}
        </td>
        <td className="alignRight widthSales">
          {baseHelper.getPrice(baseHelper.isBigCommerceUser(currentUser) ? item.basePrice : item.price)}
        </td>
        <td className="alignRight widthFee">{baseHelper.getPrice(item.referalFee || 0)}</td>
        <td className="alignRight widthPrice">{baseHelper.getPrice(item.netPrice || 0)}</td>
        <td className="alignRight widthRate">{item.taxRate ? `${item.taxRate}%` : 0}</td>
        <td className="alignRight widthAmount">{baseHelper.getPrice(item.totalTax)}</td>
        <td className="alignRight widthQuantity">{item.quantity}</td>
        <td className="alignRight">
          {baseHelper.getPrice(baseHelper.isBigCommerceUser(currentUser) ? item.baseTotal : item.total)}
        </td>
      </tr>
    ));
    return productList;
  };

  const getTotalPrice = (orderLineItemData) => {
    let total = 0;
    if (orderLineItemData && orderLineItemData.length) {
      orderLineItemData.forEach((item) => {
        total += parseFloat(baseHelper.isBigCommerceUser(currentUser) ? item.baseTotal : item.total);
        if (item.commissionSeller) {
          total -= parseFloat(item.commissionSeller);
        }
      });
    }
    return total;
  };

  // const vendorCustomDetail = (vendorInfo) => {
  //   const vendorData = vendorInfo.map((data) => {
  //     const { label, value } = data;
  //     return (
  //       <div>
  //         <b>{label}</b> {value}
  //       </div>
  //     );
  //   });
  //   return vendor;
  // };

  // const pdfToHTML = () => {
  //   const { params } = match;
  //   const input = document.getElementById("divToPrint");
  //   html2canvas(input, { scale: 3 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new JsPDF("P");

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     // const pdfHeight = pdf.internal.pageSize.getHeight();

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, 100);
  //     pdf.save(`${params.id}.pdf`);
  //   });
  // };

  // const dispatchedDate =
  //     (orderDispatchedDate && (
  //       <div>
  //         <b>{label.invoiceTaxDate} </b> {orderDispatchedDate} <br />
  //         <b>{label.issueDate}</b> {orderDispatchedDate} <br />
  //       </div>
  //     )) ||
  //     "";
  //   const invoiceNumber = baseHelper.getInvoiceNumber(vendor, orderLine);

  //   const companyNumber =
  //     (seller && seller.companyNumber && (
  //       <div>
  //         <b>{label.companyNumber}</b> {seller.companyNumber}
  //       </div>
  //     )) ||
  //     "";

  //   const vatTaxNumber =
  //     (seller && seller.vatTaxNumber && (
  //       <div>
  //         <b>{label.vatTaxNumber}</b> {seller.vatTaxNumber}
  //       </div>
  //     )) ||
  //     "";

  return (
    <div className="Polaris-Page__Invoice">
      <Layout>
        {/* {isInvoiceVisible && (
          <Layout.Section>
            <Stack>
              <Stack.Item>
                <Button primary onClick={() => pdfToHTML()}>
                  {cms("label.download")}
                </Button>
              </Stack.Item>
            </Stack>
          </Layout.Section>
        )} */}
        {isInvoiceVisible && (
          <Layout.Section>
            <span className="pull-right hidden-print" id="hide-element">
              <a href="" onClick={baseHelper.printInvoice} className="btn-print">
                {/* <i className="fa fa-print t-plus-1 fa-fw fa-lg" /> */}
                {cms("label.print")}
              </a>
            </span>
            <Stack>
              <Stack.Item>
                <style>{css}</style>
                <div id="divToPrint" className="invoice-box">
                  <Table responsive>
                    <tr>
                      <table className="text-capitalize marginBottom">
                        <tr className="top">
                          <td colSpan="3" className="text-uppercase">
                            <DisplayText size="small">
                              <b>{cms("label.title")}</b>
                            </DisplayText>
                          </td>
                          <td colSpan="5" className="alignRight text-uppercase">
                            {(logo && (
                              <img
                                src={logo}
                                id="tableBanner"
                                style={{ "max-width": "150px", "max-height": "100px" }}
                                alt="Logo"
                              />
                            )) || (
                              <DisplayText size="large">
                                {(vendorDetails && vendorDetails.description) || vendorDetails.brandName}
                              </DisplayText>
                            )}
                          </td>
                        </tr>
                      </table>
                      <table className="text-capitalize">
                        <tr>
                          <td colSpan="3">
                            <b className="text-uppercase">{cms("label.from")}</b>
                            <p className="wordBreak">
                              {(vendorDetails && vendorDetails.description) || vendorDetails.brandName}
                            </p>
                            <p className="wordBreak">{vendorDetails.address}</p>
                            <p className="wordBreak">{vendorDetails.city}</p>
                            <p className="wordBreak">
                              {vendorDetails.country}
                              {vendorDetails.pinCode ? `, ${vendorDetails.pinCode}` : ""}
                            </p>
                          </td>
                          <td className="cFields" colSpan="5">
                            {orderLineItems && orderLineItems[0].dispatchedDate ? (
                              <>
                                <b>{cms("label.invoiceTaxDate")}</b>
                                {` ${baseHelper.formatDate(orderLineItems[0].dispatchedDate)}`}
                                <br />
                                <b>{cms("label.issueDate")}</b>
                                {` ${baseHelper.formatDate(orderLineItems[0].dispatchedDate)}`}
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                            {orderLineItems && orderLineItems[0].invoiceNumber ? (
                              <>
                                <b>{cms("label.invoice")}</b>
                                {` ${orderLineItems[0].invoiceNumber}`}
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </table>
                      <hr />
                      <table className="text-capitalize">
                        <tr>
                          {sellerDetails && (
                            <td colSpan="4">
                              <b className="text-uppercase">{cms("label.to")}</b>
                              <br />
                              {`${sellerDetails.firstName} ${sellerDetails.lastName}`}
                              {sellerDetails.firstName && sellerDetails.lastName ? <br /> : ""}
                              {sellerDetails.address ? `${sellerDetails.address}` : ""}
                              {sellerDetails.address ? <br /> : ""}
                              {sellerDetails.city ? `${sellerDetails.city}` : ""}
                              {sellerDetails.city ? <br /> : ""}
                              {sellerDetails.pinCode ? `${sellerDetails.pinCode}` : ""}
                              {sellerDetails.pinCode ? <br /> : ""}
                              {sellerDetails.country ? `${sellerDetails.country}` : ""}
                            </td>
                          )}
                          {orderDataRow && (
                            <td className="alignRight" colSpan="4">
                              {/* {companyNumber} */}
                              {/* {vatTaxNumber} */}
                              <b>{cms("label.purchaseOrderNumber")}</b>
                              {` ${orderDataRow.storeOrderId ? orderDataRow.storeOrderId : orderDataRow.number}`}
                              <br />
                            </td>
                          )}
                        </tr>
                      </table>
                      <hr />
                      <table className="text-capitalize">
                        <tr className="heading">
                          <td className="text-uppercase fontWeight" colSpan="1">
                            {cms("label.item")}
                          </td>
                          <td className="text-uppercase fontWeight" colSpan="1">
                            {cms("label.productDescription")}
                          </td>
                          <td className="alignRight fontWeight text-uppercase">
                            <span>{cms("label.sales")}</span>
                            <span>{cms("label.price")}</span>
                            {cms("label.nett")}
                          </td>
                          <td className="alignRight fontWeight text-uppercase">{cms("label.referralFee")}</td>
                          <td className="alignRight fontWeight text-uppercase">
                            {cms("label.nett")}
                            {cms("label.price")}
                          </td>
                          <td className="alignRight fontWeight text-uppercase">{cms("label.taxRate")}</td>
                          <td className="alignRight fontWeight text-uppercase">{cms("label.taxAmount")}</td>
                          <td className="alignRight fontWeight text-uppercase quantityAndTotalLabel">
                            {cms("label.quantity")}
                          </td>
                          <td className="alignRight fontWeight text-uppercase quantityAndTotalLabel">
                            {cms("label.totalWithoutColon")}
                          </td>
                        </tr>
                      </table>
                      <hr />
                      <table className="text-capitalize">{orderLineItems && productsList(orderLineItems)}</table>
                      <hr />
                      <table className="text-capitalize">
                        <tr>
                          <td colSpan="4">
                            <b className="text-uppercase">{cms("label.orderDetail")}</b>
                            <br />
                            <b>
                              {cms("label.totalWithoutColon")}
                              {`${cms("label.referralFee")}`}
                              :
                            </b>
                            &nbsp;
                            {`${moneyFormat} `}
                            {(orderDataRow &&
                              orderDataRow.referalFee &&
                              baseHelper.getPrice(orderDataRow.referalFee)) ||
                              0}
                            <br />
                            <b>
                              <tr>
                                {cms("label.vatTax")}
                                {/* {"order.currency"} */}
                                {orderDataRow && (
                                  <>
                                    &nbsp;&nbsp;
                                    <td style={{ padding: 0 }}>
                                      <b>{cms("label.nett")}</b>
                                      <br />
                                      {baseHelper.getPrice(orderDataRow.total)}
                                    </td>
                                    &nbsp;
                                    <td style={{ padding: 0 }}>
                                      <b>{cms("label.taxAmount")}</b>
                                      <br />
                                      {orderDataRow.tax && baseHelper.getPrice(orderDataRow?.tax?.total)}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </b>
                          </td>
                          {orderDataRow && (
                            <td colSpan="4" className="alignRight">
                              <b>{cms("label.subTotal")}</b>
                              {` ${moneyFormat} ${baseHelper.getPrice(getTotalPrice(orderLineItems))}`}
                              <br />
                              <b>{cms("label.vatTaxGoods")}</b>
                              {` ${moneyFormat} ${orderDataRow.tax && baseHelper.getPrice(orderDataRow?.tax?.total)}`}
                              <br />
                              <b>
                                {cms("label.plusShippingBonus")}
                                {` (${cms("label.nett")})`}
                              </b>
                              {`: ${moneyFormat} ${baseHelper.getPrice(orderDataRow.shippingTotal)}`}
                              <br />
                              <b>
                                {cms("label.vatTaxTotal")}
                                {/* {(shippingTaxRate && `(${shippingTaxRate}${label.onShipping}):`) || `:`} */}
                              </b>
                              {`: ${moneyFormat} ${orderDataRow.tax && baseHelper.getPrice(orderDataRow?.tax?.total)}`}
                              <br />
                              {orderLineItems && orderLineItems[0].commissionSeller ? (
                                <>
                                  <b>{cms("label.sellerCommission")}</b>
                                  {`: ${moneyFormat} ${baseHelper.getPrice(orderLineItems[0].commissionSeller)}`}
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <b className="text-uppercase">{cms("label.total")}</b>
                              {` ${moneyFormat} ${baseHelper.getPrice(getTotalPrice(orderLineItems))}`}
                            </td>
                          )}
                        </tr>
                      </table>
                      <hr />
                      {/* {noteCustomField && (
                        <tr className="information">
                          <td colSpan="8">{noteCustomField.value}</td>
                        </tr>
                      )} */}
                    </tr>
                  </Table>
                </div>
                <br />
              </Stack.Item>
            </Stack>
          </Layout.Section>
        )}
      </Layout>
    </div>
  );
};

export default PaymentInvoice;
