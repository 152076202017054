import React, { useContext } from "react";
import { Page, EmptyState } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

const NotAllowed = () => {
  const { history } = useContext(PrivateContext);
  return (
    <Page>
      <EmptyState
        heading="Sorry"
        action={{
          content: "Go Back",
          onAction: () => history.push("/"),
        }}
        secondaryAction={{
          content: "Learn more about MarketCube",
          url: "http://marketcube.io",
        }}
      >
        <p>You are not allowed to use this feature.</p>
      </EmptyState>
    </Page>
  );
};

export default NotAllowed;
