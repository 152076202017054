import React, { useState, useEffect, useContext } from "react";
import {
  Banner as PolarisBanner,
  Button,
  Caption,
  Form,
  FormLayout,
  Layout,
  List,
  TextContainer,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import gql
import { UPDATE_COMMISSION } from "app/setup/apollo/mutations";

// import components
import { Banner, Spinner, CardFooter } from "lib/components";

// import propTypes
import { rangeProp } from "app/setup/modules/operator/features/commission/props";

// import subFeature
import RenderCommissionRange from "./subFeature/renderRange";

const RangeEnhancementCommission = (props) => {
  const {
    advanceCommissionValue,
    refetchLoading,
    standardCommissionValue,
    rangeCommissionValue,
    priorityValue,
    setRangeValue,
    isTaxInclusive,
  } = props;
  // state
  const [count, setCount] = useState(0);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [addRange, setAddRange] = useState([]);
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const { cms = {}, currentUser } = useContext(PrivateContext);
  const { isStripeSubscription = false } = currentUser;
  const {
    PERCENTAGE: PERCENTAGE_TEXT,
    COMMISSION,
    MAX,
    value: { MAX_PERCENTAGE },
    gql: { UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
  } = constant;
  // gql state
  const [setRangeCommission, { loading: updateRangeCommissionLoading }] = useMutation(UPDATE_COMMISSION);

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  const validateRangeKey = (row) => {
    return Object.keys(row).some((key) => !row[key]);
  };

  const validateRangeCommission = (row) => {
    return row.type === PERCENTAGE_TEXT && row.commission > MAX_PERCENTAGE;
  };

  const validateRangeMax = (row, index) => {
    const secondLastRow = addRange[index - 1];
    return secondLastRow.max + 1 >= row.max;
  };

  const addRow = () => {
    const row = { max: null, type: null, commission: null };
    addRange.push(row);
    setCount(count + 1);
    setAddRange(addRange);
    dismissBanner();
  };

  const setValidationBanner = (validationMessage) => {
    setBanner({ isOpen: true, status: "critical", title: validationMessage });
  };

  const rangeItemValidation = () => {
    return addRange.some((row, index) => {
      const isInvalidKey = validateRangeKey(row);
      const isInValidCommission = validateRangeCommission(row);
      const isInValidMax = index > 0 && validateRangeMax(row, index);
      if (isInvalidKey) {
        setValidationBanner(cms("message.error.invalidRowDetails"));
        return true;
      }
      if (isInValidMax) {
        setValidationBanner(cms("message.error.invaildEndRangeValue"));
        return true;
      }
      if (isInValidCommission) {
        setValidationBanner(cms("message.error.percentageUpperLimit"));
        return true;
      }
      return null;
    });
  };

  const addRangeItem = () => {
    const { length } = addRange;
    // don't check validation if no commission range added
    if (length === 0) {
      addRow();
      return;
    }
    const rangeValue = rangeItemValidation();
    if (!rangeValue) {
      addRow();
    }
  };
  const removeItem = (item) => {
    setSubmitEnable(true);
    addRange.splice(item, 1);
    setCount(count - 1);
    setAddRange(addRange);
  };
  const validateUpdatedRange = (val, item, option) => {
    if (val.length && !baseHelper.validatePositiveNumericValues(val)) {
      return;
    }
    if (addRange[item].type === constant.PERCENTAGE && Number(val) > constant.value.MAX_PERCENTAGE) {
      return;
    }
    if (option === COMMISSION) {
      addRange[item].commission = parseFloat(val) || "";
    }
    if (option === MAX) {
      addRange[item].max = parseFloat(val) || "";
    }
  };
  const updateVal = (option, val, item) => {
    if (option === COMMISSION || option === MAX) {
      validateUpdatedRange(val, item, option);
    } else {
      addRange[item].commission = null;
      addRange[item][option] = val;
    }
    setAddRange([...addRange]);
    setRangeValue([...addRange]);
    setSubmitEnable(true);
  };
  const renderRangeItems = () => {
    return <RenderCommissionRange addRange={addRange} count={count} removeItem={removeItem} updateVal={updateVal} />;
  };
  const updateAdvanceCommission = async (requestData) => {
    let bannerContent = {};
    try {
      const res = await setRangeCommission({
        variables: { input: requestData },
      });
      setRangeValue([...addRange]);
      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);
      bannerContent = {
        isOpen: true,
        status: "success",
        title: cms("message.success"),
      };
      setSubmitEnable(false);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        bannerContent = { isOpen: true, status: "critical", title: errorResponse };
      }
      setBanner(bannerContent);
    } catch (exception) {
      bannerContent = { isOpen: true, status: "critical", title: errorHelper.parse(exception) };
      setBanner(bannerContent);
    }
  };
  useEffect(() => {
    if (rangeCommissionValue.length) {
      const rangeLength =
        (rangeCommissionValue && Array.isArray(rangeCommissionValue) && rangeCommissionValue.length) || 0;
      const commissionRangeData = rangeCommissionValue.map((obj) => {
        const { min, ...rest } = obj;
        return rest;
      });
      setCount(rangeLength);
      setAddRange(commissionRangeData);
    }
  }, [rangeCommissionValue]);

  const mapAdvanceCommissionPrice = () => {
    return advanceCommissionValue.advancedValues.map((item) => {
      const updatedItem = { ...item };
      if (item.price) {
        updatedItem.price = parseFloat(item.price);
      }
      return updatedItem;
    });
  };
  const validateAllRangeValue = () => {
    if (count === 0 && rangeCommissionValue.length === 0) {
      return true;
    }
    return rangeItemValidation();
  };
  const handleSubmit = () => {
    const isAnyAdvanceValue =
      advanceCommissionValue &&
      advanceCommissionValue.advancedValues &&
      Array.isArray(advanceCommissionValue.advancedValues) &&
      advanceCommissionValue.advancedValues.length !== 0;

    const requestData = {
      type: standardCommissionValue.selectedStandardType,
      price: parseFloat(standardCommissionValue.standardAmount),
      advancedValues: (isAnyAdvanceValue && mapAdvanceCommissionPrice()) || [],
      ruleBy: priorityValue,
      rangeValues: [...addRange],
      isTaxInclusive,
    };
    if (validateAllRangeValue()) {
      return;
    }
    updateAdvanceCommission(requestData);
  };
  if (refetchLoading) {
    return (
      <Layout.Section>
        <Spinner />
      </Layout.Section>
    );
  }
  const rangeCaptions = cms("section.commissionByPrice.caption") || [];
  const renderCaptions = () => {
    return rangeCaptions.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>{caption}</Caption>
        </List.Item>
      );
    });
  };
  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            isScrollTop={false}
            onDismiss={() => dismissBanner()}
          >
            {banner.message}
          </Banner>
          <br />
        </>
      )}
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          {renderRangeItems()}
          <TextContainer>
            <Button plain id="addRange" onClick={addRangeItem} disabled={isStripeSubscription}>
              {cms("common.button.addMore")}
            </Button>
          </TextContainer>
        </FormLayout>
        <br />
        <PolarisBanner status="info">
          <p>{cms("common.label.option")}</p>
          <br />
          <p>
            <List type="bullet">{renderCaptions()}</List>
          </p>
        </PolarisBanner>
        <CardFooter disabled={!submitEnabled} loading={updateRangeCommissionLoading || false} />
      </Form>
    </>
  );
};

RangeEnhancementCommission.propTypes = rangeProp.type;

export default RangeEnhancementCommission;
