// import packages
import React, { useState, useContext, useEffect } from "react";
import { Banner as PolarisBanner, Button, Form, FormLayout, Layout, TextContainer, Stack } from "@shopify/polaris";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";

// import propTypes
import { advanceProp } from "app/setup/modules/operator/features/commission/props";

// import components
import { Banner, Toast } from "lib/components";
import TransactionTaxItem from "./transactionTaxItem";
// import { parseInt } from "lodash";

const TransactionTax = (props) => {
  const {
    PERCENTAGE,
    LABEL_NAME,
    FEE_TYPE,
    FEE_VALUE,
    gql: { UPDATE_TRANSACTION_FEE_SETTING_TEXT },
    value: { MAX_PERCENTAGE },
  } = constant;
  const { cms = {}, currentUser = {}, history } = useContext(PrivateContext);
  const { isStripeSubscription = false } = currentUser;

  const {
    transactionTax,
    refetchLoading,
    setTransactionTaxFee,
    setTransactionTax,
    dbTransactionFeeList,
    refetch,
    setLoadButton,
  } = props;
  // const [allSuppliers, setAllSuppliers] = useState([]);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [state, setState] = useState({
    count: 1,
    transactionList: [{ labelName: null, feeType: null, feeValue: null }],
    ruleBy: LABEL_NAME,
  });
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const advancedValueLength = (transactionTax && Array.isArray(transactionTax) && transactionTax.length) || 0;
    const isEqualArray = baseHelper.isItemEqual(transactionTax, state.transactionList);
    // setState({
    //   count: advancedValueLength,
    //   transactionList: transactionTax,
    // });
    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        transactionList: transactionTax,
      });
    }
  }, [transactionTax, state.transactionList]);

  const updateVal = (option, val, item) => {
    setSubmitEnable(val);
    setBanner({ isOpen: false, status: "", title: "" });
    const { transactionList } = state;
    let duplicate = false;
    if (option === FEE_TYPE) {
      transactionList[item][FEE_TYPE] = null;
    }
    if (option === LABEL_NAME || option === FEE_TYPE) {
      let labelName = "";
      let labelNameCurrent = "";
      transactionList.forEach((advanceData) => {
        labelName = transactionList[item][LABEL_NAME] ? transactionList[item][LABEL_NAME].toLowerCase() : "";
        labelNameCurrent = advanceData.labelName ? advanceData.labelName.toLowerCase() : "";

        if (labelName === labelNameCurrent && advanceData.feeType === val) {
          transactionList[item][LABEL_NAME] = null;
          setBanner({ isOpen: true, status: "critical", title: cms("section.message.error.duplicate") });
          duplicate = true;
          setSubmitEnable(false);
        }
        return null;
      });
    }
    if (duplicate) {
      return;
    }
    if (option === FEE_VALUE) {
      if (val.length && !baseHelper.validatePositiveNumericValues(val)) {
        return;
      }
      if (state.transactionList[item].value === constant.PERCENTAGE && Number(val) > constant.value.MAX_PERCENTAGE) {
        return;
      }
      transactionList[item].feeValue = val;
    } else {
      transactionList[item][option] = val;
    }
    setState({ ...state, transactionList });
    setTransactionTax(transactionList);
  };

  const add = () => {
    const { transactionList, count } = state;
    const { length } = transactionList;
    if (length > 0) {
      const line = transactionList[length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({ isOpen: true, status: "critical", title: cms("section.message.error.inCompleteRow") });
        setSubmitEnable(false);
        return;
      }
    }

    const row = { labelName: null, feeType: null, feeValue: null };
    transactionList.push(row);
    setState({ ...state, count: count + 1, transactionList });
  };

  const removeItem = (item) => {
    setSubmitEnable(true);
    const { transactionList } = state;
    transactionList.splice(item, 1);
    setState({ ...state, count: state.count - 1, transactionList });
  };

  const renderAdvanceItems = () => {
    const { count, transactionList } = state;
    return (
      <TransactionTaxItem
        transactionList={transactionList}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
      />
    );
  };

  const updateTransactionTax = async (requestData) => {
    try {
      setMessage("");
      const res = await setTransactionTaxFee({
        variables: { input: requestData },
      });
      const responseData = baseHelper.getResponseData(res.data, UPDATE_TRANSACTION_FEE_SETTING_TEXT);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_TRANSACTION_FEE_SETTING_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        setSubmitEnable(false);
        return;
      }
      setSubmitEnable(false);
      setMessage(dbTransactionFeeList ? cms("section.message.success.update") : cms("section.message.success.save"));

      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
      setSubmitEnable(false);
    }
  };

  let { transactionList } = state || [];

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  const handleSubmit = () => {
    if (!submitEnabled) {
      return;
    }
    setLoadButton("formButton");
    const requestData = {
      labels: transactionList,
      isEnabled: true,
    };
    let isEmpty = false;
    let isInvalidValue = false;
    if (transactionList && transactionList.length) {
      transactionList.map((row) => {
        const keys = Object.keys(row);
        // eslint-disable-next-line no-unused-vars
        let count = 0;
        keys.map((key) => {
          if (row[key]) {
            count += 1;
          }
          return null;
        });
        // eslint-disable-next-line no-param-reassign
        row.feeValue = parseFloat(row.feeValue || 0);
        if (row?.feeType?.toLowerCase() === PERCENTAGE && row.feeValue > MAX_PERCENTAGE) {
          isInvalidValue = true;
        }
        if (!row.labelName || !row.feeType || parseFloat(row.feeValue) === 0) {
          isEmpty = true;
        }
        return null;
      });
      transactionList = transactionList.filter((item) => item.feeType !== null && item.feeValue !== "NaN");
    }
    if (isInvalidValue) {
      setBanner({ isOpen: true, status: "critical", title: cms("section.message.error.percentageVal") });
      return;
    }
    if (isEmpty) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("section.message.error.inCompleteRow"),
      });
      setSubmitEnable(false);
      return;
    }
    requestData.labels = transactionList;
    updateTransactionTax(requestData);
  };
  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            isScrollTop={false}
            onDismiss={() => dismissBanner()}
          >
            {banner.message}
          </Banner>
          <br />
        </>
      )}
      <Form onSubmit={handleSubmit}>
        <FormLayout>{transactionList && renderAdvanceItems()}</FormLayout>
        {transactionList && (
          <TextContainer>
            <Button plain id="addLink" onClick={add} disabled={isStripeSubscription}>
              +{cms("section.access.button.vendor")}
            </Button>
          </TextContainer>
        )}
        <br />
        <br />
        <Stack>
          <Stack.Item fill>
            {" "}
            <Button secondary submit loading={false} onClick={() => history.push("/setting")}>
              {cms("common.button.cancel")}
            </Button>
          </Stack.Item>
          <Stack.Item>
            <Button primary submit disabled={!submitEnabled} loading={refetchLoading}>
              {dbTransactionFeeList
                ? cms("common.button.update")
                : cms("common.button.submit")}
              {/* {submitEnabled} */}
            </Button>
          </Stack.Item>
          <Toast message={message} timeout={5000} />
        </Stack>
      </Form>
    </>
  );
};

TransactionTax.propTypes = advanceProp.type;

export default withFeature(TransactionTax, { feature: constant.ADVANCE_COMMISSION });
