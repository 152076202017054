import React, { useContext, useCallback, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";
import { ProductProvider } from "app/product/modules/generic/context";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import Hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import prop
import { tabProp } from "app/product/modules/generic/propTypes";
import constant from "lib/constant/constant";
import addData, { getPanelComponent } from "app/product/modules/operator/features/add/config/addData";

const ServiceTabs = (props) => {
  const { setBanner } = props;
  const { cms, currentUser } = useContext(PrivateContext);
  const { aws = {}, isAttachmentVisible = false } = currentUser || {};
  const { bucket = "", path = "", id = "", secret = "" } = aws || {};
  const [selected, setSelected] = useState(0);
  const validServiceTabs = addData(cms).serviceTabs;
  if (!isAttachmentVisible && bucket && path && id && secret) {
    validServiceTabs.splice(-1);
  }
  const tabLength = validServiceTabs.length;

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  return (
    <>
      <Card>
        <Tabs tabs={validServiceTabs} selected={selected} setBanner={setBanner} />
      </Card>
      <ProductProvider
        value={{
          setBanner,
          handleTabChange,
        }}
      >
        <div className="productComponent">{getPanelComponent(selected, cms, tabLength, "serviceTabs")}</div>
      </ProductProvider>
    </>
  );
};

ServiceTabs.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(ServiceTabs), { feature: constant.PRODUCT_ADD_FORM });
