import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

// import context
import { PrivateContext } from "lib/context";
// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated } from "lib/components";
// import constant
import constant from "lib/constant/constant";
// import gql
import { FETCH_VENDOR_FIELD } from "app/advanceVendor/apollo/queries";
import { UPDATE_VENDOR_FIELD } from "app/advanceVendor/apollo/mutations";
// import SubFeatures
import {
  VendorSection,
  BrandSection,
  PaymentSection,
  ShippingSection,
  SocialLinkSection,
  CustomFieldSection,
} from "./subFeatures";

const ManageVendor = () => {
  const { history, currentUser, cms = {} } = useContext(PrivateContext);
  const { _id: currentUserId } = currentUser;
  const { gql } = constant;
  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [isShippingEnable, setIsShippingEnable] = useState(false);
  const [shippingLoading, setShippingLoading] = useState(false);
  const [isSocialLinkEnable, setIsSocialLinkEnable] = useState(false);
  const [socialLinkLoading, setSocialLinkLoading] = useState(false);
  const [customFieldInformation, setCustomFieldInformation] = useState({});
  const [isFieldUpdate, setIsFieldUpdate] = useState(false);
  const customFieldData = {
    isField1Enabled: false,
    isField2Enabled: false,
    isField3Enabled: false,
    isField4Enabled: false,
    isField5Enabled: false,
    label1: "",
    label2: "",
    label3: "",
    label4: "",
    label5: "",
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
  };
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  // useQuery Calls to fetch data.
  const { loading: fetchLoading, data, error: fetchVendorError } = useQuery(FETCH_VENDOR_FIELD, {
    variables: { input: { sellerId: currentUserId } },
  });

  const [setManageVendorFields, { loading }] = useMutation(UPDATE_VENDOR_FIELD);

  useEffect(() => {
    if (!data) {
      return;
    }
    const responseData = baseHelper.getResponseData(data, gql.GET_VENDOR_FIELD);
    const responseError = baseHelper.getResponseError(data, gql.GET_VENDOR_FIELD);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { isPayment = false, isShipping = false, isSocialLink = false, customFields = false } =
      (responseData && responseData.vendorField) || {};
    setIsPaymentEnable(isPayment);
    setIsShippingEnable(isShipping);
    setIsSocialLinkEnable(isSocialLink);
    if (customFields && customFields.length) {
      customFields.forEach((item) => {
        if (item.inputType === "textField") {
          if (!customFieldData.isField1Enabled) {
            customFieldData.isField1Enabled = true;
            customFieldData.label1 = item.label;
          } else if (!customFieldData.isField2Enabled) {
            customFieldData.isField2Enabled = true;
            customFieldData.label2 = item.label;
          } else {
            customFieldData.isField5Enabled = true;
            customFieldData.label5 = item.label;
          }
        }
        if (item.inputType === "image") {
          customFieldData.isField3Enabled = true;
          customFieldData.label3 = item.label;
        }
        if (item.inputType === "file") {
          customFieldData.isField4Enabled = true;
          customFieldData.label4 = item.label;
        }
      });
    }
    setCustomFieldInformation(customFieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, gql.GET_VENDOR_FIELD]);

  useEffect(() => {
    if (fetchVendorError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(fetchVendorError) });
    }
  }, [fetchVendorError]);

  const setLoading = () => {
    setPaymentLoading(false);
    setShippingLoading(false);
    setSocialLinkLoading(false);
  };
  const updateVendorFields = async (fieldsToUpdate, updateStates, bannerMessage) => {
    let bannerContent = {};
    try {
      const res = await setManageVendorFields({
        variables: { input: fieldsToUpdate },
      });
      updateStates();
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_VENDOR_FIELD);
      bannerContent = {
        isOpen: true,
        status: constant.SUCCESS,
        title: bannerMessage,
      };
      setPaymentLoading(false);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_VENDOR_FIELD);
        bannerContent = { isOpen: true, status: constant.CRITICAL, title: errorResponse };
      }
      setBanner(bannerContent);
    } catch (exception) {
      setLoading();
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const togglePaymentSection = () => {
    setPaymentLoading(true);
    const fieldsToUpdate = {
      isPayment: !isPaymentEnable,
    };
    const updateStates = () => {
      setPaymentLoading(false);
      setIsPaymentEnable(!isPaymentEnable);
    };
    const bannerMessage = cms("message.success.paymentUpdated");
    updateVendorFields(fieldsToUpdate, updateStates, bannerMessage);
  };
  const toggleShippingSection = () => {
    setShippingLoading(true);
    const fieldsToUpdate = {
      isShipping: !isShippingEnable,
    };
    const updateStates = () => {
      setShippingLoading(false);
      setIsShippingEnable(!isShippingEnable);
    };
    const bannerMessage = cms("message.success.shippingUpdated");
    updateVendorFields(fieldsToUpdate, updateStates, bannerMessage);
  };
  const toggleSocialLinkSection = () => {
    setSocialLinkLoading(true);
    const fieldsToUpdate = {
      isSocialLink: !isSocialLinkEnable,
    };
    const updateStates = () => {
      setSocialLinkLoading(false);
      setIsSocialLinkEnable(!isSocialLinkEnable);
    };
    const bannerMessage = cms("message.success.socialLinksUpdated");
    updateVendorFields(fieldsToUpdate, updateStates, bannerMessage);
  };
  const handleCustomFieldsSectionChange = (value, key) => {
    setCustomFieldInformation((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setIsFieldUpdate(true);
  };
  const customFieldsCheck = () => {
    const {
      isField1Enabled,
      isField2Enabled,
      isField3Enabled,
      isField4Enabled,
      isField5Enabled,
      label1,
      label2,
      label3,
      label4,
      label5,
    } = customFieldInformation;

    if (isField1Enabled && !label1) {
      return true;
    }
    if (isField2Enabled && !label2) {
      return true;
    }
    if (isField3Enabled && !label3) {
      return true;
    }
    if (isField4Enabled && !label4) {
      return true;
    }
    if (isField5Enabled && !label5) {
      return true;
    }
    return false;
  };

  const getTextFieldDataToPush = (label) => {
    return {
      label,
      key: label,
      inputType: "textField",
      placeholder: label,
    };
  };

  const onsubmit = () => {
    const customFields = [];
    const isFieldEmpty = customFieldsCheck();
    if (isFieldEmpty) {
      setBanner({ isOpen: true, title: cms("message.error.label"), status: constant.CRITICAL });
      return false;
    }
    const {
      isField1Enabled,
      isField2Enabled,
      isField3Enabled,
      isField4Enabled,
      isField5Enabled,
      label1,
      label2,
      label3,
      label4,
      label5,
    } = customFieldInformation;
    if (isField1Enabled) {
      customFields.push(getTextFieldDataToPush(label1));
    }
    if (isField2Enabled) {
      customFields.push(getTextFieldDataToPush(label2));
    }
    if (isField3Enabled) {
      const dataToPush = {
        label: label3,
        key: label3,
        inputType: "image",
        placeholder: label3,
      };
      customFields.push(dataToPush);
    }
    if (isField4Enabled) {
      const dataToPush = {
        label: label4,
        key: label4,
        inputType: "file",
        placeholder: label4,
      };
      customFields.push(dataToPush);
    }
    if (isField5Enabled) {
      customFields.push(getTextFieldDataToPush(label5));
    }
    const fieldsToUpdate = {
      customFields,
    };
    const updateStates = () => {
      return null;
    };
    const bannerMessage = cms("message.success.updated");
    updateVendorFields(fieldsToUpdate, updateStates, bannerMessage);
    return false;
  };
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });
  if (fetchLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            isScrollTop={banner.isOpen}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <BrandSection />
      <VendorSection />
      <PaymentSection
        isPaymentEnable={isPaymentEnable}
        toggleSection={togglePaymentSection}
        paymentLoading={paymentLoading}
      />
      <ShippingSection
        isShippingEnable={isShippingEnable}
        toggleSection={toggleShippingSection}
        shippingLoading={shippingLoading}
      />
      <SocialLinkSection
        isSocialLinkEnable={isSocialLinkEnable}
        toggleSection={toggleSocialLinkSection}
        socialLinkLoading={socialLinkLoading}
      />
      <CustomFieldSection data={customFieldInformation} handleChange={handleCustomFieldsSectionChange} />
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => {
              onsubmit();
            },
            loading,
            disabled: !isFieldUpdate,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => history.push("/setting"),
            },
          ]}
        />
      </Layout.Section>
      <br />
    </>
  );
};

export default withFeature(withErrorBoundary(ManageVendor), { feature: constant.MANAGE_VENDOR });
