import { gql } from "apollo-boost";

const GET_SELLER_LIST = gql`
  query getSellerList($input: GetListInputV1) {
    getSellerList(input: $input) {
      data {
        count
        sellers {
          api {
            key
            status
          }
          email {
            address
            verified
          }
          brandName
          createdAt
          plan {
            name
            code
          }
          updatedAt
          _id
          roles {
            name
          }
        }
      }
      status
      error
    }
  }
`;

const VERIFY_OTP = gql`
  query verifyOtp($input: VerifyOtp) {
    verifyOtp(input: $input) {
      data {
        token
      }
      status
      error
    }
  }
`;

export { GET_SELLER_LIST, VERIFY_OTP };
