import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Stack } from "@shopify/polaris";

import { ADD_SERVICE_AVAILABILITY, EDIT_SERVICE_PRODUCT } from "app/product/apollo/mutations";

import { PrivateContext } from "lib/context/privateContext";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";
import { ProductContext } from "app/product/modules/generic/context";

import { Toast } from "lib/components";
import ServiceDetails from "./serviceDetails";
import SlotOptions from "./slotOptions";

// eslint-disable-next-line no-restricted-imports

const ServiceAvailbility = (props) => {
  const { cms, match, currentUser } = useContext(PrivateContext);
  const { roles } = currentUser;
  const { handleTabChange, setBanner } = useContext(ProductContext);
  const { address, availability, serviceId } = props;
  const { CRITICAL, gql, SELLER } = constant;

  const [days, setDays] = useState(constant.daysArray);
  const [endTime, setEndTime] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [horus, setHours] = useState([constant.TWENTY_FOUR]);
  const [maxDuration, setMaxDuration] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [selectedDays, setSelectedDays] = useState([constant.SEVEN_DAYS]);
  const [selectedHours, setSelectedHours] = useState([constant.TWENTY_FOUR]);
  const [startTime, setStartTime] = useState(constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN);

  const [capacity, setCapacity] = useState();
  const [duration, setDuration] = useState();
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const [disabledButton, setDisabledButton] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const [addProductService, { loading: addServiceLoading }] = useMutation(ADD_SERVICE_AVAILABILITY);
  const [updateProductService, { loading: updateServiceLoading }] = useMutation(EDIT_SERVICE_PRODUCT);

  useEffect(() => {
    if (availability && availability.hour) {
      setIsEdit(true);
      const { capacity: capacityData, duration: durationData, hour, locationId } = availability || {};
      const weekDays = Object.keys(hour).filter((item) => hour[item]);
      const availableDays = Object.values(hour).filter((item) => item);
      setDuration(durationData);
      setCapacity(capacityData);
      if (locationId && locationId.length) {
        setLocation(locationId[0]);
      }
      setDays(weekDays);
      if (availableDays.length < 7) {
        setSelectedDays(["custom-days"]);
      }
      if (availableDays.length === 7) {
        setSelectedDays([constant.SEVEN_DAYS]);
      }

      if (availableDays && availableDays.length) {
        const isTwenntyFourHours =
          availableDays[0].open === constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN &&
          availableDays[0].close === constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN;
        setSelectedHours(isTwenntyFourHours ? constant.TWENTY_FOUR : constant.CUSTOM);
        setStartTime(availableDays[0].open * 60);
        setEndTime(availableDays[0].close * 60);
      }
    }
  }, [availability]);

  useEffect(() => {
    if (selectedHours[0] === constant.CUSTOM) {
      setStartTime(constant.TIME_PICKER_START_TIME * 60 * 60);
      setEndTime(constant.TIME_PICKER_START_TIME * 60 * 60);
    } else {
      setMaxDuration(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
    }
  }, [selectedHours]);

  const onSubmit = async () => {
    const weekDays = {};
    const submitStartTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN : startTime / 60;
    const submitEndTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN : endTime / 60;
    const submitDays = selectedDays[0] === constant.SEVEN_DAYS ? constant.daysArray : days;
    // eslint-disable-next-line no-return-assign
    submitDays.map((day) => (weekDays[day] = { open: submitStartTime, close: submitEndTime }));
    const inputData = {
      capacity: parseInt(capacity || 0, 10),
      duration: parseFloat(duration || 0),
      hour: weekDays,
      productId: match.params.id,
      locationId: location ? [location] : [],
    };

    if (!isEdit) {
      await addProductService({ variables: { input: inputData } })
        .then((res) => {
          const responseError = baseHelper.getResponseError(res.data, gql.ADD_PRODUCT_SERVICE);
          const responseData = baseHelper.getResponseData(res.data, gql.ADD_PRODUCT_SERVICE);
          if (responseError) {
            setBanner({ isOpen: true, status: CRITICAL, title: responseError });
          }
          if (responseData) {
            // if (responseData) {
            //   setLoader(false);
            setMessage(cms("service.message.success.availability"));
            setTimeout(() => {
              handleTabChange(2);
            }, 1500);
          }
        })
        .catch((exception) => {
          setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
        });
      return true;
    }
    inputData.serviceId = serviceId;

    await updateProductService({ variables: { input: inputData } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT_SERVICE);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT_SERVICE);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          // if (responseData) {
          //   setLoader(false);
          setMessage(cms("service.message.success.availability"));
          setTimeout(() => {
            handleTabChange(2);
          }, 1500);
          // }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const renderDisabled = () => {
    return (
      !capacity ||
      !duration ||
      (selectedDays[0] === constant.CUSTOM_DAYS && !days.length) ||
      disabledButton
    );
  };

  return (
    <>
      <Card title={cms("service.label.serviceAvailability.title")}>
        <br />
        <p className="availability-description">
          {roles.name === SELLER
            ? cms("service.label.operatorDescription")
            : cms("service.label.serviceAvailability.description")}
        </p>
        <SlotOptions
          selected={days}
          horus={horus}
          setHours={setHours}
          startTime={startTime}
          endTime={endTime}
          setDays={setDays}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          selectedDays={selectedDays}
          selectedHours={selectedHours}
          setSelectedDays={setSelectedDays}
          setDisabledButton={setDisabledButton}
          setSelectedHours={setSelectedHours}
          setMaxDuration={setMaxDuration}
        />
        <ServiceDetails
          address={address}
          duration={duration}
          disabledButton={disabledButton}
          capacity={capacity}
          setCapacity={setCapacity}
          setDuration={setDuration}
          location={location}
          setLocation={setLocation}
          setDisabledButton={setDisabledButton}
          maxDuration={maxDuration}
        />
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button
            onClick={() => {
              handleTabChange(0);
            }}
          >
            {cms("button.previous")}
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button onClick={() => handleTabChange(2)}>{cms("button.skip")}</Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            primary
            disabled={renderDisabled()}
            loading={addServiceLoading || updateServiceLoading}
            onClick={() => onSubmit()}
          >
            {cms("button.save")}
          </Button>
        </Stack.Item>
      </Stack>
      <div className="toast">
        <Toast message={message} setToast={setMessage} />
      </div>
    </>
  );
};

export default ServiceAvailbility;
