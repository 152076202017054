import React, { useContext } from "react";
import { Card, TextContainer } from "@shopify/polaris";
import WixStoreConnect from "app/product/modules/provider/features/genric/wixConnect/wixConnect";
import { PrivateContext } from "lib/context";

import { withErrorBoundary } from "lib/hoc";

const WixStore = (prop) => {
  const { setBanner } = prop;
  const { cms } = useContext(PrivateContext);
  return (
    <>
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <WixStoreConnect setBanner={setBanner} />
        </Card.Section>
      </Card>
    </>
  );
};
export default withErrorBoundary(WixStore);
