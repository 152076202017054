import React, { useEffect } from "react";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import AddressModal from "app/setup/modules/generic/addressBook/subFeature/generic/addressModal";
import { ADD_ADDRESS } from "app/setup/apollo";

const Add = (props) => {
  const {
    active,
    addressData,
    addressDataResponse,
    cms,
    defaultAddress,
    error,
    isFirst,
    refetch,
    setActive,
    setAddressData,
    setBanner,
    setError,
    total,
  } = props;
  const [addAddressItem, { loading: addAddressLoading }] = useMutation(ADD_ADDRESS);
  const addAddress = async () => {
    setActive(true);
    const addressLabels = addressDataResponse.map((item) => item && item.label);
    const { label = "" } = addressData || {};

    if (addressLabels.includes(label)) {
      setError({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.duplicateLabel") });
      return;
    }
    const requestData = {
      ...addressData,
    };
    try {
      const val = await addAddressItem({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(val.data, constant.gql.ADD_ADDRESS);
      if (responseError) {
        setError({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setAddressData({ ...defaultAddress });
      setActive(!active);
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.add") });

      refetch();
    } catch (exception) {
      setError({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const handleChange = () => {
    setActive(!active);
  };

  const isFormValid = () => {
    const { address = "", city = "", country = "", label = "", postalCode = "" } = addressData || {};
    return label && address && city && country && postalCode;
  };

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={cms("section.add.title")}
      primaryAction={{
        content: cms("button.add.primary"),
        loading: addAddressLoading,
        disabled: !isFormValid(),
        onAction: () => addAddress(),
      }}
    >
      <AddressModal
        addActive={active}
        addressForm={addressData}
        banner={error}
        cms={cms}
        setAddressForm={setAddressData}
        setBanner={setError}
        total={total}
        isFirst={isFirst}
      />
    </Modal>
  );
};
export default Add;

Add.propTypes = {
  active: PropTypes.bool,
  addressData: PropTypes.shape(),
  cms: PropTypes.func,
  defaultAddress: PropTypes.shape(),
  error: PropTypes.shape(),
  refetch: PropTypes.func,
  setActive: PropTypes.func,
  setAddressData: PropTypes.func,
  setBanner: PropTypes.func,
  setError: PropTypes.func,
  total: PropTypes.number,
};

Add.defaultProps = {
  active: null,
  addressData: {},
  cms: () => {},
  defaultAddress: {},
  error: {},
  refetch: () => {},
  setActive: () => {},
  setAddressData: () => {},
  setBanner: () => {},
  setError: () => {},
  total: "",
};
