import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import { Layout, Link } from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import { withErrorBoundary } from "lib/hoc";

import { FETCH_SELLER_PLANS, GET_ASSOCIATED_SELLER } from "app/plans/apollo/queries";
import { CREATE_PLAN, UPDATE_PLAN_DETAILS } from "app/plans/apollo/mutations";

import { Skeleton, PlanCard } from "app/plans/modules/vendor/features/components/index";

const ChargebeeVendorPlan = (props) => {
  const { onSubmit = () => {}, submitLoading, currentPlan, error, banner, setBanner } = props;
  const { cms, currentUser, history, isLoading } = useContext(PrivateContext); //
  const { gql } = constant;
  const {
    customer = "",
    plan: planCode = "",
    charge_id: shopifyChargeId = "",
    sub = "",
  } = baseHelper.queryParamsFromLocation(history);

  const isChargebee = !!customer;
  const isUpdate = !!(currentUser && currentUser.plan && currentUser.plan.code) || false;

  const chargeId = isChargebee ? sub : shopifyChargeId;

  const [activePlan, setActivePlan] = useState("");
  const [createPlan, { loading: createPlanLoading }] = useMutation(CREATE_PLAN);
  const [updatePlan, { loading: updatePlanLoading }] = useMutation(UPDATE_PLAN_DETAILS);

  const { error: planError, loading: planLoading, data: planData } = useQuery(FETCH_SELLER_PLANS);

  const { loading: associatedSellerLoading, data: associatedSellerData } = useQuery(GET_ASSOCIATED_SELLER);
  const associatedSeller = baseHelper.getResponseData(associatedSellerData, gql.GET_ASSOCIATED_SELLER) || {};
  const { email: { address = "" } = {}, isChargebee: isChargeBee, chargebee: chargebeeUser = {} } =
    associatedSeller || {};
  const chargebeeEnabled = !!(isChargeBee && chargebeeUser);

  useEffect(() => {
    if (currentPlan) {
      setActivePlan(currentPlan);
    }
  }, [currentPlan]);

  const thenBlock = (res) => {
    const gqlPlanKey = isUpdate ? gql.UPDATE_PLAN_DETAILS : gql.CREATE_PLAN;
    const responseData = baseHelper.getResponseData(res.data, gqlPlanKey);

    if (responseData) {
      const title = isUpdate ? cms("message.success.update") : cms("message.success.select");

      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title,
      });
      setActivePlan(planCode);

      setTimeout(() => {
        return history.push("/");
      }, 1500);
    } else {
      const responseError = baseHelper.getResponseError(res.data, gqlPlanKey);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
  };

  const catchBlock = (exception) => {
    setBanner({
      isOpen: true,
      status: constant.CRITICAL,
      title: exception.message,
    });
  };

  useEffect(() => {
    if (chargeId && planCode && !isLoading) {
      const planInput = {
        code: planCode,
        chargeId,
      };

      if (customer && !isUpdate) {
        planInput.customer = customer;
      }

      if (isUpdate) {
        updatePlan({
          variables: {
            input: planInput,
          },
        })
          .then((res) => {
            thenBlock(res);
          })
          .catch((exception) => {
            catchBlock(exception);
          });
      } else {
        createPlan({
          variables: {
            input: planInput,
          },
        })
          .then((res) => {
            thenBlock(res);
          })
          .catch((exception) => {
            catchBlock(exception);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeId, createPlan, customer, isLoading, planCode]);

  if (associatedSellerLoading || planLoading || isLoading || createPlanLoading || updatePlanLoading) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={error} />
      </Layout.Section>
    );
  }

  if (planError) {
    const parsedError = errorHelper.parse(planError);
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={parsedError} />
      </Layout.Section>
    );
  }

  if (!chargebeeEnabled) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title="Chargebee Subscription is not enabled." />
      </Layout.Section>
    );
  }

  let plans = baseHelper.getResponseData(planData, "getSellerPlanList") || [];
  plans = plans.planList;
  if (plans.length === 0) {
    const emailAddress = <Link url={`mailto:${address}`}>{address}</Link>;
    const title = `${cms("common.subscription.notFound", { operatorEmail: address })}`;

    return (
      <Layout.Section>
        <Banner isOpen status={constant.INFO} title={title} />
      </Layout.Section>
    );
  }

  const chargeableSortedPlans = plans
    .filter((plan) => plan)
    .sort((first, second) => first.sortOrder - second.sortOrder);

  const getLayoutSections = () => {
    return chargeableSortedPlans.map((plan, index) => {
      const submitCallback = () => {
        onSubmit(plan);
      };
      return (
        <Layout.Section oneThird key={index}>
          <PlanCard plan={plan} callback={submitCallback} activePlan={activePlan} submitLoading={submitLoading} />
        </Layout.Section>
      );
    });
  };
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {chargebeeEnabled && (
        <Layout.Section>
          <Layout>{getLayoutSections()}</Layout>
        </Layout.Section>
      )}
    </>
  );
};

ChargebeeVendorPlan.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  currentPlan: PropTypes.string,
  error: PropTypes.string,
};

ChargebeeVendorPlan.defaultProps = {
  currentPlan: "",
  error: "",
};

export default withErrorBoundary(ChargebeeVendorPlan);
