import { gql } from "apollo-boost";

const PRODUCT_FORM = gql`
  mutation updateProductFormSetting($input: ProductFormSettingData) {
    updateProductFormSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default PRODUCT_FORM;
