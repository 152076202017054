import React, { useState, useContext, useEffect, useCallback } from "react";
import { Card, ChoiceList, Link, FormLayout, TextField, Select, Tag, TextContainer } from "@shopify/polaris";
import { ExportMinor } from "@shopify/polaris-icons";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// helpers
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import constant, { providerList } from "lib/constant/constant";

// lib component
import { ResourceList, Banner, ModalPopup, PermissionBanner, Toast } from "lib/components";

// import from apollo
import { GET_VENDOR_LIST, GET_VENDOR_DETAILS } from "app/vendors/apollo/queries";

import { ENABLE_DISABLE_VENDOR } from "app/vendors/apollo/mutations";
import VENDOR_LIST_SUBSCRIPTION from "app/vendors/apollo/subscription";

import VendorExport from "app/vendors/modules/operator/feature/export/export";

// cms
import { DeleteProvider, DisableProvider, LoginProvider } from "app/vendors/modules/generic/feature/list/subFeatures";
import QuickReport from "app/reports/quickReport";
import QuickAction from "app/reports/quickAction";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";
import cmsVendorList from "./cms/vendorListCMS";

// subFeatures
import ProviderList from "./subFeatures";

const OperatorProviderList = () => {
  const { history, cms, currentUser, chatSetting = {}, isShopifyCustomOperator } = useContext(PrivateContext);

  const { fulfillmentScopeExists = false, ecommercePlatform } = currentUser;
  const vendorListCMS = cmsVendorList(cms, ecommercePlatform);
  const { label, filterOptions, filterStatus, resourceName, promotedBulkActions, sortOptions } = vendorListCMS;
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [messageToast, setMessageToast] = useState("");
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [selectedFilter, setSelectedFilter] = useState(prevFilter.list_filter || "");
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const [taggedWith, setTaggedWith] = useState(prevFilter.list_search || "");
  const paramFilter = (prevFilter.filter && prevFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [isReasonField, setIsReasonField] = useState(false);
  const [isVerifyVendors, setIsVerifyVendors] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [singleVendor, setSingleVendor] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [totalVendor, setTotalVendor] = useState(0);
  const [disableVendorId, setDisableVendorId] = useState({});
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedValue, setSelectedValue] = useState([label.doNothing]);
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [queryData, setQueryData] = useState({});
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const selected = parseInt(prevFilter.tab, 10) || 0;

  const [dataToFetch, setDataToFetch] = useState({
    list_search: prevFilter.list_search,
    search: prevFilter.search,
    filter: prevFilter.filter,
    list_filter: prevFilter.list_filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || constant.CREATED_AT,
    sort_order: prevFilter.sort_order || constant.DESC,
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [activePopover, setActivePopover] = useState({});

  const inputData = {
    list_search: taggedWith ? encodeURI(taggedWith) : taggedWith,
    search: dataToFetch.search ? encodeURI(dataToFetch.search) : dataToFetch.search,
    filter: filterByStatus && filterByStatus.length ? filterByStatus : dataToFetch.filter,
    list_filter: selectedFilter ? encodeURI(selectedFilter) : selectedFilter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  const [
    getVendorLines,
    { data: getVendorData, loading: getVendorLoading, error: getVendorError },
  ] = useLazyQuery(GET_VENDOR_DETAILS, { variables: { input: queryData } });

  useEffect(() => {
    const vendorDetailResponseData = baseHelper.getResponseData(getVendorData, constant.gql.GET_VENDOR_DETAIL);

    if (vendorDetailResponseData && !getVendorLoading) {
      const { vendorStatus } = vendorDetailResponseData || {};
      const getStatus = [];
      if (vendorStatus.length) {
        vendorStatus.forEach((isApproved) => {
          if (isApproved === constant.APPROVE) {
            getStatus.push({
              id: isApproved || constant.APPROVE,
              name: constant.APPROVED,
            });
          }
          if (isApproved === constant.ONHOLD) {
            getStatus.push({
              id: isApproved || constant.ONHOLD,
              name: constant.ONHOLD_LABEL,
            });
          }
          if (isApproved === constant.REJECT) {
            getStatus.push({
              id: isApproved || constant.REJECT,
              name: constant.REJECTED_LABEL,
            });
          }
          if (isApproved === constant.IN_REVIEW) {
            getStatus.push({
              id: isApproved || constant.IN_REVIEW,
              name: constant.REVIEW,
            });
          }
          if (isApproved === constant.ON_HOLIDAY) {
            getStatus.push({
              id: isApproved || constant.ON_HOLIDAY,
              name: constant.HOLIDAY,
            });
          }
          if (isApproved === constant.PENDING_SMALL) {
            getStatus.push({
              id: isApproved || constant.PENDING_SMALL,
              name: constant.PENDING_APPROVAL,
            });
          }
          if (isApproved === constant.DISABLED) {
            getStatus.push({
              id: isApproved || constant.DISABLED,
              name: constant.DISABLED_CAPITAL,
            });
          }
        });
      }
      setStatusList(getStatus);
    }
  }, [getVendorData, getVendorLoading]);

  const linkData = [
    {
      caption: cms("common.actions.vendors.caption.add"),
      id: "addProvider",
      label: cms("common.actions.vendors.label.add"),
      primary: true,
      link: "/providers/add",
      icon: ExportMinor,
    },
  ];

  if (!ecommercePlatform) {
    const exportVendors = {
      action: () => {
        setModal(true);
        getVendorLines();
      },
      caption: cms("button.providerList.providerList.exportProvider"),
      id: "exportProvider",
      label: cms("button.providerList.providerList.exportProvider"),
      primary: true,
      icon: ExportMinor,
    };
    linkData.push(exportVendors);
  }

  if (chatSetting.isVendor) {
    const chatInbox = {
      caption: cms("button.providerList.providerList.chatInbox"),
      id: "chatInbox",
      label: cms("button.providerList.providerList.chatInbox"),
      link: "/chat",
      icon: ExportMinor,
    };
    linkData.push(chatInbox);
  }
  // useQuery Calls to fetch data.
  const {
    error: errorVendorList,
    loading: loadingVendorList,
    data: dataVendorList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_VENDOR_LIST, {
    variables: {
      input: inputData,
    },
  });

  // vendor enableDisable mutation
  const [enableDisableVendor, { loading: enableLoading }] = useMutation(ENABLE_DISABLE_VENDOR);

  const vendorListResponseData = baseHelper.getResponseData(dataVendorList, constant.gql.GET_VENDOR_LIST);
  const vendorListErrorData = baseHelper.getResponseError(dataVendorList, constant.gql.GET_VENDOR_LIST);

  useEffect(() => {
    setSelectedItems([]);
    if (vendorListResponseData) {
      const { vendors: vendorListData = [], count: totalCount = 0 } = vendorListResponseData;
      setVendorList(vendorListData);
      setTotalVendor(totalCount);
    }
  }, [vendorListResponseData]);

  useEffect(() => {
    if (vendorListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorListErrorData, isOpen: true });
    }
    if (errorVendorList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorList), isOpen: true });
    }
  }, [vendorListErrorData, errorVendorList]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  useEffect(() => {
    setTimeout(() => {
      setMessageToast("");
    }, 5000);
  }, [messageToast]);

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  // function for remove values of more filter.
  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }

    baseHelper.setUrl(history, clearParams);
    return null;
  };

  // end
  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    setFilterByStatus(null);
    handleSelectedFilterRemove();
  };

  const tagOption = {
    all: cms("label.providerList.all"),
    pending: cms("label.providerList.pendingApproval"),
    approve: cms("label.providerList.approved"),
    inReview: cms("label.providerList.review"),
    reject: cms("label.providerList.rejected"),
    onHold: cms("label.providerList.onHold"),
    onHoliday: cms("label.providerList.onHoliday"),
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {tagOption[option]}
        </Tag>
      ));
  }

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };
  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // function for delete vendor and verify vendor.
  const deleteBulkVendor = async (isDelete = false, isSingle = false, id = "") => {
    if (isDelete && !isSingle) {
      setIsVerifyVendors(false);
      setSingleVendor(selectedItems);
      setIsReasonField(true);
      setDeleteModal(true);
    } else if (isSingle && id) {
      setIsVerifyVendors(false);
      setSingleVendor([id]);
      setIsReasonField(false);
      setDeleteModal(true);
    } else {
      setIsVerifyVendors(true);
    }
  };

  // Subscription for vendorList Page.
  useEffect(() => {
    subscribeToMore({
      document: VENDOR_LIST_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  // login vendor function.
  const loginVendor = (id, brand, role) => {
    setLoginModal(true);
    setSelectedBrand({ id, brand, role });
  };

  // enableDisable vendor function.
  const handleEnableDisable = async (id, status) => {
    try {
      if (status) {
        const response = await enableDisableVendor({
          variables: { input: { vendorId: id, isRejected: false } },
        });
        const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_VENDOR_AVAILABILITY);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }
      } else {
        setDisableVendorId(id);
        setDisableModal(true);
        setSelectedValue([label.doNothing]);
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const renderItem = (item, _, index) => {
    return (
      <ProviderList
        item={item}
        keyIndex={index}
        onDeleteVendor={(vendorId) => deleteBulkVendor(false, true, vendorId)}
        onLoginVendor={(vendorId, brandName, role) => loginVendor(vendorId, brandName, role)}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        handleEnableDisable={handleEnableDisable}
        loading={enableLoading}
        setMessageToast={setMessageToast}
        popoverDisable={popoverDisable}
      />
    );
  };

  const bulkActions = [
    {
      content: label.verify_selected,
      onAction: () => deleteBulkVendor(false, false, selectedItems),
    },
    {
      content: label.delete_selected,
      destructive: true,
      onAction: () => deleteBulkVendor(true),
    },
  ];

  const bulkActionsApproved = [
    {
      content: label.delete_selected,
      onAction: () => deleteBulkVendor(true),
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.BRAND_FILTER:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return (
          cms(`label.providerList.${value}`) ||
          (value === constant.PAYMENT_METHOD ? cms("label.providerList.method") : value)
        );
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.BRAND_FILTER,
      label: disambiguateLabel(constant.BRAND_FILTER, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }
  const appliedFilters = appliedFilterArrObj;

  const customFilter = (
    <FormLayout>
      <Select
        label={label.show_all_vendor}
        value={selectedFilter || ""}
        onChange={handleSelectChange}
        placeholder={cms("label.providerList.select")}
        options={filterOptions}
      />
      {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
        null}
    </FormLayout>
  );

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };
  const statusValues = [
    providerList.IN_REVIEW_FILTER,
    providerList.ON_HOLIDAY_FILTER,
    providerList.REJECT_FILTER,
    providerList.ON_HOLD_FILTER,
  ];
  const filterStatusValues = filterStatus.filter((item) =>
    ecommercePlatform ? !statusValues.includes(item.value) : true
  );
  const filters = [
    {
      key: constant.BRAND_FILTER,
      label: label.filter_by,
      filter: customFilter,
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatusValues}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  const handleClose = () => {
    // setAppUpdate(!appUpdate);
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  return (
    <>
      {!isShopifyCustomOperator && appUpdate === "updated" && (
        <>
          <Banner
            isOpen
            action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
            title={cms("common.popup.migrate.title")}
            status="critical"
          >
            <p>{cms("common.popup.migrate.description")}</p>
          </Banner>
          <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
            <Card>
              <TextContainer>
                <Banner
                  isOpen
                  status="critical"
                  action={{
                    content: `${cms("common.appPermission.label.aboutCustom")}`,
                    url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                  }}
                >
                  <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                </Banner>
              </TextContainer>
            </Card>
            <UpgradAppPermission />
          </ModalPopup>
          <br />
        </>
      )}
      {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
      <br />
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <QuickReport />
      <QuickAction link={linkData} />
      <Card>
        <ResourceList
          resourceName={resourceName}
          items={vendorList && vendorList.length > 0 ? vendorList : []}
          idForItem={(vendors) => {
            const { _id: id } = vendors;
            return id;
          }}
          renderItem={renderItem}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          bulkActions={selected === 2 ? bulkActionsApproved : bulkActions}
          sortValue={sortValue}
          sortOptions={sortOptions}
          onSortChange={sortFilter}
          filters={filters}
          loading={loadingVendorList}
          onQueryChange={handleQueryValueChange}
          onQueryClear={handleQueryValueRemove}
          handleClearAll={handleClearAll}
          queryValue={queryValue}
          selectable
          appliedFilters={appliedFilters}
          page={currentPage}
          count={Number(totalVendor)}
          perPage={selectedLimit}
          setPage={handlePerPage}
          setPopoverDisable={setPopoverDisable}
          setPerPage={handleSelectLimit}
        />
      </Card>
      <VendorExport
        openModal={isOpenModal}
        setOpenModal={setModal}
        statusList={statusList}
        getVendorData={getVendorData}
        getVendorLoading={getVendorLoading}
        getVendorError={getVendorError}
        getVendorLines={getVendorLines}
        setQueryData={setQueryData}
        vendorListResponseData={vendorListResponseData}
      />
      <DeleteProvider
        openModal={deleteModal}
        isReasonField={isReasonField}
        dataToFetch={inputData}
        setDeleteModal={setDeleteModal}
        setBanner={setBanner}
        selectedItems={selectedItems}
        singleVendor={singleVendor}
        isVerifyVendors={isVerifyVendors}
        setIsVerifyVendors={setIsVerifyVendors}
      />
      <LoginProvider
        loginModalOpen={loginModal}
        closeLoginModal={() => {
          setSelectedBrand({});
          setLoginModal(false);
        }}
        brandName={selectedBrand}
        setBanner={setBanner}
        setLoginModal={setLoginModal}
        setSelectedBrand={setSelectedBrand}
        isAdvanceVendor={false}
      />
      <DisableProvider
        closeDisableModal={() => {
          setDisableModal(false);
        }}
        refetch={refetch}
        setBanner={setBanner}
        disableModalOpen={disableModal}
        disableVendorId={disableVendorId}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setMessageToast={setMessageToast}
      />
      <Toast message={messageToast} timeout={5000} />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProviderList), { feature: constant.VENDOR });
