import React, { useEffect, useState, useContext } from "react";
import { Button, Layout, Stack } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import baseHelper from "lib/helpers/base";
import { Spinner, Banner /* Sheet */ } from "lib/components";
import constant from "lib/constant/constant";

import { errorHelper } from "lib/helpers";
import {
  Attachment,
  TitleDescription,
  Shipping,
  Image,
  Organization,
  Inventory,
  Variants,
} from "app/productOld/modules/generic/edit/subFeatures";
import { GET_PRODUCT_AND_SELLER_PRODUCT, GET_PRODUCT_TAGS, GET_PRODUCT_TYPE } from "app/productOld/apollo/queries";
import { EDIT_PRODUCT, UPLOAD_FILE, UPLOAD_IMAGE } from "app/productOld/apollo/mutations";
import { Price, RenderVariants } from "./subFeatures";

const OperatorProductEdit = () => {
  const { currentUser, match, history, location = {}, cms, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  const { aws } = currentUser;
  const { bucket = "", path = "", id = "", secret = "" } = aws || {};
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [isVariants, setIsVariants] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    children: null,
    action: null,
  });
  const [existingProductImages, setExistingProductImages] = useState([]);
  const [existingProductAttachments, setExistingProductAttachments] = useState([]);
  const { loading: productLoading, error, data, refetch, networkStatus } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id }, notifyOnNetworkStatusChange: true },
  });
  const { loading: tagLoading, error: tagError, data: tagData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: typeLoading, error: typeError, data: typeData } = useQuery(GET_PRODUCT_TYPE);
  const [updateProduct, { loading: editLoading }] = useMutation(EDIT_PRODUCT);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);
  useEffect(() => {
    if (!(productLoading || tagLoading || typeLoading || networkStatus === 4)) {
      setLoading(false);
    }
  }, [productLoading, tagLoading, typeLoading, networkStatus]);

  useEffect(() => {
    if (error || typeError || tagError) {
      setBanner({ isOpen: true, title: errorHelper.parse(error || typeError || tagError), status: constant.CRITICAL });
    }
  }, [error, cms, tagError, typeError]);

  const productListError = baseHelper.getResponseError(data, gql.GET_PRODUCT_AND_SELLER_PRODUCT);
  const tagListError = baseHelper.getResponseError(tagData, gql.GET_PRODUCT_TAGS);
  const typeListError = baseHelper.getResponseError(typeData, gql.GET_PRODUCT_TYPE);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: productListError });
    }
    if (tagListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: tagListError });
    }
    if (typeListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: typeListError });
    }
    if (location && location.state) {
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.addedSuccessfully"),
        action: { content: cms("message.success.action.createOther"), url: "/products/add" },
      });
    }
  }, [location, productListError, setBanner, tagListError, typeListError, cms]);

  const productResponse = baseHelper.getResponseData(data, gql.GET_PRODUCT_AND_SELLER_PRODUCT);
  const tagListResponse = baseHelper.getResponseData(tagData, gql.GET_PRODUCT_TAGS);
  const typeListResponse = baseHelper.getResponseData(typeData, gql.GET_PRODUCT_TYPE);

  useEffect(() => {
    if (productResponse) {
      const {
        attachments,
        title,
        description,
        price,
        comparePrice,
        images,
        variants,
        sku,
        barcode,
        markUp,
        sellerDiscount,
        inventoryManagement,
        productId,
        weight,
        weightUnit,
        isShipping,
        quantity,
        productType,
        tags,
        updatedAt,
        measurement = {},
        vendor,
      } = productResponse.sellerProduct;
      setExistingProductImages(images || []);
      setExistingProductAttachments(attachments || []);
      setIsVariants(variants && variants.length);

      const length = (measurement && measurement.length && measurement.length.value) || 0;
      const width = (measurement && measurement.width && measurement.width.value) || 0;
      const height = (measurement && measurement.height && measurement.height.value) || 0;

      const variantData = variants.map((item) => {
        const variantContainer = {
          barcode: item.barcode,
          imageUrl: item.image,
          inventoryQuantity: item.inventoryQuantity,
          option1: item.option1,
          option2: item.option2,
          option3: item.option3,
          option1Val: item.option1Val,
          option2Val: item.option2Val,
          option3Val: item.option3Val,
          price: item.price,
          sku: item.sku,
          markUp: item.markUp,
        };
        return variantContainer;
      });

      setValue({
        title,
        description,
        sku,
        barcode,
        price,
        comparePrice,
        variants: variantData,
        markUp,
        inventoryManagement,
        _id: productId,
        isShipping,
        quantity,
        productType,
        tags,
        length,
        width,
        height,
        updatedAt,
        vendor,
        weight,
        weightUnit,
        sellerDiscount,
      });
      setIsFirstRender(variants && variants.length);
    }
  }, [match.params.id, productResponse]);

  const handleMarkup = (fieldName, fieldValue) => {
    setDisabledButton(false);
    const markUp = { ...value.markUp };
    markUp[fieldName] = fieldValue;
    setValue({
      ...value,
      markUp,
    });
  };

  const handleDiscount = (fieldName, fieldValue) => {
    setDisabledButton(false);
    const sellerDiscount = { ...value.sellerDiscount };
    sellerDiscount[fieldName] = fieldValue || parseFloat(0);
    setValue({
      ...value,
      sellerDiscount,
    });
  };

  const fieldData = [constant.IS_SHIPPING, constant.TITLE, constant.DESCRIPTION, constant.SKU];
  const handleChange = (fieldName, fieldValue) => {
    setDisabledButton(false);
    setValue({
      ...value,
      [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
    });
  };

  const handleVariants = () => {};

  const handleBanner = () => {
    setBanner({ isOpen: false, title: "", status: "" });
  };

  const handleCancel = () => {
    history.push("/products");
  };

  const editProduct = (imagesToBeUpload = [], attachmentsToBeUpload = []) => {
    delete value.handle;
    delete value.vendorId;
    delete value.vendor;
    delete value.option1;
    delete value.option2;
    delete value.option3;
    const formValues = { ...value, images: imagesToBeUpload, attachments: attachmentsToBeUpload };

    if (formValues.markUp) {
      formValues.markUp.price = parseFloat(value.markUp.price);
    }
    formValues.quantity = parseInt(value.quantity, 10);
    if (value.sellerDiscount) {
      const discount = {};
      discount.price = parseFloat(value.sellerDiscount.price);
      discount.type = value.sellerDiscount.type;
      formValues.discount = discount;
    }
    if (formValues.updatedAt) {
      delete formValues.updatedAt;
    }
    formValues.weight = parseFloat(value.weight);
    delete formValues.sellerDiscount;
    delete formValues.length;
    delete formValues.width;
    delete formValues.height;
    delete formValues.vendor;

    const { variants = [] } = formValues || {};
    if (variants && variants.length) {
      formValues.option1 = variants[0].option1 || constant.SIZE;
      formValues.option2 = variants[0].option2 || constant.COLOR;
      formValues.option3 = variants[0].option3 || constant.MATERIAL;
    }

    updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        if (responseData) {
          refetch();
          setBanner({
            isOpen: true,
            status: constant.SUCCESS,
            title: cms("message.success.updatedSuccessfully"),
            action: { content: cms("message.success.action.viewAll"), url: "/products" },
          });
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const handleSubmit = () => {
    setDisabledButton(true);
    const { images = [], attachments = [] } = value;
    const imagesToBeUpload = [];
    const attachmentsToBeUpload = [];
    let uploadImages = [];
    let uploadAttachment = [];
    if (images && images.length) {
      uploadImages = images.map((key) => {
        return uploadImage({ variables: { input: { image: key, productId: match.params.id } } })
          .then((response) => {
            const { data: imageData } = response.data.uploadImage;
            const { imageUrl, imageId } = imageData;
            if (imageUrl) {
              const uploadedImageData = {
                url: imageUrl,
                imageId,
              };
              imagesToBeUpload.push(uploadedImageData);
            } else {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse() });
            }
          })
          .catch((exception) => {
            setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
          });
      });
    }

    if (attachments && attachments.length) {
      uploadAttachment = attachments.map((key) => {
        return uploadFile({ variables: { input: { file: key, productId: match.params.id } } })
          .then((response) => {
            const { data: attachmentData } = response.data.uploadFile;
            const { fileURL, fileId } = attachmentData;
            if (fileURL) {
              const uploadedfileData = {
                fileURL,
                fileId,
              };
              attachmentsToBeUpload.push(uploadedfileData);
            } else {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse() });
            }
          })
          .catch((exception) => {
            setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
          });
      });
    }
    Promise.all([...uploadImages, ...uploadAttachment]).then(() => {
      editProduct(
        [...existingProductImages, ...imagesToBeUpload],
        [...existingProductAttachments, ...attachmentsToBeUpload]
      );
    });
  };

  // const learnMore = (title, content) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(content);
  // };

  if (loading || isLoading) return <Spinner />;
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            onDismiss={handleBanner}
            status={banner.status}
            action={banner.action}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleDescription
          data={value}
          cms={cms}
          handleChange={handleChange}
          // learnMore={learnMore}
        />

        <Image
          data={value}
          cms={cms}
          handleChange={handleChange}
          existingProductImages={existingProductImages}
          setDisabledButton={setDisabledButton}
          setData={setValue}
          setExistingImages={setExistingProductImages}
          // learnMore={learnMore}
        />
        {bucket && path && id && secret && (
          <Attachment
            data={value}
            cms={cms}
            handleChange={handleChange}
            existingProductAttachments={existingProductAttachments}
            setDisabledButton={setDisabledButton}
            setData={setValue}
            setExistingAttachments={setExistingProductAttachments}
          />
        )}

        {!isVariants && (
          <Price
            data={value}
            cms={cms}
            handleMarkup={handleMarkup}
            handleDiscount={handleDiscount}
            handleChange={handleChange}
            // learnMore={learnMore}
          />
        )}
        {!(value && value.variants && value.variants.length) && (
          <Inventory
            handleChange={handleChange}
            cms={cms}
            data={value}
            // learnMore={learnMore}
          />
        )}
        {value && value.variants && value.variants.length ? null : (
          <Shipping
            cms={cms}
            data={value}
            handleChange={handleChange}
            isLoading={isLoading}
            // learnMore={learnMore}
          />
        )}
        {value && value.variants && value.variants.length && isFirstRender ? (
          <RenderVariants
            data={value}
            cms={cms}
            handleVariants={handleVariants}
            setBanner={setBanner}
            refetch={refetch}
            loading={loading}
          />
        ) : (
          <Variants
            data={value}
            setValue={setValue}
            cms={cms}
            handleChange={handleChange}
            // learnMore={learnMore}
          />
        )}
        <Organization
          cms={cms}
          handleChange={handleChange}
          data={value}
          productType={typeListResponse}
          productTag={tagListResponse}
          // learnMore={learnMore}
        />
        <Layout.Section>
          <Stack>
            <Stack.Item fill>
              <Button onClick={handleCancel}>{cms("common.button.cancel")}</Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                onClick={handleSubmit}
                primary
                loading={editLoading || imageLoading || uploadFileLoading}
                disabled={editLoading || disabledButton}
              >
                {cms("common.button.submit")}
              </Button>
            </Stack.Item>
          </Stack>
        </Layout.Section>
        {/* <Sheet
          title={sheetTitle}
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={{
            content: cms("common.label.done"),
            onAction: () => setSheetActive(false),
          }}
          secondaryAction={{
            content: cms("common.button.cancel"),
            onAction: () => setSheetActive(false),
          }}
        >
          {sheetContent}
        </Sheet> */}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductEdit), { feature: constant.EDIT_PRODUCT });
