import constant from "lib/constant/constant";

const formConfig = (cms) => {
  const {
    BRAND_NAME,
    BRAND_HANDLE,
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
  } = constant;

  const textFields = [
    {
      label: `${cms("common.label.firstName")}`,
      placeholder: cms("common.placeholder.firstName"),
      key: FIRST_NAME,
    },
    {
      label: `${cms("common.label.lastName")}`,
      placeholder: cms("common.placeholder.lastName"),
      key: LAST_NAME,
    },
    {
      label: `${cms("common.label.email")}`,
      placeholder: cms("common.placeholder.emailAddress"),
      key: EMAIL,
    },
    {
      label: `${cms("common.label.phone")}`,
      placeholder: cms("common.placeholder.phoneNumber"),
      key: PHONE_NUMBER,
    },
    // {
    //   label: `${cms("common.label.brandName")}`,
    //   placeholder: cms("common.placeholder.brandName"),
    //   key: BRAND_NAME,
    // },
    // {
    //   label: `${cms("common.label.brandHandle")}`,
    //   placeholder: "Brand Handle",
    //   key: BRAND_HANDLE,
    // },
    {
      label: `${cms("common.label.streetAddress")}`,
      placeholder: cms("common.placeholder.streetAddress"),
      key: STREET_ADDRESS,
    },
    {
      label: `${cms("common.label.city")}`,
      placeholder: cms("common.placeholder.city"),
      key: CITY,
    },
    {
      label: `${cms("common.label.country")}`,
      placeholder: cms("common.placeholder.country"),
      key: COUNTRY,
    },
    {
      label: `${cms("common.label.provinceCode")}`,
      placeholder: cms("common.placeholder.provinceCode"),
      key: PROVINCE_CODE,
    },
    {
      label: `${cms("common.label.postalCode")}`,
      placeholder: cms("common.placeholder.postalCode"),
      key: POSTAL_CODE,
    },
  ];

  const brandFields = [
    {
      label: `${cms("common.label.brandName")}`,
      key: BRAND_NAME,
      placeholder: cms("common.placeholder.brandName"),
      helpText: cms("helpText.brandName"),
    },
    {
      label: `${cms("common.label.brandHandle")}`,
      key: BRAND_HANDLE,
      placeholder: cms("common.placeholder.brandHandle"),
      helpText: cms("helpText.brandHandle"),
    },
  ];

  return { textFields, brandFields };
};

export default formConfig;
