import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";

import OperatorExpressPaymentSetting from "app/payments/modules/operator/features/expressPaymentSetting/expressPaymentSetting";

const { userKey } = constant;
const { operator } = userKey;

const ExpressPaymentSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorExpressPaymentSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const PaymentExportSettingComponent = getComponent(currentUserRole);
  return PaymentExportSettingComponent;
};

export default ExpressPaymentSetting;
