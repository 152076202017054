import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { OperatorOrderSetting } from "./operator/features";
import { ProviderOrderSetting } from "./provider/features";

const { userKey } = constant;
const { operator, provider } = userKey;

const OrderSetting = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorOrderSetting />;
    }
    if (role === provider) {
      return <ProviderOrderSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderSettingComponent = getComponent(currentUserRole);
  return OrderSettingComponent;
};

export default OrderSetting;
