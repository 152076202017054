import { gql } from "apollo-boost";

const REGISTER_MARKETCUBE_SELLER = gql`
  mutation registerMarketcubeSeller($input: SellerCredential!) {
    registerMarketcubeSeller(input: $input) {
      data
      status
      error
    }
  }
`;

export default REGISTER_MARKETCUBE_SELLER;
