import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import {
  nameSchema,
  codeSchema,
  descriptionSchema,
  priceSchema,
  periodSchema,
  trialPeriodSchema,
  productLimitSChema,
} from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const { CODE, DESCRIPTION, NAME, PERIOD, PRICE, PRODUCT_LIMIT, TRIAL_PERIOD } = constant;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case NAME:
      promise = validateValue(nameSchema(cms), { name: value });
      break;
    case CODE:
      promise = validateValue(codeSchema(cms), { code: value });
      break;
    case DESCRIPTION:
      promise = validateValue(descriptionSchema(cms), { description: value.trim() });
      break;
    case PRICE:
      promise = validateValue(priceSchema(cms), { price: value });
      break;
    case PERIOD:
      promise = validateValue(periodSchema(cms), { period: value });
      break;
    case TRIAL_PERIOD:
      promise = validateValue(trialPeriodSchema(cms), { trialPeriod: value });
      break;
    case PRODUCT_LIMIT:
      promise = validateValue(productLimitSChema(cms), { productLimit: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;
