import React, { useCallback, useContext, useState } from "react";
import { ActionList, Button, Card, Collapsible } from "@shopify/polaris";

// config
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import quickSettingConfig from "app/reports/operator/widgets/quickSetting/quickSettingConfig";
import { baseHelper } from "lib/helpers";
import quickLink from "./quickLinkConfig";

const OperatorQuickLink = (props) => {
  const { link: actionLink = [] } = props;
  const { cms, history, match, currentUser } = useContext(PrivateContext);
  const { path } = match;
  const [openActionLink, setActive] = useState(false);
  const [actionLinkExpanded, setExpanded] = useState(true);
  const handleToggle = useCallback(() => setActive((link) => !link), []);
  const quickLinkConfig = quickLink(cms);
  const { config, title } = quickSettingConfig(cms);

  const actionCard = (action) => {
    const onAction = () => (action.onClick ? action.onClick() : history.push(action.link));
    return (
      <ActionList
        sections={[
          {
            items: [
              {
                content: action.label,
                helpText: action.caption,
                icon: action.icon,
                onAction,
              },
            ],
          },
        ]}
      />
    );
  };

  const viewTerms = (setting) =>
    !(baseHelper.isEcommerceUser(currentUser) && setting.label === baseHelper.ucFirst(constant.TERMS));

  const getQuickLinks = (links = []) => {
    let updatedActions = [...links];
    if (currentUser.ecommercePlatform) {
      updatedActions = updatedActions.filter((item) => item.id !== "exportOrder");
    }
    return updatedActions.map((link) => actionCard(link));
  };

  const getQuickSettings = (settings = []) => {
    return settings.map((setting) => (viewTerms(setting) ? actionCard(setting) : ""));
  };

  const quickActions = (actionLink.length && actionLink) || quickLinkConfig[path];

  return (
    <Card>
      <Card.Header title={constant.ACTION}>
        <Button
          plain
          disclosure={actionLinkExpanded ? constant.DOWN : constant.UP}
          onClick={() => {
            handleToggle();
            setExpanded(!actionLinkExpanded);
          }}
        >
          {actionLinkExpanded ? constant.SHOW : baseHelper.ucFirst(constant.HIDE)}
        </Button>
      </Card.Header>

      <br />

      <Collapsible
        open={openActionLink}
        id="collapsibleQuickLink"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Card>
          <Card.Section subdued>{getQuickLinks(quickActions)}</Card.Section>

          {config[path] && (
            <Card.Section subdued title={title[path] || cms("common.actions.title.setting")}>
              {getQuickSettings(config[path])}
            </Card.Section>
          )}
        </Card>
      </Collapsible>
    </Card>
  );
};

export default OperatorQuickLink;
