// import packages
import React from "react";

// import hoc
import { withErrorBoundary } from "lib/hoc";

const SeurTerms = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: `<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    
    <style id="dynCom" type="text/css"><!-- --></style>
    <script language="JavaScript"><!--
    function msoCommentShow(anchor_id, com_id)
    {
      if(msoBrowserCheck()) 
        {
        c = document.all(com_id);
        a = document.all(anchor_id);
        if (null != c && null == c.length && null != a && null == a.length)
          {
          var cw = c.offsetWidth;
          var ch = c.offsetHeight;
          var aw = a.offsetWidth;
          var ah = a.offsetHeight;
          var x  = a.offsetLeft;
          var y  = a.offsetTop;
          var el = a;
          while (el.tagName != "BODY") 
            {
            el = el.offsetParent;
            x = x + el.offsetLeft;
            y = y + el.offsetTop;
            }
          var bw = document.body.clientWidth;
          var bh = document.body.clientHeight;
          var bsl = document.body.scrollLeft;
          var bst = document.body.scrollTop;
          if (x + cw + ah / 2 > bw + bsl && x + aw - ah / 2 - cw >= bsl ) 
            { c.style.left = x + aw - ah / 2 - cw; }
          else 
            { c.style.left = x + ah / 2; }
          if (y + ch + ah / 2 > bh + bst && y + ah / 2 - ch >= bst ) 
            { c.style.top = y + ah / 2 - ch; }
          else 
            { c.style.top = y + ah / 2; }
          c.style.visibility = "visible";
    }	}	}
    function msoCommentHide(com_id) 
    {
      if(msoBrowserCheck())
        {
        c = document.all(com_id);
        if (null != c && null == c.length)
        {
        c.style.visibility = "hidden";
        c.style.left = -1000;
        c.style.top = -1000;
        } } 
    }
    function msoBrowserCheck()
    {
      ms = navigator.appVersion.indexOf("MSIE");
      vers = navigator.appVersion.substring(ms + 5, ms + 6);
      ie4 = (ms > 0) && (parseInt(vers) >= 4);
      return ie4;
    }
    if (msoBrowserCheck())
    {
      document.styleSheets.dynCom.addRule(".msocomanchor","background: infobackground");
      document.styleSheets.dynCom.addRule(".msocomoff","display: none");
      document.styleSheets.dynCom.addRule(".msocomtxt","visibility: hidden");
      document.styleSheets.dynCom.addRule(".msocomtxt","position: absolute");
      document.styleSheets.dynCom.addRule(".msocomtxt","top: -1000");
      document.styleSheets.dynCom.addRule(".msocomtxt","left: -1000");
      document.styleSheets.dynCom.addRule(".msocomtxt","width: 33%");
      document.styleSheets.dynCom.addRule(".msocomtxt","background: infobackground");
      document.styleSheets.dynCom.addRule(".msocomtxt","color: infotext");
      document.styleSheets.dynCom.addRule(".msocomtxt","border-top: 1pt solid threedlightshadow");
      document.styleSheets.dynCom.addRule(".msocomtxt","border-right: 2pt solid threedshadow");
      document.styleSheets.dynCom.addRule(".msocomtxt","border-bottom: 2pt solid threedshadow");
      document.styleSheets.dynCom.addRule(".msocomtxt","border-left: 1pt solid threedlightshadow");
      document.styleSheets.dynCom.addRule(".msocomtxt","padding: 3pt 3pt 3pt 3pt");
      document.styleSheets.dynCom.addRule(".msocomtxt","z-index: 100");
    }
    // --></script>
    <style>
    <!--
     /* Font Definitions */
     @font-face
      {font-family:Wingdings;
      panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
      {font-family:"Segoe UI";
      panose-1:2 11 5 2 4 2 4 2 2 3;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:0in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
      {mso-style-link:"Comment Text Char";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:0in;
      font-size:10.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
      {mso-style-link:"Footer Char";
      margin:0in;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:0in;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:0in;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:8.0pt;
      margin-left:.5in;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;}
    span.FooterChar
      {mso-style-name:"Footer Char";
      mso-style-link:Footer;}
    p.DPDBodyText, li.DPDBodyText, div.DPDBodyText
      {mso-style-name:"DPD_Body Text";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:7.0pt;
      margin-left:0in;
      line-height:14.0pt;
      font-size:10.0pt;
      font-family:"Arial",sans-serif;
      color:black;}
    span.CommentTextChar
      {mso-style-name:"Comment Text Char";
      mso-style-link:"Comment Text";}
    .MsoChpDefault
      {font-family:"Calibri",sans-serif;}
    .MsoPapDefault
      {margin-bottom:8.0pt;
      line-height:107%;}
     /* Page Definitions */
     @page WordSection1
      {size:595.3pt 841.9pt;
      margin:70.85pt 70.85pt 70.85pt 70.85pt;}
    div.WordSection1
      {page:WordSection1;}
     /* List Definitions */
     ol
      {margin-bottom:0in;}
    ul
      {margin-bottom:0in;}
    -->
    </style>
    
    </head>
    
    <body lang=EN-US style='word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=ES
    style='font-size:22.0pt;line-height:107%;color:#C00000'>Condiciones de uso</span></b></p>
    
    <p class=DPDBodyText style='text-align:justify;line-height:115%'><span lang=ES
    style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
    color:windowtext'>¡Bienvenido a Singular! </span></p>
    
    <p class=DPDBodyText style='text-align:justify;line-height:115%'><span lang=ES
    style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
    color:windowtext'>Las presentes Condiciones Generales de Uso (en adelante, las &quot;<b>Condiciones</b>&quot;)
    regulan el acceso y el uso de los Servicios definidos a continuación y
    propuestos por Geopost SA, sociedad anónima con un capital social de
    701.573.487 euros, cuya sede social se encuentra en sis 26 rue Guynemer, 92130
    ISSY LES MOULINEAUX (Francia), inscrita en el Registro Mercantil de Nanterre
    con el número 340 012 392 (en adelante, &quot;<b>Geopost&quot;</b>, &quot;<b>nosotros&quot;</b>,
    &quot;<b>nuestro&quot;</b>, &quot;<b>nos&quot;</b>).</span></p>
    
    <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><b><span
    style='font-size:14.0pt;line-height:107%;color:#C00000'>1.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:14.0pt;line-height:107%;color:#C00000'>Nuestros servicios</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Singular es una
    plataforma de referencia que permite a los vendedores que cumplan determinados
    criterios (véase el apartado 2.1) para catalogar los productos que venden en
    línea en sus propios sitios web (en lo sucesivo, los <b>&quot;Servicios&quot;</b>).</span></p>
    
    <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><b><span
    lang=ES style='font-size:14.0pt;line-height:107%;color:#C00000'>2.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    lang=ES style='font-size:14.0pt;line-height:107%;color:#C00000'>Activación y
    registro de la cuenta</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>2.1 </span></b><span lang=ES>Para obtener el derecho de
    acceso y uso de la plataforma —y, por tanto, para obtener un enlace de
    activación de cuenta— debe cumplir varios criterios: </span></p>
    
    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in'><span
    lang=ES style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>ser cliente de una de las empresas afiliadas a
    Geopost (en adelante, el <b>&quot;Afiliado&quot;</b>); </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
    lang=ES style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>comercializar productos fabricados localmente o, al
    menos, creados exclusivamente para usted; </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in'><span
    lang=ES style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>aplicar medidas sostenibles y éticas;</span></p>
    
    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in'><span
    lang=ES style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>disponer de un sitio web cuyos sistemas de gestión
    de contenidos sean compatibles con el <i>back-office</i> de la plataforma (en
    adelante, el <b>&quot;Sitio Web&quot;</b>).</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>2.2 </span></b><span lang=ES>Una vez que su solicitud
    haya sido revisada y aprobada por el Afiliado, éste le enviará un enlace de registro
    específico a la plataforma de nuestro socio, MarketCube, con el fin de crear su
    cuenta (en adelante <b>&quot;Cuenta&quot;</b>) que le permitirá acceder y
    utilizar los Servicios en Singular. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>2.3 </span></b><span lang=ES>Para completar el registro
    de su Cuenta, debe introducir su dirección de correo electrónico y una
    contraseña en la plataforma de nuestro socio, MarketCube. A continuación, recibirá
    un correo electrónico con el fin de confirmar su dirección de correo electrónico
    (por favor, compruebe su carpeta de correo no deseado si no ha recibido
    ninguno). </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>2.4 </span></b><span lang=ES>La persona cuya dirección de
    correo electrónico se introduzca durante el registro —o el empleador de ésta,
    si está activando su Cuenta en nombre de su empleador— se considerará titular
    de la cuenta (en adelante, el <b>&quot;Titular de la Cuenta&quot;</b>) a los
    efectos de las presentes Condiciones, y se considerará como la parte
    contratante, así como la persona autorizada a utilizar la Cuenta. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>2.5 </span></b><span lang=ES>Una vez confirmada la
    dirección de correo electrónico, puede acceder a su Cuenta para continuar con
    el proceso de incorporación y entrar en su panel de control. </span></p>
    
    <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><b><span
    lang=ES style='font-size:14.0pt;line-height:107%;color:#C00000'>3.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    lang=ES style='font-size:14.0pt;line-height:107%;color:#C00000'>Acceso y uso de
    los Servicios </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.1 </span></b><span lang=ES>Dependiendo del sistema de
    gestión de contenidos que utilice, el Afiliado le enviará la documentación que
    describe el proceso para que pueda conectar su catálogo de productos e implementar
    su contenido en Singular a través del <i>back-office</i> de MarketCube. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.2 </span></b><span lang=ES>Usted es el único
    responsable de la seguridad de sus identificadores. Geopost no será responsable
    de la falta de mantenimiento de la seguridad de sus identificadores y/o de su
    Cuenta. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.3 </span></b><span lang=ES>Usted se compromete a no
    reproducir, duplicar, copiar, vender o explotar la totalidad o parte de Singular.
    </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.4 </span></b><span lang=ES>También se compromete a no: </span></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-left:38.4pt;text-align:justify;
    text-indent:-.25in'><span lang=ES style='font-family:Symbol'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>evitar, eludir o sortear cualquiera de las
    limitaciones técnicas de los Servicios; </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:38.4pt;text-align:justify;
    text-indent:-.25in'><span lang=ES style='font-family:Symbol'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>utilizar cualquier herramienta para habilitar
    características o funcionalidades que estén deshabilitadas en los Servicios; </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:38.4pt;text-align:justify;
    text-indent:-.25in'><span lang=ES style='font-family:Symbol'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>descompilar, desensamblar o aplicar ingeniería
    inversa a los Servicios; o </span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-left:38.4pt;text-align:justify;
    text-indent:-.25in'><span lang=ES style='font-family:Symbol'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=ES>acceder a los Servicios utilizando cualquier robot,
    <i>scraper</i> u otro medio automatizado. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.5 </span></b><span lang=ES>Salvo lo dispuesto
    expresamente en las presentes Condiciones, usted se compromete a no utilizar,
    modificar, reproducir, distribuir, vender, conceder licencias o cualquier otro
    uso de nuestro Servicio sin nuestro consentimiento<b>. </b></span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.6 </span></b><span lang=ES>Usted acepta y reconoce
    proporcionar en su Sitio Web información de contacto de cara al público, así
    como cualquier información y/o contenido legal exigido por la legislación
    aplicable. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.7 </span></b><span lang=ES>Usted es el único
    responsable de todos los contratos de venta realizados a través de su Sitio Web
    y usted reconoce y acepta que Singular no puede ser considerado en modo alguno
    como una plataforma de compraventa. Del mismo modo, usted es el único
    responsable de los bienes (incluyendo la descripción, precios, impuestos,
    tasas, cumplimiento legal, defecto, ofertas y contenido promocional) que se
    venden en su sitio web. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>3.8 </span></b><span lang=ES>Usted no debe utilizar los
    Servicios para ningún propósito no autorizado o ilegal ni violar ninguna ley
    aplicable a su actividad, incluyendo, entre otras, las leyes de propiedad
    intelectual. </span></p>
    
    <p class=MsoListParagraph style='text-align:justify;text-indent:-.25in'><b><span
    style='font-size:14.0pt;line-height:107%;color:#C00000'>4.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:14.0pt;line-height:107%;color:#C00000'>Su contenido </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.1 </span></b><span lang=ES>Una vez que su sitio web esté
    conectado a Singular, todos o parte de los productos disponibles en su sitio
    web para la venta, así como todos los elementos adjuntos a estos productos (incluyendo,
    pero, entre otros, las marcas, fotos, nombre del producto, descripciones, etc.)
    se podrán cargar en Singular (en lo sucesivo, su <b>&quot;Contenido&quot;</b>).
    </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.2 </span></b><span lang=ES>Los productos sólo se
    cargarán en la plataforma tras la validación de Geopost. En este sentido, nos
    reservamos el derecho a rechazar la carga de la totalidad o parte de los
    productos, a nuestra entera discreción y sin indicar los motivos.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.3 </span></b><span lang=ES>Deberá cargar únicamente
    Contenido preciso, apropiado y adecuado. No deberá cargar ningún Contenido que
    infrinja o fomente cualquier conducta que infrinja las leyes o reglamentos,
    incluyendo, entre otros, las leyes o reglamentos aplicables a su línea de
    negocio, a la publicidad y a los derechos de propiedad intelectual. En términos
    más generales, usted acepta que su Contenido no viola ni violará ninguna ley ni
    infringirá los derechos de terceros. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.4 </span></b><span lang=ES>Usted conserva todos los
    derechos sobre su Contenido. No obstante, en el marco de los Servicios, usted
    concede a Geopost y al Afiliado una licencia mundial no exclusiva, transferible
    y sublicenciable para utilizar, almacenar, mostrar, reproducir, guardar,
    modificar, crear trabajos derivados, ejecutar y distribuir su Contenido en Singular,
    con el único propósito de operar, desarrollar, proporcionar y utilizar los
    Servicios <a name="_Hlk165991020">y mientras dure su uso de los Servicios</a>. Usted
    declara y garantiza que tiene todos los derechos necesarios sobre el Contenido
    para conceder esta licencia. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.5 </span></b><span lang=ES>Usted también concede a
    Geopost y al Afiliado una licencia mundial no exclusiva, transferible y
    sublicenciable para utilizar y mostrar los nombres, marcas comerciales, marcas
    de servicio y logotipos asociados a su Sitio Web (en adelante, sus <b>&quot;Marcas&quot;</b>)
    en Singular únicamente con el fin de operar, desarrollar, proporcionar y
    utilizar los Servicios y mientras dure su uso de los Servicios.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.6 </span></b><span lang=ES>Después de su carga en Singular,
    nos reservamos el derecho, de eliminar la totalidad o parte de su Contenido o
    cambiar la forma en que se utiliza en los Servicios, por cualquier motivo,
    incluso cuando creemos que viola estas Condiciones o cualquier ley o reglamento,
    si bien no estamos obligados a hacerlo.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>4.7 </span></b><span lang=ES>Singular ofrece
    sincronización en tiempo real. Geopost no se compromete a este respecto y, por
    lo tanto, no puede ser responsable de ninguna diferencia de precio y/o información
    relativa a su Contenido. No obstante, Geopost hará todo lo posible para
    actualizar esta información en caso de discrepancia.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>5. Propiedad
    intelectual de Geopost y Singular</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Usted reconoce y
    acepta que Geopost retiene todos los derechos sobre Singular. Usted se
    compromete a no utilizar las marcas registradas de Geopost o cualquier
    componente de Singular sin el consentimiento previo por escrito de Geopost. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>6. Indemnización </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Usted acepta
    indemnizar y mantener indemne a Geopost y su Afiliado y sus respectivos
    funcionarios, directores, empleados y agentes de y contra cualquier
    reclamación, pleito, procedimiento, disputa, demanda, responsabilidad, daños,
    pérdidas, costes y gastos, incluyendo, sin limitación alguna, honorarios
    legales y contables razonables (incluyendo los costes de defensa de
    reclamaciones, pleitos o procedimientos iniciados por terceros), de cualquier
    manera relacionados con (a) su acceso o uso de los Servicios, (b) su Contenido
    o (c) su incumplimiento de cualquiera de estas Condiciones.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>7. Limitación de
    responsabilidad </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>7.1 </span></b><span lang=ES>Singular y los Servicios se
    proporcionan &quot;tal cual&quot;, sin garantía de ningún tipo, ya sea expresa
    o implícita. Geopost no garantiza que los Servicios sean ininterrumpidos,
    puntuales, seguros o libres de errores. Asimismo, Geopost no se compromete en
    términos de tráfico hacia su Sitio Web o de volumen de ventas, y en modo alguno
    puede ser considerado responsable a este respecto.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>7.2 </span></b><span lang=ES>Geopost no asume ninguna
    responsabilidad por cualquier Contenido que usted o cualquier otro usuario o
    tercero cargue en el marco de los Servicios. Del mismo modo, usted reconoce y
    acepta que ningún contrato de venta o transacción puede llevarse a cabo en Singular.
    Usted es el único responsable del proceso de venta en su Sitio Web.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>7.3 </span></b><span lang=ES>En la medida en que lo
    permita la ley, Geopost no será responsable de ningún daño indirecto,
    incidental, especial, consecuente o punitivo, ni de ninguna pérdida de
    beneficios o ingresos, ya se produzca directa o indirectamente, ni de ninguna
    pérdida de datos, uso, fondo de comercio u otras pérdidas intangibles. En
    ningún caso, la responsabilidad agregada de Geopost por todas las reclamaciones
    relacionadas con los Servicios superará la cantidad de diez mil euros (10.000</span><span
    lang=ES>&nbsp;</span><span lang=ES>€). </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>7.4 </span></b><span lang=ES style='color:#C00000'>En </span><span
    lang=ES>la medida en que lo permita la legislación aplicable, Geopost tendrá
    derecho, en cualquier momento, a modificar los Servicios sin previo aviso,
    incluso poniendo a su disposición nuevos servicios y funcionalidades.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>8. Terminación o
    restricción de los Servicios</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>8.1 </span></b><span lang=ES>Geopost o el Afiliado podrán
    rescindir, suspender o restringir su derecho a acceder o utilizar los Servicios
    por cualquier motivo con la debida notificación. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>8.2 </span></b><span lang=ES>También podremos terminar,
    suspender o restringir su acceso o uso inmediatamente y sin previo aviso si observamos
    una violación de estas Condiciones. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>8.3 </span></b><span lang=ES>Si deja de cumplirse alguno
    de los criterios establecidos en el apartado 2.1, también podremos finalizar,
    suspender o restringir su acceso o uso de forma inmediata y sin previo aviso.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>8.4 </span></b><span lang=ES>Podrá cancelar su cuenta en
    cualquier momento, previa notificación por escrito con 30 días de antelación al
    administrador de la cuenta de su afiliado.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>8.5 </span></b><span lang=ES>En caso de rescisión, su
    Cuenta será desconectada y su Contenido será automáticamente eliminado de Singular.
    Usted continuará obligado por las Secciones 4, 6, 10 y 11 de estas Condiciones durante
    el período de prescripción aplicable.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>9. 9. Protección de
    datos </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Geopost se
    compromete firmemente a proteger la privacidad de sus datos personales. Al
    utilizar los Servicios, reconoce y acepta que la recopilación, el uso y la
    divulgación de sus datos personales por parte de Geopost se rigen por nuestra
    Política de privacidad<a> </a></span><span class=MsoCommentReference><span
    style='font-size:8.0pt;line-height:107%'><a class=msocomanchor id="_anchor_1"
    onmouseover="msoCommentShow('_anchor_1','_com_1')"
    onmouseout="msoCommentHide('_com_1')" href="#_msocom_1" language=JavaScript
    name="_msoanchor_1">[PB1]</a>&nbsp;</span></span><span lang=ES>. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>10. Ley aplicable y
    jurisdicción</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Las presentes
    Condiciones se regirán por la legislación francesa, sin perjuicio de sus
    principios sobre conflicto de leyes. El lugar exclusivo de jurisdicción para
    todas las disputas que surjan de o en relación con este acuerdo es París. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='font-size:14.0pt;line-height:107%;color:#C00000'>11. Condiciones generales</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>11.1 Acuerdo completo </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Estas Condiciones,
    incluidos los documentos que incorporan por referencia, constituyen el acuerdo
    completo entre usted y Geopost y rigen el uso que usted haga de los Servicios y
    de su Cuenta, sustituyendo cualquier acuerdo anterior entre usted y Geopost
    (incluidas, entre otras, las versiones anteriores de estas Condiciones). </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>11.2 Modificaciones</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Estas Condiciones podrán
    verse sometidas a revisión. En caso de modificación sustancial de las mismas,<a
    name="_Hlk161392329"> se lo notificaremos al menos quince (15) días antes de
    que dichas modificaciones entren en </a>vigor. No impondremos cambios con
    carácter retroactivo; cualquier modificación entrará en vigor a partir de su
    fecha de entrada en vigor.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>11.3 Asignación </span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Usted no tendrá
    derecho a ceder o transferir de otro modo estas Condiciones —ni ninguno de sus
    derechos u obligaciones en virtud de estas— a terceros sin el consentimiento
    previo por escrito de Geopost, que se otorgará o denegará a discreción de
    Geopost. </span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>11.4 Sin exención</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Ninguna renuncia a una
    de estas Condiciones se considerará una renuncia posterior o continuada a dicha
    condición o a cualquier otra, y el hecho de que Geopost no haga valer cualquier
    derecho o disposición en virtud de estas Condiciones no constituirá una
    renuncia a dicho derecho o disposición.</span></p>
    
    <p class=MsoNormal style='text-align:justify'><b><span lang=ES
    style='color:#C00000'>11.5 Punto de contacto</span></b></p>
    
    <p class=MsoNormal style='text-align:justify'><span lang=ES>Si tiene alguna
    reclamación o pregunta sobre estas Condiciones, deberá ponerse en contacto
    directamente con el gestor de cuenta de su afiliado.</span></p>
    
    </div>
    
    <div>
    
    <hr class=msocomoff align=left size=1 width="33%">
    
    <div>
    
    <div id="_com_1" class=msocomtxt language=JavaScript
    onmouseover="msoCommentShow('_anchor_1','_com_1')"
    onmouseout="msoCommentHide('_com_1')"><a name="_msocom_1"></a>
    
    <p class=MsoCommentText><span class=MsoCommentReference><span style='font-size:
    8.0pt'>&nbsp;<a href="#_msoanchor_1" class=msocomoff>[PB1]</a></span></span>@Geopost:
    link to the privacy policy to be inserted here. </p>
    
    </div>
    
    </div>
    
    </div>
    
    </body>
    
    </html>
    ` }} />
  );
};

export default withErrorBoundary(SeurTerms);
