import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import { OperatorProductAdd } from "./operator/features";
import { ProviderProductAdd } from "./provider/features";
import { AdminProductAdd } from "./admin/features";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ProductsAdd = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminProductAdd />;
    }
    if (role === operator) {
      return <OperatorProductAdd />;
    }
    if (role === provider) {
      return <ProviderProductAdd />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductsAddComponent = getComponent(currentUserRole);
  return ProductsAddComponent;
};

export default ProductsAdd;
