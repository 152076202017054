import { gql } from "apollo-boost";

const GET_CATALOG = gql`
  query {
    getCatalog {
      data {
        sellerId
        catalog {
          storeCatalogId
          title
        }
      }
      error
      status
    }
  }
`;

export default GET_CATALOG;
