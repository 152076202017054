import { gql } from "apollo-boost";

const STANDARD_DISCOUNT = gql`
  query {
    getDiscount {
      data {
        userId
        updatedAt
        isDiscounted
        ruleBy
        price
        type
        createdAt
        _id
        advance {
          price
          type
          vendorId
          productTypeId
        }
      }
      status
      error
    }
  }
`;

export default STANDARD_DISCOUNT;
