import React, { useContext } from "react";
import { Card, Caption, Layout, TextField, FormLayout, Collapsible, TextStyle, TextContainer } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const Description = () => {
  const { BRAND_DESCRIPTION } = constant;
  const {
    description,
    handleChange,
    // learnMore
  } = useContext(BrandingContext);

  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { brand } = currentUser || {};
  const { updatedAt = "" } = brand || {};

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.brandDescription.title")}
        description={cms("section.brandDescription.description")}
      >
        <Card
          title={[
            cms("section.brandDescription.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          // actions={[{ content: "Learn more", onAction: () => learnMore("Description", "TODO: ") }]}
        >
          <Card.Section>
            <FormLayout>
              <TextContainer>{cms("section.brandDescription.description")}</TextContainer>
              <TextField
                id="description"
                placeholder={cms("section.brandDescription.placeHolder.description")}
                value={description}
                onChange={(value) => handleChange(BRAND_DESCRIPTION, value, BRAND_DESCRIPTION)}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(Description), {
  feature: constant.STANDARD_BRANDING,
});
