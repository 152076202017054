import React, { useContext } from "react";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { OperatorOrderExpiry } from "./operator/features";
import { ProviderOrderExpiry } from "./provider/features";

const { userKey } = constant;
const { operator, vendor } = userKey;
const OrderAutoExpiry = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorOrderExpiry />;
    }
    if (role === vendor) {
      return <ProviderOrderExpiry />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const OrderSettingComponent = getComponent(currentUserRole);
  return OrderSettingComponent;
};
export default OrderAutoExpiry;
