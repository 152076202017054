import React, { useContext, useState, useEffect } from "react";
import { Caption, Card, Heading, Layout, TextStyle, SettingToggle } from "@shopify/polaris";
import { useQuery } from "react-apollo";
import { GET_CREDIT_NOTE_SETTING } from "app/setup/apollo";
import constant from "lib/constant/constant";
import { Banner, SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { PrivateContext } from "lib/context";

function CreditNote() {
  const { cms, currentUser } = useContext(PrivateContext);
  const [active, setActive] = useState(false);
  const [updateAt, setUpdateAt] = useState(new Date());

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const { error: errorGetCreditNote, loading: loadingGetCreditNote, data: dataGetCreditNote } = useQuery(
    GET_CREDIT_NOTE_SETTING
  );

  const creditNoteResponseData = baseHelper.getResponseData(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);
  const creditNoteErrorData = baseHelper.getResponseError(dataGetCreditNote, constant.gql.GET_CREDIT_NOTE_SETTING);

  useEffect(() => {
    if (creditNoteResponseData) {
      const { creditNote } = creditNoteResponseData || {};
      const { _id: vendorId } = currentUser || {};
      const { isAllVendor = false, isEnabled = false, selectedVendor = [], updatedAt } = creditNote || {};
      setUpdateAt(updatedAt);
      if (isEnabled && (isAllVendor || selectedVendor.includes(vendorId))) {
        setActive(isEnabled);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditNoteResponseData]);

  useEffect(() => {
    if (creditNoteErrorData) {
      setBanner({ status: constant.CRITICAL, title: creditNoteErrorData, isOpen: true });
    }
    if (errorGetCreditNote) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetCreditNote), isOpen: true });
    }
  }, [creditNoteErrorData, errorGetCreditNote]);

  const contentStatus = active ? constant.displayStatus.DISABLE : constant.ENABLE;
  const setButtonColor = active;

  if (loadingGetCreditNote) {
    return <SkeletonAnnotated />;
  }

  return (
    <div>
      {banner.isOpen && (
        <>
          <br />
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <Layout.AnnotatedSection
        title={cms("section.creditNote.title")}
        description={cms("section.creditNote.description")}
      >
        <Card>
          <Card.Section>
            <div className="toggle-action">
              <SettingToggle
                action={{
                  content: contentStatus,
                  destructive: setButtonColor,
                  disabled: true,
                }}
                enabled={setButtonColor}
              >
                <Heading variation="strong">{cms("section.manageCreditNote.card.title")}</Heading>
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
                </TextStyle>
                <br />
                {active ? (
                  <TextStyle>{cms("section.creditNote.card.subTitle.enable")}</TextStyle>
                ) : (
                  <TextStyle>{cms("section.creditNote.card.subTitle.disable")}</TextStyle>
                )}
              </SettingToggle>
            </div>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
}
export default withFeature(withErrorBoundary(CreditNote), {
  feature: constant.CREDIT_NOTE_SETTING,
  // subFeature: constant.CREDIT_NOTE_SETTING,
});
