import styled from "styled-components";

const StyledTabs = styled.div`
  margin-bottom: 2.4rem;
  .Polaris-Tabs__TabContainer:first-child .Polaris-Tabs__Tab {
    padding-left: 1.4rem;
  }
  .Polaris-Tabs__Tab {
    min-width: auto;
    width: auto;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    .Polaris-Tabs__Title {
      padding: 0.8rem 0;
      min-width: auto;
    }
    &.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
      border-color: #008060;
    }
  }
  .Polaris-Tabs__TabContainer {
    &:nth-last-child(2) {
      margin-left: auto;
    }
  }
`;

export default StyledTabs;
