import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "@shopify/polaris";

import constant from "lib/constant/constant";

const propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Actions = (props) => {
  const { actions } = props;
  if (!(Array.isArray(actions) && actions.length)) {
    return null;
  }

  const filterAction = actions.filter((item) => item);
  const buttons = filterAction.map((item) => {
    const button = (
      <Button
        size={constant.SLIM}
        destructive={item.destructive || false}
        disabled={item.disabled || false}
        loading={!!item.loading}
        onClick={() => item.onAction()}
        key={item.content}
      >
        {item.content}
      </Button>
    );

    return button;
  });
  return <ButtonGroup>{buttons}</ButtonGroup>;
};

Actions.propTypes = propTypes;

export default Actions;
