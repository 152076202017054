import React, { useContext } from "react";
import { Card, TextField, Select, FormLayout, Stack } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "../../../add/context/context";

const { BUCKET_NAME, FOLDER_PATH, SECRET_ACCESS_KEY, ACCESS_KEY_ID } = constant;

const Bucket = (props) => {
  const { cms } = useContext(PrivateContext);
  const { data, handleChange } = useContext(FormContext);

  return (
    <Card sectioned>
      <FormLayout>
        <TextField
          label={`${cms("label.bucketName")}*`}
          placeholder={cms("placeholder.bucketName")}
          onChange={(value) => handleChange(constant.BUCKET_NAME, value)}
          value={data.bucket || ""}
        />

        <TextField
          label={`${cms("label.bucketFolder")}*`}
          placeholder={cms("placeholder.bucketFolder")}
          value={data.path || ""}
          onChange={(value) => handleChange(constant.FOLDER_PATH, value)}
        />

        <TextField
          label={`${cms("label.bucketAccessKey")}*`}
          placeholder={cms("placeholder.bucketAccessKey")}
          onChange={(value) => handleChange(constant.ACCESS_KEY_ID, value)}
          value={data.id || ""}
        />

        <TextField
          label={`${cms("label.bucketSecretKey")}*`}
          placeholder={cms("placeholder.bucketSecretKey")}
          onChange={(value) => handleChange(constant.SECRET_ACCESS_KEY, value)}
          value={data.secret || ""}
        />
      </FormLayout>
    </Card>
  );
};

export default Bucket;
