import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Caption, Card, FormLayout, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import component
import { DropZoneFile, Sheet, SkeletonCard, Toast } from "lib/components";

// import mutation
import { ADD_PRODUCT_ATTACHMENT, DELETE_PRODUCT_ATTACHMENT, UPLOAD_FILE } from "app/product/apollo/mutations";

//  import queries
import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/product/apollo/queries";

// import prop Type
import { tabProp } from "app/productLake/modules/generic/propTypes";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";
import { withErrorBoundary } from "lib/hoc";

const EditAttachment = (props) => {
  // eslint-disable-next-line react/prop-types
  const { tabObj = {} } = props;
  const { islastTab = false } = tabObj || {};
  const { handleTabChange, isVariant, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const [value, setValue] = useState({});
  const { cms, history, match } = useContext(PrivateContext);
  const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [loadingId, setLoadingId] = useState("");
  const [attachmentStartUploading, setAttachmentStartUploading] = useState(false);
  const [existingProductAttachments, setExistingProductAttachments] = useState([]);
  const [attachmentsToBeUploaded, setAttachmentsToBeUploaded] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const { CRITICAL, gql } = constant;
  const [message, setMessage] = useState("");
  setDescription(cms("label.editAttachment"));
  setTitle(cms("label.title.attachment"));
  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: fieldValue,
      });
    },
    [value]
  );
  const [addProductAttachment, { loading: addAttachmentLoading }] = useMutation(ADD_PRODUCT_ATTACHMENT);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);
  const [deleteAttachment, { loading: deleteAttachmentLoading }] = useMutation(DELETE_PRODUCT_ATTACHMENT);

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const handleAddAttachment = (data) => {
    const productAttachments = (value && value.attachments) || [];
    const uploadedAttachments = [...productAttachments, ...data];
    setAttachmentsToBeUploaded([...attachmentsToBeUploaded, ...data]);
    handleChange(constant.ATTACHMENTS, uploadedAttachments);
  };

  const removeAttachment = (index, buttonIndex) => {
    const productAttachments = value.attachments || [];
    if (index < 0 || productAttachments.length <= 0) {
      return;
    }
    const exitingProductAttachmentLength = existingProductAttachments && existingProductAttachments.length;
    attachmentsToBeUploaded.splice(buttonIndex - exitingProductAttachmentLength, 1);
    setAttachmentsToBeUploaded(attachmentsToBeUploaded);
    productAttachments.splice(buttonIndex, 1);
    handleChange(constant.ATTACHMENTS, productAttachments);
  };

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      setValue(productResponse.sellerProduct);
      setExistingProductAttachments(productResponse.sellerProduct.attachments);
    }
  }, [gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  if (productLoading) {
    return <SkeletonCard />;
  }

  const handleDeleteAttachment = (file, index) => {
    setMessage("");
    setLoadingId(index);
    const { fileId = null, fileURL = null } = file;
    const { productId = "" } = value;
    const formData = {
      fileId,
      fileURL,
      productId,
    };
    setDisabledButton(false);

    deleteAttachment({ variables: { input: formData } })
      .then((res) => {
        setLoadingId("");
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_PRODUCT_ATTACHMENT);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_PRODUCT_ATTACHMENT);
        if (responseData) {
          setMessage(cms("message.success.attachmentDelete"));
          const productAttachments = [...existingProductAttachments];
          productAttachments.splice(index, 1);
          setExistingProductAttachments(productAttachments);
          setValue({ ...value, attachments: [...productAttachments, ...attachmentsToBeUploaded] });
        }
      })
      .catch((exception) => {
        setLoadingId("");
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const addAttachmentData = async (formValues) => {
    try {
      const val = await addProductAttachment({
        variables: {
          input: formValues,
        },
      });

      const resData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_ATTACHMENT);
      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_ATTACHMENT);
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
      if (resData) {
        setMessage(cms("message.success.attachment"));
        setTimeout(() => {
          history.push("/product-lake");
        }, 1500);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const handleDownload = (file) => {
    window.open(file.fileURL, "_blank");
  };

  const attachmentUpload = (formField) => {
    const formValues = formField || {};
    const { attachments = [], productId = "" } = formValues || {};
    let hasAttachmentError = false;
    if (attachments && attachments.length) {
      const uploadedAttachments = [];
      const uploadAttachmentCallback = (attachmentResponse) => {
        const { fileId = null, fileName = null, fileURL = null } = attachmentResponse;
        if (fileURL) {
          const uploadedAttachmentData = {
            fileId,
            fileName,
            fileURL,
          };
          uploadedAttachments.push(uploadedAttachmentData);
        }
        if (hasAttachmentError) {
          return;
        }

        if (
          uploadedAttachments &&
          uploadedAttachments.length > 0 &&
          uploadedAttachments.length === attachments.length
        ) {
          setAttachmentStartUploading(false);
          formValues.attachments = uploadedAttachments;
          addAttachmentData(formValues);
        }
      };
      formValues.attachments.map(async (file) => {
        try {
          if (hasAttachmentError) {
            return;
          }
          let response = {};
          if (typeof file.fileBase64 === "string") {
            response = await uploadFile({
              variables: { input: { file: file.fileBase64, fileName: file.fileName, productId } },
            });
          } else {
            uploadAttachmentCallback(file);
            return;
          }

          if (!attachmentStartUploading) {
            setAttachmentStartUploading(true);
          }
          const attachmentResponse = baseHelper.getResponseData(response.data, gql.UPLOAD_FILE);
          const attachmentError = baseHelper.getResponseError(response.data, gql.UPLOAD_FILE);
          if (attachmentError) {
            setAttachmentStartUploading(false);
            hasAttachmentError = true;
            setBanner((prev) => ({
              ...prev,
              action: null,
              isOpen: true,
              status: CRITICAL,
              title: attachmentError,
            }));
          }
          uploadAttachmentCallback(attachmentResponse);
        } catch (uploadAttachmentError) {
          setAttachmentStartUploading(false);
          hasAttachmentError = true;
          setBanner((prev) => ({
            ...prev,
            action: null,
            isOpen: true,
            status: CRITICAL,
            title: errorHelper.parse(uploadAttachmentError),
          }));
        }
      });
      setAttachmentStartUploading(false);
    } else {
      addAttachmentData(formValues);
    }
    return null;
  };

  const onFormSubmit = async () => {
    const { attachments: formAttachment = [], productId = "" } = value;
    try {
      const formValues = {
        productId,
        attachments: formAttachment,
      };

      if (!disabledButton) {
        await attachmentUpload(formValues);
      }
      setMessage(cms("message.success.attachment"));
      setTimeout(() => {
        history.push("/product-lake");
      }, 1500);
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      }));
    }
  };

  return (
    <>
      <Card
        title={[
          cms("label.productAttachment"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productAttachment"
        // actions={[
        //   {
        //     content: cms("label.more"),
        //     onAction: () => {
        //       learnMore("productAttachment", cms("label.attachment"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.caption.attachment")}</p>
          <br />
          <FormLayout>
            <TextContainer>{cms("section.form.section.price.description")}</TextContainer>
            <DropZoneFile
              id="addProductDropZone"
              onAdd={handleAddAttachment}
              onRemove={removeAttachment}
              size={10}
              allowMultiple
              existingFileList={existingProductAttachments || []}
              removeExistingFile={handleDeleteAttachment}
              downloadExistingFile={handleDownload}
              loadingPosition={loadingId}
              disabled={addAttachmentLoading || deleteAttachmentLoading || uploadFileLoading}
            />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button
            onClick={() => {
              const tab = isVariant ? 2 : 4;
              handleTabChange(tab);
            }}
          >
            {cms("common.button.previous")}
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={islastTab ? deleteAttachmentLoading || false : deleteAttachmentLoading || disabledButton}
            loading={addAttachmentLoading || uploadFileLoading}
          >
            {value && value.status && value.status.toLowerCase() === constant.NEW
              ? cms("common.button.finish")
              : cms("common.button.submit")}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content: cms(`button.${islastTab ? "submit" : "save"}`),
          loading: addAttachmentLoading || uploadFileLoading,
          disabled: deleteAttachmentLoading || disabledButton,
          onAction: () => onFormSubmit(),
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => {
              const tab = isVariant ? 2 : 4;
              handleTabChange(tab);
            },
          },
        ]}
      /> */}
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

EditAttachment.propTypes = tabProp.type;

export default withErrorBoundary(EditAttachment);
