import { gql } from "apollo-boost";

const GET_SESSION_LIST = gql`
  query GetSessionList($input: GetListInput) {
    getSessionList(input: $input) {
      data {
        activity {
          _id
          userId
          userRole
          brandName
          device
          login
          ip
          logout
        }
        count
      }
      status
      error
    }
  }
`;

export default GET_SESSION_LIST;
