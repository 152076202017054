import React from "react";
import { AdminChangePassword } from "app/userManagement/modules/admin/features";
import { OperatorChangePassword } from "app/userManagement/modules/operator/features";
import { ProviderChangePassword } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ChangePassword = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminChangePassword />;
    }
    if (role === operator) {
      return <OperatorChangePassword />;
    }
    if (role === provider) {
      return <ProviderChangePassword />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ChangePasswordComponent = getComponent(currentUserRole);
  return ChangePasswordComponent;
};

export default ChangePassword;
