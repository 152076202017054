// import react packages
import React, { useState } from "react";
import { useMutation } from "react-apollo";

// Import Polaris Components
import { Button, Modal } from "@shopify/polaris";

// import helpers
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";

// import gql
import { DISCONNECT_WOOCOMMERCE_SHOP } from "app/product/apollo/mutations";

import { connectedShopProp } from "app/product/modules/generic/propTypes";

import { withErrorBoundary } from "lib/hoc";

const ConnectedShop = (props) => {
  const { currentUserData, cms, setBanner, setIsConnected, setValue, value } = props;
  const { wooCommerce: wooCommerceConfig = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const { shop = "" } = wooCommerceConfig || {};
  const { DISCONNECT_WOOCOMMERCE_SHOP: DISCONNECT } = constant.gql;

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);
  const [disconnectWooCommerceShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_WOOCOMMERCE_SHOP);

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectWooCommerceShop();
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("section.connect.message.success.disconnected")}`,
      });
      setIsConnected(false);
      setIsDisconnectActive(!isDisconnectActive);
      setValue({});
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleDisconnectModal = () => {
    setIsDisconnectActive(!isDisconnectActive);
  };

  return (
    <>
      <Modal
        open={isDisconnectActive}
        onClose={handleDisconnectModal}
        title={cms("section.connect.label.disconnectShop")}
        primaryAction={{
          content: `${cms("section.connect.label.disconnectShop")}`,
          onAction: disconnectShopConfirm,
          destructive: true,
          loading: disconnectLoading,
        }}
        secondaryActions={[
          {
            content: cms("section.connect.button.cancel"),
            onAction: handleDisconnectModal,
          },
        ]}
      >
        <Modal.Section>
          {`${cms("section.connect.label.disconnectWoocommerceShop")} ${shop || value.shopDomain || ""}?`}
        </Modal.Section>
      </Modal>
      <Button plain destructive id="disconnect" onClick={handleDisconnectModal}>
        {cms("section.connect.label.disconnectShop")}
      </Button>
    </>
  );
};

ConnectedShop.propTypes = connectedShopProp;

export default withErrorBoundary(ConnectedShop);
