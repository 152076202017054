import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import { SYNC_INVENTORY_VENDOR } from "app/product/apollo/mutations";

// import props
import { syncProduct } from "app/product/modules/generic/propTypes";

const {
  gql: { SYNC_INVENTORY_VENDOR: SYNC_INVENTORY_VENDOR_GQL },
} = constant;

const SyncProduct = (props) => {
  const { history, setBanner, cms } = props;
  const { CRITICAL } = constant;
  const [syncInventoryVendor, { loading: syncInventoryVendorLoading }] = useMutation(SYNC_INVENTORY_VENDOR);
  const syncProducts = async () => {
    try {
      const response = await syncInventoryVendor();
      const responseError = baseHelper.getResponseError(response.data, SYNC_INVENTORY_VENDOR_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/shopify");
      const bannerAction = {
        onAction,
        content: cms("section.connect.label.shopifyProduct"),
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("section.connect.message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncInventoryVendorLoading} onClick={(value) => syncProducts(value)}>
      {cms("section.connect.label.import")}
    </Button>
  );
};

SyncProduct.propTypes = syncProduct.type;
SyncProduct.defaultProps = syncProduct.default;

export default SyncProduct;
