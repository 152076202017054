import { gql } from "apollo-boost";

const GET_PRODUCT_FORM = gql`
  query getProductFormSetting {
    getProductFormSetting {
      error
      status
      data {
        title {
          label
          isRequired
        }
        description {
          label
          isRequired
        }
        price {
          label
          isRequired
        }
        comparePrice {
          label
          isRequired
        }
        wholesalePrice {
          label
          isRequired
        }
        markup {
          label
          isRequired
        }
        discount {
          label
          isRequired
        }
        vendor {
          label
          isRequired
        }
        productType {
          label
          isRequired
        }
        tags {
          label
          isRequired
        }
        catalog {
          label
          isRequired
        }
        sku {
          label
          isRequired
        }
        barcode {
          label
          isRequired
        }
        inventory {
          label
          isRequired
        }
        quantity {
          label
          isRequired
        }
        physical {
          label
          isRequired
        }
        location {
          label
          isRequired
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export default GET_PRODUCT_FORM;
