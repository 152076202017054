import { gql } from "apollo-boost";

const GET_ACTIVITY = gql`
  query getActivity($input: GetListInput) {
    getActivity(input: $input) {
      data {
        activity {
          _id
          collectionId
          collectionName
          activityId
          activityDate
          by
          label
          title
          createdAt
          status
          type
          byUserId
          image
          vendor
          vendorId
        }
        count
      }
      status
      error
    }
  }
`;

export default GET_ACTIVITY;
