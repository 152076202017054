import React, { useContext } from "react";
import { TextField, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";
// import hoc
import { withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const CanadaPost = (props) => {
  const { error, handleCanadaPost, dropship, handleValidation } = props;
  const { cms } = useContext(FulfillmentContext);
  return (
    <Stack vertical>
      <TextField
        label={cms("label.userName")}
        value={(dropship && dropship.canadaPost && dropship.canadaPost.userName) || ""}
        onChange={(val) => handleCanadaPost("userName", val)}
        error={error && error.userName}
        onBlur={() => {
          handleValidation("userName", dropship && dropship.canadaPost && dropship.canadaPost.userName);
        }}
      />
      <TextField
        label={cms("label.password")}
        value={(dropship && dropship.canadaPost && dropship.canadaPost.password) || ""}
        onChange={(val) => handleCanadaPost("password", val)}
        error={error && error.password}
        onBlur={() => {
          handleValidation("password", dropship && dropship.canadaPost && dropship.canadaPost.password);
        }}
      />
      <TextField
        label={cms("label.number")}
        value={(dropship && dropship.canadaPost && dropship.canadaPost.customerNumber) || ""}
        onChange={(val) => handleCanadaPost("customerNumber", val)}
        error={error && error.customerNumber}
        onBlur={() => {
          handleValidation("customerNumber", dropship && dropship.canadaPost && dropship.canadaPost.customerNumber);
        }}
      />
      <TextField
        label={cms("label.id")}
        value={(dropship && dropship.canadaPost && dropship.canadaPost.contractId) || ""}
        onChange={(val) => handleCanadaPost("contractId", val)}
      />
    </Stack>
  );
};

CanadaPost.propTypes = {
  dropship: PropTypes.objectOf(PropTypes.shape).isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleCanadaPost: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

CanadaPost.defaultProps = {
  error: {},
};

export default withFeature(CanadaPost, {
  feature: constant.CANADA_POST,
  // subFeature: constant.ADVANCE_DROPSHIP,
  // subFeatureItem: constant.CANADA_POST,
});
