import { gql } from "apollo-boost";

const UPDATE_GEOLOCATION_SETTING = gql`
  mutation updateGeolocationSetting($input: UpdateGeolocationSetting!) {
    updateGeolocationSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_GEOLOCATION_SETTING;
