import { gql } from "apollo-boost";

const SHOPIFY_INSTALL_VENDOR_LOGIN = gql`
  mutation shopifyInstallAndVendorLogin($input: shopifyInstallAndVendorLoginInput) {
    shopifyInstallAndVendorLogin(input: $input) {
      status
      error
      data {
        shopStatus
      }
    }
  }
`;

export default SHOPIFY_INSTALL_VENDOR_LOGIN;
