import React, { useCallback, useContext, useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import {
  Button,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  Frame,
  Heading,
  Layout,
  SettingToggle,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { Banner, Toast } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import { REVIEW_VENDOR_ACCESS } from "app/advanceVendor/apollo/mutations";
import cmsVendorSettingReview from "./cms/reviewAccessCMS";

const VendorReviewAccess = (props) => {
  const { status, reviewVendorData } = props;
  const [reviewVendorAccess, { loading }] = useMutation(REVIEW_VENDOR_ACCESS);
  const { cms } = useContext(PrivateContext);
  const settingReview = cmsVendorSettingReview(cms);
  const { label, messageToast, errorMessage } = settingReview;
  const [active, setActive] = useState(status || false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [updateAt, setUpdateAt] = useState(new Date());
  const [value, setValue] = useState(label.welcomeText);
  const [changeValue, setChangeValue] = useState(value);

  const contentStatus = active ? constant.DISABLE : constant.ENABLE;
  const setButtonColor = active;

  useEffect(() => {
    const { getVendorAccessSetting } = reviewVendorData || {};
    const { data } = getVendorAccessSetting || {};
    const { updatedAt = "", review } = data || {};
    const { welcomeText = "" } = review || {};
    if (welcomeText) {
      setValue(welcomeText);
      setChangeValue(welcomeText);
    }
    setUpdateAt(updatedAt);
    setActive(status);
    if (status) {
      setOpen(status);
    }
  }, [reviewVendorData, status]);

  const handleChange = useCallback(
    (newValue) => {
      const isCheckValue = !newValue.replace(/\s/g, "").length;
      if (!newValue) {
        setUpdateEnabled(false);
      } else if (newValue.length >= 1024) {
        setError(errorMessage.modalError);
        setUpdateEnabled(false);
      } else {
        setError("");
        setUpdateEnabled(!isCheckValue);
      }
      setValue(newValue);
      setChecked(false);
      if (!checked) {
        setChangeValue(newValue);
      }
    },
    [checked, errorMessage.modalError]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleApproveReject = useCallback(async (isEnabled, textValue) => {
    try {
      const response = await reviewVendorAccess({
        variables: { input: { isEnabled, welcomeText: textValue } },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.REVIEW_VENDOR_ACCESS);
      setIsLoadingAction(false);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setChangeValue(value);
    } catch (exception) {
      baseHelper.getResponseError(exception, constant.gql.REVIEW_VENDOR_ACCESS);
    }
  });

  const handleChangeCheckbox = useCallback((newChecked) => {
    setChecked(newChecked);
    setUpdateEnabled(true);
    if (newChecked) {
      return setValue(label.welcomeText);
    }
    return setValue(changeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = useCallback(() => {
    setIsLoadingAction(true);
    setActive(!active);
    handleApproveReject(!active, active ? "" : value);
    if (active) {
      setValue(label.welcomeText);
      setChecked(false);
      setMessage(messageToast.disableToastMessage);
    } else {
      setMessage(messageToast.enableToastMessage);
    }
    setOpen(!open);
  }, [
    active,
    handleApproveReject,
    label.welcomeText,
    messageToast.disableToastMessage,
    messageToast.enableToastMessage,
    open,
    value,
  ]);

  const onSubmit = () => {
    handleApproveReject(true, value);
    setActive(true);
    setUpdateAt(new Date());
    setMessage(messageToast.updateToastMessage);
    if (checked) {
      setValue(label.welcomeText);
    }
    setChecked(false);
    setUpdateEnabled(false);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={label.reviewSettingTitle}
        description={<TextStyle variation="subdued">{label.reviewSettingDescription}</TextStyle>}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <SettingToggle
              action={{
                content: contentStatus,
                onAction: handleToggle,
                destructive: setButtonColor,
                loading: isLoadingAction,
              }}
              enabled={setButtonColor}
            >
              <Heading variation="strong">{label.reviewTitle}</Heading>
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
              <br />
              <TextStyle>{label.reviewDescription}</TextStyle>
            </SettingToggle>
          </div>
          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <Stack vertical fill>
              <TextField value={value} onChange={handleChange} multiline={2} error={error} maxLength={1024} />
            </Stack>
            <Checkbox label={label.resetLabel} checked={checked} onChange={handleChangeCheckbox} />
            <div align="right">
              <Button primary submit disabled={!updateEnabled} onClick={onSubmit} loading={loading && !isLoadingAction}>
                {label.update}
              </Button>
            </div>
          </Collapsible>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(VendorReviewAccess), {
  feature: constant.VENDOR_REVIEW_SETTING,
});
