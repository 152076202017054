import React, { useContext, useCallback, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";
import { ProductProvider } from "app/product/modules/generic/context";
import { tabProp } from "app/product/modules/generic/propTypes";

// import context
import { PrivateContext } from "lib/context/privateContext";
import addData, { getPanelComponent } from "app/product/modules/admin/features/add/config/addData";

const ProductTabs = (props) => {
  const { cms } = useContext(PrivateContext);
  const { setBanner, setDescription } = props;
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  return (
    <>
      <Card>
        <Tabs tabs={addData(cms).tabs} selected={selected} setBanner={setBanner} />
      </Card>
      <ProductProvider
        value={{
          handleTabChange,
          setBanner,
          setDescription,
        }}
      >
        <div className="productComponent">{getPanelComponent(selected, cms)}</div>
      </ProductProvider>
    </>
  );
};

ProductTabs.propTypes = tabProp.type;
export default ProductTabs;
