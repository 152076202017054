import styled from "styled-components";

const StyledStack = styled.div`
  .Polaris-ChoiceList__Choices,
  .Polaris-Stack {
    padding-left: 25px;
  }
`;

export const StyledSelect = styled.div`
  .Polaris-Stack__Item {
    margin-top: 7px;
  }
`;

export const StyledSelectWidth = styled.div`
  .Polaris-Select {
    width: 80px;
  }
`;

export const StyledPageAction = styled.div`
  .Polaris-PageActions {
    padding-bottom: 0px;
  }
`;

export default StyledStack;
