import { gql } from "apollo-boost";

const UPDATE_PAYMENT_EXPORT_SETTING = gql`
  mutation updatePaymentExportSetting($input: PaymentExportSetting) {
    updatePaymentExportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_PAYMENT_EXPORT_SETTING;
