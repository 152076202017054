import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout, Spinner } from "@shopify/polaris";
import { Banner } from "lib/components";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import { baseHelper } from "lib/helpers";
import {
  GET_ORDER_REPORT_SETTING,
  GET_PAYMENT_REPORT_SETTING,
  GET_PRODUCT_REPORT_SETTING,
} from "app/setup/apollo/queries";
import OrderExportReport from "app/setup/modules/generic/exportReport/orderExportReport";
import ProductExportReport from "app/setup/modules/generic/exportReport/productExportReport";
import PaymentExportReport from "app/setup/modules/generic/exportReport/paymentExportReport";

import constant from "lib/constant/constant";

const ProviderConsolidatedReport = () => {
  const [openSetupProduct, setOpenSetupProduct] = useState(false);
  const [openSetupOrder, setOpenSetupOrder] = useState(false);
  const [openSetupPayment, setOpenSetupPayment] = useState(false);
  const [orderLastUpdateAt, setOrderLastUpdateAt] = useState();
  const [productLastUpdateAt, setproductLastUpdateAt] = useState();
  const [paymentLastUpdateAt, setPaymentLastUpdateAt] = useState();
  const [bannerValue, setBannerValue] = useState({
    uncheckCsvBanner: false,
    uncheckPdfBanner: false,
    duplicateBanner: false,
  });
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const { cms } = useContext(PrivateContext);

  const {
    loading: orderExportLoading,
    error: errorOrderReportSetting,
    data: dataOrderReportSetting,
    refetch: orderFetch,
  } = useQuery(GET_ORDER_REPORT_SETTING);
  const {
    loading: paymentExportLoading,
    error: errorPaymentReportSetting,
    data: dataPaymentReportSetting,
    refetch: paymentFetch,
  } = useQuery(GET_PAYMENT_REPORT_SETTING);
  const {
    loading: productExportLoading,
    error: errorProductReportSetting,
    data: dataProductReportSetting,
    refetch: productFetch,
  } = useQuery(GET_PRODUCT_REPORT_SETTING);

  useEffect(() => {
    const orderResponseData = baseHelper.getResponseData(dataOrderReportSetting, constant.gql.GET_ORDER_REPORT_SETTING);
    if (orderResponseData) {
      const { updatedAt: orderExportUpdatedAt } = orderResponseData;
      setOrderLastUpdateAt(orderExportUpdatedAt);
    }
  }, [dataOrderReportSetting]);

  useEffect(() => {
    const paymentResponseData = baseHelper.getResponseData(
      dataPaymentReportSetting,
      constant.gql.GET_PAYMENT_REPORT_SETTING
    );
    if (paymentResponseData) {
      const { updatedAt: paymentExportUpdateAt } = paymentResponseData;
      setPaymentLastUpdateAt(paymentExportUpdateAt);
    }
  }, [dataPaymentReportSetting]);

  useEffect(() => {
    const productResponseData = baseHelper.getResponseData(
      dataProductReportSetting,
      constant.gql.GET_PRODUCT_REPORT_SETTING
    );
    if (productResponseData) {
      const { updatedAt: productExportUpdatedAt } = productResponseData;
      setproductLastUpdateAt(productExportUpdatedAt);
    }
  }, [dataProductReportSetting]);

  useEffect(() => {
    if (bannerValue.duplicateBanner) {
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.duplicate"),
        status: constant.CRITICAL,
      });
    }
    if (bannerValue.uncheckCsvBanner) {
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (bannerValue.uncheckPdfBanner) {
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
  }, [bannerValue, cms]);

  const handleSetupToggleProductExport = () => {
    setOpenSetupProduct(!openSetupProduct);
  };
  const handleSetupToggleOrderExport = () => {
    setOpenSetupOrder(!openSetupOrder);
  };
  const handleSetupTogglePaymentExport = () => {
    setOpenSetupPayment(!openSetupPayment);
  };
  const dismissBanner = () => {
    setBannerStatus({ isOpen: false, status: "", title: "" });
    setBannerValue({
      uncheckCsvBanner: false,
      uncheckPdfBanner: false,
      bannerStatus: false,
    });
  };
  if (orderExportLoading) {
    return <Spinner />;
  }
  if (paymentExportLoading) {
    return <Spinner />;
  }
  if (productExportLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Layout>
        <Layout.Section>
          {bannerStatus.isOpen && (
            <Layout.Section>
              <Banner
                isOpen={bannerStatus.isOpen}
                status={bannerStatus.status}
                title={bannerStatus.title}
                onDismiss={() => dismissBanner()}
              />
            </Layout.Section>
          )}
          <ProductExportReport
            error={errorProductReportSetting}
            data={dataProductReportSetting}
            handleSetupToggleProductExport={handleSetupToggleProductExport}
            openSetupProduct={openSetupProduct}
            productLastUpdateAt={productLastUpdateAt}
            refetch={productFetch}
            setBannerStatus={setBannerStatus}
          />
          <OrderExportReport
            error={errorOrderReportSetting}
            data={dataOrderReportSetting}
            handleSetupToggleOrderExport={handleSetupToggleOrderExport}
            openSetupOrder={openSetupOrder}
            orderLastUpdateAt={orderLastUpdateAt}
            refetch={orderFetch}
            setBannerStatus={setBannerStatus}
          />
          <PaymentExportReport
            error={errorPaymentReportSetting}
            data={dataPaymentReportSetting}
            handleSetupTogglePaymentExport={handleSetupTogglePaymentExport}
            openSetupPayment={openSetupPayment}
            paymentLastUpdateAt={paymentLastUpdateAt}
            refetch={paymentFetch}
            setBannerStatus={setBannerStatus}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withErrorBoundary(ProviderConsolidatedReport);
