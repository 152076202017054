import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { codeRegex } = yupHelper;

const nameSchema = (cms) =>
  yup.object().shape({
    name: yup.string().required(cms("textField.message.name")).max(50, cms("textField.message.maxLength")),
  });

const codeSchema = (cms) =>
  yup.object().shape({
    code: yup
      .string()
      .required(cms("textField.message.code"))
      .matches(codeRegex, cms("textField.message.codeError"))
      .max(100, cms("textField.message.maxCodeLength")),
  });

const descriptionSchema = (cms) =>
  yup.object().shape({
    description: yup
      .string()
      .min(1, cms("textField.message.description"))
      .required(cms("textField.message.description"))
      .max(500, cms("textField.message.maxDescriptionLength")),
  });

const priceSchema = (cms) =>
  yup.object().shape({
    price: yup.number().typeError(cms("textField.message.price")),
  });

const periodSchema = (cms) =>
  yup.object().shape({
    period: yup.string().required(cms("textField.message.period")),
  });

const trialPeriodSchema = (cms) =>
  yup.object().shape({
    trialPeriod: yup.number().typeError(cms("textField.message.trialPeriod")),
  });

const productLimitSChema = (cms) =>
  yup.object().shape({
    productLimit: yup
      .number()
      .typeError(cms("textField.message.productLimit"))
      .min(1, cms("textField.message.minimumProductLimit")),
  });

export { nameSchema, codeSchema, descriptionSchema, priceSchema, periodSchema, trialPeriodSchema, productLimitSChema };
