import { gql } from "apollo-boost";

const GET_FULFILLMENT = gql`
  query GetFulfillmentData($input: OwnerId!) {
    getFulfillment(input: $input) {
      data {
        type
        isThirdPartyShipping
        shippingOption
        clientId
        clientSecret
        siteCode
        accountNumber
        consignment {
          global {
            address
            city
            country
            countryCode
            pincode
          }
          addresses {
            vendorIds
            address {
              address
              city
              country
              countryCode
              pincode
            }
          }
        }
        ownerId
        isThirdPartyShipping
      }
      status
      error
    }
  }
`;

export const GET_SELLER_FULFILLMENT = gql`
  query getSellerFulfillment {
    getSellerFulfillment {
      data {
        clickCollect {
          by
          setting {
            vendorId
            productType
          }
        }
        timeService {
          by
          setting {
            vendorId
            productType
          }
        }
        clickReserve {
          by
          setting {
            vendorId
            productType
          }
        }
        consignmentShip {
          by
          isThirdPartyShipping
          setting {
            addressId
            productType
            vendorId
          }
          shippingOption
          shippo {
            apiKey
            isAutoFulfill
            isShippingRateRequired
            shippingRateName
            defaultCarrier {
              id
              name
              service
            }
            vendorCarrier {
              id
              name
              service
              vendorId
            }
          }
        }
        consignmentPickup {
          by
          setting {
            vendorId
            productType
          }
          hopscotch {
            apiKey
          }
        }
        default
        dropship {
          by
          canadaPost {
            contractId
            customerNumber
            password
            userName
          }
          isThirdPartyShipping
          nzPost {
            accountNumber
            clientId
            clientSecret
            siteCode
          }
          sendabox {
            apiKey
            accessKey
            isAutoFulfill
            isShippingRateRequired
            defaultCarrier {
              id
              name
              service
            }
            vendorCarrier {
              id
              name
              service
              vendorId
            }
            shippingRateName
          }
          shippo {
            apiKey
            isAutoFulfill
            isShippingRateRequired
            defaultCarrier {
              id
              name
              service
            }
            shippingRateName
            vendorCarrier {
              id
              name
              service
              vendorId
            }
          }
          shippyPro {
            apiKey
            isAutoFulfill
            isShippingRateRequired
            defaultCarrier {
              id
              name
              service
            }
            vendorCarrier {
              id
              name
              service
              vendorId
            }
          }
          starshipit {
            apiKey
          }
          setting {
            productType
            vendorId
          }
          ups {
            accessLicenseNumber
            accountNumber
            isAutoFulfill
            password
            username
          }
          shippingOption
        }
        sellerId
        type
      }
      status
      error
    }
  }
`;

export default GET_FULFILLMENT;
