import styled from "styled-components";
import Carousel from "react-multi-carousel";

const StyledCarousel = styled(Carousel)`
  .react-multiple-carousel__arrow {
    min-width: 35px;
    min-height: 35px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.05);
    &.react-multiple-carousel__arrow--right {
      right: calc(1% + 1px);
      &:before {
        font-size: 14px;
        color: #7e7474;
      }
    }
    &.react-multiple-carousel__arrow--left {
      left: calc(1.2% + 1px);
      &:before {
        font-size: 14px;
        color: #7e7474;
      }
    }
  }
`;
export default StyledCarousel;
