import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import { OperatorProviderAdd } from "./operator";
import { AdminProviderAdd } from "./admin";

const { userKey } = constant;
const { admin, operator } = userKey;

const VendorAdd = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminProviderAdd />;
    }
    if (role === operator) {
      return <OperatorProviderAdd />;
    }
    return null;
  };
  if (isLoading) {
    return <SkeletonAnnotated annotatedLines={5} primaryLines={9} />;
  }
  const currentUserRole = storageHelper.get("userRole");
  const VendorAddComponent = getComponent(currentUserRole);
  return VendorAddComponent;
};

export default VendorAdd;
