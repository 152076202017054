import { gql } from "apollo-boost";

const GET_ORDER_REPORT_SETTING = gql`
  query getOrderReportSetting {
    getOrderReportSetting {
      data {
        report {
          orderNumber {
            label
            isHideCsv
            isHidePdf
          }
          lineItemId {
            label
            isHideCsv
            isHidePdf
          }
          orderStatus {
            label
            isHideCsv
            isHidePdf
          }
          vendorAmount {
            label
            isHideCsv
            isHidePdf
          }
          createdAt {
            label
            isHideCsv
            isHidePdf
          }
          fulfillmentStatus {
            label
            isHideCsv
            isHidePdf
          }
          grossSales {
            label
            isHideCsv
            isHidePdf
          }
          quantity {
            label
            isHideCsv
            isHidePdf
          }
          shipping {
            label
            isHideCsv
            isHidePdf
          }
          grossProfit {
            label
            isHideCsv
            isHidePdf
          }
          totalSalesPrice {
            label
            isHideCsv
            isHidePdf
          }
          returns {
            label
            isHideCsv
            isHidePdf
          }
          trackingNumber {
            label
            isHideCsv
            isHidePdf
          }
        }
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_ORDER_REPORT_SETTING;
