import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { storageHelper } from "lib/helpers";
import GenericExportOrder from "./genaricExport/export";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ExportOrder = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner isFullPage />;
    }
    if (role === admin) {
      return <GenericExportOrder />;
    }
    if (role === operator) {
      return <GenericExportOrder />;
    }
    if (role === provider) {
      return <GenericExportOrder />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ExportOrderComponent = getComponent(currentUserRole);
  return ExportOrderComponent;
};

export default ExportOrder;
