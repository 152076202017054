import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { Banner, SkeletonAnnotated, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { NotFound } from "layout/private/components";

import { GET_VENDOR_VARIANT, GET_CURRENCY_RATE, GET_WHOLESALE_SETTING } from "app/product/apollo/queries";
import { UPDATE_VARIANT, UPLOAD_IMAGE } from "app/product/apollo/mutations";
import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";
import { GET_GEOLOCATION_SETTING } from "app/setup/apollo";

import validate from "app/product/modules/provider/features/variant/subFeatures/yup/validate";
import { TitleEdit, Image, Price, Inventory, Shipping, Location } from "./subFeatureItems";

const {
  value: { MAX_MEASUREMENT = 999 },
  SKU,
} = constant;

const ProviderVariantEdit = () => {
  const { cms, history, match } = useContext(PrivateContext);
  const { params } = match;
  const { location } = history;
  const { state } = location;
  const { productId, id: variantId } = params;
  const [locations, setLocation] = useState([]);
  const [basePricing, setBasePricing] = useState({
    price: 0,
    comparePrice: 0,
    currencyCode: "",
  });
  const [rate, setRate] = useState([]);
  const [message, setMessage] = useState("");
  const [currencyPrice, setCurrencyPrice] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [isWholesalePrice, setIsWholesalePrice] = useState("");
  // const [metaFieldData, setMetafielData] = useState([]);
  // const [geoLocation, setGeoLocation] = useState([{}]);

  const [isImageError, setIsImageError] = useState(false);
  const [isImageErrorDisable, setIsImageErrorDisable] = useState(false);
  const [productLabelForm, setProductLabelForm] = useState({
    barcode: "",
    comparePrice: 0,
    discount: "",
    inventory: "",
    markup: { price: 0, type: constant.FLAT },
    physicalProduct: "",
    price: "",
    quantity: "",
    sku: "",
  });

  const { loading: currencyRateLoading, data: currencyRateData } = useQuery(GET_CURRENCY_RATE);

  const { data: variantData, loading, error } = useQuery(GET_VENDOR_VARIANT, {
    variables: { input: { id: productId } },
  });
  const { loading: locationLoading, data: locationData } = useQuery(GET_GEOLOCATION_SETTING);
  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);
  const { data: dataProductForm } = useQuery(GET_PRODUCT_FORM);
  const [updateVariant, { loading: updateLoading, error: updateVariantError }] = useMutation(UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_VENDOR_VARIANT,
        variables: { input: { id: productId } },
      },
    ],
  });
  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [variantValue, setVariantValue] = useState({});
  const [image, setImage] = useState();
  const [options, setOptions] = useState({
    option1Val: null,
    option2Val: null,
    option3Val: null,
  });

  const [disabledButton, setDisabledButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });

  const { gql } = constant;

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  // const [getGeolocationSetting, { data: geoLocationData }] = useLazyQuery(GET_GEOLOCATION_SETTING);
  // useEffect(() => {
  //   if (geoLocationData) {
  //     const geoLocationDataResponse = baseHelper.getResponseData(geoLocationData, gql.GET_GEOLOCATION_SETTING);
  //     const { locations } = geoLocationDataResponse;
  //     const cities = metaFieldData.map((item) => item && item.location.toLowerCase()) || [];
  //     const loc = locations.map((val) => ({
  //       value: val,
  //       disabled: false || cities.includes(val),
  //       label: val
  //         .toLowerCase()
  //         .split(" ")
  //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" "),
  //     }));
  //     setGeoLocation(loc);
  //   }
  // }, [geoLocationData, gql.GET_GEOLOCATION_SETTING, metaFieldData]);

  // const handleLocationChange = (fieldValue) => {
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.labal,
  //     value: geo.value,
  //     disabled: geo.value === fieldValue,
  //   }));
  //   setGeoLocation(loc);
  //   const locationData = [...metaFieldData];
  //   locationData.push({
  //     location: fieldValue,
  //     price: variantValue.price || 0,
  //     comparePrice: variantValue.comparePrice || 0,
  //     quantity: variantValue.inventoryQuantity || 0,
  //   });
  //   setMetafielData(locationData);
  //   setDisabledButton(false);
  // };

  // const handleTableChange = (newValue, id, field) => {
  //   const metaData = [...metaFieldData];
  //   metaData[id][field] = newValue;
  //   setMetafielData(metaData);
  //   setDisabledButton(false);
  // };

  // const handleDelete = (index) => {
  //   const metaData = [...metaFieldData];
  //   const deletedCities = metaData.splice(index, 1);
  //   const { location: cities } = deletedCities[0];
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.label,
  //     value: geo.value,
  //     disabled: geo.value === cities ? false : geo.disabled,
  //   }));
  //   setGeoLocation(loc);
  //   setMetafielData(metaData);
  //   setDisabledButton(false);
  // };

  const currencyRateError = baseHelper.getResponseError(currencyRateData, gql.GET_CURRENCY_RATE);

  useEffect(() => {
    if (dataProductForm) {
      const responseData = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      const responseError = baseHelper.getResponseError(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      }
      if (responseData && responseData.title) {
        const { barcode, comparePrice, discount, inventory, markup, price, physical, quantity, sku } = responseData;
        setProductLabelForm({
          barcode,
          comparePrice,
          discount,
          inventory,
          markup,
          physicalProduct: physical,
          price,
          quantity,
          sku,
        });
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  useEffect(() => {
    if (currencyRateError) {
      return;
    }
    const currencyRateSetting = baseHelper.getResponseData(currencyRateData, constant.gql.GET_CURRENCY_RATE) || {};
    const { currency = {} } = currencyRateSetting || {};
    const { rate: rateData = [] } = currency || {};
    setRate(rateData);
  }, [currencyRateError, currencyRateData]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  useEffect(() => {
    if (state && state.addVariant) {
      setBanner({
        action: {
          content: cms("label.action"),
          onAction: () => history.push(`/variants/add/${productId}`),
        },
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.create"),
      });
      const newState = state;
      delete newState.addVariant;
      history.replace({ ...history.location, state: newState });
    }
  }, [cms, history, productId, state]);

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, uploadImageError, updateVariantError]);

  useEffect(() => {
    const { option1Val, option2Val, option3Val } = options;
    let isAllValuesFilled = false;
    if (option1Val) {
      isAllValuesFilled = option1Val && variantValue.option1Val;
    }
    if (option2Val) {
      isAllValuesFilled = option2Val && variantValue.option2Val;
    }
    if (option3Val) {
      isAllValuesFilled = option3Val && variantValue.option3Val;
    }

    const isAnyValidationError =
      errorMessage &&
      !!(
        (errorMessage.size && option1Val) ||
        (errorMessage.color && option2Val) ||
        (errorMessage.material && option3Val)
      );
    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [variantValue, errorMessage, options]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT);
    if (responseData) {
      const { variants = [], images = [] } = responseData || {};
      if (variants.length < variantId || variantId <= 0) {
        return <NotFound />;
      }
      const variantOption = variants[variantId - 1];
      setOptions({
        option1: variantOption.option1,
        option2: variantOption.option2,
        option3: variantOption.option3,
        option1Val: variantOption.option1Val,
        option2Val: variantOption.option2Val,
        option3Val: variantOption.option3Val,
      });
      setProductImages(images || []);
      setImage((variantOption.image && [{ url: variantOption.image }]) || []);
      setVariantValue(variants[variantId - 1]);
      setBasePricing(variants[variantId - 1].basePricing);
      if (variants[variantId - 1]) {
        const { metafield = [], customInfo } = variants[variantId - 1] || {};
        if (customInfo) {
          setWholesalePrice(variants[variantId - 1].customInfo.wholesalePrice || 0);
        }
        // if (metafield && metafield.length) {
        //   const getMetaData = metafield.map((meta) => {
        //     const val = baseHelper.isValidJSON(meta.value) ? JSON.parse(meta.value) : meta.value;
        //     return {
        //       location: meta.key,
        //       price: val.price,
        //       comparePrice: val.comparePrice,
        //       quantity: val.quantity,
        //     };
        //   });

        //   setMetafielData(getMetaData);
        // }
      }
      // getGeolocationSetting();
    }
  }, [variantData, locationData, gql, variantId]); // getGeolocationSetting

  useEffect(() => {
    if (locationData) {
      const locationSetting = baseHelper.getResponseData(locationData, constant.gql.GET_GEOLOCATION_SETTING) || {};
      const { isAllowed = false, locations: locationArray = [] } = locationSetting;
      if (isAllowed && locationArray && locationArray.length) {
        const updatedLocations = locationArray.map(location => ({
          label: location,
          value: location,
          disabled: false,
        }));
        setLocation(updatedLocations);
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (rate && rate.length && basePricing && basePricing.currencyCode) {
      const currencyData =
        rate && rate.length && rate.find((element) => basePricing.currencyCode === element.currencyCode);
      if (currencyData) {
        const { currencySymbol: symbol, price } = currencyData;
        setCurrencySymbol(symbol);
        setCurrencyPrice(price);
      }
    }
  }, [basePricing, rate]);

  if (loading || locationLoading || currencyRateLoading || wholesaleLoading) {
    return <SkeletonAnnotated />;
  }

  const variantUpdate = (updateValues) => {
    const weightData = updateValues.weight;
    // eslint-disable-next-line no-param-reassign
    delete updateValues.weight;
    // eslint-disable-next-line no-param-reassign
    updateValues.weight = parseFloat(weightData);

    if (isWholesalePrice) {
      // eslint-disable-next-line no-param-reassign
      updateValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice || 0) };
    }
    updateVariant({ variables: { input: updateValues } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.EDIT_VARIANT);
        if (resError) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: resError,
          });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_VARIANT);
        if (responseData) {
          setMessage(cms("message.success.update"));
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  const onSubmit = async () => {
    const result = variantValue;
    result.comparePrice = parseFloat(result.comparePrice);
    result.price = parseFloat(result.price);
    const { price = 0, comparePrice = 0 } = basePricing || {};
    result.basePricing = {};
    if (result?.basePricing?.price) {
      result.basePricing.price = parseFloat(price || 0);
      result.basePricing.comparePrice = parseFloat(comparePrice || 0);
    }
    await handleValidation("option1", result.option1Val);
    await handleValidation("option2", result.option2Val);
    await handleValidation("option3", result.option3Val);

    result.inventoryQuantity = parseInt(result.inventoryQuantity, 10);
    const { vendorDiscount, discount, measurement = {} } = result || {};
    result.discount = vendorDiscount || discount || {};
    if (result?.discount?.price) {
      result.discount.price = parseFloat(result.discount.price || 0);
    }
    const { barcode, sku, weight } = result || {};
    const dataToCheck = {
      barcode,
      comparePrice: result.comparePrice,
      physicalProduct: result.isShipping,
      price: result.price,
      quantity: result.inventoryQuantity,
      sku,
      weight,
    };

    if (result.discount && result.discount.price !== "") {
      dataToCheck.discount = result.discount;
    }

    const requiredKeys =
      (productLabelForm &&
        Object.keys(productLabelForm).filter((item) => productLabelForm[item] && productLabelForm[item].isRequired)) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        const valueData = dataToCheck[key];
        if (!valueData && constant.VARIANTS_KEY.includes(key)) {
          errorField =
            productLabelForm[key].label ||
            (key === constant.PHYSICAL_PRODUCT ? cms("section.shipping.label.physical") : cms(`label.${key}`)) ||
            (key === "comparePrice" && "Compare Price") ||
            key;
        }
      });
    }
    if (errorField) {
      const check = cms("section.shipping.label.physical") === errorField ? " checkbox" : "";
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: `${baseHelper.ucFirst(errorField)}${check} is required.`,
      });
      return false;
    }

    if (!submitButton.isReadyToSubmit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: constant.SMOOTH,
      });
      return false;
    }

    const { length = {}, width = {}, height = {} } = measurement || {};
    let { value: lengthValue = 0 } = length || {};
    let { value: widthValue = 0 } = width || {};
    let { value: heightValue = 0 } = height || {};

    lengthValue = parseFloat(lengthValue);
    widthValue = parseFloat(widthValue);
    heightValue = parseFloat(heightValue);

    if (lengthValue > MAX_MEASUREMENT || widthValue > MAX_MEASUREMENT || heightValue > MAX_MEASUREMENT) {
      const bannerData = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.measurement.length.title"),
      };
      setBanner(bannerData);
      return false;
    }

    // // check volumetric weight is greater than 180 or not if yes then through error
    // const isLargeVolumetricWeight = baseHelper.isLargeVolumetricWeight(lengthValue, widthValue, heightValue);
    // if (isLargeVolumetricWeight) {
    //   const bannerData = {
    //     action: null,
    //     isOpen: true,
    //     status: constant.CRITICAL,
    //     title: cms("section.measurement.width.title"),
    //   };
    //   setBanner(bannerData);
    //   return false;
    // }

    result.measurement = {
      length: {
        value: lengthValue,
        unit: constant.CM,
      },
      width: {
        value: widthValue,
        unit: constant.CM,
      },
      height: {
        value: heightValue,
        unit: constant.CM,
      },
    };

    delete result.length;
    delete result.width;
    delete result.height;

    const notToUpdate = constant.NOT_TO_UPDATE;
    notToUpdate.forEach((element) => {
      delete result[element];
    });

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      result.image = { url: imageUrl };
    }
    result.id = parseInt(variantId, 10);
    result.productId = productId;

    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    // if (!isLocalImageUrl) {
    //   variantUpdate(result);
    // }

    if (!isImageError && isLocalImageUrl) {
      try {
        delete image.upload;
        const res = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
        if (resData) {
          result.image = { url: resData.imageUrl };
          // variantUpdate(result);
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    }

    // const metaData = metaFieldData.map((meta) => {
    //   return {
    //     key: meta.location,
    //     namespace: "location",
    //     type: "json",
    //     value: JSON.stringify({
    //       price: parseFloat(meta.price, 10),
    //       comparePrice: parseFloat(meta.comparePrice, 10),
    //       quantity: parseInt(meta.quantity, 10),
    //     }),
    //   };
    // });
    // result.metafield = metaData;
    variantUpdate(result);
    setDisabledButton(true);
    return null;
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldName === SKU || fieldValue > -1 || fieldValue.price > -1) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
      setDisabledButton(false);
    }
  };

  const handleMeasurementChange = (fieldName, fieldValue) => {
    if (fieldValue > -1) {
      setVariantValue((prevState) => ({
        ...prevState,
        measurement: {
          ...(prevState.measurement || {}),
          [fieldName]: {
            ...((prevState.measurement && prevState.measurement[fieldName]) || {}),
            value: fieldValue,
          },
        },
      }));
      setDisabledButton(false);
    }
  };

  const handleTitleChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
    setIsImageErrorDisable(true);
  };

  const handleCurrencyPrice = (field, newValue) => {
    setBasePricing((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    if (currencyPrice) {
      setVariantValue({
        ...variantValue,
        [field]: baseHelper.getPrice(((1 / currencyPrice) * newValue)),
      });
    }
  };
  const required = (value) => {
    if (value && value.isRequired) {
      return "*";
    }
    return "";
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
              action={banner.action}
              onDismiss={onDismissBanner}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleEdit
          cms={cms}
          errorMessage={errorMessage}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          options={options}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
          locations={locations}
        />
        <Image
          productImages={productImages}
          setImage={setImage}
          setDisabledButton={setDisabledButton}
          image={image}
          cms={cms}
          isImageError={isImageError}
          setIsImageError={setIsImageError}
          isImageErrorDisable={isImageErrorDisable}
        />
        <Price
          cms={cms}
          handleChange={handleChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
          basePricing={basePricing}
          productLabelForm={productLabelForm}
          required={required}
          setBasePricing={setBasePricing}
          handleCurrencyPrice={handleCurrencyPrice}
          currencyPrice={currencyPrice}
          currencySymbol={currencySymbol}
          setWholesalePrice={setWholesalePrice}
          wholesalePrice={wholesalePrice}
          isWholesalePriceVisible={isWholesalePrice}
        />
        <Inventory
          cms={cms}
          handleChange={handleChange}
          productLabelForm={productLabelForm}
          required={required}
          handleTitleChange={handleTitleChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        {/* {!!(geoLocation && geoLocation.length) && (
          <Location
            cms={cms}
            handleLocationChange={handleLocationChange}
            geoLocation={geoLocation}
            metaFieldData={metaFieldData}
            setMetafielData={setMetafielData}
            setDisabledButton={setDisabledButton}
            variantData={variantValue}
            handleTableChange={handleTableChange}
            handleDelete={handleDelete}
          />
        )} */}
        <Shipping
          cms={cms}
          handleChange={handleChange}
          productLabelForm={productLabelForm}
          required={required}
          handleMeasurementChange={handleMeasurementChange}
          handleTitleChange={handleTitleChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: updateLoading || imageLoading,
            disabled: disabledButton,
          }}
          secondaryActions={{
            content: cms("section.label.button.cancel"),
            onAction: () => history.push(`/products/edit/${productId}?tab=2`),
            disabled: updateLoading || imageLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderVariantEdit), { feature: constant.ADD_VARIANT });
