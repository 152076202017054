import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";

// helpers
import { baseHelper, hooksHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// contexts
import { PublicProvider } from "lib/context";

// gql
import { FETCH_CMS, CMS } from "routes/public/apollo/queries";

import { PublicLayout } from "layout";

import PublicBrandingContainer from "./publicBrandingContainer";
import publicPageContent from "./pageContent/pageContent";
import publicRouteCodes from "./routeCode/routeCode";

const PublicContainer = (props) => {
  const { gql } = constant;
  const { component: Component, ...rest } = props;
  const { match } = rest;
  const { path } = match;

  // get cms and module key on the basis of current path
  const { cms = "", module = "" } = publicRouteCodes[path];
  const pageData = publicPageContent[module] || {};

  // gql states
  const { loading: newCMSLoading, data: newCMSResponse } = useQuery(CMS, {
    variables: {
      input: { language: "en", code: cms || module, module: "public" },
    },
  });

  const { loading: cmsLoading, data: cmsResponse } = useQuery(FETCH_CMS, {
    variables: {
      input: { routeCode: cms || module },
    },
  });
  const newCMSData = baseHelper.getResponseData(newCMSResponse, "getPublicCMS");
  const cmsData = baseHelper.getResponseData(cmsResponse, gql.GET_CONTENT);
  const formattedCMSData = baseHelper.getFormattedCMS(cmsData);
  const { useCMS } = hooksHelper;
  return (
    <>
      <PublicProvider
        value={{
          oldCMS: formattedCMSData,
          cms: useCMS(newCMSData),
          pageData,
          isLoading: cmsLoading || newCMSLoading,
          ...rest,
        }}
      >
        <PublicBrandingContainer>
          <PublicLayout>
            <Component />
          </PublicLayout>
        </PublicBrandingContainer>
      </PublicProvider>
    </>
  );
};

PublicContainer.propTypes = {
  component: PropTypes.element.isRequired,
};

export default PublicContainer;
