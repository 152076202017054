// import packages
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import LargeModalExample from "app/onboarding/modules/operator/stepperOnboarding/features/components/modal";

// import gql
import { UPDATE_TERM } from "app/onboarding/apollo/mutations";
import { GET_TERM } from "app/onboarding/apollo/queries";

// import sub features
import { Returns, Payment } from "./subFeatures";

// import yup validation
import validate from "./yup/validate";

const {
  PAYMENT_TERMS_DAY,
  ALLOWED_DAY,
  RETURN_ALLOWED,
  NO_RETURN_ALLOWED,
  PRE_FULFILLMENT,
  onboardingSteps,
} = constant;

const OperatorTerms = (props) => {
  const { setSelectedTab } = props;
  const { cms = {}, currentUser, isLoading = false } = useContext(PrivateContext);
  const { ecommercePlatform = "" } = currentUser || {};

  const onNext = () => {
    if (ecommercePlatform === constant.BIGCOMMERCE) {
      setSelectedTab(onboardingSteps.FINISH);
    } else {
      setSelectedTab(onboardingSteps.APP_PERMISSION);
    }
  };
  const onPrevious = () => setSelectedTab(onboardingSteps.COMMISSION);

  const { error: termError, data: termData, loading: termLoading } = useQuery(GET_TERM);
  const [setUpTerms, { loading }] = useMutation(UPDATE_TERM);
  const { isReturn = "", returnPeriod = 1, paymentTerms = {} } = termData || {};

  const selectedPolicy = (!isReturn && NO_RETURN_ALLOWED) || RETURN_ALLOWED;

  const [returnDetail, setReturnDetail] = useState({
    selectedPolicy,
    allowedDay: returnPeriod,
  });

  const [paymentDetail, setPaymentValue] = useState({
    paymentTermsType: (paymentTerms && paymentTerms.type) || PRE_FULFILLMENT,
    paymentTermsDay: (paymentTerms && paymentTerms.days) || 0,
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [sheetActive, setSheetActive] = useState(false);

  const handleReturnChange = (field, value = null) => {
    if ((field === ALLOWED_DAY && !!value && !baseHelper.validateWholeNumber(value)) || Number(value) > 365) {
      return;
    }

    setReturnDetail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePaymentChange = (field, value) => {
    if ((field === PAYMENT_TERMS_DAY && !!value && !baseHelper.validateWholeNumber(value)) || Number(value) > 365) {
      return;
    }

    setPaymentValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isReturnAllowed = returnDetail.selectedPolicy !== NO_RETURN_ALLOWED;
  const isPaymentAllowed = paymentDetail.paymentTermsType !== PRE_FULFILLMENT;

  const setError = (field, errorText) => {
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: errorText,
    }));
  };

  const validateFields = async (field, value) => {
    if (isReturnAllowed || isPaymentAllowed) {
      const errorField = await validate(
        field,
        value,
        !value ? cms("message.error.days") : cms("message.error.daysLowerLimit")
      );
      setError(field, errorField);
    }
  };

  const setTermErrorBanner = useCallback((termErroMessage) => {
    setBanner({ isOpen: true, status: constant.CRITICAL, title: termErroMessage });
  }, []);

  // set term data | error
  useEffect(() => {
    let termErroMessage = "";
    const error = baseHelper.getResponseError(termData, constant.gql.GET_TERM);
    const termDataResponse = baseHelper.getResponseData(termData, constant.gql.GET_TERM);
    if (error) {
      termErroMessage = error;
    }
    if (termError) {
      termErroMessage = errorHelper.parse(termError);
    }
    if (termDataResponse) {
      setReturnDetail({
        selectedPolicy: (!termDataResponse.isReturn && NO_RETURN_ALLOWED) || RETURN_ALLOWED,
        allowedDay: termDataResponse.returnPeriod,
      });
      setPaymentValue({
        paymentTermsType: (termDataResponse.paymentTerms && termDataResponse.paymentTerms.type) || PRE_FULFILLMENT,
        paymentTermsDay: (termDataResponse.paymentTerms && termDataResponse.paymentTerms.days) || 0,
      });
    }
    if (!termLoading && error) {
      setTermErrorBanner(termErroMessage);
    }
  }, [cms, setTermErrorBanner, termData, termError, termLoading]);

  useEffect(() => {
    if (paymentDetail.paymentTermsType === PRE_FULFILLMENT) {
      handlePaymentChange(PAYMENT_TERMS_DAY, 0);
      setError(PAYMENT_TERMS_DAY, "");
    } else {
      handlePaymentChange(PAYMENT_TERMS_DAY, 0);
    }
    if (returnDetail.selectedPolicy === NO_RETURN_ALLOWED) {
      handleReturnChange(ALLOWED_DAY, 0);
      setError(ALLOWED_DAY, "");
    }
  }, [returnDetail.selectedPolicy, paymentDetail.paymentTermsType]);

  if (isLoading || termLoading) {
    return <SkeletonAnnotated />;
  }

  const onSubmit = async () => {
    const returnDayValue = parseInt(returnDetail.allowedDay, 10) <= 0 || returnDetail.allowedDay === "";
    const paymentDayValue = parseInt(paymentDetail.paymentTermsDay, 10) <= 0 || paymentDetail.paymentTermsDay === "";
    if (isReturnAllowed && returnDayValue) {
      setError(ALLOWED_DAY, cms("message.error.daysLowerLimit"));
      return;
    }
    if (isPaymentAllowed && paymentDayValue) {
      setError(PAYMENT_TERMS_DAY, cms("message.error.daysLowerLimit"));
      return;
    }

    const finalIsReturn = returnDetail.selectedPolicy === RETURN_ALLOWED;
    const finalReturnPeriod = parseInt(returnDetail.allowedDay, 10) || null;
    const finalPaymentTermType = paymentDetail.paymentTermsType;
    const finalPaymentTermDay = parseInt(paymentDetail.paymentTermsDay, 10);

    const initialReturnPeriod = parseInt(returnPeriod, 10);
    const initialPaymentTermsType = (paymentTerms && paymentTerms.type) || "";
    const initialPaymentTermsDay = parseInt((paymentTerms && paymentTerms.days) || "", 10);

    // Not updating terms for non-changed values
    if (
      isReturn === finalIsReturn &&
      initialReturnPeriod === finalReturnPeriod &&
      initialPaymentTermsType === finalPaymentTermType &&
      initialPaymentTermsDay === finalPaymentTermDay
    ) {
      onNext();
      return;
    }

    try {
      const response = await setUpTerms({
        variables: {
          input: {
            isReturn: finalIsReturn,
            returnPeriod: finalReturnPeriod,
            paymentTerms: {
              days: finalPaymentTermDay,
              type: finalPaymentTermType,
            },
          },
        },
      });
      const responseData = baseHelper.getResponseData(response.data, constant.UPDATE_TERM);

      if (responseData) {
        onNext();
      }
      const responseError = baseHelper.getResponseError(response.data, constant.UPDATE_TERM);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <Layout id="add-vendors-settings">
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.onboardingTerms.title")}
        description={cms("section.onboardingTerms.description")}
      >
        <Returns
          cms={cms}
          errorMessage={errorMessage}
          sheetActive={sheetActive}
          sheetToggle={setSheetActive}
          handleChange={handleReturnChange}
          data={returnDetail}
          handleValidation={validateFields}
        />
        <Payment
          cms={cms}
          handleChange={handlePaymentChange}
          data={paymentDetail}
          errorMessage={errorMessage}
          handleValidation={validateFields}
          sheetActive={sheetActive}
          sheetToggle={setSheetActive}
        />
        <PageActions
          primaryAction={{
            id: "MC_PORTAL_ONBOARDING_STEP_04",
            content: cms("common.button.nextStep"),
            onAction: () => onSubmit(),
            loading,
          }}
          secondaryActions={[
            {
              id: "previous",
              content: cms("common.button.previous"),
              onAction: () => onPrevious(),
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <LargeModalExample />
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: cms("common.button.close"), onAction: () => setSheetActive(false) }}
        primaryAction={{ content: cms("common.button.submit"), onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

OperatorTerms.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(OperatorTerms), { feature: constant.TERM });
