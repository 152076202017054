import React from "react";

import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { ViewActivity } from "app/userManagement/modules/generic/index";

const ProviderViewActivity = () => {
  return (
    <>
      <ViewActivity />
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderViewActivity), { feature: constant.VIEW_ACTIVITY });
