import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import schema from "./schema";

const validate = async (field, value, message) => {
  const { validateValue, handlePromiseError } = yupHelper;
  const {
    backgroundSchema,
    headerHexSchema,
    headingHexSchema,
    headerTextHexSchema,
    contentHexSchema,
    buttonHexSchema,
    buttonTextHexSchema,
    menuSchema,
    toastBackgroundColorSchema,
    toastTextSchema,
  } = schema(message);
  const {
    BACKGROUND,
    HEADER,
    HEADING,
    CONTENT,
    HEADER_TEXT,
    BUTTON,
    BUTTON_TEXT,
    MENU,
    TOAST_BACKGROUND_COLOR,
    TOAST_TEXT,
  } = constant;
  let promise = "";
  switch (field) {
    case BACKGROUND:
      promise = validateValue(backgroundSchema, { background: value });
      break;
    case HEADER:
      promise = validateValue(headerHexSchema, { header: value });
      break;
    case HEADING:
      promise = validateValue(headingHexSchema, { heading: value });
      break;
    case CONTENT:
      promise = validateValue(contentHexSchema, { content: value });
      break;
    case HEADER_TEXT:
      promise = validateValue(headerTextHexSchema, { headerText: value });
      break;
    case BUTTON:
      promise = validateValue(buttonHexSchema, { button: value });
      break;
    case BUTTON_TEXT:
      promise = validateValue(buttonTextHexSchema, { buttonText: value });
      break;
    case MENU:
      promise = validateValue(menuSchema, { menu: value });
      break;
    case TOAST_BACKGROUND_COLOR:
      promise = validateValue(toastBackgroundColorSchema, { toastBackgroundColor: value });
      break;
    case TOAST_TEXT:
      promise = validateValue(toastTextSchema, { toastText: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
