import React, { useContext } from "react";
import { AdminOnboardingList } from "app/userManagement/modules/admin/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin } = userKey;

const OnboardingList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminOnboardingList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OnboardingListComponent = getComponent(currentUserRole);
  return OnboardingListComponent;
};

export default OnboardingList;
