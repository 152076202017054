import { gql } from "apollo-boost";

const GET_TERM = gql`
  query {
    getTermSetting {
      data {
        isReturn
        paymentTerms {
          days
          type
        }
        returnPeriod
        updatedAt
        advanceReturn {
          vendorId
          isReturn
          returnPeriod
          option
        }
        advancePayment {
          vendorId
          days
          type
          option
        }
      }
      error
      status
    }
  }
`;

export default GET_TERM;
