import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { storageHelper } from "lib/helpers";
import GenericExportShipping from "./operator/export";

const { userKey } = constant;
const { operator } = userKey;

const ExportShipping = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner isFullPage />;
    }
    if (role === operator) {
      return <GenericExportShipping />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ExportOrderComponent = getComponent(currentUserRole);
  return ExportOrderComponent;
};

export default ExportShipping;
