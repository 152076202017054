import React from "react";
import { Card, TextField, Layout, TextContainer, Caption } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";

import constant from "lib/constant/constant";

import { baseHelper } from "lib/helpers";
import { titleProp } from "app/productOld/modules/generic/edit/propTypes";

const TitleDescription = (props) => {
  const {
    data,
    handleChange,
    cms,
    // learnMore
  } = props;
  return (
    <Layout.Section>
      <Card
        title={[
          cms("title"),
          data && data.updatedAt && (
            <Caption>{`Last updated at : On ${baseHelper.formatDate(data.updatedAt)}`}</Caption>
          ),
        ]}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () => {
        //       learnMore(cms("section.information.title"), cms("label.todo"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <TextContainer>{cms("section.information.description")}</TextContainer>
          <br />
          <TextField
            id="title"
            label={`${cms("section.information.label.title")}*`}
            value={data.title || ""}
            onChange={(value) => handleChange(constant.TITLE, value)}
            placeholder={cms("section.information.placeholder.title")}
          />
          <br />
          {cms("section.information.label.description")}
          <Editor
            id="idTiny"
            textareaName={cms("label.description")}
            value={data.description}
            onEditorChange={(value) => handleChange(constant.DESCRIPTION, value)}
            init={{
              menubar: true,
              plugins: ["autolink link image lists print preview"],
              toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
              entity_encoding: "raw",
            }}
          />
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};

TitleDescription.propTypes = titleProp.type;

export default TitleDescription;
