import React, { useState, useContext, useEffect } from "react";
import { Tabs } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { SkeletonAnnotated, Spinner } from "lib/components";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

// sub features
import OperatorAppPermission from "app/appPermission/modules/operator/appPermission";
import { About, Commission, Finish, Terms, UpdateProfile, Welcome } from "./features";

import tabs from "./stepperOnboardingConfig";

const { onboardingSteps } = constant;
const { ABOUT, APP_PERMISSION, COMMISSION, FINISH, TERM, UPDATE_PROFILE, WELCOME } = onboardingSteps;

const OperatorStepperOnboarding = () => {
  const { currentUser, isLoading, setOnboardingTab } = useContext(PrivateContext);
  const { ecommercePlatform = "" } = currentUser || {};
  const initialSelectedTab = storageHelper.get("setupProgress") || "welcome";

  const indexToTabKeyMapper = {
    welcome: WELCOME,
    about: ABOUT,
    businessInfo: UPDATE_PROFILE,
    commission: COMMISSION,
    term: TERM,
    appPermission: APP_PERMISSION,
  };

  let updatedTabs = tabs;

  if (ecommercePlatform === constant.BIGCOMMERCE) {
    const index = tabs.findIndex((item) => item.id === APP_PERMISSION);
    if (index > -1) {
      tabs.splice(index, 1);
    }
    updatedTabs = tabs.map((tab) => {
      if (tab.id === FINISH) {
        return {
          ...tab,
          tabIndex: tab.tabIndex - 1,
        };
      }
      return tab;
    });
    delete indexToTabKeyMapper.appPermission;
  }

  const findTab = (key) => updatedTabs.find((tab) => tab.id === key);
  const tabKey = indexToTabKeyMapper[initialSelectedTab];
  const currentTab = findTab(tabKey);
  // states
  const [selected, setSelected] = useState(tabKey);
  const [selectedTabIndex, setSelectedTabIndex] = useState(currentTab && currentTab.tabIndex);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [selected]);

  const changeTab = (value) => {
    setSelected(value);
    const activeTab = findTab(value);
    setSelectedTabIndex(activeTab && activeTab.tabIndex);
    if (value !== FINISH) {
      setOnboardingTab(value);
    }
  };

  const getOnboardingStep = () => {
    if (isLoading) {
      if (selected === WELCOME || selected === FINISH) {
        return <Spinner />;
      }
      return <SkeletonAnnotated primaryLines={15} />;
    }
    switch (selected) {
      case WELCOME:
        return <Welcome setSelectedTab={changeTab} />;
      case ABOUT:
        return <About setSelectedTab={changeTab} />;
      case UPDATE_PROFILE:
        return <UpdateProfile setSelectedTab={changeTab} />;
      case COMMISSION:
        return <Commission setSelectedTab={changeTab} />;
      case TERM:
        return <Terms setSelectedTab={changeTab} />;
      case APP_PERMISSION:
        return <OperatorAppPermission setSelectedTab={changeTab} />;
      case FINISH:
        return <Finish />;
      default:
        return null;
    }
  };

  const topMargin25px = {
    marginTop: "25px",
  };

  return (
    <>
      <Tabs tabs={tabs} selected={selectedTabIndex} fitted />
      <div style={topMargin25px}>{getOnboardingStep()}</div>
    </>
  );
};

export default OperatorStepperOnboarding;
