import { gql } from "apollo-boost";

const UPDATE_PRODUCT_REPORT_SETTING = gql`
  mutation updateProductReportSetting($input: ProductReportSetting) {
    updateProductReportSetting(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_PRODUCT_REPORT_SETTING;
