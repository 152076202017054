// import react packages;
import React, { useState, useEffect } from "react";

// import polaris components
import { Banner, Card, Checkbox, Layout, Modal, Scrollable, Stack, Subheading } from "@shopify/polaris";
import constant from "lib/constant/constant";
// import config

// import props
import { termProp } from "../../../props";

const TermModal = (props) => {
  const { cms, isOpen, item, loading, onSubmit, setIsOpen } = props;
  const [isEnabled, setIsEnabled] = useState(false);

  const handleCheckbox = () => {
    setIsEnabled(!isEnabled);
  };
  useEffect(() => {
    setIsEnabled(false);
  }, [isOpen]);
  return (
    <Modal
      large
      open={isOpen}
      title={cms("section.connect.terms.title")}
      onClose={() => setIsOpen(false)}
      primaryAction={{
        content: cms("section.connect.terms.button.continue"),
        onAction: () => onSubmit(),
        disabled: !isEnabled,
        loading,
      }}
      secondaryActions={[
        {
          content: cms("section.connect.button.cancel"),
          plain: true,
          onAction: () => setIsOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Layout>
            <Layout.Section>
              <Banner status={constant.WARNING}>
                <p>{cms("section.connect.paraTag.para1", { item })}</p>
              </Banner>
            </Layout.Section>
            <Layout.Section>
              <Card sectioned>
                <Scrollable shadow className="scrollHeight">
                  <b>{`${cms("section.connect.boldTag.b1")} `}</b>
                  {cms("section.connect.paraTag.para2")}
                  <br />
                  <br />
                  {cms("section.connect.paraTag.para3")}
                  <b>{` ${cms("section.connect.boldTag.b1")} `}</b>
                  {cms("section.connect.paraTag.para4", { item })}
                  <br />
                  <br />
                  {cms("section.connect.paraTag.para5")}
                  <b>{` ${cms("section.connect.boldTag.b1")} `}</b>
                  {cms("section.connect.paraTag.para6", { item })}
                  <br />
                  <br />
                  <Subheading>{cms("section.connect.subHeading.sub1", { item })}</Subheading>
                  {cms("section.connect.paraTag.para12", { item })}
                  <br />
                  <br />
                  <Subheading>{cms("section.connect.subHeading.sub2")}</Subheading>
                  {cms("section.connect.paraTag.para7")}
                  <br />
                  <br />
                  <Subheading>{cms("section.connect.subHeading.sub3")}</Subheading>
                  {cms("section.connect.paraTag.para8")}
                  <br />
                  <br />
                  <Subheading>{cms("section.connect.subHeading.sub4")}</Subheading>
                  {cms("section.connect.paraTag.para13")}
                  <br />
                  <br />
                  <b>{`${cms("section.connect.boldTag.b2")} ${cms("section.connect.boldTag.b1")} `}</b>
                  {cms("section.connect.paraTag.para9")}
                  <br />
                  <br />
                  {cms("section.connect.paraTag.para10")}
                  <b>{` ${cms("section.connect.boldTag.b1")}`}</b>
                  {cms("section.connect.paraTag.para11")}
                </Scrollable>
              </Card>
            </Layout.Section>
          </Layout>
          <Checkbox
            checked={isEnabled}
            label={cms("section.connect.terms.label.acceptTerm")}
            onChange={() => handleCheckbox()}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

TermModal.propTypes = termProp.type;
TermModal.defaultProps = termProp.default;

export default TermModal;
