import { gql } from "apollo-boost";

const GET_ASSOCIATED_SELLER = gql`
  query {
    getAssociatedSeller {
      data {
        email {
          address
        }
        firstName
        lastName
        phoneNumber
        isStripeSubscription
        setting {
          isReviewOrder
        }
      }
      status
      error
    }
  }
`;

export default GET_ASSOCIATED_SELLER;
