import { gql } from "apollo-boost";

const CUSTOM_DOMAIN_LIST = gql`
  query getCustomDomainList($input: GetListInput) {
    getCustomDomainList(input: $input) {
      data {
        count
        customDomainList {
          accessKey
          accessToken
          _id
          accountId
          activatedOn
          addedBy
          address
          billingDate
          brandName
          chargeId
          city
          email {
            address
            verified
          }
          domain
          createdAt
          isDomainActive
          shop
        }
      }
      status
      error
    }
  }
`;
export default CUSTOM_DOMAIN_LIST;
