import { gql } from "apollo-boost";

const GET_USER = gql`
  query getUser($input: GetUser) {
    getUser(input: $input) {
      data {
        brandName
        commission
        commissionType
        logo
      }
      status
      error
    }
  }
`;

export default GET_USER;
