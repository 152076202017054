import React, { useContext, useEffect } from "react";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

const BillingFailure = () => {
  const { history, currentUserRefetch } = useContext(PrivateContext);

  useEffect(() => {
    currentUserRefetch();
    history.push("/");
  }, []);

  return <Spinner isFullPage={true} />;
};

export default withErrorBoundary(BillingFailure);
