import { gql } from "apollo-boost";

const TEMP_PRODUCT_SUBSCRIPTION = gql`
  subscription {
    tempProductList {
      data {
        operationType
        tempProduct {
          _id
          id
          approved
          status
          title
          vendor
          vendorId
          updatedAt
          images {
            imageUrl
            url
          }
          published
        }
      }
      error
      status
    }
  }
`;

export default TEMP_PRODUCT_SUBSCRIPTION;
