import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Card, Layout, ChoiceList, Tag } from "@shopify/polaris";

// import hoc
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";

// import form lib
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// layout component
import { SingleColumnLayout } from "layout/private/components";

// lib components
import { ResourceList, Banner } from "lib/components";

// report components
import { QuickAction, QuickReport } from "app/reports";
import MoreFilters, { Filter } from "app/productLake/modules/generic/moreFilters/moreFilter";

// import GQL
import { GET_PRODUCT_LAKE_LIST } from "app/productLake/apollo/queries";
import { BULK_PRODUCT_LAKE } from "app/productLake/apollo/mutations";
import productListCMS from "app/productLake/modules/generic/feature/list/cms/productListCMS";

// import subFeatures.
import { /* RejectProduct */ DeleteProduct, ProductListView } from "app/productLake/modules/generic/feature/list";
import PRODUCT_LAKE_LIST from "app/productLake/apollo/subscriptions/productLakeList";
import { SyncProduct } from "./subFeature";

const OperatorProductList = () => {
  const { cms, history, currentUser } = useContext(PrivateContext);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);
  const [selected] = useState(parseInt(prevAppliedFilter.tab, 10) || 0);
  const selectedSort = prevAppliedFilter.sort_name && `${prevAppliedFilter.sort_name}_${prevAppliedFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [productList, setProductList] = useState(false);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevAppliedFilter.perPage, 10) || 10);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(prevAppliedFilter.page, 10) || 1);
  const [queryValue, setQueryValue] = useState(prevAppliedFilter.search || null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [singleId, setSingleId] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(prevAppliedFilter.list_filter || "");
  const [taggedWith, setTaggedWith] = useState(prevAppliedFilter.list_search || "");
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const { filterStatus, tabs, sortOptions, message, resourceName } = productListCMS(cms);
  const [deleteSelected, setDeleteSelected] = useState([constant.DELETE_SHOPIFY]);

  const paramFilter = (prevAppliedFilter.filter && prevAppliedFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const { ecommercePlatform = "" } = currentUser;
  const { ALL } = constant;

  const isSeller = baseHelper.isSeller(currentUser);
  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);

  const [dataToFetch, setDataToFetch] = useState({
    search: prevAppliedFilter.search,
    list_filter: prevAppliedFilter.list_filter,
    list_search: prevAppliedFilter.list_search,
    filter: prevAppliedFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevAppliedFilter.sort_name || constant.UPDATE,
    sort_order: prevAppliedFilter.sort_order || constant.DESC,
  });
  // eslint-disable-next-line no-unused-vars
  const [banner, setBanner] = useState({
    isOpen: true,
    title: message.defaultBanner.title,
    status: "info",
  });
  const [resBanner, setResBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const inputData = {
    search: prevAppliedFilter.search,
    list_filter: prevAppliedFilter.list_filter,
    list_search: prevAppliedFilter.list_search,
    filter: prevAppliedFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevAppliedFilter.sort_name || constant.UPDATE,
    sort_order: prevAppliedFilter.sort_order || constant.DESC,
  };
  // GQL:Operations
  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LAKE_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT_LAKE, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LAKE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  useEffect(() => {
    if (message.defaultBanner.title && defaultBanner) {
      setBanner((preState) => ({
        ...preState,
        title: message.defaultBanner.title,
      }));
      setDefaultBanner(false);
    }
  }, [defaultBanner, message]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };

  const bulkAction = async (eventKey, isDeleteFromStore = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_LAKE_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_LAKE_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      // all: totalProductCount === selectedItems.length,
      isDeleteFromStore,
      reason,
    };
    if (id) {
      formData.ids = [id];
      // formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }
    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setSelectedItems([]);
        setDeleteSelected([constant.DELETE_SHOPIFY]);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT_LAKE);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT_LAKE);
          if (responseData) {
            const title = cms("common.message.success.background");
            setResBanner({ isOpen: true, title, status: constant.SUCCESS });
          }
          if (responseDataError) {
            setResBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setResBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  // const handleTabChange = (selectedTabIndex) => {
  //   const tabName = tabs[selectedTabIndex].value;
  //   setSelected(selectedTabIndex);
  //   setCurrentPage(1);
  //   baseHelper.setUrl(history, { filter: tabName, tab: selectedTabIndex, page: 1 });
  //   const filterApplied = baseHelper.queryParamsFromLocation(history);
  //   setDataToFetch({ ...dataToFetch, ...filterApplied });
  // };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      setSortValue(selectedItem);
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      const filterApplied = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filterApplied });
    }
  };

  useEffect(() => {
    setSelectedItems([]);
    const productListResponseResponseData = baseHelper.getResponseData(
      dataProductList,
      constant.gql.GET_PRODUCT_LAKE_LIST
    );
    const productListResponseResponseError = baseHelper.getResponseError(
      dataProductList,
      constant.gql.GET_PRODUCT_LAKE_LIST
    );
    if (productListResponseResponseData) {
      setProductList(productListResponseResponseData.tempProductList);
      setTotalProductCount(productListResponseResponseData.count);
    }
    if (productListResponseResponseError) {
      setListLoading(false);
      setResBanner({ status: constant.CRITICAL, title: productListResponseResponseError, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setResBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorProductList), isOpen: true });
    }
  }, [errorProductList]);

  // Subscription for operator list Page.
  useEffect(() => {
    subscribeToMore({
      document: PRODUCT_LAKE_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handlePage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
    baseHelper.setUrl(history, { search: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleQueryValueRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleFilterByStatusRemove = () => {
    setFilterByStatus(null);
    baseHelper.setUrl(history, { filter: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleClearAll = () => {
    handleQueryValueRemove();
    handleTaggedWithRemove();
    handleSelectedFilterRemove();
    handleFilterByStatusRemove();
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value === constant.TITLE ? "titleName" : value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }
  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: handleFilterByStatusRemove,
    });
  }
  const appliedFilters = appliedFilterArrObj;

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: ALL, page: 1 });
    }
  };

  const filters = [
    {
      key: "selectedFilter",
      // label: cms("label.filterBy"),
      label: "Filter By",
      filter: (
        <Filter
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          productList={productList}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: "Filter By Status",
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: constant.TAGGED_WITH,
      // label: cms("label.filter"),
      label: "Advance Filter",
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        cms={cms}
        item={item}
        listLoading={listLoading}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        loadingBulkProduct={loadingBulkProduct}
        handleDeleteModal={handleDeleteModal}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setResBanner}
        active={active}
        setActive={setActive}
        isBigCommerceUser={isBigCommerceUser}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: cms("label.action"),
    },
  ];

  const bulkActions = [
    {
      content: cms("label.push"),
      onAction: () => bulkAction(constant.APPROVE),
      disabled: tabs[selected].value === constant.REVIEWED.toLowerCase(),
    },
    {
      content: cms("label.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
  ];

  const linkData = [
    {
      action: () => history.push("/product-lake/add"),
      // caption: cms("section.caption.add"),
      primary: true,
      id: "addProduct",
      label: cms("label.add"),
    },
    {
      action: () => setOpenSyncModal(true),
      // caption: cms("section.caption.import"),
      id: "importProduct",
      label: cms("label.import", { item: ecommercePlatform }),
    },
  ];

  const getComponent = () => {
    return (
      <>
        {resBanner.isOpen && (
          <>
            <Banner
              isOpen={resBanner.isOpen}
              title={resBanner.title}
              status={resBanner.status}
              onDismiss={() => {
                setResBanner({ isOpen: false, title: "", status: "" });
              }}
            />
            <br />
          </>
        )}
        <Banner isOpen title={cms("label.title", { item: ecommercePlatform })} status={constant.INFO} />
        <br />
        {isBigCommerceUser && (
          <>
            <Banner isOpen title={cms("label.requiredField")} status={constant.INFO} />
            <br />
          </>
        )}
        <QuickReport />
        <QuickAction link={linkData} />
        <Layout>
          <SyncProduct
            cms={cms}
            openSyncModal={openSyncModal}
            setResBanner={setResBanner}
            setOpenSyncModal={setOpenSyncModal}
            dataToFetch={inputData}
          />
          <DeleteProduct
            cms={cms}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            selectedItems={selectedItems}
            isSeller
            deleteSelected={deleteSelected}
          />
          <Layout.Section>
            <Card>
              {/* <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}> */}
              <ResourceList
                resourceName={resourceName}
                items={productList || []}
                idForItem={(products) => {
                  const { providerProductId: productId, _id: id } = products;
                  return isSeller && isBigCommerceUser ? productId : id;
                }}
                selectable
                renderItem={renderItem}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                queryValue={queryValue}
                selectedItems={selectedItems}
                onSelectionChange={(selectValue) => setSelectedItems(selectValue)}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={sortFilter}
                bulkActions={bulkActions}
                promotedBulkActions={promotedBulkActions}
                loading={loadingProductList}
                filters={filters}
                appliedFilters={appliedFilters}
                page={currentPage}
                count={Number(totalProductCount)}
                perPage={selectedLimit}
                setPage={handlePage}
                setPerPage={handleSelectLimit}
              />
              {/* </Tabs> */}
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withErrorBoundary(OperatorProductList);
