import React, { useState, useContext, useEffect, useCallback } from "react";
import { Card, ChoiceList, FormLayout, TextField, Select, Tag } from "@shopify/polaris";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// lib component
import { ResourceList, Banner, Toast } from "lib/components";

// import from apollo
import { ENABLE_DISABLE_VENDOR } from "app/vendors/apollo/mutations";
import { GET_VENDOR_LIST, GET_SELLER_ASSOCIATION } from "app/vendors/apollo/queries";
import VENDOR_LIST_SUBSCRIPTION from "app/vendors/apollo/subscription";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// cms
import {
  DeleteProvider,
  DisableProvider,
  LoginProvider,
  RemoveAssocProvider,
} from "app/vendors/modules/generic/feature/list/subFeatures";

import QuickReport from "app/reports/quickReport";
import QuickAction from "app/reports/quickAction";
import cmsVendorList from "./cms/vendorListCMS";

// subFeatures
import ProviderList from "./subFeatures";

const AdminProviderList = () => {
  const { history, cms } = useContext(PrivateContext);
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const selected = parseInt(prevFilter.tab, 10) || 0;

  const [messageToast, setMessageToast] = useState("");
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const [taggedWith, setTaggedWith] = useState(prevFilter.list_search || "");
  const paramFilter = (prevFilter.filter && prevFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);

  const [assocSeller, setAssocSeller] = useState(false);
  const [seller, setSeller] = useState(false);
  const [assocVendor, setAssocVendor] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [isReasonField, setIsReasonField] = useState(false);
  const [isVerifyVendors, setIsVerifyVendors] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [removeAssocModal, setRemoveAssocModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [singleVendor, setSingleVendor] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [totalVendor, setTotalVendor] = useState(0);
  const [disableVendorId, setDisableVendorId] = useState("");
  const [selectedBrand, setSelectedBrand] = useState({});
  const [dataToFetch, setDataToFetch] = useState({
    list_search: prevFilter.list_search,
    search: prevFilter.search,
    filter: prevFilter.filter,
    list_filter: prevFilter.list_filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || constant.CREATED_AT,
    sort_order: prevFilter.sort_order || constant.DESC,
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [activePopover, setActivePopover] = useState({});
  const vendorListCMS = cmsVendorList(cms);
  const { filterOptions, filterStatus, label, message, promotedBulkActions, resourceName, sortOptions } = vendorListCMS;
  const [selectedFilter, setSelectedFilter] = useState(prevFilter.list_filter || "");
  const [selectedValue, setSelectedValue] = useState([label.doNothing]);
  const [popoverDisable, setPopoverDisable] = useState(false);

  const inputData = {
    list_search: taggedWith,
    search: dataToFetch.search,
    filter: filterByStatus && filterByStatus.length ? filterByStatus : dataToFetch.filter,
    list_filter: selectedFilter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  // useQuery Calls to fetch data.
  const {
    error: errorVendorList,
    loading: loadingVendorList,
    data: dataVendorList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_VENDOR_LIST, {
    variables: {
      input: inputData,
    },
  });
  // usemutation calls for enable disable
  const [enableDisableVendor] = useMutation(ENABLE_DISABLE_VENDOR);

  // view Associate Seller
  const [
    getAssocSeller,
    { data: dataAssocSeller, error: errorAssocSeller, loading: loadingAssocSeller },
  ] = useLazyQuery(GET_SELLER_ASSOCIATION);

  const vendorListResponseData = baseHelper.getResponseData(dataVendorList, constant.gql.GET_VENDOR_LIST);
  const vendorListErrorData = baseHelper.getResponseError(dataVendorList, constant.gql.GET_VENDOR_LIST);
  // assoc provider
  const assocProviderResponseData = baseHelper.getResponseData(dataAssocSeller, constant.gql.GET_SELLER_ASSOCIATION);
  const assocProviderErrorData = baseHelper.getResponseError(dataAssocSeller, constant.gql.GET_SELLER_ASSOCIATION);

  useEffect(() => {
    if (assocProviderResponseData) {
      if (disableVendorId) {
        const { _id: sellerId = "" } = assocProviderResponseData || {};
        setSeller(sellerId);
        setDisableModal(true);
      } else {
        setAssocSeller(assocProviderResponseData);
        setRemoveAssocModal(true);
      }
    }

    if (assocProviderErrorData) {
      setBanner({ status: constant.CRITICAL, title: message.noSellerFound, isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assocProviderResponseData, assocProviderErrorData, message.noSellerFound]);

  useEffect(() => {
    if (disableVendorId) {
      getAssocSeller({ variables: { input: { vendorId: disableVendorId } } });
    }
  }, [disableVendorId, getAssocSeller]);

  useEffect(() => {
    setSelectedItems([]);
    if (vendorListResponseData) {
      const { vendors: vendorListData = [], count: totalCount = 0 } = vendorListResponseData;
      setVendorList(vendorListData);
      setTotalVendor(totalCount);
    }
  }, [vendorListResponseData]);

  useEffect(() => {
    if (vendorListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorListErrorData, isOpen: true });
    }
    if (errorVendorList || errorAssocSeller) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorVendorList || errorAssocSeller),
        isOpen: true,
      });
    }
  }, [vendorListErrorData, errorVendorList, errorAssocSeller]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  useEffect(() => {
    setTimeout(() => {
      setMessageToast("");
    }, 5000);
  }, [messageToast]);

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  // function for remove values of more filter.
  const handleTaggedWithRemove = (isClearAll = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_search: "", page: 1 };

    if (isClearAll) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }

    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
      setTaggedWith("");
      setSelectedFilter("");
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  // end

  const tagOption = {
    pending: cms("label.providerList.pendingApproval"),
    approve: cms("label.providerList.approved"),
    inReview: cms("label.providerList.review"),
    reject: cms("label.providerList.rejected"),
    onHold: cms("label.providerList.onHold"),
    onHoliday: cms("label.providerList.onHoliday"),
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
  };
  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {tagOption[option]}
        </Tag>
      ));
  }
  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };
  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // function for delete vendor and verify vendor.
  const deleteBulkVendor = async (isDelete = false, isSingle = false, id = "") => {
    if (isDelete && !isSingle) {
      setIsVerifyVendors(false);
      setSingleVendor([]);
      setIsReasonField(true);
      setDeleteModal(true);
    } else if (isSingle && id) {
      setIsVerifyVendors(false);
      setSingleVendor([id]);
      setIsReasonField(false);
      setDeleteModal(true);
    } else {
      setIsVerifyVendors(true);
    }
  };

  // Subscription for vendorList Page.
  useEffect(() => {
    subscribeToMore({
      document: VENDOR_LIST_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newVendor = subscriptionData.data.getVendorList.data.vendor;
        const vendorId = newVendor._id || "";
        const prevVendors = prev.getVendorList.data;
        const updatedVendors = prevVendors.vendors.filter((vendor) => vendor._id !== vendorId);
        const vendorListData = [newVendor, ...updatedVendors];
        const totalCount = vendorListData.length;
        return [setVendorList(vendorListData), setTotalVendor(totalCount)];
      },
    });
  }, [subscribeToMore]);

  // login vendor function.
  const loginVendor = (id, brand, role) => {
    setLoginModal(true);
    setSelectedBrand({ id, brand, role });
  };

  const assocProvider = (id) => {
    setAssocVendor(id);
    getAssocSeller({ variables: { input: { vendorId: id } } });
  };

  // enableDisable vendor function.
  const handleEnableDisable = async (id, status) => {
    try {
      if (status) {
        const response = await enableDisableVendor({
          variables: { input: { vendorId: id, isRejected: false } },
        });
        const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_VENDOR_AVAILABILITY);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }
      } else {
        setDisableVendorId(id);
        setSelectedValue([label.doNothing]);
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const renderItem = (item, _, index) => {
    return (
      <ProviderList
        item={item}
        keyIndex={index}
        onAssocProvider={(vendorId) => assocProvider(vendorId)}
        onDeleteVendor={(vendorId) => deleteBulkVendor(false, true, vendorId)}
        onLoginVendor={(vendorId, brandName, role) => loginVendor(vendorId, brandName, role)}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        handleEnableDisable={handleEnableDisable}
        setMessageToast={setMessageToast}
        popoverDisable={popoverDisable}
      />
    );
  };

  const bulkActions = [
    {
      content: label.verify_selected,
      onAction: () => deleteBulkVendor(false),
    },
    {
      content: label.delete_selected,
      destructive: true,
      onAction: () => deleteBulkVendor(true),
    },
  ];

  const bulkActionsApproved = [
    {
      content: label.delete_selected,
      onAction: () => deleteBulkVendor(true),
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.BRAND_FILTER:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.providerList.${value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.BRAND_FILTER,
      label: disambiguateLabel(constant.BRAND_FILTER, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const appliedFilters = appliedFilterArrObj;

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const customFilter = (
    <FormLayout>
      <Select
        label={label.show_all_vendor}
        value={selectedFilter || ""}
        onChange={handleSelectChange}
        placeholder={cms("label.providerList.select")}
        options={filterOptions}
      />
      {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
        null}
    </FormLayout>
  );

  const filters = [
    {
      key: constant.BRAND_FILTER,
      label: label.filter_by,
      filter: customFilter,
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <QuickReport />
      <QuickAction />
      <Card>
        <ResourceList
          resourceName={resourceName}
          items={vendorList && vendorList.length > 0 ? vendorList : []}
          idForItem={(vendors) => {
            const { _id: id } = vendors;
            return id;
          }}
          renderItem={renderItem}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          bulkActions={selected === 2 ? bulkActionsApproved : bulkActions}
          sortValue={sortValue}
          sortOptions={sortOptions}
          onSortChange={sortFilter}
          filters={filters}
          loading={loadingVendorList || loadingAssocSeller}
          onQueryChange={handleQueryValueChange}
          onQueryClear={handleQueryValueRemove}
          handleClearAll={handleClearAll}
          queryValue={queryValue}
          appliedFilters={appliedFilters}
          page={currentPage}
          count={Number(totalVendor)}
          perPage={selectedLimit}
          setPage={handlePerPage}
          setPopoverDisable={setPopoverDisable}
          setPerPage={handleSelectLimit}
        />
      </Card>
      <DeleteProvider
        openModal={deleteModal}
        handleModalClose={() => setDeleteModal(false)}
        isReasonField={isReasonField}
        dataToFetch={inputData}
        setDeleteModal={setDeleteModal}
        setBanner={setBanner}
        selectedItems={selectedItems}
        singleVendor={singleVendor}
        isVerifyVendors={isVerifyVendors}
        setIsVerifyVendors={setIsVerifyVendors}
      />
      <LoginProvider
        loginModalOpen={loginModal}
        closeLoginModal={() => {
          setSelectedBrand({});
          setLoginModal(false);
        }}
        brandName={selectedBrand}
        setBanner={setBanner}
        setLoginModal={setLoginModal}
        setSelectedBrand={setSelectedBrand}
        isAdvanceVendor={false}
      />
      <DisableProvider
        closeDisableModal={() => {
          setDisableVendorId("");
          setDisableModal(false);
        }}
        seller={seller}
        refetch={refetch}
        setBanner={setBanner}
        disableModalOpen={disableModal}
        disableVendorId={disableVendorId}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setMessageToast={setMessageToast}
      />
      <RemoveAssocProvider
        setRemoveAssocModal={setRemoveAssocModal}
        removeAssocModal={removeAssocModal}
        setBanner={setBanner}
        assocSeller={assocSeller || []}
        assocVendor={assocVendor}
      />
      <Toast message={messageToast} timeout={5000} />
    </>
  );
};

export default withErrorBoundary(AdminProviderList);
