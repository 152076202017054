import React, { useContext, useState } from "react";
import { PrivateContext } from "lib/context";
import { useMutation } from "react-apollo";
import { UPDATE_PLAN_DETAILS } from "app/plans/apollo/mutations";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { withErrorBoundary } from "lib/hoc";
import OperatorPlan from "app/plans/modules/operator/features/generic/plan/newPlan";

const UpdatePlanChargebee = () => {
  const { cms, currentUser, currentUserRefetch } = useContext(PrivateContext);
  const [updatePlan, { loading }] = useMutation(UPDATE_PLAN_DETAILS);
  const [error, setError] = useState("");

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  // const  = currentUser && currentUser.plan && currentUser.plan.code;
  const { plan = {} } = currentUser || {};

  const { code: currentPlan, chargeId, isChargebee = false } = plan || {};

  let dataToSend = {};

  if(chargeId && isChargebee) {
    dataToSend.chargeId = chargeId;
  }

  const onSubmit = (plan) => {
    dataToSend.code = plan.code;

    updatePlan({
      variables: {
        input: dataToSend,
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PLAN_DETAILS);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PLAN_DETAILS);

        if (responseData) {
          setBanner({
            isOpen: true,
            status: constant.SUCCESS,
            title: cms("message.success.update"),
          });

          setTimeout(() => currentUserRefetch(), 2000);
        } else if (responseError) {
          setError(responseError);
        }
      })
      .catch((exception) => {
        const parsedError = errorHelper.parse(exception);
        setError(parsedError);
      });
  };

  return (
    <OperatorPlan
      onSubmit={onSubmit}
      submitLoading={loading}
      currentPlan={currentPlan}
      error={error}
      banner={banner}
      setBanner={setBanner}
    />
  );
};

export default withErrorBoundary(UpdatePlanChargebee);
