import React from "react";

import { ViewEditActivity } from "app/userManagement/modules/generic/index";

const OperatorViewEditActivity = () => {
  return (
    <>
      <ViewEditActivity />
    </>
  );
};

export default OperatorViewEditActivity;
