import React, { useContext } from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";

// import components
import ImageComponent from "lib/components/dropZone/dropZone";

// imports HOC
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const CustomFieldSection = (props) => {
  const { cms } = useContext(PrivateContext);
  const { data, filelist, handleChange, handleImageRemove, lastUpdated, existingImages /* learnMore */ } = props;
  const getFields = () => {
    return data.map(({ label, placeholder, inputType, value, key }, idx) => {
      if (inputType === constant.TEXT_FIELD) {
        return (
          <>
            <TextField
              label={label}
              placeholder={placeholder}
              value={value}
              onChange={(updatedValue) => handleChange(key, updatedValue, idx)}
            />
            <br />
          </>
        );
      }
      if (inputType === constant.IMAGE) {
        const existingImagesData = existingImages.map((image, id) => {
          return {
            position: id,
            url: image,
          };
        });
        return (
          <>
            <span className="overflow">{label}</span>
            <ImageComponent
              onAdd={(updatedValue) => handleChange(key, updatedValue, idx)}
              onRemove={(updatedValue) => handleImageRemove(updatedValue)}
              allowMultiple
              size={10}
              existingImageList={existingImagesData || []}
              fileList={filelist}
              removeExistingImage={(updatedValue) => handleImageRemove(updatedValue)}
            />
            <br />
          </>
        );
      }
      if (inputType === constant.FILE) {
        return (
          <>
            <TextField
              label={label}
              placeholder={placeholder}
              value={value}
              onChange={(updatedValue) => handleChange(key, updatedValue, idx)}
            />
            <br />
          </>
        );
      }
    });
  };
  return (
    <Layout.AnnotatedSection
      title={cms("section.vendorCustom.title")}
      description={cms("section.vendorCustom.description")}
    >
      <Card
        title={[
          cms("section.vendorCustom.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore(cms("section.vendorCustom.title"), "TODO:");
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <FormLayout>
            {/* <TextContainer>{cms("section.vendorCustom.description")}</TextContainer> */}
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

CustomFieldSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  existingImages: PropTypes.shape(PropTypes.object),
  handleChange: PropTypes.func.isRequired,
  handleImageRemove: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
  // learnMore: PropTypes.func.isRequired,
};

CustomFieldSection.defaultProps = {
  existingImages: {},
  lastUpdated: "",
};
// export default withFeature(CustomFieldSection, { feature: "advanceVendorCustomProfile" });
export default CustomFieldSection;
