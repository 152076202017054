import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Caption,
  Modal,
  Link,
  Stack,
  ResourceItem,
  ResourceList,
  SkeletonThumbnail,
  Thumbnail,
  TextStyle,
} from "@shopify/polaris";
import { useQuery } from "react-apollo";

import LazyLoad from "react-lazyload";

// import helper
import { baseHelper, imageHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";

import { SkeletonList } from "lib/components";

// import gql
import { GET_VENDOR_ORDER_LINE } from "app/payments/apollo/queries";

import listData from "app/orders/modules/operator/features/list/orderListData";

const InvoiceModal = (props) => {
  const { item: lineItem, setActive, active, setBanner } = props;
  const { images = [], loading, moneyFormat, orderId, orderNumber, title, vendorId, vendor: vendorName } = lineItem;
  const handleChange = useCallback(() => setActive(!active), [active]);
  const { cms, history, currentUser } = useContext(PrivateContext);
  const { gql } = constant;
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const size = constant.LARGE;
  const isSeller = baseHelper.isSeller(currentUser);
  const { mainLink } = listData(cms);

  const inputData = {
    orderId,
  };
  if (isSeller) {
    inputData.vendorId = vendorId;
  }
  const { data: orderLinesData, loading: orderLinesLoading, error } = useQuery(GET_VENDOR_ORDER_LINE, {
    variables: {
      input: inputData,
    },
  });

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
  }, [error, setBanner]);

  const orderLineError = baseHelper.getResponseError(orderLinesData, gql.GET_VENDOR_ORDER_LINE);
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: orderLineError });
    }
  }, [orderLineError, setBanner]);
  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, gql.GET_VENDOR_ORDER_LINE);

  useEffect(() => {
    if (responseOrderLinesData) {
      const indexedOrderLinesData = responseOrderLinesData
        .filter((orderLineItem) => orderLineItem && orderLineItem.actualQuantity)
        .map((orderLineItem, index) => ({
          ...orderLineItem,
          id: index,
        }));
      setOrderLineItems(indexedOrderLinesData);
      setSelectedItems(indexedOrderLinesData.map((item) => item.id));
    }
  }, [responseOrderLinesData]);

  const renderItem = (item) => {
    const {
      id,
      name,
      commissionSeller,
      commissionTax,
      commissionVendor,
      image,
      isOrderBasedCommission,
      price,
      priceTaxRate,
      quantity,
      shippingAmount,
      shippingTax,
    } = item;

    const totalShippingAmount = parseFloat(shippingAmount || 0) + parseFloat(shippingTax || 0);
    const totalOrderPayout = parseFloat(commissionVendor || 0);
    const getItemCommissionTax = () => {
      const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
      const totalPriceInclusiveTax = baseHelper.getPrice(price * quantity);
      const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
      const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
      const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
      const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
      return sellerTaxOnCommission;
    };

    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={size} alt={`Image of ${title}`} />;
    const skeletalThumbnail = <SkeletonThumbnail size={size} />;
    const media = (
      <LazyLoad once height={80} offset={100} placeholder={skeletalThumbnail}>
        {thumbnail}
      </LazyLoad>
    );
    return (
      <ResourceItem id={id} name={name} accessibilityLabel={`View details for ${name}`}>
        <Stack alignment={constant.LEADING}>
          <Stack.Item>{media}</Stack.Item>
          <Stack.Item fill>
            <div className="ellipsis">
              <Link removeUnderline onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                <TextStyle>{name}</TextStyle>
              </Link>
            </div>
            <Caption>{` ${cms("label.commission")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}</Caption>
            {priceTaxRate && !commissionTax && !isOrderBasedCommission ? (
              <Caption>
                {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(
                  getItemCommissionTax()
                )} (Incl.)`}
              </Caption>
            ) : (
              <Caption>
                {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(commissionTax)}`}
              </Caption>
            )}
            <Caption>
              {` ${cms("label.vendorPayout")}: ${moneyFormat} ${baseHelper.getPrice(totalOrderPayout)}`}
            </Caption>
            {!!shippingAmount && (
              <Caption>
                {`${cms("label.shipping")}: ${moneyFormat} ${baseHelper.getPrice(totalShippingAmount)}`}
              </Caption>
            )}
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const generateInvoice = () => {
    const selectedLineItems = orderLineItems.filter((item) => selectedItems.includes(item.id)).map((item) => item._id);
    window.open(`/invoice/${orderId}/${JSON.stringify(selectedLineItems)}`, "_blank");
  };
  return (
    <Modal
      open={active}
      onClose={() => {
        handleChange();
      }}
      title={`#${orderNumber}`}
      primaryAction={{
        content: cms("section.export.label.generate"),
        onAction: () => {
          generateInvoice();
        },
        disabled: orderLinesLoading || selectedItems.length < 1,
        loading,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: handleChange,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          {isSeller && (
            <Stack.Item fill>
              <Caption>
                {`${cms("label.selectVendor")}: `}
                {
                  <Link url={`/providers/view/${vendorId}`} removeUnderline>
                    {vendorName}
                  </Link>
                }
              </Caption>
            </Stack.Item>
          )}
          <Stack.Item>
            {orderLinesLoading && <SkeletonList />}
            {!orderLinesLoading && (
              <ResourceList
                items={orderLineItems}
                selectedItems={selectedItems}
                onSelectionChange={setSelectedItems}
                selectable
                renderItem={renderItem}
              />
            )}
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default withErrorBoundary(InvoiceModal);
