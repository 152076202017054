import React, { useState, useEffect, useContext } from "react";
import { Card, Layout, Tabs, Banner } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// import helpers
import baseHelper from "lib/helpers/base";
import { ResourceList } from "lib/components";
import { PrivateContext } from "lib/context/privateContext";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { errorHelper } from "lib/helpers";

// import gql
import GET_AUTOMATIC_PAYOUTS from "app/userManagement/apollo/queries/getPayouts";

// import cms
import paymentListData from "./cms/paymentListData";

// import subFeature
import List from "./list/list";

const AutomaticPayouts = () => {
  const { history, cms } = useContext(PrivateContext);
  const resourceData = paymentListData(cms);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [getPayouts, setPayouts] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [tabSelected, setTabSelected] = useState(queryParam.tab || 0);
  const isPayoutsPage = history.location.pathname.includes("/payouts");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    message: "",
  });
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const queryObject = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data, loading: payoutsLoading, error } = useQuery(GET_AUTOMATIC_PAYOUTS, {
    variables: { input: queryObject },
  });

  useEffect(() => {
    if (!payoutsLoading) {
      setLoading(false);
    }
  }, [payoutsLoading]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
      return;
    }
    if (data) {
      const responseData = baseHelper.getResponseData(data, constant.gql.GET_PAYMENT_TRANS_LIST);
      const responseError = baseHelper.getResponseError(data, constant.gql.GET_PAYMENT_TRANS_LIST);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const { count: paymentTransCount, paymentTransList = [] } = responseData;
      setPayouts([...paymentTransList]);
      setCount(paymentTransCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, setBanner, errorHelper]);

  const onHandleChange = (selectedTabIndex) => {
    setTabSelected(selectedTabIndex, page);
    if (
      resourceData.tabsData &&
      resourceData.tabsData[selectedTabIndex] &&
      resourceData.tabsData[selectedTabIndex].value
    ) {
      baseHelper.setUrl(history, {
        tab: selectedTabIndex,
        filter: resourceData.tabsData[selectedTabIndex].value,
        page: 1,
      });
      setPage(1);
    }
  };
  const handleQueryClear = () => {
    setSearch(null);
    baseHelper.setUrl(history, { search: "" });
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const renderTable = (items) => {
    if (isPayoutsPage) {
      return <List item={items} />;
    }
    return null;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            status={banner.status}
            onDismiss={() => setBanner({ ...banner, isOpen: false })}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Card>
          <Tabs tabs={resourceData.tabsData} selected={parseInt(tabSelected, 10)} onSelect={onHandleChange} />
          <ResourceList
            items={getPayouts}
            renderItem={renderTable}
            queryValue={search}
            loading={loading || payoutsLoading}
            onQueryClear={handleQueryClear}
            onQueryChange={handleSearchChange}
            sortValue={sortValue}
            sortOptions={resourceData.sortOptions}
            onSortChange={(selected) => {
              handleSort(selected);
            }}
            resourceName={resourceData.resourceName}
            count={count}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </Card>
      </Layout.Section>
    </>
  );
};
export default withErrorBoundary(AutomaticPayouts);
