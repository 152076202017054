import { gql } from "apollo-boost";

const UPDATE_VARIANT = gql`
  mutation editVariant($input: EditVariant) {
    editVariant(input: $input) {
      data {
        id
        position
      }
      status
      error
    }
  }
`;

export default UPDATE_VARIANT;
