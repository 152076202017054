import React from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import constant from "lib/constant/constant";

const Shipping = (props) => {
  const { acceptOnlyValidInput, data, onChange, cms } = props;
  const { isPhysicalProduct = false, weight = "", weightUnit } = data;
  const { OPTIONS } = constant;

  const handleShipping = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const selectWeightUnit = () => {
    return (
      <Select
        id="selectWeight"
        value={weightUnit || "lb"}
        label={cms("section.shipping.label.unit")}
        labelHidden
        onChange={(value) => handleShipping(constant.WEIGHT_UNIT, value)}
        options={OPTIONS}
      />
    );
  };

  return (
    <Card title={cms("section.shipping.title")}>
      <Card.Section>
        <Checkbox
          label={cms("section.shipping.label.physical")}
          checked={isPhysicalProduct}
          onChange={(value) => handleShipping(constant.IS_PHYSICAL_PRODUCT, value)}
        />
      </Card.Section>
      {isPhysicalProduct && (
        <Card.Section title={cms("section.shipping.label.weight").toUpperCase()}>
          <p>{cms("section.shipping.label.rate")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.shipping.label.weight")}
            value={weight}
            placeholder={constant.ZERO_PRICE}
            onChange={(value) => handleShipping(constant.WEIGHT, acceptOnlyValidInput(value, weight))}
            connectedRight={selectWeightUnit()}
          />
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  acceptOnlyValidInput: PropTypes.func.isRequired,
  cms: PropTypes.func.isRequired,
  data: PropTypes.shape({
    isPhysicalProduct: PropTypes.bool.isRequired,
    weight: PropTypes.string.isRequired,
    weightUnit: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Shipping;
