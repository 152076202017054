import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, passwordRegex, phoneNumberRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup
      .string()
      .required(cms("common.message.error.passwordIsRequired"))
      .max(20, cms("common.message.error.passwordLength"))
      .matches(passwordRegex, cms("common.message.error.enterValidPassword")),
  });

const confirmationPasswordSchema = (cms) =>
  yup.object().shape({
    confirmationPassword: yup
      .string()
      .required(cms("common.message.error.confirmPasswordIsRequired"))
      .oneOf([yup.ref("password"), null], cms("common.message.error.enterSamePassword")),
  });

const acceptPolicySchema = (cms) =>
  yup.object().shape({
    acceptPolicy: yup.boolean().oneOf([true], cms("common.message.error.acceptTerms&Conditions")),
  });

const phoneNumberSchema = (cms) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.phoneNumberIsRequired"))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .test("len", cms("common.message.error.phoneNumberLength"), (val) => val.length === 10)
      .nullable(),
  });

const otpSchema = (cms) =>
  yup.object().shape({
    otp: yup
      .string()
      .required(cms("common.message.error.otpIsRequired"))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .min(5, cms("common.message.error.minOtp"))
      .max(6, cms("common.message.error.maxOtp"))
      .nullable(),
  });

export { emailSchema, passwordSchema, confirmationPasswordSchema, acceptPolicySchema, phoneNumberSchema, otpSchema };
