import {
  InviteMinor,
  ActivitiesMajor,
  AddProductMajor,
  CustomerPlusMajor,
  ChatMajor,
  DraftOrdersMajor,
  PaymentsMajor,
  ToolsMajor,
} from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.activity"),
        label: cms("common.actions.dashboard.label.activity"),
        link: "/activity",
        icon: ActivitiesMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.addProducts"),
        label: cms("common.actions.dashboard.label.addProducts"),
        link: "/products/add",
        icon: AddProductMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.addVendors"),
        label: cms("common.actions.dashboard.label.addVendors"),
        link: "/providers/add",
        icon: CustomerPlusMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.contactUs"),
        label: cms("common.actions.dashboard.label.contactUs"),
        link: "/contact-us",
        icon: ChatMajor,
      },
    ],
    "/activity": [
      {
        caption: cms("common.actions.activity.caption.bulk"),
        label: cms("common.actions.activity.label.bulk"),
        link: "activity/bulk",
        icon: ActivitiesMajor,
      },
    ],
    "/invitation": [
      {
        caption: cms("common.actions.invitation.caption.invite"),
        label: cms("common.actions.invitation.label.invite"),
        link: "/providers/add",
        icon: InviteMinor,
      },
    ],
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.settings"),
        label: cms("common.actions.orders.caption.label"),
        link: "/setting/order",
        icon: DraftOrdersMajor,
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.autoPayouts"),
        label: cms("common.actions.payments.label.autoPayouts"),
        link: "/payouts",
        icon: PaymentsMajor,
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.addProductStore"),
        label: cms("common.actions.products.label.addProductStore"),
        link: "/products/add",
        icon: AddProductMajor,
      },
      {
        caption: cms("common.actions.products.caption.review"),
        label: cms("common.actions.products.label.review"),
        link: "/setting/product",
        icon: ToolsMajor,
      },
    ],
    "/providers": [
      {
        caption: cms("common.actions.vendors.caption.add"),
        label: cms("common.actions.vendors.label.add"),
        link: "/providers/add",
        icon: "",
      },
      {
        caption: cms("common.actions.vendors.caption.vendorPage"),
        label: cms("common.actions.vendors.label.vendorPage"),
        link: "/advance-providers",
        icon: "",
      },
    ],
  };
};

export default config;
