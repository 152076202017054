import { gql } from "apollo-boost";

const UPDATE_TAG_SETTING = gql`
  mutation updateTagSetting($input: TagSetting) {
    updateTagSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_TAG_SETTING;
