import React from "react";

import ProductExportSetting from "app/setup/modules/generic/exportSetting/product";

import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";

const OperatorProductExportSetting = () => {
  return <ProductExportSetting />;
};
export default withFeature(withErrorBoundary(OperatorProductExportSetting), {
  feature: constant.PRODUCT_EXPORT_SETTING,
});
