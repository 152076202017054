import { gql } from "apollo-boost";

const CREATE_ASSOCIATION = gql`
  mutation createAssociation($input: AssociationData) {
    createAssociation(input: $input) {
      status
      data
      error
    }
  }
`;

export default CREATE_ASSOCIATION;
