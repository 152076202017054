import { gql } from "apollo-boost";

const GET_TAX_RATE_SETTING = gql`
  query {
    getTaxRateSetting {
      data {
        taxes {
          taxClass
          vendorId
          rate
        }
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_TAX_RATE_SETTING;
