import { gql } from "apollo-boost";

const UPLOAD_IMAGE = gql`
  mutation UploadImage($input: UploadImage) {
    uploadImage(input: $input) {
      data {
        imageId
        imageUrl
      }
      error
      status
    }
  }
`;

export default UPLOAD_IMAGE;
