import React, { useContext } from "react";

import { Chat } from "app/userManagement/modules/generic";
import { withErrorBoundary } from "lib/hoc";

import { PrivateContext } from "lib/context";

const OperatorChat = () => {
  const { chatSetting, history } = useContext(PrivateContext);
  const { isVendor } = chatSetting;

  if (!isVendor) {
    history.push("/");
  }

  return <Chat />;
};

export default withErrorBoundary(OperatorChat);
