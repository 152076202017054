import { gql } from "apollo-boost";

const GET_GEOLOCATION_SETTING = gql`
  query {
    getGeolocationSetting {
      data {
        isAllowed
        locations
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_GEOLOCATION_SETTING;
