import React, { useContext, useEffect, useState } from "react";
import { Button, Caption, Card, Checkbox, Select, Stack, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import Helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { Banner, Sheet, Toast } from "lib/components";

// import mutations
import { UPDATE_PRODUCT_LAKE_FULFILLMENT } from "app/productLake/apollo/mutations";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import prop Type
import { tabProp } from "app/productLake/modules/generic/propTypes";

// import context
import { ProductContext } from "app/productLake/modules/generic/context";
import { withErrorBoundary } from "lib/hoc";

const { SELECT_WEIGHT = "" } = constant;

const EditFulfillment = (props) => {
  const { tabObj = {} } = props;
  const { handleTabChange, isVariable, isVariant, setBanner, setDescription, setTitle, productValue } = useContext(
    ProductContext
  );
  const { islastTab = false } = tabObj || {};
  const [value, setValue] = useState(productValue);
  const { cms, history, match, currentUser } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { CRITICAL, gql, weightValue } = constant;
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const fieldData = [constant.IS_SHIPPING];
  setDescription(cms("label.editFulfillment"));
  setTitle(cms("label.title.fulfillment"));
  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
    });
  };

  const acceptOnlyValidInput = (val, measurementValue) => {
    return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && measurementValue);
  };

  useEffect(() => {
    if (productValue) {
      const { weight } = productValue;
      if (!weight) {
        setValue({
          weight,
        });
      }
      if (weight) {
        setValue({ weight, isShipping: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productValue]);

  const [addProductFulfillment, { loading: addFulfillmentLoading }] = useMutation(UPDATE_PRODUCT_LAKE_FULFILLMENT);

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const onFormSubmit = async () => {
    const formValues = {
      productId: match.params.id,
      weight: parseFloat(value.weight),
    };

    if (!disabledButton) {
      await addProductFulfillment({ variables: { input: formValues } })
        .then((res) => {
          const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_LAKE_FULFILLMENT);
          const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_LAKE_FULFILLMENT);
          if (responseError) {
            setBanner({ isOpen: true, status: CRITICAL, title: responseError });
          }
          if (responseData) {
            setMessage(cms("message.success.fulfillment"));
            setBanner({ isOpen: false, status: "", title: "" });
            setTimeout(() => {
              if (islastTab) {
                history.push("/product-lake");
                return;
              }
              handleTabChange(5);
            }, 1500);
          }
        })
        .catch((exception) => {
          setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
        });
    }
    if (disabledButton) {
      setMessage(cms("message.success.fulfillment"));
      setTimeout(() => {
        history.push("/product-lake");
      }, 1500);
    }
  };
  const selectWeight = (
    <Select
      id="weightUnit"
      value={weightValue.KG}
      label={cms("label.unit")}
      onChange={(val) => handleChange(SELECT_WEIGHT, val)}
      placeholder={cms("placeholder.unit")}
      labelHidden
      options={[weightValue.KG]}
    />
  );

  const isNew = value && value.status && value.status.toLowerCase() === constant.NEW;

  return (
    <>
      <Card
        title={[
          cms("label.shipping"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productshipping"
      >
        <Card.Section>
          <TextContainer>{cms("label.caption.weight")}</TextContainer>
          <br />
          <Checkbox
            id="shippingCheckbox"
            label={`${cms("label.physical")}`}
            checked={value.isShipping || ""}
            onChange={(val) => {
              handleChange(constant.IS_SHIPPING, val);
              setDisabledButton(false);
            }}
          />
          {!value.isShipping && (
            <div>
              <br />
              <Banner isOpen status={constant.INFO} isScrollTop={false}>
                <p>{cms("label.uncheck")}</p>
              </Banner>
            </div>
          )}
        </Card.Section>
        {value.isShipping && (
          <Card.Section title={cms("label.weight")}>
            <p>{cms("label.caption.rate")}</p>
            <br />
            <TextField
              id="weight"
              label={cms("label.weight")}
              value={(value.weight && value.weight.toString()) || ""}
              placeholder={constant.ZERO_PRICE}
              onChange={(val) => {
                handleChange(constant.WEIGHT, acceptOnlyValidInput(val, value.weight));
                setDisabledButton(false);
              }}
              connectedRight={selectWeight}
            />
            <br />
          </Card.Section>
        )}
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button onClick={() => (isVariable && !isVariant ? handleTabChange(3) : handleTabChange(2))}>
            {cms("common.button.previous")}
          </Button>
        </Stack.Item>
        {isNew && isAttachmentVisible && (
          <Stack.Item>
            <Button onClick={() => handleTabChange(5)} disabled={addFulfillmentLoading}>
              {cms("common.button.skip")}
            </Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={islastTab ? false : disabledButton}
            loading={addFulfillmentLoading}
          >
            {!isAttachmentVisible ? cms("common.button.finish") : cms(`common.button.${islastTab ? "submit" : "save"}`)}
          </Button>
        </Stack.Item>
      </Stack>
      <div className="toast">
        <Toast message={message} />
      </div>
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />
    </>
  );
};

EditFulfillment.propTypes = tabProp.type;

export default withErrorBoundary(EditFulfillment);
