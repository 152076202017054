import React, { useContext } from "react";
import { Button, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

// import helper
import { baseHelper } from "lib/helpers";
// import context
import { PrivateContext } from "lib/context";
// import constant
import constant from "lib/constant/constant";

const ResetAllButton = (props) => {
  const resetAll = constant.RESET_ALL;
  const muteAll = constant.MUTE_ALL;
  const { cms, currentUser } = useContext(PrivateContext);
  const { mutedLabels, openConfirmationModal, seller = {}, vendor = {} } = props;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  let labelsList = isSeller && seller;
  if (isVendor) {
    labelsList = isVendor && vendor;
  }
  return (
    <>
      <Stack distribution="trailing">
        <Button
          primary
          disabled={mutedLabels && mutedLabels.length === Object.keys(labelsList).length}
          onClick={() => openConfirmationModal(muteAll)}
        >
          {`${cms("button.muteAll")}`}
        </Button>
        <Button primary disabled={mutedLabels && !mutedLabels.length} onClick={() => openConfirmationModal(resetAll)}>
          {`${cms("button.resetAll")}`}
        </Button>
      </Stack>
    </>
  );
};

ResetAllButton.defaultProps = PropTypes.default;
ResetAllButton.propTypes = PropTypes.type;
export default ResetAllButton;
