import { gql } from "apollo-boost";

const ADD_PRODUCT_LAKE = gql`
  mutation addProductLake($input: AddProductLake) {
    addProductLake(input: $input) {
      status
      data
      error
    }
  }
`;
export default ADD_PRODUCT_LAKE;
