// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  Layout,
  FormLayout,
  Form,
  Caption,
  List,
  Button,
  Banner as PolarisBanner,
  Card,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import propTypes
import PropTypes from "prop-types";

// import gql
import { GET_VENDOR_PRODUCT_TYPES } from "app/setup/apollo/queries";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner, SkeletonAnnotated } from "lib/components";
import AdvanceItem from "./advanceLine";

let paymentTerm = false;
const AdvanceOption = (props) => {
  const {
    PRODUCT_TYPE,
    gql: { GET_VENDOR_PRODUCT },
  } = constant;
  const { cms = {} } = useContext(PrivateContext);

  const {
    advanceTermData,
    bannerData,
    isPayment,
    lastUpdated,
    // learnMore,
    module: componentModule,
    setIsSubmit,
    setValues,
  } = props;

  paymentTerm = isPayment;

  const [allSuppliers, setAllSuppliers] = useState([]);
  const [state, setState] = useState({
    count: 1,
    advancedValue: [],
  });
  const isReturn = componentModule === "return";
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const { loading, data: vendorAndProductTypesData } = useQuery(GET_VENDOR_PRODUCT_TYPES);
  useEffect(() => {
    if (bannerData && bannerData.isOpen) {
      setBanner(bannerData);
    }
  }, [bannerData]);
  useEffect(() => {
    if (loading) {
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const responseError = baseHelper.getResponseError(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const { types = [], supplierRows = [] } = responseData || {};
    if (responseError || !responseData || (!types.length && !supplierRows.length)) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.advanceOptionUnavailable"),
      });
      return;
    }

    if (!supplierRows.length) {
      setBanner({
        isOpen: true,
        status: constant.WARNING,
        title: cms("message.error.vendorUnavailable"),
      });
    }

    const allSupplierValues = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: supplierId, brandName }) => ({
        label: brandName,
        value: supplierId,
      }));
    setAllSuppliers(allSupplierValues);
  }, [vendorAndProductTypesData, loading, GET_VENDOR_PRODUCT, cms]);

  useEffect(() => {
    const advancedValueLength = advanceTermData.length || 0;
    const isEqualArray = baseHelper.isItemEqual(advanceTermData, state.advancedValue);
    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        advancedValue: advanceTermData,
      });
    }
  }, [PRODUCT_TYPE, advanceTermData, state.advancedValue]);

  if (loading) {
    return (
      <Layout.Section>
        <SkeletonAnnotated />
      </Layout.Section>
    );
  }

  const updateVal = (option, val, item) => {
    const returnOrDays = isReturn ? "returnPeriod" : "days";
    const returnOrType = isReturn ? "isReturn" : "type";
    setBanner({ isOpen: false, status: "", title: "" });
    const { advancedValue } = state;
    let duplicate = false;
    if (option === returnOrDays && Number(val) > 365) {
      return;
    }
    if (option === "option" && advancedValue[item]) {
      advancedValue[item].vendorId = null;
    }
    if (option === "vendorId") {
      advancedValue.forEach((advanceData) => {
        if (advanceData.vendorId === val && advancedValue[item]) {
          advancedValue[item].vendorId = null;
          setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.rowAlreadyExists") });
          duplicate = true;
        }
        return null;
      });
    }
    if (duplicate) {
      return;
    }
    if (option === returnOrDays && advancedValue[item]) {
      if (val.length && !baseHelper.validatePositiveNumericValues(val)) {
        return;
      }
      advancedValue[item][returnOrDays] = val;
    } else if (advancedValue[item]) {
      advancedValue[item][option] = val;
      advancedValue[item][returnOrDays] = 0;
    }

    if (option === returnOrType) {
      if (val === "preFulfillment" || val === false) {
        advancedValue[item][returnOrDays] = null;
      }
    }
    setState({ ...state, advancedValue });
    setValues(advancedValue);
    setIsSubmit(false);
  };

  const add = () => {
    const { advancedValue, count } = state;
    const returnOrType = isReturn ? "isReturn" : "type";
    const returnOrDays = isReturn ? "returnPeriod" : "days";
    const row = { [returnOrType]: null, [returnOrDays]: null, option: null, vendorId: null };
    advancedValue.push(row);
    setState({ ...state, count: count + 1, advancedValue });
  };

  const removeItem = (item) => {
    const { advancedValue } = state;
    advancedValue.splice(item, 1);
    setState({ ...state, count: state.count - 1, advancedValue });
    setIsSubmit(false);
  };

  const renderAdvanceItems = () => {
    const { count, advancedValue } = state;
    return (
      <AdvanceItem
        advancedValue={advancedValue}
        allSuppliers={allSuppliers}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
        isReturn={isReturn}
      />
    );
  };

  const returnCaption = cms("section.advanceReturn.caption") || [];
  const paymentCaption = cms("section.advancePayment.caption") || [];
  const captions = isReturn ? returnCaption : paymentCaption;

  const renderCaptions = () => {
    return captions.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>
            <b>{caption.title}</b>
            {` ${caption.description}`}
          </Caption>
        </List.Item>
      );
    });
  };

  const { advancedValue } = state || [];

  const noValues = !(allSuppliers.length || advancedValue.length < 1);
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  return (
    <Layout.AnnotatedSection
      title={isReturn ? cms("section.advanceReturn.title") : cms("section.advancePayment.title")}
      description={isReturn ? cms("section.advanceReturn.description") : cms("section.advancePayment.description")}
    >
      <Card
        title={[
          isReturn ? cms("section.advanceReturn.title") : cms("section.advancePayment.title"),
          <TextStyle variation="subdued">
            {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
          </TextStyle>,
        ]}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () =>
        //       learnMore(isReturn ? cms("section.advanceReturn.title") : cms("section.advancePayment.title")),
        //   },
        // ]}
        sectioned
      >
        <TextContainer>
          {isReturn ? cms("section.advanceReturn.description") : cms("section.advancePayment.description")}
        </TextContainer>
        <br />
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              isScrollTop={isReturn}
              onDismiss={() => dismissBanner()}
            >
              {banner.message}
            </Banner>
            <br />
          </>
        )}
        <Form>
          <FormLayout>
            {!noValues && renderAdvanceItems()}
            {!noValues && (
              <Button plain id="addLink" onClick={add}>
                {cms("common.button.addMore")}
              </Button>
            )}
          </FormLayout>
          <br />
          <PolarisBanner status="info">
            <p>{cms("common.label.option")}</p>
            <br />
            <p>
              <List type="bullet">{renderCaptions()}</List>
            </p>
          </PolarisBanner>
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  );
};

AdvanceOption.propTypes = {
  advanceTermData: PropTypes.objectOf(PropTypes.shape),
  bannerData: PropTypes.objectOf(PropTypes.shape),
  isPayment: PropTypes.bool,
  lastUpdated: PropTypes.string,
  // learnMore: PropTypes.func,
  module: PropTypes.objectOf(PropTypes.shape),
  setIsSubmit: PropTypes.func,
  setValues: PropTypes.func,
};

AdvanceOption.defaultProps = {
  advanceTermData: {},
  bannerData: {},
  lastUpdated: "",
  // learnMore: () => {},
  isPayment: false,
  module: {},
  setIsSubmit: () => {},
  setValues: () => {},
};

const featureKey = paymentTerm ? constant.PAYMENT_TERM : constant.PAYMENT_RETURN;
const subFeatureKey = paymentTerm ? constant.ADVANCE_PAYMENT_TERM : constant.ADVANCE_PAYMENT_RETURN;
export default withFeature(withErrorBoundary(AdvanceOption), {
  feature: featureKey,
  subfeature: subFeatureKey,
});
