import { gql } from "apollo-boost";

const GET_VENDOR_INVOICE = gql`
  query getVendorInvoice($input: GetVendorInvoiceInput) {
    getVendorInvoice(input: $input) {
      data {
        orderDataRow {
          billing {
            addressLineOne
            addressLineTwo
            city
            company
            country
            email
            firstName
            lastName
            phone
            postcode
            state
          }
          currency
          discountTotal
          ecommercePlatform
          isPricesIncludeTax
          number
          paymentMethodTitle
          shipping {
            addressLineOne
            addressLineTwo
            city
            company
            country
            firstName
            lastName
            postcode
            state
          }
          shippingTotal
          storeDateCreated
          storeDateCreatedGMT
          storeDateModified
          storeDateModifiedGMT
          storeOrderId
          storeParentOrderId
          storePaymentMethodId
          storeStatus
          storeTransactionId
          tax {
            cart
            discount
            shipping
            total
          }
          total
          version
          isSynced
          createdAt
          updatedAt
        }
        orderLineItemsData {
          name
          price
          basePrice
          baseTotal
          quantity
          sku
          storeOrderId
          subtotal
          subtotalTax
          total
          totalTax
          invoiceNumber
          dispatchedDate
          commissionSeller
        }
        vendor {
          brandName
          firstName
          lastName
          phoneNumber
          address
          city
          country
          pinCode
        }
        seller {
          brandName
          firstName
          lastName
          phoneNumber
          address
          city
          country
          pinCode
        }
        #vendorDetails
        taxRate
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_INVOICE;
