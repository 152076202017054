import React, { useContext } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { ReceiptMajor } from "@shopify/polaris-icons";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "app/paymentsLake/modules/generic/list/propTypes";

// import cms and subFeature
import actionData from "app/paymentsLake/modules/generic/list/cms/payment";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { cms, history } = useContext(PrivateContext);
  const { activePopover, item, setActivePopover, isInvoiceEnabled = true } = props;
  const { _id: id, orderId } = item;
  const { mainLink } = actionData(cms);
  const { COMPLETE, PAY_AND_COLLECT, RESERVE, SHIPPED } = constant;

  const renderOrderRow = (items) => {
    const {
      _id,
      accountingStatus,
      commissionSeller,
      commissionVendor,
      createdAt,
      fulfillmentType,
      isAutoExpiry,
      isAutoReject,
      isRefund,
      name,
      dueDate,
      storeOrderId,
      storeStatus,
      shippingStatus,
      vendor,
    } = items;

    let { status } = items;

    const isReserve = status === SHIPPED && fulfillmentType === RESERVE;
    if (isReserve) {
      status = PAY_AND_COLLECT;
    }

    const autoStatus = isRefund && isAutoReject ? constant.AUTO_REJECT : isAutoExpiry && constant.NO_SHOW;
    status = autoStatus || status;

    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);

    const handleClick = () => {
      history.push(`${mainLink.orderNumber}${orderId}`);
    };

    const orderStatus =
      (shippingStatus === constant.DISPATCHED && constant.COMPLETE) ||
      (shippingStatus === constant.AWAITING_DISPATCH && constant.PROCESS) ||
      storeStatus;

    const badgeStatus = baseHelper.ucFirst(orderStatus);
    const badgeType = baseHelper.getBadgeType(orderStatus);
    const isInvoiceViewAllowed = [SHIPPED, COMPLETE].includes(orderStatus.toLowerCase());

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={handleClick}>
                {"#"}
                {storeOrderId}
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="strong">
                <div className="wrapbox loose">{`Name: ${name}`}</div>
              </TextStyle>
              <br />
              <TextStyle>
                {` ${cms("label.commission")}: ${vendor[0].moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}
              </TextStyle>
              <br />
              <TextStyle>
                {` ${cms("label.amount")}: ${vendor[0].moneyFormat} ${baseHelper.getPrice(commissionVendor)}`}
              </TextStyle>
              <br />
              <TextStyle variation="subdued">
                {`${cms("label.creationDate")}: ${baseHelper.formatDate(createdAt, true)}`}
              </TextStyle>
              <br />
              {dueDate && <TextStyle>{`${cms("label.dueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>}
            </Caption>
          </Stack.Item>
          {isInvoiceEnabled && (
            <Stack.Item>
              <Popover
                // eslint-disable-next-line no-underscore-dangle
                active={activePopover[_id]}
                // eslint-disable-next-line no-underscore-dangle
                setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
                options={[
                  {
                    content: cms("label.invoice"),
                    icon: ReceiptMajor,
                    onAction: () => window.open(`/payment-lake/invoice/${orderId}`, "_blank"),
                    disabled: !isInvoiceViewAllowed,
                  },
                ]}
              />
            </Stack.Item>
          )}
        </Stack>
        <Stack.Item style={topMargin10px}>
          <br />
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;
