import React, { useContext } from "react";
import { Stack, Checkbox, TextField, Layout, Card, Button, TextContainer } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import PropTypes from "prop-types";

const ShippingSection = (props) => {
  const { isShippingEnable, toggleSection, shippingLoading } = props;
  const { cms = {} } = useContext(PrivateContext);
  const getShippingSectionTitle = () => {
    return (
      <Stack>
        <Stack.Item fill>{cms("section.shipping.title")}</Stack.Item>
        <Stack.Item>
          <Button
            destructive={isShippingEnable}
            secondary={isShippingEnable}
            primary={!isShippingEnable}
            onClick={toggleSection}
            loading={shippingLoading}
          >
            {isShippingEnable ? cms("button.disable") : cms("button.enable")}
          </Button>
        </Stack.Item>
      </Stack>
    );
  };
  const getTextFields = () => {
    const fields = [
      {
        label: cms("section.shipping.label.address"),
        placeholder: cms("section.shipping.placeHolder.address"),
        isRequired: true,
      },
      {
        label: cms("section.shipping.label.city"),
        placeholder: cms("section.shipping.placeHolder.city"),
        isRequired: true,
      },
      {
        label: cms("section.shipping.label.country"),
        placeholder: cms("section.shipping.placeHolder.country"),
        isRequired: true,
      },
      {
        label: cms("section.shipping.label.postalCode"),
        placeholder: cms("section.shipping.placeHolder.postalCode"),
        isRequired: false,
      },
    ];
    return fields.map(({ label, placeholder, isRequired }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField label={`${label}${required}`} placeholder={placeholder} value="" disabled />
          <br />
        </>
      );
    });
  };
  const getFields = () => {
    return (
      <>
        {getTextFields()}
        <Checkbox checked={false} label={cms("section.shipping.label.checkbox")} disabled />
      </>
    );
  };
  return (
    <div>
      <Layout.AnnotatedSection title={getShippingSectionTitle()} description={cms("section.shipping.description")}>
        <Card title={cms("section.shipping.subTitle")}>
          <Card.Section>
            <TextContainer>{cms("section.shipping.subDescription")}</TextContainer>
            <br />
            {getFields()}
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};

ShippingSection.propTypes = {
  toggleSection: PropTypes.func.isRequired,
  isShippingEnable: PropTypes.bool.isRequired,
  shippingLoading: PropTypes.bool.isRequired,
};

export default ShippingSection;
