import React, { useContext, useEffect } from "react";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";

import { WIX_CALLBACK } from "app/product/apollo/mutations";
import { useMutation } from "react-apollo";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const WixCallback = () => {
  const { currentUser, history, cms = () => {}, isLoading } = useContext(PrivateContext);
  const [saveWixVendorToken] = useMutation(WIX_CALLBACK);
  const { wix } = (currentUser && currentUser.storeConfig) || {};
  const { accessKey = "" } = wix || {};
  const isOrderConnect = !!accessKey;
  const isUpdatePermission = !!(isOrderConnect && currentUser.isOrderConnect);
  const queryParams = baseHelper.getQueryParams(history.location.search);

  const data = {
    code: queryParams.code,
    instanceId: queryParams.instanceId,
    state: queryParams.state,
  };

  const keyParam = "?key=wix";
  const route =
    (isUpdatePermission && `/${keyParam}`) ||
    (isOrderConnect && `/setting/order${keyParam}`) ||
    `/products/add${keyParam}`;

  // Call WIX_CALLBACK mutation and show success or error banner
  useEffect(() => {
    if (!isLoading) {
      saveWixVendorToken({ variables: { input: data } })
        .then((res) => {
          let bannerData = { title: cms("message.success.shop"), status: constant.SUCCESS };
          const resError = baseHelper.getResponseError(res.data, constant.gql.SAVE_WIX_VENDOR_TOKEN);
          if (resError) {
            bannerData = { title: resError, status: constant.CRITICAL };
          }
          history.push(route, {
            bannerData,
          });
        })
        .catch((exception) => {
          const bannerData = { title: errorHelper.parse(exception), status: constant.CRITICAL };
          history.push(route, { bannerData });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Spinner isFullPage />
      <h3 className="text-center" style={{ marginTop: "32%" }}>
        {cms("message.success.wait")}
      </h3>
    </div>
  );
};

export default withErrorBoundary(WixCallback);
