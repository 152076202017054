import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import {
  ActionList,
  Button,
  Card,
  FormLayout,
  Layout,
  Modal,
  Popover,
  // Select,
  SkeletonBodyText,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { GET_ADDRESS_DETAILS } from "app/orders/apollo/queries";
import { READY_TO_COLLECT } from "app/orders/apollo/mutations";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { CMS } from "routes/public/apollo/queries";
import { StyledDiv, AddressStyled } from "./style/style";

const { gql, PICKUP_LOCATION } = constant;

const CollectOrder = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    addressModal = false,
    addressList,
    fulfillModal,
    isBulkMark,
    setAddressModal,
    setBanner,
    setMessage,
    selectedItemData,
    onRefetchOrderLineView,
    isReadyForCollect = false,
    bulkQuantity,
    fulfillmentType,
    viewFulfillmentType,
  } = props;

  const collectData = isBulkMark
    ? selectedItemData
    : selectedItemData && selectedItemData.length && selectedItemData[0];
  const { collectDetail = {}, properties = [], fulfillmentType: type = "" } = collectData || selectedItemData || {};
  let customerAddressId = "";
  if (type === constant.COLLECT && properties && properties.length) {
    const addressData = properties.find((val) => val && val.name === PICKUP_LOCATION);
    if (addressData && addressData.value) {
      customerAddressId = addressData.value;
    }
  }

  const [vendorAddressId, setVendorAddressId] = useState(
    (collectDetail && collectDetail.addressId) || customerAddressId || ""
  );
  const [note, setNote] = useState("");
  const [addressDetails, setAddressDetails] = useState({});
  const [active, setActive] = useState(false);
  const toggleActive = () => setActive((active) => !active);

  const [getAddressListDetails, { loading: addressDetailsLoading, data: addressDetailsData }] = useLazyQuery(
    GET_ADDRESS_DETAILS
  );
  const [readyForCollectOrder, { loading: readyToCollectLoading }] = useMutation(READY_TO_COLLECT);

  useEffect(() => {
    if (!vendorAddressId) {
      const defaultAddress = addressList.find((item) => item.isDefault);
      if (defaultAddress) {
        setVendorAddressId(defaultAddress.value);
      }
    }
  }, [addressList, vendorAddressId]);

  const transformAddressList = () => {
    return addressList.map((address) => {
      const { label, value, isDefault } = address;
      if (isDefault) {
        return {
          content: label,
          onAction: () => {
            setActive((active) => !active);
            setVendorAddressId(value);
          },
          active: value === vendorAddressId,
        };
      }
      return {
        content: label,
        onAction: () => {
          setActive((active) => !active);
          setVendorAddressId(value);
        },
        active: value === vendorAddressId,
      };
    });
  };

  useEffect(() => {
    if (!addressDetailsLoading && addressDetailsData) {
      const responseData = baseHelper.getResponseData(addressDetailsData, gql.GET_ADDRESS_DETAILS);
      if (!responseData) {
        const bannerData = {
          title: baseHelper.getResponseError(addressDetailsData, gql.GET_ADDRESS_DETAILS) || errorHelper.parse(),
          status: constant.CRITICAL,
          isOpen: true,
        };
        setAddressModal(false);
        setBanner(bannerData);
      }

      setAddressDetails(responseData);
    }
  }, [addressDetailsLoading, addressDetailsData]);

  useEffect(() => {
    if (vendorAddressId) {
      setAddressDetails({});
      const { vendorId = "" } = selectedItemData || {};
      getAddressListDetails({ variables: { input: { addressId: vendorAddressId, sellerId: vendorId } } });
    }
  }, [vendorAddressId]);

  const readyForCollect = async () => {
    setMessage("");
    const { _id: orderLineItemId = "" } = selectedItemData || {};
    const dataToInsert = bulkQuantity.map(({ id, quantity }) => {
      return { id, readyQuantity: parseInt(quantity, 10), addressId: vendorAddressId, note };
    });
    const formData = {
      orderLineItemIds: dataToInsert,
    };
    if (!isBulkMark) {
      formData.isSingle = true;
    }
    try {
      const response = await readyForCollectOrder({
        variables: {
          input: formData,
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.READY_TO_COLLECT);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.READY_TO_COLLECT);

      if (responseError) {
        setAddressModal(false);
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setMessage(
        // eslint-disable-next-line no-use-before-define
        isServiceFulfillmentType()
          ? cms("message.success.readyForCollection").replace(constant.COLLECTION, constant.SERVICE)
          : cms("message.success.readyForCollection")
      );
      onRefetchOrderLineView();
      setAddressModal(false);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      setAddressModal(false);
    }
  };
  const quantityData = bulkQuantity.find((quantityItem) => quantityItem.id) || {};

  const isServiceFulfillmentType = () => {
    return (
      fulfillmentType === constant.SERVICE ||
      (viewFulfillmentType && viewFulfillmentType.length && viewFulfillmentType.includes(constant.SERVICE))
    );
  };

  const renderModal = () => {
    const modalTitle = isServiceFulfillmentType() ? cms("label.markReadyService") : cms("label.markReadyForCollection");
    const action = {
      content: isServiceFulfillmentType() ? cms("label.readyService") : cms("label.readyForCollection"),
      onAction: () => readyForCollect(),
      loading: readyToCollectLoading,
      disabled:
        !vendorAddressId || parseInt(quantityData.quantity, 10) === constant.value.ZERO || quantityData.quantity === "",
    };

    const activator = (
      <Button onClick={toggleActive} disclosure={constant.SELECT.toLowerCase()} disabled={customerAddressId}>
        {addressList.find((item) => item.value === vendorAddressId).label}
      </Button>
    );

    return (
      <>
        <Modal
          open={addressModal}
          onClose={() => {
            setAddressModal(false);
            setVendorAddressId("");
            setAddressDetails({});
            setNote("");
          }}
          title={modalTitle}
          primaryAction={action}
          sectioned
        >
          <Modal.Section>
            {fulfillModal()}
            <Layout>
              <Layout.Section secondary>
                <Card
                  title={
                    fulfillmentType === constant.SERVICE ? cms("label.serviceLocation") : cms("label.pickupLocation")
                  }
                  sectioned
                >
                  <FormLayout>
                    <StyledDiv>
                      <Popover active={active} activator={activator} onClose={toggleActive}>
                        <ActionList items={transformAddressList()} />
                      </Popover>
                    </StyledDiv>
                    {addressDetailsLoading && <SkeletonBodyText />}
                    {addressDetails &&
                      Object.keys(addressDetails).length !== 0 && [
                        <Stack vertical>
                          <Stack.Item>
                            <TextStyle>
                              <Stack wrap={false}>
                                <AddressStyled>
                                  <Stack.Item>
                                    <TextStyle>{baseHelper.ucFirst(addressDetails.address)}</TextStyle>
                                    <br />
                                    <TextStyle>{addressDetails.city}</TextStyle>
                                    <br />
                                    <TextStyle>{addressDetails.country}</TextStyle>
                                    <br />
                                    <TextStyle>{addressDetails.postalCode}</TextStyle>
                                    <br />
                                  </Stack.Item>
                                </AddressStyled>
                              </Stack>
                            </TextStyle>
                          </Stack.Item>
                        </Stack>,
                      ]}
                  </FormLayout>
                </Card>
                <br />
                <TextField
                  label={cms("label.note")}
                  value={note}
                  multiline={4}
                  onChange={(value) => setNote(value)}
                  maxLength={5000}
                />
              </Layout.Section>
            </Layout>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  return <>{vendorAddressId ? renderModal() : null}</>;
};

export default CollectOrder;
