import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import mutation
import { SYNC_WOOCOMMERCE_VENDOR_PRODUCT } from "app/product/apollo/mutations";

// import props
import { syncWooCommerceProduct } from "app/product/modules/generic/propTypes";

const SyncWooCommerceProduct = (props) => {
  const { cms, history, setBanner } = props;
  const { CRITICAL, gql } = constant;

  const [syncWooCommerceVendorProduct, { loading: syncWooCommerceVendorProductLoading }] = useMutation(
    SYNC_WOOCOMMERCE_VENDOR_PRODUCT
  );
  const syncWooCommerceProducts = async () => {
    try {
      const response = await syncWooCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, gql.SYNC_WOOCOMMERCE_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/products/woocommerce");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWoocommerceProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: `${cms("section.connect.message.success.sync")}`,
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncWooCommerceVendorProductLoading} onClick={(value) => syncWooCommerceProducts(value)}>
      {cms("section.connect.button.importWooCommerceProduct")}
    </Button>
  );
};

SyncWooCommerceProduct.propTypes = syncWooCommerceProduct.type;
SyncWooCommerceProduct.defaultProps = syncWooCommerceProduct.default;

export default SyncWooCommerceProduct;
