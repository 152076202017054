import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";

// constants
import constant from "lib/constant/constant";

// contexts
import { PublicContext } from "lib/context";

// helper components
import { Banner, Spinner } from "lib/components";

// gql
import { VERIFY_EMAIL } from "app/public/apollo/mutations";

const VerifyEmail = () => {
  const { match = {}, cms, history = [] } = useContext(PublicContext);
  const { CRITICAL, gql, SUCCESS } = constant;

  // states
  const [isTokenSet, setToken] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    message: "",
    action: null,
  });

  // gql states
  const [verificationEmail, { loading }] = useMutation(VERIFY_EMAIL);

  const verifyEmail = async () => {
    const {
      params: { token },
    } = match;
    if (!token || isTokenSet) {
      return;
    }
    setToken(true);
    try {
      const response = await verificationEmail({
        variables: { input: { token } },
      });
      const responseError = baseHelper.getResponseError(response.data, gql.VERIFY_EMAIL);
      if (responseError) {
        const bannerContent = {
          isOpen: true,
          status: CRITICAL,
          title: responseError,
          action: {
            content: cms("common.button.goBack"),
            onAction: () => history.push("/login"),
          },
        };
        setBanner(bannerContent);
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, gql.VERIFY_EMAIL);
      setBanner({ isOpen: true, status: SUCCESS, title: cms("message.success") });
      if (responseData) {
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  verifyEmail();

  if (loading) {
    return <Spinner />;
  }

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
      message: "",
      action: null,
    });
  };

  return (
    <Banner
      isOpen={banner.isOpen}
      status={banner.status}
      title={banner.title}
      onDismiss={() => dismissBanner()}
      action={banner.action}
    >
      {banner.message}
    </Banner>
  );
};

export default withErrorBoundary(VerifyEmail);
