import { gql } from "apollo-boost";

const REGISTER_BY_INVITE = gql`
  mutation registerByInvite($input: RegisterByInvite) {
    registerByInvite(input: $input) {
      data
      status
      error
    }
  }
`;

export default REGISTER_BY_INVITE;
