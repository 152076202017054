import React, { useState, useContext, useEffect } from "react";
import { Card, PageActions, FormLayout, Select, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import {
  //  Sheet,
  SkeletonCard,
  Toast,
} from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import mutation
import { ADD_PRODUCT_AS_SERVICE_INFORMATION } from "app/product/apollo/mutations";

// import queries
import {
  GET_CATALOG,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TYPE,
  GET_VENDOR_BRAND_LIST,
  GET_WHOLESALE_SETTING,
} from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import proptype
import { tabProp } from "app/product/modules/generic/propTypes/index";

// import component
import Tags from "app/product/modules/generic/features/form/tag/tag";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";
import Catalog from "app/product/modules/generic/features/form/tag/catalog";

const Information = () => {
  const { setBanner } = useContext(ProductContext);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;

  const [comparePrice, setComparePrice] = useState("");
  const [comparePriceData, setComparePriceData] = useState();
  const [data, setData] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionData, setDescriptionData] = useState();
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("");
  const [priceData, setPriceData] = useState();
  const [productFormSetting, setProductFormSetting] = useState();
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [tagData, setTagData] = useState();
  const [title, setTitle] = useState("");
  const [typeData, setTypeData] = useState();
  const [catalog, setCatalog] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);

  const { CRITICAL, gql } = constant;

  const { loading: catalogLoading, data: catalogData } = useQuery(GET_CATALOG);
  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: brandListLoading, data: vendorBrandListData } = useQuery(GET_VENDOR_BRAND_LIST);
  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);

  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_AS_SERVICE_INFORMATION);

  const { loading: loadingProductForm, data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  // load product catalog data
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || {};

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        setDescriptionData(productLabel.description);
        setPriceData(productLabel.price);
        setComparePriceData(productLabel.comparePrice);
        setTagData(productLabel.tags);
        setTypeData(productLabel.productType);
        setProductFormSetting(productLabel);
        setCatalog(productLabel.catalog);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductForm]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  if (
    brandListLoading ||
    productTagLoading ||
    productTypeLoading ||
    loadingProductForm ||
    wholesaleLoading ||
    catalogLoading
  ) {
    return <SkeletonCard />;
  }

  // load vendors
  const brandList = baseHelper.getResponseData(vendorBrandListData, constant.gql.GET_VENDOR_BRAND_LIST) || [];
  const activeVendorOptions = brandList.filter((item) => {
    return item.isInactive;
  });
  const inactiveVendorOptions = brandList.filter((item) => {
    return !item.isInactive;
  });
  const finalVendorOptions = [...activeVendorOptions, ...inactiveVendorOptions];
  const vendorOptions = finalVendorOptions.map(({ _id: vendorId, brandName, isInactive }) => ({
    label: isInactive ? `${brandName} (${cms("button.disabled")})` : brandName,
    value: vendorId,
    disabled: isInactive,
  }));

  const handleChange = (fieldName, fieldValue) => {
    setData({
      ...data,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = (content) => {
    setDescription(content);
  };

  const findVendorName = (options, selectedValue) => {
    const result = options.find((vendorOption) => vendorOption.value === selectedValue);
    return result;
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const addProduct = async (formValues) => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const responseData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_INFORMATION);

      const responseError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_INFORMATION);

      if (responseData) {
        setMessage(cms("service.message.success.add"));
        setTimeout(() => {
          history.push(`service/edit/${encodeURIComponent(responseData)}?tab=availability`, { add: true });
        }, 1500);
      }
      if (responseError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: responseError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const submitCallBack = async () => {
    const vendorName = findVendorName(vendorOptions, selectedVendor);
    const vendor = vendorName.label;
    const vendorId = selectedVendor;

    const formValues = {
      title,
      description,
      vendor,
      vendorId,
      productType: selectedProductType,
      tags: data && data.tags,
      shopifyCatalogId: data && data.shopifyCatalogId,
      isService: true,
    };

    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    try {
      // add product
      await addProduct(formValues);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };

    const dataToCheck = {
      title,
      description,
      vendor: selectedVendor,
      productType: selectedProductType,
      tags: (data && data.tags && data.tags.length) || null,
      catalog: data.shopifyCatalogId,
      comparePrice,
      price,
    };

    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("service.message.error.title"),
      };
      setBanner(banner);
      return;
    }
    if (!selectedVendor || selectedVendor.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.vendor"),
      };
      setBanner(banner);
      return;
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys
        .filter((item) => (isWholesalePrice ? requiredKeys : item !== constant.WHOLESALE_PRICE))
        .forEach((key) => {
          const valueData = dataToCheck[key];
          if (
            (!valueData || valueData.length === 0 || valueData === "undefined") &&
            constant.INFORMATION_KEY.includes(key)
          ) {
            errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
          }
        });
    }

    if (errorField) {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  const required = (value) => {
    if (value && value.isRequired) {
      return "*";
    }
    return "";
  };

  const getLabel = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  return (
    <>
      <Card title={cms("service.label.information")} id="productInformation">
        <Card.Section>
          <p>{cms("service.label.operatorDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("service.label.name")}*`}
              placeholder={cms("service.placeholder.name")}
              required="true"
              error=""
              value={title || ""}
              onChange={(value) => {
                setTitle(value);
                setDisabledButton(false);
              }}
              onBlur={() => title && setTitle(title.trim())}
            />
            {`${getLabel(descriptionData, cms("label.description"))}${required(descriptionData)}`}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={`${getLabel(priceData, cms("label.price"))}${required(priceData)}`}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix={moneyFormat}
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                  setDisabledButton(false);
                }}
              />
              <TextField
                id="comparePrice"
                label={`${getLabel(comparePriceData, cms("label.comparePrice"))}${required(comparePriceData)}`}
                min={0}
                placeholder="0.00"
                prefix={moneyFormat}
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>

            <Select
              label={`${cms("service.label.vendorName")}*`}
              placeholder={cms("service.placeholder.vendorName")}
              options={vendorOptions}
              value={selectedVendor || ""}
              onChange={(value) => {
                setSelectedVendor(value);
                setDisabledButton(false);
              }}
            />

            {productTypeOptions && productTypeOptions.length > 0 && (
              <Select
                label={`${getLabel(typeData, cms("service.label.type"))}${required(typeData)}`}
                options={productTypeOptions}
                placeholder={cms("service.placeholder.type")}
                onChange={(value) => {
                  setSelectedProductType(value);
                  setDisabledButton(false);
                }}
                value={selectedProductType}
              />
            )}
            {availableProductTags && availableProductTags.tags && availableProductTags.tags.length > 0 && (
              <Tags
                label={`${getLabel(tagData, cms("service.label.tag"))}${required(tagData)}`}
                placeholder={cms("service.placeholder.tag")}
                data={data}
                setData={setData}
                handleChange={handleChange}
                productTag={availableProductTags}
              />
            )}
            <Catalog
              label={`${getLabel(catalog, cms("service.label.collection"))}${required(catalog)}`}
              placeholder={cms("service.placeholder.collection")}
              data={data}
              setData={setData}
              handleChange={handleChange}
              productCatalog={(productCatalogOption && productCatalogOption.catalog) || []}
            />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms("button.save"),
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
          disabled: disabledButton,
        }}
      />
    </>
  );
};

Information.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(Information), { feature: constant.PRODUCT_ADD_FORM });
