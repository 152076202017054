import { gql } from "apollo-boost";

const GET_SELLER_VARIANT = gql`
  query getProductAndSellerProduct($input: GetProductSeller) {
    getProductAndSellerProduct(input: $input) {
      data {
        sellerProduct {
          _id
          sku
          basePricing {
            currencyCode
          }
          vendorShopifyProductId
          isShipping
          images {
            imageId
            imageUrl
            position
            shopifyImageId
            url
          }
          variants {
            availability {
              capacity
              duration
              locationId
              hour {
                fri {
                  close
                  open
                }
                mon {
                  close
                  open
                }
                sat {
                  close
                  open
                }
                sun {
                  close
                  open
                }
                thu {
                  close
                  open
                }
                tue {
                  close
                  open
                }
                wed {
                  close
                  open
                }
              }
            }
            option1Val
            option2Val
            option3Val
            option3
            option1
            option2
            barcode
            weightUnit
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
            metafield {
              key
              name
              namespace
              value
            }
            comparePrice
            customInfo {
              wholesalePrice
            }
            image
            inventoryManagement
            inventoryQuantity
            isShipping
            markUp {
              price
              type
            }
            price
            sku
            vendorDiscount {
              price
              type
            }
            sellerDiscount {
              price
              type
            }
            weight
          }
        }
      }
      error
      status
    }
  }
`;

export default GET_SELLER_VARIANT;
