import React, { useContext } from "react";
import { Badge, Caption, Card, Link, TextStyle, Thumbnail, Stack, Icon, Button } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const ReadyForService = (props) => {
  const { itemData, refundData } = props;
  const { cms, history, match } = useContext(PrivateContext);

  const amount = [];
  const vendorsSum = [];

  itemData
    .filter(
      (lineItem) =>
        lineItem.fulfillmentStatus !== constant.FULFILLED &&
        lineItem.fulfillmentType === constant.SERVICE &&
        lineItem.collectDetail
    )
    .forEach(({ vendor }) => {
      vendorsSum.push(vendor);
      return vendorsSum;
    });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const lineDataItems = itemData.filter(
    (lineItem) =>
      lineItem.fulfillmentStatus !== constant.FULFILLED &&
      lineItem.fulfillmentType === constant.SERVICE &&
      lineItem.collectDetail
  ).length;

  const lineItems =
    itemData &&
    itemData.length &&
    itemData
      .filter(
        (item) =>
          [constant.UNFULFILLED, constant.PARTIAL].includes(item.fulfillmentStatus) &&
          item.fulfillmentType === constant.SERVICE &&
          item.collectDetail
      )
      .map((lineData, idx) => {
        const {
          actualQuantity = 0,
          collectDetail = {},
          fulfillableQuantity = 0,
          fulfillmentType = "",
          image = "",
          isProductDeleted,
          isRefund = false,
          moneyFormat: lineMoneyFormat,
          name,
          productId,
          properties,
          price,
          quantity = 0,
          sku,
          status,
          vendor,
        } = lineData || {};

        let { readyQuantity = 0 } = collectDetail || {};
        isRefund &&
          refundData &&
          refundData.forEach((refundItem) => {
            const { totalQuantity = 0 } = refundItem;
            readyQuantity -= totalQuantity;
          });
        const lineItemKey = `lineItem${idx}`;
        const lineImage =
          imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

        const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;

        const newQuantity = parseInt(actualQuantity || quantity, 10) - parseInt(fulfillableQuantity, 10);
        const readyCollectQuantity = parseInt(readyQuantity, 10) - parseInt(newQuantity, 10);

        if (!readyCollectQuantity) {
          return null;
        }

        const totalPrice = price * readyCollectQuantity;

        amount.push(totalPrice);

        const lineItemName = <div className="productName">{name}</div>;
        const isServiceLineItem = baseHelper.isServiceLineItem(fulfillmentType, properties);
        const productURL = isServiceLineItem ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;

        return (
          <div key={lineItemKey} className="fulfilled-Secn">
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(productURL)}>{name || ""}</Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>
                          {vendor ? `${cms("label.vendor")}: ${vendor}` : ""}
                        </TextStyle>
                        <LineItemProperties
                          variation={constant.SUBDUED}
                          properties={properties}
                          isServiceLineItem={isServiceLineItem}
                        />
                        {/* {sku && ( */}
                        {isServiceLineItem ? '' : (<TextStyle variation={constant.SUBDUED}>
                          {cms("label.skuList")} {sku} </TextStyle>)}
                        {/* )} */}
                      </Caption>

                      <Badge status={baseHelper.getBadgeType(status)} className="text-capitalize">
                        {cms("label.readyService")}
                      </Badge>

                      <Button plain size="slim" onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                        <Stack spacing="extraTight" alignment="center">
                          <Stack.Item>
                            <Icon source={NoteMajor} />
                          </Stack.Item>
                          <Stack.Item>{`${cms("label.serviceSlip")}`}</Stack.Item>
                        </Stack>
                      </Button>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${readyCollectQuantity}`}</Stack.Item>
                    <Stack.Item>
                      {moneyFormat}
                      {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
                    </Stack.Item>
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        );
      });

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const moneyFormat = (itemData && itemData.length && itemData[0].moneyFormat) || constant.symbol.DOLLAR;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            (
            {lineDataItems > 1
              ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">{`${moneyFormat}${baseHelper.getPrice(totalAmount)}`}</TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );

  return [lineItems, totalFulfill];
};

export default ReadyForService;
