import styled from "styled-components";

const StyledChart = styled.div`
  padding: 20px;
  max-width: 1140px;
  width: 100%;
  margin-top: -10px;
  background: white;
  display: flex;
  justify-content: space-between;
  .brand-logo {
    max-width: 200px;
    max-height: 50px;
  }
`;

export const ReportTitle = styled.div`
  display: flex;
  justify-content: center;
`;
export default StyledChart;
