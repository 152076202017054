import { gql } from "apollo-boost";

const BULK_ORDER_FULFILL = gql`
  mutation bulkFulfillOrderCSV($input: OrderFulfill) {
    bulkFulfillOrderCSV(input: $input) {
      status
      data
      error
    }
  }
`;

export default BULK_ORDER_FULFILL;
