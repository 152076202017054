import { gql } from "apollo-boost";

const DELETE_PRODUCT_IMAGE = gql`
  mutation deleteProductImage($input: DeleteProductImage) {
    deleteProductImage(input: $input) {
      data
      status
      error
    }
  }
`;
export default DELETE_PRODUCT_IMAGE;
