const cmsVendorSettingReview = (cms) => {
  const vendorReviewCMS = {
    label: {
      reviewDescription: cms("label.section.review.description"),
      resetLabel: cms("label.reset"),
      reviewSettingDescription: cms("label.section.setting.description"),
      reviewSettingTitle: cms("label.section.setting.title"),
      reviewTitle: cms("label.section.review.title"),
      update: cms("label.update"),
      welcomeText: cms("label.welcome"),
    },
    messageToast: {
      enableToastMessage: cms("message.enable"),
      disableToastMessage: cms("message.disable"),
      updateToastMessage: cms("message.update"),
    },
    errorMessage: {
      modalError: cms("error.modal"),
    },
  };
  return vendorReviewCMS;
};

export default cmsVendorSettingReview;
