import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import { OperatorProviderView } from "./operator";
import { AdminProviderView } from "./admin";

const { userKey } = constant;
const { operator, admin } = userKey;

const VendorView = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProviderView />;
    }
    if (role === admin) {
      return <AdminProviderView />;
    }
    return null;
  };
  if (isLoading) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }
  const currentUserRole = storageHelper.get("userRole");
  const VendorViewComponent = getComponent(currentUserRole);
  return VendorViewComponent;
};

export default VendorView;
