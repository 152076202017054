import { gql } from "apollo-boost";

const GET_FULFILLMENT_TRACKING_DETAILS_SETTING = gql`
  query {
    getFulfillmentTrackingDetailsSetting {
      data {
        isEnabled
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_FULFILLMENT_TRACKING_DETAILS_SETTING;
