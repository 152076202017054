import { gql } from "apollo-boost";

const GET_PAYMENT_LAKE_LIST = gql`
  query getPaymentLakeList($input: GetListInputV1) {
    getPaymentLakeList(input: $input) {
      data {
        count
        paymentList {
          _id
          name
          commissionSeller
          commissionVendor
          createdAt
          dueDate
          storeStatus
          shippingStatus
          ownerId
          orderId
          productId
          storeOrderId
          vendor {
            _id
            brandName
            moneyFormat
          }
          accountingStatus
        }
      }
      status
      error
    }
  }
`;

export default GET_PAYMENT_LAKE_LIST;
