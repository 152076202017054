import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";

import { Button, Card, Caption, Layout, Stack, TextStyle, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

import { GET_INVOICE_SETTING, UPDATE_INVOICE_PREFIX_NUMBER } from "app/setup/apollo";

const InvoiceNumber = (props) => {
  const { cms = () => {}, history } = useContext(PrivateContext);
  const [prefixNumber, setPrefixNumber] = useState();
  const [firstPush, setFirstPush] = useState(true);

  const { setMessage, setBanner } = props;

  const [submitEnabled, setSubmitEnable] = useState(false);
  const [updateAt, setUpdateAt] = useState("");

  const {
    error: errorGetInvoiceNumber,
    loading: loadingGetInvoiceNumber,
    data: dataGetInvoiceNumber,
    refetch,
  } = useQuery(GET_INVOICE_SETTING);

  const invoiceNumberResponseData = baseHelper.getResponseData(dataGetInvoiceNumber, constant.gql.GET_INVOICE_SETTING);
  const invoiceNumberErrorData = baseHelper.getResponseError(dataGetInvoiceNumber, constant.gql.GET_INVOICE_SETTING);

  const { payment } = invoiceNumberResponseData || {};

  useEffect(() => {
    if (payment) {
      const { invoice } = payment;
      if (invoice) {
        const { prefix = {} } = invoice || {};
        const { value = "", updatedAt } = prefix || {};
        if (value) {
          setPrefixNumber(value);
          setUpdateAt(updatedAt);
        }
        if (updatedAt) {
          setFirstPush(false);
        }
      }
    }

    if (invoiceNumberErrorData) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(invoiceNumberErrorData), isOpen: true });
    }
    if (errorGetInvoiceNumber) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetInvoiceNumber), isOpen: true });
    }
  }, [payment]);

  const [updateInvoiceNumberPrefix, { loading: updateInvoiceNumberLoading }] = useMutation(
    UPDATE_INVOICE_PREFIX_NUMBER
  );

  const onChangeAction = (newValue) => {
    setPrefixNumber(newValue);
    setSubmitEnable(true);
  };

  const onSubmit = async () => {
    try {
      const response = await updateInvoiceNumberPrefix({
        variables: {
          input: { invoiceNumberPrefix: prefixNumber },
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_INVOICE_PREFIX_NUMBER);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (response && firstPush) {
        setMessage(cms("section.invoiceNumberPrefix.message.success.save"));
      } else {
        setMessage(cms("section.invoiceNumberPrefix.message.success.update"));
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  return (
    <Layout.AnnotatedSection
      title={cms("section.invoiceNumberPrefix.title")}
      description={cms("section.invoiceNumberPrefix.description")}
    >
      <Card
        title={[
          cms("section.invoiceNumberPrefix.card.title"),
          updateAt && (
            <TextStyle variation="subdued">
              <Caption>{baseHelper.lastUpdateDate(updateAt)}</Caption>
            </TextStyle>
          ),
        ]}
      >
        <Card.Section>
          <TextStyle variation="subdued">{cms("section.invoiceNumberPrefix.card.description")}</TextStyle>
          <br />
          <br />
          <TextField
            label={cms("section.invoiceNumberPrefix.label")}
            placeholder={cms("section.invoiceNumberPrefix.placeholder")}
            value={prefixNumber}
            onChange={(value) => onChangeAction(value)}
            maxLength={6}
          />
          <br />
          <Stack distribution="equalSpacing">
            <Button secondry onClick={() => history.push("/setting")}>
              {cms("common.button.cancel")}
            </Button>
            <Button primary submit disabled={!submitEnabled} onClick={onSubmit} loading={updateInvoiceNumberLoading}>
              {firstPush ? cms("common.button.submit") : cms("common.button.update")}
            </Button>
          </Stack>
        </Card.Section>
      </Card>
      <br />
    </Layout.AnnotatedSection>
  );
};

export default InvoiceNumber;
