import React, { useContext, useState } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { ReceiptMajor } from "@shopify/polaris-icons";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "app/payments/modules/generic/list/propTypes";

// import cms and subFeature
import InvoiceModal from "app/payments/modules/generic/list/modal/invoice";
import actionData from "app/payments/modules/generic/list/cms/payment";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { activePopover, item, isInvoiceEnabled = true, popoverDisable, setActivePopover, setBanner } = props;
  const { _id: id, orderId, isRefunded: isLineItemRefunded } = item;
  const { mainLink } = actionData(cms);
  const isVendor = baseHelper.isVendor(currentUser);
  const { CLOSED, COLLECT, PAID_AND_AVAILED, PAY_AND_COLLECT, REJECTED, RESERVE, SERVICE, SHIPPED } = constant;
  const [invoiceModalActive, setInvoiceModalActive] = useState(false);
  const renderOrderRow = (items) => {
    const {
      _id,
      accountingStatus,
      actualQuantity,
      commissionSeller,
      commissionTax,
      commissionVendor,
      createdAt,
      dueDate,
      fulfillmentType,
      isAutoExpiry,
      isAutoReject,
      isOrderBasedCommission,
      isRefund,
      moneyFormat,
      orderNumber,
      paidVia,
      price,
      priceTaxRate,
      rejectedByVendor = false,
      quantity,
      vendorId,
      shippingAmount,
      shippingTax,
      shopifyOrderDate,
      vendor,
    } = items;

    let { status } = items;

    let taxRate = 0;
    let totalOrderPayout = 0;
    let totalShippingAmount = 0;

    if (!priceTaxRate) {
      taxRate = parseFloat(commissionSeller || 0) + parseFloat(commissionTax || 0);
    }

    if (priceTaxRate && !commissionTax) {
      taxRate = parseFloat(commissionSeller || 0);
    }

    if (priceTaxRate && commissionTax) {
      taxRate = parseFloat(commissionSeller || 0) + parseFloat(commissionTax || 0);
    }

    totalShippingAmount = parseFloat(shippingAmount || 0) + parseFloat(shippingTax || 0);

    totalOrderPayout =  parseFloat(!rejectedByVendor && commissionVendor || 0);

    const getItemCommissionTax = () => {
      const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
      const totalPriceInclusiveTax = baseHelper.getPrice(price * quantity);
      const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
      const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
      const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
      const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
      return sellerTaxOnCommission;
    };

    const isReserve = [SHIPPED, CLOSED].includes(status) && fulfillmentType === RESERVE;
    const isCollect = [SHIPPED, CLOSED].includes(status) && fulfillmentType === COLLECT;
    const isService = [SHIPPED, CLOSED].includes(status) && fulfillmentType === SERVICE;
    const isRefunded =
      (isRefund && fulfillmentType === COLLECT) || (fulfillmentType === RESERVE && actualQuantity === 0);

    const isAcceptedByOperator = rejectedByVendor && status === constant.ACCEPTED;
    const isFulfilledByOperator = rejectedByVendor && status === constant.SHIPPED;

    if (isReserve || isCollect || isService) {
      status = isService ? PAID_AND_AVAILED : PAY_AND_COLLECT;
    }

    if (isRefunded || rejectedByVendor) {
      status = REJECTED;
    }

    if (isService) {
      status = PAID_AND_AVAILED;
    }

    if (isRefunded) {
      status = REJECTED;
    }

    const autoStatus = isRefund && isAutoReject ? constant.AUTO_REJECT : isAutoExpiry && constant.NO_SHOW;
    status = autoStatus || status;

    const badgeStatus = baseHelper.ucFirst(status);
    const badgeType = baseHelper.getBadgeType(status);
    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>{`#${orderNumber}`}</Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {`${cms("label.creationDate")}: ${baseHelper.formatDate(createdAt || shopifyOrderDate, true)}`}
              </TextStyle>
              <br />
              <div className="hidden" title={vendor}>
                {`${cms("label.vendor")}: `}
                {(isVendor && vendor) || <Link url={`/providers/view/${vendorId}`}>{vendor}</Link>}
              </div>
              <TextStyle>
                {` ${cms("label.commission")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}
              </TextStyle>
              <br />
              {priceTaxRate && !commissionTax && !isOrderBasedCommission ? (
                <TextStyle>
                  {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(
                    getItemCommissionTax()
                  )} (Incl.)`}
                </TextStyle>
              ) : (
                <TextStyle>
                  {` ${cms("label.commissionTax")}: ${moneyFormat} ${baseHelper.getPrice(commissionTax)}`}
                </TextStyle>
              )}
              <br />
              <TextStyle>
                {` ${cms("label.vendorPayout")}: ${moneyFormat} ${baseHelper.getPrice(totalOrderPayout)}`}
              </TextStyle>
              <br />
              {!!shippingAmount && (
                <>
                  <TextStyle>
                    {`${cms("label.shipping")}: ${moneyFormat} ${baseHelper.getPrice(totalShippingAmount)}`}
                  </TextStyle>
                  <br />
                </>
              )}
              {!(isReserve || isCollect) && (
                <>
                  <TextStyle>{`${cms("label.dueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>
                  <br />
                </>
              )}
              {/* {!paidVia && (
                <TextStyle>{`${cms("label.manual")}: ${baseHelper.formatDate(createdAt, false)}`}</TextStyle>
              )} */}
            </Caption>
          </Stack.Item>
          {isInvoiceEnabled && (
            <Stack.Item>
              <Popover
                // eslint-disable-next-line no-underscore-dangle
                active={activePopover[_id]}
                // eslint-disable-next-line no-underscore-dangle
                setActive={() =>
                  popoverDisable ? setActivePopover(false) : setActivePopover({ [_id]: !activePopover[_id] })}
                options={[
                  {
                    content: cms("label.invoice"),
                    icon: ReceiptMajor,
                    onAction: () => setInvoiceModalActive(true),
                    disabled: [REJECTED, constant.AUTO_REJECT, constant.NO_SHOW].includes(status) || rejectedByVendor,
                  },
                ]}
              />
            </Stack.Item>
          )}
        </Stack>
        <Stack.Item style={topMargin10px}>
          <br />
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              {isAcceptedByOperator && (
                        <Stack.Item>
                            <Badge status={baseHelper.getBadgeType("accepted by operator")}>Accepted By Operator</Badge>
                        </Stack.Item>
                      )}
                      {isFulfilledByOperator && (
                        <Stack.Item>
                            <Badge status={baseHelper.getBadgeType("fulfilled by operator")}>
                              Fulfilled By Operator
                            </Badge>
                        </Stack.Item>
                      )}
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
              {isLineItemRefunded && (
                <Stack.Item>
                  <Badge status={constant.CRITICAL}>Refunded</Badge>
                </Stack.Item>
              )}
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>
      {invoiceModalActive && (
        <InvoiceModal
          active={invoiceModalActive}
          handleClose={() => setInvoiceModalActive(!invoiceModalActive)}
          id={id}
          setActive={setInvoiceModalActive}
          item={item}
          setBanner={setBanner}
        />
      )}
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;
