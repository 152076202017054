import { gql } from "apollo-boost";

export const GET_ALL_COUNTS = gql`
  query getCount {
    getCount {
      data {
        inviteCount {
          joined
          notJoined
          total
        }
        orderCount {
          approved
          new
          rejected
          shipped
          total
        }
        paymentCount {
          all
          paid
          unpaid
        }
        productCount {
          approved
          delete
          new
          reject
          review
          total
        }
        sellerCount {
          total
          approved
          new
        }
        vendorCount {
          approved
          new
          total
        }
        shopifyProductCount {
          all
          synced
          notSynced
        }
        prestaShopTempProductCount {
          product
        }
        magentoTempProductCount {
          product
        }
        wooTempProductCount {
          product
        }
        wixTempProductCount {
          product
        }
      }
      error
      status
    }
  }
`;

export const GET_PRODUCT_COUNTS = gql`
  query productCount {
    productCount {
      data {
        productCount {
          _id
          approved
          delete
          new
          reject
          review
          total
          user
        }
      }
      error
      status
    }
  }
`;

export const GET_LAKE_COUNTS = gql`
  query getLakeCount {
    getLakeCount {
      data {
        inviteCount {
          joined
          notJoined
          total
        }
        vendorCount {
          approved
          new
          total
        }
        productCount {
          publish
          total
          new
          approved
          review
        }
        orderCount {
          completed
          pending
          processing
          refunded
          total
        }
      }
      error
      status
    }
  }
`;
