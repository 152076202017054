import { gql } from "apollo-boost";

const UPDATE_TRANSACTION_FEE_SETTING = gql`
  mutation setTransactionFee($input: TransactionFee!) {
    setTransactionFee(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_TRANSACTION_FEE_SETTING;
