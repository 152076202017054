import _ from "lodash";
import React, { useContext } from "react";
import { Caption, Card, FormLayout, InlineError, Layout, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

// constant
import constant from "lib/constant/constant";
import ImageComponent from "lib/components/dropZone/dropZone";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

const BrandSection = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    brandNameData,
    brandHandleData,
    brandInformation,
    errorMessage,
    handleChange,
    handleValidation,
    isAdvanceProfileHidden,
    // learnMore,
    lastUpdated,
  } = props;
  const imageUrl = brandInformation && brandInformation.brandImage;
  const coverImageUrl = brandInformation && brandInformation.brandCoverImage;

  if (errorMessage && errorMessage.description) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const getLabelData = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  const getPlaceholderText = (value) => {
    if (value && value.label) {
      return value.label;
    }
    return "";
  };

  const getFields = () => {
    return (
      <FormLayout>
        <TextField
          id={brandInformation.brandName}
          label={`${getLabelData(brandNameData, cms("section.brand.label.name"))}*`}
          placeholder={getPlaceholderText(brandNameData) || cms("section.brand.placeholder.name")}
          value={brandInformation.brandName}
          onChange={(value) => handleChange(constant.BRAND_NAME, value)}
          onBlur={() =>
            handleValidation(
              constant.BRAND_NAME,
              brandInformation.brandName,
              getLabelData(brandNameData, cms("section.brand.label.name"))
            )
          }
          error={errorMessage && errorMessage.brandName}
        />
        <TextField
          id="BrandHandle"
          label={`${getLabelData(brandHandleData, cms("section.brand.label.handle"))}*`}
          placeholder={getPlaceholderText(brandHandleData) || cms("section.brand.placeholder.handle")}
          value={brandInformation.slug}
          onChange={(value) => handleChange(constant.BRAND_SLUG, value)}
          onBlur={() =>
            handleValidation(
              constant.BRAND_SLUG,
              brandInformation.slug,
              getLabelData(brandHandleData, cms("section.brand.label.handle"))
            )
          }
          error={errorMessage && errorMessage.slug}
        />
        {/* {!isAdvanceProfileHidden && [
          <br />,
          <TextField
            id={brandInformation.description}
            label={`${cms("section.brand.label.description")}*`}
            placeholder={cms("section.brand.placeholder.description")}
            value={brandInformation.description}
            onChange={(value) => handleChange(constant.BRAND_DESCRIPTION, value)}
            onBlur={() => handleValidation(constant.BRAND_DESCRIPTION, brandInformation.description)}
            error={errorMessage && errorMessage.description}
            multiline
          />,
        ]} */}

        {`${cms("section.brand.label.description")}*`}
        {!isAdvanceProfileHidden && (
          <>
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={brandInformation.description || ""}
              onEditorChange={(content) => handleChange(constant.BRAND_DESCRIPTION, content)}
              onBlur={() => handleValidation(constant.BRAND_DESCRIPTION, brandInformation.description)}
              error={errorMessage && errorMessage.description}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            {errorMessage && <InlineError message={errorMessage.description} fieldID="myFieldID" />}
          </>
        )}

        {!isAdvanceProfileHidden && [
          <span className="d-block">
            {cms("section.brand.label.logo")}
            <small>(recommended size 200px X 110px)</small>
          </span>,
          <ImageComponent
            onAdd={(value) => handleChange(constant.BRAND_IMAGE, value)}
            allowMultiple={false}
            fileList={imageUrl !== "" ? (_.isString(imageUrl) && [imageUrl]) || imageUrl : []}
            onRemove={() => handleChange(constant.BRAND_IMAGE, "")}
            size={1}
          />,
          <span className="d-block">
            {cms("section.brand.label.coverImage")}
            <small>(recommended size 2000px X 400px)</small>
          </span>,
          <ImageComponent
            onAdd={(value) => handleChange(constant.BRAND_COVER_IMAGE, value)}
            allowMultiple={false}
            fileList={coverImageUrl !== "" ? (_.isString(coverImageUrl) && [coverImageUrl]) || coverImageUrl : []}
            onRemove={() => handleChange(constant.BRAND_COVER_IMAGE, "")}
            size={1}
          />,
        ]}
      </FormLayout>
    );
  };
  return (
    <Layout.AnnotatedSection title={cms("section.brand.title")} description={cms("section.brand.description")}>
      <Card
        title={[
          cms("section.brand.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore(cms("section.brand.title"), "TODO:");
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.brand.description")}</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

BrandSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  isAdvanceProfileHidden: PropTypes.bool.isRequired,
  brandInformation: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    brandImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    brandCoverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  // learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

BrandSection.defaultProps = {
  errorMessage: PropTypes.bool,
  lastUpdated: "",
};

export default BrandSection;
