import { gql } from "apollo-boost";

const GET_PAYMENT_EXPORT_DATA = gql`
  query getPaymentExportData {
    getPaymentExportData {
      data {
        vendors {
          id
          name
        }
        accountingStatuses
      }
      status
      error
    }
  }
`;

export default GET_PAYMENT_EXPORT_DATA;
