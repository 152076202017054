import React from "react";
import PropTypes from "prop-types";
import { Spinner as PolarisSpinner } from "@shopify/polaris";

const Spinner = (props) => {
  const { isFullPage = false, size } = props;
  const styleClassName = isFullPage ? "fullPageSpinner" : "spinner";
  return (
    <div className={styleClassName}>
      <PolarisSpinner accessibilityLabel="Spinner" size={size || "large"} color="teal" />
    </div>
  );
};

Spinner.propTypes = {
  isFullPage: PropTypes.bool,
  size: PropTypes.string,
};
Spinner.defaultProps = {
  isFullPage: false,
  size: "large",
};

export default Spinner;
