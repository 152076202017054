import React, { useState, useContext } from "react";
import { Form, FormLayout, Card, Checkbox, Layout } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers Components
import { CardFooter, Banner, Spinner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { UPDATE_COMMISSION } from "app/setup/apollo/mutations";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import propTypes
import { taxProp } from "app/setup/modules/operator/features/commission/props";

const Tax = (props) => {
  const {
    advanceCommissionValue,
    refetchLoading,
    standardCommissionValue,
    rangeCommissionValue,
    priorityValue,
    isTaxInclusive,
    setIsTaxInclusive,
  } = props || {};

  const {
    gql: { UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
  } = constant || {};
  const { cms = {} } = useContext(PrivateContext);

  // states
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  // mutation call
  const [updateCommission, { loading }] = useMutation(UPDATE_COMMISSION);

  if (refetchLoading) {
    return (
      <Layout.Section>
        <Spinner />
      </Layout.Section>
    );
  }

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const mapRangeCommission = () => {
    return rangeCommissionValue.map((obj) => {
      const { min, ...rest } = obj;
      return rest;
    });
  };

  const mapAdvanceCommissionPrice = () => {
    return advanceCommissionValue.advancedValues.map((item) => {
      const updatedItem = { ...item };
      if (item.price) {
        updatedItem.price = parseFloat(item.price);
      }
      return updatedItem;
    });
  };

  const updateIsTaxInclusive = async (requestData) => {
    try {
      const res = await updateCommission({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        return;
      }
      setSubmitEnable(false);
      setBanner({ isOpen: true, status: "success", title: cms("message.success") });
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const handleSubmit = () => {
    if (!submitEnabled) {
      return;
    }
    const isAnyAdvanceValue =
      advanceCommissionValue &&
      advanceCommissionValue.advancedValues &&
      Array.isArray(advanceCommissionValue.advancedValues) &&
      advanceCommissionValue.advancedValues.length !== 0;

    const isAnyRangeValue =
      rangeCommissionValue && Array.isArray(rangeCommissionValue) && rangeCommissionValue.length !== 0;

    const requestData = {
      type: standardCommissionValue.selectedStandardType,
      price: parseFloat(standardCommissionValue.standardAmount),
      advancedValues: (isAnyAdvanceValue && mapAdvanceCommissionPrice()) || [],
      rangeValues: (isAnyRangeValue && mapRangeCommission()) || [],
      ruleBy: priorityValue,
      isTaxInclusive,
    };

    updateIsTaxInclusive(requestData);
  };

  return (
    <>
      <Card.Section>
        {banner.isOpen && (
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            isScrollTop={false}
            onDismiss={() => dismissBanner()}
          >
            {banner.message}
          </Banner>
        )}
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Checkbox
              checked={isTaxInclusive}
              label="Charge commission on product price + GST"
              // eslint-disable-next-line max-len
              helpText="This will enable you to charge commission on product price and GST value rather than only product price. If you have 100 as product price and 15 as GST, the commission will be calculated on 100 + 15 i.e, 115 rather than 100."
              onChange={() => {
                setSubmitEnable(true);
                setIsTaxInclusive(!isTaxInclusive);
              }}
            />
          </FormLayout>
          <CardFooter disabled={!submitEnabled} loading={loading || false} />
        </Form>
      </Card.Section>
    </>
  );
};

Tax.propTypes = taxProp.type;

export default Tax;
