import PropTypes from "prop-types";

const featurePanelModel = {
  featureToEdit: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  updateFeature: PropTypes.func.isRequired,
  editFeatureLoading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFeatureToEdit: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  handleSellers: PropTypes.func.isRequired,
  handleVendors: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  sellers: PropTypes.array.isRequired,
};

export default featurePanelModel;
