import { gql } from "apollo-boost";

const ACCOUNT_LIST = gql`
  query getAccountList($input: GetListInputV1) {
    getAccountList(input: $input) {
      data {
        count
        paymentList {
          _id
          accountingStatus
          actualQuantity
          commissionSeller
          commissionSysAdmin
          commissionTax
          commissionVendor
          createdAt
          dueDate
          fulfillmentStatus
          fulfillmentType
          isAutoExpiry
          isExpressPayment
          isAutoReject
          isOrderBasedCommission
          isRefund
          isRefunded
          isShippingPaid
          moneyFormat
          orderId
          orderNumber
          paidVia
          price
          priceTaxRate
          quantity
          rejectedByVendor
          returnedQuantity
          shippingAmount
          shippingTax
          shopifyOrderDate
          status
          vendor
          vendorDiscount
          vendorId
        }
      }
      status
      error
    }
  }
`;

export default ACCOUNT_LIST;
