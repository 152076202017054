// import react packages
import React, { useState } from "react";
import { useMutation } from "react-apollo";

// Import Polaris Components
import { Button, Modal } from "@shopify/polaris";

// import helpers
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";

// import gql
import { DISCONNECT_SHOP } from "app/product/apollo/mutations";

// import props
import { connectedShopProp } from "app/product/modules/generic/propTypes";
import { Link } from "react-router-dom";

const ConnectedShop = (props) => {
  const { currentUserData, setBanner, setIsConnected, setShop, cms, setDisconnectStatus} = props;
  const { DISCONNECT_SHOP: DISCONNECT } = constant.gql;

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);

  const [disconnectShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_SHOP);

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectShop({
        variables: { input: { id: currentUserData.id } },
      });
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("section.connect.message.success.disconnected") });
      setIsConnected(false);
      setShop("");
      setDisconnectStatus(true);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleDisconnectModal = () => {
    setIsDisconnectActive(!isDisconnectActive);
  };

  return (
    <>
      <Modal
        open={isDisconnectActive}
        onClose={handleDisconnectModal}
        title={cms("section.connect.label.disconnectShop")}
        primaryAction={{
          content: `${cms("section.connect.label.disconnectShop")} ${currentUserData.shop}`,
          onAction: disconnectShopConfirm,
          destructive: true,
          loading: disconnectLoading,
        }}
        secondaryActions={[
          {
            content: cms("section.connect.button.cancel"),
            onAction: handleDisconnectModal,
          },
        ]}
      >
        <Modal.Section>{`${cms("section.connect.label.disconnectWoocommerceShop")} ?`}</Modal.Section>
      </Modal>
      <Link className="redColor" id="disconnect" onClick={handleDisconnectModal}>
        {cms("section.connect.label.disconnectShop")}
      </Link>
    </>
  );
};

ConnectedShop.propTypes = connectedShopProp;

export default ConnectedShop;
