import constant from "lib/constant/constant";

const cmsSellerList = (cms) => {
  const cmsData = {
    resourceName: {
      singular: cms("resourceList.singular"),
      plural: cms("resourceList.plural"),
    },
    label: {
      show_all_seller: cms("label.showAllSeller"),
      select_filter: cms("label.selectFilter"),
      like: cms("label.like"),
      filter_by: cms("label.filterBy"),
      delete_selected: cms("resourceList.promotedBulkActions.deleteSelected"),
      search: cms("label.search"),
      tabKey: cms("label.tabKey"),
      sort_name: cms("label.sortName"),
      sort_order: cms("label.sortOrder"),
      perPage: cms("label.perPage"),
      list_filter: cms("label.listFilter"),
      list_search: cms("label.listSearch"),
      filter: cms("label.filter"),
      total: cms("label.total"),
      deleteModal: cms("label.deleteModal"),
      delete: cms("label.delete"),
      primaryDelete: cms("label.primaryDelete"),
      secondaryDelete: cms("label.secondaryDelete"),
      reason: cms("label.reason"),
      logInModal: cms("label.logInModal"),
      logInAs: cms("label.logInAs"),
      otpModal: cms("label.otpModal"),
      fullAccess: cms("button.logIn.fullAccess"),
      onlyReadAccess: cms("label.onlyReadAccess"),
      enterCode: cms("label.enterCode"),
      viewDetailsFor: cms("label.viewDetailsFor"),
      name: cms("label.name"),
      email: cms("label.email"),
      onBoardedOn: cms("label.onBoardedOn"),
      page: cms("label.page"),
      plan: cms("label.plan"),
      latestActivity: cms("label.latestActivity"),
      featuresUpdate: cms("label.featuresUpdate"),
      updateFeatures: cms("button.updatePlan.updateFeatures"),
      maxProducts: cms("label.maxProducts"),
      maxVendors: cms("label.maxVendors"),
      branding: cms("label.branding"),
    },
    sortOptions: [
      { label: cms("resourceList.sortOptions.sortByDateAsc"), value: "createdAt_asc" },
      { label: cms("resourceList.sortOptions.sortByDateDesc"), value: "createdAt_desc" },
      { label: cms("resourceList.sortOptions.sortByShopNameAsc"), value: "brandName_asc" },
      { label: cms("resourceList.sortOptions.sortByShopNameDesc"), value: "brandName_desc" },
    ],
    message: {
      something_went_wrong: cms("message.error.somethingWentWrong"),
      running_background: cms("message.success.requestSuccess"),
      token_expire: cms("message.error.tokenExpire"),
      enterAccessCode: cms("message.error.enterAccessCode"),
      updatedPlanMessage: cms("message.success.updatedPlanMessage"),
    },
    promotedBulkActions: [
      {
        content: cms("resourceList.promotedBulkActions.bulkAction"),
      },
    ],
    button: {
      deleteFromMc: cms("button.deleteOperator.deleteFromMc"),
      deleteFromShop: cms("button.deleteOperator.deleteFromShop"),
      confirmOtp: cms("button.accessCodeVerify.confirmOtp"),
      resendOtp: cms("button.accessCodeVerify.resendOtp"),
      readOnly: cms("button.accessCodeVerify.readOnly"),
      updatePlan: cms("button.updatePlan.updatePlan"),
      loginSeller: cms("button.logIn.loginSeller"),
    },
    sectionContent: {
      deleteSellerSelected: cms("sectionContent.deleteSellerSelected"),
      logInAsAccess: cms("sectionContent.logInAsAccess"),
      otpNote: cms("sectionContent.otpNote"),
    },
    limitOptions: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
    filterOptions: [{ label: cms("resourceList.filterOptions.brand"), value: constant.BRAND_NAME }],
    // tabs: [
    //   {
    //     id: "all-operators",
    //     content: cms("tabs.all"),
    //     accessibilityLabel: "All operators",
    //     panelID: "all-operators-content",
    //     value: "all",
    //   },
    //   {
    //     id: "pending-approval",
    //     content: cms("tabs.pendingApproval"),
    //     panelID: "pending-approval-content",
    //     value: "pending",
    //   },
    //   {
    //     id: "approved",
    //     content: cms("tabs.approved"),
    //     panelID: "approved-content",
    //     value: "approved",
    //   },
    //   {
    //     id: "free",
    //     content: cms("tabs.startUp"),
    //     panelID: "free-content",
    //     value: "free",
    //   },
    //   {
    //     id: "bootstrap",
    //     content: cms("tabs.bootstrap"),
    //     panelID: "bootstrap-content",
    //     value: "bootstrap",
    //   },
    //   {
    //     id: "growth",
    //     content: cms("tabs.growth"),
    //     panelID: "growth-content",
    //     value: "growth",
    //   },
    //   {
    //     id: "pro",
    //     content: cms("tabs.pro"),
    //     panelID: "pro-content",
    //     value: "pro",
    //   },
    //   {
    //     id: "trial",
    //     content: cms("tabs.trial"),
    //     panelID: "trial-content",
    //     value: "trial",
    //   },
    // ],
    filterByStatusOption: [
      {
        label: cms("tabs.all"),
        value: "all",
      },
      {
        id: "pending-approval",
        label: cms("tabs.pendingApproval"),
        value: "pending",
      },
      {
        id: "approved",
        label: cms("tabs.approved"),
        value: "approved",
      },
      {
        id: "free",
        label: cms("tabs.startUp"),
        value: "free",
      },
      {
        id: "bootstrap",
        label: cms("tabs.bootstrap"),
        value: "bootstrap",
      },
      {
        id: "growth",
        label: cms("tabs.growth"),
        value: "growth",
      },
      {
        id: "pro",
        label: cms("tabs.pro"),
        value: "pro",
      },
      {
        id: "customPlan",
        label: cms("tabs.customPlan"),
        value: "customPlan",
      },
      {
        id: "trial",
        label: cms("tabs.trial"),
        value: "trial",
      },
      {
        id: "enabled",
        label: cms("tabs.enabled"),
        value: "enabled",
      },
      {
        id: "disabled",
        label: cms("tabs.disabled"),
        value: "disabled",
      },
    ],
  };

  return cmsData;
};

export default cmsSellerList;
