const publicPageContent = {
  bigCommerceInstall: {},
  bigCommercePlan: {},
  linkRegister: {},
  login: {},
  plan: {},
  register: {},
  registerRole: {},
  registerShop: {},
  resendVerifyEmail: {},
  resetPassword: {},
  shopifyPayment: {},
  shopifyRedirect: {},
  verifyEmail: {},
  wooCommerceInstall: {},
  wooCommercePlan: {},
};

export default publicPageContent;
