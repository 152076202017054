import * as yup from "yup";

const validateData = {};
const schema = (message) => {
  return yup.object().shape({
    radioGroup: yup.string().required(message.method).nullable(),
  });
};

export { validateData, schema };
