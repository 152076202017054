import React, { useContext } from "react";
import { PrivateContext } from "lib/context/privateContext";

import { storageHelper } from "lib/helpers";
import { SingleColumnLayout } from "layout/private/components";
import constant from "lib/constant/constant";

import { SkeletonAnnotated } from "lib/components";
import { AdminSellerList } from "./admin";

const { userKey } = constant;
const { admin } = userKey;

const SellerList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminSellerList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default SellerList;
