export const vendorMock = [
  { name: "Vendor A", value: 400 },
  { name: "Vendor B", value: 300 },
  { name: "Vendor C", value: 300 },
  { name: "Vendor D", value: 200 },
];

export const productMock = [
  {
    name: "Jan",
    product: 1000,
    revenue: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    product: 700,
    revenue: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    product: 800,
    revenue: 5800,
    amt: 2290,
  },
  {
    name: "Apr",
    product: 980,
    revenue: 3908,
    amt: 2000,
  },
  {
    name: "May",
    product: 950,
    revenue: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    product: 260,
    revenue: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    product: 370,
    revenue: 4300,
    amt: 2100,
  },
];

export const salesMock = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];

export const orderMock = [
  {
    name: "Jan",
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490,
  },
  {
    name: "Feb",
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590,
  },
  {
    name: "Mar",
    uv: 1397,
    pv: 1098,
    amt: 989,
    cnt: 350,
  },
  {
    name: "Apr",
    uv: 1480,
    pv: 1200,
    amt: 1228,
    cnt: 480,
  },
  {
    name: "May",
    uv: 1520,
    pv: 1108,
    amt: 1100,
    cnt: 460,
  },
  {
    name: "Jun",
    uv: 1400,
    pv: 680,
    amt: 1700,
    cnt: 380,
  },
];
