import { indexOf } from "lodash";

class ProductHelper {
  /**
   * @desc To return product csv data
   * @param productDetails array of productData
   *
   * @return csv data
   *
   */
  productCSVData = (productDetailData = [], settingResponse = {}, isCsv = false, isPdf = false, isVendor = false, {}, isExport = false) => { // {} geoLocatiobn
    const settingData = settingResponse.export;
    const {
      handle,
      title,
      body,
      vendor,
      type,
      tags,
      collectionData,
      published,
      option1Name,
      option1Value,
      option2Name,
      option2Value,
      option3Name,
      option3Value,
      variantSku,
      variantWeight,
      variantInventoryTracker,
      variantInventoryQty,
      variantInventoryPolicies,
      variantPrice,
      variantCompareAtPrice,
      variantRequiresShipping,
      variantTaxable,
      variantBarcode,
      imageSrc,
      imageAtText,
      giftCard,
    } = settingData || {};
    const productObj = {
      handle: (handle && handle.label) || "Handle",
      title: (title && title.label) || "Title",
      body: (body && body.label) || "Body(HTML)",
      vendor: (vendor && vendor.label) || "Vendor",
      type: (type && type.label) || "Type",
      tags: (tags && tags.label) || "Tags",
      collectionData: (collectionData && collectionData.label) || "Collection",
      published: (published && published.label) || "Published",
      option1Name: (option1Name && option1Name.label) || "Option 1 Name",
      option1Value: (option1Value && option1Value.label) || "Option 1 Value",
      option2Name: (option2Name && option2Name.label) || "Option 2 Name",
      option2Value: (option2Value && option2Value.label) || "Option 2 Value",
      option3Name: (option3Name && option3Name.label) || "Option 3 Name",
      option3Value: (option3Value && option3Value.label) || "Option 3 Value",
      variantSku: (variantSku && variantSku.label) || "Variant SKU",
      variantWeight: (variantWeight && variantWeight.label) || "Variant Weight",
      variantInventoryTracker:
        (variantInventoryTracker && variantInventoryTracker.label) || "Variant Inventory Tracker",
      variantInventoryQty: (variantInventoryQty && variantInventoryQty.label) || "Variant Inventory Qty",
      variantInventoryPolicies:
        (variantInventoryPolicies && variantInventoryPolicies.label) || "Variant  Inventory Policy",
      variantPrice: (variantPrice && variantPrice.label) || "Variant Price",
      variantCompareAtPrice: (variantCompareAtPrice && variantCompareAtPrice.label) || "Variant Compare At Price",
      variantRequiresShipping:
        (variantRequiresShipping && variantRequiresShipping.label) || "Variant  Requires Shipping",
      variantTaxable: (variantTaxable && variantTaxable.label) || "Variant Taxable",
      variantBarcode: (variantBarcode && variantBarcode.label) || "Variant Barcode",
      imageSrc: (imageSrc && imageSrc.label) || "Image Src",
      imageAtText: (imageAtText && imageAtText.label) || "Image Alt Text",
      giftCard: (giftCard && giftCard.label) || "Gift Card",
    };

    const updatedArray = [];
    if (settingData) {
      const notAllowedCsvKeys = Object.keys(settingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = settingData[item] && settingData[item].isHideCsv;
        }

        if (isPdf) {
          hideArray = settingData[item] && settingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedCsvKeys.map((key) => {
        updatedArray.push(productObj[key]);
        return false;
      });
    }

    const productArray = [
      (handle && handle.label) || "Handle",
      (title && title.label) || "Title",
      (body && body.label) || "Body(HTML)",
      (vendor && vendor.label) || "Vendor",
      (type && type.label) || "Type",
      (tags && tags.label) || "Tags",
      (collectionData && collectionData.label) || "Collection",
      (published && published.label) || "Published",
      (option1Name && option1Name.label) || "Option 1 Name",
      (option1Value && option1Value.label) || "Option 1 Value",
      (option2Name && option2Name.label) || "Option 2 Name",
      (option2Value && option2Value.label) || "Option 2 Value",
      (option3Name && option3Name.label) || "Option 3 Name",
      (option3Value && option3Value.label) || "Option 3 Value",
      (variantSku && variantSku.label) || "Variant SKU",
      (variantWeight && variantWeight.label) || "Variant Weight",
      (variantInventoryTracker && variantInventoryTracker.label) || "Variant Inventory Tracker",
      (variantInventoryQty && variantInventoryQty.label) || "Variant Inventory Qty",
      (variantInventoryPolicies && variantInventoryPolicies.label) || "Variant  Inventory Policy",
      (variantPrice && variantPrice.label) || "Variant Price",
      (variantCompareAtPrice && variantCompareAtPrice.label) || "Variant Compare At Price",
      (variantRequiresShipping && variantRequiresShipping.label) || "Variant  Requires Shipping",
      (variantTaxable && variantTaxable.label) || "Variant Taxable",
      (variantBarcode && variantBarcode.label) || "Variant Barcode",
      (imageSrc && imageSrc.label) || "Image Src",
      (imageAtText && imageAtText.label) || "Image Alt Text",
      (giftCard && giftCard.label) || "Gift Card",
      isExport ? "Mark up" : "",
      isExport ? "Seller Discount" : "",
      isExport && isVendor ? "_id" : "",
      isExport && !isVendor ? "Product Id" : "",
    ];

    const removeIndex = [];

    updatedArray.map((arr) => {
      removeIndex.push(productArray.indexOf(arr));
      return false;
    });

    const differenceArray = productArray.filter((item) => !updatedArray.includes(item));

    const newProductArray = [];

    newProductArray[0] = differenceArray;

    productDetailData.forEach((item, idx) => {
      if (item.variants.length > 0) {
        let imageAtTextValue = "";
        item.variants.forEach((variantValue, idxVariants) => {
          const titleValue = idxVariants === 0 ? item.title : "";
          const bodyValue = idxVariants === 0 ? item?.body?.replace(/<[^>]+>/g, "") : "";
          const vendorName = idxVariants === 0 ? item.vendor : "";
          const typeValue = idxVariants === 0 ? item.type : "";
          const tagValue = idxVariants === 0 ? item.tags : "";
          const collectionDataValue = idxVariants === 0 ? item.collectionData : "";
          const publishedValue = idxVariants === 0 ? item.published : "";
          const imageSrcValue = idxVariants === 0 ? item.imageSrc : "";
          if (item.imageSrc) {
            imageAtTextValue = idxVariants === 0 ? `Image of ${item.title}` : "";
          }
          const giftCardValue = idxVariants === 0 ? item.giftCard : "";
          const {
            option1Name: option1NameValue,
            option1Value: optionOneValue,
            option2Name: option2NameValue,
            option2Value: optionTwoValue,
            option3Name: option3NameValue,
            option3Value: optionThreeValue,
            variantSKU: variantSKUValue,
            variantWeight: variantWeightValue,
            variantInventoryTracker: variantInventoryTrackerValue,
            variantInventoryQty: variantInventoryQtyValue,
            variantInventoryPolicy: variantInventoryPolicyValue,
            variantPrice: variantPriceValue,
            variantCompareAtPrice: variantCompareAtPriceValue,
            variantRequiresShipping: variantRequiresShippingValue,
            variantTaxable: variantTaxableValue,
            variantBarcode: variantBarcodeValue,
            markUp: variantMarkUpValue,
            sellerDiscount: variantSellerDiscountValue,
          } = variantValue;

          newProductArray.push(
            [
              item.handle || "",
              titleValue || "",
              bodyValue || "",
              vendorName || "",
              typeValue || "",
              tagValue || "",
              collectionDataValue || "",
              publishedValue || "",
              option1NameValue || "",
              optionOneValue || "",
              option2NameValue || "",
              optionTwoValue || "",
              option3NameValue || "",
              optionThreeValue || "",
              variantSKUValue || "",
              variantWeightValue || "",
              variantInventoryTrackerValue || "",
              variantInventoryQtyValue || "",
              variantInventoryPolicyValue || "",
              variantPriceValue || "",
              variantCompareAtPriceValue || "",
              variantRequiresShippingValue || false,
              variantTaxableValue || false,
              variantBarcodeValue || "",
              imageSrcValue || "",
              imageAtTextValue || "",
              giftCardValue || "",
              (isExport && variantMarkUpValue && variantMarkUpValue.price) || "",
              (isExport && variantSellerDiscountValue && variantSellerDiscountValue.price) || "",
              (isExport && isVendor && item._id) || "",
              (isExport && !isVendor && item.productId) || "",
            ].filter((value, index) => {
              return removeIndex.indexOf(index) === -1;
            })
          );
        });
      } else {
        let imageAtTextData = "";
        if (item.imageSrc) {
          imageAtTextData = `Image of ${item.title}` || "";
        }
        newProductArray.push(
          [
            item.handle || "",
            item.title || "",
            item?.body?.replace(/<[^>]+>/g, "") || "",
            item.vendor || "",
            item.type || "",
            item.tags || "",
            item.collectionData || "",
            item.published || "",
            item.option1NameValue || "",
            item.optionOneValue || "",
            item.option2NameValue || "",
            item.optionTwoValue || "",
            item.option3NameValue || "",
            item.optionThreeValue || "",
            item.variantSKUValue || "",
            item.variantWeightValue || "",
            item.variantInventoryTrackerValue || "",
            item.variantInventoryQtyValue || "",
            item.variantInventoryPolicyValue || "",
            item.variantPriceValue || "",
            item.variantCompareAtPriceValue || "",
            item.variantRequiresShippingValue || "",
            item.variantTaxableValue || "",
            item.variantBarcodeValue || "",
            item.imageSrc || "",
            imageAtTextData || "",
            item.giftCard || "",
          ].filter((value, index) => {
            return removeIndex.indexOf(index) === -1;
          })
        );
      }
    });
    return newProductArray;
  };
}

export default new ProductHelper();
