import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  Button,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  Frame,
  Layout,
  PageActions,
  Select,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant , helper and components
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { DIGEST_EMAIL_SETTING } from "app/userManagement/apollo/mutation";
import { GET_DIGEST_SETTING } from "app/userManagement/apollo/queries";

import StyledStack, { StyledSelect, StyledSelectWidth, StyledPageAction } from "./styled";

const DigestSetting = () => {
  const { cms, currentUser, history, vendorHiddenModuleKeys = [], vendorReadOnlyModule = [] } = useContext(
    PrivateContext
  );

  const defaultStatus = {
    order: constant.ORDER_STATUS,
    product: constant.PRODUCT_STATUS,
    vendor: constant.VENDOR_STATUS,
  };

  const defaultModule = [constant.PRODUCT, constant.ORDER, constant.VENDOR];
  const { gql } = constant;

  const [moduleList, setModuleList] = useState(defaultModule);
  const [moduleStatus, setModuleStatus] = useState(defaultStatus);

  const [durationType, setDurationType] = useState([constant.WEEKLY]);
  const [days, setDays] = useState([constant.DAY[5]]);
  const [allDay, setAllDay] = useState(constant.DAY);

  const [duration, setDuration] = useState();

  const [date, setDate] = useState(1);

  const [createdAt, setCreatedAt] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [isDayUpdate, setIsDayUpdate] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [selectedModule, setSelectedModule] = useState([
    {
      activityType: constant.PRODUCT,
      status: constant.PRODUCT_STATUS,
    },
    {
      activityType: constant.ORDER,
      status: constant.ORDER_STATUS,
    },
    {
      activityType: constant.VENDOR,
      status: constant.VENDOR_STATUS,
    },
  ]);

  const isVendor = baseHelper.isVendor(currentUser);

  const { data: digestData, loading: digestLoading, error: digestError, refetch } = useQuery(GET_DIGEST_SETTING);

  const [updateDigestSetting, { loading }] = useMutation(DIGEST_EMAIL_SETTING);

  const isEnable = duration || duration === 0;

  const handleChange = useCallback((value) => {
    setDurationType(value);
    if (value[0] === constant.DAILY) {
      setDuration(12);
    }
    if (value[0] === constant.WEEKLY) {
      setDuration(1);
      setDays([constant.DAY[5]]);
    }
    if (value[0] === constant.MONTHLY) {
      setDuration(1);
      setDate(1);
    }
  }, []);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(digestData, gql.GET_DIGEST_SETTING);
    const responseError = baseHelper.getResponseError(digestData, gql.GET_DIGEST_SETTING);
    if (responseData) {
      const {
        activity,
        date: digestDate,
        day,
        duration: digestDuration,
        durationType: digestDurationType,
        updatedAt,
      } = responseData;
      setSelectedModule((activity && activity.length && activity) || selectedModule);
      setDate(digestDate || date);
      setDays((day && day.length && day) || [constant.DAY[5]]);
      setDuration(digestDuration);
      setDurationType([digestDurationType || constant.WEEKLY]);
      setIsSubmit(!!digestDuration);
      setCreatedAt(updatedAt || "");
    }
    if (responseError) {
      setBanner({ status: constant.CRITICAL, title: responseError, isOpen: true });
    }

    if (digestError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(digestError), isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digestData, digestError]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  // set selected modules
  const handleModule = useCallback(
    (select) => {
      const prevData = selectedModule;
      const index = prevData.findIndex((item) => item.activityType === select);
      const isExist = index >= 0;
      if (isExist) {
        prevData.splice(index, 1);
      }
      if (!isExist) {
        prevData.push({
          activityType: select,
          status: defaultStatus[select],
        });
      }
      setSelectedModule(prevData);
      setModuleList(defaultModule);
    },
    [selectedModule, defaultModule, defaultStatus]
  );

  // set selected status of modules
  const handleStatus = useCallback(
    (status, updateData) => {
      const prevData = selectedModule;
      const index = prevData.findIndex((item) => item.activityType === updateData);
      const data = prevData[index];
      const isExist = data.status && data.status.includes(status);
      if (isExist) {
        const newStatus = (data.status && data.status.filter((item) => item !== status)) || [];
        data.status = newStatus;
      }
      if (!isExist) {
        data.status.push(status);
      }
      prevData[index] = data;
      setSelectedModule(prevData);
      setModuleStatus(defaultStatus);
    },
    [selectedModule, defaultStatus]
  );

  // set selected days
  const handleDays = useCallback(
    (day) => {
      let prevData = days;
      const isExist = prevData.includes(day);
      if (isExist) {
        prevData = prevData.filter((item) => item !== day);
      }
      if (!isExist) {
        prevData.push(day);
      }
      setDays(prevData);
      setIsDayUpdate(true);
    },
    [days]
  );

  // re-render component when day update
  useEffect(() => {
    if (isDayUpdate) {
      setAllDay(constant.DAY);
      setIsDayUpdate(false);
    }
  }, [isDayUpdate]);

  const handleSubmit = async (isDisable = false) => {
    if (isDisable) {
      setDisableLoading(true);
    }
    if (!isDisable && durationType && durationType[0] === constant.WEEKLY && !days.length) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.day") });
      return null;
    }
    if (!isDisable && selectedModule && !selectedModule.length) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.module") });
      return null;
    }
    let userModule = selectedModule;
    if (!isDisable && selectedModule && selectedModule.length) {
      let isError = false;
      if (isVendor) {
        userModule = userModule.filter((item) => item.activityType !== constant.VENDOR);
      }
      userModule.map((item) => {
        if (item.status && !item.status.length) {
          isError = true;
        }
        return null;
      });
      if (isError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.activity") });
        return null;
      }
    }

    const updateDuration = isEnable && !isDisable ? duration : null;

    const dataToUpdate = {
      date: date || 1,
      day: days,
      duration: updateDuration,
      durationType: durationType[0],
      activity: userModule,
    };

    try {
      const res = await updateDigestSetting({
        variables: {
          input: dataToUpdate,
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.DIGEST_SETTING);
      let bannerValue = {
        isOpen: false,
        status: "",
        title: "",
      };

      if (resData) {
        setMessage((isDisable && cms("message.success.delete")) || cms("message.success.save"));
        bannerValue = { isOpen: false, status: "", title: "" };
        refetch();
      }
      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.DIGEST_SETTING);
        bannerValue = { isOpen: true, status: constant.CRITICAL, title: error };
      }
      setDisableLoading(false);
      setBanner({
        isOpen: bannerValue.isOpen,
        status: bannerValue.status,
        title: bannerValue.title,
      });
    } catch (exception) {
      setDisableLoading(false);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
    return null;
  };

  if (digestLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <div className="toast">
          <Frame>
            <Toast message={message} />
          </Frame>
        </div>
        <Card>
          <Card.Header
            title={[
              <div>
                {cms("label.card.title")}
                <Caption>
                  {createdAt && <TextStyle variation="subdued">{baseHelper.lastUpdateDate(createdAt)}</TextStyle>}
                </Caption>
              </div>,
            ]}
          >
            {!isEnable ? (
              <Button primary onClick={() => setDuration(1)}>
                {cms("button.enable")}
              </Button>
            ) : (
              <Button
                destructive
                loading={disableLoading}
                onClick={() => {
                  if (isSubmit) {
                    handleSubmit(true);
                  }
                  if (!isSubmit) {
                    setDuration(null);
                  }
                }}
              >
                {cms("button.disable")}
              </Button>
            )}
          </Card.Header>
          <br />
        </Card>
        {isEnable && (
          <Card>
            <Card.Section>
              <Stack vertical>
                <StyledStack>
                  <ChoiceList
                    title={cms("label.receive")}
                    choices={[
                      { label: cms("label.daily"), value: constant.DAILY },
                      { label: cms("label.week"), value: constant.WEEKLY },
                      { label: cms("label.month"), value: constant.MONTHLY },
                    ]}
                    selected={durationType}
                    onChange={handleChange}
                  />
                </StyledStack>
                {durationType && durationType[0] === constant.MONTHLY && (
                  <Stack>
                    <TextStyle>{cms("label.calender")}</TextStyle>
                    <StyledStack>
                      <Stack>
                        <Stack.Item>
                          <StyledSelectWidth>
                            <Select
                              id="selectDate"
                              placeholder={cms("placeholder.date")}
                              options={baseHelper.getDate() || []}
                              value={date && String(date)}
                              onChange={(val) => setDate(parseInt(val, 10))}
                            />
                          </StyledSelectWidth>
                        </Stack.Item>
                        <StyledSelect>
                          <Stack.Item>
                            <TextStyle>{cms("label.day")}</TextStyle>
                          </Stack.Item>
                        </StyledSelect>
                      </Stack>
                    </StyledStack>
                  </Stack>
                )}
                {durationType && durationType[0] === constant.WEEKLY && (
                  <Stack>
                    <TextStyle>{cms("label.email")}</TextStyle>
                    <StyledStack>
                      <Stack vertical={false}>
                        {allDay.map((item) => {
                          return (
                            <Stack.Item key={`${item}DayStack`}>
                              <Checkbox
                                id={`${item}Day`}
                                label={cms(`label.${item}`)}
                                checked={days.includes(item)}
                                onChange={() => handleDays(item)}
                              />
                            </Stack.Item>
                          );
                        })}
                      </Stack>
                    </StyledStack>
                  </Stack>
                )}
                {!(durationType && durationType[0] === constant.MONTHLY) && (
                  <TextStyle>{cms("label.digest")}</TextStyle>
                )}
                <StyledStack>
                  <Stack>
                    <Stack.Item>
                      {(durationType && durationType[0] === constant.DAILY && (
                        <Select
                          id="selectDuration"
                          placeholder={cms("placeholder.duration")}
                          options={
                            [
                              {
                                label: cms("label.every", { hour: 1 }),
                                value: 1,
                              },
                              {
                                label: cms("label.every", { hour: 4 }),
                                value: 4,
                              },
                              {
                                label: cms("label.every", { hour: 8 }),
                                value: 8,
                              },
                              {
                                label: cms("label.every", { hour: 12 }),
                                value: 12,
                              },
                            ] || []
                          }
                          value={duration || ""}
                          onChange={(val) => setDuration(parseInt(val, 10))}
                        />
                      )) || (
                        <StyledSelectWidth>
                          <Select
                            id="selectDuration"
                            placeholder={cms("placeholder.time")}
                            options={baseHelper.getTime() || []}
                            value={(isEnable && (duration < 10 ? `0${duration}:00` : `${duration}:00`)) || ""}
                            onChange={(val) => setDuration(parseInt(val, 10))}
                          />
                        </StyledSelectWidth>
                      )}
                    </Stack.Item>
                    <StyledSelect>
                      <Stack.Item>
                        {cms("label.hour")}
                        <TextStyle variation="subdued">{` (${cms("label.zone")})`}</TextStyle>
                      </Stack.Item>
                    </StyledSelect>
                  </Stack>
                </StyledStack>
                <TextStyle>{cms("label.module")}</TextStyle>
                <StyledStack>
                  <Stack vertical>
                    <Stack.Item>
                      {moduleList.map((item) => {
                        if ((isVendor && item === constant.VENDOR) || vendorHiddenModuleKeys.includes(item)) {
                          return null;
                        }
                        const data = selectedModule.find((item1) => item1.activityType === item);
                        return (
                          <div key={`${item}ModuleStack`}>
                            <Checkbox
                              id={`${item}Module`}
                              label={cms(`label.${item}`)}
                              checked={(data && data.activityType === item) || false}
                              onChange={() => handleModule(item)}
                              disabled={vendorReadOnlyModule.includes(item)}
                            />
                            <Stack vertical>
                              {data &&
                                moduleStatus[item] &&
                                moduleStatus[item].map((status) => {
                                  return (
                                    <Stack.Item key={`${status}${item}Stack`}>
                                      <Checkbox
                                        id={`${status}${item}Checkbox`}
                                        label={cms(`label.userModule.${item}.${status}`)}
                                        checked={data && data.status && data.status.includes(status)}
                                        onChange={() => handleStatus(status, data.activityType)}
                                        disabled={vendorReadOnlyModule.includes(item)}
                                      />
                                    </Stack.Item>
                                  );
                                })}
                              <Stack.Item />
                            </Stack>
                          </div>
                        );
                      })}
                    </Stack.Item>
                  </Stack>
                </StyledStack>
              </Stack>
              {isEnable && isSubmit && (
                <StyledPageAction>
                  <PageActions
                    secondaryActions={{
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    }}
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: () => handleSubmit(),
                      disabled: !isEnable || loading,
                      loading: loading && !disableLoading,
                    }}
                  />
                </StyledPageAction>
              )}
            </Card.Section>
          </Card>
        )}
        {isEnable && !isSubmit && (
          <PageActions
            secondaryActions={{
              content: cms("common.button.cancel"),
              onAction: () => history.push("/setting"),
            }}
            primaryAction={{
              content: cms("button.save"),
              onAction: () => handleSubmit(),
              disabled: !isEnable || loading,
              loading: loading && !disableLoading,
            }}
          />
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(DigestSetting), {
  feature: constant.DIGEST_SETTING,
});
