import { baseHelper } from "lib/helpers";

const listData = (cms, user) => {
  return {
    filterData: {
      label: cms("section.orderList.label.filterVendors"),
      filters: [
        {
          key: "vendor",
          label: cms("section.orderList.label.brandName"),
          operatorText: "like",
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/order-lake/view/",
      id: "vendorId",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    productLink: {
      link: "/product-lake/edit/",
      id: "productId",
    },
    sortOptions: [
      { label: cms("section.orderList.label.sortByDateAsc"), value: "createdAt_asc" },
      { label: cms("section.orderList.label.sortByDateDesc"), value: "createdAt_desc" },
      { label: cms("section.orderList.label.sortByOrderIdAsc"), value: "orderNumber_asc" },
      { label: cms("section.orderList.label.sortByOrderIdDesc"), value: "orderNumber_desc" },
    ],
    resourceName: {
      singular: "Order",
      plural: "Orders",
    },

    tabsData: [
      {
        id: "all-orders",
        content: cms("section.orderList.label.all"),
        accessibilityLabel: "All orders",
        panelID: "all-orders-content",
        value: "all",
      },
      {
        id: "pending",
        content: cms("section.orderList.label.pending"),
        panelID: "pending-content",
        value: "pending",
      },
      {
        id: "on-hold",
        content: cms("section.orderList.label.onHold"),
        panelID: "on-hold-content",
        value: "onHold",
      },
      {
        id: "processing",
        content: cms("section.orderList.label.processing"),
        panelID: "processing-content",
        value: "processing",
      },
      {
        id: "completed",
        content: cms("section.orderList.label.completed"),
        panelID: "completed-content",
        value: "completed",
      },
      {
        id: "cancelled",
        content: cms("section.orderList.label.cancelled"),
        panelID: "cancelled-content",
        value: "cancelled",
      },
      {
        id: "failed",
        content: cms("section.orderList.label.failed"),
        panelID: "failed-content",
        value: "failed",
      },
      {
        id: "refunded",
        content: cms("section.orderList.label.refunded"),
        panelID: "refunded-content",
        value: "refunded",
      },
      {
        id: "partial",
        content: cms("section.orderList.label.partial"),
        panelID: "partial-content",
        value: "partial",
      },
    ],
    // Note the bigcommerce user status stored in DB is in upper case so we have managed on the basis of ecommerce platform
    filterByStatusOption: [
      {
        label: cms("section.orderList.label.all"),
        value: "all",
      },
      {
        label: cms("section.orderList.label.completed"),
        value: "completed",
      },
      {
        label: cms("section.orderList.label.processing"),
        value: "processing",
      },
      {
        label: cms("section.orderList.label.pending"),
        value: "pending",
      },
      {
        label: cms("section.orderList.label.onHold"),
        value: "on Hold",
      },
      {
        label: cms("section.orderList.label.cancelled"),
        value: baseHelper.isBigCommerceUser(user) ? "Cancelled" : "cancelled",
      },
      {
        label: cms("section.orderList.label.failed"),
        value: "failed",
      },
      {
        label: cms("section.orderList.label.refunded"),
        value: baseHelper.isBigCommerceUser(user) ? "Refunded" : "refunded",
      },
      // {
      //   label: cms("section.orderList.label.partial"),
      //   value: "partial",
      // },
      // {
      //   label: cms("section.orderList.label.awaitingFulfillment"),
      //   value: "Awaiting Fulfillment",
      // },
      {
        label: cms("section.orderList.label.partiallyShipped"),
        value: "Partially Shipped",
      },
      {
        label: cms("section.orderList.label.awaitingPayment"),
        value: "Awaiting Payment",
      },
    ],
    badge: ["status", "fulfillmentStatus"],
    tableData: {
      tbody: [],
    },
  };
};
export default listData;
