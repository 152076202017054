import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  mailSubjectSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
} from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const {
  BRAND_HANDLE,
  BRAND_NAME,
  CITY,
  COUNTRY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MAIL_SUBJECT,
  PHONE_NUMBER,
  POSTAL_CODE,
  PROVINCE_CODE,
  STREET_ADDRESS,
} = constant;

const validate = async (field, value, cms, schema, newLabel) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(newLabel, cms), { firstName: value });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(newLabel, cms), { lastName: value });
      break;
    case EMAIL:
      promise = validateValue(emailSchema(newLabel, cms), { email: value });
      break;
    case PHONE_NUMBER:
      promise = validateValue(phoneNumberSchema(newLabel, cms), { phoneNumber: value });
      break;
    case CITY:
      promise = validateValue(citySchema(newLabel, cms), { city: value });
      break;
    case COUNTRY:
      promise = validateValue(countrySchema(cms), { country: value });
      break;
    case POSTAL_CODE:
      promise = validateValue(postalCodeSchema(newLabel, cms, schema), { postalCode: value });
      break;
    case PROVINCE_CODE:
      promise = validateValue(provinceCodeSchema(cms), { provinceCode: value });
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(newLabel, cms), { streetAddress: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(newLabel, cms), { brandName: value });
      break;
    case BRAND_HANDLE:
      promise = validateValue(brandHandleSchema(newLabel, cms), { brandHandle: value });
      break;
    case MAIL_SUBJECT:
      promise = validateValue(mailSubjectSchema(cms), { mailSubject: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;
