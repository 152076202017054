import { gql } from "apollo-boost";

const GET_CURRENCY_RATE = gql`
  query {
    getCurrencyRateSetting {
      error
      status
      data {
        currency {
          isAllProduct
          isAllPayment
          rate {
            currencyCode
            price
            isProduct
            isPayment
          }
          updatedAt
        }
        currencyName
      }
    }
  }
`;

export default GET_CURRENCY_RATE;
