import React, { useContext } from "react";
import { TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const StarshipIt = (props) => {
  const { cms } = useContext(FulfillmentContext);
  const { dropship, handleStarshipChange, error, handleValidation } = props;
  const { apiKey = "" } = (dropship && dropship.starshipit) || {};

  return (
    <>
      <TextField
        label={`${cms("label.api")}*`}
        value={apiKey}
        placeholder={cms("placeholder.api")}
        onChange={(value) => handleStarshipChange(value)}
        onBlur={() => {
          handleValidation(constant.API_KEY, dropship && dropship.starshipit && dropship.starshipit.apiKey);
        }}
        error={error && !apiKey && error.apiKey}
      />
    </>
  );
};

StarshipIt.propTypes = {
  dropship: PropTypes.objectOf(PropTypes.shape()).isRequired,
  handleStarshipChange: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleValidation: PropTypes.func.isRequired,
};

StarshipIt.defaultProps = {
  error: {},
};

export default withFeature(withErrorBoundary(StarshipIt), {
  feature: constant.STARSHIPIT,
  // subFeature: constant.ADVANCE_DROPSHIP,
  // subFeatureItem: constant.STARSHIPIT,
});
