import React, { useState, useEffect, useContext } from "react";
import { Banner, Caption, Card, DataTable, Layout, PageActions, TextField, TextStyle } from "@shopify/polaris";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";

import { Spinner, Toast } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/mutations";
import { GET_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/queries";
import { FormTable } from "asset/styles/globalStyle";

const { gql } = constant;

const VendorLabel = () => {
  const { history, cms } = useContext(PrivateContext);

  const [submitEnabled, setSubmitEnable] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [firstPush, setFirstPush] = useState(true);
  const [message, setMessage] = useState("");

  const [rowData, setRowData] = useState({
    brandName: {
      label: "",
    },
    brandHandle: {
      label: "",
    },
    firstName: {
      label: "",
    },
    lastName: {
      label: "",
    },
    email: {
      label: "",
    },
    city: {
      label: "",
    },
    phoneNumber: {
      label: "",
    },
    streetAddress: {
      label: "",
    },
    country: {
      label: "",
    },
    postalCode: {
      label: "",
    },
  });

  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [vendorForm, { loading: editVendorFormLoading }] = useMutation(UPDATE_VENDOR_FORM_LABEL);

  const { loading: loadingEditVendorForm, error: errorVendorForm, data: dataVendorForm, refetch } = useQuery(
    GET_VENDOR_FORM_LABEL
  );

  const vendorLabelData = baseHelper.getResponseData(dataVendorForm, gql.GET_VENDOR_FORM_LABEL);
  const vendorFormError = baseHelper.getResponseError(errorVendorForm, gql.GET_VENDOR_FORM_LABEL);

  useEffect(() => {
    if (dataVendorForm) {
      setLastUpdated(vendorLabelData.updatedAt);

      const data = vendorLabelData;
      if (data && data.createdAt) {
        setFirstPush(false);
        // eslint-disable-next-line no-shadow
        const { createdAt, updatedAt, ...rest } = data;
        setRowData({ ...rowData, ...rest });
      }

      if (vendorFormError) {
        setBannerStatus({ isOpen: true, title: vendorFormError, status: constant.CRITICAL });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorForm, vendorLabelData]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newLabel = Object.values(rowData)
      .filter((item) => item && item.label)
      .map((item) => item.label);
    let response = false;
    if ([...new Set(newLabel)].length !== newLabel.length) {
      response = true;
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.duplicate"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };
  const vendorFormLabelData = async () => {
    vendorForm({ variables: { input: { ...rowData } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, gql.UPDATE_VENDOR_FORM_LABEL);
          const responseDataError = baseHelper.getResponseError(res.data, gql.UPDATE_VENDOR_FORM_LABEL);
          if (resData) {
            refetch();
            // eslint-disable-next-line no-unused-expressions
            firstPush === true ? setMessage(cms("message.success.save")) : setMessage(cms("message.success.update"));
          }
          if (responseDataError) {
            setBannerStatus({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const updateData = rowData[key];
    updateData.label = val;
    setSubmitEnable(true);
    updateData.label = val;
    setRowData({ ...rowData, [key]: updateData });
  };

  const acceptsStringOnly = (value, prevValue) => {
    const val = (baseHelper.stringNotAcceptSpaceAtStart(value) && value) || (value !== "" && prevValue) || "";
    const result = (baseHelper.acceptOnlyString(val) && val) || (val !== "" && prevValue) || "";
    return result;
  };

  const columnContent = constant.VENDOR_COLUMN;
  const columnHeading = [cms("currentLabelHeading"), cms("newLabelHeading")];
  const getRows = () => {
    const dataKeys = Object.keys(rowData);
    return (dataKeys || [])
      .filter((item) => item !== constant.CREATED_AT && item !== constant.UPDATE)
      .map((item) => {
        const data = rowData[item];
        const currLabel = <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
        const newText = (
          <TextField
            value={data && data.label}
            maxLength={35}
            onChange={(value) => handleLabelChange(acceptsStringOnly(value, data.label), item)}
          />
        );
        return [[currLabel], [newText]];
      });
  };

  if (loadingEditVendorForm) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card
            sectioned
            title={[
              <div>
                {cms("card.title")}
                {lastUpdated && (
                  <Caption>
                    <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
                  </Caption>
                )}
              </div>,
            ]}
          >
            <FormTable className="vendor-form-label-table">
              <DataTable columnContentTypes={columnContent} headings={columnHeading} rows={getRows()} />
            </FormTable>
            <div className="vendor-form-label-pageAction">
              {!firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) vendorFormLabelData();
                    },
                    disabled: !submitEnabled,
                    loading: editVendorFormLoading,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
              {firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.submit"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) vendorFormLabelData();
                    },
                    disabled: !submitEnabled,
                    loading: editVendorFormLoading,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </div>
          </Card>
        </Layout.AnnotatedSection>
        <Toast message={message} setToast={setMessage} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};

export default VendorLabel;
