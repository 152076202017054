import { gql } from "apollo-boost";

const VENDOR_ACCESS = gql`
  mutation vendorAccess($input: VendorAccess!) {
    vendorAccess(input: $input) {
      data
      status
      error
    }
  }
`;

export default VENDOR_ACCESS;
