import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Frame, Toast as PolarisToast } from "@shopify/polaris";

const Toast = (props) => {
  const { message, setToast, timeout = 3000 } = props;
  const [toastActive, setToastActive] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    if (message) {
      setToastActive(true);
      setData(message);
      setTimeout(() => {
        setToastActive(false);
        setToast("");
      }, timeout);
    }
  }, [message]);

  return (
    <div className="toast">
      <Frame>{toastActive && <PolarisToast content={data} onDismiss={() => setToastActive(false)} />}</Frame>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  setToast: PropTypes.func,
};
Toast.defaultProps = {
  message: "",
  setToast: () => {},
};

export default Toast;
