import PropsTypes from "prop-types";

const providerLineReturnProps = {
  type: {
    showModal: PropsTypes.bool.isRequired,
    orderToInitiate: PropsTypes.object.isRequired,
    onClose: PropsTypes.func.isRequired,
  },
};

export default providerLineReturnProps;
