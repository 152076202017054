import React, { useContext, useState } from "react";
import { Card, FormLayout, PageActions, TextField } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "app/productOld/modules/operator/features/add/context/context";
import validate from "./yup/validation";

const Bucket = (props) => {
  const { buttonValue, aws, onSubmit, productAttachmentSettingLoading } = props;
  const { cms, history } = useContext(PrivateContext);
  const { data, handleChange } = useContext(FormContext);
  const { bucket = "", id = "", secret = "" } = data || {};
  const [errorMessage, setErrorMessage] = useState({});

  const handleOnBlur = async (field, value) => {
    const error = await validate(field, value && value.trim(), cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  return (
    <Card.Section>
      <FormLayout>
        <TextField
          label={`${cms("label.bucketName")}*`}
          placeholder={cms("placeholder.bucketName")}
          value={bucket}
          onChange={(value) => handleChange(constant.BUCKET_NAME, value)}
          onBlur={() => handleOnBlur(constant.BUCKET_NAME, bucket)}
          error={errorMessage && errorMessage.bucket}
        />

        <TextField
          label={`${cms("label.bucketAccessKey")}*`}
          placeholder={cms("placeholder.bucketAccessKey")}
          value={id}
          onChange={(value) => handleChange(constant.ACCESS_KEY_ID, value)}
          onBlur={() => handleOnBlur(constant.ACCESS_KEY_ID, id)}
          error={errorMessage && errorMessage.id}
        />

        <TextField
          label={`${cms("label.bucketSecretKey")}*`}
          placeholder={cms("placeholder.bucketSecretKey")}
          value={secret}
          onChange={(value) => handleChange(constant.SECRET_ACCESS_KEY, value)}
          onBlur={() => handleOnBlur(constant.SECRET_ACCESS_KEY, secret)}
          error={errorMessage && errorMessage.secret}
        />
      </FormLayout>
      <br />
      <div className="manageVendorAccessButton">
        {aws && aws.bucket && (
          <PageActions
            primaryAction={{
              id: "submitProductAttachmentSetting",
              content: cms("common.button.update"),
              disabled: productAttachmentSettingLoading || buttonValue,
              loading: productAttachmentSettingLoading,
              onAction: () => onSubmit(),
            }}
            secondaryActions={[
              {
                id: "cancelProductAttachmentSetting",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </div>
    </Card.Section>
  );
};

export default Bucket;
