import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";

import {
  Banner,
  Button,
  Caption,
  Card,
  Checkbox,
  DatePicker,
  Layout,
  Link,
  Modal,
  PageActions,
  Popover,
  Stack,
  TextField,
  TextStyle,
  Icon,
} from "@shopify/polaris";
import { CalendarMinor, DeleteMajor } from "@shopify/polaris-icons";

import { Toast } from "lib/components";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { HOLIDAY_SETTING } from "app/advanceVendor/apollo/mutations";

import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";
import { StackWrapper } from "asset/styles/globalStyle";
import VendorListModal from "./subFeatures/addVendorListModal";
import cmsHolidaySetting from "./cms/holidayCMS";

const OperatorHolidaySetting = (props) => {
  const { temp, vendorListData, refetch, updatedAt } = props;
  const [vendorHolidaySetting, { loading: vendorHolidayLoading }] = useMutation(HOLIDAY_SETTING);
  const { history, cms } = useContext(PrivateContext);
  const cmsHoliday = cmsHolidaySetting(cms);
  const { label } = cmsHoliday;

  // eslint-disable-next-line no-unused-vars
  const [popoverActive, setPopoverActive] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [addButton, setAddButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  // eslint-disable-next-line no-unused-vars
  const [editVendor, setEditVendor] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [selected, setSelected] = useState([]);
  const [prevVendorsSelected, setPrevVendorsSelected] = useState([]);
  const [vendorsSelected, setVendorsSelected] = useState([]);

  useEffect(() => {
    if (temp) {
      setVendorsSelected(temp);
      setPrevVendorsSelected(temp);
      setSelected(temp);
      const selectedIds = temp.map((vendor) => vendor.id);
      setSelected(selectedIds);
    }
  }, [temp]);

  const handleModalChange = () => {
    const selectedIds = vendorsSelected.map((vendor) => vendor.id);
    setSelected(selectedIds);
    setActive(!active);
  };

  const handleVendorChange = (vendors) => {
    const selectedIds = vendorsSelected.map((vendor) => vendor.id);
    const existingVendorRow = vendors.filter((vendorId) => selectedIds.includes(vendorId));
    setSelected([...new Set([...existingVendorRow, ...vendors])]);
    setAddButton(false);
  };

  const handleCheckedChange = useCallback((newChecked) => setChecked(newChecked), []);

  const handleTextfield = (newValue) => {
    setValue(newValue);
  };

  const onSubmit = async (vendorData = []) => {
    try {
      const vendorDetail = vendorData.map((vendor) => ({
        vendorId: vendor && vendor.id,
        date: {
          startDate: vendor && vendor.start && baseHelper.yearFirstDateFormat(vendor.start),
          endDate: vendor && vendor.end && baseHelper.yearFirstDateFormat(vendor.end),
        },
      }));
      const response = await vendorHolidaySetting({
        variables: { input: { vendor: vendorDetail, reason: value, isRejected: checked } },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.HOLIDAY_SETTING);
      // const responseData = baseHelper.getResponseData(response.data, constant.gql.HOLIDAY_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setActiveModal(false);
        return;
      }
      setActiveModal(false);
      setChecked(false);
      setValue("");
      const messsage = updatedAt ? cms("label.updated") : cms("label.saved");
      setMessage(cms("message.success.save", { item: messsage }));
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const isSubmitDisable = () => {
    const deletedVendor = prevVendorsSelected.filter(
      ({ id: prevVendorId }) => !vendorsSelected.some(({ id: selectedVendorId }) => selectedVendorId === prevVendorId)
    ).length;
    const addedVendor = vendorsSelected.filter(
      ({ id: selectedVendorId }) =>
        !prevVendorsSelected.some(({ id: prevVendorId }) => prevVendorId === selectedVendorId)
    ).length;
    const checkForUpdation = deletedVendor || addedVendor;
    const noDateCount = vendorsSelected.filter((vendor) => vendor && vendor.noDateSelected).length;
    if (!checkForUpdation || noDateCount) {
      return true;
    }
    return false;
  };

  const handleChange = (deleteVendor = false) => {
    if (!deleteVendor) {
      const deletedVendor = prevVendorsSelected.filter(
        ({ id: prevVendorId }) => !vendorsSelected.some(({ id: selectedVendorId }) => selectedVendorId === prevVendorId)
      );
      const addedVendor = vendorsSelected.filter(
        ({ id: selectedVendorId }) =>
          !prevVendorsSelected.some(({ id: prevVendorId }) => prevVendorId === selectedVendorId)
      );
      let vendorRemain;
      if (deletedVendor && deletedVendor.length) {
        const deletedIds = deletedVendor.map((vendor) => vendor && vendor.id);
        vendorRemain = vendorsSelected.filter(({ id }) => !deletedIds.includes(id));
      }
      if (deletedVendor && deletedVendor.length && !addedVendor.length) {
        onSubmit(vendorRemain);
      }
      if (addedVendor && addedVendor.length) {
        setActiveModal(!activeModal);
        setChecked(false);
        setValue("");
      }
    } else {
      const deletedIds = deleteVendor.map((vendor) => vendor && vendor.id);
      const vendorRemain = vendorsSelected.filter(({ id }) => !deletedIds.includes(id));
      const isVendorExist = prevVendorsSelected.some((vendor) => deletedIds.includes(vendor.id));
      if (isVendorExist) {
        onSubmit(vendorRemain);
      } else {
        setVendorsSelected(vendorRemain);
      }
    }
  };

  const handleClose = () => {
    setActiveModal(!activeModal);
    setChecked(false);
    setValue("");
  };

  // eslint-disable-next-line no-shadow
  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = (vendor, index) => {
    return (
      <Stack vertical>
        <Stack alignment="center">
          <Button
            icon={CalendarMinor}
            onClick={() => {
              setVendorsSelected((prev) => {
                const initialState = [...prev];
                initialState[index].isActive = !initialState[index].isActive;
                return initialState;
              });
              togglePopoverActive();
              setSelectedDates({
                start: vendor.start ? new Date(vendor.start) : new Date(),
                end: vendor.end ? new Date(vendor.end) : new Date(),
              });
            }}
          >
            {vendor.noDateSelected || !vendor.end || !vendor.start
              ? label.selectDate
              : `${moment.utc(vendorsSelected[index].start).format("ll")} -
             ${moment.utc(vendorsSelected[index].end).format("ll")}`}
          </Button>
          {!vendor.noDateSelected ? (
            <Button
              id="deleteButton"
              plain
              destructive
              onClick={() => {
                handleChange([vendorsSelected[index]]);
              }}
            >
              <Icon source={DeleteMajor} color="critical" />
            </Button>
          ) : null}
        </Stack>
      </Stack>
    );
  };

  const handleCloseDatePicker = (vendor, index) => {
    const vendorsData = [...vendorsSelected];
    vendorsData.map((item, itemIndex) => {
      if (itemIndex === index) {
        // eslint-disable-next-line no-param-reassign
        item[constant.ACTIVE] = false;
      }
      return false;
    });
    setVendorsSelected(vendorsData);
  };

  const handleCalenderRendering = (vendorList) => {
    return vendorList.map((vendor, index) => {
      return (
        <>
          <StackWrapper className="operator stack-wrpper">
            <Stack distribution="trailing" alignment="center">
              <Stack.Item>
                <p>{vendor.name}</p>
              </Stack.Item>
              <Stack.Item>
                <Popover
                  id="select-date"
                  active={vendor.isActive}
                  activator={activator(vendor, index)}
                  onClose={() => handleCloseDatePicker(vendor, index)}
                  fullWidth
                >
                  <Popover.Pane>
                    <Stack distribution="equalSpacing">
                      <TextField label={label.start} value={vendor.startingDate} />
                      <TextField label={label.end} value={vendor.endingDate} />
                    </Stack>
                    <DatePicker
                      month={vendor.month}
                      year={vendor.year}
                      onChange={(date) => {
                        setSelectedDates({
                          start: new Date(date.start),
                          end: new Date(date.end),
                        });
                      }}
                      onMonthChange={(month, year) => {
                        setVendorsSelected((prev) => {
                          const initialState = [...prev];
                          initialState[index].month = month;
                          initialState[index].year = year;
                          return initialState;
                        });
                      }}
                      selected={selectedDates}
                      disableDatesBefore={new Date()}
                      multiMonth
                      allowRange
                    />
                  </Popover.Pane>
                  <div className="holidayDate">
                    <PageActions
                      primaryAction={{
                        content: label.apply,
                        onClick: () => {
                          setVendorsSelected((prev) => {
                            const initialState = [...prev];
                            initialState[index].start = selectedDates.start;
                            initialState[index].end = selectedDates.end;
                            initialState[index].startingDate = selectedDates.start;
                            initialState[index].endingDate = selectedDates.end;
                            initialState[index].noDateSelected = false;
                            initialState[index].isActive = !initialState[index].isActive;
                            return initialState;
                          });
                          togglePopoverActive();
                          setSubmitButton(false);
                          setSelectedDates({ start: new Date(), end: new Date() });
                        },
                      }}
                      secondaryActions={[
                        {
                          content: cms("common.button.cancel"),
                          onClick: () => {
                            setSelectedDates({ start: new Date(), end: new Date() });
                            setVendorsSelected((prev) => {
                              const initialState = [...prev];
                              initialState[index].isActive = false;
                              return initialState;
                            });
                          },
                        },
                      ]}
                    />
                  </div>
                </Popover>
              </Stack.Item>
            </Stack>
            <hr className="holidayMode" />
          </StackWrapper>
          {index === vendorsSelected.length - 1 ? (
            <Stack>
              <Link onClick={handleModalChange}>{label.addVendor}</Link>
            </Stack>
          ) : null}
        </>
      );
    });
  };
  return (
    <>
      <Layout>
        <Layout.Section>
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
              />
              <br />
            </>
          )}
          <Layout.AnnotatedSection title={label.section.setting.title} description={label.section.setting.description}>
            <Card
              title={[
                label.section.holiday.title,
                Date.now() && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                  </TextStyle>
                ),
              ]}
              sectioned
            >
              <Stack spacing="tight" distribution="fillEvenly">
                <Stack.Item>
                  <TextStyle variation="strong">{label.vendor}</TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle variation="strong">{label.timePeriod}</TextStyle>
                </Stack.Item>
              </Stack>
              <hr />
              <br />
              {vendorsSelected.length > 0 ? (
                handleCalenderRendering(vendorsSelected)
              ) : (
                <Stack spacing="extraLoose">
                  <Link onClick={handleModalChange}>{label.addVendor}</Link>
                </Stack>
              )}

              {updatedAt && (
                <ActionButton>
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: () => {
                        handleChange();
                      },
                      loading: vendorHolidayLoading,
                      disabled: isSubmitDisable() || submitButton,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => {
                          history.push("/setting");
                        },
                        disabled: vendorHolidayLoading,
                      },
                    ]}
                  />
                </ActionButton>
              )}
            </Card>
            <div>
              {!updatedAt && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.submit"),
                    onAction: () => {
                      handleChange();
                    },
                    loading: vendorHolidayLoading,
                    disabled: isSubmitDisable() || submitButton,
                  }}
                  secondaryActions={[
                    {
                      content: cms("common.button.cancel"),
                      onAction: () => {
                        history.push("/setting");
                      },
                      disabled: vendorHolidayLoading,
                    },
                  ]}
                />
              )}
              <Modal
                open={activeModal}
                onClose={handleClose}
                title={label.section.setting.title}
                primaryAction={{
                  content: cms("common.button.confirm"),
                  onAction: () => onSubmit(vendorsSelected),
                  loading: vendorHolidayLoading,
                  disabled: vendorHolidayLoading,
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onAction: handleClose,
                    disabled: vendorHolidayLoading,
                  },
                ]}
              >
                <Modal.Section>
                  <Stack vertical>
                    <Stack.Item>
                      <Banner status="critical">{label.section.banner.operatorDescription}</Banner>
                    </Stack.Item>
                    <Stack.Item>
                      <div className="checkBoxLabel">
                        <Checkbox label={label.section.reject.title} checked={checked} onChange={handleCheckedChange} />
                      </div>
                      <div className="checkBox-text-style">
                        <TextStyle variation="subdued">{label.section.reject.description}</TextStyle>
                      </div>
                    </Stack.Item>
                    <Stack.Item fill>
                      <TextField
                        multiline={3}
                        label={label.reason}
                        value={value}
                        onChange={(e) => handleTextfield(e)}
                      />
                    </Stack.Item>
                  </Stack>
                </Modal.Section>
              </Modal>
            </div>
          </Layout.AnnotatedSection>
          <Toast message={message} setToast={setMessage} />
        </Layout.Section>
      </Layout>
      <VendorListModal
        vendorList={vendorListData}
        active={active}
        setActive={setActive}
        setEditVendor={setEditVendor}
        selected={selected}
        setSelected={handleVendorChange}
        vendorsSelected={vendorsSelected}
        setVendorsSelected={setVendorsSelected}
        addButton={addButton}
        setAddButton={setAddButton}
        setSubmitButton={setSubmitButton}
      />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorHolidaySetting), {
  feature: constant.VENDOR_HOLIDAY_SETTING,
});
