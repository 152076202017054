import { gql } from "apollo-boost";

const UPDATE_PRODUCT_VERSION = gql`
  mutation UpdateProductVersion($input: UpdateProductVersion!) {
    updateProductVersion(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_PRODUCT_VERSION;
