import React, { useContext, useState } from "react";
import _ from "lodash";
import {
  Badge,
  Caption,
  Collapsible,
  Heading,
  Link,
  ResourceItem,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor, ImportStoreMajor, ViewMinor } from "@shopify/polaris-icons";
// import hoc
import { withErrorBoundary } from "lib/hoc";

import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper, imageHelper } from "lib/helpers";

// import constant
import LazyLoad from "react-lazyload";
import Popover from "lib/components/popover/popover";
import constant from "lib/constant/constant";
import ProductVersioning from "./productVersioning";

const ProductListView = (props) => {
  const {
    MAGENTO,
    MAGENTO_ICON,
    PRESTASHOP,
    PRESTASHOP_ICON,
    // SHOPIFY,
    SHOPIFY_ICON,
    WIX,
    WIX_ICON,
    WOOCOMMERCE,
    WOOCOMMERCE_ICON
  } = constant;

  const {
    active,
    activePopover,
    bulkAction,
    cms,
    handleDeleteModal,
    item,
    loadingBulkProduct,
    setActivePopover,
    setListBanner,
    isBigCommerceUser = false,
  } = props;
  const { currentUser, history } = useContext(PrivateContext);
  /* const { ecommercePlatform } = currentUser; */

  const {
    _id: id,
    isLiveConnect,
    providerProductId: productId,
    liveConnect,
    name: title,
    primaryUrl,
    published,
    status: itemStatus,
    updatedAt,
    variants = [],
    vendor,
    vendorShopifyProductId,
  } = item;
  const { _id: vendorId, brandName } = (vendor && vendor.length && vendor[0]) || {};

  const { platform: liveConnectPlatform = "" } = liveConnect || {};
  let icon;
  if (vendorShopifyProductId) {
    icon = SHOPIFY_ICON;
  }
  if (liveConnectPlatform === WIX) {
    icon = WIX_ICON;
  }
  if (liveConnectPlatform === WOOCOMMERCE) {
    icon = WOOCOMMERCE_ICON;
  }
  if (liveConnectPlatform === MAGENTO) {
    icon = MAGENTO_ICON;
  }
  if (liveConnectPlatform === PRESTASHOP) {
    icon = PRESTASHOP_ICON;
  }

  const size = constant.LARGE;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const productID = isSeller && isBigCommerceUser ? productId : id;
  const thumbnailImageUrl =
    primaryUrl.length && ((primaryUrl || []).find((imageItem) => imageItem.sortOrder === 1) || primaryUrl[0]);
  const thumbnailImage = thumbnailImageUrl
    ? imageHelper.resize({ url: thumbnailImageUrl.imageUrl, type: constant.imageTypes.THUMBNAIL })
    : constant.NOIMAGESNAP;
  const thumbnail = <Thumbnail source={thumbnailImage} size={size} alt={`Image of ${title}`} />;
  const skeletalThumbnail = <SkeletonThumbnail size={size} />;
  const media = (
    <LazyLoad once height={80} offset={100} placeholder={skeletalThumbnail}>
      {thumbnail}
    </LazyLoad>
  );
  const status = itemStatus || constant.APPROVED;
  const statusType = status.toLowerCase() === constant.APPROVED.toLowerCase() ? constant.PUBLISHED : status;
  const badgeStatus = baseHelper.ucFirst(baseHelper.getBadgeStatus(statusType));
  const badgeType = baseHelper.getBadgeType(status);

  const topMargin10px = {
    marginTop: "10px",
  };

  const isAvailablePublishment = _.isBoolean(published);

  const handleSelect = (productStatus) => {
    if (productStatus === constant.VIEW_ID) {
      history.push(`${constant.PRODUCT_LAKE_URL_UPDATE}${productID}`);
    }

    if (productStatus === constant.REVIEW_ALL) {
      history.push(`${constant.PRODUCT_LAKE_URL_REVIEW}${productID}`);
    }

    if (productStatus === constant.PUSH_TO_STORE_ID) {
      setSelectedButtonIndex(productID);
      bulkAction(constant.APPROVE, false, productID, "", null, constant.NEW);
    }

    if (productStatus === constant.DELETED) {
      handleDeleteModal(constant.DELETE.toLowerCase(), productID, productStatus);
    }

    if (productStatus === "available") {
      bulkAction(constant.PUBLISH, false, productID);
    }

    if (productStatus === "unavailable") {
      bulkAction(constant.UNPUBLISH, false, productID);
    }
  };

  const pushToStore = {
    content: constant.PUSH_TO_STORE_LABEL,
    value: constant.PUSH_TO_STORE_ID,
    disabled: false,
    icon: ImportStoreMajor,
    onAction: () => handleSelect(constant.PUSH_TO_STORE_ID),
  };

  const review = {
    content: constant.displayStatus.NEEDSREVIEW,
    value: constant.REVIEW_ALL,
    disabled: false,
    icon: ViewMinor,
    onAction: () => handleSelect(constant.REVIEW_ALL),
  };

  const view = {
    content: constant.EDIT_LABEL,
    value: constant.VIEW_ID,
    disabled: false,
    icon: EditMinor,
    onAction: () => handleSelect(constant.VIEW_ID),
  };

  const deleteOption = {
    content: constant.DELETE,
    value: constant.DELETED,
    disabled: false,
    destructive: true,
    icon: DeleteMinor,
    onAction: () => handleSelect(constant.DELETED),
  };

  const viewOption = [view];
  const pushToStoreOption =
    status.toLowerCase() === constant.NEW || status === constant.REVIEWED_SMALL
      ? [pushToStore]
      : [{ ...pushToStore, disabled: true }];
  const reviewOption =
    status === constant.NEEDS_REVIEW_LAKE && isBigCommerceUser ? [review] : [{ ...review, disabled: true }];
  const deleteOptions =
    status !== constant.DELETED ? [deleteOption] : [{ ...deleteOption, disabled: true, destructive: false }];

  let options = [];

  if (isSeller) {
    options = [...viewOption];

    if (status.toLowerCase() === constant.NEW || status === constant.REVIEWED_SMALL) {
      options.unshift(...pushToStoreOption);
    }
    if (status === constant.NEEDS_REVIEW) {
      options.unshift(...reviewOption);
    }
    if (!(status.toLowerCase() === constant.NEW || status === constant.REVIEWED_SMALL)) {
      options.push(...pushToStoreOption);
    }
    if (!(status === constant.NEEDS_REVIEW)) {
      options.push(...reviewOption);
    }
    if (status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED) {
      options.push(...deleteOptions);
    }
  }

  if (isVendor) {
    options = [...viewOption];
    options.push(...deleteOptions);
  }

  if (isSystemAdmin) {
    options = [...viewOption, ...deleteOptions];
  }

  return (
    <>
      <ResourceItem
        id={productID}
        key={productID}
        accessibilityLabel={`${cms("label.detail")} ${title}`}
        persistActions
        recordID={id}
      >
        <Stack alignment={constant.LEADING} wrap={false}>
          <Stack.Item>{media}</Stack.Item>
          <Stack.Item fill>
            <div className="ellipsis">
              <Heading element="h2">
                <Link
                  onClick={() =>
                    history.push(`${constant.PRODUCT_LAKE_URL_UPDATE}${productID}?tab=${constant.INFORMATION}`)
                  }
                >
                  <TextStyle>{title}</TextStyle>
                </Link>
              </Heading>
              <Caption>
                <TextStyle variation={constant.SUBDUED}>
                  {`${constant.UPDATED} ${constant.ON}: `}
                  {`${baseHelper.formatDate(updatedAt)}`}
                </TextStyle>
                {!isVendor && vendorId && (
                  <>
                    <br />
                    {`${constant.VENDOR_LABEL}:`}
                    <Link url={`/providers/view/${vendorId}`}>{brandName}</Link>
                  </>
                )}
                <br />
                <Caption>
                  <TextStyle variation={constant.SUBDUED}>{`${cms("label.variant")} ${variants.length}`}</TextStyle>
                </Caption>
                {isAvailablePublishment && (
                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>
                      {`${constant.AVAILABILITY_STATUS}: `}
                      {published ? constant.AVAILABLE : constant.UNAVAILABLE}
                    </TextStyle>
                  </Caption>
                )}
              </Caption>
              <div style={topMargin10px}>
                <Stack>
                  <Stack.Item>
                    <Badge status={badgeType}>
                      <span className="text-capitalize">{badgeStatus}</span>
                    </Badge>
                  </Stack.Item>
                  {isLiveConnect && (
                    <Stack>
                      <Stack.Item>
                        <Tooltip active={false} content={cms("label.platform")}>
                          <img src={icon} alt={liveConnectPlatform} />
                        </Tooltip>
                      </Stack.Item>
                    </Stack>
                  )}
                  {/* <Stack.Item>
                    <div className="timelineLink">
                      <Button
                        plain
                        monochrome
                        disclosure={active[id] ? constant.UP : constant.DOWN}
                        onClick={() => {
                          setActive((prev) => {
                            return {
                              ...prev,
                              [id]: !active[id],
                            };
                          });
                        }}
                      >
                        History
                      </Button>
                    </div>
                  </Stack.Item> */}
                </Stack>
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              active={activePopover[id]}
              setActive={() => setActivePopover({ [id]: !activePopover[id] })}
              loading={selectedButtonIndex && loadingBulkProduct}
              options={options}
            />
          </Stack.Item>
        </Stack>
        <Collapsible
          open={active && active[id]}
          id="timeline"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <ProductVersioning cms={cms} productId={productID} setListBanner={setListBanner} />
        </Collapsible>
      </ResourceItem>
    </>
  );
};
ProductListView.defaultProps = {
  cms: () => {},
  item: {
    _id: "",
    title: "",
    vendor: {
      brandName: null,
    },
    createdAt: "",
    variantCount: "",
    published: "",
    productId: "",
    status: "",
    images: { imageUrl: null },
  },
  bulkAction: () => {},
  handleDeleteModal: () => {},
  loadingBulkProduct: "",
  activePopover: {},
};

ProductListView.propTypes = {
  cms: PropTypes.func,
  item: PropTypes.shape(PropTypes.object),
  bulkAction: PropTypes.func,
  handleDeleteModal: PropTypes.func,
  loadingBulkProduct: PropTypes.string,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default withErrorBoundary(ProductListView);
