import React, { useContext } from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

// propTypes
import vendorProfileProps from "app/advanceVendor/modules/operator/feature/view/propTypes";

const SocialLink = (props) => {
  const { cms } = useContext(PrivateContext);
  const { vendorDetails = {} } = props;
  const { socialLink = [] } = vendorDetails || {};
  let socialLinkData = [];
  const paymentInformation = [
    {
      term: cms("label.advanceVendor.facebook"),
      description: socialLink.facebook,
    },
    {
      term: cms("label.advanceVendor.instagram"),
      description: socialLink.instagram,
    },
    {
      term: cms("label.advanceVendor.twitter"),
      description: socialLink.twitter,
    },
    {
      term: cms("label.advanceVendor.youTube"),
      description: socialLink.youtube,
    },
    {
      term: cms("label.advanceVendor.linkedIn"),
      description: socialLink.linkedin,
    },
  ];
  socialLinkData = paymentInformation.map((item) => {
    const updatedItem = { ...item };
    if (!item.description) {
      updatedItem.description = cms("label.advanceVendor.noDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(socialLink).length === 0) {
      return <div className="noData">{cms("label.advanceVendor.noDataAvailable")}</div>;
    }
    return <DescriptionList items={socialLinkData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

SocialLink.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(SocialLink), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
