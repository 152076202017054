import constant from "lib/constant/constant";

const { attachmentType } = constant;

const { DOC, DOCX, JPEG, JPG, PDF, PNG } = attachmentType;
class Attachment {
  constructor() {
    // variable Name

    this.attachmentType = {
      pdf: constant.PDFATTACHMENT,
      doc: constant.DOCATTACHMENT,
      docx: constant.DOCXATTACHMENT,
    };
    this.allowedImageTypes = [JPEG, JPG, PNG];
    this.allowedAttachmentTypes = [DOC, DOCX, PDF];
  }

  // TODO: for specific formate for extention.
  attachmentTypeFormat = ({ fileUrl = "", extension = "" }) => {
    if (!fileUrl) {
      return constant.NOATTACHMENTSNAP;
    }
    const isImage = this.allowedImageTypes.includes(extension);
    if (isImage) {
      return fileUrl;
    }

    const formatResponse = Object.keys(this.attachmentType)
      .map((item) => fileUrl.includes(String(item)) && item)
      .filter((element) => element);
    return this.attachmentType[formatResponse[0]] || constant.NOATTACHMENTSNAP;
  };
}

export default new Attachment();
