import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery } from "react-apollo";

import ResourceList from "lib/components/resourceList/resourceList";
import { Layout, TextField, Select, FormLayout } from "@shopify/polaris";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import gql
import {
  GET_SHIPPING_BAND,
  GET_SHIPPING_RULE,
  GET_SHIPPING_RULE_PRODUCT,
  GET_SHIPPING_RULE_REGION,
} from "app/shipping/apollo/queries";

// import conmponents
import { AddNewRule, DeleteRule, EditRule } from "./modal";
import RuleItem from "./ruleItem";

const ProviderRules = (props) => {
  const { productLakeDataError, productLakeDataResponse } = props;
  const { setBannerOuter, isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser, setPageData, pageData = null, cms } = useContext(PrivateContext);
  const { moneyFormat, ecommercePlatform = "" } = currentUser || {};
  const { gql, DESTINATION, PRODUCT } = constant;
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addNewRuleModal, setAddNewRuleModal] = useState(false);
  const [item, setItem] = useState({});
  const [queryValue, setQueryValue] = useState("");
  const [filterRecord, setFilterRecord] = useState([]);
  const [shippingRule, setShippingRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [taggedWith, setTaggedWith] = useState("");
  const [shippingRulesData, setShippingRulesData] = useState([]);

  const [destinationFilter, setDestinationFilter] = useState();
  const [productFilter, setProductFilter] = useState();

  const { loading: bandLoading, data: bandData, error: bandError } = useQuery(GET_SHIPPING_BAND);
  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_RULE_PRODUCT);
  const { loading: regionLoading, data: regionData, error: regionError } = useQuery(GET_SHIPPING_RULE_REGION);
  const {
    loading: ruleLoading,
    data: ruleData,
    error: ruleError,
    refetch: ruleRefetch,
    networkStatus,
  } = useQuery(GET_SHIPPING_RULE, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (bandLoading || ruleLoading || productLoading || regionLoading || networkStatus === 4) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [bandLoading, ruleLoading, productLoading, regionLoading, networkStatus, setBannerOuter]);

  const isError = bandError || productError || regionError || ruleError;

  const toggleAddModal = useCallback(() => {
    setAddNewRuleModal(!addNewRuleModal);
  }, [addNewRuleModal]);

  useEffect(() => {
    if (pageData && pageData.primaryAction && pageData.primaryAction.content === "Add New Rule") {
      return;
    }
    setPageData({
      title: cms("title"),
      primaryAction: {
        content: cms("modal.rule.add.title"),
        onClick: () => toggleAddModal(),
        disabled: !isVendorAllowed,
      },
    });
  }, [setPageData, toggleAddModal, pageData, isVendorAllowed, cms]);

  useEffect(() => {
    if (isError) {
      setBannerOuter({
        isOpen: true,
        title: errorHelper.parse(isError),
        status: "critical",
      });
    }
  }, [isError, setBannerOuter]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBannerOuter({
        isOpen: true,
        title: errorMessage,
        status: "critical",
      });
    },
    [setBannerOuter]
  );

  const ruleDataError = baseHelper.getResponseError(ruleData, gql.GET_SHIPPING_RULE);
  useEffect(() => {
    if (ruleDataError) {
      setErrorBanner(ruleDataError);
    }
    const ruleDataResponse = baseHelper.getResponseData(ruleData, gql.GET_SHIPPING_RULE);
    const { shippingRules = [] } = ruleDataResponse || {};
    setShippingRules(shippingRules);
    setFilterRecord(shippingRules);
  }, [ruleDataError, setErrorBanner, ruleData, gql.GET_SHIPPING_RULE]);

  // Shipping Data
  const bandDataError = baseHelper.getResponseError(bandData, gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (bandDataError) setErrorBanner(bandDataError);
  }, [bandDataError, setErrorBanner]);

  const bandDataResponse = baseHelper.getResponseData(bandData, gql.GET_SHIPPING_BAND);
  const { shippingRows = [] } = bandDataResponse || {};
  const shippingBands = shippingRows.map(({ _id: bandId, name, parentId }) => ({
    bandId,
    label: name,
    parentId,
    value: parentId,
  }));

  // Product
  const productDataError = baseHelper.getResponseError(productData, gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      setErrorBanner(productDataError);
    }
  }, [productDataError, setErrorBanner]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productDataResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCTS);
  // Destinations
  const regionDataError = baseHelper.getResponseError(regionData, gql.GET_REGION);
  useEffect(() => {
    if (regionDataError) {
      setErrorBanner(regionDataError);
    }
  }, [regionDataError, setErrorBanner]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const regionDataResponse = baseHelper.getResponseData(regionData, constant.gql.GET_REGION);

  useEffect(() => {
    if (productDataResponse.length > 0) {
      setProductFilter(productDataResponse);
    }
  }, [productDataResponse]);

  useEffect(() => {
    if (regionDataResponse.length > 0) {
      setDestinationFilter(regionDataResponse);
    }
  }, [regionDataResponse]);

  useEffect(() => {
    if (productLakeDataError) {
      setErrorBanner(productLakeDataError);
    }
  }, [productLakeDataError, setErrorBanner]);

  const toggleEditModal = (items) => {
    setItem(items);
    setEditModal(!editModal);
  };
  const toggleDeleteModal = (items) => {
    setItem(items);
    setDeleteModal(!deleteModal);
  };
  function handleFiltersChange(appliedFilters) {
    if (!appliedFilters.length) {
      setFilterRecord(shippingRule);
      setQueryValue("");
      return;
    }
    let destinationRowsToShow = [];
    let productRowsToShow = [];
    if (appliedFilters.length) {
      const filterValue = appliedFilters.toLowerCase().trim();
      if (selectedFilter === DESTINATION) {
        let options = [];
        destinationFilter.forEach((destination) => {
          options = [...options, ...destination.options];
        });
        const filteredDestinations = options.filter((filteredDestination) =>
          filteredDestination.label.toLowerCase().includes(filterValue)
        );
        destinationRowsToShow = shippingRule.filter((destinationRow) =>
          filteredDestinations.find((destination) => destination.value === destinationRow.destinationCode)
        );
      }
      if (selectedFilter === PRODUCT) {
        const filteredProducts = productFilter.filter((filteredProduct) =>
          filteredProduct.label.toLowerCase().includes(filterValue)
        );
        productRowsToShow = shippingRule.filter((productRow) =>
          filteredProducts.find((product) => product.value === productRow.productId)
        );
      }
      setFilterRecord([...destinationRowsToShow, ...productRowsToShow]);
      setQueryValue("");
    }
  }
  const handleSearchChange = (searchValue) => {
    if (!searchValue.length) {
      setFilterRecord(shippingRule);
      setQueryValue("");
      return;
    }
    const search = searchValue.toLowerCase().trim();
    const updatedRules = shippingRule.filter((key) => {
      const shippingBand = shippingBands.find((band) => band.value === key.shippingBandId);
      return shippingBand && shippingBand.label && shippingBand.label.toLowerCase().includes(search);
    });
    setQueryValue(searchValue);
    setFilterRecord([...updatedRules]);
  };
  const resourceName = {
    singular: constant.RULE,
    plural: constant.RULES,
  };
  const handleQueryValueRemove = () => {
    setFilterRecord([...shippingRule]);
    setQueryValue("");
  };
  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    setTaggedWith("");
    setFilterRecord(shippingRule);
  };

  const handleTaggedWithRemove = (isClearAll = false) => {
    setTaggedWith("");
    if (isClearAll) {
      setSelectedFilter("");
    }
    handleFiltersChange("");
  };

  const handleClearAll = () => {
    setTaggedWith("");
    setSelectedFilter("");
    handleFiltersChange("");
  };

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    handleFiltersChange(value);
  };

  useEffect(() => {
    const filteredShippingRules =
      productDataResponse &&
      filterRecord.filter((newFilterRecord) => {
        const isProductCondition = newFilterRecord.condition === constant.PRODUCT;
        return !isProductCondition || productDataResponse.find((pItem) => pItem.value === newFilterRecord.productId);
      });
    setShippingRulesData(filteredShippingRules);
    if (ecommercePlatform) {
      const filteredLakeShippingRules =
        productLakeDataResponse &&
        filterRecord.filter((newFilterRecord) => {
          const isProductCondition = newFilterRecord.condition === constant.PRODUCT;
          return (
            !isProductCondition || productLakeDataResponse.find((pItem) => pItem.value === newFilterRecord.productId)
          );
        });
      setShippingRulesData(filteredLakeShippingRules);
    }
  }, [productDataResponse, filterRecord]);

  const customFilter = (
    <FormLayout>
      <Select
        label={cms("label.showAllRule")}
        value={selectedFilter}
        onChange={handleSelectChange}
        options={[
          { label: cms("label.destination"), value: "destination" },
          { label: cms("label.product"), value: "product" },
        ]}
        placeholder={cms("label.selectFilter")}
      />
      {(selectedFilter && (
        <TextField label={cms("label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
      )) ||
        null}
    </FormLayout>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value}`) || value;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];

  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true),
    });
  }

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filterBy"),
      filter: customFilter,
      shortcut: false,
    },
  ];

  const render = (items) => {
    return (
      <RuleItem
        item={items}
        destinations={regionDataResponse}
        band={shippingBands}
        rule={shippingRule}
        products={productDataResponse}
        moneyFormat={moneyFormat}
        editModal={toggleEditModal}
        deleteModal={toggleDeleteModal}
      />
    );
  };

  return (
    <>
      <Layout>
        {deleteModal && (
          <DeleteRule
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            item={item}
            refetch={ruleRefetch}
            band={shippingBands}
          />
        )}
        {editModal && (
          <EditRule
            editModal={editModal}
            setEditModal={setEditModal}
            item={item}
            setBanner={setBannerOuter}
            refetch={ruleRefetch}
            bands={shippingBands}
            products={ecommercePlatform ? productLakeDataResponse : productDataResponse}
            destinations={regionDataResponse}
            moneyFormat={moneyFormat}
          />
        )}
        {addNewRuleModal && (
          <AddNewRule
            addNewRuleModal={addNewRuleModal}
            setAddNewRuleModal={setAddNewRuleModal}
            loading={isLoading}
            destinations={regionDataResponse}
            band={shippingBands}
            products={ecommercePlatform ? productLakeDataResponse : productDataResponse}
            setBanner={setBannerOuter}
            refetch={ruleRefetch}
            moneyFormat={moneyFormat}
          />
        )}
        <Layout.Section>
          <ResourceList
            resourceName={resourceName}
            loading={isLoading}
            renderItem={render}
            items={shippingRulesData}
            onQueryChange={handleSearchChange}
            onQueryClear={handleQueryValueRemove}
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            handleClearAll={handleClearAll}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default ProviderRules;
