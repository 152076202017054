import { gql } from "apollo-boost";

const REGISTER_BY_MOBILE = gql`
  mutation registerByMobile($input: RegisterByMobile) {
    registerByMobile(input: $input) {
      data
      status
      error
    }
  }
`;

export default REGISTER_BY_MOBILE;
