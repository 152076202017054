import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Modal,
  PageActions,
  Select,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { getNames, getCode } from "country-list";
import { State } from "country-state-city";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import helper components
import {
  Banner,
  // Sheet,
  SkeletonAnnotated,
} from "lib/components";
import { baseHelper, errorHelper, featureHelper, storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import yup validations
import validate from "app/userManagement/modules/generic/editProfile/yup/validate";

// import gql
import { EDIT_PROFILE_VENDOR } from "app/userManagement/apollo/mutation/index";
import { FETCH_VENDOR_PROFILE_DATA, GET_VENDOR_PROFILE_SETTING } from "app/userManagement/apollo/queries/index";
import { GET_SELLER_FULFILLMENT } from "app/vendors/apollo/queries";
import { GET_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/queries";

// import subFeature
import editProfileConfig from "./editProfileConfig";
import {
  BrandSection,
  BusinessTaxInfo,
  PaymentSection,
  ShippingSection,
  SocialLinkSection,
  CustomFieldSection,
} from "./subFeature";

const { gql } = constant;

const ProviderEditProfile = () => {
  const { currentUser, isLoading, history, featureFlag, cms } = useContext(PrivateContext);
  const { _id: currentUserId, ecommercePlatform, taxSetting = {}, mobile = {} } = currentUser;
  const textFields = editProfileConfig(cms);
  const { UPDATE_PROFILE, GET_VENDOR_PROFILE_DATA, FETCH_VENDOR_FORM_SETTING } = constant.gql;
  const [updateVendorProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE_VENDOR);
  const [active, setActive] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [sellerId, setSellerId] = useState("");
  const [isAdvanceProfileHidden, setIsAdvanceProfileHidden] = useState(false);
  const [isShowProvinceCode, setShowProvinceCode] = useState(false);

  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [detailUpdatedAt, setDetailUpdateAt] = useState("");
  const [existingImages, setExistingImages] = useState([]);
  const [imagesToBeUpload, setImagesToBeUpload] = useState([]);

  const isBrandHandle = !!(currentUser && currentUser.brand && currentUser.brand.slug);
  const [vendorData, setVendorData] = useState({
    firstName: currentUser && currentUser.firstName,
    lastName: currentUser && currentUser.lastName,
    email: currentUser && currentUser.email && currentUser.email.address,
    phoneNumber: (mobile && mobile.number) || (currentUser && currentUser.phoneNumber),
    streetAddress: currentUser && currentUser.address,
    city: currentUser && currentUser.city,
    country: currentUser && currentUser.country,
    postalCode: currentUser && currentUser.pinCode,
    provinceCode: currentUser && currentUser.provinceCode,
  });

  const [vendorFormSetting, setVendorFormSetting] = useState({});
  const [brandInformation, setBrandInformation] = useState({
    brandName: currentUser && currentUser.brandName,
    slug: currentUser && currentUser.brand && currentUser.brand.slug,
    description: "",
    brandImage: "",
    brandCoverImage: "",
  });

  const [paymentInformation, setPaymentInformation] = useState({
    isEnabled: false,
    bankName: "",
    accountNumber: "",
    sortCode: "",
    country: "",
  });

  const [shippingInformation, setShippingInformation] = useState({
    isEnabled: false,
    isSameAsBusinessAddress: false,
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
  });

  const [socialLinkInformation, setSocialLinkInformation] = useState({
    isEnabled: false,
    facebook: "",
    youtube: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });

  const [businessTaxInformation, setBusinessTaxInformation] = useState({
    company: taxSetting?.company || "",
    vat: taxSetting?.vat || "",
    customs: taxSetting?.customs || "",
    gst: taxSetting?.gst || "",
    pst: taxSetting?.pst || "",
  });

  const [isTaxInfoUpdate, setTaxInfoUpdate] = useState(false);

  const [additionalInformation, setAdditionalInformation] = useState([]);

  const [banner, setBanner] = useState({
    status: "",
    title: "",
    children: null,
    isOpen: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorShippingMessage, setErrorShippingMessage] = useState(false);
  const [errorPaymentMessage, setErrorPaymentMessage] = useState(false);
  const [brandNameData, setBrandNameData] = useState();
  const [brandHandleData, setBrandHandleData] = useState();
  const [firstNameData, setFirstNameData] = useState();
  const [lastNameData, setLastNameData] = useState();
  const [emailData, setEmailData] = useState();
  const [cityData, setCityData] = useState();
  const [countryData, setCountryData] = useState();
  const [postalCodeData, setPostalCodaData] = useState();
  const [phoneNumberData, setPhoneNumberData] = useState();
  const [streetAddressData, setStreetAddressData] = useState();

  // useQuery Calls to fetch data.
  const { loading: fetchLoading, data, error: fetchVendorError, refetch: refetchVendorProfileData } = useQuery(
    FETCH_VENDOR_PROFILE_DATA,
    {
      variables: { input: { vendorId: currentUserId } },
    }
  );

  const { loading: vendorFormLoading, data: vendorFormData, error: vendorFormError } = useQuery(
    GET_VENDOR_PROFILE_SETTING
  );

  const { loading: loadingEditVendorForm, data: dataVendorForm } = useQuery(GET_VENDOR_FORM_LABEL);

  useEffect(() => {
    if (dataVendorForm) {
      const vendorLabelData = baseHelper.getResponseData(dataVendorForm, gql.GET_VENDOR_FORM_LABEL);
      if (vendorLabelData) {
        setBrandNameData(vendorLabelData.brandName);
        setBrandHandleData(vendorLabelData.brandHandle);
        setFirstNameData(vendorLabelData.firstName);
        setLastNameData(vendorLabelData.lastName);
        setEmailData(vendorLabelData.email);
        setCityData(vendorLabelData.city);
        setCountryData(vendorLabelData.country);
        setPostalCodaData(vendorLabelData.postalCode);
        setPhoneNumberData(vendorLabelData.phoneNumber);
        setStreetAddressData(vendorLabelData.streetAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorForm]);

  const updateVendorData = (vendorFieldsData, vendorDetailsData) => {
    const { isShipping = false, isPayment = false, isSocialLink = false, customFields = [] } = vendorFieldsData || {};
    const updatedCustomFields =
      customFields &&
      customFields
        .filter((field) => field)
        .map(({ label, placeholder, inputType, key }) => ({
          label,
          placeholder,
          inputType,
          key,
        }));
    const {
      shipping = false,
      payment = false,
      customFields: customFieldWithValue = [],
      brand = {},
      brandCover = {},
      socialLink = false,
      description = "",
      updatedAt,
    } = vendorDetailsData || {};
    setDetailUpdateAt(updatedAt);
    if (isShipping) {
      setShippingInformation((prevState) => ({
        ...prevState,
        isSameAsBusinessAddress: !!(shipping && shipping.isBizAddress),
        city: (shipping && shipping.city) || "",
        country: (shipping && shipping.country) || "",
        postalCode: (shipping && shipping.pinCode) || "",
        streetAddress: (shipping && shipping.address) || "",
        isEnabled: isShipping,
      }));
    }
    if (isPayment) {
      setPaymentInformation((prevState) => ({
        ...prevState,
        accountNumber: (payment && payment.account) || "",
        bankName: (payment && payment.bank) || "",
        country: (payment && payment.country) || "",
        sortCode: (payment && payment.sortCode) || "",
        isEnabled: isPayment,
      }));
    }
    if (isSocialLink) {
      setSocialLinkInformation((prevState) => ({
        ...prevState,
        facebook: (socialLink && socialLink.facebook) || "",
        youtube: (socialLink && socialLink.youtube) || "",
        twitter: (socialLink && socialLink.twitter) || "",
        instagram: (socialLink && socialLink.instagram) || "",
        linkedin: (socialLink && socialLink.linkedin) || "",
        isEnabled: isSocialLink,
      }));
    }
    setBrandInformation((prevState) => ({
      ...prevState,
      brandImage: (brand && brand.imageUrl) || "",
      brandCoverImage: (brandCover && brandCover.imageUrl) || "",
      description: description || "",
    }));

    setBusinessTaxInformation((prevState) => ({
      ...prevState,
      company: (businessTaxInformation && businessTaxInformation.company) || "",
      vat: (businessTaxInformation && businessTaxInformation.vat) || "",
      gst: (businessTaxInformation && businessTaxInformation.gst) || "",
      pst: (businessTaxInformation && businessTaxInformation.pst) || "",
      customs: (businessTaxInformation && businessTaxInformation.customs) || "",
    }));

    const newAdditionalInformation =
      updatedCustomFields &&
      updatedCustomFields.length &&
      updatedCustomFields.map((item, idx) => {
        const updateItem = { ...item };
        updateItem.value =
          updateItem.inputType === constant.IMAGE
            ? customFieldWithValue && customFieldWithValue[idx] && customFieldWithValue[idx].imageUrls
            : customFieldWithValue && customFieldWithValue[idx] && customFieldWithValue[idx].value;
        if (Array.isArray(updateItem.value)) {
          setExistingImages(updateItem.value);
        }
        return updateItem;
      });
    setAdditionalInformation(newAdditionalInformation);
  };

  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError } = useQuery(
    GET_SELLER_FULFILLMENT
  );

  useEffect(() => {
    if (fulfillmentError) {
      setBanner({
        title: fulfillmentError,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    setShowProvinceCode(showProvinceCode);
  }, [fulfillmentData, fulfillmentError, setBanner]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const responseData = baseHelper.getResponseData(data, GET_VENDOR_PROFILE_DATA);
    const responseError = baseHelper.getResponseError(data, GET_VENDOR_PROFILE_DATA);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { vendorField: vendorFieldsData, vendorDetails: vendorDetailsData, seller = false } = responseData;
    const { dependency = [] } = featureFlag;
    const advanceProfile = dependency && dependency.find((item) => item.key === constant.ADVANCE_VENDOR_PROFILE);

    const advanceProfilePermission = featureHelper.checkFeature({
      currentUser,
      feature: constant.ADVANCE_VENDOR_PROFILE,
      featureFlag: advanceProfile,
    });
    const isProfileHidden = !!(advanceProfilePermission && advanceProfilePermission.error);
    const { _id: id } = seller || {};
    const idOfSeller = seller && baseHelper.mongoIdAsString(id);
    setSellerId(idOfSeller);
    setIsAdvanceProfileHidden(!seller || isProfileHidden);
    updateVendorData(vendorFieldsData, vendorDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, GET_VENDOR_PROFILE_DATA]);

  useEffect(() => {
    if (fetchVendorError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(fetchVendorError) });
      return;
    }

    if (vendorFormError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(vendorFormError) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVendorError, vendorFormError, errorHelper]);

  useEffect(() => {
    if (!vendorFormData) {
      return;
    }

    const responseData = baseHelper.getResponseData(vendorFormData, FETCH_VENDOR_FORM_SETTING);
    const responseError = baseHelper.getResponseError(vendorFormData, FETCH_VENDOR_FORM_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { vendorForm = {} } = responseData || {};
    setVendorFormSetting(vendorForm);
  }, [vendorFormData, FETCH_VENDOR_FORM_SETTING]);

  const handleChangeEvent = (field, value) => {
    setIsSubmitEnabled(true);
    setVendorData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleValidation = async (field, value, label) => {
    setIsSubmitEnabled(true);
    let schema = {};
    const { provinceCode: { isRequired: provinceCodeRequired = false } = {}, postalCode: { min = 2 } = {} } =
      vendorFormSetting || {};
    if (field === constant.PROVINCE_CODE && !provinceCodeRequired) {
      return;
    }
    if (field === constant.POSTAL_CODE) {
      schema = { min };
    }
    const error = await validate(field, value, cms, schema, label);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const handleBrandInformation = (field, value) => {
    setIsSubmitEnabled(true);

    const isImage = [constant.BRAND_IMAGE, constant.BRAND_COVER_IMAGE].includes(field);

    setBrandInformation((prevState) => ({
      ...prevState,
      [field]: isImage && !(value && value.length) ? "" : value,
    }));
    if (field === constant.BRAND_NAME && !isBrandHandle) {
      const brandHandleAccurateValue = baseHelper.generateBrandHandle(value);
      handleBrandInformation(constant.BRAND_SLUG, brandHandleAccurateValue);
    }
  };

  const handlePaymentInformation = (field, value) => {
    setIsSubmitEnabled(true);
    setPaymentInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleShippingInformation = (field, value) => {
    setIsSubmitEnabled(true);
    if (field === constant.IS_SAME_BUSINESS_ADDRESS) {
      shippingInformation.city = value ? vendorData.city : "";
      shippingInformation.country = value ? vendorData.country : "";
      shippingInformation.postalCode = value ? vendorData.postalCode : "";
      shippingInformation.streetAddress = value ? vendorData.streetAddress : "";
    }
    setIsSubmitEnabled(true);
    setShippingInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleShippingValidation = async (field, value) => {
    const error = await validate(field, value, cms);
    setErrorShippingMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const handleSocialLinkInformation = (field, value) => {
    setIsSubmitEnabled(true);
    setSocialLinkInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePaymentValidation = async (field, value) => {
    const error = await validate(field, value, cms);
    setErrorPaymentMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };
  const handleImage = (prevValue = [], newValue) => {
    const manipulateImage = newValue.map((value) => {
      return { url: value };
    });
    const updatedPrevValue = (prevValue || []).map((value) => {
      if (value.url) {
        return value;
      }
      return { url: value };
    });
    const imageArray = [...(updatedPrevValue || []), ...manipulateImage];
    setImagesToBeUpload(imageArray);
    return imageArray;
  };
  const handleAdditionalSectionChange = (key, value, idx) => {
    setIsSubmitEnabled(true);
    const updatedAdditionalInformation = additionalInformation.map((field, index) => {
      const updateField = { ...field };
      if (field.key === key && idx === index) {
        if (field.inputType === constant.IMAGE && Array.isArray(value)) {
          updateField.value = handleImage(field.value, value) || [];
        } else if (field.inputType !== constant.IMAGE && typeof value === "string") {
          updateField.value = value;
        }
      }
      return updateField;
    });
    setAdditionalInformation([...updatedAdditionalInformation]);
  };

  const handleImageRemove = (value) => {
    setIsSubmitEnabled(true);
    const { position, url } = value;
    const updatedAdditionalInformation = [...additionalInformation];
    const existingImagesData = existingImages.filter((image) => image !== url);
    updatedAdditionalInformation.forEach((field) => {
      if (field.inputType === constant.IMAGE) {
        field.value.splice(position, 1);
      }
    });
    setExistingImages([...existingImagesData]);
    setAdditionalInformation([...updatedAdditionalInformation]);
  };

  const handleBusinessTaxInfoChange = (field, value) => {
    setTaxInfoUpdate(true);
    setIsSubmitEnabled(true);
    setBusinessTaxInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleClose = useCallback(() => setActive(!active), [active]);

  const vendorDetailsRequestData = () => {
    const {
      streetAddress,
      postalCode,
      isSameAsBusinessAddress,
      city: shippingCity,
      country: shippingCountry,
      isEnabled: isShippingEnabled,
    } = shippingInformation;

    const shippingData = {
      address: streetAddress,
      pinCode: postalCode,
      country: shippingCountry,
      city: shippingCity,
      isBizAddress: isSameAsBusinessAddress,
      isEnabled: isShippingEnabled,
    };
    const emptyImageUrl = { url: "" };
    const customFields =
      additionalInformation &&
      additionalInformation.length &&
      additionalInformation.map((item) => {
        if (item.inputType === constant.IMAGE) {
          const updateItem = { ...item };
          const images = item.value || [];
          updateItem.value = images.map((image) => {
            if (!image.url) {
              return {
                url: image,
              };
            }
            return image;
          });
        }
        const imageUrls = (item.inputType === constant.IMAGE && item.value) || [emptyImageUrl];
        const updatedImageUrls = ((_.isArray(imageUrls) && imageUrls) || [])
          .map((imageUrl) => {
            if (_.isString(imageUrl)) {
              return { url: imageUrl };
            }
            return imageUrl;
          })
          // eslint-disable-next-line no-shadow
          .filter((item) => item && item.url);
        return {
          label: item.label,
          key: item.key,
          inputType: item.inputType,
          value: (item.inputType !== constant.IMAGE && item.value) || "",
          imageUrls: [...updatedImageUrls],
          multimediaUrls: [],
          placeholder: item.placeHolder,
        };
      });
    const {
      bankName,
      country: paymentCountry,
      sortCode,
      accountNumber,
      isEnabled: isPaymentEnabled,
    } = paymentInformation;
    const paymentData = {
      sortCode,
      bank: bankName,
      account: accountNumber,
      country: paymentCountry,
      isEnabled: isPaymentEnabled,
    };

    const { facebook, twitter, instagram, linkedin, youtube, isEnabled: isSocialLink } = socialLinkInformation;
    const socialLinkData = {
      facebook,
      twitter,
      instagram,
      linkedin,
      youtube,
      isEnabled: isSocialLink,
    };
    const { brandImage, brandCoverImage, description } = brandInformation;
    const dataToUpdate = {
      customFields: customFields || [],
      payment: paymentData,
      shipping: shippingData,
      socialLink: socialLinkData,
      description: description || "",
      sellerId,
    };
    const isBrandImage = (Array.isArray(brandImage) && brandImage.length && brandImage[0]) || brandImage || null;
    const isBrandCoverImage =
      (Array.isArray(brandCoverImage) && brandCoverImage.length && brandCoverImage[0]) || brandCoverImage || null;
    if (isBrandImage) {
      dataToUpdate.brandImage = {
        url: isBrandImage,
      };
    }
    if (isBrandCoverImage) {
      dataToUpdate.brandCoverImage = {
        url: isBrandCoverImage,
      };
    }
    return dataToUpdate;
  };

  const editProfile = async () => {
    const vendorDetailData = vendorDetailsRequestData();
    try {
      const res = await updateVendorProfile({
        variables: {
          input: {
            formValues: {
              firstName: vendorData.firstName,
              lastName: vendorData.lastName,
              email: vendorData.email,
              phoneNumber: (mobile && mobile.number) || vendorData.phoneNumber.toString(),
              brandName: brandInformation.brandName,
              address: vendorData.streetAddress,
              city: vendorData.city,
              country: vendorData.country,
              pinCode: vendorData.postalCode,
              provinceCode: vendorData.provinceCode,
              brand: {
                slug: brandInformation.slug,
              },
            },
            isTaxInfoUpdate,
            taxInfo: {
              company: businessTaxInformation.company,
              customs: businessTaxInformation.customs,
              vat: businessTaxInformation.vat,
              gst: businessTaxInformation.gst,
              pst: businessTaxInformation.pst,
            },
            dataToUpdate: vendorDetailData,
          },
        },
      });
      const responseError = baseHelper.getResponseError(res.data, UPDATE_PROFILE);
      if (responseError) {
        setBanner({
          title: responseError,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }
      if (isEmailChanged) {
        setActive(false);
        setBanner({
          title: cms("message.success.profileUpdatedVerifyEmail"),
          children: cms("message.success.redirect"),
          status: constant.SUCCESS,
          isOpen: true,
        });
        storageHelper.remove("token");
        setTimeout(() => {
          history.push({
            pathname: "/login",
            state: { loginVia: mobile && mobile.number ? "phone" : "email" },
          });
        }, 10000);
      } else {
        const bannerValue = {
          title: cms("message.success.profileUpdated"),
          status: constant.SUCCESS,
          isOpen: true,
        };
        const brandName = currentUser && currentUser.brandName;
        const brandSlug = currentUser && currentUser.brand && currentUser.brand.slug;
        if (brandName !== brandInformation.brandName || brandSlug !== brandInformation.slug) {
          bannerValue.children = ecommercePlatform
            ? cms("message.success.pushToEcommerce", { item: baseHelper.ucFirst(ecommercePlatform) })
            : cms("message.success.pushToShopify");
        }
        setBanner(bannerValue);
        setIsSubmitEnabled(false);
        refetchVendorProfileData();
      }
    } catch (exception) {
      setActive(false);
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
        isOpen: true,
      });
    }
  };

  const updateEmailModal = () => {
    return (
      <Modal
        open={active}
        onClose={handleClose}
        title={cms("section.editProfile.emailChangeModal.title")}
        primaryAction={{
          content: cms("section.editProfile.emailChangeModal.button.primary"),
          onAction: () => editProfile(),
          loading: updateProfileLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <TextContainer>
                <p>{cms("section.editProfile.emailChangeModal.content")}</p>
              </TextContainer>
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    );
  };

  const showErrorForEmptyFields = () => {
    Object.keys(vendorData).map((item) => {
      if (!vendorData[item]) {
        return handleValidation(item, "");
      }
      return false;
    });
    const { brandImage, brandCoverImage, description, ...requiredBrandValues } = brandInformation;
    Object.keys(requiredBrandValues).map((item) => {
      if (!brandInformation[item]) {
        return handleValidation(item, "");
      }
      return false;
    });
    if (!(isAdvanceProfileHidden || brandInformation.description)) {
      return handleValidation(constant.BRAND_DESCRIPTION, "");
    }
    if (shippingInformation.isEnabled && !shippingInformation.isSameAsBusinessAddress) {
      const { isEnabled, isSameAsBusinessAddress, postalCode, ...requiredShippingValues } = shippingInformation;
      Object.keys(requiredShippingValues).map((item) => {
        if (!shippingInformation[item]) {
          return handleShippingValidation(item, "");
        }
        return false;
      });
    }
    if (paymentInformation.isEnabled) {
      const { isEnabled, ...requiredPaymentValues } = paymentInformation;
      Object.keys(requiredPaymentValues).map((item) => {
        if (!paymentInformation[item]) {
          return handlePaymentValidation(item, "");
        }
        return false;
      });
    }
    return false;
  };
  const paymentValidation = () => {
    let paymentEmptyField = false;
    let isPaymentError = false;
    if (paymentInformation.isEnabled) {
      const { isEnabled, ...requiredPaymentValues } = paymentInformation;
      paymentEmptyField = Object.values(requiredPaymentValues).some((value) => !value);
      isPaymentError = Object.values(errorPaymentMessage).some((error) => error);
    }
    return paymentEmptyField || isPaymentError;
  };
  const shippingValidation = () => {
    let isShippingEmpty = false;
    let isShippingError = false;
    if (!isAdvanceProfileHidden && shippingInformation.isEnabled) {
      const { isEnabled, isSameAsBusinessAddress, postalCode, ...requiredShippingValues } = shippingInformation;
      isShippingEmpty = Object.values(requiredShippingValues).some((value) => !value);
      isShippingError = Object.values(errorShippingMessage).some((error) => error);
    }
    return isShippingEmpty || isShippingError;
  };
  const vendorValidation = () => {
    const { provinceCode: { isRequired: provinceCodeRequired = false } = {} } = vendorFormSetting || {};
    const vendorRequiredData = { ...vendorData };

    if (!provinceCodeRequired) {
      delete vendorRequiredData.provinceCode;
    }

    const vendorFormEmptyField = Object.values(vendorRequiredData).some((value) => !value);
    // eslint-disable-next-line no-shadow
    const vendorFormError = Object.values(errorMessage).some((error) => error);
    return vendorFormEmptyField || vendorFormError;
  };
  const brandValidation = () => {
    const { brandImage, brandCoverImage, description, ...requiredBrandValues } = brandInformation;
    const brandValues = (!isAdvanceProfileHidden && { ...requiredBrandValues, description }) || requiredBrandValues;
    const brandEmptyEmptyField = Object.values(brandValues).some((value) => !value);
    return brandEmptyEmptyField;
  };

  const isAnyEmptyField = () => {
    const isVendorError = vendorValidation();
    const isBrandError = brandValidation();
    const isPaymentError = paymentValidation();
    const isShippingError = shippingValidation();
    const isAnyError = isVendorError || isBrandError || isPaymentError || isShippingError;
    return isAnyError;
  };

  const onSubmit = () => {
    setIsSubmitEnabled(false);
    showErrorForEmptyFields();
    if (isAnyEmptyField() || !(isAdvanceProfileHidden || brandInformation.description)) {
      return;
    }
    if (currentUser.email.address !== vendorData.email && !(mobile && mobile.verified)) {
      setIsEmailChanged(true);
      setActive(true);
    } else {
      editProfile();
    }
  };

  const getLabelData = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  const getPlaceholderText = (value) => {
    if (value && value.label) {
      return value.label;
    }
    return "";
  };

  const getFields = (text) => {
    const newTextField = {};
    newTextField[constant.FIRST_NAME] = firstNameData;
    newTextField[constant.LAST_NAME] = lastNameData;
    newTextField[constant.EMAIL] = emailData;
    newTextField[constant.CITY] = cityData;
    newTextField[constant.COUNTRY] = countryData;
    newTextField[constant.POSTAL_CODE] = postalCodeData;
    newTextField[constant.PHONE_NUMBER] = phoneNumberData;
    newTextField[constant.STREET_ADDRESS] = streetAddressData;

    const stateList = State.getStatesOfCountry(getCode(vendorData.country || "") || "").map((item) => ({
      label: item.name,
      value: item.isoCode,
    }));

    return (
      text
        // .filter((item) => (item.key === constant.PROVINCE_CODE ? isShowProvinceCode : true))
        .map((textField) =>
          ![constant.COUNTRY, constant.PROVINCE_CODE].includes(textField.key) ? (
            <TextField
              key={textField.key}
              type={textField.type}
              id={textField.key}
              label={`${getLabelData(newTextField[textField.key]) || textField.label}*`}
              placeholder={getPlaceholderText(newTextField[textField.key]) || textField.placeholder}
              value={(vendorData[textField.key] && vendorData[textField.key].toString()) || ""}
              min={textField.min}
              onChange={(value) => handleChangeEvent(textField.key, value)}
              onBlur={() =>
                handleValidation(
                  textField.key,
                  vendorData[textField.key],
                  newTextField[textField.key] && newTextField[textField.key].label || textField.label
                )
              }
              error={errorMessage && errorMessage[textField.key]}
            />
          ) : (
            <Select
              label={`${getLabelData(newTextField[textField.key]) || textField.label}*`}
              value={(vendorData[textField.key] && vendorData[textField.key].toString()) || ""}
              options={textField.key === constant.COUNTRY ? getNames().sort() : stateList}
              placeholder={getPlaceholderText(newTextField[textField.key]) || textField.placeholder}
              onChange={(value) => handleChangeEvent(textField.key, value)}
              onBlur={() => handleValidation(textField.key, vendorData[textField.key])}
            />
          )
        )
    );
  };
  // const learnMore = (title, content) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(content);
  // };

  if (isLoading || fetchLoading || vendorFormLoading || loadingEditVendorForm || fulfillmentLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {updateEmailModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false, children: null });
            }}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <BrandSection
        brandNameData={brandNameData}
        brandHandleData={brandHandleData}
        brandInformation={brandInformation}
        errorMessage={errorMessage}
        handleChange={handleBrandInformation}
        handleValidation={handleValidation}
        isAdvanceProfileHidden={isAdvanceProfileHidden}
        // learnMore={learnMore}
      />
      <Layout.Section>
        <Layout.AnnotatedSection
          title={cms("section.editProfile.title")}
          description={cms("section.editProfile.description")}
        >
          <Card
            title={[
              cms("section.editProfile.title"),
              // lastUpdated && <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>,
            ]}
            // actions={[
            //   {
            //     content: "Learn More",
            //     onAction: () => {
            //       learnMore(cms("section.editProfile.title"), "TODO:");
            //     },
            //   },
            // ]}
          >
            <Card.Section>
              <FormLayout>
                <TextContainer>You can change all of it within the platform at any point in the future.</TextContainer>
                {getFields(textFields)}
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>

      {!isAdvanceProfileHidden && shippingInformation.isEnabled && (
        <Layout.Section>
          <ShippingSection
            handleChange={handleShippingInformation}
            shippingInformation={shippingInformation}
            handleShippingValidation={handleShippingValidation}
            errorShippingMessage={errorShippingMessage}
            // learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && paymentInformation.isEnabled && (
        <Layout.Section>
          <PaymentSection
            handleChange={handlePaymentInformation}
            paymentInformation={paymentInformation}
            handlePaymentValidation={handlePaymentValidation}
            errorPaymentMessage={errorPaymentMessage}
            // learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && socialLinkInformation.isEnabled && (
        <Layout.Section>
          <SocialLinkSection
            handleChange={handleSocialLinkInformation}
            socialLinkInformation={socialLinkInformation}
            // learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && !!(additionalInformation && additionalInformation.length) && (
        <Layout.Section>
          <CustomFieldSection
            handleChange={handleAdditionalSectionChange}
            data={[...additionalInformation]}
            filelist={imagesToBeUpload}
            handleImageRemove={handleImageRemove}
            // learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
            existingImages={existingImages}
          />
        </Layout.Section>
      )}
      {!ecommercePlatform && (
        <Layout.Section>
          <div style={{ marginLeft: "36%" }}>
            <Card title={cms("section.vendorOverseas.title")}>
              <Card.Section>
                <TextContainer>
                  <p>{cms("section.vendorOverseas.description")}</p>
                </TextContainer>
                <br />
                <FormLayout>
                  <Checkbox
                    label={cms("common.label.overseas")}
                    checked={(vendorFormData?.getVendorFormSetting?.data?.vendor?.overseasVendor || []).includes(
                      currentUserId
                    )}
                    disabled
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </div>
        </Layout.Section>
      )}
      <Layout.Section>
        <BusinessTaxInfo
          handleChange={handleBusinessTaxInfoChange}
          businessTaxInformation={businessTaxInformation}
          lastUpdated={detailUpdatedAt}
        />
      </Layout.Section>
      {/* <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={{
          content: cms("common.label.done"),
          onAction: () => setSheetActive(false),
        }}
        secondaryAction={{
          content: cms("common.button.cancel"),
          onAction: () => setSheetActive(false),
        }}
      >
        {sheetContent}
      </Sheet> */}
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            key: "submitButton",
            onAction: () => onSubmit(),
            loading: !isEmailChanged && updateProfileLoading,
            disabled: updateProfileLoading || !isSubmitEnabled || isAnyEmptyField(),
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => history.push("/setting"),
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderEditProfile), { feature: constant.VENDOR_ADD });
