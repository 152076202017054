import React, { useContext, useState } from "react";
import { Heading, Stack, TextContainer } from "@shopify/polaris";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { ThirdPartyOptions } from "./subFeatures";

const DefaultConsignmentPickup = () => {
  const { cms } = useContext(PrivateContext);
  const { consignmentPickup } = useContext(FulfillmentContext);
  const [hopScotch, setHopScotch] = useState(consignmentPickup && consignmentPickup.hopScotch);
  const [selected, setSelected] = useState((consignmentPickup && consignmentPickup.shippingOption) || "");

  // const { value } = props;

  // const handleConsignmentPickup = (fieldName, val) => {
  //   setConsignmentPickup((prevState) => ({
  //     ...prevState,
  //     [fieldName]: val,
  //   }));
  //   setSelected("");
  //   setSubmitEnable(false);
  //   if (!val) {
  //     setSubmitEnable(true);
  //   }
  // };

  return (
    <div>
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("consignmentPickup.method")}</Heading>
          </Stack.Item>
        </Stack>
      </TextContainer>
      <br />
      {/* <RadioButton
        label={cms("label.manageShipping")}
        helpText={cms("label.manageText")}
        checked={!(consignmentPickup && consignmentPickup.isThirdPartyShipping)}
        onChange={() => {
          handleConsignmentPickup(
            "isThirdPartyShipping",
            !(consignmentPickup && consignmentPickup.isThirdPartyShipping)
          );
        }}
      />

      <RadioButton
        label={cms("label.thirdPartyText")}
        helpText={cms("label.thirdPartyText")}
        checked={consignmentPickup && consignmentPickup.isThirdPartyShipping}
        onChange={() => {
          handleConsignmentPickup(
            "isThirdPartyShipping",
            !(consignmentPickup && consignmentPickup.isThirdPartyShipping)
          );
        }}
      /> */}

      {/* {consignmentPickup && consignmentPickup.isThirdPartyShipping && value === constant.PICKUP && ( */}
      <div>
        {/* <Heading> Third-party Consignment Pickup</Heading> */}
        <ThirdPartyOptions
          selected={selected}
          setSelected={setSelected}
          hopScotch={hopScotch}
          setHopScotch={setHopScotch}
        />
      </div>
      {/* )} */}
    </div>
  );
};

// DefaultConsignmentPickup.propTypes = {
//   value: PropTypes.string.isRequired,
// };

export default withFeature(withErrorBoundary(DefaultConsignmentPickup), { feature: constant.CONSIGNMENT_PICKUP });
