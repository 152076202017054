import React, { useContext } from "react";
import {
  Caption,
  Card,
  Collapsible,
  ColorPicker,
  Layout,
  Stack,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const Heading = () => {
  const {
    color,
    errorMessage,
    handleChange,
    hex,
    // learnMore,
    resetDefault,
  } = useContext(BrandingContext);
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { updatedAt = "" } = currentUser || {};
  const { HEX, COLOR, HEADING, DEFAULT_HEADING_COLOR } = constant;

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.headingColor.title")}
        description={cms("section.headingColor.description")}
      >
        <Card
          title={[
            cms("section.headingColor.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.headingColor.subDescription")}</TextContainer>

            <br />
            <Stack>
              <Stack.Item fill>
                <TextField
                  label={cms("section.headingColor.label.hexCode")}
                  value={hex.headingHex}
                  onChange={(value) => handleChange(HEADING, value, HEX)}
                  labelAction={
                    hex.headingHex !== DEFAULT_HEADING_COLOR && {
                      content: cms("label.reset"),
                      onClick: () => resetDefault(HEADING, DEFAULT_HEADING_COLOR, HEX),
                    }
                  }
                  error={errorMessage && errorMessage.heading}
                />
                <br />
                <TextStyle>
                  <div
                    style={{
                      width: "120px",
                      height: "50px",
                      color: hex.headingHex,
                      textAlign: "center",
                      paddingTop: "13px",
                    }}
                  >
                    {cms("section.headingColor.label.sampleHeader")}
                  </div>
                </TextStyle>
              </Stack.Item>
              <Stack.Item>
                <ColorPicker onChange={(value) => handleChange(HEADING, value, COLOR)} color={color.headingColor} />
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withErrorBoundary(Heading);
