import { gql } from "apollo-boost";

const GET_ADDRESS = gql`
  query GetAddressList($input: GetListInput) {
    getAddress(input: $input) {
      data {
        addressList {
          address
          userId
          city
          country
          countryCode
          isDefault
          label
          postalCode
          _id
          updatedAt
        }
        count
        isFirstAddress
      }
      status
      error
    }
  }
`;

export default GET_ADDRESS;
