import React, { useContext, useState } from "react";
import { Card, FormLayout, Layout, PageActions, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import component
import { Banner, Sheet, Toast } from "lib/components";
import FileUpload from "lib/components/fileInput/fileUpload";

// import mutation
import { BULK_ORDER_FULFILL } from "app/orders/apollo/mutations";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { withErrorBoundary } from "lib/hoc";
import ExportOrder from "./export";

const BulkOrderFulfillment = () => {
  const { cms, history, currentUser } = useContext(PrivateContext);
  const [sheetActive, setSheetActive] = useState(false);
  const [values, setValues] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { CRITICAL, gql } = constant;
  const [disabledButton, setDisabledButton] = useState(true);
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });

  const isVendor = baseHelper.isVendor(currentUser);

  const [bulkProductUploadRequest, { loading: uploadFileLoading }] = useMutation(BULK_ORDER_FULFILL);

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  //   if (productLoading) {
  //     return <SkeletonCard />;
  //   }

  const getCSVData = (csvValue) => {
    const regexForEnterKey = /\n,|\n/gi;
    const csvDataAfterRegex = csvValue.data.replace(regexForEnterKey, "\n");
    return csvDataAfterRegex;
  };

  const onSubmit = async () => {
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.csvIsRequired"),
        status: CRITICAL,
      });
      return;
    }
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: modifiedFormat,
        fileName: values.fileName,
      },
    };
    try {
      const res = await bulkProductUploadRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, "bulkFulfillOrderCSV");

      let bannerData = {
        isOpen: true,
        status: constant.SUCCESS,
        title: "File upload status",
        children:
          "Bulk edit order upload is running in the background please click below and determine if the upload was a success.",
        action: {
          content: "Review activity logs",
          onAction: () => history.push("/activity"),
        },
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, "bulkFulfillOrderCSV");
        bannerData = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: error,
        };
      }
      setDisabledButton(true);
      setBanner(bannerData);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
      setDisabledButton(true);
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const exportAction = {
    content: "Export CSV",
    onAction: () => setIsOpenModal(true),
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection
        title="Fulfill your orders"
        description="You can bulk fulfill orders by uploading a CSV file."
      >
        <ExportOrder isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} isVendor={isVendor} />
        <Card
          title={["Bulk fulfill orders using a CSV"]}
          // actions={[
          //   {
          //     content: "Export CSV",
          //     onAction: () => setIsOpenModal(true),
          //   },
          // ]}
          id="productAttachment"
        >
          <Card.Section>
            <p>
              If you have a CS file that was exported from orders or came from another source, make sure it matches the
              CS file format. If the format doesn't match, or if there are any missing headers, then the import will
              fail.
            </p>
            <br />
            <FormLayout>
              <TextContainer>{cms("section.form.section.price.description")}</TextContainer>
              <FileUpload
                setValue={(val) => [setValues(val), setDisabledButton(false)]}
                values={values}
                isLabelVisible={false}
                setDisabledButton={setDisabledButton}
                exportAction={exportAction}
              />
            </FormLayout>
            <div className="toast">
              <Toast message={message} />
            </div>
            <PageActions
              primaryAction={{
                content: "Upload",
                id: "submitButton",
                onAction: () => onSubmit(),
                loading: uploadFileLoading,
                disabled: uploadFileLoading || disabledButton,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  id: "cancelButton",
                  onAction: () => history.push("/"),
                },
              ]}
            />
          </Card.Section>
        </Card>
        <Sheet
          // title={sheetTitle}
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        >
          {/* {sheetContent} */}
        </Sheet>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(BulkOrderFulfillment);
