import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import {
  TextField,
  Checkbox,
  Card,
  Layout,
  PageActions,
  DescriptionList,
  TextContainer,
  Modal,
  Button,
} from "@shopify/polaris";
import { useMutation } from "react-apollo";
import validate from "app/appPermission/generic/yup";
import { PrivateContext } from "lib/context";
import { Banner } from "lib/components";
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { errorHelper, storageHelper } from "lib/helpers";
import baseHelper from "lib/helpers/base";

import OLD_TO_NEW_SHOPIFY_INSTALL_SELLER_LOGIN from "app/appPermission/apollo/mutation/oldToNewShopifySellerLogin";

const OperatorUpgradAppPermission = (props) => {
  const { cms = {}, history, currentUser } = useContext(PrivateContext);
  const {
    gql,
    STEP,
    SETTINGS,
    SHOW,
    HIDE,
    API_ACCESS_TOKEN,
    REGISTERSUBDOMIN,
    API_APP_KEY,
    API_APP_SECRET_KEY,
    onboardingSteps,
  } = constant;
  const totalCheckboxes = 16;
  const adminScopesKey = "adminScopes";
  const installAppKey = "installApp";
  const { setSelectedTab } = props;

  const [errorMessage, setErrorMessage] = useState(false);
  const [shopifyInstallAndSellerLogin, { loading }] = useMutation(OLD_TO_NEW_SHOPIFY_INSTALL_SELLER_LOGIN);
  const myContainer = useRef(null);
  const [active, setActive] = useState(true);

  const handleChangeStatus = useCallback(() => setActive(!active), [active]);

  const activator = <Button onClick={handleChangeStatus}>Open</Button>;
  const [banner, setBanner] = useState({
    action: null,
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [isInputVisible, setIsInputVisible] = useState(false);

  const [values, setValues] = useState({
    goListingsPage: {
      checked: false,
      disable: false,
      order: 1,
      type: STEP,
      title: cms("common.appPermission.label.goListingsPage"),
    },
    developApps: {
      checked: false,
      disable: true,
      order: 2,
      type: STEP,
      title: cms("common.appPermission.label.developApps"),
    },
    customAppDev: {
      checked: false,
      disable: true,
      order: 3,
      type: STEP,
      title: cms("common.appPermission.label.customAppDev"),
    },
    appMarketcube: {
      checked: false,
      disable: true,
      order: 4,
      type: STEP,
      title: cms("common.appPermission.label.appMarketcube"),
    },
    adminScopes: {
      checked: false,
      disable: true,
      order: 5,
      type: STEP,
      title: cms("common.appPermission.label.adminScopes"),
    },
    read_customers: {
      checked: false,
      disable: true,
      order: 6,
      type: SETTINGS,
      title: cms("common.appPermission.customers.title"),
      helpText: cms("common.appPermission.customers.helpText"),
    },
    read_discounts: {
      checked: false,
      disable: true,
      order: 7,
      type: SETTINGS,
      title: cms("common.appPermission.discounts.title"),
      helpText: cms("common.appPermission.discounts.helpText"),
    },
    write_fulfillments: {
      checked: false,
      disable: true,
      order: 8,
      type: SETTINGS,
      title: cms("common.appPermission.fulfillments.title"),
      helpText: cms("common.appPermission.fulfillments.helpText"),
    },
    write_inventory: {
      checked: false,
      disable: true,
      order: 9,
      type: SETTINGS,
      title: cms("common.appPermission.inventory.title"),
      helpText: cms("common.appPermission.inventory.helpText"),
    },
    read_locations: {
      checked: false,
      disable: true,
      order: 10,
      type: SETTINGS,
      title: cms("common.appPermission.locations.title"),
      helpText: cms("common.appPermission.locations.helpText"),
    },
    write_order_edits: {
      checked: false,
      disable: true,
      order: 11,
      type: SETTINGS,
      title: cms("common.appPermission.orderEdits.title"),
      helpText: cms("common.appPermission.orderEdits.helpText"),
    },
    read_orders: {
      checked: false,
      disable: true,
      order: 12,
      type: SETTINGS,
      title: cms("common.appPermission.orders.title"),
      helpText: cms("common.appPermission.orders.helpText"),
    },
    read_product_listings: {
      checked: false,
      disable: true,
      order: 13,
      type: SETTINGS,
      title: cms("common.appPermission.productListings.title"),
      helpText: cms("common.appPermission.productListings.helpText"),
    },
    write_products: {
      checked: false,
      disable: true,
      order: 14,
      type: SETTINGS,
      title: cms("common.appPermission.products.title"),
      helpText: cms("common.appPermission.products.helpText"),
    },
    write_shipping: {
      checked: false,
      disable: true,
      order: 15,
      type: SETTINGS,
      title: cms("common.appPermission.shipping.title"),
      helpText: cms("common.appPermission.shipping.helpText"),
    },
    write_fulfillment_edit: {
      checked: false,
      disable: true,
      order: 16,
      type: SETTINGS,
      title: 'Merchant-managed fulfillment orders',
      helpText: `write_merchant_managed_fulfillment_orders, 
                 read_merchant_managed_fulfillment_orders`,
    },
    installApp: { checked: false, disable: true, order: 17, title: cms("common.appPermission.label.installApp") },
  });

  const [apiDetails, setApiDetails] = useState({
    shopSubDomain: currentUser?.shop,
    apiAccessToken: "",
    apiKey: "",
    apiSecretKey: "",
  });

  const onNext = () => {
    setSelectedTab(onboardingSteps.FINISH);
  };

  const toggleBanner = () =>
    setBanner({
      action: null,
      isOpen: false,
      status: "",
      title: "",
      onDismiss: null,
    });

  const handleChange = (value) => {
    // step by step checkbox
    const updateData = {};
    if (values[value].type === STEP) {
      updateData[value] = { ...values[value], checked: true, disable: true };
      const nextCheckbox = Object.keys(values)[values[value].order];

      if (values[nextCheckbox].type === STEP) {
        updateData[nextCheckbox] = { ...values[nextCheckbox], checked: false, disable: false };
      }
      // permission checkbox all disable remove
      if (value === adminScopesKey) {
        Object.keys(values).forEach((item) => {
          if (values[item].type === SETTINGS) {
            updateData[item] = { ...values[item], disable: false };
          }
        });
      }
    } else if (values[value].type === SETTINGS) {
      updateData[value] = { ...values[value], checked: !values[value].checked, disable: false };
    }

    // end step by step

    // disable install app checkbox if all top checkbox not checked
    const updatedDataSet = { ...values, ...updateData };
    const listChecked = Object.keys(updatedDataSet).filter(
      (data) => updatedDataSet[data].checked && data !== installAppKey
    );
    if (listChecked.length === totalCheckboxes) {
      updateData.installApp = { ...values.installApp, disable: false };
      if (value === installAppKey) {
        updateData.installApp = { ...values.installApp, checked: !values[value].checked, disable: false };
      }
    } else {
      updateData.installApp = { ...values.installApp, checked: false, disable: true };
    }

    setValues((prevState) => ({
      ...prevState,
      ...updateData,
    }));
  };

  const validShop = (shop) => {
    if (!shop) {
      return false;
    }

    const isValidShop = shop.endsWith(".myshopify.com");
    const shopIndex = shop.indexOf(".myshopify.com");

    if (shopIndex <= -1 || !isValidShop) {
      return shop;
    }
    const shopName = shop.slice(0, shopIndex);
    return shopName;
  };

  const handleField = (field, value) => {
    setApiDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleValidation = async (field, value) => {
    const schema = {};
    // removing myshopify.com in shop name
    if (field === REGISTERSUBDOMIN) {
      setApiDetails((prevState) => ({
        ...prevState,
        [field]: validShop(value),
      }));
    }

    const validationError = await validate(field, value, cms, schema);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };
  const isAnyError = () => Object.values(errorMessage).some((error) => error);

  const isAllValuesFilled = () =>
    Object.keys(values).every((key) => {
      if (values[key].checked === false) {
        return false;
      }
      return typeof values[key].checked === "boolean" ? true : values[key];
    });

  const isAllAPIValuesFilled = () =>
    Object.keys(apiDetails).every((key) => {
      if (!apiDetails[key]) {
        return false;
      }
      return typeof apiDetails[key] === "boolean" ? true : apiDetails[key];
    });
  const onTopScreen = () => {
    setTimeout(() => {
      document.getElementsByClassName("Polaris-Layout")[0].scrollIntoView(true);
    }, 500);
  };
  const onSubmit = async () => {
    try {
      onTopScreen();
      const res = await shopifyInstallAndSellerLogin({
        variables: {
          input: {
            shopSubDomain: `${
              apiDetails?.shopSubDomain.trim() + cms("common.appPermission.shop.suffix.shopSubDomain")
            }`,
            apiAccessToken: apiDetails?.apiAccessToken.trim(),
            apiKey: apiDetails?.apiKey.trim(),
            apiSecretKey: apiDetails?.apiSecretKey.trim(),
          },
        },
      });

      const responseData = baseHelper.getResponseData(res.data, gql.OLD_TO_NEW_SHOPIFY_SELLER_LOGIN_KEY);
      if (responseData?.token) {
        storageHelper.set("token", responseData.token);
        setApiDetails({ shopSubDomain: "", apiAccessToken: "", apiKey: "", apiSecretKey: "" });

        // onNext();
        setTimeout(() => {
          history.push("/");
        }, 1000);
        storageHelper.remove("customAppUpdate");
      }

      let bannerValue = {
        status: constant.SUCCESS,
        title: cms("common.appPermission.message.success.select"),
        children: "",
        onDismiss: toggleBanner,
      };

      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.OLD_TO_NEW_SHOPIFY_SELLER_LOGIN_KEY);
        bannerValue = { status: constant.CRITICAL, title: errorResponse };
        storageHelper.set("customAppUpdate", "updated");
      }

      setBanner({
        ...bannerValue,
        isOpen: true,
      });

      /// end some error come
    } catch (exception) {
      console.log("🚀 ~ file: OperatorUpgradAppPermission.js:353 ~ onSubmit ~ exception:", exception);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const [toggleStatus, setToggleStatus] = useState({
    read_customers: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_discounts: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_fulfillments: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_inventory: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_locations: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_order_edits: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_orders: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_fulfillment_edit: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_product_listings: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_products: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_shipping: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
  });

  const handleToggle = (event) => {
    const { id } = event.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? SHOW : HIDE,
      },
    }));
  };

  const handleCancelButton = (event) => {
    event.preventDefault();
    const resetData = {};
    toggleBanner();
    Object.keys(values).forEach((item) => {
      resetData[item] = { ...values[item], checked: false, disable: true };
    });
    resetData.goListingsPage = { ...values.goListingsPage, checked: false, disable: false };
    setValues(resetData);
    setApiDetails({ shopSubDomain: "", apiAccessToken: "", apiKey: "", apiSecretKey: "" });
    // setSelectedTab(onboardingSteps.TERM);
  };

  const getDescription = () => (
    <>
      {/* <TextContainer>
        This is the last step where you now need to connect your shop. <br />
        <br />
        Estimated time: Approximately 5 mins. <br />
        <br />
      </TextContainer>
      <DescriptionList
        items={[
          {
            term: "Step 1",
            description: "Create and configure your custom app in Shopify.",
          },
          {
            term: "Step 2",
            description: "Provide required permissions in the custom app in Shopify.",
          },
          {
            term: "Step 3",
            description: "Install the newly created custom app in Shopify.",
          },
          {
            term: "Step 4",
            description: "Provide us your shop domain and custom app credentials.",
          },
        ]}
      /> */}
    </>
  );

  return (
    <Card.Section title="App Permission">
      <Layout id="app-permission">
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              isScrollTop={banner.isOpen}
              onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
            />
          </Layout.Section>
        )}
        <Layout.Section>
          <Card>
            <Card.Section sectioned>
              {values &&
                Object.keys(values).map((item) => {
                  if (values[item].type === STEP) {
                    return (
                      <div key={item}>
                        <Checkbox
                          value={item}
                          label={values?.[item]?.title}
                          disabled={values?.[item]?.disable}
                          checked={values?.[item]?.checked}
                          onChange={() => handleChange(item)}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
            </Card.Section>
          </Card>

          <Card title="Provide permissions to your custom app">
            {values &&
              Object.keys(values).map((item) => {
                if (values[item].type === SETTINGS) {
                  return (
                    <Card.Section sectioned key={item}>
                      <Checkbox
                        value={item}
                        label={values?.[item]?.title}
                        disabled={values?.[item]?.disable}
                        checked={values?.[item]?.checked}
                        onChange={() => handleChange(item)}
                        helpText={values?.[item]?.helpText}
                      />
                    </Card.Section>
                  );
                }
                return null;
              })}
          </Card>
          <Card title="Install your custom app">
            <Card.Section sectioned>
              <Checkbox
                value="installApp"
                label={values?.installApp?.title}
                disabled={values?.installApp?.disable}
                checked={values?.installApp?.checked}
                onChange={() => handleChange(installAppKey)}
              />
            </Card.Section>
          </Card>
          {isInputVisible && <input autoFocus />}

          <Card title="Provide your custom app credentials">
            <Card.Section sectioned>
              <TextField
                label={cms("common.appPermission.shop.label")}
                error={errorMessage && errorMessage.shopSubDomain}
                helpText={`${cms("common.appPermission.shop.helpText")}`}
                id="shopSubDomain"
                type={constant.TEXT}
                prefix={`${cms("common.appPermission.shop.prefix.shopSubDomain")}`}
                suffix={`${cms("common.appPermission.shop.suffix.shopSubDomain")}`}
                value={apiDetails?.shopSubDomain}
                onChange={(value) => handleField(REGISTERSUBDOMIN, value)}
                onBlur={() => handleValidation(REGISTERSUBDOMIN, apiDetails?.shopSubDomain)}
              />
              <br />
              <TextField
                label={cms("common.appPermission.label.apiAccessToken")}
                id="apiAccessToken"
                type={constant.TEXT}
                error={errorMessage && errorMessage.apiAccessToken}
                value={apiDetails?.apiAccessToken}
                onChange={(value) => handleField(API_ACCESS_TOKEN, value)}
                onBlur={() => handleValidation(API_ACCESS_TOKEN, apiDetails?.apiAccessToken)}
              />
              <br />

              <TextField
                label={cms("common.appPermission.label.apiKey")}
                id="apiKey"
                type={constant.TEXT}
                error={errorMessage && errorMessage.apiKey}
                value={apiDetails?.apiKey}
                onChange={(value) => handleField(API_APP_KEY, value)}
                onBlur={() => handleValidation(API_APP_KEY, apiDetails?.apiKey)}
              />
              <br />

              <TextField
                label={cms("common.appPermission.label.apiSecretKey")}
                id="apiSecretKey"
                type={constant.TEXT}
                error={errorMessage && errorMessage.apiSecretKey}
                value={apiDetails?.apiSecretKey}
                onChange={(value) => handleField(API_APP_SECRET_KEY, value)}
                onBlur={() => handleValidation(API_APP_SECRET_KEY, apiDetails?.apiSecretKey)}
              />
              <br />
            </Card.Section>
          </Card>
          <PageActions
            primaryAction={{
              id: "MC_PORTAL_ONBOARDING_STEP_05",
              content: "Save",
              onAction: (e) => onSubmit(e),
              disabled: !isAllValuesFilled() || !isAllAPIValuesFilled() || isAnyError(),
              loading,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                id: "previous",
                onAction: (e) => handleCancelButton(e),
              },
            ]}
          />
        </Layout.Section>
      </Layout>
    </Card.Section>
  );
};

export default OperatorUpgradAppPermission;
