import React, { useState, useContext } from "react";
import { Heading, RadioButton, Stack, TextContainer } from "@shopify/polaris";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { ThirdPartyOptions } from "./subFeatures";

const DropShip = () => {
  const { cms, dropship, setDropship, setSubmitEnable, value, setOnSubmitError } = useContext(FulfillmentContext);
  const [canadaPost, setCanadaPost] = useState(dropship && dropship.isThirdPartyShipping && dropship.canadaPost);
  const [nzPost, setNZPost] = useState(dropship && dropship.isThirdPartyShipping && dropship.nzPost);
  const [selected, setSelected] = useState((dropship && dropship.shippingOption) || "");
  const [isError, setIsError] = useState(false);

  const handleDropship = (fieldName, val) => {
    setIsError(val);
    if (!val) {
      setOnSubmitError("");
    }
    setDropship((prevState) => ({
      ...prevState,
      shippingOption: "",
      [fieldName]: val,
    }));
    setSelected("");
    setSubmitEnable(false);
    if (!val) {
      setSubmitEnable(true);
    }
  };

  return (
    <div>
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("dropShip.method")}</Heading>
          </Stack.Item>
          {/* <Stack.Item>
            <Link onClick={() => learnMore(cms("dropShip.method"), cms("dropShip.method"))}>
              {cms("common.learnMore")}
            </Link>
          </Stack.Item> */}
        </Stack>
      </TextContainer>
      <br />
      <RadioButton
        label={cms("label.manageShipping")}
        helpText={cms("label.manageText")}
        checked={!(dropship && dropship.isThirdPartyShipping)}
        onChange={() => handleDropship("isThirdPartyShipping", !(dropship && dropship.isThirdPartyShipping))}
      />

      <RadioButton
        label={cms("label.thirdPartyText")}
        helpText={cms("label.thirdPartyText")}
        checked={dropship && dropship.isThirdPartyShipping}
        onChange={() => handleDropship("isThirdPartyShipping", !(dropship && dropship.isThirdPartyShipping))}
      />

      {dropship && dropship.isThirdPartyShipping && (value === constant.DROPSHIP || value === constant.HYBRID) && (
        <div>
          <br />
          <Heading>{cms("dropShip.heading")}</Heading>
          <br />
          <ThirdPartyOptions
            canadaPost={canadaPost}
            dropship={dropship}
            nzPost={nzPost}
            selected={selected}
            setCanadaPost={setCanadaPost}
            setDropship={setDropship}
            setNZPost={setNZPost}
            setSelected={setSelected}
            isError={isError}
          />
        </div>
      )}
    </div>
  );
};
export default withFeature(withErrorBoundary(DropShip), { feature: constant.DROPSHIP });
