import React, { useContext } from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

const SocialLinkSection = (props) => {
  const { cms } = useContext(PrivateContext);
  const { socialLinkInformation, handleChange, lastUpdated /* learnMore */ } = props;

  const getFields = () => {
    const fields = [
      {
        label: cms("section.socialLink.label.facebook"),
        placeholder: cms("section.socialLink.placeHolder.facebookUrl"),
        isRequired: false,
        key: "facebook",
      },
      {
        label: cms("section.socialLink.label.youTube"),
        placeholder: cms("section.socialLink.placeHolder.youtubeUrl"),
        isRequired: false,
        key: "youtube",
      },
      {
        label: cms("section.socialLink.label.twitter"),
        placeholder: cms("section.socialLink.placeHolder.twitterUrl"),
        isRequired: false,
        key: "twitter",
      },
      {
        label: cms("section.socialLink.label.instagram"),
        placeholder: cms("section.socialLink.placeHolder.instagramUrl"),
        isRequired: false,
        key: "instagram",
      },
      {
        label: cms("section.socialLink.label.linkedIn"),
        placeholder: cms("section.socialLink.placeHolder.linkedinUrl"),
        isRequired: false,
        key: "linkedin",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <TextField
          label={`${label}${required}`}
          placeholder={placeholder}
          value={socialLinkInformation[key]}
          id={key}
          onChange={(value) => handleChange(key, value)}
        />
      );
    });
  };

  return (
    <Layout.AnnotatedSection
      title={cms("section.socialLink.title")}
      description={cms("section.socialLink.description")}
    >
      <Card
        title={[
          cms("section.socialLink.title"),
          lastUpdated && (
            <Caption>
              <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
            </Caption>
          ),
        ]}
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore(cms("section.socialLink.title"), "TODO:");
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.socialLink.description")}</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

SocialLinkSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  socialLinkInformation: PropTypes.shape({
    facebook: PropTypes.string,
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
  // learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

SocialLinkSection.defaultProps = {
  lastUpdated: "",
};

export default SocialLinkSection;
