// import packages
import React, { useContext } from "react";
import { Button, TextField, Select, Stack } from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import propsTypes
import { AdvanceItemProp } from "app/setup/modules/operator/features/commission/props";

const TransactionTaxItem = (props) => {
  const { count, updateVal, transactionList, removeItem } = props;
  const {
    COMMISSION,
    LABEL_NAME,
    FEE_TYPE,
    FEE_VALUE,
    PERCENTAGE: PERCENTAGE_TEXT,
    FLAT: FLAT_TEXT,
    color: { RED },
    symbol: { DOLLAR, PERCENTAGE },
    // value: { MAX_FLAT, MAX_PERCENTAGE },
  } = constant;
  const { currentUser = {}, cms = {} } = useContext(PrivateContext);

  const { isStripeSubscription = false, moneyFormat = DOLLAR } = currentUser;

  const FeetypeOptions = [
    {
      label: cms("section.labelSetting.label.percentage"),
      value: PERCENTAGE_TEXT,
    },
    {
      label: cms("section.labelSetting.label.flat"),
      value: FLAT_TEXT,
    },
  ];

  const arr = Array.from(Array(count).keys());
  return arr.map((item) => {
    const transactionItem = transactionList[item];
    const advanceItemName = transactionItem.labelName;
    const feeTypeVal = transactionItem.feeType;
    const feeValueData = transactionItem.feeValue ? String(transactionItem.feeValue) : "";

    const typeOfFee = transactionItem.feeType
      ? transactionItem.feeType === PERCENTAGE_TEXT
        ? PERCENTAGE
        : moneyFormat
      : "";
    if (!transactionItem) {
      return null;
    }
    const commissionText = `${COMMISSION}${item}`;
    return (
      <div key={item} className="transactionFees">
        <Stack wrap={false} key={item} alignment="trailing">
          <Stack.Item fill>
            <TextField
              required
              min={0}
              onChange={(val) => {
                updateVal(LABEL_NAME, val, item);
              }}
              value={(transactionItem && advanceItemName) || ""}
              label={cms("section.labelSetting.label.labelName")}
              placeholder="Label"
            />
          </Stack.Item>
          <Stack.Item fill>
            <Select
              label={cms("section.labelSetting.label.feeType")}
              options={FeetypeOptions}
              placeholder={cms("common.label.options")}
              id="feeType"
              onChange={(val) => {
                updateVal(FEE_TYPE, val, item);
              }}
              value={(transactionItem && feeTypeVal) || ""}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label={cms("section.labelSetting.label.feeValue")}
              required
              min={0}
              id={commissionText}
              // max={getMaxValue}
              prefix={typeOfFee === moneyFormat ? moneyFormat : ""}
              suffix={typeOfFee === PERCENTAGE ? PERCENTAGE : ""}
              // suffix={typeOfFee}
              disabled={isStripeSubscription}
              onChange={(val) => {
                updateVal(FEE_VALUE, val, item);
              }}
              value={(transactionItem && String(feeValueData)) || ""}
            />
          </Stack.Item>
          <Stack.Item>
            <Button
              id="deleteButton"
              destructive
              plain
              onClick={() => removeItem(item)}
              disabled={isStripeSubscription}
            >
              <i className="far fa-trash fa-lg redColor" />
            </Button>
          </Stack.Item>
        </Stack>
      </div>
    );
  });
};

TransactionTaxItem.propTypes = AdvanceItemProp.type;

export default TransactionTaxItem;
