import React, { useCallback, useContext, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Badge,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  FormLayout,
  Link,
  Modal,
  ResourceItem,
  Spinner,
  Stack,
  Tag,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { ChatMajor, CircleCancelMinor, CircleTickOutlineMinor, EmailMajor, ViewMinor } from "@shopify/polaris-icons";

import { SingleColumnLayout } from "layout/private/components";
import { Banner, ModalPopup, PermissionBanner, ResourceList, Toast } from "lib/components";
import Popover from "lib/components/popover/popover";

import { useQuery, useMutation } from "react-apollo";
import { GET_QUOTE_LIST } from "app/quote/apollo/queries";
import { QUOTE_APPROVAL, SEND_QUOTE_EMAIL } from "app/quote/apollo/mutations";
import { QUOTE_LIST_SUBSCRIPTION } from "app/quote/apollo/subscription";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { ProductCard } from "asset/styles/globalStyle";

import { QuickAction } from "app/reports";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

import { baseHelper, errorHelper, imageHelper, storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";
// eslint-disable-next-line no-restricted-imports
import { constantData } from "../../../generic";

const { APPROVE, LARGE, NOIMAGESNAP, REJECT, imageTypes, gql } = constant;

const QuoteList = () => {
  const { userKey } = constant;
  const {
    cms,
    history,
    initiateQuoteChat = () => {},
    currentUser,
    unreadMessageCount,
    isShopifyCustomOperator,
  } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;

  const queryParam = baseHelper.getQueryParams(history.location.search);
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [activePopover, setActivePopover] = useState({});
  const [activePopoverId, setActivePopoverId] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCustomerEmail, setIsOpenCustomerEmail] = useState(false);
  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [contentMessage, setContentMessage] = useState("");
  const [subjectMessage, setSubjectMessage] = useState("");
  const [selectedQuoteHistory, setSelectedQuoteHistory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [quoteCount, setQuoteCount] = useState(0);
  const [preOrderAmount, setPreOrderAmount] = useState(0);
  const [quoteList, setQuoteList] = useState([]);
  const [isDeferredPayment, setIsDeferredPayment] = useState(false);
  const [quotePrice, setQuotePrice] = useState("0");
  const [search, setSearch] = useState(queryParam.search || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const inputData = {
    filter: filterByStatus && filterByStatus.length ? filterByStatus : queryParam.filter,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    search: queryParam.search,
  };

  const [quoteApproval, { loading: quoteApprovalLoading }] = useMutation(QUOTE_APPROVAL);
  const [sendQuoteEmail, { loading: sendQuoteEmailLoading }] = useMutation(SEND_QUOTE_EMAIL);

  const { data: quoteListData, loading: quoteLoading, error: quoteError, subscribeToMore } = useQuery(GET_QUOTE_LIST, {
    variables: {
      input: inputData,
    },
    notifyOnNetworkStatusChange: true,
  });
  const responseError = baseHelper.getResponseError(quoteListData, gql.GET_QUOTE_LIST);
  const responseData = baseHelper.getResponseData(quoteListData, gql.GET_QUOTE_LIST);

  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);

  useEffect(() => {
    if (responseData) {
      setQuoteList(responseData && responseData.quotes);
      setQuoteCount(responseData && responseData.count);
    }
  }, [responseData]);

  useEffect(() => {
    if (!quoteLoading) {
      setLoading(false);
    }
  }, [quoteLoading]);

  // Subscription for quoteList Page.
  useEffect(() => {
    subscribeToMore({
      document: QUOTE_LIST_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newQuote = subscriptionData.data.getQuoteList.data;
        const prevQuotes = prev.getQuoteList.data.quotes.filter((prevQuote) => prevQuote._id !== newQuote._id);
        const vendorListData = [newQuote, ...prevQuotes];
        const totalCount = vendorListData.length;
        return [setQuoteList(vendorListData), setQuoteCount(totalCount)];
      },
    });
  }, [subscribeToMore]);

  useEffect(() => {
    if (quoteError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(quoteError),
      });
    }
  }, [quoteError]);

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handlePageChange = (value) => {
    setSelectedItems([]);
    setPage(value);
  };

  const handlePopover = (quoteId) => {
    setSelectedItems([]);
    setActivePopover({ [quoteId]: !activePopover[quoteId] });
    setActivePopoverId(quoteId);
  };

  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue.trim(), page: 1 });
  };

  const handleSelect = (item) => {
    setSelectedItems(item);
  };

  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    setActivePopoverId(false);
  };

  const handleCustomerEmailModal = () => {
    setIsOpenCustomerEmail(false);
    setContentMessage("");
    setSubjectMessage("");
    setActivePopoverId(false);
  };

  const toggleHistoryMenu = (id) => {
    let updatedState = false;
    if (!id || (id && selectedQuoteHistory === id)) {
      updatedState = false;
    } else {
      updatedState = id;
    }
    setSelectedQuoteHistory(updatedState);
  };

  const handleQuoteApproval = async (action, quoteId) => {
    try {
      const quoteApprovalResponse = await quoteApproval({
        variables: {
          input: {
            action,
            price: parseFloat(quotePrice),
            quoteId,
            isDeferredPayment,
            preOrderAmount: parseFloat(preOrderAmount),
          },
        },
      });
      setIsOpenModal(false);
      const quoteApprovalResponseData = baseHelper.getResponseData(quoteApprovalResponse.data, gql.QUOTE_APPROVAL);
      if (!quoteApprovalResponseData) {
        const quoteApprovalErrorData = baseHelper.getResponseError(quoteApprovalResponse.data, gql.QUOTE_APPROVAL);
        throw new Error(quoteApprovalErrorData || "Error");
      }
      setToastMessage(cms(`message.success.${action}`));
    } catch {
      setToastMessage(cms("message.error"));
    }
  };

  const handleSendQuoteEmail = async (quoteId) => {
    try {
      const sendQuoteEmailResponse = await sendQuoteEmail({
        variables: {
          input: {
            contentMessage,
            quoteId,
            subjectMessage,
          },
        },
      });
      setIsOpenCustomerEmail(false);
      setContentMessage("");
      setSubjectMessage("");
      const sendQuoteEmailResponseData = baseHelper.getResponseData(sendQuoteEmailResponse.data, gql.SEND_QUOTE_EMAIL);
      if (!sendQuoteEmailResponseData) {
        const sendQuoteEmailErrorData = baseHelper.getResponseError(sendQuoteEmailResponse.data, gql.SEND_QUOTE_EMAIL);
        throw new Error(sendQuoteEmailErrorData || "Error");
      }
      setToastMessage("Email is successfully sent to customer!");
    } catch {
      setToastMessage(cms("message.error"));
    }
  };

  const popoverOptions = (currentQuote) => {
    const { _id: id, customerEmail, price, status } = currentQuote || {};

    if (status !== "new") {
      return [];
    }
    return [
      // {
      //   content: cms("label.viewMessage"),
      //   onAction: () => {
      //     setIsOpenMessageModal(id);
      //   },
      //   icon: ViewMinor,
      // },
      {
        content: "View Chat",
        onAction: () =>
          initiateQuoteChat(
            {
              _id: currentQuote.customerEmail,
              firstName: currentQuote.customerName || "Customer",
              email: currentQuote.customerEmail,
              role: userKey.quoteCustomer,
            },
            currentQuote.vendor,
            currentQuote.seller
          ),
        icon: ChatMajor,
      },
      {
        content: "Send Email",
        onAction: () => setIsOpenCustomerEmail(true),
        icon: EmailMajor,
      },
      {
        content: cms("label.accept"),
        onAction: () => {
          setQuotePrice(price.toString());
          setIsOpenModal(true);
        },
        icon: CircleTickOutlineMinor,
      },
      {
        content: cms("label.reject"),
        onAction: () => {
          handleQuoteApproval(REJECT, id);
        },
        icon: CircleCancelMinor,
        destructive: true,
      },
    ];
  };

  // const bulkActions = [
  //   {
  //     content: "Bulk Accept",
  //     onAction: () => bulkAction(ACCEPT),
  //     icon: CircleTickOutlineMinor,
  //     // disabled: true
  //   },
  //   {
  //     content: "Bulk Reject",
  //     onAction: () => { },
  //     icon: CircleCancelMinor,
  //     destructive: true,
  //   },
  // ];

  const filterOptions = [
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={constantData(cms).filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = { filter: "", page: 1 };
    if (clearFilter) {
      clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          case constant.CLEAR_ALL:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      clearParams.list_filter = "";
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const appliedFilterArrObj = [];
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
  };

  const appliedFilters = appliedFilterArrObj;

  const URL = "/products/edit/";
  const orderViewURL = "/orders/view/";

  const renderItem = (item) => {
    const {
      _id: id,
      createdAt,
      customerEmail,
      customerName = "",
      depositOrderId,
      finalOrderId,
      history: quoteHistory = [],
      image = "",
      orderIds,
      orders = [],
      productId,
      status,
      title,
    } = item;

    const isHistory = !!(quoteHistory && quoteHistory.length);
    const pendingOrders = (orders || []).filter((order) => order.financialStatus === "pending");
    const paidOrders = (orders || []).filter((order) => order.financialStatus === "paid");

    let financialStatus = "";
    let financialStatusBadge = "attention";
    if (orders && orders.length) {
      if (orders.length === pendingOrders.length) {
        financialStatus = "Invoice Pending";
      } else if (orders.length === paidOrders.length) {
        financialStatus = "Invoice Paid";
        financialStatusBadge = "success";
      } else if (paidOrders.length) {
        financialStatus = "Invoice Partially Paid";
      } else if (pendingOrders.length) {
        financialStatus = "Invoice Partially Pending";
      }
    }

    const linePopOverOptions = popoverOptions(item);

    // if (orderIds) {
    //   linePopOverOptions.push({
    //     content: cms("label.initiateChat"),
    //     onAction: () => {
    //       initiateChat({
    //         _id: customerEmail,
    //         firstName: "Folks",
    //         email: customerEmail,
    //         role: userKey.quoteCustomer,
    //       });
    //     },
    //     icon: ChatMajor,
    //   });
    // }

    if (depositOrderId) {
      linePopOverOptions.push({
        content: "View Deposit Order",
        onAction: () => history.push(`${orderViewURL}${depositOrderId}`),
        icon: ViewMinor,
      });
    }

    if (finalOrderId) {
      linePopOverOptions.push({
        content: "View Final Order",
        onAction: () => history.push(`${orderViewURL}${finalOrderId}`),
        icon: ViewMinor,
      });
    }

    const thumbnailImage = image ? imageHelper.resize({ url: image, type: imageTypes.THUMBNAIL }) : NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={LARGE} />;
    return (
      <ResourceItem id={id} persistActions>
        <ProductCard>
          <div className="list-item">
            <Stack alignment="leading">
              <Stack.Item>{thumbnail}</Stack.Item>
              <Stack.Item fill>
                <Stack vertical>
                  <Stack.Item>
                    <Link onClick={() => history.push(`${URL}${productId}`)}>{title}</Link>
                    <Caption>
                      <TextStyle variation="subdued">
                        {` ${cms("section.quoteList.label.createdOn")}: ${baseHelper.formatDate(createdAt)}`}
                      </TextStyle>
                    </Caption>
                    <span>
                      Customer Name:
                      {customerName || ""}
                    </span>
                    <br />
                    <span>
                      Customer Email:
                      {customerEmail}
                    </span>
                  </Stack.Item>
                  <Stack.Item>
                    <Stack>
                      <Stack.Item>
                        <Tooltip
                          content={
                            status === constant.APPROVED_SMALL
                              ? baseHelper.getQuoteStatus(cms, constant.QUOTE_SEND_SMALL)
                              : baseHelper.getQuoteStatus(cms, status)
                          }
                          preferredPosition="above"
                        >
                          <Badge status={baseHelper.getBadgeType(status)}>
                            {status === constant.APPROVED_SMALL
                              ? baseHelper.getQuoteStatus(cms, constant.QUOTE_SEND_SMALL)
                              : baseHelper.getQuoteStatus(cms, status)}
                          </Badge>
                        </Tooltip>
                      </Stack.Item>
                      {financialStatus && (
                        <Stack.Item>
                          <Badge status={financialStatusBadge}>{financialStatus}</Badge>
                        </Stack.Item>
                      )}
                    </Stack>
                  </Stack.Item>
                  {isHistory && (
                    <Stack.Item>
                      <Link onClick={() => toggleHistoryMenu(id)}>
                        {selectedQuoteHistory === id ? "Hide Details and History" : "View Details and History"}
                      </Link>
                    </Stack.Item>
                  )}
                  {selectedQuoteHistory === id && <Stack.Item>{renderQuoteHistory(id)}</Stack.Item>}
                </Stack>
              </Stack.Item>
              {linePopOverOptions && linePopOverOptions.length && (
                <Stack.Item>
                  <div className="manage-button">
                    <Popover
                      active={activePopover[id]}
                      setActive={() => {
                        handlePopover(id);
                      }}
                      options={linePopOverOptions}
                    />
                  </div>
                </Stack.Item>
              )}
            </Stack>
          </div>
        </ProductCard>
      </ResourceItem>
    );
  };

  const getModalFooter = (actions = []) => (
    <ButtonGroup>
      {actions.map((action) => (
        <Button
          primary={action.primary}
          onClick={action.onAction}
          destructive={action.destructive}
          loading={action.loading}
        >
          {action.content}
        </Button>
      ))}
    </ButtonGroup>
  );

  const renderQuoteHistory = (quoteId) => {
    const quote = quoteList.find((quoteItem) => quoteItem._id === quoteId);
    if (!quote) {
      return null;
    }

    const {
      customerName = "",
      depositAmount = 0,
      depositOrderId,
      finalOrderId,
      history: quoteHistory = [],
      message: customerMessage,
      orders = [],
      remainingAmount = 0,
    } = quote;
    const renderItem = (quoteHistory) => {
      const { type, location, date, time, orderId, price } = quoteHistory;

      const orderData = (orders || []).find((item) => item.orderId === orderId);

      const { financialStatus, orderNumber } = orderData || {};

      // const media = <Avatar customer size="medium" name={name} />;
      let message = "";

      let link = "";
      let eventLocation = "";
      let eventDate = "";
      let quoteMessage = "";
      let orderLink = "";

      const isRejected = type === "rejected";
      const isApproved = type === "approved";
      const isCreated = type === "create";
      const isDepositOrderCreated = type === "depositOrder";
      const isFinalOrderCreated = type === "finalOrder";

      if (isApproved) {
        message = `Quote issued by your vendor for a total of ${currentUser.moneyFormat}${price}.`;
      }

      if (isRejected) {
        message = "Customer quote request rejected.";
      }

      if (isCreated) {
        message = "Quote requested by customer.";
      }

      if (isCreated || isApproved) {
        eventLocation = location;
        eventDate = date;
        quoteMessage = customerMessage;
      }

      if (isDepositOrderCreated) {
        message = `  created for deposit amount of ${currentUser.moneyFormat}${depositAmount}.`;
        orderLink = <Link onClick={() => history.push(link)}>
#{orderNumber}</Link>;
        link = `${orderViewURL}${depositOrderId}`;
      }

      if (isFinalOrderCreated) {
        message = ` created for remaining amount of ${currentUser.moneyFormat}${remainingAmount}.`;
        orderLink = <Link onClick={() => history.push(link)}>
#{orderNumber}</Link>;
        link = `${orderViewURL}${finalOrderId}`;
      }

      const financialStatusBadge = (financialStatus === constant.PAID && constant.PAID) || constant.ATTENTION;

      const badgeType = baseHelper.getBadgeType(financialStatusBadge);
      const badgeStatus = baseHelper.getQuoteFinancialStatus(financialStatus);

      return (
        <ResourceItem
          id={`quoteId-${quoteHistory.type}`}
          // url={url}
          // media={media}
          accessibilityLabel={`View details for ${quoteHistory.type}`}
        >
          <Stack>
            <Stack.Item fill>
              <h3>
                <TextStyle variation={isRejected ? "negative" : "strong"}>
                  {orderLink}
                  {message}
                </TextStyle>
              </h3>
              <Caption>
                <TextStyle variation="subdued">
                  Actioned At:
                  {baseHelper.formatDate(time)}
                </TextStyle>
              </Caption>
              {(isCreated || isApproved) && [
                (eventLocation || eventDate || quoteMessage) && <br />,
                eventLocation && <TextStyle>
Event Location:{eventLocation}</TextStyle>,
                eventLocation && eventDate && <br />,
                eventDate && <TextStyle>
Event Date:{baseHelper.formatDate(eventDate, false)}</TextStyle>,
                eventDate && quoteMessage && <br />,
                quoteMessage && <TextStyle>
Message:{quoteMessage}</TextStyle>,
              ]}
              {isApproved && [
                <br />,
                <TextStyle>
                  Total Amount: 
{' '}
{currentUser.moneyFormat}
                  {price}
                </TextStyle>,
                <br />,
                <TextStyle>
                  Deposit Amount: 
{' '}
{currentUser.moneyFormat}
                  {depositAmount}
                </TextStyle>,
                <br />,
                <TextStyle>
                  Remaining Amount: 
{' '}
{currentUser.moneyFormat}
                  {remainingAmount}
                </TextStyle>,
              ]}
            </Stack.Item>
            {orderData && (
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
            )}
          </Stack>
        </ResourceItem>
      );
    };

    return (
      <ResourceList
        isFilter={false}
        resourceName={{ singular: "quote history", plural: "quote history" }}
        items={[...quoteHistory].reverse()}
        renderItem={renderItem}
      />
    );
  };

  if (loading) {
    return <Spinner />;
  }

  const linkData = [
    {
      action: () => history.push("/chat"),
      caption: cms("common.label.tabs.provider.chatInbox"),
      id: "chatInbox",
      primary: true,
      label: `${cms("common.label.tabs.provider.chatInbox")} (${unreadMessageCount})`,
    },
  ];

  const handleClose = () => {
    // setAppUpdate(!appUpdate);
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  const getComponent = () => {
    const currentQuote =
      quoteList.find((quote) => quote._id === isOpenMessageModal || quote._id === activePopoverId) || {};
    const isNewQuote = isOpenMessageModal && currentQuote.status !== "new";
    return (
      <>
        {!isShopifyCustomOperator && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.title")}
              status="critical"
            >
              <p>{cms("common.popup.migrate.description")}</p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutCustom")}`,
                      url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Card>
              <UpgradAppPermission />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <QuickAction link={linkData} />
        <Card>
          <div className="quote-list">
            <ResourceList
              items={quoteList}
              renderItem={renderItem}
              idForItem={(serviceQuote) => {
                const { _id: id } = serviceQuote;
                return id;
              }}
              loading={quoteLoading}
              queryValue={search}
              filters={filterOptions}
              onQueryClear={handleQueryClear}
              onQueryChange={handleSearchChange}
              sortOptions={constantData(cms).sortOptions}
              sortValue={sortValue}
              onSortChange={handleSort}
              selectedItems={selectedItems}
              onSelectionChange={handleSelect}
              // bulkActions={bulkActions}
              appliedFilters={appliedFilters}
              handleClearAll={handleClearAll}
              count={quoteCount}
              page={page}
              perPage={perPage}
              setPage={(value) => handlePageChange(value)}
              setPerPage={setPerPage}
            />
          </div>
        </Card>
        <Toast message={toastMessage} setToast={setToastMessage} timeout={5000} />
        <Modal
          open={isOpenCustomerEmail}
          onClose={() => handleCustomerEmailModal()}
          title="Send Email to Customer"
          primaryAction={{
            content: "Send",
            onAction: () => {
              handleSendQuoteEmail(currentQuote._id);
            },
            loading: sendQuoteEmailLoading,
          }}
          secondaryActions={{
            content: cms("label.close"),
            onAction: () => handleCustomerEmailModal(),
          }}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label="To:"
                maxLength={13}
                disabled
                value={currentQuote.customerEmail}
                // onChange={(val) => setQuotePrice(val)}
              />
              <TextField
                label="Subject:"
                maxLength={30}
                value={subjectMessage}
                onChange={(val) => setSubjectMessage(val)}
              />
              Content:
              <Editor
                id="idTiny"
                textareaName="Email Content"
                value={contentMessage || ""}
                onEditorChange={(content) => setContentMessage(content)}
                init={{
                  menubar: true,
                  plugins: ["autolink link image lists print preview"],
                  toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                  entity_encoding: "raw",
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
        <Modal
          open={isOpenModal}
          onClose={() => handleModalClose()}
          title={cms("section.quoteApproval")}
          primaryAction={{
            content: cms("label.accept"),
            onAction: () => {
              handleQuoteApproval(APPROVE, currentQuote._id);
            },
            disabled: !isDeferredPayment ? !quotePrice : !(quotePrice && preOrderAmount),
            loading: quoteApprovalLoading,
          }}
          secondaryActions={{
            content: cms("label.close"),
            onAction: () => handleModalClose(),
          }}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={`${cms("label.newQuotePrice")}*`}
                maxLength={13}
                prefix={currentUser.moneyFormat}
                value={quotePrice}
                type="number"
                onChange={(val) => setQuotePrice(val)}
              />
              <Checkbox
                label="Deposit Payment"
                checked={isDeferredPayment}
                helpText={(
                  <b>
                    "Select deposit payment if you require a deposit" (e.g. if you are charging a total of £100 and
                    would like a deposit of £20, when you add £20 as a deposit, the remaining balance for your customer
                    is £80)'
                  </b>
                )}
                onChange={() => {
                  setIsDeferredPayment(!isDeferredPayment);
                  if (!isDeferredPayment) {
                    setPreOrderAmount(0);
                  }
                }}
              />
              {isDeferredPayment && (
                <TextField
                  label="Deposit Amount*"
                  maxLength={13}
                  prefix={currentUser.moneyFormat}
                  value={preOrderAmount}
                  type="number"
                  onChange={(val) => setPreOrderAmount(val)}
                />
              )}
            </FormLayout>
          </Modal.Section>
        </Modal>
        <Modal
          open={!!(isOpenMessageModal && !isOpenModal && currentQuote.message)}
          onClose={() => setIsOpenMessageModal(false)}
          title={cms("label.viewMessage")}
          footer={getModalFooter(
            isNewQuote
              ? []
              : [
                  {
                    content: cms("label.reject"),
                    destructive: true,
                    loading: quoteApprovalLoading,
                    onAction: () => {
                      handleQuoteApproval(REJECT, currentQuote._id);
                    },
                  },
                ]
          )}
          primaryAction={
            isNewQuote
              ? []
              : [
                  {
                    content: cms("label.accept"),
                    onAction: () => {
                      setQuotePrice(currentQuote.price.toString());
                      setIsOpenModal(true);
                    },
                  },
                ]
          }
          secondaryActions={
            isNewQuote
              ? [
                  {
                    content: cms("label.close"),
                    onAction: () => {
                      setIsOpenMessageModal(false);
                    },
                  },
                ]
              : [
                  {
                    content: cms("label.initiateChat"),
                    onAction: () =>
                      initiateQuoteChat(
                        {
                          _id: isOpenMessageModal && currentQuote.customerEmail,
                          firstName: isOpenMessageModal && "Customer",
                          email: isOpenMessageModal && currentQuote.customerEmail,
                          role: userKey.quoteCustomer,
                        },
                        currentQuote.vendor,
                        currentQuote.seller
                      ),
                  },
                ]
          }
        >
          <Modal.Section>{currentQuote.message}</Modal.Section>
        </Modal>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withErrorBoundary(QuoteList);
