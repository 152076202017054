import React, { useContext } from "react";
import { DisplayText, List, Stack } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { destinationProp } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

const Destinations = (props) => {
  const { cms } = useContext(PrivateContext);
  const { destinationData, destinationCodes } = props;
  const list = destinationData.map((item) => {
    const { title, options = [] } = item;
    const selectedOptions = options.filter((option) => {
      return destinationCodes.includes(option.value);
    });
    if (!selectedOptions.length) {
      return null;
    }
    return (
      <List.Item key={`${item.title}${selectedOptions.length}`}>
        {title}
        <List>
          {selectedOptions.map((option) => (
            <List.Item key={option.value}>{option.label}</List.Item>
          ))}
        </List>
      </List.Item>
    );
  });
  const destinationList = list.filter((item) => item);

  if (!destinationList.length) {
    return null;
  }

  return (
    <Stack>
      <Stack.Item>
        <DisplayText>{cms("modal.offer.add.section.destination.label.selectedDestinations")}</DisplayText>
      </Stack.Item>
      <Stack.Item>
        <List>{destinationList}</List>
      </Stack.Item>
    </Stack>
  );
};

Destinations.propTypes = destinationProp.type;

export default Destinations;
