import React from "react";
import { Modal, TextContainer } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";

import { SYNC_INVENTORY_VENDOR } from "app/productOld/apollo/mutations";
import { GET_PRODUCT_LIST } from "app/productOld/apollo/queries";

const SyncProduct = (props) => {
  const { cms, openSyncModal = false, setOpenSyncModal, setResBanner, dataToFetch } = props;
  const [syncProductsData, { loading: loadingSyncProductsData }] = useMutation(SYNC_INVENTORY_VENDOR, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST,
        variables: {
          input: dataToFetch,
        },
      },
    ],
  });

  const syncProducts = async () => {
    await syncProductsData()
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.SYN_INVENTORY_VENDOR);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.SYN_INVENTORY_VENDOR);
        if (responseData) {
          setOpenSyncModal(false);
          setResBanner({ status: constant.INFO, title: cms("message.success.sync"), isOpen: true });
        }
        if (responseError) {
          setResBanner({ status: constant.CRITICAL, title: responseError, isOpen: true });
        }
      })
      .catch((exception) => {
        setResBanner({ status: constant.CRITICAL, title: errorHelper.parse(exception), isOpen: true });
      });
  };

  return (
    <>
      <Modal
        open={openSyncModal}
        title={cms("section.sync.label.import")}
        onClose={() => setOpenSyncModal(false)}
        secondaryActions={{
          content: cms("button.cancel"),
          onAction: () => setOpenSyncModal(false),
          disabled: loadingSyncProductsData,
          id: "cancelProductSync",
        }}
        primaryAction={[
          {
            content: cms("section.sync.label.sync"),
            onAction: syncProducts,
            destructive: true,
            loading: loadingSyncProductsData,
            id: "submitProductSync",
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("section.sync.label.description")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

SyncProduct.defaultProps = {
  cms: () => {},
  openSyncModal: false,
  dataToFetch: {},
  setOpenSyncModal: () => {},
  setResBanner: () => {},
};
SyncProduct.propTypes = {
  cms: PropTypes.func,
  openSyncModal: PropTypes.bool,
  dataToFetch: PropTypes.shape(PropTypes.object),
  setOpenSyncModal: PropTypes.func,
  setResBanner: PropTypes.func,
};

export default SyncProduct;
