import { gql } from "apollo-boost";

const GET_ORDER_LAKE = gql`
  query getOrderLake($input: GetOrderLakeInput) {
    getOrderLake(input: $input) {
      data {
        _id
        messages
        notes
        products
        refunds
        shipments
        shippingAddresses
        number
        billing {
          city
          company
          country
          countryISO2
          email
          firstName
          lastName
          phone
          postcode
          state
          addressLineOne
          addressLineTwo
        }
        shipping {
          addressLineOne
          addressLineTwo
          city
          company
          country
          firstName
          lastName
          postcode
          state
        }
        currency
        customer {
          ipAddress
          note
          storeCustomerId
        }
        discountTotal
        ecommercePlatform
        paymentMethod
        storeDateCreated
        storeDateModified
        storeOrderId
        staffNotes
        storeStatus
        tax {
          handlingCost
          shippingCost
          total
          subtotal
          wrappingCost
        }
        cost {
          handlingExTax
          handlingIncTax
          shippingExTax
          shippingIncTax
          subtotalExTax
          subtotalIncTax
          totalExTax
          totalIncTax
          wrappingExTax
          wrappingIncTax
        }
        isSynced
        createdBy
        ownerId
        updatedBy
        createdAt
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LAKE;
