import React, { useContext, useState } from "react";
import { Card, Layout } from "@shopify/polaris";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { SingleColumnLayout } from "layout/private/components";
import { Banner, SkeletonList } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import subFeature
import PaymentList from "./subFeatures/paymentList";

const OperatorPaymentList = () => {
  const { isLoading } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  // const linkData = [
  //   {
  //     action: () => setModal(true),
  //     caption: cms("label.caption"),
  //     id: "exportPayment",
  //     label: cms("label.export"),
  //     icon: ExportMinor,
  //   },
  //   {
  //     action: () => history.push("/payments/credit-note"),
  //     caption: cms("label.access"),
  //     icon: BalanceMajor,
  //     id: "creditNoteBalance",
  //     label: cms("label.balance"),
  //   },
  // ];

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        {/* <QuickReport /> */}
        {/* <QuickAction link={linkData} /> */}
        {/* <QuickAction /> */}
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <PaymentList setBanner={setBanner} />
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  // <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent() />
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withFeature(withErrorBoundary(OperatorPaymentList), { feature: constant.PAYMENTS });
