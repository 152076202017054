import { gql } from "apollo-boost";

const GET_USER = gql`
  query getUser($input: GetUser) {
    getUser(input: $input) {
      data {
        stripeKey {
          clientId
          secret
        }
      }
      status
      error
    }
  }
`;

export default GET_USER;
