import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Heading,
  Layout,
  PageActions,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast } from "lib/components";
import constant from "lib/constant/constant";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_ORDER_REPORT_SETTING } from "app/setup/apollo/mutations";
import ExportTable from "app/payments/modules/generic/exportSetting/style";
import { StyledCardHeader } from "app/onboarding/modules/genericStyle";

const { DOWN, gql, HIDE_CAPITAL, SHOW, UP } = constant;

const OrderExportReport = (props) => {
  const {
    CRITICAL,
    GROSSPROFIT,
    GROSSSALES,
    HIDDEN,
    PARTIALLY_HIDDEN,
    ORDER_COLUMN_CONTENT,
    ORDER_COLUMN_HEADING,
    RETURNS_SMALL,
    TOTALSALESPRICE,
    SUCCESS,
    VISIBLE,
    WARNING,
  } = constant;
  const {
    error: errorOrderReportSetting,
    data: dataOrderReportSetting,
    handleSetupToggleOrderExport,
    openSetupOrder,
    orderLastUpdateAt,
    refetch: orderFetch,
    setBannerStatus,
  } = props;

  const { cms, currentUser, history } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [userInput, setUserInput] = useState({
    orderNumber: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    lineItemId: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },

    orderStatus: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    vendorAmount: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    createdAt: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    fulfillmentStatus: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    grossSales: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    quantity: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shipping: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    grossProfit: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    totalSalesPrice: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    returns: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    trackingNumber: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
  });

  const [orderSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_ORDER_REPORT_SETTING);

  useEffect(() => {
    if (dataOrderReportSetting) {
      const orderData = baseHelper.getResponseData(dataOrderReportSetting, gql.GET_ORDER_REPORT_SETTING);
      const orderError = baseHelper.getResponseError(errorOrderReportSetting, gql.GET_ORDER_REPORT_SETTING);
      if (orderData.updatedAt) {
        setFirstPush(false);
      }
      setUpdatedAt(orderData.updatedAt);
      const data = { ...orderData.report };
      const keys = Object.keys(userInput);
      keys.forEach((key) => {
        if (data && !data[key]) {
          data[key] = {
            label: "",
            isHideCsv: false,
            isHidePdf: false,
          };
        }
      });

      if (data && data.createdAt) {
        setUpdatedAt(orderData.updatedAt);
        setUserInput(data);
      }
      if (orderError) {
        setBannerStatus({ isOpen: true, title: orderError, status: constant.CRITICAL });
      }
    }
  }, [dataOrderReportSetting]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const noUncheckCsv = Object.keys(userInput)
      .filter((item) => {
        return isVendor
          ? !(
              isVendor &&
              (item === RETURNS_SMALL || item === GROSSSALES || item === TOTALSALESPRICE || item === GROSSPROFIT)
            )
          : true;
      })
      .map((item) => {
        newData.push(userInput[item]);
        if (userInput[item].isHideCsv) {
          return true;
        }
        return false;
      });
    const noUncheckPdf = Object.keys(userInput)
      .filter((item) => {
        return isVendor
          ? !(
              isVendor &&
              (item === RETURNS_SMALL || item === GROSSSALES || item === TOTALSALESPRICE || item === GROSSPROFIT)
            )
          : true;
      })
      .map((item) => {
        if (userInput[item].isHidePdf) {
          return true;
        }
        return false;
      });
    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        if (element.label && item.label && element.label.trim() === item.label.trim()) {
          response = true;
          setBannerStatus({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          return false;
        }
        return false;
      });
    });
    if (!response) {
      response = true;
      noUncheckCsv.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (!response) {
      response = true;
      noUncheckPdf.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const updateOrderSetting = async (type) => {
    orderSetting({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_ORDER_REPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_ORDER_REPORT_SETTING);
          if (resData) {
            orderFetch();
            setMessage(cms("message.success.orderUpdate"));
          }
          if (type === "save") {
            setMessage(cms("message.success.orderSave"));
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleLabelChange = (val, key) => {
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val;
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };

  const selectCurrentLabelsColumn = (item) => <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField value={data && data.label} onChange={(value) => handleLabelChange(value, key)} />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === "isCsv") setUserInput({ ...userInput, [key]: { ...updateData, isHideCsv: !updateData.isHideCsv } });
    if (value === "isPdf") setUserInput({ ...userInput, [key]: { ...updateData, isHidePdf: !updateData.isHidePdf } });
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => (
    <>
      <Checkbox
        label="CSV"
        checked={userInput[index] && userInput[index].isHideCsv}
        onChange={() => handleCheckbox("isCsv", index)}
      />
      <Checkbox
        label="PDF"
        checked={userInput[index] && userInput[index].isHidePdf}
        onChange={() => handleCheckbox("isPdf", index)}
      />
    </>
  );

  const getRows = () => {
    return Object.keys(userInput)
      .filter((item) => {
        return isVendor
          ? !(
              isVendor &&
              (item === RETURNS_SMALL || item === GROSSSALES || item === TOTALSALESPRICE || item === GROSSPROFIT)
            )
          : true;
      })
      .map((item) => {
        let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        const itemData = userInput[item];
        if (itemData.isHideCsv && itemData.isHidePdf) {
          badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
        } else if (itemData.isHidePdf || itemData.isHideCsv) {
          badge = { badgesLable: PARTIALLY_HIDDEN, badgesStatus: WARNING };
        } else {
          badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        }
        const currentValues = selectCurrentLabelsColumn(item);
        const newValues = selectNewLabelsTextField(userInput[item], item);
        const badgesValues = badges(badge);
        const hideColumnValues = selectHideColumn(item);
        return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
      });
  };
  return (
    <Layout.AnnotatedSection
      title={cms("section.orderExport.title")}
      description={cms("section.orderExport.description")}
    >
      <Card>
        <StyledCardHeader className="styled-card-header">
          <Card.Header
            sectioned
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Stack>
                <Heading description="bla bla">
                  {cms("section.orderExport.cardTitle")}
                  <TextStyle variation="subdued">
                    {orderLastUpdateAt && (
                      <TextStyle variation="subdued">
                        <Caption>{`${baseHelper.lastUpdateDate(orderLastUpdateAt)}`}</Caption>
                      </TextStyle>
                    )}
                  </TextStyle>
                  <TextStyle variation="subdued" />
                </Heading>
              </Stack>
            }
            actions={[
              {
                content: openSetupOrder ? HIDE_CAPITAL : SHOW,
                onAction: () => handleSetupToggleOrderExport(),
                disclosure: openSetupOrder ? UP : DOWN,
              },
            ]}
          />
        </StyledCardHeader>
      </Card>
      <Collapsible open={openSetupOrder} id="basic-collapsible">
        <Card>
          <Card.Section>
            <ExportTable className="payment-export-table">
              <DataTable columnContentTypes={ORDER_COLUMN_CONTENT} headings={ORDER_COLUMN_HEADING} rows={getRows()} />
            </ExportTable>
            {!firstPush && (
              <div className="page-action">
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) updateOrderSetting();
                    },
                    disabled: !submitEnabled,
                    loading: settingUpdateLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {firstPush && (
          <PageActions
            primaryAction={{
              content: cms("common.button.submit"),
              onAction: () => {
                const update = verifyValues();
                if (!update) updateOrderSetting("save");
              },
              disabled: !submitEnabled,
              loading: settingUpdateLoading,
            }}
            secondaryActions={[
              {
                id: "cancelButton",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Collapsible>
      <div>
        <Toast message={message} timeout={5000} />
      </div>
    </Layout.AnnotatedSection>
  );
};

export default withFeature(withErrorBoundary(OrderExportReport), {
  feature: constant.ORDER_REPORT_SETTING,
});
