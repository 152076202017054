import PropTypes from "prop-types";

const standardCommissionProp = {
  type: {
    refetchLoading: PropTypes.bool,
    commissionUpdateStatus: PropTypes.func,
    updatedAt: PropTypes.string,
    standardCommissionValue: PropTypes.shape({
      selectedStandardType: PropTypes.string,
      standardAmount: PropTypes.string,
    }),
    advanceCommissionValue: PropTypes.shape({
      ruleBy: PropTypes.string.isRequired,
      advancedValues: PropTypes.arrayOf(PropTypes.object),
    }),
    rangeCommissionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    priorityValue: PropTypes.string.isRequired,
    // isTaxInclusive: PropTypes.bool.isRequired,
  },
  default: {
    refetchLoading: false,
    commissionUpdateStatus: () => {},
    updatedAt: null,
    standardCommissionValue: {},
  },
};

const advanceProp = {
  type: {
    refetchLoading: PropTypes.bool.isRequired,
    advanceCommissionUpdateStatus: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    standardCommissionValue: PropTypes.shape({
      selectedStandardType: PropTypes.string.isRequired,
      standardAmount: PropTypes.string.isRequired,
    }),
    advanceCommissionValue: PropTypes.shape({
      ruleBy: PropTypes.string.isRequired,
      advancedValues: PropTypes.arrayOf(PropTypes.object),
    }),
    rangeCommissionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    priorityValue: PropTypes.string.isRequired,
    // isTaxInclusive: PropTypes.bool.isRequired,
  },
};
const rangeProp = {
  type: {
    refetchLoading: PropTypes.bool.isRequired,
    commissionUpdateStatus: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    standardCommissionValue: PropTypes.shape({
      selectedStandardType: PropTypes.string.isRequired,
      standardAmount: PropTypes.string.isRequired,
    }),
    advanceCommissionValue: PropTypes.shape({
      ruleBy: PropTypes.string.isRequired,
      advancedValues: PropTypes.arrayOf(PropTypes.object),
    }),
    rangeCommissionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    priorityValue: PropTypes.string.isRequired,
    // isTaxInclusive: PropTypes.bool.isRequired,
  },
};

const priorityProp = {
  type: {
    commissionUpdateStatus: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    standardCommissionValue: PropTypes.shape({
      selectedStandardType: PropTypes.string.isRequired,
      standardAmount: PropTypes.string.isRequired,
    }),
    advanceCommissionValue: PropTypes.shape({
      ruleBy: PropTypes.string.isRequired,
      advancedValues: PropTypes.arrayOf(PropTypes.object),
    }),
    rangeCommissionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    priorityValue: PropTypes.string.isRequired,
    // isTaxInclusive: PropTypes.bool.isRequired,
  },
};

const AdvanceItemProp = {
  type: {
    count: PropTypes.number,
    advancedValue: PropTypes.array,
    allTypes: PropTypes.array,
    allSuppliers: PropTypes.array,
    updateVal: PropTypes.func,
    removeItem: PropTypes.func,
  },
};

const taxProp = {
  refetchLoading: PropTypes.bool.isRequired,
  commissionUpdateStatus: PropTypes.func.isRequired,
  standardCommissionValue: PropTypes.shape({
    selectedStandardType: PropTypes.string.isRequired,
    standardAmount: PropTypes.string.isRequired,
  }),
  advanceCommissionValue: PropTypes.shape({
    ruleBy: PropTypes.string.isRequired,
    advancedValues: PropTypes.arrayOf(PropTypes.object),
  }),
  rangeCommissionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  priorityValue: PropTypes.string.isRequired,
  // isTaxInclusive: PropTypes.bool.isRequired,
  // setIsTaxInclusive: PropTypes.func.isRequired,
};

export { standardCommissionProp, advanceProp, AdvanceItemProp, rangeProp, priorityProp, taxProp };
