import React, { useState, useContext } from "react";

import {
  Card,
  DisplayText,
  FormLayout,
  Icon,
  Link,
  Modal,
  ResourceItem,
  ResourceList,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { CircleTickMajor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { useMutation } from "react-apollo";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";

// import component
import { Toast, SkeletonCard } from "lib/components";

import { imageHelper, errorHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";

// import queries
import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/product/apollo/queries";
import { BULK_UPDATE_VARIANT, DELETE_VARIANT, UPDATE_VARIANT_IMAGE } from "app/product/apollo/mutations";

const RenderVariants = (props) => {
  const { history, currentUser, match, cms } = useContext(PrivateContext);
  const { data, handleTabChange, productLoading, setBanner, setDisabledButton, setToastMessage, refetch } = props || {};

  const { productId: productItemId, images } = data;
  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_MARKUP_PERCENTAGE },
  } = constant;
  const { moneyFormat } = currentUser;
  const [updateMarkUp, setMarkUp] = useState({
    type: "flat",
    price: "",
  });
  const [message, setMessage] = useState("");
  const [updateQuantity, setQuantity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [active, setActive] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [addImageModal, setAddImageModal] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [submitDisable, setSubmitDisable] = useState(false);

  const { gql, FLAT, PERCENTAGE, symbol } = constant;
  const maxMarkup = (updateMarkUp.type === constant.PERCENTAGE && MAX_MARKUP_PERCENTAGE) || MAX_AMOUNT;

  // GQL calls
  const [updateMarkupVariant, { loading: loadingBulkUpdate }] = useMutation(BULK_UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_AND_SELLER_PRODUCT,
        variables: { input: { _id: match.params.id } },
      },
    ],
  });
  const [deleteVariant, { loading: loadingDeleteVariant }] = useMutation(DELETE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_AND_SELLER_PRODUCT,
        variables: { input: { _id: match.params.id } },
      },
    ],
  });

  const [updateVariant, { loading: loadingUpdateVariant }] = useMutation(UPDATE_VARIANT_IMAGE, {
    refetchQueries: [
      {
        query: GET_PRODUCT_AND_SELLER_PRODUCT,
        variables: { input: { _id: match.params.id } },
      },
    ],
  });

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT) => {
    if (value > MIN_PRICE && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  if (productLoading) {
    return <SkeletonCard />;
  }

  const handleEditMarkup = () => {
    setActive(!active);
    setMessage("");
  };
  const handleEditQuantity = () => {
    setOpenQuantity(!openQuantity);
    setMessage("");
  };

  const handleDeleteModal = (index) => {
    setSelectedId(index);
    setDeleteActive(!deleteActive);
  };
  const bulkActions = [
    { content: cms("label.updateMarkup"), onAction: () => handleEditMarkup() },
    { content: cms("label.updateQuantity"), onAction: () => handleEditQuantity() },
  ];
  const handleChange = (fieldName, fieldValue, typeSelected) => {
    if (!typeSelected) {
      return setMarkUp({ price: "", type: fieldValue });
    }
    if (typeSelected === constant.PERCENTAGE && (fieldValue <= MAX_MARKUP_PERCENTAGE || !fieldValue)) {
      return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
    }
    if (typeSelected !== constant.PERCENTAGE) {
      return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
    }
    return false;
  };

  const selectMarkUp = (
    <Select
      placeholder={cms("placeholder.selectType")}
      options={[
        {
          label: cms("common.label.flat"),
          value: constant.FLAT,
        },
        {
          label: cms("common.label.percentage"),
          value: constant.PERCENTAGE,
        },
      ]}
      value={updateMarkUp.type}
      onChange={(value) => handleChange(constant.TYPE, value, false)}
    />
  );

  const handleUpdateMarkup = (fieldName = false) => {
    const value = {
      productId: productItemId,
      positions: selectedItems,
    };
    if (fieldName === constant.MARKUP) {
      value.markUp = updateMarkUp || parseInt(0, 10);
      value.markUp.price = parseFloat(updateMarkUp.price);
    }
    if (fieldName === constant.INVENTORY_QUANTITY) {
      value.inventoryQuantity = updateQuantity || parseInt(0, 10);
    }
    updateMarkupVariant({ variables: { input: value } })
      .then((res) => {
        setSelectedItems([]);
        const responseError = baseHelper.getResponseError(res, gql.BULK_UPDATE_VARIANT);
        const successMessage =
          fieldName === constant.INVENTORY_QUANTITY ? cms("message.success.quantity") : cms("message.success.markup");
        setDisabledButton(false);
        if (responseError) {
          return setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        setOpenQuantity(false);
        setActive(false);
        setToastMessage(successMessage);
        return null;
      })
      .catch((exception) => {
        setOpenQuantity(false);
        setActive(false);
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
    setSubmitDisable(false);
  };
  const handleDeleteVariant = () => {
    setToastMessage("");
    const value = {
      id: selectedId + 1,
      productId: productItemId,
    };
    deleteVariant({ variables: { input: value } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_VARIANT);
        if (responseError) {
          handleDeleteModal();
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_VARIANT);
        if (responseData) {
          setToastMessage(cms("message.success.variantDelete"));
          handleDeleteModal();
          setTimeout(() => {
            setSelectedId(null);
            refetch();
            handleTabChange(2);
            setDisabledButton(false);
          }, 2000);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
        handleDeleteModal();
      });
  };
  const renderDeleteModal = () => {
    return (
      <Modal
        open={deleteActive}
        onClose={handleDeleteModal}
        title={cms("label.deleteVariant")}
        primaryAction={{
          content: cms("button.delete"),
          onAction: handleDeleteVariant,
          loading: loadingDeleteVariant,
          disabled: loadingDeleteVariant,
          destructive: true,
        }}
      >
        <Modal.Section>{cms("label.content")}</Modal.Section>
      </Modal>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        open={active}
        onClose={handleEditMarkup}
        title={cms("label.updateVariant")}
        primaryAction={{
          content: cms("label.updateMarkupPrice"),
          onAction: () => handleUpdateMarkup(constant.MARKUP),
          loading: loadingBulkUpdate,
          disabled: !submitDisable || loadingBulkUpdate,
        }}
      >
        <Modal.Section>
          <TextField
            label={cms("label.markupPrice")}
            value={(updateMarkUp && updateMarkUp.price && updateMarkUp.price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            min={0}
            max={maxMarkup}
            step={0.01}
            prefix={updateMarkUp.type === constant.FLAT && moneyFormat}
            suffix={updateMarkUp.type === constant.PERCENTAGE && symbol.PERCENTAGE}
            onChange={(value) => {
              handleChange(
                constant.PRICE,
                acceptOnlyValidInput(value, updateMarkUp.price, maxMarkup),
                updateMarkUp.type
              );
              setSubmitDisable(value);
            }}
            connectedRight={selectMarkUp}
          />
        </Modal.Section>
      </Modal>
    );
  };
  const renderQuantityModal = () => {
    return (
      <Modal
        open={openQuantity}
        onClose={handleEditQuantity}
        title={cms("label.update")}
        primaryAction={{
          content: cms("label.updateQuantity"),
          onAction: () => handleUpdateMarkup(constant.INVENTORY_QUANTITY),
          loading: loadingBulkUpdate,
          disabled: !submitDisable || loadingBulkUpdate,
        }}
      >
        <Modal.Section>
          <TextField
            label={cms("label.quantity")}
            value={updateQuantity && updateQuantity.toString()}
            placeholder={constant.ZERO_PRICE}
            min={0}
            maxLength={10}
            onChange={(value) => {
              setQuantity(parseInt(value, 10));
              setSubmitDisable(value);
            }}
          />
        </Modal.Section>
      </Modal>
    );
  };

  const closeModal = () => {
    setAddImageModal({});
    setSelectedImage({});
  };

  const updateImage = () => {
    setToastMessage("");
    const value = {
      id: addImageModal.variantId,
      image: selectedImage.imageUrl,
      productId: match.params.id,
    };
    updateVariant({ variables: { input: value } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_VARIANT_IMAGE);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          closeModal();
        }
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_VARIANT_IMAGE);
        if (responseData) {
          setToastMessage(cms("message.success.variantImage"));
          closeModal();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
        closeModal();
      });
  };

  const onImageChange = (nImage) => {
    setSelectedImage(selectedImage.imageUrl === nImage.imageUrl ? {} : nImage);
  };

  const renderSelectImage = () => {
    const allImages = (images && images.filter((item) => item && item.imageUrl)) || [];
    const productImages = allImages.map((nImage) => {
      const isSelectedImage = nImage.imageUrl === selectedImage.imageUrl;
      return (
        <Stack.Item key={nImage.position}>
          <Stack alignment="center" vertical>
            <Stack.Item>
              <Link onClick={() => onImageChange(nImage)}>
                <Thumbnail
                  source={imageHelper.resize({ url: nImage.imageUrl, type: constant.imageTypes.THUMBNAIL })}
                  size="large"
                />
              </Link>
            </Stack.Item>
            {isSelectedImage && (
              <Stack.Item>
                <Icon source={CircleTickMajor} color="greenDark" backdrop />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      );
    });
    return <Stack>{productImages}</Stack>;
  };

  const renderImageModal = () => {
    return (
      <Modal
        open={addImageModal.isOpen}
        onClose={() => closeModal()}
        title={cms("modal.title")}
        primaryAction={{
          content: cms("modal.button"),
          onAction: () => updateImage(),
          disabled: !(selectedImage && selectedImage.imageUrl),
          loading: loadingUpdateVariant,
        }}
      >
        <Modal.Section>
          <Card sectioned>{renderSelectImage()}</Card>
        </Modal.Section>
      </Modal>
    );
  };

  const renderItem = (item, _, index) => {
    const {
      image,
      option1Val = false,
      option2Val = false,
      option3Val = false,
      option1 = cms("label.size"),
      option2 = cms("label.color"),
      option3 = cms("label.material"),
      inventoryQuantity,
      price,
      markUp = { type: FLAT, price: 0 },
      position,
    } = item;
    const { price: priceMark = "", type: markUpType = PERCENTAGE } = markUp || {};
    const media = (
      <Link onClick={() => setAddImageModal({ isOpen: true, variantId: position })}>
        <Thumbnail
          source={imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP}
          alt={`image of variant ${index}`}
        />
      </Link>
    );

    return (
      <ResourceItem id={index + 1} media={media}>
        <TextContainer>
          <Stack wrap={false}>
            <Stack.Item fill>
              {option1Val && <div className="variantEllipsis">{`${option1}: ${option1Val}`}</div>}
              {option2Val && <div className="variantEllipsis">{`${option2}: ${option2Val}`}</div>}
              {option3Val && <div className="variantEllipsis">{`${option3}: ${option3Val}`}</div>}
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={activePopover[index]}
                setActive={() => setActivePopover({ [index]: !activePopover[index] })}
                options={[
                  {
                    content: cms("button.edit"),
                    icon: EditMinor,
                    onAction: () => history.push(`/variants/edit/${productItemId}/${index + 1}`),
                  },
                  {
                    content: cms("button.delete"),
                    icon: DeleteMinor,
                    destructive: true,
                    onAction: () => handleDeleteModal(index),
                  },
                ]}
              />
            </Stack.Item>
          </Stack>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                label={cms("label.inventory")}
                value={(inventoryQuantity && inventoryQuantity.toString()) || "0"}
                disabled
              />
              <TextField
                label={cms("label.price")}
                value={(price ? price.toString() : "0") || ""}
                prefix={moneyFormat}
                disabled
              />
              <TextField
                label={cms("label.markup")}
                value={(priceMark && priceMark.toString()) || ""}
                suffix={markUpType === constant.PERCENTAGE && symbol.PERCENTAGE}
                prefix={markUpType === constant.FLAT ? moneyFormat : ""}
                disabled
              />
            </FormLayout.Group>
          </FormLayout>
        </TextContainer>
      </ResourceItem>
    );
  };
  const view = (dataNew) => {
    const { variants } = dataNew;
    return (
      <div>
        {!(variants && variants.length) && (
          <>
            <TextContainer>
              <DisplayText size="extraSmall">{cms("section.variant.content")}</DisplayText>
              <br />
              <DisplayText size="extraSmall">
                <TextStyle variation="strong">{`${cms("label.please")}: `}</TextStyle>
                {cms("label.caption.add")}
              </DisplayText>
            </TextContainer>
          </>
        )}
        {variants && variants.length > 0 && (
          <ResourceList
            items={variants}
            renderItem={renderItem}
            selectedItems={selectedItems}
            onSelectionChange={setSelectedItems}
            bulkActions={bulkActions}
            idForItem={(item, index) => index + 1}
          />
        )}
        <div className="toast">
          <Toast message={message} />
        </div>
      </div>
    );
  };
  return [renderImageModal(), renderModal(), renderQuantityModal(), renderDeleteModal(), view(data)];
};
export default RenderVariants;
