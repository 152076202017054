import { gql } from "apollo-boost";

const UPDATE_PAYOUT = gql`
  mutation($input: CurrencyPayoutSetting!) {
    updateCurrencyPayoutSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_PAYOUT;
