import React from "react";

// import generic
import { ExportProductCSV } from "app/productOld/modules/generic";

const ExportProducts = () => {
  const getComponent = () => {
    return <ExportProductCSV />;
  };
  const ExportProductComponent = getComponent();
  return ExportProductComponent;
};

export default ExportProducts;
