import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const MoreFilters = (props) => {
  const { taggedWith, dataToFetch, setDataToFetch, setTaggedWith, selectedFilter, setSelectedFilter } = props;
  const { history, cms } = useContext(PrivateContext);

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    const listFilter = baseHelper.setQueryParams(history.location, { list_filter: value });
    history.push(`${history.location.pathname}?${listFilter}`);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const filterOptions = [{ label: cms("provider.label.filter.name"), value: constant.NAME }];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  return (
    <>
      <FormLayout>
        <Select
          label={cms("provider.label.showAll")}
          value={selectedFilter || ""}
          placeholder={cms("common.placeholder.select")}
          onChange={handleSelectChange}
          options={filterOptions}
        />
        {(selectedFilter && (
          <TextField label={cms("common.label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          null}
      </FormLayout>
    </>
  );
};
MoreFilters.defaultProps = {
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};
export default MoreFilters;
