/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import hoc
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

// import component
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";
import { NotFound } from "layout/private/components";

// import gql
import { GET_PRODUCT_LAKE_VARIANT } from "app/productLake/apollo/queries";
import { UPDATE_LAKE_VARIANT, UPLOAD_IMAGE } from "app/productLake/apollo/mutations";

import validate from "app/productLake/modules/generic/features/variant/edit/yup";

// sub feature item
import {
  TitleEdit,
  Image,
  Price,
  Inventory,
  Shipping,
} from "app/productLake/modules/generic/features/variant/edit/subFeatureItems";

let optionsData = [];

const OperatorVariantEdit = () => {
  const { match, history, cms, currentUser } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);
  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);
  const { gql } = constant;
  const { location } = history;
  const { params } = match;
  const { productId, id: variantId } = params;
  const { state } = location;
  const { ecommercePlatform } = currentUser;

  const [disabledButton, setDisabledButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [image, setImage] = useState();
  const [inteventoryPolicy, setInteventoryPolicy] = useState();
  const [isImageError, setIsImageError] = useState(false);
  const [isImageErrorDisable, setIsImageErrorDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState({});
  const [price, setPrice] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [salesPrice, setSalesPrice] = useState("");
  const [variantValue, setVariantValue] = useState({});

  const { data: variantData, loading, error } = useQuery(GET_PRODUCT_LAKE_VARIANT, {
    variables: { input: { id: variantId, productId } },
  });

  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);

  const [updateLakeVariant, { loading: updateLoading, error: updateVariantError }] = useMutation(UPDATE_LAKE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LAKE_VARIANT,
        variables: { input: { id: variantId, productId } },
      },
    ],
  });

  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, updateVariantError, uploadImageError]);

  useEffect(() => {
    if (state && state.addVariant) {
      setBanner({
        action: {
          content: cms("label.action"),
          onAction: () => history.push(`/lake-variants/add/${productId}`),
        },
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.create"),
      });
      const newState = state;
      delete newState.addVariant;
      history.replace({ ...history.location, state: newState });
    }
  }, [cms, history, productId, state]);

  useEffect(() => {
    if (options) {
      let pass = 1;
      const optionV1 = [];

      for (let i = 0; i < Object.keys(options).length; i += 4) {
        optionV1.push({
          label: options[`option${pass}Val`],
          optionValueId: options[`optionValueid${pass}`],
          optionDisplayName: options[`option${pass}`],
          sortOrder: options[`optionShortOrder${pass}`],
        });
        pass += 1;
      }

      if (Object.keys(optionV1).length) optionsData = optionV1;
    }
  }, [options, variantData, gql, variantId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT_LAKE_VARIANT);
    if (responseData) {
      const { images = [], optionValues, priceId, productImages: imageList, inventoryId } = responseData || {};
      const { isManagingStock } = inventoryId || {};
      if (priceId) {
        setPrice(priceId.price || priceId.regularPrice);
        setSalesPrice(priceId.costPrice || priceId.salePrice);
      }
      if (isManagingStock) {
        setInteventoryPolicy({ label: constant.SHOPIFY });
      }

      if (responseData.length < variantId || variantId <= 0) {
        return <NotFound />;
      }

      const options1 = {};

      optionValues.forEach((option, index) => {
        const { optionId } = option;
        options1[`option${index + 1}`] = optionId.displayName;
        options1[`option${index + 1}Val`] = option.label;
        options1[`optionValueid${index + 1}`] =
          isSeller && isBigCommerceUser ? option.providerOptionValueId : option._id;
        options1[`optionShortOrder${index + 1}`] = optionId.sortOrder;
      });
      if (Object.keys(options1).length) setOptions(options1);

      setProductImages(imageList || []);
      setImage((images[0] && [{ url: images[0].imageUrl, imageId: images[0].imageId }]) || []);
      setVariantValue(responseData);
    }
  }, [variantData, gql, variantId]);

  if (loading) {
    return <SkeletonAnnotated />;
  }

  const onSubmit = async () => {
    const inputData = {
      dimensions: {
        // eslint-disable-next-line radix
        weight: parseFloat(variantValue.weight),
      },
      optionValues: optionsData,
      id: isSeller && isBigCommerceUser ? variantValue.providerVariantId : variantValue._id,
      productId,
      inventory: {
        sku: variantValue?.inventoryId?.sku,
        quantity: variantValue?.inventoryId?.quantity && parseInt(variantValue.inventoryId.quantity, 10),
        isManagingStock: inteventoryPolicy?.label === constant.SHOPIFY,
      },
    };

    const newVendorPrice = {
      price,
      salePrice: salesPrice,
    };

    if (isVendor) {
      inputData.price = newVendorPrice;
    }

    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    if (image && !isLocalImageUrl) {
      inputData.imageUrl = (image[0] && image[0].url) || "";
      inputData.imageId = (image[0] && image[0].imageId) || "";
    }
    if (!isImageError && isLocalImageUrl) {
      try {
        const res = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
        if (resData) {
          inputData.imageUrl = resData.imageUrl;
          inputData.imageId = resData.imageId;
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    }

    updateLakeVariant({ variables: { input: inputData } })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_LAKE_VARIANT);
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_LAKE_VARIANT);
        if (responseError) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: responseError,
          });
          return;
        }
        if (responseData) {
          setMessage(cms("message.success.update"));
          setDisabledButton(true);
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldName === constant.WEIGHT_UNIT || fieldValue > -1 || fieldValue.price > -1) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
      setDisabledButton(false);
    }
    if (fieldName === "inventoryId.sku") {
      setVariantValue((prevState) => ({
        ...prevState,
        inventoryId: {
          ...prevState.inventoryId,
          sku: fieldValue,
        },
      }));
      setDisabledButton(false);
    }

    if (fieldName === "inventoryId.quantity") {
      setVariantValue((prevState) => ({
        ...prevState,
        inventoryId: {
          ...prevState.inventoryId,
          quantity: fieldValue,
        },
      }));
      setDisabledButton(false);
    }
    return null;
  };

  const handleTitleChange = (fieldName, fieldValue) => {
    if (fieldName === "option1Val") optionsData[0].label = fieldValue;
    else if (fieldName === "option2Val") optionsData[1].label = fieldValue;
    else if (fieldName === "option3Val") optionsData[2].label = fieldValue;

    setOptions((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
    setIsImageErrorDisable(true);
  };

  const handleShippihngChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
    setIsImageErrorDisable(true);
  };

  const handleInteventoryPolicyChange = (label, value) => {
    const manageStock = label === constant.SHOPIFY;
    setVariantValue((prevState) => ({
      ...prevState,
      inventoryId: { ...prevState.inventoryId, isManagingStock: manageStock },
    }));
    setInteventoryPolicy({ label: value });
  };

  const handlePriceChange = (label, value) => {
    if (label === "price") {
      setPrice(value);
      setDisabledButton(false);
    } else {
      setSalesPrice(value);
      setDisabledButton(false);
    }
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
              action={banner.action}
              onDismiss={onDismissBanner}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleEdit
          cms={cms}
          errorMessage={errorMessage}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          options={options}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <Image
          productImages={productImages}
          setImage={setImage}
          setDisabledButton={setDisabledButton}
          image={image}
          cms={cms}
          isImageError={isImageError}
          setIsImageError={setIsImageError}
          isImageErrorDisable={isImageErrorDisable}
        />
        <Price
          price={price}
          salesPrice={salesPrice}
          handleChange={handlePriceChange}
          setDisabledButton={setDisabledButton}
        />
        <Inventory
          cms={cms}
          ecommercePlatform={ecommercePlatform}
          handleChange={handleChange}
          handleTitleChange={handleInteventoryPolicyChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
          inteventoryPolicy={inteventoryPolicy}
        />
        <Shipping
          cms={cms}
          handleChange={handleChange}
          handleTitleChange={handleShippihngChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: updateLoading || imageLoading,
            disabled: disabledButton,
          }}
          secondaryActions={{
            content: cms("section.label.button.cancel"),
            onAction: () => history.push(`/product-lake/edit/${productId}?tab=2`),
            disabled: updateLoading || imageLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(OperatorVariantEdit);
