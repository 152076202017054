import React, { useCallback, useContext, useState } from "react";
// import { ActionList, Button, Card, Collapsible, List, Popover, Stack } from "@shopify/polaris";
import { Button, ButtonGroup, Popover, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// config
import constant from "lib/constant/constant";
import storageHelper from "lib/helpers/storage";
import appConfig from "configuration";
import quickSettingConfig from "app/reports/operator/widgets/quickSetting/quickSettingConfig";
import { StyledActionCard, DropDown } from "app/reports/generic/style/quickAction";
import quickAction from "./quickActionConfig";

const { brandPageV2URL } = appConfig; // brandPageURL

const OperatorQuickAction = (props) => {
  const { link = [] } = props;
  const { chatSetting = {}, cms, currentUser, history, match } = useContext(PrivateContext);

  const { path } = match;
  // const [actionLinkExpanded, setExpanded] = useState(false);
  const quickActionConfig = quickAction(cms);
  const { config, title } = quickSettingConfig(cms);
  const [popoverActive, setPopoverActive] = useState(false);

  const { isVendor: isChatVendor = true } = chatSetting || {};

  const currentUserContext = {
    brandName: currentUser.brandName,
    // domain: brandPageURL,
    domain: brandPageV2URL,
    shop: currentUser.shop,
    token: storageHelper.get("token"),
  };

  const actionCard = (action) => {
    const onAction = (action.action && action.action.bind(currentUserContext)) || (() => history.push(action.link));
    return (
      <>
        <Button primary={action.primary} onClick={onAction} disabled={action.disabled} loading={action.loading}>
          {action.label}
        </Button>
      </>
    );
  };
  // eslint-disable-next-line no-shadow
  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {title[path] || cms("common.actions.title.setting")}
    </Button>
  );

  const getQuickActions = (actions = []) => {
    let updatedActions = [...actions];
    if (currentUser.ecommercePlatform || !isChatVendor) {
      updatedActions = updatedActions.filter((item) => item.id !== "chatInbox");
    }
    return <ButtonGroup>{updatedActions.map((links) => actionCard(links))}</ButtonGroup>;
  };

  const getQuickSettings = (settings = []) => {
    // return settings.map((setting) => actionCard(setting));
    if (settings.length === 1) {
      return settings.map((setting) => <div>{actionCard(setting)}</div>);
    }
    const action = settings.map((setting) => {
      if (setting.action) {
        return { label: setting.label, action: setting.action.bind(currentUserContext) };
      }
      return { label: setting.label, link: setting.link };
    });
    const options = action.map((setting) => (
      <li>
        <Button plain onClick={setting.link ? () => history.push(setting.link) : setting.action}>
          {setting.label}
        </Button>
      </li>
    ));
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <DropDown className="drop-down">{options}</DropDown>
      </Popover>
    );
  };

  const quickActions = (link.length && link) || quickActionConfig[path];

  const primaryActions = (quickActions || []).filter((quickAction) => quickAction.primary);
  const secondaryActions = (quickActions || []).filter((quickAction) => !quickAction.primary);

  return [
    <Stack>
      <Stack.Item fill>{getQuickActions(primaryActions)}</Stack.Item>
      <Stack.Item>{getQuickActions(secondaryActions)}</Stack.Item>
      {!(currentUser.ecommercePlatform && path === constant.PROVIDER_LIST) && config[path] && (
        <Stack.Item>{getQuickSettings(config[path])}</Stack.Item>
      )}
    </Stack>,
    <br />,
  ];
};

OperatorQuickAction.propTypes = {
  link: PropTypes.arrayOf(PropTypes.object),
};

OperatorQuickAction.defaultProps = {
  link: [],
};

export default OperatorQuickAction;
