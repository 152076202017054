import React, { useContext } from "react";
import { Card, Collapsible, Caption, Layout, TextStyle, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const WelcomeDescription = () => {
  const { setOnboarding, onboarding, setIsSubmitEnable, welcomeDescription } = useContext(BrandingContext);
  const { CHARACTER_COUNT, DEFAULT_WELCOME_DESCRIPTION } = constant;

  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { brand } = currentUser || {};
  const { welcome, updatedAt: brandUpdatedAt = "" } = brand || {};
  const { description: welcomeDesc } = welcome || {};
  const { updatedAt = "" } = welcomeDesc || {};

  const onChangeAction = (value) => {
    if (value && value.slice(0, CHARACTER_COUNT)) {
      setOnboarding({ ...onboarding, welcomeDescription: value.slice(0, CHARACTER_COUNT) });
      setIsSubmitEnable(true);
    }
    if (!value) {
      setOnboarding({ ...onboarding, welcomeDescription: "" });
      setIsSubmitEnable(true);
    }
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.welcomeDescription.title")}
        description={cms("section.welcomeDescription.description")}
      >
        <Card
          title={[
            cms("section.welcomeDescription.card.title"),
            updatedAt ? (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ) : (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(brandUpdatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          actions={
            welcomeDescription !== DEFAULT_WELCOME_DESCRIPTION && [
              {
                content: cms("label.reset"),
                onAction: () => [
                  setOnboarding({ ...onboarding, welcomeDescription: DEFAULT_WELCOME_DESCRIPTION }),
                  setIsSubmitEnable(true),
                ],
              },
            ]
          }
        >
          <Card.Section>
            {/* <TextField
              label="On boarding title"
              placeholder="Add title"
              value={onBoardingTitle}
              onChange={(value) => handleChange(ONBOARDING_TITLE, value, ONBOARDING_TITLE)}
            />
            <br /> */}
            <TextField value={welcomeDescription} onChange={(value) => onChangeAction(value)} multiline={4} />
            <br />
            <TextStyle variation="subdued">
              {`${CHARACTER_COUNT - welcomeDescription.length} / ${CHARACTER_COUNT}`}
            </TextStyle>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(WelcomeDescription), {
  feature: constant.STANDARD_BRANDING,
});
