import { gql } from "apollo-boost";

const UPDATE_MANAGE_FULFILLMENT_SETTING = gql`
  mutation updateManageFulfillmentSetting($input: ManageFulfillmentSettingInput!) {
    updateManageFulfillmentSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_MANAGE_FULFILLMENT_SETTING;
