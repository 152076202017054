import { gql } from "apollo-boost";

const GET_SHIPPO_CARRIER = gql`
  query getShippoCarrier($input: ServiceLevel) {
    getShippoCarrier(input: $input) {
      data {
        name
        service
        modalType
      }
      error
      status
    }
  }
`;

export default GET_SHIPPO_CARRIER;
