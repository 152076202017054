/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Layout, List, Card, Checkbox, Stack, Popover, OptionList, RadioButton } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper } from "lib/helpers";

import { Spinner } from "lib/components";

// constants
import constant from "lib/constant/constant";
import AutoComplete from "lib/components/autocomplete/autocomplete";

import { GET_SHIPPING_REGION } from "app/shipping/apollo/queries";

import DestinationShipping from "./components/destinationShipping";

const ManageGlobalShipping = (props) => {
  const {
    globalSelectedDestinations,
    setGlobalSelectedDestinations,
    globalAllowAllShippingByVendor,
    setGlobalAllowAllShippingByVendor,
    globalVendorShipping,
    setGlobalVendorShipping,
    globalVendorIds,
    setGlobalVendorIds,
    vendors,
  } = props;

  const { cms } = useContext(PrivateContext);
  const [popoverActive, setPopoverActive] = useState(false);
  const [destinations, setDestinations] = useState([]);

  const fixedType = constant.FIXED;
  const all = constant.ALL;
  const { loading: destinationLoading, data: destinationData } = useQuery(GET_SHIPPING_REGION);

  useEffect(() => {
    const destinationDetails = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];
    const destinationSelect = [{ label: "Select Country", value: "" }];
    setDestinations([...destinationSelect, ...destinationDetails]);
  }, [destinationData]);

  const togglePopover = useCallback(() => setPopoverActive((popover) => !popover), []);

  const renderDestination = () => {
    const list = destinations.map((item) => {
      const { title, options = [] } = item;
      const selectedOptions = options.filter((option) => globalSelectedDestinations.includes(option.value));
      if (!selectedOptions.length) {
        return null;
      }
      return (
        <List.Item>
          {title}
          <List type="bullet">
            {selectedOptions.map((option) => (
              <List.Item>
                {option.label} - {option.value}
              </List.Item>
            ))}
          </List>
        </List.Item>
      );
    });

    const destinationList = list.filter((item) => item);

    if (!destinationList.length) {
      return null;
    }

    return (
      <Stack>
        <Stack.Item>
          <List type="number">{destinationList}</List>
        </Stack.Item>
      </Stack>
    );
  };

  if (destinationLoading) {
    return <Spinner />;
  }

  const activator = (
    <Button size="slim" onClick={togglePopover}>
      {cms("operator.section.globalShipping.label.shippingRegion")}
    </Button>
  );

  const handleVendorShipping = (vendorShippingRows, vendorId) => {
    globalVendorShipping[vendorId] = [...vendorShippingRows];
    setGlobalVendorShipping(globalVendorShipping);
  };

  const handleDestination = (destinationsData) => {
    Object.keys(globalVendorShipping).forEach((vendorId) => {
      const previousVendorShipping = [...globalVendorShipping[vendorId]];
      let updatedVendorShipping = [];
      destinationsData.sort().forEach((destination) => {
        const shipping = previousVendorShipping.filter((item) => item.destination === destination);
        if (shipping.length) {
          updatedVendorShipping = [...updatedVendorShipping, ...shipping];
        } else {
          updatedVendorShipping.push({
            destination,
            currencyCode: "",
            amount: "",
            type: fixedType,
            taxRate: 0,
          });
        }
      });
      globalVendorShipping[vendorId] = [...updatedVendorShipping];
    });
    setGlobalSelectedDestinations([...destinationsData].sort(), globalVendorShipping);
  };

  const handleVendors = (vendorIds) => {
    const availableVendorIds = Object.keys(globalVendorShipping).filter((vendorId) => vendorId !== all);

    vendorIds.forEach((selectedVendorId) => {
      if (!globalVendorShipping[selectedVendorId]) {
        globalVendorShipping[selectedVendorId] = [];
        globalSelectedDestinations.forEach((destination) => {
          globalVendorShipping[selectedVendorId].push({
            destination,
            currencyCode: "",
            amount: "",
            type: fixedType,
            taxRate: 0,
          });
        });
      }
    });

    availableVendorIds.map((availableVendorId) => {
      if (!vendorIds.includes(availableVendorId)) {
        delete globalVendorShipping[availableVendorId];
      }
    });

    setGlobalVendorIds(vendorIds);
    setGlobalVendorShipping(globalVendorShipping);
  };

  const renderVendorShippingCards = () => {
    const vendorCards = (globalVendorIds || []).map((vendorId) => {
      const vendorData = vendors.find((vendor) => vendor.value === vendorId);
      if (!vendorData) {
        return false;
      }
      return (
        <DestinationShipping
          vendorId={vendorId}
          cardLabel={vendorData.label}
          vendorShippingRowsData={globalVendorShipping[vendorId] ? [...globalVendorShipping[vendorId]] : []}
          onChange={(vendorShippingRows) => handleVendorShipping(vendorShippingRows, vendorId)}
        />
      );
    });
    return vendorCards.filter((item) => item);
  };

  return (
    <>
      <Layout.AnnotatedSection description={cms("operator.section.showLiveRates.description")}>
        <Card sectioned>
          <Checkbox checked disabled label={cms("operator.section.showLiveRates.label")} />
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection description={cms("operator.section.chargeShipping.label.description")}>
        <Card sectioned>
          <Stack vertical>
            <Stack.Item>
              <Popover active={popoverActive} activator={activator} onClose={togglePopover}>
                <OptionList
                  onChange={handleDestination}
                  sections={destinations}
                  selected={globalSelectedDestinations}
                  allowMultiple
                />
              </Popover>
              <br />
              {renderDestination()}
            </Stack.Item>
            {globalSelectedDestinations && globalSelectedDestinations.length && (
              <Stack.Item>
                <RadioButton
                  label={cms("operator.section.globalShipping.label.allVendors")}
                  helpText={cms("operator.section.globalShipping.helpText.allVendors")}
                  checked={globalAllowAllShippingByVendor}
                  onChange={() => setGlobalAllowAllShippingByVendor(!globalAllowAllShippingByVendor)}
                />
                {globalAllowAllShippingByVendor && (
                  <Layout.Section>
                    <DestinationShipping
                      cardLabel="Global Shipping"
                      vendorId={all}
                      vendorShippingRowsData={globalVendorShipping.all ? [...globalVendorShipping.all] : []}
                      onChange={(vendorShippingRows) => handleVendorShipping(vendorShippingRows, all)}
                    />
                  </Layout.Section>
                )}
                <br />
                <RadioButton
                  label={cms("operator.section.globalShipping.label.selectedVendors")}
                  helpText={cms("operator.section.globalShipping.helpText.selectedVendors")}
                  checked={!globalAllowAllShippingByVendor}
                  onChange={() => setGlobalAllowAllShippingByVendor(!globalAllowAllShippingByVendor)}
                />
                {!globalAllowAllShippingByVendor && (
                  <Layout.Section>
                    <AutoComplete
                      label="Select Vendors"
                      labelHidden
                      placeholder={cms("operator.section.globalShipping.placeHolder.brandName")}
                      values={vendors}
                      selected={globalVendorIds}
                      onChange={handleVendors}
                      minimumSearchLength={3}
                    />
                    <br />
                    {renderVendorShippingCards()}
                  </Layout.Section>
                )}
              </Stack.Item>
            )}
          </Stack>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

export default ManageGlobalShipping;
