import { gql } from "apollo-boost";

const GET_VENDOR_LIST = gql`
  query getLakeConsignmentVendorList($input: ShippingLabelInput!) {
    getLakeConsignmentVendorList(input: $input) {
      status
      data {
        label
        value
      }
      error
    }
  }
`;

export default GET_VENDOR_LIST;
