import { gql } from "apollo-boost";

const VENDOR_LIST = gql`
  subscription {
    getVendorList {
      data {
        vendor {
          email {
            address
            verified
          }
          address
          brandName
          country
          isInactive
          createdAt
          city
          firstName
          isApproved
          lastName
          logo
          phoneNumber
          pinCode
          provinceCode
          _id
          roles {
            name
          }
        }
      }
      status
      error
    }
  }
`;

export default VENDOR_LIST;
