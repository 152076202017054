import React, { useState, useEffect, useContext } from "react";
import { Modal, TextField, Card, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { updateOrderNote } from "app/orders/apollo/updateOrderNote";
import { PrivateContext } from "lib/context";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { errorHelper } from "lib/helpers";

const Notes = (props) => {
  const { match, cms } = useContext(PrivateContext);
  const { /* dataItem, */ note, onRefetchOrderView, setBanner, setMessage } = props;
  const [notes, setNotes] = useState(note);
  const [initialNotes, setInitialNotes] = useState(note);
  const [notesModal, setNotesModal] = useState(false);
  const [updateNewNotes, setUpdateNewNotes] = useState(true);

  const [updateNotes, { loading: loadingNote, error: errorNote, data: dataNote }] = useMutation(updateOrderNote);

  const updateNote = () => {
    const noteValue = initialNotes.trim();
    const formValues = {
      note: noteValue,
      // sellerId: dataItem.sellerId,
      // shopifyOrderId: dataItem.shopifyOrderId,
      id: match.params.id,
    };
    if (noteValue) {
      updateNotes({ variables: { input: formValues } });
      setUpdateNewNotes(true);
    }
  };
  useEffect(() => {
    if (dataNote && updateNewNotes) {
      const responseNote = baseHelper.getResponseData(dataNote, constant.gql.UPDATE_ORDER_NOTE);
      const responseNoteError = baseHelper.getResponseError(dataNote, constant.gql.UPDATE_ORDER_NOTE);
      if (responseNote) {
        setNotesModal(false);
        setNotes(initialNotes);
        setMessage(cms("message.success.noteUpdated"));
        onRefetchOrderView();
      }
      if (responseNoteError) {
        setNotesModal(false);
        setBanner({
          status: "critical",
          title: responseNoteError,
          isOpen: true,
        });
      }
      setUpdateNewNotes(false);
    }
  }, [dataNote, initialNotes, onRefetchOrderView, setBanner, cms, updateNewNotes, setMessage]);

  useEffect(() => {
    setNotesModal(false);
    if (errorNote) {
      setBanner({
        status: "critical",
        title: errorHelper.parse(errorNote),
        isOpen: true,
      });
    }
  }, [errorNote, setBanner]);
  const renderUpdateNotes = () => {
    return (
      <>
        <Modal
          open={notesModal}
          onClose={() => {
            setNotesModal(false);
            setInitialNotes(notes);
          }}
          title={cms("label.updateNote")}
          primaryAction={{
            content: cms("label.updateNote"),
            onAction: () => updateNote(),
            loading: loadingNote,
          }}
          sectioned
        >
          <Modal.Section>
            <TextField
              label={cms("label.note")}
              maxLength={5000}
              value={initialNotes}
              onChange={(val) => {
                setInitialNotes(val);
              }}
            />
          </Modal.Section>
        </Modal>
      </>
    );
  };

  return [
    renderUpdateNotes(),
    <Card
      title={cms("label.note")}
      actions={[
        {
          content: cms("label.edit"),
          onAction: () => {
            setNotesModal(true);
          },
        },
      ]}
    >
      <Card.Section>
        <TextStyle variation={!notes && constant.SUBDUED}>{notes.trim() || cms("label.noNotes")}</TextStyle>
      </Card.Section>
    </Card>,
  ];
};

export default Notes;
