const cmsCustomDomain = (cms) => {
  const cmsData = {
    mainLink: {
      shop: "/operators/view/",
    },
    sortOptions: [
      { label: cms("common.label.sortOption.dateAsc"), value: "createdAt_asc" },
      { label: cms("common.label.sortOption.dateDesc"), value: "createdAt_desc" },
      { label: cms("label.sortOption.shopAsc"), value: "shop_asc" },
      { label: cms("label.sortOption.shopDesc"), value: "shop_desc" },
    ],
    resourceName: cms("label.resourceName"),
    tabs: [
      {
        id: "all-sellers",
        content: cms("label.tab.all"),
        accessibilityLabel: "All sellers",
        panelID: "all-sellers-content",
        value: "all",
      },
      {
        id: "active-domain",
        content: cms("label.tab.activeDomain"),
        panelID: "active-domain-content",
        value: "active",
      },
      {
        id: "inactive-domain",
        content: cms("label.tab.inactiveDomain"),
        panelID: "inactive-domain-content",
        value: "inactive",
      },
      {
        id: "trial",
        content: cms("label.tab.trial"),
        panelID: "trial-content",
        value: "trial",
      },
    ],
    badge: ["verified"],
    tableData: {
      tbody: [],
    },
    label: {
      approved: cms("label.approved"),
      pending: cms("label.pending"),
      disable: cms("label.disable"),
      disabled: cms("label.disabled"),
      domain: cms("label.domain"),
      email: cms("label.email"),
      enable: cms("label.enable"),
      enabled: cms("label.enabled"),
      onBoardedOn: cms("label.onBoardedOn"),
      qoutaLimit: cms("label.qoutaLimit"),
      successfullyEnabledCustomDomain: cms("message.success.enabled"),
      successfullyDisabledCustomDomain: cms("message.success.disabled"),
    },
  };
  return cmsData;
};

export default cmsCustomDomain;
