import React, { useContext } from "react";
import { Card, TextStyle } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { customerProp } from "app/orders/modules/admin/features/view/propsType";

const Customer = (props) => {
  const { cms } = useContext(PrivateContext);
  const { data } = props;
  const { customer, shippingAddress, billingAddress } = data || {};
  const { default_address: defaultAddress, email, phone } = customer || {};
  const { address1, address2, zip, city, province, country, phone: shippingPhone } = shippingAddress || {};
  const {
    address1: billingAddress1,
    address2: billingAddress2,
    zip: billingZip,
    city: billingCity,
    province: billingProvince,
    country: billingCountry,
    phone: billingPhone,
  } = billingAddress || {};
  return (
    <Card title={cms("label.customer")}>
      <Card.Section>
        <TextStyle variation={!(customer && defaultAddress && defaultAddress.name) && constant.SUBDUED}>
          {(customer && defaultAddress && defaultAddress.name) || cms("label.noCustomer")}
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.contactInformation")}>
        <TextStyle variation={!(customer && email) && constant.SUBDUED}>
          <p>{(customer && email) || cms("label.noEmailAddress")}</p>
          <p>{(customer && phone) || cms("label.noPhoneNumber")}</p>
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.shippingAddress").toUpperCase()}>
        {shippingAddress ? (
          <TextStyle>
            <p>
              {shippingAddress && address1}
              {shippingAddress && address2}
            </p>
            <p>
              {shippingAddress && zip}
              {shippingAddress && city}
              {shippingAddress && province}
            </p>
            <p>{shippingAddress && country}</p>
            <p>{shippingAddress && shippingPhone}</p>
          </TextStyle>
        ) : (
          <TextStyle variation={constant.SUBDUED}>{cms("label.noShippingAddress")}</TextStyle>
        )}
      </Card.Section>
      <Card.Section title={cms("label.billingAddress")}>
        <TextStyle>
          {billingAddress ? (
            <TextStyle>
              <p>
                {billingAddress && billingAddress1}
                {billingAddress && billingAddress2}
              </p>
              <p>
                {billingAddress && billingZip}
                {billingAddress && billingCity}
                {billingAddress && billingProvince}
              </p>
              <p>{billingAddress && billingCountry}</p>
              <p>{billingAddress && billingPhone}</p>
            </TextStyle>
          ) : (
            <TextStyle variation={constant.SUBDUED}>{cms("label.noBillingAddress")}</TextStyle>
          )}
        </TextStyle>
      </Card.Section>
    </Card>
  );
};
Customer.propTypes = customerProp.type;
export default Customer;
