import { AddProductMajor, CollectionsMajor, ChatMajor, ImagesMajor } from "@shopify/polaris-icons";
import constant from "lib/constant/constant";

const dashboardQuickLinks = (vendorHiddenModule, isEcommerceUser, cms, handleShow, onBrandV2Page) => {
  const { providerList } = constant;
  let linkData = [];
  if (cms) {
    linkData = [
      {
        label: cms("common.actions.dashboard.label.contactUs"),
        caption: cms("common.actions.dashboard.caption.contactUs"),
        icon: ChatMajor,
        link: "/contact-us",
      },
    ];

    if (!vendorHiddenModule.includes(providerList.PRODUCTS)) {
      linkData.push({
        label: cms("common.actions.dashboard.label.addProducts"),
        caption: "Add products to your store",
        icon: AddProductMajor,
        link: isEcommerceUser ? "/product-lake/add" : "/products/add",
      });
    }

    if (!vendorHiddenModule.includes(constant.ORDERS_UC_FIRST)) {
      linkData.push({
        action: handleShow,
        caption: cms("common.actions.dashboard.caption.report"),
        id: "exportOrder",
        label: cms("common.actions.dashboard.label.report"),
        icon: CollectionsMajor,
      });
    }
    if (!isEcommerceUser) {
      linkData.push({
        label: cms("common.actions.dashboard.label.viewBrandPage"),
        // caption: "View New Vendor Pages",
        icon: ImagesMajor,
        action: onBrandV2Page,
      });
    }
    // linkData.push({
    //   label: cms("common.actions.dashboard.label.viewBrandPage"),
    //   // caption: "View New Vendor Pages",
    //   icon: ImagesMajor,
    //   action: onBrandV2Page,
    // });

    if (!isEcommerceUser) {
      linkData.push({
        label: cms("common.actions.dashboard.label.brand"),
        caption: cms("common.actions.dashboard.caption.brand"),
        icon: ImagesMajor,
        action: function action() {
          const { token, domain } = this;
          const url = `${domain}/builder/${token}`;
          window.open(url);
        },
      });
    }
  }
  return linkData;
};

export default dashboardQuickLinks;
