import React, { useContext, useState, useEffect } from "react";
import { Card, Button, Stack, TextStyle, Layout, Heading, Caption } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import constant
import constant from "lib/constant/constant";

import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import mutations
import { UPDATE_PRODUCT_LISTING } from "app/productOld/apollo/mutations";
// import queries
import { GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";

const OperatorProductListingSetting = () => {
  const { gql } = constant;
  const { currentUser, cms } = useContext(PrivateContext);
  const { data: getProductListData, loading: getProductListLoading, error: getProductListError, refetch } = useQuery(
    GET_PRODUCT_LISTING
  );
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [message, setMessage] = useState("");
  // const [productListData, setProductListData] = useState(!!isEnabledValue);
  const [updateAt, setUpdateAt] = useState(new Date());

  const [isEnabledData, setIsEnabledData] = useState(false);

  const [updateProductListData, { loading }] = useMutation(UPDATE_PRODUCT_LISTING);
  const contentStatus = isEnabledData ? constant.displayStatus.DISABLE : constant.ENABLE;

  useEffect(() => {
    if (getProductListData) {
      const resData = baseHelper.getResponseData(getProductListData, gql.GET_PRODUCT_LISTING_SETTING);
      const resError = baseHelper.getResponseError(getProductListData, gql.GET_PRODUCT_LISTING_SETTING);
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
      }
      if (resData) {
        const { isEnabled, updatedAt } = resData || {};
        setIsEnabledData(isEnabled);
        setUpdateAt(updatedAt);
      }
    }
    if (getProductListError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(getProductListError),
      });
    }
  }, [getProductListData, setBanner, getProductListError]);

  const onCodeSubmit = async (enable) => {
    // setIsEnabledData(true);
    const formData = {
      isEnabled: enable,
    };
    try {
      const response = await updateProductListData({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, gql.MANAGE_PRODUCT_LISTING);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (!enable) {
        setMessage("Manage product listing setting is disabled successfully.");
        // setProductListData(!isEnabledValue);
        setIsEnabledData(!isEnabledData);
      } else {
        // setIsEnabledData(!isEnabledData);
        setMessage("Manage product listing setting is enabled successfully.");
        // setProductListData(!isEnabledValue);
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    // setIsEnabledData(false);
  };

  const renderBanner = () => {
    const { status, title, isOpen } = banner;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  if (getProductListLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {banner.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection
        title="Manage Product Listing"
        description="You can manage your product listing time on your store and your inventory threshold by enabling listing feature. By enabling this feature you can add a limit on your product listing time by mentioning the listing limit date and time from the product form.You will also be able to manage the inventory threshold on the product form by enabling this feature. "
      >
        <Card>
          <Card sectioned>
            <Stack wrap={false}>
              <Stack.Item fill>
                <Heading variation="strong">Manage Product Listing</Heading>
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
                </TextStyle>
                <br />
                <TextStyle>
                  You can manage your product listing time on your store and your inventory threshold by enbaling this setting.
                </TextStyle>
                {/* <b>{isEnabledData ? constant.ENABLED : constant.DISABLED}</b> */}
              </Stack.Item>
              <Stack.Item>
                <Button
                  id="auto"
                  primary={!isEnabledData}
                  onClick={() => onCodeSubmit(!isEnabledData)}
                  loading={loading}
                  buttonType={contentStatus}
                  destructive={isEnabledData}
                >
                  {isEnabledData ? "Disable" : "Enable"}
                </Button>
              </Stack.Item>
            </Stack>
          </Card>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default OperatorProductListingSetting;
