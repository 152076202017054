import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { subDomainRegex } = yupHelper;
const shopSubDomainSchema = (cms) => {
  return yup.object().shape({
    shopSubDomain: yup
      .string()
      .required(cms("section.message.error.domainRequired"))
      .matches(subDomainRegex, cms("section.message.error.invalidSubDomain")),
  });
};

const apiKeySchema = (cms) => {
  return yup.object().shape({
    apiKey: yup.string().required(cms("section.message.error.apiRequired")),
  });
};

export { apiKeySchema, shopSubDomainSchema };
