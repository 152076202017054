// import packages
import React, { useContext } from "react";
import { Button, TextField, Select, Stack, Checkbox, TextStyle } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import propsTypes
import { AdvanceItemProp } from "app/setup/modules/operator/features/commission/props";

const ProductMetafieldAdd = (props) => {
  const {
    count,
    updateVal,
    advancedValue,
    isMetaFieldEnable,
    removeItem,
    uniqueVal,
    disableNameKey,
    selectValue,
  } = props;
  const {
    color: { RED },
  } = constant;
  const { cms = {} } = useContext(PrivateContext);

  const advanceValueOptions = [
    {
      label: cms("label.text"),
      value: "string",
    },
    {
      label: cms("label.number"),
      value: "number",
    },
    {
      label: cms("label.measurement"),
      value: "measurement",
    },
    {
      label: "Weight",
      value: "weight",
    },
    {
      label: "Dimension",
      value: "dimension",
    },
    {
      label: "Volume",
      value: "volume",
    },
    {
      label: "Color",
      value: "color",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Date & Time",
      value: "dateTime",
    },
    {
      label: "URL",
      value: "url",
    },
    {
      label: "File",
      value: "file",
    },
    {
      label: "JSON",
      value: "json",
    },
    {
      label: "True or False",
      value: "trueFalse",
    },
    // {
    //   label: "Rating",
    //   value: "rating",
    // },
    {
      label: "Money",
      value: "money",
    },
  ];

  const extraOption = {
    json: "json",
    money: "money",
    trueFalse: "trueFalse",
  };

  const handlePlaceHolder = (value) => {
    const placeholder = {
      measurement: cms("placeholder.unit"),
      number: cms("placeholder.maxValue"),
      string: cms("placeholder.maxLength"),
      weight: "Weight",
      dimension: "Dimension",
      volume: "Volume",
      color: "Color",
      date: "Date",
      dateTime: "Date & Time",
      url: "URL",
    };
    if (value.contentType === constant.STRING && value.valueType === constant.LIST) {
      return constant.ENTER_TEXT;
    }
    return placeholder[value.contentType];
  };

  const option = [
    {
      label: "One Value",
      value: "one",
    },
    {
      label: "List of Value",
      value: "list",
    },
  ];
  const textOption = [
    {
      label: "Maximum Length",
      value: "maxLength",
    },
    {
      label: "Dropdown",
      value: "list",
    },
  ];

  const handleInput = (value, prevValue, type, valueType) => {
    if (type === constant.NUMBER) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (type === constant.STRING) {
      if (valueType === "list") {
        return value || (value !== "" && prevValue) || "";
      }
      return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && prevValue) || "";
      // return prevValue || value || "";
    }
    if (type === constant.MEASUREMENT) {
      return (baseHelper.validateMeasurement(value) && value) || (value !== "" && prevValue) || "";
    }
    if (selectValue[type]) {
      return value || (value !== "" && prevValue) || "";
    }
    return prevValue || "";
  };

  const handleKeyValidation = (value, prevValue) => {
    return (baseHelper.validateCamelCase(value) && value) || (value !== "" && prevValue) || "";
  };

  const arr = Array.from(Array(count).keys());
  return (
    <>
      {/* <div className="meta-head">
        <div className="meta-title"> Display Name</div>
        {!isMetaFieldEnable ? <div className="meta-title">Name Space</div> : null}
        {!isMetaFieldEnable ? <div className="meta-title">Key</div> : null}
        <div className="meta-title">Content Type</div>
        <div className="meta-title">Value</div>
        <div>Required</div>
      </div> */}
      {arr.map((item, index) => {
        const advanceItem = advancedValue[item];
        const advanceItemValue = advanceItem.contentType;
        let textSelected = false;
        if (advanceItemValue === "string") {
          textSelected = true;
        }
        if (!advanceItem) {
          return null;
        }

        return (
          <div key={item}>
            <Stack
              wrap={false}
              key={item}
              spacing="extraTight"
              distribution={!isMetaFieldEnable ? "fillEvenly" : ""}
              alignment="center"
            >
              <Stack.Item fill={isMetaFieldEnable}>
                <div className={isMetaFieldEnable ? "meta-input-standard" : "meta-input"}>
                  <TextField
                    required
                    label={!index ? "Display Name" : null}
                    placeholder={cms("placeholder.display")}
                    onChange={(val) => {
                      updateVal(constant.NAME, isMetaFieldEnable ? val.trim() : val, item);
                    }}
                    value={(advanceItem && advanceItem.name) || ""}
                    onBlur={() => {
                      uniqueVal(constant.NAME, advanceItem.name, item);
                    }}
                  />
                </div>
              </Stack.Item>
              {!isMetaFieldEnable && (
                <>
                  <Stack.Item>
                    <div className="meta-input">
                      <TextField
                        required
                        label={!index ? "Name Space" : null}
                        placeholder={cms("placeholder.nameSpace")}
                        onChange={(val) => {
                          updateVal(constant.NAMESPACE, val, item);
                        }}
                        onBlur={() => {
                          uniqueVal(constant.NAMESPACE, advanceItem.namespace, item);
                        }}
                        value={(advanceItem && advanceItem.namespace) || ""}
                        disabled={!disableNameKey.includes(index)}
                      />
                    </div>
                  </Stack.Item>
                </>
              )}
              {!isMetaFieldEnable && (
                <>
                  <Stack.Item>
                    <div className="meta-input">
                      <TextField
                        required
                        label={!index ? "Key" : null}
                        placeholder={cms("placeholder.key")}
                        onChange={(val) => {
                          updateVal(
                            constant.KEY,
                            handleKeyValidation(val, (advanceItem && advanceItem.key) || ""),
                            item
                          );
                        }}
                        onBlur={() => {
                          uniqueVal(constant.KEY, advanceItem.key, item);
                        }}
                        value={(advanceItem && advanceItem.key) || ""}
                        disabled={!disableNameKey.includes(index)}
                      />
                    </div>
                  </Stack.Item>
                </>
              )}
              <Stack.Item fill={isMetaFieldEnable}>
                <div className={isMetaFieldEnable ? "meta-input-standard" : "meta-input"}>
                  <Select
                    label={!index ? "Content Type" : null}
                    options={advanceValueOptions}
                    placeholder={cms("placeholder.contentType")}
                    onChange={(val) => {
                      updateVal(constant.CONTENT_TYPE, val, item);
                    }}
                    value={(advanceItem && advanceItemValue) || ""}
                  />
                </div>
              </Stack.Item>
              {
                <Stack.Item fill={isMetaFieldEnable}>
                  <div className="option-input">
                    <Select
                      options={textSelected ? textOption : null}
                      label={!index ? "Value Type" : null}
                      disabled={!textSelected}
                      placeholder="Values Type"
                      onChange={(val) => {
                        updateVal(
                          constant.VALUE_TYPE,
                          handleInput(val, (advanceItem && advanceItem.valueType) || "", "valueType"),
                          item,
                          true
                        );
                      }}
                      value={(advanceItem && advanceItem.valueType) || ""}
                    />
                  </div>
                </Stack.Item>
              }
              {selectValue[advanceItem && advanceItemValue] ||
              ["json", "trueFalse", "money"].includes(advanceItemValue) ? (
                <div className="option-input">
                  <Select
                    options={option}
                    label={!index ? "Value" : null}
                    disabled={["json", "trueFalse", "money"].includes(advanceItemValue)}
                    placeholder="Values"
                    onChange={(val) => {
                      updateVal(
                        constant.VALUE,
                        handleInput(val, (advanceItem && advanceItem.value) || "", advanceItem.contentType),
                        item,
                        true
                      );
                    }}
                    value={(advanceItem && advanceItem.value) || ""}
                  />
                </div>
              ) : (
                <>
                  <Stack.Item fill={isMetaFieldEnable}>
                    <div className="option-input">
                      {advanceItem.contentType && !["json", "trueFalse", "money"].includes(advanceItem.contentType) && (
                        <TextField
                          required
                          label={!index ? "Value" : null}
                          placeholder={handlePlaceHolder(advanceItem)}
                          onChange={(val) => {
                            updateVal(
                              constant.VALUE,
                              handleInput(
                                val,
                                (advanceItem && advanceItem.value) || "",
                                advanceItem.contentType,
                                advanceItem.valueType
                              ),
                              item
                            );
                          }}
                          value={
                            (["json", "trueFalse", "money"].includes(advanceItem.contentType) &&
                              advanceItem.contentType) ||
                            advanceItem.contentType === constant.MEASUREMENT
                              ? (advanceItem && advanceItem.value && advanceItem.value.toString()) || ""
                              : (advanceItem && advanceItem.value) || ""
                          }
                        />
                      )}
                    </div>
                  </Stack.Item>
                </>
              )}

              <>
                <div className="meta-label">{!index ? <TextStyle>Required</TextStyle> : <TextStyle />}</div>
                <Stack.Item>
                  <div className={isMetaFieldEnable ? "meta-required-standard" : "metafield_required"}>
                    <Checkbox
                      checked={advanceItem && advanceItem.isRequired}
                      onChange={(val) => updateVal("isRequired", val, item)}
                    />
                  </div>
                </Stack.Item>
              </>
              <div>
                {!index ? <br /> : null}
                <div className="metafield_delete">
                  <Button id="deleteButton" plain onClick={() => removeItem(item)}>
                    <i className="far fa-trash fa-lg" style={{ color: RED }} />
                  </Button>
                </div>
              </div>
            </Stack>
            <Stack.Item>
              <br />
            </Stack.Item>
          </div>
        );
      })}
    </>
  );
};

ProductMetafieldAdd.propTypes = AdvanceItemProp.type;

export default withErrorBoundary(ProductMetafieldAdd);
