import { gql } from "apollo-boost";

const LOGOUT_USER = gql`
  mutation {
    logout {
      status
      data
      error
    }
  }
`;

export default LOGOUT_USER;
