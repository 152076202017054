import React, { useState, useContext, useEffect } from "react";
import { Layout, Card, Caption, TextField, TextStyle, Stack, RadioButton } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper } from "lib/helpers";

import { SkeletonAnnotated } from "lib/components";

// import constant
import constant from "lib/constant/constant";

import { GET_ORDER_EXPIRY_SETTING, GET_SELLER_FULFILLMENT } from "app/setup/apollo";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

const OperatorOrderAutoExpiry = () => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: currentUserId = "" } = currentUser || {};
  const sellerId = currentUser.introductorySeller;
  const [acceptanceChecked, setAcceptanceChecked] = useState(false);
  const [acceptUpdatedAt, setAcceptUpdatedAt] = useState("");
  const [isReserve, setIsReserve] = useState(false);
  const [pickupUpdatedAt, setPickupUpdatedAt] = useState("");
  const [preferenceUpdatedAt, setPreferenceUpdatedAt] = useState("");
  const [pickupChecked, setPickupChecked] = useState(false);
  const [weekendSelected, setWeekendSelected] = useState(false);
  // const [advanceAcceptanceChecked, setAdvanceAcceptanceChecked] = useState(false);
  // const [advancePickupChecked, setAdvancePickupChecked] = useState(false);
  const [acceptanceField, setAcceptanceField] = useState("");
  // const [advanceAcceptanceField, setAdvanceAcceptanceField] = useState("");
  const [pickupField, setPickupField] = useState("");
  const [pickupFirstPush, setPickupFirstPush] = useState(false);
  const [acceptFirstPush, setAcceptFirstPush] = useState(false);
  // const [advancePickupField, setAdvancePickupField] = useState("");
  const [error, setError] = useState({});
  const [isAnyError, setIsAnyError] = useState(false);
  const [advancedValue, setAdvancedValue] = useState([
    {
      vendorId: null,
      duration: 0,
    },
  ]);
  const { loading: settingLoading = false, error: customError, data: orderAutoExpiryData } = useQuery(
    GET_ORDER_EXPIRY_SETTING,
    {
      variables: {
        input: { sellerId },
      },
    }
  );
  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  useEffect(() => {
    if (orderAutoExpiryData) {
      const resData = baseHelper.getResponseData(orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING);
      if (resData.order === null) {
        setPickupFirstPush(true);
        setAcceptFirstPush(true);
        return;
      }
      if (resData.order.orderExpirySetting) {
        const value = resData.order.orderExpirySetting;
        setWeekendSelected(value.isWeekend);
        setPreferenceUpdatedAt(value.updatedAt);
      }
      if (resData.order && resData.order.accept === null) {
        setAcceptFirstPush(true);
      } else {
        if (resData.order.accept.duration) {
          setAcceptanceChecked(true);
          setAcceptanceField(String(resData.order.accept.duration));
        }
        setAcceptUpdatedAt(resData.order.accept.updatedAt);
      }
      if (resData.order && resData.order.pickup === null) {
        setPickupFirstPush(true);
      } else {
        if (resData.order.pickup.duration) {
          setPickupChecked(true);
          setPickupField(String(resData.order.pickup.duration));
        }
        setPickupUpdatedAt(resData.order.pickup.updatedAt);
      }
      if (resData.order.advanceAccept && resData.order.advanceAccept.advance) {
        const advanceValue = resData.order.advanceAccept.advance;
        const { updatedAt = "" } = resData.order.advanceAccept;
        advanceValue.forEach((row) => {
          if (row.vendorId === currentUserId) {
            setAcceptanceChecked(true);
            setAcceptanceField(String(row.duration));
            setAcceptUpdatedAt(updatedAt);
          }
        });
      }
      if (resData.order.advancePickup && resData.order.advancePickup.advance) {
        const advanceValue = resData.order.advancePickup.advance;
        const { updatedAt = "" } = resData.order.advancePickup;
        advanceValue.forEach((row) => {
          if (row.vendorId === currentUserId) {
            setPickupChecked(true);
            setPickupField(String(row.duration));
            setPickupUpdatedAt(updatedAt);
          }
        });
      }
    }
  }, [orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING]);

  useEffect(() => {
    if (fulfillmentData) {
      const resData = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT);
      if (resData.type === constant.RESERVE || resData.type === constant.HYBRID) {
        setIsReserve(true);
      }
    }
  }, [fulfillmentData, gql.GET_SELLER_FULFILLMENT]);

  if (settingLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <div>
      <Layout.AnnotatedSection title={cms("infoTitle")} description={cms("description")}>
        <Card
          sectioned
          title={[
            cms("label.expiryPreference"),
            preferenceUpdatedAt && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(preferenceUpdatedAt)}`}</TextStyle>
              </Caption>
            ),
          ]}
        >
          <Stack vertical>
            <RadioButton
              label={cms("label.excludeWeekend")}
              helpText={cms("helpText.excludeWeekend")}
              checked={weekendSelected === false}
              id="fiveDay"
              disabled
              // onChange={handleChange}
            />
            <RadioButton
              label={cms("label.includeWeekend")}
              helpText={cms("helpText.includeWeekend")}
              id="sevenDay"
              checked={weekendSelected === true}
              // onChange={handleChange}
              disabled
            />
          </Stack>
        </Card>
        <Card
          sectioned
          title={[
            cms("label.accept"),
            acceptUpdatedAt && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(acceptUpdatedAt)}`}</TextStyle>
              </Caption>
            ),
          ]}
        >
          <Stack vertical>
            <RadioButton
              label={cms("label.disable")}
              helpText={cms("helpText.disable")}
              checked={acceptanceChecked === false}
              id="disableAcceptance"
              disabled
              // onChange={handleChange}
            />
            <RadioButton
              label={cms("label.enable")}
              helpText={cms("helpText.enable")}
              id="enableAcceptance"
              checked={acceptanceChecked === true}
              // onChange={handleChange}
              disabled
            />
            <div className="text-box">
              {acceptanceChecked && (
                <TextField id="Acceptance" placeholder="Days" value={acceptanceField} suffix="Days" disabled />
              )}
            </div>
          </Stack>
        </Card>
        {isReserve && (
          <Card
            sectioned
            title={[
              cms("label.pickup"),
              pickupUpdatedAt && (
                <Caption>
                  <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(pickupUpdatedAt)}`}</TextStyle>
                </Caption>
              ),
            ]}
          >
            <Stack vertical>
              <RadioButton
                label={cms("label.disable")}
                helpText={cms("helpText.disable")}
                checked={pickupChecked === false}
                id="disablePickup"
                disabled
                // onChange={handleChange}
              />
              <RadioButton
                label={cms("label.enable")}
                helpText={cms("helpText.enable")}
                id="enablePickup"
                checked={pickupChecked === true}
                disabled
                // onChange={handleChange}
              />
              <div className="text-box">
                {pickupChecked && (
                  <TextField id="Pickup" placeholder="Days" value={pickupField} suffix="Days" disabled />
                )}
              </div>
            </Stack>
          </Card>
        )}
      </Layout.AnnotatedSection>
    </div>
  );
};

export default withFeature(withErrorBoundary(OperatorOrderAutoExpiry), { feature: constant.ORDER_EXPIRY });
