import PropTypes from "prop-types";

const connectedShopProp = {
  currentUserData: PropTypes.object.isRequired,
  setBanner: PropTypes.func.isRequired,
  setIsConnected: PropTypes.func.isRequired,
  setShop: PropTypes.func.isRequired,
};

const syncProduct = {
  type: {
    setBanner: PropTypes.func,
  },
  default: {
    setBanner: () => {},
  },
};

const syncWixProduct = {
  type: {
    setBanner: PropTypes.func,
  },
  default: {
    setBanner: () => {},
  },
};

const syncWooCommerceProduct = {
  type: {
    setBanner: PropTypes.func,
  },
  default: {
    setBanner: () => {},
  },
};

const tabProp = {
  type: {
    selectedTab: PropTypes.number,
    setBannerValue: PropTypes.func,
    setDescription: PropTypes.string,
    tabObj: PropTypes.object,
  },
};

const tagProp = {
  type: {
    data: PropTypes.shape({
      vendorValue: PropTypes.string,
      productValueType: PropTypes.string,
    }),
    productTag: PropTypes.array,
    handleChange: PropTypes.func,
    error: PropTypes.string,
  },
};

const termProp = {
  type: {
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    setIsOpen: PropTypes.func.isRequired,
  },
  default: {
    isOpen: false,
    setIsOpen: () => {},
    cms: {},
    loading: false,
    onSubmit: () => {},
  },
};

export { connectedShopProp, syncProduct, syncWooCommerceProduct, syncWixProduct, tabProp, tagProp, termProp };
