import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Layout, TextField, Stack, Select, Button, Popover } from "@shopify/polaris";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const DateMetaField = ({ metafield, inputValue, setValue, setDisabledButton, setBanner }) => {
  const { cms, history, match } = useContext(PrivateContext);
  const [activeCalendar, setActiveCalendar] = useState({});
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [dates, setDates] = useState([inputValue[metafield.key]]);

  useEffect(() => {
    const isArray = Array.isArray(inputValue[metafield.key]);
    const value = isArray ? inputValue[metafield.key] : [inputValue[metafield.key]];
    setDates(value);
  }, [inputValue[metafield.key]]);

  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  const handleChange = (index, value) => {
    const updateData = dates || [];
    const isDateTime = metafield.contentType === "dateTime";
    if (isDateTime) {
      value.setHours(0);
      value.setMinutes(0);
    }
    updateData[index] = value ? moment(value).format("YYYY-MM-DD") : "";
    const keyName = metafield.key;
    setDates([...updateData]);
    setValue({
      ...inputValue,
      [keyName]: updateData,
    });
    setDisabledButton(false);
  };

  const handleAdd = () => {
    const addDates = dates || [];
    const isError = addDates.filter((item) => item).length !== addDates.length;
    if (isError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.row") || "Please complete the row details.",
      });
      return;
    }
    addDates.push("");
    setDates([...addDates]);
  };

  const handleDelete = (index) => {
    const updateData = dates || [];
    updateData.splice(index, 1);
    setDates([...updateData]);
    setDisabledButton(false);
  };

  const handleDurationChange = (index, val) => {
    const updateData = dates || [];
    const newVal = val ? val.split(":") : [];
    const newDate = new Date(updateData[index]);
    newDate.setHours(newVal[0]);
    newDate.setMinutes(newVal[1]);
    updateData[index] = moment(newDate).format("YYYY-MM-DD HH:mm");
    setDates([...updateData]);
    setValue({
      ...inputValue,
      [metafield.key]: updateData,
    });
    setDisabledButton(false);
  };

  return (
    <>
      <div className="stackItem-50">
        <Stack>
          <Stack.Item fill>{`${metafield.name} ${metafield.isRequired ? "*" : ""}`}</Stack.Item>
          {metafield.contentType === "dateTime" && <Stack.Item fill>Select time</Stack.Item>}
          {metafield.value === "list" && dates.length > 1 && <Stack.Item />}
        </Stack>
      </div>
      <div className="stackItem-50">
        {(dates || []).map((item, index) => {
          return (
            // <div className="tab-header-actions">
            <>
              <Stack wrap={false}>
                <Stack.Item fill>
                  <Popover
                    active={activeCalendar[index]}
                    activator={
                      <TextField
                        // label={!index ? metafield.name : ""}
                        // value={item}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        value={item ? moment(item).format("YYYY-MM-DD") : ""}
                        // eslint-disable-next-line no-shadow
                        // onChange={(value) => handelChange(index, value.start)}
                        placeholder="DD/MM/YYYY"
                        onFocus={() => setActiveCalendar({ [index]: true })}
                      />
                    }
                    onClose={() => setActiveCalendar({})}
                  >
                    <DatePicker
                      month={month}
                      year={year}
                      // eslint-disable-next-line no-shadow
                      onChange={(value) => [handleChange(index, value.start), setActiveCalendar({})]}
                      onMonthChange={handleMonthChange}
                      selected={selectedDates}
                      allowRange={false}
                      // disableDatesAfter={new Date()}
                    />
                  </Popover>
                </Stack.Item>
                {metafield.contentType === "dateTime" && (
                  <Stack.Item fill>
                    <div className="select-time">
                      <Select
                        // label={!index ? "Select time" : ""}
                        id="selectDuration"
                        disabled={!item}
                        placeholder={cms("placeholder.time")}
                        options={baseHelper.getTime() || []}
                        value={item ? moment(item).format("HH:mm") : ""}
                        onChange={(val) => handleDurationChange(index, val)}
                      />
                    </div>
                  </Stack.Item>
                )}
                {/* {activeCalendar[index] && (
                  <>
                    <DatePicker
                      month={month}
                      year={year}
                      // eslint-disable-next-line no-shadow
                      onChange={(value) => [handleChange(index, value.start), setActiveCalendar({})]}
                      onMonthChange={handleMonthChange}
                      selected={selectedDates}
                      allowRange={false}
                      // disableDatesAfter={new Date()}
                    />
                  </>
                )} */}
                {metafield.value === "list" && dates.length > 1 && (
                  <Stack.Item>
                    <Button id="deleteButton" plain onClick={() => handleDelete(index)}>
                      <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                    </Button>
                  </Stack.Item>
                )}
              </Stack>
              <br />
            </>
          );
        })}
      </div>

      {metafield.value === "list" && (
        <>
          <Button plain id="addLink" onClick={() => handleAdd()}>
            {`${cms("common.button.addMore")}`}
          </Button>
        </>
      )}
    </>
  );
};

export default DateMetaField;
