import React from "react";
import { storageHelper } from "lib/helpers";

import constant from "lib/constant/constant";
import { OperatorStepperOnboarding } from "./operator";
import { ProviderStepperOnboarding } from "./provider";

const { userKey } = constant;
const { operator, provider } = userKey;

const StepperOnboarding = () => {
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorStepperOnboarding />;
    }
    if (role === provider) {
      return <ProviderStepperOnboarding />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const StepperOnboardingComponent = getComponent(currentUserRole);
  return StepperOnboardingComponent;
};

export default StepperOnboarding;
