// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_EXPORT_FILTER = gql`
  query getExportFilter {
    getExportFilter {
        data {
          vendorFilter {
            id
            name
          }
          statusFilter {
            id
            name
          }
          fulfillmentStatuses
          orderStatuses
        }
        status
        error
    }
  }
`;

export default GET_EXPORT_FILTER;
