import React, { useContext, useState, useEffect } from "react";
import { TextField, Card, Layout, FormLayout, PageActions } from "@shopify/polaris";

import { useQuery, useMutation } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { PublicContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";
import { baseHelper, storageHelper, errorHelper } from "lib/helpers";

import { GENERATE_PASSWORD } from "app/public/apollo/mutations";
import { GET_SELLER_DATA } from "app/public/apollo/queries";

import validate from "./yup";

const GeneratePassword = () => {
  const { history, cms } = useContext(PublicContext);

  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState(true);
  const { CONFIRM_PASSWORD, CRITICAL, gql, NEW_PASSWORD, PASSWORD, SUCCESS } = constant;

  const [sellerDetails, setSellerDetails] = useState();

  const queryParams = baseHelper.getQueryParams(history.location.search) || {};
  const { shop = "", token = "" } = queryParams;
  const { loading: sellerDataLoading, data: sellerData } = useQuery(GET_SELLER_DATA, {
    variables: { input: { shop } },
  });

  const [otp, { loading: otpLoading }] = useMutation(GENERATE_PASSWORD);

  useEffect(() => {
    if (sellerDataLoading) {
      return;
    }
    const responseData = baseHelper.getResponseData(sellerData, gql.GET_SELLER_DATA);
    if (responseData) {
      setSellerDetails({
        email: responseData.email.address,
        password: token,
      });
    }
  }, [sellerDataLoading, sellerData, gql.GET_SELLER_DATA, token]);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    await handleValidation(NEW_PASSWORD, values.newPassword);
    await handleValidation(CONFIRM_PASSWORD, {
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });
    const isAnyValidationError = Object.values(errorMessage).some((error) => error);
    const isAllValuesFilled = () => Object.values(values).every((value) => value);
    if (!isAnyValidationError && isAllValuesFilled()) {
      try {
        let bannerData = {};
        let redirectURL = "";
        const reqData = {
          email:
            (sellerDetails && sellerDetails.email) ||
            (storageHelper.get("email") && storageHelper.get("email").toLowerCase()),
          password: (sellerDetails && sellerDetails.password) || storageHelper.get("password"),
          newPassword: values.newPassword,
        };

        const res = await otp({
          variables: { input: reqData },
        });
        const responseData = baseHelper.getResponseData(res.data, gql.GENERATE_PASSWORD);
        const responseError = baseHelper.getResponseError(res.data, gql.GENERATE_PASSWORD);
        bannerData = {
          status: SUCCESS,
          title: cms("message.success"),
        };
        redirectURL = "/login";

        if (!responseData) {
          bannerData = {
            status: CRITICAL,
            title: cms("message.error"),
          };
          redirectURL = "";
        }

        setBanner({
          isOpen: true,
          status: bannerData.status,
          title: responseError || bannerData.title,
        });
        if (redirectURL) {
          setTimeout(() => {
            history.push(redirectURL);
          }, 2000);
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    }
  };

  return (
    <>
      <Banner
        title={banner.title}
        status={banner.status}
        isOpen={banner.isOpen}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
      <br />
      <Layout>
        <Layout.AnnotatedSection title={`${cms("title")}`}>
          <Card sectioned>
            <FormLayout>
              <TextField
                id="newPassword"
                label={`${cms("label.newPassword")}*`}
                type={PASSWORD}
                value={values.newPassword || ""}
                placeholder={`${cms("placeholder.newPassword")}`}
                onChange={(value) => handleChange(NEW_PASSWORD, value)}
                onBlur={() => [
                  handleValidation(NEW_PASSWORD, values.newPassword),
                  values.confirmPassword &&
                    handleValidation(CONFIRM_PASSWORD, {
                      newPassword: values.newPassword,
                      confirmPassword: values.confirmPassword,
                    }),
                ]}
                error={errorMessage && errorMessage.newPassword}
              />
              <TextField
                id="confirmPassword"
                label={`${cms("label.confirmNewPassword")}*`}
                placeholder={cms("placeholder.confirmNewPassword")}
                type={PASSWORD}
                value={values.confirmPassword || ""}
                onChange={(value) => handleChange(CONFIRM_PASSWORD, value)}
                onBlur={() => {
                  handleValidation(CONFIRM_PASSWORD, {
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                  });
                }}
                error={errorMessage && errorMessage.confirmPassword}
              />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "resetPassword",
              content: cms("common.button.submit"),
              onAction: onSubmit,
              loading: otpLoading,
            }}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default withErrorBoundary(GeneratePassword);
