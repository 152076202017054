import React, { useContext } from "react";
import { RadioButton } from "@shopify/polaris";

// propTypes
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const ThirdPartyShipping = (props) => {
  const { cms } = useContext(PrivateContext);
  const { isChecked, handleShipping } = props;
  return (
    <RadioButton
      label={`${cms("label.thirdParty")} ${cms("label.shipping")}`}
      helpText={`${cms("label.thirdParty")} ${cms("label.shipping")}`}
      checked={isChecked}
      onChange={() => handleShipping("eShip")}
    />
  );
};

ThirdPartyShipping.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  handleShipping: PropTypes.func.isRequired,
};

export default withFeature(ThirdPartyShipping, { feature: constant.DROPSHIP });
