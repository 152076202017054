import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
} from "./schema";

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  STREET_ADDRESS,
  CITY,
  COUNTRY,
  POSTAL_CODE,
  PROVINCE_CODE,
  BRAND_NAME,
  BRAND_HANDLE,
} = constant;
const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms, schema, label) => {
  let promise = "";
  const { min = 2 } = schema || {};
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(`${cms("common.message.error.required", { item: label })}`), {
        firstName: value,
      });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(`${cms("common.message.error.required", { item: label })}`), {
        lastName: value,
      });
      break;
    case EMAIL:
      promise = validateValue(
        emailSchema(
          `${cms("common.message.error.required", { item: label })}`,
          `${cms("common.message.error.enterValid", { item: label.toLowerCase() })}`
        ),
        { email: value }
      );
      break;
    case PHONE_NUMBER:
      promise = validateValue(
        phoneNumberSchema(
          `${cms("common.message.error.required", { item: label })}`,
          `${cms("common.message.error.minPhoneNumberChar", { item: label })}`,
          `${cms("common.message.error.maxPhoneNumberChar", { item: label })}`,
          `${cms("common.message.error.positiveNumber")}`
        ),
        {
          phoneNumber: value,
        }
      );
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(`${cms("common.message.error.required", { item: label })}`), {
        streetAddress: value,
      });
      break;
    case CITY:
      promise = validateValue(citySchema(`${cms("common.message.error.required", { item: label })}`), {
        city: value,
      });
      break;
    case PROVINCE_CODE:
      promise = validateValue(provinceCodeSchema(`${cms("message.error.common.provinceCode")}`), {
        provinceCode: value,
      });
      break;
    case COUNTRY:
      promise = validateValue(countrySchema(`${cms("message.error.common.country")}`), {
        country: value,
      });
      break;
    case POSTAL_CODE:
      promise = validateValue(
        postalCodeSchema(
          `${cms("common.message.error.required", { item: label })}`,
          `${cms("common.message.error.minPostalCodeChar", { item: label, min })}`,
          `${cms("common.message.error.maxPostalCodeChar", { item: label })}`,
          schema
        ),
        {
          postalCode: value,
        }
      );
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(`${cms("common.message.error.required", { item: label })}`), {
        brandName: value,
      });
      break;
    case BRAND_HANDLE:
      promise = validateValue(
        brandHandleSchema(
          `${cms("common.message.error.required", {
            item: label,
          })}`,
          `${cms("message.error.common.brandHandle.brandHandleMatch", { item: label.toLowerCase() })}`
        ),
        {
          brandName: value,
        }
      );
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
