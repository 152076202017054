import { gql } from "apollo-boost";

const GET_PRODUCT_LAKE_VARIANT = gql`
  query getProductLakeVariant($input: GetProductLakeVariantInput) {
    getProductLakeVariant(input: $input) {
      data {
        _id
        optionValues {
          _id
          label
          sortOrder
          providerOptionValueId
          optionId {
            _id
            displayName
            sortOrder
            providerOptionId
          }
        }
        price
        priceId {
          price
          costPrice
          regularPrice
          calculatedPrice
          salePrice
        }
        option
        inventoryId {
          productId
          sku
          quantity
          isManagingStock
        }
        weight
        images {
          imageId
          imageUrl
        }
        productImages
        providerVariantId
      }
      error
      status
    }
  }
`;
export default GET_PRODUCT_LAKE_VARIANT;
