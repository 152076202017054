import { gql } from "apollo-boost";

const GET_TRANSACTION_FEE = gql`
  query {
    getTransactionFee {
      data {
        isEnabled
        isOrderBasedCommission
        labels {
          labelName
          feeType
          feeValue
        }
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_TRANSACTION_FEE;
