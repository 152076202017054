import React, { useContext } from "react";

import { Button, Caption, Heading, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import PropTypes from "prop-types";
// import { useQuery } from "@apollo/react-hooks";

// import from lib
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";

const SellerPlanList = (props) => {
  const { cms, history, currentUser } = useContext(PrivateContext);

  // const [profileImage, setProfileImage] = useState();

  const { item, isStripe, keyIndex, loading, setMessageToast } = props;

  const { _id, code, name } = item;

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex}>
        <Stack>
          <Stack.Item fill>
            <TextStyle>
              {/* <div className="ellipsis"> */}
              <Heading element="h2">
                {!isStripe &&
                  <Link onClick={() => history.push(`/setting/plan/edit/${_id}`)}>
                    <TextStyle>{name}</TextStyle>
                  </Link>
                }
                {isStripe && <TextStyle>{name}</TextStyle>}
              </Heading>
              {/* </div>/ */}
            </TextStyle>
            <Caption>
              <div className="ellipsis">
                <TextStyle variation="subdued">{code}</TextStyle>
                <br />
              </div>
            </Caption>
          </Stack.Item>
          {!isStripe &&(<Stack.Item>
            <Button onClick={() => history.push(`/setting/plan/edit/${_id}`)}>{cms("section.button.edit")}</Button>
          </Stack.Item>)}
        </Stack>
      </ResourceItem>
    </>
  );
};

SellerPlanList.defaultProps = {
  item: {},
  keyIndex: "",
};

SellerPlanList.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
};

export default withErrorBoundary(SellerPlanList);
