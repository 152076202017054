import React from "react";
import { Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";

const Brand = (props) => {
  const { brandName, brands, handleChange, handleValidation, errorMessage, cms } = props;
  return (
    <Select
      id="brandName"
      label={`${cms("label.brandName")}*`}
      value={brandName}
      options={brands}
      placeholder={cms("label.brandName")}
      onChange={(value) => handleChange(constant.BRAND_NAME, value)}
      onBlur={() => handleValidation(constant.BRAND_NAME, brandName)}
      error={errorMessage && errorMessage.brandName}
    />
  );
};

Brand.propTypes = {
  brandName: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  cms: PropTypes.func.isRequired,
  errorMessage: PropTypes.objectOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

export default Brand;
