import { gql } from "apollo-boost";

const CREDIT_NOTE_LIST = gql`
  query getCreditNoteList($input: GetListInput) {
    getCreditNoteList(input: $input) {
      data {
        count
        creditNoteList {
          _id
          createdAt
          creditNoteAmount
          creditNoteBalance
          moneyFormat
          name
          orderId
          orderNumber
          shopifyLineItemId
          title
          updatedAt
          vendor
          vendorId
        }
      }
      status
      error
    }
  }
`;

export default CREDIT_NOTE_LIST;
