// import packages
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

import { withErrorBoundary, withFeature } from "lib/hoc";

import { Banner, SkeletonAnnotated } from "lib/components";

import { baseHelper } from "lib/helpers";

import constant from "lib/constant/constant";
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";

import Physical from "./subFeature/physical";
import Price from "./subFeature/price";

import ManageTagTypes from "./subFeature/tagType";

const { gql } = constant;
const OperatorProductSetting = () => {
  const { cms } = useContext(PrivateContext);
  const { data: productSettingsData, loading, refetch } = useQuery(GET_PRODUCT_SETTING);
  const [radioButtonValue, setRadioButtonValue] = useState(false);
  const [lastUpdated, SetLastUpdated] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [selected, setSelected] = useState("");
  const [rate, setRate] = useState("");
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isHideType, setIsHideType] = useState(false);
  const [isHideTag, setIsHideTag] = useState(false);
  const [isCatalogHidden, setIsCatalogHidden] = useState(true);
  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (!responseData) {
      return;
    }
    const {
      productApproval = "",
      updatedAt = "",
      isPriceTaxInclusive = false,
      rate: amount,
      isCatalogHidden: catalogHidden,
      isHideTagAndType: hideTagAndType,
      isHideTag: hideTag,
      isHideType: hideType,
    } = responseData;
    setRadioButtonValue(productApproval);
    SetLastUpdated(updatedAt);
    setIsHideTagAndType(hideTagAndType);
    setIsHideTag(hideTag);
    setIsHideType(hideType);
    setIsCatalogHidden(catalogHidden);
    if (!isPriceTaxInclusive) {
      setSelected("false");
      return;
    }
    setSelected("true");
    setRate(amount || 0);
  }, [productSettingsData]);

  if (loading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Physical lastUpdated={lastUpdated} />
      <br />
      <Price
        lastUpdated={lastUpdated}
        setSelected={setSelected}
        setRate={setRate}
        selected={selected}
        rate={rate}
        cms={cms}
        // learnMore={learnMore}
        radioButtonValue={radioButtonValue}
        setBannerStatus={setBannerStatus}
        isHideTagAndType={isHideTagAndType}
        isHideTag={isHideTag}
        isHideType={isHideType}
      />
      <br />
      <ManageTagTypes
        lastUpdated={lastUpdated}
        selected={selected}
        rate={rate}
        radioButtonValue={radioButtonValue}
        isCatalogHidden={isCatalogHidden}
        isHideTagAndType={isHideTagAndType}
        isHideType={isHideType}
        isHideTag={isHideTag}
        setIsCatalogHidden={setIsCatalogHidden}
        setIsHideTagAndType={setIsHideTagAndType}
        setIsHideTag={setIsHideTag}
        setIsHideType={setIsHideType}
        setBannerStatus={setBannerStatus}
        refetch={refetch}
      />
    </>
  );
};
export default withFeature(withErrorBoundary(OperatorProductSetting), { feature: constant.PRODUCT_SETTING });
