import { gql } from "apollo-boost";

const UPDATE_TAX_RATE_SETTING = gql`
  mutation updateTaxRateSetting($input: UpdateTaxRateSetting) {
    updateTaxRateSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_TAX_RATE_SETTING;
