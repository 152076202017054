import constant from "lib/constant/constant";
import config from "configuration";
import Talk from "talkjs";

const { userKey } = constant;
class ChatHelper {
  constructor() {
    this.sellerRole = userKey.operator;
    this.vendorRole = userKey.provider;
  }

  hide = () => {
    const hideChat = () => {
      // window.tidioChatApi.hide();
      window.tidioChatApi.display(false);
    };
    if (!window.tidioChatApi) {
      document.addEventListener(constant.TIDIO_CHAT, hideChat);
    } else {
      window.tidioChatApi.on(constant.READY, hideChat);
    }
    if (window.Beacon) {
      window.Beacon('destroy');
    }
    // window.customerly("hide");
    // window.Intercom && window.Intercom("shutdown");
  };

  show = () => {
    const showChat = () => {
      // window.tidioChatApi.show();
      window.tidioChatApi.display(true);
    };

    if (!window.tidioChatApi) {
      document.addEventListener(constant.TIDIO_CHAT, showChat);
    } else {
      window.tidioChatApi.on(constant.READY, showChat);
    }

    if (window.Beacon && config.beaconLiveChatAPIKey) {
      window.Beacon('init', config.beaconLiveChatAPIKey);
    }
    // if (!isIntercomVisible) {
    //   return;
    // }
    // window.customerly("show");
    // window.Intercom("boot", {
    //   app_id: window.intercomSettings.app_id,
    //   name,
    //   email,
    // });
  };

  setUserDetails = ({
    city,
    country,
    name,
    email,
    id,
    role,
    plan = {},
    sellerName = "",
    sellerShop = "",
    shop = "",
  }) => {
    if (config.muteChatIntegration) {
      return;
    }

    // if (!isIntercomVisible) {
    //   return
    // }
    // window.Intercom("update", {
    //   name,
    //   email,
    // });
    const isVendor = role === this.vendorRole;
    const isSeller = role === this.sellerRole;

    const attributes = {
      role,
    };

    if (isVendor) {
      attributes.sellerPlan = plan.name || "";
      attributes.sellerPrice = plan.price || "";
      attributes.sellerName = sellerName;
      attributes.sellerShop = sellerShop;
    } else if (isSeller) {
      attributes.plan = plan.name || "";
      attributes.price = plan.price || "";
      attributes.shop = shop || "";
    }

    const onChatApiReady = () => {
      window.tidioChatApi.setVisitorData({
        distinct_id: id, // Unique user ID in your system
        email, // User email
        name, // Visitor name
        city, // City
        country, // Country
      });

      window.tidioChatApi.setContactProperties(attributes);
    };

    if (!window.tidioChatApi) {
      document.addEventListener(constant.TIDIO_CHAT, onChatApiReady);
    } else {
      window.tidioChatApi.on(constant.READY, onChatApiReady);
    }

    if (config.beaconLiveChatAPIKey && window.Beacon) {
      window.Beacon('identify', {
        name,
        email,
        jobTitle: role,
      });
    }
  };

  // TalkJS

  getTalkJSUser = (id, name, email, role, profileImage, custom = {}) => {
    const user = {
      id,
      name,
      email,
      role,
      custom,
    };

    if (profileImage) {
      user.photoUrl = profileImage;
    }

    return new Talk.User(user);
  };

  createTalkJSConversion = (talkCurrentUser, other) => {
    if (window.talkSession) {
      const conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(talkCurrentUser, other));
      conversation.setParticipant(talkCurrentUser);
      conversation.setParticipant(other);
      conversation.setAttributes({ custom: { role: userKey.operator } });
      return conversation;
    }
    return null;
  };

  createTalkJSGroupConversion = ({ vendor, customer, seller, subject }) => {
    if (window.talkSession) {
      const conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(vendor, customer));

      conversation.setParticipant(vendor);
      conversation.setParticipant(customer);
      conversation.setParticipant(seller);
      conversation.setAttributes({
        subject,
      });

      return conversation;
    }
    return null;
  };

  createTalkJSPopup = (conversation) => {
    if (window.talkSession) {
      const popup = window.talkSession.createPopup(conversation, { keepOpen: false });
      popup.mount({ show: false });
      return popup;
    }
    return null;
  };

  createTalkJSSession = (me) => {
    window.talkSession = new Talk.Session({
      appId: "twLyn6PC",
      me,
    });
  };

  createTalkJSInbox = () => window.talkSession.createInbox();

  destroyTalkJSSession = () => window.talkSession && window.talkSession.destroy();
}

export default new ChatHelper();
