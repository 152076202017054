import React, { useContext } from "react";
import { PageActions } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

const ActionButton = (props) => {
  const { history } = useContext(PrivateContext);
  const { pageType } = props;
  if (pageType && pageType === "view") {
    return null;
  }
  const {
    destructive = false,
    disabled = false,
    hide,
    id,
    isLoading = false,
    onSubmit = undefined,
    saveButtonValue = "Submit",
    size = "default",
    submit,
  } = props;

  const primaryAction =
    hide === true
      ? false
      : { content: saveButtonValue, submit, loading: isLoading, size, disabled, onClick: onSubmit, id, destructive };

  return (
    <PageActions
      primaryAction={primaryAction}
      secondaryActions={[
        {
          id: "cancel",
          content: "Cancel",
          onAction: () => history.push("/"),
        },
      ]}
    />
  );
};

ActionButton.propTypes = {
  destructive: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  pageType: PropTypes.string,
  saveButtonValue: PropTypes.string,
  size: PropTypes.string,
  submit: PropTypes.bool,
};

ActionButton.defaultProps = {
  destructive: false,
  disabled: false,
  hide: false,
  isLoading: false,
  onSubmit: () => {},
  pageType: "",
  saveButtonValue: "Submit",
  size: "default",
  submit: true,
};

export default ActionButton;
