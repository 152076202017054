import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_SELLER_BRANDING } from "routes/private/apollo/queries";
import { baseHelper } from "lib/helpers";
import { DisplayText } from "@shopify/polaris";
import StyledChart, { ReportTitle } from "./chartPDFStyle";

const Header = (props) => {
  const { setLogo, header } = props;
  const [bandData, setBrandData] = useState({});
  const today = new Date().toLocaleDateString();
  const { data: brandingData, loading: isLoading } = useQuery(GET_SELLER_BRANDING);
  const responseData = baseHelper.getResponseData(brandingData, "getSellerBranding");

  useEffect(() => {
    if (!isLoading && responseData) {
      setBrandData(responseData);
      setLogo(!!responseData.logo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, responseData]);

  return (
    (header && (
      <div>
        <StyledChart>
          {bandData.logo ? (
            <img src={bandData.logo} className="brand-logo" id="tableBanner" alt="brandLogo" />
          ) : (
            <div className="brand-name">
              <DisplayText size="large">{baseHelper.ucFirst(bandData.brandName)}</DisplayText>
            </div>
          )}
          <DisplayText size="small">{`Export Date :${today}`}</DisplayText>
        </StyledChart>
        <ReportTitle className="report">
          <DisplayText size="small">Analytics Report</DisplayText>
        </ReportTitle>
      </div>
    )) ||
    null
  );
};

export default Header;
