import React, { useContext } from "react";
import constant from "lib/constant/constant";
import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { OperatorProductExportSetting } from "../../setup/modules/operator/features";
import { ProviderProductExportSetting } from "../../setup/modules/provider/features";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { operator, provider } = userKey;
const ProductExportSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }

    if (role === operator) {
      return <OperatorProductExportSetting />;
    }
    if (role === provider) {
      return <ProviderProductExportSetting />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const ProductExportSettingComponent = getComponent(currentUserRole);
  return ProductExportSettingComponent;
};
export default withErrorBoundary(ProductExportSetting);
