import { gql } from "apollo-boost";

const GET_SENDABOX_CARRIER = gql`
  query {
    getSendaboxCarrier {
      data {
        id
        name
        label
        service
      }
      status
      error
    }
  }
`;

export default GET_SENDABOX_CARRIER;
