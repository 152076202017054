import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Card,
  DatePicker,
  FormLayout,
  Popover,
  // PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import mutations
import { ADD_PRODUCT_INVENTORY } from "app/product/apollo/mutations";

//  import queries
import { GET_PRODUCT } from "app/product/apollo/queries";
import { GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";
import moment from "moment";
// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

import editData from "app/product/modules/generic/config/editData";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";
// import { GET_GEOLOCATION_SETTING } from "app/setup/apollo";
// import { TagMapTable } from "app/setup/modules/operator/features/productTagSetting/style";

// import Table from "./components/table";

const Inventory = (props) => {
  const { isVariant } = props;

  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { cms, currentUser, match } = useContext(PrivateContext);
  const { gql, SHOPIFY = "", INVENTORY_MANAGEMENT, CRITICAL } = constant;
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [skuData, setSkuData] = useState("");
  // const [locationData, setLocationData] = useState("");
  const [quantityData, setQuantityData] = useState("");
  const [barcodeData, setBarcodeData] = useState("");
  const [inventoryData, setInventoryData] = useState("");
  const [productFormSetting, setProductFormSetting] = useState();
  const [metaFieldData, setMetafielData] = useState([]);
  // const [geoLocation, setGeoLocation] = useState([]);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [value, setValue] = useState({});
  const [disabledButton, setDisabledButton] = useState(true);
  const isVendor = baseHelper.isVendor(currentUser);
  // const [duration, setDuration] = useState();
  const [activeCalendar, setActiveCalendar] = useState(false);
  // const [dateValue, setDateValue] = useState("");
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [isProductList, setIsProductList] = useState(false);

  const [dates, setDates] = useState([""]);

  setDescription(cms("label.editInventory"));
  setTitle(cms("label.title.inventory"));
  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.SKU];
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { input: { id: match.params.id } },
  });
  const { loading: loadingProductForm, data: dataProductForm } = useQuery(GET_PRODUCT_FORM);
  const { loading: loadingProductListing, data: dataProductList } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        // setLocationData(productLabel.location);
        setSkuData(productLabel.sku);
        setBarcodeData(productLabel.barcode);
        setInventoryData(productLabel.inventory);
        setQuantityData(productLabel.quantity);
        setProductFormSetting(productLabel);
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);
  useEffect(() => {
    if (dataProductList) {
      const productistResponse = baseHelper.getResponseData(dataProductList, "getProductListingSetting");
      if (productistResponse) {
        const { isEnabled = {} } = productistResponse;
        if (isEnabled) {
          setIsProductList(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductList]);
  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  const handleChanged = (index, valueData) => {
    const updateData = dates || [];
    updateData[index] = valueData;
    setDates([...updateData]);
  };

  const acceptOnlyValidInput = (val) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && value.quantity) || 0;
  };
  const acceptOnlyValidInputListing = (val) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && value.listingQuantity) || 0;
  };
  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const primaryAction = {
    content: cms("label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("label.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const [getGeolocationSetting, { data: geoLocationData }] = useLazyQuery(GET_GEOLOCATION_SETTING);


  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT) || {};
      setValue(productResponse);
      // const { metafield = [] } = productResponse;
      // if (metafield && metafield.length) {
      //   const getMetaData = metafield.map((meta) => {
      //     const val = baseHelper.isValidJSON(meta.value) ? JSON.parse(meta.value) : meta.value;
      //     return {
      //       location: meta.key,
      //       price: val.price,
      //       comparePrice: val.comparePrice,
      //       quantity: val.quantity,
      //     };
      //   });

      //   setMetafielData(getMetaData);
      // }
      // getGeolocationSetting();
    }
  }, [gql.GET_PRODUCT, productData]); // getGeolocationSetting

  // useEffect(() => {
  //   if (geoLocationData) {
  //     const geoLocationDataResponse = baseHelper.getResponseData(geoLocationData, gql.GET_GEOLOCATION_SETTING);
  //     const { locations } = geoLocationDataResponse;
  //     const cities = metaFieldData.map((item) => item && item.location) || [];
  //     const loc = locations.map((location) => ({
  //       value: location,
  //       disabled: false || cities.includes(location),
  //       label: location
  //         .toLowerCase()
  //         .split(" ")
  //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" "),
  //     }));
  //     setGeoLocation(loc);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [geoLocationData]);

  // const handleLocationChange = (fieldValue) => {
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.label,
  //     value: geo.value,
  //     disabled: geo.disabled || geo.value === fieldValue || false,
  //   }));
  //   setGeoLocation(loc);
  //   const locationData = [...metaFieldData];
  //   locationData.push({
  //     location: fieldValue || "",
  //     price: value.price || 0,
  //     comparePrice: value.comparePrice || 0,
  //     quantity: value.quantity || 0,
  //   });
  //   setMetafielData(locationData);
  // };

  // const handleTableChange = (newValue, id, field) => {
  //   const metaData = [...metaFieldData];
  //   metaData[id][field] = newValue;
  //   setMetafielData(metaData);
  //   setDisabledButton(false);
  // };

  // const handleDelete = (index) => {
  //   const metaData = [...metaFieldData];
  //   const deletedCities = metaData.splice(index, 1);
  //   const { location } = deletedCities[0];
  //   const loc = geoLocation.map((geo) => ({
  //     label: geo.label,
  //     value: geo.value,
  //     disabled: geo.value === location ? false : geo.disabled,
  //   }));
  //   setGeoLocation(loc);
  //   setMetafielData(metaData);
  //   setDisabledButton(false);
  // };

  const [addProductInventory, { loading: addInventoryLoading }] = useMutation(ADD_PRODUCT_INVENTORY);

  if (productLoading || loadingProductForm || loadingProductListing) {
    return <SkeletonCard />;
  }

  const disableDate = (newDate) => {
    if (newDate.getDate() === new Date().getDate()) {
      newDate.setDate(new Date().getDate() - 1);
    } else {
      newDate.setDate(new Date());
    }
    return newDate;
  };

  const handleDurationChange = (fieldName, val) => {
    const updateData = value[fieldName] || "";
    const newVal = val ? val.split(":") : [];
    const newDate = updateData ? new Date(updateData) : new Date();
    newDate.setHours(newVal[0]);
    newDate.setMinutes(newVal[1]);
    // updateData = moment(newDate).format("YYYY-MM-DD HH:mm");
    setDates(newDate);
    setValue({
      ...value,
      [fieldName]: newDate,
    });
    setDisabledButton(false);
  };

  const handleDateChange = (fieldName, val) => {
    let updateData = value[fieldName] || "";
    val.setHours(0);
    val.setMinutes(0);
    updateData = val ? moment(val).format("YYYY-MM-DD") : "";
    setDates([...updateData]);
    setValue({
      ...value,
      [fieldName]: updateData,
    });
    setDisabledButton(false);
  };

  const onFormSubmit = async () => {
    const { _id: id = "" } = value;
    const formValues = {
      productId: id,
      sku: value.sku,
      barcode: value.barcode,
      inventoryManagement: value.inventoryManagement || "",
      compaign: value.compaign,
    };
    formValues.quantity = parseInt(value.quantity, 10);
    formValues.listingQuantity = parseInt(value.listingQuantity, 10);

    // const metaData = metaFieldData.map((meta) => {
    //   return {
    //     key: meta.location,
    //     namespace: constant.LOCATION,
    //     value: JSON.stringify({
    //       price: parseFloat(meta.price, 10),
    //       comparePrice: parseFloat(meta.comparePrice, 10),
    //       quantity: parseInt(meta.quantity, 10),
    //     }),
    //   };
    // });
    // formValues.metafield = metaData;
    const dataToCheck = {
      quantity: formValues.quantity,
      sku: value.sku,
      barcode: value.barcode,
      inventory: value.inventoryManagement,
      // location: (value && value.metafield && value.metafield.length) || metaFieldData.length,
    };

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        if (!dataToCheck.inventory && key === 'quantity') {
          return;
        }
        if (key === 'inventory') {
          return;
        }
        const valueData = dataToCheck[key];
        if (!valueData && constant.INVENTORY_KEY.includes(key)) {
          errorField = productFormSetting[key].label || key;
        }
      });
    }

    if (isVendor && errorField) {
      const banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }

    await addProductInventory({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.ADD_PRODUCT_INVENTORY);
        const responseData = baseHelper.getResponseData(res.data, gql.ADD_PRODUCT_INVENTORY);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          if (responseData) {
            setLoader(false);
            setMessage(cms("message.success.inventory"));
            setBanner({ isOpen: false, status: "", title: "" });
            setTimeout(() => {
              handleTabChange(2);
            }, 1500);
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const required = (labelValue) => {
    if (labelValue && labelValue.isRequired) {
      return "*";
    }
    return "";
  };

  const label = (val, defaultCms) => {
    if (val && val.label) {
      return val.label;
    }
    return defaultCms;
  };

  return (
    <>
      <Card
        title={[
          cms("label.organisation"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productOrganisation"
        // actions={[
        //   {
        //     content: "Learn more",
        //     onAction: () => {
        //       learnMore("productOrganisation", cms("label.organisation"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.caption.organization")}</p>
          <br />
          <FormLayout>
            {!isVariant && [
              <FormLayout.Group>
                <TextField
                  id="sku"
                  label={isVendor ? `${label(skuData, cms("label.sku"))} ${required(skuData)}` : cms("label.sku")}
                  value={value.sku || ""}
                  onChange={(val) => {
                    handleChange(constant.SKU, val);
                    setDisabledButton(false);
                  }}
                  maxLength={16}
                />
                <TextField
                  id="barcode"
                  label={
                    isVendor
                      ? `${label(barcodeData, cms("label.barcode"))} ${required(barcodeData)}`
                      : cms("label.barcode")
                  }
                  value={value.barcode || ""}
                  onChange={(val) => {
                    handleChange(constant.BARCODE, val);
                    setDisabledButton(false);
                  }}
                />
              </FormLayout.Group>,
              <FormLayout.Group>
                <Select
                  id="policySelecTabletion"
                  label={
                    isVendor
                      ? `${label(inventoryData, cms("label.inventory"))} ${required(inventoryData)}`
                      : cms("label.inventory")
                  }
                  options={editData(cms).policyOption}
                  value={value.inventoryManagement || ""}
                  onChange={(val) => {
                    handleChange(INVENTORY_MANAGEMENT, val);
                    setDisabledButton(false);
                  }}
                />
                {value && value.inventoryManagement === SHOPIFY ? (
                  <TextField
                    id="inventoryQuantity"
                    disabled={value.inventoryManagement !== SHOPIFY}
                    label={
                      isVendor
                        ? `${label(quantityData, cms("label.quantity"))} ${required(quantityData)}`
                        : cms("label.quantity")
                    }
                    value={(value.quantity && value.quantity.toString()) || ""}
                    min={0}
                    onChange={(val) => {
                      handleChange(constant.QUANTITY, acceptOnlyValidInput(val));
                      setDisabledButton(false);
                    }}
                  />
                ) : (
                  ""
                )}
              </FormLayout.Group>,
            ]}

            {isProductList && (
              <FormLayout.Group>
                <Stack distribution="fillEvenly">
                  <Stack.Item>
                    <Popover
                      active={activeCalendar}
                      activator={
                        <TextField
                          // value={item}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          value={value.compaign ? moment(value.compaign).format("YYYY-MM-DD") : ""}
                          label="Campaign End Date"
                          // disabled={isCompaign}
                          // eslint-disable-next-line no-shadow
                          // onChange={(value) => handelChange(index, value.start)}
                          placeholder="YYYY-MM-DD"
                          onFocus={() => setActiveCalendar(true)}
                        />
                      }
                      onClose={() => setActiveCalendar(false)}
                    >
                      {activeCalendar && (
                        <>
                          <DatePicker
                            month={month}
                            year={year}
                            // eslint-disable-next-line no-shadow
                            onChange={(value) => [
                              handleDateChange("compaign", value.start),
                              setActiveCalendar(false),
                            ]}
                            onMonthChange={handleMonthChange}
                            selected={selectedDates}
                            allowRange={false}
                            disableDatesBefore={disableDate(new Date())}
                          />
                        </>
                      )}
                    </Popover>
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="select-time">
                      <Select
                        label="Select time"
                        id="selectDuration"
                        // disabled={!value.compaign}
                        placeholder={cms("placeholder.time")}
                        options={baseHelper.getTime() || []}
                        value={value.compaign ? moment(value.compaign).format("HH:mm") : ""}
                        onChange={(val) => handleDurationChange("compaign", val)}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
                <br />
                <TextField
                  id="inventoryListQuantity"
                  label="Campaign Sales Goal Minimum Threshold Units"
                  helpText="This is the minimum number of units that must be sold prior to the end date for the goal for pre-orders to be accepted and the product to proceed to production."
                  placeholder="Quantity"
                  value={(value && value.listingQuantity && value.listingQuantity.toString()) || ""}
                  min={0}
                  // disabled={isCompaign}
                  maxLength={10}
                  onChange={(val) => {
                    handleChange("listingQuantity", acceptOnlyValidInputListing(val));
                    setDisabledButton(false);
                  }}
                />
              </FormLayout.Group>
            )}
            {/* {!!(geoLocation && geoLocation.length) && (
              <FormLayout.Group>
                <Select
                  label={isVendor ? `${label(locationData, "Location")} ${required(locationData)}` : "Location"}
                  options={geoLocation}
                  placeholder="Select locations"
                  // value={value.inventoryManagement || ""}
                  onChange={(val) => {
                    handleLocationChange(val);
                    setDisabledButton(false);
                  }}
                />
              </FormLayout.Group>
            )}
            {!!(geoLocation && geoLocation.length && metaFieldData && metaFieldData.length) && (
              <TagMapTable className="tag-map-table">
                <Table
                  cms={cms}
                  currentUser={currentUser}
                  data={metaFieldData}
                  setData={setMetafielData}
                  value={value}
                  handleChange={handleTableChange}
                  handleDelete={handleDelete}
                />
              </TagMapTable>
            )} */}

            <div className="toast">
              <Toast message={message} />
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button onClick={() => handleTabChange(0)}>{cms("button.previous")}</Button>
        </Stack.Item>
        {value && value.status && value.status.toLowerCase() === constant.NEW && (
          <Stack.Item>
            <Button onClick={() => handleTabChange(2)}>{cms("button.skip")}</Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={disabledButton}
            loading={loader || addInventoryLoading}
          >
            {cms("button.save")}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content: cms("button.save"),
          onAction: () => onFormSubmit(),
          loading: loader || addInventoryLoading,
          disabled: disabledButton,
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => {
              handleTabChange(0);
            },
          },
        ]}
      /> */}
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

export default Inventory;
