import * as yup from "yup";

const shopSubDomainSchema = (errorMessage) => {
  return yup.object().shape({
    shopSubDomain: yup.string().required(errorMessage).nullable(),
  });
};

const apiAccessTokenSchema = (errorMessage) => {
  return yup.object().shape({
    apiAccessToken: yup.string().required(errorMessage).nullable(),
  });
};

const apiAppKeySchema = (errorMessage) => {
  return yup.object().shape({
    apiKey: yup.string().required(errorMessage).nullable(),
  });
};

const apiAppSecretKeySchema = (errorMessage) => {
  return yup.object().shape({
    apiSecretKey: yup.string().required(errorMessage).nullable(),
  });
};

export { shopSubDomainSchema, apiAccessTokenSchema, apiAppKeySchema, apiAppSecretKeySchema };
