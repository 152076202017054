import React from "react";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

// import props
import { lineGraphProp } from "app/reporting/modules/operator/props";

const LineGraph = (props) => {
  const { data, xDataKey, dataKey1, dataKey2, width = 400, height = 250 } = props;
  return (
    <LineChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xDataKey} />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey={dataKey1} stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey={dataKey2} stroke="#82ca9d" />
    </LineChart>
  );
};

LineGraph.propTypes = lineGraphProp.type;

export default LineGraph;
