import React, { useContext } from "react";
import { Card, Stack, Caption } from "@shopify/polaris";
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { fullfillmentStatusProp } from "app/orders/modules/admin/features/view/propsType";

const FulfillmentStatus = (props) => {
  const { lineData, data } = props;
  const { userData, cms } = useContext(PrivateContext);
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const isTaxIncluded = !!data.taxesIncluded;
  const isSeller = baseHelper.isSeller(currentUserData);
  const totals = baseHelper.getOrderPrice(lineData, data, isSeller);
  const { isUpdatedOrderLine } = totals;
  const items = `${lineData.length > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${
    lineData.length
  } `;
  const markUpItems = `${totals.markUpItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${
    totals.markUpItems
  }`;
  const { code: discountLineCode = "" /* , amount */ } =
    (data.discountCodes && data.discountCodes.length && data.discountCodes[0]) || {};

  const getDynamicDiscount = (orderLineItem = {}) => {
    let discountValue = 0;
    (orderLineItem.discountAllocations || []).forEach((item) => {
      discountValue = item.amount;
    });
    return discountValue;
  };

  const dynamicDiscountValue = lineData.map((item) => getDynamicDiscount(item));
  let discountAmount = 0;
  const totalDynamicDiscount = dynamicDiscountValue.filter((item) => {
    discountAmount += parseFloat(item);
  });
  const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;
  const vendorsSum = [];
  lineData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const lineDataItems = lineData.length;
  return (
    <div className="totalSection">
      <Card>
        <Card.Section subdued>
          <Card.Subsection>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>{cms("label.subTotal")}</Stack.Item>
                <Stack.Item>{items}</Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}`}
                  {isUpdatedOrderLine ? baseHelper.getPrice(totals.price) : data.totalLineItemsPrice}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              {isSeller && isUpdatedOrderLine && !!totals.markUp && (
                <Stack wrap={false}>
                  <Stack.Item fill>{cms("label.markUpTotal")}</Stack.Item>
                  <Stack.Item>{markUpItems}</Stack.Item>
                  <Stack.Item>
                    {`${moneyFormat}`}
                    {baseHelper.getPrice(totals.markUp)}
                  </Stack.Item>
                </Stack>
              )}
              <Stack wrap={false}>
                <Stack.Item fill>{cms("label.shipping")}</Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}`}
                  {baseHelper.getPrice(totals.shippingAmount)}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {cms("label.discount")}
                  {discountLineCode ? `(${discountLineCode})` : ""}
                </Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}-`}
                  {baseHelper.getPrice(discountAmount) ||
                    (isUpdatedOrderLine &&
                      ((isSeller && baseHelper.getPrice(totals.sellerDiscount)) ||
                        baseHelper.getPrice(totals.discount))) ||
                    (lineData.totalDiscount && baseHelper.getPrice(lineData.totalDiscount)) ||
                    constant.value.FLOAT_ZERO}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {cms("label.salesTax")}
                  {isTaxIncluded && ` ${cms("label.included")}`}
                </Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}`}
                  {!isSeller && isUpdatedOrderLine ? baseHelper.getPrice(totals.tax) : data.totalTax}
                </Stack.Item>
              </Stack>
            </Card.Section>
          </Card.Subsection>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack wrap={false}>
              <Stack.Item fill>
                <b>{cms("label.total")}</b>
              </Stack.Item>
              <Stack.Item>
                <Caption>
                  {" "}
                  (
                  {lineDataItems > 1
                    ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
                    : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                  )
                </Caption>
              </Stack.Item>
              <Stack.Item>
                <b>
                  {`${moneyFormat}`}
                  {isUpdatedOrderLine ? baseHelper.getPrice(totals.totalPrice) : data.totalPrice}
                </b>
              </Stack.Item>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

FulfillmentStatus.propTypes = fullfillmentStatusProp.type;
export default FulfillmentStatus;
