import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout, DisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { GET_SELLER_BRANDING } from "app/orderLake/apollo/queries";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";
import StyledSlip from "asset/styles/paymentSlip";

// will be used to add autotable methods to jspdf, doc.autotable, won't work without including jspdf-autotable
require("jspdf-autotable");

const OrderSlipView = (props) => {
  const { getSellerAddress, orderData, orderLineItems, loading } = props;
  const { billing, shipping, cost: { shippingIncTax = 0 } = {} } = orderData || {};
  const { email, phone } = billing || {};
  const { cms, currentUser } = useContext(PrivateContext);
  const { introductorySeller: sellerId, logo: brandLogo, moneyFormat, shop } = currentUser;
  const currentUserAddress = baseHelper.getFullAddress(currentUser);
  const [fulfillmentType, setFulfillmentType] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [logo, setLogo] = useState(brandLogo);
  const [vendorShippingAmount, setVendorShippingAmount] = useState(0);
  const shippingAmount = shippingIncTax || 0;

  const isVendor = baseHelper.isVendor(currentUser);

  const { data: sellerBrandName } = useQuery(GET_SELLER_BRANDING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(sellerBrandName, constant.gql.GET_SELLER_BRANDING);
    if (!brandLogo && responseData && responseData.logo) {
      setLogo(responseData.logo);
    } else {
      setLogo(brandLogo);
    }
    if (responseData) {
      setBrandName(responseData.brandName);
    }
  }, [sellerBrandName]);

  const [userInput] = useState({
    product: {
      label: "",
      isHide: false,
    },
    quantity: {
      label: "",
      isHide: false,
    },
    price: {
      label: "",
      isHide: false,
    },
    sku: {
      label: "",
      isHide: false,
    },
    discount: {
      label: "",
      isHide: false,
    },
    total: {
      label: "",
      isHide: false,
    },
    totalDiscount: {
      label: "",
      isHide: false,
    },
    tax: {
      label: "",
      isHide: true,
    },
    totalTax: {
      label: "",
      isHide: false,
    },
    shipping: {
      label: "",
      isHide: false,
    },
    totalShipping: {
      label: "",
      isHide: false,
    },
    subTotal: {
      label: "",
      isHide: false,
    },
    purchasedFrom: {
      label: "",
      isHide: false,
    },
    deliveredTo: {
      label: "",
      isHide: false,
    },
    orderFulfilledBy: {
      label: "",
      isHide: false,
    },
  });

  useEffect(() => {
    if (fulfillmentType.includes(constant.CONSIGNMENT)) {
      if (isVendor) {
        getSellerAddress({ variables: { input: { addressId, sellerId } } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType]);

  const getAddress = (dataShipping) => {
    const {
      firstName = "",
      lastName = "",
      addressLineOne = "",
      addressLineTwo = "",
      city = "",
      country = "",
      postcode = "",
    } = dataShipping;
    return (
      <>
        <b>{`${firstName || ""} ${lastName || ""}`}</b>
        {firstName || lastName ? <br /> : ""}
        {addressLineOne || ""}
        {addressLineOne ? <br /> : ""}
        {addressLineTwo || ""}
        {addressLineTwo ? <br /> : ""}
        {city || ""}
        {city ? <br /> : ""}
        {postcode || ""}
        {postcode ? <br /> : ""}
        {country || ""}
        {country ? <br /> : ""}
      </>
    );
  };

  useEffect(() => {
    const type = [];
    let address = "";
    let vendorShipping = 0;
    orderLineItems.forEach((item) => {
      type.push(item.fulfillmentType);
      address = item.addressId;
      vendorShipping += parseFloat(item.shippingAmount) || 0;
    });
    setFulfillmentType(type);
    setAddressId(address);
    setVendorShippingAmount(vendorShipping);
  }, [orderLineItems]);

  const subTotalAmount = orderLineItems.map((item) => {
    let total = 0;
    const totalPrice = parseFloat(item.quantity) * parseFloat(item.price || item.basePrice);
    total = parseFloat(total) + parseFloat(totalPrice) + parseFloat(item.shippingAmount || 0);
    return total;
  });

  const subTotalData = subTotalAmount.reduce((total, item) => {
    return total + item;
  }, 0);

  // const pdfToHTML = () => {
  //   const input = document.getElementById("divToPrint");
  //   html2canvas(input, { scale: 3 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new JsPDF("P");

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, 100);
  //     pdf.save(`${orderData.number}.pdf`);
  //   });
  // };

  const vendorTotalValue = orderLineItems.map(({ baseTotal }) => {
    return parseFloat(baseTotal).toFixed(2);
  });
  let vendorTotal = 0;
  vendorTotalValue.forEach((val) => {
    vendorTotal += parseFloat(val);
  });

  if (loading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <Layout>
        <Layout.Section>
          {/* <div className="pdfToHtml">
            <Button primary onClick={pdfToHTML}>
              {cms("button.print")}
            </Button>
          </div> */}
          <StyledSlip id="divToPrint" className="container">
            <div className="invoice-company text-inverse ">
              {(logo && <img src={logo} className="brand-logo" id="tableBanner" alt="" />) || (
                <DisplayText size="medium">{brandName || shop}</DisplayText>
              )}
              <span className="pull-right hidden-print">
                <a href="" onClick={window.print} className="btn-white">
                  <i className="fa fa-print t-plus-1 fa-fw fa-lg" style={{ paddingRight: "20px" }} />
                  {cms("button.print")}
                </a>
              </span>
            </div>

            <div className="invoice-header">
              {shipping && (
                <div className="invoice-from">
                  <small className="title text-uppercase">{cms("label.deliver")}</small>
                  <address>{getAddress(shipping || {})}</address>
                </div>
              )}
              {billing && (
                <div className="invoice-to">
                  <small className="title text-uppercase">{cms("label.customerContact")}</small>
                  <address>{getAddress(billing || {})}</address>
                  {email && cms("label.customerEmail")}
                  {email}
                  {email ? <br /> : ""}
                  {phone && cms("label.customerPhone")}
                  {phone}
                  {phone ? <br /> : ""}
                </div>
              )}
              <div className="invoice-date">
                <small className="title text-uppercase">{cms("label.packingSlip")}</small>
                <div className="date text-inverse">{`${baseHelper.formatDate(orderLineItems.createdAt, false)}`}</div>
                <div className="invoice-detail">
                  <strong>{` ${baseHelper.formatDate(new Date(), false)}`}</strong>
                  <br />
                  {cms("label.orderId")}
                  {orderData && `: #${orderData.number || orderData.storeOrderId}`}
                </div>
              </div>
            </div>

            <div className="invoice-content">
              <div className="table-responsive">
                <table className="table table-invoice">
                  <thead>
                    <tr>
                      {userInput.product && !userInput.product.isHide ? (
                        <th>{userInput.product.label || cms("label.product")}</th>
                      ) : null}
                      {userInput.sku && !userInput.sku.isHide ? (
                        <th>{userInput.sku.label || cms("label.sku")}</th>
                      ) : null}
                      {userInput.quantity && !userInput.quantity.isHide ? (
                        <th>{userInput.quantity.label || cms("label.qty")}</th>
                      ) : null}
                      {userInput.price && !userInput.price.isHide ? (
                        <th>{userInput.price.label || cms("label.price")}</th>
                      ) : null}
                      {userInput.tax && !userInput.tax.isHide ? (
                        <th>{userInput.tax.label || cms("label.tax")}</th>
                      ) : null}
                      {userInput.subTotal && !userInput.subTotal.isHide ? (
                        <th>{userInput.subTotal.label || cms("label.subTotal")}</th>
                      ) : null}
                    </tr>
                  </thead>

                  <tbody>
                    {orderLineItems.map(({ name, quantity, price, tax, sku, ecommercePlatform, basePrice }) => (
                      <tr>
                        {userInput.product && !userInput.product.isHide ? <td>{name}</td> : null}
                        {userInput.sku && !userInput.sku.isHide ? <td>{sku}</td> : null}
                        {userInput.quantity && !userInput.quantity.isHide ? <td>{quantity}</td> : null}
                        {userInput.price && !userInput.price.isHide ? (
                          <td>
                            {ecommercePlatform === constant.BIGCOMMERCE
                              ? `${moneyFormat}${parseFloat(basePrice || 0).toFixed(2)}`
                              : `${moneyFormat}$price`}
                          </td>
                        ) : null}
                        {userInput.tax && !userInput.tax.isHide ? <td>{tax}</td> : null}
                        {userInput.subTotal && !userInput.subTotal.isHide ? (
                          <td>
                            {ecommercePlatform === constant.BIGCOMMERCE
                              ? `${moneyFormat}${(parseFloat(basePrice) * quantity).toFixed(2)}`
                              : `${moneyFormat}${tax + price * quantity}`}
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="invoice-price">
              <div className="invoice-price-left">
                <div>
                  <div className="invoice-price-row">
                    {userInput.totalTax && !userInput.totalTax.isHide ? (
                      <div className="sub-price">
                        <small className="label">
                          {userInput.totalTax && userInput.totalTax.label
                            ? userInput.totalTax.label
                            : cms("label.orderAmount")}
                        </small>
                        <span className="text-inverse">
                          {`${moneyFormat}${
                            (baseHelper.isBigCommerceUser(orderData) &&
                            !isVendor &&
                            baseHelper.getPrice(orderData && orderData.cost && orderData.cost.subtotalIncTax)) ||
                            (baseHelper.isBigCommerceUser(orderData) && isVendor && baseHelper.getPrice(vendorTotal)) ||
                            baseHelper.getPrice(subTotalData)
                            }`}
                        </span>
                      </div>
                    ) : null}

                    {shippingAmount && !userInput.totalShipping.isHide && !userInput.totalTax.isHide ? (
                      <div className="sub-price">
                        <i className="fa fa-plus" />
                      </div>
                    ) : null}
                    {shippingAmount && !userInput.totalShipping.isHide && (
                      <div className="sub-price">
                        <small className="label">
                          {userInput.totalShipping && userInput.totalShipping.label
                            ? userInput.totalShipping.label
                            : cms("label.shipping")}
                        </small>
                        <span>
                          {isVendor
                            ? `${moneyFormat + baseHelper.getPrice(vendorShippingAmount)}`
                            : `${moneyFormat + baseHelper.getPrice(shippingAmount)}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {userInput.total && !userInput.total.isHide ? (
                <div className="invoice-price-right">
                  <small className="text-upper">
                    {userInput.total.label ? userInput.total.label : cms("label.total")}
                  </small>

                  <span className="text-inverse">
                    {`${moneyFormat}${
                      (baseHelper.isBigCommerceUser(orderData) &&
                        !isVendor &&
                        baseHelper.getPrice(orderData && orderData.cost && orderData.cost.totalIncTax)) ||
                      (baseHelper.isBigCommerceUser(orderData) && isVendor && baseHelper.getPrice(subTotalData))
                      }`}
                  </span>
                </div>
              ) : null}
            </div>
            {userInput.orderFulfilledBy && !userInput.orderFulfilledBy.isHide ? (
              <div className="note">
                <span className="text-uppercase note-label">
                  {userInput.orderFulfilledBy.label || cms("label.fulfillBy")}
                </span>
                <address style={{ fontSize: "14px" }}>
                  {currentUserAddress.address ? [currentUserAddress.address, <br />] : ""}
                  {currentUserAddress.city ? [currentUserAddress.city, <br />] : ""}
                  {currentUserAddress.pinCode ? [currentUserAddress.pinCode, <br />] : ""}
                  {currentUserAddress.country}
                </address>
              </div>
            ) : null}
            <div className="invoice-footer">{cms("label.footer")}</div>
          </StyledSlip>
        </Layout.Section>
      </Layout>
    </>
  );
};
OrderSlipView.defaultProps = {
  orderData: {},
  orderLineItems: [],
};
OrderSlipView.propTypes = {
  orderData: PropTypes.shape(PropTypes.object),
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
};

export default OrderSlipView;
