import React, { useContext, useEffect, useState } from "react";
import { ChoiceList, Heading } from "@shopify/polaris";
import TimePicker from "react-bootstrap-time-picker";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context/privateContext";

// import helper
import { baseHelper } from "lib/helpers";

import CustomDaysWrapper from "./style";

const SlotOptions = (props) => {
  const { cms } = useContext(PrivateContext);
  const [daysError, setDaysError] = useState(false);

  const {
    selected,
    setEndTime,
    setStartTime,
    startTime,
    endTime,
    selectedDays,
    setDays,
    selectedHours,
    setMaxDuration = () => {},
    setSelectedDays,
    setSelectedHours,
    setDisabledButton = () => {},
  } = props;

  // Setting error message for not selecting operating day
  useEffect(() => {
    if (selectedDays[0] === constant.CUSTOM_DAYS) {
      setDaysError(!selected.length);
    } else {
      setDaysError(false);
    }
  }, [selectedDays, selected]);

  // adding 30 mins to endTime from startTime
  useEffect(() => {
    if (startTime >= endTime) {
      setEndTime(startTime + 30 * 60);
    }

    const allowedDuration =
      selectedHours[0] === constant.TWENTY_FOUR
        ? constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN
        : (endTime - startTime) / 60;
    setMaxDuration(allowedDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  const { days } = constant;
  const { MON, TUE, WED, THU, FRI, SAT, SUN } = days;

  const selectServiceDays = (selectDays) => {
    return (
      selectDays && (
        <ChoiceList
          title={cms("service.label.selectDays")}
          choices={[
            { label: "Monday", value: MON },
            { label: "Tuesday", value: TUE },
            { label: "Wednesday", value: WED },
            { label: "Thursday", value: THU },
            { label: "Friday", value: FRI },
            { label: "Saturday", value: SAT },
            { label: "Sunday", value: SUN },
          ]}
          selected={selected}
          onChange={(value) => [setDays(value), setDisabledButton(false)]}
          allowMultiple
        />
      )
    );
  };

  const defaultEndTime = baseHelper.getTimeInHourMin(startTime ? startTime / 60 + 30 : 450);

  const selectServiceHours = (selectHour) => {
    return (
      selectHour && (
        <CustomDaysWrapper className="select-service-hours">
          <div className="select-time">
            <span>{cms("service.label.startTime")}</span>
            <TimePicker
              start="07:00"
              end="21:00"
              step={30}
              onChange={(value) => [setStartTime(value), setDisabledButton(false)]}
              value={startTime}
            />
          </div>
          <div className="select-time">
            <span>{cms("service.label.endTime")}</span>
            <TimePicker
              start={defaultEndTime}
              end="21:30"
              step={30}
              onChange={(value) => [setEndTime(value), setDisabledButton(false)]}
              value={endTime}
            />
          </div>
        </CustomDaysWrapper>
      )
    );
  };
  return (
    <div className="slot-options">
      <Heading element="h2">{`${cms("service.label.operatingDay")}*`}</Heading>
      <div>
        <ChoiceList
          title={cms("service.label.days")}
          choices={[
            {
              label: cms("service.label.week"),
              value: "seven-days",
            },
            {
              label: cms("service.label.custom"),
              value: "custom-days",
              renderChildren: selectServiceDays,
            },
          ]}
          selected={selectedDays}
          onChange={(value) => [setSelectedDays(value), setDisabledButton(false)]}
          error={daysError ? cms("service.message.error.operatingDay") : ""}
          titleHidden
        />
      </div>
      <br />
      <Heading element="h2">{`${cms("service.label.operatingHour")}*`}</Heading>
      <div>
        <ChoiceList
          title={cms("service.label.hours")}
          choices={[
            { label: cms("service.label.selectDayHours"), value: "twentyFour" },
            {
              label: cms("service.label.custom"),
              value: "custom",
              renderChildren: selectServiceHours,
            },
          ]}
          selected={selectedHours}
          onChange={(value) => [setSelectedHours(value), setDisabledButton(false)]}
          titleHidden
        />
      </div>
    </div>
  );
};

export default SlotOptions;
