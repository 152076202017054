import React from "react";
import { SkeletonBodyText, Icon } from "@shopify/polaris";
import { SettingsMajor } from "@shopify/polaris-icons";

const settingIcon = <Icon source={SettingsMajor} color="base" />;

const operatorTabs = (cms, isLoading, currentUser) => [
  {
    id: "home",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.home"),
    panelID: "homeContent",
    role: "main-tab",
    link: "/",
    activeRoutes: [],
  },
  {
    id: "Invites",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.invitations"),
    panelID: "inviteContent",
    link: "/invitation",
    activeRoutes: [],
  },
  {
    id: "Orders",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.orders"),
    panelID: "ordersContent",
    link: currentUser && currentUser.ecommercePlatform ? "/order-lake" : "/orders",
    activeRoutes: ["/order-lake/view/:id", "/order-lake", "/orders/view/:id", "/orders/bulk"],
  },
  {
    id: "Products",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.products"),
    panelID: "productsContent",
    link: currentUser && currentUser.ecommercePlatform ? "/product-lake" : "/products",
    activeRoutes: [
      "/product-lake",
      "/products/add",
      "/product-lake/add",
      "/lake-variants/add/:productId",
      "/products/bulk/edit",
      "/products/edit",
      "/products/edit/:id",
      "/product-lake/edit/:id",
      "/products/service/edit/:id",
      "/products/review/:id",
      "/lake-variants/add/:productId",
      "/variants/edit/:productId/:id",
      "/lake-variants/edit/:productId/:id",
      "/variants/service/add/:productId",
      "/variants/service/edit/:productId/:id",
      "/variants/add/:productId",
      "/product-lake/review/:id",
      // "/v1/products/add",
      // "/v1/products/edit/:id",
      // "/v1/variants/add/:productId",
      // "/v1/variants/edit/:productId/:id",
    ],
  },
  {
    id: "Payments",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.payments"),
    panelID: "paymentsContent",
    link: currentUser && currentUser.ecommercePlatform ? "/payment-lake" : "/payments",
    activeRoutes: ["/payments/credit-note", "/payment-lake"],
  },
  {
    id: "Quotes",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.quotes"),
    panelID: "quoteContent",
    link: "/quotes",
    activeRoutes: [
      "/quotes",
      "/chat",
    ],
  },
  {
    id: "Vendors",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.providers"),
    panelID: "vendorsContent",
    link: "/providers",
    activeRoutes: [
      "/advance-providers",
      // "/chat",
      "/providers/add",
      "/providers/edit/:id",
      "/providers/profile/:id",
      "/providers/view/:id",
      "/provider/review/:id",
    ],
  },
  // {
  //   id: "chat",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.chatInbox"),
  //   link: "/chat",
  //   activeRoutes: [],
  //   panelID: "chat",
  // },
  // {
  //   id: "Reporting",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || "Reporting",
  //   panelID: "reportingContent",
  //   link: "/reporting",
  //   activeRoutes: ["/reporting"],
  // },
  {
    id: "Analytics",
    content: (isLoading && <SkeletonBodyText lines={1} />) || "Analytics",
    link: "/analytics",
    activeRoutes: ["/analytics/order-analytics", "/analytics/order-commission-revenue", "/analytics/product-analytics"],
    panelID: "analytics",
  },
  {
    id: "Settings",
    content: (isLoading && <SkeletonBodyText lines={1} />) || settingIcon,
    panelID: "settingsContent",
    link: "/setting",
    activeRoutes: [
      "/activity",
      "/activity/bulk",
      "/activity/bulk-edit",
      "/activity/view/:id",
      "/activity/edit/view/:id",
      "/branding",
      "/customize/fonts",
      "/change-password",
      "/commission",
      "/contact-us",
      "/chat-setting",
      "/edit-profile",
      "/email-template",
      "/email-template/edit/:id",
      "/payouts",
      "/profile",
      "/provider/manage",
      "/setting/address",
      "/setting/attachment",
      "/setting/currency/payout",
      "/setting/currency/rate",
      "/setting/customer",
      "/setting/credit-note",
      "/setting/discount",
      "/setting/fulfillment",
      "/setting/location",
      "/setting/manage-fulfillment",
      "/setting/manage-fulfillment-tracking",
      "/setting/markup",
      "/setting/notification",
      "/setting/order",
      "/setting/order/acs",
      "/setting/order/discount",
      "/setting/order-expiry",
      "/setting/order/packing-slip",
      "/setting/payment",
      "/setting/product",
      "/setting/product/approval",
      // "/setting/product-lake/approval",
      "/setting/product/label",
      "/setting/product/price",
      "/setting/product/tag",
      "/setting/provider/export",
      "/setting/tax",
      "/setting/user/database",
      "/setting/tax-rate",
      "/shipping",
      "/shipping/advance",
      "/terms",
      "/update-payment",
      "/update/plan",
      "/setting/address",
      "/setting/digest",
      "/setting/order/export",
      "/setting/order/live-connect",
      "/setting/report",
      "/setting/payment/export",
      "/setting/payment/express-payment",
      "/setting/payment/invoice-access",
      "/setting/payment/invoice",
      "/setting/payment/transaction-fees",
      "/setting/provider/access",
      "/setting/provider/advance",
      "/setting/provider/label",
      "/setting/provider/review",
      "/setting/provider/holiday",
      "/setting/payment/invoice",
      "/setting/payment/invoice-access",
      "/setting/order/packing-slip-access",
      "/setting/product/export",
      "/setting/product/metafield",
      "/setting/provider/subscription",
      "/setting/plan/list",
      "/setting/plan/edit/:id",
      "/setting/plan/add",
      "/setting/plan/preview",
      "/setting/schedule-payment",
      "/setting/product/geolocation",
      "/setting/product/listing",
    ],
  },
];

export default operatorTabs;
