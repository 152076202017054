import { gql } from "apollo-boost";

const GET_PRODUCT_LISTING = gql`
  query {
    getProductListingSetting {
      data {
        isEnabled
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_PRODUCT_LISTING;
