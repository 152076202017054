const errorMessage = {
  firstName: "First Name is required ",
  lastName: "Last Name is required",
  streetAddress: "Address is required",
  city: "City is required",
  country: "Country is required",
  postalCode: "Postal Code is required",
  minPostalCode: "Postal code must be at least 2 characters",
  maxPostalCode: "Postal code must be at most 32 characters",
  phoneNumber: "Phone Number is required",
  positive: "Must be positive",
  provinceCode: "Province Code is required",
  minProvinceCode: "Province code must be at least 2 characters",
  enterValidProvinceCode: ` Please provide valid country/province/state code,
   it should be of 2 characters and all characters should be in capital letters.`,
  alphanumeric: "Postal code must be alphanumeric",
  minPhoneNumber: "Phone number must be at least 7 characters",
  maxPhoneNumber: "Phone number must be at most 15 characters",
  brandName: "Brand Name is required",
  slug: "Brand Handle is required.",
  slugValidation: "Only lower case characters (a-z), hyphen (-) and underscore (_) are allowed in brand handle.",
  email: {
    requiredMessage: "Email is required",
    matches: "Please enter a valid email",
  },
};

export default errorMessage;
