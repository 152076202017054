import React, { useContext } from "react";

import { Card, TextStyle } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

import PropTypes from "prop-types";
import constant from "lib/constant/constant";

const Customer = (props) => {
  const { data } = props;
  const { cms } = useContext(PrivateContext);
  const { billing: billingAddress, customer, ecommercePlatform, email, shipping: shippingAddress, phone } = data || {};
  const { default_address: defaultAddress, email: customerEmail, phone: customerPhone } = customer || {};
  const {
    addressLineOne: address1,
    addressLineTwo: address2,
    firstName,
    postcode: zip,
    company,
    city,
    lastName,
    state: province,
    country,
    phone: shippingPhone,
  } = shippingAddress || {};
  const {
    addressLineOne: billingAddress1,
    addressLineTwo: billingAddress2,
    firstName: billingFirstName,
    email: billingEmail,
    lastName: billingLastName,
    postcode: billingZip,
    company: billingCompany,
    city: billingCity,
    state: billingProvince,
    country: billingCountry,
    phone: billingPhone,
  } = billingAddress || {};
  return (
    <Card title={cms("label.customer")}>
      <Card.Section>
        {ecommercePlatform === constant.WOOCOMMERCE ? (
          <TextStyle variation={!(customer && defaultAddress && defaultAddress.name) && constant.SUBDUED}>
            <div className="wrap_text">
              {(customer && defaultAddress && defaultAddress.name) || cms("label.noCustomer")}
            </div>
          </TextStyle>
        ) : (
          <TextStyle>
            <div className="wrap_text">
              {`${billingAddress && billingFirstName}
                ${billingAddress && billingLastName}` || cms("label.noCustomer")}
            </div>
          </TextStyle>
        )}
      </Card.Section>
      <Card.Section title={cms("label.contactInformation")}>
        <TextStyle variation={!(customer && email) && constant.SUBDUED}>
          <p className="wrap_text">{customerEmail || billingEmail || cms("label.noEmailAddress")}</p>
          <p className="wrap_text">{customerPhone || billingPhone || cms("label.noPhoneNumber")}</p>
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.shippingAddress").toUpperCase()}>
        {shippingAddress ? (
          <TextStyle>
            <div className="wrap_address">
              <div className="wrap_address">
                {`${(shippingAddress && firstName) || ""}
              ${(shippingAddress && lastName) || ""}`}
              </div>
              <div className="wrap_address">{`${(shippingAddress && company) || ""}`}</div>
              {`${(shippingAddress && address1) || ""}
              ${(shippingAddress && address2) || ""}`}
            </div>
            <div className="wrap_address">
              {`${(shippingAddress && city) || ""}
              ${(shippingAddress && province) || ""}`}
            </div>
            <p className="wrap_text">{shippingAddress && country}</p>
            <div className="wrap_address">{`${(shippingAddress && zip) || ""}`}</div>
            <p className="wrap_text">{shippingAddress && shippingPhone}</p>
          </TextStyle>
        ) : (
          <TextStyle variation={constant.SUBDUED}>{cms("label.noShippingAddress")}</TextStyle>
        )}
      </Card.Section>
      <Card.Section title={cms("label.billingAddress")}>
        <TextStyle>
          {billingAddress ? (
            <TextStyle>
              <div className="wrap_address">
                {`${(billingAddress && billingFirstName) || ""}
                ${(billingAddress && billingLastName) || ""}`}
              </div>
              <div className="wrap_address">{`${(billingAddress && billingCompany) || ""}`}</div>
              <div className="wrap_address">{`${(billingAddress && billingAddress1) || ""}`}</div>
              <div className="wrap_address">{`${(billingAddress && billingAddress2) || ""}`}</div>
              <div className="wrap_address">
                {`${(billingAddress && billingCity) || ""}
                ${(billingAddress && billingProvince) || ""}`}
              </div>
              <p className="wrap_text">{billingAddress && billingCountry}</p>
              <div className="wrap_address">{`${(billingAddress && billingZip) || ""}`}</div>
              <p className="wrap_text">{billingAddress && billingPhone}</p>
            </TextStyle>
          ) : (
            <TextStyle variation={constant.SUBDUED}>{cms("label.noBillingAddress")}</TextStyle>
          )}
        </TextStyle>
      </Card.Section>
    </Card>
  );
};
Customer.propTypes = PropTypes.object;
export default Customer;
