import { gql } from "apollo-boost";

const SYNC_VENDOR_PRESTASHOP_PRODUCT = gql`
  mutation syncVendorPrestaShopProduct {
    syncVendorPrestaShopProduct {
      status
      data
      error
    }
  }
`;

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const DISCONNECT_PRESTASHOP = gql`
  mutation disconnectPrestaShop {
    disconnectPrestaShop {
      status
      data
      error
    }
  }
`;

const CONNECT_PRESTASHOP = gql`
  mutation connectPrestaShop($input: PrestaShopConnect!) {
    connectPrestaShop(input: $input) {
      status
      data
      error
    }
  }
`;

export { SYNC_INVENTORY_VENDOR, SYNC_VENDOR_PRESTASHOP_PRODUCT, DISCONNECT_PRESTASHOP, CONNECT_PRESTASHOP };
