const cmsHolidaySetting = (cms) => {
  const holidayModeCMS = {
    label: {
      add: cms("label.add"),
      addVendor: cms("label.addVendor"),
      apply: cms("label.apply"),
      end: cms("label.end"),
      reason: cms("label.reason"),
      search: cms("label.search"),
      selectDate: cms("label.selectDate"),
      start: cms("label.start"),
      timePeriod: cms("label.timePeriod"),
      vendor: cms("label.vendor"),
      section: {
        setting: {
          title: cms("label.section.setting.title"),
          description: cms("label.section.setting.description"),
          providerDescription: cms("label.section.setting.providerDescription"),
        },
        holiday: {
          title: cms("label.section.holiday.title"),
        },
        banner: {
          title: cms("label.section.banner.title"),
          description: cms("label.section.banner.description"),
          operatorDescription: cms("label.section.banner.operatorDescription"),
        },
        reject: {
          title: cms("label.section.reject.title"),
          description: cms("label.section.reject.description"),
          providerDescription: cms("label.section.reject.providerDescription"),
        },
      },
    },
    messageToast: cms("message.success.save"),
  };
  return holidayModeCMS;
};

export default cmsHolidaySetting;
