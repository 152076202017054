import React, { useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import Baby from "babyparse";
import fileDownload from "js-file-download";

import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { GET_SELLER_FULFILLMENT } from "app/vendors/apollo/queries";
import constant from "lib/constant/constant";

const DownloadCSV = () => {
  const { cms, isLoading } = useContext(PrivateContext);
  const [isDownload, setIsDownload] = useState(true);
  const data = [
    {
      firstName: cms("label.firstName"),
      lastName: cms("label.lastName"),
      email: cms("label.email"),
      brandName: cms("label.brandName"),
      phoneNumber: cms("label.phoneNumber"),
      address: cms("label.address"),
      city: cms("label.city"),
      country: cms("label.country"),
      postalCode: cms("label.postalCode"),
    },
  ];
  const withProvinaceCodeData = [
    {
      firstName: cms("label.firstName"),
      lastName: cms("label.lastName"),
      email: cms("label.email"),
      brandName: cms("label.brandName"),
      phoneNumber: cms("label.phoneNumber"),
      address: cms("label.address"),
      city: cms("label.city"),
      provinceCode: cms("label.provinceCode"),
      country: cms("label.country"),
      postalCode: cms("label.postalCode"),
    },
  ];
  const { loading: fulfillmentLoading, data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  if (fulfillmentLoading || isLoading) {
    return <Spinner isFullPage />;
  }
  if (isDownload && !fulfillmentLoading && !isLoading) {
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    const scvData = showProvinceCode ? withProvinaceCodeData : data;
    const csv = Baby.unparse(scvData);
    fileDownload(csv, "bulk-vendor.csv");
    setIsDownload(false);
    setTimeout(() => {
      window.close();
    }, 1000);
  }

  return <Spinner isFullPage />;
};

export default withErrorBoundary(DownloadCSV);
