import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import OperatorVariantEdit from "app/productLake/modules/generic/features/variant/edit/edit";

const { userKey } = constant;
const { operator, provider } = userKey;

const LakeVariantEdit = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator || role === provider) {
      return <OperatorVariantEdit />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const VariantEditComponent = getComponent(currentUserRole);
  return VariantEditComponent;
};

export default LakeVariantEdit;
