import Header from "./header/header";
import Footer from "./footer/footer";
import Suspended from "./suspended/suspended";
import Tabs from "./tabs/tabs";
import TwoColumnLayout from "./twoColumnLayout/twoColumnLayout";
import SingleColumnLayout from "./singleColumnLayout/singleColumnLayout";
import NotFound from "./notFound/notFound";
import NotAllowed from "./notAllowed/notAllowed";

export { Header, Footer, Suspended, Tabs, TwoColumnLayout, SingleColumnLayout, NotAllowed, NotFound };
