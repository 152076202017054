import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { ProductLakeAddVariant } from "app/productLake/modules/generic/features/variant/subFeatures";

const { userKey } = constant;
const { operator, provider } = userKey;

const LakeVariantAdd = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator || role === provider) {
      return <ProductLakeAddVariant />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const VariantAddComponent = getComponent(currentUserRole);
  return VariantAddComponent;
};

export default LakeVariantAdd;
