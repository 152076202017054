import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { Terms as OperatorTerms } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;

const Terms = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorTerms />;
    }
    return null;
  };

  if (isLoading) {
    return (
      <>
        <SkeletonAnnotated />
        <SkeletonAnnotated />
      </>
    );
  }

  const currentUserRole = storageHelper.get("userRole");
  const TermsComponent = getComponent(currentUserRole);
  return TermsComponent;
};

export default Terms;
