import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { sellerSchema, vendorSchema } from "./schema";

const { SELLER, VENDOR } = constant;

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case SELLER:
      promise = validateValue(sellerSchema(cms), { Seller: value });
      break;
    case VENDOR:
      promise = validateValue(vendorSchema(cms), { vendor: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;
