import * as yup from "yup";

const schema = (cms) => {
  const subjectSchema = yup.object({
    subject: yup.string().required(cms("message.error.subjectIsRequired")).nullable(),
  });

  const messageSchema = yup.object({
    message: yup.string().required(cms("message.error.messageIsRequired")).nullable(),
  });

  return { subjectSchema, messageSchema };
};

export default schema;
