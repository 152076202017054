import { gql } from "apollo-boost";

const UPDATE_VENDOR_FULFILLMENT = gql`
  mutation updateVendorFulfillment($input: VendorFulfillment) {
    updateVendorFulfillment(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_VENDOR_FULFILLMENT;
