import styled from "styled-components";

export const StyledButton = styled.span`
  .Polaris-Choice {
    padding: 0.3rem 0;
  }
  .Polaris-Button {
    padding: 0.4rem 1.6rem;
  }
`;

export const StyledBadge = styled.div`
  .Polaris-Stack {
    margin-top: -0.7rem;
  }
`;

export const StyledCard = styled.div`
  .Polaris-Choice__Control {
    margin-right: 0;
  }
  .Polaris-Badge {
    margin-bottom: 1rem;
    &.Polaris-Badge--statusCritical {
      margin-bottom: 0;
    }
  }
  .Polaris-Stack__Item {
    > .Polaris-Link {
      margin-bottom: 4px;
    }
    .Polaris-Caption {
      margin-bottom: 7px;
      span {
        margin-bottom: 3px;
        display: block;
      }
    }
    .Polaris-Button {
      padding: 0.9rem 1.2rem;
    }
  }
`;

export const TotalSection = styled.div`
  > .Polaris-Card > .Polaris-Card__Section--subdued {
    padding-top: 0;
    padding-bottom: 0;
  }
  .Polaris-Card__Subsection > .Polaris-Card__Section {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledInfo = styled.div;

export const StyledStack = styled.div`
  .Polaris-Stack__Item {
    align-self: center;
    margin-top: 7px;
  }
`;
