import React, { useContext } from "react";

import OperatorInvoiceAccess from "app/setup/modules/operator/features/invoice/invoice";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator } = userKey;

const InvoiceSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorInvoiceAccess />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const InvoiceAccessComponent = getComponent(currentUserRole);
  return InvoiceAccessComponent;
};

export default withErrorBoundary(InvoiceSetting);
