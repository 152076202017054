import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Heading,
  Layout,
  PageActions,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import ExportTable from "app/payments/modules/generic/exportSetting/style";
import { StyledCardHeader } from "app/onboarding/modules/genericStyle";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast } from "lib/components";
import constant from "lib/constant/constant";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PAYMENT_REPORT_SETTING } from "app/setup/apollo/mutations";

const { DOWN, gql, HIDE_CAPITAL, SHOW, UP } = constant;

const PaymentExportReport = (props) => {
  const {
    CRITICAL,
    HIDDEN,
    PARTIALLY_HIDDEN,
    PAYMENT_TABLE_HEADING,
    PAYMENT_COLUMN,
    SELLERCOMMISSION,
    SUCCESS,
    VISIBLE,
    WARNING,
  } = constant;
  const {
    error: errorPaymentReportSetting,
    data: dataPaymentReportSetting,
    handleSetupTogglePaymentExport,
    openSetupPayment,
    paymentLastUpdateAt,
    refetch: paymentFetch,
    setBannerStatus,
  } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [message, setMessage] = useState("");
  // const [updatedAt, setUpdatedAt] = useState("");
  const isVendor = baseHelper.isVendor(currentUser);
  const [userInput, setUserInput] = useState({
    sellerCommission: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    vendorPaymentStatus: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    vendorPaidDate: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
  });

  const [paymentSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_PAYMENT_REPORT_SETTING);

  useEffect(() => {
    if (dataPaymentReportSetting) {
      const paymentData = baseHelper.getResponseData(dataPaymentReportSetting, gql.GET_PAYMENT_REPORT_SETTING);
      const paymentError = baseHelper.getResponseError(errorPaymentReportSetting, gql.GET_PAYMENT_REPORT_SETTING);
      const data = paymentData.report;
      if (data) {
        const keys = Object.keys(userInput);
        keys.forEach((key) => {
          if (!data[key]) {
            data[key] = {
              label: "",
              isHideCsv: false,
              isHidePdf: false,
            };
          }
        });
        if (paymentData.report) {
          // setUpdatedAt(paymentData.updatedAt);
          setFirstPush(false);
          setUserInput(data);
        }
      }
      if (paymentError) {
        setBannerStatus({ isOpen: true, title: paymentError, status: constant.CRITICAL });
      }
    }
  }, [dataPaymentReportSetting, errorPaymentReportSetting]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const noUncheckCsv = Object.keys(userInput)
      .filter((item) => {
        return isVendor ? !(isVendor && item === SELLERCOMMISSION) : true;
      })
      .map((item) => {
        newData.push(userInput[item]);
        if (userInput[item].isHideCsv) {
          return true;
        }
        return false;
      });
    const noUncheckPdf = Object.keys(userInput)
      .filter((item) => {
        return isVendor ? !(isVendor && item === SELLERCOMMISSION) : true;
      })
      .map((item) => {
        if (userInput[item].isHidePdf) {
          return true;
        }
        return false;
      });
    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        if (element.label && item.label && element.label.trim() === item.label.trim()) {
          response = true;
          setBannerStatus({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          return false;
        }
        return false;
      });
    });
    if (!response) {
      response = true;
      noUncheckCsv.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (!response) {
      response = true;
      noUncheckPdf.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const updatePaymentSetting = async (type) => {
    paymentSetting({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PAYMENT_REPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PAYMENT_REPORT_SETTING);
          if (resData) {
            paymentFetch();
            setMessage(cms("message.success.paymentUpdate"));
          }
          if (type === "save") {
            setMessage(cms("message.success.paymentSave"));
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const handleLabelChange = (val, key) => {
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val;
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };
  const selectCurrentLabelsColumn = (item) => <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField value={data && data.label} onChange={(value) => handleLabelChange(value, key)} />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === "isCsv") setUserInput({ ...userInput, [key]: { ...updateData, isHideCsv: !updateData.isHideCsv } });
    if (value === "isPdf") setUserInput({ ...userInput, [key]: { ...updateData, isHidePdf: !updateData.isHidePdf } });
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => (
    <>
      <Checkbox
        label={cms("label.isCsv")}
        checked={userInput[index] && userInput[index].isHideCsv}
        onChange={() => handleCheckbox("isCsv", index)}
      />
      <Checkbox
        label={cms("label.isPdf")}
        checked={userInput[index] && userInput[index].isHidePdf}
        onChange={() => handleCheckbox("isPdf", index)}
      />
    </>
  );

  const getRows = () => {
    return Object.keys(userInput)
      .filter((item) => {
        return isVendor ? !(isVendor && item === SELLERCOMMISSION) : true;
      })
      .map((item) => {
        let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        const itemData = userInput[item];
        if (itemData && itemData.isHideCsv && itemData.isHidePdf) {
          badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
        } else if (itemData.isHidePdf || itemData.isHideCsv) {
          badge = { badgesLable: PARTIALLY_HIDDEN, badgesStatus: WARNING };
        } else {
          badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        }
        const currentValues = selectCurrentLabelsColumn(item);
        const newValues = selectNewLabelsTextField(userInput[item], item);
        const badgesValues = badges(badge);
        const hideColumnValues = selectHideColumn(item);
        return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
      });
  };

  return (
    <Layout.AnnotatedSection
      title={cms("section.paymentExport.title")}
      description={cms("section.paymentExport.description")}
    >
      <Card>
        <StyledCardHeader className="styled-card-header">
          <Card.Header
            sectioned
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Stack>
                <Heading>
                  {cms("section.paymentExport.cardTitle")}
                  <TextStyle variation="subdued">
                    {paymentLastUpdateAt && (
                      <TextStyle variation="subdued">
                        <Caption>{`${baseHelper.lastUpdateDate(paymentLastUpdateAt)}`}</Caption>
                      </TextStyle>
                    )}
                  </TextStyle>
                  <TextStyle variation="subdued" />
                </Heading>
              </Stack>
            }
            actions={[
              {
                content: openSetupPayment ? HIDE_CAPITAL : SHOW,
                onAction: () => handleSetupTogglePaymentExport(),
                disclosure: openSetupPayment ? UP : DOWN,
              },
            ]}
          />
        </StyledCardHeader>
      </Card>
      <Collapsible open={openSetupPayment} id="basic-collapsible">
        <Card>
          <Card.Section>
            <ExportTable className="payment-export-table">
              <DataTable columnContentTypes={PAYMENT_COLUMN} headings={PAYMENT_TABLE_HEADING} rows={getRows()} />
            </ExportTable>
            {!firstPush && (
              <div className="page-action">
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) updatePaymentSetting();
                    },
                    disabled: !submitEnabled,
                    loading: settingUpdateLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {firstPush && (
          <div>
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => {
                  const update = verifyValues();
                  if (!update) updatePaymentSetting("save");
                },
                disabled: !submitEnabled,
                loading: settingUpdateLoading,
              }}
              secondaryActions={[
                {
                  id: "cancelButton",
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          </div>
        )}
      </Collapsible>
      <div className="toast">
        <Toast message={message} setToast={setMessage} />
      </div>
    </Layout.AnnotatedSection>
  );
};

export default withFeature(withErrorBoundary(PaymentExportReport), {
  feature: constant.PAYMENT_REPORT_SETTING,
});
