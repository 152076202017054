import React from "react";
import {
  AddressBook,
  AddSellerPlan,
  Carrier,
  CurrencyPayoutSetting,
  CurrencyRateSetting,
  EditSubscriptionPlan,
  Fulfillment,
  LocationMapping,
  ManageGeolocation,
  SellerPlanList,
  ManageFulfillmentDetail,
  ManageFulfillmentTrackingDetails,
  ManageVendorExport,
  OrderLiveConnectSetting,
  PackingSlipSetting,
  PlanPreview,
  ProductExportSetting,
  ShopifyInstall,
} from "app/setup/modules";

import CreditNote from "app/setup/modules/creditNote";
import InvoiceSetting from "app/setup/modules/invoiceSetting";
import OrderACSSetting from "app/setup/modules/orderACSSetting";

// order features
import {
  BulkOrderFulfillment,
  ExportOrder,
  ExportOrderPDF,
  ManageCustomerDetails,
  OrderAutoExpiry,
  OrderList,
  OrderSetting,
  OrderSlip,
  OrderView,
} from "app/orders/modules";

import { ExportReport, Dashboard, ExportReportPDF } from "app/dashboard/modules";

// service variant routes

import ServiceVariantAdd from "app/product/modules/variantServiceAdd";
import ServiceVariantEdit from "app/product/modules/variantServiceEdit";

// provider features
import {
  ExportVendor,
  ExportVendorPDF,
  VendorList,
  VendorAdd,
  VendorEdit,
  DownloadVendorCSV,
  VendorView,
  DownloadInviteCSV,
  VendorReview,
} from "app/vendors/modules";

// provider advance features
import {
  AdvanceProviderList,
  AdvanceProviderView,
  HolidaySetting,
  ManageVendor,
  ManageVendorAccess,
  AdvanceVendorAccess,
  ManageVendorReview,
  ManageVendorSubscription,
  VendorFormLabel,
} from "app/advanceVendor/modules";

import Reporting from "app/reporting/modules";

// sellers features
import { SellerList, SellerProfileView } from "app/sellers/modules";
import Invitation from "app/invitations/modules";
import Quote from "app/quote/modules";

// product features
import {
  BulkProductEdit,
  ExportProductCSV,
  ExportProductAsCSV,
  ExportProductPDF,
  ProductMetafield,
  ProductApprovalSetting,
  ProductAttachmentSetting,
  ProductList,
  ProductListSetting,
  // ProductAdd,
  ProductSetting,
  // ProductEdit,
  ProductReview,
  ProductConnectShop,
  ProductPriceSetting,
  ShopifyCallback,
  ShopifyProducts,
  // VariantAdd,
  // VariantEdit,
  PrestaShopProducts,
  MagentoProducts,
  WixProducts,
  WooCommerceProducts,
} from "app/productOld/modules";

// new product form
import {
  ProductAdd,
  ProductEdit,
  ProductEditCsv,
  ProductServiceEdit,
  VariantAdd,
  VariantEdit,
  WixCallback,
  WixConnect,
} from "app/product/modules";

// onboarding features
import {
  Branding,
  Commission,
  CustomizeFont,
  Discount,
  DynamicDiscount,
  FeaturePanel,
  Markup,
  PaymentConnect,
  PaymentService,
  PaypalCallBack,
  ProductFormLabel,
  ProductTagSetting,
  ReportSetting,
  Settings,
  StripeCallBack,
  StripeReturn,
  Terms,
} from "app/onboarding/modules";

import {
  BillingFailure,
  BillingSuccess,
} from "app/plans";

import Analytics from "app/analytics/modules";
import AnalyticsReport from "app/analytics/modules/report";

import CommissionRevenue from "app/analytics/modules/operator/commissionRevenue/commissionRevenue";
// import OrderAnalytics from "app/analytics/modules/provider/orderAnalytics/orderAnalytics";
// import ProductAnalytics from "app/analytics/modules/provider/productAnalytics/productAnalytics";

// plan features
import {
  UpdatePlan,
  UpdatePayment,
  Plan,
  // PlanCheckout
} from "app/plans";

// user management features

import PrivateRoute from "routes/private/privateRoute";
import UserDatabaseSetting from "app/setup/modules/userDatabaseSetting";

// shipping features
import { Shipping, AdvanceShipping, ExportShipping } from "app/shipping/modules";
// payment features
import { PaymentList, Invoice, ExportPayment, ExportPaymentPDF, SchedulePayment, TransactionFeesSetting } from "app/payments/modules";
import { EmailTemplate, EditEmailTemplate } from "app/emailTemplate/modules";

import {
  ActivityList,
  Association,
  AutomaticPayouts,
  BulkActivity,
  BulkEditActivity,
  ChangePassword,
  Chat,
  ChatSetting,
  ContactUs,
  CustomDomain,
  DigestSetting,
  EditProfile,
  Maintenance,
  Notification,
  OnboardingList,
  UserSession,
  ViewActivity,
  ViewEditActivity,
} from "app/userManagement/modules";

import ExpressPaymentSetting from "app/payments/modules/expressPaymentSetting";
import PaymentExportSetting from "app/payments/modules/exportSetting";
import ManageTax from "app/setup/modules/manageTax";
import ManageOrderTax from "app/setup/modules/manageOrderTax";
import OrderExportSetting from "app/orders/modules/exportSetting";
import CreditNoteList from "app/payments/modules/creditNoteList";

import {
  BigCommerceConnect,
  LakeShopifyProducts,
  LakeBigCommerceProducts,
  LakeVariantAdd,
  LakeVariantEdit,
  ProductLakeAdd,
  ProductLakeEdit,
  ProductLakeList,
  ProductLakeReview,
  // ProductLakeApprovalSetting,
} from "app/productLake/modules";

import {
  ExportOrderLakeCsv,
  ExportOrderLakePdf,
  OrderLakeList,
  OrderLakeView,
  OrderLakeSlip,
} from "app/orderLake/modules";
// payment-lake
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";
import { LakePaymentList, PaymentInvoice } from "app/paymentsLake/modules";

const NotFound = () => <h1>Not Found Component</h1>;

const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/activity", component: ActivityList },
  { path: "/analytics", component: Analytics },
  { path: "/analytics/report", component: AnalyticsReport },
  // { path: "/analytics/order-analytics", component: OrderAnalytics },
  // { path: "/analytics/product-analytics", component: ProductAnalytics },
  { path: "/analytics/order-commission-revenue", component: CommissionRevenue },
  { path: "/setting/report", component: ReportSetting },
  { path: "/activity/bulk", component: BulkActivity },
  { path: "/activity/bulk-edit", component: BulkEditActivity },
  { path: "/activity/view/:id", component: ViewActivity },
  { path: "/activity/edit/view/:id", component: ViewEditActivity },
  { path: "/association", component: Association },
  { path: "/branding", component: Branding },
  { path: "/bigCommerce/connect", component: BigCommerceConnect },
  { path: "/commission", component: Commission },
  { path: "/contact-us", component: ContactUs },
  { path: "/change-password", component: ChangePassword },
  { path: "/chat", component: Chat },
  { path: "/chat-setting", component: ChatSetting },
  { path: "/domain", component: CustomDomain },
  { path: "/customize/fonts", component: CustomizeFont },
  { path: "/file-download/vendor", component: DownloadVendorCSV },
  { path: "/file-download/invite", component: DownloadInviteCSV },
  { path: "/setting/product/metafield", component: ProductMetafield },
  { path: "/setting/product/listing", component: ProductListSetting },
  { path: "/profile", component: EditProfile },
  { path: "/chat-setting", component: ChatSetting },
  { path: "/invitation", component: Invitation },
  { path: "/invoice/:id/:lineItemId", component: Invoice },
  { path: "/onboard-activity", component: OnboardingList },
  { path: "/orders", component: OrderList },
  { path: "/order-lake", component: OrderLakeList },
  { path: "/order-slip/:id", component: OrderSlip },
  { path: "/orders/view/:id", component: OrderView },
  { path: "/orders/bulk", component: BulkOrderFulfillment },
  { path: "/orderlake-slip/:id", component: OrderLakeSlip },
  { path: "/order-lake/view/:id", component: OrderLakeView },
  { path: "/export/order", component: ExportOrder },
  { path: "/export/shipping", component: ExportShipping },
  { path: "/export/order/pdf", component: ExportOrderPDF },
  { path: "/export/order-lake", component: ExportOrderLakeCsv },
  { path: "/export/order-lake/pdf", component: ExportOrderLakePdf },
  { path: "/export/report", component: ExportReport },
  { path: "/export/report/pdf", component: ExportReportPDF },
  { path: "/maintenance", component: Maintenance },
  { path: "/export/payment", component: ExportPayment },
  { path: "/export/payment/pdf", component: ExportPaymentPDF },
  { path: "/export/product", component: ExportProductCSV },
  { path: "/export/product/csv", component: ExportProductAsCSV },
  { path: "/export/product/pdf", component: ExportProductPDF },
  { path: "/payment-connect", component: PaymentConnect },
  { path: "/payments", component: PaymentList },
  { path: "/payment-lake", component: LakePaymentList },
  { path: "/payment-lake/invoice/:id", component: PaymentInvoice },
  { path: "/payments/credit-note", component: CreditNoteList },
  { path: "/payouts", component: AutomaticPayouts },
  { path: "/paypal-callback", component: PaypalCallBack }, // deprecated by /paypal/callback
  { path: "/paypal/callback", component: PaypalCallBack },
  { path: "/plan", component: Plan },
  // { path: "/plan/checkout", component: PlanCheckout },
  { path: "/products", component: ProductList },
  { path: "/product-lake", component: ProductLakeList },
  // { path: "/products/add", component: ProductAdd },
  { path: "/products/add", component: ProductAdd },
  { path: "/products/add/shop", component: ProductConnectShop },
  { path: "/product-lake/add", component: ProductLakeAdd },
  // { path: "/products/edit/:id", component: ProductEdit },
  { path: "/products/edit/:id", component: ProductEdit },
  { path: "/product-lake/edit/:id", component: ProductLakeEdit },
  { path: "/product-lake/shopify", component: LakeShopifyProducts },
  { path: "/product-lake/bigcommerce", component: LakeBigCommerceProducts },
  // { path: "/products/view/:id", component: ProductEdit },
  { path: "/products/service/edit/:id", component: ProductServiceEdit },
  { path: "/products/view/:id", component: ProductEdit },
  { path: "/products/review/:id", component: ProductReview },
  { path: "/product-lake/review/:id", component: ProductLakeReview },
  { path: "/products/shopify", component: ShopifyProducts },
  { path: "/products/wix", component: WixProducts },
  { path: "/products/woocommerce", component: WooCommerceProducts },
  { path: "/products/magento", component: MagentoProducts },
  { path: "/products/prestashop", component: PrestaShopProducts },
  { path: "/products/bulk/edit", component: BulkProductEdit },
  { path: "/products/edit", component: ProductEditCsv },
  { path: "/operators", component: SellerList },
  { path: "/operators/view/:id", component: SellerProfileView },
  { path: "/quotes", component: Quote },
  { path: "/reporting", component: Reporting },
  { path: "/session", component: UserSession },
  { path: "/setting", component: Settings },
  { path: "/setting/address", component: AddressBook },
  { path: "/setting/carrier", component: Carrier },
  { path: "/setting/currency/payout", component: CurrencyPayoutSetting },
  { path: "/setting/currency/rate", component: CurrencyRateSetting },
  { path: "/setting/customer", component: ManageCustomerDetails },
  { path: "/setting/digest", component: DigestSetting },
  { path: "/setting/discount", component: Discount },
  { path: "/setting/product/label", component: ProductFormLabel },
  { path: "/setting/product/tag", component: ProductTagSetting },
  { path: "/setting/fulfillment", component: Fulfillment },
  { path: "/setting/location", component: LocationMapping },
  { path: "/setting/manage-fulfillment", component: ManageFulfillmentDetail },
  { path: "/setting/manage-fulfillment-tracking", component: ManageFulfillmentTrackingDetails },
  { path: "/setting/product/geolocation", component: ManageGeolocation },
  { path: "/setting/markup", component: Markup },
  { path: "/setting/notification", component: Notification },
  { path: "/setting/order/acs", component: OrderACSSetting },
  { path: "/setting/order", component: OrderSetting },
  { path: "/setting/order/acs", component: OrderACSSetting },
  { path: "/setting/order/discount", component: DynamicDiscount },
  { path: "/setting/order/export", component: OrderExportSetting },
  { path: "/setting/order-expiry", component: OrderAutoExpiry },
  { path: "/setting/order/live-connect", component: OrderLiveConnectSetting },
  { path: "/setting/payment", component: PaymentService },
  { path: "/setting/payment/transaction-fees", component: TransactionFeesSetting },
  { path: "/setting/payment/export", component: PaymentExportSetting },
  { path: "/setting/payment/express-payment", component: ExpressPaymentSetting },
  { path: "/setting/payment/invoice", component: InvoiceSetting },
  { path: "/setting/product", component: ProductSetting },
  { path: "/setting/product/price", component: ProductPriceSetting },
  { path: "/setting/product/approval", component: ProductApprovalSetting },
  { path: "/setting/product/export", component: ProductExportSetting },
  { path: "/setting/schedule-payment", component: SchedulePayment },

  // { path: "/setting/product-lake/approval", component: ProductLakeApprovalSetting },
  { path: "/setting/tax", component: ManageTax },
  { path: "/setting/user/database", component: UserDatabaseSetting },
  { path: "/setting/tax-rate", component: ManageOrderTax },
  { path: "/setting/attachment", component: ProductAttachmentSetting },
  { path: "/shipping", component: Shipping },
  { path: "/shipping/advance", component: AdvanceShipping },
  { path: "/stripe/callback", component: StripeCallBack },
  { path: "/stripe/return", component: StripeReturn },
  { path: "/billing/success", component: BillingSuccess },
  { path: "/billing/failure", component: BillingFailure },
  { path: "/providers/shopify-callback", component: ShopifyCallback },
  { path: "/providers/shopify/install", component: ShopifyInstall },
  { path: "/wix/callback", component: WixCallback },
  { path: "/wix/connect", component: WixConnect },
  { path: "/terms", component: Terms },
  { path: "/update/plan", component: UpdatePlan },
  { path: "/update-payment", component: UpdatePayment },
  { path: "/export/vendor", component: ExportVendor },
  { path: "/export/vendor/pdf", component: ExportVendorPDF },
  // { path: "/variants/add/:productId", component: VariantAdd },
  { path: "/variants/add/:productId", component: VariantAdd },
  { path: "/lake-variants/add/:productId", component: LakeVariantAdd },
  { path: "/variants/service/add/:productId", component: ServiceVariantAdd },
  { path: "/variants/service/edit/:productId/:id", component: ServiceVariantEdit },
  { path: "/lake-variants/edit/:productId/:id", component: LakeVariantEdit },
  // { path: "/variants/edit/:productId/:id", component: VariantEdit },
  { path: "/variants/edit/:productId/:id", component: VariantEdit },
  // { path: "/product/add", component: ProductAdd },
  // { path: "/product/edit/:id", component: ProductEdit },
  // { path: "/v1/variants/add/:productId", component: VariantAdd },
  // { path: "/v1/variants/edit/:productId/:id", component: VariantEdit },
  { path: "/providers", component: VendorList },
  { path: "/providers/add", component: VendorAdd },
  { path: "/providers/edit/:id", component: VendorEdit },
  { path: "/providers/view/:id", component: VendorView },
  { path: "/feature", component: FeaturePanel },
  { path: "/email-template", component: EmailTemplate },
  { path: "/email-template/edit/:id", component: EditEmailTemplate },
  { path: "/advance-providers", component: AdvanceProviderList },
  { path: "/providers/profile/:id", component: AdvanceProviderView },
  { path: "/provider/review/:id", component: VendorReview },
  { path: "/provider/manage", component: ManageVendor },
  { path: "/setting/credit-note", component: CreditNote },
  { path: "/setting/provider/holiday", component: HolidaySetting },
  { path: "/setting/provider/access", component: ManageVendorAccess },
  { path: "/setting/provider/export", component: ManageVendorExport },
  { path: "/setting/provider/advance", component: AdvanceVendorAccess },
  { path: "/setting/provider/label", component: VendorFormLabel },
  { path: "/setting/product/export", component: ProductExportSetting },
  { path: "/setting/provider/review", component: ManageVendorReview },
  { path: "/setting/plan/list", component: SellerPlanList },
  { path: "/setting/plan/add", component: AddSellerPlan },
  { path: "/setting/plan/preview", component: PlanPreview },
  { path: "/setting/plan/edit/:id", component: EditSubscriptionPlan },
  { path: "/setting/provider/subscription", component: ManageVendorSubscription },
  { path: "/setting/order/packing-slip", component: PackingSlipSetting },
  { path: "*", component: NotFound },
];

const routes = privateRoutes.map((route) => (
  <PrivateRoute exact path={route.path} component={route.component} key={route.path} />
));

export default routes;
