import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

// import components
import { SkeletonAnnotated } from "lib/components";
import { OperatorProductMetafield } from "./operator/features";

const ProductMetafield = () => {
  const { isLoading } = useContext(PrivateContext);
  const {
    userKey: { operator },
  } = constant;

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProductMetafield />;
    }

    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OnboardingComponent = getComponent(currentUserRole);
  return OnboardingComponent;
};

export default withFeature(withErrorBoundary(ProductMetafield), { feature: constant.PRODUCT_SETTING });
