import { gql } from "apollo-boost";

const GET_SELLER_FULFILLMENT = gql`
  query getSellerFulfillment {
    getSellerFulfillment {
      data {
        dropship {
          isThirdPartyShipping
          shippo {
            apiKey
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_SELLER_FULFILLMENT;
