import React, { useContext } from "react";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { ManageDetails } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;
const ManageCustomerDetails = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <ManageDetails />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const OrderSettingComponent = getComponent(currentUserRole);
  return OrderSettingComponent;
};
export default ManageCustomerDetails;
