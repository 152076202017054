import React, { useState } from "react";
import { AppProvider, Checkbox, Modal, ResourceItem, ResourceList, Stack, TextStyle, TopBar } from "@shopify/polaris";
import PropTypes from "prop-types";

import { LocationTopBar } from "app/setup/modules/operator/features/currency/currencyStyle";
// eslint-disable-next-line import/no-cycle
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const AddProductTag = (props) => {
  const { active, cms, handleChange, productTag, rowData, setActive, setProductTag, setRowData } = props;

  const { color } = constant;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState(productTag);
  const theme = {
    colors: {
      topBar: {
        background: color.WHITE,
        backgroundLighter: color.GRAY,
        backgroundDarker: color.ATHENS_GRAY,
        border: color.LOBLOLLY,
        color: color.CLAY,
      },
    },
  };
  const handleButtonDisable = () => {
    let isDisable = true;
    productTag.forEach((tag) => {
      const { isCheck = false } = tag;
      if (isCheck) {
        isDisable = false;
      }
    });
    return isDisable;
  };
  const handleSearchChange = (searchItem) => {
    setSearchValue(searchItem);
    setIsSearchActive(searchItem.length > 0);
    const tagToShow = productTag.filter((item) => {
      const { tagName = "" } = item || {};
      if ((tagName && tagName.toLowerCase().includes(searchItem)) || (tagName && tagName.includes(searchItem))) {
        return true;
      }
      return false;
    });
    setSearchList([...tagToShow]);
  };

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={(value) => handleSearchChange(value)}
      value={searchValue}
      placeholder={cms("section.tag.placeHolder.search")}
      showFocusBorder
    />
  );

  const handleSearchResultsDismiss = () => {
    setIsSearchActive(false);
    setSearchValue("");
    setSearchList([...productTag]);
  };

  const onSubmit = () => {
    const addRow = [...rowData];
    productTag.forEach((item) => {
      const { tagName = "", isCheck = false } = item;
      if (isCheck) {
        addRow.push({
          tag: tagName,
          vendor: "",
          type: {
            isAll: false,
            value: "",
          },
          category: {
            isAll: false,
            value: "",
          },
        });
      }
    });
    setRowData(addRow);
    setActive(!active);
  };

  const handleCheckbox = (index, checkUncheck, tag) => {
    const tagCheckbox = [...productTag];
    tagCheckbox.forEach((tagData) => {
      if (tagData.tagName === tag) {
        // eslint-disable-next-line no-param-reassign
        tagData.isCheck = checkUncheck;
      }
      return false;
    });
    setProductTag([...tagCheckbox]);
  };

  const renderItem = (item, _, index) => {
    const { tagName = "", isCheck = false } = item;
    return (
      <ResourceItem id={tagName}>
        <Stack>
          <Checkbox key={tagName} checked={isCheck} onChange={(action) => handleCheckbox(index, action, tagName)} />
          <TextStyle>{tagName}</TextStyle>
        </Stack>
      </ResourceItem>
    );
  };

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={cms("section.tag.modal.title")}
      primaryAction={{
        content: cms("section.tag.button.add"),
        onAction: onSubmit,
        disabled: handleButtonDisable(),
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: handleChange,
        },
      ]}
    >
      <Modal.Section>
        <AppProvider theme={theme}>
          <LocationTopBar className="vendor-popup">
            <div className="vendor-topBar">
              <TopBar
                searchResultsVisible={isSearchActive}
                searchField={searchFieldMarkup}
                onSearchResultsDismiss={handleSearchResultsDismiss}
              />
            </div>
            <ResourceList items={searchList} renderItem={renderItem} />
          </LocationTopBar>
        </AppProvider>
      </Modal.Section>
    </Modal>
  );
};

export default withErrorBoundary(AddProductTag);

AddProductTag.propTypes = {
  active: PropTypes.bool,
  cms: PropTypes.func,
  handleChange: PropTypes.func,
  setActive: PropTypes.func,
};

AddProductTag.defaultProps = {
  active: null,
  cms: () => {},
  handleChange: () => {},
  setActive: () => {},
};
