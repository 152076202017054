import React, { useContext, useEffect } from "react";
import { Card, TextField, Select } from "@shopify/polaris";
import { PrivateContext } from "lib/context/privateContext";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const ServiceDetails = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    address,
    capacity,
    duration,
    location,
    maxDuration = constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN,
    setCapacity,
    setDisabledButton = () => {},
    setDuration,
    setLocation,
  } = props;

  useEffect(() => {
    if (maxDuration < duration) {
      setDuration(0);
    }
  }, [maxDuration, duration, setDuration]);

  const acceptOnlyValidInput = (value, preValue, maxValue = null) => {
    const allowedDuration = maxValue || value;
    return (
      (baseHelper.validateWholeNumberExcludeZero(value) && value <= allowedDuration && value) ||
      (value !== "" && preValue) ||
      ""
    );
  };

  return (
    <Card.Section>
      <TextField
        label={`${cms("service.label.capacity")}*`}
        min={0}
        placeholder={cms("service.placeholder.capacity")}
        value={(capacity && capacity.toString()) || ""}
        onChange={(newVal) => [setCapacity(acceptOnlyValidInput(newVal, capacity)), setDisabledButton(false)]}
      />
      <br />
      <Select
        label={`${cms("service.label.location")}`}
        placeholder={cms("service.placeholder.selectLocation")}
        options={address}
        value={location}
        onChange={(newVal) => [setLocation(newVal), setDisabledButton(false)]}
      />
      <br />
      <TextField
        label={`${cms("service.label.sessionDuration")}*`}
        min={0}
        max={30}
        placeholder={cms("service.placeholder.sessionDuration")}
        value={(duration && duration.toString()) || ""}
        onChange={(newVal) => [
          setDuration(acceptOnlyValidInput(newVal, duration, maxDuration)),
          setDisabledButton(false),
        ]}
      />
    </Card.Section>
  );
};

export default ServiceDetails;
