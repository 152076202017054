import GET_ADDRESS from "./address";
import GET_ASSOCIATED_SELLER from "./getAssociatedSeller";
import GET_CATALOG from "./getCatalog";
import GET_COMMISSION from "./commission";
import GET_CREDIT_NOTE_SETTING from "./getCreditNoteSetting";
import GET_CURRENCY from "./currency";
import GET_CURRENCY_PAYOUT from "./currencyPayout";
import GET_CURRENCY_RATE from "./currencyRate";
import GET_DISCOUNT from "./discount";
import GET_DISCOUNT_SETTING from "./getDiscountSetting";
import GET_DISCOUNT_CODE_SETTING from "./getDiscountCodeSetting";
import GET_FULFILLMENT, { GET_SELLER_FULFILLMENT } from "./fulfilment";
import GET_FULFILLMENT_TRACKING_DETAILS_SETTING from "./getFulfillmentTrackingDetailsSetting";
// import GET_INVOICE_ACCESS_SETTING from "./getInvoiceAccessSetting";
import GET_MARKUP from "./markup";
import GET_ORDER_ACS_SETTING from "./getOrderACSSetting";
import GET_ORDER_EXPIRY_SETTING from "./orderExpiry";
import GET_ORDER_COMMISSION from "./getOrderCommission";
import GET_ORDER_EXPORT_SETTING from "./getOrderExportSetting";
import GET_ORDER_LIVE_CONNECT_SETTING from "./getOrderLiveConnectSetting";
import GET_ORDER_REPORT_SETTING from "./getOrderReportSetting";
import GET_PAYMENT_REPORT_SETTING from "./getPaymentReportSetting";
import GET_PRODUCT_FORM from "./productForm";
import GET_PRODUCT_REPORT_SETTING from "./getProductReportSetting";
import GET_PRODUCT_SETTING from "./getProductSetting";
import GET_PRODUCT_TAG_SETTING from "./getProductTagSetting";
import GET_PRODUCT_TYPE from "./getProductType";
import GET_PRODUCT_EXPORT_SETTING from "./getProductExportSetting";
import GET_PAYMENT_EXPORT_SETTING from "./getPaymentExportSetting";
import GET_REPORT_DATA from "./getReportData";
import GET_SELLER_LOCATION from "./getSellerLocation";
import GET_SELLER_PLAN from "./sellerPlan";
import GET_SELLER_PLAN_DETAILS from "./sellerPlanDetails";
import GET_SELLER_SETTING from "./sellerSetting";
import GET_SENDABOX_CARRIER from "./getSendaboxCarrier";
import GET_SHIPPO_CARRIER from "./getShippoCarrier";
import GET_SHIPPY_PRO_CARRIER from "./getShippyProCarrier";
import GET_TERM from "./term";
import GET_TAX_SETTING from "./getTaxSetting";
import GET_TAX_RATE_SETTING from "./getTaxRateSetting";
import GET_TRANSACTION_FEE from "./getTransactionFee";
import GET_USER from "./getUser";
import GET_USER_DATABASE_SETTING from "./getUserDatabaseSetting";
import GET_VENDOR_AND_PRODUCT_TYPES from "./advanceOption";
import GET_VENDOR_BRAND_LIST from "./getVendorBrandList";
import GET_VENDOR_DISCOUNT_SETTING from "./getVendorDiscountSetting";
import GET_VENDOR_EXPORT_SETTING from "./getVendorExportSetting";
import GET_VENDOR_FULFILLMENT from "./getVendorFulfillment";
import GET_VENDOR_HOLIDAY from "./getVendorHolidaySetting";
import GET_VENDOR_PRODUCT_TYPES from "./vendorProductType";
import STANDARD_DISCOUNT from "./discountSetting";
import GET_MANAGE_FULFILLMENT_SETTING from "./getManageFulfillmentSetting";
import { GET_MODULE, GET_ALL_USER_FOR_FEATURE, GET_FEATURE_BY_PARENT_ID } from "./feature";
import { GET_VENDOR_AUTH_CODE, GET_SELLER_PAYMENT } from "./connect";
import GET_INVOICE_SETTING from "./getInvoiceSetting";
import { GET_PACKING_SLIP_SETTING, GET_PACKING_SLIP_ACCESS } from "./getPackingSlipSetting";
import GET_VENDOR_LOCATION from "./getVendorLocation";
import GET_VENDOR_SETTING_LOCATION from "./getVendorSettingLocation";
import GET_GEOLOCATION_SETTING from "./getGeolocationSetting";

export {
  GET_ADDRESS,
  GET_ALL_USER_FOR_FEATURE,
  GET_ASSOCIATED_SELLER,
  GET_CATALOG,
  GET_COMMISSION,
  GET_CREDIT_NOTE_SETTING,
  GET_CURRENCY,
  GET_CURRENCY_PAYOUT,
  GET_CURRENCY_RATE,
  GET_DISCOUNT,
  GET_DISCOUNT_SETTING,
  GET_DISCOUNT_CODE_SETTING,
  GET_FEATURE_BY_PARENT_ID,
  GET_FULFILLMENT,
  GET_FULFILLMENT_TRACKING_DETAILS_SETTING,
  GET_GEOLOCATION_SETTING,
  GET_INVOICE_SETTING,
  GET_MANAGE_FULFILLMENT_SETTING,
  GET_MARKUP,
  GET_MODULE,
  GET_ORDER_ACS_SETTING,
  GET_ORDER_COMMISSION,
  GET_ORDER_EXPIRY_SETTING,
  GET_ORDER_EXPORT_SETTING,
  GET_ORDER_LIVE_CONNECT_SETTING,
  GET_ORDER_REPORT_SETTING,
  GET_PACKING_SLIP_ACCESS,
  GET_PACKING_SLIP_SETTING,
  GET_PRODUCT_FORM,
  GET_PRODUCT_REPORT_SETTING,
  GET_PRODUCT_SETTING,
  GET_PRODUCT_TAG_SETTING,
  GET_PRODUCT_TYPE,
  GET_PRODUCT_EXPORT_SETTING,
  GET_PAYMENT_EXPORT_SETTING,
  GET_PAYMENT_REPORT_SETTING,
  GET_REPORT_DATA,
  GET_SELLER_FULFILLMENT,
  GET_SELLER_LOCATION,
  GET_SELLER_PAYMENT,
  GET_SELLER_PLAN,
  GET_SELLER_PLAN_DETAILS,
  GET_SELLER_SETTING,
  GET_SENDABOX_CARRIER,
  GET_SHIPPO_CARRIER,
  GET_SHIPPY_PRO_CARRIER,
  GET_TERM,
  GET_TAX_SETTING,
  GET_TAX_RATE_SETTING,
  GET_TRANSACTION_FEE,
  GET_USER,
  GET_USER_DATABASE_SETTING,
  GET_VENDOR_AND_PRODUCT_TYPES,
  GET_VENDOR_AUTH_CODE,
  GET_VENDOR_BRAND_LIST,
  GET_VENDOR_DISCOUNT_SETTING,
  GET_VENDOR_EXPORT_SETTING,
  GET_VENDOR_FULFILLMENT,
  GET_VENDOR_HOLIDAY,
  GET_VENDOR_PRODUCT_TYPES,
  GET_VENDOR_LOCATION,
  GET_VENDOR_SETTING_LOCATION,
  STANDARD_DISCOUNT,
};
