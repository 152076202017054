import styled from "styled-components";

const OrderAnalyticStyle = styled.div`
  .Polaris-Card {
    text-align: center;
    padding: 3rem 2rem;
    min-width: 180px;
    border: 1px solid #ddd;
    strong {
      font-size: 2.3rem;
    }
  }
  > .Polaris-Card__Section {
    padding: 45px;
  }
  .graph-title {
    display: block;
    text-align: center;
  }
  .graph-heading {
    margin: 45px 0 25px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
  canvas {
    max-width: 270px;
    margin-bottom: 10px;
  }
  /* @page {
    size: landscape;
    margin: 0mm;
  } */
`;

const ProductAnalyticStyle = styled.div`
  .Polaris-Card {
    text-align: center;
  }
  .Polaris-Stack__Item .Polaris-Card {
    min-width: 200px;
    min-height: 100px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
  }
  .Polaris-Stack__Item img {
    height: 50px;
    width 50px;
  }
  .Polaris-Card .Polaris-Card__Section .Polaris-Card__SectionHeader h3, 
  .graph-heading {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 20px 0;
  }
  .graph-heading {
    margin: 45px 0 25px;
    font-weight: 500;
  }
  .Polaris-Stack--distributionEqualSpacing {
    display: flex;
    justify-content: space-around;
  }
  canvas {
    max-width: 270px;
    margin-bottom: 10px;
  }
  .graph-title {
    display: block;
    text-align: center;
    font-size: 20px;
  }
`;

const AnalyticsTabsHeader = styled.div`
  .tab-header-actions > .Polaris-Button {
    margin-left: 1.3rem;
  }
  .Polaris-Tabs__Wrapper {
    padding-right: 32rem;
  }
  .Polaris-Card {
    position: relative;
    .tab-header-actions {
      position: absolute;
      right: 2rem;
      top: 1.4rem;
      display: flex;
    }
  }
  .Polaris-Tabs {
    .Polaris-Tabs__Tab {
      line-height: 3rem;
    }
  }
`;

const StyledButton = styled.div`
  .btn {
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;

const StyledCSVButton = styled.div`
  .btn {
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;

export { OrderAnalyticStyle, ProductAnalyticStyle, AnalyticsTabsHeader, StyledButton, StyledCSVButton };
