import React, { useContext } from "react";

import { OperatorEditEmailTemplate } from "app/emailTemplate/modules/operator/features";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

const { userKey } = constant;
const { operator } = userKey;

const EditEmailTemplate = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorEditEmailTemplate />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const EditEmailTemplateComponent = getComponent(currentUserRole);
  return EditEmailTemplateComponent;
};

export default EditEmailTemplate;
