import { gql } from "apollo-boost";

const DELETE_USER_DATABASE_SETTING = gql`
  mutation deleteUserDatabaseSetting($input: DeleteDatabaseInput) {
    deleteUserDatabaseSetting(input: $input) {
      data
      status
      error
    }
  }
`;

const UPDATE_USER_DATABASE_SETTING = gql`
  mutation updateUserDatabaseSetting($input: UpdateDatabaseInput) {
    updateUserDatabaseSetting(input: $input) {
      data
      status
      error
    }
  }
`;

export { DELETE_USER_DATABASE_SETTING, UPDATE_USER_DATABASE_SETTING };
