import constant from "lib/constant/constant";

const { displayStatus } = constant;

class Status {
  constructor() {
    this.displayStatus = {
      accepted: displayStatus.ACCEPTED,
      alpha: displayStatus.ALPHA,
      approved: displayStatus.APPROVED,
      "auto-rejected": displayStatus["AUTO-REJECTED"],
      beta: displayStatus.BETA,
      bounced: displayStatus.BOUNCED,
      cancelled: displayStatus.CANCELLED,
      clicked: displayStatus.CLICKED,
      closed: displayStatus.CLOSED,
      deferred: displayStatus.DEFERRED,
      deleted: displayStatus.DELETED,
      deletedbyseller: displayStatus.DELETEDBYSELLER,
      delivered: displayStatus.DELIVERED,
      dispute: displayStatus.DISPUTE,
      dropped: displayStatus.DROPPED,
      fulfilled: displayStatus.FULFILLED,
      invited: displayStatus.INVITED,
      joined: displayStatus.JOINED,
      live: displayStatus.LIVE,
      "needs review": displayStatus.NEEDSREVIEW,
      new: displayStatus.NEW,
      "no-show": displayStatus["NO-SHOW"],
      opened: displayStatus.OPENED,
      paid: displayStatus.PAID,
      partial: displayStatus.PARTIAL,
      partiallyfulfilled: displayStatus.PARTIALLYFULFILLED,
      pending: displayStatus.PENDING,
      processed: displayStatus.PROCESSED,
      quotalimit: displayStatus.QUOTALIMIT,
      rejected: displayStatus.REJECTED,
      resent: displayStatus.RESENT,
      return: displayStatus.RETURN,
      reviewed: displayStatus.REVIEWED,
      revoked: displayStatus.CANCELLED,
      shipped: displayStatus.SHIPPED,
      spam: displayStatus.SPAM,
      unfulfilled: displayStatus.UNFULFILLED,
      unpaid: displayStatus.UNPAID,
    };
  }

  /**
   * @desc To get badge status
   * @param value status
   *
   * @return array
   *
   */
  getBadgeStatus = (value = "") => {
    const badgeStatus = value ? this.displayStatus[value.toLowerCase()] : "";
    return badgeStatus || value;
  };
}

export default new Status();
