import React, { useContext, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";

// import gql
import {
  GET_SHIPPING_OFFER,
  GET_SHIPPING_BAND,
  GET_SHIPPING_REGION,
  GET_SHIPPING_PRODUCT,
} from "app/shipping/apollo/queries";

// import constant
import constant from "lib/constant/constant";

// import subFeatures
import List from "./subFeatures/list/list";

// import propType
import { offerProp } from "./propTypes";

const OperatorShippingOffer = (props) => {
  const {
    setBanner,
    isVendorAllowed,
    productLakeLoading,
    productLakeError,
    productLakeDataError,
    productLakeDataResponse,
  } = props;
  const { currentUser: { ecommercePlatform = "" } = {} } = useContext(PrivateContext);

  const { loading: offerLoading, data: offerData, error: offerError, networkStatus, refetch } = useQuery(
    GET_SHIPPING_OFFER,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const { loading: bandLoading, data: bandData, error: bandError } = useQuery(GET_SHIPPING_BAND);
  const { loading: regionLoading, data: regionData, error: regionError } = useQuery(GET_SHIPPING_REGION);
  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_PRODUCT);

  const isLoading =
    offerLoading || networkStatus === 4 || bandLoading || regionLoading || productLoading || productLakeLoading;
  const isError = offerError || bandError || regionError || productError || productLakeError;

  useEffect(() => {
    if (isError) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(isError),
        status: constant.CRITICAL,
      });
    }
  }, [isError, setBanner]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBanner({
        isOpen: true,
        title: errorMessage,
        status: constant.CRITICAL,
      });
    },
    [setBanner]
  );

  // shipping offer
  const offerDataError = baseHelper.getResponseError(offerData, constant.gql.GET_SHIPPING_OFFERS);
  useEffect(() => {
    if (offerDataError) {
      setErrorBanner(offerDataError);
    }
  }, [offerDataError, setErrorBanner]);

  const offerDataResponse = baseHelper.getResponseData(offerData, constant.gql.GET_SHIPPING_OFFERS);
  const { shippingOffers = [] } = offerDataResponse || {};

  // shipping band
  const bandDataError = baseHelper.getResponseError(bandData, constant.gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (bandDataError) {
      setErrorBanner(bandDataError);
    }
  }, [bandDataError, setErrorBanner]);
  const bandDataResponse = baseHelper.getResponseData(bandData, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows = [] } = bandDataResponse || {};
  const shippingBands = shippingRows.map(({ _id: bandId, name }) => ({
    label: name,
    value: bandId,
  }));

  // shipping region/destination
  const destinationDataError = baseHelper.getResponseError(regionData, constant.gql.GET_REGION);
  useEffect(() => {
    if (destinationDataError) {
      setErrorBanner(destinationDataError);
    }
  }, [destinationDataError, setErrorBanner]);
  const destinationDataResponse = baseHelper.getResponseData(regionData, constant.gql.GET_REGION) || [];

  // shipping products
  const productDataError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      setErrorBanner(productDataError);
    }
  }, [productDataError, setErrorBanner]);
  const productDataResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCTS) || [];

  useEffect(() => {
    if (productLakeDataError) {
      setErrorBanner(productLakeDataError);
    }
  }, [productLakeDataError, setErrorBanner]);

  return (
    <List
      setBanner={setBanner}
      isVendorAllowed={isVendorAllowed}
      offers={shippingOffers}
      bands={shippingBands}
      products={ecommercePlatform ? productLakeDataResponse : productDataResponse}
      destinations={destinationDataResponse}
      loading={isLoading}
      refetch={refetch}
    />
  );
};

OperatorShippingOffer.propTypes = offerProp.type;

export default OperatorShippingOffer;
