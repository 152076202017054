import * as yup from "yup";

export const bucketName = (cms) =>
  yup.object().shape({
    bucket: yup.string().required(cms("message.error.bucketNameIsRequired")).nullable(),
  });

export const bucketAccessKey = (cms) =>
  yup.object().shape({
    access: yup.string().required(cms("message.error.bucketAccessIsRequired")).nullable(),
  });

export const bucketSecretKey = (cms) =>
  yup.object().shape({
    secret: yup.string().required(cms("message.error.bucketSecretIsRequired")).nullable(),
  });
