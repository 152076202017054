import React, { useState } from "react";
import { Layout, Collapsible } from "@shopify/polaris";

import { withErrorBoundary } from "lib/hoc";

import { Toast, Banner } from "lib/components";

import PackingSlipLabel from "./label/label";
import PackingSlipAccess from "./access/packingSlip";

const PackingSlip = () => {
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Layout>
        <Layout.Section>
          {banner.isOpen && (
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => dismissBanner()}
              />
            </Layout.Section>
          )}
          <Collapsible open>
            <PackingSlipAccess setMessage={setMessage} setBanner={setBanner} />
          </Collapsible>

          <Collapsible open>
            <PackingSlipLabel message={message} setMessage={setMessage} setBanner={setBanner} />
          </Collapsible>
          <Toast message={message} setToast={setMessage} timeout={5000} />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withErrorBoundary(PackingSlip);
