// import packages
import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  Collapsible,
  Caption,
  Form,
  FormLayout,
  Layout,
  PageActions,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";

// import context
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { useMutation, useQuery } from "@apollo/react-hooks";

// import components
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import queries
import { GET_TAX_RATE_SETTING } from "app/setup/apollo/queries";
import { UPDATE_TAX_RATE_SETTING } from "app/setup/apollo/mutations";
import { GET_VENDOR_BRAND_LIST } from "app/setup/apollo/index";

import OrderTax from "./taxSetting/orderTax";

const OrderTaxRate = () => {
  const { cms = {}, history } = useContext(PrivateContext);

  const [state, setState] = useState({
    count: 1,
    vendorOptions: [],
    taxes: [{ taxClass: null, vendorId: null, rate: null }],
  });
  const { vendorOptions, taxes, count } = state;

  const [submitEnabled, setSubmitEnable] = useState(false);

  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const [bannerMessage, setBannerMessage] = useState({ isOpen: false, status: "", title: "", message: "" });

  const [message, setMessage] = useState("");
  const [updateAt, setUpdateAt] = useState("");

  const { data: getVendorBrandList, loading: getVendorBrandListLoading } = useQuery(GET_VENDOR_BRAND_LIST);

  const { loading: taxRateLoading, data: getTaxRateSetting } = useQuery(GET_TAX_RATE_SETTING);

  const [updateTaxRateSetting, { loading: updateTaxRateSettingLoading }] = useMutation(UPDATE_TAX_RATE_SETTING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(getTaxRateSetting, constant.gql.GET_TAX_RATE_SETTING);
    const responseError = baseHelper.getResponseError(getTaxRateSetting, constant.gql.GET_TAX_RATE_SETTING);
    if (responseError) {
      setBanner({
        status: constant.CRITICAL,
        title: responseError,
        isOpen: true,
      });
    }
    if (responseData) {
      const { taxes: taxesData = [], updatedAt } = responseData;
      setUpdateAt(updatedAt);
      if (taxesData && taxesData.length) {
        setState({ ...state, count: taxesData.length, taxes: taxesData });
      }
    }
  }, [getTaxRateSetting]);

  useEffect(() => {
    const resData = baseHelper.getResponseData(getVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);
    const resError = baseHelper.getResponseError(getVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);
    if (resError) {
      setBanner({
        status: constant.CRITICAL,
        title: resError,
        isOpen: true,
      });
    }

    if (resData) {
      const vendorOptionsData = resData.map((item) => ({
        label: item.brandName,
        value: baseHelper.mongoIdAsString(item._id),
      }));
      setState({ ...state, vendorOptions: vendorOptionsData });
    }
  }, [getVendorBrandList]);

  if (getVendorBrandListLoading || taxRateLoading) {
    return <SkeletonAnnotated />;
  }

  const updateRateVal = (options, val, item) => {
    setSubmitEnable(true);
    const value = options === "rate" ? parseFloat(val || 0) : val;
    taxes[item][options] = value;
    setState({ ...state, taxes });
  };

  const add = () => {
    const { length } = taxes;
    if (length > 0) {
      const line = taxes[length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({ isOpen: true, status: "critical", title: cms("message.error.invalidRowDetails") });
        setSubmitEnable(false);
        return;
      }
    }

    const row = { taxClass: null, vendorId: null, rate: null };
    taxes.push(row);
    setState({ ...state, count: count + 1, taxes });
  };

  const removeItem = (item) => {
    setSubmitEnable(true);
    taxes.splice(item, 1);
    setState({ ...state, count: state.count - 1, taxes });
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });
  const dismissBannerMessage = () => setBannerMessage({ isOpen: false, status: "", title: "", message: "" });

  const handleSubmit = async () => {
    try {
      const res = await updateTaxRateSetting({
        variables: {
          input: { taxes },
        },
      });
      const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_TAX_RATE_SETTING);
      const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_TAX_RATE_SETTING);
      if (resError) {
        setBanner({
          status: constant.CRITICAL,
          isOpen: true,
          title: resError,
        });
      }
      if (resData) {
        const { taxes: taxData = [] } = resData;
        let isEmpty = false;
        let isInvalidValue = false;
        if (taxes && taxes.length) {
          taxes.map((row) => {
            const keys = Object.keys(row);
            // eslint-disable-next-line no-unused-vars
            let counter = 0;
            keys.map((key) => {
              if (row[key]) {
                counter += 1;
              }
              return null;
            });
            // eslint-disable-next-line no-param-reassign
            row.rate = parseFloat(row.rate || 0);
            if (row.rate > constant.value.MAX_PERCENTAGE) {
              isInvalidValue = true;
            }

            if ((counter && counter !== keys.length) || !row.rate) {
              isEmpty = true;
            }
          });
        }
        if (isInvalidValue) {
          setBannerMessage({ isOpen: true, status: "critical", title: cms("message.error.percentageUpperLimit") });
          setSubmitEnable(false);
          return;
        }
        if (isEmpty) {
          setBannerMessage({
            isOpen: true,
            status: "critical",
            title: cms("message.error.invalidRowDetails"),
          });
          setSubmitEnable(false);
          return;
        }
        setMessage(cms("message.success.taxRate"));
        setState({ ...state, taxValues: taxData });
      }
      setSubmitEnable(false);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  return (
    <>
      {bannerMessage.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerMessage.isOpen}
            status={bannerMessage.status}
            title={bannerMessage.title}
            isScrollTop={false}
            onDismiss={() => dismissBannerMessage()}
          >
            {bannerMessage.message}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("label.title")} description={cms("label.description")}>
        <Card
          title={[
            cms("section.orderTaxRate.title"),
            <TextStyle variation="subdued">
              {updateAt && <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>}
            </TextStyle>,
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.orderTaxRate.cardDescription")}</TextContainer>
            <br />
            <Collapsible
              open
              id="advancedCommission"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              {banner.isOpen && (
                <>
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    isScrollTop={false}
                    onDismiss={() => dismissBanner()}
                  >
                    {banner.message}
                  </Banner>
                  <br />
                </>
              )}
              <Form>
                <FormLayout>
                  <OrderTax
                    removeItem={removeItem}
                    updateRateVal={updateRateVal}
                    count={count}
                    taxes={taxes}
                    vendorOptions={vendorOptions}
                  />
                </FormLayout>
                <Button plain id="addLink" onClick={add}>
                  {cms("common.button.addMore")}
                </Button>
                <br />
                <ActionButton>
                  <PageActions
                    primaryAction={{
                      content: !updateAt.length ? cms("common.button.submit") : cms("common.button.update"),
                      onAction: () => handleSubmit(),
                      disabled: !submitEnabled,
                      loading: updateTaxRateSettingLoading,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </ActionButton>
              </Form>
            </Collapsible>
          </Card.Section>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OrderTaxRate), {
  feature: constant.ORDER_TAX_RATE,
});
