import { gql } from "apollo-boost";

const BULK_EDIT_UPLOAD = gql`
  mutation bulkEditProductUpload($input: ProductEditUpload) {
    bulkEditProductUpload(input: $input) {
      status
      data
      error
    }
  }
`;

export default BULK_EDIT_UPLOAD;
