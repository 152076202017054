import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Banner,
  Caption,
  Card,
  Heading,
  Layout,
  PageActions,
  Stack,
  RadioButton,
  TextStyle,
  // Thumbnail,
} from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";

import constant from "lib/constant/constant";
import { GET_CURRENCY_PAYOUT, UPDATE_PAYOUT } from "app/setup/apollo";
import { GET_VENDOR_BRAND_LIST } from "app/setup/apollo/queries";
import { PrivateContext } from "lib/context";
// eslint-disable-next-line import/no-cycle
import { Spinner } from "lib/components";
// eslint-disable-next-line import/no-cycle
import { baseHelper, errorHelper } from "lib/helpers";
// eslint-disable-next-line import/no-cycle
import { withErrorBoundary, withFeature } from "lib/hoc";

// eslint-disable-next-line import/no-cycle
import Add from "./components/add/add";

const CurrencyPayoutSetting = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { additionalStoreInfo, moneyFormat = "" } = currentUser;

  let shopifyCurrency;

  if (additionalStoreInfo) {
    const { shop: { currency = "" } = {} } = additionalStoreInfo || {};
    shopifyCurrency = currency;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isPayAllVendor, setIsPayAllVendor] = useState(true);
  const [isPaySelectedVendor, setIsPaySelectedVendor] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [updateAt, setUpdateAt] = useState();
  const [currencyName, setCurrencyName] = useState("");
  const [payout, setPayout] = useState([{ currencyCode: "", vendorId: "" }]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  // useQuery calls to fetch vendors list.
  const { error: errorVendorList, loading: loadingVendorList, data: dataVendorList } = useQuery(GET_VENDOR_BRAND_LIST);

  const vendorListResponseData = baseHelper.getResponseData(dataVendorList, constant.gql.GET_VENDOR_BRAND_LIST);
  const vendorListErrorData = baseHelper.getResponseError(dataVendorList, constant.gql.GET_VENDOR_BRAND_LIST);

  useEffect(() => {
    if (vendorListResponseData && vendorListResponseData.length) {
      const vendorNameList = vendorListResponseData
        .map(({ brandName, _id: vendorId }) => {
          if (!brandName) {
            return false;
          }
          return {
            label: brandName,
            value: vendorId,
          };
        })
        .filter((vendor) => vendor);

      setVendorList(vendorNameList);
    }
  }, [vendorListResponseData]);

  const { loading: payoutLoading, data: payoutData, refetch } = useQuery(GET_CURRENCY_PAYOUT);

  const payoutListResponseData = baseHelper.getResponseData(payoutData, constant.gql.GET_CURRENCY_PAYOUT);
  const payoutListErrorData = baseHelper.getResponseError(payoutData, constant.gql.GET_CURRENCY_PAYOUT);

  useEffect(() => {
    if (vendorListErrorData) {
      setBanner({ status: constant.CRITICAL, title: vendorListErrorData, isOpen: true });
    }
    if (errorVendorList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorList), isOpen: true });
    }

    if (payoutListErrorData) {
      setBanner({ status: constant.CRITICAL, title: payoutListErrorData, isOpen: true });
    }
    if (errorVendorList) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorList), isOpen: true });
    }
  }, [vendorListErrorData, errorVendorList, payoutListErrorData]);

  useEffect(() => {
    if (payoutListResponseData) {
      const { currency: currencyListData = {}, currencyName: shopifyCurrencyName = "" } = payoutListResponseData;
      const { rate = [], payment = {} } = currencyListData || {};
      const { isAllVendor = true, selectedVendor = [], updatedAt } = payment || {};
      const currencyListItem =
        rate &&
        rate.length &&
        rate
          .map(
            (value) =>
              !!value.isPayment && {
                label: value.currencyCode,
                value: value.currencyCode,
              }
          )
          .filter((item) => item);

      setCurrencyList(currencyListItem);
      setIsPayAllVendor(isAllVendor);
      setIsPaySelectedVendor(selectedVendor && !!selectedVendor.length);
      setPayout((selectedVendor && selectedVendor.length && selectedVendor) || [{ currencyCode: "", vendorId: "" }]);
      setCurrencyName(shopifyCurrencyName);
      setUpdateAt(updatedAt);
    }
  }, [payoutListResponseData]);

  useEffect(() => {
    if (payout && payout.length) {
      let vendorSelected = [];

      vendorSelected = payout.map((data) => data.vendorId.length && data.vendorId);
      const updatedVendorList = vendorList.map((item) => {
        const { value = "" } = item;
        const vendorData = { ...item };
        vendorData.disabled = vendorSelected.includes(value);
        return vendorData;
      });

      setVendorList(updatedVendorList || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  const handleAllVendorCheckBox = (value) => {
    if (isPaySelectedVendor) {
      setIsPaySelectedVendor(false);
    }
    setIsPayAllVendor(!value);
    setSubmitEnable(!value);
  };

  const handleSelectVendorCheckBox = (value) => {
    if (isPayAllVendor) {
      setIsPayAllVendor(false);
    }
    setIsPaySelectedVendor(!value);
    setSubmitEnable(!value);
  };

  const [updatePayout] = useMutation(UPDATE_PAYOUT);

  if (loadingVendorList || payoutLoading) {
    return <Spinner />;
  }

  const onSubmit = async () => {
    setIsLoading(true);
    const formData = {
      isAllVendor: isPayAllVendor || false,
      selectedVendor: (!isPayAllVendor && payout) || [],
    };

    try {
      const response = await updatePayout({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_PAYOUT);
      setIsLoading(false);

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.payout") });
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  const onSubmitCallback = () => {
    let isSubmit = true;
    // eslint-disable-next-line no-unused-expressions
    payout &&
      payout.forEach((element) => {
        if (!element.vendorId && isPaySelectedVendor) {
          setBanner({
            isOpen: true,
            title: cms("common.message.error.required", { item: cms("common.label.vendor") }),
            status: constant.CRITICAL,
          });
          isSubmit = false;
        } else if (!element.currencyCode && isPaySelectedVendor) {
          setBanner({
            isOpen: true,
            title: cms("common.message.error.required", { item: cms("label.currencyCode") }),
            status: constant.CRITICAL,
          });
          isSubmit = false;
        }
        return true;
      });
    if (payout.length === 0 && isPaySelectedVendor) {
      setBanner({
        isOpen: true,
        title: cms("message.error.select"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      onSubmit();
    }
  };

  return (
    <>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("section.payout.title")} description={cms("section.payout.description")}>
          <Card
            title={[
              cms("section.payout.subTitle"),
              updateAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <Stack>
                {/* <div className="flag">
                  <Thumbnail
                    source="https://cdn.shopify.com/shopifycloud/web/assets/v1/1ae10ee3926d9bbebd5cd1fec685e91a.svg"
                    size={constant.SMALL}
                  />
                </div> */}
                <Heading>
                  {currencyName && shopifyCurrency
                    ? `${currencyName} (${shopifyCurrency})`
                    : baseHelper.getCurrencyName(moneyFormat, constant.symbol, cms)}
                </Heading>
                <Badge status="info">{cms("label.currency")}</Badge>
              </Stack>
              <br />
              <RadioButton
                label={cms("label.payCurrency")}
                helpText={cms("helpText.payCurrency")}
                checked={isPayAllVendor}
                onChange={(newChecked) => handleAllVendorCheckBox(!newChecked)}
              />
              <RadioButton
                label={cms("label.selectPayCurrency")}
                helpText={cms("helpText.selectPayCurrency")}
                checked={isPaySelectedVendor}
                onChange={(newChecked) => handleSelectVendorCheckBox(!newChecked)}
              />
              {isPaySelectedVendor && (
                <Add
                  vendorList={vendorList}
                  cms={cms}
                  isPaySelectedVendor={isPaySelectedVendor}
                  currency={currencyList}
                  payout={payout}
                  setPayout={setPayout}
                  setSubmitEnable={setSubmitEnable}
                />
              )}
              <br />
              {updateAt && (
                <div className="manageVendorAccessButton">
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: onSubmitCallback,
                      disabled: !submitEnabled,
                      loading: isLoading,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </div>
              )}
            </Card.Section>
          </Card>
          {!updateAt && (
            <PageActions
              primaryAction={{
                content: cms("button.save"),
                onAction: onSubmitCallback,
                disabled: !submitEnabled,
                loading: isLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(CurrencyPayoutSetting), { feature: constant.CURRENCY_PAYOUT_SETTING });
