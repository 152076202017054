import React, { useEffect, useCallback, useContext, useState } from "react";
import { useMutation } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";

import {
  Caption,
  Card,
  DisplayText,
  Heading,
  Layout,
  Modal,
  PageActions,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { Banner, Spinner as Loader, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_VENDOR_PROFILE } from "app/vendors/apollo/queries";
import { REVIEW_VENDOR_STATUS } from "app/vendors/apollo/mutations";
import cmsvendorReview from "./cms/reviewCMS";

const VendorReview = () => {
  const [reviewVendorStatus, { loading: reviewVendorStatusLoading }] = useMutation(REVIEW_VENDOR_STATUS);
  const { history, match, cms } = useContext(PrivateContext);
  const {
    gql,
    imageURL: { EMPTY_STATE },
  } = constant;
  const { params } = match;
  const { id = "" } = params;
  const cmsData = cmsvendorReview(cms);
  const { button, caption, custom, hyper, message } = cmsData;
  const [active, setActive] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isRejectAndDelete, setIsRejectAndDelete] = useState(false);
  const [isApproved, setIsApproved] = useState("");
  const [rejectCountValue, setRejectCountValue] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    children: null,
  });
  const [messageToast, setMessageToast] = useState("");
  const [value, setValue] = useState("");
  const maxRejectCount = 3;

  const { data: vendorProfileData, loading: vendorProfileLoading } = useQuery(GET_VENDOR_PROFILE, {
    variables: { input: { vendorId: id } },
  });

  const currentVendor = baseHelper.getResponseData(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const resError = baseHelper.getResponseError(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const [values, setValues] = useState({
    brandHandle: "",
    brandName: "",
    checked: false,
    city: "",
    country: "",
    createdAt: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    postalCode: "",
    provinceCode: "",
    streetAddress: "",
  });

  const applicationTypeStatus = {
    approve: constant.APPROVED_SMALL,
    inReview: constant.IN_REVIEW_SMALL,
    onHold: constant.ON_HOLD,
    reject: constant.REJECTED,
  };

  const validateModalChange = (val) => {
    return val.replace(/\s/g, "").length && val;
  };

  const handleChange = useCallback(() => {
    setActive(!active);
    setIsDisabled(true);
    setValue("");
  }, [active]);
  const handleTextfield = useCallback((newValue) => {
    setValue(validateModalChange(newValue));
    setIsDisabled(false);
  }, []);

  useEffect(() => {
    if (currentVendor) {
      const { vendorRow = {} } = currentVendor;
      const {
        address,
        brand,
        brandName,
        city,
        country,
        createdAt,
        email = {},
        firstName,
        isApproved: approved = "",
        isReadOnly,
        lastName,
        phoneNumber,
        pinCode,
        provinceCode,
        rejectCount,
      } = vendorRow || {};
      const { address: emailAddress } = email;
      const { slug = "" } = brand || {};
      setValues({
        brandHandle: slug,
        brandName,
        checked: isReadOnly,
        city,
        country,
        createdAt,
        email: emailAddress,
        firstName,
        lastName,
        phoneNumber,
        postalCode: pinCode,
        provinceCode,
        streetAddress: address,
      });
      setIsApproved(approved);
      setRejectCountValue(rejectCount);
    }
    if (resError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: resError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVendor, resError]);

  const handleReviewVendor = async (status, toastMessage) => {
    try {
      const response = await reviewVendorStatus({
        variables: { input: { vendorId: id, status, reason: value } },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.REVIEW_VENDOR_STATUS);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.REVIEW_VENDOR_STATUS);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (responseData) {
        setMessageToast(toastMessage);
        setTimeout(() => {
          history.push("/providers");
        }, 5000);
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleSelect = (status, toastMessage) => {
    if (status !== constant.APPROVE) {
      setActive(!active);
    }
    handleReviewVendor(status, toastMessage);
  };

  const handleTitle = () => {
    const checkCount = maxRejectCount - rejectCountValue;
    if (checkCount === maxRejectCount) {
      return cms("banner.message.maxChance", { maxRejectCount });
    }
    if (checkCount === 1) {
      return cms("banner.message.onlyChance", { checkCount });
    }
    return cms("banner.message.minChance", { checkCount });
  };

  const handleModalTitle = () => {
    if (isOnHold) {
      return custom.onHoldVendor;
    }
    if (isRejectAndDelete) {
      return custom.rejectDeleteVendor;
    }
    return custom.rejectVendor;
  };
  const handleBannerTitle = () => {
    if (isApproved && isApproved === constant.APPROVE) {
      return `${values.brandName || baseHelper.ucFirst(constant.VENDOR)} ${cms("banner.message.already")} ${
        applicationTypeStatus[isApproved]
      }.`;
    }
    if (isApproved && isApproved === constant.REJECT) {
      return `${values.brandName || baseHelper.ucFirst(constant.VENDOR)} ${cms("banner.message.already")} ${
        applicationTypeStatus[isApproved]
      }.`;
    }
    if (!vendorProfileLoading && !isApproved) {
      return `${values.brandName ? values.brandName : baseHelper.ucFirst(constant.VENDOR)} ${cms(
        "banner.message.notExist"
      )}`;
    }
    return null;
  };

  return (
    <>
      <Layout.Section>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isApproved && (isApproved === constant.ONHOLD || isApproved === constant.IN_REVIEW) ? (
          <>
            <Banner isOpen status={constant.INFO} title={handleTitle()} />
          </>
        ) : vendorProfileLoading ? (
          <>
            <Layout.Section>
              <Loader />
            </Layout.Section>
          </>
        ) : (
          <>
            <Banner isOpen status={constant.CRITICAL} title={handleBannerTitle()} />
            <br />
            <Card sectioned>
              <div className="review-vendor-image">
                <img alt={constant.PROVIDER_IMAGE} src={EMPTY_STATE} />
              </div>
            </Card>
            <br />
            <a className="vendor-list Polaris-Link" href={"/providers" || false}>
              <TextStyle size={constant.SMALL}>{baseHelper.ucFirst(hyper.hyperContent)}</TextStyle>
            </a>
          </>
        )}
      </Layout.Section>
      {isApproved && (isApproved === constant.ONHOLD || isApproved === constant.IN_REVIEW) && banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
          <br />
          <DisplayText size={constant.LARGE}>{values.brandName}</DisplayText>
        </Layout.Section>
      )}
      <Layout.Section>
        <Stack vertical>
          {isApproved && (isApproved === constant.ONHOLD || isApproved === constant.IN_REVIEW) && (
            <>
              <Card
                sectioned
                title={[
                  `${!values.firstName ? "" : values.firstName} ${!values.lastName ? "" : values.lastName}`,
                  values.createdAt && (
                    <Caption>
                      <TextStyle variation="subdued">
                        {`${cms("caption.request")} ${baseHelper.formatDate(values.createdAt)}`}
                      </TextStyle>
                    </Caption>
                  ),
                  <Caption>
                    <TextStyle variation="subdued">
                      <strong>{`${caption.brandHandle} : `}</strong>
                      {`${values.brandHandle}`}
                    </TextStyle>
                    <br />
                    <TextStyle variation="subdued">
                      <strong>{`${caption.brandName} : `}</strong>
                      {`${!values.brandName ? "" : values.brandName}`}
                    </TextStyle>
                  </Caption>,
                ]}
              >
                <TextContainer spacing="tight">
                  <Heading>{caption.contactTilte}</Heading>
                  <p>{`${caption.firstName} : ${!values.firstName ? "" : values.firstName}`}</p>
                  <p>{`${caption.lastName} : ${!values.lastName ? "" : values.lastName}`}</p>
                  <p>{`${caption.email} : ${values.email}`}</p>
                  <p>{`${caption.phoneNumber} : ${!values.phoneNumber ? "" : values.phoneNumber}`}</p>
                  <p>{`${caption.address} : ${!values.streetAddress ? "" : values.streetAddress}`}</p>
                  <p>{`${caption.city} : ${!values.city ? "" : values.city}`}</p>
                  <p>{`${caption.provinceCode} : ${!values.provinceCode ? "" : values.provinceCode}`}</p>
                  <p>{`${caption.country} : ${!values.country ? "" : values.country}`}</p>
                  <p>{`${caption.postalCode} : ${!values.postalCode ? "" : values.postalCode}`}</p>
                </TextContainer>
              </Card>
            </>
          )}
        </Stack>
      </Layout.Section>
      <Layout.Section>
        {isApproved && (isApproved === constant.ONHOLD || isApproved === constant.IN_REVIEW) && (
          <>
            <PageActions
              primaryAction={{
                content: button.approveButton,
                onAction: () => handleSelect(constant.APPROVE, message.approveToast),
                loading: !value ? reviewVendorStatusLoading : false,
              }}
              secondaryActions={[
                {
                  content: button.rejectDeleteButton,
                  onAction: () => {
                    handleChange();
                    setIsRejectAndDelete(true);
                    setIsOnHold(false);
                  },
                  destructive: true,
                },
                {
                  content: button.onHoldButton,
                  onAction: () => {
                    handleChange();
                    setIsOnHold(true);
                    setIsRejectAndDelete(false);
                  },
                },
                {
                  content: button.rejectButton,
                  onAction: () => {
                    handleChange();
                    setIsOnHold(false);
                    setIsRejectAndDelete(false);
                  },
                  disabled: rejectCountValue === maxRejectCount - 1,
                },
              ]}
            />
          </>
        )}
        <Modal
          open={reviewVendorStatusLoading && value ? true : active}
          onClose={handleChange}
          title={handleModalTitle()}
          primaryAction={{
            disabled: !value || isDisabled,
            content: button.confirm,
            loading: constant.ONHOLD || constant.REJECT ? reviewVendorStatusLoading : false,
            onAction: () => {
              if (isOnHold) {
                handleSelect(constant.ONHOLD, message.onHoldToast);
              } else if (isRejectAndDelete) {
                handleSelect(constant.REJECT_DELETE, message.rejectDeleteToast);
              } else {
                handleSelect(constant.REJECT, message.rejectToast);
              }
            },
          }}
          secondaryActions={[
            {
              content: button.cancel,
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <TextContainer>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isOnHold
                    ? `${custom.description.holdDescription1} ${values.brandName || constant.VENDOR} ${
                        custom.description.holdDescription2
                      }`
                    : isRejectAndDelete
                    ? `${custom.description.rejectDeleteDescription1} ${values.brandName || constant.VENDOR}${
                        custom.description.rejectDeleteDescription2
                      }`
                    : `${custom.description.rejectDescription1} ${values.brandName || constant.VENDOR}${
                        custom.description.rejectDescription2
                      }`}
                </TextContainer>
              </Stack.Item>
              <Stack.Item fill>
                <TextField
                  label={custom.description.modalLabel}
                  value={value}
                  onChange={handleTextfield}
                  multiline={4}
                />
              </Stack.Item>
            </Stack>
          </Modal.Section>
        </Modal>
      </Layout.Section>
      <Toast message={messageToast} setToast={setMessageToast} timeout={5000} />
    </>
  );
};

export default withFeature(withErrorBoundary(VendorReview), {
  feature: constant.VENDOR_REVIEW_SETTING,
});
