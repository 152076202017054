import React, { useContext, useState, useEffect } from "react";
import { TextField, Card, Layout, FormLayout, PageActions } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { PublicContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import validate from "./yup";
import config from "configuration";

const SellerShopify = () => {
  const { cms } = useContext(PublicContext);

  const [shopSubDomain, setShopSubDomain] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState(true);
  const { CRITICAL, SHOP_SUB_DOMAIN } = constant;

  const handleValidation = async (field, value) => {   
    const validationError = await validate(field, value, cms);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (value) => {
    setShopSubDomain(value);
  };

  const onSubmit = async () => {
    const isAnyValidationError = Object.values(errorMessage).some((error) => error);

    if(isAnyValidationError) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: (errorMessage && errorMessage.shopSubDomain) || cms("section.operator.shop.message.error.invalidSubDomain"),
       });
    }

    if(!isAnyValidationError) {
      setBanner({
        isOpen: false,
        status: "",
        title: ""
      });
      const url = `${config.rootURL}/shopify/install?shop=${shopSubDomain}.myshopify.com`;
      window.location.href = url;
    }
  };

  return (
    <>
      <Banner
        title={banner.title}
        status={banner.status}
        isOpen={banner.isOpen}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
      <br />
      <Layout>
        <Layout.AnnotatedSection title={`${cms("section.operator.shop.title")}`} description={`${cms("section.operator.shop.description")}`}>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={`${cms("section.operator.shop.label.shopSubDomain")}*`}
                helpText={`${cms("section.operator.shop.helpText.shopSubDomain")}`}
                error={errorMessage && errorMessage.shopSubDomain}
                id="shopSubDomain"
                type={constant.TEXT}
                suffix={`${cms("section.operator.shop.suffix.shopSubDomain")}`}
                prefix={`${cms("section.operator.shop.prefix.shopSubDomain")}`}
                value={shopSubDomain}
                onChange={(value) => handleChange(value)}
                onBlur={() => handleValidation(SHOP_SUB_DOMAIN, shopSubDomain)}
              />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "resetPassword",
              content: cms("section.operator.shop.button.connect"),
              onAction: onSubmit,
            }}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default withErrorBoundary(SellerShopify);
