import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { SkeletonList } from "lib/components";
import { SingleColumnLayout } from "layout/private/components";

import { PrestaShopProducts } from "./provider/features";

const { userKey } = constant;
const { provider } = userKey;

const PrestaShopList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    if (role === provider) {
      return <PrestaShopProducts />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default PrestaShopList;
