import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import schema from "./schema";

const validate = async (field, value, message) => {
  const { validateValue, handlePromiseError } = yupHelper;
  const { typeSchema, amountSchema, amountLengthSchema } = schema(message);
  let promise = "";
  const { AMOUNT_LIMIT, SELECTED_STANDARD_TYPE, STANDARD_AMOUNT } = constant;
  switch (field) {
    case SELECTED_STANDARD_TYPE:
      promise = validateValue(typeSchema, { selectedStandardType: value });
      break;
    case STANDARD_AMOUNT:
      promise = validateValue(amountSchema, { standardAmount: value });
      break;
    case AMOUNT_LIMIT:
      promise = validateValue(amountLengthSchema, { standardAmount: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
