const userKey = {
  accountAdminLevel: "account-admin",
  admin: "admin",
  customer: "customer",
  operator: "operator",
  provider: "vendor", // The value of this key needs to be vendor as we are using it at multiple places.
  quoteCustomer: "quoteCustomer",
  seller: "seller",
  systemAdminLevel: "system-admin",
  systemAdminRole: "systemAdmin",
  vendor: "vendor",
};

const attachmentType = {
  DOC: "doc",
  DOCX: "docx",
  JPEG: "jpeg",
  JPG: "jpg",
  PDF: "pdf",
  PNG: "png",
};

const editor = {
  REMOVED_ITEM: "newdocument visualaid",
  PLUGIN: "link",
  TOOLBAR: "undo redo | bold italic | fontsizeselect | alignleft aligncenter alignright | code",
  THEME: "modern",
};

const imageURL = {
  csv: "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
  EMPTY_STATE: "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg",
  WELCOME: "https://ik.imagekit.io/marketcube/platform/welcome.jpg?tr=w-800",
};

const featureError = {
  hideFeature: "hideFeature",
  needPlanUpgrade: "needPlanUpgrade",
  roleNotAllowed: "roleNotAllowed",
};

const featureVersion = {
  alpha: "alpha",
  beta: "beta",
  live: "live",
};

const financialStatus = {
  AUTHORIZED: "authorized",
  INVOICE_PENDING: "Invoice Pending",
  PAID: "paid",
  PAID_PARTIALLY: "Partially Paid",
  PARTIALLY_PAID: "partially_paid",
  PARTIALLY_REFUNDED: "partially_refunded",
  PAYMENT_PENDING: "Payment Pending",
  PENDING: "pending",
  REFUNDED: "refunded",
  REFUNDED_PARTIALLY: "Partially Refunded",
  VOIDED: "voided",
};

const quoteStatus = {
  NEW: "New Request",
  APPROVED: "Approved Request",
  REJECTED: "Rejected Request",
};

const plan = {
  PRO: ["pro", "release-pro"],
  FREE: ["free", "release-free"],
  GROWTH: ["growth", "release-growth"],
  BOOTSTRAP: ["bootstrap", "release-bootstrap"],
  CUSTOM_PLAN: ["customPlan", "release-customPlan"],
};

const symbol = {
  CANADIAN: "CA$",
  DOLLAR: "$",
  EURO: "£",
  PERCENTAGE: "%",
  RUPEE: "₹",
  RUPEES: "Rs.",
};

const url = {
  ORDER: "/orders/view/:id",
  ORDER_LAKE: "/order-lake/view/:id",
};

const value = {
  MAX_AMOUNT: 99999,
  MAX_CHARACTER: 255,
  MAX_CSV_SIZE: 5 * 1024 * 1024,
  MAX_FLAT: 9999999999999,
  MAX_PERCENTAGE: 100,
  MAX_MARKUP_PERCENTAGE: 999,
  MIN_PRICE: 0,
  MIN_SEARCH_LENGTH: 3,
  ONE: 1,
  STEP: 0.01,
  ZERO: 0,
  FLOAT_ZERO: 0.0,
  MAX_MEASUREMENT: 999,
};

const weightValue = {
  LB: "lb",
  OZ: "oz",
  KG: "kg",
  GM: "g",
};

const color = {
  ATHENS_GRAY: "#DFE3E8",
  CLAY: "#212B36",
  GRAY: "#F4F6F8",
  LOBLOLLY: "#C4CDD5",
  RED: "#ff0000",
  WHITE: "#FFFFFF",
};

const currency = {
  CANADIAN: "CSD",
  DOLLAR: "USD",
  EURO: "ERU",
  RUPEE: "INR",
};

const fulfillmentTypes = [
  { label: "Dropship", value: "dropship" },
  {
    label: "Consignment Ship",
    value: "consignment",
  },
  { label: "Consignment Pickup", value: "pickup" },
  { label: "Click and Reserve", value: "reserve" },
  { label: "Click and Collect", value: "collect" },
];

const returnReason = {
  NOT_NEEDED: "notNeeded",
  NOT_AS_ADVERTISED: "notAsAdvertised",
  NOT_AS_SOLD: "notAsSold",
  FAULTY: "faulty",
  OTHER: "other",
};

const manageVendorDetails = {
  isField1Enabled: "isField1Enabled",
  isField2Enabled: "isField2Enabled",
  isField3Enabled: "isField3Enabled",
  isField4Enabled: "isField4Enabled",
  isField5Enabled: "isField5Enabled",
  label1: "label1",
  label2: "label2",
  label3: "label3",
  label4: "label4",
  label5: "label5",
};

const imageTypes = {
  THUMBNAIL: "thumbnail",
  PROFILE: "profile",
  HEADER_LOGO: "headerLogo",
  THUMBNAIL_WIDTH: "80",
  PROFILE_WIDTH: "200",
  HEADER_LOGO_WIDTH: "200",
};

const productVersion = {
  approveAll: "Accept and Publish",
  approveSelected: "Accept and Don't Publish",
  barcode: "Bar Code",
  category: "Category",
  collections: "Collections",
  comparePrice: "Compare Price",
  description: "Description",
  status: "Status",
  handle: "Handle",
  images: "Images",
  inventoryManagement: "Inventory Management",
  isShipping: "Is Shipping",
  isService: "Service",
  isTaxable: "Is Taxable",
  markAs: "Don't Accept Changes",
  measurement: "Measurement",
  no: "No",
  price: "Price",
  productCollection: "Product Collection",
  productType: "Product Type",
  quantity: "Quantity",
  reviewChanges: "Review Product Changes",
  sellers: "Seller's Value",
  sku: "Sku",
  tags: "Tags",
  title: "Title",
  variants: "Variants",
  vendor: "Vendor",
  vendors: "Vendor's Value",
  weight: "Weight",
  weightUnit: "WeightUnit",
  yes: "Yes",
};

const orderVersion = {
  actualQuantity: "actualQuantity",
  fulfillableQuantity: "fulfillableQuantity",
};

const days = {
  MON: "mon",
  TUE: "tue",
  WED: "wed",
  THU: "thu",
  FRI: "fri",
  SAT: "sat",
  SUN: "sun",
};

const daysArray = ["mon", "sun", "tue", "wed", "thu", "fri", "sat", "sun"];

const displayPlan = {
  FREE: "Free",
  PRO: "Pro+",
  GROWTH: "Pro",
  BOOTSTRAP: "Growth",
};

const displayStatus = {
  ACCEPTED: "Accepted",
  ALPHA: "Alpha",
  APPROVED: "Approved",
  AUTOREJECT: "Auto Rejected",
  BETA: "Beta",
  BOUNCED: "Bounced",
  CANCELLED: "Cancelled",
  CLICKED: "Clicked",
  CLOSED: "Closed",
  COLLECT: "Click and Collect",
  SERVICE: "Time-Based Services",
  DEFERRED: "Deferred",
  DELETED: "Deleted",
  DELETEDBYSELLER: "Deleted By Seller",
  DELIVERED: "Delivered",
  DISABLE: "Disable",
  DISPUTE: "Dispute",
  DROPPED: "Dropped",
  FULFILLED: "Fulfilled",
  INVITED: "Invited",
  JOINED: "Joined",
  LIVE: "Live",
  NEEDSREVIEW: "Review",
  NEW: "New",
  NOSHOW: "No Show",
  OPENED: "Opened",
  OUT_OF_DELIVERY: "out-for-delivery",
  PAID: "Paid",
  PARTIAL: "Partial",
  PARTIALLYFULFILLED: "Partially Fulfilled",
  PENDING: "Pending",
  PROCESSED: "Processed",
  PUBLISHED: "Published",
  QUOTALIMIT: "Qouta Exceeded",
  READY_FOR_DELIVERY: "ready-for-delivery",
  REJECTED: "Rejected",
  RESENT: "Resent",
  RESERVE: "Click and Reserve",
  RETURN: "Return",
  REVIEWED: "Reviewed",
  REVOKED: "Cancelled",
  SHIPPED: "Shipped",
  SPAM: "Spam Report",
  UNFULFILLED: "Unfulfilled",
  UNPAID: "Unpaid",
  AWAITINGDISPATCH: "Awaiting Dispatch",
};

const onboardingSteps = {
  ABOUT: "about",
  ADD_PROVIDER: "addProvider",
  ADD_PRODUCT: "product",
  APP_PERMISSION: "appPermission",
  COMMISSION: "commission",
  FINISH: "finish",
  PLAN: "plan",
  PENDING_APPROVAL: "pendingApproval",
  TERMS: "terms",
  TERM: "term",
  UPDATE_PROFILE: "updateProfile",
  WELCOME: "welcome",
};

const options = {
  OPTION1: "option1",
  OPTION2: "option2",
  OPTION3: "option3",
};

const providerList = {
  ACCEPTED: "accepted",
  ALL: "all",
  ALL_ORDERS: "All orders",
  ALL_PRODUCTS: "All products",
  APPROVE: "approve",
  APPROVED: "approved",
  BRAND: "brand",
  BRAND_PAGE_V2: "brandPageV2",
  BRANDNAME_ASC: "brandName_asc",
  BRANDNAME_DESC: "brandName_desc",
  BRANDPAGE: "brandPage",
  CLOSED: "closed",
  CREATED_ASC: "createdAt_asc",
  CREATED_AT: "createdAt",
  CREATED_DESC: "createdAt_desc",
  DELETE: "delete",
  DELETED: "deleted",
  DESC: "desc",
  DESCRIPTION: "description",
  DISABLE: "Disable Vendor",
  EMAIL: "email",
  EMAIL_ADDRESS: "test@gmail.com",
  ENABLE: "Enable Vendor",
  FULFILLMENT_STATUS: "fulfillmentStatus",
  IN_REVIEW: "inReview",
  LIKE: "like",
  LOGIN: "login",
  MARGIN_TOP: "10px",
  NEW: "new",
  ON_HOLD: "onHold",
  ORDER: "ORDER",
  ORDERNUMBER_ASC: "orderNumber_asc",
  ORDERNUMBER_DESC: "orderNumber_desc",
  ORDERS: "Orders",
  OTHER: "Other",
  PAYMENT: "Payment",
  PENDING: "pending",
  PRODUCTS: "Products",
  REJECT: "reject",
  REJECTED: "rejected",
  RETURN: "return",
  REVIEWED: "reviewed",
  SHIPPED: "shipped",
  SHIPPING: "Shipping",
  SOCIAL_LINK: "Social Link",
  STATUS: "Status",
  TAGGED_WITH: "taggedWith",
  VENDOR_IMAGE: "vendorImage",
  VENDOR_NO_IMAGE: "vendorNoImage",
  VIEW: "view",
  IN_REVIEW_FILTER: "inReview",
  ON_HOLIDAY_FILTER: "onHoliday",
  REJECT_FILTER: "reject",
  ON_HOLD_FILTER: "onHold",
};

const gql = {
  ACCEPT: "AcceptOrderExpiry",
  ACCEPT_ORDER_EXPIRY: "acceptOrderExpiry",
  ACCESS_DASHBOARD: "accessDashboard",
  ADD_ADDRESS: "addAddress",
  ADD_PRODUCT: "addProduct",
  ADD_LAKE_VARIANT: "addLakeVariant",
  ADD_PRODUCT_SERVICE: "addProductService",
  ADD_PRODUCT_ATTACHMENT: "addProductAttachment",
  ADD_PRODUCT_FULFILLMENT: "addProductFulfillment",
  ADD_PRODUCT_IMAGE: "addProductImage",
  ADD_PRODUCT_INFORMATION: "addProductInformation",
  ADD_PRODUCT_LAKE: "addProductLake",
  ADD_PRODUCT_LAKE_VARIANT: "addProductLakeVariant",
  ADD_PRODUCT_INVENTORY: "addProductInventory",
  ADD_PRODUCT_METAFIELD: "addProductMetafield",
  ADD_PRODUCT_VARIANT: "addProductVariant",
  ADD_SELLER_PLAN: "addSellerPlan",
  ADD_VARIANT: "addVariant",
  ADVANCE_ACCEPT_ORDER_EXPIRY: "advanceAcceptOrderExpiry",
  ADVANCE_PICKUP_ORDER_EXPIRY: "advancePickupOrderExpiry",
  ADVANCE_ACCEPT_ACCESS: "advanceVendorAccess",
  APPROVE_ALL_CHANGES: "approveAllProductChanges",
  APPROVE_ALL_PRODUCT_LAKE_CHANGES: "approveAllProductLakeChanges",
  APPROVE_SELLER_CHANGES: "approveSellerChanges",
  APPROVE_LAKE_SELLER_CHANGES: "approveLakeSellerChanges",
  ATTACH_UPLOAD: "attachUpload",
  BRAND_LOGO: "getBrandDetails",
  BULK_ACCOUNT: "bulkAccount",
  BULK_ACCEPT: "bulkAccept",
  BULK_REJECT: "lineRefund",
  BULK_EDIT_PRODUCT: "bulkEditProduct",
  BULK_EDIT_PRODUCT_CSV: "bulkEditProductUpload",
  BULK_INVITATION_BY_DATA: "bulkInvitationByData",
  FULFILL_ORDER_LAKE_ITEM: "fulfillOrderLakeItem",
  BULK_INVITATION_DATA: "bulkInvitationByData",
  BULK_ORDER_EDIT: "bulkEdit",
  BULK_ORDER_FULFILL: "bulkFulfill",
  BULK_PRODUCT_CSV: "bulkProductUpload",
  BULK_EDIT_PRODUCT_CSV: "bulkEditProductUpload",
  BULK_PRODUCT: "bulkProduct",
  BULK_PRODUCT_LAKE: "bulkProductLake",
  BULK_SELLER_ACTION: "bulkSellerAction",
  BULK_SUPPLIER_ACTION: "bulkSupplierAction",
  BULK_SUPPLIER_CSV: "bulkSupplierUsingCsv",
  BULK_UPDATE_VARIANT: "bulkUpdateVariant",
  CHANGE_PASSWORD: "changePassword",
  CHECKOUT_STRIPE_PLAN: "checkoutStripePlan",
  CHECK_ORDER_LINES: "checkOrderLines",
  CHECK_ORDER_LAKE_LINES: "checkOrderLakeLines",
  CHECK_RESET_TOKEN: "checkResetToken",
  CONNECT_PAYPAL_SELLER: "connectPaypalSeller",
  CONNECT_PAYPAL: "connectPaypal",
  CONNECT_PAYPAL_VENDOR: "connectPaypalVendor",
  CONNECT_STRIPE: "connectStripe",
  CONNECT_STRIPE_VENDOR: "connectStripeVendor",
  CONNECT_WIX_SHOP: "connectWixShop",
  CONNECT_MAGENTO_SHOP: "connectMagentoShop",
  CONNECT_PRESTASHOP: "connectPrestaShop",
  CREATE_ASSOCIATION: "createAssociation",
  CREATE_BAND: "createShippingBand",
  CREATE_FREE_PLAN: "createFreePlan",
  CREATE_PLAN: "createPlan",
  CREATE_SHIPPING_OFFER: "createShippingOffer",
  CREATE_SHIPPING_RULE: "createShippingRule",
  CREATE_SHOPIFY_CHARGE: "createShopifyCharge",
  CREATE_TASK: "createTask",
  CREATE_VENDOR: "createVendor",
  CREATE_API_REQUEST_ACCESS: "createApiRequestAccess",
  CREATE_PAYE_API_REQUEST_ACCESS: "createPayeApiRequestAccess",
  CREATE_VENDOR_CUSTOM_STRIPE_ACCOUNT: "createVendorCustomStripeAccount",
  CUSTOMER: "customer",
  CUSTOMIZE_FONT: "customizeFont",
  CREATE_SERVICE_OPTION: "createServiceOption",
  DELETE_ORDER_ACS_SETTING: "deleteACSSetting",
  DELETE_ADDRESS: "deleteAddress",
  DELETE_BAND: "deleteShippingBand",
  DELETE_LAKE_VARIANT: "deleteLakeVariant",
  DELETE_ORDER_ACS_SETTING: "deleteACSSetting",
  DELETE_PRODUCT_IMAGE: "deleteProductImage",
  DELETE_PRODUCT_ATTACHMENT: "deleteProductAttachment",
  DELETE_SHIPPING_OFFER: "deleteShippingOffer",
  DELETE_SHIPPING_RULE: "deleteShippingRule",
  DELETE_USER_DATABASE_SETTING: "deleteUserDatabaseSetting",
  DELETE_VARIANT: "deleteVariantById",
  DELETE_VENDOR_ASSOCIATION: "deleteVendorAssociation",
  DIGEST_SETTING: "digestSetting",
  DISABLE_ORDER_CONNECT: "disableOrderConnect",
  DISCONNECT_BIGCOMMERCE_SHOP: "disconnectBigcommerceShop",
  DISCONNECT_PAYPAL: "disconnectPaypal",
  DISCONNECT_SHOP: "disconnectShop",
  DISCONNECT_STRIPE: "disconnectStripe",
  DISCONNECT_STRIPE: "disconnectStripe",
  DISCONNECT_WOOCOMMERCE_SHOP: "disconnectWooCommerceShop",
  DISCONNECT_WIX_SHOP: "disconnectWixShop",
  DISCONNECT_PRESTASHOP: "disconnectPrestaShop",
  DISCONNECT_MAGENTO_SHOP: "disconnectMagentoShop",
  DOWNLOAD_LAKE_SHIPPING_LABEL: "downloadLakeShippingLabel",
  DOWNLOAD_SHIPPING_LABEL: "downloadShippingLabel",
  EDIT_PRODUCT: "editProduct",
  EDIT_PRODUCT_INFORMATION: "editProductInformation",
  EDIT_PRODUCT_SERVICE: "editProductService",
  EDIT_VARIANT: "editVariant",
  ENABLE_DISABLE_API: "enableDisableApi",
  FORGET_PASSWORD: "forgetPassword",
  FETCH_VENDOR: "getUser",
  GENERATE_PASSWORD: "otp",
  GET_ORDER_ACS_SETTING: "getACSSetting",
  GET_ADDRESS: "getAddress",
  GET_ADDRESS_DETAILS: "getAddressDetails",
  GENERATE_LAKE_SHIPPING_LABEL: "generateLakeShippingLabel",
  GENERATE_SHIPPING_LABEL: "generateShippingLabel",
  GET_ACCOUNT_LIST: "getAccountList",
  GET_ASSOCIATED_SELLER: "getAssociatedSeller",
  GET_ACTIVITY: "getActivity",
  GET_ALL_METRICS: "getAllMetrics",
  GET_ALL_USER_FOR_FEATURE: "getAllUserForFeature",
  GET_ALL_USERS: "getAllUsers",
  GET_BAND: "getShippingBand",
  GET_BRAND_DETAILS_DATA: "getBrandDetails",
  GET_BRANDS: "getBrands",
  GET_BRAND_REPRORTING: "getBrandForReporting",
  GET_BULK_EMAIL_TEMPLATE: "getBulkEmailTemplate",
  GET_BULK_INVITED_USER: "getBulkInvitedUser",
  GET_BULK_LIST: "getBulkLog",
  GET_CATALOG: "getCatalog",
  GET_COMMISSION: "getCommission",
  GET_CONSIGNMENT_VENDOR_LIST: "getConsignmentVendorList",
  GET_LAKE_CONSIGNMENT_VENDOR_LIST: "getLakeConsignmentVendorList",
  GET_CONTENT: "getContent",
  GET_COUNT: "getCount",
  GET_KEY_TAKEAWAY_REPORT_LIST: "getKeyTakeAwayReportList",
  GET_LAKE_COUNT: "getLakeCount",
  GET_CREDIT_NOTE_LIST: "getCreditNoteList",
  GET_CREDIT_NOTE_SETTING: "getCreditNoteSetting",
  GET_CURRENCY: "getAllCurrency",
  GET_CURRENCY_PAYOUT: "getCurrencyPayoutSetting",
  GET_CURRENCY_RATE: "getCurrencyRateSetting",
  GET_CURRENT_PLAN: "getCurrentPlan",
  GET_CURRENT_USER: "getCurrentUser",
  GET_CUSTOM_DOMAIN_LIST: "getCustomDomainList",
  GET_CUSTOMER: "getCustomer",
  GET_DIGEST_SETTING: "getDigestSetting",
  GET_DISCOUNT: "getDiscount",
  GET_DISCOUNT_SETTING: "getSellerDiscountSetting",
  GET_DISCOUNT_CODE_SETTING: "getSellerDiscountCodeSetting",
  GET_EMAIL_TEMPLATE_BY_ID: "getEmailTemplateById",
  GET_EMAIL_TEMPLATE: "getEmailTemplate",
  GET_ERROR_LIST: "getErrorLogList",
  GET_EXPORT_FILTER: "getExportFilter",
  GET_FEATURE_BY_PARENT_ID: "getFeatureByParentId",
  GET_FEATURE: "getFeature",
  GET_FILTER_OPTIONS: "getFilterOptions",
  GET_FULFILLMENT: "getFulfillment",
  GET_GA_DETAIL: "getGADetail",
  GET_GEOLOCATION_SETTING: "getGeolocationSetting",
  GET_INTRODUCTORY_SELLER: "getIntroductorySeller",
  GET_INVOICE_SETTING: "getInvoiceSetting",
  GET_INVOICE_DATA: "getInvoiceData",
  GET_KEY_TAKEAWAY_REPORT_LIST: "getKeyTakeAwayReportList",
  GET_LAKE_PRODUCT_AND_SELLER_PRODUCT: "getLakeProductAndSellerProduct",
  GET_LAKE_SHIPPING: "getLakeShipping",
  GET_MAINTENANCE: "getMaintenance",
  GET_MANAGE_FULFILLMENT_SETTING: "getManageFulfillmentSetting",
  GET_METAFIELD_SETTING: "getMetafieldSetting",
  GET_TAX_RATE_SETTING: "getTaxRateSetting",
  GET_MARKUP: "getMarkup",
  GET_MANAGE_PRICE_SETTING: "getManagePriceSetting",
  GET_MODULE: "getModule",
  GET_NOTIFICATION: "getNotificationSetting",
  GET_ORDER_ACS_SETTING: "getACSSetting",
  GET_ORDER_COMMISSION: "getOrderCommission",
  GET_ONBOARD_ACTIVITY: "getOnboardActivity",
  GET_OPERATOR_SETTING: "getSellerSetting",
  GET_ORDER_ACS_SETTING: "getACSSetting",
  GET_ORDER_DETAILS: "getOrderDetails",
  GET_ORDER_EXPIRY_SETTING: "getOrderExpirySetting",
  GET_ORDER_VERSION: "getOrderHistory",
  GET_ORDER_EXPORT_SETTING: "getOrderExportSetting",
  GET_ORDER_FULFILLMENT: "getOrderFulfillment",
  GET_ORDER_LAKE_FULFILLMENT: "getOrderLakeLineFulfillment",
  GET_ORDER_LAKE_LIST: "getOrderLakeList",
  GET_ORDER_LAKE_LINE_VIEW: "getOrderLakeLineView",
  GET_ORDER_LAKE: "getOrderLake",
  GET_ORDER_LAKE_LINE: "getOrderLakeLine",
  GET_ORDER_LINE_VIEW: "getOrderLineView",
  GET_ORDER_VERSION: "getOrderHistory",
  GET_ORDER_LINES_LIST: "getOrderLineList",
  GET_ORDER_LIST: "getOrderList",
  GET_ORDER_LINES: "getOrderLines",
  GET_ORDER_LIVE_CONNECT_SETTING: "getOrderLiveConnectSetting",
  GET_ORDER_METRICS: "getOrderMetrics",
  GET_ORDER_REPORT_LIST: "getOrderReportList",
  GET_ORDER_REFUND: "getOrderRefund",
  GET_ORDER_REPORT_LIST: "getOrderReportList",
  GET_ORDER_REPORT_SETTING: "getOrderReportSetting",
  GET_ORDER_VIEW: "getOrderView",
  GET_PACKING_SLIP_ACCESS: "getPackingSlipAccess",
  GET_PACKING_SLIP_SETTING: "getPackingSlipSetting",
  GET_PAYMENT_EXPORT_DATA: "getPaymentExportData",
  GET_PAYMENT_EXPORT_SETTING: "getPaymentExportSetting",
  GET_PAYMENT_METRICS: "getPaymentMetrics",
  GET_PAYMENT_REPORT_SETTING: "getPaymentReportSetting",
  GET_PAYMENT_TRANS_LIST: "getPaymentTransList",
  GET_PAYMENT_LAKE_LIST: "getPaymentLakeList",
  GET_PLANS: "getPlans",
  GET_PRODUCT: "getProduct",
  GET_PRODUCT_EXPORT_FILTER: "getProductExportFilter",
  GET_PRODUCT_DETAILS: "getProductDetail",
  GET_PRODUCT_LISTING_SETTING: "getProductListingSetting",
  GET_LAKE_PRODUCTS: "getLakeProducts",
  GET_PRODUCTS: "getProducts",
  GET_LAKE_PRODUCTS: "getLakeProducts",
  GET_PRODUCT_EXPORT_SETTING: "getProductExportSetting",
  GET_PRODUCT_AND_SELLER_PRODUCT: "getProductAndSellerProduct",
  GET_PRODUCT_BY_IDS: "getProductbyIds",
  GET_PRODUCT_FORM_SETTING: "getProductFormSetting",
  GET_PRODUCT_LAKE: "getProductLake",
  GET_PRODUCT_LIST: "getProductList",
  GET_PRODUCT_LAKE_LIST: "getProductLakeList",
  GET_PRODUCT_LIST_SELLER: "getProductList",
  GET_PRODUCT_METRICS: "getProductMetrics",
  GET_PRODUCT_PHYSICAL_SETTING: "getProductPhysicalSetting",
  GET_PRODUCT_REPORT_LIST: "getProductReportList",
  GET_PRODUCT_REPORT_SETTING: "getProductReportSetting",
  GET_PRODUCT_SELLER_PRODUCT: "getProductAndSellerProduct",
  GET_PRODUCT_LAKE_VARIANT: "getProductLakeVariant",
  GET_PRODUCT_SETTING: "getProductSetting",
  GET_PRODUCT_TAGS: "getProductTags",
  GET_PRODUCT_TAG_SETTING: "getProductTagSetting",
  GET_PRODUCT_TYPE: "getProductType",
  GET_PRODUCT_VERSION: "getProductVersion",
  GET_ORDER_VERSION: "getOrderHistory",
  GET_QUOTE_LIST: "getQuoteList",
  GET_REGION: "getRegion",
  GET_REPORT_DATA: "getReportData",
  GET_SALES_METRICS: "getSalesMetrics",
  GET_SCHEDULE_PAYMENT: "getSchedulePaymentSetting",
  GET_SELLER_ASSOCIATION: "getSellerAssociation",
  GET_SELLER_BRANDING: "getSellerBranding",
  GET_SELLER_DATA: "getSellerData",
  GET_SELLER_DEFAULT_ADDRESS: "getSellerDefaultAddress",
  GET_SELLER_FEATURE: "getSellerFeature",
  GET_SELLER_FULFILLMENT: "getSellerFulfillment",
  GET_SELLER_LIST: "getSellerList",
  GET_SELLER_LOCATION: "getSellerLocation",
  GET_SELLER_PLAN_DETAILS: "getSellerPlanDetails",
  GET_SELLER_PLAN_LIST: "getSellerPlanList",
  GET_SELLER_PAYMENT: "getSellerPayment",
  GET_SELLER_SETTING: "getSellerShippingSetting",
  GET_SELLER_SHIPPING: "getSellerShipping",
  GET_SENDABOX_CARRIER: "getSendaboxCarrier",
  GET_SESSION_LIST: "getSessionList",
  GET_SHIPPING_BAND: "getShippingBand",
  GET_SHIPPING_OFFERS: "getShippingOffers",
  GET_SHIPPING_RULE: "getShippingRules",
  GET_SHIPPING: "getShipping",
  GET_SHIPPING_LABEL_STATUS: "getShippingLabelStatus",
  GET_SHIPPO_CARRIER: "getShippoCarrier",
  GET_SHIPPY_PRO_CARRIER: "getShippyProCarrier",
  GET_TAX_SETTING: "getTaxSetting",
  GET_TEMP_PRODUCT_LIST: "getTempProductList",
  GET_TERM: "getTermSetting",
  GET_TRANSACTION_FEE_TEXT: "getTransactionFee",
  GET_UPLOAD: "getUpload",
  GET_USER: "getUser",
  GET_USER_DATABASE_SETTING: "getUserDatabaseSetting",
  GET_VENDOR_FORM_LABEL: "getVendorFormLabel",
  GET_VENDOR_AUTH_CODE: "getVendorAuthCode",
  GET_VENDOR_BRAND_LIST: "getVendorBrandList",
  GET_VENDOR_DETAIL: "getVendorDetail",
  GET_VENDOR_DISCOUNT_SETTING: "getVendorDiscountSetting",
  GET_VENDOR_EXPORT_SETTING: "getVendorExportSetting",
  GET_VENDOR_HOLIDAY: "getVendorHolidaySetting",
  GET_VENDOR_INVOICE: "getVendorInvoice",
  GET_VENDOR_LIST: "getVendorList",
  GET_VENDOR_LOCATION: "getVendorLocation",
  GET_VENDOR_SETTING_LOCATION: "getVendorSettingLocation",
  GET_VENDOR_METRICS: "getVendorMetrics",
  GET_VENDOR_ORDER_LINE: "getVendorOrderLine",
  GET_VENDOR_PRODUCT: "getVendorAndProductTypes",
  GET_VENDOR_PROFILE: "getVendorProfile",
  GET_VENDOR_PROFILE_DATA: "getVendorProfileData",
  FETCH_VENDOR_FORM_SETTING: "getVendorFormSetting",
  GET_VENDOR_FIELD: "getVendorField",
  GET_VENDOR_ACCESS_SETTING: "getVendorAccessSetting",
  GET_WHOLESALE_SETTING: "getWholesaleSetting",
  GET_SERVICE: "getService",
  HOLIDAY_SETTING: "holidaySetting",
  INVITE_COUNT: "inviteCount",
  INVOICE: "invoice",
  IS_BIGCOMMERCE_STORE_CONNECTED: "isBigCommerceStoreConnected",
  IS_STORE_CONNECTED: "isStoreConnected",
  IS_WOOCOMMERCE_STORE_CONNECTED: "isWooCommerceConnected",
  IS_WIX_STORE_CONNECTED: "isWixStoreConnected",
  LINE_REFUND: "lineRefund",
  LINE_RETURN: "lineReturn",
  LOGIN_BIGCOMMERCE_SELLER: "loginBigCommerceSeller",
  LOGIN_WOOCOMMERCE_SELLER: "loginWooCommerceSeller",
  LOGOUT: "logout",
  MANAGE_DISCOUNT: "manageDiscount",
  MANAGE_PRODUCT_LISTING: "manageProductListing",
  MANAGE_SUBSCRIPTION: "manageSubscription",
  ORDER_COUNT: "orderCount",
  ORDER_EXPIRY: "orderExpiry",
  PAY_VENDOR: "payVendor",
  PAY_LAKE_VENDOR: "payLakeVendor",
  PAYMENT_COUNT: "paymentCount",
  PAYPAL_CONNECTED: "paypalConnected",
  PICKUP_ORDER_EXPIRY: "pickupOrderExpiry",
  PRODUCT_COUNT: "productCount",
  SHOPIFY_PRODUCT_COUNT: "shopifyProductCount",
  PROXY_LOGIN: "proxyLogin",
  QUOTE_APPROVAL: "quoteApproval",
  REGISTER_MARKETCUBE_SELLER: "registerMarketcubeSeller",
  READY_TO_COLLECT: "updateCollectDetail",
  REGISTER_BY_INVITE: "registerByInvite",
  REGISTER_BY_MOBILE: "registerByMobile",
  REGISTER: "register",
  REGISTER_BIGCOMMERCE_SELLER: "registerBigCommerceSeller",
  REGISTER_MARKETCUBE_SELLER: "registerMarketcubeSeller",
  REGISTER_WOOCOMMERCE_SELLER: "registerWooCommerceSeller",
  SCHEDULE_PAYMENT_TERM: "schedulePaymentTerm",
  RESEND_EMAIL: "resendEmail",
  RESEND_OTP_EMAIL: "reSendOtpEmail",
  RESET_PASSWORD: "resetPassword",
  REVIEW_VENDOR_ACCESS: "reviewVendorAccess",
  REVIEW_VENDOR_STATUS: "reviewVendorStatus",
  SAVE_BIGCOMMERCE_STORE_DETAILS: "saveBigCommerceStoreDetails",
  SAVE_SHIPPING: "saveShipping",
  SAVE_SHOPIFY_VENDOR: "saveShopifyVendor",
  SAVE_WIX_VENDOR_TOKEN: "saveWixVendorToken",
  SCHEDULE_PAYMENT_TERM: "schedulePaymentTerm",
  SHOPIFY_SELLER_LOGIN_KEY: "shopifyInstallAndSellerLogin",
  STRIPE_CONFIRM: "stripeConfirm",
  STRIPE_OPERATOR: "stripeOperator",
  OLD_TO_NEW_SHOPIFY_SELLER_LOGIN_KEY: "oldToNewShopifyInstallAndSellerLogin",
  SHOPIFY_VENDOR_LOGIN_KEY: "shopifyInstallAndVendorLogin",
  SEND_BULK_INVITE_BY_CSV: "sendBulkInviteByCSV",
  SEND_HELP_MESSAGE: "sendHelpMessage",
  SEND_QUOTE_EMAIL: "sendQuoteEmail",
  SELLER_COUNT: "sellerCount",
  SET_HUB_CONTACT_PROPERTIES: "setHubContactProperties",
  SET_ORDER_COMMISSION: "setOrderCommission",
  STRIPE_CONNECTED: "stripeConnected",
  SYN_INVENTORY_VENDOR: "syncInventoryVendor",
  SYNC_INVENTORY_VENDOR: "syncInventoryVendor",
  SYNC_LAKE_INVENTORY_VENDOR: "syncLakeInventoryVendor",
  SYNC_WOO_COMMERCE_PRODUCTS: "syncWooCommerceProducts",
  SYNC_ORDER_LAKE: "syncWooCommerceOrder",
  SYNC_ORDER: "syncOrder",
  SYNC_PRODUCT_CATALOG: "syncProductCatalog",
  SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT: "syncTempVendorBigcommerceProduct",
  SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT: "syncVendorLakeProduct",
  SYNC_SHOPIFY_PRODUCT: "syncShopifyProduct",
  SYNC_VENDOR_BIGCOMMERCE_PRODUCT: "syncVendorBigcommerceProduct",
  SYNC_VENDOR_WIX_PRODUCT: "syncVendorWixProduct",
  SYNC_VENDOR_MAGENTO_PRODUCT: "syncVendorMagentoProduct",
  SYNC_VENDOR_PRESTASHOP_PRODUCT: "syncVendorPrestaShopProduct",
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT: "syncWooCommerceVendorProduct",
  TAX: "tax",
  TOGGLE_AUTO_PAYOUT: "toggleAutoPayout",
  TOGGLE_DOMAIN: "toggleDomain",
  TOTAL: "total",
  UPDATE_ORDER_ACS_SETTING: "updateACSSetting",
  UPDATE_ABOUT: "updateAbout",
  UPDATE_LAKE_SHIPPING: "updateLakeShipping",
  UPDATE_BAND: "updateShippingBand",
  UPDATE_BIGCOMMERCE_PRODUCT_SYNC: "updateBigCommerceProductSync",
  UPDATE_BRANDING: "updateBranding",
  UPDATE_BUSINESS_INFO: "updateBusinessInfo",
  UPDATE_CHARGE_DETAILS: "updateChargeDetails",
  UPDATE_COMMISSION: "updateCommission",
  UPDATE_COMMISSION_TAX_SETTING: "updateCommissionTaxSetting",
  UPDATE_CREDIT_NOTE: "updateCreditNoteSetting",
  UPDATE_DEFAULT_ADDRESS: "updateDefaultAddress",
  UPDATE_DISCOUNT: "manageDiscount",
  UPDATE_DYNAMIC_DISCOUNT_SETTING: "manageSellerDiscountSetting",
  UPDATE_EXPRESS_PAYMENT_SETTING: "updateExpressPaymentSetting",
  UPDATE_GEOLOCATION_SETTING: "updateGeolocationSetting",
  UPDATE_DISCOUNT_CODE_SETTING: "manageDiscountCodeSetting",
  UPDATE_EMAIL_TEMPLATE: "updateEmailTemplate",
  UPDATE_FEATURE: "updateFeature",
  UPDATE_FULFILLMENT: "updateFulfillment",
  UPDATE_INVITATION_EMAIL_STATUS: "updateInvitationEmailStatus",
  UPDATE_INVOICE_LABEL_SETTING: "updateInvoiceLabelSetting",
  UPDATE_INVOICE_PREFIX_NUMBER: "updateInvoiceNumberPrefix",
  UPDATE_INVOICE_CUSTOM_NOTE: "updateInvoiceCustomNote",
  UPDATE_LAKE_VARIANT: "updateLakeVariant",
  UPDATE_MAINTENANCE: "updateMaintenance",
  UPDATE_MARKUP: "updateMarkup",
  UPDATE_MANAGE_PRICE_SETTING: "updateManagePriceSetting",
  UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING : "updateMarkupDiscountExportSetting",
  UPDATE_METAFIELD: "updateMetafield",
  UPDATE_TAX_RATE_SETTING: "updateTaxRateSetting",
  UPDATE_NOTIFICATION: "notificationSetting",
  UPDATE_ORDER_ACS_SETTING: "updateACSSetting",
  UPDATE_ORDER_LINE_DELIVERY_STATUS: "updateOrderLineDeliveryStatus",
  UPDATE_ORDER_EXPORT_SETTING: "updateOrderExportSetting",
  UPDATE_ORDER_LINE_STATUS: "updateOrderLineStatus",
  UPDATE_ORDER_LINE_TRACKING: "updateOrderLineTracking",
  UPDATE_ORDER_LIVE_CONNECT_SETTING: "updateOrderLiveConnectSetting",
  UPDATE_ORDER_NOTE: "updateOrderNote",
  UPDATE_ORDER_SETTING: "updateOrderSetting",
  UPDATE_ORDER_VERSION: "updateOrderVersion",
  UPDATE_PACKING_SLIP_ACCESS: "updatePackingSlipAccess",
  UPDATE_PACKING_SLIP_LABEL: "updatePackingSlipLabelSetting",
  UPDATE_PAYMENT_EXPORT_SETTING: "updatePaymentExportSetting",
  UPDATE_PAYOUT: "updateCurrencyPayoutSetting",
  UPDATE_PHYSICAL_SETTING: "updateProductPhysicalSetting",
  UPDATE_PLAN: "updatePlan",
  UPDATE_RATE: "updateCurrencyRateSetting",
  UPDATE_PLAN_DETAILS: "updatePlanDetails",
  UPDATE_PRODUCT_ATTACHMENT_SETTING: "updateProductAttachmentSetting",
  UPDATE_PRODUCT_FORM_SETTING: "updateProductFormSetting",
  UPDATE_PRODUCT_EXPORT_SETTING: "updateProductExportSetting",
  UPDATE_ORDER_VERSION: "updateOrderVersion",
  UPDATE_PRODUCT_LAKE_IMAGE: "updateProductLakeImage",
  UPDATE_PRODUCT_SETTING: "updateProductSetting",
  UPDATE_PRODUCT_VERSION: "updateProductVersion",
  UPDATE_PRODUCT_SYNC: "updateProductSync",
  UPDATE_PROFILE_SYSTEM_ADMIN: "updateSystemAdminProfile",
  UPDATE_PROFILE: "updateVendorProfile",
  UPDATE_SELLER_FEATURE: "updateSellerFeature",
  UPDATE_SELLER_LOCATION: "updateSellerLocation",
  UPDATE_SELLER_PLAN: "updateSellerPlan",
  UPDATE_SELLER_PROFILE: "updateSellerProfile",
  UPDATE_SHIPPING_BAND: "updateShippingBand",
  UPDATE_SELLER_LOCATION: "updateSellerLocation",
  UPDATE_SHIPPING_OFFER: "updateShippingOffer",
  UPDATE_SHIPPING_RULE: "updateShippingRule",
  UPDATE_SHIPPING_TAX_SETTING: "updateShippingTaxSetting",
  UPDATE_TAG_SETTING: "updateTagSetting",
  UPDATE_TAX_INFO: "updateTaxInfo",
  UPDATE_TRACKING_DETAIL: "updateTrackingDetails",
  UPDATE_TRANSACTION_FEE_SETTING_TEXT: "setTransactionFee",
  UPDATE_USER_DATABASE_SETTING: "updateUserDatabaseSetting",
  UPDATE_VARIANT_IMAGE: "updateVariantImage",
  UPDATE_VENDOR_FORM_LABEL: "updateVendorFormLabel",
  UPDATE_VENDOR_AVAILABILITY: "updateVendorAvailability",
  UPDATE_VENDOR_DISCOUNT_SETTING: "manageVendorDiscountSetting",
  UPDATE_VENDOR_FIELD: "updateVendorField",
  UPDATE_VENDOR_LOCATION: "updateVendorLocation",
  UPDATE_VENDOR_TRACKING: "updateVendorTracking",
  UPDATE_WHOLESALE_SETTING: "updateWholesaleSetting",
  UPDATE_WIX_PRODUCT_SYNC: "updateWixProductSync",
  UPDATE_PAYMENT_REPORT_SETTING: "updatePaymentReportSetting",
  UPDATE_ORDER_REPORT_SETTING: "updateOrderReportSetting",
  UPDATE_PRODUCT_REPORT_SETTING: "updateProductReportSetting",
  UPDATE_PRODUCT_LAKE_INVENTORY: "updateProductLakeInventory",
  UPDATE_PRODUCT_LAKE_INFORMATION: "updateProductLakeInformation",
  UPDATE_PRODUCT_LAKE_FULFILLMENT: "updateProductLakeFulfillment",
  UPDATE_USER_DATABASE_SETTING: "updateUserDatabaseSetting",
  UPDATE_VENDOR_EXPORT_SETTING: "updateVendorExportSetting",
  UPDATE_VENDOR_FULFILLMENT: "updateVendorFulfillment",
  UPLOAD_FILE: "uploadFile",
  UPLOAD_IMAGE: "uploadImage",
  VENDOR_ACCESS: "vendorAccess",
  VENDOR_COUNT: "vendorCount",
  VERIFY_EMAIL: "verifyEmail",
  VERIFY_OTP: "verifyOtp",
  VERIFY_VENDOR_CUSTOM_STRIPE_ACCOUNT: "verifyVendorCustomStripeAccount",
};

const constant = {
  ABOUT_LIVE_CONNECT: "https://support.marketcube.io/article/200-marketcube-app-installation-for-vendors",
  ABOUT_OPERATOR_APP_MIGRATE: "https://drive.google.com/file/d/122h-rEMv4JMKQgTZei2yqRq70NljTAi7/view?usp=sharing",
  ABOUT_VENDOR_APP_MIGRATE: "https://drive.google.com/file/d/1PXEHzssZMyj0rG4Beytink5upoexnL5M/view?usp=sharing",
  ACCEPT: "accept",
  ACCEPTED: "accepted",
  ACCEPTED_ORDER: "acceptOrder",
  ACCEPT_LABEL: "Accept",
  ACCEPT_ORDER: "Accept Order",
  ACCESS_KEY: "accessKey",
  ACCESS_KEY_ID: "id",
  ACCESS_TOKEN: "accessToken",
  ACCOUNT_LICENSE_NUMBER: "accessLicenseNumber",
  ACCOUNT_NUMBER: "accountNumber",
  ACCOUNT_TYPE: "accountType",
  ACKNOWLEDGE: "acknowledge",
  ACTION: "Actions",
  ACTIONS: "action",
  ACTIVE: "isActive",
  ACTIVITY_LIST: "activityList",
  ADDRESS: "address",
  CARRIER: "carrier",
  ADDRESS_BOOK: "addressBook",
  ADDRESS_BOOK_IMAGE: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
  ADDRESS_LABEL: "Address label",
  ADD_BAND: "addBands",
  ADD_LAKE_VARIANT: "addLakeVariant",
  ADD_PAYMENT: "addPayment",
  ADD_PRODUCT: "addProduct",
  ADD_PRODUCT_ATTACHMENT_SETTING: "addProductAttachmentSetting",
  ADD_SERVICE_VARIANT: "addServiceVariant",
  ADD_VARIANT: "addVariant",
  ADD_VENDOR: "addVendor",
  ADMIN_SCOPES_KEY: "adminScopes",
  ADVANCE: "advance",
  ADVANCE_COMMISSION: "advanceCommission",
  ADVANCE_CONSIGNMENT: "advanceConsignment",
  ADVANCE_CONSIGNMENT_PICKUP: "advanceConsignmentPickup",
  ADVANCE_DISCOUNT: "advanceDiscount",
  ADVANCE_DROPSHIP: "advanceDropship",
  ADVANCE_MAP_FILTER: {
    collection: "shopifyCatalogId",
    liveConnectedPlatform: "liveConnectedPlatform",
    sku: "sku",
    tag: "tags",
    title: "title",
    type: "productType",
    vendor: "vendor",
  },
  ADVANCE_MARKUP: "advanceMarkup",
  ADVANCE_PAYMENT_RETURN: "advancePaymentReturn",
  ADVANCE_SCHEDULE_PAYMENT: "advanceSchedulePayment",
  ADVANCE_PAYMENT_TERM: "advancePaymentTerm",
  ADVANCE_SCHEDULE_PAYMENT: "advanceSchedulePayment",
  ADVANCE_SHIPPING: "advanceShipping",
  ADVANCE_SHIPPING_PROCESS: "Advance",
  ADVANCE_VENDOR_ACCESS: "advanceVendorAccess",
  ADVANCE_VENDOR_PROFILE: "advanceVendorProfile",
  ALL: "all",
  ALL_TAB: "All",
  ALLOWED_CURRENCY: ["$", "€"],
  ALLOWED_DAY: "allowedDay",
  ALLOWED_KEYS: [
    "attachments",
    "title",
    "description",
    "images",
    "tags",
    "variants",
    "productType",
    "weight",
    "length",
    "width",
    "height",
    "customInfo",
    "shopifyCatalogId",
    "customMetafields",
  ],
  ALLOWED_INFORMATION_KEYS: [
    "description",
    "discount",
    "productType",
    "price",
    "comparePrice",
    "tags",
    "title",
    "catalog",
  ],
  ALLOWED_SERVICE_VARIANT_OPTIONS: 1,
  ALL_CUMULATIVE: "allCumulative",
  API_ACCESS_TOKEN: "apiAccessToken",
  API_APP_KEY: "apiKey",
  API_APP_SECRET_KEY: "apiSecretKey",
  ALTERNATE_EMAIL: "alternateEmail",
  AMOUNT: "amount",
  AMOUNT_LIMIT: "amountLimit",
  ANALYTICS: "Analytics",
  // API_DOC_URL: "https://marketcube.atlassian.net/wiki/spaces/MCH/overview",
  API_DOC_URL: "https://support.marketcube.io/category/211-api-documentation",
  API_KEY: "apiKey",
  APPROVE: "approve",
  APPROVED: "Approved",
  APPROVED_SMALL: "approved",
  APPROVE_ALL: "all",
  APPROVE_SELECTED: "selected",
  AUTO_REJECT: "Auto Rejected",
  AWAITING_DISPATCH: "awaitingDispatch",
  AWAITING_FULFILLMENT: "Awaiting Fulfillment",
  BUCKET: "bucket",
  ASK: "Ask",
  ASK_SELECT_VENDOR_SHIPPING: "askSelectVendorShipping",
  ASK_SELLER_TO_ADD_BAND: "askSellerToAddBands",
  ASSOCIATION: "association",
  ATTACHMENT: "attachment",
  ATTACHMENTS: "attachments",
  ATTENTION: "attention",
  AUTOCOMPLETE: "autoComplete",
  AVAILABLE: "Available",
  AVAILABLE_OPTION: "Available Options",
  AVAILABILITY_STATUS: "Availability Status",
  AVAILABILITY: "availability",
  BACKGROUND: "background",
  BAND: "band",
  BANDS: "bands",
  BANK_NAME: "bankName",
  BARCODE: "barcode",
  BARCODE_CAMEL: "barCode",
  BIGCOMMERCE: "bigCommerce",
  BIGCOMMERCE_PRODUCT: "bigcommerceProduct",
  BOOTSTRAP: "bootstrap",
  BRAND_CMS: "brand",
  BRANDING: "branding",
  BRAND_COVER_IMAGE: "brandCoverImage",
  BRAND_FILTER: "brandFilter",
  BRAND_HANDLE: "brandHandle",
  BRAND_IMAGE: "brandImage",
  BRAND_NAME: "brandName",
  BRAND_SLUG: "slug",
  BIGCOMMERCE_CAPITAL: "Bigcommerce",
  BRAND_DESCRIPTION: "description",
  BUCKET_NAME: "bucket",
  BULK_ACTIVITY: "bulkActivity",
  BULK_CREATE: "BulkCreate",
  BULK_EDIT: "bulkEdit",
  BULK_INVITE: "bulkInvite",
  BULK_INVITATION: "bulkInvitation",
  BULK_INVITE_FILE_NAME: "bulk-invite.csv",
  BULK_PRODUCT_CSV: "bulk-product.csv",
  BULK_PRODUCT_EDIT: "bulkProductEdit",
  BULK_SUPPLIER_CSV: "bulkSupplierUsingCsv",
  BUTTON: "button",
  BUTTON_TEXT: "buttonText",
  CANADA: "canada_post",
  CANADA_POST: "canadaPost",
  CANCEL: "Cancel",
  CANCELLED: "cancelled",
  CANCEL_SMALL: "cancel",
  CANCEL_MANAGE_SHIPPING: "cancelManageShipping",
  CAPACITY: "capacity",
  CARRIER_ID: "carrierId",
  CATALOG: "catalog",
  CATEGORY: "category",
  CENTER: "center",
  CHANGE: "Change Password",
  CHANGE_PASSWORD: "changePassword",
  CHARACTER_COUNT: 700,
  CUSTOM_NOTE_COUNT: 120,
  CHECK: "check",
  CITY: "city",
  CLEAR_ALL: "clearAll",
  CLICK_COLLECT: "clickCollect",
  CLICK_RESERVE: "clickReserve",
  CLICK_SERVICE: "clickService",
  CLIENT_ID: "clientId",
  CLIENT_SECRET: "clientSecret",
  CLOSE: "Close",
  CLOSED: "closed",
  CM: "cm",
  CODE: "code",
  COLLECT: "collect",
  COLLECTED: "Collected",
  COLLECTION: "collection",
  COLLECTION_ID: "Collection Id",
  COLOR: "color",
  COMING_SOON: "Coming Soon",
  COMMISSION: "commission",
  COMMON: "common",
  COMPANY: "company",
  COMPARE_AT_PRICE: "Compare At Price",
  COMPARE_PRICE: "comparePrice",
  CONFIRM_PASSWORD_SELLER: "confirmPassword",
  COMPLETED: "Completed",
  COMPAIGN: "Campaign in progress",
  COMPAIGN_END: "Campaign Ended",
  COMPLETE: "completed",
  CONDITION: "condition",
  CONFIRM: "Confirm",
  CONFIRM_PASSWORD: "confirmPassword",
  CONNECT: "connect",
  CONNECT_PAYMENT: "connectPayment",
  CONNECT_PAYPAL: "connectPaypal",
  CONNECT_SHOP: "connectShop",
  CONNECT_SHOP_PAGE: "/products/add/shop",
  CONNECT_STRIPE: "connectStripe",
  CONSIGNMENT: "consignment",
  CONSIGNMENT_PICKUP: "consignmentPickup",
  CONSIGNMENT_PICKUP_CAPITAL: "Consignment Pickup",
  CONSIGNMENT_SHIP: "consignmentShip",
  CONSIGNMENT_SHIP_CAPITAL: "Consignment Ship",
  CONSOLIDATED_INVOICE: "Consolidated Invoice",
  CONSOLIDATED_PRODUCT: "Consolidated product report",
  CONSOLIDATED_REPORT: "Consolidated report",
  CONSOLIDATED_VENDOR: "Consolidated vendor report",
  CONSUMER_KEY: "consumerKey",
  CONSUMER_SECRET: "consumerSecret",
  CONTACT: "contact",
  CONTACT_EMAIL: "contactEmail",
  CONTACT_US: "Contact Us",
  CONTENT: "content",
  CONTENT_TYPE: "contentType",
  CONTRACT_ID: "contractId",
  COPY: "copy",
  COST_PRICE: "costPrice",
  COUNTRY: "country",
  COUNTRY_CODE: "countryCode",
  CREATE: "create",
  CREATEDAT: "Created At",
  CREATED_ASC: "createdAt_asc",
  CREATED_AT: "createdAt",
  CREATED_DESC: "createdAt_desc",
  CREATE_CSV: "createCSV",
  CREATE_FORM: "createForm",
  CREATE_VENDOR: "createVendor",
  CREDIT_NOTE: "creditNote",
  CREDIT_NOTE_LIST: "creditNoteList",
  CREDIT_NOTE_SETTING: "creditNoteSetting",
  CRITERIA: "criteria",
  CRITICAL: "critical",
  CSV: "csv",
  CURRENCY_CODE: "currencyCode",
  CURRENCY_COLUMN: ["icon", "text", "boolean"],
  CURRENCY_TABLE_HEADING: ["Currency", "Exchange rates", "Add currency option"],
  CURRENCY_PAYOUT_SETTING: "currencyPayoutSetting",
  CUSTOM: "custom",
  CUSTOM_PLAN: "customPlan",
  CUSTOM_DAYS: "custom-days",
  CUSTOM_METAFIELD: "customMetafields",
  CUSTOM_RATE_NAME: "shippingRateName",
  CUSTOMER_PRODUCT_PRICE: "Customer Product Price",
  CUSTOMER_PRICE: "customerPrice",
  CUSTOMERPRODUCTPRICE: "customerProductPrice",
  CURRENCY_RATE_SETTING: "currencyRateSetting",
  CUSTOMER_NUMBER: "customerNumber",
  CUSTOMS: "customs",
  DATA_UNIT: {
    MB: "MB",
  },
  DATE_FORMAT: "LL [at] hh:mma [(GMT)]",
  DATE_OF_PAYMENT: "dateOfPayment",
  DAILY: "daily",
  DATE_OF_PAYMENT: "dateOfPayment",
  DAY: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
  DAYS: "days",
  DECIMAL: "decimal",
  DEFAULT: "default",
  DEFAULT_ERROR: "Unable to process request, please try again.",
  DEFAULT_BACKGROUND_COLOR: "#f6f6f7",
  DEFAULT_CONTENT_COLOR: "#6d7175",
  DEFAULT_FONT: "SF Pro Text",
  DEFAULT_BUTTON_COLOR: "#008060",
  DEFAULT_BACKGROUND_COLOR: "#f6f6f7",
  DEFAULT_CONTENT_COLOR: "#6d7175",
  DEFAULT_FONT: "SF Pro Text",
  DEFAULT_CONSIGNMENT_SHIP: "defaultConsignmentShip",
  DEFAULT_ONBOARDING_FINISH_TITLE: "Onboarding Completed",
  DEFAULT_FINISH_DESCRIPTION: `That's it! Your basic marketplace setup is now complete. Next steps: 
Access your dashboard and add more products, explore the settings area, fulfill incoming orders from the marketplace and check the payments tab for accounting updates.`,
  DEFAULT_HEADER_COLOR: "#fff",
  DEFAULT_HEADING_COLOR: "#333333",
  DEFAULT_LOGIN_HEADER: "Log in to your MarketCube Dashboard",
  DEFAULT_PDF_LOGO: "https://ik.imagekit.io/marketcube/mc/test/product/temp/mc_f5cdBCVvF.jpeg",
  DEFAULT_MENU_COLOR: "#6d7175",
  DEFAULT_REGISTER_DESCRIPTION:
    "Join the world's largest dropshipping community and sell to millions of businesses and customers worldwide",
  DEFAULT_REGISTER_TITLE: "Welcome to Marketcube.io",
  DEFAULT_TEXT_COLOR: "#000",
  DEFAULT_TOAST_COLOR: "#333333",
  DEFAULT_TOAST_TEXT_COLOR: "#E3E7E5",
  DEFAULT_ONBOARDING_TITLE: "Let's get you setup",
  DEFAULT_TOAST_COLOR: "#333333",
  DEFAULT_TOAST_TEXT_COLOR: "#E3E7E5",
  DEFAULT_WELCOME_DESCRIPTION: `Setting up your marketplace account is quick and easy and should take approximately five (5) minutes. There are two (2) steps to be completed:
Update your Brand and Profile settings.
Add a new product.
Once your onboarding is complete you will be able to access your dashboard, where you will have access to add products, manage orders and review payments as well as configure marketplace settings.`,
  DELETE: "Delete",
  DELEVERED: "Delivered",
  DELETED: "Deleted",
  ADDED: "Added",
  DELETE_ECOMMERCE: "deleteEcommerce",
  DELETE_MARKET: "deleteMarket",
  DELETE_SHIPPING_BAND: "Delete Shipping Band - ",
  DELETE_SHOPIFY: "deleteShopify",
  DESCRIPTION: "description",
  DESC: "desc",
  DESTINATION: "destination",
  DESTINATION_CODE: "destinationCodes",
  DESTINATION_FILTER: "destinationFilter",
  DIGEST: "digest",
  DIGEST_EMAIL: "digestEmail",
  DIGEST_SETTING: "digestSetting",
  DIMENSION: "dimension",
  DISABLE: "Disable",
  DISABLED: "disabled",
  DISABLED_CAPITAL: "Disabled",
  DISABLE_ACCEPTANCE: "disableAcceptance",
  DISABLE_ADVANCE_ACCEPTANCE: "disableAdvanceAcceptance",
  DISABLE_ADVANCE_PICKUP: "disableAdvancePickup",
  DISABLE_PICKUP: "disablePickup",
  DISCONNECT_PAYPAL: "disconnectPaypal",
  DISCONNECT_STRIPE: "disconnectStripe",
  DISCOUNT: "discount",
  DISCOUNT_REPORT: "Discount",
  DISCOUNT_SETTING: "discountSetting",
  DISPUTE: "dispute",
  DISPATCHED: "dispatched",
  DOMAIN: "domain",
  DOMAIN_BRANDING: "domainBranding",
  DO_NOT_TRACK: "dontTrack",
  DO_NOT_TRACK_INVENTORY: "Don't track inventory",
  DROPSHIP: "dropship",
  DOWN: "down",
  DARFT: "draft",
  DUE: "due",
  DUPLICATE: "duplicate",
  DURATION: "duration",
  DYNAMIC_DISCOUNT: "dynamicDiscount",
  EAZY_SHIP: "eazyShip",
  EDIT_ADDRESS: "editAddress",
  EDIT_LABEL: "Edit",
  EDIT_PRODUCT: "editProduct",
  EDIT_PROFILE: "editProfile",
  EDIT_SERVICE_VARIANT: "editServiceVariant",
  EMAIL_SELLER: "email",
  EDIT_VARIANT: "editVariant",
  EDIT_VENDOR: "editVendor",
  EDITED: "edited",
  EMAIL: "email",
  EMAIL_NOT_PROVIDE: "email is not provided",
  EMAIL_SELLER: "email",
  EMAIL_TEMPLATE: "emailTemplate",
  ENABLE: "Enable",
  ENABLED: "enabled",
  ENABLED_CAPITAL: "Enabled",
  ENABLE_ACCEPTANCE: "enableAcceptance",
  ENABLE_ADVANCE_ACCEPTANCE: "enableAdvanceAcceptance",
  ENABLE_ADVANCE_PICKUP: "enableAdvancePickup",
  ENABLE_PICKUP: "enablePickup",
  ENTER_TEXT: "Enter Text",
  EQUAL: "equal",
  ERROR: "error",
  ERROR_LOGS: "errorLogs",
  ESHIP: "eShip",
  EVENTS: ["load", "mousemove", "mousedown", "click", "scroll", "keypress"],
  EXCLUSIVE: "exclusive",
  EXPRESS_PAYMENT_SETTING: "expressPaymentSetting",
  EXPORT: "export",
  EXPORT_ORDER: "exportOrder",
  EXPORT_PAYMENT: "exportPayment",
  EXPORT_PRODUCT: "exportProduct",
  EXPORT_REPORT: "exportReport",
  EXTRA_TIGHT: "extraTight",
  EXPORT_VENDOR: "exportVendor",
  FAILED: "failed",
  FAILURE: "Sales Goal Not Met",
  FAVICON: "favicon",
  FAVICON_SIZE: "96px X 96px",
  FILTER_BY: "Filter By",
  FILTER_BY_STATUS: "filterByStatus",
  FINISH_COVER: "finishCover",
  FINISH_DEFAULT_IMAGE: "https://ik.imagekit.io/marketcube/platform/welcome.jpg?tr=w-800",
  FINISH_DESCRIPTION: "finishDescription",
  FIRST_NAME: "firstName",
  FIXED: "fixed",
  FILE: "file",
  FILEPLACEHOLDER: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
  FIVE_DAY: "fiveDay",
  FIXED_AMOUNT: "fixed_amount",
  FLAT: "flat",
  FLVR_CLOUD: "flvrCloud",
  FOOTER: "footer",
  FOLDER_PATH: "path",
  FORECAST: "forecast",
  FORMAT_DATE: "DD-MM-YYYY hh:mm:ss",
  FORMATDATE: "DD-MM-YYYY",
  FORM_CREATE: "formCreate",
  FREE: "free",
  FREE_COST: "Fixed cost",
  FREE_SHIPPING: "Free shpping",
  FEE_TYPE: "feeType",
  FEE_VALUE: "feeValue",
  FULL: "full",
  FULFILLED: "fulfilled",
  FULFILLED_BY_OPERATOR: "fulfilled by operator",
  FULFILLEDLINEITEMS: "fulFilledLineItems",
  FULFILLED_SERVICE_ITEMS: "fulfilledServiceItems",
  FULFILLMENT: "fulfillment",
  FULFILLMENT_STATUS: "Fulfillment Status",
  FULFILLMENTS: "fulfillments",
  FULFILMENT: "fulfilment",
  FULLFILLMENTSTATUS: "fullFillmentStatus",
  FULL_ACCESS: "fullAccess",
  GENERIC_LINK: "genericLink",
  GET_BAND: "getShippingBand",
  GET_CONTENT: "getContent",
  GET_CURRENT_PLAN: "getCurrentPlan",
  GET_CURRENT_USER: "getCurrentUser",
  GET_FEATURE: "getFeature",
  GET_INTRODUCTORY_SELLER: "getIntroductorySeller",
  GET_PLANS: "getPlans",
  GET_SHIPPING_OFFERS: "getShippingOffers",
  GET_REPORT_LINES: "getReportLines",
  GET_VENDOR_AUTH_CODE: "getVendorAuthCode",
  GET_VENDOR_PRODUCT: "getVendorAndProductTypes",
  GLOBAL: "global",
  GLOBAL_CONSIGNMENT: "globalConsignment",
  GLOBAL_SHIPPING_PROCESS: "Global",
  GOLD: "gold",
  GREATER: "Greater Than",
  GREATER_THAN: "greaterThan",
  GREENDARK: "greenDark",
  GROWTH: "growth",
  GROSS_SALES: "Gross Sales",
  GROSSSALES: "grossSales",
  GROSS_PROFIT: "Gross Profit",
  GROSSPROFIT: "grossProfit",
  GST_L: "GST",
  JOINED: "Joined",
  HEADER: "header",
  HEADING: "heading",
  HEADER_TEXT: "headerText",
  HEIGHT: "height",
  HEX: "hex",
  HIDE: "Hide",
  HIDE_DETAILS: "hideMcDetails",
  HIDE_CAPITAL: "Hide",
  HIDE_MC_BRANDING: "hideMCBranding",
  HIDDEN: "Hidden",
  HOLIDAY: "On Holiday",
  HOPSCOTCH: "hopscotch",
  HYBRID: "hybrid",
  HYBRID_CLICK_COLLECT: "hybridClickCollect",
  HYBRID_CLICK_RESERVE: "hybridClickReserve",
  HYBRID_CLICK_SERVICE: "hybridClickService",
  HYBRID_CONSIGNMENT: "hybridConsignment",
  HYBRID_CONSIGNMENT_PICKUP: "hybridConsignmentPickup",
  HYBRID_CONSIGNMENT_SHIP: "hybridConsignmentShip",
  HYBRID_DROPSHIP: "hybridDropship",
  HYBRID_PICKUP: "hybridPickup",
  HYBRID_COLLECT: "hybridCollect",
  HYBRID_RESERVE: "hybridReserve",
  HYBRID_SERVICE: "hybridService",
  HYBRID_TIME_SERVICE: "hybridTimeService",
  IMAGE: "image",
  IMAGE_KIT: "imagekit",
  IMAGES: "images",
  IMAGE_URL: "imageUrl",
  INCLUSIVE: "inclusive",
  INFO: "info",
  INFORMATION: "information",
  INFORMATION_KEY: [
    "comparePrice",
    "description",
    "productType",
    "price",
    "tags",
    "title",
    "vendor",
    "wholesalePrice",
    "catalog",
  ],
  INPUT_ID_COST: "inputIdCost",
  INPUT_ID_DESCRIPTION: "inputIdDescription",
  INPUT_ID_TYPE: "inputIdType",
  INTEGER: "integer",
  INVENTORY: "inventory",
  INVENTORY_KEY: ["barcode", "inventory", "quantity", "sku", "location"],
  INVENTORY_MANAGEMENT: "inventoryManagement",
  INVENTORY_POLICY: "inventoryPolicy",
  INVENTORY_QUANTITY: "inventoryQuantity",
  INVITATION: "invitation",
  INVITE: "invite",
  INVITE_CSV: "inviteCsv",
  INVITE_FORM: "inviteForm",
  INVOICE: "invoice",
  INSTALL_APP_KEY: "installApp",
  IN_PROGRESS: "in progress",
  IN_REVIEW: "inReview",
  IN_REVIEW_SMALL: "in review",
  IS_ACCEPT_POLICY: "isAcceptPolicy",
  IS_ACCEPT_POLICY_SELLER: "isAcceptPolicy",
  IS_AUTO_FULFILL: "isAutoFulfill",
  IS_BILLING_ADDRESS: "isBillingAddress",
  IS_CSV: "isCsv",
  IS_CUSTOMER: "isCustomer",
  IS_DEFAULT: "isDefault",
  IS_FULL: "isFullAccess",
  IS_HEADER_COPY: "isHeaderCopy",
  IS_HIDE: "isHide",
  IS_HIDE_CONTACT: "isHideContact",
  IS_HIDE_TAG: "isHideTag",
  IS_HIDE_TYPE: "isHideType",
  IS_MANAGING_STOCK: "isManagingStock",
  IS_ORDER_NOTE: "isOrderNote",
  IS_PAYMENT: "isPayment",
  IS_PDF: "isPdf",
  IS_PRODUCT: "isProduct",
  IS_READ: "isReadOnly",
  IS_SERVICE: "isService",
  IS_SHIPPING: "isShipping",
  IS_SHIPPING_ADDRESS: "isShippingAddress",
  IS_SHIPPING_DISABLED: "isShippingDisabled",
  IS_SHIPPING_RATE_REQUIRED: "isShippingRateRequired",
  IS_UPDATE_ALLOWED: "isUpdateAllowed",
  IS_PHYSICAL_PRODUCT: "isPhysicalProduct",
  IS_PACKING_SLIP: ["refunded", "failed", "pending"],
  ITEM: "item",
  ITEMS: "items",
  JSON: "json",
  MONEY: "money",
  TRUE_FALSE: "trueFalse",
  KEY: "key",
  LARGE: "large",
  LAKE_TAX_RATE: "lakeTaxRate",
  LABEL: "label",
  LABEL_NAME: "labelName",
  LAST_NAME: "lastName",
  LEADING: "leading",
  LENGTH: "length",
  LESS: "Less Than",
  LESS_THAN: "lessThan",
  LIKE: "like",
  LINE_RETURN: "lineReturn",
  LIST: "list",
  LIVECONNECTED_PLATFORM: "liveConnectedPlatform",
  LOCAL: "local",
  LOCATION: "location",
  LOCATION_MAPPING_SETTING: "locationMappingSetting",
  LOCATION: "location",
  PROVIDER_LOCATION_MAPPING_SETTING: "providerLocationMappingSetting",
  LOGIN: "login",
  LOGO_URL: "logoUrl",
  LL: "LL",
  LINEITEMID: "Lineitem Id",
  LOGIN_TEXT: "loginText",
  LOGO: "https://ik.imagekit.io/marketcube/platform/logo.jpg",
  LOGOUT: "Logout",
  MAGENTO: "Magento",
  MAIL_SUBJECT: "mailSubject",
  MAINTENANCE: "maintenance",
  MANAGE_CUSTOMER: "manageCustomer",
  MANAGE_FULFILLMENT_DETAIL: "manageFulfillmentDetail",
  MANAGE_VENDOR_EXPORT: "managevendorExport",
  MANAGE_INVOICE_COLUMN_CONTENT: ["text", "text", "boolean", "icon"],
  MANAGE_INVOICE_COLUMN_HEADING: ["Current Label", "New Label", "Hide Column(s)", "Status"],
  MANAGE_LINE_ITEM: "Manage Line Item",
  MANAGE_TAX: "manageTax",
  MANAGE_VENDOR: "manageVendor",
  MANAGE_OFFER: "manage-offer",
  MANAGE_RULE: "manage-rule",
  MANAGE_SHIPPING: "manage-shipping",
  MANAGE_SHIPPING_TERMS: "manage-shipping-terms",
  MANUAL: "manual",
  MARKET_CUBE: "https://ik.imagekit.io/marketcube/platform/logo/full-logo.jpeg",
  MARKETCUBE_SHIPPING: "MarketCube Custom Shipping Rate Provider",
  MARKUP: "markUp",
  MARKUPKEY: "Mark Up",
  MARKUP_KEY: "markup",
  MARKUP_PRICE: "markupPrice",
  MARKUP_REPORT: "Markup",
  MARKUP_SETTING: "markupSetting",
  MATERIAL: "material",
  MAX: "max",
  MAX_PRODUCT_PLURAL: "maxProducts",
  MAX_VENDOR_PLURAL: "maxVendors",
  MEASUREMENT: "measurement",
  MENU: "menu",
  MESSAGE: "message",
  METAFIELD: "metafield",
  MENU: "menu",
  MORE_FILTER: "More Filter",
  MONTH: "month",
  MONTHLY: "monthly",
  MONTHS: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  MUTE_ALL: "muteAll",
  MUTE_NOTIFICATION: "muteNotification",
  NAME: "name",
  NAMESPACE: "namespace",
  NEEDS_REVIEW: "Needs Review",
  NEEDS_REVIEW_LAKE: "needs review",
  NEW: "new",
  NEW_PASSWORD: "newPassword",
  NOATTACHMENTSNAP: "https://via.placeholder.com/80x80/ffffff/000000?text=No+Attachment",
  NOIMAGESNAP: "https://via.placeholder.com/80x80/ffffff/000000?text=No+image",
  NO_ERROR: "No Error",
  NO: "No",
  NONE: "none",
  NOSHOW: "No show",
  NOTE: "note",
  NOT_PROVIDED: "Not Provided",
  NOT_ALLOWED_KEYS: ["_id", "isTaxable", "option1", "option2", "option3", "handle"],
  NOT_ALLOWED_TO_ADD: "notAllowedToAdd",
  NOT_TO_UPDATE: ["option1", "option2", "option3", "image", "vendorDiscount", "length", "width", "height"],
  NOT_SYNCED: "unsynced",
  NOT_REQUESTED: "Not Requested",
  NO_RETURN_ALLOWED: "noReturn",
  NO_SHOW: "No Show",
  NON_PHYSICAL: "nonPhysical",
  NUMBER: "number",
  NZ: "nzpost",
  NZPOST: "nzShip",
  OFFER: "offer",
  OFFERS: "offers",
  OK: "ok",
  ON: "On",
  ON_HOLD: "On hold",
  ONHOLD_LABEL: "On Hold",
  ON_HOLIDAY: "onHoliday",
  ONBOARDING_TITLE: "onBoardingTitle",
  ONBOARD_ACTIVITY: "onboardActivity",
  ONBOARD_IMAGE_TYPE: ["jpg", "jpeg", "png"],
  ONBOARDED: "Onboarded",
  ONHOLD: "onHold",
  ONHOLD_LABEL: "On Hold",
  MANAGE_GEOLOCATION: "manageGeolocation",
  ORDER_ADD: "orderAdd",
  ORDER_COLUMN_CONTENT: ["text", "text", "boolean", "icon"],
  ORDER_COLUMN_HEADING: ["Current label", "New label", "Hide column(s)", "Status"],
  ORDER_EXPORT_SETTING: "orderExportSetting",
  ORDER_ID: "Order Id",
  OPTION1: "option1",
  OPTION1_VAL: "option1Val",
  OPTION2: "option2",
  OPTION2_VAL: "option2Val",
  OPTION3: "option3",
  OPTION3_VAL: "option3Val",
  OPTION: "option",
  OPTIONS: [weightValue.KG, weightValue.LB, weightValue.OZ, weightValue.GM],
  ORDER: "order",
  ORDER_NUMBER_REPORT: "Order Number",
  ORDER_UC_FIRST: "Order",
  ORDERS: "orders",
  ORDERS_UC_FIRST: "Orders",
  ORDER_TABLE_HEADING: "orderTableHeading",
  ORDER_BY_VENDOR: "Order Value By Vendor",
  ORDER_CUSTOMER: "orderCustomer",
  ORDER_DISCOUNT_SETTING: "orderDiscountSetting",
  ORDER_EXPIRY: "orderExpiry",
  ORDER_LIVE_CONNECT_SETTING: "orderLiveConnectSetting",
  ORDER_NUMBER: "orderNumber",
  ORDER_PACKING_SLIP: "orderPackingSlip",
  ORDER_PACKING_SLIP_ACCESS: "orderPackingSlipAccess",
  ORDER_PACKING_SLIP_LABEL_SETTING: "orderPackingSlipLabelSetting",
  ORDER_SETTING: "orderSetting",
  ORDER_TAX_RATE: "orderTaxRate",
  ORDER_REVIEW: "orderReview",
  ORDER_REPORT_SETTING: "orderReportSetting",
  ORDERSTATUS: "Order Status",
  ORDER_STATUS: ["new", "accept", "reject", "fulfill", "bulkAccept", "bulkFulfill"],
  ORDER_SLIP: "orderSlip",
  PARTALLY_HIDDEN: "partiallyhidden",
  ORDER_VALUE: "orderValue",
  ORGANISATION_TAGS: "organisationTags",
  OTP: "otp",
  PACKING_SLIP_COLUMN_HEADING: ["Current label", "New label", "Hide column(s)", "Status"],
  PACKING_SLIP_LABEL: {
    DELIVERED_TO: "deliveredTo",
    DISCOUNT: "discount",
    PRODUCT: "product",
    QUANTITY: "quantity",
    SHIPPING: "shipping",
    TOTAL_DISCOUNT: "totalDiscount",
    TAX: "tax",
    TOTAL_TAX: "totalTax",
    TOTAL_SHIPPING: "totalShipping",
  },
  PACKING_SLIP_FILTER: {
    QUANTITY: "quantity",
    PURCHASE: "purchasedFrom",
    DELIVER: "deliveredTo",
    FULLFILL: "orderFulfilledBy",
  },
  PAID: "paid",
  PAY_AND_COLLECT: "Paid and Collected",
  PAID_AND_AVAILED: "Paid and Availed",
  PAIDVIAPAYPAL: "paidViaPaypal",
  PAIDVIASTRIPE: "paidViaStripe",
  PAYMENT_EXPORT_SETTING: "paymentExportSetting",
  PAYMENT_REPORT_SETTING: "paymentReportSetting",
  PARTIAL: "partial",
  PARTIAL_COLLECT: "Partially collected",
  PARTIAL_COMPLETE: "partiallyComplete",
  PARTIAL_REFUND: "Partially refunded",
  PARTIALLY_COMPLETE: "partiallyComplete",
  PARTIALLY_FULFILLED: "partiallyFulfilled",
  PARTIALLYPAID: "partially_paid",
  PARTIALLY_PAID: "partially paid",
  PARTIALLY_HIDDEN: "Partially hidden",
  PARTIALLY_REFUND: "partially_refunded",
  PARTIAL_RETURN: "Partial Return",
  PARTIAL_SHIPPED: "partially-shipped",
  PARTIALLY_SHIPPED: "partiallyShipped",
  PARTIAL_SHIP_SPACE: "partially shipped",
  PARTIALLY_SHIPPED: "Partially Shipped",
  PASSWORD: "password",
  PAYMENT: "payment",
  PAYMENT_METHOD: "paymentMethod",
  PAYMENTS: "payments",
  PAYMENTS_UC_FIRST: "Payments",
  PAYMENT_COLUMN: ["text", "text", "boolean", "icon"],
  PAYMENT_INVOICE: "paymentInvoice",
  PAYMENT_INVOICE_LABEL_SETTING: "paymentInvoiceLabelSetting",
  PAYMENT_INVOICE_ACCESS: "paymentInvoiceAccess",
  PAYMENT_TABLE_HEADING: ["Current label", "New label", "Hide column(s)", "Status"],
  PAYMENT_TERMS_DAY: "paymentTermsDay",
  PAYVENDOR: "payVendor",
  PAYOUTS: "payouts",
  PAYPAL: "paypal",
  PAYPAL_BANNER_URL: "https://support.marketcube.io/article/118-how-to-set-up-paypal-to-pay-your-vendors",
  PAYPAL_CLIENT_ID: "paypalClientId",
  PAYPAL_CONNECTED: "paypalConnected",
  PAYPAL_DEVELOPER_URL: "https://developer.paypal.com/",
  PAYPAL_ICON: "https://img.icons8.com/fluent/25/000000/paypal.png",
  PAYPAL_SECRET: "paypalSecret",
  PAYPAL_URL: "https://test.marketcube.io/paypal/callback",
  PDF: "pdf",
  PDFATTACHMENT: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
  DOCATTACHMENT: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
  DOCXATTACHMENT: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
  PASSWORD_SELLER: "password",
  PAYMENT_RETURN: "paymentReturn",
  PAYMENT_TERM: "paymentTerm",
  PENDING: "pending",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_SMALL: "pending",
  PERCENTAGE: "percentage",
  PERIOD: "period",
  PHONE_NUMBER: "phoneNumber",
  PHYSICAL: "physical",
  PHYSICAL_PRODUCT: "physicalProduct",
  PERMISSION_LINK: "https://drive.google.com/file/d/1KruDK98-2ZVZFmhBt-pxxt8jOQ-aU1Rp/view?usp=sharing",
  PICKUP: "pickup",
  PICKUP_LOCATION: "PickUpLocation",
  PINCODE: "pincode",
  PLAYGROUND_URL: "https://playground.marketcube.io/login",
  POLICY_LINK_SELLER: "policyLink",
  POLICY_LINK: "policyLink",
  POLICY_LINK_SELLER: "policyLink",
  POLICY_URL: "https://www.marketcube.io/privacy-policy",
  POLICY_URL_SELLER: "https://www.marketcube.io/privacy-policy",
  POLICY_SELECTION: "policySelection",
  POSTAL_CODE: "postalCode",
  POST_FULFILLMENT: "postFulfillment",
  POST_RETURN: "postReturn",
  PRE_FULFILLMENT: "preFulfillment",
  PRESTASHOP: "Presta Shop",
  PRICE: "price",
  PRICE_REPORT: "Price",
  PRICE_TYPE: "priceType",
  PRIVACY: "privacy",
  PRIVACY_SELLER: "privacy",
  PRO: "pro",
  PROCESSING: "Processing",
  PROCESS: "processing",
  PRODUCT: "product",
  PRODUCTS: "products",
  PRODUCT_ADD: "productAdd",
  PRODUCT_LAKE_ADD: "productLakeAdd",
  PRODUCT_ADD_CSV: "productAddCSV",
  PRODUCT_ADD_FORM: "productAddForm",
  PRODUCT_LAKE_ADD_FORM: "productLakeAddForm",
  PRODUCT_APPROVAL_SETTING: "productApprovalSetting",
  PRODUCT_ATTACHMENT: "productAttachment",
  PRODUCT_ATTACHMENT_SETTING: "productAttachmentSetting",
  PRODUCT_AUTOPUBLISH_SETTING: "productAutoPublishSetting",
  PRODUCT_BULK_CREATE: "bulkProductCreate",
  PRODUCT_COLLECTION: "productCollection",
  PRODUCT_COST: "Product Cost",
  PRODUCT_COLUMN: ["text", "text", "boolean", "icon"],
  PRODUCT_DEVELOPMENT: "Product in development",
  PRODUCTCOST: "productCost",
  PRODUCT_CSV: "productCsv",
  PRODUCT_FILTER: "productFilter",
  PRODUCT_FORM: "productForm",
  PRODUCT_FORM_LABEL_SETTING: "productFormLabelSetting",
  PRODUCT_FROM_SHOPIFY: "productFromShopify",
  PRODUCT_IDS: "productIds",
  PRODUCT_IMPORT: "productImport",
  PRODUCT_IMPORT_BIG_COMMERCE: "productImportBigCommerce",
  PRODUCT_IMPORT_SHOPIFY: "productImportShopify",
  PRODUCT_IMPORT_WIX: "productImportWix",
  PRODUCT_IMPORT_WOOCOMMERCE: "productImportWooCommerce",
  PRODUCT_LAKE_URL: "/product-lake",
  PRODUCT_LIMIT: "productLimit",
  PRODUCT_METAFIELD_SETTING: "productMetafieldSetting",
  PRODUCT_NAME: "Product Name",
  PRODUCT_PHYSICAL_SETTING: "productPhysicalSetting",
  PRODUCT_PRICE: "productPrice",
  PRODUCT_PRICE_LABEL: "Product Price",
  PRODUCT_REPORT_SETTING: "productReportSetting",
  PRODUCT_PRICE_SETTING: "productPriceSetting",
  PRODUCT_QUANTITY: "productQuantity",
  PRODUCT_QUANTITY_LABEL: "Product Quantity",
  PRODUCT_RULE_CONDITION: "productRuleCondition",
  PRODUCT_SERVICE_URL_UPDATE: "/products/service/edit/",
  PRODUCT_SETTING: "productSetting",
  PRODUCT_STATUS: ["new", "reSubmit", "review"],
  PRODUCT_TAG: "productTag",
  PRODUCTTYPE: "Product Type",
  PRODUCT_TAGS: "tags",
  TARGET: "Sales Goal Met",
  PRODUCT_TAG_TABLE_HEADING: ["Product Tags", "Vendors", "Product Types", "Product Collections"],
  PRODUCT_TAG_COLUMN: ["text", "boolean", "boolean", "boolean"],
  PRODUCT_TAG_SETTING: "productTagSetting",
  PRODUCT_TAG_TYPE_SETTING: "productTagTypeSetting",
  PRODUCT_TYPE: "productType",
  PRODUCT_UC_FIRST: "Product",
  PRODUCT_UPDATE: "productUpdate",
  PRODUCT_LAKE_UPDATE: "productLakeUpdate",
  PRODUCT_UPDATE_FORM: "productUpdateForm",
  PRODUCT_LAKE_UPDATE_FORM: "productLakeUpdateForm",
  PRODUCT_UPDATE_BULK_FORM: "productUpdateBulkForm",
  PRODUCT_URL: "/products",
  PRODUCT_URL_EDIT: "/products/bulk/edit",
  PRODUCT_LAKE_URL_EDIT: "/product-lake/bulk/edit",
  PRODUCT_URL_REVIEW: "/products/review/",
  PRODUCT_LAKE_URL_REVIEW: "/product-lake/review/",
  PRODUCT_URL_SHOPIFY: "/products/shopify",
  PRODUCT_URL_WOOCOMMERCE: "/products/woocommerce",
  PRODUCT_URL_MAGENTO: "/products/magento",
  PRODUCT_URL_PRESTASHOP: "/products/prestashop",
  PRODUCT_URL_UPDATE: "/products/edit/",
  PRODUCT_LAKE_URL_UPDATE: "/product-lake/edit/",
  PRODUCT_LIMIT: "productLimit",
  PRODUCT_USING_FORM: "addProductUsingForm",
  PRODUCT_LAKE_USING_FORM: "addProductLakeUsingForm",
  PRODUCT_VALUE_TYPE: "productValueType",
  PRODUCT_TABLE_HEADING: ["Current label", "New label", "Hide column(s)", "Status"],
  PRODUCT_EXPORT_SETTING: "productExportSetting",
  PROGRESS: "progress",
  PROFILE: "Profile",
  PROVIDER_IMAGE: "Provider Image",
  PROVIDER_LIST: "/providers",
  PROVIDER_VIEW: "/providers/view/",
  PROVINCE_CODE: "provinceCode",
  PST_L: "PST",
  PUBLISH: "publish",
  PUBLISHED: "published",
  PUSH_TO_STORE_ID: "pushToStore",
  PUSH_TO_STORE_LABEL: "Publish to Store",
  QUANTITY: "quantity",
  QUANTITYS: "Quantity",
  QUANTITY_DISABLED: "quantityDisabled",
  QUOTES: "Quotes",
  QUOTE_SEND_SMALL: "quoteSend",
  RADIO_BUTTON: "radioButton",
  RADIO_BUTTON_WITH_AUTO_COMPLETE: "radioButtonWithAutoComplete",
  RANGE: "range",
  READ: "read",
  READ_ONLY: "Read-Only",
  READ_ONLY_ACCESS: "readOnlyAccess",
  READY: "ready",
  READY_TO_PICK: "Ready to Pick",
  REASON: "reason",
  RED: "RED",
  REDDARK: "redDark",
  REGISTER: "register",
  REGISTERSUBDOMIN: "shopSubDomain",
  REFUND: "refunded",
  REGULAR_PRICE: "regularPrice",
  REJECT_DELETE: "rejectAndDelete",
  REJECT: "reject",
  REJECTED: "rejected",
  REJECTED_BY_VENDOR: "rejected by vendor",
  REJECTED_LABEL: "Rejected",
  REJECT_LABEL: "Reject",
  REJECT_ORDER: "Reject Order",
  REPORT: "report",
  RESEND: "Resend",
  RESEND_OTP: "resendOtp",
  RESERVE: "reserve",
  RESET_ALL: "resetAll",
  RATE: "rate",
  RATE_TEXT: "returnRateText",
  RETURN: "return",
  RETURNS: "Returns",
  RETURNS_SMALL: "returns",
  RETURN_ALLOWED: "return",
  REVIEW: "Review",
  REVIEWED: "Reviewed",
  REVIEWED_SMALL: "reviewed",
  REVIEW_ALL: "review",
  REVIEW_PRODUCT: "reviewProduct",
  REVIEW_PRODUCT_LAKE: "reviewProductLake",
  REVIEW_VENDOR: "reviewVendor",
  REVOKED: "revoked",
  RE_SUBMIT: "resubmit",
  RE_SYNC: "resync",
  RULE: "rule",
  RULES: "rules",
  SALE_PRICE: "salePrice",
  SALES: "sales",
  SCHEDULE_PAYMENT: "schedulePayment",
  SCHEDULE_PAYMENT_SETTING: "schedulePaymentSetting",
  SAVE_SMALL: "save",
  SAVED: "saved",
  IS_SAME_BUSINESS_ADDRESS: "isSameAsBusinessAddress",
  SCHEDULE_PAYMENT: "schedulePayment",
  SCHEDULE_PAYMENT_SETTING: "schedulePaymentSetting",
  SECRET_ACCESS_KEY: "secret",
  SELECT: "Select",
  SELECT_OPTION: "Select Options",
  SELECTED: "selected",
  SELECT_COUNTRY: "Select Country",
  SELECTED_FILTER: "selectedFilter",
  SELECTED_PAYMENT: "paymentTermsType",
  SELECTED_POLICY: "selectedPolicy",
  SELECTED_STANDARD_TYPE: "selectedStandardType",
  SELECT_CUMULATIVE: "selectedCumulative",
  SELECT_VENDOR_SHIPPING: "selectVendorShipping",
  SELECT_WEIGHT: "weightUnit",
  SELLER: "seller",
  SELLER_COMMISSION: "Seller Commission",
  SELLERCOMMISSION: "sellerCommission",
  SELLER_DISCOUNT: "sellerDiscount",
  SELLER_PRODUCTS: "sellerProducts",
  SENDABOX: "sendabox",
  SENDABOX_DEFAULT_CARRIER: "SDA",
  SERVICE: "service",
  SERVICE_AVAILABILITY_NOT_AVAILABLE: "Service availability information is missing.",
  SERVICE_ID: "Service ID",
  SESSION: "session",
  SETTINGS: "settings",
  SEVEN_DAY: "sevenDay",
  SEVEN_DAYS: "seven-days",
  SHIPPED: "shipped",
  SHIPPING: "shipping",
  SHIPPING_AMOUNT: "Shipping",
  SHIPPING_BAND: "shippingBand",
  SHIPPING_BAND_ID: "shippingBandId",
  SHIPPING_CHARGE_SELECT: "shippingChargeSelect",
  SHIPPING_CONDITION: "shippingCondition",
  SHIPPING_COST: "shippingCost",
  SHIPPING_FIXED: "fixed",
  SHIPPING_FREE: "free",
  SHIPPING_TYPE: "shippingType",
  SHIPPED_BY_VENDOR: "Shipped by Vendor",
  SHOP_NAME: "shopName",
  SHOP_CONNECT: "shopConnect",
  SHOP_DOMAIN: "shopDomain",
  SHOP_SUB_DOMAIN: "shopSubDomain",
  SHIPPO: "shippo",
  SHOPIFY_CONNECT_FORM: "selectForm",
  SHOPIFY_CONNECT_RADIO: "redioButton",
  SHIPPY_PRO: "shippyPro",
  SHIP_STATION: "shipStation",
  SHOPIFY: "shopify",
  SHOPIFY_ICON: "https://img.icons8.com/color/25/000000/shopify.png",
  SHOPIFY_CATALOG_ID: "shopifyCatalogId",
  SMOOTH: "smooth",
  SHOPIFY_PRODUCT: "shopifyProduct",
  SHOPIFY_CAPITAL: "Shopify",
  SHOW: "Show",
  SHOW_ACCOUNT_TYPE: "showAccountType",
  SHOW_BRAND_NAME: "showBrandName",
  SILVER: "silver",
  SIMPLE: "simple",
  SINGULAR: "offer",
  SIZE: "size",
  SITE_CODE: "siteCode",
  SKU: "sku",
  SKU_REPORT: "SKU",
  SLIM: "slim",
  SMALL: "small",
  SORT_ORDER: "sortOrder",
  SORT_CODE: "sortCode",
  STANDARD_AMOUNT: "standardAmount",
  STANDARD_COMMISSION: "standardCommission",
  STANDARD_PAYMENT_RETURN: "standardPaymentReturn",
  STANDARD_PAYMENT_TERM: "standardPaymentTerm",
  STANDARD_BRANDING: "standardBranding",
  STANDARD_DISCOUNT: "standardDiscount",
  STANDARD_SHIPPING: "standardShipping",
  STANDARD_VENDOR_ACCESS: "standardVendorAccess",
  STANDARD_VENDOR_PROFILE: "standardVendorProfile",
  STATUS: "status",
  STATUS_NEW: "new",
  STARSHIPIT: "starshipit",
  STEP: "step",
  STREET_ADDRESS: "streetAddress",
  STRING: "string",
  STRIPE: "stripe",
  STRIPE_ACCOUNT: "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=",
  STRIPE_EXPRESS_ACCOUNT: "https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=",
  STRIPE_CLIENT_ID: "stripeClientId",
  STRIPE_CONNECTED: "stripeConnected",
  STRIPE_DASHBOARD_URL: "https://dashboard.stripe.com/",
  STRIPE_ICON: "https://img.icons8.com/color/25/000000/stripe.png",
  STRIPE_SECRET: "stripeSecret",
  STRIPE_URL: "https://test.marketcube.io/stripe/callback",
  STORE_ORDER_ID: "storeOrderId",
  STYLE: "Style",
  SUBDUED: "subdued",
  SUBJECT: "subject",
  SUBMIT: "Submit",
  SUBMIT_SMALL: "submit",
  SUBMIT_MANAGE_SHIPPING: "submitManageShipping",
  SUBTOTAL: "subTotal",
  SUCCESS: "success",
  SUM_SYMBOL: "+",
  SUPPLIERS: "suppliers",
  SUPPLIERS_SHOPIFY_URL: "providers/shopify-callback",
  SUPPORT: "support",
  SYNC: "sync",
  SYNCED: "synced",
  SYSTEM_ADMIN_NAME: "MarketCube Support",
  TAGGED_WITH: "taggedWith",
  TAG: "Tag",
  TAGS: "tags",
  TAX: "Tax",
  TAX_KEY: "tax",
  TAX_COMMISSION_SETTING: "taxCommissionSetting",
  TAX_SETTING: "taxSetting",
  TAX_SHIPPING_SETTING: "taxShippingSetting",
  TERM: "term",
  TERMS: "terms",
  TERMS_LINK: "termsLink",
  TERMS_LINK_SELLER: "termsLink",
  TERMS_URL: "https://www.marketcube.io/terms",
  TERMS_URL_SELLER: "https://www.marketcube.io/terms",
  TEXT: "text",
  TEXT_FIELD: "textField",
  TIDIO_CHAT: "tidioChat-ready",
  TIME_PICKER_START_TIME: 7,
  TIME_SERVICE: "timeService",
  TINYMCE: "tinyMCE",
  TITLE: "title",
  TITLES: "Title",
  TAX_CLASS: "taxClass",
  TAX_CLASS_TEXT: "returnTaxClassText",
  TITLE_ASC: "title_asc",
  TITLE_DESC: "title_desc",
  TRACKING_NUMBER: "Tracking Number",
  TRIAL_PERIOD: "trialPeriod",
  TRIAL_PERIOD_MAX: 5470,
  TOKEN: "token",
  TOTALSALESPRICE: "totalSalesPrice",
  TOTAL_CHECKBOXES: 16,
  TOTAL_ORDERS: "Total Orders",
  TOTAL_SALES_PRICE: "Total Sales Price",
  TOTAL_VENDOR_PAYOUT: "totalVendorPayout",
  TRANSACTIONFEES: "TransactionFees",
  TOAST_BACKGROUND_COLOR: "toastBackgroundColor",
  TOAST_TEXT: "toastText",
  TRACKING_NUMBER: "Tracking Number",
  TRIAL_PERIOD: "trialPeriod",
  TRIAL_PERIOD_MAX: 5470,
  TOAST_BACKGROUND_COLOR: "toastBackgroundColor",
  TOAST_TEXT: "toastText",
  TWENTY_FOUR: "twentyFour",
  TWENTY_FOUR_HOUR_START_TIME_IN_MIN: 0,
  TWENTY_FOUR_HOUR_END_TIME_IN_MIN: 1440,
  TYPE: "type",
  UNAVAILABLE: "Unavailable",
  UNFULFILLED: "unfulfilled",
  UNFULFILLEDLINEITEMS: "unfulfilledLineItems",
  UNPAID: "unpaid",
  UNDELETE: "undelete",
  UNDEFINE: "undefined",
  UNPUBLISH: "unpublish",
  UNREJECT: "unreject",
  UN_DELETE: "Un-Delete",
  UN_REJECT: "Un-Reject",
  UP: "up",
  UPDATE: "updatedAt",
  UPDATE_ASC: "updatedAt_asc",
  UPDATE_DESC: "updatedAt_desc",
  UPDATE_FULFILLMENT: "updateFulfillment",
  UPDATE_MANAGE_FULFILLMENT_SETTING: "updateManageFulfillmentSetting",
  UPDATE_ORDER_SETTING: "updateOrderSetting",
  UPDATE_PLAN: "updatePlan",
  UPDATE_PRODUCT_SETTING: "updateProductSetting",
  UPDATE_SELLER_SETTING: "updateSellerShippingSetting",
  UPDATE_SHOPIFY_URL: "/update-payment?plan",
  UPDATE_TERM: "updateTerm",
  UPDATE_SELLER_CHAT_SETTING: "updateSellerChatSetting",
  UPLOAD_IMAGE: "uploadImage",
  UPS: "UPS",
  UPS_SMALL: "ups",
  USER: "user",
  USER_NAME: "userName",
  USER_ROLE: "userRole",
  VALIDATED: "validated",
  VALUE: "value",
  VALUE_TYPE: "valueType",
  VARIABLE: "variable",
  VARIANT: "variant",
  VARIANTS: "variants",
  VARIANTS_KEY: [
    "barcode",
    "comparePrice",
    "discount",
    "markup",
    "physical",
    "physicalProduct",
    "price",
    "quantity",
    "sku",
    "weight",
  ],
  VENDOR: "vendor",
  VENDORS: "vendors",
  VENDOR_ACCESS: "vendorAccess",
  VENDOR_ACCESS_COLUMN: ["text", "boolean"],
  VENDOR_ACCESS_TABLE_HEADING: ["Vendors", "Products", "Orders", "Payments"],
  VENDOR_ADD: "vendorAdd",
  VENDOR_ADD_CSV: "vendorAddCSV",
  VENDOR_ADD_FORM: "vendorAddForm",
  VENDOR_AMOUNT: "Vendor Amount",
  VENDOR_AMOUNT_KEY: "vendorAmount",
  VENDOR_CAPITAL: "Vendor",
  VENDOR_CSV: "vendorCsv",
  VENDOR_COLUMN: ["text", "text", "boolean", "icon"],
  VENDOR_CHARGE_SECTION: "vendorChargeSection",
  VENDOR_CHAT: "vendorChat",
  VENDOR_CSV: "vendorCsv",
  VENDOR_DISCOUNT: "vendorDiscount",
  VENDOR_ID: "vendorId",
  VENDOR_SELECT: "returnVendorSelect",
  ADDRESS_ID: "addressId",
  VENDOR_IDS: "vendorIds",
  VENDOR_INVITE: "vendorInvite",
  VENDOR_INVITE_CSV: "vendorInviteCSV",
  VENDOR_INVITE_FORM: "vendorInviteForm",
  VENDOR_PRODUCT_TYPE: "vendorAndProductType",
  VENDOR_PROFILE: "vendorProfile",
  VENDOR_HOLIDAY_SETTING: "vendorHolidaySetting",
  VENDOR_LABEL: "Vendor",
  VENDOR_LINK: "vendorLink",
  VENDOR_MANAGE: "vendorManage",
  VENDOR_MODULE_COLUMN_CONTENT: ["text", "radioOptions"],
  VENDOR_MODULE_TABLE_HEADING: ["Module", "Access settings"],
  VENDOR_OPTIONS: "vendorOptions",
  VENDOR_PRODUCT_SETTING_MODAL: "vendorProductSettingModal",
  VENDOR_PAID_DATE: "Vendor Paid Date",
  VENDOR_PAYMENT_STATUS: "Vendor Payment Status",
  VENDOR_REVIEW_SETTING: "vendorReviewSetting",
  VISIBLE: "visible",
  VENDOR_SETTING: "vendorSetting",
  VENDOR_SHOPIFY: "vendorShopify",
  VENDOR_STATUS: ["join"],
  VENDOR_TAGGED: "isVendorTagged",
  VENDOR_TABLE_HEADING: ["Current label", "New label", "Hide column(s)", "Status"],
  VENDOR_VALUE: "vendorValue",
  VERIFY: "verify",
  VIEW_ID: "view",
  VIEW_LABEL: "View",
  VERIFY_LOGIN: "verifyLogin",
  VIEW: "view",
  VIEW_ACTIVITY: "viewActivity",
  VIEW_ORDER: "viewOrder",
  VIEW_VENDOR: "viewVendor",
  VENDOR_CUSTOMER_CHAT: "vendorCustomerChat",
  VENDOR_OPERATOR_CHAT: "vendorOperatorChat",
  VOLUME: "volume",
  WARNING: "warning",
  WEEKLY: "weekly",
  WEIGHT: "weight",
  WEIGHT_UNIT: "weightUnit",
  WELCOME_COVER: "welcomeCover",
  WELCOME_DEFAULT_IMAGE: "https://ik.imagekit.io/marketcube/platform/welcome.jpg?tr=w-800",
  WELCOME_DESCRIPTION: "welcomeDescription",
  WHOLESALE_PRICE: "wholesalePrice",
  PRODUCT_WHOLESALE_PRICE_SETTING: "productWholesalePriceSetting",
  WIDTH: "width",
  WIX: "wix",
  WIX_ICON: "https://img.icons8.com/windows/27/000000/wix.png",
  WIX_PASCAL_CASE: "Wix",
  WIX_PRODUCT: "wixProduct",
  WOOCOMMERCE: "wooCommerce",
  MAGENTO: "magento",
  PRESTASHOP: "prestaShop",
  WOOCOMMERCE_ICON: "https://img.icons8.com/color/27/000000/woocommerce.png",
  MAGENTO_ICON: "https://img.icons8.com/color/27/000000/magento.png",
  PRESTASHOP_ICON: "https://ik.imagekit.io/marketcube/platform/logo/prestashop-icon-512x512-fds74t2x_o3PXQ1GqT.png?tr=w-25",
  WOOCOMMERCE_PASCAL_CASE: "Woocommerce",
  WOOCOMMERCE_PRODUCT: "woocommerceProduct",
  UPDATED: "Updated",
  NOTE_UPDATED: "updated",
  VAT: "vat",
  GST: "gst",
  PST: "pst",
  YELLOWDARK: "yellowDark",
  YES: "Yes",
  ZERO_PRICE: "0.00",
  ZERO_PRICE_INT: "00",
  items: "Total items: ",
  none: "None",
  attachmentType,
  color,
  currency,
  days,
  daysArray,
  displayPlan,
  displayStatus,
  editor,
  featureError,
  featureVersion,
  financialStatus,
  fulfillmentTypes,
  gql,
  imageTypes,
  imageURL,
  manageVendorDetails,
  onboardingSteps,
  options,
  orderVersion,
  plan,
  productVersion,
  orderVersion,
  providerList,
  quoteStatus,
  returnReason,
  symbol,
  url,
  userKey,
  value,
  weightValue,
};

export default constant;
export { providerList };
