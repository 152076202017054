import { emailSchema, phoneNumberSchema } from "app/orders/modules/operator/features/manageCustomerDetails/yup/schema";
import { yupHelper } from "lib/helpers";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case "email":
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case "phone":
      promise = validateValue(phoneNumberSchema(cms), { phoneNumber: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
