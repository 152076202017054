import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-apollo";
import { Badge, Card, Caption, Heading, Layout, ResourceItem, Stack, Tabs } from "@shopify/polaris";

// import helper components
import { ResourceList, Banner } from "lib/components";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { withErrorBoundary } from "lib/hoc";

import { GET_BULK_LIST } from "app/userManagement/apollo/queries/index";

import logsData from "./cms/logsData";

const ViewEditActivity = () => {
  const { match, history, cms = {} } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;

  const [selected, setSelected] = useState(0);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [search, setSearch] = useState(queryParam.search || "");
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    const tabName = logsData(cms).tabs[selectedTabIndex].content.toLowerCase();
    baseHelper.setUrl(history, { filter: tabName, page: 1 });
  };
  const inputData = {
    logId: id,
    filter: queryParam.filter,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(queryParam.perPage, 10) || 10,
    search: queryParam.search,
  };

  const { data: viewLogsData, loading: viewLogsLoading, error: viewLogsError } = useQuery(GET_BULK_LIST, {
    variables: {
      input: inputData,
    },
  });

  const responseData = baseHelper.getResponseData(viewLogsData, constant.gql.GET_BULK_LIST);
  const responseDataError = baseHelper.getResponseError(viewLogsData, constant.gql.GET_BULK_LIST);
  const { bulkLog = {}, count = "" } = responseData;

  const handleSortChange = (selectedValue) => {
    setSortValue(selectedValue);
    const sort = selectedValue.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };

  useEffect(() => {
    if (viewLogsError) {
      setBanner({ isOpen: true, title: errorHelper.parse(viewLogsError), status: constant.CRITICAL });
    }
  }, [viewLogsError]);

  useEffect(() => {
    if (responseDataError) {
      setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
    }
  }, [responseDataError]);

  const handleClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "", page: 1 });
  };

  const renderItems = (item) => {
    const { error, field: entity, updatedAt, _id, status, type: entityType } = item;
    return (
      <ResourceItem id={_id} accessibilityLabel={`${cms("label.viewDetails", { entity })}`}>
        <Stack wrap={false}>
          <Stack.Item fill>
            <Heading>
              <div className="bulk-view-ellipsis">
                {baseHelper.activityDescription({ entity, entityType, status: status.toLowerCase(), error, cms })}
              </div>
            </Heading>
            <Caption>{`${cms("label.timeStamp")}: ${baseHelper.formatDate(updatedAt)}`}</Caption>
          </Stack.Item>
          <Stack.Item>
            <Badge status={baseHelper.getBadgeType(status.toLowerCase())}>{baseHelper.ucFirst(status)}</Badge>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({ isOpen: false, title: "", status: "" });
            }}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Card>
          <Tabs tabs={logsData(cms).tabs} selected={selected} onSelect={handleTabChange}>
            <ResourceList
              loading={viewLogsLoading}
              items={bulkLog}
              sortOptions={logsData(cms).sortOptions}
              sortValue={sortValue}
              onSortChange={handleSortChange}
              onQueryClear={handleClear}
              renderItem={renderItems}
              count={count}
              resourceName={cms("label.resourceName")}
              queryValue={search}
              onQueryChange={handleSearchChange}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </Tabs>
        </Card>
      </Layout.Section>
    </>
  );
};

export default withErrorBoundary(ViewEditActivity);
