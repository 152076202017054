import { gql } from "apollo-boost";

const BULK_EDIT_PRODUCT = gql`
  mutation bulkEditProduct($input: BulkEditProduct!) {
    bulkEditProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default BULK_EDIT_PRODUCT;
