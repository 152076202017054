import { gql } from "apollo-boost";

const GET_BULK_INVITE_LIST = gql`
  query getBulkInviteList($input: GetListInput) {
    getBulkInviteList(input: $input) {
      data {
        bulkInviteList {
          _id
          createdAt
          email
          emailStatus
          resentCount
          sentBy
          signedUp
          status
          storeName
        }
        count
      }
      status
      error
    }
  }
`;

const GET_BULK_EMAIL_TEMPLATE = gql`
  query {
    getBulkEmailTemplate {
      data {
        _id
        label
        message
        subject
      }
      status
      error
    }
  }
`;

export { GET_BULK_INVITE_LIST, GET_BULK_EMAIL_TEMPLATE };
