import { baseHelper } from "lib/helpers";

class PaymentHelper {
  /**
   * @desc To return payments csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  paymentCSVData = (orderLines = [], settingResponse = {}, isCsv = false, isPdf = false, isVendor = false) => {
    const settingData = settingResponse.export;
    const { isExcludeShippingAmount = false } = settingResponse || {};
    const {
      actualPayoutDate,
      commissionTax,
      createdAt,
      date,
      discount,
      invoiceId,
      lineItemId,
      markupPrice,
      operatorCommission,
      orderNumber,
      orderPayout,
      paymentStatus,
      price,
      product,
      quantity,
      shipping,
      shippingPayout,
      shippingTax,
      sku,
      tax,
      totalVendorPayout,
      vendor,
      vendorAmount,
      status,
      refund
    } = settingData || {};

    const paymentObj = {
      date: (date && date.label) || "Date",
      orderNumber: (orderNumber && orderNumber.label) || "Order Number",
      invoiceId: (invoiceId && invoiceId.label) || "Invoice Id",
      lineItemId: (lineItemId && lineItemId.label) || "Lineitem Id",
      product: (product && product.label) || "Items",
      sku: (sku && sku.label) || "SKU",
      quantity: (quantity && quantity.label) || "Quantity",
      price: (price && price.label) || "Price",
      discount: (discount && discount.label) || "Discount",
      paymentStatus: (paymentStatus && paymentStatus.label) || "Payment Status",
      createdAt: (lineItemId && createdAt.label) || "Created At",
      tax: (tax && tax.label) || "TAX",
      shipping: (shipping && shipping.label) || "Shipping",
      shippingTax: (shippingTax && shippingTax.label) || "Tax on Shipping",
      operatorCommission: (operatorCommission && operatorCommission.label) || "Operator Commission",
      commissionTax: (commissionTax && commissionTax.label) || "Tax on Operator Commission",
      // vendorAmount: (vendorAmount && vendorAmount.label) || "Vendor Amount",
      orderPayout: (orderPayout && orderPayout.label) || "Order Payout",
      actualPayoutDate: actualPayoutDate?.label || "Actual Payout Date",
      shippingPayout: (shippingPayout && shippingPayout.label) || "Shipping Payout",
      totalVendorPayout: (totalVendorPayout && totalVendorPayout.label) || "Total Vendor Payout",
      status: (status && status.label) || "Order Status",
      refund: (refund && refund.label) || "Refunded",
    };

    if (!isVendor) {
      paymentObj.vendor = (vendor && vendor.label) || "Vendor";
      // paymentObj.markupPrice = (markupPrice && markupPrice.label) || "Markup Price";
    }
    const updatedArray = [];
    if (settingData) {
      const notAllowedCsvKeys = Object.keys(settingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = settingData[item] && settingData[item].isHideCsv;
        }

        if (isPdf) {
          hideArray = settingData[item] && settingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedCsvKeys.map((key) => {
        updatedArray.push(paymentObj[key]);
        return false;
      });
    }

    const paymentArray = [
      (date && date.label) || "Date",
      (orderNumber && orderNumber.label) || "Order Number",
      (invoiceId && invoiceId.label) || "Invoice Id",
      (lineItemId && lineItemId.label) || "Lineitem Id",
      (product && product.label) || "Items",
      (sku && sku.label) || "SKU",
      (quantity && quantity.label) || "Quantity",
      (price && price.label) || "Price",
      (discount && discount.label) || "Discount",
      (paymentStatus && paymentStatus.label) || "Payment Status",
      (lineItemId && createdAt.label) || "Created At",
      (tax && tax.label) || "TAX",
      (shipping && shipping.label) || "Shipping",
      (shippingTax && shippingTax.label) || "Tax on Shipping",
      (operatorCommission && operatorCommission.label) || "Operator Commission",
      (commissionTax && commissionTax.label) || "Tax on Operator Commission",
      // (vendorAmount && vendorAmount.label) || "Vendor Amount",
      (orderPayout && orderPayout.label) || "Order Payout",
      actualPayoutDate?.label || "Actual Payout Date",
      (shippingPayout && shippingPayout.label) || "Shipping Payout",
      (totalVendorPayout && totalVendorPayout.label) || "Total Vendor Payout",
      (status && status.label) || "Order Status",
      (refund && refund.label) || "Refunded"
    ];

    if (!isVendor) {
      paymentArray.push((vendor && vendor.label) || "Vendor");
      // paymentArray.push((markupPrice && markupPrice.label) || "Markup Price");
    }
    const removeIndex = [];

    updatedArray.map((arr) => {
      removeIndex.push(paymentArray.indexOf(arr));
      return false;
    });

    const differenceArray = paymentArray.filter((item) => !updatedArray.includes(item));

    const newPaymentArray = [];
    newPaymentArray[0] = differenceArray;
    orderLines.forEach((item, idx) => {
      let totalCommissionPrice = 0;
      let totalItemPrice = 0;
      let totalPriceTax = 0;
      let totalShippingPrice = 0;
      let totalVendorPayout = 0;
      const getItemCommissionTax = () => {
        const priceTax = baseHelper.getPrice(parseFloat(item.priceTaxRate || 0) / 100);
        const totalPriceInclusiveTax = baseHelper.getPrice(item.price * item.quantity);
        const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(priceTax)));
        const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
        const sellerCommissionInPercent = (item.commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
        const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
        return sellerTaxOnCommission;
      };
      const { isOrderBasedCommission } = item;
      totalPriceTax = parseFloat(item.price) - parseFloat(item.price) / (1 + parseFloat(item.priceTaxRate) / 100);
      totalItemPrice = baseHelper.formatPrice(
        item.price * (item.actualQuantity || 1) - item.vendorDiscount * (item.actualQuantity || 1)
      );
      totalShippingPrice = isExcludeShippingAmount
        ? 0
        : baseHelper.formatPrice(item.shippingAmount + (item.shippingTax || 0));
      totalCommissionPrice = baseHelper.formatPrice(
        parseFloat(item.commissionSeller) + parseFloat(item.commissionTax || 0)
      );
      totalVendorPayout = baseHelper.formatPrice(
        parseFloat(item.commissionVendor) + parseFloat(totalShippingPrice) /* - parseFloat(totalCommissionPrice) */
      );
      newPaymentArray[idx + 1] = [
        baseHelper.formatDate(item.createdAt),
        `#${item.orderNumber}`,
        `${item.shopifyOrderId}`,
        `${item.shopifyLineItemId}`,
        item.title,
        item.sku,
        item.quantity,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.price || 0)}`, // ${item.moneyFormat}
        `${item.moneyFormat} ${baseHelper.formatPrice(item.vendorDiscount || 0)}`,
        item.accountingStatus,
        baseHelper.formatDate(item.createdAt),

        isOrderBasedCommission
          ? `${item.moneyFormat} ${baseHelper.formatPrice(item.totalTax)}`
          : totalPriceTax
          ? `${item.moneyFormat} ${baseHelper.formatPrice(totalPriceTax)} (incl.)`
          : `${item.moneyFormat} ${baseHelper.formatPrice(0)}`,

        `${item.moneyFormat} ${baseHelper.formatPrice(item.shippingAmount || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.shippingTax || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.commissionSeller || 0)}`,

        item.priceTaxRate && !item.commissionTax
          ? `${item.moneyFormat} ${baseHelper.formatPrice(getItemCommissionTax() || 0)} (incl.)`
          : `${item.moneyFormat} ${baseHelper.formatPrice(item.commissionTax || 0)}`,

        // `${baseHelper.formatPrice(item.commissionVendor || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.commissionVendor || 0)}`,
        `${baseHelper.formatDate(item.actualPayoutDate || "")}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(totalShippingPrice || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(totalVendorPayout || 0)}`,
         item.status,
        item.isRefunded ? "Yes" : "No",
        (!isVendor && item.vendor) || null,
        // (!isVendor && item.markUpPrice) || null,
      ].filter((value, index) => {
        return removeIndex.indexOf(index) === -1;
      });
    });
    return newPaymentArray;
  };
}

export default new PaymentHelper();
