import React, { useState, useEffect, useContext } from "react";
import {
  Banner,
  Caption,
  Card,
  Checkbox,
  DataTable,
  Layout,
  PageActions,
  TextField,
  TextStyle,
} from "@shopify/polaris";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { PrivateContext } from "lib/context";

import { Spinner, Toast } from "lib/components";

import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";

import constant from "lib/constant/constant";
import validate from "app/setup/modules/generic/productFormLabel/validate";
import StyledTable from "app/setup/modules/generic/productFormLabel/style";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { PRODUCT_FORM } from "app/setup/apollo/mutations";
import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";
import { GET_WHOLESALE_SETTING } from "app/product/apollo/queries";
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE } from "app/productOld/apollo/queries";

const { gql, PRODUCT_TYPE, TAGS } = constant;

const ProductFormLabels = () => {
  const [message, setMessage] = useState("");
  const [rowData, setRowData] = useState({
    title: {
      label: "",
      isRequired: true,
    },
    description: {
      label: "",
      isRequired: false,
    },
    price: {
      label: "",
      isRequired: false,
    },
    comparePrice: {
      label: "",
      isRequired: false,
    },
    wholesalePrice: {
      label: "",
      isRequired: false,
    },
    markup: {
      label: "",
      isRequired: false,
    },
    discount: {
      label: "",
      isRequired: false,
    },
    vendor: {
      label: "",
      isRequired: true,
    },
    productType: {
      label: "",
      isRequired: false,
    },
    tags: {
      label: "",
      isRequired: false,
    },
    catalog: {
      label: "",
      isRequired: false,
    },
    sku: {
      label: "",
      isRequired: false,
    },
    barcode: {
      label: "",
      isRequired: false,
    },
    inventory: {
      label: "",
      isRequired: false,
    },
    quantity: {
      label: "",
      isRequired: false,
    },
    physical: {
      label: "",
      isRequired: false,
    },
    location: {
      label: "",
      isRequired: false,
    },
  });

  const { currentUser, history, cms } = useContext(PrivateContext);
  const [isHideCollection, setIsHideCollection] = useState(true);
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isHideTag, setIsHideTag] = useState(false);
  const [isHideType, setIsHideType] = useState(false);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const isVendor = baseHelper.isVendor(currentUser);

  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    barcode: "",
    catalog: "",
    comparePrice: "",
    description: "",
    discount: "",
    inventory: "",
    location: "",
    markup: "",
    physical: "",
    price: "",
    productType: "",
    quantity: "",
    sku: "",
    tags: "",
    title: "",
    vendor: "",
    wholesalePrice: "",
  });
  const [lastUpdated, setLastUpdated] = useState("");
  const [isProductType, setIsProductType] = useState(true);
  const [isProductTag, setIsProductTag] = useState(true);

  const [productForm, { loading: productFormLoading }] = useMutation(PRODUCT_FORM);

  const { loading: loadingProductForm, data: dataProductForm, refetch } = useQuery(GET_PRODUCT_FORM);
  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);
  const { loading: productTagsLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  useEffect(() => {
    if (productTagsData) {
      const productTagsDataResponse = baseHelper.getResponseData(productTagsData, gql.GET_PRODUCT_TAGS);
      const { tags = [] } = productTagsDataResponse;
      if (tags && tags.length) {
        setIsProductTag(false);
      }
    }
    if (productTypeData) {
      const productTypeDataResponse = baseHelper.getResponseData(productTypeData, gql.GET_PRODUCT_TYPE);
      if (productTypeDataResponse.length) {
        setIsProductType(false);
      }
    }
  }, [productTagsData, productTypeData]);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      const productFormError = baseHelper.getResponseError(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      const keys = Object.keys(productLabel);
      keys.forEach((key) => {
        if (!productLabel[key] && key !== constant.CREATED_AT && key !== constant.UPDATE && key !== constant.TITLE) {
          productLabel[key] = {
            label: "",
            isRequired: false,
          };
        }
      });
      if (productLabel && productLabel.title) {
        setLastUpdated(productLabel.updatedAt);
        setRowData(productLabel);
      }
      if (productFormError) {
        setBannerStatus({ isOpen: false, title: productFormError, status: constant.CRITICAL });
      }
    }
  }, [dataProductForm]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  const verifyValues = () => {
    setDisableSubmit(true);
    const newLabel = Object.values(rowData)
      .filter((item) => item && item.label)
      .map((item) => item.label);

    let response = false;
    if ([...new Set(newLabel)].length !== newLabel.length) {
      response = true;
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.duplicate"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const productFormLabel = async () => {
    delete rowData.createdAt;
    delete rowData.updatedAt;
    const inputData = rowData;
    if (isVendor) {
      inputData.vendor = {
        label: "",
        isRequired: true,
      };
    }
    productForm({ variables: { input: inputData } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_FORM_SETTING);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_FORM_SETTING);
          if (resData) {
            setMessage(cms("message.success.update"));
            setBannerStatus({ isOpen: false, title: "", status: "" });
          }
          if (responseDataError) {
            setBannerStatus({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
          refetch();
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const { data: productSettingData } = useQuery(GET_PRODUCT_SETTING);

  useEffect(() => {
    if (productSettingData) {
      const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
      const {
        isHideTagAndType: hideTagAndType,
        isHideTag: hideTag,
        isHideType: hideType,
        isCatalogHidden: hideCollection,
      } = productSetting || {};
      setIsHideCollection(hideCollection);
      setIsHideTagAndType(hideTagAndType);
      setIsHideTag(hideTag);
      setIsHideType(hideType);
    }
  }, [productSettingData]);

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const updateData = rowData[key];
    if (updateData.label !== val) {
      setDisableSubmit(false);
    }
    updateData.label = val;
    setRowData({ ...rowData, [key]: updateData });
  };

  const handleCheckbox = (key) => {
    setDisableSubmit(false);
    const updateData = rowData[key];
    setRowData({ ...rowData, [key]: { ...updateData, isRequired: !updateData.isRequired } });
  };

  const acceptsStringOnly = (value, prevValue) => {
    const val = (baseHelper.stringNotAcceptSpaceAtStart(value) && value) || (value !== "" && prevValue) || "";
    const result = (baseHelper.acceptOnlyString(val) && val) || (val !== "" && prevValue) || "";
    return result;
  };

  const handleValidate = async (value, field) => {
    const validationError = await validate(value, cms("message.error.limit"));
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const buttonDisable = () => {
    let disable = false;
    Object.keys(errorMessage).forEach((item) => {
      if (errorMessage[item] !== "") {
        disable = true;
      }
    });
    return disable;
  };

  const columnContent = constant.PRODUCT_COLUMN;
  const columnHeading = [cms("label.current"), cms("label.new"), cms("label.required")];

  const getRows = () => {
    const dataKeys = Object.keys(rowData);
    let roleData = isVendor
      ? dataKeys.filter(
          (item) =>
            (isHideCollection
              ? item !== constant.VENDOR && item !== constant.MARKUP_KEY && item !== constant.CATALOG
              : item !== constant.VENDOR && item !== constant.MARKUP_KEY) &&
            (!isHideTag
              ? item !== constant.VENDOR && item !== constant.MARKUP_KEY && item !== constant.PRODUCT_TAGS
              : item !== constant.VENDOR && item !== constant.MARKUP_KEY) &&
            (!isHideType
              ? item !== constant.VENDOR && item !== constant.MARKUP_KEY && item !== constant.PRODUCT_TYPE
              : item !== constant.VENDOR && item !== constant.MARKUP_KEY)
        )
      : dataKeys.filter((item) => item !== constant.LOCATION);

    roleData = roleData.filter((item) => (!isWholesalePrice && item === constant.WHOLESALE_PRICE ? "" : item));

    return (roleData || [])
      .filter((item) => item !== constant.CREATED_AT && item !== constant.UPDATE)
      .map((item) => {
        const data = rowData[item];
        const currLabel = <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
        const newText = (
          <TextField
            value={data && data.label}
            onChange={(value) => handleLabelChange(acceptsStringOnly(value, data.label), item)}
            onBlur={() => handleValidate(data && data.label, item)}
            error={errorMessage && errorMessage[item]}
            disabled={(isProductType && [PRODUCT_TYPE].includes(item)) || (isProductTag && [TAGS].includes(item))}
          />
        );
        const hideColumn = (
          <Checkbox
            disabled={
              [constant.TITLE, constant.VENDOR].includes(item) ||
              (isProductType && [PRODUCT_TYPE].includes(item)) ||
              (isProductTag && [TAGS].includes(item))
            }
            checked={data && data.isRequired === true}
            onChange={() => handleCheckbox(item)}
          />
        );
        return [[currLabel], [newText], [hideColumn]];
      });
  };

  const tableRowData = (getRows() || []).filter((item) => item);

  if (loadingProductForm || wholesaleLoading || productTagsLoading || productTypeLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card
          sectioned
          title={[
            <div>
              {cms("card.title")}
              {lastUpdated && (
                <Caption>
                  <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
                </Caption>
              )}
            </div>,
          ]}
        >
          <StyledTable className="product-form-label-table">
            <DataTable columnContentTypes={columnContent} headings={columnHeading} rows={tableRowData} />
          </StyledTable>
          {lastUpdated && (
            <PageActions
              primaryAction={{
                content: cms("common.button.update"),
                onAction: () => {
                  const update = verifyValues();
                  if (!update) productFormLabel();
                },
                disabled: buttonDisable() || productFormLoading || disableSubmit,
                loading: productFormLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </Card>
        {!lastUpdated && (
          <PageActions
            primaryAction={{
              content: cms("common.button.submit"),
              onAction: () => {
                const update = verifyValues();
                if (!update) productFormLabel();
              },
              disabled: buttonDisable() || productFormLoading || disableSubmit,
              loading: productFormLoading,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
      <Toast message={message} />
    </div>
  );
};

export default withFeature(withErrorBoundary(ProductFormLabels), { feature: constant.PRODUCT_FORM_LABEL_SETTING });
