import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import OperatorUpgradAppPermission from "./operator/OperatorUpgradAppPermission";

const { userKey } = constant;
const { operator, provider } = userKey;

const UpgradAppPermission = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorUpgradAppPermission />;
    }
    return null;
  };
  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const UpgradAppPermissionComponent = getComponent(currentUserRole);
  return UpgradAppPermissionComponent;
}

export default UpgradAppPermission;
