import { gql } from "apollo-boost";

const GET_CUSTOMER = gql`
  query getCustomer {
    getCustomer {
      status
      error
      data {
        customer {
          email
          phoneNumber
          isEmailChecked
          isPhoneNumberChecked
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export default GET_CUSTOMER;
