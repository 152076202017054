const quickReportConfig = ({ cms = {}, count = null, path = "" }) => {
  const {
    orderCount,
    productCount,
    paymentCount,
    magentoTempProductCount,
    prestaShopTempProductCount,
    wooTempProductCount,
    wixTempProductCount,
    shopifyProductCount,
  } = count;

  const {
    archived: archivedOrderCount = null,
    pendingAcceptance: pendingAcceptanceOrderCount = null,
    pendingShipment: pendingShipmentOrderCount = null,
    rejected: rejectedOrderCount = null,
    return: returnOrderCount = null,
    shipped: shippedOrderCount = null,
    total: totalOrderCount = null,
    completed: completedOrderCount = null,
    pending: pendingOrderCount = null,
    processing: processingOrderCount = null,
    refunded: refundedOrderCount = null,
  } = orderCount || {};

  const {
    disputed: disputedPaymentCount = null,
    due: duePaymentCount = null,
    forcasted: forcastedPaymentCount = null,
    paid: paidPaymentCount = null,
    total: totalPaymentCount = null,
  } = paymentCount;

  const {
    approved: approvedProductCount = null,
    delete: deletedProductCount = null,
    new: newProductCount = null,
    reject: rejectedProductCount = null,
    review: reviewProductCount = null,
    reviewed: reviewedProductCount = null,
    total: totalProductCount = null,
  } = productCount;

  const { all: allCount = null, synced: syncedCount = null, notSynced: notSyncedCount = null } = shopifyProductCount;

  const { product: totalTempProductCount = null } = wooTempProductCount;
  const { product: totalWixTempProductCount = null } = wixTempProductCount;
  const { product: totalMagentoTempProductCount = null } = magentoTempProductCount;
  const { product: totalPrestaShopTempProductCount = null } = prestaShopTempProductCount;

  const config = {
    "/": [
      {
        caption: cms("section.quickReport.caption.order"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.order"),
      },
      {
        caption: cms("section.quickReport.caption.product"),
        count: totalProductCount,
        label: cms("section.quickReport.label.product"),
      },
    ],
    "/orders": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.acceptance"),
        count: pendingAcceptanceOrderCount,
        label: cms("section.quickReport.label.acceptance"),
      },
      {
        caption: cms("section.quickReport.caption.shipment"),
        count: pendingShipmentOrderCount,
        label: cms("section.quickReport.label.shipment"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedOrderCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.ship"),
        count: shippedOrderCount,
        label: cms("section.quickReport.label.ship"),
      },
      {
        caption: cms("section.quickReport.caption.archive"),
        count: archivedOrderCount,
        label: cms("section.quickReport.label.archive"),
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: returnOrderCount,
        label: cms("section.quickReport.label.return"),
      },
    ],
    "/order-lake": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalOrderCount,
        label: cms("section.quickReport.label.all") || "All Count",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: completedOrderCount,
        label: cms("section.quickReport.label.return") || "Completed",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: pendingOrderCount,
        label: cms("section.quickReport.label.return") || "Pending",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: processingOrderCount,
        label: cms("section.quickReport.label.return") || "Processing",
      },
      {
        caption: cms("section.quickReport.caption.return"),
        count: refundedOrderCount,
        label: cms("section.quickReport.label.return") || "Refunded",
      },
    ],
    "/payments": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalPaymentCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.forecast"),
        count: forcastedPaymentCount,
        label: cms("section.quickReport.label.forecast"),
      },
      {
        caption: cms("section.quickReport.caption.due"),
        count: duePaymentCount,
        label: cms("section.quickReport.label.due"),
      },
      {
        caption: cms("section.quickReport.caption.paid"),
        count: paidPaymentCount,
        label: cms("section.quickReport.label.paid"),
      },
      {
        caption: cms("section.quickReport.caption.dispute"),
        count: disputedPaymentCount,
        label: cms("section.quickReport.label.dispute"),
      },
    ],
    "/products": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalProductCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.approve"),
        count: approvedProductCount,
        label: cms("section.quickReport.label.approve"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newProductCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.review"),
        count: reviewProductCount,
        label: cms("section.quickReport.label.review"),
      },
      {
        caption: cms("section.quickReport.caption.reviewed"),
        count: reviewedProductCount,
        label: cms("section.quickReport.label.reviewed"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedProductCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.delete"),
        count: deletedProductCount,
        label: cms("section.quickReport.label.delete"),
      },
    ],
    "/products/shopify": [
      {
        count: allCount,
        label: cms("label.all"),
      },
      {
        count: syncedCount,
        label: cms("label.synced"),
      },
      {
        count: notSyncedCount,
        label: cms("label.notSynced"),
      },
    ],
    "/product-lake": [
      {
        caption: cms("section.quickReport.caption.all"),
        count: totalProductCount,
        label: cms("section.quickReport.label.all"),
      },
      {
        caption: cms("section.quickReport.caption.approve"),
        count: approvedProductCount,
        label: cms("section.quickReport.label.approve"),
      },
      {
        caption: cms("section.quickReport.caption.new"),
        count: newProductCount,
        label: cms("section.quickReport.label.new"),
      },
      {
        caption: cms("section.quickReport.caption.review"),
        count: reviewProductCount,
        label: cms("section.quickReport.label.review"),
      },
      {
        caption: cms("section.quickReport.caption.reviewed"),
        count: reviewedProductCount,
        label: cms("section.quickReport.label.reviewed"),
      },
      {
        caption: cms("section.quickReport.caption.reject"),
        count: rejectedProductCount,
        label: cms("section.quickReport.label.reject"),
      },
      {
        caption: cms("section.quickReport.caption.delete"),
        count: deletedProductCount,
        label: cms("section.quickReport.label.delete"),
      },
    ],
    "/products/woocommerce": [
      {
        caption: cms("section.quickReport.caption.temp"),
        count: totalTempProductCount,
        label: cms("section.quickReport.label.temp"),
      },
    ],
    "/products/magento": [
      {
        caption: cms("section.quickReport.caption.temp"),
        count: totalMagentoTempProductCount,
        label: cms("section.quickReport.label.temp"),
      },
    ],
    "/products/prestashop": [
      {
        caption: cms("section.quickReport.caption.temp"),
        count: totalPrestaShopTempProductCount,
        label: cms("section.quickReport.label.temp"),
      },
    ],
    "/products/wix": [
      {
        caption: cms("section.quickReport.caption.temp"),
        count: totalWixTempProductCount,
        label: cms("section.quickReport.label.temp"),
      },
    ],
  };
  return config[path];
};

export default quickReportConfig;
