import React, { useContext } from "react";
import { Modal, TextContainer } from "@shopify/polaris";

// import propType
import propType from "prop-types";

// import constant
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const ConfirmationModal = (props) => {
  const { cms } = useContext(PrivateContext);
  const resetAll = constant.RESET_ALL;
  const muteAll = constant.MUTE_ALL;
  const { isOpen, loadingLabel, modalLabel, onClose, onSubmit } = props;
  const isLoadingLabel = loadingLabel === resetAll || loadingLabel === muteAll;
  const isModalForMuteAll = modalLabel === muteAll;
  const onPrimaryActionLabel = isModalForMuteAll ? muteAll : resetAll;

  const modalTitleMute = `${cms("modal.muteAll.title")}`;
  const modalTitleReset = `${cms("modal.resetAll.title")}`;
  const primaryAction1 = `${cms("modal.muteAll.button.primary")}`;
  const primaryAction2 = `${cms("modal.resetAll.button.primary")}`;
  const modalBodyContent1 = `${cms("modal.resetAll.content")}`;
  const modalBodyContent2 = `${cms("modal.muteAll.content")}`;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={isModalForMuteAll ? modalTitleMute : modalTitleReset}
        primaryAction={{
          content: isModalForMuteAll ? primaryAction1 : primaryAction2,
          onAction: () => onSubmit(onPrimaryActionLabel),
          loading: isLoadingLabel,
        }}
        secondaryActions={[
          {
            content: `${cms("common.button.cancel")}`,
            onAction: () => onClose(),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>{isModalForMuteAll ? modalBodyContent2 : modalBodyContent1}</TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

ConfirmationModal.defaultProps = propType.default;
ConfirmationModal.propTypes = propType.type;
export default ConfirmationModal;
