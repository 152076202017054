import { gql } from "apollo-boost";

const BULK_ACCEPT = gql`
  mutation bulkAccept($input: BulkAccept) {
    bulkAccept(input: $input) {
      status
      error
      data
    }
  }
`;

export default BULK_ACCEPT;
