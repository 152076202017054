import { gql } from "apollo-boost";

const STANDARD_DISCOUNT_SETTING = gql`
  mutation manageDiscount($input: ManageDiscount!) {
    manageDiscount(input: $input) {
      status
      data
      error
    }
  }
`;

export default STANDARD_DISCOUNT_SETTING;
