import React from "react";

import constant from "lib/constant/constant";
import { OperatorChat } from "app/userManagement/modules/operator/features";
import { ProviderChat } from "app/userManagement/modules/provider/features";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { operator, provider } = userKey;

const Chat = () => {
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorChat />;
    }
    if (role === provider) {
      return <ProviderChat />;
    }
    return "";
  };
  const currentUserRole = storageHelper.get("userRole");
  const ChatComponent = getComponent(currentUserRole);
  return ChatComponent;
};

export default withErrorBoundary(Chat);
