import React from "react";
import { SkeletonBodyText, Icon } from "@shopify/polaris";
import { SettingsMajor } from "@shopify/polaris-icons";

const settingIcon = <Icon source={SettingsMajor} color="base" />;

const providerTabs = (cms, isLoading, currentUser) => [
  {
    id: "home",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.home"),
    activeRoutes: [],
    link: "/",
    panelID: "homeContent",
  },
  {
    id: "Orders",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.orders"),
    activeRoutes: ["/order-lake/view/:id", "/order-lake", "/orders/view/:id", "/orders/bulk"],
    link: currentUser && currentUser.ecommercePlatform ? "/order-lake" : "/orders",
    panelID: "ordersContent",
  },
  {
    id: "Products",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.products"),
    panelID: "productsContent",
    link: currentUser && currentUser.ecommercePlatform ? "/product-lake" : "/products",
    activeRoutes: [
      "/product-lake",
      "/products/add",
      "/product-lake/add",
      "/lake-variants/add/:productId",
      "/products/add/shop",
      "/products/bulk/edit",
      "/products/edit",
      "/products/edit/:id",
      "/product-lake/edit/:id",
      "/products/service/edit/:id",
      "/products/shopify",
      "/products/woocommerce",
      "/products/view/:id",
      "/products/wix",
      "/products/magento",
      "/products/prestashop",
      "/product-lake/shopify",
      "/product-lake/bigcommerce",
      "/providers/shopify-callback",
      "/variants/add/:productId",
      "/lake-variants/edit/:productId/:id",
      "/variants/edit/:productId/:id",
      "/variants/service/add/:productId",
      "/variants/service/edit/:productId/:id",
      // "/v1/products/add",
      // "/v1/products/edit/:id",
      // "/v1/variants/add/:productId",
    ],
  },
  {
    id: "Payments",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.payments"),
    activeRoutes: ["/payments/credit-note", "/payment-lake"],
    link: currentUser && currentUser.ecommercePlatform ? "/payment-lake" : "/payments",
    panelID: "paymentsContent",
  },
  {
    id: "Quotes",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.operator.quotes"),
    panelID: "quoteContent",
    link: "/quotes",
    activeRoutes: ["/quotes"],
  },
  // {
  //   id: "Reporting",
  //   content: (isLoading && <SkeletonBodyText lines={1} />) || "Reporting",
  //   panelID: "reportingContent",
  //   link: "/reporting",
  //   activeRoutes: ["/reporting"],
  // },
  {
    id: "chat",
    content: (isLoading && <SkeletonBodyText lines={1} />) || cms("common.label.tabs.provider.chatInbox"),
    link: "/chat",
    activeRoutes: [],
    panelID: "chat",
  },
  {
    id: "Analytics",
    content: (isLoading && <SkeletonBodyText lines={1} />) || "Analytics",
    link: "/analytics",
    activeRoutes: ["/analytics/order-analytics", "/analytics/product-analytics", "/analytics/report"],
    panelID: "analytics",
  },
  {
    id: "Settings",
    content: (isLoading && <SkeletonBodyText lines={1} />) || settingIcon,
    panelID: "settingsContent",
    link: "/setting",
    activeRoutes: [
      "/activity/bulk",
      "/activity/bulk-edit",
      "/activity/view/:id",
      "/activity/edit/view/:id",
      "/change-password",
      "/contact-us",
      "/payment-connect",
      "/profile",
      "/setting/provider/holiday",
      "/setting/order-expiry",
      "/setting/credit-note",
      "/setting/discount",
      "/setting/location",
      "/setting/product/export",
      "/setting/notification",
      "/setting/order",
      "/setting/order/discount",
      "/shipping",
      "/shipping/advance",
      "/setting/address",
      "/setting/carrier",
      "/setting/digest",
      "/setting/order/export",
      "/setting/payment/export",
      "/setting/product",
      "/setting/product/label",
      "/setting/report",
    ],
  },
];

export default providerTabs;
