/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { Banner, ResourceList } from "lib/components";
import { useQuery, useMutation } from "react-apollo";
import { Badge, Caption, Card, Heading, ResourceItem, Stack, Tabs, TextStyle, Thumbnail } from "@shopify/polaris";

import { baseHelper, imageHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { QuickReport } from "app/reports";

// import GET_BIG_COMMERCE_TEMP_PRODUCT_SUBSCRIPTION from "app/productLake/apollo/subscriptions/productLakeList";
import GET_BIG_COMMERCE_TEMP_PRODUCT_LIST from "app/productLake/apollo/queries/getTempBigCommerceProductList";
import {SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT} from "app/productLake/apollo/mutations";

import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import MoreFilters, { Filter } from "app/productLake/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";

const List = () => {
  const { cms = {}, currentUser = {}, history } = useContext(PrivateContext);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");

  const [dataToFetch, setDataToFetch] = useState("");
  const [values, setValues] = useState(searchVal || "");
  const [appliedFiltersValue, setAppliedFiltersValue] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const [queryValue, setQueryValue] = useState(getQueryParams.search || "");
  const [sortValue, setSortValue] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [popoverActive, setPopoverActive] = useState(true);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 20);
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || null);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  const [tempData, setTempData] = useState([]);

  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
    getQueryParams.filter = "";
  }

  const inputData = {
    list_filter: getQueryParams.list_filter,
    list_search: getQueryParams.list_search,
    search: getQueryParams.search,
    sort_name: getQueryParams.sort_name,
    sort_order: getQueryParams.sort_order,
    filter: getQueryParams.filter,
    page: parseInt(getQueryParams.page || page, 10),
    perPage: parseInt(getQueryParams.perPage || perPage, 10),
  };

  const { loading, data, subscribeToMore, refetch } = useQuery(GET_BIG_COMMERCE_TEMP_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions] = useMutation(SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_BIG_COMMERCE_TEMP_PRODUCT_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const tempDataResponse = baseHelper.getResponseData(data, "getTempBigCommerceProductList");

  useEffect(() => {
    if (tempDataResponse) {
      setTempData(tempDataResponse);
    }
  }, [tempDataResponse]);

  // useEffect(() => {
  //   subscribeToMore({
  //     document: GET_BIG_COMMERCE_TEMP_PRODUCT_SUBSCRIPTION,
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //       return refetch();
  //     },
  //   });
  // }, [subscribeToMore, refetch]);

  useEffect(() => {
    const paramValue = baseHelper.getQueryParams(history.location.search);
    const listFilter = (paramValue.list_filter && paramValue.list_filter.split(",")) || [];
    const listSearch = (paramValue.list_search && paramValue.list_search.split(",")) || [];
    const appliedFilters = [];
    listFilter.forEach((item, index) => {
      appliedFilters.push({
        key: item,
        value: listSearch[index],
      });
    });
    setAppliedFiltersValue(appliedFilters);
  }, [history.location.search, values]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabsData = [
    { id: "all", panelID: "all", content: cms("label.all"), value: constant.ALL },
    { id: "synced", panelID: "synced", content: cms("label.synced"), value: constant.SYNCED },
    { id: "unsynced", panelID: "unsynced", content: cms("label.notSynced"), value: constant.NOT_SYNCED },
    { id: "pending", panelID: "pending", content: cms("label.pending"), value: constant.PENDING },
  ];
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelectedItems([]);
      setSelected(selectedTabIndex);
      const filterValue = baseHelper.setQueryParams(history.location, {
        filter: tabsData[selectedTabIndex].value,
        tab: selectedTabIndex,
      });
      history.push(`${history.location.pathname}?${filterValue}`);
    },
    [history, tabsData]
  );

  const handleSync = async (eventKey, id = false) => {
    let formData = {};

    const searchParams = {
      search: searchVal,
    };

    formData = {
      // all: false,
      ids: selectedItems,
      key: eventKey,
      searchParams,
    };

    if (id) {
      formData.ids = [id];
      // formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }

    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT);
          const responseDataError = baseHelper.getResponseError(
            res.data,
            constant.gql.SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT
          );
          if (responseData) {
            const title = cms("message.success.requestBackground");
            setBanner({ isOpen: true, title, status: constant.SUCCESS });
            setSelectedItems([]);
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const bulkActions = [
    {
      content: cms("label.syncProducts"),
      disabled: selected === 1,
      onAction: () => handleSync(constant.APPROVE),
    },
  ];

  const handleChange = (val, _id) => {
    setSelectedButtonIndex(_id);
    if (val === constant.SYNC) {
      handleSync(constant.APPROVE, _id);
    }
  };

  const handleQueryClear = () => {
    handleSearchValue("");
    baseHelper.setUrl(history, { search: "" });
  };

  const topSpace = {
    marginTop: "0.5em",
  };
  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleTaggedWithRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setTaggedWith("");

    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (select) => {
    const sort = select.split("_");
    if (sort && sort.length === 2) {
      setSortValue(select);
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove(constant.SELECTED_FILTER);
    handleSelectedFilterRemove();
  };

  const filters = [
    {
      key: constant.SELECTED_FILTER,
      label: cms("label.filterBy"),
      filter: (
        <Filter
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: constant.TAGGED_WITH,
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={() => setBanner({ isOpen: false })}
          />
          <br />
        </>
      )}
      <Banner title={cms("banner.addProductInstruction")} isOpen status={constant.INFO} />
      <br />
      <Banner title={cms("banner.importedProductInstruction")} isOpen />
      <br />
      <QuickReport />

      <Card>
        <Tabs tabs={tabsData} selected={selected} onSelect={handleTabChange} />
        <ResourceList
          resourceName={{
            singular: baseHelper.ucFirst(constant.PRODUCT),
            plural: baseHelper.ucFirst(constant.PRODUCTS),
          }}
          items={tempData.tempProductList}
          idForItem={(item) => {
            const { _id } = item;
            return _id;
          }}
          filters={filters}
          appliedFilters={appliedFilters}
          handleClearAll={handleClearAll}
          selectable
          bulkActions={bulkActions}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          loading={loading}
          sortValue={sortValue}
          sortOptions={[
            { label: cms("label.dateDesc"), value: constant.UPDATE_DESC },
            { label: cms("label.dateAsc"), value: constant.UPDATE_ASC },
            { label: cms("label.productAsc"), value: "name_asc" },
            { label: cms("label.productDesc"), value: "name_desc" },
          ]}
          onSortChange={handleSort}
          onQueryChange={handleSearchValue}
          onQueryClear={handleQueryClear}
          queryValue={queryValue}
          count={tempData.count}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          renderItem={(item) => {
            const { _id, image, isBigCommerceSync, isSynced, name, updatedAt } = item;
            const thumbnailImage = image
              ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
              : constant.NOIMAGESNAP;
            const productSyncedStatusType = isBigCommerceSync && !isSynced ? constant.PENDING : constant.NOT_SYNCED;
            const syncedStatusType = isBigCommerceSync && isSynced ? constant.SYNCED : productSyncedStatusType;
            const productSyncedStatus = isBigCommerceSync && !isSynced ? cms("label.pending") : cms("label.notSynced");
            const syncedStatus = isBigCommerceSync && isSynced ? cms("label.synced") : productSyncedStatus;
            return (
              <ResourceItem id={_id}>
                <Stack wrap={false}>
                  <Stack.Item>
                    <Thumbnail size={constant.LARGE} source={thumbnailImage} alt={`${name} image`} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Heading element="h2">
                      <div className="ellipsisBigcommerceList">
                        <TextStyle variation="strong">{name}</TextStyle>
                      </div>
                    </Heading>
                    <div className="ellipsisBigcommerceList">
                      <Caption>
                        {`${cms("label.by")} `}
                        <b>{currentUser.brandName}</b>
                        {` ${cms("label.on")} ${baseHelper.formatDate(updatedAt)}`}
                      </Caption>
                    </div>
                    <div style={topSpace}>
                      <Stack>
                        <Stack.Item>
                          <Badge status={baseHelper.getBadgeType(syncedStatusType)}>
                            {baseHelper.ucFirst(syncedStatus)}
                          </Badge>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                      active={popoverActive[_id]}
                      setActive={() => setPopoverActive({ [_id]: !popoverActive[_id] })}
                      disabled={isBigCommerceSync}
                      loading={_id === selectedButtonIndex && listLoading}
                      options={[
                        {
                          content: cms("label.publish"),
                          onAction: () => handleChange(constant.SYNC, _id),
                          disabled: isBigCommerceSync,
                        },
                      ]}
                    />
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </Card>
    </>
  );
};

export default withFeature(withErrorBoundary(List), { feature: constant.BIGCOMMERCE_PRODUCT });
