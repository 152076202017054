import { gql } from "apollo-boost";

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate($input: UpdateEmailTemplate!) {
    updateEmailTemplate(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_EMAIL_TEMPLATE;
