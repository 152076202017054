import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Spinner } from "lib/components";
import { OperatorPaymentService } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;
const PaymentService = () => {
  const { isLoading } = useContext(PrivateContext);
  if (isLoading) {
    return <Spinner />;
  }
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorPaymentService />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const PaymentServiceComponent = getComponent(currentUserRole);
  return PaymentServiceComponent;
};

export default PaymentService;
