import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Layout,
  Heading,
  Icon,
  Link,
  Stack,
  Tabs,
  TextContainer,
  TextStyle,
  Thumbnail,
  Button,
} from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CustomersMajor,
  EmailMajor,
  HomeMajor,
  PhoneMajor,
  // ChatMajor,
} from "@shopify/polaris-icons";
import appConfig from "configuration";
// context
import { PrivateContext } from "lib/context";
// helper
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";
// components
import { Banner, SkeletonAnnotated, Spinner } from "lib/components";
import NotFound from "layout/private/components/notFound/notFound";

import { GET_VENDOR_PROFILE } from "app/vendors/apollo/queries";

import { RESEND_OTP_EMAIL } from "app/vendors/apollo/mutations";
// query
import { FETCH_VENDOR, BRAND_DETAILS } from "app/advanceVendor/apollo/queries";
// sub Featuresapp/advanceVendor/apollo/queries
import { Payment, Shipping, Additional, OrderList, ProductList, SocialLink } from "./subFeatures";
import { ProfileCard, StyledActionCard } from "./style";
// cms
import viewListCMS from "./cms/view";

const VendorView = () => {
  const { match, cms, history, currentUser } = useContext(PrivateContext);
  const { id } = match.params;
  const { gql } = constant;
  const { brandTabs } = viewListCMS;
  const { data: fetchVendorData, loading: fetchVendorLoading } = useQuery(FETCH_VENDOR, {
    variables: { input: { _id: id } },
  });
  const { data: brandDetailData, loading: brandLoading } = useQuery(BRAND_DETAILS, {
    variables: { input: { vendorId: id } },
  });

  const { location: { state: { profileEdit: isProfileEdit, isBrandChanged } = {} } = {} } = history;

  const noImageURL = cms("noImageURL");
  const [selectedTab, setSelectedTab] = useState(0);
  const [vendorData, setVendorData] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(false);
  const [brandImage, setBrandImage] = useState(noImageURL);
  const [isBrandImage, setIsBrandImage] = useState(false);
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [isShowProfileEditBanner, isSetShowProfileEditBanner] = useState(true);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
    brandName: "",
    checked: false,
  });

  const { providerList } = constant;
  const { brandPageURL } = appConfig;
  const { ecommercePlatform, shop } = currentUser;

  const onBrandPage = () => {
    const url = `${brandPageURL}/${providerList.BRAND}/${shop}/${vendorData.brandName}`;
    window.open(url);
  };

  const { data: vendorProfileData, loading: vendorProfileLoading } = useQuery(GET_VENDOR_PROFILE, {
    variables: { input: { vendorId: id } },
  });

  const currentVendor = baseHelper.getResponseData(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const resError = baseHelper.getResponseError(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const [resetVendorCredential, { loading: resetVendorLoading }] = useMutation(RESEND_OTP_EMAIL);

  const setProfileEditBanner = () => {
    if (isProfileEdit && isShowProfileEditBanner) {
      setBanner({
        isOpen: true,
        title: cms("message.success.profileUpdated"),
        status: constant.SUCCESS,
        children: (isBrandChanged && cms("message.shopifyPushMessage")) || null,
      });
      isSetShowProfileEditBanner(false);
    }
  };

  useEffect(() => {
    if (currentVendor) {
      const { vendorRow = {} } = currentVendor;
      const {
        _id: vendorId,
        firstName,
        lastName,
        email = {},
        phoneNumber,
        address,
        city,
        country,
        pinCode,
        brandName,
        isReadOnly,
      } = vendorRow || {};
      const { address: emailAddress } = email;
      setValues({
        vendorId,
        firstName,
        lastName,
        email: emailAddress,
        phoneNumber,
        streetAddress: address,
        city,
        country,
        postalCode: pinCode,
        brandName,
        checked: isReadOnly,
      });
      setProfileEditBanner();
    }
    if (resError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: resError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVendor, resError]);

  const resetVendor = () => {
    const vendorInputData = {
      id: values.vendorId,
      email: values.email,
    };
    resetVendorCredential({ variables: { input: vendorInputData } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.RESEND_OTP_EMAIL);
        let bannerData = {
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("message.providerList.resetPassword"),
        };
        if (responseError) {
          bannerData = { isOpen: true, status: constant.CRITICAL, title: responseError };
        }
        setBanner(bannerData);
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  useEffect(() => {
    const resData = baseHelper.getResponseData(fetchVendorData, gql.GET_USER);
    if (!fetchVendorLoading && resData === false) {
      setIsNotFound(true);
      return;
    }
    const vendorDetail = {
      name: `${baseHelper.ucFirst(resData.firstName)} ${baseHelper.ucFirst(resData.lastName)}`,
      email: resData.email && resData.email.address,
      phoneNumber: resData.phoneNumber,
      address: `${resData.address} ${resData.city} ${resData.country} ${resData.pinCode}`,
      image: resData.image,
      brandName: resData.brandName,
    };
    setVendorData(vendorDetail);
    const vendorDetailsError = baseHelper.getResponseError(brandDetailData, gql.GET_BRAND_DETAILS_DATA);
    if (vendorDetailsError) {
      setBanner({ title: vendorDetailsError, status: constant.CRITICAL, isOpen: true });
      return;
    }
    const vendorDetailsData = baseHelper.getResponseData(brandDetailData, gql.GET_BRAND_DETAILS_DATA);
    const { vendorDetails: vendorDetailsValues } = vendorDetailsData || {};
    setVendorDetails(vendorDetailsValues);
    if (vendorDetailsValues) {
      setDescription(vendorDetailsValues.description);
    }
    if (vendorDetailsValues && vendorDetailsValues.brand && vendorDetailsValues.brand.imageUrl) {
      setBrandImage(vendorDetailsValues.brand.imageUrl);
      setIsBrandImage(true);
    }
  }, [fetchVendorData, brandDetailData, gql.GET_BRAND_DETAILS_DATA, gql.GET_USER, fetchVendorLoading]);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  if (vendorProfileLoading) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }

  const getContactDetails = () => {
    // const classValue = (isBrandImage && providerList.VENDOR_IMAGE) || providerList.VENDOR_NO_IMAGE;
    const profileImage =
      (isBrandImage && imageHelper.resize({ url: brandImage, type: constant.imageTypes.PROFILE })) || brandImage;
    return (
      <>
        <ProfileCard className="vendor-profile">
          <Card sectioned>
            <Stack>
              <Stack.Item>
                <Thumbnail
                  source={profileImage}
                  alt={cms("label.profileImageAlt", { altName: vendorData.name })}
                  // className={classValue}
                />
              </Stack.Item>
              <Stack.Item fill>
                <TextContainer>
                  <Heading element="h3">{vendorData.brandName}</Heading>
                  {description && <p>{description}</p>}
                </TextContainer>
                <Stack>
                  <Stack.Item>
                    <Link url="https://www.facebook.com/" external>
                      {/* <Icon source={EmailMajor} /> */}
                      <span className="social-icon fab fa-facebook-square" aria-hidden="true" />
                    </Link>
                  </Stack.Item>
                  <Stack.Item>
                    <Link url="https://in.linkedin.com/" external>
                      {/* <Icon source={EmailMajor} /> */}
                      <span className="social-icon fab fa-linkedin" aria-hidden="true" />
                    </Link>
                  </Stack.Item>
                  <Stack.Item>
                    <Link url="https://www.instagram.com/" external>
                      {/* <Icon source={EmailMajor} /> */}
                      <span className="social-icon fab fa-instagram" aria-hidden="true" />
                    </Link>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item fill>
                {/* <h2 className="heading">{cms("label.contactDetails")}</h2> */}
                <div className="vendor-detail">
                  <Stack>
                    <Icon source={CustomersMajor} color="success" />
                    <TextStyle>{vendorData.name}</TextStyle>
                  </Stack>
                  <Stack>
                    <Icon source={EmailMajor} color="success" />
                    <Link url={`mailto:${vendorData.email}`}>{vendorData.email}</Link>
                  </Stack>
                  <Stack>
                    <Icon source={PhoneMajor} color="success" />
                    <Link url={`tel:${vendorData.phoneNumber}`}>{vendorData.phoneNumber}</Link>
                  </Stack>
                  <Stack>
                    <Icon source={HomeMajor} color="success" />
                    <TextStyle>{vendorData.address}</TextStyle>
                  </Stack>
                </div>
              </Stack.Item>
            </Stack>
          </Card>
          {!ecommercePlatform ? (
            <StyledActionCard>
              <Button onClick={onBrandPage}>Visit Brand Page</Button>

              {/* <Button onClick={() => history.push("/")}>
              <Icon source={ChatMajor} color="base" /> Chat (5)
            </Button> */}

              <div>
                <Button loading={resetVendorLoading} onClick={() => resetVendor()}>
                  {cms("button.productList.resetVendor.primaryAction")}
                </Button>
                <Button onClick={() => history.push(`/providers/edit/${id}`)}>Edit Profile</Button>
              </div>
            </StyledActionCard>
          ) : (
            <StyledActionCard>
              <div>
                <Button loading={resetVendorLoading} onClick={() => resetVendor()}>
                  {cms("button.productList.resetVendor.primaryAction")}
                </Button>
              </div>
              {/* <Button onClick={() => history.push("/")}>
            <Icon source={ChatMajor} color="base" /> Chat (5)
          </Button> */}

              <div>
                <Button onClick={() => history.push(`/providers/edit/${id}`)}>Edit Profile</Button>
              </div>
            </StyledActionCard>
          )}
        </ProfileCard>
        <br />
      </>
    );
  };
  if (fetchVendorLoading || brandLoading) {
    return <Spinner fontSize={42} />;
  }
  if (isNotFound) {
    return <NotFound />;
  }
  const showTabPages = () => {
    switch (selectedTab) {
      case 0:
        return <ProductList />;
      case 1:
        return <OrderList />;
      case 2:
        return <Payment vendorDetails={vendorDetails} />;
      case 3:
        return <Shipping vendorDetails={vendorDetails} />;
      case 4:
        return <SocialLink vendorDetails={vendorDetails} />;
      case 5:
        return <Additional vendorDetails={vendorDetails} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Layout.Section>
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
        />
        {getContactDetails()}
        <Card>
          <Tabs tabs={brandTabs} selected={selectedTab} onSelect={handleTabChange} />
          {showTabPages()}
        </Card>
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(VendorView), { feature: constant.VENDOR_PROFILE });
