import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { withFeature, withErrorBoundary } from "lib/hoc";
import OperatorAddress from "./operator/features/addressBook/addressBook";
import ProviderAddress from "./provider/features/addressBook/addressBook";

const { userKey } = constant;
const { operator, provider } = userKey;

const AddressBook = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorAddress />;
    }
    if (role === provider) {
      return <ProviderAddress />;
    }
    return null;
  };
  const addressBookList = getComponent(currentUserRole);
  return addressBookList;
};

export default withFeature(withErrorBoundary(AddressBook), { feature: constant.ADDRESS });
