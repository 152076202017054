import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

// import components
import Header from "./subFeatureItems/header";
import HeaderText from "./subFeatureItems/headerText";
import Button from "./subFeatureItems/button";
import ButtonText from "./subFeatureItems/buttonText";
import Background from "./subFeatureItems/background";
import MenuTab from "./subFeatureItems/menuTab";
import Heading from "./subFeatureItems/heading";
import Content from "./subFeatureItems/content";
import ToastBackground from "./subFeatureItems/toastBackground";
import ToastText from "./subFeatureItems/toastText";

const Color = () => {
  const { currentUser } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  return (
    <>
      <Header />
      <HeaderText />
      <Button />
      <ButtonText />
      {!ecommercePlatform && (
        <>
          <Background />
          <MenuTab />
          <Heading />
          <Content />
          <ToastBackground />
          <ToastText />
        </>
      )}
    </>
  );
};

export default Color;

