import React from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";

const Pricing = (props) => {
  const { data, onChange, cms } = props;
  const { price, comparePrice } = data;

  const handlePricing = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Card sectioned title={cms("section.pricing.title")}>
      <FormLayout>
        <TextField
          id="price"
          label={cms("section.pricing.label.price")}
          value={price}
          onChange={(value) => handlePricing(constant.PRICE, value)}
          placeholder="0"
        />
        <TextField
          id="comparePrice"
          label={cms("section.pricing.label.compareAtPrice")}
          value={comparePrice}
          onChange={(value) => handlePricing(constant.COMPARE_PRICE, value)}
          placeholder="0"
        />
      </FormLayout>
    </Card>
  );
};

Pricing.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pricing;
