import React, { useContext } from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Pricing = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const {
    acceptOnlyValidInput,
    cms,
    data,
    isWholesalePriceVisible,
    onChange,
    setWholesalePrice,
    wholesalePrice,
  } = props;
  const { price, comparePrice } = data;
  const { moneyFormat = "Rs." } = currentUser;

  const handlePricing = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Card sectioned title={cms("section.pricing.title")}>
      <FormLayout>
        <TextField
          id="price"
          label={cms("section.pricing.label.price")}
          value={price && price.toString()}
          onChange={(value) => handlePricing(constant.PRICE, acceptOnlyValidInput(value, price))}
          placeholder={constant.ZERO_PRICE}
          prefix={
            ((moneyFormat === constant.symbol.RUPEES || moneyFormat === constant.symbol.RUPEE) && moneyFormat) ||
            constant.symbol.DOLLAR
          }
        />
        <TextField
          id="comparePrice"
          label={cms("section.pricing.label.compare")}
          value={comparePrice && comparePrice.toString()}
          onChange={(value) => handlePricing(constant.COMPARE_PRICE, acceptOnlyValidInput(value, comparePrice))}
          placeholder={constant.ZERO_PRICE}
          prefix={
            ((moneyFormat === constant.symbol.RUPEES || moneyFormat === constant.symbol.RUPEE) && moneyFormat) ||
            constant.symbol.DOLLAR
          }
        />
        {isWholesalePriceVisible && (
          <TextField
            id="wholesalePrice"
            label={cms("section.pricing.label.wholesale")}
            value={(wholesalePrice && wholesalePrice.toString()) || ""}
            onChange={(value) => setWholesalePrice(acceptOnlyValidInput(value, comparePrice))}
            placeholder={constant.ZERO_PRICE}
            prefix={
              ((moneyFormat === constant.symbol.RUPEES || moneyFormat === constant.symbol.RUPEE) && moneyFormat) ||
              constant.symbol.DOLLAR
            }
          />
        )}
      </FormLayout>
    </Card>
  );
};

Pricing.propTypes = {
  acceptOnlyValidInput: PropTypes.func.isRequired,
  cms: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pricing;
