import * as yup from "yup";

const returnTermSchema = (errorMessage) => {
  return yup.object().shape({
    allowedDay: yup.number().typeError(errorMessage).positive(errorMessage),
  });
};

const paymentTermSchema = (errorMessage) => {
  return yup.object().shape({
    paymentTermsDay: yup.number().typeError(errorMessage).positive(errorMessage),
  });
};

export { returnTermSchema, paymentTermSchema };
