import React, { useContext, useState } from "react";
import { Card, FormLayout, PageActions, Select, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import mutation

import { ADD_PRODUCT_INFORMATION } from "app/product/apollo/mutations";

// import queries
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE, GET_VENDOR_BRAND_LIST } from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import component
import Tags from "app/product/modules/generic/features/form/tag/tag";

// import proptype
import { tabProp } from "app/product/modules/generic/propTypes";

const Information = () => {
  const { handleTabChange, setBanner } = useContext(ProductContext);
  const { cms, history } = useContext(PrivateContext);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [message, setMessage] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedProductTag, setSelectedProductTag] = useState("");

  const { CRITICAL, gql, INVENTORY } = constant;

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: brandListLoading, data: vendorBrandListData } = useQuery(GET_VENDOR_BRAND_LIST);

  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_INFORMATION);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  if (brandListLoading || productTagLoading || productTypeLoading) {
    return <SkeletonCard />;
  }

  // load vendors
  const brandList = baseHelper.getResponseData(vendorBrandListData, constant.gql.GET_VENDOR_BRAND_LIST);
  const activeVendorOptions = brandList.filter((item) => {
    return item.isInactive;
  });
  const inactiveVendorOptions = brandList.filter((item) => {
    return !item.isInactive;
  });
  const finalVendorOptions = [...activeVendorOptions, ...inactiveVendorOptions];
  const vendorOptions = finalVendorOptions.map(({ _id: vendorId, brandName, isInactive }) => ({
    label: isInactive ? `${brandName} (${cms("button.disabled")})` : brandName,
    value: vendorId,
    disabled: isInactive,
  }));

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const handleTiny = (content) => {
    setDescription(content);
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const findVendorName = (options, selectedValue) => {
    const result = options.find((vendorOption) => vendorOption.value === selectedValue);
    return result;
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const addProduct = async (formValues) => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const resData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_INFORMATION);

      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_INFORMATION);

      if (resData) {
        setMessage(cms("message.success.add"));
        setTimeout(() => {
          history.push(`edit/${encodeURIComponent(resData)}?tab=${INVENTORY}`, { add: true });
        }, 1500);
      }
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const submitCallBack = async () => {
    const vendorName = findVendorName(vendorOptions, selectedVendor);
    const vendor = vendorName.label;
    const vendorId = selectedVendor;

    const formValues = {
      title,
      description,
      vendor,
      vendorId,
      productType: selectedProductType,
      tags: selectedProductTag,
    };

    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    try {
      const productId = await addProduct(formValues);
      if (productId) {
        handleTabChange(1);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.title"),
      };
      setBanner(banner);
      return;
    }
    if (!selectedVendor || selectedVendor.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.vendor"),
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };
  return (
    <>
      <Card
        title={cms("label.productInformation")}
        id="productInformation"
        // actions={[
        //   {
        //     content: cms("label.more"),
        //     onAction: () => {
        //       learnMore("productInformation", cms("label.productInformation"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              error=""
              value={title || ""}
              onChange={(value) => setTitle(value)}
            />
            {cms("label.description")}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={cms("label.price")}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix="$"
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                }}
              />
              <TextField
                id="comparePrice"
                label={cms("label.comparePrice")}
                min={0}
                placeholder="0.00"
                prefix="$"
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                }}
              />
            </FormLayout.Group>
            <Select
              label={`${cms("label.vendor")}*`}
              placeholder={`${cms("placeholder.vendor")}`}
              options={vendorOptions}
              value={selectedVendor || ""}
              onChange={(value) => setSelectedVendor(value)}
            />
            <Select
              label={cms("label.type")}
              options={productTypeOptions}
              placeholder={cms("placeholder.selectProductType")}
              onChange={(value) => setSelectedProductType(value)}
              value={selectedProductType}
            />
            <Tags
              label={cms("label.tag")}
              placeholder={cms("placeholder.tag")}
              values={availableProductTags}
              selected={selectedProductTag}
              onChange={(value) => setSelectedProductTag(value)}
            />
            <br />
          </FormLayout>
        </Card.Section>
        <div className="toast">
          <Toast message={message} />
        </div>
      </Card>
      <PageActions
        primaryAction={{
          content: cms("button.save"),
          disabled: addProductLoading,
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
        }}
      />
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

Information.propTypes = tabProp.type;

export default Information;
