import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";
import { Modal, Stack, TextField, TextStyle } from "@shopify/polaris";

// import from lib.
import { baseHelper, errorHelper } from "lib/helpers";

// import GQL.
import { BULK_FULFILL_LINEITEM } from "app/orders/apollo/mutations";
import constant from "lib/constant/constant";

function FulfillOrder(props) {
  const {
    cms,
    isEnabledData,
    isFulfillModal,
    setIsFulfillModal,
    setBanner,
    refetch,
    orderList,
    selectedItems,
    history,
  } = props;
  const [orderData, setOrderData] = useState([]);
  const [bulkFulfill, { loading }] = useMutation(BULK_FULFILL_LINEITEM);
  const handleCloseModal = () => {
    setIsFulfillModal(false);
  };
  const handleTrackingDetails = (val, key, index) => {
    const updateData = orderData || [];
    const data = updateData[index];
    if (data) {
      updateData[index][key] = val;
    }
    setOrderData([...updateData]);
  };
  const submitTrackingDetails = async () => {
    const orderLines =
      (orderData || []).map((item) => ({
        id: item.orderId,
        lineItemId: item.lineItemId,
        trackingCompany: item.trackingCompany,
        trackingNumber: item.trackingNumber,
        quantity: item.quantity ? Number(item.quantity) : null,
      })) || [];
    await bulkFulfill({ variables: { input: { orderLines } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, "updateBulkOrderLineTracking");
          const responseDataError = baseHelper.getResponseError(res.data, "updateBulkOrderLineTracking");
          if (resData) {
            setIsFulfillModal(false);
            refetch();
            setBanner({
              isOpen: true,
              title: "Your request is running in background.",
              status: constant.SUCCESS,
              action: {
                content: "View activity",
                onAction: () => history.push("/activity"),
              },
            });
          }
          if (responseDataError) {
            setIsFulfillModal(false);
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setIsFulfillModal(false);
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const acceptOnlyValidString = (value) => {
    return (baseHelper.stringNotAcceptSpaceAtStart(value) && value) || "";
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length) {
      const updatedOrderData = [];
      selectedItems.forEach((orderId) => {
        orderList.forEach((item) => {
          if (orderId === item._id) {
            if (item && item.orderLines) {
              item.orderLines.forEach((value) => {
                if (value.status === "accepted") {
                  updatedOrderData.push({
                    orderId,
                    lineItemId: value._id,
                    trackingNumber: "",
                    trackingCompany: "",
                    name: value.name,
                    orderNumber: item.orderNumber,
                    quantity: value.quantity,
                    actualQuantity: value.quantity,
                  });
                }
              });
            }
          }
        });
      });
      setOrderData(updatedOrderData);
    }
  }, [orderList, selectedItems]);

  return (
    <div>
      <Modal
        large
        open={isFulfillModal}
        onClose={handleCloseModal}
        title="Add tracking details"
        primaryAction={{
          content: "Save tracking details",
          onAction: submitTrackingDetails,
          //   destructive: true,
          //   disabled: loading,
          loading,
        }}
        // secondaryActions={[
        //   {
        //     content: cms("section.syncOrder.button.cancel"),
        //     onAction: handleCloseModal,
        //     // disabled: loading,
        //   },
        // ]}
      >
        <Modal.Section>
          {(orderData &&
            orderData.length &&
            orderData.map((item, index) => (
              <>
                {index ? <br /> : ""}
                <Stack wrap={false} distribution="fillEvenly">
                  <div
                    // style={{
                    //   width: "100px",
                    // }}
                    className="text-order"
                  >
                    <TextField labelHidden={!!index} label="Order Number" value={`#${item.orderNumber}`} disabled />
                  </div>
                  <div className="text-order">
                    <TextField labelHidden={!!index} label="Product Name" value={item.name} disabled />
                  </div>
                  {/* <div
                    style={{
                      width: "100px",
                    }}
                    className="text-order"
                  >
                    <TextField
                      label="Quantity"
                      labelHidden={!!index}
                      value={String(item.quantity || "")}
                      min={0}
                      max={item.actualQuantity || 0}
                      onChange={(val) => {
                        // eslint-disable-next-line no-unused-expressions
                        val <= (item.actualQuantity || 0) &&
                          handleTrackingDetails(acceptOnlyValidInput(val, item.quantity), "quantity", index);
                      }}
                      suffix={`of ${item.actualQuantity}`}
                    />
                  </div> */}
                  <div className="text-order">
                    <TextField
                      label={cms("section.orderList.label.trackingNumber")}
                      labelHidden={!!index}
                      value={item.trackingNumber}
                      onChange={(val) => {
                        handleTrackingDetails(acceptOnlyValidString(val), "trackingNumber", index);
                      }}
                    />
                  </div>
                  <div className="text-order">
                    <TextField
                      label={cms("section.orderList.label.trackingCompany")}
                      labelHidden={!!index}
                      value={item.trackingCompany}
                      onChange={(val) => {
                        handleTrackingDetails(acceptOnlyValidString(val), "trackingCompany", index);
                      }}
                    />
                  </div>
                </Stack>
              </>
            ))) || <TextStyle>No order to fulfill</TextStyle>}
        </Modal.Section>
      </Modal>
    </div>
  );
}

FulfillOrder.defaultProps = {
  cms: () => {},
  isFulfillModal: false,
  setIsFulfillModal: () => {},
  setBanner: () => {},
  refetch: () => {},
};

FulfillOrder.propTypes = {
  cms: PropTypes.func,
  isFulfillModal: PropTypes.bool,
  setIsFulfillModal: PropTypes.func,
  setBanner: PropTypes.func,
  refetch: PropTypes.func,
};

export default FulfillOrder;
