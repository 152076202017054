import constant from "lib/constant/constant";

const { providerList } = constant;

function listData(cms) {
  return {
    filterData: {
      label: cms("label.brandName"),
      filters: [
        {
          key: "vendor",
          label: cms("label.brandName"),
          operatorText: providerList.LIKE,
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/orders/view/",
      id: "vendorId",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    productLink: {
      link: "/products/edit/",
      id: "productId",
    },
    sortOptions: [
      { label: cms("label.sortByDateAsc"), value: constant.CREATED_ASC },
      { label: cms("label.sortByDateDesc"), value: constant.CREATED_DESC },
      { label: cms("label.sortByOrderIdAsc"), value: providerList.ORDERNUMBER_ASC },
      { label: cms("label.sortByOrderIdDesc"), value: providerList.ORDERNUMBER_DESC },
    ],
    resourceName: {
      singular: cms("resourceName.orders.singular"),
      plural: cms("resourceName.orders.plural"),
    },
    tabsData: [
      {
        id: "all-orders",
        content: cms("label.order.all"),
        accessibilityLabel: providerList.ALL_ORDERS,
        panelID: "all-orders-content",
        value: providerList.ALL,
      },
      {
        id: "pending-acceptance",
        content: cms("label.order.pendingAcceptance"),
        panelID: "pending-acceptance-content",
        value: providerList.PENDING,
      },
      {
        id: "pending-shipment",
        content: cms("label.order.pendingShipment"),
        panelID: "pending-shipment-content",
        value: providerList.ACCEPTED,
      },
      {
        id: "rejected",
        content: cms("label.order.rejected"),
        panelID: "rejected-content",
        value: providerList.REJECTED,
      },
      {
        id: "shipped",
        content: cms("label.order.shipped"),
        panelID: "shipped-content",
        value: providerList.SHIPPED,
      },
      {
        id: "archived",
        content: cms("label.order.archived"),
        panelID: "archived-content",
        value: providerList.CLOSED,
      },
      {
        id: "return",
        content: cms("label.order.returns"),
        panelID: "return-content",
        value: providerList.RETURN,
      },
    ],
    badge: [providerList.STATUS, providerList.FULFILLMENT_STATUS],
    tableData: {
      tbody: [],
    },
    lineReturnStatus: {
      create: cms("label.order.returnInProgress"),
      accept: cms("label.order.closed"),
      reject: cms("label.order.returnReject"),
      complete: cms("label.order.complete"),
      dispute: cms("label.order.dispute"),
      acknowledge: cms("label.order.processingReturn"),
    },
  };
}
export default listData;
