import { gql } from "apollo-boost";

const LINE_REFUND = gql`
  mutation lineRefund($input: LineRefund!) {
    lineRefund(input: $input) {
      status
      error
      data
    }
  }
`;

export default LINE_REFUND;
