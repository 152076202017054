import { gql } from "apollo-boost";

const GET_USER_CONTENT = gql`
  query PrivateContainer($getContentInput: GetContent, $getFeatureInput: GetFeature, $getPublicCMSInput: GetCMS!) {
    getContent(input: $getContentInput) {
      data {
        button {
          code
          en
        }
        error {
          code
          en
        }
        label {
          code
          en
        }
        message {
          code
          en
        }
        pageTitle {
          code
          en
        }
        placeHolder {
          code
          en
        }
        sectionContent {
          code
          en
        }
        sectionLabel {
          code
          en
        }
      }
      status
      error
    }
    getCurrentUser {
      status
      data {
        _id
        appId
        api {
          key
          status
        }
        aws {
          bucket
          path
          id
          secret
        }
        additionalStoreInfo {
          shop {
            currency
          }
          billing {
            id
            status
          }
        }
        font
        isOtp
        font
        ecommercePlatform
        controlPanelBaseUrl
        firstlogin
        firstName
        fulfillmentScopeExists
        lastName
        isExpressPayment
        isFirstProduct
        isTest
        isGlobalShipping
        isProductDiscovery
        isSuspended
        payoutSetting {
          isAutoStripe
          isAutoPaypal
        }
        email {
          address
          verified
        }
        mobile {
          number
          otp
          verified
        }
        contactEmail
        roles {
          name
        }
        moneyFormat
        shop
        brandName
        vendors {
          max
          associated
        }
        products {
          max
          used
        }
        isOrderConnect
        isReadOnly
        plan {
          analytics
          cancelledAt
          chargeId
          code
          commission
          isBranding
          isChargebee
          isDuePayment
          isStripe
          name
          paymentStatus
          price
          trialEndDate
          isPAYE
          paye {
            calculatedPrice
            currencyRate
            endTermDate
            orderCount
            startTermDate
          }
        }
        planPreference
        stripe {
          accountId
          isVerified
        }
        paypalKey {
          clientId
          secret
        }
        paypal {
          emailId
          payerId
          status
        }
        setupProgress
        phoneNumber
        address
        city
        country
        pinCode
        provinceCode
        commissionType
        setting {
          isAcceptOrder
          isFulfillOrder
          isReviewOrder
        }
        logo
        shop
        shopifyAccessToken
        generalInfo {
          isSellOnline
          storeType
          isVendorOnboarding
          marketplaceUnderstanding
          annualRevenue
          alternateEmail
          brandDescription
        }
        introductorySeller
        introductorySellerName
        paymentGateway
        planId
        userLevel
        isApproved
        rejectCount
        isProductSync
        isHideMcInfo
        isInactive
        emailSign
        footerText
        loginText
        domain
        isDomainActive
        register {
          title
          desc
        }
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
          backgroundColor
          headingColor
          contentColor
          menuTabColor
          toastBackground
          toastText
        }
        maintenance {
          isApp
          isSite
        }
        brand {
          termsLink
          policyLink
          slug
          description
          cover {
            imageUrl
          }
          favicon {
            imageUrl
            imageId
            updatedAt
          }
          finish {
            cover {
              imageId
              imageUrl
              updatedAt
            }
            description {
              description
              updatedAt
            }
          }
          welcome {
            description {
              description
              updatedAt
            }
            cover {
              imageId
              imageUrl
              updatedAt
            }
          }
          updatedAt
        }
        isChargebee
        chargebee {
          apiKey
          site
          currencyCode
        }
        isStripeSubscription
        stripeSubscription {
          apiKey
          isUpdate
          priceTableId
          publishedKey
        }
        isAwsCredsAvailable
        createdAt
        requestedBy
        updatedAt
        storeConfig {
          magento {
            shop
            accessToken
          }
          prestaShop {
            shop
            accessKey
          }
          wooCommerce {
            shop
            accessKey
            accessToken
          }
          bigCommerce {
            accessToken
            clientId
            clientSecret
            shopDomain
            isNewProduct
          }
          wix {
            accessKey
            instanceId
            isNewProduct
          }
          shopify {
            apiKey
            apiSecretKey
          }
        }
        taxSetting {
          company
          customs
          vat
          gst
          pst
        }
        isAttachmentVisible
      }
    }
    getFeature(input: $getFeatureInput) {
      error
      status
      data {
        _id
        moduleId
        parentId
        key
        label
        description
        hide {
          permission {
            level
            role
          }
        }
        user {
          permission {
            level
            role
          }
          tier
          group {
            seller
            vendor
            systemAdmin
          }
        }
        version
        time {
          duration {
            date
            days
          }
        }
        location {
          country
          region
          city
        }
        device {
          web {
            browser
            os
          }
          mobile {
            browser
            os
          }
        }
        features {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
        }
        dependency {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
          features {
            _id
            moduleId
            parentId
            key
            label
            description
            hide {
              permission {
                level
                role
              }
            }
            user {
              permission {
                level
                role
              }
              tier
              group {
                seller
                vendor
                systemAdmin
              }
            }
            version
            time {
              duration {
                date
                days
              }
            }
            location {
              country
              region
              city
            }
            device {
              web {
                browser
                os
              }
              mobile {
                browser
                os
              }
            }
          }
        }
      }
    }
    getPublicCMS(input: $getPublicCMSInput) {
      status
      error
      data {
        commonContent
        pageContent
      }
    }
  }
`;

export default GET_USER_CONTENT;
