import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo";
import {
  Card,
  DisplayText,
  FormLayout,
  Icon,
  Link,
  Modal,
  ResourceItem,
  ResourceList,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { CircleTickMajor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

// import component
import { Toast, SkeletonCard } from "lib/components";

// import mutation
import { DELETE_LAKE_VARIANT } from "app/productLake/apollo/mutations";

import { baseHelper, imageHelper, errorHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import { withErrorBoundary } from "lib/hoc";

const RenderVariants = (props) => {
  const { history, currentUser, match, cms } = useContext(PrivateContext);
  const { data, handleTabChange, productLoading, setBanner, setDisabledButton, setToastMessage, refetch } = props || {};
  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);

  const { images } = data;
  // const {
  //   value: { MAX_AMOUNT, MAX_PERCENTAGE },
  // } = constant;
  const { moneyFormat } = currentUser;
  // const [updateMarkUp, setMarkUp] = useState({
  //   type: "flat",
  //   price: "",
  // });
  // const maxMarkup = (updateMarkUp.type === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;
  const [message] = useState("");
  // const [updateQuantity, setQuantity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  // const [active, setActive] = useState(false);
  // const [openQuantity, setOpenQuantity] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [addImageModal, setAddImageModal] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  // const [submitDisable, setSubmitDisable] = useState(false);

  const { gql } = constant;

  // GQL calls
  // const [updateMarkupVariant, { loading: loadingBulkUpdate }] = useMutation(BULK_UPDATE_VARIANT, {
  //   refetchQueries: [
  //     {
  //       query: GET_PRODUCT_AND_SELLER_PRODUCT,
  //       variables: { input: { _id: match.params.id } },
  //     },
  //   ],
  // });
  const [deleteLakeVariant, { loading: loadingDeleteLakeVariant }] = useMutation(DELETE_LAKE_VARIANT);

  // const [updateVariant, { loading: loadingUpdateVariant }] = useMutation(UPDATE_VARIANT_IMAGE, {
  //   refetchQueries: [
  //     {
  //       query: GET_PRODUCT_AND_SELLER_PRODUCT,
  //       variables: { input: { _id: match.params.id } },
  //     },
  //   ],
  // });

  // const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT) => {
  //   if (value > MIN_PRICE && value < max) {
  //     return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  //   }
  //   if (!value) {
  //     return "";
  //   }
  //   return prevValue;
  // };

  if (productLoading) {
    return <SkeletonCard />;
  }

  // const handleEditMarkup = () => {
  //   setActive(!active);
  //   setMessage("");
  // };
  // const handleEditQuantity = () => {
  //   setOpenQuantity(!openQuantity);
  //   setMessage("");
  // };

  const handleDeleteModal = (id) => {
    setSelectedId(id);
    setDeleteActive(!deleteActive);
  };
  // const bulkActions = [
  //   { content: cms("label.updateMarkup"), onAction: () => handleEditMarkup() },
  //   { content: cms("label.updateQuantity"), onAction: () => handleEditQuantity() },
  // ];
  // const handleChange = (fieldName, fieldValue, typeSelected) => {
  //   if (!typeSelected) {
  //     return setMarkUp({ price: "", type: fieldValue });
  //   }
  //   if (typeSelected === constant.PERCENTAGE && (fieldValue <= 100 || !fieldValue)) {
  //     return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
  //   }
  //   if (typeSelected !== constant.PERCENTAGE) {
  //     return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
  //   }
  //   return false;
  // };

  // const selectMarkUp = (
  //   <Select
  //     placeholder={cms("placeholder.selectType")}
  //     options={[
  //       {
  //         label: cms("common.label.flat"),
  //         value: constant.FLAT,
  //       },
  //       {
  //         label: cms("common.label.percentage"),
  //         value: constant.PERCENTAGE,
  //       },
  //     ]}
  //     value={updateMarkUp.type}
  //     onChange={(value) => handleChange(constant.TYPE, value, false)}
  //   />
  // );

  // const handleUpdateMarkup = (fieldName = false) => {
  //   const value = {
  //     productId: match.params.id,
  //     positions: selectedItems,
  //   };
  //   if (fieldName === constant.MARKUP) {
  //     value.markUp = updateMarkUp || parseInt(0, 10);
  //     value.markUp.price = parseFloat(updateMarkUp.price);
  //   }
  //   if (fieldName === constant.INVENTORY_QUANTITY) {
  //     value.inventoryQuantity = updateQuantity || parseInt(0, 10);
  //   }
  //   updateMarkupVariant({ variables: { input: value } })
  //     .then((res) => {
  //       setSelectedItems([]);
  //       const responseError = baseHelper.getResponseError(res, gql.BULK_UPDATE_VARIANT);
  //       const successMessage =
  //         fieldName === constant.INVENTORY_QUANTITY ? cms("message.success.quantity") : cms("message.success.markup");
  //       setDisabledButton(false);
  //       if (responseError) {
  //         return setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
  //       }
  //       setOpenQuantity(false);
  //       setActive(false);
  //       setToastMessage(successMessage);
  //       return null;
  //     })
  //     .catch((exception) => {
  //       setOpenQuantity(false);
  //       setActive(false);
  //       setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
  //     });
  //   setSubmitDisable(false);
  // };
  const handleDeleteVariant = () => {
    setToastMessage("");
    deleteLakeVariant({
      variables: {
        input: {
          id: selectedId,
        },
      },
    })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_LAKE_VARIANT);
        if (responseError) {
          handleDeleteModal();
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_LAKE_VARIANT);
        if (responseData) {
          setToastMessage(cms("message.success.variantDelete"));
          handleDeleteModal();
          setTimeout(() => {
            setSelectedId(null);
            refetch();
            handleTabChange(2);
            setDisabledButton(false);
          }, 2000);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
        handleDeleteModal();
      });
  };
  const renderDeleteModal = () => {
    return (
      <Modal
        open={deleteActive}
        onClose={handleDeleteModal}
        title={cms("label.deleteVariant")}
        primaryAction={{
          content: cms("button.delete"),
          onAction: handleDeleteVariant,
          loading: loadingDeleteLakeVariant,
          disabled: loadingDeleteLakeVariant,
        }}
      >
        <Modal.Section>{cms("label.content")}</Modal.Section>
      </Modal>
    );
  };

  // const renderModal = () => {
  //   return (
  //     <Modal
  //       open={active}
  //       onClose={handleEditMarkup}
  //       title={cms("label.updateVariant")}
  //       primaryAction={{
  //         content: cms("label.updateMarkupPrice"),
  //         onAction: () => handleUpdateMarkup(constant.MARKUP),
  //         loading: loadingBulkUpdate,
  //         disabled: !submitDisable || loadingBulkUpdate,
  //       }}
  //     >
  //       <Modal.Section>
  //         <TextField
  //           label={cms("label.markupPrice")}
  //           value={(updateMarkUp && updateMarkUp.price && updateMarkUp.price.toString()) || ""}
  //           placeholder={constant.ZERO_PRICE}
  //           min={0}
  //           step={0.01}
  //           max={(updateMarkUp.type === constant.PERCENTAGE && 100) || 99999}
  //           prefix={updateMarkUp.type === constant.FLAT && moneyFormat}
  //           suffix={updateMarkUp.type === constant.PERCENTAGE && symbol.PERCENTAGE}
  //           onChange={(value) => {
  //             handleChange(
  //               constant.PRICE,
  //               acceptOnlyValidInput(value, updateMarkUp.price, maxMarkup),
  //               updateMarkUp.type
  //             );
  //             setSubmitDisable(value);
  //           }}
  //           connectedRight={selectMarkUp}
  //         />
  //       </Modal.Section>
  //     </Modal>
  //   );
  // };
  // const renderQuantityModal = () => {
  //   return (
  //     <Modal
  //       open={openQuantity}
  //       onClose={handleEditQuantity}
  //       title={cms("label.update")}
  //       primaryAction={{
  //         content: cms("label.updateQuantity"),
  //         onAction: () => handleUpdateMarkup(constant.INVENTORY_QUANTITY),
  //         loading: loadingBulkUpdate,
  //         disabled: !submitDisable || loadingBulkUpdate,
  //       }}
  //     >
  //       <Modal.Section>
  //         <TextField
  //           label={cms("label.quantity")}
  //           value={updateQuantity && updateQuantity.toString()}
  //           placeholder={constant.ZERO_PRICE}
  //           min={0}
  //           maxLength={10}
  //           onChange={(value) => {
  //             setQuantity(parseInt(value, 10));
  //             setSubmitDisable(value);
  //           }}
  //         />
  //       </Modal.Section>
  //     </Modal>
  //   );
  // };

  const closeModal = () => {
    setAddImageModal({});
    setSelectedImage({});
  };

  const updateImage = () => {
    setToastMessage("");
    // const value = {
    //   id: addImageModal.variantId,
    //   image: selectedImage.imageUrl,
    //   productId: match.params.id,
    // };
    // updateVariant({ variables: { input: value } })
    //   .then((res) => {
    //     const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_VARIANT_IMAGE);
    //     if (responseError) {
    //       setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
    //       closeModal();
    //     }
    //     const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_VARIANT_IMAGE);
    //     if (responseData) {
    //       setToastMessage(cms("message.success.variantImage"));
    //       closeModal();
    //     }
    //   })
    //   .catch((exception) => {
    //     setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    //     closeModal();
    //   });
  };

  const onImageChange = (nImage) => {
    setSelectedImage(selectedImage.imageUrl === nImage.imageUrl ? {} : nImage);
  };

  const renderSelectImage = () => {
    const allImages = (images && images.filter((item) => item && item.imageUrl)) || [];
    const productImages = allImages.map((nImage) => {
      const isSelectedImage = nImage.imageUrl === selectedImage.imageUrl;
      return (
        <Stack.Item key={nImage.position}>
          <Stack alignment="center" vertical>
            <Stack.Item>
              <Link onClick={() => onImageChange(nImage)}>
                <Thumbnail
                  source={imageHelper.resize({ url: nImage.imageUrl, type: constant.imageTypes.THUMBNAIL })}
                  size="large"
                />
              </Link>
            </Stack.Item>
            {isSelectedImage && (
              <Stack.Item>
                <Icon source={CircleTickMajor} color="greenDark" backdrop />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      );
    });
    return <Stack>{productImages}</Stack>;
  };

  const renderImageModal = () => {
    return (
      <Modal
        open={addImageModal.isOpen}
        onClose={() => closeModal()}
        title={cms("modal.title")}
        primaryAction={{
          content: cms("modal.button"),
          onAction: () => updateImage(),
          disabled: !(selectedImage && selectedImage.imageUrl),
          // loading: loadingUpdateVariant,
        }}
      >
        <Modal.Section>
          <Card sectioned>{renderSelectImage()}</Card>
        </Modal.Section>
      </Modal>
    );
  };

  const renderItem = (item, _, index) => {
    const { images: variantImage, optionValues, inventoryId, price, priceId } = item;
    const [imageData] = variantImage || [];
    const { imageUrl = "" } = imageData || {};
    const { quantity, sku } = inventoryId || {};
    const [option1, option2, option3] = optionValues || [];
    const { label: label1, optionId: optionId1 } = option1 || {};
    const { label: label2, optionId: optionId2 } = option2 || {};
    const { label: label3, optionId: optionId3 } = option3 || {};
    const media = (
      // <Link onClick={() => setAddImageModal({ isOpen: true, variantId: position })}>
      <Link>
        <Thumbnail
          source={imageHelper.resize({ url: imageUrl, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP}
          alt={`image of variant ${index}`}
        />
      </Link>
    );
    const { price: vPrice, regularPrice } = priceId || {};
    const variantPrice =
      (baseHelper.isBigCommerceUser(currentUser)
        ? (vPrice && vPrice.toString()) || (price && price.toString())
        : regularPrice && regularPrice.toString()) || "";

    return (
      <ResourceItem id={index + 1} media={media}>
        <TextContainer>
          <Stack wrap={false}>
            <Stack.Item fill>
              {label1 && <div className="variantEllipsis">{`${optionId1.displayName}: ${label1}`}</div>}
              {label2 && <div className="variantEllipsis">{`${optionId2.displayName}: ${label2}`}</div>}
              {label3 && <div className="variantEllipsis">{`${optionId3.displayName}: ${label3}`}</div>}
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={activePopover[index]}
                setActive={() => setActivePopover({ [index]: !activePopover[index] })}
                options={[
                  {
                    content: cms("button.edit"),
                    icon: EditMinor,
                    onAction: () =>
                      history.push(
                        `/lake-variants/edit/${match.params.id}/${
                          isBigCommerceUser ? item.providerVariantId : item._id
                        }`
                      ),
                  },
                  {
                    content: cms("button.delete"),
                    icon: DeleteMinor,
                    destructive: true,
                    onAction: () => handleDeleteModal(isBigCommerceUser ? item.providerVariantId : item._id),
                  },
                ]}
              />
            </Stack.Item>
          </Stack>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField label={cms("label.inventory")} value={(quantity && quantity.toString()) || ""} disabled />
              <TextField
                label={cms("label.price")}
                value={(variantPrice && variantPrice.toString()) || ""}
                prefix={moneyFormat}
                disabled
              />
              <TextField label={cms("label.sku")} value={(sku && sku.toString()) || ""} disabled />
            </FormLayout.Group>
          </FormLayout>
        </TextContainer>
      </ResourceItem>
    );
  };
  const view = (dataNew) => {
    const { variants } = dataNew;
    return (
      <div>
        {!(variants && variants.length) && (
          <>
            <TextContainer>
              <DisplayText size="extraSmall">{cms("section.variant.content")}</DisplayText>
              <br />
              <DisplayText size="extraSmall">
                <TextStyle variation="strong">{`${cms("label.please")}: `}</TextStyle>
                {cms("label.caption.add")}
              </DisplayText>
            </TextContainer>
          </>
        )}
        {variants && variants.length > 0 && (
          <ResourceList
            items={variants}
            renderItem={renderItem}
            selectedItems={selectedItems}
            onSelectionChange={setSelectedItems}
            // bulkActions={bulkActions}
            idForItem={(item, index) => index + 1}
          />
        )}
        <div className="toast">
          <Toast message={message} />
        </div>
      </div>
    );
  };
  return [renderImageModal(), renderDeleteModal(), view(data)];
};
export default withErrorBoundary(RenderVariants);
