import React from "react";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { Banner } from "lib/components";

const Shipping = (props) => {
  const {
    handleChange = () => {},
    variantData = {},
    handleTitleChange = () => {},
    handleMeasurementChange = () => {},
    cms,
  } = props;

  const {
    value: { MAX_MEASUREMENT = 999 },
  } = constant;

  const { isShipping = false, weight = "", weightUnit = "", measurement = {} } = variantData || {};
  const options = constant.OPTIONS;
  const selectWeight = (
    <Select
      id="selectWeight"
      value={weightUnit || ""}
      label={cms("section.shipping.label.selectWeight")}
      placeholder={cms("section.shipping.placeholder.selectWeight")}
      onChange={(value) => handleTitleChange(constant.WEIGHT_UNIT, value)}
      labelHidden
      options={options}
    />
  );

  const renderMeasurement = () => {
    const { length = {}, width = {}, height = {} } = measurement || {};
    const { value: lengthValue = 0 } = length || {};
    const { value: widthValue = 0 } = width || {};
    const { value: heightValue = 0 } = height || {};
    return (
      <>
        <TextField
          id="length"
          label={cms("section.shipping.label.length")}
          value={(lengthValue && lengthValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.lengthUnit")}
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => handleMeasurementChange(constant.LENGTH, Number(value))}
        />
        <br />
        <TextField
          id="width"
          label={cms("section.shipping.label.width")}
          value={(widthValue && widthValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.widthUnit")}
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => handleMeasurementChange(constant.WIDTH, Number(value))}
        />
        <br />
        <TextField
          id="height"
          label={cms("section.shipping.label.height")}
          value={(heightValue && heightValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.heightUnit")}
          min={0}
          max={MAX_MEASUREMENT}
          onChange={(value) => handleMeasurementChange(constant.HEIGHT, Number(value))}
        />
      </>
    );
  };

  return (
    <Card title="Shipping" id="variantShipping">
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label={cms("section.shipping.label.physicalProduct")}
          checked={isShipping}
          onChange={(value) => {
            handleTitleChange(constant.IS_SHIPPING, value);
            if (!value) {
              handleTitleChange(constant.WEIGHT, null);
              handleTitleChange(constant.WEIGHT_UNIT, "");
            }
          }}
        />
        {!isShipping && (
          <div>
            <br />
            <Banner isOpen status="info" isScrollTop={false}>
              <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {isShipping && (
        <Card.Section title={cms("section.shipping.measurement.title")}>
          <p>{cms("section.shipping.measurement.description")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.shipping.label.weight")}
            type="number"
            value={(weight && weight.toString()) || ""}
            disabled={!weightUnit}
            placeholder={cms("section.shipping.placeholder.weight")}
            onChange={(value) => handleChange(constant.WEIGHT, Number(value))}
            connectedRight={selectWeight}
          />
          <br />
          {renderMeasurement()}
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleMeasurementChange: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  cms: PropTypes.func.isRequired,
};

export default Shipping;
