import { gql } from "apollo-boost";

const GET_VENDOR_AND_PRODUCT_TYPES = gql`
  query getVendorAndProductTypes {
    getVendorAndProductTypes {
      data {
        supplierRows {
          _id
          brandName
        }
        types {
          _id
          value
        }
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_AND_PRODUCT_TYPES;
