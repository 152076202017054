// import packages
import React, { useState, useCallback, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  Card,
  Caption,
  Layout,
  FormLayout,
  Link,
  PageActions,
  Select,
  Stack,
  TextContainer,
  TextStyle,
  Toast,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import query
import { CUSTOMIZE_FONT } from "app/setup/apollo";

import { baseHelper } from "lib/helpers";
// import components
import { Banner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

const CustomizeFont = () => {
  const { currentUser, history, cms } = useContext(PrivateContext);
  const { updatedAt } = currentUser;
  const { DEFAULT_FONT, gql } = constant;
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [font, setFont] = useState("");
  const [active, setActive] = useState(false);

  const [submitEnabled, setSubmitEnable] = useState(false);

  const [customizeFont, { loading: customizeLoading }] = useMutation(CUSTOMIZE_FONT);

  useEffect(() => {
    if (currentUser.font) {
      setFont(currentUser.font);
    }
  }, [currentUser]);

  const handleSubmit = async (value) => {
    const defaultFont = value || font;
    const res = await customizeFont({
      variables: { input: { font: defaultFont } },
    });
    const responseError = baseHelper.getResponseError(res.data, gql.CUSTOMIZE_FONT);
    const responseData = baseHelper.getResponseData(res.data, gql.CUSTOMIZE_FONT);

    if (responseData) {
      if (updatedAt) {
        setSuccessMessage(cms("success.message.fontUpdate"));
      } else {
        setSuccessMessage(cms("success.message.font"));
      }
      setSubmitEnable(false);
      setActive(true);
    }

    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
    setTimeout(() => {
      history.go(); // we are directly manupulating the dom.
    }, 3000);
  };

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleReset = (val) => {
    setFont(val);
    setSubmitEnable(true);
  };

  const handleChange = (value) => {
    setFont(value);
    setSubmitEnable(true);
  };

  const toggleActive = useCallback(() => setActive((activeToast) => !activeToast), []);

  const valueOptions = [
    {
      label: DEFAULT_FONT,
      disabled: true,
      value: DEFAULT_FONT,
    },
    {
      label: "Arial (Sans-Serif)",
      value: "Arial",
    },
    {
      label: "Brush Script MT (Cursive)",
      value: "Brush Script MT",
    },
    {
      label: "Courier New (Monospace)",
      value: "Monospace",
    },
    {
      label: "Garamond (Serif)",
      value: "Garamond",
    },
    {
      label: "Georgia(Serif)",
      value: "Georgia",
    },
    {
      label: "Helvetica(San - Serif)",
      value: "Helvetica",
    },
    {
      label: "Tahoma(San - Serif)",
      value: "Tahoma",
    },
    {
      label: "Times New Roman(Serif)",
      value: "Times New Roman Cyr",
    },
    {
      label: "Trebuchet MS(San - Serif)",
      value: "Trebuchet MS",
    },
    {
      label: "Verdana(San - Serif)",
      value: "Verdana",
    },
  ];

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("label.section.title")} description={cms("label.section.description")}>
        {active && <Toast content={successMessage} onDismiss={toggleActive} />}
        <Card
          title={[
            cms("label.cardSection.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("label.cardSection.description")}</TextContainer>
            <FormLayout>
              <Stack />
              <div className="default-font">
                <Select
                  id="standardTypeSelect"
                  options={valueOptions}
                  value={font}
                  onChange={(selectedValue) => handleChange(selectedValue)}
                />
              </div>
              <Stack />
              <Stack>
                <Stack.Item fill>
                  <span style={{ fontFamily: font }}>{cms("label.cardSection.sample")}</span>
                </Stack.Item>
                {font !== DEFAULT_FONT && (
                  <Stack.Item>
                    <Link onClick={() => handleReset(DEFAULT_FONT)}> {cms("label.cardSection.reset")}</Link>
                  </Stack.Item>
                )}
              </Stack>
            </FormLayout>
          </Card.Section>
        </Card>
        {!updatedAt && (
          <PageActions
            primaryAction={{
              content: `${cms("common.button.submit")}`,
              onAction: () => handleSubmit(),
              loading: customizeLoading,
              disabled: !submitEnabled,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
        {updatedAt && (
          <PageActions
            primaryAction={{
              content: `${cms("common.button.update")}`,
              onAction: () => handleSubmit(),
              loading: customizeLoading,
              disabled: !submitEnabled,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
      <Layout.Section />
    </>
  );
};

export default withErrorBoundary(CustomizeFont);
