import { gql } from "apollo-boost";

export const CREATE_VENDOR = gql`
  mutation createVendor($input: VendorDetail!) {
    createVendor(input: $input) {
      data {
        otp
      }
      status
      error
    }
  }
`;

export const BULK_INVITATION_BY_DATA = gql`
  mutation bulkInvitationByData($input: BulkInviteData) {
    bulkInvitationByData(input: $input) {
      status
      data
      error
    }
  }
`;

export const BULK_SUPPLIER_CSV = gql`
  mutation bulkSupplierUsingCsv($input: BulkSupplierUsingCsv) {
    bulkSupplierUsingCsv(input: $input) {
      status
      data
      error
    }
  }
`;

export const SEND_BULK_INVITE_BY_CSV = gql`
  mutation sendBulkInviteByCSV($input: SendBulkInviteByCSV) {
    sendBulkInviteByCSV(input: $input) {
      status
      data
      error
    }
  }
`;
