import { gql } from "apollo-boost";

const EDIT_PROFILE_SYSTEM_ADMIN = gql`
  mutation updateSystemAdminProfile($input: UpdateSystemAdminProfile!) {
    updateSystemAdminProfile(input: $input) {
      data
      status
      error
    }
  }
`;

const EDIT_PROFILE_SELLER = gql`
  mutation updateSellerProfile($input: UpdateSellerProfile!) {
    updateSellerProfile(input: $input) {
      data
      status
      error
    }
  }
`;

const EDIT_PROFILE_VENDOR = gql`
  mutation updateVendorProfile($input: UpdateVendorProfile!) {
    updateVendorProfile(input: $input) {
      data
      status
      error
    }
  }
`;

export { EDIT_PROFILE_SYSTEM_ADMIN, EDIT_PROFILE_SELLER, EDIT_PROFILE_VENDOR };
