import { gql } from "apollo-boost";

const GET_ORDER_LAKE_FULFILLMENT = gql`
  query getOrderLakeFulfillment($input: GetOrderLakeInput) {
    getOrderLakeLineFulfillment(input: $input) {
      status
      data {
        _id
        comments
        items {
          quantity
          storeOrderProductId
          storeProductId
        }
        shippingMethod
        shippingProvider
        storeCustomerId
        storeDateCreated
        storeOrderAddressId
        storeOrderId
        storeShipmentId
        trackingCarrier
        trackingNumber
        ownerId
        orderId
      }
      error
    }
  }
`;
export default GET_ORDER_LAKE_FULFILLMENT;
