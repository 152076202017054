import * as yup from "yup";

export const validationSize = yup.object().shape({
  size: yup.string().required(),
});
export const validationColor = yup.object().shape({
  color: yup.string().required(),
});
export const validationMaterial = yup.object().shape({
  material: yup.string().required(),
});
