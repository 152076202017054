import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { Spinner } from "lib/components";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";

import ManageAccess from "./operator/feature/manageAccess/manageAccess";

const { userKey } = constant;
const { operator } = userKey;

const ManageVendorAccess = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <ManageAccess />;
    }
    return null;
  };

  const AdvanceProviderComponent = getComponent(currentUserRole);
  return AdvanceProviderComponent;
};

export default ManageVendorAccess;
