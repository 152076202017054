import { gql } from "apollo-boost";

const ADD_ADDRESS = gql`
  mutation addAddress($input: AddAddressInput) {
    addAddress(input: $input) {
      data
      error
      status
    }
  }
`;

const EDIT_ADDRESS = gql`
  mutation editAddress($input: EditAddressInput) {
    editAddress(input: $input) {
      data
      error
      status
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation deleteAddress($input: AddressId!) {
    deleteAddress(input: $input) {
      data
      error
      status
    }
  }
`;

const EDIT_DEFAULT = gql`
  mutation updateDefaultAddress($input: AddressId!) {
    updateDefaultAddress(input: $input) {
      data
      error
      status
    }
  }
`;

export { ADD_ADDRESS, EDIT_ADDRESS, DELETE_ADDRESS, EDIT_DEFAULT };
