import React from "react";

import constant from "lib/constant/constant";
import { Setting } from "app/userManagement/modules/operator/features";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { operator } = userKey;

const ChatSetting = () => {
  const getComponent = (role) => {
    if (role === operator) {
      return <Setting />;
    }
    return "";
  };
  const currentUserRole = storageHelper.get("userRole");
  const ChatComponent = getComponent(currentUserRole);
  return ChatComponent;
};

export default withErrorBoundary(ChatSetting);
