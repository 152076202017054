import { gql } from "apollo-boost";

const FETCH_VENDOR_FIELD = gql`
  query getVendorField($input: GetVendorField) {
    getVendorField(input: $input) {
      data {
        vendorField {
          _id
          isBranding
          isPayment
          isShipping
          isSocialLink
          sellerId
          customFields {
            imageUrls
            inputType
            key
            label
            multimediaUrls
            placeholder
            value
          }
        }
      }
      status
      error
    }
  }
`;
export default FETCH_VENDOR_FIELD;
