import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "react-apollo";
import { Button, ButtonGroup, Card, Heading } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import baseHelper
import { baseHelper } from "lib/helpers";

// import components
import { Spinner } from "lib/components";

// import queries
import { GET_FILTER_OPTIONS, GET_SALES_METRICS } from "app/reporting/apollo/queries";

// import props
import { tabProp } from "app/reporting/modules/operator/props";

// import Filters
import { EmptyReportingState, ReportingFilters } from "app/reporting/modules/generic/components";

// import Graph
import { BarGraph, PieGraph } from "app/reporting/modules/generic/graph";

const { MONTHS } = constant;

const SalesTab = (props) => {
  const { reportingConfig } = props;
  const [chartType, setChartType] = useState("barChart");
  const [filterBy, setFilterBy] = useState("");
  const [filterOptions, setFilterOptions] = useState();
  const [graphData, setGraphData] = useState([]);
  const [selectedDates, setSelectedDates] = useState();
  const [selectedFilterOption, setSelectedFilterOption] = useState();
  const [timePeriod, setTimePeriod] = useState();

  const handleTimePeriod = (value) => {
    setTimePeriod(value);
    setSelectedDates(null);
  };

  const [getReporting, { loading, data: salesGraphData }] = useLazyQuery(GET_SALES_METRICS);
  const { data: filterOptionsData } = useQuery(GET_FILTER_OPTIONS);

  const filterOptionsResponse = baseHelper.getResponseData(filterOptionsData, constant.gql.GET_FILTER_OPTIONS) || [];

  useEffect(() => {
    const response = baseHelper.getResponseData(salesGraphData, constant.gql.GET_SALES_METRICS);
    if (response) {
      const salesResponse = response.sales.map((item) => ({ ...item, month: MONTHS[item.month - 1] }));
      setGraphData(salesResponse);
    }
  }, [salesGraphData]);

  useEffect(() => {
    const submitQuery = () => {
      const inputData = {
        filter: {
          days: parseInt(timePeriod, 10),
          dateRange: selectedDates,
          filterBy,
          filterOptionValue: selectedFilterOption,
        },
      };

      getReporting({
        variables: {
          input: inputData,
        },
      });
    };
    if (filterBy && selectedFilterOption && (timePeriod || selectedDates)) submitQuery();
  }, [filterBy, getReporting, selectedDates, selectedFilterOption, timePeriod]);

  const submitReportingQuery = (value) => {
    setTimePeriod(null);
    setSelectedDates(value);
  };
  return (
    <Card.Section>
      <div style={{ display: "flex", marginBottom: "15px" }}>
        <Heading>Sales Metric</Heading>
        <div style={{ marginLeft: "auto" }}>
          <ButtonGroup segmented connectedTop>
            <Button size="slim" primary={chartType === "barChart"} onClick={() => setChartType("barChart")}>
              Bar chart
            </Button>
            <Button size="slim" primary={chartType === "pieChart"} onClick={() => setChartType("pieChart")}>
              Pie chart
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <ReportingFilters
        filterOptions={filterOptions}
        filterOptionsResponse={filterOptionsResponse}
        handleTimePeriod={handleTimePeriod}
        reportingConfig={reportingConfig}
        selectedFilterOption={selectedFilterOption}
        setFilterBy={setFilterBy}
        setFilterOptions={setFilterOptions}
        setSelectedDates={setSelectedDates}
        setSelectedFilterOption={setSelectedFilterOption}
        submitReportingQuery={submitReportingQuery}
        timePeriod={timePeriod}
      />
      {loading && (
        <div style={{ top: "60%", "min-height": "500px" }}>
          <Spinner />
        </div>
      )}
      {!loading && graphData?.length > 0 && chartType === "barChart" && (
        <BarGraph data={graphData} xDataKey="month" barDataKey="revenue" />
      )}
      {!loading && graphData?.length > 0 && chartType === "pieChart" && (
        <PieGraph data={graphData} labelSuffix="month" dataKey="revenue" />
      )}
      {!loading && !graphData?.length && <EmptyReportingState />}
    </Card.Section>
  );
};

SalesTab.propTypes = tabProp.type;

export default SalesTab;
