import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";

const Inventory = (props) => {
  const { data, onChange, cms } = props;
  const { sku, barCode, inventoryPolicy, quantity } = data;
  const isDontTrackPolicy = inventoryPolicy !== "shopify";

  const handleInventory = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInventoryPolicy = (value) => {
    if (value === "dontTrack") {
      handleInventory("quantity", "");
    }
    handleInventory("inventoryPolicy", value);
  };

  return (
    <Card sectioned title={cms("section.inventory.title")}>
      <FormLayout>
        <TextField
          id="sku"
          label={cms("section.inventory.label.sku")}
          value={sku}
          onChange={(value) => handleInventory(constant.SKU, value)}
          placeholder={cms("section.inventory.placeholder.zero")}
        />
        <TextField
          id="barCode"
          label={cms("section.inventory.label.barcode")}
          value={barCode}
          onChange={(value) => handleInventory(constant.BARCODE_CAMEL, value)}
          placeholder={cms("section.inventory.placeholder.zero")}
        />
        <Select
          id="inventoryPolicy"
          label={cms("section.inventory.label.inventoryPolicy")}
          options={[
            { label: cms("section.inventory.label.dontTrackInventory"), value: "dontTrack" },
            { label: cms("section.inventory.label.shopifyTracksInventory"), value: "shopify" },
          ]}
          onChange={handleInventoryPolicy}
          value={inventoryPolicy}
          placeholder={cms("section.inventory.placeholder.pleaseSelect")}
        />
        <TextField
          id="inventoryQuantity"
          label={cms("section.inventory.label.quantity")}
          value={quantity}
          onChange={(value) => handleInventory(constant.QUANTITY, value)}
          placeholder={cms("section.inventory.placeholder.zero")}
          disabled={isDontTrackPolicy}
        />
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Inventory;
