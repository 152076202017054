import * as yup from "yup";

const schema = (cms) => {
  const typeSchema = yup.object().shape({
    selectedStandardType: yup.string().min(1, cms("message.error.standardCommissionIsRequired")),
  });

  const amountSchema = yup.object().shape({
    standardAmount: yup.number().typeError(cms("message.error.standardCommissionIsRequired")).min(0),
  });

  const amountLengthSchema = yup.object().shape({
    standardAmount: yup.number().typeError(cms("message.error.standardCommissionIsRequired")).min(0).max(100),
  });

  return { typeSchema, amountSchema, amountLengthSchema };
};

export default schema;
