import {
  ChecklistMajor,
  ProfileMajor,
  OrdersMajor,
  PaymentsMajor,
  AddProductMajor,
  DiscountsMajor,
  ShipmentMajor,
} from "@shopify/polaris-icons";

const settingConfig = (cms) => {
  const config = {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.profile"),
        label: cms("common.actions.dashboard.label.profile"),
        icon: ProfileMajor,
        link: "/profile",
      },
    ],
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.acceptance"),
        label: cms("common.actions.orders.label.acceptance"),
        icon: OrdersMajor,
        link: "/setting/order",
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.stripe"),
        label: cms("common.actions.payments.label.stripe"),
        icon: PaymentsMajor,
        link: "/payment-connect",
      },
      {
        caption: cms("common.actions.payments.caption.paypal"),
        label: cms("common.actions.payments.label.paypal"),
        icon: PaymentsMajor,
        link: "/payment-connect",
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.autoAdd"),
        label: cms("common.actions.products.label.autoAdd"),
        icon: AddProductMajor,
        link: "/setting/product",
      },
      {
        caption: cms("common.actions.products.caption.productFormLabel"),
        label: cms("common.actions.products.label.productFormLabel"),
        link: "/setting/product/label",
        icon: ChecklistMajor,
      },
      {
        caption: cms("common.actions.products.caption.discount"),
        label: cms("common.actions.products.label.discount"),
        icon: DiscountsMajor,
        link: "/setting/discount",
      },
      {
        caption: cms("common.actions.products.caption.shipping"),
        label: cms("common.actions.products.label.shipping"),
        icon: ShipmentMajor,
        link: "/shipping",
      },
    ],
    "/product-lake": [
      // {
      //   caption: cms("common.actions.products.caption.autoAdd"),
      //   label: cms("common.actions.products.label.autoAdd"),
      //   icon: AddProductMajor,
      //   link: "/setting/product",
      // },
      // {
      //   caption: cms("common.actions.products.caption.productFormLabel"),
      //   label: cms("common.actions.products.label.productFormLabel"),
      //   link: "/setting/product/label",
      //   icon: ChecklistMajor,
      // },
      // {
      //   caption: cms("common.actions.products.caption.discount"),
      //   label: cms("common.actions.products.label.discount"),
      //   icon: DiscountsMajor,
      //   link: "/setting/discount",
      // },
      // {
      //   caption: cms("common.actions.products.caption.shipping"),
      //   label: cms("common.actions.products.label.shipping"),
      //   icon: ShipmentMajor,
      //   link: "/shipping",
      // },
    ],
  };
  const title = {
    "/": cms("common.actions.title.setting"),
    "/orders": cms("common.actions.title.orderSettings"),
    "/payments": cms("common.actions.title.paymentSettings"),
    "/products": cms("common.actions.title.productSettings"),
  };
  return { config, title };
};

export default settingConfig;
