import React, { useContext } from "react";
import { Button, Layout, Stack, TextContainer, TextField, Card } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import PropTypes from "prop-types";

const PaymentSection = (props) => {
  const { toggleSection, isPaymentEnable, paymentLoading } = props;
  const { cms = {} } = useContext(PrivateContext);
  const getPaymentSectionTitle = () => {
    return (
      <Stack>
        <Stack.Item fill>{cms("section.payment.title")}</Stack.Item>
        <Stack.Item>
          <Button
            secondary={isPaymentEnable}
            primary={!isPaymentEnable}
            onClick={toggleSection}
            loading={paymentLoading}
            destructive={isPaymentEnable}
          >
            {isPaymentEnable ? cms("button.disable") : cms("button.enable")}
          </Button>
        </Stack.Item>
      </Stack>
    );
  };

  const getFields = () => {
    const fields = [
      {
        label: cms("section.payment.label.bank"),
        placeholder: cms("section.payment.placeHolder.bank"),
        isRequired: true,
      },
      {
        label: cms("section.payment.label.account"),
        placeholder: cms("section.payment.placeHolder.account"),
        isRequired: true,
      },
      {
        label: cms("section.payment.label.sortCode"),
        placeholder: cms("section.payment.placeHolder.sortCode"),
        isRequired: true,
      },
      {
        label: cms("section.payment.label.country"),
        placeholder: cms("section.payment.placeHolder.country"),
        isRequired: true,
      },
    ];
    return fields.map(({ label, placeholder, isRequired }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField label={`${label}${required}`} placeholder={placeholder} value="" disabled />
          <br />
        </>
      );
    });
  };
  return (
    <div>
      <Layout.AnnotatedSection title={getPaymentSectionTitle()} description={cms("section.payment.description")}>
        <Card title={cms("section.payment.subTitle")}>
          <Card.Section>
            <TextContainer>{cms("section.payment.subDescription")}</TextContainer>
            <br />
            {getFields()}
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};

PaymentSection.propTypes = {
  toggleSection: PropTypes.func.isRequired,
  isPaymentEnable: PropTypes.bool.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
};

export default PaymentSection;
