import React, { useContext } from "react";
import { PaymentContext } from "app/setup/modules/operator/features/paymentService/context";

import PaypalSection from "./paypalSection";
import StripeSection from "./stripeSection";
import OtherCurrency from "./otherCurrency";

const AllowedCurrency = () => {
  const { isAllowedCurrency } = useContext(PaymentContext);
  return (
    <>
      <StripeSection />
      {(isAllowedCurrency && <PaypalSection />) || <OtherCurrency />}
    </>
  );
};

export default AllowedCurrency;
