// import metoer packages;
import React, { useContext } from "react";
import { Stack, Card, TextStyle, Badge, Link, Thumbnail, Caption, Button, Icon } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import _ from "lodash";

import { PrivateContext } from "lib/context";
// import helpers
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import propTypes
import { fulfilledLineItemsProps } from "app/orderLake/modules/operator/features/view/propsType";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const FulfilledLineItems = (props) => {
  const { lineData, fulfillmentType, setFulfillmentType } = props;
  const { cms, currentUser, match, history } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const vendorsSum = [];
  const countItems =
    lineData &&
    lineData.filter(
      (lineItem) =>
        ([constant.PARTIAL_SHIPPED, constant.PARTIAL_SHIP_SPACE].includes(
          lineItem.storeStatus && lineItem.storeStatus.toLowerCase()
        ) &&
          lineItem.trackingCompany &&
          lineItem.trackingNumber) ||
        [constant.COMPLETE, constant.SHIPPED].includes(lineItem.storeStatus && lineItem.storeStatus.toLowerCase())
    ).length;

  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);

  const subTotalAmount = lineData
    .filter(
      (lineItem) =>
        ([constant.PARTIAL_SHIPPED, constant.PARTIAL_SHIP_SPACE].includes(
          lineItem.storeStatus && lineItem.storeStatus.toLowerCase()
        ) &&
          lineItem.trackingCompany &&
          lineItem.trackingNumber) ||
        [constant.COMPLETE, constant.SHIPPED].includes(lineItem.storeStatus && lineItem.storeStatus.toLowerCase())
    )
    .map((item) => {
      let total = 0;
      const totalPrice = parseFloat(item.quantity) * parseFloat(isBigCommerceUser ? item.basePrice : item.price);
      if (item && item.providerId && !vendorsSum.includes[item.providerId]) {
        vendorsSum.push(item.providerId);
      }
      total = parseFloat(total) + parseFloat(totalPrice);
      return total;
    });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  const totalAmount = subTotalAmount.reduce((total, item) => {
    return total + item;
  }, 0);

  const lineItems = lineData.map((lineItem, idx) => {
    if (
      !lineItem ||
      ([constant.PARTIAL_SHIPPED, constant.PARTIAL_SHIP_SPACE].includes(
        lineItem.storeStatus && lineItem.storeStatus.toLowerCase()
      ) &&
        !(lineItem.trackingCompany && lineItem.trackingNumber))
    ) {
      return null;
    }
    const lineImage = lineItem.image || constant.NOIMAGESNAP;

    const lineItemKey = `lineItem${idx}`;
    const { isProductDeleted } = lineItem;
    const lineItemPrice = isBigCommerceUser ? lineItem.basePrice : lineItem.price;
    // const lineTotalPrice = parseFloat(lineItemPrice) * lineItem.quantity;

    const badgeType = baseHelper.getBadgeType(constant.COMPLETE);
    const lineItemName = <div className="productName">{lineItem.name}</div>;
    const productId = baseHelper.mongoIdAsString(lineItem.productId);

    const typeValue = lineItem && lineItem.fulfillmentType;
    const typeData = fulfillmentType;
    if (typeValue && _.isArray(typeData) && !typeData.includes(typeValue)) {
      typeData.push(typeValue);
    }
    setFulfillmentType(typeData);

    return (
      <div key={lineItemKey} className="badge-gap">
        <Card.Section subdued>
          <StyledCard className="styled-card-unfulfill">
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} />
              </Stack.Item>
              <Stack.Item fill>
                {(isProductDeleted && (lineItemName || "")) || (
                  <Link onClick={() => history.push(`/product-lake/edit/${productId}`)}>{lineItem.name || ""}</Link>
                )}
                <Caption>
                  <TextStyle variation="subdued">
                    {cms("label.skuList")}
                    {lineItem.sku}
                  </TextStyle>
                </Caption>
                <Stack alignment="center">
                  <Stack.Item>
                    <Badge status={badgeType} className="text-capitalize">
                      {constant.COMPLETE}
                    </Badge>
                    <Button plain onClick={() => window.open(`/orderlake-slip/${match.params.id}`)}>
                      <Stack spacing="extraTight" alignment="center">
                        <Stack.Item>
                          <Icon source={NoteMajor} />
                        </Stack.Item>
                        <Stack.Item>{`${cms("label.packingSlip")}`}</Stack.Item>
                      </Stack>
                    </Button>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>{` ${cms("label.qty")}: ${lineItem.quantity}`}</Stack.Item>
              <Stack.Item>
                {` ${cms("label.price")}: ${moneyFormat}${baseHelper.getPrice(lineItemPrice)}`}
              </Stack.Item>
              {/* <Stack.Item>
                {` ${cms("label.total")}: ${moneyFormat}${parseFloat(lineTotalPrice || 0).toFixed(2)}`}
              </Stack.Item> */}
            </Stack>
          </StyledCard>
        </Card.Section>
      </div>
    );
  });
  const totalFulfill = (
    <Card.Section>
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>{`${countItems} items from ${addVendorTxt}`}</Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {moneyFormat}
            {baseHelper.getPrice(totalAmount)}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
  return [lineItems, totalFulfill];
};

FulfilledLineItems.propTypes = fulfilledLineItemsProps.type;

export default FulfilledLineItems;
