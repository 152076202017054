import React, { useContext } from "react";
import { Card, TextStyle, Stack, Link, Caption, DataTable } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { customerProp } from "app/orders/modules/operator/features/view/propsType";

const VendorFulfillments = (props) => {
  const { lineData } = props;
  const { cms } = useContext(PrivateContext);

  const getFulfillmentStack = (item) => {
    return [
      <Stack.Item fill>
        <Link url="">{item.name}</Link>
        <Caption>
          <TextStyle variation="subdued">
            {`${cms("label.vendor")}: `}
            <Link>{item.vendor}</Link>
          </TextStyle>
        </Caption>
      </Stack.Item>,

      <Stack.Item>{item.vendorTrackingCompany}</Stack.Item>,
      <Stack.Item>{item.vendorTrackingNumber}</Stack.Item>,
    ];
  };

  const fulfillments = lineData
    .filter(
      (item) => item.isVendorFulfilled && !item.isConsignmentPick && item.fulfillmentStatus !== constant.FULFILLED
    )
    .map((selectedItem) => getFulfillmentStack(selectedItem));

  return (
    <div className="add-ellipsis">
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "numeric", "numeric"]}
          headings={[cms("label.product"), cms("label.shippingCompany"), cms("label.trackingNumber")]}
          rows={fulfillments}
        />
      </Card.Section>
      <Card>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.totalFulfillments")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${fulfillments.length} ${cms("label.of")} ${lineData.length}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

VendorFulfillments.propTypes = customerProp.type;
export default VendorFulfillments;
