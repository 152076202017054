// Note: Not using this file, Load module is handle in install.js.
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import { Layout } from "@shopify/polaris";

// import components
import { Spinner } from "lib/components";
import { PublicContext } from "lib/context";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";
import Banner from "lib/components/banner/banner";

import constant from "lib/constant/constant";

import { LOGIN_WOOCOMMERCE_SELLER } from "app/public/apollo/mutations/registerWooCommerceSeller";

const { gql } = constant;

const WooCommerceLoad = async () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { cms, history } = useContext(PublicContext);
  const { location } = history;
  const { state } = location;
  const queryParams = baseHelper.getQueryParams(history.location.search) || {};
  const { token } = queryParams;
  const [loginWooCommerceSeller, { loading: loginWooCommerceSellerLoading }] = useMutation(LOGIN_WOOCOMMERCE_SELLER);

  const onload = async () => {
    try {
      const response = await loginWooCommerceSeller({ variables: { input: { token } } });
      const responseData = baseHelper.getResponseData(response.data, gql.LOGIN_WOOCOMMERCE_SELLER);
      if (!(responseData && responseData.token)) {
        if (state && state.isError) {
          setBanner({ isOpen: true, status: "critical", title: state.message });
        }
        history.push("/woocommerce/load", { isError: true, message: cms("message.sellerloginError") });
        // history.go();
        return true;
      }
      storageHelper.set("token", responseData.token);
      history.push("/");
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.sellerloginError") });
    }
    return true;
  };

  if (!token || loginWooCommerceSellerLoading) {
    return (
      <div>
        <Spinner fontSize={42} />
        <h3 className="text-center">{cms("message.wait")}</h3>
      </div>
    );
  }

  if (!token) {
    onload();
  } else {
    history.push("/");
    // NOTE: As marketcube app was not loading (UI) so we used history.go to rerender marketcube app.
    history.go();

    // Temporary commented
    // if (state && state.isError) {
    //   setBanner({ isOpen: true, status: "critical", title: state.message });
    // }
    const onDismissBanner = () => {
      setBanner({
        isOpen: false,
        status: "",
        title: "",
      });
    };

    return (
      <>
        {banner.isOpen && (
          <Layout.Section>
            <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={onDismissBanner} />
          </Layout.Section>
        )}
      </>
    );
  }
};

export default WooCommerceLoad;
