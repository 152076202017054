import { gql } from "apollo-boost";

const GET_CATALOG = gql`
  query {
    getCatalog {
      data {
        catalog {
          title
          storeCatalogId
        }
      }
      status
      error
    }
  }
`;

export default GET_CATALOG;
