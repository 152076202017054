import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { OperatorGeolocationSetting } from "app/setup/modules/operator/features";

const { userKey } = constant;
const { operator } = userKey;

const ManageGeolocation = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorGeolocationSetting />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const TermsComponent = getComponent(currentUserRole);
  return TermsComponent;
};

export default withErrorBoundary(ManageGeolocation);
