const cmsFeaturePanel = (cms) => {
  const cmsData = {
    resourceName: {
      singular: cms("label.feature"),
      plural: cms("label.featurePlural"),
    },
    users: [
      {
        value: (cms("label.seller") || "").toLowerCase(),
        label: cms("label.seller"),
      },
      {
        value: (cms("label.vendor") || "").toLowerCase(),
        label: cms("label.vendor"),
      },
    ],
    label: {
      configure: cms("label.configure"),
      descriptionColon: `${cms("label.description")}:`,
      allowedForColon: `${cms("label.allowFor")}:`,
      allowedTierColon: `${cms("label.allowTier")}:`,
      hideFromColon: `${cms("label.hideFrom")}:`,
      hide: cms("label.hide"),
      show: cms("label.show"),
      up: cms("label.up"),
      down: cms("label.down"),
      NA: cms("label.N/A"),
      settings: cms("label.settings"),
      label: cms("label.label"),
      description: cms("label.description"),
      version: `${cms("label.version")}:`,
      sellersAllowed: cms("label.allowSeller"),
      vendorsAllowed: cms("label.allowVendor"),
      successFulFeaturePanel: cms("message.success.feature"),
      module: cms("label.module"),
      feature: cms("label.feature"),
    },
    button: {
      primaryAction: cms("button.save"),
    },
    placeHolder: {
      sellersAllowed: cms("placeHolder.seller"),
      vendorsAllowed: cms("placeHolder.vendor"),
    },
  };
  return cmsData;
};

export default cmsFeaturePanel;
