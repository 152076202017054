// import react packages;
import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import { PageActions, Stack, TextField } from "@shopify/polaris";

// import helper
import { OnboardingContext, PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { IS_BIGCOMMERCE_STORE_CONNECTED, SAVE_BIGCOMMERCE_STORE_DETAILS } from "app/productLake/apollo/mutations";

import SyncBigCommerceProduct from "app/productLake/modules/provider/features/add/subFeatures/syncBigcommerceProduct";

// import components
import TermModal from "app/product/modules/provider/features/genric/term/term";
// import newCMSData from "app/v1/products/modules/provider/features/add/subFeatures/wixConnect/cms/cms.json";
import ConnectedShop from "./subFeatureItems/connected";

const VendorConnectBigcommerce = (props) => {
  const { currentUser: currentUserData, history, cms } = useContext(PrivateContext);
  const { bigCommerce: bigCommerceConfig = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const { accessToken = "", clientId = "", clientSecret = "", shopDomain = "" } = bigCommerceConfig || {};
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { CRITICAL, SUCCESS, gql } = constant;
  const { setBanner } = props;
  const [value, setValue] = useState({
    accessToken: accessToken || "",
    shopDomain: shopDomain || "",
    clientId: clientId || "",
    clientSecret: clientSecret || "",
  });
  // const [isConnected, setIsConnected] = useState(!!shopDomain);
  const [isConnected, setIsConnected] = useState(false); // [TODO : NEED TO REMOVE]

  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    accessToken: "",
    shopDomain: "",
    clientId: "",
    clientSecret: "",
  });

  const [isBigCommerceConnected, { loading }] = useMutation(IS_BIGCOMMERCE_STORE_CONNECTED);
  const [saveBigCommerceStoreDetails, { storeloading }] = useMutation(SAVE_BIGCOMMERCE_STORE_DETAILS);
  const { IS_BIGCOMMERCE_STORE_CONNECTED: STORE_CONNECT, SAVE_BIGCOMMERCE_STORE_DETAILS: SAVE_STORE_DETAILS } = gql;

  const onSubmit = async () => {
    const { accessToken = "", shopDomain = "", clientId = "", clientSecret = "" } = value;
    const isValidDomainPath = baseHelper.isValidDomainPath(shopDomain.trim());

    if (!isValidDomainPath) {
      setBanner({ isOpen: true, status: CRITICAL, title: cms("section.connect.message.error.invalidShop") });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isBigCommerceConnected({
        variables: {
          input: {
            accessToken: (accessToken || "").trim(),
            shopDomain: (shopDomain || "").trim(),
            clientId: (clientId || "").trim(),
            clientSecret: (clientSecret || "").trim(),
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }
      setTimeout(async () => {
        try {
          const resp = await saveBigCommerceStoreDetails({
            variables: { input: { shopDomain, clientId, clientSecret, accessToken } },
          });
          const respError = baseHelper.getResponseError(resp.data, SAVE_STORE_DETAILS);
          if (respError) {
            setBanner({ isOpen: true, status: CRITICAL, title: respError });
            setIsOpen(false);
          }
          const respData = baseHelper.getResponseData(resp.data, SAVE_STORE_DETAILS);
          if (respData) {
            setBanner({
              isOpen: true,
              status: SUCCESS,
              title: cms("section.connect.message.success.connected"),
            });
            setIsOpen(false);
          }
        } catch (e) {
          setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(e) });
          setIsOpen(false);
        }
      });
      const responseData = baseHelper.getResponseData(response.data, STORE_CONNECT);
      if (responseData) {
        setIsConnected(true);
        setBanner({
          isOpen: true,
          status: "success",
          title: `${cms("section.connect.message.success.connected")}`,
        });
        setIsOpen(false);
        return;
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const handleTermModal = (fieldName = "check", modalValue = "") => {
    if (!value.shopDomain || !value.accessToken || !value.clientId || !value.clientSecret) {
      setIsOpen(false);
      const errorMessages = {
        clientId: cms("section.connect.message.error.id"),
        accessToken: cms("section.connect.message.error.token"),
        shopDomain: cms("section.connect.message.error.domain"),
        clientSecret: cms("section.connect.message.error.secret"),
      };
      setErrorMessage({
        ...errorMessage,
        [fieldName]: !value[fieldName] ? errorMessages[fieldName] : "",
      });

      return;
    }
    if (modalValue === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  return (
    <>
      <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shopDomain={value.shopDomain}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item="BigCommerce"
      />
      <TextField
        label={`${cms("section.connect.label.shopDomain")}*`}
        error={errorMessage.shopDomain}
        id="shopInput"
        prefix="https://"
        suffix=".mybigcommerce.com"
        placeholder={cms("section.connect.placeholder.demo")}
        type={constant.TEXT}
        value={value.shopDomain}
        onChange={(inputValue) => handleChange(constant.SHOP_DOMAIN, inputValue)}
        onBlur={() => handleTermModal(constant.SHOP_DOMAIN)}
        disabled={isConnected}
      />
      {!isConnected ? (
        <>
          <br />
          <TextField
            label={`${cms("section.connect.label.bigCommerceClientId")}*`}
            error={errorMessage.clientId}
            id="clientId"
            placeholder={cms("section.connect.placeholder.id")}
            type={constant.TEXT}
            value={value.clientId}
            onChange={(inputValue) => handleChange(constant.CLIENT_ID, inputValue)}
            onBlur={() => handleTermModal(constant.CLIENT_ID)}
            disabled={isConnected}
          />
        </>
      ) : null}
      {!isConnected ? (
        <>
          <br />
          <TextField
            label={`${cms("section.connect.label.bigCommerceClientSecret")}*`}
            error={errorMessage.clientSecret}
            id="clientSecret"
            placeholder={cms("section.connect.placeholder.secret")}
            type={constant.TEXT}
            value={value.clientSecret}
            onChange={(inputValue) => handleChange(constant.CLIENT_SECRET, inputValue)}
            onBlur={() => handleTermModal(constant.CLIENT_SECRET)}
            disabled={isConnected}
          />
        </>
      ) : null}
      {!isConnected ? (
        <>
          <br />
          <TextField
            label={`${cms("section.connect.label.bigCommerceAccessToken")}*`}
            error={errorMessage.accessToken}
            id="accessToken"
            placeholder={cms("section.connect.placeholder.token")}
            type={constant.TEXT}
            value={value.accessToken}
            onChange={(inputValue) => handleChange(constant.ACCESS_TOKEN, inputValue)}
            onBlur={() => handleTermModal(constant.ACCESS_TOKEN)}
            disabled={isConnected}
          />
        </>
      ) : null}
      {isConnected && (
        <>
          <br />
          <Stack>
            <Stack.Item fill>
              <ConnectedShop
                cms={cms}
                currentUserData={currentUserData}
                setBanner={setBanner}
                setIsConnected={setIsConnected}
                setValue={setValue}
                value={value}
              />
            </Stack.Item>
            <Stack.Item>
              <SyncBigCommerceProduct history={history} setBanner={setBanner} cms={cms} />
            </Stack.Item>
          </Stack>
        </>
      )}
      {!isConnected && (
        <PageActions
          primaryAction={{
            id: "connectShop",
            content: `${cms("section.connect.button.connect")}`,
            onAction: () => handleTermModal("check ", constant.CONNECT),
            disabled: isConnected,
          }}
          secondaryActions={[
            {
              id: "cancelAction",
              content: `${cms("section.connect.button.cancel")}`,
              onAction: () => (isOnboarding && onPrevious()) || history.push("/products"),
            },
          ]}
        />
      )}
    </>
  );
};

VendorConnectBigcommerce.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectBigcommerce.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorConnectBigcommerce), {
  feature: constant.PRODUCT_IMPORT_BIG_COMMERCE,
  // subFeature: constant.PRODUCT_IMPORT_WOOCOMMERCE,
});
