import styled from "styled-components";

const CardSkeleton = styled.div`
  min-height: 110px;
  display: flex;
  overflow: hidden;
  .Polaris-SkeletonThumbnail {
    width: 24%;
    flex: 0 0 24%;
    min-height: 110px;
    margin-right: 1rem;
  }
`;
export default CardSkeleton;
