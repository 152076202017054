import React, { useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import _ from "lodash";

import {
  Button,
  Card,
  Checkbox,
  Collapsible,
  DisplayText,
  Layout,
  Link,
  List,
  Modal,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";
import constant from "lib/constant/constant";

import { GET_PRODUCT_VERSION } from "app/productOld/apollo/queries";
import { UPDATE_PRODUCT_VERSION } from "app/productOld/apollo/mutations";

const ProductVersioning = (props) => {
  const { cms, productId, setListBanner } = props;
  const { productVersion: versioningContent, gql } = constant;
  const { GET_PRODUCT_VERSION: GET_PRODUCT_VERSION_TEXT, UPDATE_PRODUCT_VERSION: UPDATE_PRODUCT_VERSION_TEXT } = gql;

  // states
  const [showModal, setShowModal] = useState(false);
  const [revertVersionId, setRevertVersionId] = useState("");
  const [productRevertKeys, setProductRevertKeys] = useState([]);
  const [showProductKeyChange, setShowProductKeyChange] = useState([]);
  const [isVariant, setIsVariant] = useState(false);
  const [productVersions, setProductVersions] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  // query calls
  const { error, loading, data: versionData } = useQuery(GET_PRODUCT_VERSION, {
    variables: {
      input: {
        productId,
      },
    },
  });

  // mutation calls
  const [updateProductVersion, { loading: updateVersionLoading, error: updateVersionError }] = useMutation(
    UPDATE_PRODUCT_VERSION
  );

  useEffect(() => {
    const responseError = baseHelper.getResponseError(versionData, GET_PRODUCT_VERSION_TEXT);
    if (responseError) {
      setListBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    const { isVariant: variant = false, productVersions: versions = [] } = baseHelper.getResponseData(
      versionData,
      GET_PRODUCT_VERSION_TEXT
    );

    setIsVariant(variant);
    setProductVersions(versions);
  }, [GET_PRODUCT_VERSION_TEXT, setListBanner, versionData]);

  useEffect(() => {
    if (error) {
      setListBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error),
      });
    }
  }, [error, setListBanner]);

  useEffect(() => {
    if (updateVersionError) {
      setListBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(updateVersionError),
      });
    }
  }, [setListBanner, updateVersionError]);

  if (loading) {
    return <Spinner size="small" />;
  }

  const toggleRevertModal = (versionId) => {
    setShowModal(!showModal);
    setRevertVersionId(versionId);
    setProductRevertKeys([]);
    setShowProductKeyChange([]);
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const revertChange = async (productChangeId) => {
    if (!(productRevertKeys && productRevertKeys.length)) {
      setBanner({
        isOpen: true,
        title: cms("common.history.message.error.changesToRevert"),
        status: constant.CRITICAL,
      });
      return;
    }

    const data = {
      productChangeId: baseHelper.mongoIdAsString(productChangeId),
      productKeysToRevert: [...productRevertKeys],
    };

    const updateVersionResp = await updateProductVersion({
      variables: {
        input: data,
      },
    });

    const responseError = baseHelper.getResponseError(updateVersionResp, UPDATE_PRODUCT_VERSION_TEXT);

    if (responseError) {
      setListBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }

    toggleRevertModal("");
    setListBanner({
      isOpen: true,
      title: cms("common.history.message.success.changesReverted"),
      status: constant.SUCCESS,
    });
  };

  const getData = (value) => (_.isBoolean(value) && (value ? versioningContent.yes : versioningContent.no)) || value;

  const getDataInHTMLForm = (data) => ({ __html: data });

  const handleRevertTick = (key) => {
    const keyIndex = productRevertKeys.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      productRevertKeys.splice(keyIndex, 1);
    } else {
      productRevertKeys.push(key);
    }
    setProductRevertKeys([...productRevertKeys]);
  };

  const handleToggleProductDiff = (key) => {
    const keyIndex = showProductKeyChange.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      showProductKeyChange.splice(keyIndex, 1);
    } else {
      showProductKeyChange.push(key);
    }

    setShowProductKeyChange([...showProductKeyChange]);
  };

  const renderProductChange = ({ key, prev, updated }) => {
    const isKeyDescription = key === constant.DESCRIPTION;
    const isKeyTags = key === constant.TAGS;
    const isKeyCollection = key === constant.PRODUCT_COLLECTION;
    const isKeyMeasurement = key === constant.MEASUREMENT;

    if (isVariant && !["title", "description", "tags", "productType"].includes(key)) {
      return null;
    }
    const prevData = isKeyCollection || isKeyTags ? prev && baseHelper.sort(prev).join(", ") : prev && getData(prev);
    const updatedData =
      isKeyCollection || isKeyTags ? updated && baseHelper.sort(updated).join(", ") : updated && getData(updated);
    if (isKeyTags && (!(prevData || updatedData) || prevData === updatedData)) {
      return null;
    }
    const prevProductData = isKeyDescription ? (
      // eslint-disable-next-line react/no-danger
      <p dangerouslySetInnerHTML={getDataInHTMLForm(prevData)} />
    ) : (
      (isKeyMeasurement && [
        Object.keys(prevData).map((item) => (
          <>
            {`${item}:${prevData[item].value}`}
            <br />
          </>
        )),
      ]) ||
      prevData
    );
    const updatedProductData = isKeyDescription ? (
      // eslint-disable-next-line react/no-danger
      <p dangerouslySetInnerHTML={getDataInHTMLForm(updatedData)} />
    ) : (
      (isKeyMeasurement && [
        Object.keys(updatedData).map((item) => (
          <>
            {`${item}:${updatedData[item].value}`}
            <br />
          </>
        )),
      ]) ||
      updatedData
    );

    const buttonText = showProductKeyChange.includes(key) ? cms("common.label.hide") : cms("common.label.show");

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label={cms("common.history.label.checkbox")}
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{`${versioningContent[key]} ${cms("common.history.label.changed")}`}</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title={cms("commom.history.title.previous")}>{prevProductData || "No Value"}</Card.Section>
            <Card.Section subdued title={cms("commom.history.title.updated")}>
              {updatedProductData || "No Value"}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderImageChange = ({ key, prev, updated }) => {
    const buttonText = showProductKeyChange.includes(key) ? cms("common.label.hide") : cms("common.label.show");
    const prevImages = [];
    const updatedImages = [];

    (prev || []).forEach((image) => {
      prevImages.push([<Thumbnail source={image.imageUrl} alt="Vendor Image" />]);
    });
    (updated || []).forEach((image) => {
      updatedImages.push([<Thumbnail source={image.imageUrl} alt="Vendor Image" />]);
    });

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label={cms("common.history.label.checkbox")}
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{`${versioningContent[key]} ${cms("common.history.label.changed")}`}</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title={cms("commom.history.title.previous")}>
              {prevImages && prevImages.length ? <Stack>{prevImages}</Stack> : "No Images"}
            </Card.Section>
            <Card.Section subdued title={cms("commom.history.title.updated")}>
              {updatedImages && updatedImages.length ? <Stack>{updatedImages}</Stack> : "No Images"}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderVariantChange = ({ key, prev, updated }) => {
    const buttonText = showProductKeyChange.includes(key) ? cms("common.label.hide") : cms("common.label.show");

    const getVariantInfo = (variant = {}) => {
      return (
        <Card.Section title={`${cms("common.history.title.variantPosition")}: ${variant.position}`}>
          {variant.option1Val ? `${variant.option1}: ${variant.option1Val}` : ""}
          {variant.option1Val ? <br /> : ""}
          {variant.option2Val ? `${variant.option2}: ${variant.option2Val} ` : ""}
          {variant.option2Val ? <br /> : ""}
          {variant.option3Val ? `${variant.option3}: ${variant.option3Val} ` : ""}
          {variant.option3Val ? <br /> : ""}
          {`${cms("common.history.label.image")}:`}
          {variant.image ? (
            <Thumbnail source={variant.image} alt={cms("common.history.label.variantImage")} />
          ) : (
            cms("common.history.label.noImage")
          )}
          <br />
          {`${cms("common.history.label.price")}: ${variant.price || "0"}`}
          <br />
          {`${cms("common.history.label.compare")} ${cms("common.history.label.price")} : ${
            variant.comparePrice || "0"
          }`}
          <br />
          {`${cms("common.history.label.quantity")}: ${variant.inventoryQuantity || "0"}`}
          <br />
          {`${cms("common.history.label.inventoryManagement")}: ${
            variant.inventoryManagement ? cms("common.history.label.shopify") : cms("common.history.label.inventory")
          }`}
          <br />
          {`${cms("common.history.label.sku")}: ${variant.sku || cms("common.history.label.noValue")}`}
          <br />
          {`${cms("common.history.label.barcode")}: ${variant.barcode || cms("common.history.label.noValue")}`}
          <br />
          {`${cms("common.history.label.shippingRequired")}: ${
            variant.isShipping ? cms("common.history.label.yes") : cms("common.history.label.no")
          }`}
          <br />
          {`${cms("common.history.label.weight")}: ${variant.weight || cms("common.history.label.no")}`}
          <br />
          {`${cms("common.history.label.weightUnit")}: ${variant.weightUnit || cms("common.history.label.noValue")}`}
        </Card.Section>
      );
    };

    const prevVariants = (prev || []).map(getVariantInfo);
    const updatedVariants = (updated || []).map(getVariantInfo);

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label={cms("common.history.label.checkbox")}
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{`${versioningContent[key]} ${cms("common.history.label.changed")}`}</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title={cms("commom.history.title.previous")}>
              {prevVariants.length ? <Card>{prevVariants}</Card> : cms("common.history.label.noVariants")}
            </Card.Section>
            <Card.Section subdued title={cms("commom.history.title.updated")}>
              {updatedVariants.length ? <Card>{updatedVariants}</Card> : cms("common.history.label.noVariants")}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderRevertModal = (productVersion) => {
    const { changes = [], version, _id } = productVersion;
    if (revertVersionId !== baseHelper.mongoIdAsString(_id)) {
      return null;
    }

    return (
      <Modal
        key="productChangeRevertModal"
        open={showModal}
        onClose={toggleRevertModal}
        title={`${cms("common.history.title.changeVersion")} ${version}`}
        primaryAction={{
          content: cms("common.history.button.revert"),
          onAction: () => revertChange(_id),
          loading: updateVersionLoading,
        }}
        secondaryActions={{
          content: cms("common.button.cancel"),
          onAction: () => toggleRevertModal(),
        }}
        sectioned
      >
        <Modal.Section>
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
              />
              <br />
            </>
          )}
          <Layout>
            <Layout.Section>
              {changes.map((change) => {
                const { key } = change;
                if (key === "status") {
                  return null;
                }
                return (
                  (key === "images" && renderImageChange(change)) ||
                  (key === "variants" && renderVariantChange(change)) ||
                  renderProductChange(change)
                );
              })}
            </Layout.Section>
          </Layout>
        </Modal.Section>
      </Modal>
    );
  };

  const renderVersion = (productVersion, isLatest) => {
    const { changes = [], operation, userName, createdAt, version, via, revertedVersion, _id } = productVersion;
    const isOnlyStatusChanged = changes && changes.length === 1 && changes[0].key === "status";
    const isReverted = operation === "reverted";

    const isShowChange = ({ key }) => {
      const isProductKey = ["title", "description", "tags", "productType", "images", "variants"].includes(key);
      const excludeKeys = ["customMetafields", "status"];
      return !excludeKeys.includes(key) && (isVariant ? isProductKey : true);
    };

    const isChange = changes && !!changes.length && changes.some((change) => isShowChange(change));

    const viaText =
      (via === "shopify" && cms("common.history.label.shopifyStore")) ||
      (via === "vendorShopify" && cms("common.history.label.vendorLiveConnect")) ||
      cms("common.history.label.portal");

    return (
      <List.Item>
        <p className={isLatest ? "title-list" : "title-list other"}>
          {baseHelper.ucFirst(operation)}
          {" by "}
          <TextStyle variation="strong">{`${userName} `}</TextStyle>
          {`${cms("common.label.on")} ${baseHelper.formatDate(createdAt, true)}`}
        </p>
        <p>
          {`${cms("common.history.label.changeTriggered")} `}
          <TextStyle variation="strong">{viaText}</TextStyle>
        </p>
        <p>
          <TextStyle variation="subdued">
            {`${cms("common.history.label.version")} ${version} `}
            {isChange && (
              <Link monochrome onClick={() => toggleRevertModal(baseHelper.mongoIdAsString(_id))}>
                {cms("common.history.label.revertToVersion")}
              </Link>
            )}
          </TextStyle>
        </p>
        {changes && !!changes.length && !isOnlyStatusChanged ? (
          <List type="bullet">
            {changes.map(
              (change) =>
                isShowChange(change) && (
                  <List.Item>
                    <TextStyle variation="strong">
                      {`${versioningContent[`${change.key}`] || "Basic"} ${cms("common.history.label.changed")} ${" "}`}
                      {isReverted ? `(${cms("common.history.label.versionReverted")} ${revertedVersion})` : ""}
                    </TextStyle>
                  </List.Item>
                )
            )}
          </List>
        ) : null}
      </List.Item>
    );
  };

  return productVersions && productVersions.length ? (
    <div className="timelineWrapper">
      {productVersions.map((version, index) => {
        const isFirstChange = !index;
        return [
          <div className="timeline">
            <List>{renderVersion(version, isFirstChange)}</List>
          </div>,
          renderRevertModal(version),
        ];
      })}
    </div>
  ) : (
    <div className="product_version_nochange">
      <TextStyle>{cms("common.history.label.noChanges")}</TextStyle>
    </div>
  );
};

ProductVersioning.defaultProps = {
  cms: () => {},
  productId: "",
  setListBanner: {},
};

ProductVersioning.propTypes = {
  cms: PropTypes.func,
  productId: PropTypes.string,
  setListBanner: PropTypes.objectOf(string),
};

export default ProductVersioning;
