const settingList = (cms) => [
  {
    code: "marketplace",
    title: cms("admin.title"),
    description: cms("admin.description"),
    thumbnail: cms("admin.thumbnail"),
    list: [
      {
        title: cms("admin.section.user.title"),
        description: cms("admin.section.user.description"),
        icon: "fal fa-user fa-lg",
        code: "user",
        thumbnailHeader: cms("admin.section.user.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("admin.section.user.thumbnail"),
        thumbnailDescription: cms("admin.section.user.thumbnailDescription"),
        actions: [
          {
            label: cms("admin.section.user.action.profile.label"),
            link: "/profile",
            code: "profile",
            description: cms("admin.section.user.action.profile.description"),
          },
          // {
          //   label: cms("admin.section.user.action.emailTemplate.label"),
          //   code: "emailTemplate",
          //   link: "/email-template",
          //   description: cms("admin.section.user.action.emailTemplate.description"),
          // },
          {
            label: cms("admin.section.user.action.onboard.label"),
            code: "onboardingHistory",
            link: "/onboard-activity",
            description: cms("admin.section.user.action.onboard.description"),
          },
          {
            label: cms("admin.section.user.action.userSession.label"),
            code: "session",
            link: "/session",
            description: cms("admin.section.user.action.userSession.description"),
          },
          {
            label: cms("admin.section.user.action.association.label"),
            code: "association",
            link: "/association",
            description: cms("admin.section.user.action.association.description"),
          },
          {
            label: cms("admin.section.user.action.payout.label"),
            code: "automaticPayouts",
            link: "/payouts",
            description: cms("admin.section.user.action.payout.description"),
          },
          {
            label: cms("admin.section.user.action.maintenance.label"),
            code: "maintenance",
            link: "/maintenance",
            description: cms("admin.section.user.action.maintenance.description"),
          },
          {
            label: cms("admin.section.user.action.customDomain.label"),
            code: "customDomain",
            link: "/domain",
            description: cms("admin.section.user.action.customDomain.description"),
          },
          {
            label: cms("admin.section.user.action.feature.label"),
            code: "feature",
            link: "/feature",
            description: cms("admin.section.user.action.feature.description"),
          },
        ],
      },
    ],
  },
];

export default settingList;
