import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  Badge,
  Button,
  ButtonGroup,
  Caption,
  Card,
  FormLayout,
  Icon,
  Link,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";

import { updateOrderLineTracking, updateOrderLineStatus } from "app/orders/apollo/updateOrderNote";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";
import { NoteMajor } from "@shopify/polaris-icons";

const UnfulfilledLineItems = (props) => {
  const { data, itemData, onRefetchOrderLineView, onRefetchOrderView, setBanner, setMessage } = props;
  const [trackingCompany] = useState("");
  const [trackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");

  const [trackingModal, setTrackingModal] = useState(false);

  const [selectedItemData, setSelectedItemData] = useState({});
  const [isAcceptOrder, setIsAcceptedOrder] = useState("");

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );
  const [updateOrderStatus, { data: updateOrderLineData, loading: orderLineLoading }] = useMutation(
    updateOrderLineStatus
  );
  const { setPageData, pageData, history, match, cms } = useContext(PrivateContext);
  const { title, breadcrumbs, subtitle } = pageData;
  const processRow = (value, isAccept) => {
    const { _id: id, orderId } = value;
    const formData = { lineItemId: id, orderId, isAccept };

    setIsAcceptedOrder(isAccept);
    updateOrderStatus({ variables: { input: formData } });
  };
  useEffect(() => {
    if (updateOrderLineData) {
      const updateOrderStatusData = baseHelper.getResponseData(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      const updateOrderStatusError = baseHelper.getResponseError(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      if (updateOrderStatusData && isAcceptOrder) {
        setPageData({ title, breadcrumbs, subtitle });
        setMessage(cms("message.success.orderLineApproved"));
        onRefetchOrderView();
        onRefetchOrderLineView();
      } else if (updateOrderStatusData && !isAcceptOrder) {
        setMessage(cms("message.error.orderLineRejected"));
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderStatusError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderStatusError,
          isOpen: true,
        });
      }
    }
  }, [updateOrderLineData]);
  const openTrackingModel = (value) => {
    setSelectedItemData(value);
    setTrackingModal(true);
  };

  const addTrackingDetails = () => {
    if (!initialTrackingCompany) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingCompany"), status: "critical" });
      return false;
    }

    if (!initialTrackingNumber) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingNumber"), status: "critical" });
      return false;
    }
    // eslint-disable-next-line no-shadow
    const data = {
      trackingNumber: initialTrackingNumber.trim(),
      trackingCompany: initialTrackingCompany.trim(),
    };
    const { _id: id, orderId } = selectedItemData;
    data.id = orderId;
    data.lineItemId = id;
    updateOrderLine({ variables: { input: data } });
    return null;
  };
  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );

      if (updateOrderTracking) {
        setPageData({ title, breadcrumbs, subtitle });
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setMessage(cms("message.success.orderFulfilled"));
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataUpdateOrderLineTracking]);

  const acceptOnlyValidInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  const renderTracking = () => {
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber(trackingNumber);
            setInitialTrackingCompany(trackingCompany);
          }}
          title={cms("label.addTracking")}
          primaryAction={{
            content: cms("label.saveDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={cms("label.company")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(acceptOnlyValidInput(val));
                }}
              />
              <TextField
                label={cms("label.trackingNumber")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(acceptOnlyValidInput(val));
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };
  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    let countItems = 0;
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    itemData.forEach(({ vendor, price, quantity = 1, fulfillmentStatus }) => {
      if (fulfillmentStatus === constant.UNFULFILLED) {
        vendorsSum.push(vendor);
        amount.push(parseFloat(price) * parseInt(quantity, 10));
        countItems += 1;
      }
      return [vendorsSum, amount];
    });

    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
    // const lineDataItems = itemData.length;

    const stringToNumber = amount.map((item) => parseFloat(item));
    const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.total")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {countItems > 1
                  ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${moneyFormat} ${baseHelper.getPrice(totalAmount)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  const renderUnfullfilled = () => {
    const UnfulFilled = itemData.map((value, key) => {
      if (!value || value.fulfillmentStatus !== constant.UNFULFILLED) {
        return null;
      }
      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted, isVendorDeleted } = value;

      let isAccepted = false;
      let isFulfilled = false;
      switch (value.status) {
        case constant.ACCEPTED:
          isAccepted = true;
          break;
        case constant.REJECTED:
          isAccepted = false;
          break;
        case constant.STATUS_NEW:
          isAccepted = true;
          break;
        case constant.CLOSED:
          isAccepted = null;
          break;
        default:
          break;
      }
      if (value.fulfillmentStatus === constant.UNFULFILLED) {
        switch (value.status) {
          case constant.ACCEPTED:
            isFulfilled = false;
            break;
          case constant.REJECTED:
            isFulfilled = false;
            break;
          case constant.STATUS_NEW:
            isFulfilled = true;
            break;
          case constant.CLOSED:
            isFulfilled = null;
            break;
          default:
            break;
        }
      }
      const moneyFormat = value.moneyFormat || constant.symbol.DOLLAR;
      const lineTotalPrice = value.price * value.quantity;
      const status = statusHelper.getBadgeStatus(value.status);
      const badgeType = baseHelper.getBadgeType(value.status);
      const lineItemName = <div className="productName">{value.name}</div>;

      return (
        <div className="badge-gap" key={lineItemKey}>
          <Card.Section subdued>
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={value.name} />
              </Stack.Item>
              <Stack.Item fill>
                <div className="ellipsis">
                  {(isProductDeleted && (lineItemName || "")) || (
                    <Link onClick={() => history.push(`/products/edit/${value.productId}`)}>{value.name || ""}</Link>
                  )}

                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>
                      {value.vendor && value.vendorId && `${cms("label.vendor")}: `}
                      {isVendorDeleted && value.vendor}
                      {((value.vendor && value.vendorId) || !isVendorDeleted) && (
                        <Link onClick={() => history.push(constant.PROVIDER_VIEW + value.vendorId)}>
                          {value.vendor}
                        </Link>
                      )}
                    </TextStyle>
                    <br />
                    <TextStyle variation={constant.SUBDUED}>
                      {cms("label.skuList")}
                      {value.sku}
                    </TextStyle>
                  </Caption>

                  <Stack alignment="center">
                    <Stack.Item>
                      <Badge
                        status={
                          (isAccepted && value.status === cms("label.accepted").toLowerCase() && constant.SUCCESS) ||
                          (isAccepted === false && constant.WARNING) ||
                          (isAccepted && value.status === cms("label.new").toLowerCase() && constant.ATTENTION) ||
                          badgeType
                        }
                        className="text-capitalize"
                      >
                        {(isAccepted &&
                          value.status === cms("label.accepted").toLowerCase() &&
                          cms("label.accepted")) ||
                          (isAccepted === false && cms("label.rejected")) ||
                          (isAccepted && value.status === cms("label.new").toLowerCase() && cms("label.new")) ||
                          status}
                      </Badge>
                    </Stack.Item>
                  </Stack>

                  {isAccepted && isFulfilled && (
                    <Stack alignment="center">
                      <Stack.Item>
                        <ButtonGroup>
                          <Tooltip content={constant.REJECT_ORDER}>
                            <div style={{ color: "#bf0711" }}>
                              <Button
                                plain
                                monochrome
                                onClick={() => processRow(value, false, key)}
                                loading={orderLineLoading}
                              >
                                {baseHelper.ucFirst(cms("label.rejectOrder"))}
                              </Button>
                            </div>
                          </Tooltip>
                          <Tooltip content={constant.ACCEPT_ORDER}>
                            <Button
                              size="slim"
                              monochrome
                              outline
                              primary
                              onClick={() => processRow(value, true, key)}
                              loading={orderLineLoading}
                            >
                              {baseHelper.ucFirst(cms("label.acceptOrder"))}
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </Stack.Item>
                    </Stack>
                  )}

                  {isAccepted && !isFulfilled && (
                    <FormLayout>
                      <Stack alignment="center">
                        <Stack.Item>
                          <ButtonGroup>
                            <Button size="slim" onClick={() => openTrackingModel(value)}>
                              {baseHelper.ucFirst(cms("label.fulfillItem"))}
                            </Button>
                            <Button plain size="slim" onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                              <Stack spacing="extraTight">
                                <Stack.Item>
                                  <Icon source={NoteMajor} />
                                </Stack.Item>
                                <Stack.Item>{`${cms("label.packingSlip")}`}</Stack.Item>
                              </Stack>
                            </Button>
                          </ButtonGroup>
                        </Stack.Item>
                      </Stack>
                    </FormLayout>
                  )}
                </div>
              </Stack.Item>
              <Stack.Item>
                {`${cms("label.qty")}:`}
                {value.quantity}
              </Stack.Item>
              <Stack.Item>
                {moneyFormat}
                {(lineTotalPrice && baseHelper.getPrice(lineTotalPrice)) || constant.value.ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </div>
      );
    });
    return UnfulFilled;
  };

  return [renderUnfullfilled(), renderTotalAmount(), renderTracking()];
};

export default UnfulfilledLineItems;
