import React, { useContext, useState, useEffect } from "react";
import { AppProvider, Frame, Page, TopBar, DisplayText, SkeletonDisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PublicContext, PublicBrandingContext } from "lib/context";

// import helper
import { chatHelper, storageHelper, baseHelper, imageHelper, yupHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import SEO from "lib/components/seo/seo";

// import styles
import { MainHeader, LoadingWrapper } from "layout/generic/genericStyle";
import { StyledPage } from "./publicStyle";

const PublicLayout = (props) => {
  const { pageData = {}, isLoading, history } = useContext(PublicContext);
  const { branding = {}, brandingLoading = false } = useContext(PublicBrandingContext);
  const { breadcrumbs = [] } = pageData;
  const { children = null } = props;

  const { favicon } = branding.brand || {};
  const { imageUrl: faviconUrl = "" } = favicon || {};
  const faviconLogo = faviconUrl || storageHelper.get("faviconURL");
  const brandNameData = (branding && branding.brandName) || storageHelper.get("brandName");
  const vendorLogo =
    (branding && branding.logoUrl) || storageHelper.get("logoUrl") || (!brandNameData && constant.LOGO);
  const headerFont = (branding && branding.headerFont) || storageHelper.get("headerFont");
  const headerColor = (branding && branding.headerColor) || storageHelper.get("headerColor");
  const brandName = brandNameData || "Marketcube.io";

  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get("hideMcDetails"));

  const initialBranding = {
    logoUrl: "",
    headerFont: "",
    headerColor: "",
    brandName: "",
  };

  useEffect(() => {
    if (branding && branding.hideMcDetails) {
      setHideMcDetails(true);
    }
  }, [branding]);
  // const theme = {
  //   colors: {
  //     topBar: {
  //       background: headerColor && headerColor !== "" ? headerColor : "#36454f",
  //       color: headerFont && headerFont !== "" ? headerFont : "",
  //     },
  //   },
  // };

  const isValidHex = (hexCode) => yupHelper.hexRegex.test(hexCode || "");

  const { location = {} } = history;
  const { pathname = "/login" } = location;
  const isLoginPage = pathname === "/login";

  const getComponent = () => {
    if (isLoading || brandingLoading) {
      return <SkeletonAnnotated annotatedLines={5} primaryLines={5} />;
    }
    return children;
  };

  const getMenuLogo = (brandNameText) => {
    // const titleStyle = {
    //   color: headerFont || "#ffffff",
    // };
    if (vendorLogo) {
      return (
        <img
          className="brandLogo"
          src={imageHelper.resize({ url: vendorLogo, type: constant.imageTypes.HEADER_LOGO })}
          alt="Brand Logo"
        />
      );
    }
    return (
      <div className="brandTitle">
        <DisplayText>{baseHelper.ucFirst(brandNameText)}</DisplayText>
      </div>
    );
  };

  const linkStyle = {
    textDecorationLine: "none",
    cursor: "pointer",
  };

  const menuText = (name) => (
    <a style={linkStyle} href={(!isLoginPage && "/login") || false}>
      {getMenuLogo(name)}
    </a>
  );

  if (hideMcDetails) {
    chatHelper.hide();
  }

  if (baseHelper.isIframe()) {
    Object.keys(initialBranding).forEach((key) => {
      storageHelper.set(key, initialBranding[key]);
    });
  }

  return (
    <AppProvider>
      <Frame
        topBar={
          (brandingLoading && (
            <LoadingWrapper>
              <SkeletonDisplayText size="extraLarge" />
            </LoadingWrapper>
          )) || (
            <MainHeader
              background={isValidHex(headerColor) && headerColor}
              color={isValidHex(headerFont) && headerFont}
              className="main-header"
            >
              <TopBar contextControl={menuText(brandName)} />
            </MainHeader>
          )
        }
      >
        <SEO loading={brandingLoading} title={baseHelper.ucFirst(brandName)} favicon={faviconLogo || vendorLogo} />
        <StyledPage className="page-wrapper">
          <Page breadcrumbs={breadcrumbs}>{getComponent()}</Page>
        </StyledPage>
      </Frame>
    </AppProvider>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PublicLayout;
