import { gql } from "apollo-boost";

const UPDATE_COMMISSION_TAX_SETTING = gql`
  mutation updateCommissionTaxSetting($input: UpdateCommissionTaxSetting) {
    updateCommissionTaxSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_COMMISSION_TAX_SETTING;
