import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, ChoiceList, FormLayout, Select, Tag, TextField } from "@shopify/polaris";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { Banner, ResourceList } from "lib/components";
import { QuickReport } from "app/reports";
import { withErrorBoundary } from "lib/hoc";

import { GET_SELLER_LIST, VERIFY_OTP } from "app/sellers/apollo/queries/";
import { BULK_SELLER_ACTION, ENABLE_DISABLE_API, PROXY_LOGIN } from "app/sellers/apollo/mutations";

import SELLER_LIST_SUBSCRIPTION from "app/sellers/apollo/subscription";

import MoreFilters from "app/payments/modules/generic/moreFilters/moreFilter";
import cmsSellerList from "./cms/sellerListCMS";

// subFeatures
import {
  DeleteOperator,
  GetOtpModal,
  LoginOperator,
  OperatorList,
  OperatorListFooter,
  UpdateOperator,
} from "./subFeatures";
import ApiAccessModal from "./subFeatures/apiAccessModal/apiAccessModal";

const {
  CRITICAL,
  providerList: { CREATED_DESC },
  providerList: { CREATED_AT },
  providerList: { DESC },
  SUCCESS,
} = constant;

const AdminSellerList = () => {
  const { cms, currentUser, history, isLoading } = useContext(PrivateContext);
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const sellerListCMS = cmsSellerList(cms);
  const {
    filterOptions,
    filterByStatusOption,
    label,
    limitOptions,
    message,
    promotedBulkActions,
    resourceName,
    sortOptions,
    // tabs,
  } = sellerListCMS;
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [totalSeller, setTotalSeller] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [selectedFilter, setSelectedFilter] = useState(prevFilter.list_filter || "");
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);
  const [sortValue, setSortValue] = useState(selectedSort || CREATED_DESC);
  // const [selected, setSelected] = useState(parseInt(prevFilter.tab, 10) || 0);
  const [singleSeller, setSingleSeller] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [updateSellerID, setUpdateSellerID] = useState("");
  const [updateSellerName, setUpdateSellerName] = useState("");
  const [reason, setReason] = useState("");
  const [access, setAccess] = useState(true);
  const [loginSellerResponse, setLoginSellerResponse] = useState();
  const [otpEntered, setOtpEntered] = useState();
  const [selectedButton, setSelectedButton] = useState("");
  const [selectedSellerId, setSelectedSellerId] = useState("");
  const [selectedSellerBrandName, setSelectedSellerBrandName] = useState("");
  const [isReasonField, setIsReasonField] = useState(false);
  const [isApiAccessLoading, setIsApiAccessLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [taggedWith, setTaggedWith] = useState(prevFilter.list_search || null);
  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    list_filter: prevFilter.list_filter,
    list_search: prevFilter.list_search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || CREATED_AT,
    sort_order: prevFilter.sort_order || DESC,
  });

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [activePopover, setActivePopover] = useState({});
  const [popoverDisable, setPopoverDisable] = useState(false);
  const inputData = {
    search: dataToFetch.search,
    list_filter: dataToFetch.list_filter,
    list_search: dataToFetch.list_search,
    filter: filterByStatus,
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
  };

  const handleBanner = (bannerData) => {
    setBanner(bannerData);
  };

  // useQuery Calls to fetch data.
  const {
    error: errorSellerList,
    loading: loadingSellerList,
    data: dataSellerList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_SELLER_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [otpVerify, { error: errorVerifyOtp, loading: loadingVerifyOtp, data: dataVerifyOtp }] = useLazyQuery(
    VERIFY_OTP
  );

  useEffect(() => {
    if (errorSellerList) {
      setBanner({ status: CRITICAL, title: errorHelper.parse(errorSellerList), isOpen: true });
      return;
    }
    if (!dataSellerList) {
      return;
    }
    const responseData = baseHelper.getResponseData(dataSellerList, constant.gql.GET_SELLER_LIST);
    const responseError = baseHelper.getResponseError(dataSellerList, constant.gql.GET_SELLER_LIST);
    if (responseError) {
      setBanner({ status: CRITICAL, title: responseError, isOpen: true });
    }
    if (responseData) {
      const { sellers: sellerListData = [], count: totalCount = 0 } = responseData;
      setSellerList(sellerListData);
      setTotalSeller(totalCount);
      setSelectedItems([]);
    }
  }, [dataSellerList, errorSellerList]);

  // useMutation calls
  const [deleteSellers, { data, loading, error }] = useMutation(BULK_SELLER_ACTION, {
    refetchQueries: [
      {
        query: GET_SELLER_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [loginSellers, { data: sellerDataLogin, loading: loadingSellerLogin, error: errorSellerLogin }] = useMutation(
    PROXY_LOGIN
  );

  // useEffect for deleteSeller Data Mutation.
  useEffect(() => {
    if (error) {
      setBanner({ title: errorHelper.parse(error), isOpen: true, status: CRITICAL });
    }
    const responseDeleteData = baseHelper.getResponseData(data, constant.gql.BULK_SELLER_ACTION);
    const responseDeleteError = baseHelper.getResponseError(data, constant.gql.BULK_SELLER_ACTION);
    if (responseDeleteData) {
      setDeleteModal(false);
      setBanner({ isOpen: true, status: SUCCESS, title: message.running_background });
    }
    if (responseDeleteError) {
      setDeleteModal(false);
      setBanner({ isOpen: true, status: CRITICAL, title: responseDeleteError });
    }
  }, [data, error, message.running_background]);

  useEffect(() => {
    if (errorSellerLogin || errorVerifyOtp) {
      setBanner({ title: errorHelper.parse(errorSellerLogin || errorVerifyOtp), isOpen: true, status: CRITICAL });
    }
  }, [errorSellerLogin, errorVerifyOtp]);

  // Subscription for sellerList Page.
  useEffect(() => {
    subscribeToMore({
      document: SELLER_LIST_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        refetch();
        return true;
      },
    });
  }, [refetch, subscribeToMore]);

  // useEffect for login As seller Mutation call.
  useEffect(() => {
    const responseLoginData = baseHelper.getResponseData(sellerDataLogin, constant.gql.PROXY_LOGIN);
    const responseLoginError = baseHelper.getResponseError(sellerDataLogin, constant.gql.PROXY_LOGIN);
    const isSeller = baseHelper.isSeller(currentUser);
    setLoginSellerResponse(responseLoginData);
    if (responseLoginData) {
      setLoginModal(false);
      setSelectedButton("");
      if (access) {
        storageHelper.set("token", responseLoginData.token);
        if (isSeller && baseHelper.isIframe()) {
          const serverUrl = window.origin;
          window.top.location.href = serverUrl;
        } else {
          history.push("/");
        }
        return;
      }
      setOpenOtpModal(true);
    }
    if (responseLoginError) {
      setLoginModal(false);
      setSelectedButton("");
      setBanner({ isOpen: true, status: CRITICAL, title: responseLoginError });
    }
  }, [access, currentUser, history, sellerDataLogin]);

  // useEffect for verifyOtp Query call.
  useEffect(() => {
    const isSeller = baseHelper.isSeller(currentUser);
    const verifyOtpResponse = baseHelper.getResponseData(dataVerifyOtp, constant.gql.VERIFY_OTP);
    if (verifyOtpResponse) {
      if (!verifyOtpResponse.token) {
        setOpenOtpModal(false);
        setBanner({
          title: message.token_expire,
          status: CRITICAL,
          isOpen: true,
        });
        return;
      }
      storageHelper.set("token", verifyOtpResponse.token);
      setOtpEntered("");
      if (isSeller && baseHelper.isIframe()) {
        const serverUrl = window.origin;
        window.top.location.href = serverUrl;
      } else {
        history.push("/");
      }
    }
  }, [currentUser, dataVerifyOtp, history, message.token_expire]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  // function for more filter.
  // const handleTaggedWithChange = (value) => {
  //   setTaggedWith(value);
  //   baseHelper.setUrl(history, { list_search: value });
  //   const filteredObj = baseHelper.queryParamsFromLocation(history);
  //   setDataToFetch({ ...dataToFetch, ...filteredObj });
  // };

  // function for remove values of more filter.
  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams = key === constant.FILTER_BY_STATUS ? { filter: "", page: 1 } : { list_search: "", page: 1 };
    if (clearFilter) {
      clearParams.filter = "";
      switch (key) {
        case constant.TAGGED_WITH:
          return setTaggedWith("");
        case constant.FILTER_BY_STATUS:
          return setFilterByStatus(null);
        default:
          return null;
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      setTaggedWith("");
      setSelectedFilter("");
    }
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  const handleSearchTextRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleClearAll = () => {
    handleTaggedWithRemove();
    handleSearchTextRemove();
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    setCurrentPage(1);
    baseHelper.setUrl(history, { perPage: parseInt(value, 10), page: 1 });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };

  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // const handleTabChange = (selectedTabIndex) => {
  //   const { value: tabName } = tabs[selectedTabIndex];
  //   setSelected(selectedTabIndex);
  //   setCurrentPage(1);
  //   setSelectedItems([]);
  //   baseHelper.setUrl(history, { tab: parseInt(selectedTabIndex, 10), filter: tabName, page: 1 });
  //   const filteredObj = baseHelper.queryParamsFromLocation(history);
  //   setDataToFetch({ ...dataToFetch, ...filteredObj });
  // };

  // const handleSelectChange = (value) => {
  //   setSelectedFilter(value);
  //   if (value) {
  //     baseHelper.setUrl(history, { list_filter: value });
  //     const filteredObj = baseHelper.queryParamsFromLocation(history);
  //     setDataToFetch({ ...dataToFetch, ...filteredObj });
  //   }
  // };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const deleteBulkSeller = async (isDelete = false, isSingle = false, id = "") => {
    setReason("");
    if (isDelete && !isSingle) {
      setSingleSeller([]);
      setIsReasonField(true);
      setDeleteModal(true);
    } else if (isSingle && id) {
      setSingleSeller([]);
      setIsReasonField(false);
      setDeleteModal(true);
    } else {
      const fromData = {
        variables: { input: { ids: selectedItems, key: constant.VERIFY } },
      };
      await deleteSellers(fromData);
    }
  };

  const updateSeller = (sellerId, brandName) => {
    setUpdateModal(true);
    setUpdateSellerID(sellerId);
    setUpdateSellerName(brandName);
  };

  // login seller function.
  const loginSeller = (id, brand, role) => {
    setOtpEntered("");
    setLoginModal(true);
    setSelectedBrand(`${brand}#${id}#${role}`);
  };

  const apiStatus = [constant.PENDING_SMALL, constant.DISABLED];
  const [enableDisableApi, { loading: enableDisableApiLoading }] = useMutation(ENABLE_DISABLE_API);

  const handleApiOption = async (value, sellerId) => {
    setIsApiAccessLoading({ [sellerId]: value });
    await enableDisableApi({
      variables: { input: { isEnableApi: value, sellerId } },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.ENABLE_DISABLE_API);
        setIsApiAccessLoading({ [sellerId]: false });
        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title: value ? cms("message.success.enableApi") : cms("message.success.disableApi"),
        });
        setIsModalActive(false);

        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.ENABLE_DISABLE_API);
          setIsApiAccessLoading({ [sellerId]: false });
          setBanner({ isOpen: true, status: constant.CRITICAL, title: errorResponse });
          return false;
        }
      })
      .catch((exception) => {
        setIsApiAccessLoading({ [sellerId]: false });
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
    setIsModalActive(false);
  };

  const handleDisableApiModal = (value, id, status) => {
    if (status === constant.ENABLED) {
      setIsModalActive(true);
    } else {
      handleApiOption(value, id);
      setIsModalActive(false);
    }
  };

  const renderItem = (item, _, index) => {
    const { api: apiAccessStatus = {}, _id, brandName: sellerBrandName } = item;
    const { status = "" } = apiAccessStatus || {};
    setSelectedSellerId(_id);
    setSelectedSellerBrandName(sellerBrandName);
    return (
      <OperatorList
        item={item}
        keyIndex={index}
        onLoginSeller={(sellerId, brandName, role) => loginSeller(sellerId, brandName, role)}
        onUpdateSeller={(sellerID, brandName) => updateSeller(sellerID, brandName)}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        sellerListCMS={sellerListCMS}
        popoverDisable={popoverDisable}
        setBanner={setBanner}
        isLoading={isLoading}
        setIsModalActive={setIsModalActive}
        handleDisableApiModal={() => handleDisableApiModal(!!apiStatus.includes(status), _id, status)}
        enableDisableApiLoading={enableDisableApiLoading}
        handleApiOption={() => handleDisableApiModal(!!apiStatus.includes(status), _id, status)}
        isApiAccessLoading={isApiAccessLoading}
      />
    );
  };

  const handleOTP = (otpText) => {
    if (parseInt(otpText, 10) && parseInt(otpText, 10) > -1) {
      return setOtpEntered(otpText);
    }
    return setOtpEntered("");
  };

  const verifyOTP = (token, otp, key) => {
    setSelectedButton(key);
    if (!token) {
      setBanner({
        title: message.token_expire,
        status: CRITICAL,
        isOpen: true,
      });
      return;
    }
    const otpData = {
      token,
      otp,
    };
    otpVerify({
      variables: { input: otpData },
    });
  };

  const bulkActions = [
    {
      content: label.delete_selected,
      destructive: true,
      onAction: () => deleteBulkSeller(true),
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.BRAND_FILTER:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`resourceList.filterOptions.${value === constant.BRAND_NAME ? constant.BRAND_CMS : value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [];

  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.BRAND_FILTER,
      label: disambiguateLabel(constant.BRAND_FILTER, taggedWith),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(true),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(false, constant.FILTER_BY_STATUS),
    });
  }

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const appliedFilters = appliedFilterArrObj;

  // const customFilter = (
  //   <FormLayout>
  //     <Select
  //       label={label.show_all_seller}
  //       value={selectedFilter || ""}
  //       onChange={handleSelectChange}
  //       placeholder={cms("resourceList.filterOptions.select")}
  //       options={filterOptions}
  //     />
  //     {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
  //       null}
  //   </FormLayout>
  // );

  const filters = [
    {
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  const handleDeleteSeller = async (val, action) => {
    const singleRecord = singleSeller;
    const sellerIds = (singleRecord && singleRecord.length > 0 && singleRecord) || selectedItems;
    setSelectedButton(action);
    const fromData = {
      variables: {
        input: { reason, deleteFromShopify: val, ids: sellerIds, key: constant.DELETE.toLowerCase() },
      },
    };
    await deleteSellers(fromData);
  };

  const handleLoginSeller = async (sellerId, sellerAccessType, roleName) => {
    const id = sellerId;
    const isReadOnlyAccess = sellerAccessType === constant.READ_ONLY_ACCESS;
    setAccess(isReadOnlyAccess);
    setSelectedButton(sellerAccessType);
    const logInData = { variables: { input: { id, userRole: roleName, onlyRead: isReadOnlyAccess } } };
    await loginSellers(logInData);
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <QuickReport />
      <Card>
        <ResourceList
          resourceName={resourceName}
          items={sellerList && sellerList.length > 0 ? sellerList : []}
          idForItem={(sellers) => {
            const { _id: id } = sellers;
            return id;
          }}
          renderItem={renderItem}
          loading={loadingSellerList || isLoading}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          bulkActions={bulkActions}
          onQueryChange={handleQueryValueChange}
          onQueryClear={handleQueryValueRemove}
          handleClearAll={handleClearAll}
          sortOptions={sortOptions}
          onSortChange={sortFilter}
          sortValue={sortValue}
          queryValue={queryValue}
          filters={filters}
          selectable
          setPopoverDisable={setPopoverDisable}
          appliedFilters={appliedFilters}
        />
        <OperatorListFooter
          optionsList={limitOptions}
          onSelectLimit={handleSelectLimit}
          perPageCount={selectedLimit}
          totalCount={totalSeller}
          onPerPage={handlePerPage}
          currentPage={currentPage}
          sellerListCMS={sellerListCMS}
        />
        <LoginOperator
          loginModalOpen={loginModal}
          closeLoginModal={() => {
            setSelectedBrand("");
            setLoginModal(false);
          }}
          logIn={handleLoginSeller}
          brandName={selectedBrand}
          buttonLoading={loadingSellerLogin}
          selectedButton={selectedButton}
          sellerListCMS={sellerListCMS}
        />
        <GetOtpModal
          openOtpModal={openOtpModal}
          closeOtpModal={() => {
            setSelectedBrand("");
            setOpenOtpModal(false);
          }}
          buttonLoading={loadingSellerLogin}
          verifyButtonLoading={loadingVerifyOtp}
          fullAccessRes={loginSellerResponse || []}
          logIn={handleLoginSeller}
          selectedButton={selectedButton}
          brandName={selectedBrand}
          verifyOTP={verifyOTP}
          otpEntered={otpEntered}
          handleOTP={handleOTP}
          verifyOtpError={dataVerifyOtp}
          sellerListCMS={sellerListCMS}
        />
        {updateModal && (
          <UpdateOperator
            updateModalOpen={updateModal}
            operatorUpdateID={updateSellerID}
            operatorUpdateName={updateSellerName}
            updateModalClose={() => {
              setSelectedBrand("");
              setUpdateModal(false);
            }}
            handleBanner={handleBanner}
            sellerListCMS={sellerListCMS}
          />
        )}
        <DeleteOperator
          openModal={deleteModal}
          handleModalClose={() => setDeleteModal(false)}
          isReasonField={isReasonField}
          handleReasonChange={(reasonValue) => {
            setReason(reasonValue);
          }}
          reason={reason}
          deleteSellerAction={handleDeleteSeller}
          buttonLoading={loading}
          selectedButton={selectedButton}
          sellerListCMS={sellerListCMS}
        />
        <ApiAccessModal
          isModalActive={isModalActive}
          handleChange={() => setIsModalActive(false)}
          handleApiOption={handleApiOption}
          apiStatus={apiStatus}
          selectedSellerId={selectedSellerId}
          enableDisableApiLoading={enableDisableApiLoading}
          selectedSellerBrandName={selectedSellerBrandName}
        />
      </Card>
    </>
  );
};

export default withErrorBoundary(AdminSellerList);
