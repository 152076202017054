import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

import VendorSubscription from "app/advanceVendor/modules/operator/feature/vendorSubscription/vendorSubscription";

const { userKey } = constant;
const { operator } = userKey;

const ManageVendorSubscription = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <VendorSubscription />;
    }
    return null;
  };

  const AdvanceProviderComponent = getComponent(currentUserRole);
  return AdvanceProviderComponent;
};

export default withErrorBoundary(ManageVendorSubscription);
