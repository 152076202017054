// import packages
import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Layout, Modal, SettingToggle, Stack, TextContainer, TextStyle } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import components
import { Banner } from "lib/components";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import constants
import constant from "lib/constant/constant";

// import gql
import { UPDATE_PRODUCT_SYNC } from "app/productOld/apollo/mutations";
import { UPDATE_WIX_PRODUCT_SYNC } from "app/product/apollo/mutations";
import { UPDATE_BIGCOMMERCE_PRODUCT_SYNC } from "app/productLake/apollo/mutations";

const ProviderProductSetting = () => {
  const [updateProductSyncMutation] = useMutation(UPDATE_PRODUCT_SYNC);
  const [updateWixProductSyncMutation] = useMutation(UPDATE_WIX_PRODUCT_SYNC);
  const [updateBigCommerceProductSyncMutation] = useMutation(UPDATE_BIGCOMMERCE_PRODUCT_SYNC);
  const { currentUser, history, cms } = useContext(PrivateContext);
  const { wooCommerce: wooCommerceConfig = {}, wix: wixConfig = {}, bigCommerce: bigCommerceConfig = {} } =
    (currentUser && currentUser.storeConfig) || {};
  const { ecommercePlatform } = currentUser || {};
  const { shop: woocommerceVendorShop = "" } = wooCommerceConfig || {};
  const isShopConnected = currentUser.shop || woocommerceVendorShop;
  const { instanceId: wixInstanceId = "", accessKey: wixAccessKey = "", isNewProduct = false } = wixConfig || {};
  const {
    accessToken: bigCommerceAccessToken = "",
    clientId: bigCommerceClientId = "",
    clientSecret: bigCommerceClientSecret = "",
    shopDomain: bigCommerceShopDomain = "",
    isNewProduct: isNewBigcommerceProduct = false,
  } = bigCommerceConfig || {};

  const isWixShopConnected = !!(wixInstanceId && wixAccessKey);

  const isBigCommerceShopConnected = !!(
    bigCommerceAccessToken ||
    bigCommerceClientId ||
    bigCommerceClientSecret ||
    bigCommerceShopDomain
  );

  // const isBigCommerceShopConnected = false;//[TODO]
  const [banner, setBanner] = useState({});
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);
  const [isProductSyncEnabled, setIsProductSyncEnabled] = useState(currentUser.isProductSync);
  const [isWixConfirmationModalVisible, setIsWixConfirmationModalVisible] = useState(false);
  const [isWixConfirmButtonLoading, setIsWixConfirmButtonLoading] = useState(false);
  const [isWixProductSyncEnabled, setIsWixProductSyncEnabled] = useState(isNewProduct);
  const [isBigCommerceConfirmButtonLoading, setIsBigCommerceConfirmButtonLoading] = useState(false);
  const [isBigCommerceConfirmationModalVisible, setIsBigCommerceConfirmationModalVisible] = useState(false);
  const [isBigCommerceProductSyncEnabled, setIsBigCommerceProductSyncEnabled] = useState(isNewBigcommerceProduct);

  const toggleProductSync = async () => {
    try {
      setIsConfirmButtonLoading(true);
      const isProductSync = !isProductSyncEnabled;
      const res = await updateProductSyncMutation({
        variables: {
          input: { isProductSync },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_SYNC);
      const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_SYNC);
      if (!responseData) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        setIsConfirmationModalVisible(!isConfirmationModalVisible);
        setIsConfirmButtonLoading(false);
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("provider.message.success").replace("wix", ""),
      });
      setIsConfirmationModalVisible(!isConfirmationModalVisible);
      setIsConfirmButtonLoading(false);
      setIsProductSyncEnabled(!isProductSyncEnabled);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const toggleConfirmationModal = () => {
    setIsConfirmationModalVisible(!isConfirmationModalVisible);
  };
  const renderConfirmationModal = () => {
    return (
      <Modal
        key={constant.VENDOR_PRODUCT_SETTING_MODAL}
        open={isConfirmationModalVisible}
        onClose={toggleConfirmationModal}
        title={cms("provider.modal.title")}
        primaryAction={{
          content: cms("provider.modal.button.primary"),
          onAction: toggleProductSync,
          loading: isConfirmButtonLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: toggleConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("provider.modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  const toggleWixProductSync = async () => {
    try {
      setIsWixConfirmButtonLoading(true);
      const isProductSync = !isWixProductSyncEnabled;
      const res = await updateWixProductSyncMutation({
        variables: {
          input: { isProductSync },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_WIX_PRODUCT_SYNC);
      const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_WIX_PRODUCT_SYNC);
      if (!responseData) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        setIsWixConfirmationModalVisible(!isWixConfirmationModalVisible);
        setIsWixConfirmButtonLoading(false);
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("provider.message.success")}`,
      });
      setIsWixConfirmationModalVisible(!isWixConfirmationModalVisible);
      setIsWixConfirmButtonLoading(false);
      setIsWixProductSyncEnabled(!isWixProductSyncEnabled);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const toggleWixConfirmationModal = () => {
    setIsWixConfirmationModalVisible(!isWixConfirmationModalVisible);
  };
  const renderWixConfirmationModal = () => {
    return (
      <Modal
        key={constant.VENDOR_PRODUCT_SETTING_MODAL}
        open={isWixConfirmationModalVisible}
        onClose={toggleWixConfirmationModal}
        title={cms("provider.modal.title")}
        primaryAction={{
          content: cms("provider.modal.button.primary"),
          onAction: toggleWixProductSync,
          loading: isWixConfirmButtonLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: toggleWixConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("provider.wix.modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  const toggleBigCommerceProductSync = async () => {
    try {
      setIsBigCommerceConfirmButtonLoading(true);
      const isProductSync = !isBigCommerceProductSyncEnabled;
      const res = await updateBigCommerceProductSyncMutation({
        variables: {
          input: { isProductSync },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_BIGCOMMERCE_PRODUCT_SYNC);
      const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_BIGCOMMERCE_PRODUCT_SYNC);
      if (!responseData) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
        setIsBigCommerceConfirmationModalVisible(!isBigCommerceConfirmationModalVisible);
        setIsBigCommerceConfirmButtonLoading(false);
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("provider.message.success")}`,
      });
      setIsBigCommerceConfirmationModalVisible(!isBigCommerceConfirmationModalVisible);
      setIsBigCommerceConfirmButtonLoading(false);
      setIsBigCommerceProductSyncEnabled(!isBigCommerceProductSyncEnabled);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const toggleBigCommerceConfirmationModal = () => {
    setIsBigCommerceConfirmationModalVisible(!isBigCommerceConfirmationModalVisible);
  };
  const renderBigCommerceConfirmationModal = () => {
    return (
      <Modal
        key={constant.VENDOR_PRODUCT_SETTING_MODAL}
        open={isBigCommerceConfirmationModalVisible}
        onClose={toggleBigCommerceConfirmationModal}
        title={cms("provider.modal.title")}
        primaryAction={{
          content: cms("provider.modal.button.primary"),
          onAction: toggleBigCommerceProductSync,
          loading: isBigCommerceConfirmButtonLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: toggleBigCommerceConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("provider.bigcommerce.modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  return (
    <div>
      {renderConfirmationModal()}
      {renderWixConfirmationModal()}
      {renderBigCommerceConfirmationModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {ecommercePlatform !== constant.BIGCOMMERCE ? (
        <>
          <Layout.AnnotatedSection title={cms("provider.title")} description={cms("provider.description")}>
            {!isShopConnected && (
              <Card sectioned>
                <Stack>
                  <Stack.Item fill>{cms("provider.content.connect")}</Stack.Item>
                  <Stack.Item>
                    <Button onClick={() => history.push(constant.CONNECT_SHOP_PAGE)}>
                      {cms("provider.button.connect")}
                    </Button>
                  </Stack.Item>
                </Stack>
              </Card>
            )}
            {isShopConnected && (
              <SettingToggle
                action={{
                  content: isProductSyncEnabled ? cms("provider.button.disable") : cms("provider.button.enable"),
                  onAction: () => toggleConfirmationModal(),
                  destructive: isProductSyncEnabled,
                }}
                enabled={isProductSyncEnabled}
              >
                {`${cms("provider.content.autoProduct")}`}
                <TextStyle variation="strong">
                  {isProductSyncEnabled ? cms("provider.label.enabled") : cms("provider.label.disabled")}
                </TextStyle>
                <br />
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(currentUser.updatedAt)}`}</TextStyle>
              </SettingToggle>
            )}
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title={cms("provider.wix.title")} description={cms("provider.wix.description")}>
            {!isWixShopConnected && (
              <Card sectioned>
                <Stack>
                  <Stack.Item fill>{cms("provider.wix.content.connect")}</Stack.Item>
                  <Stack.Item>
                    <Button onClick={() => history.push(`${constant.CONNECT_SHOP_PAGE}?store=wix`)}>
                      {cms("provider.button.connect")}
                    </Button>
                  </Stack.Item>
                </Stack>
              </Card>
            )}
            {isWixShopConnected && (
              <SettingToggle
                action={{
                  content: isWixProductSyncEnabled ? cms("provider.button.disable") : cms("provider.button.enable"),
                  onAction: () => toggleWixConfirmationModal(),
                  destructive: isWixProductSyncEnabled,
                }}
                enabled={isWixProductSyncEnabled}
              >
                {`${cms("provider.wix.content.autoProduct")}`}
                <TextStyle variation="strong">
                  {isWixProductSyncEnabled ? cms("provider.label.enabled") : cms("provider.label.disabled")}
                </TextStyle>
                <br />
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(currentUser.updatedAt)}`}</TextStyle>
              </SettingToggle>
            )}
          </Layout.AnnotatedSection>
        </>
      ) : (
        <Layout.AnnotatedSection
          title={cms("provider.bigcommerce.title")}
          description={cms("provider.bigcommerce.description")}
        >
          {!isBigCommerceShopConnected && (
            <Card sectioned>
              <Stack>
                <Stack.Item fill>{cms("provider.bigcommerce.content.connect")}</Stack.Item>
                <Stack.Item>
                  <Button onClick={() => history.push(`${constant.CONNECT_SHOP_PAGE}?store=bigCommerce`)}>
                    {cms("provider.button.connect")}
                  </Button>
                </Stack.Item>
              </Stack>
            </Card>
          )}
          {isBigCommerceShopConnected && (
            <SettingToggle
              action={{
                content: isBigCommerceProductSyncEnabled
                  ? cms("provider.button.disable")
                  : cms("provider.button.enable"),
                onAction: () => toggleBigCommerceConfirmationModal(),
                destructive: isBigCommerceProductSyncEnabled,
              }}
              enabled={isBigCommerceProductSyncEnabled}
            >
              {`${cms("provider.bigcommerce.content.autoProduct")}`}
              <TextStyle variation="strong">
                {isBigCommerceProductSyncEnabled ? cms("provider.label.enabled") : cms("provider.label.disabled")}
              </TextStyle>
              <br />
              <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(currentUser.updatedAt)}`}</TextStyle>
            </SettingToggle>
          )}
        </Layout.AnnotatedSection>
      )}
    </div>
  );
};
export default withFeature(withErrorBoundary(ProviderProductSetting), { feature: constant.PRODUCT_SETTING });
