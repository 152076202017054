import React from "react";
import OrderExportSetting from "app/orders/modules/generic/exportSetting/order";

import { withErrorBoundary } from "lib/hoc";

const AdminOrderExportSetting = () => {
  return <OrderExportSetting />;
};

export default withErrorBoundary(AdminOrderExportSetting);
