import React from "react";
import { Layout, Card, SkeletonDisplayText, SkeletonBodyText, TextContainer } from "@shopify/polaris";

const Skeleton = () => {
  // const layouts = [...Array(3).keys()];
  // return layouts.map((_, index) => {
  //   return (
  //     // eslint-disable-next-line react/no-array-index-key
      
  //   );
  // });

  return (
    <Layout.Section key={`SkeletonLayoutSection${0}`}>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
    </Layout.Section>
  );
};

export default Skeleton;
