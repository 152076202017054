import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, ChoiceList, Modal, Stack, TextStyle } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner } from "lib/components";

// import yup
import { schema } from "app/paymentsLake/modules/operator/features/list/yup/schema";
import validate from "app/paymentsLake/modules/operator/features/list/yup";

// import propTypes
import { viewProp } from "app/paymentsLake/modules/operator/features/list/propTypes";

// import gql
import PAY_LAKE_VENDOR from "app/paymentsLake/apollo/mutaions";

const View = (props) => {
  const {
    active,
    cms,
    commissionVendor,
    handleClose,
    moneyFormat,
    orderLineItemId,
    setBanner,
    shippingAmount,
    vendor,
  } = props;

  const [balance] = useState(0);
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState(false);
  const [isExcludeShippingAmount] = useState(false);
  const [isCreditNote] = useState(false);
  const [isVendorCurrency, setIsVendorCurrency] = useState(false);
  const [selected, setSelected] = useState([]);
  const [vendorCurrency, setVendorCurrency] = useState({});
  const [modalBanner, setModalBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const [payVendor, { loading }] = useMutation(PAY_LAKE_VENDOR);

  const handleCheckbox = useCallback((newChecked) => setChecked(newChecked), []);

  const amount =
    (isExcludeShippingAmount && (shippingAmount === 0 || !shippingAmount) && parseFloat(commissionVendor)) ||
    parseFloat(commissionVendor) + (shippingAmount ? parseFloat(shippingAmount) : 0);

  const priceValue = (isVendorCurrency && `(${baseHelper.getCurrencyPrice(amount, vendorCurrency)})`) || "";

  const displayPrice = (priceValue && `: ${moneyFormat}${baseHelper.getPrice(amount)} ${priceValue}`) || "";

  const modalData = [
    // {
    //   label: cms("label.payStripe"),
    //   value: constant.STRIPE,
    //   name: "Radio Group",
    //   helpText: cms("helpText.stripe"),
    //   disabled: true,
    // },
    // {
    //   label: cms("label.payPaypal"),
    //   value: constant.PAYPAL,
    //   name: "Radio Group",
    //   helpText: cms("helpText.paypal"),
    //   disabled: true,
    // },
    {
      label: `${cms("label.manual")}${
        (selected && selected.includes(constant.MANUAL) && isVendorCurrency && vendorCurrency.price && displayPrice) ||
        ""
      }`,
      value: constant.MANUAL,
      name: "Radio Group",
      helpText: cms("helpText.manual"),
    },
  ];

  if (selected) {
    schema(selected);
  }

  useEffect(() => {
    if (selected && selected.includes(constant.MANUAL) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.rate")} ${moneyFormat}1 = ${vendorCurrency.currencySymbol}${vendorCurrency.price}`,
        isOpen: true,
        status: constant.INFO,
      });
    } else if (selected && selected.includes(constant.STRIPE) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.stripeRate")}`,
        isOpen: true,
        status: constant.INFO,
      });
    } else if (selected && selected.includes(constant.PAYPAL) && Object.keys(vendorCurrency).length) {
      setModalBanner({
        title: `${cms("label.paypalRate")}`,
        isOpen: true,
        status: constant.INFO,
      });
    }
  }, [cms, moneyFormat, selected, vendorCurrency]);

  const submit = async () => {
    const validateError = await validate(selected, cms("message.error"));
    if (selected.length === 0) {
      setError(validateError);
      return;
    }
    try {
      const userData = { paymentMethod: selected[0], orderLineItemId };
      if (isCreditNote) {
        userData.isUseCreditBalance = checked;
      }
      const response = await payVendor({ variables: { input: userData } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.PAY_LAKE_VENDOR);
      const bannerValue = {
        isOpen: true,
        title: `${moneyFormat} ${baseHelper.getPrice(amount)} ${cms("label.pay").toLowerCase()}
        ${cms("label.to")} ${vendor} ${cms("label.success")}`,
        status: constant.SUCCESS,
      };
      if (responseError) {
        bannerValue.title = responseError;
        bannerValue.status = constant.CRITICAL;
      }
      setBanner(bannerValue);
      setError(false);
    } catch (exception) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(exception),
        status: constant.CRITICAL,
      });
    }
    setSelected("");
    setIsVendorCurrency(false);
    setVendorCurrency({});
    handleClose();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: constant.SMOOTH,
    });
  };
  const handleChange = (e) => {
    setSelected(e);
    setError(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${cms("label.pay")} ${vendor} ${moneyFormat}${baseHelper.getPrice(amount)}`}
      primaryAction={{
        content: cms("label.payVendor"),
        onAction: () => {
          submit();
        },
        loading,
      }}
      // loading={loadingGetCreditNote}
    >
      <Modal.Section>
        {selected &&
          (selected.includes(constant.MANUAL) ||
            selected.includes(constant.PAYPAL) ||
            selected.includes(constant.STRIPE)) &&
          isVendorCurrency &&
          vendorCurrency.price > 0 && (
            <>
              <Banner status={modalBanner.status} isOpen={modalBanner.isOpen} isScrollTop={false}>
                <TextStyle>{modalBanner.title}</TextStyle>
              </Banner>
              <br />
            </>
          )}
        {isCreditNote && (
          <>
            <Stack>
              <Checkbox
                label={cms("label.adjust", {
                  item: `${moneyFormat}${
                    balance > amount ? baseHelper.getPrice(amount) : baseHelper.getPrice(balance)
                  }`,
                })}
                checked={checked}
                onChange={handleCheckbox}
              />
            </Stack>
            <br />
          </>
        )}
        <Stack vertical>
          <Stack.Item>
            <ChoiceList choices={modalData} selected={selected} onChange={handleChange} error={error} />
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

View.propTypes = viewProp.type;

export default View;
