import { gql } from "apollo-boost";

const GET_BIG_COMMERCE_TEMP_PRODUCT_LIST = gql`
  query getTempBigCommerceProductList($input: GetListInput) {
    getTempBigCommerceProductList(input: $input) {
      data {
        count
        tempProductList {
          _id
          name
          image
          isBigCommerceSync
          isSynced
          providerIds
          storeProductId
          status
          updatedAt
          createdAt
        }
      }
      error
      status
    }
  }
`;

export default GET_BIG_COMMERCE_TEMP_PRODUCT_LIST;
