import PropsTypes from "prop-types";

const providerExportOrderLakeProps = {
  type: {
    isOpenModal: PropsTypes.bool.isRequired,
    setIsOpenModal: PropsTypes.func.isRequired,
  },
};

export default providerExportOrderLakeProps;
