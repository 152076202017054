import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, handleBrandRegex, postalCodeRegex, provinceCodeRegex, phoneNumberRegex } = yupHelper;

const firstNameSchema = (label, cms) =>
  yup.object().shape({
    firstName: yup.string().required(cms("common.message.error.required", { item: label })),
  });

const lastNameSchema = (label, cms) =>
  yup.object().shape({
    lastName: yup.string().required(cms("common.message.error.required", { item: label })),
  });

const emailSchema = (label, cms) =>
  yup.object({
    email: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(emailRegex, cms("common.message.error.enterValid", { item: label.toLowerCase() })),
  });

const phoneNumberSchema = (label, cms) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .min(7, cms("common.message.error.minPhoneNumberChar", { item: label }))
      .max(15, cms("common.message.error.maxPhoneNumberChar", { item: label })),
  });

const provinceCodeSchema = (cms) =>
  yup.object().shape({
    provinceCode: yup
      .string()
      .required(cms("common.message.error.provinceCode"))
      .matches(provinceCodeRegex, {
        message: cms("common.message.error.enterValidProvinceCode"),
      }),
  });

const citySchema = (label, cms) =>
  yup.object().shape({
    city: yup.string().required(cms("common.message.error.required", { item: label })),
  });

const mailSubjectSchema = (cms) =>
  yup.object().shape({
    mailSubject: yup.string().required(cms("common.message.error.subject")),
  });

const countrySchema = (cms) =>
  yup.object().shape({
    country: yup.string().required(cms("common.message.error.countryIsRequired")),
  });

const postalCodeSchema = (label, cms, schema) => {
  const { min = 2 } = schema || {};
  return yup.object().shape({
    postalCode: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(postalCodeRegex, cms("common.message.error.validPostalCode", { item: label }))
      .min(min, cms("common.message.error.minPostalCodeChar", { min, item: label }))
      .max(32, cms("common.message.error.maxPostalCodeChar", { item: label }))
      .nullable(),
  });
};

const streetAddressSchema = (label, cms) =>
  yup.object().shape({
    streetAddress: yup.string().required(cms("common.message.error.required", { item: label })),
  });

const brandNameSchema = (label, cms) =>
  yup.object().shape({
    brandName: yup.string().required(cms("common.message.error.required", { item: label })),
  });

const brandHandleSchema = (label, cms) => {
  return yup.object({
    brandHandle: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(
        handleBrandRegex,
        cms("common.message.error.brandHandle.brandHandleMatch", { item: label.toLowerCase() })
      ),
  });
};

export {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  mailSubjectSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
};
