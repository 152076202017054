import React, { Fragment, useContext } from "react";
import { Card, Checkbox, Layout, RadioButton, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

// constants
import constants from "lib/constant/constant";
import AutoComplete from "lib/components/autocomplete/autocomplete";

function ManageShippingCharge(props) {
  const { manageShippingChargeProps } = props;
  const { cms } = useContext(PrivateContext);
  const {
    vendors,
    selectedVendors,
    onChange,
    checked,
    isAllVendorsAllowed,
    onChangeRadioOne,
    onChangeRadioTwo,
    onChangeAutoComplete,
  } = manageShippingChargeProps;

  const shippingChargeFieldData = [
    {
      field: constants.RADIO_BUTTON,
      key: constants.ALL_CUMULATIVE,
      label: cms("operator.section.allowCumulativeCharge.content.allow.label"),
      helpText: cms("operator.section.allowCumulativeCharge.content.allow.caption"),
    },
    {
      field: constants.RADIO_BUTTON_WITH_AUTO_COMPLETE,
      key: constants.SELECT_CUMULATIVE,
      label: cms("operator.section.allowCumulativeCharge.content.selected.label"),
      helpText: cms("operator.section.allowCumulativeCharge.content.selected.caption"),
      autocomplete: {
        field: constants.AUTOCOMPLETE,
        key: constants.AUTOCOMPLETE,
        label: "Vendors",
        helpText: cms("operator.section.allowCumulativeCharge.content.selected.placeholder.brandNameAutocomplete"),
      },
    },
  ];

  return (
    <Layout.AnnotatedSection description={cms("operator.section.allowCumulativeCharge.description")}>
      <Card sectioned>
        <Checkbox checked={checked} onChange={onChange} label={cms("operator.section.allowCumulativeCharge.label")} />
        <Stack vertical>
          {shippingChargeFieldData.map((item) => {
            const { key, field, label: itemLabel, helpText } = item;
            return (
              <Fragment key={key}>
                {field === constants.RADIO_BUTTON && checked && (
                  <RadioButton
                    id={key}
                    key={key}
                    label={itemLabel}
                    helpText={helpText}
                    checked={isAllVendorsAllowed}
                    onChange={onChangeRadioTwo}
                  />
                )}
                {field === constants.RADIO_BUTTON_WITH_AUTO_COMPLETE && checked && (
                  <>
                    <RadioButton
                      id={key}
                      key={key}
                      label={itemLabel}
                      helpText={helpText}
                      checked={!isAllVendorsAllowed}
                      onChange={onChangeRadioOne}
                    />
                    {!isAllVendorsAllowed && (
                      <Layout.Section>
                        <AutoComplete
                          label={item.autocomplete.label}
                          labelHidden
                          placeholder={item.autocomplete.helpText}
                          values={vendors}
                          selected={selectedVendors}
                          onChange={onChangeAutoComplete}
                          minimumSearchLength={constants.value.MIN_SEARCH_LENGTH}
                        />
                      </Layout.Section>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}

ManageShippingCharge.propTypes = {
  manageShippingChargeProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.array]))
    .isRequired,
};

export default ManageShippingCharge;
