import config from "configuration";
import { baseHelper } from "lib/helpers";

const { isProd = false } = config;

const standardList = (cms, currentUser) => {
  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);
  const isRotaroUser = baseHelper.isRotaroUser(currentUser);
  const list = [
    {
      code: "marketplace",
      title: cms("operator.title"),
      description: cms("operator.description"),
      thumbnail: cms("operator.thumbnail"),
      isVideo: false,
      list: [
        {
          title: cms("operator.section.product.title"),
          description: cms("operator.section.product.description"),
          icon: "fal fa-tag fa-lg",
          code: "product",
          thumbnailHeader: cms("operator.section.product.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.product.thumbnail"),
          thumbnailDescription: cms("operator.section.product.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.product.action.setting.label"),
              link: "/setting/product",
              code: "productSettings",
              description: cms("operator.section.product.action.setting.description"),
            },
            {
              label: cms("operator.section.product.action.approval.label"),
              link: "/setting/product/approval",
              code: "productApprovalSetting",
              description: cms("operator.section.product.action.approval.description"),
            },
            {
              label: cms("operator.section.product.action.attachment.label"),
              link: "/setting/attachment",
              code: "attachment",
              description: cms("operator.section.product.action.attachment.description"),
            },
            {
              label: cms("operator.section.product.action.markup.label"),
              link: "/setting/markup",
              code: "markupSettings",
              description: cms("operator.section.product.action.markup.description"),
            },
            {
              label: cms("operator.section.product.action.discount.label"),
              link: "/setting/discount",
              code: "discountSetting",
              description: cms("operator.section.product.action.discount.description"),
            },
            {
              code: "productFormSetting",
              description: cms("operator.section.product.action.productFormLabel.description"),
              label: cms("operator.section.product.action.productFormLabel.label"),
              link: "/setting/product/label",
            },
            {
              code: "productPriceSetting",
              description: cms("operator.section.product.action.price.description"),
              label: cms("operator.section.product.action.price.label"),
              link: "/setting/product/price",
            },
            {
              code: "productTagSetting",
              description: cms("operator.section.product.action.productTagSetting.description"),
              label: cms("operator.section.product.action.productTagSetting.label"),
              link: "/setting/product/tag",
            },
            {
              code: "metafield",
              description: cms("operator.section.product.action.metafield.description"),
              label: cms("operator.section.product.action.metafield.label"),
              link: "/setting/product/metafield",
            },
            {
              code: "productExportSetting",
              description: cms("operator.section.product.action.productExport.description"),
              label: cms("operator.section.product.action.productExport.label"),
              link: "/setting/product/export",
            },
            {
              code: "productExportSetting",
              description: "Manage product listing time and minimum inventory threshold.",
              label: "Manage Product Listing",
              link: "/setting/product/listing",
            },
            {
              code: "manageGeolocation",
              description: cms("operator.section.product.action.manageGeolocation.description"),
              label: cms("operator.section.product.action.manageGeolocation.label"),
              link: "/setting/product/geolocation",
            },
          ],
        },
        {
          title: cms("operator.section.order.title"),
          description: cms("operator.section.order.description"),
          icon: "fal fa-shopping-cart fa-lg",
          code: "order",
          thumbnailHeader: cms("operator.section.order.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.order.thumbnail"),
          thumbnailDescription: cms("operator.section.order.thumbnailDescription"),
          actions: [
            isRotaroUser && {
              label: cms("operator.section.order.action.acs.label"),
              link: "/setting/order/acs",
              code: "setting",
              description: cms("operator.section.order.action.acs.description"),
            },
            {
              label: cms("operator.section.order.action.commission.label"),
              link: "/commission",
              code: "commission",
              description: cms("operator.section.order.action.commission.description"),
            },
            {
              label: cms("operator.section.order.action.autoAccept.label"),
              link: "/setting/order",
              code: "orderSettings",
              description: cms("operator.section.order.action.autoAccept.description"),
            },
            {
              label: cms("operator.section.order.action.autoFulfill.label"),
              link: "/setting/order",
              code: "orderSettings",
              description: cms("operator.section.order.action.autoFulfill.description"),
            },
            {
              label: cms("operator.section.order.action.reviewRejected.label"),
              link: "/setting/order",
              code: "orderSettings",
              description: cms("operator.section.order.action.reviewRejected.description"),
            },
            {
              code: "dynamicDiscount",
              description: cms("operator.section.order.action.dynamicDiscount.description"),
              label: cms("operator.section.order.action.dynamicDiscount.label"),
              link: "/setting/order/discount",
            },
            {
              label: cms("operator.section.order.action.manageCustomer.label"),
              link: "/setting/customer",
              code: "orderSettings",
              description: cms("operator.section.order.action.manageCustomer.description"),
            },
            {
              label: "Manage Order Auto Expiry",
              link: "/setting/order-expiry",
              code: "orderSettings",
              description: "Manage order expiry manually or automatically.",
            },
            {
              code: "orderExportSettings",
              description: cms("operator.section.order.action.orderExport.description"),
              label: cms("operator.section.order.action.orderExport.label"),
              link: "/setting/order/export",
            },
            {
              code: "packingSlipSetting",
              description: cms("operator.section.order.action.managePackingSlip.description"),
              label: cms("operator.section.order.action.managePackingSlip.label"),
              link: "/setting/order/packing-slip",
            },
            {
              code: "orderLiveConnect",
              description: cms("operator.section.order.action.orderLiveConnect.description"),
              label: cms("operator.section.order.action.orderLiveConnect.label"),
              link: "/setting/order/live-connect",
            },
          ],
        },
        {
          title: cms("operator.section.branding.title"),
          description: cms("operator.section.branding.description"),
          icon: "fal fa-copyright fa-lg",
          code: "branding",
          thumbnailHeader: cms("operator.section.branding.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.branding.thumbnail"),
          thumbnailDescription: cms("operator.section.branding.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.branding.action.branding.label"),
              link: "/branding",
              code: "branding",
              description: cms("operator.section.branding.action.branding.description"),
            },
            !isBigCommerceUser && {
              label: cms("operator.section.customizeFont.action.customize.label"),
              link: "/customize/fonts",
              code: "customize",
              description: cms("operator.section.customizeFont.action.customize.description"),
            },
          ],
        },
        {
          title: cms("operator.section.payment.title"),
          description: cms("operator.section.payment.description"),
          icon: "fal fa-sack-dollar fa-lg",
          code: "payments",
          thumbnailHeader: cms("operator.section.payment.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.payment.thumbnail"),
          thumbnailDescription: cms("operator.section.payment.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.payment.action.terms.label"),
              link: "/terms",
              code: "terms",
              description: cms("operator.section.payment.action.terms.description"),
            },
            {
              label: cms("operator.section.payment.action.stripe.label"),
              link: "/setting/payment",
              code: "paymentService",
              description: cms("operator.section.payment.action.stripe.description"),
            },
            {
              label: cms("operator.section.payment.action.paypal.label"),
              link: "/setting/payment",
              code: "paymentService",
              description: cms("operator.section.payment.action.paypal.description"),
            },
            {
              code: "currencyRateSetting",
              description: cms("operator.section.payment.action.rate.description"),
              label: cms("operator.section.payment.action.rate.label"),
              link: "/setting/currency/rate",
            },
            {
              code: "currencyPayoutSetting",
              description: cms("operator.section.payment.action.payout.description"),
              label: cms("operator.section.payment.action.payout.label"),
              link: "/setting/currency/payout",
            },
            {
              code: "paymentExportSetting",
              description: cms("operator.section.payment.action.paymentExport.description"),
              label: cms("operator.section.payment.action.paymentExport.label"),
              link: "/setting/payment/export",
            },
            {
              code: "expressPayment",
              description: cms("operator.section.payment.action.expressPayment.description"),
              label: cms("operator.section.payment.action.expressPayment.label"),
              link: "/setting/payment/express-payment",
            },
            {
              code: "schedulePayment",
              description: cms("operator.section.payment.action.schedulePayment.description"),
              label: cms("operator.section.payment.action.schedulePayment.label"),
              link: "/setting/schedule-payment",
            },
            {
              code: "invoiceSetting",
              description: cms("operator.section.payment.action.invoiceAccess.description"),
              label: cms("operator.section.payment.action.invoiceAccess.label"),
              link: "/setting/payment/invoice",
            },
            {
              code: "transactionFeesSetting",
              description: cms("operator.section.payment.action.transactionFees.description"),
              label: cms("operator.section.payment.action.transactionFees.label"),
              link: "/setting/payment/transaction-fees",
            },
          ],
        },
        {
          title: cms("operator.section.tax.title"),
          description: cms("operator.section.tax.description"),
          icon: "fal fa-sack-dollar fa-lg",
          thumbnailHeader: cms("operator.section.tax.thumbnailHeader"),
          isVideo: false,
          code: "tax",
          thumbnail: cms("operator.section.tax.thumbnail"),
          thumbnailDescription: cms("operator.section.tax.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.tax.action.label"),
              link: "/setting/tax",
              code: "tax",
              description: cms("operator.section.tax.action.description"),
            },
          ],
        },
        {
          title: cms("operator.section.vendor.title"),
          description: cms("operator.section.vendor.description"),
          icon: "fal fa-user-friends fa-lg",
          thumbnailHeader: cms("operator.section.vendor.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.vendor.thumbnail"),
          code: "provider",
          thumbnailDescription: cms("operator.section.vendor.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.vendor.action.vendor.label"),
              link: "/provider/manage",
              code: "vendor",
              description: cms("operator.section.vendor.action.vendor.description"),
            },
            {
              label: cms("operator.section.vendor.action.vendorExport.label"),
              link: "/setting/provider/export",
              code: "manageExport",
              description: cms("operator.section.vendor.action.vendorExport.description"),
            },
            {
              label: cms("operator.section.vendor.action.access.label"),
              link: "/setting/provider/access",
              code: "vendorAccess",
              description: cms("operator.section.vendor.action.access.description"),
            },
            {
              label: cms("operator.section.vendor.action.advanceAccess.label"),
              link: "/setting/provider/advance",
              code: "vendorAdvance",
              description: cms("operator.section.vendor.action.advanceAccess.description"),
            },
            {
              label: cms("operator.section.vendor.action.editVendorFormLabel.label"),
              link: "/setting/provider/label",
              code: "editVendorFormLabel",
              description: cms("operator.section.vendor.action.editVendorFormLabel.description"),
            },
            {
              code: "vendorReview",
              description: cms("operator.section.vendor.action.review.description"),
              label: cms("operator.section.vendor.action.review.label"),
              link: "/setting/provider/review",
            },
            {
              code: "holidaySetting",
              description: cms("operator.section.vendor.action.holiday.description"),
              label: cms("operator.section.vendor.action.holiday.label"),
              link: "/setting/provider/holiday",
            },
            {
              code: "vendorSubscription",
              description: cms("operator.section.vendor.action.subscription.description"),
              label: cms("operator.section.vendor.action.subscription.label"),
              link: "/setting/provider/subscription",
            },
            {
              code: "managePlans",
              description: cms("operator.section.vendor.action.plan.description"),
              label: cms("operator.section.vendor.action.plan.label"),
              link: "/setting/plan/list",
            },
          ],
        },
        {
          title: cms("operator.section.fulfillment.title"),
          description: cms("operator.section.fulfillment.description"),
          icon: "fal fa-dolly fa-lg",
          code: "fulfilment",
          thumbnailHeader: cms("operator.section.fulfillment.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.fulfillment.thumbnail"),
          thumbnailDescription: cms("operator.section.fulfillment.thumbnailDescription"),
          actions: [
            {
              code: "fulfillmentSetting",
              description: cms("operator.section.fulfillment.action.setting.description"),
              label: cms("operator.section.fulfillment.action.setting.label"),
              link: "/setting/fulfillment",
            },
            {
              code: "manageFulfillmentDetail",
              description: cms("operator.section.fulfillment.action.manageFulfillment.description"),
              label: cms("operator.section.fulfillment.action.manageFulfillment.label"),
              link: "/setting/manage-fulfillment",
            },
            {
              code: "manageFulfillmentTracking",
              description: cms("operator.section.fulfillment.action.manageFulfillmentTracking.description"),
              label: cms("operator.section.fulfillment.action.manageFulfillmentTracking.label"),
              link: "/setting/manage-fulfillment-tracking",
            },
            {
              code: "address",
              description: cms("operator.section.fulfillment.action.address.description"),
              label: cms("operator.section.fulfillment.action.address.label"),
              link: "/setting/address",
            },
            {
              code: "shipping",
              description: cms("operator.section.fulfillment.action.shipping.description"),
              label: cms("operator.section.fulfillment.action.shipping.label"),
              link: "/shipping",
            },
            {
              code: "advanceShipping",
              description: cms("operator.section.fulfillment.action.advanceShipping.description"),
              label: cms("operator.section.fulfillment.action.advanceShipping.label"),
              link: "/shipping/advance",
            },
            isBigCommerceUser && {
              code: "location",
              description: cms("operator.section.fulfillment.action.location.description"),
              label: cms("operator.section.fulfillment.action.location.label"),
              link: "/setting/location",
            },
          ],
        },
        {
          title: cms("operator.section.notification.title"),
          description: cms("operator.section.notification.description"),
          icon: "fal fa-envelope fa-lg",
          code: "notifications",
          thumbnailHeader: cms("operator.section.notification.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.notification.thumbnail"),
          thumbnailDescription: cms("operator.section.notification.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.notification.action.setting.label"),
              link: "/setting/notification",
              code: "notificationSetting",
              description: cms("operator.section.notification.action.setting.description"),
            },
            {
              label: cms("operator.section.notification.action.emailTemplate.label"),
              link: "/email-template",
              code: "emailTemplate",
              description: cms("operator.section.notification.action.emailTemplate.description"),
            },
            {
              code: "digest",
              description: cms("operator.section.notification.action.digest.description"),
              label: cms("operator.section.notification.action.digest.label"),
              link: "/setting/digest",
            },
          ],
        },
        {
          title: cms("operator.section.user.title"),
          description: cms("operator.section.user.description"),
          icon: "fal fa-user fa-lg",
          code: "user",
          thumbnailHeader: cms("operator.section.user.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.user.thumbnail"),
          thumbnailDescription: cms("operator.section.user.thumbnailDescription"),
          actions: [
            isRotaroUser &&
              !isBigCommerceUser && {
                label: cms("operator.section.user.action.database.label"),
                link: "/setting/user/database",
                code: "setting",
                description: cms("operator.section.user.action.database.description"),
              },
            {
              label: cms("operator.section.user.action.profile.label"),
              link: "/profile",
              code: "profile",
              description: cms("operator.section.user.action.profile.description"),
            },
            {
              label: cms("operator.section.user.action.chatSetting.label"),
              link: "/chat-setting",
              code: "setting",
              description: cms("operator.section.user.action.chatSetting.description"),
            },
          ],
        },
        {
          title: cms("operator.section.billing.title"),
          description: cms("operator.section.billing.description"),
          icon: "fal fa-money-bill fa-lg",
          code: "billing",
          thumbnailHeader: cms("operator.section.billing.thumbnailHeader"),
          isVideo: false,
          thumbnail: cms("operator.section.billing.thumbnail"),
          thumbnailDescription: cms("operator.section.billing.thumbnailDescription"),
          actions: [
            {
              label: cms("operator.section.billing.action.setting.label"),
              link: "",
              code: "billingSetting",
              description: cms("operator.section.billing.action.setting.description"),
            },
          ],
        },
        {
          code: "creditNote",
          description: cms("operator.section.returnAndRefund.description"),
          icon: "fal fa-sack-dollar fa-lg",
          isVideo: false,
          title: cms("operator.section.returnAndRefund.title"),
          actions: [
            {
              code: "creditNote",
              description: cms("operator.section.returnAndRefund.action.creditNote.description"),
              label: cms("operator.section.returnAndRefund.action.creditNote.label"),
              link: "/setting/credit-note",
            },
          ],
        },
        {
          code: "reportSetting",
          description: cms("operator.section.report.description"),
          icon: "fal fa-file-export fa-lg",
          isVideo: false,
          title: cms("operator.section.report.title"),
          actions: [
            {
              code: "reportSetting",
              description: cms("operator.section.report.action.description"),
              label: cms("operator.section.report.action.label"),
              link: "/setting/report",
            },
          ],
        },
      ],
    }
  ];

  return list;
};

export default standardList;
