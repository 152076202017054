import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import OperatorOrderLiveConnectSetting from "./operator/features/orderLiveConnectSetting/orderLiveConnectSetting";

const { userKey } = constant;
const { operator } = userKey;

const OrderLiveConnectSetting = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorOrderLiveConnectSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderSettingComponent = getComponent(currentUserRole);
  return OrderSettingComponent;
};

export default OrderLiveConnectSetting;
