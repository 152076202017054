import React from "react";
import { ResourceList, TextStyle } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";

// import propTypes
import { listProps } from "app/payments/modules/provider/features/export/props";

import constant from "lib/constant/constant";

const List = (props) => {
  const {
    cms,
    handleSelection,
    items = {},
    orderLoading,
    onExport = () => {},
    selected = 0,
    selectedItems = {},
  } = props;

  const resourceName = [
    { singular: cms("section.export.label.status"), plural: cms("section.export.label.statusPlural") },
  ];

  const promotedBulkActions = [
    {
      content: cms("section.export.label.generate"),
      onAction: () => onExport(),
    },
  ];

  return (
    <>
      <ResourceList
        key="resourceList"
        resourceName={resourceName[selected]}
        items={items[selected]}
        renderItem={(item) => {
          const { id, name } = item;
          const replaceName = {
            onHold: constant.ON_HOLD,
          };
          const statusName = name.replace(/\b(?:onHold)\b/gi, (matched) => replaceName[matched]);
          return (
            <ResourceList.Item id={id} accessibilityLabel={`${cms("section.export.label.view")} ${id}`}>
              <h3>
                <TextStyle variation="strong">{baseHelper.ucFirst(statusName)}</TextStyle>
              </h3>
            </ResourceList.Item>
          );
        }}
        selectedItems={selectedItems[selected]}
        onSelectionChange={handleSelection}
        selectable
        promotedBulkActions={promotedBulkActions}
        loading={orderLoading}
      />
    </>
  );
};

List.propTypes = listProps.type;
List.defaultProps = listProps.default;

export default List;
