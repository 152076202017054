import { gql } from "apollo-boost";

// for operator
const UPDATE_WHOLESALE_SETTING = gql`
  mutation updateWholesaleSetting($input: WholesaleSetting) {
    updateWholesaleSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_WHOLESALE_SETTING;
