import React from "react";

// import generic
import { Export } from "./generic";

const ExportPayment = () => {
  const getComponent = () => {
    return <Export />;
  };
  const ExportPaymentComponent = getComponent();
  return ExportPaymentComponent;
};

export default ExportPayment;
