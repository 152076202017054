import React, { useContext, useState, useEffect } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  DataTable,
  Layout,
  PageActions,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast, Banner, Spinner } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_VENDOR_EXPORT_SETTING } from "app/setup/apollo/mutations";
import { GET_VENDOR_EXPORT_SETTING } from "app/setup/apollo/queries";
import { FETCH_VENDOR_FIELD } from "app/advanceVendor/apollo/queries";
import ExportTable from "./style";

const { gql } = constant;

const ManageExport = () => {
  const {
    CRITICAL,
    HIDDEN,
    IS_CSV,
    IS_PDF,
    MARKUP_PRICE,
    PARTIALLY_HIDDEN,
    VENDOR_COLUMN,
    VENDOR_TABLE_HEADING,
    SUCCESS,
    VENDOR,
    VISIBLE,
    WARNING,
    VENDOR_AMOUNT_KEY,
  } = constant;
  const { cms, currentUser, history, currentUserRefetch } = useContext(PrivateContext);
  const { _id: currentUserId } = currentUser;
  const isVendor = baseHelper.isVendor(currentUser);

  const [checked, setChecked] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const [isShippingEnable, setIsShippingEnable] = useState(false);
  const [isSocialLinkEnable, setIsSocialLinkEnable] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);

  const [customFieldInformation, setCustomFieldInformation] = useState([]);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [userInput, setUserInput] = useState({
    brandName: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    brandHandle: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    brandDescription: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    brandLogo: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    brandCoverImage: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    firstName: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    lastName: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    email: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    phoneNumber: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    streetAddress: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    city: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    provinceCode: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    country: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    postalCode: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    bankName: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    accountNumber: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    sortCode: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    paymentCountry: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    facebookUrl: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    youtubeUrl: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    twitterUrl: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    instagramUrl: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    linkedinUrl: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingStreetAddress: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingCity: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingCountry: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingPostalCode: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    customFields: [
      {
        inputType: "",
        isHideCsv: false,
        isHidePdf: false,
        key: "",
        label: "",
      },
    ],
  });

  const [vendorSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_VENDOR_EXPORT_SETTING);

  const { loading: fetchLoading, data, error: fetchVendorError, refetch: vendorRefetch } = useQuery(
    FETCH_VENDOR_FIELD,
    {
      variables: { input: { sellerId: currentUserId } },
    }
  );

  const {
    error: errorVendorExportSetting,
    loading: vendorExportLoading,
    data: dataVendorExportSetting,
    refetch,
  } = useQuery(GET_VENDOR_EXPORT_SETTING);

  useEffect(() => {
    let allChecked = true;
    const newState = { ...userInput };
    const keys = Object.keys(newState);
    keys.forEach((key) => {
      const { isHideCsv, isHidePdf } = newState[key];
      if (!isHideCsv || !isHidePdf) {
        allChecked = false;
      }
    });
    if (allChecked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [userInput]);

  useEffect(() => {
    if (fetchVendorError) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(fetchVendorError) });
    }
  }, [fetchVendorError]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const responseData = baseHelper.getResponseData(data, gql.GET_VENDOR_FIELD);
    const responseError = baseHelper.getResponseError(data, gql.GET_VENDOR_FIELD);
    if (responseError) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { isPayment = false, isShipping = false, isSocialLink = false, customFields: vendorCustomFields = [] } =
      (responseData && responseData.vendorField) || {};
    setIsPaymentEnable(isPayment);
    setIsShippingEnable(isShipping);
    setIsSocialLinkEnable(isSocialLink);
    setCustomFieldInformation(vendorCustomFields);
    if (!(vendorCustomFields && vendorCustomFields.length)) {
      delete userInput.customFields;
    }
  }, [data]);

  useEffect(() => {
    let searchData = {};
    if (dataVendorExportSetting || errorVendorExportSetting) {
      const vendorData = baseHelper.getResponseData(dataVendorExportSetting, gql.GET_VENDOR_EXPORT_SETTING);
      const vendorError = baseHelper.getResponseError(errorVendorExportSetting, gql.GET_VENDOR_EXPORT_SETTING);
      const exportData = vendorData.export;
      const updateVendorExportData = {};
      const paymentKey = ["accountNumber", "bankName", "sortCode", "paymentCountry"];
      const shippingkey = ["shippingCity", "shippingCountry", "shippingPostalCode", "shippingStreetAddress"];
      const socialKey = ["facebookUrl", "youtubeUrl", "twitterUrl", "instagramUrl", "linkedinUrl"];
      const defaultkey = [
        "brandHandle",
        "brandName",
        "brandDescription",
        "brandLogo",
        "brandCoverImage",
        "email",
        "firstName",
        "lastName",
        "phoneNumber",
        "provinceCode",
        "streetAddress",
        "city",
        "country",
        "postalCode",
      ];
      const keys = Object.keys(userInput);
      if (exportData) {
        keys.forEach((key) => {
          if (!exportData[key]) {
            setFirstPush(false);
            exportData[key] = {
              label: "",
              isHideCsv: false,
              isHidePdf: false,
            };
          }
          keys.forEach((itemKey) => {
            if (isPaymentEnable && paymentKey.includes(itemKey)) {
              updateVendorExportData[itemKey] = exportData[itemKey];
            }
            if (isSocialLinkEnable && socialKey.includes(itemKey)) {
              updateVendorExportData[itemKey] = exportData[itemKey];
            }
            if (isShippingEnable && shippingkey.includes(itemKey)) {
              updateVendorExportData[itemKey] = exportData[itemKey];
            }
            if (defaultkey.includes(itemKey)) {
              updateVendorExportData[itemKey] = exportData[itemKey];
            }
            if (key === "customFields" && exportData.customFields.length) {
              (customFieldInformation || []).forEach((item) => {
                searchData = exportData.customFields.find((x) => x.key === item.key);
                if (searchData) {
                  updateVendorExportData[item.key] = { ...searchData, isCustomField: true };
                } else {
                  updateVendorExportData[item.key] = {
                    inputType: item.inputType,
                    key: item.key,
                    label: "",
                    isCustomField: true,
                    isHideCsv: false,
                    isHidePdf: false,
                  };
                }
              });

              /*
              exportData.customFields.forEach(
                // eslint-disable-next-line no-return-assign
                (item) => (updateVendorExportData[item.key] = { ...item, isCustomField: true })
              );
              */
            }
          });
        });
        setUpdatedAt(vendorData.updatedAt);
        setUserInput(updateVendorExportData);
        if (vendorError) {
          setBannerStatus({ isOpen: true, title: vendorError, status: constant.CRITICAL });
        }
      }
    }
  }, [dataVendorExportSetting, errorVendorExportSetting]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const currentLabel = [];
    const noUncheckCsv = Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        newData.push(userInput[item]);
        currentLabel.push(cms(`label.${item}`));
        if (userInput[item].isHideCsv) {
          return true;
        }
        return false;
      });
    const noUncheckPdf = Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        if (userInput[item].isHidePdf) {
          return true;
        }
        return false;
      });

    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        const found = currentLabel.find((label) => label === element.label);
        if ((element.label && item.label && element.label.trim() === item.label.trim()) || found) {
          response = true;
          setBannerStatus({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          return false;
        }
        return false;
      });
    });
    if (!response) {
      response = true;
      noUncheckCsv.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (!response) {
      response = true;
      noUncheckPdf.forEach((item) => {
        if (!item) response = item;
      });

      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const handleAllChecked = (value) => {
    setSubmitEnable(true);
    setChecked(value);
    const newData = [];
    Object.keys(userInput).map((item) => {
      newData.push(userInput[item]);
    });

    setUserInput((prevState) => {
      const newState = { ...prevState };
      const keys = Object.keys(newState);
      keys.forEach((key, index) => {
        const updateCustomField = userInput[key];
        if (updateCustomField?.isCustomField) {
          newState[key] = {
            isHideCsv: value,
            isHidePdf: value,
            label: newData[index].label,
            isCustomField: true,
            key: updateCustomField.key,
          };
        } else newState[key] = { isHideCsv: value, isHidePdf: value, label: newData[index].label };
      });
      return newState;
    });
  };

  const updateVendorSetting = async (type) => {
    const updatedUserInput = { customFields: [] };
    const keys = Object.keys(userInput).map((item) => {
      const abc = userInput[item];
      if (abc.isCustomField) {
        // eslint-disable-next-line no-param-reassign
        delete abc.isCustomField;
        updatedUserInput.customFields.push(abc);
        return false;
      }
      updatedUserInput[item] = abc;
    });

    vendorSetting({ variables: { input: { ...updatedUserInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_VENDOR_EXPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_VENDOR_EXPORT_SETTING);
          if (resData) {
            setMessage(cms("message.success.paymentUpdate"));
            if (type === constant.SAVE_SMALL) {
              setMessage(cms("message.success.payment"));
            }
            setTimeout(() => {
              refetch();
              vendorRefetch();
              currentUserRefetch();
            }, 2000);
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const labelRegex = /[^a-zA-Z ]/;
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val.split(labelRegex).join("");
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };

  const selectCurrentLabelsColumn = (item) => <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField value={data && data.label} maxLength={35} onChange={(value) => handleLabelChange(value, key)} />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === IS_CSV) setUserInput({ ...userInput, [key]: { ...updateData, isHideCsv: !updateData.isHideCsv } });
    if (value === IS_PDF) setUserInput({ ...userInput, [key]: { ...updateData, isHidePdf: !updateData.isHidePdf } });
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => (
    <Stack wrap={false}>
      <Checkbox
        label={cms("label.isCsv")}
        checked={userInput[index] && userInput[index].isHideCsv}
        onChange={() => handleCheckbox(IS_CSV, index)}
      />
      <Checkbox
        label={cms("label.isPdf")}
        checked={userInput[index] && userInput[index].isHidePdf}
        onChange={() => handleCheckbox(IS_PDF, index)}
      />
    </Stack>
  );

  const getRows = () => {
    return Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        const itemData = userInput[item];
        if (itemData.isHideCsv && itemData.isHidePdf) {
          badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
        } else if (itemData.isHidePdf || itemData.isHideCsv) {
          badge = { badgesLable: PARTIALLY_HIDDEN, badgesStatus: WARNING };
        } else {
          badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        }
        const currentValues = selectCurrentLabelsColumn(item);
        const newValues = selectNewLabelsTextField(userInput[item], item);
        const badgesValues = badges(badge);
        const hideColumnValues = selectHideColumn(item);
        return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
      });
  };

  if (fetchLoading || vendorExportLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card>
            <Card.Header
              title={[
                cms("label.exportSetting"),
                updatedAt && firstPush && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                  </TextStyle>
                ),
              ]}
            >
              <Checkbox label={cms("label.markAll")} checked={checked} onChange={(value) => handleAllChecked(value)} />
            </Card.Header>
            <Card.Section>
              <ExportTable className="payment-export-table">
                <DataTable columnContentTypes={VENDOR_COLUMN} headings={VENDOR_TABLE_HEADING} rows={getRows()} />
              </ExportTable>
              {firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) updateVendorSetting();
                    },
                    disabled: !submitEnabled,
                    loading: settingUpdateLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </Card.Section>
          </Card>
          {!firstPush && (
            <div>
              <PageActions
                primaryAction={{
                  content: cms("common.button.submit"),
                  onAction: () => {
                    const update = verifyValues();
                    if (!update) updateVendorSetting(constant.SAVE_SMALL);
                  },
                  disabled: !submitEnabled,
                  loading: settingUpdateLoading,
                }}
                secondaryActions={[
                  {
                    id: "cancelButton",
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            </div>
          )}
        </Layout.AnnotatedSection>
        <Toast message={message} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};
export default withFeature(withErrorBoundary(ManageExport), { feature: constant.MANAGE_VENDOR_EXPORT });
