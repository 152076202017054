import { gql } from "apollo-boost";

const FETCH_ORDER_LINE_LIST = gql`
  query getOrderLineList($input: GetListInputV1) {
    getOrderLineList(input: $input) {
      status
      error
      data {
        count
        orderLineList {
          _id
          accountingStatus
          actualQuantity
          isAutoExpiry
          isAutoReject
          isShippingPaid
          isConsignmentPick
          isVendorFulfilled
          vendor
          vendorId
          shop
          orderId
          shopifyLineItemId
          createdAt
          title
          shopifyOrderId
          shopifyOrderDate
          status
          quantity
          orderNumber
          fulfillmentStatus
          fulfillmentType
          image
          isRefund
          isReturn
          return {
            quantity
            reason
            requestedAt
            updatedAt
            sellerNote
            vendorNote
            status
            isSellerManaged
          }
          returnedQuantity
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { FETCH_ORDER_LINE_LIST };
