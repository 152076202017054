import { gql } from "apollo-boost";

const REGISTER_SELLER = gql`
  mutation registerSeller($input: StoreDetail) {
    registerSeller(input: $input) {
      data {
        accepted
        firstLogin
        id
        token
      }
      error
      status
    }
  }
`;

const REGISTER_WEBHOOK = gql`
  mutation registerWebhook {
    registerWebhook {
      status
      data
      error
    }
  }
`;

const SYNC_PRODUCT_TYPE = gql`
  mutation syncProductType {
    syncProductType {
      status
      data
      error
    }
  }
`;

export { REGISTER_SELLER, REGISTER_WEBHOOK, SYNC_PRODUCT_TYPE };
