import * as yup from "yup";

const amountSchema = (cms) => {
  return yup.object().shape({
    standardAmount: yup
      .number()
      .typeError(cms("common.message.error.standardIsRequired", { item: "discount" }))
      .min(0),
  });
};
const amountLengthSchema = (cms) => {
  return yup.object().shape({
    standardAmount: yup
      .number()
      .typeError(cms("common.message.error.standardIsRequired", { item: "discount" }))
      .min(0)
      .max(100),
  });
};

export { amountSchema, amountLengthSchema };
