import React, { useContext } from "react";
import { FormLayout, Heading, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import { withErrorBoundary } from "lib/hoc";
import ConsignmentShip from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";
import DropShip from "app/setup/modules/operator/features/_fulfillment/dropShip/dropShip";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { HybridDropshipWrapper } from "asset/styles/globalStyle";

const HybridDropShip = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <HybridDropshipWrapper>
      <DropShip />
      <TextContainer>
        {/* <br /> */}
        <FormLayout>
          <Stack>
            <Stack.Item fill>
              <Heading>{cms("title")}</Heading>
            </Stack.Item>
            {/* <Stack.Item>
            <Link onClick={() => learnMore(cms("title"), cms("title"))}>{cms("common.learnMore")}</Link>
          </Stack.Item> */}
          </Stack>
        </FormLayout>
        <br />
        <TextStyle variation="subdued">{cms("hybrid.dropShip.description")}</TextStyle>
      </TextContainer>

      <ConsignmentShip method="hybrid" />
    </HybridDropshipWrapper>
  );
};

export default withErrorBoundary(HybridDropShip);
