import React, { useContext } from "react";
import { Select, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { PrivateContext } from "lib/context";

import HopScotch from "./thirdPartyShipping/hopScotch";

const ThirdPartyOptions = (props) => {
  const { cms } = useContext(PrivateContext);
  const { error, handleValidation } = useContext(FulfillmentContext);

  const { selected, setSelected } = props;

  const options = [{ label: cms("label.hopscotch"), value: constant.HOPSCOTCH }];

  return (
    <FormLayout>
      <Select
        label={cms("label.thirdPartyPickup")}
        placeholder={cms("placeholder.pickupService")}
        options={options}
        onChange={(value) => {
          setSelected(value);
        }}
        value={selected}
      />
      {selected === constant.HOPSCOTCH && <HopScotch error={error} handleValidation={handleValidation} />}
    </FormLayout>
  );
};

ThirdPartyOptions.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ThirdPartyOptions), {
  feature: constant.ADVANCE_CONSIGNMENT_PICKUP,
});
