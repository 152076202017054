import { gql } from "apollo-boost";

const CMS = gql`
  query getPublicCMS($input: GetCMS!) {
    getPublicCMS(input: $input) {
      status
      error
      data {
        commonContent
        pageContent
      }
    }
  }
`;

export default CMS;
