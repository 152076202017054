import { gql } from "apollo-boost";

const GET_LAKE_PRODUCT_AND_SELLER_PRODUCT = gql`
  query getLakeProductAndSellerProduct($input: GetProductLakeInput) {
    getLakeProductAndSellerProduct(input: $input) {
      data {
        sellerProduct {
          _id
          name
          description
          pageTitle
          type
          approvalStatus
          priceId {
            costPrice
            calculatedPrice
            price
            salePrice
            regularPrice
          }
          inventoryId {
            productId
            sku
            quantity
            isManagingStock
          }
          dimensions {
            weight
          }
          images {
            imageId
            imageUrl
          }
          variants {
            _id
            providerVariantId
            images {
              imageUrl
              imageId
            }
            optionValues {
              label
              sortOrder
              providerOptionValueId
              optionId {
                displayName
                sortOrder
                providerOptionId
              }
            }
            price
            sortOrder
            priceId {
              costPrice
              calculatedPrice
              price
              salePrice
              regularPrice
            }
            option
            inventoryId {
              productId
              sku
              quantity
              isManagingStock
            }
            weight
            images {
              imageId
              imageUrl
            }
          }
          ownerId
          sellerId
          providerIds
          ecommercePlatform
          weight
          updatedAt
        }
        product {
          _id
          name
          description
          pageTitle
          type
          approvalStatus
          priceId {
            costPrice
            calculatedPrice
            price
            salePrice
            regularPrice
          }
          inventoryId {
            productId
            sku
            quantity
            isManagingStock
          }
          dimensions {
            weight
          }
          images {
            imageId
            imageUrl
          }
          variants {
            _id
            images {
              imageUrl
              imageId
            }
            optionValues {
              label
              sortOrder
              optionId {
                displayName
                sortOrder
              }
            }
            price
            sortOrder
            priceId {
              costPrice
              calculatedPrice
              price
              salePrice
              regularPrice
            }
            option
            inventoryId {
              productId
              sku
              quantity
              isManagingStock
            }
            weight
            images {
              imageId
              imageUrl
            }
          }
          ownerId
          sellerId
          providerIds
          ecommercePlatform
          weight
          updatedAt
        }
      }
      status
      error
    }
  }
`;
export default GET_LAKE_PRODUCT_AND_SELLER_PRODUCT;
