const standardList = (cms) => [
  {
    code: "marketplace",
    title: cms("provider.title"),
    description: cms("provider.description"),
    thumbnail: cms("provider.thumbnail"),
    list: [
      {
        title: cms("provider.section.product.title"),
        description: cms("provider.section.product.description"),
        icon: "fal fa-tag fa-lg",
        code: "product",
        thumbnailHeader: cms("provider.section.product.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("provider.section.product.thumbnail"),
        thumbnailDescription: cms("provider.section.product.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.product.action.inventory.label"),
            link: "/setting/product",
            code: "productSettings",
            description: cms("provider.section.product.action.inventory.description"),
          },
          {
            label: cms("provider.section.product.action.discount.label"),
            link: "/setting/discount",
            code: "discount",
            description: cms("provider.section.product.action.discount.description"),
          },
          {
            code: "productFormSetting",
            description: cms("operator.section.product.action.productFormLabel.description"),
            label: cms("operator.section.product.action.productFormLabel.label"),
            link: "/setting/product/label",
          },
          {
            code: "productExportSetting",
            description: cms("provider.section.product.action.productExport.description"),
            label: cms("provider.section.product.action.productExport.label"),
            link: "/setting/product/export",
          },
        ],
      },
      {
        title: cms("provider.section.order.title"),
        description: cms("provider.section.order.description"),
        icon: "fal fa-shopping-cart fa-lg",
        code: "order",
        thumbnailHeader: cms("provider.section.order.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("provider.section.order.thumbnail"),
        thumbnailDescription: cms("provider.section.order.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.order.action.disableSync.label"),
            link: "/setting/order",
            code: "orderSettings",
            description: cms("provider.section.order.action.disableSync.description"),
          },
          {
            label: cms("provider.section.order.action.autoAccept.label"),
            link: "/setting/order",
            code: "orderSettings",
            description: cms("provider.section.order.action.autoAccept.description"),
          },
          {
            label: cms("provider.section.order.action.autoFulfill.label"),
            link: "/setting/order",
            code: "orderSettings",
            description: cms("provider.section.order.action.autoFulfill.description"),
          },
          {
            code: "dynamicDiscount",
            description: cms("provider.section.order.action.dynamicDiscount.description"),
            label: cms("provider.section.order.action.dynamicDiscount.label"),
            link: "/setting/order/discount",
          },
          {
            label: "Manage Order Auto Expiry",
            link: "/setting/order-expiry",
            code: "orderSettings",
            description: "Manage order expiry manually or automatically.",
          },
          {
            code: "orderExportSetting",
            description: cms("provider.section.order.action.orderExport.description"),
            label: cms("provider.section.order.action.orderExport.label"),
            link: "/setting/order/export",
          },
        ],
      },
      {
        title: cms("provider.section.payment.title"),
        description: cms("provider.section.payment.description"),
        icon: "fal fa-sack-dollar fa-lg",
        code: "payments",
        thumbnailHeader: cms("provider.section.payment.thumbnailHeader"),
        thumbnail: cms("provider.section.payment.thumbnail"),
        thumbnailDescription: cms("provider.section.payment.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.payment.action.stripe.label"),
            link: "/payment-connect",
            code: "paymentService",
            description: cms("provider.section.payment.action.stripe.description"),
          },
          {
            label: cms("provider.section.payment.action.paypal.label"),
            link: "/payment-connect",
            code: "paymentService",
            description: cms("provider.section.payment.action.paypal.description"),
          },
          {
            code: "paymentExportSetting",
            description: cms("provider.section.payment.action.paymentExport.description"),
            label: cms("provider.section.payment.action.paymentExport.label"),
            link: "/setting/payment/export",
          },
        ],
      },
      {
        title: cms("provider.section.fulfillment.title"),
        description: cms("provider.section.fulfillment.description"),
        icon: "fal fa-dolly fa-lg",
        code: "fulfilment",
        thumbnailHeader: cms("provider.section.fulfillment.thumbnailHeader"),
        thumbnail: cms("provider.section.fulfillment.thumbnail"),
        thumbnailDescription: cms("provider.section.fulfillment.thumbnailDescription"),
        actions: [
          {
            code: "address",
            description: cms("provider.section.fulfillment.action.address.description"),
            label: cms("provider.section.fulfillment.action.address.label"),
            link: "/setting/address",
          },
          {
            code: "location",
            description: cms("provider.section.fulfillment.action.location.description"),
            label: cms("provider.section.fulfillment.action.location.label"),
            link: "/setting/location",
          },
          {
            code: "carrier",
            description: cms("provider.section.fulfillment.action.carrier.description"),
            label: cms("provider.section.fulfillment.action.carrier.label"),
            link: "/setting/carrier",
          },
          {
            label: cms("provider.section.fulfillment.action.shipping.label"),
            link: "/shipping",
            code: "shipping",
            description: cms("provider.section.fulfillment.action.shipping.description"),
          },
          {
            label: cms("provider.section.fulfillment.action.advanceShipping.label"),
            link: "/shipping/advance",
            code: "advanceShipping",
            description: cms("provider.section.fulfillment.action.advanceShipping.description"),
          },
        ],
      },
      {
        title: cms("provider.section.notification.title"),
        description: cms("provider.section.notification.description"),
        icon: "fal fa-envelope fa-lg",
        code: "notifications",
        thumbnailHeader: cms("provider.section.notification.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("provider.section.notification.thumbnail"),
        thumbnailDescription: cms("provider.section.notification.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.notification.action.notification.label"),
            link: "/setting/notification",
            code: "notification",
            description: cms("provider.section.notification.action.notification.description"),
          },
          {
            code: "digest",
            description: cms("provider.section.notification.action.digest.description"),
            label: cms("provider.section.notification.action.digest.label"),
            link: "/setting/digest",
          },
        ],
      },
      {
        title: cms("provider.section.user.title"),
        description: cms("provider.section.user.description"),
        icon: "fal fa-user fa-lg",
        code: "user",
        thumbnailHeader: cms("provider.section.user.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("provider.section.user.thumbnail"),
        thumbnailDescription: cms("provider.section.user.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.user.action.profile.label"),
            link: "/profile",
            code: "profile",
            description: cms("provider.section.user.action.profile.description"),
          },
          {
            label: cms("provider.section.user.action.changePassword.label"),
            link: "/change-password",
            code: "changePassword",
            description: cms("provider.section.user.action.changePassword.description"),
          },
          {
            code: "holidaySetting",
            description: cms("provider.section.user.action.holiday.description"),
            label: cms("provider.section.user.action.holiday.label"),
            link: "/setting/provider/holiday",
          },
        ],
      },
      {
        title: cms("provider.section.billing.title"),
        description: cms("provider.section.billing.description"),
        icon: "fal fa-money-bill fa-lg",
        code: "billing",
        thumbnailHeader: cms("provider.section.billing.thumbnailHeader"),
        isVideo: false,
        thumbnail: cms("provider.section.billing.thumbnail"),
        thumbnailDescription: cms("provider.section.billing.thumbnailDescription"),
        actions: [
          {
            label: cms("provider.section.billing.action.billing.label"),
            link: "",
            code: "billing",
            description: cms("provider.section.billing.action.billing.description"),
          },
        ],
      },
      {
        code: "creditNote",
        description: cms("provider.section.returnAndRefund.description"),
        icon: "fal fa-sack-dollar fa-lg",
        isVideo: false,
        title: cms("provider.section.returnAndRefund.title"),
        actions: [
          {
            code: "creditNote",
            description: cms("provider.section.returnAndRefund.action.creditNote.description"),
            label: cms("provider.section.returnAndRefund.action.creditNote.label"),
            link: "/setting/credit-note",
          },
        ],
      },
      {
        code: "reportSetting",
        description: cms("provider.section.report.description"),
        icon: "fal fa-file-export fa-lg",
        isVideo: false,
        title: cms("provider.section.report.title"),
        actions: [
          {
            code: "reportSetting",
            description: cms("provider.section.report.action.description"),
            label: cms("provider.section.report.action.label"),
            link: "/setting/report",
          },
        ],
      },
    ],
  },
];

export default standardList;
