import React, { useState } from "react";
import { Card, Collapsible, Stack, Caption } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { reviewProduct } from "app/productOld/modules/operator/features/review/props";
/* import { baseHelper } from "lib/helpers"; */

// import hoc
import { withErrorBoundary } from "lib/hoc";

const ReviewProduct = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  /* const [isSelected, setIsSelected] = useState(true); */
  const {
    isAdded,
    isDeleted,
    date,
    vendor,
    store,
    cms,
    0: label,
    1: vendorChange,
    2: sellerChange,
    /* 3: key,
    onToggleSelect, */
  } = props;

  // Note: Product review 2.2 work in progress
  // const renderChanges = (value, keyName) => {
  // if (keyName === constant.CUSTOM_METAFIELD) {
  //   if (value && value.length) {
  //     return value.map((item) => {
  //       if (item && item.value && item.key && item.name) {
  //         const isObject = typeof item.value === "object" && item.value !== null;
  //         const metaValue = isObject ? Object.values(item.value) : item.value;
  //         const displayKey = baseHelper.ucFirst(item && item.name);
  //         const metaKey = baseHelper.ucFirst(item && item.key);
  //         return <p>{`${displayKey || metaKey}:  ${metaValue}`}</p>;
  //       }
  //       return "";
  //     });
  //   }
  //   return constant.NOT_PROVIDED;
  // }
  //   console.log("value----------++++++++++++++", value);
  //   return value;
  // };

  // const handleToggleSelect = () => {
  //   setIsSelected(!isSelected);
  //   onToggleSelect(key);
  // };
  const actionText = (isAdded && constant.ADDED) || (isDeleted && constant.DELETED) || constant.UPDATED;
  return (
    <Card
      title={[
        // <Checkbox label="Basic checkbox" labelHidden checked={isSelected} onChange={handleToggleSelect} />,
        // " ",
        `${label} changed`,
      ]}
      sectioned
      actions={[
        {
          content: isOpen ? cms("common.label.hide") : cms("common.label.show"),
          onAction: () => setIsOpen(!isOpen),
          disclosure: isOpen ? constant.UP : constant.DOWN,
        },
      ]}
    >
      <Stack>
        <Stack.Item fill>
          <Caption>
            {`${cms("common.label.lastUpdatedAt")}: `}
            {date}
          </Caption>
        </Stack.Item>
      </Stack>
      <Collapsible open={isOpen} id="basic-collapsible-2">
        <Card.Section title={`${actionText} by ${vendor}`}>{vendorChange || constant.NOT_PROVIDED}</Card.Section>
        <Card.Section title={`Published at ${store}`}>{sellerChange || constant.NOT_PROVIDED}</Card.Section>
      </Collapsible>
    </Card>
  );
};

ReviewProduct.propTypes = reviewProduct.type;

export default withErrorBoundary(ReviewProduct);
