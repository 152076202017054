import { durationSchema } from "app/orders/modules/operator/features/orderAutoExpiry/yup/schema";
import { yupHelper } from "lib/helpers";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (value, cms) => {
  const promise = validateValue(durationSchema(cms), { duration: value });
  return handlePromiseError(promise);
};

export default validate;
