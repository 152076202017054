// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Banner as PolarisBanner,
  Button,
  Caption,
  Form,
  FormLayout,
  Layout,
  List,
  TextContainer,
} from "@shopify/polaris";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { GET_VENDOR_PRODUCT_TYPES } from "app/setup/apollo/queries";
import { UPDATE_COMMISSION } from "app/setup/apollo/mutations";

// import hoc
import { withFeature } from "lib/hoc";

// import propTypes
import { advanceProp } from "app/setup/modules/operator/features/commission/props";

// import components
import { Banner, Spinner, CardFooter } from "lib/components";
import AdvanceItem from "./subFeatureItem/advanceLine/advanceLine";

const AdvanceCommission = (props) => {
  const {
    CATEGORY,
    PERCENTAGE,
    PRICE,
    PRODUCT_TYPE,
    TYPE,
    gql: { GET_VENDOR_PRODUCT, UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
    value: { MAX_PERCENTAGE },
  } = constant;
  const { cms = {}, currentUser = {} } = useContext(PrivateContext);
  const { isStripeSubscription = false } = currentUser;
  const advancedCaptions = cms("section.advancedCommission.caption") || [];

  const {
    advanceCommissionValue,
    refetchLoading,
    standardCommissionValue,
    rangeCommissionValue,
    setAdvanceValue,
    priorityValue,
    isTaxInclusive,
    refetch,
  } = props;
  const [allTypes, setAllTypes] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [state, setState] = useState({
    count: 1,
    advancedValue: [{ type: null, category: null, value: null, price: null }],
    ruleBy: PRODUCT_TYPE,
  });
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const { loading, data: vendorAndProductTypesData } = useQuery(GET_VENDOR_PRODUCT_TYPES);
  const [setAdvanceCommission, { loading: updateAdvanceCommissionLoading }] = useMutation(UPDATE_COMMISSION);
  useEffect(() => {
    if (loading) {
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const responseError = baseHelper.getResponseError(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const { types = [], supplierRows = [] } = responseData || {};
    if (responseError || !responseData || (!types.length && !supplierRows.length)) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.advancedOptionsUnavailable"),
        message: cms("message.error.advancedOptionsUnavailableReason"),
      });
      return;
    }
    if (!types.length) {
      setBanner({
        isOpen: true,
        status: "warning",
        title: cms("message.error.advancedOptionsCategoriesUnavailable"),
        message: cms("message.error.advancedOptionsCategoriesUnavailableReason"),
      });
    }
    if (!supplierRows.length) {
      setBanner({
        isOpen: true,
        status: "warning",
        title: cms("message.error.advancedOptionsVendorsUnavailable"),
        message: cms("message.error.advancedOptionsVendorsUnavailableReason"),
      });
    }
    const allTypesValues = types.map(({ _id: productId, value: productName }) => ({
      label: productName,
      value: productId,
    }));
    const allSupplierValues = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: supplierId, brandName }) => ({
        label: brandName,
        value: supplierId,
      }));
    setAllTypes(allTypesValues);
    setAllSuppliers(allSupplierValues);
  }, [vendorAndProductTypesData, loading, GET_VENDOR_PRODUCT, cms]);

  useEffect(() => {
    const { advancedValues = [] } = advanceCommissionValue || {};
    const advancedValueLength = (advancedValues && Array.isArray(advancedValues) && advancedValues.length) || 0;
    const isEqualArray = baseHelper.isItemEqual(advancedValues, state.advancedValue);

    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        advancedValue: advancedValues,
      });
    }
  }, [PRODUCT_TYPE, advanceCommissionValue, state.advancedValue]);

  if (loading || refetchLoading) {
    return (
      <Layout.Section>
        <Spinner />
      </Layout.Section>
    );
  }

  const updateVal = (option, val, item) => {
    setSubmitEnable(true);
    setBanner({ isOpen: false, status: "", title: "" });
    const { advancedValue } = state;
    let duplicate = false;
    if (option === TYPE) {
      advancedValue[item][CATEGORY] = null;
    }
    if (option === CATEGORY) {
      advancedValue.forEach((advanceData) => {
        if (advanceData.category === val) {
          advancedValue[item][CATEGORY] = null;
          setBanner({ isOpen: true, status: "critical", title: cms("message.error.alreadyExists") });
          duplicate = true;
          setSubmitEnable(false);
        }
        return null;
      });
    }
    if (option === constant.VALUE) {
      advancedValue[item].price = null;
    }
    if (duplicate) {
      return;
    }
    if (option === PRICE) {
      if (val.length && !baseHelper.validatePositiveNumericValues(val)) {
        return;
      }
      if (state.advancedValue[item].value === constant.PERCENTAGE && Number(val) > constant.value.MAX_PERCENTAGE) {
        return;
      }
      advancedValue[item].price = val;
    } else {
      advancedValue[item][option] = val;
    }
    setState({ ...state, advancedValue });
    setAdvanceValue({ ...advanceCommissionValue, advancedValue, advancedValues: advancedValue });
  };

  const add = () => {
    const { advancedValue, count } = state;
    const { length } = advancedValue;
    if (length > 1) {
      const line = advancedValue[length - 2];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({ isOpen: true, status: "critical", title: cms("message.error.invalidRowDetails") });
        setSubmitEnable(false);
        return;
      }
    }

    const row = { type: null, category: null, value: null, price: null };
    advancedValue.push(row);
    setState({ ...state, count: count + 1, advancedValue });
  };

  const removeItem = (item) => {
    setSubmitEnable(true);
    const { advancedValue } = state;
    advancedValue.splice(item, 1);
    setState({ ...state, count: state.count - 1, advancedValue });
  };

  const renderAdvanceItems = () => {
    const { count, advancedValue } = state;
    return (
      <AdvanceItem
        advancedValue={advancedValue}
        allSuppliers={allSuppliers}
        allTypes={allTypes}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
      />
    );
  };

  const renderCaptions = () => {
    return advancedCaptions.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>
            <b>{caption.title}</b>
            {` ${caption.description}`}
          </Caption>
        </List.Item>
      );
    });
  };

  const updateAdvanceCommission = async (requestData) => {
    try {
      const res = await setAdvanceCommission({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        setSubmitEnable(false);
        return;
      }
      setSubmitEnable(false);
      setBanner({ isOpen: true, status: "success", title: cms("message.success") });
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
      setSubmitEnable(false);
    }
  };

  let { advancedValue } = state || [];

  const noValues = !(allSuppliers.length || allTypes.length || advancedValue.length < 1);
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });
  const mapRangeCommission = () => {
    return rangeCommissionValue.map((obj) => {
      const { min, ...rest } = obj;
      return rest;
    });
  };

  const handleSubmit = () => {
    if (!submitEnabled) {
      return;
    }
    if (standardCommissionValue.selectedStandardType === "" || standardCommissionValue.standardAmount === "") {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.standardCommissionIsRequired") || "",
      });
      return;
    }
    const isAnyRangeValue =
      rangeCommissionValue && Array.isArray(rangeCommissionValue) && rangeCommissionValue.length !== 0;
    const requestData = {
      type: standardCommissionValue.selectedStandardType,
      price: parseFloat(standardCommissionValue.standardAmount),
      rangeValues: (isAnyRangeValue && mapRangeCommission()) || [],
      advancedValues: [],
      ruleBy: priorityValue,
      isTaxInclusive,
    };
    const isEmpty = false;
    let isInvalidValue = false;
    if (advancedValue && advancedValue.length) {
      advancedValue.map((row) => {
        const keys = Object.keys(row);
        // eslint-disable-next-line no-unused-vars
        let count = 0;
        keys.map((key) => {
          if (row[key]) {
            count += 1;
          }
          return null;
        });
        // eslint-disable-next-line no-param-reassign
        row.price = parseFloat(row.price || 0);
        if (row.value === PERCENTAGE && row.price > MAX_PERCENTAGE) {
          isInvalidValue = true;
        }

        // if ((count && count !== keys.length) || !row.price) {
        //   isEmpty = true;
        // }

        return null;
      });
      advancedValue = advancedValue.filter((item) => item.type !== null && item.price !== "NaN");
    }
    if (isInvalidValue) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.error.percentageUpperLimit") });
      return;
    }
    if (isEmpty) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.invalidRowDetails"),
      });
      setSubmitEnable(false);
      return;
    }
    requestData.advancedValues = advancedValue;
    updateAdvanceCommission(requestData);
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            isScrollTop={false}
            onDismiss={() => dismissBanner()}
          >
            {banner.message}
          </Banner>
          <br />
        </>
      )}
      <Form onSubmit={handleSubmit}>
        <FormLayout>{!noValues && renderAdvanceItems()}</FormLayout>
        {!noValues && (
          <TextContainer>
            <Button plain id="addLink" onClick={add} disabled={isStripeSubscription}>
              {cms("common.button.addMore")}
            </Button>
          </TextContainer>
        )}
        <br />
        <PolarisBanner status="info">
          <p>{cms("common.label.option")}</p>
          <br />
          <p>
            <List type="bullet">{renderCaptions()}</List>
          </p>
        </PolarisBanner>
        <CardFooter disabled={!submitEnabled} loading={updateAdvanceCommissionLoading || false} />
      </Form>
    </>
  );
};

AdvanceCommission.propTypes = advanceProp.type;

export default withFeature(AdvanceCommission, { feature: constant.ADVANCE_COMMISSION });
