import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotatedCard } from "lib/components";

import OperatorSettings from "./operator/settings";
import ProviderSettings from "./provider/settings";
import AdminSettings from "./admin/setting";

const { userKey } = constant;
const { operator, provider, admin } = userKey;

const Settings = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotatedCard />;
    }
    if (role === operator) {
      return <OperatorSettings />;
    }
    if (role === provider) {
      return <ProviderSettings />;
    }
    if (role === admin) {
      return <AdminSettings />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const SettingsComponent = getComponent(currentUserRole);
  return SettingsComponent;
};

export default Settings;
