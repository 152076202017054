import React from "react";

// import generic component
import { BulkActivity } from "app/userManagement/modules/generic";

import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

const ProviderBulkActivity = () => {
  return <BulkActivity />;
};

export default withFeature(withErrorBoundary(ProviderBulkActivity), { feature: constant.BULK_ACTIVITY });
