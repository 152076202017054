import { gql } from "apollo-boost";

const GET_SELLER_SHIPPING = gql`
  query {
    getSellerShipping {
      data {
        sellerShipping {
          _id
          id
          sellerId
          term
          type
          amount
          vendorIds
          allowVendor
          isFreeForCustomer
          shippingPerVendor {
            vendorIds
            isAllowed
          }
          createdAt
          updatedAt
        }
        isShippingBandAvailable
      }
      status
      error
    }
  }
`;

export default GET_SELLER_SHIPPING;
