import { gql } from "apollo-boost";

const RESEND_OTP_EMAIL = gql`
  mutation reSendOtpEmail($input: ResendOtpEmail) {
    reSendOtpEmail(input: $input) {
      status
      data
      error
    }
  }
`;

export default RESEND_OTP_EMAIL;
