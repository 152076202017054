import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import "jspdf-autotable";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper, fileHelper, storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";

import { GET_ORDER_LAKE_LINE } from "app/orderLake/apollo/queries";
import orderLakeHelper from "app/orderLake/modules/orderLakeHelper";

const ExportOrderAsPDF = () => {
  const data = useContext(PrivateContext);
  const brandLogo = storageHelper.get("logoUrl");
  const { DEFAULT_PDF_LOGO: defaultLogo, gql } = constant;
  const { cms, currentUser } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [
    getOrderLines,
    { data: getOrderData, loading: getOrderLoading, error: getOrderError },
  ] = useLazyQuery(GET_ORDER_LAKE_LINE, { variables: { input: queryData } });

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!getOrderLoading && !getOrderError && getOrderData) {
      const resData = baseHelper.getResponseData(getOrderData, gql.GET_ORDER_LAKE_LINE);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getOrderData, gql.GET_ORDER_LAKE_LINE);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (!resData.length) {
        bannerData = {
          title: cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const pdfData = orderLakeHelper.orderCSVData(resData, false, true, isVendor);
      if (brandLogo) {
        fileHelper
          .loadImage(brandLogo)
          .then((logo) => {
            fileHelper.generatePdf(logo, pdfData, constant.ORDER);
          })
          .catch(() => {
            fileHelper.generatePdf(false, pdfData, constant.ORDER);
          });
      } else {
        fileHelper
          .loadImage(defaultLogo)
          .then((logo) => {
            fileHelper.generatePdf(logo, pdfData, constant.ORDER);
          })
          .catch(() => {
            fileHelper.generatePdf(false, pdfData, constant.ORDER);
          });
      }
    }
  }, [
    getOrderData,
    getOrderError,
    getOrderLoading,
    cms,
    data,
    isVendor,
    currentUser.logo,
    brandLogo,
    defaultLogo,
    gql.GET_ORDER_LAKE_LINE,
  ]);

  useEffect(() => {
    const exportOrder = () => {
      const dataToFetch = {};
      const { storeStatus, fulfillmentStatus, vendor, accountingStatus } = baseHelper.queryParamsFromLocation(data);
      if (storeStatus && storeStatus.length) {
        dataToFetch.storeStatus = storeStatus.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      setQueryData(dataToFetch);
      getOrderLines();
    };
    if (isPageLoading) {
      exportOrder();
      setIsPageLoading(false);
    }
  }, [data, getOrderLines, isPageLoading]);

  return (
    <Layout>
      {getOrderLoading && <Spinner isFullPage />}
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ExportOrderAsPDF), { feature: constant.EXPORT_ORDER });
