import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Badge,
  Banner,
  Caption,
  Link,
  Modal,
  ResourceList,
  Select,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { StyledCard, StyledModal } from "app/orders/modules/operator/features/view/genericStyled";
import { AlertMinor } from "@shopify/polaris-icons";
import { withErrorBoundary } from "lib/hoc";
import StyledTitle from "app/orders/modules/generic/style/style";
import { FieldSetQty } from "./modalStyle";

const FulfillModel = (props) => {
  const {
    active,
    bulkFulfillItem,
    isEnabledData,
    loading,
    orderLineItemsData,
    refetchOrderFulfillment,
    refetchOrderLineView,
    refetchOrderView,
    selectedItems,
    setActive,
    setBanner,
    setMessage,
    setSelectedItems,
  } = props;

  const [filteredItems, setFilteredItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [fulfillmentTypes, setFulfillmentTypes] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [trackingCompany, setTrackingCompany] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [isTrackingCompany, setIsTrackingCompany] = useState(false);
  const [isTrackingNumber, setIsTrackingNumber] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [selectedFulfillmentType, setSelectedFulfillmentType] = useState("");
  const [note, setNote] = useState(true);
  const { cms, history } = useContext(PrivateContext);
  const { gql, fulfillmentTypes: fulfillments, WARNING } = constant;
  const { BULK_ORDER_FULFILL } = gql;
  useEffect(() => {
    if (selectedItems && active) {
      const arr = [];
      const arrVendor = [];
      selectedItems.forEach((element) => {
        const items = orderLineItemsData.find(
          (item) =>
            item._id === element && item.status === constant.ACCEPTED && item.fulfillmentType !== constant.RESERVE
        );
        if (items) {
          const { fulfillableQuantity, vendor, vendorId } = items;
          arr.push({ ...items, totalQuantity: fulfillableQuantity });
          const find = arrVendor.find((item) => item.value === vendorId);
          if (!find) {
            arrVendor.push({ label: vendor, value: vendorId });
          }
          if (items.trackingCompany) {
            setInitialTrackingCompany(items.trackingCompany);
            setIsTrackingCompany(true);
          } else setInitialTrackingCompany("");
          if (items.trackingNumber) {
            setInitialTrackingNumber(items.trackingNumber);
            setIsTrackingNumber(true);
          } else setInitialTrackingNumber("");
        }
      });
      if (arrVendor.length === 1) {
        setSelectedVendor(arrVendor[0].value);
      }
      setFilteredItems(arr);
      setVendors(arrVendor);
    }
  }, [active]);

  useEffect(() => {
    if (selectedVendor) {
      const arrFulfillment = [];
      filteredItems
        .filter((item) => item.vendorId === selectedVendor.toString())
        .forEach((value) => {
          const find = arrFulfillment.includes(value.fulfillmentType);
          if (!find) {
            arrFulfillment.push(value.fulfillmentType);
          }
          if (value.trackingCompany) {
            setInitialTrackingCompany(value.trackingCompany);
          } else setInitialTrackingCompany("");
          if (value.trackingNumber) {
            setInitialTrackingNumber(value.trackingNumber);
          } else setInitialTrackingNumber("");
        });
      if (arrFulfillment.length === 1) {
        setSelectedFulfillmentType(arrFulfillment[0] || constant.DROPSHIP);
      }
      setFulfillmentTypes(arrFulfillment);
    }
  }, [selectedVendor, setSelectedVendor]);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleVendorSelect = useCallback((value) => {
    setFulfillmentTypes([]);
    setSelectedFulfillmentType("");
    setTrackingCompany("");
    setTrackingNumber("");
    setSelectedVendor(value);
  }, []);
  const handleFulfillmentSelect = useCallback((value) => {
    setTrackingCompany("");
    setTrackingNumber("");
    setSelectedFulfillmentType(value);
  }, []);

  const handleQuantityChange = (id, value) => {
    const items = filteredItems;
    const index = items.findIndex((item) => item._id === id);
    items[index] = { ...items[index], fulfillableQuantity: value };
    setFilteredItems([...items]);
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const buttonDisable = () => {
    let count = 0;
    const items = filteredItems.filter(
      (item) =>
        item.vendorId === selectedVendor &&
        (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
        item.status === constant.ACCEPTED
    );
    items.forEach((item) => {
      const { fulfillableQuantity } = item;
      if (parseInt(fulfillableQuantity, 10) === constant.value.ZERO || fulfillableQuantity === "") {
        count += 1;
      }
    });
    return count === items.length;
  };

  const resetAllState = () => {
    setNote(true);
    setFilteredItems([]);
    setVendors([]);
    setFulfillmentTypes([]);
    setSelectedVendor("");
    setTrackingCompany("");
    setTrackingNumber("");
    setSelectedFulfillmentType("");
  };

  const handleFulfillment = async () => {
    if (!(isEnabledData || initialTrackingCompany || trackingCompany)) {
      handleChange();
      resetAllState();
      setBanner({ isOpen: true, title: cms("message.error.trackingCompany"), status: constant.CRITICAL });
      return false;
    }

    if (!(isEnabledData || initialTrackingNumber || trackingNumber)) {
      handleChange();
      resetAllState();
      setBanner({ isOpen: true, title: cms("message.error.trackingNumber"), status: constant.CRITICAL });
      return false;
    }
    const bulkFulfillment = {
      bulkQuantity: [],
      fulfillmentType: selectedFulfillmentType.toString(),
      trackingCompany: initialTrackingCompany.toString() || trackingCompany.toString(),
      trackingNumber: initialTrackingNumber.toString() || trackingNumber.toString(),
    };

    filteredItems
      .filter(
        (item) =>
          item.vendorId === selectedVendor &&
          (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
          item.status === constant.ACCEPTED &&
          parseInt(item.fulfillableQuantity, 10) !== constant.value.ZERO &&
          item.fulfillableQuantity !== ""
      )
      .forEach((item) => {
        const { _id, fulfillableQuantity, orderId } = item;
        bulkFulfillment.orderId = orderId.toString();
        bulkFulfillment.bulkQuantity.push({
          id: _id,
          quantity: parseInt(fulfillableQuantity, 10),
        });
      });
    if (!selectedItems) {
      bulkFulfillItem.isSingle = true;
    }
    await bulkFulfillItem({ variables: { input: { ...bulkFulfillment } } })
      .then((response) => {
        setActive(!active);
        resetAllState();
        setSelectedItems([]);
        const responseError = baseHelper.getResponseError(response.data, BULK_ORDER_FULFILL);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }
        const responseData = baseHelper.getResponseData(response.data, BULK_ORDER_FULFILL);
        if (responseData) {
          setMessage(cms("message.bulkFulfill"));
          setBanner({ isOpen: false, status: constant.CRITICAL, title: responseError });
          setTimeout(() => {
            refetchOrderLineView();
            refetchOrderView();
            refetchOrderFulfillment();
          }, 2000);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const validInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };
  return (
    <Modal
      open={active}
      onClose={() => {
        handleChange();
        resetAllState();
      }}
      title={cms("label.bulkFulfillment")}
      primaryAction={{
        content: cms("label.saveDetails"),
        onAction: () => {
          handleFulfillment();
        },
        disabled: buttonDisable() || loading,
        loading,
      }}
    >
      {vendors && vendors.length > 1 && (
        <>
          {note && (
            <Banner
              icon={AlertMinor}
              onDismiss={() => {
                setNote(false);
              }}
            >
              <p>
                {" "}
                <b>{`${cms("modal.banner.title")} `}</b>
                {`${cms("modal.banner.description")}`}
              </p>
            </Banner>
          )}
          <Modal.Section>
            <Select
              label={cms("label.selectVendor")}
              options={vendors}
              onChange={handleVendorSelect}
              value={selectedVendor}
              placeholder={cms("label.selectVendor")}
            />
          </Modal.Section>
        </>
      )}
      {selectedVendor && fulfillmentTypes && fulfillmentTypes.length > 1 && (
        <Modal.Section>
          <Select
            label={cms("label.fulfillmentType")}
            options={fulfillments.map((fulfillmentType) => {
              fulfillmentType.disabled = !fulfillmentTypes.includes(fulfillmentType.value);
              return fulfillmentType;
            })}
            onChange={handleFulfillmentSelect}
            value={selectedFulfillmentType}
            placeholder={cms("label.fulfillmentType")}
          />
        </Modal.Section>
      )}
      {selectedVendor && selectedFulfillmentType && (
        <ResourceList
          items={filteredItems.filter(
            (item) =>
              item.vendorId === selectedVendor &&
              (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
              item.status === constant.ACCEPTED
          )}
          renderItem={(item) => {
            const {
              _id,
              fulfillableQuantity,
              fulfillmentType,
              image,
              name,
              productId,
              status,
              totalQuantity,
              variantTitle,
            } = item || {};

            const bulkFulfillBadgeStatus = {
              pickup: constant.CONSIGNMENT_PICKUP_CAPITAL,
              consignment: constant.CONSIGNMENT_SHIP_CAPITAL,
            };

            const partial = totalQuantity - fulfillableQuantity;
            const lineImage =
              imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

            const badgeStatus =
              bulkFulfillBadgeStatus[baseHelper.getBadgeStatus(fulfillmentType)] ||
              baseHelper.getBadgeStatus(fulfillmentType);
            return (
              <Modal.Section>
                <StyledCard>
                  <Stack id={_id} wrap={false}>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      <StyledTitle>
                        <Link onClick={() => history.push(`/products/edit/${productId}`)}>{name || ""}</Link>
                        {variantTitle && (
                          <Caption>
                            <TextStyle variation={constant.SUBDUED}>{variantTitle.toUpperCase()}</TextStyle>
                          </Caption>
                        )}
                      </StyledTitle>
                      <Stack.Item>
                        {fulfillmentType && (
                          <Badge className="text-capitalize">
                            {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                          </Badge>
                        )}
                        {partial > 0 && <Badge status={constant.SUCCESS}>Partially fulfill</Badge>}
                      </Stack.Item>
                    </Stack.Item>
                    <FieldSetQty>
                      <Stack wrap={false}>
                        <Stack.Item>
                          <TextField
                            id={_id}
                            value={(fulfillableQuantity && fulfillableQuantity.toString()) || ""}
                            min={0}
                            max={totalQuantity}
                            onChange={(value) => [
                              value <= parseInt(totalQuantity, 10) &&
                                handleQuantityChange(_id, acceptOnlyValidInput(value, fulfillableQuantity)),
                            ]}
                            suffix={`of ${totalQuantity}`}
                          />
                        </Stack.Item>
                      </Stack>
                    </FieldSetQty>
                  </Stack>
                </StyledCard>
              </Modal.Section>
            );
          }}
        />
      )}
      {selectedVendor && selectedFulfillmentType && (
        <>
          <StyledModal>
            <Modal.Section>
              {filteredItems.filter((item) => item.totalQuantity - item.fulfillableQuantity > 0).length > 0 && (
                <Banner status={WARNING} title={cms("modal.banner.partial")} />
              )}
              <TextField
                label={cms("label.company")}
                value={initialTrackingCompany || trackingCompany}
                disabled={isTrackingCompany}
                onChange={(val) => {
                  setTrackingCompany(validInput(val));
                }}
              />
              <br />
              <TextField
                label={cms("label.trackingNumber")}
                value={initialTrackingNumber || trackingNumber}
                disabled={isTrackingNumber}
                onChange={(val) => {
                  setTrackingNumber(validInput(val));
                }}
              />
            </Modal.Section>
          </StyledModal>
        </>
      )}
    </Modal>
  );
};

export default withErrorBoundary(FulfillModel);
