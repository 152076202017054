import { CustomerPlusMajor, AddProductMajor, PaymentsMajor, ExportMinor, InviteMinor } from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/invitation": [
      {
        caption: cms("common.actions.invitation.caption.manage"),
        label: cms("common.actions.invitation.label.manage"),
        link: "/providers/add",
        icon: InviteMinor,
      },
    ],
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.export"),
        id: "exportOrder",
        label: cms("common.actions.orders.label.export"),
        link: "/orders",
        icon: ExportMinor,
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.export"),
        id: "exportPayment",
        label: cms("common.actions.payments.label.export"),
        link: "/payments",
        icon: ExportMinor,
      },
      {
        caption: cms("common.actions.payments.caption.autoPayouts"),
        id: "automaticPayouts",
        label: cms("common.actions.payments.label.autoPayouts"),
        link: "/payouts",
        icon: PaymentsMajor,
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.add"),
        id: "addProduct",
        label: cms("common.actions.products.label.add"),
        link: "/products/add",
        icon: AddProductMajor,
      },
    ],
    "/providers": [
      {
        caption: cms("common.actions.vendors.caption.add"),
        id: "addProvider",
        label: cms("common.actions.vendors.label.add"),
        link: "/providers/add",
        icon: CustomerPlusMajor,
      },
    ],
  };
};

export default config;
