import React, { createRef, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";

import { withErrorBoundary, withFeature } from "lib/hoc";

import { chatHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const Chat = () => {
  const container = createRef();

  const [state, setState] = useState(false);

  useEffect(() => {
    if (window.talkSession && !state) {
      const inbox = chatHelper.createTalkJSInbox();
      inbox.mount(container.current);
      setState(true);
    }
  }, [container, state]);
  return (
    <Layout.Section>
      <div className="chatbox-container" ref={container} />
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(Chat), {
  feature: constant.VENDOR_CHAT,
});
