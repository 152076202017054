import React, { useContext } from "react";

import OrderTaxRate from "app/setup/modules/operator/features/tax/orderTaxRate";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator } = userKey;

const ManageOrderTax = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OrderTaxRate />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const TaxComponent = getComponent(currentUserRole);
  return TaxComponent;
};

export default withErrorBoundary(ManageOrderTax);
