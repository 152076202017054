import { gql } from "apollo-boost";

const CHAT_SETTING = gql`
  mutation updateSellerChatSetting($input: UpdateSellerChatSetting!) {
    updateSellerChatSetting(input: $input) {
      data
      status
      error
    }
  }
`;

export default CHAT_SETTING;
