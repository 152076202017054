import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Card,
  DatePicker,
  FormLayout,
  Popover,
  // PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import mutations
import { ADD_PRODUCT_INVENTORY } from "app/product/apollo/mutations";

//  import queries
import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/product/apollo/queries";
import { GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";

// import data
import editData from "app/product/modules/operator/features/edit/config/editData";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";
// import { TagMapTable } from "app/setup/modules/operator/features/productTagSetting/style";
// import Table from "app/product/modules/generic/features/form/components/table";

const EditInventory = (props) => {
  const { isVariant } = props;

  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { match, cms, currentUser } = useContext(PrivateContext);
  const { gql, SHOPIFY = "", INVENTORY_MANAGEMENT, CRITICAL } = constant;
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [skuData, setSkuData] = useState("");
  const [quantityData, setQuantityData] = useState("");
  const [barcodeData, setBarcodeData] = useState("");
  const [inventoryData, setInventoryData] = useState("");
  // const [metaFieldData, setMetaFieldData] = useState([]);
  // const [sheetContent, setSheetContent] = useState("");
  const [productFormSetting, setProductFormSetting] = useState();
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [isCompaign, setIsCompaign] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [isProductList, setIsProductList] = useState(false);

  const [dates, setDates] = useState([""]);
  const [value, setValue] = useState({});
  setDescription(cms("label.editInventory"));
  setTitle(cms("label.title.inventory"));
  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.SKU];
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );
  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  const { loading: loadingProductForm, data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  const { loading: loadingProductListing, data: dataProductList } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        setSkuData(productLabel.sku);
        setBarcodeData(productLabel.barcode);
        setInventoryData(productLabel.inventory);
        setQuantityData(productLabel.quantity);
        setProductFormSetting(productLabel);
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  // useEffect(() => {
  //   if (value) {
  //     const { metafield = [] } = value;
  //     if (metafield && metafield.length) {
  //       const getMetaData = metafield.map((meta) => {
  //         const val = baseHelper.isValidJSON(meta.value) ? JSON.parse(meta.value) : meta.value;
  //         return {
  //           location: meta.key,
  //           price: val.price,
  //           comparePrice: val.comparePrice,
  //           quantity: val.quantity,
  //         };
  //       });

  //       setMetaFieldData(getMetaData);
  //     }
  //   }
  // }, [value]);

  useEffect(() => {
    if (dataProductList) {
      const productListResponse = baseHelper.getResponseData(dataProductList, "getProductListingSetting");
      if (productListResponse) {
        const { isEnabled = {} } = productListResponse;
        if (isEnabled) {
          setIsProductList(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductList]);

  const acceptOnlyValidInput = (val) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && value.quantity) || 0;
  };
  const acceptOnlyValidInputListing = (val) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && value.listingQuantity) || 0;
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  const primaryAction = {
    content: cms("label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("label.cancel"),
    onAction: () => setSheetActive(false),
  };

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      const { sellerProduct } = productResponse || {};
      const { compaign, listingQuantity } = sellerProduct || {};
      setIsCompaign(!!(compaign && listingQuantity));
      setValue(sellerProduct);
    }
  }, [gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  const [addProductInventory, { loading: addInventoryLoading }] = useMutation(ADD_PRODUCT_INVENTORY);

  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productListError });
    }
  }, [CRITICAL, cms, productListError, setBanner]);

  if (productLoading || loadingProductForm || loadingProductListing) {
    return <SkeletonCard />;
  }

  const onFormSubmit = async () => {
    const formValues = {
      productId: value.productId,
      sku: value.sku,
      barcode: value.barcode,
      inventoryManagement: value.inventoryManagement || "",
      compaign: value.compaign,
    };
    formValues.quantity = parseInt(value.quantity, 10);
    formValues.listingQuantity = parseInt(value.listingQuantity, 10);

    const dataToCheck = {
      quantity: formValues.quantity,
      sku: value.sku,
      barcode: value.barcode,
      inventory: value.inventoryManagement,
    };

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        if (!dataToCheck.inventory && key === 'quantity') {
          return;
        }
        if (key === 'inventory') {
          return;
        }
        const valueData = dataToCheck[key];
        if (!valueData && constant.INVENTORY_KEY.includes(key)) {
          errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
        }
      });
    }

    if (errorField) {
      const banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }

    await addProductInventory({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.ADD_PRODUCT_INVENTORY);
        const responseData = baseHelper.getResponseData(res.data, gql.ADD_PRODUCT_INVENTORY);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.inventory"));
          setBanner({ isOpen: false, status: "", title: "" });
          setTimeout(() => {
            handleTabChange(2);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const disableDate = (newDate) => {
    if (newDate.getDate() === new Date().getDate()) {
      newDate.setDate(new Date().getDate() - 1);
    } else {
      newDate.setDate(new Date());
    }
    return newDate;
  };

  const handleDurationChange = (fieldName, val) => {
    const updateData = value[fieldName] || "";
    const newVal = val ? val.split(":") : [];
    const newDate = updateData ? new Date(updateData) : new Date();
    newDate.setHours(newVal[0]);
    newDate.setMinutes(newVal[1]);
    // updateData = moment(newDate).format("YYYY-MM-DD HH:mm");
    setDates(newDate);
    setValue({
      ...value,
      [fieldName]: newDate,
    });
    setDisabledButton(false);
  };

  const handleDateChange = (fieldName, val) => {
    let updateData = value[fieldName] || "";
    val.setHours(0);
    val.setMinutes(0);
    updateData = val ? moment(val).format("YYYY-MM-DD") : "";
    setDates([...updateData]);
    setValue({
      ...value,
      [fieldName]: updateData,
    });
    setDisabledButton(false);
  };

  const verifyValues = () => {
    const formValues = {
      productId: value.productId,
      sku: value.sku,
      barcode: value.barcode,
      inventoryManagement: value.inventoryManagement,
    };

    formValues.quantity = parseInt(value.quantity, 10);

    const dataToCheck = {
      quantity: formValues.quantity,
      sku: value.sku,
      barcode: value.barcode,
      inventory: value.inventoryManagement,
    };

    if (!dataToCheck.inventory) {
      delete dataToCheck.quantity;
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        if (!dataToCheck.inventory && key === 'quantity') {
          return;
        }
        if (key === 'inventory') {
          return;
        }
        const valueData = dataToCheck[key];
        if (!valueData && constant.INVENTORY_KEY.includes(key)) {
          errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
        }
      });
    }

    if (errorField) {
      const banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
    } else {
      setBanner({ isOpen: false, status: "", title: "" });
      handleTabChange(2);
    }
  };

  const required = (labelValue) => {
    if (labelValue && labelValue.isRequired) {
      return "*";
    }
    return "";
  };

  const label = (val, defaultCms) => {
    if (val && val.label) {
      return val.label;
    }
    return defaultCms;
  };

  return (
    <>
      <Card
        title={[
          cms("label.organisation"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productOrganisation"
        // actions={[
        //   {
        //     content: "Learn more",
        //     onAction: () => {
        //       learnMore("productOrganisation", cms("label.organisation"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.caption.organization")}</p>
          <br />
          <FormLayout>
            {!isVariant && [
              <FormLayout.Group>
                <TextField
                  id="sku"
                  label={`${label(skuData, cms("label.sku"))} ${required(skuData)}`}
                  value={(value && value.sku) || ""}
                  onChange={(val) => {
                    handleChange(constant.SKU, val);
                    setDisabledButton(false);
                  }}
                  maxLength={16}
                />
                <TextField
                  id="barcode"
                  label={`${label(barcodeData, cms("label.barcode"))} ${required(barcodeData)}`}
                  value={(value && value.barcode) || ""}
                  onChange={(val) => {
                    handleChange(constant.BARCODE, val);
                    setDisabledButton(false);
                  }}
                />
              </FormLayout.Group>,
              <FormLayout.Group>
                <Select
                  id="policySelection"
                  label={`${label(inventoryData, cms("label.inventory"))} ${required(inventoryData)}`}
                  options={editData(cms).policyOption}
                  value={(value && value.inventoryManagement) || ""}
                  onChange={(val) => {
                    handleChange(INVENTORY_MANAGEMENT, val);
                    setDisabledButton(false);
                  }}
                />
                {value && value.inventoryManagement === constant.SHOPIFY ? (
                  <TextField
                    id="inventoryQuantity"
                    disabled={value.inventoryManagement !== SHOPIFY}
                    label={`${label(quantityData, cms("label.quantity"))} ${required(quantityData)}`}
                    value={(value && value.quantity && value.quantity.toString()) || ""}
                    min={0}
                    maxLength={10}
                    onChange={(val) => {
                      handleChange(constant.QUANTITY, acceptOnlyValidInput(val));
                      setDisabledButton(false);
                    }}
                  />
                ) : (
                  ""
                )}
              </FormLayout.Group>,
            ]}
            {/* {!!(metaFieldData && metaFieldData.length) && (
              <TagMapTable className="tag-map-table">
                <Table cms={cms} currentUser={currentUser} data={metaFieldData} value={value} />
              </TagMapTable>
            )} */}
            {isProductList && (
              <>
                <Stack distribution="fillEvenly">
                  <Stack.Item>
                      <Popover
                      active={activeCalendar}
                      activator={
                        <TextField
                          // value={item}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          value={value.compaign ? moment(value.compaign).format("YYYY-MM-DD") : ""}
                          label="Campaign End Date"
                          // disabled={isCompaign}
                          // eslint-disable-next-line no-shadow
                          // onChange={(value) => handelChange(index, value.start)}
                          placeholder="YYYY-MM-DD"
                          onFocus={() => setActiveCalendar(true)}
                          // disabled={isCompaign}
                        />
                      }
                      onClose={() => setActiveCalendar(false)}
                    >
                      {activeCalendar && (
                        <Card.Section>
                          <DatePicker
                            month={month}
                            year={year}
                            // eslint-disable-next-line no-shadow
                            onChange={(value) => [handleDateChange("compaign", value.start), setActiveCalendar(false)]}
                            onMonthChange={handleMonthChange}
                            selected={selectedDates}
                            allowRange={false}
                            disableDatesBefore={disableDate(new Date())}
                          />
                        </Card.Section>
                      )}
                    </Popover>
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="select-time">
                      <Select
                        label="Select time"
                        id="selectDuration"
                        // disabled={!value.compaign}
                        placeholder={cms("placeholder.time")}
                        options={baseHelper.getTime() || []}
                        value={value.compaign ? moment(value.compaign).format("HH:mm") : ""}
                        onChange={(val) => handleDurationChange("compaign", val)}
                        // disabled={isCompaign}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
                <br />
                <TextField
                  id="inventoryListQuantity"
                  label="Campaign Sales Goal Minimum Threshold Units"
                  helpText="This is the minimum number of units that must be sold prior to the end date for the goal for pre-orders to be accepted and the product to proceed to production."
                  value={(value && value.listingQuantity && value.listingQuantity.toString()) || ""}
                  min={0}
                  placeholder="Quantity"
                  // disabled={isCompaign}
                  maxLength={10}
                  onChange={(val) => {
                    handleChange("listingQuantity", acceptOnlyValidInputListing(val));
                    setDisabledButton(false);
                  }}
                  // disabled={isCompaign}
                />
              </>
            )}
            <div className="toast">
              <Toast message={message} />
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button onClick={() => handleTabChange(0)}>{cms("button.previous")}</Button>
        </Stack.Item>
        {value && value.status && value.status.toLowerCase() === constant.NEW && (
          <Stack.Item>
            <Button
              onClick={() => {
                verifyValues();
              }}
            >
              {cms("button.skip")}
            </Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button primary onClick={() => onFormSubmit()} disabled={disabledButton} loading={addInventoryLoading}>
            {cms("button.save")}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content: cms("button.save"),
          onAction: () => onFormSubmit(),
          loading: addInventoryLoading,
          disabled: disabledButton,
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => {
              handleTabChange(0);
            },
          },
        ]}
      /> */}
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};
export default EditInventory;
