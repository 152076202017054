import PropTypes from "prop-types";

const customerProp = {
  type: {
    data: PropTypes.object,
  },
};
const fulfillmentsProp = {
  type: {
    lineData: PropTypes.array,
  },
};
const fulfilledLineItemsProps = {
  type: {
    lineData: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]).isRequired,
    data: PropTypes.object,
  },
};
const fullfillmentStatusProp = {
  type: {
    lineData: PropTypes.arrayOf.object,
    data: PropTypes.object,
  },
};
const unfulfilledLineItemsProps = {
  type: {
    lineData: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]).isRequired,
  },
};
export { fulfillmentsProp, fulfilledLineItemsProps, fullfillmentStatusProp, customerProp, unfulfilledLineItemsProps };
