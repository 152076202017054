import React, { useContext, useState, useEffect } from "react";
import { Banner, Button, Link, Select, Stack } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import { useQuery } from "react-apollo";

import { GET_SHIPPY_PRO_CARRIER, GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";

const CarrierService = () => {
  const { cms } = useContext(PrivateContext);
  const { dropship, setDropship, setSubmitEnable, setIsError } = useContext(FulfillmentContext);

  const [shippyProList, setShippyProList] = useState();
  const [vendorList, setVedorList] = useState();

  const [carrierService, setCarrierService] = useState(
    (dropship && dropship.shippyPro && dropship.shippyPro.vendorCarrier) || []
  );

  const [defaultCarrier, setDefaultCarrier] = useState(
    (dropship && dropship.shippyPro && dropship.shippyPro.defaultCarrier) || {}
  );
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const { error: shippyProError, loading: shippyProLoading, data: shippyProData } = useQuery(GET_SHIPPY_PRO_CARRIER);

  useEffect(() => {
    if (shippyProError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(shippyProError) });
    }
    if (!shippyProData) {
      return;
    }
    const shippyProCarrierData = baseHelper.getResponseData(shippyProData, constant.gql.GET_SHIPPY_PRO_CARRIER);
    if (!shippyProCarrierData) {
      return;
    }

    if (shippyProCarrierData) {
      const shippyProDataList = shippyProCarrierData.map((item) => {
        return {
          label: item.label,
          name: item.name,
          value: item.id,
          service: item.service,
        };
      });
      setShippyProList(shippyProDataList);
    }

    const shippyProCarrierError = baseHelper.getResponseError(shippyProData, constant.gql.GET_SHIPPY_PRO_CARRIER);
    if (shippyProCarrierError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: shippyProCarrierError });
    }
  }, [shippyProData, shippyProError]);

  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  useEffect(() => {
    if (vendorAndProductTypesError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(vendorAndProductTypesError) });
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, constant.gql.GET_VENDOR_PRODUCT);
    const { supplierRows = [] } = responseData;

    if (!responseData) {
      return;
    }

    const selectedSuppliers = [];
    if (dropship && dropship.shippyPro && dropship.shippyPro.vendorCarrier && dropship.shippyPro.vendorCarrier.length) {
      dropship.shippyPro.vendorCarrier.map((data) => selectedSuppliers.push(data.vendorId));
    }
    const suppliers = supplierRows.filter((item) => {
      const { _id: id } = item;
      const itemData = item;
      if (itemData.brandName) {
        if (!selectedSuppliers.includes(id)) {
          itemData.disabled = false;
        }
        if (selectedSuppliers.includes(id)) {
          itemData.disabled = true;
        }
        return itemData;
      }
      return null;
    });

    const formattedSuppliers = suppliers
      .filter((item) => item.brandName && item)
      .map(({ _id: vendorId, brandName, disabled }) => ({
        label: brandName,
        value: vendorId,
        disabled,
      }));

    setVedorList(formattedSuppliers);
  }, [dropship, setVedorList, vendorAndProductTypesData, vendorAndProductTypesError, vendorAndProductTypesLoading]);

  const addItem = () => {
    const shippyProItem = [...carrierService];
    if (shippyProItem.length > 0) {
      const item = shippyProItem[shippyProItem.length - 1];
      const keys = Object.keys(item);
      let isInvalid = false;
      keys.forEach(() => {
        if (!item.vendorId || !item.id) {
          isInvalid = true;
        }
      });

      if (isInvalid) {
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
      } else
        shippyProItem.push({
          id: "",
          name: "",
          vendorId: "",
          service: "",
        });
      setCarrierService(shippyProItem);
    }
    if (!shippyProItem.length) {
      setCarrierService([{ id: "", name: "", vendorId: "", service: "" }]);
    }
  };

  const handleChange = (field, value, index) => {
    const oldShippyPro = (dropship && dropship.shippyPro) || {};
    const shippyProItem = [...carrierService];
    shippyProItem[index][field] = value;
    const updateVendorCarrier = [];
    // eslint-disable-next-line no-unused-expressions
    shippyProList &&
      shippyProList.find((item) => {
        // eslint-disable-next-line no-unused-expressions
        carrierService &&
          carrierService.map((element) => {
            if (item.value === element.id && element.vendorId) {
              updateVendorCarrier.push({
                id: item.value,
                name: item.name,
                service: item.service,
                vendorId: element.vendorId,
              });
            }
            if (!element.id || !element.vendorId) {
              setIsError(true);
            } else setIsError(false);
            return null;
          });
        return null;
      });

    setCarrierService([...shippyProItem]);
    setDropship((prevState) => ({
      ...prevState,
      shippyPro: { ...oldShippyPro, vendorCarrier: updateVendorCarrier || carrierService },
    }));
    setSubmitEnable(true);
  };

  const removeItem = (id) => {
    const shippyProItem = [...carrierService];
    const oldShippyPro = (dropship && dropship.shippyPro) || {};
    shippyProItem.splice(id, 1);
    setCarrierService(shippyProItem);
    setDropship((prevState) => ({
      ...prevState,
      shippyPro: { ...oldShippyPro, vendorCarrier: [...shippyProItem] },
    }));
    setSubmitEnable(true);
  };

  const handleDefaultChange = (value) => {
    const oldShippyPro = (dropship && dropship.shippyPro) || {};
    const updateDefaultCarrierData =
      shippyProList && shippyProList.find(({ value: carrierServiceValue }) => carrierServiceValue === value);
    let updateValue = {};
    if (updateDefaultCarrierData) {
      updateValue = {
        name: updateDefaultCarrierData.name,
        id: updateDefaultCarrierData.value,
        service: updateDefaultCarrierData.service,
      };
    }
    setDefaultCarrier((prevState) => ({
      ...prevState,
      id: value,
    }));
    setDropship((prevState) => ({
      ...prevState,
      shippyPro: { ...oldShippyPro, defaultCarrier: updateValue || defaultCarrier },
    }));
    setSubmitEnable(true);
  };

  const selectVendor = (data, id) => {
    return (
      <>
        <br />
        <Stack wrap={false} spacing="extraTight" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={vendorList}
                placeholder={cms("placeholder.vendors")}
                value={carrierService && carrierService.length && carrierService[id].vendorId}
                onChange={(value) => {
                  handleChange(constant.VENDOR_ID, value, id);
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={shippyProList}
                placeholder={cms("placeholder.carrier")}
                value={carrierService && carrierService.length && carrierService[id].id}
                onChange={(value) => handleChange(constant.ACCESS_KEY_ID, value, id)}
              />
            </div>
          </Stack.Item>
          <Button size="plain" onClick={() => removeItem(id)}>
            <i className="far fa-trash fa-lg" style={{ color: constant.color.RED }} />
          </Button>
        </Stack>
      </>
    );
  };

  if (shippyProLoading) {
    return <Spinner size="small" />;
  }
  return (
    <div>
      {banner.isOpen && (
        <>
          <br />
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </>
      )}
      <br />
      <Select
        label={cms("label.carrier")}
        options={shippyProList}
        placeholder={cms("placeholder.carrier")}
        value={(defaultCarrier && defaultCarrier.id) || ""}
        onChange={(value) => handleDefaultChange(value)}
      />
      {carrierService &&
        carrierService.map((value, index) => {
          return selectVendor(value, index);
        })}
      <br />
      <Link id="addLink" onClick={() => addItem()}>
        {cms("button.vendor")}
      </Link>
    </div>
  );
};

export default withErrorBoundary(CarrierService);
