import { gql } from "apollo-boost";

const GET_ORDER_LAKE_LINE_VIEW = gql`
  query getOrderLakeLineView($input: GetOrderLakeInput) {
    getOrderLakeLineView(input: $input) {
      data {
        _id
        price
        appliedDiscounts {
          amount
        }
        accountingStatus
        shippingStatus
        isVendorFulfilled
        baseCostPrice
        basePrice
        baseTotal
        baseWrappingCost
        binPickingNumber
        consignmentId
        cost {
          priceExTax
          priceIncTax
          totalExTax
          totalIncTax
          wrappingExTax
        }
        ecommercePlatform
        eventName
        fixedShippingCost
        fulfillmentType
        isBundledProduct
        isRefunded
        name
        priceExTax
        priceIncTax
        priceTax
        quantity
        quantityShipped
        sku
        storeEbayItemId
        storeEbayTransactionId
        storeEventDate
        storeExternalId
        storeOptionSetId
        storeOrderAddressId
        storeOrderId
        storeParentOrderProductId
        storeLineItemId
        storeReturnId
        storeVariantId
        tax {
          costPrice
          total
        }
        type
        upc
        weight
        wrappingMessage
        wrappingName
        createdBy
        isSynced
        commissionSysAdmin
        commissionSeller
        commissionVendor
        dueDate
        image
        productId
        ownerId
        orderId
        updatedBy
        providerId
        storeStatus
        dispatchedDate
        trackingCompany
        trackingNumber
        createdAt
        updatedAt
        shippingAddress
        shippingAmount
        currency
        brandName
        shippingData {
          _id
          ecommercePlatform
          ownerId
          storeOrderId
          storeShippingAddressId
          addressLineOne
          addressLineTwo
          baseCost
          baseHandlingCost
          city
          company
          cost {
            exTax
            incTax
            handlingExTax
            handlingIncTax
          }
          country
          countryISO2
          createdAt
          createdBy
          email
          firstName
          isSynced
          itemsShipped
          itemsTotal
          lastName
          orderId
          phone
          postcode
          shippingMethod
          shippingZoneName
          storeCostTaxClassId
          storeShippingAddressId
          storeHandlingCostTaxClassId
          storeShippingZoneId
          state
          tax {
            cost
            handlingCost
          }
          updatedAt
          updatedBy
        }
        commissionType
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LAKE_LINE_VIEW;
