import { gql } from "apollo-boost";

const ADD_OFFER = gql`
  mutation createShippingOffer($input: CreateShippingOffer!) {
    createShippingOffer(input: $input) {
      status
      data
      error
    }
  }
`;

const DELETE_OFFER = gql`
  mutation deleteShippingOffer($input: DeleteShippingOffer!) {
    deleteShippingOffer(input: $input) {
      status
      data
      error
    }
  }
`;

const EDIT_OFFER = gql`
  mutation updateShippingOffer($input: UpdateShippingOffer!) {
    updateShippingOffer(input: $input) {
      status
      data
      error
    }
  }
`;
export { ADD_OFFER, DELETE_OFFER, EDIT_OFFER };
