import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  Caption,
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { getNames, getCode } from "country-list";
import { State } from "country-state-city";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_SELLER_FULFILLMENT, GET_VENDOR_PROFILE, GET_VENDOR_PROFILE_SETTING } from "app/vendors/apollo/queries";
import { GET_VENDOR_FORM_LABEL } from "app/advanceVendor/apollo/queries";
import { UPDATE_VENDOR } from "app/vendors/apollo/mutations";

import validate from "./yup";

const EditVendorProfile = () => {
  const { history, setPageData, match, cms, currentUser = {} } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const {
    gql,
    BRAND_HANDLE,
    BRAND_NAME,
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
    VENDOR_TAGGED,
  } = constant;
  const { params } = match;
  const [errorMessage, setErrorMessage] = useState(false);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [values, setValues] = useState({
    brandHandle: "",
    brandHandleValueExist: false,
    brandName: "",
    checked: false,
    city: "",
    country: "",
    email: "",
    firstName: "",
    lastName: "",
    lastUpdateAt: "",
    oldBrandHandle: "",
    oldBrandName: "",
    phoneNumber: "",
    postalCode: "",
    provinceCode: "",
    streetAddress: "",
  });

  const [isShowProvinceCode, setShowProvinceCode] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isVendorTagged, setVendorTagged] = useState(false);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [vendorFormSetting, setVendorFormSetting] = useState({});
  const [brandNameData, setBrandNameData] = useState();
  const [brandHandleData, setBrandHandleData] = useState();
  const [firstNameData, setFirstNameData] = useState();
  const [lastNameData, setLastNameData] = useState();
  const [emailData, setEmailData] = useState();
  const [cityData, setCityData] = useState();
  const [countryData, setCountryData] = useState();
  const [postalCodeData, setPostalCodaData] = useState();
  const [phoneNumberData, setPhoneNumberData] = useState();
  const [streetAddressData, setStreetAddressData] = useState();

  const userRole = (currentUser && currentUser.roles && currentUser.roles.name) || "";

  const [updateVendorProfile, { loading }] = useMutation(UPDATE_VENDOR);
  const { data: vendorProfileData, loading: vendorProfileLoading } = useQuery(GET_VENDOR_PROFILE, {
    variables: { input: { vendorId: params.id } },
  });

  const { data: vendorFormData, error: vendorFormError, loading: vendorProfileSettingLoading } = useQuery(
    GET_VENDOR_PROFILE_SETTING,
    {
      variables: { input: { vendorId: params.id } },
    }
  );
  const currentVendor = baseHelper.getResponseData(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const { vendorRow = {} } = currentVendor;
  const { mobile = {} } = vendorRow;
  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError } = useQuery(
    GET_SELLER_FULFILLMENT
  );
  const { loading: loadingEditVendorForm, data: dataVendorForm } = useQuery(GET_VENDOR_FORM_LABEL);

  useEffect(() => {
    if (dataVendorForm) {
      const vendorLabelData = baseHelper.getResponseData(dataVendorForm, gql.GET_VENDOR_FORM_LABEL);
      if (vendorLabelData) {
        setBrandNameData(vendorLabelData.brandName);
        setBrandHandleData(vendorLabelData.brandHandle);
        setFirstNameData(vendorLabelData.firstName);
        setLastNameData(vendorLabelData.lastName);
        setEmailData(vendorLabelData.email);
        setCityData(vendorLabelData.city);
        setCountryData(vendorLabelData.country);
        setPostalCodaData(vendorLabelData.postalCode);
        setPhoneNumberData(vendorLabelData.phoneNumber);
        setStreetAddressData(vendorLabelData.streetAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorForm]);

  useEffect(() => {
    if (fulfillmentError) {
      setBanner({
        title: fulfillmentError,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
    const sellerFulfillmentresponseData = baseHelper.getResponseData(
      fulfillmentData,
      constant.gql.GET_SELLER_FULFILLMENT
    );
    const showProvinceCode = baseHelper.isShowProvinceCode(sellerFulfillmentresponseData);
    setShowProvinceCode(showProvinceCode);
  }, [fulfillmentData, fulfillmentError]);

  useEffect(() => {
    const bannerTitle = vendorFormError || fulfillmentError;
    if (bannerTitle) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(bannerTitle) });
    }
  }, [fulfillmentError, vendorFormError]);

  useEffect(() => {
    if (vendorFormData && currentVendor) {
      const responseData = baseHelper.getResponseData(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
      const responseError = baseHelper.getResponseError(vendorFormData, gql.FETCH_VENDOR_FORM_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (!responseData) {
        return;
      }

      setVendorTagged(
        (vendorFormData?.getVendorFormSetting?.data?.vendor?.overseasVendor || []).includes(
          currentVendor?.vendorRow?._id
        )
      );
      const { vendorForm = {} } = responseData || {};
      setVendorFormSetting(vendorForm);
    }
  }, [vendorFormData, currentVendor, gql.FETCH_VENDOR_FORM_SETTING]);

  useEffect(
    () => {
      if (currentVendor) {
        const { vendorRow = {} } = currentVendor;
        const {
          address,
          brand,
          brandName,
          city,
          country,
          email = {},
          isReadOnly,
          firstName,
          lastName,
          phoneNumber,
          pinCode,
          provinceCode,
          updatedAt,
        } = vendorRow || {};
        const { slug: brandHandle } = brand || {};
        const { address: emailAddress } = email;

        setValues({
          brandHandle,
          brandHandleValueExist: !!brandHandle,
          brandName,
          checked: isReadOnly,
          city,
          country,
          email: emailAddress,
          firstName,
          lastName,
          lastUpdateAt: updatedAt,
          oldBrandHandle: brandHandle,
          oldBrandName: brandName,
          phoneNumber: (mobile && mobile.number) || phoneNumber,
          postalCode: pinCode,
          provinceCode,
          streetAddress: address,
        });
      }
    },
    [currentVendor],
    vendorFormData
  );

  const handleBrandChange = (value) => {
    const manipulatedBrandHandleValue = baseHelper.generateBrandHandle(value);
    setValues((prevState) => ({
      ...prevState,
      [BRAND_NAME]: value,
      [BRAND_HANDLE]: manipulatedBrandHandleValue,
    }));
  };

  const handleVendorTagged = (field, value) => {
    setVendorTagged(value);
    setSaveDisabled(false);
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleField = (field, value) => {
    const { brandHandleValueExist } = values;
    if (field === BRAND_NAME && !brandHandleValueExist) {
      handleBrandChange(value);
    }
    setSaveDisabled(false);
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetContent, setSheetContent] = useState("");
  // const [sheetTitle, setSheetTitle] = useState("");

  // const learnMore = (title, content) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(content);
  // };

  const handleBrandValidation = async (value) => {
    const { brandHandle } = values;
    const brandError = await validate(BRAND_NAME, value, cms);
    const brandHandleError = await validate(BRAND_HANDLE, brandHandle, cms);

    setErrorMessage((prevState) => ({
      ...prevState,
      [BRAND_NAME]: brandError,
      [BRAND_HANDLE]: brandHandleError,
    }));
  };

  const handleValidation = async (field, value, newLabel = "") => {
    const { brandHandleValueExist } = values;
    if (field === BRAND_NAME && !brandHandleValueExist) {
      handleBrandValidation(value);
    }

    let schema = {};
    const { provinceCode: { isRequired: provinceCodeRequired = false } = {}, postalCode: { min = 2 } = {} } =
      vendorFormSetting || {};
    if (field === constant.PROVINCE_CODE && !provinceCodeRequired) {
      return;
    }
    if (field === constant.POSTAL_CODE) {
      schema = { min };
    }
    const validationError = await validate(field, value, cms, schema, newLabel);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleClick = () => {
    const {
      brandHandle,
      brandName,
      checked,
      city,
      country,
      email,
      firstName,
      lastName,
      oldBrandHandle,
      oldBrandName,
      phoneNumber,
      postalCode,
      provinceCode,
      streetAddress,
    } = values;

    const isFieldEmpty = !(
      brandHandle &&
      brandName &&
      city &&
      country &&
      email &&
      firstName &&
      lastName &&
      phoneNumber &&
      postalCode &&
      // provinceCode &&
      streetAddress
    );

    const {
      brandHandle: brandHandleError,
      brandName: brandNameError,
      city: cityError,
      country: countryError,
      email: emailError,
      firstName: firstNameError,
      lastName: lastNameError,
      phoneNumber: phoneNumberError,
      postalCode: postalCodeError,
      provinceCode: provinceCodeError,
      streetAddress: streetAddressError,
    } = errorMessage;

    const isAnyError =
      brandHandleError ||
      brandNameError ||
      cityError ||
      countryError ||
      emailError ||
      firstNameError ||
      lastNameError ||
      phoneNumberError ||
      postalCodeError ||
      provinceCodeError ||
      streetAddressError;

    if (isFieldEmpty || isAnyError) {
      return;
    }
    updateVendorProfile({
      variables: {
        input: {
          formValues: {
            address: streetAddress,
            brand: { slug: brandHandle },
            brandName,
            city,
            country,
            email,
            firstName,
            id: params.id,
            isOverseas: isVendorTagged,
            isReadOnly: checked,
            lastName,
            phoneNumber: phoneNumber.toString(),
            pinCode: postalCode,
            provinceCode,
            // provinceCode: country === "United States of America" ? provinceCode : null,
          },
        },
      },
    })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PROFILE);
        if (responseError) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: responseError,
          });
          return;
        }
        const isBrandChanged = oldBrandName !== brandName || oldBrandHandle !== brandHandle;
        history.push(`/providers/view/${params.id}`, { profileEdit: true, isBrandChanged });
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  const handleCancel = () => {
    history.push(`/providers/view/${params.id}`);
  };

  useEffect(() => {
    if (!isTitle && values && values.brandName) {
      setIsTitle(true);
      setPageData({
        title: cms("label.providerProfile", { brandName: baseHelper.ucFirst(values.brandName) }),
      });
    }
  }, [setPageData, values, cms, isTitle]);

  if (vendorProfileLoading || vendorProfileSettingLoading || fulfillmentLoading || loadingEditVendorForm) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }
  const getLabelData = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("sectionContent.updateVendorProfile.title")}
        description={cms("sectionContent.updateVendorProfile.description")}
      >
        <Card
          title={[
            cms("sectionContent.updateBrandDetails.title"),
            // actions={[
            //   {
            //     content: cms("sectionContent.updateBrandDetails.learnMore"),
            //     onAction: () => {
            //       learnMore(
            //         cms("sectionContent.updateVendorProfile.title"),
            //         cms("sectionContent.updateBrandDetails.todo")
            //       );
            //     },
            //   },
            // ]}
            values.lastUpdateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(values.lastUpdateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("sectionContent.updateBrandDetails.description")}</TextContainer>
            <br />
            <TextField
              label={`${getLabelData(brandNameData, cms("label.brandName"))}*`}
              value={values.brandName}
              maxLength={255}
              onChange={(value) => handleField(BRAND_NAME, value)}
              onBlur={() =>
                handleValidation(BRAND_NAME, values.brandName, getLabelData(brandNameData, cms("label.brandName")))
              }
              error={errorMessage && errorMessage.brandName}
            />
            <br />
            <TextField
              label={`${getLabelData(brandHandleData, cms("label.brandHandle"))}*`}
              value={values.brandHandle}
              maxLength={255}
              onChange={(value) => handleField(BRAND_HANDLE, value)}
              onBlur={() =>
                handleValidation(
                  BRAND_HANDLE,
                  values.brandHandle,
                  getLabelData(brandHandleData, cms("label.brandHandle"))
                )
              }
              error={errorMessage && errorMessage.brandHandle}
            />
          </Card.Section>
        </Card>
        <Card
          title={cms("sectionContent.updateVendorProfile.title")}
          // actions={[
          //   {
          //     content: cms("sectionContent.updateVendorProfile.learnMore"),
          //     onAction: () => {
          //       learnMore(
          //         cms("sectionContent.updateVendorProfile.title"),
          //         cms("sectionContent.updateVendorProfile.todo")
          //       );
          //     },
          //   },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("sectionContent.updateVendorProfile.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                label={`${getLabelData(firstNameData, cms("label.firstName"))}*`}
                value={values.firstName}
                onChange={(value) => handleField(FIRST_NAME, value)}
                onBlur={() =>
                  handleValidation(FIRST_NAME, values.firstName, getLabelData(firstNameData, cms("label.firstName")))
                }
                error={errorMessage && errorMessage.firstName}
              />

              <TextField
                label={`${getLabelData(lastNameData, cms("label.lastName"))}*`}
                value={values.lastName}
                onChange={(value) => handleField(LAST_NAME, value)}
                onBlur={() =>
                  handleValidation(LAST_NAME, values.lastName, getLabelData(lastNameData, cms("label.lastName")))
                }
                error={errorMessage && errorMessage.lastName}
              />

              <TextField
                label={`${getLabelData(emailData, cms("label.email"))}*`}
                value={values.email}
                onChange={(value) => handleField(EMAIL, value)}
                onBlur={() => handleValidation(EMAIL, values.email, getLabelData(emailData, cms("label.email")))}
                error={errorMessage && errorMessage.email}
              />

              <TextField
                label={`${getLabelData(phoneNumberData, cms("label.phoneNumber"))}*`}
                value={(values.phoneNumber && values.phoneNumber.toString()) || ""}
                onChange={(value) => handleField(PHONE_NUMBER, value)}
                disabled={mobile && mobile.number}
                onBlur={() =>
                  handleValidation(
                    PHONE_NUMBER,
                    values.phoneNumber,
                    getLabelData(phoneNumberData, cms("label.phoneNumber"))
                  )
                }
                error={errorMessage && errorMessage.phoneNumber}
              />

              <TextField
                label={`${getLabelData(streetAddressData, cms("label.streetAddress"))}*`}
                value={values.streetAddress}
                onChange={(value) => handleField(STREET_ADDRESS, value)}
                onBlur={() =>
                  handleValidation(
                    STREET_ADDRESS,
                    values.streetAddress,
                    getLabelData(streetAddressData, cms("label.streetAddress"))
                  )
                }
                error={errorMessage && errorMessage.streetAddress}
              />

              <TextField
                label={`${getLabelData(cityData, cms("label.city"))}*`}
                value={values.city}
                onChange={(value) => handleField(CITY, value)}
                onBlur={() => handleValidation(CITY, values.city, getLabelData(cityData, cms("label.city")))}
                error={errorMessage && errorMessage.city}
              />

              {/* {isShowProvinceCode && (
                <TextField
                  label={`${cms("label.provinceCode")}`}
                  value={values.provinceCode}
                  onChange={(value) => handleField(PROVINCE_CODE, value)}
                  onBlur={() => handleValidation(PROVINCE_CODE, values.provinceCode)}
                  error={errorMessage && errorMessage.provinceCode}
                />
              )} */}

              <Select
                label={`${getLabelData(countryData, cms("label.country"))}*`}
                placeholder={cms("label.country")}
                value={values.country}
                options={getNames().sort()}
                onChange={(value) => handleField(COUNTRY, value)}
                onBlur={() => handleValidation(COUNTRY, values.country)}
                error={errorMessage && errorMessage.country}
              />

              <Select
                label={`${cms("label.provinceCode")}`}
                placeholder={`${cms("label.provinceCode")}`}
                value={values.provinceCode}
                options={State.getStatesOfCountry(getCode(values.country) || "").map((item) => ({
                  label: item.name,
                  value: item.isoCode,
                }))}
                onChange={(value) => handleField(PROVINCE_CODE, value)}
                onBlur={() => handleValidation(PROVINCE_CODE, values.provinceCode)}
                error={errorMessage && errorMessage.provinceCode}
              />

              <TextField
                label={`${getLabelData(postalCodeData, cms("label.postalCode"))}*`}
                value={values.postalCode}
                onChange={(value) => handleField(POSTAL_CODE, value)}
                onBlur={() =>
                  handleValidation(
                    POSTAL_CODE,
                    values.postalCode,
                    getLabelData(postalCodeData, cms("label.postalCode"))
                  )
                }
                error={errorMessage && errorMessage.postalCode}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {!ecommercePlatform && userRole === constant.SELLER && (
          <Card title={cms("sectionContent.overseas.title")}>
            <Card.Section>
              <TextContainer>
                <p>{cms("sectionContent.overseas.description")}</p>
              </TextContainer>
              <br />
              <FormLayout>
                <Checkbox
                  label={cms("common.label.overseas")}
                  checked={isVendorTagged}
                  onChange={(value) => handleVendorTagged(VENDOR_TAGGED, value)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        )}
        <Card title={cms("sectionContent.vendorAccessPermission.title")}>
          <Card.Section>
            <TextContainer>
              <p>{cms("sectionContent.vendorAccessPermission.description.para1")}</p>
              <p>{cms("sectionContent.vendorAccessPermission.description.para2")}</p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("message.updateVendorProfile.readOnly")}
                checked={values.checked}
                onChange={(value) => handleField("checked", value)}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <br />
        <Stack distribution="equalSpacing" spacing="tight">
          <Button onClick={() => handleCancel()}>{cms("common.button.cancel")}</Button>
          <Button primary onClick={() => handleClick()} loading={loading} disabled={isSaveDisabled}>
            {cms("common.button.submit")}
          </Button>
        </Stack>
      </Layout.AnnotatedSection>
      {/* <Sheet
        isOpen={sheetActive}
        title={sheetTitle}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: cms("button.sheet.secondaryAction"), onAction: () => setSheetActive(false) }}
        primaryAction={{ content: cms("button.sheet.primaryAction"), onAction: () => setSheetActive(false) }}
      >
        {sheetContent}
      </Sheet> */}
    </>
  );
};

export default withFeature(withErrorBoundary(EditVendorProfile), { feature: constant.VENDOR_ADD });
