import React from "react";
import { TextField, Select, Stack } from "@shopify/polaris";
import { withErrorBoundary } from "lib/hoc";
import { StackItems50 } from "app/setup/modules/operator/features/productTagSetting/style";
import { getName } from "country-list";

const AddMapperField = (props) => {
  const { cms, updateVal, countryList, country } = props;
  const selectCountry = (data, id) => {
    return (
      <>
        <StackItems50>
          <Stack wrap={false} spacing="extraTight" alignment="center">
            <Stack.Item>
              <div>
                <TextField required placeholder="Location" value={data.seller ? getName(data.seller) : ""} disabled />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div>
                <Select
                  options={countryList}
                  placeholder={cms("placeholder.selectcountry")}
                  value={(data && data.vendor) || ""}
                  onChange={(val) => {
                    updateVal(val, id, data.seller);
                  }}
                />
              </div>
            </Stack.Item>
          </Stack>
        </StackItems50>
      </>
    );
  };
  return (
    <div>
      {country &&
        country.map((value, index) => {
          return selectCountry(value, index);
        })}
    </div>
  );
};
export default withErrorBoundary(AddMapperField);
