import React from "react";
import { Modal, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

function LoginSeller(props) {
  const { brandName, buttonLoading, closeLoginModal, logIn, loginModalOpen, selectedButton, sellerListCMS } = props;
  const sellerData = brandName.split("#");

  const { button, label, sectionContent } = sellerListCMS;
  return (
    <>
      <Modal
        key={label.logInModal}
        open={loginModalOpen}
        onClose={closeLoginModal}
        title={`${label.logInAs} ${baseHelper.ucFirst(sellerData[0])}`}
        primaryAction={{
          content: label.fullAccess,
          onAction: () => logIn(sellerData[1], constant.FULL_ACCESS, sellerData[2]),
          disabled: buttonLoading && selectedButton !== constant.FULL_ACCESS,
          loading: buttonLoading && selectedButton === constant.FULL_ACCESS,
        }}
        secondaryActions={[
          {
            content: button.readOnly,
            onAction: () => logIn(sellerData[1], constant.READ_ONLY_ACCESS, sellerData[2]),
            disabled: buttonLoading && selectedButton !== constant.READ_ONLY_ACCESS,
            loading: buttonLoading && selectedButton === constant.READ_ONLY_ACCESS,
          },
        ]}
        sectioned
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>{sectionContent.logInAsAccess}</Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
}

LoginSeller.defaultProps = {
  loginModalOpen: false,
  brandName: "",
  selectedButton: "",
  buttonLoading: false,
  closeLoginModal: () => {},
  logIn: () => {},
};

LoginSeller.propTypes = {
  loginModalOpen: PropTypes.bool,
  brandName: PropTypes.string,
  selectedButton: PropTypes.string,
  buttonLoading: PropTypes.bool,
  closeLoginModal: PropTypes.func,
  logIn: PropTypes.func,
  sellerListCMS: PropTypes.func.isRequired,
};

export default LoginSeller;
