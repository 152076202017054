import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Caption,
  Card,
  FormLayout,
  Frame,
  Heading,
  Layout,
  Modal,
  PageActions,
  Stack,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import context
import { PrivateContext } from "lib/context";

import { DELETE_USER_DATABASE_SETTING, UPDATE_USER_DATABASE_SETTING } from "app/setup/apollo/mutations";
import { GET_USER_DATABASE_SETTING } from "app/setup/apollo";

import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner, Toast } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import validate from "./yup";

const UserDatabaseSetting = () => {
  const [values, setValues] = useState({ uri: "" });
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [active, setActive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cms, history } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const { data: databaseData, error: dbError, loading: dbLoader, refetch } = useQuery(GET_USER_DATABASE_SETTING);

  const databaseResponse = baseHelper.getResponseData(databaseData, constant.gql.GET_USER_DATABASE_SETTING);
  const databaseError = baseHelper.getResponseError(databaseData, constant.gql.GET_USER_DATABASE_SETTING);

  useEffect(() => {
    if (databaseResponse) {
      setValues({ uri: databaseResponse.uri });
      setUpdatedAt(databaseResponse.updatedAt);
      if (databaseResponse.uri) {
        setIsDelete(true);
      }
      refetch();
      setIsLoading(false);
    }
    if (databaseError) {
      setBanner({ status: constant.CRITICAL, title: databaseError, isOpen: true });
    }
    if (dbError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(dbError), isOpen: true });
    }
  }, [databaseError, databaseResponse, dbError, refetch]);

  const [updateDatabaseSetting, { loading: updateLoading }] = useMutation(UPDATE_USER_DATABASE_SETTING);
  const [deleteDatabaseSetting, { loading: resetLoading }] = useMutation(DELETE_USER_DATABASE_SETTING);

  const handleChange = useCallback((key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value.trim(),
    }));
    setIsLoading(true);
  }, []);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleSubmit = useCallback(
    async (uri, dbUpdatedAt) => {
      setIsLoading(false);
      try {
        const inputData = {
          uri,
        };
        const response = await updateDatabaseSetting({
          variables: { input: inputData },
        });
        const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_USER_DATABASE_SETTING);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }

        const key = dbUpdatedAt ? "message.success.update" : "message.success.save";

        setMessage(cms(key));

        refetch();
      } catch (exception) {
        baseHelper.getResponseError(exception, constant.gql.UPDATE_USER_DATABASE_SETTING);
      }
    },
    [cms, refetch, updateDatabaseSetting]
  );

  const onSubmit = () => {
    const { updatedAt: dbUpdatedAt } = databaseResponse;
    handleSubmit(values.uri, dbUpdatedAt);
  };

  const handleModal = useCallback(async () => {
    try {
      const response = await deleteDatabaseSetting();
      const responseError = baseHelper.getResponseError(response.data, constant.gql.DELETE_USER_DATABASE_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      setMessage(cms("message.success.delete"));
      setErrorMessage("");
      setActive(false);

      refetch();
      setIsDelete(false);
    } catch (exception) {
      baseHelper.getResponseError(exception, constant.gql.DELETE_USER_DATABASE_SETTING);
    }
  }, [deleteDatabaseSetting, cms, refetch]);

  if (dbLoader) {
    return <Spinner />;
  }

  const buttons = [
    {
      content: cms("section.button.cancel"),
      onAction: () => history.push("/setting"),
    },
  ];

  if (isDelete) {
    buttons.push({
      destructive: true,
      content: cms("section.button.delete"),
      onAction: () => setActive(true),
    });
  }

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection
        title={cms("title")}
        description={<TextStyle variation="subdued">{cms("description")}</TextStyle>}
      >
        <div className="toast">
          <Frame>
            <Toast message={message} setToast={setMessage} />
          </Frame>
        </div>
        <Card sectioned>
          <div className="toggle-action">
            <Heading variation="strong">{cms("section.title")}</Heading>
            <br />
            {updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            )}
            <br />
            <TextStyle>{cms("section.description")}</TextStyle>
          </div>
          <br />
          <>
            <FormLayout>
              <TextField
                id="uri"
                value={values.uri || ""}
                onChange={(value) => handleChange("uri", value)}
                // onBlur={() => handleValidation("uri", values.uri)}
                label={`${cms("section.form.uri.label")}*`}
                name="uri"
                error={errorMessage && errorMessage.uri}
                autoComplete="off"
              />
            </FormLayout>
            <PageActions
              primaryAction={{
                content:
                  databaseResponse && databaseResponse.uri ? cms("section.button.update") : cms("section.button.save"),
                onAction: onSubmit,
                disabled: !(
                  values &&
                  isLoading &&
                  values.uri &&
                  !errorMessage.uri &&
                  values.uri !== databaseResponse.uri
                ),
                loading: updateLoading,
              }}
              secondaryActions={buttons}
            />
          </>
        </Card>

        <Modal
          open={active}
          onClose={() => setActive(false)}
          title={cms("section.modal.title")}
          primaryAction={{
            content: cms("section.modal.button.confirm"),
            onAction: () => {
              handleModal();
            },
            loading: resetLoading,
          }}
          secondaryActions={[
            {
              content: cms("section.modal.button.cancel"),
              onAction: () => setActive(false),
            },
          ]}
        >
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <TextContainer>{cms("section.modal.text")}</TextContainer>
              </Stack.Item>
            </Stack>
          </Modal.Section>
        </Modal>

        <Layout.Section />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(UserDatabaseSetting);
