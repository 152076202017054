import React, { useState, useContext, useEffect, useCallback } from "react";
import { Form, Layout } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature } from "lib/hoc";
import baseHelper from "lib/helpers/base";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Sheet } from "lib/components";

// helpers
import { errorHelper } from "lib/helpers";

import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/productOld/apollo/queries";
import { ADD_VARIANT } from "app/productOld/apollo/mutations";
// import provider
import { FormProvider } from "app/productOld/modules/operator/features/variant/context/context";
import validate from "app/productOld/modules/operator/features/variant/subFeatures/yup/validate";
import { manualFormProp } from "app/productOld/modules/operator/features/variant/props";
import { ActionButton, Image, Inventory, Price, Shipping, TitleVariant } from "./subFeatureItems";

const getOptions = (data) => {
  const variantOption = data || "";
  return {
    option1: variantOption.option1 || "Size",
    option2: variantOption.option2 || "Color",
    option3: variantOption.option3 || "Material",
  };
};

const ManualForm = (props) => {
  const { setBanner } = props;
  const { history, match } = useContext(PrivateContext);
  const [value, setValue] = useState({});
  const [values, setValues] = useState({ size: "", color: "", material: "" });
  // eslint-disable-next-line no-unused-vars
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const shopify = constant.SHOPIFY;
  const queryParams = baseHelper.getQueryParams(history ? history.location.search : "");
  const productId = baseHelper.getParam(match, "productId");
  const [errorMessage, setErrorMessage] = useState(false);

  const [resData, setResData] = useState({
    data: true,
    loaded: true,
    loading: false,
    productOptions: getOptions(),
    dismissBanner: false,
    message: [],
    title: "",
    option1: false,
    option2: false,
    option3: false,
    quantity: "",
    quantityDisabled: true,
    isShipping: true,
    isTaxable: true,
    image: "",
    isSelect: false,
    productImages: [],
    vendorId: "",
  });
  const { data: productData, error: productError } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: productId } },
  });
  const listRoute = queryParams && queryParams.dashboard ? "/" : "/variants";
  const [addVariant] = useMutation(ADD_VARIANT);
  useEffect(() => {
    if (productError) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(productError),
        status: "critical",
      });
    }
  }, [productError, setBanner, errorHelper]);
  const setErrorBanner = useCallback(
    (bannerErrorMessage) => {
      setBanner({
        isOpen: true,
        title: bannerErrorMessage,
        status: "critical",
      });
    },
    [setBanner]
  );

  const resDataError = baseHelper.getResponseError(productData, "getProductAndSellerProduct");
  useEffect(() => {
    if (resDataError) {
      setErrorBanner(resDataError);
    }
  }, [resDataError, setErrorBanner]);

  const resDataResponse = baseHelper.getResponseData(productData, "getProductAndSellerProduct");

  useEffect(() => {
    if (resDataResponse) {
      const { sellerProduct } = resDataResponse;
      const { variants, vendorId } = sellerProduct;
      const variant = variants || [];
      const productOptions = getOptions(sellerProduct);
      const option1 = !!variant.option1Val;
      const option2 = !!variant.option2Val;
      const option3 = !!variant.option3Val;
      setResData((preState) => ({
        ...preState,
        loading: false,
        data: sellerProduct,
        vendorId,
        option1,
        option2,
        option3,
        productOptions,
        productImages: sellerProduct.images,
        isTaxable: false,
      }));
    }
  }, [resDataResponse]);

  const primaryAction = {
    content: "Done",
    onAction: () => {
      setSheetActive(false);
    },
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: () => {
      setSheetActive(false);
    },
  };

  if (isFormSubmit) history.push("/");

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = (e) => {
    handleChange(constant.TINYMCE, e.target.getContent());
  };

  const handleChangeVariant = (key, changeVariantValue) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: changeVariantValue,
    }));
  };

  const learnMore = (productItem, title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(`TODO: Add description about ${productItem}`);
  };
  const handleValidation = async (field, handleValidationValue) => {
    const { target } = handleValidationValue;
    const validationError = await validate(field, target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const submitCallBack = async () => {
    const {
      image,
      price,
      comparePrice,
      sku,
      barcode,
      policySelection,
      inventoryQuantity,
      shipping,
      weight,
      selectWeight,
      variant,
    } = value;
    const option1 = (variant && variant.length && variant[0].option1) || constant.SIZE;
    const option2 = (variant && variant.length && variant[0].option2) || constant.COLOR;
    const option3 = (variant && variant.length && variant[0].option3) || constant.MATERIAL;

    const formValues = {
      comparePrice,
      price,
      barcode,
      inventoryManagement: policySelection === shopify ? shopify : null,
      sku,
      productId,
      // shippingId,
      // seller,
      inventoryQuantity,
      isTaxable: true,
      isShipping: !!shipping,
      weight,
      weightUnit: selectWeight || constant.LB,
      fulfillmentService: constant.MANUAL,
      image,
    };
    if (option1) formValues.option1Val = values.size;
    if (option2) formValues.option2Val = values.color;
    if (option3) formValues.option3Val = values.material;

    try {
      const res = await addVariant({
        variables: {
          input: formValues,
        },
      });
      const responseData = baseHelper.getResponseData(res.data, "addvariant");
      if (responseData) {
        history.push(`/variants/edit/${productId}/${responseData.data.position}`, { addVariant: true });
        setBanner({
          isOpen: true,
          title: "Add variant successfully",
          status: "success",
        });
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(exception),
        status: "critical",
      });
    }
  };
  const onFormSubmit = () => {
    submitCallBack();
  };
  if (resData.loading) {
    return null;
  }

  return (
    <>
      <Layout.AnnotatedSection title="Add Variant">
        <Form onSubmit={onFormSubmit}>
          <FormProvider value={{ errorMessage, handleChange, handleValidation, handleTiny, data: value, learnMore }}>
            <Layout.Section>
              <TitleVariant values={values} handleChangeVariant={handleChangeVariant} resData={resData} />
              <Image />
              <Price />
              <Inventory />
              <Shipping />
            </Layout.Section>
          </FormProvider>
        </Form>
      </Layout.AnnotatedSection>
      <Layout.Section>
        <ActionButton
          submit
          history={history}
          id="submitButton"
          isLoading={value.loaded || false}
          backUrl={listRoute}
          onSubmit={submitCallBack}
        />
      </Layout.Section>
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

ManualForm.propTypes = manualFormProp.type;

export default withFeature(ManualForm, { feature: constant.PRODUCT_USING_FORM });
