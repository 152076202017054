import React, { useState, useEffect } from "react";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";
import { withErrorBoundary } from "lib/hoc";

// import component
import { Banner } from "lib/components";

import { baseHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const Shipping = (props) => {
  const { handleChange = () => {}, variantData = {}, handleTitleChange = () => {}, cms, setDisabledButton } = props;
  const { weight = "", weightUnit: variantWeightUnit = "", isShipping: shipping } = variantData || {};
  const { weightValue } = constant;
  const [isShipping, setIsShipping] = useState(weight || shipping);
  const [weightUnit, setWeightUnit] = useState(variantWeightUnit);

  useEffect(() => {
    setIsShipping(weight || shipping);
    setWeightUnit(variantWeightUnit || weight ? constant.weightValue.KG : "");
  }, [weight, shipping, variantWeightUnit]);

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && weight);
  };

  const selectWeight = (
    <Select
      id="selectWeight"
      value={weightUnit || ""}
      label={cms("section.shipping.label.select")}
      placeholder={cms("section.shipping.placeholder.select")}
      onChange={(value) => handleChange(constant.WEIGHT_UNIT, value)}
      labelHidden
      options={[weightValue.KG]}
    />
  );

  return (
    <Card title={cms("section.shipping.title")} id="variantShipping">
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label={cms("section.shipping.label.physical")}
          checked={isShipping}
          onChange={(value) => {
            handleTitleChange(constant.IS_SHIPPING, value);
            setDisabledButton(false);
            if (!value) {
              handleTitleChange(constant.WEIGHT, null);
              handleTitleChange(constant.WEIGHT_UNIT, constant.weightValue.KG);
            }
          }}
        />
        {!isShipping && (
          <div>
            <br />
            <Banner isOpen status={constant.INFO} isScrollTop={false}>
              <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {isShipping && (
        <Card.Section title={cms("section.measurement.title")}>
          <p>{cms("section.measurement.description")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.shipping.label.weight")}
            value={(weight && weight.toString()) || ""}
            placeholder={cms("section.shipping.placeholder.weight")}
            disabled={!weightUnit}
            onChange={(value) => {
              handleChange(constant.WEIGHT, acceptOnlyValidInput(value));
              setDisabledButton(false);
            }}
            connectedRight={selectWeight}
          />
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default withErrorBoundary(Shipping);
