import { gql } from "apollo-boost";

const GET_NOTIFICATION_SETTING = gql`
  query getNotificationSetting {
    getNotificationSetting {
      status
      data {
        labels
        userId
      }
      error
    }
  }
`;
export default GET_NOTIFICATION_SETTING;
