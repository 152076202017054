import React, { useContext, useCallback, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";
import { ProductProvider } from "app/productLake/modules/generic/context";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import Hoc
import { withErrorBoundary } from "lib/hoc";

// import prop
import { tabProp } from "app/productLake/modules/generic/propTypes";
import addData, { getPanelComponent } from "app/productLake/modules/operator/features/add/config/addData";

const ProductTabs = (props) => {
  const { setBanner } = props;
  const { cms, currentUser } = useContext(PrivateContext);
  const { aws = {}, isAttachmentVisible = false } = currentUser || {};
  const { bucket = "", path = "", id = "", secret = "" } = aws || {};
  const [selected, setSelected] = useState(0);
  const validProductTabs = addData(cms).tabs;
  if (!isAttachmentVisible && bucket && path && id && secret) {
    validProductTabs.splice(-1);
  }
  const tabLength = validProductTabs.length;

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  return (
    <>
      <Card>
        <Tabs tabs={validProductTabs} selected={selected} setBanner={setBanner} />
      </Card>
      <ProductProvider
        value={{
          setBanner,
          handleTabChange,
        }}
      >
        <div className="productComponent">{getPanelComponent(selected, cms, tabLength)}</div>
      </ProductProvider>
    </>
  );
};

ProductTabs.propTypes = tabProp.type;

export default withErrorBoundary(ProductTabs);
