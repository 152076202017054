import React, { useContext } from "react";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import OperatorPackingSlipAccess from "app/setup/modules/operator/features/packingSlip/packingSlip";

const { userKey } = constant;
const { operator } = userKey;
const PackingSlipSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorPackingSlipAccess />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const OrderSettingComponent = getComponent(currentUserRole);
  return OrderSettingComponent;
};

export default withFeature(withErrorBoundary(PackingSlipSetting), {
  feature: constant.ORDER_PACKING_SLIP,
});
