import React, { useContext } from "react";
import { MediaCard, List, Caption } from "@shopify/polaris";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const Finish = () => {
  const { currentUserRefetch, cms = {}, setIsOnboarding } = useContext(PrivateContext);
  return (
    <MediaCard
      title={cms("common.onboarding.finish.title")}
      primaryAction={{
        id: "MC_PORTAL_ONBOARDING_STEP_06",
        content: cms("common.onboarding.finish.button.primary"),
        button: "primary",
        onAction: () => {
          storageHelper.remove("setupProgress");
          currentUserRefetch();
          setIsOnboarding({ cms: "dashboard", module: "dashboard" });
        },
      }}
      secondaryAction={{
        content: cms("common.onboarding.finish.button.secondary"),
        url: "https://meeting.marketcube.io/meetings/marketcube/white-glove-assisted-setup",
        external: true,
        onAction: () => {},
      }}
      description={[
        <>
          <p>{cms("common.onboarding.finish.description")}</p>
          <br />
          <p>{cms("common.onboarding.finish.nextStep")}</p>
          <br />
          <p>
            <List type="number">
              {cms("common.onboarding.finish.list") &&
                cms("common.onboarding.finish.list").map((list) => <List.Item>{list}</List.Item>)}
              <List.Item>
                {cms("common.onboarding.finish.bookSession")}
                <sup>*</sup>
              </List.Item>
            </List>
          </p>
          <br />
          <p>
            <Caption>
              <sup>*</sup>
              {cms("common.onboarding.finish.paragraph")}
            </Caption>
          </p>
          <br />
          <p>
            {/* <!-- Start of Meetings Embed Script --> */}
            {/* <div class="meetings-iframe-container" data-src="https://meeting.marketcube.io/meetings/marketcube/white-glove-assisted-setup?embed=true"></div>
            <script type="text/javascript" 
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script> */}
            {/* <!-- End of Meetings Embed Script --> */}
          </p>
        </>,
      ]}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src="https://ik.imagekit.io/marketcube/platform/welcome.jpg?tr=w-800"
      />
    </MediaCard>
  );
};

export default Finish;
