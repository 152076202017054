import React from "react";
import { baseHelper } from "lib/helpers";
import { Tooltip } from "@shopify/polaris";

const InvoiceHeader = (props) => {
  const {
    invoiceSetting,
    vendorName,
    vendorAddress,
    customerAddress,
    invoiceNumberPrefix,
    dueDate,
    orderLineItems,
    createdAt,
    shopifyOrderId,
    cms,
    vat,
  } = props;
  return (
    <div className="invoice-header">
      {!invoiceSetting.purchaseFrom.isHide && (
        <div className="invoice-from">
          <small className="title text-uppercase">{invoiceSetting.purchaseFrom.label}</small>
          <address>
            <strong className="text-inverse capitalize">{vendorName.toLowerCase()}</strong>
            <br />
            {vendorAddress.address}
            <br />
            {vendorAddress.city}
            <br />
            {vendorAddress.country}
            {vendorAddress.pinCode ? `, ${vendorAddress.pinCode}` : ""}
          </address>
        </div>
      )}
      {customerAddress && !invoiceSetting.deliverTo.isHide && (
        <>
          <div className="invoice-from">
            <small className="title text-uppercase">{invoiceSetting.deliverTo.label}</small>
            <address>
              <strong className="text-inverse capitalize">{customerAddress.name || ""}</strong>
              <br />
              {`${customerAddress.address1 || ""}  ${customerAddress.address2 || ""}`}
              <br />
              {customerAddress.city || ""}
              <br />
              {customerAddress.country || ""}
              {customerAddress.zip ? `, ${customerAddress.zip}` : ""}
              <br />
              {customerAddress.phone || ""}
            </address>
          </div>
        </>
      )}

      <div className="invoice-date">
        <small className="title text-uppercase">{`${cms("label.invoice")}`}</small>
        <div className="date text-inverse">{`${baseHelper.formatDate(createdAt, false)}`}</div>
        <div className="invoice-detail">
          {`${cms("label.invoiceNumber")}    
              ${invoiceNumberPrefix || shopifyOrderId}`}
        </div>
        {vat && (
          <div className="invoice-detail">
            {`${cms("label.vat")} #: `}
            {vat}
          </div>
        )}
        {dueDate && (
          <div className="invoice-detail">
            {`${cms("label.taxPoint")}: `}
            {`${baseHelper.dayFirstDateFormat(dueDate)}`}
          </div>
        )}
        <div className="invoice-detail">
          {`${cms("label.status")}: `}
          {baseHelper.getOrderAccountingStatus(orderLineItems)}
        </div>
      </div>
    </div>
  );
};

const LineItemList = (props) => {
  const { lineitemNumber, invoiceSetting, isSku, item, isDiscount } = props;
  return (
    <tr className="line-remove">
      <th style={{ textAlign: "left" }}>{lineitemNumber}</th>
      {isSku && !invoiceSetting.sku.isHide && <td />}
      <td />
      <td />
      {!invoiceSetting.tax.isHide && <td />}
      {isDiscount && !invoiceSetting?.discount?.isHide && <td />}
      {!invoiceSetting.subtotal.isHide && <th style={{ textAlign: "right" }}>{`${item.accountingStatus}`}</th>}
    </tr>
  );
};

const ProductItem = (props) => {
  const {
    invoiceSetting,
    isSku,
    item,
    isDiscount,
    moneyFormat,
    getItemPrice,
    getItemPriceTax,
    isOrderBasedCommission,
    getSubtotalAmount,
    isTaxInclusive,
    addGstPstText,
    getDynamicDiscount,
    isDynamicDiscount,
  } = props;

  let totalWithDiscount = parseFloat(getSubtotalAmount(item));
  if (isOrderBasedCommission && isDynamicDiscount) {
    totalWithDiscount += parseFloat(getDynamicDiscount(item));
  }
  return (
    <tr>
      <td style={{ textAlign: "left" }}>{`${item.name}`}</td>
      {isSku && !invoiceSetting.sku.isHide && <td>{`${item.sku}`}</td>}
      <td>{`${item.actualQuantity || 0}`}</td>
      <td>{`${moneyFormat}${baseHelper.getPrice(getItemPrice(item))}`}</td>
      {!isOrderBasedCommission && isTaxInclusive && !invoiceSetting.tax.isHide ? (
        <td>{`*${moneyFormat}${baseHelper.getPrice(getItemPriceTax(item))}`}</td>
      ) : !invoiceSetting.tax.isHide && isOrderBasedCommission ? (
        <td>{addGstPstText(item)}</td>
      ) : (
        <td>-</td>
      )}
      {isDiscount && !invoiceSetting?.discount?.isHide && (
        <td>{`${moneyFormat}${baseHelper.getPrice(item.vendorDiscount)}`}</td>
      )}
      {!invoiceSetting.subtotal.isHide && <td>{`${moneyFormat}${baseHelper.getPrice(totalWithDiscount)}`}</td>}
    </tr>
  );
};

const ShippingItem = (props) => {
  const {
    invoiceSetting,
    isSku,
    item,
    isDiscount,
    moneyFormat,
    isShippingTax,
    isOrderBasedCommission,
    showShippingTax,
    getTotalShipping,
    shippingTaxType,
  } = props;
  return (
    <tr className="line-remove">
      {}
      <td style={{ textAlign: "left" }}>{invoiceSetting.shipping.label}</td>
      {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
      <td>-</td>
      {!item.shippingAmount ? <td>-</td> : <td>{`${moneyFormat}${baseHelper.getPrice(item.shippingAmount)}`}</td>}
      {!isOrderBasedCommission && isShippingTax && !invoiceSetting.tax.isHide && !!item.shippingTax ? (
        <td>
          {`${moneyFormat}${baseHelper.getPrice(item.shippingTax)}`}
          {shippingTaxType === "percentage"
            ? `(${baseHelper.getPercentage(item.shippingTax, item.shippingAmount)}%)`
            : ""}
        </td>
      ) : (
        !invoiceSetting.tax.isHide && (
          <td>
            {showShippingTax(item) ? `${moneyFormat}${baseHelper.getPrice(parseFloat(showShippingTax(item)))}` : "-"}
          </td>
        )
      )}
      {isDiscount && !invoiceSetting?.discount?.isHide && <td>-</td>}
      {!invoiceSetting.subtotal.isHide && !!getTotalShipping(item) ? (
        <td>{`${moneyFormat}${baseHelper.getPrice(getTotalShipping(item) + parseFloat(showShippingTax(item)))}`}</td>
      ) : (
        <td>0.00</td>
      )}
    </tr>
  );
};

const ProductTotal = (props) => {
  const {
    invoiceSetting,
    isSku,
    item,
    isDiscount,
    moneyFormat,
    getItemPriceTax,
    isTaxInclusive,
    getTotalShipping,
    shopifyLineItemShippingAmount,
    isOrderBasedCommission,
    getTotalAmount,
    getItemPrice,
    getDynamicDiscount,
    isDynamicDiscount,
    shopifyShipping,
    transactionFeeLinesData,
    cms,
  } = props;
  let subTotalAmount = getTotalShipping(item);
  subTotalAmount += parseFloat(getTotalAmount(item));
  let infoIcon = false;
  if (isOrderBasedCommission) {
    if (isDynamicDiscount) {
      subTotalAmount += parseFloat(getDynamicDiscount(item));
    }
    if (transactionFeeLinesData && !shopifyShipping.amount && shopifyShipping.term === "flat") {
      subTotalAmount -= item.shippingAmount;
      infoIcon = true;
    }
  }
  let shippingTaxCal = baseHelper.getPrice(getItemPriceTax(item) + item.shippingTax) || "0.00";
  if (isOrderBasedCommission) {
    shippingTaxCal = baseHelper.getPrice(getItemPriceTax(item)) || "0.00";
  }
  return (
    <tr>
      <th>{invoiceSetting.totalAmount.label}</th>
      {isSku && !invoiceSetting.sku.isHide && <td />}
      <td />
      <th>
        {`${moneyFormat}${baseHelper.getPrice(
          getItemPrice(item) * item.actualQuantity + shopifyLineItemShippingAmount(item)
        )}`}
      </th>

      {!isTaxInclusive || isOrderBasedCommission
        ? !invoiceSetting.tax.isHide && <th>{`${moneyFormat}${baseHelper.getPrice(shippingTaxCal) || "0.00"}`}</th>
        : !invoiceSetting.tax.isHide && <th>{`${moneyFormat}${baseHelper.getPrice(item.shippingTax) || "0.00"}`}</th>}
      {isDiscount && !invoiceSetting?.discount?.isHide && (
        <th>{`${moneyFormat}${baseHelper.getPrice(item.vendorDiscount * item.actualQuantity)}`}</th>
      )}
      {!invoiceSetting.subtotal.isHide && infoIcon && (
        <th>
          <Tooltip
            content={
              cms("label.tooltipHelpText") ? cms("label.tooltipHelpText") : "Marketplace managed shipping is not added"
            }
            style={{ display: "inlineBlock" }}
          >
            {`${moneyFormat}${baseHelper.getPrice(subTotalAmount)}`}
            <span>#</span>
          </Tooltip>
        </th>
      )}

      {!invoiceSetting.subtotal.isHide && !infoIcon && (
        <th>{`${moneyFormat}${baseHelper.getPrice(subTotalAmount)}`}</th>
      )}
    </tr>
  );
};

const OperatorCommission = (props) => {
  const { invoiceSetting, isSku, label, isDiscount } = props;
  return (
    <tr className="invoice-deduction">
      <td style={{ textAlign: "left" }}>{label}</td>
      {isSku && !invoiceSetting.sku.isHide && <td />}
      <td />
      <td />
      {!invoiceSetting.tax.isHide && <td />}
      {isDiscount && !invoiceSetting.discount.isHide && <td />}
      {!invoiceSetting.subtotal.isHide && <td />}
    </tr>
  );
};

const DynamicDiscount = (props) => {
  const {
    invoiceSetting,
    isSku,
    isDiscount,
    shopifyDiscount,
    dynamicDiscount,
    getDynamicDiscount,
    moneyFormat,
    item,
  } = props;
  if (!item.discountAllocations.length) {
    return null;
  }
  return (
    <tr>
      <td style={{ textAlign: "left" }}>{`${dynamicDiscount} (${shopifyDiscount})`}</td>
      {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
      <td>-</td>
      <td>-</td>
      {!invoiceSetting.tax.isHide && <td>-</td>}
      {isDiscount && !invoiceSetting.discount.isHide && <td>-</td>}
      {!invoiceSetting.subtotal.isHide && <td>{`${moneyFormat}${baseHelper.getPrice(getDynamicDiscount(item))}`}</td>}
    </tr>
  );
};

const OperatorCommissionDetails = (props) => {
  const {
    invoiceSetting,
    isSku,
    item,
    isDiscount,
    moneyFormat,
    isOrderBasedCommission,
    commissionType,
    getOperatorCommissionTax,
    getTotalOperatorCommission,
    commissionTaxType,
    commissionTaxRate,
  } = props;
  return (
    <tr>
      <td style={{ textAlign: "left" }}>{invoiceSetting.sellerCommission.label}</td>
      {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
      <td>-</td>
      {item.commissionSeller !== 0 ? (
        <td>
          {`${moneyFormat}${baseHelper.getPrice(item.commissionSeller)}`}
          {commissionType === "percentage" ? `(${baseHelper.getPrice(item.commissionSetting.rate)}%)` : ""}
        </td>
      ) : (
        <td>-</td>
      )}
      {!isOrderBasedCommission && !invoiceSetting.tax.isHide && item.priceTaxRate && !item.commissionTax ? (
        <td>{`*${moneyFormat}${baseHelper.getPrice(getOperatorCommissionTax(item))}`}</td>
      ) : !invoiceSetting.tax.isHide && !!item.commissionTax ? (
        <td>
          {`${moneyFormat}${baseHelper.getPrice(item.commissionTax)}`}
          {commissionTaxType === "percentage" ? `(${baseHelper.getPrice(commissionTaxRate)}%)` : ""}
        </td>
      ) : (
        <td>-</td>
      )}
      {isDiscount && !invoiceSetting.discount.isHide && <td>-</td>}
      {!invoiceSetting.subtotal.isHide && !!getTotalOperatorCommission(item) ? (
        <td>{`${moneyFormat}${baseHelper.getPrice(getTotalOperatorCommission(item))}`}</td>
      ) : (
        <td>0.00</td>
      )}
    </tr>
  );
};

const TotalDeduction = (props) => {
  const {
    invoiceSetting,
    isSku,
    item,
    isDiscount,
    moneyFormat,
    getDeductionTotal,
    totalLineTaxNoShipping,
    transactionFeeTotal,
    showShippingTaxDeduction,
    getTotalDeduction,
  } = props;

  return (
    <tr>
      <th style={{ textAlign: "left" }}>{invoiceSetting.totalDeduction.label}</th>
      {isSku && !invoiceSetting.sku.isHide && <td />}
      <td />
      {getDeductionTotal(item) != 0 || transactionFeeTotal || totalLineTaxNoShipping ? (
        <th>{`${moneyFormat}${baseHelper.getPrice(
          getDeductionTotal(item) + totalLineTaxNoShipping + transactionFeeTotal
        )}`}</th>
      ) : (
        <th>0.00</th>
      )}
      {!invoiceSetting.tax.isHide && !!item.commissionTax ? (
        <th>{`${moneyFormat}${baseHelper.getPrice(
          item.commissionTax + parseFloat(showShippingTaxDeduction(item))
        )}`}</th>
      ) : (
        <th>{`${moneyFormat}${baseHelper.getPrice(parseFloat(showShippingTaxDeduction(item)) || "0.00")}`}</th>
      )}
      {isDiscount && !invoiceSetting.discount.isHide && <td />}
      {!invoiceSetting?.subtotal?.isHide &&
      (!!getTotalDeduction(item) || totalLineTaxNoShipping || transactionFeeTotal) ? (
        <th>
          {`${moneyFormat}${baseHelper.getPrice(
            getTotalDeduction(item) + totalLineTaxNoShipping + transactionFeeTotal
          )}`}
        </th>
      ) : (
        <td>0.00</td>
      )}
    </tr>
  );
};

const DeductionsShipping = (props) => {
  const {
    isSku,
    showShipping,
    invoiceSetting,
    orderLineItem,
    vendorLineCount,
    showShippingTax,
    shopifyShipping,
    vendorProductCount,
    moneyFormat,
    isDiscount,
    isThirdPartyShipping,
  } = props || {};

  const { shippingAmount = 0, thirdPartyShippingAmount = 0, requiresShipping = true } = orderLineItem;
  if (requiresShipping === "false") {
    return null;
  }
  if (!showShipping) {
    return null;
  }
  let shippingAmountShow = 0;
  let totalShippingWithTax = 0;
  if (isThirdPartyShipping && thirdPartyShippingAmount) {
    shippingAmountShow = baseHelper.getPrice(parseFloat(thirdPartyShippingAmount)) || 0;
    totalShippingWithTax = parseFloat(shippingAmountShow);
  } else {
    shippingAmountShow = baseHelper.getPrice(parseFloat(shopifyShipping.amount) / vendorLineCount) || 0;
    totalShippingWithTax = parseFloat(shippingAmountShow);
    shippingAmountShow = parseFloat(shippingAmountShow / vendorProductCount) - shippingAmount || 0;
    totalShippingWithTax = parseFloat(totalShippingWithTax / vendorProductCount) - shippingAmount || 0;
  }

  totalShippingWithTax += parseFloat(showShippingTax(orderLineItem));

  return (
    <tr className="line-remove">
      <td style={{ textAlign: "left" }}>{invoiceSetting.shipping.label}</td>
      {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
      <td>-</td>
      <td>{`${moneyFormat}${baseHelper.getPrice(shippingAmountShow)}`}</td>
      <td>{`${moneyFormat}${showShippingTax(orderLineItem)}`}</td>
      {isDiscount && !invoiceSetting?.discount?.isHide && <td>-</td>}
      <td>{`${moneyFormat}${baseHelper.getPrice(totalShippingWithTax)}`}</td>
    </tr>
  );
};

const TotalLineTotal = (props) => {
  const { invoiceSetting, isSku, item, isDiscount, moneyFormat, getLinesSubTotal, transactionFeeAllTotal } = props;

  return (
    <tr>
      <th style={{ textAlign: "left" }}>{invoiceSetting.orderTotal.label}</th>
      {isSku && !invoiceSetting.sku.isHide && <td />}
      <td />
      <td />
      {!invoiceSetting.tax.isHide && <td />}
      {isDiscount && !invoiceSetting.discount.isHide && <td />}
      {/* {!invoiceSetting.status.isHide && <td />} */}
      {!invoiceSetting?.subtotal?.isHide && (
        <td>
          <b>{`${moneyFormat}${baseHelper.getPrice(getLinesSubTotal(item) - transactionFeeAllTotal)}`}</b>
        </td>
      )}
    </tr>
  );
};

const ColumnData = (props) => {
  const { invoiceSetting, isDiscount, isSku } = props;
  return (
    <tr>
      <th style={{ textAlign: "left" }}>{invoiceSetting.product.label}</th>
      {isSku && !invoiceSetting.sku.isHide && <th>{invoiceSetting.sku.label}</th>}
      <th>{invoiceSetting.quantity.label}</th>
      <th>{invoiceSetting.price.label}</th>
      {!invoiceSetting.tax.isHide && <th>{invoiceSetting.tax.label}</th>}
      {isDiscount && !invoiceSetting?.discount?.isHide && <th>{invoiceSetting?.discount?.label}</th>}
      {!invoiceSetting?.subtotal?.isHide && <th>{invoiceSetting?.subtotal?.label}</th>}
    </tr>
  );
};

const OrderSummary = (props) => {
  const {
    invoiceSetting,
    shippingAmountValue,
    moneyFormat,
    isOrderBasedCommission,
    summaryAmount,
    totalVendorPayout,
    shippingTax,
    totalShippingTax,
    totalSummaryShippingTax,
    totalGst,
    isDynamicDiscount,
    totalDeductionTax,
    totalDiscountValue,
    totalDeductionValue,
    deductionValue,
    vendorProductCount = 1,
  } = props;
  return (
    <div className="invoice-price">
      <b style={{ display: "flex", justifyContent: "center" }}>Order Summary</b>
      <div className="invoice-price-left">
        <div className="invoice-price-row">
          {!invoiceSetting.orderPayout.isHide && (
            <div className="sub-price">
              {/* <small className="label">{invoiceSetting.orderPayout.label}</small>
                <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalOrderPayout)}`}</span> */}
            </div>
          )}
          <div className="sub-price">
            <small className="label">{invoiceSetting.totalOrderAmount.label}</small>
            <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(summaryAmount)}`}</span>
          </div>
          {!invoiceSetting.totalOrderShipping.isHide && isOrderBasedCommission && (
            <div className="sub-price">
              <small className="label"> Tax on Shipping</small>
              <span className="text-inverse">{`${moneyFormat}${
                baseHelper.getPrice(totalSummaryShippingTax) || "0.00"
              }`}</span>
            </div>
          )}
          {!invoiceSetting.totalOrderShipping.isHide && (
            <div className="sub-price">
              <small className="label">{invoiceSetting.totalOrderShipping.label}</small>
              <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(shippingAmountValue)}`}</span>
            </div>
          )}
          {!invoiceSetting.totalOrderTax.isHide && (
            <div className="sub-price">
              <small className="label">{invoiceSetting.totalOrderTax.label}</small>
              <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(
                totalShippingTax + totalGst
              )}`}</span>
            </div>
          )}
          {!invoiceSetting.totalDeductionAmount.isHide && (
            <div className="sub-price">
              <small className="label">{invoiceSetting.totalDeductionAmount.label}</small>
              <span className="text-inverse">
                {isDynamicDiscount
                  ? `${moneyFormat}${baseHelper.getPrice(
                      deductionValue + parseFloat(totalDiscountValue || 0) + parseFloat(totalDeductionValue || 0)
                    )}`
                  : `${moneyFormat}${baseHelper.getPrice(deductionValue + parseFloat(totalDeductionValue || 0))}`}
              </span>
            </div>
          )}
          {!invoiceSetting.totalDeductionTax.isHide && (
            <div className="sub-price">
              <small className="label">{invoiceSetting.totalDeductionTax.label}</small>
              <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalDeductionTax)}`}</span>
            </div>
          )}
        </div>
      </div>
      <hr style={{ borderTop: "0.1rem solid rgb(225, 227, 229, 1)" }} />
      <div className="invoice-price-right">
        <small className="text-upper">{invoiceSetting.totalVendorPayout.label}</small>
        <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalVendorPayout)}`}</span>
      </div>
    </div>
  );
};

const FooterSection = (props) => {
  const {
    invoiceSetting,
    type,
    constant,
    isVendor,
    getVendorShippingAddress,
    fulfillmentData,
    sellerInfo,
    getShippingAddress,
    sellerName,
    sellerAddress,
    sellerTaxInfo,
    cms,
    value,
  } = props;
  return (
    <div className="invoice-footer-content">
      {!invoiceSetting.fulfillBy.isHide && (
        <>
          <div className="note">
            <span className="text-uppercase note-label">{`${invoiceSetting.fulfillBy.label}:`}</span>
            <address>
              {(isVendor && type === constant.CONSIGNMENT && getVendorShippingAddress(fulfillmentData, sellerInfo)) ||
                getShippingAddress(sellerName, sellerAddress)}
              {sellerTaxInfo && sellerTaxInfo.company && (
                <>
                  {`${cms("label.companyNumber")}`}
                  {` ${sellerTaxInfo && sellerTaxInfo.company}`}
                  <br />
                </>
              )}
              {sellerTaxInfo && sellerTaxInfo.customs && (
                <>
                  {`${cms("label.customsNumber")}`}
                  {` ${sellerTaxInfo && sellerTaxInfo.customs}`}
                  <br />
                </>
              )}
            </address>
          </div>
        </>
      )}
      {value && (
        <div className="note">
          <span className="text-uppercase">{`${cms("label.note")}:`}</span>
          <br />
          <span>{`${value}`}</span>
        </div>
      )}
    </div>
  );
};

export {
  ColumnData,
  LineItemList,
  ProductItem,
  ShippingItem,
  ProductTotal,
  OperatorCommission,
  DynamicDiscount,
  OperatorCommissionDetails,
  DeductionsShipping,
  TotalDeduction,
  TotalLineTotal,
  OrderSummary,
  InvoiceHeader,
  FooterSection,
};
