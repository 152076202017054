import React, { useContext } from "react";
import { Layout, Card, Checkbox } from "@shopify/polaris";

// propTypes
import PropTypes from "prop-types";
import { PrivateContext } from "lib/context";

const HideShippingDescription = (props) => {
  const { manageIsHideDescriptionProps } = props;
  const { cms } = useContext(PrivateContext);
  const { isHideDescription, onChange } = manageIsHideDescriptionProps;

  return (
    <Layout.AnnotatedSection description={cms("operator.section.showDescription.description")}>
      <Card sectioned>
        <Checkbox
          checked={!isHideDescription}
          label={cms("operator.section.showDescription.label")}
          helpText={cms("operator.section.showDescription.caption")}
          onChange={onChange}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
};

HideShippingDescription.propTypes = {
  manageIsHideDescriptionProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.bool])).isRequired,
};

export default HideShippingDescription;
