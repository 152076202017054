import { gql } from "apollo-boost";

const DISCONNECT_WIX_SHOP = gql`
  mutation disconnectWixShop($input: DisconnectShop) {
    disconnectWixShop(input: $input) {
      status
      data
      error
    }
  }
`;

const IS_WIX_STORE_CONNECTED = gql`
  mutation isWixStoreConnected {
    isWixStoreConnected {
      status
      data
      error
    }
  }
`;

const SYNC_VENDOR_WIX_PRODUCT = gql`
  mutation syncVendorWixProduct {
    syncVendorWixProduct {
      status
      data
      error
    }
  }
`;

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const UPDATE_WIX_PRODUCT_SYNC = gql`
  mutation updateWixProductSync($input: UpdateProductSync!) {
    updateWixProductSync(input: $input) {
      status
      data
      error
    }
  }
`;

const WIX_CALLBACK = gql`
  mutation saveWixVendorToken($input: SaveWixVendorToken) {
    saveWixVendorToken(input: $input) {
      data
      status
      error
    }
  }
`;

export {
  DISCONNECT_WIX_SHOP,
  IS_WIX_STORE_CONNECTED,
  SYNC_INVENTORY_VENDOR,
  SYNC_VENDOR_WIX_PRODUCT,
  UPDATE_WIX_PRODUCT_SYNC,
  WIX_CALLBACK,
};
