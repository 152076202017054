import React, { useState } from "react";
import { InlineError } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "../../constant/constant";

const FileInput = (props) => {
  const { setBanner = () => {} } = props;
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length === 0) {
      setErrorMessage("");
      return;
    }
    setBanner({ isOpen: false, status: "", title: "" });
    const fileList = Array.from(selectedFiles);
    const [file] = fileList;
    const { name, size } = file;
    const fileSizeInMB = size;
    const extension = name.split(".").pop();
    const isExtensionValid = extension.toLowerCase() === "csv";
    if (!isExtensionValid) {
      setErrorMessage("Please enter a valid csv");
      props.setValue("");
      return;
    }

    const isSizeValid = fileSizeInMB <= constant.value.MAX_CSV_SIZE;
    if (!isSizeValid) {
      setErrorMessage("Please enter a file of size less than 5 MB");
      props.setValue("");
      return;
    }
    setErrorMessage("");

    // read file content
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    const values = {};
    reader.onload = (readerEvent) => {
      values.data = readerEvent.target.result;
      values.fileName = file.name;
      props.setValue(values);
    };
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const getStyleClass = errorMessage && "error";

  return (
    <div className={getStyleClass}>
      <input
        id="fileInput"
        className="fileInput"
        type="file"
        name="Select File"
        onChange={handleChange}
        onClick={handleClick}
      />
      {errorMessage && <InlineError message={errorMessage} fieldID="inputInlineError" />}
    </div>
  );
};

FileInput.defaultProps = {
  setBanner: () => {},
};

FileInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  setBanner: PropTypes.func,
};

export default FileInput;
