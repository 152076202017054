import React from "react";
import PropTypes from "prop-types";
import { Select } from "@shopify/polaris";

import constant from "lib/constant/constant";

const AccountType = (props) => {
  const {
    accountType,
    isAccountTypeDisable,
    handleChange,
    handleValidation,
    errorMessage,
    cms,
    isSeller,
    isVendor,
  } = props;
  const accountTypes = [
    { label: cms("label.seller"), value: "seller", disabled: !isSeller },
    { label: cms("label.vendor"), value: "vendor", disabled: !isVendor },
  ];

  const onAccountChange = (value) => {
    handleChange(constant.ACCOUNT_TYPE, value);
    handleChange(constant.BRAND_NAME, "");
  };

  return (
    <Select
      id="accountType"
      label={`${cms("label.accountType")}*`}
      value={accountType}
      placeholder={cms("label.accountType")}
      onChange={(value) => onAccountChange(value)}
      onBlur={() => handleValidation(constant.ACCOUNT_TYPE, accountType)}
      disabled={isAccountTypeDisable}
      options={accountTypes}
      error={errorMessage && errorMessage.accountType}
    />
  );
};

AccountType.propTypes = {
  accountType: PropTypes.string.isRequired,
  cms: PropTypes.func.isRequired,
  errorMessage: PropTypes.objectOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  isAccountTypeDisable: PropTypes.bool.isRequired,
  isVendor: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AccountType;
