import { gql } from "apollo-boost";

const READY_TO_COLLECT = gql`
  mutation updateCollectDetail($input: CollectDetailInput!) {
    updateCollectDetail(input: $input) {
      status
      error
      data
    }
  }
`;

export default READY_TO_COLLECT;
