import React, { Fragment, useContext } from "react";
import { Layout, Card, Stack, RadioButton } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// constants
import constant from "lib/constant/constant";

const ManageShippingProcess = (props) => {
  const { allowShipping, handleAllowShipping } = props;
  const { cms } = useContext(PrivateContext);

  const allowShippingData = [
    {
      field: constant.RADIO_BUTTON,
      key: constant.GLOBAL,
      label: cms("operator.section.globalShipping.label.global"),
      helpText: cms("operator.section.globalShipping.helpText.globalShipping"),
    },
    {
      field: constant.RADIO_BUTTON,
      key: constant.ADVANCE,
      label: cms("operator.section.globalShipping.label.advance"),
      helpText: cms("operator.section.globalShipping.helpText.advanceShipping"),
    },
  ];

  return (
    <Layout.AnnotatedSection
      title={cms("operator.section.globalShipping.title")}
      description={cms("operator.section.globalShipping.description")}
    >
      <Card sectioned title={cms("operator.section.globalShipping.label.shippingProcess")}>
        <Stack vertical>
          {allowShippingData.map((item) => {
            const { key, field, label: itemLabel, helpText } = item;
            return (
              <Fragment key={key}>
                {field === constant.RADIO_BUTTON && (
                  <RadioButton
                    id={key}
                    key={key}
                    label={itemLabel}
                    helpText={helpText}
                    checked={allowShipping === key}
                    onChange={handleAllowShipping}
                  />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default ManageShippingProcess;
