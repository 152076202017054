import { gql } from "apollo-boost";

const UPDATE_INVOICE_ACCESS_SETTING = gql`
  mutation updateInvoiceAccessSetting($input: UpdateInvoiceAccessSettingInput!) {
    updateInvoiceAccessSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_INVOICE_ACCESS_SETTING;
