import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

//  import components
import { Banner, SkeletonAnnotated, Toast } from "lib/components";
import { NotFound } from "layout/private/components";

// contexts
import { PrivateContext } from "lib/context";
import { ProductContext } from "app/product/modules/generic/context";

// gql
import { useMutation, useQuery } from "react-apollo";
import { UPLOAD_IMAGE, EDIT_SERVICE_VARIANT } from "app/product/apollo/mutations";
import { GET_ADDRESS } from "app/setup/apollo/queries";
import { GET_SELLER_VARIANT } from "app/product/apollo/queries";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import validate from "app/product/modules/operator/features/variant/subFeatures/yup/validate";

// sub feature items
import Availability from "./subFeatureItems/availability";
import { Image, Price, ServiceTitleEdit } from "./subFeatureItems";

const OperatorServiceVariantEdit = () => {
  const { match, history, cms } = useContext(PrivateContext);
  const { state } = history.location;
  const { serviceId } = useContext(ProductContext);

  const { params } = match;
  const { productId = "", id: variantId } = params;
  const { gql } = constant;

  const { OPTION1, OPTION2, OPTION3 } = constant.options; // options state is already declared

  const [message, setMessage] = useState("");
  const [wholesalePrice, setWholesalePrice] = useState("");

  const [endTime, setEndTime] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [maxDuration, setMaxDuration] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [startTime, setStartTime] = useState(constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN);

  const [basePricing, setBasePricing] = useState();
  const [capacity, setCapacity] = useState();
  const [duration, setDuration] = useState();
  const [location, setLocation] = useState();
  const [vendorShopifyProductId, setVendorShopifyProductId] = useState();

  const [disabledButton, setDisabledButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const [isImageErrorDisable, setIsImageErrorDisable] = useState(false);

  const [address, setAddress] = useState([]);
  const [availability, setAvailability] = useState({});
  const [image, setImage] = useState([]);
  const [productImages, setProductImages] = useState([]);

  const [days, setDays] = useState(constant.daysArray);
  const [horus, setHours] = useState(["twentyfour"]);
  const [selectedDays, setSelectedDays] = useState([constant.SEVEN_DAYS]);
  const [selectedHours, setSelectedHours] = useState([constant.TWENTY_FOUR]);

  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });

  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [pricing, setPricing] = useState({
    price: "",
    comparePrice: "",
  });
  const [options, setOptions] = useState({
    option1Val: null,
    option2Val: null,
    option3Val: null,
  });
  const [variantValue, setVariantValue] = useState({});

  const { data: variantData, loading, error } = useQuery(GET_SELLER_VARIANT, {
    variables: { input: { _id: productId } },
  });

  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);
  const [editServiceVariant, { loading: serviceVariantLoading, error: updateVariantError }] = useMutation(
    EDIT_SERVICE_VARIANT
  );

  useEffect(() => {
    if (state && state.addVariant) {
      setBanner({
        action: {
          content: cms("label.action"),
          onAction: () => history.push(`/variants/service/add/${productId}`),
        },
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.create"),
      });
      const newState = state;
      delete newState.addVariant;
      history.replace({ ...history.location, state: newState });
    }
  }, [cms, history, productId, state]);

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, uploadImageError, updateVariantError]);

  useEffect(() => {
    const { option1Val, option2Val, option3Val } = options;
    let isAllValuesFilled = false;
    if (option1Val) {
      isAllValuesFilled = option1Val && variantValue.option1Val;
    }
    if (option2Val) {
      isAllValuesFilled = option2Val && variantValue.option2Val;
    }
    if (option3Val) {
      isAllValuesFilled = option3Val && variantValue.option3Val;
    }

    const isAnyValidationError =
      errorMessage &&
      !!(
        (errorMessage.size && option1Val) ||
        (errorMessage.color && option2Val) ||
        (errorMessage.material && option3Val)
      );

    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [variantValue, errorMessage, options]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT_SELLER_PRODUCT);
    if (responseData) {
      const { sellerProduct = {} } = responseData || {};
      setBasePricing(responseData && responseData.sellerProduct && responseData.sellerProduct.basePricing);
      const { variants = [], images = [] } = sellerProduct || {};

      setVendorShopifyProductId(responseData.sellerProduct.vendorShopifyProductId);

      if (variants.length < variantId || variantId <= 0) {
        return <NotFound />;
      }
      const serviceVariantValue = variants[variantId - 1];
      setOptions({
        option1Val: serviceVariantValue.option1Val,
        option2Val: serviceVariantValue.option2Val,
        option3Val: serviceVariantValue.option3Val,
        option1: serviceVariantValue.option1,
        option2: serviceVariantValue.option2,
        option3: serviceVariantValue.option3,
      });
      setProductImages(images || []);
      setImage((serviceVariantValue.image && [{ url: serviceVariantValue.image }]) || []);
      setVariantValue(serviceVariantValue);
      setPricing({ price: serviceVariantValue.price || 0, comparePrice: serviceVariantValue.comparePrice || 0 });
      setAvailability({
        capacity: serviceVariantValue.availability.capacity || 0,
        duration: serviceVariantValue.availability.duration || 0,
        hour: serviceVariantValue.availability.hour || {},
        locationId: serviceVariantValue.availability.locationId || [],
      });
      if (serviceVariantValue && serviceVariantValue.customInfo) {
        setWholesalePrice(serviceVariantValue.customInfo.wholesalePrice || 0);
      }
    }
  }, [variantData, gql, variantId]);

  useEffect(() => {
    if (availability && availability.hour) {
      const { capacity: capacityData, duration: durationData, hour, locationId } = availability || {};
      const weekDays = Object.keys(hour).filter((item) => hour[item]);
      const availableDays = Object.values(hour).filter((item) => item);
      setDuration(durationData);
      setCapacity(capacityData);
      if (locationId && locationId.length) {
        setLocation(locationId[0]);
      }
      setDays(weekDays);
      if (availableDays.length < 7) {
        setSelectedDays(["custom-days"]);
      }
      if (availableDays.length === 7) {
        setSelectedDays([constant.SEVEN_DAYS]);
      }

      if (availableDays && availableDays.length) {
        const isTwenntyFourHours =
          availableDays[0].open === constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN &&
          availableDays[0].close === constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN;
        setSelectedHours(isTwenntyFourHours ? constant.TWENTY_FOUR : constant.CUSTOM);
        setStartTime(availableDays[0].open * 60);
        setEndTime(availableDays[0].close * 60);
      }
    }
  }, [availability]);

  const { loading: addressLoading, data: addressData } = useQuery(GET_ADDRESS);
  const addressResponse = baseHelper.getResponseData(addressData, gql.GET_ADDRESS);

  useEffect(() => {
    if (addressResponse && addressResponse.addressList) {
      const newData =
        (addressResponse &&
          addressResponse.addressList.map((item) => {
            return { label: item.label, value: item._id };
          })) ||
        [];
      setAddress(newData);
    }
  }, [addressResponse]);

  useEffect(() => {
    if (selectedHours[0] === constant.CUSTOM) {
      setStartTime(constant.TIME_PICKER_START_TIME * 60 * 60);
      setEndTime(constant.TIME_PICKER_START_TIME * 60 * 60);
    } else {
      setMaxDuration(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
    }
  }, [selectedHours]);

  useEffect(() => {
    if (
      errorMessage &&
      (errorMessage[constant.OPTION1] || errorMessage[constant.OPTION2] || errorMessage[constant.OPTION3])
    ) {
      setDisabledButton(true);
    }
  }, [errorMessage]);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  // eslint-disable-next-line consistent-return
  const onSubmitAction = async () => {
    const result = variantValue;
    await handleValidation(OPTION1, result.option1Val);
    await handleValidation(OPTION2, result.option2Val);
    await handleValidation(OPTION3, result.option3Val);

    if (!submitButton.isReadyToSubmit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: constant.SMOOTH,
      });
      return false;
    }

    const { sellerDiscount, markUp } = result || {};
    const weekDays = {};
    const submitStartTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN : startTime / 60;
    const submitEndTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN : endTime / 60;
    const submitDays = selectedDays[0] === constant.SEVEN_DAYS ? constant.daysArray : days;

    // eslint-disable-next-line no-return-assign
    submitDays.map((day) => (weekDays[day] = { open: submitStartTime, close: submitEndTime }));

    const inputData = {
      capacity: parseInt(capacity || 0, 10),
      duration: parseInt(duration || 0, 10),
      hour: weekDays,
      id: parseInt(match.params.id, 10),
      locationId: location ? [location] : [],
      price: parseFloat(pricing.price || 0) || 0,
      comparePrice: parseFloat(pricing.comparePrice || 0),
      markUp: { ...markUp, price: parseFloat((markUp && markUp.price) || 0) } || {
        markUp: { price: 0, type: constant.FLAT },
      },
      discount: { ...sellerDiscount, price: parseFloat((sellerDiscount && sellerDiscount.price) || 0) } || {
        sellerDiscount: { price: 0, type: constant.FLAT },
      },
      productId,
      option1Val: variantValue.option1Val || "",
      option2Val: variantValue.option2Val || "",
      option3Val: variantValue.option3Val || "",
    };
    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      inputData.image = { url: imageUrl };
    }
    const isLocalImageUrl = image && image.upload === constant.LOCAL;
    inputData.serviceId = serviceId;

    if (!isImageError && isLocalImageUrl) {
      await uploadImage({
        variables: { input: { image: image[0], productId: match.params.productId } },
      })
        .then((res) => {
          const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
          if (resData) {
            inputData.image = { url: resData.imageUrl };
          }
        })
        .catch((exception) => {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: errorHelper.parse(exception),
          });
        });
    }
    await editServiceVariant({ variables: { input: inputData } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, constant.EDIT_SERVICE_VARIANT);
        const responseData = baseHelper.getResponseData(res.data, constant.EDIT_SERVICE_VARIANT);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("service.message.success.variant"));
          setBanner({ isOpen: false, status: constant.SUCCESS, title: cms("service.message.success.variant") });
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (loading || addressLoading) {
    return <SkeletonAnnotated />;
  }

  const handleTitleChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
    setIsImageErrorDisable(true);
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldName === constant.WEIGHT_UNIT || fieldValue > -1 || fieldValue.price > -1 || fieldName === constant.SKU) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
      setDisabledButton(false);
    }
  };

  const renderDisabled = () => {
    return !capacity || !duration || (selectedDays[0] === constant.CUSTOM_DAYS && !days.length) || disabledButton;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("service.description")}>
        <ServiceTitleEdit
          cms={cms}
          errorMessage={errorMessage}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          options={options}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <Image
          productImages={productImages}
          setImage={setImage}
          setDisabledButton={setDisabledButton}
          image={image}
          cms={cms}
          isImageError={isImageError}
          setIsImageError={setIsImageError}
          isImageErrorDisable={isImageErrorDisable}
        />
        <Price
          cms={cms}
          variantData={variantValue}
          handleChange={handleChange}
          setDisabledButton={setDisabledButton}
          vendorShopifyProductId={vendorShopifyProductId}
          basePricing={basePricing}
          setWholesalePrice={setWholesalePrice}
          wholesalePrice={wholesalePrice}
          isWholesalePriceVisible={false} // disabled wholesale price form field as not required as of now
        />
        <Availability
          address={address}
          availability={availability}
          capacity={capacity}
          cms={cms}
          duration={duration}
          endTime={endTime}
          horus={horus}
          location={location}
          selected={days}
          selectedDays={selectedDays}
          selectedHours={selectedHours}
          serviceId={serviceId}
          setCapacity={setCapacity}
          setDays={setDays}
          setDisabledButton={setDisabledButton}
          setDuration={setDuration}
          setEndTime={setEndTime}
          setHours={setHours}
          setLocation={setLocation}
          setSelectedDays={setSelectedDays}
          setSelectedHours={setSelectedHours}
          setStartTime={setStartTime}
          startTime={startTime}
          setMaxDuration={setMaxDuration}
          maxDuration={maxDuration}
        />
        <PageActions
          primaryAction={{
            content: "Save",
            onAction: () => onSubmitAction(),
            loading: serviceVariantLoading || imageLoading,
            disabled: renderDisabled(),
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => history.push(`/products/service/edit/${productId}?tab=variant`),
              disabled: serviceVariantLoading || imageLoading,
            },
          ]}
        />
        <Toast message={message} setToast={setMessage} />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorServiceVariantEdit), { feature: constant.PRODUCT_UPDATE });
