import React, { useContext, useEffect } from "react";
import { useMutation } from "react-apollo";
import { TextContainer } from "@shopify/polaris";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { Spinner } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import gql
import { VERIFY_STRIPE_VENDOR } from "app/setup/apollo/mutations";

const StripeReturn = () => {
  const { history, currentUser, isLoading, cms } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: id } = currentUser;
  const [verifyStripeVendor, { loading }] = useMutation(VERIFY_STRIPE_VENDOR);
  const { code, state } = baseHelper.getQueryParams(history.location.search);

  const redirectRoute = "/payment-connect";

  const connect = async () => {
    try {
      console.log("Stripe return callback loading");

      const response = await verifyStripeVendor();

      const responseData = baseHelper.getResponseData(response.data, gql.VERIFY_VENDOR_CUSTOM_STRIPE_ACCOUNT);
      const responseError = baseHelper.getResponseError(response.data, gql.VERIFY_VENDOR_CUSTOM_STRIPE_ACCOUNT);

      if (responseError) {
        history.push(redirectRoute, { isStripeConnected: false, message: responseError });
      }
      if (responseData) {
        history.push(redirectRoute, {
          isStripeConnected: true,
        });
      }
    } catch (exception) {
      history.push(redirectRoute, {
        message: errorHelper.parse(exception),
        isStripeConnected: false,
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, code, history]);

  if (loading || isLoading) {
    return <Spinner fontSize={42} />;
  }

  if (id !== state) {
    return <TextContainer>{cms("common.message.error.notFound")}</TextContainer>;
  }

  return null;
};

export default withErrorBoundary(StripeReturn);
