import { gql } from "apollo-boost";

const UPDATE_TERM = gql`
  mutation setupTerm($input: UpdateTerm) {
    updateTerm(input: $input) {
      data
      status
      error
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { UPDATE_TERM };
