import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

const Inventory = (props) => {
  const {
    variantData = {},
    handleChange = () => {},
    handleTitleChange = () => {},
    cms,
    setDisabledButton,
    productLabelForm = {},
    required = () => {},
  } = props;
  const { barcode: barcodeLabel, inventory: inventoryLabel, quantity: quantityLabel, sku: skuLabel } = productLabelForm;
  const { barcode = null, inventoryManagement = null, inventoryQuantity = 0, sku = "" } = variantData;
  const isDisabled = inventoryManagement !== constant.SHOPIFY;

  const options = [
    { label: cms("section.inventory.label.noTrack"), value: constant.DO_NOT_TRACK_INVENTORY },
    { label: cms("section.inventory.label.shopify"), value: constant.SHOPIFY },
  ];

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && inventoryQuantity) || 0;
  };
  const label = (value, defaultCms) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCms;
  };
  return (
    <Card title={cms("section.inventory.title")} id="variantInventory" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="sku"
            label={`${label(skuLabel, cms("section.inventory.label.sku"))}${required(skuLabel)}`}
            value={sku || ""}
            onChange={(value) => {
              handleChange(constant.SKU, value);
              setDisabledButton(false);
            }}
            maxLength={16}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="barcode"
            label={`${label(barcodeLabel, cms("section.inventory.label.barcode"))}${required(barcodeLabel)}`}
            value={barcode || ""}
            onChange={(value) => {
              handleTitleChange(constant.BARCODE, value);
              setDisabledButton(false);
            }}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            id="policySelection"
            label={`${label(inventoryLabel, cms("section.inventory.label.policy"))}${required(inventoryLabel)}`}
            options={options}
            value={inventoryManagement || ""}
            onChange={(value) => {
              handleTitleChange(constant.INVENTORY_MANAGEMENT, value);
              if (value !== constant.SHOPIFY) {
                handleChange(constant.INVENTORY_QUANTITY, 0);
                setDisabledButton(false);
              }
            }}
          />
        </FormLayout.Group>
        {inventoryManagement === constant.SHOPIFY ? (
          <FormLayout.Group>
            <TextField
              id="inventoryQuantity"
              disabled={isDisabled}
              label={`${label(quantityLabel, cms("section.inventory.label.quantity"))}${required(quantityLabel)}`}
              value={(inventoryQuantity && inventoryQuantity.toString()) || ""}
              min={0}
              onChange={(value) => {
                handleChange(constant.INVENTORY_QUANTITY, acceptOnlyValidInput(value));
                setDisabledButton(false);
              }}
              maxLength={13}
            />
          </FormLayout.Group>
        ) : (
          ""
        )}
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Inventory;
