import React from "react";

import AdminPlanCard from "app/plans/widgets/admin/features/planCard/planCard";
import OperatorPlanCard from "app/plans/widgets/operator/features/planCard/planCard";
import ProviderPlanCard from "app/plans/widgets/provider/features/planCard/planCard";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const PlanCard = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminPlanCard />;
    }
    if (role === operator) {
      return <OperatorPlanCard />;
    }
    if (role === provider) {
      return <ProviderPlanCard />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const PlanCardComponent = getComponent(currentUserRole);
  return PlanCardComponent;
};

export default PlanCard;
