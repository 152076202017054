import React, { useContext } from "react";
import { Card, TextField, Select } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
// import context
import { FormContext } from "app/productOld/modules/operator/features/variant/context/context";

import vendorProductCms from "app/productOld/modules/operator/features/variant/cms/vendorProduct";

const Inventory = () => {
  const { cms = {} } = useContext(PrivateContext);
  const { data, handleChange } = useContext(FormContext);
  const isDisabled = data.policySelection !== constant.SHOPIFY;
  if (data.quantityDisabled !== isDisabled) {
    handleChange(constant.QUANTITY_DISABLED, isDisabled);
  }

  return (
    <Card title="Inventory" id="productInventory" sectioned>
      <Card.Section>
        <TextField
          id="sku"
          label="SKU (Stock Keeping Unit)"
          value={data.sku || ""}
          onChange={(value) => handleChange(constant.SKU, value)}
        />
        <br />
        <TextField
          id="barcode"
          label="Barcode (ISBN, UPC, GTIN, etc.)"
          value={data.barcode || ""}
          onChange={(value) => handleChange(constant.BARCODE, value)}
        />
        <br />
        <Select
          id="policySelection"
          label="Inventory policy"
          options={vendorProductCms.options.policySelection}
          value={data.policySelection || ""}
          onChange={(value) => handleChange(constant.POLICY_SELECTION, value)}
        />
        <br />
        <TextField
          id="inventoryQuantity"
          disabled={isDisabled}
          label={cms.label.quantity}
          type="number"
          value={data.inventoryQuantity || ""}
          onChange={(value) => handleChange(constant.INVENTORY_QUANTITY, Number(value))}
        />
      </Card.Section>
    </Card>
  );
};

export default Inventory;
