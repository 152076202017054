import React from "react";
import PropTypes from "prop-types";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import {
  ManageSetupRule,
  ManagePayment,
  ManageShippingCharge,
  HideShippingDescription,
  ManageAggregateShipping,
  ManageGlobalShipping,
  ShippingByVendor,
} from "./subItems";

const VendorManager = (props) => {
  const {
    manageSetupRuleProps,
    managePaymentProps,
    manageShippingChargeProps,
    manageIsHideDescriptionProps,
    manageAggregateShippingSettingProps,
    setBannerStatus,
    setShippingByVendor,
    shippingByVendor,
    setIsShippingByVendor,
    isShippingByVendor,
    shippingVendors,
    globalSelectedDestinations,
    setGlobalSelectedDestinations,
    globalAllowAllShippingByVendor,
    setGlobalAllowAllShippingByVendor,
    globalVendorShipping,
    setGlobalVendorShipping,
    globalVendorIds,
    setGlobalVendorIds,
    vendors,
    isGlobal,
  } = props;

  return (
    <>
      {!isGlobal && (
        <>
          <ManageSetupRule manageSetupRuleProps={manageSetupRuleProps} />
          <ManagePayment managePaymentProps={managePaymentProps} />
          <ManageShippingCharge manageShippingChargeProps={manageShippingChargeProps} />
          <HideShippingDescription manageIsHideDescriptionProps={manageIsHideDescriptionProps} />
          <ShippingByVendor
            setShippingByVendor={setShippingByVendor}
            shippingByVendor={shippingByVendor}
            setIsShippingByVendor={setIsShippingByVendor}
            isShippingByVendor={isShippingByVendor}
            shippingVendors={shippingVendors}
            vendors={vendors}
          />
          <ManageAggregateShipping
            manageAggregateShippingSettingProps={manageAggregateShippingSettingProps}
            setBannerStatus={setBannerStatus}
          />
        </>
      )}

      {isGlobal && (
        <ManageGlobalShipping
          globalSelectedDestinations={globalSelectedDestinations}
          setGlobalSelectedDestinations={setGlobalSelectedDestinations}
          globalAllowAllShippingByVendor={globalAllowAllShippingByVendor}
          setGlobalAllowAllShippingByVendor={setGlobalAllowAllShippingByVendor}
          globalVendorShipping={globalVendorShipping}
          setGlobalVendorShipping={setGlobalVendorShipping}
          globalVendorIds={globalVendorIds}
          setGlobalVendorIds={setGlobalVendorIds}
          vendors={vendors}
        />
      )}
    </>
  );
};

// export default VendorManager;
export default withFeature(withErrorBoundary(VendorManager), { feature: "vendorManageShipping" });

VendorManager.propTypes = {
  manageAggregateShippingSettingProps: PropTypes.func,
  manageIsHideDescriptionProps: PropTypes.objectOf(PropTypes.shape),
  managePaymentProps: PropTypes.objectOf(PropTypes.shape),
  manageSetupRuleProps: PropTypes.objectOf(PropTypes.shape),
  manageShippingChargeProps: PropTypes.objectOf(PropTypes.shape),
  setBannerStatus: PropTypes.func,
};

VendorManager.defaultProps = {
  manageAggregateShippingSettingProps: () => {},
  manageIsHideDescriptionProps: {},
  managePaymentProps: {},
  manageSetupRuleProps: {},
  manageShippingChargeProps: {},
  setBannerStatus: () => {},
};
