import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import {
  ActionList,
  Button,
  Caption,
  DatePicker,
  Link,
  Modal,
  PageActions,
  Popover,
  RadioButton,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import { useQuery } from "react-apollo";

// import helper
import { baseHelper, errorHelper, statusHelper } from "lib/helpers";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import queries
import { GET_PRODUCT_EXPORT_FILTER } from "app/productOld/apollo/queries";

// import components
import { Banner } from "lib/components";
import List from "./subFeatures/list";

// import props
import { exportProps } from "./props";

const ProviderExportProduct = (props) => {
  const { openModal, setOpenModal, bulkEditproduct = true } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const [loading, setLoading] = useState(false);

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const { createdAt } = currentUser;

  const { data: productExportData, loading: productExportLoading, error } = useQuery(GET_PRODUCT_EXPORT_FILTER);

  const [selected, setSelected] = useState(0);
  const [statusList, setStatusList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([[]]);
  const [submitDate, setSubmitDate] = useState({});
  const [exportedAll, setExportedAll] = useState(true);
  const [exportedLink, setExportedLink] = useState(false);
  const [exportedPDFLink, setExportedPDFLink] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [active, setActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectDate, setSelectDate] = useState();

  const exportProductURL = "/export/product";

  const disableDate = (newDate) => {
    if (newDate.getDate() === new Date(createdAt).getDate()) {
      newDate.setDate(new Date(createdAt).getDate() - 1);
    } else {
      newDate.setDate(new Date(createdAt));
    }
    return newDate;
  };

  const handleStates = () => {
    setExportedLink(null);
    setExportedPDFLink(null);
    setSelectedItems([[]]);
    setSelected(0);
  };
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);
  useEffect(() => {
    if (error) {
      setBanner({ title: errorHelper.parse(error), status: constant.CRITICAL, isOpen: true });
      return;
    }
    if (!productExportLoading && !error && productExportData) {
      const res = baseHelper.getResponseData(productExportData, constant.gql.GET_PRODUCT_EXPORT_FILTER);
      if (!res || !(res.statuses && res.statuses.length)) {
        return;
      }

      const { statuses } = res;

      const getStatus = [];

      statuses.forEach((item) => {
        getStatus.push({
          id: item,
          name: statusHelper.getBadgeStatus(item),
        });
      });

      setStatusList(getStatus);
    }
  }, [productExportData, productExportLoading, error]);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setExportedLink(null);
    setExportedPDFLink(null);
  };

  const onExport = () => {
    const dateRange = selectDate || {};
    let status = [];
    status = [...selectedItems[0]];
    const statusString = status.join(",");

    if (!(exportedAll || statusString)) {
      setLoading(false);
      setBanner({ title: cms("section.export.message.error.select"), status: constant.CRITICAL, isOpen: true });
      return;
    }
    let url = `?startDate=${dateRange.start || ""}&endDate=${dateRange.end || ""}`;
    if (!exportedAll) {
      url = `?status=${statusString}&startDate=${dateRange.start || ""}&endDate=${dateRange.end || ""}`;
    }
    const csvDownloadRoute = `${exportProductURL}${url}`;
    const pdfDownloadRoute = `${exportProductURL}/pdf${url}`;
    setExportedLink(csvDownloadRoute);
    setExportedPDFLink(pdfDownloadRoute);
  };

  const tabs = [{ id: "statuses", content: cms("section.export.label.status"), panelId: "panel-status" }];

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(tabs);
    const singularItem = constant.ITEM;
    const pluralItem = constant.ITEMS;
    const changeIndex = 0;
    selectedItems.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${constant.SELECTED})`;
      }
    });
    return exportModalTabs;
  };

  const items = [statusList];
  const handleSelection = (event) => {
    const prevSelection = [...selectedItems];
    prevSelection[selected] = [...event];
    setSelectedItems(prevSelection);
    setExportedLink(null);
    setExportedPDFLink(null);
    setLoading(false);
  };

  const onCloseModal = () => {
    handleStates();
    setExportedAll(true);
    setOpenModal(false);
    setBanner("");
    setLoading(false);
    setSubmitDate({});
    setSelectDate();
    setIsCalendarOpen(false);
    setIsEnabled(false);
    setActive(false);
    setPopoverActive(false);
  };

  const onDismissBanner = () => {
    setIsEnabled(false);
    setExportedLink(false);
    setBanner({ isOpen: !banner.isOpen });
  };

  const downloadCSV = (link) => {
    window.open(link);
    onCloseModal();
  };

  const downloadPDF = (link) => {
    window.open(link);
    onCloseModal();
  };

  const tab = getResourceListTabs();

  const handleChange = () => {
    setActive(!active);
    setPopoverActive(!popoverActive);
  };

  const activator = (
    <Button onClick={handleChange} disclosure>
      {cms("section.export.label.selectFormat")}
    </Button>
  );
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);

  const handleDatePicker = () => {
    setIsEnabled(banner.isOpen);
    setExportedLink(false);
    setSelectDate({ start: selectDate.start, end: selectDate.end });
    setSubmitDate((prev) => {
      const initialState = { ...prev };
      initialState.start = selectDate.start;
      initialState.end = selectDate.end;
      initialState.startingDate = moment(selectDate.start).format("ll");
      initialState.endingDate = moment(selectDate.end).format("ll");
      initialState.isShowDate = true;
      return initialState;
    });
    setIsCalendarOpen(false);
  };

  const handleCancelDate = () => {
    const initialState = { ...submitDate };
    setSelectDate({
      start: initialState.start || new Date(),
      end: initialState.end || new Date(),
    });
    setIsCalendarOpen(false);
    setIsEnabled(banner.isOpen);
    setExportedLink(false);
  };

  const actionOption = [
    {
      content: cms("section.export.label.exportCSV"),
      onAction: () => {
        setLoading(false);
        downloadCSV(exportedLink);
      },
    },
  ];
  if (bulkEditproduct) {
    actionOption.push({
      content: cms("section.export.label.exportPDF"),
      onAction: () => {
        setLoading(false);
        downloadPDF(exportedPDFLink);
      },
    });
  }
  const handlePopover = () => {
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList items={actionOption} />
      </Popover>
    );
  };

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Link onClick={() => history.push("/setting/product/export")}>{cms("section.export.label.customize")}</Link>
      </Stack.Item>
      <Stack.Item>
        <Button onClick={() => onCloseModal()}>{cms("section.export.label.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        {exportedLink && !isEnabled ? (
          handlePopover()
        ) : (
          <Button primary onClick={() => onExport()} loading={loading} disabled={isEnabled}>
            {cms("section.export.label.generate")}
          </Button>
        )}
      </Stack.Item>
    </Stack>
  );

  return (
    <Modal
      open={openModal}
      onClose={() => onCloseModal()}
      title={cms("section.export.label.title")}
      loading={productExportLoading}
      footer={actions()}
    >
      <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={() => onDismissBanner()} />
      <Modal.Section>
        {isCalendarOpen && (
          <>
            <div>
              <DatePicker
                month={month}
                year={year}
                onChange={(date) => {
                  setSelectDate({ start: date.start, end: date.end });
                }}
                onMonthChange={handleMonthChange}
                selected={selectDate}
                disableDatesAfter={new Date()}
                disableDatesBefore={disableDate(new Date(moment(createdAt).format()))}
                multiMonth
                allowRange
              />
            </div>
            <div>
              <PageActions
                primaryAction={{
                  content: cms("section.export.label.apply"),
                  onClick: () => {
                    handleDatePicker();
                  },
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onClick: () => {
                      handleCancelDate();
                      setIsEnabled(false);
                    },
                  },
                ]}
              />
            </div>
            <br />
          </>
        )}
        {!isCalendarOpen && (
          <Stack>
            <Stack.Item fill>
              <RadioButton
                id="exportAllRadio"
                name={constant.EXPORT}
                label={cms("section.export.label.all")}
                helpText={cms("section.export.helpText.all")}
                checked={exportedAll}
                onChange={() => {
                  setBanner({ isOpen: false });
                  setExportedAll(!exportedAll);
                  setExportedPDFLink(false);
                  setExportedLink(false);
                  setIsEnabled(false);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                plain
                removeUnderline
                onClick={() => {
                  setIsCalendarOpen(true);
                  setIsEnabled(true);
                }}
              >
                {cms("section.export.label.date")}
              </Button>
              {submitDate.isShowDate && (
                <TextStyle variation="subdued">
                  <Caption>{`${submitDate.startingDate} - ${submitDate.endingDate}`}</Caption>
                </TextStyle>
              )}
            </Stack.Item>
            <RadioButton
              id="exportSelectedRadio"
              name={constant.EXPORT}
              label={cms("section.export.label.one")}
              helpText={cms("section.export.helpText.select")}
              checked={!exportedAll}
              onChange={() => {
                setBanner({ isOpen: false });
                setExportedAll(!exportedAll);
                setExportedPDFLink(false);
                setExportedLink(false);
                setIsEnabled(false);
              }}
            />
          </Stack>
        )}
        {!exportedAll && !isCalendarOpen && (
          <Tabs tabs={tab} selected={selected} onSelect={handleTabChange} fitted>
            <List
              items={items}
              selected={selected}
              handleSelection={handleSelection}
              productExportLoading={productExportLoading}
              selectedItems={selectedItems || []}
              onExport={onExport}
              cms={cms}
            />
          </Tabs>
        )}
      </Modal.Section>
    </Modal>
  );
};

ProviderExportProduct.propTypes = exportProps.type;
ProviderExportProduct.defaultProps = exportProps.default;

export default ProviderExportProduct;
