import React, { useContext, useEffect, useState } from "react";
import { Tabs, Card } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import tab prop
import { tabProp } from "app/product/modules/generic/propTypes";
import { ProductProvider } from "app/product/modules/generic/context/index";

// import data
import editData from "app/product/modules/operator/features/editService/config/editData";

// import tab
import Availability from "app/product/modules/generic/features/form/service/availability";
// import EditAttachment from "./attachment";
// import EditFulfillment from "./fulfillment";
import EditImage from "./image";
import EditInformation from "./information";
// import EditInventory from "./inventory";
// import EditMetafield from "./metafield";
import EditVariant from "./variant";

const EditTabs = (props) => {
  const {
    setBanner,
    setDescription,
    setTitle,
    isVariant,
    refetch,
    isMetafield,
    availability,
    serviceId,
    getAvailability,
  } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { search } = history.location;
  const { tab } = baseHelper.getQueryParams(search);
  const { ATTACHMENT, AVAILABILITY, FULFILLMENT, IMAGE, INFORMATION, VARIANT, METAFIELD } = constant;
  const validProductTabs = editData(cms, isVariant).serviceTabs;
  const [selected, setSelected] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    const validtab = validProductTabs.map((item) => item.id);
    const tabName = validtab.includes(tab) ? tab : INFORMATION;
    setSelected(tabName);
    if (!validtab.includes(tab)) {
      baseHelper.setUrl(history, { tab: tabName });
    }
  }, [INFORMATION, history, selected, tab, validProductTabs]);

  useEffect(() => {
    let index = validProductTabs.findIndex((item) => item.id === tab);
    if (validProductTabs.length < index) {
      index = 0;
    }
    setSelectedTabIndex(index);
  }, [tab, validProductTabs]);

  const handleTabChange = (tabIndex) => {
    const tabId = validProductTabs[tabIndex].id;
    setSelected(tabId);
    if (tabId === AVAILABILITY && serviceId) {
      getAvailability({ variables: { input: { id: serviceId } } });
    }
    baseHelper.setUrl(history, { tab: tabId });
  };

  const getTab = (tabSelected) => {
    let lastTab = isAttachmentVisible ? ATTACHMENT : FULFILLMENT;
    if (isVariant) {
      lastTab = isAttachmentVisible ? ATTACHMENT : VARIANT;
    }

    if (isMetafield) {
      lastTab = isAttachmentVisible ? ATTACHMENT : METAFIELD;
    }

    const tabObj = { islastTab: tabSelected === lastTab };

    switch (tabSelected) {
      case INFORMATION:
        return <EditInformation />;
      case AVAILABILITY:
        return <Availability />;
      case IMAGE:
        return <EditImage />;
      case VARIANT:
        return <EditVariant isVariant={isVariant} tabObj={tabObj} />;
      // case FULFILLMENT:
      //   return <EditFulfillment tabObj={tabObj} />;
      // case ATTACHMENT:
      //   return <EditAttachment tabObj={tabObj} />;
      // case METAFIELD:
      // return <EditMetafield tabObj={tabObj} />;
      default:
        return <EditInformation />;
    }
  };

  return (
    <>
      <Card>
        <Tabs tabs={validProductTabs} selected={selectedTabIndex} onSelect={handleTabChange} />
      </Card>
      <ProductProvider
        value={{
          handleTabChange,
          isVariant,
          refetch,
          setBanner,
          setDescription,
          setTitle,
          availability,
          serviceId,
          getAvailability,
        }}
      >
        <div className="productComponent">{getTab(selected)}</div>
      </ProductProvider>
    </>
  );
};

EditTabs.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(EditTabs), {
  feature: constant.PRODUCT_UPDATE_FORM,
});
