import React, { useContext } from "react";
import { OperatorContactUs } from "app/userManagement/modules/operator/features";
import { ProviderContactUs } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator, provider } = userKey;

const ContactUs = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorContactUs />;
    }
    if (role === provider) {
      return <ProviderContactUs />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ContactUsComponent = getComponent(currentUserRole);
  return ContactUsComponent;
};

export default ContactUs;
