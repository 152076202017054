import { gql } from "apollo-boost";

const GET_SELLER_PLAN = gql`
  query getSellerPlanList($input: GetListInputV1) {
    getSellerPlanList(input: $input) {
      data {
        planList {
          _id
          code
          currencyCode
          name
          price
          productLimit
          period
          periodUnit
          priceModel
          updatedAt
          description
          sellerId
          trialPeriod
          trialPeriodUnit
          redirectURL
          createdAt
        }
        count
      }
      error
      status
    }
  }
`;

export default GET_SELLER_PLAN;
