import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  Banner,
  Caption,
  Card,
  Collapsible,
  Layout,
  Link,
  PageActions,
  RadioButton,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";

import { SkeletonAnnotated, Toast } from "lib/components";

// import constant
import constant from "lib/constant/constant";

import {
  ACCEPT_ORDER_EXPIRY,
  GET_ORDER_EXPIRY_SETTING,
  GET_SELLER_FULFILLMENT,
  PICKUP_ORDER_EXPIRY,
  ORDER_EXPIRY,
} from "app/setup/apollo";
// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import AdvanceOrderAutoExpiry from "./advanceExpiry";
import AdvancePickupAutoExpiry from "./advancePickupExpiry";
// import yup validation
import validate from "./yup";

const OperatorOrderAutoExpiry = () => {
  const history = useHistory();
  const { currentUser, cms, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: currentUserId = "" } = currentUser || {};
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [toast, setToast] = useState({
    content: "",
    isOpen: false,
  });
  const [acceptanceChecked, setAcceptanceChecked] = useState(false);
  const [pickupChecked, setPickupChecked] = useState(false);
  const [weekendSelected, setWeekendSelected] = useState(false);
  const [acceptSubmit, setAcceptSubmit] = useState(false);
  const [pickupSubmit, setPickupSubmit] = useState(false);
  const [weekendSubmit, setWeekendSubmit] = useState(false);
  const [acceptanceField, setAcceptanceField] = useState("");
  const [acceptUpdatedAt, setAcceptUpdatedAt] = useState("");
  const [pickupField, setPickupField] = useState("");
  const [pickupUpdatedAt, setPickupUpdatedAt] = useState("");
  const [preferenceUpdatedAt, setPreferenceUpdatedAt] = useState("");
  const [pickupFirstPush, setPickupFirstPush] = useState(false);
  const [acceptFirstPush, setAcceptFirstPush] = useState(false);
  const [isReserve, setIsReserve] = useState(false);
  const [error, setError] = useState({});
  const [isAnyError, setIsAnyError] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [showAdvancePickup, setShowAdvancePickup] = useState(false);
  const { loading: settingLoading = false, error: customerError, data: orderAutoExpiryData } = useQuery(
    GET_ORDER_EXPIRY_SETTING,
    {
      variables: {
        input: { sellerId: currentUserId },
      },
    }
  );

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  const handleValidation = async (field, value) => {
    const validationError = await validate(value, cms);
    setError((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
    if (Number(value) === 0 && !validationError) {
      setError((prevState) => ({
        ...prevState,
        [field]: cms("message.error.greaterThanZero"),
      }));
    }
  };

  const [AcceptOrderExpiry, { loading: acceptUpdateLoading }] = useMutation(ACCEPT_ORDER_EXPIRY);
  const [PickupOrderExpiry, { loading: pickupUpdateLoading }] = useMutation(PICKUP_ORDER_EXPIRY);
  const [OrderExpiryDays, { loading: daysUpdateLoading }] = useMutation(ORDER_EXPIRY);

  useEffect(() => {
    if (orderAutoExpiryData) {
      const resData = baseHelper.getResponseData(orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING);
      if (resData.order === null) {
        setPickupFirstPush(true);
        setAcceptFirstPush(true);
        return;
      }
      if (resData.order.orderExpirySetting) {
        const value = resData.order.orderExpirySetting;
        setWeekendSelected(value.isWeekend);
        setPreferenceUpdatedAt(value.updatedAt);
      }
      if (resData.order && resData.order.accept === null) {
        setAcceptFirstPush(true);
      } else {
        if (resData.order.accept.duration) {
          setAcceptanceChecked(true);
          setAcceptanceField(String(resData.order.accept.duration));
        }
        setAcceptUpdatedAt(resData.order.accept.updatedAt);
      }
      if (resData.order && resData.order.pickup === null) {
        setPickupFirstPush(true);
      } else {
        if (resData.order.pickup.duration) {
          setPickupChecked(true);
          setPickupField(String(resData.order.pickup.duration));
        }
        setPickupUpdatedAt(resData.order.pickup.updatedAt);
      }
      // if (resData.order.accept && resData.order.accept.duration) {
      //   setAcceptanceChecked(true);
      //   setAcceptanceField(String(resData.order.accept.duration));
      //   setAcceptUpdatedAt(resData.order.accept.updatedAt);
      // }
      // if (resData.order.pickup && resData.order.pickup.duration) {
      //   setPickupChecked(true);
      //   setPickupField(String(resData.order.pickup.duration));
      //   setPickupUpdatedAt(resData.order.pickup.updatedAt);
      // }
    }
  }, [orderAutoExpiryData, gql.GET_ORDER_EXPIRY_SETTING]);

  useEffect(() => {
    if (fulfillmentData) {
      const resData = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT);
      if (resData.type === constant.RESERVE || resData.type === constant.HYBRID) {
        setIsReserve(true);
      }
    }
  }, [fulfillmentData, gql.GET_SELLER_FULFILLMENT]);

  const handleChange = useCallback((newChecked, id) => {
    if (id === constant.ENABLE_ACCEPTANCE) {
      setAcceptanceChecked(newChecked);
      setAcceptSubmit(false);
    }
    if (id === constant.DISABLE_ACCEPTANCE) {
      setAcceptanceChecked(false);
      setAcceptanceField(null);
      setAcceptSubmit(true);
    }
    if (id === constant.ENABLE_PICKUP) {
      setPickupChecked(newChecked);
      setPickupSubmit(false);
    }
    if (id === constant.DISABLE_PICKUP) {
      setPickupChecked(false);
      setPickupField(null);
      setPickupSubmit(true);
    }
    if (id === constant.FIVE_DAY) {
      setWeekendSelected(false);
      setWeekendSubmit(true);
    }
    if (id === constant.SEVEN_DAY) {
      setWeekendSelected(true);
      setWeekendSubmit(true);
    }
    setError({});
  }, []);

  const acceptOnlyValidInput = (value) => {
    if (value && value > 0) {
      return baseHelper.validateWholeNumber(value) && value;
    }
  };

  const onSubmit = async (Field) => {
    let inputData = {};
    if (Field === constant.ACCEPT) {
      handleValidation("acceptance", acceptanceField);
      setAcceptSubmit(false);
      await error.acceptance;
      if (error.acceptance) return;
      if (acceptanceChecked) {
        inputData = {
          isActive: acceptanceChecked,
          duration: Number(acceptanceField),
        };
      } else {
        inputData = {
          isActive: acceptanceChecked,
        };
      }
      const res = await AcceptOrderExpiry({
        variables: {
          input: inputData,
        },
      });
      if (res) {
        const resData = baseHelper.getResponseData(res.data, gql.ACCEPT_ORDER_EXPIRY);
        const resError = baseHelper.getResponseError(res.data, gql.ACCEPT_ORDER_EXPIRY);
        if (resError) {
          setBannerStatus({
            isOpen: true,
            status: "critical",
            title: resError,
          });
        }
        if (resData) {
          setToast({
            isOpen: true,
            content: cms("message.success.setting"),
          });
        }
      }
    }
    if (Field === constant.PICKUP) {
      await handleValidation("pickup", pickupField);
      setPickupSubmit(false);
      if (error.pickup) return;
      if (pickupChecked) {
        inputData = {
          isActive: pickupChecked,
          duration: Number(pickupField),
        };
      } else {
        inputData = {
          isActive: pickupChecked,
        };
      }
      const res = await PickupOrderExpiry({
        variables: {
          input: inputData,
        },
      });
      if (res) {
        const resData = baseHelper.getResponseData(res.data, gql.PICKUP_ORDER_EXPIRY);
        const resError = baseHelper.getResponseError(res.data, gql.PICKUP_ORDER_EXPIRY);
        if (resError) {
          setBannerStatus({
            isOpen: true,
            status: "critical",
            title: resError,
          });
        }
        if (resData) {
          setToast({
            isOpen: true,
            content: cms("message.success.setting"),
          });
        }
      }
    }
    if (Field === constant.DAYS) {
      setWeekendSubmit(false);
      inputData = {
        isWeekend: weekendSelected,
      };
      const res = await OrderExpiryDays({
        variables: {
          input: inputData,
        },
      });
      if (res) {
        const resData = baseHelper.getResponseData(res.data, gql.ORDER_EXPIRY);
        const resError = baseHelper.getResponseError(res.data, gql.ORDER_EXPIRY);
        if (resError) {
          setBannerStatus({
            isOpen: true,
            status: "critical",
            title: resError,
          });
        }
        if (resData) {
          setToast({
            isOpen: true,
            content: cms("message.success.setting"),
          });
        }
      }
    }
  };

  useEffect(() => {
    const isAnyErrorValue = () => Object.values(error).some((err) => err);
    setIsAnyError(isAnyErrorValue());
  }, [error]);

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });
  const dismissToast = () => setToast({ isOpen: false, content: "" });

  if (isLoading || settingLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <div>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Toast message={toast.isOpen && toast.content} setToast={setToast} />
      <Layout.AnnotatedSection title={cms("infoTitle")} description={cms("description")}>
        <Card
          sectioned
          title={[
            cms("label.expiryPreference"),
            preferenceUpdatedAt && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(preferenceUpdatedAt)}`}</TextStyle>
              </Caption>
            ),
          ]}
        >
          <Stack vertical>
            <RadioButton
              label={cms("label.excludeWeekend")}
              helpText={cms("helpText.excludeWeekend")}
              checked={weekendSelected === false}
              id="fiveDay"
              onChange={handleChange}
            />
            <RadioButton
              label={cms("label.includeWeekend")}
              helpText={cms("helpText.includeWeekend")}
              id="sevenDay"
              checked={weekendSelected === true}
              onChange={handleChange}
            />
          </Stack>
          <div className="manageCustomerButton">
            <PageActions
              primaryAction={{
                content: cms("common.button.update"),
                onAction: () => onSubmit("days"),
                disabled: isAnyError || !weekendSubmit,
                loading: daysUpdateLoading,
              }}
            />
          </div>
        </Card>
        <Card
          sectioned
          title={[
            cms("label.accept"),
            acceptUpdatedAt && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(acceptUpdatedAt)}`}</TextStyle>
              </Caption>
            ),
          ]}
        >
          <Stack vertical>
            <RadioButton
              label={cms("label.disable")}
              helpText={cms("helpText.disable")}
              checked={acceptanceChecked === false}
              id="disableAcceptance"
              onChange={handleChange}
            />
            <RadioButton
              label={cms("label.enable")}
              helpText={cms("helpText.enable")}
              id="enableAcceptance"
              checked={acceptanceChecked === true}
              onChange={handleChange}
            />
            <div className="text-box">
              {acceptanceChecked && (
                <TextField
                  id="acceptance"
                  value={acceptanceField}
                  suffix="Days"
                  onChange={(value) => {
                    setAcceptanceField(acceptOnlyValidInput(value));
                    setAcceptSubmit(true);
                  }}
                  onBlur={() => handleValidation("acceptance", acceptanceField)}
                  error={(error && error.acceptance) || null}
                />
              )}
            </div>
          </Stack>

          <div className="manageCustomerButton">
            <PageActions
              primaryAction={{
                content: acceptFirstPush ? cms("common.button.submit") : cms("common.button.update"),
                onAction: () => onSubmit("accept"),
                disabled: isAnyError || !acceptSubmit,
                loading: acceptUpdateLoading,
              }}
            />
          </div>
          <Link onClick={() => setShowAdvance(!showAdvance)}>{cms("label.advanceAccept")}</Link>
        </Card>
      </Layout.AnnotatedSection>

      <Collapsible open={showAdvance}>
        <AdvanceOrderAutoExpiry setBannerStatus={setBannerStatus} setToast={setToast} show={showAdvance} />
      </Collapsible>
      {isReserve && (
        <Layout.AnnotatedSection>
          <Card
            sectioned
            title={[
              cms("label.pickup"),
              pickupUpdatedAt && (
                <Caption>
                  <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(pickupUpdatedAt)}`}</TextStyle>
                </Caption>
              ),
            ]}
          >
            <Stack vertical>
              <RadioButton
                label={cms("label.disable")}
                helpText={cms("helpText.disable")}
                checked={pickupChecked === false}
                id="disablePickup"
                onChange={handleChange}
              />
              <RadioButton
                label={cms("label.enable")}
                helpText={cms("helpText.enable")}
                id="enablePickup"
                checked={pickupChecked === true}
                onChange={handleChange}
              />
              <div className="text-box">
                {pickupChecked && (
                  <TextField
                    id="pickup"
                    value={pickupField}
                    suffix="Days"
                    onChange={(value) => {
                      setPickupField(acceptOnlyValidInput(value));
                      setPickupSubmit(true);
                    }}
                    onBlur={() => handleValidation("pickup", pickupField)}
                    error={(error && error.pickup) || null}
                  />
                )}
              </div>
            </Stack>
            {/* <br /> */}
            <div className="manageCustomerButton">
              <PageActions
                primaryAction={{
                  content: pickupFirstPush ? cms("common.button.submit") : cms("common.button.update"),
                  onAction: () => onSubmit("pickup"),
                  disabled: isAnyError || !pickupSubmit,
                  loading: pickupUpdateLoading,
                }}
              />
            </div>
            <Link onClick={() => setShowAdvancePickup(!showAdvancePickup)}>{cms("label.advancePickup")}</Link>
          </Card>
        </Layout.AnnotatedSection>
      )}
      <Collapsible open={showAdvancePickup}>
        {isReserve && (
          <AdvancePickupAutoExpiry setBannerStatus={setBannerStatus} setToast={setToast} show={showAdvancePickup} />
        )}
      </Collapsible>
    </div>
  );
};

export default withFeature(withErrorBoundary(OperatorOrderAutoExpiry), { feature: constant.ORDER_EXPIRY });
