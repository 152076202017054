import { gql } from "apollo-boost";

const GET_ONBOARD_ACTIVITY = gql`
  query getOnboardActivity($input: GetListInput) {
    getOnboardActivity(input: $input) {
      status
      error
      data {
        count
        OnBoardActivity {
          _id
          store
          email
          message
          country
          platform
          createdAt
          activity
          brandName
        }
      }
    }
  }
`;

export default GET_ONBOARD_ACTIVITY;
