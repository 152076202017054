import styled from "styled-components";

const StyledTable = styled.div`
  td,
  th {
    border-bottom: 0;
    vertical-align: middle;
    &:nth-child(3) {
      text-align: center;
      width: 25%;
    }
  }
`;
export default StyledTable;
