import { gql } from "apollo-boost";

const UPDATE_SELLER_LOCATION = gql`
  mutation updateSellerLocation($input: SellerLocationSetting) {
    updateSellerLocation(input: $input) {
      status
      data
      error
    }
  }
`;

export default UPDATE_SELLER_LOCATION;
