// import packages
import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import components
import { SkeletonAnnotated } from "lib/components";
import { OperatorCommission } from "./operator/features";

const Commission = () => {
  const { isLoading } = useContext(PrivateContext);
  const {
    userKey: { operator },
  } = constant;

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorCommission />;
    }

    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OnboardingComponent = getComponent(currentUserRole);
  return OnboardingComponent;
};

export default Commission;
