import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { SkeletonList } from "lib/components";

import { storageHelper } from "lib/helpers";
import { SingleColumnLayout } from "layout/private/components";
import NotFound from "layout/private/components/notFound/notFound";

// import AdminInvitation from "./admin/invitation";
import OperatorInvitation from "./operator/invitation";

const { userKey } = constant;
const { /* admin, */ operator } = userKey;

const Invitation = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    // if (role === admin) {
    //   return <AdminInvitation />;
    // }
    if (role === operator) {
      return <OperatorInvitation />;
    }
    return <NotFound />;
  };

  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default Invitation;
