import { gql } from "apollo-boost";

const UPDATE_PAYMENT_REPORT_SETTING = gql`
  mutation updatePaymentReportSetting($input: PaymentReportSetting) {
    updatePaymentReportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_PAYMENT_REPORT_SETTING;
