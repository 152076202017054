import React, { useContext, useState } from "react";
import {
  Banner,
  Card,
  Link,
  Collapsible,
  Caption,
  Layout,
  Stack,
  Heading,
  TextContainer,
  TextStyle,
  VideoThumbnail,
} from "@shopify/polaris";

import { withFeature, withErrorBoundary } from "lib/hoc";
import { useQuery } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
// helper
import { baseHelper, storageHelper } from "lib/helpers";
import { ModalPopup, PermissionBanner, SkeletonAnnotatedCard } from "lib/components";

// import gql
import { GET_SHIPPING } from "app/shipping/apollo/queries";

import { PrivateContext } from "lib/context";
import { StyledSection, StyledCardHeader } from "app/onboarding/modules/genericStyle";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

import standardList from "./standardConfig";

const OperatorSettings = () => {
  const { currentUser, cms, isLoading, isShopifyCustomOperator } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false, ecommercePlatform } = currentUser;

  const [openSetup, setOpenSetup] = useState({});
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);
  const { imageURL, ADVANCE_SHIPPING, FULFILLMENT, TAX, LAKE_TAX_RATE } = constant;

  const allowShipping = [ADVANCE_SHIPPING];
  const wooCommerceSetting = [TAX, FULFILLMENT];
  const wooCommerceFeature = [LAKE_TAX_RATE];
  const { loading: loadingShipping, data: shippingData } = useQuery(GET_SHIPPING);
  const responseData = baseHelper.getResponseData(shippingData, constant.gql.GET_SHIPPING);

  const { shipping } = responseData || {};
  const { term } = shipping || {};

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleClose = () => {
    // setAppUpdate(!appUpdate);
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  const borderSet = {
    border: "0",
  };

  const hiddenCards = ["Billing", "General"];

  if (isLoading || loadingShipping) {
    return <SkeletonAnnotatedCard />;
  }

  return (standardList(cms, currentUser) || []).map((settingList) => {
    let updateSettingList = settingList.list;
    const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
    const isWooCommerceUser = baseHelper.isWooCommerceUser(currentUser);
    if (isEcommerceUser) {
      updateSettingList = baseHelper.getAllowedSetting(updateSettingList);
      if (!isWooCommerceUser) {
        updateSettingList = updateSettingList.filter((item) => !wooCommerceSetting.includes(item.code));
      }
    }

    return (
      <>
        {!isShopifyCustomOperator && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.title")}
              status="critical"
            >
              <p>{cms("common.popup.migrate.description")}</p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_LIVE_CONNECT,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Card>
              <UpgradAppPermission />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <Layout.AnnotatedSection
          title={settingList.title}
          description={[
            <div>
              {settingList.isVideo && (
                <>
                  <VideoThumbnail
                    videoLength={80}
                    // eslint-disable-next-line max-len
                    thumbnailUrl={imageURL.WELCOME}
                  />
                  <br />
                </>
              )}
              <TextStyle variation="subdued">
                {ecommercePlatform ? cms("operator.tempDescription") : settingList.description}
              </TextStyle>
            </div>,
          ]}
        >
          {updateSettingList.map((actionList) => {
            const isSellerBootstrapPlan = baseHelper.isBootstrapPlan(currentUser) && actionList.code === constant.USER;
            let actionData = actionList.actions;
            if (isSellerBootstrapPlan) {
              actionData = actionData.filter((item) => item.code !== constant.EMAIL_TEMPLATE);
            }
            if (isEcommerceUser) {
              actionData = baseHelper.allowedSettingList(actionList.code, actionData);
              if (!isWooCommerceUser) {
                actionData = actionData.filter((item) => !wooCommerceFeature.includes(item.code));
              }
            }
            if (!isEcommerceUser) {
              actionData = actionData.filter((item) => item && item.code !== LAKE_TAX_RATE);
            }
            // NOTE: Remove this condition, once the development and QA is completed
            // if (process.env.REACT_APP_ENV === "prod") {
            //   actionData = actionData.filter((item) => item.code !== "attachment");
            // }
            // if (process.env.REACT_APP_ENV === "prod") {
            //   const hideTax = actionData.find((item) => item.code === "tax");
            //   if (hideTax) {
            //     hiddenCards.push("Taxes");
            //   }
            // }

            return (
              !hiddenCards.includes(actionList.title) && (
                <Card>
                  <StyledCardHeader className="styled-card-header">
                    <Card.Header
                      sectioned
                      title={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Stack>
                          <i className={actionList.icon} />
                          <Heading>
                            {actionList.title}
                            <TextStyle variation="subdued">
                              <Caption>{actionList.description}</Caption>
                            </TextStyle>
                          </Heading>
                        </Stack>
                      }
                      actions={[
                        {
                          content: openSetup[actionList.code] ? "Hide" : "Show",
                          onAction: () => handleSetupToggle(actionList.code),
                          disclosure: openSetup[actionList.code] ? "up" : "down",
                        },
                      ]}
                    />
                  </StyledCardHeader>

                  <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                    <StyledSection className="styled-settings">
                      <Card.Section subdued>
                        {actionData
                          .filter((step) => step)
                          .map((step) => {
                            if (
                              (term !== constant.VENDOR_MANAGE || currentUser.isGlobalShipping) &&
                              allowShipping.includes(step.code)
                            ) {
                              return null;
                            }
                            return (
                              <div style={borderSet}>
                                <Card.Section subdued>
                                  <Stack>
                                    <Stack.Item>
                                      <Link url={step.link}>{step.label}</Link>
                                      <TextStyle variation="">
                                        <Caption>{step.description}</Caption>
                                      </TextStyle>
                                    </Stack.Item>
                                  </Stack>
                                </Card.Section>
                              </div>
                            );
                          })}
                        {actionList.isVideo && (
                          <Layout.AnnotatedSection
                            title={actionList.thumbnailHeader}
                            description={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <div>
                                <VideoThumbnail videoLength={80} thumbnailUrl={actionList.thumbnail} />
                                <br />
                                <TextStyle variation="">{actionList.thumbnailDescription}</TextStyle>
                              </div>
                            }
                          />
                        )}
                      </Card.Section>
                    </StyledSection>
                  </Collapsible>
                </Card>
              )
            );
          })}
        </Layout.AnnotatedSection>
      </>
    );
  });
};

export default withFeature(withErrorBoundary(OperatorSettings), { feature: constant.SETTINGS });
