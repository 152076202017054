/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import { DisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { useQuery } from "@apollo/react-hooks";

import { GET_INVOICE_SETTING, GET_SELLER_SETTING } from "app/setup/apollo/queries";

import StyledSlip from "asset/styles/paymentSlip";
import {
  LineItemList,
  ProductItem,
  ShippingItem,
  ProductTotal,
  OperatorCommission,
  DynamicDiscount,
  OperatorCommissionDetails,
  DeductionsShipping,
  TotalDeduction,
  TotalLineTotal,
  OrderSummary,
  ColumnData,
  InvoiceHeader,
  FooterSection,
} from "app/payments/modules/generic/view/uiComponents";

const { gql, VENDOR_MANAGE, NONE, EQUAL, FLAT, GST_L, PST_L } = constant;

require("jspdf-autotable");

const OrderTaxInvoiceView = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const [invoiceSetting, setInvoiceSetting] = useState({
    purchaseFrom: { label: cms("label.title"), isHide: false },
    deliverTo: { label: cms("label.to"), isHide: false },
    product: { label: cms("label.item"), isHide: false },
    sku: { label: cms("label.sku"), isHide: false },
    quantity: { label: cms("label.quantity"), isHide: false },
    price: { label: cms("label.price"), isHide: false },
    tax: { label: cms("label.tax"), isHide: false },
    discount: { label: cms("label.discount"), isHide: false },
    subtotal: { label: cms("label.subTotal"), isHide: false },
    shipping: { label: cms("label.shipping"), isHide: false },
    sellerCommission: { label: cms("label.sellerCommission"), isHide: false },
    totalDeduction: { label: cms("label.totalDeduction"), isHide: false },
    orderTotal: { label: cms("label.orderTotal"), isHide: false },
    orderPayout: { label: cms("label.vendorAmount"), isHide: false },
    totalVendorPayout: { label: cms("label.totalVendorPayout"), isHide: false },
    fulfillBy: { label: cms("label.fulfill"), isHide: false },
    totalOrderTax: { label: cms("label.totalOrderTax"), isHide: false },
    totalAmount: { label: cms("label.totalAmount"), isHide: false },
    totalDeductionAmount: { label: cms("label.totalDeductionAmount"), isHide: false },
    totalOrderShipping: { label: cms("label.totalOrderShipping"), isHide: false },
    totalDeductionTax: { label: cms("label.totalDeductionTax"), isHide: false },
    totalOrderAmount: { label: cms("label.totalOrderAmount"), isHide: false },
  });

  const {
    invoiceNumberPrefix,
    fulfillment,
    logo,
    orderData,
    orderLineItems,
    sellerInfo = {},
    vendorInfo = {},
    viewOrderData = {},
  } = props;
  const {
    isOrderBasedCommission = false,
    vendorLineCount = 1,
    totalOrderProductCount = 1,
    totalOrderAllProductCount = 1,
    totalOrderAllProductCountTran = 1,
  } = orderData;
  const {
    shipping: shopifyShipping,
    transactionFeeLines: transactionFeeLinesData,
    isThirdPartyShipping = false,
  } = viewOrderData;

  const isSeller = baseHelper.isSeller(currentUser);
  const { type = "" } = fulfillment || {};
  const [orderLine] = orderLineItems || [];
  const {
    commissionTax,
    createdAt,
    moneyFormat,
    shopifyOrderId,
    dueDate,
    shippingTax,
    price,
    commissionSetting,
    commissionTaxSetting,
    shippingTaxSetting,
    isDiscountAllowed: isDynamicDiscount,
    vendorProductCount,
  } = orderLine || {};
  const { rate: commissionRate = "", type: commissionType = "" } = commissionSetting || {};
  const { rate: commissionTaxRate = "", type: commissionTaxType = "" } = commissionTaxSetting || {};
  const { rate: shippingTaxRate = "", type: shippingTaxType = "" } = shippingTaxSetting || {};

  const totals = baseHelper.getOrderPrice(orderLineItems, orderData, isSeller, false, true);
  const { taxSetting: sellerTaxInfo = {} } = sellerInfo;
  const { vat = "" } = sellerTaxInfo || {};
  let lineitemNumber = 1;
  let summaryAmount = 0;

  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);

  const vendorAddress = baseHelper.getFullAddress(vendorInfo);
  const vendorName = baseHelper.getFullName(vendorInfo);
  const sellerAddress = baseHelper.getFullAddress(sellerInfo);
  const customerAddress = baseHelper.getCustomerAddress(viewOrderData.shippingAddress || {});
  const sellerName = baseHelper.getFullName(sellerInfo);
  const isVendor = baseHelper.isVendor(currentUser);

  const { code: discountLineCode = "" } =
    (viewOrderData.discountCodes && viewOrderData.discountCodes.length && viewOrderData.discountCodes[0]) || {};

  const { data: invoiceSettingData, loading: invoiceSettingLoading } = useQuery(GET_INVOICE_SETTING);
  const { data: sellerSettingData, loading: sellerSettingLoading } = useQuery(GET_SELLER_SETTING);

  const manageInvoiceResponseData = baseHelper.getResponseData(invoiceSettingData, gql.GET_INVOICE_SETTING);
  const { payment } = manageInvoiceResponseData || {};
  const { invoice = {} } = payment || {};
  const { note = {} } = invoice || {};
  const { value = "" } = note || {};
  let totalDiscountValue = 0;

  useEffect(() => {
    if (payment) {
      const { invoice = {} } = payment;
      const { manage: invoiceData } = invoice || {};
      if (invoiceData) {
        const setting = baseHelper.getInvoiceSlipSettings(invoiceData, cms);
        setInvoiceSetting(setting);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    if (!sellerSettingData) {
      return;
    }

    const sellerData = baseHelper.getResponseData(sellerSettingData, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    const { isExcludeShippingAmount = false } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
  }, [sellerSettingData]);

  // const invoiceSetting = baseHelper.getInvoiceSlipSettings(settingData, cms);
  const shippingTerm = [NONE, FLAT, VENDOR_MANAGE];
  const shopifyShippingAmount = (orderLineItem = {}) => {
    const { shippingAmount = 0, thirdPartyShippingAmount = 0, requiresShipping = true } = orderLineItem;
    if (requiresShipping === "false") {
      return 0;
    }
    let isShippingAdd = false;
    const showShippingAmount = shopifyShipping.amount / vendorLineCount / vendorProductCount;
    // 3pl select
    if (isThirdPartyShipping && shopifyShipping.amount && thirdPartyShippingAmount) {
      return thirdPartyShippingAmount;
    }
    // 3pl select with custom shipping or free shippgin
    if (isThirdPartyShipping && shopifyShipping.amount && !thirdPartyShippingAmount) {
      return showShippingAmount;
    }
    if (shopifyShipping.term === VENDOR_MANAGE && shopifyShipping.amount && !shippingAmount) {
      return showShippingAmount;
    }
    if (shopifyShipping.term === NONE && shopifyShipping.amount && !shippingAmount) {
      return showShippingAmount;
    }

    if (shopifyShipping.term === EQUAL && shopifyShipping.amount && shippingAmount) {
      return 0;
    }

    if (shopifyShipping.term === FLAT && shopifyShipping.amount && shippingAmount) {
      return showShippingAmount - shippingAmount;
    }
    return 0;
  };
  const shopifyLineItemShippingAmount = (orderLines = []) => {
    if (orderLines.requiresShipping === "false") {
      return 0;
    }
    if (!isThirdPartyShipping && isOrderBasedCommission && shippingTerm.includes(shopifyShipping.term)) {
      // custom shipping
      if (shopifyShipping.term === VENDOR_MANAGE && shopifyShipping.amount && !orderLines.shippingAmount) {
        return shopifyShipping.amount / vendorLineCount / vendorProductCount;
      }
      // band shipping mulitple with quantity
      if (shopifyShipping.term === VENDOR_MANAGE && !shopifyShipping.amount) {
        return shopifyShipping.amount / vendorLineCount / vendorProductCount;
      }
      // band shipping
      if (shopifyShipping.term === VENDOR_MANAGE && shopifyShipping.amount) {
        return orderLines.shippingAmount;
      }
      if (shopifyShipping.term === FLAT && !shopifyShipping.amount) {
        return orderLines.shippingAmount;
      }
      return shopifyShipping.amount / vendorLineCount / vendorProductCount;
    }
    if (isOrderBasedCommission && isThirdPartyShipping) {
      // 3pl custom shipping
      if (isThirdPartyShipping && shopifyShipping.amount && !orderLines.thirdPartyShippingAmount) {
        return shopifyShipping.amount / vendorLineCount / vendorProductCount;
      }
      // 3pl shipping selected
      if (isThirdPartyShipping && orderLines?.thirdPartyShippingAmount) {
        return orderLines?.thirdPartyShippingAmount;
      }
    }

    return orderLines.shippingAmount;
  };
  const fulfillmentData =
    fulfillment && type === constant.CONSIGNMENT && baseHelper.fulfillmentDetails(fulfillment, currentUser);

  const getSellerCommission = (orderLines = []) => {
    const sellerCommisionDetails = {
      priceTaxRate: 0,
      commissionSeller: 0,
      sellerTaxOnCommission: 0,
    };
    orderLines.forEach((item) => {
      const isRejected = item && item.status === constant.REJECTED;
      let { commissionSeller, priceTaxRate } = item || {};
      const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
      const totalPriceInclusiveTax = baseHelper.getPrice(item.price * item.quantity);
      const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
      const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
      const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
      const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
      if (isRejected) {
        commissionSeller = 0;
        priceTaxRate = 0;
      }
      sellerCommisionDetails.priceTaxRate += priceTaxRate;
      sellerCommisionDetails.commissionSeller += Number(commissionSeller);
      sellerCommisionDetails.sellerTaxOnCommission += sellerTaxOnCommission;
    });
    return sellerCommisionDetails;
  };

  const getItemCommissionTax = (item = {}) => {
    const { commissionSeller, priceTaxRate } = item || {};
    const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
    const totalPriceInclusiveTax = baseHelper.getPrice(item.price * item.quantity);
    const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
    const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
    const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
    const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
    return sellerTaxOnCommission;
  };

  let discountValue = 0;
  const getDynamicDiscount = (orderLineItem = {}) => {
    const { actualQuantity, quantity, isDiscountAllowed = false } = orderLineItem;

    const { value_type: dynamicDiscountType } = viewOrderData.discountApplications[0] || {};

    if (isDiscountAllowed && orderLineItem.discountAllocations && orderLineItem.discountAllocations.length) {
      const lastDiscountAllocations = orderLineItem.discountAllocations[orderLineItem.discountAllocations.length - 1];
      discountValue = lastDiscountAllocations.amount;
    }
    return baseHelper.getPrice(discountValue);
  };
  const getTotalShipping = (orderLineItem = {}) => {
    const { shippingAmount = 0, shippingTax = 0, shopifyShippingTax = 0, requiresShipping = "true" } =
      orderLineItem || {};
    if (requiresShipping === "false") {
      return 0;
    }
    let totalOperatorCommission = parseFloat(shippingAmount) + parseFloat(shippingTax);
    if (isOrderBasedCommission && (shopifyShippingTax || shopifyShipping.term === VENDOR_MANAGE)) {
      totalOperatorCommission = parseFloat(shippingAmount);
    }
    return totalOperatorCommission;
  };
  const getItemPrice = (item) => {
    const { price, markUpPrice = 0, sellerDiscount = 0 } = item;
    let priceValue = parseFloat(price || 0);
    if (isOrderBasedCommission) {
      priceValue = parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0);
    }
    return priceValue;
  };

  const duplicateFilter = (taxList) => {
    let listData = [];
    const dataDuplicate = {};
    taxList.forEach((item) => {
      if (!dataDuplicate[item.title]) {
        dataDuplicate[item.title] = 1;
        listData = [...listData, item];
      }
    });
    return listData;
  };

  const taxLinesData = (orderLineData) => {
    let totalTaxData = 0;
    let taxShow = false;
    let countCheck = 0;
    let totalTaxRate = 0;
    let deductionTaxRate = 0;
    let shippingTaxDeduction = false;
    const { vendorVat = "", vendorGst = "", vendorPst = "" } = orderLineData || {};

    const productPrice =
      parseFloat(orderLineData.markUpPrice) +
      parseFloat(orderLineData.price) -
      parseFloat(orderLineData.sellerDiscount) -
      getDynamicDiscount(orderLineData) / orderLineData.actualQuantity;
    let taxName = "";
    duplicateFilter(orderLineData.taxLines).forEach((item) => {
      totalTaxRate += parseFloat(item.rate);
      taxName = item.title.toUpperCase();
      if (vendorGst && taxName === GST_L) {
        totalTaxData += parseFloat(item.price);
        taxShow = true;
        countCheck += 1;
        deductionTaxRate += parseFloat(item.rate);
      }
      if (vendorPst && taxName === PST_L) {
        totalTaxData += parseFloat(item.price);
        taxShow = true;
        countCheck += 1;
        deductionTaxRate += parseFloat(item.rate);
      }
      if (vendorVat && taxName !== GST_L && taxName !== PST_L) {
        totalTaxData += parseFloat(item.price);
        taxShow = true;
        countCheck += 1;
        deductionTaxRate += parseFloat(item.rate);
      }
    });
    const listData = duplicateFilter(orderLineData.taxLines);
    if (listData.length && countCheck && countCheck === listData.length) {
      shippingTaxDeduction = true;
    }

    return {
      listData,
      shippingTaxDeduction,
      totalTaxData,
      countCheck,
      taxShow,
      totalTaxRate,
      totalTaxAmount: parseFloat(productPrice * totalTaxRate) * orderLineData.actualQuantity,
      deductionTaxAmount: parseFloat(productPrice * deductionTaxRate) * orderLineData.actualQuantity,
    };
  };
  const showShippingTax = (orderLineData) => {
    const { totalTax = 0, shopifyShippingTax = 0, shippingAmount = 0, thirdPartyShippingAmount = 0 } =
      orderLineData || {};
    if (!isOrderBasedCommission || !shopifyShippingTax) {
      return 0;
    }

    let productShippingTax = 0;
    const { totalTaxRate = 0 } = taxLinesData(orderLineData);
    productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    if (isThirdPartyShipping) {
      if (thirdPartyShippingAmount) {
        productShippingTax = parseFloat(totalTaxRate * thirdPartyShippingAmount);
      } else if (shopifyShipping.amount) {
        productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
      }
    }
    if (!isThirdPartyShipping && shopifyShipping.term === VENDOR_MANAGE) {
      productShippingTax =
        parseFloat(totalTaxRate * getTotalShipping(orderLineData)) ||
        parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }
    if (orderLineData.requiresShipping === "false") {
      productShippingTax = 0;
    }
    return isOrderBasedCommission && totalTax > 0 ? baseHelper.getPrice(productShippingTax) : "0.00";
  };

  const showShippingTaxLineDeduction = (orderLineData) => {
    const { totalTax = 0, shopifyShippingTax = 0 } = orderLineData || {};
    let productShippingTax = 0;
    if (!isOrderBasedCommission || !shopifyShippingTax) {
      return 0;
    }
    if (orderLineData.requiresShipping === "false") {
      productShippingTax = 0;
    }
    const { totalTaxRate = 0, countCheck = 0, taxShow = false } = taxLinesData(orderLineData);

    if (countCheck !== duplicateFilter(orderLineData.taxLines).length) {
      return 0;
    }
    if (isThirdPartyShipping) {
      productShippingTax = parseFloat(totalTaxRate * getTotalShipping(orderLineData)) || 0;
      return isOrderBasedCommission && totalTax > 0 ? baseHelper.getPrice(productShippingTax) : "-";
    }
    if (shopifyShipping.term === FLAT || shopifyShipping.term === NONE) {
      productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }
    if (taxShow && shopifyShipping.term === VENDOR_MANAGE) {
      productShippingTax = parseFloat(totalTaxRate * getTotalShipping(orderLineData)) || 0;
    }
    if (taxShow && shopifyShipping.term === EQUAL) {
      productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }
    return isOrderBasedCommission && totalTax > 0 ? baseHelper.getPrice(productShippingTax) : "-";
  };

  const showShippingTaxDeduction = (orderLineData, taxAdd = false) => {
    const {
      vendorVat = "",
      vendorGst = "",
      totalTax = 0,
      vendorPst = "",
      thirdPartyShippingAmount = 0,
      shippingAmount = 0,
      shopifyShippingTax = 0,
    } = orderLineData || {};
    const { shippingTaxDeduction, totalTaxRate = 0, countCheck = 0, taxShow = false } = taxLinesData(orderLineData);
    if (orderLineData.requiresShipping === "false") {
      return 0;
    }
    if (!isOrderBasedCommission || !shopifyShippingTax) {
      return 0;
    }
    if (shippingTaxDeduction && shopifyShipping.term === VENDOR_MANAGE && shippingAmount) {
      return 0;
    }
    let productShippingTax = 0;
    const listTerm = [NONE, FLAT, VENDOR_MANAGE];
    if (!isThirdPartyShipping && listTerm.includes(shopifyShipping.term)) {
      // custome shipping select
      if (shopifyShipping.term === VENDOR_MANAGE && !shippingAmount) {
        return parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
      }
      if (shopifyShipping.term === NONE || shopifyShipping.term === FLAT) {
        return parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
      }
    }
    const canadaStore = orderLineData.taxLines.filter((item) => item.title === PST_L || item.title === GST_L).length;
    const canadaVatStore = orderLineData.taxLines.filter((item) => item.title !== PST_L && item.title !== GST_L).length;
    // 3pl with custome shipping select
    if (isOrderBasedCommission && isThirdPartyShipping && shopifyShipping.amount && !thirdPartyShippingAmount) {
      if (shopifyShipping.amount) {
        return parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount || 0;
      }
    }

    // 3pl shippging
    if (isOrderBasedCommission && isThirdPartyShipping && shopifyShipping.amount && thirdPartyShippingAmount) {
      if (thirdPartyShippingAmount) {
        return parseFloat(totalTaxRate * thirdPartyShippingAmount) || 0;
      }
    }
    if (!canadaStore && vendorVat) {
      return 0;
    }

    if (!canadaVatStore && canadaStore && vendorGst && vendorPst) {
      return 0;
    }

    // band select and vendor not tax register
    if (!taxAdd && !taxShow && shopifyShipping.term === VENDOR_MANAGE) {
      productShippingTax = parseFloat(shopifyShippingTax / totalOrderProductCount);
    }
    // custome shipping select
    if (shopifyShipping.term === VENDOR_MANAGE) {
      productShippingTax = parseFloat(totalTaxRate * getTotalShipping(orderLineData)) || 0;
    }
    // custome shipping select
    if (!shippingAmount && shopifyShipping.term === VENDOR_MANAGE) {
      productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }

    if (!taxShow && shopifyShipping.term === EQUAL) {
      productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }
    if (taxShow && countCheck !== duplicateFilter(orderLineData.taxLines).length && shopifyShipping.term === EQUAL) {
      productShippingTax = parseFloat(shopifyShippingTax / vendorLineCount) / vendorProductCount;
    }

    return isOrderBasedCommission && totalTax > 0 ? baseHelper.getPrice(productShippingTax) : "-";
  };

  const addGstPst = (orderLineData, isShippingTax = false) => {
    const { totalTax } = orderLineData;
    const productPrice =
      parseFloat(orderLineData.markUpPrice) +
      parseFloat(orderLineData.price) -
      parseFloat(orderLineData.sellerDiscount) -
      getDynamicDiscount(orderLineData) / orderLineData.actualQuantity;
    let { totalTaxRate = 0, taxShow = false } = taxLinesData(orderLineData);
    if (isShippingTax) {
      return parseFloat(productPrice * totalTaxRate) * orderLineData.actualQuantity;
      // return taxNoShipping;
    }
    if (!taxShow) {
      return parseFloat(orderLineData.totalTax);
    }
    totalTaxRate = parseFloat(productPrice * totalTaxRate) * orderLineData.actualQuantity;
    return parseFloat(totalTax > 0 ? totalTax : totalTaxRate);
  };

  const addGstPstDeduction = (orderLineData) => {
    const { deductionTaxAmount = 0 } = taxLinesData(orderLineData);
    return deductionTaxAmount;
  };
  const addGstPstText = (orderLineItem) => {
    const { taxLines = [] } = orderLineItem || {};
    let taxValueData = "";
    const taxCount = {};
    const productPrice =
      parseFloat(orderLineItem.markUpPrice) +
      parseFloat(orderLineItem.price) -
      parseFloat(orderLineItem.sellerDiscount) -
      getDynamicDiscount(orderLineItem) / orderLineItem.actualQuantity;
    if (taxLines && taxLines.length > 0) {
      taxValueData = taxLines.map((tax) => {
        if (taxCount[tax.title]) {
          return null;
        }
        taxCount[tax.title] = 1;
        return (
          <div>
            {`${moneyFormat}${baseHelper.getPrice((productPrice * tax.rate * 100) / 100)}(${baseHelper.getPrice(
              tax.rate * 100
            )}%)${tax.title === PST_L ? tax.title : ""}${tax.title === GST_L ? tax.title : ""}`}
          </div>
        );
      });
    }
    return taxValueData;
  };

  const getSubtotalAmount = (orderLineItem = {}) => {
    const {
      price = 0,
      actualQuantity = 1,
      vendorDiscount = 0,
      markUpPrice = 0,
      sellerDiscount = 0,
      isDiscountAllowed,
    } = orderLineItem || {};
    if (isOrderBasedCommission) {
      const priceValue = parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0);
      const amount = parseFloat(priceValue || 0) * parseFloat(actualQuantity);
      let totalTaxAmount = parseFloat(amount || 0);
      totalTaxAmount -= parseFloat(vendorDiscount || 0) * actualQuantity;
      summaryAmount += totalTaxAmount;
      totalTaxAmount += addGstPst(orderLineItem, true);
      if (isDiscountAllowed) {
        totalTaxAmount -= getDynamicDiscount(orderLineItem);
      }
      return parseFloat(totalTaxAmount);
    }
    const amount = parseFloat(price || 0) * parseFloat(actualQuantity);
    const totalAmount = parseFloat(amount || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    summaryAmount += totalAmount;
    return totalAmount;
  };
  const getTotalAmount = (orderLineItem = {}) => {
    const {
      price = 0,
      actualQuantity = 1,
      vendorDiscount = 0,
      markUpPrice = 0,
      sellerDiscount = 0,
      isDiscountAllowed,
    } = orderLineItem || {};
    if (isOrderBasedCommission) {
      const priceValue = parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0);
      const amounts = parseFloat(priceValue || 0) * parseFloat(actualQuantity);
      let totalTaxAmounts = parseFloat(amounts || 0);
      totalTaxAmounts += addGstPst(orderLineItem, true);
      totalTaxAmounts -= parseFloat(vendorDiscount || 0) * actualQuantity;
      totalTaxAmounts += shopifyShippingAmount(orderLineItem);
      totalTaxAmounts += parseFloat(showShippingTax(orderLineItem));
      if (isDiscountAllowed) {
        totalTaxAmounts -= getDynamicDiscount(orderLineItem);
      }
      return totalTaxAmounts;
    }
    const amounts = parseFloat(price || 0) * parseFloat(actualQuantity);
    const totalAmounts = parseFloat(amounts || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    return totalAmounts;
  };

  const getDeductionTotal = (orderLineItem = {}) => {
    const dynamicDiscount = getDynamicDiscount(orderLineItem);
    const result = parseFloat(orderLineItem.commissionSeller || 0) + parseFloat(dynamicDiscount || 0);
    let deductionTotal = isDynamicDiscount ? result : parseFloat(orderLineItem.commissionSeller || 0);
    if (isOrderBasedCommission) {
      deductionTotal += shopifyShippingAmount(orderLineItem);
    }
    return deductionTotal;
  };

  const getLinesSubTotal = (orderLineItem = {}) => {
    const {
      price = 0,
      actualQuantity = 1,
      isDiscountAllowed,
      vendorDiscount = 0,
      shippingTax,
      shippingAmount,
      commissionSeller,
      priceTaxRate,
      commissionTax: commissionTaxValue = 0,
      markUpPrice = 0,
      sellerDiscount = 0,
      totalTax: productTax = 0,
      vendorGst = 0,
      vendorPst = 0,
      vendorVat = 0,
      shopifyShippingTax = 0,
    } = orderLineItem || {};
    let amount = 0;
    amount = parseFloat(price || 0) * parseFloat(actualQuantity);
    if (isOrderBasedCommission) {
      let totalTaxValue = 0;
      let operatorShippingTaxValue = 0;
      if (shopifyShippingTax || shopifyShipping.term === VENDOR_MANAGE) {
        operatorShippingTaxValue = parseFloat(shippingTax);
      }
      if (vendorGst || vendorPst || vendorVat) {
        totalTaxValue = addGstPstDeduction(orderLineItem);
        if (totalTaxValue && shippingAmount && shopifyShipping.term !== FLAT) {
          totalTaxValue += parseFloat(showShippingTaxLineDeduction(orderLineItem)) || 0;
        }
        if (totalTaxValue && shippingAmount && isThirdPartyShipping && shopifyShipping.term === FLAT) {
          totalTaxValue += parseFloat(showShippingTaxLineDeduction(orderLineItem)) || 0;
        }
      }
      amount =
        (parseFloat(price || 0) + parseFloat(markUpPrice || 0) - parseFloat(sellerDiscount || 0)) *
          parseFloat(actualQuantity || 0) +
        parseFloat(totalTaxValue || 0) -
        parseFloat(operatorShippingTaxValue || 0);
    }
    const totalAmount = parseFloat(amount || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    let discountValue = 0;
    let taxRate = 0;
    if (!priceTaxRate) {
      taxRate = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (priceTaxRate && !commissionTaxValue) {
      taxRate = parseFloat(commissionSeller);
    }
    if (priceTaxRate && commissionTaxValue) {
      taxRate = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (isDiscountAllowed) {
      discountValue = getDynamicDiscount(orderLineItem);
    }
    let totalTax;
    if (orderLineItem.requiresShipping === "true") {
      totalTax = parseFloat(shippingAmount);
    } else {
      totalTax = 0;
    }
    totalTax += parseFloat(shippingTax || 0) - parseFloat(taxRate || 0);
    const totalLineAmount = parseFloat(totalAmount) + parseFloat(totalTax) - parseFloat(discountValue);

    return totalLineAmount;
  };

  let totalDeductionTax = 0;
  let totalShippingTax = 0;
  let totalSummaryShippingTax = 0;
  let shippingAmountValue = 0;
  let deductionValue = 0;
  let totalGst = 0;
  let totalDeductionValue = 0;
  orderLineItems.forEach((item) => {
    totalDeductionTax += parseFloat(item.commissionTax || 0);
    totalShippingTax += parseFloat(item.shippingTax || 0);
    if (isOrderBasedCommission) {
      totalGst += item?.isDiscountAllowed ? addGstPst(item) : addGstPst(item, true);
      totalDeductionTax += parseFloat(showShippingTaxDeduction(item));
      totalSummaryShippingTax += parseFloat(item.shippingTax || 0);
      totalDeductionValue += shopifyShippingAmount(item);
      if (shopifyShipping.term === VENDOR_MANAGE) {
        if (item.shippingAmount) {
          // band select shipping
          // totalGst += parseFloat(showShippingTax(item)) - shippingTax;
          totalShippingTax -= parseFloat(item.shippingTax || 0);
          totalGst += parseFloat(showShippingTax(item));
        } else if (item.requiresShipping === "true") {
          // custom shipping
          totalGst += parseFloat(item.shopifyShippingTax / vendorLineCount) / (vendorProductCount || 1) - shippingTax;
        }
      } else {
        totalGst += parseFloat(showShippingTax(item));
      }
      shippingAmountValue += shopifyShippingAmount(item);
    }
    shippingAmountValue += parseFloat(item.shippingAmount || 0);
    deductionValue += parseFloat(item.commissionSeller || 0);
    totalDiscountValue += parseFloat(getDynamicDiscount(item) || 0);
  });

  const getTotalOperatorCommission = (orderLineItem = {}) => {
    const { priceTaxRate, commissionSeller = 0, commissionTax: commissionTaxValue = 0 } = orderLineItem || {};
    let totalOperatorCommission = 0;
    if (!priceTaxRate) {
      totalOperatorCommission = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (priceTaxRate && !commissionTaxValue) {
      totalOperatorCommission = parseFloat(commissionSeller);
    }
    if (priceTaxRate && commissionTaxValue) {
      totalOperatorCommission = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    return totalOperatorCommission;
  };
  const getTotalDeduction = (orderLineItem = {}) => {
    let discountValueTotal = 0;
    const totalOperatorCommission = getTotalOperatorCommission(orderLineItem);
    if (orderLineItem.isDiscountAllowed) {
      discountValueTotal = parseFloat(getDynamicDiscount(orderLineItem));
    }
    if (isOrderBasedCommission) {
      discountValueTotal += shopifyShippingAmount(orderLineItem);
      discountValueTotal += parseFloat(showShippingTaxDeduction(orderLineItem, true));
    }
    const totalDeduction = totalOperatorCommission + parseFloat(discountValueTotal || 0);
    return totalDeduction;
  };

  const getOperatorCommissionTax = (orderLineItem = {}) => {
    const { priceTaxRate, commissionTax: commissionTaxValue = 0 } = orderLineItem || {};
    const operatorCommission =
      priceTaxRate && !commissionTaxValue ? getItemCommissionTax(orderLineItem) : parseFloat(commissionTaxValue);
    return operatorCommission;
  };
  const getItemPriceTax = (orderLineItem = {}) => {
    const { priceTaxRate, shippingTax = 0 } = orderLineItem || {};
    let totalPriceTax = 0;
    if (isOrderBasedCommission) {
      totalPriceTax = addGstPst(orderLineItem, true);
      totalPriceTax += parseFloat(showShippingTax(orderLineItem));
      return totalPriceTax;
    }
    totalPriceTax = parseFloat(price) - parseFloat(price) / (1 + parseFloat(priceTaxRate) / 100);
    return totalPriceTax;
  };

  const checkShippingTax = (lineData = []) => {
    let isShippingTax = false;
    lineData.forEach((data) => {
      if (data.shippingTax) {
        isShippingTax = true;
      }
    });
    return isShippingTax;
  };

  const checkPriceTaxRate = (lineData = []) => {
    let isTaxInclusive = false;
    lineData.forEach((data) => {
      if (data.priceTaxRate) {
        isTaxInclusive = true;
      }
    });
    return isTaxInclusive;
  };

  const checkSku = (lineData = []) => {
    let isSku = false;
    lineData.forEach((data) => {
      if (data.sku) {
        isSku = true;
      }
    });
    return isSku;
  };

  const checkDiscount = (lineData = []) => {
    let isDiscount = false;
    lineData.forEach((data) => {
      if (data.vendorDiscount) {
        isDiscount = true;
      }
    });
    return isDiscount;
  };

  const isShippingTax = checkShippingTax(orderLineItems || []);
  const isTaxInclusive = checkPriceTaxRate(orderLineItems || []);
  const isSku = checkSku(orderLineItems || []);
  const isDiscount = checkDiscount(orderLineItems || []);
  const { priceTaxRate } = getSellerCommission(orderLineItems);
  if (priceTaxRate && !commissionTax) {
    totals.commissionTax = 0;
  }
  // const totalCommission = parseFloat(totals.commissionTax) + parseFloat(totals.commissionSeller);
  const totalOrderPayout = totals.commissionVendor;
  const totalShippingPayout = parseFloat(totals.shippingAmount) + parseFloat(totals.shippingTax);
  let totalVendorPayout = totalOrderPayout;
  // const priceTax = parseFloat(price) - parseFloat(price) / (1 + parseFloat(priceTaxRate) / 100);
  // const OrderTax = priceTax + shippingTax;
  if (!isExcludeShippingAmount) {
    totalVendorPayout += totalShippingPayout;
  }

  const getVendorShippingAddress = (data, users) => {
    const { address = "", city = "", pincode = "", country = "" } = data || {};
    const { firstName = "", lastName = "", phoneNumber = "" } = users || {};
    return (
      <>
        {firstName}
        {lastName}
        {firstName || lastName ? <br /> : ""}
        {phoneNumber}
        {phoneNumber ? <br /> : ""}
        {address}
        {address ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {country}
        {country ? <br /> : ""}
        {pincode}
      </>
    );
  };

  const getShippingAddress = (sName, sAddress) => (
    <>
      {sName}
      <br />
      {sAddress.address}
      {sAddress.address ? <br /> : ""}
      {sAddress.city}
      {sAddress.city ? <br /> : ""}
      {sAddress.country}
      {sAddress.pinCode ? `, ${sAddress.pinCode}` : ""}
    </>
  );

  const flatPriceCal = (flatPrice) => {
    let flatPriceSet = 0;
    let totalQuantity = 0;
    orderLineItems.map((itemNo) => {
      totalQuantity += itemNo.actualQuantity;
    });
    flatPriceSet = flatPrice;
    //  flatPriceSet /= totalQuantity;
    return flatPriceSet;
  };
  let transactionFeeTotal = 0;
  let transactionFeeAllTotal = 0;
  const transactionFeeCal = (productSubTotal, orderDataSet) => {
    let finalPrice = 0;
    const dataList = {};
    let totalFee = 0;
    transactionFeeAllTotal = 0;
    const { transactionFeeLines = [] } = orderDataSet;
    transactionFeeLines.map((item) => {
      if (item.feeType === constant.PERCENTAGE) {
        totalFee = (productSubTotal * baseHelper.getPrice(item.feeValue)) / 100;
      }
      if (item.feeType === constant.FLAT) {
        totalFee = parseFloat(item.feeValue) / (totalOrderAllProductCountTran || 1);
      }
      const { labelName, ...restData } = item;
      restData.total = baseHelper.getPrice(totalFee);
      if (!dataList[item.labelName]) {
        dataList[item.labelName] = [restData];
      } else {
        dataList[item.labelName] = [...dataList[item.labelName], restData];
      }
      finalPrice += parseFloat(restData.total);
    });

    return {
      dataList,
      finalPrice,
    };
  };

  const sortFeeData = (feeData) => {
    return feeData.sort((first, second) => {
      if (first.feeType.toUpperCase() > second.feeType.toUpperCase()) {
        return -1;
      }
      if (first.feeType.toUpperCase() < second.feeType.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  function dataArrySum(taxList, orderLineItem) {
    const listData = [];
    const productPrice =
      parseFloat(orderLineItem.markUpPrice) +
      parseFloat(orderLineItem.price) -
      parseFloat(orderLineItem.sellerDiscount) -
      getDynamicDiscount(orderLineItem) / orderLineItem.quantity;
    taxList.forEach((item) => {
      if (listData[item.title]) {
        listData[item.title] = {
          ...item,
          price: parseFloat(productPrice * item.rate) * orderLineItem.actualQuantity,
        };
      } else {
        listData[item.title] = {
          ...item,
          price: parseFloat(productPrice * item.rate) * orderLineItem.actualQuantity,
        };
      }
    });
    return Object.values(listData);
  }
  const transactionFeeList = (productSubTotal = 0, lineData) => {
    let totalVendorDiscount =
      parseFloat(lineData.vendorDiscount || 0) * lineData.actualQuantity + parseFloat(productSubTotal);
    if (!shopifyShipping.amount && shopifyShipping.term === FLAT) {
      totalVendorDiscount -= lineData.shippingAmount;
    }
    const { finalPrice, dataList } = transactionFeeCal(totalVendorDiscount, viewOrderData);
    transactionFeeTotal = finalPrice;
    let totalData = 0;
    let showLabelName = 0;
    const showTransaction = Object.keys(dataList).map((showLabel) => {
      const sortFeeArray = sortFeeData(dataList[showLabel]);
      return sortFeeArray.map((data) => {
        if (sortFeeArray.length > 1) {
          showLabelName = data.feeType === constant.PERCENTAGE ? showLabel : "";
          totalData = parseFloat(sortFeeArray[0].total) || 0;
          totalData += flatPriceCal(parseFloat(sortFeeArray[1].total || 0)) * lineData.actualQuantity;
        } else {
          totalData =
            data.feeType === constant.PERCENTAGE
              ? parseFloat(sortFeeArray[0].total)
              : flatPriceCal(parseFloat(sortFeeArray[0].total || 0)) * lineData.actualQuantity;
          showLabelName = showLabel;
        }
        const rowTotal = moneyFormat + baseHelper.getPrice(totalData);
        let calFlatPrice = data.feeType === constant.PERCENTAGE ? data.total : data.total;
        if (data.feeType === constant.FLAT) {
          calFlatPrice = flatPriceCal(data.total);
          calFlatPrice *= lineData.actualQuantity;
          transactionFeeAllTotal += parseFloat(calFlatPrice);
        } else {
          transactionFeeAllTotal += parseFloat(data.total);
        }

        return (
          <tr className="line-remove">
            <td>{showLabelName}</td>
            <td>{data.feeType === constant.PERCENTAGE ? "-" : "-"}</td>
            {isSku && !invoiceSetting.sku.isHide && <td>{data.feeType === constant.PERCENTAGE ? "-" : "-"}</td>}
            <td>
              {`${moneyFormat}${baseHelper.getPrice(calFlatPrice)}`}
              {data.feeType === constant.PERCENTAGE &&
                `(${baseHelper.getPrice(data.feeValue)}${data.feeType === constant.PERCENTAGE ? "%" : ""}) `}
            </td>
            <td>{data.showLabel ? "-" : "-"}</td>
            {isDiscount && !invoiceSetting?.discount?.isHide ? <td>-</td> : ""}
            <td>{data.feeType === constant.FLAT ? rowTotal : sortFeeArray.length === 1 ? rowTotal : ""}</td>
          </tr>
        );
      });
    });
    return showTransaction;
  };

  let showShipping = false;
  const showShippingOnTranFeeSetting = (orderLineItem) => {
    const { shippingAmount = "", thirdPartyShippingAmount = 0, requiresShipping = true } = orderLineItem || {};
    const termList = [VENDOR_MANAGE, NONE];
    const termListOther = [EQUAL, FLAT];

    if (requiresShipping === "false") {
      return null;
    }
    if (!isThirdPartyShipping && termList.includes(shopifyShipping.term) && shopifyShipping.amount && !shippingAmount) {
      showShipping = true;
    }
    if (
      !isThirdPartyShipping &&
      termListOther.includes(shopifyShipping.term) &&
      shopifyShipping.amount &&
      shippingAmount
    ) {
      showShipping = shopifyShipping.term !== EQUAL;
    }
    if (isThirdPartyShipping && shopifyShipping.amount) {
      showShipping = true;
    }
    if (!showShipping) {
      return null;
    }
    let shippingAmountShow = 0;
    let totalShippingWithTax = 0;

    if (isThirdPartyShipping && thirdPartyShippingAmount) {
      shippingAmountShow = thirdPartyShippingAmount || 0;
    } else {
      shippingAmountShow = shopifyShipping.amount / vendorLineCount || 0;
      shippingAmountShow = parseFloat(shippingAmountShow / vendorProductCount);
    }
    totalShippingWithTax = shippingAmountShow + parseFloat(showShippingTax(orderLineItem));

    return (
      <tr className="line-remove">
        <td style={{ textAlign: "left" }}>{invoiceSetting.shipping.label}</td>
        {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
        <td>-</td>
        <td>{`${moneyFormat}${baseHelper.getPrice(shippingAmountShow)}`}</td>
        <td>{`${moneyFormat}${showShippingTax(orderLineItem)}`}</td>
        {isDiscount && !invoiceSetting?.discount?.isHide && <td>-</td>}
        <td>{`${moneyFormat}${baseHelper.getPrice(totalShippingWithTax)}`}</td>
      </tr>
    );
  };

  let totalLineTax = 0;
  let totalLineTaxNoShipping = 0;

  const showItemTaxGst = (orderLineItem) => {
    const { taxLines = [], vendorGst = "", vendorPst = "", vendorVat = "", totalTax = 0, actualQuantity = 0 } =
      orderLineItem || {};
    // check store
    const canadaStore = taxLines.filter((item) => item.title === PST_L || item.title === GST_L).length;
    const canadaVatStore = taxLines.filter((item) => item.title !== PST_L && item.title !== GST_L).length;

    if (!canadaStore && vendorVat) {
      return null;
    }

    if (!canadaVatStore && canadaStore && vendorGst && vendorPst) {
      return null;
    }
    // check shipping tax deduction base on vender register gst and pst or vat
    const { shippingTaxDeduction } = taxLinesData(orderLineItem);

    if (shippingTaxDeduction) {
      return null;
    }

    let taxValueData = "";
    totalLineTax = 0;
    totalLineTaxNoShipping = 0;
    let taxName = "";
    if (taxLines && taxLines.length > 0) {
      taxValueData = dataArrySum(taxLines, orderLineItem).map((tax) => {
        taxName = tax.title;
        if (vendorGst && taxName.toUpperCase() === GST_L) {
          return null;
        }
        if (vendorPst && taxName.toUpperCase() === PST_L) {
          return null;
        }
        totalLineTax += parseFloat(tax.price);
        totalLineTaxNoShipping += parseFloat(tax.price);
        return (
          <div style={{ textAlign: "right" }}>
            {`${moneyFormat}${baseHelper.getPrice(tax.price)}(${baseHelper.getPrice(tax.rate * 100)}%)${
              taxName.toUpperCase() === PST_L ? tax.title : ""
            }${taxName.toUpperCase() === GST_L ? tax.title : ""}`}
          </div>
        );
      });
    }
    if (!showShipping) {
      totalLineTax += parseFloat(showShippingTax(orderLineItem));
    }
    return (
      <tr className="line-remove">
        <td>Item Tax</td>
        {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
        <td>-</td>
        <td>{taxValueData}</td>
        <td>
          {!showShipping && showShippingTax(orderLineItem) ? `${moneyFormat}${showShippingTax(orderLineItem)}` : "-"}
        </td>
        {isDiscount && !invoiceSetting?.discount?.isHide ? <td>-</td> : ""}
        <td>{`${moneyFormat}${baseHelper.getPrice(totalLineTax)}`}</td>
      </tr>
    );
  };
  const totalDeductionCount = (noShippingTax, fees) => {
    totalDeductionValue += noShippingTax + fees;
  };

  let flatShippingMessage = "";
  if (transactionFeeLinesData && !shopifyShipping.amount && shopifyShipping.term === "flat") {
    flatShippingMessage = cms("label.tooltipHelpText")
      ? `#${cms("label.tooltipHelpText")}`
      : "#Marketplace managed shipping is not added";
  }

  // const { sellerCommissionTax, commissionSeller, vendorCommissionTax, commissionVendor } = orderLineItems || {};
  if (invoiceSettingLoading || sellerSettingLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <StyledSlip font={currentUser && currentUser.font} className="container">
        <div className="invoice-company text-inverse ">
          {(logo && <img src={logo} className="brand-logo" id="tableBanner" alt="" />) || (
            <DisplayText size="medium">{sellerInfo.brandName}</DisplayText>
          )}
          <span className="pull-right hidden-print" id="hide-element">
            <a href="" onClick={baseHelper.printInvoice} className="btn-white">
              <i className="fa fa-print t-plus-1 fa-fw fa-lg" />
              {` ${cms("label.print")} `}
            </a>
          </span>
        </div>
        {/* invoice-header */}
        <InvoiceHeader
          invoiceSetting={invoiceSetting}
          vendorName={vendorName}
          vendorAddress={vendorAddress}
          customerAddress={customerAddress}
          invoiceNumberPrefix={invoiceNumberPrefix}
          dueDate={dueDate}
          orderLineItems={orderLineItems}
          createdAt={createdAt}
          shopifyOrderId={shopifyOrderId}
          vat={vat}
          cms={cms}
        />
        {/* end invoice-header */}
        {/* invoice-content */}
        <div className="invoice-content">
          <div className="table-responsive">
            <table className="table table-invoice">
              <thead>
                <ColumnData invoiceSetting={invoiceSetting} isDiscount={isDiscount} isSku={isSku} />
              </thead>
              {orderLineItems &&
                orderLineItems.map((item) => (
                  <>
                    <tbody>
                      {orderLineItems.length > 1 ? (
                        <LineItemList
                          invoiceSetting={invoiceSetting}
                          lineitemNumber={`${cms("label.lineItem")} # ${lineitemNumber++}`}
                          isSku={isSku}
                          item={item}
                          isDiscount={isDiscount}
                        />
                      ) : (
                        <tr className="line-remove">
                          <th style={{ textAlign: "left" }}> Line item details</th>
                        </tr>
                      )}
                      <ProductItem
                        invoiceSetting={invoiceSetting}
                        moneyFormat={moneyFormat}
                        isSku={isSku}
                        item={item}
                        isDiscount={isDiscount}
                        getItemPrice={getItemPrice}
                        getItemPriceTax={getItemPriceTax}
                        isOrderBasedCommission={isOrderBasedCommission}
                        getSubtotalAmount={getSubtotalAmount}
                        subtotalAmount={getSubtotalAmount(item)}
                        isTaxInclusive={isTaxInclusive}
                        addGstPstText={addGstPstText}
                        getDynamicDiscount={getDynamicDiscount}
                        isDynamicDiscount={isDynamicDiscount}
                      />
                      <p className="description">{`${item.description}`}</p>
                      {isOrderBasedCommission && showShippingOnTranFeeSetting(item)}
                      {!showShipping &&
                        item.requiresShipping === "true" &&
                        !isExcludeShippingAmount &&
                        !invoiceSetting.shipping.isHide &&
                        baseHelper.getPrice(item.shippingAmount) > 0 && (
                          <ShippingItem
                            invoiceSetting={invoiceSetting}
                            moneyFormat={moneyFormat}
                            isSku={isSku}
                            isOrderBasedCommission={isOrderBasedCommission}
                            item={item}
                            isDiscount={isDiscount}
                            isShippingTax={isShippingTax}
                            isTaxInclusive={isTaxInclusive}
                            getTotalShipping={getTotalShipping}
                            shippingTaxType={shippingTaxType}
                            showShippingTax={showShippingTax}
                          />
                        )}
                      {!invoiceSetting.totalAmount.isHide && (
                        <ProductTotal
                          invoiceSetting={invoiceSetting}
                          moneyFormat={moneyFormat}
                          isSku={isSku}
                          item={item}
                          isDiscount={isDiscount}
                          isShippingTax={isShippingTax}
                          isTaxInclusive={isTaxInclusive}
                          getTotalShipping={getTotalShipping}
                          getItemPriceTax={getItemPriceTax}
                          getTotalAmount={getTotalAmount}
                          getItemPrice={getItemPrice}
                          shopifyLineItemShippingAmount={shopifyLineItemShippingAmount}
                          isOrderBasedCommission={isOrderBasedCommission}
                          getDynamicDiscount={getDynamicDiscount}
                          isDynamicDiscount={isDynamicDiscount}
                          shopifyShipping={shopifyShipping}
                          transactionFeeLinesData={transactionFeeLinesData}
                          cms={cms}
                        />
                      )}

                      {!invoiceSetting.sellerCommission.isHide && (
                        <OperatorCommission
                          invoiceSetting={invoiceSetting}
                          isSku={isSku}
                          label={cms("label.deduction")}
                          isDiscount={isDiscount}
                        />
                      )}
                      {item.isDiscountAllowed && (
                        <DynamicDiscount
                          invoiceSetting={invoiceSetting}
                          isSku={isSku}
                          isDiscount={isDiscount}
                          moneyFormat={moneyFormat}
                          item={item}
                          dynamicDiscount={cms("label.dynamicDiscount")}
                          shopifyDiscount={cms("label.shopifyDiscount")}
                          getDynamicDiscount={getDynamicDiscount}
                        />
                      )}

                      {!invoiceSetting.sellerCommission.isHide && (
                        <OperatorCommissionDetails
                          invoiceSetting={invoiceSetting}
                          moneyFormat={moneyFormat}
                          isSku={isSku}
                          item={item}
                          isDiscount={isDiscount}
                          isShippingTax={isShippingTax}
                          isTaxInclusive={isTaxInclusive}
                          getTotalAmount={getTotalAmount}
                          isOrderBasedCommission={isOrderBasedCommission}
                          getOperatorCommissionTax={getOperatorCommissionTax}
                          getTotalOperatorCommission={getTotalOperatorCommission}
                          commissionTaxRate={commissionTaxRate}
                          commissionType={commissionType}
                          commissionTaxType={commissionTaxType}
                        />
                      )}
                      {orderData?.isOrderBasedCommission &&
                        transactionFeeList(baseHelper.getPrice(getTotalAmount(item) + getTotalShipping(item)), item)}
                      {isOrderBasedCommission && showItemTaxGst(item)}
                      {isOrderBasedCommission && totalDeductionCount(totalLineTaxNoShipping, transactionFeeAllTotal)}
                      {isOrderBasedCommission && (
                        <DeductionsShipping
                          orderLineItem={item}
                          isSku={isSku}
                          showShipping={showShipping}
                          invoiceSetting={invoiceSetting}
                          vendorLineCount={vendorLineCount}
                          showShippingTax={showShippingTax}
                          shopifyShipping={shopifyShipping}
                          vendorProductCount={vendorProductCount}
                          moneyFormat={moneyFormat}
                          isDiscount={isDiscount}
                          isThirdPartyShipping={isThirdPartyShipping}
                        />
                      )}
                      {!invoiceSetting.totalDeduction.isHide && (
                        <TotalDeduction
                          invoiceSetting={invoiceSetting}
                          moneyFormat={moneyFormat}
                          isSku={isSku}
                          item={item}
                          isDiscount={isDiscount}
                          isShippingTax={isShippingTax}
                          isTaxInclusive={isTaxInclusive}
                          getTotalAmount={getTotalAmount}
                          isOrderBasedCommission={isOrderBasedCommission}
                          showShippingTax={showShippingTax}
                          showShippingTaxDeduction={showShippingTaxDeduction}
                          getDeductionTotal={getDeductionTotal}
                          getTotalDeduction={getTotalDeduction}
                          commissionTaxRate={commissionTaxRate}
                          commissionType={commissionType}
                          commissionTaxType={commissionTaxType}
                          totalLineTaxNoShipping={totalLineTaxNoShipping}
                          totalLineTax={totalLineTax}
                          transactionFeeTotal={transactionFeeAllTotal}
                        />
                      )}
                      {orderLineItems.length && !invoiceSetting.orderTotal.isHide && (
                        <TotalLineTotal
                          invoiceSetting={invoiceSetting}
                          isSku={isSku}
                          item={item}
                          isDiscount={isDiscount}
                          moneyFormat={moneyFormat}
                          getLinesSubTotal={getLinesSubTotal}
                          transactionFeeAllTotal={transactionFeeAllTotal}
                        />
                      )}
                    </tbody>
                    <br />
                  </>
                ))}
            </table>
          </div>
          {/* invoice-price */}
          <OrderSummary
            invoiceSetting={invoiceSetting}
            isSku={isSku}
            shippingAmountValue={shippingAmountValue}
            moneyFormat={moneyFormat}
            isOrderBasedCommission={isOrderBasedCommission}
            summaryAmount={summaryAmount}
            totalVendorPayout={totalVendorPayout}
            shippingTax={shippingTax}
            totalShippingTax={totalShippingTax}
            totalSummaryShippingTax={totalSummaryShippingTax}
            totalGst={totalGst}
            isDynamicDiscount={isDynamicDiscount}
            totalDeductionTax={totalDeductionTax}
            totalDeductionValue={totalDeductionValue}
            totalDiscountValue={totalDiscountValue}
            deductionValue={deductionValue}
            vendorProductCount={orderLineItems.length}
          />
          <br />

          {isTaxInclusive && !isOrderBasedCommission && <h2>{`*${cms("label.taxInclusive")}`}</h2>}
          {flatShippingMessage && <h2>{flatShippingMessage}</h2>}
          {/* end invoice-price */}
        </div>
        {/* end invoice-content */}
        {/* invoice-note */}
        <FooterSection
          invoiceSetting={invoiceSetting}
          type={type}
          constant={constant}
          moneyFormat={moneyFormat}
          isVendor={isVendor}
          getVendorShippingAddress={getVendorShippingAddress}
          fulfillmentData={fulfillmentData}
          sellerInfo={sellerInfo}
          getShippingAddress={getShippingAddress}
          sellerName={sellerName}
          sellerAddress={sellerAddress}
          sellerTaxInfo={sellerTaxInfo}
          cms={cms}
          value={value}
        />
        {/* invoice-footer */}
        <div className="invoice-footer">{`${cms("label.footerNote")}`}</div>
      </StyledSlip>
    </>
  );
};
OrderTaxInvoiceView.defaultProps = {
  fulfillment: () => {},
  logo: "",
  orderData: {},
  orderLineItems: [],
  sellerInfo: {},
  vendorInfo: {},
};
OrderTaxInvoiceView.propTypes = {
  fulfillment: PropTypes.func,
  logo: PropTypes.string,
  orderData: PropTypes.shape(PropTypes.object),
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
  sellerInfo: PropTypes.shape(PropTypes.object),
  vendorInfo: PropTypes.shape(PropTypes.object),
};
export default OrderTaxInvoiceView;
