import React, { useContext } from "react";
import { Checkbox, TextField } from "@shopify/polaris";

import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";

const UPS = (props) => {
  const { error, handleUpsApiChange, handleValidation } = props;
  const { cms, dropship, upsAccessLicenseNumber } = useContext(FulfillmentContext);
  const { accessLicenseNumber = "", accountNumber = "", password = "", username = "" } =
    (dropship && dropship.ups) || {};

  return (
    <>
      <TextField
        label={cms("label.license")}
        value={accessLicenseNumber}
        placeholder={cms("label.license").replace("*", "")}
        onChange={(value) => {
          handleUpsApiChange("accessLicenseNumber", value);
        }}
        onBlur={() => {
          handleValidation(
            constant.ACCOUNT_LICENSE_NUMBER,
            dropship && dropship.ups && dropship.ups.accessLicenseNumber
          );
        }}
        error={error && !accessLicenseNumber && error.accessLicenseNumber}
      />
      <br />
      <TextField
        label={cms("label.accountNumber")}
        value={accountNumber}
        placeholder={cms("label.accountNumber").replace("*", "")}
        onChange={(value) => {
          handleUpsApiChange("accountNumber", value);
        }}
        onBlur={() => {
          handleValidation(constant.ACCOUNT_NUMBER, dropship && dropship.ups && dropship.ups.accessLicenseNumber);
        }}
        error={error && !accountNumber && error.accountNumber}
      />
      <br />
      <TextField
        label={cms("label.password")}
        value={password}
        placeholder={cms("label.password").replace("*", "")}
        onChange={(value) => {
          handleUpsApiChange("password", value);
        }}
        onBlur={() => {
          handleValidation(constant.PASSWORD, dropship && dropship.ups && dropship.ups.accessLicenseNumber);
        }}
        error={error && !password && error.password}
      />
      <br />
      <TextField
        label={cms("label.userName")}
        value={username}
        placeholder={cms("label.userName").replace("*", "")}
        onChange={(value) => {
          handleUpsApiChange("username", value);
        }}
        onBlur={() => {
          handleValidation(constant.USER_NAME, dropship && dropship.ups && dropship.ups.accessLicenseNumber);
        }}
        error={error && !username && error.userName}
      />
      {upsAccessLicenseNumber && (
        <>
          <br />
          <Checkbox
            label={cms("label.orderline")}
            helpText={cms("helpText.orderline")}
            checked={(dropship && dropship.ups && dropship.ups.isAutoFulfill) || false}
            onChange={(value) => handleUpsApiChange("isAutoFulfill", value)}
          />
        </>
      )}
    </>
  );
};

export default withFeature(withErrorBoundary(UPS), {
  feature: constant.UPS,
});
