import {
  CircleTickOutlineMinor,
  ChecklistMajor,
  DiscountsMajor,
  DiscountCodeMajor,
  ImagesMajor,
  PackageMajor,
  OrdersMajor,
  SecureMajor,
  ProfileMajor,
  TipsMajor,
  BalanceMajor,
  PaymentsMajor,
  NotificationMajor,
} from "@shopify/polaris-icons";

const settingConfig = (cms) => {
  const config = {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.profile"),
        label: cms("common.actions.dashboard.label.profile"),
        link: "/profile",
        icon: ProfileMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.commission"),
        label: cms("common.actions.dashboard.label.commission"),
        link: "/commission",
        icon: TipsMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.terms"),
        label: cms("common.actions.dashboard.label.terms"),
        link: "/terms",
        icon: SecureMajor,
      },
    ],
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.acceptance"),
        label: cms("common.actions.orders.label.acceptance"),
        link: "/setting/order",
        icon: OrdersMajor,
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.payouts"),
        label: cms("common.actions.payments.label.payouts"),
        link: "/payouts",
        icon: BalanceMajor,
      },
      {
        caption: cms("common.actions.payments.caption.stripe"),
        label: cms("common.actions.payments.label.stripe"),
        link: "/setting/payment",
        icon: PaymentsMajor,
      },
      {
        caption: cms("common.actions.payments.caption.paypal"),
        label: cms("common.actions.payments.label.paypal"),
        link: "/setting/payment",
        icon: PaymentsMajor,
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.approval"),
        label: cms("common.actions.products.label.approval"),
        link: "/setting/product",
        icon: CircleTickOutlineMinor,
      },
      {
        caption: cms("common.actions.products.caption.discount"),
        label: cms("common.actions.products.label.discount"),
        link: "/setting/discount",
        icon: DiscountsMajor,
      },
      {
        caption: cms("common.actions.products.caption.productFormLabel"),
        label: cms("common.actions.products.label.productFormLabel"),
        link: "/setting/product/label",
        icon: ChecklistMajor,
      },
      {
        caption: cms("common.actions.products.caption.markup"),
        label: cms("common.actions.products.label.markup"),
        link: "/setting/markup",
        icon: DiscountCodeMajor,
      },
      {
        caption: cms("common.actions.products.caption.shipping"),
        label: cms("common.actions.products.label.shipping"),
        link: "/shipping",
        icon: PackageMajor,
      },
    ],
    "/providers": [
      {
        caption: cms("common.actions.vendors.caption.manageVendor"),
        label: cms("common.actions.vendors.label.manageVendor"),
        link: "/provider/manage",
        icon: ProfileMajor,
      },
      {
        caption: cms("common.actions.vendors.caption.brandPage"),
        id: "brandPage",
        label: cms("common.actions.vendors.label.brandPage"),
        icon: ImagesMajor,
        action: function action() {
          const { token, domain } = this;
          const url = `${domain}/builder/${token}`;
          window.open(url);
        },
      },
      {
        caption: cms("common.actions.vendors.caption.muteNotification"),
        label: cms("common.actions.vendors.label.muteNotification"),
        link: "/setting/notification",
        icon: NotificationMajor,
      },
    ],
  };

  const title = {
    "/": cms("common.actions.title.setting"),
    "/orders": cms("common.actions.title.orderSettings"),
    "/payments": cms("common.actions.title.paymentSettings"),
    "/products": cms("common.actions.title.productSettings"),
    "/providers": cms("common.actions.title.vendorSettings"),
  };

  return { config, title };
};

export default settingConfig;
