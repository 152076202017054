import React, { useContext, useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Caption, ChoiceList, Layout, PageActions, TextContainer } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";
import { CHAT_SETTING } from "app/userManagement/apollo/mutation";
import { GET_CHAT_SETTING } from "app/userManagement/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Banner, Spinner, Toast } from "lib/components";

// import subFeatures

const Setting = () => {
  const { cms, chatSetting, setSetting, history, isLoading } = useContext(PrivateContext);
  const { gql, VENDOR_CUSTOMER_CHAT, VENDOR_OPERATOR_CHAT } = constant;

  const [selected, setSelected] = useState([]);

  const [disable, setDisable] = useState(false);
  const [lastUpdated, SetLastUpdated] = useState(false);
  const [toast, setToast] = useState(false);

  const [toastMessage, SetToastMessage] = useState("");
  const [updated, SetUpdated] = useState("");

  const [banner, setBanner] = useState({
    status: "",
    title: "",
    isOpen: false,
  });

  const handleChange = useCallback((value) => [setSelected(value), setDisable(true), setToast(false)], []);

  const [updateSellerChatSetting, { loading }] = useMutation(CHAT_SETTING);

  const { data, loading: chatLoading, refetch } = useQuery(GET_CHAT_SETTING);

  const responseData = baseHelper.getResponseData(data, gql.GET_OPERATOR_SETTING);

  useEffect(() => {
    const { chat = {} } = responseData;
    if (!chat) {
      SetLastUpdated(false);
      setSelected((prev) => [...prev, VENDOR_OPERATOR_CHAT, VENDOR_CUSTOMER_CHAT]);
    }
    if (chat) {
      const { isCustomer, isVendor, updatedAt = "" } = chat;
      setSetting({
        isCustomer,
        isVendor,
      });
      if (isVendor) {
        setSelected((prev) => [...prev, VENDOR_OPERATOR_CHAT]);
      }
      if (isCustomer) {
        setSelected((prev) => [...prev, VENDOR_CUSTOMER_CHAT]);
      }
      SetLastUpdated(true);
      SetUpdated(updatedAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  useEffect(() => {
    const isVendorKey = selected.includes(VENDOR_OPERATOR_CHAT);
    const isCustomerKey = selected.includes(VENDOR_CUSTOMER_CHAT);
    setSetting({
      isVendor: isVendorKey,
      isCustomer: isCustomerKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, setSetting]);

  if (chatLoading || isLoading) {
    return <Spinner />;
  }

  const onAction = async () => {
    const message = lastUpdated ? cms("message.success.update") : cms("message.success.save");
    try {
      const response = await updateSellerChatSetting({
        variables: {
          input: {
            isCustomer: chatSetting.isCustomer,
            isVendor: chatSetting.isVendor,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.UPDATE_SELLER_CHAT_SETTING);
      if (responseError) {
        setBanner({
          title: responseError,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }
      setToast(true);
      setDisable(false);
      SetToastMessage(message);
      refetch();
    } catch (exception) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
        isOpen: true,
      });
    }
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.chatSetting.title")}
        description={cms("section.chatSetting.description")}
      >
        <Card
          title={[
            cms("section.chatSetting.title"),
            updated && <Caption>{`Last updated at: On ${baseHelper.formatDate(updated)}`}</Caption>,
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.chatSetting.cardDescription")}</TextContainer>
            <br />
            <div className="chat-setting">
              <ChoiceList
                id="chat-setting"
                allowMultiple
                choices={[
                  {
                    label: cms("section.chatSetting.vendor.label"),
                    value: VENDOR_OPERATOR_CHAT,
                  },
                  {
                    label: cms("section.chatSetting.customer.label"),
                    value: VENDOR_CUSTOMER_CHAT,
                  },
                ]}
                selected={selected}
                onChange={handleChange}
              />
            </div>
            <div className="page-action">
              {lastUpdated && (
                <PageActions
                  primaryAction={{
                    id: "cancelChatSetting",
                    content: cms("common.button.update"),
                    onAction: () => onAction(),
                    loading,
                    disabled: !disable,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelChatSetting",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </div>
          </Card.Section>
        </Card>
        {!lastUpdated && (
          <PageActions
            primaryAction={{
              id: "cancelChatSetting",
              content: cms("common.button.submit"),
              onAction: () => onAction(),
              loading,
              disabled: !disable,
            }}
            secondaryActions={[
              {
                id: "cancelChatSetting",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
        {toast && <Toast message={toastMessage} />}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(Setting);
