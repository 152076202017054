import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { PageActions, Layout } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// imports mutations
import { UPDATE_BRANDING } from "app/setup/apollo/mutations";

// import contexts
import { PrivateBrandingContext, PrivateContext } from "lib/context";
import { BrandingProvider } from "./context/context";

import validate from "./yup";

import CustomPage from "./subFeatures";

const {
  BACKGROUND,
  BRAND_DESCRIPTION,
  BUTTON_TEXT,
  BUTTON,
  COLOR,
  CONTACT,
  CONTENT,
  COPY,
  DEFAULT_BUTTON_COLOR,
  DEFAULT_FINISH_DESCRIPTION,
  DEFAULT_HEADER_COLOR,
  DEFAULT_TEXT_COLOR,
  DEFAULT_WELCOME_DESCRIPTION,
  DEFAULT_HEADING_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_CONTENT_COLOR,
  DEFAULT_MENU_COLOR,
  DEFAULT_TOAST_TEXT_COLOR,
  DEFAULT_TOAST_COLOR,
  DOMAIN,
  EMAIL,
  FAVICON,
  FINISH_COVER,
  FOOTER,
  gql: { UPDATE_BRANDING: UPDATE_BRANDING_GQL },
  HEADER_TEXT,
  HEADER,
  HEADING,
  HEX,
  IMAGE_URL,
  IMAGE,
  LOGIN,
  MENU,
  REGISTER,
  TOAST_BACKGROUND_COLOR,
  TOAST_TEXT,
  WELCOME_COVER,
  // DEFAULT_ONBOARDING_TITLE
} = constant;

const OperatorBranding = () => {
  const { setBrandingData = () => { }, branding = {} } = useContext(PrivateBrandingContext);
  const { currentUser = {}, history, cms = () => { } } = useContext(PrivateContext);

  const {
    brand = {},
    emailSign = "",
    footerText = "",
    isHideMcInfo = false,
    loginText = "",
    logo = "",
    // plan = {},
    register: userRegister,
    shop,
    style,
  } = currentUser;

  const {
    cover: brandCover = {},
    description: brandDescription = "",
    favicon: brandFavicon = {},
    finish = {
      cover: {},
      description: {},
    },
    welcome = {
      cover: {},
      description: {},
    },
  } = brand || {};
  const { cover: finishCover, description: finishSetupDescription } = finish || {};
  const { cover: welcomeCover, description: welcomeCoverDescription } = welcome || {};
  const { imageUrl = "" } = brandCover || {};
  const { imageUrl: faviconImageUrl = "" } = brandFavicon || {};
  const { imageUrl: finishImage = "" } = finishCover || {};
  const { imageUrl: welcomeImage = "" } = welcomeCover || {};
  const { description: finishDesc = "" } = finishSetupDescription || {};
  const { description: welcomeDesc = "" } = welcomeCoverDescription || {};
  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [message, setMessage] = useState("");
  const [prevButtonHex, setPrevButtonHex] = useState("");
  const [prevButtonTextHex, setPrevButtonTextHex] = useState("");
  const [prevHeaderHex, setPrevHeaderHex] = useState("");
  const [prevHeaderTextHex, setPrevHeaderTextHex] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [image, setImage] = useState((logo && { url: logo }) || "");
  const [faviconImage, setFaviconImage] = useState(faviconImageUrl);
  const [coverImageURL, setCoverImage] = useState(imageUrl);
  const [onboarding, setOnboarding] = useState({
    finishCoverImage: finishImage,
    finishDescription: finishDesc || DEFAULT_FINISH_DESCRIPTION,
    welcomeCoverImage: welcomeImage,
    // onBoardingTitle: DEFAULT_ONBOARDING_TITLE,
    welcomeDescription: welcomeDesc || DEFAULT_WELCOME_DESCRIPTION,
  });

  const [description, setDescription] = useState(brandDescription);
  const [domain, setDomain] = useState(currentUser.domain || "");
  const termsLink = brand && brand.termsLink;
  const policyLink = brand && brand.policyLink;
  const [isHeaderCopy, setHeaderCopy] = useState(false);
  const [isHideContact, setContact] = useState(isHideMcInfo);

  const [backgroundColor, setBackgroundColor] = useState(
    style && style.backgroundColor
      ? baseHelper.hexToHSL(style.backgroundColor)
      : baseHelper.hexToHSL(DEFAULT_BACKGROUND_COLOR)
  );

  const [backgroundHex, setBackgroundHex] = useState(
    style && style.backgroundColor ? style.backgroundColor : DEFAULT_BACKGROUND_COLOR
  );

  const [menuColor, setMenuColor] = useState(
    style && style.menuTabColor ? baseHelper.hexToHSL(style.menuTabColor) : baseHelper.hexToHSL(DEFAULT_MENU_COLOR)
  );

  const [menuHex, setMenuHex] = useState(style && style.menuTabColor ? style.menuTabColor : DEFAULT_MENU_COLOR);

  const [headingColor, setheadingColor] = useState(
    style && style.headingColor ? baseHelper.hexToHSL(style.headingColor) : baseHelper.hexToHSL(DEFAULT_HEADING_COLOR)
  );

  const [headingHex, setHeadingHex] = useState(
    style && style.headingColor ? style.headingColor : DEFAULT_HEADING_COLOR
  );

  const [contentColor, setContentColor] = useState(
    style && style.contentColor ? baseHelper.hexToHSL(style.contentColor) : baseHelper.hexToHSL(DEFAULT_CONTENT_COLOR)
  );

  const [contentHex, setContentHex] = useState(
    style && style.contentColor ? style.contentColor : DEFAULT_CONTENT_COLOR
  );

  const [toastBackgroundColor, setToastBackgroundColor] = useState(
    style && style.toastBackground
      ? baseHelper.hexToHSL(style.toastBackground)
      : baseHelper.hexToHSL(DEFAULT_TOAST_COLOR)
  );

  const [toastBackgroundHex, setToastBackgroundHex] = useState(
    style && style.toastBackground ? style.toastBackground : DEFAULT_TOAST_COLOR
  );

  const [toastTextColor, setToastTextColor] = useState(
    style && style.toastText ? baseHelper.hexToHSL(style.toastText) : baseHelper.hexToHSL(DEFAULT_TOAST_TEXT_COLOR)
  );

  const [toastTextHex, setToastTextHex] = useState(
    style && style.toastText ? style.toastText : DEFAULT_TOAST_TEXT_COLOR
  );

  const [header, setHeaderColor] = useState(
    style && style.header && style.header.color
      ? baseHelper.hexToHSL(style.header.color)
      : baseHelper.hexToHSL(DEFAULT_HEADER_COLOR)
  );
  const [headerHex, setHeaderHex] = useState(
    style && style.header && style.header.color ? style.header.color : DEFAULT_HEADER_COLOR
  );
  const [headerText, setHeaderTextColor] = useState(
    style && style.header && style.header.font
      ? baseHelper.hexToHSL(style.header.font)
      : baseHelper.hexToHSL(DEFAULT_TEXT_COLOR)
  );
  const [headerTextHex, setHeaderTextHex] = useState(
    style && style.header && style.header.font ? style.header.font : DEFAULT_TEXT_COLOR
  );
  const [button, setButtonColor] = useState(
    baseHelper.hexToHSL((style && style.button && style.button.color) || DEFAULT_BUTTON_COLOR)
  );
  const [buttonHex, setButtonHex] = useState((style && style.button && style.button.color) || DEFAULT_BUTTON_COLOR);
  const [buttonText, setButtonTextColor] = useState(
    style && style.button && style.button.font
      ? baseHelper.hexToHSL(style.button.font)
      : baseHelper.hexToHSL(DEFAULT_TEXT_COLOR)
  );
  const [buttonTextHex, setButtonTextHex] = useState(
    style && style.button && style.button.font ? style.button.font : DEFAULT_TEXT_COLOR
  );
  const [login, setStateLogin] = useState({
    isDefault: !loginText,
    loginText: loginText || cms("section.loginPageHeader.value.defaultText"),
  });
  const [register, setStateRegister] = useState({
    isDefault: !(userRegister && userRegister.title),
    title: userRegister && userRegister.title ? userRegister.title : cms("section.registerPageContent.value.title"),
    description:
      userRegister && userRegister.desc ? userRegister.desc : cms("section.registerPageContent.value.description"),
  });

  const [email, setStateEmail] = useState({
    isDefault: !emailSign,
    content: emailSign || cms("section.emailSignature.value.defaultText", { shop }),
  });
  const [footer, setStateFooter] = useState({
    isDefault: !footerText,
    content: footerText || cms("section.footerText.value.defaultText"),
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const { finishCoverImage, finishDescription, welcomeCoverImage, welcomeDescription, onBoardingTitle } = onboarding;

  // const [sheetActive, setSheetActive] = useState(false);
  // const [sheetContent, setSheetContent] = useState("");
  // const [sheetTitle, setSheetTitle] = useState("");

  // const learnMore = (title, content) => {
  // setSheetActive(true);
  // setSheetTitle(title);
  // setSheetContent(content);
  // };

  const [setBranding, { loading: brandingLoading }] = useMutation(UPDATE_BRANDING);

  const handleDomainChange = (value) => {
    setDomain(value);
  };

  const handleLoginChange = (field, value) => {
    setStateLogin((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRegisterChange = (field, value) => {
    setStateRegister((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleEmailChange = (field, value) => {
    setStateEmail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleFooterChange = (field, value) => {
    setStateFooter((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleContactChange = (value) => {
    setContact(value);
  };

  const updateBranding = useCallback(
    async (requestData) => {
      try {
        const response = await setBranding({
          variables: { input: requestData },
        });
        const responseError = baseHelper.getResponseError(response.data, UPDATE_BRANDING_GQL);

        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return null;
        }
        setMessage(cms("message.success"));
        setTimeout(() => {
          history.go(); // Todo Update favicon without refresh
        }, 2000);
      } catch (exception) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      }
      return null;
    },
    [setBranding, cms, history]
  );
  const getStateData = useCallback(
    (updatedParams) => {
      const {
        base64Image = null,
        cover: coverData = {},
        description: descriptionValue,
        favicon: faviconData = {},
        faviconImageUrl: faviconUrl,
        faviconUrl: faviconImg,
        finish: finishData = {},
        finishDescription: finishDescriptionValue,
        finishImageUrl: finishUrl,
        finishUrl: finishImg,
        imageUrl: brandCoverImageUrl,
        resetButtonColor = null,
        resetButtonTextColor = null,
        resetHeaderColor = null,
        resetHeaderTextColor = null,
        resetHeadingColor = null,
        resetContentColor = null,
        resetBackgroundColor = null,
        resetMenuTabColor = null,
        resetToastBackground = null,
        resetToastText = null,
        welcome: welcomeData = {},
        welcomeDescription: welcomeDescriptionValue,
        welcomeImageUrl: welcomeUrl,
        welcomeUrl: welcomeImg,
      } = updatedParams || {};
      const { cover = {}, description: finishDescriptionData = {} } = finishData;
      const { cover: welcomeCoverData = {}, description: welcomeDescriptionData = {} } = welcomeData;
      const requestData = {
        buttonColor: resetButtonColor || buttonHex,
        buttonFont: resetButtonTextColor || buttonTextHex,
        color: resetHeaderColor || headerHex,
        backgroundColor: resetBackgroundColor || backgroundHex,
        headingColor: resetHeadingColor || headingHex,
        contentColor: resetContentColor || contentHex,
        menuTabColor: resetMenuTabColor || menuHex,
        toastBackground: resetToastBackground || toastBackgroundHex,
        toastText: resetToastText || toastTextHex,
        brand: {
          termsLink: termsLink || "",
          policyLink: policyLink || "",
          cover: {},
          favicon: {
            imageUrl: (faviconImg && brandFavicon && brandFavicon.imageUrl) || faviconData?.imageUrl || "",
            imageId: (faviconImg && brandFavicon && brandFavicon.imageId) || faviconData?.imageId || "",
          },
          finish: {
            cover: {
              imageUrl: (finishImg && finishCover && finishCover.imageUrl) || cover?.imageUrl || "",
              imageId: (finishImg && finishCover && finishCover.imageId) || cover?.imageId || "",
            },
            description: {
              description: finishDescriptionValue || finishDescriptionData.description,
            },
          },
          welcome: {
            cover: {
              imageUrl: (welcomeImg && welcomeCover && welcomeCover.imageUrl) || welcomeCoverData?.imageUrl || "",
              imageId: (welcomeImg && welcomeCover && welcomeCover.imageId) || welcomeCoverData?.imageId || "",
            },
            description: {
              description: welcomeDescriptionValue || welcomeDescriptionData?.description,
            },
          },
          description: descriptionValue,
        },
        brandCoverImage: {
          url: brandCoverImageUrl || coverData?.imageUrl || "",
        },
        brandFavicon: {
          url: faviconUrl,
        },
        brandFinishCoverImage: {
          url: finishUrl,
        },
        brandWelcomeCoverImage: {
          url: welcomeUrl,
        },
        domain,
        emailSign: email.isDefault ? "" : email.content,
        footer: footer.isDefault ? "" : footer.content,
        headerFont: resetHeaderTextColor || headerTextHex,
        hideMcContactInfo: isHideContact,
        imageId: null,
        imageUrl: null || "",
        loginText: login.isDefault ? "" : login.loginText,
        logo: base64Image,
        register: {
          desc: register.isDefault ? "" : register.description,
          title: register.isDefault ? "" : register.title,
        },
      };

      // if (baseHelper.isGrowthAndAbovePlan({ plan })) {
      // requestData.domain = domain;
      // }

      return requestData;
    },
    [
      backgroundHex,
      buttonHex,
      buttonTextHex,
      contentHex,
      headerHex,
      headingHex,
      menuHex,
      termsLink,
      policyLink,
      brandFavicon,
      finishCover,
      welcomeCover,
      domain,
      email.isDefault,
      email.content,
      footer.isDefault,
      footer.content,
      headerTextHex,
      isHideContact,
      login.isDefault,
      login.loginText,
      register.isDefault,
      register.description,
      register.title,
      toastBackgroundHex,
      toastTextHex,
    ]
  );

  const postBranding = useCallback(
    (updatedParams) => {
      const requestData = getStateData(updatedParams);
      updateBranding(requestData);
    },
    [getStateData, updateBranding]
  );

  const handleColorAndHexChange = (field, color, hexCode, reset = false) => {
    switch (field) {
      case BACKGROUND:
        setBackgroundColor(color);
        setBackgroundHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetBackgroundColor: hexCode });
        }
        break;
      case HEADER:
        setHeaderColor(color);
        setHeaderHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetHeaderColor: hexCode });
        }
        break;
      case HEADING:
        setheadingColor(color);
        setHeadingHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetHeadingColor: hexCode });
        }
        break;
      case CONTENT:
        setContentColor(color);
        setContentHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetContentColor: hexCode });
        }
        break;
      case HEADER_TEXT:
        setHeaderTextColor(color);
        setHeaderTextHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetHeaderTextColor: hexCode });
        }
        break;
      case BUTTON:
        setButtonColor(color);
        setButtonHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetButtonColor: hexCode });
        }
        break;
      case BUTTON_TEXT:
        setButtonTextColor(color);
        setButtonTextHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetButtonTextColor: hexCode });
        }
        break;
      case MENU:
        setMenuColor(color);
        setMenuHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetMenuTabColor: hexCode });
        }
        break;
      case TOAST_BACKGROUND_COLOR:
        setToastBackgroundColor(color);
        setToastBackgroundHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetToastBackground: hexCode });
        }
        break;
      case TOAST_TEXT:
        setToastTextColor(color);
        setToastTextHex(hexCode);
        if (reset) {
          postBranding({ ...brand, resetToastText: hexCode });
        }
        break;
      default:
        break;
    }
  };

  const validateHexCode = async (field, value) => {
    const validationError = await validate(field, value, cms("message.error"));
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
    return validationError;
  };

  const convertHexToHSL = async (field, value) => {
    const isAnyError = await validateHexCode(field, value);
    if (isAnyError !== "") {
      return false;
    }
    const color = baseHelper.hexToHSL(value);
    return color;
  };

  const convertHslToHex = (field, value) => {
    const { hue, saturation, brightness } = value;
    if (!(hue || saturation || brightness)) {
      switch (field) {
        case BACKGROUND:
          return backgroundHex;
        case BUTTON:
          return buttonHex;
        case HEADER:
          return headerHex;
        case HEADING:
          return headingHex;
        case CONTENT:
          return contentHex;
        case BUTTON_TEXT:
          return buttonTextHex;
        case HEADER_TEXT:
          return headerTextHex;
        case MENU:
          return menuHex;
        case TOAST_BACKGROUND_COLOR:
          return toastBackgroundHex;
        case TOAST_TEXT:
          return toastTextHex;
        default:
          return "";
      }
    }
    const hexCode = baseHelper.hslToHex(hue, parseFloat(saturation * 100), parseFloat(brightness * 100));
    return hexCode;
  };

  const setHexAndColorData = async (field, value, state, reset = false) => {
    let color = {};
    let hexCode = "";
    if (value !== "") {
      if (state === COLOR) {
        hexCode = convertHslToHex(field, value);
        color = value;
      }
      if (state === HEX) {
        color = await convertHexToHSL(field, value);
        hexCode = value;
      }
    }
    handleColorAndHexChange(field, color, hexCode, reset);
  };

  const handleCopyChange = (value) => {
    setHeaderCopy(value);
    if (!headerHex) {
      return;
    }
    let hexCode = headerHex;
    if (!value) {
      hexCode = "";
    }
    setHexAndColorData(BUTTON, hexCode, HEX);
  };

  const handleImageChange = (field, value) => {
    setImage(value);
  };

  const handleFaviconChange = (field, value) => {
    setFaviconImage(value);
  };

  const handleCoverChange = (field, value) => {
    setCoverImage(value);
  };

  const handleDescriptionChange = (field, value) => {
    setDescription(value);
  };

  const handleStateChange = (field, value, state, reset = false) => {
    setIsSubmitEnable(true);
    switch (state) {
      case BRAND_DESCRIPTION:
        return handleDescriptionChange(field, value);
      case DOMAIN:
        return handleDomainChange(value);
      case LOGIN:
        return handleLoginChange(field, value);
      case IMAGE:
        return handleImageChange(field, value);
      case FAVICON:
        return handleFaviconChange(field, value);
      case IMAGE_URL:
        return handleCoverChange(field, value);
      case REGISTER:
        return handleRegisterChange(field, value);
      case EMAIL:
        return handleEmailChange(field, value);
      case FOOTER:
        return handleFooterChange(field, value);
      case CONTACT:
        return handleContactChange(value);
      case COLOR:
      case HEX:
        return setHexAndColorData(field, value, state, reset);
      case COPY:
        return handleCopyChange(value);
      case WELCOME_COVER:
        return setOnboarding({ ...onboarding, welcomeCoverImage: value });
      case FINISH_COVER:
        return setOnboarding({ ...onboarding, finishCoverImage: value });
      default:
        return null;
    }
  };

  const handleTiny = (field, value, state) => {
    return handleStateChange(field, value, state);
  };

  const handleChange = (field, value, state) => {
    return handleStateChange(field, value, state);
  };

  useEffect(() => {
    if ((isHeaderCopy && headerHex !== buttonHex) || (!isHeaderCopy && headerHex === buttonHex)) {
      setHeaderCopy(!isHeaderCopy);
    }
  }, [buttonHex, headerHex, isHeaderCopy]);

  if ((buttonHex && prevButtonHex !== buttonHex) || (buttonTextHex && prevButtonTextHex !== buttonTextHex)) {
    setPrevButtonHex(buttonHex);
    setPrevButtonTextHex(buttonTextHex);
    setBrandingData({ ...branding, buttonColor: buttonHex, buttonFont: buttonTextHex });
  }

  if ((headerHex && prevHeaderHex !== headerHex) || (headerTextHex && prevHeaderTextHex !== headerTextHex)) {
    setPrevHeaderHex(headerHex);
    setPrevHeaderTextHex(headerTextHex);
    setBrandingData({ ...branding, headerColor: headerHex, headerFont: headerTextHex });
  }

  const resetDefault = (field, value, state) => {
    handleStateChange(field, value, state, true);
  };
  const onSubmit = async () => {
    setIsSubmitEnable(false);
    if (image && image.url) {
      return postBranding({
        description,
        faviconImageUrl: faviconImage,
        faviconUrl: faviconImage,
        finishDescription,
        finishImageUrl: finishCoverImage,
        finishUrl: finishCoverImage,
        imageUrl: coverImageURL,
        welcomeDescription,
        welcomeImageUrl: welcomeCoverImage,
        welcomeUrl: welcomeCoverImage,
      });
    }
    return postBranding({
      base64Image: image,
      description,
      faviconImageUrl: faviconImage,
      faviconUrl: faviconImage,
      finishDescription,
      finishImageUrl: finishCoverImage,
      finishUrl: finishCoverImage,
      imageUrl: coverImageURL,
      welcomeDescription,
      welcomeImageUrl: welcomeCoverImage,
      welcomeUrl: welcomeCoverImage,
    });
  };

  const color = {
    backgroundColor,
    header,
    headerText,
    button,
    buttonText,
    menuColor,
    headingColor,
    contentColor,
    toastBackgroundColor,
    toastTextColor,
  };

  const hex = {
    backgroundHex,
    headerHex,
    headerTextHex,
    headingHex,
    contentHex,
    toastBackgroundHex,
    toastTextHex,
    buttonHex,
    buttonTextHex,
    menuHex,
    DEFAULT_HEADER_COLOR,
    DEFAULT_TEXT_COLOR,
  };

  const page = { login, register, email, footer, isHideContact, handleChange, resetDefault, handleTiny };

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      <Layout.Section>
        <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={dismissBanner} />
        <BrandingProvider
          value={{
            color,
            coverImageURL,
            description,
            domain,
            errorMessage,
            faviconImage,
            finishCoverImage,
            finishDescription,
            handleChange,
            handleTiny,
            hex,
            image,
            isHeaderCopy,
            onboarding,
            onBoardingTitle,
            page,
            resetDefault,
            setBanner,
            setIsSubmitEnable,
            setOnboarding,
            welcomeCoverImage,
            welcomeDescription,
            // learnMore,
          }}
        >
          <CustomPage />
          <Toast message={message} setToast={setMessage} />
        </BrandingProvider>
        {/* <Sheet
isOpen={sheetActive}
title={sheetTitle}
onClose={() => setSheetActive(false)}
secondaryAction={{ content: cms("common.button.close"), onAction: () => setSheetActive(false) }}
primaryAction={{ content: cms("common.label.done"), onAction: () => setSheetActive(false) }}
>
{sheetContent}
</Sheet>
*/}
        <Layout.Section>
          <PageActions
            primaryAction={{
              id: "submit",
              content: cms("common.button.submit"),
              onAction: () => onSubmit(),
              loading: brandingLoading,
              disabled: brandingLoading || !isSubmitEnable,
            }}
            secondaryActions={[
              {
                id: "cancel",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        </Layout.Section>
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorBranding));
