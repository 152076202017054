import React, { useContext } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { DeleteMinor, ImageMajor, LogOutMinor, ProfileMajor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

// import from lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import Popover from "lib/components/popover/popover";

// import cms
import cmsVendorList from "app/vendors/modules/admin/features/list/cms/vendorListCMS";

const VendorListView = (props) => {
  const { cms, history } = useContext(PrivateContext);
  const {
    activePopover,
    handleEnableDisable,
    item,
    keyIndex,
    onAssocProvider,
    onDeleteVendor,
    onLoginVendor,
    popoverDisable,
    setActivePopover,
    setMessageToast,
  } = props;
  const { _id, brandName, createdAt, email, firstName, isInactive, lastName, onHoliday, isApproved, roles } = item;
  const { address = "", verified } = email || {};
  const { name: role } = roles;
  const { APPROVE, providerList, INFO, HOLIDAY } = constant;
  const { BRAND_PAGE_V2, BRANDPAGE, DELETE, DISABLE, ENABLE, LOGIN, VIEW } = providerList;

  const vendorListCMS = cmsVendorList(cms);
  const { button, label, message } = vendorListCMS;
  const handleSelectAction = (action) => {
    if (action === VIEW) {
      onAssocProvider(_id);
    }
    if (action === DELETE) {
      onDeleteVendor(_id);
    }
    if (action === LOGIN) {
      onLoginVendor(_id, brandName, role);
    }
    if (action === ENABLE) {
      setMessageToast(message.vendorEnable);
      handleEnableDisable(_id, isInactive);
    }
    if (action === DISABLE) {
      handleEnableDisable(_id, isInactive);
    }
  };

  const topMargin10px = {
    marginTop: providerList.MARGIN_TOP,
  };

  const handleBadge = () => {
    switch (isApproved || APPROVE) {
      case providerList.IN_REVIEW:
        return { status: label.needReview, value: constant.ATTENTION };
      case providerList.ON_HOLD:
        return { status: label.onHold, value: constant.INFO };
      case providerList.APPROVE:
        return { status: label.approved, value: constant.SUCCESS };
      case providerList.REJECT:
        return { status: label.reject, value: constant.CRITICAL };
      default:
    }
    return {};
  };

  const approveOptions = [
    {
      content: button.loginVendor,
      icon: LogOutMinor,
      onAction: () => handleSelectAction(LOGIN),
    },
    {
      content: button.enableVendor,
      icon: ImageMajor,
      onAction: () => handleSelectAction(ENABLE),
      disabled: !isInactive,
    },
    {
      content: button.disableVendor,
      icon: ImageMajor,
      onAction: () => handleSelectAction(DISABLE),
      disabled: isInactive || onHoliday,
    },
    {
      content: button.brandPage,
      icon: ImageMajor,
      onAction: () => handleSelectAction(BRANDPAGE),
    },
    {
      content: button.brandPageV2,
      icon: ImageMajor,
      onAction: () => handleSelectAction(BRAND_PAGE_V2),
    },
    // check for review if isApproved value inReview or onHold
    {
      content: button.reviewVendor,
      icon: ImageMajor,
      onAction: () =>
        history.push({
          pathname: `/provider/review/${item._id}`,
          state: { detail: item },
        }),
      disabled: !(isApproved === label.inReview || isApproved === label.hold),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const reviewOptions = [
    {
      content: button.reviewVendor,
      icon: ImageMajor,
      onAction: () =>
        history.push({
          pathname: `/provider/review/${item._id}`,
          state: { detail: item },
        }),
      disabled: !verified || !(isApproved === label.inReview || isApproved === label.hold),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  const rejectOptions = [
    {
      content: button.profile,
      icon: ProfileMajor,
      onAction: () => history.push({ pathname: `/providers/profile/${item._id}`, state: { detail: item } }),
    },
    {
      content: button.deleteVendor,
      destructive: true,
      icon: DeleteMinor,
      onAction: () => handleSelectAction(DELETE),
    },
  ];

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <div className="ellipsis">
              <Link url={`/providers/view/${_id}`}>
                <TextStyle>
                  <div className="ellipsis">
                    {(brandName && baseHelper.ucFirst(brandName)) || cms("error.common.emptyBrandName")}
                  </div>
                </TextStyle>
              </Link>
              <Caption>
                <TextStyle variation="subdued">
                  {`${constant.ONBOARDED} ${constant.ON}: ${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
                <br />
                <div className="ellipsis">
                  {`${label.name} ${firstName || cms("error.common.emptyName")}${lastName || ""}`}
                </div>
                <div className="ellipsis">{`${label.email} ${address}`}</div>
              </Caption>
              <div style={topMargin10px}>
                <Stack>
                  <Stack.Item>
                    <Badge status={verified ? handleBadge().value : constant.NEW}>
                      {verified ? handleBadge().status : constant.PENDING}
                    </Badge>
                    {isInactive && <Badge status={label.critical}>{label.disabled}</Badge>}
                  </Stack.Item>
                  {onHoliday ? (
                    <Stack.Item>
                      <Badge status={INFO}>{HOLIDAY}</Badge>
                    </Stack.Item>
                  ) : null}
                </Stack>
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              active={activePopover[_id]}
              setActive={
                () => (popoverDisable ? setActivePopover(false) : setActivePopover({ [_id]: !activePopover[_id] }))
                // eslint-disable-next-line react/jsx-curly-newline
              } // prettier and eslint rules are violated
              options={
                // eslint-disable-next-line no-nested-ternary
                isApproved === label.inReview || isApproved === label.hold
                  ? reviewOptions
                  : isApproved === label.rejected
                  ? rejectOptions
                  : approveOptions
              }
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

VendorListView.defaultProps = {
  item: {},
  keyIndex: "",
  onDeleteVendor: () => {},
  onLoginVendor: () => {},
  onAssocProvider: () => {},
  activePopover: {},
};

VendorListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  onDeleteVendor: PropTypes.func,
  onLoginVendor: PropTypes.func,
  onAssocProvider: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default withErrorBoundary(VendorListView);
