import React from "react";
import { Layout, TextField, TextStyle } from "@shopify/polaris";

const JsonMetaData = (props) => {
  const { inputValue, handleChange, metafield } = props;

  return (
    <>
      <TextStyle>{`${metafield.name} ${metafield.isRequired ? "*" : ""}`}</TextStyle>
      <TextField
        value={inputValue[metafield.key] || ""}
        onChange={(value) => handleChange(metafield.key, value, "", "", metafield.contentType)}
        multiline={4}
      />
      <Layout.Section />
    </>
  );
};

export default JsonMetaData;
