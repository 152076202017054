import { gql } from "apollo-boost";

const LOGIN = gql`
  mutation loginCheck($input: Credential!) {
    login(input: $input) {
      data {
        token
        isOtp
      }
      status
      error
    }
  }
`;

const LOGIN_MOBILE = gql`
  mutation loginMobile($input: MobileCredential!) {
    loginMobile(input: $input) {
      data {
        token
        isOtp
      }
      status
      error
    }
  }
`;
export { LOGIN, LOGIN_MOBILE };
