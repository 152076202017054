import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";

// Import Polaris Components
import { Stack, Modal } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  DISCONNECT_SHOP,
  DISCONNECT_BIGCOMMERCE_SHOP,
  SYNC_LAKE_INVENTORY_VENDOR,
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT,
  SYNC_VENDOR_BIGCOMMERCE_PRODUCT,
} from "app/productLake/apollo/mutations";
import { DISCONNECT_WOOCOMMERCE_SHOP } from "app/product/apollo/mutations/wooCommerceConnect";
import { DISCONNECT_WIX_SHOP, SYNC_VENDOR_WIX_PRODUCT } from "app/product/apollo/mutations";

// import report
import { QuickAction, QuickReport } from "app/reports";
import { AddProductMajor, ImportStoreMajor, OnlineStoreMajor, StoreMajor } from "@shopify/polaris-icons";
import { withErrorBoundary } from "lib/hoc";

const {
  gql: {
    SYNC_LAKE_INVENTORY_VENDOR: SYNC_LAKE_INVENTORY_VENDOR_GQL,
    DISCONNECT_SHOP: DISCONNECT,
    DISCONNECT_BIGCOMMERCE_SHOP: DISCONNECT_BIG_SHOP,
    DISCONNECT_WOOCOMMERCE_SHOP: DISCONNECT_WOO_SHOP,
    DISCONNECT_WIX_SHOP: DISCONNECT_WIX,
    SYNC_WIX_VENDOR_PRODUCT,
    SYNC_VENDOR_BIGCOMMERCE_PRODUCT: SYNC_VENDOR_BIGCOMMERCE_PRODUCT_GQL,
  },
} = constant;

const Widget = (props) => {
  const { setBanner = () => {} } = props;
  const { cms, currentUser = {}, history = {}, currentUserRefetch = () => { } } = useContext(PrivateContext);

  const { shop = null } = currentUser;
  const { wooCommerce: wooCommerceConfig = {}, wix: wixConfig = {}, bigCommerce: bigCommerceConfig = {} } =
    (currentUser && currentUser.storeConfig) || {};
  const { shop: woocommerceVendorShop = "" } = wooCommerceConfig || {};
  const { instanceId: wixInstanceId = "", accessKey: wixAccessKey = "" } = wixConfig || {};
  const {
    accessToken: bigCommerceAccessToken = "",
    clientId: bigCommerceClientId = "",
    clientSecret: bigCommerceClientSecret = "",
    shopDomain: bigCommerceShopDomain = "",
  } = bigCommerceConfig || {};

  const [syncLakeInventoryVendor, { loading: syncInventoryVendorLoading }] = useMutation(SYNC_LAKE_INVENTORY_VENDOR);
  const [syncWooCommerceVendorProduct, { loading: syncWooCommerceVendorProductLoading }] = useMutation(
    SYNC_WOOCOMMERCE_VENDOR_PRODUCT
  );
  const [syncBigCommerceVendorProduct, { loading: syncBigCommerceVendorProductLoading }] = useMutation(
    SYNC_VENDOR_BIGCOMMERCE_PRODUCT
  );
  const [syncVendorWixProduct, { loading: syncVendorWixProductLoading }] = useMutation(SYNC_VENDOR_WIX_PRODUCT);
  const [disconnectShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_SHOP);
  const [disconnectWooCommerceShop, { loading: disconnectWooCommerceLoading }] = useMutation(
    DISCONNECT_WOOCOMMERCE_SHOP
  );
  const [disconnectWixShop, { loading: disconnectWixLoading }] = useMutation(DISCONNECT_WIX_SHOP);
  const [disconnectBigCommerceShop, { loading: disconnectBigCommerceLoading }] = useMutation(
    DISCONNECT_BIGCOMMERCE_SHOP
  );

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);
  const [isWooCommerceDisconnectActive, setIsWooCommerceDisconnectActive] = useState(false);
  const [isWixDisconnectActive, setWixDisconnectActive] = useState(false);
  const [isBigCommerceDisconnectActive, setIsBigCommerceDisconnectActive] = useState(false);

  if (
    !(
      shop ||
      woocommerceVendorShop ||
      (wixInstanceId && wixAccessKey) ||
      (bigCommerceAccessToken && bigCommerceClientId && bigCommerceClientSecret && bigCommerceShopDomain)
    )
  ) {
    return (
      <>
        <QuickReport />
        <QuickAction />
      </>
    );
  }

  const syncProducts = async () => {
    try {
      const response = await syncLakeInventoryVendor();
      const responseError = baseHelper.getResponseError(response.data, SYNC_LAKE_INVENTORY_VENDOR_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/product-lake/shopify");
      const bannerAction = {
        onAction,
        content: cms("section.connect.label.store"),
      };
      setBanner({
        isOpen: true,
        status: constant.INFO,
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncWooCommerceProducts = async () => {
    try {
      const response = await syncWooCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, constant.SYNC_WOOCOMMERCE_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/product-lake/woocommerce");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWoocommerceProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: constant.INFO,
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncWixProducts = async () => {
    try {
      const response = await syncVendorWixProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_WIX_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/product-lake/wix");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWixProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const syncBigCommerceProducts = async () => {
    try {
      const response = await syncBigCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, SYNC_VENDOR_BIGCOMMERCE_PRODUCT_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const onAction = () => history.push("/product-lake/bigcommerce");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewBigcommerceProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: cms("message.success.sync"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectShop({
        variables: { input: { id: currentUser.id } },
      });
      setIsDisconnectActive(!isDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.disconnect") });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectWooCommerceShopConfirm = async () => {
    try {
      const response = await disconnectWooCommerceShop({
        variables: { input: { id: currentUser.id } },
      });
      setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_WOO_SHOP);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: `${cms("section.connect.message.success.disconnected")}`,
      });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectWixShopConfirm = async () => {
    try {
      const response = await disconnectWixShop();
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_WIX);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, DISCONNECT_WIX);
      if (responseData) {
        setWixDisconnectActive(!isWixDisconnectActive);
        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title: `${cms("section.connect.message.success.disconnected")}`,
        });
        setTimeout(() => {
          currentUserRefetch();
        }, 1000);
      }
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const disconnectBigCommerceShopConfirm = async () => {
    try {
      const response = await disconnectBigCommerceShop({
        variables: { input: { id: currentUser._id } },
      });
      setIsBigCommerceDisconnectActive(!isBigCommerceDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_BIG_SHOP);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.disconnect") });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const linkData = [
    {
      action: () => history.push("/product-lake/add"),
      caption: cms("section.caption.add"),
      id: "addProduct",
      icon: AddProductMajor,
      primary: true,
      label: cms("section.connect.label.add"),
      /* primary: true, */
      shop: true,
    },
  ];
  const storedata = [];
  if (shop) {
    storedata.unshift(
      {
        action: () => syncProducts(),
        caption: cms("section.sync.label.import"),
        id: "importProduct",
        label: cms("section.connect.label.import"),
        icon: ImportStoreMajor,
        loading: syncInventoryVendorLoading,
        shop,
      },
      {
        action: () => history.push("/product-lake/shopify"),
        caption: cms("section.connect.label.view"),
        id: "storeProduct",
        label: cms("section.connect.label.view"),
        icon: OnlineStoreMajor,
        shop,
      },
      {
        action: () => setIsDisconnectActive(!isDisconnectActive),
        caption: `${cms("section.disconnect.label.disconnectShop", { shop })}`,
        id: "disconnectShop",
        label: `${cms("section.disconnect.label.disconnect", { shop })}`,
        icon: StoreMajor,
        shop,
      }
    );
  }

  if (woocommerceVendorShop) {
    storedata.unshift(
      {
        action: () => syncWooCommerceProducts(),
        caption: cms("section.connect.label.importWooCommerce"),
        id: "importProduct",
        icon: ImportStoreMajor,
        label: cms("section.connect.label.importWooCommerce"),
        loading: syncWooCommerceVendorProductLoading,
        shop: woocommerceVendorShop,
      },
      {
        action: () => history.push("/product-lake/woocommerce"),
        caption: cms("section.connect.label.view"),
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: cms("section.connect.label.wooCommerce"),
        shop: woocommerceVendorShop,
      },
      {
        action: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
        caption: `${cms("section.disconnect.label.wooCommerce", { woocommerceVendorShop })}`,
        id: "disconnectWooCommerceShop",
        icon: StoreMajor,
        label: `${cms("section.disconnect.label.wooCommerceShop", { woocommerceVendorShop })}`,
        shop: woocommerceVendorShop,
      }
    );
  }

  if (bigCommerceAccessToken && bigCommerceClientId && bigCommerceClientSecret && bigCommerceShopDomain) {
    storedata.unshift(
      {
        action: () => syncBigCommerceProducts(),
        caption: cms("section.connect.label.importBigCommerce"),
        id: "importProduct",
        icon: ImportStoreMajor,
        label: cms("section.connect.label.importBigCommerce"),
        loading: syncBigCommerceVendorProductLoading,
        shop: true,
      },
      {
        action: () => history.push("/product-lake/bigcommerce"),
        caption: cms("section.connect.label.viewBigcommerce"),
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: cms("section.connect.label.viewBigcommerce"),
        shop: true,
      },
      {
        action: () => setIsBigCommerceDisconnectActive(!isBigCommerceDisconnectActive),
        caption: `${cms("section.disconnect.label.bigCommerce", { bigCommerceShopDomain })}`,
        id: "disconnectBigcommerceShop",
        icon: StoreMajor,
        label: `${cms("section.disconnect.label.bigCommerceShop", { bigCommerceShopDomain })}`,
        shop: true,
      }
    );
  }

  if (wixInstanceId && wixAccessKey) {
    storedata.unshift(
      {
        action: () => syncWixProducts(),
        caption: cms("section.connect.label.importWix"),
        id: "importProduct",
        icon: ImportStoreMajor,
        label: cms("section.connect.label.importWix"),
        loading: syncVendorWixProductLoading,
        shop: true,
      },
      {
        action: () => history.push("/product-lake/wix"),
        caption: cms("section.connect.label.viewWix"),
        id: "storeProduct",
        icon: OnlineStoreMajor,
        label: cms("section.connect.label.wix"),
        shop: true,
      },
      {
        action: () => setWixDisconnectActive(!isWixDisconnectActive),
        caption: cms("section.disconnect.label.wix"),
        id: "disconnectWixShop",
        icon: StoreMajor,
        label: cms("section.disconnect.label.wixShop"),
        shop: true,
      }
    );
  }

  return (
    <>
      <Stack.Item>
        <Modal
          open={isDisconnectActive}
          onClose={() => setIsDisconnectActive(!isDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.sync.label.disconnect")} ${currentUser.shop}`,
            onAction: disconnectShopConfirm,
            destructive: true,
            loading: disconnectLoading,
          }}
          secondaryActions={[
            {
              content: cms("button.cancel"),
              onAction: () => setIsDisconnectActive(!isDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.sync.label.disconnectShop")}`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isWooCommerceDisconnectActive}
          onClose={() => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectWooCommerceShopConfirm,
            destructive: true,
            loading: disconnectWooCommerceLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.connect.label.disconnectShop")} ${woocommerceVendorShop}?`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isWixDisconnectActive}
          onClose={() => setWixDisconnectActive(!isWixDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectWixShopConfirm,
            destructive: true,
            loading: disconnectWixLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setWixDisconnectActive(!isWixDisconnectActive),
            },
          ]}
        >
          <Modal.Section>
            {`${cms("section.connect.label.disconnectShop")} ${cms("section.connect.label.wixShop")}?`}
          </Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isBigCommerceDisconnectActive}
          onClose={() => setIsBigCommerceDisconnectActive(!isBigCommerceDisconnectActive)}
          title={cms("section.sync.label.disconnect")}
          primaryAction={{
            content: `${cms("section.connect.label.disconnect")}`,
            onAction: disconnectBigCommerceShopConfirm,
            destructive: true,
            loading: disconnectBigCommerceLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("button.cancel")}`,
              onAction: () => setIsBigCommerceDisconnectActive(!isBigCommerceDisconnectActive),
            },
          ]}
        >
          <Modal.Section>
            {`${cms("section.connect.label.disconnectShop")} ${cms("section.connect.label.bigcommerceShop")}?`}
          </Modal.Section>
        </Modal>
      </Stack.Item>
      <QuickReport />
      <QuickAction link={linkData} storedata={storedata} />
    </>
  );
};

Widget.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default withErrorBoundary(Widget);
