// import packages
import React, { useContext, useState } from "react";
import { Button, Card, FormLayout, Layout } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import helpers components
import { Footer } from "layout/public/components";
import { Banner } from "lib/components";

// import helpers
import { storageHelper } from "lib/helpers";

// import context
import { PublicContext } from "lib/context";

const PreLogin = () => {
  const { cms, history } = useContext(PublicContext);

  const loginTitle = storageHelper.get("loginText");

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  // const onSubmitLoginVia = (value) => ;

  return (
    <>
      <Banner
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
      <br />
      <Layout>
        <Layout.AnnotatedSection
          title={(loginTitle && loginTitle !== "" && loginTitle) || cms("title")}
          description={cms("description")}
        >
          <div className="prelogin_card">
            <Card sectioned>
              <FormLayout>
                <div className="preloging_Heading"> Select Login</div>
                <div className="flex-container">
                  <div className="prelogin_email_button">
                    <Button
                      primary
                      id="prelogin_button"
                      label="Email"
                      name="email"
                      onClick={() => {
                        history.push({
                          pathname: "/login",
                          state: { loginVia: "email" },
                        });
                      }}
                    >
                      Email
                    </Button>
                  </div>
                  <>OR</>
                  <div className="prelogin_phone_button">
                    <Button
                      primary
                      id="prelogin_button"
                      label="Phone number"
                      name="phone number"
                      onClick={() => {
                        history.push({
                          pathname: "/login",
                          state: { loginVia: "phone" },
                        });
                      }}
                    >
                      Phone Number
                    </Button>
                  </div>
                </div>
              </FormLayout>
            </Card>
          </div>
        </Layout.AnnotatedSection>
      </Layout>
      <Footer />
    </>
  );
};

export default withErrorBoundary(PreLogin);
