import { gql } from "apollo-boost";

const GET_REPORT_DATA = gql`
  query getReportData($input: GetReportDataInput) {
    getReportData(input: $input) {
      data {
        orderNumber
        lineItemId
        title
        vendor
        quantity
        markup
        sellerCommission
        createdAt
        fulfillmentStatus
        shipping
        tax
        sku
        orderStatus
        vendorPaymentStatus
        vendorAmount
        vendorPaidDate
        price
        productCost
        discount
        returns
        productType
        customerProductPrice
        totalSalesPrice
        grossSales
        grossProfit
        trackingNumber
      }
      error
      status
    }
  }
`;

export default GET_REPORT_DATA;
