import React, { useContext, useEffect } from "react";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { Card, Link, TextStyle } from "@shopify/polaris";
import { OrderContext } from "app/orders/modules/provider/features/view/orderContext";
import { PrivateContext } from "lib/context";
import { customerProp } from "app/orders/modules/provider/features/view/propsType";

const Customer = (props) => {
  const { userKey } = constant;
  const { chatSetting = {}, cms, currentUser, initiateChat = () => {} } = useContext(PrivateContext);
  const { introductorySeller: sellerId } = currentUser;
  const { addressId, fulfillmentType } = useContext(OrderContext);
  const {
    customerData = {},
    data,
    getSellerAddress,
    isHideBillingInfo,
    isLiveConnect,
    orderLiveConnectSetting,
    sellerAddressData,
  } = props;

  const { customer, email, isHideCustomerDetails, phone, shippingAddress } = data || {};
  let { billingAddress } = data || {};

  const { isCustomer: isChatCustomer = true } = chatSetting || {};

  if (sellerId === "66c33208c740ea0013ea5b43") {
    billingAddress = {
      address1: '7 Bell Yard',
      address2: '',
      city: 'London',
      company: 'Provenance Hub Limited',
      country: 'United Kingdom',
      first_name: 'Provenance',
      last_name: 'Hub',
      phone: '',
      province: 'England',
      zip: 'WC2A 2JR',
    };
  }

  const {
    default_address: defaultAddress,
    email: customerEmail,
    first_name: firstName,
    last_name: lastName,
    phone: customerPhone,
  } = customer || {};
  const {
    address1,
    address2,
    city,
    company,
    country,
    first_name: shippingFirstName,
    last_name: shippingLastName,
    phone: shippingPhone,
    province,
    zip,
  } = shippingAddress || {};

  const { isBillingAddress, isCustomer, isShippingAddress } = orderLiveConnectSetting;
  // const fulfillmentData =
  //   (fulfillment &&
  //     // (type === constant.CONSIGNMENT || defaultType === constant.CONSIGNMENT) &&
  //     baseHelper.fulfillmentDetails(fulfillment, currentUser)) ||
  //   {};
  useEffect(() => {
    if (fulfillmentType && fulfillmentType.length) {
      if (fulfillmentType.includes("consignment")) {
        getSellerAddress({ variables: { input: { addressId, sellerId } } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType, addressId]);

  // const { address = "", city: cityName = "", pincode = "", country: countryName = "" } = fulfillmentData || {};

  const { customer: customerValue } = customerData || {};

  const {
    address1: billingAddress1,
    address2: billingAddress2,
    city: billingCity,
    company: billingCompany,
    country: billingCountry,
    first_name: billingFirstName,
    last_name: billingLastName,
    phone: billingPhone,
    province: billingProvince,
    zip: billingZip,
  } = billingAddress || {};

  const resData = baseHelper.getResponseData(sellerAddressData, constant.gql.GET_ADDRESS_DETAILS);
  const { address = "", city: cityName = "", country: countryName = "", postalCode: pincode = "" } = resData || {};

  const isFulfillmentTypeAvailable = !!(fulfillmentType && fulfillmentType.length);
  const isValidFulfillmentType =
    isFulfillmentTypeAvailable &&
    (fulfillmentType.includes(constant.PICKUP) || fulfillmentType.includes(constant.CONSIGNMENT));

  const handleCustomer = () => {
    const orderCustomerEmail = customerEmail || email || cms("label.noEmailAddress");
    const orderCustomerPhone = customerPhone || phone || cms("label.noPhoneNumber");

    const { isEmailChecked = false, isPhoneNumberChecked = false } = customerValue || {};

    const operatorSettingEmail = (customerValue && customerValue.email) || cms("label.noEmailAddress");
    const operatorSettingPhone = (customerValue && customerValue.phoneNumber) || cms("label.noPhoneNumber");

    if (
      !isValidFulfillmentType &&
      ((!isLiveConnect && isHideCustomerDetails) || (isLiveConnect && isCustomer && isHideCustomerDetails))
    ) {
      return (
        <>
          <TextStyle
            variation={!((customerValue && customerValue.email) || customerEmail || email) && constant.SUBDUED}
          >
            <p className="wrap_text">{isEmailChecked ? operatorSettingEmail : orderCustomerEmail}</p>
          </TextStyle>
          <TextStyle
            variation={!((customerValue && customerValue.phoneNumber) || customerPhone || phone) && constant.SUBDUED}
          >
            <p className="wrap_text">{isPhoneNumberChecked ? operatorSettingPhone : orderCustomerPhone}</p>
          </TextStyle>
          {isChatCustomer && customerData && (
            <Link
              onClick={() => {
                initiateChat({
                  _id: customer.id.toString(),
                  firstName: customer.first_name,
                  email: isEmailChecked ? customerValue.email : customerEmail || email,
                });
              }}
            >
              {cms("button.initiateChat")}
            </Link>
          )}
        </>
      );
    }

    const customerInfoView = (isCustomerEnable) =>
      isCustomerEnable && !isValidFulfillmentType ? (
        <>
          <TextStyle variation={cms("label.noEmailAddress") === orderCustomerEmail && constant.SUBDUED}>
            <p className="wrap_text">{orderCustomerEmail}</p>
          </TextStyle>

          <TextStyle variation={cms("label.noPhoneNumber") === orderCustomerPhone && constant.SUBDUED}>
            <p className="wrap_text">{orderCustomerPhone}</p>
          </TextStyle>
        </>
      ) : (
        <TextStyle variation={constant.SUBDUED}>{cms("label.noCustomer")}</TextStyle>
      );

    return (
      <>
        {isLiveConnect ? customerInfoView(isCustomer) : customerInfoView(true)}
        {isChatCustomer &&
          (customerEmail || email) &&
          (firstName || lastName) &&
          !isValidFulfillmentType &&
          (!isLiveConnect || (isLiveConnect && isCustomer)) && (
            <>
              <br />
              <Link
                onClick={() => {
                  initiateChat({
                    _id: customer.id.toString(),
                    firstName: `${firstName} ${lastName}`,
                    email: customerEmail || email,
                    role: userKey.customer,
                  });
                }}
              >
                {cms("button.initiateChat")}
              </Link>
            </>
          )}
      </>
    );
  };

  const isFulfillmentInfoAvailable = !!(fulfillmentType && fulfillmentType.length);
  const isShippingHide = isFulfillmentInfoAvailable
    ? !(fulfillmentType.includes(constant.DROPSHIP) || fulfillmentType.includes(constant.CONSIGNMENT)) &&
      fulfillmentType.includes(constant.PICKUP)
    : false;

  const isOnlyReserveAndCollect =
    fulfillmentType &&
    fulfillmentType.length &&
    (fulfillmentType.includes(constant.RESERVE) || fulfillmentType.includes(constant.COLLECT));

  const billingAddressView = (isBillingEnable) =>
    billingAddress && isBillingEnable ? (
      <TextStyle>
        <div className="wrap_address">
          {`${(billingAddress && billingFirstName) || ""} 
        ${(billingAddress && billingLastName) || ""}`}
        </div>
        <div className="wrap_address">{`${(billingAddress && billingCompany) || ""}`}</div>
        <div className="wrap_address">
          {`${(billingAddress && billingAddress1) || ""} 
      ${(billingAddress && billingAddress2) || ""}`}
        </div>
        <div className="wrap_address">
          {`${(billingAddress && billingZip) || ""} 
      ${(billingAddress && billingCity) || ""} 
      ${(billingAddress && billingProvince) || ""}`}
        </div>
        <p className="wrap_text">{billingAddress && billingCountry}</p>
        <p className="wrap_text">{billingAddress && billingPhone}</p>
      </TextStyle>
    ) : (
      <TextStyle variation={constant.SUBDUED}>{cms("label.noBillingAddress")}</TextStyle>
    );

  const shippingAddressView = (isShippingEnable) =>
    shippingAddress && isShippingEnable ? (
      <TextStyle>
        <div className="wrap_address">
          {`${(shippingAddress && shippingFirstName) || ""} 
            ${(shippingAddress && shippingLastName) || ""}`}
        </div>
        <div className="wrap_address">{`${(shippingAddress && company) || ""}`}</div>
        <div className="wrap_address">
          {`${(shippingAddress && address1) || ""} 
            ${(shippingAddress && address2) || ""}`}
        </div>
        <div className="wrap_address">
          {`${(shippingAddress && zip) || ""} 
            ${(shippingAddress && city) || ""} 
            ${(shippingAddress && province) || ""}`}
        </div>
        <p className="wrap_text">{shippingAddress && country}</p>
        <p className="wrap_text">{shippingAddress && shippingPhone}</p>
      </TextStyle>
    ) : (
      <TextStyle variation={constant.SUBDUED}>{cms("label.noShippingAddress")}</TextStyle>
    );

  return (
    <Card title={cms("label.customer")}>
      <Card.Section>
        <TextStyle variation={!(customer && defaultAddress && defaultAddress.name) && constant.SUBDUED}>
          <div className="wrap_text">
            {(customer && defaultAddress && defaultAddress.name) || cms("label.noCustomer")}
          </div>
        </TextStyle>
      </Card.Section>
      <Card.Section title={cms("label.contactInformation")}>
        <TextStyle>{handleCustomer()}</TextStyle>
      </Card.Section>
      {fulfillmentType.includes(constant.CONSIGNMENT) && (
        <Card.Section title={cms("label.consignmentAddress").toUpperCase()}>
          <TextStyle>
            <div className="wrap_text">
              <p>{address}</p>
              <p>{cityName}</p>
              <p>{countryName}</p>
              <p>{pincode}</p>
            </div>
          </TextStyle>
        </Card.Section>
      )}
      {(!isFulfillmentInfoAvailable ||
        fulfillmentType.includes(constant.DROPSHIP || constant.SERVICE) ||
        isOnlyReserveAndCollect) && (
        <>
          <Card.Section
            title={
              (fulfillmentType.includes("dropship") && cms("label.dropshipAddress").toUpperCase()) ||
              cms("label.shippingAddress").toUpperCase()
            }
          >
            <TextStyle>{isLiveConnect ? shippingAddressView(isShippingAddress) : shippingAddressView(true)}</TextStyle>
          </Card.Section>
          {!isHideBillingInfo && (
            <Card.Section title={cms("label.billingAddress")}>
              <TextStyle>{isLiveConnect ? billingAddressView(isBillingAddress) : billingAddressView(true)}</TextStyle>
            </Card.Section>
          )}
        </>
      )}
      {isShippingHide && (
        <>
          <Card.Section title={cms("label.shippingAddress").toUpperCase()}>
            <TextStyle variation={constant.SUBDUED}>{cms("label.noShippingAddress")}</TextStyle>
          </Card.Section>
          {!isHideBillingInfo && (
            <Card.Section title={cms("label.billingAddress")}>
              <TextStyle variation={constant.SUBDUED}>{cms("label.noBillingAddress")}</TextStyle>
            </Card.Section>
          )}
        </>
      )}
    </Card>
  );
};
Customer.propTypes = customerProp.type;
export default Customer;
