import { gql } from "apollo-boost";

const GENERATE_OTP = gql`
  mutation generateOtp($input: OtpCredential) {
    generateOtp(input: $input) {
      data {
        status
      }
      status
      error
    }
  }
`;
export default GENERATE_OTP;
