import { gql } from "apollo-boost";

const FETCH_MAINTENANCE = gql`
  query fetchMaintenance {
    getMaintenance {
      status
      data {
        isApp
      }
      error
    }
  }
`;
export default FETCH_MAINTENANCE;
