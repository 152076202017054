import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { SingleColumnLayout } from "layout/private/components";
import { SkeletonList } from "lib/components";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { OperatorProviderList } from "./operator";
import { AdminProviderList } from "./admin";

const { userKey } = constant;
const { admin, operator } = userKey;

const Vendors = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    if (role === admin) {
      return <AdminProviderList />;
    }
    if (role === operator) {
      return <OperatorProviderList />;
    }
    return null;
  };

  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default Vendors;
