import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "@shopify/polaris";
import StyledToggle from "./style";

const ToggleSwitch = (props) => {
  const { checked, id = "toggle-switch", loading, onAction } = props;
  return (
    <StyledToggle>
      <label htmlFor={id} className="toggle-switch">
        <input id={id} type="checkbox" checked={checked} onChange={onAction} />
        <span className="switch">
          <span className="tick" />
          <span className="cross" />
        </span>
        {loading && (
          <span className="loading">
            <Spinner accessibilityLabel="Small spinner example" size="small" />
          </span>
        )}
      </label>
    </StyledToggle>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  onAction: PropTypes.func,
};
ToggleSwitch.defaultProps = {
  checked: false,
  loading: false,
  onAction: () => {},
};
export default ToggleSwitch;
