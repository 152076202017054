import React, { useState } from "react";

import { Button, Modal } from "@shopify/polaris";
import { useMutation } from "react-apollo";

import constant from "lib/constant/constant";

import { connectedShopProp } from "app/product/modules/generic/propTypes";

import { DISCONNECT_WIX_SHOP } from "app/product/apollo/mutations";

import { baseHelper, errorHelper } from "lib/helpers";

const ConnectedShop = (props) => {
  const { currentUserData, cms, setBanner, setIsActive, setIsConnected } = props;
  const [isDisconnectActive, setIsDisconnectActive] = useState(false);

  const [disconnectWixShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_WIX_SHOP);
  const { DISCONNECT_WIX_SHOP: DISCONNECT } = constant.gql;

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectWixShop({
        variables: { input: { id: currentUserData._id } },
      });
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("section.connect.message.success.disconnected"),
      });
      setIsActive(false);
      setIsDisconnectActive(!isDisconnectActive);
      setIsConnected(false);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  // const disconnectShopConfirm = async () => {
  //   setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("section.connect.message.success.disconnected") });
  //   setIsActive(false);
  //   setIsDisconnectActive(!isDisconnectActive);
  // };

  const handleDisconnectModal = () => {
    setIsDisconnectActive(!isDisconnectActive);
  };

  return (
    <>
      <Modal
        open={isDisconnectActive}
        onClose={handleDisconnectModal}
        title={cms("section.connect.label.disconnectShop")}
        primaryAction={{
          content: `${cms("section.connect.label.disconnectShop")}`,
          onAction: disconnectShopConfirm,
          destructive: true,
          loading: disconnectLoading,
        }}
        secondaryActions={[
          {
            content: cms("section.connect.button.cancel"),
            onAction: handleDisconnectModal,
          },
        ]}
      >
        <Modal.Section>
          {`${cms("section.connect.label.disconnectWoocommerceShop")} ${cms("section.connect.label.wixShop")}?`}
        </Modal.Section>
      </Modal>
      <Button plain destructive id="disconnect" onClick={handleDisconnectModal}>
        {cms("section.connect.label.disconnectShop")}
      </Button>
    </>
  );
};

ConnectedShop.propTypes = connectedShopProp;

export default ConnectedShop;
