import React, { useContext } from "react";
import { Heading, TextContainer, TextStyle, Stack } from "@shopify/polaris";
import TimeService from "app/setup/modules/operator/features/_fulfillment/generic/consignmentShip/consignmentShip";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

const HybridTimeService = () => {
  const { cms } = useContext(FulfillmentContext);
  return (
    <div className="hybridTimeService">
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("hybrid.timeService.title")}</Heading>
          </Stack.Item>
          <br />
          <TextStyle variation="subdued">{cms("hybrid.timeService.description")}</TextStyle>
        </Stack>
      </TextContainer>
      <br />
      <TimeService method="hybridTimeService" />
    </div>
  );
};

export default HybridTimeService;
