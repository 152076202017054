import { gql } from "apollo-boost";

const UPDATE_VENDOR_FIELD = gql`
  mutation updateVendorField($input: UpdateVendorField) {
    updateVendorField(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_VENDOR_FIELD;
