import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { shopSubDomainSchema, apiAccessTokenSchema, apiAppKeySchema, apiAppSecretKeySchema } from "./schema";

const { API_ACCESS_TOKEN, REGISTERSUBDOMIN, API_APP_KEY, API_APP_SECRET_KEY } = constant;

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case REGISTERSUBDOMIN:
      promise = validateValue(shopSubDomainSchema(cms("common.appPermission.message.error.subDomainRequired")), {
        shopSubDomain: value,
      });
      break;
    case API_ACCESS_TOKEN:
      promise = validateValue(apiAccessTokenSchema(cms("common.appPermission.message.error.apiTokenRequired")), {
        apiAccessToken: value,
      });
      break;

    case API_APP_KEY:
      promise = validateValue(apiAppKeySchema(cms("common.appPermission.message.error.apiKeyRequired")), {
        apiKey: value,
      });
      break;

    case API_APP_SECRET_KEY:
      promise = validateValue(apiAppSecretKeySchema(cms("common.appPermission.message.error.apiSecretKeyRequired")), {
        apiSecretKey: value,
      });
      break;

    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
