import { gql } from "apollo-boost";

const GET_PRODUCT_LIST = gql`
  query getProductList($input: GetListInputV1) {
    getProductList(input: $input) {
      status
      error
      data {
        ... on SellerProductListData {
          count
          productList {
            _id
            title
          }
        }
        ... on ProductListData {
          count
          productList {
            _id
            title
          }
        }
      }
    }
  }
`;
export default GET_PRODUCT_LIST;
