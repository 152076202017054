import { gql } from "apollo-boost";

const UPDATE_PRODUCT_EXPORT_SETTING = gql`
  mutation updateProductExportSetting($input: ProductExportSetting) {
    updateProductExportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_PRODUCT_EXPORT_SETTING;
