import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Caption,
  Card,
  Checkbox,
  DataTable,
  Layout,
  PageActions,
  SettingToggle,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_ORDER_EXPORT_SETTING, UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING } from "app/setup/apollo/mutations";
import { GET_ORDER_EXPORT_SETTING } from "app/setup/apollo/queries";
import { GET_WHOLESALE_SETTING } from "app/product/apollo/queries";

import ExportTable from "./style";

const { gql } = constant;

const OrderExportSetting = () => {
  const {
    CRITICAL,
    HIDDEN,
    PARTIALLY_HIDDEN,
    ORDER_COLUMN_CONTENT,
    ORDER_COLUMN_HEADING,
    SUCCESS,
    VISIBLE,
    WARNING,
  } = constant;

  const { cms, currentUser, history } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const [isWholesalePrice, setIsWholesalePrice] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [markupDiscountExportSetting, setMarkupDiscountExportSetting] = useState(false);
  const [userInput, setUserInput] = useState({
    orderId: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    lineItemId: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    product: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    variantSku: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    variantWeight: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    orderNumber: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    orderStatus: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    fulfillmentStatus: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    quantity: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    price: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    wholesalePrice: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    createdAt: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    vendor: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    markupPrice: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    trackingNumber: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingCountry: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingName: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingAddress: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingCity: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingState: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    shippingPincode: {
      label: "",
      isHideCsv: false,
      isHidePdf: false,
    },
    discount: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shipping: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    totalVendorPayout: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    tax: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    customerPrice: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    comparePrice: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
  });

  const { /* loading: wholesaleLoading, */ data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);

  const [orderSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_ORDER_EXPORT_SETTING);
  const [markupExportSetting, { loading: markupDiscountExportLoading }] = useMutation(
    UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING
  );
  const {
    /* loading: loadingOrderExport, */
    error: errorOrderExportSetting,
    data: dataOrderExportSetting,
    refetch,
  } = useQuery(GET_ORDER_EXPORT_SETTING);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, constant.gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        const { isWholesalePriceVisible = false } = wholesaleResponse;
        setIsWholesalePrice(isWholesalePriceVisible);
      }
    }
  }, [wholesaleData]);

  useEffect(() => {
    if (dataOrderExportSetting) {
      const orderData = baseHelper.getResponseData(dataOrderExportSetting, gql.GET_ORDER_EXPORT_SETTING);
      const orderError = baseHelper.getResponseError(errorOrderExportSetting, gql.GET_ORDER_EXPORT_SETTING);
      const data = orderData.export;
      const { isMarkupDiscountExport } = orderData;
      if (data) {
        const keys = Object.keys(userInput);
        keys.forEach((key) => {
          if (!data[key]) {
            data[key] = {
              label: "",
              isHideCsv: false,
              isHidePdf: false,
            };
          }
        });
        if (data.createdAt) {
          setUpdatedAt(orderData.updatedAt);
          setFirstPush(false);
          setUserInput(data);
        }
      }
      setMarkupDiscountExportSetting(isMarkupDiscountExport);
      if (orderError) {
        setBannerStatus({ isOpen: true, title: orderError, status: constant.CRITICAL });
      }
    }
  }, [dataOrderExportSetting]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const currentLabel = [];
    const noUncheckCsv = Object.keys(userInput)
      .filter((item) => {
        return isWholesalePrice
          ? (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.COMPARE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting && isVendor)
          : (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.COMPARE_PRICE &&
              item !== constant.WHOLESALE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.WHOLESALE_PRICE &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting && isVendor && item !== constant.WHOLESALE_PRICE);
      })
      .map((item) => {
        newData.push(userInput[item]);
        currentLabel.push(cms(`label.${item}`));
        if (userInput[item].isHideCsv) {
          return true;
        }
        return false;
      });
    const noUncheckPdf = Object.keys(userInput)
      .filter((item) => {
        return isWholesalePrice
          ? (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX_KEY &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.COMPARE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting && isVendor)
          : (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX_KEY &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.WHOLESALE_PRICE &&
              item !== constant.COMPARE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.WHOLESALE_PRICE &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting && isVendor && item !== constant.WHOLESALE_PRICE);
      })
      .map((item) => {
        if (userInput[item].isHidePdf) {
          return true;
        }
        return false;
      });

    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        const found = currentLabel.find((label) => label === element.label);
        if ((element.label && item.label && element.label.trim() === item.label.trim()) || found) {
          response = true;
          setBannerStatus({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          return false;
        }
        return false;
      });
    });
    if (!response) {
      response = true;
      noUncheckCsv.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (!response) {
      response = true;
      noUncheckPdf.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const updateMarkupDiscountExportSetting = async () => {
    markupExportSetting({ variables: { input: { isMarkupDiscountExport: !markupDiscountExportSetting } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_MARKUP_DISCOUNT_EXPORT_SETTING);
          if (resData) {
            setMessage(cms("message.success.save"));
            setMarkupDiscountExportSetting(!markupDiscountExportSetting);
            refetch();
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const updateOrderSetting = async () => {
    orderSetting({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_ORDER_EXPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_ORDER_EXPORT_SETTING);
          if (resData) {
            if (firstPush) {
              setMessage(cms("message.success.order"));
            } else {
              setMessage(cms("message.success.update"));
            }
            refetch();
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val;
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };

  const selectCurrentLabelsColumn = (item) => <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField value={data && data.label} onChange={(value) => handleLabelChange(value, key)} />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === "isCsv") setUserInput({ ...userInput, [key]: { ...updateData, isHideCsv: !updateData.isHideCsv } });
    if (value === "isPdf") setUserInput({ ...userInput, [key]: { ...updateData, isHidePdf: !updateData.isHidePdf } });
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => (
    <>
      <Checkbox
        label={cms("label.isCsv")}
        checked={userInput[index] && userInput[index].isHideCsv}
        onChange={() => handleCheckbox("isCsv", index)}
      />{" "}
      <Checkbox
        label={cms("label.isPdf")}
        checked={userInput[index] && userInput[index].isHidePdf}
        onChange={() => handleCheckbox("isPdf", index)}
      />
    </>
  );

  const getRows = () => {
    return Object.keys(userInput)
      .filter((item) => {
        return isWholesalePrice
          ? (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX_KEY &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.COMPARE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting && isVendor && item !== constant.VENDOR)
          : (!isVendor &&
              item !== constant.TOTAL_VENDOR_PAYOUT &&
              item !== constant.SHIPPING &&
              item !== constant.DISCOUNT &&
              item !== constant.TAX_KEY &&
              item !== constant.CUSTOMER_PRICE &&
              item !== constant.WHOLESALE_PRICE &&
              item !== constant.COMPARE_PRICE) ||
              (!markupDiscountExportSetting &&
                isVendor &&
                item !== constant.MARKUP_PRICE &&
                item !== constant.VENDOR &&
                item !== constant.WHOLESALE_PRICE &&
                item !== constant.DISCOUNT) ||
              (markupDiscountExportSetting &&
                isVendor &&
                item !== constant.WHOLESALE_PRICE &&
                item !== constant.VENDOR);
      })
      .map((item) => {
        let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        const itemData = userInput[item];
        if (itemData.isHideCsv && itemData.isHidePdf) {
          badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
        } else if (itemData.isHidePdf || itemData.isHideCsv) {
          badge = { badgesLable: PARTIALLY_HIDDEN, badgesStatus: WARNING };
        } else {
          badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        }
        const currentValues = selectCurrentLabelsColumn(item);
        const newValues = selectNewLabelsTextField(userInput[item], item);
        const badgesValues = badges(badge);
        const hideColumnValues = selectHideColumn(item);
        return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
      });
  };

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        {!isVendor && (
          <Layout.AnnotatedSection title={cms("markupExportTitle")} description={cms("markupExportDescription")}>
            <SettingToggle
              action={{
                id: "submit",
                content: markupDiscountExportSetting ? constant.DISABLE : constant.ENABLE,
                onAction: () => updateMarkupDiscountExportSetting(),
                // disabled: markupDiscountExportSetting,
                destructive: markupDiscountExportSetting,
                loading: markupDiscountExportLoading,
              }}
              enabled={markupDiscountExportSetting}
            >
              {cms("settingDescription")}
              <TextStyle variation="strong">
                {`${markupDiscountExportSetting ? constant.ENABLED_CAPITAL : constant.DISABLED_CAPITAL}`}
              </TextStyle>
            </SettingToggle>
          </Layout.AnnotatedSection>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card
            title={[
              cms("label.manage"),
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <ExportTable className="payment-export-table">
                <DataTable columnContentTypes={ORDER_COLUMN_CONTENT} headings={ORDER_COLUMN_HEADING} rows={getRows()} />
              </ExportTable>
              {!firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) updateOrderSetting();
                    },
                    disabled: !submitEnabled,
                    loading: settingUpdateLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </Card.Section>
          </Card>
          {firstPush && (
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => {
                  const update = verifyValues();
                  if (!update) updateOrderSetting();
                },
                disabled: !submitEnabled,
                loading: settingUpdateLoading,
              }}
              secondaryActions={[
                {
                  id: "cancelButton",
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </Layout.AnnotatedSection>
        <Toast message={message} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(OrderExportSetting), { feature: constant.ORDER_EXPORT_SETTING });
