import { gql } from "apollo-boost";

const GET_PRODUCT_LAKE = gql`
  query getProductLake($input: GetProductLakeInput) {
    getProductLake(input: $input) {
      data {
        name
        description
        pageTitle
        type
        approvalStatus
        priceId {
          costPrice
          calculatedPrice
          price
          salePrice
          regularPrice
        }
        inventoryId {
          productId
          sku
          quantity
          isManagingStock
        }
        dimensions {
          weight
        }
        images {
          imageId
          imageUrl
        }
        variants {
          _id
          images {
            imageUrl
            imageId
          }
          providerVariantId
          optionValues {
            providerOptionValueId
            label
            sortOrder
            optionId {
              providerOptionId
              displayName
              sortOrder
            }
          }
          price
          priceId {
            costPrice
            calculatedPrice
            price
            salePrice
            regularPrice
          }
          option
          inventoryId {
            productId
            sku
            quantity
            isManagingStock
          }
          weight
          images {
            imageId
            imageUrl
          }
        }
        ownerId
        sellerId
        providerIds
        providerProductId
        ecommercePlatform
        weight
      }
      status
      error
    }
  }
`;
export default GET_PRODUCT_LAKE;
