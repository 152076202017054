// packages
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// constant
import constant from "lib/constant/constant";

// helpers
import { baseHelper, storageHelper } from "lib/helpers";

// import context
import { PublicContext, PublicBrandingProvider } from "lib/context";

// gql
import { GET_SELLER_DATA } from "routes/public/apollo/queries";
import config from "configuration";

const {
  gql: { GET_SELLER_DATA: GET_SELLER_DATA_GQL = "getSellerData" },
} = constant;

const PublicBrandingContainer = (props) => {
  const { children = null } = props;
  const { history, match } = useContext(PublicContext);
  const { store } = baseHelper.queryParamsFromLocation(history);
  const { params } = match || {};
  const { shop } = params || {};

  const [branding, setBranding] = useState({});
  const hostName = window.location.host;

  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;

  const reqData = {
    domain: hostName,
    shop: store || shop || "",
  };
  // gql states
  const { data: sellerData, loading } = useQuery(GET_SELLER_DATA, {
    variables: { input: reqData },
  });

  let buttonCSS = "";
  const buttonStylingChange = useCallback(({ buttonColor, buttonFont }) => {
    const setDefault = "setDefault";
    const defaultButtonColor = "#008060";

    const buttonText = buttonFont || "";
    let bColor = "";
    if (buttonColor) {
      bColor = `linear-gradient(180deg,${buttonColor},${buttonColor})`;
    }

    if (buttonColor === setDefault) {
      bColor = "";
    }

    let buttonCSSData = "";
    if (bColor && buttonText) {
      buttonCSSData = `.Polaris-Page .Polaris-Button--primary:not(.Polaris-Button--disabled) {
          background: ${bColor || defaultButtonColor} !important;
          border-color: ${buttonColor} !important;
          color: ${buttonText} !important;
          ${bColor && `box-shadow: inset 0 1px 0 0 ${buttonColor}, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;`}
        }
      `;
    } else if (bColor && buttonColor) {
      buttonCSSData = `.Polaris-Page .Polaris-Button--primary:not(.Polaris-Button--disabled) {
          background: ${bColor || defaultButtonColor} !important;
          border-color: ${buttonColor} !important;
          ${bColor && `box-shadow: inset 0 1px 0 0 ${buttonColor}, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;`}
        }
      `;
    } else if (buttonText) {
      buttonCSSData = `.Polaris-Page .Polaris-Button--primary:not(.Polaris-Button--disabled) {
          color: ${buttonText} !important;
        }
      `;
    }
    buttonCSS = buttonCSSData;
    storageHelper.set("buttonCSS", buttonCSS);
  }, []);

  const responseData = baseHelper.getResponseData(sellerData, GET_SELLER_DATA_GQL);
  const responseError = baseHelper.getResponseError(sellerData, GET_SELLER_DATA_GQL);
  const { plan: { isBranding = false } = {} } = responseData || {};

  useEffect(() => {
    // To check sellerData available and seller has branding
    if (responseData && isBranding) {
      const { brand } = responseData || {};
      const { termsLink = null, policyLink = null } = brand || {};
      storageHelper.set("termsLink", termsLink);
      storageHelper.set("policyLink", policyLink);
    }
    if (isCustomDomain) {
      if (responseData && isBranding) {
        const { style, loginText, logo, register, brandName, isHideMcInfo, brand, _id = {}, isProductDiscovery = false } = responseData || {};
        const { header: headerData, button: buttonData } = style || {};
        const { color: headerColor = null, font: headerFont = null } = headerData || {};
        const { color: buttonColor = null, font: buttonFont = null } = buttonData || {};

        const isHidePoweredBy = isBranding && isHideMcInfo; // && baseHelper.isProPlan(responseData)

        const { termsLink = null, policyLink = null, favicon } = brand || {};
        const { imageUrl: faviconUrl = "" } = favicon || {};

        buttonStylingChange({ buttonColor, buttonFont });

        setBranding((prevState) => ({
          ...prevState,
          logoUrl: logo,
          brand,
          isDefaultLogo: !(logo && logo.trim() !== "") || false,
          loginText: loginText || false,
          register: register || false,
          brandName: brandName || false,
          sellerButtonColor: style && style.button && style.button.color,
          buttonFont: style && style.button && style.button.font,
          hideMcDetails: isHideMcInfo,
          isHidePoweredBy,
          headerColor,
          headerFont,
        }));

        storageHelper.set("headerColor", headerColor);
        storageHelper.set("loginText", loginText);
        storageHelper.set("logoUrl", logo);
        storageHelper.set("register", register);
        storageHelper.set("brandName", brandName);
        storageHelper.set("headerFont", headerFont);
        storageHelper.set("hideMcDetails", isHideMcInfo);
        storageHelper.set("hidePoweredBy", isHidePoweredBy);
        storageHelper.set("termsLink", termsLink);
        storageHelper.set("policyLink", policyLink);
        storageHelper.set("sellerId", _id);
        storageHelper.set("faviconUrl", faviconUrl);
        // if (isProductDiscovery) {
        //   storageHelper.set("hideTermsAndPrivacy", true);
        // }
      }

      if (responseError || (responseData && !isBranding)) {
        storageHelper.remove("headerColor");
        storageHelper.remove("loginText");
        storageHelper.remove("logoUrl");
        storageHelper.remove("register");
        storageHelper.remove("brandName");
        storageHelper.remove("headerFont");
        storageHelper.remove("hideMcDetails");
        storageHelper.remove("hidePoweredBy");
        storageHelper.remove("termsLink");
        storageHelper.remove("policyLink");
        storageHelper.remove("sellerId");
        storageHelper.remove("buttonCSS");
        storageHelper.remove("faviconUrl");
        storageHelper.remove("hideTermsAndPrivacy");
      }
    }
  }, [responseData, responseError, isBranding, buttonStylingChange, isCustomDomain]);

  if (!buttonCSS || buttonCSS.trim() === "") {
    buttonCSS = storageHelper.get("buttonCSS");
  }

  return (
    <>
      <style>{buttonCSS}</style>
      <PublicBrandingProvider
        value={{
          branding,
          brandingLoading: loading,
        }}
      >
        {children}
      </PublicBrandingProvider>
    </>
  );
};

PublicBrandingContainer.propTypes = {
  children: PropTypes.node,
};

PublicBrandingContainer.defaultProps = {
  children: null,
};

export default PublicBrandingContainer;
