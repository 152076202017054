import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { ChoiceList, Modal, Stack } from "@shopify/polaris";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

// import mutuation
import { ENABLE_DISABLE_VENDOR } from "app/vendors/apollo/mutations";
import { GET_SELLER_FULFILLMENT } from "app/setup/apollo/index";

import PropTypes from "prop-types";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import cmsVendorList from "app/vendors/modules/operator/feature/list/cms/vendorListCMS";

function DisableVendor(props) {
  const { cms } = useContext(PrivateContext);
  const vendorListCMS = cmsVendorList(cms);
  const { helpText, label, message } = vendorListCMS;
  const [isReserve, setIsReserve] = useState(false);
  const {
    closeDisableModal,
    disableModalOpen,
    disableVendorId,
    refetch,
    selectedValue,
    setBanner,
    setSelectedValue,
    setMessageToast,
  } = props;
  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);
  const [enableDisableVendor, { loading: vendorAvailabilityLoading }] = useMutation(ENABLE_DISABLE_VENDOR);

  const handleSelectedValue = useCallback((value) => {
    setSelectedValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleConfirm = async () => {
    const isReject = selectedValue[0] === label.rejectOrder ? true : false || false;
    try {
      const response = await enableDisableVendor({
        variables: { input: { vendorId: disableVendorId, isRejected: isReject } },
      });
      closeDisableModal(false);
      setMessageToast(message.vendorDisable);
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_VENDOR_AVAILABILITY);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };
  const handleClose = () => {
    closeDisableModal(false);
  };

  useEffect(() => {
    if (fulfillmentData) {
      const resData = baseHelper.getResponseData(fulfillmentData, constant.gql.GET_SELLER_FULFILLMENT);
      const { clickReserve = {}, default: defaultFulfillmentType = "" } = resData || {};
      const { setting = [] } = clickReserve || {};
      let result = false;
      if (setting.length) {
        result = setting.map((vendor) => vendor && vendor.vendorId).includes(disableVendorId);
      }
      if (
        resData.type === constant.RESERVE ||
        (resData.type === constant.HYBRID && (defaultFulfillmentType === constant.RESERVE || result))
      ) {
        setIsReserve(true);
      } else {
        setIsReserve(false);
      }
    }
  }, [fulfillmentData, disableVendorId]);

  return (
    <>
      <Modal
        open={disableModalOpen}
        onClose={closeDisableModal}
        title={constant.providerList.DISABLE}
        primaryAction={{
          content: constant.CONFIRM,
          onAction: handleConfirm,
          loading: vendorAvailabilityLoading,
        }}
        secondaryActions={[
          {
            content: constant.CLOSE,
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <ChoiceList
                choices={[
                  {
                    label: label.doNothing,
                    value: label.doNothing,
                    helpText: isReserve ? helpText.doHelpTextCR : helpText.doHelpText,
                  },
                  { label: label.rejectOrder, value: label.rejectOrder, helpText: helpText.rejectHelpText },
                ]}
                selected={selectedValue}
                onChange={handleSelectedValue}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
}

DisableVendor.defaultProps = {
  disableModalOpen: false,
  closeDisableModal: () => {},
};

DisableVendor.propTypes = {
  disableModalOpen: PropTypes.bool,
  closeDisableModal: PropTypes.func,
};
export default withErrorBoundary(DisableVendor);
