import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";
import { AdminSessionList } from "./admin/features";

const { userKey } = constant;
const { admin } = userKey;

const UserSession = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === admin) {
      return <AdminSessionList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const UserSessionComponent = getComponent(currentUserRole);
  return UserSessionComponent;
};

export default UserSession;
