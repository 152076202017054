import React, { useContext } from "react";
import { Card, FormLayout, TextField, Select, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "app/productOld/modules/admin/features/add/context/context";

const {
  SHOPIFY = "",
  QUANTITY_DISABLED = "",
  SKU = "",
  BARCODE = "",
  POLICY_SELECTION = "",
  INVENTORY_QUANTITY = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999 },
} = constant;

const Inventory = () => {
  const { cms } = useContext(PrivateContext);
  const { data, handleChange, learnMore } = useContext(FormContext);
  const isDisabled = data.policySelection === SHOPIFY;
  if (data.quantityDisabled !== isDisabled) {
    handleChange(QUANTITY_DISABLED, isDisabled);
  }

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && data.inventoryQuantity);
  };

  const policyOption = [
    { label: cms("section.form.section.inventory.label.shopify"), value: "shopify" },
    { label: cms("section.form.section.inventory.label.dontTrack"), value: "Don't track inventory" },
  ];

  return (
    <Card
      title={cms("section.form.section.inventory.title")}
      id="productInventory"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productInventory", cms("section.form.section.inventory.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>{cms("section.form.section.inventory.description")}</TextContainer>
          <FormLayout.Group>
            <TextField
              id="sku"
              label={cms("section.form.section.inventory.label.sku")}
              value={data.sku || ""}
              onChange={(value) => handleChange(SKU, value)}
            />
            <TextField
              id="barcode"
              label={cms("section.form.section.inventory.label.barcode")}
              value={data.barcode || ""}
              onChange={(value) => handleChange(BARCODE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              id="policySelection"
              label={cms("section.form.section.inventory.label.inventory")}
              options={policyOption}
              value={data.policySelection || ""}
              onChange={(value) => handleChange(POLICY_SELECTION, value)}
            />
            <TextField
              id="inventoryQuantity"
              disabled={!isDisabled}
              label={cms("section.form.section.inventory.label.quantity")}
              value={data.inventoryQuantity || ""}
              min={MIN_PRICE}
              max={MAX_FLAT}
              onChange={(value) => {
                handleChange(INVENTORY_QUANTITY, acceptOnlyValidInput(value));
              }}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Inventory;
