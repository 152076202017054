import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Badge,
  Banner,
  Caption,
  Card,
  FormLayout,
  Layout,
  Link,
  Modal,
  ResourceList,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import _ from "lodash";
import { READY_TO_COLLECT } from "app/orders/apollo/mutations";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { StyledCard, StyledLayout, StyledModal } from "app/orders/modules/operator/features/view/genericStyled";
import { withErrorBoundary } from "lib/hoc";
import StyledTitle from "app/orders/modules/generic/style/style";
import { FieldSetQty } from "./modalStyle";
import { StyledDiv } from "./style/style";

const FulfillModel = (props) => {
  const {
    active,
    addressList,
    bulkFulfillItem,
    isEnabledData,
    loading,
    orderLineItemsData,
    refetchOrderFulfillment,
    refetchOrderLineView,
    refetchOrderView,
    selectedItems,
    setActive,
    setMessage,
    setBanner,
    setSelectedItems,
  } = props;

  const [filteredItems, setFilteredItems] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [noteMessage, setNoteMessage] = useState("");
  const [fulfillmentTypes, setFulfillmentTypes] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [trackingCompany, setTrackingCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [isTrackingCompany, setIsTrackingCompany] = useState(false);
  const [isTrackingNumber, setIsTrackingNumber] = useState(false);
  const [selectedFulfillmentType, setSelectedFulfillmentType] = useState("");
  const { cms, history } = useContext(PrivateContext);
  const { gql, fulfillmentTypes: fulfillments, WARNING } = constant;
  const { BULK_ORDER_FULFILL } = gql;
  const [addressLabel, setAddressLabel] = useState([]);
  const [selectedAddressLabel, setSelectedAddressLabel] = useState("");
  const [selectedAddress, setSelectedAddress] = useState({});
  const [customerAddressIds, setCustomerAddressId] = useState([]);
  const [readyForCollectOrder, { loading: readyToCollectLoading }] = useMutation(READY_TO_COLLECT);

  const isPickReserve = fulfillmentTypes && fulfillmentTypes.includes("pickup");
  const isReserve = fulfillmentTypes && fulfillmentTypes.includes("reserve");
  // need to add message for bulk - --
  useEffect(() => {
    if (selectedItems && active) {
      const arr = [];
      const arrVendor = [];
      selectedItems.forEach((element) => {
        const items = orderLineItemsData.find(
          (item) =>
            item._id === element && item.status === constant.ACCEPTED && item.fulfillmentType !== constant.PICKUP
        );
        if (items) {
          const { fulfillableQuantity, vendor, vendorId } = items;
          arr.push({ ...items, totalQuantity: fulfillableQuantity });
          const find = arrVendor.find((item) => item.value === vendorId);
          if (!find) {
            arrVendor.push({ label: vendor, value: vendorId });
          }
          if (items.trackingCompany) {
            setInitialTrackingCompany(items.trackingCompany);
            setIsTrackingCompany(true);
          }
          if (items.trackingNumber) {
            setInitialTrackingNumber(items.trackingNumber);
            setIsTrackingNumber(true);
          }
          if (fulfillmentTypes.includes(constant.CONSIGNMENT)) {
            setInitialTrackingCompany("");
            setInitialTrackingNumber("");
            setIsTrackingNumber(false);
            setIsTrackingCompany(false);
          }
        }
      });
      if (arrVendor.length === 1) {
        setSelectedVendor(arrVendor[0].value);
      }
      setFilteredItems(arr);
      setVendors(arrVendor);
    }
  }, [fulfillmentTypes, active, setInitialTrackingCompany]);

  useEffect(() => {
    if (selectedVendor) {
      const arrFulfillment = [];
      filteredItems
        .filter((item) => item.vendorId === selectedVendor.toString())
        .forEach((value) => {
          const find = arrFulfillment.includes(value.fulfillmentType);
          if (!find) {
            arrFulfillment.push(value.fulfillmentType);
          }
        });
      if (arrFulfillment.length === 1) {
        setSelectedFulfillmentType(arrFulfillment[0] || constant.DROPSHIP);
      }
      setFulfillmentTypes(arrFulfillment);
    }
  }, [selectedVendor, setSelectedVendor]);

  const resetAllState = () => {
    const defaultAddress = addressList.find((item) => item.isDefault);
    if (defaultAddress) {
      setSelectedAddress(defaultAddress);
      setSelectedAddressLabel(defaultAddress._id);
    }
    setNoteMessage("");
    setFilteredItems([]);
    setLineItems([]);
    setVendors([]);
    setCustomerAddressId([]);
    setFulfillmentTypes([]);
    setSelectedVendor("");
    setTrackingCompany("");
    setTrackingNumber("");
    setSelectedFulfillmentType("");
  };

  useEffect(() => {
    if (
      [constant.PICKUP, constant.RESERVE, constant.COLLECT].includes(selectedFulfillmentType) &&
      addressList &&
      !addressList.length
    ) {
      setActive(!active);
      resetAllState();
      setSelectedItems([]);
      setBanner({
        isOpen: true,
        title: cms("message.error.address"),
        status: constant.CRITICAL,
      });
    } else if (selectedFulfillmentType) {
      let itemsArr = [];
      const arrAddress = [];
      filteredItems
        .filter(
          (item) =>
            item.vendorId === selectedVendor &&
            (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
            item.status === constant.ACCEPTED
        )
        .forEach((val) => {
          const { properties = [] } = val;
          const addressData = properties.find((value) => value && value.name === constant.PICKUP_LOCATION) || {};
          if (addressData.value && !arrAddress.includes(addressData.value)) {
            arrAddress.push(addressData.value);
          }
        });
      if (arrAddress && arrAddress.length && selectedFulfillmentType === constant.COLLECT) {
        arrAddress.forEach((address) => {
          const addressLine = [];
          filteredItems
            .filter(
              (val) =>
                val.vendorId === selectedVendor &&
                val.fulfillmentType === constant.COLLECT &&
                val.status === constant.ACCEPTED
            )
            .forEach((val) => {
              const { properties = [] } = val || {};
              const addressData = properties.find((value) => value && value.name === constant.PICKUP_LOCATION) || {};
              if (addressData.value === address) {
                addressLine.push(val);
              }
            });
          itemsArr.push(addressLine);
        });
      } else {
        itemsArr = filteredItems.filter(
          (item) =>
            item.vendorId === selectedVendor &&
            (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
            item.status === constant.ACCEPTED
        );
      }
      setNoteMessage("");
      setCustomerAddressId([...new Set(arrAddress)]);
      setLineItems(itemsArr);
    }
  }, [selectedFulfillmentType]);

  useEffect(() => {
    if (addressList && addressList.length) {
      const formattedAddress = addressList
        .filter((item) => item.label && item)
        .map(({ _id: addressID, label, isDefault = false }) => ({
          label: `${label} ${isDefault ? `(${cms("label.defaultAddress")})` : ""}`,
          value: addressID,
          isDefault: !!isDefault,
        }));
      setAddressLabel(formattedAddress);
      const defaultAddress = addressList.filter((item) => item.isDefault);
      setSelectedAddress(defaultAddress[0]);
      // eslint-disable-next-line no-underscore-dangle
      setSelectedAddressLabel(defaultAddress[0]._id);
    }
  }, [addressList]);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleAddressChange = (value) => {
    setSelectedAddressLabel(value);
    const newAddress = addressList && addressList.find((address) => address._id === value);
    if (newAddress) {
      setSelectedAddress(newAddress);
    }
  };

  const handleFulfillmentSelect = useCallback((value) => {
    setTrackingCompany("");
    setTrackingNumber("");
    setSelectedFulfillmentType(value);
  }, []);

  const handleQuantityChange = (id, value) => {
    const items = filteredItems;
    const index = items.findIndex((item) => item._id === id);
    items[index] = { ...items[index], fulfillableQuantity: value };
    setFilteredItems([...items]);
  };

  const handleNoteChange = (value, arrItems) => {
    if (arrItems && arrItems.length) {
      const items = filteredItems.map((val) => (arrItems.includes(val._id) ? { ...val, note: value } : { ...val }));
      setFilteredItems([...items]);
      return;
    }
    setNoteMessage(value);
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const buttonDisable = () => {
    if (
      selectedFulfillmentType === constant.PICKUP ||
      (selectedFulfillmentType === constant.RESERVE && Object.keys(selectedAddress).length === 0)
    ) {
      return true;
    }
    let count = 0;
    const items = filteredItems.filter(
      (item) =>
        item.vendorId === selectedVendor &&
        (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
        item.status === constant.ACCEPTED
    );
    items.forEach((item) => {
      const { fulfillableQuantity } = item;
      if (Number(fulfillableQuantity) === constant.value.ZERO || fulfillableQuantity === "") {
        count += 1;
      }
    });
    return count === items.length;
  };

  const readyForCollect = async () => {
    const { _id: id } = selectedAddress;
    const orderLineItemIds = [];

    filteredItems
      .filter(
        (item) =>
          item.vendorId === selectedVendor &&
          (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
          item.status === constant.ACCEPTED &&
          parseInt(item.fulfillableQuantity, 10) !== constant.value.ZERO &&
          item.fulfillableQuantity !== ""
      )
      .forEach((item) => {
        const { _id, fulfillableQuantity, properties = [], note = "" } = item;
        const addressData = properties.find((value) => value && value.name === constant.PICKUP_LOCATION) || {};
        orderLineItemIds.push({
          id: _id,
          readyQuantity: parseInt(fulfillableQuantity, 10),
          addressId: addressData.value ? addressData.value : id,
          note: note || noteMessage,
        });
      });

    const formData = {
      orderLineItemIds,
    };
    if (!selectedItems) {
      formData.isSingle = true;
    }

    try {
      const response = await readyForCollectOrder({
        variables: {
          input: formData,
        },
      });
      setMessage("");
      setActive(!active);
      resetAllState();
      setSelectedItems([]);
      const responseError = baseHelper.getResponseError(response.data, constant.gql.READY_TO_COLLECT);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.READY_TO_COLLECT);

      if (responseError) {
        resetAllState();
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setMessage(cms("message.success.readyForCollection"));
      await refetchOrderLineView();
      await refetchOrderFulfillment();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleFulfillment = async () => {
    if (!(isEnabledData || initialTrackingCompany || trackingCompany)) {
      handleChange();
      resetAllState();
      setBanner({ isOpen: true, title: cms("message.error.trackingCompany"), status: constant.CRITICAL });
      return false;
    }

    if (!(isEnabledData || initialTrackingNumber || trackingNumber)) {
      handleChange();
      resetAllState();
      setBanner({ isOpen: true, title: cms("message.error.trackingNumber"), status: constant.CRITICAL });
      return false;
    }
    setMessage("");
    const bulkFulfillment = {
      bulkQuantity: [],
      fulfillmentType: selectedFulfillmentType.toString(),
      trackingCompany: initialTrackingCompany.toString() || trackingCompany.toString(),
      trackingNumber: initialTrackingNumber.toString() || trackingNumber.toString(),
    };

    filteredItems
      .filter(
        (item) =>
          item.vendorId === selectedVendor &&
          (item.fulfillmentType === selectedFulfillmentType || item.fulfillmentType === null) &&
          item.status === constant.ACCEPTED &&
          parseInt(item.fulfillableQuantity, 10) !== constant.value.ZERO &&
          item.fulfillableQuantity !== ""
      )
      .forEach((item) => {
        const { _id, fulfillableQuantity } = item;
        bulkFulfillment.bulkQuantity.push({
          id: _id,
          quantity: parseInt(fulfillableQuantity, 10),
        });
      });
    if (!selectedItems) {
      bulkFulfillItem.isSingle = true;
    }
    await bulkFulfillItem({ variables: { input: { ...bulkFulfillment } } })
      .then((response) => {
        setActive(!active);
        resetAllState();
        setSelectedItems([]);
        const responseError = baseHelper.getResponseError(response.data, BULK_ORDER_FULFILL);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }
        const responseData = baseHelper.getResponseData(response.data, BULK_ORDER_FULFILL);
        if (responseData) {
          setMessage(cms("message.bulkFulfill"));
          setBanner({ isOpen: false, status: constant.CRITICAL, title: responseError });
          setTimeout(() => {
            refetchOrderLineView();
            refetchOrderView();
            refetchOrderFulfillment();
          }, 2000);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };
  const validInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  const primaryAction = () => {
    if (selectedFulfillmentType === constant.RESERVE) {
      return {
        content: cms("label.readyForCollection"),
        onAction: () => {
          return readyForCollect();
        },
        disabled: buttonDisable() || loading,
        loading: loading || readyToCollectLoading,
      };
    }
    if (selectedFulfillmentType === constant.COLLECT) {
      return {
        content: cms("label.readyForCollection"),
        onAction: () => {
          return readyForCollect();
        },
        disabled: buttonDisable() || loading,
        loading: loading || readyToCollectLoading,
      };
    }
    if (selectedFulfillmentType === constant.SERVICE) {
      return {
        content: cms("label.readyService"),
        onAction: () => {
          return readyForCollect();
        },
        disabled: buttonDisable() || loading,
        loading: loading || readyToCollectLoading,
      };
    }
    if (selectedFulfillmentType === constant.PICKUP) {
      return {
        content: cms("label.readyForCollection"),
        onAction: () => {
          return readyForCollect();
        },
        disabled: buttonDisable() || loading,
        loading: loading || readyToCollectLoading,
      };
    }
    return {
      content: cms("label.saveDetails"),
      onAction: () => {
        return handleFulfillment();
      },
      disabled: buttonDisable() || loading,
      loading: loading || readyToCollectLoading,
    };
  };

  const partialBanner = () =>
    filteredItems.filter(
      (item) =>
        item.totalQuantity - item.fulfillableQuantity > 0 &&
        item.fulfillmentType === selectedFulfillmentType &&
        item.vendorId === selectedVendor
    ).length > 0 && (
      <Modal.Section>
        <Banner status={WARNING} title={cms("modal.banner.partial")} />
      </Modal.Section>
    );

  const trackingDetails = () => {
    return (
      <>
        <TextField
          label={cms("label.company")}
          value={initialTrackingCompany || trackingCompany}
          disabled={isTrackingCompany}
          onChange={(val) => {
            setTrackingCompany(validInput(val));
          }}
        />
        <br />
        <TextField
          label={cms("label.trackingNumber")}
          value={initialTrackingNumber || trackingNumber}
          disabled={isTrackingNumber}
          onChange={(val) => {
            setTrackingNumber(validInput(val));
          }}
        />
      </>
    );
  };

  const getModalText = (fulfillmentType) => {
    if ([constant.COLLECT, constant.RESERVE].includes(fulfillmentType)) {
      return cms("label.markReadyForCollection");
    }
    if (fulfillmentType === constant.SERVICE) {
      return cms("label.markReadyService");
    }
    return cms("label.bulkFulfillment");
  };

  const addressDetails = (lineItemData, arrItems = []) => {
    let addressDetailData;
    let addressLabelData;
    const { properties = [], fulfillmentType, _id: lineItemId } = lineItemData || {};
    const lineItem = filteredItems.find((value) => value._id === lineItemId) || {};
    const { note = "" } = lineItem || {};
    if (fulfillmentType === constant.COLLECT && properties && properties.length) {
      const addressData = properties.find((val) => val && val.name === constant.PICKUP_LOCATION);
      if (addressData && addressData.value) {
        const address = addressList.find((val) => val._id === addressData.value) || {};
        addressDetailData = address;
        addressLabelData = address._id;
      }
    }
    return (
      <>
        {/* {partialBanner()} */}
        <StyledLayout>
          <Card.Section>
            <Layout>
              <Layout.Section secondary>
                <Card title={cms("label.pickupLocation")} sectioned>
                  <FormLayout>
                    <Select
                      options={addressLabel}
                      label={cms("label.address")}
                      value={addressLabelData || selectedAddressLabel}
                      onChange={(value) => handleAddressChange(value)}
                      placeholder={cms("label.selectAddress")}
                      disabled={addressDetailData && addressLabelData}
                    />
                    {(addressDetailData || selectedAddress) &&
                      Object.keys(addressDetailData || selectedAddress).length !== 0 && (
                        <Stack vertical>
                          <Stack.Item>
                            <TextStyle>
                              <Stack wrap={false}>
                                <Stack.Item>
                                  <TextStyle>{(addressDetailData || {}).address || selectedAddress.address}</TextStyle>
                                  <br />
                                  <TextStyle>{(addressDetailData || {}).city || selectedAddress.city}</TextStyle>
                                  <br />
                                  <TextStyle>{(addressDetailData || {}).country || selectedAddress.country}</TextStyle>
                                  <br />
                                  <TextStyle>
                                    {(addressDetailData || {}).postalCode || selectedAddress.postalCode}
                                  </TextStyle>
                                  <br />
                                </Stack.Item>
                              </Stack>
                            </TextStyle>
                          </Stack.Item>
                        </Stack>
                      )}
                  </FormLayout>
                </Card>
                <br />
                <TextField
                  label={cms("label.note")}
                  value={note || noteMessage}
                  multiline={4}
                  onChange={(value) => handleNoteChange(value, arrItems)}
                />
              </Layout.Section>
            </Layout>
          </Card.Section>
        </StyledLayout>
      </>
    );
  };

  const renderElement = (item) => {
    const lineItem = filteredItems.find((value) => value._id === item._id) || {};
    const { _id, fulfillableQuantity, fulfillmentType, image, name, productId, status, totalQuantity, variantTitle } =
      lineItem || {};

    const bulkFulfillBadgeStatus = {
      pickup: constant.CONSIGNMENT_PICKUP_CAPITAL,
      consignment: constant.CONSIGNMENT_SHIP_CAPITAL,
    };

    const partial = totalQuantity - fulfillableQuantity;
    const lineImage = imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
    const badgeStatus =
      bulkFulfillBadgeStatus[baseHelper.getBadgeStatus(fulfillmentType)] || baseHelper.getBadgeStatus(fulfillmentType);
    return (
      <Modal.Section>
        <StyledCard>
          <StyledDiv>
            <Stack id={_id}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={name} />
              </Stack.Item>
              <Stack.Item fill>
                <StyledTitle>
                  {<Link onClick={() => history.push(`/products/edit/${productId}`)}>{name || ""}</Link>}
                  {variantTitle && (
                    <Caption>
                      <TextStyle variation={constant.SUBDUED}>{variantTitle.toUpperCase()}</TextStyle>
                    </Caption>
                  )}
                </StyledTitle>
                <Stack.Item>
                  {fulfillmentType && (
                    <Badge className="text-capitalize">
                      {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                    </Badge>
                  )}
                  {partial > 0 && (
                    <Badge status={constant.SUCCESS}>
                      {[constant.RESERVE, constant.COLLECT].includes(fulfillmentType)
                        ? cms("label.partialReady")
                        : constant.displayStatus.PARTIALLYFULFILLED}
                    </Badge>
                  )}
                </Stack.Item>
              </Stack.Item>
              <FieldSetQty>
                <Stack.Item>
                  <TextField
                    id={_id}
                    value={(fulfillableQuantity && fulfillableQuantity.toString()) || ""}
                    min={0}
                    max={totalQuantity}
                    onChange={(value) => [
                      value <= parseInt(totalQuantity, 10) &&
                        handleQuantityChange(_id, acceptOnlyValidInput(value, fulfillableQuantity)),
                    ]}
                    suffix={`of ${totalQuantity}`}
                  />
                </Stack.Item>
              </FieldSetQty>
            </Stack>
          </StyledDiv>
        </StyledCard>
      </Modal.Section>
    );
  };

  return (
    <Modal
      open={active}
      onClose={() => {
        handleChange();
        resetAllState();
      }}
      title={getModalText(selectedFulfillmentType)}
      primaryAction={primaryAction()}
    >
      {/* {vendors && vendors.length > 1 && (
        <Modal.Section>
          <Select label="Select Vendor" options={vendors} onChange={handleVendorSelect} value={selectedVendor} />
        </Modal.Section>
      )} */}
      {selectedVendor && fulfillmentTypes && fulfillmentTypes.length > 1 && (
        <Modal.Section>
          <Select
            label={cms("label.fulfillmentType")}
            options={fulfillments.map((fulfillmentType) => {
              fulfillmentType.disabled = !fulfillmentTypes.includes(fulfillmentType.value);
              return fulfillmentType;
            })}
            onChange={handleFulfillmentSelect}
            value={selectedFulfillmentType}
            placeholder={cms("label.fulfillmentType")}
          />
        </Modal.Section>
      )}
      {selectedVendor && selectedFulfillmentType && (
        <ResourceList
          items={lineItems}
          renderItem={(item) => {
            if (_.isArray(item)) {
              const lineItemIds = item.map((val) => val._id);
              const [lineItemData] = item;
              return (
                <>
                  <ResourceList
                    items={item}
                    renderItem={(lineItem) => {
                      return renderElement(lineItem);
                    }}
                  />
                  {selectedVendor &&
                    selectedFulfillmentType &&
                    [constant.COLLECT].includes(selectedFulfillmentType) &&
                    customerAddressIds &&
                    customerAddressIds.length &&
                    addressDetails(lineItemData, lineItemIds)}
                </>
              );
            }
            return renderElement(item);
          }}
        />
      )}
      {selectedVendor && selectedFulfillmentType && selectedFulfillmentType === constant.DROPSHIP && (
        <>
          {partialBanner()}
          <StyledModal>
            <Modal.Section>{trackingDetails()}</Modal.Section>
          </StyledModal>
        </>
      )}
      {selectedVendor && selectedFulfillmentType && selectedFulfillmentType === constant.CONSIGNMENT && (
        <>
          {partialBanner()}
          <StyledLayout>
            <Card.Section>
              <Layout>
                <Layout.Section>
                  <Stack>
                    <Stack.Item>
                      <TextContainer>
                        {cms("label.consignmentShip")}
                        <br />
                        <TextStyle variation="subdued">{cms("helpText.consignmentShip")}</TextStyle>
                      </TextContainer>
                    </Stack.Item>
                  </Stack>
                </Layout.Section>
                <Layout.Section secondary>
                  <Card sectioned>
                    <FormLayout>{trackingDetails()}</FormLayout>
                  </Card>
                </Layout.Section>
              </Layout>
            </Card.Section>
          </StyledLayout>
        </>
      )}
      {selectedVendor &&
        selectedFulfillmentType &&
        [constant.COLLECT, constant.RESERVE, constant.SERVICE].includes(selectedFulfillmentType) &&
        !(customerAddressIds && customerAddressIds.length) &&
        addressDetails()}
    </Modal>
  );
};

export default withErrorBoundary(FulfillModel);
