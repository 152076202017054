// import packages
import React, { useContext } from "react";
import { Button, TextField, Select, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

const OrderTax = (props) => {
  const { count, removeItem, updateRateVal, vendorOptions, taxes } = props;
  const {
    color: { RED },
  } = constant;
  const { cms = {} } = useContext(PrivateContext);

  const arr = Array.from(Array(count).keys());
  return arr.map((item) => {
    const taxItem = taxes[item];
    if (!taxItem) {
      return null;
    }

    const vendorSelect = `${constant.VENDOR_SELECT}${item}`;
    const taxClassText = `${constant.TAX_CLASS_TEXT}${item}`;
    const rateText = `${constant.RATE_TEXT}${item}`;

    return (
      <div key={item}>
        <Stack wrap={false} key={item} spacing="extraTight" distribution="fillEvenly" alignment="center">
          <Stack.Item>
            <div className="formWidth180px">
              <TextField
                placeholder={cms("placeholder.name")}
                id={taxClassText}
                type="text"
                onChange={(val) => {
                  updateRateVal(constant.TAX_CLASS, val, item);
                }}
                value={(taxItem && taxItem.taxClass) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth180px">
              <Select
                id={vendorSelect}
                placeholder={cms("placeholder.selectVendor")}
                options={vendorOptions}
                onChange={(val) => {
                  updateRateVal(constant.VENDOR_ID, val, item);
                }}
                value={(taxItem && taxItem.vendorId) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth180px">
              <TextField
                placeholder={cms("placeholder.rate")}
                type="number"
                id={rateText}
                suffix="%"
                value={(taxItem && taxItem.rate && taxItem.rate.toString()) || ""}
                onChange={(val) => {
                  updateRateVal(constant.RATE, val, item);
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button id="deleteButton" plain onClick={() => removeItem(item)}>
              <i className="far fa-trash fa-lg" style={{ color: RED }} />
            </Button>
          </Stack.Item>
        </Stack>
        <Stack.Item>
          <br />
        </Stack.Item>
      </div>
    );
  });
};

export default OrderTax;
