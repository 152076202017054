import React from "react";
import { Layout, Stack, TextField, TextStyle } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import { Toast } from "lib/components";

// import validate from "./yup";

const MoneyMetaData = (props) => {
  const { inputValue, handleChange, metafield, moneyFormat } = props;

  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999 },
  } = constant;

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT) => {
    if (parseFloat(val) > MIN_PRICE && parseFloat(val) < max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  return (
    <>
      <TextStyle>{`${metafield.name} ${metafield.isRequired ? "*" : ""}`}</TextStyle>
      <Stack distribution="fill">
        <TextField
          value={inputValue[metafield.key] || ""}
          onChange={(value) => handleChange(metafield.key, acceptOnlyValidInput(value), "", "", metafield.contentType)}
          prefix={moneyFormat || ""}
        />
      </Stack>
    </>
  );
};

export default MoneyMetaData;
