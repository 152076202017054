import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  ResourceItem,
  TextStyle,
  FormLayout,
  Stack,
  Select,
  TextField,
  Popover,
  DatePicker,
  Scrollable,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import from lib
import { baseHelper } from "lib/helpers";

import constant from "lib/constant/constant";

function ProductListView(props) {
  const { item, keyIndex, textField, addField, handleDurationChange, handleChange } = props;
  const { currentUser } = useContext(PrivateContext);
  const [activeCalendar, setActiveCalendar] = useState({});
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const isSeller = baseHelper.isSeller(currentUser);
  const {
    productId,
    title,
    price = 0,
    comparePrice = 0,
    markUp,
    quantity = 0,
    isService,
    sellerDiscount,
    vendorDiscount,
    inventoryManagement,
    position = false,
    measurement = {},
    compaign,
    listingQuantity,
  } = item;

  const { price: markUpPrice = 0, type: markUpType = constant.FLAT } = markUp || {};
  const { price: sellerDiscountPrice = 0, type: sellerDiscountType = constant.FLAT } = sellerDiscount || {};
  const { price: vendorDiscountPrice = 0, type: vendorDiscountType = constant.FLAT } = vendorDiscount || {};

  const disableDate = (newDate) => {
    if (newDate.getDate() === new Date().getDate()) {
      newDate.setDate(new Date().getDate() - 1);
    } else {
      newDate.setDate(new Date());
    }
    return newDate;
  };

  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  let { length = {}, width = {}, height = {} } = measurement || {};
  length = (length && length.value) || 0;
  width = (width && width.value) || 0;
  height = (height && height.value) || 0;

  return (
    <ResourceItem id={keyIndex}>
      <div className="bulk-form">
        <FormLayout.Group>
          <TextStyle variation="strong">{title}</TextStyle>
          {addField.indexOf(baseHelper.ucFirst(constant.PRICE)) !== -1 && (
            <>{textField(productId, price, constant.PRICE, isService, false, inventoryManagement, position)}</>
          )}
          {addField.indexOf(baseHelper.ucFirst(constant.QUANTITY)) !== -1 && (
            <>
              {textField(
                productId,
                parseInt(quantity, 10),
                constant.QUANTITY,
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
          {isSeller && addField.indexOf(constant.MARKUPKEY) !== -1 && (
            <>
              {textField(
                productId,
                markUpPrice,
                constant.MARKUP,
                isService,
                markUpType || constant.FLAT,
                inventoryManagement,
                position
              )}
            </>
          )}
          {!isSeller && addField.indexOf(constant.COMPARE_AT_PRICE) !== -1 && (
            <>
              {textField(
                productId,
                comparePrice,
                constant.COMPARE_PRICE,
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
          {addField.indexOf(baseHelper.ucFirst(constant.DISCOUNT)) !== -1 && (
            <>
              {(isSeller &&
                textField(
                  productId,
                  sellerDiscountPrice,
                  constant.SELLER_DISCOUNT,
                  isService,
                  sellerDiscountType,
                  inventoryManagement,
                  position
                )) ||
                textField(
                  productId,
                  vendorDiscountPrice,
                  constant.VENDOR_DISCOUNT,
                  isService,
                  vendorDiscountType,
                  inventoryManagement,
                  position
                )}
            </>
          )}
          {addField.indexOf(baseHelper.ucFirst(constant.LENGTH)) !== -1 && (
            <>
              {textField(
                productId,
                parseInt(length, 10),
                constant.LENGTH,
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
          {addField.indexOf(baseHelper.ucFirst(constant.WIDTH)) !== -1 && (
            <>
              {textField(
                productId,
                parseInt(width, 10),
                constant.WIDTH,
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
          {addField.indexOf(baseHelper.ucFirst(constant.HEIGHT)) !== -1 && (
            <>
              {textField(
                productId,
                parseInt(height, 10),
                constant.HEIGHT,
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
          {addField.indexOf("Campaign") !== -1 && (
            <>
              <Stack wrap={false}>
                <Stack.Item fill>
                  <Popover
                    active={activeCalendar[keyIndex]}
                    activator={
                      <TextField
                        // label={!index ? metafield.name : ""}
                        // value={item}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        value={compaign ? moment(compaign).format("YYYY-MM-DD") : ""}
                        // eslint-disable-next-line no-shadow
                        // onChange={(value) => handelChange(index, value.start)}
                        placeholder="DD/MM/YYYY"
                        onFocus={() => setActiveCalendar({ [keyIndex]: true })}
                      />
                    }
                    onClose={() => setActiveCalendar({})}
                  >
                    <DatePicker
                      month={month}
                      year={year}
                      // eslint-disable-next-line no-shadow
                      onChange={(value) => [handleChange(keyIndex, value.start), setActiveCalendar({})]}
                      onMonthChange={handleMonthChange}
                      selected={selectedDates}
                      allowRange={false}
                      disableDatesBefore={disableDate(new Date())}
                    />
                  </Popover>
                </Stack.Item>
                <Stack.Item fill>
                  <div className="select-time">
                    <Select
                      // label={!index ? "Select time" : ""}
                      id="selectDuration"
                      disabled={!compaign}
                      placeholder="Time"
                      options={baseHelper.getTime() || []}
                      value={compaign ? moment(compaign).format("HH:mm") : ""}
                      onChange={(val) => handleDurationChange(keyIndex, val)}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              {/* {textField(
              productId,
              compaign,
              "compaign",
              isService,
              false,
              inventoryManagement,
              position
            )} */}
            </>
          )}
          {addField.indexOf("Listing Quantity") !== -1 && (
            <>
              {textField(
                productId,
                parseInt(listingQuantity, 10),
                "listingQuantity",
                isService,
                false,
                inventoryManagement,
                position
              )}
            </>
          )}
        </FormLayout.Group>
      </div>
    </ResourceItem>
  );
}

ProductListView.defaultProps = {
  item: {},
  keyIndex: 0,
  textField: () => {},
  addField: [],
};
ProductListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  textField: PropTypes.func,
  addField: PropTypes.arrayOf(PropTypes.string),
};

export default withFeature(withErrorBoundary(ProductListView), { feature: constant.PRODUCT });
