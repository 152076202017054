import * as yup from "yup";
import { yupHelper } from "lib/helpers";

import errorMessage from "./errorMessage";

const { postalCodeRegex, provinceCodeRegex, phoneNumberRegex } = yupHelper;

const firstNameSchema = (label, cms) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required(`${cms("common.message.error.required", { item: label })}`)
      .nullable(),
  });
};
const lastNameSchema = (label, cms) => {
  return yup.object().shape({
    lastName: yup
      .string()
      .required(`${cms("common.message.error.required", { item: label })}`)
      .nullable(),
  });
};

const { emailRegex, handleBrandRegex } = yupHelper;
const emailSchema = (label, cms) => {
  return yup.object({
    email: yup
      .string()
      .required(`${cms("common.message.error.required", { item: label })}`)
      .matches(emailRegex, cms("common.message.error.enterValid", { item: label.toLowerCase() }))
      .nullable(),
  });
};

const phoneNumberSchema = (label, cms) => {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(phoneNumberRegex, errorMessage.positive)
      .min(7, cms("common.message.error.minPhoneNumberChar", { item: label }))
      .max(15, cms("common.message.error.maxPhoneNumberChar", { item: label }))
      .nullable(),
  });
};

const citySchema = (label, cms) => {
  return yup.object().shape({
    city: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};

const countrySchema = yup.object().shape({
  country: yup.string().required(errorMessage.country).nullable(),
});

const postalCodeSchema = (label, cms, schema) => {
  const { min = 2 } = schema || {};
  return yup.object().shape({
    postalCode: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(postalCodeRegex, cms("common.message.error.validPostalCode", { item: label }))
      .min(min, cms("common.message.error.minPostalCodeChar", { min, item: label }))
      .max(32, cms("common.message.error.maxPostalCodeChar", { item: label }))
      .nullable(),
  });
};

const provinceCodeSchema = yup.object().shape({
  provinceCode: yup
    .string()
    .required(errorMessage.provinceCode)
    .matches(provinceCodeRegex, errorMessage.enterValidProvinceCode)
    .nullable(),
});

const streetAddressSchema = (label, cms) => {
  return yup.object().shape({
    streetAddress: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};
const brandNameSchema = (label, cms) => {
  return yup.object().shape({
    brandName: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};

const brandHandleSchema = (label, cms) => {
  return yup.object().shape({
    brandHandle: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(
        handleBrandRegex,
        cms("common.message.error.brandHandle.brandHandleMatch", { item: label.toLowerCase() })
      )
      .nullable(),
  });
};

export {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
};
