import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { OperatorProviderAdd } from "app/vendors/modules/operator";
import { OnboardingProvider, PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const { onboardingSteps } = constant;

const AddProvider = (props) => {
  const { setSelectedTab } = props;
  const { currentUserRefetch = () => {} } = useContext(PrivateContext);
  const [isSelectChange, setIsSelectChange] = useState(false);

  const onNext = () => {
    setSelectedTab(onboardingSteps.FINISH);
  };

  const onPrevious = () => {
    setSelectedTab(onboardingSteps.TERM);
    currentUserRefetch();
  };

  return (
    <OnboardingProvider
      value={{
        isOnboarding: true,
        onNext,
        onPrevious,
        isSelectChange,
        setIsSelectChange,
      }}
    >
      <OperatorProviderAdd />
    </OnboardingProvider>
  );
};

AddProvider.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default AddProvider;
