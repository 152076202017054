import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { BulkCreate } from "app/vendors/modules/generic/feature/add/subFeatures";

const AdminBulkCreate = (props) => {
  const {
    // learnMore
    setBanner,
  } = props;
  // return <BulkCreate setBanner={setBanner} learnMore={learnMore} />;
  return <BulkCreate setBanner={setBanner} />;
};

AdminBulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
  // learnMore: PropTypes.func.isRequired,
};
export default withFeature(AdminBulkCreate, { feature: constant.BULK_CREATE });
