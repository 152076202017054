import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Banner, Button, DropZone as PolarisDropZone, List, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
// import { attchmentHelper } from "lib/helpers"; // TODO
import constant from "lib/constant/constant";

const DropZoneFile = (props) => {
  const {
    allowMultiple = true,
    disabled,
    downloadExistingFile,
    existingFileList = [],
    fileList = [],
    id,
    label,
    loadingPosition = "",
    onAdd,
    onRemove,
    removeExistingFile,
  } = props;
  const [files, setFiles] = useState(fileList);
  const [existingFiles, setExistingFiles] = useState(existingFileList);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [hasError, setHasError] = useState(rejectedFiles.length > 0);

  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/vnd.microsoft.icon",
    "image/webp",
    "image/x-icon",
  ];
  const filePlaceHolder = constant.FILEPLACEHOLDER;
  const allowedImageTypes = ["bmp", "gif", "png", "jpg", "jpeg", "svg%2Bxml", "vnd", "webp"];
  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  useEffect(() => {
    setExistingFiles(existingFileList);
  }, [existingFileList]);

  const handleDrop = useCallback(
    // eslint-disable-next-line no-shadow
    async (_droppedFiles, acceptedFiles, rejectedFiles) => {
      const promiseList = acceptedFiles.map((acceptedFile) => {
        return new Promise((resolve) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(acceptedFile);
          reader.onloadend = () => {
            const result = reader.result || "";
            resolve({ fileBase64: result, fileName: acceptedFile.name });
          };
        });
      });
      const response = await Promise.all(promiseList);
      onAdd(response);
      setFiles([...files, ...response]);
      setRejectedFiles(rejectedFiles);
    },
    [files, onAdd]
  );

  const fileUpload = <PolarisDropZone.FileUpload />;

  const removeFile = (file, buttonIndex) => {
    const updatedFiles = [...files];
    const index = updatedFiles.indexOf(file);
    if (index < 0) {
      return;
    }
    onRemove(index, buttonIndex);
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setHasError(false);
    }
  };

  const isValidSize = (file) => {
    if (file.size >= props.size * 1024 * 1024) {
      return false;
    }
    return true;
  };

  const isTypeAllowed = (file) => {
    return allowedTypes.includes(file.type);
  };

  const isValidFileNameLength = (file) => {
    if (file && file.name && file.name.substr(0, file.name.lastIndexOf(".")).length > 128) {
      return false;
    }
    return true;
  };

  const customValidator = (file) => {
    const isValidFile = isValidSize(file) && isTypeAllowed(file) && isValidFileNameLength(file);
    if (!isValidFile) {
      setHasError(!isValidFile);
    }
    return isValidFile;
  };
  const uploadedFiles = () => {
    if (files.length === 0 && existingFiles.length === 0) {
      return null;
    }
    return (
      <Stack>
        {existingFiles.map((file, index) => {
          const fileUrl = file.fileURL;
          const pos = _.isString(fileUrl) && fileUrl.length && fileUrl.lastIndexOf("/") + 1;
          const extension = fileUrl.substring(pos, fileUrl.length).split(".")[1];
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Stack key={`dropZoneUploadedFile${index}`} alignment="center" vertical>
              <Stack.Item fill>
                <Thumbnail
                  alt={`file${index}`}
                  size="large"
                  source={allowedImageTypes.includes(extension) ? fileUrl : filePlaceHolder}
                />
              </Stack.Item>
              <Stack.Item fill>
                <div className="content-text-wrap attachment-text-wrap">
                  <TextStyle variation="strong">{file.fileName}</TextStyle>
                </div>
              </Stack.Item>
              <Stack.Item>
                <Button
                  loading={index === loadingPosition}
                  disabled={(loadingPosition && index !== loadingPosition) || disabled}
                  onClick={() => removeExistingFile(file, index)}
                >
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
                <Button onClick={() => downloadExistingFile(file)}>
                  <i className="far fa-arrow-down fa-lg" style={{ color: "black" }} />
                </Button>
              </Stack.Item>
            </Stack>
          );
        })}
        {files.map((file, index) => {
          const isImage = file && file.fileBase64.includes(":image/");
          const isFile = file.url || file.fileUrl || file || file.fileBase64;
          return (
            <Stack alignment="center" vertical key="uploadedFiles">
              <Stack.Item fill>
                <Thumbnail
                  size="large"
                  alt={isImage ? "image" : "file"}
                  source={isImage ? file.fileBase64 || constant.NOIMAGESNAP : filePlaceHolder}
                />
              </Stack.Item>
              <Stack.Item fill>
                <div className="content-text-wrap attachment-text-wrap">
                  <TextStyle variation="strong">{isFile && isFile.fileName.toLowerCase().replace(/\s/g, "")}</TextStyle>
                </div>
              </Stack.Item>

              <Stack.Item>
                <Button onClick={() => removeFile(file, existingFiles.length + index)}>
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const errorMessage = rejectedFiles.length && hasError && (
    <Banner title="The following files couldn’t be uploaded:" status="critical" onDismiss={() => setHasError(false)}>
      <List type="bullet">
        {rejectedFiles.map((file) => (
          // eslint-disable-next-line max-len
          <List.Item>{`"${file.name}" is not supported. File type must be .pdf, .doc, .docx, .gif, .jpg, .jpeg, .bmp, .svg, .ico, .webp, file name should be less than 128 character and file size should be less than ${props.size}MB.`}</List.Item>
        ))}
      </List>
    </Banner>
  );

  const isDropZoneShown = (!allowMultiple && files.length === 0) || allowMultiple;

  return (
    <Stack vertical>
      {errorMessage}
      {uploadedFiles()}
      {isDropZoneShown && (
        <PolarisDropZone
          id={id}
          type="file"
          onDrop={handleDrop}
          allowMultiple={allowedTypes}
          customValidator={customValidator}
          disabled={disabled}
          label={label}
        >
          {fileUpload}
        </PolarisDropZone>
      )}
    </Stack>
  );
};

DropZoneFile.propTypes = {
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  existingFileList: PropTypes.arrayOf(PropTypes.any),
  fileList: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  label: PropTypes.string,
  loadingPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  removeExistingFile: PropTypes.func,
  size: PropTypes.number,
  downloadExistingFile: PropTypes.func,
};
DropZoneFile.defaultProps = {
  allowMultiple: false,
  disabled: false,
  existingFileList: [],
  fileList: [],
  id: "dropZone",
  loadingPosition: "",
  label: "",
  onAdd: () => {},
  onRemove: () => {},
  removeExistingFile: () => {},
  size: 1,
  downloadExistingFile: () => {},
};

export default DropZoneFile;
