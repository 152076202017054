import { gql } from "apollo-boost";

const SCHEDULE_PAYMENT_TERM = gql`
  mutation schedulePaymentTerm($input: schedulePaymentTerm!) {
    schedulePaymentTerm(input: $input) {
      status
      error
    }
  }
`;

export default SCHEDULE_PAYMENT_TERM;
