import React, { useContext } from "react";
import { Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import props
import { rowProp } from "app/payments/modules/operator/features/list/propTypes";

function CommissionRevenueRow(props) {
  const { currentUser } = useContext(PrivateContext);
  const { item } = props;
  const { _id: id } = item;
  const { moneyFormat } = currentUser;

  const isVendor = baseHelper.isVendor(currentUser);

  const renderOrderRow = (items) => {
    const {
      commissionSeller,
      name: vendor,
      _id: userId,
      totalPrice,
      // eslint-disable-next-line no-shadow
    } = items;
    let commissionPercentage = (commissionSeller / totalPrice) * 100;
    if (commissionPercentage && commissionPercentage % 1 !== 0) {
      commissionPercentage = baseHelper.getPrice(commissionPercentage);
    }

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <div className="hidden" title={vendor}>
              {"Vendor Name: "}
              {(isVendor && vendor) || <Link url={`/providers/view/${userId}`}>{vendor}</Link>}
            </div>
            <TextStyle>
              {`Operator Commission: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)} (${
                commissionPercentage || 0
              }%)`}
            </TextStyle>
            <br />
            <TextStyle>{`Revenue: ${moneyFormat} ${baseHelper.getPrice(totalPrice)}`}</TextStyle>
            <br />
          </Stack.Item>
        </Stack>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>
    </>
  );
}

CommissionRevenueRow.propTypes = rowProp.type;
export default CommissionRevenueRow;
