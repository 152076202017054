import { gql } from "apollo-boost";

const FETCH_VENDOR_PROFILE_DATA = gql`
  query getVendorProfileData($input: VendorProfileData!) {
    getVendorProfileData(input: $input) {
      data {
        seller {
          _id
        }
        vendorField {
          isBranding
          isPayment
          isShipping
          isSocialLink
          sellerId
          customFields {
            imageUrls
            inputType
            key
            label
            multimediaUrls
            placeholder
            value
          }
        }
        vendorDetails {
          description
          sellerId
          brand {
            imageId
            imageUrl
          }
          brandCover {
            imageId
            imageUrl
          }
          payment {
            account
            bank
            country
            sortCode
          }
          brandName
          shipping {
            address
            city
            country
            pinCode
            isBizAddress
          }
          socialLink {
            facebook
            instagram
            twitter
            youtube
            linkedin
          }
          updatedAt
          customFields {
            label
            value
            placeholder
            multimediaUrls
            imageUrls
            key
            inputType
          }
        }
      }
      status
      error
    }
  }
`;

export default FETCH_VENDOR_PROFILE_DATA;
