import { gql } from "apollo-boost";

const GET_VENDOR_SETTING_LOCATION = gql`
  query {
    getVendorSettingLocation {
      data {
        locations {
          seller
          vendor
        }
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_VENDOR_SETTING_LOCATION;
