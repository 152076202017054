import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  ActionList,
  Button,
  Link,
  Modal,
  Popover,
  RadioButton,
  ResourceList,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { GET_EXPORT_FILTER, CHECK_ORDER_LINES } from "app/orders/apollo/queries";
import OperatorExportReport from "./props";

const OperatorExportReporting = (props) => {
  const { isOpenModal, setIsOpenModal } = props;
  const { cms, history } = useContext(PrivateContext);
  const { loading: getExportFilterLoading, data: getExportFilter } = useQuery(GET_EXPORT_FILTER);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([[], []]);
  const [vendorList, setVendorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [exportAll, setExportAll] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isPrimaryActionLoading, setPrimaryAction] = useState(false);
  const [exportLink, setExportLink] = useState(false);
  const [exportPDFLink, setExportPDFLink] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState("");
  const [queryData, setQueryData] = useState({});
  const dataToFetch = {};
  const exportReportUrl = "/export/report";
  const [
    getCheckOrderList,
    { loading: checkOrderLineLoading, data: checkOrderLineData },
  ] = useLazyQuery(CHECK_ORDER_LINES, { variables: { input: { ...queryData } } });

  useEffect(() => {
    let bannerData = false;
    if (!checkOrderLineLoading && checkOrderLineData && generate) {
      const responseData = baseHelper.getResponseData(checkOrderLineData, constant.gql.CHECK_ORDER_LINES);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkOrderLineData, constant.gql.CHECK_ORDER_LINES) ||
            cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
        };
      }

      if (bannerData) {
        setPrimaryAction(false);
        setBanner({ ...bannerData, isOpen: true });
      } else {
        const csvDownloadRoute = (exportAll && exportReportUrl) || `${exportReportUrl}${params}`;
        const pdfDownloadRoute = (exportAll && `${exportReportUrl}/pdf`) || `${exportReportUrl}/pdf${params}`;
        setPrimaryAction(false);
        setExportLink(csvDownloadRoute);
        setExportPDFLink(pdfDownloadRoute);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOrderLineLoading, checkOrderLineData, exportAll, params, cms]);

  useEffect(() => {
    if (!getExportFilterLoading || getExportFilter) {
      const exportFilterData = baseHelper.getResponseData(getExportFilter, constant.gql.GET_EXPORT_FILTER);
      if (!exportFilterData) {
        const bannerValue = {
          status: constant.CRITICAL,
          title: baseHelper.getResponseError(getExportFilter, constant.gql.GET_EXPORT_FILTER),
        };
        setBanner({ ...bannerValue, isOpen: true });
      }
      const { vendorFilter = [], statusFilter = [], fulfillmentStatuses = [], orderStatuses = [] } = exportFilterData;
      setStatusList(statusFilter);
      setFulfillmentStatus(fulfillmentStatuses);
      setOrderStatus(orderStatuses);
      setVendorList(vendorFilter);
    }
  }, [getExportFilter, getExportFilterLoading]);

  const exportOrder = {
    label: {
      resourceName: [
        { singular: cms("common.label.vendor"), plural: cms("section.report.vendorPlural") },
        { singular: cms("common.label.status"), plural: cms("section.report.statusPlural") },
      ],
    },
    exportModalTabs: [
      {
        id: constant.VENDORS,
        content: cms("common.label.vendor"),
        panelID: "panel-vendors",
      },
      {
        id: constant.STATUS,
        content: cms("common.label.status"),
        panelID: "panel-status",
      },
    ],
  };

  const onExport = (isSpecificExport = false) => {
    setLoading(true);
    setGenerate(true);
    setPrimaryAction(true);
    let bannerData = false;
    let status = [];
    let vendor = [];
    let fulfillmentStatusNew = [];
    let orderStatusNew = [];

    vendor = [...selectedOptions[0]];
    status = [...selectedOptions[1]];
    fulfillmentStatusNew = status.filter((item) => item && fulfillmentStatus.includes(item));
    orderStatusNew = status.filter((item) => item && orderStatus.includes(item));
    const fulfillmentString = fulfillmentStatusNew.join(",");
    const orderString = orderStatusNew.join(",");
    const vendorString = vendor.join(",");
    if (!(exportAll || vendorString || fulfillmentString || orderString)) {
      setLoading(false);
      bannerData = {
        title: cms("common.message.error.pleaseSelect"),
        status: constant.CRITICAL,
      };
      setPrimaryAction(false);
      setBanner({ ...bannerData, isOpen: true });
      return;
    }
    if (isSpecificExport) {
      const updatedSelectedOptions = baseHelper.cloneData(selectedOptions);
      if (!selectedTabIndex) {
        setParams(`?vendor=${vendorString}`);
        updatedSelectedOptions[1] = [];
        orderStatusNew = [];
        fulfillmentStatusNew = [];
      } else {
        setParams(`?status=${orderString}&fulfillmentStatus=${fulfillmentString}`);
        updatedSelectedOptions[0] = [];
        vendor = [];
      }
      setSelectedOptions(updatedSelectedOptions);
    }
    setParams(`?vendor=${vendorString}&status=${orderString}&fulfillmentStatus=${fulfillmentString}`);
    if (orderStatusNew.length > 0) {
      dataToFetch.status = orderStatusNew;
    }
    if (fulfillmentStatusNew.length > 0) {
      dataToFetch.fulfillmentStatus = fulfillmentStatusNew;
    }
    if (vendor.length > 0) {
      dataToFetch.vendor = vendor;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
    setExportLink(false);
    setExportPDFLink(false);
  };

  const toggleBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(exportOrder.exportModalTabs);
    const singularItem = cms("section.exportOrder.label.item");
    const pluralItem = cms("section.exportOrder.label.items");
    const changeIndex = 0;
    selectedOptions.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${cms("section.exportOrder.label.Selected")})`;
      }
    });
    return exportModalTabs;
  };
  const closeModal = () => {
    setSelectedTabIndex(0);
    setSelectedOptions([[], []]);
    setExportAll(true);
    setPrimaryAction(false);
    setExportLink(false);
    setExportPDFLink(false);
    setIsOpenModal(false);
    setLoading(false);
    toggleBanner();
    setGenerate(false);
  };

  const downloadCSV = () => {
    window.open(exportLink);
    closeModal();
  };

  const downloadPDF = () => {
    window.open(exportPDFLink);
    closeModal();
  };

  const handleSelectionChange = (selectedItems) => {
    const prevSelectedOptions = [...selectedOptions];
    prevSelectedOptions[selectedTabIndex] = [...selectedItems];
    setSelectedOptions(prevSelectedOptions);
    setExportLink(false);
    setExportPDFLink(false);
    setLoading(false);
  };

  const handleTabChange = (selectedTabInde) => {
    setSelectedTabIndex(selectedTabInde);
    setExportLink(false);
    setExportPDFLink(false);
  };

  const items = [vendorList, statusList];
  const updateResourceName = [...exportOrder.label.resourceName];

  const promotedBulkActions = [
    {
      content: cms("section.report.generate"),
      onAction: () => onExport(true),
    },
  ];
  const [active, setActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const handleChange = () => {
    setActive(!active);
    setPopoverActive(!popoverActive);
  };

  const activator = (
    <Button onClick={handleChange} disclosure>
      {cms("section.report.selectFormat")}
    </Button>
  );
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);

  const handlePopover = () => {
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList
          items={[
            {
              content: cms("section.report.exportCSV"),
              onAction: () => {
                setLoading(false);
                downloadCSV();
              },
            },
            {
              content: cms("section.report.exportPDF"),
              onAction: () => {
                setLoading(false);
                downloadPDF();
              },
            },
          ]}
        />
      </Popover>
    );
  };

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Link onClick={() => history.push("/setting/report")}>{cms("section.report.customize")}</Link>
      </Stack.Item>
      <Stack.Item>
        <Button onClick={() => closeModal()}>{cms("common.button.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        {exportLink ? (
          handlePopover()
        ) : (
          <Button primary onClick={() => onExport()} loading={loading}>
            {cms("section.report.generate")}
          </Button>
        )}
      </Stack.Item>
    </Stack>
  );
  return (
    <>
      <Modal open={isOpenModal} onClose={() => closeModal()} title={cms("section.report.report")} footer={actions()}>
        <Modal.Section>
          {banner.isOpen && (
            <Banner
              key="banner"
              onDismiss={toggleBanner}
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
            />
          )}
          <Stack vertical>
            <RadioButton
              label={cms("section.report.exportAll")}
              helpText={cms("section.report.exportAllText")}
              checked={exportAll}
              name="allOrders"
              onChange={() => {
                setGenerate(false);
                setBanner({ isOpen: false });
                setExportAll((prevState) => !prevState);
                setExportPDFLink(false);
                setExportLink(false);
              }}
            />
            <RadioButton
              label={cms("section.report.exportSelected")}
              helpText={cms("section.report.exportSelectedText")}
              name="selectedOrders"
              checked={!exportAll}
              onChange={() => {
                setGenerate(false);
                setExportAll((prevState) => !prevState);
                setExportPDFLink(false);
                setBanner({ isOpen: false });
                setExportLink(false);
              }}
            />
          </Stack>
          {!exportAll && (
            <>
              <Tabs
                key="tabs"
                tabs={getResourceListTabs()}
                selected={selectedTabIndex}
                onSelect={handleTabChange}
                fitted
              >
                <ResourceList
                  key="resourceList"
                  resourceName={updateResourceName[selectedTabIndex]}
                  items={items[selectedTabIndex]}
                  renderItem={(item) => {
                    const { id, name, location } = item;
                    return (
                      <ResourceList.Item id={id} accessibilityLabel={`${cms("section.export.label.view")} ${id}`}>
                        <h3>
                          <TextStyle variation="strong">{name}</TextStyle>
                        </h3>
                        <div>{location}</div>
                      </ResourceList.Item>
                    );
                  }}
                  selectedItems={selectedOptions[selectedTabIndex]}
                  onSelectionChange={handleSelectionChange}
                  selectable
                  promotedBulkActions={promotedBulkActions}
                  loading={getExportFilterLoading}
                />
              </Tabs>
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

OperatorExportReport.propTypes = OperatorExportReport.type;

export default withErrorBoundary(OperatorExportReporting);
