import { gql } from "apollo-boost";

const VIEW_DASHBOARD = gql`
  mutation viewDashboard {
    viewDashboard {
      data
      status
      error
    }
  }
`;
export default VIEW_DASHBOARD;
