import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Caption, Frame, TextStyle, SettingToggle } from "@shopify/polaris";
import { Banner, Toast } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { SET_ORDER_COMMISSION } from "app/setup/apollo/mutations";

const OrderBased = (props) => {
  const { cms = {} } = useContext(PrivateContext);
  const [message, setMessage] = useState("");

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [isOrderBased, setIsOrderBased] = useState(false);
  let contentStatus = isOrderBased ? constant.displayStatus.DISABLE : constant.ENABLE;

  const { isOrderBasedCommission, setIsOrderBasedCommission, updatedAt } = props;
  const {
    gql: { SET_ORDER_COMMISSION: UPDATE_COMMISSION_TEXT },
  } = constant;

  // gql state
  const [setOrderCommission, { loading }] = useMutation(SET_ORDER_COMMISSION);

  const updateAdvanceCommission = async (requestData) => {
    let bannerContent = {};
    try {
      const res = await setOrderCommission({
        variables: { input: { isOrderBasedCommission: requestData.isOrderBased } },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);

      contentStatus = isOrderBased ? constant.displayStatus.DISABLE : constant.ENABLE;

      if (isOrderBased) {
        setMessage(cms("message.successDisabled"));
      } else {
        setMessage(cms("message.successEnabled"));
      }

      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        bannerContent = { isOpen: true, status: "critical", title: errorResponse };
        setBanner(bannerContent);
      }
    } catch (exception) {
      bannerContent = { isOpen: true, status: "critical", title: errorHelper.parse(exception) };
      setBanner(bannerContent);
    }
  };

  const handleToggle = () => {
    setIsOrderBased(!isOrderBased);
    setIsOrderBasedCommission(!isOrderBased);
    const requestData = {
      isOrderBased: !isOrderBased,
    };
    updateAdvanceCommission(requestData);
  };

  const dismissBanner = () => {
    setBanner({ isOpen: false });
  };

  useEffect(() => {
    if (!isOrderBasedCommission) {
      return;
    }
    setIsOrderBased(isOrderBasedCommission);
  }, [isOrderBasedCommission]);

  return (
    <>
      <div className="toast">
        <Frame>
          <Toast message={message} />
        </Frame>
      </div>
      <div className="toggle-action">
        <SettingToggle
          action={{
            content: contentStatus,
            onAction: handleToggle,
            destructive: isOrderBased,
            loading,
          }}
          enabled={isOrderBased}
        >
          <TextStyle variation="strong">{cms("section.commissionByOrder.title")}</TextStyle>
          <TextStyle variation="subdued">
            <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
          </TextStyle>
          <br />
          {banner.isOpen && (
            <>
              <br />
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                isScrollTop={false}
                onDismiss={() => dismissBanner()}
              >
                {banner.message}
              </Banner>
              <br />
            </>
          )}
          <TextStyle>{cms("section.commissionByOrder.content.description")}</TextStyle>
        </SettingToggle>
      </div>
    </>
  );
};

export default OrderBased;
