import constant from "lib/constant/constant";

const { onboardingSteps } = constant;

const { WELCOME, UPDATE_PROFILE, PENDING_APPROVAL, FINISH } = onboardingSteps;

const inReviewTab = [
  {
    id: WELCOME,
    content: "Welcome",
    accessibilityLabel: "welcomeToMarketcube",
    panelID: "welcomeContent",
    tabIndex: 0,
  },
  {
    id: UPDATE_PROFILE,
    content: "Step 1",
    panelID: "updateProfileContent",
    tabIndex: 1,
  },
  {
    id: PENDING_APPROVAL,
    content: "Step 2",
    panelID: "pendingApprovalContent",
    tabIndex: 2,
  },
  {
    id: FINISH,
    content: "Finish Setup",
    panelID: "finishContent",
    tabIndex: 3,
  },
];

const approveTab = [
  {
    id: WELCOME,
    content: "Welcome",
    accessibilityLabel: "welcome-to-marketcube",
    panelID: "welcomeContent",
    tabIndex: 0,
  },
  {
    id: UPDATE_PROFILE,
    content: "Step 1",
    panelID: "updateProfileContent",
    tabIndex: 1,
  },
  {
    id: FINISH,
    content: "Finish Setup",
    panelID: "finishContent",
    tabIndex: 2,
  },
];

export { approveTab, inReviewTab };
