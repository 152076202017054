import constant from "lib/constant/constant";

const editProfileConfig = (cms) => {
  const { EMAIL, FIRST_NAME, LAST_NAME, PHONE_NUMBER } = constant;

  const textFields = [
    {
      label: `${cms("common.label.firstName")}*`,
      placeholder: cms("common.placeholder.firstName"),
      key: FIRST_NAME,
    },
    {
      label: `${cms("common.label.lastName")}*`,
      placeholder: cms("common.placeholder.lastName"),
      key: LAST_NAME,
    },
    {
      label: `${cms("common.label.email")}*`,
      placeholder: cms("common.placeholder.emailAddress"),
      key: EMAIL,
    },
    {
      label: `${cms("common.label.phone")}*`,
      placeholder: cms("common.placeholder.phoneNumber"),
      key: PHONE_NUMBER,
    },
  ];
  return textFields;
};

export default editProfileConfig;
