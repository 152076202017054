// import packages
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Card, FormLayout, Layout, PageActions, TextField } from "@shopify/polaris";
import { ViewMinor, HideMinor } from "@shopify/polaris-icons";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import helpers components
import constant from "lib/constant/constant";
import { Footer } from "layout/public/components";
import { Banner } from "lib/components";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";

// import context
import { PublicContext } from "lib/context";

// import mutation
import { LOGIN, LOGIN_MOBILE, GENERATE_OTP } from "app/public/apollo/mutations";

// import yup validation
import validate from "./yup";

const Login = () => {
  const { history, location, cms } = useContext(PublicContext);
  const { loginVia = "email" } = location.state || {};
  const [loginUser, { loading }] = useMutation(LOGIN);
  const [loginMobileUser, { mobileLoading }] = useMutation(LOGIN_MOBILE);
  const [generateOtp, { generateLoading }] = useMutation(GENERATE_OTP);

  const { CRITICAL } = constant;
  const [values, setValues] = useState({ email: "", password: "", phoneNumber: "", otp: "" });
  const [errorMessage, setErrorMessage] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [submitButton, setSubmitState] = useState(false);
  const [isOtpSend, setOtpSend] = useState(false);
  const [visible, setVisible] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const acceptOnlyValidInput = (value) => {
    return baseHelper.acceptOnlyNumber(value);
  };
  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePressEnterKey = () => {
    const inputPassword = document.querySelector("#password");
    const loginButton = document.querySelector("#primaryActionLogin");
    inputPassword.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        loginButton.click();
      }
    });
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from.state &&
      location.state.from.state.isUserNotFound
    ) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.notFound"),
      });
    }
  }, [cms, location, CRITICAL]);

  useEffect(() => {
    // handlePressEnterKey();
  }, []);

  useEffect(() => {
    const isAnyValidationError =
      errorMessage && !!(errorMessage.email || errorMessage.password || errorMessage.phoneNumber || errorMessage.otp);
    const isAllValuesFilled =
      // eslint-disable-next-line no-nested-ternary
      loginVia === "email"
        ? values.email && values.password
        : !isOtpSend
        ? values.phoneNumber
        : values.phoneNumber && values.otp;
    setSubmitState(isAllValuesFilled && !isAnyValidationError);
  }, [values, errorMessage]);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const onSubmit = async () => {
    if (loginVia === "email") {
      await handleValidation("email", values.email);
      await handleValidation("password", values.password);
    }
    if (loginVia === "phone") {
      await handleValidation("phoneNumber", values.phoneNumber);
    }

    if (isOtpSend) {
      await handleValidation("phoneNumber", values.phoneNumber);
      await handleValidation("otp", values.otp);
    }
    if (!submitButton) {
      return;
    }

    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.internet"),
      });
      return;
    }

    if (loginVia === "email") {
      loginUser({ variables: { input: { email: values.email, password: values.password } } })
        .then((response) => {
          const loginResponse = baseHelper.getResponseData(response.data, "login");
          const loginError = baseHelper.getResponseError(response.data, "login");
          if (loginError) {
            setBanner({
              isOpen: true,
              status: CRITICAL,
              title: loginError,
            });
          } else if (loginResponse) {
            const buttonsToRemove = [
              "buttonCSS",
              "headerColor",
              "loginText",
              "logoUrl",
              "register",
              "brandName",
              "headerFont",
              "hideMcDetails",
            ];
            buttonsToRemove.forEach((element) => {
              storageHelper.remove(element);
            });
            if (loginResponse.token) {
              storageHelper.set("token", loginResponse.token);
              storageHelper.set("isInitial", true);
              storageHelper.set("email", values.email);
              history.push("/");
            }

            if (loginResponse.isOtp) {
              storageHelper.set("email", values.email);
              storageHelper.set("password", values.password);
              history.push("/otp");
            }
          }
        })
        .catch(() => {
          setBanner({
            isOpen: true,
            status: CRITICAL,
            title: cms("message.error.unknown"),
          });
        });
    }

    if (loginVia === "phone" && !isOtpSend) {
      generateOtp({ variables: { input: { mobileNumber: values.phoneNumber, isLogin: true } } })
        .then((response) => {
          const generateOtpResponse = baseHelper.getResponseData(response.data, "generateOtp");
          const generateOtpRError = baseHelper.getResponseError(response.data, "generateOtp");
          if (generateOtpRError) {
            setBanner({
              isOpen: true,
              status: CRITICAL,
              title: generateOtpRError,
            });
          } else if (generateOtpResponse) {
            setSubmitState(false);
            setOtpSend(true);
            setTimeout(() => {
              setResendOtp(!isOtpSend);
            }, 15000);
          }
        })
        .catch(() => {
          setBanner({
            isOpen: true,
            status: CRITICAL,
            title: cms("message.error.unknown"),
          });
        });
    }

    if (loginVia === "phone" && isOtpSend) {
      loginMobileUser({ variables: { input: { mobileNumber: values.phoneNumber, otp: values.otp } } })
        .then((response) => {
          const loginResponse = baseHelper.getResponseData(response.data, "loginMobile");
          const loginError = baseHelper.getResponseError(response.data, "loginMobile");
          if (loginError) {
            setBanner({
              isOpen: true,
              status: CRITICAL,
              title: loginError,
            });
          } else if (loginResponse) {
            const buttonsToRemove = [
              "buttonCSS",
              "headerColor",
              "loginText",
              "logoUrl",
              "register",
              "brandName",
              "headerFont",
              "hideMcDetails",
            ];
            buttonsToRemove.forEach((element) => {
              storageHelper.remove(element);
            });

            if (loginResponse.token) {
              storageHelper.set("token", loginResponse.token);
              storageHelper.set("isInitial", true);
              storageHelper.set("phoneNumber", values.phoneNumber);
              history.push("/");
            }

            if (loginResponse.isOtp) {
              storageHelper.set("phoneNumber", values.phoneNumber);
              history.push("/otp");
            }
          }
        })
        .catch(() => {
          setBanner({
            isOpen: true,
            status: CRITICAL,
            title: cms("message.error.unknown"),
          });
        });
    }
  };

  const loginTitle = storageHelper.get("loginText");

  const [secondaryAction1, secondaryAction2] = cms("button.secondary");

  const renderViewIcon = () => (
    <Button id="eyeButton" plain icon={visible ? HideMinor : ViewMinor} onClick={() => setVisible(!visible)} />
  );

  const onResendOtp = async () => {
    if (loginVia === "phone") {
      await handleValidation("phoneNumber", values.phoneNumber);
    }
    if (resendOtp) {
      generateOtp({ variables: { input: { mobileNumber: values.phoneNumber, isLogin: true } } })
        .then((response) => {
          const generateOtpResponse = baseHelper.getResponseData(response.data, "generateOtp");
          const generateOtpRError = baseHelper.getResponseError(response.data, "generateOtp");
          if (generateOtpRError) {
            setBanner({
              isOpen: true,
              status: CRITICAL,
              title: generateOtpRError,
            });
          } else if (generateOtpResponse) {
            const isAnyValidationError = errorMessage.phoneNumber;
            const isAllValuesFilled = values.phoneNumber;
            setResendOtp(isAllValuesFilled && !isAnyValidationError && !isOtpSend);
          }
        })
        .then(() => {
          setTimeout(() => {
            setResendOtp(true);
          }, 60000);
        })
        .catch(() => {
          setBanner({
            isOpen: true,
            status: CRITICAL,
            title: cms("message.error.unknown"),
          });
        });
    }
  };

  const secondaryActionValue =
    // eslint-disable-next-line no-nested-ternary
    loginVia !== "phone"
      ? [
          {
            id: "resetPassword",
            content: secondaryAction1,
            plain: true,
            onAction: () => history.push("/reset-password"),
          },
          {
            id: "resendVerifyEmail",
            content: secondaryAction2,
            plain: true,
            onAction: () => history.push("/resend-verify-email"),
          },
        ]
      : isOtpSend
      ? [
          {
            id: "registerHere",
            content: "Resend OTP",
            disabled: !resendOtp,
            plain: true,
            onAction: () => {
              onResendOtp();
            },
          },
        ]
      : [];

  return (
    <>
      <Banner
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
      <br />
      <Layout>
        <Layout.AnnotatedSection
          title={(loginTitle && loginTitle !== "" && loginTitle) || cms("title")}
          description={cms("description")}
        >
          {loginVia === "email" && (
            <Card sectioned>
              <FormLayout>
                <TextField
                  id="email"
                  value={values.email || ""}
                  onChange={(value) => handleChange("email", value)}
                  onBlur={() => handleValidation("email", values.email)}
                  label={`${cms("common.label.email")}*`}
                  name="email"
                  placeholder={cms("common.placeholder.emailAddress")}
                  error={errorMessage && errorMessage.email}
                />
                <TextField
                  id="password"
                  value={values.password || ""}
                  onChange={(value) => handleChange("password", value)}
                  onBlur={() => handleValidation("password", values.password)}
                  label={`${cms("common.label.password")}*`}
                  type={visible ? "text" : "password"}
                  name="password"
                  placeholder={cms("common.placeholder.password")}
                  error={errorMessage && errorMessage.password}
                  suffix={renderViewIcon()}
                />
              </FormLayout>
            </Card>
          )}
          {loginVia === "phone" && (
            <Card sectioned>
              <FormLayout>
                <TextField
                  id="phoneNumber"
                  value={values.phoneNumber || ""}
                  onChange={(value) => handleChange("phoneNumber", acceptOnlyValidInput(value))}
                  onBlur={() => handleValidation("phoneNumber", values.phoneNumber)}
                  label={`${cms("common.label.phone")}*`}
                  name="phoneNumber"
                  placeholder={cms("common.placeholder.phoneNumber")}
                  error={errorMessage && errorMessage.phoneNumber}
                />
                {isOtpSend && (
                  <TextField
                    id="otp"
                    value={values.otp || ""}
                    onChange={(value) => handleChange("otp", acceptOnlyValidInput(value))}
                    onBlur={() => handleValidation("otp", values.otp)}
                    label={`${cms("common.label.otp")}*`}
                    type={visible ? "text" : "password"}
                    name="otp"
                    placeholder={cms("common.placeholder.otp")}
                    error={errorMessage && errorMessage.otp}
                    suffix={renderViewIcon()}
                  />
                )}
              </FormLayout>
            </Card>
          )}
          <PageActions
            primaryAction={{
              id:
                // eslint-disable-next-line no-nested-ternary
                loginVia === "phone"
                  ? !submitButton
                    ? "prelogin_button_disabled"
                    : "prelogin_button"
                  : "primaryActionLogin",
              content: loginVia === "phone" && !isOtpSend ? "Generate OTP" : cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton,
              loading: loading || mobileLoading || generateLoading,
            }}
            secondaryActions={secondaryActionValue}
          />
        </Layout.AnnotatedSection>
      </Layout>
      <Footer loginVia={loginVia} />
    </>
  );
};

export default withErrorBoundary(Login);
