import React from "react";
import constant from "lib/constant/constant";
import Information from "app/product/modules/provider/features/add/subFeatures/form/subFeatureItem/information";
import ServiceInformation from "app/product/modules/provider/features/add/subFeatures/form/service/information";
import { baseHelper } from "lib/helpers";

const addData = (cms, isOnboarding, userId, isMetafield = false, isProductDiscovery = false) => {
  const options = [
    {
      label: cms("label.connect"),
      value: constant.WOOCOMMERCE,
    },
    isProductDiscovery && {
      label: "Import products by connecting to a Magento store",
      value: constant.MAGENTO,
    },
    isProductDiscovery && {
      label: "Import products by connecting to a Presta Shop store",
      value: constant.PRESTASHOP,
    },
    {
      label: cms("label.store"),
      value: constant.SHOPIFY,
    },
    {
      label: cms("section.connect.label.wix"),
      value: constant.WIX,
    },
    {
      label: cms("label.form"),
      value: constant.CREATE_FORM,
    },
  ];

  const filterTabs = [
    {
      id: "information",
      content: cms("label.information"),
      panelID: "information-content",
      PanelComponent: (selectedTab) => <Information selectedTab={selectedTab} />,
    },
    {
      id: "inventory",
      content: cms("label.inventory"),
      panelID: "inventory-content",
    },
    {
      id: "image",
      content: cms("label.image"),
      panelID: "image-content",
    },
    {
      id: "variant",
      content: cms("label.variant"),
      panelID: "variant-content",
    },
    {
      id: "fulfillment",
      content: cms("label.fulfillment"),
      panelID: "fulfillment-content",
    },
    // {
    //   id: "metafield",
    //   content: cms("label.metafieldPlural"),
    //   panelID: "metafield-content",
    // },
    // {
    //   id: "attachment",
    //   content: cms("label.attachment"),
    //   panelID: "attachment-content",
    // },
  ];

  if (isMetafield) {
    filterTabs.push({
      id: "metafield",
      content: cms("label.metafieldPlural"),
      panelID: "metafield-content",
    });
  }

  if (baseHelper.isPAASEnabled(userId)) {
    options.push({
      label: cms("service.label.service"),
      value: constant.SERVICE,
    });
  }

  options.push({
    label: cms("label.csv"),
    value: constant.CREATE_CSV,
    disabled: !!isOnboarding,
  });

  return {
    options: options.filter(item => item),
    tabs: filterTabs,
    serviceTabs: [
      {
        id: "information",
        content: cms("label.information"),
        panelID: "information-content",
        PanelComponent: (selectedTab) => <ServiceInformation selectedTab={selectedTab} />,
      },
      {
        id: "service",
        content: cms("service.label.availability"),
        panelID: "service-content",
      },
      {
        id: "image",
        content: cms("label.image"),
        panelID: "image-content",
      },
      {
        id: "variant",
        content: cms("label.variant"),
        panelID: "variant-content",
      },
    ],
  };
};

export default addData;

export const getPanelComponent = (selectedTab, cms) => {
  return addData(cms).tabs[selectedTab].PanelComponent(selectedTab);
};

export const getServicePanelComponent = ({ selectedTab, cms, tabs = "serviceTabs" }) => {
  return addData(cms)[tabs][selectedTab].PanelComponent(selectedTab);
};
