const helpAction = {
  id: "helpPrimaryAction",
  link: "https://support.marketcube.io",
  external: true,
};

const privatePageContent = {
  activity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "activity",
    title: "activity",
  },
  addPayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "connectStripePayPal",
  },
  addProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "addProduct",
  },
  // addProductV1: {
  //   breadcrumbs: [{ url: "/products" }],
  //   title: "addProduct",
  // },
  // editProductV1: {
  //   breadcrumbs: [{ url: "/products" }],
  //   title: "editProduct",
  // },
  addLakeVariant: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "addVariant",
  },
  bigcommerceProduct: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "bigcommerceProduct",
  },
  analytics: {
    breadcrumbs: [{ url: "/analytics" }],
    title: "analytics",
  },  
  editLakeProduct: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "editProduct",
  },
  productLake: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "addProduct",
  },
  editProductLake: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "editProduct",
  },
  address: {
    breadcrumbs: [{ url: "/setting" }],
    title: "address",
  },
  carrier: {
    breadcrumbs: [{ url: "/setting" }],
    title: "carrierSetting",
  },
  addVariant: {
    breadcrumbs: [{ url: "/" }],
    title: "addVariant",
  },
  // addVariantV1: {
  //   breadcrumbs: [{ url: "/" }],
  //   title: "addVariant",
  // },
  addVendor: {
    breadcrumbs: [{ url: "/providers" }],
    title: "addVendor",
  },
  addPlan: {
    breadcrumbs: [{ url: "/setting" }],
    title: "addPlan",
  },
  analytics: {
    breadcrumbs: [{ url: "/analytics" }],
    title: "analytics",
  },
  editPlan: {
    breadcrumbs: [{ url: "/setting" }],
    title: "editPlan",
  },
  association: {
    breadcrumbs: [{ url: "/" }],
    title: "createAssociation",
  },
  advanceShipping: {
    breadcrumbs: [{ url: "/setting" }],
    title: "advanceShipping",
  },
  bulkActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "bulkActivity",
    title: "bulkActivity ",
  },
  bulkEditActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "bulkEditActivity",
    title: "bulkEditActivity",
  },
  updateApp: {
    breadcrumbs: [{ url: "/" }],
    title: "updateApp",
  },
  bulkProductEdit: {
    breadcrumbs: [{ url: "/products" }],
    primaryAction: {
      content: "Add Product",
      id: "addProductPrimaryAction",
      url: "/products/add",
    },
    title: "bulkProductEdit",
  },
  changePassword: {
    breadcrumbs: [{ url: "/" }],
    title: "changePassword",
  },
  commission: {
    breadcrumbs: [{ url: "/setting" }],
    title: "commission",
  },
  connectPayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "connectStripePayPal",
  },
  connectShop: {
    breadcrumbs: [{ url: "/products" }],
    title: "connectShop",
  },
  contactUs: {
    breadcrumbs: [{ url: "/" }],
    title: "contactUs",
  },
  creditNoteList: {
    breadcrumbs: [{ url: "/payments" }],
    title: "creditNoteList",
  },
  currencyPayoutSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "currencyPayout",
  },
  currencyRateSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "currencyRate",
  },
  customizeFont: {
    breadcrumbs: [{ url: "/customize/fonts" }],
    title: "customizeFont",
  },
  vendor: {
    // breadcrumbs: [{ url: "/setting" }],
    title: "chat",
  },
  customizeEmailTemplate: { breadcrumbs: [{ url: "/email-template" }], title: "editEmailTemplate" },
  dashboard: {
    primaryAction: helpAction,
  },
  discountSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "discountSetting",
  },
  creditNoteSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "creditNote",
  },
  domain: {
    breadcrumbs: [{ url: "/" }],
    title: "editCustomDomain",
  },
  downloadFile: {},
  dynamicDiscount: {
    breadcrumbs: [{ url: "/setting" }],
    title: "dynamicDiscount",
  },
  editProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "editProduct",
  },
  editProducts: {
    breadcrumbs: [{ url: "/products" }],
    title: "editProducts",
  },
  editProfile: {
    breadcrumbs: [{ url: "/setting" }],
    title: "editProfile",
  },
  chatSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "chatSetting",
  },
  // editVariant: {
  //   breadcrumbs: [{ url: "/products" }],
  //   title: "editVariant",
  // },
  editVariant: {
    breadcrumbs: [{ url: "/products" }],
    title: "editVariant",
  },
  editLakeVariant: {
    breadcrumbs: [{ url: "/product-lake" }],
    title: "editVariant",
  },
  emailTemplate: {
    breadcrumbs: [{ url: "/setting" }],
    title: "emailTemplates",
  },
  feature: {
    breadcrumbs: [{ url: "/" }],
    title: "feature",
  },
  fulfillment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "fulfillment",
  },
  invitation: {
    pageClassName: "invitationList",
    title: "invitation",
    primaryAction: helpAction,
  },
  invoice: {
    breadcrumbs: [{ url: "/" }],
    title: "invoice",
  },
  invoiceSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "invoice",
  },
  transactionFeesSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "transactionFees",
  },
  listSeller: {
    breadcrumbs: [{ url: "/" }],
    title: "operator",
  },
  location: {
    breadcrumbs: [{ url: "/setting" }],
    title: "locationMapping",
  },
  markupSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "markupSetting",
  },
  location: {
    breadcrumbs: [{ url: "/setting" }],
    title: "locationMapping",
  },
  maintenance: {
    breadcrumbs: [{ url: "/" }],
    title: "editMaintenance",
  },
  manageCustomer: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageCustomer",
  },
  manageFulfillment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageFulfillmentDetail",
  },
  manageFulfillmentTracking: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageFulfillmentTrackingDetails",
  },
  managePlans: {
    breadcrumbs: [{ url: "/setting" }],
    pageClassName: "managePlans",
    title: "managePlans",
  },
  muteNotification: {
    breadcrumbs: [{ url: "/setting" }],
    title: "muteNotification",
  },
  operator: {
    title: "operator",
  },
  onboardActivity: {
    breadcrumbs: [{ url: "/setting" }],
    title: "onboardHistory",
  },
  orderACSSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "orderACSSetting",
  },
  orderExport: {},
  orders: {
    pageClassName: "ordersList",
    title: "order",
    primaryAction: helpAction,
  },
  orderLake: {
    title: "order",
    primaryAction: helpAction,
  },
  orderSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "orderSetting",
  },
  orderSlip: {},
  manageGeolocation: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageGeolocation",
  },
  payments: {
    title: "payment",
    pageClassName: "payment",
    primaryAction: helpAction,
  },

  paymentsLakeList: {
    title: "payment",
    pageClassName: "payment",
    primaryAction: helpAction,
  },
  payouts: {
    breadcrumbs: [{ url: "/payments" }],
    pageClassName: "automaticPayoutsList",
    title: "automaticPayout",
  },
  plan: {
    title: "plan",
  },
  managePlans: {
    breadcrumbs: [{ url: "/setting" }],
    pageClassName: "managePlans",
    title: "managePlans",
  },
  products: {
    title: "product",
    primaryAction: helpAction,
  },
  lakeProductList: {
    title: "product",
    primaryAction: helpAction,
  },
  productFormLabel: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productFormLabel",
  },
  productExportSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productExport",
  },
  productPriceSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "wholesalePriceSetting",
  },
  productTagSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productTagSetting",
  },
  attachment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productAttachment",
  },
  productSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productSetting",
  },
  productListingSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productListingSetting",
  },
  productApprovalSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productApprovalSetting",
  },
  reportSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "consolidatedReport",
  },
  product: {
    breadcrumbs: [{ url: "/products" }],
    title: "reviewProduct",
  },
  previewPlan: {
    breadcrumbs: [{ url: "/setting/plan/list"}],
    title: "previewPlan",
  },
  schedulePayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "schedulePayments",
  },
  session: {
    breadcrumbs: [{ url: "/" }],
    title: "userSession",
  },
  settings: {
    title: "setting",
    primaryAction: helpAction,
  },
  shipping: {
    breadcrumbs: [{ url: "/setting" }],
    pageClassName: "manageShipping",
    title: "shipping",
  },
  shopifyCallback: {
    breadcrumbs: [{ url: "/" }],
    title: "",
  },
  shopifyProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "shopifyProduct",
  },
  term: {
    breadcrumbs: [{ url: "/setting" }],
    title: "term",
  },
  tax: {
    breadcrumbs: [{ url: "/setting" }],
    title: "taxSetting",
  },
  updatePlan: {
    breadcrumbs: [{ url: "/" }],
    title: "updatePlan",
  },
  userDatabaseSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "userDatabaseSetting",
  },
  vendorExport: {
    breadcrumbs: [{ url: "/setting" }],
    title: "vendorExport",
  },
  vendors: {
    pageClassName: "providersList",
    title: "vendor",
    primaryAction: helpAction,
  },
  vendorExport: {
    breadcrumbs: [{ url: "/setting" }],
    title: "vendorExport",
  },
  vendorSubscription: {
    breadcrumbs: [{ url: "/setting" }],
    title: "subscription",
  },
  advanceVendorProfile: {
    breadcrumbs: [{ url: "/providers" }],
    title: "advanceVendor",
  },
  viewActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "viewActivity",
    title: "viewBulkActivity",
  },
  viewBulkEditActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "viewBulkEditActivity",
    title: "viewBulkEditActivity",
  },
  viewProduct: {
    title: "editProduct",
  },
  viewVendor: { breadcrumbs: [{ url: "/providers" }], title: "vendorProfile" },
  viewOrder: {
    breadcrumbs: [{ url: "/orders" }],
  },
  viewOrderLake: {
    breadcrumbs: [{ url: "/order-lake" }],
  },
  branding: {
    breadcrumbs: [{ url: "/setting" }],
    title: "branding",
  },
  manageVendor: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageVendorProfile",
  },
  metafield: {
    breadcrumbs: [{ url: "/setting" }],
    title: "productMetafieldSetting",
  },
  vendorAccess: {
    breadcrumbs: [{ url: "/setting" }],
    title: "access",
  },
  vendorSubscription: {
    breadcrumbs: [{ url: "/setting" }],
    title: "subscription",
  },
  advanceVendorAccess: {
    breadcrumbs: [{ url: "/setting" }],
    title: "advanceAccess",
  },
  providerFormLabel: {
    breadcrumbs: [{ url: "/setting" }],
    title: "providerFormLabel",
  },
  prestaShopProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "prestaShopProduct",
  },
  magentoProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "magentoProduct",
  },
  woocommerceProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "wooCommerceProduct",
  },
  digestSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "digest",
  },
  orderExpiry: {
    breadcrumbs: [{ url: "/setting" }],
    title: "orderExpiry",
  },
  orderExportSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "orderExportSetting",
  },
  orderLiveConnect: {
    breadcrumbs: [{ url: "/setting" }],
    title: "orderLiveConnectSetting",
  },
  paymentExportSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "manageInvoice",
  },
  expressPayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "expressPayment",
  },
  vendorApproval: {
    breadcrumbs: [{ url: "/setting" }],
    title: "approveVendor",
  },
  editService: {
    breadcrumbs: [{ url: "/products" }],
    title: "editService",
  },
  reviewVendor: {
    breadcrumbs: [{ url: "/providers" }],
    title: "reviewVendor",
  },
  wixProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "wixProduct",
  },
  wholesalePriceSetting: {
    breadcrumbs: [{ url: "/settings" }],
    title: "wholesalePriceSetting",
  },
  packingSlipSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "packingSlipSetting",
  },
  wixInstall: {},

  // manageModules: {
  //   breadcrumbs: [{ url: "/setting" }],
  //   title: "Manage vendor Access",
  // },
  // advanceVendor: {
  //   breadcrumbs: [{ url: "/setting" }],
  //   title: "Advance Manage Vendor Access",
  // },
  holidaySetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "vendorHolidaySetting",
  },
  quotes: {
    breadcrumbs: [{ url: "/quotes" }],
    title: "quote",
  }
};

export default privatePageContent;
