import React, { useContext } from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";

// propTypes
import vendorProfileProps from "app/advanceVendor/modules/operator/feature/view/propTypes";

const Shipping = (props) => {
  const { cms } = useContext(PrivateContext);
  const { vendorDetails = {} } = props;
  const { shipping = [] } = vendorDetails || {};
  let shippingTabData = [];
  const shippingInformation = [
    {
      term: cms("label.advanceVendor.streetAddress"),
      description: shipping.address,
    },
    {
      term: cms("label.advanceVendor.streetCity"),
      description: shipping.city,
    },
    {
      term: cms("label.advanceVendor.country"),
      description: shipping.country,
    },
    {
      term: cms("label.advanceVendor.postalCode"),
      description: shipping.pinCode,
    },
  ];
  shippingTabData = shippingInformation.map((item) => {
    const updatedItem = { ...item };
    if (!updatedItem.description) {
      updatedItem.description = cms("label.advanceVendor.noDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(shipping).length === 0) {
      return <div className="noData">{cms("label.advanceVendor.noDataAvailable")}</div>;
    }
    return <DescriptionList items={shippingTabData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

Shipping.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(Shipping), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
  // subFeature: constant.ADVANCE_VENDOR_PROFILE,
});
