import React, { useContext, useState } from "react";
import { useQuery } from "react-apollo";
import { Badge, Card, DisplayText, List, Stack, Layout } from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import { FETCH_SELLER_PLANS } from "app/plans/apollo/queries";

import { Skeleton } from "app/plans/modules/vendor/features/components/index";

const OperatorPlanPreview = () => {
  const { cms, currentUser } = useContext(PrivateContext);
  const { isStripeSubscription = false, stripeSubscription = {} } = currentUser || {};

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const isStripe = !!(isStripeSubscription && stripeSubscription);
  const { error: planError, loading: planLoading, data: planData } = useQuery(FETCH_SELLER_PLANS);

  if (planLoading) {
    return <Skeleton />;
  }

  if (planError) {
    const parsedError = errorHelper.parse(planError);
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={parsedError} />
      </Layout.Section>
    );
  }

  if (!isStripe) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={cms("common.subscription.notEnable")} />
      </Layout.Section>
    );
  }

  let plans = baseHelper.getResponseData(planData, "getSellerPlanList") || [];
  plans = plans.planList;

  const getLayoutSections = () => {
    return plans.map((plan, index) => {

      const {
        analytics = false,
        code = "",
        commission = "",
        description = "",
        name = "",
        period = "",
        periodUnit = "",
        price = "",
        productLimit = "",
        trialPeriod,
        trialPeriodUnit = "",
      } = plan || {};

      const anayticsValue = (analytics && constant.YES ) || constant.NO;

      return (
        <Layout.Section oneThird key={index}>
          <Card key={code}>
            <Card.Section>
              <Stack alignment="center" wrap={false}>
                <Stack.Item fill>
                  <DisplayText size="small" element="h1">
                    <Stack>
                      <Stack.Item fill>{name.toUpperCase()}</Stack.Item>
                    </Stack>
                  </DisplayText>
                </Stack.Item>
                <Stack.Item>
                  <DisplayText size="large">
                    {" "}
                    <Stack>
                      <Stack.Item fill>
                        {`${currentUser.moneyFormat}${price}`}
                        <br />
                        {period && (
                          <Badge status={constant.INFO}>
                            {`${period} ${periodUnit}${Number(period) > 1 ? "s" : ""}`}
                          </Badge>
                        )}
                      </Stack.Item>
                    </Stack>
                  </DisplayText>
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <p>{description}</p>
              <br />
              <List>
                <List.Item key="sellerPlanProduct">{`${cms("common.label.productLimit")}: ${productLimit}`}</List.Item>
                <List.Item key="sellerPlanCommission">{`${cms("common.label.commission")}: ${commission}%`}</List.Item>
                <List.Item key="sellerPlanAnalytics">{`${cms("common.label.analytics")}: ${anayticsValue}`}</List.Item>
              </List>
            </Card.Section>
          </Card>
        </Layout.Section>
      );
    });
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {isStripe && (
        <Layout.Section>
          <Layout>{getLayoutSections()}</Layout>
        </Layout.Section>
      )}
    </>
  );
};

export default OperatorPlanPreview;
