import React from "react";

// import generic
import { ExportProductPDF } from "app/productOld/modules/generic";

const ExportProductsPDF = () => {
  const getComponent = () => {
    return <ExportProductPDF />;
  };
  const ExportProductComponent = getComponent();
  return ExportProductComponent;
};

export default ExportProductsPDF;
