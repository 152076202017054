import React from "react";
import PropTypes from "prop-types";
import { Card, Layout, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris";

const SkeletonAnnotated = (props) => {
  const { annotatedLines = 3, primaryLines = 4 } = props;
  return (
    <>
      <Layout.AnnotatedSection
        title={<SkeletonDisplayText size="small" />}
        description={<SkeletonBodyText lines={annotatedLines} />}
      >
        <Card sectioned>
          <TextContainer>
            <SkeletonBodyText lines={primaryLines} />
          </TextContainer>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

SkeletonAnnotated.propTypes = {
  annotatedLines: PropTypes.number,
  primaryLines: PropTypes.number,
};

SkeletonAnnotated.defaultProps = {
  annotatedLines: 3,
  primaryLines: 4,
};

export default SkeletonAnnotated;
