import React, { useContext, useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { ChoiceList, Card, Layout, Link, Tabs, Tag, TextContainer } from "@shopify/polaris";
import { ImportMinor, AddProductMajor } from "@shopify/polaris-icons";

// import hoc
import { PrivateContext } from "lib/context";
import { withFeature, withErrorBoundary } from "lib/hoc";

// import form lib
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// layout component
import { SingleColumnLayout } from "layout/private/components";

// lib components
import { ResourceList, Banner, ModalPopup, PermissionBanner } from "lib/components";

// report components
import { QuickAction, QuickReport } from "app/reports";

// import GQL
import { GET_PRODUCT_LIST, GET_PRODUCT_LISTING } from "app/productOld/apollo/queries";
import { GET_VENDOR_LIST } from "app/vendors/apollo/queries";
import { BULK_PRODUCT } from "app/productOld/apollo/mutations";
import productListCMS from "app/productOld/modules/generic/feature/list/cms/productListCMS";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

// import subFeatures.
import { RejectProduct, DeleteProduct, ProductListView } from "app/productOld/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/productOld/modules/generic/moreFilters/moreFilter";
import { SUBSCRIPTION_BY_OPERATOR } from "app/productOld/apollo/subscriptions/productList";
import OperatorExportProduct from "app/productOld/modules/operator/features/export/export";
import { SyncProduct } from "./subFeature";

const OperatorProductList = () => {
  const { cms, currentUser, history, isShopifyCustomOperator } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);

  const queryParam = baseHelper.getQueryParams(history.location.search);

  const paramFilter = (prevAppliedFilter.filter && prevAppliedFilter.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);

  const [selected, setSelected] = useState(parseInt(prevAppliedFilter.tab, 10) || 0);
  const selectedSort = prevAppliedFilter.sort_name && `${prevAppliedFilter.sort_name}_${prevAppliedFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.UPDATE_DESC);
  const [productList, setProductList] = useState(false);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevAppliedFilter.perPage, 10) || 10);
  const [selectedItems, setSelectedItems] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(prevAppliedFilter.page, 10) || 1);
  const [queryValue, setQueryValue] = useState(prevAppliedFilter.search ? decodeURI(prevAppliedFilter.search) : null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [singleId, setSingleId] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [primaryActionTitle, setPrimaryActionTitle] = useState(null);
  const [secondaryActionsTitle, setSecondaryActionsTitle] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(prevAppliedFilter.list_filter || "");
  const [taggedWith, setTaggedWith] = useState(prevAppliedFilter.list_search || "");
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const { filterStatus, tabs, sortOptions, message, resourceName } = productListCMS(cms);
  const [deleteSelected, setDeleteSelected] = useState([constant.DELETE_SHOPIFY]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [popoverDisable, setPopoverDisable] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const [isproductListSetting, setIsProductListSetting] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const [dataToFetch, setDataToFetch] = useState({
    search: prevAppliedFilter.search,
    list_filter: prevAppliedFilter.list_filter,
    list_search: prevAppliedFilter.list_search,
    filter: prevAppliedFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevAppliedFilter.sort_name || constant.UPDATE,
    sort_order: prevAppliedFilter.sort_order || constant.DESC,
  });

  useEffect(() => {
    const sort = sortValue.split("_");
    const productSearch = {
      search: queryValue ? decodeURI(queryValue) : "",
      list_filter: selectedFilter ? decodeURI(selectedFilter) : "",
      list_search: taggedWith ? decodeURI(taggedWith) : "",
      sort_name: sort[0] || constant.UPDATE,
      sort_order: sort[1] || constant.DESC,
      page: parseInt(currentPage, 10),
      perPage: parseInt(selectedLimit, 10),
      filter: filterByStatus || [],
    };
    storageHelper.set("productSearch", `?${new URLSearchParams(productSearch).toString()}`);
  }, [queryValue, taggedWith, selectedFilter, sortValue, currentPage, selectedLimit, filterByStatus]);

  // eslint-disable-next-line no-unused-vars
  const [banner, setBanner] = useState({
    isOpen: true,
    title: message.defaultBanner.title,
    status: "info",
  });
  const [resBanner, setResBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const inputData = {
    search: dataToFetch.search ? encodeURI(dataToFetch.search) : dataToFetch.search,
    list_filter: dataToFetch.list_filter ? encodeURI(dataToFetch.list_filter) : dataToFetch.list_filter,
    list_search: dataToFetch.list_search ? encodeURI(dataToFetch.list_search) : dataToFetch.list_search,
    filter: filterByStatus || selectedTab,
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
  };

  // update value based on display name - Advance search
  if (constant.ADVANCE_MAP_FILTER && constant.ADVANCE_MAP_FILTER[inputData.list_filter]) {
    inputData.list_filter = constant.ADVANCE_MAP_FILTER[inputData.list_filter];
  }
  inputData.list_search =
    inputData.list_filter === constant.SHOPIFY_CATALOG_ID && inputData.list_search
      ? (prevAppliedFilter && prevAppliedFilter.list_cat_id) || dataToFetch.list_cat_id
      : inputData.list_search;

  // GQL:Operations
  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const { loading: loadingProductListing, data: dataProductSetting } = useQuery(GET_PRODUCT_LISTING);

  useEffect(() => {
    if (dataProductSetting) {
      const productistResponse = baseHelper.getResponseData(dataProductSetting, "getProductListingSetting");
      if (productistResponse) {
        const { isEnabled = {} } = productistResponse;
        if (isEnabled) {
          setIsProductListSetting(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductSetting]);

  const { error: errorVendorList, loading: loadingVendorList, data: dataVendorList } = useQuery(GET_VENDOR_LIST);

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  useEffect(() => {
    if (message.defaultBanner.title && defaultBanner) {
      setBanner((preState) => ({
        ...preState,
        title: message.defaultBanner.title,
      }));
      setDefaultBanner(false);
    }
  }, [defaultBanner, message]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };
  const handleRejectModal = (eventKey, id = false) => {
    setSingleId(null);

    if (
      (eventKey === constant.REJECT ||
        eventKey === constant.UNREJECT ||
        eventKey === constant.UNDELETE ||
        eventKey === constant.RE_SUBMIT) &&
      id
    ) {
      setSingleId(id);
    }
    switch (eventKey) {
      case constant.REJECT:
        setModalTitle(cms("label.rejectProduct"));
        setPrimaryActionTitle(cms("label.reject"));
        setSecondaryActionsTitle(cms("label.notReject"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.UNREJECT:
        setModalTitle(cms("label.unrejectProduct"));
        setPrimaryActionTitle(cms("label.unreject"));
        setSecondaryActionsTitle(cms("label.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.UNDELETE:
        setModalTitle(cms("label.undeleteProduct"));
        setPrimaryActionTitle(cms("label.undelete"));
        setSecondaryActionsTitle(cms("label.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.RE_SUBMIT:
        setModalTitle(cms("label.resubmitProduct"));
        setPrimaryActionTitle(cms("label.resubmit"));
        setSecondaryActionsTitle(cms("label.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      default:
        break;
    }
  };

  const checkHoliday = (items) => {
    const onHolidayVendors = [];
    vendorList.forEach((vendor) => {
      if (vendor && vendor.onHoliday) {
        onHolidayVendors.push(vendor._id);
      }
    });
    const selectedVendorIds = [];
    items.forEach((value) => {
      const product = productList.find((productData) => productData.productId === value);
      if (product && !selectedVendorIds.includes(product.vendorId)) {
        selectedVendorIds.push(product.vendorId);
      }
    });
    const check = selectedVendorIds.every((vendorId) => onHolidayVendors.includes(vendorId));
    return check;
  };

  const checkVendorStatus = (items) => {
    const inactiveVendors = [];
    vendorList.forEach((vendor) => {
      if (vendor && vendor.isInactive) {
        inactiveVendors.push(vendor._id);
      }
    });
    const selectedVendorIds = [];
    items.forEach((value) => {
      const product = productList.find((productData) => productData.productId === value);
      if (product && !selectedVendorIds.includes(product.vendorId)) {
        selectedVendorIds.push(product.vendorId);
      }
    });
    const check = selectedVendorIds.every((vendorId) => inactiveVendors.includes(vendorId));
    return check;
  };

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      deleteFromShopify,
      reason,
    };
    let holiday = false;
    let inactiveVendor = false;
    if (eventKey === constant.PUBLISH && selectedItems.length) {
      holiday = checkHoliday(selectedItems);
      inactiveVendor = checkVendorStatus(selectedItems);
    }
    if (id) {
      if (eventKey === constant.PUBLISH) {
        holiday = checkHoliday([id]);
      }
      formData.ids = [id];
      setListLoading(`${eventKey}_${id}`);
    }
    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setOpenRejectModal(false);
        setSelectedItems([]);
        setDeleteSelected([constant.DELETE_SHOPIFY]);
        setKeyName(null);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (responseData) {
            let title = cms("common.message.success.background");
            if (holiday) {
              title = cms("label.markAvailable");
            }
            if (inactiveVendor) {
              title = cms("label.markProduct");
            }
            setResBanner({ isOpen: true, title, status: holiday || inactiveVendor ? constant.INFO : constant.SUCCESS });
          }
          if (responseDataError) {
            setResBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setResBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      setSortValue(selectedItem);
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      const filterApplied = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filterApplied });
    }
  };

  useEffect(() => {
    setSelectedItems([]);
    const productListResponseResponseData = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const productListResponseResponseError = baseHelper.getResponseError(
      dataProductList,
      constant.gql.GET_PRODUCT_LIST
    );
    if (productListResponseResponseData) {
      setProductList(productListResponseResponseData.productList);
      setTotalProductCount(productListResponseResponseData.count);
    }
    if (productListResponseResponseError) {
      setResBanner({ status: constant.CRITICAL, title: productListResponseResponseError, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    setSelectedItems([]);
    const vendorListResponseData = baseHelper.getResponseData(dataVendorList, constant.gql.GET_VENDOR_LIST);
    const vendorListErrorData = baseHelper.getResponseError(dataVendorList, constant.gql.GET_VENDOR_LIST);
    if (vendorListResponseData) {
      const { vendors: vendorListData = [] } = vendorListResponseData;
      setVendorList(vendorListData);
    }
    if (vendorListErrorData) {
      setListLoading(false);
      setResBanner({ status: constant.CRITICAL, title: vendorListErrorData, isOpen: true });
    }
  }, [dataVendorList]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setResBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorProductList), isOpen: true });
    }
    if (errorVendorList) {
      setResBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorList), isOpen: true });
    }
  }, [errorProductList, errorVendorList]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_BY_OPERATOR,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handlePage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
    baseHelper.setUrl(history, { search: value, page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleQueryValueRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams =
      key === constant.FILTER_BY_STATUS ? { list_filter: "", page: 1 } : { list_cat_id: "", list_search: "", page: 1 };
    if (clearFilter) {
      // clearParams.list_filter = "";
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH: {
            setTaggedWith("");
            baseHelper.setUrl(history, clearParams);
            const filterApplied = baseHelper.queryParamsFromLocation(history);
            return setDataToFetch({ ...dataToFetch, ...filterApplied });
          }
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setQueryParams(history.location, clearParams)];
          case constant.CLEAR_ALL:
            return [
              setTaggedWith(""),
              setSelectedFilter(""),
              setFilterByStatus(null),
              baseHelper.setUrl(history, clearParams),
            ];
          default:
            return null;
        }
      }
    }
    if (key === constant.FILTER_BY_STATUS) {
      setFilterByStatus(null);
    } else {
      // clearParams.list_filter = "";
      setTaggedWith("");
    }
    baseHelper.setUrl(history, clearParams);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
    return null;
  };

  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setTaggedWith("");
    baseHelper.setUrl(history, { list_cat_id: "", list_search: "", list_filter: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleClearAll = () => {
    handleQueryValueRemove();
    handleTaggedWithRemove(true, constant.CLEAR_ALL);
    handleSelectedFilterRemove();
    setSelectedFilter("");
    setTaggedWith("");
    setFilterByStatus(null);
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {option === constant.PENDING_SMALL ? cms("label.review") : baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return value === constant.SKU
          ? value.toUpperCase()
          : cms(`label.${value === constant.TITLE ? "titleName" : baseHelper.ucFirst(value)}`) ||
              baseHelper.ucFirst(value);
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }
  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: "taggedWith",
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(true, constant.TAGGED_WITH),
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: "selectedFilter",
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  if (!isEmpty(filterByStatus)) {
    appliedFilters.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(true, constant.FILTER_BY_STATUS),
    });
  }

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: "all", page: 1 });
    }
  };

  const filters = [
    {
      key: "selectedFilter",
      label: cms("label.filterBy"),
      filter: (
        <Filter
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          productList={productList}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: cms("common.label.filterByStatus"),
      filter: (
        <ChoiceList
          title={cms("common.label.filterByStatus")}
          titleHidden
          choices={filterStatus}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: "taggedWith",
      label: cms("label.filter"),
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        cms={cms}
        item={item}
        listLoading={listLoading}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        loadingBulkProduct={loadingBulkProduct}
        handleRejectModal={handleRejectModal}
        handleDeleteModal={handleDeleteModal}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setResBanner}
        active={active}
        popoverDisable={popoverDisable}
        setActive={setActive}
        isproductListSetting={isproductListSetting}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: cms("label.action"),
    },
  ];

  const bulkActions = [
    {
      content: cms("label.push"),
      onAction: () => bulkAction(constant.APPROVE),
      disabled: tabs[selected].value === constant.APPROVED.toLowerCase(),
    },
    {
      content: cms("label.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: cms("label.edit"),
      onAction: () => bulkAction(constant.BULK_EDIT),
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: cms("label.rejectSelected"),
      onAction: () => handleRejectModal(constant.REJECT),
      destructive: true,
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: cms("label.available"),
      onAction: () => bulkAction(constant.PUBLISH),
    },
    {
      content: cms("label.unavailable"),
      onAction: () => bulkAction(constant.UNPUBLISH),
    },
    {
      content: cms("label.unrejectSelected"),
      onAction: () => handleRejectModal(constant.UNREJECT),
    },
  ];

  const linkData = [
    {
      action: () => history.push("/products/add"),
      caption: cms("section.caption.add"),
      id: "addProduct",
      primary: true,
      label: cms("label.add"),
      // icon: AddProductMajor,
    },
    {
      action: () => history.push("/products/edit"),
      caption: cms("section.caption.edit"),
      id: "editProducts",
      primary: true,
      label: cms("label.editProduct"),
    },
    {
      action: () => setModal(true),
      caption: cms("label.caption"),
      id: "exportPayment",
      primary: true,
      label: cms("section.export.label.title"),
      //  icon: ExportMinor,
    },
    {
      action: () => setOpenSyncModal(true),
      caption: cms("section.caption.import"),
      id: "importProduct",
      label: cms("label.import"),
      // icon: ImportMinor,
    },
  ];

  const updatedResourceName = { ...resourceName };

  updatedResourceName.singular = `${updatedResourceName.singular} from page ${currentPage}`;
  updatedResourceName.plural = `${updatedResourceName.plural} from page ${currentPage}`;

  const handleClose = () => {
    // setAppUpdate(!appUpdate);
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  const getComponent = () => {
    return (
      <>
        {!isShopifyCustomOperator && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.title")}
              status="critical"
            >
              <p>{cms("common.popup.migrate.description")}</p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title="Upgrade to App permission">
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutCustom")}`,
                      url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Card>
              <UpgradAppPermission />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        {resBanner.isOpen && (
          <>
            <Banner
              isOpen={resBanner.isOpen}
              title={resBanner.title}
              status={resBanner.status}
              onDismiss={() => {
                setResBanner({ isOpen: false, title: "", status: "" });
              }}
            />
            <br />
          </>
        )}
        <Banner isOpen title={cms("label.title")} status={constant.INFO} />
        <br />
        <QuickReport />
        <QuickAction link={linkData} />
        <Layout>
          <SyncProduct
            cms={cms}
            openSyncModal={openSyncModal}
            setResBanner={setResBanner}
            setOpenSyncModal={setOpenSyncModal}
            dataToFetch={inputData}
          />
          <DeleteProduct
            cms={cms}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            selectedItems={selectedItems}
            isSeller
            deleteSelected={deleteSelected}
          />
          <RejectProduct
            cms={cms}
            openRejectModal={openRejectModal}
            setOpenRejectModal={setOpenRejectModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            modalTitle={modalTitle}
            primaryActionTitle={primaryActionTitle}
            secondaryActionsTitle={secondaryActionsTitle}
            keyName={keyName}
          />
          <Layout.Section>
            <Card>
              <ResourceList
                resourceName={updatedResourceName}
                items={productList || []}
                idForItem={(products) => {
                  const { productId: id } = products;
                  return id;
                }}
                renderItem={renderItem}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                queryValue={queryValue}
                selectedItems={selectedItems}
                onSelectionChange={setSelectedItems}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={sortFilter}
                bulkActions={bulkActions}
                promotedBulkActions={promotedBulkActions}
                loading={loadingProductList || loadingVendorList || loadingProductListing}
                filters={filters}
                appliedFilters={appliedFilters}
                page={currentPage}
                count={Number(totalProductCount)}
                perPage={selectedLimit}
                setPage={handlePage}
                setPopoverDisable={setPopoverDisable}
                setPerPage={handleSelectLimit}
              />
            </Card>
          </Layout.Section>
          <OperatorExportProduct vendorData={vendorList} openModal={isOpenModal} setOpenModal={setModal} />
        </Layout>
      </>
    );
  };

  return <SingleColumnLayout primary={getComponent()} />;
};

export default withFeature(withErrorBoundary(OperatorProductList), { feature: constant.PRODUCTS });
