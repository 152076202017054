import React, { useEffect, useState } from "react";
import { Autocomplete, Icon } from "@shopify/polaris";
import { ArrowUpMinor } from "@shopify/polaris-icons";

// import props
import { reportingAutoCompleteProp } from "app/reporting/modules/operator/props";

const ReportingAutoComplete = (props) => {
  const { label = "Filter options:", initOptions, allowMultiple = false, listTitle = "", handleAutoComplete } = props;
  const [selectedOptions, setSelectedOptions] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(initOptions);

  useEffect(() => {
    setInputValue("");
    setOptions(initOptions);
  }, [initOptions]);

  const updateText = (value) => {
    setInputValue(value);

    if (value === "") {
      setOptions(initOptions);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = initOptions.filter((option) => option.label.match(filterRegex));
    setOptions(resultOptions);
  };

  const updateSelection = (selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = options.find((option) => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    const [first] = selected; // hack to show checkboxes in select but allow to select only one option
    setInputValue(selectedValue[0]);
    setSelectedOptions([first]);
    handleAutoComplete([first]);
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={label}
      value={inputValue}
      suffix={<Icon source={ArrowUpMinor} color="inkLighter" />}
      placeholder="Select"
    />
  );

  return (
    <Autocomplete
      options={options}
      selected={selectedOptions}
      onSelect={updateSelection}
      textField={textField}
      listTitle={listTitle}
      allowMultiple={allowMultiple}
    />
  );
};

ReportingAutoComplete.propTypes = reportingAutoCompleteProp.type;

export default ReportingAutoComplete;
