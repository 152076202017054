import React, { useContext, useEffect, useState } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import context, constant and helpers
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner, Spinner } from "lib/components";

// GQL
import { GET_NOTIFICATION_SETTING } from "app/userManagement/apollo/queries";
import { UPDATE_NOTIFICATION_SETTING } from "app/userManagement/apollo/mutation";

// generic
import { CardList, ConfirmationModal, ResetAllButton } from "app/userManagement/modules/generic";

const ProviderNotification = () => {
  const { cms, currentUser, history } = useContext(PrivateContext);
  const cardContent = {
    vendor: {
      newOrderSupplier: `${cms("label.newOrder")}`,
      packingSlip: `${cms("label.packingSlip")}`,
      productBulkUploadNotification: `${cms("label.bulkProduct")}`,
      productBulkUploadNotificationError: `${cms("label.bulkProductError")}`,
      productsReviewSupplier: cms("label.reviewProduct"),
      productsApprovedSupplier: `${cms("label.productApprove")}`,
      productsEditApproved: `${cms("label.editProductApprove")}`,
      productsDeleteSupplier: `${cms("label.deleteProduct")}`,
      productsEditSupplier: `${cms("label.editProducts")}`,
      productsRejectedSupplier: `${cms("label.productRejected")}`,
      sellerAcceptsOrder: `${cms("label.sellerOrderAccepted")}`,
      sellerRejectsOrder: `${cms("label.sellerOrderRejected")}`,
      shipmentBySeller: `${cms("label.fulfillmentDone")}`,
      vendorProfileUpdate: `${cms("label.profileUpdate")}`,
    },
  };

  const resetAll = constant.RESET_ALL;
  const muteAll = constant.MUTE_ALL;
  const isVendor = baseHelper.isVendor(currentUser);
  const labelsList = isVendor && cardContent.vendor;
  const [mutedLabels, setMutedLabels] = useState([]);
  const [loadingLabel, setLoadingLabel] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLabel, setModalLabel] = useState("");
  const [refetchLabel, setRefetchLabel] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });
  const { data: notificationSettingData, refetch, loading, networkStatus } = useQuery(GET_NOTIFICATION_SETTING, {
    notifyOnNetworkStatusChange: true,
  });
  const [updateNotificationSetting] = useMutation(UPDATE_NOTIFICATION_SETTING);

  useEffect(() => {
    const responseError = baseHelper.getResponseError(notificationSettingData, constant.gql.GET_NOTIFICATION);
    if (responseError) {
      setBanner({
        title: responseError,
        status: constant.CRITICAL,
        isOpen: true,
      });
      return;
    }
    const responseData = baseHelper.getResponseData(notificationSettingData, constant.gql.GET_NOTIFICATION);
    const { labels = [] } = responseData || {};
    setMutedLabels(labels || []);
  }, [notificationSettingData]);

  const onOpenModal = (modalLabelProp) => {
    setIsModalOpen(true);
    setModalLabel(modalLabelProp);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
    setModalLabel("");
  };

  const handleClick = (labelData) => {
    setLoadingLabel(labelData);
    const isResetAll = labelData === resetAll;
    const isMuteAll = labelData === muteAll;
    const isMuted = mutedLabels && mutedLabels.includes(labelData);
    let allLabels =
      isMuteAll && mutedLabels ? Object.keys(labelsList).filter((item) => !mutedLabels.includes(item)) : [labelData];
    if (allLabels.indexOf("muteAll") !== -1) {
      const allKeys = Object.keys(cardContent.vendor);
      allLabels = allKeys;
    }
    const input = { input: { label: allLabels, isMuted, isResetAll } };
    updateNotificationSetting({ variables: input })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_NOTIFICATION);
        if (responseError) {
          setLoadingLabel(false);
          setIsModalOpen(false);
          setBanner({
            title: responseError,
            status: constant.CRITICAL,
            isOpen: true,
          });
          return;
        }

        setRefetchLabel(labelData);
        refetch()
          .then(() => {
            setRefetchLabel("");
            setLoadingLabel(false);
            setIsModalOpen(false);
            setBanner({
              title: `${cms("message.success")}`,
              status: constant.SUCCESS,
              isOpen: true,
            });
          })
          .catch((exception) => {
            setIsModalOpen(false);
            setRefetchLabel("");
            setLoadingLabel(false);
            setBanner({
              status: constant.CRITICAL,
              title: errorHelper.parse(exception),
              isOpen: true,
            });
          });
      })
      .catch((exception) => {
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
        setLoadingLabel(false);
      });
  };

  const loadCardList = () => {
    let labelsListData = isVendor && cardContent.vendor;
    const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
    if (isEcommerceUser) {
      labelsListData = baseHelper.getAllowedVendorNotificatonSetting(labelsListData);
    }
    return Object.keys(labelsListData).map((labelData) => {
      const isMuted = (mutedLabels && mutedLabels.includes(labelData)) || false;
      const isLoadingLabel = loadingLabel === labelData || (networkStatus === 4 && refetchLabel === labelData);

      return (
        <CardList
          key={labelData}
          isMuted={isMuted}
          labelData={labelData}
          isLoadingLabel={isLoadingLabel}
          handleClick={handleClick}
          vendor={cardContent.vendor}
        />
      );
    });
  };

  if (networkStatus !== 4 && loading) {
    return <Spinner />;
  }

  return (
    <div>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <ConfirmationModal
        onClose={onCloseModal}
        isOpen={isModalOpen}
        onSubmit={handleClick}
        modalLabel={modalLabel}
        loadingLabel={loadingLabel}
      />
      <Layout.AnnotatedSection title={`${cms("title")}`} description={`${cms("description")}`}>
        <ResetAllButton mutedLabels={mutedLabels} vendor={cardContent.vendor} openConfirmationModal={onOpenModal} />
        <br />
        {loadCardList()}
      </Layout.AnnotatedSection>
      <Layout.Section>
        <PageActions
          secondaryActions={{
            content: cms("common.button.cancel"),
            onAction: () => history.push("/setting"),
          }}
        />
      </Layout.Section>
    </div>
  );
};

export default withFeature(withErrorBoundary(ProviderNotification), { feature: constant.MUTE_NOTIFICATION });
