import React, { useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";

import { GET_SHIPPING_BY_VENDOR } from "app/shipping/apollo/queries";

const ExportShipping = () => {
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [getShippingByVendor, { data: getShippingData, loading: getShippingLoading, error: getShippingError }] = useLazyQuery(
    GET_SHIPPING_BY_VENDOR
  );

  useEffect(() => {
    if (
      !getShippingLoading &&
      !getShippingError
    ) {
        const resData = baseHelper.getResponseData(getShippingData, "getShippingByVendor");
        const resError = baseHelper.getResponseError(getShippingData, "getShippingByVendor");
        if (!resData) {
            return;
        }
        if (!resData && resError) {
            setBanner({
                title: resError,
                status: constant.CRITICAL,
                isOpen: true,
            });
            return;
        }
        const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
        FileDownload(Baby.unparse(JSON.parse(resData.csvData)), `Shipping Export - ${date}.csv`);
        setTimeout(() => {
            window.close();
        }, 500);
    }
  }, [
    getShippingData,
    getShippingLoading,
    getShippingError,
  ]);

  useEffect(() => {
    getShippingByVendor({ variables: {} });
  }, []);

  return (
    <Layout.Section>
      {getShippingLoading && <Spinner isFullPage />}
      {banner.isOpen && (
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
        />
      )}
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(ExportShipping), { feature: constant.EXPORT_ORDER });