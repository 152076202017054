// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const GET_PRODUCT_EXPORT_FILTER = gql`
  query getProductExportFilter {
    getProductExportFilter {
        data {
            vendorFilter {
                id
                name
            }
            statuses
        }
        status
        error
    }
  }
`;

export default GET_PRODUCT_EXPORT_FILTER;
