function paymentListData(cms) {
  return {
    mainLink: {
      orderNumber: "/orders/view/",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: cms("common.label.sortOption.dateAsc"), value: "createdAt_asc" },
      { label: cms("common.label.sortOption.dateDesc"), value: "createdAt_desc" },
    ],
    resourceName: cms("label.resourceName"),
    tabsData: [
      {
        id: "all-payouts",
        content: cms("label.tab.all"),
        accessibilityLabel: "All payouts",
        panelID: "all-payouts-content",
        value: "all",
      },
      {
        id: "failed-payouts",
        content: cms("label.tab.failed"),
        accessibilityLabel: "Failed payouts",
        panelID: "failed-payouts-content",
        value: "error",
      },
      {
        id: "pending-payouts",
        content: cms("label.tab.pending"),
        accessibilityLabel: "Pending payouts",
        panelID: "pending-payouts-content",
        value: "pending",
      },
      {
        id: "paid-payouts",
        content: cms("label.tab.success"),
        accessibilityLabel: "Success payouts",
        panelID: "paid-payouts-content",
        value: "paid",
      },
    ],
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
  };
}

export default paymentListData;
