import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import { Layout } from "@shopify/polaris";

// import components
import { Spinner } from "lib/components";
import { PublicContext } from "lib/context";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";
import Banner from "lib/components/banner/banner";

// import constant
import constant from "lib/constant/constant";

// import page content

import { LOGIN_BIGCOMMERCE_SELLER } from "app/public/apollo/mutations/registerBigCommerceSeller";

const { gql } = constant;

const BigCommerceLoad = async () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { cms, history } = useContext(PublicContext);
  const { location } = history;
  const { state } = location;
  const queryParams = baseHelper.getQueryParams(history.location.search) || {};
  const { signed_payload: signedPayload } = queryParams;
  const [loginBigCommerceSeller, { loading: loginBigCommerceSellerLoading }] = useMutation(LOGIN_BIGCOMMERCE_SELLER);
  const onload = async () => {
    try {
      const response = await loginBigCommerceSeller({ variables: { input: { signedPayload } } });
      const responseData = baseHelper.getResponseData(response.data, gql.LOGIN_BIGCOMMERCE_SELLER);
      if (!(responseData && responseData.token)) {
        if (state && state.isError) {
          setBanner({ isOpen: true, status: "critical", title: state.message });
        }
        history.push("/bigcommerce/load", { isError: true, message: cms("message.sellerloginError") });
        // history.go();
        return true;
      }
      storageHelper.set("token", responseData.token);
      history.push("/");
      // NOTE: As marketcube app was not loading (UI) so we used history.go to rerender marketcube app.
      history.go();
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.sellerloginError") });
    }
    return true;
  };

  if (!signedPayload || loginBigCommerceSellerLoading) {
    return (
      <div>
        <Spinner fontSize={42} />
        <h3 className="text-center">{cms("message.wait")}</h3>
      </div>
    );
  }
  const userToken = storageHelper.get("token");
  if (signedPayload && !userToken) {
    onload();
  } else {
    history.push("/");
    // NOTE: As marketcube app was not loading (UI) so we used history.go to rerender marketcube app.
    history.go();
  }

  const onDismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  // Temporary commented
  // if (state && state.isError) {
  //   console.log("inside here===>");
  //   setBanner({ isOpen: true, status: "critical", title: state.message });
  // }
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
    </>
  );
};

export default BigCommerceLoad;
