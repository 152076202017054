import { gql } from "apollo-boost";

const GET_DIGEST_SETTING = gql`
  query getDigestSetting {
    getDigestSetting {
      error
      status
      data {
        duration
        date
        day
        durationType
        activity {
          activityType
          status
        }
        updatedAt
      }
    }
  }
`;
export default GET_DIGEST_SETTING;
