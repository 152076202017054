import React, { useState, useEffect, useContext, useCallback } from "react";
import { Layout, SettingToggle, TextStyle, Button, Checkbox, Modal, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { errorHelper } from "lib/helpers";
import { UPDATE_ORDER_SETTING } from "app/orders/apollo/mutation";

const { gql, ACCEPTED_ORDER, FULFILLMENTS, ORDER_REVIEW } = constant;

const OperatorOrderSetting = () => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { _id: currentUserId, setting } = currentUser;
  const { isAcceptOrder, isFulfillOrder, isReviewOrder = false } = setting || {};
  const [toggleOrderStatus, setToggleOrderStatus] = useState(false);
  const [toggleOrderReview, setToggleOrderReview] = useState(false);
  const [autoOrdersEnabled, setAutoOrdersEnabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setSettingOrder] = useMutation(UPDATE_ORDER_SETTING);
  const contentStatus = toggleOrderStatus
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textStatus = toggleOrderStatus
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");
  const autoFullfillStatus = autoOrdersEnabled
    ? cms("section.autoFulfillOrders.button.disable")
    : cms("section.autoFulfillOrders.button.enable");
  const textAutoStatus = autoOrdersEnabled
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");

  const reviewOrderStatus = toggleOrderReview
    ? cms("section.orderReview.button.disable")
    : cms("section.orderReview.button.enable");

  const textOrderStatus = toggleOrderReview
    ? cms("section.orderReview.label.enabled")
    : cms("section.orderReview.label.disabled");

  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleCloseDelete = () => {
    setActive(false);
  };

  const handleCheckChange = useCallback(() => setChecked(!checked), [checked]);

  const updateOrderSetting = (orderStatus, key = false) => {
    const requestData = {
      // id: currentUserId,
      isAcceptOrder: orderStatus,
      isFulfillOrder: autoOrdersEnabled,
      isReviewOrder: toggleOrderReview,
    };

    if (key === ORDER_REVIEW) {
      requestData.isReviewOrder = !toggleOrderReview;
      setActive(false);
    }

    if (!orderStatus && key === ACCEPTED_ORDER) {
      requestData.isAcceptOrder = orderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    if (key === FULFILLMENTS) {
      requestData.isAcceptOrder = toggleOrderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    setButtonLoading(key);
    setSettingOrder({
      variables: { input: requestData },
    })
      .then((res) => {
        setButtonLoading(false);
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_ORDER_SETTING);
        let bannerValue = { status: constant.CRITICAL, title: errorResponse };
        if (!errorResponse) {
          const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_ORDER_SETTING);
          bannerValue = { status: constant.SUCCESS, title: cms("message.success") };
          setToggleOrderStatus(requestData.isAcceptOrder);
          setAutoOrdersEnabled(requestData.isFulfillOrder);
          setToggleOrderReview(requestData.isReviewOrder);
        }

        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch((exception) => {
        setButtonLoading(false);
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };
  const onSubmit = () => {
    const orderStatus = toggleOrderStatus;
    updateOrderSetting(!orderStatus, ACCEPTED_ORDER);
  };
  const onAutoSubmit = () => {
    const orderStatus = autoOrdersEnabled;
    updateOrderSetting(!orderStatus, FULFILLMENTS);
  };
  const onReviewSubmit = () => {
    if (!toggleOrderReview) {
      setActive(true);
    } else {
      const orderStatus = toggleOrderReview;
      updateOrderSetting(!orderStatus, ORDER_REVIEW);
    }
    // const orderStatus = toggleOrderReview;
  };
  useEffect(() => {
    setToggleOrderStatus(isAcceptOrder);
    setToggleOrderReview(isReviewOrder);
  }, [isAcceptOrder, isReviewOrder]);

  useEffect(() => {
    if (isFulfillOrder) {
      setAutoOrdersEnabled(isFulfillOrder);
    }
  }, [isFulfillOrder]);
  let setButtonColor = false;
  let setFullFillButtonColor = false;
  let setReviewButtonColor = false;
  if (autoOrdersEnabled) {
    setFullFillButtonColor = true;
  }
  if (toggleOrderStatus) {
    setButtonColor = true;
  }
  if (toggleOrderReview) {
    setReviewButtonColor = true;
  }
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });
  return (
    <>
      {/* <div style={{ height: "500px" }}> */}
      <Modal
        open={active}
        onClose={() => handleCloseDelete()}
        title={cms("section.orderReview.modal.title")}
        primaryAction={{
          content: "Confirm",
          onAction: () => updateOrderSetting(toggleOrderStatus, ORDER_REVIEW),
          disabled: !checked,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCloseDelete,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("section.orderReview.modal.content")}</p>
            <Checkbox
              label={cms("section.orderReview.modal.agreeReview")}
              checked={checked}
              onChange={handleCheckChange}
            />
          </TextContainer>
        </Modal.Section>
      </Modal>
      {/* </div> */}
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}

      <Layout.AnnotatedSection
        title={cms("section.autoAccept.title")}
        description={cms("section.autoAccept.description")}
      >
        <SettingToggle
          action={{
            id: "submit",
            content: contentStatus,
            onAction: () => onSubmit(),
            destructive: setButtonColor,
            loading: buttonLoading === ACCEPTED_ORDER,
          }}
          enabled={setButtonColor}
        >
          {cms("section.autoAccept.content")}
          <TextStyle variation="strong">{textStatus}</TextStyle>
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.autoFulfillOrders.title")}
        description={cms("section.autoFulfillOrders.description")}
      >
        <SettingToggle
          action={{
            id: "submit",
            content: autoFullfillStatus,
            onAction: () => onAutoSubmit(),
            disabled: !toggleOrderStatus,
            destructive: setFullFillButtonColor,
            loading: buttonLoading === FULFILLMENTS,
          }}
          enabled={setFullFillButtonColor}
        >
          {cms("section.autoFulfillOrders.content")}
          <TextStyle variation="strong">{` ${textAutoStatus}`}</TextStyle>
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.orderReview.title")}
        description={cms("section.orderReview.description")}
      >
        <SettingToggle
          action={{
            id: "submit",
            content: reviewOrderStatus,
            onAction: () => onReviewSubmit(),
            destructive: setReviewButtonColor,
            loading: buttonLoading === ORDER_REVIEW,
          }}
          enabled={setReviewButtonColor}
        >
          {cms("section.orderReview.content")}
          <TextStyle variation="strong">{` ${textOrderStatus}`}</TextStyle>
        </SettingToggle>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(OperatorOrderSetting), { feature: constant.ORDER_SETTING });
