import { gql } from "apollo-boost";

const GET_ORDER_LAKE_LIST = gql`
  query GetOrderLakeList($input: GetListInputV1) {
    getOrderLakeList(input: $input) {
      status
      data {
        count
        orderList {
          _id
          lineItems {
            _id
            shippingStatus
            providerId
            ownerId
          }
          messages
          notes
          products
          refunds
          shipments
          shippingAddresses
          number
          billing {
            city
            company
            country
            countryISO2
            email
            firstName
            lastName
            phone
            state
            addressLineOne
            addressLineTwo
            postcode
          }
          currency
          customer {
            ipAddress
            note
            storeCustomerId
          }
          discountTotal
          ecommercePlatform
          paymentMethod
          storeDateCreated
          storeDateModified
          storeOrderId
          staffNotes
          storeStatus
          tax {
            handlingCost
            shippingCost
            subtotal
            total
            wrappingCost
          }
          cost {
            handlingExTax
            handlingIncTax
            shippingExTax
            shippingIncTax
            subtotalExTax
            subtotalIncTax
            totalExTax
            totalIncTax
            wrappingExTax
            wrappingIncTax
          }
          isSynced
          createdBy
          ownerId
          updatedBy
          createdAt
          updatedAt
        }
      }
      error
    }
  }
`;

export default GET_ORDER_LAKE_LIST;
