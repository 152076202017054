import React from "react";
import { ActionList, Button, Popover as PolarisPopover, Spinner } from "@shopify/polaris";
import PropTypes from "prop-types";

const Popover = (props) => {
  const { active, item, loading, setActive } = props;

  const button = (
    <Button onClick={() => setActive(!active)} disclosure>
      {(loading && <Spinner size="small" />) || "Manage Line Item"}
    </Button>
  );

  return (
    <PolarisPopover active={active} activator={button} onClose={() => setActive()} onActionAnyItem={() => setActive()}>
      <PolarisPopover.Pane>
        <ActionList items={item} onActionAnyItem={() => setActive()} />
      </PolarisPopover.Pane>
    </PolarisPopover>
  );
};

Popover.propTypes = {
  active: PropTypes.bool,
  item: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActive: PropTypes.func.isRequired,
};

Popover.defaultProps = {
  active: false,
};

export default Popover;
