import { gql } from "apollo-boost";

const BULK_EDIT = gql`
  mutation bulkEdit($input: BulkEdit) {
    bulkEdit(input: $input) {
      status
      data
      error
    }
  }
`;

export default BULK_EDIT;
