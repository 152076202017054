import { gql } from "apollo-boost";

const GET_ORDER_LINES = gql`
  query getOrderLines($input: GetOrderLines) {
    getOrderLines(input: $input) {
      data {
        orderLines {
          _id
          shippingAddress {
            address1
            address2
            city
            company
            country
            country_code
            country_name
            customer_id
            default
            first_name
            id
            last_name
            name
            phone
            province
            province_code
            zip
          }
          discountAllocations {
            amount
          }
          vendor
          status
          actualQuantity
          fulfillmentService
          fulfillmentStatus
          fulfillmentType
          vendorId
          quantity
          markUpPrice
          comparePrice
          price
          priceSet {
            shopMoney {
              amount
              currencyCode
            }
            presentmentMoney {
              amount
              currencyCode
            }
          }
          shipping {
            amount
            term
          }
          accountingStatus
          wholesalePrice
          orderId
          orderNumber
          commissionSeller
          commissionTax
          commissionVendor
          createdAt
          shippingAmount
          shippingTax
          shopifyOrderId
          shopifyLineItemId
          moneyFormat
          name
          sku
          grams
          totalDiscount
          paidVia
          totalTax
          trackingNumber
          trackingCompany
          vendorDiscount
          vendorWholesalePrice
          priceTaxRate
        }
        hasMore
      }
      status
      error
    }
  }
`;

const CHECK_ORDER_LINES = gql`
  query checkOrderLines($input: CheckOrderLines) {
    checkOrderLines(input: $input) {
      data {
        _id
        fulfillmentStatus
        vendorId
      }
      status
      error
    }
  }
`;

export { CHECK_ORDER_LINES, GET_ORDER_LINES };
