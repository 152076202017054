import { gql } from "apollo-boost";

const GET_ORDER_COMMISSION = gql`
  query {
    getOrderCommission {
      data {
        isOrderBasedCommission
      }
      status
      error
    }
  }
`;

export default GET_ORDER_COMMISSION;
