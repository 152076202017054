import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

//  import components
import { Banner, SkeletonAnnotated } from "lib/components";

// contexts
import { PrivateContext } from "lib/context";
import { ProductContext } from "app/product/modules/generic/context";

// gql
import { useMutation, useQuery } from "react-apollo";
import { UPLOAD_IMAGE, ADD_SERVICE_VARIANT } from "app/product/apollo/mutations";
import { GET_ADDRESS } from "app/setup/apollo/queries";
import { GET_SELLER_VARIANT } from "app/product/apollo/queries";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// sub feature items
import Availability from "./subFeatureItems/availability";
import { Image, Title, Pricing } from "./subFeatureItems";

const AddServiceVariant = () => {
  const { match, history, cms } = useContext(PrivateContext);
  const { params } = match;
  const { productId = "" } = params;
  const {
    gql,
    value: { MAX_FLAT, MIN_PRICE },
  } = constant;

  const [capacity, setCapacity] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  const [duration, setDuration] = useState();
  const [location, setLocation] = useState();

  const [days, setDays] = useState(constant.daysArray);
  const [endTime, setEndTime] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [horus, setHours] = useState([constant.TWENTY_FOUR]);
  const [maxDuration, setMaxDuration] = useState(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
  const [selectedDays, setSelectedDays] = useState([constant.SEVEN_DAYS]);
  const [selectedHours, setSelectedHours] = useState([constant.TWENTY_FOUR]);
  const [startTime, setStartTime] = useState(constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN);

  const { data: variantData, loading, error } = useQuery(GET_SELLER_VARIANT, {
    variables: { input: { _id: productId } },
  });

  // states
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [title, setTitle] = useState({});
  const [image, setImage] = useState([]);
  const [pricing, setPricing] = useState({
    price: 0,
    comparePrice: 0,
  });
  const [inventory, setInventory] = useState({
    sku: "",
    barCode: "",
    inventoryPolicy: "",
    quantity: "",
  });
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [variantOption, setVariantOption] = useState([]);
  const [titleError, setTitleError] = useState({});
  const [productImages, setProductImages] = useState([]);

  // start
  const [address, setAddress] = useState([]);

  const { availability, serviceId } = useContext(ProductContext);

  // end

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  useEffect(() => {
    if (error && Object.keys(error).length) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.fetch"),
      }));
    }
  }, [cms, error]);

  if (variantData && Object.keys(variantOption).length === 0) {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);
    const responseError = baseHelper.getResponseError(variantData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);
    const { sellerProduct = {} } = responseData || {};
    const { variants = [], images = [] } = sellerProduct || {};
    const variantPosition = variants.length + 1;
    const productSKU = sellerProduct && sellerProduct.sku;
    if (responseError) {
      setBanner({
        ...banner,
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
    }
    let options = {
      option1: cms("label.size"),
      option2: cms("label.color"),
      option3: cms("label.material"),
    };
    if (variants.length) {
      const {
        option1Val = null,
        option2Val = null,
        option3Val = null,
        option1 = null,
        option2 = null,
        option3 = null,
      } = variants[0];
      const availableOptions = {
        option1: option1Val && option1,
        option2: option2Val && option2,
        option3: option3Val && option3,
      };
      options = availableOptions;
    }
    setVariantOption(options);
    setProductImages(images);
    if (productSKU) setInventory({ ...inventory, sku: `${productSKU}-${variantPosition}` });
  }

  useEffect(() => {
    if (availability && availability.hour) {
      // setIsEdit(true);
      const { capacity: capacityData, duration: durationData, hour, locationId } = availability || {};
      const weekDays = Object.keys(hour).filter((item) => hour[item]);
      const availableDays = Object.values(hour).filter((item) => item);
      setDuration(durationData);
      setCapacity(capacityData);
      if (locationId && locationId.length) {
        setLocation(locationId[0]);
      }
      setDays(weekDays);
      if (availableDays.length < 7) {
        setSelectedDays(["custom-days"]);
      }
      if (availableDays.length === 7) {
        setSelectedDays([constant.SEVEN_DAYS]);
      }

      if (availableDays && availableDays.length) {
        const isTwenntyFourHours =
          availableDays[0].open === constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN &&
          availableDays[0].close === constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN;
        setSelectedHours(isTwenntyFourHours ? constant.TWENTY_FOUR : constant.CUSTOM);
        setStartTime(availableDays[0].open * 60);
        setEndTime(availableDays[0].close * 60);
      }
    }
  }, [availability]);

  const { data: addressData } = useQuery(GET_ADDRESS);
  const addressResponse = baseHelper.getResponseData(addressData, constant.gql.GET_ADDRESS);

  useEffect(() => {
    if (addressResponse && addressResponse.addressList) {
      const newData =
        (addressResponse &&
          addressResponse.addressList.map((item) => {
            return { label: item.label, value: item._id };
          })) ||
        [];
      setAddress(newData);
    }
  }, [addressResponse]);

  const [addServiceVariant, { loading: addServiceVariantLoading }] = useMutation(ADD_SERVICE_VARIANT);

  useEffect(() => {
    if (selectedHours[0] === constant.CUSTOM) {
      setStartTime(constant.TIME_PICKER_START_TIME * 60 * 60);
      setEndTime(constant.TIME_PICKER_START_TIME * 60 * 60);
    } else {
      setMaxDuration(constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN);
    }
  }, [selectedHours]);

  // gql states

  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (loading) {
    return <SkeletonAnnotated />;
  }

  const saveVariant = async (variantValue) => {
    try {
      const result = await addServiceVariant({
        variables: {
          input: variantValue,
        },
      });
      const responseData = baseHelper.getResponseData(result.data, constant.ADD_SERVICE_VARIANT);
      const responseError = baseHelper.getResponseError(result.data, constant.ADD_SERVICE_VARIANT);
      if (responseError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: responseError,
        });
        return;
      }
      if (responseData) {
        history.push(`/variants/service/edit/${productId}/${responseData.position}`, { addVariant: true });
      }
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      }));
    }
  };

  const prepareData = () => {
    const { option1 = null, option2 = null, option3 = null } = title;
    const formValues = {
      productId,
      seller: "",
      image: {},
      option1Val: option1 || "",
      option2Val: option2 || "",
      option3Val: option3 || "",
      price: parseFloat(pricing.price),
      comparePrice: parseFloat(pricing.comparePrice),
      inventoryManagement: "",
      inventoryQuantity: parseInt(0, 10),
      isShipping: false,
      weight: parseInt(0, 10),
      weightUnit: constant.weightValue.LB,
    };

    const requiredError = {};
    let hasError = false;

    if (variantOption.option1) {
      requiredError.option1 = !(option1 && option1.trim() !== "");
      hasError = hasError || requiredError.option1;
    }
    if (variantOption.option2) {
      requiredError.option2 = !(option2 && option2.trim() !== "");
      hasError = hasError || requiredError.option2;
    }
    if (variantOption.option3) {
      requiredError.option3 = !(option3 && option3.trim() !== "");
      hasError = hasError || requiredError.option3;
    }
    setTitleError(requiredError);

    return !hasError && formValues;
  };

  const onSubmit = async () => {
    let variantValue = prepareData();
    const weekDays = {};
    // eslint-disable-next-line no-return-assign
    const submitStartTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_START_TIME_IN_MIN : startTime / 60;
    const submitEndTime =
      selectedHours[0] === constant.TWENTY_FOUR ? constant.TWENTY_FOUR_HOUR_END_TIME_IN_MIN : endTime / 60;
    const submitDays = selectedDays[0] === constant.SEVEN_DAYS ? constant.daysArray : days;

    // eslint-disable-next-line no-return-assign
    submitDays.map((day) => (weekDays[day] = { open: submitStartTime, close: submitEndTime }));
    const inputData = {
      capacity: parseInt(capacity || 0, 10),
      duration: parseFloat(duration || 0),
      hour: weekDays,
      // productId: match.params.id,
      locationId: location ? [location] : [],
    };

    if (!variantValue) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    inputData.productId = variantValue.productId;
    delete variantValue.seller;

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      variantValue.image = { url: imageUrl };
    }
    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    if (!isLocalImageUrl) {
      delete variantValue.barcode;
      delete variantValue.inventoryManagement;
      delete variantValue.inventoryQuantity;
      delete variantValue.isShipping;
      delete variantValue.productId;
      delete variantValue.sku;
      delete variantValue.weight;
      delete variantValue.weightUnit;
      variantValue = {
        ...variantValue,
        ...inputData,
      };
      saveVariant(variantValue);
      return;
    }

    if (image && image.length) {
      try {
        const response = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const responseData = baseHelper.getResponseData(response.data, gql.UPLOAD_IMAGE);
        const responseError = baseHelper.getResponseError(response.data, gql.UPLOAD_IMAGE);
        if (responseError) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: responseError,
          }));
          return;
        }
        const { imageUrl = null, imageId = "" } = responseData || {};
        if (!imageUrl) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.url"),
          }));
          return;
        }
        variantValue.image = { url: imageUrl, imageId };
      } catch (exception) {
        setBanner((prev) => ({
          ...prev,
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        }));
        return;
      }
    }
    delete variantValue.barcode;
    delete variantValue.inventoryManagement;
    delete variantValue.inventoryQuantity;
    delete variantValue.isShipping;
    delete variantValue.productId;
    delete variantValue.sku;
    delete variantValue.weight;
    delete variantValue.weightUnit;
    variantValue = {
      ...inputData,
      ...variantValue,
    };
    saveVariant(variantValue);
  };

  const renderDisabled = () => {
    return (
      !capacity ||
      !duration ||
      (selectedDays[0] === constant.CUSTOM_DAYS && !days.length) ||
      disabledButton
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title="Add Service Variant" description={cms("description")}>
        <Title data={title} onChange={setTitle} variantOption={variantOption} error={titleError} cms={cms} />
        <Image productImages={productImages} image={image} setImage={setImage} cms={cms} />
        <Pricing
          acceptOnlyValidInput={acceptOnlyValidInput}
          data={pricing}
          onChange={setPricing}
          cms={cms}
          setWholesalePrice={setWholesalePrice}
          wholesalePrice={wholesalePrice}
          isWholesalePriceVisible={false} // disabled wholesale price field as not required as of now
        />
        <Availability
          cms={cms}
          selected={days}
          horus={horus}
          setHours={setHours}
          startTime={startTime}
          endTime={endTime}
          setDays={setDays}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          selectedDays={selectedDays}
          selectedHours={selectedHours}
          setSelectedDays={setSelectedDays}
          setSelectedHours={setSelectedHours}
          address={address}
          duration={duration}
          capacity={capacity}
          setCapacity={setCapacity}
          setDuration={setDuration}
          location={location}
          setLocation={setLocation}
          availability={availability}
          serviceId={serviceId}
          setMaxDuration={setMaxDuration}
          maxDuration={maxDuration}
          setDisabledButton={setDisabledButton}
        />
        <PageActions
          primaryAction={{
            id: "submit",
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: imageLoading || addServiceVariantLoading,
            disabled: renderDisabled(),
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push(`/products/service/edit/${productId}?tab=variant`),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(AddServiceVariant), { feature: constant.PRODUCT_ADD_FORM });
