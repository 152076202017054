import React, { useContext } from "react";
import { Card, Layout, Caption, Collapsible, TextContainer, TextStyle } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const Image = () => {
  const { IMAGE } = constant;
  const {
    handleChange,
    image,
    // learnMore
  } = useContext(BrandingContext);
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { updatedAt = "" } = currentUser || {};

  return (
    <Collapsible open>
      <Layout.AnnotatedSection title={cms("section.logo.title")} description={cms("section.logo.description")}>
        <Card
          title={[
            cms("section.logo.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          // actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.logo.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.logo.subDescription")}</TextContainer>
            <br />
            <DropZone
              id="dropZone"
              onAdd={(value) => handleChange(IMAGE, (value && value.length && value[0]) || "", IMAGE)}
              onRemove={() => handleChange(IMAGE, "", IMAGE)}
              fileList={(image !== "" && [image]) || []}
              allowMultiple={false}
            />
            <br />
            <Caption>{cms("section.logo.caption")}</Caption>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(Image), {
  feature: constant.STANDARD_BRANDING,
});
