import React from "react";
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import ManageAddressBook from "app/setup/modules/generic/addressBook/addressBook";

const ProviderAddressBook = () => {
  return <ManageAddressBook />;
};

export default withFeature(withErrorBoundary(ProviderAddressBook), { feature: constant.ADDRESS });
