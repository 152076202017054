import React, { useContext } from "react";
import { Checkbox, Stack, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";

import constant from "lib/constant/constant";

import CarrierService from "./components/carrierService";

const ShippyPro = (props) => {
  const { cms, dropship, shippyProApiKey, setIsError } = useContext(FulfillmentContext);
  const { error, handleShippyPro, handleValidation } = props;
  const { apiKey = "" } = (dropship && dropship.shippyPro) || {};

  return (
    <>
      <TextField
        label={`${cms("label.api")}*`}
        value={apiKey}
        placeholder={cms("placeholder.api")}
        onChange={(value) => handleShippyPro(constant.API_KEY, value)}
        onBlur={() => {
          handleValidation(constant.API_KEY, dropship && dropship.shippyPro && dropship.shippyPro.apiKey);
        }}
        error={error && !apiKey && error.apiKey}
      />
      {shippyProApiKey && (
        <>
          <br />
          <Stack vertical>
            <Stack>
              <Stack.Item fill>
                <Checkbox
                  label={cms("label.orderline")}
                  helpText={cms("helpText.orderline")}
                  checked={dropship && dropship.shippyPro && dropship.shippyPro.isAutoFulfill}
                  onChange={(value) => handleShippyPro(constant.IS_AUTO_FULFILL, value)}
                />
              </Stack.Item>
            </Stack>
            <Stack>
              <Stack.Item>
                <Checkbox
                  label={cms("label.rate")}
                  helpText={cms("helpText.rate")}
                  checked={dropship && dropship.shippyPro && dropship.shippyPro.isShippingRateRequired}
                  onChange={(value) => {
                    handleShippyPro(constant.IS_SHIPPING_RATE_REQUIRED, value);
                    setIsError(!value);
                  }}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </>
      )}

      {!(dropship && dropship.shippyPro && dropship.shippyPro.isShippingRateRequired) && shippyProApiKey && (
        <CarrierService />
      )}
    </>
  );
};

ShippyPro.propTypes = {
  handleShippyPro: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleValidation: PropTypes.func.isRequired,
};

ShippyPro.defaultProps = {
  error: {},
};

export default withFeature(withErrorBoundary(ShippyPro), {
  feature: constant.SHIPPY_PRO,
  // subFeature: constant.ADVANCE_DROPSHIP,
  // subFeatureItem: constant.SHIPPY_PRO,
});
