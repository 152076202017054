import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

import ProviderAnalyticsReport from "app/analytics/modules/provider/report";

const { userKey } = constant;
const { provider } = userKey;

const AnalyticsReport = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === provider) {
      return <ProviderAnalyticsReport />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const AnalyticComponent = getComponent(currentUserRole);
  return AnalyticComponent;
};

export default withErrorBoundary(AnalyticsReport);
