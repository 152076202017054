import React from "react";

import { Activity } from "app/userManagement/modules/generic";

const AdminActivity = () => {
  return (
    <>
      <Activity />
    </>
  );
};

export default AdminActivity;
