import React from "react";

import { ActivityList } from "app/userManagement/modules/generic/index";

const AdminActivityList = () => {
  return (
    <>
      <ActivityList />
    </>
  );
};

export default AdminActivityList;
