/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from "react";
import { DisplayText, Layout, Modal, PageActions, Stack, TextContainer, Thumbnail } from "@shopify/polaris";

import { useQuery, useMutation } from "react-apollo";
import { useLazyQuery } from "@apollo/react-hooks";

import _ from "lodash";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner, Banner } from "lib/components";
import { baseHelper, imageHelper, errorHelper } from "lib/helpers";

import GET_LAKE_PRODUCT_AND_SELLER_PRODUCT from "app/productLake/apollo/queries/getLakeProductAndSellerProduct";
import { APPROVE_ALL_PRODUCT_LAKE_CHANGES, APPROVE_LAKE_SELLER_CHANGES } from "app/productLake/apollo/mutations/index";

import { FETCH_VENDOR } from "app/advanceVendor/apollo/queries";

import Variant from "./subFeature/variant";

import content from "./reviewContent";
import ReviewProduct from "./subFeature/reviewProduct";

// const noImageUrl = "https://via.placeholder.com/80x80/ffffff/000000?text=No+image";

const OperatorProductReview = () => {
  const { match, history, currentUser, cms } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;

  const { labels } = content;

  const [diff, setDiff] = useState({});
  const [keys, setKeys] = useState([]);
  const [vendorProducts, setVendorProducts] = useState({});
  const [vendorBrand, setvendorBrand] = useState({});
  const [sellerProducts, setSellerProducts] = useState({});
  const [isVariant, setIsVariant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [notApproveLoading, setNotApproveLoading] = useState(false);
  const [unselectedKeys, setUnselectedKeys] = useState([]);

  const [active, setActive] = useState(false);
  let isPrice = false;

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { data, loading, error } = useQuery(GET_LAKE_PRODUCT_AND_SELLER_PRODUCT, { variables: { input: { id } } });

  const [approveAllProductLakeChanges] = useMutation(APPROVE_ALL_PRODUCT_LAKE_CHANGES);
  const [approveLakeSeller] = useMutation(APPROVE_LAKE_SELLER_CHANGES);

  const [getUser, { data: userData, error: userError, loading: userLoading }] = useLazyQuery(FETCH_VENDOR, {
    variables: { input: { _id: vendorProducts.ownerId } },
  });

  const objectConversion = (obj) => {
    let tempObj = {};
    Object.keys(obj).forEach((key) => {
      if (["priceId", "inventoryId"].includes(key)) {
        tempObj = Object.assign(tempObj, obj[key]);
      } else if (["optionValues"].includes(key)) {
        obj[key].forEach((value) => {
          const { label = "", optionId = {} } = value;
          const { displayName = "", sortOrder } = optionId || {};
          tempObj = Object.assign(tempObj, { [`option${sortOrder}Val`]: label, [`option${sortOrder}`]: displayName });
        });
      } else {
        tempObj[key] = obj[key];
      }
    });
    return tempObj;
  };

  useEffect(() => {
    const difference = (object, base) =>
      _.transform(object, (result, value, key) => {
        if (value !== base[key]) {
          // eslint-disable-next-line no-param-reassign
          result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
        }
      });

    if (!error && !loading && data && isLoading) {
      setIsLoading(false);
      const resData = baseHelper.getResponseData(data, constant.gql.GET_LAKE_PRODUCT_AND_SELLER_PRODUCT);
      let vendorProduct = { ...resData.product };
      let sellerProduct = { ...resData.sellerProduct };

      const { weight: productWeight, images: vImages = [] } = vendorProduct || {};

      vendorProduct.weight = productWeight || 0;

      const { weight: sellerProductWeight, images: sImages = [] } = sellerProduct || {};

      sellerProduct.weight = sellerProductWeight || 0;

      const vendorProductImages = vImages.filter(
        (imageData) =>
          !sImages.find((image) => image.imageId === imageData.imageId && image.imageUrl === imageData.imageUrl)
      );
      const operatorProductImages = sImages.filter(
        (imageData) =>
          !vImages.find((image) => image.imageId === imageData.imageId && image.imageUrl === imageData.imageUrl)
      );
      vendorProduct.images = vendorProductImages;
      sellerProduct.images = operatorProductImages;

      const sellerImages = (sellerProduct && sellerProduct.images && sellerProduct.images.length) || 0;
      const vendorImages = (vendorProduct && vendorProduct.images && vendorProduct.images.length) || 0;

      const sellerVariants = (sellerProduct && sellerProduct.variants && sellerProduct.variants.length) || 0;
      const vendorVariants = (vendorProduct && vendorProduct.variants && vendorProduct.variants.length) || 0;

      const { images = [] } =
        sellerImages > vendorImages
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      let diffValue =
        sellerVariants > vendorVariants
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      delete diffValue.images;
      diffValue.images = [...(images || [])];
      const variant = !!(sellerVariants || vendorVariants);
      diffValue = objectConversion(diffValue);
      vendorProduct = objectConversion(vendorProduct);
      sellerProduct = objectConversion(sellerProduct);
      const key = _.keys(vendorProduct);
      setDiff(diffValue);
      setKeys(key);
      setVendorProducts(vendorProduct);
      setSellerProducts(sellerProduct);
      setIsVariant(variant);
      getUser();
    }
  }, [data, error, isLoading, keys, loading, getUser]);

  useEffect(() => {
    if (!userError && !userLoading && userData) {
      const responseData = baseHelper.getResponseData(userData, constant.gql.FETCH_VENDOR);
      const { brandName } = responseData;

      setvendorBrand(brandName);
    }
  }, [userData, userError, userLoading]);

  const getDataInHTMLForm = (value) => ({ __html: value });

  const handleChange = (_id, value) => {
    if (value) {
      setActive(true);
    } else {
      // eslint-disable-next-line no-use-before-define
      approveAll(_id, false);
    }
  };

  const getData = (value) => (_.isBoolean(value) && (value ? labels.yes : labels.no)) || value;
  const difference = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (value !== base[key]) {
        // eslint-disable-next-line
        result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
      }
    });

  let variantDiffs = [];
  const getTableRows = (value) => {
    const tableRows = [];

    if (!loading && !error && data) {
      Object.keys(value).map((key) => {
        if (!(vendorProducts[key] || sellerProducts[key])) {
          return null;
        }

        const isVariantPrice = diff && diff.variants.find((item) => item.priceId);
        if (key === "price" || isVariantPrice) {
          isPrice = true;
        }

        if (_.includes(keys, key) && labels[key] && key !== constant.IMAGES && key !== constant.VARIANTS) {
          const isKeyDescription = key === constant.DESCRIPTION;
          // eslint-disable-next-line no-nested-ternary
          const vendorData = getData(vendorProducts[key]);
          // eslint-disable-next-line no-nested-ternary
          const sellerData = getData(sellerProducts[key]);
          if (!(vendorData || sellerData) || vendorData === sellerData) {
            return null;
          }
          if (isVariant && key === constant.QUANTITY) {
            return null;
          }
          const vendorProductData =
            isKeyDescription && vendorData ? (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={getDataInHTMLForm(vendorData)} />
            ) : (
              vendorData || false
            );
          const sellerProductData =
            isKeyDescription && sellerData ? (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={getDataInHTMLForm(sellerData)} />
            ) : (
              sellerData || false
            );

          tableRows.push([labels[key], vendorProductData, sellerProductData, key]);
        }

        if (key === constant.IMAGES && diff.images && diff.images.length) {
          const vImages = [];
          const sImages = [];
          diff.images.map((image, idx) => {
            const vendorImage = vendorProducts.images[idx] && vendorProducts.images[idx].imageUrl;
            const sellerImage = sellerProducts.images[idx] && sellerProducts.images[idx].imageUrl;
            if (vendorImage !== sellerImage) {
              vImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: vendorImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={cms("label.vendorImage")}
                />,
              ]);
              sImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: sellerImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={cms("label.sellerImage")}
                />,
              ]);
            }
            return null;
          });
          if (vImages.length && sImages.length) {
            tableRows.push([labels[key], <Stack>{vImages}</Stack>, <Stack>{sImages}</Stack>, key]);
          }
        }

        const sellerVariantData =
          sellerProducts && sellerProducts.variants && sellerProducts.variants.length ? sellerProducts.variants : null;
        const vendorVariantData =
          vendorProducts && vendorProducts.variants && vendorProducts.variants.length ? vendorProducts.variants : null;
        const sellerVariantLength = sellerProducts && sellerProducts.variants && sellerProducts.variants.length;
        const vendorVariantLength = vendorProducts && vendorProducts.variants && vendorProducts.variants.length;

        if (isVariant) {
          const isSellerHasMoreVariants = sellerVariantLength > vendorVariantLength;
          const sellerVariants = [...(sellerVariantData || [])];
          const vendorVariants = [...(vendorVariantData || [])];
          const compareFromVariants = isSellerHasMoreVariants ? [...sellerVariants] : [...vendorVariants];
          const compareWithVariants = isSellerHasMoreVariants ? [...vendorVariants] : [...sellerVariants];
          const isEqualVariants = sellerVariantLength === vendorVariantLength;

          variantDiffs = compareFromVariants.map((itemVariant) => {
            itemVariant = itemVariant && objectConversion(itemVariant);
            const { weight = {} } = itemVariant || {};
            itemVariant.weight = weight || 0;

            const variantChanges = {};
            let variantWith = false;
            const { sortOrder, _id: variantId = "", providerVariantId = "" } = itemVariant;
            variantChanges.position = sortOrder;
            variantWith = compareWithVariants.find((item) => item.sortOrder === sortOrder);
            variantWith = variantWith && objectConversion(variantWith);
            if (variantWith) {
              const { weight: withWeight = {} } = variantWith || {};
              variantWith.weight = withWeight || 0;
            }

            if (!variantWith) {
              variantChanges[isSellerHasMoreVariants ? "isDeleted" : "isAdded"] = true;
            } else {
              const itemVariantChanges = (itemVariant && itemVariant.images && itemVariant.images.length) || 0;
              const variantWithChanges = (variantWith && variantWith.images && variantWith.images.length) || 0;
              const { images = [] } =
                variantWithChanges > itemVariantChanges
                  ? difference(variantWith, itemVariant)
                  : difference(itemVariant, variantWith);
              variantChanges.variantDiff = difference(itemVariant, variantWith);
              variantChanges.variantDiff.images = images;
              variantChanges.isUpdated = true;
              variantChanges.position = isSellerHasMoreVariants ? itemVariant.sortOrder : variantWith.sortOrder;
            }

            variantChanges.sellerVariant = isSellerHasMoreVariants
              ? { ...itemVariant }
              : (variantWith && { ...variantWith }) || null;
            variantChanges.vendorVariant = isSellerHasMoreVariants
              ? (variantWith && { ...variantWith }) || null
              : { ...itemVariant };

            if (isEqualVariants && !variantWith) {
              const avalaibleVariantIds = compareWithVariants.map((item) => item.providerVariantId);
              let variantAdded = vendorVariants.find(
                (item) => !avalaibleVariantIds.includes(baseHelper.mongoIdAsString(item._id))
              );
              variantAdded = variantAdded && objectConversion(variantAdded);
              if (variantAdded) {
                const extraVariantChange = { ...variantChanges };
                extraVariantChange.sellerVariant = null;
                extraVariantChange.vendorVariant = variantAdded && { ...variantAdded };
                extraVariantChange.isAdded = true;
                delete extraVariantChange.isDeleted;
                delete extraVariantChange.isUpdated;
                return extraVariantChange;
              }
            }

            return variantChanges;
          });
        }
        return null;
      });
    }

    return tableRows;
  };

  const setURL = () => {
    setTimeout(() => {
      history.push("/product-lake");
    }, 2500);
  };

  const onToggleSelect = (key) => {
    const allUnselectedKeys = unselectedKeys;
    const keyIndex = allUnselectedKeys.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      allUnselectedKeys.splice(keyIndex, 1);
    } else {
      allUnselectedKeys.push(key);
    }
    setUnselectedKeys(allUnselectedKeys);
  };

  const approveAll = (_id, isToPublish = true) => {
    setPublishLoading(!isToPublish);
    setApproveLoading(isToPublish);
    setNotApproveLoading(false);

    approveAllProductLakeChanges({ variables: { input: { id: _id, isToPublish } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_ALL_PRODUCT_LAKE_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = constant.SUCCESS;
        bannerData.title = cms("message.success.approved");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = constant.CRITICAL;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setPublishLoading(false);
        setApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
    setActive(false);
  };
  const approveLakeSellerChanges = (_id) => {
    setNotApproveLoading(true);
    setPublishLoading(false);
    setApproveLoading(false);
    approveLakeSeller({ variables: { input: { id: _id } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_LAKE_SELLER_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = constant.SUCCESS;
        bannerData.title = cms("message.error.declined");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = constant.CRITICAL;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setNotApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const rows = getTableRows(diff);

  const { _id } = vendorProducts;

  const rendorPopup = () => {
    return (
      <>
        <Modal
          open={active}
          onClose={() => {
            setActive(false);
          }}
          title={cms("modal.title")}
          primaryAction={{
            content: cms("modal.button.confirm"),
            onAction: () => approveAll(_id, false),
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              onAction: () => setActive(false),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>{cms("modal.message")}</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  if (loading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <Layout.Section>
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ isOpen: false })}
        />
      </Layout.Section>
      <Layout.Section>
        {sellerProducts && sellerProducts.name && <DisplayText size="large">{sellerProducts.name}</DisplayText>}
      </Layout.Section>
      <Layout.Section>
        {rows.map((productData) => {
          return (
            <ReviewProduct
              store={currentUser && currentUser.shop}
              vendor={vendorBrand}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              cms={cms}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...productData}
            />
          );
        })}
        {variantDiffs.map((variantsData) => {
          return (
            <Variant
              store={currentUser && currentUser.shop}
              vendor={vendorBrand}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              cms={cms}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...variantsData}
            />
          );
        })}
      </Layout.Section>
      {rendorPopup()}
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: cms("label.acceptAndPublish"),
            onAction: () => approveAll(_id),
            loading: approveLoading,
            disabled: notApproveLoading || publishLoading,
          }}
          secondaryActions={[
            {
              content: cms("label.acceptAndNotPublish"),
              onAction: () => handleChange(_id, isPrice),
              loading: publishLoading,
              disabled: notApproveLoading || approveLoading,
              destructive: true,
              // size: constant.SLIM,
            },
            {
              content: cms("label.notAccept"),
              onAction: () => approveLakeSellerChanges(_id, false),
              loading: notApproveLoading,
              disabled: approveLoading || publishLoading,
              // size: constant.SLIM,
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductReview), {
  feature: constant.REVIEW_PRODUCT_LAKE,
});
