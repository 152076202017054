import React, { useCallback, useContext, useEffect, useState } from "react";
import { Caption, Link, Modal, ResourceList, Stack, TextField, TextStyle, Thumbnail, Badge } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";
import { FieldSetQty, StyledModal } from "./modalStyle";

const EditModal = (props) => {
  const {
    active,
    bulkEditItem,
    bulkEditLoading,
    isBulkChecked,
    orderLineItemsData,
    refetchOrderLineView,
    refetchOrderRefund,
    refetchOrderView,
    selectedItems,
    isDynamicDiscount,
    setActive,
    setBanner,
    setMessage,
    setSelectedItems,
  } = props;

  const [filteredItems, setFilteredItems] = useState([]);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { gql } = constant;
  const { BULK_ORDER_EDIT } = gql;
  // eslint-disable-next-line no-unused-vars
  const isVendor = baseHelper.isVendor(currentUser);

  useEffect(() => {
    if (selectedItems) {
      const arr = [];
      selectedItems.forEach((element) => {
        const items = orderLineItemsData.find((item) => item._id === element);
        if (items) {
          const { fulfillableQuantity } = items;
          arr.push({ ...items, totalQuantity: fulfillableQuantity });
        }
      });
      setFilteredItems(arr);
    }
  }, [active]);

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };
  const handleChange = useCallback(() => {
    setFilteredItems([]);
    setActive(!active);
    if (!isBulkChecked) {
      setSelectedItems([]);
    }
  }, [active]);

  const handleQuantityChange = (id, value) => {
    const items = filteredItems;
    const index = items.findIndex((item) => item._id === id);
    items[index] = { ...items[index], fulfillableQuantity: value };
    setFilteredItems([...items]);
  };

  const buttonDisable = () => {
    let count = 0;
    filteredItems.forEach((item) => {
      const { fulfillableQuantity } = item;
      if (fulfillableQuantity === "") {
        count += 1;
      }
    });
    return count === filteredItems.length;
  };

  const handleEdit = async () => {
    const bulkEdit = {
      orderId: "",
      bulkQuantity: [],
    };

    filteredItems
      .filter((item) => item.fulfillableQuantity !== "")
      .forEach((item) => {
        const { _id, fulfillableQuantity, orderId, totalQuantity } = item;
        bulkEdit.orderId = orderId.toString();
        bulkEdit.bulkQuantity.push({
          id: _id,
          quantity: parseInt(fulfillableQuantity, 10),
          totalQuantity: totalQuantity.toString(),
        });
      });
    if (!isBulkChecked) {
      bulkEdit.isSingle = true;
    }
    if (isDynamicDiscount) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("message.error.discountApplied"),
        isOpen: true,
      });
      setActive(!active);
    } else {
      await bulkEditItem({ variables: { input: { ...bulkEdit } } })
        .then((response) => {
          setActive(!active);
          setFilteredItems([]);
          setSelectedItems([]);
          const responseError = baseHelper.getResponseError(response.data, BULK_ORDER_EDIT);
          if (responseError) {
            setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          }
          const responseData = baseHelper.getResponseData(response.data, BULK_ORDER_EDIT);
          const { bulkQuantity = [] } = bulkEdit;
          const { quantity = 0, totalQuantity = 0 } = bulkQuantity[0];
          const quantityChange = totalQuantity - quantity;
          if (responseData) {
            if (isBulkChecked) {
              setMessage(cms("message.success.updateInventory"));
            } else {
              setMessage(
                `${cms("message.success.inventory", { item: quantity })} ${
                  quantityChange > 0 ? `, ${cms("message.success.manageTransaction")}` : "."
                }`
              );
            }
            setTimeout(() => {
              refetchOrderLineView();
              refetchOrderRefund();
              refetchOrderView();
            }, 5000);
          }
        })
        .catch((exception) => {
          setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
          setActive(!active);
          setFilteredItems([]);
          setSelectedItems([]);
        });
    }
  };

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={cms("label.adjustQuantity")}
      primaryAction={{
        content: cms("common.button.update"),
        onAction: () => {
          handleEdit();
        },
        disabled: buttonDisable() || bulkEditLoading,
        loading: bulkEditLoading,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: handleChange,
        },
      ]}
    >
      <ResourceList
        items={filteredItems}
        renderItem={(item) => {
          const {
            _id,
            fulfillableQuantity,
            fulfillmentType,
            image,
            name,
            productId,
            productQuantity,
            status,
            variantTitle,
          } = item || {};

          const badgeStatus =
            baseHelper.getBadgeStatus(fulfillmentType) === constant.PICKUP
              ? constant.CONSIGNMENT_PICKUP_CAPITAL
              : baseHelper.getBadgeStatus(fulfillmentType);
          const lineImage =
            imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
          return (
            <Modal.Section>
              <StyledModal>
                <Stack id={_id}>
                  <Stack.Item>
                    <Thumbnail size="small" source={lineImage} alt={name} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Stack.Item>
                      {name || "" || (
                        <Link onClick={() => history.push(`/products/edit/${productId}`)}>{name || ""}</Link>
                      )}
                      {variantTitle && (
                        <Caption>
                          <TextStyle variation={constant.SUBDUED}>{variantTitle.toUpperCase()}</TextStyle>
                        </Caption>
                      )}
                    </Stack.Item>
                    <Stack.Item>
                      {fulfillmentType && (
                        <Badge className="text-capitalize">
                          {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                        </Badge>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                  <FieldSetQty>
                    <Stack>
                      <Stack vertical spacing="extraTight" alignment="center">
                        <TextStyle>{cms("label.available")}</TextStyle>
                        <TextStyle>{productQuantity}</TextStyle>
                      </Stack>
                      <Stack vertical spacing="extraTight" alignment="center">
                        <TextStyle>{cms("label.quantity")}</TextStyle>
                        <TextField
                          id={_id}
                          value={(fulfillableQuantity && fulfillableQuantity.toString()) || ""}
                          min={0}
                          max={productQuantity}
                          placeholder={constant.value.ZERO}
                          onChange={(value) => [
                            value <= parseInt(productQuantity, 10) &&
                              handleQuantityChange(_id, acceptOnlyValidInput(value, fulfillableQuantity)),
                          ]}
                          align="center"
                        />
                      </Stack>
                    </Stack>
                  </FieldSetQty>
                </Stack>
              </StyledModal>
            </Modal.Section>
          );
        }}
      />
    </Modal>
  );
};

export default withErrorBoundary(EditModal);
