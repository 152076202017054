import { gql } from "apollo-boost";

const GET_MANAGE_PRICE_SETTING = gql`
  query {
    getManagePriceSetting {
      data {
        isUpdatePrice
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_MANAGE_PRICE_SETTING;
