import React, { useState, useCallback, useContext } from "react";
import { DropZone, Caption, Stack, Thumbnail, Button, Card, TextStyle } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import Banner from "../banner/banner";

const FileUpload = (props) => {
  const { cms } = useContext(PrivateContext);
  const [files, setFiles] = useState([]);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const { setFileData, setIsSubmitDisabled, isLabelVisible = true, exportAction } = props;

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      setBanner({ isOpen: false });
      const { name } = acceptedFiles[0];
      const extension = name.split(".").pop();
      const isExtensionValid = extension.toLowerCase() === constant.CSV;
      if (!isExtensionValid) {
        setBanner({ title: cms("common.message.error.selectCSV"), status: constant.CRITICAL, isOpen: true });
        props.setValue("");
        return;
      }
      setFiles((file) => [...file, ...acceptedFiles]);
      setIsSubmitDisabled(false);
    },
    [props]
  );

  const validImageTypes = ["file/csv"];
  const removeFile = (file) => {
    const updatedFiles = [...files];
    const index = updatedFiles.indexOf(file);
    if (index < 0) {
      return;
    }
    if (index === 0) {
      setFileData(null);
    }
    updatedFiles.splice(index, 1);
    props.setValue({});
    setFiles([]);
  };

  const fileReader = (newFile) => {
    const reader = new FileReader();
    reader.readAsBinaryString(newFile);
    const values = {};
    reader.onload = (readerEvent) => {
      values.data = readerEvent.target.result;
      values.fileName = newFile.name;
      props.setValue(values);
    };
  };

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file) => (
        <Stack alignment="center" vertical key="uploadedImages">
          <Thumbnail
            size="large"
            alt={file.name}
            source={validImageTypes.indexOf(file.type) > 0 ? window.URL.createObjectURL(file) : constant.imageURL.csv}
          />
          {!Object.values(props.values).length && fileReader(file)}
          <div>
            {file.name}
            <Caption>
              {file.size}
              bytes
            </Caption>
          </div>
          <Stack.Item>
            <Button onClick={() => removeFile(file)}>
              <i className="far fa-trash fa-lg" style={{ color: cms("common.uploadCSV.color.remove") }} />
            </Button>
          </Stack.Item>
        </Stack>
      ))}
    </Stack>
  );
  return (
    <Card
      title={<TextStyle>{`${cms("common.uploadCSV.title")}*`}</TextStyle>}
      actions={
        !isLabelVisible
          ? (exportAction && [exportAction]) || []
          : [
              {
                content: cms("common.uploadCSV.downloadCSV"),
                onAction: () => (props.downloadCSV && window.open(props.downloadCSV)) || props.getCSV(),
              },
            ]
      }
    >
      {banner.isOpen && (
        <Banner status={banner.status} title={banner.title} isOpen={banner.isOpen} isScrollTop={false} />
      )}
      <Card.Section>
        {uploadedFiles}
        {!uploadedFiles && (
          <DropZone allowMultiple={false} onDrop={handleDropZoneDrop} type="file">
            {fileUpload}
          </DropZone>
        )}
      </Card.Section>
    </Card>
  );
};

FileUpload.propTypes = {
  setValue: PropTypes.func.isRequired,
  downloadCSV: PropTypes.string,
  getCSV: PropTypes.func,
  setFileData: PropTypes.func,
  setIsSubmitDisabled: PropTypes.func,
  values: PropTypes.shape(PropTypes.object),
};
FileUpload.defaultProps = {
  downloadCSV: "",
  getCSV: () => {},
  setFileData: () => {},
  setIsSubmitDisabled: () => {},
  values: {},
};

export default FileUpload;
