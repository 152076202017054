import styled from "styled-components";

const StyledSlip = styled.div`
  padding: 20px;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  background: white;
  font-family: ${(props) => props.font || ""};
  .text-uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .title {
    font-weight: 600;
    font-size: 12.5px;
  }
  .brand-logo {
    max-width: 200px;
    max-height: 50px;
  }
  .invoice-content {
    margin-bottom: 80px;
  }
  .table {
    margin-bottom: 15px;
    th,
    td {
      padding: 12px 20px;
      text-align: right;
      border-top: 1px solid #dee2e6;
      font-size: 16px;
      text-transform: capitalize;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }
    tr:last-child {
      td,
      th {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
  thead {
    border: 1px solid #dee2e6;
  }
  address,
  .invoice-detail {
    font-style: normal;
    line-height: 1.5;
    font-size: 16px;
    text-transform: capitalize;
  }
  .invoice-company {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .invoice-deduction {
    color: #726f6f;
    font-style: italic;
    height: 55px;
  }
  .invoice-header {
    background: #f0f3f4;
    padding: 20px 0;
    margin: 0 -20px 20px;
  }
  .invoice-date,
  .invoice-from,
  .invoice-to {
    display: table-cell;
    width: 1%;
  }
  .invoice-from,
  .invoice-to {
    padding: 0 20px;
  }
  .invoice-date .date,
  .invoice-from strong,
  .invoice-to strong {
    font-size: 16px;
    font-weight: 600;
  }
  .invoice-date {
    text-align: right;
    padding: 0 20px;
  }
  .invoice-summary {
    background: #f0f3f4;
    width: 100%;
    margin-bottom: 15px;
  }
  .description {
    opacity: 0.6;
    font-size: 15px;
    margin-top: -8px;
    margin-left: 18px;
    word-break: break-all;
  }
  .invoice-price {
    // border: 1px solid #dee2e6;
    background: #f0f3f4;
    // display: flex;
    // width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin: -20px;
    margin-bottom: 15x;
    .label {
      text-transform: capitalize;
      margin-bottom: 5px;
    }
    .label-bold {
      text-transform: capitalize;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .invoice-price-left {
      width: 100%;
      font-size: 16px;
      font-weight: 100;
    }
    .invoice-price-right {
      background: 1px solid #ccc;
      color: #444141;
      font-size: 16px;
      font-weight: bolder;
      padding-right: 20px;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      small {
        text-transform: uppercase;
        display: inline-block;
        text-align: right;
        // opacity: 0.6;
        font-size: 16px;
        margin-bottom: 5px;
        color: #202223;
        font-weight: bolder;
      }
    }
    .invoice-price-left .sub-price {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    small {
      font-weight: 400;
    }
    .invoice-price-row {
      padding: 0 20px;
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      // justify-content: space-between;
      .text-inverse small {
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  .invoice-footer {
    border-top: 1px solid #ddd;
    padding: 10px;
    font-size: 10px;
    text-align: center;
  }
  .invoice-footer-content {
    padding: 20px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .invoice-note1,
  .invoice-note {
    display: table-cell;
    width: 1%;
    flex: 2;
    margin: 15px;
  }
  .note {
    text-align: left;
    color: #666666;
    font-size: 14px;
    width: 35%;
  }
  .note-font {
    font-size: 14 px;
  }
  .invoice-note-title {
    text-align: center;
  }
  .invoice-note-content {
    text-align: left;
    padding: 10px;
  }
  .invoice-note1 {
    text-align: right;
    padding: 0 20px 15px;
    font-size: 15px;
  }
  .sub-price .fa-plus {
    color: #a1a4a6;
  }
  .line-remove td {
    border: 0;
  }
  .line-remove th {
    border: 0;
  }
  .sub-price .fa-equals {
    color: #a1a4a6;
  }
  .sub-price .fa-minus {
    color: #a1a4a6;
  }
  .line-remove td {
    border: 0;
  }
  .line-remove th {
    border: 0;
  }
  .sub-price .fa-equals {
    color: #a1a4a6;
  }
  .invoice > div:not(.invoice-footer) {
    margin-bottom: 20px;
  }
  .btn-white {
    color: #2d353c;
    background: #fff;
    border: 1px solid #d9dfe3;
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    border-radius: 4px;
    .fa {
      font-size: 15px;
    }
  }
  .extra-charges {
    padding: 15px;
    text-align: right;
    .label {
      text-transform: uppercase;
      font-size: 12px;
    }
    .sub-price {
      display: inline-block;
      vertical-align: top;
      margin-left: 30px;
    }
  }
`;
export default StyledSlip;
