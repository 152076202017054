import PropTypes from "prop-types";

const priceProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
    handleMarkup: PropTypes.func,
    handleDiscount: PropTypes.func,
  },
};
const titleProp = {
  type: {
    handleChange: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.func,
  },
};
const imageProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
  },
};
const organizationProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
    handleChange: PropTypes.func,
  },
};
const variantsProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
    handleMarkup: PropTypes.func,
    currentUser: PropTypes.object,
  },
};
const shippingProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
    handleChange: PropTypes.func,
  },
};
const inventoryProp = {
  type: {
    data: PropTypes.object,
    t: PropTypes.func,
    handleChange: PropTypes.func,
  },
};
export { titleProp, imageProp, priceProp, variantsProp, organizationProp, inventoryProp, shippingProp };
