import { gql } from "apollo-boost";

const BULK_SELLER_ACTION = gql`
  mutation bulkSellerAction($input: BulkAction!) {
    bulkSellerAction(input: $input) {
      data
      status
      error
    }
  }
`;

const PROXY_LOGIN = gql`
  mutation proxyLogin($input: ProxyLogin) {
    proxyLogin(input: $input) {
      data {
        token
      }
      status
      error
    }
  }
`;

export { BULK_SELLER_ACTION, PROXY_LOGIN };
