import React, { useState } from "react";
import { Banner, Button, Link, Select, Stack } from "@shopify/polaris";

import constant from "lib/constant/constant";
// eslint-disable-next-line import/no-cycle
import { withErrorBoundary } from "lib/hoc";

const Add = (props) => {
  const { cms, currency, isPaySelectedVendor, payout, setPayout, setSubmitEnable, vendorList } = props;
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  let isInvalid = false;
  const removeItem = (id) => {
    const payoutItem = [...payout];
    payoutItem.splice(id, 1);
    setPayout(payoutItem);
    setSubmitEnable(true);
    if (!isInvalid) {
      setBanner({
        isOpen: false,
        title: cms("common.message.error.invalidRowDetails"),
        status: constant.CRITICAL,
      });
      return false
    }
  };

  const handleChange = (field, value, index) => {
    const payoutItem = [...payout];
    payoutItem[index][field] = value;
    setPayout([...payoutItem]);
    setSubmitEnable(true);
    if (!isInvalid) {
      setBanner({
        isOpen: false,
        title: cms("common.message.error.invalidRowDetails"),
        status: constant.CRITICAL,
      });
      return false
    }

  };

  const selectVendor = (data, id) => {
    return (
      <>
        <br />
        <Stack wrap={false} spacing="extraTight" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={vendorList}
                placeholder={cms("placeholder.vendor")}
                value={payout && payout.length && payout[id].vendorId}
                onChange={(value) => handleChange(constant.VENDOR_ID, value, id)}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={currency || []}
                placeholder={cms("placeholder.currency")}
                value={payout && payout.length && payout[id].currencyCode}
                onChange={(value) => handleChange(constant.CURRENCY_CODE, value, id)}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button plain onClick={() => removeItem(id)}>
              <i className="far fa-trash fa-lg" style={{ color: constant.color.RED }} />
            </Button>
          </Stack.Item>
        </Stack>
      </>
    );
  };

  const addItem = () => {
    const payoutItem = [...payout];

    if (payoutItem.length > 0) {
      const item = payoutItem[payoutItem.length - 1];
      const keys = Object.keys(item);
      keys.forEach((key) => {
        if (!item[key]) {
          isInvalid = true;
        }
      });

      if (isInvalid) {
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
      } else
        payoutItem.push({
          currencyCode: "",
          vendorId: "",
        });
      setPayout(payoutItem);
    }

    if (!payoutItem.length) {
      setPayout([{ currencyCode: "", vendorId: "" }]);
    }
  };

  return (
    <div>
      {banner.isOpen && (
        <>
          <br />
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </>
      )}
      {isPaySelectedVendor &&
        payout &&
        payout.map((value, index) => {
          return selectVendor(value, index);
        })}
      <br />
      {isPaySelectedVendor && (
        <Link id="addLink" onClick={() => addItem()}>
          {cms("common.button.addMore")}
        </Link>
      )}
    </div>
  );
};
export default withErrorBoundary(Add);
