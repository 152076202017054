import { gql } from "apollo-boost";

const ADD_VARIANT = gql`
  mutation addVariant($input: AddVariant) {
    addVariant(input: $input) {
      data {
        id
        position
      }
      status
      error
    }
  }
`;

export { ADD_VARIANT };
