import React, { useCallback, useContext, useEffect, useState } from "react";
import { Badge, Caption, Card, Collapsible, Layout, SkeletonBodyText, Stack, TextStyle } from "@shopify/polaris";
import { useQuery } from "react-apollo";

// context
import { PrivateContext } from "lib/context";

import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner, MultiCarousel, ReportCardSkeleton } from "lib/components";

import { GET_ALL_COUNTS, GET_LAKE_COUNTS } from "app/reports/apollo/queries";
import { ORDER_DATA, PRODUCT_DATA, SHOPIFY_PRODUCT_DATA } from "app/reports/apollo/subscriptions";

import ReportCard from "app/reports/generic/style/reportCard";
import config from "./quickReportConfig";

const {
  gql: { GET_COUNT, GET_LAKE_COUNT, ORDER_COUNT, PRODUCT_COUNT, SHOPIFY_PRODUCT_COUNT },
  DOWN,
  UP,
} = constant;

const ProviderQuickReport = () => {
  const { cms, currentUser, match, vendorHiddenModule = [] } = useContext(PrivateContext);
  const { ecommercePlatform } = currentUser;
  const { path } = match || {};
  const { data: dashboardData, loading: dashboardLoading, error: dashboardError, subscribeToMore } = useQuery(
    [constant.WOOCOMMERCE, constant.BIGCOMMERCE].includes(ecommercePlatform) ? GET_LAKE_COUNTS : GET_ALL_COUNTS
  );

  const [openQuickReport, setQuickReport] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [count, setCount] = useState({
    orderCount: {},
    paymentCount: {},
    productCount: {},
    wooTempProductCount: {},
    wixTempProductCount: {},
    shopifyProductCount: {},
    prestaShopTempProductCount: {},
    magentoTempProductCount: {},
  });
  useEffect(() => {
    if (dashboardError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(dashboardError),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardError, errorHelper]);
  useEffect(() => {
    const responseData = baseHelper.getResponseData(
      dashboardData,
      [constant.WOOCOMMERCE, constant.BIGCOMMERCE].includes(ecommercePlatform) ? GET_LAKE_COUNT : GET_COUNT
    );
    const responseError = baseHelper.getResponseError(
      dashboardData,
      [constant.WOOCOMMERCE, constant.BIGCOMMERCE].includes(ecommercePlatform) ? GET_LAKE_COUNT : GET_COUNT
    );
    if (responseError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const {
      orderCount = null,
      paymentCount = null,
      productCount = null,
      prestaShopTempProductCount = null,
      magentoTempProductCount = null,
      wooTempProductCount = null,
      wixTempProductCount = null,
      shopifyProductCount = null,
    } = responseData;
    setCount({
      orderCount: orderCount || {},
      paymentCount: paymentCount || {},
      productCount: productCount || {},
      prestaShopTempProductCount: prestaShopTempProductCount || {},
      magentoTempProductCount: magentoTempProductCount || {},
      wooTempProductCount: wooTempProductCount || {},
      wixTempProductCount: wixTempProductCount || {},
      shopifyProductCount: shopifyProductCount || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const createSubscribeToMore = useCallback(
    ({ document, responseKey }) => {
      subscribeToMore({
        document,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }
          const responseData = baseHelper.getResponseData(subscriptionData.data, responseKey);
          if (!responseData) {
            const responseError = baseHelper.getResponseError(subscriptionData.data, responseKey);
            setBanner({
              isOpen: true,
              status: constant.CRITICAL,
              title: responseError,
            });
            return null;
          }
          setCount((prevState) => ({
            ...prevState,
            [responseKey]: responseData[responseKey] || {},
          }));
          return true;
        },
      });
    },
    [subscribeToMore]
  );

  useEffect(() => {
    switch (path) {
      case "/":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        break;
      case "/orders":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        break;
      case "/products":
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        break;
        case "/products/shopify":
        createSubscribeToMore({ document: SHOPIFY_PRODUCT_DATA, responseKey: SHOPIFY_PRODUCT_COUNT });
        break;
      default:
        break;
    }
  }, [createSubscribeToMore, path]);

  // data taken from report config
  const quickReportData = config({ cms, count, path });

  const isHome = path === "/";
  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  // const noDataAvailable = (
  //   <Card.Section subdued key="noData">
  //     <Stack>
  //       <Stack.Item>No data available</Stack.Item>
  //     </Stack>
  //   </Card.Section>
  // );

  const getQuickReportList = () => {
    if (!quickReportData) {
      return "";
    }
    const loading = <SkeletonBodyText lines={3} />;
    const dataStack = (quickReport) =>
      (isHome && (
        <Stack>
          <Stack.Item fill>
            <TextStyle>{quickReport.label}</TextStyle>
            <Caption>{quickReport.caption}</Caption>
          </Stack.Item>
          <Stack.Item>
            <Badge status={constant.SUCCESS}>{quickReport.count}</Badge>
          </Stack.Item>
        </Stack>
      )) || (
        <Card>
          <TextStyle variation="strong">{quickReport.count}</TextStyle>
          <span className="card-title">{quickReport.label}</span>
        </Card>
      );
    const reportData = quickReportData.map((quickReport, index) => {
      const { count: reportCount = null, label = "" } = quickReport;
      if (!reportCount && reportCount !== 0) {
        return null;
      }
      if (!vendorHiddenModule.includes(label)) {
        return (
          <>
            {isHome && index > 0 && index !== quickReportData.length && <br />}
            {dashboardLoading ? loading : dataStack(quickReport)}
          </>
        );
      }
      return null;
    });

    const filteredReportData = reportData.filter((data) => data);

    if (filteredReportData.length) {
      // return filteredReportData;
      return (
        (isHome && filteredReportData) || (
          <ReportCard>
            <MultiCarousel containerClass="quick-reports" slideItems={filteredReportData} />
          </ReportCard>
        )
      );
    }
    return null;
  };

  const home = () => {
    return (
      (isHome && dashboardLoading && (
        <>
          <br />
          <SkeletonBodyText lines={3} />
        </>
      )) || (
        <Card>
          <Card.Header
            title={cms("common.label.quickReport")}
            actions={[
              {
                content: openQuickReport ? cms("common.label.hide") : cms("common.label.show"),
                onAction: () => setQuickReport(!openQuickReport),
                disclosure: openQuickReport ? UP : DOWN,
              },
            ]}
          />
          <br />
          <Collapsible open={openQuickReport} id="collapsibleQuickReport">
            <Card>
              <Card.Section subdued>{getQuickReportList()}</Card.Section>
            </Card>
          </Collapsible>
        </Card>
      )
    );
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout>
            <Layout.Section>
              <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
            </Layout.Section>
          </Layout>
          <br />
        </>
      )}
      {(isHome && home()) ||
        (dashboardLoading && <ReportCardSkeleton cardCount={quickReportData && quickReportData.length} />) ||
        getQuickReportList()}
    </>
  );
};

export default ProviderQuickReport;
