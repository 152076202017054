import { gql } from "apollo-boost";

const GET_VENDOR_PROFILE = gql`
  query getVendorProfile($input: VendorProfileData!) {
    getVendorProfile(input: $input) {
      data {
        vendorRow {
          _id
          rejectCount
          firstName
          lastName
          email {
            address
          }
          phoneNumber
          mobile {
            number
            verified
          }
          address
          city
          country
          createdAt
          pinCode
          provinceCode
          brandName
          brand {
            slug
          }
          isApproved
          isReadOnly
          rejectCount
          updatedAt
        }
      }
      status
      error
    }
  }
`;

export default GET_VENDOR_PROFILE;
