import { gql } from "apollo-boost";

const DISCONNECT_BIGCOMMERCE_SHOP = gql`
  mutation disconnectBigcommerceShop($input: DisconnectShop) {
    disconnectBigcommerceShop(input: $input) {
      status
      data
      error
    }
  }
`;

const IS_BIGCOMMERCE_STORE_CONNECTED = gql`
  mutation isBigCommerceStoreConnected {
    isBigCommerceStoreConnected {
      status
      data
      error
    }
  }
`;

const SAVE_BIGCOMMERCE_STORE_DETAILS = gql`
  mutation saveBigCommerceStoreDetails($input: saveBigCommerceStoreDetails) {
    saveBigCommerceStoreDetails(input: $input) {
      status
      data
      error
    }
  }
`;

const SYNC_VENDOR_BIGCOMMERCE_PRODUCT = gql`
  mutation syncVendorBigcommerceProduct {
    syncVendorBigcommerceProduct {
      status
      data
      error
    }
  }
`;

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const UPDATE_BIGCOMMERCE_PRODUCT_SYNC = gql`
  mutation updateBigCommerceProductSync($input: updateBigCommerceProductSync!) {
    updateBigCommerceProductSync(input: $input) {
      status
      data
      error
    }
  }
`;

const SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT = gql`
  mutation syncTempVendorBigcommerceProduct($input: BulkAction!) {
    syncTempVendorBigcommerceProduct(input: $input) {
      status
      data
      error
    }
  }
`;

export {
  DISCONNECT_BIGCOMMERCE_SHOP,
  IS_BIGCOMMERCE_STORE_CONNECTED,
  SAVE_BIGCOMMERCE_STORE_DETAILS,
  SYNC_INVENTORY_VENDOR,
  SYNC_VENDOR_BIGCOMMERCE_PRODUCT,
  UPDATE_BIGCOMMERCE_PRODUCT_SYNC,
  SYNC_TEMP_VENDOR_BIGCOMMERCE_PRODUCT,
};
