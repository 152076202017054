import { gql } from "apollo-boost";

const SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT = gql`
  mutation syncVendorLakeProduct($input: BulkAction!) {
    syncVendorLakeProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_VENDOR_LAKE_SHOPIFY_PRODUCT;
