import { gql } from "apollo-boost";

const GET_FEATURE_FLAG = gql`
  query getFeature($input: GetFeature) {
    getFeature(input: $input) {
      error
      status
      data {
        _id
        moduleId
        parentId
        key
        label
        description
        hide {
          permission {
            level
            role
          }
        }
        user {
          permission {
            level
            role
          }
          tier
          group {
            seller
            vendor
            systemAdmin
          }
        }
        version
        time {
          duration {
            date
            days
          }
        }
        location {
          country
          region
          city
        }
        device {
          web {
            browser
            os
          }
          mobile {
            browser
            os
          }
        }
        features {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
        }
        dependency {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
          features {
            _id
            moduleId
            parentId
            key
            label
            description
            hide {
              permission {
                level
                role
              }
            }
            user {
              permission {
                level
                role
              }
              tier
              group {
                seller
                vendor
                systemAdmin
              }
            }
            version
            time {
              duration {
                date
                days
              }
            }
            location {
              country
              region
              city
            }
            device {
              web {
                browser
                os
              }
              mobile {
                browser
                os
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_FEATURE_FLAG;
