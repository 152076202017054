import { gql } from "apollo-boost";

const GENERATE_SHIPPING_LABEL = gql`
  mutation generateShippingLabel($input: ShippingLabelInput!) {
    generateShippingLabel(input: $input) {
      status
      data
      error
    }
  }
`;

export default GENERATE_SHIPPING_LABEL;
