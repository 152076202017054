import { gql } from "apollo-boost";

const GET_ORDER_ACS_SETTING = gql`
  query getACSSetting {
    getACSSetting {
      status
      error
      data {
        userName
        password
        updatedAt
      }
    }
  }
`;

export default GET_ORDER_ACS_SETTING;
