import { gql } from "apollo-boost";

const CONTACT_US = gql`
  mutation sendHelpMessage($input: [SendHelpMessage]!) {
    sendHelpMessage(input: $input) {
      status
      error
      data
    }
  }
`;

export default CONTACT_US;
