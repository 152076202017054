import { gql } from "apollo-boost";

const LOGIN_WOOCOMMERCE_SELLER = gql`
  mutation loginWooCommerceSeller($input: LoginWooCommerceSeller) {
    loginWooCommerceSeller(input: $input) {
      data {
        token
      }
      error
      status
    }
  }
`;

const REGISTER_WOOCOMMERCE_SELLER = gql`
  mutation registerWooCommerceSeller($input: RegisterWooCommerceSeller) {
    registerWooCommerceSeller(input: $input) {
      data {
        parentToken
        token
      }
      error
      status
    }
  }
`;

const REGISTER_WOOCOMMERCE_WEBHOOK = gql`
  mutation registerWebhook {
    registerWebhook {
      status
      data
      error
    }
  }
`;

export { LOGIN_WOOCOMMERCE_SELLER, REGISTER_WOOCOMMERCE_SELLER, REGISTER_WOOCOMMERCE_WEBHOOK };
