import React, { useEffect } from "react";
import { ApolloProvider } from "react-apollo";
import { AppProvider } from "@shopify/polaris";

// import "@shopify/polaris/styles.css";

import "./i18n";

import config from "configuration";
import Routes from "./routes";
import client from "./apollo";

const App = () => {
  // will work like componentDidMount
  useEffect(() => {
    if (config.localizeAPIKey) {
      window.Localize.initialize({
        key: config.localizeAPIKey,
        rememberLanguage: true,
      });
    }
    // if (config.beaconLiveChatAPIKey) {
    //   window.Beacon('init', config.beaconLiveChatAPIKey);
    // }
  }, []);

  return (
    <ApolloProvider client={client}>
      <AppProvider>
        <Routes />
      </AppProvider>
    </ApolloProvider>
  );
};

export default App;
