import { gql } from "apollo-boost";

const GET_KEY_TAKEAWAY_REPORT_LIST = gql`
  query getKeyTakeAwayReportList {
    getKeyTakeAwayReportList {
      status
      error
      data {
        size {
          count
          name
        }
        color {
          count
          name
        }
        productType {
          count
          name
        }
        orderCount
      }
    }
  }
`;

const GET_PRODUCT_REPORT_LIST = gql`
  query getProductReportList {
    getProductReportList {
      status
      error
      data {
        topPerformingProducts {
          title
          productCount
          totalQuantity
          orderCount
          image
        }
        bottomPerformingProducts {
          title
          orderCount
          image
        }
        stockUtilisationRate
      }
    }
  }
`;

export { GET_KEY_TAKEAWAY_REPORT_LIST, GET_PRODUCT_REPORT_LIST };
