import { gql } from "apollo-boost";

const CREATE_API_REQUEST_ACCESS = gql`
  mutation createApiRequestAccess($input: CreateApiRequestAccess) {
    createApiRequestAccess(input: $input) {
      status
      data
      error
    }
  }
`;
export default CREATE_API_REQUEST_ACCESS;
