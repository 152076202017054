import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import ProductTagSetting from "app/setup/modules/operator/features/productTagSetting/productTagSetting";

const { userKey } = constant;
const { operator } = userKey;

const TagSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <ProductTagSetting />;
    }
    return null;
  };

  const ProductTagComponent = getComponent(currentUserRole);
  return ProductTagComponent;
};

export default withErrorBoundary(TagSetting);
