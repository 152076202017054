import React, { useContext } from "react";
import { OperatorNotification } from "app/userManagement/modules/operator/features";
import { ProviderNotification } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator, provider } = userKey;

const Notification = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorNotification />;
    }
    if (role === provider) {
      return <ProviderNotification />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const NotificationComponent = getComponent(currentUserRole);
  return NotificationComponent;
};

export default Notification;
