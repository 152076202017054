import { gql } from "apollo-boost";

const REGISTER = gql`
  mutation registerEntry($input: Credential!) {
    register(input: $input) {
      data
      status
      error
    }
  }
`;

export default REGISTER;
