import React, { useContext, useEffect, useState } from "react";
import { PrivateContext } from "lib/context";
import { useMutation, useQuery } from "react-apollo";

import { Layout, Card, List, Stack, DisplayText, Badge, Button } from "@shopify/polaris";

import { FETCH_SELLER_PLANS } from "app/plans/apollo/queries";
import { UPDATE_PLAN_DETAILS } from "app/plans/apollo/mutations";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { GET_ALL_COUNTS } from "app/reports/apollo/queries";
import { withErrorBoundary } from "lib/hoc";
import { Banner } from "lib/components";
import { Skeleton } from "app/plans/modules/vendor/features/components/index";

const ProviderUpdatePlanStripe = () => {
  const { cms, currentUser, currentUserRefetch, associatedSeller = {}, isLoading, history } = useContext(
    PrivateContext
  );

  const [updatePlan, { loading }] = useMutation(UPDATE_PLAN_DETAILS);

  const [error, setError] = useState("");
  const [activePlan, setActivePlan] = useState("");

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const {
    gql: { GET_COUNT },
  } = constant;

  const { error: planError, loading: planLoading, data: planData } = useQuery(FETCH_SELLER_PLANS);
  const { isStripeSubscription = false } = associatedSeller || {};
  const [count, setCount] = useState(0);

  const { plan = {} } = currentUser || {};

  const { code: currentPlan = "", chargeId, isChargebee = false } = plan || {};

  const { data: dashboardData } = useQuery(GET_ALL_COUNTS);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(dashboardData, GET_COUNT);
    if (responseData) {
      const { productCount = null } = responseData;
      setCount(productCount.total);
    }
    if (currentPlan) {
      setActivePlan(currentPlan);
    }
  }, [currentPlan, dashboardData, GET_COUNT]);

  const dataToSend = {};

  if (chargeId && isStripeSubscription) {
    dataToSend.chargeId = chargeId;
  }

  const onSubmit = (plan) => {
    dataToSend.code = plan.code;

    updatePlan({
      variables: {
        input: dataToSend,
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PLAN_DETAILS);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PLAN_DETAILS);

        if (responseData) {
          setBanner({
            isOpen: true,
            status: constant.SUCCESS,
            title: cms("message.success.update"),
          });
          setActivePlan(currentPlan);
          setTimeout(() => currentUserRefetch(), 2000);
        } else if (responseError) {
          setError(responseError);
        }
      })
      .catch((exception) => {
        const parsedError = errorHelper.parse(exception);
        setError(parsedError);
      });
  };

  if (planLoading || isLoading) {
    return <Skeleton />;
  }

  if (!isStripeSubscription) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title="Vendor Subscription is not enabled." />
      </Layout.Section>
    );
  }

  let plans = baseHelper.getResponseData(planData, "getSellerPlanList") || [];
  plans = plans.planList;
  if (plans.length === 0) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.INFO} title={cms("common.subscription.notFound")} />
      </Layout.Section>
    );
  }

  const Heading = ({ name = "", monthlyFee = "", isActive = false, isPopular = false }) => {
    const { cms } = useContext(PrivateContext);

    return (
      <Stack alignment="center" wrap={false}>
        <Stack.Item fill>
          <DisplayText size="small" element="h1">
            <Stack>
              <Stack.Item fill>
                {name.toUpperCase()}
{' '}
&nbsp;
{isPopular && <Badge status={constant.SUCCESS}>{cms("label.popular")}</Badge>}
                {isActive && <Badge status={constant.INFO}>{cms("label.active")}</Badge>}
              </Stack.Item>
            </Stack>
          </DisplayText>
        </Stack.Item>
        <Stack.Item>
          <DisplayText size="large">{`$${monthlyFee}/m`}</DisplayText>
        </Stack.Item>
      </Stack>
    );
  };

  const chargeableSortedPlans = plans
    .filter((plan) => plan)
    .sort((first, second) => first.sortOrder - second.sortOrder);

  const getLayoutSections = () => {
    return chargeableSortedPlans.map((plan, index) => {
      const submitCallback = () => {
        onSubmit(plan);
      };
      const {
        analytics = false,
        code = "",
        commission = 0,
        description = "",
        name = "",
        period = "",
        periodUnit = "",
        price = "",
        productLimit = "",
      } = plan || {};

      const isActivePlan = activePlan === plan.code;
      let isDisabled = false;
      const { FREE, GOLD, SILVER } = constant;
      if (code === FREE) {
        if (activePlan === FREE || count >= 5) {
          isDisabled = true;
        }
      }

      if (code === SILVER) {
        if (activePlan === SILVER || count >= 50) {
          isDisabled = true;
        }
      }

      if (activePlan === GOLD && code === GOLD) {
        isDisabled = true;
      }

      const anayticsValue = (analytics && constant.YES) || constant.NO;

      return (
        <Layout.Section oneThird key={index}>
          <Card key={code}>
            <Card.Section>
              <Stack alignment="center" wrap={false}>
                <Stack.Item fill>
                  <DisplayText size="small" element="h1">
                    <Stack>
                      <Stack.Item fill>
                        {name.toUpperCase()}
                        {isActivePlan && <Badge status={constant.SUCCESS}> active</Badge>}
                      </Stack.Item>
                    </Stack>
                  </DisplayText>
                </Stack.Item>
                <Stack.Item>
                  <DisplayText size="large">
                    {" "}
                    <Stack>
                      <Stack.Item fill>
                        {`${currentUser.moneyFormat}${price}`}
                        <br />
                        {period && (
                          <Badge status={constant.INFO}>
                            {`${period} ${periodUnit}${Number(period) > 1 ? "s" : ""}`}
                          </Badge>
                        )}
                      </Stack.Item>
                    </Stack>
                  </DisplayText>
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <p>{description}</p>
              <br />
              <List>
                <List.Item key="sellerPlanProduct">{`${cms("common.label.productLimit")}: ${productLimit}`}</List.Item>
                <List.Item key="sellerPlanCommission">{`${cms("common.label.commission")}: ${commission}%`}</List.Item>
                <List.Item key="sellerPlanAnalytics">{`${cms("common.label.analytics")}: ${anayticsValue}`}</List.Item>
              </List>
              <br />
              <Button primary fullWidth onClick={() => submitCallback()} disabled={isDisabled} loading={loading}>
                {cms("button.update")}
              </Button>
            </Card.Section>
          </Card>
        </Layout.Section>
      );
    });
  };

  if (!isStripeSubscription) {
    history.push("/");
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {isStripeSubscription && (
        <Layout.Section>
          <Layout>{getLayoutSections()}</Layout>
        </Layout.Section>
      )}
    </>
  );
};

export default withErrorBoundary(ProviderUpdatePlanStripe);
