import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, TextField, Stack, Layout, TextContainer } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import components
import ImageComponent from "lib/components/dropZone/dropZone";

// import constant
import constant from "lib/constant/constant";

const CustomFieldSection = (props) => {
  const { cms = {} } = useContext(PrivateContext);
  const { data, handleChange } = props;
  const { manageVendorDetails } = constant;
  const {
    isField1Enabled,
    isField2Enabled,
    isField3Enabled,
    isField4Enabled,
    isField5Enabled,
    label1,
    label2,
    label3,
    label4,
    label5,
  } = manageVendorDetails;
  const getFields = () => {
    return (
      <>
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={data.isField1Enabled}
              label=""
              onChange={(value) => handleChange(value, isField1Enabled)}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label=""
              value={data.label1}
              onChange={(value) => handleChange(value, label1)}
              placeholder={cms("section.custom.placeHolder.label")}
              disabled={!data.isField1Enabled}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label=""
              maxLength={30}
              value=""
              placeholder={cms("section.custom.placeHolder.textField")}
              disabled
            />
          </Stack.Item>
        </Stack>
        <br />
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={data.isField2Enabled}
              label=""
              onChange={(value) => handleChange(value, isField2Enabled)}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label=""
              value={data.label2}
              onChange={(value) => handleChange(value, label2)}
              placeholder={cms("section.custom.placeHolder.label")}
              disabled={!data.isField2Enabled}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label=""
              maxLength={30}
              value=""
              placeholder={cms("section.custom.placeHolder.textField")}
              disabled
            />
          </Stack.Item>
        </Stack>
        <br />
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={data.isField3Enabled}
              label=""
              onChange={(value) => handleChange(value, isField3Enabled)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label=""
              value={data.label3}
              onChange={(value) => handleChange(value, label3)}
              placeholder={cms("section.custom.placeHolder.label")}
              disabled={!data.isField3Enabled}
            />
          </Stack.Item>
        </Stack>
        <br />
        <span>{cms("section.custom.label.upload")}</span>
        <ImageComponent size={15} disabled />
        <br />
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={data.isField4Enabled}
              label=""
              onChange={(value) => handleChange(value, isField4Enabled)}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label=""
              value={data.label4}
              onChange={(value) => handleChange(value, label4)}
              placeholder={cms("section.custom.placeHolder.label")}
              maxLength={10}
              disabled={!data.isField4Enabled}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField label="" maxLength={30} value="" placeholder={cms("section.custom.placeHolder.url")} disabled />
          </Stack.Item>
        </Stack>
        <br />
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={data.isField5Enabled}
              label=""
              onChange={(value) => handleChange(value, isField5Enabled)}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label=""
              value={data.label5}
              onChange={(value) => handleChange(value, label5)}
              placeholder={cms("section.custom.placeHolder.label")}
              disabled={!data.isField5Enabled}
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label=""
              maxLength={1000}
              value=""
              placeholder={cms("section.custom.placeHolder.textField")}
              disabled
            />
          </Stack.Item>
        </Stack>
        <br />
      </>
    );
  };
  return (
    <div>
      <Layout.AnnotatedSection title={cms("section.custom.title")} description={cms("section.custom.description")}>
        <Card title={cms("section.custom.subTitle")}>
          <Card.Section>
            <TextContainer>{cms("section.custom.subDescription")}</TextContainer>
            <br />
            {getFields()}
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};

CustomFieldSection.propTypes = {
  data: PropTypes.shape({
    isField1Enabled: PropTypes.bool.isRequired,
    isField2Enabled: PropTypes.bool.isRequired,
    isField3Enabled: PropTypes.bool.isRequired,
    isField4Enabled: PropTypes.bool.isRequired,
    isField5Enabled: PropTypes.bool.isRequired,
    label1: PropTypes.string.isRequired,
    label2: PropTypes.string.isRequired,
    label3: PropTypes.string.isRequired,
    label4: PropTypes.string.isRequired,
    label5: PropTypes.string.isRequired,
    value1: PropTypes.string.isRequired,
    value2: PropTypes.string.isRequired,
    value3: PropTypes.string.isRequired,
    value4: PropTypes.string.isRequired,
    value5: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CustomFieldSection;
