// import packages
import React, { useContext, useState } from "react";
import { Layout } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Banner } from "lib/components";
import constant from "lib/constant/constant";
import { PaymentProvider } from "app/setup/modules/operator/features/paymentService/context";
import AllowedCurrency from "./allowedCurrency";
import AutomaticPayout from "./automaticPayout";

const ConnectPaymentService = () => {
  const { cms } = useContext(PrivateContext);
  const isAllowedCurrency = true;
  const [isStripe, setIsStripe] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  return (
    <>
      {!isAllowedCurrency && (
        <Layout.Section>
          <Banner isOpen title={cms("message.error.defaultBanner")} status={constant.WARNING} />
        </Layout.Section>
      )}
      <br />
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            status={banner.status}
            onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.connect.title")} description={cms("section.connect.description")}>
        <PaymentProvider value={{ setBanner, isAllowedCurrency, setIsStripe }}>
          <AllowedCurrency />
        </PaymentProvider>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.automaticPayouts.title")}
        description={cms("section.automaticPayouts.description")}
      >
        <PaymentProvider value={{ setBanner, isStripe }}>
          <AutomaticPayout />
        </PaymentProvider>
      </Layout.AnnotatedSection>
    </>
  );
};
export default ConnectPaymentService;
