import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { labelSchema, addressSchema, citySchema, countrySchema, postalCodeSchema } from "./schema";

const validate = async (field, value, cms) => {
  const { validateValue, handlePromiseError } = yupHelper;
  let promise = "";
  switch (field) {
    case constant.LABEL:
      promise = validateValue(labelSchema(cms), { label: value });
      break;
    case constant.ADDRESS:
      promise = validateValue(addressSchema(cms), { address: value });
      break;
    case constant.CITY:
      promise = validateValue(citySchema(cms), { city: value });
      break;
    case constant.COUNTRY:
      promise = validateValue(countrySchema(cms), { countryCode: value });
      break;
    case constant.POSTAL_CODE:
      promise = validateValue(postalCodeSchema(cms), { postalCode: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
