import React, { useContext, useState, useEffect } from "react";
import {
  Banner,
  Card,
  Link,
  Collapsible,
  Caption,
  Layout,
  Stack,
  Heading,
  TextContainer,
  TextStyle,
  VideoThumbnail,
} from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

// import helper
import { baseHelper, storageHelper } from "lib/helpers";

// import gql
import { GET_VENDOR_DISCOUNT_SETTING } from "app/setup/apollo/queries";
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";
import { GET_SELLER_FULFILLMENT } from "app/onboarding/apollo/queries";

import { ModalPopup, PermissionBanner, SkeletonAnnotatedCard } from "lib/components";
import { PrivateContext } from "lib/context";

import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
import standardList from "./standardConfig";

const ProviderSettings = () => {
  const [openSetup, setOpenSetup] = useState({});
  const [isShippo, setIsShippo] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  const {
    cms,
    currentUser,
    isLoading,
    isShopConnected,
    isShopifyOldVendor,
    vendorHiddenModuleKeys = [],
    vendorHiddenModule = [],
  } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false, ecommercePlatform, shop, shopifyAccessToken } = currentUser;

  const isHiddenModule =
    vendorHiddenModuleKeys.includes(constant.PRODUCT) && vendorHiddenModuleKeys.includes(constant.ORDER);

  const { imageURL, SHIPPING, ADVANCE_SHIPPING, FULFILLMENT, TAX, LAKE_TAX_RATE } = constant;

  const allowShipping = [SHIPPING, ADVANCE_SHIPPING];
  const wooCommerceSetting = [TAX, FULFILLMENT];
  const wooCommerceFeature = [LAKE_TAX_RATE, ADVANCE_SHIPPING];

  const { data: discountData, loading: getDiscountLoading } = useQuery(GET_VENDOR_DISCOUNT_SETTING);
  const resDiscountData = baseHelper.getResponseData(discountData, constant.gql.GET_VENDOR_DISCOUNT_SETTING);

  const { loading: bandLoading, data: bandData } = useQuery(GET_SHIPPING_BAND);
  const resBandData = baseHelper.getResponseData(bandData, constant.gql.GET_SHIPPING_BAND);

  const { loading: fulfillmentLoading, data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT);

  useEffect(() => {
    if (!fulfillmentData) {
      return;
    }
    const responseData = baseHelper.getResponseData(fulfillmentData, constant.gql.GET_SELLER_FULFILLMENT);
    if (!responseData) {
      return;
    }
    if (responseData) {
      const { dropship } = responseData || {};
      if (dropship?.shippo?.apiKey) {
        setIsShippo(true);
      }
    }
  }, [fulfillmentData]);

  const { shippingTerm } = resBandData;

  const { isEnabled = false } = resDiscountData || {};

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  const borderSet = {
    border: "0",
  };

  const iconHeightFix = {
    lineHeight: "2.2em",
  };
  const hiddenCards = ["Billing", "General"];

  if (isLoading || bandLoading || getDiscountLoading || fulfillmentLoading) {
    return <SkeletonAnnotatedCard />;
  }

  return (standardList(cms) || []).map((settingList) => {
    let updateSettingList = settingList.list;
    const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
    const isWooCommerceUser = baseHelper.isWooCommerceUser(currentUser);
    if (isEcommerceUser) {
      updateSettingList = baseHelper.getAllowedVendorSetting(updateSettingList);
      if (!isWooCommerceUser) {
        updateSettingList = updateSettingList.filter((item) => !wooCommerceSetting.includes(item.code));
      }
    }
    return (
      <>
        {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.banner.description.heading")}
              status="critical"
            >
              <p>
                <br />
                {cms("common.popup.migrate.banner.description.para1")}
                <br />
                <br />
                {cms("common.popup.migrate.banner.description.para2")}
              </p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutURL")}`,
                      url: constant.ABOUT_VENDOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>
                      <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para1")}
                      <br />
                      <br />
                      {cms("common.popup.migrate.banner.description.para2")}
                    </TextContainer>
                  </Banner>
                </TextContainer>
                <br />
                {banner.isOpen && (
                  <Banner
                    isOpen={banner.isOpen}
                    status={banner.status}
                    title={banner.title}
                    action={banner.action}
                    onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                    isScrollTop={banner.isOpen}
                  />
                )}
              </Card>
              <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setBanner={setBanner} />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <Layout.AnnotatedSection
          title={settingList.title}
          description={[
            <div>
              {settingList.isVideo && (
                <>
                  <VideoThumbnail
                    videoLength={80}
                    // eslint-disable-next-line max-len
                    thumbnailUrl={imageURL.WELCOME}
                  />
                  <br />
                </>
              )}
              <TextStyle variation="subdued">
                {ecommercePlatform ? cms("provider.tempDescription") : settingList.description}
              </TextStyle>
            </div>,
          ]}
        >
          {updateSettingList.map((actionList) => {
            if (
              vendorHiddenModule.includes(actionList.title) ||
              (vendorHiddenModule.includes(constant.ORDERS_UC_FIRST) && actionList.code === constant.FULFILMENT)
            ) {
              return null;
            }
            let actionData = actionList.actions;
            if (isEcommerceUser) {
              actionData = baseHelper.allowedSettingList(actionList.code, actionData);
              actionData = actionData.filter((data) => {
                if (data.code === constant.LOCATION && !shop && !shopifyAccessToken) {
                  return;
                }
                return data;
              });

              if (!isWooCommerceUser) {
                actionData = actionData.filter((item) => !wooCommerceFeature.includes(item.code));
              }
            }

            if (!isEcommerceUser) {
              updateSettingList = updateSettingList.filter((item) => !wooCommerceSetting.includes(item.code));
            }

            actionData = actionData.filter((step) => {
              if (isHiddenModule && step.code === constant.DIGEST) {
                return null;
              }
              if (shippingTerm !== constant.VENDOR_MANAGE && allowShipping.includes(step.code)) {
                return null;
              }
              if (!isEnabled && step.code === constant.DYNAMIC_DISCOUNT) {
                return null;
              }
              if (!isShippo && step.code === constant.CARRIER) {
                return null;
              }
              if (!isEcommerceUser && step.code === constant.LOCATION) {
                return null;
              }
              return step;
            });

            if (!(actionData && actionData.length)) {
              hiddenCards.push(actionList.title);
            }

            return (
              !hiddenCards.includes(actionList.title) && (
                <Card>
                  <Card.Header
                    sectioned
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Stack>
                        <i className={actionList.icon} style={iconHeightFix} />
                        <Heading>
                          {actionList.title}
                          <TextStyle variation="subdued">
                            <Caption>{actionList.description}</Caption>
                          </TextStyle>
                        </Heading>
                      </Stack>
                    }
                    actions={[
                      {
                        content: openSetup[actionList.code] ? "Hide" : "Show",
                        onAction: () => handleSetupToggle(actionList.code),
                        disclosure: openSetup[actionList.code] ? "up" : "down",
                      },
                    ]}
                  />
                  <br />

                  <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                    <Card.Section subdued>
                      {actionData
                        .filter((step) => step)
                        .map((step) => {
                          return (
                            <div style={borderSet}>
                              <Card.Section subdued>
                                <Stack>
                                  <Stack.Item>
                                    <Link url={step.link}>{step.label}</Link>
                                    <TextStyle variation="">
                                      <Caption>{step.description}</Caption>
                                    </TextStyle>
                                  </Stack.Item>
                                </Stack>
                              </Card.Section>
                            </div>
                          );
                        })}
                      {actionList.isVideo && (
                        <Layout.AnnotatedSection
                          title={actionList.thumbnailHeader}
                          description={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <div>
                              <VideoThumbnail videoLength={80} thumbnailUrl={actionList.thumbnail} />
                              <br />
                              <TextStyle variation="">{actionList.thumbnailDescription}</TextStyle>
                            </div>
                          }
                        />
                      )}
                    </Card.Section>
                  </Collapsible>
                </Card>
              )
            );
          })}
        </Layout.AnnotatedSection>
      </>
    );
  });
};

export default withFeature(withErrorBoundary(ProviderSettings), { feature: constant.SETTINGS });
