import { gql } from "apollo-boost";

const GET_VENDOR_DETAILS = gql`
  query getVendorDetail($input: CheckVendorStatus) {
    getVendorDetail(input: $input) {
      status
      error
      data {
        vendorDetail {
          accountNumber
          bankName
          brandHandle
          brandName
          brandDescription
          brandCoverImage
          brandLogo
          city
          country
          email
          facebookUrl
          firstName
          instagramUrl
          lastName
          linkedinUrl
          paymentCountry
          phoneNumber
          postalCode
          provinceCode
          shippingCity
          shippingCountry
          shippingPostalCode
          shippingStreetAddress
          sortCode
          streetAddress
          twitterUrl
          youtubeUrl
          customFields {
            imageUrls
            inputType
            key
            label
            multimediaUrls
            placeholder
            value
          }
        }
        vendorStatus
      }
    }
  }
`;

export default GET_VENDOR_DETAILS;
