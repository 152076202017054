import React, { useContext } from "react";
import { useQuery } from "react-apollo";
import { PublicContext } from "lib/context";
import { Spinner } from "lib/components";

import config from "configuration";

// import helpers
import { accessHelper, baseHelper, storageHelper } from "lib/helpers";

// import gql
import { FETCH_MAINTENANCE, GET_CURRENT_USER } from "app/public/apollo/queries";

// import constant
import constant from "lib/constant/constant";
import UnderMaintenance from "./feature/underMaintenance";

const {
  gql: { GET_CURRENT_USER: GET_CURRENT_USER_GQL },
} = constant;

const Install = () => {
  const isIncognito = accessHelper.isIncognito();

  const { data: maintenanceData, loading: maintenanceLoading } = useQuery(FETCH_MAINTENANCE);
  const { data: currentUserData, loading: currentUserLoading } = useQuery(GET_CURRENT_USER);

  const { history } = useContext(PublicContext);

  if (maintenanceLoading) {
    return <Spinner isFullPage />;
  }

  const { getMaintenance = {} } = maintenanceData || {};
  const { isApp = false } = getMaintenance.data || {};

  if (isApp) {
    return <UnderMaintenance />;
  }

  const redirect = () => {
    const queryParams = baseHelper.getQueryParams(history.location.search);
    let { shop } = queryParams;
    const { hmac = "" } = queryParams;

    if (history.location.state) {
      shop = history.location.state.shop;
    }

    const clientId = config.shopifyAPIKey;
    const redirectURI = `${config.rootURL}/shopify/redirect`;

    const scope =
      // eslint-disable-next-line max-len
      "read_products, write_products, read_customers, write_customers, write_order_edits, read_discounts, write_discounts, unauthenticated_read_product_listings," + // read_orders
      // eslint-disable-next-line max-len
      "write_orders, read_fulfillments, read_orders, read_inventory, write_inventory, write_fulfillments, read_shipping, write_shipping, read_locations," +
      "write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders ";

    // eslint-disable-next-line max-len
    const url = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectURI}&state=${hmac}`;
    window.top.location.href = url;
  };

  const shop = storageHelper.get("shop");
  const token = storageHelper.get("token");

  if (isIncognito) {
    window.open(config.rootURL, "_blank");
    return <div className="text-center">Redirecting to Marketcube...</div>;
  } else {
    if (shop && token) {
      if (currentUserLoading) {
        return <Spinner isFullPage />;
      }
  
      const currentUser = baseHelper.getResponseData(currentUserData, GET_CURRENT_USER_GQL);
  
      const isSeller = baseHelper.isSeller(currentUser);
      const validShop = baseHelper.validShop(shop);
  
      if (isSeller && validShop && currentUser.shop === validShop) {
        history.push("/");
      } else {
        storageHelper.remove("token");
        storageHelper.remove("shop");
        redirect();
      }
    } else {
      redirect();
    }
  
    const isIframe = baseHelper.isIframe();
  
    if (isIframe) {
      return null;
    }
  
    return <div className="text-center">Redirecting to Install accept Page</div>;
  }
};

export default Install;
