import { gql } from "apollo-boost";

const GET_VENDOR_FORM_LABEL = gql`
  query getVendorFormLabel {
    getVendorFormLabel {
      status
      error
      data {
        brandName {
          label
        }
        brandHandle {
          label
        }
        firstName {
          label
        }
        lastName {
          label
        }
        email {
          label
        }
        city {
          label
        }
        phoneNumber {
          label
        }
        streetAddress {
          label
        }
        country {
          label
        }
        postalCode {
          label
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export default GET_VENDOR_FORM_LABEL;
