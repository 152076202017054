import React, { useContext } from "react";
import { FormLayout, Card, TextField, Stack, Checkbox } from "@shopify/polaris";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context/privateContext";

const {
  PRICE = "",
  SKU = "",
  BARCODE = "",
  INVENTORY_QUANTITY = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999 },
} = constant;

const VariantField = (props) => {
  const { data } = props || {};
  const { cms = {}, currentUser } = useContext(PrivateContext);
  const { name } = currentUser.roles;
  const {
    variantValues,
    variants,
    validVariants,
    option1,
    option2,
    option3,
    handleValidVariants,
    variantValueChange,
  } = props;
  const key = {
    temp: 0,
  };
  const variantRows = variants.map((variant, idx) => {
    const item = { ...variants[idx] };
    key.temp += 1;
    return (
      <Card.Section subdued key={key.temp}>
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={!!validVariants[idx]}
              id="variant"
              labelHidden
              label={cms("section.variant.title")}
              onChange={() => handleValidVariants(idx)}
            />
          </Stack.Item>
          <Stack.Item vertical fill>
            {item.option1 && <Stack.Item>{`${option1}: ${item.option1}`}</Stack.Item>}
            {item.option2 && <Stack.Item>{`${option2}: ${item.option2}`}</Stack.Item>}
            {item.option3 && <Stack.Item>{`${option3}: ${item.option3}`}</Stack.Item>}
          </Stack.Item>
        </Stack>
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label={cms("section.variant.label.price")}
              id="price"
              type="number"
              min={MIN_PRICE}
              max={MAX_FLAT}
              value={(variantValues[idx] && variantValues[idx].price && variantValues[idx].price.toString()) || ""}
              onChange={(val) => variantValueChange(PRICE, (Number(val) >= 0 && Number(val)) || 0, idx)}
              disabled={name === "seller"}
            />
            <TextField
              label={cms("section.variant.label.sku")}
              id="variantSku"
              value={(variantValues[idx] && variantValues[idx].sku) || ""}
              onChange={(val) => variantValueChange(SKU, val, idx)}
            />
            <TextField
              label={cms("section.variant.label.barcode")}
              id="variantBarcode"
              value={(variantValues[idx] && variantValues[idx].barcode) || ""}
              onChange={(val) => variantValueChange(BARCODE, val, idx)}
            />
            <TextField
              id="variantInventory"
              label={cms("section.variant.label.inventory")}
              disabled={data.quantityDisabled}
              type="number"
              min={MIN_PRICE}
              max={MAX_FLAT}
              value={
                (variantValues[idx] &&
                  variantValues[idx].inventoryQuantity &&
                  variantValues[idx].inventoryQuantity.toString()) ||
                ""
              }
              onChange={(val) => variantValueChange(INVENTORY_QUANTITY, (Number(val) >= 0 && Number(val)) || 0, idx)}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    );
  });
  return variantRows;
};

export default VariantField;
