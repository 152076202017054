import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";

import { OperatorBranding } from "./operator/features";

const Branding = () => {
  const { isLoading } = useContext(PrivateContext);
  const {
    userKey: { operator },
  } = constant;

  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorBranding />;
    }
    return null;
  };

  if (isLoading) {
    return <Spinner />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const BrandingComponent = getComponent(currentUserRole);
  return BrandingComponent;
};

export default Branding;
