// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const SEND_QUOTE_EMAIL = gql`
  mutation sendQuoteEmail($input: sendQuoteEmailInput!) {
    sendQuoteEmail(input: $input) {
      data
      status
      error
    }
  }
`;

export default SEND_QUOTE_EMAIL;