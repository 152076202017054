import { gql } from "apollo-boost";

const UPDATE_PRODUCT_LAKE_FULFILLMENT = gql`
  mutation updateProductLakeFulfillment($input: UpdateFulfillment) {
    updateProductLakeFulfillment(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_PRODUCT_LAKE_FULFILLMENT;
