import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner, Spinner } from "lib/components";

// import query
import { GET_VENDOR_DETAILS } from "app/vendors/apollo/queries";
import { GET_VENDOR_EXPORT_SETTING } from "app/setup/apollo/queries";
import { FETCH_VENDOR_FIELD } from "app/advanceVendor/apollo/queries";

// import helpers
import { baseHelper } from "lib/helpers";
import vendorHelper from "./helper/vendor";

const Export = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser, isLoading } = data;
  const { _id: currentUserId } = currentUser;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();

  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const [isSocialLinkEnable, setIsSocialLinkEnable] = useState(false);
  const [customFieldInformation, setCustomFieldInformation] = useState({});
  const [isShippingEnable, setIsShippingEnable] = useState(false);

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [
    getVendorLines,
    { data: getVendorData, loading: getVendorLoading, error: getVendorError },
  ] = useLazyQuery(GET_VENDOR_DETAILS, { variables: { input: queryData } });

  const [getVendorSetting, { data: getSettingData, loading: settingLoading, error: settingError }] = useLazyQuery(
    GET_VENDOR_EXPORT_SETTING
  );

  const [fetchVendor, { loading: fetchLoading, data: fetchVendorData, error: fetchVendorError }] = useLazyQuery(
    FETCH_VENDOR_FIELD,
    {
      variables: { input: { sellerId: currentUserId } },
    }
  );

  useEffect(() => {
    if (!fetchVendorData) {
      return;
    }
    const responseData = baseHelper.getResponseData(fetchVendorData, constant.gql.GET_VENDOR_FIELD);
    const responseError = baseHelper.getResponseError(fetchVendorData, constant.gql.GET_VENDOR_FIELD);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { isPayment = false, isShipping = false, isSocialLink = false, customFields = [] } =
      (responseData && responseData.vendorField) || {};
    setIsPaymentEnable(isPayment);
    setIsShippingEnable(isShipping);
    setIsSocialLinkEnable(isSocialLink);
    setCustomFieldInformation(customFields);
  }, [data, fetchVendorData]);

  useEffect(() => {
    if (
      !getVendorLoading &&
      !getVendorError &&
      getVendorData &&
      getSettingData &&
      fetchVendorData &&
      !settingLoading &&
      !settingError &&
      !fetchLoading &&
      !fetchVendorError
    ) {
      const resData = baseHelper.getResponseData(getVendorData, constant.gql.GET_VENDOR_DETAIL);
      const settingResponse = baseHelper.getResponseData(getSettingData, constant.gql.GET_VENDOR_EXPORT_SETTING);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getVendorData, constant.gql.GET_VENDOR_DETAIL);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const csvData = vendorHelper.vendorCSVData(
        resData.vendorDetail,
        settingResponse,
        true,
        false,
        isPaymentEnable,
        isSocialLinkEnable,
        isShippingEnable,
        customFieldInformation
      );
      const csv = Baby.unparse(csvData);
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      FileDownload(csv, `${constant.CONSOLIDATED_VENDOR} ${date}.csv`);
      setTimeout(() => {
        window.close();
      }, 500);
    }
  }, [
    getVendorData,
    getVendorError,
    getVendorLoading,
    cms,
    getSettingData,
    settingLoading,
    settingError,
    isLoading,
    isVendor,
    isPaymentEnable,
    isSocialLinkEnable,
    isShippingEnable,
    fetchLoading,
    fetchVendorError,
    customFieldInformation,
    fetchVendorData,
  ]);

  useEffect(() => {
    const exportVendor = () => {
      const dataToFetch = {};
      const { endDate, startDate, status, vendor } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (startDate && endDate) {
        dataToFetch.dateRange = { start: startDate, end: endDate };
      }
      setQueryData(dataToFetch);
      // getVendorLines();
      // getVendorSetting();
    };
    if (isPageLoading) {
      exportVendor();
      setIsPageLoading(false);
    }
  }, [data, getVendorLines, getVendorSetting, isPageLoading]);

  useEffect(() => {
    if (!isLoading && currentUser) {
      fetchVendor();
      getVendorLines();
      getVendorSetting();
      setIsPageLoading(true);
    }
  }, [isLoading, currentUser, fetchVendor, getVendorLines, getVendorSetting]);

  if (getVendorLoading || fetchLoading || isLoading || settingLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <Layout>
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(Export), { feature: constant.EXPORT_VENDOR });
