import { gql } from "apollo-boost";

const GET_VENDOR_DISCOUNT_SETTING = gql`
  query {
    getVendorDiscountSetting {
      data {
        isAccept
        isEnabled
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_VENDOR_DISCOUNT_SETTING;
