import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { PrivateContext } from "lib/context";

// import polaris packages
import {
  Avatar,
  Badge,
  Button,
  Caption,
  Card,
  Collapsible,
  EmptyState,
  Link,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

// import helper components
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { Banner, SkeletonList } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import gql
import { BULK_PRODUCT, BULK_PRODUCT_LAKE } from "app/productOld/apollo/mutations";
import { GET_BULK_EMAIL_TEMPLATE } from "app/invitations/apollo/query";
import { BULK_INVITATION_BY_DATA, UPDATE_INVITATION_EMAIL_STATUS } from "app/invitations/apollo/mutation";
import Popover from "lib/components/popover/popover";
import { CancelSmallMinor, ImportStoreMajor, RefreshMinor, ViewMajor, ViewMinor } from "@shopify/polaris-icons";
import ProductVersioning from "app/productOld/modules/generic/feature/list/productVersioning";
import { GET_ACTIVITY } from "app/userManagement/apollo/queries";
import { ProductCard } from "asset/styles/globalStyle";

const Activity = () => {
  const { cms, currentUser, history, vendorHiddenModuleKeys = [] } = useContext(PrivateContext);
  const { ecommercePlatform, isReadOnly } = currentUser;
  const currentUserRole = storageHelper.get("userRole");
  const { displayStatus } = constant;
  const {
    APPROVED,
    DELETED,
    gql,
    imageURL,
    IN_PROGRESS,
    PROGRESS,
    PUBLISHED,
    userKey: { provider },
  } = constant;
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [active, setActive] = useState({});
  const [resendMail, setResendMail] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });

  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const serviceLabel = "Service";
  const typeInvite = "invite";
  const typeOrder = "order";
  const typeProduct = "product";
  const typeProductCsv = "productCsv";
  const typeShopifyProduct = "productImport";
  const typeVendorCsv = "vendorCsv";

  const DELETE = DELETED.toLowerCase();
  const { data: activityData, loading: activityLoading, error: activityError } = useQuery(GET_ACTIVITY, {
    variables: {
      input: {
        isIgnoreCount: true,
      },
    },
  });

  const [
    revokeInvitation,
    { error: errorRevokeInvitation, loading: loadingRevokeInvitation, data: dataRevokeInvitation },
  ] = useMutation(UPDATE_INVITATION_EMAIL_STATUS);

  const [resendInvitation, { loading: loadingResendInvitation }] = useMutation(BULK_INVITATION_BY_DATA);

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT);
  const [bulkLakeProductActions, { loading: loadingLakeBulkProduct }] = useMutation(BULK_PRODUCT_LAKE);

  const [
    emailTemplate,
    { error: errorEmailTemplate, loading: loadingEmailTemplate, data: dataEmailTemplate },
  ] = useLazyQuery(GET_BULK_EMAIL_TEMPLATE);

  const responseData = baseHelper.getResponseData(activityData, gql.GET_ACTIVITY);
  const { activity = [] } = responseData;

  const responseError = baseHelper.getResponseError(activityError, gql.GET_ACTIVITY);
  useEffect(() => {
    if (responseError) {
      setBanner({
        title: responseError,
        status: constant.CRITICAL,
        isOpen: true,
      });
    }
  }, [responseError]);

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "") => {
    const formData = {
      key: eventKey,
      ids: [],
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
    }

    if (ecommercePlatform) {
      delete formData.deleteFromShopify;
      formData.isDeleteFromStore = deleteFromShopify;
    }

    const bulkProductAction = ecommercePlatform ? bulkLakeProductActions : bulkProductActions;

    await bulkProductAction({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const response = baseHelper.getResponseData(
            res.data,
            ecommercePlatform ? constant.gql.BULK_PRODUCT_LAKE : constant.gql.BULK_PRODUCT
          );
          const responseDataError = baseHelper.getResponseError(
            res.data,
            ecommercePlatform ? constant.gql.BULK_PRODUCT_LAKE : constant.gql.BULK_PRODUCT
          );
          if (response) {
            const title = cms("common.history.message.success.requestProcessing");
            setBanner({ isOpen: true, title, status: constant.SUCCESS });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };

  useEffect(() => {
    if (dataRevokeInvitation) {
      const revokeInvitationResponseData = baseHelper.getResponseData(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      const revokeInvitationResponseError = baseHelper.getResponseError(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      if (revokeInvitationResponseData) {
        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("common.history.message.success.requestProcessing"),
        });
      }
      if (revokeInvitationResponseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: revokeInvitationResponseError });
      }
    }
  }, [dataRevokeInvitation, setBanner, cms]);

  useEffect(() => {
    if (errorRevokeInvitation) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(errorRevokeInvitation) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRevokeInvitation, setBanner, errorHelper]);

  useEffect(() => {
    if (dataEmailTemplate) {
      const emailTemplateResponseData = baseHelper.getResponseData(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      const emailTemplateResponseError = baseHelper.getResponseError(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      if (emailTemplateResponseData) {
        const resendInviteData = {
          emails: [
            {
              email: resendMail,
            },
          ],
          message: {
            message: emailTemplateResponseData.message,
            subject: emailTemplateResponseData.subject,
          },
          isSingleInvite: true,
          isReadOnly,
          isResent: true,
        };
        resendInvitation({ variables: { input: { ...resendInviteData } } })
          .then((resendInviteRes) => {
            const resendInviteResponseData = baseHelper.getResponseData(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            const resendInviteResponseError = baseHelper.getResponseError(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            if (resendInviteResponseData) {
              setBanner({
                title: cms("common.history.message.success.requestProcessing"),
                status: constant.SUCCESS,
                isOpen: true,
              });
            }
            if (resendInviteResponseError) {
              setBanner({ title: resendInviteResponseError, status: constant.CRITICAL, isOpen: true });
            }
          })
          .catch((exception) => {
            setBanner({ title: errorHelper.parse(exception), status: constant.CRITICAL, isOpen: true });
          });
      }
      if (emailTemplateResponseError) {
        setBanner({ title: emailTemplateResponseError, status: constant.CRITICAL, isOpen: true });
      }
    }
  }, [isReadOnly, dataEmailTemplate, emailTemplate.message, emailTemplate.subject, resendInvitation, resendMail, cms]);

  useEffect(() => {
    if (errorEmailTemplate) {
      setBanner({ title: errorHelper.parse(errorEmailTemplate), status: constant.CRITICAL, isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEmailTemplate, errorHelper]);

  const handleCancel = async (email) => {
    const formData = { email };
    revokeInvitation({ variables: { input: formData } });
  };

  const handleResend = (mail) => {
    setResendMail(mail);
    emailTemplate();
  };

  const handleSelectChange = (value, id, email, selectedId, linkCard = "", isProduct = false) => {
    setSelectedButtonIndex(selectedId);
    if (value === constant.CANCEL) {
      handleCancel(email);
    }
    if (value === constant.RESEND) {
      handleResend(email);
    }

    if (value === constant.VIEW_ID) {
      history.push(linkCard);
    }

    if (!isProduct && value === constant.VIEW_ID) {
      history.push(linkCard);
    }

    if (value === constant.REVIEW_ALL) {
      history.push(`${constant.PRODUCT_URL_REVIEW}${id}`);
    }

    if (value === constant.PUSH_TO_STORE_ID) {
      bulkAction(constant.APPROVE, false, id, "", null, constant.NEW);
    }
  };

  if (activityLoading) {
    return <SkeletonList />;
  }
  const productUrl = ecommercePlatform ? "/product-lake" : "/products";
  const orderUrl = ecommercePlatform ? "/order-lake" : "/orders";

  const renderCard = () => {
    if (isSeller && responseData && activity && activity.length === 0) {
      return (
        <EmptyState
          heading={cms("common.history.heading.productAndVendor")}
          action={
            isSeller && {
              content: cms("common.history.action.secondary"),
              onAction: () => history.push("/providers/add"),
            }
          }
          secondaryAction={{
            content: cms("common.history.action.primary"),
            onAction: () => history.push(`${productUrl}/add`),
          }}
          image={imageURL.EMPTY_STATE}
        >
          <p>{cms("section.activity.next")}</p>
        </EmptyState>
      );
    }

    if (
      (isVendor || isSystemAdmin) &&
      responseData &&
      activity &&
      activity.length === 0 &&
      !vendorHiddenModuleKeys.includes(constant.PRODUCT)
    ) {
      return (
        <EmptyState
          heading={cms("common.history.heading.product")}
          action={{ content: cms("common.history.action.primary"), onAction: () => history.push(`${productUrl}/add`) }}
          image={imageURL.EMPTY_STATE}
        >
          <p>{cms("section.activity.next")}</p>
        </EmptyState>
      );
    }
    if (ecommercePlatform) {
      return (
        <EmptyState
          heading={isSeller ? cms("common.history.heading.productAndVendor") : cms("common.history.heading.product")}
          action={
            isSeller && {
              content: cms("common.history.action.secondary"),
              onAction: () => history.push("/providers/add"),
            }
          }
          secondaryAction={{
            content: cms("common.history.action.primary"),
            onAction: () => history.push(`${productUrl}/add`),
          }}
          image={imageURL.EMPTY_STATE}
        >
          <p>{cms("section.activity.next")}</p>
        </EmptyState>
      );
    }

    if (
      isVendor &&
      vendorHiddenModuleKeys.includes(constant.PRODUCT) &&
      vendorHiddenModuleKeys.includes(constant.ORDER) &&
      vendorHiddenModuleKeys.includes(constant.PAYMENT)
    ) {
      return <EmptyState heading={cms("common.history.heading.unavailable")} image={imageURL.EMPTY_STATE} />;
    }

    if (responseData) {
      return activity.map((data) => {
        const {
          _id,
          label,
          status,
          createdAt,
          by,
          byUserId,
          title,
          type,
          activityId,
          collectionName,
          image = "",
          /* vendor = "", */
          vendorId = "",
        } = data;

        const isOrder = type === typeOrder;
        const isProduct = [typeProduct, typeShopifyProduct].includes(type);
        const isInvite = [typeInvite].includes(type);
        // const brandName = isSeller || isVendor ? vendor : by;
        const isProductCsv = type === typeProductCsv;
        const isVendorCsv = type === typeVendorCsv;

        const activityLogId = activityId;
        const thumbnailUrl = image || constant.NOIMAGESNAP;

        const badge =
          status &&
          status.map((item) => {
            let badgeItem = item.toLowerCase();
            if (badgeItem === APPROVED.toLowerCase()) {
              badgeItem = PUBLISHED;
            }
            const filedStatus = baseHelper.getBadgeStatus(badgeItem);
            const badgeStatus = filedStatus.toLowerCase();
            if (badgeItem === IN_PROGRESS) {
              badgeItem = PROGRESS;
            }
            const badgeType = baseHelper.getBadgeType(badgeItem);
            return {
              badgeType,
              badgeStatus,
            };
          });
        const { badgeType: firstBadgeType, badgeStatus: firstBadgeStatus } = (badge.length && badge[0]) || {};
        const showLink = !(status && status[0] && status[0].toLowerCase() === DELETE);
        const productList = showLink && status && status[0] && status[0].toLowerCase() === "new";
        const productURL =
          label === serviceLabel
            ? `${productUrl}/service/edit/${activityLogId}`
            : `${productUrl}/edit/${activityLogId}`;

        let linkCard = isOrder ? `${orderUrl}/view/${activityLogId}` : productURL;
        if (collectionName === "errorLogs") {
          linkCard = `/activity/view/${activityId}`;
        }

        if (isSeller && collectionName === "sellerProducts" && productList) {
          linkCard = productUrl;
        }

        if (isSeller && isInvite) {
          linkCard = "/invitation";
        }

        const inviteOptions = [
          {
            content: cms("common.history.button.resend"),
            value: cms("common.history.button.resend"),
            icon: RefreshMinor,
            onAction: () => handleSelectChange("Resend", activityLogId, title, _id, linkCard, isProduct),
          },
          {
            content: cms("common.button.cancel"),
            value: cms("common.button.cancel"),
            icon: CancelSmallMinor,
            destructive: true,
            onAction: () => handleSelectChange("Cancel", activityLogId, title, _id, linkCard, isProduct),
          },
        ];

        const view = {
          content: constant.VIEW_LABEL,
          value: constant.VIEW_ID,
          disabled: false,
          icon: ViewMajor,
          onAction: () => handleSelectChange(constant.VIEW_ID, activityLogId, title, _id, linkCard, isProduct),
        };

        let options;

        if (isInvite) {
          options = inviteOptions;
        }

        if (isProduct) {
          const pushToStore = {
            content: constant.PUSH_TO_STORE_LABEL,
            value: constant.PUSH_TO_STORE_ID,
            disabled: false,
            icon: ImportStoreMajor,
            onAction: () =>
              handleSelectChange(constant.PUSH_TO_STORE_ID, activityLogId, title, _id, linkCard, isProduct),
          };
          const review = {
            content: displayStatus.NEEDSREVIEW,
            value: constant.REVIEW_ALL,
            disabled: false,
            icon: ViewMinor,
            onAction: () => handleSelectChange(constant.REVIEW_ALL, activityLogId, title, _id, linkCard, isProduct),
          };
          const pushToStoreOption =
            status && status[0] && status[0].toLowerCase() === constant.NEW
              ? [pushToStore]
              : [{ ...pushToStore, disabled: true }];
          const reviewOption =
            status && status[0] && status[0] === constant.NEEDS_REVIEW ? [review] : [{ ...review, disabled: true }];

          const viewOption =
            status && status[0] && status[0] !== constant.DELETED.toLowerCase()
              ? [view]
              : [{ ...view, disabled: true }];

          if (isSeller) {
            options = [...viewOption];

            if (status && status[0] && status[0].toLowerCase() === constant.NEW) {
              options.unshift(...pushToStoreOption);
            }
            if (!(status && status[0] && status[0].toLowerCase() === constant.NEW)) {
              options.push(...pushToStoreOption);
            }

            if (status && status[0] && status[0] === constant.NEEDS_REVIEW) {
              options.unshift(...reviewOption);
            }
            if (!(status && status[0] && status[0] === constant.NEEDS_REVIEW)) {
              options.push(...reviewOption);
            }
          }

          if (!isSeller) {
            options = [...viewOption];
          }
        }

        if (isOrder || isProductCsv || isVendorCsv) {
          options = [view];
        }

        if (isReadOnly && currentUserRole === provider) {
          options.forEach((option) => {
            if (option.content === constant.PUSH_TO_STORE_LABEL) {
              // eslint-disable-next-line no-param-reassign
              option.disabled = true;
            }
          });
        }

        const id = _id;

        return (
          <Card sectioned key={_id}>
            <ProductCard className="checkbox-list product-card">
              <div className="list-item">
                <Stack>
                  <Stack.Item>
                    {(isInvite && <Avatar customer size="medium" name="" />) || (
                      <Thumbnail size="large" source={thumbnailUrl} />
                    )}
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="ellipsis">
                      <TextStyle>
                        {!showLink ? (
                          `${title}`
                        ) : (
                          <Link url={linkCard}>
                            <TextStyle>{title}</TextStyle>
                          </Link>
                        )}
                      </TextStyle>
                      <Caption>
                        <TextStyle variation="subdued">
                          {`${constant.UPDATED} ${cms("common.label.on")}:`}
                          {` ${baseHelper.formatDate(createdAt)}`}
                        </TextStyle>
                        <br />
                        {!isInvite && `${cms("common.label.doneBy")}: `}
                        {(isVendor && <Link url="/profile">{currentUser.brandName}</Link>) ||
                          (isSeller && byUserId === currentUser._id ? (
                            by
                          ) : (
                            <Link url={`/providers/view/${vendorId}`}>{by}</Link>
                          ))}
                      </Caption>
                      <Stack>
                        <Stack.Item>
                          <Badge status="info">{baseHelper.ucFirst(label)}</Badge>
                        </Stack.Item>
                        <Stack.Item>
                          <Badge status={firstBadgeType}>{baseHelper.ucFirst(firstBadgeStatus)}</Badge>
                        </Stack.Item>
                        {(isProduct && !ecommercePlatform && (
                          <Stack.Item>
                            <div className="timelineLink history-items">
                              <Button
                                plain
                                removeUnderline
                                disclosure={expanded[id] ? "up" : "down"}
                                onClick={() => {
                                  setActive((prev) => {
                                    return {
                                      ...prev,
                                      [id]: !active[id],
                                    };
                                  });
                                  setExpanded((prev) => {
                                    return {
                                      ...prev,
                                      [id]: !expanded[id],
                                    };
                                  });
                                }}
                              >
                                {cms("common.history.button.history")}
                              </Button>
                            </div>
                          </Stack.Item>
                        )) ||
                          null}
                      </Stack>
                    </div>
                  </Stack.Item>
                  <Stack.Item>
                    <Popover
                      active={activePopover[id]}
                      setActive={() => setActivePopover({ [id]: !activePopover[id] })}
                      loading={
                        _id === selectedButtonIndex &&
                        (loadingRevokeInvitation ||
                          loadingResendInvitation ||
                          loadingEmailTemplate ||
                          loadingBulkProduct ||
                          loadingLakeBulkProduct)
                      }
                      options={options}
                      disabled={isInvite && status && status[0].toLowerCase() === constant.JOINED.toLowerCase()}
                    />
                  </Stack.Item>
                </Stack>
                <Collapsible open={active[id]} id="timeline" transition={{ duration: "150ms", timingFunction: "ease" }}>
                  <div className="activity">
                    <ProductVersioning productId={activityId} setListBanner={setBanner} cms={cms} />
                  </div>
                </Collapsible>
              </div>
            </ProductCard>
          </Card>
        );
      });
    }
    return null;
  };
  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
          <br />
        </>
      )}
      {renderCard()}
    </>
  );
};

export default Activity;
