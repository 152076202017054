import React, { useContext } from "react";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import config from "configuration";

const WixConnect = () => {
  const { cms = () => {}, currentUser: currentUserData, isLoading, history } = useContext(PrivateContext);
  const { brandName = "" } = currentUserData || {};
  const queryParams = baseHelper.getQueryParams(history.location.search);

  const data = {
    code: queryParams.token,
  };

  const keyParam = "?key=wix";
  const route = `/products/add${keyParam}`;

  if (isLoading) {
    let bannerData = false;
    if (!(data && data.code)) {
      bannerData = { title: cms("message.error.token"), status: constant.CRITICAL };
      history.push(route, {
        bannerData,
      });
    }
    const clientId = config.vendorWixAPIKey;
    const redirectUri = baseHelper.getCurrentDomain(window.location, "wix/callback");

    const wixUri =
      "https://www.wix.com/installer/install?" +
      `token=${data.code}&appId=${clientId}&redirectUrl=${redirectUri}&status=${brandName}`;
    setTimeout(() => {
      window.location.href = wixUri;
    });
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Spinner isFullPage />
      <h3 className="text-center" style={{ marginTop: "32%" }}>
        {cms("message.success.information")}
      </h3>
    </div>
  );
};

export default withErrorBoundary(WixConnect);
