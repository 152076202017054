import React, { useState, useEffect, useContext } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import { Modal, RadioButton, Stack, TextStyle, ResourceList, Tabs, Link } from "@shopify/polaris";
import { Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { GET_ORDER_LINES, CHECK_ORDER_LINES } from "app/orders/apollo/queries";

import adminExportOrderProps from "./props";

const AdminExportOrder = (props) => {
  const { isOpenModal, setIsOpenModal } = props;
  const { cms, history } = useContext(PrivateContext);
  const { loading: getOrderLineLoading, data: getOrderLineData } = useQuery(GET_ORDER_LINES);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([[], []]);
  const [vendorList, setVendorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [exportAll, setExportAll] = useState(true);
  const [isPrimaryActionLoading, setPrimaryAction] = useState(false);
  const [exportLink, setExportLink] = useState(false);
  const [isModalClickable, setIsModalClickable] = useState(true);
  const [params, setParams] = useState("");
  const [queryData, setQueryData] = useState({});
  const dataToFetch = {};
  const exportOrderUrl = "/export/order";
  const [
    getCheckOrderList,
    { loading: checkOrderLineLoading, data: checkOrderLineData },
  ] = useLazyQuery(CHECK_ORDER_LINES, { variables: { input: { ...queryData } } });

  useEffect(() => {
    let bannerData = false;
    if (!checkOrderLineLoading && checkOrderLineData) {
      const responseData = baseHelper.getResponseData(checkOrderLineData, constant.gql.CHECK_ORDER_LINES);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkOrderLineData, constant.gql.CHECK_ORDER_LINES) ||
            cms("message.error.noOrder"),
          status: "critical",
        };
      }

      if (bannerData) {
        setPrimaryAction(false);
        setBanner({ ...bannerData, isOpen: true });
      } else {
        const csvDownloadRoute = (exportAll && exportOrderUrl) || `${exportOrderUrl}${params}`;
        setPrimaryAction(false);
        setExportLink(csvDownloadRoute);
      }
    }
  }, [checkOrderLineLoading, checkOrderLineData, exportAll, params, cms]);

  useEffect(() => {
    if (!getOrderLineLoading || getOrderLineData) {
      const orderLines = baseHelper.getResponseData(getOrderLineData, constant.gql.GET_ORDER_LINES);
      if (!orderLines) {
        const bannerValue = {
          status: "critical",
          title: baseHelper.getResponseError(getOrderLineData, constant.gql.GET_ORDER_LINES),
        };
        setBanner({ ...bannerValue, isOpen: true });
      }
      if (!orderLines.length) {
        setIsModalClickable(false);
        return;
      }
      let vendorData = [];
      let statusData = [];
      const fulfillmentStatusData = [];
      const orderStatusData = [];
      orderLines.forEach(({ fulfillmentStatus: fulfillStatus, status, vendor, vendorId }) => {
        statusData.push(
          {
            id: fulfillStatus,
            name: fulfillStatus,
          },
          {
            id: status,
            name: status,
          }
        );
        fulfillmentStatusData.push(fulfillStatus);
        orderStatusData.push(status);
        vendorData.push({
          id: vendorId,
          name: baseHelper.ucFirst(vendor),
        });
      });
      vendorData = baseHelper.removeDuplicateData(vendorData);
      statusData = baseHelper.removeDuplicateData(statusData);
      vendorData = [...baseHelper.sort(vendorData, constant.NAME)];
      statusData = [...baseHelper.sort(statusData, constant.NAME)];
      setStatusList(statusData);
      setVendorList(vendorData);
      setFulfillmentStatus(fulfillmentStatusData);
      setOrderStatus(orderStatusData);
    }
  }, [getOrderLineData, getOrderLineLoading]);

  const exportOrder = {
    label: {
      resourceName: [
        { singular: cms("section.exportOrder.label.vendor"), plural: cms("section.exportOrder.label.vendors") },
        { singular: cms("section.exportOrder.label.status"), plural: cms("section.exportOrder.label.statuses") },
      ],
    },
    exportModalTabs: [
      {
        id: "vendors",
        content: cms("section.exportOrder.label.vendor"),
        panelID: "panel-vendors",
      },
      {
        id: "status",
        content: cms("section.exportOrder.label.status"),
        panelID: "panel-status",
      },
    ],
  };

  const onExport = (isSpecificExport = false) => {
    setPrimaryAction(true);
    let bannerData = false;
    let status = [];
    let vendor = [];
    let fulfillmentStatusNew = [];
    let orderStatusNew = [];

    vendor = [...selectedOptions[0]];
    status = [...selectedOptions[1]];
    fulfillmentStatusNew = status.filter((item) => item && fulfillmentStatus.includes(item));
    orderStatusNew = status.filter((item) => item && orderStatus.includes(item));
    const fulfillmentString = fulfillmentStatusNew.join(",");
    const orderString = orderStatusNew.join(",");
    const vendorString = vendor.join(",");
    if (!(exportAll || vendorString || fulfillmentString || orderString)) {
      bannerData = {
        title: cms("message.error.pleaseSelect"),
        status: "critical",
      };
      setPrimaryAction(false);
      setBanner({ ...bannerData, isOpen: true });
      return;
    }
    if (isSpecificExport) {
      const updatedSelectedOptions = baseHelper.cloneData(selectedOptions);
      if (!selectedTabIndex) {
        setParams(`?vendor=${vendorString}`);
        updatedSelectedOptions[1] = [];
        orderStatusNew = [];
        fulfillmentStatusNew = [];
      } else {
        setParams(`?status=${orderString}&fulfillmentStatus=${fulfillmentString}`);
        updatedSelectedOptions[0] = [];
        vendor = [];
      }
      setSelectedOptions(updatedSelectedOptions);
    }
    setParams(`?vendor=${vendorString}&status=${orderString}&fulfillmentStatus=${fulfillmentString}`);
    if (orderStatusNew.length > 0) {
      dataToFetch.status = orderStatusNew;
    }
    if (fulfillmentStatusNew.length > 0) {
      dataToFetch.fulfillmentStatus = fulfillmentStatusNew;
    }
    if (vendor.length > 0) {
      dataToFetch.vendor = vendor;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
  };

  const toggleBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(exportOrder.exportModalTabs);
    const singularItem = cms("section.exportOrder.label.item");
    const pluralItem = cms("section.exportOrder.label.items");
    const changeIndex = 0;

    selectedOptions.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${cms("section.exportOrder.label.Selected")})`;
      }
    });
    return exportModalTabs;
  };
  const closeModal = () => {
    setSelectedTabIndex(0);
    setSelectedOptions([[], []]);
    setExportAll(true);
    setPrimaryAction(false);
    setExportLink(false);
    setIsOpenModal(false);
    toggleBanner();
  };

  const downloadCSV = () => {
    window.open(exportLink);
    closeModal();
  };

  const handleSelectionChange = (selectedItems) => {
    const prevSelectedOptions = [...selectedOptions];
    prevSelectedOptions[selectedTabIndex] = [...selectedItems];
    setSelectedOptions(prevSelectedOptions);
    setExportLink(false);
  };

  const handleTabChange = (selectedTabInde) => {
    setSelectedTabIndex(selectedTabInde);
    setExportLink(false);
  };

  const items = [vendorList, statusList];
  const updateResourceName = [...exportOrder.label.resourceName];

  const promotedBulkActions = [
    {
      content: cms("section.exportOrder.label.generateCSV"),
      onAction: () => onExport(true),
    },
  ];

  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={() => closeModal()}
        title={cms("section.exportOrder.label.exportOrder")}
        loading={getOrderLineLoading}
        primaryAction={{
          loading: isPrimaryActionLoading,
          content: exportLink
            ? cms("section.exportOrder.label.downloadCSV")
            : cms("section.exportOrder.label.generateCSV"),
          onAction: () => (exportLink ? downloadCSV() : onExport()),
          disabled: !isModalClickable,
        }}
        secondaryActions={[
          {
            content: cms("section.exportOrder.label.cancel"),
            onAction: () => closeModal(),
          },
        ]}
      >
        <Modal.Section>
          {banner.isOpen && (
            <Banner
              key="banner"
              onDismiss={toggleBanner}
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
            />
          )}
          <Stack vertical>
            <RadioButton
              label={cms("section.exportOrder.label.exportAll")}
              helpText={cms("section.exportOrder.label.exportAllText")}
              checked={exportAll}
              name="allOrders"
              onChange={() => {
                setBanner({ isOpen: false });
                setExportAll((prevState) => !prevState);
                setExportLink(false);
              }}
            />
            <RadioButton
              label={cms("section.exportOrder.label.exportSelected")}
              helpText={cms("section.exportOrder.label.exportSelectedText")}
              name="selectedOrders"
              checked={!exportAll}
              onChange={() => {
                setExportAll((prevState) => !prevState);
                setExportLink(false);
                setBanner({ isOpen: false });
              }}
            />
            <br />
            <Link onClick={() => history.push("/setting/order/export")}>{cms("section.export.label.customize")}</Link>
          </Stack>
          {!exportAll && (
            <>
              <Tabs
                key="tabs"
                tabs={getResourceListTabs()}
                selected={selectedTabIndex}
                onSelect={handleTabChange}
                fitted
              />
              <ResourceList
                key="resourceList"
                resourceName={updateResourceName[selectedTabIndex]}
                items={items[selectedTabIndex]}
                renderItem={(item) => {
                  const { id, name, location } = item;
                  const replaceName = {
                    noShow: constant.NOSHOW,
                  };
                  const statusName = name.replace(/\b(?:noShow)\b/gi, (matched) => replaceName[matched]);
                  return (
                    <ResourceList.Item id={id}>
                      <h3>
                        <TextStyle variation="strong">{baseHelper.ucFirst(statusName)}</TextStyle>
                      </h3>
                      <div>{location}</div>
                    </ResourceList.Item>
                  );
                }}
                selectedItems={selectedOptions[selectedTabIndex]}
                onSelectionChange={handleSelectionChange}
                promotedBulkActions={promotedBulkActions}
              />
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

AdminExportOrder.propTypes = adminExportOrderProps.type;

export default AdminExportOrder;
