const csvData = [
  {
    Handle: "example-t-shirt",
    Title: "Example T-Shirt",
    "Body (HTML)": "",
    Vendor: "Brand Name",
    Type: "T-Shirts",
    Tags: "mens t-shirt example",
    Collection: "Collection Name",
    Published: true,
    "Option1 Name": "Title",
    "Option1 Value": 'Lithograph - Height: 9" x Width: 12"',
    "Option2 Name": "",
    "Option2 Value": "",
    "Option3 Name": "",
    "Option3 Value": "",
    "Variant SKU": "Example-t-shirt-m",
    "Variant Grams": 3629,
    "Variant Inventory Tracker": "shopify",
    "Variant Inventory Qty": 1,
    "Variant Inventory Policy": "deny",
    "Variant Price": 25,
    "Variant Compare At Price": 0,
    "Variant Requires Shipping": true,
    "Variant Taxable": true,
    "Variant Barcode": "",
    "Image Src": "http://cdn.shopify.com/s/images/shopify_shirt.png?e6948ca4d9028f9e734b8202cc472e3b89aae0f3",
    "Image Alt Text": "A black t-shirt with the shopify logo",
    "Gift Card": false,
    "Google Shopping / MPN": "7X8ABC910",
    "Google Shopping / Age Group": "Adult",
    "Google Shopping / Gender": "Unisex",
    "Google Shopping / Google Product Category": "Apparel & Accessories > Clothing",
    "SEO Title": "Our awesome T-shirt in 70 characters or less.",
    "SEO Description": "A great description of your products in 160 characters or less",
    "Google Shopping / AdWords Grouping": "T-shirts",
    "Google Shopping / AdWords Labels": "cotton, pre-shrunk",
    "Google Shopping / Condition": "used",
    "Google Shopping / Custom Product": false,
    "Google Shopping / Custom Label 0": "",
    "Google Shopping / Custom Label 1": "",
    "Google Shopping / Custom Label 2": "",
    "Google Shopping / Custom Label 3": "",
    "Google Shopping / Custom Label 4": "",
    "Variant Image": "",
    "Variant Weight Unit": "kg",
  },
  {
    Handle: "example-shirt",
    Title: "Example Shirt",
    "Body (HTML)": "",
    Vendor: "Brand Name",
    Type: "Shirts",
    Tags: "Clothing",
    Collection: "Collection Name",
    Published: true,
    "Option1 Name": "Size",
    "Option1 Value": "S",
    "Option2 Name": "Color",
    "Option2 Value": "Red",
    "Option3 Name": "",
    "Option3 Value": "",
    "Variant SKU": "Example-shirt",
    "Variant Grams": 80,
    "Variant Inventory Tracker": "shopify",
    "Variant Inventory Qty": 5,
    "Variant Inventory Policy": "deny",
    "Variant Price": 699,
    "Variant Compare At Price": 0,
    "Variant Requires Shipping": true,
    "Variant Taxable": true,
    "Variant Barcode": "",
    "Image Src": "https://dictionary.cambridge.org/images/thumb/shirt_noun_002_33400.jpg?version=5.0.81",
    "Image Alt Text": "Formal Shirt",
    "Gift Card": false,
    "Google Shopping / MPN": "",
    "Google Shopping / Age Group": "",
    "Google Shopping / Gender": "",
    "Google Shopping / Google Product Category": "",
    "SEO Title": "",
    "SEO Description": "",
    "Google Shopping / AdWords Grouping": "",
    "Google Shopping / AdWords Labels": "",
    "Google Shopping / Condition": "",
    "Google Shopping / Custom Product": false,
    "Google Shopping / Custom Label 0": "",
    "Google Shopping / Custom Label 1": "",
    "Google Shopping / Custom Label 2": "",
    "Google Shopping / Custom Label 3": "",
    "Google Shopping / Custom Label 4": "",
    "Variant Image": "",
    "Variant Weight Unit": "lb",
    // "Collection Id": "270791213247",
  },
  {
    Handle: "example-shirt",
    Title: "",
    "Body (HTML)": "",
    Vendor: "",
    Type: "",
    Tags: "",
    Collection: "",
    Published: true,
    "Option1 Name": "Size",
    "Option1 Value": "M",
    "Option2 Name": "Color",
    "Option2 Value": "Black",
    "Option3 Name": "",
    "Option3 Value": "",
    "Variant SKU": "",
    "Variant Grams": 80,
    "Variant Inventory Tracker": "shopify",
    "Variant Inventory Qty": 5,
    "Variant Inventory Policy": "deny",
    "Variant Price": 699,
    "Variant Compare At Price": 0,
    "Variant Requires Shipping": true,
    "Variant Taxable": true,
    "Variant Barcode": "",
    "Image Src": "",
    "Image Alt Text": "",
    "Gift Card": false,
    "Google Shopping / MPN": "",
    "Google Shopping / Age Group": "",
    "Google Shopping / Gender": "",
    "Google Shopping / Google Product Category": "",
    "SEO Title": "",
    "SEO Description": "",
    "Google Shopping / AdWords Grouping": "",
    "Google Shopping / AdWords Labels": "",
    "Google Shopping / Condition": "",
    "Google Shopping / Custom Product": false,
    "Google Shopping / Custom Label 0": "",
    "Google Shopping / Custom Label 1": "",
    "Google Shopping / Custom Label 2": "",
    "Google Shopping / Custom Label 3": "",
    "Google Shopping / Custom Label 4": "",
    "Variant Image": "",
    "Variant Weight Unit": "lb",
    // "Collection Id": "270791213247",
  },
  {
    Handle: "example-shirt",
    Title: "",
    "Body (HTML)": "",
    Vendor: "",
    Type: "",
    Tags: "",
    Collection: "",
    Published: true,
    "Option1 Name": "Size",
    "Option1 Value": "L",
    "Option2 Name": "Color",
    "Option2 Value": "Blue",
    "Option3 Name": "",
    "Option3 Value": "",
    "Variant SKU": "",
    "Variant Grams": 80,
    "Variant Inventory Tracker": "shopify",
    "Variant Inventory Qty": 5,
    "Variant Inventory Policy": "deny",
    "Variant Price": 699,
    "Variant Compare At Price": 0,
    "Variant Requires Shipping": true,
    "Variant Taxable": true,
    "Variant Barcode": "",
    "Image Src": "",
    "Image Alt Text": "",
    "Gift Card": false,
    "Google Shopping / MPN": "",
    "Google Shopping / Age Group": "",
    "Google Shopping / Gender": "",
    "Google Shopping / Google Product Category": "",
    "SEO Title": "",
    "SEO Description": "",
    "Google Shopping / AdWords Grouping": "",
    "Google Shopping / AdWords Labels": "",
    "Google Shopping / Condition": "",
    "Google Shopping / Custom Product": false,
    "Google Shopping / Custom Label 0": "",
    "Google Shopping / Custom Label 1": "",
    "Google Shopping / Custom Label 2": "",
    "Google Shopping / Custom Label 3": "",
    "Google Shopping / Custom Label 4": "",
    "Variant Image": "",
    "Variant Weight Unit": "lb",
    // "Collection Id": "270791213247",
  },
  {
    Handle: "example-shirt",
    Title: "",
    "Body (HTML)": "",
    Vendor: "",
    Type: "",
    Tags: "",
    Collection: "",
    Published: true,
    "Option1 Name": "Size",
    "Option1 Value": "XL",
    "Option2 Name": "Color",
    "Option2 Value": "Yellow",
    "Option3 Name": "",
    "Option3 Value": "",
    "Variant SKU": "",
    "Variant Grams": 80,
    "Variant Inventory Tracker": "shopify",
    "Variant Inventory Qty": 5,
    "Variant Inventory Policy": "deny",
    "Variant Price": 699,
    "Variant Compare At Price": 0,
    "Variant Requires Shipping": true,
    "Variant Taxable": true,
    "Variant Barcode": "",
    "Image Src": "",
    "Image Alt Text": "",
    "Gift Card": false,
    "Google Shopping / MPN": "",
    "Google Shopping / Age Group": "",
    "Google Shopping / Gender": "",
    "Google Shopping / Google Product Category": "",
    "SEO Title": "",
    "SEO Description": "",
    "Google Shopping / AdWords Grouping": "",
    "Google Shopping / AdWords Labels": "",
    "Google Shopping / Condition": "",
    "Google Shopping / Custom Product": false,
    "Google Shopping / Custom Label 0": "",
    "Google Shopping / Custom Label 1": "",
    "Google Shopping / Custom Label 2": "",
    "Google Shopping / Custom Label 3": "",
    "Google Shopping / Custom Label 4": "",
    "Variant Image": "",
    "Variant Weight Unit": "lb",
    // "Collection Id": "270791213247",
  },
];

export default csvData;
