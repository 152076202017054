import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card, DisplayText, EmptyState, Layout, Stack } from "@shopify/polaris";

// import private context
import { PrivateConsumer } from "lib/context";

// import library helpers
import { featureHelper, statusHelper } from "lib/helpers";

// import string constants
import constants from "lib/constant/constant";

const { featureError, featureVersion } = constants;

const propTypes = {
  onValidate: PropTypes.func,
  forwardedRef: PropTypes.objectOf(),
};

const defaultProps = {
  onValidate: undefined,
  forwardedRef: null,
};

const pattern = /^((http|https|ftp):\/\/)/;

const withFeature = (WrappedComponent, featureConfig) => {
  class Feature extends React.Component {
    nextPath = (path, history) => {
      if (pattern.test(path)) {
        window.open(path);
      } else {
        history.push(path);
      }
    };

    errorState = ({ error, feature, cms, history }) => {
      const { label, description } = feature;
      const { label: cmsLabel = {}, message: cmsMessage = {} } = cms || {};
      const roleAction = {
        heading: cmsMessage.notAllowed,
        action: {
          content: cmsLabel.dashboard,
          url: "/",
        },
      };
      const tierAction = {
        heading: cmsMessage.upgradePlan,
        action: {
          content: cmsLabel.upgradePlan,
          url: "/update/plan",
        },
      };
      const comingSoon = {
        heading: cmsMessage.availableSoon,
        action: {
          content: cmsLabel.dashboard,
          url: "/",
        },
      };
      const annotated = {
        label: cmsMessage.availableSoon,
        description: "",
      };
      const annotatedText = (label && description && { label, description }) || annotated;
      const errorAction =
        (error === featureError.roleNotAllowed && roleAction) ||
        (error === featureError.needPlanUpgrade && tierAction) ||
        comingSoon;
      return this.renderError(errorAction, annotatedText, history);
    };

    renderError = ({ heading, action, secondaryAction } = {}, annotated = false, history) => {
      const errorLayout = (
        <Layout.AnnotatedSection title={annotated.label} description={annotated.description}>
          <Card title={annotated.label} sectioned>
            <Stack>
              <Stack.Item fill>
                <DisplayText size="small">{heading}</DisplayText>
              </Stack.Item>
              <Stack.Item>
                <Button primary onClick={() => this.nextPath(action.url, history)}>
                  {action.content}
                </Button>
              </Stack.Item>
            </Stack>
          </Card>
        </Layout.AnnotatedSection>
      );

      const error = (annotated && annotated && errorLayout) || (
        <EmptyState
          heading={heading}
          action={{ content: action.content, url: action.url }}
          secondaryAction={secondaryAction && { content: secondaryAction.content, url: secondaryAction.url }}
        />
      );
      return error;
    };

    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <PrivateConsumer>
          {({ oldCMS: cms = {}, currentUser, featureFlag, history }) => {
            const isRoot = history.location.pathname === "/"; // hack for onboarding setup flow
            const response = featureHelper.checkFeature({ currentUser, featureFlag, ...featureConfig });
            const { onValidate } = this.props;
            if (onValidate) {
              onValidate(!(response && response.error));
            }
            if (response && response.error === featureError.hideFeature && !isRoot) {
              return null;
            }
            if (response && response.error && response.feature && !isRoot) {
              return this.errorState({ ...response, cms, history });
            }
            const getSectionTitle = (title) => {
              const { version } = (response && response.feature) || {};
              const betaOrAlpha = [featureVersion.beta, featureVersion.alpha];
              if (betaOrAlpha.includes(version)) {
                return (
                  <Stack>
                    <Stack.Item>{title}</Stack.Item>
                    <Stack.Item fill>
                      <span id={`${version}Badge`}>
                        <Badge status={statusHelper.getBadgeType(version)}>
                          {statusHelper.getBadgeStatus(version)}
                        </Badge>
                      </span>
                    </Stack.Item>
                  </Stack>
                );
              }
              return title;
            };
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <WrappedComponent ref={forwardedRef} {...rest} getSectionTitle={getSectionTitle} isFeatureValid />;
          }}
        </PrivateConsumer>
      );
    }
  }

  Feature.propTypes = propTypes;
  Feature.defaultProps = defaultProps;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return React.forwardRef((props, ref) => <Feature {...props} forwardedRef={ref} />);
};

export default withFeature;
