import React, { useContext, useEffect, useState } from "react";
import { Card, FormLayout, PageActions, Select, Stack, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import mutation
import { ADD_PRODUCT_AS_SERVICE_INFORMATION } from "app/product/apollo/mutations";

// import queries
import { GET_CATALOG, GET_PRODUCT_SETTING, GET_PRODUCT_TAGS, GET_PRODUCT_TYPE } from "app/product/apollo/queries";
import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

// import context
import { OnboardingContext, PrivateContext } from "lib/context";
import { ProductContext } from "app/product/modules/generic/context";

// import component
import Tags from "app/product/modules/generic/features/form/tag/tag";
import Catalog from "app/product/modules/generic/features/form/tag/catalog";

// import proptype
import { tabProp } from "app/product/modules/generic/propTypes";

const Information = () => {
  const { isOnboarding, onNext, onPrevious } = useContext(OnboardingContext);
  const { setBanner } = useContext(ProductContext);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const { AVAILABILITY, CRITICAL, gql } = constant;

  const [comparePrice, setComparePrice] = useState("");
  const [comparePriceData, setComparePriceData] = useState();
  const [data, setData] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionData, setDescriptionData] = useState();
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("");
  const [priceData, setPriceData] = useState();
  const [productFormSetting, setProductFormSetting] = useState();
  const [selectedProductType, setSelectedProductType] = useState("");
  const [tagData, setTagData] = useState();
  const [title, setTitle] = useState("");
  const [typeData, setTypeData] = useState();
  const [catalog, setCatalog] = useState("");
  const [isHideTag, setIsHideTag] = useState(true);
  const [isHideType, setIsHideType] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isHideCollection, setIsHideCollection] = useState(true);

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { data: productSettingData, error: productSettingError, loading: productSettingLoading } = useQuery(
    GET_PRODUCT_SETTING
  );

  const {
    loading: loadingProductForm,
    // error: errorProductForm,
    data: dataProductForm,
    // refetch: refetchProductForm,
  } = useQuery(GET_PRODUCT_FORM);

  const { loading: catalogLoading, data: catalogData } = useQuery(GET_CATALOG);

  const [addServiceItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_AS_SERVICE_INFORMATION);

  // load product catalog data
  const productCatalogOption = baseHelper.getResponseData(catalogData, constant.gql.GET_CATALOG) || {};

  useEffect(() => {
    if (productSettingError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productSettingError });
      return;
    }
    if (!productSettingData) {
      return;
    }
    const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);
    if (productError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productError });
      return;
    }
    const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
    const { isHideTag: hideTag, isHideType: hideType, isCatalogHidden } = productSetting || {};
    setIsHideTag(!!hideTag);
    setIsHideType(!!hideType);
    setIsHideCollection(!!isCatalogHidden);
  }, [CRITICAL, productSettingData, productSettingError, setBanner, setIsHideTag, setIsHideType]);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        delete productLabel.vendor;
        delete productLabel.markup;
        setDescriptionData(productLabel.description);
        setPriceData(productLabel.price);
        setComparePriceData(productLabel.comparePrice);
        setTagData(productLabel.tags);
        setTypeData(productLabel.productType);
        setProductFormSetting(productLabel);
        setCatalog(productLabel.catalog);
      }
    }
  }, [dataProductForm]);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  if (productTagLoading || productTypeLoading || productSettingLoading || loadingProductForm || catalogLoading) {
    return <SkeletonCard />;
  }

  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  const handleChange = (fieldName, fieldValue) => {
    setDisabledButton(false);
    setData({
      ...data,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = (content) => {
    setDescription(content);
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const addService = async (formValues) => {
    try {
      const val = await addServiceItem({
        variables: {
          input: formValues,
        },
      });

      const responseData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_INFORMATION);
      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_INFORMATION);

      if (responseData) {
        if (isOnboarding) {
          onNext();
          return;
        }
        setMessage(cms("service.message.success.add"));
        setTimeout(() => {
          history.push(`service/edit/${encodeURIComponent(responseData)}?tab=${AVAILABILITY}`, { add: true });
        }, 1500);
      }
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const submitCallBack = async () => {
    const formValues = {
      title,
      description,
      productType: selectedProductType,
      tags: data && data.tags,
      shopifyCatalogId: data && data.shopifyCatalogId,
      isService: true,
    };

    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    try {
      // add product
      await addService(formValues);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };

    const dataToCheck = {
      title,
      description,
      comparePrice,
      price,
      catalog: data.shopifyCatalogId || [],
    };

    if (isHideTag) {
      dataToCheck.tags = (data && data.tags && data.tags.length) || null;
    }
    if (isHideType) {
      dataToCheck.productType = selectedProductType;
    }

    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("service.message.error.title"),
      };
      setBanner(banner);
      return;
    }

    const requiredKeys =
      (productFormSetting &&
        Object.keys(productFormSetting).filter(
          (item) => productFormSetting[item] && productFormSetting[item].isRequired
        )) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys
        .filter((item) => {
          if (!isHideTag && item === constant.PRODUCT_TAGS) {
            return false;
          }
          if (!isHideType && item === constant.PRODUCT_TYPE) {
            return false;
          }
          return true;
        })
        .forEach((key) => {
          const valueData = dataToCheck[key];
          if (
            (!valueData || valueData.length === 0 || valueData === "undefined") &&
            constant.INFORMATION_KEY.includes(key)
          ) {
            errorField = productFormSetting[key].label || cms(`label.${key}`) || key;
          }
        });
    }

    if (errorField) {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  const required = (value) => {
    if (value && value.isRequired) {
      return "*";
    }
    return "";
  };

  const getLabel = (value, defaultCMS) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCMS;
  };

  return (
    <>
      <Card title={cms("service.label.information")} id="serviceInformation">
        <Card.Section>
          <p>{cms("service.label.serviceAvailability.description")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("service.label.name")}*`}
              placeholder={cms("service.placeholder.name")}
              error=""
              value={title || ""}
              onChange={(value) => {
                setTitle(value);
                setDisabledButton(false);
              }}
              onBlur={() => title && setTitle(title.trim())}
            />
            {`${getLabel(descriptionData, cms("label.description"))}${required(descriptionData)}`}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={`${getLabel(priceData, cms("label.price"))}${required(priceData)}`}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix={moneyFormat}
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                  setDisabledButton(false);
                }}
              />
              <TextField
                id="comparePrice"
                label={`${getLabel(comparePriceData, cms("label.comparePrice"))}${required(comparePriceData)}`}
                min={0}
                placeholder="0.00"
                prefix={moneyFormat}
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>

            {isHideType ? (
              <Stack vertical>
                {productTypeOptions && productTypeOptions.length && (
                  <Select
                    label={`${getLabel(typeData, cms("service.label.type"))}${required(typeData)}`}
                    options={productTypeOptions}
                    placeholder={cms("service.placeholder.type")}
                    onChange={(value) => setSelectedProductType(value)}
                    value={selectedProductType}
                  />
                )}
              </Stack>
            ) : null}
            {isHideTag ? (
              <Stack vertical>
                {availableProductTags && availableProductTags.tags && availableProductTags.tags.length && (
                  <Tags
                    label={`${getLabel(tagData, cms("service.label.tag"))}${required(tagData)}`}
                    placeholder={cms("service.placeholder.tag")}
                    data={data}
                    setData={setData}
                    handleChange={handleChange}
                    productTag={availableProductTags}
                  />
                )}
              </Stack>
            ) : null}
            {!isHideCollection ? (
              <Catalog
                label={`${getLabel(catalog, cms("service.label.collection"))}${required(catalog)}`}
                placeholder={cms("service.placeholder.collection")}
                data={data}
                isHideCollection={isHideCollection}
                // setData={setData}
                handleChange={handleChange}
                productCatalog={(productCatalogOption && productCatalogOption.catalog) || []}
              />
            ) : null}
            <br />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms(`common.button.${isOnboarding ? "submit" : "save"}`),
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
          disabled: disabledButton,
        }}
        secondaryActions={
          isOnboarding && [
            {
              content: cms("common.button.previous"),
              onAction: onPrevious,
            },
          ]
        }
      />
    </>
  );
};

Information.propTypes = tabProp.type;

export default withFeature(withErrorBoundary(Information), { feature: constant.PRODUCT_ADD_FORM });
