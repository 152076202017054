import { gql } from "apollo-boost";

const GET_WIX_TEMP_PRODUCT_SUBSCRIPTION = gql`
  subscription {
    tempWixProductList {
      data {
        operationType
        tempWixProduct {
          _id
          name
          image
          isShopifySync
          isSynced
          providerIds
          storeProductId
          status
          updatedAt
          createdAt
        }
      }
      error
      status
    }
  }
`;

export default GET_WIX_TEMP_PRODUCT_SUBSCRIPTION;
