import React, { useContext, useState } from "react";
import { Select, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import CanadaPost from "./thirdPartyShipping/canadaPost";
import NZPostShipping from "./thirdPartyShipping/nzpostShipping";
import Sendabox from "./thirdPartyShipping/sendabox";
import Shippo from "./thirdPartyShipping/shippo";
import ShippyPro from "./thirdPartyShipping/shippyPro";
import StarshipIt from "./thirdPartyShipping/starshipit";
import UPS from "./thirdPartyShipping/ups";

const ThirdPartyOptions = (props) => {
  const { dropship, isEcommerceUser, setDropship, handleValidation, error, setSubmitEnable, cms } = useContext(
    FulfillmentContext
  );

  const { isError, selected, setSelected } = props;
  const [input, setInput] = useState({
    isAutoFulfill: true,
  });

  const options = [
    { label: cms("label.canada"), value: constant.CANADA_POST, disabled: false },
    { label: cms("label.NZ"), value: constant.NZPOST, disabled: false },
    { label: cms("label.sendabox"), value: constant.SENDABOX, disabled: false },
    { label: cms("label.shippo"), value: constant.SHIPPO, disabled: false },
    { label: cms("label.shippyPro"), value: constant.SHIPPY_PRO, disabled: false },
    { label: cms("label.starship"), value: constant.STARSHIPIT, disabled: false },
    { label: cms("label.ups"), value: constant.UPS_SMALL, disabled: false },
    { label: cms("label.eazy"), value: constant.EAZY_SHIP, disabled: true },
    { label: cms("label.ship"), value: constant.SHIP_STATION, disabled: true },
    { label: cms("label.flvr"), value: constant.FLVR_CLOUD, disabled: true },
  ];

  const ecommerceUserOption = [{ label: cms("label.shippo"), value: constant.SHIPPO, disabled: false }];

  const handleCanadaPost = async (field, value) => {
    const oldCanadaPost = (dropship && dropship.canadaPost) || {};
    await setDropship((prevState) => ({
      ...prevState,
      canadaPost: { ...oldCanadaPost, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleNZPost = async (field, value) => {
    const oldNZPost = (dropship && dropship.nzPost) || {};
    await setDropship((prevState) => ({
      ...prevState,
      nzPost: { ...oldNZPost, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleSendabox = async (field, value) => {
    const oldSendabox = (dropship && dropship.sendabox) || {};
    await setDropship((prevState) => ({
      ...prevState,
      sendabox: { ...oldSendabox, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleStarshipChange = async (value) => {
    await setDropship((prevState) => ({
      ...prevState,
      starshipit: { apiKey: value },
    }));
    setSubmitEnable(true);
  };

  const handleShippo = async (field, value) => {
    const oldShippo = (dropship && dropship.shippo) || {};
    await setDropship((prevState) => ({
      ...prevState,
      shippo: { ...oldShippo, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleShippyPro = async (field, value) => {
    const oldShippyPro = (dropship && dropship.shippyPro) || {};
    await setDropship((prevState) => ({
      ...prevState,
      shippyPro: { ...oldShippyPro, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleUpsApiChange = async (field, value) => {
    const oldUPS = (dropship && dropship.ups) || {};
    await setDropship((prevState) => ({
      ...prevState,
      ups: { ...oldUPS, [field]: value },
    }));
    setSubmitEnable(true);
  };

  const handleSelect = async (value) => {
    setSelected(value);
    const newDropship = dropship;
    if (value === constant.NZPOST) {
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.starshipit;
      delete newDropship.shippo;
      delete newDropship.shippyPro;
      delete newDropship.ups;
      newDropship.nzPost = {};
    }
    if (value === constant.CANADA_POST) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.starshipit;
      delete newDropship.shippo;
      delete newDropship.shippyPro;
      delete newDropship.ups;
      newDropship.canadaPost = {};
    }
    if (value === constant.SENDABOX) {
      delete newDropship.nzPost;
      delete newDropship.canadaPost;
      delete newDropship.shippo;
      delete newDropship.shippyPro;
      delete newDropship.starshipit;
      delete newDropship.ups;
      newDropship.sendabox = {};
    }
    if (value === constant.SHIPPO) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.shippyPro;
      delete newDropship.starshipit;
      delete newDropship.ups;
      newDropship.shippo = {};
    }
    if (value === constant.SHIPPO) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.starshipit;
      delete newDropship.shippyPro;
      delete newDropship.ups;
      newDropship.shippo = {};
    }
    if (value === constant.SHIPPY_PRO) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.shippo;
      delete newDropship.starshipit;
      delete newDropship.ups;
      newDropship.shippyPro = {};
    }
    if (value === constant.STARSHIPIT) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.shippyPro;
      delete newDropship.shippo;
      newDropship.starshipit = {};
    }
    if (value === constant.UPS_SMALL) {
      delete newDropship.nzPost;
      delete newDropship.sendabox;
      delete newDropship.canadaPost;
      delete newDropship.sendabox;
      delete newDropship.shippo;
      delete newDropship.shippyPro;
      delete newDropship.starshipit;
      newDropship.ups = {};
    }
    newDropship.shippingOption = value;
    setSelected(value);
    await setDropship(newDropship);
    // await setDropship((prevState) => ({
    //   ...prevState,
    //   shippingOption: value,
    // }));
  };

  return (
    <FormLayout>
      <Select
        label={cms("label.thirdParty")}
        placeholder={cms("placeholder.shippingOption")}
        options={isEcommerceUser ? ecommerceUserOption : options}
        onChange={(value) => handleSelect(value)}
        value={selected}
      />
      {selected === constant.NZPOST && (
        <NZPostShipping
          dropship={dropship}
          error={error}
          handleNZPost={handleNZPost}
          handleValidation={handleValidation}
        />
      )}
      {selected === constant.CANADA_POST && (
        <CanadaPost
          dropship={dropship}
          error={error}
          handleCanadaPost={handleCanadaPost}
          handleValidation={handleValidation}
        />
      )}
      {selected === constant.SENDABOX && (
        <Sendabox
          dropship={dropship}
          handleSendabox={handleSendabox}
          error={error}
          handleValidation={handleValidation}
          isError={isError}
        />
      )}
      {selected === constant.STARSHIPIT && (
        <StarshipIt
          dropship={dropship}
          handleStarshipChange={handleStarshipChange}
          error={error}
          handleValidation={handleValidation}
        />
      )}

      {selected === constant.SHIPPO && (
        <Shippo handleShippo={handleShippo} error={error} handleValidation={handleValidation} />
      )}

      {selected === constant.SHIPPY_PRO && (
        <ShippyPro handleShippyPro={handleShippyPro} error={error} handleValidation={handleValidation} />
      )}

      {selected === constant.UPS_SMALL && (
        <UPS
          handleUpsApiChange={handleUpsApiChange}
          error={error}
          handleValidation={handleValidation}
          input={input}
          setInput={setInput}
        />
      )}
    </FormLayout>
  );
};

ThirdPartyOptions.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ThirdPartyOptions), {
  feature: constant.ADVANCE_DROPSHIP,
  // subFeature: constant.ADVANCE_DROPSHIP,
});
