const hybridTabs = (cms, hybridOption, constant) => {
  const tabs = [
    {
      id: "hybridDropship",
      content: cms("tab.dropShip"),
      panelID: "hybridDropship",
      fulfillmentType: constant.HYBRID_DROPSHIP,
    },
    {
      id: "hybridConsignmentShip",
      content: cms("tab.consignmentShip"),
      panelID: "hybridConsignmentShip",
      fulfillmentType: constant.HYBRID_CONSIGNMENT,
    },
    {
      id: "hybridConsignmentPickup",
      content: cms("tab.consignmentPick"),
      panelID: "hybridConsignmentPickup",
      fulfillmentType: constant.HYBRID_PICKUP,
    },
    {
      id: "hybridClickCollect",
      content: cms("tab.clickCollect"),
      panelID: "hybridClickCollect",
      fulfillmentType: constant.HYBRID_COLLECT,
    },
    {
      id: "hybridClickReserve",
      content: cms("tab.clickReserve"),
      panelID: "hybridClickReserve",
      fulfillmentType: constant.HYBRID_RESERVE,
    },
    {
      id: "hybridTimeService",
      content: cms("tab.timeService"),
      panelID: "hybridTimeService",
      fulfillmentType: constant.HYBRID_SERVICE,
    },
  ];

  return tabs.filter(({ fulfillmentType }) => {
    const fulfillment = hybridOption.find((item) => item.value === fulfillmentType);
    return fulfillment && !fulfillment.disabled;
  });
};

export default hybridTabs;

export const getPanelComponent = (selectedTab) => {
  return hybridTabs[selectedTab].PanelComponent();
};
