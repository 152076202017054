import React, { useContext } from "react";
import { Card, TextField, Layout, Stack, Button, TextContainer } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import PropTypes from "prop-types";

const SocialLinkSection = (props) => {
  const { cms = {} } = useContext(PrivateContext);
  const { isSocialLinkEnable, toggleSection, socialLinkLoading } = props;
  const getSocialLinkSectionTitle = () => {
    return (
      <Stack>
        <Stack.Item fill>{cms("section.social.title")}</Stack.Item>
        <Stack.Item>
          <Button
            destructive={isSocialLinkEnable}
            secondary={isSocialLinkEnable}
            primary={!isSocialLinkEnable}
            onClick={toggleSection}
            loading={socialLinkLoading}
          >
            {isSocialLinkEnable ? cms("button.disable") : cms("button.enable")}
          </Button>
        </Stack.Item>
      </Stack>
    );
  };

  const getFields = () => {
    const fields = [
      {
        label: cms("section.social.label.facebook"),
        placeholder: cms("section.social.placeHolder.facebook"),
        isRequired: false,
      },
      {
        label: cms("section.social.label.youtube"),
        placeholder: cms("section.social.placeHolder.youtube"),
        isRequired: false,
      },
      {
        label: cms("section.social.label.twitter"),
        placeholder: cms("section.social.placeHolder.twitter"),
        isRequired: false,
      },
      {
        label: cms("section.social.label.instagram"),
        placeholder: cms("section.social.placeHolder.instagram"),
        isRequired: false,
      },
      {
        label: cms("section.social.label.linkedin"),
        placeholder: cms("section.social.placeHolder.linkedin"),
        isRequired: false,
      },
    ];
    return fields.map(({ label, placeholder, isRequired }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField label={`${label}${required}`} placeholder={placeholder} value="" disabled />
          <br />
        </>
      );
    });
  };

  return (
    <Layout.AnnotatedSection title={getSocialLinkSectionTitle()} description={cms("section.social.description")}>
      <Card title={cms("section.social.subTitle")}>
        <Card.Section>
          <TextContainer>{cms("section.social.subDescription")}</TextContainer>
          <br />
          {getFields()}
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

SocialLinkSection.propTypes = {
  toggleSection: PropTypes.func.isRequired,
  isSocialLinkEnable: PropTypes.bool.isRequired,
  socialLinkLoading: PropTypes.bool.isRequired,
};

export default SocialLinkSection;
