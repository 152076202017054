import PropTypes from "prop-types";

const customerProp = {
  type: {
    data: PropTypes.object,
  },
};
const fulfillmentsProp = {
  type: {
    lineData: PropTypes.array,
  },
};
const fullfillmentStatusProp = {
  type: {
    lineData: PropTypes.arrayOf.object,
    data: PropTypes.object,
  },
};

export { customerProp, fulfillmentsProp, fullfillmentStatusProp };
