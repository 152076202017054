import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import config from "configuration";
import PrivateContainer from "./privateContainer";

// import helpers
import { storageHelper, baseHelper } from "../../lib/helpers";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const url = baseHelper.parseUrl(config.rootURL);

  const isCustomDomain = window.location.hostname !== url.hostname;
  const reloadApp = () => {
    const shop = storageHelper.get("shop");
    if (shop) {
      const shopifyURL = baseHelper.getShopifyURL(shop);
      if (shopifyURL) {
        window.top.location.href = shopifyURL;
      }
    }
  };

  const redirectToLogin = (routeProps) => {
    return (
      <Redirect
        to={{
          pathname:
            // eslint-disable-next-line no-bitwise
            isCustomDomain & ["uat-qa-1.marketcube.io", "innoterra.marketcube.io"].includes(window.location.hostname) ? "/prelogin" : "/login",
          state: { from: routeProps.location },
        }}
      />
    );
  };

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps) => {
        // if inside iFrame and there is no token in browser storage
        // getShopifyURL and reload the app
        const { pathname: currentPath } = routeProps.location;
        const isIframe = baseHelper.isIframe();
        if (isIframe && !storageHelper.get("token")) {
          reloadApp();
        }
        if (!storageHelper.get("token")) {
          return redirectToLogin(routeProps);
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <PrivateContainer component={Component} {...routeProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;
