// import packages
import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";

import {
  Caption,
  Card,
  Checkbox,
  Layout,
  PageActions,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import { PrivateContext } from "lib/context";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";
import {
  GET_DISCOUNT_SETTING,
  GET_DISCOUNT_CODE_SETTING,
  UPDATE_DYNAMIC_DISCOUNT_SETTING,
  UPDATE_DISCOUNT_CODE_SETTING,
} from "app/setup/apollo";

import { Banner, SkeletonAnnotated, Toast } from "lib/components/index";
import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";
import Add from "./components/add";

const OperatorDynamicDiscount = () => {
  const { cms, history } = useContext(PrivateContext);
  const [allVendor, setAllVendor] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [submitEnabledCode, setSubmitEnableCode] = useState(false);
  const [isDiscountEnabled, setIsDiscountEnabled] = useState(false);
  const [isCheckEnabled, setIsCheckEnabled] = useState("");
  const [isCheckEnabledCode, setIsCheckEnabledCode] = useState("");
  const [message, setMessage] = useState("");
  const [prefix, setPrefix] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedCodeDate, setUpdatedCodeDate] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [updateDynamicDiscountSetting] = useMutation(UPDATE_DYNAMIC_DISCOUNT_SETTING);
  const [updateDiscountCodeSetting, { loading: discountCodeLoading }] = useMutation(UPDATE_DISCOUNT_CODE_SETTING);

  const { error: errorVendorBrandList, loading: loadingVendorBrandList, data: dataVendorBrandList } = useQuery(
    GET_VENDOR_BRAND_LIST
  );

  const vendorBrandListResponseData = baseHelper.getResponseData(
    dataVendorBrandList,
    constant.gql.GET_VENDOR_BRAND_LIST
  );
  const vendorBrandListErrorData = baseHelper.getResponseError(dataVendorBrandList, constant.gql.GET_VENDOR_BRAND_LIST);

  const handleChange = (value) => {
    setIsCheckEnabled(value);
    setSubmitEnable(true);
    setAllVendor(true);
    if (allVendor) {
      setVendors([]);
    }
  };

  const handleDiscountCodeChange = (value) => {
    setBannerStatus({ isOpen: false, title: "", status: "" });
    setIsCheckEnabledCode(value);
    setSubmitEnableCode(true);
  };

  useEffect(() => {
    if (vendorBrandListResponseData) {
      const vendorNameList =
        vendorBrandListResponseData &&
        vendorBrandListResponseData.map((value) => {
          return {
            label: value.brandName,
            value: value._id,
          };
        });
      if (vendors && vendors.length) {
        let vendorSelected = [];
        vendorSelected = vendors.map((data) => {
          return data.vendorId.length && data.vendorId;
        });
        const updatedVendorList = vendorNameList.map((item) => {
          const { value = "" } = item;
          const vendorData = { ...item };
          vendorData.disabled = vendorSelected.includes(value);
          return vendorData;
        });
        setVendorList(updatedVendorList || []);
      } else {
        setVendorList(vendorNameList);
      }
    }
  }, [vendorBrandListResponseData, vendors]);

  const {
    error: dynamicDiscountError,
    loading: loadingGetDiscountSetting,
    data: dynamicDiscountData,
    refetch,
  } = useQuery(GET_DISCOUNT_SETTING);

  const {
    error: discountCodeError,
    loading: loadingGetDiscountCodeSetting,
    data: discountCodeData,
    refetch: discountCodeRefetch,
  } = useQuery(GET_DISCOUNT_CODE_SETTING);

  const dynamicDiscountResponseData = baseHelper.getResponseData(
    dynamicDiscountData,
    constant.gql.GET_DISCOUNT_SETTING
  );

  const discountCodeResponseData = baseHelper.getResponseData(discountCodeData, constant.gql.GET_DISCOUNT_CODE_SETTING);

  const dynamicDiscountErrorData = baseHelper.getResponseError(dynamicDiscountData, constant.gql.GET_DISCOUNT_SETTING);
  const discountCodeErrorData = baseHelper.getResponseError(discountCodeData, constant.gql.GET_DISCOUNT_CODE_SETTING);

  useEffect(() => {
    if (dynamicDiscountResponseData && vendorBrandListResponseData) {
      const { isAllowed, isAllVendor = true, selectedVendor, updatedAt } = dynamicDiscountResponseData || {};
      if (isAllowed) {
        setIsCheckEnabled(isAllowed);
        setIsDiscountEnabled(isAllowed);
        if (isAllVendor) {
          setAllVendor(isAllVendor);
          setVendors([]);
        } else {
          let vendordata = selectedVendor.map((value) => ({ vendorId: value }));
          vendordata = vendordata.filter((element) =>
            vendorBrandListResponseData.find((vendor) => vendor._id === element.vendorId)
          );
          setAllVendor(isAllVendor);
          setVendors(vendordata);
        }
      }
      setUpdatedDate(updatedAt);
    }
  }, [dynamicDiscountResponseData, vendorBrandListResponseData]);

  useEffect(() => {
    if (discountCodeResponseData) {
      const { isEnabled, prefixCode, updatedAt } = discountCodeResponseData || {};
      if (isEnabled) {
        setIsCheckEnabledCode(isEnabled);
        setPrefix(prefixCode);
      } else {
        setPrefix("");
      }
      setUpdatedCodeDate(updatedAt);
    }
  }, [discountCodeResponseData]);

  useEffect(() => {
    if (vendorBrandListErrorData) {
      setBannerStatus({ status: constant.CRITICAL, title: vendorBrandListErrorData, isOpen: true });
    }
    if (errorVendorBrandList) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(errorVendorBrandList), isOpen: true });
    }
    if (dynamicDiscountErrorData) {
      setBannerStatus({ status: constant.CRITICAL, title: dynamicDiscountErrorData, isOpen: true });
    }
    if (dynamicDiscountError) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(dynamicDiscountError), isOpen: true });
    }
    if (discountCodeErrorData) {
      setBannerStatus({ status: constant.CRITICAL, title: discountCodeErrorData, isOpen: true });
    }
    if (discountCodeError) {
      setBannerStatus({ status: constant.CRITICAL, title: errorHelper.parse(discountCodeError), isOpen: true });
    }
  }, [
    vendorBrandListErrorData,
    errorVendorBrandList,
    dynamicDiscountErrorData,
    dynamicDiscountError,
    setBannerStatus,
    discountCodeErrorData,
    discountCodeError,
  ]);

  const onSubmit = async (enable) => {
    setSubmitEnable(true);
    let formData;
    if (enable) {
      const selectedVendor = vendors.map(({ vendorId }) => vendorId);
      formData = {
        isAllVendor: allVendor,
        isAllowed: enable,
        selectedVendor: (!allVendor && selectedVendor) || [],
      };
    } else {
      formData = {
        isAllVendor: allVendor || false,
        isAllowed: enable,
        selectedVendor: [],
      };
    }
    try {
      const response = await updateDynamicDiscountSetting({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_DYNAMIC_DISCOUNT_SETTING);
      setIsLoading(false);

      if (responseError) {
        setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (enable && !updatedDate) {
        setMessage(cms("message.success.save"));
      } else {
        setMessage(cms("message.success.update"));
      }
      refetch();
      discountCodeRefetch();
    } catch (exception) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };

  const handleSubmit = () => {
    let isSubmit = true;
    if (vendors) {
      vendors.forEach((element) => {
        if (!element.vendorId && !allVendor) {
          setBannerStatus({
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.row"),
          });
          isSubmit = false;
        } else {
          setBannerStatus({ isOpen: false, title: "", status: "" });
        }
      });
    }
    if (vendors.length === 0 && !allVendor) {
      setBannerStatus({
        isOpen: true,
        title: cms("message.error.atleast"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      setIsLoading(true);
      onSubmit(isCheckEnabled);
    }
  };

  const onCodeSubmit = async (enable) => {
    setSubmitEnableCode(true);
    const formData = {
      isEnabled: enable,
      prefixCode: enable ? prefix : "",
    };
    try {
      const response = await updateDiscountCodeSetting({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_DISCOUNT_CODE_SETTING);

      if (responseError) {
        setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (enable && !updatedCodeDate) {
        setMessage(cms("section.discountCode.message.success.save"));
      } else {
        setMessage(cms("section.discountCode.message.success.update"));
      }
      setTimeout(() => {
        refetch();
        discountCodeRefetch();
      }, 2000);
    } catch (exception) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnableCode(false);
  };

  const handleSubmitCode = () => {
    let isSubmit = true;
    if (isCheckEnabledCode && !prefix) {
      setBannerStatus({
        isOpen: true,
        title: cms("section.discountCode.message.error.prefixRequired"),
        status: constant.CRITICAL,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      onCodeSubmit(isCheckEnabledCode);
    }
  };

  const handleEnabledCheckBox = (value) => {
    setBannerStatus({ isOpen: false, title: "", status: "" });
    setAllVendor(value);
    setSubmitEnable(true);
  };

  const handlePrefixCodeChange = (value) => {
    const regexCheck = new RegExp(/^[A-Za-z0-9]*$/);
    setBannerStatus({ isOpen: false, title: "", status: "" });
    const isValidInput = regexCheck.test(value);
    if (isValidInput) {
      setPrefix(value);
      setSubmitEnableCode(value);
    }
  };

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  if (loadingVendorBrandList || loadingGetDiscountSetting || loadingGetDiscountCodeSetting) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card
          title={[
            cms("section.discount.title"),
            updatedDate && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedDate)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <Stack vertical>
              <TextContainer>{cms("section.discount.description")}</TextContainer>
            </Stack>
            <br />
            <Checkbox label={cms("label.offer")} checked={isCheckEnabled} onChange={handleChange} />
            <br />
            {isCheckEnabled && (
              <>
                <br />
                <TextContainer>{cms("label.selectVendor")}</TextContainer>
                <br />
                <Stack vertical fill>
                  <RadioButton
                    label={cms("label.allow")}
                    checked={allVendor}
                    onChange={(value) => handleEnabledCheckBox(value)}
                  />
                  <RadioButton
                    label={cms("label.selectAllow")}
                    checked={!allVendor}
                    onChange={(value) => handleEnabledCheckBox(!value)}
                  />
                  {!allVendor && (
                    <Add
                      vendorList={vendorList}
                      isSelectedVendor={!allVendor}
                      vendors={vendors}
                      setVendors={setVendors}
                      setSubmitEnable={setSubmitEnable}
                      cms={cms}
                    />
                  )}
                </Stack>
              </>
            )}
            <br />
            {updatedDate && (
              <div className="manageVendorAccessButton manage-link">
                <PageActions
                  primaryAction={{
                    id: "submitDiscountSetting",
                    content: cms("common.button.update"),
                    disabled: !submitEnabled,
                    loading: isLoading,
                    onAction: () => handleSubmit(),
                  }}
                  secondaryActions={[
                    {
                      id: "cancelDiscountSetting",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {!updatedDate && (
          <PageActions
            primaryAction={{
              id: "submitDiscountSetting",
              content: cms("common.button.submit"),
              disabled: !submitEnabled,
              loading: isLoading,
              onAction: () => handleSubmit(),
            }}
            secondaryActions={[
              {
                id: "cancelDiscountSetting",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
      <div>
        <Layout.AnnotatedSection
          title={cms("section.discountCode.title")}
          description={cms("section.discountCode.description")}
        >
          <Card
            title={[
              cms("section.discountCode.section.discount.title"),
              updatedCodeDate && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedCodeDate)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <Stack vertical>
                <TextContainer>{cms("section.discountCode.section.discount.description")}</TextContainer>
              </Stack>
              <br />
              <Checkbox
                label={cms("section.discountCode.label.allow")}
                checked={isCheckEnabledCode}
                onChange={handleDiscountCodeChange}
                disabled={!isDiscountEnabled}
              />
              <br />
              {isCheckEnabledCode && (
                <>
                  <br />
                  <Stack vertical fill>
                    <TextField
                      label={cms("section.discountCode.label.prefix")}
                      value={prefix}
                      maxLength={5}
                      onChange={(value) => handlePrefixCodeChange(value.trim())}
                    />
                  </Stack>
                </>
              )}
              <br />
              {updatedCodeDate && (
                <div className="manageVendorAccessButton manage-link">
                  <PageActions
                    primaryAction={{
                      id: "submitDiscountSetting",
                      content: cms("common.button.update"),
                      disabled: !submitEnabledCode || !isDiscountEnabled || (isCheckEnabledCode && !prefix),
                      loading: discountCodeLoading,
                      onAction: () => handleSubmitCode(),
                    }}
                    secondaryActions={[
                      {
                        id: "cancelDiscountSetting",
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </div>
              )}
            </Card.Section>
          </Card>
          {!updatedCodeDate && (
            <PageActions
              primaryAction={{
                id: "submitDiscountSetting",
                content: cms("common.button.submit"),
                disabled: !submitEnabledCode || !isDiscountEnabled || (isCheckEnabledCode && !prefix),
                loading: discountCodeLoading,
                onAction: () => handleSubmitCode(),
              }}
              secondaryActions={[
                {
                  id: "cancelDiscountSetting",
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
          <div className="toast">
            <Toast message={message} setToast={setMessage} />
          </div>
        </Layout.AnnotatedSection>
      </div>
      <br />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorDynamicDiscount), {
  feature: constant.ORDER_DISCOUNT_SETTING,
});
