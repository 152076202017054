import { gql } from "apollo-boost";

const featureData = `
user {
    permission {
      level
      role
    }
    tier
    group {
      seller
      vendor
      systemAdmin
    }
  }
  hide {
    permission {
      role
      level
    }
  }
  _id
  time {
    duration {
      days
    }
  }
  location {
    country
    region
    city
  }
  device {
    mobile {
      browser
      os
    }
    web {
      os
      browser
    }
  }
  key
  label
  description
  version`;

const GET_MODULE = gql`
  query getModule {
    getModule {
      data {
        ${featureData}
      }
      status
      error
    }
  }
`;
const GET_ALL_USER_FOR_FEATURE = gql`
  query getAllUserForFeature {
    getAllUserForFeature {
      data {
        shops {
          label
          value
        }
        brandNames {
          label
          value
        }
      }
      status
      error
    }
  }
`;
const GET_FEATURE_BY_PARENT_ID = gql`
  query getFeatureByParentId($input: GetFeatureId) {
    getFeatureByParentId(input: $input) {
      status
      data {
        moduleId
        parentId
        ${featureData}
        features {
            moduleId
            parentId
            ${featureData}
        }
        dependency {
            moduleId
            parentId
            ${featureData}
            features {
                moduleId
                parentId
                ${featureData}
            }
        }
      }
      error
    }
  }
`;

export { GET_MODULE, GET_ALL_USER_FOR_FEATURE, GET_FEATURE_BY_PARENT_ID };
