import { gql } from "apollo-boost";

const UPDATE_INVITATION_EMAIL_STATUS = gql`
  mutation updateInvitationEmailStatus($input: UpdateInvitationEmailStatus!) {
    updateInvitationEmailStatus(input: $input) {
      data
      status
      error
    }
  }
`;

const BULK_INVITATION_BY_DATA = gql`
  mutation bulkInvitationByData($input: BulkInviteData) {
    bulkInvitationByData(input: $input) {
      data
      status
      error
    }
  }
`;

export { UPDATE_INVITATION_EMAIL_STATUS, BULK_INVITATION_BY_DATA };
