import React, { useContext } from "react";

import { Card, DataTable, Link, Stack, TextStyle } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

import { fulfillmentsProp } from "app/orderLake/modules/operator/features/view/propsType";

const Fulfillments = (props) => {
  const { lineData } = props;
  const { cms } = useContext(PrivateContext);
  let fulfillments = lineData.map((item) => {
    const { productId } = item;
    if (item.trackingCompany || item.trackingNumber) {
      return [
        <Stack.Item fill>
          <Link onClick={() => props.history.push(`/product-lake/edit/${productId}`)}>{item.name}</Link>
        </Stack.Item>,

        <Stack.Item>{item.trackingCompany}</Stack.Item>,
        <Stack.Item>{item.trackingNumber}</Stack.Item>,
      ];
    }
    return null;
  });

  fulfillments = fulfillments.filter((item) => item);
  return (
    <div className="add-ellipsis">
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "numeric", "numeric"]}
          headings={[cms("label.product"), cms("label.shippingCompany"), cms("label.trackingNumber")]}
          rows={fulfillments}
        />
      </Card.Section>
      <Card>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">Total Fulfilments</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${fulfillments.length} of ${lineData.length}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

Fulfillments.propTypes = fulfillmentsProp.type;

export default Fulfillments;
