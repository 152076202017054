import { gql } from "apollo-boost";

const GET_CURRENCY_PAYOUT = gql`
  query {
    getCurrencyPayoutSetting {
      error
      status
      data {
        currency {
          rate {
            currencyCode
            currencySymbol
            price
            isPayment
          }
          payment {
            isAllVendor
            selectedVendor {
              vendorId
              currencyCode
            }
            updatedAt
          }
        }
        currencyName
      }
    }
  }
`;

export default GET_CURRENCY_PAYOUT;
