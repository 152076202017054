import { gql } from "apollo-boost";

const GET_PRODUCT_AND_SELLER_PRODUCT = gql`
  query getProductAndSellerProduct($input: GetProductSeller) {
    getProductAndSellerProduct(input: $input) {
      data {
        sellerProduct {
          _id
          addedBy
          approved
          attachments {
            fileId
            fileName
            fileURL
          }
          barcode
          basePricing {
            currencyCode
          }
          compaign
          listingQuantity
          comparePrice
          customMetafields {
            key
            value
          }
          customInfo {
            wholesalePrice
          }
          customCharge
          customMetafields {
            key
            value
          }
          customLabel
          customRule
          createdAt
          deletedAt
          deletedBy
          description
          discountAmount
          discountRule
          fulfillmentService
          handle
          images {
            imageId
            imageUrl
            position
            url
          }
          inventoryItemId
          inventoryManagement
          isService
          isShipping
          isTaxable
          lastImagePosition
          lastVariantPosition
          markUp {
            price
            type
          }
          metafield {
            key
            namespace
            value
          }
          markUpAmount
          markUpRule
          moneyFormat
          option1
          option2
          option3
          price
          priceWithMarkup
          primaryImageUrl
          productId
          productType
          published
          publishedAt
          quantity
          reason {
            createdAt
            message
            type
          }
          sellerDiscount {
            price
            type
          }
          sellerId
          serviceId
          shopifyCatalogId
          shopifyProductId
          sku
          status
          tags
          title
          updatedAt
          variants {
            barcode
            comparePrice
            customCharge
            customLabel
            customRule
            fulfillmentService
            image
            imageId
            markUp {
              type
              price
            }
            imageUrl
            inventoryManagement
            inventoryPolicy
            inventoryQuantity
            isShipping
            isTaxable
            option1
            option1Val
            option2
            option2Val
            option3
            option3Val
            position
            price
            quantity
            sku
            title
            vendorDiscount {
              price
              type
            }
            weight
            weightUnit
            measurement {
              length {
                value
                unit
              }
              width {
                value
                unit
              }
              height {
                value
                unit
              }
            }
          }
          vendor
          vendorDiscount {
            price
            type
          }
          vendorId
          vendorShopifyProductId
          weight
          weightUnit
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
        }
        product {
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
          weight
          weightUnit
        }
      }
      status
      error
    }
  }
`;
export default GET_PRODUCT_AND_SELLER_PRODUCT;
