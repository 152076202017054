import { gql } from "apollo-boost";

const GET_METAFIELD_SETTING = gql`
  query {
    getMetafieldSetting {
      error
      status
      data {
        metafield {
          contentType
          key
          name
          namespace
          value
          valueType
          isRequired
        }
        updatedAt
      }
    }
  }
`;

export default GET_METAFIELD_SETTING;
