import { yupHelper } from "lib/helpers";
import { validationSize, validationColor, validationMaterial } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const validate = async (field, value) => {
  let errorMessage = "";
  switch (field) {
    case "option1":
      errorMessage = validateValue(validationSize, { size: value });
      break;
    case "option2":
      errorMessage = validateValue(validationColor, { color: value });
      break;
    case "option3":
      errorMessage = validateValue(validationMaterial, { material: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(errorMessage);
};

export default validate;
