import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";
import { Layout, Card, ChoiceList, PageActions } from "@shopify/polaris";
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Banner, Sheet } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { UPDATE_ABOUT, SET_HUB_CONTACT_PROPERTIES } from "app/onboarding/apollo/mutations";
import validate from "./yup";

const { onboardingSteps } = constant;

const About = (props) => {
  const { cms, currentUser } = useContext(PrivateContext);
  const {
    gql: { UPDATE_ABOUT: UPDATE_ABOUT_TEXT, SET_HUB_CONTACT_PROPERTIES: SET_HUB_CONTACT },
  } = constant;
  const { setSelectedTab } = props;
  const { generalInfo = {} } = currentUser;
  const [selectedProduct, setSelectedProduct] = useState((generalInfo && generalInfo.isSellOnline) || "");
  const [selectedStore, setSelectedStore] = useState((generalInfo && generalInfo.storeType) || "");
  const isVendorOnboardingNull = generalInfo === null || generalInfo.isVendorOnboarding === null;
  const isVendorOnboardingValue = generalInfo && generalInfo.isVendorOnboarding ? "yes" : "no";
  const [selectedVendor, setSelectedVendor] = useState(isVendorOnboardingNull ? "" : isVendorOnboardingValue);
  const [selectedMultiVendor, setSelectedMultiVendor] = useState(
    (generalInfo && generalInfo.marketplaceUnderstanding) || ""
  );
  const revenueData = {
    "0": "zero",
    "250K": "twoFifty",
    "750K": "sevenFifty",
    "750K+": "moreThanSevenFifty",
  };

  const annualRevenue = (generalInfo && generalInfo.annualRevenue) || "";

  const revenueValue = annualRevenue ? revenueData[annualRevenue] : "";

  const [selectedRevenue, setSelectedRevenue] = useState(revenueValue);
  const [error, setError] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);

  const [updateAbout, { loading: updateAboutLoading }] = useMutation(UPDATE_ABOUT);
  const [setHubContactProperties] = useMutation(SET_HUB_CONTACT_PROPERTIES);

  const isAllValuesFilled = () => {
    return !!(selectedProduct && selectedRevenue && selectedMultiVendor && selectedStore && selectedVendor);
  };

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const onNext = () => {
    setSelectedTab(onboardingSteps.UPDATE_PROFILE);
  };

  const handleValidation = async (key, value) => {
    const validationError = await validate(key, value);
    setError((prevState) => ({
      ...prevState,
      [key]: validationError,
    }));
  };

  const setHubContactPropertiesData = async () => {
    try {
      const userDetails = baseHelper.getChatUser(currentUser);
      const { id: sellerId, dateFreeTrialStarted } = userDetails;
      const response = await setHubContactProperties({
        variables: {
          input: {
            sellerId,
            dateFreeTrialStarted,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, SET_HUB_CONTACT);
      if (responseError) {
        console.log("Error while creating or updating hub contact is: ", responseError);
      }
    } catch (resError) {
      console.log("Error is:", resError);
    }
  };

  const onSubmit = async () => {
    try {
      let revenue;
      if (selectedRevenue === "zero") revenue = "0";
      if (selectedRevenue === "twoFifty") revenue = "250K";
      if (selectedRevenue === "sevenFifty") revenue = "750K";
      if (selectedRevenue === "moreThanSevenFifty") revenue = "750K+";
      const data = {
        product: selectedProduct,
        store: selectedStore,
        vendor: selectedVendor,
        multiVendor: selectedMultiVendor,
        revenue,
      };
      await handleValidation("product", data.product);
      await handleValidation("store", data.store);
      await handleValidation("vendor", data.vendor);
      await handleValidation("multiVendor", data.multiVendor);
      await handleValidation("revenue", data.revenue);

      if (!isAllValuesFilled()) {
        return;
      }

      const updateAboutResponse = await updateAbout({
        variables: {
          input: {
            isSellOnline: data.product,
            storeType: data.store,
            isVendorOnboarding: data.vendor === "yes",
            marketplaceUnderstanding: data.multiVendor,
            annualRevenue: data.revenue,
          },
        },
      });

      const responseError = baseHelper.getResponseError(updateAboutResponse.data, UPDATE_ABOUT_TEXT);

      if (responseError) {
        setBanner({
          title: responseError,
          status: "critical",
          isOpen: true,
        });
        return;
      }

      setHubContactPropertiesData();
      onNext();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  return (
    <Layout id="about-settings">
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("common.onboarding.about.title")}
        description={cms("common.onboarding.about.description")}
      >
        <Card
          title={cms("common.onboarding.about.subTitle")}
          description={cms("common.onboarding.about.subDescription")}
          // actions={[{ content: "Learn more", onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <ChoiceList
              title={cms("common.onboarding.about.label.productOrService")}
              choices={[
                { label: cms("common.onboarding.about.label.yes"), value: "yes" },
                { label: cms("common.onboarding.about.label.no"), value: "no" },
                { label: cms("common.onboarding.about.label.agency"), value: "agency" },
              ]}
              selected={selectedProduct}
              onChange={(value) => setSelectedProduct(value[0])}
              error={error && error.product}
            />
            <br />
            <ChoiceList
              title={cms("common.onboarding.about.label.onlineStore")}
              choices={[
                {
                  label: cms("common.onboarding.about.label.multiVendor"),
                  value: "multiVendor",
                },
                {
                  label: cms("common.onboarding.about.label.regular"),
                  value: "regular",
                },
              ]}
              selected={selectedStore}
              onChange={(value) => setSelectedStore(value[0])}
              error={error && error.store}
            />
            <br />
            <ChoiceList
              title={cms("common.onboarding.about.label.vendorOnboard")}
              choices={[
                { label: cms("common.onboarding.about.label.yes"), value: "yes" },
                { label: cms("common.onboarding.about.label.no"), value: "no" },
              ]}
              selected={selectedVendor}
              onChange={(value) => setSelectedVendor(value[0])}
              error={error && error.vendor}
            />
            <br />
            <ChoiceList
              title={cms("common.onboarding.about.label.multiVendorMarketPlace")}
              choices={[
                {
                  label: cms("common.onboarding.about.label.everything"),
                  value: "everything",
                },
                {
                  label: cms("common.onboarding.about.label.some"),
                  value: "some",
                },
                { label: cms("common.onboarding.about.label.none"), value: "none" },
              ]}
              selected={selectedMultiVendor}
              onChange={(value) => setSelectedMultiVendor(value[0])}
              error={error && error.multiVendor}
            />
            <br />
            <ChoiceList
              title={cms("common.onboarding.about.label.annualRevenue")}
              choices={[
                {
                  label: cms("common.onboarding.about.label.zero"),
                  value: "zero",
                },
                { label: cms("common.onboarding.about.label.twoFifty"), value: "twoFifty" },
                { label: cms("common.onboarding.about.label.sevenFifty"), value: "sevenFifty" },
                { label: cms("common.onboarding.about.label.moreThanSevenFifty"), value: "moreThanSevenFifty" },
              ]}
              selected={selectedRevenue}
              onChange={(value) => setSelectedRevenue(value[0])}
              error={error && error.revenue}
            />
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            id: "MC_PORTAL_ONBOARDING_STEP_01",
            content: cms("common.button.nextStep"),
            onAction: () => onSubmit(),
            loading: updateAboutLoading,
          }}
          secondaryActions={[
            {
              content: cms("common.button.previous"),
              onAction: () => setSelectedTab(onboardingSteps.WELCOME),
            },
          ]}
        />
      </Layout.AnnotatedSection>

      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Submit", onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

About.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default About;
