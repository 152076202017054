import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, handleBrandRegex, phoneNumberRegex } = yupHelper;

const firstNameSchema = (errorMessage) => {
  return yup.object().shape({
    firstName: yup.string().required(errorMessage).nullable(),
  });
};

const lastNameSchema = (errorMessage) => {
  return yup.object().shape({
    lastName: yup.string().required(errorMessage).nullable(),
  });
};

const emailSchema = (fieldRequired, validEmail) => {
  return yup.object().shape({
    email: yup.string().required(fieldRequired).matches(emailRegex, validEmail).nullable(),
  });
};

const phoneNumberSchema = (errorMessage, min, max, validNumber) => {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .required(errorMessage)
      .matches(phoneNumberRegex, validNumber)
      .min(7, min)
      .max(15, max)
      .nullable(),
  });
};

const streetAddressSchema = (errorMessage) => {
  return yup.object().shape({
    streetAddress: yup.string().required(errorMessage).nullable(),
  });
};

const citySchema = (errorMessage) => {
  return yup.object().shape({
    city: yup.string().required(errorMessage).nullable(),
  });
};

const countrySchema = (errorMessage) => {
  return yup.object().shape({
    country: yup.string().required(errorMessage).nullable(),
  });
};

const postalCodeSchema = (fieldRequired, min, max, schema) => {
  const { min: minSchema = 2 } = schema || {};
  return yup.object().shape({
    postalCode: yup.string().required(fieldRequired).min(minSchema, min).max(32, max).nullable(),
  });
};

const provinceCodeSchema = (errorMessage) => {
  return yup.object().shape({
    provinceCode: yup.string().required(errorMessage).nullable(),
  });
};

const brandNameSchema = (errorMessage) => {
  return yup.object().shape({
    brandName: yup.string().required(errorMessage).nullable(),
  });
};

const brandHandleSchema = (fieldRequired, validBrandHandle) => {
  return yup.object({
    brandName: yup.string().required(fieldRequired).matches(handleBrandRegex, validBrandHandle),
  });
};

export {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
};
