import React, { useContext, useState } from "react";
import { Layout, Card, Select, FormLayout, List } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";
import ProductTabs from "./subFeatures/form/subFeatureItem/tab";
import BulkCreate from "./subFeatures/bulkCreateCSV/bulkCreate";

const { CREATE_FORM, CREATE_CSV } = constant;

const ProductAdd = () => {
  const { cms, isLoading } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [selected, setSelected] = useState();

  const options = [
    {
      label: cms("label.form"),
      value: CREATE_FORM,
    },
    // {
    //   label: cms("label.service"),
    //   value: SERVICE,
    // },
    {
      label: cms("label.csv"),
      value: CREATE_CSV,
    },
  ];
  const renderComponent = (value) => {
    if (value === CREATE_FORM) {
      return <ProductTabs setBanner={setBanner} />;
    }

    if (value === CREATE_CSV) {
      return <BulkCreate setBanner={setBanner} cms={cms} />;
    }
    return null;
  };
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={cms("label.import")} description={cms("label.csvInfo")}>
        <Card>
          <Card.Section>
            <FormLayout>
              <List type="bullet">
                <List.Item>{cms("label.manual")}</List.Item>
                <List.Item>{cms("label.upload")}</List.Item>
                <List.Item>{cms("label.paas")}</List.Item>
              </List>
              <Select
                id="addProduct"
                options={options}
                onChange={(value) => setSelected(value)}
                placeholder={cms("label.choose")}
                value={selected}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {renderComponent(selected)}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProductAdd), { feature: constant.ADD_PRODUCT });
