import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { postalCodeRegex, phoneNumberRegex } = yupHelper;

const firstNameSchema = (label, cms) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required(`${cms("common.message.error.required", { item: label })}`)
      .nullable(),
  });
};

const lastNameSchema = (label, cms) => {
  return yup.object().shape({
    lastName: yup
      .string()
      .required(`${cms("common.message.error.required", { item: label })}`)
      .nullable(),
  });
};

const { emailRegex, handleBrandRegex } = yupHelper;
const alternateEmailSchema = (cms) => {
  return yup.object({
    alternateEmail: yup.string().matches(emailRegex, cms("common.message.error.enterValidEmail")).nullable(),
  });
};
const emailSchema = (label, cms) => {
  return yup.object({
    email: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(emailRegex, cms("common.message.error.enterValid", { item: label.toLowerCase() }))
      .nullable(),
  });
};

const phoneNumberSchema = (label, cms) => {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .min(7, cms("common.message.error.minPhoneNumberChar", { item: label }))
      .max(15, cms("common.message.error.maxPhoneNumberChar", { item: label }))
      .nullable(),
  });
};

const citySchema = (label, cms) => {
  return yup.object().shape({
    city: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};

const countrySchema = (cms) => {
  return yup.object().shape({
    country: yup.string().required(cms("common.message.error.countryIsRequired")).nullable(),
  });
};

const postalCodeSchema = (label, cms, schema) => {
  const { min = 2, max = 32 } = schema || {};
  return yup.object().shape({
    postalCode: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(postalCodeRegex, cms("common.message.error.validPostalCode", { item: label }))
      .min(min, cms("common.message.error.minPostalCodeChar", { min, item: label }))
      .max(max, cms("common.message.error.maxPostalCodeChar", { item: label }))
      .nullable(),
  });
};

const provinceCodeSchema = (cms) => {
  return yup.object().shape({
    provinceCode: yup.string().required(cms("common.message.error.provinceCode")).nullable(),
  });
};

const streetAddressSchema = (label, cms) => {
  return yup.object().shape({
    streetAddress: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};
const brandNameSchema = (label, cms) => {
  return yup.object().shape({
    brandName: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .nullable(),
  });
};
const brandSlugSchema = (label, cms) => {
  return yup.object().shape({
    slug: yup
      .string()
      .required(cms("common.message.error.required", { item: label }))
      .matches(
        handleBrandRegex,
        cms("common.message.error.brandHandle.brandHandleMatch", { item: label.toLowerCase() })
      )
      .nullable(),
  });
};
const brandDescriptionSchema = (cms) => {
  return yup.object().shape({
    description: yup.string().required(cms("message.error.brandDescription")).nullable(),
  });
};
const bankNameSchema = (cms) => {
  return yup.object().shape({
    bankName: yup.string().required(cms("message.error.bank")).nullable(),
  });
};
const accountNumberSchema = (cms) => {
  return yup.object().shape({
    accountNumber: yup.string().required(cms("message.error.account")).nullable(),
  });
};
const sortCodeSchema = (cms) => {
  return yup.object().shape({
    sortCode: yup.string().required(cms("message.error.sortCode")).nullable(),
  });
};

export {
  alternateEmailSchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
  brandNameSchema,
  brandSlugSchema,
  brandDescriptionSchema,
  bankNameSchema,
  accountNumberSchema,
  sortCodeSchema,
};
