import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { baseHelper, storageHelper } from "lib/helpers";
import PublicContainer from "./publicContainer";

// import helpers

const PublicRoute = (props) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps) => {
        const { pathname: currentPath } = routeProps.location;
        const isLoadUrl = baseHelper.isLoadUrl(currentPath);

        if (storageHelper.get("token") && !isLoadUrl) {
          return <Redirect to={{ pathname: "/", state: { from: routeProps.location } }} />;
        }

        return <PublicContainer component={Component} {...routeProps} />;
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PublicRoute;
