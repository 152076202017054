import React, { useState, useContext } from "react";
import { Card, PageActions, FormLayout, Select, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import mutation
import { ADD_PRODUCT_LAKE } from "app/productLake/apollo/mutations";

// import queries
import { GET_VENDOR_BRAND_LIST } from "app/productLake/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/productLake/modules/generic/context";

// import proptype
import { tabProp } from "app/productLake/modules/generic/propTypes";

const Information = () => {
  const { setBanner } = useContext(ProductContext);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { moneyFormat = "", ecommercePlatform } = currentUser;
  const [selectedVendor, setSelectedVendor] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedProductType, setSelectedProductType] = useState("");

  const { BIGCOMMERCE, CRITICAL, gql, INVENTORY } = constant;
  const { loading: brandListLoading, data: vendorBrandListData } = useQuery(GET_VENDOR_BRAND_LIST);

  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_LAKE);

  if (brandListLoading) {
    return <SkeletonCard />;
  }

  // load vendors
  const brandList = baseHelper.getResponseData(vendorBrandListData, constant.gql.GET_VENDOR_BRAND_LIST) || [];
  const activeVendorOptions = brandList.filter((item) => {
    return item.isInactive;
  });
  const inactiveVendorOptions = brandList.filter((item) => {
    return !item.isInactive;
  });
  const finalVendorOptions = [...activeVendorOptions, ...inactiveVendorOptions];
  const vendorOptions = finalVendorOptions.map(({ _id: vendorId, brandName, isInactive }) => ({
    label: isInactive ? `${brandName} (${cms("common.button.disabled")})` : brandName,
    value: vendorId,
    disabled: isInactive,
  }));

  const handleTiny = (content) => {
    setDescription(content);
  };

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const addProduct = async (formValues) => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const responseData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_LAKE);

      const responseError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_LAKE);

      if (responseData) {
        setMessage(cms("message.success.add"));
        setTimeout(() => {
          history.push(`edit/${encodeURIComponent(responseData)}?tab=${INVENTORY}`);
        }, 1500);
      }
      if (responseError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: responseError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const submitCallBack = async () => {
    const vendorId = selectedVendor;
    let productPrice = {
      regularPrice: price || "",
      salePrice: comparePrice || "",
    };
    if (baseHelper.isBigCommerceUser(currentUser)) {
      productPrice = {
        price,
        costPrice: comparePrice,
      };
    }

    const formValues = {
      name: title,
      description,
      providerIds: [vendorId],
      type: selectedProductType,
      price: productPrice,
    };

    formValues.price.costPrice = parseFloat(comparePrice || 0).toString();
    formValues.price.price = parseFloat(price || 0).toString();
    try {
      // add product
      await addProduct(formValues);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };

    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.title"),
      };
      setBanner(banner);
      return;
    }
    if (!selectedVendor || selectedVendor.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.vendor"),
      };
      setBanner(banner);
      return;
    }

    submitCallBack();
  };

  let typeOptions = [
    { label: "Simple", value: "simple" },
    // { label: "Grouped", value: "grouped" },
    // { label: "External", value: "external" },
    { label: "Variable", value: "variable" },
  ];

  if (currentUser.ecommercePlatform === BIGCOMMERCE) {
    typeOptions = [
      { label: "Physical", value: "physical" },
      { label: "Digital", value: "digital" },
    ];
  }

  return (
    <>
      <Card title={cms("label.productInformation")} id="productInformation">
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              required="true"
              error=""
              value={title || ""}
              onChange={(value) => {
                setTitle(value);
                setDisabledButton(false);
              }}
              onBlur={() => title && setTitle(title.trim())}
            />
            {`${cms("label.description")}`}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={`${cms("label.price")}`}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix={moneyFormat}
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                  setDisabledButton(false);
                }}
              />
              <TextField
                id="comparePrice"
                label={`${cms("label.comparePrice")}`}
                min={0}
                placeholder="0.00"
                prefix={moneyFormat}
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>
            <Select
              label={`${cms("label.vendor")}*`}
              placeholder={`${cms("placeholder.vendor")}`}
              options={vendorOptions}
              value={selectedVendor || ""}
              onChange={(value) => {
                setSelectedVendor(value);
                setDisabledButton(false);
              }}
            />
            <Select
              label={`${cms("label.type")}${ecommercePlatform ? "*" : ""}`}
              options={typeOptions}
              placeholder={cms("placeholder.selectProductType")}
              onChange={(value) => setSelectedProductType(value)}
              value={selectedProductType}
            />
            <br />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms("common.button.save"),
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
          disabled: disabledButton || (ecommercePlatform && !selectedProductType),
        }}
      />
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

Information.propTypes = tabProp.type;

export default withErrorBoundary(Information);
