import {
  ExportMinor,
  ImportMinor,
  AddProductMajor,
  CustomerPlusMajor,
  InviteMinor,
  ChatMajor,
} from "@shopify/polaris-icons";

const config = (cms) => {
  return {
    "/invitation": [
      {
        caption: cms("common.actions.invitation.caption.invite"),
        label: cms("common.actions.invitation.label.invite"),
        link: "/providers/add",
        primary: true,
        icon: InviteMinor,
      },
    ],
    "/orders": [
      {
        caption: cms("common.actions.orders.caption.export"),
        id: "exportOrder",
        label: cms("common.actions.orders.label.export"),
        link: "/orders",
        icon: ExportMinor,
      },
      {
        caption: cms("common.actions.orders.caption.import"),
        id: "importOrder",
        label: cms("common.actions.orders.label.import"),
        primary: true,
        link: "/orders",
        icon: ImportMinor,
      },
    ],
    "/order-lake": [
      {
        caption: cms("common.actions.orders.caption.export"),
        id: "exportOrder",
        label: cms("common.actions.orders.label.export"),
        link: "/order-lake",
        icon: ExportMinor,
      },
      {
        caption: cms("common.actions.orders.caption.import"),
        id: "importOrder",
        label: cms("common.actions.orders.label.import"),
        primary: true,
        link: "/order-lake",
        icon: ImportMinor,
      },
    ],
    "/payments": [
      {
        caption: cms("common.actions.payments.caption.export"),
        id: "exportPayment",
        label: cms("common.actions.payments.label.export"),
        primary: true,
        link: "/payments",
        icon: ExportMinor,
      },
    ],
    "/products": [
      {
        caption: cms("common.actions.products.caption.import"),
        id: "importProduct",
        label: cms("common.actions.products.label.import"),
        link: "/products",
        icon: ImportMinor,
      },
      {
        caption: cms("common.actions.products.caption.add"),
        id: "addProduct",
        label: cms("common.actions.products.label.add"),
        primary: true,
        link: "/products/add",
        icon: AddProductMajor,
      },
    ],
    "/providers": [
      {
        caption: cms("common.actions.vendors.caption.add"),
        id: "addProvider",
        label: cms("common.actions.vendors.label.add"),
        primary: true,
        link: "/providers/add",
        icon: CustomerPlusMajor,
      },
      {
        caption: cms("button.providerList.providerList.exportProvider"),
        id: "exportProvider",
        label: cms("button.providerList.providerList.exportProvider"),
        primary: true,
        link: "/providers",
        icon: CustomerPlusMajor,
      },
      {
        caption: cms("button.providerList.providerList.chatInbox"),
        id: "chatInbox",
        label: cms("button.providerList.providerList.chatInbox"),
        link: "/chat",
        icon: ChatMajor,
      },
    ],
  };
};

export default config;
