import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField, Collapsible, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// import helper components and context
import { SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { UPDATE_BRANDING } from "app/userManagement/apollo/mutation";

const EditPolicy = (props) => {
  const { setBanner /* learnMore */ } = props;
  const { currentUser, cms = {}, isLoading, history } = useContext(PrivateContext);
  const { brand = {}, domain } = currentUser;
  const [sellerData, setSellerData] = useState({
    policyLink: brand && brand.policyLink,
    termsLink: brand && brand.termsLink,
  });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const [setBranding, { loading: brandingLoading }] = useMutation(UPDATE_BRANDING);
  if (isLoading) {
    return <SkeletonAnnotated />;
  }
  const handleChange = (field, value) => {
    setIsSubmitEnabled(true);
    setSellerData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    setIsSubmitEnabled(false);
    try {
      const res = await setBranding({
        variables: {
          input: {
            brand: sellerData,
            domain: domain || "",
          },
        },
      });
      const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_BRANDING);
      if (responseError) {
        setBanner({
          title: responseError,
          status: constant.CRITICAL,
          isOpen: true,
        });
        return;
      }
      setBanner({
        title: cms("message.success.profileUpdated"),
        status: constant.SUCCESS,
        isOpen: true,
      });
    } catch (exception) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
        isOpen: true,
      });
    }
  };

  return (
    <>
      <Collapsible open>
        <Layout.AnnotatedSection
          title={cms("section.termsAndPolicy.title")}
          description={cms("section.termsAndPolicy.description")}
        >
          <Card
            title={[
              cms("section.termsAndPolicy.subTitle"),
              // lastUpdated && <Caption>{`Updated at : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>,
            ]}
            primaryFooterAction={{
              content: cms("common.button.submit"),
              key: "submitButton",
              onAction: () => onSubmit(),
              loading: brandingLoading,
              disabled: brandingLoading || !isSubmitEnabled,
            }}
            secondaryFooterActions={[
              {
                content: cms("common.button.cancel"),
                key: "cancelButton",
                onAction: () => history.push("/setting"),
              },
            ]}
            // actions={[
            //   {
            //     content: "Learn More",
            //     onAction: () => {
            //       learnMore(cms("section.termsAndPolicy.subTitle"), "TODO");
            //     },
            //   },
            // ]}
          >
            <Card.Section>
              <TextContainer>{cms("section.termsAndPolicy.subDescription")}</TextContainer>
              <br />
              <FormLayout>
                <TextField
                  id="termsOfUse"
                  label={cms("section.termsAndPolicy.label.termsOfUse")}
                  placeholder={cms("section.termsAndPolicy.placeHolder.termsOfUse")}
                  value={sellerData.termsLink}
                  onChange={(value) => handleChange("termsLink", value, cms("section.termsAndPolicy.type.termsLink"))}
                />
                <TextField
                  id="policy"
                  label={cms("section.termsAndPolicy.label.policy")}
                  placeholder={cms("section.termsAndPolicy.placeHolder.policy")}
                  value={sellerData.policyLink}
                  onChange={(value) => handleChange("policyLink", value, cms("section.termsAndPolicy.type.policyLink"))}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Collapsible>
    </>
  );
};

EditPolicy.defaultProps = {
  setBanner: () => {},
  // learnMore: () => {},
};

EditPolicy.propTypes = {
  setBanner: PropTypes.func,
  // learnMore: PropTypes.func,
};

export default EditPolicy;
