import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

// import constant
import constant from "lib/constant/constant";

import OperatorQuote from "./operator";
import ProviderQuote from "./provider";

const { userKey } = constant;
const { operator, provider } = userKey;

const Quote = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorQuote />;
    }
    if (role === provider) {
      return <ProviderQuote />;
    }
  };

  const currentUserRole = storageHelper.get("userRole");
  const QuoteComponent = getComponent(currentUserRole);
  return QuoteComponent;
};

export default Quote;
