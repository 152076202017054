// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Card, DescriptionList, Layout, PageActions, TextField, TextStyle } from "@shopify/polaris";

// import components
import { Editor } from "@tinymce/tinymce-react";
import { Banner, Spinner } from "lib/components";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helper
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import yup validation
import validate from "app/emailTemplate/modules/operator/features/yup/validate";

// import gql
import { FETCH_EMAIL_TEMPLATE_BY_ID } from "app/emailTemplate/apollo/queries";
import { UPDATE_EMAIL_TEMPLATE } from "app/emailTemplate/apollo/mutations";
import { errorHelper } from "lib/helpers";

const OperatorEditEmailTemplate = () => {
  const { cms = {}, history, match } = useContext(PrivateContext);
  const { params } = match;
  const { editor, gql, SUBJECT, MESSAGE } = constant;

  const listRoute = "/email-template";
  const templateId = params.id;

  const [errorMessage, setErrorMessage] = useState();
  const [isNotFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subjectTags, setSubjectTags] = useState({});
  const [submitEnable, setSubmitEnable] = useState(false);
  const [tags, setTags] = useState({});

  const [state, setState] = useState({
    label: "",
    subject: "",
    message: "",
    prevMessage: "",
    prevSubject: "",
  });

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [customizeEmailTemplate, { loading: updateTemplateLoading }] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const { data: emailTemplateData, loading: emailTemplateLoading, error } = useQuery(FETCH_EMAIL_TEMPLATE_BY_ID, {
    variables: {
      input: {
        id: templateId,
      },
    },
  });

  const emailTemplateError = baseHelper.getResponseError(emailTemplateData, gql.GET_EMAIL_TEMPLATE_BY_ID);
  const emailTemplateResponse = baseHelper.getResponseData(emailTemplateData, gql.GET_EMAIL_TEMPLATE_BY_ID);

  const getEmailTemplate = (response) => {
    if (response && !response.label) {
      setLoading(false);
      setNotFound(true);
      return;
    }

    if (response) {
      const { label = "", message = "", subject = "", tags: messageTags = {}, subjectTags: subTags = {} } = response;

      setState({
        ...state,
        label,
        subject,
        message,
        prevMessage: message,
        prevSubject: subject,
      });
      setTags(messageTags);
      setSubjectTags(subTags);
    }
  };

  useEffect(() => {
    if (!emailTemplateLoading) {
      setLoading(false);
    }
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
  }, [emailTemplateLoading, error]);

  if (isNotFound) {
    history.push(`${listRoute}?isNotFound=${isNotFound}`);
  }

  useEffect(() => {
    if (emailTemplateError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: emailTemplateError });
      setNotFound(true);
    }

    if (emailTemplateResponse) {
      getEmailTemplate(emailTemplateResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateError, emailTemplateResponse]);

  useEffect(() => {
    if (!state.subject || !state.message) {
      setSubmitEnable(false);
    }
  }, [state]);

  const renderList = (item) => {
    const list = Object.keys(item).map((key) => {
      const handleBar = `{{${key}}}`;
      return {
        term: handleBar,
        description: item[key] || cms("label.noDescription"),
      };
    });
    return list;
  };

  const subjectDescription = () => {
    if (subjectTags && Object.entries(subjectTags).length) {
      return (
        <Card.Section title={cms("label.subjectTags")}>
          <DescriptionList items={renderList(subjectTags)} />
        </Card.Section>
      );
    }
    return null;
  };

  const handleValidate = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const messageDescription = () => {
    if (tags && Object.entries(tags).length) {
      return (
        <Card.Section title={cms("label.messageTags")}>
          <div className="description-list">
            <DescriptionList items={renderList(tags)} spacing="loose" />
          </div>
        </Card.Section>
      );
    }
    return null;
  };

  const showDescription = () => [subjectDescription(), messageDescription()];

  const { label = "", message = "", subject = "", prevMessage = "" /* , prevSubject = "" */ } = state;
  if (loading) {
    return <Spinner />;
  }

  const handleEditorChange = (content) => {
    setState({ ...state, message: content });
  };

  const handleSubjectChange = (value) => {
    setState({ ...state, subject: value });
    setSubmitEnable(true);
  };

  const handleFunction = () => {
    if (state.message) {
      setSubmitEnable(true);
    }
  };

  const submitCallback = async (reqData) => {
    // call mutation
    try {
      const res = await customizeEmailTemplate({
        variables: { input: reqData },
      });

      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_EMAIL_TEMPLATE);
      if (!responseData) {
        // const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_EMAIL_TEMPLATE);
        // setBanner({ isOpen: true, status: constant.CRITICAL, title: errorResponse });
        return false;
      }
      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success") });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    return null;
  };

  const onsubmit = async () => {
    setSubmitEnable(false);
    const data = {
      id: templateId,
      subject,
      message,
    };

    // const prevSubjectTags = baseHelper.templateTags(prevSubject);
    // const currentSubjectTags = baseHelper.templateTags(subject);
    // const isValidSubject = baseHelper.validateTags(prevSubjectTags, currentSubjectTags);

    // const prevMessageTags = baseHelper.templateTags(prevMessage);
    // const currentMessageTags = baseHelper.templateTags(message);
    // const isValidContent = baseHelper.validateTags(prevMessageTags, currentMessageTags);

    // if (!isValidSubject || !isValidContent) {
    //   setBanner({
    //     isOpen: true,
    //     title: cms("message.error.incorrectTag"),
    //     status: constant.CRITICAL,
    //   });
    //   return false;
    // }

    await submitCallback(data);
    return null;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            status={banner.status}
            onDismiss={() => setBanner({ isOpen: false })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={label}>
        <Card>
          <Card.Section>
            <TextField
              id="template-label"
              label={cms("label.label")}
              value={label}
              placeholder={cms("placeholder.label")}
              disabled
            />
            <br />
            <TextField
              id="template-subject"
              label={`${cms("label.subject")}*`}
              value={state.subject}
              placeholder={`${cms("placeholder.subject")}`}
              onChange={(value) => handleSubjectChange(value)}
              onBlur={() => handleValidate(SUBJECT, state.subject)}
              error={errorMessage && errorMessage.subject}
            />
            <br />
            <label htmlFor="content">{`${cms("label.message")}*`}</label>
            <div>
              <br />
              <Editor
                initialValue={prevMessage || ""}
                init={{
                  removed_menuitems: editor.REMOVED_ITEM,
                  plugins: editor.PLUGIN,
                  toolbar: editor.TOOLBAR,
                  themes: editor.THEME,
                  elementpath: false,
                  branding: false,
                }}
                onKeyDown={handleFunction}
                onEditorChange={(value) => handleEditorChange(value)}
                onBlur={() => handleValidate(MESSAGE, state.message)}
              />
              {errorMessage && <TextStyle variation="negative">{errorMessage.message}</TextStyle>}
            </div>
          </Card.Section>
        </Card>
        <Card>{showDescription()}</Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => {
              onsubmit();
            },
            loading: updateTemplateLoading,
            disabled: !submitEnable,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => history.push("/email-template"),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorEditEmailTemplate), {
  feature: constant.EMAIL_TEMPLATE,
});
