import React from "react";
import { Card, TextField, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";

const ServiceTitle = (props) => {
  const {
    cms,
    errorMessage = false,
    handleChange = () => {},
    handleValidation = () => {},
    options,
    variantData = {},
  } = props;

  const {
    option1 = cms("service.label.session"),
    option2 = cms("service.label.location"),
    option3 = cms("service.label.artist"),
    option1Val = null,
    option2Val = null,
    option3Val = null,
  } = options || {};

  const renderTextField = (optionLabel, optionValue, id) => {
    return (
      <TextField
        id={`optionValue${id}`}
        label={`${optionLabel}*`}
        value={(optionValue.trim() ? optionValue : optionValue.trim()) || ""}
        onChange={(value) => {
          handleChange(`option${id}Val`, value.trim() ? value : value.trim());
        }}
        onBlur={() => handleValidation(`option${id}` || optionLabel.toLowerCase(), optionValue)}
        placeholder={optionLabel}
        error={
          errorMessage &&
          ((errorMessage[`option${id}`] && cms("message.error.field")) ||
            (errorMessage[optionLabel.toLowerCase()] && cms("message.error.field")))
        }
      />
    );
  };

  return (
    <Card title={cms("section.option.title")} id="title" sectioned>
      <FormLayout>
        {option1 && option1Val && renderTextField(option1, variantData.option1Val, "1")}
        {option2 && option2Val && renderTextField(option2, variantData.option2Val, "2")}
        {option3 && option3Val && renderTextField(option3, variantData.option3Val, "3")}
      </FormLayout>
    </Card>
  );
};

ServiceTitle.propTypes = {
  cms: PropTypes.func.isRequired,
  errorMessage: PropTypes.shape(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.string),
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

ServiceTitle.defaultProps = {
  options: {},
};

export default ServiceTitle;
