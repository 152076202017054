import React, { useState } from "react";
import { Card, Modal, Stack, Link, Thumbnail, Icon } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

// import components
import { DropZone } from "lib/components";
import { imageHelper } from "lib/helpers";

const Image = (props) => {
  const {
    cms,
    disabledButton,
    image = [],
    isImageErrorDisable,
    productImages: images = [],
    setDisabledButton,
    setImage,
    setIsImageError,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const onImageChange = (nImage) => {
    setSelectedImage(selectedImage === nImage ? {} : nImage);
    setDisabledButton(false);
  };

  const uploadLocalImage = (value) => {
    const uploadImage = value;
    uploadImage.upload = constant.LOCAL;
    setImage(uploadImage);
  };

  const renderModal = () => {
    const allImages = images.filter((item) => item);
    const productImages = allImages.map((nImage, index) => {
      const isSelectedImage = nImage === selectedImage;
      return (
        <Stack.Item key={nImage.position}>
          <Stack alignment="center" vertical>
            <Stack.Item>
              <Link onClick={() => onImageChange(nImage)}>
                <Thumbnail
                  source={imageHelper.resize({ url: nImage, type: constant.imageTypes.THUMBNAIL })}
                  size="large"
                  alt={`image ${index}`}
                />
              </Link>
            </Stack.Item>
            {isSelectedImage && (
              <Stack.Item>
                <Icon source={CircleTickMajor} color="greenDark" backdrop />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      );
    });
    return <Stack>{productImages}</Stack>;
  };
  return (
    <Card
      title={cms("section.images.title")}
      id="variantImage"
      sectioned
      actions={[
        {
          content: images && images.length && cms("section.images.modal.title"),
          onAction: () => images && images.length && setIsOpen(true),
        },
      ]}
    >
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={cms("section.images.modal.title")}
        primaryAction={{
          content: cms("section.label.button.save"),
          onAction: () => selectedImage && [setIsOpen(false), setImage([{ url: selectedImage }])],
        }}
        sectioned
      >
        <Modal.Section>
          <Card sectioned>{renderModal()}</Card>
        </Modal.Section>
      </Modal>
      <DropZone
        id="dropZone"
        onAdd={(value) => {
          uploadLocalImage(value);
          setDisabledButton(false);
        }}
        onRemove={() => {
          setImage([]);
          setDisabledButton(false);
        }}
        fileList={(image && image.length && image) || []}
        allowMultiple={false}
        size={10}
        setDisabledButton={setDisabledButton}
        setIsImageError={setIsImageError}
        disabledButton={disabledButton}
        isImageErrorDisable={isImageErrorDisable}
      />
    </Card>
  );
};

Image.propTypes = {
  setImage: PropTypes.func.isRequired,
  productImages: PropTypes.arrayOf(PropTypes.shape).isRequired,
  image: PropTypes.arrayOf(PropTypes.any),
  cms: PropTypes.func.isRequired,
};

Image.defaultProps = {
  image: [],
};

export default withErrorBoundary(Image);
