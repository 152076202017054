import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Stack, TextContainer } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

const EmailChangeModal = (props) => {
  const { isOpen, onClose, onSubmit, isLoading } = props;
  const { cms } = useContext(PrivateContext);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={cms("section.editProfile.emailChangeModal.title")}
      primaryAction={{
        content: cms("section.editProfile.emailChangeModal.button.primary"),
        onAction: onSubmit,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: cms("common.button.cancel"),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <TextContainer>
              <p>{cms("section.editProfile.emailChangeModal.content")}</p>
            </TextContainer>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

EmailChangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailChangeModal;
