import constant from "lib/constant/constant";

class ErrorHelper {
  constructor() {
    this.default = constant.DEFAULT_ERROR;
  }

  /**
   * @desc Returns a string from exception object
   * @param {Object} exception
   *
   * @return {String} message
   *
   */
  parse = (exception = "") => {
    if (process.env.DEBUG) {
      console.error(exception);
    }
    if (
      exception &&
      exception.networkError &&
      exception.networkError.result &&
      exception.networkError.result.errors &&
      exception.networkError.result.errors.length
    ) {
      const { networkError: { result: { errors = [] } = {} } = {} } = exception || {};
      return errors[0] || this.default;
    }
    if (typeof exception === "string" && exception !== "") return exception;
    return (exception && exception.message) || this.default;
  };
}

export default new ErrorHelper();
