import React, { useState, useEffect, useContext, useCallback } from "react";
import { Banner, ChoiceList, Modal, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";

const DeleteProduct = (props) => {
  const {
    cms,
    openDeleteModal = false,
    id = false,
    loadingBulkProduct,
    setOpenDeleteModal,
    bulkAction,
    selectedItems,
    deleteSelected,
  } = props;
  const [reason, setReason] = useState(null);
  const { currentUser } = useContext(PrivateContext);
  const [selectedDeleteFrom, setSelectedDeleteFrom] = useState(deleteSelected);
  const isVendor = baseHelper.isVendor(currentUser);
  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
    setReason(null);
  };
  const handleReasonChange = (value) => {
    setReason(value);
  };
  const handleSelectedExportAs = useCallback((value) => setSelectedDeleteFrom(value), []);
  const handleClose = () => {
    handleCloseDelete();
    handleSelectedExportAs([constant.DELETE_SHOPIFY]);
  };
  useEffect(() => {
    if (openDeleteModal) {
      setReason(null);
    }
  }, [openDeleteModal]);
  useEffect(() => {
    if (deleteSelected) {
      setSelectedDeleteFrom(deleteSelected);
    }
  }, [deleteSelected]);
  const productLabel = selectedItems.length > 1 ? cms("label.productPlural") : cms("label.product");
  return (
    <>
      {isVendor && (
        <Modal
          open={openDeleteModal}
          onClose={() => handleCloseDelete()}
          title={cms("section.delete.label.delete")}
          primaryAction={{
            content: cms("section.delete.label.delete"),
            onAction: () => bulkAction(constant.DELETE.toLowerCase(), true, id, reason, constant.DELETE_SHOPIFY),
            destructive: true,
            loading: loadingBulkProduct,
          }}
        >
          <Modal.Section>
            <Banner
              status={constant.CRITICAL}
              title={` ${cms("section.delete.message.about")} ${selectedItems.length || 1} ${productLabel}. 
${cms("section.delete.message.vendor")}`}
            />
            <TextField
              label={cms("section.delete.label.reason")}
              value={reason}
              onChange={handleReasonChange}
              multiline={2}
            />
          </Modal.Section>
        </Modal>
      )}
      {!isVendor && (
        <Modal
          open={openDeleteModal}
          onClose={() => handleClose()}
          title={cms("section.delete.label.delete")}
          secondaryActions={[
            {
              content: cms("section.delete.label.delete"),
              destructive: true,
              onAction: () => {
                bulkAction(
                  constant.DELETE.toLowerCase(),
                  selectedDeleteFrom[0] === constant.DELETE_SHOPIFY,
                  id,
                  reason,
                  selectedDeleteFrom[0]
                );
              },
              loading: loadingBulkProduct,
            },
          ]}
        >
          <Modal.Section>
            <Banner
              isOpen
              status={constant.CRITICAL}
              title={`${cms("section.delete.message.about")} ${selectedItems.length || 1} ${productLabel}. 
                ${cms("section.delete.message.this")}`}
            />
            <br />
            <ChoiceList
              choices={[
                {
                  label: cms("section.delete.choiceList.shopify"),
                  value: constant.DELETE_SHOPIFY,
                },
                {
                  label: cms("section.delete.choiceList.marketCube"),
                  value: constant.DELETE_MARKET,
                },
              ]}
              selected={selectedDeleteFrom}
              onChange={handleSelectedExportAs}
            />
            <TextField
              label={cms("section.delete.label.reason")}
              value={reason}
              onChange={handleReasonChange}
              multiline={2}
            />
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

const RejectProduct = (props) => {
  const {
    cms,
    openRejectModal = false,
    id = false,
    loadingBulkProduct,
    selectedButton,
    setOpenRejectModal,
    bulkAction,
    modalTitle,
    primaryActionTitle,
    secondaryActionsTitle,
    keyName,
  } = props;
  const [reasonReject, setReasonReject] = useState(null);
  const handleCloseReject = () => {
    setOpenRejectModal(false);
    setReasonReject(null);
  };
  const handleReasonChange = (value) => {
    setReasonReject(value);
  };
  useEffect(() => {
    if (openRejectModal) {
      setReasonReject(null);
    }
  }, [openRejectModal]);
  return (
    <>
      <Modal
        open={openRejectModal}
        onClose={() => handleCloseReject()}
        title={modalTitle}
        primaryAction={{
          content: primaryActionTitle,
          onAction: () => bulkAction(keyName, false, id, reasonReject, keyName),
          loading: loadingBulkProduct && selectedButton === keyName,
          disabled: loadingBulkProduct && selectedButton !== keyName,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: secondaryActionsTitle,
            onAction: () => handleCloseReject(),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label={cms("section.delete.label.enter")}
            value={reasonReject}
            onChange={handleReasonChange}
            multiline={5}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};

DeleteProduct.defaultProps = {
  cms: () => {},
  openDeleteModal: false,
  id: "",
  setOpenDeleteModal: () => {},
  bulkAction: () => {},
  loadingBulkProduct: false,
  deleteSelected: () => {},
};
DeleteProduct.propTypes = {
  cms: PropTypes.func,
  openDeleteModal: PropTypes.bool,
  id: PropTypes.string,
  setOpenDeleteModal: PropTypes.func,
  bulkAction: PropTypes.func,
  loadingBulkProduct: PropTypes.bool,
  deleteSelected: PropTypes.func,
};

RejectProduct.defaultProps = {
  bulkAction: () => {},
  cms: () => {},
  id: "",
  keyName: null,
  loadingBulkProduct: false,
  modalTitle: null,
  openRejectModal: false,
  primaryActionTitle: null,
  secondaryActionsTitle: null,
  selectedButton: null,
  setOpenRejectModal: () => {},
};

RejectProduct.propTypes = {
  bulkAction: PropTypes.func,
  cms: PropTypes.func,
  id: PropTypes.string,
  keyName: PropTypes.string,
  loadingBulkProduct: PropTypes.bool,
  modalTitle: PropTypes.string,
  openRejectModal: PropTypes.bool,
  primaryActionTitle: PropTypes.string,
  secondaryActionsTitle: PropTypes.string,
  selectedButton: PropTypes.string,
  setOpenRejectModal: PropTypes.func,
};

export { DeleteProduct, RejectProduct };
