import { gql } from "apollo-boost";

const REVIEW_VENDOR_STATUS = gql`
  mutation reviewVendorStatus($input: ReviewVendorStatus) {
    reviewVendorStatus(input: $input) {
      data
      status
      error
    }
  }
`;
export default REVIEW_VENDOR_STATUS;
