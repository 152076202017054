import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Modal,
  OptionList,
  Popover,
  RadioButton,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import components
import { AutoComplete, Banner } from "lib/components";

// import constant
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

// import mutation
import { ADD_OFFER } from "app/shipping/apollo/mutations";

// import offer Helper
import {
  checkValidation,
  destinationsShow,
} from "app/shipping/modules/operator/features/offer/subFeatures/edit/subFeatureItems/offerHelper";

// import propType
import { addOfferProp } from "app/shipping/modules/operator/features/offer/propTypes";

import RenderOfferCondition from "./subFeatureItems/condition";

const AddOffer = (props) => {
  const {
    shippingOffer: shippingOfferProp = {},
    onClose,
    destinations,
    show = false,
    bands,
    products,
    handleBanner,
    refetch,
  } = props || {};

  const [addOffer, { loading }] = useMutation(ADD_OFFER);
  const { currentUser, cms } = useContext(PrivateContext);

  const [popoverActive, setPopoverActive] = useState(false);
  const [isAllDestination, setIsAllDestination] = useState(true);
  const [isAllProduct, setIsAllProduct] = useState(true);
  const [isConditionApplied, setIsConditionApplied] = useState(false);
  const defaultOffer = {
    shippingBandId: "",
    price: 0,
    description: "",
    condition: "",
    destinationCodes: [],
    productIds: [],
    productQuantity: {
      value: 0,
      criteria: "",
    },
    productPrice: {
      value: 0,
      criteria: "",
    },
    orderValue: {
      value: 0,
      criteria: "",
    },
    priceType: "",
    isCumulative: false,
  };
  const defaultBanner = { isOpen: false, status: "", title: "" };

  const [shippingOffer, setShippingOffer] = useState(defaultOffer);
  const [banner, setBanner] = useState({ ...defaultBanner });

  useEffect(() => {
    if (Object.keys(shippingOfferProp).length !== 0 && shippingOfferProp !== shippingOffer) {
      setShippingOffer(shippingOfferProp);
    }
  }, [shippingOffer, shippingOfferProp]);

  const toggleBanner = () => setBanner(defaultBanner);

  const { moneyFormat = constant.symbol.DOLLAR } = currentUser || {};

  const closeModal = () => {
    setBanner({ ...defaultBanner });
    setShippingOffer({ ...defaultOffer });
    onClose();
  };

  const togglePopover = () => setPopoverActive(!popoverActive);

  const activator = <Button onClick={togglePopover}>{cms("modal.rule.add.button.selectRegion")}</Button>;

  const handleShippingOffer = (field, val) => {
    setShippingOffer((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };
  const handleDestinationChange = () => {
    setIsAllDestination(!isAllDestination);
  };
  const renderDestinations = () => {
    if (destinations) {
      return destinationsShow(destinations, shippingOffer, cms);
    }
    return true;
  };
  const handleProductChange = () => {
    setIsAllProduct(!isAllProduct);
  };

  const handleConditionChange = () => {
    setIsConditionApplied(!isConditionApplied);
  };
  const handleCondition = (condition) => {
    const isOrderValue = condition === constant.ORDER_VALUE;

    if (isOrderValue) {
      shippingOffer.productIds = [];
      shippingOffer.isCumulative = false;
    }
    setShippingOffer((prevState) => ({
      ...prevState,
      condition,
    }));
  };
  const handleCriteria = (criteriaField, fieldName, criteriaValue) => {
    setShippingOffer((prevState) => ({
      ...prevState,
      [criteriaField]: {
        ...prevState[criteriaField],
        [fieldName]: (fieldName === constant.CRITERIA && criteriaValue) || parseFloat(criteriaValue),
      },
    }));
  };
  const toggleCumulativeShipping = () => {
    const toggleCumulative = !shippingOffer.isCumulative;
    setShippingOffer((prevState) => ({
      ...prevState,
      isCumulative: toggleCumulative,
    }));
  };

  const offerConditions = () => {
    return (
      <RenderOfferCondition
        handleConditionChange={handleConditionChange}
        isConditionApplied={isConditionApplied}
        shippingOffer={shippingOffer}
        handleCriteria={handleCriteria}
        handleCondition={handleCondition}
        moneyFormat={moneyFormat}
      />
    );
  };

  const priceTypeOptions = [
    {
      label: cms("label.fixed"),
      value: constant.FIXED,
    },
    {
      label: cms("label.freeShipping"),
      value: constant.FREE,
    },
  ];

  const isOrderValue = shippingOffer.condition === constant.ORDER_VALUE;

  const isFieldDisabled = false;

  const addShippingBand = async () => {
    const requestData = {
      ...shippingOffer,
    };

    const productPriceValue = parseFloat(requestData.productPrice.value);
    const productQuantityValue = parseInt(requestData.productQuantity.value, 10);
    const orderValue = parseFloat(requestData.orderValue.value);

    delete requestData.productPrice.value;
    delete requestData.productQuantity.value;
    delete requestData.orderValue.value;

    requestData.productPrice.value = productPriceValue;
    requestData.productQuantity.value = productQuantityValue;
    requestData.orderValue.value = orderValue;
    requestData.price = parseFloat(requestData.price);

    try {
      const response = await addOffer({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.CREATE_SHIPPING_OFFER);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      handleBanner({ isOpen: true, status: "success", title: cms("modal.offer.add.message.shippingOfferAdded") });
      setPopoverActive(false);
      setShippingOffer({ ...defaultOffer });
      onClose();
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };
  const onSubmit = () => {
    const hasError = checkValidation(shippingOffer, cms);
    if (hasError) {
      setBanner({ isOpen: true, status: "critical", title: hasError });
      return false;
    }
    addShippingBand();
    return true;
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <Modal
      large
      open={show}
      onClose={closeModal}
      title={cms("modal.offer.add.title")}
      primaryAction={{
        content: cms("modal.offer.add.button.primary"),
        loading,
        onAction: () => onSubmit(),
      }}
    >
      <Modal.Section>
        <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        <Stack vertical>
          <Stack.Item>
            <Card sectioned title={cms("modal.offer.add.section.band.title")}>
              <Stack vertical>
                <Select
                  id="shippingBand"
                  options={bands}
                  name="shippingBand"
                  placeholder={cms("modal.offer.add.section.band.placeholder.select")}
                  value={shippingOffer.shippingBandId || ""}
                  onChange={(val) => handleShippingOffer("shippingBandId", val)}
                />
              </Stack>
            </Card>
          </Stack.Item>
          <Stack.Item>
            <Card sectioned title={cms("label.destination")}>
              <Stack vertical>
                <RadioButton
                  label={cms("modal.offer.add.section.destination.label.allDestination")}
                  helpText={cms("modal.offer.add.section.destination.caption.allDestination")}
                  checked={isAllDestination}
                  id="allDestinations"
                  name="allDestinations"
                  onChange={handleDestinationChange}
                />
                <RadioButton
                  label={cms("modal.offer.add.section.destination.label.selectedDestination")}
                  helpText={cms("modal.offer.add.section.destination.caption.selectedDestination")}
                  id="selectedDestinations"
                  name="selectedDestinations"
                  checked={!isAllDestination}
                  onChange={handleDestinationChange}
                />
                {!isAllDestination && (
                  <Stack.Item>
                    <Stack.Item>
                      <Popover active={popoverActive} activator={activator} onClose={togglePopover}>
                        <OptionList
                          onChange={(val) => handleShippingOffer("destinationCodes", val)}
                          sections={destinations}
                          selected={shippingOffer.destinationCodes}
                          allowMultiple
                        />
                      </Popover>
                    </Stack.Item>
                    <Stack.Item>{renderDestinations()}</Stack.Item>
                  </Stack.Item>
                )}
              </Stack>
            </Card>
          </Stack.Item>
          {!isOrderValue && (
            <Stack.Item>
              <Card sectioned title={cms("label.product")}>
                <Stack vertical>
                  <RadioButton
                    id="allProducts"
                    name="allProducts"
                    label={cms("modal.offer.add.section.product.label.allProduct")}
                    helpText={cms("modal.offer.add.section.product.caption.allProduct")}
                    checked={isAllProduct}
                    onChange={handleProductChange}
                  />
                  <RadioButton
                    id="selectedProducts"
                    name="selectedProducts"
                    label={cms("modal.offer.add.section.product.label.selectedDestination")}
                    helpText={cms("modal.offer.add.section.product.caption.selectedDestination")}
                    checked={!isAllProduct}
                    onChange={handleProductChange}
                  />
                  {!isAllProduct && (
                    <AutoComplete
                      id="productOfferCondition"
                      placeholder={cms("modal.rule.add.placeholder.startTyping")}
                      values={products}
                      selected={shippingOffer.productIds}
                      onChange={(val) => handleShippingOffer("productIds", val)}
                      minimumSearchLength={3}
                    />
                  )}
                </Stack>
              </Card>
            </Stack.Item>
          )}
          {offerConditions()}
          <Stack.Item>
            <Card sectioned title={cms("modal.offer.add.section.product.label.offerDetails")}>
              <Stack vertical>
                <TextField
                  id="inputIdDescription"
                  name="description"
                  type="text"
                  label={`${cms("modal.offer.add.section.detail.label.description")}*`}
                  placeholder={cms("modal.offer.add.section.detail.placeholder.description")}
                  value={shippingOffer.description}
                  onChange={(val) => handleShippingOffer("description", val)}
                />
                <Stack>
                  <Stack.Item>
                    <Select
                      id="inputIdType"
                      name="shippingType"
                      options={priceTypeOptions}
                      label={cms("modal.offer.add.section.detail.label.selectShippingType")}
                      placeholder={cms("modal.rule.add.placeholder.SelectShippingRate")}
                      value={shippingOffer.priceType || ""}
                      disabled={isFieldDisabled}
                      onChange={(val) => handleShippingOffer("priceType", val)}
                    />
                  </Stack.Item>
                  {shippingOffer.priceType === constant.FIXED && (
                    <Stack.Item>
                      <TextField
                        id="inputIdCost"
                        label={`${cms("modal.rule.add.label.amount")}*`}
                        min={constant.value.MIN_PRICE}
                        step={constant.value.STEP}
                        value={shippingOffer.price.toString() || ""}
                        disabled={isFieldDisabled}
                        onChange={(val) => {
                          handleShippingOffer(constant.PRICE, acceptOnlyValidInput(val), shippingOffer.price);
                        }}
                        prefix={moneyFormat}
                      />
                    </Stack.Item>
                  )}
                </Stack>
                {!isOrderValue && (
                  <Checkbox
                    checked={shippingOffer.isCumulative}
                    onChange={toggleCumulativeShipping}
                    label={cms("modal.offer.add.section.detail.label.chargeAggregateShipping")}
                    helpText={cms("modal.offer.add.section.detail.caption.chargeAggregateShipping")}
                  />
                )}
              </Stack>
            </Card>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

AddOffer.propTypes = addOfferProp.type;

export default AddOffer;
