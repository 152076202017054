import React, { useContext, useEffect, useState } from "react";
import { Button, Layout, Stack, TextField, TextStyle } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const UrlMetaData = ({ metafield, setValue, inputValue, setBanner, setDisabledButton }) => {
  const [url, setURL] = useState([""]);
  const { cms } = useContext(PrivateContext);

  useEffect(() => {
    if (inputValue[metafield.key]) {
      const isArray = Array.isArray(inputValue[metafield.key]);
      if (isArray) {
        setURL(inputValue[metafield.key]);
      } else {
        setURL([inputValue[metafield.key]]);
      }
    }
  }, [metafield.key, inputValue]);

  const handleAdd = () => {
    const addURL = url || [];
    const isError = addURL.filter((item) => item).length !== addURL.length;
    if (isError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.row") || "Please complete the row details.",
      });
      return;
    }
    addURL.push("");
    setURL([...addURL]);
  };

  const handleChange = (index, value) => {
    const updateData = url || [];
    updateData[index] = value;
    const keyName = metafield.contentType === "url" ? metafield.key : "";
    setURL([...updateData]);
    setValue({
      ...inputValue,
      [keyName]: updateData,
    });
    setDisabledButton(false);
  };

  const handleDlete = (index) => {
    const updateData = url || [];
    updateData.splice(index, 1);
    setURL([...updateData]);
    setDisabledButton(false);
  };

  return (
    <>
      <TextStyle>{`${metafield.name} ${metafield.isRequired ? "*" : ""}`}</TextStyle>
      <Stack vertical>
        {(url || []).map((item, index) => (
          <Stack>
            <Stack.Item fill>
              <TextField
                id="url"
                value={item || ""}
                onChange={(value) => handleChange(index, value)}
                placeholder="Enter Url"
              />
            </Stack.Item>
            {metafield.value === "list" && url.length > 1 && (
              <Button id="deleteButton" plain onClick={() => handleDlete(index)}>
                <i className="far fa-trash fa-lg" style={{ color: "red" }} />
              </Button>
            )}
          </Stack>
        ))}
      </Stack>

      {metafield.value === "list" && (
        <>
          <Button plain id="addLink" onClick={() => handleAdd()}>
            {`${cms("common.button.addMore")}`}
          </Button>
        </>
      )}
      <Layout.Section />
    </>
  );
};

export default UrlMetaData;
