import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import OperatorPlanEdit from "./operator/features/managePlan/EditPlan";

const { userKey } = constant;
const { operator } = userKey;

const EditSubscriptionPlan = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorPlanEdit />;
    }
    return null;
  };
  const managePlans = getComponent(currentUserRole);
  return managePlans;
};

export default EditSubscriptionPlan;
