import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const LocationTopBar = styled.div`
  .Polaris-TopBar__SearchField {
    padding: 0;
  }
  .Polaris-TopBar-SearchField__Input {
    height: 4rem;
  }
  .Polaris-Choice {
    padding: 0;
  }
  .Polaris-Stack > .Polaris-Stack__Item {
    margin-left: 0.6rem;
  }
  .currency-topBar {
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

export const CurrencyTable = styled.div`
  .Polaris-DataTable__Cell--firstColumn {
    width: 35%;
    padding-left: 0;
  }
  .flag {
    display: flex;
  }
  .Polaris-DataTable__Cell--verticalAlignTop {
    vertical-align: middle;
  }
  .Polaris-Labelled__Error {
    position: absolute;
  }
`;
