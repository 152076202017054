import React from "react";
import { Card, Checkbox, FormLayout, Select, Stack, TextField } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { renderDetail } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

const RenderOfferDetail = (props) => {
  const { FIXED, FREE } = constant;
  const {
    isCumulative,
    handleCost,
    handleDescription,
    handleType,
    priceType,
    description,
    toggleCumulativeShipping,
    isOrderValue,
    moneyFormat,
    priceValue,
    cms,
  } = props;

  const options = [
    { label: cms("label.fixed"), value: FIXED },
    { label: cms("label.freeShipping"), value: FREE },
  ];

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <Card title={cms("modal.offer.add.section.product.label.offerDetails")} sectioned>
      <Stack.Item>
        <TextField
          label={`${cms("modal.offer.add.section.detail.label.description")}*`}
          placeholder={`${cms("modal.offer.add.section.detail.placeholder.description")} *`}
          value={description}
          onChange={handleDescription}
        />
      </Stack.Item>
      <Stack.Item>
        <FormLayout>
          <Stack>
            <Select
              label={`${cms("modal.offer.add.section.detail.label.selectShippingType")} *`}
              options={options}
              onChange={handleType}
              placeholder={cms("modal.offer.add.section.detail.placeholder.selectShipping")}
              value={priceType}
            />
            {priceType === FIXED && (
              <Stack.Item>
                <TextField
                  label={`${cms("modal.offer.add.section.detail.label.amount")} *`}
                  min={0}
                  step={0.01}
                  value={(priceValue && priceValue.toString()) || ""}
                  onChange={(value) => {
                    handleCost(acceptOnlyValidInput(value, priceValue));
                  }}
                  prefix={moneyFormat}
                />
              </Stack.Item>
            )}
          </Stack>
        </FormLayout>
      </Stack.Item>
      <br />
      {!isOrderValue && (
        <Stack.Item>
          <Checkbox
            checked={isCumulative}
            onChange={toggleCumulativeShipping}
            label={cms("label.chargeCumulative")}
            helpText={cms("modal.offer.add.section.detail.caption.chargeAggregateShipping")}
          />
        </Stack.Item>
      )}
    </Card>
  );
};

RenderOfferDetail.propTypes = renderDetail.type;

export default RenderOfferDetail;
