import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { storageHelper } from "lib/helpers";
import GenericExportOrder from "./genericExport/exportPdf";

const { userKey } = constant;
const { operator, provider } = userKey;

const ExportOrderLakePdf = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <GenericExportOrder />;
    }
    if (role === provider) {
      return <GenericExportOrder />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ExportOrderComponent = getComponent(currentUserRole);
  return ExportOrderComponent;
};

export default ExportOrderLakePdf;
