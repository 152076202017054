import React from "react";
import { Modal, TextContainer } from "@shopify/polaris";

function ApiAccessModal(props) {
  const {
    apiStatus,
    enableDisableApiLoading,
    handleApiOption,
    handleChange,
    isModalActive,
    selectedSellerBrandName,
    selectedSellerId,
    status,
  } = props;

  return (
    <>
      <Modal
        open={isModalActive}
        onClose={() => {
          handleChange();
        }}
        title="Disable API Confirmation"
        primaryAction={{
          content: "Confirm",
          onAction: () => handleApiOption(!!apiStatus.includes(status), selectedSellerId),
          loading: enableDisableApiLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              handleChange();
            },
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to disable the API for <b>{selectedSellerBrandName}</b>?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default ApiAccessModal;
