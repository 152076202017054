import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import { Layout } from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import { withErrorBoundary } from "lib/hoc";

import { CHECKOUT_STRIPE_PLAN } from "app/plans/apollo/mutations";

import Skeleton from "app/plans/modules/vendor/features/components/skeleton";

const StripeVendorPlan = () => {
  const { cms, isLoading, associatedSeller = {}, history } = useContext(PrivateContext);

  const { isStripeSubscription = false, stripeSubscription = {} } = associatedSeller || {};

  const { priceTableId = "", publishedKey = "" } = stripeSubscription || {};

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [checkoutStripePlan, { loading: createPlanLoading }] = useMutation(CHECKOUT_STRIPE_PLAN);

  const { session_id: stripeSessionId = "" } = baseHelper.queryParamsFromLocation(history);

  const isStripe = !!(isStripeSubscription && stripeSubscription && stripeSubscription.apiKey);
  const { gql } = constant;

  const onSubmit = async () => {
    try {
      const dataToSend = {
        sessionId: stripeSessionId,
      };

      const res = await checkoutStripePlan({
        variables: {
          input: dataToSend,
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.CHECKOUT_STRIPE_PLAN);
      const resError = baseHelper.getResponseError(res.data, gql.CHECKOUT_STRIPE_PLAN);
      if (resError) {
        setBanner({
          status: constant.CRITICAL,
          isOpen: true,
          title: resError,
        });
      }

      if (resData) {
        const title = cms("message.success.select");

        setBanner({
          isOpen: true,
          status: constant.SUCCESS,
          title,
        });

        setTimeout(() => {
          history.push("/");
        }, 1500);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  useEffect(() => {
    if (stripeSessionId) {
      onSubmit();
    }
  }, [stripeSessionId]);

  if (!isStripe) {
    return (
      <Layout.Section>
        <Banner isOpen status={constant.CRITICAL} title={cms("common.subscription.notEnable")} />
      </Layout.Section>
    );
  }

  if (isLoading && !(priceTableId && publishedKey)) {
    setTimeout(() => {
      return <Skeleton />;
    }, 1500);
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      {isStripe && !createPlanLoading && (
        <Layout.Section>
          {!(priceTableId && publishedKey) && <Skeleton />}
          <stripe-pricing-table pricing-table-id={priceTableId} publishable-key={publishedKey} />
        </Layout.Section>
      )}
    </>
  );
};

export default withErrorBoundary(StripeVendorPlan);
