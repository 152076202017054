import React, { useContext, useEffect } from "react";
import { useMutation } from "react-apollo";
import { MediaCard, TextStyle } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import { StyledMediaCard } from "app/onboarding/modules/genericStyle";

import { REVIEW_VENDOR_STATUS } from "app/vendors/apollo/mutations";

const PendingApproval = (props) => {
  const [reviewVendorStatus] = useMutation(REVIEW_VENDOR_STATUS);
  const { setSelectedTab, text } = props;

  const {
    cms,
    currentUser: { _id, isApproved, updatedAt },
  } = useContext(PrivateContext);
  const {
    onboardingSteps: { FINISH },
  } = constant;
  const finalText = [];

  useEffect(() => {
    if (isApproved === constant.APPROVE) {
      setSelectedTab(FINISH);
      try {
        reviewVendorStatus({
          variables: { input: { vendorId: _id, status: isApproved } },
        });
      } catch (exception) {
        baseHelper.getResponseError(exception, constant.gql.REVIEW_VENDOR_STATUS);
      }
    }
  }, [FINISH, _id, isApproved, reviewVendorStatus, setSelectedTab]);

  const linedText = text.split(/\r\n|\r|\n/g);
  linedText.forEach((element) => {
    const spacedText = element.split(" ");
    finalText.push(
      ...spacedText.map((value) => {
        if (value === "") {
          return <span>&nbsp;</span>;
        }
        return (
          <span>
            {value}
            &nbsp;
          </span>
        );
      })
    );
    finalText.push(<br />);
  });

  return (
    <StyledMediaCard>
      <MediaCard
        title={cms("common.onboarding.reviewVendor.title")}
        description={[
          <>
            <TextStyle variation="subdued">
              {`${cms("common.onboarding.reviewVendor.description")} ${baseHelper.formatDate(updatedAt)}`}
            </TextStyle>
            <div className="notes">{finalText}</div>
          </>,
        ]}
      >
        <img
          alt=""
          width="100%"
          height="100%"
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
          src={constant.imageURL.WELCOME}
        />
      </MediaCard>
    </StyledMediaCard>
  );
};

export default withErrorBoundary(PendingApproval);
