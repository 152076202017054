import { gql } from "apollo-boost";

const BRAND_LOGO = gql`
  query getBrandDetails($input: VendorProfileData!) {
    getBrandDetails(input: $input) {
      data {
        vendorDetails {
          brand {
            imageUrl
          }
        }
      }
      status
      error
    }
  }
`;
export default BRAND_LOGO;
