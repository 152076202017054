// import react packages;
import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// Import Polaris Components
import {
  Card,
  Collapsible,
  Checkbox,
  PageActions,
  Link,
  TextField,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

// helper
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

import SHOPIFY_INSTALL_VENDOR_LOGIN from "app/product/apollo/mutations/shopifyVendorLogin";
import validate from "app/appPermission/generic/yup";

// import components
import SyncProduct from "app/product/modules/provider/features/add/subFeatures/syncProduct";
import TermModal from "app/product/modules/provider/features/genric/term/term";
import DiscoveryTermModal from "app/product/modules/provider/features/genric/term/discoveryTerm";
import ConnectedShop from "./subFeatureItems/connected";

const VendorAppConnectShopify = (props) => {
  const { history, currentUser: currentUserData, cms, currentUserRefetch } = useContext(PrivateContext);
  const { appToMigrate = false, pageName, setBanner, setIsOpenModal, setModalBanner } = props;
  const [shop, setShop] = useState((currentUserData && currentUserData.shop) || "");
  const [isConnected, setIsConnected] = useState(!!(currentUserData && currentUserData.shop));
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [shopConnectedStatus, setShopConnectedStatus] = useState(!!isConnected);
  const [disconnectStatus, setDisconnectStatus] = useState(false);
  const {
    ADMIN_SCOPES_KEY,
    API_APP_KEY,
    API_APP_SECRET_KEY,
    API_ACCESS_TOKEN,
    gql,
    HIDE,
    REGISTERSUBDOMIN,
    SAVED,
    SHOPIFY_CONNECT_FORM,
    SHOPIFY_CONNECT_RADIO,
    STARTS,
    STEP,
    SETTINGS,
    SHOW,
    TOTAL_CHECKBOXES,
    INSTALL_APP_KEY,
    VALIDATED,
  } = constant;
  const [shopifyInstallAndVendorLogin, { loading }] = useMutation(SHOPIFY_INSTALL_VENDOR_LOGIN);

  const defaultPermissions = {
    goListingsPage: {
      checked: false,
      disable: false,
      order: 1,
      type: STEP,
      title: cms("common.appPermission.label.goListingsPage"),
    },
    developApps: {
      checked: false,
      disable: true,
      order: 2,
      type: STEP,
      title: cms("common.appPermission.label.developApps"),
    },
    customAppDev: {
      checked: false,
      disable: true,
      order: 3,
      type: STEP,
      title: cms("common.appPermission.label.customAppDev"),
    },
    appMarketcube: {
      checked: false,
      disable: true,
      order: 4,
      type: STEP,
      title: cms("common.appPermission.label.appMarketcubeVendor"),
    },
    adminScopes: {
      checked: false,
      disable: true,
      order: 5,
      type: STEP,
      title: cms("common.appPermission.label.adminScopes"),
    },
    read_customers: {
      checked: false,
      disable: true,
      order: 6,
      type: SETTINGS,
      title: cms("common.appPermission.customers.title"),
      helpText: cms("common.appPermission.customers.helpText"),
    },
    read_discounts: {
      checked: false,
      disable: true,
      order: 7,
      type: SETTINGS,
      title: cms("common.appPermission.discounts.title"),
      helpText: cms("common.appPermission.discounts.helpText"),
    },
    write_fulfillments: {
      checked: false,
      disable: true,
      order: 8,
      type: SETTINGS,
      title: cms("common.appPermission.fulfillments.title"),
      helpText: cms("common.appPermission.fulfillments.helpText"),
    },
    write_inventory: {
      checked: false,
      disable: true,
      order: 9,
      type: SETTINGS,
      title: cms("common.appPermission.inventory.title"),
      helpText: cms("common.appPermission.inventory.helpText"),
    },
    read_locations: {
      checked: false,
      disable: true,
      order: 10,
      type: SETTINGS,
      title: cms("common.appPermission.locations.title"),
      helpText: cms("common.appPermission.locations.helpText"),
    },
    write_merchant_managed_fulfillment_orders: {
      checked: false,
      disable: true,
      order: 11,
      type: SETTINGS,
      title: cms("common.appPermission.merchant.title"),
      helpText: cms("common.appPermission.merchant.helpText"),
    },
    write_order_edits: {
      checked: false,
      disable: true,
      order: 12,
      type: SETTINGS,
      title: cms("common.appPermission.orderEdits.title"),
      helpText: cms("common.appPermission.orderEdits.helpText"),
    },
    read_orders: {
      checked: false,
      disable: true,
      order: 13,
      type: SETTINGS,
      title: cms("common.appPermission.orders.title"),
      helpText: cms("common.appPermission.orders.helpText"),
    },
    read_product_listings: {
      checked: false,
      disable: true,
      order: 14,
      type: SETTINGS,
      title: cms("common.appPermission.productListings.title"),
      helpText: cms("common.appPermission.productListings.helpText"),
    },
    write_products: {
      checked: false,
      disable: true,
      order: 15,
      type: SETTINGS,
      title: cms("common.appPermission.products.title"),
      helpText: cms("common.appPermission.products.helpText"),
    },
    write_shipping: {
      checked: false,
      disable: true,
      order: 16,
      type: SETTINGS,
      title: cms("common.appPermission.shipping.title"),
      helpText: cms("common.appPermission.shipping.helpText"),
    },
    installApp: { checked: false, disable: true, order: 17, title: cms("common.appPermission.label.installApp") },
  };

  const productDiscoveryPermission = {
    goListingsPage: {
      checked: false,
      disable: false,
      order: 1,
      type: STEP,
      title: cms("common.appPermission.label.goListingsPage"),
    },
    developApps: {
      checked: false,
      disable: true,
      order: 2,
      type: STEP,
      title: cms("common.appPermission.label.developApps"),
    },
    customAppDev: {
      checked: false,
      disable: true,
      order: 3,
      type: STEP,
      title: cms("common.appPermission.label.customAppDev"),
    },
    appMarketcube: {
      checked: false,
      disable: true,
      order: 4,
      type: STEP,
      title: cms("common.appPermission.label.appMarketcubeVendor"),
    },
    adminScopes: {
      checked: false,
      disable: true,
      order: 5,
      type: STEP,
      title: cms("common.appPermission.label.adminScopes"),
    },
    write_inventory: {
      checked: false,
      disable: true,
      order: 9,
      type: SETTINGS,
      title: cms("common.appPermission.inventory.title"),
      helpText: "read_inventory",
    },
    read_locations: {
      checked: false,
      disable: true,
      order: 10,
      type: SETTINGS,
      title: cms("common.appPermission.locations.title"),
      helpText: "read_locations",
    },
    read_product_listings: {
      checked: false,
      disable: true,
      order: 14,
      type: SETTINGS,
      title: cms("common.appPermission.productListings.title"),
      helpText: "read_product_listings",
    },
    write_products: {
      checked: false,
      disable: true,
      order: 15,
      type: SETTINGS,
      title: cms("common.appPermission.products.title"),
      helpText: "read_products",
    },
    installApp: { checked: false, disable: true, order: 17, title: cms("common.appPermission.label.installApp") },
  };

  const isProductDiscovery = !!currentUserData?.isProductDiscovery;

  const [values, setValues] = useState(isProductDiscovery ? productDiscoveryPermission : defaultPermissions);

  useEffect(() => {
    setValues(isProductDiscovery ? productDiscoveryPermission : defaultPermissions);
  }, [isProductDiscovery]);

  const [apiDetails, setApiDetails] = useState({
    shopSubDomain: currentUserData?.shop,
    apiAccessToken: "",
    apiKey: "",
    apiSecretKey: "",
  });

  const toggleBanner = () =>
    setBanner({
      action: null,
      isOpen: false,
      status: "",
      title: "",
      onDismiss: null,
    });

  const handleChange = (value) => {
    // step by step checkbox
    const updateData = {};
    if (values[value].type === STEP) {
      updateData[value] = { ...values[value], checked: true, disable: true };
      const nextCheckbox = Object.keys(values)[values[value].order];

      if (values[nextCheckbox].type === STEP) {
        updateData[nextCheckbox] = { ...values[nextCheckbox], checked: false, disable: false };
      }
      // permission checkbox all disable remove
      if (value === ADMIN_SCOPES_KEY) {
        Object.keys(values).forEach((item) => {
          if (values[item].type === SETTINGS) {
            updateData[item] = { ...values[item], disable: false };
          }
        });
      }
    } else if (values[value].type === SETTINGS) {
      updateData[value] = { ...values[value], checked: !values[value].checked, disable: false };
    }

    // end step by step

    // disable install app checkbox if all top checkbox not checked
    const updatedDataSet = { ...values, ...updateData };
    const listChecked = Object.keys(updatedDataSet).filter(
      (data) => updatedDataSet[data].checked && data !== INSTALL_APP_KEY
    );
    const totalCheckboxes = isProductDiscovery ? 9 : TOTAL_CHECKBOXES;
    if (listChecked.length === totalCheckboxes) {
      updateData.installApp = { ...values.installApp, disable: false };
      if (value === INSTALL_APP_KEY) {
        updateData.installApp = { ...values.installApp, checked: !values[value].checked, disable: false };
      }
    } else {
      updateData.installApp = { ...values.installApp, checked: false, disable: true };
    }

    setValues((prevState) => ({
      ...prevState,
      ...updateData,
    }));
  };

  const validShop = (shopCheck) => {
    if (!shopCheck) {
      return false;
    }

    const isValidShop = shopCheck.endsWith(".myshopify.com");
    const shopIndex = shopCheck.indexOf(".myshopify.com");

    if (shopIndex <= -1 || !isValidShop) {
      return shopCheck;
    }
    const shopName = shopCheck.slice(0, shopIndex);
    return shopName;
  };

  const handleField = (field, value) => {
    setApiDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (appToMigrate) {
      setShopConnectedStatus(false);
    }
  }, [appToMigrate]);

  const handleValidation = async (field, value) => {
    const schema = {};
    // removing myshopify.com in shop name
    if (field === REGISTERSUBDOMIN) {
      setApiDetails((prevState) => ({
        ...prevState,
        [field]: validShop(value),
      }));
    }

    const validationError = await validate(field, value, cms, schema);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };
  const isAnyError = () => Object.values(errorMessage).some((error) => error);

  const isAllValuesFilled = () =>
    Object.keys(values).every((key) => {
      if (values[key].checked === false) {
        return false;
      }
      return typeof values[key].checked === "boolean" ? true : values[key];
    });

  const isAllAPIValuesFilled = () =>
    Object.keys(apiDetails).every((key) => {
      if (!apiDetails[key]) {
        return false;
      }
      return typeof apiDetails[key] === "boolean" ? true : apiDetails[key];
    });

  const [toggleStatus, setToggleStatus] = useState({
    read_customers: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_discounts: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_fulfillments: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_inventory: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_locations: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_merchant_managed_fulfillment_orders: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_order_edits: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_orders: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    read_product_listings: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_products: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
    write_shipping: {
      active: true,
      toggleText: baseHelper.ucFirst(HIDE),
    },
  });

  const handleToggle = (event) => {
    const { id } = event.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? SHOW : HIDE,
      },
    }));
  };

  const handleTermModal = (value) => {
    if (value === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  const onSubmit = async () => {
    try {
      let bannerValue = {};
      const storeInputToSend = {
        shopSubDomain: `${apiDetails?.shopSubDomain.trim() + cms("common.appPermission.shop.suffix.shopSubDomain")}`,
        apiAccessToken: apiDetails?.apiAccessToken.trim(),
        apiKey: apiDetails?.apiKey.trim(),
        apiSecretKey: apiDetails?.apiSecretKey.trim(),
        isValidShop: isOpen,
      };
      if (appToMigrate) {
        storeInputToSend.appToMigrate = true;
      }
      const res = await shopifyInstallAndVendorLogin({
        variables: {
          input: storeInputToSend,
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.SHOPIFY_VENDOR_LOGIN_KEY);
      const { shopifyInstallAndVendorLogin: { status = "", error = "" } = {} } = res.data || {}
      if (status === "error") {
        setIsOpen(false);
        setModalBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: error,
        });
        return;
      }

      if (responseData.shopStatus === "supplier") {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `Shop ${cms("section.connect.message.error.alreadyConnected")}`,
        });
        return;
      }
      if (responseData.shopStatus === "associated") {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${cms("connect.message.error.cannotSync")} ${apiDetails?.shopSubDomain.trim()} ${cms(
            "section.connect.message.error.alreadyAssociated"
          )}`,
        });
        return;
      }

      if (responseData?.shopStatus === VALIDATED && !responseData?.dataSave) {
        handleTermModal("connect");
      }

      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.SHOPIFY_VENDOR_LOGIN_KEY);
        bannerValue = { status: constant.CRITICAL, title: errorResponse, onDismiss: toggleBanner };
        setIsOpen(false);
      }

      if (responseData?.shopStatus === SAVED) {
        const resetData = {};
        toggleBanner();
        Object.keys(values).forEach((item) => {
          resetData[item] = { ...values[item], checked: false, disable: true };
        });
        setShopConnectedStatus(true);
        setValues(resetData);
        // setApiDetails({ apiAccessToken: "", apiKey: "", apiSecretKey: "" });
        setApiDetails((apiDetailsData) => ({
          ...apiDetailsData,
          apiAccessToken: "",
          apiKey: "",
          apiSecretKey: "",
        }));

        setIsOpen(false);
        bannerValue = {
          status: constant.SUCCESS,
          children: "",
          title: cms("common.appPermission.message.success.selectVendor"),
          onDismiss: toggleBanner,
        };

        if (pageName === SHOPIFY_CONNECT_RADIO) {
          history.push("/products/add?shop=true");
        }
        if (appToMigrate) {
          setShopConnectedStatus(false);
          setIsOpenModal(false);
          currentUserRefetch();
        }
      }
      setBanner({ ...bannerValue, isOpen: true });
      /// end some error come
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const resetForm = () => {
    setShopConnectedStatus(false);
    const resetData = {};
    Object.keys(values).forEach((item) => {
      resetData[item] = { ...values[item], checked: false, disable: true };
    });
    resetData.goListingsPage = { ...values.goListingsPage, checked: false, disable: false };
    setValues(resetData);
    setApiDetails({ shopSubDomain: "", apiAccessToken: "", apiKey: "", apiSecretKey: "" });
  };
  useEffect(() => {
    if (disconnectStatus) {
      resetForm();
    }
  }, [disconnectStatus]);

  const secondaryActionsButton = () => {
    if (!shopConnectedStatus) {
      return [
        {
          content: cms("common.button.cancel"),
          id: "cancelButton",
          onAction: () => history.push("/products"),
        },
      ];
    }

    return [
      {
        content: (
          <ConnectedShop
            cms={cms}
            currentUserData={currentUserData}
            setBanner={setBanner}
            setIsConnected={setIsConnected}
            setShop={setShop}
            setDisconnectStatus={setDisconnectStatus}
          />
        ),
        disabled: !shopConnectedStatus,
        id: "disconnectButton",
        destructive: !shopConnectedStatus,
      },
    ];
  };
  // connected shop success message
  if (history.location.search === "?shop=true") {
    const bannerValues = {
      status: constant.SUCCESS,
      children: "",
      title: cms("common.appPermission.message.success.selectVendor"),
      onDismiss: toggleBanner,
    };
    setBanner({ ...bannerValues, isOpen: true });
    history.push("/products/add");
  }
  // connected shop

  if (shopConnectedStatus) {
    return (
      <Card title={cms("section.connect.title")}>
        <Card.Section sectioned>
          <TextContainer>{cms("section.connect.description")}</TextContainer>
          <br />
          <TextField
            label={cms("common.appPermission.shop.label")}
            error={errorMessage && errorMessage.shopSubDomain}
            disabled={!!shopConnectedStatus}
            // helpText={`${cms("common.appPermission.shop.helpText")}`}
            id="shopSubDomain"
            type={constant.TEXT}
            prefix={`${cms("common.appPermission.shop.prefix.shopSubDomain")}`}
            suffix={`${cms("common.appPermission.shop.suffix.shopSubDomain")}`}
            value={shopConnectedStatus ? currentUserData.shop || apiDetails?.shopSubDomain : apiDetails?.shopSubDomain}
            onChange={(value) => handleField(REGISTERSUBDOMIN, value)}
            onBlur={() => handleValidation(REGISTERSUBDOMIN, apiDetails?.shopSubDomain)}
          />
          <br />
          <Stack>
            <Stack.Item fill>
              <ConnectedShop
                cms={cms}
                currentUserData={currentUserData}
                setBanner={setBanner}
                setIsConnected={setIsConnected}
                setShop={setShop}
                setDisconnectStatus={setDisconnectStatus}
              />
            </Stack.Item>
            <Stack.Item>
              <SyncProduct history={history} setBanner={setBanner} cms={cms} />
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    );
  }
  return (
    <>
      {isProductDiscovery ? <DiscoveryTermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={shop}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.SHOPIFY_CAPITAL}
      /> : <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={shop}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.SHOPIFY_CAPITAL}
      />}
      {pageName === SHOPIFY_CONNECT_FORM && (
        <>
          {" "}
          <TextContainer>
            {cms("section.connect.description")}
            <Link external url={constant.ABOUT_VENDOR_APP_MIGRATE}>
              {` ${cms("common.appPermission.label.aboutURL")}`}
            </Link>
          </TextContainer>
          <br />
        </>
      )}
      <Card title={cms("common.appPermission.title")}>
        <Card.Section title={cms("common.appPermission.label.step1")}>
          {values &&
            Object.keys(values).map((item) => {
              if (values[item].type === STEP) {
                return (
                  <div key={item}>
                    <Checkbox
                      value={item}
                      label={values?.[item]?.title}
                      disabled={shopConnectedStatus ? true : values?.[item]?.disable}
                      checked={shopConnectedStatus ? true : values?.[item]?.checked}
                      onChange={() => handleChange(item)}
                    />
                  </div>
                );
              }
              return null;
            })}
        </Card.Section>

        <Card title={cms("common.appPermission.subTitle")}>
          {values &&
            Object.keys(values).map((item) => {
              if (values[item].type === SETTINGS) {
                return (
                  <Card.Section key={item}>
                    <Stack>
                      <Stack.Item fill>
                        {" "}
                        <Checkbox
                          value={item}
                          label={values?.[item]?.title}
                          disabled={shopConnectedStatus ? true : values?.[item]?.disable}
                          checked={shopConnectedStatus ? true : values?.[item]?.checked}
                          onChange={() => handleChange(item)}
                        />
                        <Collapsible id={item} open={toggleStatus?.[item]?.active}>
                          <p className="customer_text">{values?.[item]?.helpText}</p>
                        </Collapsible>
                      </Stack.Item>
                      <Stack.Item>
                        <Link onClick={handleToggle} id={item}>
                          {toggleStatus?.[item]?.toggleText}
                        </Link>
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                );
              }
              return null;
            })}
        </Card>

        <Card.Section title={cms("common.appPermission.label.step2")}>
          <Checkbox
            value="installApp"
            label={values?.installApp?.title}
            disabled={shopConnectedStatus ? true : values?.installApp?.disable}
            checked={shopConnectedStatus ? true : values?.installApp?.checked}
            onChange={() => handleChange(INSTALL_APP_KEY)}
          />
        </Card.Section>

        <Card.Section sectioned title={cms("common.appPermission.label.step3")}>
          <TextField
            label={`${cms("common.appPermission.shop.label")}*`}
            error={errorMessage && errorMessage.shopSubDomain}
            disabled={!!shopConnectedStatus}
            helpText={`${cms("common.appPermission.shop.helpText")}`}
            id="shopSubDomain"
            type={constant.TEXT}
            prefix={`${cms("common.appPermission.shop.prefix.shopSubDomain")}`}
            suffix={`${cms("common.appPermission.shop.suffix.shopSubDomain")}`}
            value={shopConnectedStatus ? currentUserData.shop || apiDetails?.shopSubDomain : apiDetails?.shopSubDomain}
            onChange={(value) => handleField(REGISTERSUBDOMIN, value)}
            onBlur={() => handleValidation(REGISTERSUBDOMIN, apiDetails?.shopSubDomain)}
          />
          <br />
          <TextField
            label={`${cms("common.appPermission.label.apiAccessToken")}*`}
            id="apiAccessToken"
            type={constant.TEXT}
            disabled={!!shopConnectedStatus}
            error={errorMessage && errorMessage.apiAccessToken}
            value={shopConnectedStatus ? STARTS : apiDetails?.apiAccessToken}
            onChange={(value) => handleField(API_ACCESS_TOKEN, value)}
            onBlur={() => handleValidation(API_ACCESS_TOKEN, apiDetails?.apiAccessToken)}
          />
          <br />

          <TextField
            label={`${cms("common.appPermission.label.apiKey")}*`}
            id="apiKey"
            disabled={!!shopConnectedStatus}
            type={constant.TEXT}
            error={errorMessage && errorMessage.apiKey}
            value={shopConnectedStatus ? STARTS : apiDetails?.apiKey}
            onChange={(value) => handleField(API_APP_KEY, value)}
            onBlur={() => handleValidation(API_APP_KEY, apiDetails?.apiKey)}
          />
          <br />

          <TextField
            label={`${cms("common.appPermission.label.apiSecretKey")}*`}
            id="apiSecretKey"
            disabled={!!shopConnectedStatus}
            type={constant.TEXT}
            error={errorMessage && errorMessage.apiSecretKey}
            value={shopConnectedStatus ? STARTS : apiDetails?.apiSecretKey}
            onChange={(value) => handleField(API_APP_SECRET_KEY, value)}
            onBlur={() => handleValidation(API_APP_SECRET_KEY, apiDetails?.apiSecretKey)}
          />
          <br />
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={[
          {
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => onSubmit(),
            disabled: !isAllValuesFilled() || !isAllAPIValuesFilled() || isAnyError(),
            loading,
          },
        ]}
        secondaryActions={secondaryActionsButton()}
      />
      {pageName === SHOPIFY_CONNECT_FORM && shopConnectedStatus && (
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <SyncProduct history={history} setBanner={setBanner} cms={cms} />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

VendorAppConnectShopify.propTypes = {
  setBanner: PropTypes.func,
};

VendorAppConnectShopify.defaultProps = {
  setBanner: () => {},
};

export default withFeature(withErrorBoundary(VendorAppConnectShopify), {
  feature: constant.PRODUCT_IMPORT_SHOPIFY,
});
