import React from "react";

import { ActivityList } from "app/userManagement/modules/generic/index";

const ProviderActivityList = () => {
  return (
    <>
      <ActivityList />
    </>
  );
};

export default ProviderActivityList;
