import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Card,
  FormLayout,
  Modal,
  RadioButton,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { isBoolean } from "lodash";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import { SkeletonCard, Toast } from "lib/components";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import queries
import {
  GET_ADVANCE_METAFIELD,
  GET_METAFIELD_SETTING,
  GET_PRODUCT_AND_SELLER_PRODUCT,
} from "app/product/apollo/queries";
import { ADD_PRODUCT_METAFIELD, UPLOAD_IMAGE } from "app/product/apollo/mutations";
import DateMetaField from "./genericMetafield/date";
import MoneyMetaField from "./genericMetafield/money";
import UrlMetaData from "./genericMetafield/url";
import File from "./genericMetafield/file";
import JsonMetaData from "./genericMetafield/json";

const EditMetafield = (props) => {
  const { cms, history, match, currentUser } = useContext(PrivateContext);
  const { handleTabChange, isVariant, setDescription, setTitle, setBanner } = useContext(ProductContext);
  const { tabObj = {} } = props;
  const { islastTab = false } = tabObj || {};
  const { moneyFormat } = currentUser;

  const [disabledButton, setDisabledButton] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("kg");
  const [updatedAt, setUpdatedAt] = useState("");
  const [value, setValue] = useState({});
  const [metafieldData, setMetafieldData] = useState([]);
  const [catalogIds, setCatalogIds] = useState([]);
  const [activeColor, setActivateColor] = useState(false);
  const [active, setActive] = useState(false);

  const selectValue = {
    // color: "color",
    date: "date",
    dateTime: "dateTime",
    file: "file",
    trueFalse: "trueFalse",
    json: "json",
    url: "url",
    money: "money",
  };
  const weightUnitOption = ["lb", "oz", "kg", "g"];
  const dimensionUnitOption = ["mm", "cm", "m", "in", "ft", "yd"];
  const volumeUnitOption = [
    "ml",
    "cl",
    "L",
    "m^3",
    "fl oz",
    "pt",
    "qt",
    "gal",
    "imp fl oz",
    "imp pt",
    "imp qt",
    "imp gal",
  ];

  const multipleOption = [constant.MEASUREMENT, constant.WEIGHT, constant.DIMENSION, "volume", "url"];

  const { loading: catalogLoading, data: metaFieldData } = useQuery(GET_METAFIELD_SETTING);
  const { data: advanceData, loading: advanceLoading } = useQuery(GET_ADVANCE_METAFIELD);

  const { loading: productLoading, data: productData, refetch } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  const [addProductMetafield, { loading: updateTagSettingLoading }] = useMutation(ADD_PRODUCT_METAFIELD);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);

  setTitle(cms("label.title.metafield"));
  setDescription(cms("label.addMetafield"));

  useEffect(() => {
    if (productData) {
      const productResponse =
        baseHelper.getResponseData(productData, constant.gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      const dataToSend = {};
      const metaData =
        productResponse && productResponse.sellerProduct && productResponse.sellerProduct.customMetafields;
      const updatedAtDate = productResponse && productResponse.sellerProduct && productResponse.sellerProduct.updatedAt;
      const { shopifyCatalogId } = (productResponse && productResponse.sellerProduct) || {};
      setCatalogIds(shopifyCatalogId || []);
      if (metaData && updatedAtDate) {
        metaData.forEach((data) => {
          if (data && data.value && data.value.unit) {
            setSelectedValue(data.value.unit);
          }
          dataToSend[data.key] = data.value;
        });
        setValue(dataToSend);
        setUpdatedAt(updatedAtDate);
      }
    }
  }, [constant.gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  // load product catalog data
  useEffect(() => {
    const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
    const advanceSetting = baseHelper.getResponseData(advanceData, "getAdvanceMetafieldSetting") || {};
    const { advanceMetafield } = advanceSetting || {};
    const { metafield = [] } = metafieldSetting;
    const { isEnabled, isAll, customSetting } = advanceMetafield || {};
    if (metafield && metafield.length && isEnabled) {
      const advanceFilterData = (customSetting || []).filter((item) => catalogIds.includes(item.catalogId));
      let filterSetting = [];
      if (isAll && !(advanceFilterData && advanceFilterData.length)) {
        filterSetting = metafield || [];
      } else {
        const metaKeys = advanceFilterData.flatMap((item) => item.metafields);
        const activeKeys = [...new Set(metaKeys)];
        filterSetting = (metafield || []).filter((item) => activeKeys.includes(item.key));
      }
      setMetafieldData(filterSetting);
      return;
    }
    setMetafieldData(metafield || []);
    (metafield || []).forEach((item) => {
      if (item.isRequired) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    });
  }, [metaFieldData, catalogIds, advanceData]);

  const handleChange = useCallback(
    (fieldName, fieldValue, index, isMesurement, type, metaData) => {
      let multipleValue = value[fieldName];
      const notInArray = ["json", "trueFalse", "money"];
      const measurementUnitData = metaData?.contentType === "measurement";
      let measurementUnit = "";
      if (measurementUnitData) {
        [measurementUnit = ""] = (metaData.value[0] || "").split(",") || [];
      }
      const defaultUnit = {
        weight: "lb",
        volume: "ml",
        dimension: "mm",
        measurement: measurementUnit || "",
      };
      if (Array.isArray(multipleValue)) {
        multipleValue[index] = isMesurement
          ? { unit: multipleValue[index]?.unit || defaultUnit[type], value: fieldValue }
          : fieldValue;
      } else if (notInArray.includes(type)) {
        multipleValue = fieldValue;
      } else {
        multipleValue = [
          isMesurement ? { value: fieldValue, unit: multipleValue?.unit || defaultUnit[type] } : fieldValue,
        ];
      }
      setValue({
        ...value,
        [fieldName]: multipleValue,
      });
      setDisabledButton(false);
    },
    [value]
  );

  const handleMeasurement = (fieldName, fieldValue, index) => {
    setDisabledButton(false);
    setSelectedValue(fieldValue);
    let updateData = value[fieldName.key] || [];
    const isArray = Array.isArray(updateData);
    if (isArray) {
      updateData[index] = { ...updateData[index], unit: fieldValue };
    } else {
      updateData = { ...updateData, unit: fieldValue };
    }
    setValue((prevState) => ({
      ...prevState,
      [fieldName.key]: updateData,
    }));
  };

  const typeOption = (data) => {
    const splitData = data[0].split(",");
    return splitData.map((key) => ({ label: key, value: key })) || {};
  };

  const handleAddImage = ({ data, metafield }) => {
    const productImages = (value && value[metafield.key]) || [];
    const isArray = Array.isArray(productImages);
    setValue({
      ...value,
      [metafield.key]: isArray ? [...productImages, ...data] : [productImages, ...data],
    });
  };

  const imageUpload = (formField) => {
    let images = value[formField.key] || [];
    let hasImageError = false;
    if (images && images.length) {
      const uploadedImages = [];
      const uploadImageCallback = (imageResponse) => {
        const { imageUrl = null } = imageResponse;
        if (imageUrl) {
          uploadedImages.push(imageUrl);
        }
        if (hasImageError) {
          return;
        }
        if (uploadedImages && uploadedImages.length > 0 && uploadedImages.length === images.length) {
          images = uploadedImages;
          setActive(false);
          setValue({
            ...value,
            [formField.key]: images,
          });
          setDisabledButton(false);
        }
      };
      images.map(async (img) => {
        try {
          if (hasImageError) {
            return;
          }
          let response = {};
          const regex = new RegExp('data:image/([a-zA-Z]*);base64,([^"]*)');
          if (regex.test(img)) {
            response = await uploadImage({ variables: { input: { image: img, productId: match.params.id } } });
          } else {
            uploadImageCallback({ imageUrl: img });
            return;
          }
          // if (!imageStartUploading) {
          //   setImageStartUploading(true);
          // }
          const imageResponse = baseHelper.getResponseData(response.data, constant.gql.UPLOAD_IMAGE);
          const imageError = baseHelper.getResponseError(response.data, constant.gql.UPLOAD_IMAGE);
          if (imageError) {
            // setImageStartUploading(false);
            hasImageError = true;
            setBanner((prev) => ({
              ...prev,
              action: null,
              isOpen: true,
              status: constant.CRITICAL,
              title: imageError,
            }));
            setActive(false);
          }
          uploadImageCallback(imageResponse);
        } catch (uploadImageError) {
          // setImageStartUploading(false);
          hasImageError = true;
          setBanner((prev) => ({
            ...prev,
            action: null,
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("label.notSave"),
          }));
          setActive(false);
        }
      });
      // setImageStartUploading(false);
    } else {
      setActive(false);
      setDisabledButton(false);
    }
    return null;
  };

  const removeExistingImage = (val, index, key) => {
    let imageData = value[key];
    const isArray = Array.isArray(imageData);
    if (isArray) {
      imageData.splice(index, 1);
    } else {
      imageData = "";
    }
    setValue({
      ...value,
      [key]: imageData,
    });
  };

  const selectDropdown = (field, index, item) => {
    return (
      <Select
        value={(item && item.unit) || ""}
        options={
          (field.contentType === constant.WEIGHT && weightUnitOption) ||
          (field.contentType === constant.DIMENSION && dimensionUnitOption) ||
          (field.contentType === "volume" && volumeUnitOption) ||
          typeOption(field.value)
        }
        onChange={(val) => {
          handleMeasurement(field, val, index || 0);
        }}
      />
    );
  };

  const handleDropdown = (field, index, item) => {
    if ([constant.MEASUREMENT, constant.WEIGHT, constant.DIMENSION, "volume"].includes(field.contentType)) {
      return selectDropdown(field, index, item);
    }
    return null;
  };

  // const handleDimension = (field) => {
  //   return (
  //     <>
  //       <TextField
  //         label={
  //           (field.contentType === constant.DIMENSION && "Maximum dimension") ||
  //           (field.contentType === "volume" && "Maximum volume") ||
  //           (field.contentType === "rating" && "Rating scale maximum")
  //         }
  //         connectedRight={handleDropdown(field)}
  //       />
  //       <br />
  //     </>
  //   );
  // };

  const handlePlaceHolder = (field) => {
    if ([constant.NUMBER, constant.MEASUREMENT].includes(field)) {
      return constant.ZERO_PRICE;
    }
    if (field === constant.INTEGER) {
      return constant.ZERO_PRICE_INT;
    }
    return null;
  };

  const activator = (key, data) => {
    const isArray = Array.isArray(data);
    const isData = isArray ? data.length : data;
    return <Button onClick={() => setActive({ [key]: !active[key] })}>{isData ? "Preview" : "Select"}</Button>;
  };

  const handleValidateInput = (field, val, prevValue) => {
    setIsValid(true);
    if (
      [constant.NUMBER, constant.MEASUREMENT, constant.WEIGHT, constant.DIMENSION, constant.VOLUME].includes(
        field.contentType
      )
    ) {
      const validate = baseHelper.validatePositiveNumericValues(val);
      // field.contentType === constant.NUMBER
      //   ? baseHelper.validatePositiveNumericValues(val)
      //   : baseHelper.validatePositiveNumericValues(val && val.value);
      if (field.contentType === constant.NUMBER) {
        if (Number(val) > Number(field.value)) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.maxLength"),
          });
          setIsValid(false);
          return "";
        }
      }
      return (validate && val) || "";
    }
    if (field.contentType === constant.STRING && val.length > field.value) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.maxLength"),
      });
      setIsValid(false);
      return prevValue;
    }
    return val;
  };

  // const advanceField = (option) => {
  //   return (
  //     <>
  //       {/* {option === "color" && ( */}
  //       <Stack wrap={false} spacing="extraTight" distribution="fillEvenly" alignment="center">
  //         <br />
  //         <Stack.Item>
  //           <TextField
  //             required
  //             placeholder={option}
  //             // placeholder="metafield.name"
  //           />
  //         </Stack.Item>
  //         <Stack.Item>
  //           <Button id="deleteButton" plain>
  //             <i className="far fa-trash fa-lg" style={{ color: "red" }} />
  //           </Button>
  //         </Stack.Item>
  //       </Stack>
  //       {/* )} */}
  //     </>
  //   );
  // };

  const checkRequiredValues = (data) => {
    let isError = false;
    let keyName = "";
    (metafieldData || []).forEach((item) => {
      if (item.isRequired) {
        const itemData = data.find((dataItem) => dataItem.key === item.key);

        if (item.contentType !== "trueFalse" && !(itemData && itemData.value)) {
          isError = true;
          keyName = item.name;
        } else if (item.contentType === "trueFalse" && itemData && !isBoolean(itemData.value)) {
          isError = true;
          keyName = item.name;
        }
        if (
          itemData &&
          Array.isArray(itemData.value) &&
          itemData.value.some((value) => typeof value === "object" && !value.value)
        ) {
          isError = true;
          keyName = item.name;
        }
      }
    });
    return { isError, keyName };
  };

  const onFormSubmit = async () => {
    try {
      // convert input into key value pair
      const convertedData = Object.entries(value).map(([key, convertValue]) => ({ key, value: convertValue }));
      const formData = {
        productId: match.params.id,
        metaField: convertedData,
      };
      const { isError, keyName } = checkRequiredValues(convertedData);
      if (isError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: `${keyName} is Required.`,
        });
        setDisabledButton(true);
      }
      if (isValid && !isError) {
        const res = await addProductMetafield({
          variables: {
            input: formData,
          },
        });
        const resData = baseHelper.getResponseData(res.data, constant.gql.ADD_PRODUCT_METAFIELD);
        const resError = baseHelper.getResponseError(res.data, constant.gql.ADD_PRODUCT_METAFIELD);
        if (resError) {
          setBanner({
            status: constant.CRITICAL,
            isOpen: true,
            title: resError,
          });
          setDisabledButton(true);
        }
        if (resData) {
          setMessage(cms("message.success.metafield"));
          setTimeout(() => {
            history.push("/products");
          }, 1500);
        }
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
      setDisabledButton(true);
    }
  };

  if (catalogLoading || productLoading || advanceLoading) {
    return <SkeletonCard />;
  }

  const handleAdd = (contentType, isMesurement, type) => {
    const updateData = (value && { ...value }) || {};

    let isError = false;
    if (Array.isArray(updateData[contentType])) {
      isError =
        updateData[contentType].filter((item) => (isMesurement ? item?.value && item?.unit : item)).length !==
        updateData[contentType].length;
      if (!isError) {
        const mesurementUnitData = metafieldData.find((item) => item.contentType === "mesurement");
        let mesurementUnit = "";
        if (mesurementUnitData) {
          [mesurementUnit = ""] = (mesurementUnitData.value || "").split(",") || [];
        }
        const defaultUnit = {
          weight: "lb",
          volume: "ml",
          dimension: "mm",
          mesurement: mesurementUnit || "",
        };
        updateData[contentType].push(isMesurement ? { value: "", unit: defaultUnit[type] } : "");
      }
    } else {
      isError = !updateData[contentType];
      if (!isError) {
        updateData[contentType] = [updateData[contentType], ""];
      }
    }
    if (isError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.row") || "Please complete the row details.",
      });
      return;
    }
    setValue({ ...updateData });
  };

  const handleDelete = (index, content) => {
    const updateData = value[content] || [];
    updateData.splice(index, 1);
    setValue({ ...value, [content]: updateData });
    setDisabledButton(false);
  };

  const metaFields = ({ item, index, metafield, data }) => {
    const isObject = item && typeof item === "object";
    const { valueType = "", value = "" } = metafield;
    let selectOptions;
    const isSelect = valueType === "list";
    if (isSelect) {
      selectOptions = value.split(",");
    }
    return (
      <div className="tab-header-actions">
        {index ? <br /> : ""}
        <TextStyle>{!index ? `${metafield.name} ${metafield.isRequired ? "*" : ""}` : ""}</TextStyle>
        <br />
        <Stack alignment="center">
          <Stack.Item fill>
            {!isSelect && (
              <TextField
                autoFocus
                placeholder={handlePlaceHolder(metafield.contentType)}
                value={isObject ? item.value : item}
                // multipleOption.includes(metafield.contentType)
                //   ? (value[metafield.key] && value[metafield.key].value) || ""
                //   : value[metafield.key]
                // }
                onChange={(val) => {
                  // eslint-disable-next-line no-unused-expressions
                  // multipleOption.includes(metafield.contentType)
                  //   ? handleChange(
                  //       metafield.key,
                  //       handleValidateInput(metafield, { value: val, unit: selectedValue }),
                  //       metafield.contentType
                  //     )
                  //   :
                  handleChange(
                    metafield.key,
                    handleValidateInput(metafield, val, isObject ? item.value : item),
                    index,
                    isObject || multipleOption.includes(metafield.contentType),
                    metafield.contentType
                  );
                }}
                multiline={1}
                onFocus={(event) => {
                  if (metafield.contentType === "color") {
                    setActivateColor({ [index]: true });
                  }
                  const prevVal = event.target.value;
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = "";
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = prevVal;
                }}
                connectedRight={
                  metafield.contentType === "color" ? (
                    <input
                      type="color"
                      id="colorpicker"
                      value={item || "#0000ff"}
                      onChange={(e) => {
                        handleChange(metafield.key, e.target.value, index);
                      }}
                    />
                  ) : (
                    handleDropdown(metafield, index || 0, item)
                  )
                }
              />
            )}
            {isSelect && (
                <Select
                  options={selectOptions}
                  disabled={false}
                  placeholder="Text Values"
                  onChange={(val) => {
                    handleChange(
                      metafield.key,
                      handleValidateInput(metafield, val, isObject ? item.value : item),
                      index,
                      isObject || multipleOption.includes(metafield.contentType),
                      metafield.contentType
                    );
                  }}
                  value={isObject ? item.value : item}
                />
            )}
          </Stack.Item>
          {metafield.value === "list" && data && data.length > 1 && (
            <Button id="deleteButton" plain onClick={() => handleDelete(index, metafield.key)}>
              <i className="far fa-trash fa-lg" style={{ color: "red" }} />
            </Button>
          )}
        </Stack>
      </div>
    );
  };

  const removeLocalData = (key) => {
    const keyData = value?.[key] || [];
    const data = Array.isArray(keyData) ? keyData : [keyData];
    const regex = new RegExp('data:image/([a-zA-Z]*);base64,([^"]*)');
    const filterData = data.filter((item) => !regex.test(item));
    setValue({
      ...value,
      [key]: filterData,
    });
  };

  return (
    <>
      {/* {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )} */}
      <Card
        title={[
          cms("label.productMetafield"),
          updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
      >
        <Card.Section>
          <p>{cms("label.caption.metafield")}</p>
          <br />
          <FormLayout>
            {metafieldData &&
              metafieldData.length &&
              metafieldData.map((metafield) => (
                <>
                  <Stack wrap={false} spacing="extraTight" distribution="fill" alignment="center">
                    <Stack.Item fill>
                      {!selectValue[metafield.contentType] && (
                        <>
                          {Array.isArray(value[metafield.key])
                            ? value[metafield.key].map((item, index) =>
                                metaFields({ item, index, metafield, data: value[metafield.key] })
                              )
                            : metaFields({
                                item: value[metafield.key],
                                metafield,
                                data: value[metafield.key],
                              })}
                          {metafield.value === "list" && (
                            <>
                              <Button
                                plain
                                id="addLink"
                                onClick={() =>
                                  handleAdd(
                                    metafield.key,
                                    multipleOption.includes(metafield.contentType),
                                    metafield.contentType
                                  )
                                }
                              >
                                {`${cms("common.button.addMore")}`}
                              </Button>
                            </>
                          )}
                        </>
                      )}
                      {/* {[constant.DIMENSION, "volume", "rating"].includes(metafield.contentType) &&
                        handleDimension(metafield)} */}
                    </Stack.Item>
                    {/* <Stack.Item>
                      <br />
                      {metafield.value === "list" && (
                        <Button id="deleteButton" plain>
                          <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                        </Button>
                      )}
                    </Stack.Item> */}
                  </Stack>
                  {["date", "dateTime"].includes(metafield.contentType) && (
                    <DateMetaField
                      metafield={metafield}
                      data={value[metafield.contentType]}
                      inputValue={value}
                      setValue={setValue}
                      handleChange={handleChange}
                      setDisabledButton={setDisabledButton}
                      setBanner={setBanner}
                    />
                  )}
                  {metafield.contentType === "url" && (
                    <UrlMetaData
                      metafield={metafield}
                      onFormSubmit={onFormSubmit}
                      data={value[metafield.contentType]}
                      handleURLChange={handleChange}
                      inputValue={value}
                      setValue={setValue}
                      setBanner={setBanner}
                      setDisabledButton={setDisabledButton}
                    />
                  )}
                  {metafield.contentType === "file" && (
                    <>
                      {`${metafield.name} ${metafield.isRequired ? "*" : ""}`}
                      <Modal
                        activator={activator(metafield.key, value[metafield.key])}
                        open={active[metafield.key]}
                        onClose={() => [setActive({}), removeLocalData(metafield.key)]}
                        primaryAction={{
                          content: "Upload images",
                          onAction: () => imageUpload(metafield),
                          loading: imageLoading,
                          // disabled: !value[metafield.key],
                        }}
                        secondaryActions={[
                          {
                            content: "Cancel",
                            onAction: () => [setActive({}), removeLocalData(metafield.key)],
                            disabled: imageLoading,
                          },
                        ]}
                      >
                        <File
                          handleAddImage={(data) => handleAddImage({ data, metafield })}
                          removeImage={(index, val) => removeExistingImage(index, val, metafield.key)}
                          data={
                            (value[metafield.key] &&
                              (Array.isArray(value[metafield.key]) ? value[metafield.key] : [value[metafield.key]])) ||
                            []
                          }
                          allowMultiple={metafield.value === "list"}
                        />
                      </Modal>
                    </>
                  )}
                  {metafield.contentType === "money" && (
                    <MoneyMetaField
                      handleChange={handleChange}
                      inputValue={value}
                      setValue={value}
                      metafield={metafield}
                      moneyFormat={moneyFormat}
                    />
                  )}
                  {metafield.contentType === "trueFalse" && (
                    <>
                      <TextStyle>{`${metafield.name} ${metafield.isRequired ? "*" : ""}`}</TextStyle>
                      <Stack>
                        <Stack.Item>
                          <RadioButton
                            label="True"
                            checked={value[metafield.key] === true}
                            onChange={() => {
                              handleChange(metafield.key, true, "", "", metafield.contentType);
                            }}
                          />
                        </Stack.Item>
                        <Stack.Item>
                          <RadioButton
                            label="False"
                            checked={value[metafield.key] === false}
                            onChange={() => {
                              handleChange(metafield.key, false, "", "", metafield.contentType);
                            }}
                          />
                        </Stack.Item>
                      </Stack>
                    </>
                  )}
                  {metafield.contentType === "json" && (
                    <JsonMetaData
                      handleChange={handleChange}
                      inputValue={value}
                      setValue={value}
                      metafield={metafield}
                    />
                  )}
                  {/* {metafield.contentType === "color" && (
                    <>
                      <TextField
                        label="Color"
                        connectedLeft={
                          activeColor ? <input
                          type="color"
                          id="colorpicker"
                          value="#0000ff"
                          onChange={(e) => {
                            handleChange(metafield.key, e.target.value);
                          }}
                        /> : ""}
                        value={value[metafield.key]}
                        onFocus={() => setActivateColor(true)}
                      />
                      {/* {activeColor && (
                        <div style={{ width: "200px", height: "200px" }}>
                          <input
                            type="color"
                            id="colorpicker"
                            value="#0000ff"
                            onChange={(e) => [console.log(">>>>>>", e.target.value), setColor(e.target.value)]}
                          />
                        </div>
                      )} */}
                  {/* </> */}
                  {/* )} */}
                  {/* <AddMetafield
                    activeField={activeField}
                    handleChange={handleChange}
                    handleDimension={handleDimension}
                    handleDropdown={handleDropdown}
                    handleValidateInput={handleValidateInput}
                    metafield={metafield}
                    selectedValue={selectedValue}
                    setIsMultipleOption={setIsMultipleOption}
                    value={value}
                  /> */}
                  {/* {metafield.value === "list" && (
                    <>
                      {addColor && addColor.map(() => advanceField(metafield.contentType))}
                      {<AddMetafield metafield={metafield} />}
                    </>
                  )} */}
                </>
              ))}
          </FormLayout>
        </Card.Section>
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button
            onClick={() => {
              const tab = isVariant ? 2 : 4;
              handleTabChange(tab);
            }}
          >
            {cms("button.previous")}
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            primary
            loading={updateTagSettingLoading}
            disabled={disabledButton || !isValid}
            onClick={() => onFormSubmit()}
          >
            {islastTab
              ? cms("button.finish")
              : cms(`button.${islastTab ? constant.SUBMIT_SMALL : constant.SAVE_SMALL}`)}
          </Button>
        </Stack.Item>
      </Stack>
      <div className="toast">
        <Toast message={message} />
      </div>
    </>
  );
};

export default withErrorBoundary(EditMetafield);
