const productListCMS = (cms) => {
  return {
    tabs: [
      {
        id: "all-products",
        content: cms("label.all"),
        accessibilityLabel: "All products",
        panelID: "all-products-content",
        value: "all",
      },
      {
        id: "new-products",
        content: cms("label.new"),
        panelID: "new-products-content",
        value: "new",
      },
      {
        id: "review-products",
        content: cms("label.review"),
        panelID: "review-products-content",
        value: "pending",
      },
      {
        id: "reviewed-products",
        content: cms("label.reviewed"),
        panelID: "reviewed-products-content",
        value: "reviewed",
      },
      {
        id: "approved-products",
        content: cms("label.approve"),
        panelID: "approved-products-content",
        value: "approved",
      },
      {
        id: "rejected-products",
        content: cms("label.rejected"),
        panelID: "rejected-products-content",
        value: "rejected",
      },
      {
        id: "deleted-products",
        content: cms("label.deleted"),
        panelID: "deleted-products-content",
        value: "deleted",
      },
    ],
    filterStatus: [
      {
        label: cms("label.all"),
        value: "all",
      },
      {
        label: cms("label.new"),
        value: "new",
      },
      {
        label: cms("label.review"),
        value: "pending",
      },
      {
        label: cms("label.reviewed"),
        value: "reviewed",
      },
      {
        label: cms("label.approve"),
        value: "approved",
      },
      {
        label: cms("label.rejected"),
        value: "rejected",
      },
      {
        label: cms("label.deleted"),
        value: "deleted",
      },
      {
        label: cms("label.draft"),
        value: "draft",
      },
    ],
    sortOptions: [
      { label: cms("label.dateAsc"), value: "updatedAt_asc" },
      { label: cms("label.dateDesc"), value: "updatedAt_desc" },
      { label: cms("label.titleAsc"), value: "title_asc" },
      { label: cms("label.titleDesc"), value: "title_desc" },
    ],
    message: {
      defaultBanner: {
        title: cms("label.title", ""),
      },
    },
    resourceName: {
      singular: cms("label.product"),
      plural: cms("label.productPlural"),
    },
  };
};

export default productListCMS;
