import { gql } from "apollo-boost";

const GET_PRODUCT_TAGS = gql`
  query getProductTags($input: GetProductType) {
    getProductTags(input: $input) {
      data {
        tags
      }
      status
      error
    }
  }
`;

export default GET_PRODUCT_TAGS;
