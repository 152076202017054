import styled from "styled-components";

export const StyledDiv = styled.div`
  .Polaris-Button {
    width: 100%;
  }
  .Polaris-Button__Text {
    text-align: left;
    width: 100%;
  }
  .Polaris-ActionList {
    padding: 0 0;
  }
  .Polaris-Popover__Pane {
    width: 130%;
    max-width: 130%;
  }
`;

export const AddressStyled = styled.div`
  .Polaris-Stack__Item {
    word-wrap: break-word;
  }
`;
