import React, { useState, useContext } from "react";
import { Card, TextField, FormLayout, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Price = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { symbol } = constant;
  const { moneyFormat = symbol.DOLLAR } = currentUser;
  const { variantData = {}, handleChange = () => {} } = props;
  const {
    price = 0,
    comparePrice = 0,
    markUp = { price: 0, type: "flat" },
    sellerDiscount = { price: 0, type: "flat" },
  } = variantData;
  const [type, setType] = useState({
    markUp: { labelType: (markUp && markUp.type) || "flat" },
    sellerDiscount: { labelType: (sellerDiscount && sellerDiscount.type) || "flat" },
  });
  const options = [
    { label: cms("section.price.label.flat"), value: constant.FLAT },
    { label: cms("section.price.label.percentage"), value: constant.PERCENTAGE },
  ];
  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if ("type" in value) {
      setType((preState) => ({ ...preState, [field]: { labelType: value.type } }));
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= 100) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
  };
  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || "flat"}
      placeholder={cms("section.price.placeholder.selectType")}
      label={cms("section.price.label.type")}
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );
  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label={cms("section.price.label.price")}
            type="number"
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            prefix={moneyFormat}
            disabled
          />
          <TextField
            id="comparePrice"
            label={cms("section.price.label.compare")}
            type="number"
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            prefix={moneyFormat}
            disabled
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="markUp"
            label={cms("section.price.label.markup")}
            type="number"
            value={(markUp && markUp.price.toString()) || ""}
            placeholder={cms("section.price.placeholder.price")}
            max={(type.markUp.labelType === constant.PERCENTAGE && 100) || 9999}
            onChange={(value) => {
              handlePriceChange(constant.MARKUP, markUp, { price: Number(value) }, type.markUp.labelType);
            }}
            connectedRight={selectWeight(constant.MARKUP, markUp)}
            suffix={type.markUp.labelType === constant.PERCENTAGE && "%"}
            // prefix={type.markUp.labelType === constant.FLAT && "Rs."}
            prefix={moneyFormat || "Rs."}
          />
          <TextField
            id="discount"
            label={cms("section.price.label.discount")}
            type="number"
            value={(sellerDiscount && sellerDiscount.price.toString()) || ""}
            max={(type.sellerDiscount.labelType === constant.PERCENTAGE && 100) || 9999}
            placeholder={cms("section.price.placeholder.price")}
            onChange={(value) => {
              handlePriceChange(
                constant.SELLER_DISCOUNT,
                sellerDiscount,
                { price: Number(value) },
                type.sellerDiscount.labelType
              );
            }}
            connectedRight={selectWeight(constant.SELLER_DISCOUNT, sellerDiscount)}
            suffix={type.sellerDiscount.labelType === constant.PERCENTAGE && "%"}
            // prefix={type.sellerDiscount.labelType === constant.FLAT && "Rs."}
            prefix={moneyFormat || "Rs."}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Price;
