import React, { useContext, useState } from "react";
import {
  Banner,
  Card,
  Link,
  Collapsible,
  Caption,
  Layout,
  Stack,
  Heading,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";

import { withErrorBoundary } from "lib/hoc";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

// import helper
import { baseHelper, storageHelper } from "lib/helpers";

import { ModalPopup, PermissionBanner, SkeletonAnnotatedCard } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import standardList from "./standardConfig";

const OperatorAnalytics = () => {
  const [openSetup, setOpenSetup] = useState({});
  const { cms, currentUser, isShopifyCustomOperator, isLoading } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;

  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const borderSet = {
    border: "0",
  };

  const iconHeightFix = {
    lineHeight: "2.2em",
  };
  const hiddenCards = ["Billing", "General"];

  if (isLoading) {
    return <SkeletonAnnotatedCard />;
  }

  const handleClose = () => {
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  return (standardList(cms) || []).map((analyticList) => {
    const updateAnalyticList = analyticList.list;
    return (
      <>
        {!isShopifyCustomOperator && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.title")}
              status="critical"
            >
              {cms("common.popup.migrate.description")}
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutCustom")}`,
                      url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Card>
              <UpgradAppPermission />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <Layout.AnnotatedSection
          title="Analytics"
          description={[
            <div>
              <TextStyle variation="subdued">{analyticList.description}</TextStyle>
            </div>,
          ]}
        >
          {updateAnalyticList.map((actionList) => {
            const actionData = actionList.actions;
            return (
              !hiddenCards.includes(actionList.title) && (
                <Card>
                  <Card.Header
                    sectioned
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Stack>
                        <i className={actionList.icon} style={iconHeightFix} />
                        <Heading>
                          {actionList.title}
                          <TextStyle variation="subdued">
                            <Caption>{actionList.description}</Caption>
                          </TextStyle>
                        </Heading>
                      </Stack>
                    }
                    actions={[
                      {
                        content: openSetup[actionList.code] ? "Hide" : "Show",
                        onAction: () => handleSetupToggle(actionList.code),
                        disclosure: openSetup[actionList.code] ? "up" : "down",
                      },
                    ]}
                  />
                  <br />

                  <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                    <Card.Section subdued>
                      {actionData.map((step) => {
                        return (
                          <div style={borderSet}>
                            <Card.Section subdued>
                              <Stack>
                                <Stack.Item>
                                  <Link url={step.link}>{step.label}</Link>
                                  <TextStyle variation="">
                                    <Caption>{step.description}</Caption>
                                  </TextStyle>
                                </Stack.Item>
                              </Stack>
                            </Card.Section>
                          </div>
                        );
                      })}
                    </Card.Section>
                  </Collapsible>
                </Card>
              )
            );
          })}
        </Layout.AnnotatedSection>
      </>
    );
  });
};

export default withErrorBoundary(OperatorAnalytics);
