import React, { useContext, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";

import { useMutation } from "@apollo/react-hooks";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";

import { UPDATE_CHARGE_DETAILS } from "app/plans/apollo/mutations/updatePlan";

const OperatorUpdatePayment = () => {
  const { CRITICAL, gql } = constant;
  const [banner, setBanner] = useState({
    isOpen: false,
    action: null,
    title: "",
    status: "",
    onDismiss: null,
  });
  const { cms, currentUser, history } = useContext(PrivateContext);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const [updateChargeDetails, { loading: chargeDetailsLoading }] = useMutation(UPDATE_CHARGE_DETAILS);
  useEffect(() => {
    updateChargeDetails({
      variables: {
        input: {
          shop: currentUser.shop,
          chargeId: queryParams && queryParams.charge_id,
          plan: queryParams && queryParams.plan,
          shopifyAccessToken: currentUser.shopifyAccessToken || "",
        },
      },
    })
      .then((response) => {
        const responseError = baseHelper.getResponseError(response.data, gql.UPDATE_CHARGE_DETAILS);
        const responseData = baseHelper.getResponseData(response.data, gql.UPDATE_CHARGE_DETAILS);
        if (responseError) {
          let resError = responseError;
          if (!responseError === cms("message.error.unprocessable")) {
            resError = cms("message.error.charge");
          }
          setBanner({
            status: CRITICAL,
            isOpen: true,
            title: resError,
            action: {
              content: cms("message.label.back"),
              onAction: () => history.push("/"),
            },
          });
        } else if (responseData) {
          history.push("/update/plan?plan=true");
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (chargeDetailsLoading) {
    return (
      <div>
        <Spinner />
        {cms("message.success.plan")}
      </div>
    );
  }
  return (
    <div>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            message={banner.message}
            action={banner.action}
            onDismiss={() => {
              setBanner({ isOpen: false, title: "", status: "" });
            }}
          />
        </Layout.Section>
      )}
    </div>
  );
};

export default withErrorBoundary(OperatorUpdatePayment);
