import React, { useContext } from "react";
import { Caption, ResourceList, Stack, TextStyle } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import component
import Actions from "./action";

// import propType
import { ruleItemProp } from "./props";

const RuleItem = (props) => {
  const { isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser = {}, cms } = useContext(PrivateContext);
  const { moneyFormat, item = {}, band = [], destinations = [], products = [], editModal, deleteModal } = props || {};
  const {
    id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    isShippingDisabled,
  } = item;
  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;

  if (item.condition === null) return null;
  const shippingBand =
    (band.length > 0 && band.find((key) => key.value === shippingBandId || key.parentId === shippingBandId)) || false;

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);
  const isFreeOption = priceType === constant.FREE;
  const priceTypeText = isFreeOption ? cms("label.free") : cms("label.fixed");

  const { _id: currentUserId = null } = currentUser || {};
  const { userId: ruleCreatedBy = null } = item || {};
  const shortcutActions = [
    {
      content: cms("button.edit"),
      onAction: () => editModal(item),
      disabled: !isVendorAllowed,
    },
    {
      content: cms("button.delete"),
      destructive: true,
      onAction: () => deleteModal(item),
      disabled: !isVendorAllowed || ruleCreatedBy !== currentUserId,
    },
  ];

  return (
    <ResourceList.Item id={id} accessibilityLabel={`${cms("label.viewDetails")} `}>
      <div className="custom-wrapper">
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle variation="strong">{(shippingBand && shippingBand.label) || ""}</TextStyle>

            <Caption>{description}</Caption>

            <br />
            {isDestination && (
              <Caption>{`${cms("label.destination")}: ${(destination && destination.label) || ""}`}</Caption>
            )}
            {isProduct && product && (
              <Caption>{`${cms("label.product")}: ${(product && product.label) || ""}`}</Caption>
            )}
            <Caption>{`${cms("label.priceType")}: ${priceTypeText}`}</Caption>
            <Caption>{`${cms("label.cost")}: ${moneyFormat} ${isFreeOption ? "0" : price}`}</Caption>
            <Caption>
              {`${cms("label.shipping")}: ${isShippingDisabled ? cms("label.disabled") : cms("label.enabled")}`}
            </Caption>
          </Stack.Item>

          <Actions actions={shortcutActions} />
        </Stack>
      </div>
    </ResourceList.Item>
  );
};
RuleItem.propTypes = ruleItemProp.type;
export default RuleItem;
