// import packages
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Caption, Layout, PageActions, Stack, TextStyle } from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import cms and constants
import constant from "lib/constant/constant";

// import components
import { Banner, Toast } from "lib/components";

// import provider
import { FormProvider } from "app/productOld/modules/operator/features/add/context/context";

// import gql
import { UPDATE_PRODUCT_ATTACHMENT_SETTING } from "app/productOld/apollo/mutations";

import Bucket from "./subFeatures/bucket/bucket";

const OperatorProductAttachmentSetting = () => {
  const [updateProductAttachmentSetting, { loading: productAttachmentSettingLoading }] = useMutation(
    UPDATE_PRODUCT_ATTACHMENT_SETTING
  );
  const { history, currentUser, cms, currentUserRefetch } = useContext(PrivateContext);
  const { aws, updatedAt } = currentUser;
  const [buttonValue, setButtonValue] = useState(true);
  const [firstPush, setFirstPush] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(false);
  const [message, setMessage] = useState("");

  const [value, setValue] = useState({});
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const handleAction = () => {
    setIsEnable((enable) => !enable);
    // eslint-disable-next-line no-unused-expressions
    isEnable ? setMessage(cms("message.success.disable")) : setMessage("");
  };
  const handleChange = (fieldName, fieldValue) => {
    setButtonValue(false);
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const saveProductAttachmentInfo = async (formValues) => {
    setButtonValue(true);
    
    updateProductAttachmentSetting({
      variables: {
        input: formValues,
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_ATTACHMENT_SETTING);
        const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_ATTACHMENT_SETTING);
        if(responseData){
          setMessage(cms("message.success.attachmentSetting"));
          setBannerStatus({
            status: constant.SUCCESS,
            title: cms("message.success.attachmentSetting"),
            isOpen: true,
          });
        }
        if (errorResponse) {
          setBannerStatus({
            status: constant.CRITICAL,
            title: errorResponse,
            isOpen: true,
          });
          return;
        }
        setTimeout(() => { currentUserRefetch() }, 5000);
      })
      .catch((exception) => {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  const submitCallBack = () => {
    const { bucket, id, secret } = value;

    const formValues = {
      bucket,
      path: "/",
      id,
      secret,
    };
    saveProductAttachmentInfo(formValues);
  };

  const onSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!value.bucket || value.bucket.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.bucketNameIsRequired"),
      };
      setBannerStatus(banner);
      return;
    }
    if (!value.id || value.id.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.bucketAccessIsRequired"),
      };
      setBannerStatus(banner);
      return;
    }
    if (!value.secret || value.secret.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.bucketSecretIsRequired"),
      };
      setBannerStatus(banner);
      return;
    }
    // setMessage(cms("message.success.attachmentSetting"));
    submitCallBack();
  };

  useEffect(() => {
    setValue(aws);
    if (aws.secret) {
      setIsEnable(true);
    }
  }, [aws]);

  useEffect(() => {
    setLastUpdated(updatedAt);
    if (updatedAt) {
      setFirstPush(false);
    }
  }, [updatedAt]);

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title={cms("title")} description={cms("label.description")}>
          <Card>
            <Card.Header
              sectioned
              title={[
                cms("title"),
                aws.bucket && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>
                  </TextStyle>
                ),
              ]}
            >
              {/* {!isEnable ? (          // commenting code due to inprogress service work.
                <Button primary onClick={handleAction}>
                  {cms("label.enable")}
                </Button>
              ) : (
                <Button destructive onClick={handleAction}>
                  {cms("label.disable")}
                </Button>
              )} */}
              <Stack>
                <TextStyle>{cms("description")}</TextStyle>
              </Stack>
            </Card.Header>
            {/* {isEnable && ( */}
            <FormProvider value={{ handleChange, data: value, aws }}>
              <Bucket
                productAttachmentSettingLoading={productAttachmentSettingLoading}
                buttonValue={buttonValue}
                onSubmit={onSubmit}
                firstPush={!firstPush}
                lastUpdated={lastUpdated}
                aws={aws}
              />
            </FormProvider>
            {/* )} */}
          </Card>
          {aws && !aws.bucket && (
            <PageActions
              primaryAction={{
                id: "submitProductAttachmentSetting",
                content: cms("common.button.submit"),
                disabled: productAttachmentSettingLoading || buttonValue,
                loading: productAttachmentSettingLoading,
                onAction: () => onSubmit(),
              }}
              secondaryActions={[
                {
                  id: "cancelProductAttachmentSetting",
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
          <Toast message={message} setToast={setMessage} />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductAttachmentSetting), {
  feature: constant.PRODUCT_ATTACHMENT,
});
