import { gql } from "apollo-boost";

const FETCH_VENDOR = gql`
  query getUser($input: GetUser) {
    getUser(input: $input) {
      data {
        brandName
        firstName
        lastName
        address
        city
        country
        pinCode
        phoneNumber
        email {
          address
          verified
        }
      }
      status
      error
    }
  }
`;
export default FETCH_VENDOR;
