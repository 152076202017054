// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-apollo";
import { Card, Layout, Collapsible } from "@shopify/polaris";

// import helper
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { FETCH_EMAIL_TEMPLATE_LIST } from "app/emailTemplate/apollo/queries";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import components
import { Banner } from "lib/components";
import { errorHelper } from "lib/helpers";

const AdminEmailTemplate = () => {
  const { history, cms = {} } = useContext(PrivateContext);
  const { gql } = constant;
  const [listData, setListData] = useState({});
  const [listCount, setListCount] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [toggleButton, setToggleButton] = useState({});

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  useEffect(() => {
    const queryParams = baseHelper.getQueryParams(history.location.search) || {};
    if (queryParams && queryParams.isNotFound && !banner.isOpen) {
      setBanner({ isOpen: true, title: errorHelper.parse(), status: constant.CRITICAL });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cms, banner.isOpen]);

  const { data: emailTemplateData, loading: emailTemplateLoading, error } = useQuery(FETCH_EMAIL_TEMPLATE_LIST);

  useEffect(() => {
    // if (!emailTemplateLoading) {
    //   setLoading(false);
    // }

    if (error) {
      // set banner with error message
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: constant.CRITICAL });
    }
  }, [emailTemplateLoading, error, cms]);

  const emailTemplateError = baseHelper.getResponseError(emailTemplateData, gql.GET_EMAIL_TEMPLATE);
  const emailTemplateResponse = baseHelper.getResponseData(emailTemplateData, gql.GET_EMAIL_TEMPLATE);
  const listingEmailTemplates = async (response) => {
    if (response && response.length) {
      await Promise.all(
        response.map((element) => {
          const key = element.category || "Notification";
          if (!listData[key]) {
            listData[key] = [];
            setToggleButton({
              ...toggleButton,
              [key]: false,
            });
          }
          listData[key].push(element);
          return null;
        })
      );
      setListData(listData);
      const totalCount = Object.entries(listData).length;
      setListCount(totalCount);
    } else {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.templatesNotFound") });
    }
  };

  useEffect(() => {
    if (emailTemplateError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: emailTemplateError });
    }

    if (emailTemplateResponse) {
      listingEmailTemplates(emailTemplateResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateError, emailTemplateResponse]);

  const toggleTemplate = (type) => {
    setToggleButton({
      ...toggleButton,
      [type]: !toggleButton[type],
    });
  };

  // function render category specific item
  const renderItem = (type, items) => {
    const listItem = items.map((item) => {
      return (
        <Collapsible open={toggleButton[type]} id={`basic-collapsible-${type}`}>
          <Card title={item.label} key={item.label} sectioned />
        </Collapsible>
      );
    });
    return listItem;
  };

  // function to list email templates
  const renderEmailTemplates = () => {
    if (listCount) {
      const list = Object.keys(listData).map((item) => (
        <Card
          title={item}
          actions={[
            {
              content: toggleButton[item] ? constant.HIDE : constant.SHOW,
              disclosure: toggleButton[item] ? constant.UP : constant.DOWN,
              onAction: () => toggleTemplate(item),
            },
          ]}
          key={item}
        >
          <br />
          {renderItem(item, listData[item])}
        </Card>
      ));
      return list;
    }
    return null;
  };

  const closeBanner = () => {
    const currentUrl = history.location.pathname;
    setBanner({ isOpen: false });
    history.push(currentUrl);
  };

  return [
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} title={banner.title} status={banner.status} onDismiss={() => closeBanner()} />
        </Layout.Section>
      )}
      {(listCount && (
        <Layout.AnnotatedSection title={cms("section.title")} description={cms("section.description")}>
          {renderEmailTemplates()}
        </Layout.AnnotatedSection>
      )) ||
        ""}
    </>,
  ];
};

export default withErrorBoundary(AdminEmailTemplate);
