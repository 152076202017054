import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Heading, Card, Caption, Link, Stack, Badge, TextStyle, Layout, Tabs } from "@shopify/polaris";

// import helper and constant
import baseHelper from "lib/helpers/base";
import { Banner, ResourceList } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

// import gql
import { CUSTOM_DOMAIN_LIST } from "app/userManagement/apollo/queries";
import { TOGGLE_DOMAIN } from "app/userManagement/apollo/mutation";
import { CUSTOM_DOMAIN_SUBSCRIPTION } from "app/userManagement/apollo/subscriptions";

import { errorHelper } from "lib/helpers";

// cms
import cmsCustomDomain from "./cms/customDomain";

import Actions from "./action/action";

const AdminCustomDomain = () => {
  const { history, cms = {} } = useContext(PrivateContext);
  const cmsData = cmsCustomDomain(cms);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const { search, filter, sort_name: sortName = "createdAt", sort_order: sortOrder = "desc" } = queryParams;
  const [listData, setListData] = useState([]);
  const [count, setCount] = useState();
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  const [updatedPreviousData, setUpdatedPreviousData] = useState([]);
  const [updatedNewValue, setUpdatedNewValue] = useState({});
  const [queryValue, setQueryValue] = useState(search || "");
  const sortSelected = sortName && `${sortName}_${sortOrder}`;
  const [sortValue, setSortValue] = useState(sortSelected);
  const [page, setPage] = useState(parseInt(queryParams.page, 10) || 1);
  const [perPage, setPerPage] = useState(queryParams.perPage || 20);
  const [buttonLoading, setButtonLoading] = useState({});
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { label, tabs, resourceName, sortOptions } = cmsData;
  // useQuery, UseMutation Calls to fetch data.
  const { loading: resourceListLoading, error, data: queryData, subscribeToMore, refetch } = useQuery(
    CUSTOM_DOMAIN_LIST,
    {
      variables: {
        input: {
          search,
          filter,
          sort_name: sortName,
          sort_order: sortOrder,
          page: parseInt(page, 10),
          perPage: parseInt(perPage, 10),
        },
      },
    }
  );
  const [toggleCustomDomain] = useMutation(TOGGLE_DOMAIN);
  useEffect(() => {
    const paramValue = baseHelper.getQueryParams(history.location.search);
    const listFilter = (paramValue.filter && paramValue.filter.split(",")) || [];
    const listSearch = (paramValue.search && paramValue.search.split(",")) || [];
    const appliedFilters = [];
    listFilter.forEach((item, index) => {
      appliedFilters.push({
        key: item,
        value: listSearch[index],
      });
    });
    setSelected(parseInt(paramValue.tab, 10) || 0);
    setPage(parseInt(paramValue.page, 10));
  }, [history.location.search]);

  // Subscription for Custom Domain.
  useEffect(() => {
    subscribeToMore({
      document: CUSTOM_DOMAIN_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        refetch();
        const customDomainValue = subscriptionData.data.customDomainView.data.customDomain;
        const prevCustomDomainList = prev.getCustomDomainList.data.customDomainList;
        return [setUpdatedNewValue(customDomainValue), setUpdatedPreviousData([...prevCustomDomainList])];
      },
    });
  }, [subscribeToMore, refetch]);
  const showCustomDomainView = () => {
    const { _id: customId, isDomainActive } = updatedNewValue;
    const showCustomData = [];
    updatedPreviousData.forEach((item, index) => {
      const { _id: id } = item;
      if (id === customId) {
        updatedPreviousData[index].isDomainActive = isDomainActive;
      }
      if (selected === 1 || selected === 2) {
        if (id === customId) {
          return;
        }
        showCustomData.push(item);
      }
    });
    let domainListData = [...updatedPreviousData];
    if (selected === 1 || selected === 2) {
      domainListData = [];
      domainListData = [...showCustomData];
    }
    setListData(domainListData);
    setCount(domainListData.count);
    setUpdatedNewValue({});
    setUpdatedPreviousData([]);
  };

  if (updatedPreviousData.length && Object.keys(updatedNewValue).length) {
    showCustomDomainView();
  }

  useEffect(() => {
    if (resourceListLoading) {
      return;
    }
    const responseData = baseHelper.getResponseData(queryData, constant.gql.GET_CUSTOM_DOMAIN_LIST);
    const responseError = baseHelper.getResponseError(queryData, constant.gql.GET_CUSTOM_DOMAIN_LIST);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { count: customDomainCount, customDomainList } = responseData;
    setCount(customDomainCount);
    setListData([...customDomainList]);
  }, [queryData, resourceListLoading]);

  useEffect(() => {
    if (error) {
      const bannerContent = {
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error),
      };
      setBanner(bannerContent);
    }
  }, [error, queryData]);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(parseInt(selectedTabIndex, 10));
    setPage(1);
    if (tabs && tabs[selectedTabIndex] && tabs[selectedTabIndex].value) {
      baseHelper.setUrl(history, {
        tab: selectedTabIndex,
        filter: tabs[selectedTabIndex].value,
        page: 1,
      });
    }
  };

  const handleSort = (select) => {
    const sort = select.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(select);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const handleSearchValue = (value) => {
    const searchValue = baseHelper.setQueryParams(history.location, { search: value });
    setQueryValue(value);
    history.push(`${history.location.pathname}?${searchValue}`);
  };
  const handleQueryValueRemove = () => {
    const searchValue = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${searchValue}`);
  };

  const toggleDomain = async (sellerId, isDomainActive) => {
    setButtonLoading({ [sellerId]: true });
    try {
      const response = await toggleCustomDomain({
        variables: {
          input: {
            sellerId,
          },
        },
      });
      setButtonLoading({ [sellerId]: false });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.TOGGLE_DOMAIN);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: !isDomainActive ? label.successfullyEnabledCustomDomain : label.successfullyDisabledCustomDomain,
      });
    } catch (exception) {
      setButtonLoading({ [sellerId]: false });
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const renderItem = (item) => {
    const { email, domain, isDomainActive, createdAt, _id: id } = item;
    let statusOfUser = label.approved;
    if (!email.verified) {
      statusOfUser = label.pending;
    }
    const sellerId = baseHelper.mongoIdAsString(id);
    const actionLoading = buttonLoading[sellerId];
    const shortcutActions = [
      {
        content: isDomainActive ? label.disable : label.enable,
        onAction: () => toggleDomain(sellerId, isDomainActive),
        loading: actionLoading,
        destructive: isDomainActive,
      },
    ];

    const badgeStatus = baseHelper.ucFirst(statusOfUser);
    const badgeType = baseHelper.getBadgeType(statusOfUser);

    return (
      <Card sectioned>
        <Stack alignment="leading">
          <Stack.Item fill>
            <Heading>
              <Stack wrap={false}>
                <Stack.Item>
                  <Link onClick={() => history.push(`/operators/view/${sellerId}`)}>{baseHelper.ucFirst(item.shop)}</Link>
                </Stack.Item>
              </Stack>
            </Heading>
            <Caption>{`${label.email} ${email.address}`}</Caption>
            <Caption>{`${label.domain} ${domain}`}</Caption>
            <Caption>
              <TextStyle variation="subdued">{`${label.onBoardedOn} ${baseHelper.formatDate(createdAt)}`}</TextStyle>
            </Caption>
          </Stack.Item>
          <div
            style={{
              paddingRight: "25px",
            }}
          >
            <Stack.Item>
              <Badge status={badgeType}>
                <span className="text-capitalize">{badgeStatus}</span>
              </Badge>
            </Stack.Item>
          </div>
          <Actions actions={shortcutActions} />
        </Stack>
      </Card>
    );
  };
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner(() => setBanner({ isOpen: false, title: "", status: "" }))}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <div className="domainList">
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
            <ResourceList
              resourceName={resourceName}
              items={listData && listData.length > 0 ? listData : []}
              renderItem={renderItem}
              loading={resourceListLoading}
              showHeader
              queryValue={queryValue}
              count={count}
              totalItemsCount={count}
              onQueryChange={handleSearchValue}
              onQueryClear={handleQueryValueRemove}
              sortValue={sortValue}
              sortOptions={sortOptions}
              onSortChange={handleSort}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </Card>
        </div>
      </Layout.Section>
    </>
  );
};

export default withErrorBoundary(AdminCustomDomain);
