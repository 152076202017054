import React, { useState, useContext } from "react";
import { Heading, RadioButton, Stack, TextContainer } from "@shopify/polaris";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import ThirdPartyOptions from "./subFeatures/thirdPartyOptions";

const ConsignmentShipMethod = () => {
  const { cms, consignmentShip, setConsignmentShip, setSubmitEnable, value } = useContext(FulfillmentContext);

  const [selected, setSelected] = useState((consignmentShip && consignmentShip.shippingOption) || "");

  const handleConsignmentShip = (fieldName, val) => {
    setConsignmentShip((prevState) => ({
      ...prevState,
      shippingOption: "",
      [fieldName]: val,
    }));
    setSelected("");
    setSubmitEnable(false);
    if (!val) {
      setSubmitEnable(true);
    }
  };

  return (
    <div>
      <TextContainer>
        <Stack>
          <Stack.Item fill>
            <Heading>{cms("consignmentShip.method")}</Heading>
          </Stack.Item>
        </Stack>
      </TextContainer>
      <br />
      <RadioButton
        label={cms("label.manageShipping")}
        helpText={cms("label.manageText")}
        checked={!(consignmentShip && consignmentShip.isThirdPartyShipping)}
        onChange={() =>
          handleConsignmentShip("isThirdPartyShipping", !(consignmentShip && consignmentShip.isThirdPartyShipping))}
      />

      <RadioButton
        label={cms("label.thirdPartyText")}
        helpText={cms("label.thirdPartyText")}
        checked={consignmentShip && consignmentShip.isThirdPartyShipping}
        onChange={() =>
          handleConsignmentShip("isThirdPartyShipping", !(consignmentShip && consignmentShip.isThirdPartyShipping))}
      />

      {consignmentShip &&
        consignmentShip.isThirdPartyShipping &&
        (value === constant.CONSIGNMENT || value === constant.HYBRID) && (
          <div>
            <br />
            <Heading>{cms("dropShip.heading")}</Heading>
            <br />
            <ThirdPartyOptions
              consignmentShip={consignmentShip}
              selected={selected}
              setConsignmentShip={setConsignmentShip}
              setSelected={setSelected}
            />
          </div>
        )}
    </div>
  );
};
export default withFeature(withErrorBoundary(ConsignmentShipMethod), { feature: constant.CONSIGNMENT_SHIP });
