import yup from "lib/helpers/yup";
import { schema } from "./schema";

const validate = async (value, cms) => {
  let promise = "";
  promise = yup.validateValue(schema(cms), { radioGroup: value });
  return yup.handlePromiseError(promise);
};

export default validate;
