import React from "react";
// import generic
import { InvoiceDownload } from "app/payments/modules/generic";

// import hoc
import { withErrorBoundary } from "lib/hoc";

const AdminInvoice = () => {
  return <InvoiceDownload />;
};

export default withErrorBoundary(AdminInvoice);
