import React from "react";

import PrivateRouter from "layout/private/components/privateRouter/privateRouter";
import { Route } from "react-router-dom";

const routes = [{ path: "/", component: PrivateRouter, exact: false }];

const privateRoutes = routes.map((route) => (
  <Route exact={route.exact} path={route.path} component={route.component} key={route.path} />
));

export default privateRoutes;
