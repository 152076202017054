import React, { useState, useContext } from "react";
import { Modal, DataTable, Layout, Checkbox } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";

import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { DELETE_VENDOR_ASSOCIATION } from "app/vendors/apollo/mutations";
import cmsVendorList from "app/vendors/modules/admin/features/list/cms/vendorListCMS";
import constant from "lib/constant/constant";

const RemoveAssocProvider = (props) => {
  const { setRemoveAssocModal, removeAssocModal, assocSeller, assocVendor, setBanner } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteFromShopify, setDeleteFromShopify] = useState(false);
  const vendorListCMS = cmsVendorList(cms);
  const { message, label, button } = vendorListCMS;

  //   remove associate seller
  const [removeAssocSeller, { loading }] = useMutation(DELETE_VENDOR_ASSOCIATION);

  const onBulkSubmit = () => {
    const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);

    if (!isSystemAdmin) {
      return false;
    }
    const sellerIds = [...selectedRows];

    return sellerIds.forEach((id) => {
      const dataToRemove = {
        seller: id,
        id: assocVendor,
        deleteFromShopify,
      };
      removeAssocSeller({ variables: { input: dataToRemove } })
        .then((res) => {
          setSelectedRows([]);
          setDeleteFromShopify(false);
          const removeResData = baseHelper.getResponseData(res.data, constant.gql.DELETE_VENDOR_ASSOCIATION);
          const removeResDataError = baseHelper.getResponseError(res.data, constant.gql.DELETE_VENDOR_ASSOCIATION);
          if (removeResData) {
            setRemoveAssocModal(false);
            setBanner({ isOpen: true, title: message.assocRemoveSuccess, status: constant.SUCCESS });
          }
          if (removeResDataError) {
            setRemoveAssocModal(false);
            setBanner({ isOpen: true, title: removeResDataError, status: constant.CRITICAL });
          }
        })
        .catch((exception) => {
          setRemoveAssocModal(false);
          setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
        });
    });
  };
  const onRowSelect = (id) => {
    if (selectedRows.includes(id)) {
      const index = selectedRows.indexOf(id);
      if (index > -1) {
        const selected = selectedRows;
        selected.splice(index, 1);
        setSelectedRows((prevState) => [...prevState, ...selected]);
      }
    } else {
      setSelectedRows((prevState) => [...prevState, id]);
    }
  };

  const rows = [];
  if (assocSeller) {
    const { _id: id, shop } = assocSeller;
    rows.push([
      <Checkbox checked={selectedRows.includes(id)} labelHidden onChange={() => onRowSelect(id)} />,
      baseHelper.ucFirst(shop),
    ]);
  }

  return (
    <>
      <Modal
        open={removeAssocModal}
        onClose={() => {
          setSelectedRows([]);
          setDeleteFromShopify(false);
          setRemoveAssocModal(false);
        }}
        title={label.listOfAssocSellers}
        primaryAction={{
          content: button.removeSeller,
          onAction: () => onBulkSubmit(),
          loading,
          disabled: loading,
        }}
      >
        <DataTable columnContentTypes={["text", "text"]} headings={["#", label.store]} rows={rows} />
        <Layout.Section>
          <Checkbox
            checked={deleteFromShopify}
            label={label.deleteProductsAssociated}
            onChange={() => setDeleteFromShopify(!deleteFromShopify)}
          />
        </Layout.Section>
      </Modal>
    </>
  );
};

RemoveAssocProvider.defaultProps = {
  assocSeller: [],
  assocVendor: false,
  removeAssocModal: false,
  setRemoveAssocModal: () => {},
  setBanner: () => {},
};

RemoveAssocProvider.propTypes = {
  assocSeller: PropTypes.arrayOf(PropTypes.string),
  removeAssocModal: PropTypes.bool,
  assocVendor: PropTypes.bool,
  setRemoveAssocModal: PropTypes.func,
  setBanner: PropTypes.func,
};

export default RemoveAssocProvider;
