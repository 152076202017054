import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import {
  Banner,
  Button,
  Card,
  DatePicker,
  Icon,
  Layout,
  PageActions,
  Popover,
  Stack,
  Tabs,
  TextField,
} from "@shopify/polaris";
import { CalendarMinor, DeleteMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { SingleColumnLayout } from "layout/private/components";
import { Spinner } from "lib/components";
import { baseHelper, storageHelper } from "lib/helpers";
import { AnalyticsTabsHeader } from "app/analytics/modules/generic/style";
import Header from "app/analytics/modules/provider/header";
import { AnalyticThemeProvider } from "asset/styles/themeStyle";

// import gql
import { useQuery } from "@apollo/react-hooks";
import {
  GET_ORDER_REPORT_LIST,
  GET_PRODUCT_REPORT_LIST,
  GET_KEY_TAKEAWAY_REPORT_LIST,
} from "app/analytics/apollo/queries";
import { GET_ASSOCIATED_SELLER } from "app/setup/apollo";
import constant from "lib/constant/constant";
import OrderAnalyticsChart from "./orderAnalyticsChart";
import ProductAnalyticsChart from "./productAnalyticsChart";

const Reports = () => {
  const { cms, currentUser, isLoading, history } = useContext(PrivateContext);
  const { gql } = constant;
  const { DEFAULT_CONTENT_COLOR, DEFAULT_HEADING_COLOR } = constant;

  const [countryList, setCountryList] = useState([[], []]);
  const [endingDate, setEndingDate] = useState(null);

  const [isDateSelected, setIsDateSelected] = useState(false);
  const [noDateSelected, setisDateSelect] = useState(true);
  const [orderReport, setOrderReport] = useState({});
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState(0);
  const [startingDate, setStartingDate] = useState(null);
  const [value, setValues] = useState({ dateType: "today" });
  // const componentRef = useRef();
  const [header, setHeader] = useState(false);
  const [logo, setLogo] = useState(false);

  const [isKeyTakeAway, setIsKeyTakeAway] = useState(false);
  const componentRef = useRef();

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [topPerforming, setTopPerforming] = useState([]);
  const [bottomPerforming, setBottomPerforming] = useState([]);
  const [stockUtilisation, setStockUtilisation] = useState(0);
  const [sizeData, setSizeData] = useState([[], []]);
  const [colorData, setColorData] = useState([[], []]);
  const [typeData, setTypeData] = useState([[], []]);
  // const componentRef = useRef();

  let contentDataColor = "";
  let headingDataColor = "";

  if (currentUser && currentUser.style) {
    contentDataColor = currentUser.style.contentColor;
    headingDataColor = currentUser.style.headingColor;
  }

  const { data: sellerData } = useQuery(GET_ASSOCIATED_SELLER);
  const sellerView = baseHelper.getResponseData(sellerData, gql.GET_ASSOCIATED_SELLER) || {};

  const { loading: productReportListLoading, data: productReportListData, error: productReportListError } = useQuery(
    GET_PRODUCT_REPORT_LIST
  );
  const {
    loading: keyTakeAwaysReportListLoading,
    data: keyTakeAwaysReportListData,
    error: keyTakeAwaysReportListError,
  } = useQuery(GET_KEY_TAKEAWAY_REPORT_LIST);

  const onBeforeGetContentResolve = useRef(null);

  const [headerLoading, setLoading] = useState(false);

  const handleAfterPrint = useCallback(() => {
    setHeader(false);
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      const { font = "" } = currentUser;
      onBeforeGetContentResolve.current = resolve;
      componentRef.current.style.fontFamily = font;

      setTimeout(() => {
        setLoading(false);
        setHeader(true);
        resolve();
      }, 1000);
    });
  }, [setLoading, setHeader, currentUser]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "analytics-chart",
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle: `
     svg {
      fill: ${contentDataColor || DEFAULT_CONTENT_COLOR}
     }
     p,
     div,
     span {
      color: ${contentDataColor || DEFAULT_CONTENT_COLOR}
     }
     h2,
     h1,
     h3,
     h4 {
      color: ${headingDataColor || DEFAULT_HEADING_COLOR}
     }
   `,
  });

  useEffect(() => {
    if (logo && header && typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo, onBeforeGetContentResolve.current]);

  useEffect(() => {
    if (selected === 0) {
      setValues({ dateType: "today" });
      setSelectedDates({
        start: new Date(),
        end: new Date(),
      });
    } else if (selected === 1) {
      setValues({ dateType: "yesterday" });
      setSelectedDates({
        start: new Date(),
        end: new Date(),
      });
    } else if (selected === 2) {
      setValues({ dateType: "previous" });
      setSelectedDates({
        start: new Date(),
        end: new Date(),
      });
    }
  }, [selected]);

  useEffect(() => {
    if (isDateSelected) {
      setSelectedDates(selectedDates);
      setIsDateSelected(false);
    }
  }, [selectedDates, isDateSelected]);

  const { data: orderReportListResponse, loading: orderReportListLoading, error: orderReportError } = useQuery(
    GET_ORDER_REPORT_LIST,
    {
      variables: {
        input: {
          ...value,
        },
      },
    }
  );

  useEffect(() => {
    const orderReportListData = baseHelper.getResponseData(orderReportListResponse, constant.gql.GET_ORDER_REPORT_LIST);

    const orderReportListError = baseHelper.getResponseError(orderReportError, constant.gql.GET_ORDER_REPORT_LIST);

    if (orderReportListData) {
      const { countryList: countryListData = [], orderReportList = [] } = orderReportListData || {};
      const countryNames = [];
      const countryCount = [];
      (countryListData || []).forEach((item) => {
        countryNames.push(item._id);
        countryCount.push(item.count);
      });
      setCountryList([countryNames, countryCount]);

      if (orderReportList) {
        const [orderData] = orderReportList || [];
        setOrderReport(orderData || {});
      }
      if (orderReportListError) {
        setBanner({ status: constant.CRITICAL, title: orderReportListError, isOpen: true });
      }
    }
  }, [orderReportError, orderReportListResponse]);

  // eslint-disable-next-line no-shadow
  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
  }, []);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setisDateSelect(true);
    setSelectedDates({
      start: new Date(),
      end: new Date(),
    });
  }, []);

  const tabs = [
    {
      id: "all-customers-4",
      content: "Today",
      accessibilityLabel: "All customers",
      panelID: "all-customers-content-4",
    },
    {
      id: "accepts-marketing-4",
      content: "Yesterday",
      panelID: "accepts-marketing-content-4",
    },
    {
      id: "repeat-customers-4",
      content: "Last Month",
      panelID: "repeat-customers-content-4",
    },
  ];

  const onSubmit = async (isDeleted) => {
    if (isDeleted) {
      setValues({ dateType: "today" });
      setSelectedDates({
        start: new Date(),
        end: new Date(),
      });
      setSelected(0);
      setisDateSelect(true);
    }
  };

  const togglePopoverActive = useCallback(() => setPopoverActive((popover) => !popover), []);

  const activator = (
    <Stack alignment="center">
      <Stack.Item>
        <Button icon={CalendarMinor} onClick={togglePopoverActive}>
          {noDateSelected
            ? "Select Date"
            : `${moment(selectedDates.start).format("ll")} - ${moment(selectedDates.end).format("ll")}`}
        </Button>
      </Stack.Item>
      {noDateSelected === false ? (
        <Stack.Item>
          <Button id="deleteButton" plain destructive onClick={() => onSubmit(true)}>
            <Icon source={DeleteMajor} color="critical" />
          </Button>
        </Stack.Item>
      ) : null}
    </Stack>
  );

  useEffect(() => {
    const productListResponseData = baseHelper.getResponseData(
      productReportListData,
      constant.gql.GET_PRODUCT_REPORT_LIST
    );
    const productListResponseError = baseHelper.getResponseError(
      productReportListError,
      constant.gql.GET_PRODUCT_REPORT_LIST
    );

    if (productListResponseData) {
      setTopPerforming(productListResponseData.topPerformingProducts);
      setBottomPerforming(productListResponseData.bottomPerformingProducts);
      const { stockUtilisationRate } = productListResponseData;
      if (stockUtilisationRate && stockUtilisationRate % 1 !== 0) {
        setStockUtilisation(stockUtilisationRate.toFixed(2));
      } else {
        setStockUtilisation(stockUtilisationRate);
      }
    }

    if (productListResponseError) {
      setBanner({ status: constant.CRITICAL, title: productListResponseError, isOpen: true });
    }
  }, [productReportListData, productReportListError]);

  useEffect(() => {
    const keyTakeAwayResponseData = baseHelper.getResponseData(
      keyTakeAwaysReportListData,
      constant.gql.GET_KEY_TAKEAWAY_REPORT_LIST
    );

    const keyTakeAwayResponseError = baseHelper.getResponseError(
      keyTakeAwaysReportListError,
      constant.gql.GET_KEY_TAKEAWAY_REPORT_LIST
    );
    if (keyTakeAwayResponseData) {
      const { size, color, productType, orderCount } = keyTakeAwayResponseData || {};
      const isColorCount = !!(orderCount && color && color.length);
      const isSizeCount = !!(orderCount && size && size.length);
      const isProductTypeCount = !!(orderCount && productType && productType.length);

      const colorName = isColorCount ? ["Total Orders Line Items"] : [];
      const sizeName = isSizeCount ? ["Total Orders Line Items"] : [];
      const colorValue = isColorCount ? [orderCount] : [];
      const sizeValue = isSizeCount ? [orderCount] : [];
      const typeName = isProductTypeCount ? ["Total Orders Line Items"] : [];
      const typeValue = isProductTypeCount ? [orderCount] : [];

      const isKeyTakeAwayData = isColorCount || isSizeCount || isProductTypeCount;
      setIsKeyTakeAway(isKeyTakeAwayData);

      (size || []).forEach((item) => {
        sizeName.push(item.name);
        sizeValue.push(item.count);
      });
      setSizeData([sizeName, sizeValue]);
      (color || []).forEach((item) => {
        colorName.push(item.name);
        colorValue.push(item.count);
      });
      setColorData([colorName, colorValue]);
      (productType || []).forEach((item) => {
        typeName.push(item.name);
        typeValue.push(item.count);
      });
      setTypeData([typeName, typeValue]);
    }
    if (keyTakeAwayResponseError) {
      setBanner({ status: constant.CRITICAL, title: keyTakeAwayResponseError, isOpen: true });
    }
  }, [keyTakeAwaysReportListData, keyTakeAwaysReportListError]);

  if (isLoading || orderReportListLoading || productReportListLoading || keyTakeAwaysReportListLoading) {
    return <Spinner />;
  }

  const { plan = {} } = currentUser || {};
  const { analytics: isAnalytics = false } = plan || {};
  const { isStripeSubscription = false } = sellerView;

  if (!isAnalytics && isStripeSubscription) {
    const tierAction = {
      heading: cms("common.message.error.upgrade"),
      action: {
        content: cms("common.button.upgradePlan"),
        url: "/update/plan",
      },
    };
    return (
      <Layout.AnnotatedSection title={tierAction.heading}>
        <Card title={tierAction.heading} sectioned>
          <Stack>
            <Stack.Item>
              <Button primary onClick={() => history.push(tierAction.action.url)}>
                {tierAction.action.content}
              </Button>
            </Stack.Item>
          </Stack>
        </Card>
      </Layout.AnnotatedSection>
    );
  }

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const getComponent = () => {
    return (
      <>
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                isScrollTop={banner.isOpen}
                title={banner.title}
                onDismiss={() => dismissBanner()}
              />
            </Layout.Section>
          )}
          <Layout.Section>
            <AnalyticsTabsHeader className="analytics-tabs">
              <Card>
                <div className="tab-header-actions">
                  <Popover
                    id="calendar-wrapper"
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                    fullWidth
                  >
                    <Popover.Pane>
                      <Stack wrap={false}>
                        <TextField label="Starting" value={startingDate} />
                        <TextField label="Ending" value={endingDate} />
                      </Stack>
                      <DatePicker
                        month={month}
                        year={year}
                        onChange={(date) => {
                          setSelectedDates({ start: date.start, end: date.end });
                          setStartingDate(moment(date.start).format("ll"));
                          setEndingDate(moment(date.end).format("ll"));
                          setisDateSelect(true);
                        }}
                        onMonthChange={handleMonthChange}
                        selected={selectedDates}
                        disableDatesAfter={new Date()}
                        // multiMonth
                        allowRange
                      />
                    </Popover.Pane>
                    <div className="calendar-actions">
                      <PageActions
                        primaryAction={{
                          content: "Apply",
                          onClick: () => {
                            setValues({
                              startDate: moment(new Date(selectedDates.start).setHours(0, 0, 0, 0)).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: moment(new Date(selectedDates.end).setHours(0, 0, 0, 0)).format("YYYY-MM-DD"),
                            });
                            setIsDateSelected(true);
                            setisDateSelect(false);
                            togglePopoverActive();
                          },
                        }}
                        secondaryActions={[
                          {
                            content: "Cancel",
                            onClick: togglePopoverActive,
                          },
                        ]}
                      />
                    </div>
                  </Popover>
                  <Button primary loading={headerLoading} onClick={handlePrint}>
                    Export Your Data
                  </Button>
                </div>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} disclosureText="More views" />
                <AnalyticThemeProvider contentColor={contentDataColor}>
                  <div ref={componentRef}>
                    <Header logo={logo} setLogo={setLogo} header={header} />
                    <OrderAnalyticsChart orderReport={orderReport} countryList={countryList} />
                    <ProductAnalyticsChart
                      typeData={typeData}
                      sizeData={sizeData}
                      colorData={colorData}
                      isKeyTakeAway={isKeyTakeAway}
                      topPerforming={topPerforming}
                      bottomPerforming={bottomPerforming}
                      stockUtilisation={stockUtilisation}
                    />
                  </div>
                </AnalyticThemeProvider>
              </Card>
            </AnalyticsTabsHeader>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default Reports;
