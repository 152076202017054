import { gql } from "apollo-boost";

const GET_SELLER_PLAN_DETAILS = gql`
  query getSellerPlanDetails($input: GetSellerPlanInput!) {
    getSellerPlanDetails(input: $input) {
      data {
        _id
        code
        currencyCode
        name
        price
        productLimit
        period
        periodUnit
        priceModel
        updatedAt
        description
        sellerId
        trialPeriod
        trialPeriodUnit
        redirectURL
        createdAt
      }
      error
      status
    }
  }
`;

export default GET_SELLER_PLAN_DETAILS;
