import React, { useContext, useEffect, useState } from "react";
import { Banner, Card, FormLayout, Layout, PageActions, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import hoc
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import FileUpload from "app/product/modules/operator/features/editCsv/fileUpload";
import { Sheet, SkeletonAnnotated } from "lib/components";

// import mutation
import { BULK_EDIT_UPLOAD } from "app/product/apollo/mutations";

const OperatorProductEditCsv = () => {
  const { cms, history, isLoading } = useContext(PrivateContext);
  const { gql, CRITICAL, SUCCESS, INFO } = constant;
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });

  const [values, setValues] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [bulkProductUploadRequest, { loading: bulkUploadLoading }] = useMutation(BULK_EDIT_UPLOAD);
  const [sheetActive, setSheetActive] = useState(false);

  const getCSVData = (csvValue) => {
    const regexForEnterKey = /\n,|\n/gi;
    const csvDataAfterRegex = csvValue.data.replace(regexForEnterKey, "\n");
    return csvDataAfterRegex;
  };

  const onSubmit = async () => {
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.csvIsRequired"),
        status: CRITICAL,
      });
      return;
    }
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: modifiedFormat,
        fileName: values.fileName,
      },
      isBulkEdit: true,
    };
    try {
      const res = await bulkProductUploadRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_EDIT_PRODUCT_CSV);
      // eslint-disable-next-line no-shadow
      let banner = {
        isOpen: true,
        status: INFO,
        title: cms("section.bulk.message.success.title"),
        children: cms("section.bulk.message.success.content"),
        action: {
          content: cms("section.bulk.message.success.action"),
          onAction: () => history.push("/activity/bulk-edit"),
        },
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_EDIT_PRODUCT_CSV);
        banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: error,
        };
      }
      setIsSubmitDisabled(true);
      setBanner(banner);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  useEffect(() => {
    if (values.data) {
      setIsSubmitDisabled(false);
    }
    if (!values.data) {
      setIsSubmitDisabled(true);
    }
  }, [values]);

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={cms("section.setting.title")} description={cms("section.setting.description")}>
        <Card title={cms("section.bulk.title")}>
          <Card.Section>
            <TextContainer>
              {cms("section.bulk.description")}
              <br />
              <Banner status="info">
                <p>{cms("section.bulk.label.exportProduct")}</p>
              </Banner>
            </TextContainer>
            <br />
            <FormLayout>
              <FileUpload setValue={setValues} values={values} />
            </FormLayout>
            <PageActions
              primaryAction={{
                content: cms("section.bulk.button.primary"),
                id: "submitButton",
                onAction: () => onSubmit(),
                loading: bulkUploadLoading,
                disabled: bulkUploadLoading || isSubmitDisabled,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  id: "cancelButton",
                  onAction: () => history.push("/"),
                },
              ]}
            />
          </Card.Section>
        </Card>
        <Sheet
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(OperatorProductEditCsv);
