import React, { useContext, useState } from "react";
import { Card, Layout, Link, TextContainer } from "@shopify/polaris";
import { BalanceMajor, ExportMinor } from "@shopify/polaris-icons";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { SingleColumnLayout } from "layout/private/components";
import { Banner, ModalPopup, PermissionBanner, SkeletonList } from "lib/components";
import { QuickAction, QuickReport } from "app/reports";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

// import subFeature
import OperatorExportPayment from "app/payments/modules/operator/features/export/export";
import PaymentList from "./subFeatures/paymentList";

const OperatorPaymentList = () => {
  const { currentUser, isLoading, history, cms, isShopifyCustomOperator } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false } = currentUser;

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const [isOpenModal, setModal] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const linkData = [
    {
      action: () => setModal(true),
      caption: cms("label.caption"),
      id: "exportPayment",
      label: cms("label.export"),
      primary: true,
      icon: ExportMinor,
    },
    {
      action: () => history.push("/payments/credit-note"),
      caption: cms("label.access"),
      icon: BalanceMajor,
      id: "creditNoteBalance",
      label: cms("label.balance"),
    },
  ];

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };

  const handleClose = () => {
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        {!isShopifyCustomOperator && appUpdate === "updated" && (
          <>
            <Banner
              isOpen
              action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
              title={cms("common.popup.migrate.title")}
              status="critical"
            >
              <p>{cms("common.popup.migrate.description")}</p>
            </Banner>
            <ModalPopup open={isOpen} onClose={handleClose} title="Upgrade to App permission">
              <Card>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutCustom")}`,
                      url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Card>
              <UpgradAppPermission />
            </ModalPopup>
            <br />
          </>
        )}
        {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
        <br />
        <QuickReport />
        <QuickAction link={linkData} />
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <PaymentList setBanner={setBanner} />
            </Card>
          </Layout.Section>

          <OperatorExportPayment openModal={isOpenModal} setOpenModal={setModal} />
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  // <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent() />
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withFeature(withErrorBoundary(OperatorPaymentList), { feature: constant.PAYMENTS });
