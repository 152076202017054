import React from "react";
import { RadioButton } from "@shopify/polaris";
import PropTypes from "prop-types";
import constant from "lib/constant/constant";
import { withErrorBoundary, withFeature } from "lib/hoc";

const TimeService = (props) => {
  const { cms, onHandleChange, value } = props;
  return (
    <RadioButton
      label={cms("label.timeService")}
      helpText={cms("helpText.timeService")}
      id="timeService"
      checked={value === constant.SERVICE}
      onChange={() => onHandleChange(constant.SERVICE)}
    />
  );
};

TimeService.propTypes = {
  value: PropTypes.string.isRequired,
  cms: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(TimeService), { feature: constant.TIME_SERVICE });
