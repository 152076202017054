import React, { useContext } from "react";

import { AdminActivity } from "app/userManagement/modules/admin/features";
import { OperatorActivity } from "app/userManagement/modules/operator/features";
import { ProviderActivity } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Activity = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminActivity />;
    }
    if (role === operator) {
      return <OperatorActivity />;
    }
    if (role === provider) {
      return <ProviderActivity />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ActivityComponent = getComponent(currentUserRole);
  return ActivityComponent;
};

export default Activity;
