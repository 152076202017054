// import packages
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Card, Layout, PageActions, Spinner } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

// import helpers components
import { Banner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";

// import subFeatures

// import gql
import { GET_SHIPPING_REGION } from "app/shipping/apollo/queries";

import { GET_VENDOR_BRAND_LIST } from "app/productOld/apollo/queries";

import { GET_SELLER_FULFILLMENT, UPDATE_FULFILLMENT } from "app/setup/apollo";
import {
  Advance,
  Consignment,
  Dropship,
  Global,
  ManageShipping,
  ThirdPartyShipping,
  ThirdPartyOptions,
} from "./subFeatures";

import validate from "./yup";

const OperatorFulfillment = () => {
  const { history, currentUser, cms } = useContext(PrivateContext);
  const { _id: currentUserId } = currentUser;
  const {
    gql: { GET_SELLER_FULFILLMENT: GET_FULFILLMENT_TEXT, GET_VENDOR_BRAND_LIST: GET_VENDOR_BRAND_LIST_TEXT },
  } = constant;

  let vendors = [];

  const [error, setError] = useState(false);
  const [advanceError, setAdvanceError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isThirdPartyShipping, setThirdPartyShipping] = useState(false);
  const [isShipping, setShipping] = useState(true);
  const [selected, setSelected] = useState(constant.NZPOST);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [siteCode, setSiteCode] = useState("");

  const [canadaPost, setCanadaPost] = useState({});

  const [fulfillment, setFulfillment] = useState({
    isDropship: true,
    isConsignment: false,
  });

  const [global, setGlobal] = useState({
    address: "",
    city: "",
    pincode: "",
    country: "",
    countryCode: "",
  });

  const [addresses, setAddressValues] = useState([
    {
      address: {},
      vendorIds: [],
    },
  ]);

  const [selectedVendors, setSelectedVendors] = useState([]);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [updateFulfillment, { loading: updateFulfillmentLoading }] = useMutation(UPDATE_FULFILLMENT);
  const { loading: destinationLoading, data: destinationData, error: destinationError } = useQuery(GET_SHIPPING_REGION);
  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError } = useQuery(
    GET_SELLER_FULFILLMENT
  );

  const { loading: vendorLoading, data: vendorData, error: vendorError } = useQuery(GET_VENDOR_BRAND_LIST);
  const responseData = baseHelper.getResponseData(vendorData, GET_VENDOR_BRAND_LIST_TEXT);
  if (responseData) {
    vendors = (responseData || []).map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: baseHelper.mongoIdAsString(item._id),
      label: item.brandName,
    }));
  }

  // eslint-disable-next-line no-shadow
  const setStateValues = (fulfillment) => {
    const { consignment = {}, dropship = {}, type = "" } = fulfillment;

    // eslint-disable-next-line no-shadow
    const { canadaPost = {}, isThirdPartyShipping = false, nzPost = {}, shippingOption = "" } = dropship || {};

    // eslint-disable-next-line no-shadow
    const { accountNumber = "", clientId = "", clientSecret = "", siteCode = "" } = nzPost || {};

    // eslint-disable-next-line no-shadow
    const { global = {}, addresses = [] } = consignment || {};
    const { address = "", city = "", country = "", pincode = "", countryCode = "" } = global || {};
    const addressValues = addresses && addresses.length ? addresses : [{ address: {}, vendorIds: [] }];
    const isDropship = type === constant.DROPSHIP;
    // eslint-disable-next-line no-shadow
    const isShipping = !isThirdPartyShipping;
    const selectedOption = shippingOption || selected;

    setShipping(isShipping);
    setThirdPartyShipping(isThirdPartyShipping);
    setSelected(selectedOption);
    setClientId(clientId);
    setClientSecret(clientSecret);
    setAccountNumber(accountNumber);
    setSiteCode(siteCode);
    setFulfillment({
      isDropship,
      isConsignment: !isDropship,
    });
    setGlobal({
      address,
      city,
      country,
      pincode,
      countryCode,
    });
    setAddressValues(addressValues);
    setCanadaPost(canadaPost);

    // eslint-disable-next-line no-shadow
    const selectedVendors = [];
    // eslint-disable-next-line no-shadow
    (addresses || []).forEach((address) => {
      selectedVendors.push(address.vendorIds || []);
    });
    setSelectedVendors([...new Set([...selectedVendors.flat()])]);
  };

  useEffect(() => {
    if (!fulfillmentData) {
      return;
    }
    // eslint-disable-next-line no-shadow
    const responseData = baseHelper.getResponseData(fulfillmentData, GET_FULFILLMENT_TEXT);
    if (!responseData) {
      return;
    }
    setStateValues(responseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentData]);

  const destinationDetails = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];
  const destinationSelect = [{ label: "Select Country", value: "" }];
  const destinations = [...destinationSelect, ...destinationDetails];

  const countries =
    (destinations &&
      destinations.length &&
      destinations
        .map((item) => item.options)
        .flat()
        .filter((item) => item)) ||
    [];

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const handleValidation = async (key, value) => {
    const errorMessage = await validate(key, value, cms);

    setError((prevState) => ({
      ...prevState,
      [key]: errorMessage,
    }));
  };

  const handleGlobalValidation = ({ address, city, countryCode, pincode } = {}) => {
    let isValidate = false;
    if (!address) {
      handleValidation(constant.ADDRESS, address);
      isValidate = true;
    }
    if (!city) {
      handleValidation(constant.CITY, city);
      isValidate = true;
    }
    if (!countryCode) {
      handleValidation(constant.COUNTRY_CODE, countryCode);
      isValidate = true;
    }
    if (!pincode) {
      handleValidation(constant.PINCODE, pincode);
      isValidate = true;
    }
    return isValidate;
  };

  const handleVendorValidation = (vendorIds = []) => {
    let isValidate = false;
    if (!(vendorIds && vendorIds.length)) {
      handleValidation(constant.VENDOR_IDS, vendorIds);
      isValidate = true;
    }
    return isValidate;
  };

  // eslint-disable-next-line no-shadow
  const handleAdvanceValidate = (addresses) => {
    let isValidate = false;
    let address = [];
    const { length } = addresses;
    if (length <= 1) {
      address = addresses.map((addressItem) => {
        // eslint-disable-next-line no-shadow
        const { vendorIds = [], address } = addressItem || {};
        if (vendorIds && vendorIds.length) {
          const addressValidation = handleGlobalValidation(address);
          const vendorValidation = handleVendorValidation(vendorIds);
          if (addressValidation || vendorValidation) {
            isValidate = true;
          }
        }
        return isValidate;
      });
    } else {
      address = addresses.map((addressItem) => {
        // eslint-disable-next-line no-shadow
        const { vendorIds = [], address } = addressItem || {};
        const addressValidation = handleGlobalValidation(address);
        const vendorValidation = handleVendorValidation(vendorIds);
        if (addressValidation || vendorValidation) {
          isValidate = true;
        }
        return isValidate;
      });
    }

    const isAdvance = (address && address.length && address[0]) || isValidate;
    return isAdvance;
  };

  // eslint-disable-next-line no-shadow
  const handleShippingValidation = ({ clientId, clientSecret, accountNumber, siteCode } = {}) => {
    let isValidate = false;
    if (!clientId) {
      handleValidation(constant.CLIENT_ID, clientId);
      isValidate = true;
    }
    if (!clientSecret) {
      handleValidation(constant.CLIENT_SECRET, clientSecret);
      isValidate = true;
    }

    if (!accountNumber) {
      handleValidation(constant.ACCOUNT_NUMBER, accountNumber);
      isValidate = true;
    }
    if (!siteCode) {
      handleValidation(constant.SITE_CODE, siteCode);
      isValidate = true;
    }

    return isValidate;
  };

  const handleCanadaPostValidation = (canadaPostData) => {
    let isValidate = false;

    const { contractId, customerNumber, password, userName } = canadaPostData || {};
    if (!userName) {
      handleValidation(constant.USER_NAME, userName);
      isValidate = true;
    }
    if (!password) {
      handleValidation(constant.PASSWORD, password);
      isValidate = true;
    }

    if (!customerNumber) {
      handleValidation(constant.CUSTOMER_NUMBER, customerNumber);
      isValidate = true;
    }
    if (!contractId) {
      handleValidation(constant.CONTRACT_ID, contractId);
      isValidate = true;
    }

    return isValidate;
  };

  const onSubmit = async () => {
    setIsSubmit(false);
    const { isDropship, isConsignment } = fulfillment;
    const type = isDropship ? constant.DROPSHIP : constant.CONSIGNMENT;
    const thirdPartyOption = (isThirdPartyShipping && selected) || "";

    let globalError = false;
    let advanceValidate = false;
    let clientError = false;
    let credentialError = false;
    if (isConsignment) {
      globalError = handleGlobalValidation(global);
      advanceValidate = handleAdvanceValidate(addresses);
    }

    if (isDropship && isThirdPartyShipping && selected === constant.NZPOST) {
      clientError = handleShippingValidation({ clientId, clientSecret, accountNumber, siteCode });
    }

    if (isDropship && isThirdPartyShipping && selected === constant.CANADA_POST) {
      credentialError = handleCanadaPostValidation(canadaPost);
    }

    if (isThirdPartyShipping && !thirdPartyOption) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.thirdPartyError") });
      setIsSubmit(true);
      setAdvanceError(false);
      return;
    }

    if (clientError) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.clientDetailError") });
      setIsSubmit(true);
      setAdvanceError(false);
      return;
    }

    if (credentialError) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.credentialError") });
      setIsSubmit(true);
      setAdvanceError(false);
      return;
    }

    if (globalError) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.globalError") });
      setIsSubmit(true);
      setAdvanceError(false);
      return;
    }

    if (advanceValidate) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.advanceError") });
      setError(false);
      setIsSubmit(true);
      setAdvanceError(false);
      return;
    }

    const addressesData =
      addresses && addresses.length && addresses[0].vendorIds && addresses[0].vendorIds.length ? addresses : [];
    const consignment =
      (!isDropship && {
        global,
        addresses: addressesData,
      }) ||
      {};

    let dropship = {
      isThirdPartyShipping: isDropship && isThirdPartyShipping,
      shippingOption: thirdPartyOption,
    };

    if (isDropship && isThirdPartyShipping && selected === constant.NZPOST) {
      dropship = {
        ...dropship,
        nzPost: {
          clientId,
          clientSecret,
          accountNumber,
          siteCode,
        },
      };
    }

    if (isDropship && isThirdPartyShipping && selected === constant.CANADA_POST) {
      // for canada post
      dropship = {
        ...dropship,
        canadaPost,
      };
    }

    const formData = {
      type,
      consignment,
      ownerId: currentUserId,
      dropship,
    };

    try {
      const response = await updateFulfillment({
        variables: {
          input: formData,
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.UPDATE_FULFILLMENT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: cms("message.success") });
      setError(false);
    } catch (resError) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.error") });
    }
  };

  const handleChange = (field) => {
    setIsSubmit(true);
    const isDropship = field === constant.DROPSHIP;
    setFulfillment((prevState) => ({
      ...prevState,
      isDropship,
      isConsignment: !isDropship,
    }));
  };

  const handleShipping = (val) => {
    setIsSubmit(true);
    // eslint-disable-next-line no-shadow
    const isThirdPartyShipping = val === constant.ESHIP;
    // eslint-disable-next-line no-shadow
    const isShipping = !isThirdPartyShipping;
    setThirdPartyShipping(isThirdPartyShipping);
    setShipping(isShipping);
  };

  const handleClientId = (val) => {
    setClientId(val);
    setIsSubmit(true);
  };

  const handleClientSecret = (val) => {
    setClientSecret(val);
    setIsSubmit(true);
  };

  const handleAccountNumber = (val) => {
    setAccountNumber(val);
    setIsSubmit(true);
  };

  const handleSiteCode = (val) => {
    setSiteCode(val);
    setIsSubmit(true);
  };

  const handleCanadaPostChange = (field, value) => {
    setIsSubmit(true);
    setCanadaPost((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleGlobal = (field, value) => {
    let country = global.country || "";
    if (field === constant.COUNTRY_CODE) {
      // eslint-disable-next-line no-shadow
      const existCountry = countries.find((country) => country.value === value);
      const { label = "" } = existCountry || false;
      country = label || "";
    }
    setIsSubmit(true);
    setGlobal((prevState) => ({
      ...prevState,
      [field]: value,
      country,
    }));
  };

  const queryLoading = destinationLoading || fulfillmentLoading || vendorLoading;
  const isError = destinationError || fulfillmentError || vendorError;

  useEffect(() => {
    if (isError) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(isError),
        status: "critical",
      });
    }
  }, [isError, setBanner]);

  if (queryLoading) {
    return <Spinner />;
  }

  const optionLabel = `${cms("label.thirdParty")} ${cms("label.shipping")} ${cms("label.option")}`;

  return (
    <Layout>
      {banner.isOpen && (
        <Layout.Section>
          <br />
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onDismissBanner} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")}>
        <Card sectioned>
          <Dropship handleChange={handleChange} data={fulfillment} />
          <Consignment handleChange={handleChange} data={fulfillment} />
        </Card>
      </Layout.AnnotatedSection>

      {!fulfillment.isConsignment && (
        <Layout.AnnotatedSection title={cms("label.dropshipMethod")}>
          <Card sectioned>
            <ManageShipping isChecked={isShipping} handleShipping={handleShipping} />
            <ThirdPartyShipping isChecked={isThirdPartyShipping} handleShipping={handleShipping} />
          </Card>
        </Layout.AnnotatedSection>
      )}

      {!fulfillment.isConsignment && isThirdPartyShipping && (
        <Layout.AnnotatedSection title={optionLabel}>
          <Card sectioned>
            <ThirdPartyOptions
              data={fulfillment}
              handleSelectChange={handleSelectChange}
              selected={selected}
              clientId={clientId}
              accountNumber={accountNumber}
              siteCode={siteCode}
              clientSecret={clientSecret}
              handleClientId={handleClientId}
              handleClientSecret={handleClientSecret}
              handleSiteCode={handleSiteCode}
              handleAccountNumber={handleAccountNumber}
              handleCanadaPostChange={handleCanadaPostChange}
              canadaPost={canadaPost}
              setError={error}
            />
          </Card>
        </Layout.AnnotatedSection>
      )}

      {fulfillment.isConsignment && (
        <>
          <Global
            data={fulfillment}
            global={global}
            onChange={handleGlobal}
            loading={destinationLoading}
            destinations={destinations}
            setError={error}
          />

          <Advance
            setIsSubmit={setIsSubmit}
            data={fulfillment}
            vendors={vendors}
            addresses={addresses}
            setAddressValueOption={setAddressValues}
            loading={vendorLoading}
            destinations={destinations}
            countries={countries}
            setSelectedVendors={setSelectedVendors}
            selectedVendors={selectedVendors}
            setError={error}
            setAdvanceError={setAdvanceError}
            advanceError={advanceError}
          />
        </>
      )}
      <Layout.Section>
        <PageActions
          primaryAction={{
            id: "submit",
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: updateFulfillmentLoading,
            disabled: updateFulfillmentLoading || !isSubmit,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        />
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(OperatorFulfillment);
