import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Collapsible, Heading, Icon, Layout, Stack, TextContainer } from "@shopify/polaris";
import { CircleDisabledMajor, CircleTickMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { useQuery } from "@apollo/react-hooks";

import { withFeature, withErrorBoundary } from "lib/hoc";

import { Banner, Spinner as Loader, Toast } from "lib/components";
import { NotFound } from "layout/private/components";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";

import { getOrderView, getOrderLineView } from "app/orders/apollo/viewOrder";
import { errorHelper } from "lib/helpers";

import { Customer, FulfilledLineItems, Fulfillments, FulfillmentStatus, Notes, UnfulfilledLineItems } from "./features";

const { gql, HIDE, PAID } = constant;

const AdminOrderView = () => {
  const [viewOrderData, setViewOrderData] = useState(false);
  const [viewLineOrderData, setViewLineOrderData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });
  const { setPageData, match, history, cms } = useContext(PrivateContext);
  const [toggleStatus, setToggleStatus] = useState({
    unfulfilledLineItems: {
      active: true,
      toggleText: HIDE,
    },
    fullFillmentStatus: {
      active: true,
      toggleText: HIDE,
    },
    fulFilledLineItems: {
      active: true,
      toggleText: HIDE,
    },
    fulFillments: {
      active: true,
      toggleText: HIDE,
    },
  });
  const {
    loading: loadingOrderLineView,
    error: errorOrderLineView,
    data: dataOrderLineView,
    refetch: refetchOrderLineView,
  } = useQuery(getOrderLineView, {
    variables: { input: { orderId: match.params.id } },
  });
  const { loading: loadingOrderView, error: errorOrderView, data: dataOrderView, refetch: refetchOrderView } = useQuery(
    getOrderView,
    {
      variables: { input: { id: match.params.id } },
    }
  );
  useEffect(() => {
    if (dataOrderLineView && dataOrderView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderView = baseHelper.getResponseData(dataOrderView, gql.GET_ORDER_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderViewError = baseHelper.getResponseError(dataOrderView, gql.GET_ORDER_VIEW);

      if (orderLineView && orderView) {
        setViewOrderData(orderView);
        setViewLineOrderData(orderLineView);
      }
      if (orderLineViewError && orderViewError) {
        setIsError(true);
      }
    }
  }, [dataOrderLineView, dataOrderView]);

  useEffect(() => {
    if (viewOrderData && viewLineOrderData) {
      // eslint-disable-next-line no-unused-vars
      let isDisabled = true;
      const { orderNumber = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      viewLineOrderData.map((lineItem) => {
        if (lineItem.status === constant.ACCEPTED) {
          isDisabled = false;
        }
        return null;
      });
      setPageData({
        title: `#${orderNumber}`,
        breadcrumbs: [{ url: "/" }],
        subtitle: baseHelper.formatDate(createdAt),
      });
    }
  }, [viewOrderData, viewLineOrderData, history, match.params.id, setPageData]);

  useEffect(() => {
    if (errorOrderView || errorOrderLineView) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorOrderView || errorOrderLineView),
        isOpen: true,
      });
    }
  }, [errorOrderView, errorOrderLineView]);

  const handleToggle = (evt) => {
    const { id } = evt.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? cms("common.label.show") : cms("common.label.hide"),
      },
    }));
  };
  const renderHeading = (title, count, fulfilled = false) => {
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          <Icon
            source={fulfilled ? CircleTickMajor : CircleDisabledMajor}
            backdrop
            color={fulfilled ? constant.GREENDARK : constant.YELLOWDARK}
          />
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
        {count && (
          <Stack.Item>
            <Badge status={fulfilled ? constant.SUCCESS : constant.ATTENTION}>{count}</Badge>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const renderItemData = (dataOrderViews, dataOrderLineViews) => {
    const orderLineItemsData = dataOrderLineViews;
    const { financialStatus } = dataOrderViews || {};
    if (!orderLineItemsData)
      return (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      );

    let unfulfillCount = constant.value.ZERO;
    let fulfillCount = constant.value.ZERO;
    if (orderLineItemsData) {
      orderLineItemsData.map((lineItem) => {
        if (lineItem.fulfillmentStatus === constant.UNFULFILLED) {
          unfulfillCount += constant.value.ONE;
        } else {
          fulfillCount += constant.value.ONE;
        }
        return null;
      });
    }
    return (
      <>
        {(unfulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.UNFULFILLED), unfulfillCount)}
            actions={[
              {
                content: toggleStatus.unfulfilledLineItems.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.UNFULFILLEDLINEITEMS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.UNFULFILLEDLINEITEMS} open={toggleStatus.unfulfilledLineItems.active}>
              <UnfulfilledLineItems
                itemData={orderLineItemsData}
                onRefetchOrderLineView={refetchOrderLineView}
                onRefetchOrderView={refetchOrderView}
                setBanner={setBanner}
                setMessage={setMessage}
                data={dataOrderViews}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLED), fulfillCount, true)}
            actions={[
              {
                content: toggleStatus.fulFilledLineItems.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULFILLEDLINEITEMS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLEDLINEITEMS} open={toggleStatus.fulFilledLineItems.active}>
              <FulfilledLineItems lineData={orderLineItemsData} data={dataOrderViews} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLMENTS), constant.value.ZERO, true)}
            actions={[
              {
                content: toggleStatus.fulFillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "fulFillments",
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLMENTS} open={toggleStatus.fulFillments.active}>
              <Fulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {dataOrderViews && (
          <Card
            title={renderHeading(
              (financialStatus === PAID && `${cms("label.paid")}`) || cms("label.unpaid"),
              constant.value.ZERO,
              financialStatus === PAID
            )}
            actions={[
              {
                content: toggleStatus.fullFillmentStatus.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULLFILLMENTSTATUS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULLFILLMENTSTATUS} open={toggleStatus.fullFillmentStatus.active}>
              <FulfillmentStatus lineData={orderLineItemsData} data={dataOrderViews} />
            </Collapsible>
          </Card>
        )}
      </>
    );
  };
  let isNote = true;
  const getSecondaryComponent = (dataOrderViews) => {
    const { note } = dataOrderViews || {};
    if (!note) {
      isNote = false;
    }
    return (
      <>
        {dataOrderViews && (
          <>
            <Notes
              note={(isNote && note) || ""}
              dataItem={dataOrderViews}
              setBanner={setBanner}
              setMessage={setMessage}
              onRefetchOrderView={refetchOrderView}
            />
            <Customer data={dataOrderViews} />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      {(loadingOrderView || loadingOrderLineView) && (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      )}
      <Layout.Section>
        {isError && (
          <Layout.Section>
            <div style={{ display: "flex" }}>
              <NotFound />
            </div>
          </Layout.Section>
        )}
        <Toast message={message} />
        {viewOrderData && viewLineOrderData && renderItemData(viewOrderData, viewLineOrderData)}
      </Layout.Section>
      <Layout.Section secondary>{getSecondaryComponent(viewOrderData)}</Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(AdminOrderView), { feature: constant.VIEW_ORDER });
