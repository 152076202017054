import React, { useState, useCallback } from "react";
import { Card, Layout, Tag, Select, TextContainer, Stack, Autocomplete, Caption, FormLayout } from "@shopify/polaris";
import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";
import { organizationProp } from "app/productOld/modules/generic/edit/propTypes";

const Organization = (props) => {
  const {
    cms,
    handleChange,
    data,
    productType,
    productTag,
    // learnMore
  } = props;
  const [selected, setSelected] = useState((data && data.tags) || []);
  const [inputValue, setInputValue] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);

  const isKeyValuePair = !!(productTag && productTag.tags && productTag.tags.length && productTag.tags.value);
  let sortedOptions =
    (productTag && productTag.tags && baseHelper.sort(productTag.tags, isKeyValuePair && "label")) || [];
  sortedOptions = isKeyValuePair ? sortedOptions : sortedOptions.map((item) => ({ label: item, value: item }));

  const onSelect = (selectedTags) => {
    setSelected(selectedTags);
    handleChange(constant.TAGS, selectedTags);
  };

  const filterAndUpdateOptions = (inputString) => {
    if (!inputString) {
      setInputValue(inputString);
      return;
    }
    const filterRegex = new RegExp(inputString, "i");
    const resultOptions = sortedOptions.filter((option) => option.label.match(filterRegex));
    setInputValue(inputString);
    setOptionsValue(resultOptions);
  };

  const textField = (
    <Autocomplete.TextField
      onChange={filterAndUpdateOptions}
      label={cms("section.organization.label.productTags")}
      value={inputValue}
      placeholder={cms("section.organization.placeholder.selectTags")}
    />
  );
  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selected];
      options.splice(options.indexOf(tag), 1);
      handleChange(constant.TAGS, options);
      setSelected(options);
    },
    [selected, handleChange]
  );
  const tagsMarkup =
    (selected.length &&
      selected.map((option) => {
        let tagLabel = "";
        tagLabel = option.replace("_", " ");
        return (
          <Tag key={`option${option}`} onRemove={removeTag(option)}>
            {tagLabel}
          </Tag>
        );
      })) ||
    [];

  return (
    <>
      <Layout.Section>
        <Card
          title={[
            cms("section.organization.title"),
            data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
          ]}
          // actions={[
          //   {
          //     content: cms("common.label.learnMore"),
          //     onAction: () => {
          //       learnMore(cms("section.organization.title"), cms("label.todo"));
          //     },
          //   },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.organization.description")}</TextContainer>
            <br />
            <FormLayout>
              <Select
                label={`${cms("common.label.vendor")}*`}
                options={(data && data.vendor && [data.vendor]) || []}
                disabled
              />
              <Select
                label={cms("section.organization.label.productType")}
                placeholder={cms("section.organization.placeholder.pleaseSelect")}
                value={data.productType}
                options={productType || []}
                onChange={(value) => handleChange(constant.PRODUCT_TYPE, value)}
              />
              <Autocomplete
                allowMultiple
                options={optionsValue || []}
                selected={selected}
                textField={textField}
                onSelect={(value) => onSelect(value)}
                listTitle="Suggested Tags"
              />
            </FormLayout>
            <br />
            <TextContainer>
              <Stack>{tagsMarkup}</Stack>
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
    </>
  );
};

Organization.propTypes = organizationProp.type;
export default Organization;
