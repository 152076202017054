import React, { useContext, useEffect, useState } from "react";
import { Button, Caption, Card, Checkbox, Layout, PageActions, Stack, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers
import baseHelper from "lib/helpers/base";
import { OnboardingContext, PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import helper
import { errorHelper } from "lib/helpers";

import { Toast, Banner } from "lib/components";

// import mutation
import { UPDATE_SETTING, SYNC_PRODUCT_CATALOG } from "app/productOld/apollo/mutations";

import { SYNC_PRODUCT_TAG, SYNC_PRODUCT_TYPE } from "app/productOld/apollo/mutations/connect";

const ManageTagTypes = (props) => {
  const {
    isCatalogHidden,
    isHideTagAndType,
    isHideTag,
    isHideType,
    lastUpdated,
    radioButtonValue,
    rate,
    selected,
    setBannerStatus,
    setIsCatalogHidden,
    refetch,
  } = props || {};
  const { cms, history } = useContext(PrivateContext);

  const { APPROVE_ALL, APPROVE_SELECTED, gql, REVIEW_ALL } = constant;
  const [message, setMessage] = useState("");
  const [submitEnable, setSubmitEnable] = useState(false);
  const [setSettingProduct, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);
  const [syncProductTag] = useMutation(SYNC_PRODUCT_TAG);
  const [syncProductType] = useMutation(SYNC_PRODUCT_TYPE);
  const [syncCatalog] = useMutation(SYNC_PRODUCT_CATALOG);

  const [input, setInput] = useState({
    isHideTagAndType,
    isHideTag,
    isHideType,
  });

  useEffect(() => {
    setInput({
      isHideTag,
      isHideType,
    });
  }, [isHideTag, isHideType]);

  const handleCheckbox = (key, value) => {
    setInput({
      ...input,
      [key]: value,
    });
  };

  const handleResyncTags = () => {
    syncProductTag({}).then(() => {
      setMessage(cms("operator.section.tagAndType.message.success.resyncTag"));
    });
  };

  const handleResyncTypes = () => {
    syncProductType({}).then(() => {
      setMessage(cms("operator.section.tagAndType.message.success.resyncType"));
    });
  };

  const updateProductSetting = () => {
    const requestData = {
      isHideTagAndType,
      isHideTag: input.isHideTag,
      isHideType: input.isHideType,
      isCatalogHidden,
    };

    setSettingProduct({
      variables: { input: requestData },
    }).then((res) => {
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_SETTING);
      if (responseData) {
        setMessage("");
        refetch();
        setMessage(cms("operator.section.tagAndType.message.success.update"));
      }
    });
  };

  const onSubmit = () => {
    setSubmitEnable(false);
    updateProductSetting();
  };

  const handleSyncCatalog = () => {
    syncCatalog()
      .then((res) => {
        const errorResponse = baseHelper.getResponseError(res.data, gql.SYNC_PRODUCT_CATALOG);
        let bannerValue = { status: constant.CRITICAL, title: errorResponse, isOpen: true };
        if (!errorResponse) {
          const responseData = baseHelper.getResponseData(res.data, gql.SYNC_PRODUCT_CATALOG);
          if (responseData) {
            setMessage(cms("operator.section.catalog.message.success"));
            bannerValue = { status: "", title: "", isOpen: false };
          }
          return null;
        }

        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: bannerValue.isOpen,
        });
        return null;
      })
      .catch((exception) => {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  return (
    <>
      <Layout.AnnotatedSection
        title={cms("operator.section.tagAndType.title")}
        description={cms("operator.section.tagAndType.description")}
      >
        <Card
          title={[
            cms("operator.section.tagAndType.label.title"),
            <TextStyle variation="subdued">
              {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
            </TextStyle>,
          ]}
          sectioned
        >
          <Banner isOpen status="info">
            <p>{cms("operator.section.tagAndType.message.info.resyncLimit")}</p>
          </Banner>
          <br />
          <Stack vertical>
            <Stack>
              <Stack.Item fill>
                <div className="choice-list">
                  <Checkbox
                    helpText={cms("operator.section.tagAndType.helpText.tag")}
                    label={cms("operator.section.tagAndType.label.tag")}
                    checked={input.isHideTagAndType || input.isHideTag}
                    onChange={(value) => {
                      handleCheckbox(constant.IS_HIDE_TAG, value);
                      setSubmitEnable(true);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item>
                <Button size="slim" onClick={() => handleResyncTags()}>
                  {cms("operator.section.tagAndType.label.resync")}
                </Button>
              </Stack.Item>
            </Stack>
            <Stack>
              <Stack.Item fill>
                <div className="choice-list">
                  <Checkbox
                    helpText={cms("operator.section.tagAndType.helpText.type")}
                    label={cms("operator.section.tagAndType.label.type")}
                    checked={input.isHideTagAndType || input.isHideType}
                    onChange={(value) => {
                      handleCheckbox(constant.IS_HIDE_TYPE, value);
                      setSubmitEnable(true);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item>
                <Button size="slim" onClick={() => handleResyncTypes()}>
                  {cms("operator.section.tagAndType.label.resync")}
                </Button>
              </Stack.Item>
            </Stack>
            <Stack>
              <Stack.Item fill>
                <div className="choice-list">
                  <Checkbox
                    helpText={cms("operator.section.catalog.helpText")}
                    label={cms("operator.section.catalog.label")}
                    checked={!isCatalogHidden}
                    onChange={() => {
                      setIsCatalogHidden(!isCatalogHidden);
                      setSubmitEnable(true);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item>
                <Button size="slim" onClick={() => handleSyncCatalog()}>
                  {cms("operator.section.tagAndType.label.resync")}
                </Button>
              </Stack.Item>
            </Stack>
          </Stack>
          <div className="toast">
            <Toast message={message} setToast={setMessage} />
          </div>
          <div className="page-action">
            {lastUpdated ? (
              <PageActions
                primaryAction={{
                  id: "submitproductTagandType",
                  content: cms("common.button.update"),
                  loading: productSettingLoading,
                  disabled: !submitEnable,
                  onAction: () => onSubmit(),
                }}
                secondaryActions={[
                  {
                    id: "cancelProductSetting",
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            ) : null}
          </div>
        </Card>
        {!lastUpdated ? (
          <PageActions
            primaryAction={{
              id: "submitproductTagandType",
              content: cms("common.button.submit"),
              loading: productSettingLoading,
              onAction: () => onSubmit(),
              disabled: !(input.isHideTag || input.isHideType || !isCatalogHidden) || !submitEnable,
            }}
            secondaryActions={[
              {
                id: "cancelProductSetting",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        ) : null}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ManageTagTypes), {
  feature: constant.PRODUCT_TAG_TYPE_SETTING,
  // subFeature: constant.PRODUCT_TAG_TYPE_SETTING,
});
