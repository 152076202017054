import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  Caption,
  DataTable,
  Layout,
  Modal,
  RadioButton,
  Stack,
  TextContainer,
  TextStyle,
  PageActions,
} from "@shopify/polaris";

import { useQuery, useMutation } from "react-apollo";

// import context
import { PrivateContext } from "lib/context";
// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// helpers and component
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated, Toast } from "lib/components";
// import constant
import constant from "lib/constant/constant";

// import gql
import { FETCH_VENDOR_ACCESS } from "app/advanceVendor/apollo/queries";
import { VENDOR_ACCESS } from "app/advanceVendor/apollo/mutations";

const ManageAccess = () => {
  const { cms, history } = useContext(PrivateContext);
  const { gql } = constant;
  const [userInput, setUserInput] = useState({
    product: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    order: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
    payment: {
      isFullAccess: true,
      isHide: false,
      isReadOnly: false,
    },
  });

  const [updatedAt, setUpdatedAt] = useState("");
  const [submitEnabled, setSubmitEnable] = useState(true);
  const [activePopup, setActivePopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const VENDOR_MODULE_TABLE_HEADINGS = constant.VENDOR_MODULE_TABLE_HEADING;
  const VENDOR_MODULE_COLUMN_CONTENTS = constant.VENDOR_MODULE_COLUMN_CONTENT;

  const { loading: settingLoading = false, data: vendorAccessData, refetch } = useQuery(FETCH_VENDOR_ACCESS);

  const [vendorAccess, { loading: accessSettingLoading }] = useMutation(VENDOR_ACCESS);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  useEffect(() => {
    if (!vendorAccessData) {
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING);
    const responseError = baseHelper.getResponseError(vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }

    if (responseData && responseData.access) {
      const { access = {} } = responseData;
      setUpdatedAt(access && access.updatedAt);
      delete access.updatedAt;
      setUserInput(access);
    }
  }, [vendorAccessData, gql.GET_VENDOR_ACCESS_SETTING]);

  const handleChange = (key, value, id) => {
    setSubmitEnable(false);
    const newState = userInput;
    newState[id] = { ...newState[id], [key]: value };
    if (key === constant.IS_FULL) {
      newState[id] = { ...newState[id], isReadOnly: false, isHide: false };
    }
    if (key === constant.IS_READ) {
      newState[id] = { ...newState[id], isFullAccess: false, isHide: false };
    }
    if (key === constant.IS_HIDE) {
      newState[id] = { ...newState[id], isFullAccess: false, isReadOnly: false };
    }

    setUserInput({ ...newState });
  };

  const handleSubmit = () => {
    setActivePopup(true);
    const { product = {}, order = {}, payment = {} } = userInput;
    if (product.isReadOnly || order.isReadOnly || payment.isReadOnly) {
      setActivePopup(true);
    }
    if (product.isHide || order.isHide || payment.isHide) {
      setActivePopup(true);
    }
  };

  const onSubmit = async () => {
    try {
      setActivePopup(!activePopup);
      const res = await vendorAccess({
        variables: {
          input: userInput,
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.VENDOR_ACCESS);
      const resError = baseHelper.getResponseError(res.data, gql.VENDOR_ACCESS);
      if (resError) {
        setBanner({
          status: constant.CRITICAL,
          isOpen: true,
          title: resError,
        });
      }
      if (resData) {
        setSuccessMessage(cms("message.success.save"));
        refetch();
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const handlePopup = () => {
    setActivePopup(!activePopup);
  };

  const getModuleName = (item) => {
    if (item === constant.ORDER) {
      return constant.ORDER_UC_FIRST;
    }
    if (item === constant.PRODUCT) {
      return constant.PRODUCT_UC_FIRST;
    }
    return constant.PAYMENTS_UC_FIRST;
  };

  const selectModules = (item) => <TextStyle>{getModuleName(item)}</TextStyle>;
  const selectRadioOptions = (item) => (
    <Stack>
      <Stack.Item fill>
        <RadioButton
          label={cms("label.full")}
          checked={userInput[item].isFullAccess}
          onChange={(value) => handleChange(constant.IS_FULL, value, item)}
        />
      </Stack.Item>
      <Stack.Item fill>
        <RadioButton
          label={cms("label.read")}
          checked={userInput[item].isReadOnly}
          onChange={(value) => handleChange(constant.IS_READ, value, item)}
        />
      </Stack.Item>
      <Stack.Item>
        <RadioButton
          label={cms("label.hide")}
          checked={userInput[item].isHide}
          onChange={(value) => handleChange(constant.IS_HIDE, value, item)}
        />
      </Stack.Item>
    </Stack>
  );
  const getRows = () => {
    return Object.keys(userInput).map((item) => {
      const modules = selectModules(item);
      const options = selectRadioOptions(item);
      return [[modules], [options]];
    });
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  if (settingLoading || accessSettingLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <Layout>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            isScrollTop={banner.isOpen}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Modal
          open={activePopup}
          onClose={handlePopup}
          title={cms("title")}
          primaryAction={{
            content: cms("common.button.confirm"),
            onAction: onSubmit,
            loading: accessSettingLoading,
          }}
          secondaryActions={[
            {
              content: cms("common.button.close"),
              onAction: handlePopup,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>{cms("section.modal.description")}</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Toast message={successMessage} />
          <Card
            title={[
              cms("section.title"),
              updatedAt && (
                <TextStyle variation="subdued">
                  <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                </TextStyle>
              ),
            ]}
          >
            <Card.Section>
              <DataTable
                columnContentTypes={VENDOR_MODULE_COLUMN_CONTENTS}
                headings={VENDOR_MODULE_TABLE_HEADINGS}
                rows={getRows()}
              />
              {updatedAt && (
                <div className="manageVendorAccessButton">
                  <PageActions
                    primaryAction={{
                      content: cms("common.button.update"),
                      onAction: handleSubmit,
                      disabled: submitEnabled,
                    }}
                    secondaryActions={[
                      {
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      },
                    ]}
                  />
                </div>
              )}
            </Card.Section>
          </Card>
          {!updatedAt && (
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: handleSubmit,
                disabled: submitEnabled,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
        </Layout.AnnotatedSection>
      </Layout.Section>
    </Layout>
  );
};
export default withFeature(withErrorBoundary(ManageAccess), {
  feature: constant.STANDARD_VENDOR_ACCESS,
});
