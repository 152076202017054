import React, { useContext } from "react";
import { Card, TextField, TextContainer, FormLayout } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "app/productOld/modules/admin/features/add/context/context";

const TitleDescription = () => {
  const { cms } = useContext(PrivateContext);
  const { handleTiny, data, handleChange, learnMore } = useContext(FormContext);

  return (
    <Card
      title={cms("section.form.section.information.title")}
      id="productInformation"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productInformation", cms("section.form.section.information.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>{cms("section.form.section.information.description")}</TextContainer>
          <TextField
            id="title"
            label={`${cms("section.form.section.information.label.title")}*`}
            value={data.title || ""}
            onChange={(value) => handleChange(constant.TITLE, value)}
            placeholder={cms("section.form.section.information.placeholder.title")}
          />
          {`${cms("section.form.section.information.label.description")}*`}
          <Editor
            id="idTiny"
            textareaName="Description"
            value={data.tinyMCE}
            onEditorChange={handleTiny}
            init={{
              menubar: true,
              plugins: ["autolink link image lists print preview"],
              toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
              entity_encoding: "raw",
            }}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default TitleDescription;
