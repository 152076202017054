import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { bucketName, bucketAccessKey, bucketSecretKey } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  const { BUCKET_NAME, ACCESS_KEY_ID, SECRET_ACCESS_KEY } = constant;
  let errorMessage = "";
  switch (field) {
    case BUCKET_NAME:
      errorMessage = validateValue(bucketName(cms), { bucket: value });
      break;
    case ACCESS_KEY_ID:
      errorMessage = validateValue(bucketAccessKey(cms), { access: value });
      break;
    case SECRET_ACCESS_KEY:
      errorMessage = validateValue(bucketSecretKey(cms), { secret: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(errorMessage);
};

export default validate;
