import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

const Inventory = (props) => {
  const {
    variantData = {},
    handleChange = () => {},
    handleTitleChange = () => {},
    cms,
    setDisabledButton,
    inteventoryPolicy,
    ecommercePlatform,
  } = props;
  const { isManagingStock, quantity = 0, sku = " " } = variantData.inventoryId || {};
  const isDisabled = inteventoryPolicy?.label !== constant.SHOPIFY;

  const options = [
    { label: cms("section.inventory.label.noTrack"), value: constant.DO_NOT_TRACK_INVENTORY },
    { label: cms("section.inventory.label.shopify", { item: ecommercePlatform }), value: constant.SHOPIFY },
  ];

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && quantity);
  };

  return (
    <Card title={cms("section.inventory.title")} id="variantInventory" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="sku"
            label={cms("section.inventory.label.sku")}
            value={sku || ""}
            onChange={(value) => {
              handleChange("inventoryId.sku", value);
              setDisabledButton(false);
            }}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="quantity"
            label={cms("section.inventory.label.quantity")}
            disabled={isDisabled}
            placeholder={constant.ZERO_PRICE}
            value={(quantity && quantity.toString()) || ""}
            onChange={(value) => {
              handleChange("inventoryId.quantity", Number(acceptOnlyValidInput(value)));
              setDisabledButton(false);
            }}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            id="policySelection"
            label={cms("section.inventory.label.policy")}
            options={options}
            value={isManagingStock || !isDisabled ? constant.SHOPIFY : ""}
            onChange={(value) => {
              handleTitleChange(constant.INVENTORY_MANAGEMENT, value);
              setDisabledButton(false);
              if (value !== constant.SHOPIFY) {
                handleChange(constant.INVENTORY_QUANTITY, 0);
              }
            }}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default withErrorBoundary(Inventory);
