import { gql } from "apollo-boost";

const ADVANCE_ACCEPT_ORDER_EXPIRY = gql`
  mutation advanceAcceptOrderExpiry($input: AdvanceOrderExpiry) {
    advanceAcceptOrderExpiry(input: $input) {
      status
      data
      error
    }
  }
`;
export default ADVANCE_ACCEPT_ORDER_EXPIRY;
