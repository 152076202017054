import React, { useContext } from "react";

import { QuickLink, QuickReport } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";

import { PrivateContext } from "lib/context";
import { SkeletonList } from "lib/components";

import { Activity } from "app/userManagement/modules";
import { withErrorBoundary } from "lib/hoc";

const AdminDashboard = () => {
  const { isLoading } = useContext(PrivateContext);

  const getSecondaryComponent = () => (
    <>
      <QuickLink />
      <QuickReport />
    </>
  );
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return <Activity />;
  };
  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default withErrorBoundary(AdminDashboard);
