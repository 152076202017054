import React, { useState } from "react";
import { Card, Stack, TextStyle, Layout, Heading, Caption, Checkbox, PageActions } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

import { Banner, Toast } from "lib/components";

// import mutations
import { MANAGE_METAFIELD_KEY } from "app/productOld/apollo/mutations";

const OperatorMetafieldKeySetting = ({ metaketRefetch, keyUpdateAt, isEnabledData, history, setIsEnabledData }) => {
  
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [isEnableButton, setIsEnableButton] = useState(true);
  const [message, setMessage] = useState("");

  const [updateProductListData, { loading }] = useMutation(MANAGE_METAFIELD_KEY);

  const onCodeSubmit = async () => {
    setIsEnableButton(true);
    const formData = {
      isEnabled: isEnabledData,
    };
    try {
      const response = await updateProductListData({
        variables: {
          input: formData,
        },
      });

      const responseError = baseHelper.getResponseError(response.data, "manageMetaFieldKeys");

      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      if (!isEnabledData) {
        setMessage("Manage metafield key setting is disabled successfully.");
      } else {
        setMessage("Manage metafield key setting is enabled successfully.");
      }
      setTimeout(() => {
        metaketRefetch();
      }, 3000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    // setIsEnabledData(false);
  };

  const renderBanner = () => {
    const { status, title, isOpen } = banner;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  return (
    <>
      {banner.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection
        title="Namespace & Key"
        // eslint-disable-next-line max-len
        description="You can hide and manage to dyanamicly genrate name space and key for your metafield on the basis of their respective given display name."
      >
        <Card>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>
                <Heading variation="strong">Manage Namespace & Key</Heading>
                {keyUpdateAt && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(keyUpdateAt)}`}</Caption>
                  </TextStyle>
                )}
                <br />
                <TextStyle>
                  Please check the given checkbox to hide the namespace & key and to generate them dynamically.
                </TextStyle>
                {/* <b>{isEnabledData ? constant.ENABLED : constant.DISABLED}</b> */}
              </Stack.Item>
            </Stack>
            <br />
            <Checkbox
              label="Hide Namespace & Key From Metafields Setting"
              checked={isEnabledData}
              onChange={(val) => [setIsEnabledData(val), setIsEnableButton(false)]}
            />
            <div className="metafield-action-button">
              <PageActions
                primaryAction={{
                  content: keyUpdateAt ? "Update" : "Save",
                  onAction: () => onCodeSubmit(),
                  loading,
                  disabled: isEnableButton || loading,
                }}
                secondaryActions={[
                  {
                    onAction: () => history.push("/setting"),
                    content: "Cancel",
                  },
                ]}
              />
            </div>
          </Card.Section>
        </Card>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default OperatorMetafieldKeySetting;
