import constant from "lib/constant/constant";

const { providerList } = constant;
const productListCMS = (cms) => {
  return {
    tabs: [
      {
        id: "all-products",
        content: cms("label.productList.all"),
        accessibilityLabel: providerList.ALL_PRODUCTS,
        panelID: "all-products-content",
        value: providerList.ALL,
      },
      {
        id: "new-products",
        content: cms("label.productList.new"),
        panelID: "new-products-content",
        value: providerList.NEW,
      },
      {
        id: "review-products",
        content: cms("label.productList.review"),
        panelID: "review-products-content",
        value: providerList.PENDING,
      },
      {
        id: "reviewed-products",
        content: cms("label.productList.reviewed"),
        panelID: "reviewed-products-content",
        value: providerList.REVIEWED,
      },
      {
        id: "approved-products",
        content: cms("label.productList.approved"),
        panelID: "approved-products-content",
        value: providerList.APPROVED,
      },
      {
        id: "rejected-products",
        content: cms("label.productList.rejected"),
        panelID: "rejected-products-content",
        value: providerList.REJECTED,
      },
      {
        id: "deleted-products",
        content: cms("label.productList.deleted"),
        panelID: "deleted-products-content",
        value: providerList.DELETED,
      },
    ],
    sortOptions: [
      { label: cms("label.productList.selectSort"), value: "" },
      { label: cms("label.productList.dateAsc"), value: cms("label.productList.createdAtAsc") },
      { label: cms("label.productList.dateDesc"), value: cms("label.productList.createdAtDesc") },
      { label: cms("label.productList.productTitleAsc"), value: cms("label.productList.titleAsc") },
      { label: cms("label.productList.productTitleDesc"), value: cms("label.productList.titleDesc") },
    ],
    message: {
      defaultBanner: {
        title: cms("message.productList.defaultBanner.title"),
      },
    },
    resourceName: {
      singular: cms("label.productList.product"),
      plural: cms("label.productList.products"),
    },
  };
};

export default productListCMS;
