import { gql } from "apollo-boost";

const UPDATE_PRODUCT_LAKE_INVENTORY = gql`
  mutation updateProductLakeInventory($input: UpdateInventory) {
    updateProductLakeInventory(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_PRODUCT_LAKE_INVENTORY;
