// import constant
import constant from "lib/constant/constant";

// helper
import { yupHelper } from "lib/helpers";

// import schema
import { returnTermSchema, paymentTermSchema } from "./schema";

const { PAYMENT_TERMS_DAY, ALLOWED_DAY } = constant;
const { validateValue, handlePromiseError } = yupHelper;

const validateTerms = async (field, value, message) => {
  let promise = "";
  switch (field) {
    case ALLOWED_DAY:
      promise = validateValue(returnTermSchema(message), { allowedDay: value });
      break;
    case PAYMENT_TERMS_DAY:
      promise = validateValue(paymentTermSchema(message), { paymentTermsDay: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validateTerms;
