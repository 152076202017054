import React from "react";

import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { AutomaticPayouts } from "app/userManagement/modules/generic";

const OperatorAutomaticPayouts = () => {
  return <AutomaticPayouts />;
};

export default withFeature(withErrorBoundary(OperatorAutomaticPayouts), { feature: constant.PAYOUTS });
