import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import schema from "./schema";

const validate = async (field, value, cms) => {
  const { validateValue, handlePromiseError } = yupHelper;
  const { subjectSchema, messageSchema } = schema(cms);
  let promise = "";
  const { SUBJECT, MESSAGE } = constant;
  switch (field) {
    case SUBJECT:
      promise = validateValue(subjectSchema, { subject: value });
      break;
    case MESSAGE:
      promise = validateValue(messageSchema, { message: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
