import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// import context
import { PrivateContext } from "lib/context";
// import constant
import constant from "lib/constant/constant";
// import components
import { Banner, Spinner } from "lib/components";
// import query
import { GET_PRODUCT_EXPORT_SETTING } from "app/setup/apollo/queries";
import { GET_PRODUCT_DETAILS } from "app/productOld/apollo/queries";
// import helpers
import { baseHelper } from "lib/helpers";
import productHelper from "./helper/product";

const ExportAsCSV = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser } = data;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [csvDetails, setCSVDetails] = useState([]);
  const [hasRecord, setHasRecord] = useState(true);
  const [pdfCount, setPdfCount] = useState(0);
  const [getProductList, { data: getProductData, loading: productDetailsLoading, error: productError }] = useLazyQuery(
    GET_PRODUCT_DETAILS
  );

  const [getProductSetting, { data: getSettingData, loading: settingLoading, error: settingError }] = useLazyQuery(
    GET_PRODUCT_EXPORT_SETTING
  );

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  useEffect(() => {
    if (
      !productDetailsLoading &&
      !productError &&
      getProductData &&
      getSettingData &&
      !settingLoading &&
      !settingError
    ) {
      const resData = baseHelper.getResponseData(getProductData, constant.gql.GET_PRODUCT_DETAILS);
      const settingResponse = baseHelper.getResponseData(getSettingData, constant.gql.GET_PRODUCT_EXPORT_SETTING);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getProductData, constant.gql.GET_PRODUCT_DETAILS);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }

      const { productList = [], hasMore = false } = resData;
      setHasRecord(hasMore);
      if (!(productList && productList.length)) {
        bannerData = {
          title: cms("section.export.message.error.noProduct"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const csvData = productHelper.productCSVData(productList, settingResponse, true, false, isVendor, {}, false);
      if (csvData && csvData.length) {
        setPdfCount(pdfCount + 1);
        if (pdfCount > 0) {
          csvData.shift();
        }
        setCSVDetails([...csvDetails, ...csvData]);
      }

      if (hasMore) {
        setQueryData({ ...queryData, page: parseInt(queryData.page, 10) + 1 });
      }
    }
  }, [
    getProductData,
    productError,
    productDetailsLoading,
    cms,
    getSettingData,
    settingLoading,
    settingError,
    isVendor,
  ]);

  useEffect(() => {
    if (csvDetails && csvDetails.length > 1) {
      const csvData = Baby.unparse(csvDetails);
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      if (!hasRecord) {
        setTimeout(() => {
          window.close();
        }, 500);
        FileDownload(csvData, `${constant.CONSOLIDATED_PRODUCT} ${date}.csv`);
      }
    }
  }, [csvDetails]);

  useEffect(() => {
    getProductList({ variables: { input: queryData } });
  }, [queryData]);

  useEffect(() => {
    const exportProduct = () => {
      const dataToFetch = { page: 1 };
      const { endDate, startDate, status, vendor } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (startDate && endDate) {
        dataToFetch.dateRange = { start: startDate, end: endDate };
      }
      setQueryData(dataToFetch);
      getProductSetting();
    };
    if (isPageLoading) {
      exportProduct();
      setIsPageLoading(false);
    }
  }, [data, getProductSetting, isPageLoading]);

  return (
    <Layout.Section>
      {productDetailsLoading && <Spinner isFullPage />}
      {banner.isOpen && (
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
        />
      )}
      {/* <Banner
        title="Product export is in progress"
        status="info"
        isOpen={true}
      >
        <br />
        Please wait while we are exporting your products in a batch of 500. Please allow multiple downloads if there is a popup on your screen. <br /> <br />

        This tab will automatically close once all the downloads are completed.
      </Banner> */}
    </Layout.Section>
  );
};
export default withFeature(withErrorBoundary(ExportAsCSV), { feature: constant.EXPORT_PRODUCT });
