import { gql } from "apollo-boost";

const INVITE_DATA = gql`
  subscription {
    inviteCount {
      data {
        inviteCount {
          joined
          notJoined
          total
        }
      }
      error
      status
    }
  }
`;
const OPERATOR_DATA = gql`
  subscription {
    sellerCount {
      data {
        sellerCount {
          total
          new
          approved
        }
      }
      error
      status
    }
  }
`;
const ORDER_DATA = gql`
  subscription {
    orderCount {
      data {
        orderCount {
          approved
          new
          rejected
          shipped
          total
        }
      }
      error
      status
    }
  }
`;
const PAYMENT_DATA = gql`
  subscription {
    paymentCount {
      data {
        paymentCount {
          all
          paid
          unpaid
        }
      }
      error
      status
    }
  }
`;

const PRODUCT_DATA = gql`
  subscription {
    productCount {
      data {
        productCount {
          approved
          delete
          new
          reject
          review
          total
        }
      }
      error
      status
    }
  }
`;
const SHOPIFY_PRODUCT_DATA = gql`
  subscription {
    productCount {
      data {
        shopifyProductCount {
          all
          synced
          notSynced
        }
      }
      error
      status
    }
  }
`;
const PROVIDER_DATA = gql`
  subscription {
    vendorCount {
      data {
        vendorCount {
          approved
          new
          total
        }
      }
      error
      status
    }
  }
`;

export { ORDER_DATA, PRODUCT_DATA, PROVIDER_DATA, OPERATOR_DATA, INVITE_DATA, SHOPIFY_PRODUCT_DATA, PAYMENT_DATA };
