import React, { useContext, useState } from "react";
import { Card, FormLayout, PageActions, Select, Stack, TextField } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation } from "@apollo/react-hooks";
// import constant
import constant from "lib/constant/constant";
// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";
// import helper
import { baseHelper, errorHelper } from "lib/helpers";
// import hoc
import { withErrorBoundary } from "lib/hoc";
// import mutation
import { ADD_PRODUCT_LAKE } from "app/productLake/apollo/mutations";
import { OnboardingContext, PrivateContext } from "lib/context";
import { ProductContext } from "app/productLake/modules/generic/context";
// import proptype
import { tabProp } from "app/productLake/modules/generic/propTypes";

const Information = () => {
  const { isOnboarding, onNext, onPrevious } = useContext(OnboardingContext);
  const { handleTabChange, setBanner } = useContext(ProductContext);
  const { cms, currentUser, history } = useContext(PrivateContext);
  const { moneyFormat, ecommercePlatform } = currentUser;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedProductType, setSelectedProductType] = useState("");
  const [message, setMessage] = useState("");
  const { BIGCOMMERCE, CRITICAL, gql, INVENTORY } = constant;
  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT_LAKE);
  if (addProductLoading) {
    return <SkeletonCard />;
  }
  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };
  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };
  const handleTiny = (content) => {
    setDescription(content);
  };
  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };
  const addProduct = async (formValues) => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });
      const responseData = baseHelper.getResponseData(val.data, gql.ADD_PRODUCT_LAKE);
      const resError = baseHelper.getResponseError(val.data, gql.ADD_PRODUCT_LAKE);
      if (responseData) {
        if (isOnboarding) {
          onNext();
          return;
        }
        setMessage(cms("message.success.add"));
        setTimeout(() => {
          history.push(`edit/${encodeURIComponent(responseData)}?tab=${INVENTORY}`, { add: true });
        }, 1000);
      }
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const submitCallBack = async () => {
    const priceValue = parseFloat(price);
    const comparePriceValue = parseFloat(comparePrice);

    let productPrice = {
      regularPrice: (priceValue && priceValue.toString()) || "",
      salePrice: (comparePriceValue && comparePriceValue.toString()) || "",
    };
    if (baseHelper.isBigCommerceUser(currentUser)) {
      productPrice = {
        price: (priceValue && priceValue.toString()) || "",
        costPrice: (comparePriceValue && comparePriceValue.toString()) || "",
      };
    }

    const formValues = {
      name: title,
      description,
      type: selectedProductType,
      price: productPrice,
    };

    try {
      // add product
      const productId = await addProduct(formValues);
      if (productId) {
        handleTabChange(1);
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };
  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!title || title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: cms("message.error.title"),
      };
      setBanner(banner);
      return;
    }
    const errorField = "";
    if (errorField) {
      banner = {
        action: null,
        isOpen: true,
        status: CRITICAL,
        title: `${baseHelper.ucFirst(errorField)} is required.`,
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  let typeOptions = [
    { label: "Simple", value: "simple" },
    // { label: "Grouped", value: "grouped" },
    // { label: "External", value: "external" },
    { label: "Variable", value: "variable" },
  ];

  if (currentUser.ecommercePlatform === BIGCOMMERCE) {
    typeOptions = [
      { label: "Physical", value: "physical" },
      { label: "Digital", value: "digital" },
    ];
  }

  return (
    <>
      <Card title={cms("label.productInformation")} id="productInformation">
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              error=""
              value={title || ""}
              onChange={(value) => {
                setTitle(value);
                setDisabledButton(false);
              }}
              onBlur={() => title && setTitle(title.trim())}
            />
            {`${cms("label.description")}`}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={description || ""}
              onEditorChange={handleTiny}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            <FormLayout.Group condensed>
              <TextField
                id="price"
                label={cms("label.price")}
                min={0}
                value={price || ""}
                placeholder="0.00"
                prefix={moneyFormat}
                onChange={(value) => {
                  setPrice(acceptOnlyValidInput(value, price));
                  setDisabledButton(false);
                }}
              />
              <TextField
                id="comparePrice"
                label={cms("label.comparePrice")}
                min={0}
                placeholder="0.00"
                prefix={moneyFormat}
                value={comparePrice || ""}
                onChange={(value) => {
                  setComparePrice(acceptOnlyValidInput(value, comparePrice));
                  setDisabledButton(false);
                }}
              />
            </FormLayout.Group>
            <Stack vertical>
              {/* {productTypeOptions && productTypeOptions.length && ( */}
              <Select
                label={`${cms("label.type")}${ecommercePlatform ? "*" : ""}`}
                options={typeOptions}
                placeholder={cms("placeholder.selectProductType")}
                onChange={(value) => setSelectedProductType(value)}
                value={selectedProductType}
              />
              {/* )} */}
            </Stack>
            <br />
          </FormLayout>
          <div className="toast">
            <Toast message={message} />
          </div>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms(`common.button.${isOnboarding ? "submit" : "save"}`),
          onAction: () => onFormSubmit(),
          loading: addProductLoading,
          disabled: disabledButton || (ecommercePlatform && !selectedProductType),
        }}
        secondaryActions={
          isOnboarding && [
            {
              content: cms("common.button.previous"),
              onAction: onPrevious,
            },
          ]
        }
      />
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />
    </>
  );
};
Information.propTypes = tabProp.type;
export default withErrorBoundary(Information);
