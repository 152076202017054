import styled from "styled-components";

const StyledTable = styled.div`
  td,
  th {
    border-bottom: 0;
    vertical-align: middle;
    &:nth-child(3) {
      text-align: center;
      width: 25%;
    }
  }
  .Polaris-DataTable__Cell {
    position: relative;
  }
  .Polaris-InlineError {
    position: absolute;
    bottom: 0;
  }
`;
export default StyledTable;
