import { gql } from "apollo-boost";

const DELETE_ORDER_ACS_SETTING = gql`
  mutation deleteACSSetting($input: DeleteACSInput) {
    deleteACSSetting(input: $input) {
      data
      status
      error
    }
  }
`;

const UPDATE_ORDER_ACS_SETTING = gql`
  mutation updateACSSetting($input: UpdateACSInput) {
    updateACSSetting(input: $input) {
      data
      status
      error
    }
  }
`;

export { DELETE_ORDER_ACS_SETTING, UPDATE_ORDER_ACS_SETTING };
