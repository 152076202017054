import { gql } from "apollo-boost";

const UPDATE_VENDOR_EXPORT_SETTING = gql`
  mutation updateVendorExportSetting($input: VendorExportSetting) {
    updateVendorExportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_VENDOR_EXPORT_SETTING;
