import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  Frame,
  Icon,
  Layout,
  List,
  PageActions,
  RadioButton,
  Select,
  Stack,
  Tag,
  TextField,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import constant , helper and components
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

import { UPDATE_ADVANCE_METAFIELDS } from "app/product/apollo/mutations";
import { GET_CATALOG, GET_ADVANCE_METAFIELD } from "app/product/apollo/queries";
import { StyledPageAction } from "app/userManagement/modules/generic/digestSetting/styled";
import { ViewMajor } from "@shopify/polaris-icons";

const AdvanceMetaFields = ({ metaData }) => {
  const { cms, currentUser, history } = useContext(PrivateContext);

  const { gql } = constant;

  const [catalog, setCatalog] = useState([]);
  const [catalogOption, setCatalogOption] = useState([]);
  const [advanceValue, setAdvanceValue] = useState([{ type: "category" }]);

  const [createdAt, setCreatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });

  const [isSubmit, setIsSubmit] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [isAll, setIsAll] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const tags = ["Rustic", "Antique", "Vinyl", "Refurbished"];

  const handleChange = (val, index) => {
    const updateValue = advanceValue || [];
    updateValue[index].catalogId = Number(val);
    setAdvanceValue([...updateValue]);
  };

  const isVendor = baseHelper.isVendor(currentUser);

  const { data: catalogData, loading: catalogLoading, error: catalogError } = useQuery(GET_CATALOG);
  const { data: advanceData, loading: advanceLoading, error: advanceError, refetch } = useQuery(GET_ADVANCE_METAFIELD);

  const [updateAdvanceSetting, { loading }] = useMutation(UPDATE_ADVANCE_METAFIELDS);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(catalogData, gql.GET_CATALOG);
    const responseError = baseHelper.getResponseError(catalogData, gql.GET_CATALOG);
    if (responseData && responseData.catalog) {
      const filterCatalog = (responseData.catalog || []).map((item) => ({
        label: item.title,
        value: item.storeCatalogId,
      }));
      setCatalog(filterCatalog);
    }
    if (responseError) {
      setBanner({ status: constant.CRITICAL, title: responseError, isOpen: true });
    }

    if (catalogError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(catalogError), isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogData, catalogError]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(advanceData, "getAdvanceMetafieldSetting");
    const responseError = baseHelper.getResponseError(advanceData, "getAdvanceMetafieldSetting");
    if (responseData) {
      if (responseData.advanceMetafield) {
        const { isAll: isAllSetting, isEnabled, customSetting } = responseData.advanceMetafield || {};
        setAdvanceValue(customSetting || []);
        setIsAll(isAllSetting);
        setIsEnable(isEnabled);
        setIsSubmit(true);
      }
      setCreatedAt(responseData.updatedAt);
    }
    if (responseError) {
      setBanner({ status: constant.CRITICAL, title: responseError, isOpen: true });
    }

    if (advanceError) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(advanceError), isOpen: true });
    }
  }, [advanceData, advanceError]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  const handleSubmit = async (isDisable = false) => {
    const dataToUpdate = isDisable
      ? {
          isAll: true,
          isEnabled: false,
          customSetting: [],
        }
      : { isAll, isEnabled: isEnable, customSetting: advanceValue };
    if (isDisable) {
      setDisableLoading(true);
    }
    if (!isAll && advanceValue && !advanceValue.length) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: "Please add at least one row." });
      return null;
    }

    const advnceData = advanceValue.every(
      (advanceItem) => advanceItem.catalogId && advanceItem.metafields && advanceItem.metafields.length
    );
    if (!advnceData) {
      setBanner({ status: constant.CRITICAL, title: "Please complete the row details.", isOpen: true });
      return false;
    }

    try {
      const res = await updateAdvanceSetting({
        variables: {
          input: dataToUpdate,
        },
      });
      const resData = baseHelper.getResponseData(res.data, "updateAdvanceMetafield");
      let bannerValue = {
        isOpen: false,
        status: "",
        title: "",
      };

      if (resData) {
        setMessage(
          (isDisable && (cms("message.success.delete") || "Setting disabled successfully.")) ||
            cms("message.success.save") ||
            "Setting saved successfully"
        );
        bannerValue = { isOpen: false, status: "", title: "" };
        refetch();
      }
      if (!resData) {
        const error = baseHelper.getResponseError(res.data, "updateAdvanceMetafield");
        bannerValue = { isOpen: true, status: constant.CRITICAL, title: error };
      }
      setDisableLoading(false);
      setBanner({
        isOpen: bannerValue.isOpen,
        status: bannerValue.status,
        title: bannerValue.title,
      });
    } catch (exception) {
      setDisableLoading(false);
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
    return null;
  };

  const handleMetaChange = (val, index) => {
    const updateValue = advanceValue || [];
    if (updateValue[index] && updateValue[index].metafields) {
      updateValue[index].metafields = val;
    } else {
      updateValue[index].metafields = val;
    }
    setAdvanceValue([...updateValue]);
  };

  const remove = (index) => {
    const updatedData = advanceValue || [];
    updatedData.splice(index, 1);
    setAdvanceValue([...updatedData]);
  };

  const add = () => {
    const updatedData = advanceValue || [];
    // Need to add condition for extra rows
    const itemData = updatedData.every(
      (dataItem) => dataItem.catalogId && dataItem.metafields && dataItem.metafields.length
    );
    if (!itemData) {
      setBanner({ status: constant.CRITICAL, title: "Please complete the row details.", isOpen: true });
      return false;
    }
    updatedData.push({ type: "category" });
    setAdvanceValue([...updatedData]);
  };

  const handleRadioChange = (val) => {
    setIsAll(val);
    if (val) {
      setAdvanceValue([]);
    }

    if (!val) {
      setAdvanceValue([{ type: "category" }]);
    }
  };

  if (catalogLoading || advanceLoading) {
    return <SkeletonAnnotated />;
  }

  const options = (item) => {
    const selectedOptions = [];
    if (item) {
      (item || []).forEach((tag) => {
        const tagData = metaData.find((tagItem) => tagItem.value === tag);
        if (tagData) {
          selectedOptions.push(tagData);
        }
      });
    }
    return (
      <List type="bullet">
        {selectedOptions.map((items) => (
          <List.Item>{items.label}</List.Item>
        ))}
      </List>
    );
    // return selectedOptions;
  };
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  return (
    <>
      <Layout.AnnotatedSection
        title="Advanced Metafields"
        description="You can set advance metafields on the basis of categories."
      >
        <div className="toast">
          <Frame>
            <Toast message={message} />
          </Frame>
        </div>
        <Card>
          <Card.Header
            title={[
              <div>
                Advanced Metafields
                <Caption>
                  {createdAt && <TextStyle variation="subdued">{baseHelper.lastUpdateDate(createdAt)}</TextStyle>}
                </Caption>
              </div>,
            ]}
          >
            {!isEnable ? (
              <Button primary onClick={() => setIsEnable(true)}>
                Enable
              </Button>
            ) : (
              <Button
                destructive
                loading={disableLoading}
                onClick={() => {
                  if (isSubmit) {
                    handleSubmit(true);
                  }
                  if (!isSubmit) {
                    setIsEnable(false);
                  }
                }}
              >
                Disable
              </Button>
            )}
          </Card.Header>
          {!isEnable ? <br /> : ""}
          {/* </Card> */}
          {isEnable && (
            //   <Card>
            <Card.Section>
              <TextStyle>
                You can set metafields as per the product category. There's no limit on how many unique combinations you
                can add.
              </TextStyle>
              <br />
              <br />
              <Stack vertical>
                <RadioButton
                  label="Enable all metafields"
                  helpText="By selecting this, all the above mentioned metafields will be displayed for the products that do not have any category. Also for the below given category, mentioned metafield(s) will be displayed"
                  checked={isAll}
                  id="enabled"
                  name="meta"
                  onChange={() => handleRadioChange(true)}
                />
                <RadioButton
                  label="Enable metafields only for selected catagories"
                  helpText="By selecting this, only below selected metafield(s) will be displayed for the below mentioned categories. If the product do not have any category, no metafield will be displayed for the respective product."
                  id="disabled"
                  name="meta"
                  checked={isAll === false}
                  onChange={() => handleRadioChange(false)}
                />
              </Stack>
              <br />
              <>
                {banner.isOpen && (
                  <>
                    <Banner
                      isOpen={banner.isOpen}
                      status={banner.status}
                      title={banner.title}
                      isScrollTop={false}
                      onDismiss={() => dismissBanner()}
                    >
                      {banner.message}
                    </Banner>
                    <br />
                  </>
                )}
                {/* <div className="stack-item-5"> */}
                {/* <Stack wrap>
                  <Stack.Item>Type</Stack.Item>
                  <Stack.Item fill>Option</Stack.Item>
                  <Stack.Item fill>Metafield</Stack.Item>
                  <Stack.Item fill>View</Stack.Item>
                </Stack>
                <br /> */}
                {/* </div> */}
                {(advanceValue || []).map((item, index) => (
                  <>
                    {index ? <br /> : ""}
                    {/* <div className="stack-item-5"> */}
                    <Stack alignment="center" wrap>
                      {/* <Stack.Item>
                          <TextField value="category" disabled />
                        </Stack.Item> */}
                      <div className="catalog-label">
                        <Stack.Item fill>
                          <Select
                            value={item?.catalogId || ""}
                            label={!index ? "Collections" : ""}
                            placeholder="Select Catalog"
                            options={catalog}
                            onChange={(val) => handleChange(val, index)}
                          />
                        </Stack.Item>
                      </div>
                      <Stack.Item fill>
                        <Autocomplete
                          allowMultiple
                          options={metaData || []}
                          selected={item?.metafields || []}
                          textField={
                            <Autocomplete.TextField
                              // onChange={updateText}
                              label={!index ? "Metafields" : ""}
                              // value={inputValue}
                              // placeholder="Vintage, cotton, summer"
                              placeholder="Display Name"
                              verticalContent={
                                (item?.metafields || []).length ? (
                                  <Stack spacing="extraTight" alignment="center">
                                    {tags.map((tag) => (
                                      <Tag key={tag}>{tag}</Tag>
                                    ))}
                                  </Stack>
                                ) : null
                              }
                            />
                          }
                          onSelect={(val) => handleMetaChange(val, index)}
                          listTitle="Metafields"
                        />
                      </Stack.Item>
                      <Stack.Item>
                        {/* <Stack> */}
                        {/* {(item.metafields || []).length ? (
                            <Stack spacing="extraTight" alignment="center">
                              {item.metafields.map((tag) => {
                                const tagData = metaData.find((tagItem) => tagItem.value === tag);
                                if (tagData) {
                                  return <Tag key={tag}>{tagData.label}</Tag>;
                                }
                                return null;
                              })}
                            </Stack>
                          ) : null} */}
                        {/* <Select
                            value={item?.catalogId || ""}
                            placeholder="Selected"
                            options={options(item?.metafields) || []}
                            // onChange={(val) => handleChange(val, index)}
                          /> */}
                        {!index ? <br /> : null}
                        <Tooltip
                          preferredPosition="mostSpace"
                          active={isShow}
                          content={item?.metafields ? options(item?.metafields) : null}
                        >
                          <Button plain onClick={() => setIsShow(!isShow)}>
                            <Icon source={ViewMajor} color="primary" />
                          </Button>
                        </Tooltip>
                        {/* </Stack> */}
                      </Stack.Item>
                      <Stack.Item>
                        {!index ? <br /> : ""}
                        <Button id="deleteButton" plain onClick={() => remove(index)}>
                          <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                        </Button>
                      </Stack.Item>
                    </Stack>
                    {/* </div> */}
                  </>
                ))}
                <div className="equal-stack-item">
                  <Button plain id="addLink" onClick={add}>
                    + Add more
                  </Button>
                </div>
              </>
              {isEnable && isSubmit && (
                <StyledPageAction>
                  <div className="metafield-action-advance-button">
                    <PageActions
                      secondaryActions={{
                        content: cms("common.button.cancel"),
                        onAction: () => history.push("/setting"),
                      }}
                      primaryAction={{
                        content: cms("common.button.update"),
                        onAction: () => handleSubmit(),
                        disabled: !isEnable || loading,
                        loading: loading && !disableLoading,
                      }}
                    />
                  </div>
                </StyledPageAction>
              )}
            </Card.Section>
            //   </Card>
          )}
        </Card>
        {isEnable && !isSubmit && (
          <StyledPageAction>
            <PageActions
              secondaryActions={{
                content: cms("common.button.cancel"),
                onAction: () => history.push("/setting"),
                disabled: loading,
              }}
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => handleSubmit(),
                disabled: !isEnable || loading,
                loading: loading && !disableLoading,
              }}
            />
          </StyledPageAction>
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

export default AdvanceMetaFields;
