import React, { useContext } from "react";
import PropTypes from "prop-types";

import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import ClickCollect from "./subFeature/clickCollect/clickCollect";
import ClickReserve from "./subFeature/clickReserve/clickReserve";
import TimeService from "./subFeature/timeService/timeService";
import ConsignmentPickup from "./subFeature/consignmentPickup/consignmentPickup";
import ConsignmentShip from "./subFeature/consignmentShip/consignmentShip";
import HybridDropShip from "./subFeature/hybridDropShip/hybridDropShip";
import ConsignmentShipMethod from "../consignmentShip/consignmentShippingMethod";

const Hybrid = ({ type }) => {
  const { selectedTab, hybridDropShipTabs } = useContext(FulfillmentContext);

  const {
    HYBRID_DROPSHIP,
    HYBRID_CONSIGNMENT_SHIP,
    HYBRID_CONSIGNMENT_PICKUP,
    HYBRID_CLICK_RESERVE,
    HYBRID_CLICK_COLLECT,
    HYBRID_TIME_SERVICE,
  } = constant;

  const dropshipIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_DROPSHIP);
  const consignmentShipIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_CONSIGNMENT_SHIP);
  const consignmentPickupIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_CONSIGNMENT_PICKUP);
  const clickReserveIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_CLICK_RESERVE);
  const clickCollectIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_CLICK_COLLECT);
  const timeServiceIndex = hybridDropShipTabs.findIndex((item) => item.id === HYBRID_TIME_SERVICE);

  return (
    <>
      {selectedTab === dropshipIndex && type === constant.HYBRID && <HybridDropShip method="dropShip" type="hybrid" />}
      {selectedTab === consignmentShipIndex && type === constant.HYBRID && (
        <ConsignmentShipMethod method="consignmentShipMethod" type="hybrid" />
      )}
      <br />
      <br />
      {selectedTab === consignmentShipIndex && type === constant.HYBRID && (
        <ConsignmentShip method="defaultConsignmentShip" />
      )}
      {selectedTab === consignmentPickupIndex && type === constant.HYBRID && (
        <ConsignmentPickup method="HybridConsignmentPickup" />
      )}
      {selectedTab === clickCollectIndex && type === constant.HYBRID && <ClickCollect method="hybridClickCollect" />}
      {selectedTab === clickReserveIndex && type === constant.HYBRID && <ClickReserve method="hybridClickReserve" />}
      {selectedTab === timeServiceIndex && type === constant.HYBRID && <TimeService method="hybridTimeService" />}
    </>
  );
};

Hybrid.propTypes = {
  type: PropTypes.string.isRequired,
};

export default withFeature(withErrorBoundary(Hybrid), { feature: constant.HYBRID });
