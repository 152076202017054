/* eslint-disable react/jsx-curly-newline */
// import packages
import React from "react";
import { FormLayout, Card, TextField, Stack, Checkbox } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const {
  INVENTORY_QUANTITY = "",
  PRICE = "",
  REGULAR_PRICE = "",
  SKU = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999 },
} = constant;

const VariantField = (props) => {
  const {
    variantValues,
    variants,
    validVariants,
    option1,
    option2,
    option3,
    handleValidVariants,
    variantValueChange,
    cms,
    currentUser,
    data,
  } = props;
  const key = {
    temp: 0,
  };

  const { inventoryId, priceId } = data;
  let price = "";
  let quantity = "";
  let sku = "";
  if (priceId) {
    price = priceId.price || "";
  }

  if (inventoryId) {
    quantity = inventoryId.quantity || 0;
    sku = inventoryId.sku || "";
  }

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  const acceptOnlyWholeNumber = (val, inventoryQuantity) => {
    return (baseHelper.validateWholeNumber(val) && val) || (val !== "" && inventoryQuantity);
  };

  const variantRows = variants.map((variant, idx) => {
    const inventoryQuantity = variantValues[idx] && variantValues[idx].inventoryQuantity;
    const item = { ...variants[idx] };
    key.temp += 1;

    const variantPrice =
      (baseHelper.isBigCommerceUser(currentUser)
        ? variantValues[idx].price && variantValues[idx].price.toString()
        : variantValues[idx].regularPrice && variantValues[idx].regularPrice.toString()) || "";

    return (
      <Card.Section subdued key={key.temp}>
        <Stack wrap={false}>
          <Stack.Item>
            <Checkbox
              checked={!!validVariants[idx]}
              id="variant"
              labelHidden
              label={cms("label.productVariant")}
              onChange={() => handleValidVariants(idx)}
            />
          </Stack.Item>
          <Stack.Item vertical fill>
            {item.option1 && (
              <Stack.Item>
                <div className="ellipsis">{`${option1}: ${item.option1}`}</div>
              </Stack.Item>
            )}
            {item.option2 && (
              <Stack.Item>
                <div className="ellipsis">{`${option2}: ${item.option2}`}</div>
              </Stack.Item>
            )}
            {item.option3 && (
              <Stack.Item>
                <div className="ellipsis">{`${option3}: ${item.option3}`}</div>
              </Stack.Item>
            )}
          </Stack.Item>
        </Stack>
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label={cms("label.price")}
              id="price"
              type="number"
              min={MIN_PRICE}
              max={MAX_FLAT}
              value={(variantValues[idx] && variantPrice) || price}
              onChange={(val) =>
                variantValueChange(
                  baseHelper.isBigCommerceUser(currentUser) ? PRICE : REGULAR_PRICE,
                  acceptOnlyValidInput(val),
                  idx
                )
              }
            />
            <TextField
              label={cms("label.sku")}
              id="variantSku"
              value={(variantValues[idx] && variantValues[idx].sku) || sku}
              onChange={(val) => variantValueChange(SKU, val, idx)}
            />
            <TextField
              id="variantInventory"
              label={cms("label.inventory")}
              min={MIN_PRICE}
              max={MAX_FLAT}
              value={
                (variantValues[idx] &&
                  variantValues[idx].inventoryQuantity &&
                  variantValues[idx].inventoryQuantity.toString()) ||
                quantity.toString()
              }
              onChange={(val) =>
                variantValueChange(INVENTORY_QUANTITY, acceptOnlyWholeNumber(val, inventoryQuantity), idx)
              }
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    );
  });
  return variantRows;
};

export default withErrorBoundary(VariantField);
