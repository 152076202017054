import yupHelper from "lib/helpers/yup";
import constant from "lib/constant/constant";

import { emailSchema, accountTypeSchema, brandNameSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const { EMAIL, ACCOUNT_TYPE, BRAND_NAME } = constant;
const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case EMAIL:
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case ACCOUNT_TYPE:
      promise = validateValue(accountTypeSchema(cms), { accountType: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(cms), { brandName: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
