import React from "react";
import { useMutation } from "react-apollo";
import PropTypes from "prop-types";
import { Modal, TextContainer } from "@shopify/polaris";

// import from lib.
import { baseHelper, errorHelper } from "lib/helpers";

// import GQL.
import { SYNC_ORDER } from "app/orders/apollo/mutations";
import constant from "lib/constant/constant";

function SyncOrder(props) {
  const { cms, isSyncOpen, setIsSyncModal, setBanner, refetch } = props;
  const [SyncOrders, { loading }] = useMutation(SYNC_ORDER);
  const handleCloseModal = () => {
    setIsSyncModal(false);
  };
  const handleSyncOrder = async () => {
    await SyncOrders()
      .then((res) => {
        setIsSyncModal(false);
        const responseData = baseHelper.getResponseData(res.data, constant.gql.SYNC_ORDER);
        const responseDataError = baseHelper.getResponseError(res.data, constant.gql.SYNC_ORDER);

        if (responseData) {
          refetch();
          setBanner({
            isOpen: true,
            status: "success",
            title: cms("message.success.updateSuccess"),
          });
        }
        if (responseDataError) {
          setBanner({
            isOpen: true,
            status: "critical",
            title: responseDataError,
          });
        }
      })
      .catch((exception) => {
        setIsSyncModal(false);
        setBanner({
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
        });
      });
  };
  return (
    <>
      <Modal
        open={isSyncOpen}
        onClose={handleCloseModal}
        title={cms("section.syncOrder.label.modalLabel")}
        primaryAction={{
          content: cms("section.syncOrder.button.syncOrder"),
          onAction: handleSyncOrder,
          destructive: true,
          disabled: loading,
          loading,
        }}
        secondaryActions={[
          {
            content: cms("section.syncOrder.button.cancel"),
            onAction: handleCloseModal,
            disabled: loading,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("message.success.modalMessage")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
}

SyncOrder.defaultProps = {
  cms: () => {},
  isSyncOpen: false,
  setIsSyncModal: () => {},
  setBanner: () => {},
  refetch: () => {},
};

SyncOrder.propTypes = {
  cms: PropTypes.func,
  isSyncOpen: PropTypes.bool,
  setIsSyncModal: PropTypes.func,
  setBanner: PropTypes.func,
  refetch: PropTypes.func,
};

export default SyncOrder;
