import { gql } from "apollo-boost";

const GET_VENDOR_VARIANTS = gql`
  query getProduct($input: GetProduct) {
    getProduct(input: $input) {
      data {
        _id
        sku
        variants {
          option1Val
          option2Val
          option3Val
          option3
          option1
          option2
          barcode
          weightUnit
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
          comparePrice
          image
          inventoryManagement
          inventoryQuantity
          isShipping
          isTaxable
          price
          sku
          weight
          vendorDiscount {
            price
            type
          }
        }
        images {
          url
          imageId
          imageUrl
          position
        }
      }
      error
      status
    }
  }
`;

export default GET_VENDOR_VARIANTS;
