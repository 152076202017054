import React from "react";

import { Layout } from "@shopify/polaris";
import PropTypes from "prop-types";

const TwoColumnLayout = (props) => {
  const { primary = null, secondary = null } = props;
  return (
    <>
      <Layout.Section>{primary}</Layout.Section>
      <Layout.Section secondary>{secondary}</Layout.Section>
    </>
  );
};

TwoColumnLayout.propTypes = {
  primary: PropTypes.shape(PropTypes.object).isRequired,
  secondary: PropTypes.shape(PropTypes.object).isRequired,
};

export default TwoColumnLayout;
