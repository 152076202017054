// import packages
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, PageActions, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";

// constant
import constant from "lib/constant/constant";

// helper components
import { Banner } from "lib/components";
import { Footer } from 'layout/public/components';

// contexts
import { PublicContext } from "lib/context";
import config from "configuration";

// import gql
import { FETCH_DOMAIN, GET_BRANDS } from "app/public/apollo/queries";
import { RESET_PASSWORD } from "app/public/apollo/mutations";

// yup validation
import validate from "./yup";

// sub-features
import { AccountType, Brand } from "./subFeatureItems";

const ResetPassword = () => {
  const { cms, history } = useContext(PublicContext);
  const { EMAIL, ACCOUNT_TYPE, BRAND_NAME, SHOW_BRAND_NAME, SHOW_ACCOUNT_TYPE, VENDOR, gql } = constant;
  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  // states
  const [values, setValues] = useState({
    email: "",
    isEmailDisable: false,
    isAccountTypeDisable: false,
    accountType: "",
    brandName: "",
  });
  const [showFields, setShowFields] = useState({
    showAccountType: false,
    showBrandName: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [userBrands, setUserBrands] = useState({
    seller: [],
    vendor: [],
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitButton, setSubmitState] = useState(false);
  const [fetchNewAccount, setFetchNewAccount] = useState(true);
  const [customDomain, setCustomDomain] = useState([]);

  // gql states
  const [getAccount, { data: accountData, loading: getBrandLoading }] = useLazyQuery(GET_BRANDS);
  const [resetPassword, { loading, data }] = useMutation(RESET_PASSWORD);

  const updateSubmitButton = useCallback(
    (field) => {
      const isAnyValidationError = errorMessage && !!errorMessage[field];
      const isAllValuesFilled = values[field];
      setSubmitState(!!isAllValuesFilled && !isAnyValidationError);
    },
    [errorMessage, values]
  );

  useEffect(() => {
    if (errorMessage) {
      if (!(showFields.showAccountType || showFields.showBrandName)) {
        updateSubmitButton(EMAIL);
      }
      if (showFields.showAccountType) {
        updateSubmitButton(ACCOUNT_TYPE);
      }
      if (showFields.showBrandName) {
        updateSubmitButton(BRAND_NAME);
      }
    }
  }, [showFields, errorMessage, updateSubmitButton, EMAIL, ACCOUNT_TYPE, BRAND_NAME]);

  const setFieldStatus = useCallback((field, value) => {
    setShowFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    setFieldStatus(SHOW_BRAND_NAME, false);
    if (
      (values.accountType === constant.SELLER || values.accountType === constant.VENDOR) &&
      showFields.showAccountType
    ) {
      setFieldStatus(SHOW_BRAND_NAME, true);
    }
  }, [
    SHOW_BRAND_NAME,
    showFields.showBrandName,
    values.accountType,
    userBrands,
    setFieldStatus,
    showFields.showAccountType,
  ]);

  const handleChange = useCallback(async (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const sendEmail = useCallback(
    async (accountType = "", brandName = "") => {
      const inputData = {};
      inputData.email = values.email && values.email.toLowerCase();
      inputData.userType = accountType || values.accountType;
      if (values && values.accountType === constant.SELLER) {
        inputData.brandName = brandName || values.brandName;
      }
      if (values && values.accountType === constant.VENDOR) {
        inputData.introductorySeller = brandName || values.brandName;
      }
      try {
        const res = await resetPassword({
          variables: { input: inputData },
        });
        const resData = baseHelper.getResponseData(res.data, gql.FORGET_PASSWORD);
        let bannerData = { title: cms("message.success.mail"), status: constant.SUCCESS };
        let isMailSend = true;
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.FORGET_PASSWORD);
          bannerData = { title: resError, status: constant.CRITICAL };
          isMailSend = false;
        }
        setBanner({
          isOpen: true,
          isScrollTop: true,
          status: bannerData.status,
          title: bannerData.title,
        });
        if (isMailSend) {
          setTimeout(() => history.push("/login"), 1000);
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          isScrollTop: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    },
    [values, resetPassword, gql.FORGET_PASSWORD, cms, history]
  );

  const listBrand = useCallback((response) => {
    setUserBrands((prevState) => ({
      ...prevState,
      seller: response.getBrands.data.shops,
      vendor: response.getBrands.data.associatedSellers,
    }));
  }, []);

  const showSellerStore = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_BRAND_NAME, true);
    },
    [SHOW_BRAND_NAME, listBrand, setFieldStatus]
  );
  const showAccountAndBrand = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_ACCOUNT_TYPE, true);
    },
    [SHOW_ACCOUNT_TYPE, listBrand, setFieldStatus]
  );
  const userStoreList = useCallback(
    async (response) => {
      const resData = baseHelper.getResponseData(response, gql.GET_BRANDS);
      const resError = baseHelper.getResponseError(response, gql.GET_BRANDS);
      const isSellerRole = resData && !!resData.shops.length;
      const isVendorRole = resData && !!resData.associatedSellers.length;
      if (isVendorRole && resData.associatedSellers) {
        setIsVendor(!!resData.associatedSellers.filter((item) => item).length);
      }
      if (isSellerRole && resData.shops) {
        setIsSeller(!!resData.shops.filter((item) => item).length);
      }
      const isBothRole = resData && !!resData.shops.length && !!resData.associatedSellers.length;
      if (resData) {
        setBanner({ isOpen: false });
      }
      if (isSellerRole && isVendorRole) {
        showAccountAndBrand(response);
      }
      if (isSellerRole && !isVendorRole) {
        showSellerStore(response);
        const sellerBrandName = resData && resData.shops && resData.shops.length && resData.shops[0].value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.SELLER,
          brandName: sellerBrandName,
        }));
        if (resData.shops.length) {
          setFieldStatus(SHOW_ACCOUNT_TYPE, true);
        }
      }
      if (!isSellerRole && isVendorRole) {
        showSellerStore(response);
        const vendorBrandName =
          resData &&
          resData.associatedSellers &&
          resData.associatedSellers.length &&
          resData.associatedSellers[0].value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.VENDOR,
          brandName: vendorBrandName,
        }));
        if (resData.associatedSellers.length) {
          setFieldStatus(SHOW_ACCOUNT_TYPE, true);
        }
      }
      if ((!isSellerRole && !isVendorRole && !isBothRole) || resError) {
        setValues((prevState) => ({
          ...prevState,
          email: "",
        }));
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: constant.CRITICAL,
          title: resError || cms("common.message.error.unauthorized"),
        }));
        return;
      }
      setValues((prevState) => ({
        ...prevState,
        isEmailDisable: true,
      }));
    },
    [gql.GET_BRANDS, cms, showAccountAndBrand, showSellerStore, SHOW_ACCOUNT_TYPE, setFieldStatus]
  );
  useEffect(() => {
    if (!loading && !getBrandLoading && isLoading) {
      if (data) {
        const resData = baseHelper.getResponseData(data, gql.FORGET_PASSWORD);
        let bannerData = { title: cms("message.success.mail"), status: constant.SUCCESS };
        if (!resData) {
          const resError = baseHelper.getResponseError(data, gql.FORGET_PASSWORD);
          bannerData = { title: resError, status: constant.CRITICAL };
        }
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: bannerData.status,
          title: bannerData.title,
        }));
      }
      setIsLoading(false);
    }
  }, [data, accountData, loading, getBrandLoading, isLoading, gql.FORGET_PASSWORD, cms, userStoreList]);

  useEffect(() => {
    if (accountData && !isEmailSent && fetchNewAccount) {
      userStoreList(accountData);
      setFetchNewAccount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  const fetchAccountTypes = async () => {
    setIsLoading(true);
    const isAnyValidationError = errorMessage && !!errorMessage.email;
    const isAllValuesFilled = values.email;
    if (!isAnyValidationError && isAllValuesFilled) {
      try {
        getAccount({ variables: { input: { email: values.email } } });
      } catch (exception) {
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        }));
      }
    }
  };

  const fetchAccounts = () => {
    setIsLoading(true);
    setValues((prevState) => ({
      ...prevState,
      isAccountTypeDisable: true,
    }));
    setFieldStatus(SHOW_BRAND_NAME, true);
  };

  const displayBrands = (role) => {
    if (role === constant.VENDOR) {
      return userBrands.vendor.length ? userBrands.vendor : [];
    }
    if (role === constant.SELLER) {
      return userBrands.seller.length ? userBrands.seller : [];
    }
    return false;
  };

  const renderBrandName = () => {
    if (!showFields.showBrandName) {
      return null;
    }
    return (
      <Brand
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        brandName={values.brandName}
        brands={displayBrands(values.accountType)}
        cms={cms}
      />
    );
  };

  const renderAccountType = () => {
    if (!showFields.showAccountType) {
      return null;
    }
    return (
      <AccountType
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        accountType={values.accountType}
        isVendor={isVendor}
        isSeller={isSeller}
        isAccountTypeDisable={values.isAccountTypeDisable}
        cms={cms}
      />
    );
  };

  const onSubmit = async () => {
    const isEmailSubmit = !(showFields.showAccountType || showFields.showBrandName);
    const isAccountTypeSubmit = showFields.showAccountType;
    const isBrandNameSubmit = showFields.showBrandName;
    if (isEmailSubmit) {
      await handleValidation(EMAIL, values.email);
      fetchAccountTypes();
      setFetchNewAccount(true);
    }
    if (isAccountTypeSubmit) {
      await handleValidation(ACCOUNT_TYPE, values.accountType);
      fetchAccounts();
    }
    if (isBrandNameSubmit) {
      await handleValidation(BRAND_NAME, values.brandName);
      setIsEmailSent(true);
      sendEmail();
    }
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => dismissBanner()} />
      <br />
      <Layout>
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                label={`${cms("common.label.email")}*`}
                value={values.email || ""}
                placeholder={cms("common.placeholder.emailAddress")}
                onChange={(value) => handleChange(EMAIL, value)}
                onBlur={() => handleValidation(EMAIL, values.email)}
                disabled={values.isEmailDisable}
                error={errorMessage && errorMessage.email}
              />
              {renderAccountType()}
              {renderBrandName()}
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "resetPassword",
              content: cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton,
              loading: loading || getBrandLoading,
            }}
            secondaryActions={{
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/login"),
            }}
          />
        </Layout.AnnotatedSection>
      </Layout>
      <Footer/>
    </>
  );
};

export default withErrorBoundary(ResetPassword);
