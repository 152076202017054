import { gql } from "apollo-boost";

const UPDATE_VARIANT_IMAGE = gql`
  mutation updateVariantImage($input: UpdateVariant) {
    updateVariantImage(input: $input) {
      data {
        id
        position
      }
      status
      error
    }
  }
`;
export default UPDATE_VARIANT_IMAGE;
