import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Banner as PolarisBanner } from "@shopify/polaris";

const Banner = (props) => {
  // NOTE: Add more props as per your need
  const {
    action = null,
    children = null,
    isOpen = false,
    isScrollTop = true,
    onDismiss = null,
    status = "",
    title = "",
    id = "",
  } = props;
  useEffect(() => {
    if (isOpen && isScrollTop) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [isScrollTop, isOpen]);
  if (!isOpen) {
    return null;
  }
  return (
    <span id={id}>
      <PolarisBanner status={status} title={title} onDismiss={onDismiss} action={action}>
        {children}
      </PolarisBanner>
    </span>
  );
};

Banner.propTypes = {
  action: PropTypes.shape({
    content: PropTypes.string.isRequired,
    onAction: PropTypes.func.isRequired,
  }),
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isScrollTop: PropTypes.bool,
  onDismiss: PropTypes.func,
  status: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
};
Banner.defaultProps = {
  action: null,
  children: null,
  isOpen: false,
  isScrollTop: true,
  onDismiss: null,
  status: "",
  title: "",
  id: "polarisBanner",
};

export default Banner;
