import { gql } from "apollo-boost";

const EDIT_SELLER_PLAN = gql`
  mutation updateSellerPlan($input: UpdateSellerPlanInput) {
    updateSellerPlan(input: $input) {
      data
      status
      error
    }
  }
`;
export default EDIT_SELLER_PLAN;
