import React, { useContext } from "react";
import { Card, RadioButton, Select, Stack, TextField } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import propType
import { PrivateContext } from "lib/context";
import { offerConditionProp } from "app/shipping/modules/operator/features/offer/propTypes";

const RenderOfferCondition = (props) => {
  const {
    handleConditionChange,
    isConditionApplied,
    shippingOffer,
    handleCriteria,
    handleCondition,
    moneyFormat,
  } = props;
  const { cms } = useContext(PrivateContext);
  const isProductPrice = shippingOffer.condition === constant.PRODUCT_PRICE;
  const isProductQuantity = shippingOffer.condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = shippingOffer.condition === constant.ORDER_VALUE;

  const criteriaText =
    (isProductPrice && cms("offer.add.section.condition.label.priceShouldBe")) ||
    (isProductQuantity && cms("offer.add.section.condition.label.quantityShouldBe")) ||
    cms("offer.add.section.condition.label.orderValueShouldBe");

  const criteriaValues =
    (isProductPrice && shippingOffer.productPrice) ||
    (isProductQuantity && shippingOffer.productQuantity) ||
    (isOrderValue && shippingOffer.orderValue) ||
    {};

  const criteriaKey =
    (isProductPrice && constant.PRODUCT_PRICE) ||
    (isProductQuantity && constant.PRODUCT_QUANTITY) ||
    constant.ORDER_VALUE;

  const conditionOptions = [
    {
      key: constant.PRODUCT_PRICE,
      label: cms("label.productPrice"),
      value: constant.PRODUCT_PRICE,
    },
    {
      key: constant.PRODUCT_QUANTITY,
      label: cms("label.productQuantity"),
      value: constant.PRODUCT_QUANTITY,
    },
    {
      key: constant.ORDER_VALUE,
      label: cms("label.orderValue"),
      value: constant.ORDER_VALUE,
    },
  ];

  const offerCriteriaOptions = [
    { key: constant.GREATER_THAN, label: cms("label.greaterThan"), value: constant.GREATER_THAN },
    { key: constant.LESS_THAN, label: cms("label.lessThan"), value: constant.LESS_THAN },
  ];

  return (
    <Stack.Item>
      <Card sectioned title={cms("modal.offer.add.section.condition.label.offerCondition")}>
        <Stack vertical>
          <RadioButton
            id="allConditions"
            name="allConditions"
            label={cms("modal.offer.add.section.condition.label.allConditions")}
            helpText={cms("modal.offer.add.section.condition.caption.allCondition")}
            checked={!isConditionApplied}
            onChange={handleConditionChange}
          />
          <RadioButton
            id="selectedConditions"
            name="selectedConditions"
            label={cms("modal.offer.add.section.condition.label.selectedCondition")}
            helpText={cms("modal.offer.add.section.condition.caption.selectedCondition")}
            checked={isConditionApplied}
            onChange={handleConditionChange}
          />
          {isConditionApplied && (
            <Stack.Item>
              <Stack.Item>
                <Select
                  id="shippingCondition"
                  name="shippingCondition"
                  options={conditionOptions}
                  placeholder={`${cms("modal.offer.add.section.condition.placeHolder.selectShippingOffer")} *`}
                  value={shippingOffer.condition || ""}
                  onChange={handleCondition}
                />
              </Stack.Item>
              {(isProductPrice || isProductQuantity || isOrderValue) && (
                <Stack.Item>
                  <br />
                  <Stack>
                    <Stack.Item>{criteriaText}</Stack.Item>
                    <Stack.Item>
                      <Select
                        id="criteria"
                        key="criteria"
                        options={offerCriteriaOptions}
                        placeholder={cms("modal.offer.add.section.condition.placeHolder.selectCriteria")}
                        value={criteriaValues.criteria || ""}
                        onChange={(val) => handleCriteria(criteriaKey, "criteria", val)}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <TextField
                        id="criteriaValue"
                        type="number"
                        placeholder={cms("modal.offer.add.section.condition.placeHolder.selectCriteria")}
                        value={(criteriaValues.value && criteriaValues.value.toString()) || ""}
                        min={constant.value.MIN_PRICE}
                        step={isProductPrice || isOrderValue ? constant.value.STEP : constant.value.MIN_PRICE}
                        prefix={(isProductPrice || isOrderValue) && moneyFormat}
                        onChange={(val) => handleCriteria(criteriaKey, "value", val)}
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              )}
            </Stack.Item>
          )}
        </Stack>
      </Card>
    </Stack.Item>
  );
};

RenderOfferCondition.propTypes = offerConditionProp.type;

export default RenderOfferCondition;
