import { gql } from "apollo-boost";

const GET_ORDER_LIVE_CONNECT_SETTING = gql`
  query {
    getOrderLiveConnectSetting {
      data {
        isShippingAddress
        isOrderNote
        isBillingAddress
        isCustomer
      }
      status
      error
    }
  }
`;

export default GET_ORDER_LIVE_CONNECT_SETTING;
