import * as yup from "yup";

const reasonSchema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
});

const noteSchema = yup.object().shape({
  note: yup.string().required("Note is required"),
});

export { reasonSchema, noteSchema };
