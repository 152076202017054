import { gql } from "apollo-boost";

const BRAND_DETAILS = gql`
  query getBrandDetails($input: VendorProfileData!) {
    getBrandDetails(input: $input) {
      data {
        vendorDetails {
          payment {
            bank
            country
            sortCode
            account
          }
          shipping {
            address
            city
            country
            isBizAddress
            pinCode
          }
          customFields {
            inputType
            key
            label
            placeholder
            value
            imageUrls
          }
          brand {
            imageId
            imageUrl
          }
          description
          socialLink {
            youtube
            facebook
            twitter
            instagram
            linkedin
          }
        }
      }
      status
      error
    }
  }
`;
export default BRAND_DETAILS;
