import { gql } from "apollo-boost";

const GET_ORDER_DETAIL = gql`
  query getOrderDetails($input: GetOrderDetail) {
    getOrderDetails(input: $input) {
      status
      error
      data {
        isNotFound
        orderDataRow {
          billingAddress {
            address1
            address2
            city
            country
            first_name
            last_name
            province
            phone
            zip
          }
        }
        orderLineItemsData {
          orderNumber
          shopifyOrderId
          createdAt
          accountingStatus
          commissionSeller
          createdAt
          dueDate
          grams
          isShippingPaid
          moneyFormat
          price
          markUpPrice
          shippingAmount
          sellerDiscount
          discountAllocations {
            amount
            discount_application_index
          }
          totalTax
          priceSet {
            shopMoney {
              amount
              currencyCode
            }
          }
        }
        seller {
          brandName
          logo
          tax {
            isTax
            rate
            type
          }
        }
      }
    }
  }
`;

const GET_PACKING_SLIP_ACCESS = gql`
  query getPackingSlipAccess {
    getPackingSlipAccess {
      status
      error
      data {
        isAllVendor
        isEnabled
        isFulfilled
        selectedVendor
        updatedAt
      }
    }
  }
`;

const GET_PACKING_SLIP_SETTING = gql`
  query getPackingSlipSetting {
    getPackingSlipSetting {
      data {
        product {
          label
          isHide
        }
        quantity {
          label
          isHide
        }
        price {
          label
          isHide
        }
        sku {
          label
          isHide
        }
        discount {
          label
          isHide
        }
        tax {
          label
          isHide
        }
        total {
          label
          isHide
        }
        totalTax {
          label
          isHide
        }
        totalShipping {
          label
          isHide
        }
        totalDiscount {
          label
          isHide
        }
        subTotal {
          label
          isHide
        }
        purchasedFrom {
          label
          isHide
        }
        deliveredTo {
          label
          isHide
        }
        orderFulfilledBy {
          label
          isHide
        }
        createdAt
        updatedAt
      }
      error
      status
    }
  }
`;

export { GET_PACKING_SLIP_SETTING, GET_ORDER_DETAIL, GET_PACKING_SLIP_ACCESS };
