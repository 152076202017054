const css = `.invoice-box {
    max-width: 950px;
    width: 950px;
    margin-left: auto;
    padding-left: 15px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background: #fff;
    font-size: 13px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #000000;
}

.Polaris-Page__Invoice {
  max-width: 95rem;
  margin: 0 auto;
  margin-top: 5rem;
  width: 100%;
}

.cFields {

}

.btn-print {
  background: linear-gradient(180deg,#6371c7,#5563c1);
  border-color: #3f4eae;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgb(22 29 37 / 5%), 0 0 0 0 transparent;
  margin-bottom: 1%;
  color: #fff;
  border: 1px solid #d9dfe3;
  display: inline-block;
  vertical-align: top;
  padding: 8px 12px;
  border-radius: 4px;
  .fa {
    font-size: 15px;
  }
}

.widthProduct {
  width:70px;
}
.widthSku{
  width:40px;
}

.widthSales {
  width: 140px;
}

.wordBreak {
  word-break: break-word;
}

.widthFee {
  width: 100px;
}

.widthPrice {
  width: 120px;
}

.widthRate {
  width: 100px;
}

.widthAmount {
  width: 120px;
}

.widthQuantity {
  width: 90px;
}

.marginBottom {
  margin-bottom: 0%;
}

.alignRight {
  text-align: right;
  white-space: nowrap;
}

.alignLeft {
  text-align: left!important;
}

.fontWeight {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

span {
  margin-right: 4px;
}

table th, table td {
  padding: 10px 15px;
  line-height: 1.4;
  text-align: left;
}

hr {
  border: none;
  color: #000000;
  background-color: grey;
  height: 1px;
  width: 98%;
  padding: 0px;
}

.text-capitalize {
  text-transform: capitalize;
  overflow-wrap: anywhere;
}

.hrClass {
  width: 700px;
}

.paddingLeft {
  padding-left: 13px;
}

.quantityAndTotalLabel {
  width: 90px;
}`;

export default css;
