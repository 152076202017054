import React from "react";
import { AdminAssociation } from "app/userManagement/modules/admin/features";
import { storageHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin } = userKey;

const Association = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminAssociation />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const AdminAssociationComponent = getComponent(currentUserRole);
  return AdminAssociationComponent;
};

export default Association;
