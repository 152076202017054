import React, { useEffect, useState, useContext } from "react";
import { Tabs, Card, Link, TextContainer } from "@shopify/polaris";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import lib
import { ResourceList, Banner, ModalPopup, PermissionBanner } from "lib/components";
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { QuickAction, QuickReport } from "app/reports";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

// import gql
import BULK_INVITE_VIEW from "app/invitations/apollo/subscription";
import { GET_BULK_INVITE_LIST, GET_BULK_EMAIL_TEMPLATE } from "app/invitations/apollo/query";
import { UPDATE_INVITATION_EMAIL_STATUS, BULK_INVITATION_BY_DATA } from "app/invitations/apollo/mutation";

// import subFeatures
import InvitationListView from "app/invitations/generic/subFeature/invitationListView";

const OperatorInvitation = () => {
  const { history, currentUser, cms, isShopifyCustomOperator } = useContext(PrivateContext);
  const { fulfillmentScopeExists = false, isReadOnly } = currentUser;
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [bulkInvitation, setBulkInvitation] = useState(false);
  const [selected, setSelected] = useState(parseInt(prevFilter.tab, 10) || 0);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [totalVendor, setTotalVendor] = useState(0);
  const [resendMail, setResendMail] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || "createdAt",
    sort_order: prevFilter.sort_order || "desc",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const promotedBulkActions = [];
  const bulkActions = [];
  const tabs = [
    {
      id: "all-vendors",
      content: cms("tabs.all"),
      accessibilityLabel: "All vendors",
      panelID: "all-vendors-content",
      value: "all",
    },
    {
      id: "invited-vendors",
      content: cms("tabs.invited"),
      accessibilityLabel: "invited vendors",
      panelID: "invited-vendors-content",
      value: "invited",
    },
    {
      id: "joined-vendors",
      content: cms("tabs.joined"),
      accessibilityLabel: "joined vendors",
      panelID: "joined-vendors-content",
      value: "joined",
    },
    {
      id: "revoked-vendors",
      content: cms("tabs.revoked"),
      accessibilityLabel: "revoked vendors",
      panelID: "revoked-vendors-content",
      value: "revoked",
    },
    {
      id: "resent-vendors",
      content: cms("tabs.resent"),
      accessibilityLabel: "resent vendors",
      panelID: "resent-vendors-content",
      value: "resent",
    },
    {
      id: "pending-vendors",
      content: cms("tabs.pending"),
      accessibilityLabel: "pending vendors",
      panelID: "pending-vendors-content",
      value: "pending",
    },
  ];

  const sortOptions = [
    { label: cms("resourceList.sortOption.sortByDateAsc"), value: constant.CREATED_ASC },
    { label: cms("resourceList.sortOption.sortByDateDesc"), value: constant.CREATED_DESC },
  ];

  const inputData = {
    search: dataToFetch.search,
    filter: dataToFetch.filter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  const {
    error: errorOperatorInvitation,
    loading: loadingOperatorInvitation,
    data: dataOperatorInvitation,
    subscribeToMore,
    refetch,
  } = useQuery(GET_BULK_INVITE_LIST, {
    variables: {
      input: inputData,
    },
  });

  // Mutation Calls
  const [
    revokeInvitation,
    { error: errorRevokeInvitation, loading: loadingRevokeInvitation, data: dataRevokeInvitation },
  ] = useMutation(UPDATE_INVITATION_EMAIL_STATUS, {
    refetchQueries: [
      {
        query: GET_BULK_INVITE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [resendInvitation, { loading: loadingResendInvitation }] = useMutation(BULK_INVITATION_BY_DATA, {
    refetchQueries: [
      {
        query: GET_BULK_INVITE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [
    emailTemplate,
    { error: errorEmailTemplate, loading: loadingEmailTemplate, data: dataEmailTemplate },
  ] = useLazyQuery(GET_BULK_EMAIL_TEMPLATE);

  const handleCancel = async (vendorMail, buttonIndex) => {
    setSelectedButtonIndex(buttonIndex);
    const formData = { email: vendorMail };
    await revokeInvitation({ variables: { input: formData } });
  };

  const handleResend = (mail, buttonIndex) => {
    setSelectedButtonIndex(buttonIndex);
    setResendMail(mail);
    emailTemplate();
  };

  // Subscription for vendorList Page.
  useEffect(() => {
    subscribeToMore({
      document: BULK_INVITE_VIEW,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  useEffect(() => {
    if (errorEmailTemplate) {
      setBanner({ title: errorHelper.parse(errorEmailTemplate), status: constant.CRITICAL, isOpen: true });
    }
  }, [errorEmailTemplate]);

  useEffect(() => {
    if (dataEmailTemplate) {
      const emailTemplateResponseData = baseHelper.getResponseData(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      const emailTemplateResponseError = baseHelper.getResponseError(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      if (emailTemplateResponseData) {
        const resendInviteData = {
          emails: [
            {
              email: resendMail,
            },
          ],
          message: {
            message: emailTemplateResponseData.message,
            subject: emailTemplateResponseData.subject,
          },
          isSingleInvite: true,
          isReadOnly,
          isResent: true,
        };
        resendInvitation({ variables: { input: { ...resendInviteData } } })
          .then((resendInviteRes) => {
            const resendInviteResponseData = baseHelper.getResponseData(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            const resendInviteResponseError = baseHelper.getResponseError(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            if (resendInviteResponseData) {
              setBanner({ title: cms("message.requestSuccess"), status: constant.SUCCESS, isOpen: true });
            }
            if (resendInviteResponseError) {
              setBanner({ title: resendInviteResponseError, status: constant.CRITICAL, isOpen: true });
            }
          })
          .catch((exception) => {
            setBanner({ title: errorHelper.parse(exception), status: constant.CRITICAL, isOpen: true });
          });
      }
      if (emailTemplateResponseError) {
        setBanner({ title: emailTemplateResponseError, status: constant.CRITICAL, isOpen: true });
      }
    }
  }, [isReadOnly, dataEmailTemplate, emailTemplate.message, emailTemplate.subject, resendInvitation, resendMail, cms]);

  useEffect(() => {
    if (dataRevokeInvitation) {
      const revokeInvitationResponseData = baseHelper.getResponseData(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      const revokeInvitationResponseError = baseHelper.getResponseError(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      if (revokeInvitationResponseData) {
        setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.requestSuccess") });
      }
      if (revokeInvitationResponseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: revokeInvitationResponseError });
      }
    }
  }, [cms, dataRevokeInvitation, setBanner]);

  useEffect(() => {
    if (errorRevokeInvitation) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(errorRevokeInvitation) });
    }
  }, [errorRevokeInvitation, setBanner]);

  const renderItem = (item) => {
    return (
      <InvitationListView
        item={item}
        setBanner={setBanner}
        cancelButtonLoading={loadingRevokeInvitation}
        resendButtonLoading={loadingResendInvitation || loadingEmailTemplate}
        handleCancel={handleCancel}
        handleResend={handleResend}
        selectedButtonIndex={selectedButtonIndex}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
      />
    );
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleTabChange = (selectedTabIndex) => {
    const { value: tabName } = tabs[selectedTabIndex];
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { tab: selectedTabIndex, filter: tabName });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleSearchTextRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleClearAll = () => {
    handleSearchTextRemove();
  };

  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };

  // GQL success response
  useEffect(() => {
    const responseDataInvitation = baseHelper.getResponseData(dataOperatorInvitation, "getBulkInviteList");
    const errorDataInvitation = baseHelper.getResponseError(dataOperatorInvitation, "getBulkInviteList");
    if (responseDataInvitation) {
      const { count: totalCount = 0 } = responseDataInvitation;
      setBulkInvitation(responseDataInvitation.bulkInviteList);
      setTotalVendor(totalCount);
    }
    if (errorDataInvitation) {
      setBanner({ status: constant.CRITICAL, title: errorDataInvitation, isOpen: true });
    }
  }, [dataOperatorInvitation]);

  // GQL Error
  useEffect(() => {
    if (errorOperatorInvitation) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorOperatorInvitation), isOpen: true });
    }
  }, [errorOperatorInvitation]);

  const handleClose = () => {
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  return (
    <>
      {!isShopifyCustomOperator && appUpdate === "updated" && (
        <>
          <Banner
            isOpen
            action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
            title={cms("common.popup.migrate.title")}
            status="critical"
          >
            <p>{cms("common.popup.migrate.description")}</p>
          </Banner>
          <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
            <Card>
              <TextContainer>
                <Banner
                  isOpen
                  status="critical"
                  action={{
                    content: `${cms("common.appPermission.label.aboutCustom")}`,
                    url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                  }}
                >
                  <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                </Banner>
              </TextContainer>
            </Card>
            <UpgradAppPermission />
          </ModalPopup>
          <br />
        </>
      )}
      {isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
      <br />
      <QuickReport />
      <QuickAction />
      {banner.isOpen && (
        <>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false });
            }}
          />
          <br />
        </>
      )}
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <ResourceList
            resourceName={{
              singular: cms("resourceList.singular"),
              plural: cms("resourceList.plural"),
            }}
            items={bulkInvitation || []}
            renderItem={renderItem}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            handleClearAll={handleClearAll}
            queryValue={queryValue}
            sortValue={sortValue}
            sortOptions={sortOptions}
            onSortChange={sortFilter}
            loading={loadingOperatorInvitation}
            page={currentPage}
            count={Number(totalVendor)}
            perPage={selectedLimit}
            setPage={handlePerPage}
            setPerPage={handleSelectLimit}
          />
        </Tabs>
      </Card>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorInvitation), { feature: constant.INVITATION });
