import { gql } from "apollo-boost";

const APPROVE_ALL_CHANGES = gql`
  mutation approveAllProductChanges($input: AllProductChanges) {
    approveAllProductChanges(input: $input) {
      data
      error
      status
    }
  }
`;

const APPROVE_SELLER_CHANGES = gql`
  mutation approveSellerChanges($input: ApproveSellerChanges) {
    approveSellerChanges(input: $input) {
      data
      error
      status
    }
  }
`;

export { APPROVE_ALL_CHANGES, APPROVE_SELLER_CHANGES };
