// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

const QUOTE_LIST_SUBSCRIPTION = gql`
  subscription {
    getQuoteList {
      data {
        _id
        customerEmail
        price
        message
        orderIds
        productId
        status
        createdAt
        title
        image
      }
      error
      status
    }
  }
`;

export default QUOTE_LIST_SUBSCRIPTION;
