import React from "react";

import { BulkActivity } from "app/userManagement/modules/generic";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

const OperatorBulkActivity = () => {
  return <BulkActivity />;
};

export default withFeature(withErrorBoundary(OperatorBulkActivity), { feature: constant.BULK_ACTIVITY });
