import { gql } from "apollo-boost";

const UPDATE_INVOICE_CUSTOM_NOTE = gql`
  mutation updateInvoiceCustomNote($input: UpdateInvoiceCustomNoteInput!) {
    updateInvoiceCustomNote(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_INVOICE_CUSTOM_NOTE;
