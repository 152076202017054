import React, { useContext } from "react";
import { Card, Caption, Collapsible, TextContainer } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

const File = (props) => {
  const { handleAddImage, removeImage, allowMultiple, data } = props;
  const { cms } = useContext(PrivateContext) || {};
  const updateImageData = [];

  if (data && data.length) {
    data.forEach((item) => {
      const regex = new RegExp('data:image/([a-zA-Z]*);base64,([^"]*)');
      if (!regex.test(item)) {
        updateImageData.push({ url: item });
      }
    });
  }

  const propsValues = {
    existingImageList: updateImageData || [],
  };

  if (!allowMultiple) {
    propsValues.fileList = (!allowMultiple ? (!updateImageData.length && data) || [""] : []) || [];
  }

  return (
    <Collapsible open>
      <Card title={["File"]}>
        <Card.Section>
          <TextContainer>
            {cms("section.cover.subDescription")}
            <small>(recommended size 2000px X 400px)</small>
          </TextContainer>
          <br />
          <DropZone
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propsValues}
            id="dropZone"
            onAdd={handleAddImage}
            onRemove={removeImage}
            removeExistingImage={removeImage}
            allowMultiple={allowMultiple}
            allowedTypes={["gif", "jpeg", "jpg", "png", "svg"]}
            size={10}
            sizeType="MB"
          />
          <br />
          <Caption>{cms("section.cover.value.defaultText")}</Caption>
        </Card.Section>
      </Card>
    </Collapsible>
  );
};

export default File;
