import React, { useContext, useState } from "react";

import { QuickLink, QuickReport } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import { PrivateContext } from "lib/context";
import { ModalPopup, PermissionBanner, SkeletonList } from "lib/components";

import { PlanCard } from "app/plans";
import { Activity } from "app/userManagement/modules";

import { baseHelper, storageHelper } from "lib/helpers";
import OnBoardingSkeleton from "lib/components/skeletonOnboarding/onBoardingSkeleton";
import { Banner, Card, Layout, Link, Modal, TextContainer } from "@shopify/polaris";
import { withErrorBoundary } from "lib/hoc";
import OperatorExportReporting from "app/dashboard/modules/provider/export/export";
import appConfig from "configuration";
import constant from "lib/constant/constant";
import VendorAppConnectShopify from "app/product/modules/provider/features/add/subFeatures/connect/appConnect";
import dashboardQuickLinks from "./dashboardConfig";

const { brandPageV2URL } = appConfig;
const { providerList } = constant;

const ProviderDashboard = () => {
  const [isExportModal, setIsExportModal] = useState(false);
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customVendorAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });
  const {
    currentUser,
    isLoading,
    cms = {},
    isShopConnected,
    isShopifyOldVendor,
    vendorHiddenModule,
    shop,
  } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const { plan = {}, fulfillmentScopeExists = false } = currentUser || {};
  const { isStripe = false } = plan || {};
  const isEcommerceUser = !!currentUser.ecommercePlatform;
  const handleShow = () => {
    setIsExportModal(true);
  };

  const onBrandV2Page = () => {
    const url = `${brandPageV2URL}/${providerList.BRAND}/${shop}/${currentUser.brandName}`;
    window.open(url);
  };
  const linkData = dashboardQuickLinks(vendorHiddenModule, isEcommerceUser, cms, handleShow, onBrandV2Page);

  const getSecondaryComponent = () => {
    return (
      <>
        {isStripe && <PlanCard />}
        <QuickLink link={linkData} />
        <QuickReport />
      </>
    );
  };

  const handleClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setIsOpen(false);
  };

  const handleModalChange = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setAppUpdate(storageHelper.get("customVendorAppUpdate"));
    setIsOpen(true);
  };
  const handleModalClose = () => {
    storageHelper.set("customVendorAppUpdate", "updated");
    setAppUpdate(storageHelper.get("customVendorAppUpdate"));
  };

  function CustomAppUpdateModle() {
    if (appUpdate === "No") {
      return (
        <div style={{ height: "500px" }}>
          <Modal
            open={appUpdate}
            onClose={handleModalClose}
            title={cms("common.popup.migrate.banner.description.heading")}
            primaryAction={{
              content: cms("common.popup.migrate.continue"),
              onAction: handleModalChange,
            }}
          >
            <Card>
              <Modal.Section>
                <TextContainer>
                  {cms("common.popup.migrate.banner.description.para1")}
                  <br />
                  <br />
                  {cms("common.popup.migrate.banner.description.para2")}
                </TextContainer>
              </Modal.Section>
            </Card>
          </Modal>
        </div>
      );
    }
    return null;
  }

  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        <Activity />
        <OperatorExportReporting isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
      </>
    );
  };

  if (isLoading && storageHelper.get("setupProgress")) {
    return (
      <Layout.Section>
        <OnBoardingSkeleton />
      </Layout.Section>
    );
  }
  return [
    <>
      {isVendor && storageHelper.get("customVendorAppUpdate") === "No" && <CustomAppUpdateModle />}
      {isShopConnected && isShopifyOldVendor && appUpdate === "updated" && (
        <>
          <Banner
            isOpen
            action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
            title={cms("common.popup.migrate.banner.description.heading")}
            status="critical"
          >
            <p>
              <br />
              {cms("common.popup.migrate.banner.description.para1")}
              <br />
              <br />
              {cms("common.popup.migrate.banner.description.para2")}
            </p>
          </Banner>
          <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
            <Card>
              <TextContainer>
                <Banner
                  isOpen
                  status="critical"
                  action={{
                    content: `${cms("common.appPermission.label.aboutURL")}`,
                    url: constant.ABOUT_VENDOR_APP_MIGRATE,
                  }}
                >
                  <TextContainer>
                    <strong>{cms("common.popup.migrate.banner.description.heading")}</strong>
                    <br />
                    <br />
                    {cms("common.popup.migrate.banner.description.para1")}
                    <br />
                    <br />
                    {cms("common.popup.migrate.banner.description.para2")}
                  </TextContainer>
                </Banner>
              </TextContainer>
              <br />
              {banner.isOpen && (
                <Banner
                  isOpen={banner.isOpen}
                  status={banner.status}
                  title={banner.title}
                  action={banner.action}
                  onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
                  isScrollTop={banner.isOpen}
                />
              )}
            </Card>
            <VendorAppConnectShopify appToMigrate={isOpen} setIsOpenModal={setIsOpen} setBanner={setBanner} />
          </ModalPopup>
          <br />
        </>
      )}
      <br />
      {isShopConnected && !isShopifyOldVendor && !fulfillmentScopeExists && <PermissionBanner />}
      <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />
    </>,
  ];
};

export default withErrorBoundary(ProviderDashboard);
