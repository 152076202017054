import { gql } from "apollo-boost";

const ADD_SELLER_PLAN = gql`
  mutation addSellerPlan($input: AddSellerPlanInput!) {
    addSellerPlan(input: $input) {
      data
      status
      error
    }
  }
`;
export default ADD_SELLER_PLAN;
