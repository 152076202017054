import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tag, ChoiceList } from "@shopify/polaris";
import { useQuery } from "react-apollo";

import { withErrorBoundary } from "lib/hoc";

//  import helpers
import baseHelper from "lib/helpers/base";

//  import constant
import constant from "lib/constant/constant";

//  import helpers components
import { ResourceList } from "lib/components";

//  import context
import { PrivateContext } from "lib/context";
import MoreFilters from "app/paymentsLake/modules/generic/moreFilters/moreFilter";
import { errorHelper } from "lib/helpers";

//  import gql
import { GET_PAYMENT_LAKE_LIST } from "app/paymentsLake/apollo/queries";
import PAYMENT_LAKE_LIST from "app/payments/apollo/subscriptions/payments";

//  import cms
import actionData from "./cms/payment";
import { listProp } from "./propTypes";

//  import subFeature
import PaymentRow from "./features/paymentRow";

const PaymentList = (props) => {
  const { setBanner } = props;
  const { cms, history } = useContext(PrivateContext);
  const { ALL, CRITICAL, CREATED_AT, DESC, FILTER_BY, gql, PAID, TAGGED_WITH } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  // const [loading, setLoading] = useState(false);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || "");
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || "");
  const [paymentListData, setPaymentList] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [isInvoiceEnabled] = useState(true);
  const resourceData = actionData(cms);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  // const [tab, setTab] = useState(parseInt(queryParam.tab, 10) || 0);
  const [activePopover, setActivePopover] = useState({});
  const paramFilter = (queryParam.filter && queryParam.filter.split(",")) || [];
  const [filterByStatus, setFilterByStatus] = useState(paramFilter || []);

  if (selectedFilter === constant.NAME) {
    setSelectedFilter(constant.PRODUCT_NAME);
  }
  const renderItem = (item) => {
    return (
      <PaymentRow
        item={item}
        setBanner={setBanner}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        isInvoiceEnabled={isInvoiceEnabled}
      />
    );
  };

  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || CREATED_AT,
    sort_order: queryParam.sort_order || DESC,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: orderLinesData, loading: orderLoading, subscribeToMore, error, refetch } = useQuery(
    GET_PAYMENT_LAKE_LIST,
    {
      variables: {
        input: inputData,
      },
    }
  );

  useEffect(() => {
    subscribeToMore({
      document: PAYMENT_LAKE_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  // useEffect(() => {
  // setSelectedItems([]);
  // if (!orderLoading) {
  // setLoading(false);
  // }
  // }, [orderLoading]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: errorHelper.parse(error), status: CRITICAL });
    }
  }, [error, setBanner, CRITICAL]);

  const orderLineError = baseHelper.getResponseError(orderLinesData, gql.GET_PAYMENT_LAKE_LIST);
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: CRITICAL, title: orderLineError });
    }
  }, [orderLineError, setBanner, CRITICAL]);

  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, gql.GET_PAYMENT_LAKE_LIST);

  useEffect(() => {
    if (responseOrderLinesData) {
      setPaymentList(responseOrderLinesData && responseOrderLinesData.paymentList);
      setPaymentCount(responseOrderLinesData && responseOrderLinesData.count);
    }
  }, [responseOrderLinesData]);

  useEffect(() => {
    if (filterByStatus && filterByStatus.length) {
      return baseHelper.setUrl(history, { filter: filterByStatus, page: 1 });
    }
    return baseHelper.setUrl(history, { filter: "", page: 1 });
  }, [filterByStatus, history]);

  const removeTag = useCallback(
    (tag) => () => {
      setFilterByStatus((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
    },
    []
  );

  // const handleSelect = (item) => {
  //   setSelectedItems(item);
  // };

  // const handleTabChange = (tabSelected) => {
  //   setTab(parseInt(tabSelected, 10));
  //   setPage(1);
  //   if (resourceData.tabsData && resourceData.tabsData[tabSelected] && resourceData.tabsData[tabSelected].id) {
  //     baseHelper.setUrl(history, {
  //       tab: tabSelected,
  //       filter: resourceData.tabsData[tabSelected].id,
  //       page: 1,
  //     });
  //   }
  // };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };
  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
    }
  };
  const handleQueryClear = () => {
    const searchValue = baseHelper.setQueryParams(history.location, { search: "" });
    setSearch("");
    history.push(`${history.location.pathname}?${searchValue}`);
  };

  // const bulkActions = [
  //   {
  //     content: cms("label.markDispute"),
  //     onAction: () => handleBulkAccount(DISPUTE),
  //   },
  // ];

  const handleTaggedWithRemove = (clearFilter = false, key) => {
    const clearParams =
      key === constant.FILTER_BY_STATUS ? { filter: "", page: 1 } : { list_filter: "", list_search: "", page: 1 };
    if (clearFilter) {
      if (key) {
        switch (key) {
          case constant.TAGGED_WITH:
            return [setTaggedWith(""), setSelectedFilter(""), baseHelper.setUrl(history, clearParams)];
          case constant.FILTER_BY_STATUS:
            return [setFilterByStatus(null), baseHelper.setUrl(history, clearParams)];
          default:
            return null;
        }
      }
    }
    clearParams.list_filter = "";
    clearParams.filter = "";
    setTaggedWith("");
    setSelectedFilter("");
    setFilterByStatus(null);
    baseHelper.setUrl(history, clearParams);
    return null;
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(false);
    handleQueryClear();
  };

  let tagMarkup = [];
  if (filterByStatus && filterByStatus.length) {
    tagMarkup =
      filterByStatus &&
      filterByStatus.map((option) => (
        <Tag key={option} onRemove={removeTag(option)}>
          {baseHelper.ucFirst(option)}
        </Tag>
      ));
  }

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case constant.TAGGED_WITH:
        return `${value}`;
      case constant.SELECTED_FILTER:
        return cms(`label.${value}`) || value;
      case constant.FILTER_BY_STATUS:
        return <div className="remove_tag">{tagMarkup}</div>;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilterArrObj = [];
  if (!isEmpty(taggedWith)) {
    appliedFilterArrObj.push({
      key: constant.TAGGED_WITH,
      label: disambiguateLabel(constant.TAGGED_WITH, taggedWith),
      onRemove: () => handleTaggedWithRemove(true, constant.TAGGED_WITH),
    });
  }
  if (!isEmpty(selectedFilter)) {
    appliedFilterArrObj.push({
      key: constant.SELECTED_FILTER,
      label: disambiguateLabel(constant.SELECTED_FILTER, selectedFilter),
      onRemove: () => handleTaggedWithRemove(false),
    });
  }
  if (!isEmpty(filterByStatus)) {
    appliedFilterArrObj.push({
      key: constant.FILTER_BY_STATUS,
      label: disambiguateLabel(constant.FILTER_BY_STATUS, filterByStatus),
      onRemove: () => handleTaggedWithRemove(true, constant.FILTER_BY_STATUS),
    });
  }
  const appliedFilters = appliedFilterArrObj;

  // const filterOptions = [{ label: cms("label.brandName"), value: constant.VENDOR }];
  const filterOptions = [{ label: constant.PRODUCT_NAME, value: constant.NAME }];

  const filterByStatusOption = [
    {
      label: cms("label.all"),
      value: ALL,
    },
    // {
    //   // label: cms("label.forecast"),
    //   label: "forecast",
    //   value: "forecast",
    // },
    // {
    //   label: cms("label.duePayment"),
    //   value: DUE,
    // },
    {
      label: cms("label.pay"),
      value: PAID,
    },
    // {
    //   // label: cms("label.dispute"),
    //   label: "dispute",
    //   value: "dispute",
    // },
  ];

  const handleAction = (value) => {
    setFilterByStatus(value);
    baseHelper.setUrl(history, { filter: value, page: 1 });
    if (!value.length) {
      baseHelper.setUrl(history, { filter: ALL, page: 1 });
    }
  };

  const filters = [
    {
      key: TAGGED_WITH,
      // label: cms("label.filter"),
      label: FILTER_BY,
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={filterOptions}
        />
      ),
      shortcut: false,
    },
    {
      key: constant.FILTER_BY_STATUS,
      label: "Filter by status",
      filter: (
        <ChoiceList
          title="Filter by status"
          titleHidden
          choices={filterByStatusOption}
          selected={filterByStatus || []}
          onChange={(value) => handleAction(value)}
          allowMultiple
        />
      ),
    },
  ];

  return (
    <>
      {/* <Tabs tabs={resourceData.tabsData} selected={tab} onSelect={handleTabChange} /> */}
      <ResourceList
        items={paymentListData}
        idForItem={(orderLine) => {
          const { _id: id } = orderLine;
          return id;
        }}
        renderItem={renderItem}
        loading={orderLoading}
        queryValue={search}
        onQueryClear={handleQueryClear}
        onQueryChange={handleSearchChange}
        sortOptions={resourceData.sortOptions}
        sortValue={sortValue}
        onSortChange={handleSort}
        // selectedItems={selectedItems}
        // onSelectionChange={handleSelect}
        // bulkActions={bulkActions}
        appliedFilters={appliedFilters}
        handleClearAll={handleClearAll}
        // selectable
        filters={filters}
        count={paymentCount}
        page={page}
        perPage={perPage}
        setPage={handlePageChange}
        setPerPage={handlePageLimitChange}
      />
    </>
  );
};
PaymentList.propTypes = listProp.type;
export default withErrorBoundary(PaymentList);
