import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import Moment from "moment";

import "jspdf-autotable";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner, Spinner } from "lib/components";

// import query
import { GET_ORDER_LINES, GET_PAYMENT_EXPORT_SETTING } from "app/payments/apollo/queries";

// import helpers
import { baseHelper, fileHelper, storageHelper } from "lib/helpers";
import paymentHelper from "./helper/payment";

const ExportAsPDF = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser } = data;
  const brandLogo = storageHelper.get("logoUrl");
  const defaultLogo = constant.DEFAULT_PDF_LOGO;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [pdfDetails, setPDFDetails] = useState([]);
  const [hasRecord, setHasRecord] = useState(true);
  const [pdfCount, setPdfCount] = useState(0);
  const [
    getOrderLines,
    { data: getOrderData, loading: getOrderLoading, error: getOrderError },
  ] = useLazyQuery(GET_ORDER_LINES, { variables: { input: queryData } });

  const [getPaymentSetting, { data: getSettingData, loading: settingLoading, error: settingError }] = useLazyQuery(
    GET_PAYMENT_EXPORT_SETTING
  );

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!getOrderLoading && !getOrderError && getOrderData && getSettingData && !settingLoading && !settingError) {
      const resData = baseHelper.getResponseData(getOrderData, constant.gql.GET_ORDER_LINES);
      const settingResponse = baseHelper.getResponseData(getSettingData, constant.gql.GET_PAYMENT_EXPORT_SETTING);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getOrderData, constant.gql.GET_ORDER_LINES);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }

      const { orderLines = [], hasMore = false } = resData;
      setHasRecord(hasMore);

      if (!(orderLines && orderLines.length)) {
        bannerData = {
          title: cms("common.message.error.noPayment"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }

      const pdfData = paymentHelper.paymentCSVData(orderLines, settingResponse, false, true, isVendor);

      if (pdfData && pdfData.length) {
        setPdfCount(pdfCount + 1);
        if (pdfCount > 0) {
          pdfData.shift();
        }
        setPDFDetails([...pdfDetails, ...pdfData]);
      }

      if (hasMore) {
        setQueryData({ ...queryData, page: parseInt(queryData.page, 10) + 1 });
      }
    }
  }, [
    brandLogo,
    cms,
    defaultLogo,
    getOrderData,
    getOrderError,
    getOrderLoading,
    getSettingData,
    isVendor,
    settingError,
    settingLoading,
  ]);

  useEffect(() => {
    const callback = () => {
      setTimeout(() => {
        window.close();
      }, 500);
    };
    if (pdfDetails && pdfDetails.length > 1) {
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      if (!hasRecord) {
        fileHelper
          .loadImage(brandLogo || defaultLogo)
          .then((logo) => {
            fileHelper.generatePdf(logo, pdfDetails, `${constant.CONSOLIDATED_INVOICE} ${date}`, callback);
          })
          .catch(() => {
            fileHelper.generatePdf(false, pdfDetails, `${constant.CONSOLIDATED_INVOICE} ${date}`, callback);
          });
      }
    }
  }, [pdfDetails]);

  useEffect(() => {
    getOrderLines({ variables: { input: { ...queryData } } });
  }, [queryData]);

  useEffect(() => {
    const exportPayment = () => {
      const {
        accountingStatus,
        endDate,
        fulfillmentStatus,
        startDate,
        status,
        taxStatus,
        vendor,
        page = 1,
      } = baseHelper.queryParamsFromLocation(data);

      const dataToFetch = { page };

      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      if (startDate && endDate) {
        dataToFetch.dateRange = { start: startDate, end: endDate };
      }
      if (taxStatus && taxStatus.length) {
        dataToFetch.taxStatus = taxStatus.split(",");
      }
      setQueryData(dataToFetch);
      getPaymentSetting();
    };
    if (isPageLoading) {
      exportPayment();
      setIsPageLoading(false);
    }
  }, [data, getOrderLines, getPaymentSetting, isPageLoading]);

  return (
    <Layout.Section>
      {getOrderLoading && <Spinner isFullPage />}
      {banner.isOpen && (
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
        />
      )}
      {/* <Banner
        title="Payment export is in progress"
        status="info"
        isOpen={true}
      >
        <br />
        Please wait while we are exporting your payments in a batch of 500. Please allow multiple downloads if there is a popup on your screen. <br /> <br />

        This tab will automatically close once all the downloads are completed.
      </Banner> */}
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(ExportAsPDF), { feature: constant.EXPORT_PAYMENT });
