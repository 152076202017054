import { gql } from "apollo-boost";

const SYNC_PRODUCT_CATALOG = gql`
  mutation {
    syncProductCatalog {
      data
      status
      error
    }
  }
`;

export default SYNC_PRODUCT_CATALOG;
