import { gql } from "apollo-boost";

const UPDATE_SELLER_FEATURE = gql`
  mutation updateSellerFeature($input: UpdateSellerFeature!) {
    updateSellerFeature(input: $input) {
      data
      status
      error
    }
  }
`;

export default UPDATE_SELLER_FEATURE;
