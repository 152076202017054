import React, { useState, useContext, useCallback } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { CashDollarMajor } from "@shopify/polaris-icons";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "app/paymentsLake/modules/operator/features/list/propTypes";

// import cms and subFeature
import actionData from "app/paymentsLake/modules/operator/features/list/cms/resourceTableData";
import View from "./view";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { cms, history } = useContext(PrivateContext);
  const { activePopover, item, setActivePopover, setBanner } = props;
  const { _id: id, orderId } = item;
  const { mainLink } = actionData(cms);
  const [active, setActive] = useState(false);
  // const [invoiceModalActive, setInvoiceModalActive] = useState(false);
  const [orderLineItemId, setOrderLineItemId] = useState("");
  const [commissionVendor1, setCommissionVendor] = useState("");
  const [shippingAmount, setShippingAmount] = useState("");
  const [moneyFormat1, setMoneyFormat] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorDetail, setVendorDetail] = useState("");
  const [fulfillmenttype, setFulfillmentType] = useState("");

  const { PAY_AND_COLLECT, RESERVE, SHIPPED } = constant;

  // eslint-disable-next-line no-shadow
  const handleChange = (value, id, orderId, commissionVendor, moneyFormat, vendor, shippingAmount = "", vendorId) => {
    if (value === constant.INVOICE) {
      // setInvoiceModalActive(true);
    }
    if (value === constant.gql.PAY_VENDOR) {
      setActive(true);
      setOrderLineItemId(id);
      setCommissionVendor(commissionVendor);
      setMoneyFormat(moneyFormat);
      setVendorDetail(vendorId);
      setVendorName(vendor);
      setShippingAmount(shippingAmount);
    }
  };

  const handlePopover = useCallback(
    (itemId, type) => {
      setActivePopover({ [itemId]: !activePopover[itemId] });
      setFulfillmentType(type);
    },
    [activePopover, setActivePopover]
  );

  const renderOrderRow = (items) => {
    const {
      // dueDate,
      _id,
      accountingStatus,
      commissionSeller,
      commissionVendor,
      createdAt,
      fulfillmentType,
      isAutoExpiry,
      isAutoReject,
      isRefund,
      name,
      dueDate,
      storeOrderId,
      storeStatus,
      shippingStatus,
      // eslint-disable-next-line no-shadow
      shippingAmount,
      vendor,
      // vendorId: userId,
      // eslint-disable-next-line no-shadow
    } = items;

    let vendorDetails = {};
    vendor.forEach((element) => {
      vendorDetails = element;
    });
    const { _id: userId = "", brandName = "", moneyFormat = "" } = vendorDetails;

    let { status } = items;

    const isReserve = status === SHIPPED && fulfillmentType === RESERVE;
    if (isReserve) {
      status = PAY_AND_COLLECT;
    }

    const autoStatus = isRefund && isAutoReject ? constant.AUTO_REJECT : isAutoExpiry && constant.NO_SHOW;
    status = autoStatus || status;

    const { ERROR, PAYVENDOR, UNPAID } = constant;
    const handleClick = () => {
      history.push(`${mainLink.orderNumber}${orderId}`);
    };
    const orderStatus =
      (shippingStatus === constant.DISPATCHED && constant.COMPLETE) ||
      (shippingStatus === constant.AWAITING_DISPATCH && constant.PROCESS) ||
      storeStatus;

    const badgeStatus = baseHelper.ucFirst(orderStatus);
    const badgeType = baseHelper.getBadgeType(orderStatus);

    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);

    const isPayoutAllowed = [ERROR, UNPAID].includes(accountingStatus);

    const option = [
      // {
      //   content: cms("label.invoice"),
      //   icon: ReceiptMajor,
      //   onAction: () => handleChange(INVOICE, id, orderId, commissionVendor, moneyFormat, brandName),
      // },
    ];
    const unpaid = {
      content: cms("label.payVendor"),
      icon: CashDollarMajor,
      onAction: () =>
        handleChange(PAYVENDOR, id, orderId, commissionVendor, moneyFormat, brandName, shippingAmount, userId),
      disabled: !isPayoutAllowed,
    };
    if (isPayoutAllowed) {
      option.unshift(unpaid);
    }
    if (!isPayoutAllowed) {
      option.push(unpaid);
    }

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={handleClick}>
                {"#"}
                {storeOrderId}
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="strong">
                <div className="wrapbox loose">{`Name: ${name}`}</div>
              </TextStyle>
              <br />
              <TextStyle>
                {` ${cms("label.commission")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}
              </TextStyle>
              <br />
              <TextStyle>
                {` ${cms("label.amount")}: ${moneyFormat} ${baseHelper.getPrice(commissionVendor)}`}
              </TextStyle>
              <br />
              <TextStyle variation="subdued">
                {`${cms("label.creationDate")}: ${baseHelper.formatDate(createdAt, true)}`}
              </TextStyle>
              <br />
              {dueDate && <TextStyle>{`${cms("label.dueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>}
            </Caption>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              setActive={() => handlePopover(_id, fulfillmentType)}
              options={option}
            />
          </Stack.Item>
        </Stack>
        <br />
        <Stack.Item style={topMargin10px}>
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>

      {active && (
        <View
          active={active}
          handleClose={() => setActive(!active)}
          orderLineItemId={orderLineItemId}
          commissionVendor={commissionVendor1}
          shippingAmount={shippingAmount}
          moneyFormat={moneyFormat1}
          vendor={vendorName}
          vendorId={vendorDetail}
          setBanner={setBanner}
          cms={cms}
          fulfillmentType={fulfillmenttype}
        />
      )}
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;
