import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  Card,
  Layout,
  Button,
  Popover,
  OptionList,
  Tag,
  Stack,
  TextField,
  Select,
  FormLayout,
  TextStyle,
  Scrollable,
} from "@shopify/polaris";
import { useQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import from lib
import { ResourceList, Banner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { GET_MANAGE_PRICE_SETTING, GET_PRODUCT_BY_IDS_SELLER } from "app/productOld/apollo/queries";

// subFeatures
import { EditProduct, ProductListView } from "app/productOld/modules/generic/feature/bulkEdit/subFeature";
import Pagination from "app/productOld/modules/generic/feature/bulkEdit/paginationBulkEdit/paginationBulkEdit";

const OperatorBulkProductEdit = () => {
  const { currentUser, history, cms } = useContext(PrivateContext);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const { location } = history;
  const { state } = location;
  const isSeller = baseHelper.isSeller(currentUser);

  const { gql, symbol, FLAT, PERCENTAGE } = constant;
  const { moneyFormat = symbol.DOLLAR } = currentUser;
  const [isManagePrice, setIsManagePrice] = useState(false);
  const { loading: managePriceLoading, data: managePriceData } = useQuery(GET_MANAGE_PRICE_SETTING);

  const fields = [cms("label.price"), cms("label.discount"), cms("label.markUpKey"), cms("label.quantity")];

  const measurementFields = [
    {
      label: cms("label.length"),
      value: cms("label.length"),
    },
    {
      label: cms("label.width"),
      value: cms("label.width"),
    },
    {
      label: cms("label.height"),
      value: cms("label.height"),
    },
  ];

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [addField, setAddField] = useState(fields);
  const [keyField, setKeyField] = useState(measurementFields);
  const [formData, setFromData] = useState(false);
  const [editData, setEditData] = useState([]);
  const [sliced, setSliced] = useState(false);
  const [page, setPage] = useState(queryParams.page || 1);
  const [selectedLimit, setSelectedLimit] = useState(queryParams.perPage || 10);
  const [editBanner, setEditBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    dismiss: "",
  });

  useEffect(() => {
    if (editData && editData.length && sliced) {
      const end = parseInt(selectedLimit, 10) * parseInt(page, 10);
      let start = end + 1 * parseInt(page, 10) - parseInt(selectedLimit, 10);
      start -= page;
      const newArray = editData.slice(start, end);
      setEditData(newArray);
      setSliced(false);
    }
  }, [editData, sliced, page, selectedLimit]);

  useEffect(() => {
    if (managePriceData) {
      const managePriceResponse = baseHelper.getResponseData(managePriceData, gql.GET_MANAGE_PRICE_SETTING);
      if (managePriceResponse) {
        setIsManagePrice(managePriceResponse.isUpdatePrice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managePriceData]);

  const inputData = {
    ids: state && state.selectedItems,
    page: 1,
    perPage: parseInt(queryParams.perPage || selectedLimit, 10),
  };

  const { error: errorProductList, data: dataProductList, loading: dataLoading } = useQuery(GET_PRODUCT_BY_IDS_SELLER, {
    variables: { input: inputData },
  });

  useEffect(() => {
    const responseDataError = baseHelper.getResponseError(dataProductList, constant.gql.GET_PRODUCT_BY_IDS);
    const responseData = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_BY_IDS);
    if (responseData) {
      const runEffect = async () => {
        let products = responseData.productList;
        const variants = [];
        products = await products.filter((product, idx) => {
          const { _id: checkId = false } = product;
          if (checkId) {
            const productData = product;
            const { _id: id, productId: productID, sellerDiscount, vendorDiscount, isService } = productData;
            const productId = (isSeller && productID) || id;
            const isVariant = product.variants && product.variants.length;
            if (isSeller && !product.markUp) {
              productData.markUp = {
                price: 0,
                type: constant.FLAT,
              };
            }
            const isDiscount = (isSeller && sellerDiscount) || vendorDiscount;
            if (!(isDiscount && isDiscount.price)) {
              productData.sellerDiscount = {
                price: 0,
                type: constant.FLAT,
              };
            }

            if (product.variants && product.variants.length) {
              product.variants.map((variant) => {
                const nVariant = { ...variant };
                nVariant.productId = productId;
                nVariant.isService = isService;
                if (nVariant.inventoryQuantity) {
                  nVariant.quantity = nVariant.inventoryQuantity;
                }
                nVariant.productIndex = idx;
                nVariant.productTitle = product.title;
                if (isSeller && !variant.markUp) {
                  nVariant.markUp = {
                    price: 0,
                    type: constant.FLAT,
                  };
                }
                const isVDiscount = (isSeller && variant.sellerDiscount) || variant.vendorDiscount;

                nVariant.sellerDiscount = {
                  price: (isVDiscount && isVDiscount.price) || 0,
                  type: (isVDiscount && isVDiscount.type) || constant.FLAT,
                };

                nVariant.discount = {
                  price: (isVDiscount && isVDiscount.price) || 0,
                  type: (isVDiscount && isVDiscount.type) || constant.FLAT,
                };

                return variants.push(nVariant);
              });
            }
            if (isVariant) {
              productData.isVariant = true;
              return productData;
            }
            return productData;
          }
          return false;
        });
        variants.map((variant, idx) => {
          const nVariant = { ...variant };
          const { option1Val, option2Val, option3Val } = nVariant;
          nVariant.title = `${nVariant.productTitle}-${option1Val || ""}${`${option1Val && option2Val ? "/" : ""}${
            variant.option2Val || ""
          }`}${`${(option1Val || option2Val) && option3Val ? "/" : ""}${variant.option3Val || ""}`}`;
          const finalIndex = nVariant.productIndex + idx + 1;
          products.splice(finalIndex, 0, nVariant);
          return nVariant;
        });
        products = products.filter((item) => !item.isVariant);
        setFromData(products);
        setEditData(products);
        setSliced(true);
      };
      runEffect();
    }
    if (responseDataError) {
      setEditBanner({
        isOpen: true,
        title: responseDataError,
        status: constant.CRITICAL,
      });
    }
  }, [dataProductList, isSeller]);

  useEffect(() => {
    if (errorProductList) {
      setEditBanner({
        isOpen: true,
        title: errorHelper.parse(errorProductList),
        status: constant.CRITICAL,
      });
    }
  }, [errorProductList]);

  const [optionSelected] = useState(constant.FLAT);

  const handleAction = (value) => {
    const removeIndex = keyField.findIndex((item) => item.value === value[0]);
    if (removeIndex !== -1) {
      setAddField((preVal) => [...preVal, value[0]]);
      setSelected([value]);
      keyField.splice(removeIndex, 1);
    }
  };

  const handleRemove = (value) => {
    if (value) {
      const addIndex = addField.indexOf(value);
      if (addIndex !== -1) {
        const obj = {
          label: value,
          value,
        };
        setKeyField((preVal) => [...preVal, obj]);
        addField.splice(addIndex, 1);
      }
    }
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) }, state);
  };
  const handlePerPage = (pageValue) => {
    setPage(parseInt(pageValue, 10));
    baseHelper.setUrl(history, { page: pageValue }, state);
    setEditData(formData);
    setSliced(true);
  };

  const toggleActive = useCallback(() => setActive((activeToggle) => !activeToggle), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      {cms("button.addFields")}
    </Button>
  );

  // update the record
  const handleTextChange = (id, val, key, keyType = false, position = false) => {
    let newValue = val;
    const newData = formData.map((valData) => {
      const dataList = valData;
      if (id === dataList.productId && (dataList.position === position || !position)) {
        if (key === constant.MARKUP || key === constant.SELLER_DISCOUNT || key === constant.VENDOR_DISCOUNT) {
          const isLessThanMaxFlat = dataList[key].type === FLAT && val <= constant.value.MAX_FLAT;
          const isLessThanMaxPercentage = dataList[key].type === PERCENTAGE && val <= constant.value.MAX_PERCENTAGE;
          const markupPricePercentageValue =
            dataList[key].type === PERCENTAGE && val <= constant.value.MAX_MARKUP_PERCENTAGE;
          if (isLessThanMaxFlat || isLessThanMaxPercentage || markupPricePercentageValue) {
            newValue = { price: val, type: dataList[key].type };
          } else {
            newValue = { price: dataList[key].price, type: dataList[key].type };
          }
          if (keyType) {
            newValue = { price: 0, type: val };
          }
        }
        if (typeof newValue === "object") {
          return { ...dataList, [key]: newValue };
        }
        if (key === constant.LENGTH || key === constant.WIDTH || key === constant.HEIGHT) {
          dataList.measurement = { ...dataList.measurement, [key]: { value: parseFloat(newValue), unit: constant.CM } };
          return { ...dataList };
        }
        return { ...dataList, [key]: parseInt(newValue, 10) };
      }
      return dataList;
    });
    setFromData(newData);
    setEditData(newData);
    setSliced(true);
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };
  const textField = (productId, fieldVal, key, isService, keyType = false, inventoryManagement, positionKey) => {
    let label = key;
    if (label === constant.SELLER_DISCOUNT || label === constant.VENDOR_DISCOUNT) {
      label = baseHelper.ucFirst(constant.DISCOUNT);
    }
    if (label === constant.COMPARE_PRICE) {
      label = constant.COMPARE_AT_PRICE;
    }

    let isDisabled =
      (key === constant.QUANTITY && inventoryManagement !== constant.SHOPIFY) ||
      (key !== constant.QUANTITY && key !== constant.MARKUP && isSeller);

    if (
      key === constant.SELLER_DISCOUNT ||
      key === constant.VENDOR_DISCOUNT ||
      key === constant.LENGTH ||
      key === constant.WIDTH ||
      key === constant.HEIGHT
    ) {
      isDisabled = false;
    }
    if (key === constant.PRICE) {
      isDisabled = !isManagePrice;
    }

    if (
      key === constant.LENGTH ||
      key === constant.WIDTH ||
      key === constant.HEIGHT ||
      (key === constant.QUANTITY && isService)
    ) {
      isDisabled = true;
    }
    return (
      <TextField
        value={(fieldVal && fieldVal.toString()) || ""}
        placeholder={(keyType && "0.00") || (key === constant.PRICE && "0.00") || ""}
        min={0}
        id={productId}
        disabled={isDisabled}
        onChange={(value) => {
          handleTextChange(productId, acceptOnlyValidInput(value, fieldVal), key, false, positionKey);
        }}
        step={(keyType && 0.01) || 1}
        prefix={
          (keyType && optionSelected === keyType && moneyFormat) ||
          (!keyType && key === constant.PRICE && moneyFormat) ||
          ""
        }
        suffix={(keyType && optionSelected !== keyType && cms("label.percentage")) || ""}
        connectedRight={
          (keyType && (
            <Select
              options={[
                { label: cms("label.flat"), value: constant.FLAT },
                { label: cms("label.percent"), value: constant.PERCENTAGE },
              ]}
              onChange={(value) => handleTextChange(productId, value, key, true, positionKey)}
              value={keyType}
              disabled={isDisabled}
            />
          )) ||
          ""
        }
      />
    );
  };

  const renderItem = (item, _, keyIndex) => (
    <ProductListView item={item} keyIndex={keyIndex} textField={textField} addField={addField} />
  );

  return (
    <>
      {editBanner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={editBanner.isOpen}
            title={editBanner.title}
            status={editBanner.status}
            onDismiss={() => {
              setEditBanner({ isOpen: false, title: "", status: "" });
            }}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Scrollable horizontal>
          <div className="bulk-card">
            <Card title={cms("label.currentlyEditingTheseFields")}>
              <Layout.Section>
                <Stack>
                  {addField.map((value) => (
                    <Stack.Item>
                      <Tag onRemove={() => handleRemove(value)} disabled={addField.length === 1}>
                        {value}
                      </Tag>
                    </Stack.Item>
                  ))}
                  <Stack.Item>
                    <Popover
                      active={active}
                      activator={(keyField.length > 0 && activator) || <></>}
                      onClose={toggleActive}
                    >
                      <OptionList onChange={handleAction} options={keyField} selected={selected} />
                    </Popover>
                  </Stack.Item>
                </Stack>
                <br />
              </Layout.Section>
              <Layout.Section>
                <div className="bulk-form">
                  <FormLayout.Group>
                    <TextStyle>{cms("label.title")}</TextStyle>
                    {addField.indexOf(baseHelper.ucFirst(constant.PRICE)) !== -1 && (
                      <TextStyle>{cms("label.price")}</TextStyle>
                    )}
                    {addField.indexOf(baseHelper.ucFirst(constant.QUANTITY)) !== -1 && (
                      <TextStyle>{cms("label.quantity")}</TextStyle>
                    )}
                    {addField.indexOf(constant.MARKUPKEY) !== -1 && <TextStyle>{cms("label.markUp")}</TextStyle>}
                    {addField.indexOf(baseHelper.ucFirst(constant.DISCOUNT)) !== -1 && (
                      <TextStyle>{cms("label.discount")}</TextStyle>
                    )}
                    {addField.indexOf(baseHelper.ucFirst(constant.LENGTH)) !== -1 && (
                      <TextStyle>{cms("label.length")}</TextStyle>
                    )}
                    {addField.indexOf(baseHelper.ucFirst(constant.WIDTH)) !== -1 && (
                      <TextStyle>{cms("label.width")}</TextStyle>
                    )}
                    {addField.indexOf(baseHelper.ucFirst(constant.HEIGHT)) !== -1 && (
                      <TextStyle>{cms("label.height")}</TextStyle>
                    )}
                  </FormLayout.Group>
                </div>
                <br />
              </Layout.Section>
              <ResourceList isFilter={false} items={editData} renderItem={renderItem} loading={dataLoading} />
              <Pagination
                onSelectLimit={handleSelectLimit}
                perPageCount={selectedLimit}
                totalCount={formData && formData.length}
                onPerPage={handlePerPage}
                currentPage={page}
              />
            </Card>
          </div>
        </Scrollable>
      </Layout.Section>
      <Layout.Section>
        <EditProduct formData={formData} editBanner={setEditBanner} addField={addField} />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorBulkProductEdit), {
  feature: constant.PRODUCT_UPDATE_BULK_FORM,
  // subFeature: constant.PRODUCT_UPDATE_BULK_FORM,
});
