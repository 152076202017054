import constant from "lib/constant/constant";

const { providerList } = constant;

const cmsVendorList = (cms) => {
  const cmsData = {
    sortOptions: [
      { label: cms("label.providerList.sortByDateAsc"), value: providerList.CREATED_ASC },
      { label: cms("label.providerList.sortByDateDesc"), value: providerList.CREATED_DESC },
      { label: cms("label.providerList.sortByBrandNameAsc"), value: providerList.BRANDNAME_ASC },
      { label: cms("label.providerList.sortByBrandNameDesc"), value: providerList.BRANDNAME_DESC },
    ],
    promotedBulkActions: [
      {
        content: cms("label.providerList.bulkAction"),
      },
    ],
    resourceName: {
      singular: cms("label.providerList.vendor"),
      plural: cms("label.providerList.vendors"),
    },
    filterOptions: [{ label: cms("label.providerList.brand"), value: constant.BRAND_NAME }],
    message: {
      something_went_wrong: cms("error.common.somethingWentWrong"),
      running_background: cms("message.providerList.requestSuccess"),
      token_expire: cms("message.providerList.token_expire"),
      enterAccessCode: cms("message.providerList.enterAccessCode"),
    },
    label: {
      show_all_vendor: cms("label.providerList.showAllVendor"),
      select_filter: cms("label.providerList.selectFilter"),
      like: cms("label.providerList.like"),
      filter_by: cms("label.providerList.filterBy"),
      verify_selected: cms("label.providerList.verifySelected"),
      delete_selected: cms("label.providerList.deleteSelected"),
      search: cms("label.providerList.search"),
      tabKey: cms("label.providerList.tabKey"),
      sort_name: cms("label.providerList.sortName"),
      sort_order: cms("label.providerList.sortOrder"),
      perPage: cms("label.providerList.perPage"),
      list_filter: cms("label.providerList.listFilter"),
      list_search: cms("label.providerList.listSearch"),
      filter: cms("label.providerList.filter"),
      total: cms("label.providerList.total"),
      deleteModal: cms("label.providerList.deleteModal"),
      delete: cms("label.providerList.delete"),
      primaryDelete: cms("label.providerList.primaryDelete"),
      secondaryDelete: cms("label.providerList.secondaryDelete"),
      reason: cms("label.providerList.reason"),
      logInModal: cms("label.providerList.logInModal"),
      logInAs: cms("label.providerList.logInAs"),
      fullAccess: cms("label.providerList.fullAccess"),
      onlyReadAccess: cms("label.providerList.onlyReadAccess"),
      otpModal: cms("label.providerList.otpModal"),
      enterCode: cms("label.providerList.enterCode"),
      viewDetailsFor: cms("label.providerList.viewDetailsFor"),
      name: cms("label.providerList.name"),
      email: cms("label.providerList.email"),
      onBoardedOn: cms("label.providerList.onBoardedOn"),
      page: cms("label.providerList.page"),
    },
    sectionContent: {
      deleteVendorSelected: cms("sectionContent.providerList.deleteVendorSelected"),
      // deleteVendorNote: cms("sectionContent.providerList.deleteVendorNote"),
      logInAsAccess: cms("sectionContent.providerList.logInAsAccess"),
      otpNote: cms("sectionContent.providerList.otpNote"),
    },
    button: {
      deleteFromMc: cms("button.providerList.deleteProvider.primaryAction"),
      deleteFromShop: cms("button.providerList.deleteProvider.secondaryAction1"),
      confirmOtp: cms("button.providerList.accessCodeVerify.primaryAction"),
      resendOtp: cms("button.providerList.accessCodeVerify.secondaryAction1"),
      readOnly: cms("button.providerList.accessCodeVerify.secondaryAction2"),
      deleteVendor: cms("button.providerList.providerList.primaryAction"),
      loginVendor: cms("button.providerList.providerList.secondaryAction1"),
    },
    limitOptions: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
    tabs: [
      {
        id: "all-vendors",
        content: cms("label.providerList.all"),
        accessibilityLabel: "All vendors",
        panelID: "all-vendors-content",
        value: providerList.ALL,
      },
      {
        id: "pending-approval",
        content: cms("label.providerList.pendingApproval"),
        panelID: "pending-approval-content",
        value: providerList.PENDING,
      },
      {
        id: "approved",
        content: cms("label.providerList.approved"),
        panelID: "approved-content",
        value: providerList.APPROVED,
      },
    ],
  };

  return cmsData;
};

export default cmsVendorList;
