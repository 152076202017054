import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";
import { ActionList, Badge, Button, Card, Collapsible, TextStyle } from "@shopify/polaris";
import { NoteMajor, RedoMajor, TransactionMajor } from "@shopify/polaris-icons";

import constant from "lib/constant/constant";
import { CREATE_API_REQUEST_ACCESS, CREATE_PAYE_API_REQUEST_ACCESS } from "app/dashboard/apollo/mutation";
import { PrivateContext } from "lib/context";
import { StyledCardHeader } from "asset/styles/globalStyle";
import { StyledPlanCard, InnerCard } from "app/plans/widgets/operator/features/planCard/planCardStyle";
import { baseHelper, errorHelper } from "lib/helpers";

const ApiAccess = (props) => {
  const { cms, currentUser, currentUserRefetch } = useContext(PrivateContext);
  const { api = {}, plan: { price = 0, isPAYE = false } = {} } = currentUser;
  const { status = "", key = "" } = api || {};
  const { setBanner, apiAccessPricePlan, setSuccessMessage } = props;
  const [isVisibleApi, setIsVisibleApi] = useState(false);
  const [openPlanCard, setOpenPlanCard] = useState(true);

  const path = "/";

  const revealApiHelpText = `${cms("section.api.caption.token")} ${isVisibleApi ? key : ""}`;
  const handleAction = (link) => {
    window.open(link);
  };

  const config = {
    "/": [
      {
        content: cms("section.api.label.token"),
        helpText: revealApiHelpText,
        onClick: () => setIsVisibleApi(true),
        icon: TransactionMajor,
      },
      {
        content: cms("section.api.label.playground"),
        helpText: cms("section.api.caption.playground"),
        icon: RedoMajor,
        onClick: () => handleAction(constant.PLAYGROUND_URL),
      },
      {
        content: cms("section.api.label.doc"),
        helpText: cms("section.api.caption.doc"),
        icon: NoteMajor,
        onClick: () => handleAction(constant.API_DOC_URL),
      },
    ],
  };

  const [createApiAccess, { loading: updateTemplateLoading }] = useMutation(CREATE_API_REQUEST_ACCESS);
  const [createPayeApiAccess, { loading: updatePayeLoading }] = useMutation(CREATE_PAYE_API_REQUEST_ACCESS);


  const handleApiAccessButton = async () => {
    await createApiAccess({
      variables: { input: { isRequestApi: true } },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.CREATE_API_REQUEST_ACCESS);
        if (responseData) {
          setSuccessMessage(cms("common.message.success.apiAccess"));
          currentUserRefetch();
        }
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_EMAIL_TEMPLATE);
          setBanner({ isOpen: true, status: constant.CRITICAL, title: errorResponse });
          return false;
        }
        return true;
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const handlePayeApiAccessButton = async () => {
    await createPayeApiAccess({
      variables: { input: { isRequestApi: true } },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.CREATE_PAYE_API_REQUEST_ACCESS);
        if (responseData) {
          setSuccessMessage(cms("common.message.success.apiAccess"));
          currentUserRefetch();
        }
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_EMAIL_TEMPLATE);
          setBanner({ isOpen: true, status: constant.CRITICAL, title: errorResponse });
          return false;
        }
        return true;
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const actionCard = (action) => {
    return (
      <ActionList
        sections={[
          {
            items: [
              {
                content: action.content,
                helpText: action.helpText,
                icon: action.icon,
                onAction: action.onClick,
              },
            ],
          },
        ]}
      />
    );
  };

  const getQuickSettings = (settings = []) => {
    return settings.map((setting) => actionCard(setting));
  };

  const badgeStatus = {
    pending: constant.ATTENTION,
    enabled: constant.SUCCESS,
    disabled: constant.CRITICAL,
  };

  const apiAccess = () => {
    if (apiAccessPricePlan.includes(price) && !status && !isPAYE) {
      return (
        <>
          <Button primary fullWidth onClick={() => handleApiAccessButton()} loading={updatePayeLoading}>
            {cms("section.api.button.request")}
          </Button>
        </>
      );
    }
    if (isPAYE && !status) {
      return (
        <>
          <Button primary fullWidth onClick={() => handlePayeApiAccessButton()} loading={updateTemplateLoading}>
            {cms("section.api.button.request")}
          </Button>
        </>
      );
    }
    return (
      <>
        <TextStyle>{cms("section.api.label.access")}</TextStyle>
        <Badge progress={status === "pending" ? "partiallyComplete" : ""} status={badgeStatus[status]}>
          {status}
        </Badge>
        <br />
        <br />
        <TextStyle variation="subdued">{cms("section.api.description")}</TextStyle>
        {status === "enabled" && (
          <>
            <br />
            <br />
            {getQuickSettings(config[path])}
          </>
        )}
      </>
    );
  };

  return (
    <Card>
      <StyledPlanCard>
        <StyledCardHeader>
          <Card.Header title={cms("section.api.title")}>
            <Button
              plain
              disclosure={openPlanCard ? constant.UP : constant.DOWN}
              onClick={() => {
                setOpenPlanCard(!openPlanCard);
              }}
            >
              {openPlanCard ? cms("common.label.hide") : cms("common.label.show")}
            </Button>
          </Card.Header>
        </StyledCardHeader>
        <Collapsible
          open={openPlanCard}
          id="collapsiblePlanCard"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <Card>
            <InnerCard className="inner-card">
              <Card.Section subdued>{apiAccess()}</Card.Section>
            </InnerCard>
          </Card>
        </Collapsible>
      </StyledPlanCard>
    </Card>
  );
};

export default ApiAccess;
