import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";
// import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { OperatorProductLakeReview } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;

const ProductReview = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorProductLakeReview />;
    }

    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ProductReviewComponent = getComponent(currentUserRole);
  return ProductReviewComponent;
};

export default ProductReview;
