// import packages
import React, { useState, useContext, useEffect } from "react";
import { Layout, Tabs } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import components
import { GET_SHIPPING, GET_SHIPPING_LAKE_PRODUCT } from "app/shipping/apollo/queries";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { OperatorShippingOffer, OperatorShippingRule } from "./features";

const OperatorAdvanceShipping = () => {
  const { history, currentUser, cms } = useContext(PrivateContext);
  const isSeller = baseHelper.isSeller(currentUser);
  const [selectedTab, setSelectedTabs] = useState(0);
  const [sellerShipping, setSellerShipping] = useState({});
  const [isShippingBandAvailable, setIsShippingBandAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState(true);
  const [enable, setEnable] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    message: "",
  });

  const { loading: shippingLoading, data: shippingData, error } = useQuery(GET_SHIPPING);

  useEffect(() => {
    if (!shippingLoading) setLoading(false);
  }, [shippingLoading]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(error) });
      return;
    }
    const responseError = baseHelper.getResponseError(shippingData, constant.gql.GET_SHIPPING);
    if (responseError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      return;
    }
    const responseData = baseHelper.getResponseData(shippingData, constant.gql.GET_SHIPPING);
    const { shipping = {}, isShippingBandAvailable: isBandAvailable = false } = responseData || {};
    setSellerShipping(shipping);
    setIsShippingBandAvailable(isBandAvailable);
  }, [shippingData, error]);

  const { loading: productLakeLoading, data: productLakeData, error: productLakeError } = useQuery(
    GET_SHIPPING_LAKE_PRODUCT
  );

  const productLakeDataError = baseHelper.getResponseError(productLakeData, constant.gql.GET_LAKE_PRODUCTS);
  const productLakeDataResponse = baseHelper.getResponseData(productLakeData, constant.gql.GET_LAKE_PRODUCTS) || [];

  if (loading || shippingLoading || productLakeLoading) {
    return <Spinner />;
  }

  const { term, allowVendor = constant.ALL, vendorIds = [] } = sellerShipping || {};
  const isVendorManageShipping = term === constant.VENDOR_MANAGE || {};
  const isUserAllowed =
    allowVendor === constant.ALL || (allowVendor === constant.SELECTED && vendorIds.includes(currentUser.id));
  const isVendorAllowed = isSeller ? isVendorManageShipping : isUserAllowed;

  const handleTabChange = (tabSelected) => {
    setSelectedTabs(tabSelected);
    setBanner({ ...banner, isOpen: false });
  };

  const closeBanner = () => {
    setBanner({ isOpen: false, title: "", status: "", message: "" });
  };

  const bannerValues = () => {
    const banners = [];
    banners.push({
      isOpen: banner.isOpen,
      status: banner.status,
      title: banner.title,
      message: banner.message,
      key: constant.DEFAULT,
    });
    if (typeof isVendorManageShipping === "object" && !Object.keys(isVendorManageShipping).length) {
      banners.push({
        isOpen: enable,
        status: constant.WARNING,
        title: cms("message.enable.title"),
        message: cms("message.enable.content"),
        action: baseHelper.bannerButtonAction(
          true,
          {
            message: cms("message.enable.action"),
            to: "/shipping",
          },
          history
        ),
        onDismiss: () => setEnable(false),
      });
    }

    if (isVendorManageShipping && !isShippingBandAvailable) {
      banners.push({
        isOpen: add,
        status: constant.WARNING,
        title: cms("message.add.title"),
        message: cms("message.add.content"),
        action: baseHelper.bannerButtonAction(
          true,
          {
            message: cms("message.add.action"),
            to: "https://support.marketcube.io",
          },
          history
        ),
        onDismiss: () => setAdd(false),
      });
    }
    return banners;
  };
  const renderBanners = () => {
    const banners = bannerValues();

    if (!banners.length) {
      return null;
    }

    return banners.map((item) => {
      return (
        item.isOpen && (
          <Layout.Section key={item.key}>
            <Banner
              isOpen={item.isOpen}
              status={item.status}
              title={item.title}
              action={item.action || null}
              onDismiss={item.onDismiss || closeBanner()}
            >
              {item.message || null}
            </Banner>
          </Layout.Section>
        )
      );
    });
  };

  const tabs = [
    {
      id: constant.MANAGE_RULE,
      content: cms("tab.rule"),
      accessibilityLabel: cms("tab.rule"),
      panelID: constant.MANAGE_RULE,
    },
    {
      id: constant.MANAGE_OFFER,
      content: cms("tab.offer"),
      accessibilityLabel: cms("tab.offer"),
      panelID: constant.MANAGE_OFFER,
    },
  ];

  return (
    <>
      {renderBanners()}
      <Layout.Section>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />
      </Layout.Section>
      <Layout.Section>
        {selectedTab === 0 && (
          <OperatorShippingRule
            setBanner={setBanner}
            isVendorAllowed={isVendorAllowed}
            productLakeLoading={productLakeLoading}
            productLakeError={productLakeError}
            productLakeDataError={productLakeDataError}
            productLakeDataResponse={productLakeDataResponse}
          />
        )}
        {selectedTab === 1 && (
          <OperatorShippingOffer
            setBanner={setBanner}
            isVendorAllowed={isVendorAllowed}
            productLakeLoading={productLakeLoading}
            productLakeError={productLakeError}
            productLakeDataError={productLakeDataError}
            productLakeDataResponse={productLakeDataResponse}
          />
        )}
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorAdvanceShipping), { feature: constant.ADVANCE_SHIPPING });
