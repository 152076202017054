import { gql } from "apollo-boost";

const GET_PRODUCT_DETAILS = gql`
  query getProductDetail($input: CheckProductStatus) {
    getProductDetail(input: $input) {
      status
      data {
        productList {
          title
          handle
          sku
          tags
          published
          body
          imageSrc
          giftCard
          collectionData
          imageAltText
          variants {
            option1Name
            option1Value
            option2Name
            option2Value
            option3Name
            option3Value
            variantSKU
            variantWeight
            variantInventoryTracker
            variantInventoryQty
            variantInventoryPolicy
            variantPrice
            variantCompareAtPrice
            variantRequiresShipping
            variantTaxable
            variantBarcode
          }
          vendor
          type
        }
        hasMore
      }
      error
    }
  }
`;

const GET_PRODUCT_VARIANT_DETAILS = gql`
  query getProductVariantDetail($input: CheckProductDetailStatus) {
    getProductVariantDetail(input: $input) {
      status
      data {
        productList {
          _id
          title
          handle
          productId
          sku
          variants {
            option1Name
            option1Value
            option2Name
            option2Value
            option3Name
            option3Value
            variantSKU
            variantInventoryQty
            variantPrice
            variantCompareAtPrice
            variantBarcode
            markUp {
              price
              type
            }
            sellerDiscount {
              price
              type
            }
          }
          vendor
        }
        hasMore
      }
      error
    }
  }
`;

export { GET_PRODUCT_DETAILS, GET_PRODUCT_VARIANT_DETAILS };
