import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import routes
import publicRoutes from "./public";
import privateRoutes from "./private";

const NotFound = () => {
  return <h1>Not Found</h1>;
};
const Live = () => <p>App is live</p>;

const Routes = () => (
  <Router>
    <Switch>
      {publicRoutes}
      {privateRoutes}
      <Route path="/live" component={Live} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
