const standardList = (cms) => [
  {
    code: "marketplace",
    title: "Analytics",
    description: "This page will display the reports based on commission revenue of all vendors.",
    thumbnail: cms("provider.thumbnail"),
    list: [
      {
        title: "Orders",
        description: "View your order report here.",
        icon: "fal fa-shopping-cart fa-lg",
        code: "order",
        actions: [
          {
            label: "Commission Revenue",
            link: "analytics/order-commission-revenue",
            code: "orderSettings",
            description: "Display commission revenue on monthly basis.",
          },
        ],
      },
    ],
  },
];

export default standardList;
