import { gql } from "apollo-boost";

const PICKUP_ORDER_EXPIRY = gql`
  mutation pickupOrderExpiry($input: OrderExpiry) {
    pickupOrderExpiry(input: $input) {
      status
      data
      error
    }
  }
`;
export default PICKUP_ORDER_EXPIRY;
