import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link, PageActions } from "@shopify/polaris";
import { PrivateContext, PrivateBrandingContext } from "lib/context";
import { useMutation } from "@apollo/react-hooks";
import config from "configuration";
import constant from "lib/constant/constant";

import { LOGOUT_USER } from "layout/private/apollo/mutations";
import { storageHelper, baseHelper, errorHelper, chatHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

const InformationModal = (props) => {
  const { openModal } = props;
  const { associatedSeller = {}, cms, destroyPopup, history, currentUser, isLoading } = useContext(PrivateContext);
  const { branding } = useContext(PrivateBrandingContext);
  const { email: { address = "" } = {} } = associatedSeller || {};
  const isVendor = baseHelper.isVendor(currentUser);

  const { supportEmail = "" } = config;
  const emailAddress = address || supportEmail;
  const emailURL = `mailto:${emailAddress}`;

  const [setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { isHideMc } = branding || {};

  const {
    gql: { LOGOUT },
  } = constant;

  const [logoutMutation, { loading }] = useMutation(LOGOUT_USER);

  const logout = async () => {
    try {
      const tokenKey = storageHelper.get("token");
      if (!tokenKey) {
        history.go(0);
      }
      const response = await logoutMutation();
      const responseData = baseHelper.getResponseData(response.data, LOGOUT);
      if (responseData) {
        // NOTE: Removed all except language code & shop
        // storageHelper.removeAll();
        storageHelper.remove("token");
        storageHelper.remove("isInitial");
        storageHelper.remove("userRole");
        storageHelper.remove("setupProgress");
        //storageHelper.removeAll();

        chatHelper.destroyTalkJSSession();
        destroyPopup();

        const {
          buttonCSS = "",
          headerColor = "",
          loginText = "",
          logoUrl = "",
          register = {},
          brandName = "",
          headerFont = "",
        } = branding;

        if (isVendor) {
          // baseHelper.setBranding(this.props.branding);
          if (buttonCSS && buttonCSS !== "") {
            storageHelper.set("buttonCSS", buttonCSS);
          }
          if (headerColor && headerColor !== "") {
            storageHelper.set("headerColor", headerColor);
          }
          if (loginText && loginText !== "") {
            storageHelper.set("loginText", loginText);
          }
          if (logoUrl && logoUrl !== "") {
            storageHelper.set("logoUrl", logoUrl);
          }
          if (register && (register.title || register.desc)) {
            storageHelper.set("register", register);
          }
          if (brandName && brandName !== "") {
            storageHelper.set("brandName", brandName);
          }
          if (headerFont && headerFont !== "") {
            storageHelper.set("headerFont", headerFont);
          }
          if (isHideMc) {
            storageHelper.set("hideMcDetails", isHideMc);
          }
        } else {
          storageHelper.remove("brandName", brandName);
        }
        history.push("/login");
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(exception),
      });
    }
  };

  if (!openModal) {
    return null;
  }

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <div className="hide-close-button">
      <div>
        <div className="Polaris-Modal-Dialog__Container" data-polaris-layer="true" data-polaris-overlay="true">
          <div>
            <div className="Polaris-Modal-Dialog">
              <div className="Polaris-Modal-Dialog__Modal">
                <div className="Polaris-Modal__BodyWrapper">
                  <div
                    className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical"
                    data-polaris-scrollable="true"
                  >
                    <section className="Polaris-Modal-Section">
                      <div className="Polaris-Modal-Header__Title">
                        <h1 className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                          {cms("common.subscription.description.para1")}
                          <Link url={emailURL}> {emailAddress}</Link> {cms("common.subscription.description.para2")}
                        </h1>
                        <PageActions
                          primaryAction={{
                            content: cms("common.actions.dashboard.label.logout"),
                            onClick: () => {
                              logout();
                            },
                            loading,
                          }}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Polaris-Backdrop" />
    </div>
  );
};
InformationModal.propTypes = {
  cms: PropTypes.func,
};

InformationModal.defaultProps = {
  cms: {},
};

export default InformationModal;
