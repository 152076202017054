import React, { useContext, useEffect, useState } from "react";
import { Layout, SettingToggle, Stack, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
// import components
import { Banner, Spinner, Toast, ToggleSwitch } from "lib/components";
import { PrivateContext } from "lib/context";

import { GET_ORDER_LIVE_CONNECT_SETTING, UPDATE_ORDER_LIVE_CONNECT_SETTING } from "app/setup/apollo";
import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const OperatorOrderLiveConnectSetting = () => {
  const { gql } = constant;

  const { cms } = useContext(PrivateContext);
  const [message, setMessage] = useState("");
  const [toggleLoader, setToggleLoader] = useState("");

  const [setting, setSetting] = useState({
    isShippingAddress: true,
    isOrderNote: true,
    isBillingAddress: false,
    isCustomer: false,
  });

  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const {
    error: orderLiveConnectSettingError,
    data: orderLiveConnectSettingData,
    loading: orderLiveConnectSettingLoading,
  } = useQuery(GET_ORDER_LIVE_CONNECT_SETTING);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(orderLiveConnectSettingData, gql.GET_ORDER_LIVE_CONNECT_SETTING);
    const responseError = baseHelper.getResponseError(orderLiveConnectSettingError, gql.GET_ORDER_LIVE_CONNECT_SETTING);
    if (responseError) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    if (responseData) {
      setSetting(responseData);
    }
  }, [orderLiveConnectSettingData, orderLiveConnectSettingError]);

  const [updateOrderLiveConnect, { loading: updateOrderLiveConnectLoading }] = useMutation(
    UPDATE_ORDER_LIVE_CONNECT_SETTING
  );

  const updateOrderSetting = async (value, field) => {
    const dataToUpdate = {
      isShippingAddress: value.isShippingAddress,
      isOrderNote: value.isOrderNote,
      isBillingAddress: value.isBillingAddress,
      isCustomer: value.isCustomer,
    };

    const toastMessage = {
      isShippingAddress: value.isShippingAddress
        ? cms("section.message.success.shippingEnable")
        : cms("section.message.success.shippingDisable"),
      isOrderNote: value.isOrderNote
        ? cms("section.message.success.orderEnable")
        : cms("section.message.success.orderDisable"),
      isBillingAddress: value.isBillingAddress
        ? cms("section.message.success.billingEnable")
        : cms("section.message.success.billingDisable"),
      isCustomer: value.isCustomer
        ? cms("section.message.success.customerEnable")
        : cms("section.message.success.customerDisable"),
    };

    try {
      const res = await updateOrderLiveConnect({
        variables: { input: dataToUpdate },
      });
      const resData = baseHelper.getResponseData(res.data, gql.UPDATE_ORDER_LIVE_CONNECT_SETTING);
      if (resData) {
        setMessage(toastMessage[field]);
      }
      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.UPDATE_ORDER_LIVE_CONNECT_SETTING);
        setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: error });
      }
    } catch (exception) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const onSubmit = (field) => {
    const updateSetting = { ...setting };
    updateSetting[field] = !updateSetting[field];
    setSetting(updateSetting);
    setToggleLoader(field);
    updateOrderSetting(updateSetting, field);
  };

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });
  if (orderLiveConnectSettingLoading) {
    return <Spinner />;
  }

  return (
    <>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.shipping.title")} description={cms("section.shipping.description")}>
        <Layout.Section>
          {/* hiding extra action div of SettingToggle component */}
          <div className="setting-toggle">
            <SettingToggle>
              <Stack alignment="center" distribution="equalSpacing">
                <Stack.Item>
                  <TextStyle>
                    {cms("section.shipping.label.shipping")}
                    <TextStyle variation="strong">
                      {` ${setting.isShippingAddress ? cms("label.enable") : cms("label.disable")}.`}
                    </TextStyle>
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <ToggleSwitch
                    id={constant.IS_SHIPPING_ADDRESS}
                    checked={setting.isShippingAddress}
                    loading={updateOrderLiveConnectLoading && toggleLoader === constant.IS_SHIPPING_ADDRESS}
                    onAction={() => onSubmit(constant.IS_SHIPPING_ADDRESS)}
                  />
                </Stack.Item>
              </Stack>
            </SettingToggle>
          </div>
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={cms("section.order.title")} description={cms("section.order.description")}>
        <Layout.Section>
          <div className="setting-toggle">
            <SettingToggle>
              <Stack alignment="center" distribution="equalSpacing">
                <Stack.Item>
                  <TextStyle>
                    {cms("section.order.label.order")}
                    <TextStyle variation="strong">
                      {` ${setting.isOrderNote ? cms("label.enable") : cms("label.disable")}.`}
                    </TextStyle>
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <ToggleSwitch
                    id={constant.IS_ORDER_NOTE}
                    checked={setting.isOrderNote}
                    loading={updateOrderLiveConnectLoading && toggleLoader === constant.IS_ORDER_NOTE}
                    onAction={() => onSubmit(constant.IS_ORDER_NOTE)}
                  />
                </Stack.Item>
              </Stack>
            </SettingToggle>
          </div>
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={cms("section.billing.title")} description={cms("section.billing.description")}>
        <Layout.Section>
          <div className="setting-toggle">
            <SettingToggle>
              <Stack alignment="center" distribution="equalSpacing">
                <Stack.Item>
                  <TextStyle>
                    {cms("section.billing.label.billing")}
                    <TextStyle variation="strong">
                      {` ${setting.isBillingAddress ? cms("label.enable") : cms("label.disable")}.`}
                    </TextStyle>
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <ToggleSwitch
                    id={constant.IS_BILLING_ADDRESS}
                    checked={setting.isBillingAddress}
                    loading={updateOrderLiveConnectLoading && toggleLoader === constant.IS_BILLING_ADDRESS}
                    onAction={() => onSubmit(constant.IS_BILLING_ADDRESS)}
                  />
                </Stack.Item>
              </Stack>
            </SettingToggle>
          </div>
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={cms("section.customer.title")} description={cms("section.customer.description")}>
        <Layout.Section>
          <div className="setting-toggle">
            <SettingToggle>
              <Stack alignment="center" distribution="equalSpacing">
                <Stack.Item>
                  <TextStyle>
                    {cms("section.customer.label.customer")}
                    <TextStyle variation="strong">
                      {` ${setting.isCustomer ? cms("label.enable") : cms("label.disable")}.`}
                    </TextStyle>
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <ToggleSwitch
                    id={constant.IS_CUSTOMER}
                    checked={setting.isCustomer}
                    loading={updateOrderLiveConnectLoading && toggleLoader === constant.IS_CUSTOMER}
                    onAction={() => onSubmit(constant.IS_CUSTOMER)}
                  />
                </Stack.Item>
              </Stack>
            </SettingToggle>
          </div>
        </Layout.Section>
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(OperatorOrderLiveConnectSetting), {
  feature: constant.ORDER_LIVE_CONNECT_SETTING,
});
