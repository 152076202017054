import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { Banner, Spinner } from "lib/components";

import { GET_ORDER_LAKE_LINE } from "app/orderLake/apollo/queries";

import orderLakeHelper from "app/orderLake/modules/orderLakeHelper";

const ExportOrder = () => {
  const data = useContext(PrivateContext);
  const { cms, currentUser } = data;
  const { gql } = constant;
  const isVendor = baseHelper.isVendor(currentUser);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [
    getOrderLines,
    { data: getOrderData, loading: getOrderLoading, error: getOrderError },
  ] = useLazyQuery(GET_ORDER_LAKE_LINE, { variables: { input: queryData } });

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!getOrderLoading && !getOrderError && getOrderData) {
      const resData = baseHelper.getResponseData(getOrderData, gql.GET_ORDER_LAKE_LINE);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getOrderData, gql.GET_ORDER_LAKE_LINE);
        bannerData = {
          title: resError,
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (!resData.length) {
        bannerData = {
          title: cms("common.message.error.noOrder"),
          status: constant.CRITICAL,
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }

      const csvData = orderLakeHelper.orderCSVData(resData, true, false, isVendor);
      const csv = Baby.unparse(csvData);
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      FileDownload(csv, `${"order"} ${date}.csv`);
      setTimeout(() => {
        window.close();
      }, 500);
    }
  }, [getOrderData, getOrderError, getOrderLoading, cms, data, isVendor, gql.GET_ORDER_LAKE_LINE]);

  useEffect(() => {
    const exportOrder = () => {
      const dataToFetch = {};
      const { status, storeStatus, vendor, accountingStatus } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (storeStatus && storeStatus.length) {
        dataToFetch.storeStatus = storeStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      setQueryData(dataToFetch);
      getOrderLines();
    };
    if (isPageLoading) {
      exportOrder();
      setIsPageLoading(false);
    }
  }, [data, getOrderLines, isPageLoading]);

  return (
    <Layout>
      {getOrderLoading && <Spinner isFullPage />}
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(ExportOrder), { feature: constant.EXPORT_ORDER });
