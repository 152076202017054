import React, { useContext, useEffect } from "react";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";

import { SHOPIFY_CALLBACK } from "app/productOld/apollo/mutations";
import { useMutation } from "react-apollo";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const ProviderShopifyCallback = () => {
  const { currentUser, history, cms = () => {}, isLoading } = useContext(PrivateContext);
  const [getShopifyCallback] = useMutation(SHOPIFY_CALLBACK);
  const isOrderConnect = !!(currentUser && currentUser.shop);
  const isUpdatePermission = !!(isOrderConnect && currentUser.isOrderConnect);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const { ecommercePlatform } = currentUser;

  const data = {
    shop: queryParams.shop,
    code: queryParams.code,
  };

  const shopParam = "?shop=true";
  const route =
    (isUpdatePermission && `/${shopParam}`) || (isOrderConnect && `/setting/order${shopParam}`) || ecommercePlatform
      ? `/product-lake/add${shopParam}`
      : `/products/add${shopParam}`;

  // ComponentDidMount: Call GET_SHOPIFY_CALLBACK mutation and show success or error banner
  useEffect(() => {
    if (!isLoading) {
      getShopifyCallback({ variables: { input: data } })
        .then((res) => {
          let bannerData = { title: cms("message.success.shop"), status: constant.SUCCESS };
          const resError = baseHelper.getResponseError(res.data, constant.gql.SAVE_SHOPIFY_VENDOR);
          if (resError) {
            bannerData = { title: resError, status: constant.CRITICAL };
          }
          history.push(route, {
            bannerData,
          });
        })
        .catch((exception) => {
          const bannerData = { title: errorHelper.parse(exception), status: constant.CRITICAL };
          history.push(route, { bannerData });
        });
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Spinner isFullPage />
      <h3 className="text-center" style={{ marginTop: "32%" }}>
        {cms("message.success.wait")}
      </h3>
    </div>
  );
};

export default withErrorBoundary(ProviderShopifyCallback);
