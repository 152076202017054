// import packages
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Caption,
  Card,
  Form,
  FormLayout,
  Layout,
  List,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

// import components
import { Banner, CardFooter } from "lib/components";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import cms and constants
import constant from "lib/constant/constant";

// import props
import priceProp from "app/productOld/modules/operator/features/setting/props";

// import mutation
import { UPDATE_SETTING } from "app/productOld/apollo/mutations";

const { APPROVE_SELECTED, APPROVE_ALL, gql, REVIEW_ALL } = constant;

const Price = (props) => {
  const {
    cms,
    isHideTagAndType,
    isHideTag,
    isHideType,
    lastUpdated,
    // learnMore,
    radioButtonValue,
    rate,
    selected,
    setBannerStatus,
    setRate,
    setSelected,
  } = props || {};
  const [updateProductSetting, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [error, setError] = useState("");
  const {
    value: { MIN_PRICE = 0, MAX_PERCENTAGE },
  } = constant;

  const returnOptions = [
    {
      label: cms("operator.section.productPrice.label.taxInclusive"),
      value: "true",
    },
    {
      label: cms("operator.section.productPrice.label.taxExclusive"),
      value: "false",
    },
  ];

  const renderLists = () => {
    const standardLists = cms("operator.section.productPrice.caption") || [];

    const list = standardLists.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>{` ${caption}`}</Caption>
        </List.Item>
      );
    });
    return list;
  };

  const acceptOnlyValidInput = (val, prevValue, max) => {
    if (parseFloat(val) > MIN_PRICE && parseFloat(val) < max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleValidation = () => {
    if (!rate && selected === "true") {
      setSubmitEnabled(false);
      setError(cms("operator.section.productPrice.message.error.fieldRequired"));
      return;
    }
    setError("");
  };

  const handleSelectChange = (value) => {
    setSelected(value);
    setRate("");
    if (value === "false") {
      setError("");
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  };

  const handleSubmit = async () => {
    if (!submitEnabled || error) {
      return;
    }
    setSubmitEnabled(false);
    updateProductSetting({
      variables: {
        input: {
          isPriceTaxInclusive: selected === "true",
          rate: parseFloat(rate),
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_SETTING);
        let bannerValue = { status: constant.SUCCESS, title: cms("operator.section.productPrice.message.success") };
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_SETTING);
          bannerValue = { status: constant.CRITICAL, title: errorResponse };
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch((exception) => {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  return (
    <Layout.AnnotatedSection
      title={cms("operator.section.productPrice.title")}
      description={cms("operator.section.productPrice.description")}
    >
      <Card
        title={[
          cms("operator.section.productPrice.title"),
          <TextStyle variation="subdued">
            {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
          </TextStyle>,
        ]}
        // actions={[
        //   {
        //     content: cms("common.label.learnMore"),
        //     onAction: () => learnMore(cms("operator.section.productPrice.title")),
        //   },
        // ]}
        sectioned
      >
        <Form onSubmit={() => handleSubmit()}>
          <FormLayout>
            <TextContainer>{cms("operator.section.productPrice.description")}</TextContainer>
            <Stack wrap={false}>
              <Select
                placeholder={cms("operator.section.productPrice.placeHolder.type")}
                options={returnOptions}
                id="isPriceTaxInclusive"
                onChange={(value) => handleSelectChange(value)}
                value={selected}
              />
              <TextField
                id="rate"
                placeholder={cms("common.placeholder.zero")}
                suffix={<div className="suffixColor">%</div>}
                min={0}
                value={rate.toString()}
                onChange={(value) => {
                  setRate(acceptOnlyValidInput(value, rate, MAX_PERCENTAGE));
                  setSubmitEnabled(true);
                }}
                onBlur={() => handleValidation()}
                disabled={selected === "false" || !selected}
                error={error}
              />
            </Stack>
            <Banner isOpen status="critical">
              <p>{cms("common.label.option")}</p>
              <br />
              <List type="bullet">{renderLists()}</List>
            </Banner>
          </FormLayout>
          <CardFooter disabled={!submitEnabled} loading={productSettingLoading} />
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  );
};

Price.propTypes = priceProp.type;

export default withFeature(withErrorBoundary(Price), {
  feature: constant.PRODUCT_PRICE_SETTING,
  // subFeature: constant.PRODUCT_PRICE_SETTING,
});
