import styled from "styled-components";

export const StyledPage = styled.div`
  padding-top: 3.6rem;
  .Polaris-Header-Title__TitleWithMetadataWrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .Polaris-Page-Header__Row {
    align-items: flex-end;
  }
  .tabs-content {
    margin-top: 2.5rem;
  }
  .Polaris-Page-Header {
    margin-bottom: 3rem;
    &.Polaris-Page-Header--hasNavigation {
      display: flex;
      align-items: flex-start;
      .Polaris-Page-Header__Navigation {
        margin-right: 1.5rem;
      }
    }
    .Polaris-Header-Title__TitleMetadata {
      margin-top: 0;
      margin-bottom: 0.7rem;
    }
  }
  .Polaris-Header-Title {
    line-height: 1;
  }
  .help-text {
    display: flex;
    padding: 0.7rem 1rem;
    .Polaris-Icon {
      margin-right: 1rem;
    }
  }

  .Polaris-Header-Title__TitleMetadata {
    vertical-align: top;
    margin-top: 0.5rem;
  }

  .Polaris-Header-Title {
    line-height: 1;
  }
`;

export const LoadingWrapper = styled.div`
  background-color: #fff;
  .Polaris-SkeletonDisplayText__DisplayText {
    max-width: none;
    height: 5.7rem;
  }
`;
