import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Layout } from "@shopify/polaris";
import { baseHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { Banner, SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";
import { GET_TAX_SETTING } from "app/setup/apollo/queries";
import ComissionTax from "./taxSetting/comissionTax";
import ShippingTax from "./taxSetting/shippingTax";

const Tax = () => {
  const [commissionRate, setCommissionRate] = useState("");
  const [commissionUpdatedDate, setCommissionUpdatedDate] = useState("");
  const [selected, setSelected] = useState("");
  const [shippingRate, setShippingRate] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [updatedDate, setUpdatedDate] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { EXCLUSIVE, INCLUSIVE, gql } = constant;
  const { data: getTaxSettingData, refetch, loading: getTaxSettingLoading } = useQuery(GET_TAX_SETTING);
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  useEffect(() => {
    const responseData = baseHelper.getResponseData(getTaxSettingData, gql.GET_TAX_SETTING);
    const responseError = baseHelper.getResponseError(getTaxSettingData, gql.GET_TAX_SETTING);
    if (responseError) {
      setBanner({
        status: constant.CRITICAL,
        title: responseError,
        isOpen: true,
      });
      return;
    }
    if (responseData) {
      const { shipping = {}, commission = {} } = responseData || {};
      const { value: commissionValue = "", updatedAt: commissionDate = "" } = commission || {};
      const { isTaxInclusive = true, value = "", updatedAt: shippingDate = "" } = shipping || {};
      setCommissionUpdatedDate(commissionDate);
      setCommissionRate(commissionValue);
      setUpdatedDate(shippingDate);
      if (isTaxInclusive) {
        setSelected(INCLUSIVE);
        return;
      }
      setSelected(EXCLUSIVE);
      setShippingRate(value);
    }
  }, [EXCLUSIVE, INCLUSIVE, getTaxSettingData, gql.GET_TAX_SETTING, setBanner]);

  if (getTaxSettingLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      <Layout>
        <Layout.Section>
          {banner.isOpen && (
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => dismissBanner()}
              />
            </Layout.Section>
          )}
          <ComissionTax
            commissionRate={commissionRate}
            commissionUpdatedDate={commissionUpdatedDate}
            refetch={refetch}
            setBanner={setBanner}
            setCommissionRate={setCommissionRate}
            setCommissionUpdatedDate={setCommissionUpdatedDate}
            setSubmitEnabled={setSubmitEnabled}
            submitEnabled={submitEnabled}
          />
          <ShippingTax
            refetch={refetch}
            selected={selected}
            setBanner={setBanner}
            setSelected={setSelected}
            setShippingRate={setShippingRate}
            setSubmitEnabled={setSubmitEnabled}
            setUpdatedDate={setUpdatedDate}
            shippingRate={shippingRate}
            submitEnabled={submitEnabled}
            updatedDate={updatedDate}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(Tax), {
  feature: constant.TAX_SETTING,
});
