class CommissionRevenueHelper {
  /**
   * @desc To return orders csv data
   * @param commissionList array of commissionList
   *
   * @return csv data
   *
   */
  commissionCSVData = (commissionList = []) => {
    const newOrderArray = [];
    newOrderArray[0] = ["Vendor Name", "Operator Commission", "Revenue"];

    commissionList.forEach((item, idx) => {
      newOrderArray[idx + 1] = [item.name, item.commissionSeller, item.totalPrice];
    });
    return newOrderArray;
  };
}

export default new CommissionRevenueHelper();
