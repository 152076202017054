import React, { useContext } from "react";
import { ProviderDiscount } from "app/setup/modules/provider/features";
import { OperatorDiscount } from "app/setup/modules/operator/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { provider, operator } = userKey;

const Discount = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === provider) {
      return <ProviderDiscount />;
    }
    if (role === operator) {
      return <OperatorDiscount />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const DiscountComponent = getComponent(currentUserRole);
  return DiscountComponent;
};

export default Discount;
