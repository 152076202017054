import React, { useContext } from "react";
import { AdminEditProfile } from "app/userManagement/modules/admin/features";
import { OperatorEditProfile } from "app/userManagement/modules/operator/features";
import { ProviderEditProfile } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const EditProfile = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminEditProfile />;
    }
    if (role === operator) {
      return <OperatorEditProfile />;
    }
    if (role === provider) {
      return <ProviderEditProfile />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const EditProfileComponent = getComponent(currentUserRole);
  return EditProfileComponent;
};

export default EditProfile;
