import React from "react";

import { ChangePassword } from "app/userManagement/modules/generic";
import { withErrorBoundary } from "lib/hoc";

const AdminChangePassword = () => {
  return <ChangePassword />;
};

export default withErrorBoundary(AdminChangePassword);
