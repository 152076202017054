import { gql } from "apollo-boost";

const UPDATE_SELLER_SETTING = gql`
  mutation updateSellerShippingSetting($input: UpdateSellerSetting) {
    updateSellerShippingSetting(input: $input) {
      data
      status
      error
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { UPDATE_SELLER_SETTING };
