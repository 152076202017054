import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Badge, Caption, Checkbox, Heading, Modal, Stack, TextField, TextStyle, Thumbnail } from "@shopify/polaris";

// import mutation
import { LINE_REFUND } from "app/orders/apollo/mutations";

// import query
import { getOrderLineView } from "app/orders/apollo/viewOrder";

// import from lib
import { baseHelper, errorHelper, imageHelper } from "lib/helpers";
import { Banner } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import style
import { FieldSetQty } from "app/orders/modules/provider/features/view/modal/modalStyle";

// import props
import providerLineRefundProps from "./props";

const ProviderLineRefund = (props) => {
  const { onClose, orderInitiate, refetch, setBanner, setMessage, showModal, setIsLineRefundOption } = props;
  const {
    accountingStatus,
    actualQuantity,
    image,
    orderId,
    orderNumber,
    shopifyLineItemId,
    title: productTitle,
    vendor,
  } = orderInitiate;
  let { fulfillmentStatus } = orderInitiate;
  const { cms } = useContext(PrivateContext);
  const [viewLineOrderData, setViewLineOrderData] = useState([]);
  const [check, setChecked] = useState({ [shopifyLineItemId]: true, isAddToInventory: true });
  const [moneyFormat, setMoneyFormat] = useState(constant.symbol.DOLLAR);
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [refundQuantity, setRefundQuantity] = useState(0);
  const { loading: loadingOrderLineView, error: errorOrderLineView, data: dataOrderLineView } = useQuery(
    getOrderLineView,
    {
      variables: { input: { orderId } },
    }
  );

  const isPartialRefund = fulfillmentStatus === constant.REFUND && !!actualQuantity;

  if (isPartialRefund) {
    fulfillmentStatus = constant.PARTIAL_REFUND;
  }

  useEffect(() => {
    if (dataOrderLineView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, constant.gql.GET_ORDER_LINE_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, constant.gql.GET_ORDER_LINE_VIEW);

      if (orderLineView) {
        setViewLineOrderData(orderLineView);
      }
      if (orderLineViewError) {
        setBanner({
          status: constant.CRITICAL,
          title: orderLineViewError,
          isOpen: true,
        });
      }
    }
  }, [dataOrderLineView, setBanner]);

  useEffect(() => {
    if (errorOrderLineView) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorOrderLineView),
        isOpen: true,
      });
    }
  }, [errorOrderLineView, setBanner]);

  const [updateOrderLineRefund, { data: lineRefundData, loading: lineRefundloading }] = useMutation(LINE_REFUND);
  useEffect(() => {
    if (lineRefundData) {
      const updateRefundData = baseHelper.getResponseData(lineRefundData, constant.gql.LINE_REFUND);
      const updateRefundDataError = baseHelper.getResponseError(lineRefundData, constant.gql.LINE_REFUND);
      if (updateRefundData) {
        setMessage(cms("message.success.refund"));
        refetch();
      }
      if (updateRefundDataError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateRefundDataError,
          isOpen: true,
        });
      }
      onClose();
    }
  }, [lineRefundData, cms, refetch, onClose, setBanner, setMessage]);

  const lineData = viewLineOrderData.find((item) => item.shopifyLineItemId === shopifyLineItemId);

  useEffect(() => {
    if (lineData) {
      const {
        actualQuantity: partialQuantity = 0,
        fulfillableQuantity = 0,
        markUpPrice,
        moneyFormat: moneyData = "",
        priceSet = {},
        quantity: lineQuantity = 0,
        sellerDiscount,
      } = lineData || {};
      const { shopMoney = {} } = priceSet || {};
      const { amount = 0 } = shopMoney || {};
      const lineAmount = amount - markUpPrice + sellerDiscount;
      const moneyformat = moneyData || constant.symbol.DOLLAR;
      let updatedQuantity = (partialQuantity || lineQuantity) - fulfillableQuantity;
      let lineTotalPrice = lineAmount * updatedQuantity;
      setQuantity(updatedQuantity);
      setRefundQuantity(updatedQuantity);
      if (partialQuantity) {
        lineTotalPrice = lineAmount * updatedQuantity;
        updatedQuantity = partialQuantity - fulfillableQuantity;
        setQuantity(updatedQuantity);
        setRefundQuantity(updatedQuantity);
      }
      setMoneyFormat(moneyformat);
      setTotalPrice(lineTotalPrice);
    }
  }, [lineData]);

  const thumbnailImage = image
    ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
    : constant.NOIMAGESNAP;
  const thumbnail = <Thumbnail source={thumbnailImage} size={constant.LARGE} />;

  const renderTitle = (orderNo) => {
    if (!orderNo) {
      return null;
    }
    return (
      <>
        {`${cms("section.lineRefund.label.refund")} #`}
        {orderNo}
      </>
    );
  };

  const acceptOnlyValidInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const buttonDisable = () => {
    if (Number(refundQuantity) === constant.value.ZERO || refundQuantity === "") {
      return true;
    }
    return false;
  };

  const handleQuantityChange = (data, value) => {
    const {
      actualQuantity: partialQuantity = 0,
      fulfillableQuantity = 0,
      markUpPrice,
      priceSet: priceData = {},
      quantity: lineQuantity = 0,
      sellerDiscount,
    } = data || {};
    const { shopMoney: shoppingMoney = {} } = priceData || {};
    const { amount = 0 } = shoppingMoney || {};
    const lineAmount = amount - markUpPrice + sellerDiscount;
    const updatedQuantity = (partialQuantity || lineQuantity) - fulfillableQuantity;
    const lineTotalPrice = lineAmount * value || lineAmount * updatedQuantity;
    setTotalPrice(lineTotalPrice);
    setRefundQuantity(value);
  };

  const handleSubmit = (id, orderid) => {
    const data = {
      isSingle: true,
      refund: {
        isAddToInventory: check.isAddToInventory,
        orderId: orderid,
        orderLineItemId: id,
        quantity: Number(refundQuantity),
        totalQuantity: Number(quantity),
      },
      isRefund: true,
    };
    setIsLineRefundOption(quantity === refundQuantity);
    updateOrderLineRefund({ variables: { input: data } });
  };

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      loading={loadingOrderLineView}
      title={renderTitle(orderNumber)}
      primaryAction={{
        content: cms("section.lineRefund.button.mark"),
        onAction: () => handleSubmit(shopifyLineItemId, orderId),
        loading: lineRefundloading,
        disabled: buttonDisable() || loadingOrderLineView || lineRefundloading,
      }}
      secondaryActions={[
        {
          content: cms("section.lineRefund.button.cancel"),
          onAction: () => onClose(),
          disabled: loadingOrderLineView || lineRefundloading,
        },
      ]}
    >
      <Modal.Section>
        <Banner status={constant.INFO} isOpen isScrollTop={false}>
          <p>{cms("section.lineRefund.modal.banner.para")}</p>
        </Banner>
        <br />
        <Stack wrap={false}>
          {thumbnail}
          <Stack.Item fill>
            <div className="ellipsis">
              <Heading>{productTitle}</Heading>
              <Caption>
                <TextStyle variation="subdued">
                  {`${cms("section.orderList.label.vendor")}: `}
                  <strong>{vendor}</strong>
                </TextStyle>
              </Caption>
            </div>
            <Caption>
              {`${cms("section.orderList.label.lineItemId")}: `}
              <span>{`#${shopifyLineItemId}`}</span>
            </Caption>
            <div className="badge-space">
              <Stack>
                <Stack.Item>
                  <Badge status={baseHelper.getBadgeType(accountingStatus)}>
                    {baseHelper.ucFirst(accountingStatus)}
                  </Badge>
                </Stack.Item>
                <Stack.Item>
                  <Badge>{baseHelper.ucFirst(fulfillmentStatus)}</Badge>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack vertical>
            <Stack.Item>
              {`${cms("section.lineRefund.label.quantity")}: `}
              {`${quantity} `}
              &nbsp;&nbsp;
              {`${moneyFormat}`}
              {(totalPrice && baseHelper.getPrice(totalPrice)) || constant.value.ZERO}
            </Stack.Item>
            <FieldSetQty>
              <Stack.Item>
                <TextField
                  value={(refundQuantity && refundQuantity.toString()) || ""}
                  min={0}
                  max={quantity}
                  onChange={(value) => [
                    value <= parseInt(quantity, 10) &&
                      handleQuantityChange(lineData, acceptOnlyValidInput(value, refundQuantity)),
                  ]}
                  suffix={`of ${quantity}`}
                />
              </Stack.Item>
            </FieldSetQty>
          </Stack>
        </Stack>
        <br />
        <Checkbox
          label={cms("section.lineRefund.modal.checkbox.label")}
          checked={check[shopifyLineItemId]}
          onChange={(value) => setChecked({ [shopifyLineItemId]: value, isAddToInventory: value })}
        />
      </Modal.Section>
    </Modal>
  );
};

ProviderLineRefund.propTypes = providerLineRefundProps.type;

export default ProviderLineRefund;
