// import packages
import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Caption, Card, Checkbox, Layout, PageActions, Stack, TextContainer, TextStyle } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner, Toast } from "lib/components";
// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import { UPDATE_SETTING } from "app/productOld/apollo/mutations";

const AutoPublish = (props) => {
  const {
    isAutoPublish,
    isHideTagAndType,
    lastUpdated,
    radioButtonValue,
    rate,
    selected,
    setIsAutoPublish,
    refetch,
  } = props;
  const [buttonValue, setButtonValue] = useState(true);
  const [message, setMessage] = useState("");
  const { cms, history } = useContext(PrivateContext);
  const [updateProductSetting, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);
  const [banner, setBanner] = useState({
    status: "",
    title: "",
    onDismiss: null,
  });

  const handleChange = (value) => {
    setIsAutoPublish(value);
    setButtonValue(false);
  };
  const onSubmit = () => {
    setButtonValue(true);
    updateProductSetting({
      variables: {
        input: {
          isAutoPublish,
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
          setBanner({ status: constant.CRITICAL, title: errorResponse, isOpen: true });
        }
        if (isAutoPublish) {
          setMessage(cms("message.success.checked"));
        } else {
          setMessage(cms("message.success.unchecked"));
        }
        refetch();
      })
      .catch((exception) => {
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  const renderBanner = () => {
    const { status, title, isOpen } = banner;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  return (
    <>
      {banner.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title={cms("section.publish.title")} description={cms("section.publish.description")}>
        <Card
          title={[
            cms("section.publish.heading"),
            lastUpdated && (
              <TextStyle variation="subdued">
                {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <Stack vertical>
              <TextContainer>{cms("section.publish.content.description")}</TextContainer>
            </Stack>
            <br />
            <Checkbox label={cms("button.publish")} checked={isAutoPublish} onChange={handleChange} />
            {lastUpdated && (
              <div className="manageVendorAccessButton">
                <PageActions
                  primaryAction={{
                    id: "submitProductSetting",
                    content: cms("common.button.update"),
                    loading: productSettingLoading,
                    disabled: productSettingLoading || buttonValue,
                    onAction: () => onSubmit(),
                  }}
                  secondaryActions={[
                    {
                      id: "cancelProductSetting",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {!lastUpdated && (
          <PageActions
            primaryAction={{
              id: "submitProductSetting",
              content: cms("common.button.submit"),
              loading: productSettingLoading,
              disabled: productSettingLoading || buttonValue,
              onAction: () => onSubmit(),
            }}
            secondaryActions={[
              {
                id: "cancelProductSetting",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/"),
              },
            ]}
          />
        )}
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
      <br />
    </>
  );
};

export default withFeature(withErrorBoundary(AutoPublish), {
  feature: constant.PRODUCT_AUTOPUBLISH_SETTING,
});
