import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Collapsible,
  Form,
  FormLayout,
  Layout,
  PageActions,
  TextContainer,
  Banner,
  TextStyle,
  Caption,
} from "@shopify/polaris";

import { useQuery, useMutation } from "react-apollo";

import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";

import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Spinner, Toast } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { GET_VENDOR_SETTING_LOCATION, GET_VENDOR_LOCATION, GET_SELLER_LOCATION } from "app/setup/apollo/queries";
import { SYNC_LOCATION, UPDATE_VENDOR_LOCATION } from "app/setup/apollo/mutations";
import AddMapperField from "./component/add";

const ProviderLocationMapper = () => {
  const { cms = {}, history, isLoading, currentUserRefetch } = useContext(PrivateContext);
  const [country, setCountry] = useState([]);
  const [message, setMessage] = useState();
  const [updateAt, setUpdateAt] = useState();
  const [countryList, setCountryList] = useState([]);
  const [sellerLocation, setSellerLocation] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const {
    error: errorVendorSetingLocation,
    loading: loadingVendorSettingLocation,
    data: dataVendorSettingLocation,
    refetch: refetchVendorData,
  } = useQuery(GET_VENDOR_SETTING_LOCATION);

  const {
    error: errorSellerLocation,
    loading: loadingSellerLocation,
    data: dataSellerLocation,
    refetch: refectSellerData,
  } = useQuery(GET_SELLER_LOCATION);
  const sellerLocationResponseData = baseHelper.getResponseData(dataSellerLocation, constant.gql.GET_SELLER_LOCATION);

  const vendorSettingLocationResponseData = baseHelper.getResponseData(
    dataVendorSettingLocation,
    constant.gql.GET_VENDOR_SETTING_LOCATION
  );

  const { error: errorVendorLocation, loading: loadingVendorLocation, data: dataVendorLocation, refetch } = useQuery(
    GET_VENDOR_LOCATION
  );

  const vendorLocationResponseData = baseHelper.getResponseData(dataVendorLocation, constant.gql.GET_VENDOR_LOCATION);

  const [syncLakeLocation, { loading: syncVendorLoading }] = useMutation(SYNC_LOCATION);

  const [updateVendorLocation, { loading: isLoadingLabel }] = useMutation(UPDATE_VENDOR_LOCATION);
  useEffect(() => {
    const vendorLocationQueryError = baseHelper.getResponseError(errorVendorLocation, constant.gql.GET_VENDOR_LOCATION);

    if (vendorLocationQueryError) {
      setBanner({
        isOpen: true,
        title: vendorLocationQueryError,
        status: constant.CRITICAL,
      });
    }

    if (!(countryList && countryList.length) && vendorLocationResponseData && vendorLocationResponseData.length) {
      const updatedCountryList = vendorLocationResponseData.map((item) => {
        return { label: item.countryName, value: item.countryCode };
      });
      setCountryList(updatedCountryList || []);
    }
  }, [vendorLocationResponseData]);

  useEffect(() => {
    const sellerLocationQueryError = baseHelper.getResponseError(errorSellerLocation, constant.gql.GET_SELLER_LOCATION);

    if (sellerLocationQueryError) {
      setBanner({
        isOpen: true,
        title: sellerLocationQueryError,
        status: constant.CRITICAL,
      });
    }

    const vendorSettingLocationQueryError = baseHelper.getResponseError(
      errorVendorSetingLocation,
      constant.gql.GET_VENDOR_SETTING_LOCATION
    );

    if (vendorSettingLocationQueryError) {
      setBanner({
        isOpen: true,
        title: vendorSettingLocationQueryError,
        status: constant.CRITICAL,
      });
    }

    if (sellerLocationResponseData) {
      const listData = [...countryList];
      const { locations } = sellerLocationResponseData;
      const { locations: vendorLocation, updatedAt } = vendorSettingLocationResponseData || {};
      const tempArr = [];
      setSellerLocation(locations || []);
      (locations || []).forEach((item) => {
        const vendorData = (vendorLocation || []).find((vendorLocationItem) => vendorLocationItem.seller === item);
        if (!vendorData) {
          tempArr.push({ seller: item, vendor: "" });
        } else {
          tempArr.push(vendorData);
        }
      });
      listData.forEach((item) => {
        const vendorData = (vendorLocation || []).find(
          (vendorLocationItem) => vendorLocationItem.vendor === item.value
        );
      });
      setCountryList(listData);
      if (updatedAt) {
        setUpdateAt(updatedAt);
      }
      setCountry(tempArr);
    }
  }, [sellerLocationResponseData, vendorSettingLocationResponseData, errorSellerLocation, errorVendorSetingLocation]);

  const updateVal = (value, id) => {
    const advanced = [...country];
    advanced[id].vendor = value;
    setCountry([...advanced]);
    setIsDisabled(false);
  };

  const onSubmit = () => {
    let filteredData = (country || []).map((item) => ({ seller: item.seller, vendor: item.vendor }));
    filteredData = filteredData.filter((item) => sellerLocation.includes(item.seller));
    updateVendorLocation({
      variables: {
        input: { locations: filteredData },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_VENDOR_LOCATION);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_VENDOR_LOCATION);
        if (responseData) {
          setMessage(cms("message.success.update"));
          setIsDisabled(true);
          refetchVendorData();
        }
        if (responseError) {
          setBanner({
            isOpen: true,
            title: responseError,
            status: constant.CRITICAL,
          });
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          title: errorHelper.parse(exception),
          status: constant.CRITICAL,
        });
      });
  };

  const syncProducts = async () => {
    try {
      const response = await syncLakeLocation();
      const responseError = baseHelper.getResponseError(response.data, SYNC_LOCATION);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("common.message.success.background"),
      });
      setTimeout(() => {
        currentUserRefetch();
        refetch();
        refectSellerData();
        refetchVendorData();
      }, 3000);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  if (loadingVendorLocation || loadingSellerLocation || isLoading || loadingVendorSettingLocation) {
    return <Spinner />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card
          title={[
            cms("section.title"),
            updateAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          actions={{
            content: syncVendorLoading ? <Spinner size="small" /> : "Sync Location",
            onAction: () => syncProducts(),
          }}
        >
          <Card.Section>
            <TextContainer>
              {!country.length ? cms("section.notLocationData") : cms("section.providerDescription")}
            </TextContainer>
            <br />
            <Collapsible
              open
              id="advancedCommission"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <Form>
                <FormLayout>
                  <AddMapperField
                    country={country}
                    cms={cms}
                    countryList={countryList}
                    updateVal={updateVal}
                    count={country.length}
                  />
                </FormLayout>

                {updateAt && (
                  <>
                    <ActionButton>
                      <PageActions
                        primaryAction={{
                          content: cms("common.button.update"),
                          onAction: () => onSubmit(),
                          disabled: isDisabled,
                          loading: isLoadingLabel,
                        }}
                        secondaryActions={[
                          {
                            content: cms("common.button.cancel"),
                            onAction: () => history.push("/setting"),
                          },
                        ]}
                      />
                    </ActionButton>
                  </>
                )}
              </Form>
            </Collapsible>
          </Card.Section>
        </Card>
        {!updateAt && (
          <ActionButton>
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => onSubmit(),
                loading: isLoadingLabel,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          </ActionButton>
        )}
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderLocationMapper), {
  feature: constant.PROVIDER_LOCATION_MAPPING_SETTING,
});
