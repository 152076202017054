import React, { useContext } from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

const PaymentSection = (props) => {
  const {
    handleChange,
    paymentInformation,
    handlePaymentValidation,
    errorPaymentMessage,
    // learnMore,
    lastUpdated,
  } = props;
  const { cms } = useContext(PrivateContext);

  const getFields = () => {
    const fields = [
      {
        label: cms("section.payment.label.name"),
        placeholder: cms("section.payment.placeHolder.name"),
        isRequired: true,
        key: "bankName",
      },
      {
        label: cms("section.payment.label.accountNumber"),
        placeholder: cms("section.payment.placeHolder.accountNumber"),
        isRequired: true,
        key: "accountNumber",
      },
      {
        label: cms("section.payment.label.sortCode"),
        placeholder: cms("section.payment.placeHolder.sortCode"),
        isRequired: true,
        key: "sortCode",
      },
      {
        label: cms("section.payment.label.country"),
        placeholder: cms("section.payment.placeHolder.country"),
        isRequired: true,
        key: "country",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <TextField
          label={`${label}${required}`}
          placeholder={placeholder}
          value={paymentInformation[key]}
          onChange={(value) => handleChange(key, value)}
          onBlur={() => (!isRequired && "") || handlePaymentValidation(key, paymentInformation[key])}
          error={errorPaymentMessage && errorPaymentMessage[key]}
        />
      );
    });
  };
  return (
    <Layout.AnnotatedSection title={cms("section.payment.title")} description={cms("section.payment.description")}>
      <Card
        title={[
          cms("section.payment.title"),
          lastUpdated && (
            <Caption>
              <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
            </Caption>
          ),
        ]}
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore(cms("section.payment.title"), "TODO:");
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.payment.description")}</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

PaymentSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handlePaymentValidation: PropTypes.func.isRequired,
  paymentInformation: PropTypes.shape({
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    sortCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errorPaymentMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  // learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

PaymentSection.defaultProps = {
  errorPaymentMessage: PropTypes.bool,
  lastUpdated: "",
};

export default PaymentSection;
