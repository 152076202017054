import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";

import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

import { CREATE_SHOPIFY_CHARGE } from "app/plans/apollo/mutations";

import OperatorPlan from "app/plans/modules/operator/features/generic/plan/newPlan";

const UpdatePlanShopify = () => {
  const { currentUser } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [error, setError] = useState("");

  const [createShopifyCharge, { loading }] = useMutation(CREATE_SHOPIFY_CHARGE);

  const setURL = (url) => {
    window.top.location = url;
  };

  const onSubmit = (plan) => {
    createShopifyCharge({
      variables: {
        input: {
          code: plan.customCode || plan.code,
          isUpdatePlan: true,
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.CREATE_SHOPIFY_CHARGE);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.CREATE_SHOPIFY_CHARGE);

        if (responseError) {
          setError(responseError);
          return false;
        }

        if (responseData && responseData.confirmation_url) {
          const isIframe = baseHelper.isIframe();
          if (isIframe) {
            setURL(responseData.confirmation_url);
            return true;
          }
          window.location.href = responseData.confirmation_url;
        } else {
          setError(errorHelper.parse());
        }
        return true;
      })
      .catch((exception) => {
        const parsedError = errorHelper.parse(exception);
        setError(parsedError);
      });
  };

  const currentPlan = currentUser && currentUser.plan && currentUser.plan.code;

  return (
    <OperatorPlan
      banner={banner}
      currentPlan={currentPlan}
      error={error}
      onSubmit={onSubmit}
      setBanner={setBanner}
      submitLoading={loading}
    />
  );
};

export default withErrorBoundary(UpdatePlanShopify);
