import styled from "styled-components";

export const StyledActionCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  > button {
    &:first-of-type {
      background: #008060;
      color: #fff;
      border: 0;
    }
    & + button {
      margin-left: auto;
    }
  }
  > div {
    margin-left: 1rem;
    display: flex;
    > div:first-child {
      margin-right: 1rem;
    }
  }
`;

export const DropDown = styled.ul`
  list-style: none;
  padding: 1.5rem;
  margin: 0;
  li {
    & + li {
      margin-top: 0.7rem;
    }
    button {
      color: #202223;
    }
  }
`;
