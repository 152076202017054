import { gql } from "apollo-boost";

const FETCH_CMS = gql`
  query getContent($input: GetContent) {
    getContent(input: $input) {
      data {
        button {
          code
          en
        }
        error {
          code
          en
        }
        label {
          code
          en
        }
        message {
          code
          en
        }
        pageTitle {
          code
          en
        }
        placeHolder {
          code
          en
        }
        sectionContent {
          code
          en
        }
        sectionLabel {
          code
          en
        }
      }
      status
      error
    }
  }
`;

export default FETCH_CMS;
