import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { Caption, Card, Layout, Modal, SettingToggle, TextContainer, TextStyle } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, Toast, SkeletonAnnotated } from "lib/components";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_VENDOR_DISCOUNT_SETTING } from "app/setup/apollo/queries";
import { UPDATE_VENDOR_DISCOUNT_SETTING } from "app/setup/apollo/mutations";

function ProviderDiscount() {
  const { cms } = useContext(PrivateContext);
  const [active, setActive] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [message, setMessage] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  const [isAccept, setIsAccept] = useState();
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [setDiscountData, { loading: isLoadingLabel }] = useMutation(UPDATE_VENDOR_DISCOUNT_SETTING);
  const { data: dynamicDiscountData, loading: getDiscountLoading, refetch } = useQuery(GET_VENDOR_DISCOUNT_SETTING);

  const dynamicDiscount = baseHelper.getResponseData(dynamicDiscountData, constant.gql.GET_VENDOR_DISCOUNT_SETTING);
  const dynamicDiscountErrorData = baseHelper.getResponseError(
    dynamicDiscountData,
    constant.gql.GET_VENDOR_DISCOUNT_SETTING
  );

  useEffect(() => {
    const { isAccept: isAccepted = false, isEnabled: isEnable = false, updatedAt = "" } = dynamicDiscount || {};
    setIsAccept(isAccepted);
    setIsEnabled(isEnable);
    setUpdatedDate(updatedAt);
  }, [dynamicDiscount]);

  useEffect(() => {
    if (dynamicDiscountErrorData) {
      setBannerStatus({ status: constant.CRITICAL, title: dynamicDiscountErrorData, isOpen: true });
    }
  }, [dynamicDiscountErrorData]);

  const contentStatus = isAccept ? constant.DISABLE : constant.ENABLE;

  const setButtonColor = isAccept;

  const onSubmit = async () => {
    try {
      setActive(!active);
      const response = await setDiscountData({
        variables: {
          input: {
            isAccept: !isAccept,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_VENDOR_DISCOUNT_SETTING);
      if (responseError) {
        setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (response && updatedDate) {
        setMessage(cms("message.success.update"));
        setIsAccept(!isAccept);
      } else {
        setMessage(cms("message.success.save"));
        setIsAccept(!isAccept);
      }
      refetch();
    } catch (exception) {
      setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleToggle = () => {
    setActive(!active);
  };

  const handleChange = useCallback(() => {
    setActive(!active);
  }, [active]);

  const renderPopup = () => {
    return (
      <Modal
        open={active}
        onClose={handleChange}
        title={isAccept ? cms("section.discount.modal.rejectTitle") : cms("section.discount.modal.acceptTitle")}
        primaryAction={{
          content: cms("common.button.confirm"),
          onAction: () => onSubmit(),
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: () => handleChange(),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {isAccept
              ? cms("section.discount.modal.rejectDescription")
              : cms("section.discount.modal.acceptDescription")}
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };

  if (getDiscountLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <Layout>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.Section>
        <Layout.AnnotatedSection title={cms("vendorTitle")} description={cms("vendorDescription")}>
          {renderPopup()}
          <Card sectioned>
            <div className="toggle-action toggle-space">
              <TextStyle variation="strong">{cms("section.discount.title")}</TextStyle>
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedDate)}`}</Caption>
              </TextStyle>
              <br />
              <TextStyle>{cms("section.discount.vendorDescription")}</TextStyle>
              <SettingToggle
                action={{
                  content: contentStatus,
                  onAction: handleToggle,
                  destructive: setButtonColor,
                  loading: isLoadingLabel,
                  disabled: !isEnabled,
                }}
                enabled={setButtonColor}
              />
            </div>
          </Card>
          <div className="toast">
            <Toast message={message} setToast={setMessage} />
          </div>
        </Layout.AnnotatedSection>
      </Layout.Section>
    </Layout>
  );
}
export default withFeature(withErrorBoundary(ProviderDiscount), {
  feature: constant.ORDER_DISCOUNT_SETTING,
});
