import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Card,
  Heading,
  Layout,
  PageActions,
  RadioButton,
  Select,
  Stack,
  Tabs,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

import { baseHelper, errorHelper, featureHelper } from "lib/helpers";
import { GET_SELLER_FULFILLMENT, UPDATE_SELLER_FULFILLMENT } from "app/setup/apollo";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "react-apollo";
import { Banner, SkeletonAnnotated } from "lib/components";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { FulfillmentProvider } from "./context";

// sub feature
import Hybrid from "./hybrid/hybrid";
import DropShip from "./dropShip/dropShip";
import ConsignmentShip from "./consignmentShip/consignmentShip";
import ConsignmentShipMethod from "./consignmentShip/consignmentShippingMethod";
import ConsignmentPickup from "./consignmentPickup/defaultPickup";
import DefaultConsignmentPickup from "./consignmentPickup/consignmentPickup";
import ClickReserve from "./clickReserve/clickReserve";
import ClickCollect from "./clickCollect/clickCollect";
import TimeService from "./timeService/timeService";

import hybridTabs from "./hybrid/hybridConfig";

import validate from "./yup";

const { featureError: featureErrorKeys } = constant;

const Fulfillment = () => {
  const { cms, currentUser, featureFlag, history } = useContext(PrivateContext);
  const { _id: currentUserId } = currentUser;
  const isEcommerceUser = baseHelper.isEcommerceUser(currentUser);
  const [value, setValue] = useState(constant.DROPSHIP);

  const [isConsignmentPickupTab, setIsConsignmentPickupTab] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPanel, setIsPanel] = useState(false);
  const [isShowingConsignmentShipTab, setIsShowingConsignmentShipTab] = useState(false);
  const [isShowingDropShipTab, setIsShowingDropShipTab] = useState(isEcommerceUser || false);
  const [submitEnabled, setSubmitEnable] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const [onSubmitError, setOnSubmitError] = useState("");
  const [selectHybridOption, setSelectHybridOption] = useState("");
  const [sendaboxApiKeyAndAccessKey, setSendaboxApiAndAccessKey] = useState("");
  const [shippoApiKey, setShippoApiKey] = useState("");
  const [consignmentShippoApiKey, setconsignmentShippoApiKey] = useState("");
  const [shippyProApiKey, setShippyProApiKey] = useState("");

  const [upsAccessLicenseNumber, setUPSAccessLicenseNumber] = useState("");
  const [error, setError] = useState();

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [infoBanner, setInfoBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const { loading: fulfillmentLoading, data: fulfillmentData, error: fulfillmentError, refetch } = useQuery(
    GET_SELLER_FULFILLMENT
  );
  const [updateFulfillment, { loading: updateFulfillmentLoading }] = useMutation(UPDATE_SELLER_FULFILLMENT);

  const availableOptions = [
    {
      feature: constant.DROPSHIP,
      label: cms("label.dropship"),
      value: constant.HYBRID_DROPSHIP,
    },
    {
      feature: constant.CONSIGNMENT_SHIP,
      label: cms("label.consignmentShip"),
      value: constant.HYBRID_CONSIGNMENT,
    },
    {
      feature: constant.CONSIGNMENT_PICKUP,
      label: cms("label.consignmentPickup"),
      value: constant.HYBRID_PICKUP,
    },
    {
      feature: constant.CLICK_COLLECT,
      label: cms("label.clickCollect"),
      value: constant.HYBRID_COLLECT,
    },
    {
      feature: constant.CLICK_RESERVE,
      label: cms("label.clickReserve"),
      value: constant.HYBRID_RESERVE,
    },
    {
      feature: constant.TIME_SERVICE,
      label: cms("label.timeService"),
      value: constant.HYBRID_SERVICE,
    },
  ];

  const hybridOption = availableOptions
    // eslint-disable-next-line no-shadow
    .map(({ label, value, feature }) => {
      let updatedLabel = label;

      const featureStatus = featureHelper.checkFeature({ currentUser, featureFlag, feature });
      const featureError = featureStatus && featureStatus.error;
      if (featureError && featureError === featureErrorKeys.hideFeature) {
        return false;
      }

      if (featureError) {
        const featureReason =
          (featureError === featureErrorKeys.roleNotAllowed && cms("message.error.notAllowed")) ||
          (featureError === featureErrorKeys.needPlanUpgrade && cms("message.error.planUpgrade")) ||
          cms("message.error.comingSoon");
        updatedLabel = `${label} (${featureReason})`;
      }

      return { disabled: !!featureError, label: updatedLabel, value };
    })
    .filter((item) => item);

  const [hybridDropShipTabs, setHybridDropShipTabs] = useState([
    {
      id: "setup",
      content: cms("tab.setup"),
      panelID: "setup",
    },
  ]);

  const [dropship, setDropship] = useState({});
  const [consignmentPickup, setConsignmentPickup] = useState({});
  const [consignmentShip, setConsignmentShip] = useState([]);
  const [timeService, setTimeService] = useState({});
  const [clickCollect, setClickCollect] = useState({});
  const [clickReserve, setClickReserve] = useState({});
  const [type, setType] = useState(constant.DROPSHIP);
  const [defaultData, setDefaultData] = useState();

  const onHandleChange = (val) => {
    setValue(val);
    setIsPanel(false);
    setIsShowingConsignmentShipTab(false);
    setIsShowingDropShipTab(false);
    setIsConsignmentPickupTab(false);
    if (val === constant.HYBRID && type === constant.HYBRID) {
      setIsPanel(true);
      setIsShowingConsignmentShipTab(false);
      setIsShowingDropShipTab(false);
      setSelectHybridOption(constant.HYBRID.concat(baseHelper.ucFirst(defaultData)));
    }
    if (val === constant.DROPSHIP && type === constant.DROPSHIP) {
      setIsPanel(false);
      setIsShowingConsignmentShipTab(false);
      setIsConsignmentPickupTab(false);
      setIsShowingDropShipTab(true);
    }
    if (val === constant.CONSIGNMENT) {
      setSubmitEnable(false);
      if (type === constant.CONSIGNMENT) {
        setIsPanel(false);
        setIsShowingConsignmentShipTab(true);
        setIsConsignmentPickupTab(false);
        setIsShowingDropShipTab(false);
      }
      setDropship({ isThirdPartyShipping: false });
    }
    if (val === constant.PICKUP && type === constant.PICKUP) {
      setDropship({ isThirdPartyShipping: false });
      setIsPanel(false);
      setIsShowingConsignmentShipTab(false);
      setIsShowingDropShipTab(false);
      setIsConsignmentPickupTab(true);
    }
    if (val === constant.HYBRID) {
      setSubmitEnable(false);
      return;
    }
    setSubmitEnable(true);
  };
  const onHandleSelect = (val) => {
    setSelectHybridOption(val);
    setSubmitEnable(true);
    switch (val) {
      case constant.HYBRID_DROPSHIP:
        setDefaultData(constant.DROPSHIP);
        break;
      case constant.HYBRID_CONSIGNMENT:
        setDefaultData(constant.CONSIGNMENT);
        break;
      case constant.HYBRID_PICKUP:
        setDefaultData(constant.PICKUP);
        break;
      case constant.HYBRID_RESERVE:
        setDefaultData(constant.RESERVE);
        break;
      case constant.HYBRID_COLLECT:
        setDefaultData(constant.COLLECT);
        break;
      case constant.HYBRID_SERVICE:
        setDefaultData(constant.SERVICE);
        break;
      default:
        break;
    }
    // if (val === "hybridDropship" || val === "hybridConsignment" || val === "hybridPickup") {
    //   const validTabs = hybridDropShipTabs.length;
    //   if (validTabs === 1) {
    //     hybridTabs.map((tab) => {
    //       return hybridDropShipTabs.push(tab);
    //     });
    //   }
    //   setHybridDropShipTabs(hybridDropShipTabs);
    //   setIsPanel(true);
    // }
  };

  useEffect(() => {
    if (fulfillmentError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(fulfillmentError) });
    }
    if (!fulfillmentData) {
      return;
    }
    const responseData = baseHelper.getResponseData(fulfillmentData, constant.gql.GET_SELLER_FULFILLMENT);
    if (!responseData) {
      return;
    }
    if (responseData) {
      const {
        clickCollect: clickCollectValue,
        clickReserve: clickReserveValue,
        timeService: timeServiceValue,
        consignmentShip: consignmentShipValue,
        consignmentPickup: consignmentPickupValue,
        default: defaultValue,
        dropship: dropshipValue,
        type: typeValue,
      } = responseData;

      if (consignmentShipValue && consignmentShipValue.shippo && consignmentShipValue.shippo.apiKey) {
        const {
          shippo: { apiKey },
        } = consignmentShipValue;
        setconsignmentShippoApiKey(apiKey);
        //setInfoBanner({ isOpen: true, status: constant.INFO, title: cms("message.defaultAddress") });
      }
      if (
        dropshipValue &&
        dropshipValue.sendabox &&
        dropshipValue.sendabox.apiKey &&
        dropshipValue.sendabox.accessKey
      ) {
        const {
          sendabox: { apiKey, accessKey },
        } = dropshipValue;
        setSendaboxApiAndAccessKey(apiKey && accessKey ? `${apiKey}-${accessKey}` : "");
      }
      if (dropshipValue && dropshipValue.shippyPro && dropshipValue.shippyPro.apiKey) {
        const {
          shippyPro: { apiKey },
        } = dropshipValue;
        setShippyProApiKey(apiKey);
      }
      if (dropshipValue && dropshipValue.shippo && dropshipValue.shippo.apiKey) {
        const {
          shippo: { apiKey },
        } = dropshipValue;
        setShippoApiKey(apiKey);
      }
      if (dropshipValue && dropshipValue.ups && dropshipValue.ups.accessLicenseNumber) {
        const {
          ups: { accessLicenseNumber },
        } = dropshipValue;
        setUPSAccessLicenseNumber(accessLicenseNumber);
      }
      setDropship(dropshipValue);
      setTimeService(timeServiceValue || {});
      setClickCollect(clickCollectValue || {});
      setClickReserve(clickReserveValue || {});
      setConsignmentPickup(consignmentPickupValue || []);
      setConsignmentShip(consignmentShipValue || []);
      setDefaultData(defaultValue);
      setType(typeValue);
      setValue(typeValue);
      // onHandleChange(typeValue);
      if (typeValue === constant.DROPSHIP) {
        setIsShowingDropShipTab(true);
        setIsPanel(false);
        setIsShowingConsignmentShipTab(false);
        setIsConsignmentPickupTab(false);
      }

      if (typeValue === constant.CONSIGNMENT) {
        setIsShowingConsignmentShipTab(true);
        setIsPanel(false);
        setIsShowingDropShipTab(false);
        setIsConsignmentPickupTab(false);
      }
      if (typeValue === constant.PICKUP) {
        setIsShowingConsignmentShipTab(false);
        setIsPanel(false);
        setIsShowingDropShipTab(false);
        setIsConsignmentPickupTab(true);
      }
      if ([constant.PICKUP, constant.HYBRID].includes(typeValue)) {
        setIsShowingDropShipTab(false);
        setIsShowingConsignmentShipTab(false);
        setIsPanel(false);
        // if (typeValue === "pickup") {
        //   setSubmitEnable(true);
        // }
      }
      const hybridDefault = baseHelper.ucFirst(defaultValue);
      const hybridSelectedOption = typeValue.concat(hybridDefault);
      if (typeValue === constant.HYBRID) {
        onHandleSelect(hybridSelectedOption);
        if (
          hybridSelectedOption === constant.HYBRID_DROPSHIP ||
          hybridSelectedOption === constant.HYBRID_CONSIGNMENT ||
          hybridSelectedOption === constant.HYBRID_PICKUP ||
          hybridSelectedOption === constant.HYBRID_RESERVE ||
          hybridSelectedOption === constant.HYBRID_COLLECT ||
          hybridSelectedOption === constant.HYBRID_SERVICE
        ) {
          const validTabs = hybridDropShipTabs.length;
          if (validTabs === 1) {
            hybridTabs(cms, hybridOption, constant).map((tab) => {
              return hybridDropShipTabs.push(tab);
            });
          }
          setHybridDropShipTabs(hybridDropShipTabs);
          setIsPanel(true);
        }
      }
      if (typeValue !== constant.HYBRID) {
        setSelectHybridOption("");
      }
      setSubmitEnable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentData]);

  const fulfillmentTabs = [
    {
      id: "setup",
      content: cms("tab.setup"),
      panelID: "setup",
    },
  ];

  if (isShowingDropShipTab) {
    const dropShipTab = {
      id: "dropShip",
      content: cms("tab.dropShip"),
      panelID: "dropShip",
    };
    fulfillmentTabs.push(dropShipTab);
  }

  if (isShowingConsignmentShipTab) {
    const consignmentShipTab = {
      id: "consignmentShip",
      content: cms("tab.consignmentShip"),
      panelID: "consignmentShip",
    };
    fulfillmentTabs.push(consignmentShipTab);
  }

  if (isConsignmentPickupTab) {
    const consignmentPickupTab = {
      id: "consignmentPickup",
      content: "Consignment Pickup",
      // content: cms("tab.consignmentPickup"),
      panelID: "consignmentPickup",
    };
    fulfillmentTabs.push(consignmentPickupTab);
  }

  const handleSelectTabChange = useCallback((selectedTabIndex) => setSelectedTab(selectedTabIndex), []);
  const handleValidation = async (field, val) => {
    const errorMessage = await validate(field, val, cms);
    setError((prevState) => ({
      ...prevState,
      [field]: errorMessage,
    }));
  };

  useEffect(() => {
    if (error) {
      if (Object.values(error).filter((i) => !!i).length) {
        setSubmitEnable(false);
      }
    }
  }, [error]);

  const onSubmitCallback = async () => {
    const formData = {
      clickCollect: clickCollect || {},
      clickReserve: clickReserve || {},
      timeService: timeService || {},
      consignmentShip: consignmentShip || {},
      type: value,
      dropship: dropship || {},
      consignmentPickup: consignmentPickup || {},
    };
    if (value === constant.HYBRID) {
      formData.default = defaultData;
    }

    try {
      const response = await updateFulfillment({
        variables: {
          input: formData,
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_FULFILLMENT);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.UPDATE_FULFILLMENT);
     
      if((value=== constant.CONSIGNMENT || (consignmentShip?.shippingOption === constant.SHIPPO && value===constant.HYBRID )) && formData?.consignmentShip?.isThirdPartyShipping){
        setInfoBanner({ isOpen: true, status: constant.INFO, title: cms("message.defaultAddress") });
      }     
     
      if (type === constant.HYBRID && consignmentShip && consignmentShip.shippo && consignmentShip.shippo.apiKey) {
        const {
          shippo: { apiKey },
        } = consignmentShip;
        setconsignmentShippoApiKey(apiKey);
      }
      if (type === constant.HYBRID && dropship && dropship.shippo && dropship.shippo.apiKey) {
        const {
          shippo: { apiKey },
        } = dropship;
        setShippoApiKey(apiKey);
      }
      if (responseData) {
        refetch();
      }
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }

      setBanner({ isOpen: true, status: constant.SUCCESS, title: cms("message.success.addFulfillment") });
      setError(false);
      setSubmitEnable(false);
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const onSubmit = () => {
    let invalidRowValues = false;
    if (consignmentShip) {
      let fieldValues = false;
      const { isThirdPartyShipping } = consignmentShip;
      // if (consignmentShip.by) {
      //   if (consignmentShip.setting && !consignmentShip.setting.length) {
      //     setBanner({
      //       isOpen: true,
      //       status: constant.CRITICAL,
      //       title: cms("message.requireFields"),
      //     });
      //     invalidRowValues = true;
      //   }
      // }
      if (consignmentShip.setting && consignmentShip.setting.length) {
        if (consignmentShip.by) {
          consignmentShip.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (consignmentShip.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (consignmentShip.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            // keys.forEach((key) => {
            //   if (row[key]) {
            //     fieldValues = true;
            //   }
            // });
            const cmskey = {
              vendorId: cms("message.error.vendorId"),
              addressId: cms("message.error.addressId"),
              productType: cms("message.error.productType"),
            };
            const keyValue = {
              vendorId: constant.VENDOR_ID,
              addressId: constant.ADDRESS_ID,
              productType: constant.PRODUCT_TYPE,
            };
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
      if (!isThirdPartyShipping) {
        consignmentShip.shippo = null;
        // setShippoApiKey("");
      }
    }
    if (dropship) {
      let fieldValues = false;
      const { isThirdPartyShipping } = dropship;
      if (dropship.setting && dropship.setting.length) {
        if (dropship.by) {
          dropship.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (dropship.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (dropship.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
      if (!isThirdPartyShipping) {
        dropship.shippo = null;
        dropship.sendabox = null;
        dropship.shippyPro = null;
        dropship.canadaPost = null;
        dropship.ups = null;
        setShippoApiKey("");
      }
    }
    if (consignmentPickup) {
      let fieldValues = false;
      if (consignmentPickup.setting && consignmentPickup.setting.length) {
        if (consignmentPickup.by) {
          consignmentPickup.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (consignmentPickup.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (consignmentPickup.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
    }

    if (clickReserve) {
      let fieldValues = false;
      if (clickReserve.setting && clickReserve.setting.length) {
        if (clickReserve.by) {
          clickReserve.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (clickReserve.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (clickReserve.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
    }

    if (clickCollect) {
      let fieldValues = false;
      if (clickCollect.setting && clickCollect.setting.length) {
        if (clickCollect.by) {
          clickCollect.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (clickCollect.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (clickCollect.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
    }

    if (timeService) {
      let fieldValues = false;
      if (timeService.setting && timeService.setting.length) {
        if (timeService.by) {
          timeService.setting.forEach((row) => {
            let keys = Object.keys(row);
            if (timeService.by === constant.VENDOR) {
              keys = keys.filter((newKey) => newKey !== constant.PRODUCT_TYPE);
            }
            if (timeService.by === constant.PRODUCT_TYPE) {
              keys = keys.filter((newKey) => newKey !== constant.VENDOR_ID);
            }
            keys.forEach((key) => {
              if (row[key]) {
                fieldValues = true;
              }
            });
            if (fieldValues) {
              keys.forEach((key) => {
                if (!row[key] && row[key] !== 0) {
                  invalidRowValues = true;
                }
              });
              if (invalidRowValues) {
                setBanner({
                  isOpen: true,
                  status: constant.CRITICAL,
                  title: cms("message.error.emptyField"),
                });
              }
            }
          });
        }
      }
    }

    if (onSubmitError) {
      invalidRowValues = true;
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: onSubmitError,
      });
    }

    if (isError) {
      invalidRowValues = true;
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.emptyField"),
      });
    }

    if (!invalidRowValues) {
      onSubmitCallback();
    }
  };
  // const primaryAction = {
  //   content: cms("common.button.done"),
  //   onAction: () => setSheetActive(false),
  // };

  // const secondaryAction = {
  //   content: cms("common.button.cancel"),
  //   onAction: () => setSheetActive(false),
  // };
  // const learnMore = (fulfillmentItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(`TODO: Add description about ${fulfillmentItem}`);
  // };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });
  const dismissInfoBanner = () => setInfoBanner({ isOpen: false, status: "", title: "", message: "" });

  if (fulfillmentLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      {infoBanner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={infoBanner.isOpen}
            status={infoBanner.status}
            title={infoBanner.title}
            onDismiss={() => dismissInfoBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("title")}
        description={isEcommerceUser ? cms("bigCommerceDescription") : cms("description")}
      >
        <FulfillmentProvider
          value={{
            cms,
            clickCollect,
            clickReserve,
            timeService,
            consignmentPickup,
            consignmentShip,
            defaultData,
            dropship,
            error,
            fulfillmentTabs,
            handleValidation,
            hybridDropShipTabs,
            isEcommerceUser,
            // learnMore,
            selectedTab,
            sendaboxApiKeyAndAccessKey,
            setIsError,
            setConsignmentPickup,
            setConsignmentShip,
            setClickCollect,
            setClickReserve,
            setTimeService,
            setDefaultData,
            setDropship,
            setHybridDropShipTabs,
            setIsPanel,
            setOnSubmitError,
            setSubmitEnable,
            setType,
            shippoApiKey,
            shippyProApiKey,
            submitEnabled,
            type,
            upsAccessLicenseNumber,
            value,
            consignmentShippoApiKey,
          }}
        >
          <Card>
            <Tabs
              tabs={isPanel ? hybridDropShipTabs : fulfillmentTabs}
              selected={selectedTab}
              onSelect={handleSelectTabChange}
            />
            <Card.Section>
              {!selectedTab && (
                <div>
                  <TextContainer>
                    <Stack>
                      <Stack.Item fill>
                        <Heading>{cms("title")}</Heading>
                      </Stack.Item>
                      {/* <Stack.Item>
                          <Link onClick={() => learnMore(cms("title"), cms("title"))}>{cms("common.learnMore")}</Link>
                        </Stack.Item> */}
                    </Stack>
                  </TextContainer>
                  <br />
                  <RadioButton
                    label={cms("label.dropShip")}
                    helpText={cms("dropShip.helpText")}
                    id="dropShip"
                    checked={value === constant.DROPSHIP}
                    onChange={() => onHandleChange(constant.DROPSHIP)}
                  />
                  {!isEcommerceUser && (
                    <>
                      <RadioButton
                        label={cms("label.consignmentShip")}
                        helpText={cms("consignmentShip.helpText")}
                        id="consignmentShip"
                        checked={value === constant.CONSIGNMENT}
                        onChange={() => onHandleChange(constant.CONSIGNMENT)}
                      />
                      <ConsignmentPickup cms={cms} onHandleChange={onHandleChange} value={value} />
                      <ClickCollect cms={cms} onHandleChange={onHandleChange} value={value} />
                      <ClickReserve cms={cms} onHandleChange={onHandleChange} value={value} />
                      <TimeService cms={cms} onHandleChange={onHandleChange} value={value} />
                      {/* <RadioButton
                      label={cms("label.clickReserve")}
                      helpText={cms("clickReserve.helpText")}
                      id="clickReserve"
                      checked={value === constant.RESERVE}
                      onChange={() => onHandleChange(constant.RESERVE)}
                    /> */}
                      <RadioButton
                        label={cms("label.hybrid")}
                        helpText={cms("hybrid.helpText")}
                        id="hybrid"
                        checked={value === constant.HYBRID}
                        onChange={() => onHandleChange(constant.HYBRID)}
                      />
                    </>
                  )}
                </div>
              )}

              {selectedTab === 1 && type === constant.DROPSHIP && <DropShip />}
              {selectedTab === 1 && type === constant.CONSIGNMENT && (
                <>
                  <ConsignmentShipMethod method={constant.CONSIGNMENT_SHIP_METHOD} value={value} />
                  <br />
                  <br />
                  <ConsignmentShip method={constant.DEFAULT_CONSIGNMENT_SHIP} value={value} />
                </>
              )}
              {selectedTab === 1 && type === constant.PICKUP && <DefaultConsignmentPickup value={value} />}

              {(value === constant.HYBRID || type === constant.HYBRID) && (
                <>
                  {!selectedTab && (
                    <div className="hybridDesc">
                      <br />
                      <TextStyle variation="subdued">{cms("dropShip.hybridDescription")}</TextStyle>
                      <div className="hybridSelect">
                        <Select
                          placeholder={cms("label.fulfillment")}
                          options={hybridOption}
                          onChange={(val) => onHandleSelect(val)}
                          value={selectHybridOption}
                        />
                      </div>
                    </div>
                  )}
                  <Hybrid type={type} />
                </>
              )}
            </Card.Section>
          </Card>
        </FulfillmentProvider>
        <br />
        <div className="fulfillment_button">
          <PageActions
            primaryAction={{
              id: "submit",
              content: cms("common.button.submit"),
              disabled: !submitEnabled,
              loading: updateFulfillmentLoading,
              onAction: () => {
                onSubmit();
              },
            }}
            secondaryActions={[
              {
                id: "cancel",
                content: cms("button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        </div>
      </Layout.AnnotatedSection>
      {/* <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        title={sheetTitle}
      >
        {sheetContent}
      </Sheet> */}
    </>
  );
};
export default withFeature(withErrorBoundary(Fulfillment), { feature: constant.FULFILLMENT });
