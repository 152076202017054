import React, { useState, useContext, useEffect } from "react";
import { Select, Stack, TextField, TextStyle, Badge, Banner, Card, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper } from "lib/helpers";

const Price = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;
  const {
    FLAT,
    symbol,
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_MARKUP_PERCENTAGE },
  } = constant;
  const {
    basePricing = {},
    handleChange = () => {},
    isManagePrice,
    isWholesalePriceVisible,
    isService,
    productLabelForm = {},
    required = () => {},
    setDisabledButton,
    setWholesalePrice,
    variantData = {},
    wholesalePrice,
  } = props;

  const {
    comparePrice: comparePriceLabel,
    discount: discountLabel,
    markup: markupLabel,
    price: priceLabel,
  } = productLabelForm;

  const {
    price = 0,
    comparePrice = 0,
    markUp = { price: 0, type: "" },
    sellerDiscount: variantSellerDiscount,
  } = variantData;
  const { type: typeCheck } = variantSellerDiscount || {};
  const { type: markUpType } = markUp || {};

  const sellerDiscount = variantSellerDiscount;
  const [type, setType] = useState({
    markUp: { labelType: (markUp && markUp.type) || "" },
    sellerDiscount: { labelType: (sellerDiscount && sellerDiscount.type) || FLAT },
  });

  useEffect(() => {
    setType({
      markUp: { labelType: (markUp && markUp.type) || "" },
      sellerDiscount: { labelType: (sellerDiscount && sellerDiscount.type) || FLAT },
    });
  }, [markUp, sellerDiscount]);

  const options = [
    { label: cms("section.price.label.flat"), value: constant.FLAT },
    { label: cms("section.price.label.percentage"), value: constant.PERCENTAGE },
  ];

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  const label = (value, defaultCms) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCms;
  };

  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if ("type" in value) {
      setType((preState) => ({ ...preState, [field]: { labelType: value.type } }));
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= MAX_MARKUP_PERCENTAGE) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
  };
  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || ""}
      placeholder={cms("section.price.placeholder.type")}
      label={cms("section.price.label.type")}
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );

  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      <FormLayout>
        {basePricing && basePricing.currencyCode && (
          <Banner status={constant.INFO}>
            <TextStyle>{`${cms("label.import")} ${basePricing && basePricing.currencyCode}`}</TextStyle>
          </Banner>
        )}
        <FormLayout.Group>
          <TextField
            id="price"
            label={[
              `${label(priceLabel, cms("section.price.label.price"))}${required(priceLabel) || ""}`,
              <span className="currency-badge">
                <Badge status="info">{cms("section.price.label.currency")}</Badge>
              </span>,
            ]}
            type="number"
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={!isManagePrice}
            onChange={(value) => {
              handleChange(constant.PRICE, parseFloat(acceptOnlyValidInput(value) || 0));
              setDisabledButton(false);
            }}
          />
          <TextField
            id="comparePrice"
            label={`${label(comparePriceLabel, cms("section.price.label.compare"))}${required(comparePriceLabel) || ""}`}
            type="number"
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled
          />
        </FormLayout.Group>
        {isWholesalePriceVisible && !isService && (
          <FormLayout.Group>
            <TextField
              id="wholesalePrice"
              label={cms("section.price.label.wholesale")}
              min={0}
              placeholder="0.00"
              prefix={moneyFormat}
              value={(wholesalePrice && wholesalePrice.toString()) || ""}
              onChange={(value) => {
                setWholesalePrice(acceptOnlyValidInput(value, wholesalePrice));
                setDisabledButton(false);
              }}
            />
            <Stack>
              <Stack.Item fill />
            </Stack>
          </FormLayout.Group>
        )}
        {!isService && (
          <FormLayout.Group>
            <TextField
              id="markUp"
              label={`${label(markupLabel, cms("section.price.label.markup"))}${required(markupLabel) || ""}`}
              value={(markUp && markUp.price && markUp.price.toString()) || ""}
              placeholder={constant.ZERO_PRICE}
              max={(type.markUp.labelType === constant.PERCENTAGE && MAX_MARKUP_PERCENTAGE) || MAX_FLAT}
              onChange={(value) => {
                handlePriceChange(
                  constant.MARKUP,
                  markUp,
                  { price: acceptOnlyValidInput(value, markUp?.price) },
                  type.markUp.labelType
                );
                setDisabledButton(false);
              }}
              connectedRight={selectWeight(constant.MARKUP, markUp)}
              suffix={type.markUp.labelType === constant.PERCENTAGE && symbol.PERCENTAGE}
              prefix={type.markUp.labelType === constant.FLAT && currentUser.moneyFormat}
              disabled={!markUpType}
            />
            <TextField
              id="discount"
              label={`${label(discountLabel, cms("section.price.label.discount"))}${required(discountLabel) || ""}`}
              value={(sellerDiscount && sellerDiscount.price && sellerDiscount.price.toString()) || ""}
              max={(type.sellerDiscount.labelType === constant.PERCENTAGE && 100) || MAX_FLAT}
              placeholder={constant.ZERO_PRICE}
              onChange={(value) => {
                handlePriceChange(
                  constant.SELLER_DISCOUNT,
                  sellerDiscount,
                  { price: acceptOnlyValidInput(value, sellerDiscount.price) },
                  type.sellerDiscount.labelType
                );
                setDisabledButton(false);
              }}
              connectedRight={selectWeight(constant.SELLER_DISCOUNT, sellerDiscount)}
              suffix={type.sellerDiscount.labelType === constant.PERCENTAGE && symbol.PERCENTAGE}
              prefix={type.sellerDiscount.labelType === constant.FLAT && currentUser.moneyFormat}
              disabled={!typeCheck}
            />
          </FormLayout.Group>
        )}
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Price;
