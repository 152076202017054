import React, { useContext } from "react";
import { Modal, TextContainer, TextStyle } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import gql
import { DELETE_RULE } from "app/shipping/apollo/mutations";

// import propType
import { deleteProp } from "app/shipping/modules/provider/features/subFeatures/advance/rules/props";

const DeleteRule = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { currentUser = {}, cms } = useContext(PrivateContext);
  const { deleteModal, setDeleteModal, item, refetch, band = [] } = props;
  const { gql } = constant;
  const [deleteRule, { loading }] = useMutation(DELETE_RULE);

  const { shippingBandId = null } = item;
  const shippingBand =
    band.find((key) => {
      return (
        key.value === shippingBandId ||
        baseHelper.mongoIdAsString(key.parentId) === baseHelper.mongoIdAsString(shippingBandId)
      );
    }) || false;
  const handleChange = () => {
    const { _id: ruleId = "" } = item || {};
    deleteRule({ variables: { input: { shippingRuleId: ruleId } } })
      .then((data) => {
        const responseError = baseHelper.getResponseError(data.data, gql.DELETE_SHIPPING_RULE);
        if (responseError) {
          setBannerOuter({ isOpen: true, status: "critical", title: responseError });
          return;
        }
        refetch();
        setBannerOuter({
          isOpen: true,
          title: cms("modal.rule.delete.message.success.deleteSuccessfully"),
          status: "success",
        });
        setDeleteModal(false);
      })
      .catch((exception) => {
        setBannerOuter({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
      });
  };
  const toggleModal = () => {
    setDeleteModal(!deleteModal);
  };
  const getTitle = () => {
    return `${cms("modal.rule.delete.label.delete")}-${shippingBand && shippingBand.label}`;
  };

  const { userId: ruleCreatedBy = null } = item || {};
  const { _id: currentUserId = null } = currentUser || {};

  if (!isVendorAllowed || ruleCreatedBy !== currentUserId) {
    return null;
  }

  return (
    <>
      <Modal
        open={deleteModal}
        onClose={toggleModal}
        title={getTitle()}
        primaryAction={{
          loading,
          content: cms("modal.rule.delete.button.primary"),
          onAction: handleChange,
          destructive: true,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <TextStyle variation="strong">{cms("modal.rule.delete.content")}</TextStyle>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
DeleteRule.propTypes = deleteProp.type;
export default DeleteRule;
