import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { clientIdSchema, secretSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  const { STRIPE_CLIENT_ID, STRIPE_SECRET, PAYPAL_CLIENT_ID, PAYPAL_SECRET } = constant;
  let errorMessage = "";
  switch (field) {
    case STRIPE_CLIENT_ID:
      errorMessage = validateValue(clientIdSchema(cms), { clientId: value });
      break;
    case STRIPE_SECRET:
      errorMessage = validateValue(secretSchema(cms), { secret: value });
      break;
    case PAYPAL_CLIENT_ID:
      errorMessage = validateValue(clientIdSchema(cms), { clientId: value });
      break;
    case PAYPAL_SECRET:
      errorMessage = validateValue(secretSchema(cms), { secret: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(errorMessage);
};

export default validate;
