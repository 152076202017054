import { gql } from "apollo-boost";

const ACCEPT_ORDER_EXPIRY = gql`
  mutation acceptOrderExpiry($input: OrderExpiry) {
    acceptOrderExpiry(input: $input) {
      status
      data
      error
    }
  }
`;
export default ACCEPT_ORDER_EXPIRY;
