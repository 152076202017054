import { gql } from "apollo-boost";

const UPDATE_VENDOR = gql`
  mutation updateVendorProfile($input: UpdateVendorProfile!) {
    updateVendorProfile(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_VENDOR;
