import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import NotFound from "layout/private/components/notFound/notFound";

import { OperatorProductList } from "./operator/features";
import { ProviderProductList } from "./provider/features";
// import { AdminProductList } from "./admin/features";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ProductList = () => {
  const currentUserRole = storageHelper.get("userRole");
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    // if (role === admin) {
    //   return <AdminProductList />;
    // }
    if (role === operator) {
      return <OperatorProductList />;
    }
    if (role === provider) {
      return <ProviderProductList />;
    }

    return <NotFound />;
  };

  const OrderListComponent = getComponent(currentUserRole);
  return OrderListComponent;
};

export default ProductList;
