import React, { useState, useEffect, useContext } from "react";
import { Layout, SettingToggle, TextStyle, Modal, TextContainer, Button, Card, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import config from "configuration";
// import hoc
import { PrivateContext } from "lib/context";
import { withFeature, withErrorBoundary } from "lib/hoc";

// import from lib
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

// import gql
import { errorHelper } from "lib/helpers";
import { UPDATE_ORDER_SETTING, UPDATE_LIVE_ORDER_SETTING } from "app/orders/apollo/index";

const { gql, ACCEPTED_ORDER, FULFILLMENTS } = constant;

const ProviderOrderSetting = () => {
  const [updateProductSyncMutation] = useMutation(UPDATE_LIVE_ORDER_SETTING);
  const { currentUser, cms, history, vendorReadOnlyModule = [], vendorHiddenModuleKeys = [] } = useContext(
    PrivateContext
  );
  const { _id: currentUserId, setting = {}, shop } = currentUser;
  const { isAcceptOrder, isFulfillOrder } = setting || {};
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isOrderSyncEnabled, setIsOrderSyncEnabled] = useState(currentUser.isOrderConnect);
  const isVendor = baseHelper.isVendor(currentUser);
  const isShopifyConnected = !!(isVendor && currentUser.shop);
  const isShopConnected = baseHelper.isVendorStoreConnected(currentUser);
  const [toggleOrderStatus, setToggleOrderStatus] = useState(false);
  const [autoOrdersEnabled, setAutoOrdersEnabled] = useState(false);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setSettingOrder] = useMutation(UPDATE_ORDER_SETTING);
  const contentStatus = toggleOrderStatus
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textStatus = toggleOrderStatus
    ? cms("section.liveOrderInventory.label.enabled")
    : cms("section.liveOrderInventory.label.disabled");

  const contentLiveStatus = isOrderSyncEnabled
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textLiveStatus = isOrderSyncEnabled
    ? cms("section.liveOrderInventory.label.enabled")
    : cms("section.liveOrderInventory.label.disabled");
  const autoFullfillStatus = autoOrdersEnabled
    ? cms("section.autoFulfillOrders.button.disable")
    : cms("section.autoFulfillOrders.button.enable");
  const textAutoStatus = autoOrdersEnabled
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");
  const updateOrderSetting = (orderStatus, key = false) => {
    const requestData = {
      // id: currentUserId,
      isAcceptOrder: orderStatus,
      isFulfillOrder: autoOrdersEnabled,
    };

    if (!orderStatus && key === ACCEPTED_ORDER) {
      requestData.isAcceptOrder = orderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    if (key === FULFILLMENTS) {
      requestData.isAcceptOrder = toggleOrderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    setButtonLoading(key);
    setSettingOrder({
      variables: { input: requestData },
    })
      .then((res) => {
        setButtonLoading(false);
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_ORDER_SETTING);
        let bannerValue = { status: constant.CRITICAL, title: errorResponse };
        if (!errorResponse) {
          const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_ORDER_SETTING);
          bannerValue = { status: constant.SUCCESS, title: cms("message.success") };
          setToggleOrderStatus(responseData.setting.isAcceptOrder);
          setAutoOrdersEnabled(responseData.setting.isFulfillOrder);
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch((exception) => {
        setButtonLoading(false);
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };
  const onSubmit = () => {
    const orderStatus = toggleOrderStatus;
    updateOrderSetting(!orderStatus, ACCEPTED_ORDER);
  };
  const onAutoSubmit = () => {
    const orderStatus = autoOrdersEnabled;
    updateOrderSetting(!orderStatus, FULFILLMENTS);
  };
  useEffect(() => {
    setToggleOrderStatus(isAcceptOrder);
  }, [isAcceptOrder]);
  useEffect(() => {
    if (isFulfillOrder) {
      setAutoOrdersEnabled(isFulfillOrder);
    }
  }, [isFulfillOrder]);
  let setButtonColor = false;
  let setFullFillButtonColor = false;
  if (autoOrdersEnabled) {
    setFullFillButtonColor = true;
  }
  if (toggleOrderStatus) {
    setButtonColor = true;
  }
  const updateOrderSync = async (typeConnect) => {
    await updateProductSyncMutation({
      variables: {
        input: { shop },
      },
    })
      .then((res) => {
        const errorResponse = baseHelper.getResponseError(res.data, gql.DISABLE_ORDER_CONNECT);
        let bannerValue = { status: constant.CRITICAL, title: errorResponse };
        if (!errorResponse) {
          bannerValue = { status: constant.SUCCESS, title: cms("message.success") };
          setIsOrderSyncEnabled(!isOrderSyncEnabled);
        }
        if (typeConnect) {
          setButtonLoading(false);
          setIsConfirmationModalVisible(false);
        }

        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch((exception) => {
        setButtonLoading(false);
        setIsConfirmationModalVisible(false);
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  const toggleProductSync = async () => {
    const isReadOnly = vendorReadOnlyModule.includes(constant.ORDER);
    if (isVendor && shop && !isOrderSyncEnabled && !isReadOnly) {
      /* const clientId = config.vendorShopifyAPIKey;
      const redirectUri = baseHelper.getCurrentDomain(window.location, constant.SUPPLIERS_SHOPIFY_URL);
      const scope =
        // eslint-disable-next-line max-len
        "read_products, write_products, read_customers, write_customers, read_orders, write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, read_shipping, write_shipping, read_locations, write_order_edits, read_discounts, write_discounts ";
      // eslint-disable-next-line max-len
      const url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
      window.location.href = url;
      */
      await updateOrderSync(false);
    } else {
      setButtonLoading(true);
      await updateOrderSync(true);
      /* 
      await updateProductSyncMutation({
        variables: {
          input: { shop },
        },
      })
        .then((res) => {
          const errorResponse = baseHelper.getResponseError(res.data, gql.DISABLE_ORDER_CONNECT);
          let bannerValue = { status: constant.CRITICAL, title: errorResponse };
          if (!errorResponse) {
            bannerValue = { status: constant.SUCCESS, title: cms("message.success") };
            setIsOrderSyncEnabled(!isOrderSyncEnabled);
          }
          setButtonLoading(false);
          setIsConfirmationModalVisible(false);
          setBannerStatus({
            status: bannerValue.status,
            title: bannerValue.title,
            isOpen: true,
          });
        })
        .catch((exception) => {
          setButtonLoading(false);
          setIsConfirmationModalVisible(false);
          setBannerStatus({
            status: constant.CRITICAL,
            title: errorHelper.parse(exception),
            isOpen: true,
          });
        });
        */
    }
  };
  const toggleConfirmationModal = () => {
    setIsConfirmationModalVisible(!isConfirmationModalVisible);
  };

  const handleConnectShop = () => {
    if (vendorHiddenModuleKeys.includes("product")) {
      setBannerStatus({
        status: constant.CRITICAL,
        title: cms("message.error.notAllow"),
        isOpen: true,
      });
    } else {
      history.push(constant.CONNECT_SHOP_PAGE);
    }
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        key={cms("modal.title")}
        open={isConfirmationModalVisible}
        onClose={toggleConfirmationModal}
        title={cms("modal.title")}
        primaryAction={{
          content: contentLiveStatus,
          onAction: toggleProductSync,
          loading: isButtonLoading,
          destructive: isOrderSyncEnabled,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };
  return (
    <>
      {renderConfirmationModal()}
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => setBannerStatus({ title: "", status: "", isOpen: false })}
          />
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection
        title={cms("section.liveOrderInventory.title")}
        description={cms("section.liveOrderInventory.description")}
      >
        {!isShopifyConnected && (
          <Card sectioned>
            <Stack>
              <Stack.Item fill>{cms("section.liveOrderInventory.content.connectShop")}</Stack.Item>
              <Stack.Item>
                <Button onClick={() => handleConnectShop()}>{cms("section.liveOrderInventory.button.connect")}</Button>
              </Stack.Item>
            </Stack>
          </Card>
        )}
        {isShopifyConnected && (
          <SettingToggle
            action={{
              content: contentLiveStatus,
              onAction: () => (isOrderSyncEnabled ? toggleConfirmationModal() : toggleProductSync()),
              destructive: isOrderSyncEnabled,
              disabled: !isShopifyConnected,
            }}
            enabled={isOrderSyncEnabled}
          >
            {cms("section.liveOrderInventory.content.liveOrderInventory")}
            <TextStyle variation="strong">{` ${textLiveStatus.toLowerCase()}`}</TextStyle>
          </SettingToggle>
        )}
      </Layout.AnnotatedSection>
      <br />
      <Layout.AnnotatedSection
        title={cms("section.autoAccept.title")}
        description={cms("section.autoAccept.description")}
      >
        <SettingToggle
          action={{
            id: "submit",
            content: contentStatus,
            onAction: () => onSubmit(),
            destructive: setButtonColor,
            loading: isButtonLoading === ACCEPTED_ORDER,
          }}
          enabled={setButtonColor}
        >
          {cms("section.autoAccept.content")}
          <TextStyle variation="strong">{` ${textStatus}`}</TextStyle>
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.autoFulfillOrders.title")}
        description={cms("section.autoFulfillOrders.description")}
      >
        <SettingToggle
          action={{
            id: "submit",
            content: autoFullfillStatus,
            onAction: () => onAutoSubmit(),
            disabled: !toggleOrderStatus,
            destructive: setFullFillButtonColor,
            loading: isButtonLoading === FULFILLMENTS,
          }}
          enabled={setFullFillButtonColor}
        >
          {cms("section.autoFulfillOrders.content")}
          <TextStyle variation="strong">{` ${textAutoStatus}`}</TextStyle>
        </SettingToggle>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderOrderSetting), { feature: constant.ORDER_SETTING });
