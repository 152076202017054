import React, { useContext, useState } from "react";
import { Card, Layout } from "@shopify/polaris";
import { ExportMinor, PaymentsMajor } from "@shopify/polaris-icons";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { SingleColumnLayout } from "layout/private/components";
import { Banner, SkeletonList } from "lib/components";
import { QuickAction, QuickReport } from "app/reports";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import subFeature
import PaymentList from "app/payments/modules/generic/list/list";
import AdminExportPayment from "app/payments/modules/admin/features/export/export";

const AdminPaymentList = () => {
  const { isLoading, cms } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const [isOpenModal, setModal] = useState(false);

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };

  const linkData = [
    {
      action: () => setModal(true),
      caption: cms("label.caption"),
      id: "exportPayment",
      label: cms("label.export"),
      primary: true,
      icon: ExportMinor,
    },
    {
      caption: cms("label.managePayout"),
      id: "automaticPayouts",
      label: cms("label.automatic"),
      link: "/payouts",
      icon: PaymentsMajor,
    },
  ];
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        <QuickReport />
        <QuickAction link={linkData} />
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <PaymentList setBanner={setBanner} />
            </Card>
          </Layout.Section>

          <AdminExportPayment openModal={isOpenModal} setOpenModal={setModal} />
        </Layout>
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  return <SingleColumnLayout primary={getComponent(currentUserRole)} />;
};

export default withFeature(withErrorBoundary(AdminPaymentList), { feature: constant.PAYMENTS });
