import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Caption,
  DataTable,
  FormLayout,
  Link,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import { Spinner } from "lib/components";

import { baseHelper, errorHelper } from "lib/helpers";
import { useMutation, useQuery } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { UPDATE_TRACKING_DETAIL } from "app/orders/apollo/mutations/index";

// import queries
import { GET_MANAGE_FULFILLMENT_SETTING } from "app/setup/apollo/queries";

import { fulfillmentsProp } from "app/orders/modules/provider/features/view/propsType";
import { FieldSetQty } from "app/orders/modules/operator/features/view/modal/modalStyle";

const Fulfillments = (props) => {
  const { gql } = constant;
  const { cms, history } = useContext(PrivateContext);
  const [editModal, setEditModal] = useState(false);
  const [trackingCompany, setTrackingCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [quantity, setQuantity] = useState();
  const [totalLineItemQuantity, setTotalLineItemQuantity] = useState();
  const [fulfillmentId, setFulfillmentId] = useState("");
  const [viewLineOrderData, setViewLineOrderData] = useState({});
  const [isEnableFulfillmentSetting, setIsEnableFulfillmentSetting] = useState(false);
  const [updateTrackingDetail, setUpdateTrackingDetail] = useState(true);

  const { lineData, fulfillmentsCount, lineCount, setBanner, setMessage, refetchOrderFulfillment } = props;
  const { fulfillmentType: type, _id: lineItemId } = viewLineOrderData;
  const [updateTracking, { loading: loadingTracking, error: errorTracking, data: trackingData }] = useMutation(
    UPDATE_TRACKING_DETAIL
  );

  const { data: fulfillSettingData, loading: loadingFulfillment } = useQuery(GET_MANAGE_FULFILLMENT_SETTING);

  const row = [];
  let totalQuantity;
  let fulfilledQuantity = 0;
  lineCount.forEach((item) => {
    if (item.fulfillableQuantity === 0) {
      fulfilledQuantity = fulfillmentsCount;
    }
  });

  const handleChange = useCallback(() => setEditModal(!editModal), [editModal]);

  const validInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  const editTrackingDetails = () => {
    const formValues = {
      shopifyFulfillmentId: fulfillmentId,
      trackingCompany,
      trackingNumber,
      lineItemId,
      trackingUrl: "",
    };
    updateTracking({ variables: { input: formValues } });
    setUpdateTrackingDetail(true);
  };

  const badgeStatus =
    baseHelper.getBadgeStatus(type) === constant.PICKUP
      ? constant.CONSIGNMENT_PICKUP_CAPITAL
      : baseHelper.getBadgeStatus(type);

  useEffect(() => {
    if (fulfillSettingData) {
      const resData = baseHelper.getResponseData(fulfillSettingData, gql.GET_MANAGE_FULFILLMENT_SETTING);
      const resError = baseHelper.getResponseError(fulfillSettingData, gql.GET_MANAGE_FULFILLMENT_SETTING);
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
      }
      if (resData) {
        const { edit } = resData || {};
        setIsEnableFulfillmentSetting(edit);
      }
    }
  }, [fulfillSettingData, gql.GET_MANAGE_FULFILLMENT_SETTING, setBanner]);

  useEffect(() => {
    if (trackingData && updateTracking) {
      const responseTracking = baseHelper.getResponseData(trackingData, constant.gql.UPDATE_TRACKING_DETAIL);
      const responseTrackingError = baseHelper.getResponseError(trackingData, constant.gql.UPDATE_TRACKING_DETAIL);
      if (responseTracking) {
        setEditModal(false);
        setMessage(cms("message.success.updateTracking"));
        refetchOrderFulfillment();
      }
      if (responseTrackingError) {
        setEditModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: errorHelper.parse(responseTrackingError),
          isOpen: true,
        });
      }
      setUpdateTrackingDetail(false);
    }
  }, [setBanner, setMessage, trackingData, updateTrackingDetail, updateTracking, cms, refetchOrderFulfillment]);

  useEffect(() => {
    setEditModal(false);
    if (errorTracking) {
      setBanner({
        status: constant.CRITICAL,
        title: errorHelper.parse(errorTracking),
        isOpen: true,
      });
    }
  }, [errorTracking, setBanner]);

  const openModel = (selectedId, value, fulfillableQuantity) => {
    setTrackingCompany(value.trackingCompany);
    setTrackingNumber(value.trackingNumber);
    setQuantity(fulfillableQuantity);
    setTotalLineItemQuantity(value.lineItems[0].totalQuantity);
    setFulfillmentId(value.shopifyFulfillmentId);
    setViewLineOrderData(lineCount.find((item) => item.shopifyLineItemId === selectedId));
    setEditModal(true);
  };

  const editTrackingModal = () => {
    return (
      <>
        <Modal
          open={editModal}
          onClose={() => {
            handleChange(false);
          }}
          title={cms("label.editTracking")}
          primaryAction={{
            content: cms("label.updateDetails"),
            disabled: !(trackingCompany && trackingNumber),
            onAction: () => {
              editTrackingDetails();
            },
            loading: loadingTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <Stack wrap={false} id={viewLineOrderData._id}>
                <Stack.Item>
                  <Thumbnail
                    size="large"
                    source={viewLineOrderData.image ? viewLineOrderData.image : constant.NOIMAGESNAP}
                    alt={viewLineOrderData.name}
                  />
                </Stack.Item>
                <Stack.Item fill>
                  <div className="ellipsis">
                    <Stack.Item>
                      {(viewLineOrderData.isProductDeleted && (viewLineOrderData.name || "")) || (
                        <Link onClick={() => history.push(`/products/edit/${viewLineOrderData.productId}`)}>
                          {viewLineOrderData.name || ""}
                        </Link>
                      )}
                      {viewLineOrderData.variantTitle && (
                        <Caption>
                          <TextStyle variation={constant.SUBDUED}>
                            {viewLineOrderData.variantTitle.toUpperCase()}
                          </TextStyle>
                        </Caption>
                      )}
                    </Stack.Item>
                    <Badge className="text-capitalize">
                      {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                    </Badge>
                  </div>
                </Stack.Item>
                <FieldSetQty>
                  <Stack.Item>
                    <TextField
                      value={quantity}
                      disabled
                      suffix={`${cms("label.of")} ${
                        viewLineOrderData.actualQuantity - viewLineOrderData.fulfillableQuantity
                      }`}
                    />
                  </Stack.Item>
                </FieldSetQty>
              </Stack>
              <TextField
                label={cms("label.company")}
                value={trackingCompany}
                onChange={(val) => {
                  setTrackingCompany(validInput(val));
                }}
              />
              <TextField
                label={cms("label.trackingNumber")}
                value={trackingNumber}
                onChange={(val) => {
                  setTrackingNumber(validInput(val));
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  if (lineData) {
    lineData.forEach((value) => {
      const { lineItems } = value;
      lineItems.forEach((lineItem) => {
        const found = lineCount.find(
          (item) =>
            item.shopifyLineItemId === lineItem.id &&
            [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(item.fulfillmentType)
        );
        if (!found) {
          const data = lineCount.find((item) => item.shopifyLineItemId === lineItem.id && item);
          let isShowEnableFulfillmentButton = true;
          if (data.isDelivered) {
            isShowEnableFulfillmentButton = false;
          }
          if (data.isReturn && !(data.quantity > data.returnedQuantity)) {
            isShowEnableFulfillmentButton = false;
          }
          row.push([
            <Stack.Item fill>
              <div className="ellipsis">
                <Link>{lineItem.name}</Link>
                <Caption>
                  <br />
                  <TextStyle>{`${cms("label.vendor")}: ${lineItem.vendor}`}</TextStyle>
                </Caption>
              </div>
            </Stack.Item>,

            <Stack.Item>{value.trackingCompany}</Stack.Item>,
            <Stack.Item>
              {value.trackingNumber}
              <br />
              <br />
              {isEnableFulfillmentSetting && isShowEnableFulfillmentButton && (
                <Button onClick={() => openModel(lineItem.id, value, lineItem.quantity)}>
                  {cms("button.editFulfillment")}
                </Button>
              )}
            </Stack.Item>,
          ]);
        }
      });
    });
    totalQuantity = (lineCount && lineCount.length) || 0;
  }
  if (loadingFulfillment) {
    return <Spinner />;
  }

  return (
    <Card>
      {editTrackingModal()}
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "text", "numeric"]}
          headings={[cms("label.product"), cms("label.shippingCompany"), cms("label.trackingNumber")]}
          rows={row}
        />
      </Card.Section>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{cms("label.totalFulfillments")}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="strong">{`${fulfilledQuantity} ${cms("label.of")} ${totalQuantity}`}</TextStyle>
          </Stack.Item>
        </Stack>
      </Card.Section>
    </Card>
  );
};
Fulfillments.propTypes = fulfillmentsProp.type;
export default Fulfillments;
