import React, { useContext, useState, useEffect } from "react";
import { Card, Button, Stack, TextStyle } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import constant
import constant from "lib/constant/constant";

import { SkeletonAnnotated } from "lib/components";

// import context
import { PaymentContext } from "app/setup/modules/operator/features/paymentService/context";

// import mutations
import { TOGGLE_AUTO_PAYOUT } from "app/setup/apollo/mutations";

// import queries
import { GET_USER } from "app/setup/apollo/queries";

const AutomaticPayout = () => {
  const { gql } = constant;
  const { setBanner, isStripe } = useContext(PaymentContext);
  const { currentUser, cms } = useContext(PrivateContext);
  const { data: getUserData, loading: getUserLoading, error: getUserError } = useQuery(GET_USER);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const isPayPalConnected = !!(
    currentUser &&
    currentUser.paypalKey &&
    currentUser.paypalKey.clientId &&
    currentUser.paypalKey.secret
  );
  const { isAutoStripe = false, isAutoPaypal = false } = (currentUser && currentUser.payoutSetting) || {};
  const [autoStripeData, setAutoStripeData] = useState(!!isAutoStripe);
  const [autoPaypalData, setAutoPaypalData] = useState(!!isAutoPaypal);
  const [stripeDataLoading, setStripeDataLoading] = useState(false);
  const [paypalDataLoading, setPaypalDataLoading] = useState(false);

  const [autoPayoutData] = useMutation(TOGGLE_AUTO_PAYOUT);

  useEffect(() => {
    if (getUserData) {
      const resData = baseHelper.getResponseData(getUserData, gql.GET_USER);
      const resError = baseHelper.getResponseError(getUserData, gql.GET_USER);
      if (resError) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        });
      }
      if (resData) {
        const stripeValues = resData.stripeKey;
        setIsStripeConnected(stripeValues && !!(stripeValues.clientId && stripeValues.secret));
      }
    }
    if (getUserError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(getUserError),
      });
    }
  }, [getUserData, setBanner, gql.GET_USER, getUserError]);

  const handleSubmit = (paymentMethod) => {
    if (paymentMethod === constant.STRIPE) {
      if (!(isAutoStripe || isStripeConnected || isStripe)) {
        return setBanner({ isOpen: true, title: cms("message.error.stripeError"), status: constant.CRITICAL });
      }
      setStripeDataLoading(true);
    }
    if (paymentMethod === constant.PAYPAL) {
      if (!(isAutoPaypal || isPayPalConnected)) {
        return setBanner({ isOpen: true, title: cms("message.error.paypalError"), status: constant.CRITICAL });
      }
      setPaypalDataLoading(true);
    }

    autoPayoutData({ variables: { input: { paymentMethod } } })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.TOGGLE_AUTO_PAYOUT);
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.TOGGLE_AUTO_PAYOUT);
          setBanner({ isOpen: true, title: error, status: constant.CRITICAL });
          return;
        }
        if (paymentMethod === constant.STRIPE) {
          setStripeDataLoading(false);
          setAutoStripeData(!autoStripeData);
          setBanner({ isOpen: true, title: cms("message.success.stripeSuccess"), status: constant.SUCCESS });
        }
        if (paymentMethod === constant.PAYPAL) {
          setPaypalDataLoading(false);
          setAutoPaypalData(!autoPaypalData);
          setBanner({ isOpen: true, title: cms("message.success.paypalSuccess"), status: constant.SUCCESS });
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
    return "";
  };

  const stripeStatus = isAutoStripe ? cms("section.label.disable") : cms("section.label.enable");
  const paypalStatus = isAutoPaypal ? cms("section.label.disable") : cms("common.section.label.enable");

  if (getUserLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      <Card>
        <Card sectioned>
          <Stack wrap={false}>
            <Stack.Item fill>
              <TextStyle>
                {`${cms("section.label.automaticPayoutStrip")} `}
                <b>{autoStripeData ? constant.ENABLED : constant.DISABLED}</b>
              </TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Button
                id="autoPayStripeButton"
                primary
                onClick={() => handleSubmit("stripe")}
                loading={stripeDataLoading}
                buttonType={stripeStatus}
                destructive={autoStripeData}
              >
                {autoStripeData ? cms("section.label.disable") : cms("section.label.enable")}
              </Button>
            </Stack.Item>
          </Stack>
        </Card>
      </Card>
      <Card sectioned>
        <Stack wrap={false}>
          <Stack.Item fill>
            <TextStyle>
              {`${cms("section.label.automaticPayoutPaypalDescription")} `}
              <b>{autoPaypalData ? constant.ENABLED : constant.DISABLED}</b>
            </TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Button
              id="autoPayPaypalButton"
              primary
              loading={paypalDataLoading}
              onClick={() => handleSubmit("paypal")}
              buttonType={paypalStatus}
              destructive={autoPaypalData}
            >
              {autoPaypalData ? cms("section.label.disable") : cms("section.label.enable")}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};

export default AutomaticPayout;
