import React, { useEffect, useState, useContext } from "react";
import { Button, Layout, Stack, Sheet } from "@shopify/polaris";

import { withFeature, withErrorBoundary } from "lib/hoc";

import { useQuery, useMutation } from "react-apollo";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { Spinner, Banner } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { Attachment, TitleDescription, Shipping, Image, Inventory, Variants } from "../../../generic/edit/subFeatures";
import { Price, RenderVariants, Organization } from "./subFeatures";

import { GET_PRODUCT, GET_PRODUCT_TYPE, GET_PRODUCT_TAGS, GET_PRODUCT_SETTING } from "../../../../apollo/queries";
import { EDIT_PRODUCT, UPLOAD_IMAGE, UPLOAD_FILE } from "../../../../apollo/mutations";

const {
  value: { MAX_MEASUREMENT = 999 },
} = constant;

const ProviderEditProduct = () => {
  const { currentUser, match, history, location = {}, cms } = useContext(PrivateContext);
  const { isAwsCredsAvailable = false } = currentUser;
  const { gql } = constant;
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [isVariants, setIsVariants] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [existingProductImages, setExistingProductImages] = useState([]);
  const [existingProductAttachments, setExistingProductAttachments] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isHideTag, setIsHideTag] = useState(true);
  const [isHideType, setIsHideType] = useState(true);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    children: null,
    action: null,
  });

  const { loading: productLoading, error, data, refetch, networkStatus } = useQuery(GET_PRODUCT, {
    variables: { input: { id: match.params.id }, notifyOnNetworkStatusChange: true },
  });
  const { loading: tagLoading, error: tagError, data: tagData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: typeLoading, error: typeError, data: typeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productSettingLoading, error: productSettingError, data: productSettingData } = useQuery(
    GET_PRODUCT_SETTING
  );

  const [updateProduct, { loading: editLoading }] = useMutation(EDIT_PRODUCT);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  useEffect(() => {
    if (!(productLoading || tagLoading || typeLoading || productSettingLoading || networkStatus === 4)) {
      setLoading(false);
    }
  }, [productLoading, networkStatus, tagLoading, typeLoading, productSettingLoading]);

  useEffect(() => {
    if (error || typeError || tagError || productSettingError) {
      setBanner({
        isOpen: true,
        title: errorHelper.parse(error || typeError || tagError || productSettingError),
        status: constant.CRITICAL,
      });
    }
  }, [error, cms, tagError, typeError, productSettingError]);

  const productListError = baseHelper.getResponseError(data, gql.GET_PRODUCT);
  const tagListError = baseHelper.getResponseError(tagData, gql.GET_PRODUCT_TAGS);
  const typeListError = baseHelper.getResponseError(typeData, gql.GET_PRODUCT_TYPE);
  const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: productListError });
    }
    if (tagListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: tagListError });
    }
    if (typeListError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: typeListError });
    }
    if (productError) {
      setBanner({ isOpen: true, status: "critical", title: productError });
    }
    if (location && location.state) {
      setBanner({
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.addedSuccessfully"),
        action: { content: cms("message.success.action.createOther"), url: "/products/add" },
      });
    }
  }, [location, cms, productListError, setBanner, tagListError, typeListError]);

  const productResponse = baseHelper.getResponseData(data, gql.GET_PRODUCT);
  const tagListResponse = baseHelper.getResponseData(tagData, gql.GET_PRODUCT_TAGS);
  const typeListResponse = baseHelper.getResponseData(typeData, gql.GET_PRODUCT_TYPE);

  useEffect(() => {
    if (productSettingData) {
      const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
      const { isHideTagAndType, isHideTag, isHideType } = productSetting || {};
      setIsHideTagAndType(!!isHideTagAndType);
      setIsHideTag(!!isHideTag);
      setIsHideType(!!isHideType);
    }
  }, [productSettingData, setIsHideTag, setIsHideType]);

  useEffect(() => {
    if (productResponse) {
      const {
        _id,
        title,
        description,
        price,
        comparePrice,
        images,
        attachments,
        variants,
        sku,
        barcode,
        quantity,
        markUp,
        vendorDiscount,
        inventoryManagement,
        weight,
        weightUnit,
        isShipping,
        productType,
        tags,
        updatedAt,
        measurement = {},
      } = productResponse;
      setExistingProductImages(images || []);
      setExistingProductAttachments(attachments || []);
      setIsVariants(variants && variants.length);

      const length = (measurement && measurement.length && measurement.length.value) || 0;
      const width = (measurement && measurement.width && measurement.width.value) || 0;
      const height = (measurement && measurement.height && measurement.height.value) || 0;

      const variantData = variants.map((item) => {
        const variantContainer = {
          barcode: item.barcode,
          imageUrl: item.image || item.imageUrl,
          inventoryQuantity: item.inventoryQuantity,
          option1: item.option1,
          option2: item.option2,
          option3: item.option3,
          option1Val: item.option1Val,
          option2Val: item.option2Val,
          option3Val: item.option3Val,
          price: item.price,
          sku: item.sku,
          isShipping: item.isShipping,
          weight: item.weight,
          weightUnit: item.weightUnit,
          measurement: item.measurement || {},
        };
        return variantContainer;
      });

      setValue({
        _id,
        title,
        description,
        price,
        comparePrice,
        sku,
        barcode,
        variants: variantData,
        markUp,
        discount: vendorDiscount,
        inventoryManagement,
        weight,
        weightUnit,
        isShipping,
        quantity,
        productType,
        tags,
        length,
        width,
        height,
        updatedAt,
      });
      setIsFirstRender(variants && variants.length);
    }
  }, [match.params.id, productResponse]);

  const handleMarkup = (fieldName, fieldValue) => {
    setDisabledButton(false);
    const markUp = { ...value.markUp };
    markUp[fieldName] = fieldValue;
    setValue({
      ...value,
      markUp,
    });
  };

  const handleDiscount = (fieldName, fieldValue) => {
    setDisabledButton(false);
    const discount = { ...value.discount };
    discount[fieldName] = fieldValue || parseFloat(0);
    setValue({
      ...value,
      discount,
    });
  };
  const fieldData = [constant.IS_SHIPPING, constant.TITLE, constant.DESCRIPTION, constant.SKU];

  const handleChange = (fieldName, fieldValue) => {
    setDisabledButton(false);
    setValue({
      ...value,
      [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
    });
  };

  const handleVariants = () => {};

  const handleBanner = () => {
    setBanner({ isOpen: false });
  };

  const handleCancel = () => {
    history.push("/products");
  };

  const editProduct = (imagesToBeUpload = [], attachmentsToBeUpload = []) => {
    delete value.handle;
    delete value.sellerDiscount;
    delete value.vendorId;
    delete value.vendor;
    delete value.option1;
    delete value.option2;
    delete value.option3;
    const formValues = { ...value, images: imagesToBeUpload, attachments: attachmentsToBeUpload };

    if (formValues.discount.price) {
      formValues.discount.price = parseFloat(value.discount && value.discount.price);
    }

    if (formValues.updatedAt) {
      delete formValues.updatedAt;
    }

    let length = (value && value.length) || 0;
    let width = (value && value.width) || 0;
    let height = (value && value.height) || 0;

    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);

    if (length > MAX_MEASUREMENT || width > MAX_MEASUREMENT || height > MAX_MEASUREMENT) {
      const bannerData = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.measurement.length.title"),
      };
      setBanner(bannerData);
      return;
    }

    // // check volumetric weight is greater than 180 or not if yes then through error
    // const isLargeVolumetricWeight = baseHelper.isLargeVolumetricWeight(length, width, height);
    // if (isLargeVolumetricWeight) {
    //   const bannerData = {
    //     action: null,
    //     isOpen: true,
    //     status: constant.CRITICAL,
    //     title: cms("section.measurement.width.title"),
    //   };
    //   setBanner(bannerData);
    //   return;
    // }

    const measurement = {
      length: {
        value: length,
        unit: constant.CM,
      },
      width: {
        value: width,
        unit: constant.CM,
      },
      height: {
        value: height,
        unit: constant.CM,
      },
    };

    delete formValues.length;
    delete formValues.width;
    delete formValues.height;

    formValues.price = parseFloat(value.price);
    formValues.comparePrice = parseFloat(value.comparePrice);
    formValues.weight = parseFloat(value.weight);
    formValues.quantity = parseInt(value.quantity, 10);
    formValues.measurement = measurement;

    const { variants = [] } = formValues || {};
    if (variants && variants.length) {
      formValues.option1 = variants[0].option1 || constant.SIZE;
      formValues.option2 = variants[0].option2 || constant.COLOR;
      formValues.option3 = variants[0].option3 || constant.MATERIAL;
    }

    updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT);
        if (responseError) {
          setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        }
        if (responseData) {
          refetch();
          setBanner({
            isOpen: true,
            status: constant.SUCCESS,
            title: cms("message.success.updatedSuccessfully"),
            action: { content: cms("message.success.action.viewAll"), url: "/products" },
          });
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const handleSubmit = () => {
    setDisabledButton(true);
    const { images = [], attachments = [] } = value;
    const imagesToBeUpload = [];
    const attachmentsToBeUpload = [];
    let uploadImages = [];
    let uploadAttachment = [];
    if (images && images.length) {
      uploadImages = images.map((key) => {
        return uploadImage({ variables: { input: { image: key, productId: match.params.id } } })
          .then((response) => {
            const { data: imageData } = response.data.uploadImage;
            const { imageUrl, imageId } = imageData;
            if (imageUrl) {
              const uploadedImageData = {
                url: imageUrl,
                imageId,
              };
              imagesToBeUpload.push(uploadedImageData);
            } else {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse() });
            }
          })
          .catch((exception) => {
            setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
          });
      });
    }

    if (attachments && attachments.length) {
      uploadAttachment = attachments.map((key) => {
        return uploadFile({
          variables: {
            input: {
              file: key,
              productId: match.params.id,
            },
          },
        })
          .then((response) => {
            const { data: attachmentData } = response.data.uploadFile;
            const { fileURL, fileId } = attachmentData;
            if (fileURL) {
              const uploadedfileData = {
                fileURL,
                fileId,
              };
              attachmentsToBeUpload.push(uploadedfileData);
            } else {
              setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse() });
            }
          })
          .catch((exception) => {
            setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
          });
      });
    }
    Promise.all([...uploadImages, ...uploadAttachment]).then(() => {
      editProduct(
        [...existingProductImages, ...imagesToBeUpload],
        [...existingProductAttachments, ...attachmentsToBeUpload]
      );
    });
  };

  // const learnMore = (title, content) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(content);
  // };

  if (loading) return <Spinner />;

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            onDismiss={handleBanner}
            status={banner.status}
            action={banner.action}
          />
        </Layout.Section>
      )}
      {/* <Layout> */}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleDescription
          data={value}
          cms={cms}
          handleChange={handleChange}
          // learnMore={learnMore}
        />
        <Image
          data={value}
          cms={cms}
          handleChange={handleChange}
          existingProductImages={existingProductImages}
          setDisabledButton={setDisabledButton}
          setData={setValue}
          setExistingImages={setExistingProductImages}
          // learnMore={learnMore}
        />
        {isAwsCredsAvailable && (
          <Attachment
            data={value}
            cms={cms}
            handleChange={handleChange}
            existingProductAttachments={existingProductAttachments}
            setDisabledButton={setDisabledButton}
            setData={setValue}
            setExistingAttachments={setExistingProductAttachments}
          />
        )}
        {!isVariants && (
          <Price
            data={value}
            cms={cms}
            handleMarkup={handleMarkup}
            handleDiscount={handleDiscount}
            handleChange={handleChange}
            // learnMore={learnMore}
          />
        )}
        {!(value && value.variants && value.variants.length) && (
          <Inventory
            handleChange={handleChange}
            cms={cms}
            data={value}
            // learnMore={learnMore}
          />
        )}
        {value && value.variants && value.variants.length ? null : (
          <Shipping
            cms={cms}
            data={value}
            handleChange={handleChange}
            // learnMore={learnMore}
          />
        )}
        {value && value.variants && value.variants.length && isFirstRender ? (
          <RenderVariants
            data={value}
            cms={cms}
            currentUser={currentUser}
            handleVariants={handleVariants}
            handleBanner={setBanner}
            refetch={refetch}
            loading={loading}
            // learnMore={learnMore}
          />
        ) : (
          <Variants
            data={value}
            cms={cms}
            handleChange={handleChange}
            // learnMore={learnMore}
          />
        )}
        {!(isHideTagAndType || (isHideTag && isHideType)) ? (
          <Organization
            cms={cms}
            handleChange={handleChange}
            data={value}
            productType={typeListResponse}
            productTag={tagListResponse}
            // learnMore={learnMore}
          />
        ) : null}
        <Layout.Section>
          <Stack>
            <Stack.Item fill>
              <Button onClick={handleCancel}>{cms("common.button.cancel")}</Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                onClick={handleSubmit}
                primary
                loading={editLoading || imageLoading || uploadFileLoading}
                disabled={editLoading || disabledButton}
              >
                {cms("common.button.submit")}
              </Button>
            </Stack.Item>
          </Stack>
        </Layout.Section>
        <Sheet
          title={sheetTitle}
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={{
            content: cms("common.label.done"),
            onAction: () => setSheetActive(false),
          }}
          secondaryAction={{
            content: cms("common.button.cancel"),
            onAction: () => setSheetActive(false),
          }}
        >
          {sheetContent}
        </Sheet>
      </Layout.AnnotatedSection>
      {/* </Layout> */}
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderEditProduct), { feature: constant.EDIT_PRODUCT });
