// import packages
import React, { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Caption, Card, Checkbox, Layout, PageActions, Stack, TextContainer, TextStyle } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

import { withErrorBoundary, withFeature } from "lib/hoc";

import { SkeletonAnnotated, Toast } from "lib/components";

import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

// import gql
import { GET_WHOLESALE_SETTING } from "app/productOld/apollo/queries";
import { UPDATE_WHOLESALE_SETTING } from "app/productOld/apollo/mutations";

const WholesalePriceSetting = (props) => {
  const { setBannerStatus } = props;
  const [buttonValue, setButtonValue] = useState(true);
  const [firstPush, setFirstPush] = useState(true);
  const { cms, history } = useContext(PrivateContext);
  const { data: productSettingData, loading: getProductSettingLoading, refetch } = useQuery(GET_WHOLESALE_SETTING);
  const { gql } = constant;
  const [message, setMessage] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [updateWholesaleSetting, { loading: productSettingLoading }] = useMutation(UPDATE_WHOLESALE_SETTING);
  const [wholesalebutton, setwholesalebutton] = useState("");

  const handleChange = (value) => {
    setwholesalebutton(value);
    setButtonValue(false);
  };

  const onSubmit = () => {
    setButtonValue(true);
    updateWholesaleSetting({
      variables: {
        input: {
          isWholesalePriceVisible: wholesalebutton,
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_WHOLESALE_SETTING);
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_WHOLESALE_SETTING);
          setBannerStatus({ status: constant.CRITICAL, title: errorResponse, isOpen: true });
        }
        setMessage(cms("message.success.wholesale"));
        refetch();
      })
      .catch((exception) => {
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
          isOpen: true,
        });
      });
  };

  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingData, gql.GET_WHOLESALE_SETTING);
    if (!responseData) {
      return;
    }
    const { isWholesalePriceVisible, updatedAt = "" } = responseData;
    setwholesalebutton(isWholesalePriceVisible);
    setLastUpdated(updatedAt);
    if (updatedAt) {
      setFirstPush(false);
    }
  }, [productSettingData, gql.GET_WHOLESALE_SETTING]);

  if (getProductSettingLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      <Layout.AnnotatedSection title={cms("label.wholesale")} description={cms("section.wholesale.description")}>
        <Card
          title={[
            cms("section.wholesale.title"),
            lastUpdated && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>
              </TextStyle>
            ),
          ]}
        >
          <Card.Section>
            <Stack vertical>
              <TextContainer>{cms("section.wholesale.shortDescription")}</TextContainer>
            </Stack>
            <br />
            <Checkbox label={cms("button.wholesale")} checked={wholesalebutton} onChange={handleChange} />
            <br />
            <br />
            {!firstPush && (
              <div className="manageVendorAccessButton">
                <PageActions
                  primaryAction={{
                    id: "submitProductSetting",
                    content: cms("common.button.update"),
                    loading: productSettingLoading,
                    disabled: productSettingLoading || buttonValue,
                    onAction: () => onSubmit(),
                  }}
                  secondaryActions={[
                    {
                      id: "cancelProductSetting",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              </div>
            )}
          </Card.Section>
        </Card>
        {firstPush && (
          <Layout.Section>
            <PageActions
              primaryAction={{
                id: "submitProductSetting",
                content: cms("common.button.submit"),
                loading: productSettingLoading,
                disabled: productSettingLoading || buttonValue,
                onAction: () => onSubmit(),
              }}
              secondaryActions={[
                {
                  id: "cancelProductSetting",
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/"),
                },
              ]}
            />
          </Layout.Section>
        )}
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
      </Layout.AnnotatedSection>
      <br />
    </>
  );
};

export default withFeature(withErrorBoundary(WholesalePriceSetting), {
  feature: constant.PRODUCT_WHOLESALE_PRICE_SETTING,
});
