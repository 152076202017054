import React, { useContext } from "react";
import { ResourceList, Stack, TextStyle, Heading, Link, Caption, Badge } from "@shopify/polaris";

// import helpers
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import cms
import paymentListData from "app/userManagement/modules/generic/automaticPayout/feature/cms/paymentListData";

// import propTypes
import { listProp } from "app/userManagement/modules/generic/automaticPayout/feature/propsType";

function List(props) {
  const { history, cms } = useContext(PrivateContext);
  const resourceData = paymentListData(cms);
  const { mainLink, links } = resourceData;
  const { item } = props;
  const { _id, dueDate, errorMessage, gateway, status, orderNumber, vendor, createdAt, orderId, vendorId } = item;
  let badgeColor = "success";
  switch (status.toLowerCase()) {
    case "pending":
      badgeColor = "new";
      break;
    case "failed":
    case "error":
      badgeColor = "warning";
      break;

    default:
      break;
  }
  return (
    <ResourceList.Item id={_id}>
      <Stack vertical={false} distribution="fillEvenly">
        <Stack distribution="equalSpacing" alignment="center">
          <Stack.Item fill>
            <Heading>
              {`${cms("label.orderId")}: `}
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>{`#${orderNumber}`}</Link>
            </Heading>
          </Stack.Item>
          <Stack.Item>
            <Heading>{`(${baseHelper.ucFirst(gateway)})`}</Heading>
          </Stack.Item>
          <Stack.Item>
            <Badge status={badgeColor}>{baseHelper.ucFirst(status)}</Badge>
          </Stack.Item>
        </Stack>
      </Stack>
      <Caption>
        {`${cms("label.vendor")}: `}
        <Link plain onClick={() => history.push(`${links.vendor.link}${vendorId}`)}>
          {vendor}
        </Link>
      </Caption>
      {errorMessage ? <Caption>{`${cms("label.reason")}: ${errorMessage}`}</Caption> : ""}
      <Caption>{`${cms("label.dueDate")}: ${baseHelper.formatDate(dueDate)}`}</Caption>
      <Caption>
        <TextStyle variation="subdued">{`${cms("label.createdOn")}: ${baseHelper.formatDate(createdAt)} `}</TextStyle>
      </Caption>
    </ResourceList.Item>
  );
}

List.propTypes = listProp.type;
export default List;
