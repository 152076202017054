const cmsSellerView = (cms) => {
  const cmsData = {
    label: {
      brand_name: cms("sellerView.label.brand"),
      commission_type: cms("sellerView.label.commissionType"),
      commission: cms("sellerView.label.commission"),
      logo: cms("sellerView.label.logo"),
    },
  };

  return cmsData;
};

export default cmsSellerView;
