// import react packages;
import React, { useEffect, useState } from "react";
// import polaris components
import { Banner, Card, Checkbox, Layout, Modal, Scrollable, Stack, Subheading, Heading } from "@shopify/polaris";
// import props
import { termProp } from "app/product/modules/generic/propTypes";
import constant from "lib/constant/constant";
import config from "configuration";
import { baseHelper } from "lib/helpers";

const DiscoveryTermModal = (props) => {
  const url = baseHelper.parseUrl(config.rootURL);
  const { cms, isOpen, item, loading, onSubmit, setIsOpen, isProductDiscovery = true } = props;

  const isCustomDomain = window.location.hostname !== url.hostname;
  const isBRT = isProductDiscovery && isCustomDomain && ["singular.marketcube.io"].includes(window.location.hostname);

  const [isEnabled, setIsEnabled] = useState(false);

  const handleCheckbox = () => {
    setIsEnabled(!isEnabled);
  };

  useEffect(() => {
    setIsEnabled(false);
  }, [isOpen]);

  return (
    <Modal
      large
      open={isOpen}
      title={cms("common.section.connect.terms.title")}
      onClose={() => setIsOpen(false)}
      primaryAction={{
        content: cms("common.section.connect.terms.button.continue"),
        onAction: () => onSubmit(),
        disabled: !isEnabled,
        loading,
      }}
      secondaryActions={[
        {
          content: cms("common.section.connect.button.cancel"),
          plain: true,
          onAction: () => setIsOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Layout>
            <Layout.Section>
              <Banner status={constant.WARNING}>
                <p>{cms("common.section.connect.paraTag.para1", { item })}</p>
              </Banner>
            </Layout.Section>
            <Layout.Section>
              <Card sectioned>
                <Scrollable shadow className="scrollHeight">
                  {isBRT && (
                    <div dangerouslySetInnerHTML={{ __html: `
                      <h1 style="text-align: center;"><b>Termini e Condizioni di Sicurezza dei Dati e Copertura Responsabilit&agrave;</b></h1> <br/>
                  
                      <p>Misure di Sicurezza dei Dati: La Societ&agrave; si impegna a adottare misure ragionevoli per proteggere e salvaguardare tutti i dati dei clienti contro accessi non autorizzati, divulgazioni, alterazioni o distruzioni, in conformit&agrave; con il Regolamento Generale sulla Protezione dei Dati (GDPR) per i clienti dell'UE e il Data Protection Act 2018 per i clienti GB.</p> <br/>
                      
                      <p>Notifica di Violazione dei Dati: In caso di violazione dei dati, la Societ&agrave; notificher&agrave; prontamente il(i) cliente(i) interessato(i) e le autorit&agrave; di vigilanza competenti nell'UE e l'Ufficio del Commissario per l'Informazione del Regno Unito (ICO) conformemente ai requisiti del GDPR e del Data Protection Act 2018. Copertura Responsabilit&agrave;: La Societ&agrave; mantiene una copertura assicurativa cibernetica per mitigare l'impatto finanziario delle violazioni dei dati sui clienti sia nell'UE che in GB. Tale copertura include l'indennizzo per le perdite finanziarie dirette subite dai clienti a causa della negligenza della Societ&agrave; o della sua mancata adeguata salvaguardia dei loro dati, come richiesto dal GDPR e dal Data Protection Act 2018.</p> <br/>
                      
                      <p>Limiti di Responsabilit&agrave;: La responsabilit&agrave; della Societ&agrave; ai sensi del presente accordo non superer&agrave; i limiti di copertura specificati nella polizza assicurativa cibernetica, tranne nei casi di dolo o grave negligenza, in conformit&agrave; con le leggi e i regolamenti UE e GB applicabili. Responsabilit&agrave; del Cliente: I clienti sono responsabili di fornire informazioni accurate e aggiornate alla Societ&agrave; allo scopo di ottenere una copertura assicurativa cibernetica. I clienti devono anche attenersi a qualsiasi protocollo di sicurezza o raccomandazione fornita dalla Societ&agrave; per mitigare il rischio di violazioni dei dati, come richiesto dal GDPR e dal Data Protection Act 2018.</p> <br/>
                      
                      <p>Processo di Reclami: In caso di violazione dei dati, i clienti interessati devono notificare prontamente la Societ&agrave; e fornire tutta la documentazione necessaria per facilitare il processo di reclami con il fornitore di assicurazioni cibernetiche, in conformit&agrave; con le procedure delineate nel GDPR e nel Data Protection Act 2018.</p> <br/>
                      
                      <p>Esclusioni: La copertura assicurativa cibernetica fornita dalla Societ&agrave; potrebbe non coprire determinati tipi di perdite, incluse ma non limitate a perdite derivanti da atti di guerra, terrorismo o atti intenzionali da parte di dipendenti o terze parti, come specificato nella polizza assicurativa cibernetica e nei regolamenti UE e GB pertinenti.</p> <br/>
                      
                      <p>Revisione della Polizza: La Societ&agrave; si riserva il diritto di revisionare periodicamente e aggiornare la propria polizza assicurativa cibernetica per garantire una copertura adeguata contro le minacce emergenti e i requisiti normativi sia nell'UE che in GB. Confidenzialit&agrave;: Tutte le informazioni relative alla copertura assicurativa cibernetica, incluse le condizioni della polizza, i limiti di copertura e le informazioni sui reclami, saranno trattate come riservate e non saranno divulgate a terzi senza il preventivo consenso scritto della Societ&agrave;, in conformit&agrave; con il GDPR e il Data Protection Act 2018.</p> <br/>
                      
                      <p>Legge Applicabile: Il presente accordo sar&agrave; regolato e interpretato in conformit&agrave; con le leggi del Regno Unito, e qualsiasi controversia derivante da o relativa a questo accordo sar&agrave; risolta tramite arbitrato in conformit&agrave; con le regole dell'arbitrato concordate, nel rispetto delle leggi e dei regolamenti UE e GB applicabili. Le procedure arbitrali saranno condotte in conformit&agrave; con la legge sull'arbitrato del Regno Unito.</p> <br/>
                      
                      <p>Accettando questi termini e condizioni, il cliente riconosce di aver letto, compreso e accettato le disposizioni qui delineate, in conformit&agrave; con il GDPR e il Data Protection Act 2018 per i clienti UE e GB, rispettivamente.</p> <br/>
                    ` }} />
                  )}
                  {!isBRT && (
                    <>
                      <b>{`${cms("common.section.connect.boldTag.b1")} `}</b>
                      {cms("common.section.connect.paraTag.para2")}
                      <br />
                      <br />
                      {cms("common.section.connect.paraTag.para3")}
                      <b>{` ${cms("section.connect.boldTag.b1")}, `}</b>
                      {cms("common.section.connect.paraTag.para4", { item })}
                      <br />
                      <br />
                      {cms("common.section.connect.paraTag.para5")}
                      <b>{` ${cms("section.connect.boldTag.b1")} `}</b>
                      {cms("common.section.connect.paraTag.para6", { item })}
                      <br />
                      <br />
                      <Subheading>{cms("common.section.connect.subHeading.sub1", { item })}</Subheading>
                      {cms("common.section.connect.paraTag.para12", { item })}
                      <br />
                      <br />
                      <Subheading>{cms("common.section.connect.subHeading.sub2")}</Subheading>
                      {cms("common.section.connect.paraTag.para7")}
                      <br />
                      <br />
                      <b>{`${cms("common.section.connect.boldTag.b2")} ${cms("common.section.connect.boldTag.b1")} `}</b>
                      {cms("common.section.connect.paraTag.para9")}
                      <br />
                      <br />
                      <Card.Section subdued title={<b>What we don't need or ask for?</b>}>
                        <br />
                        <Subheading>Order Access</Subheading>
                        <b>We do not ask for order permission</b> to access information about your created and managed orders in your store. We do not track on store for any kind of details related to order in your store.
                        <br />
                        <br />
                        <Subheading>Customer Access</Subheading>
                        <b>We do not ask for customer permission</b> to access information about your customers in your store. We do not track on store for any kind of details related to your customers.
                        <br />
                      </Card.Section>
                    </>
                  )}
                </Scrollable>
              </Card>
            </Layout.Section>
          </Layout>
          <Checkbox
            checked={isEnabled}
            label={cms("common.section.connect.terms.label.acceptTerm")}
            onChange={() => handleCheckbox()}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

DiscoveryTermModal.propTypes = termProp.type;
DiscoveryTermModal.defaultProps = termProp.default;

export default DiscoveryTermModal;
