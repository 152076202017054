import { gql } from "apollo-boost";

const UPDATE_ORDER_LIVE_CONNECT_SETTING = gql`
  mutation updateOrderLiveConnectSetting($input: UpdateOrderLiveConnectSetting!) {
    updateOrderLiveConnectSetting(input: $input) {
      data
      status
      error
    }
  }
`;
export default UPDATE_ORDER_LIVE_CONNECT_SETTING;
