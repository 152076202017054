import { gql } from "apollo-boost";

const EDIT_PRODUCT = gql`
  mutation editProduct($input: EditProduct) {
    editProduct(input: $input) {
      status
      data
      error
    }
  }
`;

const ATTACH_UPLOAD = gql`
  mutation attachUpload($input: AttachUpload) {
    attachUpload(input: $input) {
      status
      data {
        _id
      }
      error
    }
  }
`;
export { EDIT_PRODUCT, ATTACH_UPLOAD };
