import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { Stack, Button } from "@shopify/polaris";

// import from lib
import { PrivateContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";

// GQL
import BULK_EDIT_PRODUCT from "app/productOld/apollo/mutations/bulkEditProduct";
import constant from "lib/constant/constant";

const {
  value: { MAX_MEASUREMENT = 999, MAX_MARKUP_PERCENTAGE },
} = constant;

function EditProduct(props) {
  const { formData, editBanner, addField } = props;
  const { history, currentUser, cms } = useContext(PrivateContext);
  const [updateProducts, { loading }] = useMutation(BULK_EDIT_PRODUCT);
  const isSeller = baseHelper.isSeller(currentUser);
  const discountKey = isSeller ? constant.SELLER_DISCOUNT : constant.VENDOR_DISCOUNT;
  const updateProduct = async () => {
    if (Object.keys(formData).length > 0) {
      let isInvalidRow = false;
      let isInvalidDimension = false;
      let isGreaterVolumetricWeight = false;
      let data = JSON.parse(JSON.stringify(formData));
      data = data
        .filter((filterItem) => {
          const itemData = filterItem;
          const { markUp = {}, sellerDiscount = {}, vendorDiscount = {} } = filterItem || {};
          const { price: markUpPrice = null, type: markUpType = null } = markUp || {};
          const { price: sellerDiscountPrice = null, type: sellerDiscountType = null } = sellerDiscount || {};
          const { price: vendorDiscountPrice = null, type: vendorDiscountType = null } = vendorDiscount || {};

          if (isSeller) {
            // const hasPriceKey = baseHelper.hasKey(itemData, "price");
            // if (hasPriceKey) {
            // delete itemData.price;
            // }
            itemData.markUp = { price: parseFloat(markUpPrice), type: markUpType };
            itemData.sellerDiscount = {
              price: parseFloat(sellerDiscountPrice),
              type: sellerDiscountType,
            };
          } else {
            itemData.vendorDiscount = {
              price: parseFloat(vendorDiscountPrice),
              type: vendorDiscountType,
            };
          }
          return itemData;
        })
        .map((item) => {
          const { _id: id, productId, position, price = 0, comparePrice = 0 } = item;
          const pItem = {
            _id: isSeller ? productId : id,
          };
          addField.forEach((hItem) => {
            let label = hItem.toLowerCase();
            if (label === constant.DISCOUNT) {
              label = discountKey;
            }
            if (label === constant.MARKUPKEY.toLowerCase()) {
              label = constant.MARKUP;
            }
            if (label === constant.COMPARE_AT_PRICE.toLowerCase()) {
              label = constant.COMPARE_PRICE;
            }
            if (label === "Listing Quantity".toLowerCase()) {
              label = "listingQuantity";
            }
            if (item[label]) {
              pItem[label] = item[label];
            }
          });

          if (item.position) {
            pItem.position = position;
          }

          if (item.measurement) {
            const { length = {}, width = {}, height = {} } = item.measurement || {};
            const { value: lengthValue = 0 } = length || {};
            const { value: widthValue = 0 } = width || {};
            const { value: heightValue = 0 } = height || {};

            if (lengthValue > MAX_MEASUREMENT || widthValue > MAX_MEASUREMENT || heightValue > MAX_MEASUREMENT) {
              isInvalidDimension = true;
            }
            // // check volumetric weight is greater than 180 or not if yes then through error
            // if (baseHelper.isLargeVolumetricWeight(lengthValue, widthValue, heightValue)) {
            //   isGreaterVolumetricWeight = true;
            // }

            pItem.measurement = item.measurement || {};
          }
          if (pItem.markUp) {
            isInvalidRow =
              isInvalidRow ||
              !!(pItem.markUp.type === constant.PERCENTAGE && parseFloat(pItem.markUp.price) > MAX_MARKUP_PERCENTAGE);
          }

          if (pItem[discountKey]) {
            isInvalidRow =
              isInvalidRow ||
              !!(pItem[discountKey].type === constant.PERCENTAGE && parseFloat(pItem[discountKey].price) > 100);
          }
          if (price) {
            pItem.price = parseFloat(price || 0);
          }
          if (comparePrice) {
            pItem.comparePrice = parseFloat(comparePrice || 0);
          }
          return pItem;
        });

      if (isInvalidRow) {
        editBanner({ isOpen: true, title: cms("message.error.greaterThan"), status: "critical" });
        return;
      }

      if (isInvalidDimension || isGreaterVolumetricWeight) {
        const bannerData = {
          isOpen: true,
          status: constant.CRITICAL,
          title: isInvalidDimension
            ? cms("message.error.inValidDimension")
            : cms("message.error.greaterVolumetricWeight"),
        };
        editBanner(bannerData);
        return;
      }
      editBanner({ isOpen: false, title: "", status: "" });

      await updateProducts({
        variables: {
          input: {
            products: data,
          },
        },
      })
        .then((res) => {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_EDIT_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_EDIT_PRODUCT);
          if (responseData) {
            setTimeout(() => {
              history.push("/products");
            }, 2000);
            editBanner({ isOpen: true, title: cms("message.success.updateSuccess"), status: "success" });
          }
          if (responseDataError) {
            editBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        })
        .catch((exception) => {
          editBanner({ isOpen: true, title: errorHelper.parse(exception), status: "critical" });
        });
    }
  };

  return (
    <Stack>
      <Stack.Item fill>
        <Button onClick={() => history.push(constant.PRODUCT_URL)}>{cms("common.button.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        <Button primary onClick={() => updateProduct()} loading={loading}>
          {cms("common.button.submit")}
        </Button>
      </Stack.Item>
    </Stack>
  );
}

EditProduct.defaultProps = {
  formData: [{}],
  editBanner: () => {},
  addField: [],
};
EditProduct.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.object),
  editBanner: PropTypes.func,
  addField: PropTypes.arrayOf(PropTypes.string),
};

export default EditProduct;
