// eslint-disable-next-line import/no-extraneous-dependencies
import gql from "graphql-tag";

// will need to modify keys for getOrderRefund

const GET_OPERATOR_SETTING = gql`
  query {
    getSellerSetting {
      data {
        chat {
          isCustomer
          isVendor
        }
        order {
          isHideBillingInfo
        }
      }
      status
      error
    }
  }
`;

const getOrderView = gql`
  query getOrderView($input: GetOrderDetail!) {
    getOrderView(input: $input) {
      status
      error
      data {
        appId
        billingAddress {
          address1
          address2
          city
          company
          country
          country_code
          country_name
          customer_id
          default
          first_name
          id
          last_name
          name
          phone
          province
          province_code
          zip
        }
        confirmed
        contactEmail
        currency
        customer {
          accepts_marketing
          currency
          default_address {
            address1
            address2
            city
            company
            country
            country_code
            country_name
            customer_id
            default
            first_name
            id
            last_name
            name
            phone
            province
            province_code
            zip
          }
          email
          first_name
          id
          last_name
          last_order_id
          last_order_name
          latitude
          longitude
          marketing_opt_in_level
          multipass_identifier
          note
          orders_count
          phone
          state
          tags
          tax_exempt
          total_spent
          verified_email
        }
        note
        createdAt
        customerLocale
        deviceId
        discountApplications {
          type
          value
          value_type
          allocation_method
          target_selection
          target_type
          code
        }
        discountCodes {
          amount
          code
          type
        }
        email
        financialStatus
        fulfillmentStatus
        gateway
        id
        isBuyerAcceptsMarketing
        isOrderBasedCommission
        isShippingPaid
        isThirdPartyShipping
        isHideCustomerDetails
        isTest
        landingSite
        landingSiteRef
        locationId
        moneyFormat
        name
        noteAttributes {
          name
          value
        }
        number
        orderNumber
        orderStatusUrl
        paymentGateway
        phone
        presentmentCurrency
        processingMethod
        reference
        referringSite
        refunds {
          user_id
        }
        sellerId
        sellerShippingAmount
        shipping {
          amount
          perVendorAmount
          term
        }
        shippingAddress {
          address1
          address2
          city
          company
          country
          country_code
          country_name
          customer_id
          default
          first_name
          id
          last_name
          name
          phone
          province
          province_code
          zip
        }
        shippingLines {
          title
          price
          code
          source
          requested_fulfillment_service_id
          carrier_identifier
          discounted_price
        }
        shop
        shopifyOrderId
        shopifyShippingCharge
        sourceIdentifier
        sourceName
        sourceUrl
        status
        subtotal
        subtotalPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        tags
        taxesIncluded
        transactionFeeLines {
          labelName
          feeType
          feeValue
        }
        taxLines {
          price
          rate
          title
        }
        token
        totalDiscount
        totalDiscountsSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalLineItemsPrice
        totalLineItemsPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalPrice
        totalPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalPriceUsd
        totalShippingPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTax
        totalTaxSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTipReceived
        totalWeight
      }
    }
  }
`;

const getOrderLineView = gql`
  query getOrderLineView($input: GetOrderLineView!) {
    getOrderLineView(input: $input) {
      data {
        _id
        accountingStatus
        actualQuantity
        addressId
        collectDetail {
          addressId
          address
          city
          country
          countryCode
          postalCode
          code
          note
          readyQuantity
        }
        commissionSeller
        commissionSysAdmin
        commissionVendor
        consignmentPickAddress {
          address
          city
          country
          pinCode
        }
        createdAt
        customCharge
        customLabel
        discountAllocations {
          amount
          discount_application_index
        }
        dueDate
        fulfillableQuantity
        fulfillmentService
        fulfillmentStatus
        fulfillmentType
        grams
        hopscotch {
          status
        }
        id
        image
        isAutoExpiry
        isAutoReject
        isRefund
        isCampaign
        isConsignmentPick
        isReturn
        isDeleted
        isDelivered
        isDiscountAllowed
        isGiftCard
        isProductDeleted
        isShippingPaid
        isVendorDeleted
        isVendorFulfilled
        liveConnect
        markUpPrice
        moneyFormat
        name
        orderId
        orderNumber
        paidVia
        price
        priceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        productExists
        productId
        productQuantity
        properties {
          name
          value
        }
        quantity
        requiresShipping
        rejectedByVendor
        sellerDiscount
        sellerId
        shippingAmount
        shop
        shopifyLineItemId
        shopifyOrderDate
        shopifyOrderId
        shopifyOrderUpdateDate
        shopifyProductId
        sku
        status
        taxable
        taxLines {
          price
          rate
          title
        }
        title
        totalDiscount
        totalDiscountSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTax
        updatedAt
        trackingCompany
        trackingNumber
        variantId
        variantInventoryManagement
        variantTitle
        vendor
        vendorDiscount
        vendorId
        vendorShopifyProductId
        vendorTrackingCompany
        vendorTrackingNumber
        consignmentId
        consignmentUrl
        returnedQuantity
      }
      status
      error
    }
  }
`;

const GET_ORDER_REFUND = gql`
  query getOrderRefund($input: GetOrderLineView!) {
    getOrderRefund(input: $input) {
      data {
        _id
        lineItems {
          id
          line_item {
            fulfillment_status
            id
            price
            quantity
            sku
            title
            vendor
          }
          line_item_id
          quantity
          restock_type
          subtotal
        }
        note
        orderId
        shopifyRefundId
      }
      status
      error
    }
  }
`;

const GET_ORDER_FULFILLMENT = gql`
  query getOrderFulfillment($input: GetOrderLineView!) {
    getOrderFulfillment(input: $input) {
      data {
        _id
        lineItems {
          id
          name
          price
          quantity
          sku
          title
          vendor
        }
        orderId
        shopifyOrderId
        shopifyFulfillmentId
        status
        trackingCompany
        trackingNumber
      }
      status
      error
    }
  }
`;

export { GET_OPERATOR_SETTING, getOrderView, getOrderLineView, GET_ORDER_REFUND, GET_ORDER_FULFILLMENT };
