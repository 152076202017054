import React from "react";

import { Layout } from "@shopify/polaris";

import PropTypes from "prop-types";

const SingleColumnLayout = (props) => {
  const { primary = null } = props;
  return (
    <>
      <Layout.Section>{primary}</Layout.Section>
    </>
  );
};

SingleColumnLayout.propTypes = {
  primary: PropTypes.shape(PropTypes.object).isRequired,
};

export default SingleColumnLayout;
