import React from "react";
import { ResourceList, TextStyle } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";

// import propTypes
import { listProps } from "app/vendors/modules/operator/feature/export/Props/props";

import constant from "lib/constant/constant";
import { Spinner } from "lib/components";

const List = (props) => {
  const {
    cms,
    handleSelection,
    items = {},
    orderLoading,
    onExport = () => {},
    selected = 0,
    selectedItems = {},
    getVendorLoading,
    isLoading,
  } = props;
  const promotedBulkActions = [
    {
      content: cms("sectionContent.export.label.generate"),
      onAction: () => onExport(),
    },
  ];

  Object.keys(items).forEach(index => {
    items[index] = items[index].filter(item => item.id && item.name);
  });

  return (
    <>
      {getVendorLoading && !isLoading ? (
        <Spinner />
      ) : (
        <ResourceList
          key="resourceList"
          items={(items[selected] || []).filter((item) => item.name && item.id)}
          renderItem={(item) => {
            const { id, name } = item;
            const replaceName = {
              onHold: constant.ON_HOLD,
            };
            const statusName = name.replace(/\b(?:onHold)\b/gi, (matched) => replaceName[matched]);
            return (
              <ResourceList.Item id={id} accessibilityLabel={`${cms("section.export.label.view")} ${id}`}>
                <h3>
                  <TextStyle variation="strong">{baseHelper.ucFirst(statusName)}</TextStyle>
                </h3>
              </ResourceList.Item>
            );
          }}
          selectedItems={selectedItems[selected]}
          onSelectionChange={handleSelection}
          selectable
          promotedBulkActions={promotedBulkActions}
          loading={orderLoading}
        />
      )}
    </>
  );
};

List.propTypes = listProps.type;
List.defaultProps = listProps.default;

export default List;
