import { gql } from "apollo-boost";

const GET_PRODUCT = gql`
  query getProduct($input: GetProduct) {
    getProduct(input: $input) {
      data {
        _id
        addedBy
        approved
        attachments {
          fileId
          fileURL
        }
        barcode
        comparePrice
        customCharge
        customLabel
        customRule
        createdAt
        description
        fulfillmentService
        handle
        images {
          imageId
          imageUrl
          position
          url
        }
        inventoryManagement
        isShipping
        isTaxable
        lastImagePosition
        lastVariantPosition
        markUp {
          price
          type
        }
        moneyFormat
        option1
        option2
        option3
        price
        primaryImageUrl
        productId
        productType
        inventoryManagement
        sellerDiscount {
          price
          type
        }
        vendorDiscount {
          price
          type
        }
        quantity
        sellerId
        shopifyProductId
        sku
        status
        tags
        title
        variants {
          barcode
          comparePrice
          customCharge
          customLabel
          customRule
          fulfillmentService
          image
          imageId
          markUp {
            type
            price
          }
          imageUrl
          inventoryManagement
          inventoryPolicy
          inventoryQuantity
          isShipping
          isTaxable
          option1
          option1Val
          option2
          option2Val
          option3
          option3Val
          position
          price
          quantity
          sku
          title
          vendorDiscount {
            price
            type
          }
          weight
          weightUnit
          measurement {
            length {
              value
              unit
            }
            width {
              value
              unit
            }
            height {
              value
              unit
            }
          }
        }
        vendor
        vendorDiscount {
          price
          type
        }
        vendorId
        weight
        weightUnit
        measurement {
          length {
            value
            unit
          }
          width {
            value
            unit
          }
          height {
            value
            unit
          }
        }
        updatedAt
      }
      status
      error
    }
  }
`;
export default GET_PRODUCT;
