import React, { useContext, useState, useEffect } from "react";
import { Caption, Form, FormLayout, RadioButton, Stack, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers Components
import { CardFooter, Banner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { UPDATE_COMMISSION } from "app/setup/apollo/mutations";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import propTypes
import { priorityProp } from "app/setup/modules/operator/features/commission/props";

const CommissionPriority = (props) => {
  const { cms = {}, currentUser = {} } = useContext(PrivateContext);
  const { isStripeSubscription = false } = currentUser;
  const {
    advanceCommissionValue,
    standardCommissionValue,
    rangeCommissionValue,
    priorityValue,
    setPriorityValue,
    isTaxInclusive,
  } = props;
  const {
    PRODUCT_TYPE,
    VENDOR,
    RANGE,
    gql: { UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
  } = constant;

  // React state
  const [priority, setPriority] = useState();
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  // gql state
  const [setRangeCommission, { loading }] = useMutation(UPDATE_COMMISSION);

  const updatePriority = (value) => {
    setPriority(value);
    setPriorityValue(value);
    setSubmitEnable(true);
  };

  const mapAdvanceCommissionPrice = () => {
    return advanceCommissionValue.advancedValues.map((item) => {
      const updatedItem = { ...item };
      if (item.price) {
        updatedItem.price = parseFloat(item.price);
      }
      return updatedItem;
    });
  };

  const updateCommissionPriority = async (requestData) => {
    let bannerContent = {};
    try {
      const res = await setRangeCommission({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);
      bannerContent = {
        isOpen: true,
        status: "success",
        title: cms("message.success"),
      };
      setSubmitEnable(false);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        bannerContent = { isOpen: true, status: "critical", title: errorResponse };
      }
      setBanner(bannerContent);
    } catch (exception) {
      bannerContent = { isOpen: true, status: "critical", title: errorHelper.parse(exception) };
      setBanner(bannerContent);
    }
  };
  const mapRangeCommission = () => {
    return rangeCommissionValue.map((obj) => {
      const { min, ...rest } = obj;
      return rest;
    });
  };

  const handleSubmit = () => {
    if (!priority) {
      setBanner({ isOpen: true, status: "critical", title: cms("message.error.priorityIsRequired") });
      return;
    }
    const isAnyAdvanceValue =
      advanceCommissionValue &&
      advanceCommissionValue.advancedValues &&
      Array.isArray(advanceCommissionValue.advancedValues) &&
      advanceCommissionValue.advancedValues.length !== 0;

    const isAnyRangeValue =
      rangeCommissionValue && Array.isArray(rangeCommissionValue) && rangeCommissionValue.length !== 0;
    const requestData = {
      type: standardCommissionValue.selectedStandardType,
      price: parseFloat(standardCommissionValue.standardAmount),
      advancedValues: (isAnyAdvanceValue && mapAdvanceCommissionPrice()) || [],
      rangeValues: (isAnyRangeValue && mapRangeCommission()) || [],
      ruleBy: priority,
      isTaxInclusive,
    };
    updateCommissionPriority(requestData);
  };
  const dismissBanner = () => {
    setBanner({ isOpen: false });
  };

  useEffect(() => {
    if (!priorityValue) {
      return;
    }
    setPriority(priorityValue);
  }, [priorityValue]);

  return (
    <>
      {banner.isOpen && (
        <>
          <br />
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            isScrollTop={false}
            onDismiss={() => dismissBanner()}
          >
            {banner.message}
          </Banner>
        </>
      )}
      <br />
      <Stack vertical>
        <RadioButton
          label={[
            cms("section.priority.label.productType"),
            <TextStyle variation="subdued">
              <Caption>Product type for set the priority first to product</Caption>
            </TextStyle>,
          ]}
          checked={priority === PRODUCT_TYPE}
          id="disabled"
          name="priority"
          disabled={isStripeSubscription}
          onChange={() => updatePriority(PRODUCT_TYPE)}
        />
        <RadioButton
          label={[
            cms("common.label.vendor"),
            <TextStyle variation="subdued">
              <Caption>Vendor type for set the priority first to vendor</Caption>
            </TextStyle>,
          ]}
          id="optional"
          name="priority"
          checked={priority === VENDOR}
          disabled={isStripeSubscription}
          onChange={() => updatePriority(VENDOR)}
        />
        <RadioButton
          label={[
            cms("section.commissionByPrice.title"),
            <TextStyle variation="subdued">
              <Caption>Commission by price range type for set the priority first to commission</Caption>
            </TextStyle>,
          ]}
          id="optionalValue"
          name="priority"
          checked={priority === RANGE}
          disabled={isStripeSubscription}
          onChange={() => updatePriority(RANGE)}
        />
      </Stack>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <CardFooter disabled={!submitEnabled} loading={loading || false} />
        </FormLayout>
      </Form>
    </>
  );
};

CommissionPriority.propTypes = priorityProp.type;

export default CommissionPriority;
