import React, { useContext, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import queries
import { GET_PRODUCT } from "app/product/apollo/queries";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";

// import tab
import EditTabs from "./features/form/tab";

const ProductEdit = () => {
  const { isLoading, match } = useContext(PrivateContext);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isVariant, setIsVariant] = useState(false);

  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const { loading: productLoading, data: productData, refetch } = useQuery(GET_PRODUCT, {
    variables: { input: { id: match.params.id } },
  });

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCT) || {};
      const { variants = [] } = productResponse || {};
      if (variants && variants.length) {
        setIsVariant(true);
        return;
      }
      setIsVariant(false);
    }
  }, [productData]);

  if (isLoading || productLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={title} description={description}>
        <EditTabs
          setBanner={setBanner}
          setDescription={setDescription}
          setTitle={setTitle}
          isVariant={isVariant}
          refetch={refetch}
          setIsVariant={setIsVariant}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default ProductEdit;
