import React from "react";
import { PieChart, Pie, Cell } from "recharts";

// import props
import { pieGraphProp } from "app/reporting/modules/operator/props";

const PieGraph = (props) => {
  const { data, dataKey, labelSuffix, width = 900, height = 450, cx = 400, cy = 200, outerRadius = 150 } = props;
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FE8153", "#0089DE"];
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line no-shadow
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const label = data[index][labelSuffix] ? `${data[index][labelSuffix]}-` : "";
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${label}${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <PieChart width={width} height={height}>
      <Pie
        cx={cx}
        cy={cy}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={outerRadius}
        fill="#8884d8"
        data={data}
        dataKey={dataKey}
      >
        {data.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

PieGraph.propTypes = pieGraphProp.type;

export default PieGraph;
