import { DomainsMajor, CalendarTickMajor, CalendarMajor } from "@shopify/polaris-icons";

const settingConfig = (cms) => {
  const config = {
    "/": [
      {
        caption: cms("common.actions.dashboard.caption.customDomain"),
        label: cms("common.actions.dashboard.label.customDomain"),
        link: "/domain",
        icon: DomainsMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.featurePlan"),
        label: cms("common.actions.dashboard.label.featurePlan"),
        link: "/feature",
        icon: CalendarMajor,
      },
      {
        caption: cms("common.actions.dashboard.caption.plan"),
        label: cms("common.actions.dashboard.label.plan"),
        link: "",
        icon: CalendarTickMajor,
      },
    ],
  };

  const title = {
    "/": cms("common.actions.title.setting"),
    "/orders": cms("common.actions.title.orderSettings"),
    "/payments": cms("common.actions.title.paymentSettings"),
    "/products": cms("common.actions.title.productSettings"),
    "/providers": cms("common.actions.title.vendorSettings"),
  };

  return { config, title };
};

export default settingConfig;
