import { gql } from "apollo-boost";

const FULFILL_ORDER_LAKE_ITEM = gql`
  mutation fulfillOrderLakeItem($input: FulfillOrderLakeLine!) {
    fulfillOrderLakeItem(input: $input) {
      status
      data
      error
    }
  }
`;

export default FULFILL_ORDER_LAKE_ITEM;
