import React, { useContext } from "react";

import constant from "lib/constant/constant";
import { SkeletonAnnotated } from "lib/components";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";

import ManageExport from "./operator/features/manageExport/manageExport";

const { userKey } = constant;
const { operator } = userKey;

const ManageVendorExport = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <ManageExport />;
    }
    return null;
  };

  const ManageExportVendor = getComponent(currentUserRole);
  return ManageExportVendor;
};

export default ManageVendorExport;
