import { gql } from "apollo-boost";

const EDIT_PROFILE = gql`
  mutation updateVendor($input: UpdateVendorProfile!) {
    updateVendorProfile(input: $input) {
      data
      status
      error
    }
  }
`;
export default EDIT_PROFILE;
