import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions, List, Card, Checkbox } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import components
import { Banner, Spinner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

// import from gql
import { UPDATE_MAINTENANCE } from "app/userManagement/apollo/mutation";
import { GET_MAINTENANCE } from "app/userManagement/apollo/queries";
import { errorHelper } from "lib/helpers";

const { gql } = constant;

const AdminMaintenance = () => {
  const { history, cms = {} } = useContext(PrivateContext);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [setUpdateMaintenance, { loading }] = useMutation(UPDATE_MAINTENANCE);
  const { loading: maintenanceLoading, error, data } = useQuery(GET_MAINTENANCE);
  const [checkedVal, setChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  const [values, setValues] = useState({
    isApp: false,
    isSite: false,
  });
  const handleChange = (value) => {
    setCheckedValue(value);
    setValues((prevValues) => ({
      ...prevValues,
      isSite: value,
    }));
  };
  const handleCheck = (value) => {
    setChecked(value);
    setValues((prevValues) => ({
      ...prevValues,
      isApp: value,
    }));
  };
  const onSubmit = async () => {
    try {
      const res = await setUpdateMaintenance({
        variables: {
          input: {
            maintenance: {
              ...values,
            },
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_MAINTENANCE);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_MAINTENANCE);
        setBannerStatus({
          status: constant.CRITICAL,
          title: errorResponse,
          isOpen: true,
        });
      }
      setBannerStatus({
        status: constant.SUCCESS,
        title: cms("message.success"),
        isOpen: true,
      });
    } catch (exception) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  useEffect(() => {
    if (error) {
      setBannerStatus({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorHelper]);

  useEffect(() => {
    if (data) {
      const responseData = baseHelper.getResponseData(data, gql.GET_MAINTENANCE);
      if (!responseData) {
        const responseError = baseHelper.getResponseError(data, gql.GET_MAINTENANCE);
        setBannerStatus({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      setCheckedValue(responseData.isSite);
      setChecked(responseData.isApp);
    }
  }, [data]);

  if (maintenanceLoading) {
    return <Spinner />;
  }

  const standardLists = [`${cms("captions.app")}`, `${cms("captions.site")}`];

  const renderCaptions = () => {
    return standardLists.map((listItem) => <List.Item key={listItem}>{listItem}</List.Item>);
  };
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Layout style={{ opacity: loading ? 1 : 0.5 }}>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Layout.Section>
            <Card sectioned>
              <List type="bullet">{renderCaptions()}</List>
              <Checkbox label={cms("label.app")} checked={checkedVal} onChange={handleCheck} id="autoVerifyCheckBox" />
              <br />
              <Checkbox
                label={cms("label.site")}
                checked={checkedValue}
                onChange={handleChange}
                id="readOnlyCheckbox"
              />
            </Card>
          </Layout.Section>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: cms("common.button.submit"),
              onAction: () => onSubmit(),
              loading,
            }}
            secondaryActions={[
              {
                content: cms("common.button.cancel"),
                onAction: () => history.push("/"),
              },
            ]}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withErrorBoundary(AdminMaintenance);
