import React from "react";
import Information from "app/product/modules/admin/features/add/subFeatures/form/subFeatureItem/information";

const addData = (cms) => {
  return {
    tabs: [
      {
        id: "information",
        content: cms("label.information"),
        panelID: "information-content",
        PanelComponent: (selectedTab) => <Information selectedTab={selectedTab} />,
      },
      {
        id: "inventory",
        content: cms("label.inventory"),
        panelID: "inventory-content",
      },
      {
        id: "image",
        content: cms("label.image"),
        panelID: "image-content",
      },
      {
        id: "variant",
        content: cms("label.variant"),
        panelID: "variant-content",
      },
      {
        id: "fulfillment",
        content: cms("label.fulfillment"),
        panelID: "fulfillment-content",
      },
      {
        id: "attachment",
        content: cms("label.attachment"),
        panelID: "attachment-content",
      },
    ],
  };
};

export default addData;

export const getPanelComponent = (selectedTab, cms) => {
  return addData(cms).tabs[selectedTab].PanelComponent(selectedTab);
};
