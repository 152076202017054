import { gql } from "apollo-boost";

const SYNC_ORDER = gql`
  mutation {
    syncOrder {
      data
      status
      error
    }
  }
`;

export default SYNC_ORDER;
