import React, { useContext, useState, useEffect } from "react";
import { Card, FormLayout, Select, Caption, SkeletonBodyText, TextContainer } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import components

// import helpers and consstants
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import Tags from "./tag";
import { FormContext } from "../../../../context/context";

// import queries
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE } from "../../../../../../../../apollo/queries";

const Organization = () => {
  const { data, handleChange, learnMore, setBanner } = useContext(FormContext);
  const { cms } = useContext(PrivateContext);

  // query calls
  const { loading: productTagsLoading, data: productTagsData, error: productTagsError } = useQuery(GET_PRODUCT_TAGS);
  const { loading: productTypeLoading, data: productTypeData, error: productTypeError } = useQuery(GET_PRODUCT_TYPE);

  // load tags
  const productTagsOptions = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || [];
  const getProductTagsError = baseHelper.getResponseError(productTagsData, constant.gql.GET_PRODUCT_TAGS);

  // load types
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];
  const getProductTypeError = baseHelper.getResponseError(productTypeData, constant.gql.GET_PRODUCT_TYPE);

  useEffect(() => {
    if (productTypeError || getProductTypeError || productTagsError || getProductTagsError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(productTypeError || getProductTypeError || productTagsError || getProductTagsError),
      });
    }
  }, [productTypeError, errorHelper, getProductTypeError, productTagsError, getProductTagsError]);

  if (productTagsLoading || productTypeLoading) {
    return (
      <Card
        title={cms("section.form.section.organization.title")}
        id="productOrganisation"
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore("productOrganisation", cms("section.form.section.organization.title"));
            },
          },
        ]}
        sectioned
      >
        <SkeletonBodyText />
      </Card>
    );
  }

  return (
    <Card
      title={[
        cms("section.form.section.organization.title"),
        data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
      ]}
      id="productOrganisation"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore(cms("section.organization.title"), "TODO:");
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>You can select product organization details for the product.</TextContainer>
          <FormLayout.Group>
            <Select
              label={cms("section.form.section.organization.label.productType")}
              id="chooseProductType"
              placeholder={cms("section.form.section.organization.placeholder.pleaseSelect")}
              options={productTypeOptions || []}
              value={data.productValueType || ""}
              onChange={(value) => handleChange(constant.PRODUCT_VALUE_TYPE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Tags
              label={cms("section.form.section.organization.label.productTags")}
              id="productTag"
              placeholder={cms("section.form.section.organization.placeholder.selectTags")}
              values={productTagsOptions}
              selected={data.organisationTags || []}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Organization;
