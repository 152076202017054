import PropTypes from "prop-types";

const viewProp = {
  type: {
    active: PropTypes.bool,
    commissionVendor: PropTypes.string,
    handleClose: PropTypes.func,
    moneyFormat: PropTypes.string,
    orderLineItemId: PropTypes.string,
    setBanner: PropTypes.func,
    vendor: PropTypes.string,
  },
};
const rowProp = {
  type: {
    setBanner: PropTypes.func,
    item: PropTypes.object,
  },
};
const listProp = {
  type: {
    setBanner: PropTypes.func,
  },
};
const footerProp = {
  type: {
    count: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    changePageLimit: PropTypes.func,
    changePage: PropTypes.func,
    resourceData: PropTypes.object,
    t: PropTypes.func,
  },
};

export { viewProp, rowProp, footerProp, listProp };
