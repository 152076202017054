import React, { useContext, useEffect, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

import { PrivateContext } from "lib/context/privateContext";

// import queries
import {
  GET_ADVANCE_METAFIELD,
  GET_METAFIELD_SETTING,
  GET_PRODUCT_AND_SELLER_PRODUCT,
} from "app/product/apollo/queries";
import { GET_GEOLOCATION_SETTING } from "app/setup/apollo";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";

import OperatorEditTabs from "./subFeatures/form/tab";

const OperatorProductEdit = () => {
  const { cms, isLoading, match } = useContext(PrivateContext);
  const [description, setDescription] = useState("");
  const [locations, setLocation] = useState([]);
  const [isMetafield, setIsMetafield] = useState(false);
  const [isVariant, setIsVariant] = useState(false);
  const [isCampaign, setIsCampaign] = useState(false);
  const [title, setTitle] = useState("");
  const [catalogIds, setCatalogIds] = useState([]);

  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const { loading: productLoading, data: productData, refetch } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  const { loading: locationLoading, data: locationData } = useQuery(GET_GEOLOCATION_SETTING);

  const { loading: metafieldLoading, data: metaFieldData } = useQuery(GET_METAFIELD_SETTING);
  const { data: advanceData, loading: advanceLoading } = useQuery(GET_ADVANCE_METAFIELD);

  useEffect(() => {
    if (metaFieldData) {
      const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
      const advanceSetting = baseHelper.getResponseData(advanceData, "getAdvanceMetafieldSetting") || {};
      const { metafield = [] } = metafieldSetting;
      const { advanceMetafield } = advanceSetting || {};
      const { isEnabled, isAll, customSetting } = advanceMetafield || {};

      if (metafield && metafield.length) {
        if (isEnabled) {
          let isAllow = false;
          if (isAll) {
            isAllow = true;
          } else {
            const advanceFilterData = (customSetting || []).filter((item) => catalogIds.includes(item.catalogId));
            if (advanceFilterData && advanceFilterData.length) {
              isAllow = true;
            }
          }
          setIsMetafield(isAllow);
          return;
        }
        setIsMetafield(true);
        return;
      }
      setIsMetafield(false);
    }
  }, [metaFieldData, advanceData, catalogIds]);

  useEffect(() => {
    if (locationData) {
      const locationSetting = baseHelper.getResponseData(locationData, constant.gql.GET_GEOLOCATION_SETTING) || {};
      const { isAllowed = false, locations: locationArray = [] } = locationSetting;
      if (isAllowed && locationArray && locationArray.length) {
        setLocation(locationArray);
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (productData) {
      const productResponse =
        baseHelper.getResponseData(productData, constant.gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      const { variants = [], shopifyCatalogId = [], listingQuantity } = (productResponse && productResponse.sellerProduct) || {};
      setCatalogIds(shopifyCatalogId || []);
      setIsVariant(!!(variants && variants.length));
      setIsCampaign(!!listingQuantity);
    }
  }, [productData]);

  if (isLoading || productLoading || locationLoading || metafieldLoading || advanceLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection title={title} description={description}>
        <OperatorEditTabs
          setBanner={setBanner}
          cms={cms}
          setDescription={setDescription}
          setTitle={setTitle}
          refetch={refetch}
          setIsVariant={setIsVariant}
          isVariant={isVariant}
          isMetafield={isMetafield}
          locations={locations}
          isCampaign={isCampaign}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductEdit), { feature: constant.PRODUCT_UPDATE });
