import { gql } from "apollo-boost";

const SYNC_TEMP_VENDOR_WIX_PRODUCT = gql`
  mutation syncTempVendorWixProduct($input: BulkAction!) {
    syncTempVendorWixProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_TEMP_VENDOR_WIX_PRODUCT;
