import React from "react";
import PropTypes from "prop-types";
import { RadioButton } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { withFeature } from "lib/hoc";

const ConsignmentPickup = (props) => {
  const { cms, onHandleChange, value } = props;
  return (
    <RadioButton
      label={cms("label.consignmentPickup")}
      helpText={cms("consignmentPickup.helpText")}
      checked={value === constant.PICKUP}
      id="consignmentPickup"
      onChange={() => onHandleChange(constant.PICKUP)}
    />
  );
};

ConsignmentPickup.propTypes = {
  value: PropTypes.string.isRequired,
  cms: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

export default withFeature(ConsignmentPickup, { feature: constant.CONSIGNMENT_PICKUP });
