import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const AdvanceFilters = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, taggedWith, selectedFilter } = props;
  const { cms, history } = useContext(PrivateContext);

  const filterStatus = [
    { label: cms("label.new"), value: cms("label.new").toLowerCase() },
    { label: cms("label.review"), value: cms("label.review").toLowerCase() },
    { label: cms("label.approve"), value: cms("label.approve").toLowerCase() },
    { label: cms("label.reject"), value: cms("label.reject").toLowerCase() },
    { label: cms("label.delete"), value: cms("label.delete").toLowerCase() },
  ];

  const handleSelectChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { filter: value });
  };

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  return (
    <>
      <FormLayout>
        {(selectedFilter && selectedFilter !== constant.STATUS && (
          <TextField label={cms("common.label.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          (selectedFilter === constant.STATUS && (
            <Select
              label={baseHelper.ucFirst(cms("common.label.status"))}
              value={taggedWith}
              placeholder={cms("common.label.select")}
              onChange={handleSelectChange}
              options={filterStatus}
            />
          )) ||
          null}
      </FormLayout>
    </>
  );
};

const Filter = (props) => {
  const {
    dataToFetch,
    isShopifyList,
    setDataToFetch,
    setTaggedWith,
    selectedFilter,
    setSelectedFilter,
    /* productList,
    vendorProductListData, */
  } = props;
  const { cms, history } = useContext(PrivateContext);

  const filterOptions = isShopifyList
    ? [{ label: cms("label.titleName"), value: constant.TITLE }]
    : [{ label: cms("label.titleName"), value: constant.NAME }];

  // if (productList || vendorProductListData) {
  //   filterOptions.push({ label: "Live connected platform", value: constant.LIVECONNECTED_PLATFORM });
  // }

  const handleSelectChange = (value) => {
    setSelectedFilter(constant.TITLES);
    let listFilter = baseHelper.setQueryParams(history.location, { list_filter: value });
    if (value !== constant.STATUS) {
      setTaggedWith("");
      listFilter = baseHelper.setQueryParams(history.location, { list_filter: value, list_search: "" });
    }
    history.push(`${history.location.pathname}?${listFilter}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  return (
    <Select
      label={cms("common.label.showAll")}
      placeholder={!selectedFilter ? cms("common.placeholder.select") : ""}
      value={selectedFilter || ""}
      onChange={handleSelectChange}
      options={filterOptions}
    />
  );
};

AdvanceFilters.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  taggedWith: "",
  selectedFilter: "",
};

AdvanceFilters.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  selectedFilter: PropsType.string,
};

Filter.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  setSelectedFilter: () => {},
  selectedFilter: "",
};

Filter.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export { AdvanceFilters as default, Filter };
