import { gql } from "apollo-boost";

const GET_ASSOCIATED_SELLER = gql`
  query {
    getAssociatedSeller {
      data {
        brandName
        email {
          address
        }
        chargebee {
          apiKey
          site
          currencyCode
        }
        firstName
        isChargebee
        isStripeSubscription
        setting {
          isReviewOrder
        }
        stripeSubscription {
          apiKey
          isUpdate
          priceTableId
          publishedKey
        }
        lastName
        phoneNumber
        contactEmail
      }
      status
      error
    }
  }
`;

export default GET_ASSOCIATED_SELLER;
