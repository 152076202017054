import { gql } from "apollo-boost";

const GET_TEMP_PRODUCT_LIST = gql`
  query getShopifyLakeProductList($input: GetListInput) {
    getShopifyLakeProductList(input: $input) {
      data {
        count
        tempProductLakeList {
          _id
          data {
            title
            image {
              src
            }
          }
          updatedAt
          isShopifySync
          isSynced
          isUpdate
          isDelete
        }
      }
      error
      status
    }
  }
`;

export default GET_TEMP_PRODUCT_LIST;
