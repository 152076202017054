import React from "react";
import { Card, Stack, DisplayText, Pagination, Select } from "@shopify/polaris";
import propType from "prop-types";

const SessionFooter = (props) => {
  const { optionList, count, perPageCount, onSelectLimit, onPerPage, currentPage } = props;
  const totalCountLabel = "Total Count :";
  return (
    <>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <DisplayText size="small\n">
              <b>{totalCountLabel}</b>
              {` ${count}`}
            </DisplayText>
          </Stack.Item>
          <Stack.Item fill>
            <Pagination
              hasPrevious={(count && currentPage !== 1 && true) || false}
              onPrevious={() => {
                if (currentPage !== 1) {
                  onPerPage(currentPage - 1);
                }
              }}
              hasNext={(currentPage < count && true) || false}
              onNext={() => {
                if (currentPage <= count) {
                  onPerPage(currentPage + 1);
                }
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Select value={perPageCount} options={optionList} onChange={onSelectLimit} />
          </Stack.Item>
        </Stack>
      </Card.Section>
    </>
  );
};

SessionFooter.propTypes = propType.type;
SessionFooter.defaultProps = propType.default;
export default SessionFooter;
