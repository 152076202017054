import React, { useContext, useState } from "react";
import { PageActions, Stack } from "@shopify/polaris";
import { useMutation } from "react-apollo";

import { PrivateContext } from "lib/context";
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import TermModal from "app/product/modules/provider/features/genric/term/term";
import ConnectedShop from "app/product/modules/provider/features/genric/wixConnect/subFeatureItems/connected";
import SyncWixProduct from "app/product/modules/provider/features/add/subFeatures/syncWixProduct";

import { IS_WIX_STORE_CONNECTED } from "app/product/apollo/mutations";

import config from "configuration";

import { baseHelper, errorHelper } from "lib/helpers";
import DiscoveryTermModal from "../term/discoveryTerm";

const WixStore = (props) => {
  const { cms, currentUser: currentUserData, history } = useContext(PrivateContext);
  // const { brandName = "" } = currentUserData || {};
  const { wix } = (currentUserData && currentUserData.storeConfig) || {};
  const { accessKey = "" } = wix || {};
  const { setBanner } = props;
  const [isConnected, setIsConnected] = useState(!!accessKey);
  const [isDisconnectActive, setIsDisconnectActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isProductDiscovery = !!currentUserData?.isProductDiscovery;

  const [isWixStoreConnected, { loading }] = useMutation(IS_WIX_STORE_CONNECTED);

  const { IS_WIX_STORE_CONNECTED: STORE_CONNECTED } = constant.gql;

  const onSubmit = async () => {
    try {
      const response = await isWixStoreConnected();
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECTED);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        setIsOpen(false);
        return;
      }

      setIsDisconnectActive(true);
      setTimeout(() => {
        // Public app install flow temporary commented.
        // const clientId = config.vendorWixAPIKey;
        // const redirectUri = baseHelper.getCurrentDomain(window.location, "install/wix");
        // const url = `https://www.wix.com/installer/install?appId=${clientId}&state=${brandName}&redirectUrl=${redirectUri}`;

        // Private app install flow.
        const shareUrl = config.vendorWixShareUrl;
        window.location.href = shareUrl;
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
  };

  const handleTermModal = (newValue) => {
    if (newValue === constant.CONNECT) {
      setIsOpen(true);
    }
  };
  return (
    <>
      {isProductDiscovery ? <DiscoveryTermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.WIX_PASCAL_CASE}
      /> : <TermModal
        cms={cms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
        item={constant.WIX_PASCAL_CASE}
      />}
      <br />
      {!isConnected ? (
        <div className="manageCustomerButton">
          <PageActions
            primaryAction={{
              id: "connectShop",
              content: cms("section.connect.button.connect"),
              onAction: () => handleTermModal(constant.CONNECT),
              disabled: loading,
            }}
            secondaryActions={[
              {
                id: "cancelAction",
                content: cms("section.connect.button.cancel"),
                onAction: () => history.push("/setting"),
              },
            ]}
          />
        </div>
      ) : (
        <Stack>
          <Stack.Item fill>
            <ConnectedShop
              cms={cms}
              currentUserData={currentUserData}
              isActive={isDisconnectActive}
              setBanner={setBanner}
              setIsActive={setIsDisconnectActive}
              setIsConnected={setIsConnected}
            />
          </Stack.Item>
          <Stack.Item>
            <SyncWixProduct history={history} setBanner={setBanner} cms={cms} />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};
export default withFeature(withErrorBoundary(WixStore), {
  feature: constant.PRODUCT_IMPORT_WIX,
  // subFeature: constant.PRODUCT_IMPORT_WIX,
});
