import React, { useContext } from "react";
import { Card, TextField, Select, FormLayout } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

import PropTypes from "prop-types";

const Address = (props) => {
  const { cms } = useContext(PrivateContext);
  const { addressData, handleChange, destinations, setError: error } = props;
  const { address, city, pincode, countryCode } = addressData;

  return (
    <Card sectioned>
      <FormLayout>
        <TextField
          label={cms("label.address")}
          placeholder={cms("placeholder.address")}
          type="text"
          value={address}
          onChange={(value) => handleChange("address", value)}
          error={error && !address && error.address}
        />

        <TextField
          label={cms("label.city")}
          placeholder={cms("placeholder.city")}
          type="text"
          value={city}
          onChange={(value) => handleChange("city", value)}
          error={error && !city && error.city}
        />

        <Select
          label={cms("label.country")}
          options={destinations}
          onChange={(value) => handleChange("countryCode", value)}
          value={countryCode}
          error={error && !countryCode && error.countryCode}
        />

        <TextField
          label={cms("label.pincode")}
          placeholder={cms("placeholder.pincode")}
          type="text"
          value={pincode}
          onChange={(value) => handleChange("pincode", value)}
          error={error && !pincode && error.pincode}
        />
      </FormLayout>
    </Card>
  );
};

Address.propTypes = {
  addressData: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func,
  destinations: PropTypes.objectOf(PropTypes.string),
  setError: PropTypes.bool,
};
Address.defaultProps = {
  addressData: {},
  handleChange: () => {},
  destinations: {},
  setError: null,
};

export default Address;
