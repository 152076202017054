import styled from "styled-components";

const ReportCard = styled.div`
  text-align: center;
  margin-bottom: 1.3rem;
  .quick-reports {
    padding: 0 0 1rem;
    margin: 0 -0.5rem;
  }
  .image-item {
    padding: 0 0.5rem;
  }
  .Polaris-Card {
    margin-top: 0 !important;
    padding: 2rem;
    border-radius: 0.6rem;
    .Polaris-TextStyle--variationStrong {
      margin-bottom: 1rem;
      display: block;
    }
    @media (min-width: 768px) {
      padding: 3rem 2rem;
      span {
        font-size: 2.6rem;
        &.card-title {
          font-size: 1.4rem;
        }
      }
    }
  }
`;
export default ReportCard;
