import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { shopSubDomainSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const { SHOP_SUB_DOMAIN } = constant;
const validate = (field, value, cms) => {
  let promise = "";

  switch (field) {
    case SHOP_SUB_DOMAIN:
      promise = validateValue(shopSubDomainSchema(cms), { shopSubDomain: value });
      break;
    default:
      return "";
  }

  return handlePromiseError(promise);
};

export default validate;
