import { gql } from "apollo-boost";

const BULK_PRODUCT = gql`
  mutation bulkProduct($input: BulkAction!) {
    bulkProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default BULK_PRODUCT;
