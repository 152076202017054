import { gql } from "apollo-boost";

const UPDATE_ORDER_EXPORT_SETTING = gql`
  mutation updateOrderExportSetting($input: OrderExportSetting) {
    updateOrderExportSetting(input: $input) {
      status
      data
      error
    }
  }
`;
export default UPDATE_ORDER_EXPORT_SETTING;
