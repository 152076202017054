import { gql } from "apollo-boost";

// for operator
const UPDATE_SETTING = gql`
  mutation updateSetting($input: UpdateSetting) {
    updateProductSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_SETTING;
