import React, { useContext } from "react";
import { Card, Caption, Link, Stack, DataTable, TextStyle } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { fulfillmentsProp } from "app/orders/modules/admin/features/view/propsType";

const Fulfillments = (props) => {
  const { cms } = useContext(PrivateContext);

  const { lineData } = props;
  let row = [];
  let totalQuantity;
  let fulfilledQuantity;
  if (lineData) {
    row = lineData
      .filter((value) => {
        if (value.fulfillmentStatus === constant.FULFILLED) {
          return value;
        }
        return null;
      })
      .map((value) => {
        return [
          <Stack.Item fill>
            <div className="ellipsis">
              <Link url="">{value.name}</Link>
              <Caption>
                <br />
                <TextStyle>
                  {`${cms("label.vendor")}: `}
                  <Link>{value.vendor}</Link>
                </TextStyle>
              </Caption>
            </div>
          </Stack.Item>,

          <Stack.Item>{value.trackingCompany}</Stack.Item>,
          <Stack.Item>{value.trackingNumber}</Stack.Item>,
        ];
      });
    totalQuantity = lineData.length;
    fulfilledQuantity = row.length;
  }

  return (
    <Card>
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "text", "numeric"]}
          headings={[cms("label.product"), cms("label.shippingCompany"), cms("label.trackingNumber")]}
          rows={row}
        />
      </Card.Section>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{cms("label.totalFulfillments")}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="strong">{`${fulfilledQuantity} of ${totalQuantity}`}</TextStyle>
          </Stack.Item>
        </Stack>
      </Card.Section>
    </Card>
  );
};
Fulfillments.propTypes = fulfillmentsProp.type;
export default Fulfillments;
