import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";

import { Button, Card, Caption, Layout, Stack, TextStyle, TextField } from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper, errorHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

import { GET_INVOICE_SETTING, UPDATE_INVOICE_CUSTOM_NOTE } from "app/setup/apollo";

const CustomNote = (props) => {
  const { setMessage, setBanner } = props;
  const { cms = () => {}, history } = useContext(PrivateContext);
  const { CUSTOM_NOTE_COUNT } = constant;

  const [customNote, setCustomNote] = useState();
  const [count, setCount] = useState(0);
  const [firstPush, setFirstPush] = useState(true);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [updateAt, setUpdateAt] = useState("");

  const {
    error: errorGetInvoiceCustomNote,
    loading: loadingGetInvoiceCustomNote,
    data: dataGetInvoiceCustomNote,
    refetch,
  } = useQuery(GET_INVOICE_SETTING);

  const invoiceNumberResponseData = baseHelper.getResponseData(
    dataGetInvoiceCustomNote,
    constant.gql.GET_INVOICE_SETTING
  );
  const invoiceNumberErrorData = baseHelper.getResponseError(
    dataGetInvoiceCustomNote,
    constant.gql.GET_INVOICE_SETTING
  );

  const { payment } = invoiceNumberResponseData || {};

  useEffect(() => {
    if (payment) {
      const { invoice = {} } = payment;
      if (invoice) {
        const { note = {} } = invoice;
        const { value = "", updatedAt } = note || {};
        if (value) {
          setCustomNote(value);
          setUpdateAt(updatedAt);
          setCount(value.length);
        }
        if (updatedAt) {
          setFirstPush(false);
        }
      }
      refetch();
    }
    if (invoiceNumberErrorData) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(invoiceNumberErrorData), isOpen: true });
    }
    if (errorGetInvoiceCustomNote) {
      setBanner({ status: constant.CRITICAL, title: errorHelper.parse(errorGetInvoiceCustomNote), isOpen: true });
    }
  }, [payment]);

  const [updateInvoiceCustomNote, { loading: updateInvoiceCustomNoteLoading }] = useMutation(
    UPDATE_INVOICE_CUSTOM_NOTE
  );

  const onChangeAction = (newValue) => {
    if (newValue.length <= 120) {
      setCount(newValue.length);
      setCustomNote(newValue);
    }
    setSubmitEnable(true);
  };

  const onSubmit = async () => {
    try {
      const response = await updateInvoiceCustomNote({
        variables: {
          input: { invoiceCustomNote: customNote },
        },
      });

      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_INVOICE_CUSTOM_NOTE);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
        return;
      }
      if (response && firstPush) {
        setMessage(cms("section.customNote.message.success.save"));
      } else {
        setMessage(cms("section.customNote.message.success.update"));
      }
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
    }
    setSubmitEnable(false);
  };
  return (
    <Layout.AnnotatedSection
      title={cms("section.customNote.title")}
      description={cms("section.customNote.description")}
    >
      <Card
        title={[
          cms("section.customNote.card.title"),
          updateAt && (
            <TextStyle variation="subdued">
              <Caption>{baseHelper.lastUpdateDate(updateAt)}</Caption>
            </TextStyle>
          ),
        ]}
      >
        <Card.Section>
          <TextStyle variation="subdued">{cms("section.customNote.card.description")}</TextStyle>
          <br />
          <br />
          <TextField
            label={`${cms("section.customNote.label")}`}
            value={customNote}
            onChange={(value) => onChangeAction(value)}
            multiline={3}
          />
          <br />
          <TextStyle variation="subdued">{`${count} / ${CUSTOM_NOTE_COUNT}`}</TextStyle>
          <br />
          <br />
          <Stack distribution="equalSpacing">
            <Button secondry onClick={() => history.push("/setting")}>
              {cms("common.button.cancel")}
            </Button>
            <Button
              primary
              submit
              disabled={!submitEnabled}
              onClick={onSubmit}
              loading={updateInvoiceCustomNoteLoading}
            >
              {firstPush ? cms("common.button.submit") : cms("common.button.update")}
            </Button>
          </Stack>
        </Card.Section>
      </Card>
      <br />
    </Layout.AnnotatedSection>
  );
};

export default CustomNote;
