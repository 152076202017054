import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, phoneNumberRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup.string().required(cms("common.message.error.passwordIsRequired")),
  });

const phoneNumberSchema = (cms) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.phoneNumberIsRequired"))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .test("len", cms("common.message.error.phoneNumberLength"), (val) => val.length === 10)
      .nullable(),
  });

const otpSchema = (cms) =>
  yup.object().shape({
    otp: yup
      .string()
      .required(cms("common.message.error.otpIsRequired"))
      .matches(phoneNumberRegex, cms("common.message.error.positiveNumber"))
      .min(5, cms("common.message.error.minOtp"))
      .max(6, cms("common.message.error.maxOtp"))
      .nullable(),
  });

export { emailSchema, passwordSchema, phoneNumberSchema, otpSchema };
