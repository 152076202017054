import React from "react";
import { EmptyState } from "@shopify/polaris";
import { PrivateConsumer } from "lib/context";

const WithErrorBoundary = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error,
        errorInfo,
      });
      // You can also log error messages to an error reporting service here
    }

    render() {
      const { errorInfo, error } = this.state;
      if (errorInfo || error) {
        return (
          <PrivateConsumer>
            {({ history }) => (
              <EmptyState
                heading="Oops, Something went wrong"
                action={{
                  content: "Go Back",
                  onAction: () => history.push("/"),
                }}
                secondaryAction={{
                  content: "Report Issue",
                  onAction: () => history.push("/contact-us"),
                }}
              >
                <p>This page is currently not available. We are working on this issue</p>
              </EmptyState>
            )}
          </PrivateConsumer>
        );
      }
      // Normally, just render children
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default WithErrorBoundary;
