import React, { useContext } from "react";
import {
  Caption,
  Card,
  Collapsible,
  Layout,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "app/setup/modules/operator/features/branding/context/context";

const LoginHeader = () => {
  const { cms = () => {}, currentUser } = useContext(PrivateContext);
  const { updatedAt = "" } = currentUser || {};
  const { LOGIN, LOGIN_TEXT, IS_DEFAULT, DEFAULT_LOGIN_HEADER } = constant;
  const {
    // learnMore
    page,
  } = useContext(BrandingContext);
  const { login, handleChange, resetDefault } = page;

  const loginList = [
    {
      label: cms("section.loginPageHeader.label.default"),
      value: true,
      id: "loginDefault",
    },
    {
      label: cms("section.loginPageHeader.label.customized"),
      value: false,
      id: "loginCustom",
    },
  ];

  const renderList = () => {
    return loginList.map((list) => (
      <RadioButton
        label={list.label}
        checked={login.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="login"
        onChange={() => handleChange(IS_DEFAULT, list.value, LOGIN)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.loginPageHeader.label.title")}
        description={cms("section.loginPageHeader.description")}
      >
        <Card
          title={[
            cms("section.loginPageHeader.label.title"),
            updatedAt && (
              <TextStyle variation="subdued">
                <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
              </TextStyle>
            ),
          ]}
          // actions={[
          //   { content: "Learn more", onAction: () => learnMore(cms("section.loginPageHeader.title"), "TODO: ") },
          // ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.loginPageHeader.subDescription")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!login.isDefault} id="custom-login-collapsible">
              <TextField
                id="loginCustomText"
                label={cms("section.loginPageHeader.label.loginHeaderText")}
                value={login.loginText}
                onChange={(value) => handleChange(LOGIN_TEXT, value, LOGIN)}
                labelAction={
                  login.loginText !== DEFAULT_LOGIN_HEADER && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(LOGIN_TEXT, DEFAULT_LOGIN_HEADER, LOGIN),
                  }
                }
              />
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default withFeature(withErrorBoundary(LoginHeader), {
  feature: constant.STANDARD_BRANDING,
});
