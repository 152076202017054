import React, { useState, useContext } from "react";
import { Card, FormLayout, PageActions, TextContainer, Banner } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import fileDownload from "js-file-download";
import Baby from "babyparse";

// import hoc
import { withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context/privateContext";

import { baseHelper, errorHelper } from "lib/helpers";

// import component
import { Sheet } from "lib/components";

import FileUpload from "lib/components/fileInput/fileUpload";
import csvData from "app/productOld/modules/admin/features/add/addProductConfig";

// import query
import { BULK_UPLOAD } from "app/productOld/apollo/mutations";

const BulkCreate = (props) => {
  const { setBanner } = props;
  const { history, cms } = useContext(PrivateContext) || {};
  const { gql, CRITICAL, SUCCESS, INFO } = constant;
  const [values, setValues] = useState({});
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [bulkProductUploadRequest, { loading: bulkUploadLoading }] = useMutation(BULK_UPLOAD);

  const getCSVData = (csvValue) => {
    const regexForEnterKey = /\n,|\n/gi;
    const csvDataAfterRegex = csvValue.data.replace(regexForEnterKey, "\n");
    return csvDataAfterRegex;
  };

  const onSubmit = async () => {
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.csvIsRequired"),
        status: CRITICAL,
      });
      return;
    }
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: modifiedFormat,
        fileName: values.fileName,
      },
    };
    try {
      const res = await bulkProductUploadRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_PRODUCT_CSV);
      let banner = {
        isOpen: true,
        status: INFO,
        title: cms("section.bulk.message.success.title"),
        children: cms("section.bulk.message.success.content"),
        action: {
          content: cms("section.bulk.message.success.action"),
          onAction: () => history.push("/activity/bulk"),
        },
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_PRODUCT_CSV);
        banner = {
          action: null,
          isOpen: true,
          status: CRITICAL,
          title: error,
        };
      }

      setBanner(banner);
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  const downloadCSV = () => {
    const csv = Baby.unparse(csvData);
    fileDownload(csv, constant.BULK_PRODUCT_CSV);
  };

  return (
    <>
      <Card
        title={cms("section.bulk.title")}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => learnMore(cms("section.bulk.title"), "TODO:"),
          },
        ]}
      >
        <Card.Section>
          <TextContainer>
            {cms("section.bulk.description")}
            <br />
            <Banner status={INFO}>
              <p>{cms("section.bulk.label.sampleCsv")}</p>
            </Banner>
          </TextContainer>
          <br />
          <FormLayout>
            <FileUpload setValue={setValues} getCSV={downloadCSV} values={values} />
          </FormLayout>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms("section.bulk.button.primary"),
          id: "submitButton",
          onAction: () => onSubmit(),
          loading: bulkUploadLoading,
          disabled: bulkUploadLoading || !Object.values(values).length,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            id: "cancelButton",
            onAction: () => history.push("/"),
          },
        ]}
      />
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={{
          content: cms("common.label.done"),
          onAction: () => setSheetActive(false),
        }}
        secondaryAction={{
          content: cms("common.button.cancel"),
          onAction: () => setSheetActive(false),
        }}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

BulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default withFeature(BulkCreate, { feature: constant.PRODUCT_BULK_CREATE });
