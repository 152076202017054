import React, { useContext, useState, useCallback, useEffect } from "react";
// import packages
import { useQuery } from "react-apollo";
import { QuickReport } from "app/reports";
import {
  ResourceItem,
  TextStyle,
  Badge,
  Stack,
  Caption,
  Link,
  Heading,
  Card,
  Tabs,
  TextField,
  Select,
  Button,
  FormLayout,
} from "@shopify/polaris";

// import helper components
import { ResourceList, SkeletonList } from "lib/components";
import { baseHelper, storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

// import gql
import { GET_ONBOARD_ACTIVITY } from "app/userManagement/apollo/queries";
import { ONBOARDING_ACTIVITY } from "app/userManagement/apollo/subscriptions";

// import subFeature
import { TwoColumnLayout } from "layout/private/components";
import tabs from "./tabs";

const AdminOnboardingList = () => {
  const { history, isLoading, cms } = useContext(PrivateContext);

  const getQueryParams = baseHelper.getQueryParams(history.location.search);

  const [values, setValues] = useState("");
  const [selectedTab, setSelectedTab] = useState(parseInt(getQueryParams && getQueryParams.tab, 10) || 0);
  const [selectedFilter, setSelectedFilter] = useState((getQueryParams && getQueryParams.filter) || "");
  const [queryValue, setQueryValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 20);
  const [onboardingList, setOnboardingList] = useState([]);
  const [onboardingCount, setOnboardingCount] = useState(0);

  const { data, loading, subscribeToMore } = useQuery(GET_ONBOARD_ACTIVITY, {
    variables: {
      input: {
        search: (getQueryParams && getQueryParams.search) || "",
        sort_name: (getQueryParams && getQueryParams.sort_name) || "",
        sort_order: (getQueryParams && getQueryParams.sort_order) || "",
        list_filter: (getQueryParams && getQueryParams.list_filter) || "",
        list_search: (getQueryParams && getQueryParams.list_search) || "",
        page: (getQueryParams && parseInt(getQueryParams.page || page, 10)) || 1,
        perPage: (getQueryParams && parseInt(getQueryParams.perPage || perPage, 10)) || 20,
        filter: (getQueryParams && getQueryParams.filter) || "",
      },
    },
  });

  const getOnboardingList = baseHelper.getResponseData(data, constant.gql.GET_ONBOARD_ACTIVITY);
  useEffect(() => {
    if (getOnboardingList) {
      setOnboardingList(getOnboardingList && getOnboardingList.OnBoardActivity);
      setOnboardingCount(getOnboardingList && getOnboardingList.count);
    }
  }, [getOnboardingList]);

  const onAddFilter = useCallback(() => {
    const getFilter = baseHelper.setQueryParams(history.location, { list_filter: selectedFilter, list_search: values });
    history.push(`${history.location.pathname}?${getFilter}`);
  }, [history, selectedFilter, values]);

  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (selected) => {
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(sortedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const badgeStatus = {
    supplierSignUp: cms("label.badge.supplierSignUp"),
    firstProduct: cms("label.badge.firstProduct"),
    appUninstall: cms("label.badge.appUninstall"),
    appInstall: cms("label.badge.appInstall"),
    firstInvite: cms("label.badge.firstInvite"),
    firstLogin: cms("label.badge.firstLogin"),
    firstOrder: cms("label.badge.firstOrder"),
    firstVendor: cms("label.badge.firstVendor"),
    sellerSignUp: cms("label.badge.sellerSignUp"),
  };

  const formattedPlatform = {
    shopify: cms("label.shopify"),
    mcDirect: cms("label.mcDirect"),
  };

  const formattedMessage = {
    appUninstall: cms("message.error.appUninstall"),
    appInstallSuccessful: cms("message.success.appInstallSuccessful"),
    chargeAccepted: cms("message.success.chargeAccepted"),
    chargeDeclined: cms("message.error.chargeDeclined"),
    firstInviteSuccessful: cms("message.success.firstInviteSuccessful"),
    firstOrder: cms("message.success.firstOrder"),
    firstProduct: cms("message.success.firstProduct"),
    firstVendor: cms("message.success.firstVendor"),
    firstLoginSuccessful: cms("message.success.firstLoginSuccessful"),
    firstLoginFailed: cms("message.error.firstLoginFailed"),
    sellerSignUpSuccessful: cms("message.success.sellerSignUpSuccessful"),
    sellerSignUpFailed: cms("message.error.sellerSignUpFailed"),
    supplierSignUpSuccessful: cms("message.success.supplierSignUpSuccessful"),
    supplierSignUpFailed: cms("message.error.supplierSignUpFailed"),
  };

  const getBadgeStatus = (status) => {
    return badgeStatus[status];
  };

  const getTabs = {
    0: "all",
    1: "firstLogin",
    2: "appInstall",
    3: "appUninstall",
    4: "supplierSignUp",
    5: "sellerSignUp",
  };

  const handleTabChange = (select) => {
    setSelectedTab(select);
    setFilters(getTabs[select], select);
  };

  const handleRemoveQuery = () => {
    const searchValue = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${searchValue}`);
  };

  useEffect(() => {
    subscribeToMore({
      document: ONBOARDING_ACTIVITY,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const prevOnboarding = prev.getVendorList.data;
        const OnboardingData = [...prevOnboarding.OnBoardActivity];
        const totalCount = OnboardingData.length;
        return [setOnboardingList(OnboardingData), setOnboardingCount(totalCount)];
      },
    });
  }, [subscribeToMore]);

  const options = [
    { label: cms("label.filterOption.activity"), value: "activity" },
    { label: cms("label.filterOption.email"), value: "email" },
    { label: cms("label.filterOption.store"), value: "store" },
    { label: cms("label.filterOption.brandName"), value: "brandName" },
  ];

  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <Card>
        <Tabs tabs={tabs(cms)} selected={selectedTab} onSelect={handleTabChange} />
        <ResourceList
          resourceName={cms("label.resourceName")}
          items={onboardingList}
          filters={[
            {
              key: "select",
              label: cms("label.selectFilter"),
              filter: (
                <>
                  <Select
                    options={options}
                    placeholder={cms("label.selectFilter")}
                    onChange={setSelectedFilter}
                    value={selectedFilter}
                  />
                  <br />
                  {selectedFilter && (
                    <FormLayout>
                      <TextField label={cms("label.like")} value={values} onChange={setValues} />
                      <Button onClick={onAddFilter}>{cms("label.addFilter")}</Button>
                    </FormLayout>
                  )}
                </>
              ),
            },
          ]}
          loading={loading}
          sortValue={sortValue}
          sortOptions={[
            { label: cms("common.label.sortOption.dateAsc"), value: "createdAt_asc" },
            { label: cms("common.label.sortOption.dateDesc"), value: "createdAt_desc" },
          ]}
          onSortChange={handleSort}
          onQueryChange={handleSearchValue}
          queryValue={queryValue}
          count={onboardingCount}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          onQueryClear={handleRemoveQuery}
          renderItem={(item) => {
            const { _id: id, createdAt, store, activity, message, email, country, platform, brandName } = item;
            const Store = store || brandName || "";
            return (
              <ResourceItem id={id}>
                <Stack>
                  <Stack.Item fill>
                    <Heading element="h2">
                      {(store && (
                        <Link url={`https://${Store}.myshopify.com`}>
                          <TextStyle variation="strong">{Store}</TextStyle>
                        </Link>
                      )) || <TextStyle variation="strong">{`${Store} - ${formattedMessage[message]}`}</TextStyle>}
                    </Heading>
                    <Caption>
                      {`${cms("label.email")}: `}
                      <b>{email}</b>
                    </Caption>
                    <Caption>
                      {`${cms("label.country")}: `}
                      {country}
                    </Caption>
                    <Caption>
                      {`${cms("label.platform")}: `}
                      {formattedPlatform[platform]}
                    </Caption>
                    <Caption>{` ${cms("label.date")}: ${baseHelper.formatDate(createdAt)}`}</Caption>
                  </Stack.Item>
                  <Stack.Item>
                    <Badge status={baseHelper.getBadgeType(activity)}>{getBadgeStatus(activity)}</Badge>
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </Card>
    );
  };
  const currentUserRole = storageHelper.get("userRole");
  return <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={<QuickReport />} />;
};

export default withErrorBoundary(AdminOnboardingList);
