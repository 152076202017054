const editData = (cms, isVariant) => {
  const filterServiceTab = [
    {
      id: "information",
      content: cms("label.information"),
      panelID: "information-content",
    },
  ];

  if (!isVariant) {
    filterServiceTab.push({
      id: "availability",
      content: cms("service.label.availability"),
      panelID: "availability-content",
    });
  }

  filterServiceTab.push({
    id: "image",
    content: cms("label.image"),
    panelID: "image-content",
  });

  filterServiceTab.push({
    id: "variant",
    content: cms("label.variant"),
    panelID: "variant-content",
  });

  return {
    option: {
      option1: "option1",
      option2: "option2",
      option3: "option3",
    },
    policyOption: [
      {
        label: cms("label.notTrack"),
        value: "Don't track inventory",
      },
      {
        label: cms("label.shopify"),
        value: "shopify",
      },
    ],
    serviceTabs: filterServiceTab,
  };
};

export default editData;
