import { gql } from "apollo-boost";

const GET_ASSOCIATED_SELLER = gql`
  query {
    getAssociatedSeller {
      data {
        email {
          address
        }
        chargebee {
          apiKey
          site
          currencyCode
        }
        firstName
        isChargebee
        lastName
        phoneNumber
      }
      status
      error
    }
  }
`;

export default GET_ASSOCIATED_SELLER;
