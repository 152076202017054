import React, { useContext } from "react";
import { Card, FormLayout, Layout, TextField, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

const BusinessTaxInfo = (props) => {
  const { cms } = useContext(PrivateContext);

  const { businessTaxInformation, handleChange } = props;

  const getFields = () => {
    return (
      <>
        <TextField
          key="company"
          label={cms("section.businessTax.label.company")}
          placeholder={cms("section.businessTax.placeholder.company")}
          value={businessTaxInformation.company}
          maxLength={20}
          onChange={(value) => handleChange(constant.COMPANY, value)}
        />
        <br />
        <TextField
          key="customs"
          label={cms("section.businessTax.label.customs")}
          placeholder={cms("section.businessTax.placeholder.customs")}
          value={businessTaxInformation.customs}
          maxLength={20}
          onChange={(value) => handleChange(constant.CUSTOMS, value)}
        />
        <br />
        <TextField
          key="vat"
          label={cms("section.businessTax.label.vat")}
          placeholder={cms("section.businessTax.placeholder.vat")}
          value={businessTaxInformation.vat}
          maxLength={20}
          onChange={(value) => handleChange(constant.VAT, value)}
        />
        <br />
        <TextField
          key="gst"
          label={cms("section.businessTax.label.gst")}
          placeholder={cms("section.businessTax.placeholder.gst")}
          value={businessTaxInformation.gst}
          maxLength={20}
          onChange={(value) => handleChange(constant.GST, value)}
        />
        <br />
        <TextField
          key="pst"
          label={cms("section.businessTax.label.pst")}
          placeholder={cms("section.businessTax.placeholder.pst")}
          value={businessTaxInformation.pst}
          maxLength={20}
          onChange={(value) => handleChange(constant.PST, value)}
        />
      </>
    );
  };

  return (
    <Layout.AnnotatedSection
      title={cms("section.businessTax.title")}
      description={cms("section.businessTax.description")}
    >
      <Card title={cms("section.businessTax.title")}>
        <Card.Section>
          <FormLayout>
            <TextContainer>{cms("section.businessTax.description")}</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

BusinessTaxInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  businessTaxInformation: PropTypes.shape({
    customs: PropTypes.string,
    company: PropTypes.string,
    vat: PropTypes.string,
  }),
  // lastUpdated: PropTypes.string,
};

BusinessTaxInfo.defaultProps = {
  // lastUpdated: "",
  businessTaxInformation: {},
};

export default BusinessTaxInfo;
