import React, { useContext } from "react";
import { Card, TextField } from "@shopify/polaris";

// import context
import { FormContext } from "app/productOld/modules/operator/features/variant/context/context";

const TitleVariant = (props) => {
  const { values, handleChangeVariant /* resData */ } = props;
  const { errorMessage, handleValidation } = useContext(FormContext);
  // const { option1, option2, option3 } = resData;
  // return option3 || option2 || option1 ? (
  return (
    <Card sectioned>
      <Card.Section>
        {/* {option1 ? ( */}
        <>
          <TextField
            id="size"
            label="Size"
            value={values.size}
            onChange={(value) => handleChangeVariant("size", value)}
            placeholder="Size"
            onBlur={(value) => handleValidation("size", value)}
            error={errorMessage && errorMessage.size}
          />
          <br />
        </>
        {/* ) : null}
        {option2 ? ( */}
        <>
          <TextField
            id="color"
            label="Color"
            value={values.color}
            onChange={(value) => handleChangeVariant("color", value)}
            placeholder="Color"
            onBlur={(value) => handleValidation("color", value)}
            error={errorMessage && errorMessage.color}
          />
          <br />
        </>
        {/* ) : null}
        {option3 ? ( */}
        <>
          <TextField
            id="material"
            label="Material"
            value={values.material}
            onChange={(value) => handleChangeVariant("material", value)}
            placeholder="Material"
            onBlur={(value) => handleValidation("material", value)}
            error={errorMessage && errorMessage.material}
          />
          <br />
        </>
        {/* ) : null} */}
      </Card.Section>
    </Card>
  );
  // ) : null;
};

export default TitleVariant;
