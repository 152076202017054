import { gql } from "apollo-boost";

const CUSTOM_DOMAIN_SUBSCRIPTION = gql`
  subscription {
    customDomainView {
      data {
        operationType
        customDomain {
          accessKey
          accessToken
          _id
          accountId
          activatedOn
          addedBy
          address
          billingDate
          brandName
          chargeId
          city
          email {
            address
            verified
          }
          domain
          createdAt
          isDomainActive
          shop
        }
      }
    }
  }
`;

export default CUSTOM_DOMAIN_SUBSCRIPTION;
