import React, { useContext } from "react";
import { Thumbnail, ResourceItem, Stack, Heading, Link, Caption, TextStyle, Badge, Button } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";
import { baseHelper, imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const ProductListView = (props) => {
  const { keyIndex, item, bulkAction, loading } = props;
  const { currentUser, history, cms } = useContext(PrivateContext);
  const { _id: id, productId, title, vendor, vendorId, createdAt, variants = [], published, status, images } = item;
  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);
  const productID = isSeller ? productId : id;
  const thumbnailImage =
    images.length > 0
      ? imageHelper.resize({ url: images[0].imageUrl, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
  const renderThumbnail = <Thumbnail source={thumbnailImage} alt={title} />;

  const getShortcutActionsForSeller = (statusProduct, ids) => {
    const productStatus = statusProduct.toLowerCase();
    if (productStatus === constant.NEW.toLowerCase() || productStatus === constant.REVIEWED.toLowerCase()) {
      return (
        <Button
          size="slim"
          loading={loading}
          onClick={() => {
            bulkAction(constant.APPROVE, false, ids, "", null, productStatus);
          }}
        >
          {cms("button.productList.pushToStore.primaryAction")}
        </Button>
      );
    }
    return null;
  };

  let badgeType = constant.DEFAULT;
  const statusColor = status.toLowerCase();
  switch (statusColor) {
    case constant.APPROVED.toLowerCase():
      badgeType = constant.SUCCESS;
      break;
    case constant.REVIEWED.toLowerCase():
    case constant.NEEDS_REVIEW.toLowerCase():
      badgeType = constant.INFO;
      break;
    case constant.REJECTED.toLowerCase():
      badgeType = constant.WARNING;
      break;
    case constant.DELETED.toLowerCase():
      badgeType = constant.DEFAULT;
      break;
    default:
      badgeType = constant.ATTENTION;
      break;
  }

  return (
    <ResourceItem
      media={renderThumbnail}
      id={productID}
      key={keyIndex}
      accessibilityLabel={`${cms("label.productList.viewDetailsFor")} ${title}`}
      persistActions
      recordID={id}
    >
      <Stack alignment="leading">
        <Stack.Item fill>
          <div className="text-capitalize">
            <Heading>
              <Link onClick={() => history.push(`/products/edit/${productID}`)}>
                <div className="ellipsis">{title}</div>
              </Link>
            </Heading>
            <Caption>
              {`${cms("label.productList.addedBy")} `}
              {(isVendor && <TextStyle variation="strong">{vendor}</TextStyle>) || (
                <Link onClick={() => history.push(`/providers/view/${vendorId}`)}>
                  <TextStyle variation="strong">{vendor}</TextStyle>
                </Link>
              )}
              {` ${cms("label.productList.on")} ${baseHelper.formatDate(createdAt)}`}
            </Caption>
            <Caption>
              <TextStyle variation="subdued">
                {`${cms("label.productList.numberOfVariants")}${variants.length}`}
              </TextStyle>
            </Caption>
            {published && (
              <Caption>
                <TextStyle variation="subdued">
                  {cms("label.productList.availabilityStatus")}
                  {published ? constant.AVAILABLE : constant.UN_AVAILABLE}
                </TextStyle>
              </Caption>
            )}
          </div>
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <Badge status={badgeType}>
                <span className="text-capitalize">{status}</span>
              </Badge>
            </Stack.Item>
            <Stack.Item>{getShortcutActionsForSeller(status, productID)}</Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
};
ProductListView.defaultProps = {
  keyIndex: "",
  item: {
    _id: "",
    title: "",
    vendor: "",
    createdAt: "",
    variantCount: "",
    published: "",
    productId: "",
    status: "",
    images: { imageUrl: null },
  },
  loading: false,
  bulkAction: () => {},
};

ProductListView.propTypes = {
  keyIndex: PropTypes.number,
  item: PropTypes.shape(PropTypes.object),
  bulkAction: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProductListView;
