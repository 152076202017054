import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";

import { AdminSellerProfile } from "./admin";

const { userKey } = constant;
const { admin } = userKey;

const SellerProfileView = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminSellerProfile />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const SellerProfileViewComponent = getComponent(currentUserRole);
  return SellerProfileViewComponent;
};

export default SellerProfileView;
