import React, { useContext } from "react";
import { ResourceItem, Stack, Heading, Link, Caption, TextStyle, Badge } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const ActivityListView = (props) => {
  const { keyIndex, item, cms } = props;
  const { currentUser } = useContext(PrivateContext);
  const {
    DELETED,
    ERROR_LOGS,
    INVITE,
    INVITE_CSV,
    IN_PROGRESS,
    NEEDS_REVIEW,
    NEW,
    ORDER,
    PRODUCT,
    PRODUCT_CSV,
    PRODUCT_IMPORT,
    PROGRESS,
    SELLER_PRODUCTS,
    VENDOR_CSV,
  } = constant;
  const DELETE = DELETED.toLowerCase();
  const isSeller = baseHelper.isSeller(currentUser);
  const { ecommercePlatform } = currentUser;
  const { _id, label, status, createdAt, by, title, type, activityId, collectionName } = item;
  let iconClass;
  const isOrder = type === ORDER;
  const isProduct = [PRODUCT, PRODUCT_CSV, PRODUCT_IMPORT].includes(type);
  const isInvite = [INVITE, INVITE_CSV].includes(type);
  const isVendor = type === VENDOR_CSV;

  const productUrl = ecommercePlatform ? "/product-lake" : "/products";
  const orderUrl = ecommercePlatform ? "/order-lake" : "/orders";

  if (isOrder) iconClass = "fal fa-shopping-cart fa-lg";
  if (isProduct) iconClass = "fal fa-tag fa-lg";
  if (isInvite) iconClass = "fal fa-envelope-open fa-lg";
  if (isVendor) iconClass = "fal fa-user fa-lg";

  const activityLogId = activityId;

  const badge =
    status &&
    status.map((element) => {
      let badgeItem = element.toLowerCase();
      const filedStatus = baseHelper.getBadgeStatus(badgeItem);
      const badgeStatus = filedStatus.toLowerCase();
      if (badgeItem === IN_PROGRESS) {
        badgeItem = PROGRESS;
      }
      const badgeType = baseHelper.getBadgeType(badgeItem);
      return {
        badgeType,
        badgeStatus,
      };
    });
  const { badgeType: firstBadgeType, badgeStatus: firstBadgeStatus } = (badge.length && badge[0]) || {};
  const { badgeType: secondBadgeType, badgeStatus: secondBadgeStatus } = (badge.length && badge[1]) || {};
  const showLink = !(status && status[0] && status[0].toLowerCase() === DELETE);
  const productList =
    showLink && status && status[0] && (status[0].toLowerCase() === NEEDS_REVIEW || status[0].toLowerCase() === NEW);

  let linkCard = isOrder ? `${orderUrl}/view/${activityLogId}` : `${productUrl}/edit/${activityLogId}`;
  if (collectionName === ERROR_LOGS) {
    linkCard = `activity/view/${activityId}`;
  }

  if (isSeller && collectionName === SELLER_PRODUCTS && productList) {
    linkCard = productUrl;
  }

  if (isSeller && isInvite) {
    linkCard = "/invitation";
  }

  const linkText = <div className="ellipsis">{`${label}: ${title}`}</div>;

  return (
    <ResourceItem media="" id={_id} key={keyIndex}>
      <Stack alignment="center" distribution="leading">
        <Stack.Item>
          <i className={iconClass} />
        </Stack.Item>
        <Stack.Item fill>
          <Heading>{!showLink ? <b>{linkText}</b> : <Link url={linkCard}>{linkText}</Link>}</Heading>
          <Caption>
            {cms("common.label.by")}
            <TextStyle variation="strong">{` ${by} `}</TextStyle>
            {cms("common.label.on")}
            {` ${baseHelper.formatDate(createdAt)}`}
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <Badge status={firstBadgeType}>{baseHelper.ucFirst(firstBadgeStatus)}</Badge>
          {badge.length > 1 && <Badge status={secondBadgeType}>{baseHelper.ucFirst(secondBadgeStatus)}</Badge>}
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
};
ActivityListView.defaultProps = {
  keyIndex: "",
  item: {
    _id: "",
    label: "",
    status: "",
    createdAt: "",
    by: "",
    title: "",
    type: "",
    activityId: "",
    collectionName: "",
  },
  cms: () => {},
};

ActivityListView.propTypes = {
  keyIndex: PropTypes.number,
  item: PropTypes.shape(PropTypes.object),
  cms: PropTypes.func,
};

export default ActivityListView;
