import React, { useState, useContext } from "react";
import { Modal, Stack, Select, Checkbox, TextField, DisplayText } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

// import lib component
import Banner from "lib/components/banner/banner";

// import context
import { PrivateContext } from "lib/context";
import { errorHelper } from "lib/helpers";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import gql
import { EDIT_RULE } from "app/shipping/apollo/mutations";

// import prop
import { editProp } from "app/shipping/modules/provider/features/subFeatures/advance/rules/props";

const EditRule = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { currentUser = {}, cms } = useContext(PrivateContext);
  const { editModal, setEditModal, item, refetch, destinations = [], products = [], bands = [], moneyFormat } =
    props || {};

  const [shippingRule, setShippingRule] = useState(item);
  const { value, gql } = constant;
  const [editBanner, setEditBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });
  const [editRule, { loading }] = useMutation(EDIT_RULE);
  const {
    _id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    isShippingDisabled,
  } = shippingRule || {};

  const option = [
    { label: cms("label.fixed"), value: constant.FIXED },
    { label: cms("modal.rule.edit.label.free"), value: constant.FREE },
  ];

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((key) => key.value === destinationCode)
    ) || {};
  const destination = countries.find((key) => key.value === destinationCode);
  const product = products.find((key) => key.value === productId);
  const shippingBand = bands.find((band) => band.value === shippingBandId || band.parentId === shippingBandId) || false;
  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;

  const handleShippingRule = (key, val) => {
    setShippingRule({ ...shippingRule, [key]: val });
  };
  const toggleModal = () => {
    setEditModal(!editModal);
  };
  const handleDismiss = () => {
    setEditBanner({ isOpen: false });
  };
  const checkValidation = () => {
    if (!description) {
      return cms("message.error.addDescriptionForRule");
    }
    if (description.length > 255) {
      return cms("message.error.descriptionCharacter");
    }
    if (!priceType) {
      return cms("message.error.selectShippingRuleType");
    }
    if (priceType !== constant.FREE && !price) {
      return cms("message.error.provideCost");
    }
    if (priceType !== constant.FREE && price < 0) {
      return cms("message.error.provideValidPrice");
    }
    return false;
  };

  const handleUpdate = () => {
    const hasError = checkValidation();
    if (hasError) {
      return setEditBanner({
        isOpen: true,
        title: hasError,
        status: constant.CRITICAL,
      });
    }

    const data = {
      _id,
      parentId: item && item.parentId,
      condition,
      description,
      destinationCode,
      isShippingDisabled,
      price: parseFloat(priceType && priceType === constant.FIXED && price) || 0,
      priceType,
      productId,
      shippingBandId,
    };

    const { userId: ruleCreatedBy = null } = item || {};
    const { _id: currentUserId = null } = currentUser || {};
    if (baseHelper.mongoIdAsString(ruleCreatedBy) !== baseHelper.mongoIdAsString(currentUserId)) {
      data.parentId = _id;
    }

    return editRule({ variables: { input: data } })
      .then((response) => {
        const responseError = baseHelper.getResponseError(response.data, gql.UPDATE_SHIPPING_RULE);
        if (responseError) {
          setEditBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }

        const responseData = baseHelper.getResponseData(response.data, gql.UPDATE_SHIPPING_RULE);
        if (responseData) {
          refetch();
          setBannerOuter({
            isOpen: true,
            title: cms("modal.rule.edit.message.success.updateSuccessfully"),
            status: "success",
          });
          setEditModal(false);
        }
      })
      .catch((exception) => {
        setEditBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  if (!isVendorAllowed) {
    return null;
  }

  const acceptOnlyValidInput = (val, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
  };

  return (
    <>
      <Modal
        open={editModal}
        onClose={toggleModal}
        title={cms("modal.rule.edit.title")}
        primaryAction={{
          loading,
          content: cms("modal.rule.edit.button.primary"),
          onAction: handleUpdate,
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              {editBanner.isOpen && (
                <Banner
                  isOpen={editBanner.isOpen}
                  title={editBanner.title}
                  message={editBanner.message}
                  status={editBanner.status}
                  onDismiss={handleDismiss}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {`${cms("modal.rule.edit.label.shippingBand")}: ${shippingBand && shippingBand.label}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <Stack>
                {isDestination && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${cms("modal.rule.add.label.destination")}: ${
                        (destination && destination.label) || cms("label.notAvailable")
                      } `}
                    </DisplayText>
                  </Stack.Item>
                )}
                {isProduct && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${cms("modal.rule.edit.label.product")}: ${
                        (product && product.label) || cms("label.notAvailable")
                      }`}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label={cms("modal.rule.add.label.description")}
                value={description}
                onChange={(val) => handleShippingRule(constant.DESCRIPTION, val)}
                placeholder={cms("modal.rule.edit.placeholder.description")}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    label={cms("modal.rule.edit.label.selectShippingType")}
                    options={option}
                    onChange={(val) => handleShippingRule(constant.PRICE_TYPE, val)}
                    value={priceType}
                    placeholder={cms("modal.rule.edit.placeholder.selectShipping")}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      prefix={moneyFormat}
                      label={cms("modal.rule.add.label.amount")}
                      onChange={(val) => handleShippingRule(constant.PRICE, acceptOnlyValidInput(val, price))}
                      value={price.toString() || ""}
                      min={value.MIN_PRICE}
                      step={value.STEP}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label={cms("modal.rule.edit.label.availableShipping")}
                checked={isShippingDisabled}
                onChange={() => handleShippingRule(constant.IS_SHIPPING_DISABLED, !isShippingDisabled)}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};
EditRule.propTypes = editProp.type;
export default EditRule;
