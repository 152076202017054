import React, { useContext } from "react";
import { TextField, Card, FormLayout } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { withErrorBoundary } from "lib/hoc";

const Price = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { moneyFormat = "" } = currentUser;
  const { handleChange, price, salesPrice } = props;

  const isVendor = baseHelper.isVendor(currentUser);

  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999 },
  } = constant;

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (val >= min && val <= max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  return (
    <Card title={cms("section.price.title")} id="variantPrice" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label={cms("label.regularPrice")}
            min="0"
            value={(price && price.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={!isVendor}
            onChange={(value) => handleChange("price", acceptOnlyValidInput(value))}
          />
          <TextField
            id="salesPrice"
            label={cms("label.salesPrice")}
            min="0"
            value={(salesPrice && salesPrice.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            prefix={moneyFormat}
            disabled={!isVendor}
            onChange={(value) => handleChange("salesPrice", acceptOnlyValidInput(value))}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

export default withErrorBoundary(Price);
