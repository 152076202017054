import React, { useContext } from "react";

import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

import { OperatorServiceVariantEdit } from "./operator/features";
import { ProviderServiceVariantEdit } from "./provider/features";

const { userKey } = constant;
const { operator, provider } = userKey;

const ServiceVariantEdit = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorServiceVariantEdit />;
    }
    if (role === provider) {
      return <ProviderServiceVariantEdit />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const VariantEditComponent = getComponent(currentUserRole);
  return VariantEditComponent;
};

export default ServiceVariantEdit;
