import { gql } from "apollo-boost";

const FETCH_CURRENT_USER = gql`
  {
    getCurrentUser {
      status
      data {
        _id
        aws {
          bucket
          path
          id
          secret
        }
        isOtp
        firstlogin
        firstName
        fulfillmentScopeExists
        ecommercePlatform
        lastName
        isFirstProduct
        isSuspended
        isExpressPayment
        isTest
        isProductDiscovery
        payoutSetting {
          isAutoStripe
          isAutoPaypal
        }
        email {
          address
          verified
        }
        contactEmail
        roles {
          name
        }
        moneyFormat
        shop
        brandName
        vendors {
          max
          associated
        }
        products {
          max
          used
        }
        isOrderConnect
        isReadOnly
        plan {
          analytics
          cancelledAt
          chargeId
          code
          commission
          isBranding
          isChargebee
          isStripe
          isDuePayment
          name
          paymentStatus
          price
          trialEndDate
          isPAYE
          paye {
            calculatedPrice
            currencyRate
            endTermDate
            orderCount
            startTermDate
          }
        }
        planPreference
        stripe {
          accountId
          isVerified
        }
        paypalKey {
          clientId
          secret
        }
        paypal {
          emailId
          payerId
          status
        }
        setupProgress
        phoneNumber
        address
        city
        country
        pinCode
        commissionType
        setting {
          isAcceptOrder
          isFulfillOrder
        }
        isChargebee
        chargebee {
          apiKey
          site
          currencyCode
        }
        isStripeSubscription
        stripeSubscription {
          apiKey
          isUpdate
          priceTableId
          publishedKey
        }
        logo
        favicon
        shop
        shopifyAccessToken
        generalInfo {
          isSellOnline
          storeType
          isVendorOnboarding
          marketplaceUnderstanding
          annualRevenue
          alternateEmail
          brandDescription
        }
        introductorySeller
        introductorySellerName
        paymentGateway
        planId
        userLevel
        isApproved
        rejectCount
        isProductSync
        isHideMcInfo
        isInactive
        emailSign
        footerText
        loginText
        domain
        isDomainActive
        register {
          title
          desc
        }
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
        }
        maintenance {
          isApp
          isSite
        }
        brand {
          termsLink
          policyLink
          slug
          description
          cover {
            imageUrl
          }
        }
        isAwsCredsAvailable
        sellerName
        sellerShop
        createdAt
        requestedBy
        updatedAt
        storeConfig {
          magento {
            shop
            accessToken
          }
          prestaShop {
            shop
            accessKey
          }
          wooCommerce {
            shop
            accessKey
            accessToken
          }
          bigCommerce {
            accessToken
            clientId
            clientSecret
            shopDomain
            isNewProduct
          }
          wix {
            accessKey
            instanceId
            isNewProduct
          }
          shopify {
            apiKey
            apiSecretKey
          }
        }
        taxSetting {
          company
          customs
          vat
        }
        isAttachmentVisible
      }
    }
  }
`;
export default FETCH_CURRENT_USER;
