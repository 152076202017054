import PropTypes from "prop-types";

const advanceProp = {
  type: {
    advancedValues: PropTypes.arrayOf(PropTypes.object),
    method: PropTypes.string.isRequired,
    ruleBy: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    isInvalidRow: PropTypes.bool,
  },
  default: {
    advancedValues: [],
    isOpen: false,
    isInvalidRow: false,
  },
};

export default advanceProp;
