import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import {
  ActionList,
  Button,
  Caption,
  DatePicker,
  Link,
  Modal,
  PageActions,
  Popover,
  RadioButton,
  Stack,
  Tabs,
  TextStyle,
} from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import { useQuery } from "react-apollo";

// import helper
import { baseHelper, errorHelper, statusHelper } from "lib/helpers";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import queries
import { CHECK_ORDER_LINES, GET_PAYMENT_EXPORT_DATA } from "app/payments/apollo/queries";

// import components
import { Banner } from "lib/components";
import List from "./subFeatures/list";

// import props
import { exportProps } from "./props";

const ProviderExportPayment = (props) => {
  const { openModal, setOpenModal } = props;
  const { cms, currentUser, history } = useContext(PrivateContext);
  const [isExportDone, setExportDone] = useState(false);
  const [queryData, setQueryData] = useState({});
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataToFetch = {};
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const { createdAt } = currentUser;
  const [
    getCheckOrderList,
    { data: checkOrderList, loading: checkOrderLoading, error: checkOrderError },
  ] = useLazyQuery(CHECK_ORDER_LINES, { variables: { input: queryData } });
  const { data: paymentData, loading: paymentLoading, error } = useQuery(GET_PAYMENT_EXPORT_DATA);
  const [selected, setSelected] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [exportedAll, setExportedAll] = useState(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectDate, setSelectDate] = useState();
  const [submitDate, setSubmitDate] = useState({});
  const [exportedLink, setExportedLink] = useState(false);
  const [exportedPDFLink, setExportedPDFLink] = useState(false);
  const [selectedItems, setSelectedItems] = useState([[], []]);
  const [params, setParams] = useState();
  const exportPaymentURL = "/export/payment";

  const disableDate = (newDate) => {
    if (newDate.getDate() === new Date(createdAt).getDate()) {
      newDate.setDate(new Date(createdAt).getDate() - 1);
    } else {
      newDate.setDate(new Date(createdAt));
    }
    return newDate;
  };

  const handleStates = () => {
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
    setSelectedItems([[], []]);
    setSelected(0);
  };

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);
  useEffect(() => {
    if (error) {
      setBanner({ title: errorHelper.parse(error), status: constant.CRITICAL, isOpen: true });
      return;
    }
    if (!paymentLoading && paymentData) {
      const getTaxStatus = [];

      const response = baseHelper.getResponseData(paymentData, constant.gql.GET_PAYMENT_EXPORT_DATA);
      const { vendors = [], accountingStatuses = [] } = response || {};

      const getStatus = accountingStatuses.map(accountingStatus => ({
        id: accountingStatus,
        name: statusHelper.getBadgeStatus(accountingStatus),
      }));
  
      getTaxStatus.push(
        { id: "isTaxInclusive", name: cms("section.export.label.inclusiveTax") },
        { id: "isTaxExclusive", name: cms("section.export.label.exclusiveTax") }
      );

      setVendorList(vendors);
      setStatusList(getStatus);
      setTaxList(getTaxStatus);
    }
  }, [paymentData, paymentLoading, error]);

  useEffect(() => {
    let bannerData = false;
    if (checkOrderList && !checkOrderLoading && generate) {
      const checkOrderData = baseHelper.getResponseData(checkOrderList, constant.gql.CHECK_ORDER_LINES);
      if (!checkOrderData) {
        const resError = baseHelper.getResponseError(checkOrderList, constant.gql.CHECK_ORDER_LINES);
        bannerData = { title: resError || cms("message.error.noPayment"), status: constant.CRITICAL, isOpen: true };
        setQueryData({});
      }
      const csvDownloadRoute = `${exportPaymentURL}${params}`;
      const pdfDownloadRoute = `${exportPaymentURL}/pdf${params}`;
      setExportedLink((isExportDone && csvDownloadRoute) || null);
      setExportedPDFLink((isExportDone && pdfDownloadRoute) || null);
      setLoading(false);
    }
    if (checkOrderError) {
      bannerData = { title: errorHelper.parse(checkOrderError), status: constant.CRITICAL, isOpen: true };
    }
    if (bannerData) {
      setBanner({ title: bannerData.title, status: bannerData.status, isOpen: bannerData.isOpen });
      handleStates();
    }
  }, [checkOrderError, checkOrderList, checkOrderLoading, exportedAll, isExportDone, params, cms]);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
  };

  const onExport = () => {
    setLoading(true);
    setGenerate(true);
    let taxStatus = [];
    let dateRange = {};
    dateRange = selectDate || {};
    taxStatus = [...selectedItems[1]];
    let accountingStatus = [];
    accountingStatus = [...selectedItems[0]];
    const taxStatusString = taxStatus.join(",");
    const statusString = accountingStatus.join(",");
    if (!(exportedAll || statusString)) {
      setLoading(false);
      setBanner({ title: cms("message.error.status"), status: constant.CRITICAL, isOpen: true });
      return;
    }
    let url = `?startDate=${dateRange.start || ""}&endDate=${dateRange.end || ""}`;
    if (!exportedAll) {
      url = `?accountingStatus=${statusString}&taxStatus=${taxStatusString}&startDate=${
        dateRange.start || ""
      }&endDate=${dateRange.end || ""}`;
    }
    setParams(url);
    if (accountingStatus.length > 0) {
      dataToFetch.accountingStatus = accountingStatus;
    }
    if (selectDate) {
      const startDate = moment(selectDate.start).format("ll");
      const endDate = moment(selectDate.end).format("ll");
      dataToFetch.dateRange = { start: startDate, end: endDate };
    }
    if (taxStatus.length > 0) {
      dataToFetch.taxStatus = taxStatus;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
    setExportDone(true);
    setExportedLink(false);
    setExportedPDFLink(false);
  };
  const tabs = [
    { id: "statuses", content: cms("section.export.label.status"), panelId: "panel-status" },
    { id: "taxstatus", content: cms("section.export.label.taxStatus"), panelId: "panel-taxStatus" },
  ];

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(tabs);
    const singularItem = constant.ITEM;
    const pluralItem = constant.ITEMS;
    const changeIndex = 0;
    selectedItems.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${constant.SELECTED})`;
      }
    });
    return exportModalTabs;
  };

  const items = [statusList, taxList];

  const handleSelection = (event) => {
    const prevSelection = [...selectedItems];
    prevSelection[selected] = [...event];
    setSelectedItems(prevSelection);
    setExportedLink(null);
    setExportedPDFLink(null);
    setExportDone(false);
    setLoading(false);
  };

  const onCloseModal = () => {
    handleStates();
    setExportedAll(true);
    setOpenModal(false);
    setBanner("");
    setLoading(false);
    setSubmitDate({});
    setSelectDate();
    setIsCalendarOpen(false);
    setGenerate(false);
  };

  const downloadCSV = (link) => {
    window.open(link);
    onCloseModal();
  };

  const downloadPDF = (link) => {
    window.open(link);
    onCloseModal();
  };

  const tab = getResourceListTabs();
  const [active, setActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const handleChange = () => {
    setActive(!active);
    setPopoverActive(!popoverActive);
  };

  const activator = (
    <Button onClick={handleChange} disclosure>
      {cms("section.export.label.selectFormat")}
    </Button>
  );
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);

  const handleDatePicker = () => {
    setSelectDate({ start: selectDate.start, end: selectDate.end });
    setSubmitDate((prev) => {
      const initialState = { ...prev };
      initialState.start = selectDate.start;
      initialState.end = selectDate.end;
      initialState.startingDate = moment(selectDate.start).format("ll");
      initialState.endingDate = moment(selectDate.end).format("ll");
      initialState.isShowDate = true;
      return initialState;
    });
    setIsCalendarOpen(false);
  };

  const handleCancelDate = () => {
    const initialState = { ...submitDate };
    setSelectDate({
      start: initialState.start || new Date(),
      end: initialState.end || new Date(),
    });
    setIsCalendarOpen(false);
  };

  const handlePopover = () => {
    return (
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList
          items={[
            {
              content: cms("section.export.label.exportCSV"),
              onAction: () => {
                downloadCSV(exportedLink);
              },
            },
            {
              content: cms("section.export.label.exportPDF"),
              onAction: () => {
                downloadPDF(exportedPDFLink);
              },
            },
          ]}
        />
      </Popover>
    );
  };

  const actions = () => (
    <Stack alignment="center">
      <Stack.Item fill>
        <Link onClick={() => history.push("/setting/payment/export")}>{cms("section.export.label.customize")}</Link>
      </Stack.Item>
      <Stack.Item>
        <Button onClick={() => onCloseModal()}>{cms("section.export.label.cancel")}</Button>
      </Stack.Item>
      <Stack.Item>
        {exportedLink ? (
          handlePopover()
        ) : (
          <Button primary onClick={() => onExport()} loading={loading}>
            {cms("section.export.label.generate")}
          </Button>
        )}
      </Stack.Item>
    </Stack>
  );

  return (
    <Modal
      open={openModal}
      onClose={() => onCloseModal()}
      title={[
        cms("section.export.label.title"),
        <TextStyle variation="subdued">
          <Caption>{cms("section.export.helpText.description")}</Caption>
        </TextStyle>,
      ]}
      loading={paymentLoading}
      footer={actions()}
    >
      <Banner
        title={banner.title}
        status={banner.status}
        isOpen={banner.isOpen}
        onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
      />
      <Modal.Section>
        {isCalendarOpen && (
          <>
            <div>
              <DatePicker
                month={month}
                year={year}
                onChange={(date) => {
                  setSelectDate({ start: date.start, end: date.end });
                }}
                onMonthChange={handleMonthChange}
                selected={selectDate}
                disableDatesAfter={new Date()}
                disableDatesBefore={disableDate(new Date())}
                multiMonth
                allowRange
              />
            </div>
            <div>
              <PageActions
                primaryAction={{
                  content: cms("button.apply"),
                  onClick: () => {
                    handleDatePicker();
                  },
                }}
                secondaryActions={[
                  {
                    content: cms("common.button.cancel"),
                    onClick: () => {
                      handleCancelDate();
                    },
                  },
                ]}
              />
            </div>
            <br />
          </>
        )}
        {!isCalendarOpen && (
          <Stack>
            <Stack.Item fill>
              <RadioButton
                id="exportAllRadio"
                name={constant.EXPORT}
                label={cms("section.export.label.all")}
                helpText={cms("section.export.helpText.all")}
                checked={exportedAll}
                onChange={() => {
                  setBanner({ isOpen: false });
                  setExportedAll(!exportedAll);
                  setExportDone(false);
                  setExportedPDFLink(false);
                  setExportedLink(false);
                  setGenerate(false);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Button plain removeUnderline onClick={() => setIsCalendarOpen(true)}>
                {cms("section.export.label.selectDate")}
              </Button>
              {submitDate.isShowDate && (
                <TextStyle variation="subdued">
                  <Caption>{`${submitDate.startingDate} - ${submitDate.endingDate}`}</Caption>
                </TextStyle>
              )}
            </Stack.Item>
            <RadioButton
              id="exportSelectedRadio"
              name={constant.EXPORT}
              label={cms("section.export.label.selectInvoice")}
              helpText={cms("section.export.helpText.select")}
              checked={!exportedAll}
              onChange={() => {
                setBanner({ isOpen: false });
                setExportedAll(!exportedAll);
                setExportDone(false);
                setExportedPDFLink(false);
                setExportedLink(false);
                setGenerate(false);
              }}
            />
          </Stack>
        )}
        {!exportedAll && !isCalendarOpen && (
          <Tabs tabs={tab} selected={selected} onSelect={handleTabChange} fitted>
            <List
              items={items}
              selected={selected}
              handleSelection={handleSelection}
              paymentLoading={paymentLoading}
              selectedItems={selectedItems || []}
              onExport={onExport}
              cms={cms}
            />
          </Tabs>
        )}
      </Modal.Section>
    </Modal>
  );
};

ProviderExportPayment.propTypes = exportProps.type;
ProviderExportPayment.defaultProps = exportProps.default;

export default ProviderExportPayment;
