import { gql } from "apollo-boost";

const SYNC_LOCATION = gql`
  mutation syncLocation {
    syncLocation {
      status
      data
      error
    }
  }
`;

export default SYNC_LOCATION;
