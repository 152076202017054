// import packages
import React, { useState, useContext } from "react";
import { DisplayText, TextField, Select, Checkbox, Modal, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import components
import Banner from "lib/components/banner/banner";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { EDIT_RULE } from "app/shipping/apollo/mutations";

// import propType
import { editRuleProp } from "app/shipping/modules/operator/features/rule/propTypes";

const EditRule = (props) => {
  const {
    shippingRule: shippingRuleProp = {},
    onClose,
    show = false,
    bands,
    destinations,
    products,
    handleBanner,
    refetch,
  } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const defaultBanner = {
    isOpen: false,
    status: "",
    title: "",
  };
  const [shippingRule, setShippingRule] = useState(shippingRuleProp);
  const [banner, setBanner] = useState({ ...defaultBanner });

  const [updateRule, { loading: updateRuleLoading }] = useMutation(EDIT_RULE);

  const checkValidation = () => {
    const { price, description, priceType } = shippingRule || {};
    if (!description) {
      return cms("message.error.addDescriptionForRule");
    }
    if (description.length > constant.value.MAX_CHARACTER) {
      return cms("message.error.descriptionCharacter");
    }
    if (!priceType) {
      return cms("message.error.selectShippingRuleType");
    }
    if (priceType !== constant.FREE && !price) {
      return cms("message.error.provideCost");
    }
    if (priceType !== constant.FREE && price < 0) {
      return cms("message.error.provideValidPrice");
    }
    return false;
  };

  const prepareRequestData = () => {
    const {
      _id,
      description,
      condition,
      destinationCode,
      isShippingDisabled,
      price,
      priceType,
      productId,
      shippingBandId,
    } = shippingRule;
    return {
      _id,
      condition,
      description,
      destinationCode,
      isShippingDisabled,
      price: (priceType && priceType === constant.FIXED && price) || 0,
      priceType,
      productId,
      shippingBandId,
    };
  };

  const closeModal = () => {
    setBanner({ ...defaultBanner });
    onClose();
  };

  const editShippingBand = async () => {
    const requestData = prepareRequestData();
    try {
      const response = await updateRule({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_SHIPPING_RULE);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      handleBanner({
        isOpen: true,
        status: "success",
        title: cms("modal.rule.edit.message.success.updateSuccessfully"),
      });
      closeModal();
      refetch();
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };
  const onSubmit = () => {
    const hasError = checkValidation();
    if (hasError) {
      setBanner({ isOpen: true, status: "critical", title: hasError });
      return false;
    }
    editShippingBand();
    return true;
  };

  const toggleBanner = () => setBanner({ ...defaultBanner });

  const handleShippingRule = (key, value) => {
    setShippingRule({ ...shippingRule, [key]: value });
  };

  const { shippingBandId, price, description, condition, destinationCode, productId, priceType, isShippingDisabled } =
    shippingRule || {};
  shippingRule.price = parseFloat(price);
  const { moneyFormat = constant.symbol.DOLLAR } = currentUser || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((item) => item.value === destinationCode);
  const product = products.find((item) => item.value === productId);

  const shippingBand = bands.find((band) => band.value === shippingBandId);

  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;
  const priceTypeOptions = [
    {
      label: cms("label.fixed"),
      value: constant.FIXED,
    },
    {
      label: cms("modal.rule.edit.label.free"),
      value: constant.FREE,
    },
  ];

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={cms("modal.rule.edit.title")}
      primaryAction={{
        content: cms("modal.rule.edit.button.primary"),
        loading: updateRuleLoading,
        onAction: () => onSubmit(),
      }}
    >
      <Modal.Section>
        <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        <br />
        <form>
          <Stack vertical>
            <Stack.Item>
              <DisplayText size={constant.SMALL}>
                {`${cms("modal.rule.edit.label.shippingBand")}: ${(shippingBand && shippingBand.label) || ""}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <Stack>
                {isDestination && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${cms("modal.rule.add.label.destination")}: ${destination && destination.label}`}
                    </DisplayText>
                  </Stack.Item>
                )}
                {isProduct && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${cms("modal.rule.edit.label.product")} ${
                        (product && product.label) || cms("label.notAvailable")
                      }`}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id={constant.INPUT_ID_DESCRIPTION}
                type={constant.TEXT}
                label={cms("modal.rule.add.label.description")}
                placeholder={cms("modal.rule.edit.placeholder.description")}
                value={description}
                onChange={(val) => handleShippingRule(constant.DESCRIPTION, val)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    options={priceTypeOptions}
                    id={constant.INPUT_ID_TYPE}
                    label={cms("modal.rule.edit.label.selectShippingType")}
                    name={constant.SHIPPING_TYPE}
                    placeholder={cms("modal.rule.edit.placeholder.selectShipping")}
                    value={priceType || ""}
                    onChange={(val) => handleShippingRule(constant.PRICE_TYPE, val)}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      id={constant.INPUT_ID_COST}
                      label={cms("modal.rule.add.label.amount")}
                      min={constant.value.MIN_PRICE}
                      step={constant.value.STEP}
                      value={price.toString() || ""}
                      onChange={(val) => handleShippingRule(constant.PRICE, acceptOnlyValidInput(val, price))}
                      prefix={moneyFormat}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                checked={isShippingDisabled}
                onChange={() => handleShippingRule(constant.IS_SHIPPING_DISABLED, !isShippingDisabled)}
                label={cms("modal.rule.edit.label.availableShipping")}
              />
            </Stack.Item>
          </Stack>
        </form>
      </Modal.Section>
    </Modal>
  );
};

EditRule.propTypes = editRuleProp.type;

export default EditRule;
