import React, { useContext } from "react";
import ProviderCreditNote from "app/setup/modules/provider/features/returnAndRefunds/creditNotes/creditNote";
import OperatorCreditNote from "app/setup/modules/operator/features/returnAndRefunds/creditNote/creditNote";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";

const { userKey } = constant;
const { provider, operator } = userKey;

const CreditNote = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === provider) {
      return <ProviderCreditNote />;
    }
    if (role === operator) {
      return <OperatorCreditNote />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const CreditNoteComponent = getComponent(currentUserRole);
  return CreditNoteComponent;
};

export default withErrorBoundary(CreditNote);
