import { yupHelper } from "lib/helpers";

// import from lib
import constant from "lib/constant/constant";
import { amountSchema, amountLengthSchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case constant.STANDARD_AMOUNT:
      promise = validateValue(amountSchema(cms), {
        standardAmount: value,
      });
      break;
    case constant.AMOUNT_LIMIT:
      promise = validateValue(amountLengthSchema(cms), {
        standardAmount: value,
      });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;
