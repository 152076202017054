import { gql } from "apollo-boost";

const GET_ERROR_LIST = gql`
  query getErrorLogList($input: GetListInput) {
    getErrorLogList(input: $input) {
      data {
        count
        errorLogList {
          _id
          createdAt
          duplicate
          error
          errorType
          field
          fileName
          initiator
          status
          success
          total
          updatedAt
          userId
        }
      }
      status
      error
    }
  }
`;

export default GET_ERROR_LIST;
