import React, { useContext, useEffect, useState } from "react";
import { Button, Caption, Card, Checkbox, Select, Stack, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import Helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import component
import { Banner, Sheet, SkeletonCard, Toast } from "lib/components";

// import mutations
import { ADD_PRODUCT_FULFILLMENT } from "app/product/apollo/mutations";

//  import queries
import { GET_PRODUCT_AND_SELLER_PRODUCT } from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import prop Type
import { tabProp } from "app/product/modules/generic/propTypes";

import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";

// import context
import { ProductContext } from "app/product/modules/generic/context";

const {
  SELECT_WEIGHT = "",
  value: { MIN_PRICE = 0, MAX_MEASUREMENT = 999 },
} = constant;

const EditFulfillment = (props) => {
  const { tabObj = {} } = props;
  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { islastTab = false } = tabObj || {};
  const [value, setValue] = useState({});
  const [productMeasurement, setProductMeasurement] = useState({});
  const { cms, history, match, currentUser } = useContext(PrivateContext);
  const { isAttachmentVisible = false } = currentUser || {};
  const { CRITICAL, gql, weightValue } = constant;
  const [message, setMessage] = useState("");
  const [sheetActive, setSheetActive] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");
  const [physicalData, setPhysicalData] = useState("");
  const { OPTIONS } = constant;
  const fieldData = [constant.IS_SHIPPING];
  setDescription(cms("label.editFulfillment"));
  setTitle(cms("label.title.fulfillment"));
  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
    });
  };

  const { loading: loadingProductForm, data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  useEffect(() => {
    if (dataProductForm) {
      const productLabel = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (productLabel && productLabel.title) {
        setPhysicalData(productLabel.physical);
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  const acceptOnlyValidInput = (val, measurementValue) => {
    return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && measurementValue);
  };

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, {
    variables: { input: { _id: match.params.id } },
  });

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT) || {};
      setValue(productResponse && productResponse.sellerProduct);
      setProductMeasurement(productResponse?.sellerProduct);
    }
  }, [gql.GET_PRODUCT_AND_SELLER_PRODUCT, productData]);

  const [addProductFulfillment, { loading: addFulfillmentLoading }] = useMutation(ADD_PRODUCT_FULFILLMENT);

  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT_AND_SELLER_PRODUCT);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productListError });
    }
  }, [CRITICAL, cms, productListError, setBanner]);

  if (productLoading || loadingProductForm) {
    return <SkeletonCard />;
  }

  const primaryAction = {
    content: cms("button.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };

  let length = (value.measurement && value.measurement.length && value.measurement.length.value) || "";
  let height = (value.measurement && value.measurement.height && value.measurement.height.value) || "";
  let width = (value.measurement && value.measurement.width && value.measurement.width.value) || "";

  const onFormSubmit = async () => {
    let banner = {};
    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);

    if (length > MAX_MEASUREMENT || width > MAX_MEASUREMENT || height > MAX_MEASUREMENT) {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.measurement"),
      };
      setBanner(banner);
      return;
    }

    // const isLargeVolumetricWeight = baseHelper.isLargeVolumetricWeight(length, width, height);
    // if (isLargeVolumetricWeight) {
    //   banner = {
    //     action: null,
    //     isOpen: true,
    //     status: constant.CRITICAL,
    //     title: cms("message.error.volume"),
    //   };
    //   setBanner(banner);
    //   return;
    // }

    const formValues = {
      productId: value.productId,
      isShipping: value.isShipping,
      weight: parseFloat(value.weight),
      weightUnit: value.weightUnit || weightValue.LB,
      measurement: {
        height: {
          value: height,
          unit: constant.CM,
        },
        length: {
          value: length,
          unit: constant.CM,
        },
        width: {
          value: width,
          unit: constant.CM,
        },
      },
    };

    const lengthValue = parseFloat(length);
    const widthValue = parseFloat(width);
    const heightValue = parseFloat(height);

    if (lengthValue > MAX_MEASUREMENT || widthValue > MAX_MEASUREMENT || heightValue > MAX_MEASUREMENT) {
      const bannerData = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.measurement"),
      };
      setBanner(bannerData);
      return;
    }

    if (physicalData && physicalData.isRequired && !value.isShipping) {
      setBanner({
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("message.error.checkbox", {
          item: ` ${baseHelper.ucFirst(physicalData.label) || cms("label.physical")}`,
        }),
      });
      return;
    }

    if (!disabledButton) {
      await addProductFulfillment({ variables: { input: formValues } })
        .then((res) => {
          const responseError = baseHelper.getResponseError(res.data, gql.ADD_PRODUCT_FULFILLMENT);
          const responseData = baseHelper.getResponseData(res.data, gql.ADD_PRODUCT_FULFILLMENT);
          if (responseError) {
            setBanner({ isOpen: true, status: CRITICAL, title: responseError });
          }
          if (responseData) {
            setMessage(cms("message.success.fulfillment"));
            setBanner({ isOpen: false, status: "", title: "" });
            setTimeout(() => {
              if (islastTab) {
                history.push("/products");
                return;
              }
              handleTabChange(5);
            }, 1500);
          }
        })
        .catch((exception) => {
          setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
        });
    }
    if (disabledButton) {
      setMessage(cms("message.success.fulfillment"));
      setTimeout(() => {
        if (islastTab) {
          history.push("/products");
          return;
        }
        handleTabChange(5);
      }, 1500);
    }
  };
  const handleSkip = () => {
    handleTabChange(5);
  };
  const selectWeight = (
    <Select
      id="weightUnit"
      value={value.weightUnit || weightValue.LB}
      label={cms("label.unit")}
      onChange={(val) => handleChange(SELECT_WEIGHT, val)}
      placeholder={cms("placeholder.unit")}
      labelHidden
      options={OPTIONS}
    />
  );

  const handleMeasurement = (key, val) => {
    const measurementValue = value && value.measurement;
    const data = measurementValue[key] || {};
    delete measurementValue[key];
    const { unit = "" } = data || {};
    const measurement = {
      ...measurementValue,
      [key]: {
        value: val,
        unit,
      },
    };
    setValue({
      ...value,
      measurement,
    });
  };
  const productLength = productMeasurement?.measurement?.length?.value || "";
  const productHeight = productMeasurement?.measurement?.height?.value || "";
  const productWidth = productMeasurement?.measurement?.width?.value || "";

  const renderMeasurement = () => {
    return (
      <>
        <TextField
          id="length"
          label={cms("label.length")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          value={length.toString() || productLength.toString() || ""}
          placeholder={cms("placeholder.length")}
          disabled
          onChange={(val) => {
            handleMeasurement(constant.LENGTH, acceptOnlyValidInput(val, length));
            setDisabledButton(false);
          }}
        />
        <br />
        <TextField
          id="width"
          label={cms("label.width")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          value={width.toString() || productWidth.toString() || ""}
          placeholder={cms("placeholder.width")}
          disabled
          onChange={(val) => {
            handleMeasurement(constant.WIDTH, acceptOnlyValidInput(val, width));
            setDisabledButton(false);
          }}
        />
        <br />
        <TextField
          id="height"
          label={cms("label.height")}
          min={MIN_PRICE}
          max={MAX_MEASUREMENT}
          value={height.toString() || productHeight.toString() || ""}
          placeholder={cms("placeholder.height")}
          disabled
          onChange={(val) => {
            handleMeasurement(constant.HEIGHT, acceptOnlyValidInput(val, height));
            setDisabledButton(false);
          }}
        />
      </>
    );
  };

  const isNew = value && value.status && value.status.toLowerCase() === constant.NEW;

  const required = (data) => {
    if (data && data.isRequired) {
      return "*";
    }
    return "";
  };

  const getLabel = (data, defaultCMS) => {
    if (data && data.label) {
      return data.label;
    }
    return defaultCMS;
  };

  return (
    <>
      <Card
        title={[
          cms("label.shipping"),
          value && value.title && (
            <TextStyle variation="subdued">
              {value.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>}
            </TextStyle>
          ),
        ]}
        id="productshipping"
        // actions={[
        //   {
        //     content: cms("label.more"),
        //     onAction: () => {
        //       learnMore("productshipping", cms("label.shipping"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <TextContainer>{cms("label.caption.weight")}</TextContainer>
          <br />
          <Checkbox
            id="shippingCheckbox"
            label={`${getLabel(physicalData, cms("label.physical"))}${required(physicalData)}`}
            checked={value.isShipping || ""}
            onChange={(val) => {
              handleChange(constant.IS_SHIPPING, val);
              setDisabledButton(false);
            }}
          />
          {!value.isShipping && (
            <div>
              <br />
              <Banner isOpen status={constant.INFO} isScrollTop={false}>
                <p>{cms("label.uncheck")}</p>
              </Banner>
            </div>
          )}
        </Card.Section>
        {value.isShipping && (
          <Card.Section title={cms("label.weight")}>
            <p>{cms("label.caption.rate")}</p>
            <br />
            <TextField
              id="weight"
              label={cms("label.weight")}
              value={
                (value.weight && value.weight.toString()) ||
                (productMeasurement?.weight && productMeasurement.weight.toString()) ||
                ""
              }
              placeholder={constant.ZERO_PRICE}
              onChange={(val) => {
                handleChange(constant.WEIGHT, acceptOnlyValidInput(val, value.weight));
                setDisabledButton(false);
              }}
              disabled={!value.weightUnit}
              connectedRight={selectWeight}
            />
            <br />
            {renderMeasurement()}
          </Card.Section>
        )}
      </Card>
      <br />
      <Stack>
        <Stack.Item fill>
          <Button onClick={() => handleTabChange(3)}>{cms("button.previous")}</Button>
        </Stack.Item>
        {((isNew && isAttachmentVisible) || !islastTab) && (
          <Stack.Item>
            <Button onClick={() => handleSkip()} disabled={addFulfillmentLoading}>
              {/* {isAttachmentVisible ? "Skip" : "Finish"} */}
              {cms("button.skip")}
            </Button>
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            primary
            onClick={() => onFormSubmit()}
            disabled={islastTab ? false : disabledButton}
            loading={addFulfillmentLoading}
          >
            {!isAttachmentVisible && islastTab ? cms("button.finish") : cms(`button.${islastTab ? "submit" : "save"}`)}
          </Button>
        </Stack.Item>
      </Stack>
      {/* <PageActions
        primaryAction={{
          content: cms(`button.${islastTab ? "submit" : "save"}`),
          onAction: () => onFormSubmit(),
          loading: addFulfillmentLoading,
          disabled: disabledButton,
        }}
        secondaryActions={[
          {
            content: cms("button.previous"),
            onAction: () => handleTabChange(3),
          },
        ]}
      /> */}
      <div className="toast">
        <Toast message={message} />
      </div>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};

EditFulfillment.propTypes = tabProp.type;

export default EditFulfillment;
