import { gql } from "apollo-boost";

const ADVANCE_PICKUP_ORDER_EXPIRY = gql`
  mutation advancePickupOrderExpiry($input: AdvanceOrderExpiry) {
    advancePickupOrderExpiry(input: $input) {
      status
      data
      error
    }
  }
`;
export default ADVANCE_PICKUP_ORDER_EXPIRY;
