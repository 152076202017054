import React, { useContext } from "react";
import constant from "lib/constant/constant";
import { SkeletonAnnotated } from "lib/components";
import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { OperatorOrderExportSetting } from "app/orders/modules/operator/features";
import { ProviderOrderExportSetting } from "app/orders/modules/provider/features";
import { AdminOrderExportSetting } from "app/orders/modules/admin/features";
import { withErrorBoundary } from "lib/hoc";

const { userKey } = constant;
const { admin, operator, provider } = userKey;
const OrderExportSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminOrderExportSetting />;
    }
    if (role === operator) {
      return <OperatorOrderExportSetting />;
    }
    if (role === provider) {
      return <ProviderOrderExportSetting />;
    }
    return null;
  };
  const currentUserRole = storageHelper.get("userRole");
  const OrderExportSettingComponent = getComponent(currentUserRole);
  return OrderExportSettingComponent;
};
export default withErrorBoundary(OrderExportSetting);
