import { gql } from "apollo-boost";

const GET_COMMISSION = gql`
  query GetCommissionData($input: SellerId!) {
    getCommission(input: $input) {
      data {
        _id
        commission
        sellerId
        type
        advance {
          vendorId
          categoryId
          type
          option
          commission
        }
        createdAt
        updatedAt
      }
      status
      error
    }
  }
`;

export default GET_COMMISSION;
