import PropTypes from "prop-types";

const returnProp = {
  type: {
    data: PropTypes.shape({
      selectedPolicy: PropTypes.string.isRequired,
      allowedDay: PropTypes.string.isRequired,
    }),
    handleChange: PropTypes.func,
    handleValidation: PropTypes.func,
    errorMessage: PropTypes.objectOf(PropTypes.string),
  },
};

const paymentProp = {
  type: {
    data: PropTypes.shape({
      paymentTermsType: PropTypes.string.isRequired,
      paymentTermsDay: PropTypes.string.isRequired,
    }),
    handleChange: PropTypes.func,
    handleValidation: PropTypes.func,
    errorMessage: PropTypes.objectOf(PropTypes.string),
  },
};

export { returnProp, paymentProp };
