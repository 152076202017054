import { gql } from "apollo-boost";

const GET_ORDER_REPORT_LIST = gql`
  query getOrderReportList($input: GetReportListInput!) {
    getOrderReportList(input: $input) {
      status
      error
      data {
        orderReportList {
          totalPrice
          totalOrders
          orderRentalCount
        }
        countryList
      }
    }
  }
`;

export default GET_ORDER_REPORT_LIST;
