import React, { useContext } from "react";
import { Checkbox, FormLayout, Heading, TextContainer, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

// import hoc
import { FulfillmentContext } from "app/setup/modules/operator/features/_fulfillment/context";
import { withFeature, withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
import CarrierService from "./shippo/carrierService";

const Shippo = (props) => {
  const { cms, consignmentShip, consignmentShippoApiKey } = useContext(FulfillmentContext);
  const { error, handleShippo, handleValidation } = props;
  const { apiKey = "", shippingRateName = "" } = (consignmentShip && consignmentShip.shippo) || {};

  return (
    <>
      <TextField
        label={`${cms("label.api")}*`}
        value={apiKey}
        placeholder={cms("placeholder.api")}
        onChange={(value) => handleShippo(constant.API_KEY, value)}
        onBlur={() => {
          handleValidation(
            constant.API_KEY,
            consignmentShip && consignmentShip.shippo && consignmentShip.shippo.apiKey
          );
        }}
        error={error && !apiKey && error.apiKey}
      />
      {consignmentShip && consignmentShip.shippo && apiKey && consignmentShippoApiKey && (
        <>
          <br />
          <Checkbox
            label={cms("label.orderline")}
            helpText={cms("helpText.orderline")}
            checked={consignmentShip && consignmentShip.shippo && consignmentShip.shippo.isAutoFulfill}
            onChange={(value) => handleShippo(constant.IS_AUTO_FULFILL, value)}
          />
          <Checkbox
            label={cms("label.customRate")}
            helpText={cms("helpText.shippoRate")}
            checked={consignmentShip && consignmentShip.shippo && consignmentShip.shippo.isShippingRateRequired}
            onChange={(value) => handleShippo(constant.IS_SHIPPING_RATE_REQUIRED, value)}
          />
          <br />
          <FormLayout>
            <TextContainer spacing="tight">
              <Heading>{`${cms("label.carrier")}`}</Heading>
            </TextContainer>
            <CarrierService />
            <TextField
              label={`${cms("label.customRateName")}*`}
              placeholder={cms("placeholder.shippingRate")}
              value={shippingRateName}
              onChange={(value) => [
                handleShippo(constant.CUSTOM_RATE_NAME, value),
                handleValidation(constant.CUSTOM_RATE_NAME, value),
              ]}
              onBlur={() => {
                handleValidation(constant.CUSTOM_RATE_NAME, shippingRateName || "");
              }}
              error={error && !shippingRateName && error.shippingRateName}
            />
          </FormLayout>
        </>
      )}
    </>
  );
};

Shippo.propTypes = {
  handleShippo: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  handleValidation: PropTypes.func.isRequired,
};

Shippo.defaultProps = {
  error: {},
};
export default withFeature(withErrorBoundary(Shippo), {
  feature: constant.SHIPPO,
});
