import { gql } from "apollo-boost";

const SET_HUB_CONTACT_PROPERTIES = gql`
  mutation setHubContactProperties($input: ContactProperties!) {
    setHubContactProperties(input: $input) {
      data
      status
      error
    }
  }
`;

export default SET_HUB_CONTACT_PROPERTIES;
