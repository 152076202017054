import React, { useContext } from "react";

import { AdminViewActivity } from "app/userManagement/modules/admin/features";
import { OperatorViewActivity } from "app/userManagement/modules/operator/features";
import { ProviderViewActivity } from "app/userManagement/modules/provider/features";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ViewActivity = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === admin) {
      return <AdminViewActivity />;
    }
    if (role === operator) {
      return <OperatorViewActivity />;
    }
    if (role === provider) {
      return <ProviderViewActivity />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ViewActivityComponent = getComponent(currentUserRole);
  return ViewActivityComponent;
};

export default ViewActivity;
