import React from "react";
import { Switch } from "react-router-dom";
import privateRoutes from "./privateRoutes";

const PrivateRouter = () => (
  <>
    <Switch>{privateRoutes}</Switch>
  </>
);

export default PrivateRouter;
