import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, FormLayout, Stack, Tag } from "@shopify/polaris";

// helpers
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    const isKeyValuePair = !!(Array.isArray(props.values) && props.values.length && props.values[0].value);
    let sortedOptions = baseHelper.sort(props.values, isKeyValuePair && constant.LABEL);
    sortedOptions = isKeyValuePair ? sortedOptions : sortedOptions.map((item) => ({ label: item, value: item }));
    this.options = sortedOptions.filter((item) => item.label);
    const { minimumSearchLength } = props;
    this.state = {
      options: minimumSearchLength ? [] : [...this.options],
      selected: props.selected || [],
      inputText: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { values = [] } = nextProps;
    const isKeyValuePair = !!(values && values.length && values[0].value);
    let sortedOptions = baseHelper.sort(values, isKeyValuePair && "label");
    sortedOptions = isKeyValuePair ? sortedOptions : sortedOptions.map((item) => ({ label: item, value: item }));
    this.options = sortedOptions.filter((item) => item.label);
    this.setState({ selected: nextProps.selected || [] });
  }

  handleChange = (val) => {
    const { onChange } = this.props;
    const selected = [];
    val.forEach((value) => {
      const selectedVal = this.options.find((item) => item.value === value);
      if (selectedVal) {
        selected.push(selectedVal.value);
      }
    });
    this.setState({ selected }, () => onChange(selected));
  };

  updateText = (newValue) => {
    this.setState({ inputText: newValue }, () => {
      this.filterAndUpdateOptions(newValue);
    });
  };

  filterAndUpdateOptions = (inputString) => {
    const { minimumSearchLength } = this.props;
    if (!inputString || (minimumSearchLength && inputString.length < minimumSearchLength)) {
      this.setState({
        options: minimumSearchLength ? [] : [...this.options],
      });
      return;
    }

    const resultOptions = this.options.filter((option) => {
      const searchText = option.label.toLowerCase();
      if (searchText.indexOf(inputString.toLowerCase()) === 0) {
        return option;
      }
      return null;
    });
    this.setState({
      options: resultOptions,
    });
  };

  removeTag = (tag) => {
    const { onChange } = this.props;
    const { selected } = this.state;
    const valueIndex = selected.findIndex((item) => item === tag);
    if (valueIndex !== -1) {
      selected.splice(valueIndex, 1);
    }
    this.setState({ selected }, () => onChange(selected));
  };

  renderTags = () => {
    const { disabled } = this.props;
    const { selected } = this.state;
    const selectedTags = this.options.filter((item) => selected.includes(item.value));
    let tagFields = baseHelper.sort(selectedTags, constant.LABEL).map((item) => (
      <Tag key={item.value} disabled={disabled} onRemove={() => this.removeTag(item.value)}>
        {item.label}
      </Tag>
    ));
    tagFields = tagFields.filter((item) => item);
    return <Stack>{tagFields}</Stack>;
  };

  render() {
    const { label, placeholder, labelHidden, sort, id, disabled, error } = this.props;
    const { options, selected, inputText } = this.state;
    let sortedTags = [...options];
    if (sort) {
      sortedTags = baseHelper.sort(options, constant.LABEL);
    }

    const textField = (
      <Autocomplete.TextField
        onChange={this.updateText}
        label={label}
        id={id}
        labelHidden={labelHidden}
        value={inputText}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
      />
    );

    return (
      <FormLayout>
        <Autocomplete
          allowMultiple
          options={sortedTags}
          textField={textField}
          selected={selected}
          disabled={disabled}
          onSelect={this.handleChange}
          listTitle={constant.AVAILABLE_OPTION}
        />

        {this.renderTags()}
      </FormLayout>
    );
  }
}

AutoComplete.defaultProps = {
  values: [],
  selected: [],
  placeholder: "",
  labelHidden: false,
  sort: true,
  disabled: false,
  id: "",
  minimumSearchLength: 3,
  error: "",
};

AutoComplete.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  labelHidden: PropTypes.bool,
  sort: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minimumSearchLength: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default AutoComplete;
