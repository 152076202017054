import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
// eslint-disable-next-line import/no-cycle
import { storageHelper } from "lib/helpers";
// eslint-disable-next-line import/no-cycle
import { SkeletonAnnotated } from "lib/components";
// eslint-disable-next-line import/no-cycle
import { withErrorBoundary } from "lib/hoc";
import constant from "lib/constant/constant";
// eslint-disable-next-line import/no-cycle
import OperatorPayout from "./subFeatures/payout/payout";

const { userKey } = constant;
const { operator } = userKey;

const CurrencyPayoutSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonAnnotated />;
    }
    if (role === operator) {
      return <OperatorPayout />;
    }
    return null;
  };
  const exchangeComponent = getComponent(currentUserRole);
  return exchangeComponent;
};

export default withErrorBoundary(CurrencyPayoutSetting);
