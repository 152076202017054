import React from "react";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

// import component
import { Banner } from "lib/components";

import { baseHelper } from "lib/helpers";

import constant from "lib/constant/constant";

const Shipping = (props) => {
  const {
    cms,
    handleChange = () => {},
    handleTitleChange = () => {},
    productLabelForm = {},
    required = () => {},
    setDisabledButton,
    variantData = {},
  } = props;
  const { physicalProduct: physicalProductLabel } = productLabelForm;
  const { isShipping = false, weight = "", weightUnit = "", measurement = {} } = variantData || {};
  const options = constant.OPTIONS;

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && weight);
  };

  const label = (value, defaultCms) => {
    if (value && value.label) {
      return value.label;
    }
    return defaultCms;
  };

  const selectWeight = (
    <Select
      id="selectWeight"
      value={weightUnit || ""}
      label={cms("section.shipping.label.select")}
      placeholder={cms("section.shipping.placeholder.select")}
      onChange={(value) => handleChange(constant.WEIGHT_UNIT, value)}
      labelHidden
      options={options}
    />
  );

  const renderMeasurement = () => {
    const { length = {}, width = {}, height = {} } = measurement || {};
    const { value: lengthValue = 0 } = length || {};
    const { value: widthValue = 0 } = width || {};
    const { value: heightValue = 0 } = height || {};
    return (
      <>
        <TextField
          id="length"
          label={cms("section.shipping.label.length")}
          value={(lengthValue && lengthValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.length")}
          min={0}
          disabled
        />
        <br />
        <TextField
          id="width"
          label={cms("section.shipping.label.width")}
          value={(widthValue && widthValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.width")}
          min={0}
          disabled
        />
        <br />
        <TextField
          id="height"
          label={cms("section.shipping.label.height")}
          value={(heightValue && heightValue.toString()) || ""}
          placeholder={cms("section.shipping.placeholder.height")}
          min={0}
          disabled
        />
      </>
    );
  };
  return (
    <Card title={cms("section.shipping.title")} id="variantShipping">
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label={`${label(physicalProductLabel, cms("section.shipping.label.physical"))}${required(
            physicalProductLabel
          )}`}
          checked={isShipping}
          onChange={(value) => {
            handleTitleChange(constant.IS_SHIPPING, value);
            setDisabledButton(false);
            if (!value) {
              handleTitleChange(constant.WEIGHT, null);
              handleTitleChange(constant.WEIGHT_UNIT, constant.weightValue.LB);
            }
          }}
        />
        {!isShipping && (
          <div>
            <br />
            <Banner isOpen status={constant.INFO} isScrollTop={false}>
              <p>{cms("section.shipping.label.uncheck")}</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {isShipping && (
        <Card.Section title={cms("section.measurement.title")}>
          <p>{cms("section.measurement.description")}</p>
          <br />
          <TextField
            id="weight"
            label={cms("section.shipping.label.weight")}
            value={(weight && weight.toString()) || ""}
            placeholder={cms("section.shipping.placeholder.weight")}
            disabled={!weightUnit}
            onChange={(value) => {
              handleChange(constant.WEIGHT, acceptOnlyValidInput(value));
              setDisabledButton(false);
            }}
            connectedRight={selectWeight}
          />
          <br />
          {renderMeasurement()}
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  cms: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  setDisabledButton: PropTypes.func.isRequired,
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Shipping;
