import { gql } from "apollo-boost";

const GET_SELLER_FULFILLMENT = gql`
  query getSellerFulfillment {
    getSellerFulfillment {
      data {
        dropship {
          isThirdPartyShipping
          canadaPost {
            contractId
          }
          shippyPro {
            apiKey
          }
          sendabox {
            apiKey
          }
          ups {
            accessLicenseNumber
          }
          shippingOption
        }
      }
      status
      error
    }
  }
`;

export default GET_SELLER_FULFILLMENT;
