// import react packages;
import React, { useState, useEffect } from "react";

// import polaris components
import { Banner, Card, Checkbox, Layout, Modal, Scrollable, Stack, Subheading, List } from "@shopify/polaris";
import constant from "lib/constant/constant";
// import config

const HelpModal = (props) => {
  const { isOpen, loading, onSubmit, setIsOpen } = props;

  return (
    <Modal
      large
      open={isOpen}
      title="Enable Webservice API and generate your Access Key"
      onClose={() => setIsOpen(false)}
      primaryAction={{
        content: "Done",
        onAction: () => onSubmit(),
        loading,
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Layout>
            <Layout.Section>
              <Card sectioned title="Create your Prestashop access key enabling Webservice">
                In your Prestashop account: <br/> <br/>
                <List type="number">
                    <List.Item>Go to <b>Advance Parameters &gt; Webservice.</b></List.Item>
                    <List.Item>In <b>Configuration</b>, toggle On for <b>Enable PrestaShop's webservice.</b> and Save.</List.Item>
                    <List.Item>Now, Click on <b>Add new webservice key</b> on top right corner.</List.Item>
                    <List.Item>Click on <b>Generate</b> to generate your access key.</List.Item>
                    <List.Item>Now toggle On for <b>Enable webservice key</b> if not enabled by default.</List.Item>
                    <List.Item>Provide Permissions by selecting <b>All</b> checkbox for following resources: <b>categories, combinations, image_types, images, price_ranges, product_customization_fields, product_feature_values, product_features, product_option_values, product_options, product_suppliers, products, stock_availables, stocks, stores, weight_ranges.</b>.</List.Item>
                    <List.Item>Once done, click on <b>Save</b> to successfully save your newly generated webservice key.</List.Item>
                </List>
              </Card>
            </Layout.Section>
          </Layout>
          <Banner status={constant.WARNING}>
            <b>IMPORTANT:</b> Copy and store the Access key displayed. 
          </Banner>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default HelpModal;
