import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, passwordRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const shopNameSchema = (cms) =>
  yup.object().shape({
    shopName: yup.string().required(cms("message.error.shopNameIsRequired")),
  });

const brandNameSchema = (cms) =>
  yup.object().shape({
    shopName: yup.string().required("Brandname is required."),
  });

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup
      .string()
      .required(cms("common.message.error.passwordIsRequired"))
      .max(20, cms("common.message.error.passwordLength"))
      .matches(passwordRegex, cms("common.message.error.enterValidPassword")),
  });

const confirmationPasswordSchema = (cms) =>
  yup.object().shape({
    confirmationPassword: yup
      .string()
      .required(cms("common.message.error.confirmPasswordIsRequired"))
      .oneOf([yup.ref("password"), null], cms("message.error.confirmPassword")),
  });

const acceptPolicySchema = (cms) =>
  yup.object().shape({
    acceptPolicy: yup.boolean().oneOf([true], cms("common.message.error.acceptTerms&Conditions")),
  });

export { emailSchema, passwordSchema, confirmationPasswordSchema, acceptPolicySchema, brandNameSchema, shopNameSchema };
