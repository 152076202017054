function actionData(cms) {
  return {
    bulkAction: [
      { key: "paidViaPaypal", value: "Pay via paypal", roles: ["seller"] },
      { key: "paidViaStripe", value: "Pay via stripe", roles: ["seller"] },
      { key: "dispute", value: "Mark as Dispute" },
    ],
    filterData: {
      label: cms("label.filterPayment"),
      filters: [
        {
          key: "vendor",
          label: cms("label.brandName"),
          operatorText: cms("label.like"),
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/orders/view/",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: cms("label.dateAsc"), value: "createdAt_asc" },
      { label: cms("label.dateDesc"), value: "createdAt_desc" },
      { label: cms("label.orderIdAsc"), value: "orderNumber_asc" },
      { label: cms("label.orderIdDesc"), value: "orderNumber_desc" },
    ],
    options: [
      { label: cms("label.invoice"), value: "invoice" },
      { label: cms("label.payVendor"), value: "payVendor" },
    ],
    resourceName: {
      singular: cms("label.payment"),
      plural: cms("label.paymentPlural"),
    },
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
    // tabsData: [
    //   {
    //     id: "all",
    //     content: cms("label.all"),
    //     accessibilityLabel: "All customers",
    //     panelID: "all-customers-content",
    //   },
    //   {
    //     id: "forecast",
    //     content: cms("label.forecast"),
    //     panelID: "forecasted-content",
    //   },
    //   {
    //     id: "due",
    //     content: cms("label.duePayment"),
    //     panelID: "due-for-payment-content",
    //   },
    //   {
    //     id: "paid",
    //     content: cms("label.pay"),
    //     panelID: "paid-content",
    //   },
    //   {
    //     id: "dispute",
    //     content: cms("label.dispute"),
    //     panelID: "disputed-content",
    //   },
    // ],
    badge: ["accountingStatus"],
    // media: 'image',
    tableData: {
      tbody: [],
    },
  };
}
export default actionData;
