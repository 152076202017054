import React from "react";
import { Card, OptionList, Popover, RadioButton, Stack } from "@shopify/polaris";
import { renderDestination } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

const RenderOfferDestination = (props) => {
  const {
    checkedDestination,
    renderRegion,
    handleDestination,
    popoverActive,
    activator,
    handleShippingDestination,
    destinationData,
    destinationCodes,
    togglePopoverActive,
    cms,
  } = props;

  return (
    <Card title={cms("label.destination")} sectioned>
      <Stack.Item>
        <RadioButton
          label={cms("modal.offer.add.section.destination.label.allDestination")}
          helpText={cms("modal.offer.add.section.destination.caption.allDestination")}
          checked={checkedDestination}
          id="all-destinations"
          name="all-destinations"
          onChange={handleDestination}
        />
      </Stack.Item>
      <Stack.Item>
        <RadioButton
          label={cms("modal.offer.add.section.destination.label.selectedDestinations")}
          helpText={cms("modal.offer.add.section.destination.caption.selectedDestination")}
          id="selected-destinations"
          name="selected-destinations"
          checked={!checkedDestination}
          onChange={handleDestination}
        />
      </Stack.Item>

      {!checkedDestination && [
        <Stack.Item>
          <Stack.Item>
            <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
              <OptionList
                onChange={(value) => {
                  handleShippingDestination(value);
                }}
                sections={destinationData}
                selected={destinationCodes}
                allowMultiple
              />
            </Popover>
          </Stack.Item>
          <Stack.Item>{renderRegion()}</Stack.Item>
        </Stack.Item>,
      ]}
    </Card>
  );
};

RenderOfferDestination.propTypes = renderDestination.type;

export default RenderOfferDestination;
