import styled from "styled-components";

const StyledToggle = styled.div`
  .toggle-switch {
    position: relative;
    display: inline-block;
    min-height: 4.6rem;
    min-width: 9rem;
    .loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 2;
      bottom: 0;
      pointer-events: none;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      padding-left: 13px;
      .Polaris-Spinner svg {
        fill: #ccc;
      }
    }
    input {
      display: none;
      &:checked {
        & + .switch {
          background-color: #008060;
          &:before {
            transform: translateX(41px);
            background-color: white;
          }
          .tick {
            display: block;
            width: 10px;
            height: 19px;
            position: absolute;
            transform: rotate(45deg);
            left: 15px;
            top: 10px;
            border-bottom: 2px solid white;
            border-right: 2px solid white;
          }
          .cross {
            display: none;
          }
        }
        & ~ .loading {
          justify-content: flex-end;
          padding-right: 15px;
        }
      }
    }
    .switch {
      position: absolute;
      cursor: pointer;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #bbbfc3;
      inset: 0;
      transition: background-color 0.2s ease;
      &:before {
        position: absolute;
        content: "";
        left: 5px;
        top: 3px;
        width: 38px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #bbbfc3;
        background-color: #babfc3;
        transition: transform 0.3s ease;
      }
      .cross {
        display: block;
        position: absolute;
        right: 7px;
        top: 12px;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        &:before,
        &:after {
          position: absolute;
          left: 15px;
          content: " ";
          height: 20px;
          width: 2px;
          background-color: #333;
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export default StyledToggle;
