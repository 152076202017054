import { gql } from "apollo-boost";

const MANAGE_METAFIELD_KEY = gql`
  mutation manageMetaFieldKeys($input: MetaFieldKeysAccess) {
    manageMetaFieldKeys(input: $input) {
      data
      status
      error
    }
  }
`;

export default MANAGE_METAFIELD_KEY;
