import { gql } from "apollo-boost";

const UPDATE_RATE = gql`
  mutation($input: CurrencyRateSetting!) {
    updateCurrencyRateSetting(input: $input) {
      status
      error
      data
    }
  }
`;

export default UPDATE_RATE;
