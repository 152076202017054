/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

// import component
import { Banner, SkeletonAnnotated, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";
import { NotFound } from "layout/private/components";

// import gql
import { GET_MANAGE_PRICE_SETTING, GET_SELLER_VARIANT, GET_WHOLESALE_SETTING } from "app/product/apollo/queries";
import { UPDATE_VARIANT, UPLOAD_IMAGE } from "app/product/apollo/mutations";
import { GET_PRODUCT_FORM } from "app/setup/apollo/queries";
import { GET_GEOLOCATION_SETTING } from "app/setup/apollo";

import validate from "app/product/modules/operator/features/variant/subFeatures/yup/validate";
// import Location from "app/product/modules/provider/features/variant/subFeatures/edit/subFeatureItems/location";

// sub feature item
import { TitleEdit, Image, Price, Inventory, Shipping } from "./subFeatureItems";

const OperatorVariantEdit = () => {
  const { match, history, cms, currentUser } = useContext(PrivateContext);
  const { params } = match;
  const { location } = history;
  const { state } = location;
  const { productId, id: variantId } = params;

  const [basePricing, setBasePricing] = useState({});
  const [vendorShopifyProductId, setVendorShopifyProductId] = useState();

  const [isImageError, setIsImageError] = useState(false);
  const [isImageErrorDisable, setIsImageErrorDisable] = useState(false);
  const [locations, setLocation] = useState([]);
  const [isWholesalePrice, setIsWholesalePrice] = useState("");
  const [message, setMessage] = useState("");
  const [wholesalePrice, setWholesalePrice] = useState("");
  const [isManagePrice, setIsManagePrice] = useState(false);
  // const [metaFieldData, setMetafielData] = useState([]);

  const [productLabelForm, setProductLabelForm] = useState({
    barcode: "",
    comparePrice: 0,
    discount: "",
    markup: { price: 0, type: constant.FLAT },
    physicalProduct: "",
    price: "",
    quantity: "",
    sku: "",
  });

  const { data: variantData, loading, error } = useQuery(GET_SELLER_VARIANT, {
    variables: { input: { _id: productId } },
  });

  const { loading: wholesaleLoading, data: wholesaleData } = useQuery(GET_WHOLESALE_SETTING);
  const { loading: managePriceLoading, data: managePriceData } = useQuery(GET_MANAGE_PRICE_SETTING);
  const { loading: locationLoading, data: locationData } = useQuery(GET_GEOLOCATION_SETTING);
  const { data: dataProductForm } = useQuery(GET_PRODUCT_FORM);

  const [updateVariant, { loading: updateLoading, error: updateVariantError }] = useMutation(UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_SELLER_VARIANT,
        variables: { input: { _id: productId } },
      },
    ],
  });
  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [variantValue, setVariantValue] = useState({});
  const [image, setImage] = useState();

  const [options, setOptions] = useState({
    option1Val: null,
    option2Val: null,
    option3Val: null,
  });
  const [disabledButton, setDisabledButton] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });

  const { gql } = constant;

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    if (managePriceData) {
      const managePriceResponse = baseHelper.getResponseData(managePriceData, gql.GET_MANAGE_PRICE_SETTING);
      if (managePriceResponse) {
        setIsManagePrice(managePriceResponse.isUpdatePrice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managePriceData]);

  useEffect(() => {
    if (dataProductForm) {
      const responseData = baseHelper.getResponseData(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      const responseError = baseHelper.getResponseError(dataProductForm, gql.GET_PRODUCT_FORM_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
      }
      if (responseData && responseData.title) {
        const { barcode, comparePrice, discount, markup, inventory, price, physical, quantity, sku } = responseData;
        setProductLabelForm({
          barcode,
          comparePrice,
          discount,
          markup,
          inventory,
          physicalProduct: physical,
          price,
          quantity,
          sku,
        });
      }
    }
  }, [dataProductForm, gql.GET_PRODUCT_FORM_SETTING]);

  useEffect(() => {
    if (state && state.addVariant) {
      setBanner({
        action: {
          content: cms("label.action"),
          onAction: () => history.push(`/variants/add/${productId}`),
        },
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.create"),
      });
      const newState = state;
      delete newState.addVariant;
      history.replace({ ...history.location, state: newState });
    }
  }, [cms, history, productId, state]);

  useEffect(() => {
    if (wholesaleData) {
      const wholesaleResponse = baseHelper.getResponseData(wholesaleData, gql.GET_WHOLESALE_SETTING);
      if (wholesaleResponse) {
        setIsWholesalePrice(wholesaleResponse.isWholesalePriceVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholesaleData]);

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, uploadImageError, updateVariantError]);

  useEffect(() => {
    const { option1Val, option2Val, option3Val } = options;
    let isAllValuesFilled = false;
    if (option1Val) {
      isAllValuesFilled = option1Val && variantValue.option1Val;
    }
    if (option2Val) {
      isAllValuesFilled = option2Val && variantValue.option2Val;
    }
    if (option3Val) {
      isAllValuesFilled = option3Val && variantValue.option3Val;
    }

    const isAnyValidationError =
      errorMessage &&
      !!(
        (errorMessage.size && option1Val) ||
        (errorMessage.color && option2Val) ||
        (errorMessage.material && option3Val)
      );

    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [variantValue, errorMessage, options]);

  useEffect(() => {
    if (locationData) {
      const locationSetting = baseHelper.getResponseData(locationData, constant.gql.GET_GEOLOCATION_SETTING) || {};
      const { isAllowed = false, locations: locationArray = [] } = locationSetting;
      if (isAllowed && locationArray && locationArray.length) {
        const updatedLocations = locationArray.map(location => ({
          label: location,
          value: location,
          disabled: false,
        }));
        setLocation(updatedLocations);
      }
    }
  }, [locationData]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT_SELLER_PRODUCT);

    if (responseData) {
      const { sellerProduct = {} } = responseData || {};
      setBasePricing(responseData && responseData.sellerProduct && responseData.sellerProduct.basePricing);
      const { variants = [], images = [] } = sellerProduct || {};
      setVendorShopifyProductId(responseData.sellerProduct.vendorShopifyProductId);

      if (variants.length < variantId || variantId <= 0) {
        return <NotFound />;
      }

      const variantOption = variants[variantId - 1];
      setOptions({
        option1: variantOption.option1,
        option2: variantOption.option2,
        option3: variantOption.option3,
        option1Val: variantOption.option1Val,
        option2Val: variantOption.option2Val,
        option3Val: variantOption.option3Val,
      });
      setProductImages(images || []);
      setImage((variantOption.image && [{ url: variantOption.image }]) || []);
      setVariantValue(variants[variantId - 1]);
      if (variants[variantId - 1] && variants[variantId - 1].customInfo) {
        setWholesalePrice(variants[variantId - 1].customInfo.wholesalePrice || 0);
      }
    }
  }, [variantData, gql, variantId]);

  // useEffect(() => {
  //   if (variantValue) {
  //     const { metafield = [] } = variantValue;
  //     if (metafield && metafield.length) {
  //       const getMetaData = metafield.map((meta) => {
  //         const val = baseHelper.isValidJSON(meta.value) ? JSON.parse(meta.value) : meta.value;
  //         return {
  //           location: meta.key,
  //           price: val.price,
  //           comparePrice: val.comparePrice,
  //           quantity: val.quantity,
  //         };
  //       });

  //       setMetafielData(getMetaData);
  //     }
  //   }
  // }, [variantValue]);

  if (loading || locationLoading || wholesaleLoading) {
    return <SkeletonAnnotated />;
  }

  const variantUpdate = (updateValues) => {
    const weightData = updateValues && updateValues.weight;
    const markUpPrice = (updateValues && updateValues.markUp && updateValues.markUp.price) || 0;
    const discountPrice = (updateValues && updateValues.discount && updateValues.discount.price) || 0;
    delete updateValues.weight;
    // eslint-disable-next-line no-param-reassign
    updateValues.weight = parseFloat(weightData);
    if (updateValues?.markUp?.price) {
      updateValues.markUp.price = parseFloat(markUpPrice) || 0;
    }
    if (updateValues?.discount?.price) {
      updateValues.discount.price = parseFloat(discountPrice) || 0;
    }

    if (isWholesalePrice) {
      updateValues.customInfo = { wholesalePrice: parseFloat(wholesalePrice || 0) };
    }

    delete updateValues.metafield;

    updateVariant({ variables: { input: updateValues } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.EDIT_VARIANT);
        if (resError) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: resError,
          });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_VARIANT);
        if (responseData) {
          setMessage(cms("message.success.update"));
        }
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  const onSubmit = async () => {
    const result = { ...variantValue };
    await handleValidation("option1", result.option1Val);
    await handleValidation("option2", result.option2Val);
    await handleValidation("option3", result.option3Val);

    const { barcode, markUp, sellerDiscount, sku, weight } = result || {};

    if (markUp && !markUp.price) {
      markUp.price = 0;
    }

    if (sellerDiscount && !sellerDiscount.price) {
      sellerDiscount.price = 0;
    }

    result.inventoryQuantity = parseInt(result.inventoryQuantity, 10);

    const dataToCheck = {
      barcode,
      comparePrice: result.comparePrice,
      physicalProduct: result.isShipping,
      price: result.price,
      quantity: result.inventoryQuantity,
      sku,
      weight,
    };

    if (sellerDiscount && sellerDiscount.price !== "") {
      dataToCheck.discount = sellerDiscount;
    }

    if (markUp && markUp.price !== "") {
      dataToCheck.markup = markUp;
    }

    const requiredKeys =
      (productLabelForm &&
        Object.keys(productLabelForm).filter((item) => productLabelForm[item] && productLabelForm[item].isRequired)) ||
      [];

    let errorField = "";
    if (requiredKeys && requiredKeys.length) {
      requiredKeys.forEach((key) => {
        if (key === constant.COMPARE_PRICE) {
          return;
        }
        const valueData = dataToCheck[key];
        if (!valueData && constant.VARIANTS_KEY.includes(key)) {
          errorField =
            productLabelForm[key].label ||
            (key === constant.PHYSICAL_PRODUCT ? cms("section.shipping.label.physical") : cms(`label.${key}`)) ||
            key;
        }
      });
    }
    if (errorField) {
      const check = cms("section.shipping.label.physical") === errorField ? " checkbox" : "";
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: `${baseHelper.ucFirst(errorField)}${check} is required.`,
      });
      return false;
    }
    if (!submitButton.isReadyToSubmit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: constant.SMOOTH,
      });
      return false;
    }

    const { discount: discountValue, sellerDiscount: sellerDiscountValue } = result || {};
    result.discount = sellerDiscountValue || discountValue || {};
    delete result.sellerDiscount;
    const notToUpdate = constant.NOT_TO_UPDATE;
    notToUpdate.forEach((element) => {
      delete result[element];
    });

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      result.image = { url: imageUrl };
    }
    result.id = parseInt(variantId, 10);
    result.productId = productId;
    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    if (!isImageError && isLocalImageUrl) {
      try {
        const res = await uploadImage({
          variables: { input: { image: image[0], productId: match.params.productId } },
        });
        const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
        if (resData) {
          result.image = { url: resData.imageUrl };
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    }
    setDisabledButton(true);
    if (!errorField) {
      variantUpdate(result);
    }
    return null;
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldName === constant.WEIGHT_UNIT || fieldValue > -1 || fieldValue.price > -1 || fieldName === constant.SKU) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
      setDisabledButton(false);
    }
  };

  const handleTitleChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
    setIsImageErrorDisable(true);
  };

  const required = (value) => {
    if (value && value.isRequired) {
      return "*";
    }
    return "";
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
              action={banner.action}
              onDismiss={onDismissBanner}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleEdit
          cms={cms}
          errorMessage={errorMessage}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          options={options}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
          locations={locations}
        />
        <Image
          productImages={productImages}
          setImage={setImage}
          setDisabledButton={setDisabledButton}
          image={image}
          cms={cms}
          isImageError={isImageError}
          setIsImageError={setIsImageError}
          isImageErrorDisable={isImageErrorDisable}
        />
        <Price
          basePricing={basePricing}
          handleChange={handleChange}
          isManagePrice={isManagePrice}
          isWholesalePriceVisible={isWholesalePrice}
          productLabelForm={productLabelForm}
          required={required}
          setDisabledButton={setDisabledButton}
          setWholesalePrice={setWholesalePrice}
          vendorShopifyProductId={vendorShopifyProductId}
          variantData={variantValue}
          wholesalePrice={wholesalePrice}
        />
        <Inventory
          cms={cms}
          handleChange={handleChange}
          handleTitleChange={handleTitleChange}
          productLabelForm={productLabelForm}
          required={required}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        {/* {!!(metaFieldData && metaFieldData.length) && (
          <Location
            cms={cms}
            currentUser={currentUser}
            metaFieldData={metaFieldData}
            setDisabledButton={setDisabledButton}
            variantData={variantValue}
          />
        )} */}
        <Shipping
          cms={cms}
          handleChange={handleChange}
          handleTitleChange={handleTitleChange}
          productLabelForm={productLabelForm}
          required={required}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <div className="toast">
          <Toast message={message} setToast={setMessage} />
        </div>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            onAction: () => onSubmit(),
            loading: updateLoading || imageLoading,
            disabled:
              disabledButton ||
              (variantValue && !(variantValue.option1Val || variantValue.option2Val || variantValue.option3Val)),
          }}
          secondaryActions={{
            content: cms("section.label.button.cancel"),
            onAction: () => history.push(`/products/edit/${productId}?tab=2`),
            disabled: updateLoading || imageLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorVariantEdit), { feature: constant.EDIT_VARIANT });
