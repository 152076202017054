import { gql } from "apollo-boost";

const SHOPIFY_CALLBACK = gql`
  mutation saveShopifyVendor($input: SaveShopifyVendor) {
    saveShopifyVendor(input: $input) {
      data
      status
      error
    }
  }
`;

export default SHOPIFY_CALLBACK;
