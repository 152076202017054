import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  Collapsible,
  Layout,
  FormLayout,
  TextField,
  Caption,
  List,
  Select,
  Stack,
  Link,
  TextContainer,
  Banner as PolarisBanner,
  TextStyle,
} from "@shopify/polaris";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import helpers
import { baseHelper, errorHelper } from "lib/helpers";

// import components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

// constant
import constant from "lib/constant/constant";

// props
import advanceProp from "app/setup/modules/generic/propTypes";

let isMarkUp = true;

const AdvanceOption = (props) => {
  const { currentUser, cms, isLoading, history } = useContext(PrivateContext);
  const { PRODUCT_TYPE, TYPE, FLAT, CATEGORY, userKey, gql, PERCENTAGE } = constant;
  const { moneyFormat = "" } = currentUser || {};
  const { location = {} } = history;
  const { pathname } = location;
  const isMarkup = pathname === "/setting/markup";
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [sheetActive, setSheetActive] = useState(false);

  const {
    advancedValues,
    method,
    isOpen = false,
    ruleBy: propsRuleBy,
    isInvalidRow = false,
    setSubmitEnable = () => {},
    setAdvancedRuleByOption = () => {},
    lastUpdated = "",
    vendorAndProductTypesLoading,
    vendorAndProductTypesError,
    vendorAndProductTypesData,
    setAdvancedValueOption,
  } = props;
  isMarkUp = method !== constant.DISCOUNT;

  const [count, setCount] = useState((advancedValues && advancedValues.length) || 1);
  const [advancedValue, setAdvancedValue] = useState(
    advancedValues || [{ type: null, category: null, value: null, price: "" }]
  );
  const [allTypes, setAllTypes] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [isResponseLoaded, setResponseLoaded] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  if (advancedValues && advancedValues.length && isDataLoading) {
    setAdvancedValueOption(advancedValues);
    setIsDataLoading(false);
  }

  useEffect(() => {
    if (isInvalidRow) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("common.message.error.invalidRowDetails"),
      });
    }
    if (!isInvalidRow) {
      setBanner({ isOpen: false });
    }
  }, [isInvalidRow, method, cms]);

  useEffect(() => {
    if (vendorAndProductTypesError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(vendorAndProductTypesError),
      });
    }
  }, [cms, vendorAndProductTypesError]);

  useEffect(() => {
    if (vendorAndProductTypesLoading) {
      return;
    }
    if (isResponseLoaded) {
      return;
    }
    setResponseLoaded(true);
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, gql.GET_VENDOR_PRODUCT);
    if (!responseData) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.advancedOptionsUnavailableReason"),
        status: constant.CRITICAL,
      });
      return;
    }
    if (advancedValues && advancedValues.length) {
      setAdvancedValue(advancedValues);
      setCount(advancedValues.length);
    }
    const { types = [], supplierRows = [] } = responseData;
    const formattedTypes = types.map(({ _id: productTypeId, value }) => ({ label: value, value: productTypeId }));
    const formattedSuppliers = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: vendorId, brandName }) => ({
        label: brandName,
        value: vendorId,
      }));

    const isAnySuppliers = formattedSuppliers.length;
    const isAnyTypes = formattedTypes.length;

    if (!isAnyTypes) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.advancedOptionsCategoriesUnavailableReason"),
        status: constant.WARNING,
      });
    }
    if (!isAnySuppliers) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.advancedOptionsVendorsUnavailableReason"),
        status: constant.WARNING,
      });
    }
    if (!(isAnyTypes || isAnySuppliers)) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.advancedOptionsUnavailableReason"),
        status: constant.CRITICAL,
      });
    }
    setAllTypes(formattedTypes);
    setAllSuppliers(formattedSuppliers);
  }, [
    gql.GET_VENDOR_PRODUCT,
    cms,
    vendorAndProductTypesData,
    vendorAndProductTypesLoading,
    advancedValues,
    isResponseLoaded,
  ]);

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getRuleOptions = [
    {
      label: cms("common.label.vendor"),
      value: userKey.vendor,
    },
    {
      label: cms("common.label.productType"),
      value: PRODUCT_TYPE,
    },
  ];

  const removeItem = (index) => {
    setBanner({
      isOpen: false,
    });
    const updatedAdvancedValue = [...advancedValue];
    updatedAdvancedValue.splice(index, 1);
    setAdvancedValue(updatedAdvancedValue);
    setAdvancedValueOption(updatedAdvancedValue);
    setCount(count - 1);
    setSubmitEnable(true);
  };

  const addItem = () => {
    if (advancedValue.length) {
      const line = advancedValue[advancedValue.length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (!line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setSubmitEnable(false);
        setBanner({
          isOpen: true,
          title: cms("common.message.error.invalidRowDetails"),
          status: constant.CRITICAL,
        });
        return;
      }
    }

    const row = { type: null, category: null, value: null, price: "" };
    const updatedAdvancedValue = [...advancedValue];
    updatedAdvancedValue.push(row);
    setAdvancedValue(updatedAdvancedValue);
    setAdvancedValueOption(updatedAdvancedValue);
    setCount(count + 1);
  };

  const updateValue = ({ option, value, item }) => {
    let duplicate = false;
    const updatedAdvancedValue = [...advancedValue];
    if (value === PERCENTAGE || value === FLAT) {
      updatedAdvancedValue[item].price = null;
    }
    if (option === TYPE) {
      updatedAdvancedValue[item][CATEGORY] = null;
    }
    if (option === CATEGORY) {
      updatedAdvancedValue.forEach((data) => {
        if (data.category === value) {
          updatedAdvancedValue[item][option] = null;
          updatedAdvancedValue[item][CATEGORY] = null;
          setBanner({
            isOpen: true,
            title: cms("common.message.error.alreadyExists"),
            status: constant.CRITICAL,
          });
          duplicate = true;
        }
      });
    }
    if (duplicate) {
      return;
    }
    if (!duplicate) {
      setBanner({
        isOpen: false,
        title: "",
        status: "",
      });
    }
    if (option === method) {
      const isLessThanMaxFlat = updatedAdvancedValue[item].value === FLAT && value <= constant.value.MAX_FLAT;
      const isLessThanMaxPercentage =
        updatedAdvancedValue[item].value === PERCENTAGE && value <= constant.value.MAX_MARKUP_PERCENTAGE;
      if (isLessThanMaxFlat || isLessThanMaxPercentage) {
        updatedAdvancedValue[item].price = value;
      }
    } else {
      updatedAdvancedValue[item][option] = value;
    }
    setAdvancedValue(updatedAdvancedValue);
    setAdvancedValueOption(updatedAdvancedValue);
    setSubmitEnable(true);
  };

  const valueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },

    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE,
    },
  ];

  const typeOptions = [
    {
      label: cms("common.label.category"),
      value: CATEGORY,
    },
    {
      label: cms("common.label.vendor"),
      value: userKey.vendor,
    },
  ];

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };

  const renderAdvanceItems = () => {
    const availableOptions = [...typeOptions];
    if (!allSuppliers.length) {
      availableOptions.pop();
    }
    if (!allTypes.length) {
      availableOptions.shift();
    }
    const indexes = Array.from(Array(count).keys());
    const formWidth170px = { width: "170px" };
    const formWidth100px = { width: "100px" };
    return indexes.map((index) => {
      const advanceItem = advancedValue[index];

      const isFlatType = advanceItem && advanceItem.value === FLAT;
      const getMaxValue = isFlatType ? constant.value.MAX_FLAT : constant.value.MAX_MARKUP_PERCENTAGE;
      const getValuePrefix = isFlatType ? (currentUser && moneyFormat) || constant.symbol.DOLLAR : "";
      const getValueSuffix = isFlatType ? "" : constant.symbol.PERCENTAGE;
      const getTypeOptions = advanceItem && advanceItem.type === CATEGORY ? [...allTypes] : [...allSuppliers];

      if (!advanceItem) {
        return null;
      }
      return (
        <Stack wrap={false} key={index} spacing="extraTight" distribution="fillEvenly" alignment="center">
          <Stack.Item>
            <Select
              id={`advanceTypeSelect${index}`}
              options={availableOptions}
              placeholder={cms("common.placeholder.type")}
              value={(advanceItem && advanceItem.type) || ""}
              onChange={(value) => updateValue({ option: TYPE, value, item: index })}
            />
          </Stack.Item>
          <Stack.Item>
            <div style={formWidth170px}>
              <Select
                id={`advanceOptionSelect${index}`}
                options={getTypeOptions}
                placeholder={cms("common.label.options")}
                value={(advanceItem && advanceItem.category) || ""}
                onChange={(value) => updateValue({ option: CATEGORY, value, item: index })}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div style={formWidth170px}>
              <Select
                id={`advanceValueSelect${index}`}
                options={valueOptions}
                placeholder={cms("common.placeholder.value")}
                value={(advanceItem && advanceItem.value) || ""}
                onChange={(value) => updateValue({ option: "value", value, item: index })}
              />
            </div>
          </Stack.Item>
          <div style={formWidth100px}>
            <Stack.Item>
              <TextField
                id={`advanceValue${index}`}
                min={0}
                max={getMaxValue}
                prefix={getValuePrefix}
                suffix={getValueSuffix}
                onChange={(value) => {
                  updateValue({
                    option: method,
                    value: acceptOnlyValidInput(value, advanceItem && advanceItem.price),
                    item: index,
                  });
                }}
                value={
                  (advanceItem && (advanceItem.price === "" || advanceItem.price) && advanceItem.price.toString()) || ""
                }
                disabled={!(advanceItem && advanceItem.value)}
              />
            </Stack.Item>
          </div>

          <Stack.Item>
            <Button id={`advanceDeleteButton${index}`} plain onClick={() => removeItem(index)}>
              <i className="far fa-trash fa-lg redColor" />
            </Button>
          </Stack.Item>
        </Stack>
      );
    });
  };

  const noValues = !(allSuppliers.length || allTypes.length || advancedValue.length < 1);

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const caption = isMarkup ? cms("section.advance.caption") : cms("operator.advance.caption");
  const title = isMarkup ? cms("section.advance.title") : cms("operator.advance.title");
  const description = isMarkup ? cms("section.advance.description") : cms("operator.advance.description");

  return (
    <Collapsible open={isOpen}>
      <Layout.AnnotatedSection title={title || ""} description={description || ""}>
        <Card
          title={[
            method === "markUp" ? cms("section.advance.title") : cms("operator.advance.title"),
            lastUpdated && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
              </Caption>
            ),
          ]}
          // actions={[
          //   {
          //     content: cms("common.label.learnMore"),
          //     onAction: () => {
          //       setSheetActive(true);
          //     },
          //   },
          // ]}
        >
          <Card.Section>
            {(method === "discount" && (
              <>
                <TextContainer>{cms("operator.advance.subDescription")}</TextContainer>
                <br />
              </>
            )) || <TextContainer>{cms("section.advance.description")}</TextContainer>}
            <br />
            {banner.isOpen && (
              <>
                <Banner
                  isOpen={banner.isOpen}
                  status={banner.status}
                  title={banner.title}
                  onDismiss={dismissBanner}
                  isScrollTop={false}
                />
                <br />
              </>
            )}
            <Sheet
              title={method === "discount" ? `Advance ${baseHelper.ucFirst(method)}` : "Advance markUp"}
              isOpen={sheetActive}
              onClose={() => setSheetActive(false)}
              primaryAction={{
                content: cms("common.label.done"),
                onAction: () => setSheetActive(false),
              }}
              secondaryAction={{
                content: cms("common.button.cancel"),
                onAction: () => setSheetActive(false),
              }}
            >
              TODO:
            </Sheet>
            <FormLayout>
              {
                <Select
                  label={cms("common.label.setRules")}
                  placeholder={cms("common.label.setRuleBy")}
                  options={getRuleOptions}
                  value={propsRuleBy}
                  onChange={(selectedValue) => {
                    setAdvancedRuleByOption(selectedValue);
                    setSubmitEnable(true);
                  }}
                />
              }
              {!noValues && renderAdvanceItems()}
              {!noValues && (
                <Link id="addLink" onClick={addItem}>
                  {cms("common.button.addMore")}
                </Link>
              )}
              <PolarisBanner status="info">
                <p>{cms("common.label.option")}</p>
                <br />
                <div id="listContent">
                  <List type="bullet">
                    {caption &&
                      caption.map((item) => {
                        return (
                          <List.Item key={item}>
                            <Caption>
                              <b>{item.title}</b>
                              {` ${item.description}`}
                            </Caption>
                          </List.Item>
                        );
                      })}
                  </List>
                </div>
              </PolarisBanner>
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

AdvanceOption.propTypes = advanceProp.type;
AdvanceOption.defaultProps = advanceProp.default;

const featureKey = (isMarkUp && constant.ADVANCE_MARKUP) || constant.ADVANCE_DISCOUNT;

export default withFeature(withErrorBoundary(AdvanceOption), { feature: featureKey });
