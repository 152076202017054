import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Layout, Stack, FormLayout, TextField, Card, PageActions, Button } from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_VENDOR_PROFILE } from "app/vendors/apollo/queries";
import { RESEND_OTP_EMAIL } from "app/vendors/apollo/mutations";

const ViewVendorProfile = () => {
  const { history, match, cms, currentUser } = useContext(PrivateContext);
  const { gql } = constant;
  const { params } = match;
  const { id = "" } = params;
  const { ecommercePlatform } = currentUser;
  const { location: { state: { profileEdit: isProfileEdit /* isBrandChanged */ } = {} } = {} } = history;

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    children: null,
  });
  const [showProfileEditBanner, setShowProfileEditBanner] = useState(true);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
    brandName: "",
    checked: false,
  });

  const { data: vendorProfileData, loading: vendorProfileLoading } = useQuery(GET_VENDOR_PROFILE, {
    variables: { input: { vendorId: id } },
  });

  const [resetVendorCredential, { loading: resetVendorLoading }] = useMutation(RESEND_OTP_EMAIL);

  const currentVendor = baseHelper.getResponseData(vendorProfileData, gql.GET_VENDOR_PROFILE);
  const resError = baseHelper.getResponseError(vendorProfileData, gql.GET_VENDOR_PROFILE);

  const setProfileEditBanner = () => {
    if (isProfileEdit && showProfileEditBanner) {
      setBanner({
        isOpen: true,
        title: cms("message.success.profileUpdated"),
        status: constant.SUCCESS,
        children:
          (ecommercePlatform
            ? cms("message.pushToEcommerce", { item: baseHelper.ucFirst(ecommercePlatform) })
            : cms("message.shopifyPushMessage")) || null,
      });
      setShowProfileEditBanner(false);
    }
  };

  useEffect(() => {
    if (currentVendor) {
      const { vendorRow = {} } = currentVendor;
      const {
        _id: vendorId,
        firstName,
        lastName,
        email = {},
        phoneNumber,
        address,
        city,
        country,
        pinCode,
        brandName,
        isReadOnly,
      } = vendorRow || {};
      const { address: emailAddress } = email;
      setValues({
        vendorId,
        firstName,
        lastName,
        email: emailAddress,
        phoneNumber,
        streetAddress: address,
        city,
        country,
        postalCode: pinCode,
        brandName,
        checked: isReadOnly,
      });
      setProfileEditBanner();
    }
    if (resError) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: resError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVendor, resError]);

  if (vendorProfileLoading) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }

  const textFields = [
    { label: `${cms("label.firstName")}*`, value: values.firstName },
    { label: `${cms("label.lastName")}*`, value: values.lastName },
    { label: `${cms("label.email")}*`, value: values.email },
    { label: `${cms("label.phoneNumber")}*`, value: values.phoneNumber },
    { label: `${cms("label.brandName")}*`, value: values.brandName },
    { label: `${cms("label.streetAddress")}*`, value: values.streetAddress },
    { label: `${cms("label.city")}*`, value: values.city },
    { label: `${cms("label.country")}*`, value: values.country },
    { label: `${cms("label.postalCode")}`, value: values.postalCode },
  ];

  const resetVendor = () => {
    const vendorInputData = {
      id: values.vendorId,
      email: values.email,
    };
    resetVendorCredential({ variables: { input: vendorInputData } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.RESEND_OTP_EMAIL);
        let bannerData = {
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("message.success.resetPassword"),
        };
        if (responseError) {
          bannerData = { isOpen: true, status: constant.CRITICAL, title: responseError };
        }
        setBanner(bannerData);
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const { brandName = "" } = values || {};

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "", children: null })}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      {!resError && (
        <Layout.AnnotatedSection
          title={<div className="ellipsis">{cms("title.profile", { brandName })}</div>}
          description={cms("description.profile")}
        >
          <FormLayout>
            <Stack wrap>
              <Stack.Item fill />
              <Stack.Item>
                <Button primary loading={resetVendorLoading} onClick={() => resetVendor()}>
                  {cms("button.resetVendor.primaryAction")}
                </Button>
              </Stack.Item>
              <Stack.Item>
                <Button primary onClick={() => history.push(`/providers/edit/${id}`)}>
                  {cms("button.editProfile.primaryAction")}
                </Button>
              </Stack.Item>
            </Stack>
            <Card sectioned>
              <FormLayout>
                {textFields.map((textField) => (
                  <TextField
                    label={textField.label}
                    key={textField.key}
                    id={textField.key}
                    value={(textField.value && textField.value.toString()) || ""}
                    disabled
                  />
                ))}
              </FormLayout>
            </Card>
          </FormLayout>
          <PageActions
            secondaryActions={[
              {
                content: cms("button.cancel"),
                onAction: () => history.push("/"),
              },
            ]}
          />
        </Layout.AnnotatedSection>
      )}
    </>
  );
};

export default withFeature(withErrorBoundary(ViewVendorProfile), {
  feature: constant.STANDARD_VENDOR_PROFILE,
  // subFeature: constant.STANDARD_VENDOR_PROFILE,
});
